import React, { useContext, useEffect, useState } from "react";
import ChangeOverTimeSiteRankingChartView from "./ChangeOverTimeSiteRankingChartView";
import { DATA_STATUS, RESOURCES } from "../../../../config/constants";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import DashboardDataContext from "../../Context/DashboardDataContext";
import DashboardCalculator from "../../Models/DashboardCalculator";
import { ChangeOverTimeSiteRankingChartDataModel } from "../../Models/DashboardModels";

const convertChangeOverTimeSiteRankingDataToChartModel = (
  changeOverTimeSiteRankingData
) => {
  const totalForSiteNameByYear = changeOverTimeSiteRankingData.value;

  const years = Object.keys(totalForSiteNameByYear);

  if (years.length !== 2) {
    return { data: [], hasDataGaps: true };
  }

  const year1 = years[0];
  const year2 = years[1];

  const orderedSiteNames = Object.keys(totalForSiteNameByYear[year1]).sort();
  const chartModels = orderedSiteNames.map((siteName) => {
    return new ChangeOverTimeSiteRankingChartDataModel(
      siteName,
      year1,
      totalForSiteNameByYear[year1][siteName],
      year2,
      totalForSiteNameByYear[year2][siteName]
    );
  });

  const hasDataGaps =
    changeOverTimeSiteRankingData.status !== DATA_STATUS.COMPLETE;

  return {
    data: chartModels,
    hasDataGaps: hasDataGaps,
  };
};

const ChangeOverTimeSiteRankingChart = ({ element }) => {
  const userInventory = useContext(UserInventoryContext);
  const currentInventory = userInventory.selectedInventory.get;

  const dashboardDataContext = useContext(DashboardDataContext);
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const [changeOverTimeSiteRankingData, setChangeOverTimeSiteRankingData] =
    useState(null);

  const config = element["config"];

  const subtopic = config["subtopic"];
  const isDataLoaded = dashboardHolderFacade.isAllRequiredDataPresent(
    [subtopic],
    true
  );

  useEffect(() => {
    if (
      isDataLoaded &&
      currentInventory.type === RESOURCES.PORTFOLIO &&
      dashboardHolderFacade.isComparisonTime()
    ) {
      setChangeOverTimeSiteRankingData(
        DashboardCalculator.getChangeOverTimeSiteRankingData(
          config,
          dashboardHolderFacade
        )
      );
    }
  }, [dashboardDataContext.lastDataUpdated]);

  if (!currentInventory || currentInventory.type !== RESOURCES.PORTFOLIO) {
    return null;
  }

  if (!isDataLoaded || changeOverTimeSiteRankingData === null) {
    return (
      <ChangeOverTimeSiteRankingChartView
        title="Change over time - Site ranking"
        isLoading={true}
      />
    );
  }

  const { data: changeOverTimeSiteRankingChartData, hasDataGaps } =
    convertChangeOverTimeSiteRankingDataToChartModel(
      changeOverTimeSiteRankingData
    );

  return (
    <ChangeOverTimeSiteRankingChartView
      title="Change over time - Site ranking"
      isLoading={false}
      period={dashboardHolderFacade.getComparisonTimeDisplay()}
      subtopic={subtopic}
      intensity={DashboardCalculator.getIntensityDisplay(
        config,
        dashboardHolderFacade
      )}
      hasDataGaps={hasDataGaps}
      chartData={changeOverTimeSiteRankingChartData}
      unit={DashboardCalculator.getDividendUnit(config, dashboardHolderFacade)}
      intensityUnit={DashboardCalculator.getDivisorUnit(
        config,
        dashboardHolderFacade
      )}
    />
  );
};

export default ChangeOverTimeSiteRankingChart;
