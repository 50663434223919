import { RTFUtils } from "../../../services/UtilsService";
import { FileAttachmentItemModel } from "../../common/FileAttachments";

export const CertificationStatus = {
  published: "Published",
  unpublished: "Unpublished",
};

export class CertificationListItemModel {
  constructor(id, name, order, isPublished, updatedOn, updatedBy, status) {
    this.id = id;
    this.name = name;
    this.order = order;
    this.isPublished = isPublished;
    this.updatedOn = updatedOn;
    this.updatedBy = updatedBy;
    this.status = status;
  }

  static fromDTO(dto) {
    return new CertificationListItemModel(
      dto["id"],
      dto["name"],
      dto["order"],
      dto["is_published"],
      dto["last_updated_on"],
      dto["last_updated_by"],
      dto["is_published"]
        ? CertificationStatus.published
        : CertificationStatus.unpublished
    );
  }
}

export class CertificationModel {
  constructor(
    id,
    name,
    order,
    version,
    description,
    verificationDetails,
    isPublished,
    logoFile,
    createdBy,
    createdOn,
    updatedBy,
    updatedOn
  ) {
    this.id = id;
    this.name = name;
    this.order = order;
    this.version = version;
    this.description = description;
    this.verificationDetails = verificationDetails;
    this.isPublished = isPublished;
    this.logoFile = logoFile;
    this.createdBy = createdBy;
    this.createdOn = createdOn;
    this.updatedBy = updatedBy;
    this.updatedOn = updatedOn;
  }

  static fromDTO(dto) {
    const logoFile = dto["logo_file"];

    return new CertificationModel(
      dto["id"],
      dto["name"],
      dto["order"],
      dto["version"],
      RTFUtils.convertHTMLToEditorState(dto["description"]),
      RTFUtils.convertHTMLToEditorState(dto["verification_details"]),
      dto["is_published"],
      logoFile ? [FileAttachmentItemModel.fromFileDTO(logoFile)] : [],
      dto["created_by"],
      dto["created_on"],
      dto["updated_by"],
      dto["updated_on"]
    );
  }
}

export class CertificationContentCategoryModel {
  constructor(id, name, order, criteria) {
    this.id = id;
    this.name = name;
    this.order = order;
    this.criteria = criteria;
  }

  static fromDTO(dto) {
    return new CertificationContentCategoryModel(
      dto["id"],
      dto["name"],
      dto["order"],
      dto["criteria"].map((criterion) =>
        CertificationContentCriteriaModel.fromDTO(criterion)
      )
    );
  }
}

class CertificationContentCriteriaModel {
  constructor(id, number, name, order, description, instruction, requirements) {
    this.id = id;
    this.number = number;
    this.name = name;
    this.order = order;
    this.description = description;
    this.instruction = instruction;
    this.requirements = requirements;
  }

  static fromDTO(dto) {
    return new CertificationContentCriteriaModel(
      dto["id"],
      dto["number"],
      dto["name"],
      dto["order"],
      dto["description"],
      dto["instruction"],
      dto["requirements"].map((requirement) =>
        CertificationRequirementModel.fromDTO(requirement)
      )
    );
  }
}

export class CertificationRequirementModel {
  constructor(
    id,
    criteriaId,
    number,
    name,
    order,
    instruction,
    instructionGreenviewPortal,
    greenviewGuidance,
    additionalInformation,
    subrequirementSections,
    sampleEvidences
  ) {
    this.id = id;
    this.criteria_id = criteriaId;
    this.number = number;
    this.name = name;
    this.order = order;
    this.instruction = instruction;
    this.instructionGreenviewPortal = instructionGreenviewPortal;
    this.greenviewGuidance = greenviewGuidance;
    this.additionalInformation = additionalInformation;
    this.subrequirementSections = subrequirementSections;
    this.sampleEvidences = sampleEvidences;
  }

  static fromDTO(dto) {
    return new CertificationRequirementModel(
      dto["id"],
      dto["criteria_id"],
      dto["number"],
      dto["name"],
      dto["order"],
      dto["instruction"],
      dto["instruction_greenview_portal"],
      dto["greenview_guidance"],
      dto["additional_information"],
      dto["subrequirement_sections"].map(
        CertificationSubrequirementSectionModel.fromDTO
      ),
      dto["sample_evidences"].map(CertificationSampleEvidenceModel.fromDTO)
    );
  }
}

class CertificationSubrequirementSectionModel {
  constructor(id, sectionName, subrequirements) {
    this.id = id;
    this.sectionName = sectionName;
    this.subrequirements = subrequirements;
  }

  static fromDTO(dto) {
    return new CertificationSubrequirementSectionModel(
      dto["id"],
      dto["section_name"],
      dto["subrequirements"].map(CertificationSubrequirementModel.fromDTO)
    );
  }
}

class CertificationSampleEvidenceModel {
  constructor(id, name, file) {
    this.id = id;
    this.name = name;
    this.files = file ? [file] : [];
  }

  static fromDTO(dto) {
    return new CertificationSampleEvidenceModel(
      dto["id"],
      dto["name"],
      dto["file"] ? FileAttachmentItemModel.fromFileDTO(dto.file) : null
    );
  }
}

class CertificationSubrequirementModel {
  constructor(id, content) {
    this.id = id;
    this.content = content;
  }

  static fromDTO(dto) {
    return new CertificationSubrequirementModel(dto["id"], dto["content"]);
  }
}
