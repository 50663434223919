export const performanceComparisonChartConfig = {
  numTicks: 5,
  lineChartLeftMarginInPx: 50,
  lineChartRightMarginInPx: 50,
  bottomAxisHeightInPx: 50,
  tableTitleColumnIndex: 0,
  tablePercentageRowIndex: 3,
  leftAxisLabelOffsetInPx: 22,
  monthTicks: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  chartViewId: "performance-comparison-chart-view-id",
  expandedChartViewId: "expanded-performance-comparison-chart-view-id",
  miniViewExcludeOnDownloadContainerId:
    "performance-comparison-mini-view-exclude-on-download-container-id",
  expandedViewExcludeOnDownloadContainerId:
    "performance-comparison-expanded-view-exclude-on-download-container-id",
};

export const performanceComparisonChartAccessors = {
  xAccessor: (d) => d?.month,
  yAccessor: (d) => d?.value,
};
