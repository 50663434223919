import React, { useContext, useEffect, useState } from "react";
import MetroAreaService from "../../../../services/MetroAreaService";
import withAuthentication from "../../../HOC/withAuthentication";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { Container } from "react-bootstrap";
import FilterSearchBox from "../../../../gvds-components/common/FilterSearchBox";
import { REFERENCE_DATA } from "../../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import uniq from "lodash/uniq";
import ToastContext from "../../../../context/ToastContext";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../../config/search-config";
import GVDSTable, {
  SORTING_TYPES,
} from "../../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconTableExport } from "@tabler/icons-react";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import ExcelExporterService from "../../../../services/ExcelExporterService";

const MetroArea = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [metroAreaList, setMetroAreaList] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    metroAreaList,
    SystemToolboxSearchKeysByPageName.referenceData.metroArea,
    {
      name: SORTING_TYPES.asc,
    }
  );

  const loadRecord = () => {
    setIsLoading(true);
    MetroAreaService.getAllMetroAreas()
      .then((response) => {
        setIsLoading(false);
        setMetroAreaList(response);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load metro areas.</span>);
      });
  };

  useEffect(() => {
    loadRecord();
  }, []);

  const columns = [
    {
      header: "Metro Area",
      dataKey: "name",
      sortable: true,
    },
    {
      header: "Country Name",
      dataKey: "country_name",
      sortable: true,
    },
  ];

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  const exportListAsExcel = () => {
    const flatJson = ExcelExporterService.generateFlatJsonByTableColumns(
      metroAreaList,
      columns
    );
    ExcelExporterService.exportToExcel(flatJson, "Metro Area");
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return To Reference Data"
          onClick={goToReferenceData}
        />
        <PageHeader.Title>
          <h1>Metro Area</h1>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={exportListAsExcel}
            icon={<GVDSIcon Icon={IconTableExport} />}
            text="Export as Excel"
          />
        </PageHeader.Title>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search by Metro area"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(metroAreaList.map((q) => q.country_name_list).flat())}
          prefix="Country"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, country_name_list: filterKs })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {filteredSortedData.length === 0 && (
        <div className="table__no_content">No record found</div>
      )}
    </Container>
  );
};

export default withAuthentication(MetroArea);
