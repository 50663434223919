import { MenuTypeEnum } from "./WidgetModels";

const MENU_ORDER_LIST = [
  MenuTypeEnum.ContinuousTimeFilter,
  MenuTypeEnum.ComparisonTimeFilter,
  MenuTypeEnum.MetricSelector,
  MenuTypeEnum.SiteSelector,
  MenuTypeEnum.ClassificationSelector,
  MenuTypeEnum.UnitSelector,
];

const MENU_ORDER = MENU_ORDER_LIST.reduce(
  (menuToSortOrder, menuInList, menuIndex) => {
    menuToSortOrder[menuInList] = menuIndex;

    return menuToSortOrder;
  },
  {}
);

export const dashboardMenuSortFn = (menuSchema1, menuSchema2) => {
  const sortOrder1 = MENU_ORDER[menuSchema1["type"]];
  const sortOrder2 = MENU_ORDER[menuSchema2["type"]];

  if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
    return sortOrder1 - sortOrder2;
  } else if (sortOrder1 !== undefined) {
    return -1;
  } else if (sortOrder2 !== undefined) {
    return 1;
  } else {
    return menuSchema1.localeCompare(menuSchema2);
  }
};
