import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import Form from "react-bootstrap/Form";
import React, { useContext } from "react";
import CertificationContext from "../CertificationContext";

const CertificationCriteriaFilters = ({
  searchBoxRef,
  searchInput,
  onInput,
  isHideCompleted,
  onHideCompletedChange,
  filteredCriteriaCount,
}) => {
  const certificationContext = useContext(CertificationContext);

  return (
    <div className="certification-homepage__criterion-filter__container">
      <FilterSearchBox
        ref={searchBoxRef}
        value={searchInput}
        onInput={onInput}
        placeholder="Search by criteria name, description, number, category name"
        className="certification-homepage__criterion-filter__search-box"
      />
      <div className="d-flex">
        <div className="gvds-text--inputField">Hide complete</div>
        <Form.Check
          className="ms-1"
          type="switch"
          checked={isHideCompleted}
          onChange={(event) => onHideCompletedChange(event.target.checked)}
        />
      </div>
      <div className="ms-auto">
        Showing {filteredCriteriaCount} of{" "}
        {certificationContext.certification.getTotalCriteriaCount()} Criteria
      </div>
    </div>
  );
};

export default CertificationCriteriaFilters;
