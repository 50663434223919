import _ from "lodash";
import get from "lodash/get";
import {
  CellValue,
  DateCellValue,
  DropdownCellValue,
  NumberCellValue,
  RemoveActionCell,
} from "../BulkDataInputCellViewer";
import { RESOURCES } from "../../../config/constants";

const getTranslatedDefaultColumns = (t) => {
  return [
    {
      key: "action",
      label: "",
      width: 2,
      classType: RemoveActionCell,
      errorKey: "",
    },
    {
      key: "index",
      label: `${t("data-management.shared.bulk-input.table-header-row")}`,
      width: 2.5,
      classType: CellValue,
      errorKey: "index",
    },
  ];
};

const getTranslatedValueColumns = (t) => {
  return [
    {
      key: "subtopic",
      label: `${t(
        "data-management.environmental.usage.single-input.label-subtopic"
      )}*`,
      width: 10,
      classType: DropdownCellValue,
      errorKey: "environmental_subtopic_id",
    },
    {
      key: "type",
      label: `${t("data-management.environmental.shared.type")}*`,
      width: 10,
      classType: DropdownCellValue,
      errorKey: "environmental_type_id",
    },
    {
      key: "meter",
      label: `${t("data-management.environmental.shared.meter-id")}*`,
      width: 10,
      classType: DropdownCellValue,
      errorKey: "meter_id",
    },
    {
      key: "periodFrom",
      label: `${t("shared-input-label.period-from")}*`,
      width: 11.5,
      classType: DateCellValue,
      errorKey: "period_from",
    },
    {
      key: "periodTo",
      label: `${t("shared-input-label.period-to")}*`,
      width: 11.5,
      classType: DateCellValue,
      errorKey: "period_to",
    },
    {
      key: "usage",
      label: `${t(
        "data-management.environmental.usage.single-input.label-usage"
      )}*`,
      width: 10,
      classType: NumberCellValue,
      errorKey: "usage",
    },
    {
      key: "usageUnit",
      label: `${t(
        "data-management.environmental.usage.bulk-input.table-header-usage-unit"
      )}*`,
      width: 10,
      classType: DropdownCellValue,
      errorKey: "unit_id",
    },
    {
      key: "cost",
      label: `${t(
        "data-management.environmental.usage.single-input.label-cost"
      )}`,
      width: 10,
      classType: NumberCellValue,
      errorKey: "cost",
    },
    {
      key: "costUnit",
      label: `${t(
        "data-management.environmental.usage.bulk-input.table-header-cost-unit"
      )}`,
      width: 10,
      classType: DropdownCellValue,
      errorKey: "currency_id",
    },
  ];
};

class DataRecordValue {
  action;
  index;
  facility;
  subtopic;
  type;
  meter;
  periodFrom;
  periodTo;
  usage;
  usageUnit;
  cost;
  costUnit;

  static fromRowData(rowData, columns) {
    const newRecord = new DataRecordValue();
    columns.forEach((c, col) => {
      newRecord[c.key] = c.classType.fromCellData(rowData[col]);
    });
    return newRecord;
  }

  static newReading(row, removeRow, columns) {
    const newReading = new DataRecordValue();

    columns.forEach((c) => {
      newReading[c.key] = new c.classType();
    });

    newReading.action = RemoveActionCell.fromRemoveRow(() => removeRow(row));
    newReading.index = new CellValue(row + 1, true, [], [], false);
    return newReading;
  }

  updateDataEditor(
    sites = [],
    currencies = [],
    facilities = [],
    subtopics = [],
    types = [],
    meters = [],
    units = []
  ) {
    this.site && this.site.setDropdownOptions(sites);
    this.facility && this.facility.setDropdownOptions(facilities);
    this.subtopic.setDropdownOptions(subtopics);
    this.type.setDropdownOptions(types);
    this.meter.setDropdownOptions(meters);
    this.usageUnit.setDropdownOptions(units);
    this.costUnit.setDropdownOptions(currencies);
  }

  toGridRow(columns) {
    return columns.map((c) => this[c.key]);
  }

  isEmpty() {
    return (
      (!this.site || this.site.isEmpty()) &&
      (!this.facility || this.facility.isEmpty()) &&
      this.subtopic.isEmpty() &&
      this.type.isEmpty() &&
      this.meter.isEmpty() &&
      this.periodFrom.isEmpty() &&
      this.periodTo.isEmpty() &&
      this.usage.isEmpty() &&
      this.usageUnit.isEmpty() &&
      this.cost.isEmpty() &&
      this.costUnit.isEmpty()
    );
  }

  removeAllErrors(columns) {
    columns.forEach((c) => {
      this[c.key].dataErrors = [];
      this[c.key].possibleErrors = [];
    });
  }
}

export default class BulkEnvironmentalDataInputService {
  siteId = "";
  facilityId = "";
  sites = [];
  columns = [];
  selectorOptions = {};
  currencies = [];

  constructor(translator) {
    this.t = translator;
  }

  updateResource(resourceType, node) {
    this.siteId = "";
    this.facilityId = "";
    this.columns = [];
    switch (resourceType) {
      case RESOURCES.PORTFOLIO:
        this.sites = _.chain(node.children)
          .map((c) => c.nodeValue)
          .filter((c) => c.type === RESOURCES.SITE)
          .sortBy("name")
          .value();
        this.columns = this.columns
          .concat(getTranslatedDefaultColumns(this.t))
          .concat([
            {
              key: "site",
              label: `${this.t("shared.site")}*`,
              width: 10,
              classType: DropdownCellValue,
              errorKey: "site_id",
            },
            {
              key: "facility",
              label: `${this.t("shared.facility")}*`,
              width: 10,
              classType: DropdownCellValue,
              errorKey: "facility_id",
            },
          ])
          .concat(getTranslatedValueColumns(this.t));
        break;
      case RESOURCES.SITE:
        this.sites = [node.nodeValue];
        this.siteId = node.nodeValue.id;
        this.columns = this.columns
          .concat(getTranslatedDefaultColumns(this.t))
          .concat([
            {
              key: "facility",
              label: `${this.t("shared.facility")}*`,
              width: 10,
              classType: DropdownCellValue,
              errorKey: "facility_id",
            },
          ])
          .concat(getTranslatedValueColumns(this.t));
        break;
      default:
        const site = _.chain(node.parents)
          .map((n) => n.nodeValue)
          .find((p) => p.type === RESOURCES.SITE)
          .value();
        this.sites = site ? [site] : [];
        this.siteId = site.id;
        this.facilityId = node.nodeValue.id;
        this.columns = this.columns
          .concat(getTranslatedDefaultColumns(this.t))
          .concat(getTranslatedValueColumns(this.t));
    }
  }

  setSelectionOptions(bulkConfig) {
    this.currencies = bulkConfig.currencies.map((c) => ({
      id: c.id,
      name: `${c.name} (${c.id})`,
    }));
    this.selectorOptions = _.chain(bulkConfig)
      .get("facility_datasets")
      .groupBy("facility.id")
      .value();
  }

  getNewRow(row, removeRow) {
    const newReading = DataRecordValue.newReading(
      Number(row),
      removeRow,
      this.columns
    );
    this.updateDataEditorForRow(newReading);
    return newReading.toGridRow(this.columns);
  }

  updateDataEditorForRow(record) {
    const facilities = _.chain(this.sites)
      .find((s) => s.name === record.site?.value || s.id === this.siteId)
      .get("value.all_facilities", [])
      .sortBy("name")
      .value();
    const facility = _.chain(facilities)
      .find(
        (f) => f.name === record.facility?.value || f.id === this.facilityId
      )
      .value();
    const datasets = _.chain(this.selectorOptions)
      .get(facility?.id, [])
      .value();
    const subtopics = datasets.map((d) => d["environmental_subtopic"]);
    const types = _.chain(datasets)
      .filter((d) => d["environmental_subtopic"].name === record.subtopic.value)
      .flatMap((d) => d["meters"])
      .map((m) => m["environmental_type"])
      .uniqBy((u) => u.id)
      .sortBy("name")
      .value();
    const meters = _.chain(datasets)
      .filter((d) => d["environmental_subtopic"].name === record.subtopic.value)
      .flatMap((d) => d["meters"])
      .filter((m) => m["environmental_type"].name === record.type.value)
      .sortBy("name")
      .value();
    const units = _.chain(datasets)
      .filter((d) => d["environmental_subtopic"].name === record.subtopic.value)
      .flatMap((d) => d["meters"])
      .find((m) => m["environmental_type"].name === record.type.value)
      .get("environmental_type.units", [])
      .sortBy("name")
      .value();
    record.updateDataEditor(
      this.sites,
      this.currencies,
      facilities,
      subtopics,
      types,
      meters,
      units
    );
  }

  getFacilityIdForRecord(record) {
    const facilities = _.chain(this.sites)
      .find((s) => s.name === record.site?.value || s.id === this.siteId)
      .get("value.all_facilities", [])
      .value();
    return _.chain(facilities)
      .find(
        (f) => f.name === record.facility?.value || f.id === this.facilityId
      )
      .get("id")
      .value();
  }

  populateDefault(record) {
    const facilityId = this.getFacilityIdForRecord(record);
    if (
      facilityId &&
      !record.subtopic.isEmpty() &&
      !record.type.isEmpty() &&
      !record.meter.isEmpty()
    ) {
      const meter = _.chain(this.selectorOptions)
        .get(facilityId, [])
        .find((d) => d["environmental_subtopic"].name === record.subtopic.value)
        .get("meters", [])
        .find(
          (m) =>
            m.name === record.meter.value &&
            m["environmental_type"].name === record.type.value
        )
        .value();

      if (record.usageUnit.isEmpty()) {
        record.usageUnit.updateValue(
          _.chain(meter).get("default_unit.name", "").value()
        );
      }

      if (record.costUnit.isEmpty()) {
        const defaultCurrencyId = _.chain(meter)
          .get("default_currency.id", "")
          .value();
        record.costUnit.updateValue(
          _.chain(this.currencies)
            .find((c) => c.id === defaultCurrencyId)
            .get("name", "")
            .value()
        );
      }
    }
  }

  populateRow(record) {
    this.populateDefault(record);
    this.updateDataEditorForRow(record);
  }

  updateRow(rowData, changes) {
    const record = DataRecordValue.fromRowData(rowData, this.columns);

    changes.forEach((c) => {
      const colName = this.columns[c.col]?.key;
      if (colName) {
        record[colName].updateValue(c.value);
      }
    });
    this.populateRow(record);
    return record.toGridRow(this.columns);
  }

  removeRow(rowNumber, setRowToDelete, grid) {
    let changedGrid = grid.map((row) => [...row]);
    changedGrid.splice(rowNumber, 1);
    changedGrid = changedGrid.map((g, row) => {
      const reading = DataRecordValue.fromRowData(g, this.columns);
      reading.index.updateValue(row + 1);
      reading.action = RemoveActionCell.fromRemoveRow(() =>
        setRowToDelete(row)
      );
      return reading.toGridRow(this.columns);
    });
    return changedGrid;
  }

  removeEmptyRows = (grid, setRowToDelete) => {
    return grid
      .map((rowData) => DataRecordValue.fromRowData(rowData, this.columns))
      .filter((r) => !r.isEmpty())
      .map((r, row) => {
        r.index.updateValue(row + 1);
        r.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row));
        return r.toGridRow(this.columns);
      });
  };

  getDataReading = (rowData) => {
    const record = DataRecordValue.fromRowData(rowData, this.columns);
    const site = _.chain(this.sites)
      .find(
        (s) =>
          s.name?.trim() === record.site?.value?.trim() || s.id === this.siteId,
        {}
      )
      .value();
    const facilityId = _.chain(site)
      .get("value.all_facilities", [])
      .find(
        (f) =>
          f.name?.trim() === record.facility?.value?.trim() ||
          f.id === this.facilityId
      )
      .get("id", "");
    const dataset = _.chain(this.selectorOptions)
      .get(facilityId, [])
      .find(
        (d) =>
          d["environmental_subtopic"].name?.trim() ===
          record.subtopic?.value?.trim()
      )
      .value();
    const subtopicId = _.chain(dataset)
      .get("environmental_subtopic.id", "")
      .value();
    const typeId = _.chain(dataset)
      .get("meters", [])
      .find(
        (m) =>
          m["environmental_type"].name?.trim() === record.type?.value?.trim()
      )
      .get("environmental_type.id", "")
      .value();
    const meter = _.chain(dataset)
      .get("meters", [])
      .find(
        (m) =>
          m.name?.trim() === record.meter?.value?.trim() &&
          m["environmental_type"].name?.trim() === record.type?.value?.trim()
      )
      .value();
    const unit = _.chain(meter)
      .get("environmental_type.units", [])
      .find((u) => u.name?.trim() === record.usageUnit?.value?.trim())
      .get("id", "")
      .value();
    const currency = _.chain(this.currencies)
      .find((c) => c.name?.trim() === record.costUnit?.value?.trim())
      .get("id", "")
      .value();

    return {
      period_from: record.periodFrom.getValue(),
      period_to: record.periodTo.getValue(),
      usage: record.usage.getValue(),
      unit_id: unit,
      cost: record.cost.getValue(),
      currency_id: currency,
      row: record.index.value - 1,
      facility_id: facilityId,
      site_id: site?.id,
      environmental_subtopic_id: subtopicId,
      environmental_type_id: typeId,
      meter_id: meter ? meter.id : "",
    };
  };

  updateGridWithErrors = (grid, dataErrors, possibleErrors) => {
    const changedGrid = grid.map((rowData) => {
      const reading = DataRecordValue.fromRowData(rowData, this.columns);
      reading.removeAllErrors(this.columns);
      return reading.toGridRow(this.columns);
    });

    for (const [row, err] of Object.entries(dataErrors)) {
      const reading = DataRecordValue.fromRowData(
        changedGrid[row],
        this.columns
      );
      this.columns.forEach(({ key, errorKey }) => {
        reading[key].dataErrors = _.chain(err).get(errorKey, []).value();
      });
      changedGrid[row] = reading.toGridRow(this.columns);
    }

    for (const [row, err] of Object.entries(possibleErrors)) {
      const reading = DataRecordValue.fromRowData(
        changedGrid[row],
        this.columns
      );
      this.columns.forEach(({ key, errorKey }) => {
        reading[key].possibleErrors = get(err, errorKey, []);
      });
      changedGrid[row] = reading.toGridRow(this.columns);
    }

    return changedGrid;
  };
}
