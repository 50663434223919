import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AddUsersWithRoles from "../User/AddUsersWithRole";
import { RESOURCES } from "../../config/constants";
import SiteService from "../../services/SiteService";
import {
  DropdownOptions,
  GeographicCoordinates,
} from "./SiteFacilityInformationComponents";
import SelectCurrency from "../Currency/SelectCurrency";
import GVFormGroup from "../common/GVFormGroup";
import InfoTooltip from "../common/Tooltip/InfoTooltip";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSFormTextArea from "../../gvds-components/Forms/GVDSFormTextArea";
import { useTranslation } from "react-i18next";

const CreateSiteForm = ({
  site,
  onChange,
  isValidated,
  errors,
  firstUserEmail = null,
}) => {
  const { t } = useTranslation();

  const [siteTypeOptions, setSiteTypeOptions] = useState([]);
  const [usersWithRoles, setUsersWithRoles] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableProvinces, setAvailableProvinces] = useState([]);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);

  const [siteName, setSiteName] = useState(site.name || "");
  const [siteType, setSiteType] = useState(site.type || { id: "", name: "" });
  const [comment, setComment] = useState(site.comment || "");

  const [streetAddress, setStreetAddress] = useState(
    site.location?.street_address || ""
  );
  const [zipCode, setZipCode] = useState(site.location?.zip_code || "");
  const [city, setCity] = useState(site.location?.city || "");
  const [stateOrProvince, setStateOrProvince] = useState(
    site.location?.state_or_province || ""
  );
  const [country, setCountry] = useState(
    site.location?.country || { id: "", name: "" }
  );

  const [currency, setCurrency] = useState(
    site.main_attribute?.default_currency || { id: "", name: "" }
  );

  useEffect(() => {
    SiteService.getCreateSiteOptions().then((createSiteOptions) => {
      setSiteTypeOptions(createSiteOptions.site_types);
      setAvailableCountries(createSiteOptions.countries);
      setAvailableCurrencies(createSiteOptions.currencies);
      setAvailableProvinces(createSiteOptions.provinces);
    });
  }, []);

  useEffect(() => {
    onChange({
      name: siteName,
      type: siteType,
      comment: comment,
      location: {
        street_address: streetAddress,
        zip_code: zipCode,
        state_or_province: stateOrProvince,
        city: city,
        country: country,
      },
      main_attribute: {
        default_currency: currency,
      },
      usersWithRoles,
    });
  }, [
    siteName,
    siteType,
    comment,
    streetAddress,
    zipCode,
    city,
    stateOrProvince,
    country,
    currency,
    usersWithRoles,
  ]);

  useEffect(() => {
    if (!currency.id && country) {
      const countryDetails = availableCountries.find(
        (c) => c.id === country.id
      );
      if (countryDetails && countryDetails.default_currency) {
        setCurrency(countryDetails.default_currency);
      }
    }
  }, [country, availableCountries]);

  const changeCountry = (targetName, newId, newName) => {
    if (stateOrProvince) {
      const provinceDetail = availableProvinces.find(
        (p) => p.name === stateOrProvince
      );
      if (provinceDetail && provinceDetail.country.id !== newId) {
        setStateOrProvince("");
      } else if (
        !provinceDetail &&
        availableProvinces.find((p) => p.country.id === newId)
      ) {
        // no matching province dropdown and new country has province dropdown
        setStateOrProvince("");
      }
    }

    setCountry({ id: newId, name: newName });
  };

  const changeCurrency = (targetName, newId, newName) => {
    setCurrency({ id: newId, name: newName });
  };

  const isSiteNameValid = () => {
    return siteName && siteName.length > 0 && !errors.name;
  };

  const isSiteTypeValid = () => {
    return siteType && siteType.id;
  };

  const isAddressValid = () => {
    return streetAddress && streetAddress.length > 0;
  };

  const isCityValid = () => {
    return city && city.length > 0;
  };

  const isZipCodeValid = () => {
    return zipCode && zipCode.length > 0 && !errors.location?.zip_code;
  };

  const isCountryValid = () => {
    return country && country.id && country.id.length > 0;
  };

  const isCurrencyValid = () => {
    return currency && currency.id && currency.id.length > 0;
  };

  const isStateOrProvinceValid = () => {
    return (
      stateOrProvince &&
      stateOrProvince.length > 0 &&
      !errors.location?.state_or_province
    );
  };

  const stateOrProvinceInput = () => {
    const provincesOfCountry = availableProvinces.filter(
      (p) => p.country.id === country.id
    );

    if (provincesOfCountry.length > 0) {
      return (
        <>
          <DropdownOptions
            label="State / Province"
            inEditMode={true}
            targetName="stateOrProvince"
            value={{ id: stateOrProvince, name: stateOrProvince }}
            onChange={(targetName, newId, newName) =>
              setStateOrProvince(newName)
            }
            options={provincesOfCountry.map((p) => {
              return { id: p.name, name: p.name };
            })}
            isInvalid={!isStateOrProvinceValid()}
            invalidMessage="State or Province is required."
          />
        </>
      );
    } else {
      return (
        <>
          <Form.Label>State / Province</Form.Label>
          <GVDSFormField
            name="stateOrProvince"
            value={stateOrProvince}
            onInput={(value) => setStateOrProvince(value)}
          />
        </>
      );
    }
  };

  return (
    <>
      <section className="section-box">
        <header>
          <h2 id="site-information">Site Information</h2>
        </header>
        <article>
          <h3>General</h3>
          <GVFormGroup controlId="siteName">
            <Form.Label>Site Name</Form.Label>
            <GVDSFormField
              placeholder="Enter site name"
              value={siteName}
              onInput={(value) => setSiteName(value)}
              statusMetadata={
                isValidated && !isSiteNameValid()
                  ? FormFieldStatusMetadata.getError(
                      errors.name ? errors.name : "Please provide site name."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup controlId="siteType">
            <DropdownOptions
              label="Site Type"
              inEditMode={true}
              targetName="type"
              value={siteType}
              onChange={(targetName, newId, newName) =>
                setSiteType({ id: newId, name: newName })
              }
              options={siteTypeOptions}
              isInvalid={isValidated && !isSiteTypeValid()}
              invalidMessage="Please select a site type."
            />
          </GVFormGroup>
          <GVFormGroup controlId="comment">
            <Form.Label>Site Comment</Form.Label>
            <GVDSFormTextArea
              rows={3}
              placeholder="Capture any special use cases for this site that others should know about"
              value={comment}
              onInput={(value) => setComment(value)}
            />
          </GVFormGroup>
        </article>

        <article>
          <h3>Location</h3>
          <Row>
            <Col>
              <GVFormGroup>
                <Form.Label>Street Address</Form.Label>
                <GVDSFormField
                  name="streetAddress"
                  value={streetAddress}
                  onInput={(value) => setStreetAddress(value)}
                  statusMetadata={
                    isValidated && !isAddressValid()
                      ? FormFieldStatusMetadata.getError(
                          "Street address is required."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
            <Col lg={4}>
              <GVFormGroup>
                <Form.Label>Postal Code</Form.Label>
                <GVDSFormField
                  name="zipCode"
                  value={zipCode}
                  onInput={(value) => setZipCode(value)}
                  statusMetadata={
                    isValidated && !isZipCodeValid()
                      ? FormFieldStatusMetadata.getError(
                          errors.location?.zip_code
                            ? errors.location?.zip_code
                            : "Postal code is required."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <GVFormGroup>
                <Form.Label>City</Form.Label>
                <GVDSFormField
                  name="city"
                  value={city}
                  onInput={(value) => setCity(value)}
                  statusMetadata={
                    isValidated && !isCityValid()
                      ? FormFieldStatusMetadata.getError("City is required.")
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
            <Col lg={4}>{stateOrProvinceInput()}</Col>
            <Col lg={4}>
              <DropdownOptions
                label="Country"
                inEditMode={true}
                targetName="country"
                value={country}
                onChange={changeCountry}
                options={availableCountries}
                isInvalid={isValidated && !isCountryValid()}
                invalidMessage={"Please select a country"}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={7} xxl={4}>
              <GVFormGroup>
                <Form.Label>
                  Geographic Coordinates{" "}
                  <InfoTooltip
                    info={t("admin.site-details.tooltip-geo-coordinate")}
                  />
                </Form.Label>
                <div>
                  {t("admin.site-details.label-description-geo-coordinate")}
                </div>
                <GeographicCoordinates
                  siteName={siteName}
                  streetAddress={streetAddress}
                  zipCode={zipCode}
                  city={city}
                  stateOrProvince={stateOrProvince}
                  countryName={country.name}
                />
              </GVFormGroup>
            </Col>
          </Row>
        </article>
        <article>
          <h3>Currency</h3>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <Form.Label>Currency of Utility Invoice</Form.Label>
              <SelectCurrency
                selected={currency?.id}
                onCurrencySelected={(currencyId) => {
                  changeCurrency(
                    "currency",
                    currencyId,
                    availableCurrencies.find((c) => c.id === currencyId)?.name
                  );
                }}
                isInvalid={isValidated && !isCurrencyValid()}
                showLabel={false}
              />
            </Col>
          </Row>
        </article>
        <article>
          <AddUsersWithRoles
            resource={RESOURCES.SITE}
            usersWithRoles={usersWithRoles}
            setUsersWithRoles={setUsersWithRoles}
            firstUserEmail={firstUserEmail}
            isValidated={isValidated}
          />
        </article>
      </section>
    </>
  );
};

export default CreateSiteForm;
