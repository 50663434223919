import GVDSModal from "../Modals/GVDSModal";
import GVDSButton, { buttonVariant } from "../Buttons/GVDSButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import UserProfileContext from "../../context/UserProfileContext";
import {
  localStorageHomepageAnnouncementPopUpKey,
  POP_UP_ANNOUNCEMENT_RESPONSE_OPTIONS,
} from "../../config/constants";
import moment from "moment/moment";
import GVDSIcon from "../Icons/GVDSIcon";
import { IconSpeakerphone } from "@tabler/icons-react";

const SUPPRESS_DURATION_IN_HOURS = 6;

const isShowHomepageAnnouncementPopUp = (userEmail, announcementId) => {
  const savedValues =
    JSON.parse(
      localStorage.getItem(localStorageHomepageAnnouncementPopUpKey)
    ) || {};

  if (
    !savedValues ||
    !savedValues[userEmail] ||
    !savedValues[userEmail][announcementId]
  ) {
    return true;
  }

  const isPopUpSuppressed =
    savedValues[userEmail][announcementId].response ===
    POP_UP_ANNOUNCEMENT_RESPONSE_OPTIONS.SUPPRESSED;

  const popUpSuppressedExpiryTime = moment(
    savedValues[userEmail][announcementId].timestamp
  ).add(SUPPRESS_DURATION_IN_HOURS, "hours");

  const isPopUpPastSuppressedExpiryTime = moment() > popUpSuppressedExpiryTime;

  if (isPopUpSuppressed && isPopUpPastSuppressedExpiryTime) {
    return true;
  }

  return !savedValues[userEmail] || !savedValues[userEmail][announcementId];
};

const HomepageAnnouncementPopUp = ({ announcement }) => {
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const [show, setShow] = useState(true);

  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.innerHTML = announcement.description;
    }
  }, [announcement.description]);

  const prepareSavedValues = (responseOption) => {
    let savedValues =
      JSON.parse(
        localStorage.getItem(localStorageHomepageAnnouncementPopUpKey)
      ) || {};

    if (!savedValues[user.email]) {
      savedValues[user.email] = {};
    }

    savedValues[user.email][announcement.id] = {
      response: responseOption,
      timestamp: new Date().toISOString(),
    };

    return savedValues;
  };

  const dismiss = () => {
    const savedValues = prepareSavedValues(
      POP_UP_ANNOUNCEMENT_RESPONSE_OPTIONS.DISMISS
    );
    localStorage.setItem(
      localStorageHomepageAnnouncementPopUpKey,
      JSON.stringify(savedValues)
    );
    setShow(false);
  };

  const openCtaUrl = (responseOption, url) => {
    const savedValues = prepareSavedValues(responseOption);
    localStorage.setItem(
      localStorageHomepageAnnouncementPopUpKey,
      JSON.stringify(savedValues)
    );
    window.open(url, "_blank", "noreferrer");
  };

  if (!isShowHomepageAnnouncementPopUp(user?.email, announcement.id)) {
    return null;
  } else {
    return (
      <GVDSModal
        size={GVDSModal.Size.small}
        title={
          <div className="homepage-announcement-popup__title-icon">
            <GVDSIcon Icon={IconSpeakerphone} />
          </div>
        }
        show={show}
        onHide={dismiss}
        backdrop="static"
        keyboard={false}
        className="homepage-announcement-popup"
        contentClassName="homepage-announcement-popup__content"
      >
        <GVDSModal.Body>
          <div className="homepage-announcement-popup__content-title">
            {announcement.title}
          </div>
          <div
            className="homepage-announcement-popup__display__content"
            ref={descriptionRef}
          />
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {announcement.firstCtaText && (
            <GVDSButton
              variant={buttonVariant.primary}
              text={announcement.firstCtaText}
              onClick={() => {
                if (announcement.firstCtaUrl) {
                  openCtaUrl(
                    POP_UP_ANNOUNCEMENT_RESPONSE_OPTIONS.FIRST_CTA_URL,
                    announcement.firstCtaUrl
                  );
                }
              }}
            />
          )}
          {announcement.secondCtaText && (
            <GVDSButton
              variant={buttonVariant.primary}
              text={announcement.secondCtaText}
              onClick={() => {
                if (announcement.secondCtaUrl) {
                  openCtaUrl(
                    POP_UP_ANNOUNCEMENT_RESPONSE_OPTIONS.SECOND_CTA_URL,
                    announcement.secondCtaUrl
                  );
                }
              }}
            />
          )}
        </GVDSModal.Footer>
      </GVDSModal>
    );
  }
};

export default HomepageAnnouncementPopUp;
