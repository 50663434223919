import React from "react";
import { HomepageAnnouncementDisplay } from "../../../Home/Widgets/HomepageAnnouncementWidget";

const HomepageAnnouncementPreview = ({
  title,
  descriptionBodyHtml,
  firstCtaText,
  secondCtaText,
}) => {
  const isTitleAndDescriptionValid = title && descriptionBodyHtml;

  if (isTitleAndDescriptionValid) {
    return (
      <>
        {(firstCtaText || secondCtaText) && (
          <div className="gvds-text--caption__italic gvds-color--gray6">
            CTA buttons are not clickable, only for preview
          </div>
        )}
        <HomepageAnnouncementDisplay
          title={title}
          descriptionBodyHtml={descriptionBodyHtml}
          firstCtaText={firstCtaText}
          secondCtaText={secondCtaText}
        />
      </>
    );
  } else {
    return (
      <div className="base-widget__container widget--homepage-announcement__display__container">
        <div className="system-toolbox--preview-homepage-announcement__display-empty">
          This section will be populated once title and description have been
          filled in.
        </div>
      </div>
    );
  }
};

export default HomepageAnnouncementPreview;
