import React from "react";
import { Trans, useTranslation } from "react-i18next";

export const DataIssueAlertExplanationBlurb = () => {
  const { t } = useTranslation();

  return (
    <div>
      <strong>
        {t("data-management.shared.data-issue-alert-blurb1")}
      </strong>
      :
      <br />
      <ul>
        <li>
          <Trans i18nKey={"data-management.shared.data-issue-alert-blurb2"}>
            <strong>Possible Issues</strong> such as Data Gaps and abnormal
            usage/cost (80% deviation from the previous record) are
            automatically detected by default. If this column is blank, no
            issues are detected/possible issues alerts have been turned off.
          </Trans>
        </li>
        <li>
          <Trans i18nKey={"data-management.shared.data-issue-alert-blurb3"}>
            <strong>Data Requested</strong> indicates that a user has requested
            data from this site.
          </Trans>
        </li>
      </ul>
    </div>
  );
};

const DataIssueAlert = ({ icon, description }) => {
  return (
    <div className="meter-details__data-issue-alert">
      <span className="me-2">{icon}</span>
      {description}
    </div>
  );
};

export default DataIssueAlert;
