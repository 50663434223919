import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import GVDSIconCustom from "../../../gvds-components/Icons/GVDSIconCustom";
import { infoTooltipPopupDelayStart } from "../../../config/style-config";

const InfoTooltip = ({
  info,
  icon = <GVDSIconCustom.Info />,
  placement = "auto",
  ...props
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={infoTooltipPopupDelayStart}
      overlay={
        <Popover>
          <Popover.Body>{info}</Popover.Body>
        </Popover>
      }
    >
      <div className="d-inline-flex align-items-center h-100 ms-1" {...props}>
        <div className="info-tooltip-icon">{icon}</div>
      </div>
    </OverlayTrigger>
  );
};

export default InfoTooltip;
