import Tab from "react-bootstrap/Tab";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import React, { forwardRef, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconChevronDown } from "@tabler/icons-react";
import moment from "moment/moment";
import Tabs from "react-bootstrap/Tabs";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import DashboardFilterMonthGridSelection from "./DashboardFilterMonthGridSelection";

const monthsInclusionInfoTooltipCopy =
  "Select or deselect months to be included for the chosen year. Use “Apply to all years” to copy your selection across all years.";

const getYearRange = (startDate, endDate) => {
  const startYear = moment(startDate).year();
  const endYear = moment(endDate).year();
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  return years;
};

const getEnabledMonthsByYear = (startPeriod, endPeriod) => {
  const start = moment(startPeriod);
  const end = moment(endPeriod);
  const enabledMonths = {};

  while (start.isSameOrBefore(end, "month")) {
    const year = start.year();
    const monthInNumber = start.month() + 1;

    if (!enabledMonths[year]) {
      enabledMonths[year] = [];
    }

    enabledMonths[year].push(monthInNumber);

    start.add(1, "month");
  }

  return enabledMonths;
};

const MonthInclusionTrigger = forwardRef(
  ({ onClick, isFilterApplied }, ref) => (
    <div
      className="dashboard-filter__continuous-time-month-inclusion-control__trigger"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="dashboard-filter__text-display-with-indicator">
        {isFilterApplied && (
          <span className="dashboard-filter__applied-filter-indicator" />
        )}
        Months
      </div>
      <GVDSIcon Icon={IconChevronDown} />
    </div>
  )
);

const MonthInclusionGridByYear = ({
  inputIncludedMonthsByYear,
  onMonthSelectionClick,
  enabledMonthsByYear,
  handleSelectAll,
  handleClearAll,
  handleApplyToAllYears,
  availableYears,
  activeYear,
  onYearChange,
}) => {
  const enabledMonths = enabledMonthsByYear[activeYear] || [];
  const selectedMonths = inputIncludedMonthsByYear[activeYear] || [];

  const isSelectAllDisabled =
    enabledMonths.length > 0 &&
    enabledMonths.every((month) => selectedMonths.includes(month));

  const isClearAllDisabled = selectedMonths.length === 0;

  const isApplyToAllYearsButtonDisabled = () => {
    const isMonthsForActiveYearNotInitialised = selectedMonths.length === 0;
    const onlyOneYearAvailable = availableYears.length === 1;

    const allYearsHaveSameSelection = availableYears.every((year) => {
      const currentYearMonths = inputIncludedMonthsByYear[year] || [];

      const enabledMonthsForYear = enabledMonthsByYear[year] || [];
      const validMonthsForThisYear = selectedMonths.filter((month) =>
        enabledMonthsForYear.includes(month)
      );

      return (
        JSON.stringify([...currentYearMonths].sort()) ===
        JSON.stringify([...validMonthsForThisYear].sort())
      );
    });

    return (
      isMonthsForActiveYearNotInitialised ||
      onlyOneYearAvailable ||
      allYearsHaveSameSelection
    );
  };

  return (
    <div className="dashboard-filter__continuous-time-month-inclusion-control__tabs__container">
      <Tabs
        activeKey={activeYear}
        onSelect={(year) => onYearChange(parseInt(year))}
        className="dashboard-filter__continuous-time-month-inclusion-control__tabs"
      >
        {availableYears.map((year) => (
          <Tab key={year} eventKey={year} title={year}>
            <div className="dashboard-filter__continuous-time-month-inclusion-control__month-grid__action-button">
              <GVDSTextButton
                onClick={handleSelectAll}
                disabled={isSelectAllDisabled}
              >
                Select all
              </GVDSTextButton>
              <GVDSTextButton
                onClick={handleClearAll}
                disabled={isClearAllDisabled}
              >
                Clear all
              </GVDSTextButton>
            </div>
            <DashboardFilterMonthGridSelection
              enabledMonthNumbers={enabledMonthsByYear[year]}
              selectedMonthNumbers={inputIncludedMonthsByYear[year]}
              onMonthSelect={onMonthSelectionClick}
            />
            <div className="dashboard-filter__continuous-time-month-inclusion-control__action-button">
              <GVDSTextButton
                text="Apply to all years"
                onClick={handleApplyToAllYears}
                disabled={isApplyToAllYearsButtonDisabled()}
              />
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

const ContinuousTimeMonthInclusionControlView = ({
  startPeriod,
  endPeriod,
  includedMonthsByYear,
  onSave,
}) => {
  const availableYears = getYearRange(startPeriod, endPeriod);

  const [show, setShow] = useState(false);
  const handleToggle = (isShow) => {
    setShow(isShow);
    resetToPreviousSavedValue();
  };

  const [inputIncludedMonthsByYear, setInputIncludedMonthsByYear] =
    useState(includedMonthsByYear);
  const [activeYear, setActiveYear] = useState(availableYears[0]);

  useEffect(() => {
    setInputIncludedMonthsByYear(includedMonthsByYear);
    setActiveYear(availableYears[0]);
  }, [includedMonthsByYear, startPeriod, endPeriod]);

  const resetToPreviousSavedValue = () => {
    setInputIncludedMonthsByYear(includedMonthsByYear);
  };

  const handleMonthSelectionClick = (monthNumber) => {
    const newIncludedMonths = { ...inputIncludedMonthsByYear };
    let months;
    if (newIncludedMonths[activeYear] === undefined) {
      months = [];
    } else {
      months = newIncludedMonths[activeYear];
    }

    const isMonthRemoval = months.includes(monthNumber);

    if (isMonthRemoval) {
      newIncludedMonths[activeYear] = months.filter((m) => m !== monthNumber);
    } else {
      newIncludedMonths[activeYear] = [...months, monthNumber].sort(
        (a, b) => a - b
      );
    }

    setInputIncludedMonthsByYear(newIncludedMonths);
  };

  const handleSelectAll = () => {
    const newIncludedMonths = { ...inputIncludedMonthsByYear };
    newIncludedMonths[activeYear] = [...enabledMonthsByYear[activeYear]];
    setInputIncludedMonthsByYear(newIncludedMonths);
  };

  const handleClearAll = () => {
    const newIncludedMonths = { ...inputIncludedMonthsByYear };
    newIncludedMonths[activeYear] = [];
    setInputIncludedMonthsByYear(newIncludedMonths);
  };

  const handleApplyToAllYears = () => {
    if (!(activeYear in inputIncludedMonthsByYear)) {
      return;
    }

    const newIncludedMonthsByYear = {};
    const monthsToApply = [...inputIncludedMonthsByYear[activeYear]];

    availableYears.forEach((year) => {
      const enabledMonthsForYear = enabledMonthsByYear[year] || [];
      newIncludedMonthsByYear[year] = monthsToApply.filter((month) =>
        enabledMonthsForYear.includes(month)
      );
    });

    setInputIncludedMonthsByYear(newIncludedMonthsByYear);
  };

  const handleClose = () => {
    setShow(false);
    resetToPreviousSavedValue();
  };

  const handleSave = () => {
    onSave(inputIncludedMonthsByYear);
    setShow(false);
  };

  const enabledMonthsByYear = getEnabledMonthsByYear(startPeriod, endPeriod);

  const hasExcludedMonths = () => {
    for (const [year, selectedMonths] of Object.entries(includedMonthsByYear)) {
      const enabledMonthsForYear = enabledMonthsByYear[year] || [];
      const actualSelectedMonths = selectedMonths || [];

      const hasUnselectedMonth = enabledMonthsForYear.some(
        (month) => !actualSelectedMonths.includes(month)
      );

      if (hasUnselectedMonth) {
        return true;
      }
    }

    return false;
  };

  const hasChanges = () => {
    return availableYears.some((year) => {
      const savedMonths = includedMonthsByYear[year] || [];
      const inputMonths = inputIncludedMonthsByYear[year] || [];

      return (
        JSON.stringify([...savedMonths].sort()) !==
        JSON.stringify([...inputMonths].sort())
      );
    });
  };

  return (
    <Dropdown align="start" drop="down" show={show} onToggle={handleToggle}>
      <Dropdown.Toggle
        as={MonthInclusionTrigger}
        isFilterApplied={hasExcludedMonths()}
      />
      <Dropdown.Menu
        className="dashboard-filter__continuous-time-month-inclusion-control__dropdown__menu"
        flip={false}
      >
        <div className="dashboard-filter__continuous-time-month-inclusion-control__dropdown__heading">
          Months
          <InfoTooltip info={monthsInclusionInfoTooltipCopy} />
        </div>

        <MonthInclusionGridByYear
          inputIncludedMonthsByYear={inputIncludedMonthsByYear}
          onMonthSelectionClick={handleMonthSelectionClick}
          enabledMonthsByYear={enabledMonthsByYear}
          handleSelectAll={handleSelectAll}
          handleClearAll={handleClearAll}
          handleApplyToAllYears={handleApplyToAllYears}
          availableYears={availableYears}
          activeYear={activeYear}
          onYearChange={setActiveYear}
        />

        <div className="dashboard-filter__continuous-time-month-inclusion-control__dropdown__footer">
          {hasChanges() && (
            <div className="gvds-text--modal-warning-text">
              Changes won't be applied until you press Save
            </div>
          )}
          <Spacer />
          <GVDSButton
            onClick={handleClose}
            variant={buttonVariant.tertiary}
            text="Cancel"
          />
          <GVDSButton
            onClick={handleSave}
            variant={buttonVariant.primary}
            text="Save"
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ContinuousTimeMonthInclusionControlView;
