import { AnnouncementUtils } from "../../../gvds-components/AnnouncementBar/AnnouncementBarContext";

export const SUBSCRIPTION_FEATURE_STATUS = {
  HAS_ACCESS: "has access",
  HAS_SUBSCRIPTION_NO_ACCESS: "has subscription no access",
  NO_SUBSCRIPTION: "no subscription",
};

const hasCurrentSubscription = (inventorySubscriptions) => {
  const current = inventorySubscriptions
    ? inventorySubscriptions["current"] ?? []
    : [];

  const currentSubPaidCompleted = current.find(
    (s) =>
      !s.is_free_subscription &&
      !AnnouncementUtils.isSubscriptionUnpaid(s.payment_completion_status)
  );
  const currentSubPaymentPendingWithAccess = current.find(
    (s) =>
      !s.is_free_subscription &&
      AnnouncementUtils.isSubscriptionUnpaid(s.payment_completion_status) &&
      s.days_left_for_access > 0
  );
  const currentTrialSub = current.find((s) => s.is_free_subscription);

  return (
    current.length > 0 &&
    (currentSubPaidCompleted ||
      currentSubPaymentPendingWithAccess ||
      currentTrialSub)
  );
};

export const getSubscriptionFeatureStatus = (
  anyFeaturesRequired = [],
  isLoadingInventory,
  selectedTreeNode,
  userProfile
) => {
  const inventoryTreeNode = selectedTreeNode.get;

  if (isLoadingInventory || !inventoryTreeNode) {
    return SUBSCRIPTION_FEATURE_STATUS.NO_SUBSCRIPTION;
  }

  if (
    userProfile?.is_admin ||
    !anyFeaturesRequired ||
    anyFeaturesRequired.length === 0
  ) {
    return SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;
  }

  const inventoryDetails = inventoryTreeNode.nodeValue.value;
  const inventoryFeatures = inventoryDetails.features;

  const hasSubscription = hasCurrentSubscription(
    inventoryDetails.subscriptions
  );

  if (!hasSubscription) {
    return SUBSCRIPTION_FEATURE_STATUS.NO_SUBSCRIPTION;
  } else {
    const hasFeature = anyFeaturesRequired.find(
      (feature) => inventoryFeatures && inventoryFeatures.indexOf(feature) >= 0
    );

    return hasFeature
      ? SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS
      : SUBSCRIPTION_FEATURE_STATUS.HAS_SUBSCRIPTION_NO_ACCESS;
  }
};
