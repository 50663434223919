import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";

import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import PermissionsContext from "../../../context/PermissionsContext";
import withAuthentication from "../../HOC/withAuthentication";
import {
  ListPendingInviteTeamView,
  ListUsersTeamView,
} from "../../User/ListUsersView";
import LoadingSpinner from "../../common/LoadingSpinner";
import ContractService from "../../../services/ContractService";
import UserService from "../../../services/UserService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { AddUserWithRole } from "../../User/AddUsersWithRole";
import ApprovedDomains from "../../User/ApprovedDomains";
import ToastContext from "../../../context/ToastContext";
import SiteService from "../../../services/SiteService";
import { usePrevious } from "../../common/ReactHook";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import { isUserBillingAdminForInventory } from "../Billing/BillingSharedUtils";
import UserProfileContext from "../../../context/UserProfileContext";
import { Trans, useTranslation } from "react-i18next";

const TeamDetails = () => {
  const permissionsCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const [resourceDetails, setResourceDetails] = useState(null);
  const [resourceUsers, setResourceUsers] = useState(null);
  const [resourcePendingUsers, setResourcePendingUsers] = useState(null);
  const [resourceApprovedDomains, setResourceApprovedDomains] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);

  const [isAddingUser, setIsAddingUser] = useState(false);

  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();
  const selectedTreeNode = userInventory.selectedTreeNode.get;
  const inventoryDetails = selectedTreeNode?.nodeValue?.value;

  useEffect(() => {
    if (
      !permissionsCtx.isLoadingPermissions &&
      !userInventory.isLoadingInventory.get &&
      selectedInventory
    ) {
      if (selectedInventory.type === RESOURCES.SITE) {
        if (previousInventory !== selectedInventory || !resourceDetails) {
          getRolesForResource(RESOURCES.SITE);
          getSiteDetails(selectedInventory.id);
        }
      } else if (selectedInventory.type === RESOURCES.PORTFOLIO) {
        if (
          previousInventory?.type !== selectedInventory.type ||
          !resourceDetails
        ) {
          getRolesForResource(RESOURCES.CONTRACT);
          getContractDetails(userInventory.selectedContractId.get);
        }
      }
    }
  }, [selectedInventory, userInventory, permissionsCtx, resourceDetails]);

  const getSiteDetails = (siteId) => {
    setIsLoading(true);
    SiteService.getSite(siteId)
      .then((details) => {
        setResourceDetails(details);
        setResourceUsers(details.users);
        setResourcePendingUsers(details.pending_users);
        setResourceApprovedDomains(details.approved_domains);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load site</span>);
      })
      .finally(() => setIsLoading(false));
  };

  const getContractDetails = (contractId) => {
    setIsLoading(true);
    ContractService.getContractDetails(contractId)
      .then((details) => {
        setResourceDetails(details);
        setResourceUsers(details.users);
        setResourcePendingUsers(details.pendingUsers);
        setResourceApprovedDomains(details.approvedDomains);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load contract</span>);
      })
      .finally(() => setIsLoading(false));
  };

  const getRolesForResource = (roleForResource) => {
    setIsLoadingOptions(true);
    UserService.getAllRolesForResource(roleForResource)
      .then((availableRoles) => {
        setAvailableRoles(availableRoles);
      })
      .catch(() => {
        setAvailableRoles(null);
        toastContext.addFailToast(<span>Failed to load roles</span>);
      })
      .finally(() => setIsLoadingOptions(false));
  };

  const updateResourceDetails = (newResourceDetails) => {
    setResourceDetails(newResourceDetails);
    setResourceUsers(newResourceDetails.users);
    if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      setResourcePendingUsers(newResourceDetails.pendingUsers);
      setResourceApprovedDomains(newResourceDetails.approvedDomains);
    } else if (selectedInventory.type === RESOURCES.SITE) {
      setResourcePendingUsers(newResourceDetails.pending_users);
      setResourceApprovedDomains(newResourceDetails.approved_domains);
    }
  };

  let content;
  if (
    isLoading ||
    isLoadingOptions ||
    permissionsCtx.isLoadingPermissions ||
    userInventory.isLoadingInventory.get ||
    !resourceDetails ||
    !selectedInventory
  ) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    const resourceType = selectedInventory.type;
    let resourceId;
    let actionPermission;
    let editDomainsPermission;
    let updateResourceApprovedDomains;
    let deleteInvite;
    let removeUser;
    let editUserRole;
    let addUserWithRoleToResource;
    let billingAdmin = null;
    let canEditRole;

    if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      resourceId = userInventory.selectedContractId.get;
      actionPermission =
        permissionsCtx.permissions[PERMISSIONS.CONTRACT_USER_MANAGEMENT] ||
        isUserBillingAdminForInventory(user, inventoryDetails);
      editDomainsPermission =
        permissionsCtx.permissions[PERMISSIONS.CONTRACT_APPROVED_DOMAINS];
      editUserRole = (userId, roleId, asBillingAdmin) =>
        ContractService.editUserRole(
          resourceId,
          userId,
          roleId,
          asBillingAdmin
        );
      removeUser = (userId) =>
        ContractService.removeUserFromContract(resourceId, userId);
      deleteInvite = (inviteId) =>
        ContractService.deleteInviteFromContract(resourceId, inviteId);
      addUserWithRoleToResource = async (email, roleId, asBillingAdmin) => {
        setIsAddingUser(true);
        ContractService.addUserToContract(
          resourceId,
          email,
          roleId,
          asBillingAdmin
        )
          .then((updatedContractDetails) => {
            toastContext.addSuccessToast(
              <span>Invites successfully sent.</span>
            );
            updateResourceDetails(updatedContractDetails);
          })
          .catch((error) => {
            toastContext.addFailToast(
              <span>
                {error.response &&
                error.response.data &&
                (typeof error.response.data === "string" ||
                  error.response.data instanceof String)
                  ? error.response.data
                  : "Failed to add " + email + " to contract team"}
              </span>
            );
          })
          .finally(() => setIsAddingUser(false));
      };
      updateResourceApprovedDomains = (newApprovedDomains) => {
        setIsLoading(true);
        ContractService.updateContractApprovedDomains(
          resourceId,
          newApprovedDomains
        )
          .then((updatedContractDetails) => {
            setResourceApprovedDomains(updatedContractDetails.approvedDomains);
          })
          .catch((error) => {
            if (error.status === 400) {
              toastContext.addFailToast(<span>{error.data.message}</span>);
            } else {
              toastContext.addFailToast(
                <span>Failed to update contract approved domains.</span>
              );
            }
          })
          .finally(() => setIsLoading(false));
      };
      billingAdmin = resourceDetails.billingAdmin;
      canEditRole =
        permissionsCtx.permissions[PERMISSIONS.CONTRACT_USER_MANAGEMENT];
    } else if (selectedInventory.type === RESOURCES.SITE) {
      resourceId = selectedInventory.id;
      actionPermission =
        permissionsCtx.permissions[PERMISSIONS.SITE_USER_MANAGEMENT] ||
        isUserBillingAdminForInventory(user, inventoryDetails);
      editDomainsPermission =
        permissionsCtx.permissions[PERMISSIONS.SITE_APPROVED_DOMAINS];
      editUserRole = (userId, roleId, asBillingAdmin) =>
        SiteService.editUserRole(resourceId, userId, roleId, asBillingAdmin);
      removeUser = (userId) =>
        SiteService.removeUserFromSite(resourceId, userId);
      deleteInvite = (inviteId) =>
        SiteService.deleteInviteFromSite(resourceId, inviteId);
      addUserWithRoleToResource = async (email, roleId, asBillingAdmin) => {
        setIsAddingUser(true);
        SiteService.addUserToSite(resourceId, email, roleId, asBillingAdmin)
          .then((updatedSiteDetails) => {
            toastContext.addSuccessToast(
              <span>Invites successfully sent.</span>
            );
            updateResourceDetails(updatedSiteDetails);
          })
          .catch((error) => {
            toastContext.addFailToast(
              <span>
                {error.response &&
                error.response.data &&
                (typeof error.response.data === "string" ||
                  error.response.data instanceof String)
                  ? error.response.data
                  : "Failed to add " + email + " to site team"}
              </span>
            );
          })
          .finally(() => setIsAddingUser(false));
      };
      updateResourceApprovedDomains = (newApprovedDomains) => {
        setIsLoading(true);
        SiteService.updateSiteApprovedDomains(resourceId, newApprovedDomains)
          .then((updatedSiteDetails) => {
            setResourceApprovedDomains(updatedSiteDetails.approved_domains);
          })
          .catch((error) => {
            if (error.status === 400) {
              toastContext.addFailToast(<span>{error.data.message}</span>);
            } else {
              toastContext.addFailToast(
                <span>Failed to update site approved domains.</span>
              );
            }
          })
          .finally(() => setIsLoading(false));
      };
      billingAdmin = resourceDetails.billing_admin;
      canEditRole =
        permissionsCtx.permissions[PERMISSIONS.SITE_USER_MANAGEMENT];
    }
    content = (
      <ViewTeamDetails
        resourceType={resourceType}
        resourceUsers={resourceUsers}
        resourcePendingUsers={resourcePendingUsers}
        resourceApprovedDomains={resourceApprovedDomains}
        availableRoles={availableRoles}
        actionPermission={actionPermission}
        editDomainsPermission={editDomainsPermission}
        addUserWithRoleToResource={addUserWithRoleToResource}
        editUserRole={editUserRole}
        updateResourceDetails={updateResourceDetails}
        removeUser={removeUser}
        deleteInvite={deleteInvite}
        updateResourceApprovedDomains={updateResourceApprovedDomains}
        isAddingUser={isAddingUser}
        billingAdmin={billingAdmin}
        canEditRole={canEditRole}
      />
    );
  }

  return <Container fluid>{content}</Container>;
};

const ViewTeamDetails = ({
  resourceType,
  resourceUsers,
  resourcePendingUsers,
  resourceApprovedDomains,
  availableRoles,
  actionPermission,
  editDomainsPermission,
  addUserWithRoleToResource,
  editUserRole,
  updateResourceDetails,
  removeUser,
  deleteInvite,
  updateResourceApprovedDomains,
  isAddingUser,
  billingAdmin,
  canEditRole,
}) => {
  const { t } = useTranslation();

  const inventoryTypeDisplay =
    resourceType === RESOURCES.SITE ? t("shared.site") : t("shared.contract");

  return (
    <>
      <PageHeader>
        <PageHeader.Title>
          <h1>
            <Trans i18nKey="admin.team-management.page-title">
              {{ inventoryTypeDisplay }} Team
            </Trans>
          </h1>
        </PageHeader.Title>
      </PageHeader>
      {!billingAdmin && (
        <GVDSBanner
          title={t("admin.team-management.banner-title-assign-billing-admin")}
          variant={GVDSBanner.Variants.warning}
        >
          {t("admin.team-management.banner-message-assign-billing-admin")}
        </GVDSBanner>
      )}
      <section className="section-box">
        <h2>{t("admin.team-management.section-title-users")} ({resourceUsers.length})</h2>
        <ListUsersTeamView
          users={resourceUsers}
          availableRoles={availableRoles}
          onEdit={editUserRole}
          onDelete={removeUser}
          onSuccessResponse={updateResourceDetails}
          hasActionPermission={actionPermission}
          billingAdmin={billingAdmin}
          canEditRole={canEditRole}
        />
      </section>
      <section className="section-box">
        <h2>{t("admin.team-management.section-title-pending-invite")} ({resourcePendingUsers.length})</h2>
        <ListPendingInviteTeamView
          pendingUserInvites={resourcePendingUsers}
          onDelete={deleteInvite}
          onSuccessResponse={updateResourceDetails}
          hasActionPermission={actionPermission}
        />
      </section>
      {actionPermission && (
        <>
          <AddUserWithRole
            availableRoles={availableRoles}
            approvedDomains={resourceApprovedDomains}
            onAddingUser={addUserWithRoleToResource}
            isAddingUser={isAddingUser}
            hasBillingAdmin={!!billingAdmin}
          />
          <ApprovedDomains
            approvedDomains={resourceApprovedDomains}
            isAllowedToEdit={editDomainsPermission}
            onEditDone={updateResourceApprovedDomains}
          />
        </>
      )}
    </>
  );
};

export default withAuthentication(TeamDetails);
