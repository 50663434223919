import { performanceComparisonChartConfig } from "./PerformanceComparisonChartViewConfig";
import { scaleLinear } from "@visx/scale";

export class PerformanceComparisonChartUtils {
  static getTooltipsPosition(chartWidth, xAxisScaleTickCount) {
    const tooltipsPosition = [];
    const xAxisScaleTickWidth = chartWidth / xAxisScaleTickCount;

    for (let index = 0; index < xAxisScaleTickCount; index++) {
      tooltipsPosition.push(
        performanceComparisonChartConfig.lineChartLeftMarginInPx +
          index * xAxisScaleTickWidth +
          xAxisScaleTickWidth / 2
      );
    }

    return tooltipsPosition;
  }

  static getYAxisTickValues(minValue, maxValue, filledHeight) {
    const yAxisScale = scaleLinear({
      domain: [minValue, maxValue],
      range: [0, filledHeight],
    });
    let yAxisScaleTicks = yAxisScale.ticks();

    if (yAxisScaleTicks.length === 0) {
      return [];
    } else if (yAxisScaleTicks.length === 1) {
      return [yAxisScaleTicks[0]];
    } else {
      const firstTick = yAxisScaleTicks[0];
      const secondTick = yAxisScaleTicks[1];
      let tickStep = secondTick - firstTick;
      let lastTick = yAxisScaleTicks[yAxisScaleTicks.length - 1];

      while (lastTick <= maxValue) {
        lastTick += tickStep;
        yAxisScaleTicks.push(lastTick);
      }

      tickStep = lastTick / performanceComparisonChartConfig.numTicks;
      for (let index = 0; index < yAxisScaleTicks.length; index++) {
        if (yAxisScaleTicks[index] > tickStep) {
          tickStep = yAxisScaleTicks[index - 1];
          break;
        }
      }

      let tickValue = firstTick;
      yAxisScaleTicks = [];
      while (tickValue <= lastTick) {
        yAxisScaleTicks.push(tickValue);
        tickValue += tickStep;
      }
    }

    return yAxisScaleTicks;
  }
}
