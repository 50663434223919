import React from "react";

import { BEST_PRACTICE_QUESTION_STATUS } from "../../../config/constants";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../services/UtilsService";

const statusToColorMap = {
  [BEST_PRACTICE_QUESTION_STATUS.ACTIVE]: StatusLabel.Colors.green,
  [BEST_PRACTICE_QUESTION_STATUS.INACTIVE]: StatusLabel.Colors.gray,
};

const BestPracticeStatusBadge = ({ status }) => {
  let labelColor = statusToColorMap[status];

  return (
    <StatusLabel color={labelColor}>
      {StringUtils.capitaliseWord(status)}
    </StatusLabel>
  );
};

export default BestPracticeStatusBadge;
