import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FEATURES } from "../../../config/constants";
import SummaryService from "../../../services/SummaryService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { REPORT_INITIATIVES_REPORT } from "../../../config/ROUTES_NAME";
import { getURLWithPageParams } from "../../common/QueryHandler";
import moment from "moment";
import ToastContext from "../../../context/ToastContext";
import UserProfileContext from "../../../context/UserProfileContext";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import { FeatureLockWidgetContent } from "./BaseWidgetEmptyContent";
import {
  IconClockHour3,
  IconCoin,
  IconHeartHandshake,
} from "@tabler/icons-react";
import BaseWidgetGenericSummaryItem, {
  BaseWidgetGenericSeparator,
} from "./BaseWidgetGenericSummaryItem";
import { isRequestAborted } from "../../../services/HttpService";
import BaseWidgetPeriodControl from "./BaseWidgetPeriodControl";
import BaseWidgetHeadingTooltip from "./BaseWidgetHeadingTooltip";
import { useTranslation } from "react-i18next";

const defaultEndPeriod = new moment()
  .subtract(1, "month")
  .endOf("month")
  .toDate();
const defaultStartPeriod = moment(defaultEndPeriod).startOf("year").toDate();

const SocialImpactWidget = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.DATA_MANAGEMENT__INITIATIVE],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const locked =
    subscriptionFeatureStatus !== SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const [start, setStart] = useState(defaultStartPeriod);
  const [end, setEnd] = useState(defaultEndPeriod);

  const [impactSummary, setImpactSummary] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const abortControllerRef = useRef(null);

  useEffect(() => {
    loadSocialImpactSummary(start, end);
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [
    userInventory.isLoadingInventory.get,
    userInventory.selectedInventory.get,
  ]);

  const loadSocialImpactSummary = (start, end) => {
    const inventory = userInventory.selectedInventory.get;
    if (!userInventory.isLoadingInventory.get && inventory && inventory.id) {
      setIsLoading(true);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      SummaryService.getSocialImpactSummary(
        inventory.type,
        inventory.id,
        start,
        end,
        abortControllerRef.current.signal
      )
        .then((data) => {
          setImpactSummary(data);
        })
        .catch((e) => {
          if (isRequestAborted(e)) {
            return;
          }

          toastContext.addFailToast(
            <span>Failed to load social impact summary.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }
  };

  const redirectToInitiativeReportPage = () => {
    const reportParams = { ...impactSummary, start, end };
    history.push(
      getURLWithPageParams(location, REPORT_INITIATIVES_REPORT, reportParams)
    );
  };

  const onPeriodSelectorSaved = (newStart, newEnd) => {
    loadSocialImpactSummary(newStart, newEnd);
  };

  let content;
  if (locked) {
    content = (
      <FeatureLockWidgetContent
        subscriptionFeatureStatus={subscriptionFeatureStatus}
      />
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <div
        className="base-widget__generic-summary__layout-container widget--social-impact__content"
        onClick={redirectToInitiativeReportPage}
      >
        <BaseWidgetGenericSummaryItem
          label={t("landing-page.widget-social-impact.label-volunteer-hours")}
          icon={IconClockHour3}
          value={impactSummary.volunteer_hours}
          unit={t("landing-page.widget-social-impact.unit-hours")}
        />
        <BaseWidgetGenericSeparator />
        <BaseWidgetGenericSummaryItem
          label={t("landing-page.widget-social-impact.label-inkind-donation")}
          icon={IconHeartHandshake}
          value={impactSummary.inkind_donation}
          unit={impactSummary?.currency_id}
        />
        <BaseWidgetGenericSeparator />
        <BaseWidgetGenericSummaryItem
          label={t("landing-page.widget-social-impact.label-cash-contribution")}
          icon={IconCoin}
          value={impactSummary.cash_contributions}
          unit={impactSummary?.currency_id}
        />
      </div>
    );
  }
  return (
    <div className="base-widget__container">
      <div className="base-widget__heading">
        <div className="base-widget__heading__title-text">
          {t("landing-page.widget-social-impact.title")}{" "}
          <BaseWidgetHeadingTooltip
            info={t("landing-page.widget-social-impact.tooltip")}
          />
        </div>
        <BaseWidgetPeriodControl
          start={start}
          end={end}
          setStart={setStart}
          setEnd={setEnd}
          onSave={onPeriodSelectorSaved}
        />
      </div>
      {content}
    </div>
  );
};

export default SocialImpactWidget;
