import React, { useContext, useState } from "react";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import ViewAllMarketPurchaseOffsetRecords from "./MarketPurchaseOffsets/ViewAllMarketPurchaseOffsetRecords";
import ViewAllEnvironmentalDataRecords from "./ViewAllEnvironmentalDataRecords";
import Spacer from "../../../gvds-components/Layout/Spacer";
import { PERMISSIONS } from "../../../config/constants";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSDropdownToggle from "../../../gvds-components/Buttons/GVDSDropdownToggle";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";
import PermissionsContext from "../../../context/PermissionsContext";
import {
  getDefaultMonthRangeEnd,
  getDefaultMonthRangeStart,
} from "../../common/MonthRangeSelector";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import { ENVIRONMENTAL_DATA_BULK_INPUT } from "../../../config/ROUTES_NAME";
import { useHistory, useLocation } from "react-router-dom";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import { ButtonLoadingSpinner } from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import { Trans, useTranslation } from "react-i18next";

const ENVIRONMENTAL_ALL_RECORDS_VIEW_STATE = {
  USAGE_METERS: "USAGE_METERS",
  MARKET_OFFSETS: "MARKET_OFFSETS",
};
const ViewAllEnvironmentalRecords = ({
  lastUpdate,
  onRecordsUpdate,
  datasets,
  onMeterInput,
  trackers,
  onTrackerInput,
  onTrackerRecordUpdate,
}) => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);
  const history = useHistory();
  const location = useLocation();

  const [allRecordsViewState, setAllRecordsViewState] = useState(
    ENVIRONMENTAL_ALL_RECORDS_VIEW_STATE.USAGE_METERS
  );

  const [recordPeriodStart, setRecordPeriodStart] = useState(
    getDefaultMonthRangeStart()
  );
  const [recordPeriodEnd, setRecordPeriodEnd] = useState(
    getDefaultMonthRangeEnd()
  );

  const [isMultipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const [totalDataLengthCanBeDeleted, setTotalDataLengthCanBeDeleted] =
    useState(0);
  const [triggerDeleteAllTimestamp, setTriggerDeleteAllTimestamp] =
    useState(null);

  const triggerDeleteAll = () => {
    setTriggerDeleteAllTimestamp(new Date());
  };
  const resetTriggerDeleteAll = () => {
    setTriggerDeleteAllTimestamp(null);
  };

  const [isDownloadExcelLoading, setIsDownloadExcelLoading] = useState(false);
  const [triggerDownloadExcelTimestamp, setTriggerDownloadExcelTimestamp] =
    useState(null);

  const triggerDownloadExcel = () => {
    setTriggerDownloadExcelTimestamp(new Date());
  };
  const resetTriggerDownloadExcel = () => {
    setTriggerDownloadExcelTimestamp(null);
  };

  const goToBulkInput = () => {
    history.push(
      getRedirectURLWithCurrentParam(ENVIRONMENTAL_DATA_BULK_INPUT, location)
    );
  };

  let content;
  let sideInputButton;
  switch (allRecordsViewState) {
    case ENVIRONMENTAL_ALL_RECORDS_VIEW_STATE.USAGE_METERS:
      content = (
        <ViewAllEnvironmentalDataRecords
          lastUpdate={lastUpdate}
          datasets={datasets}
          onRecordsUpdate={onRecordsUpdate}
          startPeriod={recordPeriodStart}
          setStartPeriod={setRecordPeriodStart}
          endPeriod={recordPeriodEnd}
          setEndPeriod={setRecordPeriodEnd}
          isMultipleDeleteMode={isMultipleDeleteMode}
          setMultipleDeleteMode={setMultipleDeleteMode}
          setTotalDataLengthCanBeDeleted={setTotalDataLengthCanBeDeleted}
          triggerDeleteAllTimestamp={triggerDeleteAllTimestamp}
          triggerDownloadExcelTimestamp={triggerDownloadExcelTimestamp}
          setIsDownloadExcelLoading={setIsDownloadExcelLoading}
        />
      );
      sideInputButton = !permissionCtx.isLoadingPermissions &&
        permissionCtx.permissions[PERMISSIONS.ENVIRONMENTAL_RECORD_CREATE] && (
          <Dropdown className="guided-tour-target__environment-input-in-all-records">
            <Dropdown.Toggle as={GVDSDropdownToggle}>
              {t("data-management.environmental.usage.input-button.label")}
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              {
                <Dropdown.Item onClick={() => onMeterInput(null, datasets)}>
                  <GVDSIcon Icon={IconCirclePlus} />{" "}
                  {t("data-management.environmental.usage.input-button.single")}
                </Dropdown.Item>
              }
              {
                <Dropdown.Item onClick={() => goToBulkInput()}>
                  <GVDSIcon Icon={IconCirclePlus} />{" "}
                  {t("data-management.environmental.usage.input-button.bulk")}
                </Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        );
      break;
    case ENVIRONMENTAL_ALL_RECORDS_VIEW_STATE.MARKET_OFFSETS:
      content = (
        <ViewAllMarketPurchaseOffsetRecords
          lastUpdate={lastUpdate}
          onRecordsUpdate={onRecordsUpdate}
          trackers={trackers}
          onTrackerRecordUpdate={onTrackerRecordUpdate}
          startPeriod={recordPeriodStart}
          setStartPeriod={setRecordPeriodStart}
          endPeriod={recordPeriodEnd}
          setEndPeriod={setRecordPeriodEnd}
          isMultipleDeleteMode={isMultipleDeleteMode}
          setMultipleDeleteMode={setMultipleDeleteMode}
          setTotalDataLengthCanBeDeleted={setTotalDataLengthCanBeDeleted}
          triggerDeleteAllTimestamp={triggerDeleteAllTimestamp}
          triggerDownloadExcelTimestamp={triggerDownloadExcelTimestamp}
          setIsDownloadExcelLoading={setIsDownloadExcelLoading}
        />
      );
      sideInputButton = !permissionCtx.isLoadingPermissions &&
        permissionCtx.permissions[PERMISSIONS.ENVIRONMENTAL_RECORD_CREATE] && (
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => onTrackerInput(null, trackers)}
            text={t(
              "data-management.environmental.market-purchase.input-button.single"
            )}
          />
        );
      break;
    default:
  }

  return (
    <>
      <GVFormGroup>
        <Form.Label>
          {t("data-management.environmental.all-records.view-option-label")}
        </Form.Label>
        <div className="d-flex">
          <div>
            <Form.Check
              id="usage-meters"
              label={t("data-management.environmental.all-records.view-usage")}
              type="radio"
              checked={
                allRecordsViewState ===
                ENVIRONMENTAL_ALL_RECORDS_VIEW_STATE.USAGE_METERS
              }
              onChange={() => {
                setAllRecordsViewState(
                  ENVIRONMENTAL_ALL_RECORDS_VIEW_STATE.USAGE_METERS
                );
                resetTriggerDeleteAll();
                resetTriggerDownloadExcel();
              }}
            />
            <Form.Check
              id="market-offsets"
              label={t(
                "data-management.environmental.all-records.view-market-purchase"
              )}
              type="radio"
              checked={
                allRecordsViewState ===
                ENVIRONMENTAL_ALL_RECORDS_VIEW_STATE.MARKET_OFFSETS
              }
              onChange={() => {
                setAllRecordsViewState(
                  ENVIRONMENTAL_ALL_RECORDS_VIEW_STATE.MARKET_OFFSETS
                );
                resetTriggerDeleteAll();
                resetTriggerDownloadExcel();
              }}
            />
          </div>
          <Spacer />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.ENVIRONMENTAL_RECORD_DELETE
            ] && (
              <div className="ms-auto">
                {!isMultipleDeleteMode && (
                  <Dropdown>
                    <Dropdown.Toggle as={OtherActionDropdownToggle} />

                    <Dropdown.Menu>
                      <Dropdown.Item
                        key="delete-multiple"
                        href="#"
                        onClick={() => setMultipleDeleteMode(true)}
                        disabled={totalDataLengthCanBeDeleted === 0}
                      >
                        <span className="color-red">
                          {t(
                            "data-management.shared.button-delete-multiple-records"
                          )}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        key="delete-all"
                        href="#"
                        onClick={() => triggerDeleteAll()}
                        disabled={totalDataLengthCanBeDeleted === 0}
                      >
                        <span className="color-red">
                          <Trans i18nKey="data-management.shared.button-delete-all-records">
                            Delete all ({{ totalDataLengthCanBeDeleted }})
                            records
                          </Trans>
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            )}
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.ENVIRONMENTAL_RECORD_VIEW
            ] && (
              <GVDSButton
                className={`me-2 ${
                  isDownloadExcelLoading ? "gvds-button-is-loading" : ""
                }`}
                text={
                  isDownloadExcelLoading ? (
                    <ButtonLoadingSpinner />
                  ) : (
                    t("data-management.shared.button-download-as-excel")
                  )
                }
                variant={buttonVariant.secondary}
                disabled={isDownloadExcelLoading}
                onClick={triggerDownloadExcel}
              />
            )}
          {sideInputButton}
        </div>
      </GVFormGroup>
      {content}
    </>
  );
};

export default ViewAllEnvironmentalRecords;
