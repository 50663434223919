import React, { useEffect, useRef, useState } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const PopoverOnTruncated = ({ content, contentClassName }) => {
  const descriptionElRef = useRef();

  const [isTruncated, setIsTruncated] = useState(false);

  const compareSize = () => {
    const isWidthScrollableWithoutTruncation =
      descriptionElRef.current.scrollWidth >
      descriptionElRef.current.clientWidth;

    const isHeightScrollableWithoutTruncation =
      descriptionElRef.current.scrollHeight >
      descriptionElRef.current.clientHeight;

    setIsTruncated(
      isWidthScrollableWithoutTruncation || isHeightScrollableWithoutTruncation
    );
  };

  useEffect(() => {
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  useEffect(() => {
    compareSize();
  }, [content]);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        isTruncated ? (
          <Popover>
            <Popover.Body>{content}</Popover.Body>
          </Popover>
        ) : (
          <div style={{ display: "none" }} />
        )
      }
    >
      <div className={contentClassName} ref={descriptionElRef}>
        {content}
      </div>
    </OverlayTrigger>
  );
};

export default PopoverOnTruncated;
