import React, { useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CertificationContentCategories from "./Category/CertificationContentCategories";
import CertificationContentCriteria from "./Criteria/CertificationContentCriteria";
import { CertificationCategoryAdminService } from "../../../services/PortalAdmin/Certification/CertificationCategoryAdminService";
import ToastContext from "../../../context/ToastContext";

const CertificationContentManager = ({ certification }) => {
  const toastContext = useContext(ToastContext);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const reloadCategories = () => {
    CertificationCategoryAdminService.getCategories(certification.id)
      .then((response) => {
        setCategories(response);
        setSelectedCategory(
          response.find((category) => category.id === selectedCategory.id)
        );
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load categories</span>);
      });
  };

  return (
    <>
      <Row>
        <Col md={3}>
          <CertificationContentCategories
            certificationId={certification?.id}
            categories={categories}
            setCategories={setCategories}
            selectedCategory={selectedCategory}
            onSelectCategory={setSelectedCategory}
          />
        </Col>
        <Col md={9}>
          <CertificationContentCriteria
            certificationId={certification?.id}
            selectedCategory={selectedCategory}
            allCategories={categories}
            reloadCategories={reloadCategories}
          />
        </Col>
      </Row>
    </>
  );
};

export default CertificationContentManager;
