import {
  performanceTimelineChartConfig,
  performanceTimelinePeriodGroupingEnum,
} from "./PerformanceTimelineViewConfig";
import _ from "lodash";
import {
  OTHERS_LEGEND_LABEL,
  PerformanceTimelineChartDataModel,
} from "../../Models/DashboardModels";
import moment from "moment";

export class PerformanceTimelineChartUtils {
  static getYAxisScaleMaxValue = (chartData) => {
    let maxValue = 0;

    chartData.forEach((datum) => {
      if (datum.periodTotalValue > maxValue) {
        maxValue = datum.periodTotalValue;
      }
    });

    return maxValue;
  };

  static getOrdinalScale(start, stop, count) {
    if (count === 1) {
      const midPoint =
        start +
        (stop - start - performanceTimelineChartConfig.minimapBarWidthInPx) / 2;
      return [midPoint];
    }

    const tickCountExcludeStart = count - 1;
    const step =
      (stop - start - performanceTimelineChartConfig.minimapBarWidthInPx) /
      tickCountExcludeStart;

    const retVal = [start];
    for (let i = 0; i < tickCountExcludeStart; i++) {
      retVal.push(start + (i + 1) * step);
    }

    return retVal;
  }

  static getSVGPathData = (
    xPosition,
    yPosition,
    barWidth,
    barHeight,
    radius
  ) => {
    if (radius > 0) {
      return `
    M ${xPosition + radius} ${yPosition}
    H ${barWidth + xPosition - radius}
    A ${radius} ${radius} 0 0 1 ${barWidth + xPosition} ${
        yPosition + radius
      }        
    V ${barHeight + yPosition}    
    H ${xPosition}
    V ${yPosition + radius}
    A ${radius} ${radius} 0 0 1 ${xPosition + radius} ${yPosition}
    Z
  `;
    } else {
      return `
    M ${xPosition} ${yPosition}
    H ${barWidth + xPosition}
    V ${barHeight + yPosition}
    H ${xPosition}
    Z
  `;
    }
  };

  static getQuarterDate(quarter) {
    const quarterValues = quarter.split(" ");
    const year = parseInt(quarterValues[0]);
    const month = parseInt(quarterValues[1].substring(1, 2)) * 3 - 2;

    return new Date(year, month - 1, 1);
  }

  static groupData(inputDataModels, classificationValues, displayMode) {
    if (displayMode === performanceTimelinePeriodGroupingEnum.YEAR) {
      const dataByYear = _.groupBy(inputDataModels, (d) =>
        d.period.getFullYear()
      );
      const retVal = [];

      Object.keys(dataByYear).forEach((year) => {
        const yearData = dataByYear[year];
        const yearValuesByClassificationValue = {};
        classificationValues.forEach((key) => {
          yearValuesByClassificationValue[key] = yearData.reduce(
            (total, data) => total + (data.valueByType[key] ?? 0),
            0
          );
        });

        const divisorValue = yearData.every(
          (data) => data.divisorValue === null
        )
          ? null
          : yearData.reduce(
              (divisorTotal, data) => divisorTotal + (data.divisorValue ?? 0),
              0
            );

        if (divisorValue !== null) {
          classificationValues.forEach((key) => {
            if (divisorValue === 0) {
              yearValuesByClassificationValue[key] = null;
            } else {
              yearValuesByClassificationValue[key] =
                yearValuesByClassificationValue[key] / divisorValue;
            }
          });
        }

        retVal.push(
          new PerformanceTimelineChartDataModel(
            new Date(parseInt(year), 1, 1),
            year,
            yearValuesByClassificationValue
          )
        );
      });

      return retVal;
    } else if (displayMode === performanceTimelinePeriodGroupingEnum.QUARTER) {
      const dataByQuarter = _.groupBy(inputDataModels, (d) => {
        const dateObj = new Date(d.period);
        const year = dateObj.getFullYear();
        const quarter = Math.floor(dateObj.getMonth() / 3) + 1;

        return `${year} Q${quarter}`;
      });
      const retVal = [];

      Object.keys(dataByQuarter).forEach((quarter) => {
        const quarterData = dataByQuarter[quarter];
        const quarterValuesByClassificationValue = {};
        classificationValues.forEach((key) => {
          quarterValuesByClassificationValue[key] = quarterData.reduce(
            (total, data) => total + (data.valueByType[key] ?? 0),
            0
          );
        });

        const divisorValue = quarterData.every(
          (data) => data.divisorValue === null
        )
          ? null
          : quarterData.reduce(
              (divisorTotal, data) => divisorTotal + (data.divisorValue ?? 0),
              0
            );

        if (divisorValue !== null) {
          classificationValues.forEach((key) => {
            if (divisorValue === 0) {
              quarterValuesByClassificationValue[key] = null;
            } else {
              quarterValuesByClassificationValue[key] =
                quarterValuesByClassificationValue[key] / divisorValue;
            }
          });
        }

        retVal.push(
          new PerformanceTimelineChartDataModel(
            PerformanceTimelineChartUtils.getQuarterDate(quarter),
            quarter,
            quarterValuesByClassificationValue
          )
        );
      });

      return retVal;
    } else if (displayMode === performanceTimelinePeriodGroupingEnum.MONTH) {
      const dataByMonth = _.groupBy(inputDataModels, (d) =>
        moment(d.period).format("MMM 'YY")
      );
      const retVal = [];

      Object.keys(dataByMonth).forEach((month) => {
        const monthData = dataByMonth[month];
        const monthValuesByClassificationValue = {};
        classificationValues.forEach((key) => {
          monthValuesByClassificationValue[key] = monthData.reduce(
            (total, data) => total + (data.valueByType[key] ?? 0),
            0
          );
        });

        const divisorValue = monthData.every(
          (data) => data.divisorValue === null
        )
          ? null
          : monthData.reduce(
              (divisorTotal, data) => divisorTotal + (data.divisorValue ?? 0),
              0
            );

        if (divisorValue !== null) {
          classificationValues.forEach((key) => {
            if (divisorValue === 0) {
              monthValuesByClassificationValue[key] = null;
            } else {
              monthValuesByClassificationValue[key] =
                monthValuesByClassificationValue[key] / divisorValue;
            }
          });
        }

        retVal.push(
          new PerformanceTimelineChartDataModel(
            moment(month, "MMM 'YY").toDate(),
            month,
            monthValuesByClassificationValue
          )
        );
      });

      return retVal;
    } else {
      return inputDataModels;
    }
  }

  static getCalculatedChartData = (chartData, displayedKeys) => {
    const singleValueKeys = displayedKeys.filter(
      (key) => key !== OTHERS_LEGEND_LABEL
    );

    const displayedChartData = chartData.map((datum) => {
      const updatedValues = {};
      let othersValue = null;

      Object.entries(datum.valueByType).forEach(([key, value]) => {
        if (singleValueKeys.includes(key)) {
          updatedValues[key] = value;
        } else {
          if (othersValue === null) {
            othersValue = 0;
          }
          othersValue += value;
        }
      });

      if (othersValue !== null) {
        updatedValues[OTHERS_LEGEND_LABEL] = othersValue;
      }

      return new PerformanceTimelineChartDataModel(
        datum.period,
        datum.groupName,
        updatedValues
      );
    });

    return displayedChartData;
  };

  static getTableRowData(chartData, types, periods) {
    const tableRowData = [];
    const valuesByPeriod = _.groupBy(chartData, (d) => d.groupName);

    types.forEach((type) => {
      const rowData = [type];

      periods.forEach((period) => {
        rowData.push(valuesByPeriod[period][0].valueByType[type]);
      });

      tableRowData.push(rowData);
    });

    return tableRowData;
  }
}
