import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import BestPracticeAdminService from "../../../services/BestPracticeAdminService";
import ToastContext from "../../../context/ToastContext";
import { POSITION } from "../../../config/constants";
import GVFormGroup from "../../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";

const MoveBestPracticeDialog = ({
  show,
  onHide,
  onMoveSuccess,
  questionToBeMoved,
  allQuestions,
}) => {
  const toastContext = useContext(ToastContext);

  const [isValidated, setIsValidated] = useState(false);

  const [position, setPosition] = useState(POSITION.BEFORE);
  const [questionToMoveTo, setQuestionToMoveTo] = useState(null);

  const moveQuestion = () => {
    setIsValidated(true);

    if (questionToMoveTo) {
      BestPracticeAdminService.moveBestPracticeQuestion(
        questionToBeMoved,
        questionToMoveTo,
        position
      ).then(() => {
        toastContext.addSuccessToast(<span>Question was moved</span>);
        onMoveSuccess();
        onHide();
      });
    }
  };

  return (
    <GVDSModal
      show={show}
      onHide={onHide}
      title="Move this question to"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <GVFormGroup>
          <Form.Label>Position</Form.Label>
          <Form.Check
            id="before"
            label="before"
            custom
            type="radio"
            checked={position === POSITION.BEFORE}
            onChange={() => setPosition(POSITION.BEFORE)}
          />
          <Form.Check
            id="after"
            label="after"
            custom
            type="radio"
            checked={position === POSITION.AFTER}
            onChange={() => {
              setPosition(POSITION.AFTER);
            }}
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Question</Form.Label>
          <GVDSFormSingleSelect
            className="question-to-move-to"
            placeholder="Search for a question"
            isSearchable={true}
            options={allQuestions.map((q) => {
              return { value: q, label: q.question_text };
            })}
            value={
              questionToMoveTo
                ? {
                    value: questionToMoveTo,
                    label: questionToMoveTo.question_text,
                  }
                : null
            }
            onSelect={(selected) => {
              setQuestionToMoveTo(selected.value);
            }}
            statusMetadata={
              isValidated && !questionToMoveTo
                ? FormFieldStatusMetadata.getError("Please select a question")
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onHide}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={moveQuestion}
          text="Move Question"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default MoveBestPracticeDialog;
