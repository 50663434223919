import axios from "axios";
import { API_URL } from "../../../config/api-config";

export default class MarketSegmentService {
  static GetAllMarketSegments = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/market_segments"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
