import intersection from "lodash/intersection";

export const resetFilterKeys = (filterKeys, keyToOptionsMap) => {
	Object.keys(filterKeys).forEach((key) => {
		let newFilterValue = [];

		if (filterKeys[key] && filterKeys[key].length > 0) {
			newFilterValue = intersection(keyToOptionsMap[key], filterKeys[key]);
		}

		filterKeys[key] = newFilterValue;
	});

	return filterKeys;
};