export const FEATURE_FLAG_SHOW_GLTR_ON_SIGNUP =
  process.env.REACT_APP_FEATURE_FLAG_SHOW_GLTR_ON_SIGNUP;

export const FEATURE_FLAG_UNDER_MAINTENANCE =
  process.env.REACT_APP_FEATURE_FLAG_UNDER_MAINTENANCE?.toLowerCase() ===
  "true";
export const FEATURE_FLAG_MAINTENANCE_END_TIME =
  process.env.REACT_APP_FEATURE_FLAG_MAINTENANCE_END_TIME;

export const FEATURE_FLAG_ANNUAL_SURVEY_2023_URL =
  process.env.REACT_APP_FEATURE_FLAG_ANNUAL_SURVEY_2023_URL;

export const FEATURE_FLAG_NEW_DESIGN_POP_UP_LAST_SHOW_DATE =
  process.env.REACT_APP_FEATURE_FLAG_NEW_DESIGN_POP_UP_LAST_SHOW_DATE;
