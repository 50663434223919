import React, { useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const TooltipPersistentOnHover = ({
  triggerContent,
  triggerClassName,
  tooltipContent,
  placement = "auto",
}) => {
  const [show, setShow] = useState(false);

  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  return (
    <>
      <OverlayTrigger
        show={show}
        placement={placement}
        overlay={
          <Popover
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            <Popover.Body>{tooltipContent}</Popover.Body>
          </Popover>
        }
      >
        <div
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={triggerClassName}
        >
          {triggerContent}
        </div>
      </OverlayTrigger>
    </>
  );
};

export const TextTooltipPersistentOnHover = ({
                                    triggerContent,
                                    triggerClassName,
                                    tooltipContent,
                                    placement = "auto",
                                  }) => {
  const [show, setShow] = useState(false);

  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  return (
    <>
      <OverlayTrigger
        show={show}
        placement={placement}
        overlay={
          <Popover
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            <Popover.Body>{tooltipContent}</Popover.Body>
          </Popover>
        }
      >
        <span
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={"text-tooltip-trigger " + (triggerClassName ? triggerClassName : "")}
        >
          {triggerContent}
        </span>
      </OverlayTrigger>
    </>
  );
};

export default TooltipPersistentOnHover;
