import React from "react";
import GVDSButton, { buttonVariant } from "./GVDSButton";
import { IconChevronDown } from "@tabler/icons-react";
import GVDSIcon from "../Icons/GVDSIcon";

const GVDSDropdownToggle = React.forwardRef(({ className, children, onClick }, ref) => {
  return (
    <GVDSButton
      ref={ref}
      className={`with-trailing-icon ${className ?? ""}`}
      variant={buttonVariant.primary}
      onClick={onClick}
      text={
        <>
          {children}
          <GVDSIcon Icon={IconChevronDown} className="dropdown-button-icon" />
        </>
      }
    />
  );
});

export default GVDSDropdownToggle;
