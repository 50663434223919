import { matchPath } from "react-router-dom";
import {
  BEST_PRACTICES_ASSESS,
  BEST_PRACTICES_GUIDANCE,
  BEST_PRACTICES_GUIDANCE_ARTICLE,
  CERTIFICATION_CONTENT_PAGE,
  CERTIFICATION_CONTENT_PAGE_REQUIREMENT,
  CERTIFICATION_HOMEPAGE,
  CERTIFICATIONS,
  DASHBOARDS,
  ENVIRONMENTAL_DATA,
  ENVIRONMENTAL_DATA_BULK_INPUT,
  ENVIRONMENTAL_DATA_METER,
  ENVIRONMENTAL_DATA_SETUP,
  GOALS_AND_TARGETS,
  GSTC,
  MARKET_PURCHASE_OFFSETS_TRACKER,
  OPERATIONAL_DATA,
  OPERATIONAL_DATA_BULK_INPUT,
  OPERATIONAL_DATA_METER,
  OPERATIONAL_DATA_SETUP,
  OVERVIEW_DATA,
  OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT,
  OVERVIEW_DATA_OPERATIONAL_BULK_INPUT,
  PEOPLE_DATA,
  PEOPLE_DATA_METER,
  PEOPLE_DATA_SETUP,
  POLICY_TRACKER,
  POLICY_TRACKER_DETAILS,
  REPORT_BEST_PRACTICE_REPORT,
  REPORT_HCMI_METRIC,
  REPORT_INITIATIVES_REPORT,
  REPORT_PERFORMANCE_REPORT,
  REPORT_RISK_ASSESSMENT_REPORT,
  REPORT_STANDARD_REPORT,
  SURVEYS,
  SURVEYS_CREATE,
  VIEW_ALL_INITIATIVES,
  VIEW_SURVEY,
  VIEW_TARGET_DETAILS,
} from "../../../config/ROUTES_NAME";
import { FEATURES } from "../../../config/constants";

const FeaturesRequiredByPath = {
  [DASHBOARDS]: [FEATURES.DASHBOARDS],
  [OVERVIEW_DATA]: [FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL],
  [OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [OVERVIEW_DATA_OPERATIONAL_BULK_INPUT]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [ENVIRONMENTAL_DATA_SETUP]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [ENVIRONMENTAL_DATA_METER]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [MARKET_PURCHASE_OFFSETS_TRACKER]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [ENVIRONMENTAL_DATA_BULK_INPUT]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [ENVIRONMENTAL_DATA]: [FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL],
  [OPERATIONAL_DATA_SETUP]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [OPERATIONAL_DATA_METER]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [OPERATIONAL_DATA]: [FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL],
  [OPERATIONAL_DATA_BULK_INPUT]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [PEOPLE_DATA]: [FEATURES.DATA_MANAGEMENT__PEOPLE],
  [PEOPLE_DATA_SETUP]: [FEATURES.DATA_MANAGEMENT__PEOPLE],
  [PEOPLE_DATA_METER]: [FEATURES.DATA_MANAGEMENT__PEOPLE],

  [MARKET_PURCHASE_OFFSETS_TRACKER]: [
    FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  ],
  [VIEW_ALL_INITIATIVES]: [FEATURES.DATA_MANAGEMENT__INITIATIVE],
  [SURVEYS]: [FEATURES.SURVEY__ANSWER, FEATURES.SURVEY__CREATE],
  [SURVEYS_CREATE]: [FEATURES.SURVEY__CREATE],
  [VIEW_SURVEY]: [FEATURES.SURVEY__ANSWER, FEATURES.SURVEY__CREATE],

  [BEST_PRACTICES_ASSESS]: [FEATURES.BEST_PRACTICES__ASSESSMENT],
  [BEST_PRACTICES_GUIDANCE]: [FEATURES.BEST_PRACTICES__GUIDANCE],
  [BEST_PRACTICES_GUIDANCE_ARTICLE]: [FEATURES.BEST_PRACTICES__GUIDANCE],
  [GOALS_AND_TARGETS]: [FEATURES.GOALS_N_TARGETS],
  [VIEW_TARGET_DETAILS]: [FEATURES.GOALS_N_TARGETS],
  [REPORT_HCMI_METRIC]: [FEATURES.REPORT__HCMI],
  [REPORT_STANDARD_REPORT]: [FEATURES.REPORT__STANDARD],
  [REPORT_PERFORMANCE_REPORT]: [
    FEATURES.REPORT__PERFORMANCE,
    FEATURES.REPORT__PERFORMANCE_BENCHMARK,
  ],
  [REPORT_INITIATIVES_REPORT]: [FEATURES.REPORT__INITIATIVES],
  [REPORT_BEST_PRACTICE_REPORT]: [FEATURES.REPORT__BEST_PRACTICES],
  [REPORT_RISK_ASSESSMENT_REPORT]: [FEATURES.REPORT__RISK_ASSESSMENT],
  [GSTC]: [FEATURES.GSTC],
  [POLICY_TRACKER]: [FEATURES.POLICY_TRACKER],
  [POLICY_TRACKER_DETAILS]: [FEATURES.POLICY_TRACKER],
  [CERTIFICATIONS]: [FEATURES.CERTIFICATIONS],
  [CERTIFICATION_HOMEPAGE]: [FEATURES.CERTIFICATIONS],
  [CERTIFICATION_CONTENT_PAGE]: [FEATURES.CERTIFICATIONS],
  [CERTIFICATION_CONTENT_PAGE_REQUIREMENT]: [FEATURES.CERTIFICATIONS],
};
const getFeaturesRequiredForPath = (pathname) => {
  const matchingPath = Object.keys(FeaturesRequiredByPath).find(
    (mappedPath) => {
      return matchPath(pathname, { path: mappedPath, exact: true });
    }
  );

  return matchingPath ? FeaturesRequiredByPath[matchingPath] : [];
};

export default getFeaturesRequiredForPath;
