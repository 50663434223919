import React from "react";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import { SYSTEM_TOOLBOX_BRAND_FLAGS_LIST, SYSTEM_TOOLBOX_COMPANIES_LIST } from "../../../config/ROUTES_NAME";
import { Link, useLocation } from "react-router-dom";
import { ONBOARDING_TEMPLATE_FROM_DROPBOX_LINK } from "../../../config/constants";

const OnboardingSetupTemplate = () => {
  const location = useLocation();

  return (
    <div className="onboarding-tool__text-blurb-container">
      <div>
        This onboarding tool is optimised for corporate companies with multiple
        sites.
      </div>
      <div>
        <ol>
          <li>
            Download the onboarding template from{" "}
            <a
              href={ONBOARDING_TEMPLATE_FROM_DROPBOX_LINK}
              target="_blank"
              rel="noreferrer"
              className="download-template-link"
            >
              Greenview's Onboarding folder in Dropbox
            </a>{" "}
            and fill it out with clients to facilitate new contract, sites, and
            users in the Greenview Portal.
          </li>
          <li>
            Create the following in reference data. These will be utilised later
            in the onboarding tool.
            <ol type="a">
              <li>
                Create the client's company in the 'Companies' reference data
                page{" "}
                <Link
                  to={getRedirectURLWithCurrentParam(
                    SYSTEM_TOOLBOX_COMPANIES_LIST,
                    location
                  )}
                  target="_blank"
                >
                  here.
                </Link>
              </li>
              <li>
                If applicable, create the company' s brand flags in the 'Brand
                Flag' reference data page{" "}
                <Link
                  to={getRedirectURLWithCurrentParam(
                    SYSTEM_TOOLBOX_BRAND_FLAGS_LIST,
                    location
                  )}
                  target="_blank"
                >
                  here.
                </Link>
              </li>
            </ol>
            <div className="mb-1">
              If you had created a new company or brand flag, please refresh
              this page before proceeding to next step.
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default OnboardingSetupTemplate;
