import React from "react";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import { PERFORMANCE_REPORT_OPERATIONAL_PER_LABEL } from "../Report/PerformanceReport/PerformanceReportConfig";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  SOCIAL_PERFORMANCE_GROUP_ENUM,
  TARGET_SPECIAL_UNITS,
  TARGET_TOPIC,
} from "../../config/constants";
import UNSDGPicker from "../common/UNSDGPicker";
import TargetConfigParamsModel from "./TargetConfigParamsModel";
import GVFormGroup from "../common/GVFormGroup";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";

const SocialTargetBuilder = ({ config, params, setParams }) => {
  const getSocialSubtopics = () => {
    return _.chain(config)
      .get("social_subtopics", [])
      .map((o) => ({
        value: o,
        label: o,
      }))
      .value();
  };

  return (
    <Row>
      <Col sm={8}>
        <GVFormGroup>
          <Form.Label>Social Impact Data</Form.Label>
          <GVDSFormSingleSelect
            isSearchable={false}
            className="social-impact-metric-select"
            value={
              params.performance_group_name
                ? {
                    value: params.performance_group_name,
                    label: params.performance_group_name,
                  }
                : null
            }
            options={getSocialSubtopics()}
            onSelect={(selected) =>
              setParams({
                ...params,
                performance_group_name: selected.value,
                unit_id: TargetConfigParamsModel.getDefaultUnit(
                  selected.value,
                  config
                ),
              })
            }
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>
            UN Sustainable Development Goals (SDGs){" "}
            <span className="optional-form-label">(Optional)</span>
          </Form.Label>
          <UNSDGPicker
            selectedUNSDGs={params.un_sdgs ? params.un_sdgs : []}
            allUNSDGs={config.un_sdgs ? config.un_sdgs : []}
            onChange={(selected) =>
              setParams({
                ...params,
                un_sdgs:
                  selected.length > 0
                    ? selected.map((s) => ({ id: s.value, name: s.label }))
                    : null,
              })
            }
          />
        </GVFormGroup>
      </Col>
    </Row>
  );
};

const EnvironmentalTargetBuilder = ({ config, params, setParams }) => {
  if (
    config["environmental_subtopics"] &&
    config["environmental_subtopics"].length === 0
  ) {
    return (
      <div className="create-target-form__locked-content">
        Your site does not have environmental data. To get started, go to Data
        Management.
      </div>
    );
  }
  const operationDisabled = TargetConfigParamsModel.isUsingPercentageAsUnit(
    params["performance_group_name"],
    config
  );
  const operationUnitMap = TargetConfigParamsModel.getOperationUnitMap(
    params,
    config
  );

  const getSubtopics = () => {
    return _.chain(config)
      .get("environmental_subtopics", [])
      .map((o) => ({
        value: o.name,
        label: o.name,
      }))
      .value();
  };

  const getPerformanceGroups = () => {
    if (!params["subtopic_name"] || !config["environmental_subtopics"])
      return [];
    return _.chain(config)
      .get("environmental_subtopics", [])
      .find((s) => s.name === params["subtopic_name"])
      .get("performance_groups", [])
      .value();
  };

  const getIntensityMetrics = () => {
    let metrics = [];
    if (config["operational_metrics"]) {
      metrics = metrics.concat(config["operational_metrics"]);
    }
    if (config["area_types"]) {
      metrics = metrics.concat(config["area_types"].map((a) => a.name));
    }
    return metrics;
  };

  const onSubtopicChange = (subtopicName) => {
    setParams({
      ...params,
      subtopic_name: subtopicName,
      performance_group_name: "",
      unit_id: "",
      use_location_based: null,
    });
  };

  return (
    <div>
      <Row>
        <Col sm={4}>
          <GVFormGroup>
            <Form.Label>Subtopic</Form.Label>
            <GVDSFormSingleSelect
              isSearchable={false}
              className="environmental-subtopic-select"
              value={
                params.subtopic_name
                  ? {
                      value: params.subtopic_name,
                      label: params.subtopic_name,
                    }
                  : null
              }
              options={getSubtopics()}
              onSelect={(selected) => onSubtopicChange(selected.value)}
            />
          </GVFormGroup>
        </Col>
        <Col sm={8}>
          <GVFormGroup>
            <Form.Label>Performance Grouping</Form.Label>
            <GVDSFormSingleSelect
              isSearchable={false}
              className="environmental-performance-group-select"
              value={
                params.performance_group_name
                  ? {
                      value: params.performance_group_name,
                      label: params.performance_group_name,
                    }
                  : null
              }
              options={getPerformanceGroups().map((o) => ({
                value: o.name,
                label: o.name,
              }))}
              onSelect={(selected) => {
	              const isUsingPercentage =
		              TargetConfigParamsModel.isUsingPercentageAsUnit(
			              selected.value,
			              config
		              );
                const unitMap = getEnvironmentalUnitMap(
                  config,
                  params["subtopic_name"],
                  isUsingPercentage
                );
                const isPrevUnitAvailable = !!unitMap[params["unit_id"]]
                if (isUsingPercentage) {
                  setParams({
                    ...params,
                    area_unit_id: null,
                    intensity_metric: null,
                    performance_group_name: selected.value,
                    use_location_based:
                      TargetConfigParamsModel.getUseLocationBasedForPerformanceGroup(
                        selected.value,
                        params,
                        config
                      ),
                    unit_id: isPrevUnitAvailable? params["unit_id"]: "",
                  });
                } else {
                  setParams({
                    ...params,
                    performance_group_name: selected.value,
                    use_location_based:
                      TargetConfigParamsModel.getUseLocationBasedForPerformanceGroup(
                        selected.value,
                        params,
                        config
                      ),
                    unit_id: isPrevUnitAvailable? params["unit_id"]: "",
                  });
                }
              }}
            />
          </GVFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <GVFormGroup>
            <Form.Label>
              Operational Metric{" "}
              <span className="optional-form-label">(optional)</span>
            </Form.Label>
            <GVDSFormSingleSelect
              placeholder={
                operationDisabled
                  ? "Not Applicable"
                  : "Select Operational Metric"
              }
              isClearable
              isSearchable={false}
              disabled={operationDisabled}
              value={
                params.intensity_metric
                  ? {
                      value: params.intensity_metric,
                      label:
                        PERFORMANCE_REPORT_OPERATIONAL_PER_LABEL[
                          params.intensity_metric
                        ],
                    }
                  : null
              }
              options={_.sortBy(
                getIntensityMetrics().map((m) => ({
                  value: m,
                  label: PERFORMANCE_REPORT_OPERATIONAL_PER_LABEL[m],
                })),
                "label"
              )}
              onSelect={(selected) =>
                setParams({
                  ...params,
                  intensity_metric: selected?.value,
                })
              }
            />
          </GVFormGroup>
        </Col>
        {!_.isEmpty(operationUnitMap) && (
          <Col sm={4}>
            <GVFormGroup>
              <Form.Label>Unit</Form.Label>
              <GVDSFormSingleSelect
                disabled={operationDisabled}
                value={
                  params["area_unit_id"]
                    ? {
                        value: params["area_unit_id"],
                        label: operationUnitMap[params["area_unit_id"]],
                      }
                    : null
                }
                options={Object.entries(operationUnitMap).map(([id, name]) => ({
                  value: id,
                  label: name,
                }))}
                onSelect={(selected) =>
                  setParams({
                    ...params,
                    area_unit_id: selected.value,
                  })
                }
              />
            </GVFormGroup>
          </Col>
        )}
      </Row>
    </div>
  );
};

const getEnvironmentalUnitMap = (config, subtopicName, isUsingPercentage) => {
  if (isUsingPercentage) {
    return {
      [TARGET_SPECIAL_UNITS.PERCENTAGE]: TARGET_SPECIAL_UNITS.PERCENTAGE,
    };
  } else {
    return _.chain(config)
      .get("environmental_subtopics", [])
      .find((s) => s.name === subtopicName)
      .get("units", [])
      .sortBy("name")
      .reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
      .value();
  }
};

export const SelectTargetUnit = ({
  topic,
  config,
  params,
  setParams,
  hasComparisonYear,
}) => {
  let unitMap = {};

  if (topic === TARGET_TOPIC.SOCIAL) {
    if (
      params["performance_group_name"] ===
      SOCIAL_PERFORMANCE_GROUP_ENUM.TOTAL_VOLUNTEER_HOURS
    ) {
      unitMap = {
        [TARGET_SPECIAL_UNITS.HOURS]: _.capitalize(TARGET_SPECIAL_UNITS.HOURS),
      };
    } else {
      unitMap = _.chain(config)
        .get("currencies", [])
        .sortBy("name")
        .reduce(
          (acc, { id, name }) => ({ ...acc, [id]: `${name} (${id})` }),
          {}
        )
        .value();
    }
  } else if (
    topic === TARGET_TOPIC.ENVIRONMENTAL &&
    !TargetConfigParamsModel.isUsingPercentageAsUnit(
      params["performance_group_name"],
      config
    )
  ) {
    unitMap = getEnvironmentalUnitMap(config, params["subtopic_name"], false);
  }
  if (
    hasComparisonYear ||
    TargetConfigParamsModel.isUsingPercentageAsUnit(
      params["performance_group_name"],
      config
    )
  ) {
    unitMap = getEnvironmentalUnitMap(config, params["subtopic_name"], true);
  }

  return (
    <GVDSFormSingleSelect
      className="target-unit-select"
      value={
        params.unit_id
          ? { value: params.unit_id, label: unitMap[params.unit_id] }
          : null
      }
      options={Object.entries(unitMap).map(([id, name]) => ({
        value: id,
        label: name,
      }))}
      onSelect={(selected) =>
        setParams({
          ...params,
          unit_id: selected.value,
          use_percentage_change:
            selected.value === TARGET_SPECIAL_UNITS.PERCENTAGE &&
            !TargetConfigParamsModel.isUsingPercentageAsUnit(
              params["performance_group_name"],
              config
            ),
        })
      }
    />
  );
};

const TargetFormBuilder = ({ topic, config, params, setParams }) => {
  if (topic === "social") {
    return (
      <SocialTargetBuilder
        config={config}
        params={params}
        setParams={setParams}
      />
    );
  }

  if (topic === "environmental") {
    return (
      <EnvironmentalTargetBuilder
        config={config}
        params={params}
        setParams={setParams}
      />
    );
  }

  return <div>not defined</div>;
};

export default TargetFormBuilder;
