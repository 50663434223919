import SafeDataService from "../../../../services/SafeDataService";
import PerformanceReportFormatService from "../PerformanceReportFormatService";
import React from "react";

export const getComparisonColumns = (
  base,
  next,
  report_type = "",
  report_title = "",
  show_as_negative = false
) => {
  const variance = SafeDataService.getVariance(next, base);
  return (
    <>
      <td
        className={
          "performance-report-table__number-cell" +
          (SafeDataService.highlightMissing(base) ? " missing-data" : "")
        }
      >
        {show_as_negative && "("}
        {PerformanceReportFormatService.renderFormattedValue(
          report_type,
          report_title,
          SafeDataService.getValue(base)
        )}
        {show_as_negative && ")"}
      </td>
      <td
        className={
          "performance-report-table__number-cell" +
          (SafeDataService.highlightMissing(next) ? " missing-data" : "")
        }
      >
        {show_as_negative && "("}
        {PerformanceReportFormatService.renderFormattedValue(
          report_type,
          report_title,
          SafeDataService.getValue(next)
        )}
        {show_as_negative && ")"}
      </td>
      <td
        className={
          "performance-report-table__number-cell divide-border-right" +
          (SafeDataService.highlightMissing(variance) ? " missing-data" : "")
        }
      >
        {PerformanceReportFormatService.renderFormattedValue(
          report_type,
          report_title,
          SafeDataService.getValue(variance)
        )}
      </td>
    </>
  );
};
