import React, { useContext, useEffect, useState } from "react";
import { cloneDeep, get, isEqual } from "lodash";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingSpinner from "../common/LoadingSpinner";

import SiteService from "../../services/SiteService";
import {
  Area,
  brandFlagNeedBrandCompanyMesssage,
  Certificate,
  Certification,
  deep_field_separator,
  DropdownOptions,
  empty_id_name,
  EntityRelationship,
  expedia_star_option,
  FloorArea,
  GeographicCoordinates,
  RelatedEntities,
  StarPicker,
  type_hotel,
} from "./SiteFacilityInformationComponents";
import {
  new_entry_prefix,
  PERMISSIONS,
  tobe_deleted_entry_prefix,
} from "../../config/constants";
import ToastContext from "../../context/ToastContext";
import PermissionsContext from "../../context/PermissionsContext";
import SelectCurrency from "../Currency/SelectCurrency";
import GVFormGroup from "../common/GVFormGroup";
import { DateTimeUtils } from "../../services/UtilsService";
import InfoTooltip from "../common/Tooltip/InfoTooltip";
import BeforeTabChangesPrompt from "../common/Tabs/BeforeTabChangesPrompt";
import BeforeUrlChangesPrompt from "../common/BeforeUrlChangesPrompt";
import MetroAreaService from "../../services/MetroAreaService";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSFormTextArea from "../../gvds-components/Forms/GVDSFormTextArea";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSFormSingleDatePicker from "../../gvds-components/Forms/GVDSFormSingleDatePicker";
import GVDSFormMultiSelect from "../../gvds-components/Forms/GVDSFormMultiSelect";
import { useTranslation } from "react-i18next";

const ViewSiteInformation = ({ siteDetails, onChange }) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const permissionsCtx = useContext(PermissionsContext);

  const [isLoading, setIsLoading] = useState(true);
  const [inEditMode, setEditMode] = useState(false);
  const [inputFields, setInputFields] = useState({
    name: { original: "", inputValue: "" },
    type: { original: empty_id_name, inputValue: empty_id_name },
    comment: { original: "", inputValue: "" },

    areas: { original: [], inputValue: [] },

    location__street_address: { original: "", inputValue: "" },
    location__city: { original: "", inputValue: "" },
    location__state_or_province: { original: "", inputValue: "" },
    location__zip_code: { original: "", inputValue: "" },
    location__country: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },

    related_entities: { original: [], inputValue: [] },
    brand: { original: empty_id_name, inputValue: empty_id_name },
    other_identifier_code: { original: "", inputValue: "" },

    about_attribute__status: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    about_attribute__date_opened: {
      original: "",
      inputValue: "",
    },

    main_attribute__default_currency: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    main_attribute__metro_area: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    main_attribute__koppen_geiger_climate_zone: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    main_attribute__baileys_ecoregion_climate_zone: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },

    hotel_attribute__affiliations: {
      original: [],
      inputValue: [],
    },
    hotel_attribute__expedia_star: {
      original: null,
      inputValue: null,
    },
    hotel_attribute__market_segment: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    hotel_attribute__hotel_type: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    hotel_attribute__asset_class: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    hotel_attribute__laundry_location: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    hotel_attribute__room_count: {
      original: null,
      inputValue: null,
    },

    certificates: { original: [], inputValue: [] },
  });
  const [originalLat, setOriginalLat] = useState({});
  const [originalLong, setOriginalLong] = useState({});

  const [siteFacilityOptions, setSiteFacilityOptions] = useState({});

  const [siteUpdateErrors, setSiteUpdateErrors] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!inEditMode);
  };

  const onInputFieldsChange = (newInputFields) => {
    setInputFields({ ...newInputFields });
    setHasChanges(true);
  };

  const saveChanges = () => {
    setSiteUpdateErrors({});

    if (!isInputFieldsValid()) {
      return;
    }

    setIsLoading(true);

    const editedFields = Object.keys(inputFields)
      .filter((key) => {
        const original = inputFields[key].original;
        const inputValue = inputFields[key].inputValue;

        return !isEqual(original, inputValue);
      })
      .reduce((obj, key) => {
        if (
          inputFields[key].inputValue.id !== undefined &&
          inputFields[key].inputValue.id === ""
        ) {
          inputFields[key].inputValue = { id: null, name: null };
        }

        if (key.includes(deep_field_separator)) {
          const [topField, nestedField] = key.split(deep_field_separator);
          if (!obj[topField]) {
            obj[topField] = {};
          }
          if (topField === "about_attribute" && nestedField === "date_opened") {
            obj[topField][nestedField] = DateTimeUtils.getUTCISOString(
              inputFields[key].inputValue
            );
          } else {
            obj[topField][nestedField] = inputFields[key].inputValue;
          }
        } else if (key === "areas") {
          const originalAreas = inputFields[key].original;
          const inputAreas = inputFields[key].inputValue;

          obj[key] = inputAreas
            .filter((inputArea) => {
              const originalArea = originalAreas.find(
                (area) => area.id === inputArea.id
              );

              if (!originalArea) {
                // if new area is empty, do not include
                return !(
                  inputArea.id.startsWith(new_entry_prefix) &&
                  inputArea.hasNoData()
                );
              }

              return !isEqual(inputArea, originalArea);
            })
            .map((area) => {
              area.record_month = DateTimeUtils.getUTCISOString(
                area.record_month
              );
              return area;
            });
        } else if (key === "certificates") {
          const originalCertificates = inputFields[key].original;
          const inputCertificates = inputFields[key].inputValue;

          obj[key] = inputCertificates
            .filter((inputCertificate) => {
              const originalCertificate = originalCertificates.find(
                (certificate) => certificate.id === inputCertificate.id
              );

              if (!originalCertificate) {
                // if new certificate is empty, do not include
                return !(
                  inputCertificate.id.startsWith(new_entry_prefix) &&
                  inputCertificate.has_no_data()
                );
              }

              return !isEqual(inputCertificate, originalCertificate);
            })
            .map((certificate) => {
              certificate.date_completed = DateTimeUtils.getUTCISOString(
                certificate.date_completed
              );
              return certificate;
            });
        } else {
          obj[key] = inputFields[key].inputValue;
        }

        return obj;
      }, {});

    SiteService.editSite(siteDetails.id, editedFields)
      .then(() => {
        setEditMode(false);
        setIsLoading(false);
        toastContext.addSuccessToast(
          <span>Site Details have been updated</span>
        );
        onChange();
      })
      .catch((error) => {
        if (error.status === 400) {
          setSiteUpdateErrors(error.data.message);
        }
        toastContext.addFailToast(<span>Failed to update Site Details</span>);
        setIsLoading(false);
      });
  };

  const cancelChanges = () => {
    setSiteUpdateErrors({});
    resetInputFields();
    setEditMode(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setOriginalValues();
    resetInputFields();
    SiteService.getSiteFacilityOptions()
      .then((data) => {
        setSiteFacilityOptions(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load site facility details.</span>
        );
      });
  }, [siteDetails]);

  const setOriginalValues = () => {
    const originalFields = inputFields;

    originalFields.name.original = siteDetails.name;
    originalFields.type.original = siteDetails.type;
    originalFields.comment.original = siteDetails.comment;

    originalFields.location__city.original = siteDetails.location.city;
    originalFields.location__country.original = siteDetails.location.country;
    originalFields.location__state_or_province.original =
      siteDetails.location.state_or_province;
    originalFields.location__street_address.original =
      siteDetails.location.street_address;
    originalFields.location__zip_code.original = siteDetails.location.zip_code;

    originalFields.areas.original = siteDetails.areas.map(
      (area) =>
        new Area(
          area.id,
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            area.record_month
          ),
          area.gfa_built,
          area.gfa_conditioned_space,
          area.gfa_guestroom,
          area.gfa_meeting,
          area.unit,
          area.comment
        )
    );

    originalFields.related_entities.original = siteDetails.related_entities.map(
      (related_entity) => {
        return new EntityRelationship(
          related_entity.id,
          related_entity.relation_type,
          related_entity.company,
          related_entity.internal_company_code
        );
      }
    );
    originalFields.brand.original = siteDetails.brand
      ? siteDetails.brand
      : empty_id_name;
    originalFields.other_identifier_code.original =
      siteDetails.other_identifier_code;

    originalFields.about_attribute__status.original = siteDetails
      .about_attribute.status
      ? siteDetails.about_attribute.status
      : empty_id_name;
    originalFields.about_attribute__date_opened.original = siteDetails
      .about_attribute.date_opened
      ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          siteDetails.about_attribute.date_opened
        )
      : null;
    originalFields.main_attribute__default_currency.original = siteDetails
      .main_attribute.default_currency
      ? siteDetails.main_attribute.default_currency
      : empty_id_name;
    originalFields.main_attribute__metro_area.original = siteDetails
      .main_attribute.metro_area
      ? siteDetails.main_attribute.metro_area
      : empty_id_name;
    originalFields.main_attribute__koppen_geiger_climate_zone.original =
      siteDetails.main_attribute.koppen_geiger_climate_zone
        ? siteDetails.main_attribute.koppen_geiger_climate_zone
        : empty_id_name;
    originalFields.main_attribute__baileys_ecoregion_climate_zone.original =
      siteDetails.main_attribute.baileys_ecoregion_climate_zone
        ? siteDetails.main_attribute.baileys_ecoregion_climate_zone
        : empty_id_name;

    if (siteDetails.type.name.toLowerCase() === type_hotel) {
      originalFields.hotel_attribute__affiliations.original = siteDetails
        .hotel_attribute.affiliations
        ? siteDetails.hotel_attribute.affiliations
        : [];
      originalFields.hotel_attribute__expedia_star.original = siteDetails
        .hotel_attribute.expedia_star
        ? siteDetails.hotel_attribute.expedia_star
        : null;
      originalFields.hotel_attribute__market_segment.original = siteDetails
        .hotel_attribute.market_segment
        ? siteDetails.hotel_attribute.market_segment
        : empty_id_name;
      originalFields.hotel_attribute__hotel_type.original = siteDetails
        .hotel_attribute.hotel_type
        ? siteDetails.hotel_attribute.hotel_type
        : empty_id_name;
      originalFields.hotel_attribute__asset_class.original = siteDetails
        .hotel_attribute.asset_class
        ? siteDetails.hotel_attribute.asset_class
        : empty_id_name;
      originalFields.hotel_attribute__laundry_location.original = siteDetails
        .hotel_attribute.laundry_location
        ? siteDetails.hotel_attribute.laundry_location
        : empty_id_name;
      originalFields.hotel_attribute__room_count.original = siteDetails
        .hotel_attribute.room_count
        ? siteDetails.hotel_attribute.room_count
        : null;
    }

    originalFields.certificates.original = siteDetails.certificates.map(
      (certificate) =>
        new Certificate(
          certificate.id,
          certificate.certification,
          certificate.status,
          certificate.date_completed,
          certificate.comment
        )
    );

    setInputFields(originalFields);

    setOriginalLat(siteDetails.location.lat);
    setOriginalLong(siteDetails.location.long);
  };

  const resetInputFields = () => {
    const resetFields = inputFields;

    Object.keys(resetFields).forEach((key) => {
      resetFields[key].inputValue = cloneDeep(resetFields[key].original);
    });

    setInputFields(resetFields);
    setHasChanges(false);
  };

  const changeTextInputField = (event) => {
    const targetName = event.target.name;

    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = event.target.value;

    onInputFieldsChange(newInputFields);
  };

  const changeDropdownInputField = (targetName, newId, newName) => {
    const newInputFields = inputFields;

    newInputFields[targetName].inputValue = { id: newId, name: newName };

    if (targetName === "location__country") {
      newInputFields["main_attribute__metro_area"].inputValue = empty_id_name;

      if (inputFields.location__state_or_province.inputValue) {
        const provinceDetail = siteFacilityOptions.provinces
          ? siteFacilityOptions.provinces.find(
              (p) =>
                p.name === inputFields.location__state_or_province.inputValue
            )
          : null;
        if (provinceDetail && provinceDetail.country.id !== newId) {
          newInputFields["location__state_or_province"].inputValue = "";
        } else if (
          !provinceDetail &&
          siteFacilityOptions.provinces &&
          siteFacilityOptions.provinces.find((p) => p.country.id === newId)
        ) {
          // no matching province dropdown and new country has province dropdown
          newInputFields["location__state_or_province"].inputValue = "";
        }
      }
    }

    onInputFieldsChange(newInputFields);
  };

  const changeDate = (targetName, newDate) => {
    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = newDate;

    onInputFieldsChange(newInputFields);
  };

  const changeMultiSelect = (targetName, selected) => {
    const newInputFields = inputFields;

    newInputFields[targetName].inputValue = selected.map((item) => {
      return { id: item.value, name: item.label };
    });

    onInputFieldsChange(newInputFields);
  };

  const changeStateOrProvinceDropdown = (targetName, newId, newName) => {
    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = newName;

    onInputFieldsChange(newInputFields);
  };

  const changeStarPicker = (targetName, value) => {
    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = value;

    onInputFieldsChange(newInputFields);
  };

  const changeArea = (areas) => {
    const newInputFields = inputFields;
    newInputFields.areas.inputValue = areas;

    onInputFieldsChange(newInputFields);
  };

  const getBrandCompaniesIds = (relatedEntities) => {
    return relatedEntities
      .filter(
        (entity) =>
          entity.relation_type.name.toLowerCase() === "brand" &&
          entity.company.id.length > 0 &&
          !entity.id.startsWith(tobe_deleted_entry_prefix)
      )
      .map((entity) => entity.company.id);
  };

  const getAvailableBrandOptions = (relatedEntities) => {
    const brandCompaniesIds = getBrandCompaniesIds(relatedEntities);

    return brandCompaniesIds && siteFacilityOptions.brands
      ? Object.entries(siteFacilityOptions.brands)
          .map(([brandCompanyId, brandsOfCompany]) => {
            return brandCompaniesIds.indexOf(brandCompanyId) >= 0
              ? brandsOfCompany
              : [];
          })
          .reduce((a, b) => a.concat(b), [])
      : [];
  };

  const changeRelatedEntities = (relatedEntities) => {
    const newInputFields = inputFields;
    newInputFields.related_entities.inputValue = relatedEntities;

    const brandOptions = getAvailableBrandOptions(
      inputFields.related_entities.inputValue
    );

    const isCurrentlySelectedBrandInAvailableBrandList = brandOptions.find(
      (brandOption) => brandOption.id === inputFields.brand.inputValue.id
    );

    const isBrandOptionAvailable = brandOptions.length > 0;
    const isBrandValueNA = inputFields.brand.inputValue.id === "";

    if (
      !isBrandOptionAvailable ||
      (!isBrandValueNA && !isCurrentlySelectedBrandInAvailableBrandList)
    ) {
      newInputFields.brand.inputValue = empty_id_name;
    }

    onInputFieldsChange(newInputFields);
  };

  const changeCertificate = (certificates) => {
    const newInputFields = inputFields;
    newInputFields.certificates.inputValue = certificates;

    onInputFieldsChange(newInputFields);
  };

  const isSiteNameValid = () => {
    return (
      inputFields.name.inputValue && inputFields.name.inputValue.length > 0
    );
  };

  const isSiteTypeValid = () => {
    return inputFields.type.inputValue && inputFields.type.inputValue.id;
  };

  const isAddressValid = () => {
    return (
      inputFields.location__street_address.inputValue &&
      inputFields.location__street_address.inputValue.length > 0
    );
  };

  const isCityValid = () => {
    return (
      inputFields.location__city.inputValue &&
      inputFields.location__city.inputValue.length > 0
    );
  };

  const isZipCodeValid = () => {
    return (
      inputFields.location__zip_code.inputValue &&
      inputFields.location__zip_code.inputValue.length > 0
    );
  };

  const getProvincesOfCountry = () => {
    return siteFacilityOptions.provinces &&
      inputFields.location__country.inputValue
      ? siteFacilityOptions.provinces.filter(
          (p) => p.country.id === inputFields.location__country.inputValue.id
        )
      : [];
  };

  const isStateOrProvinceValid = () => {
    const provincesOfCountry = getProvincesOfCountry();

    if (provincesOfCountry.length === 0) {
      return true;
    } else {
      return !!inputFields.location__state_or_province.inputValue;
    }
  };

  const isCountryValid = () => {
    return (
      inputFields.location__country.inputValue &&
      inputFields.location__country.inputValue.id &&
      inputFields.location__country.inputValue.id.length > 0
    );
  };

  const isCurrencyValid = () => {
    return (
      inputFields.main_attribute__default_currency.inputValue &&
      inputFields.main_attribute__default_currency.inputValue.id &&
      inputFields.main_attribute__default_currency.inputValue.id.length > 0
    );
  };

  const isInputFieldsValid = () => {
    return (
      isSiteNameValid() &&
      isSiteTypeValid() &&
      isAddressValid() &&
      isCityValid() &&
      isZipCodeValid() &&
      isStateOrProvinceValid() &&
      isCountryValid() &&
      isCurrencyValid()
    );
  };

  const isSiteNameWithErrors = () => {
    return !!get(siteUpdateErrors, "name.name");
  };

  const isAddressWithErrors = () => {
    return !!get(siteUpdateErrors, "location.street_address");
  };

  const isZipCodeWithErrors = () => {
    return !!get(siteUpdateErrors, "location.zip_code");
  };

  const isStateOrProvinceWithErrors = () => {
    return !!get(siteUpdateErrors, "location.state_or_province");
  };

  if (isLoading) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  }

  const stateOrProvinceInput = () => {
    const provincesOfCountry = getProvincesOfCountry();

    if (inEditMode) {
      if (provincesOfCountry.length > 0) {
        return (
          <DropdownOptions
            label="State / Province"
            inEditMode={true}
            targetName="location__state_or_province"
            value={{
              id: inputFields.location__state_or_province.inputValue,
              name: inputFields.location__state_or_province.inputValue,
            }}
            onChange={changeStateOrProvinceDropdown}
            options={provincesOfCountry.map((p) => {
              return { id: p.name, name: p.name };
            })}
            isInvalid={
              !isStateOrProvinceValid() || isStateOrProvinceWithErrors()
            }
            invalidMessage="State or Province is required."
          />
        );
      } else {
        return (
          <>
            <Form.Label>State / Province</Form.Label>
            <GVDSFormField
              name="location__state_or_province"
              value={inputFields.location__state_or_province.inputValue}
              onInput={(value, e) => changeTextInputField(e)}
            />
          </>
        );
      }
    } else {
      return (
        <>
          <Form.Label>State / Province</Form.Label>
          <Form.Control
            readOnly
            plaintext
            name="location__state_or_province"
            value={inputFields.location__state_or_province.inputValue || ""}
          />
        </>
      );
    }
  };

  const brandOptions = getAvailableBrandOptions(
    inputFields.related_entities.inputValue
  );

  const getBrandCompaniesOptionTuples = () => {
    let brandCompaniesOptionTuples = [];
    if (
      getBrandCompaniesIds(inputFields.related_entities.inputValue).length > 0
    ) {
      brandCompaniesOptionTuples.push({ value: "", label: "Not Applicable" });
      if (brandOptions) {
        brandOptions.map((option) => {
          brandCompaniesOptionTuples.push({
            value: option.id,
            label: option.name,
          });
        });
      }
    }
    return brandCompaniesOptionTuples;
  };

  const getBrandValue = (inputValue) => {
    const isNotApplicableSelected =
      inputValue.name === "" && inputValue.id === "";
    if (isNotApplicableSelected) {
      if (
        getBrandCompaniesIds(inputFields.related_entities.inputValue).length > 0
      ) {
        return { value: "", label: "Not Applicable" };
      } else {
        return null;
      }
    } else {
      return { value: inputValue.id, label: inputValue.name };
    }
  };

  const getBrandTitle = (inputValue) => {
    const brandValue = getBrandValue(inputValue);
    if (brandValue) {
      return brandValue.label;
    } else {
      return "";
    }
  };

  const getSiteNameErrorMessage = () => {
    return !isSiteNameValid()
      ? "Please provide site name."
      : isSiteNameWithErrors()
      ? get(siteUpdateErrors, "name.name")
      : "";
  };

  const getStreetAddressErrorMessage = () => {
    return !isAddressValid()
      ? "Street address is required."
      : isAddressWithErrors()
      ? get(siteUpdateErrors, "location.street_address")
      : "";
  };

  return (
    <>
      {!permissionsCtx.isLoadingPermissions &&
        permissionsCtx.permissions[PERMISSIONS.SITE_EDIT] &&
        !inEditMode && (
          <div className="d-flex justify-content-end mb-4">
            <GVDSButton
              className="edit-site-button"
              variant={buttonVariant.primary}
              onClick={toggleEditMode}
              text="Edit Site Details"
            />
          </div>
        )}
      <Form>
        <section className="section-box">
          <header>
            <h2>General</h2>
          </header>
          <article>
            <GVFormGroup>
              <Form.Label>Site Name</Form.Label>
              {inEditMode ? (
                <GVDSFormField
                  name="name"
                  data-lpignore="true"
                  value={inputFields.name.inputValue}
                  onInput={(value, e) => changeTextInputField(e)}
                  statusMetadata={
                    !isSiteNameValid() || isSiteNameWithErrors()
                      ? FormFieldStatusMetadata.getError(
                          getSiteNameErrorMessage()
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              ) : (
                <Form.Control
                  readOnly
                  plaintext
                  data-lpignore="true"
                  value={inputFields.name.inputValue || ""}
                />
              )}
            </GVFormGroup>
            <GVFormGroup>
              <DropdownOptions
                label="Type"
                inEditMode={inEditMode}
                targetName="type"
                value={inputFields.type.inputValue}
                onChange={changeDropdownInputField}
                options={siteFacilityOptions.types}
                isInvalid={!isSiteTypeValid()}
                invalidMessage="Please select a site type."
              />
            </GVFormGroup>
            <div>
              <Form.Label>Comment</Form.Label>
              {inEditMode ? (
                <GVDSFormTextArea
                  rows={3}
                  name="comment"
                  value={inputFields.comment.inputValue}
                  onInput={(value, e) => changeTextInputField(e)}
                />
              ) : (
                <Form.Control
                  readOnly
                  plaintext
                  as="textarea"
                  rows={3}
                  name="comment"
                  value={inputFields.comment.inputValue || ""}
                />
              )}
            </div>
          </article>
        </section>
        <section className="section-box">
          <header>
            <h2>Location</h2>
          </header>
          <article>
            <Row>
              <Col>
                <GVFormGroup>
                  <Form.Label>Street Address</Form.Label>
                  {inEditMode ? (
                    <GVDSFormField
                      name="location__street_address"
                      value={inputFields.location__street_address.inputValue}
                      onInput={(value, e) => changeTextInputField(e)}
                      statusMetadata={
                        !isAddressValid() || isAddressWithErrors()
                          ? FormFieldStatusMetadata.getError(
                              getStreetAddressErrorMessage()
                            )
                          : FormFieldStatusMetadata.getDefault()
                      }
                    />
                  ) : (
                    <Form.Control
                      readOnly
                      plaintext
                      name="location__street_address"
                      value={
                        inputFields.location__street_address.inputValue || ""
                      }
                    />
                  )}
                </GVFormGroup>
              </Col>
              <Col lg={4}>
                <GVFormGroup>
                  <Form.Label>Postal Code</Form.Label>
                  {inEditMode ? (
                    <GVDSFormField
                      name="location__zip_code"
                      value={inputFields.location__zip_code.inputValue}
                      onInput={(value, e) => changeTextInputField(e)}
                      statusMetadata={
                        !isZipCodeValid() || isZipCodeWithErrors()
                          ? FormFieldStatusMetadata.getError(
                              "Postal code is not valid."
                            )
                          : FormFieldStatusMetadata.getDefault()
                      }
                    />
                  ) : (
                    <Form.Control
                      readOnly
                      plaintext
                      name="location__zip_code"
                      value={inputFields.location__zip_code.inputValue || ""}
                    />
                  )}
                </GVFormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <GVFormGroup>
                  <Form.Label>City</Form.Label>
                  {inEditMode ? (
                    <GVDSFormField
                      name="location__city"
                      value={inputFields.location__city.inputValue}
                      onInput={(value, e) => changeTextInputField(e)}
                      statusMetadata={
                        !isCityValid()
                          ? FormFieldStatusMetadata.getError(
                              "City is required."
                            )
                          : FormFieldStatusMetadata.getDefault()
                      }
                    />
                  ) : (
                    <Form.Control
                      readOnly
                      plaintext
                      name="location__city"
                      value={inputFields.location__city.inputValue || ""}
                    />
                  )}
                </GVFormGroup>
              </Col>
              <Col lg={4}>{stateOrProvinceInput()}</Col>
              <Col lg={4}>
                <DropdownOptions
                  label="Country"
                  inEditMode={inEditMode}
                  targetName="location__country"
                  value={inputFields.location__country.inputValue}
                  onChange={changeDropdownInputField}
                  options={siteFacilityOptions.countries}
                  isInvalid={!isCountryValid()}
                  invalidMessage="Please select a country"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={7} xxl={4}>
                <GVFormGroup>
                  <Form.Label>
                    Geographic Coordinates{" "}
                    <InfoTooltip
                      info={t("admin.site-details.tooltip-geo-coordinate")}
                    />
                  </Form.Label>
                  <div>
                    {t("admin.site-details.label-description-geo-coordinate")}
                  </div>
                  <GeographicCoordinates
                    siteName={inputFields.name.inputValue}
                    originalLat={originalLat}
                    originalLong={originalLong}
                    isInputLocationSameAsOriginal={
                      inputFields.location__street_address.inputValue ===
                        inputFields.location__street_address.original &&
                      inputFields.location__zip_code.inputValue ===
                        inputFields.location__zip_code.original &&
                      inputFields.location__city.inputValue ===
                        inputFields.location__city.original &&
                      inputFields.location__state_or_province.inputValue ===
                        inputFields.location__state_or_province.original &&
                      inputFields.location__country.inputValue.name ===
                        inputFields.location__country.original.name
                    }
                    onFailGetLatLong={() =>
                      setSiteUpdateErrors({
                        ...siteUpdateErrors,
                        location: {
                          street_address:
                            "Failed to converted address into geographic coordinates. Please ensure that the street address is complete and unambiguous.",
                        },
                      })
                    }
                    streetAddress={
                      inputFields.location__street_address.inputValue
                    }
                    zipCode={inputFields.location__zip_code.inputValue}
                    city={inputFields.location__city.inputValue}
                    stateOrProvince={
                      inputFields.location__state_or_province.inputValue
                    }
                    countryName={inputFields.location__country.inputValue?.name}
                  />
                </GVFormGroup>
              </Col>
            </Row>
          </article>
        </section>
        <section className="section-box">
          <header>
            <h2>Floor Area Details</h2>
            <div>
              Floor area details are used for report calculation in some
              reports. Please ensure that floor area details are present for the
              Month Year during or after the report period when generating a
              report or responding to a data request.
            </div>
          </header>
          <article>
            <FloorArea
              isHotel={
                inputFields.type.inputValue.name.toLowerCase() === type_hotel
              }
              inEditMode={inEditMode}
              areaUnits={siteFacilityOptions.area_units}
              areas={inputFields.areas.inputValue}
              onAreaChange={changeArea}
            />
          </article>
        </section>
        <section className="section-box">
          <header>
            <h2>Related Entities</h2>
            <div>
              Add your brand, management or owner company here. Setting this up
              properly will allows users at the brand / management level to
              search for this site when necessary.
            </div>
          </header>
          <article>
            <Row className="mt-3">
              <Col xxl={4} xl={6}>
                <GVFormGroup>
                  <Form.Label>Brand Flag</Form.Label>
                  {inEditMode ? (
                    <GVDSFormSingleSelect
                      name="brand"
                      placeholder={
                        getBrandCompaniesIds(
                          inputFields.related_entities.inputValue
                        ).length === 0
                          ? brandFlagNeedBrandCompanyMesssage
                          : "Select a Brand Company"
                      }
                      value={
                        inputFields.brand.inputValue
                          ? getBrandValue(inputFields.brand.inputValue)
                          : null
                      }
                      options={getBrandCompaniesOptionTuples()}
                      onSelect={(selectedOption, actionCtx) => {
                        const targetName = actionCtx.name;
                        const newId = selectedOption.value;
                        const newName = selectedOption.label;
                        changeDropdownInputField(targetName, newId, newName);
                      }}
                      disabled={
                        getBrandCompaniesIds(
                          inputFields.related_entities.inputValue
                        ).length === 0
                      }
                    />
                  ) : (
                    <Form.Control
                      readOnly
                      plaintext
                      type="text"
                      name="brand"
                      value={
                        inputFields.brand.inputValue
                          ? getBrandTitle(inputFields.brand.inputValue)
                          : ""
                      }
                    />
                  )}
                </GVFormGroup>
              </Col>
              <Col xxl={4} xl={6}>
                <GVFormGroup>
                  <Form.Label>Other Identifier Code</Form.Label>
                  {inEditMode ? (
                    <GVDSFormField
                      name="other_identifier_code"
                      value={inputFields.other_identifier_code.inputValue}
                      onInput={(value, e) => changeTextInputField(e)}
                    />
                  ) : (
                    <Form.Control
                      readOnly
                      plaintext
                      name="other_identifier_code"
                      value={inputFields.other_identifier_code.inputValue || ""}
                      onChange={changeTextInputField}
                    />
                  )}
                </GVFormGroup>
              </Col>
            </Row>
            <RelatedEntities
              inEditMode={inEditMode}
              relatedEntities={inputFields.related_entities.inputValue}
              relatedCompanyOptions={
                siteFacilityOptions.related_company_options
              }
              onRelatedEntitiesChange={changeRelatedEntities}
            />
          </article>
        </section>
        <section className="section-box">
          <header>
            <h2>Attributes</h2>
            <div className="mb-3">
              The Attributes of your Site will help users to make sense of your
              data. We recommend that all relevant fields are completed to
              ensure that your data is as accurate and useful as possible to the
              readers of your report.
            </div>
          </header>
          <article>
            <div className="gvds-site-facility-content-box">
              <h3>About</h3>
              <DropdownOptions
                label="Status"
                inEditMode={inEditMode}
                targetName="about_attribute__status"
                value={inputFields.about_attribute__status.inputValue}
                onChange={changeDropdownInputField}
                options={siteFacilityOptions.statuses}
              />
              <GVFormGroup>
                <Form.Label>Date Opened</Form.Label>
                {inEditMode ? (
                  <div className="form-datepicker">
                    <GVDSFormSingleDatePicker
                      selected={
                        inputFields.about_attribute__date_opened.inputValue
                      }
                      onChange={(date) =>
                        changeDate("about_attribute__date_opened", date)
                      }
                      dateFormat="dd MMM yyyy"
                      className="block-datepicker attributes-open-date-picker"
                      maxDate={new Date()}
                    />
                  </div>
                ) : (
                  <Form.Control
                    readOnly
                    plaintext
                    type="text"
                    name="about_attribute__date_opened"
                    value={
                      inputFields.about_attribute__date_opened.inputValue
                        ? moment(
                            inputFields.about_attribute__date_opened.inputValue
                          ).format("DD MMM YYYY")
                        : ""
                    }
                  />
                )}
              </GVFormGroup>
            </div>
          </article>
          <article>
            <div className="gvds-site-facility-content-box">
              <h3>Main</h3>
              <GVFormGroup>
                <Form.Label>Currency of Utility Invoice</Form.Label>
                <SelectCurrency
                  selected={
                    inputFields.main_attribute__default_currency.inputValue?.id
                  }
                  onCurrencySelected={(currencyId) => {
                    changeDropdownInputField(
                      "main_attribute__default_currency",
                      currencyId,
                      siteFacilityOptions.currencies.find(
                        (c) => c.id === currencyId
                      )?.name
                    );
                  }}
                  displayAsText={!inEditMode}
                  isInvalid={!isCurrencyValid()}
                  invalidMessage="Please select a currency"
                  targetName={"main_attribute__default_currency"}
                  showLabel={false}
                />
              </GVFormGroup>
              <DropdownOptions
                label="Metro Area"
                inEditMode={inEditMode}
                targetName="main_attribute__metro_area"
                value={inputFields.main_attribute__metro_area.inputValue}
                onChange={changeDropdownInputField}
                options={
                  inputFields.location__country.inputValue
                    ? siteFacilityOptions.metro_areas?.filter((metro_area) => {
                        return (
                          metro_area.country === null ||
                          metro_area.country.id ===
                            inputFields.location__country.inputValue.id
                        );
                      })
                    : []
                }
                optionSortFn={MetroAreaService.sortFn}
              />
              <DropdownOptions
                label="Climate Zone (Köppen–Geiger)"
                inEditMode={inEditMode}
                targetName="main_attribute__koppen_geiger_climate_zone"
                value={
                  inputFields.main_attribute__koppen_geiger_climate_zone
                    .inputValue
                }
                onChange={changeDropdownInputField}
                options={siteFacilityOptions.koppen_geiger_climate_zones}
              />
              <DropdownOptions
                label="Climate Zone (Bailey's Ecoregion)"
                inEditMode={inEditMode}
                targetName="main_attribute__baileys_ecoregion_climate_zone"
                value={
                  inputFields.main_attribute__baileys_ecoregion_climate_zone
                    .inputValue
                }
                onChange={changeDropdownInputField}
                options={siteFacilityOptions.baileys_ecoregion_climate_zones}
              />
            </div>
          </article>
          {inputFields.type.inputValue.name.toLowerCase() === type_hotel && (
            <article>
              <div className="gvds-site-facility-content-box">
                <h3>Hotel</h3>
                <GVFormGroup>
                  <Form.Label>Affiliation</Form.Label>
                  <GVDSFormMultiSelect
                    name="hotel_attribute__affiliations"
                    disabled={!inEditMode}
                    value={
                      inputFields.hotel_attribute__affiliations.inputValue
                        ? inputFields.hotel_attribute__affiliations.inputValue.map(
                            (affiliation) => {
                              return {
                                label: affiliation.name,
                                value: affiliation.id,
                              };
                            }
                          )
                        : []
                    }
                    onSelect={(values) =>
                      changeMultiSelect("hotel_attribute__affiliations", values)
                    }
                    options={
                      siteFacilityOptions.affiliations
                        ? siteFacilityOptions.affiliations.map(
                            (affiliation) => {
                              return {
                                label: affiliation.name,
                                value: affiliation.id,
                              };
                            }
                          )
                        : []
                    }
                    className="affiliation-select"
                  />
                </GVFormGroup>
                <GVFormGroup>
                  <StarPicker
                    label="Expedia Stars"
                    inEditMode={inEditMode}
                    targetName="hotel_attribute__expedia_star"
                    value={inputFields.hotel_attribute__expedia_star.inputValue}
                    onChange={changeStarPicker}
                    options={expedia_star_option}
                  />
                </GVFormGroup>
                <DropdownOptions
                  label="Market Segment"
                  inEditMode={inEditMode}
                  targetName="hotel_attribute__market_segment"
                  value={inputFields.hotel_attribute__market_segment.inputValue}
                  onChange={changeDropdownInputField}
                  options={siteFacilityOptions.market_segments}
                />
                <DropdownOptions
                  label="Hotel Type"
                  inEditMode={inEditMode}
                  targetName="hotel_attribute__hotel_type"
                  value={inputFields.hotel_attribute__hotel_type.inputValue}
                  onChange={changeDropdownInputField}
                  options={siteFacilityOptions.hotel_types}
                />
                <DropdownOptions
                  label="Service Type / Asset Class"
                  inEditMode={inEditMode}
                  targetName="hotel_attribute__asset_class"
                  value={inputFields.hotel_attribute__asset_class.inputValue}
                  onChange={changeDropdownInputField}
                  options={siteFacilityOptions.asset_classes}
                />
                <DropdownOptions
                  label="Main Laundry Wash Location"
                  inEditMode={inEditMode}
                  targetName="hotel_attribute__laundry_location"
                  value={
                    inputFields.hotel_attribute__laundry_location.inputValue
                  }
                  onChange={changeDropdownInputField}
                  options={siteFacilityOptions.laundry_locations}
                />
                <GVFormGroup>
                  <Form.Label>Room Count</Form.Label>
                  {inEditMode ? (
                    <GVDSFormField
                      name="hotel_attribute__room_count"
                      type="number"
                      value={inputFields.hotel_attribute__room_count.inputValue}
                      onInput={(value, e) => changeTextInputField(e)}
                    />
                  ) : (
                    <Form.Control
                      readOnly
                      plaintext
                      name="hotel_attribute__room_count"
                      type="number"
                      value={
                        inputFields.hotel_attribute__room_count.inputValue || ""
                      }
                    />
                  )}
                </GVFormGroup>
              </div>
            </article>
          )}
        </section>
        <section className="section-box">
          <header>
            <h2>Certifications</h2>
            <div>
              The system only records certifications issued by certified
              certification bodies
            </div>
          </header>
          <article>
            <Certification
              inEditMode={inEditMode}
              certificates={inputFields.certificates.inputValue}
              certificateOptions={siteFacilityOptions.certificate_options}
              onCertificateChange={changeCertificate}
            />
          </article>
        </section>
      </Form>
      {inEditMode && (
        <div className="floating-action-container">
          <div className="d-flex justify-content-end">
            <GVDSButton
              className="me-3"
              variant={buttonVariant.tertiary}
              disabled={!inEditMode}
              onClick={cancelChanges}
              text="Cancel"
            />
            <GVDSButton
              className="me-3"
              variant={buttonVariant.primary}
              disabled={!inEditMode}
              onClick={saveChanges}
              text="Save Changes"
            />
          </div>
        </div>
      )}
      <BeforeTabChangesPrompt
        when={hasChanges}
        tabName="Site Details"
        message="You have unsaved changes in the form. Your data will be cleared and won’t be stored when you leave this page. Are you sure?"
        onProceed={cancelChanges}
      />
      <BeforeUrlChangesPrompt
        when={hasChanges}
        pageName="Site Details Input Page"
        message="You have unsaved changes in the form. Your data will be cleared and won’t be stored when you leave this page. Are you sure?"
      />
    </>
  );
};
export default ViewSiteInformation;
