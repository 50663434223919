import React, { useContext, useEffect, useState } from "react";
import MarketPurchaseOffsetService from "../../../../services/MarketPurchaseService";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import Accordion from "react-bootstrap/Accordion";
import { PERMISSIONS, RESOURCES } from "../../../../config/constants";
import PermissionsContext from "../../../../context/PermissionsContext";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import sortBy from "lodash/sortBy";
import { MARKET_PURCHASE_OFFSETS_TRACKER } from "../../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import LastActivityDisplay from "../../../common/LastActivityDisplay";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconExternalLink } from "@tabler/icons-react";
import GVDSAccordionHeader from "../../../../gvds-components/Accordion/GVDSAccordionHeader";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import { useTranslation } from "react-i18next";

const ViewAllMarketPurchaseTrackers = ({ trackers, onTrackerInput }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const toastContext = useContext(ToastContext);
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);
  const [offsetTypes, setOffsetTypes] = useState([]);
  const [activeKey, setActiveKey] = useState();

  useEffect(() => {
    setIsLoading(true);
    MarketPurchaseOffsetService.getAllTypes()
      .then((data) => {
        setOffsetTypes(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load market purchase configuration.</span>
        );
      });
  }, []);

  const showTrackerDetails = (tracker, trackers) => {
    history.push({
      pathname: MARKET_PURCHASE_OFFSETS_TRACKER,
      state: {
        tracker,
        trackers,
      },
    });
  };

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else if (trackers?.length === 0) {
    content = (
      <div className="table__no_content mt-2">
        <p>
          {t("data-management.environmental.market-purchase.tracker-list.no-content")}
        </p>
      </div>
    );
  } else {
    content = offsetTypes.map((offsetType) => {
      const trackerCount = trackers.filter(
        (tracker) => tracker["offset_type"]["id"] === offsetType.id
      ).length;

      if (trackerCount === 0) {
        return null;
      }

      return (
        <Accordion
          key={offsetType.id}
          activeKey={activeKey === offsetType.id ? activeKey : null}
          className="accordion-header"
        >
          <Accordion.Item eventKey={offsetType.id}>
            <GVDSAccordionHeader
              isExpanded={activeKey === offsetType.id}
              title={offsetType.name}
              count={trackerCount}
              onClick={() =>
                activeKey === offsetType.id
                  ? setActiveKey(null)
                  : setActiveKey(offsetType.id)
              }
            />
            <Accordion.Body>
              <GVDSTableDisplay className="market-purchase-trackers-table">
                <thead>
                  <tr>
                    <th className="market-purchase-trackers-table__tracker-id">
                      {t("data-management.environmental.market-purchase.tracker-list.table-header.tracker")}
                    </th>
                    <th className="market-purchase-trackers-table__last_updated">
                      {t("shared-table-header.last-updated-by")}
                    </th>
                    <th className="market-purchase-trackers-table__actions gvds-table-cell__actions-header">
                      {t("shared-input-label.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortBy(trackers, ["facility.name", "name"])
                    .filter(
                      (tracker) =>
                        tracker["offset_type"]["id"] === offsetType.id
                    )
                    .map((tracker) => (
                      <tr
                        key={tracker.id}
                        className="is-clickable"
                        onClick={() => showTrackerDetails(tracker, trackers)}
                      >
                        <td className="market-purchase-trackers-table__tracker-id">
                          {tracker["tracker_id"]}{" "}
                          {selectedInventory.type === RESOURCES.SITE && (
                            <div className="table-data__subtext">
                              {tracker["facility"]["name"]}
                            </div>
                          )}
                        </td>
                        <td className="market-purchase-trackers-table__last-updated">
                          {tracker["last_activity"] && (
                            <LastActivityDisplay
                              lastActivity={tracker["last_activity"]}
                            />
                          )}
                        </td>
                        <td className="market-purchase-trackers-table__actions">
                          <div className="gvds-table-cell__actions-container">
                            <GVDSIconButton
                              className="view-tracker-button"
                              variant={iconButtonVariant.tertiary}
                              icon={<GVDSIcon Icon={IconExternalLink} />}
                              tooltipText="View Details"
                              onClick={(e) => {
                                e.stopPropagation();
                                showTrackerDetails(tracker, trackers);
                              }}
                            />
                            {!permissionCtx.isLoadingPermissions &&
                              permissionCtx.permissions[
                                PERMISSIONS
                                  .MARKET_PURCHASE_OFFSETS_RECORD_CREATE
                              ] && (
                                <GVDSIconButton
                                  className="input-eac-record-button"
                                  variant={iconButtonVariant.tertiary}
                                  icon={<GVDSIcon Icon={IconCirclePlus} />}
                                  tooltipText="Input"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onTrackerInput(tracker, [tracker]);
                                  }}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </GVDSTableDisplay>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    });
  }
  return (
    <>
      <div className="d-flex mb-2">
        <h2 className="d-flex text-capitalize">
          {t("data-management.environmental.market-purchase.section-title")}
          <span className="body-1 ms-1">
            <InfoTooltip info={t("data-management.environmental.market-purchase.section-description")} />
          </span>
        </h2>
        <div className="ms-auto d-flex flex-row-reverse align-items-center">
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.MARKET_PURCHASE_OFFSETS_RECORD_CREATE
            ] && (
              <GVDSButton
                variant={buttonVariant.primary}
                className="market-purchase-records__single-input"
                onClick={() => onTrackerInput(null, trackers)}
                text={t(
                  "data-management.environmental.market-purchase.input-button.single"
                )}
              />
            )}
        </div>
      </div>
      {content}
    </>
  );
};

export default ViewAllMarketPurchaseTrackers;
