import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../context/ToastContext";
import { EditorState } from "draft-js";
import { MultipleFilesUploader } from "../../common/FileAttachments";
import CertificationAdminService from "../../../services/PortalAdmin/Certification/CertificationAdminService";
import Col from "react-bootstrap/Col";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import GVDSFormFieldWithCharacterCount from "../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import {
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import { POSITION } from "../../../config/constants";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { RTFUtils } from "../../../services/UtilsService";
import { useTranslation } from "react-i18next";
import TextEditor from "../../common/TextEditor";

export const CertificationForm = ({ certification, onSuccess }) => {
  const toastContext = useContext(ToastContext);

  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(null);
  const [version, setVersion] = useState(null);
  const [descriptionEditorState, setDescriptionEditorState] = useState(
    EditorState.createEmpty()
  );
  const [logoFilesInput, setLogoFilesInput] = useState([]);
  const [verificationDetailsEditorState, setVerificationDetailsEditorState] =
    useState(EditorState.createEmpty());

  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    if (certification) {
      setName(certification.name);
      setVersion(certification.version);
      setDescriptionEditorState(certification.description);
      setVerificationDetailsEditorState(certification.verificationDetails);
      setLogoFilesInput(certification.logoFile);
    } else {
      setName(null);
      setVersion(null);
      setDescriptionEditorState(EditorState.createEmpty());
      setVerificationDetailsEditorState(EditorState.createEmpty());
      setLogoFilesInput([]);
    }
  }, [certification]);

  const isLogoFileInputValid = () => {
    const activeFiles = logoFilesInput.filter((file) => !file.isToBeDeleted());

    return activeFiles.length <= 1;
  };

  const isInputsValid = () => {
    return (
      name &&
      !RTFUtils.isEmpty(descriptionEditorState) &&
      isLogoFileInputValid() &&
      !RTFUtils.isEmpty(verificationDetailsEditorState)
    );
  };

  const onSave = async () => {
    setIsValidated(true);

    if (!isInputsValid()) {
      return;
    }

    setIsSaving(true);
    if (certification) {
      try {
        const updatedCertification =
          await CertificationAdminService.updateCertification(
            certification.id,
            name,
            version,
            RTFUtils.convertEditorStateToHTML(descriptionEditorState),
            logoFilesInput,
            RTFUtils.convertEditorStateToHTML(verificationDetailsEditorState)
          );
        toastContext.addSuccessToast(
          <span>
            Certification successfully updated.
            {certification.isPublished
              ? ""
              : " It will remain hidden until published."}
          </span>
        );
        setLogoFilesInput(updatedCertification.logoFile);
      } catch (error) {
        if (error.response.status === 400) {
          toastContext.addFailToast(
            <span>{error.response.data?.message}</span>
          );
        } else {
          toastContext.addFailToast(
            <span>Failed to update certification</span>
          );
        }
      } finally {
        setIsSaving(false);
      }
    } else {
      try {
        const certificationId =
          await CertificationAdminService.createCertification(
            name,
            version,
            RTFUtils.convertEditorStateToHTML(descriptionEditorState),
            logoFilesInput.length > 0 ? logoFilesInput[0] : null,
            RTFUtils.convertEditorStateToHTML(verificationDetailsEditorState)
          );
        toastContext.addSuccessToast(
          <span>
            Certification successfully created. It will remain hidden until
            published.
          </span>
        );
        onSuccess(certificationId);
      } catch (error) {
        if (error.response.status === 400) {
          toastContext.addFailToast(
            <span>{error.response.data?.message}</span>
          );
        } else {
          toastContext.addFailToast(
            <span>Failed to create new certification</span>
          );
        }
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      <Col md={7}>
        <GVFormGroup controlId="name">
          <Form.Label>Certification name</Form.Label>
          <GVDSFormFieldWithCharacterCount
            name="name"
            value={name}
            onInput={setName}
            placeholder="Enter certification name"
            maxLength={50}
            statusMetadata={
              isValidated && !name
                ? FormFieldStatusMetadata.getError(
                    "This field cannot be empty."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>

        <GVFormGroup controlId="version">
          <Form.Label>Certification version</Form.Label>
          <span className="optional-form-label ms-1">(Optional)</span>
          <GVDSFormField
            name="version"
            value={version}
            onInput={setVersion}
            placeholder="Enter certification version"
          />
        </GVFormGroup>

        <GVFormGroup controlId="description">
          <Form.Label>Description</Form.Label>
          <TextEditor
            editorState={descriptionEditorState}
            onEditorStateChange={setDescriptionEditorState}
            placeholder="Reference description for this certification"
            toolbar={{
              options: [
                "blockType",
                "inline",
                "list",
                "link",
                "image",
                "remove",
                "history",
              ],
            }}
            statusMetadata={
              isValidated && RTFUtils.isEmpty(descriptionEditorState)
                ? FormFieldStatusMetadata.getError(
                    "This field cannot be empty."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>

        <GVFormGroup>
          <Form.Label>
            Certification logo
            <span className="optional-form-label ms-1">(Optional)</span>
          </Form.Label>
          <MultipleFilesUploader
            files={logoFilesInput}
            setFiles={setLogoFilesInput}
            useS3PresignedURL={true}
            fileTypes="image/*"
          />
          {!isLogoFileInputValid() && (
            <GVDSFormErrorMessage errorMsg="Maximum 1 logo file is allowed" />
          )}
        </GVFormGroup>

        <GVFormGroup controlId="verificationDetails">
          <Form.Label>Ready for audit?</Form.Label>
          <TextEditor
            editorState={verificationDetailsEditorState}
            onEditorStateChange={setVerificationDetailsEditorState}
            placeholder="Describe why site needs the certification, how to prepare for the final check, and any additional information regarding the certification level"
            toolbar={{
              options: [
                "blockType",
                "inline",
                "list",
                "link",
                "image",
                "remove",
                "history",
              ],
            }}
            statusMetadata={
              isValidated && RTFUtils.isEmpty(verificationDetailsEditorState)
                ? FormFieldStatusMetadata.getError(
                    "This field cannot be empty."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>

        <div className="d-flex justify-content-end">
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            text="Save"
            onClickAsyncFunc={onSave}
            disabled={isSaving || !isInputsValid()}
          />
        </div>
      </Col>
    </>
  );
};

export const MoveCertificationDialog = ({
  show,
  closeModal,
  onSuccess,
  certificationToBeMoved,
  allCertifications,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const [isValidated, setIsValidated] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const [position, setPosition] = useState(POSITION.BEFORE);
  const [certificationToMoveTo, setCertificationToMoveTo] = useState(null);

  const resetState = () => {
    setCertificationToMoveTo(null);
    setIsValidated(false);
    setPosition(POSITION.BEFORE);
  };

  const handleClose = () => {
    if (!isMoving) {
      closeModal();
    }
  };

  const moveCertification = async () => {
    setIsValidated(true);

    if (certificationToMoveTo) {
      setIsMoving(true);
      try {
        const updatedCertifications =
          await CertificationAdminService.moveCertification(
            certificationToBeMoved,
            certificationToMoveTo,
            position
          );
        resetState();
        onSuccess(updatedCertifications);
        toastContext.addSuccessToast(
          <span>Certification successfully moved.</span>
        );
      } catch (error) {
        toastContext.addFailToast(<span>Failed to move certification.</span>);
      } finally {
        setIsMoving(false);
      }
    }
  };

  return (
    <GVDSModal
      title="Move this certification to"
      size={GVDSModal.Size.small}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>
        <GVFormGroup>
          <Form.Label>Position</Form.Label>
          <Form.Check
            id="before"
            label="Before"
            type="radio"
            checked={position === POSITION.BEFORE}
            onChange={() => setPosition(POSITION.BEFORE)}
          />
          <Form.Check
            id="after"
            label="After"
            type="radio"
            checked={position === POSITION.AFTER}
            onChange={() => {
              setPosition(POSITION.AFTER);
            }}
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Certification Name</Form.Label>
          <GVDSFormSingleSelect
            placeholder="Select certification"
            className="certification-to-move-to"
            isSearchable={true}
            value={
              certificationToMoveTo
                ? {
                    value: certificationToMoveTo,
                    label: certificationToMoveTo.name,
                  }
                : null
            }
            options={allCertifications.map((q, index) => {
              const numberOrder = index + 1;
              const label = `${numberOrder}. ${q.name}`;
              return { value: q, label: label };
            })}
            isOptionDisabled={(option) =>
              option.value === certificationToBeMoved
            }
            onSelect={(selected) => {
              setCertificationToMoveTo(selected.value);
            }}
            statusMetadata={
              isValidated && !certificationToMoveTo
                ? FormFieldStatusMetadata.getError(
                    "Please select a certification"
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            onClickAsyncFunc={moveCertification}
            text="Move Certification"
            disabled={isMoving}
          />
        </>
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const PublishUnpublishCertificationDialog = ({
  show,
  isSaving,
  closeModal,
  certification,
  confirmProcess,
}) => {
  const { t } = useTranslation();

  let confirmationTitle;
  let confirmationText;
  let confirmationButton;
  if (certification.isPublished) {
    confirmationTitle = "Unpublish";
    confirmationText =
      "Are you sure you want to hide this certification from user view?";
    confirmationButton = "Unpublish";
  } else {
    confirmationTitle = "Publish";
    confirmationText =
      "Are you sure you want to make this certification visible to users? ";
    confirmationButton = "Publish";
  }

  const handleClose = () => {
    if (!isSaving) {
      closeModal();
    }
  };

  return (
    <GVDSModal
      title={confirmationTitle}
      size={GVDSModal.Size.small}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>{confirmationText}</GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          text={t("shared-modal.footer.cancel")}
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          onClickAsyncFunc={confirmProcess}
          text={confirmationButton}
          disabled={isSaving}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};
