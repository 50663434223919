import React, { useContext, useEffect, useState } from "react";
import uniq from "lodash/uniq";

import Container from "react-bootstrap/Container";

import withAuthentication from "../HOC/withAuthentication";
import LoadingSpinner from "../common/LoadingSpinner";
import SiteService from "../../services/SiteService";
import {
  CREATE_SITE,
  getViewContractDetailsPath,
  getViewSiteDetailsPath,
  HOME,
} from "../../config/ROUTES_NAME";
import FilterSearchBox from "../../gvds-components/common/FilterSearchBox";
import UserInventoryContext, {
  ACTIVE_SITES,
  INACTIVE_SITES,
  INACTIVE_SITES_STORAGE_KEY,
} from "../../context/UserInventoryContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  generateSelectedQueryParam,
  getRedirectURLWithCurrentParam,
} from "../common/QueryHandler";
import SiteStatusBadge from "./SiteStatusBadge";
import UserAvatar from "../common/UserAvatar";
import CommentTooltip from "../common/Tooltip/CommentTooltip";
import { DateTimeUtils, UtilsService } from "../../services/UtilsService";
import SubscriptionSharedUtils from "../SystemToolbox/Subscription/SubscriptionSharedUtils";
import FeatureContext from "../../context/FeatureContext";
import ToastContext from "../../context/ToastContext";
import { PERMISSIONS, RESOURCES, SITE_STATUS } from "../../config/constants";
import PermissionsContext from "../../context/PermissionsContext";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import Spacer from "../../gvds-components/Layout/Spacer";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import useGVDSTableCtrl from "../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../config/search-config";
import GVDSTable, {
  GVDSTableRowSelectCheckbox,
  MULTI_SELECT_DATAKEY,
  SORTING_TYPES,
} from "../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";
import GVDSTableBulkActionBar from "../../gvds-components/Table/Controls/GVDSTableBulkActionBar";

const ViewAllSites = () => {
  const permissionsCtx = useContext(PermissionsContext);
  const history = useHistory();
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const location = useLocation();

  const [allSites, setAllSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMultipleSelectMode, setIsMultipleSelectMode] = useState(false);
  const [selectedInactiveSiteIds, setSelectedInactiveSiteIds] = useState(
    JSON.parse(sessionStorage.getItem(INACTIVE_SITES_STORAGE_KEY)) || []
  );

  useEffect(() => {
    SiteService.getAllSites()
      .then((sitesResponse) => {
        setAllSites(sitesResponse);
        setIsLoading(false);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load sites.</span>);
      });
  }, []);

  const switchToMultipleSelectMode = () => {
    setSelectedInactiveSiteIds(
      JSON.parse(sessionStorage.getItem(INACTIVE_SITES_STORAGE_KEY)) || []
    );
    setIsMultipleSelectMode(true);
  };

  const toggleInactiveSiteId = (siteId) => {
    setSelectedInactiveSiteIds(
      UtilsService.toggleItem(selectedInactiveSiteIds, siteId)
    );
  };

  const updateUserInventoryContracts = (inactiveSiteIds) => {
    const contracts = userInventory.contracts.get ?? [];
    if (inactiveSiteIds.length === 0) {
      const newContracts = contracts.filter((c) => c.id !== INACTIVE_SITES);
      userInventory.contracts.set(newContracts);
      if (userInventory.selectedContractId.get === INACTIVE_SITES) {
        userInventory.selectedContractId.set(newContracts[0].id);
      }
    } else if (inactiveSiteIds.length > 0) {
      const index = contracts.findIndex((c) => c.id === INACTIVE_SITES);
      if (index === -1) {
        contracts.push({
          id: INACTIVE_SITES,
          name: INACTIVE_SITES,
        });
        userInventory.contracts.set(contracts);
      }

      if (userInventory.selectedContractId.get === INACTIVE_SITES) {
        userInventory.loadUserInventory();
      } else {
        userInventory.selectedContractId.set(INACTIVE_SITES);
      }
    }
  };

  const goToSelectedInactiveSite = (siteId) => {
    const inactiveSiteIds = selectedInactiveSiteIds;
    if (siteId && !inactiveSiteIds.includes(siteId)) {
      inactiveSiteIds.push(siteId);
    }

    updateUserInventoryContracts(inactiveSiteIds);

    sessionStorage.setItem(
      INACTIVE_SITES_STORAGE_KEY,
      JSON.stringify(inactiveSiteIds)
    );

    if (siteId || inactiveSiteIds.length > 0) {
      location.search = generateSelectedQueryParam(
        RESOURCES.SITE,
        siteId ?? inactiveSiteIds[0],
        INACTIVE_SITES
      );
      history.push(getRedirectURLWithCurrentParam(HOME, location));
    }
  };

  const cancelMultipleSelectMode = () => {
    setIsMultipleSelectMode(false);
    setSelectedInactiveSiteIds(
      JSON.parse(sessionStorage.getItem(INACTIVE_SITES_STORAGE_KEY)) || []
    );
  };

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <ListSitesView
        sites={allSites}
        isMultipleSelectMode={isMultipleSelectMode}
        cancelMultipleSelectMode={cancelMultipleSelectMode}
        selectedInactiveSiteIds={selectedInactiveSiteIds}
        toggleInactiveSiteId={toggleInactiveSiteId}
        goToSelectedInactiveSite={goToSelectedInactiveSite}
      />
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>All Sites</h1>
          {!permissionsCtx.isLoadingPermissions && (
            <>
              <Spacer />
              {permissionsCtx.permissions[PERMISSIONS.PORTAL_ADMIN] && (
                <GVDSButton
                  variant={buttonVariant.secondary}
                  className="select-multiple"
                  disabled={isMultipleSelectMode}
                  onClick={switchToMultipleSelectMode}
                  text="Access multiple inactive sites"
                />
              )}
              {permissionsCtx.permissions[PERMISSIONS.SITE_CREATE] && (
                <GVDSButton
                  variant={buttonVariant.primary}
                  className="create-site"
                  onClick={() => {
                    history.push(CREATE_SITE);
                  }}
                  icon={<GVDSIcon Icon={IconCirclePlus} />}
                  text="Create Site"
                />
              )}
            </>
          )}
        </PageHeader.Title>
      </PageHeader>
      {content}
    </Container>
  );
};

export const ListSitesView = ({
  sites,
  isMultipleSelectMode,
  cancelMultipleSelectMode,
  selectedInactiveSiteIds,
  toggleInactiveSiteId,
  goToSelectedInactiveSite,
}) => {
  const location = useLocation();
  const history = useHistory();
  const featureCtx = useContext(FeatureContext);
  const allFeatures = featureCtx.features;
  const userInventory = useContext(UserInventoryContext);
  const userContracts = userInventory.contracts.get;

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    sites,
    SystemToolboxSearchKeysByPageName.sites,
    {
      name: SORTING_TYPES.asc,
    },
    {},
    null
  );

  useEffect(() => {
    if (isMultipleSelectMode) {
      setFilterKeys({ ...filterKeys, status: [SITE_STATUS.INACTIVE] });
    } else {
      setFilterKeys({
        ...filterKeys,
        status: [SITE_STATUS.ACTIVE, SITE_STATUS.INACTIVE],
      });
    }
  }, [isMultipleSelectMode]);

  let columns = [
    {
      header: "Site Name",
      dataKey: "name",
      sortable: true,
      headerStyle: { width: "300px" },
      renderer: (s) => (
        <div className="site-name">
          <Link
            to={getRedirectURLWithCurrentParam(
              getViewSiteDetailsPath(s.id),
              location
            )}
            className="view-site"
            onClick={(e) => e.stopPropagation()}
          >
            {s.name}
          </Link>
        </div>
      ),
    },
    {
      header: "Status",
      dataKey: "status",
      sortable: true,
      headerStyle: { width: "100px" },
      renderer: (s) => <SiteStatusBadge status={s.status} />,
    },
    {
      header: "Site Admins",
      dataKey: "users",
      headerStyle: { width: "150px" },
      renderer: (s) => (
        <div className="users">
          {s.users.length === 0 ? (
            <span className="gvds-color--gray6">Unassigned</span>
          ) : null}
          {s.users
            .sort((u1, u2) => u1.email.localeCompare(u2.email))
            .map((u, index) => (
              <span key={index} className="site-admin-avatar">
                <UserAvatar fullName={u.full_name} userEmail={u.email} />
              </span>
            ))}
        </div>
      ),
    },
    {
      header: "Subscribed Features",
      dataKey: "features",
      headerStyle: { width: "200px" },
      renderer: (s) => {
        return s.features && s.features.length > 0 ? (
          <>
            {SubscriptionSharedUtils.getShorthandFeatureDisplay(
              allFeatures,
              s.features,
              "right"
            )}
          </>
        ) : (
          "-"
        );
      },
    },
    {
      header: "Associated Contracts",
      dataKey: "associatedContractNames",
      headerStyle: { width: "300px" },
      renderer: (s) => {
        let content;
        if (
          !s.associatedContractNames ||
          s.associatedContractNames.length === 0
        ) {
          content = (
            <span className="gvds-color--gray6">No associated contracts.</span>
          );
        } else {
          content = (
            <>
              {s.associatedContracts.map((contract, index) => (
                <span key={index} className="associated-contract">
                  {index > 0 && ", "}
                  {contract.id !== ACTIVE_SITES &&
                  userContracts?.find(
                    (userContract) => userContract.id === contract.id
                  ) ? (
                    <Link
                      to={getRedirectURLWithCurrentParam(
                        getViewContractDetailsPath(contract.id),
                        location
                      )}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {contract.name}
                    </Link>
                  ) : (
                    contract.name
                  )}
                </span>
              ))}
            </>
          );
        }

        return <div className="associated-contracts">{content}</div>;
      },
    },
    {
      header: "End Period",
      dataKey: "endDate",
      headerStyle: {
        width: "120px",
      },
      sortable: true,
      renderer: (s) =>
        s.endDate ? DateTimeUtils.formatLocalDate(s.endDate) : "-",
    },
    {
      header: "Account Manager",
      dataKey: "accountManager",
      headerStyle: { width: "150px" },
      renderer: (s) => (
        <div>
          {!s.accountManager ? (
            <span className="gvds-color--gray6">
              Unassigned
            </span>
          ) : (
            <span>
              <UserAvatar
                fullName={s.accountManager["full_name"]}
                userEmail={s.accountManager["email"]}
              />
            </span>
          )}
        </div>
      ),
    },
    {
      header: "Comment",
      dataKey: "comment",
      headerStyle: {
        width: "120px",
      },
      renderer: (s) =>
        s.comment ? <CommentTooltip comment={s.comment} /> : "-",
    },
  ];

  if (isMultipleSelectMode) {
    columns.unshift({
      header: "",
      dataKey: MULTI_SELECT_DATAKEY,
      renderer: (s) => {
        if (s.status === SITE_STATUS.INACTIVE) {
          return (
            <div>
              <GVDSTableRowSelectCheckbox
                key={s.id}
                checked={selectedInactiveSiteIds.indexOf(s.id) >= 0}
                onChange={() => toggleInactiveSiteId(s.id)}
              />
            </div>
          );
        } else {
          return null;
        }
      },
    });
  } else {
    columns.push({
      header: "",
      dataKey: "accessSite",
      headerStyle: {
        width: "50px",
      },
      renderer: (s) =>
        s.status === SITE_STATUS.INACTIVE ? (
          <GVDSTextButton
            text="Access this site"
            onClick={(e) => {
              e.stopPropagation();
              goToSelectedInactiveSite(s.id);
            }}
          />
        ) : null,
    });
  }

  return (
    <div>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        onRowClick={(site) => {
          const siteUrl = getRedirectURLWithCurrentParam(
            getViewSiteDetailsPath(site.id),
            location
          );

          history.push(siteUrl);
        }}
        noContent={
          sites.length === 0 ? (
            <div className="table__no_content">No sites</div>
          ) : (
            filteredSortedData.length === 0 && (
              <div className="table__no_content">
                No sites. Try changing your filter.
              </div>
            )
          )
        }
        viewControl={
          <>
            <GVDSTableCtrlContainer>
              <FilterSearchBox
                className="site-search-box"
                placeholder="Search for a site"
                value={searchText}
                onInput={setSearchText}
              />
              <GVDSTableCtrlMultiSelect
                options={uniq(
                  sites.map((s) => s.associatedContractNames).flat()
                ).sort()}
                prefix="Contract"
                onChange={(filterKs) =>
                  setFilterKeys({
                    ...filterKeys,
                    associatedContractNames: filterKs,
                  })
                }
              />
              <GVDSTableCtrlMultiSelect
                options={uniq(sites.map((c) => c.status).flat())}
                prefix="Status"
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, status: filterKs })
                }
                defaultSelected={filterKeys.status}
              />
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            {isMultipleSelectMode && (
              <GVDSTableBulkActionBar
                selectedRowsCount={selectedInactiveSiteIds.length}
                actionButtons={
                  <>
                    <GVDSButton
                      variant={buttonVariant.primary}
                      disabled={selectedInactiveSiteIds.length === 0}
                      onClick={(e) => {
                        e.preventDefault();
                        goToSelectedInactiveSite();
                      }}
                      text={"Access selected sites"}
                    />
                    <GVDSButton
                      variant={buttonVariant.tertiary}
                      onClick={(e) => {
                        e.preventDefault();
                        cancelMultipleSelectMode();
                      }}
                      text="Cancel"
                    />
                  </>
                }
              />
            )}
          </>
        }
      />
    </div>
  );
};

export default withAuthentication(ViewAllSites);
