import DashboardExpandedModalView from "../../DashboardExpandedModalView";
import React from "react";
import { ExpandedPerformanceComparisonChartViewHeader } from "./PerformanceComparisonChartViewHeaders";
import { expandedModalViewOptionEnum } from "../../Models/DashboardModels";
import PerformanceComparisonChartViewContent from "./PerformanceComparisonChartViewContent";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import { NumberService } from "../../../../services/UtilsService";
import { performanceComparisonChartConfig } from "./PerformanceComparisonChartViewConfig";
import LoadingSpinnerInFullPage from "../../../common/LoadingSpinnerInFullPage";

const ExpandedTableView = ({ chartData, unit }) => {
  const tableRowData = chartData.toTableRowData();
  const selectedMonths = performanceComparisonChartConfig.monthTicks;

  return (
    <div className="dashboard--expanded-view__table-container">
      <GVDSTableDisplay>
        <thead>
          <tr>
            <th></th>
            {selectedMonths.map((month) => (
              <th style={{ textAlign: "right" }}>
                {month} ({unit})
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRowData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {rowData.map((cellData, columnIndex) => (
                <td
                  key={`row-${columnIndex}`}
                  align={
                    columnIndex ===
                    performanceComparisonChartConfig.tableTitleColumnIndex
                      ? "left"
                      : "right"
                  }
                >
                  {cellData === null
                    ? ""
                    : columnIndex ===
                      performanceComparisonChartConfig.tableTitleColumnIndex
                    ? cellData
                    : rowIndex ===
                      performanceComparisonChartConfig.tablePercentageRowIndex
                    ? `${NumberService.format(cellData, 2, 2)}%`
                    : NumberService.format(cellData, 2, 2)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </GVDSTableDisplay>
    </div>
  );
};

const ExpandedPerformanceComparisonChartViewBody = ({
  chartData,
  unit,
  intensityUnit,
  selectedExpandedViewOption,
}) => {
  return (
    <div className="dashboard--performance-comparison__expanded-chart-view__content-container">
      {selectedExpandedViewOption === expandedModalViewOptionEnum.CHART ? (
        <PerformanceComparisonChartViewContent
          chartData={chartData}
          unit={unit}
          intensityUnit={intensityUnit}
        />
      ) : (
        <ExpandedTableView chartData={chartData} unit={unit} />
      )}
    </div>
  );
};

const ExpandedPerformanceComparisonChartView = ({
  isShowExpandedChartView,
  onCloseModal,
  title,
  hasDataGaps,
  period,
  subtopic,
  intensity,
  unit,
  intensityUnit,
  chartData,
  selectedExpandedViewOption,
  onExpandedViewOptionChange,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  return (
    <>
      <DashboardExpandedModalView
        id={performanceComparisonChartConfig.expandedChartViewId}
        isShow={isShowExpandedChartView}
        closeModal={onCloseModal}
        hideCloseButton={isDownloadingChart}
        header={
          <ExpandedPerformanceComparisonChartViewHeader
            title={title}
            period={period}
            hasDataGaps={hasDataGaps}
            subtopic={subtopic}
            intensity={intensity}
            selectedExpandedViewOption={selectedExpandedViewOption}
            onExpandedViewOptionChange={onExpandedViewOptionChange}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        }
        body={
          <ExpandedPerformanceComparisonChartViewBody
            chartData={chartData}
            unit={unit}
            intensityUnit={intensityUnit}
            selectedExpandedViewOption={selectedExpandedViewOption}
          />
        }
      />
      {isShowExpandedChartView && isDownloadingChart && (
        <LoadingSpinnerInFullPage />
      )}
    </>
  );
};

export default ExpandedPerformanceComparisonChartView;
