import React, { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import withAuthentication from "../../HOC/withAuthentication";
import UserService from "../../../services/UserService";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import AllUsersList from "./AllUsersList";
import PendingUsersList from "./PendingUsersList";

const UserManagement = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = () => {
    setIsLoadingUsers(true);
    UserService.getAllUsers()
      .then((usersResponse) => setAllUsers(usersResponse))
      .finally(() => setIsLoadingUsers(false));
  };

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Users</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="team-invite-request">
        <Tab eventKey="team-invite-request" title="Team Invite Request">
          <PendingUsersList onApproveInvite={loadUsers} />
        </Tab>
        <Tab eventKey="all-users" title="All Users">
          <AllUsersList
            allUsers={allUsers}
            loadUsers={loadUsers}
            isLoadingUsers={isLoadingUsers}
          />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default withAuthentication(UserManagement);
