import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { UserManagedTagDisplay } from "./UserManagedTagDisplay";
import { RESOURCES } from "../../../config/constants";
import TooltipPersistentOnHover, {
  TextTooltipPersistentOnHover,
} from "../Tooltip/TooltipPersistentOnHover";
import { ADMIN_TAGS } from "../../../config/ROUTES_NAME";
import { getRedirectURLWithCurrentParam } from "../QueryHandler";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { InventoryUtils } from "../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSTag from "../../../gvds-components/common/GVDSTag";
import InfoTooltip from "../Tooltip/InfoTooltip";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import { Trans, useTranslation } from "react-i18next";

export const ManageTagsLink = () => {
  const { t } = useTranslation();

  const location = useLocation();

  return (
    <div>
      <Trans i18nKey="user-managed-tag.manage-tags-link">
        Manage tags{" "}
        <Link
          to={getRedirectURLWithCurrentParam(ADMIN_TAGS, location)}
          target="_blank"
        >
          here
        </Link>
        .
      </Trans>
    </div>
  );
};

const TagExplanationByResourceTypeBlurb = ({ tagResourceType }) => {
  const { t } = useTranslation();

  const isPortfolioResourceType = tagResourceType === RESOURCES.PORTFOLIO;
  return (
    <div className="mb-3">
      <div className="about-tag-example__label">
        <div className="gvds-text--heading3 me-2">
          {isPortfolioResourceType
            ? t("user-managed-tag.shared.portfolio-tag")
            : t("user-managed-tag.shared.site-tag")}
        </div>
        <UserManagedTagDisplay
          tagText={
            isPortfolioResourceType
              ? t("user-managed-tag.about-tags.portfolio-tag-example")
              : t("user-managed-tag.about-tags.site-tag-example")
          }
          resourceType={tagResourceType}
        />
      </div>
      <div>
        {isPortfolioResourceType
          ? t("user-managed-tag.about-tags.portfolio-tag-description")
          : t("user-managed-tag.about-tags.site-tag-description")}
      </div>
    </div>
  );
};

const ModuleTagExplanationBlurb = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-tag-example__label">
        <div className="gvds-text--heading3 me-2">
          {t("user-managed-tag.shared.module-tag")}
        </div>
        <GVDSTag variant={GVDSTag.Variants.system}>
          {t("user-managed-tag.about-tags.module-tag-example")}
        </GVDSTag>
      </div>
      <p>{t("user-managed-tag.about-tags.module-tag-description")}</p>
    </>
  );
};

export const AboutTagsModal = ({ show, close }) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const showPortfolioTag =
    InventoryUtils.isNotIndividualSiteSubscription(userInventory);

  return (
    <GVDSModal
      show={show}
      onHide={close}
      size={GVDSModal.Size.medium}
      title={t("user-managed-tag.about-tags.modal-title")}
    >
      <GVDSModal.Body>
        <div className="mb-3">{t("user-managed-tag.shared.tag-promotion")}</div>
        {showPortfolioTag && (
          <TagExplanationByResourceTypeBlurb
            tagResourceType={RESOURCES.PORTFOLIO}
          />
        )}
        <TagExplanationByResourceTypeBlurb tagResourceType={RESOURCES.SITE} />
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={close}
          text={t("shared-modal.footer.close")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const AboutTagsInFileStorageModal = ({ show, close }) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const showPortfolioTag =
    InventoryUtils.isNotIndividualSiteSubscription(userInventory);

  return (
    <GVDSModal
      show={show}
      onHide={close}
      size={GVDSModal.Size.medium}
      title={t("user-managed-tag.about-tags.modal-title")}
    >
      <GVDSModal.Body>
        <p>{t("user-managed-tag.shared.tag-promotion")}</p>
        <ModuleTagExplanationBlurb />
        {showPortfolioTag && (
          <TagExplanationByResourceTypeBlurb
            tagResourceType={RESOURCES.PORTFOLIO}
          />
        )}
        <TagExplanationByResourceTypeBlurb tagResourceType={RESOURCES.SITE} />
        <ManageTagsLink />
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={close}
          text={t("shared-modal.footer.close")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const AboutTagsTooltip = ({
  asText = true,
  triggerContent,
  tagResourceType,
  withAdminTagLink = true,
}) => {
  const tooltipContent = (
    <>
      <TagExplanationByResourceTypeBlurb tagResourceType={tagResourceType} />

      {withAdminTagLink && <ManageTagsLink />}
    </>
  );

  if (asText) {
    return (
      <TextTooltipPersistentOnHover
        triggerContent={triggerContent}
        tooltipContent={tooltipContent}
      />
    );
  } else {
    return (
      <TooltipPersistentOnHover
        triggerContent={triggerContent}
        tooltipContent={tooltipContent}
      />
    );
  }
};

export const AboutTagsInInitiativeTooltip = () => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const showPortfolioTag =
    InventoryUtils.isNotIndividualSiteSubscription(userInventory);

  const tooltipContent = (
    <div>
      <div className="gvds-text--heading3">
        {t("user-managed-tag.about-tags.modal-title")}
      </div>
      <div className="mb-2">{t("user-managed-tag.shared.tag-promotion")}</div>
      {showPortfolioTag && (
        <TagExplanationByResourceTypeBlurb
          tagResourceType={RESOURCES.PORTFOLIO}
        />
      )}
      <TagExplanationByResourceTypeBlurb tagResourceType={RESOURCES.SITE} />
    </div>
  );
  return <InfoTooltip info={tooltipContent} />;
};

export default AboutTagsTooltip;
