import Dropdown from "react-bootstrap/Dropdown";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import Form from "react-bootstrap/Form";
import {
  FormFieldStatusMetadata,
  isEndDateBeforeStartDate,
} from "../../../gvds-components/Forms/GVDSFormShared";
import Spacer from "../../../gvds-components/Layout/Spacer";
import React, { forwardRef, useState } from "react";
import { DateTimeUtils } from "../../../services/UtilsService";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCalendarEvent } from "@tabler/icons-react";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import moment from "moment/moment";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";

const firstMonthInYearInMonthIndex = 0;
const lastFullMonth = moment().subtract(1, "months").endOf("month").toDate();

const quickfillOptions = [
  {
    label: "Trailing 12 months",
    value: "trailing-12m",
    dateRange: {
      start: moment().subtract(12, "months").startOf("month").toDate(),
      end: moment().subtract(1, "months").endOf("month").toDate(),
    },
  },
  {
    label: "This year to date",
    value: "ytd",
    dateRange: {
      start: moment().startOf("year").toDate(),
      end:
        moment().month() === firstMonthInYearInMonthIndex
          ? moment().endOf("month").toDate()
          : moment().subtract(1, "months").endOf("month").toDate(),
    },
  },
  {
    label: "This month",
    value: "current-month",
    dateRange: {
      start: moment().startOf("month").toDate(),
      end: moment().toDate(),
    },
  },
  {
    label: "This quarter",
    value: "current-quarter",
    dateRange: {
      start: moment().quarter(moment().quarter()).startOf("quarter")._d,
      end: moment().toDate(),
    },
  },
  {
    label: "This year",
    value: "current-year",
    dateRange: {
      start: moment().startOf("year").toDate(),
      end: moment().toDate(),
    },
  },
  {
    label: "Last month",
    value: "last-month",
    dateRange: {
      start: moment().subtract(1, "months").startOf("month").toDate(),
      end: moment().subtract(1, "months").endOf("month").toDate(),
    },
  },
  {
    label: "Last quarter",
    value: "last-quarter",
    dateRange: {
      start: moment()
        .quarter(moment().quarter() - 1)
        .startOf("quarter")._d,
      end: moment()
        .quarter(moment().quarter() - 1)
        .endOf("quarter")._d,
    },
  },
  {
    label: "Last year",
    value: "last-year",
    dateRange: {
      start: moment().startOf("year").subtract(1, "year").toDate(),
      end: moment().endOf("year").subtract(1, "year").toDate(),
    },
  },
];

const getActiveQuickfillByPeriod = (start, end) => {
  if (!start || !end) return null;

  return quickfillOptions.find((option) => {
    const { start: optionStart, end: optionEnd } = option.dateRange;
    return moment(optionStart).isSame(start) && moment(optionEnd).isSame(end);
  });
};

const QuickfillButton = ({
  activeQuickfillValue,
  quickfillOption,
  onClick,
}) => {
  const isQuickfillActive = activeQuickfillValue === quickfillOption.value;

  return (
    <GVDSTextButton
      className={`quickfill-button ${isQuickfillActive ? "active" : ""}`}
      text={quickfillOption.label}
      onClick={() => onClick(quickfillOption)}
    />
  );
};

const QuickfillButtons = ({ setPeriod, activeQuickfillValue }) => {
  const handleQuickfillClick = (option) => {
    const {
      dateRange: { start: newStart, end: newEnd },
      value,
    } = option;
    setPeriod(newStart, newEnd, value);
  };

  return (
    <div>
      <div className="gvds-text--heading4">Quickfill</div>
      <div className="dashboard-filter__continuous-time-period-control__quickfill__container">
        {quickfillOptions.map((option) => (
          <QuickfillButton
            key={option.value}
            activeQuickfillValue={activeQuickfillValue}
            quickfillOption={option}
            onClick={handleQuickfillClick}
          />
        ))}
      </div>
    </div>
  );
};

const PeriodControlToggleTrigger = forwardRef(
  ({ start, end, onClick }, ref) => (
    <div
      className="dashboard-filter__continuous-time-period-control__trigger"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="dashboard-filter__text-display">
        {DateTimeUtils.formatLocalMonthYear(start)} -{" "}
        {DateTimeUtils.formatLocalMonthYear(end)}
      </div>
      <GVDSIcon Icon={IconCalendarEvent} />
    </div>
  )
);

const ContinuousTimePeriodControlView = ({
  startPeriod,
  endPeriod,
  activeQuickfillValue,
  onSave,
}) => {
  const [show, setShow] = useState(false);
  const handleToggle = (isShow) => {
    setShow(isShow);
    resetInputDates();
  };

  const initialActiveQuickfillValue =
    activeQuickfillValue ||
    getActiveQuickfillByPeriod(startPeriod, endPeriod)?.value;

  const [inputActiveQuickfillValue, setInputActiveQuickfillValue] = useState(
    initialActiveQuickfillValue
  );

  const [inputStartPeriod, setInputStartPeriod] = useState(startPeriod);
  const [inputEndPeriod, setInputEndPeriod] = useState(endPeriod);

  const resetInputDates = () => {
    setInputStartPeriod(startPeriod);
    setInputEndPeriod(endPeriod);

    setInputActiveQuickfillValue(initialActiveQuickfillValue);
  };

  const handlePeriodChange = (start, end, quickfillValue = null) => {
    if (start && start !== inputStartPeriod) {
      setInputStartPeriod(start);
    }

    if (end && end !== inputEndPeriod) {
      setInputEndPeriod(end);
    }

    if (quickfillValue !== null) {
      setInputActiveQuickfillValue(quickfillValue);
    } else {
      setInputActiveQuickfillValue(null);
    }
  };

  const handleClose = () => {
    setShow(false);
    resetInputDates();
  };

  const handleSave = () => {
    onSave(inputStartPeriod, inputEndPeriod, inputActiveQuickfillValue);
    setShow(false);
  };

  const isInputDatesSameAsOriginalValues =
    moment(startPeriod).isSame(inputStartPeriod) &&
    moment(endPeriod).isSame(inputEndPeriod);

  return (
    <Dropdown align="start" drop="down" show={show} onToggle={handleToggle}>
      <Dropdown.Toggle
        as={PeriodControlToggleTrigger}
        start={startPeriod}
        end={endPeriod}
      />
      <Dropdown.Menu
        className="dashboard-filter__continuous-time-period-control__dropdown__menu"
        flip={false}
      >
        <div className="dashboard-filter__continuous-time-period-control__dropdown__content">
          <QuickfillButtons
            setPeriod={handlePeriodChange}
            activeQuickfillValue={inputActiveQuickfillValue}
          />
          <GVDSFormStartEndMonthPicker
            startMonthLabel={<Form.Label>From</Form.Label>}
            endMonthLabel={<Form.Label>To</Form.Label>}
            startMonth={inputStartPeriod}
            endMonth={inputEndPeriod}
            onChange={handlePeriodChange}
            maxStartMonth={lastFullMonth}
            maxEndMonth={lastFullMonth}
            statusMetadata={
              startPeriod &&
              endPeriod &&
              isEndDateBeforeStartDate(inputStartPeriod, inputEndPeriod)
                ? FormFieldStatusMetadata.getError(
                    "End date cannot be before start date"
                  )
                : FormFieldStatusMetadata.getDefault()
            }
            isPortalling={false}
          />
          <div className="dashboard-filter__continuous-time-period-control__dropdown__footer">
            <Spacer />
            <GVDSButton
              onClick={handleClose}
              variant={buttonVariant.tertiary}
              text="Cancel"
            />
            <GVDSButton
              onClick={handleSave}
              variant={buttonVariant.primary}
              text="Save"
              disabled={isInputDatesSameAsOriginalValues}
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ContinuousTimePeriodControlView;
