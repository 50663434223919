import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CertificationCommentAdd from "./CertificationCommentAdd";
import CertificationCommentDisplayInput from "./CertificationCommentDisplayInput";
import CertificationContext from "../../CertificationContext";
import {useParams, useRouteMatch} from "react-router-dom";
import {CERTIFICATION_CONTENT_PAGE_REQUIREMENT} from "../../../../config/ROUTES_NAME";

const CertificationComments = () => {
  const { t } = useTranslation();

  const routeMatch = useRouteMatch(CERTIFICATION_CONTENT_PAGE_REQUIREMENT);
  const requirementId = routeMatch?.params["requirementId"];

  const certificationContext = useContext(CertificationContext);

  const selectedRequirement =
    certificationContext.certification?.getSelectedRequirement(requirementId);
  const comments = selectedRequirement?.comments;

  let content;

  if (!comments || comments.length === 0) {
    content = (
      <div className="table__no_content h-100">
        {t("certifications.content-page.comments.no-content")}
      </div>
    );
  } else {
    content = comments.map((c) => (
      <CertificationCommentDisplayInput key={c.id} commentModel={c} />
    ));
  }

  return (
    <div className="certification-comments__page-container">
      <div className="certification-comments__comments-layout">{content}</div>
      <div className="certification-comments__add-comment-layout">
        <CertificationCommentAdd />
      </div>
    </div>
  );
};

export default CertificationComments;
