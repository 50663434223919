import Form from "react-bootstrap/Form";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconMessageCircle, IconPaperclip } from "@tabler/icons-react";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import { NOT_SECTIONED } from "../../SystemToolbox/Certification/CertificationSharedUtils";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CertificationAssessmentService } from "../../../services/CertificationService";
import CertificationOffcanvasContext from "./CertificationOffcanvas/CertificationOffcanvasContext";
import CertificationContext from "../CertificationContext";
import { useParams } from "react-router-dom";
import CertificationCollapsibleSection from "../CertificationCollapsibleSection";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import { downloadFromPresignedS3Url } from "../../common/FileAttachments";
import { CertificationRequirementCommentService } from "../../../services/CertificationCommentService";
import CertificationSubrequirementDisplay from "./CertificationSubrequirementDisplay";
import { RESOURCES } from "../../../config/constants";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import { useTranslation } from "react-i18next";
import { CertificationUtil } from "../CertificationUtil";
import GVDSTextButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSTextButtonWithLoadingAction";

const InstructionGreenviewPortalSection = ({ content }) => {
  return (
    <CertificationCollapsibleSection
      title="Using Greenview Portal"
      content={content}
    />
  );
};

const GreenviewGuidance = ({ content }) => {
  return (
    <CertificationCollapsibleSection
      title="Greenview's guidance"
      content={content}
    />
  );
};

const RecommendedEvidence = ({ content }) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const downloadFile = async (fileLink) => {
    const selectedInventory = userInventory.selectedInventory.get;

    try {
      await downloadFromPresignedS3Url(fileLink, {
        resource_type: selectedInventory.type,
        resource_id: selectedInventory.id,
      });
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to download recommended evidence file.</span>
      );
    }
  };

  let formattedContent = content.map((evidence, index) => (
    <li key={index}>
      {evidence.name}
      {evidence.downloadLink !== null && (
        <GVDSTextButtonWithLoadingAction
          onClickAsyncFunc={() => downloadFile(evidence.downloadLink)}
          className="ms-2"
          text="Download evidence template"
        />
      )}
    </li>
  ));
  formattedContent = <ul>{formattedContent}</ul>;

  return (
    <CertificationCollapsibleSection
      title="Recommended evidence"
      content={formattedContent}
      isUsingInnerHtml={false}
    />
  );
};

const AdditionalInformation = ({ content }) => {
  return (
    <CertificationCollapsibleSection
      title="Additional Information"
      content={content}
    />
  );
};

const debounceWait = 1000;

const CertificationRequirementDisplay = () => {
  const { t } = useTranslation();

  const { certificationId, criterionId, requirementId } = useParams();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const certificationContext = useContext(CertificationContext);
  const certificationOffcanvasContext = useContext(
    CertificationOffcanvasContext
  );

  const saveApplicableTimer = useRef(null);
  const isApplicableOldValue = useRef(null);

  const [
    subrequirementCheckboxCssMinWidth,
    setSubrequirementCheckboxCssMinWidth,
  ] = useState(0);
  const subRequirementNumberRef = useRef(null);

  const debounceOnApplicableChange = () => {
    const currentInventory = userInventory.selectedInventory.get;
    const isApplicableNewValue = !selectedRequirement.isApplicable;

    if (isApplicableOldValue.current === null) {
      isApplicableOldValue.current = selectedRequirement.isApplicable;
    }

    clearTimeout(saveApplicableTimer.current);
    selectedRequirement.isApplicable = isApplicableNewValue;
    certificationContext.certification.calculateTotalApplicableRequirementCount(
      criterionId,
      isApplicableNewValue,
      selectedRequirement.isCompleted
    );
    certificationContext.refreshCertification();

    saveApplicableTimer.current = setTimeout(() => {
      CertificationAssessmentService.saveRequirementAssessment(
        currentInventory.type,
        currentInventory.id,
        certificationContext.certificationId,
        requirementId,
        isApplicableNewValue,
        selectedRequirement.isCompleted
      )
        .then(() => {
          isApplicableOldValue.current = null;
        })
        .catch(() => {
          if (
            selectedRequirement.isApplicable !== isApplicableOldValue.current
          ) {
            selectedRequirement.isApplicable = isApplicableOldValue.current;
            certificationContext.certification.calculateTotalApplicableRequirementCount(
              criterionId,
              isApplicableOldValue.current,
              selectedRequirement.isCompleted
            );
            certificationContext.refreshCertification();
          }
          toastContext.addFailToast(<span>Failed to update requirement.</span>);
        });
    }, debounceWait);
  };

  const saveCompleteTimer = useRef(null);
  const isCompleteOldValue = useRef(null);

  const debounceOnCompleteChange = () => {
    const currentInventory = userInventory.selectedInventory.get;
    const isCompleteNewValue = !selectedRequirement.isCompleted;

    if (isCompleteOldValue.current === null) {
      isCompleteOldValue.current = selectedRequirement.isCompleted;
    }

    clearTimeout(saveCompleteTimer.current);
    selectedRequirement.isCompleted = isCompleteNewValue;
    certificationContext.certification.calculateCompletedRequirementCount(
      criterionId,
      isCompleteNewValue
    );
    certificationContext.refreshCertification();

    saveCompleteTimer.current = setTimeout(() => {
      CertificationAssessmentService.saveRequirementAssessment(
        currentInventory.type,
        currentInventory.id,
        certificationContext.certificationId,
        requirementId,
        selectedRequirement.isApplicable,
        isCompleteNewValue
      )
        .then(() => {
          isCompleteOldValue.current = null;
        })
        .catch(() => {
          if (selectedRequirement.isCompleted !== isCompleteOldValue.current) {
            selectedRequirement.isCompleted = isCompleteOldValue.current;
            certificationContext.certification.calculateCompletedRequirementCount(
              criterionId,
              isCompleteOldValue.current
            );
            certificationContext.refreshCertification();
          }
          toastContext.addFailToast(<span>Failed to update requirement.</span>);
        });
    }, debounceWait);
  };

  const selectedRequirement =
    certificationContext.certification?.getSelectedRequirement(requirementId);

  useEffect(() => {
    if (
      subRequirementNumberRef.current &&
      selectedRequirement.instruction.length === 0
    ) {
      setSubrequirementCheckboxCssMinWidth(
        CertificationUtil.calculateMinCssWidth(
          [selectedRequirement.number],
          subRequirementNumberRef
        )
      );
    } else {
      setSubrequirementCheckboxCssMinWidth(24);
    }
  }, [selectedRequirement]);

  useEffect(() => {
    const currentInventory = userInventory.selectedInventory.get;

    if (
      requirementId &&
      selectedRequirement &&
      currentInventory &&
      currentInventory.type === RESOURCES.SITE
    ) {
      Promise.all([
        CertificationAssessmentService.getRequirementEvidenceFiles(
          currentInventory.type,
          currentInventory.id,
          certificationContext.certificationId,
          requirementId
        ),
        CertificationRequirementCommentService.getRequirementComments(
          currentInventory.type,
          currentInventory.id,
          certificationId,
          requirementId
        ),
      ])
        .then(([evidence, comments]) => {
          selectedRequirement.evidence = evidence;
          selectedRequirement.comments = comments;
          certificationContext.refreshCertification();
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load requirement evidence and comments</span>
          );
        });
    }
  }, [requirementId]);

  // TODO show count

  if (!selectedRequirement) {
    return null;
  }

  return (
    <div className="certification-content-page__content__details__container">
      <div className="action-buttons__container">
        <div className="applicable-completed__container">
          <div className="d-flex align-items-center">
            <div className="gvds-text--inputField">Applicable</div>
            <InfoTooltip
              info={t("certifications.content-page.applicable-tooltip")}
            />
            <Form.Check
              className="ms-2"
              type="switch"
              checked={selectedRequirement.isApplicable}
              onChange={debounceOnApplicableChange}
            />
          </div>
          <div className="mark-as-completed__container">
            <div className="gvds-text--inputField">Complete</div>
            <Form.Check
              className="ms-1"
              type="checkbox"
              label=""
              checked={selectedRequirement.isCompleted}
              onChange={debounceOnCompleteChange}
              disabled={!selectedRequirement.isApplicable}
            />
          </div>
        </div>
        <div className="evidence-comments__container">
          <GVDSButton
            variant={buttonVariant.secondary}
            text={`Evidence (${selectedRequirement.evidence.length})`}
            icon={<GVDSIcon Icon={IconPaperclip} />}
            onClick={() => {
              certificationOffcanvasContext.setActiveTabKey("evidence");
              certificationOffcanvasContext.setShow(true);
            }}
          />
          <GVDSButton
            className="ms-3"
            variant={buttonVariant.secondary}
            text={`Comments (${selectedRequirement.comments.length})`}
            icon={<GVDSIcon Icon={IconMessageCircle} />}
            onClick={() => {
              certificationOffcanvasContext.setActiveTabKey("comments");
              certificationOffcanvasContext.setShow(true);
            }}
          />
        </div>
      </div>
      <div className="content__details__title">
        <div className="requirement-number" ref={subRequirementNumberRef}>
          {selectedRequirement.number}
        </div>
        <div>{selectedRequirement.name}</div>
      </div>
      <div>
        {selectedRequirement.instruction && (
          <GVDSBanner
            variant={GVDSBanner.Variants.informational}
            className="content__details__instruction-banner"
          >
            <div className="gvds-text--caption">
              {selectedRequirement.instruction}
            </div>
          </GVDSBanner>
        )}
        {selectedRequirement.subrequirementSections.map((section, index) => {
          return (
            <div
              className="content__details__sub-requirement-section__container"
              key={`section-${index}`}
            >
              <div
                className="content__details__sub-requirement-section"
                style={{ marginLeft: `${subrequirementCheckboxCssMinWidth}px` }}
              >
                {section.name !== NOT_SECTIONED ? section.name : null}
              </div>
              {section.subrequirements.map((sub, index) => (
                <CertificationSubrequirementDisplay
                  key={`sub-${index}`}
                  selectedRequirement={selectedRequirement}
                  subRequirement={sub}
                  completeCheckboxMinWidth={subrequirementCheckboxCssMinWidth}
                />
              ))}
            </div>
          );
        })}
      </div>
      {selectedRequirement.instructionGreenviewPortal && (
        <InstructionGreenviewPortalSection
          content={selectedRequirement.instructionGreenviewPortal}
        />
      )}
      {selectedRequirement.greenviewGuidance && (
        <GreenviewGuidance content={selectedRequirement.greenviewGuidance} />
      )}
      {selectedRequirement.sampleEvidences.length > 0 && (
        <RecommendedEvidence content={selectedRequirement.sampleEvidences} />
      )}
      {selectedRequirement.additionalInformation && (
        <AdditionalInformation
          content={selectedRequirement.additionalInformation}
        />
      )}
    </div>
  );
};

export default CertificationRequirementDisplay;
