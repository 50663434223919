import axios from "axios";
import { API_URL } from "../../../config/api-config";
import { HttpResponseService } from "../../../services/UtilsService";
import { columns, SITE_NAME_KEY, SiteValue } from "./LatLongFinderBulkInputService";

const DATA_START_INDEX = 2;

export default class LatLongFinderService {
  static toRequestPayload(grid) {
    return grid.filter((g) => {
      const site = SiteValue.fromRowData(g);
      return site[SITE_NAME_KEY].getValue() !== undefined;
    }).map((g) => {
      const site = SiteValue.fromRowData(g);
      const payload = {};
      Object.entries(columns).forEach(([key, value]) => {
        if (key >= DATA_START_INDEX) {
          payload[value.key] = site[value.key].getValue();
        }
      });
      return payload;
    });
  }

  static async downloadLatLong(payload) {
    try {
      const response = await axios.post(API_URL + "/api/v1/admin/lat_long_finder/download",
        { payload },
        { responseType: "blob" }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Sites Latitude Longitude.xlsx"
      );
    } catch (error) {
      throw error;
    }
  }
}