import axios from "axios";
import { API_URL } from "../config/api-config";
import {
  BEST_PRACTICE_ADOPTION_LEVEL,
  BEST_PRACTICE_ADOPTION_LEVEL_PRECEDENCE,
  BEST_PRACTICE_QUESTION_TYPE,
  RESOURCES,
} from "../config/constants";
import minBy from "lodash/minBy";

export class BestPracticeAssessedQuestionModel {
  constructor(questionResponse) {
    this.questionId = questionResponse.question_id;
    this.questionText = questionResponse.question_text;
    this.questionType = questionResponse.question_type;
    this.description = questionResponse.description;
    this.categories = questionResponse.categories;
    this.tags = questionResponse.tags;
    this.options = questionResponse.options;
    this.answers = questionResponse.answers;
    this.comment = questionResponse.comment;
    this.linkedGuidanceArticles = questionResponse.linked_guidance_articles;
    this.searchString = `
    [ ${this.categories.map((category) => category.name).join(", ")} ] 
    [ ${this.tags.map((tag) => tag.name).join(", ")} ]`;
  }

  get categoryNames() {
    return this.categories.map((category) => category.name);
  }

  get tagNames() {
    return this.tags.map((tag) => tag.name);
  }

  get isAnswered() {
    return !!this.answers && this.answers.length > 0;
  }

  get adoptionLevel() {
    if (!this.isAnswered) {
      return BEST_PRACTICE_ADOPTION_LEVEL.NO_RESPONSE;
    }

    if (this.questionType === BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE) {
      return this.answers[0].adoption_level;
    } else if (this.questionType === BEST_PRACTICE_QUESTION_TYPE.CHECKBOXES) {
      return minBy(
        this.answers.map((a) => a.adoption_level),
        (adoptionLvl) => BEST_PRACTICE_ADOPTION_LEVEL_PRECEDENCE[adoptionLvl]
      );
    } else {
      return BEST_PRACTICE_ADOPTION_LEVEL.NA;
    }
  }
}

export default class BestPracticeAssessmentService {
  static getBestPracticeQuestions = async (resource_type, resource_id) => {
    try {
      const params = { resource_type: resource_type, resource_id: resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/best_practices/assessments?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data.map((q) => new BestPracticeAssessedQuestionModel(q));
    } catch (error) {
      throw error.response;
    }
  };

  static updateAnswer = async (site_id, question_id, answer_ids) => {
    try {
      const params = { resource_type: RESOURCES.SITE, resource_id: site_id };
      const data = { answer_ids };
      const response = await axios.put(
        `${API_URL}/api/v1/best_practices/assessments/${question_id}/answer?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateComment = async (site_id, question_id, comment) => {
    try {
      const params = { resource_type: RESOURCES.SITE, resource_id: site_id };
      const data = { comment };
      const response = await axios.put(
        `${API_URL}/api/v1/best_practices/assessments/${question_id}/comment?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
