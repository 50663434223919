import axios from "axios";
import { API_URL } from "../config/api-config";
import DataRequestModel from "../components/Data/Overview/DataRequest/DataRequestModel";
import { DateTimeUtils } from "./UtilsService";

export default class DataRequestService {
  static createDataRequest = async (
    type,
    periodFrom,
    periodTo,
    deadline,
    description,
    requestParams
  ) => {
    try {
      const data = {
        type: type,
        period_from: DateTimeUtils.getUTCISOString(periodFrom),
        period_to: DateTimeUtils.getUTCISOString(periodTo),
        deadline: DateTimeUtils.getUTCISOString(deadline),
        description: description,
        resource_id: requestParams["resource_id"],
        resource_type: requestParams["resource_type"],
        request_params: requestParams,
      };

      const response = await axios.post(
        API_URL + "/api/v1/data_requests",
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getDataRequests = async (resource_id, resource_type, abortControllerSignal) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/data_requests`, {
        params: {
          resource_id,
          resource_type,
          current_date: DateTimeUtils.getUTCToday(),
        },
        signal: abortControllerSignal
      });
      return response.data.map((d) => DataRequestModel.fromJson(d));
    } catch (error) {
      throw error;
    }
  };

  static getOngoingDataRequests = async (resource_id, resource_type, abortControllerSignal) => {
    return (
      await DataRequestService.getDataRequests(resource_id, resource_type, abortControllerSignal)
    ).filter((d) => !d.isExpired());
  };

  static getDataRequest = async (
    data_request_id,
    resource_id,
    resource_type,
    abortControllerSignal
  ) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/data_requests/${data_request_id}`,
        {
          params: {
            resource_id,
            resource_type,
            current_date: DateTimeUtils.getUTCToday(),
          },
          signal: abortControllerSignal
        }
      );
      return DataRequestModel.fromJson(response.data);
    } catch (error) {
      throw error;
    }
  };

  static sendEmailForDataRequest = async (dataRequestId) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/data_requests/${dataRequestId}/emails`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getPreview = async (type, requestParams) => {
    try {
      const data = {
        type: type,
        request_params: requestParams,
      };

      const response = await axios.post(
        `${API_URL}/api/v1/data_requests/preview`,
        data
      );
      return DataRequestModel.fromJson(response.data);
    } catch (error) {
      throw error;
    }
  };

  static deleteDataRequest = async (
    data_request_id,
    resource_id,
    resource_type
  ) => {
    try {
      await axios.delete(`${API_URL}/api/v1/data_requests/${data_request_id}`, {
        params: {
          resource_id,
          resource_type,
        },
      });
    } catch (error) {
      throw error;
    }
  };
}
