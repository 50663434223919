import axios from "axios";
import { API_URL } from "../../config/api-config";
import { PORTAL_MODULE_LINKS_URL_ORDER } from "../../config/constants";

export default class PortalModuleLinkService {
  static getAllPortalModuleLinks = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/portal_module_links`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static PortalModuleLinkSortFn = (module1, module2) => {
    const sortOrder1 = PORTAL_MODULE_LINKS_URL_ORDER[module1.url];
    const sortOrder2 = PORTAL_MODULE_LINKS_URL_ORDER[module2.url];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return -1;
    } else if (sortOrder2 !== undefined) {
      return 1;
    } else {
      return 0;
    }
  };
}
