import axios from "axios";
import { API_URL } from "../config/api-config";
import moment from "moment";
import { DateTimeUtils, NumberService } from "./UtilsService";
import { RESOURCES } from "../config/constants";
import { PackagePlanModel } from "../components/common/Billing/PackagePlanModel";

export class BillingSiteSubscriptionContractInvoiceModel {
  constructor(
    siteId,
    siteName,
    fullPrice,
    subscriptionStartDate,
    subscriptionEndDate,
    fullCycleStartDate,
    fullCycleEndDate
  ) {
    this.siteId = siteId;
    this.siteName = siteName;
    this.fullPrice = fullPrice;
    this.subscriptionStartDate = subscriptionStartDate;
    this.subscriptionEndDate = subscriptionEndDate;
    this.fullCycleStartDate = fullCycleStartDate;
    this.fullCycleEndDate = fullCycleEndDate;

    this.invoicedPrice = this.getProratedAmount();
  }

  getProratedAmount() {
    return BillingProrationService.prorateAmount(
      this.fullPrice,
      this.fullCycleStartDate,
      this.fullCycleEndDate,
      this.subscriptionStartDate,
      this.subscriptionEndDate
    );
  }

  static fromSiteSubscription(
    siteSubscription,
    contractSubscriptionStartDate,
    contractSubscriptionEndDate
  ) {
    return new BillingSiteSubscriptionContractInvoiceModel(
      siteSubscription.site.id,
      siteSubscription.site.name,
      siteSubscription.priceAmount,
      siteSubscription.startPeriod ?? contractSubscriptionStartDate,
      siteSubscription.endPeriod ?? contractSubscriptionEndDate,
      contractSubscriptionStartDate,
      contractSubscriptionEndDate
    );
  }

  toRequestPayload() {
    return {
      site_id: this.siteId,
      invoice_price: this.invoicedPrice,
    };
  }
}

export class BillingConfigurationModel {
  constructor(paymentMethod, billingAdmin, packagePlans, allFeatures) {
    this.paymentMethod = paymentMethod;
    this.billingAdmin = billingAdmin;
    this.packagePlans = packagePlans;
    this.allFeatures = allFeatures;
  }

  static fromDTO(dto) {
    const paymentMethod = dto.payment_method
      ? {
          brand: dto.payment_method.brand,
          last4: dto.payment_method.last4,
          expiryDate: dto.payment_method.expiry_date,
        }
      : null;

    const billingAdmin = dto.billing_admin
      ? {
          id: dto.billing_admin.id,
          email: dto.billing_admin.email,
          fullName: dto.billing_admin.full_name,
        }
      : null;

    const packagePlans =
      dto.ordered_package_plans.length > 0
        ? dto.ordered_package_plans.map((pp) => PackagePlanModel.fromDTO(pp))
        : null;

    return new BillingConfigurationModel(
      paymentMethod,
      billingAdmin,
      packagePlans,
      dto.features
    );
  }
}

export class BillingProrationService {
  static prorateAmount(
    fullAmount,
    fullStart,
    fullEnd,
    prorateStart,
    prorateEnd
  ) {
    const fullPeriodInDays = DateTimeUtils.getDateDifference(
      fullStart,
      fullEnd
    );
    const proratePeriodInDays = DateTimeUtils.getDateDifference(
      prorateStart,
      prorateEnd
    );

    return NumberService.toPrecision(
      (fullAmount * proratePeriodInDays) / fullPeriodInDays,
      2
    );
  }
}

export default class BillingService {
  static getBillingConfiguration = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/billing/configuration`,
        { params: { resource_type, resource_id } }
      );
      return BillingConfigurationModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static getInvoices = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/billing/invoices`, {
        params: { resource_type, resource_id },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getUpdatePaymentMethodUrl = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/billing/configuration/payment_method`,
        {
          params: { resource_type, resource_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static cancelSubscription = async (
    resource_type,
    resource_id,
    subscription_id,
    cancellation_reasons,
    feedback
  ) => {
    const payload = {
      cancellation_reasons: {
        reasons: cancellation_reasons,
        feedback: feedback,
      },
    };
    try {
      let response;
      if (resource_type === RESOURCES.PORTFOLIO) {
        response = await axios.post(
          `${API_URL}/api/v1/contracts/${resource_id}/subscriptions/${subscription_id}/cancel`,
          payload,
          {
            params: { resource_type, resource_id },
          }
        );
      } else {
        response = await axios.post(
          `${API_URL}/api/v1/sites/${resource_id}/subscriptions/${subscription_id}/cancel`,
          payload,
          {
            params: { resource_type, resource_id },
          }
        );
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static changeSubscriptionBillingCycle = async (
    resource_type,
    resource_id,
    subscription_id,
    new_billing_cycle
  ) => {
    const payload = {
      new_billing_cycle,
    };
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/sites/${resource_id}/subscriptions/${subscription_id}/change-billing-cycle`,
        payload,
        {
          params: { resource_type, resource_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getSubscriptionRefundAmount = async (
    resource_type,
    resource_id,
    subscription_id
  ) => {
    try {
      let response;
      response = await axios.post(
        `${API_URL}/api/v1/sites/${resource_id}/subscriptions/${subscription_id}/refund-amount`,
        {},
        {
          params: { resource_type, resource_id },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static changeSubscriptionPlan = async (
    resource_type,
    resource_id,
    subscription_id,
    new_plan_id
  ) => {
    const payload = {
      new_plan_id,
    };
    try {
      let response;

      response = await axios.post(
        `${API_URL}/api/v1/sites/${resource_id}/subscriptions/${subscription_id}/change-plan`,
        payload,
        {
          params: { resource_type, resource_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static requestNewSubscription = async (
    resource_type,
    resource_id,
    plan_id,
    billing_cycle,
    after_subscription_id
  ) => {
    const payload = {
      plan_id,
      billing_cycle,
      after_subscription_id,
    };
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/billing/request_new_subscription`,
        payload,
        {
          params: { resource_type, resource_id },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getNewCyclePaymentDate = (nextBillingDate) => {
    return moment(nextBillingDate).subtract(7, "day");
  };
}
