import React, { useContext, useState } from "react";
import GVDSButton, { buttonVariant } from "./Buttons/GVDSButton";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faMultiply } from "@fortawesome/free-solid-svg-icons/faMultiply";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import GVDSIconButton, { iconButtonVariant } from "./Buttons/GVDSIconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GVDSButtonWithLoadingAction from "./Buttons/GVDSButtonWithLoadingAction";
import GVDSFormTextArea from "./Forms/GVDSFormTextArea";
import Form from "react-bootstrap/Form";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "./Forms/GVDSFormShared";
import GVDSFormField from "./Forms/GVDSFormField";
import GVDSFormSingleSelect from "./Forms/GVDSFormSingleSelect";
import GVDSModal from "./Modals/GVDSModal";
import GVDSFormSingleDatePicker from "./Forms/GVDSFormSingleDatePicker";
import GVDSFormStartEndDatePicker from "./Forms/GVDSFormStartEndDatePicker";
import { IconCirclePlus, IconMailFilled, IconTrash } from "@tabler/icons-react";
import GVDSIcon from "./Icons/GVDSIcon";
import GVDSFormSingleMonthPicker from "./Forms/GVDSFormSingleMonthPicker";
import moment from "moment";
import GVDSFormStartEndMonthPicker from "./Forms/GVDSFormStartEndMonthPicker";
import GVDSFormSingleYearPicker from "./Forms/GVDSFormSingleYearPicker";
import GVDSFormStartEndYearPicker from "./Forms/GVDSFormStartEndYearPicker";
import GVDSFormMultiSelect from "./Forms/GVDSFormMultiSelect";
import GVDSTag, { TagVariants } from "./common/GVDSTag";
import GVDSFormFieldWithCharacterCount from "./Forms/GVDSFormFieldWithCharacterCount";
import GVDSTextAreaWithCharacterCount from "./Forms/GVDSTextAreaWithCharacterCount";
import useGVDSTableCtrl from "./Table/GVDSTableHook";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "./Table/GVDSTable";
import { DateTimeUtils, NumberService } from "../services/UtilsService";
import CommentTooltip from "../components/common/Tooltip/CommentTooltip";
import GVDSTableCtrlContainer from "./Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "./Table/Controls/GVDSPagination";
import GVDSBanner from "./common/GVDSBanner";
import GVDSInfoCard from "./common/GVDSInfoCard";
import ToastContext from "../context/ToastContext";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSDropdownToggle from "./Buttons/GVDSDropdownToggle";

const Collapsible = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="me-2">
      <h1 onClick={toggleCollapse} style={{ cursor: "pointer" }}>
        {title}
      </h1>
      {!isCollapsed && children}
    </div>
  );
};

const TestButtons = () => {
  const asyncFunction = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
  };

  const normalFunction = () => {
    alert("Normal Function");
  };

  return (
    <>
      <div className="mt-4 mb-2">
        <h3>Normal</h3>
        <GVDSButton
          variant={buttonVariant.primary}
          text="Primary"
          onClick={normalFunction}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.secondary}
          text="Secondary"
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.tertiary}
          text="Tertiary"
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          text="Destructive Primary"
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.destructive_tertiary}
          text="Destructive Tertiary"
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Icon</h3>
        <GVDSButton
          variant={buttonVariant.primary}
          text="Primary With Icon"
          icon={<FontAwesomeIcon icon={faPlus} />}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.secondary}
          text="Secondary With Icon"
          icon={<FontAwesomeIcon icon={faMinus} />}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.tertiary}
          text="Tertiary With Icon"
          icon={<FontAwesomeIcon icon={faMultiply} />}
          className="me-2"
        />
        <span className="me-2"></span>
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          text="Destructive Primary With Icon"
          icon={<FontAwesomeIcon icon={faTrash} />}
        />
        <GVDSButton
          variant={buttonVariant.destructive_tertiary}
          text="Destructive Tertiary With Icon"
          icon={<FontAwesomeIcon icon={faTrash} />}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Loading State</h3>
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          text="Primary With Loading State"
          onClickAsyncFunc={asyncFunction}
          icon={<FontAwesomeIcon icon={faPlus} />}
          className="me-2"
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.secondary}
          text="Secondary With Loading State"
          onClickAsyncFunc={asyncFunction}
          className="me-2"
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.destructive_primary}
          text="Destructive With Loading State"
          onClickAsyncFunc={asyncFunction}
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          text="Disabled Primary With Loading State"
          onClickAsyncFunc={asyncFunction}
          icon={<FontAwesomeIcon icon={faPlus} />}
          className="me-2"
          disabled={true}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Disabled</h3>
        <GVDSButton
          variant={buttonVariant.primary}
          text="Primary Disabled"
          disabled={true}
          onClick={normalFunction}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.secondary}
          text="Secondary Disabled"
          disabled={true}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.tertiary}
          text="Tertiary Disabled"
          disabled={true}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          text="Destructive Primary Disabled"
          disabled={true}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.destructive_tertiary}
          text="Destructive Tertiary Disabled"
          disabled={true}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Disabled-Icon</h3>
        <GVDSButton
          variant={buttonVariant.primary}
          text="Primary Disabled With Icon"
          icon={<FontAwesomeIcon icon={faPlus} />}
          disabled={true}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.secondary}
          text="Secondary Disabled With Icon"
          icon={<FontAwesomeIcon icon={faMinus} />}
          disabled={true}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.tertiary}
          text="Tertiary Disabled With Icon"
          icon={<FontAwesomeIcon icon={faMultiply} />}
          disabled={true}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          text="Destructive Primary Disabled With Icon"
          icon={<FontAwesomeIcon icon={faTrash} />}
          disabled={true}
          className="me-2"
        />
        <GVDSButton
          variant={buttonVariant.destructive_tertiary}
          text="Destructive Tertiary Disabled With Icon"
          icon={<FontAwesomeIcon icon={faTrash} />}
          disabled={true}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Default</h3>
        <GVDSButton variant="bad-variant" text="Bad Variant" />
      </div>
      <div className="mt-2 mb-2">
        <h3>Icon Button</h3>
        <GVDSIconButton
          variant={iconButtonVariant.secondary}
          icon={<GVDSIcon Icon={IconCirclePlus} />}
          tooltipText="Secondary"
          placement="right"
          onClick={normalFunction}
          className="me-2"
        />
        <GVDSIconButton
          variant={iconButtonVariant.secondary}
          icon={<GVDSIcon Icon={IconCirclePlus} />}
          tooltipText="Secondary disabled"
          placement="right"
          onClick={normalFunction}
          className="me-2"
          disabled={true}
        />
        <GVDSIconButton
          variant={iconButtonVariant.tertiary}
          icon={<GVDSIcon Icon={IconCirclePlus} />}
          tooltipText="Tertiary"
          placement="right"
          onClick={normalFunction}
          className="me-2"
        />
        <GVDSIconButton
          variant={iconButtonVariant.tertiary}
          icon={<GVDSIcon Icon={IconCirclePlus} />}
          tooltipText="Tertiary disabled"
          placement="right"
          onClick={normalFunction}
          className="me-2"
          disabled={true}
        />
        <GVDSIconButton
          variant={iconButtonVariant.destructive}
          icon={<GVDSIcon Icon={IconTrash} />}
          tooltipText="Destructive"
          placement="right"
          className="me-2"
        />
        <GVDSIconButton
          variant={iconButtonVariant.destructive}
          icon={<GVDSIcon Icon={IconTrash} />}
          tooltipText="Destructive disabled"
          placement="right"
          disabled={true}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Dropdown Button</h3>
        <Dropdown>
          <Dropdown.Toggle as={GVDSDropdownToggle}>Input</Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {
              <Dropdown.Item>
                <GVDSIcon Icon={IconCirclePlus} /> Single Record
              </Dropdown.Item>
            }
            {
              <Dropdown.Item>
                <GVDSIcon Icon={IconCirclePlus} /> Bulk Records
              </Dropdown.Item>
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

const TestForms = () => {
  const [textArea, setTextArea] = useState(null);
  const [fieldState, setFieldState] = useState(FormFieldState.normal);
  const [singleSelectSelected, setSingleSelectSelected] = useState(null);
  const options = [
    "first option",
    "second option",
    "another one",
    "here is slightly longer one just to see how it goes",
  ].map((i) => ({ label: i, value: i }));

  const errorMessage =
    fieldState === FormFieldState.warning
      ? "Some warning message, this message might get long..."
      : fieldState === FormFieldState.error
      ? "Some error message, this message might get long..."
      : null;

  return (
    <div>
      <div className="mt-2 mb-2">
        <h3>Field error type</h3>
        <Form.Check
          label="Normal"
          type="radio"
          checked={fieldState === FormFieldState.normal}
          onChange={() => {
            setFieldState(FormFieldState.normal);
          }}
        />
        <Form.Check
          label="Warning"
          type="radio"
          checked={fieldState === FormFieldState.warning}
          onChange={() => {
            setFieldState(FormFieldState.warning);
          }}
        />
        <Form.Check
          label="Error"
          type="radio"
          checked={fieldState === FormFieldState.error}
          onChange={() => {
            setFieldState(FormFieldState.error);
          }}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Single Select</h3>
        <GVDSFormSingleSelect
          options={options}
          value={singleSelectSelected}
          onSelect={(selected) => setSingleSelectSelected(selected)}
          statusMetadata={new FormFieldStatusMetadata(fieldState, errorMessage)}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Single Select - Disabled</h3>
        <GVDSFormSingleSelect
          disabled
          options={options}
          statusMetadata={new FormFieldStatusMetadata(fieldState, errorMessage)}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Form field</h3>
        <GVDSFormField
          placeholder="This is a placeholder text"
          value={textArea}
          onInput={setTextArea}
          statusMetadata={new FormFieldStatusMetadata(fieldState, errorMessage)}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Form field - Disabled</h3>
        <GVDSFormField
          placeholder="This is a placeholder text"
          disabled
          value={textArea}
          onInput={setTextArea}
          statusMetadata={new FormFieldStatusMetadata(fieldState, errorMessage)}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Text Area</h3>
        <GVDSFormTextArea
          placeholder="This is a placeholder text"
          value={textArea}
          onInput={setTextArea}
          statusMetadata={new FormFieldStatusMetadata(fieldState, errorMessage)}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Text Area - Disabled</h3>
        <GVDSFormTextArea
          placeholder="This is a placeholder text"
          disabled
          statusMetadata={new FormFieldStatusMetadata(fieldState, errorMessage)}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Input Field With Character Count</h3>
        <GVDSFormFieldWithCharacterCount
          placeholder="This is a placeholder text"
          value={textArea}
          onInput={setTextArea}
          maxLength={50}
          statusMetadata={new FormFieldStatusMetadata(fieldState, errorMessage)}
        />
      </div>
      <div className="mt-2 mb-2">
        <h3>Text Area With Character Count</h3>
        <GVDSTextAreaWithCharacterCount
          placeholder="This is a placeholder text"
          value={textArea}
          onInput={setTextArea}
          rows={3}
          maxLength={50}
          statusMetadata={new FormFieldStatusMetadata(fieldState, errorMessage)}
        />
      </div>
    </div>
  );
};

const TestModals = () => {
  const [showSmallModal, setShowSmallModal] = useState(false);
  const [showMediumModal, setShowMediumModal] = useState(false);

  return (
    <div>
      <div className="mt-2 mb-2">
        <h3>Small Modal</h3>
        <GVDSButton
          text="Small Modal"
          onClick={() => setShowSmallModal(true)}
        />
        <GVDSModal
          title="Small Modal"
          size={GVDSModal.Size.small}
          show={showSmallModal}
          onHide={() => setShowSmallModal(false)}
        >
          <GVDSModal.Body>
            Here are some text to make myself a little bit useful. Longer
            sentences should be tested. What do you say?
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton text="Noted" onClick={() => setShowSmallModal(false)} />
          </GVDSModal.Footer>
        </GVDSModal>
      </div>
      <div className="mt-2 mb-2">
        <h3>Medium Modal</h3>
        <GVDSButton
          text="Medium Modal"
          onClick={() => setShowMediumModal(true)}
        />
        <GVDSModal
          title="Medium Modal"
          size={GVDSModal.Size.medium}
          show={showMediumModal}
          onHide={() => setShowMediumModal(false)}
        >
          <GVDSModal.Body>
            Here are some text to make myself a little bit useful. Longer
            sentences should be tested. What do you say?
            <GVDSFormField />
            <GVDSFormField />
            <GVDSFormField />
            <GVDSFormField />
            <GVDSFormField />
            <GVDSFormField />
            <GVDSFormField />
            <GVDSFormField />
            <GVDSFormField />
            End of form here
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              text="Noted"
              onClick={() => setShowMediumModal(false)}
            />
          </GVDSModal.Footer>
        </GVDSModal>
      </div>
    </div>
  );
};

const TestDatePicker = () => {
  const [normalDateValue, setNormalDateValue] = useState(new Date(2023, 8, 1));
  const [warningDateValue, setWarningDateValue] = useState();
  const [errorDateValue, setErrorDateValue] = useState();

  const [startDate, setStartDate] = useState(new Date(2023, 8, 1));
  const [endDate, setEndDate] = useState(new Date(2023, 8, 1));

  const onStartEndDatePickerChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <>
      <div className="mt-4 mb-2">
        <h3>Single DatePicker</h3>
        <div className="mb-3">
          Normal
          <GVDSFormSingleDatePicker
            selected={normalDateValue}
            onChange={setNormalDateValue}
            startYear={2015}
            endYear={2030}
            dateFormat="dd MMM yyyy"
          />
        </div>
        <div className="mb-3">
          Disabled
          <GVDSFormSingleDatePicker disabled={true} />
        </div>
        <div className="mb-3">
          With Warning
          <GVDSFormSingleDatePicker
            selected={warningDateValue}
            onChange={setWarningDateValue}
            statusMetadata={FormFieldStatusMetadata.getWarning(
              "Missing datepicker"
            )}
          />
        </div>
        <div className="mb-3">
          With Error
          <GVDSFormSingleDatePicker
            selected={errorDateValue}
            onChange={setErrorDateValue}
            statusMetadata={FormFieldStatusMetadata.getError(
              "Error datepicker"
            )}
          />
        </div>
      </div>

      <div className="mt-4 mb-2">
        <h3>Start End DatePicker</h3>
        <div className="mb-3">
          Normal
          <GVDSFormStartEndDatePicker
            startDate={startDate}
            startDateLabel="Period From"
            endDate={endDate}
            endDateLabel="Period To"
            onChange={(startDate, endDate) =>
              onStartEndDatePickerChange(startDate, endDate)
            }
            startYear={2020}
            endYear={2035}
          />
        </div>
        <div className="mb-3">
          Disabled Start
          <GVDSFormStartEndDatePicker disabledStartDate />
        </div>
        <div className="mb-3">
          Disabled End
          <GVDSFormStartEndDatePicker disabledEndDate />
        </div>
        <div className="mb-3">
          Disabled All
          <GVDSFormStartEndDatePicker disabledStartDate disabledEndDate />
        </div>
        <div className="mb-3">
          With Warning
          <GVDSFormStartEndDatePicker
            statusMetadata={FormFieldStatusMetadata.getWarning(
              "Missing datepicker"
            )}
          />
        </div>
        <div className="mb-3">
          With Error
          <GVDSFormStartEndDatePicker
            statusMetadata={FormFieldStatusMetadata.getError(
              "Error datepicker"
            )}
          />
        </div>
      </div>
    </>
  );
};

const TestMonthPicker = () => {
  const [normalMonthValue, setNormalMonthValue] = useState(
    new Date(2023, 8, 1)
  );
  const [warningMonthValue, setWarningMonthValue] = useState();
  const [errorMonthValue, setErrorMonthValue] = useState();

  const momentDate = moment(new Date());
  const maxMonth = momentDate.add(8, "month").toDate();
  const minMonth = momentDate.subtract(3, "year").toDate();

  const [startMonth, setStartMonth] = useState(new Date(2023, 8, 1));
  const [endMonth, setEndMonth] = useState(new Date(2023, 8, 1));

  const onStartEndDatePickerChange = (startDate, endDate) => {
    setStartMonth(startDate);
    setEndMonth(endDate);
  };

  return (
    <>
      <div className="mt-4 mb-2">
        <h3>Single MonthPicker</h3>
        <div className="mb-3">
          Normal
          <GVDSFormSingleMonthPicker
            selected={normalMonthValue}
            onChange={setNormalMonthValue}
            maxMonth={maxMonth}
            minMonth={minMonth}
          />
        </div>
        <div className="mb-3">
          Disabled
          <GVDSFormSingleMonthPicker disabled={true} />
        </div>
        <div className="mb-3">
          With Warning
          <GVDSFormSingleMonthPicker
            selected={warningMonthValue}
            onChange={setWarningMonthValue}
            statusMetadata={FormFieldStatusMetadata.getWarning(
              "Missing monthpicker"
            )}
          />
        </div>
        <div className="mb-3">
          With Error
          <GVDSFormSingleMonthPicker
            selected={errorMonthValue}
            onChange={setErrorMonthValue}
            statusMetadata={FormFieldStatusMetadata.getError(
              "Error monthpicker"
            )}
          />
        </div>
      </div>

      <div className="mt-4 mb-2">
        <h3>Start End MonthPicker</h3>
        <div className="mb-3">
          Normal
          <GVDSFormStartEndMonthPicker
            startMonth={startMonth}
            startMonthLabel="Start Month"
            endMonth={endMonth}
            endMonthLabel="End Month"
            onChange={(startMonth, endMonth) =>
              onStartEndDatePickerChange(startMonth, endMonth)
            }
          />
        </div>
        <div className="mb-3">
          Disabled Start
          <GVDSFormStartEndMonthPicker disabledStartMonth />
        </div>
        <div className="mb-3">
          Disabled End
          <GVDSFormStartEndMonthPicker disabledEndMonth />
        </div>
        <div className="mb-3">
          Disabled All
          <GVDSFormStartEndMonthPicker disabledStartMonth disabledEndMonth />
        </div>
        <div className="mb-3">
          With Warning
          <GVDSFormStartEndMonthPicker
            statusMetadata={FormFieldStatusMetadata.getWarning(
              "Missing monthpicker"
            )}
          />
        </div>
        <div className="mb-3">
          With Error
          <GVDSFormStartEndMonthPicker
            statusMetadata={FormFieldStatusMetadata.getError(
              "Error monthpicker"
            )}
          />
        </div>
      </div>
    </>
  );
};

const TestYearPicker = () => {
  const [normalYearValue, setNormalYearValue] = useState(
    new Date(2023, 8, 1).getFullYear()
  );
  const [warningYearValue, setWarningYearValue] = useState();
  const [errorYearValue, setErrorYearValue] = useState();

  const [startYear, setStartYear] = useState(
    new Date(2023, 8, 1).getFullYear()
  );
  const [endYear, setEndYear] = useState(new Date(2023, 8, 1).getFullYear());

  const onStartEndYearPickerChange = (startDate, endDate) => {
    setStartYear(startDate);
    setEndYear(endDate);
  };

  return (
    <>
      <div className="mt-4 mb-2">
        <h3>Single YearPicker</h3>
        <div className="mb-3">
          Normal
          <GVDSFormSingleYearPicker
            selected={normalYearValue}
            onChange={setNormalYearValue}
          />
        </div>
        <div className="mb-3">
          Disabled
          <GVDSFormSingleYearPicker disabled={true} />
        </div>
        <div className="mb-3">
          With Warning
          <GVDSFormSingleYearPicker
            selected={warningYearValue}
            onChange={setWarningYearValue}
            statusMetadata={FormFieldStatusMetadata.getWarning(
              "Missing yearpicker"
            )}
          />
        </div>
        <div className="mb-3">
          With Error
          <GVDSFormSingleYearPicker
            selected={errorYearValue}
            onChange={setErrorYearValue}
            statusMetadata={FormFieldStatusMetadata.getError(
              "Error yearpicker"
            )}
          />
        </div>
      </div>

      <div className="mt-4 mb-2">
        <h3>Start End YearPicker</h3>
        <div className="mb-3">
          Normal
          <GVDSFormStartEndYearPicker
            startYear={startYear}
            startYearLabel="Start Year"
            endYear={endYear}
            endYearLabel="End Year"
            onChange={(startYear, endYear) =>
              onStartEndYearPickerChange(startYear, endYear)
            }
          />
        </div>
        <div className="mb-3">
          Disabled Start
          <GVDSFormStartEndYearPicker disabledStartYear />
        </div>
        <div className="mb-3">
          Disabled End
          <GVDSFormStartEndYearPicker disabledEndYear />
        </div>
        <div className="mb-3">
          Disabled All
          <GVDSFormStartEndYearPicker disabledStartYear disabledEndYear />
        </div>
        <div className="mb-3">
          With Warning
          <GVDSFormStartEndYearPicker
            statusMetadata={FormFieldStatusMetadata.getWarning(
              "Missing yearpicker"
            )}
          />
        </div>
        <div className="mb-3">
          With Error
          <GVDSFormStartEndYearPicker
            statusMetadata={FormFieldStatusMetadata.getError(
              "Error yearpicker"
            )}
          />
        </div>
      </div>
    </>
  );
};

const TestRadioCheckbox = () => {
  const [value, setValue] = useState(false);

  return (
    <div>
      <div className="mt-2 mb-2">
        <h3>Checkbox</h3>
        <Form.Check
          label="Normal"
          type="checkbox"
          checked={value}
          onChange={() => setValue(!value)}
        />
        <Form.Check
          label="Disabled"
          type="checkbox"
          checked={value}
          disabled={true}
          onChange={() => setValue(!value)}
        />
        <Form.Check
          label="Error"
          type="checkbox"
          checked={value}
          onChange={() => setValue(!value)}
          className="gvds-form__error"
        />
        <GVDSFormErrorMessage
          status={FormFieldState.error}
          errorMsg={"Error message"}
        />
        <h3>Radio Button</h3>
        <Form.Check
          label="Normal"
          type="radio"
          checked={value}
          onChange={() => setValue(!value)}
        />
        <Form.Check
          label="Disabled"
          type="radio"
          checked={value}
          disabled={true}
          onChange={() => setValue(!value)}
        />
        <Form.Check
          label="Error"
          type="radio"
          checked={value}
          onChange={() => setValue(!value)}
          className="gvds-form__error"
        />
        <GVDSFormErrorMessage
          status={FormFieldState.error}
          errorMsg={"Error message"}
        />
        <h3>Switch</h3>
        <Form.Check
          label="Normal"
          type="switch"
          checked={value}
          onChange={() => setValue(!value)}
        />
        <Form.Check
          label="Disabled"
          type="switch"
          checked={value}
          disabled={true}
          onChange={() => setValue(!value)}
        />
        <Form.Check
          label="Error"
          type="switch"
          checked={value}
          onChange={() => setValue(!value)}
          className="gvds-form__error"
        />
        <GVDSFormErrorMessage
          status={FormFieldState.error}
          errorMsg={"Error message"}
        />
      </div>
    </div>
  );
};

const TestMultiSelect = () => {
  const siteTagOptionsExample = [
    "Site Tag 1",
    "Site Tag 2",
    "Site Tag 3",
    "Site Tag 4",
    "Site Tag 5",
    "Site Tag 6",
    "Site Tag 7",
  ].map((i, index) => ({
    label: i,
    value: index,
    variant: GVDSTag.Variants.site,
  }));

  const portfolioTagOptionsExample = [
    "Portfolio Tag 1",
    "Portfolio Tag 2",
    "Portfolio Tag 3",
    "Portfolio Tag 4",
    "Portfolio Tag 5",
    "Portfolio Tag 6",
    "Portfolio Tag 7",
  ].map((i, index) => ({
    label: i,
    value: index,
    variant: GVDSTag.Variants.portfolio,
  }));

  const multipleTypeTagOptionsExample = [
    {
      label: "SDG Tag 1",
      value: 1,
      variant: GVDSTag.Variants.system,
    },
    {
      label: "Portfolio Tag 1",
      value: 2,
      variant: GVDSTag.Variants.portfolio,
    },
    {
      label: "Site Tag 1",
      value: 3,
      variant: GVDSTag.Variants.site,
    },
    {
      label: "Alternative Tag 1",
      value: 4,
      variant: GVDSTag.Variants.system,
    },
  ];

  return (
    <div>
      <div className="mt-2 mb-2">
        <h3>Multiselect</h3>
        <GVDSFormMultiSelect
          placeholder="Select Tag"
          options={siteTagOptionsExample}
          prefix="siteTag"
          onSelect={() => {}}
        />
        <h3>Multiselect - Alternative Color</h3>
        <GVDSFormMultiSelect
          placeholder="Select Tag"
          options={portfolioTagOptionsExample}
          prefix="siteTag"
          onSelect={() => {}}
        />{" "}
        <h3>Multiselect - Multiple Color</h3>
        <GVDSFormMultiSelect
          placeholder="Select Tag"
          options={multipleTypeTagOptionsExample}
          prefix="siteTag"
          onSelect={() => {}}
        />
        <h3>Multiselect - Disabled</h3>
        <GVDSFormMultiSelect
          placeholder="Select Tag"
          options={siteTagOptionsExample}
          prefix="siteTag"
          onSelect={() => {}}
          disabled
        />
        <h3>Multiselect - Disabled Option</h3>
        <GVDSFormMultiSelect
          placeholder="Select Tag"
          options={siteTagOptionsExample}
          prefix="siteTag"
          onSelect={() => {}}
          isOptionDisabled={(option) =>
            option.value === 1 || option.value === 3
          }
        />
        <h3>Multiselect - Error</h3>
        <GVDSFormMultiSelect
          placeholder="Select Tag"
          options={siteTagOptionsExample}
          prefix="siteTag"
          onSelect={() => {}}
          statusMetadata={FormFieldStatusMetadata.getError(
            "Field cannot be empty"
          )}
        />
        <h3>Multiselect - Warning</h3>
        <GVDSFormMultiSelect
          placeholder="Select Tag"
          options={siteTagOptionsExample}
          prefix="siteTag"
          onSelect={() => {}}
          statusMetadata={FormFieldStatusMetadata.getWarning("Warning Message")}
        />
      </div>
    </div>
  );
};

const tableTestData = [
  {
    subtopic_name: "Energy",
    type_name: "Electricity",
    meter_name: "meter 101",
    periodFrom: "2021-01-01T00:00:00+00:00",
    periodTo: "2021-01-28T00:00:00+00:00",
    usage: 20.405,
    comments: "Here is a comment",
  },
  {
    subtopic_name: "Water",
    type_name: "Mountain Dew",
    meter_name: "meter 101",
    periodFrom: "2021-01-01T00:00:00+00:00",
    periodTo: "2021-01-28T00:00:00+00:00",
    usage: 20.405,
    comments: "Here is a comment",
  },
  {
    subtopic_name: "Waste",
    type_name: "Recyclables",
    meter_name: "Truck 1",
    periodFrom: "2021-01-01T00:00:00+00:00",
    periodTo: "2021-01-28T00:00:00+00:00",
    usage: 20.405,
    comments: "Here is a comment",
  },
  {
    subtopic_name: "Energy",
    type_name: "Electricity",
    meter_name: "meter_101",
    periodFrom: "2021-02-01T00:00:00+00:00",
    periodTo: "2021-02-28T00:00:00+00:00",
    usage: 20.405,
    comments: "Here is a comment",
  },
  {
    subtopic_name: "Energy",
    type_name: "Electricity",
    meter_name: "meter_101",
    periodFrom: "2021-03-01T00:00:00+00:00",
    periodTo: "2021-03-28T00:00:00+00:00",
    usage: 20.405,
    comments: "Here is a comment",
  },
];

const TestTable = () => {
  const {
    filteredSortedData,
    currentPageData,

    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,

    filterKeys,
    setFilterKeys,

    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(tableTestData);

  let columns = [
    { header: "Subtopic", dataKey: "subtopic_name", sortable: true },
    { header: "Type", dataKey: "type_name", sortable: true },
    { header: "Meter", dataKey: "meter_name", sortable: true },
    {
      header: "Period From",
      dataKey: "periodFrom",
      sortable: true,
      renderer: (d) => DateTimeUtils.formatUTCDate(d["periodFrom"]),
    },
    {
      header: "Period To",
      dataKey: "periodTo",
      sortable: true,
      renderer: (d) => DateTimeUtils.formatUTCDate(d["periodTo"]),
    },
    {
      header: "Usage",
      dataKey: "usage",
      sortable: true,
      renderer: (d) => NumberService.format(d["usage"], 2, 2),
    },
    { header: "Unit", dataKey: "unit_name" },
    {
      header: "Comment",
      dataKey: "comments",
      sortable: true,
      renderer: (d) =>
        d["comments"] ? <CommentTooltip comment={d["comments"]} /> : null,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (d) => {
        return <></>;
      },
    },
  ];

  return (
    <div>
      <GVDSTableCtrlContainer>
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
    </div>
  );
};

const TestBanner = () => {
  return (
    <div>
      <div className="mt-2 mb-2">
        <GVDSBanner
          title={"Error message title"}
          variant={GVDSBanner.Variants.error}
        >
          Alert Message
        </GVDSBanner>
      </div>
      <div className="mt-2 mb-2">
        <GVDSBanner
          title={"Success message title"}
          variant={GVDSBanner.Variants.success}
        >
          Alert Message
        </GVDSBanner>
      </div>
      <div className="mt-2 mb-2">
        <GVDSBanner
          title={"Warning message title"}
          variant={GVDSBanner.Variants.warning}
        >
          Alert Message
        </GVDSBanner>
      </div>
      <div className="mt-2 mb-2">
        <GVDSBanner variant={GVDSBanner.Variants.warning}>
          Alert Message with no title
        </GVDSBanner>
      </div>
      <div className="mt-2 mb-2">
        <GVDSBanner variant={GVDSBanner.Variants.error}>
          Alert Message with no title with a very long text. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat.
        </GVDSBanner>
      </div>
      <div className="mt-2 mb-2">
        <GVDSBanner
          title={"Alert with button"}
          variant={GVDSBanner.Variants.success}
        >
          Alert Message
          <GVDSBanner.Footer>
            <GVDSButton variant={buttonVariant.primary} text="Setup" />
            <GVDSButton variant={buttonVariant.tertiary} text="Cancel" />
          </GVDSBanner.Footer>
        </GVDSBanner>
      </div>
      <div className="mt-2 mb-2">
        <GVDSBanner
          title={"Alert with custom icon"}
          variant={GVDSBanner.Variants.success}
          icon={IconMailFilled}
        >
          As this report is large, it may take some time to generate. You can
          leave this page and return later to download it. To update the status,
          refresh the page or click "Refresh Status" below.
        </GVDSBanner>
      </div>
    </div>
  );
};

const TestInfoCard = () => {
  return (
    <div>
      <div className="mt-2 mb-2">
        <GVDSInfoCard title={"Info card title"}>Info Message</GVDSInfoCard>
      </div>
      <div className="mt-2 mb-2">
        <GVDSInfoCard title={"Info card with list"}>
          <ul>
            <li>
              Data with incomplete values are represented in{" "}
              <span className="body-1-bold color-red">red</span>
            </li>
            <li>
              Data with no available values are represented with a{" "}
              <span className="body-1-bold">-</span>
            </li>
            <li>
              Values that are not included in aggregation are{" "}
              <span className="body-1-bold color-gray56">faded out</span>
            </li>
            <li>
              Values that cannot be calculated are represented with an{" "}
              <span className="body-1-bold">N/A</span>
            </li>
          </ul>
        </GVDSInfoCard>
      </div>
      <div className="mt-2 mb-2">
        <GVDSInfoCard useIcon={false} title={"Info card without icon"}>
          Info Message
        </GVDSInfoCard>
      </div>
      <div className="mt-2 mb-2">
        <GVDSInfoCard title={"Info card with button"}>
          Info Message
          <GVDSInfoCard.Footer>
            <GVDSButton variant={buttonVariant.primary} text="Setup" />
            <GVDSButton variant={buttonVariant.tertiary} text="Cancel" />
          </GVDSInfoCard.Footer>
        </GVDSInfoCard>
      </div>
      <div className="mt-2 mb-2">
        <GVDSInfoCard useIcon={false} title={"Info card no icon with button"}>
          <ul>
            <li>body text</li>
            <li>body text</li>
          </ul>
          <GVDSInfoCard.Footer>
            <GVDSButton variant={buttonVariant.primary} text="Setup" />
            <GVDSButton variant={buttonVariant.tertiary} text="Cancel" />
          </GVDSInfoCard.Footer>
        </GVDSInfoCard>
      </div>
    </div>
  );
};

const TestToast = () => {
  const toastContext = useContext(ToastContext);
  return (
    <div className="mt-2 mb-2">
      <GVDSButton
        variant={buttonVariant.primary}
        text="Show success toast"
        onClick={() =>
          toastContext.addSuccessToast(
            <span>Success toast message display</span>
          )
        }
      />
      <GVDSButton
        variant={buttonVariant.destructive_primary}
        text="Show fail toast"
        onClick={() =>
          toastContext.addFailToast(<span>Fail toast message display</span>)
        }
      />
      <GVDSButton
        variant={buttonVariant.destructive_primary}
        text="Show fail toast with long text"
        onClick={() =>
          toastContext.addFailToast(
            <span>
              When you have multiple toasts, we default to vertically stacking
              them in a readable manner.
            </span>
          )
        }
      />
    </div>
  );
};

const TestPage = () => {
  return (
    <div>
      <Collapsible title="Table">
        <TestTable />
      </Collapsible>
      <Collapsible title="Buttons">
        <TestButtons />
      </Collapsible>
      <Collapsible title="Forms">
        <TestForms />
      </Collapsible>
      <Collapsible title="Modals">
        <TestModals />
      </Collapsible>
      <Collapsible title="Date Picker">
        <TestDatePicker />
      </Collapsible>
      <Collapsible title="Month Picker">
        <TestMonthPicker />
      </Collapsible>
      <Collapsible title="Year Picker">
        <TestYearPicker />
      </Collapsible>
      <Collapsible title="Radio and Checkbox">
        <TestRadioCheckbox />
      </Collapsible>
      <Collapsible title="Multiselect">
        <TestMultiSelect />
      </Collapsible>
      <Collapsible title="Banner">
        <TestBanner />
      </Collapsible>
      <Collapsible title="Info Card">
        <TestInfoCard />
      </Collapsible>
      <Collapsible title="Toast">
        <TestToast />
      </Collapsible>
    </div>
  );
};

export default TestPage;
