import React, { useContext, useState } from "react";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import { MultipleFilesUploader } from "../../../common/FileAttachments";
import { useTranslation } from "react-i18next";
import { CertificationAssessmentService } from "../../../../services/CertificationService";
import ToastContext from "../../../../context/ToastContext";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import CertificationContext from "../../CertificationContext";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";
import { DEFAULT_MAX_FILE_SIZE_IN_MB } from "../../../../config/constants";
import { useParams, useRouteMatch } from "react-router-dom";
import { CERTIFICATION_CONTENT_PAGE_REQUIREMENT } from "../../../../config/ROUTES_NAME";

const CertificationEvidenceUploadModal = ({ show, close }) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const certificationContext = useContext(CertificationContext);

  const { certificationId, criterionId } = useParams();
  const routeMatch = useRouteMatch(CERTIFICATION_CONTENT_PAGE_REQUIREMENT);
  const requirementId = routeMatch?.params["requirementId"];

  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [oversizedFileNames, setOversizedFileNames] = useState([]);

  const handleClose = () => {
    if (!isUploading) {
      resetFiles();
      close();
    }
  };

  const resetFiles = () => {
    setFiles([]);
    setOversizedFileNames([]);
  };

  const handleUpload = async () => {
    const currentInventory = userInventory.selectedInventory.get;

    if (currentInventory) {
      setIsUploading(true);

      setOversizedFileNames([]);

      try {
        const evidenceFiles =
          await CertificationAssessmentService.uploadRequirementEvidenceFiles(
            currentInventory.type,
            currentInventory.id,
            certificationContext.certificationId,
            requirementId,
            files
          );
        certificationContext.certification.addEvidenceFilesToRequirement(
          criterionId,
          requirementId,
          evidenceFiles
        );
        handleClose();
        certificationContext.refreshCertification();
        toastContext.addSuccessToast(<span>File(s) uploaded</span>);
      } catch {
        toastContext.addFailToast(<span>Failed to upload file(s)</span>);
      } finally {
        setIsUploading(false);
      }
    }
  };

  let customErrorBanner = null;
  if (oversizedFileNames.length > 0) {
    customErrorBanner = (
      <GVDSBanner
        title={"Upload issues detected"}
        variant={GVDSBanner.Variants.error}
      >
        <div style={{ whiteSpace: "pre-wrap" }}>
          {oversizedFileNames.length > 0 && (
            <>
              <div className="mb-2">
                The following file(s) exceed the {DEFAULT_MAX_FILE_SIZE_IN_MB}MB
                size limit. Please reduce the file size or split the file, then
                try uploading again:
              </div>
              <ul>
                {oversizedFileNames.map((filename) => (
                  <li className="ms-1">{filename}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </GVDSBanner>
    );
  }

  return (
    <GVDSModal
      title="Upload requirement evidence"
      size={GVDSModal.Size.medium}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>
        <MultipleFilesUploader
          files={files}
          setFiles={setFiles}
          customErrorBanner={customErrorBanner}
          onSelectOversizedFiles={(oversizedFiles) =>
            setOversizedFileNames(oversizedFiles.map((file) => file.name))
          }
        />
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          text={t("shared-modal.footer.cancel")}
          disabled={isUploading}
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          onClickAsyncFunc={handleUpload}
          text={t("shared-modal.footer.upload")}
          disabled={files.length < 1}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default CertificationEvidenceUploadModal;
