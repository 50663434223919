import _ from "lodash";
import { DATA_STATUS } from "../config/constants";

export const NA_VALUE = "N/A";
export const NO_VALUE = "-";
export const SAFE_DATA_EMPTY_VALUE = null;

export default class SafeDataService {
  static mergeStatuses(statuses = []) {
    if (statuses.indexOf(DATA_STATUS.MISSING) >= 0) {
      return DATA_STATUS.MISSING;
    }
    return DATA_STATUS.COMPLETE;
  }

  static sum(data = []) {
    const values = data.map((d) => SafeDataService.getValue(d));
    const status = SafeDataService.mergeStatuses(data.map((d) => d.status));
    const total = _.sum(
      values.filter(
        (v) => v !== SAFE_DATA_EMPTY_VALUE && v !== NO_VALUE && v !== NA_VALUE
      )
    );

    if (total === 0 && values.indexOf(NA_VALUE) >= 0) {
      return {
        value: NA_VALUE,
        status,
      };
    }
    if (total === 0 && values.indexOf(NO_VALUE) >= 0) {
      return {
        value: NO_VALUE,
        status,
      };
    }

    return {
      value: total,
      status,
    };
  }

  static multiply(value, factor) {
    if (
      SafeDataService.getValue(value) === NA_VALUE ||
      SafeDataService.getValue(value) === NO_VALUE ||
      SafeDataService.getValue(value) === SAFE_DATA_EMPTY_VALUE
    ) {
      return value;
    }
    return {
      value: value.value * factor,
      status: value.status,
    };
  }

  static minus(first, second) {
    const firstValue = SafeDataService.getValue(first);
    const secondValue = SafeDataService.getValue(second);

    if (
      firstValue === NO_VALUE ||
      firstValue === SAFE_DATA_EMPTY_VALUE ||
      secondValue === NO_VALUE ||
      secondValue === SAFE_DATA_EMPTY_VALUE
    ) {
      return {
        value: NO_VALUE,
        status: SafeDataService.mergeStatuses([first.status, second.status]),
      };
    }

    const isNA = secondValue === NA_VALUE || firstValue === NA_VALUE;
    const value = isNA ? NA_VALUE : firstValue - secondValue;
    return {
      value,
      status: SafeDataService.mergeStatuses([first.status, second.status]),
    };
  }

  static divide(dividend, divisor) {
    const dividendValue = SafeDataService.getValue(dividend);
    const divisorValue = SafeDataService.getValue(divisor);

    if (
      divisorValue === NO_VALUE ||
      divisorValue === SAFE_DATA_EMPTY_VALUE ||
      dividendValue === NO_VALUE ||
      dividendValue === SAFE_DATA_EMPTY_VALUE
    ) {
      return {
        value: NO_VALUE,
        status: SafeDataService.mergeStatuses([
          dividend.status,
          divisor.status,
        ]),
      };
    }

    const isNA =
      divisorValue === 0 ||
      divisorValue === NA_VALUE ||
      dividendValue === NA_VALUE;
    const value = isNA ? NA_VALUE : dividendValue / divisorValue;
    return {
      value,
      status: SafeDataService.mergeStatuses([dividend.status, divisor.status]),
    };
  }

  static getVariance(dividend, divisor) {
    const dividendValue = SafeDataService.getValue(dividend);
    const divisorValue = SafeDataService.getValue(divisor);

    if (
      divisorValue === NO_VALUE ||
      divisorValue === SAFE_DATA_EMPTY_VALUE ||
      dividendValue === NO_VALUE ||
      dividendValue === SAFE_DATA_EMPTY_VALUE
    ) {
      return {
        value: NO_VALUE,
        status: SafeDataService.mergeStatuses([
          dividend.status,
          divisor.status,
        ]),
      };
    }

    const isNA =
      divisorValue === 0 ||
      divisorValue === NA_VALUE ||
      dividendValue === NA_VALUE;
    const value = isNA
      ? NA_VALUE
      : ((dividendValue - divisorValue) / divisorValue) * 100;
    return {
      value,
      status: SafeDataService.mergeStatuses([dividend.status, divisor.status]),
    };
  }

  static getValue(data) {
    if (
      (data.status === DATA_STATUS.NA ||
        data.status === DATA_STATUS.EXCLUDED) &&
      data.value === SAFE_DATA_EMPTY_VALUE
    ) {
      return NA_VALUE;
    }

    if (
      data.value === SAFE_DATA_EMPTY_VALUE
    ) {
      return NO_VALUE;
    }
    return data.value;
  }

  static highlightMissing(data) {
    const value = SafeDataService.getValue(data);

    return (
      value !== NO_VALUE &&
      value !== NA_VALUE &&
      data.status === DATA_STATUS.MISSING
    );
  }
}
