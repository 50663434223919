import React from "react";

import TemplateGlobalPage from "../../gvds-components/Layout/TemplateGlobalPage";
import PageHeader from "../../gvds-components/Layout/PageHeader";

export const TermsOfUseContent = () => {
  return (
    <>
      <PageHeader>
        <PageHeader.Title>
          <h1>TERMS OF USE</h1>
        </PageHeader.Title>
      </PageHeader>
      <p>
        These Terms of Use govern a User’s access and use of the Greenview
        Platform. By accessing and using the Greenview Platform, the User shall
        be deemed to have agreed to be legally bound by these Terms of Use (as
        amended from time to time).
      </p>
      <p>
        <strong>1. DEFINITIONS AND INTERPRETATION</strong>
      </p>
      <p>1.1. In these Terms of Use, unless the context otherwise requires:</p>
      <p>
        <strong>“Affiliate”</strong> means with respect to any Person, any other
        Person that, directly or indirectly, through one or more intermediaries,
        Controls or is Controlled by or is under common Control with the first
        Person;
      </p>
      <p>
        <strong>“Applicable Law”</strong> means all applicable statutes,
        enactments, acts of legislature of parliament, laws, ordinances, rules,
        by-laws, regulations, notifications, guidelines, policies, directions,
        directives and orders of any governmental or regulatory authority,
        tribunal, board, court or a recognised stock exchange;
      </p>
      <p>
        <strong>“Confidential Information”</strong> has the meaning ascribed to
        it in Clause 10.1;
      </p>
      <p>
        <strong>“Control”</strong> means the power to direct the management or
        policies of any Person, whether through the ownership of over fifty per
        cent (50%) of the voting power of such Person, through the power to
        appoint more than half of the board of directors or similar governing
        body of such entity, through contractual arrangements or otherwise, and
        <strong>“Controlled”</strong> shall be construed accordingly;
      </p>
      <p>
        <strong>“Data”</strong> has the meaning ascribed to it in Clause 8.4;
      </p>
      <p>
        <strong>“Greenview”</strong> means Greenview Hospitality Pte. Ltd.
        and/or Greenview LLC;
      </p>
      <p>
        <strong>“Greenview Platform”</strong> means the online platform operated
        by Greenview (including the software related there to) accessible{" "}
        <a href="https://greenview.sg/" target="_blank">
          here{" "}
        </a>
        (or such other link as may be specified by Greenview from time to time)
        by the User through the internet or other remote means, and any
        subsequent application programming interfaces or online platforms that
        are developed and made available by Greenview to interact with or
        otherwise be used in connection with the Services, including but not
        limited to the online portal accessible{" "}
        <a href="https://greenview.sg/online-portal" target="_blank">
          here
        </a>
        ;
      </p>
      <p>
        <strong>“Indemnitee”</strong> has the meaning ascribed to it in Clause
        6.5;
      </p>
      <p>
        <strong>“Intellectual Property Rights”</strong> means patents, rights to
        inventions, copyright and related rights, moral rights, trade marks and
        service marks, business names and domain names, rights in get-up,
        goodwill and the right to sue for passing off, rights in designs, rights
        in computer software, database rights, rights to use, and protect the
        confidentiality of, confidential information (including know-how and
        trade secrets), and all other intellectual property rights, in each case
        whether registered or unregistered and including all applications and
        rights to apply for and be granted, renewals or extensions of, and
        rights to claim priority from, such rights and all similar or equivalent
        rights or forms of protection which subsist or will subsist now or in
        the future in any part of the world;
      </p>
      <p>
        <strong>“Person”</strong> means any individual, corporation, company,
        partnership, firm, voluntary association, joint venture, trustee, trust,
        executor, administrator or other legal personal representative,
        unincorporated organisation, syndicate or other business enterprise,
        governmental, administrative or regulatory authority or agency or any
        other entity whether acting in an individual, fiduciary or other
        capacity, and their respective legal personal representatives,
        successors and permitted assigns, as the case may be;
      </p>
      <p>
        <strong>“Privacy Policy”</strong> means Greenview’s privacy policy (as
        amended from time to time) accessible{" "}
        <a href="https://greenview.sg/" target="_blank">
          here
        </a>
        ;
      </p>
      <p>
        <strong>“Services”</strong> means the services provided by Greenview to
        the User via the Greenview Platform, including any Subscription
        Services;
      </p>
      <p>
        <strong>“Service IP”</strong> has the meaning ascribed to it in Clause
        8.1;
      </p>
      <p>
        <strong>“Software Licence”</strong> has the meaning ascribed to it in
        Clause 8.2;
      </p>
      <p>
        <strong>“Subscription Services”</strong> means any Services provided by
        Greenview to the User via the Greenview Platform in consideration of
        fees payable by the User to Greenview;
      </p>
      <p>
        <strong>“Terms of Use”</strong> means these terms of use (as amended
        from time to time) accessible{" "}
        <a href="https://greenview.sg/" target="_blank">
          here
        </a>
        ;
      </p>
      <p>
        <strong>“User”</strong> means any Person that accesses and/or uses the
        Greenview Platform, including its Affiliates;
      </p>
      <p>
        <strong>“User Account”</strong> has the meaning ascribed to it in Clause
        3.1; and
      </p>
      <p>
        <strong>“User Default”</strong> has the meaning ascribed to it in Clause
        4.4.
      </p>
      <p>
        1.2. A reference to these Terms of Use or any document referred to
        herein includes any and all amendments, additions and variations
        thereto.
      </p>
      <p>
        1.3. Unless the context otherwise requires, references to Clauses are to
        the clauses of these Terms of Use.
      </p>
      <p>
        1.4. Headings shall not affect the interpretation of these Terms of Use.
      </p>
      <p>
        1.5. Unless the context otherwise requires, words in the singular shall
        include the plural and words in the plural shall include the singular
        and a reference to one gender shall include a reference to the other
        genders.
      </p>
      <p>
        1.6. A reference to a statute or statutory provision is a reference to
        it as it is in force as at the date of these Terms of Use and shall
        include all subordinate legislation made as at the date of these Terms
        of Use under that statute or statutory provision.
      </p>
      <p>
        1.7. A reference to writing or written includes any means of visible
        reproduction including emails.
      </p>
      <p>
        1.8. Any time or business day shall refer to Singapore time or a
        business day in Singapore, being a day (other than a Saturday, Sunday or
        gazetted public holiday) on which commercial banks are open for business
        in Singapore.
      </p>
      <p>
        <strong>2. SERVICES</strong>
      </p>
      <p>
        2.1. The Services are made available to the User via the Greenview
        Platform, including any Subscription Services.
      </p>
      <p>
        2.2. Greenview undertakes that the Services will be provided using
        reasonable care and skill. The foregoing undertaking shall not apply to
        the extent of any non-conformance which is caused by use of the Services
        contrary to Greenview’s instructions, or modification or alteration of
        the Services by any party other than Greenview or its duly authorised
        contractors or agents. If the Services do not conform with the foregoing
        undertaking, Greenview may, at its expense, use reasonable commercial
        endeavours to correct any such non-conformance, or provide the User with
        alternative means of accomplishing the desired performance. Such
        correction or substitution constitutes the User's sole and exclusive
        remedy for any breach of the foregoing undertaking.
      </p>
      <p>
        2.3. Greenview makes no representation, warranty or undertaking that the
        Services are complete or that the Services will achieve the User’s
        intended purposes. The User acknowledges that it relies on its own skill
        and judgement in using the Services and it is solely responsible for the
        selection of the Services.
      </p>
      <p>
        2.4. The use of the Greenview Platform to access the Services may not be
        free of interruptions or errors as the network connections, IT systems,
        software, hardware and/or internet service provider used by the User to
        access the Greenview Platform can be unpredictable and may from time to
        time interfere with or prevent the use of the Greenview Platform.
        Greenview shall not be liable for any interference with the use of the
        Greenview Platform.
      </p>
      <p>
        2.5. Although Greenview makes every effort to keep the Greenview
        Platform free from malware (such as bugs, viruses, worms trojans and
        logic bombs), Greenview does not guarantee that the Greenview Platform
        will be secure or free from malware and will not be liable for any loss
        or damage caused by malware or other technologically harmful or
        malicious material that may infect the User’s computer equipment,
        program, data or other proprietary material due to the use of the
        Services or the downloading of any content or link to any websites or
        resources from the Greenview Platform. The User shall ensure that it
        takes necessary security measures (such as conducting virus scans)
        before downloading any content. The User shall not misuse the Greenview
        Platform by knowingly introducing any malware or other technologically
        harmful or malicious material.
      </p>
      <p>
        2.6. It may be necessary for Greenview to carry out scheduled or
        unscheduled repairs or maintenance, or remote patching or upgrading of
        the Greenview Platform, which may temporarily degrade the quality of the
        Services or result in a partial or complete outage of the Greenview
        Platform or a limitation or discontinuation of any individual features
        of the Greenview Platform. Any degradation or interruption in the
        Services or the Greenview Platform shall not give rise to a refund of
        any sums paid by the User to Greenview or any liability on Greenview,
        nor shall it relieve the User from its obligations to pay any sums due
        and payable to Greenview. The User shall have no claim whatsoever in
        respect of the technical standards and availability of the Greenview
        Platform.
      </p>
      <p>
        2.7. Where the Greenview Platform contains links to other websites and
        resources provided by third parties (including any third party online
        payment service provider), such links shall be accessed by the User at
        its own risk. Greenview makes no representation, warranty or commitment
        and shall have no liability or obligation whatsoever in relation to the
        content or use of, or correspondence with, any such websites or
        resources, or any transactions completed, and any contract entered into
        by the User, with any such third party. Any contract entered into and
        any transaction completed via any such websites or resources is between
        the User and the relevant third party, and not Greenview. Greenview
        recommends that the User refers to the third party's terms of use and
        privacy policy prior to using the relevant website or resource.
        Greenview does not endorse or approve any such websites or resources nor
        their content.
      </p>
      <p>
        2.8. Greenview reserves the right to amend the Services from time to
        time, including by adding or deleting features and functions, in an
        effort to improve the User’s experience. The User may not automatically
        be given access to such new features or functions and Greenview reserves
        the right to charge additional fees for such new features and functions.
      </p>
      <p>
        <strong>3. USER ACCOUNT</strong>
      </p>
      <p>
        3.1. In order to access and use any Subscription Services, the User must
        register for a user account via the Greenview Platform (
        <strong>“User Account”</strong>). The approval of such registration
        shall be subject to Greenview’s sole and absolute discretion.
      </p>
      <p>
        3.2. Where the User is an individual representing an entity, he acts
        solely for and on behalf of such entity which shall be jointly and
        severally liable for the User’s obligations hereunder.
      </p>
      <p>
        3.3. Greenview may request certain information from the User during the
        registration of a User Account. Any such information provided by the
        User must be true, complete, accurate and not misleading. The User must
        maintain and immediately update such information stored in the User
        Account should there be any subsequent changes to such information.
        Greenview shall not be liable for any incorrectness or incompleteness of
        such information or any other content of the User on the Greenview
        Platform.
      </p>
      <p>
        3.4. Where the User chooses or is allocated a username, password or any
        other information as part of Greenview’s security procedures relating to
        a User Account, the User must keep such information confidential and
        prevent any disclosure of the same to any third parties. If the User
        knows or suspects that the security of such information has been
        compromised, the User shall promptly notify Greenview. Greenview shall
        not be liable for any unauthorised access to or use of any User Account
        by any third party. Greenview has the right to disable any username or
        password at any time.
      </p>
      <p>
        3.5. Greenview reserves the right to suspend or terminate a User Account
        at any time for any reason whatsoever. In such event, the User may not
        use the Greenview Platform via another User Account without Greenview’s
        prior consent. Re-registration of a User Account under a new or false
        name is prohibited.
      </p>
      <p>
        <strong>4. USER’S OBLIGATIONS</strong>
      </p>
      <p>4.1. The User shall:</p>
      <p>
        (a) co-operate with Greenview in all matters relating to the Services;
      </p>
      <p>
        (b) provide Greenview with such information and documents as Greenview
        may reasonably require in order to supply the Services and ensure that
        such information and documents are true, complete, accurate and not
        misleading. The User shall be solely responsible for the accuracy,
        timely updating and back-up of such information and documents and any
        content of the User on the Greenview Platform;
      </p>
      <p>
        (c) obtain and maintain all necessary licences, permissions and consents
        which are or may be required for the User to access and use the
        Services;
      </p>
      <p>
        (d) comply with Applicable Law in relation to its use of any Services;
      </p>
      <p>
        (e) not use the Services to post or upload any material on the Greenview
        Platform that infringes or is likely to infringe any Intellectual
        Property Rights of any person or entity or which is in violation of any
        Applicable Law. Greenview shall have the right to delete or edit any
        such material without notice;
      </p>
      <p>
        (f) not use the Services in any manner which, in Greenview’s reasonable
        opinion, threatens the security, integrity or availability of the
        Services; and
      </p>
      <p>
        (g) ensure that its network connections and IT systems are secure
        throughout the use of the Services and comply with the relevant
        specifications required to access the Services.
      </p>
      <p>4.2. The User shall not:</p>
      <p>
        (a) remove any copyright, trademark or other proprietary notices from
        any part of the Greenview Platform;
      </p>
      <p>
        (b) reproduce or modify copies of any content of the Greenview Platform
        or have it printed off or downloaded in any way, except as permitted by
        Greenview or where such reproduction is incidental to the normal use of
        the Greenview Platform or necessary for performing back-ups;
      </p>
      <p>
        (c) distribute, transfer, publicly display, perform, transmit, stream,
        broadcast or otherwise exploit any part of the Greenview Platform,
        except as permitted by Greenview;
      </p>
      <p>
        (d) use the Greenview Platform in any way that would violate the rights
        of Greenview or any third party;
      </p>
      <p>
        (e) rent, lease, sub-license, adapt, vary or modify or permit any part
        of the Greenview Platform to be combined with or become incorporated
        into any programs;
      </p>
      <p>
        (f) disassemble, decompile, reverse-engineer or create derivative works
        based on the whole or any part of the Greenview Platform;
      </p>
      <p>
        (g) use the Greenview Platform to conduct any illegal or immoral
        activities. Greenview shall be entitled, but not obliged, to monitor
        content and/or other actions of the User or to look for circumstances
        which might point to such activities on the Greenview Platform;
      </p>
      <p>
        (h) use the Greenview Platform in any way that could hinder the
        operations, damage, disable, overburden, impair or compromise
        Greenview’s IT systems or security or interfere with other Users; and
      </p>
      <p>
        (i) collect or harvest information or data from the Greenview Platform
        or Greenview’s IT systems or attempt to decipher any transmissions to or
        from the servers running the Greenview Platform.
      </p>
      <p>
        4.3. The User represents and warrants that: (a) the User has the legal
        right and full power and authority to legally bind itself and any entity
        which it represents to these Terms of Use; (b) any such entity is duly
        registered under the Applicable Law of its jurisdiction of registration;
        and (c) the User and any entity which it represents has not and will not
        enter into any agreements inconsistent with these Terms of Use.
      </p>
      <p>
        4.4. In the event that Greenview’s performance of any of its obligations
        is prevented or delayed by any act or omission by the User or failure by
        the User to perform any relevant obligation hereunder
        <strong>(“User Default”)</strong>, Greenview shall have the right,
        without prejudice to any other right or remedy available to Greenview,
        to suspend any part of the Services and/or any agreement entered into by
        Greenview or its Affiliates with the User or its Affiliates without
        notice until the User remedies the User Default, and to rely on the User
        Default to relieve Greenview from the performance of any of its
        obligations to the extent that the User Default prevents or delays
        Greenview’s performance of such obligations and Greenview shall not be
        liable for any loss or damage arising from its failure or delay to
        perform such obligations.
      </p>
      <p>
        <strong>5. PAYMENTS</strong>
      </p>
      <p>
        5.1 All sums due and payable by the User to Greenview in connection with
        the Services shall be paid promptly by their due dates specified by
        Greenview and shall be non-refundable under all circumstances. Without
        prejudice to the foregoing, Greenview reserves the right to demand
        upfront payment of fees for the provision of any Subscription Services
        for any particular period and such fees shall be non-refundable in the
        event of any termination of such Subscription Services prior to the
        expiry of such period.
      </p>
      <p>
        5.2 In the event that any sum due and payable by the User to Greenview
        is not paid by the due date, Greenview shall be entitled to carry out
        any of the following (without prejudice to any other right or remedy
        available to Greenview):
      </p>
      <p>
        (a) charge the User interest on such outstanding sum at the rate of one
        point five per cent (1.5%) or such other rate permitted by Applicable
        Law for each month (or part thereof) from the due date to the date of
        actual payment thereof;
      </p>
      <p>
        (b) set off such outstanding sum together with any such interest accrued
        thereon (or any part thereof) against any sum payable by Greenview to
        the User;
      </p>
      <p>
        (c) condition any remainder or renewal of any Services on additional
        payment terms; and/or
      </p>
      <p>
        (d) terminate or suspend any part of the Services and/or any agreement
        entered into by Greenview or its Affiliates with the User or its
        Affiliates.
      </p>
      <p>
        5.3 Any amendments made to these Terms of Use shall not be grounds for
        the User’s non-payment of any sum due and payable by the User to
        Greenview.
      </p>
      <p>
        <strong>6. LIMITATION OF LIABILITY AND INDEMNITY</strong>
      </p>
      <p>
        6.1 To the fullest extent permitted by Applicable Law, Greenview shall
        not be liable for any loss or damage suffered by the User in connection
        with the access or use of the Greenview Platform or the Services,
        including but not limited to any special, indirect, incidental or
        consequential loss or any loss of use, revenue, profits, sales,
        business, opportunity, goodwill, reputation, contracts or anticipated
        savings.
      </p>
      <p>
        6.2 Without prejudice to the foregoing, if Greenview is found to be
        liable for any such loss or damage, the maximum aggregate liability of
        Greenview shall be limited to a sum not exceeding the total amount of
        fees received by Greenview for any Services, save that Greenview shall
        only accept liability that is finally determined in accordance with
        Clause 14 and which arises as a direct result of Greenview’s wilful
        breach or gross negligence.
      </p>
      <p>
        6.3 The Greenview Platform and the Services are provided on an "as is"
        and "as available" basis. Greenview expressly disclaims all warranties
        of any kind, express or implied, including but not limited to implied
        warranties of merchantability, satisfactory quality, title,
        non-infringement and fitness for a particular purpose. The use of the
        Greenview Platform and the Services is at the sole risk of the User.
      </p>
      <p>
        6.4 Any claim by the User must be formally commenced within six (6)
        months after the User becomes aware (or ought reasonably to have become
        aware) of the facts giving rise to such claim, failing which the User
        shall have no right of action in respect of the same subject matter of
        such claim. This Clause 6.4 expressly overrides any statutory provision
        that would otherwise apply.
      </p>
      <p>
        6.5 The User shall fully indemnify and hold harmless Greenview, its
        Affiliates and their respective shareholders, officers, employees,
        agents and other representatives (each, an <strong>“Indemnitee”</strong>
        ) against any and all losses, costs, damages, claims, demands, actions,
        proceedings, liabilities and expenses whatsoever (including all legal
        fees on a full indemnity basis) suffered or incurred by any Indemnitee
        arising out of or in connection with any breach by the User of any of
        its obligations under these Terms of Use or any other agreement between
        Greenview and the User, including but not limited to any User Default.
      </p>
      <p>
        <strong>7. PRIVACY POLICY</strong>
      </p>
      <p>
        The access and use of the Greenview Platform by the User is subject to
        the Privacy Policy which is incorporated into these Terms of Use by
        reference.
      </p>
      <p>
        <strong>8. SOFTWARE LICENCE </strong>
      </p>
      <p>
        8.1. All Intellectual Property Rights in or arising out of or in
        connection with the Services, including but not limited to the Greenview
        Platform (collectively, <strong>“Service IP”</strong>), other than
        Intellectual Property Rights in any Data provided by the User, shall be
        owned by Greenview, its Affiliates and/or their respective licensors.
        Save for the Software Licence, nothing in these Terms of Use shall
        constitute a transfer or grant to the User of any right, title or
        interest in any Service IP or any other Intellectual Property Rights
        owned by Greenview, its Affiliates and/or their respective licensors.
      </p>
      <p>
        8.2. Greenview grants to the User a non-exclusive, non-transferable and
        revocable licence (with no right to sub-license) to use the Services via
        the Greenview Platform solely as necessary for the User to enjoy the
        benefit of the Services (<strong>“Software Licence”</strong>), subject
        to the User’s full payment of fees for any Subscription Services.
      </p>
      <p>
        8.3. The User shall not: (a) sub-license, assign, novate the benefit or
        burden of, or otherwise transfer the Software Licence in whole or in
        part; or (b) allow the Greenview Platform or any related software to
        become the subject of any charge, lien or other encumbrance.
      </p>
      <p>
        8.4. During the performance of the Services or in connection with the
        User’s access and use of the Greenview Platform, it may be necessary for
        Greenview to obtain, receive or collect data or information, including
        but not limited to personal data of any User (collectively,{" "}
        <strong>“Data”</strong>). Subject to the Privacy Policy, the User grants
        to Greenview a non-exclusive, worldwide, perpetual, irrevocable and
        royalty-free licence to:
      </p>
      <p>
        (a) retain, use, compile, back-up, distribute, display, store, process,
        reproduce or create derivative works of the Data solely as necessary for
        Greenview to supply the Services to the User;
      </p>
      <p>
        (b) aggregate the Data with other data for use in an anonymous manner
        for Greenview’s marketing and sales activities; and
      </p>
      <p>
        (c) copy, maintain and store the Data on the servers of Greenview and/or
        its contractors throughout the provision of the Services and for any
        further duration as may be prescribed by Applicable Law.
      </p>
      <p>
        8.5 The User represents and warrants that it has obtained all
        authorisations necessary to use and transfer the Data within and outside
        of any country in which the User is located in connection with
        Greenview’s provision of the Services or the User’s access and use of
        the Greenview Platform.
      </p>
      <p>
        8.6 The User shall fully indemnify and hold harmless each Indemnitee
        against any and all losses, costs, damages, claims, demands, actions,
        proceedings, liabilities and expenses whatsoever (including all legal
        fees on a full indemnity basis) suffered or incurred by such Indemnitee
        arising out of or in connection with any claim made against such
        Indemnitee for actual or alleged infringement of a third party's
        Intellectual Property Rights arising out of or in connection with
        Greenview’s collection, use and storage of the Data provided by the
        User, including but not limited to any claim made against Greenview for
        actual or alleged infringement of a third party's Intellectual Property
        Rights arising therefrom.
      </p>
      <p>8.7 The User undertakes that it shall not:</p>
      <p>
        (a) modify, alter, obscure, remove, delete, augment, interfere with, add
        to, publish, transmit, adapt, translate, participate in the transfer or
        sale of, create derivative works from, or in any way exploit any Service
        IP (in whole or in part);
      </p>
      <p>
        (b) at any time do, or omit to do, or permit to be done, any act or
        thing which will in any way impair the rights, title and interest of
        Greenview, its Affiliates and/or their respective licensors in and to
        any Service IP or make any representation or do or permit to be done any
        act which may be taken to indicate that the User has any right, title or
        interest in or to the ownership of any Service IP except as granted
        under the Software Licence, or that may invalidate or jeopardise the
        registration of any Service IP;
      </p>
      <p>
        (c) do, or omit to do, or permit to be done, any act that will or may
        weaken, damage or be detrimental to the reputation or goodwill
        associated with any Service IP or Greenview or its Affiliates;
      </p>
      <p>
        (d) directly or indirectly challenge, contest, or call into question the
        validity or the ownership of any Service IP, and to the extent that any
        such claim exists or may exist, the User irrevocably waives such claim
        or right of action;
      </p>
      <p>
        (e) at any time, use or register or apply to register any company name,
        business name or marks which incorporates any Service IP, any variation
        thereof or which is identical or deceptively similar to any Service IP;
      </p>
      <p>
        (f) in using the Greenview Platform, infringe any third party’s
        Intellectual Property Rights; or
      </p>
      <p>
        (g) assist any other Person directly or indirectly to carry out any of
        the above acts in this Clause 8.7.
      </p>
      <p>
        8.8 If, at any time, the User is deemed under Applicable Law to be the
        proprietor or acquires any proprietary right, title and interest in or
        relating to any Service IP by virtue of the Software Licence or by
        operation of any Applicable Law, the User hereby irrevocably and
        unconditionally assigns to Greenview, its Affiliates and/or their
        respective licensors all such rights, titles and interests. The User
        shall forthwith do all acts and execute all documents which Greenview
        deems necessary for assigning such rights, titles and interests to
        Greenview, its Affiliates and/or their respective licensors without
        payment of any fee or premium to the User. Without prejudice to the
        foregoing, the User hereby grants Greenview an irrevocable power of
        attorney to execute any such documents as may be required to demonstrate
        and record ownership of any rights, titles and interests contemplated
        within this Clause 8.
      </p>
      <p>
        <strong>9. TERMINATION OF SUBSCRIPTION SERVICES</strong>
      </p>
      <p>
        9.1 Without prejudice to any other right or remedy available to it,
        Greenview or the User may terminate any Subscription Services by giving
        not less than thirty (30) days’ written notice of termination.
      </p>
      <p>
        9.2 Without prejudice to any other rights and remedies available to
        Greenview, Greenview shall be entitled to terminate the User’s access to
        and use of any Subscription Services forthwith at any time in the event
        that:
      </p>
      <p>
        (a) the User fails to pay any sum due and payable for the Subscription
        Services by the due date;
      </p>
      <p>
        (b) the User commits a breach of any of its obligations under these
        Terms of Use or any other agreement between Greenview and the User and
        (if such breach is remediable) fails to remedy that breach within thirty
        (30) days after receipt of notice in writing from Greenview to do so;
      </p>
      <p>
        (c) any activity or use of the Services in connection with the User
        Account violates these Terms of Use, the Intellectual Property Rights of
        a third party or any Applicable Law or is otherwise disruptive or
        harmful to a third party;
      </p>
      <p>
        (d) the User takes any step or action in connection with its entering
        administration, provisional liquidation or any composition or
        arrangement with its creditors (other than in relation to a solvent
        restructuring), being wound up (whether voluntarily or by order of the
        court, unless for the purpose of a solvent restructuring), having a
        receiver appointed to any of its assets or ceasing to carry on business
        or, if the step or action is taken in another jurisdiction, in
        connection with any analogous procedure in the relevant jurisdiction;
      </p>
      <p>
        (e) the User suspends, or threatens to suspend, or ceases or threatens
        to cease to carry on all or a substantial part of its business; and/or
      </p>
      <p>(f) the User is convicted of any criminal offence.</p>
      <p>9.3 Upon the termination of any Subscription Services:</p>
      <p>
        (a) the User shall immediately pay to Greenview all outstanding sums due
        and payable by the User to Greenview for the Subscription Services
        rendered up to the date of termination, including any disbursements and
        applicable taxes reasonably incurred up to that time and the termination
        shall not operate to affect any provisions which either expressly (or by
        implication) survive such termination; and
      </p>
      <p>
        (b) the Software Licence in respect of the Subscription Services shall
        immediately terminate and the User shall cease any use of the
        Subscription Services.
      </p>
      <p>
        9.4 Termination of any Subscription Services shall be without prejudice
        to any other right or remedy available to Greenview and shall not affect
        any rights, remedies, obligations and liabilities of Greenview that have
        accrued up to the date of termination, including but not limited to the
        right to claim damages in respect of any breach of these Terms of Use
        which existed at or before the date of termination.
      </p>
      <p>
        9.5 Any provision of these Terms of Use that expressly or by implication
        is intended to have effect after termination of any Subscription
        Services shall continue in full force and effect, including but not
        limited to Clauses 6 to 14.
      </p>
      <p>
        <strong>10. CONFIDENTIALITY</strong>
      </p>
      <p>
        10.1 The User undertakes that it shall not, without the prior written
        consent of Greenview, disclose to any third party any proprietary
        information of Greenview which the User may acquire through its access
        or use of the Greenview Platform or the Services, including but not
        limited to the Service IP and any specifications of the Greenview
        Platform or the Services (collectively,{" "}
        <strong>“Confidential Information”</strong>), except:
      </p>
      <p>
        (a) to the extent that such Confidential Information is in the public
        domain, other than by breach of this Clause 10;
      </p>
      <p>
        (b) to the User’s officers, employees or professional advisers who need
        to know such Confidential Information solely for the User to exercise
        its rights or carry out its obligations under these Terms of Use,
        provided that such officers, employees or professional advisers comply
        with this Clause 10; or
      </p>
      <p>
        (c) as may be required by Applicable Law, a court of competent
        jurisdiction or any governmental or regulatory authority (provided that
        the User shall first consult Greenview on the proposed content of the
        disclosure).
      </p>
      <p>
        10.2 The User shall not use any Confidential Information for any purpose
        other than to exercise the User’s rights and perform its obligations
        under these Terms of Use.
      </p>
      <p>
        <strong>11. NOTICES</strong>
      </p>
      <p>
        11.1 Any notice or other communication given to Greenview in connection
        with these Terms of Use shall be in writing and shall be delivered by
        hand or by registered post to 5A Shenton Way #48-18 Singapore 068814 or
        by email to support@greenviewportal.com.
      </p>
      <p>
        11.2 Any notice or other communication given to the User in connection
        with these Terms of Use shall be in writing and shall be delivered by
        hand or by post to the User’s registered address or by email to the
        User’s last known email address provided to Greenview (if any).
      </p>
      <p>
        11.3 Any notice or other communication shall be deemed to have been
        served on and received by the addressee:
      </p>
      <p>(a) in the case of delivery by hand, when delivered;</p>
      <p>
        (b) in the case of registered post, on the second business day after the
        date of posting (if sent by local mail) and on the seventh business day
        after the date of posting (if sent by air mail); or
      </p>
      <p>(c) in the case of email, at the time of successful transmission,</p>
      <p>
        provided that in each case where delivery by hand occurs after 6pm on a
        business day or on a day which is not a business day, service shall be
        deemed to occur at 9am on the following business day.
      </p>
      <p>
        11.4 This Clause 11 does not apply to the service of any proceedings or
        other documents in any legal action or any method of dispute resolution.
      </p>
      <p>
        <strong>12. GENERAL</strong>
      </p>
      <p>
        12.1 If any provision of these Terms of Use is determined by any court
        or other competent authority to be illegal, invalid or unenforceable,
        the same shall be deemed to be deleted from these Terms of Use and shall
        be of no force and effect, and the other provisions of these Terms of
        Use will continue in effect.
      </p>
      <p>
        12.2 These Terms of Use contain the entire agreement between Greenview
        and the User in respect of the subject matter hereof and supersedes any
        prior agreements, discussions, commitments and writings between such
        parties in respect of the subject matter hereof.
      </p>
      <p>
        12.3 A person who is not Greenview or the User shall have no rights
        under the Contracts (Rights of Third Parties) Act, Chapter 53B of
        Singapore to enforce any provision ofthese Terms of Use.
      </p>
      <p>
        12.4 No failure on the part of Greenview to exercise, and no delay on
        its part in exercising, any right or remedy under these Terms of Use
        will operate as a waiver thereof, nor will any single or partial
        exercise of any right or remedy preclude any other or further exercise
        thereof or the exercise of any other right or remedy. No waiver shall be
        effective unless made in writing and executed by Greenview waiving its
        right or remedy. The rights of Greenview provided in these Terms of Use
        are cumulative and not exclusive of any rights or remedies provided by
        ApplicableLaw.
      </p>
      <p>
        12.5 Time shall not be of the essence in relation to Greenview’s
        performance of the Services. Time shall be of the essence in relation to
        any sums due and payable by the User to Greenview.
      </p>
      <p>
        12.6 The User agrees to the use of electronic communication and to the
        electronic delivery of notices and records of transactions initiated or
        completed via the Greenview Platform.
      </p>
      <p>
        12.7 These Terms of Use do not and will not be construed to create any
        partnership, joint venture, agency, fiduciary relationship or other
        relationship between Greenview and the User.
      </p>
      <p>
        12.8 Any waiver by Greenview of any breach of any of provision of these
        Terms of Use by the User shall not be deemed a waiver of any subsequent
        breach or default by the User and shall in no way affect the other
        provisions of these Terms of Use.
      </p>
      <p>
        12.9 Greenview may at any time assign, transfer, novate or otherwise
        dispose of any of its rights and obligations under these Terms of Use.
        The User shall not assign, transfer, novate or otherwise dispose of any
        of its rights and obligations under these Terms of Use without the prior
        written consent of Greenview. These Terms of Use shall be binding on and
        inure for the benefit of the respective successors and permitted assigns
        of Greenview and the User.
      </p>
      <p>
        12.10 Notwithstanding anything to the contrary herein, Greenview shall
        not be in breach of these Terms of Use nor liable for delay in
        performing or failure to perform any of its obligations under these
        Terms of Use if such delay or failure results from events, circumstances
        or causes beyond Greenview’s reasonable control, including but not
        limited to acts of God, acts of civil or military authority, strikes,
        lockouts, labour disputes, epidemics, pandemics, infectious diseases,
        global health or other life-threatening emergencies, wars, terrorist
        acts, riots, civil commotion, fires, earthquakes, storms, typhoons,
        floods, breakdowns in electronic or computer information or IT systems,
        computer attacks, attacks on or through the internet, service provider,
        telecommunications or hosting facility, or any Applicable Law (including
        government directives, advisories, orders, quarantines and/or curfews).
        In such circumstances, the time for performance by Greenview shall be
        extended by a period equivalent to the period during which performance
        of the obligation has been delayed or failed to be performed.
      </p>
      <p>13. UPDATES TO TERMS OF USE</p>
      <p>
        Greenview reserves the right to update these Terms of Use in its sole
        and absolute discretion from time to time to ensure that they are
        consistent with Applicable Law and/or Greenview’s business requirements.
        Users are advised to check these Terms of Use regularly for any updates.
        Subject to the User’s rights under Applicable Law, the prevailing terms
        of these Terms of Use from time to time shall apply. By continuing to
        access and/or use the Greenview Platform and/or the Services or
        otherwise continuing its relationship with Greenview after any updates
        have been introduced and published, the User shall be deemed to have
        accepted these Terms of Use as updated.
      </p>
      <p>
        <strong>14. GOVERNING LAW AND JURISDICTION</strong>
      </p>
      <p>
        14.1 These Terms of Use shall be governed by and construed in accordance
        with the laws of Singapore.
      </p>
      <p>
        14.2 Greenview and the User agree that the courts of Singapore shall
        have non-exclusive jurisdiction to settle any dispute or claim that
        arises out of or in connection with these Terms of Use or its subject
        matter (including non-contractual disputes or claims).
      </p>
      <p>Version 1.0 effective on April 26, 2021</p>
    </>
  );
};

const TermsOfUse = () => {
  return (
    <TemplateGlobalPage>
      <div className="terms_conditions">{TermsOfUseContent()}</div>
    </TemplateGlobalPage>
  );
};

export default TermsOfUse;
