import find from "lodash/find";
import _ from "lodash";
import SafeDataService, { NA_VALUE } from "../../../services/SafeDataService";

export const PERFORMANCE_REPORT_TYPE = {
  OPERATIONAL_ONLY: "Operational Only",
  ENVIRONMENTAL_ONLY: "Environmental Only",
  ENVIRONMENTAL_BY_OPERATIONAL: "Environmental By Operational",
};

export const PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY = "operational";

class PerformanceMeterBreakdownModel {
  constructor(year, meterBreakdown) {
    this.year = year;
    this.cost = meterBreakdown["cost"];
    this.usage = meterBreakdown["usage"];
    this.emissions = meterBreakdown["emissions"];
    this.meterName = meterBreakdown["meter_name"];
    this.meterId = meterBreakdown["meter_id"];
    this.facilityName = meterBreakdown["facility_name"];
    this.environmentalTypeName = meterBreakdown["environmental_type_name"];
  }

  getMonthValue(month, valueType) {
    return this[valueType][month];
  }
}

class PerformanceTrackerBreakdownModel {
  constructor(trackerBreakdown) {
    this.facilityName = trackerBreakdown["facility_name"];
    this.offsetTypeName = trackerBreakdown["offset_type_name"];
    this.trackerId = trackerBreakdown["tracker_id"];

    this.availableEmissionsOffsets =
      trackerBreakdown["available_emissions_offsets"];

    this.availableBundledEmissionsOffsets =
      trackerBreakdown["available_bundled_emissions_offsets"];
    this.usedBundledEmissionsOffsets =
      trackerBreakdown["used_bundled_emissions_offsets"];
    this.surplusBundledEmissionsOffsets =
      trackerBreakdown["surplus_bundled_emissions_offsets"];

    this.availableUnbundledEmissionsOffsets =
      trackerBreakdown["available_unbundled_emissions_offsets"];
    this.usedUnbundledEmissionsOffsets =
      trackerBreakdown["used_unbundled_emissions_offsets"];
    this.surplusUnbundledEmissionsOffsets =
      trackerBreakdown["surplus_unbundled_emissions_offsets"];
  }
}

export default class PerformanceYearlyReportModel {
  constructor(year, report) {
    this.year = year;
    this.usage = report.usage;
    this.cost = report.cost;
    this.emissions = report.emissions;
    this.operational = report.operational;
    this.meterBreakdowns = report["meter_breakdowns"].map(
      (m) => new PerformanceMeterBreakdownModel(year, m)
    );
    this.totalMarketPurchaseDeduction =
      report["total_market_purchase_deduction"];
    this.trackerBreakdowns = report["tracker_breakdowns"].map(
      (t) => new PerformanceTrackerBreakdownModel(t)
    );
    this.totalAfterOffsets = report["total_after_offsets"];
  }

  getMonthlyValue(month, valueType) {
    return this[valueType].monthly[month];
  }

  getQuarterlyValue(month, valueType) {
    return this[valueType].quarterly[month];
  }

  getYearlyValue(valueType) {
    return this[valueType].yearly;
  }

  getMeterDetails() {
    return this.meterBreakdowns.map((m) => ({
      id: m.meterId,
      name: m.meterName,
      facilityName: m.facilityName,
    }));
  }

  getMeterMonthlyValue(meterId, month, valueType) {
    const meter = find(this.meterBreakdowns, (m) => m.meterId === meterId);
    if (!meter) return NA_VALUE;
    return meter.getMonthValue(month, valueType);
  }

  getTypeMonthlyValue(typeName, month, valueType) {
    const typeTotal = SafeDataService.sum(
      _.chain(this.meterBreakdowns)
        .filter((m) => m.environmentalTypeName === typeName)
        .map((m) => m.getMonthValue(month, valueType))
        .value()
    );

    return typeTotal;
  }

  getAvailableOffsetTypeValue(typeName) {
    return SafeDataService.sum(
      _.chain(this.trackerBreakdowns)
        .filter((t) => t.offsetTypeName === typeName)
        .map((t) => t.availableEmissionsOffsets)
        .value()
    );
  }

  getUsedOffsetTypeValue(typeName) {
    // Note: below is possible as currently portal only supports 1 type of offset (EAC),
    //  otherwise need to group trackers by offset type, and run loop for all offsets
    return this.totalMarketPurchaseDeduction;
  }

  getAvailableBundledEACOffsetValueByType(typeName) {
    return SafeDataService.sum(
      _.chain(this.trackerBreakdowns)
        .filter((t) => t.offsetTypeName === typeName)
        .map((t) => t.availableBundledEmissionsOffsets)
        .value()
    );
  }

  getUsedBundledEACOffsetValueByType(typeName) {
    return SafeDataService.sum(
      _.chain(this.trackerBreakdowns)
        .filter((t) => t.offsetTypeName === typeName)
        .map((t) => t.usedBundledEmissionsOffsets)
        .value()
    );
  }

  getSurplusBundledEACOffsetValueByType(typeName) {
    return SafeDataService.sum(
      _.chain(this.trackerBreakdowns)
        .filter((t) => t.offsetTypeName === typeName)
        .map((t) => t.surplusBundledEmissionsOffsets)
        .value()
    );
  }

  getAvailableUnbundledEACOffsetValueByType(typeName) {
    return SafeDataService.sum(
      _.chain(this.trackerBreakdowns)
        .filter((t) => t.offsetTypeName === typeName)
        .map((t) => t.availableUnbundledEmissionsOffsets)
        .value()
    );
  }

  getUsedUnbundledEACOffsetValueByType(typeName) {
    return SafeDataService.sum(
      _.chain(this.trackerBreakdowns)
        .filter((t) => t.offsetTypeName === typeName)
        .map((t) => t.usedUnbundledEmissionsOffsets)
        .value()
    );
  }

  getSurplusUnbundledEACOffsetValueByType(typeName) {
    return SafeDataService.sum(
      _.chain(this.trackerBreakdowns)
        .filter((t) => t.offsetTypeName === typeName)
        .map((t) => t.surplusUnbundledEmissionsOffsets)
        .value()
    );
  }

  getTypeNames() {
    return this.meterBreakdowns.map((m) => m.environmentalTypeName);
  }

  getOffsetTypeNames() {
    return this.trackerBreakdowns.map((t) => t.offsetTypeName);
  }
}
