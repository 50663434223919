import React, { useCallback, useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

import InputTargetStepSelectTopic from "./InputTargetStepSelectTopic";
import {
  RESOURCES,
  TARGET_PERIOD_TYPE,
  TARGET_TOPIC,
} from "../../config/constants";
import TargetService from "../../services/TargetService";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import InputTargetStepTargetDetails from "./InputTargetStepTargetDetails";
import InputTargetStepTargetValue from "./InputTargetStepTargetValue";
import InputTargetStepTargetPeriod from "./InputTargetStepTargetPeriod";
import TargetConfigParamsModel from "./TargetConfigParamsModel";
import HorizontalStepper from "../common/HorizontalStepper";
import CollectiveTargetService from "../../services/CollectiveTargetService";
import Delayed from "../common/Delayed";
import InputTargetValueRecordTable from "./InputTargetValueRecordTable";
import LoadingSpinner from "../common/LoadingSpinner";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import Spacer from "../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconArrowDown, IconCopy, IconTrash } from "@tabler/icons-react";

const COLLECTIVE_TARGET_STEPS = {
  SETUP_TARGET: "Setup",
  SET_TARGET_VALUE: "Site's Target Value",
};

const getEmptyTargetValuesFromInventory = (inventoryChildren) => {
  return inventoryChildren.map((node) => {
    return {
      id: node.nodeValue.id,
      name: node.nodeValue.name,
      targetValue: "",
    };
  });
};

const getExistingTargetValues = (emptyTargetValues, existingTargetValues) => {
  return emptyTargetValues.map((value) => {
    return {
      ...value,
      targetValue: existingTargetValues.find(
        (newValue) => newValue.resource_id === value.id
      )?.target_value,
    };
  });
};

const isTopicInputValid = (topic) => {
  return topic !== "";
};

const isTargetDetailsValid = (params, config, topic) => {
  let isTargetDetailInputComplete =
    !!params["performance_group_name"]?.length > 0;
  if (topic === TARGET_TOPIC.ENVIRONMENTAL && isTargetDetailInputComplete) {
    isTargetDetailInputComplete =
      isTargetDetailInputComplete && !!params["subtopic_name"]?.length > 0;
    if (!!params["intensity_metric"]?.length > 0) {
      const operationUnitMap = TargetConfigParamsModel.getOperationUnitMap(
        params,
        config
      );
      if (Object.keys(operationUnitMap).length > 0) {
        isTargetDetailInputComplete =
          isTargetDetailInputComplete && !!params["area_unit_id"]?.length > 0;
      }
    }
  }
  return isTargetDetailInputComplete;
};

const isTargetValueValid = (params, hasComparisonYear) => {
  return (
    !!params["direction"]?.length > 0 &&
    !!params["unit_id"]?.length > 0 &&
    !(hasComparisonYear ^ !!params["comparison_year"])
  );
};

const isTargetPeriodValid = (params) => {
  return params["period_type"] === TARGET_PERIOD_TYPE.TOTAL
    ? !!params["baseline_year"]
    : !!params["period_type"] && !!params["target_year"];
};

const InputCollectiveTargetModal = ({
  show,
  setShow,
  onInputSuccess,
  existingCollectiveTarget = null,
  onTriggerDelete,
  onReloadCollectiveTarget,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const isEditing =
    existingCollectiveTarget !== null && !!existingCollectiveTarget?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [topic, setTopic] = useState("");
  const [config, setConfig] = useState({});
  const [params, setParams] = useState({});
  const [hasComparisonYear, setHasComparisonYear] = useState();
  const [currentStep, setCurrentStep] = useState(
    COLLECTIVE_TARGET_STEPS.SETUP_TARGET
  );
  const [targetPreviewName, setTargetPreviewName] = useState("");
  const [globalTargetValue, setGlobalTargetValue] = useState();
  const [targetValues, setTargetValues] = useState([]);
  const [invalidTargetValues, setInvalidTargetValues] = useState([]);

  const handleClose = () => {
    setShow(false);
  };

  const handleCancel = () => {
    resetFormToEmpty();
    if (existingCollectiveTarget) {
      onReloadCollectiveTarget();
    }
    setShow(false);
  };

  const setFormToExistingTarget = () => {
    setTopic(existingCollectiveTarget.topic);
    setHasComparisonYear(!!existingCollectiveTarget.params.comparison_year);
    setParams({
      ...existingCollectiveTarget.params,
      target_year: existingCollectiveTarget.targetYear,
      baseline_year: existingCollectiveTarget.baselineYear,
    });

    setTargetValues(
      getExistingTargetValues(
        getEmptyTargetValuesFromInventory(
          userInventory.selectedTreeNode.get.children
        ),
        existingCollectiveTarget.targetValues
      )
    );
  };

  const resetFormToEmpty = () => {
    setTopic("");
    setHasComparisonYear(undefined);
    setParams({});
    clearAllTargetValueRows();
    setTargetPreviewName("");
    setGlobalTargetValue("");
    setCurrentStep(COLLECTIVE_TARGET_STEPS.SETUP_TARGET);
    setInvalidTargetValues([]);
    setIsValidated(false);
  };

  useEffect(() => {
    if (
      selectedInventory &&
      selectedInventory.id &&
      selectedInventory.type === RESOURCES.PORTFOLIO
    ) {
      TargetService.getConfig(selectedInventory.type, selectedInventory.id)
        .then((data) => {
          setConfig(data);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load target configuration.</span>
          );
        });
    }
  }, [selectedInventory]);

  useEffect(() => {
    if (existingCollectiveTarget && existingCollectiveTarget.id) {
      setFormToExistingTarget();
    } else {
      resetFormToEmpty();
    }
  }, [selectedInventory, existingCollectiveTarget]);

  const saveCollectiveTarget = (event) => {
    if (isAllTargetValuesValid()) {
      event.preventDefault();
      if (!selectedInventory.id) {
        toastContext.addFailToast(
          <span>An error occurred. Please try again.</span>
        );
        return;
      }
      setIsSaving(true);
      const httpRequest = isEditing
        ? CollectiveTargetService.updateCollectiveTarget(
            selectedInventory.type,
            selectedInventory.id,
            existingCollectiveTarget.id,
            getTargetFromParams()
          )
        : CollectiveTargetService.createCollectiveTarget(
            selectedInventory.type,
            selectedInventory.id,
            getTargetFromParams()
          );
      httpRequest
        .then(() => {
          setIsSaving(false);
          handleClose();
          onInputSuccess();
          resetFormToEmpty();
          toastContext.addSuccessToast(
            `Shared target ${isEditing ? "saved" : "created"} successfully.`
          );
        })
        .catch(() => {
          setIsSaving(false);
          toastContext.addFailToast(
            <span>
              Failed to {isEditing ? "edit" : "create"} shared target. Please
              try again.
            </span>
          );
        });
    }
  };

  const onTopicChange = (newTopic) => {
    setTopic(newTopic);
    setParams({
      ...params,
      subtopic_name: "",
      performance_group_name: "",
      intensity_metric: "",
      area_unit_id: "",
      un_sdgs: null,
      unit_id: "",
      target_name: "",
      use_location_based: null,
    });
  };

  const getTargetFromParams = () => {
    let targetParams = { ...params };
    ["target_year", "baseline_year"].forEach((k) => delete targetParams[k]);
    return {
      topic,
      target_year: params["target_year"],
      baseline_year: params["baseline_year"],
      params: targetParams,
      target_values: targetValues.map((value) => {
        return {
          resource_id: value.id,
          resource_type: RESOURCES.SITE,
          target_value:
            value.targetValue === null || value.targetValue === undefined
              ? ""
              : value.targetValue.toString(),
        };
      }),
    };
  };

  const isSetupStepCompleted = () => {
    return (
      isTopicInputValid(topic) &&
      isTargetDetailsValid(params, config, topic) &&
      isTargetValueValid(params, hasComparisonYear) &&
      isTargetPeriodValid(params)
    );
  };

  const getSetupStepContent = () => {
    return (
      <div className="create-target-form__portfolio-steps">
        <div className="mb-3">
          A Shared Target creates the same target for all sites under a
          portfolio but with a specific target value for each site.
        </div>
        <InputTargetStepSelectTopic
          title="STEP 1. SELECT A TOPIC"
          topic={topic}
          setTopic={onTopicChange}
          isInvalid={isValidated && !isTopicInputValid(topic)}
        />
        <InputTargetStepTargetDetails
          title="STEP 2. WHAT IS YOUR TARGET?"
          topic={topic}
          config={config}
          params={params}
          setParams={setParams}
          isInvalid={
            isValidated && !isTargetDetailsValid(params, config, topic)
          }
        />
        <InputTargetStepTargetValue
          title="STEP 3. DETERMINING TARGET"
          topic={topic}
          config={config}
          params={params}
          setParams={setParams}
          businessErrors={null}
          targetInvalid={null}
          hasComparisonYear={hasComparisonYear}
          setHasComparisonYear={setHasComparisonYear}
          isInvalid={
            isValidated && !isTargetValueValid(params, hasComparisonYear)
          }
        />
        <InputTargetStepTargetPeriod
          title={`STEP 4. DETERMINING TARGET PERIOD`}
          params={params}
          setParams={setParams}
          isInvalid={isValidated && !isTargetPeriodValid(params)}
        />
      </div>
    );
  };

  const goToStep = async (stepId) => {
    if (currentStep === COLLECTIVE_TARGET_STEPS.SETUP_TARGET) {
      setIsValidated(false);
      if (!isSetupStepCompleted()) {
        setIsValidated(true);
        return;
      }
    }
    setCurrentStep(stepId);
    if (stepId === COLLECTIVE_TARGET_STEPS.SET_TARGET_VALUE) {
      setIsLoading(true);
      try {
        const targetPreview = await CollectiveTargetService.getPreview(
          selectedInventory.type,
          selectedInventory.id,
          getTargetFromParams()
        );
        setTargetPreviewName(targetPreview.name);
      } catch (error) {
        setTargetPreviewName("");
        toastContext.addFailToast(
          <span>Failed to get shared target preview. Please try again.</span>
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getTargetPeriod = () => {
    if (params["period_type"] === TARGET_PERIOD_TYPE.ANNUAL) {
      return `Jan ${params["target_year"]} - Dec ${params["target_year"]}`;
    } else if (params["period_type"] === TARGET_PERIOD_TYPE.TOTAL) {
      return `Jan ${params["baseline_year"]} - Dec ${params["target_year"]}`;
    } else {
      return "";
    }
  };

  const populateTargetValuesWithGlobalValue = () => {
    setTargetValues(
      targetValues.map((value) => {
        return { ...value, targetValue: globalTargetValue };
      })
    );
  };

  const clearAllTargetValueRows = () => {
    setTargetValues(
      getEmptyTargetValuesFromInventory(
        userInventory.selectedTreeNode.get.children
      )
    );
  };

  const onTargetValuesChange = (newTargetValue) => {
    setTargetValues(
      targetValues.map((value) => {
        if (value.id === newTargetValue.id) {
          return { ...value, targetValue: newTargetValue.targetValue };
        } else {
          return value;
        }
      })
    );
  };

  const copyTargetValues = useCallback(async () => {
    const excelTargetValues = targetValues
      .map((row, rowIndex) => {
        return [rowIndex + 1, row.name].join("\t");
      })
      .join("\n");

    try {
      await navigator.clipboard.writeText(excelTargetValues);
      toastContext.addSuccessToast(<span>Site names have been copied.</span>);
    } catch (err) {
      toastContext.addFailToast(<span>Failed to copy target values.</span>);
    }
  }, [targetValues]);

  const getSetTargetValueStepContent = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <>
          <div className="gv-body-2--bold gvds-color--gray6">
            STEP 5. ENTER SITE TARGET VALUES
          </div>
          <div className="create-target-form__collective-target-value__container">
            <div className="create-target-form__collective-target-value-instruction">
              Fill in the target values (__{params["unit_id"]}) for all sites in
              the table below.
            </div>
            <div className="create-target-form__collective-target-preview-name">
              {targetPreviewName}
            </div>

            <div className="d-flex align-items-center mb-3">
              <div className="create-target-form__quick-fill-label me-2">
                Quick-fill all rows:
              </div>
              <GVDSFormField
                className="input-global-value me-2"
                placeholder="value"
                value={globalTargetValue}
                onInput={(value) => {
                  setGlobalTargetValue(value);
                }}
                style={{ width: "100px" }}
              />
              <GVDSButton
                className="btn-populate-target-values"
                variant={buttonVariant.tertiary}
                onClick={populateTargetValuesWithGlobalValue}
                icon={<GVDSIcon Icon={IconArrowDown} />}
                text="Populate"
              />
              <Spacer />
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={copyTargetValues}
                icon={<GVDSIcon Icon={IconCopy} />}
                text="Copy rows into excel"
              />
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                className="btn-clear-all-target-values ms-2"
                onClick={clearAllTargetValueRows}
                icon={<GVDSIcon Icon={IconTrash} />}
                text="Clear all rows"
              />
            </div>

            <Delayed>
              <InputTargetValueRecordTable
                unitId={params["unit_id"]}
                targetPeriod={getTargetPeriod()}
                inputRecord={targetValues}
                onTargetValuesChange={onTargetValuesChange}
                disabled={false}
                invalidTargetValues={invalidTargetValues}
              />
            </Delayed>

            <div>
              After saving this Shared Target, a target will be created for each
              site under this portfolio. The targets can only be edited or
              deleted at the portfolio level. Each site's target progress can be
              monitored at the site level.
            </div>
          </div>
        </>
      );
    }
  };

  const getInvalidTargetValues = () => {
    return targetValues.filter((targetValue) => {
      const value = parseFloat(targetValue.targetValue);
      return value < 0 || isNaN(value);
    });
  };

  const isAllTargetValuesValid = () => {
    const invalidTargetValues = getInvalidTargetValues();
    setInvalidTargetValues(invalidTargetValues);
    return invalidTargetValues.length === 0;
  };

  const stepperSteps = [
    {
      id: COLLECTIVE_TARGET_STEPS.SETUP_TARGET,
      title: "1",
      description: "Setup",
    },
    {
      id: COLLECTIVE_TARGET_STEPS.SET_TARGET_VALUE,
      title: "2",
      description: "Site's Target Value",
    },
  ];

  let content;
  let footer;

  switch (currentStep) {
    case COLLECTIVE_TARGET_STEPS.SETUP_TARGET:
      content = getSetupStepContent();
      footer = (
        <>
          <div className="gvds-text--modal-warning-text me-2">
            You can only click "Next" when all compulsory fields have been
            filled in.
          </div>
          <GVDSButton
            variant={buttonVariant.tertiary}
            className="me-2"
            onClick={handleCancel}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => goToStep(COLLECTIVE_TARGET_STEPS.SET_TARGET_VALUE)}
            text="Next"
          />
        </>
      );
      break;
    case COLLECTIVE_TARGET_STEPS.SET_TARGET_VALUE:
      content = getSetTargetValueStepContent();
      footer = (
        <>
          <GVDSButton
            variant={buttonVariant.tertiary}
            className="me-4"
            onClick={handleCancel}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => goToStep(COLLECTIVE_TARGET_STEPS.SETUP_TARGET)}
            className="me-2"
            text="Previous"
          />
          <GVDSButton
            className="btn-submit-collective-target"
            variant={buttonVariant.primary}
            onClick={saveCollectiveTarget}
            disabled={isSaving}
            text="Save"
          />
        </>
      );
      break;
  }

  return (
    <GVDSModal
      title={`${isEditing ? "Manage" : "Create"} Shared Target`}
      size={GVDSModal.Size.large}
      show={show}
      onHide={handleClose}
    >
      <div className="create-target-form__stepper">
        <HorizontalStepper
          steps={stepperSteps}
          currentStepId={currentStep}
          isStepsClickable={isSetupStepCompleted()}
          onStepClick={goToStep}
        />
      </div>
      <GVDSModal.Body>
        <Form className="create-target-form" onSubmit={saveCollectiveTarget}>
          {content}
        </Form>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        {isEditing && (
          <GVDSButton
            variant={buttonVariant.destructive_tertiary}
            onClick={onTriggerDelete}
            text="Delete"
          />
        )}
        <div className="ms-auto d-flex align-items-center">{footer}</div>
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default InputCollectiveTargetModal;
