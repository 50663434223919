import React from "react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconLock } from "@tabler/icons-react";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import { useHistory, useLocation } from "react-router-dom";
import { getRedirectURLWithCurrentParam } from "../QueryHandler";
import { ADMIN_BILLING } from "../../../config/ROUTES_NAME";
import { useTranslation } from "react-i18next";

export const NoSubscriptionTitle = () => {
  const { t } = useTranslation();

  return <>{t("shared.no-subscription-title")}</>;
};

export const NoSubscriptionDescription = ({ onClickListener }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <div>{t("shared.no-subscription-description1")}</div>
      <div className="mt-2">{t("shared.no-subscription-description2")}</div>
      <GVDSButton
        variant={buttonVariant.primary}
        text={t("shared.no-subscription-cta")}
        className="mt-3"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          history.push(getRedirectURLWithCurrentParam(ADMIN_BILLING, location));
          if (onClickListener) {
            onClickListener();
          }
        }}
      />
    </>
  );
};

const NoSubscriptionMessage = () => {
  return (
    <div className="feature-lock">
      <GVDSIcon Icon={IconLock} className="gvds-empty-content--icon" />
      <div className="gvds-empty-content--title">
        <NoSubscriptionTitle />
      </div>
      <div className="feature-lock-message">
        <NoSubscriptionDescription />
      </div>
    </div>
  );
};

export default NoSubscriptionMessage;
