import axios from "axios";
import { API_URL } from "../config/api-config";
import _ from "lodash";
import { USER_ROLE_ORDER } from "../config/constants";

export class ResourceRoleModel {
  constructor(resourceRole) {
    this.roleId = resourceRole.role_id;
    this.roleName = resourceRole.role_name;
    this.resourceId = resourceRole.resource_id;
    this.resourceType = resourceRole.resource_type;
    this.inventoryName = resourceRole.inventory_name;
  }
}

export class UserListModel {
  constructor(userData) {
    this.id = userData.id;
    this.email = userData.email;
    this.fullName = userData.full_name;
    this.position = userData.position;
    this.status = userData.status;
    this.resourceRoles = userData.resource_roles.map(
      (resource_role) => new ResourceRoleModel(resource_role)
    );
  }

  get get_user_status() {
    return this.status;
  }

  get getUserResourceRoleStrings() {
    if (this.resourceRoles.length === 0) {
      return [];
    }
    return this.resourceRoles
      .filter((resource) => {
        return !!resource.inventoryName;
      })
      .map((resource) => {
        return `(${_.capitalize(resource.resourceType)}) ${
          resource.inventoryName
        }`;
      });
  }
}

export default class UserService {
  static createPortalAdminUser = async (email) => {
    try {
      const data = {
        email: email,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/portal_admins`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getAllUsers = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/users");
      return response.data.map((d) => new UserListModel(d));
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static getAllRolesForResource = async (resource) => {
    try {
      const response = await axios.get(API_URL + "/api/v1/roles", {
        params: { resource },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static checkPermissions = async (permissionsToCheck, params) => {
    try {
      const response = await axios.get(API_URL + "/api/v1/permissions", {
        params: { permissionsToCheck: permissionsToCheck.join(","), ...params },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getUserProfile = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/user/profile`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateUserProfile = async (full_name, position) => {
    try {
      const data = {
        full_name,
        position,
      };
      const response = await axios.put(`${API_URL}/api/v1/user/profile`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static changePassword = async (current_password, new_password) => {
    try {
      const data = {
        current_password,
        new_password,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/user/change_password`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getMfaSetupToken = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/user/mfa_setup`);
      return response.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  static verifyMfaFirstTime = async (otp) => {
    try {
      const data = {
        user_code: otp,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/user/mfa_setup`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static disableMfa = async (password) => {
    try {
      const response = await axios.delete(`${API_URL}/api/v1/user/mfa_setup`, {
        data: { password: password },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateTermsAndConditions = async (
    userId,
    { full_name, position, accepted_terms_conditions, accepted_privacy_policy }
  ) => {
    try {
      const data = {
        full_name,
        position,
        accepted_terms_conditions,
        accepted_privacy_policy,
      };
      const response = await axios.put(
        `${API_URL}/api/v1/users/${userId}/terms_and_conditions`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static resendUserInvite = async (userId) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/users/${userId}/resend_invite`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getUserStatusByEmail = async (email) => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${API_URL}/api/v1/users_by_email/${email}/status`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static resendUserInviteByEmail = async (email) => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${API_URL}/api/v1/users_by_email/${email}/resend_invite`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static setNewUserPassword = async (email, confirmationCode, newPassword) => {
    try {
      const data = {
        confirmation_code: confirmationCode,
        new_password: newPassword,
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${API_URL}/api/v1/users_by_email/${email}/set_new_password`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateLastPasswordReset = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/user/last_password_reset`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static postAuthCheck = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/v1/user/post_auth`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateLanguage = async (lang_code) => {
    try {
      const data = {
        lang_code,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/user/language`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static offboardUser = async (userId) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/users/${userId}/offboard`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getPortalAdmins = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/portal_admins`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export class UserRoleService {
  static roleSortFn = (role1, role2) => {
    const sortOrder1 = USER_ROLE_ORDER[role1.name];
    const sortOrder2 = USER_ROLE_ORDER[role2.name];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return -1;
    } else if (sortOrder2 !== undefined) {
      return 1;
    } else {
      return role1.name.localeCompare(role2.name);
    }
  };
}
