import React, { useContext } from "react";
import UserInventoryContext from "../../context/UserInventoryContext";
import Row from "react-bootstrap/Row";
import EnvironmentalMetricsWidget from "./Widgets/EnvironmentalMetricsWidget";
import { RESOURCES } from "../../config/constants";
import Col from "react-bootstrap/Col";
import SocialImpactWidget from "./Widgets/SocialImpactWidget";
import TargetWidget from "./Widgets/TargetWidget";
import InitiativeWidget from "./Widgets/InitiativeWidget";
import BestPracticeWidget from "./Widgets/BestPracticeWidget";

const LandingPagePerformance = () => {
  const userInventory = useContext(UserInventoryContext);
  if (
    !userInventory.isLoadingInventory.get &&
    userInventory.selectedInventory.get
  ) {
    let currentInventoryType = userInventory.selectedInventory.get.type;
    return (
      <div className="landing-page__container p-0">
        <Row className="landing-page__row-widget-container">
          <Col>
            <div className="base-widget__container">
              <EnvironmentalMetricsWidget />
            </div>
          </Col>
        </Row>
        {currentInventoryType !== RESOURCES.FACILITY && (
          <Row className="landing-page__row-widget-multi-col-container">
            <Col>
              <SocialImpactWidget />
            </Col>
            <Col>
              {currentInventoryType === RESOURCES.SITE && <InitiativeWidget />}
            </Col>
          </Row>
        )}
        {currentInventoryType === RESOURCES.SITE && (
          <>
            <Row className="landing-page__row-widget-multi-col-container">
              <Col>
                <TargetWidget />
              </Col>
              <Col>
                <BestPracticeWidget />
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
  return null;
};

export default LandingPagePerformance;
