import LoadingSpinner from "../../../common/LoadingSpinner";
import React, { useContext, useRef, useState } from "react";
import { PerformanceTimelineChartViewHeader } from "./PerformanceTimelineChartViewHeaders";
import {
  defaultPeriodGroupingOption,
  performanceTimelineChartConfig,
} from "./PerformanceTimelineViewConfig";
import PerformanceTimelineChartViewContent from "./PerformanceTimelineChartViewContent";
import { PerformanceTimelineChartUtils } from "./PerformanceTimelineChartUtils";
import ExpandedPerformanceTimelineChartView from "./ExpandedPerformanceTimelineChartView";
import {
  downloadChartAsImageTimeoutInMs,
  expandedModalViewOptionEnum,
  PerformanceTimelineMinimapPositionModel,
} from "../../Models/DashboardModels";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import { DashboardTableExcelDownloader } from "../../DashboardTableExcelDownloader";
import { WidgetTypeEnum } from "../../Models/WidgetModels";

const PerformanceTimelineChartView = ({
  isLoading,
  title,
  titleInfoTooltip,
  period,
  hasDataGaps,
  chartData,
  chartKeys,
  subtopic,
  intensity,
  intensityUnit,
  unit,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [selectedTimePeriodOption, setSelectedTimePeriodOption] = useState(
    defaultPeriodGroupingOption
  );
  const [isShowExpandedView, setIsShowExpandedView] = useState(false);
  const [selectedExpandedViewOption, setSelectedExpandedViewOption] =
    useState(null);
  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const isDownloadingFromTableView = useRef(false);

  const [minimapPosition, setMinimapPosition] = useState(null);

  const groupedData = PerformanceTimelineChartUtils.groupData(
    chartData,
    chartKeys,
    selectedTimePeriodOption
  );

  const hasData = groupedData.length > 0;
  const showExpandedChartView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
    setIsShowExpandedView(true);
  };

  const showExpandedTableView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
    setIsShowExpandedView(true);
  };

  let content;

  if (isLoading) {
    content = (
      <div className="dashboard--performance-timeline__mini-view-box">
        <LoadingSpinner />
      </div>
    );
  } else {
    const fileName = DashboardWidgetSharedUtils.getDownloadFileName(
      userInventory.selectedTreeNode.get?.nodeValue?.name || "",
      WidgetTypeEnum.PerformanceTimelineChart,
      subtopic,
      period
    );

    const downloadChartAsImage = async (
      chartViewId,
      isFromExpandedView,
      excludeOnDownloadIds
    ) => {
      if (selectedExpandedViewOption === expandedModalViewOptionEnum.TABLE) {
        setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
      }
      setIsDownloadingChart(true);

      setTimeout(async () => {
        try {
          await DashboardWidgetSharedUtils.downloadChartAsImage(
            chartViewId,
            fileName,
            excludeOnDownloadIds,
            isFromExpandedView
          );
        } catch (e) {
          toastContext.addFailToast(
            <span>Failed to download chart as image.</span>
          );
        } finally {
          setIsDownloadingChart(false);
          if (isDownloadingFromTableView.current) {
            setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
            isDownloadingFromTableView.current = false;
          }
        }
      }, downloadChartAsImageTimeoutInMs);
    };

    const onDownloadTableAsExcel = () => {
      const excelSheetName = "Table";
      const periods = groupedData.map((datum) => datum.groupName);
      const headerRows = [
        ["Period", period],
        [],
        ["Type", ...periods.map((period) => `${period} (${unit})`)],
      ];

      const dataRows = PerformanceTimelineChartUtils.getTableRowData(
        groupedData,
        chartKeys,
        periods
      );

      const columnWidths = [30, ...periods.map(() => 18)];

      DashboardTableExcelDownloader.downloadExcel(
        headerRows,
        dataRows,
        fileName,
        excelSheetName,
        columnWidths
      );
    };

    const handleMinimapPositionChange = (
      startXDomainValue,
      endXDomainValue
    ) => {
      setMinimapPosition(
        new PerformanceTimelineMinimapPositionModel(
          startXDomainValue,
          endXDomainValue
        )
      );
    };

    content = (
      <div id={performanceTimelineChartConfig.chartViewId}>
        <div className="dashboard--performance-timeline__mini-view-box">
          <PerformanceTimelineChartViewHeader
            title={title}
            titleInfoTooltip={titleInfoTooltip}
            period={period}
            hasDataGaps={hasDataGaps}
            subtopic={subtopic}
            intensity={intensity}
            showExpandedChartView={showExpandedChartView}
            showExpandedTableView={showExpandedTableView}
            hasData={hasData}
            selectedTimePeriodOption={selectedTimePeriodOption}
            onSelectTimePeriodOption={(option) =>
              setSelectedTimePeriodOption(option)
            }
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(
                performanceTimelineChartConfig.chartViewId,
                false,
                [
                  performanceTimelineChartConfig.miniViewExcludeOnDownloadContainerId,
                ]
              )
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
          <div className="content-container">
            {hasData ? (
              <PerformanceTimelineChartViewContent
                chartData={groupedData}
                chartKeys={chartKeys}
                unit={unit}
                intensityUnit={intensityUnit}
                selectedTimePeriod={selectedTimePeriodOption}
                minimapPosition={minimapPosition}
                onMinimapPositionChange={handleMinimapPositionChange}
              />
            ) : (
              <div className="table__no_content">No data available</div>
            )}
          </div>
        </div>
        {hasData && (
          <ExpandedPerformanceTimelineChartView
            title={title}
            period={period}
            subtopic={subtopic}
            intensity={intensity}
            intensityUnit={intensityUnit}
            chartData={groupedData}
            chartKeys={chartKeys}
            unit={unit}
            hasDataGaps={hasDataGaps}
            isShowExpandedChartView={isShowExpandedView}
            onCloseModal={() => setIsShowExpandedView(false)}
            selectedExpandedViewOption={selectedExpandedViewOption}
            onSelectExpandedViewOption={(option) =>
              setSelectedExpandedViewOption(option)
            }
            selectedTimePeriodOption={selectedTimePeriodOption}
            onSelectTimePeriodOption={(option) =>
              setSelectedTimePeriodOption(option)
            }
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(
                performanceTimelineChartConfig.expandedChartViewId,
                true,
                [
                  performanceTimelineChartConfig.expandedViewExcludeOnDownloadContainerId,
                ]
              )
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
            minimapPosition={minimapPosition}
            onMinimapPositionChange={handleMinimapPositionChange}
          />
        )}
      </div>
    );
  }

  return content;
};

export default PerformanceTimelineChartView;
