import React, { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import ToastContext from "../../../context/ToastContext";
import HelpArticleService from "../../../services/HelpArticleService";
import HelpfulTipsLinkDisplay from "../../Help/HelpfulTipsLinkDisplay";
import BaseWidgetHeadingSubheader from "./BaseWidgetHeadingSubheader";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconBulb } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const HelpfulTipsWidget = () => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [helpfulTips, setHelpfulTips] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    HelpArticleService.getHelpfulTips()
      .then((data) => {
        setHelpfulTips(data);
      })
      .catch((e) => {
        toastContext.addFailToast("Failed to load helpful tips");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <>
        {helpfulTips.map((tip) => (
          <HelpfulTipsLinkDisplay key={tip.id} helpArticleListItemModel={tip} />
        ))}
      </>
    );
  }

  return (
    <div className="base-widget__container">
      <div className="base-widget__heading-icon">
        <GVDSIcon Icon={IconBulb} />
      </div>
      <div className="base-widget__heading">
        <div>
          {t("landing-page.weekly-helpful-tips.title")}{" "}
          <BaseWidgetHeadingSubheader
            text={t("landing-page.weekly-helpful-tips.description")}
          />
        </div>
      </div>
      <div className="widget--helpful-tips__content">{content}</div>
    </div>
  );
};
export default HelpfulTipsWidget;
