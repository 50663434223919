import axios from "axios";
import { API_URL } from "../../config/api-config";

export default class ClimateZoneService {
  static KoppenGeigerClimateZone = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/climate_zone/koppen_geiger"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  static BaileysEcoregionClimateZone = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/climate_zone/baileys_ecoregion"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}
