import get from "lodash/get";
import set from "lodash/set";
import { flatMapDeep } from "lodash";

export default class BulkSitesInputErrorService {
  constructor() {
    this.dataErrorRows = null;
  }

  resetErrors() {
    this.dataErrorRows = null;
  }

  updateErrors(errors) {
    this.dataErrorRows = errors.dataErrors.errorRowNumbers;
  }

  hasDataErrors() {
    return this.dataErrorRows && this.dataErrorRows.length > 0;
  }

  hasNoDataErrors() {
    return this.dataErrorRows && this.dataErrorRows.length === 0;
  }

  disableSubmit() {
    return !this.dataErrorRows || this.dataErrorRows.length > 0;
  }

  prepareDataErrors(dataErrors) {
    let errors = {};
    for (const [row, err] of Object.entries(dataErrors)) {
      let error = { ...err };

      if (get(error, "about_attribute.status._schema")) {
        set(error, "about_attribute.status", [
          "Status does not exist in the portal.",
        ]);
      }
      if (get(error, "brand._schema")) {
        set(error, "brand", ["Brand Flag does not belong to Brand Company."]);
      }
      if (get(error, "hotel_attribute.asset_class._schema")) {
        set(error, "hotel_attribute.asset_class", [
          "Asset Class does not exist in the portal.",
        ]);
      }
      if (get(error, "hotel_attribute.hotel_type._schema")) {
        set(error, "hotel_attribute.hotel_type", [
          "Hotel Type does not exist in the portal.",
        ]);
      }
      if (get(error, "hotel_attribute.laundry_location._schema")) {
        set(error, "hotel_attribute.laundry_location", [
          "Main Laundry Wash Location does not exist in the portal.",
        ]);
      }
      if (get(error, "hotel_attribute.market_segment._schema")) {
        set(error, "hotel_attribute.market_segment", [
          "Market Segment does not exist in the portal.",
        ]);
      }
      if (get(error, "location.country._schema")) {
        set(error, "location.country", [
          "Country does not exist in the portal.",
        ]);
      }
      if (get(error, "main_attribute.default_currency._schema")) {
        set(error, "main_attribute.default_currency", [
          "Currency of Utility Invoice does not exist in the portal.",
        ]);
      }
      if (get(error, "main_attribute.baileys_ecoregion_climate_zone._schema")) {
        set(error, "main_attribute.baileys_ecoregion_climate_zone", [
          "Climate Zone (Bailey's Ecoregion) does not exist in the portal.",
        ]);
      }
      if (get(error, "main_attribute.koppen_geiger_climate_zone._schema")) {
        set(error, "main_attribute.koppen_geiger_climate_zone", [
          "Climate Zone (Köppen–Geiger) does not exist in the portal.",
        ]);
      }
      if (get(error, "main_attribute.metro_area._schema")) {
        set(error, "main_attribute.metro_area", [
          "Metro Area does not exist in the portal.",
        ]);
      }
      if (get(error, "type._schema")) {
        set(error, "type", ["Site Type does not exist in the portal."]);
      }

      errors[row] = error;
      errors[row]["index"] = flatMapDeep(errors[row], (i) => Object.values(i));
    }
    return errors;
  }
}
