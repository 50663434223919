import { ARTICLE_CONTENT_TYPE } from "../../config/constants";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconFileTypePdf, IconVideo } from "@tabler/icons-react";
import React from "react";
import StatusLabel from "../../gvds-components/common/StatusLabel";

export const ContentTypeDisplay = ({ contentTypeName }) => {
  if (contentTypeName === ARTICLE_CONTENT_TYPE.VIDEO) {
    return <GVDSIcon Icon={IconVideo} className="content-type-icon-display" />;
  } else {
    return null;
  }
};

export const ContentTypeLabelDisplay = ({ articleTypeName }) => {
  if (articleTypeName) {
    if (articleTypeName === ARTICLE_CONTENT_TYPE.PDF) {
      return (
        <StatusLabel
          color={StatusLabel.Colors.blue}
          className="help-article-type-display"
        >
          <GVDSIcon
            Icon={IconFileTypePdf}
            className="help-article-type-display-icon"
          />
          {articleTypeName}
        </StatusLabel>
      );
    } else if (articleTypeName === ARTICLE_CONTENT_TYPE.VIDEO) {
      return (
        <StatusLabel
          color={StatusLabel.Colors.yellow}
          className="help-article-type-display"
        >
          <GVDSIcon
            Icon={IconVideo}
            className="help-article-type-display-icon"
          />
          {articleTypeName}
        </StatusLabel>
      );
    } else {
      return (
        <StatusLabel color={StatusLabel.Colors.green}>
          {articleTypeName}
        </StatusLabel>
      );
    }
  } else {
    return null;
  }
};
