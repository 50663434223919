import React, { useContext, useEffect, useState } from "react";

import FloatingAssistantMenu from "./FloatingAssistantMenu";
import GuidedTourRunner from "./GuidedTour/GuidedTourRunner";
import HelpArticleService from "../../services/HelpArticleService";
import { withPrereqReady } from "../HOC/withPrereqReady";
import FloatingAssistantContext from "./FloatingAssistantContext";
import GuidedTourContext from "./GuidedTour/GuidedTourContext";

const FloatingAssistant = () => {
  const floatingAssistantContext = useContext(FloatingAssistantContext);
  const guidedTourContext = useContext(GuidedTourContext);

  const [helpArticlesByPath, setHelpArticlesByPath] = useState({});

  useEffect(() => {
    const loadHelpArticles = async () => {
      const helpArticles = await HelpArticleService.getAllHelpArticles();

      const processedHelpArticlesByPath = helpArticles.reduce(
        (helpArticlesByPath, currentHelpArticle) => {
          if (
            currentHelpArticle.isEnglishArticle() &&
            !currentHelpArticle.isHelpfulTips()
          ) {
            currentHelpArticle.relevantPortalModules.forEach((module) => {
              const path = module.url;

              if (!helpArticlesByPath[path]) {
                helpArticlesByPath[path] = [currentHelpArticle];
              } else {
                helpArticlesByPath[path].push(currentHelpArticle);
              }
            });
          }

          return helpArticlesByPath;
        },
        {}
      );

      setHelpArticlesByPath(processedHelpArticlesByPath);
    };

    loadHelpArticles();
  }, []);

  return (
    <>
      {floatingAssistantContext.isOpen && (
        <FloatingAssistantMenu
          onClose={floatingAssistantContext.close}
          startGuidedTour={guidedTourContext.startGuidedTour}
          helpArticlesByPath={helpArticlesByPath}
        />
      )}
      <GuidedTourRunner
        isRunning={guidedTourContext.isRunning}
        stopRunning={guidedTourContext.stopGuidedTour}
      />
    </>
  );
};

export default withPrereqReady(FloatingAssistant);
