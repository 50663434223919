import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext, {
  getFacilities,
} from "../../../context/UserInventoryContext";
import { DateTimeUtils, UtilsService } from "../../../services/UtilsService";
import EnvironmentalSubtopicService from "../../../services/EnvironmentalSubtopicService";
import uniq from "lodash/uniq";
import EnvironmentalService from "../../../services/EnvironmentalService";
import LoadingSpinner from "../../common/LoadingSpinner";
import Accordion from "react-bootstrap/Accordion";
import sortBy from "lodash/sortBy";
import EnvironmentalMeterDialog from "./EnvironmentalMeterDialog";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSAccordionHeader from "../../../gvds-components/Accordion/GVDSAccordionHeader";
import EnvironmentalTypeMetersDisplay from "./MeterSetup/EnvironmentalTypeMetersDisplay";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import InputAlertPreferencesModal from "../InputAlertPreferencesModal";
import { useTranslation } from "react-i18next";

const EnvironmentalMeterSetup = ({ metersDataSet, reloadMeters }) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [facilities, setFacilities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allSubtopicOptions, setAllSubtopicOptions] = useState([]);
  const subtopics = useMemo(() => {
    return uniq(allSubtopicOptions.map((d) => d.name)).sort(
      EnvironmentalSubtopicService.subtopicsSortFn
    );
  }, [allSubtopicOptions]);
  const [activeKey, setActiveKey] = useState(
    subtopics.length > 0 ? subtopics[0] : null
  );
  const [expandedTypes, setExpandedTypes] = useState([]);
  const [environmentalSubtopic, setEnvironmentalSubtopic] = useState("");
  const [environmentalType, setEnvironmentalType] = useState("");

  const [currentMeterDetails, setCurrentMeterDetails] = useState(null);

  const [unitByType, setUnitsByType] = useState([]);
  const [showPromptInputMeter, setShowPromptInputMeterModal] = useState(false);
  const [showAlertPreferencesModal, setShowAlertPreferencesModal] =
    useState(false);

  const [showDeleteMeterDialog, setShowDeleteMeterDialog] = useState(false);
  const [showDeleteMeterWithRecordsModal, setShowDeleteMeterWithRecordsModal] =
    useState(false);
  const [
    marketPurchaseTrackersWithOneMeter,
    setMarketPurchaseTrackersWithOneMeter,
  ] = useState([]);

  const toggleExpandedType = (id) => {
    setExpandedTypes(UtilsService.toggleItem(expandedTypes, id));
  };

  const handleClosePromptAddMeter = () => {
    setCurrentMeterDetails(null);
    setShowPromptInputMeterModal(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (selectedInventory) {
      setFacilities(getFacilities(userInventory.selectedTreeNode.get));
      EnvironmentalSubtopicService.getAllInputSubtopics()
        .then((data) => {
          setAllSubtopicOptions(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load environmental data configuration.</span>
          );
        });
    }
  }, [userInventory.selectedInventory.get]);

  const onEnvironmentalTypeToggle = (isTrackingMeter, typeId, subtopicId) => {
    if (isTrackingMeter) {
      toastContext.addFailToast(
        <span>To stop tracking a type, delete each meter under it.</span>
      );
    } else {
      addMeterDialog(typeId, subtopicId);
    }
  };

  const addMeterDialog = (typeId, subtopicID) => {
    const environmentalSubtopic = allSubtopicOptions.find(
      (subtopic) => subtopic.id === subtopicID
    );
    const envType = environmentalSubtopic.environmental_types.find(
      (env) => env.id === typeId
    );

    setCurrentMeterDetails(null);
    setUnitsByType(envType.units);
    setEnvironmentalType(typeId);
    setEnvironmentalSubtopic(subtopicID);
    setShowPromptInputMeterModal(true);
  };

  const editMeterDialog = (typeId, subtopicID, meter, facilityId) => {
    setCurrentMeterDetails({
      facilityId: facilityId,
      meterName: meter.name,
      monitoringOnly: meter.for_monitoring_only,
      unitId: meter.default_unit.id,
      frequency: meter.frequency,
      currencyId: meter.default_currency.id,
      description: meter.description,
      meterId: meter.id,
    });
    setShowPromptInputMeterModal(true);

    const subtopic = allSubtopicOptions.find(
      (subtopic) => subtopic.id === subtopicID
    );

    const envType = subtopic.environmental_types.find(
      (env) => env.id === typeId
    );
    setUnitsByType(envType.units);
  };

  const displayAlertPreferencesDialog = (meter, facilityId) => {
    setCurrentMeterDetails({
      meterId: meter.id,
      dismissAlertsDate: meter.dismiss_alerts_date
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            meter.dismiss_alerts_date
          )
        : null,
      enableDataGapsAlert: meter.enable_data_gaps_alert,
      enableUnusualHighLowAlert: meter.enable_unusually_high_low_alert,
      effectivePeriodStartDate: meter.start_date
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(meter.start_date)
        : null,
      effectivePeriodEndDate: meter.end_date
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(meter.end_date)
        : null,
      facilityId: facilityId,
    });
    setShowAlertPreferencesModal(true);
  };

  const onAlertPreferencesUpdate = () => {
    toastContext.addSuccessToast(
      <span>Alert preferences updated successfully.</span>
    );
    setShowAlertPreferencesModal(false);
    reloadMeters();
  };

  const isEnvironmentalTypeTracked = (
    subtopicName,
    metersDataSet,
    environmentTypeId
  ) => {
    const newMetersDataSet = metersDataSet
      .filter(
        (meterDataset) =>
          meterDataset.environmental_subtopic.name === subtopicName
      )
      .map(
        (meterDataset) =>
          meterDataset.meters.filter(
            (envType) => envType.environmental_type.id === environmentTypeId
          ).length
      );
    return newMetersDataSet.reduce((a, b) => a + b, 0) > 0;
  };

  const deleteMeterHandler = (meterData) => {
    EnvironmentalService.getMeterStatus(
      meterData.meterId,
      selectedInventory.id,
      selectedInventory.type
    )
      .then((data) => {
        if (data["has_records"]) {
          setShowDeleteMeterWithRecordsModal(true);
        } else if (data["market_offset_trackers_with_only_meter"].length > 0) {
          setMarketPurchaseTrackersWithOneMeter(
            data["market_offset_trackers_with_only_meter"]
          );
        } else {
          setShowDeleteMeterDialog(true);
        }
        setShowPromptInputMeterModal(false);
      })
      .catch(() => {});
  };

  const deleteMeter = () => {
    EnvironmentalService.deleteMeter(currentMeterDetails)
      .then(() => {
        toastContext.addSuccessToast(<span>Meter removed successfully</span>);
        setShowDeleteMeterDialog(false);
        reloadMeters();
        toggleExpandedType(environmentalType);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to remove meter.</span>);
      });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {subtopics.map((subtopicName) => {
        const subtopicOptions = allSubtopicOptions.find(
          (d) => d.name === subtopicName
        );
        const envTypesSortedByUsageThenName = sortBy(
          subtopicOptions.environmental_types,
          [
            (envType) => {
              return !isEnvironmentalTypeTracked(
                subtopicName,
                metersDataSet,
                envType.id
              );
            },
            "name",
          ]
        );

        return (
          <Accordion
            key={subtopicName}
            defaultActiveKey={activeKey}
            activeKey={activeKey === subtopicName ? activeKey : null}
          >
            <Accordion.Item eventKey={subtopicName}>
              <GVDSAccordionHeader
                isExpanded={activeKey === subtopicName}
                title={subtopicName}
                onClick={() =>
                  activeKey === subtopicName
                    ? setActiveKey(null)
                    : setActiveKey(subtopicName)
                }
              />
              <Accordion.Body>
                <GVDSTableDisplay>
                  <thead>
                    <tr>
                      <th className="environmental-type-table__tracking">
                        {t(
                          "data-management.environmental.setup.table-header.tracking"
                        )}
                      </th>
                      <th className="gvds-table-header__expandable" />
                      <th className="environmental-type-table__type">
                        {t("data-management.environmental.shared.type")}
                      </th>
                      <th className="environmental-type-table__meter_count">
                        {t(
                          "data-management.environmental.setup.table-header.meter-count"
                        )}
                      </th>
                      <th className="environmental-type-table__definition">
                        {t("shared.definition")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {envTypesSortedByUsageThenName.map((environmentType) => {
                      const isEnvTypeTracked = isEnvironmentalTypeTracked(
                        subtopicName,
                        metersDataSet,
                        environmentType.id
                      );
                      return (
                        <EnvironmentalTypeMetersDisplay
                          key={environmentType.id}
                          subtopicName={subtopicName}
                          metersDataSet={metersDataSet}
                          subtopicOptions={subtopicOptions}
                          isEnvTypeTracked={isEnvTypeTracked}
                          environmentType={environmentType}
                          expandedTypes={expandedTypes}
                          toggleExpandedType={toggleExpandedType}
                          onEnvironmentalTypeToggle={onEnvironmentalTypeToggle}
                          addMeterDialog={addMeterDialog}
                          editMeterDialog={editMeterDialog}
                          displayAlertPreferencesDialog={
                            displayAlertPreferencesDialog
                          }
                        />
                      );
                    })}
                  </tbody>
                </GVDSTableDisplay>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}

      <EnvironmentalMeterDialog
        show={showPromptInputMeter}
        close={handleClosePromptAddMeter}
        onUpdateSuccessful={() => {
          reloadMeters();
          toggleExpandedType(environmentalType);
        }}
        currentMeterDetails={currentMeterDetails}
        environmentalSubtopic={environmentalSubtopic}
        environmentalType={environmentalType}
        facilities={facilities}
        unitByType={unitByType}
        deleteMeterHandler={deleteMeterHandler}
      />

      <InputAlertPreferencesModal
        show={showAlertPreferencesModal}
        setShow={setShowAlertPreferencesModal}
        facilityId={currentMeterDetails?.facilityId}
        meterId={currentMeterDetails?.meterId}
        oldDismissAlertDate={currentMeterDetails?.dismissAlertsDate}
        oldEnableDataGapsAlert={currentMeterDetails?.enableDataGapsAlert}
        oldEnableAbnormalUsageCost={
          currentMeterDetails?.enableUnusualHighLowAlert
        }
        oldEffectivePeriodStartDate={
          currentMeterDetails?.effectivePeriodStartDate
        }
        oldEffectivePeriodEndDate={currentMeterDetails?.effectivePeriodEndDate}
        updateAlertPreferences={EnvironmentalService.updateAlertPreferences}
        onAlertPreferencesUpdate={onAlertPreferencesUpdate}
      />

      <GVDSModal
        title={t(
          "data-management.environmental.setup.delete-meter.modal-title-unable"
        )}
        size={GVDSModal.Size.small}
        show={showDeleteMeterWithRecordsModal}
        onHide={() => setShowDeleteMeterWithRecordsModal(false)}
      >
        <GVDSModal.Body>
          <div>
            {t(
              "data-management.environmental.setup.delete-meter.unable-has-data1"
            )}
          </div>
          <br />
          <div>
            {t(
              "data-management.environmental.setup.delete-meter.unable-has-data2"
            )}
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => setShowDeleteMeterWithRecordsModal(false)}
            text={t("shared-modal.footer.okay")}
          />
        </GVDSModal.Footer>
      </GVDSModal>

      {marketPurchaseTrackersWithOneMeter.length > 0 && (
        <GVDSModal
          title={t(
            "data-management.environmental.setup.delete-meter.modal-title-unable"
          )}
          size={GVDSModal.Size.small}
          show
          onHide={() => setMarketPurchaseTrackersWithOneMeter([])}
        >
          <GVDSModal.Body>
            <div>
              {t(
                "data-management.environmental.setup.delete-meter.unable-has-eac1"
              )}{" "}
              {marketPurchaseTrackersWithOneMeter.join(", ")}.
            </div>
            <br />
            <div>
              {t(
                "data-management.environmental.setup.delete-meter.unable-has-eac2"
              )}
              <ul>
                <li>
                  {t(
                    "data-management.environmental.setup.delete-meter.unable-has-eac-action1"
                  )}
                </li>
                <li>
                  {t(
                    "data-management.environmental.setup.delete-meter.unable-has-eac-action2"
                  )}
                </li>
              </ul>
            </div>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={() => setMarketPurchaseTrackersWithOneMeter([])}
              text={t("shared-modal.footer.okay")}
            />
          </GVDSModal.Footer>
        </GVDSModal>
      )}

      <GVDSModal
        title={t("data-management.environmental.setup.delete-meter.modal-title")}
        size={GVDSModal.Size.small}
        show={showDeleteMeterDialog}
        onHide={() => setShowDeleteMeterDialog(false)}
      >
        <GVDSModal.Body>
          <div>
            {t(
              "data-management.environmental.setup.delete-meter.warning-permanent"
            )}
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => setShowDeleteMeterDialog(false)}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={() => deleteMeter()}
            text={t("shared.delete")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default EnvironmentalMeterSetup;
