import React, { useContext, useState } from "react";
import ViewAllTask from "../../TaskManagement/ViewAllTask";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import { FEATURES } from "../../../config/constants";
import UserInventoryContext from "../../../context/UserInventoryContext";
import UserProfileContext from "../../../context/UserProfileContext";
import { FeatureLockWidgetContent } from "./BaseWidgetEmptyContent";
import BaseWidgetHeadingSubheader from "./BaseWidgetHeadingSubheader";
import {useTranslation} from "react-i18next";

const TaskManagementWidget = () => {
  const {t} = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();
  const [taskCount, setTaskCount] = useState(0);

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.TASK_MANAGEMENT],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const hasFeatureTaskManagement =
    subscriptionFeatureStatus === SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const content = hasFeatureTaskManagement ? (
    <ViewAllTask isArchivedTasks={false} setTaskCount={setTaskCount} />
  ) : (
    <FeatureLockWidgetContent
      subscriptionFeatureStatus={subscriptionFeatureStatus}
    />
  );

  return (
    <div className="base-widget__container widget-task-management__container">
      <div className="base-widget__heading">
        <div>
          {t("landing-page.widget-task-mgmt.title")} <BaseWidgetHeadingSubheader text={`(${taskCount})`} />
        </div>
      </div>
      {content}
    </div>
  );
};

export default TaskManagementWidget;
