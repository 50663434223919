import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import {Link, useLocation} from "react-router-dom";
import { getQueryHandler } from "../../common/QueryHandler";
import BillingPaymentService from "../../../services/Public/BillingPaymentService";
import greenviewPortalLogo from "../../../assets/images/new-greenview-logo.svg";
import SupportContactLink from "../../common/SupportContactLink";
import {HOME} from "../../../config/ROUTES_NAME";

const SUBSCRIPTION_TYPE = "type";
const SUBSCRIPTION_ID = "id";
const HASH = "h";

const BillingPayment = () => {
  const location = useLocation();
  const [errorState, setErrorState] = useState(null);

  useEffect(() => {
    const subscriptionType = getQueryHandler(location).get(SUBSCRIPTION_TYPE);
    const subscriptionId = getQueryHandler(location).get(SUBSCRIPTION_ID);
    const hash = getQueryHandler(location).get(HASH);

    BillingPaymentService.getPaymentUrl(subscriptionType, subscriptionId, hash)
      .then((paymentUrl) => {
        window.location.replace(paymentUrl);
      })
      .catch((e) => {
        const errorCode = e?.response?.data?.message?.code;

        if (errorCode) {
          setErrorState(errorCode)
        } else {
          setErrorState(true);
        }
      });
  }, []);

  let content = (
    <>
      <h2>Redirecting you to payment</h2>
      <div>
        Please do not close or refresh this page. It may take up to 60 seconds.
        We appreciate your patience.
      </div>
      <div className="mt-3">
        <LoadingSpinner height={24} width={4} />
      </div>
    </>
  );

  if (errorState) {
    if (errorState === 'paid') {
      content = (
        <>
          <h2>Subscription has been paid</h2>
          <div>
            <Link to={HOME}>Click here to access Greenview Portal.</Link>
          </div>
        </>
      );
    } else {
      content = (
        <>
          <h2>Something went wrong</h2>
          <div>
            We can't find your subscription. Please contact <SupportContactLink />{" "}
            to get help.
          </div>
        </>
      );
    }
  }

  return (
    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
      <div className="mb-4">
        <img src={greenviewPortalLogo} alt="Greenview Portal" />
      </div>
      {content}
    </div>
  );
};

export default BillingPayment;
