import React from "react";
import TemplateGlobalPage from "../../gvds-components/Layout/TemplateGlobalPage";
import PageHeader from "../../gvds-components/Layout/PageHeader";

const PrivacyPolicy = () => {
  return (
    <TemplateGlobalPage>
      <div className="privacy-policy">
        <PageHeader>
          <PageHeader.Title>
            <h1>Privacy Policy</h1>
          </PageHeader.Title>
        </PageHeader>
        <p>
          This Privacy Policy sets out the basis on which we may collect, use,
          disclose and/or otherwise process Personal Data in connection with
          and/or for the purposes of our operations. By accessing and using the
          Greenview Platform, you agree to be legally bound by and acknowledge
          that Personal Data is processed as described in this Privacy Policy
          (as amended from time to time).
        </p>
        <p>
          This Privacy Policy forms part of, and shall be read and construed as
          one document with, the Terms of Use. Capitalised terms used in this
          Privacy Policy have the meaning ascribed to them in the Terms of Use,
          unless otherwise defined herein. In the event of any inconsistency
          between this Privacy Policy and the Terms of Use, the latter shall
          prevail to the fullest extent permissible by Applicable Law.
        </p>
        <p>
          This Privacy Policy supplements but does not supersede or replace any
          previous consent which you may have provided to us, nor does it affect
          any rights that we may have under Applicable Law, in connection with
          the collection, use, disclosure and/or processing of Personal Data.
        </p>
        <p>
          <strong>1. DEFINITIONS AND INTERPRETATION </strong>
        </p>
        <p>
          1.1 In this Privacy Policy, unless the context otherwise requires:
        </p>
        <p>
          <strong>“Applicable Law”</strong> means all applicable statutes,
          enactments, acts of legislature of parliament, laws, ordinances,
          rules, by-laws, regulations, notifications, guidelines, policies,
          directions, directives and orders of any governmental or regulatory
          authority, tribunal, board, court or a recognised stock exchange,
          including the PDPA and where relevant, the GDPR;
        </p>
        <p>
          <strong>“Data Protection Officer”</strong> means such person(s)
          designated by us from time to time to be responsible for ensuring that
          we comply with the PDPA and where relevant, the GDPR;
        </p>
        <p>
          <strong>"GDPR"</strong> means the General Data Protection Regulation
          (Regulation EU 2016/679);
        </p>
        <p>
          <strong>“Individual”</strong> means a natural person, whether living
          or deceased;
        </p>
        <p>
          <strong>“PDPA”</strong> means the Personal Data Protection Act (No. 26
          of 2012) of Singapore (as amended from time to time);
        </p>
        <p>
          <strong>“Personal Data”</strong> means any data about an Individual
          whose identity can be known or identified: (a) from such data; or (b)
          from such data and other information which we have or are likely to
          have access to, including data already in our possession or which we
          may collect in the future;
        </p>
        <p>
          <strong>“Privacy Policy”</strong> means this privacy policy (as
          amended from time to time) accessible{" "}
          <a href="https://greenview.sg/" target="_blank">
            here
          </a>
          ;
        </p>
        <p>
          <strong>“Terms of Use”</strong> means our terms of use (as amended
          from time to time) accessible{" "}
          <a href="https://greenview.sg/" target="_blank">
            here
          </a>
          ;
        </p>
        <p>
          <strong>“Third Party Personal Data”</strong> has the meaning ascribed
          to it in Clause 5.5;
        </p>
        <p>
          <strong>“we”, “us”, “our”</strong> means Greenview Hospitality Pte.
          Ltd. and/or Greenview LLC; and
        </p>
        <p>
          <strong>“you”, “your”</strong> means any User, including its duly
          authorised representative(s).
        </p>
        <p>
          1.2 Unless the context otherwise requires, references to Clauses are
          to the clauses of this Privacy Policy.
        </p>
        <p>
          <strong>2. COLLECTION OF PERSONAL DATA </strong>
        </p>
        <p>
          2.1 In general, we collect Personal Data in several ways, including
          but not limited to the following:
        </p>
        <p>
          (a) when you register for a User Account via the Greenview Platform;
        </p>
        <p>(b) in the course of providing any Services to you;</p>
        <p>
          (c) when you contact us for enquiries or feedback, either via emails,
          phone calls or other forms of written and verbal communication;
        </p>
        <p>
          (d) when you are visiting our premises or attending our events or
          programmes; and
        </p>
        <p>
          (e) through your access, use or navigation of the Greenview Platform
          and the use of cookies.
        </p>
        <p>
          2.2 Depending on the nature of your interaction with us, Personal Data
          which we may collect from you include:
        </p>
        <p>
          (a) personal particulars such as name, gender, date of birth,
          occupation, photographs, marital status, relatives, national service
          standing and contact particulars, including telephone number(s),
          residential / mailing address(es) and email address(es);
        </p>
        <p>
          (b) to such extent permitted by Applicable Law, details of
          identification documents (such as national identity cards, passport
          numbers, and birth certificate numbers, whether partially or in full),
          and applicable visa or permits (such as employment pass, work permit,
          permanent residency status) and other documents such as marriage
          certificates;
        </p>
        <p>(c) details of credit / debit cards or bank accounts;</p>
        <p>
          (d) details of personal preferences or other special requests or
          special conditions applicable to an Individual;
        </p>
        <p>
          (e) network usage data and other data and information, submitted by
          you or gathered automatically by the Greenview Platform, including
          computer Internet Protocol (IP) address, web browser type and version,
          operating system, links visited and other activities conducted online
          or using the Greenview Platform; and
        </p>
        <p>
          (f) other information which you may provide to us from time to time,
          in the course of such interaction with us.
        </p>
        <p>
          <strong>
            3. PURPOSES OF COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA
          </strong>
        </p>
        <p>
          In general, we may collect, use and/or disclose Personal Data for any
          or all of the following purposes:
        </p>
        <p>
          (a) performing our obligations in the course of or in connection with
          the provision of the Services to you, and to improve the Services
          (particularly in respect of Personal Data under Clauses 2.2(a) and
          2.2(d));
        </p>
        <p>
          (b) enforcing any provision of our Terms of Use (particularly in
          respect of Personal Data under Clauses 2.2(a), 2.2(b) and 2.2(e));
        </p>
        <p>
          (c) verifying identities (particularly in respect of Personal Data
          under Clause 2.2(b));
        </p>
        <p>
          (d) responding to, handling and processing your enquiries, requests
          and/or feedback (particularly in respect of Personal Data under Clause
          2.2(a));
        </p>
        <p>
          (e) managing your relationship with us (particularly in respect of
          Personal Data under Clauses 2.2(a) and 2.2(d));
        </p>
        <p>
          (f) processing payment or credit transactions (particularly in respect
          of Personal Data under Clause 2.2(c));
        </p>
        <p>
          (g) sending you marketing information about any Services, including
          notifying you of our marketing events, initiatives and promotions
          (provided that you have not opted out to receive the same)
          (particularly in respect of Personal Data under Clause 2.2(a));
        </p>
        <p>
          (h) complying with any Applicable Law, or to assist in law enforcement
          and investigations conducted by any governmental and/or regulatory
          authority (particularly in respect of Personal Data under Clauses
          2.2(a) and 2.2(b));
        </p>
        <p>
          (i) record-keeping purposes, including but not limited to for purposes
          of accounting, auditing or obtaining financial or tax advice
          (particularly in respect of Personal Data under Clause 2.2(a));
        </p>
        <p>
          (j) legal purposes (including but not limited to obtaining legal
          advice and dispute resolution) or the recovery of bad debts or damages
          (particularly in respect of Personal Data under Clauses 2.2(a) and
          2.2(b));
        </p>
        <p>
          (k) any other purposes for which you have provided the information and
          in relation to which we have obtained your consent (particularly in
          respect of Personal Data under Clause 2.2(a)); and
        </p>
        <p>
          (l) any other purposes reasonably related and/or ancillary to the
          above.
        </p>
        <p>
          3.2 The purposes set out in Clause 3 may continue to apply even in
          situations where your relationship with us (for example, pursuant to a
          contract) has been terminated or altered in any way, for a reasonable
          period thereafter (including, where applicable, a period to enable us
          to enforce our rights under any contract with you).
        </p>
        <p>
          <strong>4. DISCLOSURE OF PERSONAL DATA</strong>
        </p>
        <p>
          4.1 In carrying out one or more of the purposes set out in Clause 3,
          we may need to disclose Personal Data to one or more of the following
          third parties:
        </p>
        <p>(a) our Affiliates;</p>
        <p>
          (b) our agents, contractors or third party service providers in
          connection with any services provided to us (including but not limited
          to website maintenance services, internet cloud services, courier
          services and payment processing services);
        </p>
        <p>
          (c) our personnel (including any person engaged under a contract of
          service with us, management personnel, permanent or temporary
          employees and volunteers engaged by us from time to time);
        </p>
        <p>
          (d) our professional advisors (including but not limited to auditors
          and lawyers);
        </p>
        <p>(e) our business partners;</p>
        <p>(f) our underwriters and insurers;</p>
        <p>
          (g) relevant governmental and/or regulatory authorities, statutory
          boards or law enforcement agencies to comply with any Applicable Law;
          and
        </p>
        <p>
          (h) any person to whom disclosure is permitted or required by any
          Applicable Law, enforceable governmental request or court order.
        </p>
        <p>
          4.2 We will use reasonable endeavours to ensure that the above third
          parties, whom Personal Data is being disclosed to, will provide a
          comparable standard of protection to Personal Data.
        </p>
        <p>
          <strong>5. CONSENT</strong>
        </p>
        <p>
          5.1 We will not collect, use or disclose Personal Data without your
          consent unless otherwise authorised under Applicable Law, including in
          the following circumstances:
        </p>
        <p>
          (a) collecting, using or disclosing Personal Data for our legitimate
          interests, business asset transactions, business improvement and
          commercial research and development; and/or
        </p>
        <p>
          (b) if our legitimate interests outweigh any adverse effect on you. In
          that case, we may provide you with information as to the balancing
          test that we have carried out. If interested, please contact us
          through the contact details included in Clause 14.
        </p>
        <p>
          5.2 We will in any case notify you of the collection, use or
          disclosure of Personal Data through this Privacy Policy or otherwise.
        </p>
        <p>
          5.3 To the fullest extent permitted by Applicable Law, you are deemed
          to have consented to us collecting, using or disclosing Personal Data
          where:
        </p>
        <p>
          (a) you voluntarily provide Personal Data to us for specified
          purposes, and where it is reasonable that you would voluntarily
          provide such Personal Data to us;
        </p>
        <p>(b) you access or use the Greenview Platform;</p>
        <p>
          (c) such collection, use or disclosure of Personal Data is reasonably
          necessary for the conclusion or performance of a contract between you
          and us; and
        </p>
        <p>
          (d) you tick the box to consent to this Privacy Policy on the
          Greenview Platform.
        </p>
        <p>
          5.4 Where consent or notification is required to be obtained or
          provided to you, we will take reasonable steps to highlight the
          purposes relevant to you, by appropriate means, at the time of
          collection of Personal Data from you, including the following:
        </p>
        <p>(a) via notifications on or through the Greenview Platform;</p>
        <p>(b) via email; and</p>
        <p>(c) in the course of any other written communications.</p>
        <p>
          5.5 If you provide us with Personal Data relating to any third party
          (e.g. information of an Individual customer or the spouse, children,
          parents or relatives of an Individual customer) (
          <strong>“Third Party Personal Data”</strong>) for any particular
          purpose, you warrant and represent to us that you have obtained the
          consent of the relevant third party to us collecting, using or
          disclosing such Personal Data for the relevant purpose(s) or that the
          processing of Third Party Personal Data is allowed on other grounds.
          By providing us with any Third Party Personal Data, you represent and
          warrant that you have ensured the accuracy of such Third Party
          Personal Data provided and have also obtained such third party’s
          consent for our collection, use or disclosure of such Third Party
          Personal Data. We may require you to provide written confirmation from
          such third party that he or she has consented to the use of his or her
          Personal Data. You also agree to fully indemnify and hold harmless
          each Indemnitee against any and all losses, costs, damages, claims,
          demands, actions, proceedings, liabilities and expenses whatsoever
          (including all legal fees on a full indemnity basis) suffered or
          incurred by such Indemnitee arising out of or in connection with your
          acts, omission, fault and/or negligence which result in our breach of
          any Applicable Law.
        </p>
        <p>
          5.6 In so far as we collect Personal Data from any third party(ies),
          we will take reasonable steps to inform the relevant third party(ies)
          of our purposes for collecting Personal Data and to verify that
          consent has been obtained by the relevant third party(ies) to such
          disclosure to us for the intended purpose.
        </p>
        <p>
          <strong>Withdrawal of Consent</strong>
        </p>
        <p>
          5.7 The consent that you provide for the collection, use and
          disclosure of Personal Data will remain valid until such time it is
          being withdrawn by you. You may withdraw consent and request us to
          stop collecting, using and/or disclosing Personal Data for any or all
          of the purposes listed above by submitting your request via email to
          our Data Protection Officer at the contact details provided in Clause
          14.
        </p>
        <p>
          5.8 Upon receipt of your written request to withdraw your consent, we
          may require reasonable time (depending on the complexity of the
          request and its impact on our relationship with you) for your request
          to be processed and for us to notify you of the consequences of us
          acceding to the same.
        </p>
        <p>
          5.9 Whilst we respect your decision to withdraw your consent, please
          note that depending on the nature and scope of your request, we may
          not be in a position to continue providing the Services to you and we
          shall, in such circumstances, notify you before completing the
          processing of your request. Should you decide to cancel your
          withdrawal of consent, please inform us in writing in the manner
          described in Clause 5.7.
        </p>
        <p>
          5.10 Please note that withdrawing your consent does not affect our
          right to continue to collect, use and/or disclose Personal Data where
          such collection, use and/or disclosure without consent is nevertheless
          permitted or required under Applicable Law.
        </p>
        <p>
          <strong>6. ACCESS TO AND CORRECTION OF PERSONAL DATA </strong>
        </p>
        <p>
          6.1 If you wish to make: (a) an access request for access to a copy of
          Personal Data which we obtained from you or information about the ways
          in which we use or disclose Personal Data; or (b) a correction request
          to correct or update any Personal Data which we obtained from you, you
          may submit your request via email to our Data Protection Officer at
          the contact details provided in Clause 14.
        </p>
        <p>
          6.2 The PDPA allows and we reserve the right to charge a reasonable
          fee for handling or processing an access request, where applicable. If
          so, we will inform you of the fee before processing your request.
          Before we accede to your request, we may also need to verify the
          identity or the relevant Individual by requesting his or her
          identification documents.
        </p>
        <p>6.3 We will respond to your request as set out in Clause 7.4.</p>
        <p>
          <strong>7. RIGHTS UNDER THE GDPR</strong>
        </p>
        <p>
          7.1 If you are located in the European Union while using our services,
          the GDPR is applicable to our processing activities with regard to
          your Personal Data. Under the GDPR, you have the following rights with
          regard to us processing your Personal Data in lieu of the rights as
          described in Clause 6:
        </p>
        <p>
          (a) <strong>Right to withdraw consent</strong>: In so far as our
          processing of your Personal Data is based on your consent (see above),
          you have the right to withdraw consent at any time.
        </p>
        <p>
          (b) <strong>Right of access</strong>: You have the right to request
          access to your Personal Data. This enables you to receive a copy of
          the Personal Data we hold about you (but not necessarily the documents
          themselves). We will then also provide you with further specifics of
          our processing of your Personal Data.
        </p>
        <p>
          (c) <strong>Right to rectification</strong>: You have the right to
          request rectification of the Personal Data that we hold about you.
          This enables you to have any incomplete or inaccurate data we hold
          about you corrected.
        </p>
        <p>
          (d) <strong>Right to erasure</strong>: You have the right to request
          erasure of your Personal Data. This enables you to ask us to delete or
          remove Personal Data where: (i) the Personal Data are no longer
          necessary, (ii) you have withdrawn your consent, (iii) you have
          objected to the processing activities, (iv) the Personal Data have
          been unlawfully processed, (v) the Personal Data have to be erased on
          the basis of a legal requirement, or (vi) where the Personal Data have
          been collected in relation to the offer of information society
          services. We do not have to honour your request to the extent that the
          processing is necessary: (i) for exercising the right of freedom of
          expression and information, (ii) for compliance with a legal
          obligation which requires processing, (iii) for reasons of public
          interest in the area of public health, (iv) for archiving purposes, or
          (v) for the establishment, exercise or defence of legal claims.
        </p>
        <p>
          (e) <strong>Right to object</strong>: You have the right to object to
          processing of your Personal Data where we are relying on legitimate
          interests as processing ground. Insofar as the processing of your
          Personal Data takes place for direct marketing purposes, we will
          always honour your request. For processing for other purposes, we will
          also cease and desist processing, unless we have compelling legitimate
          grounds for the processing which override your interests, rights and
          freedoms or that are related to the institution, exercise or
          substantiation of a legal claim.
        </p>
        <p>
          (f) <strong>Right to restriction</strong>: You have the right to
          request restriction of processing of your Personal Data in case: (i)
          the accuracy of the Personal Data is contested by you, during the
          period we verify your request, (ii) the processing is unlawful and
          restriction is requested by you instead of erasure, (iii) we no longer
          need the Personal Data but they are required by you for the
          establishment, exercise or defence of legal claims, or (iv) in case
          you have objected to processing, during the period we verify your
          request. If we have restricted the processing of your Personal Data,
          this means that we will only store them and no longer process them in
          any other way, unless: (i) with your consent, (ii) for the
          establishment, exercise or defence of legal claims, (iii) for the
          protection of the rights of another natural or legal person, (iv) or
          for reasons of important public interest.
        </p>
        <p>
          (g) <strong>Right to data portability</strong>: You have the right to
          request to transfer of your Personal Data to you or to a third party
          of your choice (right to data portability). We will provide to you, or
          such third, your Personal Data in a structured, commonly used,
          machine-readable format. Please note that this right only applies if
          it concerns processing that is carried out by us by automated means,
          and only if our processing ground for such processing is your consent
          or the performance of a contract to which you are a party.
        </p>
        <p>
          (h) <strong>Automated decision-making</strong>: You have the right not
          to be subject to a decision based solely on automated processing,
          which significantly impacts you (“which produces legal effects
          concerning you or similarly significantly affects you”). In this
          respect, please be informed that when processing your Personal Data,
          we do not make use of automated decision-making.
        </p>
        <p>
          (i) <strong>Right to complaint</strong>: In addition to the above
          mentioned rights you have the right to lodge a complaint with a
          supervisory authority, in particular in the European Union member
          state of your habitual residence, place of work or of an alleged
          infringement of the GDPR at all times. However, we would appreciate
          the chance to deal with your concerns before you approach the
          supervisory authority so please contact us beforehand.
        </p>
        <p>
          7.2 The exercise of the abovementioned rights is free of charge and
          can be carried out by phone or by e-mail via the contact details
          displayed in Clause 14. If requests are manifestly unfounded or
          excessive, in particular because of the repetitive character, we will
          either charge you a reasonable fee or refuse to comply with the
          request.
        </p>
        <p>
          7.3 Before we accede to your request, we may also need to verify the
          identity or the relevant Individual by requesting his or her
          identification documents.
        </p>
        <p>
          7.4 We will provide you with information about the follow-up to the
          request without undue delay and in principle within one (1) month of
          receipt of the request. Depending on the complexity of the request and
          on the number of requests, this period can be extended by another two
          (2) months. We will notify you of such an extension within one (1)
          month of receipt of the request. The GDPR may allow or require us to
          refuse your request. If we cannot comply with your request, we will
          inform you of the reasons why, subject to any legal or regulatory
          restrictions.
        </p>
        <p>
          <strong>8. PROTECTION OF PERSONAL DATA</strong>
        </p>
        <p>
          8.1 To safeguard Personal Data in our possession from unauthorised
          access, collection, use, disclosure, copying, modification, disposal
          or similar risks, we shall make reasonable security measures such as
          up-to-date antivirus protection, encryption and the use of privacy
          filters to secure all storage and transmission of Personal Data by us,
          and disclosing Personal Data both internally and to our authorised
          third party service providers and agents only on a need-to-know basis.
        </p>
        <p>
          8.2 You should be aware, however, that no method of transmission over
          the internet or method of electronic storage is completely secure.
          While security cannot be guaranteed, we strive to protect the security
          of Personal Data and are constantly reviewing and enhancing our data
          security measures.
        </p>
        <p>
          8.3 In principle, your Personal Data is stored in Singapore. Some of
          our recipients of your Personal Data are located in countries that may
          not, by law, provide the same level of data protection as you are used
          to under the PDPA, and where relevant, the GDPR. If this is the case,
          we will take reasonable steps to protect Personal Data to a standard
          acceptable to the protection accorded to Personal Data under the PDPA,
          and where relevant, the GDPR, by ensuring that the recipient is either
          in a jurisdiction which has comparable data protection laws to
          Singapore, or is contractually bound to protect Personal Data to a
          comparable standard.
        </p>
        <p>
          8.4 We will ensure that third parties who receive Personal Data from
          us protect such Personal Data in a manner consistent with this Privacy
          Policy and not use such Personal Data for any purposes other than
          those specified by us, by incorporating appropriate contractual terms
          in our written agreements with such third parties.
        </p>
        <p>
          8.5 We are not responsible in any way for the security and/or
          management of Personal Data shared by you with third party websites or
          links accessible via the Greenview Platform.
        </p>
        <p>
          <strong>9. ACCURACY OF PERSONAL DATA </strong>
        </p>
        <p>
          9.1 We generally rely on Personal Data provided by you. In order to
          ensure that Personal Data is current, complete and accurate, please
          update us if there are changes to Personal Data by informing our Data
          Protection Officer via email at the contact details provided in Clause
          14.
        </p>
        <p>
          9.2 We will take reasonable steps to verify the accuracy of Personal
          Data received at the point of collection, but you remain primarily
          responsible and liable to ensure that all Personal Data submitted to
          us is complete and accurate. Information provided voluntarily by you
          to us shall prima facie be deemed complete and accurate.
        </p>
        <p>
          9.3 We will also take reasonable steps to periodically verify Personal
          Data in our possession, taking into account the exigencies of our
          operations, but you are nonetheless required to notify us, from time
          to time, of any applicable changes to such Personal Data.
        </p>
        <p>
          9.4 We shall not be held liable for any inability on our part to
          provide the Services to you if you fail to ensure that Personal Data
          submitted to us is complete and accurate or fail to notify us of any
          relevant changes to such Personal Data. In this regard, you should
          ensure that Personal Data provided by you to us is at all times
          correct, accurate and complete and you agree to update us in a timely
          manner of all changes to such Personal Data.
        </p>
        <p>
          <strong>10. RETENTION OF PERSONAL DATA </strong>
        </p>
        <p>
          10.1 We may retain Personal Data for as long as it is necessary to
          fulfil the purpose(s) for which it was collected, or as required or
          permitted by Applicable Law, for example:
        </p>
        <p>
          (a) performing our obligations in the course of or in connection with
          the provision of the Services to you, and to improve the Services: one
          (1) year after the end of the customer relationship;
        </p>
        <p>
          (b) enforcing any provision of our Terms of Use: in case of a dispute,
          ten (10) years after finalisation of the specific matter; otherwise,
          one (1) year after the end of the customer relationship;
        </p>
        <p>
          (c) verifying identities: the entire duration of the customer
          relationship;
        </p>
        <p>
          (d) responding to, handling and processing your enquiries, requests
          and/or feedback: six (6) months after the last correspondence
          regarding the specific matter;
        </p>
        <p>
          (e) managing your relationship with us: one (1) year after the end of
          the customer relationship;
        </p>
        <p>
          (f) processing payment or credit transactions: as permitted by
          Applicable Law;
        </p>
        <p>
          (g) sending you marketing information about our services, including
          notifying you of our marketing events, initiatives and promotions: one
          (1) month after you have objected to using your Personal Data in this
          regard or one (1) month after the end of the customer relationship (if
          you have never objected during that time);
        </p>
        <p>
          (h) complying with any Applicable Law, or to assist in law enforcement
          and investigations conducted by any governmental and/or regulatory
          authority: as permitted by Applicable Law;
        </p>
        <p>
          (i) record-keeping purposes, including but not limited to for purposes
          of accounting, auditing or obtaining financial or tax advice: as
          permitted by Applicable Law; and
        </p>
        <p>
          (j) legal purposes (including but not limited to obtaining legal
          advice and dispute resolution) or the recovery of bad debts or
          damages: ten (10) years after finalisation of the specific matter.
        </p>
        <p>
          10.2 We will cease to retain Personal Data, or remove the means by
          which Personal Data can be associated with you, as soon as it is
          reasonable to assume that such retention no longer serves the
          purpose(s) for which such Personal Data was collected, and is no
          longer necessary for legal or business purposes.
        </p>
        <p>
          <strong>11. COOKIES AND WEB BROWSER INFORMATION</strong>
        </p>
        <p>
          11.1 When you visit the Greenview Platform, we send one or more
          cookies to your computer and other devices. The information obtained
          from such cookies may be used by us for enabling certain features and
          functions on the Greenview Platform, storing user preferences,
          improving the efficiency of the Greenview Platform and administering
          the Services to you and to enable us or third-party advertising
          partners to display targeted advertisements which may be of interest
          to you.
        </p>
        <p>
          11.2 We also collect information which is sent to us by your web
          browser. When you browse the Greenview Platform, we automatically
          receive your computer’s internet protocol (IP) address which provides
          us with information that helps us learn about your web browser and
          operating system, such as the name of your operating system and the
          name and version of your web browser.
        </p>
        <p>
          11.3 Other information we may collect include the address of the web
          page you were visiting when you accessed the Greenview Platform and
          the date and time of your visit. The type of information we collect
          depends on the settings on your web browser.
        </p>
        <p>
          11.4 By accessing and ticking the box to consent to this Privacy
          Policy on the Greenview Platform, you are deemed to have consented to
          our use of such cookies. If you wish to withdraw your consent at any
          time, please configure your internet browser to erase all cookies from
          your computer or block or disable the relevant cookies.
        </p>
        <p>
          <strong>12. UPDATES TO PRIVACY POLICY</strong>
        </p>
        <p>
          We reserve the right to update this Privacy Policy in our sole and
          absolute discretion from time to time to ensure that it is consistent
          with Applicable Law and/or our business requirements. Hence, you are
          advised to check this Privacy Policy regularly for any updates.
          Subject to your rights under Applicable Law, the prevailing terms of
          this Privacy Policy from time to time shall apply. By continuing to
          access and/or use the Greenview Platform and/or the Services or
          otherwise continuing your relationship with us after any updates have
          been introduced and published, you shall be deemed to have accepted
          this Privacy Policy as updated.
        </p>
        <p>
          <strong>13. GOVERNING LAW AND JURISDICTION</strong>
        </p>
        <p>
          13.1 This Privacy Policy shall be governed by and construed in
          accordance with the laws of Singapore.
        </p>
        <p>
          13.2 The courts of Singapore shall have non-exclusive jurisdiction to
          settle any dispute or claim that arises out of or in connection with
          this Privacy Policy or its subject matter (including non-contractual
          disputes or claims).
        </p>
        <p>
          <strong>14. CONTACT INFORMATION</strong>
        </p>
        <p>
          14.1 If you have any enquiries or feedback on our Personal Data
          protection policies and procedures, or if you wish to make any request
          relating to Personal Data, you may contact our Data Protection Officer
          via email, the details of which are provided below:
          <br />
          Email address: grace@greenview.sg
        </p>
        <p>
          14.2 We may require reasonable time for your request to be processed,
          depending on the complexity of your request.
        </p>
        <p>
          14.3 For withdrawal of marketing consent, you may also unsubscribe
          from receiving direct marketing emails via the Greenview Platform.
        </p>
        <p>Version 1.0 effective on July 19, 2021</p>
      </div>
    </TemplateGlobalPage>
  );
};

export default PrivacyPolicy;
