import axios from "axios";
import { API_URL } from "../config/api-config";

export default class FacilityService {
  static createNewFacility = async (siteId, name, type) => {
    try {
      const data = {
        name: name,
        type: type,
        siteId: siteId,
      };
      const response = await axios.post(`${API_URL}/api/v1/facilities`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getFacility = async (facilityId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/facilities/${facilityId}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  static editFacility = async (
    resource_type,
    resource_id,
    facilityId,
    editedFields
  ) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.patch(
        `${API_URL}/api/v1/facilities/${facilityId}?${new URLSearchParams(
          params
        ).toString()}`,
        editedFields
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteFacility = async (resource_type, resource_id, facilityId) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.delete(
        `${API_URL}/api/v1/facilities/${facilityId}?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getUsers = async (facilityId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/facilities/" + facilityId + "/users"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}
