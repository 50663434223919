import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { getRedirectURLWithCurrentParam } from "../../../components/common/QueryHandler";
import { Link, matchPath, useLocation } from "react-router-dom";
import {
  ADMIN,
  ADMIN_BILLING,
  ADMIN_FACILITIES,
  ADMIN_SITE_DETAILS,
  ADMIN_TAGS,
  ADMIN_TEAM,
  BEST_PRACTICES,
  BEST_PRACTICES_ASSESS,
  BEST_PRACTICES_GUIDANCE,
  CERTIFICATIONS,
  DASHBOARDS,
  DATA,
  ENVIRONMENTAL_DATA,
  getViewCertificationHomepagePath,
  GOALS_AND_TARGETS,
  OPERATIONAL_DATA,
  OVERVIEW_DATA,
  PEOPLE_DATA,
  POLICY_TRACKER,
  REPORT_BEST_PRACTICE_REPORT,
  REPORT_HCMI_METRIC,
  REPORT_INITIATIVES_REPORT,
  REPORT_PERFORMANCE_REPORT,
  REPORT_RISK_ASSESSMENT_REPORT,
  REPORT_STANDARD_REPORT,
  SURVEYS,
  VIEW_ALL_INITIATIVES,
} from "../../../config/ROUTES_NAME";
import UserInventoryContext from "../../../context/UserInventoryContext";
import GVDSIcon from "../../Icons/GVDSIcon";
import {
  IconAward,
  IconBook,
  IconChartHistogram,
  IconChevronDown,
  IconChevronRight,
  IconClipboardCheck,
  IconFileAnalytics,
  IconSettings,
  IconTable,
  IconTargetArrow,
} from "@tabler/icons-react";
import GVDSIconSlim from "../../Icons/GVDSIconSlim";
import Spacer from "../../Layout/Spacer";
import { smoothScrollBehaviour } from "../../../config/constants";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import { CertificationService } from "../../../services/CertificationService";

class L1Nav {
  constructor(name, icon, route, l2Navs = []) {
    this.name = name;
    this.icon = icon;
    this.route = route;
    this.l2Navs = l2Navs;
  }

  hasChildNavs() {
    return !!this.l2Navs && this.l2Navs.length > 0;
  }
}

class L2Nav {
  constructor(name, route) {
    this.name = name;
    this.route = route;
  }
}

const getNavItems = (t) => [
  new L1Nav(
    t("side-nav.l1.dashboards"),
    <GVDSIcon Icon={IconChartHistogram} />,
    DASHBOARDS
  ),
  new L1Nav(
    t("side-nav.l1.data-management"),
    <GVDSIcon Icon={IconTable} />,
    DATA,
    [
      new L2Nav(t("side-nav.l2.data-management.overview"), OVERVIEW_DATA),
      new L2Nav(
        t("side-nav.l2.data-management.environmental"),
        ENVIRONMENTAL_DATA
      ),
      new L2Nav(t("side-nav.l2.data-management.operational"), OPERATIONAL_DATA),
      new L2Nav(t("side-nav.l2.data-management.people"), PEOPLE_DATA),
      new L2Nav(
        t("side-nav.l2.data-management.initiatives"),
        VIEW_ALL_INITIATIVES
      ),
    ]
  ),
  new L1Nav(
    t("side-nav.l1.reports"),
    <GVDSIcon Icon={IconFileAnalytics} />,
    "/report",
    [
      new L2Nav(
        t("side-nav.l2.reports.performance"),
        REPORT_PERFORMANCE_REPORT
      ),
      new L2Nav(t("side-nav.l2.reports.standard"), REPORT_STANDARD_REPORT),
      new L2Nav(t("side-nav.l2.reports.hcmi"), REPORT_HCMI_METRIC),
      new L2Nav(
        t("side-nav.l2.reports.best-practice"),
        REPORT_BEST_PRACTICE_REPORT
      ),
      new L2Nav(
        t("side-nav.l2.reports.risk-assessment"),
        REPORT_RISK_ASSESSMENT_REPORT
      ),
      new L2Nav(
        t("side-nav.l2.reports.initiatives"),
        REPORT_INITIATIVES_REPORT
      ),
    ]
  ),
  new L1Nav(
    t("side-nav.l1.goals-n-targets"),
    <GVDSIcon Icon={IconTargetArrow} />,
    GOALS_AND_TARGETS,
    []
  ),
  new L1Nav(
    t("side-nav.l1.certifications"),
    <GVDSIcon Icon={IconAward} />,
    CERTIFICATIONS,
    []
  ),
  new L1Nav(
    t("side-nav.l1.resources"),
    <GVDSIcon Icon={IconBook} />,
    BEST_PRACTICES,
    [
      new L2Nav(
        t("side-nav.l2.resources.best-practices"),
        BEST_PRACTICES_ASSESS
      ),
      new L2Nav(
        t("side-nav.l2.resources.guidance-library"),
        BEST_PRACTICES_GUIDANCE
      ),
      new L2Nav(t("side-nav.l2.resources.policy-tracker"), POLICY_TRACKER),
    ]
  ),
  new L1Nav(
    t("side-nav.l1.surveys"),
    <GVDSIcon Icon={IconClipboardCheck} />,
    SURVEYS,
    []
  ),
  new L1Nav(t("side-nav.l1.admin"), <GVDSIcon Icon={IconSettings} />, ADMIN, [
    new L2Nav(t("side-nav.l2.admin.site-details"), ADMIN_SITE_DETAILS),
    new L2Nav(t("side-nav.l2.admin.team"), ADMIN_TEAM),
    new L2Nav(t("side-nav.l2.admin.facilities"), ADMIN_FACILITIES),
    new L2Nav(t("side-nav.l2.admin.tags"), ADMIN_TAGS),
    new L2Nav(t("side-nav.l2.admin.billing"), ADMIN_BILLING),
  ]),
];

const SideNavL2Item = ({ item }) => {
  const location = useLocation();

  const isSelected = matchPath(location.pathname, {
    path: item.route,
    exact: false,
  });

  return (
    <div className="gvds-module-nav__l2-row">
      <Link to={getRedirectURLWithCurrentParam(item.route, location)}>
        <div
          className={
            "gvds-module-nav__selectable" + (isSelected ? " selected" : "")
          }
        >
          {item.name}
        </div>
      </Link>
    </div>
  );
};

const SideNavL1Item = ({ item, isSideNavMinimised }) => {
  const location = useLocation();

  const l1Ref = useRef();
  const [isExpanded, setIsExpanded] = useState(false);

  const hasChildNavs = item.hasChildNavs();
  const isSelected =
    !hasChildNavs &&
    matchPath(location.pathname, { path: item.route, exact: true });

  const isChildSelected =
    hasChildNavs &&
    matchPath(location.pathname, { path: item.route, exact: false });

  const showAsExpanded = isExpanded;

  useEffect(() => {
    if (isChildSelected && isExpanded === false) {
      setIsExpanded(true);
    }
  }, []);

  useEffect(() => {
    if (
      !isSideNavMinimised &&
      (isSelected || isChildSelected) &&
      l1Ref.current
    ) {
      l1Ref.current.scrollIntoView(smoothScrollBehaviour);
    }
  }, [isSideNavMinimised]);

  useEffect(() => {
    if (isExpanded && l1Ref.current) {
      l1Ref.current.scrollIntoView(smoothScrollBehaviour);
    }
  }, [isExpanded]);

  if (isSideNavMinimised) {
    return (
      <div className="gvds-module-nav__l1-row">
        <div
          className={
            "gvds-module-nav__selectable" +
            (isSelected || isChildSelected ? " selected" : "")
          }
        >
          <div className="nav-icon">{item.icon}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        ref={l1Ref}
        className={
          "gvds-module-nav__l1-row" + (showAsExpanded ? " is-expanded" : "")
        }
        onClick={() => {
          if (hasChildNavs) {
            setIsExpanded(!isExpanded);
          }
        }}
      >
        {hasChildNavs ? (
          <div
            className={
              "gvds-module-nav__selectable" +
              (isSelected || isChildSelected ? " selected" : "")
            }
          >
            <div className="nav-icon">{item.icon}</div>
            <div>{item.name}</div>
            <>
              <Spacer />
              <div className="expansion-icon">
                {showAsExpanded ? (
                  <GVDSIconSlim Icon={IconChevronDown} />
                ) : (
                  <GVDSIconSlim Icon={IconChevronRight} />
                )}
              </div>
            </>
          </div>
        ) : (
          <Link
            className={
              "gvds-module-nav__selectable" + (isSelected ? " selected" : "")
            }
            to={getRedirectURLWithCurrentParam(item.route, location)}
          >
            <div className="nav-icon">{item.icon}</div>
            <div>{item.name}</div>
          </Link>
        )}
      </div>
      {hasChildNavs && (
        <div
          className={
            "gvds-module-nav__l2-container" +
            (showAsExpanded ? " expanded" : "")
          }
        >
          {item.l2Navs.map((i, index) => (
            <SideNavL2Item key={index} item={i} />
          ))}
        </div>
      )}
    </>
  );
};

const ModuleNav = ({ isSideNavMinimised }) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);
  const [certifications, setCertifications] = useState([]);

  const certificationL2Navs = useMemo(() => {
    return certifications.map(
      (c) => new L2Nav(c.name, getViewCertificationHomepagePath(c.id))
    );
  }, [certifications]);

  useEffect(() => {
    CertificationService.getCertifications()
      .then((idNameDTOs) => {
        setCertifications(idNameDTOs);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (!selectedInventory) {
    return null;
  }

  let content;

  if (isLoading) {
    content = isSideNavMinimised ? (
      <LoadingSpinner width={3} height={20} />
    ) : (
      <LoadingSpinner />
    );
  } else {
    const navItems = getNavItems(t);

    const certificationL1Nav = navItems.find(
      (l1Item) => l1Item.route === CERTIFICATIONS
    );

    if (certifications.length > 0) {
      certificationL1Nav.l2Navs = certificationL2Navs;
    } else {
      const certificationIndex = navItems.indexOf(certificationL1Nav);
      navItems.splice(certificationIndex, 1);
    }

    content = navItems.map((i) => (
      <SideNavL1Item
        key={i.route}
        item={i}
        isSideNavMinimised={isSideNavMinimised}
      />
    ));
  }

  return <div className="gvds-module-nav">{content}</div>;
};

export default ModuleNav;
