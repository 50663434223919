import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "./Layout/DashboardLayout";
import DashboardMenu from "./Layout/DashboardMenu";
import DashboardOptionContext, {
  DashboardOptionProvider,
} from "./Context/DashboardOptionContext";
import LoadingSpinner from "../common/LoadingSpinner";
import DashboardSchemaParser from "./DashboardSchemaParser";
import DashboardDataContext, {
  DashboardDataProvider,
} from "./Context/DashboardDataContext";
import InlineSpinner from "../common/InlineSpinner";
import { LAST_TAB_STORAGE_KEY } from "./Widgets/DashboardTabs/DashboardTabs";

const Dashboard = () => {
  const dashboardOptionContext = useContext(DashboardOptionContext);
  const dashboardDataContext = useContext(DashboardDataContext);

  const selectedDashboard = dashboardOptionContext.selectedDashboard;

  const [parsedViewSchemaWidgets, setParsedViewSchemaWidgets] = useState(null);

  useEffect(() => {
    if (!!selectedDashboard) {
      dashboardDataContext.setIsInitialisingDashboardView(true);

      const parsedView = DashboardSchemaParser.parseView(
        selectedDashboard?.dashboardSchema ?? null
      );

      dashboardDataContext.dashboardHolderFacade.setSharedMenuSchema(
        parsedView.menuSchema
      );
      dashboardDataContext.dashboardHolderFacade.setNonSharedMenuSchema([]);
      dashboardDataContext.onUpdateConfig();

      setParsedViewSchemaWidgets(parsedView.widgets);

      dashboardDataContext.setIsInitialisingDashboardView(false);
    }
  }, [selectedDashboard]);

  if (dashboardOptionContext.isLoadingDashboardList) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column">
      <DashboardMenu />
      <DashboardLayout>
        {dashboardOptionContext.isLoadingSelectedDashboard ||
        dashboardDataContext.isInitialisingDashboardView ? (
          <InlineSpinner />
        ) : (
          parsedViewSchemaWidgets
        )}
      </DashboardLayout>
    </div>
  );
};

const DashboardWithContext = () => {
  useEffect(() => {
    return () => {
      sessionStorage.removeItem(LAST_TAB_STORAGE_KEY);
    };
  }, []);

  return (
    <DashboardOptionProvider>
      <DashboardDataProvider>
        <Dashboard />
      </DashboardDataProvider>
    </DashboardOptionProvider>
  );
};

export default DashboardWithContext;
