import React, { useContext, useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import AnnouncementService from "../../../../services/AnnouncementService";
import { RTFUtils } from "../../../../services/UtilsService";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import GVFormGroup from "../../../common/GVFormGroup";
import AnnouncementInitialContent from "./AnnouncementInitialContent.json";
import { RESOURCES } from "../../../../config/constants";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSFormMultiSelect from "../../../../gvds-components/Forms/GVDSFormMultiSelect";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import Container from "react-bootstrap/Container";

const ANNOUNCEMENT_TYPE_EVERYONE = "everyone";
const ANNOUNCEMENT_TYPE_CONTRACTS = "contracts";
const ANNOUNCEMENT_TYPE_SITES = "sites";

const EmailAnnouncement = () => {
  const toastContext = useContext(ToastContext);

  const [contracts, setContracts] = useState([]);
  const [sites, setSites] = useState([]);

  const [isSendingAnnouncement, setIsSendingAnnouncement] = useState(false);
  const [showSendingModal, setShowSendingModal] = useState(false);
  const closeSendingModal = () => {
    setShowSendingModal(false);
  };

  const [isValidated, setIsValidated] = useState(false);
  const [announcementType, setAnnouncementType] = useState("");
  const [selectedContractValues, setSelectedContractValues] = useState([]);
  const [selectedSiteValues, setSelectedSiteValues] = useState([]);
  const [subject, setSubject] = useState("");
  const [announcementBodyEditorState, setAnnouncementBodyEditorState] =
    useState(
      EditorState.createWithContent(convertFromRaw(AnnouncementInitialContent))
    );

  useEffect(() => {
    AnnouncementService.getConfig().then((config) => {
      setContracts(config.contracts);
      setSites(config.sites);
    });
  }, []);

  const resetFields = () => {
    setIsValidated(false);
    setAnnouncementType("");
    setSelectedContractValues([]);
    setSubject("");
    setAnnouncementBodyEditorState(EditorState.createEmpty());
  };

  const isAnnouncementRecipientValid = () => {
    if (!announcementType || announcementType.length === 0) {
      return false;
    }

    if (
      announcementType === ANNOUNCEMENT_TYPE_CONTRACTS &&
      (!selectedContractValues || selectedContractValues.length === 0)
    ) {
      return false;
    }

    return true;
  };

  const isSubjectValid = () => {
    return subject && subject.length > 0;
  };

  const isAnnouncementBodyValid = () => {
    return !RTFUtils.isEmpty(announcementBodyEditorState);
  };

  const isAnnouncementEmailValid = () => {
    return isSubjectValid() && isAnnouncementBodyValid();
  };

  const isAnnouncementValid = () => {
    return isAnnouncementRecipientValid() && isAnnouncementEmailValid();
  };

  const getAnnouncementBodyHTML = () => {
    return RTFUtils.isEmpty(announcementBodyEditorState)
      ? ""
      : RTFUtils.convertEditorStateToHTML(announcementBodyEditorState);
  };

  const sendTestEmail = async () => {
    setIsValidated(true);

    if (isAnnouncementEmailValid()) {
      try {
        await AnnouncementService.sendTestEmail(
          subject,
          getAnnouncementBodyHTML()
        );

        toastContext.addSuccessToast(
          <span>
            A test announcement email has been sent to your email address.
          </span>
        );
      } catch (e) {
        toastContext.addFailToast(<span>Failed to send test email.</span>);
      }
    }
  };

  const sendAnnouncement = async () => {
    setIsValidated(true);

    if (isAnnouncementValid()) {
      setIsSendingAnnouncement(true);
      setShowSendingModal(true);

      let targetResourceIds;
      let targetResourceType;

      if (announcementType === ANNOUNCEMENT_TYPE_CONTRACTS) {
        targetResourceType = RESOURCES.CONTRACT;
        targetResourceIds = selectedContractValues.map((entry) => entry.value);
      } else if (announcementType === ANNOUNCEMENT_TYPE_SITES) {
        targetResourceType = RESOURCES.SITE;
        targetResourceIds = selectedSiteValues.map((entry) => entry.value);
      } else {
        targetResourceType = null;
        targetResourceIds = null;
      }

      try {
        await AnnouncementService.sendAnnouncement(
          targetResourceType,
          targetResourceIds,
          subject,
          getAnnouncementBodyHTML()
        );

        resetFields();
        setIsSendingAnnouncement(false);
      } catch (e) {
        closeSendingModal();
        setIsSendingAnnouncement(false);
        toastContext.addFailToast(
          <span>Failed to send announcement email.</span>
        );
      }
    }
  };

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Send an announcement</h1>
        </PageHeader.Title>
      </PageHeader>
      <Row>
        <Col sm={5}>
          <GVFormGroup>
            <Form.Label>Send announcement to</Form.Label>
            <Form.Check
              id="everyone"
              label="All users in the portal"
              type="radio"
              checked={announcementType === ANNOUNCEMENT_TYPE_EVERYONE}
              onChange={() => setAnnouncementType(ANNOUNCEMENT_TYPE_EVERYONE)}
            />
            <Form.Check
              id="contracts"
              label="Users in specific contract(s)"
              type="radio"
              checked={announcementType === ANNOUNCEMENT_TYPE_CONTRACTS}
              onChange={() => setAnnouncementType(ANNOUNCEMENT_TYPE_CONTRACTS)}
            />
            <Form.Check
              id="sites"
              label="Users in specific site(s) with individual site subscriptions"
              type="radio"
              checked={announcementType === ANNOUNCEMENT_TYPE_SITES}
              onChange={() => setAnnouncementType(ANNOUNCEMENT_TYPE_SITES)}
            />
            {announcementType === ANNOUNCEMENT_TYPE_CONTRACTS && (
              <GVDSFormMultiSelect
                name="selected-contracts"
                placeholder="Select contracts"
                value={selectedContractValues}
                onSelect={(values) => setSelectedContractValues(values)}
                options={contracts.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
              />
            )}
            {announcementType === ANNOUNCEMENT_TYPE_SITES && (
              <GVDSFormMultiSelect
                name="selected-sites"
                placeholder="Select sites"
                value={selectedSiteValues}
                onSelect={(values) => setSelectedSiteValues(values)}
                options={sites.map((c) => ({ label: c.name, value: c.id }))}
              />
            )}
            {isValidated && !isAnnouncementRecipientValid() && (
              <div className="manual-invalid-feedback">
                Please select recipients
              </div>
            )}
          </GVFormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={5}>
          <GVFormGroup>
            <Form.Label>Subject Header</Form.Label>
            <GVDSFormField
              name="subject"
              value={subject}
              onInput={(value) => setSubject(value)}
              statusMetadata={
                isValidated && !isSubjectValid()
                  ? FormFieldStatusMetadata.getError(
                      "Please provide subject header."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
        </Col>
      </Row>
      <div className="d-flex flex-row">
        <div>
          <Form.Label>Body</Form.Label>
        </div>
        <div className="ms-auto">
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={sendTestEmail}
            text="Send test email"
          />
        </div>
      </div>
      <div>
        <Editor
          editorState={announcementBodyEditorState}
          onEditorStateChange={(editorState) => {
            setAnnouncementBodyEditorState(editorState);
          }}
          wrapperClassName="rtf-wrapper"
          editorClassName="rtf-editor"
          toolbar={{
            options: [
              "blockType",
              "inline",
              "list",
              "link",
              "image",
              "remove",
              "history",
            ],
          }}
        />
        {isValidated && !isAnnouncementBodyValid() && (
          <div className="manual-invalid-feedback">
            EmailAnnouncement cannot be empty
          </div>
        )}
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={sendAnnouncement}
          text="Send Announcement Email"
        />
      </div>

      <GVDSModal
        title="Emails are being sent."
        size={GVDSModal.Size.small}
        show={showSendingModal}
        onHide={closeSendingModal}
      >
        <GVDSModal.Body>
          {isSendingAnnouncement && <LoadingSpinner />}
          {!isSendingAnnouncement && (
            <div>
              The system is currently sending out the announcement message to
              all relevant users. This will take a few minutes in the
              background. A copy of the announcement message will be sent to
              support@greenviewportal.com.
            </div>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={closeSendingModal}
            disabled={isSendingAnnouncement}
            text="Close"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </Container>
  );
};

export default EmailAnnouncement;
