import { DateTimeUtils } from "../../../services/UtilsService";
import { RESOURCES } from "../../../config/constants";
import {
  SubscriptionPaymentType,
  SubscriptionPricingType,
} from "./SubscriptionSharedUtils";

class BillingSiteSubscriptionFromContractSubListModel {
  constructor({
    id,
    site,
    startPeriod,
    endPeriod,
    reminderDate,
    features,
    paymentStatus,
  }) {
    this.id = id;
    this.site = site;
    this.startPeriod = startPeriod;
    this.endPeriod = endPeriod;
    this.reminderDate = reminderDate;
    this.features = features;
    this.paymentStatus = paymentStatus;
  }

  static fromDTO(requestDTO) {
    return new BillingSiteSubscriptionFromContractSubListModel({
      id: requestDTO["id"],
      site: requestDTO["site"],
      startPeriod: requestDTO["start_period"],
      endPeriod: requestDTO["end_period"],
      reminderDate: requestDTO["reminder_date"],
      features: requestDTO["features"],
      paymentStatus: requestDTO["payment_status"],
    });
  }
}

export default class BillingSubscriptionModel {
  constructor({
    id,
    name,
    resourceId,
    type,
    startPeriod,
    endPeriod,
    currencyId,
    priceAmount,
    pricingType,
    comments,
    status,
    discountCode,
    paymentStatus,
    paymentType,
    plan,
    accountManager,
    billingAdmin,
    siteSubscriptions,
    siteNames,
    priceAmountSearch,
    startPeriodSearch,
    endPeriodSearch,
    typeFilter,
  }) {
    this.id = id;
    this.name = name;
    this.resourceId = resourceId;
    this.type = type;
    this.startPeriod = startPeriod;
    this.endPeriod = endPeriod;
    this.currencyId = currencyId;
    this.priceAmount = priceAmount;
    this.pricingType = pricingType;
    this.comments = comments;
    this.status = status;
    this.discountCode = discountCode;
    this.paymentStatus = paymentStatus;
    this.paymentType = paymentType;
    this.plan = plan;
    this.accountManager = accountManager;
    this.billingAdmin = billingAdmin;
    this.siteSubscriptions = siteSubscriptions;
    this.siteNames = siteNames;
    this.priceAmountSearch = priceAmountSearch;
    this.startPeriodSearch = startPeriodSearch;
    this.endPeriodSearch = endPeriodSearch;
    this.typeFilter = typeFilter;
  }

  static fromDTO(requestDTO) {
    return new BillingSubscriptionModel({
      id: requestDTO["id"],
      name: requestDTO["resource"] ? requestDTO["resource"]["name"] : "",
      resourceId: requestDTO["resource"] ? requestDTO["resource"]["id"] : "",
      type: requestDTO["type"],
      startPeriod: requestDTO["start_period"] ?? null,
      endPeriod: requestDTO["end_period"] ?? null,
      currencyId: requestDTO["currency"] ? requestDTO["currency"]["id"] : "",
      priceAmount:
        requestDTO["pricing_type"] === SubscriptionPricingType.PAID
          ? requestDTO["price_amount"] ?? 0
          : null,
      pricingType: requestDTO["pricing_type"],
      comments: requestDTO["comments"],
      status: requestDTO["status"],
      discountCode: requestDTO["discount_code"],
      paymentStatus: requestDTO["payment_status"],
      paymentType: requestDTO["payment_type"],
      plan: requestDTO["plan"] ? requestDTO["plan"]["name"] : "",
      accountManager: requestDTO["account_manager"],
      billingAdmin: requestDTO["billing_admin"],
      siteSubscriptions: requestDTO["site_subscriptions"]?.map((subscription) =>
        BillingSiteSubscriptionFromContractSubListModel.fromDTO(subscription)
      ),
      siteNames: requestDTO["site_subscriptions"]
        ?.map((subscription) => subscription.site.name)
        .join(","),
      priceAmountSearch: requestDTO["price_amount"]
        ? requestDTO["price_amount"].toString()
        : null,
      startPeriodSearch: requestDTO["start_period"]
        ? DateTimeUtils.formatLocalDate(requestDTO["start_period"])
        : null,
      endPeriodSearch: requestDTO["end_period"]
        ? DateTimeUtils.formatLocalDate(requestDTO["end_period"])
        : null,
      typeFilter:
        requestDTO["type"] === RESOURCES.SITE
          ? requestDTO["type"]
          : `${requestDTO["type"]} - ${
              requestDTO["payment_type"] ?? SubscriptionPaymentType.CENTRALIZED
            }`,
    });
  }
}
