import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Badge from "react-bootstrap/Badge";

import ToastContext from "../../../context/ToastContext";
import {
  ARTICLE_CONTENT_MAX_FILE_SIZE_IN_MB,
  ARTICLE_CONTENT_TYPE,
  GUIDANCE_ARTICLE_STATUS,
} from "../../../config/constants";
import { SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_LIST } from "../../../config/ROUTES_NAME";
import LoadingSpinner from "../../common/LoadingSpinner";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import BestPracticeCategoryService from "../../../services/ReferenceDataServices/BestPracticeCategoryService";
import GuidanceArticleAdminService from "../../../services/GuidanceArticleAdminService";
import UNSDGPicker from "../../common/UNSDGPicker";
import GuidanceArticleDisplay from "../../BestPractices/GuidanceArticles/GuidanceArticleDisplay";
import { MultipleFilesUploader } from "../../common/FileAttachments";
import BestPracticeQuestionLink from "../Shared/BestPracticeQuestionLink";
import GVFormGroup from "../../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormMultiSelect from "../../../gvds-components/Forms/GVDSFormMultiSelect";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import {
  GuideContentTypeUtils,
  StringUtils,
} from "../../../services/UtilsService";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconTrash } from "@tabler/icons-react";

const GuidanceArticleForm = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);
  const { guidanceArticleId } = useParams();

  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [availableSiteTypes, setAvailableSiteTypes] = useState([]);
  const [allUNSDGs, setUNSDGs] = useState([]);
  const [availableArticleTypes, setAvailableArticleTypes] = useState([]);
  const [availableContentTypes, setAvailableContentTypes] = useState([]);
  const [availableGuidanceArticles, setAvailableGuidanceArticles] = useState(
    []
  );
  const [allBestPracticeQuestions, setAllBestPracticeQuestions] = useState([]);

  const [isEditExisting, setIsEditExisting] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [originalTitle, setOriginalTitle] = useState("");

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteGuidanceArticlePrompt, setShowDeleteGuidanceArticlePrompt] =
    useState(false);
  const cancelDeleteQuestionPrompt = () => {
    setShowDeleteGuidanceArticlePrompt(false);
  };

  const [status, setStatus] = useState(GUIDANCE_ARTICLE_STATUS.INACTIVE);
  const [title, setTitle] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [siteTypes, setSiteTypes] = useState([]);
  const [selectedUNSDGs, setSelectedUNSDGs] = useState([]);
  const [articleType, setArticleType] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [relevantArticles, setRelevantArticles] = useState([]);
  const [contentUploadFilesInput, setContentUploadFilesInput] = useState([]);
  const [downloadableResourceFilesInput, setDownloadableResourceFilesInput] =
    useState([]);
  const [htmlBody, setHtmlBody] = useState(null);
  const [linkedBestPracticeQuestions, setLinkedBestPracticeQuestions] =
    useState([]);

  useEffect(() => {
    setIsLoading(true);

    let loadOptions = new Promise((resolve) => resolve());
    if (availableCategories.length === 0) {
      loadOptions = new Promise((resolve) => {
        GuidanceArticleAdminService.getGuidanceArticleOptions()
          .then((options) => {
            setAvailableCategories(options.categories);
            setAvailableTags(options.tags);
            setAvailableSiteTypes(options.site_types);
            setSiteTypes(options.site_types);
            setUNSDGs(options.un_sdgs);
            setAvailableArticleTypes(options.article_types);
            setAvailableContentTypes(options.content_types);
            setAvailableGuidanceArticles(options.guidance_articles);
            setAllBestPracticeQuestions(options.best_practices_questions);

            resolve();
          })
          .catch(() => {
            setIsLoading(false);
            toastContext.addFailToast(
              <span>Failed to load guide options. Please try again</span>
            );
          });
      });
    }
    loadOptions.then(() => {
      if (guidanceArticleId) {
        setIsEditExisting(true);
        GuidanceArticleAdminService.getGuidanceArticle(guidanceArticleId).then(
          (article) => {
            setStatus(article.status);
            setOriginalTitle(article.title);
            setTitle(article.title);
            setCategories(article.categories);
            setTags(article.tags);
            setSiteTypes(article.site_types);
            setSelectedUNSDGs(article.un_sdgs);
            setArticleType(article.article_type);
            setContentUploadFilesInput(
              article.content_file ? [article.content_file] : []
            );
            setDownloadableResourceFilesInput(article.attached_files);
            setHtmlBody(article.body);
            setLinkedBestPracticeQuestions(article.linked_questions);
            setRelevantArticles(article.relevant_articles);
            setContentType(article.content_type);
            setIsLoading(false);
          }
        );
      } else {
        setIsEditExisting(false);
        setStatus(GUIDANCE_ARTICLE_STATUS.ACTIVE);
        setOriginalTitle("New Guide");
        setTitle("");
        setCategories([]);
        setTags([]);
        setSelectedUNSDGs([]);
        setArticleType(null);
        setContentUploadFilesInput([]);
        setDownloadableResourceFilesInput([]);
        if (availableSiteTypes.length > 0) {
          setSiteTypes(availableSiteTypes);
        }
        setLinkedBestPracticeQuestions([]);
        setRelevantArticles([]);
        setContentType(null);
        setIsLoading(false);
      }
    });
  }, [guidanceArticleId]);

  const changeContentFile = (selectedFiles) => {
    setContentUploadFilesInput(selectedFiles);
    if (selectedFiles.length > 0) {
      const filesNotDeleted = selectedFiles.filter(
        (file) => !file.isToBeDeleted()
      );
      if (filesNotDeleted.length === 1) {
        const file = filesNotDeleted[0];
        let contentFileType;
        if (GuideContentTypeUtils.isPdf(file)) {
          contentFileType = getContentType(ARTICLE_CONTENT_TYPE.PDF);
        } else if (GuideContentTypeUtils.isVideo(file)) {
          contentFileType = getContentType(ARTICLE_CONTENT_TYPE.VIDEO);
        } else {
          contentFileType = null;
        }
        setContentType(contentFileType);
      } else {
        setContentType(null);
      }
    } else {
      setContentType(null);
    }
  };

  const showPreview = (e) => {
    e.preventDefault();
    setShowPreviewModal(true);
  };

  const closePreview = () => {
    setShowPreviewModal(false);
  };

  const isTitleInvalid = () => {
    return !title || title.length === 0;
  };

  const isCategoryInvalid = () => {
    return categories.length === 0;
  };

  const isSiteTypesInvalid = () => {
    return siteTypes.length === 0;
  };

  const isUNSDGEmpty = () => {
    return !selectedUNSDGs || !selectedUNSDGs.length > 0;
  };

  const isArticleTypeInvalid = () => {
    return !articleType;
  };

  const isContentTypeInvalid = () => {
    return !contentType;
  };

  const isContentFileValid = () => {
    const filesNotDeleted = contentUploadFilesInput?.filter(
      (file) => !file.isToBeDeleted()
    );
    return (
      filesNotDeleted.length === 1 &&
      (GuideContentTypeUtils.isPdf(filesNotDeleted[0]) ||
        GuideContentTypeUtils.isVideo(filesNotDeleted[0]))
    );
  };

  const saveGuidanceArticle = () => {
    setIsValidated(true);

    if (
      !isTitleInvalid() &&
      !isCategoryInvalid() &&
      !isSiteTypesInvalid() &&
      !isUNSDGEmpty() &&
      !isArticleTypeInvalid() &&
      isContentFileValid()
    ) {
      setIsLoading(true);
      if (!isEditExisting) {
        GuidanceArticleAdminService.createGuidanceArticle(
          title,
          categories,
          tags,
          siteTypes,
          selectedUNSDGs,
          articleType,
          contentUploadFilesInput[0],
          downloadableResourceFilesInput,
          linkedBestPracticeQuestions.map((q) => q.id),
          relevantArticles.map((a) => a.id),
          contentType
        )
          .then(() => {
            setIsLoading(false);
            toastContext.addSuccessToast(
              <span>Your guide was successfully created</span>
            );
            goToGuidanceArticleList();
          })
          .catch(() => {
            setIsLoading(false);
            toastContext.addFailToast(
              <span>Failed to add guide. Please try again.</span>
            );
          });
      } else {
        const newContentFile = contentUploadFilesInput.filter((file) =>
          file.isNew()
        );

        GuidanceArticleAdminService.editGuidanceArticle(
          guidanceArticleId,
          title,
          categories,
          tags,
          siteTypes,
          selectedUNSDGs.map((unSdg) => {
            return { id: unSdg.id, name: unSdg.name };
          }),
          articleType,
          newContentFile.length > 0 ? newContentFile[0] : null,
          downloadableResourceFilesInput,
          linkedBestPracticeQuestions.map((q) => q.id),
          relevantArticles.map((a) => a.id),
          contentType
        )
          .then(() => {
            setIsLoading(false);
            toastContext.addSuccessToast(
              <span>Your guide was successfully updated</span>
            );
            goToGuidanceArticleList();
          })
          .catch(() => {
            setIsLoading(false);
            toastContext.addFailToast(
              <span>Failed to update guide. Please try again.</span>
            );
          });
      }
    }
  };

  const onSavingLinkedBestPracticeQn = (selectedQuestions) => {
    setLinkedBestPracticeQuestions(selectedQuestions);
  };

  const deleteArticle = () => {
    GuidanceArticleAdminService.deleteGuidanceArticle(guidanceArticleId)
      .then(() => {
        setShowDeleteGuidanceArticlePrompt(false);
        toastContext.addSuccessToast(
          <span>Your guide was successfully deleted</span>
        );
        goToGuidanceArticleList();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Something went wrong, please try again.</span>
        );
      });
  };

  const goToGuidanceArticleList = () => {
    history.push(SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_LIST);
  };

  const toggleStatus = () => {
    let newStatus;

    if (status === GUIDANCE_ARTICLE_STATUS.ACTIVE) {
      newStatus = GUIDANCE_ARTICLE_STATUS.INACTIVE;
    } else {
      newStatus = GUIDANCE_ARTICLE_STATUS.ACTIVE;
    }

    GuidanceArticleAdminService.changeArticleStatus(
      guidanceArticleId,
      newStatus
    )
      .then(() => {
        setStatus(newStatus);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to update guide status</span>);
      });
  };

  const changeCategory = (selectedOptions) => {
    setCategories(
      selectedOptions.map((option) => {
        return { id: option.value, name: option.label };
      })
    );
  };

  const changeTags = (selected) => {
    setTags(
      selected.map((item) => {
        return { id: item.value, name: item.label };
      })
    );
  };

  const changeSiteTypes = (selected) => {
    setSiteTypes(
      selected.map((item) => {
        return { id: item.value, name: item.label };
      })
    );
  };

  const onSelectingUNSDGs = (selectedUNSDGs) => {
    setSelectedUNSDGs(
      selectedUNSDGs.map((sdg) => {
        return { id: sdg.value, name: sdg.label };
      })
    );
  };

  const changeArticleType = (selectedOption) => {
    setArticleType({ id: selectedOption.value, name: selectedOption.label });
  };

  const getContentType = (contentType) => {
    return availableContentTypes.find((obj) => obj.name === contentType);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text={
            <>
              Return to Guide List{" "}
              <span className="caption color-gray56 ms-1">
                (Unless saved, changes will be lost)
              </span>
            </>
          }
          onClick={goToGuidanceArticleList}
          className="cancel-guidance-article"
        />
        <PageHeader.Title>
          <h1>{originalTitle}</h1>
          <Spacer />

          {isEditExisting && (
            <Dropdown>
              <Dropdown.Toggle as={OtherActionDropdownToggle} />

              <Dropdown.Menu>
                <Dropdown.Item
                  key="delete"
                  href="#"
                  className="danger"
                  onClick={() => setShowDeleteGuidanceArticlePrompt(true)}
                >
                  <GVDSIcon Icon={IconTrash} /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <GVDSButton
            variant={buttonVariant.secondary}
            onClick={showPreview}
            text="Preview"
            disabled={
              contentUploadFilesInput.length > 0 && !isContentFileValid()
            }
          />
        </PageHeader.Title>
        <PageHeader.Status>
          <div className="info-field">
            <StatusLabel
              color={
                status === GUIDANCE_ARTICLE_STATUS.ACTIVE
                  ? StatusLabel.Colors.green
                  : StatusLabel.Colors.gray
              }
            >
              {StringUtils.capitaliseWord(status)}
            </StatusLabel>
            {isEditExisting && (
              <GVDSButton
                variant={buttonVariant.tertiary}
                className="mark-as-active ms-2"
                onClick={toggleStatus}
                text={
                  <>
                    Mark as
                    <span className="text-capitalize ms-1">
                      {status === GUIDANCE_ARTICLE_STATUS.ACTIVE
                        ? GUIDANCE_ARTICLE_STATUS.INACTIVE
                        : GUIDANCE_ARTICLE_STATUS.ACTIVE}
                    </span>
                  </>
                }
              />
            )}
          </div>
        </PageHeader.Status>
      </PageHeader>

      <section className="section-box">
        <header>
          <h2>Guide Setup</h2>
        </header>
        <article>
          <GVFormGroup>
            <Form.Label>Guide Title</Form.Label>
            <GVDSFormField
              name="title"
              value={title}
              onInput={(value) => setTitle(value)}
              statusMetadata={
                isValidated && isTitleInvalid()
                  ? FormFieldStatusMetadata.getError("Please Enter a title.")
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Categories</Form.Label>
            <GVDSFormMultiSelect
              className="select__category"
              name="category"
              placeholder="Select Category"
              value={
                categories
                  ? categories.map((category) => {
                      return { value: category.id, label: category.name };
                    })
                  : []
              }
              onSelect={changeCategory}
              options={availableCategories
                .sort((category1, category2) =>
                  BestPracticeCategoryService.bestPracticeSortFn(
                    category1.name,
                    category2.name
                  )
                )
                .map((category) => {
                  return { value: category.id, label: category.name };
                })}
              statusMetadata={
                isValidated && isCategoryInvalid()
                  ? FormFieldStatusMetadata.getError(
                      "Please Select a Category."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>
              Tags <span className="optional-form-label">(optional)</span>
            </Form.Label>
            <GVDSFormMultiSelect
              name="tags"
              value={
                tags
                  ? tags.map((tag) => {
                      return {
                        label: tag.name,
                        value: tag.id,
                      };
                    })
                  : []
              }
              onSelect={(values) => changeTags(values)}
              options={availableTags.map((tag) => {
                return {
                  label: tag.name,
                  value: tag.id,
                };
              })}
              className="tag-select"
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Guide Type</Form.Label>
            <GVDSFormSingleSelect
              className="select__guide-type"
              name="article-type"
              placeholder="Select Guide Type"
              value={
                articleType
                  ? { value: articleType.id, label: articleType.name }
                  : null
              }
              onSelect={changeArticleType}
              options={availableArticleTypes.map((type) => {
                return { value: type.id, label: type.name };
              })}
              statusMetadata={
                isValidated && isArticleTypeInvalid()
                  ? FormFieldStatusMetadata.getError(
                      "Please Select an Guide Type."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Relevant Site Types</Form.Label>
            <GVDSFormMultiSelect
              name="site_types"
              value={
                siteTypes
                  ? siteTypes.map((siteType) => {
                      return {
                        label: siteType.name,
                        value: siteType.id,
                      };
                    })
                  : []
              }
              onSelect={(values) => changeSiteTypes(values)}
              options={availableSiteTypes.map((siteType) => {
                return {
                  label: siteType.name,
                  value: siteType.id,
                };
              })}
              className="site-type-select"
              statusMetadata={
                isValidated && isSiteTypesInvalid()
                  ? FormFieldStatusMetadata.getError(
                      "Please select at least one site type"
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup controlId="">
            <Form.Label>UN SDGs</Form.Label>{" "}
            <UNSDGPicker
              selectedUNSDGs={selectedUNSDGs}
              allUNSDGs={allUNSDGs}
              isInvalid={isValidated && isUNSDGEmpty()}
              onChange={onSelectingUNSDGs}
              invalidMessage="Please select applicable UN SDGs"
            />
          </GVFormGroup>
          <GVFormGroup className="attach-file__container">
            <Form.Label>Attach a File</Form.Label>
            <span className="mx-2 optional-form-label">
              (acceptable format: PDF, MP4)
            </span>
            <MultipleFilesUploader
              files={contentUploadFilesInput}
              setFiles={changeContentFile}
              maxFileSizeInMB={ARTICLE_CONTENT_MAX_FILE_SIZE_IN_MB}
              useS3PresignedURL={true}
            />
            {isValidated && !isContentFileValid() && (
              <div className="manual-invalid-feedback">
                Require 1 file of extension PDF or MP4.
              </div>
            )}
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Content Type</Form.Label>
            <GVDSFormSingleSelect
              placeholder="Select content Type"
              options={availableContentTypes.map((contentType) => {
                return { value: contentType.id, label: contentType.name };
              })}
              value={
                contentType
                  ? {
                      value: contentType.id,
                      label: contentType.name,
                    }
                  : null
              }
              disabled
              statusMetadata={
                isValidated && isContentTypeInvalid()
                  ? FormFieldStatusMetadata.getError(
                      "Please choose file with accepted format/extension"
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>
              Downloadable Resources{" "}
              <span className="optional-form-label">
                (optional; up to 40MB)
              </span>
            </Form.Label>
            <MultipleFilesUploader
              files={downloadableResourceFilesInput}
              setFiles={setDownloadableResourceFilesInput}
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Relevant Guides</Form.Label>
            <span className="mx-2 optional-form-label">(Optional)</span>
            <GVDSFormMultiSelect
              placeholder="Search or Select a title"
              isSearchable={true}
              value={
                relevantArticles
                  ? relevantArticles.map((article) => {
                      return { value: article.id, label: article.title };
                    })
                  : []
              }
              onSelect={(selected) => {
                setRelevantArticles(
                  selected.map((item) => {
                    return { id: item.value, title: item.label };
                  })
                );
              }}
              options={availableGuidanceArticles.map((article) => {
                return { value: article.id, label: article.title };
              })}
            />
          </GVFormGroup>
        </article>
      </section>

      <section className="section-box">
        <header>
          <h2>Linked Best Practices</h2>
        </header>
        <article>
          <BestPracticeQuestionLink
            allQuestions={allBestPracticeQuestions}
            linkedQuestions={linkedBestPracticeQuestions}
            onLinked={onSavingLinkedBestPracticeQn}
          />
        </article>
      </section>

      <div className="d-flex justify-content-end">
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={saveGuidanceArticle}
          text="Save"
        />
      </div>

      <GVDSModal
        title={<Badge variant="primary">Preview</Badge>}
        show={showPreviewModal}
        onHide={closePreview}
        size={GVDSModal.Size.large}
      >
        <GVDSModal.Body>
          <GuidanceArticleDisplay
            title={title}
            htmlContent={htmlBody}
            contentFileInput={
              contentUploadFilesInput.filter((file) => !file.isToBeDeleted())
                .length > 0
                ? contentUploadFilesInput.filter(
                    (file) => !file.isToBeDeleted()
                  )[0]
                : null
            }
            lastEditedOn={new Date()}
            articleType={articleType}
            categories={categories}
            tags={tags}
            siteTypes={siteTypes}
            UNSDGs={selectedUNSDGs}
            files={downloadableResourceFilesInput.filter(
              (file) => !file.isToBeDeleted()
            )}
            contentType={contentType}
            relevantArticles={relevantArticles}
          />
        </GVDSModal.Body>
      </GVDSModal>

      <GVDSModal
        title={`Deleting ${originalTitle}`}
        show={showDeleteGuidanceArticlePrompt}
        onHide={cancelDeleteQuestionPrompt}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          <p>
            This action cannot be undone and will remove all attached files.
          </p>
          <p>Are you sure?</p>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancelDeleteQuestionPrompt}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteArticle}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </div>
  );
};

export default GuidanceArticleForm;
