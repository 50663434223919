import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import BestPracticesReportService from "../../../services/Report/BestPracticesReportService";
import LoadingSpinner from "../../common/LoadingSpinner";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import uniq from "lodash/uniq";
import intersection from "lodash/intersection";
import Form from "react-bootstrap/Form";
import { UtilsService } from "../../../services/UtilsService";
import flatMap from "lodash/flatMap";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSTag from "../../../gvds-components/common/GVDSTag";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { bestPracticeReportQuestionSearchKeys } from "../../../config/search-config";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTable, {
  MULTI_SELECT_DATAKEY,
} from "../../../gvds-components/Table/GVDSTable";
import { useTranslation } from "react-i18next";

const BestPracticesReport = () => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const [selectedQuestionIds, setSelectedQuestionIds] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(questions, bestPracticeReportQuestionSearchKeys);

  useEffect(() => {
    if (selectedInventory?.id) {
      setIsLoading(true);
      BestPracticesReportService.getReportConfig(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((data) => {
          setQuestions(data.questions);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load report configuration.</span>
          );
        });
    }
  }, [selectedInventory]);

  const toggleQuestionSelection = (questionId) => {
    setSelectedQuestionIds(
      UtilsService.toggleItem(selectedQuestionIds, questionId)
    );
  };

  const onPageQuestionsSelect = () => {
    const questionIds = currentPageData.map((r) => r.id);
    const intersectIds = intersection(selectedQuestionIds, questionIds);
    if (intersectIds.length === questionIds.length) {
      setSelectedQuestionIds(
        selectedQuestionIds.filter((rId) => questionIds.indexOf(rId) < 0)
      );
    } else {
      setSelectedQuestionIds(uniq([...selectedQuestionIds, ...questionIds]));
    }
  };

  const selectAllFiltered = () => {
    const questionIds = filteredSortedData.map((r) => r.id);
    setSelectedQuestionIds(uniq([...selectedQuestionIds, ...questionIds]));
  };

  const unselectAllFiltered = () => {
    const questionIds = filteredSortedData.map((r) => r.id);
    setSelectedQuestionIds(
      selectedQuestionIds.filter((rId) => questionIds.indexOf(rId) < 0)
    );
  };

  const onDownloadReport = async () => {
    if (selectedQuestionIds.length === 0) return;

    try {
      await BestPracticesReportService.downloadReport(
        selectedInventory.type,
        selectedInventory.id,
        selectedQuestionIds
      );
    } catch {
      toastContext.addFailToast(<span>Failed to generate report.</span>);
    }
  };

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    const columns = [
      {
        header: "",
        dataKey: MULTI_SELECT_DATAKEY,
        renderer: (d) => {
          return (
            <Form.Check
              checked={selectedQuestionIds.indexOf(d.id) >= 0}
              type="checkbox"
              key={d.id}
              id={d.id}
              label=""
              onChange={() => toggleQuestionSelection(d.id)}
            />
          );
        },
      },
      {
        header: "Question",
        headerStyle: { minWidth: "600px" },
        dataKey: "questionText",
      },
      {
        header: "Categories",
        headerStyle: { maxWidth: "200px", width: "200px" },
        dataKey: "categoryNames",
        renderer: (d) =>
          d.categoryNames.map((category) => (
            <GVDSTag variant={GVDSTag.Variants.system} key={category}>
              {category}
            </GVDSTag>
          )),
      },
      {
        header: "Tags",
        headerStyle: { maxWidth: "350px", width: "350px" },
        dataKey: "tagNames",
        renderer: (d) =>
          d.tagNames.map((tag) => (
            <GVDSTag variant={GVDSTag.Variants.system} key={tag}>
              {tag}
            </GVDSTag>
          )),
      },
    ];
    const pageQuestionIds = currentPageData.map((r) => r.id);
    const isPageSelected =
      intersection(pageQuestionIds, selectedQuestionIds).length ===
      pageQuestionIds.length;
    content = (
      <div className="best-practice-report-config">
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          isPageSelected={isPageSelected}
          onPageSelect={onPageQuestionsSelect}
          onRowClick={(bestPracticeQuestion) =>
            toggleQuestionSelection(bestPracticeQuestion.id)
          }
          noContent={
            <div className="table__no_content">
              No best practice questions found within the specified scope. Try
              adjusting your filter or use another keyword.
            </div>
          }
          viewControl={
            <GVDSTableCtrlContainer>
              <FilterSearchBox
                placeholder="Search Best Practice Question"
                value={searchText}
                onInput={setSearchText}
              />
              <GVDSTableCtrlMultiSelect
                options={uniq(
                  questions.map((q) => q.categoryNames).flat()
                ).sort()}
                prefix="Categories"
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, categoryNames: filterKs })
                }
              />
              <GVDSTableCtrlMultiSelect
                options={uniq(flatMap(questions.map((f) => f.tagNames))).sort()}
                defaultSelected={[]}
                prefix="Tags"
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, tagNames: filterKs })
                }
              />
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={selectAllFiltered}
                text="Select All Filtered"
              />
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={unselectAllFiltered}
                text="Unselect All Filtered"
              />
              <div className="body-1 ms-4">
                Questions selected: {selectedQuestionIds.length}
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
          }
        />
        <div className="report-config-footer">
          <GVDSButtonWithLoadingAction
            className="best-practices-report-download-button"
            onClickAsyncFunc={onDownloadReport}
            disabled={selectedQuestionIds.length === 0 || isLoading}
            text="Download Report"
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("reports.best-practices.page-title")}</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          {t("reports.best-practices.page-description")}
        </PageHeader.Description>
      </PageHeader>
      {content}
    </div>
  );
};

export default BestPracticesReport;
