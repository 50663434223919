import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import greenviewPortalLogo from "../assets/images/new-greenview-logo.svg";
import { DateTimeUtils } from "../services/UtilsService";
import { FEATURE_FLAG_MAINTENANCE_END_TIME } from "../config/feature-flag-config";
import SupportContactLink from "../components/common/SupportContactLink";

export const UnderMaintenancePlaceholder = () => {
  return (
    <div className="screen-container">
      <Container fluid className="h-100">
        <Row className="h-100 justify-content-center align-items-center">
          <Col xl={4} lg={6} md={8} s={12}>
            <Container fluid className="login-section">
              <Row>
                <Col>
                  <div className="d-flex justify-content-center">
                    <img
                      src={greenviewPortalLogo}
                      alt="Greenview Portal"
                      width="400"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center mt-2">
                    <p>
                      The portal is currently under maintenance.
                      <br />
                      We'll be back at approximately{" "}
                      {DateTimeUtils.formatISODateWithFriendlyHourMinute(
                        FEATURE_FLAG_MAINTENANCE_END_TIME
                      )}
                      .
                    </p>
                    <p>
                      For urgent issues, contact <SupportContactLink />.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
