import { DateTimeUtils } from "../../../services/UtilsService";

export default class WidgetService {
  static getDeadlineDueMessage(deadline) {
    const remainingDays = DateTimeUtils.getRemainingDaysBefore(deadline);
    if (remainingDays > 60) {
      return "due " + DateTimeUtils.formatUTCDate(deadline);
    }
    return DateTimeUtils.getDeadlineDueMessage(deadline);
  }
}
