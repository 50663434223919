import axios from "axios";
import { API_URL } from "../config/api-config";

export default class GoalService {
  static async createGoal(resource_type, resource_id, name) {
    try {
      const payload = { name };
      const response = await axios.post(`${API_URL}/api/v1/goals`, payload, {
        params: { resource_type, resource_id },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getAll(resource_type, resource_id) {
    try {
      const response = await axios.get(API_URL + "/api/v1/goals", {
        params: { resource_id, resource_type },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editGoal(resource_type, resource_id, goalId, name) {
    try {
      const payload = { name };
      const response = await axios.put(
        `${API_URL}/api/v1/goals/${goalId}`,
        payload,
        {
          params: { resource_type, resource_id },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteGoal(resource_type, resource_id, goalId) {
    try {
      const response = await axios.delete(`${API_URL}/api/v1/goals/${goalId}`, {
        params: { resource_type, resource_id },
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
}
