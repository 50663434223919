export const GVDSColors = {
  white: "#ffffff",
  gray2: "#F3F5F5",
  gray3: "#E5EAEA",
  gray4: "#D1DADA",
  gray6: "#667879",
  gray9: "#305050",
  gray10: "#151D1D",
  tealSolid: "#00919A",

  errorDark: "#9C271F",
  error: "#E84444",
  errorLight: "#FFE9E9",
  warningDark: "#724808",
  warning: "#FFB906",
  warningLight: "#FAF3C3",
  successDark: "#05604A",
  success: "#1B9376",
  successLight: "#E6F7EA",
  infoDark: "#043E75",
  info: "#1E6FBB",
  infoLight: "#BDD9FA",

  data1: "#3B3F9E",
  data2: "#2CB6A4",
  data3: "#7A81FC",
  data4: "#FCB446",
  data5: "#3E8571",
  data6: "#3ECEFF",
  data7: "#B24888",
  data8: "#A9CC58",
  data9: "#8F5BB8",
  data10: "#FE6D68",
};

export const GVDSComponentColors = {
  formDisabledBackgroundColor: GVDSColors.gray3,
  formDisabledTextColor: GVDSColors.gray6,
};
