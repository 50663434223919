import axios from "axios";
import { API_URL } from "../config/api-config";

export default class AnnouncementService {
  static getConfig = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/admin/announcements/config"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static sendTestEmail = async (subject, announcement_body) => {
    try {
      const payload = {
        subject,
        announcement_body,
      };

      const response = await axios.post(
        API_URL + "/api/v1/admin/announcements/test_email",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static sendAnnouncement = async (
    announcement_target_type = null,
    announcement_target_ids = null,
    subject,
    announcement_body
  ) => {
    try {
      const payload = {
        announcement_target_type,
        announcement_target_ids,
        subject,
        announcement_body,
      };

      const response = await axios.post(
        API_URL + "/api/v1/admin/announcements",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
