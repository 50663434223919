import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import withAuthentication from "../../HOC/withAuthentication";
import FacilityService from "../../../services/FacilityService";
import LoadingSpinner from "../../common/LoadingSpinner";
import { getViewSiteDetailsPath } from "../../../config/ROUTES_NAME";
import ViewFacilityInformation from "./ViewFacilityInformation";
import { InventoryIcon } from "../../common/Inventory/InventoryDisplay";
import PermissionsContext from "../../../context/PermissionsContext";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconTrash } from "@tabler/icons-react";

const ViewFacilityDetails = () => {
  let history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const permissionsCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);

  const { siteId, facilityId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [facilityDetails, setFacilityDetails] = useState({});

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const cancelDeletePrompt = () => {
    setShowDeletePrompt(false);
  };

  const loadFacility = () => {
    setIsLoading(true);
    FacilityService.getFacility(facilityId)
      .then((data) => {
        setFacilityDetails(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load facility details.</span>
        );
      });
  };

  useEffect(() => {
    loadFacility();
  }, [facilityId]);

  const goToSite = () => {
    history.push(getViewSiteDetailsPath(siteId));
  };

  const deleteFacility = () => {
    FacilityService.deleteFacility(RESOURCES.SITE, siteId, facilityId)
      .then(() => {
        toastContext.addSuccessToast(<span>Facility delete successfully</span>);
        userInventory.loadUserInventory();
        goToSite();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toastContext.addFailToast(<span>{err.response.data.message}</span>);
        } else {
          toastContext.addFailToast(<span>Failed to delete facility</span>);
        }
      });
  };

  if (isLoading) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton text="Return to Site" onClick={goToSite} />
        <PageHeader.Title>
          <h1>
            <div className="me-2 pt-1 d-inline-flex align-items-center">
              {/* TODO */}
              {InventoryIcon("facility")}
            </div>
            {facilityDetails.name}
          </h1>
          <Spacer />
          {!permissionsCtx.isLoadingPermissions &&
            permissionsCtx.permissions[
              PERMISSIONS.SITE_FACILITY_MANAGEMENT
            ] && (
              <Dropdown>
                <Dropdown.Toggle as={OtherActionDropdownToggle} />

                <Dropdown.Menu>
                  <Dropdown.Item
                    key="delete"
                    href="#"
                    className="danger"
                    onClick={() => setShowDeletePrompt(true)}
                  >
                    <GVDSIcon Icon={IconTrash} /> Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
        </PageHeader.Title>
      </PageHeader>
      <ViewFacilityInformation
        facilityDetails={facilityDetails}
        onChange={loadFacility}
      />

      <GVDSModal
        show={showDeletePrompt}
        onHide={cancelDeletePrompt}
        title="Delete Facility"
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          Deleting a facility will delete all environmental and operational data
          at that facility. This action cannot be undone. Are you sure?
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancelDeletePrompt}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={() => deleteFacility()}
            disabled={isLoading}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </Container>
  );
};

export default withAuthentication(ViewFacilityDetails);
