import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

const getDefaultValueIfNull = (value, defaultValue) => {
  return value === null ? defaultValue : value;
};

const ActivityLogDetailsFieldWithChanges = ({
  label,
  value,
  changes,
  defaultValue = "-",
  lineThroughValueDisplay,
}) => {
  let valueDisplay;

  if (changes) {
    valueDisplay = (
      <>
        <span className="text-decoration-line-through gvds-color--gray6">
          {getDefaultValueIfNull(changes[0], defaultValue)}
        </span>{" "}
        -> <span>{getDefaultValueIfNull(changes[1], defaultValue)}</span>
      </>
    );
  } else {
    if (lineThroughValueDisplay) {
      valueDisplay = (
        <span className="text-decoration-line-through gvds-color--gray6">
          {getDefaultValueIfNull(value, defaultValue)}
        </span>
      );
    } else {
      valueDisplay = <span>{getDefaultValueIfNull(value, defaultValue)}</span>;
    }
  }
  return (
    <Row>
      <Col sm={3}>
        <div className="gvds-text--inputField">{label}</div>
      </Col>
      <Col sm={9} className="text-break">
        {valueDisplay}
      </Col>
    </Row>
  );
};

export default ActivityLogDetailsFieldWithChanges;
