import React, { useContext, useState } from "react";
import GVDSModal from "../Modals/GVDSModal";
import GVDSButton, { buttonVariant } from "../Buttons/GVDSButton";
import GuidedTourContext from "../../components/FloatingAssistant/GuidedTour/GuidedTourContext";
import moment from "moment";
import UserProfileContext from "../../context/UserProfileContext";
import { FEATURE_FLAG_NEW_DESIGN_POP_UP_LAST_SHOW_DATE } from "../../config/feature-flag-config";
import { localStorageNewDesignPopUpKey } from "../../config/constants";

const responseOptions = {
  takeTour: "takeTour",
  dismiss: "dismiss",
};

const isShowNewDesignPopUp = (userEmail) => {
  const lastShowIsoDate = FEATURE_FLAG_NEW_DESIGN_POP_UP_LAST_SHOW_DATE;

  const isStillShowTime =
    !lastShowIsoDate || moment(lastShowIsoDate).toDate() > new Date();

  if (!isStillShowTime) {
    return false;
  }

  const savedValues = JSON.parse(
    localStorage.getItem(localStorageNewDesignPopUpKey)
  );

  return !savedValues || !savedValues[userEmail];
};

const NewDesignPopUp = () => {
  const guidedTourContext = useContext(GuidedTourContext);
  const [show, setShow] = useState(true);
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const prepareSavedValues = (responseOption) => {
    let savedValues = JSON.parse(
      localStorage.getItem(localStorageNewDesignPopUpKey)
    );
    if (!savedValues) {
      savedValues = {};
    }
    savedValues[user.email] = {
      response: responseOption,
      timestamp: new Date(),
    };

    return savedValues;
  };

  const dismiss = () => {
    localStorage.setItem(
      localStorageNewDesignPopUpKey,
      JSON.stringify(prepareSavedValues(responseOptions.dismiss))
    );
    setShow(false);
  };

  const takeTour = () => {
    localStorage.setItem(
      localStorageNewDesignPopUpKey,
      JSON.stringify(prepareSavedValues(responseOptions.takeTour))
    );
    setShow(false);
    guidedTourContext.startGuidedTour();
  };

  if (!isShowNewDesignPopUp(user?.email)) {
    return null;
  } else {
    return (
        <GVDSModal
            size={GVDSModal.Size.small}
            title="Welcome to Greenview Portal!"
            show={show}
            onHide={dismiss}
        >
          <GVDSModal.Body>
            <div>
              As you embark your journey with us, feel free to explore our
              platform's features and capabilities. We're here to support your
              success every step of the way.
              <br/>
              <br/>
              Let’s take a moment to show you around with a short guided tour!
            </div>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
                variant={buttonVariant.primary}
                text="Let's Go"
                onClick={takeTour}
            />
          </GVDSModal.Footer>
        </GVDSModal>
    );
  }
};

export default NewDesignPopUp;
