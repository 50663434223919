import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  getRedirectURLWithCurrentParam,
  getURLWithPageParams,
} from "../common/QueryHandler";
import {
  GOALS_AND_TARGETS,
  REPORT_INITIATIVES_REPORT,
  REPORT_PERFORMANCE_REPORT,
  REPORT_STANDARD_REPORT,
} from "../../config/ROUTES_NAME";
import TargetService from "../../services/TargetService";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  DateTimeUtils,
  NumberService,
  StringUtils,
} from "../../services/UtilsService";
import {
  DATA_STATUS,
  PERMISSIONS,
  RESOURCES,
  TARGET_PERIOD_TYPE,
  TARGET_STATUS,
  TARGET_TOPIC,
} from "../../config/constants";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import SafeDataService from "../../services/SafeDataService";
import MissingValueWarningIcon from "../common/MissingValueWarningIcon";
import InputTargetModal from "./InputTargetModal";
import GoalService from "../../services/GoalService";
import InfoTooltip from "../common/Tooltip/InfoTooltip";
import UNSDGService from "../../services/UNSDGService";
import GVFormGroup from "../common/GVFormGroup";
import PermissionsContext from "../../context/PermissionsContext";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormTextArea from "../../gvds-components/Forms/GVDSFormTextArea";
import MultiLineTextDisplay from "../common/MultiLineTextDisplay";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import Spacer from "../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import WorkspaceInventoryIcon from "../../gvds-components/common/WorkspaceInventoryIcon";
import {
  IconEdit,
  IconFile,
  IconFlag,
  IconHourglassHigh,
  IconMapPinFilled,
  IconTargetArrow,
  IconTrash,
} from "@tabler/icons-react";
import PopoverOnBigNumberTruncated from "../common/Tooltip/PopoverOnBigNumberTruncated";

const EditTargetModal = (props) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedInventory?.id) {
      setIsLoading(true);
      GoalService.getAll(selectedInventory.type, selectedInventory.id)
        .then((data) => {
          setGoals(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load goals. Please try again.</span>
          );
        });
    }
  }, [selectedInventory]);

  if (isLoading) {
    return (
      <GVDSModal
        title={"Edit Target"}
        size={GVDSModal.Size.small}
        show={props.show}
      >
        <GVDSModal.Body>
          <LoadingSpinner />
        </GVDSModal.Body>
      </GVDSModal>
    );
  }
  return <InputTargetModal goals={goals} {...props} />;
};

const DeleteTargetModal = ({ show, close, targetId }) => {
  const history = useHistory();
  const location = useLocation();
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    if (!isLoading) {
      close();
    }
  };

  const deleteTarget = () => {
    if (selectedInventory?.id) {
      setIsLoading(true);
      TargetService.deleteTarget(
        selectedInventory.type,
        selectedInventory.id,
        targetId
      )
        .then(() => {
          toastContext.addSuccessToast(
            "Your target has been deleted successfully."
          );
          history.push(
            getRedirectURLWithCurrentParam(GOALS_AND_TARGETS, location)
          );
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to delete target. Please try again.</span>
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <GVDSModal
      title="Delete Target"
      size={GVDSModal.Size.small}
      show={show}
      onHide={onClose}
    >
      <GVDSModal.Body>
        <div>
          Comments will be deleted along with this target. This will not affect
          the inputted data in the portal.
        </div>
        <br />
        <div>Are you sure?</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onClose}
          text="Cancel"
          disabled={isLoading}
        />
        <GVDSButton
          className="delete-target-button"
          variant={buttonVariant.destructive_primary}
          onClick={deleteTarget}
          text="Yes, Delete"
          disabled={isLoading}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const MarkAsClosedModal = ({ show, close, proceedToCloseTarget }) => {
  const onTargetClose = () => {
    proceedToCloseTarget();
    close();
  };

  return (
    <GVDSModal
      title="Close Target"
      size={GVDSModal.Size.small}
      show={show}
      onHide={close}
    >
      <GVDSModal.Body>
        <div>
          Closed targets are hidden by default on the main Goals & Targets page.
        </div>
        <br />
        <div>You can re-open a target anytime.</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={close}
          text="Cancel"
        />
        <GVDSButton
          className="delete-target-button"
          variant={buttonVariant.primary}
          onClick={onTargetClose}
          text="Mark as Closed"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const getTargetBoxValueDisplay = (value, precision) => {
  if (value === null || value === undefined) {
    return null;
  }

  const safeValue = SafeDataService.getValue(value);

  const isValueNumeric = NumberService.isNumber(safeValue);

  if (!isValueNumeric) {
    return safeValue;
  } else {
    const valueToPrecision = NumberService.toPrecision(safeValue, precision);

    return (
      <PopoverOnBigNumberTruncated
        numberValue={valueToPrecision}
        contentClassName="target-value__value"
        maximumFractionDigits={precision}
        minimumFractionDigits={precision}
      />
    );
  }
};

const ViewTargetDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const { targetId } = useParams();
  const [target, setTarget] = useState({});
  const [config, setConfig] = useState({});
  const [targetDetails, setTargetDetails] = useState({});
  const [comment, setComment] = useState("");
  const [showEditComment, setShowEditComment] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showMarkAsClosed, setShowMarkAsClosed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const isAllowedToEditTarget =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.GOALS_N_TARGETS_EDIT];
  const isAllowedToDeleteTarget =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.GOALS_N_TARGETS_DELETE];

  const loadTarget = () => {
    if (targetId && selectedInventory?.id) {
      setIsLoading(true);
      setIsLoadingDetails(true);
      TargetService.getTarget(
        targetId,
        selectedInventory.type,
        selectedInventory.id
      )
        .then((data) => {
          setIsLoading(false);
          setTarget(data);
          setComment(data.comment);
          TargetService.getDetails(
            targetId,
            data.currentDateForCalculation,
            selectedInventory.type,
            selectedInventory.id
          )
            .then((data) => {
              setIsLoadingDetails(false);
              setTargetDetails(data);
            })
            .catch(() => {
              setIsLoadingDetails(false);
              toastContext.addFailToast(
                "Failed to load target details. Please try again."
              );
            });
        })
        .catch((e) => {
          if (e.status === 404) {
            backToAllGoalsAndTargets(false);
          } else {
            setIsLoading(false);
            toastContext.addFailToast(
              "Failed to load target. Please try again."
            );
          }
        });
    }
  };

  const backToAllGoalsAndTargets = (saveInBrowserHistory = true) => {
    if (saveInBrowserHistory) {
      history.push(getRedirectURLWithCurrentParam(GOALS_AND_TARGETS, location));
    } else {
      history.replace(
        getRedirectURLWithCurrentParam(GOALS_AND_TARGETS, location)
      );
    }
  };

  useEffect(() => {
    if (
      target.resourceId &&
      selectedInventory.id &&
      selectedInventory.id !== target.resourceId
    ) {
      backToAllGoalsAndTargets(false);
    } else {
      loadTarget();
    }
  }, [targetId, selectedInventory]);

  useEffect(() => {
    if (selectedInventory?.id && selectedInventory?.type === RESOURCES.SITE) {
      setIsLoadingConfig(true);
      TargetService.getConfig(selectedInventory.type, selectedInventory.id)
        .then((data) => {
          setConfig(data);
          setIsLoadingConfig(false);
        })
        .catch(() => {
          setIsLoadingConfig(false);
          toastContext.addFailToast(
            <span>Failed to load target configuration.</span>
          );
        });
    }
  }, [selectedInventory]);

  const redirectToReport = (reportPath, paramsState) => {
    history.push(
      getRedirectURLWithCurrentParam(reportPath, location),
      paramsState
    );
  };

  const updateTargetStatus = (newStatus) => {
    TargetService.patchTarget(
      targetId,
      { status: newStatus },
      selectedInventory.type,
      selectedInventory.id
    ).then((data) => {
      toastContext.addSuccessToast(
        "Your target status has been updated successfully."
      );
      setTarget(data);
    });
  };

  const updateTargetComment = (e) => {
    e.preventDefault();
    TargetService.patchTarget(
      targetId,
      { comment },
      selectedInventory.type,
      selectedInventory.id
    ).then((data) => {
      toastContext.addSuccessToast("Your comment has been saved successfully.");
      setShowEditComment(false);
      setTarget(data);
    });
  };

  const getComment = () => {
    if (showEditComment) {
      return (
        <Form className="mt-2">
          <GVFormGroup controlId="targetComment">
            <GVDSFormTextArea
              className="target-comment-input"
              rows={3}
              value={comment}
              onInput={(value) => setComment(value)}
            />
          </GVFormGroup>
          <div className="d-flex justify-content-end">
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={() => {
                setShowEditComment(false);
                setComment(target.comment);
              }}
              text="Cancel"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={updateTargetComment}
              text="Save"
            />
          </div>
        </Form>
      );
    }
    if (comment) {
      return (
        <div className="target-details__comment-container">
          <MultiLineTextDisplay>{comment}</MultiLineTextDisplay>
        </div>
      );
    }
    return (
      <div className="target-details__comment-container">
        No comment written. Click “Edit Comment” to write additional context and
        information related to the target.
      </div>
    );
  };

  const getCreatedBy = () => {
    return `${
      target.collectiveTargetInfo.created_by.full_name ??
      target.collectiveTargetInfo.created_by.email
    } (${DateTimeUtils.formatLocalDate(
      target.collectiveTargetInfo.created_on
    )})`;
  };

  const getTargetDetailView = () => {
    if (isLoadingConfig) {
      return <LoadingSpinner />;
    } else if (
      target.topic === TARGET_TOPIC.ENVIRONMENTAL &&
      !config["environmental_subtopics"]?.find(
        (subtopic) => subtopic.name === target.params?.subtopic_name
      )
    ) {
      return (
        <div className="view-target-details__no-meter-setup">
          <GVDSIcon Icon={IconFile} className="gvds-empty-content--icon" />
          <h3>Environmental meter not set up</h3>
          <div>
            To view the Target Summary, set up your environmental meter for{" "}
            {target.params?.subtopic_name} and input your data in Data
            Management.
          </div>
        </div>
      );
    } else if (target.showCalculatedValue) {
      return (
        <>
          <h2>Target Summary</h2>
          <Row>
            <Col sm={8}>
              <Row className="mb-4">
                <Col sm={8}>
                  <div
                    className={
                      "target-summary-box d-flex flex-column justify-content-between " +
                      (targetDetails.isTargetAchieved
                        ? "target-summary-box--with-medal"
                        : "")
                    }
                  >
                    <div>
                      <div className="body-1 d-flex justify-content-between align-items-center">
                        <span>Performance Summary</span>
                        {targetDetails.currentValue?.status ===
                          DATA_STATUS.MISSING && <MissingValueWarningIcon />}
                      </div>
                      <div className="body-2 mt-2">{targetDetails.summary}</div>
                    </div>
                    <div className="d-flex align-items-center">
                      {targetDetails.isTargetAchieved && (
                        <>
                          <StatusLabel color={StatusLabel.Colors.green}>
                            Achieved
                          </StatusLabel>
                          <div className="gvds-text--caption ms-1 me-1">
                            for{" "}
                            {DateTimeUtils.formatLocalPeriod(
                              targetDetails.currentValueStartDate,
                              targetDetails.currentValueEndDate
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="target-summary-box">
                    <div className="d-flex align-items-center">
                      <GVDSIcon Icon={IconHourglassHigh} />
                      <div className="gvds-text--formLabel me-1">
                        Time remaining
                      </div>
                      <InfoTooltip info="The amount of time between next month or the target’s start date (whichever comes later) and the target’s end date." />
                    </div>
                    <h3 className="mt-2 mb-4">{target.timeRemaining}</h3>
                    {target.periodType === TARGET_PERIOD_TYPE.TOTAL && (
                      <div className="gvds-text--caption">
                        approx. {target.timePassed} elapsed
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                {targetDetails.comparisonValue && (
                  <Col sm={4}>
                    <div className="target-summary-box">
                      <div className="d-flex align-items-center">
                        <GVDSIcon Icon={IconFlag} />
                        <div className="gvds-text--formLabel">
                          Comparable Value
                        </div>
                        {targetDetails.comparisonValue?.status ===
                          DATA_STATUS.MISSING && (
                          <div className="ms-auto">
                            <MissingValueWarningIcon />
                          </div>
                        )}
                      </div>
                      <div>({target.comparisonYear})</div>
                      <h1 className="mt-2 target-value__container">
                        {getTargetBoxValueDisplay(
                          targetDetails.comparisonValue,
                          targetDetails.precision
                        )}{" "}
                        <div className="gvds-text--body ms-1">
                          {target.unitId}
                        </div>
                      </h1>
                      {target.intensityMetricPerLabel && (
                        <div className="gvds-text--body">
                          {target.intensityMetricPerLabel}
                        </div>
                      )}
                    </div>
                  </Col>
                )}
                <Col sm={4}>
                  <div className="target-summary-box">
                    <div className="d-flex align-items-center">
                      <GVDSIcon Icon={IconMapPinFilled} />
                      <div className="gvds-text--formLabel">Current Value</div>
                      {targetDetails.currentValue?.status ===
                        DATA_STATUS.MISSING && (
                        <div className="ms-auto">
                          <MissingValueWarningIcon />
                        </div>
                      )}
                    </div>
                    <div>({target.currentValuePeriodLabel})</div>
                    <h1 className="mt-2 target-value__container">
                      {isLoadingDetails ? (
                        <div style={{ height: "8px" }}>
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <>
                          {getTargetBoxValueDisplay(
                            targetDetails.currentValue,
                            targetDetails.precision
                          )}
                        </>
                      )}{" "}
                      <div className="gvds-text--body ms-1">
                        {target.unitId}
                      </div>
                    </h1>
                    {target.intensityMetricPerLabel && (
                      <div className="gvds-text--body">
                        {target.intensityMetricPerLabel}
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="target-summary-box">
                    <div className=" d-flex align-items-center">
                      <GVDSIcon Icon={IconTargetArrow} />
                      <div className="gvds-text--formLabel">Target</div>
                      {targetDetails.targetValue?.status ===
                        DATA_STATUS.MISSING && (
                        <div className="ms-auto">
                          <MissingValueWarningIcon />
                        </div>
                      )}
                    </div>
                    <div>({target.targetValuePeriodLabel})</div>
                    <h1 className="mt-2 target-value__container">
                      {isLoadingDetails ? (
                        <div style={{ height: "8px" }}>
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <>
                          {getTargetBoxValueDisplay(
                            targetDetails.targetValue,
                            targetDetails.precision
                          )}
                        </>
                      )}{" "}
                      <div className="gvds-text--body ms-1">
                        {target.unitId}
                      </div>
                    </h1>
                    {target.intensityMetricPerLabel && (
                      <div className="gvds-text--body">
                        {target.intensityMetricPerLabel}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                {targetDetails.comparisonValue && (
                  <Col sm={4}>
                    <div className="target-summary-box">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="gvds-text--formLabel">
                          Current value against comparable
                        </span>
                        {targetDetails.comparableDiff?.status ===
                          DATA_STATUS.MISSING && <MissingValueWarningIcon />}
                      </div>
                      <h1 className="mt-2 target-value__container">
                        {getTargetBoxValueDisplay(
                          targetDetails.comparableDiff,
                          targetDetails.precision
                        )}{" "}
                        <div className="gvds-text--body ms-1">
                          {target.usePercentageChange ? "%" : target.unitId}
                        </div>
                      </h1>
                      {!target.usePercentageChange &&
                        target.intensityMetricPerLabel && (
                          <div className="gvds-text--body">
                            {target.intensityMetricPerLabel}
                          </div>
                        )}
                    </div>
                  </Col>
                )}
                <Col sm={4}>
                  <div className="target-summary-box">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="gvds-text--formLabel">
                        Current value against target
                      </span>
                      {targetDetails.targetDiff?.status ===
                        DATA_STATUS.MISSING && <MissingValueWarningIcon />}
                    </div>
                    <h1 className="mt-2 target-value__container">
                      {getTargetBoxValueDisplay(
                        targetDetails.targetDiff,
                        targetDetails.precision
                      )}{" "}
                      <div className="gvds-text--body ms-1">
                        {target.usePercentageChange ? "%" : target.unitId}
                      </div>
                    </h1>
                    {!target.usePercentageChange &&
                      target.intensityMetricPerLabel && (
                        <div className="gvds-text--body">
                          {target.intensityMetricPerLabel}
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
              {target.showProgress && (
                <Row>
                  <Col>
                    <div
                      className="target-summary-box"
                      style={{ height: "100px" }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <span>Progress to date</span>
                        {targetDetails.progress?.status ===
                          DATA_STATUS.MISSING && <MissingValueWarningIcon />}
                      </div>
                      {isLoadingDetails || !targetDetails.progress ? (
                        <LoadingSpinner />
                      ) : (
                        <div className="target-details__progress">
                          <ProgressBar
                            className="color-green"
                            now={SafeDataService.getValue(
                              targetDetails.progress
                            )}
                          />
                          <div className="ms-1">
                            <div className="gv-body-2--bold">
                              {NumberService.format(
                                SafeDataService.getValue(
                                  targetDetails.progress
                                ),
                                0
                              )}
                              %
                            </div>
                            <div className="caption">
                              {targetDetails.currentValue &&
                                NumberService.format(
                                  SafeDataService.getValue(
                                    targetDetails.currentValue,
                                    targetDetails.precision,
                                    targetDetails.precision
                                  )
                                )}{" "}
                              {target.unitId}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col sm={4}>
              <div className="target-details__run-report-container">
                <h3>For more in-depth analysis & calculations</h3>
                {isLoadingDetails && <LoadingSpinner />}
                {!isLoadingDetails &&
                  target.topic === TARGET_TOPIC.ENVIRONMENTAL && (
                    <>
                      <div>
                        The Portal has reports that can show you year on year
                        changes and monthly breakdowns.
                      </div>
                      <div className="mt-3 d-flex flex-column align-items-center">
                        <GVDSButton
                          variant={buttonVariant.primary}
                          onClick={() =>
                            window.open(
                              getURLWithPageParams(
                                location,
                                REPORT_PERFORMANCE_REPORT,
                                targetDetails.reportParams?.performance
                              ),
                              "_blank"
                            )
                          }
                          text="Run Performance Report"
                        />
                        <GVDSButton
                          className="mt-2"
                          variant={buttonVariant.primary}
                          onClick={() =>
                            window.open(
                              getURLWithPageParams(
                                location,
                                REPORT_STANDARD_REPORT,
                                targetDetails.reportParams?.standard
                              ),
                              "_blank"
                            )
                          }
                          text="Run Standard Report"
                        />
                      </div>
                    </>
                  )}
                {!isLoadingDetails && target.topic === TARGET_TOPIC.SOCIAL && (
                  <>
                    <div>
                      The Portal has reports that can show your initiative
                      breakdowns.
                    </div>
                    <div className="mt-3">
                      <GVDSButton
                        variant={buttonVariant.primary}
                        onClick={() =>
                          window.open(
                            getURLWithPageParams(
                              location,
                              REPORT_INITIATIVES_REPORT,
                              targetDetails.reportParams?.initiative
                            ),
                            "_blank"
                          )
                        }
                        text="Run Initiative Report"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="target-details__market-purchase-message">
                {target.params["use_location_based"] === true && (
                  <>
                    Location-based emissions target values are used for the
                    selected performance group, calculated using system default
                    emission factors (i.e. national grid-average emission
                    factor).
                  </>
                )}
                {target.params["use_location_based"] === false && (
                  <>
                    Market-based emissions target values are used for the
                    selected performance group, calculated with market purchases
                    and custom emission factors considered.
                  </>
                )}
              </div>
            </Col>
          </Row>
          <hr />
        </>
      );
    }
  };

  let content;
  let title;
  let status;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    title = (
      <div className="flex-grow-1">
        {target.isFromCollectiveTarget && (
          <StatusLabel color={StatusLabel.Colors.gray}>
            <GVDSIcon Icon={WorkspaceInventoryIcon[RESOURCES.PORTFOLIO]} />
            Shared Target
          </StatusLabel>
        )}
        {!target.isFromCollectiveTarget && (
          <div className="gvds-text--formLabel gvds-color--gray6 mt-2 mb-1">
            {target.goal?.name ? target.goal.name : "Not associated to a goal"}
          </div>
        )}
        <div className="d-flex">
          <h1>{target.name}</h1>
          {target?.id &&
            (isAllowedToDeleteTarget || isAllowedToEditTarget) &&
            !target.isFromCollectiveTarget && (
              <>
                <Spacer />
                <div className="d-flex justify-content-end align-items-center">
                  {isAllowedToEditTarget && (
                    <GVDSButton
                      className="show-edit-target-button"
                      variant={buttonVariant.secondary}
                      disabled={isLoading}
                      onClick={() => setShowEdit(true)}
                      icon={<GVDSIcon Icon={IconEdit} />}
                      text="Edit"
                    />
                  )}
                  {isAllowedToDeleteTarget && (
                    <GVDSButton
                      className="show-delete-target-button"
                      variant={buttonVariant.destructive_tertiary}
                      onClick={() => setShowDelete(true)}
                      icon={<GVDSIcon Icon={IconTrash} />}
                      text="Delete"
                    />
                  )}
                </div>
              </>
            )}
        </div>
      </div>
    );
    status = (
      <div className="info-field">
        <StatusLabel
          color={
            target.status === TARGET_STATUS.OPEN
              ? StatusLabel.Colors.green
              : target.status === TARGET_STATUS.CLOSED
              ? StatusLabel.Colors.gray
              : ""
          }
        >
          {StringUtils.capitaliseWord(target.status)}
        </StatusLabel>
        {!target.isFromCollectiveTarget &&
          target.status === TARGET_STATUS.OPEN &&
          isAllowedToEditTarget && (
            <GVDSButton
              className="ms-2 target-mark-as-closed-button"
              variant={buttonVariant.secondary}
              onClick={() => setShowMarkAsClosed(true)}
              text="Mark as Closed"
            />
          )}
        {!target.isFromCollectiveTarget &&
          target.status === TARGET_STATUS.CLOSED &&
          isAllowedToEditTarget && (
            <GVDSButton
              variant={buttonVariant.secondary}
              className="ms-2"
              onClick={() => updateTargetStatus(TARGET_STATUS.OPEN)}
              text="Re-open target"
            />
          )}
      </div>
    );
    content = (
      <>
        {target.targetParamDetails?.un_sdgs && (
          <div className="target-details__un-sdgs-container">
            {target.targetParamDetails.un_sdgs
              .sort((sdg1, sdg2) =>
                UNSDGService.unsdgNameSortFn(sdg1.name, sdg2.name)
              )
              .map((u, index) => (
                <div
                  className="target-details__un-sdgs-container__pill"
                  key={index}
                >
                  {u.name}
                </div>
              ))}
          </div>
        )}
        <hr />
        {getTargetDetailView()}
        {!target.isFromCollectiveTarget && (
          <>
            <div className="d-flex flex-row">
              <h2>Comment</h2>
              {!showEditComment && isAllowedToEditTarget && (
                <div className="ms-auto">
                  <GVDSButton
                    variant={buttonVariant.secondary}
                    className="edit-comment-button"
                    icon={<GVDSIcon Icon={IconEdit} />}
                    onClick={() => setShowEditComment(true)}
                    text="Edit Comment"
                  />
                </div>
              )}
            </div>
            {getComment()}
          </>
        )}
        {target.isFromCollectiveTarget && (
          <>
            <div className="mb-4">
              This target was created at a portfolio which contains this site.
              This target can only be edited or deleted at the portfolio level.
              Please contact the user in charge of the portfolio for more
              information about this target.
            </div>
            <div className="caption">
              Created under portfolio:{" "}
              {target.collectiveTargetInfo.portfolio_name}
            </div>
            <div className="caption">Created by: {getCreatedBy()}</div>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="target-details__container">
        <PageHeader>
          <PageHeader.BackButton
            text="Return to previous page"
            onClick={backToAllGoalsAndTargets}
          />
          <PageHeader.Title>{title}</PageHeader.Title>
          <PageHeader.Status>{status}</PageHeader.Status>
        </PageHeader>
        {content}
      </div>
      <DeleteTargetModal
        show={showDelete}
        close={() => setShowDelete(false)}
        targetId={target.id}
      />
      <EditTargetModal
        existingTarget={target}
        show={showEdit}
        setShow={setShowEdit}
        onChange={loadTarget}
      />
      <MarkAsClosedModal
        show={showMarkAsClosed}
        close={() => setShowMarkAsClosed(false)}
        proceedToCloseTarget={() => updateTargetStatus(TARGET_STATUS.CLOSED)}
      />
    </>
  );
};

export default ViewTargetDetails;
