import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { uniqueId } from "lodash";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";

import {
  BEST_PRACTICE_ADOPTION_LEVEL,
  BEST_PRACTICE_QUESTION_STATUS,
  BEST_PRACTICE_QUESTION_TYPE,
  new_entry_prefix,
  tobe_deleted_entry_prefix,
} from "../../../config/constants";
import { SYSTEM_TOOLBOX_BEST_PRACTICES_LIST } from "../../../config/ROUTES_NAME";
import BestPracticeAdminService from "../../../services/BestPracticeAdminService";
import ToastContext from "../../../context/ToastContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import BestPracticeCategoryService from "../../../services/ReferenceDataServices/BestPracticeCategoryService";
import GVFormGroup from "../../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormMultiSelect from "../../../gvds-components/Forms/GVDSFormMultiSelect";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../services/UtilsService";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import {
  IconCircleArrowDown,
  IconCircleArrowUp,
  IconCirclePlus,
  IconTrash,
} from "@tabler/icons-react";

const questionTypeOptions = [
  {
    value: BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE,
    label: "Multiple Choice",
  },
  { value: BEST_PRACTICE_QUESTION_TYPE.CHECKBOXES, label: "Checkboxes" },
];

const getQuestionTypeOptionByValue = (optionValue) =>
  questionTypeOptions.find(
    (questionTypeOption) => questionTypeOption.value === optionValue
  );

class BestPracticeOption {
  constructor(id = uniqueId(new_entry_prefix), name, adoptionLevel) {
    this.id = id;
    this.name = name;
    this.adoptionLevel = adoptionLevel;
  }

  isNewAndEmpty = () => {
    return this.id.startsWith(new_entry_prefix) && !this.name;
  };

  isInvalid = () => {
    return this.hasNoName() || this.hasNoLevel();
  };

  hasNoName = () => {
    return !this.name || this.name.length === 0;
  };

  hasNoLevel = () => {
    return !this.adoptionLevel || this.adoptionLevel.length === 0;
  };

  withName(name) {
    this.name = name;
    return this;
  }

  withLevel(adoptionLevel) {
    this.adoptionLevel = adoptionLevel;
    return this;
  }

  static fromJson(optionJson) {
    return new BestPracticeOption(
      optionJson["id"],
      optionJson["option_text"],
      optionJson["adoption_level"]
    );
  }

  toJson = () => {
    return { id: this.id, name: this.name, adoption_level: this.adoptionLevel };
  };
}

const BestPracticeForm = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);
  const { bestPracticeId } = useParams();

  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [availableSiteTypes, setAvailableSiteTypes] = useState([]);

  const [isEditExisting, setIsEditExisting] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [originalQuestionText, setOriginalQuestionText] = useState("");

  const [showDeleteOptionPrompt, setShowDeleteOptionPrompt] = useState(false);
  const [optionToBeDeleted, setOptionToBeDeleted] = useState(null);
  const cancelDeleteOptionPrompt = () => {
    setShowDeleteOptionPrompt(false);
    setOptionToBeDeleted(null);
  };

  const [showDeleteQuestionPrompt, setShowDeleteQuestionPrompt] =
    useState(false);
  const cancelDeleteQuestionPrompt = () => {
    setShowDeleteQuestionPrompt(false);
  };

  const [status, setStatus] = useState(BEST_PRACTICE_QUESTION_STATUS.ACTIVE);
  const [questionText, setQuestionText] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [siteTypes, setSiteTypes] = useState([]);
  const [description, setDescription] = useState("");
  const [questionType, setQuestionType] = useState(
    BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE
  );
  const [questionOptions, setQuestionOptions] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    let loadDropdownOptions = new Promise((resolve) => resolve());
    if (availableCategories.length === 0) {
      loadDropdownOptions = new Promise((resolve) => {
        BestPracticeAdminService.getBestPracticeOptions().then((options) => {
          setAvailableCategories(options.categories);
          setAvailableTags(options.tags);
          setAvailableSiteTypes(options.site_types);
          setSiteTypes(options.site_types);

          resolve();
        });
      });
    }
    loadDropdownOptions
      .then(() => {
        if (bestPracticeId) {
          setIsEditExisting(true);
          BestPracticeAdminService.getBestPracticeQuestion(bestPracticeId).then(
            (question) => {
              setStatus(question.status);
              setOriginalQuestionText(question.question_text);
              setQuestionText(question.question_text);
              setCategories(question.categories);
              setTags(question.tags);
              setSiteTypes(question.site_types);
              setDescription(question.description);
              setQuestionType(question.question_type);
              setQuestionOptions(
                question.options.map((option) =>
                  BestPracticeOption.fromJson(option)
                )
              );
              setIsLoading(false);
            }
          );
        } else {
          setIsEditExisting(false);
          setStatus(BEST_PRACTICE_QUESTION_STATUS.ACTIVE);
          setOriginalQuestionText("New Best Practice Question");
          setQuestionText("");
          setCategories([]);
          setTags([]);
          if (availableSiteTypes.length > 0) {
            setSiteTypes(availableSiteTypes);
          }
          setDescription("");
          setQuestionType(BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE);
          setQuestionOptions([
            new BestPracticeOption(),
            new BestPracticeOption(),
          ]);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load best practice question configuration.</span>
        );
      });
  }, [bestPracticeId]);

  const isQuestionTextInvalid = () => {
    return !questionText || questionText.length === 0;
  };

  const isCategoryInvalid = () => {
    return categories.length === 0;
  };

  const isSiteTypesInvalid = () => {
    return siteTypes.length === 0;
  };

  const saveQuestion = () => {
    setIsValidated(true);

    if (
      !isQuestionTextInvalid() &&
      !isCategoryInvalid() &&
      !isSiteTypesInvalid() &&
      questionOptions.length > 0 &&
      !questionOptions.find((option) => option.isInvalid())
    ) {
      if (!isEditExisting) {
        BestPracticeAdminService.createBestPracticeQuestion(
          questionText,
          categories,
          tags,
          siteTypes,
          description,
          questionType,
          questionOptions.map((option) => option.toJson())
        )
          .then(() => {
            toastContext.addSuccessToast(
              <span>Your question was successfully created</span>
            );
            goToBestPracticeList();
          })
          .catch(() => {
            toastContext.addFailToast(
              <span>Failed to add question. Please try again.</span>
            );
          });
      } else {
        BestPracticeAdminService.editBestPracticeQuestion(
          bestPracticeId,
          questionText,
          categories,
          tags,
          siteTypes,
          description,
          questionType,
          questionOptions.map((option) => option.toJson())
        ).then(() => {
          toastContext.addSuccessToast(
            <span>Your question was successfully updated</span>
          );
          goToBestPracticeList();
        });
      }
    }
  };

  const deleteQuestion = () => {
    BestPracticeAdminService.deleteBestPracticeQuestion(bestPracticeId)
      .then(() => {
        setShowDeleteQuestionPrompt(false);
        toastContext.addSuccessToast(
          <span>Your question was successfully deleted</span>
        );
        goToBestPracticeList();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Something went wrong, please try again.</span>
        );
      });
  };

  const goToBestPracticeList = () => {
    history.push(SYSTEM_TOOLBOX_BEST_PRACTICES_LIST);
  };

  const toggleStatus = () => {
    let newStatus;

    if (status === BEST_PRACTICE_QUESTION_STATUS.ACTIVE) {
      newStatus = BEST_PRACTICE_QUESTION_STATUS.INACTIVE;
    } else {
      newStatus = BEST_PRACTICE_QUESTION_STATUS.ACTIVE;
    }

    BestPracticeAdminService.changeBestPracticeQuestionStatus(
      bestPracticeId,
      newStatus
    ).then(() => {
      setStatus(newStatus);
    });
  };

  const changeCategory = (selectedOptions) => {
    setCategories(
      selectedOptions.map((option) => {
        return { id: option.value, name: option.label };
      })
    );
  };

  const changeTags = (selected) => {
    setTags(
      selected.map((item) => {
        return { id: item.value, name: item.label };
      })
    );
  };

  const changeSiteTypes = (selected) => {
    setSiteTypes(
      selected.map((item) => {
        return { id: item.value, name: item.label };
      })
    );
  };

  const changeQuestionType = (selectedOption) => {
    setQuestionType(selectedOption.value);
  };

  const addOption = () => {
    setQuestionOptions([...questionOptions, new BestPracticeOption()]);
  };

  const deleteOption = (option) => {
    if (option.id.startsWith(new_entry_prefix)) {
      questionOptions.splice(questionOptions.indexOf(option), 1);
      setQuestionOptions([...questionOptions]);
    } else {
      setOptionToBeDeleted(option);
      setShowDeleteOptionPrompt(true);
    }
  };

  const deleteExistingOption = () => {
    optionToBeDeleted.id = tobe_deleted_entry_prefix + optionToBeDeleted.id;
    setQuestionOptions([...questionOptions]);
    setOptionToBeDeleted(null);
    setShowDeleteOptionPrompt(false);
  };

  const changeOptionName = (event, option) => {
    event.preventDefault();
    option.name = event.target.value;
    setQuestionOptions([...questionOptions]);
  };

  const changeOptionAdoptionLevel = (event, option, adoptionLevel) => {
    option.adoptionLevel = adoptionLevel;
    setQuestionOptions([...questionOptions]);
  };

  const moveOptionUp = (option) => {
    const optionIndex = questionOptions.indexOf(option);
    questionOptions[optionIndex] = questionOptions[optionIndex - 1];
    questionOptions[optionIndex - 1] = option;
    setQuestionOptions([...questionOptions]);
  };

  const moveOptionDown = (option) => {
    const optionIndex = questionOptions.indexOf(option);
    questionOptions[optionIndex] = questionOptions[optionIndex + 1];
    questionOptions[optionIndex + 1] = option;
    setQuestionOptions([...questionOptions]);
  };

  const setYesNoOptions = () => {
    const yes = new BestPracticeOption()
      .withName("Yes")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.YES);
    const noButPlanned = new BestPracticeOption()
      .withName("No, but plan to within the next 12 months")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS);
    const no = new BestPracticeOption()
      .withName("No")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.NO);
    const na = new BestPracticeOption()
      .withName("N/A")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.NA);
    setQuestionOptions([yes, noButPlanned, no, na]);
  };

  const setPercentOptions = () => {
    const percent90 = new BestPracticeOption()
      .withName("90% +")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.YES);
    const percent75to89 = new BestPracticeOption()
      .withName("75-89%")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.YES);
    const percent50to74 = new BestPracticeOption()
      .withName("50-74%")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS);
    const percent1to49 = new BestPracticeOption()
      .withName("1-49%")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS);
    const percent0 = new BestPracticeOption()
      .withName("0%")
      .withLevel(BEST_PRACTICE_ADOPTION_LEVEL.NO);

    setQuestionOptions([
      percent90,
      percent75to89,
      percent50to74,
      percent1to49,
      percent0,
    ]);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const questionOptionsWithoutDeletedOnes = questionOptions.filter(
    (option) => !option.id.startsWith(tobe_deleted_entry_prefix)
  );

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text={
            <>
              Return to Question List{" "}
              <span className="caption color-gray56 ms-1">
                (Unless saved, changes will be lost)
              </span>
            </>
          }
          onClick={goToBestPracticeList}
        />
        <PageHeader.Title>
          <h1>{originalQuestionText}</h1>
          <Spacer />
          {isEditExisting && (
            <Dropdown>
              <Dropdown.Toggle as={OtherActionDropdownToggle} />

              <Dropdown.Menu>
                <Dropdown.Item
                  key="delete"
                  href="#"
                  onClick={() => setShowDeleteQuestionPrompt(true)}
                >
                  <span className="color-red">
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                    Delete
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </PageHeader.Title>
        <PageHeader.Status>
          <div className="info-field">
            <StatusLabel
              color={
                status === BEST_PRACTICE_QUESTION_STATUS.ACTIVE
                  ? StatusLabel.Colors.green
                  : StatusLabel.Colors.gray
              }
            >
              {StringUtils.capitaliseWord(status)}
            </StatusLabel>
            {isEditExisting && (
              <GVDSButton
                variant={buttonVariant.tertiary}
                className="mark-as-active ms-2"
                onClick={toggleStatus}
                text={
                  <>
                    Mark as
                    <span className="text-capitalize ms-1">
                      {status === BEST_PRACTICE_QUESTION_STATUS.ACTIVE
                        ? BEST_PRACTICE_QUESTION_STATUS.INACTIVE
                        : BEST_PRACTICE_QUESTION_STATUS.ACTIVE}
                    </span>
                  </>
                }
              />
            )}
          </div>
        </PageHeader.Status>
      </PageHeader>
      <section className="section-box">
        <article>
          <GVFormGroup>
            <Form.Label>Question Text</Form.Label>
            <GVDSFormField
              name="question-text"
              value={questionText}
              onInput={(value, e) => {
                e.preventDefault();
                setQuestionText(value);
              }}
              statusMetadata={
                isValidated && isQuestionTextInvalid()
                  ? FormFieldStatusMetadata.getError(
                      "Please Enter Question Text."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Categories</Form.Label>
            <GVDSFormMultiSelect
              className="select__best-practice-category"
              name="category"
              placeholder="Select Category"
              value={
                categories
                  ? categories.map((category) => {
                      return { value: category.id, label: category.name };
                    })
                  : []
              }
              onSelect={changeCategory}
              options={availableCategories
                .sort((category1, category2) =>
                  BestPracticeCategoryService.bestPracticeSortFn(
                    category1.name,
                    category2.name
                  )
                )
                .map((category) => {
                  return { value: category.id, label: category.name };
                })}
              statusMetadata={
                isValidated && isCategoryInvalid()
                  ? FormFieldStatusMetadata.getError(
                      "Please Select a Category."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>
              Tags <span className="optional-form-label">(optional)</span>
            </Form.Label>
            <GVDSFormMultiSelect
              name="tags"
              value={
                tags
                  ? tags.map((tag) => {
                      return {
                        label: tag.name,
                        value: tag.id,
                      };
                    })
                  : []
              }
              onSelect={(values) => changeTags(values)}
              options={availableTags.map((tag) => {
                return {
                  label: tag.name,
                  value: tag.id,
                };
              })}
              className="tag-select"
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Applicable Site Types</Form.Label>
            <GVDSFormMultiSelect
              name="site_types"
              value={
                siteTypes
                  ? siteTypes.map((siteType) => {
                      return {
                        label: siteType.name,
                        value: siteType.id,
                      };
                    })
                  : []
              }
              onSelect={(values) => changeSiteTypes(values)}
              options={availableSiteTypes.map((siteType) => {
                return {
                  label: siteType.name,
                  value: siteType.id,
                };
              })}
              className="site-type-select"
              statusMetadata={
                isValidated && isSiteTypesInvalid()
                  ? FormFieldStatusMetadata.getError(
                      "Please select at least one site type"
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>
              Description{" "}
              <span className="optional-form-label">(optional)</span>
            </Form.Label>
            <GVDSFormField
              name="description"
              value={description}
              onInput={(value, e) => {
                e.preventDefault();
                setDescription(value);
              }}
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Question Type</Form.Label>
            <GVDSFormSingleSelect
              className="select__best-practice-question-type"
              name="question-type"
              placeholder="Select Question Type"
              value={getQuestionTypeOptionByValue(questionType)}
              onSelect={changeQuestionType}
              disabled={isEditExisting}
              options={questionTypeOptions}
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Options and Associated Adoption Level</Form.Label>
            <div className="body-1 mb-2">
              Every option must be mapped to a best practice adoption level. In
              the event of failure, adoption level will be shown as
              “UNAVAILABLE” to users. In the event of conflicting adoption
              levels, the system will prioritise in the following order N/A > No
              > In Progress > Yes.
            </div>
            <Row>
              <Col className="border-bottom border-gray" sm={6} />
              <Col
                className="d-flex align-items-center body-2-extra-bold border-bottom border-start border-end border-gray pb-2"
                sm={4}
              >
                <div className="w-25 text-center">
                  {BEST_PRACTICE_ADOPTION_LEVEL.YES}
                </div>
                <div className="w-25 text-center">
                  {BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS}
                </div>
                <div className="w-25 text-center">
                  {BEST_PRACTICE_ADOPTION_LEVEL.NO}
                </div>
                <div className="w-25 text-center">
                  {BEST_PRACTICE_ADOPTION_LEVEL.NA}
                </div>
              </Col>
              <Col className="border-bottom border-gray" sm={2} />
            </Row>
            {questionOptionsWithoutDeletedOnes.map((option, index) => {
              return (
                <Row key={`option-${option.id}`}>
                  <Col
                    className="d-flex align-items-baseline border-bottom border-gray py-2"
                    sm={6}
                  >
                    <Form.Check
                      disabled
                      label=""
                      type={
                        questionType ===
                        BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE
                          ? "radio"
                          : "checkbox"
                      }
                    />
                    <div className="w-100">
                      <GVDSFormField
                        placeholder={`Option ${index + 1}`}
                        name={`option-${index + 1}`}
                        value={option.name}
                        onInput={(value, event) =>
                          changeOptionName(event, option)
                        }
                        statusMetadata={
                          isValidated && option.hasNoName()
                            ? FormFieldStatusMetadata.getError(
                                "This field cannot be empty."
                              )
                            : FormFieldStatusMetadata.getDefault()
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    className="d-flex flex-column border-bottom border-start border-end border-gray py-2"
                    sm={4}
                  >
                    <div className="d-flex flex-grow-1 align-items-center">
                      <div className="flex-grow-1 d-flex justify-content-center">
                        <Form.Check
                          label=""
                          type="radio"
                          data-cy="best-practice-option-adoption-level__yes"
                          checked={
                            option.adoptionLevel ===
                            BEST_PRACTICE_ADOPTION_LEVEL.YES
                          }
                          onChange={(event) =>
                            changeOptionAdoptionLevel(
                              event,
                              option,
                              BEST_PRACTICE_ADOPTION_LEVEL.YES
                            )
                          }
                        />
                      </div>
                      <div className="flex-grow-1 d-flex justify-content-center">
                        <Form.Check
                          label=""
                          type="radio"
                          data-cy="best-practice-option-adoption-level__in-progress"
                          checked={
                            option.adoptionLevel ===
                            BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS
                          }
                          onChange={(event) =>
                            changeOptionAdoptionLevel(
                              event,
                              option,
                              BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS
                            )
                          }
                        />
                      </div>
                      <div className="flex-grow-1 d-flex justify-content-center">
                        <Form.Check
                          label=""
                          type="radio"
                          data-cy="best-practice-option-adoption-level__no"
                          checked={
                            option.adoptionLevel ===
                            BEST_PRACTICE_ADOPTION_LEVEL.NO
                          }
                          onChange={(event) =>
                            changeOptionAdoptionLevel(
                              event,
                              option,
                              BEST_PRACTICE_ADOPTION_LEVEL.NO
                            )
                          }
                        />
                      </div>
                      <div className="flex-grow-1 d-flex justify-content-center">
                        <Form.Check
                          label=""
                          type="radio"
                          data-cy="best-practice-option-adoption-level__na"
                          checked={
                            option.adoptionLevel ===
                            BEST_PRACTICE_ADOPTION_LEVEL.NA
                          }
                          onChange={(event) =>
                            changeOptionAdoptionLevel(
                              event,
                              option,
                              BEST_PRACTICE_ADOPTION_LEVEL.NA
                            )
                          }
                        />
                      </div>
                    </div>
                    {isValidated && option.hasNoLevel() && (
                      <div className="manual-invalid-feedback ms-2">
                        Select an adoption level for this option.
                      </div>
                    )}
                  </Col>
                  <Col
                    className="d-flex align-items-center border-bottom border-gray py-2"
                    sm={2}
                  >
                    <Button
                      size="sm"
                      variant="link"
                      disabled={
                        index === 0 &&
                        questionOptionsWithoutDeletedOnes.length > 1
                      }
                      onClick={() => moveOptionUp(option)}
                    >
                      <GVDSIcon Icon={IconCircleArrowUp} />
                    </Button>
                    <Button
                      size="sm"
                      variant="link"
                      disabled={
                        index === questionOptionsWithoutDeletedOnes.length - 1
                      }
                      onClick={() => moveOptionDown(option)}
                    >
                      <GVDSIcon Icon={IconCircleArrowDown} />
                    </Button>
                    <Button
                      size="sm"
                      variant="link"
                      className="link-danger"
                      onClick={() => deleteOption(option)}
                    >
                      <GVDSIcon Icon={IconTrash} />
                    </Button>
                  </Col>
                </Row>
              );
            })}
            {isValidated && questionOptionsWithoutDeletedOnes.length === 0 && (
              <div className="manual-invalid-feedback">
                Need to have some options
              </div>
            )}

            <div className="d-flex align-items-center mt-3">
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={addOption}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text="Add Option"
              />
              {!questionOptions.find((option) => !option.isNewAndEmpty()) &&
                questionType ===
                  BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE && (
                  <>
                    <div className="color-gray56">or populate with</div>
                    <Button size="sm" variant="link" onClick={setYesNoOptions}>
                      Y/N options
                    </Button>
                    <div className="color-gray56">or</div>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={setPercentOptions}
                    >
                      % options
                    </Button>
                  </>
                )}
            </div>
          </GVFormGroup>
        </article>
      </section>

      <div className="d-flex justify-content-end">
        <GVDSButton
          variant={buttonVariant.tertiary}
          className="cancel-best-practice-input"
          onClick={goToBestPracticeList}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={saveQuestion}
          text="Save"
        />
      </div>

      <GVDSModal
        show={showDeleteOptionPrompt}
        onHide={cancelDeleteOptionPrompt}
        title="Existing responses will be deleted along with this option."
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          <p>
            Responses may have already been collected for this choice. Deleting{" "}
            <strong>{optionToBeDeleted?.name}</strong> will also delete all
            existing responses of <strong>{optionToBeDeleted?.name}</strong>{" "}
            permanently.
          </p>
          <p>This action cannot be undone. Are you sure?</p>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancelDeleteOptionPrompt}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteExistingOption}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <GVDSModal
        show={showDeleteQuestionPrompt}
        onHide={cancelDeleteQuestionPrompt}
        title="Existing responses will be deleted along with this question."
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          <p>
            Responses may have already been collected for this choice. Deleting
            this question will also delete all its existing responses
            permanently.
          </p>
          <p>This action cannot be undone. Are you sure?</p>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancelDeleteQuestionPrompt}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteQuestion}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </div>
  );
};

export default BestPracticeForm;
