import axios from "axios";
import { API_URL } from "../config/api-config";

export default class CurrencyService {
  static getAllCurrency = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/ref_data/currencies");
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}
