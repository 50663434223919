import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import withAuthentication from "../../HOC/withAuthentication";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import {
  ADVANCED_OR_AND_FILTER,
  REPORT_TIME_FRAMES,
  RESOURCES,
} from "../../../config/constants";
import SelectCurrency from "../../Currency/SelectCurrency";
import UNSDGPicker from "../../common/UNSDGPicker";
import InitiativesReportService from "../../../services/Report/InitiativesReportService";
import { DateTimeUtils } from "../../../services/UtilsService";
import ToastContext from "../../../context/ToastContext";
import GVFormGroup from "../../common/GVFormGroup";
import { useLocation } from "react-router-dom";
import { GenericUserManagedTagPicker } from "../../common/CentralTags/UserManagedTagPicker";
import { AboutTagsInInitiativeTooltip } from "../../common/CentralTags/AboutTags";
import { AdvancedOrAndSelectorFilter } from "../../common/Report/AdvancedFilter";
import { GenericUserManagedTagModel } from "../../common/CentralTags/UserManagedTagModel";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import { TextCopies } from "../../../config/text-copies";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import { extractPageParamsObjectFromURL } from "../../common/QueryHandler";
import { useTranslation } from "react-i18next";

const InitiativesReport = () => {
  const { t } = useTranslation();

  let location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [timeFrameSelected, setTimeFrameSelected] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().startOf("day").subtract(1, "year").startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(
    moment()
      .startOf("day")
      .subtract(1, "year")
      .add(11, "months")
      .endOf("month")
      .toDate()
  );
  const [isPeriodDisabled, setIsPeriodDisabled] = useState(true);

  const [subtopic, setSubtopic] = useState("");
  const [selectedUserManagedTags, setSelectedUserManagedTags] = useState([]);
  const [userManagedTagOptions, setUserManagedTagOptions] = useState([]);
  const [advancedTagFilter, setAdvancedTagFilter] = useState(
    ADVANCED_OR_AND_FILTER.OR
  );
  const [unSdgs, setUnSdgs] = useState([]);
  const [advancedUnSdgFilter, setAdvancedUnSdgFilter] = useState(
    ADVANCED_OR_AND_FILTER.OR
  );
  const [currencyId, setCurrencyId] = useState("");
  const [unitConfigs, setUnitConfigs] = useState({});

  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [reportConfig, setReportConfig] = useState({});
  const [isReportInputValidated, setIsReportInputValidated] = useState(false);

  const [inventoryId, setInventoryId] = useState("");
  const [showEmptyReportBanner, setShowEmptyReportBanner] = useState(false);

  const resetConfigSelection = () => {
    setSelectedUserManagedTags([]);
    setAdvancedTagFilter(ADVANCED_OR_AND_FILTER.OR);
    setUnSdgs([]);
    setAdvancedUnSdgFilter(ADVANCED_OR_AND_FILTER.OR);
  };

  useEffect(() => {
    const reportParamsObj = extractPageParamsObjectFromURL(location);
    if (reportParamsObj) {
      setStartDate(moment(reportParamsObj["start"]).toDate());
      setEndDate(moment(reportParamsObj["end"]).toDate());
      setTimeFrameSelected(REPORT_TIME_FRAMES.CUSTOM);
      setIsPeriodDisabled(false);
      setUnitConfigs(reportParamsObj["unit_configs"]);
      setSubtopic(reportParamsObj["subtopic"]);
      setUnSdgs(reportParamsObj["un_sdgs"] || []);
    }
  }, [location.search]);

  useEffect(() => {
    if (
      userInventory.selectedTreeNode.get &&
      userInventory.selectedTreeNode.get.nodeValue.value.main_attribute &&
      userInventory.selectedTreeNode.get.nodeValue.value.main_attribute
        .default_currency
    ) {
      const defaultCurrency =
        userInventory.selectedTreeNode.get.nodeValue.value.main_attribute
          .default_currency;
      setCurrencyId(defaultCurrency.id);
    } else if (location.state) {
      setCurrencyId(location.state["currency_id"]);
    } else {
      setCurrencyId("");
    }
  }, [location.state, userInventory.selectedTreeNode.get]);

  useEffect(() => {
    if (
      userInventory.selectedInventory.get &&
      userInventory.selectedInventory.get.id &&
      inventoryId !== userInventory.selectedInventory.get.id
    ) {
      setInventoryId(userInventory.selectedInventory.get.id);

      setIsLoadingConfig(true);
      setInventoryId(userInventory.selectedInventory.get.id);
      InitiativesReportService.getReportConfig(
        userInventory.selectedInventory.get.type,
        userInventory.selectedInventory.get.id
      ).then((config) => {
        setIsLoadingConfig(false);
        setReportConfig(config);
        setCurrencyId(config["default_units"]["currency_code"]);

        const portfolioTags = config["portfolio_tags"].map(
          (t) =>
            new GenericUserManagedTagModel(t.id, t.name, RESOURCES.PORTFOLIO)
        );
        const siteTags = config["site_tags"].map(
          (t) => new GenericUserManagedTagModel(t.id, t.name, RESOURCES.SITE)
        );
        setUserManagedTagOptions(portfolioTags.concat(siteTags));
        resetConfigSelection();
      });
    }
  }, [userInventory.selectedInventory.get]);

  const timeFrameChange = (selectedOption) => {
    const newTimeFrameSelected = selectedOption.value;
    setTimeFrameSelected(newTimeFrameSelected);

    let startDate = moment()
      .startOf("day")
      .subtract(1, "year")
      .startOf("month")
      .toDate();
    let endDate = moment()
      .startOf("day")
      .subtract(1, "year")
      .add(11, "months")
      .endOf("month")
      .toDate();
    const disabled = newTimeFrameSelected !== REPORT_TIME_FRAMES.CUSTOM;

    if (newTimeFrameSelected === REPORT_TIME_FRAMES.THIS_MONTH) {
      startDate = moment().startOf("month").toDate();
      endDate = moment().endOf("month").toDate();
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.THIS_QUARTER) {
      startDate = moment().quarter(moment().quarter()).startOf("quarter")._d;
      endDate = moment().quarter(moment().quarter()).endOf("quarter")._d;
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.THIS_YEAR) {
      startDate = moment().startOf("year").toDate();
      endDate = moment().endOf("year").toDate();
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.LAST_MONTH) {
      startDate = moment().subtract(1, "months").startOf("month").toDate();
      endDate = moment().subtract(1, "months").endOf("month").toDate();
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.LAST_QUARTER) {
      startDate = moment()
        .quarter(moment().quarter() - 1)
        .startOf("quarter")._d;
      endDate = moment()
        .quarter(moment().quarter() - 1)
        .endOf("quarter")._d;
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.LAST_YEAR) {
      startDate = moment().startOf("year").subtract(1, "year").toDate();
      endDate = moment().endOf("year").subtract(1, "year").toDate();
    }

    setStartDate(startDate);
    setEndDate(endDate);
    setIsPeriodDisabled(disabled);
  };

  const onStartDateChange = (date) => {
    if (!date) return;
    setStartDate(moment(date).startOf("month").toDate());
  };

  const onEndDateChange = (date) => {
    if (!date) return;
    setEndDate(moment(date).endOf("month").toDate());
  };

  const onSubtopicChange = (sub) => {
    setSubtopic(sub);
    if (!reportConfig || !reportConfig["unit_configs_by_subtopic"]) {
      return null;
    }
    setUnitConfigs(
      _.chain(reportConfig["unit_configs_by_subtopic"][sub])
        .map((c) => c["config_field"])
        .reduce(
          (acc, fieldKey) => ({
            ...acc,
            [fieldKey]: reportConfig["default_units"][fieldKey],
          }),
          {}
        )
        .value()
    );
  };

  const onSelectingUNSDGs = (selectedUNSDGs) => {
    setUnSdgs(
      selectedUNSDGs.map((sdg) => {
        return { id: sdg.value, name: sdg.label };
      })
    );
  };

  const getConversionOptions = () => {
    if (!reportConfig || !reportConfig.unit_configs_by_subtopic) {
      return null;
    }

    let unitConfigsBySubtopic = [];

    if (reportConfig.unit_configs_by_subtopic[subtopic]) {
      unitConfigsBySubtopic = reportConfig.unit_configs_by_subtopic[subtopic];
    }

    const selectorOptions = [
      <GVFormGroup>
        <SelectCurrency
          selected={currencyId}
          onCurrencySelected={setCurrencyId}
          isInvalid={isReportInputValidated && !isCurrencyValid()}
        />
        {isReportInputValidated && !isCurrencyValid() && (
          <div className="manual-invalid-feedback">
            Please select a currency
          </div>
        )}
      </GVFormGroup>,
    ];

    unitConfigsBySubtopic.map((unitConfig) => {
      selectorOptions.push(
        <GVFormGroup>
          <Form.Label>{unitConfig.label}</Form.Label>
          <GVDSFormSingleSelect
            placeholder="Select unit"
            value={
              unitConfig.units
                .map((unit) => {
                  return {
                    value: unit.id,
                    label: unit.name,
                  };
                })
                .find(
                  (unit) => unit.value === unitConfigs[unitConfig.config_field]
                ) || null
            }
            options={unitConfig.units.map((option) => {
              return {
                value: option.id,
                label: option.name,
              };
            })}
            onSelect={(newSelect) =>
              setUnitConfigs({
                ...unitConfigs,
                [unitConfig.config_field]: newSelect.value,
              })
            }
            statusMetadata={
              isReportInputValidated && !unitConfigs[unitConfig.config_field]
                ? FormFieldStatusMetadata.getError("Please select a unit")
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
      );
    });

    const rowGroups = [];
    const itemsPerRow = 3;

    for (let i = 0, j = 0; i < selectorOptions.length; i++) {
      if (i >= itemsPerRow && i % itemsPerRow === 0) {
        j++;
      }
      rowGroups[j] = rowGroups[j] || [];
      rowGroups[j].push(selectorOptions[i]);
    }

    return rowGroups.map((rowGroup, index) => {
      return (
        <Row key={index}>
          {rowGroup.map((item, index) => (
            <Col key={index} xxl={3} xl={4} lg={4}>
              {item}
            </Col>
          ))}
        </Row>
      );
    });
  };

  const isPeriodValid = () => {
    return (
      (timeFrameSelected === REPORT_TIME_FRAMES.CUSTOM &&
        !!startDate &&
        !!endDate &&
        !isEndDateEarlierThanStartDate()) ||
      (!!timeFrameSelected && timeFrameSelected !== REPORT_TIME_FRAMES.CUSTOM)
    );
  };

  const isCurrencyValid = () => {
    return !!currencyId;
  };

  const areUnitsValid = () => {
    let unitConfigsBySubtopic = [];

    if (reportConfig.unit_configs_by_subtopic[subtopic]) {
      unitConfigsBySubtopic = reportConfig.unit_configs_by_subtopic[subtopic];
    }

    return unitConfigsBySubtopic.every((unitConfig) => {
      return !!unitConfigs[unitConfig.config_field];
    });
  };

  const isEndDateEarlierThanStartDate = () => {
    return startDate && endDate && endDate < startDate;
  };

  const isReportInputValid = () => {
    return isPeriodValid() && isCurrencyValid() && areUnitsValid();
  };

  const generateReport = async () => {
    setShowEmptyReportBanner(false);
    setIsReportInputValidated(true);

    if (isReportInputValid()) {
      setIsLoadingReport(true);

      const resourceType = userInventory.selectedInventory.get.type;
      const resourceId = userInventory.selectedInventory.get.id;

      const payload = {
        start: DateTimeUtils.getUTCISOString(startDate),
        end: DateTimeUtils.getUTCISOString(endDate),
        subtopic: subtopic,
        un_sdg_ids: unSdgs.map((sdg) => sdg.id).join(","),
        advanced_or_and_un_sdg_filter: advancedUnSdgFilter,
        user_managed_tag_ids: selectedUserManagedTags.map((t) => t.id),
        advanced_or_and_tag_filter: advancedTagFilter,
        currency_id: currencyId,
      };
      Object.keys(unitConfigs).map((configField) => {
        payload[configField] = unitConfigs[configField];
      });

      try {
        const reportStatus = await InitiativesReportService.getReportStatus(
          resourceType,
          resourceId,
          payload
        );
        setShowEmptyReportBanner(reportStatus.status.length > 0);

        await InitiativesReportService.downloadReport(
          resourceType,
          resourceId,
          payload
        );
      } catch (error) {
        toastContext.addFailToast(
          <span>Failed to generate initiative report.</span>
        );
      }

      setIsLoadingReport(false);
    }
  };

  const EmptyReportBanner = (
    <>
      {showEmptyReportBanner && (
        <GVDSBanner
          title=" The downloaded report is blank as there are no initiatives that meet the filters chosen."
          variant={GVDSBanner.Variants.warning}
          className="mt-2"
        >
          Try changing the date filter, subtopic or UN SDGs selection.
        </GVDSBanner>
      )}
    </>
  );

  const disableDownloadReport =
    isLoadingReport || !subtopic || !isPeriodValid();

  if (isLoadingConfig) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("reports.initiatives.page-title")}</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          {t("reports.initiatives.page-description")}
        </PageHeader.Description>
      </PageHeader>
      <div className="report-config">
        <div className="report-config-options">
          <GVFormGroup>
            <Row>
              <Col xxl={3} xl={4} lg={4}>
                <GVFormGroup controlId="selectTimeFrame">
                  <Form.Label>Time Frames</Form.Label>
                  <GVDSFormSingleSelect
                    placeholder="Select Time Frame"
                    value={
                      timeFrameSelected
                        ? { value: timeFrameSelected, label: timeFrameSelected }
                        : null
                    }
                    options={Object.values(REPORT_TIME_FRAMES).map(
                      (timeFrame) => {
                        return { value: timeFrame, label: timeFrame };
                      }
                    )}
                    onSelect={timeFrameChange}
                    statusMetadata={
                      isReportInputValidated && !timeFrameSelected
                        ? FormFieldStatusMetadata.getError(
                            "Please select a time frame"
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                  />
                </GVFormGroup>
              </Col>
              <Col xxl={6} xl={8} lg={8}>
                <GVDSFormStartEndMonthPicker
                  startMonthLabel={<Form.Label>Report Period</Form.Label>}
                  className="initiative-report"
                  disabledStartMonth={isPeriodDisabled}
                  disabledEndMonth={isPeriodDisabled}
                  startMonth={startDate}
                  endMonth={endDate}
                  onChange={(paramStartMonth, paramEndMonth) => {
                    if (paramStartMonth !== startDate) {
                      onStartDateChange(paramStartMonth);
                    }
                    if (paramEndMonth !== endDate) {
                      onEndDateChange(paramEndMonth);
                    }
                  }}
                />
              </Col>
            </Row>
          </GVFormGroup>
          <Row className="d-flex flex-row align-items-center">
            <Col xxl={3} xl={4} lg={4} className="align-self-end">
              <GVFormGroup>
                <Form.Label>Subtopic</Form.Label>
                <GVDSFormSingleSelect
                  name="subtopic"
                  placeholder="Select subtopic"
                  value={subtopic ? { value: subtopic, label: subtopic } : null}
                  options={
                    reportConfig.subtopics &&
                    reportConfig.subtopics.map((subtopic) => {
                      return { value: subtopic, label: subtopic };
                    })
                  }
                  onSelect={(selectedOption) =>
                    onSubtopicChange(selectedOption.value)
                  }
                />
              </GVFormGroup>
            </Col>
            <Col xxl={3} xl={4} lg={4} className="align-self-end">
              <GVFormGroup>
                <div className="form-label-row">
                  <Form.Label>
                    Tags <span className="optional-form-label">(Optional)</span>
                  </Form.Label>
                  <AboutTagsInInitiativeTooltip />
                  <div className="ms-auto">
                    <AdvancedOrAndSelectorFilter
                      selectedFilter={advancedTagFilter}
                      setSelectedFilter={setAdvancedTagFilter}
                    />
                  </div>
                </div>
                <GenericUserManagedTagPicker
                  selectedTags={selectedUserManagedTags}
                  onChange={(selectedTags) => {
                    setSelectedUserManagedTags(selectedTags);
                  }}
                  allGenericUserManagedTagModels={userManagedTagOptions}
                />
              </GVFormGroup>
            </Col>
            <Col xxl={3} xl={4} lg={4} className="align-self-end">
              <GVFormGroup>
                <div className="form-label-row flex-wrap">
                  <Form.Label>
                    UN SDGs{" "}
                    <span className="optional-form-label">(Optional)</span>
                  </Form.Label>
                  <InfoTooltip
                    info={<span>{TextCopies.UNSDGs.about}</span>}
                    placement="top"
                  />
                  <div className="ms-auto">
                    <AdvancedOrAndSelectorFilter
                      selectedFilter={advancedUnSdgFilter}
                      setSelectedFilter={setAdvancedUnSdgFilter}
                    />
                  </div>
                </div>
                <UNSDGPicker
                  selectedUNSDGs={unSdgs}
                  allUNSDGs={reportConfig.un_sdgs ? reportConfig.un_sdgs : []}
                  onChange={onSelectingUNSDGs}
                  placeholder="Select UN SDGs"
                />
              </GVFormGroup>
            </Col>
          </Row>
        </div>
        <div className="report-config-section report-config-options">
          {getConversionOptions()}
        </div>
        <div className="report-config-footer">
          <GVDSButtonWithLoadingAction
            onClickAsyncFunc={generateReport}
            text="Download Report"
            disabled={disableDownloadReport}
          />
        </div>
      </div>
      {EmptyReportBanner}
    </div>
  );
};

export default withAuthentication(InitiativesReport);
