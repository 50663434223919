import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";

import withAuthentication from "../../HOC/withAuthentication";
import {
  SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS,
  VIEW_ALL_CONTRACTS,
  VIEW_ALL_SITES,
  VIEW_ALL_USERS,
} from "../../../config/ROUTES_NAME";
import { DropdownOptions } from "../../Site/SiteFacilityInformationComponents";
import RejectionInitialContent from "./RejectionInitialContent.json";
import {
  DateTimeUtils,
  RTFUtils,
  StringUtils,
} from "../../../services/UtilsService";
import LoadingSpinner from "../../common/LoadingSpinner";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import SignUpStatusBadge from "./SignUpStatusBadge";
import ToastContext from "../../../context/ToastContext";
import {
  RESOURCES,
  SIGNUP_STATUS,
  USER_STATUS,
} from "../../../config/constants";
import CreateSiteForm from "../../Site/CreateSiteForm";
import UserService from "../../../services/UserService";
import GVFormGroup from "../../common/GVFormGroup";
import CreateSiteSubscriptionForm from "./CreateSiteSubscriptionForm";
import SubscriptionSharedUtils, {
  customEndPeriodRange,
  SubscriptionBillingCycle,
  SubscriptionPricingType,
  TAX_COUNTRY_NAME_SINGAPORE,
} from "../Subscription/SubscriptionSharedUtils";
import SubscriptionService from "../../../services/SubscriptionService";
import clone from "lodash/clone";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import {
  IconAlertTriangle,
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconIdBadge2,
} from "@tabler/icons-react";
import PortalAdminSignUpReviewService from "../../../services/PortalAdmin/PortalAdminSignUpReviewService";
import { isUsersValid } from "../../User/AddUsersWithRole";

const CONFIRMATION_REVIEW_STATE = {
  CONFIRMING: "confirming",
  LOADING: "loading",
  DONE: "done",
};

const ViewSignUpRequest = () => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();
  const { signupId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [isOutcomeSuccess, setIsOutcomeSuccess] = useState(null);
  const [isInOutcomeDetails, setIsInOutcomeDetails] = useState(false);

  const [signUpRequestModel, setSignUpRequestModel] = useState(null);

  useEffect(() => {
    if (signupId) {
      setIsLoading(true);
      PortalAdminSignUpReviewService.getSignUpRequest(signupId)
        .then((signUpRequest) => {
          setSignUpRequestModel(signUpRequest);
          setIsLoading(false);
        })
        .catch(() => {
          toastContext.addFailToast("Failed to load sign up request");
        });
    }
  }, [signupId]);

  const goToSignupRequestList = () => {
    history.push(SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  let outcomeDisplay;

  if (signUpRequestModel.status !== SIGNUP_STATUS.PENDING) {
    outcomeDisplay = (
      <ApplicationOutcome signUpRequestModel={signUpRequestModel} />
    );
  } else {
    if (isInOutcomeDetails) {
      if (isOutcomeSuccess) {
        outcomeDisplay = (
          <ApproveApplication signUpRequestModel={signUpRequestModel} />
        );
      } else {
        outcomeDisplay = (
          <RejectApplication
            signUpModel={
              signUpRequestModel ? signUpRequestModel.signUpFormModel : {}
            }
          />
        );
      }
    } else {
      outcomeDisplay = (
        <ApplicationReview
          signUpModel={
            signUpRequestModel ? signUpRequestModel.signUpFormModel : {}
          }
          isOutcomeSuccess={isOutcomeSuccess}
          setIsOutcomeSuccess={setIsOutcomeSuccess}
          continueToNextStage={() => setIsInOutcomeDetails(true)}
        />
      );
    }
  }

  const getReviewDateDisplay = () => {
    if (!signUpRequestModel?.reviewDate) {
      return "-";
    } else {
      return <>{DateTimeUtils.formatUTCDate(signUpRequestModel.reviewDate)}</>;
    }
  };

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text="Return to All Site Sign-Up Request List"
          onClick={goToSignupRequestList}
        />
        <PageHeader.Title>
          <h1>
            Sign-Up Application for{" "}
            {signUpRequestModel?.signUpFormModel?.siteName}
          </h1>
        </PageHeader.Title>
        <PageHeader.Status>
          <div>
            <div className="info-field">
              <SignUpStatusBadge status={signUpRequestModel.status} />
            </div>
            <div className="info-field">
              Request Date:{" "}
              <span style={{ fontWeight: "normal" }}>
                {DateTimeUtils.formatUTCDate(signUpRequestModel.requestDate)}
              </span>
            </div>
            <div className="info-field">
              Reviewed on: {getReviewDateDisplay()}
            </div>
          </div>
        </PageHeader.Status>
      </PageHeader>
      <hr />
      <Container fluid>
        <Row>
          <Col lg={6} sm={12}>
            {outcomeDisplay}
          </Col>
          <Col lg={6} sm={12}>
            <SignUpForm
              signUpModel={
                signUpRequestModel ? signUpRequestModel.signUpFormModel : {}
              }
              packagePlanName={signUpRequestModel?.packagePlanName}
              forTrial={signUpRequestModel?.forTrial}
              billingCycle={signUpRequestModel?.billingCycle}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const SignUpForm = ({
  signUpModel,
  packagePlanName,
  forTrial,
  billingCycle,
}) => {
  return (
    <div className="admin-signup-form-review">
      <h2>Sign Up Form</h2>
      <div className="section-label">User Info</div>
      <section>
        <GVFormGroup controlId="emailAddress">
          <Form.Label>Company Email Address</Form.Label>
          <Form.Control
            type="text"
            name="emailAddress"
            readOnly={true}
            plaintext={true}
            disabled={true}
            value={signUpModel.emailAddress}
          />
        </GVFormGroup>
        <Row>
          <Col>
            <GVFormGroup controlId="username">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                readOnly={true}
                plaintext={true}
                disabled={true}
                value={signUpModel.userFullName}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup controlId="companyName">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={signUpModel.companyName}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
        </Row>
      </section>

      <div className="section-label">Site Information</div>
      <section>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>Site Name</Form.Label>
              <Form.Control
                type="text"
                name="siteName"
                value={signUpModel.siteName}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <DropdownOptions
                label="Site Type"
                targetName="siteType"
                inEditMode={false}
                value={signUpModel.siteType}
              />
            </GVFormGroup>
          </Col>
        </Row>

        <GVFormGroup>
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            name="streetAddress"
            value={signUpModel.streetAddress || ""}
            readOnly={true}
            plaintext={true}
            disabled={true}
          />
        </GVFormGroup>

        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>City</Form.Label>
              <Form.Control
                name="city"
                value={signUpModel.city || ""}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                name="zipCode"
                value={signUpModel.zipCode || ""}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>State / Province</Form.Label>
              <Form.Control
                name="stateOrProvince"
                value={signUpModel.stateOrProvince || ""}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <DropdownOptions
                label="Country"
                inEditMode={false}
                targetName="country"
                value={signUpModel.country}
              />
            </GVFormGroup>
          </Col>
        </Row>
      </section>

      <div className="section-label">Plan Selected</div>
      <section>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>Package Plan Name</Form.Label>
              <Form.Control
                name="packagePlanName"
                value={packagePlanName || ""}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <Form.Label>For Trial</Form.Label>
            <Form.Control
              name="packagePlanName"
              value={forTrial ? "Yes" : "No"}
              readOnly={true}
              plaintext={true}
              disabled={true}
            />
          </Col>
          <Col>
            <GVFormGroup>
              <Form.Label>Billing Cycle</Form.Label>
              <Form.Control
                name="billingCycle"
                value={StringUtils.capitaliseWord(billingCycle) || ""}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
        </Row>
      </section>
    </div>
  );
};

const ApplicationOutcome = ({ signUpRequestModel }) => {
  const emailBodyRef = useRef();

  useEffect(() => {
    if (emailBodyRef.current) {
      emailBodyRef.current.innerHTML = signUpRequestModel.reviewEmailBody;
    }
  }, [emailBodyRef.current, signUpRequestModel]);

  return signUpRequestModel.status === SIGNUP_STATUS.REJECTED &&
    signUpRequestModel.reviewEmailBody !== null ? (
    <div>
      <GVFormGroup>
        <Form.Label>Application Rejection Email</Form.Label>
        <div ref={emailBodyRef} />
      </GVFormGroup>
    </div>
  ) : null;
};

const ApplicationReview = ({
  signUpModel,
  isOutcomeSuccess,
  setIsOutcomeSuccess,
  continueToNextStage,
}) => {
  const [isCheckingEmail, setIsCheckingEmail] = useState(true);
  const [userStatus, setUserStatus] = useState("");

  useEffect(() => {
    if (signUpModel) {
      setIsCheckingEmail(true);
      UserService.getUserStatusByEmail(signUpModel.emailAddress)
        .then((userStatus) => {
          setUserStatus(userStatus);
        })
        .catch(() => {
          setUserStatus("Error");
        })
        .finally(() => {
          setIsCheckingEmail(false);
        });
    }
  }, [signUpModel]);

  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  let emailStatus = (
    <span className="color-green">Checking email status...</span>
  );

  if (!isCheckingEmail) {
    if (userStatus === "Error") {
      emailStatus = <>Error while checking email status</>;
    } else {
      emailStatus = (
        <>
          <span className="color-green">
            Status of email address in Greenview Portal:{" "}
          </span>
          <StatusLabel
            color={
              userStatus === USER_STATUS.ACTIVE
                ? StatusLabel.Colors.green
                : userStatus === USER_STATUS.AWAIT_LOGIN ||
                  userStatus === USER_STATUS.PASSWORD_RESET
                ? StatusLabel.Colors.gray
                : StatusLabel.Colors.red
            }
          >
            {StringUtils.capitaliseWord(userStatus)}
          </StatusLabel>
        </>
      );
    }
  }

  return (
    <>
      <h2 className="mb-4">Application Review</h2>
      <div className="mb-4">
        <h3>Verify that:</h3>
        <ol style={{ paddingLeft: "15px" }}>
          <li>Company email address is used.</li>
          <li>
            Matching email address check.{" "}
            <Button
              variant="link"
              size="sm"
              onClick={() => openLinkInNewTab(VIEW_ALL_USERS)}
            >
              Click here to view all users.
            </Button>
            <br />
            <div className="d-flex align-items-center color-green">
              <GVDSIcon Icon={IconIdBadge2} className="me-2" />
              <span>{emailStatus}</span>
            </div>
          </li>
          <li>
            Site duplication check - site shouldn’t already exist within the
            portal.{" "}
            <Button
              variant="link"
              size="sm"
              onClick={() => openLinkInNewTab(VIEW_ALL_SITES)}
            >
              Click here to view all sites.
            </Button>
          </li>
          <li>
            Determine if site should be associated to a parent contract.{" "}
            <Button
              variant="link"
              size="sm"
              className="mx-2"
              onClick={() => openLinkInNewTab(VIEW_ALL_CONTRACTS)}
            >
              Click here to view all contracts.
            </Button>
          </li>
        </ol>
      </div>
      <div>
        <h3>Application outcome</h3>
        <Container>
          <Row>
            <Col>
              <div
                className={
                  isOutcomeSuccess === true
                    ? "admin-signup-outcome-option active"
                    : "admin-signup-outcome-option"
                }
                onClick={() => setIsOutcomeSuccess(true)}
              >
                <div className="mb-2">
                  <GVDSIcon
                    className="color-green"
                    Icon={IconCircleCheckFilled}
                  />
                </div>
                <div>
                  <strong>Approve Application</strong>
                </div>
                <div>
                  Continue to create site, site subscription, and user in the
                  Portal.
                </div>
              </div>
            </Col>
            <Col>
              <div
                className={
                  isOutcomeSuccess === false
                    ? "admin-signup-outcome-option active"
                    : "admin-signup-outcome-option"
                }
                onClick={() => setIsOutcomeSuccess(false)}
              >
                <div className="mb-2">
                  <GVDSIcon className="color-red" Icon={IconCircleXFilled} />
                </div>
                <div>
                  <strong>Reject Application</strong>
                </div>
                <div>
                  Nothing will be created. Send a rejection email if necessary.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {isOutcomeSuccess !== null && (
          <div className="d-flex flex-row mt-3">
            <GVDSButton
              variant={buttonVariant.tertiary}
              className="ms-auto"
              onClick={() => setIsOutcomeSuccess(null)}
              text="Cancel"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              className="admin-signup__continue-button"
              onClick={continueToNextStage}
              text="Continue"
            />
          </div>
        )}
      </div>
    </>
  );
};

const ApproveApplication = ({ signUpRequestModel }) => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();
  const location = useLocation();
  const siteFormRef = useRef();
  const subscriptionFormRef = useRef();

  const signUpFormModel = signUpRequestModel.signUpFormModel;

  const [showApprovalConfirmation, setShowApprovalConfirmation] =
    useState(false);

  const [isLoadingSubscriptionOptions, setIsLoadingSubscriptionOptions] =
    useState(true);

  const closeApprovalConfirmation = () => {
    setShowApprovalConfirmation(false);
  };

  const onApprovalSuccess = () => {
    history.push(
      getRedirectURLWithCurrentParam(
        SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS,
        location
      )
    );
  };

  const [isValidated, setIsValidated] = useState(false);
  const [site, setSite] = useState({
    name: signUpFormModel?.siteName,
    type: signUpFormModel?.siteType,
    comment: "",
    location: {
      street_address: signUpFormModel?.streetAddress,
      zip_code: signUpFormModel?.zipCode,
      state_or_province: signUpFormModel?.stateOrProvince,
      city: signUpFormModel?.city,
      country: signUpFormModel?.country,
    },
  });
  const [siteErrors, setSiteErrors] = useState({});
  const [subscription, setSubscription] = useState(
    SubscriptionSharedUtils.getBlankSiteSubscriptionModel()
  );
  const [allFeatures, setAllFeatures] = useState([]);
  const [orderedPackagePlans, setOrderedPackagePlans] = useState([]);

  useEffect(() => {
    loadSubscriptionOptions();
  }, []);

  const initializeFreeTrialSubscriptionPeriod = () => {
    subscription.startPeriod = new Date();
    subscription.endPeriod = SubscriptionSharedUtils.calculateEndPeriod(
      customEndPeriodRange.fourteenDays,
      subscription.startPeriod
    );
  };

  const loadSubscriptionOptions = async () => {
    setIsLoadingSubscriptionOptions(true);
    try {
      const options = await SubscriptionService.getSubscriptionOptions(
        RESOURCES.SITE,
        ""
      );
      if (options) {
        setAllFeatures(options["features"]);
        const orderedPackagePlanOptions = options["ordered_package_plans"];
        setOrderedPackagePlans(orderedPackagePlanOptions);

        subscription.features = options["features"];

        const chosenPlanId = signUpRequestModel.packagePlanId;
        const chosenPlanOption = orderedPackagePlanOptions.find(
          (plan) => plan.package_plan_id === chosenPlanId
        );
        if (chosenPlanOption) {
          subscription.planId = chosenPlanId;
          subscription.features = options["features"].filter((f) =>
            chosenPlanOption.feature_names.includes(f.name)
          );
        }

        if (signUpRequestModel.forTrial) {
          subscription.pricingType = SubscriptionPricingType.FREE;
          initializeFreeTrialSubscriptionPeriod();
          subscription.autoRenew = false;
        } else {
          subscription.autoRenew = true;
          subscription.pricingType = SubscriptionPricingType.PAID;
          const chosenBillingCycle = signUpRequestModel.billingCycle;
          subscription.billingCycle = chosenBillingCycle;
          subscription.startPeriod = DateTimeUtils.getTodayWithTimeInZero();

          const price = chosenPlanOption.prices.find(
            (price) => price.billing_cycle === chosenBillingCycle
          );
          if (price) {
            subscription.priceAmount = price.price_in_decimal;
            subscription.currencyId = price.currency.toUpperCase();
          }

          if (chosenBillingCycle === SubscriptionBillingCycle.MONTHLY) {
            subscription.endPeriod = SubscriptionSharedUtils.calculateEndPeriod(
              customEndPeriodRange.oneMonth,
              subscription.startPeriod
            );
          } else if (chosenBillingCycle === SubscriptionBillingCycle.YEARLY) {
            subscription.endPeriod = SubscriptionSharedUtils.calculateEndPeriod(
              customEndPeriodRange.oneYear,
              subscription.startPeriod
            );
          }
          const defaultReminderDate =
            SubscriptionSharedUtils.getDefaultReminderDate(
              subscription,
              chosenBillingCycle,
              subscription.pricingType
            );
          if (defaultReminderDate !== null) {
            subscription.reminderDate = defaultReminderDate;
          }
        }

        if (signUpFormModel.country.name === TAX_COUNTRY_NAME_SINGAPORE) {
          subscription.isTaxApplicable = true;
        }

        setSubscription(clone(subscription));
      }
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to load subscription options.</span>
      );
    } finally {
      setIsLoadingSubscriptionOptions(false);
    }
  };

  const isSiteValid = () => {
    return (
      site.name &&
      site.type &&
      site.type.id &&
      site.location?.country?.id &&
      site.location.country.id.length > 0 &&
      site.location?.city &&
      site.location.city.length > 0 &&
      site.location?.zip_code &&
      site.location.zip_code.length > 0 &&
      site.main_attribute?.default_currency?.id &&
      site.main_attribute.default_currency.id.length > 0 &&
      isUsersValid(site.usersWithRoles)
    );
  };

  const isSiteSubscriptionValid = () => {
    return (
      subscription.isStartDateValid() &&
      subscription.isEndDateValid() &&
      subscription.isReminderDateValid() &&
      subscription.isPriceValid() &&
      subscription.isFeaturesValid()
    );
  };

  const isApprovalValid = () => {
    return isSiteValid() && isSiteSubscriptionValid();
  };

  const approveSignUpRequest = () => {
    setIsValidated(true);
    if (isApprovalValid()) {
      setShowApprovalConfirmation(true);
    } else if (!isSiteValid() && siteFormRef.current) {
      siteFormRef.current.scrollIntoView();
    } else if (!isSiteSubscriptionValid() && subscriptionFormRef.current) {
      subscriptionFormRef.current.scrollIntoView();
    }
  };

  return (
    <>
      <div ref={siteFormRef}>
        <div className="mb-2">
          General site information has been populated based on the sign-up
          application form except for the site team members.
          <br />
          Please ensure that the applicant is added as a team member before
          approving site and user creation.
        </div>
        <CreateSiteForm
          site={site}
          onChange={(siteDetails) => {
            subscription.isTaxApplicable =
              siteDetails.location.country.name === TAX_COUNTRY_NAME_SINGAPORE;
            setSubscription(clone(subscription));
            setSite(siteDetails);
          }}
          isValidated={isValidated}
          errors={siteErrors}
          firstUserEmail={signUpFormModel?.emailAddress}
        />
      </div>

      <div ref={subscriptionFormRef}>
        <CreateSiteSubscriptionForm
          subscription={subscription}
          onChange={setSubscription}
          isValidated={isValidated}
          isLoadingSubscriptionOptions={isLoadingSubscriptionOptions}
          allFeatures={allFeatures}
          orderedPackagePlans={orderedPackagePlans}
          minEndDate={new Date()}
        />
      </div>

      <div className="d-flex flex-row mt-3">
        <GVDSButton
          variant={buttonVariant.primary}
          className="ms-auto"
          onClick={approveSignUpRequest}
          text="Approve Site Sign-Up Request"
        />
      </div>

      <ApproveConfirmation
        show={showApprovalConfirmation}
        onClose={closeApprovalConfirmation}
        onSuccess={onApprovalSuccess}
        site={site}
        setSiteErrors={(siteErrors) => {
          setSiteErrors(siteErrors);
          if (siteFormRef.current) {
            siteFormRef.current.scrollIntoView();
          }
        }}
        subscription={subscription}
      />
    </>
  );
};

const ApproveConfirmation = ({
  show,
  onClose,
  onSuccess,
  site,
  setSiteErrors,
  subscription,
}) => {
  const { signupId } = useParams();

  const toastContext = useContext(ToastContext);

  const [confirmationState, setConfirmationState] = useState(
    CONFIRMATION_REVIEW_STATE.CONFIRMING
  );

  const close = () => {
    if (confirmationState === CONFIRMATION_REVIEW_STATE.CONFIRMING) {
      if (onClose) {
        onClose();
      }
    } else if (confirmationState === CONFIRMATION_REVIEW_STATE.DONE) {
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const onApprove = () => {
    setConfirmationState(CONFIRMATION_REVIEW_STATE.LOADING);
    PortalAdminSignUpReviewService.approveSignUpRequest(
      signupId,
      site,
      subscription.toRequestPayload()
    )
      .then(() => {
        setConfirmationState(CONFIRMATION_REVIEW_STATE.DONE);
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 400) {
          setConfirmationState(CONFIRMATION_REVIEW_STATE.CONFIRMING);
          setSiteErrors(response.data.message);
          toastContext.addFailToast(
            <span>Site cannot be created. Please check the site details.</span>
          );
          onClose();
        } else {
          setConfirmationState(CONFIRMATION_REVIEW_STATE.CONFIRMING);
          toastContext.addFailToast(
            <span>Failed to approve signup. Please try again.</span>
          );
        }
      });
  };

  let content, title;

  if (confirmationState === CONFIRMATION_REVIEW_STATE.CONFIRMING) {
    title = "Approve Sign-Up Application";
    content = (
      <>
        <GVDSModal.Body>
          <div>
            Upon confirmation, the following things will happen:
            <ul>
              <li>Site and user will be created</li>
              <li>User will receive an invitation email to join the portal</li>
              <li>
                User will receive an email that their sign-up was approved
              </li>
            </ul>
          </div>
          <div className="mt-2">Are you sure?</div>
          {(!site.usersWithRoles || site.usersWithRoles.length === 0) && (
            <div className="mt-2 color-red text-vertical-center">
              <GVDSIcon Icon={IconAlertTriangle} /> No site team members and
              billing admin have been added.
            </div>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={close}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={onApprove}
            text="Yes, Approve Site Sign-Up Request"
          />
        </GVDSModal.Footer>
      </>
    );
  } else if (confirmationState === CONFIRMATION_REVIEW_STATE.LOADING) {
    title = "Creating site, user and sending emails";
    content = (
      <>
        <GVDSModal.Body>
          <div>
            Site and user is being created in the portal and emails are being
            sent.
          </div>
          <LoadingSpinner />
          <div className="d-flex justify-content-center text-center color-red">
            Please do not refresh or leave this page.
          </div>
        </GVDSModal.Body>
      </>
    );
  } else if (confirmationState === CONFIRMATION_REVIEW_STATE.DONE) {
    title = "Sign-Up Request successfully approved";
    content = (
      <>
        <GVDSModal.Body>
          <div>The approval process has been completed.</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={close}
            text="Close"
          />
        </GVDSModal.Footer>
      </>
    );
  }

  return (
    <GVDSModal
      title={title}
      size={GVDSModal.Size.small}
      show={show}
      onHide={close}
    >
      {content}
    </GVDSModal>
  );
};

const RejectApplication = () => {
  const { signupId } = useParams();
  const toastContext = useContext(ToastContext);
  const history = useHistory();
  const location = useLocation();
  const [showRejectionConfirmation, setShowRejectionConfirmation] =
    useState(false);
  const [shouldSendEmail, setShouldSendEmail] = useState(false);
  const [confirmationState, setConfirmationState] = useState(
    CONFIRMATION_REVIEW_STATE.CONFIRMING
  );

  const closeRejectionConfirmation = () => {
    setShowRejectionConfirmation(false);
  };

  const onRejectionSuccess = () => {
    history.push(
      getRedirectURLWithCurrentParam(
        SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS,
        location
      )
    );
  };

  const [rejectionEmailEditorState, setRejectionEmailEditorState] = useState(
    EditorState.createWithContent(convertFromRaw(RejectionInitialContent))
  );

  const isRejectionEmailValid = () => {
    return !RTFUtils.isEmpty(rejectionEmailEditorState);
  };

  const rejectSignUpRequest = () => {
    if (shouldSendEmail) {
      setShowRejectionConfirmation(true);
    } else {
      PortalAdminSignUpReviewService.rejectSignUpRequest(signupId, null, false)
        .then(() => {
          setConfirmationState(CONFIRMATION_REVIEW_STATE.DONE);
          setShowRejectionConfirmation(true);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to reject signup. Please try again.</span>
          );
        });
    }
  };

  return (
    <>
      <h2>Reject Sign-Up Request</h2>
      <div className="mt-4 mb-4">
        <div className="gv-body-2--extra-bold mb-1">
          Send a rejection email?
        </div>
        <Form.Group className="ms-1">
          <Form.Check
            id="do-not-send-email"
            label={
              <div>
                <strong>No,</strong> do not send anything.
              </div>
            }
            type="radio"
            checked={!shouldSendEmail}
            onChange={() => setShouldSendEmail(false)}
          />
          <Form.Check
            id="send-email"
            label={
              <div>
                <strong>Yes,</strong> send a rejection email via
                support@greenviewportal.com to this site's users.
              </div>
            }
            type="radio"
            checked={shouldSendEmail}
            onChange={() => setShouldSendEmail(true)}
          />
        </Form.Group>
      </div>
      {shouldSendEmail && (
        <>
          <div className="gv-body-2--extra-bold">Rejection Email Content</div>
          <div className="caption">
            Please ensure that reason for rejection is filled in.
          </div>
          <Editor
            editorState={rejectionEmailEditorState}
            onEditorStateChange={(editorState) => {
              setRejectionEmailEditorState(editorState);
            }}
            wrapperClassName="rtf-wrapper"
            editorClassName="rtf-editor"
            toolbar={{
              options: [
                "blockType",
                "inline",
                "list",
                "link",
                "remove",
                "history",
              ],
            }}
          />
          {!isRejectionEmailValid() && (
            <div className="manual-invalid-feedback">
              Rejection email cannot be empty
            </div>
          )}
        </>
      )}

      <div className="d-flex flex-row mt-3">
        <GVDSButton
          variant={buttonVariant.primary}
          className="ms-auto"
          onClick={rejectSignUpRequest}
          text="Reject Site Sign-Up Request"
        />
      </div>

      <RejectConfirmation
        signupId={signupId}
        show={showRejectionConfirmation}
        onClose={closeRejectionConfirmation}
        onSuccess={onRejectionSuccess}
        rejectionEmail={RTFUtils.convertEditorStateToHTML(
          rejectionEmailEditorState
        )}
        confirmationState={confirmationState}
        onConfirmationStateChange={(newConfirmationState) =>
          setConfirmationState(newConfirmationState)
        }
      />
    </>
  );
};

const RejectConfirmation = ({
  signupId,
  show,
  onClose,
  onSuccess,
  rejectionEmail,
  confirmationState,
  onConfirmationStateChange,
}) => {
  const toastContext = useContext(ToastContext);

  const close = () => {
    if (confirmationState === CONFIRMATION_REVIEW_STATE.CONFIRMING) {
      if (onClose) {
        onClose();
      }
    } else if (confirmationState === CONFIRMATION_REVIEW_STATE.DONE) {
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const onReject = () => {
    onConfirmationStateChange(CONFIRMATION_REVIEW_STATE.LOADING);
    PortalAdminSignUpReviewService.rejectSignUpRequest(
      signupId,
      rejectionEmail,
      true
    )
      .then(() => {
        onConfirmationStateChange(CONFIRMATION_REVIEW_STATE.DONE);
      })
      .catch(() => {
        onConfirmationStateChange(CONFIRMATION_REVIEW_STATE.CONFIRMING);
        toastContext.addFailToast(
          <span>Failed to reject signup. Please try again.</span>
        );
      });
  };

  let content, title;

  if (confirmationState === CONFIRMATION_REVIEW_STATE.CONFIRMING) {
    title = "Reject Sign-Up Application";
    content = (
      <>
        <GVDSModal.Body>
          <div>
            Upon confirmation, the following things will happen:
            <ul>
              <li>
                User will receive an email that their sign-up was rejected
              </li>
            </ul>
          </div>
          <div className="mt-2">Are you sure?</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={close}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={onReject}
            text="Yes, Reject Site Sign-Up Request"
          />
        </GVDSModal.Footer>
      </>
    );
  } else if (confirmationState === CONFIRMATION_REVIEW_STATE.LOADING) {
    title = "Sending Rejection Email";
    content = (
      <>
        <GVDSModal.Body>
          <div>Email is being sent.</div>
          <LoadingSpinner />
          <div className="d-flex justify-content-center text-center color-red">
            Please do not refresh or leave this page.
          </div>
        </GVDSModal.Body>
      </>
    );
  } else if (confirmationState === CONFIRMATION_REVIEW_STATE.DONE) {
    title = "Sign-Up Request successfully rejected";
    content = (
      <>
        <GVDSModal.Body>
          <div>The rejection process has been completed.</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={close}
            text="Close"
          />
        </GVDSModal.Footer>
      </>
    );
  }

  return (
    <GVDSModal
      title={title}
      size={GVDSModal.Size.small}
      show={show}
      onHide={close}
    >
      {content}
    </GVDSModal>
  );
};

export default withAuthentication(ViewSignUpRequest);
