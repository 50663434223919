import {
  CellValue,
  DateCellValue,
  DropdownCellValue,
  NumberCellValue,
  RemoveActionCell
} from "../Data/BulkDataInputCellViewer";
import SiteBulkService from "../../services/SiteBulkService";
import find from "lodash/find";
import get from "lodash/get";
import _ from "lodash";
import { COMPANY_TYPE, new_entry_prefix } from "../../config/constants";
import MetroAreaService from "../../services/MetroAreaService";

const extraSpaceRegexPattern = /\s\s+/g;

const columns = {
  0: {
    key: "action",
    label: "",
    width: 2,
    classType: RemoveActionCell,
    errorKey: "",
    readOnly: true
  },
  1: {
    key: "index",
    label: "Row",
    width: 2.5,
    classType: CellValue,
    errorKey: "index",
    readOnly: true,
  },
  2: {
    key: "name",
    label: "Site Name*",
    width: 10,
    classType: CellValue,
    errorKey: "name",
  },
  3: {
    key: "type",
    label: "Site Type*",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "type",
  },
  4: {
    key: "comment",
    label: "Comment",
    width: 10,
    classType: CellValue,
    errorKey: "comment",
  },
  5: {
    key: "street_address",
    label: "Street Address*",
    width: 10,
    classType: CellValue,
    errorKey: "location.street_address",
  },
  6: {
    key: "city",
    label: "City*",
    width: 10,
    classType: CellValue,
    errorKey: "location.city",
  },
  7: {
    key: "state_or_province",
    label: "State / Province",
    width: 10,
    classType: CellValue,
    errorKey: "location.state_or_province",
  },
  8: {
    key: "zip_code",
    label: "Postal Code*",
    width: 10,
    classType: CellValue,
    errorKey: "location.zip_code",
  },
  9: {
    key: "country",
    label: "Country*",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "location.country",
  },
  10: {
    key: "approved_domains",
    label: "Approved Email Domains for this Site only (comma-separated)",
    width: 10,
    classType: CellValue,
    errorKey: "approved_domains",
  },
  11: {
    key: "status",
    label: "Status",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "about_attribute.status",
  },
  12: {
    key: "date_opened",
    label: "Date Opened",
    width: 10,
    classType: DateCellValue,
    errorKey: "about_attribute.date_opened",
  },
  13: {
    key: "brand_company",
    label: "Brand Company",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "brand_company",
  },
  14: {
    key: "brand_company_id_code",
    label: "Brand Company ID Code",
    width: 10,
    classType: CellValue,
    errorKey: "brand_company_id_code",
  },
  15: {
    key: "brand_flag",
    label: "Brand Flag",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "brand",
  },
  16: {
    key: "asset_owner_company",
    label: "Asset Owner Company",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "asset_owner_company",
  },
  17: {
    key: "asset_owner_company_id_code",
    label: "Asset Owner Company ID Code",
    width: 10,
    classType: CellValue,
    errorKey: "asset_owner_company_id_code",
  },
  18: {
    key: "management_company",
    label: "Management Company",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "management_company",
  },
  19: {
    key: "management_company_id_code",
    label: "Management Company ID Code",
    width: 10,
    classType: CellValue,
    errorKey: "management_company_id_code",
  },
  20: {
    key: "other_identifier_code",
    label: "Other Property Identifier Code",
    width: 10,
    classType: CellValue,
    errorKey: "other_identifier_code",
  },
  21: {
    key: "currency",
    label: "Currency of Utility Invoice*",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "main_attribute.default_currency",
  },
  22: {
    key: "metro_area",
    label: "Metro Area",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "main_attribute.metro_area",
  },
  23: {
    key: "koppen_geiger_climate_zone",
    label: "Climate Zone(Köppen–Geiger)",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "main_attribute.koppen_geiger_climate_zone",
  },
  24: {
    key: "baileys_ecoregion_climate_zone",
    label: "Climate Zone(Bailey's Ecoregion)",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "main_attribute.baileys_ecoregion_climate_zone",
  },
  25: {
    key: "expedia_star",
    label: "Expedia Stars",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "hotel_attribute.expedia_star",
  },
  26: {
    key: "market_segment",
    label: "Market Segment",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "hotel_attribute.market_segment",
  },
  27: {
    key: "hotel_type",
    label: "Hotel Type",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "hotel_attribute.hotel_type",
  },
  28: {
    key: "asset_class",
    label: "Service Type / Asset Class",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "hotel_attribute.asset_class",
  },
  29: {
    key: "laundry_location",
    label: "Main Laundry Wash Location",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "hotel_attribute.laundry_location",
  },
  30: {
    key: "room_count",
    label: "Room Count",
    width: 10,
    classType: NumberCellValue,
    errorKey: "hotel_attribute.room_count",
  },
};

class SiteValue {
  static new(row, removeRow) {
    const newSite = new SiteValue();

    Object.values(columns).forEach((c) => {
      newSite[c.key] = new c.classType();
    });

    newSite.action = RemoveActionCell.fromRemoveRow(() => removeRow(row));
    newSite.index = new CellValue(row + 1, true, [], [], false);
    return newSite;
  }

  static fromRowData(rowData) {
    const newSite = new SiteValue();
    Object.values(columns).forEach((c, col) => {
      newSite[c.key] = c.classType.fromCellData(rowData[col]);
    });
    return newSite;
  }

  static getNameByCol(col) {
    return columns[col]?.key;
  }

  updateDataEditor(options) {
    this.type.setDropdownOptions(options["site_facility_types"]);
    this.country.setDropdownOptions(options["countries"]);
    this.currency.setDropdownOptions(options["currencies"]);
    this.status.setDropdownOptions(options["status"]);

    const brandCompanies = _.chain(options["related_company_options"])
      .find((r) => r["name"] === COMPANY_TYPE.BRAND)
      .get("companies", [])
      .sortBy("name")
      .value();
    this.brand_company.setDropdownOptions(brandCompanies);
    if (this.brand_company.value) {
      const companyId = _.chain(brandCompanies)
        .find((c) => c.name === this.brand_company.value)
        .get("id", "")
        .value();
      this.brand_flag.setDropdownOptions(get(options["brands"], companyId, []));
    }
    this.asset_owner_company.setDropdownOptions(
      _.chain(options["related_company_options"])
        .find((r) => r["name"] === COMPANY_TYPE.ASSET_OWNER)
        .get("companies", [])
        .sortBy("name")
        .value()
    );
    this.management_company.setDropdownOptions(
      _.chain(options["related_company_options"])
        .find((r) => r["name"] === COMPANY_TYPE.MANAGEMENT)
        .get("companies", [])
        .sortBy("name")
        .value()
    );

    const metroAreaOptions = [...options["metro_areas"]].sort(MetroAreaService.sortFn);
    this.metro_area.setDropdownOptions(metroAreaOptions);

    this.expedia_star.setDropdownOptions(options["expedia_stars"]);
    this.koppen_geiger_climate_zone.setDropdownOptions(
      options["koppen_geiger_climate_zone"]
    );
    this.baileys_ecoregion_climate_zone.setDropdownOptions(
      options["baileys_ecoregion_climate_zone"]
    );
    this.market_segment.setDropdownOptions(options["market_segments"]);
    this.hotel_type.setDropdownOptions(options["hotel_types"]);
    this.asset_class.setDropdownOptions(options["asset_classes"]);
    this.laundry_location.setDropdownOptions(options["laundry_locations"]);
  }

  toGridRow() {
    return Object.values(columns).map((c) => this[c.key]);
  }

  isEmpty() {
    return Object.values(columns)
      .filter((c) => !c.readOnly)
      .reduce((acc, cur) => acc && this[cur.key].isEmpty(), true);
  }

  removeAllErrors() {
    Object.values(columns).forEach((c) => {
      this[c.key].dataErrors = [];
      this[c.key].possibleErrors = [];
    });
  }
}

export const getSiteColumnLabels = () =>
  Object.values(columns).map((c) => ({ label: c.label, width: c.width }));

export default class BulkSitesInputService {
  selectorOptions = {};

  async getSelectionOptions() {
    const siteBulkConfig = await SiteBulkService.getConfig();
    this.selectorOptions = {
      ...siteBulkConfig,
      currencies: siteBulkConfig["currencies"].map((c) => ({
        id: c.id,
        name: `${c.name} (${c.id})`,
      })),
    };
  }

  getNewRow(row, removeRow) {
    const newRow = SiteValue.new(Number(row), removeRow);
    newRow.updateDataEditor(this.selectorOptions);
    return newRow.toGridRow();
  }

  updateRow(rowData, changes) {
    const siteRow = SiteValue.fromRowData(rowData);

    changes.forEach((c) => {
      const colName = SiteValue.getNameByCol(c.col);
      if (colName) {
        siteRow[colName].updateValue(c.value);
      }
    });
    siteRow.updateDataEditor(this.selectorOptions);
    return siteRow.toGridRow();
  }

  removeRow(rowNumber, setRowToDelete, grid) {
    let changedGrid = grid.map((row) => [...row]);
    changedGrid.splice(rowNumber, 1);
    changedGrid = changedGrid.map((g, row) => {
      const site = SiteValue.fromRowData(g);
      site.index.updateValue(row + 1);
      site.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row));
      return site.toGridRow();
    });
    return changedGrid;
  }

  removeEmptyRows = (grid, setRowToDelete) => {
    return grid
      .map((rowData) => SiteValue.fromRowData(rowData))
      .filter((r) => !r.isEmpty())
      .map((r, row) => {
        r.index.updateValue(row + 1);
        r.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row));
        return r.toGridRow();
      });
  };

  getSite(rowData) {
    const site = SiteValue.fromRowData(rowData);
    const type =
      find(
        this.selectorOptions["site_facility_types"],
        (t) => t.name === site.type.value
      ) || site.type.getValue();
    const country =
      find(
        this.selectorOptions["countries"],
        (t) => t.name === site.country.getValue()
      ) || site.country.getValue();
    const currency =
      find(
        this.selectorOptions["currencies"],
        (t) => t.name === site.currency.getValue()
      ) || site.currency.getValue();
    const status =
      find(
        this.selectorOptions["status"],
        (t) => t.name === site.status.getValue()
      ) || site.status.getValue();
    const metro_area =
      find(
        this.selectorOptions["metro_areas"],
        (t) => t.name === site.metro_area.getValue()
      ) || site.metro_area.getValue();
    const approvedDomains = site.approved_domains.value
      ? site.approved_domains.value.split(",")
      : undefined;
    const koppen_geiger_climate_zone =
      find(
        this.selectorOptions["koppen_geiger_climate_zone"],
        (t) => t.name === site.koppen_geiger_climate_zone.getValue()
      ) || site.koppen_geiger_climate_zone.getValue();
    const baileys_ecoregion_climate_zone =
      find(
        this.selectorOptions["baileys_ecoregion_climate_zone"],
        (t) => t.name === site.baileys_ecoregion_climate_zone.getValue()
      ) || site.baileys_ecoregion_climate_zone.getValue();
    const market_segment =
      find(
        this.selectorOptions["market_segments"],
        (t) => t.name === site.market_segment.getValue()
      ) || site.market_segment.getValue();
    const hotel_type =
      find(
        this.selectorOptions["hotel_types"],
        (t) => t.name === site.hotel_type.getValue()
      ) || site.hotel_type.getValue();
    const asset_class =
      find(
        this.selectorOptions["asset_classes"],
        (t) => t.name === site.asset_class.getValue()
      ) || site.asset_class.getValue();
    const laundry_location =
      find(
        this.selectorOptions["laundry_locations"],
        (t) => t.name === site.laundry_location.getValue()
      ) || site.laundry_location.getValue();

    let related_entities = [];
    let brand_flag = null;

    if (site.brand_company.value || site.brand_company_id_code.value) {
      const brand_type = _.chain(
        this.selectorOptions["related_company_options"]
      )
        .find((r) => r["name"] === COMPANY_TYPE.BRAND)
        .value();
      const brand_company = _.chain(brand_type)
        .get("companies", [])
        .find((c) => c.name === site.brand_company.value)
        .value();

      related_entities.push({
        id: new_entry_prefix + COMPANY_TYPE.BRAND,
        relation_type: {
          id: brand_type.id,
          name: brand_type.name,
        },
        company: brand_company
          ? {
              id: brand_company.id,
              name: brand_company.name,
            }
          : null,
        internal_company_code: site.brand_company_id_code.value,
      });
      brand_flag = site.brand_flag.getValue();
      if (brand_company) {
        const brand = _.chain(this.selectorOptions["brands"])
          .get(brand_company.id)
          .find((b) => b.name === site.brand_flag.value)
          .value();
        if (brand) {
          brand_flag = {
            id: brand.id,
            name: brand.name,
          };
        }
      }
    }

    if (
      site.asset_owner_company.value ||
      site.asset_owner_company_id_code.value
    ) {
      const asset_owner_type = _.chain(
        this.selectorOptions["related_company_options"]
      )
        .find((r) => r["name"] === COMPANY_TYPE.ASSET_OWNER)
        .value();
      const asset_owner_company = _.chain(asset_owner_type)
        .get("companies", [])
        .find((c) => c.name === site.asset_owner_company.value)
        .value();
      related_entities.push({
        id: new_entry_prefix + COMPANY_TYPE.ASSET_OWNER,
        relation_type: {
          id: asset_owner_type.id,
          name: asset_owner_type.name,
        },
        company: asset_owner_company
          ? {
              id: asset_owner_company.id,
              name: asset_owner_company.name,
            }
          : null,
        internal_company_code: site.asset_owner_company_id_code.value,
      });
    }

    if (
      site.management_company.value ||
      site.management_company_id_code.value
    ) {
      const management_type = _.chain(
        this.selectorOptions["related_company_options"]
      )
        .find((r) => r["name"] === COMPANY_TYPE.MANAGEMENT)
        .value();
      const management_company = _.chain(management_type)
        .get("companies", [])
        .find((c) => c.name === site.management_company.value)
        .value();
      related_entities.push({
        id: new_entry_prefix + COMPANY_TYPE.MANAGEMENT,
        relation_type: {
          id: management_type.id,
          name: management_type.name,
        },
        company: management_company
          ? {
              id: management_company.id,
              name: management_company.name,
            }
          : null,
        internal_company_code: site.management_company_id_code.value,
      });
    }

    return {
      row: site.index.value - 1,
      name: site.name.value,
      type: type,
      comment: site.comment.value,
      approved_domains: approvedDomains,
      location: {
        street_address: site.street_address.value?.replace(extraSpaceRegexPattern, " ").trim(),
        zip_code: site.zip_code.value,
        state_or_province: site.state_or_province.value?.replace(extraSpaceRegexPattern, " ").trim(),
        city: site.city.value?.replace(extraSpaceRegexPattern, " ").trim(),
        country: country
      },
      brand: brand_flag,
      other_identifier_code: site.other_identifier_code.value,
      related_entities: related_entities,
      about_attribute: {
        status: status,
        date_opened: site.date_opened.getValue(),
      },
      main_attribute: {
        default_currency: currency,
        metro_area: metro_area,
        koppen_geiger_climate_zone: koppen_geiger_climate_zone,
        baileys_ecoregion_climate_zone: baileys_ecoregion_climate_zone,
      },
      hotel_attribute: {
        expedia_star: site.expedia_star.getValue(),
        market_segment: market_segment,
        hotel_type: hotel_type,
        asset_class: asset_class,
        laundry_location: laundry_location,
        room_count: site.room_count.getValue(),
      },
    };
  }

  updateGridWithErrors = (grid, dataErrors) => {
    const changedGrid = grid.map((rowData) => {
      const site = SiteValue.fromRowData(rowData);
      site.removeAllErrors();
      return site.toGridRow();
    });

    for (const [row, err] of Object.entries(dataErrors)) {
      const reading = SiteValue.fromRowData(changedGrid[row]);
      Object.values(columns).forEach(({ key, errorKey }) => {
        reading[key].dataErrors = _.chain(err).get(errorKey, []).value();
      });
      changedGrid[row] = reading.toGridRow();
    }

    return changedGrid;
  };
}
