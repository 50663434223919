import React from "react";
import { dashboardContentContainerId } from "../Models/DashboardModels";

const DashboardLayout = ({ children }) => {
  return (
    <div
      id={dashboardContentContainerId}
      className="dashboard-content__container"
    >
      {children}
    </div>
  );
};

export default DashboardLayout;
