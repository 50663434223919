import {
  localStorageHomepageAnnouncementPopUpKey,
  localStorageNewDesignPopUpKey,
} from "../../config/constants";
import { useContext } from "react";
import UserProfileContext from "../../context/UserProfileContext";
import NewDesignPopUp from "../../gvds-components/PopUp/NewDesignPopUp";
import HomepageAnnouncementPopUp from "../../gvds-components/PopUp/HomepageAnnouncementPopUp";

const isShowNewDesignPopUpToBeShown = () => {
  const savedValues = JSON.parse(
    localStorage.getItem(localStorageNewDesignPopUpKey)
  );

  return !savedValues;
};

const LandingPagePopUp = ({ popUpAnnouncement }) => {
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const suppressPopUpAnnouncement = () => {
    if (!popUpAnnouncement) return;

    const savedValues =
      JSON.parse(
        localStorage.getItem(localStorageHomepageAnnouncementPopUpKey)
      ) || {};

    if (!savedValues[user.email]) {
      savedValues[user.email] = {};
    }

    savedValues[user.email][popUpAnnouncement.id] = {
      response: "suppressed",
      timestamp: new Date().toISOString(),
    };

    localStorage.setItem(
      localStorageHomepageAnnouncementPopUpKey,
      JSON.stringify(savedValues)
    );
  };

  const isRenderNewDesignPopUp = () => {
    if (isShowNewDesignPopUpToBeShown()) {
      suppressPopUpAnnouncement();
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {isRenderNewDesignPopUp() && <NewDesignPopUp />}
      {popUpAnnouncement && (
        <HomepageAnnouncementPopUp announcement={popUpAnnouncement} />
      )}
    </>
  );
};

export default LandingPagePopUp;
