import React, { forwardRef, useState } from "react";
import { DateTimeUtils } from "../../../services/UtilsService";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCalendarEvent } from "@tabler/icons-react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import Form from "react-bootstrap/Form";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import moment from "moment/moment";
import {
  FormFieldStatusMetadata,
  isEndDateBeforeStartDate,
} from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import Spacer from "../../../gvds-components/Layout/Spacer";
import { useTranslation } from "react-i18next";

const tooltipDelay = 300;
const tooltipPlacement = "top";

const QuickfillButtons = ({ setReportPeriod }) => {
  const handleYtd = () => {
    const startOfYear = moment().startOf("year").toDate();
    const lastFullMonth = moment()
      .subtract(1, "months")
      .endOf("month")
      .toDate();

    setReportPeriod(startOfYear, lastFullMonth);
  };

  const handleTrailing12Months = () => {
    const trailing12StartPeriod = moment()
      .subtract(12, "months")
      .startOf("month")
      .toDate();
    const trailing12EndPeriod = moment()
      .subtract(1, "months")
      .endOf("month")
      .toDate();

    setReportPeriod(trailing12StartPeriod, trailing12EndPeriod);
  };

  return (
    <div className="ms-auto quickfill-container on-form-label">
      <span className="quickfill-label">Quickfill:</span>
      <GVDSTextButton
        className="quickfill-input"
        text="YTD"
        onClick={handleYtd}
      />
      <GVDSTextButton
        className="quickfill-input"
        text="Trailing-12M"
        onClick={handleTrailing12Months}
      />
    </div>
  );
};

const PeriodControlDropdownTrigger = forwardRef(
  ({ start, end, onClick }, ref) => {
    const { t } = useTranslation();

    return (
      <OverlayTrigger
        delay={tooltipDelay}
        placement={tooltipPlacement}
        overlay={
          <Tooltip>
            {t("landing-page.base-widget.period-selector-tooltip")}
          </Tooltip>
        }
      >
        <div
          className="base-widget__heading-period-control__container"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <GVDSIcon Icon={IconCalendarEvent} />
          <div className="base-widget__heading-period-control__period">
            {DateTimeUtils.formatLocalMonthYear(start)} -{" "}
            {DateTimeUtils.formatLocalMonthYear(end)}
          </div>
        </div>
      </OverlayTrigger>
    );
  }
);

const CancelTrigger = forwardRef(({ onClick }, ref) => (
  <GVDSButton
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    variant={buttonVariant.tertiary}
    text="Cancel"
  />
));

const SaveTrigger = forwardRef(
  ({ onClick, handleSave, isInputDatesSameAsOriginalValues }, ref) => (
    <GVDSButton
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        handleSave();
        onClick(e);
      }}
      variant={buttonVariant.primary}
      text="Select"
      disabled={isInputDatesSameAsOriginalValues}
    />
  )
);

const BaseWidgetPeriodControl = ({ start, end, setStart, setEnd, onSave }) => {
  const lastFullMonth = moment().subtract(1, "months").endOf("month").toDate();

  const [inputStartDate, setInputStartDate] = useState(start);
  const [inputEndDate, setInputEndDate] = useState(end);

  const changeStartDate = (date) => {
    if (!date) return;
    setInputStartDate(moment(date).toDate());
  };

  const changeEndDate = (date) => {
    if (!date) return;
    setInputEndDate(moment(date).toDate());
  };

  const handleSave = () => {
    if (!isEndDateBeforeStartDate(inputStartDate, inputEndDate)) {
      setStart(inputStartDate);
      setEnd(inputEndDate);

      onSave(inputStartDate, inputEndDate);
    }
  };

  const resetInputDates = () => {
    setInputStartDate(start);
    setInputEndDate(end);
  };

  const isInputDatesSameAsOriginalValues =
    moment(start).isSame(inputStartDate) && moment(end).isSame(inputEndDate);

  return (
    <Dropdown
      className="base-widget__heading-period-control"
      align="end"
      onToggle={() => resetInputDates()}
    >
      <Dropdown.Toggle
        as={PeriodControlDropdownTrigger}
        start={start}
        end={end}
      />
      <Dropdown.Menu
        className="base-widget__heading-period-control-dropdown__menu"
        flip={false}
      >
        <div className="base-widget__heading-period-control-dropdown__content">
          <GVDSFormStartEndMonthPicker
            startMonthLabel={<Form.Label>Period</Form.Label>}
            endMonthLabel={
              <QuickfillButtons
                setReportPeriod={(start, end) => {
                  changeStartDate(start);
                  changeEndDate(end);
                }}
              />
            }
            startMonth={inputStartDate}
            endMonth={inputEndDate}
            onChange={(paramStartMonth, paramEndMonth) => {
              if (paramStartMonth !== start) {
                changeStartDate(paramStartMonth);
              }
              if (paramEndMonth !== end) {
                changeEndDate(paramEndMonth);
              }
            }}
            maxStartMonth={lastFullMonth}
            maxEndMonth={lastFullMonth}
            statusMetadata={
              start &&
              end &&
              isEndDateBeforeStartDate(inputStartDate, inputEndDate)
                ? FormFieldStatusMetadata.getError(
                    "End date cannot be before start date"
                  )
                : FormFieldStatusMetadata.getDefault()
            }
            isPortalling={false}
          />
          <div className="base-widget__heading-period-control-dropdown__footer">
            {!isInputDatesSameAsOriginalValues && (
              <div className="gvds-text--modal-warning-text">
                Date changes won't be applied until you press Select
              </div>
            )}
            <Spacer />
            <Dropdown.Item as={CancelTrigger} />
            <Dropdown.Item
              as={SaveTrigger}
              handleSave={handleSave}
              isInputDatesSameAsOriginalValues={
                isInputDatesSameAsOriginalValues
              }
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BaseWidgetPeriodControl;
