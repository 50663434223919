import InventoryFilterView from "./InventoryFilterView";
import { useContext, useEffect } from "react";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { RESOURCES } from "../../../config/constants";
import DashboardDataContext from "../Context/DashboardDataContext";
import InlineSpinner from "../../common/InlineSpinner";

const transformSiteInventoryData = (allSitesData, portfolioTreeNode) => {
  if (!allSitesData) {
    return [];
  }

  const siteIds = [];
  const facilityIds = [];

  portfolioTreeNode.children.forEach((c) => {
    if (c.nodeValue.type === RESOURCES.SITE) {
      siteIds.push(c.nodeValue.id);
    } else if (c.nodeValue.type === RESOURCES.FACILITY) {
      facilityIds.push(c.nodeValue.id);
    }
  });

  return allSitesData
    .map((site) => {
      if (siteIds.includes(site.id)) {
        return {
          id: site.id,
          name: site.name,
          facilities: site.all_facilities.map((facility) => ({
            id: facility.id,
            name: facility.name,
          })),
        };
      } else {
        const includedFacilities = site.all_facilities
          .filter((facility) => facilityIds.includes(facility.id))
          .map((facility) => ({
            id: facility.id,
            name: facility.name,
          }));

        if (includedFacilities.length > 0) {
          return {
            id: site.id,
            name: site.name,
            facilities: includedFacilities,
          };
        } else {
          return null;
        }
      }
    })
    .filter((s) => s !== null)
    .sort((siteData1, siteData2) => {
      return siteData1.name
        .toLowerCase()
        .localeCompare(siteData2.name.toLowerCase());
    });
};

const InventoryFilter = () => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const selectedConfigHolder = dashboardDataContext.selectedConfigHolder;

  const userInventory = useContext(UserInventoryContext);
  const currentInventory = userInventory.selectedInventory.get;

  let inventoryFilterOptionData = null;

  if (currentInventory && currentInventory.type === RESOURCES.PORTFOLIO) {
    const allSitesUnderContract = userInventory.sitesUnderSelectedContract.get;
    const inventoryTreeNode = userInventory.selectedTreeNode.get;

    inventoryFilterOptionData = transformSiteInventoryData(
      allSitesUnderContract,
      inventoryTreeNode
    );
  }

  useEffect(() => {
    if (currentInventory) {
      if (currentInventory.type === RESOURCES.PORTFOLIO) {
        selectedConfigHolder.setInventoryFilter(inventoryFilterOptionData);
      } else {
        selectedConfigHolder.setInventoryFilter(null);
      }

      dashboardDataContext.onUpdateConfig();
    }
  }, [currentInventory]);

  const setInventoryFilter = (selectedInventories) => {
    selectedConfigHolder.setInventoryFilter(selectedInventories);
    dashboardDataContext.onUpdateConfig();
  };

  if (!currentInventory || currentInventory.type !== RESOURCES.PORTFOLIO) {
    return null;
  } else if (selectedConfigHolder.inventoryFilter === null) {
    return (
      <div>
        <InlineSpinner />
      </div>
    );
  }

  return (
    <InventoryFilterView
      sites={inventoryFilterOptionData}
      selectedSites={selectedConfigHolder.inventoryFilter}
      setSelectedSites={setInventoryFilter}
    />
  );
};

export default InventoryFilter;
