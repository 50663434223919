import React, { useContext } from "react";
import WorkspaceNav from "./TopNavComponents/WorkspaceNav";
import Spacer from "../Layout/Spacer";
import TopNavItem from "./TopNavComponents/TopNavItem";
import GVDSIcon from "../Icons/GVDSIcon";
import { IconFolder, IconHelp } from "@tabler/icons-react";
import FileStorageStateContext from "../../context/FileStorageStateContext";
import GVDSCustomIcons from "../Icons/GVDSIconCustom";
import FeatureContext from "../../context/FeatureContext";
import UserInventoryContext from "../../context/UserInventoryContext";
import WorkspaceInfoContext, {
  WorkspaceInfoProvider,
} from "./WorkspaceInfoContext";
import WorkspaceInfo from "./WorkspaceInfo";
import FloatingAssistantContext from "../../components/FloatingAssistant/FloatingAssistantContext";
import UserProfileContext from "../../context/UserProfileContext";
import { useTranslation } from "react-i18next";

const WorkspaceInfoNavItem = () => {
  const workspaceInfoContext = useContext(WorkspaceInfoContext);
  const userInventory = useContext(UserInventoryContext);
  const featureCtx = useContext(FeatureContext);
  const allFeatures = featureCtx.features;
  const isLoadingFeatures = featureCtx.isLoadingFeatures;

  let icon = <GVDSCustomIcons.Info />;
  if (!isLoadingFeatures && userInventory.selectedTreeNode.get) {
    const inventoryFeatureNames =
      userInventory.selectedTreeNode.get.nodeValue.value.features;

    const allFeaturesNotLoadedProperly =
      allFeatures.length < inventoryFeatureNames.length;

    const featuresDisplay = allFeaturesNotLoadedProperly
      ? inventoryFeatureNames.map((f) => ({
          id: f,
          name: f,
          displayName: f,
          isEnabled: true,
        }))
      : allFeatures.map((f) => ({
          id: f.id,
          name: f.name,
          displayName: f.display_name,
          isEnabled: inventoryFeatureNames.includes(f.name),
        }));

    const isFullFeature =
      allFeaturesNotLoadedProperly || featuresDisplay.every((f) => f.isEnabled);

    if (isFullFeature) {
      icon = <GVDSCustomIcons.Info className="workspace-info--full-feature" />;
    }
  }

  return (
    <TopNavItem
      icon={icon}
      className="icon-button"
      onClick={() => workspaceInfoContext.open()}
    />
  );
};

const TopNav = () => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const fileStorageStateContext = useContext(FileStorageStateContext);
  const floatingAssistantContext = useContext(FloatingAssistantContext);
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const isPortalAdmin = !!user?.is_admin;

  return (
    <WorkspaceInfoProvider>
      <div className="gvds-top-nav">
        <WorkspaceNav />
        {isPortalAdmin && <WorkspaceInfoNavItem />}
        <Spacer />
        {!userInventory.isInventoryEmpty.get && (
          <TopNavItem
            text={t("top-nav.file-storage")}
            icon={<GVDSIcon Icon={IconFolder} />}
            onClick={() => fileStorageStateContext.open()}
            className="guided-tour-target__new-look__file-storage"
          />
        )}
        <TopNavItem
          text={t("top-nav.help")}
          icon={<GVDSIcon Icon={IconHelp} />}
          onClick={() => floatingAssistantContext.toggle()}
          className="guided-tour-target__new-look__help"
        />
      </div>
      <WorkspaceInfo />
    </WorkspaceInfoProvider>
  );
};

export default TopNav;
