import React, { useContext, useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import {
  downloadFromPresignedS3Url,
  MultipleFilesUploader,
} from "../../../common/FileAttachments";
import withAuthentication from "../../../HOC/withAuthentication";
import LoadingSpinner from "../../../common/LoadingSpinner";
import BenchmarkService from "../../../../services/BenchmarkService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DateTimeUtils } from "../../../../services/UtilsService";
import Form from "react-bootstrap/Form";
import ToastContext from "../../../../context/ToastContext";
import GVFormGroup from "../../../common/GVFormGroup";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import InlineSpinner from "../../../common/InlineSpinner";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSFormSingleYearPicker from "../../../../gvds-components/Forms/GVDSFormSingleYearPicker";

const UploadBenchmarkDataModal = ({
  show,
  onClose,
  currentRecord,
  onUpload,
  existingDocs = [],
}) => {
  const toastContext = useContext(ToastContext);
  const disableYearPicker = !!currentRecord?.year;

  const [isLoading, setIsLoading] = useState(false);
  const [uploadYear, setUploadYear] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);

  useEffect(() => {
    setUploadYear(currentRecord?.year);
  }, [currentRecord]);

  const onModalClose = () => {
    setUploadFiles([]);
    onClose();
  };

  const saveBenchmark = () => {
    setIsLoading(true);
    const doc = existingDocs.find((d) => d.year === uploadYear);
    const httpRequest = doc
      ? BenchmarkService.updateBenchmarkData(doc.id, uploadFiles[0])
      : BenchmarkService.saveBenchmarkData(uploadYear, uploadFiles[0]);

    httpRequest
      .then(() => {
        toastContext.addSuccessToast(
          <span>Benchmark Data was successfully uploaded.</span>
        );
        setIsLoading(false);
        onUpload();
        onModalClose();
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to upload benchmark data.</span>
        );
      });
  };
  let content;
  if (isLoading) {
    content = (
      <div className="p-3">
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <>
        <GVDSModal.Body>
          <div className="body-2">
            Upload the CHSB Index Tool file with only the appropriate sheets
            used for benchmarking data.
          </div>

          <div className="body-2 fst-italic pt-3 pb-3">
            Note: This action will replace an existing file for the selected
            year.
          </div>
          <Row>
            <GVFormGroup as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Year</Form.Label>
              <GVDSFormSingleYearPicker
                disabled={disableYearPicker}
                placeholder="Select a year"
                selected={uploadYear}
                onChange={(year) => setUploadYear(year)}
              />
            </GVFormGroup>
          </Row>
          <MultipleFilesUploader
            files={uploadFiles}
            setFiles={setUploadFiles}
          />
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={onModalClose}
            text="Cancel"
          />

          <GVDSButton
            variant={buttonVariant.primary}
            disabled={!uploadYear || uploadFiles.length !== 1}
            onClick={saveBenchmark}
            text="Upload"
          />
        </GVDSModal.Footer>
      </>
    );
  }

  return (
    <GVDSModal
      show={show}
      onHide={onModalClose}
      title="Upload File"
      size={GVDSModal.Size.small}
      icon={<GVDSIcon Icon={IconCirclePlus} />}
      variant={buttonVariant.primary}
    >
      {content}
    </GVDSModal>
  );
};

const ViewAllBenchmarkData = () => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);

  const [isLoading, setIsLoading] = useState(true);
  const [allBenchmarkData, setAllBenchmarkData] = useState([]);
  const [deletedReferenceId, setCurrentlyDeletedReference] = useState("");
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentlyEditRecord, setCurrentlyEditRecord] = useState({});
  const [downloadingFilepath, setDownloadingFilepath] = useState("");

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allBenchmarkData, null, {
    filename: SORTING_TYPES.asc,
  });

  const loadRecords = () => {
    setIsLoading(true);
    BenchmarkService.getAllBenchmarkData()
      .then((benchmarkDataResponse) => {
        setAllBenchmarkData(benchmarkDataResponse);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load benchmarks data.</span>);
      });
  };

  useEffect(() => {
    loadRecords();
  }, []);

  const showEditRecord = (benchmark) => {
    setShowUploadModal(true);
    setCurrentlyEditRecord(benchmark);
  };

  const downloadFile = async (filepath) => {
    try {
      setDownloadingFilepath(filepath);
      const selectedInventory = userInventory.selectedInventory.get;
      const params = {
        resource_type: selectedInventory.type,
        resource_id: selectedInventory.id,
      };
      await downloadFromPresignedS3Url(filepath, params);

      setDownloadingFilepath("");
    } catch (e) {
      setDownloadingFilepath("");
      toastContext.addFailToast(<span>Failed to download file</span>);
    }
  };

  const promptDeleteRecord = (benchmark) => {
    setCurrentlyDeletedReference(benchmark.id);
    setShowPromptDeleteModal(true);
  };

  const deleteCurrentlySelectedReference = () => {
    BenchmarkService.deleteBenchmarkReference(deletedReferenceId)
      .then(() => {
        toastContext.addSuccessToast(
          <span>Benchmark Data was successfully deleted.</span>
        );
        loadRecords();
        handleClosePromptDeleteModal();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to delete Benchmark Data.</span>
        );
      });
  };

  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  const onUploadModalClose = () => {
    setShowUploadModal(false);
    setCurrentlyEditRecord({});
  };

  const columns = [
    {
      header: "Year",
      dataKey: "year",
      sortable: true,
      renderer: (b) => <div className="site-name">{b.year}</div>,
    },
    {
      header: "File Name",
      dataKey: "filename",
      renderer: (b) => (
        <div className="site-name">
          <Button
            variant="link"
            size="sm"
            onClick={() => downloadFile(b.filepath)}
            disabled={downloadingFilepath === b.filepath}
          >
            {downloadingFilepath === b.filepath && <InlineSpinner />}
            {downloadingFilepath !== b.filepath && b.filename}
          </Button>
        </div>
      ),
    },
    {
      header: "Uploaded By",
      dataKey: "created_by",
      renderer: (b) => (
        <div className="users">
          {b.created_by}
          <span className="caption fst-italic">
            &nbsp;({DateTimeUtils.formatUTCDate(b.created_on)})
          </span>
        </div>
      ),
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (b) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => {
                showEditRecord(b);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                promptDeleteRecord(b);
              }}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          </>
        );
      },
    },
  ];

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <div>
        <GVDSTableCtrlContainer>
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        </GVDSTableCtrlContainer>
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
        />
        {allBenchmarkData.length === 0 && (
          <div className="table__no_content">No benchmark available</div>
        )}
        <GVDSModal
          show={showPromptDeleteModal}
          onHide={handleClosePromptDeleteModal}
          title="Delete Benchmark Data File"
          size={GVDSModal.Size.small}
        >
          <GVDSModal.Body>
            This action cannot be undone. Are you sure?
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={handleClosePromptDeleteModal}
              text="Cancel"
            />
            <GVDSButton
              variant={buttonVariant.destructive_primary}
              onClick={deleteCurrentlySelectedReference}
              text="Yes, Delete."
            />
          </GVDSModal.Footer>
        </GVDSModal>
        <UploadBenchmarkDataModal
          show={showUploadModal}
          onClose={onUploadModalClose}
          currentRecord={currentlyEditRecord}
          existingDocs={allBenchmarkData}
          onUpload={loadRecords}
        />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => setShowUploadModal(true)}
            text="Upload File"
            icon={<GVDSIcon Icon={IconCirclePlus} />}
          />
        </PageHeader.Title>
      </PageHeader>
      {content}
    </Container>
  );
};

export default withAuthentication(ViewAllBenchmarkData);
