import React, { useContext, useEffect, useState } from "react";

import withAuthentication from "../../HOC/withAuthentication";
import { getAdminFacilityDetailsPath } from "../../../config/ROUTES_NAME";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import PermissionsContext from "../../../context/PermissionsContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import SiteService from "../../../services/SiteService";
import ToastContext from "../../../context/ToastContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { usePrevious } from "../../common/ReactHook";
import CreateFacility from "./CreateFacility";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import { Container } from "react-bootstrap";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import {useTranslation} from "react-i18next";

const ViewAllFacilities = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const toastContext = useContext(ToastContext);
  const permissionsCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const [siteDetails, setSiteDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (
      selectedInventory &&
      (!previousInventory || selectedInventory.id !== previousInventory.id)
    ) {
      if (selectedInventory.type === RESOURCES.SITE) {
        SiteService.getSite(selectedInventory.id)
          .then((data) => {
            setSiteDetails(data);
          })
          .catch((e) => {
            toastContext.addFailToast(
              <span>Failed to load facilities. Please try again.</span>
            );
          })
          .finally(() => setIsLoading(false));
      }
    }
  }, [selectedInventory]);

  let content;
  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else if (siteDetails && selectedInventory) {
    content = (
      <Container fluid>
        <PageHeader>
          <PageHeader.Title>
            <h1>{t("admin.facilities.page-title")}</h1>
            {!permissionsCtx.isLoadingPermissions &&
              permissionsCtx.permissions[
                PERMISSIONS.SITE_FACILITY_MANAGEMENT
              ] && (
                <>
                  <Spacer />
                  <CreateFacility
                    siteId={selectedInventory.id}
                    onCreate={() => {
                      userInventory.loadUserInventory();
                    }}
                  />
                </>
              )}
          </PageHeader.Title>
        </PageHeader>
        <GVDSTableDisplay className="facility-table">
          <thead>
            <tr>
              <th>Facility Name</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {siteDetails.all_facilities.map((facility) => {
              return (
                <tr
                  key={facility.id}
                  className="is-clickable"
                  onClick={() => {
                    const facilityUrl = getRedirectURLWithCurrentParam(
                      getAdminFacilityDetailsPath(facility.id),
                      location
                    );

                    history.push(facilityUrl);
                  }}
                >
                  <td className="facility-name">
                    <Link
                      to={getRedirectURLWithCurrentParam(
                        getAdminFacilityDetailsPath(facility.id),
                        location
                      )}
                    >
                      {facility.name}
                    </Link>
                  </td>
                  <td>{facility.type.name}</td>
                </tr>
              );
            })}
          </tbody>
        </GVDSTableDisplay>
      </Container>
    );
  }

  return content;
};
export default withAuthentication(ViewAllFacilities);
