import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

const MAX_COUNT_CUTOFF = 10;

const PeopleGroupingCategoryDisplay = ({ categories }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (categories.length <= MAX_COUNT_CUTOFF) {
    return (
      <>
        {categories.map((category) => {
          return (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td>{category.definition}</td>
            </tr>
          );
        })}
      </>
    );
  } else {
    if (isExpanded) {
      return (
        <>
          <tr key="collapse">
            <Button
              size="sm"
              variant="link"
              onClick={() => setIsExpanded(false)}
              className="fst-italic"
            >
              (hide categories)
            </Button>
          </tr>
          {categories.map((category) => {
            return (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>{category.definition}</td>
              </tr>
            );
          })}
        </>
      );
    } else {
      return (
        <tr>
          <td>
            <Button
              size="sm"
              variant="link"
              onClick={() => setIsExpanded(true)}
              className="fst-italic"
            >
              (show categories)
            </Button>
          </td>
          <td />
        </tr>
      );
    }
  }
};

const PeopleGroupingsTable = ({ groupings = [] }) => {
  if (groupings.length === 0) return null;
  return (
    <div className="people-glossary-table-container">
      <Table className="people-glossary-table">
        <tbody>
          {groupings.map((grouping) => {
            return (
              <React.Fragment key={`container-${grouping.id}`}>
                <tr key={grouping.id} className="people-grouping-row">
                  <th>{grouping.name}</th>
                  <td>{grouping.definition}</td>
                </tr>
                <PeopleGroupingCategoryDisplay
                  categories={grouping.categories}
                />
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PeopleGroupingsTable;
