import axios from "axios";
import { API_URL } from "../config/api-config";
import moment from "moment";
import { DateTimeUtils, RTFUtils } from "./UtilsService";
import { cloneDeep, uniqueId } from "lodash";
import { new_entry_prefix } from "../config/constants";

export const getPlainTextFromHTML = (html) => {
  const contentState = RTFUtils.convertHTMLToEditorState(html);
  return contentState.getCurrentContent().getPlainText();
};

export const HomepageAnnouncementStatusEnum = {
  UPCOMING: "UPCOMING",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
};

export const HOMEPAGE_ANNOUNCEMENT_ORDER = {
  [HomepageAnnouncementStatusEnum.ACTIVE]: 0,
  [HomepageAnnouncementStatusEnum.UPCOMING]: 1,
  [HomepageAnnouncementStatusEnum.EXPIRED]: 2,
};

export class SystemToolboxHomepageAnnouncementModel {
  constructor(
    id = uniqueId(new_entry_prefix),
    title = "",
    description = "",
    startDate = null,
    endDate = null,
    isOnetimePopup = false,
    firstCtaText = "",
    firstCtaUrl = "",
    secondCtaText = "",
    secondCtaUrl = "",
    createdOn = null,
    createdBy = null,
    updatedOn = null,
    updatedBy = null
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.isOneTimePopup = isOnetimePopup;
    this.firstCtaText = firstCtaText;
    this.firstCtaUrl = firstCtaUrl;
    this.secondCtaText = secondCtaText;
    this.secondCtaUrl = secondCtaUrl;
    this.createdOn = createdOn;
    this.createdBy = createdBy;
    this.updatedOn = updatedOn;
    this.updatedBy = updatedBy;
  }

  static fromDTO(homepageAnnouncementDTO) {
    return new SystemToolboxHomepageAnnouncementModel(
      homepageAnnouncementDTO.id,
      homepageAnnouncementDTO.title,
      homepageAnnouncementDTO.description,
      homepageAnnouncementDTO.start_date,
      homepageAnnouncementDTO.end_date,
      homepageAnnouncementDTO.one_time_popup,
      homepageAnnouncementDTO.first_cta_text,
      homepageAnnouncementDTO.first_cta_url,
      homepageAnnouncementDTO.second_cta_text,
      homepageAnnouncementDTO.second_cta_url,
      homepageAnnouncementDTO.created_on,
      homepageAnnouncementDTO.created_by,
      homepageAnnouncementDTO.updated_on,
      homepageAnnouncementDTO.updated_by
    );
  }

  get lastActivity() {
    return {
      action_by: this.updatedBy ? this.updatedBy : this.createdBy,
      action_on: this.updatedOn
        ? moment.utc(this.updatedOn)
        : moment.utc(this.createdOn),
    };
  }

  get status() {
    const currentDate = moment().startOf("day");
    const startPeriod = moment(this.startDate).startOf("day");
    const endPeriod = moment(this.endDate).endOf("day");

    if (startPeriod.isAfter(currentDate)) {
      return HomepageAnnouncementStatusEnum.UPCOMING;
    } else if (currentDate.isBetween(startPeriod, endPeriod, "day", "[]")) {
      return HomepageAnnouncementStatusEnum.ACTIVE;
    } else {
      return HomepageAnnouncementStatusEnum.EXPIRED;
    }
  }

  get startPeriodDisplay() {
    return DateTimeUtils.formatLocalDate(this.startDate);
  }

  get endPeriodDisplay() {
    return DateTimeUtils.formatLocalDate(this.endDate);
  }

  get periodDisplay() {
    return DateTimeUtils.formatLocalPeriod(this.startDate, this.endDate);
  }

  get plainTextDescription() {
    return getPlainTextFromHTML(this.description);
  }

  copyAnnouncement() {
    const originalAnnouncementModel = cloneDeep(this);
    return new SystemToolboxHomepageAnnouncementModel(
      null,
      `Copy of ${originalAnnouncementModel.title}`,
      originalAnnouncementModel.description,
      originalAnnouncementModel.startDate,
      originalAnnouncementModel.endDate,
      originalAnnouncementModel.isOneTimePopup,
      originalAnnouncementModel.firstCtaText,
      originalAnnouncementModel.firstCtaUrl,
      originalAnnouncementModel.secondCtaText,
      originalAnnouncementModel.secondCtaUrl
    );
  }
}

export default class HomepageAnnouncementAdminService {
  static getAnnouncements = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/homepage_announcements`
      );
      return response.data.map((announcementDTO) =>
        SystemToolboxHomepageAnnouncementModel.fromDTO(announcementDTO)
      );
    } catch (error) {
      throw error;
    }
  };

  static createAnnouncement = async (
    title,
    description,
    start_date,
    end_date,
    one_time_popup,
    first_cta_text,
    first_cta_url,
    second_cta_text,
    second_cta_url
  ) => {
    try {
      const data = {
        title,
        description,
        start_date,
        end_date,
        one_time_popup,
        first_cta_text,
        first_cta_url,
        second_cta_text,
        second_cta_url,
      };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/homepage_announcements`,
        {
          ...data,
          start_date: DateTimeUtils.getUTCISOString(start_date),
          end_date: DateTimeUtils.getUTCISOString(end_date),
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editAnnouncement = async (
    announcement_id,
    title,
    description,
    start_date,
    end_date,
    one_time_popup,
    first_cta_text,
    first_cta_url,
    second_cta_text,
    second_cta_url
  ) => {
    try {
      const data = {
        title,
        description,
        start_date,
        end_date,
        one_time_popup,
        first_cta_text,
        first_cta_url,
        second_cta_text,
        second_cta_url,
      };

      const response = await axios.put(
        `${API_URL}/api/v1/admin/homepage_announcements/${announcement_id}`,
        {
          ...data,
          start_date: DateTimeUtils.getUTCISOString(start_date),
          end_date: DateTimeUtils.getUTCISOString(end_date),
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteAnnouncement = async (announcementId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/admin/homepage_announcements/${announcementId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static announcementSortFn = (announcement1, announcement2) => {
    const status1 = announcement1.status;
    const status2 = announcement2.status;

    const sortOrder1 = HOMEPAGE_ANNOUNCEMENT_ORDER[status1.toUpperCase()];
    const sortOrder2 = HOMEPAGE_ANNOUNCEMENT_ORDER[status2.toUpperCase()];

    if (sortOrder1 !== sortOrder2) {
      return sortOrder1 - sortOrder2;
    }

    const startDate1 = moment(announcement1.startDate);
    const startDate2 = moment(announcement2.startDate);

    return startDate1.diff(startDate2);
  };
}
