import React, { useContext, useEffect, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import PerformanceReportChartService, {
  PERFORMANCE_CHART_COLOR,
  PERFORMANCE_CHART_TYPE,
} from "./PerformanceReportChartService";
import domtoimage from "dom-to-image";
import { HtmlService } from "../../../../services/UtilsService";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import sortBy from "lodash/sortBy";
import capitalize from "lodash/capitalize";
import {
  PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY,
  PERFORMANCE_REPORT_TYPE,
} from "../PerformanceYearlyReportModel";
import PerformanceReportLineChart from "./PerformanceReportLineChart";
import PerformanceReportBenchmarkChart from "./PerformanceReportBenchmarkChart";
import PerformanceBenchmarkReportModel from "../PerformanceBenchmarkReportModel";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../../common/Feature/FeatureCheckPageWrapper";
import { FEATURES } from "../../../../config/constants";
import UserProfileContext from "../../../../context/UserProfileContext";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";

const chartViewId = "performance-report-chart-view";

const PerformanceReportChart = ({
  reportParams,
  reportConfig,
  report,
  benchmark,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const hasFeaturePerformanceBenchmark =
    getSubscriptionFeatureStatus(
      [FEATURES.REPORT__PERFORMANCE_BENCHMARK],
      userInventory.isLoadingInventory.get,
      userInventory.selectedTreeNode,
      userProfile
    ) === SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;
  const benchmarkDisplayable =
    new PerformanceBenchmarkReportModel(benchmark).isDisplayable() &&
    hasFeaturePerformanceBenchmark;
  const [selectedType, setSelectedType] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (report && report["report_type"]) {
      if (report["report_type"] === PERFORMANCE_REPORT_TYPE.OPERATIONAL_ONLY) {
        setSelectedType(PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY);
      } else {
        setSelectedType(sortBy(report["column_groups"], "seq")[0]["data_key"]);
      }
    }
  }, [report]);

  const downloadChart = () => {
    setIsDownloading(true);
    setTimeout(async () => {
      const element = document.getElementById(chartViewId);
      const dataUrl = await domtoimage.toPng(element, {
        bgcolor: PERFORMANCE_CHART_COLOR.WHITE,
      });
      HtmlService.downloadFileFromUrl(
        dataUrl,
        PerformanceReportChartService.getDownloadImageName(
          userInventory.selectedTreeNode.get?.nodeValue?.name || "",
          report["title"],
          selectedType,
          PerformanceReportChartService.getReportYears(report)
        )
      );
      setIsDownloading(false);
    }, 500);
  };

  if (!report || !report["yearly_reports"]) return null;

  const isSingleType = reportParams["use_performance_group"] === false;
  let singleTypeEnvSubtopicName = null;

  if (isSingleType) {
    const envTypeId = reportParams["environmental_type_id"]
    const subtopicConfig = reportConfig["environmental_subtopics"].find((subtopicConfig) => {
      return !!subtopicConfig["environmental_types"].find((envType) => envType["id"] === envTypeId)
    });

    singleTypeEnvSubtopicName = subtopicConfig.name;
  }

  return (
    <div className="performance-report-chart">
      <div className="d-flex justify-content-center mb-2">
        {report["report_type"] !== PERFORMANCE_REPORT_TYPE.OPERATIONAL_ONLY && (
          <ButtonGroup className="big-btn-group">
            {sortBy(report["column_groups"], "seq").map((c, index) => (
              <GVDSButton
                key={index}
                variant={buttonVariant.tertiary}
                className={
                  selectedType === c["data_key"]
                    ? "gvds-button-group--selected"
                    : "gvds-button-group--not-selected"
                }
                onClick={() => setSelectedType(c["data_key"])}
                text={capitalize(c["data_key"])}
              />
            ))}
            {benchmarkDisplayable && (
              <GVDSButton
                variant={buttonVariant.tertiary}
                className={
                  selectedType === PERFORMANCE_CHART_TYPE.BENCHMARK
                    ? "gvds-button-group--selected"
                    : "gvds-button-group--not-selected"
                }
                onClick={() =>
                  setSelectedType(PERFORMANCE_CHART_TYPE.BENCHMARK)
                }
                text="CHSB Benchmark"
              />
            )}
          </ButtonGroup>
        )}
      </div>
      <div className="d-flex">
        <div className="ms-auto">
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={downloadChart}
            text="Download Chart"
          />
        </div>
      </div>
      <div id={chartViewId}>
        {selectedType !== PERFORMANCE_CHART_TYPE.BENCHMARK && (
          <div className="performance-report-chart-view">
            <div className="performance-report-line-chart-view">
              <PerformanceReportLineChart
                showAsPercentage={report["show_as_percentage"]}
                valueType={selectedType}
                report={report}
                useLocationBased={reportParams["use_location_based"]}
                reportMonths={reportParams["months"]}
                isSingleType={isSingleType}
                singleTypeEnvSubtopicName={singleTypeEnvSubtopicName}
              />
            </div>
          </div>
        )}
        {selectedType === PERFORMANCE_CHART_TYPE.BENCHMARK &&
          benchmarkDisplayable && (
            <div className="performance-report-chart-view">
              <PerformanceReportBenchmarkChart
                benchmark={benchmark}
                report={report}
                isDownloading={isDownloading}
                operationalMetric={reportParams["operational_metric"]}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default PerformanceReportChart;
