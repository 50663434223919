import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Popover } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import { UserUtils } from "../../services/UtilsService";

const UserAvatar = ({ fullName, userEmail }) => {
  const userAttribute = UserUtils.getUserAttribute(userEmail, fullName);

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Popover>
            <Popover.Body>
              {UserUtils.getFullNameOrEmail(fullName, userEmail)}
            </Popover.Body>
          </Popover>
        }
      >
        <Button
          className={
            "gvds-avatar " +
            UserUtils.getAvatarColor(userAttribute.hash)
          }
        >
          {userAttribute.initial}
        </Button>
      </OverlayTrigger>
    </>
  );
};

export default UserAvatar;
