const AWSConfig = process.env.REACT_APP_PROD
  ? {
      REGION: process.env.REACT_APP_PROD_REGION,
      USER_POOL_ID: process.env.REACT_APP_PROD_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_PROD_APP_CLIENT_ID,
    }
  : {
      REGION: "ap-south-1",
      USER_POOL_ID: "ap-south-1_MNzaoPMZZ",
      APP_CLIENT_ID: "70a779puhq76n4naoef2vl64kc",
    };

export default AWSConfig;
