import JoinedBullet from "../../../common/JoinedBullet";
import {
  barChartMaxCount,
  summarySiteRankingExpandedViewExcludeOnDownloadContainerId,
  summarySiteRankingMiniViewExcludeOnDownloadContainerId,
} from "./SummarySiteRankingViewConfig";
import GVDSFormSingleSelect from "../../../../gvds-components/Forms/GVDSFormSingleSelect";
import { siteRankingSortOptions } from "../DashboardWidgetSharedUtils";
import DashboardMinifiedHeaderControls from "../DashboardMinifiedHeaderControls";
import React, { useContext } from "react";
import DashboardExpandedHeaderControls from "../DashboardExpandedHeaderControls";
import DashboardDataGapsIndicator from "../DashboardDataGapsIndicator";
import DashboardDataContext from "../../Context/DashboardDataContext";

export const SummarySiteRankingChartViewHeader = ({
  title,
  subtopic,
  intensity,
  selectedSiteCount,
  hasDataGaps,
  period,
  showExpandedChartView,
  showExpandedTableView,
  sortOrder,
  onSortOrderChange,
  hasData,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  const dashboardDataContext = useContext(DashboardDataContext);

  let siteCountLabel;
  if (selectedSiteCount > barChartMaxCount) {
    siteCountLabel = `showing first ${barChartMaxCount} sites (expand to see all)`;
  } else {
    siteCountLabel = `showing first ${selectedSiteCount} sites`;
  }

  return (
    <div className="header-container">
      <div>
        <div className="title-container">
          <div className="title">{title}</div>
          {hasDataGaps && <DashboardDataGapsIndicator />}
          {hasData && (
            <>
              <JoinedBullet className="dashboard-joined-bullet" />
              <div className="site-count-label">{siteCountLabel}</div>
            </>
          )}
        </div>
        <div className="subtitle-container">
          <div className="subtitle">{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div className="subtitle">
            {subtopic} {intensity ?? ""}
          </div>
        </div>
      </div>
      {!dashboardDataContext.isDownloadingDashboardAsImage && (
        <div
          id={summarySiteRankingMiniViewExcludeOnDownloadContainerId}
          className="dashboard-chart-header__action-buttons"
        >
          <GVDSFormSingleSelect
            className="dashboard--sort-chart-data__single-select"
            value={sortOrder}
            options={siteRankingSortOptions}
            onSelect={onSortOrderChange}
            disabled={!hasData}
          />
          <DashboardMinifiedHeaderControls
            showExpandedTableView={showExpandedTableView}
            showExpandedChartView={showExpandedChartView}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
            disabled={!hasData}
            isDownloading={isDownloadingChart}
          />
        </div>
      )}
    </div>
  );
};

export const ExpandedSummarySiteRankingChartViewHeader = ({
  title,
  period,
  subtopic,
  intensity,
  hasDataGaps,
  selectedSiteCount,
  selectedViewOption,
  onSelectViewOption,
  sortOrder,
  onSortOrderChange,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  return (
    <div className="dashboard--summary-site-ranking__expanded-chart-view__header-container">
      <div>
        <div className="expanded-chart-view__title-container">
          <div className="expanded-chart-view__title">{title}</div>
          {hasDataGaps && <DashboardDataGapsIndicator />}
          <JoinedBullet className="dashboard-joined-bullet" />
          <div className="site-count-label">{selectedSiteCount} sites</div>
        </div>
        <div className="expanded-chart-view__subtitle">
          <div>{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div>
            {subtopic} {intensity ?? ""}
          </div>
        </div>
      </div>
      <div
        id={summarySiteRankingExpandedViewExcludeOnDownloadContainerId}
        className="dashboard-chart-header__action-buttons"
      >
        <GVDSFormSingleSelect
          className="expanded-chart-view__sort-select"
          value={sortOrder}
          options={siteRankingSortOptions}
          onSelect={onSortOrderChange}
        />
        <DashboardExpandedHeaderControls
          selectedViewOption={selectedViewOption}
          onSelectViewOption={onSelectViewOption}
          onExportChart={downloadChartAsImage}
          onDownloadTableAsExcel={onDownloadTableAsExcel}
          isDownloading={isDownloadingChart}
        />
      </div>
    </div>
  );
};
