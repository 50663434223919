import React, { useContext, useState } from "react";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import { BillingCycleEnum } from "../../common/Billing/PackagePlanModel";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import PlanChangeDisplay from "./PlanChangeDisplay";
import { yearlyMonthlyPriceDiff } from "../../common/Billing/BillingPlans";
import { DateTimeUtils, NumberService } from "../../../services/UtilsService";
import BillingService from "../../../services/BillingService";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { RESOURCES } from "../../../config/constants";

const ChangeBillingCycleModal = ({
  show,
  onClose,
  currentPlan,
  currentBillingCycle,
  currency = "USD",
  nextBillingDate,
  selectedSubscriptionId,
  onChangeBillingCycleSuccess,
}) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const resourceType = selectedInventory?.type;

  const [isLoading, setIsLoading] = useState(false);

  if (
    !currentPlan ||
    !currentPlan.hasMonthly ||
    resourceType !== RESOURCES.SITE
  ) {
    return null;
  }

  const closeModal = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const newBillingCycle =
    currentBillingCycle === BillingCycleEnum.monthly
      ? BillingCycleEnum.yearly
      : BillingCycleEnum.monthly;

  const monthlyPrice = currentPlan.getMonthlyPriceForCurrency(currency);
  const yearlyPrice = currentPlan.getYearlyPriceForCurrency(currency);

  const currentPrice =
    currentBillingCycle === BillingCycleEnum.monthly
      ? monthlyPrice
      : yearlyPrice;
  const newPrice =
    newBillingCycle === BillingCycleEnum.monthly ? monthlyPrice : yearlyPrice;

  const onSubmitChange = () => {
    setIsLoading(true);

    BillingService.changeSubscriptionBillingCycle(
      selectedInventory.type,
      selectedInventory.id,
      selectedSubscriptionId,
      newBillingCycle
    )
      .then(() => {
        toastContext.addSuccessToast(
          <span>Subscription billing cycle updated</span>
        );
        onChangeBillingCycleSuccess();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to change subscription billing cycle</span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const priceDiffInPercent = yearlyMonthlyPriceDiff(
    yearlyPrice.priceInDecimal,
    monthlyPrice.priceInDecimal
  );
  const newCycleDate = DateTimeUtils.formatLocalDate(nextBillingDate);
  const newCyclePaymentDate = DateTimeUtils.formatLocalDate(
    BillingService.getNewCyclePaymentDate(nextBillingDate)
  );
  const newCharge = `${currency.toUpperCase()} $${NumberService.format(
    newPrice.priceInDecimal
  )}`;

  const priceChangeBanner =
    newBillingCycle === BillingCycleEnum.monthly ? (
      <GVDSBanner
        variant={GVDSBanner.Variants.error}
        title={`You are switching from a yearly to monthly billing cycle. Your subscription cost will increase by ${priceDiffInPercent}%`}
      />
    ) : (
      <GVDSBanner
        variant={GVDSBanner.Variants.success}
        title={`You are switching from a yearly to monthly billing cycle. You will save ${priceDiffInPercent}%`}
      />
    );

  return (
    <GVDSModal
      size={GVDSModal.Size.medium}
      title="Change billing cycle"
      show={show}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        {priceChangeBanner}
        <div className="mt-3">
          <PlanChangeDisplay
            currentPlanName={currentPlan.name}
            currentBillingCycle={currentBillingCycle}
            currentPrice={NumberService.format(currentPrice.priceInDecimal)}
            currency={currency}
            newPlanName={currentPlan.name}
            newBillingCycle={newBillingCycle}
            newPrice={NumberService.format(newPrice.priceInDecimal)}
          />
        </div>
        <div className="mt-4">
          Your new {newBillingCycle} billing cycle starts on{" "}
          <strong>{newCycleDate}</strong>, and you'll be charged{" "}
          <strong>{newCharge}</strong> 7 days before on {newCyclePaymentDate}.
          Do review your payment details to ensure successful payment.
          Applicable taxes not inclusive.
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={closeModal}
          text={`Stay on ${currentBillingCycle}`}
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={onSubmitChange}
          text={`Change to ${newBillingCycle}`}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default ChangeBillingCycleModal;
