import React, { useContext, useEffect, useRef, useState } from "react";
import LoadingSpinner from "../common/LoadingSpinner";
import BulkDataInput, { BulkDataInputControls } from "../Data/BulkDataInput";
import BulkSiteUsersInputService, {
  getSiteUsersColumnLabels,
} from "./BulkSiteUsersInputService";
import range from "lodash/range";
import groupBy from "lodash/groupBy";
import SiteBulkService from "../../services/SiteBulkService";
import BulkSiteUsersInputErrorService from "./BulkSiteUsersInputErrorService";
import ToastContext from "../../context/ToastContext";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";

const INITIAL_GRID_SIZE = 10;

const BulkSiteUsersInput = ({ onComplete }) => {
  const toastContext = useContext(ToastContext);

  const bulkInputService = useRef(new BulkSiteUsersInputService());
  const bulkInputErrorService = useRef(new BulkSiteUsersInputErrorService());
  const [grid, setGrid] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [numOfUsersInputted, setNumOfUsersInputted] = useState();
  const [rowToDelete, setRowToDelete] = useState(-1);
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    bulkInputService.current
      .getSelectionOptions()
      .then(() => {
        resetGrid();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load bulk input selection options.</span>
        );
      });
  }, []);

  useEffect(() => {
    if (rowToDelete >= 0) {
      const newGrid = bulkInputService.current.removeRow(
        rowToDelete,
        setRowToDelete,
        grid
      );
      setGrid(newGrid);
      setRowToDelete(-1);
    }
  }, [rowToDelete]);

  const resetGrid = () => {
    const initialGrid = range(INITIAL_GRID_SIZE).map((row) =>
      bulkInputService.current.getNewRow(row, setRowToDelete)
    );
    bulkInputErrorService.current.resetErrors();
    setHasEdited(false);
    setGrid(initialGrid);
  };

  const handleChanges = (changes = [], additions = []) => {
    setHasEdited(true);

    const totalChanges = [...changes, ...additions];
    const changedGrid = grid.map((row) => [...row]);
    const changesByRow = groupBy(totalChanges, (c) => c.row);

    for (const [row, rowChanges] of Object.entries(changesByRow)) {
      const rowData = changedGrid[row]
        ? changedGrid[row]
        : bulkInputService.current.getNewRow(row, setRowToDelete);
      changedGrid[row] = bulkInputService.current.updateRow(
        rowData,
        rowChanges
      );
    }
    setGrid(changedGrid);
  };

  const addNewRow = () => {
    const newRow = Object.values(grid).length;
    setGrid([
      ...grid,
      bulkInputService.current.getNewRow(newRow, setRowToDelete),
    ]);
  };

  const submitUsers = async () => {
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const users = changedGrid.map((rowData) =>
      bulkInputService.current.getUser(rowData)
    );
    setIsLoading(true);
    try {
      await SiteBulkService.createUsers(users);
      setIsLoading(false);
      setNumOfUsersInputted(grid.length);
      resetGrid();
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to create users.</span>);
    }
  };

  const validateBulkData = async () => {
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const users = changedGrid.map((rowData) =>
      bulkInputService.current.getUser(rowData)
    );
    if (users.length === 0) return;

    setIsLoading(true);
    try {
      const errors = await SiteBulkService.validateUsers(users);
      const newGrid = bulkInputService.current.updateGridWithErrors(
        changedGrid,
        bulkInputErrorService.current.prepareDataErrors(
          errors.dataErrors.rowErrors
        )
      );
      bulkInputErrorService.current.updateErrors(errors);
      setHasEdited(false);
      setGrid(newGrid);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to validate user input.</span>);
    }
  };

  let content;
  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <BulkDataInput
        className="bulk-input-users"
        grid={grid}
        hasChanges={
          hasEdited ||
          bulkInputErrorService.current.hasDataErrors() ||
          !bulkInputErrorService.current.disableSubmit()
        }
        columns={getSiteUsersColumnLabels()}
        onChange={handleChanges}
      />
    );
  }

  return (
    <div>
      <div className="users-message body-2">
        Ensure that the relevant Sites have been created before continuing with
        this step of the onboarding process.
      </div>
      {bulkInputErrorService.current.hasDataErrors() && (
        <GVDSBanner
          title={`${bulkInputErrorService.current.dataErrorRows.length} issues detected.`}
          variant={GVDSBanner.Variants.error}
        >
          Please fix the following rows:{" "}
          {bulkInputErrorService.current.dataErrorRows
            .map((row) => Number(row) + 1)
            .join(", ")}
        </GVDSBanner>
      )}
      {bulkInputErrorService.current.hasNoDataErrors() && (
        <GVDSBanner
          title="No issues found."
          variant={GVDSBanner.Variants.success}
        >
          Click "Create Users" to enter your users into the system.
        </GVDSBanner>
      )}
      <div className="d-flex flex-row-reverse align-items-center">
        {hasEdited && <div>Changes made! Validate before submitting.</div>}
      </div>
      <div className="bulk-input-ctrl__container">
        <BulkDataInputControls
          addNewRow={addNewRow}
          rowCount={grid.length}
          onClearAll={resetGrid}
        />
        <div className="bulk-input-ctrl__action-ctrl bulk-input-user-buttons">
          <GVDSButton
            variant={buttonVariant.secondary}
            className="validate-bulk-data"
            onClick={validateBulkData}
            text="Check for Validation"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            className="submit-bulk-data"
            onClick={submitUsers}
            disabled={
              bulkInputErrorService.current.disableSubmit() ||
              hasEdited ||
              isLoading
            }
            text="Create Users"
            icon={<GVDSIcon Icon={IconCirclePlus} />}
          />
        </div>
      </div>
      {content}

      <GVDSModal
        show={numOfUsersInputted > 0}
        onHide={() => setNumOfUsersInputted(null)}
        title="Bulk Input Success"
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          You have added {numOfUsersInputted} users to their respective Site
          Team(s). You have reached the end of the onboarding process.
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => {
              setNumOfUsersInputted(null);
              onComplete();
            }}
            text="Return to Create Contract"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </div>
  );
};

export default BulkSiteUsersInput;
