import React from "react";
import Button from "react-bootstrap/Button";

export const buttonVariant = {
  primary: "gvds-button__primary",
  secondary: "gvds-button__secondary",
  tertiary: "gvds-button__tertiary",
  destructive_primary: "gvds-button__destructive-primary",
  destructive_tertiary: "gvds-button__destructive-tertiary",
};

const GVDSButton = React.forwardRef(
  (
    {
      variant = buttonVariant.primary,
      text = "",
      disabled,
      icon,
      onClick,
      className,
      isSubmitButton = false,
      ...props
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        className={`gvds-button ${variant} ${icon ? "with-leading-icon" : ""} ${
          className ?? ""
        }`}
        disabled={disabled}
        onClick={onClick}
        type={isSubmitButton ? "submit" : "button"}
        {...props}
      >
        <>
          {icon && <span className="gvds-button__icon">{icon}</span>}
          {text}
        </>
      </Button>
    );
  }
);

export default GVDSButton;
