import React, { useContext, useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import EnvironmentalService from "../../../../services/EnvironmentalService";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import GVFormGroup from "../../../common/GVFormGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import LoadingSpinner from "../../../common/LoadingSpinner";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleYearPicker from "../../../../gvds-components/Forms/GVDSFormSingleYearPicker";
import { useTranslation } from "react-i18next";
import { ObjectUtils } from "../../../../services/UtilsService";

const initialCustomEmissionFactorDetails = () => {
  return {
    year: "",
    factor: "",
    source_description: "",
    environmental_type: null,
  };
};

const isStringEntryEmpty = (entry) => {
  return ObjectUtils.isEmpty(entry) || entry.length === 0;
};

const EnvironmentalCustomEmissionFactorDialog = ({
  show,
  close,
  onUpdateSuccessful,
  currentCustomEmissionFactorDetails,
  siteId,
  deleteCustomEmissionFactorHandler,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);

  const [customEmissionFactorDetails, setCustomEmissionFactorDetails] =
    useState(null);
  const [isValidated, setIsValidated] = useState(false);
  const [
    customEmissionFactorErrorMessage,
    setCustomEmissionFactorErrorMessage,
  ] = useState();

  const [isEditExistingRecord, setIsEditExistingRecord] = useState(false);
  const onCloseDialog = () => {
    resetForm();
    close();
  };

  const resetForm = () => {
    setCustomEmissionFactorDetails(initialCustomEmissionFactorDetails());
    setCustomEmissionFactorErrorMessage(null);
    setIsValidated(false);
  };

  useEffect(() => {
    if (show) {
      if (
        currentCustomEmissionFactorDetails &&
        currentCustomEmissionFactorDetails.id
      ) {
        setIsEditExistingRecord(true);
        setCustomEmissionFactorDetails({
          ...currentCustomEmissionFactorDetails,
        });
      } else {
        setCustomEmissionFactorDetails({
          ...initialCustomEmissionFactorDetails(),
          environmental_type:
            currentCustomEmissionFactorDetails.environmental_type,
        });
        setIsEditExistingRecord(false);
      }
      setIsLoading(false);
    }
  }, [currentCustomEmissionFactorDetails]);

  const onChange = (value, eventName) => {
    setCustomEmissionFactorDetails({
      ...customEmissionFactorDetails,
      [eventName]: value,
    });
  };

  const isFactorValueValid = () => {
    return (
      !isStringEntryEmpty(customEmissionFactorDetails.factor) &&
      parseFloat(customEmissionFactorDetails.factor) >= 0
    );
  };

  const saveCustomEmissionFactor = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsValidated(true);
    setCustomEmissionFactorErrorMessage(null);

    if (
      customEmissionFactorDetails.year &&
      isFactorValueValid() &&
      !isStringEntryEmpty(customEmissionFactorDetails.source_description)
    ) {
      if (selectedInventory) {
        if (isEditExistingRecord) {
          setIsLoading(true);
          EnvironmentalService.editCustomEmissionFactor(
            selectedInventory.type,
            selectedInventory.id,
            currentCustomEmissionFactorDetails.id,
            siteId,
            customEmissionFactorDetails.year,
            customEmissionFactorDetails.environmental_type.id,
            customEmissionFactorDetails.factor,
            customEmissionFactorDetails.source_description
          )
            .then(() => {
              toastContext.addSuccessToast(
                <span>Custom emission factor Updated successfully</span>
              );
              onCloseDialog();
              onUpdateSuccessful();
            })
            .catch((err) => {
              if (err.response != null && err.response.status === 400) {
                setCustomEmissionFactorErrorMessage(err.response.data.message);
              } else {
                toastContext.addFailToast(
                  <span>Failed to update custom emission factor</span>
                );
              }
            })
            .finally(() => setIsLoading(false));
        } else {
          setIsLoading(true);
          EnvironmentalService.createCustomEmissionFactor(
            selectedInventory.type,
            selectedInventory.id,
            siteId,
            customEmissionFactorDetails.year,
            customEmissionFactorDetails.environmental_type.id,
            customEmissionFactorDetails.factor,
            customEmissionFactorDetails.source_description
          )
            .then(() => {
              toastContext.addSuccessToast(
                <span>Custom emission factor added successfully</span>
              );
              onCloseDialog();
              onUpdateSuccessful();
            })
            .catch((err) => {
              if (err.response != null && err.response.status === 400) {
                setCustomEmissionFactorErrorMessage(err.response.data.message);
              } else {
                toastContext.addFailToast(
                  <span>Failed to add custom emission factor</span>
                );
              }
            })
            .finally(() => setIsLoading(false));
        }
      }
    }
  };

  return (
    <>
      <GVDSModal
        title={
          isEditExistingRecord
            ? t(
                "data-management.environmental.custom-ef.setup.modal-title-edit"
              )
            : t("data-management.environmental.custom-ef.setup.modal-title-add")
        }
        show={show}
        onHide={onCloseDialog}
      >
        <GVDSModal.Body>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="mb-3">
                {t(
                  "data-management.environmental.custom-ef.setup.add.modal-description"
                )}
              </div>

              <GVFormGroup>
                <Form.Label>
                  {t("data-management.environmental.shared.type")}
                </Form.Label>
                <GVDSFormField
                  value={
                    customEmissionFactorDetails
                      ? customEmissionFactorDetails.environmental_type?.name
                      : ""
                  }
                  disabled
                />
              </GVFormGroup>
              <GVFormGroup>
                <Form.Label>{t("shared-input-label.year")}</Form.Label>
                <GVDSFormSingleYearPicker
                  placeholder="Select a year"
                  selected={
                    customEmissionFactorDetails &&
                    customEmissionFactorDetails.year
                      ? customEmissionFactorDetails.year
                      : null
                  }
                  onChange={(year) => onChange(year, "year")}
                  maxYear={new Date().getFullYear()}
                  statusMetadata={
                    customEmissionFactorDetails && isValidated
                      ? !customEmissionFactorDetails.year
                        ? FormFieldStatusMetadata.getError(
                            "Please select a year."
                          )
                        : customEmissionFactorErrorMessage !== null &&
                          customEmissionFactorDetails.year
                        ? FormFieldStatusMetadata.getError(
                            customEmissionFactorErrorMessage
                          )
                        : FormFieldStatusMetadata.getDefault()
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
              <GVFormGroup>
                <Form.Label>
                  {t("data-management.environmental.custom-ef.emission-factor")}
                </Form.Label>
                <Row>
                  <Col sm={7}>
                    <GVDSFormField
                      type="number"
                      name="factor"
                      placeholder="Enter emission factor value"
                      value={
                        customEmissionFactorDetails
                          ? customEmissionFactorDetails.factor
                          : ""
                      }
                      onInput={(value) => onChange(value, "factor")}
                      statusMetadata={
                        isValidated &&
                        customEmissionFactorDetails &&
                        isStringEntryEmpty(customEmissionFactorDetails.factor)
                          ? FormFieldStatusMetadata.getError(
                              "Please provide factor value."
                            )
                          : isValidated &&
                            customEmissionFactorDetails &&
                            !isFactorValueValid()
                          ? FormFieldStatusMetadata.getError(
                              "This value cannot be negative."
                            )
                          : FormFieldStatusMetadata.getDefault()
                      }
                    />
                  </Col>
                  <Col sm={5} className="text-vertical-center">
                    kgCO2e/kWh
                  </Col>
                </Row>
              </GVFormGroup>
              <GVFormGroup>
                <Form.Label>{t("shared-input-label.description")}</Form.Label>
                <GVDSFormField
                  name="source_description"
                  placeholder="Short description of source"
                  value={
                    customEmissionFactorDetails
                      ? customEmissionFactorDetails.source_description
                      : ""
                  }
                  onInput={(value) => onChange(value, "source_description")}
                  statusMetadata={
                    isValidated &&
                    customEmissionFactorDetails &&
                    isStringEntryEmpty(
                      customEmissionFactorDetails.source_description
                    )
                      ? FormFieldStatusMetadata.getError(
                          "Please provide source description."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {isEditExistingRecord && (
            <>
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                onClick={() =>
                  deleteCustomEmissionFactorHandler(customEmissionFactorDetails)
                }
                disabled={isLoading}
                text={t("shared.delete")}
              />
              <Spacer />
            </>
          )}
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={onCloseDialog}
            disabled={isLoading}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            text={t("shared-modal.footer.save")}
            disabled={isLoading}
            onClick={saveCustomEmissionFactor}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default EnvironmentalCustomEmissionFactorDialog;
