import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import {
  IconArrowsUpDown,
  IconCirclePlus,
  IconEdit,
} from "@tabler/icons-react";
import GVDSTextButton from "../../../../gvds-components/Buttons/GVDSTextButton";
import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import { CertificationCategoryAdminService } from "../../../../services/PortalAdmin/Certification/CertificationCategoryAdminService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import {
  CannotDeleteCategoryModal,
  CategoryFormModal,
  ConfirmDeleteCategoryModal,
  MoveCertificationCategoryModal,
} from "./CertificationContentCategoryModals";

const CATEGORY_NAME_MAX_CHARACTER_DISPLAY_LENGTH = 90;
const CATEGORY_HAS_CRITERIA_ERROR_MESSAGE = "Category has criteria";

const truncateCategoryName = (str, num) => {
  if (str.length <= num) return str;
  return str.slice(0, num) + "...";
};

const CertificationContentCategories = ({
  certificationId,
  categories,
  setCategories,
  selectedCategory,
  onSelectCategory,
}) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    if (certificationId) {
      setIsloading(true);
      CertificationCategoryAdminService.getCategories(certificationId)
        .then((response) => {
          setCategories(response);
          if (response.length > 0) {
            onSelectCategory(response[0]);
          }
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to load categories</span>);
        })
        .finally(() => {
          setIsloading(false);
        });
    }
  }, [certificationId]);

  const [categoryToBeEdited, setCategoryToBeEdited] = useState(null);
  const [showCategoryFormModal, setShowCategoryFormModal] = useState(false);
  const onCategoryFormModalClose = () => {
    setCategoryToBeEdited(null);
    setShowCategoryFormModal(false);
  };
  const promptAddCategory = () => {
    setCategoryToBeEdited(null);
    setShowCategoryFormModal(true);
  };
  const promptEditCategory = (category) => {
    setCategoryToBeEdited(category);
    setShowCategoryFormModal(true);
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [showCannotDeleteCategoryModal, setShowCannotDeleteCategoryModal] =
    useState(false);
  const onSuccessDeleteCategory = () => {
    const newCategories = categories.filter(
      (category) => category.id !== categoryToBeEdited.id
    );
    setCategories(newCategories);

    onDeleteCategoryModalClose();
  };

  const onDeleteCategoryModalClose = () => {
    setCategoryToBeEdited(null);
    setShowDeleteCategoryModal(false);
  };
  const promptDeleteCategory = () => {
    setShowCategoryFormModal(false);
    setShowDeleteCategoryModal(true);
  };

  const [categoryToBeMoved, setCategoryToBeMoved] = useState(null);
  const [showMoveCategoryModal, setShowMoveCategoryModal] = useState(false);
  const onMoveCategoryModalClose = () => {
    setCategoryToBeMoved(null);
    setShowMoveCategoryModal(false);
  };
  const promptMoveCategory = (category) => {
    setCategoryToBeMoved(category);
    setShowMoveCategoryModal(true);
  };

  const onSuccessMoveCategories = (newCategories) => {
    setCategories(newCategories);
    setShowMoveCategoryModal(false);
  };

  const refreshCategories = (updatedCategory) => {
    const category = categories.find(
      (category) => category.id === updatedCategory.id
    );

    if (category === undefined) {
      categories.push(updatedCategory);
    } else {
      category.name = updatedCategory.name;
    }
    setCategories(categories);
    if (categories.length === 1) {
      onSelectCategory(categories[0]);
    }
  };

  const deleteCategory = async () => {
    setIsDeleting(true);

    try {
      await CertificationCategoryAdminService.deleteCategory(
        certificationId,
        categoryToBeEdited.id
      );

      toastContext.addSuccessToast(<span>Category successfully deleted.</span>);
      setIsDeleting(false);
      onSuccessDeleteCategory();
    } catch (error) {
      setIsDeleting(false);

      if (error.response.data.message === CATEGORY_HAS_CRITERIA_ERROR_MESSAGE) {
        setShowDeleteCategoryModal(false);
        setShowCannotDeleteCategoryModal(true);
      } else {
        toastContext.addFailToast(<span>Failed to delete category.</span>);
      }
    }
  };

  return (
    <>
      <div className="gvds-text--heading3 mb-3">Categories</div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="system-toolbox--certification__category-container">
          {categories.length > 0 &&
            categories.map((category) => (
              <div
                key={category.id}
                className={`system-toolbox--certification__category${
                  category.id === selectedCategory?.id ? " selected" : ""
                }`}
                onClick={() => onSelectCategory(category)}
              >
                <span className="gvds-text--heading4">
                  {truncateCategoryName(
                    category.name,
                    CATEGORY_NAME_MAX_CHARACTER_DISPLAY_LENGTH
                  )}
                </span>
                <Spacer />
                <div className="system-toolbox--certification__content-button-container">
                  <GVDSIconButton
                    variant={iconButtonVariant.tertiary}
                    icon={<GVDSIcon Icon={IconEdit} />}
                    tooltipText="Edit"
                    onClick={() => promptEditCategory(category)}
                  />
                  <GVDSIconButton
                    variant={iconButtonVariant.tertiary}
                    icon={<GVDSIcon Icon={IconArrowsUpDown} />}
                    tooltipText="Move"
                    disabled={categories.length === 1}
                    onClick={() => promptMoveCategory(category)}
                  />
                </div>
              </div>
            ))}
          <GVDSTextButton
            className="system-toolbox--certification__add-content-button"
            variant={iconButtonVariant.tertiary}
            icon={<GVDSIcon Icon={IconCirclePlus} className="me-2" />}
            text="Add category"
            onClick={promptAddCategory}
          />
        </div>
      )}
      <CategoryFormModal
        show={showCategoryFormModal}
        setShowInputCategoryForm={setShowCategoryFormModal}
        closeModal={onCategoryFormModalClose}
        certificationId={certificationId}
        category={categoryToBeEdited}
        onConfirmDeleteCategory={promptDeleteCategory}
        onSuccess={(updatedCategory) => {
          refreshCategories(updatedCategory);
          setCategoryToBeEdited(null);
          setShowCategoryFormModal(false);
        }}
      />
      <ConfirmDeleteCategoryModal
        show={showDeleteCategoryModal}
        isDeleting={isDeleting}
        closeModal={onDeleteCategoryModalClose}
        asyncOnDelete={deleteCategory}
      />
      <CannotDeleteCategoryModal
        show={showCannotDeleteCategoryModal}
        closeModal={() => setShowCannotDeleteCategoryModal(false)}
      />
      {categoryToBeMoved && (
        <MoveCertificationCategoryModal
          show={showMoveCategoryModal}
          closeModal={onMoveCategoryModalClose}
          onSuccess={onSuccessMoveCategories}
          categoryToBeMoved={categoryToBeMoved}
          allCategories={categories}
          certificationId={certificationId}
        />
      )}
    </>
  );
};

export default CertificationContentCategories;
