import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { DateTimeUtils } from "../../../services/UtilsService";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconExternalLink } from "@tabler/icons-react";
import Container from "react-bootstrap/Container";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import LoadingSpinner from "../../common/LoadingSpinner";
import { getAdminViewEnterpriseSignUpRequest } from "../../../config/ROUTES_NAME";
import { PortalAdminEnterpriseSignUpService } from "../../../services/PortalAdmin/PortalAdminSignUpReviewService";
import ToastContext from "../../../context/ToastContext";

const ViewAllEnterpriseSignUpRequests = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);

  const [enterpriseRequests, setEnterpriseRequests] = useState([]);

  const goToRequest = (requestId) => {
    history.push(getAdminViewEnterpriseSignUpRequest(requestId));
  };

  useEffect(() => {
    setIsLoading(true);

    PortalAdminEnterpriseSignUpService.getAllEnterpriseSignUpRequests()
      .then((enterpriseRequests) => {
        setEnterpriseRequests(enterpriseRequests);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to load enterprise sign up requests.</span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(enterpriseRequests, null);

  const columns = [
    {
      header: "Request Date",
      headerStyle: { width: "300px" },
      dataKey: "request_date",
      sortable: true,
      renderer: (r) => {
        return <span>{DateTimeUtils.formatUTCDate(r.request_date)}</span>;
      },
    },
    {
      header: "Company Name",
      headerStyle: { width: "300px" },
      dataKey: "company_name",
    },
    {
      header: "User Email",
      headerStyle: { width: "300px" },
      dataKey: "email_address",
    },
    {
      header: "No. of Properties",
      headerStyle: { width: "150px" },
      dataKey: "no_of_properties",
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "160px" },
      renderer: (r) => {
        return (
          <GVDSIconButton
            variant={iconButtonVariant.tertiary}
            onClick={() => {
              goToRequest(r.id);
            }}
            icon={<GVDSIcon Icon={IconExternalLink} />}
            tooltipText="View Details"
          />
        );
      },
    },
  ];

  return (
    <Container fluid>
      <div>
        Follow up with client inquiry separately via{" "}
        <strong>sales@greenview.sg</strong>. Once confirmed, proceed to create
        the contract subscription under Contracts
      </div>
      <GVDSTableCtrlContainer>
        {enterpriseRequests.length > 0 ? (
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        ) : null}
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        onRowClick={(r) => goToRequest(r.id)}
      />
      {isLoading && <LoadingSpinner />}
      {!isLoading &&
        (!enterpriseRequests || enterpriseRequests.length === 0) && (
          <div className="table__no_content">
            There are currently no enterprise sign-up requests.
          </div>
        )}
    </Container>
  );
};

export default ViewAllEnterpriseSignUpRequests;
