import React, { useContext, useEffect, useRef, useState } from "react";
import range from "lodash/range";
import groupBy from "lodash/groupBy";

import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import BulkDataInput, { BulkDataInputControls } from "../Data/BulkDataInput";
import SiteBulkService from "../../services/SiteBulkService";
import BulkFloorAreasInputErrorService from "./BulkFloorAreasInputErrorService";
import BulkFloorAreasInputService, {
  getFloorAreaColumnLabels,
} from "./BulkFloorAreasInputService";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";

const INITIAL_GRID_SIZE = 10;

const areaHeaderRenderer = ({ className, children }) => {
  return (
    <div className={className}>
      <table>
        <thead>
          <tr>
            <th
              className="cell read-only"
              style={{ width: "2rem" }}
              rowSpan={2}
            />
            <th
              className="cell read-only"
              style={{ width: "2.5rem" }}
              rowSpan={2}
            >
              Row
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Site Name*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Month Year*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Gross Floor Area*
            </th>
            <th className="cell read-only" colSpan={3}>
              Hotel / Accommodation Only
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Unit*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Comment
            </th>
          </tr>
          <tr>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Conditioned Space
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Guestrooms and Corridors
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Meeting/Function Space
            </th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const BulkFloorAreasInput = ({ onComplete }) => {
  const toastContext = useContext(ToastContext);

  const bulkInputService = useRef(new BulkFloorAreasInputService());
  const bulkInputErrorService = useRef(new BulkFloorAreasInputErrorService());

  const [grid, setGrid] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [numOfAreaInputted, setNumOfAreaInputted] = useState();
  const [rowToDelete, setRowToDelete] = useState(-1);
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    bulkInputService.current
      .getSelectionOptions()
      .then(() => {
        resetGrid();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load bulk input selection options.</span>
        );
      });
  }, []);

  useEffect(() => {
    if (rowToDelete >= 0) {
      const newGrid = bulkInputService.current.removeRow(
        rowToDelete,
        setRowToDelete,
        grid
      );
      setGrid(newGrid);
      setRowToDelete(-1);
    }
  }, [rowToDelete]);

  const resetGrid = () => {
    const initialGrid = range(INITIAL_GRID_SIZE).map((row) =>
      bulkInputService.current.getNewRow(row, setRowToDelete)
    );
    bulkInputErrorService.current.resetErrors();
    setHasEdited(false);
    setGrid(initialGrid);
  };

  const handleChanges = (changes = [], additions = []) => {
    setHasEdited(true);

    const totalChanges = [...changes, ...additions];
    const changedGrid = grid.map((row) => [...row]);
    const changesByRow = groupBy(totalChanges, (c) => c.row);

    for (const [row, rowChanges] of Object.entries(changesByRow)) {
      const rowData = changedGrid[row]
        ? changedGrid[row]
        : bulkInputService.current.getNewRow(row, setRowToDelete);
      changedGrid[row] = bulkInputService.current.updateRow(
        rowData,
        rowChanges
      );
    }
    setGrid(changedGrid);
  };

  const addNewRow = () => {
    const newRow = Object.values(grid).length;
    setGrid([
      ...grid,
      bulkInputService.current.getNewRow(newRow, setRowToDelete),
    ]);
  };

  const submitAreas = async () => {
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const areas = changedGrid.map((rowData) =>
      bulkInputService.current.getArea(rowData)
    );
    setIsLoading(true);

    try {
      await SiteBulkService.createFloorAreas(areas);
      setIsLoading(false);
      setNumOfAreaInputted(grid.length);
      resetGrid();
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to create floor areas.</span>);
    }
  };

  const validateBulkData = async () => {
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const areas = changedGrid.map((rowData) =>
      bulkInputService.current.getArea(rowData)
    );
    if (areas.length === 0) return;

    setIsLoading(true);
    try {
      const errors = await SiteBulkService.validateFloorAreas(areas);
      const newGrid = bulkInputService.current.updateGridWithErrors(
        changedGrid,
        bulkInputErrorService.current.prepareDataErrors(
          errors.dataErrors.rowErrors
        )
      );
      bulkInputErrorService.current.updateErrors(errors);
      setHasEdited(false);
      setGrid(newGrid);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(
        <span>Failed to validate floor areas input.</span>
      );
    }
  };

  let content;
  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <BulkDataInput
        className="bulk-input-floor-areas"
        grid={grid}
        hasChanges={
          hasEdited ||
          bulkInputErrorService.current.hasDataErrors() ||
          !bulkInputErrorService.current.disableSubmit()
        }
        columns={getFloorAreaColumnLabels()}
        onChange={handleChanges}
        headerRenderer={areaHeaderRenderer}
      />
    );
  }

  return (
    <div>
      <div className="floor-area-message body-2">
        Ensure that the relevant Sites have been created before continuing with
        this step of the onboarding process.
      </div>
      {bulkInputErrorService.current.hasDataErrors() && (
        <GVDSBanner
          title={`${bulkInputErrorService.current.dataErrorRows.length} issues detected.`}
          variant={GVDSBanner.Variants.error}
          className="mt-2"
        >
          Please fix the following rows:{" "}
          {bulkInputErrorService.current.dataErrorRows
            .map((row) => Number(row) + 1)
            .join(", ")}
        </GVDSBanner>
      )}
      {bulkInputErrorService.current.hasNoDataErrors() && (
        <GVDSBanner
          title="No issues found."
          variant={GVDSBanner.Variants.success}
          className="mt-2"
        >
          Click "Submit" to enter your floor areas into the system.
        </GVDSBanner>
      )}
      <div className="d-flex flex-row-reverse align-items-center">
        {hasEdited && <div>Changes made! Validate before submitting.</div>}
      </div>
      <div className="bulk-input-ctrl__container">
        <BulkDataInputControls
          addNewRow={addNewRow}
          rowCount={grid.length}
          onClearAll={resetGrid}
        />
        <div className="bulk-input-ctrl__action-ctrl bulk-input-areas-buttons">
          <GVDSButton
            variant={buttonVariant.secondary}
            className="validate-bulk-data"
            onClick={validateBulkData}
            text="Check for Validation"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            className="submit-bulk-data"
            onClick={submitAreas}
            disabled={
              bulkInputErrorService.current.disableSubmit() ||
              hasEdited ||
              isLoading
            }
            text="Submit"
          />
        </div>
      </div>
      {content}

      <GVDSModal
        show={numOfAreaInputted > 0}
        onHide={() => setNumOfAreaInputted(null)}
        title="Bulk Input Success"
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          You have added {numOfAreaInputted} rows of floor area details to the
          respective Site(s). Click on the “Continue” button to continue with
          the onboarding process.
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => {
              setNumOfAreaInputted(null);
              onComplete();
            }}
            text="Continue"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </div>
  );
};

export default BulkFloorAreasInput;
