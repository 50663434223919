import {
  dashboardChartBackgroundColor,
  DashboardColorPalette,
} from "../DashboardColorPalette";
import React, { Fragment, useRef, useState } from "react";
import { Group } from "@visx/group";
import {
  changeOverTimeSiteRankingBarDirectionEnum,
  changeOverTimeSiteRankingConfig,
  changeOverTimeSiteRankingMargin,
  ChangeOverTimeSiteRankingViewTypeEnum,
} from "./ChangeOverTimeSiteRankingChartViewConfig";
import { scaleBand } from "@visx/scale";
import {
  barChartRadius,
  singleBarChartGapInPx,
  singleHorizontalBarChartHeightInPx,
} from "../../Models/DashboardModels";
import { ChangeOverTimeSiteRankingChartUtils } from "./ChangeOverTimeSiteRankingChartUtils";
import { AxisBottom, AxisLeft, AxisTop } from "@visx/axis";
import { NumberService, StringUtils } from "../../../../services/UtilsService";
import { GVDSColors } from "../../../../styles/gvds-colors";
import GridColumns from "@visx/grid/lib/grids/GridColumns";
import { Text } from "@visx/text";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import { DashboardBarChartSharedUtils } from "../DashboardBarChartSharedUtils";
import {
  DashboardWidgetSharedUtils,
  YAxisTick,
} from "../DashboardWidgetSharedUtils";

const SingleBarTooltipContent = ({
  siteName,
  changeValue,
  changePercentage,
  firstYear,
  firstYearValue,
  secondYear,
  secondYearValue,
  unit,
  intensityUnit,
}) => {
  const displayedUnit = intensityUnit ? `${unit}/${intensityUnit}` : unit;

  return (
    <div className="dashboard--chart__popover-tooltip-container">
      <div className="popover-tooltip-label">Site</div>
      <div className="popover-tooltip-value">
        <strong>{siteName}</strong>
      </div>
      <div className="popover-tooltip-label">YoY change</div>
      <div className="popover-tooltip-value">
        <strong>
          {DashboardWidgetSharedUtils.getChartValueDisplay(changeValue)}
        </strong>{" "}
        {displayedUnit}{" "}
        {`(${DashboardWidgetSharedUtils.getChartPercentDisplay(
          changePercentage
        )})`}
      </div>
      <div className="popover-tooltip-label">{firstYear}</div>
      <div className="popover-tooltip-value">
        <strong>{NumberService.format(firstYearValue)}</strong> {displayedUnit}
      </div>
      <div className="popover-tooltip-label">{secondYear}</div>
      <div className="popover-tooltip-value">
        <strong>{NumberService.format(secondYearValue)}</strong> {displayedUnit}
      </div>
    </div>
  );
};

const HorizontalBar = ({
  xPosition,
  yPosition,
  barWidth,
  barHeight,
  barRadius = 0,
  barRadiusSpace = 0,
  barColor,
  direction = changeOverTimeSiteRankingBarDirectionEnum.RIGHT,
  tooltipContent,
}) => {
  const ref = useRef(null);
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const svgPathData = ChangeOverTimeSiteRankingChartUtils.getSVGPathData(
    xPosition,
    yPosition,
    barWidth,
    barHeight,
    barRadius,
    barRadiusSpace,
    direction
  );

  return (
    <>
      <Group
        innerRef={ref}
        onMouseOver={() => setIsShowTooltip(true)}
        onMouseLeave={() => setIsShowTooltip(false)}
      >
        <svg width={xPosition + barWidth} height={yPosition + barHeight}>
          <path d={svgPathData} fill={barColor} />
        </svg>
      </Group>
      <Overlay show={isShowTooltip} placement="top" target={ref.current}>
        <Popover>
          <Popover.Body>
            <div>{tooltipContent}</div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

const ChangeOverTimeSiteRankingChartViewContent = ({
  width,
  height,
  sortedChartData,
  selectedViewType,
  unit,
  intensityUnit,
  isExpandedView = false,
}) => {
  const labelWidthCalculationRef = useRef();

  const xMax =
    width -
    changeOverTimeSiteRankingMargin.left -
    changeOverTimeSiteRankingMargin.right;
  const yMax =
    height -
    changeOverTimeSiteRankingMargin.top -
    changeOverTimeSiteRankingMargin.bottom;

  let filledHeight;
  if (isExpandedView) {
    filledHeight =
      ChangeOverTimeSiteRankingChartUtils.getExpandedViewChartHeight(
        sortedChartData.length
      );
  } else {
    filledHeight = ChangeOverTimeSiteRankingChartUtils.getChartHeight(
      sortedChartData.length
    );
  }
  filledHeight =
    filledHeight -
    changeOverTimeSiteRankingMargin.top -
    changeOverTimeSiteRankingMargin.bottom;

  const xAxisScaleRangeValue =
    ChangeOverTimeSiteRankingChartUtils.getXAxisScaleRangeValue(
      sortedChartData,
      selectedViewType.value
    );

  const xAxisScale = ChangeOverTimeSiteRankingChartUtils.getXAxisScale(
    xAxisScaleRangeValue.min,
    xAxisScaleRangeValue.max,
    xMax
  );
  const xAxisLabel = StringUtils.getTitleCase(selectedViewType.value);
  const xAxisTickFormat = (value) =>
    selectedViewType.value === ChangeOverTimeSiteRankingViewTypeEnum.PERCENTAGE
      ? `${NumberService.humanize(value)}%`
      : NumberService.humanize(value);

  const yAxisScale = scaleBand({
    domain: sortedChartData.map((d) => d.siteName),
    range: [0, filledHeight],
    padding: 0.2,
  });

  const xAxisDomainLength =
    Math.abs(xAxisScale.domain()[0]) + Math.abs(xAxisScale.domain()[1]);
  const scaleFactor = xAxisDomainLength !== 0 ? xMax / xAxisDomainLength : 1;

  const additionalTopMargin = isExpandedView ? 24 : 0;

  return (
    <>
      <svg width={width} height={height}>
        <rect
          width={width}
          height={height}
          fill={dashboardChartBackgroundColor}
        />
        <GridColumns
          top={
            changeOverTimeSiteRankingMargin.top +
            additionalTopMargin +
            changeOverTimeSiteRankingConfig.gridMargin
          }
          left={changeOverTimeSiteRankingMargin.left}
          scale={xAxisScale}
          height={yMax - 2 * changeOverTimeSiteRankingConfig.gridMargin}
          stroke={GVDSColors.gray4}
        />
        <Group
          top={changeOverTimeSiteRankingMargin.top + additionalTopMargin}
          left={changeOverTimeSiteRankingMargin.left}
        >
          {sortedChartData.map((datum) => {
            let value = datum.changeValue[selectedViewType.value];
            const isEmptyValue =
              value === null || value === undefined || isNaN(value);

            if (isEmptyValue) {
              value = 0;
            }

            const xPosition = xAxisScale(Math.min(0, value));
            const yPosition = yAxisScale(datum.siteName);

            let barWidth = Math.abs(value) * scaleFactor;
            const isBarWidthUnderMinimumSize =
              barWidth < DashboardBarChartSharedUtils.minimumBarSizeInPx;
            if (barWidth > 0 && isBarWidthUnderMinimumSize) {
              barWidth = DashboardBarChartSharedUtils.minimumBarSizeInPx;
            }

            const textXPosition =
              ChangeOverTimeSiteRankingChartUtils.getTextXPosition(
                value,
                xPosition,
                barWidth,
                selectedViewType.value,
                labelWidthCalculationRef
              );
            const textYPosition =
              yPosition +
              singleBarChartGapInPx +
              singleHorizontalBarChartHeightInPx / 4;

            return (
              <Fragment key={datum.siteName}>
                <HorizontalBar
                  xPosition={xPosition}
                  yPosition={yPosition}
                  barHeight={singleHorizontalBarChartHeightInPx}
                  barWidth={barWidth}
                  barColor={DashboardColorPalette[0]}
                  barRadius={barChartRadius}
                  barRadiusSpace={
                    isBarWidthUnderMinimumSize
                      ? DashboardBarChartSharedUtils.minimumBarSizeInPx
                      : barChartRadius
                  }
                  direction={
                    value > 0
                      ? changeOverTimeSiteRankingBarDirectionEnum.RIGHT
                      : changeOverTimeSiteRankingBarDirectionEnum.LEFT
                  }
                  tooltipContent={
                    <SingleBarTooltipContent
                      siteName={datum.siteName}
                      changeValue={datum.changeValue.value}
                      changePercentage={datum.changeValue.percentage}
                      firstYear={datum.year1}
                      firstYearValue={datum.year1Value}
                      secondYear={datum.year2}
                      secondYearValue={datum.year2Value}
                      unit={unit}
                      intensityUnit={intensityUnit}
                    />
                  }
                />
                <Text
                  x={textXPosition}
                  y={textYPosition}
                  className="dashboard--bar-chart-total-value"
                >
                  {`${
                    isEmptyValue
                      ? ""
                      : selectedViewType.value ===
                        ChangeOverTimeSiteRankingViewTypeEnum.PERCENTAGE
                      ? `${DashboardWidgetSharedUtils.getChartPercentDisplay(
                          value,
                          true
                        )}`
                      : DashboardWidgetSharedUtils.getChartValueDisplay(
                          value,
                          true
                        )
                  }
                    `}
                </Text>
              </Fragment>
            );
          })}
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yAxisScale}
            left={changeOverTimeSiteRankingConfig.axisLeftMargin}
            tickComponent={(value) => (
              <YAxisTick
                width={
                  changeOverTimeSiteRankingMargin.left +
                  changeOverTimeSiteRankingConfig.axisLeftMargin
                }
                x={value.x}
                y={value.y}
                value={value.formattedValue}
              />
            )}
            tickValues={sortedChartData.map((d) => d.siteName)}
          />
          {isExpandedView ? (
            <AxisTop
              hideAxisLine
              hideTicks
              top={changeOverTimeSiteRankingMargin.top}
              scale={xAxisScale}
              label={xAxisLabel}
              labelClassName="chart-axis-label"
              labelOffset={16}
              tickFormat={xAxisTickFormat}
            />
          ) : (
            <AxisBottom
              hideAxisLine
              hideTicks
              top={yMax}
              scale={xAxisScale}
              label={xAxisLabel}
              labelClassName="chart-axis-label"
              labelOffset={12}
              tickFormat={xAxisTickFormat}
            />
          )}
        </Group>
      </svg>
      <div
        ref={labelWidthCalculationRef}
        style={{ width: "fit-content", visibility: "hidden" }}
      ></div>
    </>
  );
};

export default ChangeOverTimeSiteRankingChartViewContent;
