import React from "react";
import Form from "react-bootstrap/Form";
import {
  DEFAULT_OPERATION_METER_NAME,
  FREQUENCY_OPTIONS,
} from "../../../config/constants";
import GVFormGroup from "../../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormFieldWithCharacterCount from "../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MeterTips from "../MeterTips";
import { TextCopies } from "../../../config/text-copies";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";

const getFrequencyOptionByValue = (optionValue) =>
  FREQUENCY_OPTIONS.find((option) => option.value === optionValue);

const OperationMeterDialog = ({
  show,
  cancel,
  facilities,
  meterDetails,
  disableFacilitySelect,
  onDropdownChange,
  onTextChange,
  isValidated,
  saveMeter,
  deleteMeterHandler,
  isEditExistingMeter,
  isLoading,
}) => {
  const getFacilityOptionByValue = (optionValue) => {
    if (facilities) {
      const option = facilities.find((facility) => facility.id === optionValue);
      return option ? { value: option.id, label: option.name } : null;
    } else {
      return null;
    }
  };
  return (
    <>
      <GVDSModal
        title={isEditExistingMeter ? "Edit Meter" : "Add a meter"}
        size={GVDSModal.Size.large}
        show={show}
        onHide={cancel}
      >
        <GVDSModal.Body>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {isEditExistingMeter ? (
                ""
              ) : (
                <GVFormGroup>
                  <p>
                    Meters can only be created at Hotel & Accommodation
                    facilities with a limit of one meter per operational type.
                  </p>
                </GVFormGroup>
              )}
              <Row>
                <Col md={8} lg={8}>
                  <GVFormGroup>
                    <Form.Label>Meter ID</Form.Label>
                    <div>{DEFAULT_OPERATION_METER_NAME}</div>
                  </GVFormGroup>
                  <GVFormGroup controlId="meterForm.SelectCustom">
                    <Form.Label>Facility</Form.Label>
                    <GVDSFormSingleSelect
                      className="select__facility"
                      placeholder="Select Facility"
                      value={
                        meterDetails.facilityID
                          ? getFacilityOptionByValue(meterDetails.facilityID)
                          : null
                      }
                      options={facilities.map((facility) => {
                        return { value: facility.id, label: facility.name };
                      })}
                      onSelect={(selectedOption) =>
                        onDropdownChange(selectedOption.value, "facilityID")
                      }
                      statusMetadata={
                        isValidated && !meterDetails.facilityID
                          ? FormFieldStatusMetadata.getError(
                              "Please select a facility."
                            )
                          : FormFieldStatusMetadata.getDefault()
                      }
                      disabled={disableFacilitySelect}
                    />
                    {facilities.length === 0 && (
                      <div className="no-facility-message">
                        No facilities available. Operational meter for this type
                        may have already been created at your Hotel &
                        Accommodation facilities.
                      </div>
                    )}
                  </GVFormGroup>

                  <GVFormGroup controlId="frequency">
                    <Form.Label>Frequency</Form.Label>
                    <div className="form-label-description">
                      {
                        TextCopies.EnvironmentalOperational
                          .meterFrequencyDescription
                      }
                      <InfoTooltip
                        info={`${TextCopies.EnvironmentalOperational.meterFrequencyExample}.`}
                      />
                    </div>
                    <GVDSFormSingleSelect
                      className="select__frequency"
                      placeholder="Select frequency"
                      value={
                        meterDetails.frequency
                          ? getFrequencyOptionByValue(meterDetails.frequency)
                          : null
                      }
                      options={FREQUENCY_OPTIONS}
                      onSelect={(selectedOption) =>
                        onDropdownChange(selectedOption.value, "frequency")
                      }
                      statusMetadata={
                        isValidated && !meterDetails.frequency
                          ? FormFieldStatusMetadata.getError(
                              "Please select a frequency."
                            )
                          : FormFieldStatusMetadata.getDefault()
                      }
                    />
                  </GVFormGroup>

                  <GVFormGroup>
                    <Form.Label>Description</Form.Label>
                    <span className="optional-form-label mx-1">(Optional)</span>
                    <GVDSFormFieldWithCharacterCount
                      placeholder="Short reference description for other users"
                      value={meterDetails.description}
                      onInput={(value) => onTextChange(value, "description")}
                      maxLength={50}
                      statusMetadata={FormFieldStatusMetadata.getDefault()}
                    />
                  </GVFormGroup>
                </Col>
                <Col md={4} lg={4}>
                  <MeterTips />
                </Col>
              </Row>
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {isEditExistingMeter && (
            <>
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                onClick={() => deleteMeterHandler(meterDetails)}
                disabled={isLoading}
                text="Delete"
              />
              <Spacer />
            </>
          )}
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancel}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            disabled={facilities.length === 0 || isLoading}
            text="Save"
            onClick={saveMeter}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default OperationMeterDialog;
