import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import withAuthentication from "../HOC/withAuthentication";
import { getViewHelpArticlePath, HELP } from "../../config/ROUTES_NAME";
import LoadingSpinner from "../common/LoadingSpinner";
import HelpArticleService from "../../services/HelpArticleService";
import { DateTimeUtils } from "../../services/UtilsService";
import { ARTICLE_CONTENT_TYPE } from "../../config/constants";
import { getPresignedS3Url } from "../common/FileAttachments";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import GVDSLink from "../../gvds-components/Buttons/GVDSLink";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import { ContentTypeDisplay } from "../common/ContentTypeDisplay";
import { HelpAdditionalSupportBlurb } from "./HelpSharedComponents";
import { Trans, useTranslation } from "react-i18next";

const ViewHelpArticle = () => {
  const { t } = useTranslation();

  let location = useLocation();
  let history = useHistory();
  const { articleId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [article, setArticle] = useState(null);
  const [pdfPresignedUrl, setPdfPresignedUrl] = useState(null);
  const [videoPresignedUrl, setVideoPresignedUrl] = useState(null);

  const videoPlayerRef = useCallback(
    (node) => {
      if (node !== null && videoPresignedUrl !== null) {
        // Reference https://gist.github.com/edin-m/889fa79a0fa124b1a8c3
        // Reference https://wplearninglab.com/amazon-s3-video-embed-code/
        node.src = videoPresignedUrl;
        node.load();
        node.oncontextmenu = (e) => {
          e.preventDefault();
        };
      }
    },
    [article, videoPresignedUrl]
  );

  useEffect(() => {
    HelpArticleService.getHelpArticle(articleId).then((article) => {
      setArticle(article);
      setIsLoading(false);

      if (article.file) {
        if (article.articleType.name === ARTICLE_CONTENT_TYPE.PDF) {
          getPresignedS3Url(article.file.link).then((presignedS3URL) => {
            setPdfPresignedUrl(presignedS3URL);
          });
        } else if (article.articleType.name === ARTICLE_CONTENT_TYPE.VIDEO) {
          getPresignedS3Url(article.file.link).then((presignedS3URL) => {
            setVideoPresignedUrl(presignedS3URL);
          });
        }
      }
    });
  }, [articleId]);

  const goToHelpArticleList = () => {
    history.push(HELP);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isContentLoading = () => {
    if (article.articleType.name === ARTICLE_CONTENT_TYPE.PDF) {
      return !pdfPresignedUrl;
    } else if (article.articleType.name === ARTICLE_CONTENT_TYPE.VIDEO) {
      return !videoPresignedUrl;
    } else {
      return true;
    }
  };

  const getContentDisplay = () => {
    if (!article.file) {
      return (
        <div>
          <div className="body-1-bold">There was an internal error:</div>
          <div className="body-1">
            Please contact portal admin at{" "}
            <a href="mailto:support@greenviewportal.com">
              support@greenviewportal.com
            </a>
          </div>
        </div>
      );
    }

    if (isContentLoading()) {
      return null;
    }

    if (article.articleType.name === ARTICLE_CONTENT_TYPE.PDF) {
      return (
        <iframe
          title="PDF"
          className="help-article-pdf-container"
          src={`${
            process.env.PUBLIC_URL
          }/dist/pdfjs-2.5.207-dist/web/gv-viewer.html?file=${encodeURIComponent(
            pdfPresignedUrl
          )}#zoom=page-width`}
        />
      );
    } else if (article.articleType.name === ARTICLE_CONTENT_TYPE.VIDEO) {
      return (
        <div className="help-article-video-container">
          <video
            ref={videoPlayerRef}
            controls="controls"
            controlsList="nodownload"
          />
        </div>
      );
    }
  };

  const backButtonPage = t("help-center.article.back-page-label");

  return (
    <div className="flex-grow-1 d-flex flex-column">
      <PageHeader>
        <PageHeader.BackButton
          text={
            <Trans i18nKey="shared.button-return-to-page">
              Return to {{ backButtonPage }}
            </Trans>
          }
          onClick={goToHelpArticleList}
        />
        <PageHeader.Title>
          <h1>{article.title}</h1>
        </PageHeader.Title>
      </PageHeader>
      <div className="d-flex flex-grow-1">
        <div className="help-article">
          {article && article.file && isContentLoading() && <LoadingSpinner />}
          {getContentDisplay()}
        </div>
        <div className="help-article-side-info">
          <div className="gvds-text--heading4">
            {t("shared.last-updated-on")}
          </div>
          {DateTimeUtils.formatUTCDate(article.lastEditedOn)}
          {article.relevantArticles.length > 0 && (
            <>
              <div className="gvds-text--heading4 mt-2">
                {t("help-center.article.label-relevant-guides")}
              </div>
              <ul>
                {article.relevantArticles.map((ra) => {
                  return (
                    <li>
                      <div className="text-vertical-center">
                        <GVDSLink
                          className="help-center-article-link"
                          variant={GVDSLink.variant.media_title}
                          to={getRedirectURLWithCurrentParam(
                            getViewHelpArticlePath(ra.id),
                            location
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {ra.title}
                        </GVDSLink>
                        <ContentTypeDisplay
                          contentTypeName={ra.article_type.name}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          <div className="gvds-text--heading4 mt-2">
            {t("help-center.article.label-additional-support")}
          </div>
          <HelpAdditionalSupportBlurb />
        </div>
      </div>
    </div>
  );
};

export default withAuthentication(ViewHelpArticle);
