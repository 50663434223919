import React, { useContext } from "react";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import BillingPackages from "../../common/Billing/BillingPlans";
import BillingPlanComparisonTable from "../../common/Billing/BillingPlanComparisonTable";
import {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  SubscriptionPricingType,
} from "../../SystemToolbox/Subscription/SubscriptionSharedUtils";
import {
  CONTACT_EMAIL_SALES,
  CONTACT_EMAIL_SALES_SUBJECT,
  RESOURCES,
} from "../../../config/constants";
import UserProfileContext from "../../../context/UserProfileContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { isUserBillingAdminForInventory } from "./BillingSharedUtils";
import { MailToUtils } from "../../../services/UtilsService";
import LoadingSpinner from "../../common/LoadingSpinner";
import FeatureContext from "../../../context/FeatureContext";

const ViewPricingPlan = ({
  show,
  onHide,
  orderedPackagePlans,
  billingAdmin,
  currentSubscription,
  currentPlanId,
  onChoosingNewPlan,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const selectedTreeNode = userInventory.selectedTreeNode.get;
  const inventoryDetails = selectedTreeNode?.nodeValue?.value;
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();
  const featureCtx = useContext(FeatureContext);
  const allFeatures = featureCtx.features;

  const isBillingAdmin = isUserBillingAdminForInventory(user, inventoryDetails);

  const isAtSite = selectedInventory.type === RESOURCES.SITE;

  const currency = "usd";

  const onChoosingPlan = (plan) => {
    onChoosingNewPlan(plan);
  };

  const onChoosingEnterprise = () => {
    window.open(
      MailToUtils.sendEmailWithSubject(
        CONTACT_EMAIL_SALES,
        CONTACT_EMAIL_SALES_SUBJECT
      ),
      "_blank"
    );
  };

  let isInTrial = false;
  if (currentSubscription?.pricingType === SubscriptionPricingType.FREE) {
    isInTrial = true;
  }

  const isLoading = !orderedPackagePlans || orderedPackagePlans.length === 0;

  const isSubscriptionFromContract =
    currentSubscription && currentSubscription.siteContractSubscriptionDetails;
  const isSubscriptionFromContractAndFullFeature =
    isSubscriptionFromContract &&
    currentSubscription.features.length === allFeatures.length;

  return (
    <GVDSModal size={GVDSModal.Size.large} show={show} onHide={onHide} className="view-pricing-plan-modal">
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <BillingPackages
            pricingPlanModels={orderedPackagePlans}
            onChoosingPlan={onChoosingPlan}
            onChoosingEnterprise={onChoosingEnterprise}
            currency={currency}
            isSignUp={false}
            isBillingAdmin={isBillingAdmin}
            billingAdminEmail={billingAdmin?.email}
            currentPlanId={currentPlanId}
            isInTrial={isInTrial}
            isAtSite={isAtSite}
            disableSelection={isSubscriptionFromContractAndFullFeature}
          />
        )}
        <div className="mb-5"></div>
        <BillingPlanComparisonTable
          orderedPlanNames={orderedPackagePlans
            .map((p) => p.name)
            .filter((name) => name !== CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME)}
        />
      </GVDSModal.Body>
    </GVDSModal>
  );
};

export default ViewPricingPlan;
