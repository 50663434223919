export const dashboardSubtopic = {
  energy: "Energy",
  emissions: "Emissions",
  water: "Water",
  waste: "Waste",

  gfa: "GFA",
  conditionedSpace: "Conditioned Space",
  occupiedRoom: "Occupied Room",
};

export const classificationGroups = {
  environmentType: "Environment Type",
  scope: "Scope 1, 2 and 3",
  scope1Grouping: "Scope 1",
  scope2Grouping: "Scope 2",
  scope3Category: "Scope 3",
};

export const classificationValues = {
  scope1: "Scope 1",
  scope2: "Scope 2",
  scope3: "Scope 3",
};

export const scopeClassificationValues = [
  classificationValues.scope1,
  classificationValues.scope2,
  classificationValues.scope3,
];

export const scope3InclusionEnum = {
  allScopes: "allScopes",
  scope3Only: "scope3Only",
  noScope3: "noScope3",
  none: "none",
};

export const rowDataMeta = {
  scope3: "scope3",
};
