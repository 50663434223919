import React, { useState } from "react";
import GVDSFormSingleDatePicker from "./GVDSFormSingleDatePicker";
import {
  FormFieldStatusMetadata,
  getDateLabel,
  getMinEndDate,
  GVDSFormErrorMessage,
  isEndDateBeforeStartDate,
} from "./GVDSFormShared";

const GVDSFormStartEndDatePicker = ({
  className,
  startDate,
  startDateLabel,
  startPlaceholderText = "From",
  minStartDate,
  maxStartDate,
  endDate,
  endDateLabel,
  endPlaceholderText = "To",
  minEndDate,
  maxEndDate,
  onChange,
  startYear,
  endYear,
  dateFormat = "dd/MM/yyyy",
  disabledStartDate = false,
  disabledEndDate = false,
  statusMetadata = FormFieldStatusMetadata.getDefault(),
  isPortalling = true,
}) => {
  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);

  const gvdsClassNames =
    "gvds-form__start-end-datepicker__container " + (className ?? "");
  const statusMetadataWithoutMessage = new FormFieldStatusMetadata(
    statusMetadata.state
  );
  const shouldShowDateLabel = startDateLabel || endDateLabel;

  const onStartDateChange = (inputDate) => {
    if (isEndDateBeforeStartDate(inputDate, endDate)) {
      onChange(inputDate, null);
    } else {
      onChange(inputDate, endDate);
    }
  };

  return (
    <>
      <div className={gvdsClassNames}>
        <div className="start-date__container">
          {shouldShowDateLabel && getDateLabel(startDateLabel, endDateLabel)}
          <GVDSFormSingleDatePicker
            placeholder={startPlaceholderText}
            selected={startDate}
            onChange={onStartDateChange}
            startYear={startYear}
            endYear={endYear}
            dateFormat={dateFormat}
            disabled={disabledStartDate}
            onCalendarOpen={() => setIsStartCalendarOpen(true)}
            onCalendarClose={() => setIsStartCalendarOpen(false)}
            isFocusStyle={isStartCalendarOpen}
            className="start-date"
            statusMetadata={statusMetadataWithoutMessage}
            minDate={minStartDate}
            maxDate={maxStartDate}
            highlightDates={endDate ? [endDate] : []}
            isPortalling={isPortalling}
          />
        </div>
        <div className="end-date__container">
          {shouldShowDateLabel && getDateLabel(endDateLabel, startDateLabel)}
          <GVDSFormSingleDatePicker
            placeholder={endPlaceholderText}
            selected={endDate}
            onChange={(date) => onChange(startDate, date)}
            startYear={startYear}
            endYear={endYear}
            dateFormat={dateFormat}
            disabled={disabledEndDate}
            onCalendarOpen={() => setIsEndCalendarOpen(true)}
            onCalendarClose={() => setIsEndCalendarOpen(false)}
            isFocusStyle={isEndCalendarOpen}
            className="end-date"
            statusMetadata={statusMetadataWithoutMessage}
            isUseIcon={false}
            minDate={getMinEndDate(startDate, minEndDate)}
            maxDate={maxEndDate}
            openToDate={
              endDate && !isEndDateBeforeStartDate(startDate, endDate)
                ? endDate
                : startDate
            }
            highlightDates={startDate ? [startDate] : []}
            isPortalling={isPortalling}
          />
        </div>
      </div>
      {statusMetadata.isNotNormal() && statusMetadata.message && (
        <GVDSFormErrorMessage
          status={statusMetadata.state}
          errorMsg={statusMetadata.message}
        />
      )}
    </>
  );
};

export default GVDSFormStartEndDatePicker;
