import { useEffect, useState } from "react";
import _ from "lodash";
import fuzzysort from "fuzzysort";
import {
  FUZZYSORT_SHARED_OPTIONS,
  fuzzysortOptionsWithKeys,
} from "../../config/search-config";
import SavedFiltersService from "../../services/SavedFiltersService";
import { NumberService, StringUtils } from "../../services/UtilsService";

const initialIndex = 0;

const useGVDSTableCtrl = (
  data,
  searchKey = null,
  defaultSortByDataKey = {},
  sortKeyPriorities = {},
  persistFilterKey = null,
  fuzzysortOptions = FUZZYSORT_SHARED_OPTIONS
) => {
  const [filteredSortedData, setFilteredSortedData] = useState([]);

  const [startIndex, setStartIndex] = useState(initialIndex);
  const [endIndex, setEndIndex] = useState(initialIndex);
  const onPaginationChange = (start, end) => {
    setStartIndex(start);
    setEndIndex(end);
  };

  const [searchText, setSearchText] = useState("");

  const [sortKeys, setSortKeys] = useState(defaultSortByDataKey);

  const [filterKeys, setFilterKeys] = useState({});

  useEffect(() => {
    const savedFilters = SavedFiltersService.getSavedFilters(persistFilterKey);

    if (savedFilters) {
      setSearchText(
        savedFilters[SavedFiltersService.SAVED_FILTER_OBJ_KEY__SEARCH_TEXT]
      );
      setFilterKeys(
        savedFilters[SavedFiltersService.SAVED_FILTER_OBJ_KEY__FILTER_KEY]
      );
    }
  }, []);

  useEffect(() => {
    let filtered = [...data];

    if (searchKey && searchText && searchText.length > 0) {
      filtered = fuzzysort
        .go(
          searchText,
          filtered,
          fuzzysortOptionsWithKeys(searchKey, fuzzysortOptions)
        )
        .map((result) => result.obj);
    }

    if (filterKeys) {
      for (let [filterK, filterVs] of Object.entries(filterKeys)) {
        filtered = filtered.filter((item) => {
          if (filterVs.length === 0) return true;
          const itemProperty = _.get(item, filterK);
          if (Array.isArray(itemProperty)) {
            return itemProperty.some((value) => filterVs.indexOf(value) >= 0);
          } else {
            return filterVs.indexOf(itemProperty) >= 0;
          }
        });
      }
    }

    const sortKeysByPriority = _.chain(sortKeys)
      .keys()
      .sortBy([(key) => sortKeyPriorities[key]])
      .value();

    const caseInsensitiveSortKeysByPriority = sortKeysByPriority.map(
      (sortKey) => {
        return (data) =>
          NumberService.isNumber(data[sortKey])
            ? data[sortKey]
            : StringUtils.isNotEmpty(data[sortKey])
            ? data[sortKey].toLowerCase()
            : null;
      }
    );

    const sortedData = _.orderBy(
      filtered,
      caseInsensitiveSortKeysByPriority,
      sortKeysByPriority.map((k) => sortKeys[k])
    );

    if (persistFilterKey) {
      SavedFiltersService.saveFilters(persistFilterKey, {
        [SavedFiltersService.SAVED_FILTER_OBJ_KEY__SEARCH_TEXT]: searchText,
        [SavedFiltersService.SAVED_FILTER_OBJ_KEY__FILTER_KEY]: filterKeys,
      });
    }

    setFilteredSortedData(sortedData);
  }, [data, searchKey, searchText, filterKeys, sortKeys]);

  const isPaginationNotInitialised =
    startIndex === initialIndex && endIndex === initialIndex;
  const currentPageData = isPaginationNotInitialised
    ? filteredSortedData
    : filteredSortedData.slice(startIndex - 1, endIndex);
  const totalDataLength = filteredSortedData.length;

  return {
    filteredSortedData,
    currentPageData,

    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,

    searchText,
    setSearchText,

    filterKeys,
    setFilterKeys,

    sortKeys,
    setSortKeys,
  };
};

export default useGVDSTableCtrl;
