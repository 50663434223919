import flatMap from "lodash/flatMap";
import get from "lodash/get";
import set from "lodash/set";

export default class BulkSiteUsersInputErrorService {
  constructor() {
    this.dataErrorRows = null;
  }

  resetErrors() {
    this.dataErrorRows = null;
  }

  updateErrors(errors) {
    this.dataErrorRows = errors.dataErrors.errorRowNumbers;
  }

  hasDataErrors() {
    return this.dataErrorRows && this.dataErrorRows.length > 0;
  }

  hasNoDataErrors() {
    return this.dataErrorRows && this.dataErrorRows.length === 0;
  }

  disableSubmit() {
    return !this.dataErrorRows || this.dataErrorRows.length > 0;
  }

  prepareDataErrors(dataErrors) {
    let errors = {};
    for (const [row, err] of Object.entries(dataErrors)) {
      let error = { ...err };
      if (!get(error, "site_name") && get(error, "site_id")) {
        set(error, "site_name", get(error, "site_id"));
      }

      errors[row] = error;
      errors[row]["index"] = flatMap(Object.values(errors[row]));
    }
    return errors;
  }
}
