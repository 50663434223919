import React, { useContext, useEffect, useState } from "react";
import TargetService from "../../services/TargetService";
import ToastContext from "../../context/ToastContext";
import UserInventoryContext from "../../context/UserInventoryContext";
import {
  RESOURCES,
  TARGET_TOPIC,
  TARGET_TOPIC_DISPLAY_LABEL,
} from "../../config/constants";
import TargetPreview from "./TargetPreview";
import GVFormGroup from "../common/GVFormGroup";
import InputTargetStepSelectTopic from "./InputTargetStepSelectTopic";
import InputTargetStepTargetDetails from "./InputTargetStepTargetDetails";
import InputTargetStepTargetValue from "./InputTargetStepTargetValue";
import InputTargetStepTargetPeriod from "./InputTargetStepTargetPeriod";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";

const InputTargetStepAssociateGoal = ({
  goals,
  selectedGoalId,
  setSelectedGoalId,
}) => {
  const selectedGoal = goals.find((goal) => goal.id === selectedGoalId);

  return (
    <GVFormGroup>
      <div className="gv-body-2--bold gvds-color--gray6">
        STEP 1. ASSOCIATE TO A GOAL{" "}
        <span className="optional-form-label">(Optional)</span>
      </div>
      <div className="p-3">
        <div className="form-label-description">
          You may group this target under an existing goal. If you do not wish
          to do so, leave this field blank.{" "}
        </div>
        <GVDSFormSingleSelect
          isClearable
          value={
            selectedGoal
              ? { value: selectedGoal.id, label: selectedGoal.name }
              : null
          }
          options={goals.map((goal) => {
            return { value: goal.id, label: goal.name };
          })}
          onSelect={(goalOption) => setSelectedGoalId(goalOption?.value)}
        />
      </div>
    </GVFormGroup>
  );
};

const InputTargetModal = ({
  show,
  setShow,
  onChange,
  goals,
  existingTarget = {},
  clearOnSave = false,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const isEditing = !!existingTarget?.id;

  const [goalId, setGoalId] = useState("");
  const [topic, setTopic] = useState("");
  const [config, setConfig] = useState({});
  const [params, setParams] = useState({});
  const [targetErrors, setTargetErrors] = useState({});
  const [isTargetChanging, setIsTargetChanging] = useState(false);
  const [hasComparisonYear, setHasComparisonYear] = useState();

  const setFormToExistingTarget = () => {
    setGoalId(existingTarget.goal?.id);
    setTopic(existingTarget.topic);
    setHasComparisonYear(!!existingTarget.params.comparison_year);
    setParams({
      ...existingTarget.params,
      target_year: existingTarget.targetYear,
      baseline_year: existingTarget.baselineYear,
    });
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseResetForm = () => {
    setShow(false);

    if (existingTarget.id) {
      setFormToExistingTarget();
    }
  };

  useEffect(() => {
    if (selectedInventory?.id && selectedInventory?.type === RESOURCES.SITE) {
      TargetService.getConfig(selectedInventory.type, selectedInventory.id)
        .then((data) => {
          setConfig(data);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load target configuration.</span>
          );
        });
    }
  }, [selectedInventory]);

  useEffect(() => {
    if (existingTarget.id) {
      setFormToExistingTarget();
    }
  }, [existingTarget.id]);

  const onTopicChange = (newTopic) => {
    setTopic(newTopic);
    setParams({
      ...params,
      subtopic_name: "",
      performance_group_name: "",
      intensity_metric: "",
      area_unit_id: "",
      un_sdgs: null,
      unit_id: "",
      target_name: "",
      use_location_based: null,
    });
  };

  const getTargetFromParams = () => {
    let targetParams = { ...params };
    ["target_year", "baseline_year"].forEach((k) => delete targetParams[k]);
    return {
      goal_id: goalId && goalId !== "" ? goalId : null,
      topic,
      target_year: params["target_year"],
      baseline_year: params["baseline_year"],
      params: targetParams,
    };
  };

  const saveTarget = (e) => {
    e.preventDefault();
    if (!selectedInventory.id) {
      toastContext.addFailToast(
        <span>An error occurred. Please try again.</span>
      );
      return;
    }
    setIsTargetChanging(true);
    const httpRequest = isEditing
      ? TargetService.updateTarget(
          selectedInventory.type,
          selectedInventory.id,
          existingTarget.id,
          getTargetFromParams()
        )
      : TargetService.createTarget(
          selectedInventory.type,
          selectedInventory.id,
          getTargetFromParams()
        );
    httpRequest
      .then(() => {
        setIsTargetChanging(false);
        onChange();
        setShow(false);
        if (clearOnSave) {
          setGoalId("");
          setTopic("");
          setHasComparisonYear(undefined);
          setParams({});
        }
        toastContext.addSuccessToast(
          `Your target was ${isEditing ? "saved" : "created"} successfully.`
        );
      })
      .catch(() => {
        setIsTargetChanging(false);
        toastContext.addFailToast(
          <span>
            Failed to {isEditing ? "edit" : "create"} target. Please try again.
          </span>
        );
      });
  };

  return (
    <GVDSModal
      title={isEditing ? "Edit Target" : "Create Target"}
      size={GVDSModal.Size.large}
      show={show}
      onHide={handleClose}
      className="create-target-form"
    >
      <GVDSModal.Body>
        <div className="input-target-modal__layout">
          <div className="create-target-form__steps">
            <InputTargetStepAssociateGoal
              goals={goals}
              selectedGoalId={goalId}
              setSelectedGoalId={setGoalId}
            />
            <InputTargetStepSelectTopic
              title="STEP 2. SELECT A TOPIC"
              topic={topic}
              setTopic={onTopicChange}
            >
              <div
                className={
                  "create-target-form__box" +
                  (topic === TARGET_TOPIC.FREE_FORM ? " active" : "")
                }
                onClick={() => onTopicChange(TARGET_TOPIC.FREE_FORM)}
              >
                <div className="target-topic-name">
                  {TARGET_TOPIC_DISPLAY_LABEL[TARGET_TOPIC.FREE_FORM]}
                </div>
                <div className="target-topic-description">
                  Any other targets that are currently not measured in the
                  portal
                </div>
              </div>
            </InputTargetStepSelectTopic>
            <InputTargetStepTargetDetails
              title="STEP 3. WHAT IS YOUR TARGET?"
              topic={topic}
              config={config}
              params={params}
              setParams={setParams}
            />
            <InputTargetStepTargetValue
              title="STEP 4. DETERMINING TARGET"
              topic={topic}
              config={config}
              params={params}
              setParams={setParams}
              businessErrors={targetErrors["business_errors"]}
              targetInvalid={targetErrors["target_invalid"]}
              hasComparisonYear={hasComparisonYear}
              setHasComparisonYear={setHasComparisonYear}
            />
            <InputTargetStepTargetPeriod
              title={`STEP ${
                topic === TARGET_TOPIC.FREE_FORM ? 4 : 5
              }. DETERMINING TARGET PERIOD`}
              params={params}
              setParams={setParams}
            />
          </div>
          <div className="create-target-form__preview">
            <TargetPreview
              newTarget={getTargetFromParams()}
              targetErrors={targetErrors}
              setTargetErrors={setTargetErrors}
            />
            {targetErrors["target_invalid"] && (
              <div className="target-value-error-message ms-2 mt-2 mb-2">
                Target value cannot be > 100% as it is unattainable.
              </div>
            )}
            {!targetErrors["target_invalid"] && <div className="mt-4" />}
            {isEditing && (
              <div className="gvds-text--modal-warning-text text-end mb-1">
                Upon saving, previous target setup will be lost. <br />
                Any changes to the target will not affect comments.
              </div>
            )}
            <div className="d-flex justify-content-end">
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={handleCloseResetForm}
                text="Cancel"
              />
              <GVDSButton
                className="save-target-button ms-2"
                variant={buttonVariant.primary}
                disabled={
                  targetErrors["business_errors"] ||
                  targetErrors["data_incomplete"] ||
                  targetErrors["target_invalid"] ||
                  isTargetChanging
                }
                onClick={saveTarget}
                text="Save"
              />
            </div>
          </div>
        </div>
      </GVDSModal.Body>
    </GVDSModal>
  );
};

export default InputTargetModal;
