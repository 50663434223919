import axios from "axios";
import { API_URL } from "../config/api-config";

export class BrandListItemModel {
  constructor(brandListItem) {
    this.id = brandListItem.id;
    this.name = brandListItem.name;
    this.brand_company_id = brandListItem.brand_company_id;
    this.brand_company = brandListItem.brand_company;
  }

  get brand_name() {
    return this.brand_company;
  }
}

export default class BrandFlagService {
  static getAllBrandFlags = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/brand_company"
      );
      // return response.data;
      return response.data.map((a) => new BrandListItemModel(a));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static createNewBrandFlag = async (newRecord) => {
    try {
      const data = {
        name: newRecord.brand_flag_name,
        brand_company_id: newRecord.brand_company_id,
      };
      const response = await axios.post(
        API_URL + "/api/v1/ref_data/brand_company",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static updateBrandFlag = async (brandFlagId, newRecord) => {
    try {
      const data = {
        name: newRecord.brand_flag_name,
        brand_company_id: newRecord.brand_company_id,
      };
      const response = await axios.patch(
        API_URL + `/api/v1/ref_data/brand_company/${brandFlagId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getBrandFlagById = async (BrandFlagId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/brand_company/" + BrandFlagId
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static deleteDataRecords = async (brandFlagId) => {
    try {
      const response = await axios.delete(
        API_URL + `/api/v1/ref_data/brand_company/${brandFlagId}`
      );
      return response;
    } catch (error) {}
  };
}
