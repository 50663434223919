import Form from "react-bootstrap/Form";
import React from "react";

export const indeterminateCheckboxValueEnum = {
  true: "true",
  false: "false",
  indeterminate: "indeterminate",
};

const GVDSFormCheckboxWithIndeterminate = ({ value, className, ...props }) => {
  const checkboxClassName = `${className || ""} ${
    value === indeterminateCheckboxValueEnum.indeterminate
      ? "form-check-input-indeterminate"
      : ""
  }`;

  return (
    <Form.Check
      {...props}
      className={checkboxClassName}
      checked={value === indeterminateCheckboxValueEnum.true}
    />
  );
};

export default GVDSFormCheckboxWithIndeterminate;
