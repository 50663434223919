import React from "react";
import _ from "lodash";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import GVDSIcon from "../Icons/GVDSIcon";
import {
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconArrowsSort,
} from "@tabler/icons-react";
import GVDSIconButton, { iconButtonVariant } from "../Buttons/GVDSIconButton";
import { useTranslation } from "react-i18next";

export const MULTI_SELECT_DATAKEY = "multi-select";
export const ACTIONS_TABLE_HEADER = "Actions";
export const ACTIONS_DATAKEY = "actions";

export const SORTING_TYPES = {
  asc: "asc",
  desc: "desc",
};

const getSortingIcon = (sortType) => {
  if (sortType === SORTING_TYPES.asc) {
    return <GVDSIcon Icon={IconArrowNarrowUp} />;
  } else if (sortType === SORTING_TYPES.desc) {
    return <GVDSIcon Icon={IconArrowNarrowDown} />;
  } else {
    return <GVDSIcon Icon={IconArrowsSort} />;
  }
};

const getNextSortType = (sortType) => {
  switch (sortType) {
    case SORTING_TYPES.asc:
      return SORTING_TYPES.desc;
    case SORTING_TYPES.desc:
      return "";
    default:
      return SORTING_TYPES.asc;
  }
};

const getNewSortKeys = (sortKeys, dataKey, newSortType) => {
  let newSortKeys = { ...sortKeys };
  if (newSortType) {
    newSortKeys = { ...newSortKeys, [dataKey]: newSortType };
  } else {
    delete newSortKeys[dataKey];
  }
  return newSortKeys;
};

const getSortingButtonForColumns = (
  columns,
  sortKeys,
  dataKey,
  setSortKeys
) => {
  const col = _.find(columns, (c) => c["dataKey"] === dataKey);
  if (!col["sortable"]) return;

  return getSortingButton(sortKeys, dataKey, setSortKeys);
};

export const getSortingButton = (sortKeys, dataKey, setSortKeys) => {
  const onSortChange = (dataKey) => {
    const newSortType = getNextSortType(sortKeys[dataKey]);
    setSortKeys(getNewSortKeys(sortKeys, dataKey, newSortType));
  };

  const isActive =
    sortKeys[dataKey] === SORTING_TYPES.asc ||
    sortKeys[dataKey] === SORTING_TYPES.desc;

  return (
    <GVDSIconButton
      variant={iconButtonVariant.tertiary}
      icon={getSortingIcon(sortKeys[dataKey])}
      className={"gvds-table-cell__sorting-icon" + (isActive ? " active" : "")}
      onClick={() => onSortChange(dataKey)}
    />
  );
};

const getRowClassName = (isSelected, isClickable) => {
  const classNames = ["data-row"];

  if (isSelected) {
    classNames.push("is-selected");
  }
  if (isClickable) {
    classNames.push("is-clickable");
  }

  return classNames.join(" ");
};

export const GVDSTableRowSelectCheckbox = ({ checked, onChange }) => {
  return (
    <Form.Check
      checked={checked}
      type="checkbox"
      label=""
      onChange={onChange}
    />
  );
};

const GVDSTable = ({
  viewControl = null,
  noContent = null,
  columns = [],
  dataToDisplay = [],
  hideRowNumber = false,
  startIndex,
  sortKeys,
  setSortKeys,
  className = "",
  isPageSelected = false,
  onPageSelect = null,
  onRowClick = null,

  selectionKey,
  selectedValues,
}) => {
  const { t } = useTranslation();

  let tableContent;
  if (dataToDisplay.length === 0) {
    tableContent = noContent;
  } else {
    const multiSelectColumnIndex = columns.findIndex(
      (c) => c["dataKey"] === MULTI_SELECT_DATAKEY
    );
    let dataColumns = _.clone(columns);
    if (multiSelectColumnIndex !== -1) {
      dataColumns.splice(multiSelectColumnIndex, 1);
    }

    tableContent = (
      <div className="gvds-table-viewport">
        <Table className={className}>
          <thead>
            <tr>
              {multiSelectColumnIndex !== -1 && (
                <th
                  key={MULTI_SELECT_DATAKEY}
                  className="gvds-table-cell__multi-select"
                >
                  {onPageSelect && (
                    <div className="gvds-table-header__header-cell">
                      <Form.Check
                        checked={isPageSelected}
                        type="checkbox"
                        label=""
                        onChange={onPageSelect}
                      />
                    </div>
                  )}
                </th>
              )}
              {!hideRowNumber && (
                <th className="gvds-table-cell__row-number-header">
                  <div className="gvds-table-header__header-cell">
                    {t("shared-table-header.numbering")}
                  </div>
                </th>
              )}
              {dataColumns.map((c) => (
                <th
                  key={c["header"]}
                  className={
                    (c["sortable"] ? "sortable" : "") +
                    (c["dataKey"] === ACTIONS_DATAKEY
                      ? " gvds-table-cell__actions-header"
                      : "")
                  }
                  style={c["headerStyle"] ? c["headerStyle"] : null}
                >
                  <div className="gvds-table-header__header-cell">
                    <div className="gvds-table-header__header-cell-text">
                      {c["header"]}
                    </div>
                    {getSortingButtonForColumns(
                      columns,
                      sortKeys,
                      c["dataKey"],
                      setSortKeys
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {dataToDisplay.map((d, index) => (
              <tr
                key={index}
                className={getRowClassName(
                  selectedValues && selectedValues.includes(d[selectionKey]),
                  onRowClick !== null
                )}
              >
                {multiSelectColumnIndex !== -1 && (
                  <td
                    key={index + MULTI_SELECT_DATAKEY}
                    className="gvds-table-cell__multi-select"
                  >
                    {columns[multiSelectColumnIndex]["renderer"](d)}
                  </td>
                )}
                {!hideRowNumber && (
                  <td
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(d);
                      }
                    }}
                  >
                    <div className="gvds-table-cell__row-number">
                      {index + startIndex}
                    </div>
                  </td>
                )}
                {dataColumns.map((c) => {
                  if (c["dataKey"] === ACTIONS_DATAKEY) {
                    return (
                      <td key={index + c["dataKey"]}>
                        <div className="gvds-table-cell__actions-container">
                          {c["renderer"]
                            ? c["renderer"](d)
                            : _.get(d, c["dataKey"])}
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={index + c["dataKey"]}
                        onClick={() => {
                          if (onRowClick) {
                            onRowClick(d);
                          }
                        }}
                      >
                        {c["renderer"]
                          ? c["renderer"](d)
                          : _.get(d, c["dataKey"])}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  return (
    <div className="gvds-table-container">
      {!!viewControl && (
        <div className="gvds-table-view-control__container">{viewControl}</div>
      )}
      {tableContent}
    </div>
  );
};

export default GVDSTable;
