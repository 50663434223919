import React, { useContext, useEffect, useState } from "react";
import AffiliationsService from "../../../../services/ReferenceDataServices/HotelAndAccommodationAttributes/AffiliationService";
import { Container } from "react-bootstrap";
import { REFERENCE_DATA } from "../../../../config/ROUTES_NAME";
import withAuthentication from "../../../HOC/withAuthentication";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../../common/LoadingSpinner";
import Form from "react-bootstrap/Form";
import ToastContext from "../../../../context/ToastContext";
import DeleteRecordDialog from "../DeleteRecordDialog";
import GVFormGroup from "../../../common/GVFormGroup";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import FilterSearchBox from "../../../../gvds-components/common/FilterSearchBox";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../../config/search-config";

const Affiliations = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [affiliations, setAffiliations] = useState([]);
  const toastContext = useContext(ToastContext);
  const [editRecord, setEditRecord] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [typeId, setTypeId] = useState("");
  const [show, setShowInputModal] = useState(false);

  const [isValidated, setIsValidated] = useState(false);
  const [deletedId, setCurrentlyDeletedType] = useState("");
  const [isDelete, setIsDelete] = useState("");
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    affiliations,
    SystemToolboxSearchKeysByPageName.referenceData.affiliations,
    {
      name: SORTING_TYPES.asc,
    }
  );

  const loadRecord = () => {
    setIsLoading(true);
    AffiliationsService.GetAllAffiliation()
      .then((response) => {
        setAffiliations(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load affiliations</span>);
      });
  };

  useEffect(() => {
    loadRecord();
  }, []);

  const showEditRecord = (type) => {
    setTypeId(type.id);
    setTypeName(type.name);
    setShowInputModal(true);
    setEditRecord(true);
  };

  const promptDeleteRecord = async (type) => {
    setCurrentlyDeletedType(type.id);
    setIsLoading(true);
    try {
      const response = await AffiliationsService.GetAffiliationById(type.id);
      setIsLoading(false);
      if (response) {
        setIsDelete(false);
      } else {
        setIsDelete(true);
      }
      setShowPromptDeleteModal(true);
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(
        <span>Failed to load affiliation details.</span>
      );
    }
  };

  const columns = [
    {
      header: "Affiliations",
      dataKey: "name",
      sortable: true,
      renderer: (q) => <div>{q.name}</div>,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (q) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={(e) => {
                e.preventDefault();
                showEditRecord(q);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                promptDeleteRecord(q);
              }}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          </>
        );
      },
    },
  ];

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  const openModal = () => {
    setShowInputModal(true);
    setEditRecord(false);
    setIsValidated(false);
  };

  const cancelDialog = () => {
    setShowInputModal(false);
    setTypeName("");
    setEditRecord(false);
  };
  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  const deleteCurrentlySelectedType = () => {
    AffiliationsService.deleteDataRecords(deletedId).then(() => {
      toastContext.addSuccessToast(
        <span>Affiliation was successfully deleted.</span>
      );
      loadRecord();
      handleClosePromptDeleteModal();
    });
  };

  const resetFields = () => {
    setShowInputModal(false);
    setTypeName("");
  };

  const isInputValid = () => {
    return typeName !== "";
  };

  const saveData = async (event) => {
    setIsValidated(true);

    if (isInputValid()) {
      event.preventDefault();
      setIsLoading(true);

      try {
        if (editRecord) {
          await AffiliationsService.updateRecord(typeId, typeName);
          toastContext.addSuccessToast(
            <span>Affiliation Type was successfully edited.</span>
          );
          loadRecord();
          resetFields();
        } else {
          const response = await AffiliationsService.createRecord(typeName);
          if (response) {
            toastContext.addSuccessToast(
              <span>Affiliation was successfully added.</span>
            );
            loadRecord();
            resetFields();
          } else {
            toastContext.addFailToast(
              <span>
                Affiliation already exists with this name. Please enter a new
                name.
              </span>
            );
          }
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        editRecord
          ? toastContext.addFailToast(
              <span>Failed to update Affiliation Type.</span>
            )
          : toastContext.addFailToast(
              <span>Failed to add Affiliation Type.</span>
            );
      }
    }
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <Container fluid>
        <PageHeader>
          <PageHeader.BackButton
            text="Return To Reference Data"
            onClick={goToReferenceData}
          />
          <PageHeader.Title>
            <h1>Affiliations</h1>
            <Spacer />
            <GVDSButton
              variant={buttonVariant.primary}
              className="add-data"
              onClick={openModal}
              text="Create Affiliation"
              icon={<GVDSIcon Icon={IconCirclePlus} />}
            />
          </PageHeader.Title>
        </PageHeader>

        <GVDSTableCtrlContainer>
          <FilterSearchBox
            placeholder="Search by Affiliations"
            value={searchText}
            onInput={setSearchText}
          />
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        </GVDSTableCtrlContainer>
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
        />
        {filteredSortedData.length === 0 && (
          <div className="table__no_content">No record found</div>
        )}
        <GVDSModal
          show={show}
          onHide={() => cancelDialog()}
          title={editRecord ? "Edit Affiliation" : "Create Affiliation"}
          size={GVDSModal.Size.small}
        >
          <GVDSModal.Body>
            <GVFormGroup>
              <Form.Label>Affiliation</Form.Label>
              <GVDSFormField
                className="company_name_input"
                placeholder="Enter Affiliation"
                value={typeName}
                onInput={(value) => setTypeName(value)}
                statusMetadata={
                  isValidated && typeName === ""
                    ? FormFieldStatusMetadata.getError(
                        "Please provide Affiliation."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </GVDSModal.Body>

          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={() => cancelDialog()}
              text="Cancel"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              text="Save"
              onClick={saveData}
            />
          </GVDSModal.Footer>
        </GVDSModal>
      </Container>
      <DeleteRecordDialog
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        isDelete={isDelete}
        proceed={deleteCurrentlySelectedType}
        title="Affiliation"
      />
    </div>
  );
};

export default withAuthentication(Affiliations);
