import React from "react";
import { Link } from "react-router-dom";
import GVDSIcon from "../Icons/GVDSIcon";
import { IconExternalLink } from "@tabler/icons-react";

export const linkVariant = {
  inline: "gvds-link__inline",
  media_title: "gvds-link__media-title",
};

const trailingIcon = <GVDSIcon Icon={IconExternalLink} />;

const GVDSLink = React.forwardRef(
  (
    {
      variant = linkVariant.inline,
      icon,
      to = null,
      className,
      isExternal = false,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <Link
        ref={ref}
        className={`gvds-link ${variant} ${className ?? ""}`}
        to={to}
        {...props}
      >
        {children}
        {isExternal && variant === linkVariant.inline && (
          <span className="gvds-link__icon">{trailingIcon}</span>
        )}
      </Link>
    );
  }
);

GVDSLink.variant = linkVariant;

export default GVDSLink;
