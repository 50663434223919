import React, { useContext, useState } from "react";
import InputPeopleRecordModal from "./InputPeopleRecord/InputPeopleRecordModal";
import { PEOPLE_DATA_METER } from "../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import { PERMISSIONS } from "../../../config/constants";
import PermissionsContext from "../../../context/PermissionsContext";
import PeopleCategoryDisplay from "./PeopleCategoryDisplay";
import LastActivityDisplay from "../../common/LastActivityDisplay";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconExternalLink } from "@tabler/icons-react";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import { ACTIONS_TABLE_HEADER } from "../../../gvds-components/Table/GVDSTable";

const PeopleDashboard = ({ peopleMeters, onUpdate }) => {
  const history = useHistory();
  const permissionCtx = useContext(PermissionsContext);

  const [showInputModal, setShowInputModal] = useState(false);
  const [inputMeter, setInputMeter] = useState(null);

  const onModalClose = () => {
    setShowInputModal(false);
  };

  const showMeterDetails = (meter) => {
    history.push({
      pathname: PEOPLE_DATA_METER,
      state: {
        meter,
      },
    });
  };

  return (
    <div>
      <GVDSTableDisplay className="people-setup-table">
        <thead>
          <tr>
            <th>Subtopic</th>
            <th>Groupings</th>
            <th>Last Updated By</th>
            <th className="gvds-table-cell__actions-header">
              {ACTIONS_TABLE_HEADER}
            </th>
          </tr>
        </thead>
        <tbody>
          {peopleMeters.length > 0 && (
            <>
              {peopleMeters.map((meter) => {
                return (
                  <tr
                    key={meter.id}
                    className="is-clickable"
                    onClick={() => showMeterDetails(meter)}
                  >
                    <td>{meter.subtopic.name}</td>
                    <td>
                      <ul>
                        {meter.groupings.map((grouping) => {
                          return (
                            <li key={grouping.id}>
                              {grouping.name}{" "}
                              <div className="d-inline table-data__subtext">
                                (
                                <PeopleCategoryDisplay
                                  categories={grouping.categories}
                                />
                                )
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </td>
                    <td>
                      {meter.last_activity && (
                        <LastActivityDisplay
                          lastActivity={meter.last_activity}
                        />
                      )}
                    </td>
                    <td>
                      <div className="gvds-table-cell__actions-container">
                        <GVDSIconButton
                          variant={iconButtonVariant.tertiary}
                          icon={<GVDSIcon Icon={IconExternalLink} />}
                          tooltipText="View Details"
                          onClick={(e) => {
                            e.stopPropagation();
                            showMeterDetails(meter);
                          }}
                        />
                        {!permissionCtx.isLoadingPermissions &&
                          permissionCtx.permissions[
                            PERMISSIONS.PEOPLE_RECORD_CREATE
                          ] && (
                            <GVDSIconButton
                              variant={iconButtonVariant.tertiary}
                              icon={<GVDSIcon Icon={IconCirclePlus} />}
                              onClick={(e) => {
                                e.stopPropagation();
                                setInputMeter(meter);
                                setShowInputModal(true);
                              }}
                              tooltipText="Input"
                            />
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </GVDSTableDisplay>
      {peopleMeters.length === 0 && (
        <div className="table__no_content">
          No tracked types for people data yet. Set up your types to start
          tracking your people data.
        </div>
      )}
      <InputPeopleRecordModal
        showModal={showInputModal}
        onClose={onModalClose}
        onDoneInput={onUpdate}
        meterDetails={inputMeter}
      />
    </div>
  );
};

export default PeopleDashboard;
