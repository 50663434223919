import flatMap from "lodash/flatMap";
import { DateTimeUtils } from "../../../services/UtilsService";

export default class BulkOperationalDataInputErrorService {
  constructor() {
    this.dataErrorRows = null;
    this.possibleErrorRows = null;
  }

  resetErrors() {
    this.dataErrorRows = null;
    this.possibleErrorRows = null;
  }

  updateErrors(errors) {
    this.dataErrorRows = errors.dataErrors.errorRowNumbers;
    this.possibleErrorRows = errors.possibleErrors.errorRowNumbers;
  }

  hasDataErrors() {
    return this.dataErrorRows && this.dataErrorRows.length > 0;
  }

  hasNoDataErrors() {
    return this.dataErrorRows && this.dataErrorRows.length === 0;
  }

  hasPossibleErrors() {
    return this.possibleErrorRows && this.possibleErrorRows.length > 0;
  }

  hasNoPossibleErrors() {
    return this.possibleErrorRows && this.possibleErrorRows.length === 0;
  }

  hasNoErrors() {
    return this.hasNoDataErrors() && this.hasNoPossibleErrors();
  }

  disableSubmit() {
    return !this.dataErrorRows || this.dataErrorRows.length > 0;
  }

  prepareDataErrors(dataErrors) {
    let errors = {};
    for (const [row, err] of Object.entries(dataErrors)) {
      errors[row] = err;
      errors[row]["index"] = flatMap(Object.values(errors[row]));
    }
    return errors;
  }

  preparePossibleErrors(possibleErrors) {
    let errors = {};
    for (const [row, err] of Object.entries(possibleErrors)) {
      errors[row] = {};

      if (err["period_from"]) {
        errors[row]["period_from"] = [
          [
            err["period_from"]["gap_message"],
            "between",
            DateTimeUtils.formatUTCDate(err["period_from"]["gap_start"]),
            "to",
            DateTimeUtils.formatUTCDate(err["period_from"]["gap_end"]),
          ].join(" ") + ".",
        ];
      }
      if (err["period_to"]) {
        errors[row]["period_to"] = [
          [
            err["period_to"]["gap_message"],
            "between",
            DateTimeUtils.formatUTCDate(err["period_to"]["gap_start"]),
            "to",
            DateTimeUtils.formatUTCDate(err["period_to"]["gap_end"]),
          ].join(" ") + ".",
        ];
      }

      if (err["value"]) {
        errors[row]["value"] = [
          `Value in the record for ${DateTimeUtils.formatUTCDate(
            err["value"]["start"]
          )} - ${DateTimeUtils.formatUTCDate(
            err["value"]["end"]
          )} is unusually ${
            err["value"]["compared_to_previous"]
          } compared to the previous value.`,
        ];
      }

      errors[row]["index"] = flatMap(Object.values(errors[row]));
    }
    return errors;
  }
}
