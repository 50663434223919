import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ToastContext from "../../context/ToastContext";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { isEmpty } from "lodash";
import GVDSInfoCard from "../../gvds-components/common/GVDSInfoCard";

const PasteHandlerContext = React.createContext(null);

const PasteHandlerProvider = ({ children }) => {
  const [lastPaste, setLastPaste] = useState(null);
  const [rowIndicesToNewValues, setRowIndicesToNewValues] = useState();

  const store = useCallback(
    {
      handlePaste: (newRowIndicesToNewValues) => {
        setRowIndicesToNewValues(newRowIndicesToNewValues);
        setLastPaste(new Date());
      },
      lastPaste: lastPaste,
      rowIndicesToNewValues: rowIndicesToNewValues,
    },
    [lastPaste, rowIndicesToNewValues]
  );

  return (
    <PasteHandlerContext.Provider value={store}>
      {children}
    </PasteHandlerContext.Provider>
  );
};

export const withPasteHandler = (Component) => (props) => (
  <PasteHandlerProvider>
    <Component {...props} />
  </PasteHandlerProvider>
);

const findMatchingSite = (targetValues, siteId) => {
  if (targetValues) {
    return targetValues.find((entry) => {
      return entry.id === siteId;
    });
  } else {
    return null;
  }
};

const updateMatchingTargetValue = (
  inputRecord,
  siteRow,
  newValue,
  onTargetValuesChange
) => {
  const matchingSite = findMatchingSite(inputRecord, siteRow.id);

  if (matchingSite) {
    const value = parseFloat(newValue);
    matchingSite.targetValue = isNaN(value) ? "" : value < 0 ? "" : value;
    onTargetValuesChange(matchingSite);
  }
};

const isRowInvalid = (checkedRow, invalidTargetValues) => {
  return invalidTargetValues.some((row) => checkedRow.id === row.id);
};

const InputTargetValueRecordTable = withPasteHandler(
  ({
    unitId,
    targetPeriod,
    inputRecord,
    onTargetValuesChange,
    disabled = false,
    invalidTargetValues,
  }) => {
    const toastContext = useContext(ToastContext);
    const pasteHandlerContext = useContext(PasteHandlerContext);

    const handlePaste = useCallback(
      (pasteText, rowIndex) => {
        const values = pasteText.split(/\r?\n/).map((v) => v.trim());
        const rowIndicesToNewValues = {};
        values.map((value, index) => {
          if (rowIndex + index < inputRecord.length) {
            updateMatchingTargetValue(
              inputRecord,
              inputRecord[rowIndex + index],
              value,
              onTargetValuesChange
            );
            rowIndicesToNewValues[rowIndex + index] = value;
          }
        });
        pasteHandlerContext.handlePaste(rowIndicesToNewValues);
        toastContext.addSuccessToast(
          <span>Target values have been pasted.</span>
        );
      },
      [inputRecord]
    );

    return useMemo(() => {
      if (isEmpty(inputRecord)) {
        return null;
      } else {
        return (
          <>
            <GVDSInfoCard title="For spreadsheet:" className="mt-2">
              <ol type="1">
                <li>
                  Click "Copy rows into excel" to copy the site names and paste
                  them into your spreadsheet.
                </li>
                <li>
                  Fill the Target Values in your spreadsheet and copy the values
                  once done.
                </li>
                <li>
                  Click on the first cell below to paste the values from your
                  spreadsheet.
                </li>
              </ol>
            </GVDSInfoCard>
            <Table className="create-target-form__target-value-input-table">
              <thead>
                <tr>
                  <th style={{ width: "60px" }}>Row #</th>
                  <th>Site Name</th>
                  <th style={{ width: "280px" }}>
                    Target Value in {unitId} ({targetPeriod})
                  </th>
                </tr>
              </thead>
              <tbody>
                {inputRecord.map((row, rowIndex) => {
                  return (
                    <tr key={`row-${rowIndex}`}>
                      <td
                        key={`col-rownum-${rowIndex}`}
                        style={{ textAlign: "right" }}
                      >
                        {rowIndex + 1}
                      </td>
                      <td key={`col-site-name-${rowIndex}`}>{row.name}</td>
                      <td>
                        <InputField
                          initialValue={row.targetValue}
                          handlePaste={handlePaste}
                          updateTargetValue={(newValue) =>
                            updateMatchingTargetValue(
                              inputRecord,
                              row,
                              newValue,
                              onTargetValuesChange
                            )
                          }
                          disabled={disabled}
                          rowIndex={rowIndex}
                          isInvalid={isRowInvalid(row, invalidTargetValues)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        );
      }
    }, [inputRecord, invalidTargetValues]);
  }
);

const InputField = ({
  initialValue,
  handlePaste,
  updateTargetValue,
  disabled,
  rowIndex,
  isInvalid,
}) => {
  const pasteHandlerContext = useContext(PasteHandlerContext);

  const [localValue, setLocalValue] = useState(initialValue);

  useEffect(() => {
    const value = parseFloat(initialValue);
    setLocalValue(isNaN(value) ? "" : value < 0 ? "" : value);
  }, [initialValue]);

  useEffect(() => {
    updateTargetValue(localValue);
  }, [localValue]);

  useEffect(() => {
    if (
      pasteHandlerContext.lastPaste &&
      pasteHandlerContext.rowIndicesToNewValues[rowIndex]
    ) {
      setLocalValue(pasteHandlerContext.rowIndicesToNewValues[rowIndex]);
    }
  }, [pasteHandlerContext.lastPaste]);

  return useMemo(
    () => (
      <>
        <Form.Control
          size="sm"
          className="target-value-input"
          value={localValue}
          onPaste={(e) => {
            e.preventDefault(); // prevent onChange
            handlePaste(e.clipboardData.getData("Text"), rowIndex);
          }}
          onChange={(e) => setLocalValue(e.target.value)}
          disabled={disabled}
          isInvalid={isInvalid}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a value.
        </Form.Control.Feedback>
      </>
    ),
    [localValue, isInvalid]
  );
};

export default InputTargetValueRecordTable;
