import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import { UtilsService } from "../../../services/UtilsService";
import React, { useContext } from "react";
import CertificationContext from "../CertificationContext";
import { MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY } from "./CertificationHomepage";
import GVDSFilterButton from "../../../gvds-components/common/GVDSFilterButton";

const CertificationCategoryFilters = ({
  selectedCategoryNames,
  onSelectedCategoryNamesChange,
}) => {
  const certificationContext = useContext(CertificationContext);
  const allCategoryNames =
    certificationContext.certification.getAllCategoryNames();

  return (
    <div className="certification-homepage__category-filters__container">
      <div className="category-title">Category:</div>
      {allCategoryNames.map((categoryName, index) => (
        <div className="my-1" key={index}>
          <GVDSFilterButton
            className="me-2"
            isSelected={selectedCategoryNames.includes(categoryName)}
            filterName={categoryName}
            maxAllowedChars={
              MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY
            }
            onClick={(categoryName) =>
              onSelectedCategoryNamesChange(
                UtilsService.toggleItem(selectedCategoryNames, categoryName)
              )
            }
          />
        </div>
      ))}
      <GVDSTextButton
        text="Clear all"
        className="ms-2 me-3"
        disabled={selectedCategoryNames.length === 0}
        onClick={() => onSelectedCategoryNamesChange([])}
      />
    </div>
  );
};

export default CertificationCategoryFilters;
