import React, { useContext, useEffect, useState } from "react";
import sortBy from "lodash/sortBy";

import UserInventoryContext, {
  getFacilities,
} from "../../../context/UserInventoryContext";
import OperationalService from "../../../services/OperationalService";
import LoadingSpinner from "../../common/LoadingSpinner";
import OperationMeterDialog from "./OperationalMeterDialog";
import { RESOURCES } from "../../../config/constants";
import ToastContext from "../../../context/ToastContext";
import { type_hotel } from "../../Site/SiteFacilityInformationComponents";
import { OPERATIONAL_DATA } from "../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import OperationalTypeMetersDisplay from "./MeterSetup/OperationalTypeMetersDisplay";
import { DateTimeUtils } from "../../../services/UtilsService";
import InputAlertPreferencesModal from "../InputAlertPreferencesModal";
import { useTranslation } from "react-i18next";

const initialMeterDetails = {
  meterName: "",
  frequency: "",
  description: "",
  facilityID: "",
};

const MAX_LENGTH_METER_DESC = 50;

export const getOperationMeterCount = (datasets, operationType) => {
  const data = datasets.map(
    (dataset) =>
      dataset.meters.filter(
        (opType) => opType.operation_type.id === operationType.id
      ).length
  );
  return data.reduce((a, b) => a + b, 0);
};

const OperationalMeterSetup = () => {
  const { t } = useTranslation();

  let history = useHistory();
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const currentInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [operationTypes, setOperationTypes] = useState([]);
  const [operationDatasets, setOperationDatasets] = useState([]);

  const [meterDetails, setMeterDetails] = useState(initialMeterDetails);

  const [meterDialog, setShowMeterDialog] = useState(false);
  const [isEditExistingMeter, setIsEditExistingMeter] = useState(false);

  const [facilities, setFacilities] = useState([]);
  const [disableFacilitySelect, setDisableFacilitySelect] = useState(false);
  const [operationType, setOperationalType] = useState("");
  const [checkMeterExistDialog, setCheckMeterExistDialog] = useState(false);
  const [deleteMeterDialog, setDeleteMeterDialog] = useState(false);
  const [disableOperational, setDisableOperational] = useState();
  const [showAlertPreferencesModal, setShowAlertPreferencesModal] =
    useState(false);

  const resetForm = () => {
    setIsValidated(false);
  };

  const closeMeterDialog = () => {
    setShowMeterDialog(false);
    resetForm();
  };

  const loadOperationalDatasets = () => {
    setIsLoading(true);
    if (currentInventory) {
      const facilities = getFacilities(userInventory.selectedTreeNode.get);
      setFacilities(facilities);
      Promise.all([
        OperationalService.getOperationMetricType(),
        OperationalService.getAllDatasets(
          currentInventory.id,
          currentInventory.type
        ),
      ])
        .then((response) => {
          setOperationTypes(response[0]);
          setOperationDatasets(response[1]);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load operational data.</span>
          );
        });
    }
  };

  useEffect(() => {
    loadOperationalDatasets();
  }, [userInventory.selectedInventory.get]);

  useEffect(() => {
    setDisableOperational(true);
    const inventory = userInventory.selectedInventory.get;
    if (inventory && inventory.id) {
      const resource = userInventory.selectedTreeNode.get.nodeValue.value;
      if (inventory.type === RESOURCES.SITE) {
        const facilities = userInventory.selectedTreeNode.get.children;

        const facilityWithHotelType = facilities.map(
          (facility) => facility.nodeValue.value.type
        );
        const hotelTypeFacilityCount = facilityWithHotelType.filter(
          (facility_type) => facility_type.name.toLowerCase() === type_hotel
        ).length;

        if (hotelTypeFacilityCount > 0) {
          setDisableOperational(false);
        } else {
          setDisableOperational(true);
        }
      } else if (inventory.type === RESOURCES.FACILITY) {
        setDisableOperational(
          inventory.type === RESOURCES.FACILITY &&
            resource.type.name.toLowerCase() !== type_hotel
        );
      }
    }
  }, [userInventory.selectedInventory.get]);

  const onOperationMeterToggle = (isTrackingMeter, operationType) => {
    if (isTrackingMeter) {
      toastContext.addFailToast(
        <span>To stop tracking a type, delete each meter under it.</span>
      );
    } else {
      addMeterDialog(operationType);
    }
  };

  const addMeterDialog = (operationType) => {
    if (userInventory.selectedInventory.get.type === RESOURCES.SITE) {
      addMeterDialogForSite(operationType);
    } else if (
      userInventory.selectedInventory.get.type === RESOURCES.FACILITY
    ) {
      let facilityID = "";
      if (facilities.length === 1) {
        facilityID = facilities[0].id;
        setDisableFacilitySelect(true);
      } else {
        setDisableFacilitySelect(false);
      }
      setShowMeterDialog(true);
      setIsEditExistingMeter(false);
      setOperationalType(operationType.id);
      setMeterDetails({
        facilityID: facilityID,
        meterName: "",
        frequency: "",
        description: "",
      });
    }
  };

  const handleTextChange = (value, eventName) => {
    setMeterDetails({ ...meterDetails, [eventName]: value });
  };

  const handleDropdownChange = (value, eventName) => {
    setMeterDetails({ ...meterDetails, [eventName]: value });
  };

  const submitMeterHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsValidated(true);

    if (
      meterDetails.frequency &&
      meterDetails.facilityID &&
      (!meterDetails.description ||
        meterDetails.description.length <= MAX_LENGTH_METER_DESC)
    ) {
      if (currentInventory) {
        try {
          if (isEditExistingMeter) {
            setIsDialogLoading(true);
            await OperationalService.editMeterDetails(
              currentInventory.type,
              currentInventory.id,
              meterDetails
            )
              .then(() => {
                toastContext.addSuccessToast(
                  <span>Meter updated successfully</span>
                );
                setIsDialogLoading(false);
                loadOperationalDatasets();
                closeMeterDialog();
              })
              .catch(() => {
                setIsDialogLoading(false);
                toastContext.addFailToast(<span>Failed to update meter</span>);
              });
          } else {
            setIsDialogLoading(true);
            await OperationalService.createOperationalMeter(
              currentInventory.type,
              currentInventory.id,
              meterDetails.facilityID,
              operationType,
              meterDetails.frequency,
              meterDetails.description
            )
              .then(() => {
                toastContext.addSuccessToast(
                  <span>Meter created successfully</span>
                );
                setIsDialogLoading(false);
                loadOperationalDatasets();
                closeMeterDialog();
              })
              .catch(() => {
                setIsDialogLoading(false);
                toastContext.addFailToast(<span>Failed to add meter</span>);
              });
          }
        } catch (error) {}
      }
    }
  };

  const deleteMeterHandler = (data) => {
    OperationalService.getMeterStatus(
      currentInventory.type,
      currentInventory.id,
      data.meterID
    )
      .then((data) => {
        if (data.has_records) {
          setCheckMeterExistDialog(true);
        } else {
          setDeleteMeterDialog(true);
        }
        setShowMeterDialog(false);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to check meter data</span>);
      });
  };

  const deleteMeter = () => {
    OperationalService.deleteMeter(
      currentInventory.type,
      currentInventory.id,
      meterDetails.meterID
    )
      .then(() => {
        toastContext.addSuccessToast(<span>Meter removed successfully</span>);
        setDeleteMeterDialog(false);
        loadOperationalDatasets();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to remove meter</span>);
      });
  };

  const addMeterDialogForSite = (operationType) => {
    const facilities = getFacilities(userInventory.selectedTreeNode.get);

    const facilityWithHotelType = facilities.filter((facility) => {
      return type_hotel === facility.value.type.name.toLowerCase();
    });

    const datasetsWithOpsType = operationDatasets.filter((dataset) => {
      return dataset.meters.find(
        (meter) => meter.operation_type.id === operationType.id
      );
    });
    const facilityIdsWithOpsType = datasetsWithOpsType.map(
      (dataset) => dataset.facility.id
    );
    const facilitiesWithoutSuchOpsType = facilityWithHotelType.filter(
      (facility) => facilityIdsWithOpsType.indexOf(facility.id) < 0
    );

    setFacilities(facilitiesWithoutSuchOpsType);
    let facilityID = "";
    if (facilities.length === 1) {
      facilityID = facilities[0].id;
      setDisableFacilitySelect(true);
    } else {
      setDisableFacilitySelect(false);
    }
    setShowMeterDialog(true);
    setIsEditExistingMeter(false);
    setOperationalType(operationType.id);
    setMeterDetails({
      facilityID: facilityID,
      meterName: "",
      frequency: "monthly",
      description: "",
    });
  };

  const editMeterDialog = (meter, facilityId) => {
    const facilities = getFacilities(userInventory.selectedTreeNode.get);
    setFacilities(facilities);
    setMeterDetails({
      meterID: meter.id,
      frequency: meter.frequency,
      description: meter.description,
      facilityID: facilityId,
    });
    setDisableFacilitySelect(true);
    setShowMeterDialog(true);
    setIsEditExistingMeter(true);
    setDisableFacilitySelect(true);
  };

  const displayAlertPreferencesDialog = (meter, facilityId) => {
    setMeterDetails({
      meterId: meter.id,
      dismissAlertsDate: meter.dismiss_alerts_date
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            meter.dismiss_alerts_date
          )
        : null,
      enableDataGapsAlert: meter.enable_data_gaps_alert,
      enableUnusualHighLowAlert: meter.enable_unusually_high_low_alert,
      effectivePeriodStartDate: meter.start_date
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(meter.start_date)
        : null,
      effectivePeriodEndDate: meter.end_date
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(meter.end_date)
        : null,
      facilityId: facilityId,
    });
    setShowAlertPreferencesModal(true);
  };

  const onAlertPreferencesUpdate = () => {
    toastContext.addSuccessToast(
      <span>Alert preferences updated successfully.</span>
    );
    setShowAlertPreferencesModal(false);
    loadOperationalDatasets();
  };

  if (disableOperational) {
    return (
      <div>
        <h1>{t("data-management.operational.page-title")}</h1>
        <div className="table__no_content mt-2">
          <p>{t("data-management.operational.not-available-for-non-hotel")}</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const backToOperational = () => {
    history.push(OPERATIONAL_DATA);
  };

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text="Return to Operational Data Input Page"
          onClick={backToOperational}
        />
        <PageHeader.Title>
          <h1>Operational Meter Setup</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          Meters can only be created at Hotel & Accommodation facilities with a
          limit of one meter per operational type.
        </PageHeader.Description>
      </PageHeader>
      <GVDSTableDisplay className="operation-type-table">
        <thead>
          <tr>
            <th className="operation-type-table__tracking">Tracking</th>
            <th className="operation-type-table__type">Type</th>
            <th className="operation-type-table__definition">Definition</th>
          </tr>
        </thead>
        <tbody>
          {sortBy(operationTypes, [
            (operationType) => {
              return !getOperationMeterCount(operationDatasets, operationType);
            },
            "name",
          ]).map((operationType) => {
            return (
              <OperationalTypeMetersDisplay
                key={operationType.id}
                operationType={operationType}
                operationDatasets={operationDatasets}
                onOperationMeterToggle={onOperationMeterToggle}
                addMeterDialogForSite={addMeterDialogForSite}
                editMeterDialog={editMeterDialog}
                displayAlertPreferencesDialog={displayAlertPreferencesDialog}
              />
            );
          })}
        </tbody>
      </GVDSTableDisplay>

      <OperationMeterDialog
        show={meterDialog}
        cancel={closeMeterDialog}
        facilities={facilities}
        meterDetails={meterDetails}
        disableFacilitySelect={disableFacilitySelect}
        onDropdownChange={handleDropdownChange}
        onTextChange={handleTextChange}
        isValidated={isValidated}
        saveMeter={submitMeterHandler}
        deleteMeterHandler={deleteMeterHandler}
        isEditExistingMeter={isEditExistingMeter}
        isLoading={isDialogLoading}
      />

      <InputAlertPreferencesModal
        show={showAlertPreferencesModal}
        setShow={setShowAlertPreferencesModal}
        facilityId={meterDetails?.facilityId}
        meterId={meterDetails?.meterId}
        oldDismissAlertDate={meterDetails?.dismissAlertsDate}
        oldEnableDataGapsAlert={meterDetails?.enableDataGapsAlert}
        oldEnableAbnormalUsageCost={meterDetails?.enableUnusualHighLowAlert}
        oldEffectivePeriodStartDate={meterDetails?.effectivePeriodStartDate}
        oldEffectivePeriodEndDate={meterDetails?.effectivePeriodEndDate}
        updateAlertPreferences={OperationalService.updateAlertPreferences}
        onAlertPreferencesUpdate={onAlertPreferencesUpdate}
      />

      <GVDSModal
        title="Unable to Delete Meter"
        size={GVDSModal.Size.small}
        show={checkMeterExistDialog}
        onHide={() => setCheckMeterExistDialog(false)}
      >
        <GVDSModal.Body>
          <div>
            This meter cannot be deleted because it currently has associated
            data records.
          </div>
          <br />
          <div>
            In order to delete this meter, you must first delete all of its
            existing records on the “Meter” page or in the “History” page.
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => setCheckMeterExistDialog(false)}
            text="Okay"
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <GVDSModal
        title="Delete Meter"
        size={GVDSModal.Size.small}
        show={deleteMeterDialog}
        onHide={() => setDeleteMeterDialog(false)}
      >
        <GVDSModal.Body>
          <div>
            This meter does not have existing data records. You are about to
            permanently delete this meter, are you sure?
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => setDeleteMeterDialog(false)}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={() => deleteMeter()}
            text="Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </div>
  );
};

export default OperationalMeterSetup;
