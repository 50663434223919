import React, { useContext, useEffect, useState } from "react";
import UserInventoryContext from "../../context/UserInventoryContext";
import PermissionsContext from "../../context/PermissionsContext";
import FileStorageStateContext, {
  FILE_STORAGE_PAGES,
} from "../../context/FileStorageStateContext";
import LoadingSpinner from "../common/LoadingSpinner";
import {
  FILE_ASSOCIATED_RESOURCE_PATH_NAME,
  PERMISSIONS,
  RESOURCES,
} from "../../config/constants";
import Dropdown from "react-bootstrap/Dropdown";
import { matchPath, useLocation } from "react-router-dom";
import FileStorageService, {
  FILE_MODULE_BY_PATH,
} from "../../services/FileStorageService";
import { UtilsService } from "../../services/UtilsService";
import intersection from "lodash/intersection";
import uniq from "lodash/uniq";
import Form from "react-bootstrap/Form";
import FileStorageItemDisplay from "./FileStorageItemDisplay";
import { FILE_STORAGE_MAIN_SELECT_MODE } from "./FileStorageMain";
import ToastContext from "../../context/ToastContext";
import flatMap from "lodash/flatMap";
import fuzzysort from "fuzzysort";
import {
  fileStorageSearchKeys,
  fuzzysortOptionsWithKeys,
} from "../../config/search-config";
import { GenericUserManagedTagModel } from "../common/CentralTags/UserManagedTagModel";
import { AboutTagsInFileStorageModal } from "../common/CentralTags/AboutTags";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormStartEndMonthPicker from "../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import FilterSearchBox from "../../gvds-components/common/FilterSearchBox";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSPagination from "../../gvds-components/Table/Controls/GVDSPagination";
import Spacer from "../../gvds-components/Layout/Spacer";
import GVDSDropdownToggle from "../../gvds-components/Buttons/GVDSDropdownToggle";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import {
  IconDownload,
  IconEdit,
  IconTrash,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSIconCustom from "../../gvds-components/Icons/GVDSIconCustom";
import { Trans, useTranslation } from "react-i18next";

const FileStorageBulkActionBar = ({
  selectMode,
  selectedCount,
  actionButtons,
}) => {
  const { t } = useTranslation();

  let selectedRowsForActionMessage;
  let icon;
  let note;

  if (selectMode === FILE_STORAGE_MAIN_SELECT_MODE.DOWNLOAD) {
    selectedRowsForActionMessage = (
      <Trans i18nKey={"file-storage.bulk-action-selected-rows-download"}>
        <strong>{{ selectedCount }}</strong> files selected to download
      </Trans>
    );
    icon = IconDownload;
    note = <>{t("file-storage.bulk-download-message")}</>;
  } else if (selectMode === FILE_STORAGE_MAIN_SELECT_MODE.DELETE) {
    selectedRowsForActionMessage = (
      <Trans i18nKey={"file-storage.bulk-action-selected-rows-delete"}>
        <strong>{{ selectedCount }}</strong> files selected to delete
      </Trans>
    );
    icon = IconTrash;
  } else if (selectMode === FILE_STORAGE_MAIN_SELECT_MODE.EDIT) {
    selectedRowsForActionMessage = (
      <Trans i18nKey={"file-storage.bulk-action-selected-rows-edit"}>
        <strong>{{ selectedCount }}</strong> files selected to edit
      </Trans>
    );
    icon = IconEdit;
  }

  return (
    <div className="file-storage-bulk-action-bar__container">
      <div className="file-storage-bulk-action-bar__content">
        <GVDSIcon className="me-1" Icon={icon} />
        <div className="selected-rows-count">
          {selectedRowsForActionMessage}
        </div>
        {actionButtons}
      </div>
      {note && <div className="file-storage-bulk-action-bar__note">{note}</div>}
    </div>
  );
};

const FileStorageAllFiles = ({
  allFileStorageObjects,
  isLoading,
  loadFileStorageObjects,
  selectedFiles,
  setSelectedFiles,
  selectMode,
  onModeChange,
  onEdit,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const permissionCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);
  const fileStorageStateContext = useContext(FileStorageStateContext);
  const location = useLocation();

  const [isDeleting, setIsDeleting] = useState(false);

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showTagsInfo, setShowTagsInfo] = useState(false);

  const selectedInventory = userInventory.selectedInventory.get;

  const onDeletePromptClose = () => {
    if (!isDeleting) {
      setShowDeletePrompt(false);
    }
  };

  const closeTagsInfo = () => {
    setShowTagsInfo(false);
  };

  const goToFileStorageUpload = () => {
    fileStorageStateContext.changePage(FILE_STORAGE_PAGES.UPLOAD);
  };

  const onDownload = () => {
    toastContext.addSuccessToast(
      <span>
        <Trans i18nKey={"file-storage.bulk-download-initiated-message"}>
          Initiated download for {selectedFiles.length} files.
        </Trans>
      </span>
    );
    fileStorageStateContext
      .downloadFiles(
        selectedInventory.type,
        selectedInventory.id,
        selectedFiles.map((f) => f.link)
      )
      .then(() =>
        toastContext.addSuccessToast(
          <span>{t("file-storage.bulk-download-done-message")}</span>
        )
      )
      .catch(() =>
        toastContext.addFailToast(
          <span>
            <span>{t("file-storage.bulk-download-error-message")}</span>
          </span>
        )
      );
    onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.NONE);
  };

  const onDelete = async () => {
    setIsDeleting(true);
    fileStorageStateContext.setIsLoading(true);
    const selectedFileIds = selectedFiles.map((f) => f.id);
    const selectedFileWithAssociatedResourceIds = selectedFiles
      .filter((f) => f.hasAssociatedResources)
      .map((f) => f.id);
    const associatedResourceObjectTypes = new Set();

    for (const fileId of selectedFileWithAssociatedResourceIds) {
      associatedResourceObjectTypes.add(
        await fileStorageStateContext.getAssociatedResources(
          selectedInventory.type,
          selectedInventory.id,
          fileId
        )
      );
    }

    FileStorageService.deleteFileStorageObjects(
      selectedInventory.type,
      selectedInventory.id,
      selectedFileIds
    )
      .then(() => {
        setIsDeleting(false);
        fileStorageStateContext.setIsLoading(false);
        setShowDeletePrompt(false);
        for (const associatedResourceObjectType of associatedResourceObjectTypes) {
          const associatedResourcePathName =
            FILE_ASSOCIATED_RESOURCE_PATH_NAME[associatedResourceObjectType];
          if (
            matchPath(location.pathname, {
              path: associatedResourcePathName,
              exact: true,
            })
          ) {
            fileStorageStateContext.reloadAssociatedResourceRelatedComponentFlag(
              associatedResourceObjectType
            );
            break;
          }
        }
        toastContext.addSuccessToast(<span>Files deleted successfully</span>);
        loadFileStorageObjects();
        onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.NONE);
      })
      .catch(() => {
        setIsDeleting(false);
        fileStorageStateContext.setIsLoading(false);
        toastContext.addFailToast(<span>Failed to delete files</span>);
      });
  };

  let fileStorageDisplay = null;

  if (isLoading) {
    fileStorageDisplay = <LoadingSpinner />;
  } else if (selectedInventory) {
    if (selectedInventory.type === RESOURCES.FACILITY) {
      fileStorageDisplay = (
        <div className="file-storage-body">
          {t("file-storage.not-available-for-facility")}
        </div>
      );
    } else if (
      selectedInventory.type === RESOURCES.SITE ||
      selectedInventory.type === RESOURCES.PORTFOLIO
    ) {
      let currentPortfolioSites = null;
      if (selectedInventory.type === RESOURCES.PORTFOLIO) {
        const portfolios = userInventory.portfoliosUnderSelectedContract.get;
        currentPortfolioSites = portfolios
          .filter((p) => p.id === selectedInventory.id)[0]
          .sites.map((s) => s.name)
          .sort();
      }
      fileStorageDisplay = (
        <div className="file-storage-body">
          <FileModeBanner
            selectMode={selectMode}
            selectedFiles={selectedFiles}
            onModeChange={onModeChange}
            onEdit={onEdit}
            onDownload={onDownload}
            setShowDeletePrompt={setShowDeletePrompt}
          />
          <FileStorageViewAllFiles
            allFileStorageObjects={allFileStorageObjects}
            loadFileStorageObjects={loadFileStorageObjects}
            selectMode={selectMode}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            onModeChange={onModeChange}
            onEdit={onEdit}
            currentPortfolioSites={currentPortfolioSites}
          />
        </div>
      );
    }
  }

  const hasPermissionToCreate =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.FILE_STORAGE_CREATE];

  const selectedFilesCount = selectedFiles.length;

  return (
    <>
      <GVDSButton
        variant={buttonVariant.tertiary}
        onClick={() => fileStorageStateContext.close()}
        icon={<GVDSIcon Icon={IconX} />}
        text={t("shared-modal.footer.close")}
      />
      <div className="file-storage-header">
        <h1>{t("top-nav.file-storage")}</h1>
        <div className="ms-auto d-flex flex-row">
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => setShowTagsInfo(true)}
            icon={<GVDSIconCustom.Info />}
            text={t("file-storage.button-about-tags")}
            className="me-2"
          />
          {selectedInventory &&
            selectedInventory.type === RESOURCES.SITE &&
            hasPermissionToCreate && (
              <GVDSButton
                variant={buttonVariant.primary}
                onClick={goToFileStorageUpload}
                icon={<GVDSIcon Icon={IconUpload} />}
                text={t("file-storage.button-upload-files")}
              />
            )}
        </div>
      </div>
      {fileStorageDisplay}
      <GVDSModal
        show={showDeletePrompt}
        onHide={onDeletePromptClose}
        size={GVDSModal.Size.small}
        title={
          <Trans i18nKey="file-storage.modal-title-delete-files">
            You are about to delete {{ selectedFilesCount }} files
          </Trans>
        }
      >
        {!isDeleting ? (
          <>
            <GVDSModal.Body>
              <div>{t("file-storage.delete-warning")}</div>
            </GVDSModal.Body>
            <GVDSModal.Footer>
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={onDeletePromptClose}
                text={t("shared-modal.footer.cancel")}
              />
              <GVDSButton
                variant={buttonVariant.destructive_primary}
                onClick={onDelete}
                text={t("shared-modal.footer.yes-delete")}
              />
            </GVDSModal.Footer>
          </>
        ) : (
          <GVDSModal.Body>
            <div className="mt-4">
              <LoadingSpinner />
            </div>
          </GVDSModal.Body>
        )}
      </GVDSModal>
      <AboutTagsInFileStorageModal show={showTagsInfo} close={closeTagsInfo} />
    </>
  );
};

const FileModeBanner = ({
  selectMode,
  onModeChange,
  selectedFiles,
  onEdit,
  onDownload,
  setShowDeletePrompt,
}) => {
  const { t } = useTranslation();

  if (
    ![
      FILE_STORAGE_MAIN_SELECT_MODE.EDIT,
      FILE_STORAGE_MAIN_SELECT_MODE.DOWNLOAD,
      FILE_STORAGE_MAIN_SELECT_MODE.DELETE,
    ].includes(selectMode)
  ) {
    return null;
  }

  let actionButtons;

  if (selectMode === FILE_STORAGE_MAIN_SELECT_MODE.EDIT) {
    actionButtons = (
      <>
        <GVDSButton
          variant={buttonVariant.secondary}
          disabled={selectedFiles.length === 0}
          onClick={() => onEdit()}
          text={t("file-storage.button-edit-selected-files")}
        />
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={() => onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.NONE)}
          text={t("shared-modal.footer.cancel")}
        />
      </>
    );
  } else if (selectMode === FILE_STORAGE_MAIN_SELECT_MODE.DOWNLOAD) {
    actionButtons = (
      <>
        <GVDSButton
          variant={buttonVariant.secondary}
          disabled={selectedFiles.length === 0}
          onClick={() => onDownload()}
          text={t("file-storage.button-download-selected-files")}
        />
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={() => onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.NONE)}
          text={t("shared-modal.footer.cancel")}
        />
      </>
    );
  } else if (selectMode === FILE_STORAGE_MAIN_SELECT_MODE.DELETE) {
    actionButtons = (
      <>
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          disabled={selectedFiles.length === 0}
          onClick={() => setShowDeletePrompt(true)}
          text={t("file-storage.button-delete-selected-files")}
        />
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={() => onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.NONE)}
          text={t("shared-modal.footer.cancel")}
        />
      </>
    );
  }

  return (
    <FileStorageBulkActionBar
      selectMode={selectMode}
      selectedCount={selectedFiles ? selectedFiles.length : 0}
      actionButtons={actionButtons}
    />
  );
};

const FileStorageActionDropdown = ({
  onModeChange,
  totalFileCount,
  showDropdown,
  editAll,
  downloadAll,
  deleteAll,
}) => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);

  return (
    <Dropdown className={showDropdown ? "" : "visibility-hidden"}>
      <Dropdown.Toggle as={GVDSDropdownToggle}>
        {t("file-storage.button-multiple-files-trigger")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {!permissionCtx.isLoadingPermissions &&
          permissionCtx.permissions[PERMISSIONS.FILE_STORAGE_VIEW] && (
            <>
              <Dropdown.Item
                onClick={() =>
                  onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.DOWNLOAD)
                }
              >
                <GVDSIcon Icon={IconDownload} />{" "}
                {t("file-storage.button-download-multiple-files")}
              </Dropdown.Item>
              <Dropdown.Item onClick={downloadAll}>
                <GVDSIcon Icon={IconDownload} />{" "}
                <Trans i18nKey="file-storage.button-download-all-files">
                  Download All ({{ totalFileCount }}) Files
                </Trans>
              </Dropdown.Item>
            </>
          )}

        {!permissionCtx.isLoadingPermissions &&
          permissionCtx.permissions[PERMISSIONS.FILE_STORAGE_EDIT] && (
            <>
              <Dropdown.Item
                onClick={() => onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.EDIT)}
              >
                <GVDSIcon Icon={IconEdit} />{" "}
                {t("file-storage.button-edit-multiple-files")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => editAll()}>
                <GVDSIcon Icon={IconEdit} />{" "}
                <Trans i18nKey="file-storage.button-edit-all-files">
                  Edit All ({{ totalFileCount }}) Files
                </Trans>
              </Dropdown.Item>
            </>
          )}

        {!permissionCtx.isLoadingPermissions &&
          permissionCtx.permissions[PERMISSIONS.FILE_STORAGE_DELETE] && (
            <>
              <Dropdown.Item
                className="color-red"
                onClick={() =>
                  onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.DELETE)
                }
              >
                <GVDSIcon Icon={IconTrash} />{" "}
                {t("file-storage.button-delete-multiple-files")}
              </Dropdown.Item>
              <Dropdown.Item className="color-red" onClick={() => deleteAll()}>
                <GVDSIcon Icon={IconTrash} />{" "}
                <Trans i18nKey="file-storage.button-delete-all-files">
                  Delete All ({{ totalFileCount }}) Files
                </Trans>
              </Dropdown.Item>
            </>
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const FileStorageViewAllFiles = ({
  allFileStorageObjects,
  loadFileStorageObjects,
  selectMode,
  selectedFiles,
  setSelectedFiles,
  onModeChange,
  onEdit,
  currentPortfolioSites = [],
}) => {
  const { t } = useTranslation();

  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [filteredFiles, setFilteredFiles] = useState([]);

  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [filterKeys, setFilterKeys] = useState({ modules: [] });
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [displayedFiles, setDisplayedFiles] = useState([]);
  const [associatedModuleOptions, setAssociatedModuleOptions] = useState([]);
  const [
    associatedModuleOptionsHeaderText,
    setAssociatedModuleOptionsHeaderText,
  ] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);

  const selectedInventory = userInventory.selectedInventory.get;
  const showSelect = selectMode !== FILE_STORAGE_MAIN_SELECT_MODE.NONE;

  useEffect(() => {
    const currentPath = Object.keys(FILE_MODULE_BY_PATH).find((path) =>
      matchPath(location.pathname, {
        path: path,
        exact: false,
      })
    );
    setFilterKeys({
      modules: currentPath ? FILE_MODULE_BY_PATH[currentPath] : [],
    });
    if (currentPath) {
      setAssociatedModuleOptionsHeaderText(
        t("file-storage.modules-prefilter-message")
      );
    } else {
      setAssociatedModuleOptionsHeaderText(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    FileStorageService.getAllFileStorageOptions(
      selectedInventory.type,
      selectedInventory.id
    )
      .then((data) => {
        setAssociatedModuleOptions(data.associated_modules);
        const portfolioTags = data.portfolio_tags.map(
          (t) =>
            new GenericUserManagedTagModel(t.id, t.name, RESOURCES.PORTFOLIO)
        );
        const siteTags = flatMap(Object.values(data.site_tags_by_site_ids)).map(
          (t) => new GenericUserManagedTagModel(t.id, t.name, RESOURCES.SITE)
        );
        const allTagOptions = [...portfolioTags, ...siteTags].sort(
          (tag1, tag2) => {
            return tag1.name
              .toLowerCase()
              .localeCompare(tag2.name.toLowerCase());
          }
        );
        setTagOptions(allTagOptions);
        setIsLoadingOptions(false);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to load file storage options</span>
        );
        setIsLoadingOptions(false);
      });
  }, []);

  useEffect(() => {
    let filtered = allFileStorageObjects;

    if (searchText) {
      filtered = fuzzysort
        .go(
          searchText,
          filtered,
          fuzzysortOptionsWithKeys(fileStorageSearchKeys)
        )
        .map((result) => result.obj);
    }

    if (filterKeys.modules && filterKeys.modules.length > 0) {
      filtered = filtered.filter((f) => f.hasModule(filterKeys.modules));
    }
    if (
      filterKeys.allTagSelectionValues &&
      filterKeys.allTagSelectionValues.length > 0
    ) {
      filtered = filtered.filter((f) =>
        f.hasTag(filterKeys.allTagSelectionValues)
      );
    }
    if (filterKeys.sites && filterKeys.sites.length > 0) {
      filtered = filtered.filter((f) => f.inSite(filterKeys.sites));
    }

    filtered = filtered.filter((f) => f.within(start, end));

    setFilteredFiles(filtered);
  }, [allFileStorageObjects, filterKeys, start, end, searchText]);

  useEffect(() => {
    setDisplayedFiles(filteredFiles.slice(startIndex - 1, endIndex));
  }, [filteredFiles, startIndex, endIndex]);

  const onPaginationChange = (start, end) => {
    setStartIndex(start);
    setEndIndex(end);
  };

  const onFileSelect = (file) => {
    if (showSelect) {
      setSelectedFiles(UtilsService.toggleItem(selectedFiles, file));
    }
  };

  const pageIds = displayedFiles.map((f) => f.id);

  const isPageSelected = () => {
    return showSelect
      ? intersection(
          pageIds,
          selectedFiles.map((f) => f.id)
        ).length === pageIds.length
      : false;
  };

  const onPageSelect = () => {
    const intersectIds = intersection(
      selectedFiles.map((f) => f.id),
      pageIds
    );
    if (intersectIds.length === pageIds.length) {
      setSelectedFiles(selectedFiles.filter((f) => pageIds.indexOf(f.id) < 0));
    } else {
      setSelectedFiles(uniq([...selectedFiles, ...displayedFiles]));
    }
  };

  if (!allFileStorageObjects || allFileStorageObjects.length === 0) {
    return (
      <div className="file-storage__no-content">
        <div>
          {selectedInventory.type === RESOURCES.PORTFOLIO ? (
            <>
              <Trans i18nKey="file-storage.no-content-portfolio">
                No files uploaded at any of the sites in this portfolio.
                <br />
                To start, navigate to a site and click the "Upload Files"
                button.
              </Trans>
            </>
          ) : (
            t("file-storage.no-content-site")
          )}
        </div>
        <br />
        <div>
          <Trans i18nKey="file-storage.no-content-guide">
            Store all sustainability-related documents in one place for easy
            access. Examples:
            <ul className="text-start">
              <li>
                Utilities invoices as evidence for environmental data records
              </li>
              <li>Images and documents for Initiatives</li>
              <li>Supporting documents for GSTC certification</li>
              <li>Hotel certifications and licenses</li>
              <li>Sustainability meeting minutes</li>
            </ul>
          </Trans>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <GVDSTableCtrlContainer>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row mb-2">
              <FilterSearchBox
                className="file-storage-search-box"
                placeholder="Search name or description"
                value={searchText}
                onInput={setSearchText}
              />
              {selectedInventory.type === RESOURCES.PORTFOLIO && (
                <div className="ms-2">
                  <GVDSTableCtrlMultiSelect
                    options={currentPortfolioSites}
                    defaultSelected={filterKeys.sites || []}
                    prefix="Sites"
                    onChange={(filterKs) =>
                      setFilterKeys({ ...filterKeys, sites: filterKs })
                    }
                  />
                </div>
              )}
            </div>
            {isLoadingOptions ? (
              <LoadingSpinner />
            ) : (
              <div className="d-flex flex-row">
                <GVDSTableCtrlMultiSelect
                  options={associatedModuleOptions.map((m) => m.name)}
                  prefix={t("file-storage.filter-prefix-modules")}
                  value={filterKeys.modules}
                  onChange={(filterKs) => {
                    setFilterKeys({ ...filterKeys, modules: filterKs });
                    setAssociatedModuleOptionsHeaderText(null);
                  }}
                  width="250px"
                  optionsHeaderText={associatedModuleOptionsHeaderText}
                />
                <GVDSTableCtrlMultiSelect
                  nonStringOptions={tagOptions}
                  optionTransformer={GenericUserManagedTagModel.getOptionTransformer()}
                  prefix={t("file-storage.filter-prefix-tags")}
                  onChange={(filterKs) =>
                    setFilterKeys({
                      ...filterKeys,
                      allTagSelectionValues: filterKs,
                    })
                  }
                  noOptionsMessage={GenericUserManagedTagModel.getNoTagsCreatedYetMessage(
                    t
                  )}
                />
                <div className="me-1">
                  <GVDSFormStartEndMonthPicker
                    startMonth={start}
                    endMonth={end}
                    onChange={(s, e) => {
                      if (s !== start) {
                        setStart(s);
                      }
                      if (e !== end) {
                        setEnd(e);
                      }
                    }}
                    startPlaceholderText={t("file-storage.filter-placeholder-uploaded-start")}
                    endPlaceholderText={t("file-storage.filter-placeholder-uploaded-end")}
                  />
                </div>
                <FileStorageActionDropdown
                  onModeChange={onModeChange}
                  totalFileCount={filteredFiles.length}
                  showDropdown={!showSelect}
                  editAll={() => {
                    onModeChange(
                      FILE_STORAGE_MAIN_SELECT_MODE.EDIT,
                      filteredFiles
                    );
                    onEdit();
                  }}
                  downloadAll={() =>
                    onModeChange(
                      FILE_STORAGE_MAIN_SELECT_MODE.DOWNLOAD,
                      filteredFiles
                    )
                  }
                  deleteAll={() =>
                    onModeChange(
                      FILE_STORAGE_MAIN_SELECT_MODE.DELETE,
                      filteredFiles
                    )
                  }
                />
              </div>
            )}
          </div>
        </GVDSTableCtrlContainer>
        <div className="d-flex">
          {showSelect && (
            <div className="d-flex align-items-end">
              <Form.Check
                checked={isPageSelected()}
                id="select-all-files-in-page"
                type="checkbox"
                label={t("shared.select-all-within-this-page")}
                onChange={onPageSelect}
              />
            </div>
          )}
          <Spacer />
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={filteredFiles.length}
            onChange={onPaginationChange}
          />
        </div>
        {filteredFiles.length === 0 && (
          <div className="file-storage__no-content">
            {t("file-storage.no-results-found")}
          </div>
        )}
        {displayedFiles.map((f) => {
          return (
            <FileStorageItemDisplay
              key={f.id}
              fileStorageObject={f}
              onEdit={() => {
                setSelectedFiles([f]);
                onEdit();
              }}
              onActionToFileStorageObject={loadFileStorageObjects}
              showSelect={showSelect}
              isSelected={selectedFiles.some(
                (fileTobeEdited) => fileTobeEdited.id === f.id
              )}
              onSelect={() => onFileSelect(f)}
            />
          );
        })}
      </>
    );
  }
};
export default FileStorageAllFiles;
