import React, { useContext, useEffect, useState } from "react";
import {
  DateTimeUtils,
  NumberService,
} from "../../../../services/UtilsService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import withAuthentication from "../../../HOC/withAuthentication";
import { PERMISSIONS } from "../../../../config/constants";
import {
  getDefaultMonthRangeEnd,
  getDefaultMonthRangeStart,
} from "../../../common/MonthRangeSelector";
import DeleteEnvironmentalDataRecordPrompt from "../DeleteEnvironmentalDataRecordPrompt";
import ToastContext from "../../../../context/ToastContext";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import PermissionsContext from "../../../../context/PermissionsContext";
import { ENVIRONMENTAL_DATA } from "../../../../config/ROUTES_NAME";
import MarketPurchaseOffsetService from "../../../../services/MarketPurchaseService";
import InputMarketPurchaseRecordModal from "./InputMarketPurchaseRecordModal";
import { MarketPurchaseOffsetRecordModel } from "./MarketPurchaseOffsetModel";
import CommentTooltip from "../../../common/Tooltip/CommentTooltip";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "../../../common/ReactHook";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSFormStartEndMonthPicker from "../../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSTable, {
  ACTIONS_DATAKEY,
} from "../../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import { Trans, useTranslation } from "react-i18next";

const getUnitName = (unitId, units) => {
  return units.filter((u) => u.id === unitId).map((u) => u.name);
};

const ViewMarketPurchaseTrackerDetails = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const [start, setStart] = useState(getDefaultMonthRangeStart());
  const [end, setEnd] = useState(getDefaultMonthRangeEnd());

  const [tracker, setTracker] = useState({});
  const [trackers, setTrackers] = useState([]);
  const [trackerRecords, setTrackerRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentlyEditedRecordModel, setCurrentlyEditedRecordModel] =
    useState(null);
  const [showInputModal, setShowInputModal] = useState(false);
  const handleCloseEditModal = () => {
    setCurrentlyEditedRecordModel(null);
    setShowInputModal(false);
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(trackerRecords, null);

  const [
    currentlyToBeDeletedRecordModels,
    setCurrentlyToBeDeletedRecordModels,
  ] = useState([]);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  useEffect(() => {
    if (location.state) {
      setTracker(location.state.tracker);
      setTrackers(location.state.trackers);
    } else {
      backToDashboard();
    }
  }, []);

  useEffect(() => {
    if (previousInventory && previousInventory !== selectedInventory) {
      backToDashboard();
    }
  }, [selectedInventory]);

  const loadDataRecords = () => {
    setIsLoading(true);
    MarketPurchaseOffsetService.getTrackerRecords(
      selectedInventory.type,
      selectedInventory.id,
      tracker.id,
      start,
      end
    )
      .then((data) => {
        setTrackerRecords(
          data.map((record) => MarketPurchaseOffsetRecordModel.fromJson(record))
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load market purchase records.</span>
        );
      });
  };

  const reloadAllData = () => {
    loadDataRecords();
  };

  useEffect(() => {
    if (tracker.id) {
      loadDataRecords();
    }
  }, [tracker, start, end]);

  const backToDashboard = () => {
    history.push(ENVIRONMENTAL_DATA);
  };

  const promptDeleteRecord = (recordModels) => {
    setCurrentlyToBeDeletedRecordModels(recordModels);
    setShowPromptDeleteModal(true);
  };

  const deleteCurrentlySelectedRecordModel = () => {
    MarketPurchaseOffsetService.deleteDataRecords(
      selectedInventory.type,
      selectedInventory.id,
      currentlyToBeDeletedRecordModels.map((model) => model.id)
    )
      .then(() => {
        toastContext.addSuccessToast(
          <span>Data record successfully deleted</span>
        );
        setCurrentlyToBeDeletedRecordModels([]);
        reloadAllData();
        handleClosePromptDeleteModal();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to delete record.</span>);
      });
  };

  const showEditRecord = (recordModel) => {
    setCurrentlyEditedRecordModel(recordModel);
    setShowInputModal(true);
  };

  if (!tracker.id) {
    return (
      <div>
        {t(
          "data-management.environmental.market-purchase.tracker-details.tracker-not-found"
        )}
      </div>
    );
  }

  const trackerDetails = (
    <>
      {tracker && (
        <Form>
          <Row className="mb-3">
            <Col lg={4}>
              <div>
                <Form.Label>{t("shared.facility")}</Form.Label>
                <div className="gvds-text--body">
                  {tracker["facility"]["name"]}
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Form.Label>
                  {t("data-management.environmental.shared.type")}
                </Form.Label>
                <div className="gvds-text--body">
                  {tracker["offset_type"]["name"]}
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Form.Label>{t("shared-input-label.unit")}</Form.Label>
                <div className="gvds-text--body">
                  {getUnitName(
                    tracker["unit_id"],
                    tracker["offset_type"]["units"]
                  )}{" "}
                  ({tracker["unit_id"]})
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <div>
                <Form.Label>
                  {t(
                    "data-management.environmental.market-purchase.tracker-details.linked-usage-meters"
                  )}
                </Form.Label>
                <ul>
                  {tracker["environmental_meters"].map((m) => (
                    <li key={m.id} className="gvds-text--body">
                      {tracker["offset_type"]["environmental_type_name"]}
                      {" - "}
                      {m.name} ({tracker["facility"]["name"]})
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );

  const columns = [
    {
      header: t("shared-input-label.period-from"),
      dataKey: "periodFrom",
      sortable: true,
      renderer: (data) => DateTimeUtils.formatUTCDate(data["periodFrom"]),
    },
    {
      header: t("shared-input-label.period-to"),
      dataKey: "periodTo",
      sortable: true,
      renderer: (data) => DateTimeUtils.formatUTCDate(data["periodTo"]),
    },
    {
      header: `${t(
        "data-management.environmental.market-purchase.single-input.option-bundled"
      )} / ${t(
        "data-management.environmental.market-purchase.single-input.option-unbundled"
      )}`,
      dataKey: "isBundled",
      renderer: (d) => (d.isBundled ? "Bundled" : "Unbundled"),
    },
    {
      header: t(
        "data-management.environmental.market-purchase.records.table-header-amount"
      ),
      dataKey: "amount",
      sortable: true,
      renderer: (data) => NumberService.format(data["amount"], 2, 2),
    },
    { header: t("shared-input-label.unit"), dataKey: "unitName" },
    {
      header: t("shared-input-label.comments"),
      dataKey: "comments",
      sortable: true,
      renderer: (data) =>
        data["comments"] ? <CommentTooltip comment={data["comments"]} /> : null,
    },
    {
      header: t("shared-input-label.actions"),
      dataKey: ACTIONS_DATAKEY,
      renderer: (data) => {
        return (
          <>
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.MARKET_PURCHASE_OFFSETS_RECORD_EDIT
              ] && (
                <GVDSIconButton
                  className="edit-eac-record-button"
                  variant={iconButtonVariant.tertiary}
                  icon={<GVDSIcon Icon={IconEdit} />}
                  onClick={() => {
                    showEditRecord(data);
                  }}
                  tooltipText={t("shared.edit")}
                />
              )}
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.MARKET_PURCHASE_OFFSETS_RECORD_DELETE
              ] && (
                <GVDSIconButton
                  className="delete-eac-record-button"
                  variant={iconButtonVariant.destructive}
                  icon={<GVDSIcon Icon={IconTrash} />}
                  onClick={() => {
                    promptDeleteRecord([data]);
                  }}
                  tooltipText={t("shared.delete")}
                />
              )}
          </>
        );
      },
    },
  ];

  const pastRecords = (
    <>
      {isLoading || !trackerRecords ? (
        <LoadingSpinner />
      ) : (
        <GVDSTable
          className="past-records"
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          noContent={
            <div className="table__no_content">
              <p>{t("data-management.environmental.all-records.no-record")}</p>
            </div>
          }
          viewControl={
            <GVDSTableCtrlContainer>
              <div className="date-range-container">
                <GVDSFormStartEndMonthPicker
                  startMonth={start}
                  endMonth={end}
                  onChange={(s, e) => {
                    if (s !== start) {
                      setStart(s);
                    }
                    if (e !== end) {
                      setEnd(e);
                    }
                  }}
                />
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
          }
        />
      )}
    </>
  );

  const backButtonPage = t("data-management.environmental.tab-data-input");

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text={
            <Trans i18nKey="shared.button-return-to-page">
              Return to {{ backButtonPage }}
            </Trans>
          }
          onClick={backToDashboard}
        />
        <PageHeader.Title>
          <h1>{tracker["tracker_id"]}</h1>
          <Spacer />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.MARKET_PURCHASE_OFFSETS_RECORD_CREATE
            ] && (
              <>
                <GVDSButton
                  variant={buttonVariant.secondary}
                  className="add-data"
                  onClick={() => setShowInputModal(true)}
                  text={t(
                    "data-management.environmental.market-purchase.input-button.single"
                  )}
                />
              </>
            )}
        </PageHeader.Title>
      </PageHeader>
      {trackerDetails}
      {pastRecords}
      <InputMarketPurchaseRecordModal
        show={showInputModal}
        currentRecord={currentlyEditedRecordModel}
        selectedTracker={{ id: tracker ? tracker["id"] : null }}
        trackers={trackers}
        unitId={tracker ? tracker["unit_id"] : null}
        onSave={reloadAllData}
        onClose={handleCloseEditModal}
      />
      <DeleteEnvironmentalDataRecordPrompt
        noOfRecordsToBeDeleted={1}
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        proceed={deleteCurrentlySelectedRecordModel}
      />
    </div>
  );
};

export default withAuthentication(ViewMarketPurchaseTrackerDetails);
