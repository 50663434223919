import axios from "axios";
import { API_URL } from "../config/api-config";
import { FileAttachmentItemModel } from "../components/common/FileAttachments";
import { BestPracticeQuestionListItemModel } from "./BestPracticeAdminService";
import { BestPracticeAssessedQuestionModel } from "./BestPracticeAssessmentService";
import { GuidanceArticleListItemModel } from "./GuidanceArticleAdminService";

export const GUIDANCE_BOOKMARK_STATUS = {
  IS_BOOKMARKED: "Bookmarked",
  IS_NOT_BOOKMARKED: "Not Bookmarked",
};

class GuidanceArticleUserListItemModel extends GuidanceArticleListItemModel {
  constructor(articleListItem) {
    super(articleListItem);

    this.isBookmarked = articleListItem["bookmark_status"];
  }

  get bookmarkStatus() {
    return this.isBookmarked
      ? GUIDANCE_BOOKMARK_STATUS.IS_BOOKMARKED
      : GUIDANCE_BOOKMARK_STATUS.IS_NOT_BOOKMARKED;
  }
}

export default class GuidanceArticleService {
  static getGuidanceArticle = async (
    resource_type,
    resource_id,
    guidanceArticleId
  ) => {
    try {
      const params = { resource_type: resource_type, resource_id: resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/guidance_articles/${guidanceArticleId}?${new URLSearchParams(
          params
        ).toString()}`
      );

      const article = response.data;

      if (article.content_file) {
        article.content_file = FileAttachmentItemModel.fromFileDTO(
          article.content_file
        );
      }

      article.attached_files = article.attached_files.map((file) =>
        FileAttachmentItemModel.fromFileDTO(file)
      );

      article.linked_questions = article.linked_questions.map(
        (q) => new BestPracticeQuestionListItemModel(q)
      );

      return article;
    } catch (error) {
      throw error;
    }
  };

  static getGuidanceArticlesForInventory = async (
    resource_id,
    resource_type
  ) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/guidance_articles`, {
        params: { resource_id, resource_type },
      });
      return response.data.map((a) => new GuidanceArticleUserListItemModel(a));
    } catch (error) {
      throw error;
    }
  };

  static createBookmark = async (resource_id, resource_type, article_id) => {
    try {
      const params = { resource_type: resource_type, resource_id: resource_id };
      const response = await axios.put(
        `${API_URL}/api/v1/guidance_articles/${article_id}/bookmark?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteBookmark = async (resource_id, resource_type, article_id) => {
    try {
      const params = { resource_type: resource_type, resource_id: resource_id };
      const response = await axios.delete(
        `${API_URL}/api/v1/guidance_articles/${article_id}/bookmark?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getLinkedQuestionsForArticle = async (
    resource_id,
    resource_type,
    articleId
  ) => {
    try {
      const params = { resource_type: resource_type, resource_id: resource_id };
      const data = { articleId };
      const response = await axios.get(
        `${API_URL}/api/v1/guidance_articles/${articleId}/best_practices?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data.map((q) => new BestPracticeAssessedQuestionModel(q));
    } catch (error) {
      throw error;
    }
  };
}
