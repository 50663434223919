import React, { useContext, useEffect, useMemo, useState } from "react";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import {
  ActivityLogModel,
  EnvironmentalDataRecordActivityLogService,
  environmentalDataRecordChangeKeyLabelPair,
} from "../../../services/ActivityLogService";
import uniq from "lodash/uniq";
import { resetFilterKeys } from "../../../gvds-components/Table/TableUtils";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { RESOURCES } from "../../../config/constants";
import LastActivityDisplay from "../LastActivityDisplay";
import { uniqBy } from "lodash";
import ActivityLogChangesDisplay from "./ActivityLogChangesDisplay";
import LoadingSpinner from "../LoadingSpinner";
import GVDSTable from "../../../gvds-components/Table/GVDSTable";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActivityLogUserMetaDisplay from "./ActivityLogUserMetaDisplay";
import ActivityLogDetailsFieldWithChanges from "./ActivityLogDetailsFieldWithChanges";
import {
  ACTIVITY_LOG_ACTION_LABEL,
  filterActivityLogsByPeriod,
  getActionLogLabelColor,
  getTranslatedActionLogLabel,
  LegacyActivityLogDetailContent,
} from "./ActivityLogSharedUtils";
import { DateTimeUtils } from "../../../services/UtilsService";
import GVDSFormStartEndDatePicker from "../../../gvds-components/Forms/GVDSFormStartEndDatePicker";
import { useTranslation } from "react-i18next";

const ViewEnvironmentalDataRecordsActivityLogsList = ({
  lastUpdate,
  setActivityLogDatePeriodStart,
  activityLogDatePeriodStart,
  setActivityLogDatePeriodEnd,
  activityLogDatePeriodEnd,
  setFilterStartDate,
  filterStartDate,
  setFilterEndDate,
  filterEndDate,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [allActivityLogs, setAllActivityLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentActivityLog, setCurrentActivityLog] = useState(null);
  const [showActivityLogDetailModal, setShowActivityLogDetailModal] =
    useState(false);
  const handleCloseActivityLogDetailModal = () => {
    setCurrentActivityLog(null);
    setShowActivityLogDetailModal(false);
  };

  const isSiteInventory =
    userInventory.selectedInventory.get.type === RESOURCES.SITE;
  const isSiteHasMoreThanOneFacility =
    isSiteInventory && userInventory.facilities.get.length > 1;

  const periodFilteredActivityLogs = useMemo(
    () =>
      filterActivityLogsByPeriod({
        allActivityLogs,
        activityLogDatePeriodStart,
        activityLogDatePeriodEnd,
        filterStartDate,
        filterEndDate,
      }),
    [
      allActivityLogs,
      activityLogDatePeriodStart,
      activityLogDatePeriodEnd,
      filterStartDate,
      filterEndDate,
    ]
  );

  const {
    filteredSortedData,
    currentPageData,

    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,

    filterKeys,
    setFilterKeys,

    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(periodFilteredActivityLogs);

  const loadActivityLogs = () => {
    const currentInventory = userInventory.selectedInventory.get;
    if (currentInventory) {
      setIsLoading(true);
      EnvironmentalDataRecordActivityLogService.getEnvironmentalDataRecordActivityLogs(
        currentInventory.id,
        currentInventory.type
      )
        .then((activityLogModels) => {
          setAllActivityLogs(activityLogModels);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load activity log records.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }
  };

  const onActivityLogPeriodControlChange = (startPeriod, endPeriod) => {
    if (startPeriod !== activityLogDatePeriodStart) {
      setActivityLogDatePeriodStart(startPeriod);
    }
    if (endPeriod !== activityLogDatePeriodEnd) {
      setActivityLogDatePeriodEnd(endPeriod);
    }
  };
  const onRecordPeriodControlChange = (startPeriod, endPeriod) => {
    if (startPeriod !== filterStartDate) {
      setFilterStartDate(startPeriod);
    }
    if (endPeriod !== filterEndDate) {
      setFilterEndDate(endPeriod);
    }
  };

  useEffect(() => {
    loadActivityLogs();
  }, [userInventory.selectedInventory.get, lastUpdate]);

  useEffect(() => {
    const actions = uniq(periodFilteredActivityLogs.map((r) => r.action));
    const subtopicNames = uniq(
      periodFilteredActivityLogs.map((r) => r.subtopicName)
    );
    const typeNames = uniq(periodFilteredActivityLogs.map((r) => r.typeName));
    const facilityNames = uniq(
      periodFilteredActivityLogs.map((r) => r.facilityName)
    );
    const meterNames = uniq(periodFilteredActivityLogs.map((r) => r.meterName));

    const newFilterKeys = resetFilterKeys(filterKeys, {
      action: actions,
      subtopicName: subtopicNames,
      typeName: typeNames,
      facilityName: facilityNames,
      meterName: meterNames,
    });

    setFilterKeys(newFilterKeys);
  }, [periodFilteredActivityLogs]);

  let columns = [
    {
      header: t("data-management.shared.activity-log.label-action"),
      headerStyle: { width: "100px" },
      dataKey: "action",
      sortable: true,
      renderer: (d) => (
        <StatusLabel color={getActionLogLabelColor(d.action)}>
          {getTranslatedActionLogLabel(t, d.action)}
        </StatusLabel>
      ),
    },
    {
      header: t("data-management.shared.activity-log.label-action-by"),
      headerStyle: { width: "150px" },
      dataKey: "actionBy",
      sortable: true,
      renderer: (d) => {
        return (
          <div style={{ wordBreak: "break-all" }}>
            {d.lastActivity && (
              <LastActivityDisplay lastActivity={d.lastActivity} />
            )}
          </div>
        );
      },
    },
    {
      header: t(
        "data-management.environmental.usage.single-input.label-subtopic"
      ),
      dataKey: "subtopicName",
      sortable: true,
    },
    {
      header: t("data-management.environmental.shared.type"),
      dataKey: "typeName",
      sortable: true,
    },
    {
      header: t("shared.meter"),
      dataKey: "meterName",
      sortable: true,
    },
    {
      header: t("data-management.shared.activity-log.label-changes"),
      headerStyle: { minWidth: "200px", width: "30%" },
      dataKey: "changes",
      renderer: (d) => {
        return (
          <div style={{ wordBreak: "break-all" }}>
            <ActivityLogChangesDisplay
              action={d.action}
              changes={d.changes}
              keyLabelPair={environmentalDataRecordChangeKeyLabelPair}
            />
          </div>
        );
      },
    },
  ];

  if (isSiteHasMoreThanOneFacility) {
    columns.splice(2, 0, {
      header: t("shared.facility"),
      headerStyle: { width: "150px", maxWidth: "240px" },
      dataKey: "facilityName",
      sortable: true,
    });
  }

  let content;
  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <GVDSTable
        className="environmental-records-activity-log-table"
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        onRowClick={(activityLog) => {
          setCurrentActivityLog(activityLog);
          setShowActivityLogDetailModal(true);
        }}
        selectionKey={"id"}
        noContent={
          <div className="table__no_content">
            <p>{t("data-management.shared.activity-log.no-content")}</p>
          </div>
        }
        viewControl={
          <>
            <GVDSTableCtrlContainer>
              <GVDSTableCtrlMultiSelect
                nonStringOptions={uniqBy(
                  periodFilteredActivityLogs.flat(),
                  (t) => t.action
                )}
                optionTransformer={ActivityLogModel.getOptionTransformer()}
                prefix="Action"
                value={filterKeys.action}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, action: filterKs })
                }
              />
              {isSiteHasMoreThanOneFacility && (
                <GVDSTableCtrlMultiSelect
                  options={uniq(
                    periodFilteredActivityLogs.map((r) => r.facilityName)
                  )}
                  prefix="Facility"
                  value={filterKeys.facilityName}
                  onChange={(filterKs) =>
                    setFilterKeys({ ...filterKeys, facilityName: filterKs })
                  }
                />
              )}
              <GVDSTableCtrlMultiSelect
                options={uniq(
                  periodFilteredActivityLogs.map((r) => r.subtopicName)
                )}
                prefix="Subtopic"
                value={filterKeys.subtopicName}
                onChange={(filterKs) => {
                  setFilterKeys({ ...filterKeys, subtopicName: filterKs });
                }}
              />
              <GVDSTableCtrlMultiSelect
                options={uniq(
                  periodFilteredActivityLogs.map((r) => r.typeName)
                )}
                prefix="Type"
                value={filterKeys.type_name}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, typeName: filterKs })
                }
              />
              <GVDSTableCtrlMultiSelect
                options={uniq(
                  periodFilteredActivityLogs.map((r) => r.meterName)
                )}
                prefix="Meter"
                value={filterKeys.meterName}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, meterName: filterKs })
                }
              />
            </GVDSTableCtrlContainer>
            <GVDSTableCtrlContainer>
              <div className="date-range__layout-container">
                <div className="date-range-inline-label">
                  {t("data-management.shared.activity-log.label-action-date")}
                </div>
                <div className="date-range-container">
                  <GVDSFormStartEndDatePicker
                    startDate={activityLogDatePeriodStart}
                    endDate={activityLogDatePeriodEnd}
                    onChange={onActivityLogPeriodControlChange}
                    dateFormat="dd MMM yyyy"
                  />
                </div>
              </div>

              <div className="date-range__layout-container">
                <span className="date-range-inline-label">
                  {t("data-management.shared.activity-log.label-record-period")}
                </span>
                <div className="date-range-container">
                  <GVDSFormStartEndDatePicker
                    startDate={filterStartDate}
                    endDate={filterEndDate}
                    onChange={onRecordPeriodControlChange}
                    dateFormat="dd MMM yyyy"
                  />
                </div>
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
          </>
        }
      />
    );
  }

  return (
    <div>
      {content}
      <EnvironmentalDataRecordActivityLogDetailsModal
        show={showActivityLogDetailModal}
        onClose={handleCloseActivityLogDetailModal}
        currentRecord={currentActivityLog}
      />
    </div>
  );
};

const EnvironmentalDataRecordActivityLogDetailsModal = ({
  show,
  onClose,
  currentRecord,
}) => {
  const { t } = useTranslation();

  if (currentRecord) {
    const isDeleteAction =
      currentRecord.action === ACTIVITY_LOG_ACTION_LABEL.DELETE;
    const isLegacyActivityLog = currentRecord.isLegacyActivityLog;

    let modalContent;
    if (isLegacyActivityLog) {
      modalContent = <LegacyActivityLogDetailContent />;
    } else {
      modalContent = (
        <div className="mt-3 flex-column d-flex gap-3">
          <Row>
            <Col sm={3}>
              <div className="gvds-text--inputField">
                {t("data-management.shared.activity-log.label-action")}
              </div>
            </Col>
            <Col sm={9}>
              <StatusLabel color={getActionLogLabelColor(currentRecord.action)}>
                {getTranslatedActionLogLabel(t, currentRecord.action)}
              </StatusLabel>
            </Col>
          </Row>
          <Row>
            <Col sm={3} className="align-content-center">
              <div className="gvds-text--inputField">
                {t("data-management.shared.activity-log.label-action-by")}
              </div>
            </Col>
            <Col sm={9}>
              <ActivityLogUserMetaDisplay
                lastActivity={currentRecord.lastActivity}
              />
            </Col>
          </Row>
          <ActivityLogDetailsFieldWithChanges
            label={t("shared.facility")}
            value={currentRecord.facilityName}
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t("data-management.environmental.shared.type")}
            value={currentRecord.typeName}
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t("shared.meter")}
            value={currentRecord.meterName}
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t("shared-input-label.period-from")}
            value={DateTimeUtils.formatLocalDate(currentRecord.periodFrom)}
            changes={
              currentRecord.changes ? currentRecord.changes.period_from : null
            }
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t("shared-input-label.period-to")}
            value={DateTimeUtils.formatLocalDate(currentRecord.periodTo)}
            changes={
              currentRecord.changes ? currentRecord.changes.period_to : null
            }
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t(
              "data-management.environmental.usage.single-input.label-usage"
            )}
            value={currentRecord.usage}
            changes={currentRecord.changes ? currentRecord.changes.usage : null}
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t("shared-input-label.unit")}
            value={currentRecord.unitName}
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t(
              "data-management.environmental.usage.single-input.label-cost"
            )}
            value={currentRecord.cost}
            changes={currentRecord.changes ? currentRecord.changes.cost : null}
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t("shared-input-label.currency")}
            value={currentRecord.currencyName}
            lineThroughValueDisplay={isDeleteAction}
          />
          <ActivityLogDetailsFieldWithChanges
            label={t("shared-input-label.comments")}
            value={currentRecord.comments}
            changes={
              currentRecord.changes ? currentRecord.changes.comments : null
            }
            lineThroughValueDisplay={isDeleteAction}
          />
        </div>
      );
    }
    return (
      <GVDSModal
        title={t(
          "data-management.shared.activity-log.modal-title-activity-details"
        )}
        size={GVDSModal.Size.medium}
        show={show}
        onHide={onClose}
      >
        <GVDSModal.Body>{modalContent}</GVDSModal.Body>
      </GVDSModal>
    );
  }
};
export default ViewEnvironmentalDataRecordsActivityLogsList;
