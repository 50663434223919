export default class OperationalDataRecordModel {
  constructor(facilityId, facilityName, readingJson) {
    this.facilityId = facilityId;
    this.facilityName = facilityName;
    this.id = readingJson["id"];
    this.meterId = readingJson["meter_id"];
    this.meterType = readingJson["meter_type"];
    this.periodFrom = readingJson["period_from"];
    this.periodTo = readingJson["period_to"];
    this.value = readingJson["value"];
    this.comments = readingJson["comments"];
  }
}
