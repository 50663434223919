import React, { useContext } from "react";
import { Link, Route, Switch } from "react-router-dom";

import withAuthentication from "../HOC/withAuthentication";
import {
  ADMIN_BILLING,
  ADMIN_FACILITIES,
  ADMIN_FACILITY_DETAILS,
  ADMIN_SITE_DETAILS,
  ADMIN_TAGS,
  ADMIN_TEAM,
} from "../../config/ROUTES_NAME";
import UserInventoryContext, {
  getSiteId,
} from "../../context/UserInventoryContext";
import { RESOURCES } from "../../config/constants";
import ViewTeamDetails from "./Team/ViewTeamDetails";
import ViewSiteDetails from "./Site/ViewSiteDetails";
import ViewAllFacilities from "./Facility/ViewAllFacilities";
import ViewFacilityDetails from "./Facility/ViewFacilityDetails";
import { getPathWithUpdatedInventory } from "../common/QueryHandler";
import ViewAllTags from "./Tags/ViewAllTags";
import { PageWrapper } from "../../gvds-components/Nav/PageWrapper/PageWrapper";
import ViewBilling from "./Billing/ViewBilling";
import { Trans, useTranslation } from "react-i18next";

const Admin = () => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const getLinkToFacilityDetails = (facilityId) => {
    const newLocation = {
      pathname: `/admin/facilities/${facilityId}`,
    };
    return getPathWithUpdatedInventory(
      newLocation,
      RESOURCES.SITE,
      getSiteId(userInventory.selectedTreeNode.get)
    );
  };

  const isFacility =
    selectedInventory && selectedInventory.type === RESOURCES.FACILITY;

  let content;
  if (isFacility) {
    content = (
      <div>
        <div className="table__no_content">
          <div>
            <Trans i18nKey="admin.not-applicable-to-facility">
              Please access the Facility’s Details{" "}
              <Link to={getLinkToFacilityDetails(selectedInventory.id)}>
                here
              </Link>
              .
            </Trans>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <Switch>
        <Route
          exact
          path={ADMIN_TEAM}
          render={() => (
            <PageWrapper configRouteName={ADMIN_TEAM}>
              <ViewTeamDetails />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={ADMIN_SITE_DETAILS}
          render={() => (
            <PageWrapper configRouteName={ADMIN_SITE_DETAILS}>
              <ViewSiteDetails />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={ADMIN_FACILITIES}
          render={() => (
            <PageWrapper configRouteName={ADMIN_FACILITIES}>
              <ViewAllFacilities />{" "}
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={ADMIN_FACILITY_DETAILS}
          render={() => <ViewFacilityDetails />}
        />
        <Route
          exact
          path={ADMIN_TAGS}
          render={() => (
            <PageWrapper configRouteName={ADMIN_TAGS}>
              <ViewAllTags />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={ADMIN_BILLING}
          render={() => (
            <PageWrapper configRouteName={ADMIN_BILLING}>
              <ViewBilling />
            </PageWrapper>
          )}
        />
      </Switch>
    );
  }

  return content;
};

export default withAuthentication(Admin);
