import React from "react";

import Select, { components } from "react-select";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "./GVDSFormShared";
import GVDSTag, { TAG_COLOR_BY_VARIANT, TagVariants } from "../common/GVDSTag";
import GVDSIcon from "../Icons/GVDSIcon";
import { IconX } from "@tabler/icons-react";
import {GVDSComponentColors} from "../../styles/gvds-colors";

const portallingMultiSelectStyles = {
  // disabled state cursor style cannot be overridden through CSS
  control: (provided, state) => ({
    ...provided,
    ...(state.isDisabled && {
      pointerEvents: "auto",
      cursor: "not-allowed",
      backgroundColor: GVDSComponentColors.formDisabledBackgroundColor,
      color: GVDSComponentColors.formDisabledTextColor,
    }),
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => ({
    ...provided,
    ...(state.isDisabled && {
      color: GVDSComponentColors.formDisabledTextColor
    })
  }),
  multiValue: (provided, state) => ({
    ...provided,
    ...(state.isDisabled && {
      color: GVDSComponentColors.formDisabledTextColor
    })
  })
};

export const GenericOption = (props) => {
  return (
    <components.Option {...props}>
      <GVDSTag variant={props.data.variant}>{props.label}</GVDSTag>
    </components.Option>
  );
};

const MultiValue = ({ variant = GVDSTag.Variants.system, children }) => {
  const tagColor = TAG_COLOR_BY_VARIANT[variant];
  return (
    <div className={`selected-option-container option-color--${tagColor}`}>
      {children}
    </div>
  );
};

export const MultiValueContainer = (props) => {
  return (
    <MultiValue variant={props.data.variant}>
      <components.MultiValueContainer {...props} />
    </MultiValue>
  );
};

export const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <GVDSIcon Icon={IconX} />
    </components.MultiValueRemove>
  );
};

const GVDSFormMultiSelect = ({
  name,
  options = [],
  value,
  onSelect,
  statusMetadata = FormFieldStatusMetadata.getDefault(),
  placeholder = "Select Option",
  noOptionsMessage = "No options",
  disabled,
  isLoading,
  isSearchable,
  isClearable,
  isOptionDisabled,
  hideSelectedOptions = false,
  menuIsOpen,
  closeMenuOnSelect,
  className,
  components,
  width = "200px",
  maxMenuHeight,
  menuPlacement,
  menuShouldScrollIntoView = true,
  ...props
}) => {
  const gvdsClassNames = ["gvds-form__multi-select"].concat(
    statusMetadata.state === FormFieldState.warning
      ? ["gvds-form__warning"]
      : statusMetadata.state === FormFieldState.error
      ? ["gvds-form__error"]
      : []
  );

  const combinedClassNames = `${gvdsClassNames.join(" ")} ${className || ""}`;

  const onSelectChange = (selectedOption, actionContext) => {
    onSelect(selectedOption, actionContext);
  };

  return (
    <>
      <Select
        isMulti
        name={name}
        options={options}
        value={value}
        onChange={onSelectChange}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        isDisabled={disabled}
        isLoading={isLoading}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isOptionDisabled={isOptionDisabled}
        hideSelectedOptions={hideSelectedOptions}
        menuIsOpen={menuIsOpen}
        closeMenuOnSelect={closeMenuOnSelect}
        classNamePrefix="gvds-multi-select"
        className={combinedClassNames}
        components={{
          Option: GenericOption,
          MultiValueContainer: MultiValueContainer,
          MultiValueRemove: MultiValueRemove,
          ...components,
        }}
        width={width}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        styles={portallingMultiSelectStyles}
        menuPosition="fixed"
        {...props}
      />
      {statusMetadata.isNotNormal() && statusMetadata.message && (
        <GVDSFormErrorMessage
          status={statusMetadata.state}
          errorMsg={statusMetadata.message}
        />
      )}
    </>
  );
};

export default GVDSFormMultiSelect;
