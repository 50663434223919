import {
  CellValue,
  DateCellValue,
  DropdownCellValue,
  NumberCellValue,
  RemoveActionCell,
} from "../BulkDataInputCellViewer";
import { RESOURCES } from "../../../config/constants";
import _ from "lodash";
import get from "lodash/get";

const defaultColumns = [
  {
    key: "action",
    label: "",
    width: 2,
    classType: RemoveActionCell,
    errorKey: "",
  },
  {
    key: "index",
    label: "Row",
    width: 2.5,
    classType: CellValue,
    errorKey: "index",
  },
];

const valueColumns = [
  {
    key: "metric",
    label: "Metric*",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "meter_id",
  },
  {
    key: "periodFrom",
    label: "Period from*",
    width: 11.5,
    classType: DateCellValue,
    errorKey: "period_from",
  },
  {
    key: "periodTo",
    label: "Period to*",
    width: 11.5,
    classType: DateCellValue,
    errorKey: "period_to",
  },
  {
    key: "value",
    label: "Value*",
    width: 10,
    classType: NumberCellValue,
    errorKey: "value",
  },
];

class DataRecordValue {
  action;
  index;
  facility;
  metric;
  periodFrom;
  periodTo;
  value;

  static fromRowData(rowData, columns) {
    const newRecord = new DataRecordValue();
    columns.forEach((c, col) => {
      newRecord[c.key] = c.classType.fromCellData(rowData[col]);
    });
    return newRecord;
  }

  static newReading(row, removeRow, columns) {
    const newReading = new DataRecordValue();

    columns.forEach((c) => {
      newReading[c.key] = new c.classType();
    });

    newReading.action = RemoveActionCell.fromRemoveRow(() => removeRow(row));
    newReading.index = new CellValue(row + 1, true, [], [], false);
    return newReading;
  }

  updateDataEditor(sites = [], facilities = [], metrics = []) {
    this.site && this.site.setDropdownOptions(sites);
    this.facility && this.facility.setDropdownOptions(facilities);
    this.metric.setDropdownOptions(metrics);
  }

  toGridRow(columns) {
    return columns.map((c) => this[c.key]);
  }

  isEmpty() {
    return (
      (!this.site || this.site.isEmpty()) &&
      (!this.facility || this.facility.isEmpty()) &&
      this.metric.isEmpty() &&
      this.periodFrom.isEmpty() &&
      this.periodTo.isEmpty() &&
      this.value.isEmpty()
    );
  }

  removeAllErrors(columns) {
    columns.forEach((c) => {
      this[c.key].dataErrors = [];
      this[c.key].possibleErrors = [];
    });
  }
}

export default class BulkOperationalDataInputService {
  siteId = "";
  facilityId = "";
  sites = [];
  columns = [];
  selectorOptions = {};

  updateResource(resourceType, node) {
    this.siteId = "";
    this.facilityId = "";
    this.columns = [];
    switch (resourceType) {
      case RESOURCES.PORTFOLIO:
        this.sites = _.chain(node.children)
          .map((c) => c.nodeValue)
          .filter((c) => c.type === RESOURCES.SITE)
          .sortBy("name")
          .value();
        this.columns = this.columns
          .concat(defaultColumns)
          .concat([
            {
              key: "site",
              label: "Site*",
              width: 10,
              classType: DropdownCellValue,
              errorKey: "site_id",
            },
            {
              key: "facility",
              label: "Facility*",
              width: 10,
              classType: DropdownCellValue,
              errorKey: "facility_id",
            },
          ])
          .concat(valueColumns);
        break;
      case RESOURCES.SITE:
        this.sites = [node.nodeValue];
        this.siteId = node.nodeValue.id;
        this.columns = this.columns
          .concat(defaultColumns)
          .concat([
            {
              key: "facility",
              label: "Facility*",
              width: 10,
              classType: DropdownCellValue,
              errorKey: "facility_id",
            },
          ])
          .concat(valueColumns);
        break;
      default:
        const site = _.chain(node.parents)
          .map((n) => n.nodeValue)
          .find((p) => p.type === RESOURCES.SITE)
          .value();
        this.sites = site ? [site] : [];
        this.siteId = site.id;
        this.facilityId = node.nodeValue.id;
        this.columns = this.columns.concat(defaultColumns).concat(valueColumns);
    }
  }

  setSelectionOptions(datasets) {
    this.selectorOptions = _.chain(datasets).groupBy("facility.id").value();
  }

  getNewRow(row, removeRow) {
    const newReading = DataRecordValue.newReading(
      Number(row),
      removeRow,
      this.columns
    );
    this.updateDataEditorForRow(newReading);
    return newReading.toGridRow(this.columns);
  }

  updateDataEditorForRow(record) {
    const facilities = _.chain(this.sites)
      .find((s) => s.name === record.site?.value || s.id === this.siteId)
      .get("value.all_facilities", [])
      .sortBy("name")
      .value();
    const facility = _.chain(facilities)
      .find(
        (f) => f.name === record.facility?.value || f.id === this.facilityId
      )
      .value();
    const datasets = _.chain(this.selectorOptions)
      .get(facility?.id, [])
      .value();
    const metrics = _.chain(datasets)
      .flatMap((d) => d["meters"])
      .map((m) => m["operation_type"])
      .sortBy("name")
      .value();
    record.updateDataEditor(this.sites, facilities, metrics);
  }

  updateRow(rowData, changes) {
    const record = DataRecordValue.fromRowData(rowData, this.columns);

    changes.forEach((c) => {
      const colName = this.columns[c.col]?.key;
      if (colName) {
        record[colName].updateValue(c.value);
      }
    });
    this.updateDataEditorForRow(record);
    return record.toGridRow(this.columns);
  }

  removeEmptyRows(grid, setRowToDelete) {
    return grid
      .map((rowData) => DataRecordValue.fromRowData(rowData, this.columns))
      .filter((r) => !r.isEmpty())
      .map((r, row) => {
        r.index.updateValue(row + 1);
        r.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row));
        return r.toGridRow(this.columns);
      });
  }

  removeRow(rowNumber, setRowToDelete, grid) {
    let changedGrid = grid.map((row) => [...row]);
    changedGrid.splice(rowNumber, 1);
    changedGrid = changedGrid.map((g, row) => {
      const reading = DataRecordValue.fromRowData(g, this.columns);
      reading.index.updateValue(row + 1);
      reading.action = RemoveActionCell.fromRemoveRow(() =>
        setRowToDelete(row)
      );
      return reading.toGridRow(this.columns);
    });
    return changedGrid;
  }

  getDataReading(rowData) {
    const record = DataRecordValue.fromRowData(rowData, this.columns);
    const site = _.chain(this.sites)
      .find((s) => s.name?.trim() === record.site?.value?.trim() || s.id === this.siteId, {})
      .value();
    const facilityId = _.chain(site)
      .get("value.all_facilities", [])
      .find(
        (f) =>
          f.name?.trim() === record.facility?.value?.trim() || f.id === this.facilityId
      )
      .get("id", "");
    const meter = _.chain(this.selectorOptions)
      .get(facilityId, [])
      .flatMap((d) => d["meters"])
      .find((m) => m["operation_type"]["name"]?.trim() === record.metric?.value?.trim())
      .value();

    return {
      site_id: site?.id,
      facility_id: facilityId,
      period_from: record.periodFrom.getValue(),
      period_to: record.periodTo.getValue(),
      value: record.value.getValue(),
      row: record.index.value - 1,
      meter_id: meter ? meter.id : "",
    };
  }

  updateGridWithErrors = (grid, dataErrors, possibleErrors) => {
    const changedGrid = grid.map((rowData) => {
      const reading = DataRecordValue.fromRowData(rowData, this.columns);
      reading.removeAllErrors(this.columns);
      return reading.toGridRow(this.columns);
    });

    for (const [row, err] of Object.entries(dataErrors)) {
      const reading = DataRecordValue.fromRowData(
        changedGrid[row],
        this.columns
      );
      this.columns.forEach(({ key, errorKey }) => {
        reading[key].dataErrors = _.chain(err).get(errorKey, []).value();
      });
      changedGrid[row] = reading.toGridRow(this.columns);
    }

    for (const [row, err] of Object.entries(possibleErrors)) {
      const reading = DataRecordValue.fromRowData(
        changedGrid[row],
        this.columns
      );
      this.columns.forEach(({ key, errorKey }) => {
        reading[key].possibleErrors = get(err, errorKey, []);
      });
      changedGrid[row] = reading.toGridRow(this.columns);
    }

    return changedGrid;
  };
}
