import React from "react";
import remove from "lodash/remove";

import {
  INITIATIVE_EXCEPTION_SOURCE,
  INITIATIVE_TRACKING_CRITERION,
  INITIATIVE_TRACKING_FIELD,
} from "../../../config/constants";
import { InitiativeModel } from "./InitiativeModel";
import { SortUtils } from "../../../services/UtilsService";
import InitiativeService from "../../../services/InitiativeService";
import {
  SourceSetupSelector,
  UnitSetupSelector,
} from "./InitiativeFormComponents";
import { SelectCurrencyCompact } from "../../Currency/SelectCurrency";
import GVFormGroup from "../../common/GVFormGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormTextArea from "../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconTrash } from "@tabler/icons-react";
import Form from "react-bootstrap/Form";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import { Trans, useTranslation } from "react-i18next";

const fieldWidth = {
  [INITIATIVE_TRACKING_FIELD.SOURCE]: "250px",
  [INITIATIVE_TRACKING_FIELD.DESCRIPTOR]: "250px",
  [INITIATIVE_TRACKING_FIELD.CURRENCY]: "120px",
  [INITIATIVE_TRACKING_FIELD.UNIT]: "150px",
  [INITIATIVE_TRACKING_FIELD.VALUE]: "160px",
  [INITIATIVE_TRACKING_FIELD.QUANTITY]: "110px",
};

const getFieldWidth = (criterion, field) => {
  if (
    criterion === INITIATIVE_TRACKING_CRITERION.EFFICIENCY_SAVINGS &&
    field === INITIATIVE_TRACKING_FIELD.UNIT
  ) {
    return "200px";
  } else {
    return fieldWidth[field];
  }
};

const TrackingCriteriaForm = ({
  trackingCriteriaRecord,
  subtopicTrackingCriteriaOptions,
  currencies,
  defaultCurrencyId,
  energyTypes,
  onTrackingCriteriaRecordUpdate,
  disabled,
  isValidated,
}) => {
  const { t } = useTranslation();

  const onChange = () => {
    onTrackingCriteriaRecordUpdate({ ...trackingCriteriaRecord });
  };

  const onAddingTrackingCriteria = (newTrackingCriterion) => {
    if (!trackingCriteriaRecord[newTrackingCriterion]) {
      const newCriterionRecord = [];

      const newTrackingMeter = InitiativeModel.createNewTrackingMeter(
        subtopicTrackingCriteriaOptions[newTrackingCriterion]["options"].fields,
        null,
        defaultCurrencyId
      );
      newCriterionRecord.push(newTrackingMeter);

      trackingCriteriaRecord = {
        [newTrackingCriterion]: newCriterionRecord,
        ...trackingCriteriaRecord,
      };
    }

    onChange();
  };

  const onDeletingTrackingCriteria = (criterion) => {
    delete trackingCriteriaRecord[criterion];
    onChange();
  };

  const onAddingTrackingMeter = (criterion) => {
    const newTrackingMeter = InitiativeModel.createNewTrackingMeter(
      subtopicTrackingCriteriaOptions[criterion]["options"].fields,
      null,
      defaultCurrencyId
    );

    trackingCriteriaRecord[criterion].push(newTrackingMeter);
    onChange();
  };

  const onDeletingTrackingMeter = (criterion, meter) => {
    remove(trackingCriteriaRecord[criterion], (meterInCriterion) => {
      return meterInCriterion === meter;
    });

    if (trackingCriteriaRecord[criterion].length === 0) {
      delete trackingCriteriaRecord[criterion];
    }

    onChange();
  };

  const onChangeMeterField = () => {
    onChange();
  };

  const onChangeInputField = (event, meter) => {
    const fieldUpdated = event.target.name;
    const fieldType = event.target.type;
    if (fieldType === "number") {
      meter[fieldUpdated] = parseFloat(event.target.value);
    } else {
      meter[fieldUpdated] = event.target.value;
    }

    onChange();
  };

  const getTrackingCriteriaSelector = () => {
    if (disabled) {
      return null;
    }

    const unselectedTrackingCriteria = Object.keys(
      subtopicTrackingCriteriaOptions
    )
      .filter(
        (criterionOption) =>
          Object.keys(trackingCriteriaRecord).indexOf(criterionOption) < 0
      )
      .sort(SortUtils.sortStringWithOthersLast);

    let criteriaSelectors = (
      <div className="gvds-text--caption__italic gvds-color--gray6">
        {t(
          "data-management.initiatives.input.tracking-criteria.label-all-criteria-selected"
        )}
      </div>
    );

    if (unselectedTrackingCriteria.length > 0) {
      criteriaSelectors = unselectedTrackingCriteria.map((criterion, index) => {
        return (
          <OverlayTrigger
            key={index}
            placement="top"
            overlay={
              <Tooltip id={"tracking-criterion-"}>
                {subtopicTrackingCriteriaOptions[criterion]["description"]}
              </Tooltip>
            }
          >
            <span>
              <GVDSButton
                variant={buttonVariant.secondary}
                className="mx-2 tracking-criterion-selector"
                onClick={() => onAddingTrackingCriteria(criterion)}
                text={criterion}
              />
            </span>
          </OverlayTrigger>
        );
      });
    }

    return (
      <>
        <div>
          <Form.Label className="my-2">
            {t(
              "data-management.initiatives.input.tracking-criteria.label-select-criterion"
            )}
          </Form.Label>
        </div>
        {criteriaSelectors}
      </>
    );
  };

  const getTrackingMeterDisplay = (
    criterion,
    trackingMeter,
    meterIndex,
    isNotAccountingInformation,
    isNotOtherCriterion
  ) => {
    const detailOptions = subtopicTrackingCriteriaOptions[criterion]["options"];

    return (
      <tr key={`${criterion}-meter-${meterIndex}`}>
        {Object.keys(trackingMeter)
          .sort(InitiativeService.initiativeTrackingCriteriaFieldColumnSortFn)
          .map((field, fieldIndex) => {
            let content = null;
            const key = `${criterion}-meter-${meterIndex}-${field}`;
            if (field === INITIATIVE_TRACKING_FIELD.SOURCE) {
              content = (
                <SourceSetupSelector
                  controlId={`source-${fieldIndex}`}
                  trackingMeter={trackingMeter}
                  field={field}
                  sourceOptions={
                    isNotAccountingInformation
                      ? detailOptions?.sources
                      : detailOptions?.sources.sort(
                          InitiativeService.initiativeAccountingInfoSourceSortFn
                        )
                  }
                  onChangeTrackingMeter={onChangeMeterField}
                  disabled={disabled || !isNotAccountingInformation}
                  isValidated={isValidated}
                />
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.DESCRIPTOR) {
              content = (
                <GVDSFormField
                  type="text"
                  value={trackingMeter[field]}
                  onInput={(value) => {
                    trackingMeter[field] = value;
                    onChangeMeterField();
                  }}
                  disabled={disabled}
                />
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.UNIT) {
              let unitOptions = [];

              if (trackingMeter.source && trackingMeter.source.name) {
                if (
                  trackingMeter.source.name ===
                    INITIATIVE_EXCEPTION_SOURCE.ENERGY &&
                  trackingMeter[INITIATIVE_TRACKING_FIELD.ENV_TYPE]
                ) {
                  unitOptions = energyTypes.find(
                    (t) =>
                      t.id ===
                      trackingMeter[INITIATIVE_TRACKING_FIELD.ENV_TYPE].id
                  ).units;
                } else {
                  unitOptions =
                    detailOptions.units_by_source[trackingMeter.source.name];
                }
              }

              content = (
                <UnitSetupSelector
                  controlId={`unit-${fieldIndex}`}
                  trackingMeter={trackingMeter}
                  field={field}
                  unitOptions={unitOptions}
                  onChangeTrackingMeter={onChangeMeterField}
                  disabled={disabled}
                  isValidated={isValidated}
                />
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.QUANTITY) {
              content = (
                <GVFormGroup controlId={`${key}-quantity`}>
                  <GVDSFormField
                    type="number"
                    placeholder="Quantity"
                    name={field}
                    value={
                      trackingMeter[field] === null ? "" : trackingMeter[field]
                    }
                    onInput={(value, e) => onChangeInputField(e, trackingMeter)}
                    statusMetadata={
                      isValidated &&
                      !InitiativeModel.isValueValid(trackingMeter[field])
                        ? FormFieldStatusMetadata.getError(
                            "Non-negative value only."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    disabled={disabled}
                  />
                </GVFormGroup>
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.ENV_TYPE) {
              if (
                trackingMeter.source &&
                trackingMeter.source.name === INITIATIVE_EXCEPTION_SOURCE.ENERGY
              ) {
                content = (
                  <div>
                    <GVDSFormSingleSelect
                      placeholder="Select Type"
                      value={
                        trackingMeter[field]
                          ? {
                              value: trackingMeter[field].id,
                              label: trackingMeter[field].name,
                            }
                          : null
                      }
                      options={energyTypes.map((energyType) => {
                        return { value: energyType.id, label: energyType.name };
                      })}
                      onSelect={(selectedOption) => {
                        trackingMeter[field] = {
                          id: selectedOption.value,
                          name: selectedOption.label,
                        };
                        onChangeMeterField();
                      }}
                      disabled={disabled}
                    />
                  </div>
                );
              } else {
                content = (
                  <div>
                    <span className="caption fst-italic">-not required-</span>
                  </div>
                );
              }
            } else if (field === INITIATIVE_TRACKING_FIELD.CURRENCY) {
              content = (
                <div>
                  <SelectCurrencyCompact
                    currencies={currencies}
                    selectedCurrencyId={trackingMeter.currency}
                    onCurrencySelected={(newCurrencyId) => {
                      trackingMeter.currency = newCurrencyId;
                      onChangeMeterField();
                    }}
                    disabled={disabled}
                    isInvalid={isValidated && !trackingMeter.currency}
                  />
                </div>
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.VALUE) {
              content = (
                <GVFormGroup controlId={`${key}-value`}>
                  <GVDSFormField
                    type="number"
                    placeholder="Value"
                    name={field}
                    value={
                      trackingMeter[field] === null ? "" : trackingMeter[field]
                    }
                    onInput={(value, e) => onChangeInputField(e, trackingMeter)}
                    statusMetadata={
                      isValidated &&
                      !InitiativeModel.isValueValid(trackingMeter[field])
                        ? FormFieldStatusMetadata.getError(
                            "Non-negative value only."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    disabled={disabled}
                  />
                </GVFormGroup>
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.PARTICIPANT) {
              content = (
                <GVFormGroup controlId={`${key}-participant`}>
                  <GVDSFormField
                    type="number"
                    placeholder="No. of participants"
                    name={field}
                    value={
                      trackingMeter[field] === null ? "" : trackingMeter[field]
                    }
                    onInput={(value, e) => onChangeInputField(e, trackingMeter)}
                    statusMetadata={
                      isValidated &&
                      !InitiativeModel.isValueValid(trackingMeter[field])
                        ? FormFieldStatusMetadata.getError(
                            "Non-negative value only."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    disabled={disabled}
                  />
                </GVFormGroup>
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.VOLUNTEER_HOURS) {
              content = (
                <GVFormGroup controlId={`${key}-volunteer-hours`}>
                  <GVDSFormField
                    type="number"
                    placeholder="Total volunteer hours"
                    name={field}
                    value={
                      trackingMeter[field] === null ? "" : trackingMeter[field]
                    }
                    onInput={(value, e) => onChangeInputField(e, trackingMeter)}
                    statusMetadata={
                      isValidated &&
                      !InitiativeModel.isValueValid(trackingMeter[field])
                        ? FormFieldStatusMetadata.getError(
                            "Non-negative value only."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    disabled={disabled}
                  />
                </GVFormGroup>
              );
            } else if (
              field === INITIATIVE_TRACKING_FIELD.VOLUNTEER_HOURLY_RATE
            ) {
              content = (
                <GVFormGroup controlId={`${key}-volunteer-hourly-rate`}>
                  <GVDSFormField
                    type="number"
                    placeholder="Volunteer hourly rate"
                    name={field}
                    value={
                      trackingMeter[field] === null ? "" : trackingMeter[field]
                    }
                    onInput={(value, e) => onChangeInputField(e, trackingMeter)}
                    statusMetadata={
                      isValidated &&
                      !InitiativeModel.isValueValid(trackingMeter[field])
                        ? FormFieldStatusMetadata.getError(
                            "Non-negative value only."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    disabled={disabled}
                  />
                </GVFormGroup>
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.ANNUAL_REDUCTION) {
              content = (
                <GVFormGroup controlId={`${key}-annual-reduction`}>
                  <GVDSFormField
                    type="number"
                    placeholder="Estimated reduction"
                    name={field}
                    value={
                      trackingMeter[field] === null ? "" : trackingMeter[field]
                    }
                    onInput={(value, e) => onChangeInputField(e, trackingMeter)}
                    statusMetadata={
                      isValidated &&
                      !InitiativeModel.isValueValid(trackingMeter[field])
                        ? FormFieldStatusMetadata.getError(
                            "Non-negative value only."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    disabled={disabled}
                  />
                </GVFormGroup>
              );
            } else if (field === INITIATIVE_TRACKING_FIELD.COMMENT) {
              content = (
                <GVFormGroup controlId={`${key}-comment`}>
                  <GVDSFormTextArea
                    rows={3}
                    placeholder="Enter other results that cannot be captured in any of the other criterias"
                    name={field}
                    value={
                      trackingMeter[field] === null ? "" : trackingMeter[field]
                    }
                    onInput={(value, e) => onChangeInputField(e, trackingMeter)}
                    disabled={disabled}
                  />
                </GVFormGroup>
              );
            }
            return <td key={`${criterion}-source-${fieldIndex}`}>{content}</td>;
          })}
        {!disabled && isNotAccountingInformation && isNotOtherCriterion && (
          <td className="initiative__action-column">
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              icon={<GVDSIcon Icon={IconTrash} />}
              onClick={(e) => {
                e.preventDefault();
                onDeletingTrackingMeter(criterion, trackingMeter);
              }}
            />
          </td>
        )}
      </tr>
    );
  };
  if (subtopicTrackingCriteriaOptions === null) {
    return (
      <div className="initiative__locked-content">
        {t(
          "data-management.initiatives.input.tracking-criteria.locked-until-subtopic-selected"
        )}
      </div>
    );
  }

  return (
    <div>
      {getTrackingCriteriaSelector()}
      {Object.keys(trackingCriteriaRecord)
        .sort(InitiativeService.initiativeTrackingCriteriaSortFn)
        .map((criterion) => {
          const description =
            subtopicTrackingCriteriaOptions[criterion]["description"];
          const detailOptions =
            subtopicTrackingCriteriaOptions[criterion]["options"];
          const isNotAccountingInformation =
            criterion !== INITIATIVE_TRACKING_CRITERION.ACCOUNTING_INFORMATION;
          const isNotOtherCriterion =
            criterion !== INITIATIVE_TRACKING_CRITERION.OTHER;

          const criterionDisplay =
            criterion === INITIATIVE_TRACKING_CRITERION.ACCOUNTING_INFORMATION
              ? "Financial Impact"
              : criterion;

          return (
            <div
              className="initiative__tracking-criterion-container"
              key={criterion}
            >
              <div className="d-flex flex-row align-items-center mb-3">
                <div>
                  <div className="gvds-text--heading4">{criterionDisplay}</div>
                  <div>{description}</div>
                </div>
                <div className="ms-auto">
                  {!disabled && isNotAccountingInformation && (
                    <GVDSButton
                      variant={buttonVariant.destructive_tertiary}
                      icon={<GVDSIcon Icon={IconTrash} />}
                      onClick={(e) => {
                        e.preventDefault();
                        onDeletingTrackingCriteria(criterion);
                      }}
                      text="Remove"
                    />
                  )}
                </div>
              </div>

              <GVDSTableDisplay>
                <thead>
                  <tr>
                    {detailOptions?.fields
                      .sort(
                        InitiativeService.initiativeTrackingCriteriaFieldColumnSortFn
                      )
                      .map((field) => (
                        <th
                          key={field}
                          style={{
                            textTransform: "capitalize",
                            width: getFieldWidth(criterion, field),
                          }}
                        >
                          {field === INITIATIVE_TRACKING_FIELD.ENV_TYPE
                            ? "type"
                            : field === INITIATIVE_TRACKING_FIELD.PARTICIPANT
                            ? "No. of Participants"
                            : field}{" "}
                          {(field === INITIATIVE_TRACKING_FIELD.ENV_TYPE ||
                            field === INITIATIVE_TRACKING_FIELD.DESCRIPTOR) && (
                            <span className="optional-form-label">
                              ({t("shared-input-label.optional")})
                            </span>
                          )}
                          {field ===
                            INITIATIVE_TRACKING_FIELD.VOLUNTEER_HOURS && (
                            <InfoTooltip
                              info={
                                <>
                                  <Trans i18nKey="data-management.initiatives.input.tracking-criteria.label-description-volunteer-hours">
                                    <em>Volunteer Hours</em> refers to the total
                                    number of hours for the whole volunteer
                                    event, i.e. sum of the number of hours
                                    completed by each volunteer.
                                  </Trans>
                                </>
                              }
                              placement="top"
                            />
                          )}
                        </th>
                      ))}
                    {!disabled &&
                      isNotAccountingInformation &&
                      isNotOtherCriterion && (
                        <th style={{ width: "50px" }}>
                          {t("shared-input-label.actions")}
                        </th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {trackingCriteriaRecord[criterion].map(
                    (trackingMeter, meterIndex) => {
                      return getTrackingMeterDisplay(
                        criterion,
                        trackingMeter,
                        meterIndex,
                        isNotAccountingInformation,
                        isNotOtherCriterion
                      );
                    }
                  )}
                </tbody>
              </GVDSTableDisplay>

              {!disabled &&
                isNotAccountingInformation &&
                isNotOtherCriterion && (
                  <GVDSButton
                    className="mt-1"
                    variant={buttonVariant.secondary}
                    onClick={() => onAddingTrackingMeter(criterion)}
                    text={`Add ${criterion}`}
                  />
                )}
            </div>
          );
        })}
    </div>
  );
};
export default TrackingCriteriaForm;
