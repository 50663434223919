import Form from "react-bootstrap/Form";
import React, { useContext, useRef } from "react";
import { CertificationAssessmentService } from "../../../services/CertificationService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import CertificationContext from "../CertificationContext";
import ToastContext from "../../../context/ToastContext";

const debounceWait = 1000;

const CertificationSubrequirementDisplay = ({
  selectedRequirement,
  subRequirement,
  completeCheckboxMinWidth,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const certificationContext = useContext(CertificationContext);

  const saveSubRequirementCompleteTimer = useRef(null);
  const isSubRequirementCompleteOldValue = useRef(null);

  const debounceOnSubRequirementCompleteChange = (sub) => {
    const currentInventory = userInventory.selectedInventory.get;
    const isCompleteNewValue = !sub.isCompleted;

    if (isSubRequirementCompleteOldValue.current === null) {
      isSubRequirementCompleteOldValue.current = sub.isCompleted;
    }

    clearTimeout(saveSubRequirementCompleteTimer.current);
    sub.isCompleted = isCompleteNewValue;
    certificationContext.refreshCertification();

    saveSubRequirementCompleteTimer.current = setTimeout(() => {
      CertificationAssessmentService.saveSubrequirementAssessment(
        currentInventory.type,
        currentInventory.id,
        certificationContext.certificationId,
        selectedRequirement.id,
        sub.id,
        isCompleteNewValue
      )
        .then(() => {
          isSubRequirementCompleteOldValue.current = null;
        })
        .catch(() => {
          if (sub.isCompleted !== isSubRequirementCompleteOldValue.current) {
            sub.isCompleted = isSubRequirementCompleteOldValue.current;
            certificationContext.refreshCertification();
          }
          toastContext.addFailToast(
            <span>Failed to update sub-requirement.</span>
          );
        });
    }, debounceWait);
  };

  return (
    <div className="content__details__sub-requirement">
      <div
        className="d-flex justify-content-center"
        style={{ minWidth: `${completeCheckboxMinWidth}px` }}
      >
        <Form.Check
          type="checkbox"
          label=""
          disabled={!selectedRequirement.isApplicable}
          checked={subRequirement.isCompleted}
          onChange={() =>
            debounceOnSubRequirementCompleteChange(subRequirement)
          }
        />
      </div>
      <div className="sub-requirement__content">{subRequirement.content}</div>
    </div>
  );
};

export default CertificationSubrequirementDisplay;
