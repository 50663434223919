import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "./GVDSFormShared";
import { TextareaUtils } from "../../services/UtilsService";

const GVDSFormTextArea = React.forwardRef(
  (
    {
      value,
      onInput,
      name,
      disabled = false,
      plaintext = false,
      readOnly = false,
      className = "",
      rows = 3,
      autoAdjustHeight = false,
      maxLength = null,
      placeholder = null,
      statusMetadata = FormFieldStatusMetadata.getDefault(),
      ...props
    },
    ref
  ) => {
    const gvdsClassNames = ["gvds-form__textarea"].concat(
      statusMetadata.state === FormFieldState.warning
        ? ["gvds-form__warning"]
        : statusMetadata.state === FormFieldState.error
        ? ["gvds-form__error"]
        : []
    );
    const combinedClassNames = `${gvdsClassNames.join(" ")} ${className}`;

    useEffect(() => {
      if (autoAdjustHeight && ref && ref.current && value) {
        TextareaUtils.dynamicallyResizeTextarea(ref, ref.current);
      }
    }, []);

    return (
      <>
        <Form.Control
          ref={ref}
          as="textarea"
          name={name}
          value={value || ""}
          className={combinedClassNames}
          disabled={disabled}
          readOnly={readOnly}
          plaintext={plaintext}
          maxLength={maxLength}
          rows={rows}
          placeholder={placeholder}
          onChange={(e) => {
            onInput(e.target.value, e);
            if (autoAdjustHeight) {
              TextareaUtils.dynamicallyResizeTextarea(ref, e.target);
            }
          }}
          {...props}
        />
        {statusMetadata.isNotNormal() && statusMetadata.message && (
          <GVDSFormErrorMessage
            status={statusMetadata.state}
            errorMsg={statusMetadata.message}
          />
        )}
      </>
    );
  }
);

export default GVDSFormTextArea;
