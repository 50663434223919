import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../context/ToastContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PortalAdminEnterpriseSignUpService } from "../../../services/PortalAdmin/PortalAdminSignUpReviewService";
import { SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS } from "../../../config/ROUTES_NAME";
import LoadingSpinner from "../../common/LoadingSpinner";
import { DateTimeUtils } from "../../../services/UtilsService";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import withAuthentication from "../../HOC/withAuthentication";
import {
  getPathWithTabKey,
  getRedirectURLWithCurrentParam,
} from "../../common/QueryHandler";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import { DropdownOptions } from "../../Site/SiteFacilityInformationComponents";

const EnterpriseSignUpSiteForm = ({ signUpModel }) => {
  return (
    <div className="admin-signup-form-review">
      <h2>Sign Up Form</h2>
      <div className="section-label">User Info</div>
      <section>
        <GVFormGroup controlId="emailAddress">
          <Form.Label>Company Email Address</Form.Label>
          <Form.Control
            type="text"
            name="emailAddress"
            readOnly={true}
            plaintext={true}
            disabled={true}
            value={signUpModel.emailAddress}
          />
        </GVFormGroup>
        <Row>
          <Col>
            <GVFormGroup controlId="username">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                readOnly={true}
                plaintext={true}
                disabled={true}
                value={signUpModel.userFullName}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup controlId="companyName">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={signUpModel.companyName}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
        </Row>
      </section>

      <div className="section-label">Site Information</div>
      <section>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>Site Name</Form.Label>
              <Form.Control
                type="text"
                name="siteName"
                value={signUpModel.siteName}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <DropdownOptions
                label="Site Type"
                targetName="siteType"
                inEditMode={false}
                value={signUpModel.siteType}
              />
            </GVFormGroup>
          </Col>
        </Row>

        <GVFormGroup>
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            name="streetAddress"
            value={signUpModel.streetAddress || ""}
            readOnly={true}
            plaintext={true}
            disabled={true}
          />
        </GVFormGroup>

        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>City</Form.Label>
              <Form.Control
                name="city"
                value={signUpModel.city || ""}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                name="zipCode"
                value={signUpModel.zipCode || ""}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>State / Province</Form.Label>
              <Form.Control
                name="stateOrProvince"
                value={signUpModel.stateOrProvince || ""}
                readOnly={true}
                plaintext={true}
                disabled={true}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <DropdownOptions
                label="Country"
                inEditMode={false}
                targetName="country"
                value={signUpModel.country}
              />
            </GVFormGroup>
          </Col>
        </Row>
      </section>
    </div>
  );
};

const ViewEnterpriseSignupRequest = () => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();
  const location = useLocation();
  const { signupId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [signUpRequestModel, setSignUpRequestModel] = useState(null);

  useEffect(() => {
    if (signupId) {
      setIsLoading(true);
      PortalAdminEnterpriseSignUpService.getEnterpriseSignUpRequest(signupId)
        .then((signUpRequest) => {
          setSignUpRequestModel(signUpRequest);
          setIsLoading(false);
        })
        .catch(() => {
          toastContext.addFailToast(
            "Failed to load enterprise sign up request"
          );
        });
    }
  }, [signupId]);

  const goToSignupRequestList = () => {
    history.push(
      getPathWithTabKey(
        getRedirectURLWithCurrentParam(
          SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS,
          location
        ),
        "enterprise-inquiry"
      )
    );
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text="Return to All Sign-Up Request List"
          onClick={goToSignupRequestList}
        />
        <PageHeader.Title>
          <h1>
            Inquiry from{" "}
            {signUpRequestModel?.enterpriseSignUpFormModel?.companyName}
          </h1>
        </PageHeader.Title>
        <PageHeader.Status>
          <div>
            <div className="info-field">
              Request Date:{" "}
              <span style={{ fontWeight: "normal" }}>
                {DateTimeUtils.formatUTCDate(signUpRequestModel.requestDate)}
              </span>
            </div>
          </div>
        </PageHeader.Status>
      </PageHeader>
      <hr />
      <Container fluid>
        <Row>
          <Col lg={6} sm={12}>
            <GVFormGroup>
              <Form.Label>Email Address</Form.Label>
              <div>
                <a
                  href={`mailto:${signUpRequestModel?.enterpriseSignUpFormModel?.emailAddress}`}
                >
                  {signUpRequestModel?.enterpriseSignUpFormModel?.emailAddress}
                </a>
              </div>
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>No. Of Properties</Form.Label>
              <Form.Control
                type="number"
                name="noOfProperties"
                readOnly={true}
                plaintext={true}
                disabled={true}
                value={
                  signUpRequestModel?.enterpriseSignUpFormModel?.noOfProperties
                }
              />
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>Business Needs</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="businessNeeds"
                readOnly={true}
                plaintext={true}
                disabled={true}
                rows={3}
                value={
                  signUpRequestModel?.enterpriseSignUpFormModel?.businessNeeds
                }
              />
            </GVFormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <EnterpriseSignUpSiteForm
              signUpModel={
                signUpRequestModel
                  ? signUpRequestModel.enterpriseSignUpFormModel
                  : {}
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withAuthentication(ViewEnterpriseSignupRequest);
