import React, { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import PeopleService from "../../../services/PeopleService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";

const DeletePeopleMeterModal = ({ meterId, showModal, onClose, onSuccess }) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [hasRecords, setHasRecords] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (showModal && meterId && selectedInventory?.id) {
      setIsLoading(true);
      PeopleService.getMeterStatus(meterId, selectedInventory.id)
        .then((data) => {
          setIsLoading(false);
          setHasRecords(data.has_records);
        })
        .catch((e) => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to load meter status.</span>);
        });
    }
  }, [meterId, showModal, selectedInventory]);

  const onDelete = () => {
    setIsLoading(true);
    PeopleService.deleteMeter(meterId, selectedInventory?.id)
      .then(() => {
        setIsLoading(false);
        toastContext.addSuccessToast(
          <span>Stopped tracking subtopic successfully.</span>
        );
        onSuccess();
        onClose();
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed stop tracking subtopic.</span>);
      });
  };

  if (!showModal) {
    return null;
  }

  if (isLoading) {
    return (
      <GVDSModal
        title="Stop tracking subtopic"
        size={GVDSModal.Size.small}
        show={showModal}
      >
        <GVDSModal.Body>
          <LoadingSpinner />
        </GVDSModal.Body>
      </GVDSModal>
    );
  }

  if (hasRecords) {
    return (
      <GVDSModal
        title="Unable to stop tracking subtopic"
        size={GVDSModal.Size.small}
        show={showModal}
        onHide={onClose}
      >
        <GVDSModal.Body>
          <div>This subtopic currently has associated data records.</div>
          <br />
          <div>
            In order to stop tracking this subtopic, you must first delete all
            of its associated existing records on the ”Subtopic” page or in the
            “History” page.
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={onClose}
            text="Okay"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    );
  }

  return (
    <GVDSModal
      title="Stop tracking subtopic"
      size={GVDSModal.Size.small}
      show={showModal}
      onHide={onClose}
    >
      <GVDSModal.Body>
        <div>There are not associated data records for this subtopic.</div>
        <br />
        <div>
          When you stop tracking this subtopic, you will lose its setup
          configuration, are you sure?
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onClose}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          onClick={onDelete}
          text="Yes, stop tracking"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default DeletePeopleMeterModal;
