import React from "react";
import GVDSInfoCard from "../../gvds-components/common/GVDSInfoCard";

const ReportLegend = () => (
  <GVDSInfoCard
    title="How to Interpret Special Formatting in your Report"
    className="mt-2"
  >
    <ul>
      <li>
        Data with incomplete values are represented in{" "}
        <span className="body-1-bold color-red">red</span>
      </li>
      <li>
        Data with no available values are represented with a{" "}
        <span className="body-1-bold">-</span>
      </li>
      <li>
        Values that are not included in aggregation are{" "}
        <span className="body-1-bold color-gray56">faded out</span>
      </li>
      <li>
        Values that cannot be calculated are represented with an{" "}
        <span className="body-1-bold">N/A</span>
      </li>
    </ul>
  </GVDSInfoCard>
);
export default ReportLegend;

export const EmissionReportingMethodBlurb = () => (
  <div>
    <p>
      <strong>Location-based emissions</strong> reflects the average emissions
      intensity of grids on which energy consumption occurs.
    </p>
    <div>
      <strong>Market-based emissions</strong> takes into account the specific
      choices that companies make when they purchase energy, such as having
      market purchases and custom supplier-specific emission factors.
    </div>
  </div>
);
