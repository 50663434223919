import React, { useState } from "react";

const TabsContext = React.createContext(null);

export const TabsProvider = ({ children }) => {
  const [actionsBeforeChangeTabByEventKey, setActionsBeforeChangeTabByEventKey] = useState({});

  const getFunctionByEventKey = (eventKey) => {
    return actionsBeforeChangeTabByEventKey[eventKey]
  }

  const registerListener = (eventKey, listenerFn) => {
    setActionsBeforeChangeTabByEventKey({ ...actionsBeforeChangeTabByEventKey, [eventKey]: listenerFn });
  };

  const unregisterListener = (eventKey) => {
    delete actionsBeforeChangeTabByEventKey[eventKey];
    setActionsBeforeChangeTabByEventKey(actionsBeforeChangeTabByEventKey);
  };

  const store = {
    getFunctionByEventKey: getFunctionByEventKey,
    registerListener: registerListener,
    unregisterListener: unregisterListener
  };

  return (
    <TabsContext.Provider value={store}>
      {children}
    </TabsContext.Provider>
  );
};

export default TabsContext;