import React, { useContext, useEffect, useState } from "react";
import UserInventoryContext from "../../context/UserInventoryContext";
import { useHistory, useLocation } from "react-router-dom";
import UserProfileContext from "../../context/UserProfileContext";
import NavWrapperContext from "../Nav/NavWrapperContext";
import {
  RESOURCES,
  subscriptionPaymentCompletionStatus,
} from "../../config/constants";
import PaymentInstructionModal from "../../components/Admin/Billing/PaymentInstructionModal";
import subscriptionMessageCtas from "./AnnouncementBarSubscriptionMessageCtaModel";

const AnnouncementBarContext = React.createContext(null);

export class AnnouncementUtils {
  static isSubscriptionFullyPaid(paymentStatus) {
    return paymentStatus === subscriptionPaymentCompletionStatus.fullyPaid;
  }

  static isSubscriptionPartiallyPaid(paymentStatus) {
    return paymentStatus === subscriptionPaymentCompletionStatus.partiallyPaid;
  }

  static isSubscriptionUnpaid(paymentStatus) {
    return paymentStatus === subscriptionPaymentCompletionStatus.unpaid;
  }
}

export const getAnnouncementMessageAndCta = (
  history,
  location,
  inventoryType,
  inventorySubscriptions,
  isBillingAdmin = false,
  setShowPaymentInstruction,
  setPaymentLink
) => {
  const past = inventorySubscriptions
    ? inventorySubscriptions["past"] ?? []
    : [];
  const current = inventorySubscriptions
    ? inventorySubscriptions["current"] ?? []
    : [];
  const upcoming = inventorySubscriptions
    ? inventorySubscriptions["upcoming"] ?? []
    : [];

  const currentSubPaidCompleted = current.find(
    (s) =>
      !s.is_free_subscription &&
      !AnnouncementUtils.isSubscriptionUnpaid(s.payment_completion_status)
  );
  const currentSubPaymentPendingWithAccess = current.find(
    (s) =>
      !s.is_free_subscription &&
      AnnouncementUtils.isSubscriptionUnpaid(s.payment_completion_status) &&
      s.days_left_for_access > 0
  );
  const currentTrialSub = current.find((s) => s.is_free_subscription);
  const currentPartiallyPaid = current.find((s) =>
    AnnouncementUtils.isSubscriptionPartiallyPaid(s.payment_completion_status)
  );

  const hasCurrentAccess =
    current.length > 0 &&
    (currentSubPaidCompleted ||
      currentSubPaymentPendingWithAccess ||
      currentTrialSub);

  if (hasCurrentAccess) {
    const hasSiteTrialAndContractPaidAccess =
      currentTrialSub &&
      currentSubPaidCompleted &&
      !currentTrialSub.is_from_contract &&
      currentSubPaidCompleted.is_from_contract;

    if (
      currentSubPaidCompleted &&
      !hasSiteTrialAndContractPaidAccess &&
      !currentPartiallyPaid
    ) {
      return null;
    } else if (currentPartiallyPaid && isBillingAdmin) {
      return subscriptionMessageCtas.paymentPendingDecentralisedContract(
        history,
        location
      );
    } else if (currentSubPaymentPendingWithAccess) {
      if (isBillingAdmin) {
        const isDecentralised =
          inventoryType === RESOURCES.PORTFOLIO &&
          currentSubPaymentPendingWithAccess.is_from_contract &&
          currentSubPaymentPendingWithAccess.is_decentralised;

        if (isDecentralised) {
          return subscriptionMessageCtas.paymentPendingDecentralisedContract(
            history,
            location
          );
        } else {
          return subscriptionMessageCtas.paymentPending(
            currentSubPaymentPendingWithAccess,
            setShowPaymentInstruction,
            setPaymentLink
          );
        }
      } else {
        return null;
      }
    } else if (currentTrialSub) {
      const futureSubUnpaid = upcoming.find(
        (s) =>
          !s.is_free_subscription &&
          AnnouncementUtils.isSubscriptionUnpaid(s.payment_completion_status)
      );

      if (futureSubUnpaid) {
        return subscriptionMessageCtas.currentlyInTrialWithUnpaidSub(
          currentTrialSub,
          history,
          location
        );
      } else {
        return subscriptionMessageCtas.currentlyInTrial(
          currentTrialSub,
          isBillingAdmin,
          history,
          location
        );
      }
    }
  } else {
    const currentSubPaymentPendingNoAccess = current.find(
      (s) =>
        !s.is_free_subscription &&
        AnnouncementUtils.isSubscriptionUnpaid(s.payment_completion_status) &&
        s.days_left_for_access <= 0
    );

    const futureSubPaymentPending = upcoming.find(
      (s) =>
        !s.is_free_subscription &&
        AnnouncementUtils.isSubscriptionUnpaid(s.payment_completion_status)
    );

    if (currentSubPaymentPendingNoAccess || futureSubPaymentPending) {
      const pendingSubscription =
        currentSubPaymentPendingNoAccess ?? futureSubPaymentPending;

      if (!isBillingAdmin) {
        return subscriptionMessageCtas.noActiveSubscription(
          false,
          isBillingAdmin,
          history,
          location
        );
      } else {
        if (!pendingSubscription.is_custom_plan) {
          if (pendingSubscription.is_first_subscription) {
            return subscriptionMessageCtas.paymentPending(
              pendingSubscription,
              setShowPaymentInstruction,
              setPaymentLink
            );
          } else {
            return subscriptionMessageCtas.noActiveSubscription(
              false,
              isBillingAdmin,
              history,
              location
            );
          }
        } else {
          // custom plan
          if (inventoryType === RESOURCES.PORTFOLIO) {
            if (!pendingSubscription.is_decentralised) {
              return subscriptionMessageCtas.noActiveSubscription(
                true,
                isBillingAdmin,
                history,
                location
              );
            } else {
              return subscriptionMessageCtas.paymentPendingDecentralisedContract(
                history,
                location
              );
            }
          } else {
            if (
              pendingSubscription.is_from_contract &&
              pendingSubscription.is_decentralised
            ) {
              return subscriptionMessageCtas.paymentPending(
                pendingSubscription,
                setShowPaymentInstruction,
                setPaymentLink
              );
            }
          }
        }
      }
    }

    const pastPaidSub = past.find(
      (s) =>
        !s.is_free_subscription &&
        !AnnouncementUtils.isSubscriptionUnpaid(s.payment_completion_status)
    );

    if (
      (pastPaidSub && upcoming.length === 0) ||
      (past.length === 0 && upcoming.length === 0)
    ) {
      return subscriptionMessageCtas.noActiveSubscription(
        false,
        isBillingAdmin,
        history,
        location
      );
    }

    const pastTrialSub = past.find((s) => s.is_free_subscription);

    if (pastTrialSub) {
      return subscriptionMessageCtas.pastTrial(
        pastTrialSub,
        isBillingAdmin,
        history,
        location
      );
    } else {
      return subscriptionMessageCtas.noActiveSubscription(
        inventoryType === RESOURCES.PORTFOLIO,
        isBillingAdmin,
        history,
        location
      );
    }
  }

  return null;
};

export const AnnouncementBarProvider = (props) => {
  const history = useHistory();
  const location = useLocation();
  const navWrapperContext = useContext(NavWrapperContext);
  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const [show, setShow] = useState(false);
  const [messageAndCta, setMessageAndCta] = useState(null);

  const [showPaymentInstruction, setShowPaymentInstruction] = useState(false);
  const [paymentLink, setPaymentLink] = useState(false);

  useEffect(() => {
    const selectedTreeNode = userInventory.selectedTreeNode.get;
    const inventoryDetails = selectedTreeNode?.nodeValue?.value;
    const inventoryType = userInventory.selectedInventory.get?.type;

    if (inventoryDetails) {
      const messageAndCta = getAnnouncementMessageAndCta(
        history,
        location,
        inventoryType,
        inventoryDetails.subscriptions,
        user.id === inventoryDetails.billing_admin_id || user?.is_admin,
        setShowPaymentInstruction,
        setPaymentLink
      );

      if (messageAndCta) {
        setMessageAndCta(messageAndCta);
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, [userInventory.selectedInventory.get]);

  const store = {
    show: !navWrapperContext.isSystemToolbox && show,
    isDismissible: messageAndCta?.dismissible,
    dismiss: () => setShow(false),
    message: messageAndCta?.message,
    cta: messageAndCta?.cta,
    ctaAction: messageAndCta?.ctaAction,
  };

  return (
    <AnnouncementBarContext.Provider value={store}>
      {props.children}
      {showPaymentInstruction && (
        <PaymentInstructionModal
          onClose={() => {
            setShowPaymentInstruction(false);
          }}
          paymentLink={paymentLink}
        />
      )}
    </AnnouncementBarContext.Provider>
  );
};

export default AnnouncementBarContext;
