import React from "react";
import GVDSButton, { buttonVariant } from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";

const DeleteCertificationPrompt = ({
  show,
  cancel,
  deleteCertification,
  proceed,
}) => {
  const messageBody = (
    <>
      {!deleteCertification && (
        <div>
          <p>
            This cannot be deleted as it is currently in use. To delete this,
            remove all associations with it in the portal.
          </p>
        </div>
      )}
      {deleteCertification && (
        <div>
          <p>
            Fields with this selection option will return to default. This
            action cannot be undone. Are you sure?
          </p>
        </div>
      )}
    </>
  );

  return (
    <GVDSModal
      show={show}
      onHide={cancel}
      title={deleteCertification
        ? "Delete Certification"
        : "Unable to Delete Certification"}
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>{messageBody}</GVDSModal.Body>
      <GVDSModal.Footer>
        {deleteCertification && (
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancel}
            text="Cancel"
          />
        )}
        {deleteCertification ? (
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={proceed}
            text="Yes, Delete."
          />
        ) : (
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={cancel}
            text="Okay"
          />
        )}
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default DeleteCertificationPrompt;
