import React from "react";
import moment from "moment";
import { withParentSize } from "@visx/responsive";
import PerformanceReportChartService, {
  customTheme,
  PERFORMANCE_CHART_COLOR,
} from "./PerformanceReportChartService";
import { scaleOrdinal } from "@visx/scale";
import {
  Axis,
  GlyphSeries,
  Grid,
  LineSeries,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import { Text } from "@visx/text";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { NumberService } from "../../../../services/UtilsService";
import { SUBTOPIC_TYPE } from "../../../../config/constants";

const accessors = {
  xAccessor: (d) => d?.x,
  yAccessor: (d) => d?.y,
};

const chartTitleDescription = (
  parentWidth,
  useLocationBased,
  isSingleType,
  singleTypeEnvSubtopicName
) => {
  let message;

  if (!isSingleType) {
    if (!useLocationBased) {
      return null;
    }

    message = "Market purchases are not reflected on this chart";
  } else {
    if (singleTypeEnvSubtopicName === SUBTOPIC_TYPE.ENERGY) {
      message = useLocationBased
        ? "This chart only reflects the Scope 1 or 2 emissions, and do not include market purchase."
        : "This chart only reflects the Scope 1 or 2 emissions.";
    } else if (singleTypeEnvSubtopicName === SUBTOPIC_TYPE.WASTE) {
      message = useLocationBased
        ? "This chart only reflects the Scope 3 Waste emissions, and do not include market purchase."
        : "This chart only reflects the Scope 3 Waste emissions.";
    } else {
      return null;
    }
  }

  return (
    <Text
      x={parentWidth / 2}
      dy={30}
      width={parentWidth}
      verticalAnchor="start"
      textAnchor="middle"
      style={{
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19px",
        fontStyle: "italic",
      }}
    >
      {message}
    </Text>
  );
};

const PerformanceReportLineChart = withParentSize(
  ({
    valueType,
    report,
    reportMonths,
    parentWidth,
    parentHeight,
    showAsPercentage = false,
    useLocationBased = false,
    isSingleType,
    singleTypeEnvSubtopicName,
  }) => {
    const reportYears = PerformanceReportChartService.getReportYears(report);
    const yearlyData = PerformanceReportChartService.getYearlyData(
      valueType,
      report,
      reportYears,
      reportMonths,
      showAsPercentage
    );
    const missingData = PerformanceReportChartService.getMissingData(
      valueType,
      report,
      reportYears,
      reportMonths,
      showAsPercentage
    );
    const ordinalColorScale = scaleOrdinal({
      domain: reportYears,
      range: customTheme.colors,
    });
    return (
      <>
        <XYChart
          height={parentHeight}
          xScale={{ type: "time" }}
          yScale={{ type: "linear" }}
          theme={customTheme}
        >
          <Text
            x={parentWidth / 2}
            dy={5}
            width={parentWidth}
            verticalAnchor="start"
            textAnchor="middle"
            className="performance-report__chart-table-title"
          >
            {`${
              report["title"]
            } ${PerformanceReportChartService.getTitleDetails(
              valueType,
              report["column_groups"]
            )}`}
          </Text>
          {chartTitleDescription(
            parentWidth,
            useLocationBased,
            isSingleType,
            singleTypeEnvSubtopicName
          )}
          <Axis
            orientation="bottom"
            label="Month"
            tickFormat={(d) => {
              return moment.monthsShort()[d.getMonth()];
            }}
          />
          <Axis
            orientation="left"
            tickFormat={(d) => NumberService.humanize(d)}
          />
          <Grid columns={false} numTicks={4} />
          {yearlyData.map((yd, index) => (
            <LineSeries
              key={index}
              dataKey={yd.dataKey}
              data={yd.data}
              {...accessors}
            />
          ))}
          {missingData.map((yd) => (
            <GlyphSeries
              key={yd.dataKey}
              dataKey={yd.dataKey}
              data={yd.data}
              colorAccessor={() => PERFORMANCE_CHART_COLOR.RED}
              {...accessors}
            />
          ))}
          <Tooltip
            snapTooltipToDatumX
            snapTooltipToDatumY
            showVerticalCrosshair
            showSeriesGlyphs
            renderTooltip={({ tooltipData, colorScale }) => {
              const allDatums = Object.values(tooltipData.datumByKey);
              const xLabel =
                allDatums && allDatums.length > 0
                  ? moment.monthsShort()[allDatums[0].datum.x.getMonth()]
                  : "-";
              return (
                <div>
                  <div>{xLabel}</div>
                  {reportYears.map((key, index) => (
                    <div className="d-flex mt-1" key={index}>
                      <div style={{ color: colorScale(key) }}>{key}</div>
                      <div
                        className="ms-1"
                        style={{
                          color: tooltipData.datumByKey[key].datum.color,
                        }}
                      >
                        {tooltipData.datumByKey[key].datum.label}
                      </div>
                    </div>
                  ))}
                </div>
              );
            }}
          />
        </XYChart>
        <LegendOrdinal scale={ordinalColorScale}>
          {(labels) => (
            <div className="d-flex justify-content-center bg-white">
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 50px">
                  <svg width={15} height={3}>
                    <rect fill={label.value} width={15} height={3} />
                  </svg>
                  <LegendLabel className="caption ms-1">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </>
    );
  }
);

export default PerformanceReportLineChart;
