import { TARGET_TOPIC } from "../../config/constants";
import GVFormGroup from "../common/GVFormGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import TargetFormBuilder from "./TargetFormBuilder";
import React from "react";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";

const InputTargetStepTargetDetails = ({
  title,
  topic,
  params,
  setParams,
  config,
  isInvalid,
}) => {
  if (topic === TARGET_TOPIC.FREE_FORM) {
    return (
      <GVFormGroup>
        <div className="gv-body-2--bold gvds-color--gray6">{title}</div>
        <Row className="p-3">
          <Col>
            <GVFormGroup>
              <Form.Label>Target Name</Form.Label>
              <GVDSFormField
                id="target-name-input"
                placeholder="Target Name"
                value={params.target_name}
                onInput={(value) =>
                  setParams({ ...params, target_name: value })
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
      </GVFormGroup>
    );
  }
  return (
    <GVFormGroup>
      <div className="gv-body-2--bold gvds-color--gray6">{title}</div>
      <div className="p-3">
        {topic ? (
          <TargetFormBuilder
            topic={topic}
            params={params}
            setParams={setParams}
            config={config}
          />
        ) : (
          <div className="create-target-form__locked-content mb-3">
            This section will appear once you have selected a topic above
          </div>
        )}
        {isInvalid && (
          <div className="manual-invalid-feedback">Please complete step 2.</div>
        )}
      </div>
    </GVFormGroup>
  );
};

export default InputTargetStepTargetDetails;
