import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import { getRedirectURLWithCurrentParam } from "../../../common/QueryHandler";
import {
  CREATE_SITE,
  SYSTEM_TOOLBOX_ONBOARDING_TOOL,
} from "../../../../config/ROUTES_NAME";
import { Link, useLocation } from "react-router-dom";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSFormMultiSelect from "../../../../gvds-components/Forms/GVDSFormMultiSelect";

const SelectIndividualSitesModal = ({
  allSites,
  reloadAllSites,
  siteSubscriptions,
  showModal,
  onCloseModal,
  onSuccess,
}) => {
  const location = useLocation();
  const toastContext = useContext(ToastContext);
  const [selectedSites, setSelectedSites] = useState([]);

  useEffect(() => {
    setSelectedSites(
      siteSubscriptions.map((subscription) => {
        return {
          id: subscription.site.id,
          name: subscription.site.name,
          isFixed: !subscription.canDeleteFromContractSubscription,
        };
      })
    );
  }, [siteSubscriptions]);

  const closeModal = () => {
    onCloseModal();
  };

  const saveSites = () => {
    onCloseModal();
    onSuccess(selectedSites);
    toastContext.addSuccessToast(<span>Site(s) selected successfully</span>);
  };

  const onSelectingSite = (sites) => {
    setSelectedSites(
      sites.map((site) => {
        return { id: site.value, name: site.label, isFixed: !!site.isFixed };
      })
    );
  };

  const onChangeWithFixedSites = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = selectedSites
          .filter((s) => s.isFixed)
          .map((site) => {
            return { label: site.name, value: site.id, isFixed: site.isFixed };
          });
        break;
    }
    onSelectingSite(newValue);
  };

  return (
    <GVDSModal
      show={showModal}
      onHide={closeModal}
      title="Select individual site(s)"
      size={GVDSModal.Size.medium}
    >
      <GVDSModal.Body className="contract-subscription__select-site__modal-body">
        <div className="gv-text-16 mb-4">
          <ol className="contract-subscription__select-site__instruction-steps">
            <li>
              Create new site(s) using the{" "}
              <Link
                to={getRedirectURLWithCurrentParam(
                  SYSTEM_TOOLBOX_ONBOARDING_TOOL,
                  location
                )}
                target="_blank"
                rel="noreferrer"
              >
                onboarding tool
              </Link>{" "}
              (multiple) or{" "}
              <Link
                to={getRedirectURLWithCurrentParam(CREATE_SITE, location)}
                target="_blank"
                rel="noreferrer"
              >
                create site form
              </Link>{" "}
              (single), and click on "Reload site list".
            </li>
            <li>
              Use the search field below to select site(s) from the Portal
              database.
            </li>
          </ol>
        </div>
        <div className="reload-all-sites">
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.tertiary}
            onClickAsyncFunc={reloadAllSites}
            text="Reload site list"
          />
        </div>
        <GVDSFormMultiSelect
          isOptionDisabled={(option) => {
            return (
              selectedSites.findIndex((site) => site.id === option.value) !== -1
            );
          }}
          placeholder="Select sites"
          value={selectedSites.map((site) => {
            return { label: site.name, value: site.id, isFixed: site.isFixed };
          })}
          onSelect={onChangeWithFixedSites}
          options={allSites
            .sort((site1, site2) => site1.name.localeCompare(site2.name))
            .map((site) => ({
              label: site.name,
              value: site.id,
            }))}
          className={"site-select"}
          closeMenuOnSelect={false}
        />
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={closeModal}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={saveSites}
          text="Save"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default SelectIndividualSitesModal;
