import React, { Children } from "react";
import { Collapse } from "react-bootstrap";
import GVDSIconButton, { iconButtonVariant } from "../Buttons/GVDSIconButton";
import GVDSIconSlim from "../Icons/GVDSIconSlim";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";

export const getExpandableIcon = (isExpanded) => {
  return isExpanded ? IconChevronDown : IconChevronRight;
};

export const expandableIconCell = (isExpanded) => {
  return (
    <td>
      <GVDSIconButton
        variant={iconButtonVariant.tertiary}
        icon={<GVDSIconSlim Icon={getExpandableIcon(isExpanded)} />}
        tooltipText={isExpanded ? "Collapse" : "Expand"}
        placement="top"
        onClick={() => {}}
      />
    </td>
  );
};

const GVDSTableExpandableRow = ({
  isExpanded,
  toggleIsExpanded,
  controlColumns,
  dataColumns,
  expandedBody,
  disabled = false,
}) => {
  return (
    <>
      <tr
        className={!disabled ? "is-clickable" : ""}
        onClick={() => {
          if (!disabled) {
            toggleIsExpanded();
          }
        }}
      >
        {[
          controlColumns.map((col, i) => (
            <React.Fragment key={`control-${i}`}>{col}</React.Fragment>
          )),
        ]}
        {[
          dataColumns.map((col, i) => (
            <React.Fragment key={`data-${i}`}>{col}</React.Fragment>
          )),
        ]}
      </tr>
      <tr className={!isExpanded ? "d-none" : ""}>
        {[...Array(Children.count(controlColumns))].map((el, i) => (
          <td key={i} />
        ))}
        <td colSpan={Children.count(dataColumns)} className="pe-2">
          <Collapse in={isExpanded}>{expandedBody}</Collapse>
        </td>
      </tr>
    </>
  );
};

export default GVDSTableExpandableRow;
