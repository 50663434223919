import React, { useState } from "react";
import { ExcelService } from "../../services/UtilsService";
import LoadingSpinner from "./LoadingSpinner";
import { FileUploader } from "./FileAttachments";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";

const EXCEL_UPLOAD_ERRORS = {
  NOT_XLSX:
    "Your file was not of the correct format. Ensure that you’re using the Excel spreadsheet provided.",
  MORE_THAN_ONE:
    "You tried to upload more than one file. Please upload only one file at a time.",
  UNKNOWN: (
    <div>
      Unknown error occurred. Please contact{" "}
      <a href="mailto:support@greenviewportal.com">
        support@greenviewportal.com
      </a>{" "}
      with the file you intended to upload or try again.
    </div>
  ),
};

const BulkExcelUploadModal = ({
  sheetName,
  startingRow,
  show,
  onClose,
  onRowsRead,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);

  const onFileChange = (files) => {
    if (files.length < 1) return;
    if (files.length > 1) {
      setError(EXCEL_UPLOAD_ERRORS.MORE_THAN_ONE);
      return;
    }
    if (!ExcelService.isExcel(files[0].file)) {
      setError(EXCEL_UPLOAD_ERRORS.NOT_XLSX);
      return;
    }

    setIsLoading(true);
    ExcelService.getRows(
      files[0].file,
      sheetName,
      startingRow,
      (newRows) => {
        setRows(newRows);
        setError(null);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        setError(EXCEL_UPLOAD_ERRORS.UNKNOWN);
      }
    );
  };

  const onModalClose = () => {
    setRows([]);
    setError(null);
    onClose();
  };

  const onUpload = () => {
    onRowsRead(rows);
    onModalClose();
  };

  let title;
  let content;
  if (isLoading) {
    title = "Uploading spreadsheet";
    content = (
      <>
        <GVDSModal.Body>
          <div className="body-2">
            Your spreadsheet is currently being uploaded. This may take slightly
            longer depending on the number of rows your spreadsheet has.
          </div>
          <div className="p-4">
            <LoadingSpinner />
          </div>
          <div className="body-1 color-red text-center">
            Please do not refresh or leave this page.
          </div>
        </GVDSModal.Body>
      </>
    );
  } else if (rows.length > 0) {
    title = "Spreadsheet successfully uploaded";
    content = (
      <>
        <GVDSModal.Body>
          <div className="body-2">
            Your data has been added to the bulk import form. Click on “Close”
            to continue with the validation and submission of your data.
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            text="Close"
            onClick={onUpload}
          />
        </GVDSModal.Footer>
      </>
    );
  } else {
    title = "Upload Excel spreadsheet";
    content = (
      <>
        <GVDSModal.Body>
          <div>
            Ensure that you’re using a filled up Excel template provided on the
            bulk input page. You may also copy-and-paste the cells directly into
            the online bulk input form.
          </div>
          <div className="fst-italic pt-3 pb-3">
            Note: This action will remove all existing rows in your online bulk
            input form.
          </div>
          {error && (
            <GVDSBanner
              title="An error occurred while uploading your file. Please try again."
              variant={GVDSBanner.Variants.error}
            >
              {error}
            </GVDSBanner>
          )}
          <FileUploader
            files={[]}
            setFiles={onFileChange}
            description="a filled up .xlsx template"
          />
        </GVDSModal.Body>
      </>
    );
  }

  return (
    <GVDSModal
      size={GVDSModal.Size.medium}
      title={title}
      show={show}
      onHide={onModalClose}
    >
      {content}
    </GVDSModal>
  );
};

export default BulkExcelUploadModal;
