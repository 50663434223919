import React, { useContext, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";

import PeopleService, {
  PEOPLE_ROW_INPUT_LIMIT,
} from "../../../services/PeopleService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import InputPeopleRecordTable from "./InputPeopleRecord/InputPeopleRecordTable";
import PeopleGroupingsTable from "./PeopleGroupingsTable";
import LoadingSpinner from "../../common/LoadingSpinner";
import Delayed from "../../common/Delayed";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormMultiSelect from "../../../gvds-components/Forms/GVDSFormMultiSelect";
import StatusLabel from "../../../gvds-components/common/StatusLabel";

const isExistingMeter = (meter) => {
  return meter && meter.meter_id;
};

const isNoOfRowsMoreThanLimit = (noOfRows) => {
  return noOfRows > PEOPLE_ROW_INPUT_LIMIT;
};

const PeopleMeterModal = ({
  showModal,
  closeModal,
  onSuccess,
  currentMeterDetails,
  subtopicOptions,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);

  const [subtopicDefn, setSubtopicDefn] = useState("");
  const [groupingOptions, setGroupingOptions] = useState([]);
  const [isOnlyOneGroupingOption, setIsOnlyOneGroupingOption] = useState(false);

  const [inputMeterDetails, setInputMeterDetails] = useState(null);
  const [isValidated, setIsValidated] = useState(false);

  const groupingsSelectorRef = useRef();

  const onClose = () => {
    if (!isLoading) {
      setIsValidated(false);
      closeModal();
    }
  };

  useEffect(() => {
    if (currentMeterDetails) {
      const meterInput = { ...currentMeterDetails };

      if (currentMeterDetails.subtopic_id) {
        const subtopicOption = subtopicOptions.find(
          (subtopic) => subtopic.id === currentMeterDetails.subtopic_id
        );

        setSubtopicDefn(subtopicOption ? subtopicOption.definition : "");
        setGroupingOptions(subtopicOption ? subtopicOption.groupings : []);

        if (subtopicOption.groupings.length === 1) {
          setIsOnlyOneGroupingOption(true);

          if (!isExistingMeter(currentMeterDetails)) {
            meterInput.grouping_ids = [subtopicOption.groupings[0].id];
          }
        } else {
          setIsOnlyOneGroupingOption(false);
        }
      }

      setInputMeterDetails(meterInput);
    }
  }, [currentMeterDetails, subtopicOptions]);

  const changeGroupings = (selected) => {
    inputMeterDetails.grouping_ids = selected.map((item) => item.value);
    setInputMeterDetails({ ...inputMeterDetails });
  };

  const subtopicName =
    !inputMeterDetails || !inputMeterDetails.subtopic_id
      ? ""
      : subtopicOptions.find(
          (subtopic) => subtopic.id === currentMeterDetails.subtopic_id
        )?.name;

  const isMeterGroupingsValid = () => {
    return inputMeterDetails && inputMeterDetails.grouping_ids.length > 0;
  };

  const saveMeter = async () => {
    setIsValidated(true);

    if (isMeterGroupingsValid() && !isNoOfRowsMoreThanLimit(noOfRows)) {
      setIsLoading(true);
      const successMsg = isExistingMeter(currentMeterDetails)
        ? "Subtopic updated successfully."
        : "Started tracking subtopic successfully.";
      const errorMsg = isExistingMeter(currentMeterDetails)
        ? "Failed to update subtopic."
        : "Failed to set up subtopic.";
      const httpRequest = isExistingMeter(currentMeterDetails)
        ? PeopleService.updatePeopleMeter(
            selectedInventory.id,
            currentMeterDetails.meter_id,
            inputMeterDetails.subtopic_id,
            inputMeterDetails.grouping_ids
          )
        : PeopleService.createPeopleMeter(
            selectedInventory.id,
            inputMeterDetails.subtopic_id,
            inputMeterDetails.grouping_ids
          );
      httpRequest
        .then(() => {
          toastContext.addSuccessToast(<span>{successMsg}</span>);
          onSuccess();
          setIsLoading(false);
        })
        .catch(() => {
          toastContext.addFailToast(<span>{errorMsg}</span>);
          setIsLoading(false);
        });
    } else {
      if (!isMeterGroupingsValid() && groupingsSelectorRef.current) {
        groupingsSelectorRef.current.scrollIntoView();
      }
    }
  };

  const groupings = (
    inputMeterDetails ? inputMeterDetails.grouping_ids : []
  ).map((groupingId) =>
    groupingOptions.find((grouping) => groupingId === grouping.id)
  );

  const noOfRows = PeopleService.numberOfRowsFromGroupings(groupings);

  const getSelectGroupingStatusMetadata = () => {
    if (isValidated && !isMeterGroupingsValid()) {
      return FormFieldStatusMetadata.getError(
        "Please select at least one grouping."
      );
    } else if (isNoOfRowsMoreThanLimit(noOfRows)) {
      return FormFieldStatusMetadata.getError(
        `Please unselect some groupings (e.g. unselect some Age groupings if you have selected more than one of them). The groupings you have selected result in ${noOfRows} input rows which exceed the system limit of ${PEOPLE_ROW_INPUT_LIMIT}.`
      );
    } else {
      return FormFieldStatusMetadata.getDefault();
    }
  };

  return (
    <GVDSModal
      title={`Setup ${subtopicName}`}
      size={GVDSModal.Size.medium}
      show={showModal}
      onHide={onClose}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {subtopicDefn && <div className="mb-4">{subtopicDefn}</div>}
            <Form.Label ref={groupingsSelectorRef}>
              Groupings Tracked
            </Form.Label>
            <div className="mb-1">
              Order of groupings tracked determine the table structure; see
              Table Preview below and customise accordingly. Glossary for each
              grouping available after selection.
            </div>
            <GVDSFormMultiSelect
              name="groupings"
              disabled={isOnlyOneGroupingOption}
              maxMenuHeight={210}
              value={
                inputMeterDetails && inputMeterDetails.grouping_ids
                  ? inputMeterDetails.grouping_ids.map((grouping_id) => {
                      return {
                        label: groupingOptions.find(
                          (grouping) => grouping.id === grouping_id
                        ).name,
                        value: grouping_id,
                      };
                    })
                  : []
              }
              onSelect={(values) => changeGroupings(values)}
              options={groupingOptions.map((grouping) => {
                return {
                  label: grouping.name,
                  value: grouping.id,
                };
              })}
              hideSelectedOptions={true}
              className="grouping-select"
              statusMetadata={getSelectGroupingStatusMetadata()}
            />
            {inputMeterDetails && inputMeterDetails.grouping_ids && (
              <>
                {inputMeterDetails.grouping_ids.length > 0 && (
                  <div className="my-4">
                    <GlossaryView
                      selectedGroupingIds={
                        inputMeterDetails ? inputMeterDetails.grouping_ids : []
                      }
                      groupingOptions={groupingOptions}
                    />
                  </div>
                )}

                <div className="my-4">
                  <PeopleDataInputPreview groupings={groupings} />
                </div>
              </>
            )}
          </>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        {isExistingMeter(currentMeterDetails) && (
          <div className="people-meter-modal__update-message caption color-gray56">
            Any changes to the tracked groupings will not affect past records.
            <br />
            Upon saving, the new groupings will be used for newly created
            records.
          </div>
        )}
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onClose}
          disabled={isLoading}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={saveMeter}
          disabled={
            isLoading ||
            !isMeterGroupingsValid() ||
            isNoOfRowsMoreThanLimit(noOfRows)
          }
          text="Save & Close"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const GlossaryView = ({ selectedGroupingIds, groupingOptions }) => {
  const groupings = selectedGroupingIds.map((groupingId) =>
    groupingOptions.find((grouping) => groupingId === grouping.id)
  );

  return (
    <div>
      <Form.Label>Glossary</Form.Label>
      <PeopleGroupingsTable groupings={groupings} />
    </div>
  );
};

const PeopleDataInputPreview = ({ groupings }) => {
  return (
    <div>
      <Form.Label>Table Preview</Form.Label>
      <div>
        This is a preview of what your site will have to input for the groupings
        listed above.
      </div>
      <div className="people-preview-table">
        <StatusLabel color={StatusLabel.Colors.yellow} className="mb-2">
          Preview Only
        </StatusLabel>
        <Delayed>
          <InputPeopleRecordTable
            previewMode={true}
            previewGroupings={groupings}
          />
        </Delayed>
      </div>
    </div>
  );
};

export default PeopleMeterModal;
