import React, { useContext, useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useHistory } from "react-router-dom";
import {
  getAdminEditGuidanceArticlePath,
  SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_CREATE,
} from "../../../config/ROUTES_NAME";
import ToastContext from "../../../context/ToastContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingSpinner from "../../common/LoadingSpinner";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import uniq from "lodash/uniq";
import withAuthentication from "../../HOC/withAuthentication";
import GuidanceArticleAdminService from "../../../services/GuidanceArticleAdminService";
import UNSDGService from "../../../services/UNSDGService";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSTag from "../../../gvds-components/common/GVDSTag";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit } from "@tabler/icons-react";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import Spacer from "../../../gvds-components/Layout/Spacer";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Container from "react-bootstrap/Container";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GuidanceArticleStatusBadge from "./GuidanceArticleStatusBadge";
import { ContentTypeLabelDisplay } from "../../common/ContentTypeDisplay";

const ViewAllGuidanceArticles = () => {
  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Guidance Library</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="guidance-articles-list">
        <Tab
          eventKey="guidance-articles-list"
          title="Guides"
          tabClassName="tab-nav"
        >
          <GuidanceArticlesList />
        </Tab>
      </Tabs>
    </Container>
  );
};

const GuidanceArticlesList = () => {
  const history = useHistory();
  const goToCreate = () => {
    history.push(SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_CREATE);
  };

  const goToEdit = (a) => {
    history.push(getAdminEditGuidanceArticlePath(a.id));
  };

  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(true);

  const [guidanceArticles, setGuidanceArticles] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    guidanceArticles,
    SystemToolboxSearchKeysByPageName.guidanceArticle
  );

  const loadQuestions = () => {
    setIsLoading(true);
    GuidanceArticleAdminService.getAllGuidanceArticles()
      .then((articles) => {
        setGuidanceArticles(articles);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Something went wrong. Please refresh page.</span>
        );
      });
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  const columns = [
    {
      header: "Title",
      dataKey: "title",
      sortable: true,
    },
    {
      header: "Format",
      headerStyle: { width: "150px" },
      dataKey: "content_type_name",
      sortable: true,
      renderer: (a) => {
        return <ContentTypeLabelDisplay articleTypeName={a.contentTypeName} />;
      },
    },
    {
      header: "Category",
      dataKey: "category_names",
      renderer: (a) =>
        a.category_names.map((category) => (
          <GVDSTag variant={GVDSTag.Variants.system} key={category}>
            {category}
          </GVDSTag>
        )),
    },
    {
      header: "Tags",
      dataKey: "tag_names",
      renderer: (a) =>
        a.tag_names.map((tag) => (
          <GVDSTag variant={GVDSTag.Variants.system} key={tag}>
            {tag}
          </GVDSTag>
        )),
    },
    {
      header: "Status",
      dataKey: "status",
      renderer: (a) => <GuidanceArticleStatusBadge status={a.status} />,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (a) => {
        return (
          <Row>
            <Col>
              <GVDSIconButton
                className="edit-article-button"
                variant={iconButtonVariant.tertiary}
                onClick={() => {
                  goToEdit(a);
                }}
                icon={<GVDSIcon Icon={IconEdit} />}
                tooltipText="Edit"
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            className="add-guidance-article"
            onClick={goToCreate}
            icon={<GVDSIcon Icon={IconCirclePlus} />}
            text="Add Guide"
          />
        </PageHeader.Title>
      </PageHeader>

      <GVDSTableCtrlContainer>
        <FilterSearchBox
          className="article-search-box"
          placeholder="Search for a guide"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(guidanceArticles.map((q) => q.category_names).flat())}
          prefix="Category"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, category_names: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(guidanceArticles.map((q) => q.tag_names).flat())}
          prefix="Tag"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, tag_names: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(guidanceArticles.map((q) => q.status).flat())}
          prefix="Status"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, status: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(
            guidanceArticles
              .map((q) => q.un_sdg_names)
              .flat()
              .sort(UNSDGService.unsdgNameSortFn)
          )}
          prefix="UN SDG"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, un_sdg_names: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(guidanceArticles.map((q) => q.site_type_names).flat())}
          prefix="Site Type"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, site_type_names: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(guidanceArticles.map((q) => q.contentTypeName))}
          prefix="Format"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, contentTypeName: filterKs })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        className="guidance-library__article-list"
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {guidanceArticles.length === 0 ? (
        <div className="table__no_content">No guides</div>
      ) : (
        filteredSortedData.length === 0 && (
          <div className="table__no_content">
            No guides. Try changing your filter.
          </div>
        )
      )}
    </Container>
  );
};
export default withAuthentication(ViewAllGuidanceArticles);
