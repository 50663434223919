import { MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY } from "./Homepage/CertificationHomepage";
import moment from "moment/moment";

export class CertificationUtil {
  static sortCriteriaFn = (c1, c2) => {
    return c1.order - c2.order;
  };

  static sortEvidenceFiles(file1, file2) {
    return moment(file2.createdOn).diff(file1.createdOn);
  }

  static calculateMinCssWidth(values, ref) {
    /* Use this when not possible to use display: contents due to requiring box-shadow across cells */

    if (!values || !ref.current) {
      return 0;
    }

    let maxWidth = 0;

    values.forEach((value) => {
      ref.current.innerHTML = value;
      const width = Math.ceil(ref.current.clientWidth);
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    return maxWidth;
  }

  static getCategoryNameDisplay(categoryName) {
    if (
      categoryName.length > MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY
    ) {
      return `${categoryName.slice(
        0,
        MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY + 1
      )}...`;
    } else {
      return categoryName;
    }
  }
}
