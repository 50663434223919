import React, { useCallback, useState } from "react";
import _ from "lodash";

import { TOAST_TYPE } from "../config/constants";

class ToastItem {
  constructor(type, display) {
    this.id = _.uniqueId();
    this.type = type;
    this.display = display;
  }
}

const internalErrorToastItem = new ToastItem(
  TOAST_TYPE.FAIL,
  (
    <div>
      <div className="body-1-bold">There was an internal error:</div>
      <div className="body-1">
        Please contact portal admin at{" "}
        <a href="mailto:support@greenviewportal.com">
          support@greenviewportal.com
        </a>
      </div>
    </div>
  )
);

const ToastContext = React.createContext(null);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addSuccessToast = (newToast) => {
    setToasts([new ToastItem(TOAST_TYPE.SUCCESS, newToast), ...toasts]);
  };

  const addFailToast = (...newToasts) => {
    const failToasts = newToasts.map(
      (toast) => new ToastItem(TOAST_TYPE.FAIL, toast)
    );
    setToasts([...failToasts, ...toasts]);
  };

  const addInternalFailToast = () => {
    setToasts([_.clone(internalErrorToastItem), ...toasts]);
  };

  const removeToast = (toast) => {
    toasts.splice(toasts.indexOf(toast), 1);
    setToasts([...toasts]);
  };

  const clearAllToast = () => {
    setToasts([]);
  };

  const store = useCallback(
    {
      addSuccessToast: addSuccessToast,
      addFailToast: addFailToast,
      addInternalFailToast: addInternalFailToast,
      removeToast: removeToast,
      clearAllToast: clearAllToast,
      getAllToasts: () => toasts,
    },
    [toasts]
  );

  return (
    <ToastContext.Provider value={store}>{children}</ToastContext.Provider>
  );
};

export default ToastContext;
