import React, { useContext, useEffect, useState } from "react";
import clone from "lodash/clone";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import greenviewPortalLogo from "../../assets/images/new-greenview-logo.svg";
import SignUpService, { SignUpFormModel } from "../../services/SignUpService";
import LoadingSpinner from "../common/LoadingSpinner";
import ToastContext from "../../context/ToastContext";
import { DropdownOptions } from "../Site/SiteFacilityInformationComponents";
import GVFormGroup from "../common/GVFormGroup";
import SiteFacilityTypeOptionsService from "../../services/ReferenceDataServices/SiteFacilityTypeOptionsService";
import { FEATURE_FLAG_SHOW_GLTR_ON_SIGNUP } from "../../config/feature-flag-config";
import SupportContactLink from "../common/SupportContactLink";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../config/ROUTES_NAME";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";

const greenviewMarketingURL = "https://greenview.sg/services/greenview-portal/";
const greenviewNewsletterSubscriptionURL =
  "https://www.linkedin.com/newsletters/whats-new-greenview-7069342367484403712";

const GLTRBlurb = () => {
  return (
    <>
      <h1>The Green Lodging Trends Report (GLTR)</h1>
      <p>
        The GLTR is the leading global benchmarking study on sustainability best
        practices in hotels. It benchmarks hotels from around the world to
        understand which best practices are in place.
      </p>
      <div>
        <div>
          <strong>Why should you participate:</strong>
        </div>
        <div>
          <ul>
            <li>Get a free benchmarking report!</li>
            <li>
              Find out where your property is aligned with best practices, and
              where it is behind among peers locally and worldwide.
            </li>
            <li>
              Discover new innovative practices that hotels are implementing.
            </li>
            <li>
              Highlight your own hotel's innovations to showcase to the world.
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <strong>How to participate:</strong>
        </div>
        <div>
          <ol>
            <li>
              Upon successful login, you will be directed to Greenview Portal
              Landing Page "My Greenview".
            </li>
            <li>Go to top menu "Surveys".</li>
            <li>
              On the survey page, click on "2022 Green Lodging Trends Report
              Survey".
            </li>
            <li>
              Please complete and submit the survey by due date to receive your
              free GLTR benchmark report (in order to receive the free benchmark
              report, all questions must be answered).
            </li>
          </ol>
        </div>
      </div>
      <div>
        To maintain data integrity, only valid hotels and staff members of the
        hotels with verified email addresses are accepted to participate in the
        survey.
      </div>

      <hr />
    </>
  );
};

const SIGN_UP_PAGE_STATE = {
  SIGN_UP_FORM: "SIGN_UP_FORM",
  SUBMITTED_FORM: "SUBMITTED_FORM",
};

const SignUp = () => {
  const [signUpPageState, setSignUpPageState] = useState(
    SIGN_UP_PAGE_STATE.SIGN_UP_FORM
  );
  let content;
  if (signUpPageState === SIGN_UP_PAGE_STATE.SIGN_UP_FORM) {
    content = (
      <Row className="signup-page__container">
        <Col md={6} sm={12} className="signup-info">
          <div className="signup-info__container">
            <div className="signup-info__description">
              <img
                src={greenviewPortalLogo}
                alt="Greenview Portal"
                height="60"
              />
              <div className="mt-3">
                {FEATURE_FLAG_SHOW_GLTR_ON_SIGNUP && <GLTRBlurb />}
                {FEATURE_FLAG_SHOW_GLTR_ON_SIGNUP ? (
                  <p>
                    Check out the Portal's range of modules and functionalities:
                  </p>
                ) : (
                  <p>
                    A renowned GSTC-Recognized sustainability management system.
                    Crafted by industry experts, for hotels and hotel companies
                    seeking to collect source data on a single platform,
                    enhancing collaboration and sustainability performance
                    management through technology and automation.
                  </p>
                )}
              </div>

              <div>
                Discover the power of:
                <ul>
                  <li>ESG Data Management & Reporting</li>
                  <li>Sustainability Benchmarking</li>
                  <li>Setting Goals & Targets</li>
                </ul>
              </div>

              <div>
                Curious to know more about the Greenview Portal?{" "}
                <a
                  href={greenviewMarketingURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here
                </a>
              </div>
            </div>
            <div className="signup-info__contact-us">
              <div>Represent a company with multiple hotels/properties?</div>
              <div>
                <SupportContactLink
                  linkText="Contact us"
                  subject="Company Interest in Greenview Portal"
                />{" "}
                for group rates.
              </div>
            </div>
          </div>
        </Col>

        <Col md={6} sm={12} className="signup-form">
          <SignUpForm
            onSignUp={() =>
              setSignUpPageState(SIGN_UP_PAGE_STATE.SUBMITTED_FORM)
            }
          />
        </Col>
      </Row>
    );
  } else if (signUpPageState === SIGN_UP_PAGE_STATE.SUBMITTED_FORM) {
    content = (
      <div className="signup-form-submitted">
        <div className="signup-form-submitted__container">
          <div className="signup-form-submitted__header">
            Application Submitted Successfully
          </div>
          <div className="signup-form-submitted__body">
            <div className="mb-4">
              Thank you for showing interest in the Greenview Portal. We are
              currently processing your information and property details.
            </div>
            <div className="mb-4">
              You can expect to hear from us via email within the next few
              working days.
            </div>
          </div>
          <div className="signup-form-submitted__footer">
            <div>In the meantime,</div>
            <ul>
              <li>
                <a
                  href={greenviewMarketingURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more about the Greenview Portal
                </a>
              </li>
              <li>
                <a
                  href={greenviewNewsletterSubscriptionURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Subscribe to Greenview’s newsletter
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="signup-page">
      {content}
    </Container>
  );
};

const getSiteNameErrorMessage = (signUpModel) => {
  return !signUpModel.isSiteNameNotEmpty()
    ? "Please enter your site name."
    : !signUpModel.isSiteNameNoBlacklistedCharacter()
    ? "Site name contains special characters. Only letters, numbers and symbols are allowed."
    : "Invalid site name.";
};

const getProvincesOfCountry = (countryId, provinces) => {
  return provinces && countryId
    ? provinces.filter((p) => p.country.id === countryId)
    : [];
};

const onCountryChange = (countryId, provinces, signUpModel) => {
  const provincesOfCountry = getProvincesOfCountry(countryId, provinces);
  const selectedProvince = provincesOfCountry
    ? provincesOfCountry.find((p) => p.name === signUpModel.stateOrProvince)
    : null;
  if (selectedProvince && selectedProvince.country.id !== countryId) {
    signUpModel.stateOrProvince = "";
  } else if (!selectedProvince && provincesOfCountry.length > 0) {
    signUpModel.stateOrProvince = "";
  }
};

const SignUpForm = ({ onSignUp }) => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [siteTypes, setSiteTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);

  const [signUpModel, setSignUpModel] = useState(new SignUpFormModel());

  useEffect(() => {
    setIsLoading(true);
    SignUpService.getSignUpConfig()
      .then((data) => {
        setSiteTypes(data["types"]);
        setCountries(data["countries"]);
        setProvinces(data["provinces"]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <div>
            <div>
              <strong>Failed to load config.</strong>
            </div>
            <div>
              Please contact portal admin at <SupportContactLink />
            </div>
          </div>
        );
      });
  }, []);

  const onChange = () => {
    setSignUpModel(clone(signUpModel));
  };

  const login = async () => {
    history.push(LOGIN);
  };

  const submitApplication = async () => {
    setIsValidated(true);
    if (signUpModel.isValid()) {
      setIsLoading(true);
      try {
        await SignUpService.submitSignupApplication(
          signUpModel.userFullName,
          signUpModel.emailAddress,
          signUpModel.companyName,
          signUpModel.siteName,
          signUpModel.siteType,
          signUpModel.streetAddress,
          signUpModel.zipCode,
          signUpModel.city,
          signUpModel.stateOrProvince,
          signUpModel.country
        );
        onSignUp();
      } catch (e) {
        toastContext.addFailToast(
          <div>
            <div className="body-1-bold">Failed to submit application.</div>
            <div className="body-1">
              Please contact portal admin at <SupportContactLink />
            </div>
          </div>
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    const stateOrProvinceInput = () => {
      const provincesOfCountry = getProvincesOfCountry(
        signUpModel.country?.id,
        provinces
      );

      if (provincesOfCountry.length > 0) {
        return (
          <>
            <DropdownOptions
              label="State / Province"
              inEditMode={true}
              targetName="stateOrProvince"
              value={{
                id: signUpModel.stateOrProvince,
                name: signUpModel.stateOrProvince,
              }}
              onChange={(targetName, value, label) => {
                signUpModel.stateOrProvince = value;
                onChange();
              }}
              options={provincesOfCountry.map((p) => {
                return { id: p.name, name: p.name };
              })}
              isInvalid={isValidated && !signUpModel.isStateOrProvinceValid()}
              invalidMessage="State or Province is required."
            />
          </>
        );
      } else {
        return (
          <>
            <Form.Label>State / Province</Form.Label>
            <GVDSFormField
              name="stateOrProvince"
              value={signUpModel.stateOrProvince}
              onInput={(value) => {
                signUpModel.stateOrProvince = value;
                onChange();
              }}
              statusMetadata={
                isValidated && !signUpModel.isStateOrProvinceValid()
                  ? FormFieldStatusMetadata.getError(
                      "State or Province is required."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </>
        );
      }
    };

    content = (
      <div className="signup-form__container">
        <section className="mb-3">
          <h1>Sign up for a 14-day trial — it’s free</h1>
          <div>
            For individual properties. If you represent a company,{" "}
            <SupportContactLink
              subject={"Company Interest in Greenview Portal"}
              linkText={"contact us"}
            />{" "}
            for group rates and corporate offers.
          </div>
        </section>

        <section>
          <GVFormGroup>
            <Form.Label>Business Email</Form.Label>
            <GVDSFormField
              name="emailAddress"
              className="signup-form__email-input"
              value={signUpModel.emailAddress}
              onInput={(value) => {
                signUpModel.emailAddress = value;
                onChange();
              }}
              statusMetadata={
                isValidated && !signUpModel.isEmailAddressValid()
                  ? FormFieldStatusMetadata.getError(
                      "Please enter a valid email."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <Row>
            <Col>
              <GVFormGroup>
                <Form.Label>Your Name</Form.Label>
                <GVDSFormField
                  name="userFullName"
                  value={signUpModel.userFullName}
                  onInput={(value) => {
                    signUpModel.userFullName = value;
                    onChange();
                  }}
                  statusMetadata={
                    isValidated && !signUpModel.isNameValid()
                      ? FormFieldStatusMetadata.getError(
                          "Please enter your name."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
            <Col>
              <GVFormGroup>
                <Form.Label>Company Name</Form.Label>
                <GVDSFormField
                  name="company"
                  value={signUpModel.companyName}
                  onInput={(value) => {
                    signUpModel.companyName = value;
                    onChange();
                  }}
                  statusMetadata={
                    isValidated && !signUpModel.isCompanyValid()
                      ? FormFieldStatusMetadata.getError(
                          "Please enter your company."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>
        </section>

        <section>
          <div className="section-label">Tell us about your property</div>
          <Row>
            <Col>
              <GVFormGroup>
                <Form.Label>Property Name</Form.Label>
                <GVDSFormField
                  name="siteName"
                  placeholder="Property Name"
                  value={signUpModel.siteName}
                  onInput={(value) => {
                    signUpModel.siteName = value;
                    onChange();
                  }}
                  statusMetadata={
                    isValidated && !signUpModel.isSiteNameValid()
                      ? FormFieldStatusMetadata.getError(
                          getSiteNameErrorMessage(signUpModel)
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
            <Col>
              <GVFormGroup>
                <DropdownOptions
                  label="Property Type"
                  targetName="siteType"
                  inEditMode={true}
                  value={signUpModel.siteType}
                  onChange={(targetName, value, label) => {
                    signUpModel.siteType = { id: value, name: label };
                    onChange();
                  }}
                  options={siteTypes}
                  isInvalid={isValidated && !signUpModel.isSiteTypeValid()}
                  invalidMessage="Please select a site type."
                  optionSortFn={
                    SiteFacilityTypeOptionsService.siteFacilityTypeSortFn
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>

          <GVFormGroup>
            <Form.Label>Street Address</Form.Label>
            <GVDSFormField
              name="streetAddress"
              value={signUpModel.streetAddress}
              onInput={(value) => {
                signUpModel.streetAddress = value;
                onChange();
              }}
              statusMetadata={
                isValidated && !signUpModel.isStreetAddressValid()
                  ? FormFieldStatusMetadata.getError(
                      "Street address is required."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <Row>
            <Col>
              <GVFormGroup>
                <Form.Label>City</Form.Label>
                <GVDSFormField
                  name="city"
                  value={signUpModel.city}
                  onInput={(value) => {
                    signUpModel.city = value;
                    onChange();
                  }}
                  statusMetadata={
                    isValidated && !signUpModel.isCityValid()
                      ? FormFieldStatusMetadata.getError("City is required.")
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
            <Col>
              <GVFormGroup>
                <Form.Label>Postal Code</Form.Label>
                <GVDSFormField
                  name="zipCode"
                  value={signUpModel.zipCode}
                  onInput={(value) => {
                    signUpModel.zipCode = value;
                    onChange();
                  }}
                  statusMetadata={
                    isValidated && !signUpModel.isZipCodeValid()
                      ? FormFieldStatusMetadata.getError(
                          "Postal code is not valid."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>
          <Row>
            <Col>{stateOrProvinceInput()}</Col>
            <Col>
              <GVFormGroup>
                <DropdownOptions
                  label="Country"
                  inEditMode={true}
                  targetName="country"
                  value={signUpModel.country}
                  onChange={(targetName, value, label) => {
                    signUpModel.country = { id: value, name: label };
                    onCountryChange(value, provinces, signUpModel);
                    onChange();
                  }}
                  options={countries}
                  isInvalid={isValidated && !signUpModel.isCountryValid()}
                  invalidMessage="Please select a country"
                />
              </GVFormGroup>
            </Col>
          </Row>
        </section>
        <section className="d-flex flex-row align-items-center">
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={submitApplication}
            text="Submit Application"
          />
          <div className="ms-4">Already a user?</div>
          <Button
            variant="link"
            size="sm"
            className="login_button"
            onClick={login}
          >
            Login instead
          </Button>
        </section>
      </div>
    );
  }

  return content;
};

export default SignUp;
