import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import React, { useContext, useEffect, useState } from "react";
import UserInventoryContext from "../../../context/UserInventoryContext";
import PermissionsContext from "../../../context/PermissionsContext";
import UserManagedTag from "../../common/CentralTags/UserManagedTagDisplay";
import UserManagedTagService from "../../../services/UserManagedTagService";
import ToastContext from "../../../context/ToastContext";
import { AboutTagsModal } from "../../common/CentralTags/AboutTags";
import Container from "react-bootstrap/Container";
import uniq from "lodash/uniq";
import { userManagedTagSearchKeys } from "../../../config/search-config";
import LoadingSpinner from "../../common/LoadingSpinner";
import TagInputModal from "./TagInputModal";
import TagDeletePrompt from "./TagDeletePrompt";
import withAuthentication from "../../HOC/withAuthentication";
import { StringUtils } from "../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import {
  IconCirclePlus,
  IconEdit,
  IconHelp,
  IconTags,
  IconTrash,
} from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { useTranslation } from "react-i18next";

const EmptyTagListPlaceholder = ({ isPortfolio }) => {
  const { t } = useTranslation();

  const portfolioTagCTA = (
    <>
      Click on <strong>Create Portfolio Tag</strong> to create a tag that all
      the sites within your portfolio can use.
    </>
  );
  const siteTagCTA = (
    <>
      Click on <strong>Create Site Tag</strong> to start sorting and organizing
      items.
    </>
  );

  return (
    <div className="empty-tag-list-placeholder">
      <div className="gvds-empty-content--icon">
        <GVDSIcon Icon={IconTags} />
      </div>
      <div className="gvds-empty-content--title">
        Start grouping items with tags
      </div>
      <div>
        <p>{t("user-managed-tag.shared.tag-promotion")}</p>
        <p>{isPortfolio ? portfolioTagCTA : siteTagCTA}</p>
      </div>
    </div>
  );
};

const ViewAllTags = () => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const isPortfolio =
    selectedInventory && selectedInventory.type === RESOURCES.PORTFOLIO;

  const [isLoading, setIsLoading] = useState(true);
  const [allTags, setAllTags] = useState([]);
  const [showTagInput, setShowTagInput] = useState(false);
  const [showTagsInfo, setShowTagsInfo] = useState(false);
  const [showTagDeletePrompt, setShowTagDeletePrompt] = useState(false);
  const [currentEditedTag, setCurrentEditedTag] = useState(null);
  const [currentToBeDeletedTag, setCurrentToBeDeletedTag] = useState(null);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allTags, userManagedTagSearchKeys, {
    name: SORTING_TYPES.asc,
  });

  useEffect(() => {
    loadTags();
  }, [selectedInventory]);

  const showEditTag = (tag) => {
    setCurrentEditedTag(tag);
    setShowTagInput(true);
  };

  const promptDeleteRecord = (tag) => {
    if (selectedInventory) {
      setCurrentToBeDeletedTag(tag);
      setShowTagDeletePrompt(true);
    }
  };

  const closeTagsInfo = () => {
    setShowTagsInfo(false);
  };

  const closeInputModal = () => {
    setCurrentEditedTag(null);
    setShowTagInput(false);
  };

  const closeDeletePrompt = () => {
    setCurrentToBeDeletedTag(null);
    setShowTagDeletePrompt(false);
  };

  const loadTags = () => {
    if (selectedInventory) {
      setIsLoading(true);
      UserManagedTagService.getTags(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((tags) => {
          setAllTags(tags);
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to load tags.</span>);
        })
        .finally(() => setIsLoading(false));
    }
  };

  let columns = [
    {
      header: "Tag",
      dataKey: "name",
      headerStyle: { width: "240px", maxWidth: "240px" },
      sortable: true,
      renderer: (tag) => {
        return <UserManagedTag tag={tag} />;
      },
    },
    {
      header: "Description",
      dataKey: "description",
      sortable: true,
      renderer: (tag) => {
        return tag.description ? (
          tag.description
        ) : (
          <p className="tag-description-column__caption">
            No description added
          </p>
        );
      },
    },
    {
      header: "Tag Type",
      dataKey: "resourceType",
      sortable: true,
      renderer: (tag) => {
        return StringUtils.getTitleCase(tag.resourceType);
      },
    },
    {
      header: "Created Under",
      dataKey: "createdUnder",
      sortable: true,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (tag) => {
        if (
          tag.resourceType === RESOURCES.PORTFOLIO &&
          selectedInventory &&
          selectedInventory.type !== RESOURCES.PORTFOLIO
        ) {
          return null;
        }

        return (
          <>
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[PERMISSIONS.USER_MANAGED_TAG_EDIT] && (
                <GVDSIconButton
                  variant={iconButtonVariant.tertiary}
                  className="edit-tag"
                  onClick={(e) => {
                    e.preventDefault();
                    showEditTag(tag);
                  }}
                  icon={<GVDSIcon Icon={IconEdit} />}
                  tooltipText="Edit"
                />
              )}
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.USER_MANAGED_TAG_DELETE
              ] && (
                <GVDSIconButton
                  variant={iconButtonVariant.destructive}
                  className="delete-tag"
                  onClick={() => {
                    promptDeleteRecord(tag);
                  }}
                  icon={<GVDSIcon Icon={IconTrash} />}
                  tooltipText="Delete"
                />
              )}
          </>
        );
      },
    },
  ];

  let content;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    if (allTags && allTags.length > 0) {
      content = (
        <div>
          <GVDSTableCtrlContainer>
            <FilterSearchBox
              className="w-25 me-2"
              placeholder="Search tags"
              value={searchText}
              onInput={setSearchText}
            />
            {!isPortfolio && (
              <GVDSTableCtrlMultiSelect
                options={uniq(
                  allTags
                    .map((q) => q.getCreatedUnderWithResourceTypePrefix)
                    .flat()
                )}
                prefix="Created Under"
                onChange={(filterKs) =>
                  setFilterKeys({
                    ...filterKeys,
                    getCreatedUnderWithResourceTypePrefix: filterKs,
                  })
                }
              />
            )}
            {!isPortfolio && (
              <GVDSTableCtrlMultiSelect
                options={uniq(allTags.map((q) => q.resourceType).flat())}
                prefix="Tag Type"
                onChange={(filterKs) =>
                  setFilterKeys({
                    ...filterKeys,
                    resourceType: filterKs,
                  })
                }
              />
            )}
            <GVDSPagination
              startIndex={startIndex}
              endIndex={endIndex}
              total={totalDataLength}
              onChange={onPaginationChange}
            />
          </GVDSTableCtrlContainer>
          <GVDSTable
            columns={columns}
            dataToDisplay={currentPageData}
            startIndex={startIndex}
            sortKeys={sortKeys}
            setSortKeys={setSortKeys}
            className="tag-table"
          />
          {filteredSortedData.length === 0 && (
            <div className="table__no_content">No tag found</div>
          )}
        </div>
      );
    } else {
      content = <EmptyTagListPlaceholder isPortfolio={isPortfolio} />;
    }
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("admin.tags.page-title")}</h1>
          <GVDSIconButton
            variant={iconButtonVariant.tertiary}
            onClick={() => setShowTagsInfo(true)}
            icon={<GVDSIcon Icon={IconHelp} />}
            tooltipText="About Tags"
            className="about-tags-info"
          />
          <Spacer />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[PERMISSIONS.USER_MANAGED_TAG_CREATE] && (
              <GVDSButton
                className="ms-2 create-tag-button"
                variant={buttonVariant.primary}
                onClick={() => setShowTagInput(true)}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text={"Create " + (isPortfolio ? "Portfolio" : "Site") + " Tag"}
              />
            )}
        </PageHeader.Title>
      </PageHeader>
      {content}
      <AboutTagsModal show={showTagsInfo} close={closeTagsInfo} />
      <TagInputModal
        show={showTagInput}
        isPortfolio={isPortfolio}
        currentTag={currentEditedTag}
        onSuccess={loadTags}
        onClose={closeInputModal}
      />
      <TagDeletePrompt
        show={showTagDeletePrompt}
        tag={currentToBeDeletedTag}
        onClose={closeDeletePrompt}
        onDelete={loadTags}
      />
    </Container>
  );
};

export default withAuthentication(ViewAllTags);
