import axios from "axios";
import { API_URL } from "../config/api-config";
import get from "lodash/get";

export default class SiteBulkService {
  static getConfig = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/admin/onboarding/sites/config"
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static createSites = async (sites) => {
    try {
      const data = {
        sites
      };

      const response = await axios.post(
        API_URL + "/api/v1/admin/onboarding/sites",
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static validateSites = async (sites) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/admin/onboarding/sites/validate`,
        sites
      );
      return {
        dataErrors: {
          rowErrors: get(response.data, "data_errors.row_errors", {}),
          errorRowNumbers: get(
            response.data,
            "data_errors.error_row_numbers",
            []
          ),
        },
      };
    } catch (error) {
      return {};
    }
  };

  static createFloorAreas = async (areas) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/admin/onboarding/sites/areas",
        areas
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static validateFloorAreas = async (areas) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/admin/onboarding/sites/areas/validate",
        areas
      );
      return {
        dataErrors: {
          rowErrors: get(response.data, "data_errors.row_errors", {}),
          errorRowNumbers: get(
            response.data,
            "data_errors.error_row_numbers",
            []
          ),
        },
      };
    } catch (error) {
      throw error;
    }
  };

  static createUsers = async (users) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/admin/onboarding/sites/users",
        users
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static validateUsers = async (users) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/admin/onboarding/sites/users/validate",
        users
      );
      return {
        dataErrors: {
          rowErrors: get(response.data, "data_errors.row_errors", {}),
          errorRowNumbers: get(
            response.data,
            "data_errors.error_row_numbers",
            []
          ),
        },
      };
    } catch (error) {
      throw error;
    }
  };
}
