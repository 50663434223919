import axios from "axios";
import { API_URL } from "../config/api-config";
import get from "lodash/get";
import EnvironmentalDataRecordModel from "../components/Data/Environmental/EnvironmentalDataRecordModel";
import { DateTimeUtils, HttpResponseService } from "./UtilsService";
import _ from "lodash";
import { EnvironmentalMeterDataHealthStatusModel } from "../components/Data/Environmental/EnvironmentalMeterRecordsService";

export default class EnvironmentalService {
  static getAllDatasets = async (resource_id, resource_type) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/environmental_datasets`,
        { params: { resource_id, resource_type } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getAllDataRecords = async (resourceId, resourceType, start, end) => {
    try {
      const params = {
        resource_id: resourceId,
        resource_type: resourceType,
      };
      if (start) params["start"] = DateTimeUtils.getUTCISOString(start);
      if (end) params["end"] = DateTimeUtils.getUTCISOString(end);

      const response = await axios.get(
        `${API_URL}/api/v1/environmental_data_records`,
        { params }
      );

      let readings = [];
      for (const facilityRecords of response.data) {
        readings = readings.concat(
          facilityRecords["records"].map(
            (r) =>
              new EnvironmentalDataRecordModel(
                facilityRecords["id"],
                facilityRecords["name"],
                r
              )
          )
        );
      }
      return readings;
    } catch (error) {
      throw error;
    }
  };

  static getDataRecordsForMeter = async (
    resource_type,
    resource_id,
    meterId,
    start,
    end
  ) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/environmental_meters/${meterId}/environmental_data_records`,
        {
          params: {
            resource_type,
            resource_id,
            start: DateTimeUtils.getUTCISOString(start),
            end: DateTimeUtils.getUTCISOString(end),
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getDataHealthMetersStatus = async (
    resource_type,
    resource_id,
    start,
    end
  ) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/data_health/environmental_meters_status`,
        {
          params: {
            resource_type,
            resource_id,
            start: DateTimeUtils.getUTCISOString(start),
            end: DateTimeUtils.getUTCISOString(end),
          },
        }
      );
      return response.data.map((dto) =>
        EnvironmentalMeterDataHealthStatusModel.fromDTO(dto)
      );
    } catch (error) {
      throw error;
    }
  };

  static createMeter = async (
    resource_type,
    resource_id,
    subtopicId,
    typeId,
    meterDetails
  ) => {
    const facilityId = meterDetails.facilityId;
    const data = {
      environmental_subtopic: subtopicId,
      environmental: {
        environmental_type_id: typeId,
        unit_id: meterDetails.unitId,
        currency_id: meterDetails.currencyId,
        name: meterDetails.meterName,
        for_monitoring_only: meterDetails.monitoringOnly
          ? meterDetails.monitoringOnly
          : false,
        frequency: meterDetails.frequency,
        description: meterDetails.description,
      },
    };

    try {
      return await axios.post(
        `${API_URL}/api/v1/facilities/${facilityId}/environmental_meters`,
        data,
        {
          params: { resource_type, resource_id },
        }
      );
    } catch (error) {
      throw error;
    }
  };

  static editMeter = async (
    resource_type,
    resource_id,
    selectedMeterId,
    meterDetails
  ) => {
    const facilityId = meterDetails.facilityId;
    const environmental_meter = {
      unit_id: meterDetails.unitId,
      currency_id: meterDetails.currencyId,
      name: meterDetails.meterName,
      for_monitoring_only: meterDetails.monitoringOnly
        ? meterDetails.monitoringOnly
        : false,
      frequency: meterDetails.frequency,
      description: meterDetails.description,
    };

    try {
      return await axios.put(
        `${API_URL}/api/v1/facilities/${facilityId}/environmental_meters/${selectedMeterId}`,
        environmental_meter,
        { params: { resource_type, resource_id } }
      );
    } catch (error) {
      throw error;
    }
  };

  static editMeterEffectivePeriod = async (
    resource_type,
    resource_id,
    facilityId,
    selectedMeterId,
    start_date,
    end_date
  ) => {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/facilities/${facilityId}/environmental_meters/${selectedMeterId}/effective_period`,
        {
          start_date: DateTimeUtils.getUTCISOString(start_date),
          end_date: DateTimeUtils.getUTCISOString(end_date),
        },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static validateSingleDataRecord = async (
    resource_type,
    resource_id,
    meter_id,
    start,
    end,
    usage,
    unit_id,
    cost,
    currency_id,
    comments,
    record_id = null
  ) => {
    const reading = {
      period_from: DateTimeUtils.getUTCISOString(start),
      period_to: DateTimeUtils.getUTCISOString(end),
      meter_id,
      usage,
      unit_id,
      cost: cost ? cost : null,
      currency_id,
      comments,
    };

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/facilities/${resource_id}/environmental_data_records/validate`,
        reading,
        { params: { resource_type, resource_id, record_id } }
      );
      return {
        dataErrors: get(response.data, "data_errors", {}),
        possibleErrors: get(response.data, "possible_errors", {}),
      };
    } catch (error) {
      throw error;
    }
  };

  static createDataRecord = async (
    resource_type,
    resource_id,
    facilityId,
    { meter_id, start, end, usage, unit_id, cost, currency_id, comments }
  ) => {
    const reading = {
      period_from: DateTimeUtils.getUTCISOString(start),
      period_to: DateTimeUtils.getUTCISOString(end),
      meter_id,
      usage,
      unit_id,
      cost: cost ? cost : null,
      currency_id,
      comments,
    };

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/facilities/${facilityId}/environmental_data_records`,
        reading,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editDataRecord = async (
    resource_type,
    resource_id,
    recordId,
    { meter_id, start, end, usage, unit_id, cost, currency_id, comments }
  ) => {
    const reading = {
      period_from: DateTimeUtils.getUTCISOString(start),
      period_to: DateTimeUtils.getUTCISOString(end),
      meter_id,
      usage,
      unit_id,
      cost: cost ? cost : null,
      currency_id,
      comments,
    };

    try {
      const response = await axios.put(
        `${API_URL}/api/v1/environmental_data_records/${recordId}`,
        reading,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static createDataRecords = async (resource_type, resource_id, records) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/environmental_data_records`,
        records,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static validateDataRecords = async (resource_type, resource_id, records) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/environmental_data_records/validate`,
        records,
        { params: { resource_type, resource_id } }
      );
      return {
        dataErrors: {
          rowErrors: get(response.data, "data_errors.row_errors", {}),
          errorRowNumbers: get(
            response.data,
            "data_errors.error_row_numbers",
            []
          ),
        },
        possibleErrors: {
          rowErrors: get(response.data, "possible_errors.row_errors", {}),
          errorRowNumbers: get(
            response.data,
            "possible_errors.error_row_numbers",
            []
          ),
        },
      };
    } catch (error) {
      return {};
    }
  };

  static deleteDataRecords = async (resource_type, resource_id, recordIds) => {
    try {
      return await axios.delete(
        `${API_URL}/api/v1/environmental_data_records`,
        {
          data: { record_ids: recordIds },
          params: { resource_type, resource_id },
        }
      );
    } catch (error) {
      throw error;
    }
  };

  static getMeterStatus = async (meter_id, resource_id, resource_type) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/environmental_meters/${meter_id}/status`,
        {
          params: { resource_id, resource_type },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static deleteMeter = async (meterData) => {
    const facilityId = meterData.facilityId;
    const meterId = meterData.meterId;
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/facilities/${facilityId}/environmental_meters/${meterId}`
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  static getBulkConfig = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/environmental_data_records/config`,
        {
          params: { resource_id, resource_type },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static downloadTemplate = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/environmental_data_records/template`,
        {
          params: { resource_id, resource_type },
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "BulkEnvironmentalInputTemplate.xlsx"
      );
    } catch (error) {
      throw error;
    }
  };

  static getAllCustomEmissionFactors = async (resource_id, resource_type) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/environmental_custom_emission_factors`,
        { params: { resource_id, resource_type } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static createCustomEmissionFactor = async (
    resource_type,
    resource_id,
    site_id,
    year,
    environmental_type_id,
    factor,
    source_description
  ) => {
    const data = {
      site_id,
      year,
      environmental_type_id,
      factor,
      source_description,
    };

    try {
      return await axios.post(
        `${API_URL}/api/v1/environmental_custom_emission_factors`,
        data,
        {
          params: { resource_type, resource_id },
        }
      );
    } catch (error) {
      throw error;
    }
  };

  static editCustomEmissionFactor = async (
    resource_type,
    resource_id,
    selectedEnvironmentalCustomEmissionFactorId,
    site_id,
    year,
    environmental_type_id,
    factor,
    source_description
  ) => {
    const data = {
      site_id,
      year,
      environmental_type_id,
      factor,
      source_description,
    };
    try {
      return await axios.put(
        `${API_URL}/api/v1/environmental_custom_emission_factor/${selectedEnvironmentalCustomEmissionFactorId}`,
        data,
        { params: { resource_type, resource_id } }
      );
    } catch (error) {
      throw error;
    }
  };

  static deleteCustomEmissionFactor = async (
    selectedEnvironmentalCustomEmissionFactorId,
    resource_type,
    resource_id
  ) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/environmental_custom_emission_factor/${selectedEnvironmentalCustomEmissionFactorId}`,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static downloadEnvironmentalDataRecordExcel = async (
    resourceType,
    resourceId,
    start,
    end,
    facilityIds,
    subtopicIds,
    typeIds,
    meterIds
  ) => {
    const params = {
      resource_id: resourceId,
      resource_type: resourceType,
    };
    const data = {};
    if (start) data["start"] = DateTimeUtils.getUTCISOString(start);
    if (end) data["end"] = DateTimeUtils.getUTCISOString(end);
    if (!_.isEmpty(facilityIds)) data["facility_ids"] = facilityIds;
    if (!_.isEmpty(subtopicIds))
      data["environmental_subtopic_ids"] = subtopicIds;
    if (!_.isEmpty(typeIds)) data["environmental_type_ids"] = typeIds;
    if (!_.isEmpty(meterIds)) data["meter_ids"] = meterIds;

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/environmental_data_records/download`,
        data,
        {
          params: params,
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Environmental Data Record Excel.xlsx"
      );
    } catch (error) {
      throw error;
    }
  };

  static updateAlertPreferences = async (
    resource_type,
    resource_id,
    selectedMeterId,
    dismissAlertsDate,
    enableDataGapsAlert,
    enableUnusuallyHighLowAlert,
    effectivePeriodStartDate,
    effectivePeriodEndDate,
    facilityId
  ) => {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/facilities/${facilityId}/environmental_meters/${selectedMeterId}/alert_preferences`,
        {
          dismiss_alerts_date: DateTimeUtils.getUTCISOString(dismissAlertsDate),
          enable_data_gaps_alert: enableDataGapsAlert,
          enable_unusually_high_low_alert: enableUnusuallyHighLowAlert,
          start_date: DateTimeUtils.getUTCISOString(effectivePeriodStartDate),
          end_date: DateTimeUtils.getUTCISOString(effectivePeriodEndDate),
        },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export const DataRecordErrorValidationEnum = {
  DATA_RECORD_ALREADY_EXIST_FOR_ENTERED_PERIOD:
    "A data record already exists in the system for the time period you have entered.",
};

export const PossibleErrorValueOutOfRangeComparisonEnum = {
  HIGH: "high",
  LOW: "low",
};
