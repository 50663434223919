import React, { useCallback, useContext, useEffect, useState } from "react";
import { withAmplify } from "./AWSAmplifyContext";
import UserService from "../services/UserService";
import ToastContext from "./ToastContext";

const UserProfileContext = React.createContext(null);
export const LanguageContext = React.createContext(null);

export const UserProfileProvider = withAmplify(({ amplify, children }) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [langCode, setLangCode] = useState(null);

  const loadUserProfile = async () => {
    setIsLoading(true);
    amplify.getCurrentUser().then((amplifyUserData) => {
      if (amplifyUserData && amplifyUserData["username"]) {
        UserService.getUserProfile()
          .then((profile) => {
            setUserProfile(profile);
            setLangCode(profile.lang_code);
            setIsLoading(false);
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              toastContext.clearAllToast();
              clearUserProfile();
              amplify.signOut();
            } else {
              setIsLoading(false);
              toastContext.addFailToast(
                <span>Failed to load user profile. Please refresh</span>
              );
            }
          });
      } else {
        setUserProfile(null);
        setIsLoading(false);
      }
    });
  };

  const clearUserProfile = () => {
    setUserProfile(null);
    setLangCode(null);
  };

  useEffect(() => {
    amplify.currentUser.subscribe((user) => {
      if (user) {
        loadUserProfile();
      } else {
        clearUserProfile();
      }
    });
  }, [amplify]);

  const store = useCallback(
    {
      loadUserProfile: loadUserProfile,
      isLoadingUserProfile: isLoading,
      getUserProfile: () => userProfile,
      clearUserProfile: clearUserProfile,
    },
    [isLoading, userProfile]
  );

  const languageStore = useCallback(
    {
      getLangCode: () => langCode,
      setLangCode: (newLangCode) => {
        setLangCode(newLangCode);
      },
    },
    [isLoading, userProfile, langCode]
  );

  return (
    <UserProfileContext.Provider value={store}>
      <LanguageContext.Provider value={languageStore}>
        {children}
      </LanguageContext.Provider>
    </UserProfileContext.Provider>
  );
});

export default UserProfileContext;
