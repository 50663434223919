import ProgressBar from "react-bootstrap/ProgressBar";
import React, { memo, useContext, useState } from "react";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconDownload } from "@tabler/icons-react";
import {
  infoTooltipPopupDelayStart,
  TOOLTIP_PLACEMENTS,
} from "../../../config/style-config";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import { getViewCertificationContentPagePath } from "../../../config/ROUTES_NAME";
import { useHistory, useLocation } from "react-router-dom";
import CertificationContext from "../CertificationContext";
import { MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY } from "./CertificationHomepage";
import { NoContentError } from "../../../errors/NoContentError";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import NoEvidenceUploadedModal from "../NoEvidenceUploadedModal";
import { CertificationAssessmentService } from "../../../services/CertificationService";

const CategoryFilter = ({ truncatedCategoryName, categoryCSSMinWidth }) => {
  return (
    <div
      style={{ minWidth: categoryCSSMinWidth }}
      className="certification-homepage__criterion__category"
    >
      {truncatedCategoryName}
    </div>
  );
};

const CertificationCriterion = memo(
  ({
    categoryName,
    criterion,
    criterionNumberCSSMinWidth,
    categoryCSSMinWidth,
    isClickable = false,
    isHidden = false,
  }) => {
    const userInventory = useContext(UserInventoryContext);
    const toastContext = useContext(ToastContext);
    const certificationContext = useContext(CertificationContext);
    const history = useHistory();
    const location = useLocation();

    const [isShowNoEvidenceUploadedModal, setIsShowNoEvidenceUploadedModal] =
      useState(false);

    const completedPercentage =
      criterion.completedRequirementCount ===
      criterion.totalApplicableRequirementCount
        ? 100
        : Math.round(
            (criterion.completedRequirementCount /
              criterion.totalApplicableRequirementCount) *
              100
          );

    const isCategoryNameTruncated =
      categoryName.length >
      MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY;
    const truncatedCategoryName = isCategoryNameTruncated
      ? `${categoryName.slice(
          0,
          MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY + 1
        )}...`
      : categoryName;

    const gotoCertificationContentPage = () => {
      history.push(
        getRedirectURLWithCurrentParam(
          getViewCertificationContentPagePath(
            certificationContext.certificationId,
            criterion.id
          ),
          location
        )
      );
    };

    const downloadCriterionEvidence = async (criterion) => {
      const currentInventory = userInventory.selectedInventory.get;

      try {
        await CertificationAssessmentService.downloadEvidence(
          currentInventory.type,
          currentInventory.id,
          certificationContext.certificationId,
          criterion.id
        );
        toastContext.addSuccessToast(
          <span>All files have been downloaded successfully.</span>
        );
      } catch (error) {
        if (error instanceof NoContentError) {
          setIsShowNoEvidenceUploadedModal(true);
        } else {
          toastContext.addFailToast(<span>Failed to download files.</span>);
        }
      }
    };

    return (
      <>
        <div
          className={`certification-homepage__criterion__container
      ${completedPercentage === 100 ? " completed" : ""}
      ${isClickable ? " hoverable" : ""}
      ${isHidden ? " d-none" : ""}`}
          onClick={() => (isClickable ? gotoCertificationContentPage() : null)}
        >
          <div className="certification-homepage__criterion__details__container">
            <div
              className="certification-homepage__criterion__number"
              style={{ minWidth: `${criterionNumberCSSMinWidth}px` }}
            >
              {criterion.number}
            </div>
            <div>
              <div className="gvds-text--heading4">{criterion.name}</div>
              <div className="certification-homepage__criterion__description">
                {criterion.description}
              </div>
            </div>
          </div>
          {isCategoryNameTruncated ? (
            <OverlayTrigger
              placement={TOOLTIP_PLACEMENTS.TOP}
              delay={infoTooltipPopupDelayStart}
              overlay={
                <Popover>
                  <Popover.Body>{categoryName}</Popover.Body>
                </Popover>
              }
            >
              <div className="flex-shrink-0">
                <CategoryFilter
                  truncatedCategoryName={truncatedCategoryName}
                  categoryCSSMinWidth={categoryCSSMinWidth}
                />
              </div>
            </OverlayTrigger>
          ) : (
            <div className="flex-shrink-0">
              <CategoryFilter
                truncatedCategoryName={truncatedCategoryName}
                categoryCSSMinWidth={categoryCSSMinWidth}
              />
            </div>
          )}
          <div className="certification-homepage__criterion__requirements">
            <ProgressBar now={completedPercentage} />
            <div className="gvds-text--caption mt-1">{`${criterion.completedRequirementCount}/${criterion.totalApplicableRequirementCount} requirements`}</div>
          </div>
          <OverlayTrigger
            placement={TOOLTIP_PLACEMENTS.TOP}
            delay={infoTooltipPopupDelayStart}
            overlay={
              <Popover>
                <Popover.Body>Download criteria evidence</Popover.Body>
              </Popover>
            }
          >
            <div>
              <GVDSIconButton
                variant={iconButtonVariant.tertiary}
                icon={<GVDSIcon Icon={IconDownload} />}
                onClick={async (event) => {
                  event.stopPropagation();
                  await downloadCriterionEvidence(criterion);
                }}
              />
            </div>
          </OverlayTrigger>
        </div>
        <NoEvidenceUploadedModal
          isShow={isShowNoEvidenceUploadedModal}
          closeModal={() => setIsShowNoEvidenceUploadedModal(false)}
          displayedText="There are no evidence files uploaded for this criteria."
        />
      </>
    );
  }
);

export default CertificationCriterion;
