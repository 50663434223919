import React, { useContext } from "react";
import UserProfileContext from "../../context/UserProfileContext";

export const isPrereqReady = (userProfileContext) => {
  let userProfile = userProfileContext.getUserProfile();

  return !(
    userProfileContext.isLoadingUserProfile ||
    !userProfile ||
    !userProfile.accepted_terms_conditions ||
    !userProfile.accepted_privacy_policy
  );
};

// ref https://stackoverflow.com/a/57783573
export const withPrereqReady = (WrappedComponent) => {
  return (props) => {
    const userProfileContext = useContext(UserProfileContext);

    if (isPrereqReady(userProfileContext)) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };
};
