import React, { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import RiskAssessmentReportService from "../../../services/Report/RiskAssessmentReportService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import Form from "react-bootstrap/Form";
import { UtilsService } from "../../../services/UtilsService";
import GeneratedReports from "./GeneratedReports";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import { buttonVariant } from "../../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import { IconMailFilled } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const RiskAssessmentReport = () => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [riskParameters, setRiskParameters] = useState([]);

  const [isValidated, setIsValidated] = useState(false);
  const [selectedRiskParameterNames, setSelectedRiskParameterNames] = useState(
    []
  );

  const [isReportBeingGenerated, setIsReportBeingGenerated] = useState(false);
  const [generatedReportLastUpdate, setGeneratedReportLastUpdate] = useState(
    new Date()
  );

  useEffect(() => {
    if (selectedInventory?.id) {
      setIsLoadingConfig(true);
      RiskAssessmentReportService.getReportConfig(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((data) => {
          setRiskParameters(data.risk_parameters);
          setSelectedRiskParameterNames(
            data.risk_parameters.map((p) => p.name)
          );
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load report configuration.</span>
          );
        })
        .finally(() => {
          setIsLoadingConfig(false);
        });
    }
  }, [selectedInventory]);

  const toggleRiskParameterSelection = (parameterName) => {
    setSelectedRiskParameterNames(
      UtilsService.toggleItem(selectedRiskParameterNames, parameterName)
    );
  };

  const onGenerateReport = async () => {
    setIsValidated(true);

    if (selectedRiskParameterNames.length === 0) return;

    try {
      await RiskAssessmentReportService.triggerReportGeneration(
        selectedInventory.type,
        selectedInventory.id,
        selectedRiskParameterNames
      );

      setGeneratedReportLastUpdate(new Date());
    } catch (e) {
      toastContext.addFailToast(<span>Failed to generate report.</span>);
    }
  };

  const updateReportBeingGeneratedBanner = (hasQueuedReport) => {
    setIsReportBeingGenerated(hasQueuedReport);
  };

  let reportConfigContent;
  let reportBeingGeneratedBanner;

  if (isLoadingConfig) {
    reportConfigContent = (
      <div className="report-config">
        <div className="m-3">
          <LoadingSpinner />
        </div>
      </div>
    );
  } else {
    reportConfigContent = (
      <div className="report-config">
        <div className="report-config-options">
          <div className="mb-3">Select risk categories:</div>
          {riskParameters.map((p) => {
            return (
              <div key={p.name} className="report-config-risk-category-option">
                <Form.Check
                  checked={selectedRiskParameterNames.indexOf(p.name) >= 0}
                  type="checkbox"
                  id={p.name}
                  label=""
                  onChange={() => toggleRiskParameterSelection(p.name)}
                />
                <div className="report-config-risk-category-name">
                  <strong>{p.name}</strong> - {p.description}
                </div>
              </div>
            );
          })}
          {isValidated && selectedRiskParameterNames.length === 0 && (
            <div className="manual-invalid-feedback">
              Please select at least one risk category.
            </div>
          )}
        </div>
        <div className="report-config-footer">
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            onClickAsyncFunc={onGenerateReport}
            text="Generate Report"
          />
        </div>
      </div>
    );
  }

  if (isReportBeingGenerated) {
    reportBeingGeneratedBanner = (
      <GVDSBanner
        title="Your report is being generated. You will receive an email when it's ready."
        variant={GVDSBanner.Variants.success}
        icon={IconMailFilled}
        className="mt-2"
      >
        As this report is large, it may take some time to generate. You can
        leave this page and return later to download it. To update the status,
        refresh the page or click "Refresh Status" below.
      </GVDSBanner>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("reports.risk-assessment.page-title")}</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          {t("reports.risk-assessment.page-description")}
        </PageHeader.Description>
      </PageHeader>
      {reportConfigContent}
      {reportBeingGeneratedBanner}
      <div className="mt-3">
        <GeneratedReports
          lastUpdate={generatedReportLastUpdate}
          onLoadReports={updateReportBeingGeneratedBanner}
        />
      </div>
    </div>
  );
};

export default RiskAssessmentReport;
