import React, { useContext, useEffect, useState } from "react";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { useHistory, useLocation } from "react-router-dom";

import ViewAllOperationalDataRecords from "./ViewAllOperationalDataRecords";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";

import {
  OPERATIONAL_DATA_BULK_INPUT,
  OPERATIONAL_DATA_SETUP,
} from "../../../config/ROUTES_NAME";
import InputOperationalDataRecordModal from "./InputOperationalDataRecordModal";
import OperationalDashboard from "./OperationalDashboard";
import UserInventoryContext from "../../../context/UserInventoryContext";
import OperationalService from "../../../services/OperationalService";
import LoadingSpinner from "../../common/LoadingSpinner";
import { type_hotel } from "../../Site/SiteFacilityInformationComponents";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSDropdownToggle from "../../../gvds-components/Buttons/GVDSDropdownToggle";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconSettings } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export const isOperationalDisabled = (resource, inventory, facilities) => {
  if (inventory.type === RESOURCES.SITE) {
    const facilityWithHotelType = facilities.map(
      (facility) => facility.nodeValue.value.type
    );
    const hotelTypeFacilityCount = facilityWithHotelType.filter(
      (facilityType) => facilityType.name.toLowerCase() === type_hotel
    ).length;

    return hotelTypeFacilityCount <= 0;
  } else if (inventory.type === RESOURCES.FACILITY) {
    return (
      inventory.type === RESOURCES.FACILITY &&
      resource.type.name.toLowerCase() !== type_hotel
    );
  }
};

const Operational = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [datasets, setDatasets] = useState([]);
  const [showInputRecord, setShowInputRecord] = useState(false);
  const [disableOperational, setDisableOperational] = useState(true);

  useEffect(() => {
    if (!disableOperational) {
      const inventory = userInventory.selectedInventory.get;
      setIsLoading(true);
      setDatasets([]);

      OperationalService.getAllDatasets(inventory.id, inventory.type)
        .then((ds) => {
          setDatasets(ds);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load operational meters. Please try again.</span>
          );
        });
    }
  }, [disableOperational, userInventory.selectedInventory.get]);

  useEffect(() => {
    setDisableOperational(true);
    const inventory = userInventory.selectedInventory.get;
    if (inventory && inventory.id) {
      setDisableOperational(
        isOperationalDisabled(
          userInventory.selectedTreeNode.get.nodeValue.value,
          inventory,
          userInventory.selectedTreeNode.get.children
        )
      );
    }
  }, [userInventory.selectedInventory.get]);

  const goToOperationalSetup = () => {
    history.push(
      getRedirectURLWithCurrentParam(OPERATIONAL_DATA_SETUP, location)
    );
  };

  const goToBulkInput = () => {
    history.push(
      getRedirectURLWithCurrentParam(OPERATIONAL_DATA_BULK_INPUT, location)
    );
  };

  if (disableOperational) {
    return (
      <div>
        <PageHeader>
          <PageHeader.Title>
            <h1>{t("data-management.operational.page-title")}</h1>
          </PageHeader.Title>
        </PageHeader>
        <div className="table__no_content mt-2">
          <p>
            {t("data-management.operational.not-available-for-non-hotel")}
          </p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("data-management.operational.page-title")}</h1>
          <Spacer />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.OPERATIONAL_METER_MANAGEMENT
            ] && (
              <GVDSButton
                variant={buttonVariant.secondary}
                className="operational-setup"
                onClick={() => goToOperationalSetup()}
                icon={<GVDSIcon Icon={IconSettings} />}
                text="Setup"
              />
            )}
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.OPERATIONAL_RECORD_CREATE
            ] && (
              <Dropdown className="ms-2">
                <Dropdown.Toggle as={GVDSDropdownToggle}>Input</Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item onClick={() => setShowInputRecord(true)}>
                    <GVDSIcon Icon={IconCirclePlus} />
                    Single Record
                  </Dropdown.Item>
                  <Dropdown.Item onClick={goToBulkInput}>
                    <GVDSIcon Icon={IconCirclePlus} />
                    Bulk Records
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="data-input">
        <Tab eventKey="data-input" title="Data Input">
          <OperationalDashboard
            lastUpdate={lastUpdate}
            datasets={datasets}
            onUpdate={() => setLastUpdate(new Date())}
          />
        </Tab>
        <Tab eventKey="history" title="All Records">
          <ViewAllOperationalDataRecords
            lastUpdate={lastUpdate}
            datasets={datasets}
          />
        </Tab>
      </Tabs>

      <InputOperationalDataRecordModal
        show={showInputRecord}
        onClose={() => setShowInputRecord(false)}
        datasets={datasets}
        onDoneInput={() => {
          setLastUpdate(new Date());
        }}
      />
    </div>
  );
};

export default Operational;
