import React from "react";
import { matchPath, useLocation } from "react-router-dom";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { getViewHelpArticlePath } from "../../config/ROUTES_NAME";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import HelpArticleService from "../../services/HelpArticleService";
import GVDSLink from "../../gvds-components/Buttons/GVDSLink";
import { ContentTypeDisplay } from "../common/ContentTypeDisplay";
import { useTranslation } from "react-i18next";

const getMatchingPathForRelevantHelpArticles = (
  pathname,
  helpArticlesByPath
) => {
  return Object.keys(helpArticlesByPath).find((mappedPath) => {
    const isMappedPathLandingPage = mappedPath === "/";
    if (isMappedPathLandingPage) {
      return matchPath(pathname, { path: mappedPath, exact: true });
    } else {
      return matchPath(pathname, { path: mappedPath, exact: false });
    }
  });
};

export const hasRelevantHelpArticles = (pathname, helpArticleBypath) => {
  return !!getMatchingPathForRelevantHelpArticles(pathname, helpArticleBypath);
};

const RelevantHelpArticleDisplay = ({ helpArticleListItem }) => {
  let location = useLocation();

  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Popover>
          <Popover.Body>{helpArticleListItem.description}</Popover.Body>
        </Popover>
      }
    >
      <div className="text-vertical-center help-article__container">
        <GVDSLink
          to={getRedirectURLWithCurrentParam(
            getViewHelpArticlePath(helpArticleListItem.id),
            location
          )}
          variant={GVDSLink.variant.media_title}
          target="_blank"
          rel="noopener noreferrer"
        >
          {helpArticleListItem.title}
        </GVDSLink>
        <ContentTypeDisplay
          contentTypeName={helpArticleListItem.articleType.name}
        />
      </div>
    </OverlayTrigger>
  );
};

const FloatingAssistantRelevantHelpArticles = ({ helpArticlesByPath }) => {
  const { t } = useTranslation();

  let location = useLocation();
  const pathname = location.pathname;

  const matchingPath = getMatchingPathForRelevantHelpArticles(
    pathname,
    helpArticlesByPath
  );

  if (!matchingPath) {
    return null;
  }

  const helpArticles = helpArticlesByPath[matchingPath].sort(
    HelpArticleService.HelpArticleSortFn
  );

  return (
    <div className="relevant-help-article-menu">
      <div className="relevant-help-article-menu__header">
        {t("floating-assistant.recommended-guides")}
      </div>
      <div className="relevant-help-article-menu__list-container">
        {helpArticles.map((helpArticleListItem) => {
          return (
            <RelevantHelpArticleDisplay
              key={helpArticleListItem.id}
              helpArticleListItem={helpArticleListItem}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FloatingAssistantRelevantHelpArticles;
