import { TARGET_PERIOD_TYPE } from "../../config/constants";
import GVFormGroup from "../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import GVDSFormSingleYearPicker from "../../gvds-components/Forms/GVDSFormSingleYearPicker";
import GVDSFormStartEndYearPicker from "../../gvds-components/Forms/GVDSFormStartEndYearPicker";

const InputTargetStepTargetPeriod = ({
  title,
  params,
  setParams,
  isInvalid,
}) => {
  const currentYear = new Date().getFullYear();

  return (
    <GVFormGroup>
      <div className="gv-body-2--bold gvds-color--gray6">{title}</div>
      <div className="p-3">
        <Form.Label>
          How would you like to track your target progress?
        </Form.Label>
        <Form.Check
          id="annual-target"
          label="Trailing-12 months (e.g. Apr  2020 - Mar 2021)"
          type="radio"
          checked={
            params.period_type === undefined
              ? false
              : params.period_type === TARGET_PERIOD_TYPE.ANNUAL
          }
          onChange={() =>
            setParams({
              ...params,
              period_type: TARGET_PERIOD_TYPE.ANNUAL,
              baseline_year: null,
              target_year: null,
            })
          }
        />
        <Form.Check
          id="total-target"
          label="Over the entire date range (from start date to most recent full month)"
          type="radio"
          checked={
            params.period_type === undefined
              ? false
              : params.period_type === TARGET_PERIOD_TYPE.TOTAL
          }
          onChange={() =>
            setParams({
              ...params,
              period_type: TARGET_PERIOD_TYPE.TOTAL,
              baseline_year: null,
              target_year: null,
            })
          }
        />

        {params.period_type === TARGET_PERIOD_TYPE.ANNUAL && (
          <Row>
            <Col sm={4}>
              <GVFormGroup className="mt-2">
                <Form.Label>Target Year</Form.Label>
                <GVDSFormSingleYearPicker
                  selected={params.target_year}
                  minYear={
                    params.comparison_year ? params.comparison_year + 1 : null
                  }
                  onChange={(year) =>
                    setParams({ ...params, target_year: year })
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>
        )}
        {params.period_type === TARGET_PERIOD_TYPE.TOTAL && (
          <Row className="mt-2">
            <Col sm={5}>
              <GVFormGroup>
                <GVDSFormStartEndYearPicker
                  startYearLabel={<Form.Label>Start of year</Form.Label>}
                  startYear={params.baseline_year}
                  endYearLabel={<Form.Label>End of year</Form.Label>}
                  endYear={params.target_year}
                  minEndYear={params.target_year}
                  onChange={(startYear, endYear) => {
                    setParams({
                      ...params,
                      baseline_year: startYear,
                      target_year:
                        endYear && startYear > endYear ? startYear : endYear,
                    });
                  }}
                />
              </GVFormGroup>
            </Col>
          </Row>
        )}
        {!params.period_type && (
          <div className="create-target-form__locked-content mt-2 mb-3">
            This section will appear once you have selected an option above
          </div>
        )}
        {isInvalid && (
          <div className="manual-invalid-feedback">Please complete step 4.</div>
        )}
      </div>
    </GVFormGroup>
  );
};

export default InputTargetStepTargetPeriod;
