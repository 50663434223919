import React from "react";
import {
  infoTooltipPopupDelayStart,
  TOOLTIP_PLACEMENTS,
} from "../../config/style-config";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const GVDSFilterButton = ({
  className,
  isSelected = false,
  filterName,
  isDisabled = false,
  maxAllowedChars,
  onClick,
}) => {
  const isTruncated = filterName.length > maxAllowedChars;

  if (isTruncated) {
    return (
      <OverlayTrigger
        placement={TOOLTIP_PLACEMENTS.TOP}
        delay={infoTooltipPopupDelayStart}
        overlay={
          <Popover>
            <Popover.Body>{filterName}</Popover.Body>
          </Popover>
        }
      >
        <div
          className={`gvds-filter-button${isSelected ? " active" : ""}
          ${isDisabled ? " disabled" : ""}
          ${className !== null ? " " + className : ""}`}
          onClick={() => {
            if (!isDisabled) {
              onClick(filterName);
            }
          }}
        >
          {`${filterName.slice(0, maxAllowedChars + 1)}...`}
        </div>
      </OverlayTrigger>
    );
  } else {
    return (
      <div
        className={`gvds-filter-button${isSelected ? " active" : ""}
        ${isDisabled ? " disabled" : ""}
        ${className !== null ? " " + className : ""}`}
        onClick={() => {
          if (!isDisabled) {
            onClick(filterName);
          }
        }}
      >
        {filterName}
      </div>
    );
  }
};

export default GVDSFilterButton;
