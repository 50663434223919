import React from "react";

export const FormFieldState = {
  normal: "normal",
  warning: "warning",
  error: "error",
};

export class FormFieldStatusMetadata {
  constructor(state, message) {
    this.state = state;
    this.message = message;
  }

  isNotNormal() {
    return this.state !== FormFieldState.normal;
  }

  static getDefault() {
    return FormFieldStatusMetadataDefault;
  }

  static getWarning(message) {
    return new FormFieldStatusMetadata(FormFieldState.warning, message);
  }

  static getError(message) {
    return new FormFieldStatusMetadata(FormFieldState.error, message);
  }
}

const FormFieldStatusMetadataDefault = new FormFieldStatusMetadata(
  FormFieldState.normal,
  null
);

export const GVDSFormErrorMessage = ({
  status = FormFieldState.error,
  errorMsg,
}) => {
  return (
    <div
      className={
        status === FormFieldState.warning
          ? "gvds-form__warning-message"
          : status === FormFieldState.error
          ? "gvds-form__error-message"
          : null
      }
    >
      {errorMsg}
    </div>
  );
};

export const getDateLabel = (dateLabel, placeholder) => {
  return (
    <div className={dateLabel ? "" : "invisible-placeholder"}>
      {dateLabel ?? placeholder}
    </div>
  );
};

export const isEndDateBeforeStartDate = (startDate, endDate) => {
  return endDate - startDate < 0;
};

export const getMinEndDate = (startDate, minEndDate) => {
  if (startDate && minEndDate) {
    return Math.max(startDate, minEndDate);
  } else {
    return startDate ?? minEndDate;
  }
};
