import Container from "react-bootstrap/Container";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import React, { useContext, useEffect, useState } from "react";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import {
  IconArrowsUpDown,
  IconEdit,
  IconEye,
  IconEyeOff,
} from "@tabler/icons-react";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import LoadingSpinner from "../../common/LoadingSpinner";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import withAuthentication from "../../HOC/withAuthentication";
import {
  getAdminEditCertificationPath,
  SYSTEM_TOOLBOX_CERTIFICATION_SETUP,
} from "../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import ToastContext from "../../../context/ToastContext";
import CertificationAdminService from "../../../services/PortalAdmin/Certification/CertificationAdminService";
import {
  MoveCertificationDialog,
  PublishUnpublishCertificationDialog,
} from "./CertificationModals";
import LastUpdatedOnDisplay from "../LastUpdatedOnDisplay";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { CertificationStatus } from "./CertificationModel";

const CertificationList = () => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();

  const goToEdit = (certification) => {
    history.push(getAdminEditCertificationPath(certification.id));
  };

  const [certifications, setCertifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [certificationToBeMoved, setCertificationToBeMoved] = useState(null);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const closeMoveDialog = () => {
    setCertificationToBeMoved(null);
    setShowMoveDialog(false);
  };
  const promptMoveCertification = (certification) => {
    setCertificationToBeMoved(certification);
    setShowMoveDialog(true);
  };

  const onSuccessMoveCertification = (updatedCertifications) => {
    setCertifications(updatedCertifications);
    setShowMoveDialog(false);
  };

  const [selectedCertification, setSelectedCertification] = useState(null);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [
    isSavingCertificationPublishUnpublish,
    setIsSavingCertificationPublishUnpublish,
  ] = useState(false);
  const toggleCertificationPublishStatus = async () => {
    setIsSavingCertificationPublishUnpublish(true);

    if (selectedCertification.isPublished) {
      try {
        await CertificationAdminService.unpublishCertification(
          selectedCertification.id
        );
        toastContext.addSuccessToast(
          <span>
            Certification successfully unpublished.
            <br />
            It may take up to 5 minutes for the certification to be hidden from
            the side navigation
          </span>
        );
      } catch {
        toastContext.addFailToast(
          <span>Failed to unpublish certification.</span>
        );
      } finally {
        setIsSavingCertificationPublishUnpublish(false);
      }
    } else {
      try {
        await CertificationAdminService.publishCertification(
          selectedCertification.id
        );
        toastContext.addSuccessToast(
          <span>
            Certification successfully published.
            <br />
            It may take up to 5 minutes for the certification to be displayed on
            the side navigation
          </span>
        );
      } catch {
        toastContext.addFailToast(
          <span>Failed to publish certification.</span>
        );
      } finally {
        setIsSavingCertificationPublishUnpublish(false);
      }
    }
    setShowPublishDialog(false);

    const processedCertification = certifications.find(
      (cert) => cert.id === selectedCertification.id
    );
    processedCertification.isPublished = !selectedCertification.isPublished;
    processedCertification.status = processedCertification.isPublished
      ? CertificationStatus.published
      : CertificationStatus.unpublished;
    setCertifications([...certifications]);
    setSelectedCertification(null);
  };

  const closePublishDialog = () => {
    setSelectedCertification(null);
    setShowPublishDialog(false);
  };
  const promptPublishCertification = (certification) => {
    setSelectedCertification(certification);
    setShowPublishDialog(true);
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(certifications);

  const loadCertifications = () => {
    setIsLoading(true);
    CertificationAdminService.getCertifications()
      .then((data) => {
        setCertifications(data);
      })
      .catch((error) => {
        toastContext.addFailToast(<span>Failed to load certifications</span>);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadCertifications();
  }, []);

  const columns = [
    {
      header: "Certification name",
      dataKey: "name",
      sortable: true,
    },
    {
      header: "Last Updated By",
      headerStyle: { width: "15%" },
      dataKey: "updatedOn",
      sortable: true,
      renderer: (certification) => {
        return (
          <LastUpdatedOnDisplay
            updatedBy={certification.updatedBy}
            updatedOn={certification.updatedOn}
          />
        );
      },
    },
    {
      header: "Status",
      headerStyle: { width: "10%" },
      dataKey: "status",
      sortable: true,
      renderer: (certification) => {
        return (
          <StatusLabel
            color={
              certification.isPublished
                ? StatusLabel.Colors.green
                : StatusLabel.Colors.gray
            }
          >
            {certification.status}
          </StatusLabel>
        );
      },
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "15%" },
      renderer: (certification) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              icon={
                <GVDSIcon
                  Icon={certification.isPublished ? IconEyeOff : IconEye}
                />
              }
              tooltipText={certification.isPublished ? "Unpublish" : "Publish"}
              onClick={() => promptPublishCertification(certification)}
            />
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => {
                goToEdit(certification);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              icon={<GVDSIcon Icon={IconArrowsUpDown} />}
              tooltipText="Move Certification"
              onClick={() => promptMoveCertification(certification)}
            />
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        hideRowNumber={true}
      />
      {certifications.length === 0 ? (
        <div className="table__no_content">
          No certifications created yet. Click "Setup certification" at the
          top-right to get started.
        </div>
      ) : (
        filteredSortedData.length === 0 && (
          <div className="table__no_content">
            No certification. Try changing your filter.
          </div>
        )
      )}
      {certificationToBeMoved && (
        <MoveCertificationDialog
          show={showMoveDialog}
          closeModal={closeMoveDialog}
          certificationToBeMoved={certificationToBeMoved}
          allCertifications={certifications}
          onSuccess={(newCertifications) =>
            onSuccessMoveCertification(newCertifications)
          }
        />
      )}
      {selectedCertification && (
        <PublishUnpublishCertificationDialog
          show={showPublishDialog}
          isSaving={isSavingCertificationPublishUnpublish}
          certification={selectedCertification}
          closeModal={closePublishDialog}
          confirmProcess={toggleCertificationPublishStatus}
        />
      )}
    </>
  );
};

const ViewAllCertification = () => {
  const history = useHistory();

  const goToSetup = () => {
    history.push(SYSTEM_TOOLBOX_CERTIFICATION_SETUP);
  };

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Certifications</h1>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            text="Setup certification"
            onClick={goToSetup}
          />
        </PageHeader.Title>
      </PageHeader>
      <CertificationList />
    </Container>
  );
};

export default withAuthentication(ViewAllCertification);
