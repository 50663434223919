import axios from "axios";
import { API_URL } from "../../config/api-config";
import { SignUpFormModel } from "../SignUpService";
import {EnterprisePlanSignUpModel} from "../../components/Public/SignUp/SignUpModel";

export class SignUpRequestModel {
  constructor(
    status,
    requestDate,
    reviewDate,
    reviewEmailBody,
    signUpFormModel = null,
    packagePlanId,
    packagePlanName,
    forTrial,
    billingCycle
  ) {
    this.status = status;
    this.requestDate = requestDate;
    this.reviewDate = reviewDate;
    this.reviewEmailBody = reviewEmailBody;
    this.signUpFormModel = signUpFormModel;

    this.packagePlanId = packagePlanId;
    this.packagePlanName = packagePlanName;
    this.forTrial = forTrial;
    this.billingCycle = billingCycle;
  }

  static fromDTO(signUpRequestDTO) {
    const form = new SignUpFormModel(
      signUpRequestDTO["user_full_name"],
      signUpRequestDTO["email_address"],
      signUpRequestDTO["company_name"],
      signUpRequestDTO["site_name"],
      signUpRequestDTO["site_type"],
      signUpRequestDTO["street_address"],
      signUpRequestDTO["zip_code"],
      signUpRequestDTO["city"],
      signUpRequestDTO["state_or_province"],
      signUpRequestDTO["country"]
    );
    return new SignUpRequestModel(
      signUpRequestDTO["status"],
      signUpRequestDTO["request_date"],
      signUpRequestDTO["review_date"],
      signUpRequestDTO["review_email_body"],
      form,
      signUpRequestDTO["chosen_package_plan_id"],
      signUpRequestDTO["chosen_package_plan_name"],
      signUpRequestDTO["chosen_for_trial"],
      signUpRequestDTO["chosen_billing_cycle"],
    );
  }
}

export class EnterpriseSignUpRequestModel {
  constructor(
    requestDate,
    enterpriseSignUpFormModel = null,
  ) {
    this.requestDate = requestDate;
    this.enterpriseSignUpFormModel = enterpriseSignUpFormModel;
  }

  static fromDTO(signUpRequestDTO) {
    const form = new EnterprisePlanSignUpModel(
      signUpRequestDTO["user_full_name"],
      signUpRequestDTO["email_address"],
      signUpRequestDTO["company_name"],
      signUpRequestDTO["no_of_properties"],
      signUpRequestDTO["business_needs"],
      signUpRequestDTO["site_name"],
      signUpRequestDTO["site_type"],
      signUpRequestDTO["street_address"],
      signUpRequestDTO["zip_code"],
      signUpRequestDTO["city"],
      signUpRequestDTO["state_or_province"],
      signUpRequestDTO["country"]
    );
    return new EnterpriseSignUpRequestModel(
      signUpRequestDTO["request_date"],
      form,
    );
  }
}

export default class PortalAdminSignUpReviewService {
  static getAllSignUpRequests = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/signup-requests`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getSignUpRequest = async (signupId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/signup-requests/${signupId}`
      );
      return SignUpRequestModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static approveSignUpRequest = async (signupId, site, subscription) => {
    try {
      const data = {
        site: site,
        subscription: subscription,
      };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/signup-requests/${signupId}/approve`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static rejectSignUpRequest = async (
    signupId,
    rejection_email,
    shouldSendEmail
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/admin/signup-requests/${signupId}/reject`,
        {
          rejection_email: rejection_email,
          should_send_email: shouldSendEmail,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export class PortalAdminEnterpriseSignUpService {
  static getAllEnterpriseSignUpRequests = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v2/admin/enterprise-signup-requests`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getEnterpriseSignUpRequest = async (signupId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v2/admin/enterprise-signup-requests/${signupId}`
      );
      return EnterpriseSignUpRequestModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };
}