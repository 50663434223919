import React, { useMemo, useRef, useState } from "react";
import Divider, { DividerOrientation } from "../../common/Divider";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconChevronDown, IconChevronRight, IconX } from "@tabler/icons-react";
import clone from "lodash/clone";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import { Collapse } from "react-bootstrap";
import PolicyTrackerUtils from "../PolicyTrackerUtils";
import uniq from "lodash/uniq";

const policyTrackerSingleValueFilterKeys = [
  "region",
  "countryName",
  "state",
  "city",
  "jurisdictionLevel",
  "priorityLevel",
  "complianceObligation",
  "legislativeStatus",
  "transitionRisk",
];

const isPolicyTrackerFilterSelected = (filterKeys) => {
  return Object.values(filterKeys).some((filterKey) => filterKey.length > 0);
};

const clearPolicyTrackerFilter = (filterKeys, setFilterKeys) => {
  Object.keys(filterKeys).forEach((key) => (filterKeys[key] = []));
  setFilterKeys(clone(filterKeys));
};

const getFilteredPolicies = (
  filterKeys,
  policies,
  selectedFilterKeysInOrder,
  filterKey
) => {
  let filteredPolicies = policies;

  const filterKeyIndex = selectedFilterKeysInOrder.indexOf(filterKey);
  let selectedFilterKeys;

  if (filterKeyIndex === -1) {
    selectedFilterKeys = selectedFilterKeysInOrder;
  } else {
    selectedFilterKeys = selectedFilterKeysInOrder.slice(0, filterKeyIndex);
  }

  selectedFilterKeys.forEach((key) => {
    if (filterKeys[key] && filterKeys[key].length > 0) {
      if (policyTrackerSingleValueFilterKeys.includes(key)) {
        filteredPolicies = filteredPolicies.filter((policy) =>
          filterKeys[key].includes(policy[key])
        );
      } else {
        filteredPolicies = filteredPolicies.filter((policy) =>
          policy[key].some((criteria) => filterKeys[key].includes(criteria))
        );
      }
    }
  });

  return filteredPolicies;
};

const PolicyTrackerFilter = React.forwardRef(
  ({ isPortfolio, policies, filterKeys, setFilterKeys }, ref) => {
    const selectedFilterKeysInOrder = useRef([]);

    Object.entries(filterKeys).forEach(([key, values]) => {
      if (values.length > 0) {
        if (!selectedFilterKeysInOrder.current.includes(key)) {
          selectedFilterKeysInOrder.current.push(key);
        }
      } else {
        if (selectedFilterKeysInOrder.current.includes(key)) {
          selectedFilterKeysInOrder.current.splice(
            selectedFilterKeysInOrder.current.indexOf(key),
            1
          );
        }
      }
    });

    const regionOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "region"
          )
            .filter((policy) => policy.region)
            .map((policy) => policy.region)
        ).sort(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const countryOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "countryName"
          )
            .filter((policy) => policy.country)
            .map((policy) => policy.country.name)
        ).sort(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const stateOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "state"
          )
            .filter((policy) => policy.state)
            .map((policy) => policy.state)
        ).sort(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const cityOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "city"
          )
            .filter((policy) => policy.city)
            .map((policy) => policy.city)
        ).sort(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const jurisdictionLevelOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "jurisdictionLevel"
          )
            .filter((policy) => policy.jurisdictionLevel)
            .map((policy) => policy.jurisdictionLevel)
        ).sort(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const siteOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "siteName"
          )
            .map((policy) => policy.sites.map((site) => site.name))
            .flat()
        ).sort(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const priorityLevelOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "priorityLevel"
          )
            .filter((policy) => policy.priorityLevel)
            .map((policy) => policy.priorityLevel)
        ).sort(PolicyTrackerUtils.priorityLevelSortFn),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const complianceObligationOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "complianceObligation"
          )
            .filter((policy) => policy.complianceObligation)
            .map((policy) => policy.complianceObligation)
        ).sort(PolicyTrackerUtils.complianceObligationSortFn),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const legislativeStatusOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "legislativeStatus"
          )
            .filter((policy) => policy.legislativeStatus)
            .map((policy) => policy.legislativeStatus)
        ).sort(PolicyTrackerUtils.legislativeStatusSortFn),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const esgOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "esgStatuses"
          )
            .filter(
              (policy) => policy.esgStatuses && policy.esgStatuses.length > 0
            )
            .map((policy) => policy.esgStatuses.filter((esg) => esg))
            .flat()
        ).sort(PolicyTrackerUtils.esgSortFn),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const topicOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "topics"
          )
            .filter((policy) => policy.topics && policy.topics.length > 0)
            .map((policy) => policy.topics.filter((topic) => topic))
            .flat()
        ).sort(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const keywordOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "keywords"
          )
            .filter((policy) => policy.keywords && policy.keywords.length > 0)
            .map((policy) => policy.keywords.filter((keyword) => keyword))
            .flat()
        ).sort(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    const transitionRiskOptions = useMemo(
      () =>
        uniq(
          getFilteredPolicies(
            filterKeys,
            policies,
            selectedFilterKeysInOrder.current,
            "transitionRisk"
          )
            .filter((policy) => policy.transitionRisk)
            .map((policy) => policy.transitionRisk)
        )
          .sort()
          .reverse(),
      [filterKeys, policies, selectedFilterKeysInOrder.current]
    );

    return (
      <div className="policy-tracker__filter__container" ref={ref}>
        <div className="policy-tracker__filter__location-label">Location</div>
        <div className="policy-tracker__filter__regulation-label">
          Regulation Parameters
        </div>
        <div className="policy-tracker__filter__themes-label">Themes</div>
        <Divider
          className="policy-tracker__filter__divider"
          orientation={DividerOrientation.vertical}
        />
        {isPortfolio ? (
          <div className="policy-tracker__filter__location-dropdown-container">
            <GVDSTableCtrlMultiSelect
              options={regionOptions}
              prefix="Region"
              value={filterKeys["region"]}
              onChange={(filterKs) =>
                setFilterKeys({
                  ...filterKeys,
                  region: filterKs,
                })
              }
            />
            <GVDSTableCtrlMultiSelect
              options={countryOptions}
              prefix="Country"
              value={filterKeys["countryName"]}
              onChange={(filterKs) => {
                setFilterKeys({
                  ...filterKeys,
                  countryName: filterKs,
                });
              }}
            />
            <GVDSTableCtrlMultiSelect
              options={stateOptions}
              prefix="State"
              value={filterKeys["state"]}
              onChange={(filterKs) =>
                setFilterKeys({
                  ...filterKeys,
                  state: filterKs,
                })
              }
            />
            <GVDSTableCtrlMultiSelect
              options={cityOptions}
              prefix="City"
              value={filterKeys["city"]}
              onChange={(filterKs) =>
                setFilterKeys({
                  ...filterKeys,
                  city: filterKs,
                })
              }
            />
            <GVDSTableCtrlMultiSelect
              options={jurisdictionLevelOptions}
              prefix="Jurisdiction Level"
              value={filterKeys["jurisdictionLevel"]}
              onChange={(filterKs) =>
                setFilterKeys({
                  ...filterKeys,
                  jurisdictionLevel: filterKs,
                })
              }
            />
            <GVDSTableCtrlMultiSelect
              options={siteOptions}
              prefix="Site"
              value={filterKeys["siteName"]}
              onChange={(filterKs) =>
                setFilterKeys({
                  ...filterKeys,
                  siteName: filterKs,
                })
              }
            />
          </div>
        ) : (
          <div className="policy-tracker__filter__location-dropdown-container">
            <GVDSTableCtrlMultiSelect
              options={jurisdictionLevelOptions}
              prefix="Jurisdiction Level"
              value={filterKeys["jurisdictionLevel"]}
              onChange={(filterKs) =>
                setFilterKeys({
                  ...filterKeys,
                  jurisdictionLevel: filterKs,
                })
              }
            />
          </div>
        )}
        <div className="policy-tracker__filter__regulation-dropdown-container">
          <GVDSTableCtrlMultiSelect
            options={priorityLevelOptions}
            prefix="Priority Level"
            value={filterKeys["priorityLevel"]}
            onChange={(filterKs) =>
              setFilterKeys({
                ...filterKeys,
                priorityLevel: filterKs,
              })
            }
            longSelectThreshold={5}
          />
          <GVDSTableCtrlMultiSelect
            options={complianceObligationOptions}
            prefix="Compliance Obligation"
            value={filterKeys["complianceObligation"]}
            onChange={(filterKs) =>
              setFilterKeys({
                ...filterKeys,
                complianceObligation: filterKs,
              })
            }
          />
          <GVDSTableCtrlMultiSelect
            options={legislativeStatusOptions}
            prefix="Legislative Status"
            value={filterKeys["legislativeStatus"]}
            onChange={(filterKs) =>
              setFilterKeys({
                ...filterKeys,
                legislativeStatus: filterKs,
              })
            }
            longSelectThreshold={6}
          />
        </div>
        <div className="policy-tracker__filter__themes-dropdown-container">
          <GVDSTableCtrlMultiSelect
            options={esgOptions}
            prefix="E/S/G"
            value={filterKeys["esgStatuses"]}
            onChange={(filterKs) => {
              setFilterKeys({
                ...filterKeys,
                esgStatuses: filterKs,
              });
            }}
          />
          <GVDSTableCtrlMultiSelect
            options={topicOptions}
            prefix="Topics"
            value={filterKeys["topics"]}
            onChange={(filterKs) =>
              setFilterKeys({
                ...filterKeys,
                topics: filterKs,
              })
            }
          />
          <GVDSTableCtrlMultiSelect
            options={keywordOptions}
            prefix="Keywords"
            value={filterKeys["keywords"]}
            onChange={(filterKs) =>
              setFilterKeys({
                ...filterKeys,
                keywords: filterKs,
              })
            }
          />
          <GVDSTableCtrlMultiSelect
            options={transitionRiskOptions}
            prefix="Transition Risks"
            value={filterKeys["transitionRisk"]}
            onChange={(filterKs) =>
              setFilterKeys({
                ...filterKeys,
                transitionRisk: filterKs,
              })
            }
          />
        </div>
        {isPolicyTrackerFilterSelected(filterKeys) && (
          <GVDSButton
            className="ms-auto btn-clear-all"
            variant={buttonVariant.secondary}
            icon={<GVDSIcon Icon={IconX} />}
            text="Clear all"
            onClick={() => clearPolicyTrackerFilter(filterKeys, setFilterKeys)}
          />
        )}
      </div>
    );
  }
);

export const StickyPolicyTrackerFilter = ({
  isPortfolio,
  policies,
  filterKeys,
  setFilterKeys,
  isAnnouncementBarVisible,
}) => {
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  return (
    <div
      className={`policy-tracker__sticky-filter-section__container${
        isAnnouncementBarVisible ? " with-announcement-bar" : ""
      }`}
    >
      <div
        className="policy-tracker__filter-section-trigger"
        onClick={() => setIsFilterExpanded(!isFilterExpanded)}
      >
        <div className="gvds-text--heading4">Filter</div>
        <GVDSTextButton
          text={
            <GVDSIconSlim
              className="color-black"
              Icon={isFilterExpanded ? IconChevronDown : IconChevronRight}
            />
          }
        />
      </div>
      <Collapse in={isFilterExpanded}>
        <div className="policy-tracker__filter-section-collapsible">
          <PolicyTrackerFilter
            isPortfolio={isPortfolio}
            policies={policies}
            filterKeys={filterKeys}
            setFilterKeys={setFilterKeys}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default PolicyTrackerFilter;
