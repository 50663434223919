import React, { useContext, useEffect, useRef, useState } from "react";
import range from "lodash/range";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import BulkDataInput, { BulkDataInputControls } from "../BulkDataInput";
import groupBy from "lodash/groupBy";
import OperationalService from "../../../services/OperationalService";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import { OPERATIONAL_DATA, OVERVIEW_DATA } from "../../../config/ROUTES_NAME";
import withAuthentication from "../../HOC/withAuthentication";
import { isOperationalDisabled } from "./Operational";
import BulkOperationalDataInputErrorService from "./BulkOperationalDataInputErrorService";
import BulkOperationalDataInputService from "./BulkOperationalDataInputService";
import { RESOURCES } from "../../../config/constants";
import ToastContext from "../../../context/ToastContext";
import flatMap from "lodash/flatMap";
import BulkExcelUploadModal from "../../common/BulkExcelUploadModal";
import { useHistory, useLocation } from "react-router-dom";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconDownload, IconUpload } from "@tabler/icons-react";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";

const INITIAL_GRID_SIZE = 10;
const DATA_STARTING_COL = 2;

const BulkOperationalDataInput = () => {
  let history = useHistory();
  let location = useLocation();
  const bulkInputErrorService = useRef(
    new BulkOperationalDataInputErrorService()
  );

  const [hasEdited, setHasEdited] = useState(false);

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const bulkInputService = useRef(new BulkOperationalDataInputService());

  const [grid, setGrid] = useState([]);
  const [rowToDelete, setRowToDelete] = useState(-1);
  const [numOfRecordsInputted, setNumOfRecordsInputted] = useState();
  const [currentInventoryId, setCurrentInventoryId] = useState("");
  const [disableOperational, setDisableOperational] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [bulkConfig, setBulkConfig] = useState({});

  useEffect(() => {
    if (
      userInventory.selectedInventory.get &&
      userInventory.selectedInventory.get.id &&
      userInventory.selectedInventory.get.id !== currentInventoryId
    ) {
      setCurrentInventoryId(userInventory.selectedInventory.get.id);
      if (
        isOperationalDisabled(
          userInventory.selectedTreeNode.get.nodeValue.value,
          userInventory.selectedInventory.get,
          userInventory.selectedTreeNode.get.children
        )
      ) {
        setDisableOperational(true);
      } else {
        bulkInputErrorService.current.resetErrors();
        setIsLoading(true);
        bulkInputService.current.updateResource(
          userInventory.selectedInventory.get.type,
          userInventory.selectedTreeNode.get
        );
        OperationalService.getBulkConfig(
          userInventory.selectedInventory.get.type,
          userInventory.selectedInventory.get.id
        )
          .then((config) => {
            setBulkConfig(config);
            bulkInputService.current.setSelectionOptions(
              config["facility_datasets"]
            );
            resetGrid();
          })
          .catch(() => {
            toastContext.addFailToast(
              <span>Failed to get config. Please try again.</span>
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [userInventory.selectedInventory.get]);

  useEffect(() => {
    if (rowToDelete >= 0) {
      const newGrid = bulkInputService.current.removeRow(
        rowToDelete,
        setRowToDelete,
        grid
      );
      setGrid(newGrid);
      setRowToDelete(-1);
    }
  }, [rowToDelete]);

  const resetGrid = () => {
    const initialGrid = range(INITIAL_GRID_SIZE).map((row) =>
      bulkInputService.current.getNewRow(row, setRowToDelete)
    );
    bulkInputErrorService.current.resetErrors();
    setHasEdited(false);
    setGrid(initialGrid);
  };

  const addNewRow = () => {
    const newRow = Object.values(grid).length;
    setGrid([
      ...grid,
      bulkInputService.current.getNewRow(newRow, setRowToDelete),
    ]);
  };

  const onTemplateDownload = async () => {
    setIsDownloading(true);
    try {
      await OperationalService.downloadTemplate(
        userInventory.selectedInventory.get.type,
        userInventory.selectedInventory.get.id
      );
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to download template. Please try again.</span>
      );
    } finally {
      setIsDownloading(false);
    }
  };

  const onRowsRead = async (rows) => {
    await handleChanges(
      [],
      [],
      flatMap(
        rows.map((row, ri) =>
          row.map((col, ci) => ({
            row: ri,
            col: ci + DATA_STARTING_COL,
            value: col,
          }))
        )
      )
    );
  };

  const handleChanges = async (changedGrid, changes = [], additions = []) => {
    setHasEdited(true);
    const totalChanges = [...changes, ...additions];
    const changesByRow = groupBy(totalChanges, (c) => c.row);

    for (const [row, rowChanges] of Object.entries(changesByRow)) {
      const rowData = changedGrid[row]
        ? changedGrid[row]
        : bulkInputService.current.getNewRow(row, setRowToDelete);
      changedGrid[row] = bulkInputService.current.updateRow(
        rowData,
        rowChanges
      );
    }
    setGrid(changedGrid);
  };

  const submitBulkData = async () => {
    setIsLoading(true);
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const newDataReadings = changedGrid.map((rowData) =>
      bulkInputService.current.getDataReading(rowData)
    );
    const selectedInventory = userInventory.selectedInventory.get;
    try {
      await OperationalService.createDataRecords(
        selectedInventory.type,
        selectedInventory.id,
        newDataReadings
      );
      setNumOfRecordsInputted(grid.length);
      resetGrid();
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to submit. Please try again.</span>
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onInputSuccess = () => {
    if (userInventory.selectedInventory.get?.type === RESOURCES.PORTFOLIO) {
      resetGrid();
    } else {
      history.push(getRedirectURLWithCurrentParam(OPERATIONAL_DATA, location));
    }
    setNumOfRecordsInputted(null);
  };

  const validateBulkData = async () => {
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    if (changedGrid.length === 0) return;

    const newDataReadings = changedGrid.map((rowData) =>
      bulkInputService.current.getDataReading(rowData)
    );
    if (newDataReadings.length === 0) return;

    const selectedInventory = userInventory.selectedInventory.get;
    setIsLoading(true);
    try {
      const errors = await OperationalService.validateDataRecords(
        selectedInventory.type,
        selectedInventory.id,
        newDataReadings
      );
      const newGrid = bulkInputService.current.updateGridWithErrors(
        changedGrid,
        bulkInputErrorService.current.prepareDataErrors(
          errors.dataErrors.rowErrors
        ),
        bulkInputErrorService.current.preparePossibleErrors(
          errors.possibleErrors.rowErrors
        )
      );
      bulkInputErrorService.current.updateErrors(errors);
      setHasEdited(false);
      setGrid(newGrid);
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to validate. Please try again.</span>
      );
    } finally {
      setIsLoading(false);
    }
  };
  const backToDataPage = () => {
    if (userInventory.selectedInventory.get.type === RESOURCES.PORTFOLIO) {
      history.push(OVERVIEW_DATA);
    } else {
      history.push(OPERATIONAL_DATA);
    }
  };

  const operationTitle = (
    <PageHeader>
      <PageHeader.BackButton
        text={
          userInventory.selectedInventory.get &&
          userInventory.selectedInventory.get.type === RESOURCES.PORTFOLIO
            ? "Return to Data Overview Page"
            : "Return to Operational Data Input Page"
        }
        onClick={backToDataPage}
      />
      <PageHeader.Title>
        <h1>Bulk Input Operational Records</h1>
      </PageHeader.Title>
    </PageHeader>
  );

  if (disableOperational) {
    return (
      <div>
        {operationTitle}
        <div className="table__no_content mt-2">
          <p>
            Bulk Input Operational Records is only available for Facilities of
            Facility Type: Hotel / Accommodation.
          </p>
        </div>
      </div>
    );
  }

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <BulkDataInput
        className="bulk-input-operational"
        grid={grid}
        hasChanges={
          hasEdited ||
          bulkInputErrorService.current.hasDataErrors() ||
          bulkInputErrorService.current.hasPossibleErrors() ||
          !bulkInputErrorService.current.disableSubmit()
        }
        columns={bulkInputService.current.columns}
        onChange={(changes, additions) =>
          handleChanges(
            grid.map((row) => [...row]),
            changes,
            additions
          )
        }
      />
    );
  }
  return (
    <div>
      {operationTitle}

      {bulkInputErrorService.current.hasDataErrors() && (
        <GVDSBanner
          title={`${bulkInputErrorService.current.dataErrorRows.length} issues detected.`}
          variant={GVDSBanner.Variants.error}
          className="mt-2"
        >
          Please fix the following rows:{" "}
          {bulkInputErrorService.current.dataErrorRows
            .map((row) => Number(row) + 1)
            .join(", ")}
        </GVDSBanner>
      )}

      {bulkInputErrorService.current.hasNoErrors() && (
        <GVDSBanner
          title="No issues found."
          variant={GVDSBanner.Variants.success}
          className="mt-2"
        >
          Click "Submit" to enter your data records into the system.
        </GVDSBanner>
      )}

      {bulkInputErrorService.current.hasPossibleErrors() && (
        <GVDSBanner
          title={`${bulkInputErrorService.current.possibleErrorRows.length} possible issues detected. Records with this issue can be submitted.`}
          variant={GVDSBanner.Variants.warning}
          className="mt-2"
        >
          Please check the following rows:{" "}
          {bulkInputErrorService.current.possibleErrorRows
            .map((row) => Number(row) + 1)
            .join(", ")}
        </GVDSBanner>
      )}
      <div className="d-flex flex-row-reverse align-items-center">
        {hasEdited && <div>Changes made! Validate before submitting.</div>}
      </div>

      <div className="bulk-input-ctrl__container">
        <BulkDataInputControls
          addNewRow={addNewRow}
          rowCount={grid.length}
          onClearAll={resetGrid}
        />
        <div className="bulk-input-ctrl__action-ctrl">
          <GVDSButton
            className="download-operation-template"
            variant={buttonVariant.tertiary}
            disabled={isDownloading}
            onClick={onTemplateDownload}
            icon={<GVDSIcon Icon={IconDownload} />}
            text="Download Template"
          />
          <GVDSButton
            className="upload-operation-sheet"
            variant={buttonVariant.tertiary}
            disabled={isLoading}
            onClick={() => setShowUploadModal(true)}
            icon={<GVDSIcon Icon={IconUpload} />}
            text="Upload Sheet"
          />

          <GVDSButton
            variant={buttonVariant.secondary}
            className="validate-bulk-data"
            onClick={validateBulkData}
            text="Check for Validation"
          />
          <GVDSButton
            className="submit-bulk-data"
            onClick={submitBulkData}
            disabled={
              isLoading ||
              hasEdited ||
              bulkInputErrorService.current.disableSubmit()
            }
            text="Submit"
          />
        </div>
      </div>
      {content}

      <GVDSModal
        title="Bulk Input Success"
        size={GVDSModal.Size.small}
        show={numOfRecordsInputted > 0}
        onHide={onInputSuccess}
      >
        <GVDSModal.Body>
          You have recorded {numOfRecordsInputted} entries into the portal.
        </GVDSModal.Body>
      </GVDSModal>

      <BulkExcelUploadModal
        sheetName={bulkConfig["upload_sheet_name"]}
        startingRow={bulkConfig["upload_start_row"]}
        show={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onRowsRead={onRowsRead}
      />
    </div>
  );
};

export default withAuthentication(BulkOperationalDataInput);
