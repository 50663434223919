import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DataRequestService from "../../../../services/DataRequestService";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import { DateTimeUtils } from "../../../../services/UtilsService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { getURLWithPageParams } from "../../../common/QueryHandler";
import {
  REPORT_HCMI_METRIC,
  REPORT_PERFORMANCE_REPORT,
  REPORT_STANDARD_REPORT,
} from "../../../../config/ROUTES_NAME";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import uniq from "lodash/uniq";
import { REPORT_TYPE, RESOURCES } from "../../../../config/constants";
import keyBy from "lodash/keyBy";
import ToastContext from "../../../../context/ToastContext";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVFormGroup from "../../../common/GVFormGroup";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import {
  IconExternalLink,
  IconFileAnalytics,
  IconTrash,
} from "@tabler/icons-react";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import StatusLabel from "../../../../gvds-components/common/StatusLabel";

const ViewAllDataRequests = () => {
  const history = useHistory();
  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [allDataRequests, setAllDataRequests] = useState({});
  const [newDataRequestDetails, setNewDataRequestDetails] = useState({});
  const [dataRequestDetails, setDataRequestDetails] = useState(null);
  const [dataRequestToBeDeleted, setDataRequestToBeDeleted] = useState(null);
  const [inventoryId, setInventoryId] = useState("");

  const dataRequestList = useMemo(
    () => Object.values(allDataRequests),
    [allDataRequests]
  );

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    searchText,
    setSearchText,
    filterKeys,
    setFilterKeys,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(dataRequestList, null);

  useEffect(() => {
    const inventory = userInventory.selectedInventory.get;
    if (inventory && inventory.id && inventoryId !== inventory.id) {
      setInventoryId(inventory.id);
      setAllDataRequests({});
      setNewDataRequestDetails({});
      setIsLoading(true);
      DataRequestService.getDataRequests(inventory.id, inventory.type)
        .then((data) => {
          setAllDataRequests(keyBy(data, "id"));
          setIsLoading(false);
          data
            .filter((d) => !d.isExpired())
            .forEach((d) => {
              DataRequestService.getDataRequest(
                d.id,
                inventory.id,
                inventory.type
              ).then((details) => {
                setNewDataRequestDetails(details);
              });
            });
        })
        .catch((e) => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to load data requests.</span>);
        });
    }
  }, [userInventory.selectedInventory.get]);

  useEffect(() => {
    if (
      newDataRequestDetails.id &&
      newDataRequestDetails.resourceId === inventoryId
    ) {
      setAllDataRequests({
        ...allDataRequests,
        [newDataRequestDetails.id]: newDataRequestDetails,
      });
    }
  }, [newDataRequestDetails.id, newDataRequestDetails.resourceId]);

  const runReport = (reportType, requestParams) => {
    if (reportType === REPORT_TYPE.HCMI) {
      history.push(
        getURLWithPageParams(location, REPORT_HCMI_METRIC, requestParams)
      );
    }
    if (reportType === REPORT_TYPE.STANDARD_REPORT) {
      history.push(
        getURLWithPageParams(location, REPORT_STANDARD_REPORT, requestParams)
      );
    }
    if (reportType === REPORT_TYPE.PERFORMANCE) {
      history.push(
        getURLWithPageParams(location, REPORT_PERFORMANCE_REPORT, requestParams)
      );
    }
  };

  const deleteDataRequest = () => {
    DataRequestService.deleteDataRequest(
      dataRequestToBeDeleted.id,
      userInventory.selectedInventory.get.id,
      userInventory.selectedInventory.get.type
    ).then(() => {
      const dataRequests = { ...allDataRequests };
      delete dataRequests[dataRequestToBeDeleted.id];
      setAllDataRequests(dataRequests);
      setDataRequestToBeDeleted(null);
      toastContext.addSuccessToast(
        <span>Data request successfully deleted</span>
      );
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const columns = [
    {
      header: "Requested by",
      dataKey: "requestedBy",
      renderer: (d) =>
        `${d.requestedBy} (${DateTimeUtils.formatUTCDate(d["requestedOn"])})`,
    },
    {
      header: "Requested for",
      dataKey: "typeInFullName",
      renderer: (d) => (
        <div>
          {d.typeInFullName}
          {d.type === REPORT_TYPE.PERFORMANCE && d.requestParams && (
            <div>Subtopic: {d.requestParams.subtopic_name}</div>
          )}
          {d.periods.map((p, index) => (
            <div key={index}>{p}</div>
          ))}
        </div>
      ),
    },
    {
      header: "Deadline",
      dataKey: "deadline",
      sortable: true,
      defaultSortType: SORTING_TYPES.asc,
      sortPriority: 2,
      renderer: (d) => (
        <>
          {DateTimeUtils.formatUTCDate(d.deadline)}{" "}
          <span className="caption fst-italic data-request-due-message">
            {!d.isExpired() && d.getDeadlineDueMessage()}
          </span>
        </>
      ),
    },
    {
      header: "Status",
      dataKey: "statusOrder",
      sortable: true,
      defaultSortType: SORTING_TYPES.asc,
      sortPriority: 1,
      headerStyle: { width: "150px" },
      renderer: (d) =>
        d.isProgressLoading() ? (
          <LoadingSpinner />
        ) : (
          <>
            <StatusLabel color={d.getStatusLabelColor()}>
              {d.status}
            </StatusLabel>
            {!d.isExpired() &&
              userInventory.selectedInventory.get.type ===
                RESOURCES.PORTFOLIO && (
                <div className="data-request-progress mt-2">
                  <ProgressBar now={d.progress} />{" "}
                  <div className="ms-1 caption flex-shrink-0">
                    {d.completed_sites_facilities} ({d.progress}%)
                  </div>
                </div>
              )}
          </>
        ),
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (d) => (
        <>
          <GVDSIconButton
            className="view-data-request"
            variant={iconButtonVariant.tertiary}
            disabled={d.isProgressLoading()}
            onClick={() => setDataRequestDetails(d)}
            icon={<GVDSIcon Icon={IconExternalLink} />}
            tooltipText="View Details"
          />
          {d.runReport && (
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              icon={<GVDSIcon Icon={IconFileAnalytics} />}
              onClick={() => runReport(d.type, d.requestParams)}
              tooltipText="Run Report"
            />
          )}
          {d.allowDelete && (
            <GVDSIconButton
              className="delete-data-request"
              variant={iconButtonVariant.destructive}
              onClick={() => setDataRequestToBeDeleted(d)}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <GVDSTableCtrlContainer>
        <GVDSTableCtrlMultiSelect
          options={uniq(dataRequestList.map((r) => r["typeInFullName"])).sort()}
          prefix="Report Type"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, typeInFullName: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(dataRequestList.map((r) => r["status"])).sort()}
          prefix="Status"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, status: filterKs })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        onRowClick={(dataRequest) => setDataRequestDetails(dataRequest)}
      />

      {dataRequestList.length === 0 ? (
        <div className="table__no_content">
          <p>No data requests issued.</p>
        </div>
      ) : (
        filteredSortedData.length === 0 && (
          <div className="table__no_content">
            <p>No data requests. Try changing your filter.</p>
          </div>
        )
      )}

      {dataRequestDetails && (
        <GVDSModal
          title="Data Request Details"
          size={GVDSModal.Size.medium}
          className="data-request-details-modal"
          show={true}
          onHide={() => setDataRequestDetails(null)}
        >
          <GVDSModal.Body>
            <GVFormGroup>
              <Form.Label>Requested by</Form.Label>
              <div>
                {dataRequestDetails.requestedBy} on{" "}
                {DateTimeUtils.formatUTCDate(dataRequestDetails.requestedOn)}
              </div>
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>Deadline</Form.Label>
              <div>
                {DateTimeUtils.formatUTCDate(dataRequestDetails.deadline)}{" "}
                <span className="color-gray56 caption fst-italic">
                  {dataRequestDetails.getDeadlineDueMessage()}
                </span>
              </div>
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>Description</Form.Label>
              {!dataRequestDetails.description ? (
                <div className="data-request-no-description-message">
                  No description was given.
                </div>
              ) : (
                <div>{dataRequestDetails.description}</div>
              )}
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>Report</Form.Label>
              <div>
                {dataRequestDetails.typeInFullName}
                {dataRequestDetails.type === REPORT_TYPE.PERFORMANCE &&
                  dataRequestDetails.requestParams && (
                    <span>
                      {" (Subtopic: "}
                      {dataRequestDetails.requestParams.subtopic_name})
                    </span>
                  )}
              </div>
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>Period</Form.Label>
              <div>
                {dataRequestDetails.periods.map((p, index) => (
                  <div key={index}>{p}</div>
                ))}
              </div>
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>Requested Data</Form.Label>
              <div>
                {dataRequestDetails.isExpired() && (
                  <span className="color-gray56 caption fst-italic">
                    Data request has expired.
                  </span>
                )}
                {dataRequestDetails.isCompleted() &&
                  "All requested data has been inputted."}
                {dataRequestDetails.missingTypes?.map((type, index) => (
                  <div key={index}>- {type}</div>
                ))}
              </div>
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>Status</Form.Label>
              <div className="d-flex mt-1">
                <StatusLabel color={dataRequestDetails.getStatusLabelColor()}>
                  {dataRequestDetails.status}
                </StatusLabel>
                {!dataRequestDetails.isExpired() &&
                  userInventory.selectedInventory.get.type ===
                    RESOURCES.PORTFOLIO && (
                    <div className="data-request-progress ms-2 w-25">
                      <ProgressBar now={dataRequestDetails.progress} />{" "}
                      <div className="ms-1 caption">
                        {dataRequestDetails.progress}%
                      </div>
                    </div>
                  )}
              </div>
              {!dataRequestDetails.isExpired() &&
                userInventory.selectedInventory.get.type ===
                  RESOURCES.PORTFOLIO && (
                  <div className="mt-1">
                    {dataRequestDetails.completed_sites_facilities} sites and
                    facilities have completed the data request.
                  </div>
                )}
              {!dataRequestDetails.isExpired() && (
                <div>For more details, download the report.</div>
              )}
            </GVFormGroup>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={() => setDataRequestDetails(null)}
              text="Close"
            />
          </GVDSModal.Footer>
        </GVDSModal>
      )}

      <GVDSModal
        title="Delete Data Request"
        size={GVDSModal.Size.small}
        show={!!dataRequestToBeDeleted}
        onHide={() => setDataRequestToBeDeleted(null)}
      >
        <GVDSModal.Body>
          <div>
            Details of this data request will not be stored and you will no
            longer be able to see the progress of this data request.
          </div>
          <br />
          <div>This action cannot be undone. Are you sure?</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => setDataRequestToBeDeleted(null)}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={() => deleteDataRequest()}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default ViewAllDataRequests;
