import JoinedBullet from "../../../common/JoinedBullet";
import DashboardMinifiedHeaderControls from "../DashboardMinifiedHeaderControls";
import React, { useContext } from "react";
import { siteRankingSortOptions } from "../DashboardWidgetSharedUtils";
import GVDSFormSingleSelect from "../../../../gvds-components/Forms/GVDSFormSingleSelect";
import { barChartMaxCount } from "../SummarySiteRankingChart/SummarySiteRankingViewConfig";
import {
  changeOverTimeSiteRankingConfig,
  changeOverTimeSiteRankingViewTypeOptions,
} from "./ChangeOverTimeSiteRankingChartViewConfig";
import DashboardExpandedHeaderControls from "../DashboardExpandedHeaderControls";
import DashboardDataGapsIndicator from "../DashboardDataGapsIndicator";
import DashboardDataContext from "../../Context/DashboardDataContext";
import { expandedModalViewOptionEnum } from "../../Models/DashboardModels";

export const ChangeOverTimeSiteRankingChartViewHeader = ({
  title,
  hasDataGaps,
  period,
  subtopic,
  intensity,
  selectedSiteCount,
  hasData,
  sortOrder,
  onSortOrderChange,
  selectedViewType,
  onViewTypeChange,
  showExpandedTableView,
  showExpandedChartView,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  const dashboardDataContext = useContext(DashboardDataContext);

  let siteCountLabel;
  if (selectedSiteCount > barChartMaxCount) {
    siteCountLabel = `showing first ${barChartMaxCount} sites (expand to see all)`;
  } else {
    siteCountLabel = `showing first ${selectedSiteCount} sites`;
  }

  return (
    <div className="header-container">
      <div>
        <div className="title-container">
          <div className="title">
            {title}
            {hasDataGaps && <DashboardDataGapsIndicator />}
          </div>
          {hasData && (
            <>
              <JoinedBullet className="title-joined-bullet" />
              <div className="site-count-label">{siteCountLabel}</div>
            </>
          )}
        </div>
        <div className="subtitle-container">
          <div className="subtitle">{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div className="subtitle">
            {subtopic} {intensity}
          </div>
        </div>
      </div>
      {!dashboardDataContext.isDownloadingDashboardAsImage && (
        <div
          id={
            changeOverTimeSiteRankingConfig.miniViewExcludeOnDownloadContainerId
          }
          className="dashboard-chart-header__action-buttons"
        >
          <GVDSFormSingleSelect
            className="dashboard--sort-chart-data__single-select"
            value={sortOrder}
            options={siteRankingSortOptions}
            onSelect={onSortOrderChange}
            disabled={!hasData}
          />
          <GVDSFormSingleSelect
            className="dashboard--view-type__single-select"
            value={selectedViewType}
            options={changeOverTimeSiteRankingViewTypeOptions}
            onSelect={onViewTypeChange}
            disabled={!hasData}
          />
          <DashboardMinifiedHeaderControls
            showExpandedTableView={showExpandedTableView}
            showExpandedChartView={showExpandedChartView}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
            disabled={!hasData}
            isDownloading={isDownloadingChart}
          />
        </div>
      )}
    </div>
  );
};

export const ExpandedChangeOverTimeSiteRankingChartViewHeader = ({
  title,
  hasDataGaps,
  period,
  subtopic,
  intensity,
  hasData,
  selectedSiteCount,
  sortOrder,
  onSortOrderChange,
  selectedViewType,
  onViewTypeChange,
  selectedExpandedViewOption,
  onExpandedViewOptionChange,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  let viewTypeOptions;
  let selectedExpandedViewType;
  if (selectedExpandedViewOption === expandedModalViewOptionEnum.TABLE) {
    viewTypeOptions = changeOverTimeSiteRankingViewTypeOptions.map((o) => ({
      label: `${changeOverTimeSiteRankingConfig.tableViewTypePrefix} ${o.label}`,
      value: o.value,
    }));
    selectedExpandedViewType = {
      label: `${changeOverTimeSiteRankingConfig.tableViewTypePrefix} ${selectedViewType.label}`,
      value: selectedViewType.value,
    };
  } else {
    viewTypeOptions = changeOverTimeSiteRankingViewTypeOptions;
    selectedExpandedViewType = selectedViewType;
  }

  const handleViewTypeChange = (newViewType) => {
    if (selectedExpandedViewOption === expandedModalViewOptionEnum.TABLE) {
      onViewTypeChange({
        label: newViewType.label
          .replace(changeOverTimeSiteRankingConfig.tableViewTypePrefix, "")
          .trim(),
        value: newViewType.value,
      });
    } else {
      onViewTypeChange(newViewType);
    }
  };

  return (
    <div className="dashboard--change-over-time-site-ranking__expanded-chart-view__header-container">
      <div>
        <div className="expanded-chart-view__title-container">
          <div className="expanded-chart-view__title">{title}</div>
          {hasDataGaps && <DashboardDataGapsIndicator />}
          {hasData && (
            <>
              <JoinedBullet className="dashboard-joined-bullet" />
              <div className="site-count-label">{selectedSiteCount} sites</div>
            </>
          )}
        </div>
        <div className="expanded-chart-view__subtitle">
          <div>{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div>
            {subtopic} {intensity}
          </div>
        </div>
      </div>
      <div
        id={
          changeOverTimeSiteRankingConfig.expandedViewExcludeOnDownloadContainerId
        }
        className="dashboard-chart-header__action-buttons"
      >
        <GVDSFormSingleSelect
          className="dashboard--sort-chart-data__single-select"
          value={sortOrder}
          options={siteRankingSortOptions}
          onSelect={onSortOrderChange}
        />
        <GVDSFormSingleSelect
          className="dashboard--view-type__single-select"
          value={selectedExpandedViewType}
          options={viewTypeOptions}
          onSelect={handleViewTypeChange}
        />
        <DashboardExpandedHeaderControls
          selectedViewOption={selectedExpandedViewOption}
          onSelectViewOption={onExpandedViewOptionChange}
          onExportChart={downloadChartAsImage}
          onDownloadTableAsExcel={onDownloadTableAsExcel}
          isDownloading={isDownloadingChart}
        />
      </div>
    </div>
  );
};
