import LoadingSpinner from "../../../common/LoadingSpinner";
import React, { useContext, useRef, useState } from "react";
import {
  DashboardWidgetSharedUtils,
  siteRankingSortOptions,
} from "../DashboardWidgetSharedUtils";
import {
  barChartMaxCount,
  baseAdditionalHeightInPx,
  expandedSummarySiteRankingChartViewId,
  expandedViewSingleBarAdditionalHeightInPx,
  legendContainerWidthInPx,
  singleBarAdditionalHeightInPx,
  summarySiteRankingChartViewId,
  summarySiteRankingExpandedViewExcludeOnDownloadContainerId,
  summarySiteRankingMiniViewExcludeOnDownloadContainerId,
} from "./SummarySiteRankingViewConfig";
import HorizontalStackedBarChart from "./HorizontalStackedBarChart";
import DashboardStackedBarLegend from "../DashboardStackedBarLegend";
import { SummarySiteRankingChartUtils } from "./SummarySiteRankingChartUtils";
import ExpandedSummarySiteRankingChartView from "./ExpandedSummarySiteRankingChartView";
import {
  downloadChartAsImageTimeoutInMs,
  expandedModalViewOptionEnum,
  expandedViewSingleHorizontalBarChartHeightInPx,
  maximumChartLegendItemCount,
  singleHorizontalBarChartHeightInPx,
} from "../../Models/DashboardModels";
import { SummarySiteRankingChartViewHeader } from "./SummarySiteRankingChartViewHeaders";
import { DashboardColorPalette } from "../DashboardColorPalette";
import { ParentSize } from "@visx/responsive";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import { NumberService } from "../../../../services/UtilsService";
import { DashboardTableExcelDownloader } from "../../DashboardTableExcelDownloader";
import { WidgetTypeEnum } from "../../Models/WidgetModels";

const margin = { top: 10, left: 180, right: 40, bottom: 50 };

export const SummarySiteRankingChartViewContent = ({
  sortedChartData,
  chartKeys,
  intensityUnit,
  unit,
  availableBarSpaceCount = barChartMaxCount,
  isExpandedView = false,
}) => {
  const xAxisScaleMaxValue =
    SummarySiteRankingChartUtils.getXAxisScaleMaxValue(sortedChartData);

  let displayedLegendItems;
  let displayedChartData;
  if (chartKeys.length > maximumChartLegendItemCount) {
    displayedLegendItems =
      DashboardWidgetSharedUtils.getMaximumDisplayedLegendItems(chartKeys);
    displayedChartData = SummarySiteRankingChartUtils.getCalculatedChartData(
      sortedChartData,
      displayedLegendItems
    );
  } else {
    displayedLegendItems = chartKeys;
    displayedChartData = sortedChartData;
  }

  const chartColors = DashboardColorPalette.slice(
    0,
    displayedLegendItems.length
  );

  let chartHeight;
  if (isExpandedView) {
    chartHeight =
      (expandedViewSingleHorizontalBarChartHeightInPx +
        expandedViewSingleBarAdditionalHeightInPx) *
        availableBarSpaceCount +
      baseAdditionalHeightInPx;
  } else {
    chartHeight =
      (singleHorizontalBarChartHeightInPx + singleBarAdditionalHeightInPx) *
        availableBarSpaceCount +
      baseAdditionalHeightInPx;
  }

  return (
    <ParentSize className="chart-legend-container">
      {(parent) =>
        parent.width > 0 ? (
          <>
            <div className="chart-container">
              <HorizontalStackedBarChart
                width={parent.width - legendContainerWidthInPx}
                height={chartHeight}
                margin={margin}
                chartData={displayedChartData.map((datum) =>
                  datum.toBarChartData()
                )}
                chartKeys={displayedLegendItems}
                chartColors={chartColors}
                xAxisScaleMaxValue={xAxisScaleMaxValue}
                intensityUnit={intensityUnit}
                unit={unit}
                availableBarSpaceCount={availableBarSpaceCount}
                isExpandedView={isExpandedView}
              />
            </div>
            <div
              className={`legend-container ${
                isExpandedView ? "expanded-view" : ""
              }`}
            >
              <DashboardStackedBarLegend
                legendItems={displayedLegendItems}
                chartColors={chartColors}
              />
            </div>
          </>
        ) : null
      }
    </ParentSize>
  );
};

const SummarySiteRankingChartView = ({
  isLoading,
  title,
  subtopic,
  intensity = null,
  intensityUnit = null,
  hasDataGaps = false,
  period,
  chartData,
  chartKeys,
  unit,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isShowExpandedChartView, setIsShowExpandedChartView] = useState(false);
  const [selectedExpandedViewOption, setSelectedExpandedViewOption] =
    useState(null);
  const [sortOrder, setSortOrder] = useState(siteRankingSortOptions[0]);
  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const isDownloadingFromTableView = useRef(false);

  const showExpandedChartView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
    setIsShowExpandedChartView(true);
  };

  const showExpandedTableView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
    setIsShowExpandedChartView(true);
  };

  let content;
  if (isLoading) {
    content = (
      <div className="dashboard--summary-site-ranking__mini-view-box">
        <LoadingSpinner />
      </div>
    );
  } else {
    const sortedChartData = SummarySiteRankingChartUtils.sortBarChartValues(
      chartData,
      sortOrder.value
    );

    const truncatedSortedChartData =
      chartData.length > barChartMaxCount
        ? sortedChartData.slice(0, barChartMaxCount)
        : sortedChartData;

    const selectedSiteCount = chartData.length;
    const hasData = chartData.length > 0;
    const fileName = DashboardWidgetSharedUtils.getDownloadFileName(
      userInventory.selectedTreeNode.get?.nodeValue?.name || "",
      WidgetTypeEnum.SummarySiteRankingChart,
      subtopic,
      period
    );

    const onSortOrderChange = (newSortOrder) => {
      setSortOrder(newSortOrder);
    };

    const downloadChartAsImage = async (
      chartViewId,
      isFromExpandedView,
      excludeOnDownloadIds
    ) => {
      if (selectedExpandedViewOption === expandedModalViewOptionEnum.TABLE) {
        isDownloadingFromTableView.current = true;
        setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
      }
      setIsDownloadingChart(true);

      setTimeout(async () => {
        try {
          await DashboardWidgetSharedUtils.downloadChartAsImage(
            chartViewId,
            fileName,
            excludeOnDownloadIds,
            isFromExpandedView
          );
        } catch (e) {
          toastContext.addFailToast(
            <span>Failed to download chart as image.</span>
          );
        } finally {
          setIsDownloadingChart(false);
          if (isDownloadingFromTableView.current) {
            setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
            isDownloadingFromTableView.current = false;
          }
        }
      }, downloadChartAsImageTimeoutInMs);
    };

    const onDownloadTableAsExcel = () => {
      const excelSheetName = "Table";
      const displayedUnit = intensityUnit
        ? `(${unit}/${intensityUnit})`
        : `(${unit})`;

      const headerRows = [
        ["Period", period],
        [],
        [
          "Site Name",
          ...chartKeys.map((key) => `${key} ${displayedUnit}`),
          `Total ${displayedUnit}`,
        ],
      ];

      const dataRows = sortedChartData.map((chartData) => {
        const rowData = chartData.toTableRowData(chartKeys);

        return rowData.map((value, index) => {
          if (index === 0) {
            return value;
          }
          return NumberService.format(value);
        });
      });

      DashboardTableExcelDownloader.downloadExcel(
        headerRows,
        dataRows,
        fileName,
        excelSheetName
      );
    };

    content = (
      <div id={summarySiteRankingChartViewId}>
        <div className="dashboard--summary-site-ranking__mini-view-box">
          <SummarySiteRankingChartViewHeader
            title={title}
            subtopic={subtopic}
            intensity={intensity}
            selectedSiteCount={selectedSiteCount}
            period={period}
            hasDataGaps={hasDataGaps}
            showExpandedChartView={showExpandedChartView}
            showExpandedTableView={showExpandedTableView}
            sortOrder={sortOrder}
            onSortOrderChange={onSortOrderChange}
            hasData={hasData}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(summarySiteRankingChartViewId, false, [
                summarySiteRankingMiniViewExcludeOnDownloadContainerId,
              ])
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
          <div className="content-container">
            {hasData ? (
              <SummarySiteRankingChartViewContent
                sortedChartData={truncatedSortedChartData}
                chartKeys={chartKeys}
                intensityUnit={intensityUnit}
                unit={unit}
              />
            ) : (
              <div className="table__no_content">No data available</div>
            )}
          </div>
        </div>
        {hasData && (
          <ExpandedSummarySiteRankingChartView
            isShowExpandedChartView={isShowExpandedChartView}
            onCloseModal={() => setIsShowExpandedChartView(false)}
            title="Site ranking"
            period={period}
            chartData={chartData}
            chartKeys={chartKeys}
            subtopic={subtopic}
            intensity={intensity}
            intensityUnit={intensityUnit}
            unit={unit}
            hasDataGaps={hasDataGaps}
            sortOrder={sortOrder}
            onSortOrderChange={onSortOrderChange}
            selectedExpandedViewOption={selectedExpandedViewOption}
            onSelectExpandedViewOption={(option) =>
              setSelectedExpandedViewOption(option)
            }
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(
                expandedSummarySiteRankingChartViewId,
                true,
                [summarySiteRankingExpandedViewExcludeOnDownloadContainerId]
              )
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        )}
      </div>
    );
  }

  return content;
};

export default SummarySiteRankingChartView;
