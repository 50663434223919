import React from "react";
import greenviewPortalLogo from "../../assets/images/new-greenview-logo.svg";
import { HOME } from "../../config/ROUTES_NAME";
import { Link } from "react-router-dom";

const TemplateGlobalPage = ({ children }) => {
  return (
    <div className="page-layout--global-page">
      <div className="gutter">
        <div className="logo">
          <Link to={HOME}>
            <img src={greenviewPortalLogo} alt="Greenview Portal" />
          </Link>
        </div>
      </div>
      <div className="content-container">{children}</div>
      <div className="gutter" />
    </div>
  );
};

export default TemplateGlobalPage;
