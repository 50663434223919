import GVFormGroup from "../GVFormGroup";
import Form from "react-bootstrap/Form";
import React, { useContext, useState } from "react";

import { buttonVariant } from "../../../gvds-components/Buttons/GVDSButton";
import Spacer from "../../../gvds-components/Layout/Spacer";

import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";

import ViewEnvironmentalDataRecordsActivityLogsList from "./ViewEnvironmentalDataRecordsActivityLogsList";
import ViewMarketPurchaseRecordsActivityLogsList from "./ViewMarketPurchaseRecordsActivityLogsList";
import {
  EnvironmentalDataRecordActivityLogDownloadService,
  MarketPurchaseOffsetRecordActivityLogDownloadService,
} from "../../../services/ActivityLogService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import {
  getDefaultDateRangeEnd,
  getDefaultDateRangeStart,
} from "../MonthRangeSelector";
import ToastContext from "../../../context/ToastContext";
import { useTranslation } from "react-i18next";

const ENVIRONMENTAL_ACTIVITY_LOG_VIEW_STATE = {
  USAGE_METERS: "USAGE_METERS",
  MARKET_OFFSETS: "MARKET_OFFSETS",
};

const ViewEnvironmentalActivityLogs = ({ lastUpdate }) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [activityLogViewState, setActivityLogViewState] = useState(
    ENVIRONMENTAL_ACTIVITY_LOG_VIEW_STATE.USAGE_METERS
  );

  const [
    environmentalDataRecordActivityLogDatePeriodStart,
    setEnvironmentalDataRecordActivityLogDatePeriodStart,
  ] = useState(getDefaultDateRangeStart());
  const [
    environmentalDataRecordActivityLogDatePeriodEnd,
    setEnvironmentalDataRecordActivityLogDatePeriodEnd,
  ] = useState(getDefaultDateRangeEnd());
  const [
    environmentalDataRecordDatePeriodStart,
    setEnvironmentalDataRecordDatePeriodStart,
  ] = useState(null);
  const [
    environmentalDataRecordDatePeriodEnd,
    setEnvironmentalDataRecordDatePeriodEnd,
  ] = useState(null);

  const [
    marketPurchaseOffsetRecordActivityLogDatePeriodStart,
    setMarketPurchaseRecordActivityLogDatePeriodStart,
  ] = useState(getDefaultDateRangeStart());
  const [
    marketPurchaseOffsetRecordActivityLogDatePeriodEnd,
    setMarketPurchaseOffsetRecordActivityLogDatePeriodEnd,
  ] = useState(getDefaultDateRangeEnd());
  const [
    marketPurchaseOffsetRecordDatePeriodStart,
    setMarketPurchaseOffsetRecordDatePeriodStart,
  ] = useState(null);
  const [
    marketPurchaseOffsetRecordDatePeriodEnd,
    setMarketPurchaseOffsetRecordDatePeriodEnd,
  ] = useState(null);

  const onDownloadEnvironmentalDataRecordActivityLogExcel = async () => {
    const currentInventory = userInventory.selectedInventory.get;
    try {
      await EnvironmentalDataRecordActivityLogDownloadService.downloadExcel(
        currentInventory.type,
        currentInventory.id,
        environmentalDataRecordActivityLogDatePeriodStart,
        environmentalDataRecordActivityLogDatePeriodEnd,
        environmentalDataRecordDatePeriodStart,
        environmentalDataRecordDatePeriodEnd
      );
    } catch {
      toastContext.addFailToast(<span>Failed to download excel.</span>);
    }
  };

  const onDownloadMarketPurchaseOffsetRecordActivityLogExcel = async () => {
    const currentInventory = userInventory.selectedInventory.get;
    try {
      await MarketPurchaseOffsetRecordActivityLogDownloadService.downloadExcel(
        currentInventory.type,
        currentInventory.id,
        marketPurchaseOffsetRecordActivityLogDatePeriodStart,
        marketPurchaseOffsetRecordActivityLogDatePeriodEnd,
        marketPurchaseOffsetRecordDatePeriodStart,
        marketPurchaseOffsetRecordDatePeriodEnd
      );
    } catch {
      toastContext.addFailToast(<span>Failed to download excel.</span>);
    }
  };

  let content;
  let downloadExcelFn;
  switch (activityLogViewState) {
    case ENVIRONMENTAL_ACTIVITY_LOG_VIEW_STATE.USAGE_METERS:
      content = (
        <ViewEnvironmentalDataRecordsActivityLogsList
          lastUpdate={lastUpdate}
          setActivityLogDatePeriodStart={
            setEnvironmentalDataRecordActivityLogDatePeriodStart
          }
          activityLogDatePeriodStart={
            environmentalDataRecordActivityLogDatePeriodStart
          }
          setActivityLogDatePeriodEnd={
            setEnvironmentalDataRecordActivityLogDatePeriodEnd
          }
          activityLogDatePeriodEnd={
            environmentalDataRecordActivityLogDatePeriodEnd
          }
          setFilterStartDate={setEnvironmentalDataRecordDatePeriodStart}
          filterStartDate={environmentalDataRecordDatePeriodStart}
          setFilterEndDate={setEnvironmentalDataRecordDatePeriodEnd}
          filterEndDate={environmentalDataRecordDatePeriodEnd}
        />
      );
      downloadExcelFn = onDownloadEnvironmentalDataRecordActivityLogExcel;
      break;
    case ENVIRONMENTAL_ACTIVITY_LOG_VIEW_STATE.MARKET_OFFSETS:
      content = (
        <ViewMarketPurchaseRecordsActivityLogsList
          lastUpdate={lastUpdate}
          setActivityLogDatePeriodStart={
            setMarketPurchaseRecordActivityLogDatePeriodStart
          }
          activityLogDatePeriodStart={
            marketPurchaseOffsetRecordActivityLogDatePeriodStart
          }
          setActivityLogDatePeriodEnd={
            setMarketPurchaseOffsetRecordActivityLogDatePeriodEnd
          }
          activityLogDatePeriodEnd={
            marketPurchaseOffsetRecordActivityLogDatePeriodEnd
          }
          setFilterStartDate={setMarketPurchaseOffsetRecordDatePeriodStart}
          filterStartDate={marketPurchaseOffsetRecordDatePeriodStart}
          setFilterEndDate={setMarketPurchaseOffsetRecordDatePeriodEnd}
          filterEndDate={marketPurchaseOffsetRecordDatePeriodEnd}
        />
      );
      downloadExcelFn = onDownloadMarketPurchaseOffsetRecordActivityLogExcel;
      break;
    default:
      content = (
        <ViewEnvironmentalDataRecordsActivityLogsList
          lastUpdate={lastUpdate}
          setActivityLogDatePeriodStart={
            setEnvironmentalDataRecordActivityLogDatePeriodStart
          }
          activityLogDatePeriodStart={
            environmentalDataRecordActivityLogDatePeriodStart
          }
          setActivityLogDatePeriodEnd={
            setEnvironmentalDataRecordActivityLogDatePeriodEnd
          }
          activityLogDatePeriodEnd={
            environmentalDataRecordActivityLogDatePeriodEnd
          }
          setFilterStartDate={setEnvironmentalDataRecordDatePeriodStart}
          filterStartDate={environmentalDataRecordDatePeriodStart}
          setFilterEndDate={setEnvironmentalDataRecordDatePeriodEnd}
          filterEndDate={environmentalDataRecordDatePeriodEnd}
        />
      );
      downloadExcelFn = onDownloadEnvironmentalDataRecordActivityLogExcel;
      break;
  }

  return (
    <>
      <GVFormGroup>
        <Form.Label>
          {t(
            "data-management.shared.activity-log.label-view-activity-log-for"
          )}
        </Form.Label>
        <div className="d-flex">
          <div>
            <Form.Check
              id="usage-meters-activity-log"
              label={t("data-management.environmental.all-records.view-usage")}
              type="radio"
              checked={
                activityLogViewState ===
                ENVIRONMENTAL_ACTIVITY_LOG_VIEW_STATE.USAGE_METERS
              }
              onChange={() =>
                setActivityLogViewState(
                  ENVIRONMENTAL_ACTIVITY_LOG_VIEW_STATE.USAGE_METERS
                )
              }
            />
            <Form.Check
              id="market-offsets-activity-log"
              label={t(
                "data-management.environmental.all-records.view-market-purchase"
              )}
              type="radio"
              checked={
                activityLogViewState ===
                ENVIRONMENTAL_ACTIVITY_LOG_VIEW_STATE.MARKET_OFFSETS
              }
              onChange={() => {
                setActivityLogViewState(
                  ENVIRONMENTAL_ACTIVITY_LOG_VIEW_STATE.MARKET_OFFSETS
                );
              }}
            />
          </div>
          <Spacer />
          <GVDSButtonWithLoadingAction
            className="mb-2"
            text={t(
              "data-management.shared.activity-log.button-download-activity-log"
            )}
            variant={buttonVariant.primary}
            onClickAsyncFunc={downloadExcelFn}
          />
        </div>
      </GVFormGroup>
      {content}
    </>
  );
};

export default ViewEnvironmentalActivityLogs;
