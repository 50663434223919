import { TOOLTIP_PLACEMENTS } from "../../../config/style-config";

export class TooltipUtils {
  static getTooltipContentStyle(placement) {
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/2775
    let contentStyle;

    switch (placement) {
      case TOOLTIP_PLACEMENTS.TOP:
        contentStyle = { marginBottom: "10px" };
        break;
      case TOOLTIP_PLACEMENTS.BOTTOM:
        contentStyle = { marginTop: "2px" };
        break;
      case TOOLTIP_PLACEMENTS.LEFT:
        contentStyle = { marginRight: "2px" };
        break;
      case TOOLTIP_PLACEMENTS.RIGHT:
        contentStyle = { marginLeft: "2px" };
        break;
      default:
        contentStyle = {
          marginBottom: "10px",
          marginTop: "2px",
          marginLeft: "2px",
          marginRight: "2px",
        };
    }

    return contentStyle;
  }
}
