import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import withAuthentication from "../../HOC/withAuthentication";
import {
  getAdminEditBestPracticePath,
  SYSTEM_TOOLBOX_BEST_PRACTICES_CREATE,
} from "../../../config/ROUTES_NAME";
import BestPracticeAdminService from "../../../services/BestPracticeAdminService";
import uniq from "lodash/uniq";
import { BEST_PRACTICE_QUESTION_TYPE } from "../../../config/constants";
import MoveBestPracticeDialog from "./MoveBestPracticeDialog";
import ToastContext from "../../../context/ToastContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import { BEST_PRACTICE_ADOPTION_LEVEL_INFO } from "./BestPracticeModel";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSTag from "../../../gvds-components/common/GVDSTag";
import {
  IconArrowsVertical,
  IconChevronDown,
  IconChevronUp,
  IconCirclePlus,
  IconEdit,
} from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import Container from "react-bootstrap/Container";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import BestPracticeStatusBadge from "./BestPracticeStatusBadge";

const ViewAllBestPractices = () => {
  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Best Practices List</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="best-practices-list">
        <Tab
          eventKey="best-practices-list"
          title="Best Practices"
          tabClassName="tab-nav"
        >
          <BestPracticesList />
        </Tab>
      </Tabs>
    </Container>
  );
};

const BestPracticesList = () => {
  const history = useHistory();
  const goToCreate = () => {
    history.push(SYSTEM_TOOLBOX_BEST_PRACTICES_CREATE);
  };

  const goToEdit = (q) => {
    history.push(getAdminEditBestPracticePath(q.id));
  };

  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(true);
  const [questionList, setQuestionList] = useState([]);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [questionToBeMoved, setQuestionToBeMoved] = useState(null);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    questionList,
    SystemToolboxSearchKeysByPageName.bestPractice
  );

  const closeMoveDialog = () => {
    setQuestionToBeMoved(null);
    setShowMoveDialog(false);
  };
  const promptMoveQuestion = (question) => {
    setQuestionToBeMoved(question);
    setShowMoveDialog(true);
  };

  const loadQuestions = () => {
    setIsLoading(true);
    BestPracticeAdminService.getAllBestPracticeQuestion()
      .then((questions) => {
        setQuestionList(questions);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Something went wrong. Please refresh page.</span>
        );
      });
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  const columns = [
    {
      header: "Question",
      dataKey: "question_text",
      sortable: true,
      renderer: (q) => <QuestionDisplay key={q.id} questionItem={q} />,
    },
    {
      header: "Categories",
      dataKey: "categoryNames",
      renderer: (q) =>
        q.categoryNames.map((category) => (
          <GVDSTag variant={GVDSTag.Variants.system} key={category}>
            {category}
          </GVDSTag>
        )),
    },
    {
      header: "Tags",
      dataKey: "tagNames",
      renderer: (q) =>
        q.tagNames.map((tag) => (
          <GVDSTag variant={GVDSTag.Variants.system} key={tag}>
            {tag}
          </GVDSTag>
        )),
    },
    {
      header: "Status",
      dataKey: "status",
      renderer: (q) => <BestPracticeStatusBadge status={q.status} />,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (q) => {
        return (
          <>
            <GVDSIconButton
              className="edit-button"
              variant={iconButtonVariant.tertiary}
              onClick={() => {
                goToEdit(q);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              className="move-button"
              variant={iconButtonVariant.tertiary}
              onClick={() => promptMoveQuestion(q)}
              icon={<GVDSIcon Icon={IconArrowsVertical} />}
              tooltipText="Move"
            />
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            className="add-best-practice"
            onClick={goToCreate}
            icon={<GVDSIcon Icon={IconCirclePlus} />}
            text="Add a Best Practice"
          />
        </PageHeader.Title>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search Best Practice Question"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(questionList.map((q) => q.categoryNames).flat())}
          prefix="Categories"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, categoryNames: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(questionList.map((q) => q.status))}
          prefix="Status"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, status: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(questionList.map((q) => q.tagNames).flat())}
          prefix="Tags"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, tagNames: filterKs })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        className="best-practice-list"
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {questionList.length === 0 ? (
        <div className="table__no_content">No best practice available</div>
      ) : (
        filteredSortedData.length === 0 && (
          <div className="table__no_content">
            No best practice found. Try changing your filter.
          </div>
        )
      )}
      <MoveBestPracticeDialog
        show={showMoveDialog}
        onHide={closeMoveDialog}
        onMoveSuccess={loadQuestions}
        questionToBeMoved={questionToBeMoved}
        allQuestions={questionList}
      />
    </Container>
  );
};

const QuestionDisplay = ({ questionItem }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (isExpanded && options === null) {
      BestPracticeAdminService.getBestPracticeQuestionOptions(
        questionItem.id
      ).then((options) => {
        setOptions(options);
        setIsLoading(false);
      });
    }
  }, [isExpanded]);

  return (
    <div>
      <div className="mb-2">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsExpanded(!isExpanded);
          }}
        >
          <div className="text-vertical-center">
            <span className="me-2">
              {isExpanded ? (
                <GVDSIconSlim Icon={IconChevronUp} />
              ) : (
                <GVDSIconSlim Icon={IconChevronDown} />
              )}
            </span>
            {questionItem.question_text}
          </div>
        </a>
      </div>
      {isExpanded && isLoading && (
        <div>
          <Spinner animation="grow" variant="greenview" />
        </div>
      )}
      {isExpanded && !isLoading && (
        <div>
          {options.map((option, index) => {
            const adoptionInfo =
              BEST_PRACTICE_ADOPTION_LEVEL_INFO[option["adoption_level"]];
            return (
              <div key={index} className="d-flex">
                <Form.Check
                  disabled
                  key={option.id}
                  label=""
                  type={
                    questionItem.questionType ===
                    BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE
                      ? "radio"
                      : "checkbox"
                  }
                />
                <span>
                  <span className="ms-1 me-1">{option["option_text"]}</span>{" "}
                  {adoptionInfo && (
                    <InfoTooltip
                      info={adoptionInfo?.definition}
                      icon={adoptionInfo?.icon}
                    />
                  )}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withAuthentication(ViewAllBestPractices);
