import React from "react";
import { RESOURCES } from "../../../config/constants";
import {
  faBuilding,
  faHome,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TODO replace below
export const InventoryIcon = (type, className, size = "1x") => {
  switch (type) {
    case RESOURCES.PORTFOLIO:
      return (
        <FontAwesomeIcon
          className={className}
          size={size}
          icon={faLayerGroup}
        />
      );
    case RESOURCES.SITE:
      return (
        <FontAwesomeIcon className={className} size={size} icon={faBuilding} />
      );
    case RESOURCES.FACILITY:
      return (
        <FontAwesomeIcon className={className} size={size} icon={faHome} />
      );
    default:
      return null;
  }
};

export const InventoryDisplay = (
  nodeValue,
  showCount = false,
  isBold = false
) => {
  const sitesCount =
    nodeValue.type === RESOURCES.PORTFOLIO ? nodeValue.value.sites.length : 0;
  const facilitiesCount =
    nodeValue.type === RESOURCES.PORTFOLIO
      ? nodeValue.value.facilities.length
      : 0;
  let inventoryName = nodeValue.name;
  if (showCount === true) {
    inventoryName += ` (${sitesCount + facilitiesCount})`;
  }
  return (
    <div className="inventory-display">
      <div className="inventory-display__icon-container">
        {InventoryIcon(nodeValue.type)}
      </div>
      <div className={`inventory-display__name ${isBold ? "fw-bold" : ""}`}>
        {inventoryName}
      </div>
    </div>
  );
};
