export const singleBarAdditionalHeightInPx = 10;
export const expandedViewSingleBarAdditionalHeightInPx = 8;
export const baseAdditionalHeightInPx = 75;
export const barChartMaxCount = 5;
export const legendContainerWidthInPx = 240;

export const summarySiteRankingChartViewId =
  "summary-site-ranking-chart-view-id";
export const expandedSummarySiteRankingChartViewId =
  "expanded-summary-site-ranking-chart-view-id";
export const summarySiteRankingMiniViewExcludeOnDownloadContainerId =
  "summary-site-ranking-mini-view-exclude-on-download-container-id";
export const summarySiteRankingExpandedViewExcludeOnDownloadContainerId =
  "summary-site-ranking-expanded-view-exclude-on-download-container-id";
