import axios from "axios";
import { API_URL } from "../config/api-config";

export class CompanyListItemModel {
  constructor(companyListItem) {
    this.id = companyListItem.id;
    this.name = companyListItem.name;
    this.company_types = companyListItem.company_types;
    this.users = companyListItem.users;
    this.related_entities = companyListItem.related_entities;
  }

  get company_types_name() {
    return this.company_types.map((company_type) => company_type.name);
  }
}

export default class CompanyService {
  static getCreateCompanyOptions = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/company_options"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static createNewCompany = async (name, company_types) => {
    try {
      const data = {
        name,
        company_types,
      };
      const response = await axios.post(
        API_URL + "/api/v1/ref_data/companies",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static getAllCompanies = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/ref_data/companies");
      return response.data.map((a) => new CompanyListItemModel(a));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static getCompanyById = async (companyId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/company/" + companyId
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static updateCompany = async (companyId, name, company_types) => {
    try {
      const data = {
        name,
        company_types,
      };
      const response = await axios.patch(
        API_URL + `/api/v1/ref_data/company/${companyId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteDataRecords = async (companyId) => {
    try {
      const response = await axios.delete(
        API_URL + `/api/v1/ref_data/company/${companyId}`
      );
      return response;
    } catch (error) {}
  };
}
