import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { StringUtils } from "../../../services/UtilsService";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import GVDSButton from "../../../gvds-components/Buttons/GVDSButton";
import React, { useState } from "react";
import { UnitConversionTypeEnum } from "../../../services/ReferenceDataServices/UnitsService";
import { TroubleshootDataUnitConversionService } from "../../../services/TroubleshootDataService";
import withAuthentication from "../../HOC/withAuthentication";
import LoadingSpinner from "../../common/LoadingSpinner";

const UnitConversion = ({
  unitConversionFactors,
  isLoadingConversionFactors,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [selectedInputUnit, setSelectedInputUnit] = useState(null);
  const [selectedOutputUnit, setSelectedOutputUnit] = useState(null);
  const [inputValue, setInputValue] = useState(null);

  const [outputConvertedValue, setOutputConvertedValue] = useState(null);
  const [outputConversionFactor, setOutputConversionFactor] = useState(null);

  const isConversionInputValid = () => {
    return (
      StringUtils.isNotEmpty(selectedInputUnit) &&
      StringUtils.isNotEmpty(selectedOutputUnit) &&
      StringUtils.isNotEmpty(inputValue)
    );
  };

  const submitUsageConversion = () => {
    setIsValidated(true);
    if (isConversionInputValid()) {
      setIsLoading(true);
      TroubleshootDataUnitConversionService.convert(
        selectedInputUnit,
        selectedOutputUnit,
        inputValue
      )
        .then((conversion) => {
          setOutputConvertedValue(conversion.converted_value);
          setOutputConversionFactor(conversion.conversion_factor);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getUnitOptionByValue = (optionValue) => {
    if (unitConversionFactors) {
      const option = unitConversionFactors.find(
        (option) => option.name === optionValue
      );
      return option ? { value: option.name, label: option.unit_name } : null;
    } else {
      return null;
    }
  };

  const getUnitObjFromUnitName = (unitName) => {
    return unitConversionFactors.find((unit) => unit.name === unitName);
  };

  const getOutputUnitsFromSelectedInputUnitType = () => {
    if (!selectedInputUnit) {
      return [];
    }
    const inputUnit = getUnitObjFromUnitName(selectedInputUnit);
    const outputUnitsFromInputUnitType = selectedInputUnit
      ? unitConversionFactors.filter(
          (unit) =>
            unit.unit_type ===
            getUnitObjFromUnitName(selectedInputUnit).unit_type
        )
      : [];

    if (inputUnit.unit_type === UnitConversionTypeEnum.DRY_VOLUME) {
      const dryVolumeUnitConversionFactors = unitConversionFactors.filter(
        (unit) => unit.unit_type === UnitConversionTypeEnum.LIQUID_VOLUME
      );
      outputUnitsFromInputUnitType.push(...dryVolumeUnitConversionFactors);
    } else if (inputUnit.unit_type === UnitConversionTypeEnum.LIQUID_VOLUME) {
      const liquidVolumeUnitConversionFactors = unitConversionFactors.filter(
        (unit) => unit.unit_type === UnitConversionTypeEnum.DRY_VOLUME
      );
      outputUnitsFromInputUnitType.push(...liquidVolumeUnitConversionFactors);
    }

    return outputUnitsFromInputUnitType.sort(
      TroubleshootDataUnitConversionService.UnitConversionSortFn
    );
  };

  const unitTypes = Array.from(
    new Set(unitConversionFactors.map((unit) => unit.unit_type))
  );

  const unitConversionFactorBySelectedUnitType = unitConversionFactors
    .filter((unit) => unit.unit_type === selectedUnitType)
    .sort(TroubleshootDataUnitConversionService.UnitConversionSortFn);

  let content;

  if (isLoadingConversionFactors) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <div className="troubleshoot-data-tool__container">
        <h3>Basic Unit Conversion</h3>
        <Row>
          <Col>
            <GVFormGroup controlId="usage">
              <Form.Label>Input Value</Form.Label>
              <GVDSFormField
                type="number"
                placeholder="Enter usage"
                value={inputValue}
                onInput={(value) => setInputValue(value)}
                statusMetadata={
                  isValidated && !StringUtils.isNotEmpty(inputValue)
                    ? FormFieldStatusMetadata.getError(
                        "Please enter a input value."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <Form.Label>Unit Type</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Select Unit Type"
                value={
                  selectedUnitType
                    ? { value: selectedUnitType, label: selectedUnitType }
                    : null
                }
                options={unitTypes.map((type) => {
                  return { value: type, label: type };
                })}
                onSelect={(option) => setSelectedUnitType(option.value)}
                statusMetadata={
                  isValidated && !selectedUnitType
                    ? FormFieldStatusMetadata.getError(
                        "Please select a unit type"
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>Input Unit</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Select Input Unit"
                value={
                  selectedInputUnit
                    ? getUnitOptionByValue(selectedInputUnit)
                    : null
                }
                options={unitConversionFactorBySelectedUnitType.map((unit) => {
                  return { value: unit.name, label: unit.unit_name };
                })}
                onSelect={(option) => setSelectedInputUnit(option.value)}
                statusMetadata={
                  isValidated && !selectedInputUnit
                    ? FormFieldStatusMetadata.getError("Please select a unit")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>

          <Col>
            <GVFormGroup>
              <Form.Label>Output Unit</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Select Output Unit"
                value={
                  selectedInputUnit
                    ? getUnitOptionByValue(selectedOutputUnit)
                    : null
                }
                options={getOutputUnitsFromSelectedInputUnitType().map(
                  (unit) => {
                    return { value: unit.name, label: unit.unit_name };
                  }
                )}
                onSelect={(option) => setSelectedOutputUnit(option.value)}
                statusMetadata={
                  isValidated && !selectedOutputUnit
                    ? FormFieldStatusMetadata.getError("Please select a unit")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <GVFormGroup controlId="conversionFactor">
              <Form.Label>Unit Conversion Factor</Form.Label>
              <GVDSFormField
                placeholder="Conversion Factor"
                type="number"
                value={outputConversionFactor}
                disabled
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup controlId="convertedUsage">
              <Form.Label>Converted Value</Form.Label>
              <GVDSFormField
                placeholder="Converted Usage"
                type="number"
                value={outputConvertedValue}
                disabled
              />
            </GVFormGroup>
          </Col>
        </Row>
        <GVDSButton
          onClick={submitUsageConversion}
          disabled={isLoading || !isConversionInputValid}
          text={isLoading ? "Converting" : "Convert"}
        />
      </div>
    );
  }

  return content;
};

export default withAuthentication(UnitConversion);
