import React, { useContext } from "react";

import Toast from "react-bootstrap/Toast";

import ToastContext from "../../context/ToastContext";
import { TOAST_TYPE } from "../../config/constants";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCircleCheckFilled, IconForbidFilled } from "@tabler/icons-react";

const ICON_BY_TYPE = {
  [TOAST_TYPE.SUCCESS]: IconCircleCheckFilled,
  [TOAST_TYPE.FAIL]: IconForbidFilled,
};

const ToastsDisplay = () => {
  const toastContext = useContext(ToastContext);
  const toasts = toastContext.getAllToasts();

  return (
    <div className="toasts-container">
      {toasts.map((toast) => (
        <Toast
          className={`toast-display toast-display-${toast.type}`}
          key={toast.id}
          show={toasts.indexOf(toast) >= 0}
          onClose={() => toastContext.removeToast(toast)}
          delay={toast.type === TOAST_TYPE.SUCCESS ? 5000 : 0}
          autohide={toast.type !== TOAST_TYPE.FAIL}
        >
          <Toast.Header className="toast-display-header">
            <div className="toast-display-header--icon">
              <GVDSIcon Icon={ICON_BY_TYPE[toast.type]} />
            </div>
            {toast.display}
          </Toast.Header>
        </Toast>
      ))}
    </div>
  );
};

export default ToastsDisplay;
