import React from "react";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DropdownOptions } from "../../Site/SiteFacilityInformationComponents";
import SiteFacilityTypeOptionsService from "../../../services/ReferenceDataServices/SiteFacilityTypeOptionsService";

const getSiteNameErrorMessage = (signUpModel) => {
  return !signUpModel.isSiteNameNotEmpty()
    ? "Please enter your site name."
    : !signUpModel.isSiteNameNoBlacklistedCharacter()
    ? "Site name contains special characters. Only letters, numbers and symbols are allowed."
    : "Invalid site name.";
};

const getProvincesOfCountry = (countryId, provinces) => {
  return provinces && countryId
    ? provinces.filter((p) => p.country.id === countryId)
    : [];
};

const onCountryChange = (countryId, provinces, signUpModel) => {
  const provincesOfCountry = getProvincesOfCountry(countryId, provinces);
  const selectedProvince = provincesOfCountry
    ? provincesOfCountry.find((p) => p.name === signUpModel.stateOrProvince)
    : null;
  if (selectedProvince && selectedProvince.country.id !== countryId) {
    signUpModel.stateOrProvince = "";
  } else if (!selectedProvince && provincesOfCountry.length > 0) {
    signUpModel.stateOrProvince = "";
  }
};

export const SignUpSiteDetailsFormFiew = ({
  signUpOptions,
  signUpModel,
  onModelChange,
  isValidated,
}) => {
  const siteTypes = signUpOptions["types"];
  const countries = signUpOptions["countries"];
  const provinces = signUpOptions["provinces"];

  const stateOrProvinceInput = () => {
    const provincesOfCountry = getProvincesOfCountry(
      signUpModel.country?.id,
      provinces
    );

    if (provincesOfCountry.length > 0) {
      return (
        <>
          <DropdownOptions
            label="State / Province"
            inEditMode={true}
            targetName="stateOrProvince"
            value={{
              id: signUpModel.stateOrProvince,
              name: signUpModel.stateOrProvince,
            }}
            onChange={(targetName, value, label) => {
              signUpModel.stateOrProvince = value;
              onModelChange();
            }}
            options={provincesOfCountry.map((p) => {
              return { id: p.name, name: p.name };
            })}
            isInvalid={isValidated && !signUpModel.isStateOrProvinceValid()}
            invalidMessage="State or Province is required."
          />
        </>
      );
    } else {
      return (
        <>
          <Form.Label>State / Province</Form.Label>
          <GVDSFormField
            name="stateOrProvince"
            value={signUpModel.stateOrProvince}
            onInput={(value) => {
              signUpModel.stateOrProvince = value;
              onModelChange();
            }}
            statusMetadata={
              isValidated && !signUpModel.isStateOrProvinceValid()
                ? FormFieldStatusMetadata.getError(
                    "State or Province is required."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </>
      );
    }
  };

  return (
    <>
      <Row>
        <Col>
          <GVFormGroup>
            <Form.Label>Property Name</Form.Label>
            <GVDSFormField
              name="siteName"
              placeholder="Property Name"
              value={signUpModel.siteName}
              onInput={(value) => {
                signUpModel.siteName = value;
                onModelChange();
              }}
              statusMetadata={
                isValidated && !signUpModel.isSiteNameValid()
                  ? FormFieldStatusMetadata.getError(
                      getSiteNameErrorMessage(signUpModel)
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
        </Col>
        <Col>
          <GVFormGroup>
            <DropdownOptions
              label="Property Type"
              targetName="siteType"
              inEditMode={true}
              value={signUpModel.siteType}
              onChange={(targetName, value, label) => {
                signUpModel.siteType = { id: value, name: label };
                onModelChange();
              }}
              options={siteTypes}
              isInvalid={isValidated && !signUpModel.isSiteTypeValid()}
              invalidMessage="Please select a site type."
              optionSortFn={
                SiteFacilityTypeOptionsService.siteFacilityTypeSortFn
              }
            />
          </GVFormGroup>
        </Col>
      </Row>

      <GVFormGroup>
        <Form.Label>Street Address</Form.Label>
        <GVDSFormField
          name="streetAddress"
          value={signUpModel.streetAddress}
          onInput={(value) => {
            signUpModel.streetAddress = value;
            onModelChange();
          }}
          statusMetadata={
            isValidated && !signUpModel.isStreetAddressValid()
              ? FormFieldStatusMetadata.getError("Street address is required.")
              : FormFieldStatusMetadata.getDefault()
          }
        />
      </GVFormGroup>
      <Row>
        <Col>
          <GVFormGroup>
            <Form.Label>City</Form.Label>
            <GVDSFormField
              name="city"
              value={signUpModel.city}
              onInput={(value) => {
                signUpModel.city = value;
                onModelChange();
              }}
              statusMetadata={
                isValidated && !signUpModel.isCityValid()
                  ? FormFieldStatusMetadata.getError("City is required.")
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
        </Col>
        <Col>
          <GVFormGroup>
            <Form.Label>Postal Code</Form.Label>
            <GVDSFormField
              name="zipCode"
              value={signUpModel.zipCode}
              onInput={(value) => {
                signUpModel.zipCode = value;
                onModelChange();
              }}
              statusMetadata={
                isValidated && !signUpModel.isZipCodeValid()
                  ? FormFieldStatusMetadata.getError(
                      "Postal code is not valid."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>{stateOrProvinceInput()}</Col>
        <Col>
          <GVFormGroup>
            <DropdownOptions
              label="Country"
              inEditMode={true}
              targetName="country"
              value={signUpModel.country}
              onChange={(targetName, value, label) => {
                signUpModel.country = { id: value, name: label };
                onCountryChange(value, provinces, signUpModel);
                onModelChange();
              }}
              options={countries}
              isInvalid={isValidated && !signUpModel.isCountryValid()}
              invalidMessage="Please select a country"
            />
          </GVFormGroup>
        </Col>
      </Row>
    </>
  );
};

const SignUpFormView = ({
  signUpOptions,
  signUpModel,
  onModelChange,
  isValidated,
}) => {
  return (
    <div>
        <GVFormGroup>
          <Form.Label>Business Email</Form.Label>
          <GVDSFormField
            name="emailAddress"
            className="signup-form__email-input"
            value={signUpModel.emailAddress}
            onInput={(value) => {
              signUpModel.emailAddress = value;
              onModelChange();
            }}
            statusMetadata={
              isValidated && !signUpModel.isEmailAddressValid()
                ? FormFieldStatusMetadata.getError(
                    "Please enter a valid email."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>Your Name</Form.Label>
              <GVDSFormField
                name="userFullName"
                value={signUpModel.userFullName}
                onInput={(value) => {
                  signUpModel.userFullName = value;
                  onModelChange();
                }}
                statusMetadata={
                  isValidated && !signUpModel.isNameValid()
                    ? FormFieldStatusMetadata.getError(
                        "Please enter your name."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <Form.Label>Company Name</Form.Label>
              <GVDSFormField
                name="company"
                value={signUpModel.companyName}
                onInput={(value) => {
                  signUpModel.companyName = value;
                  onModelChange();
                }}
                statusMetadata={
                  isValidated && !signUpModel.isCompanyValid()
                    ? FormFieldStatusMetadata.getError(
                        "Please enter your company."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <SignUpSiteDetailsFormFiew
          signUpOptions={signUpOptions}
          signUpModel={signUpModel}
          onModelChange={onModelChange}
          isValidated={isValidated}
        />
    </div>
  );
};

export default SignUpFormView;
