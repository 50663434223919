import React from "react";

import { BarLoader } from "react-spinners";
import { GVDSColors } from "../../styles/gvds-colors";

const InlineSpinner = ({
  color = GVDSColors.tealSolid,
  height = 5,
  width = 100,
}) => {
  return (
    <div className="center-content">
      <BarLoader color={color} loading={true} height={height} width={width} />
    </div>
  );
};

export default InlineSpinner;
