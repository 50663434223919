import React from "react";
import { getSortingButton } from "../../gvds-components/Table/GVDSTable";

const TableHeaderColumnWithSortButton = ({
  className,
  headerTitle,
  dataKey,
  sortKeys,
  setSortKeys,
}) => {
  return (
    <th className={className}>
      <div className="gvds-table-header__header-cell">
        <div>{headerTitle}</div>
        {getSortingButton(sortKeys, dataKey, setSortKeys)}
      </div>
    </th>
  );
};

export default TableHeaderColumnWithSortButton;
