import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import goalTargetModelIllustration from "../../assets/images/goaltargetmodel.png";
import GoalService from "../../services/GoalService";
import LoadingSpinner from "../common/LoadingSpinner";
import GVFormGroup from "../common/GVFormGroup";
import PermissionsContext from "../../context/PermissionsContext";
import { PERMISSIONS } from "../../config/constants";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import {
  IconBulb,
  IconCirclePlus,
  IconEdit,
  IconTargetArrow,
  IconTrash,
} from "@tabler/icons-react";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import GVDSTableDisplay from "../../gvds-components/Table/GVDSTableDisplay";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";

const ViewAllGoals = ({ isLoading, goals, onGoalChanged }) => {
  const permissionCtx = useContext(PermissionsContext);
  const [goalToBeEdited, setGoalToBeEdited] = useState(null);
  const [showInputModal, setShowInputModal] = useState(false);
  const closeInputModal = () => {
    setShowInputModal(false);
    setGoalToBeEdited(null);
  };
  const [goalToBeDeleted, setGoalToBeDeleted] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setGoalToBeDeleted(null);
  };
  const isAllowedToCreateGoal =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.GOALS_N_TARGETS_CREATE];
  const isAllowedToEditGoal =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.GOALS_N_TARGETS_EDIT];
  const isAllowedToDeleteGoal =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.GOALS_N_TARGETS_DELETE];

  let content;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else if (!goals || goals.length === 0) {
    content = (
      <div className="view-all-goals__no-content">
        <GVDSIcon Icon={IconTargetArrow} className="gvds-empty-content--icon" />
        <div className="gvds-empty-content--title">
          Use goals to group your targets
        </div>
        <div>
          Click on the “Create Goal” button at the top-right of this section to
          create goals and group your targets.
        </div>
      </div>
    );
  } else {
    content = (
      <GVDSTableDisplay className="view-all-goals-table__container">
        <tbody>
          {goals.map((goal, index) => {
            return (
              <tr key={goal.id}>
                <td>
                  <div className="view-all-goals-table__row-container">
                    <div className="view-all-goals-table__row__index">
                      {index + 1 + "."}
                    </div>
                    <div className="view-all-goals-table__row__name">
                      {goal.name}
                    </div>
                    {isAllowedToEditGoal && (
                      <GVDSIconButton
                        variant={iconButtonVariant.tertiary}
                        className="trigger-edit-goal"
                        onClick={() => {
                          setGoalToBeEdited(goal);
                          setShowInputModal(true);
                        }}
                        icon={<GVDSIcon Icon={IconEdit} />}
                        tooltipText="Edit"
                      />
                    )}
                    {isAllowedToDeleteGoal && (
                      <GVDSIconButton
                        variant={iconButtonVariant.destructive}
                        className="trigger-delete-goal"
                        onClick={() => {
                          setGoalToBeDeleted(goal);
                          setShowDeleteModal(true);
                        }}
                        icon={<GVDSIcon Icon={IconTrash} />}
                        tooltipText="Delete"
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </GVDSTableDisplay>
    );
  }

  return (
    <div className="mb-4 guided-tour-target__goals">
      <div className="d-flex flex-row">
        <h2>Goals</h2>
        <div className="ms-auto d-flex flex-row">
          {isAllowedToCreateGoal && (
            <GVDSButton
              variant={buttonVariant.primary}
              className="create-goal-button guided-tour-target__create-goal"
              onClick={() => setShowInputModal(true)}
              icon={<GVDSIcon Icon={IconCirclePlus} />}
              text="Create Goal"
            />
          )}
        </div>
      </div>
      <div className="my-2">{content}</div>
      <InputGoalModal
        show={showInputModal}
        close={closeInputModal}
        currentGoal={goalToBeEdited}
        onSuccess={onGoalChanged}
      />
      <DeleteGoalModal
        show={showDeleteModal}
        close={closeDeleteModal}
        goal={goalToBeDeleted}
        onSuccess={() => {
          setGoalToBeDeleted(null);
          if (onGoalChanged) {
            onGoalChanged();
          }
        }}
      />
    </div>
  );
};

const InputGoalModal = ({ show, close, currentGoal, onSuccess }) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);
  const [isEditExistingGoal, setIsEditExistingMeter] = useState(false);
  const [goalName, setGoalName] = useState("");
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    if (currentGoal != null) {
      setGoalName(currentGoal.name);
      setIsEditExistingMeter(true);
    } else {
      setGoalName("");
      setIsEditExistingMeter(false);
    }
  }, [currentGoal]);

  const saveGoal = async () => {
    setIsValidated(true);
    if (!isGoalNameInvalid()) {
      setIsLoading(true);
      try {
        if (isEditExistingGoal) {
          await GoalService.editGoal(
            selectedInventory.type,
            selectedInventory.id,
            currentGoal.id,
            goalName
          );
          toastContext.addSuccessToast(
            <span>Your goal was edited successfully.</span>
          );
        } else {
          await GoalService.createGoal(
            selectedInventory.type,
            selectedInventory.id,
            goalName
          );
          toastContext.addSuccessToast(
            <span>Your goal was created successfully.</span>
          );
        }

        if (onSuccess) {
          onSuccess();
        }
        close();
        setGoalName("");
      } catch (e) {
        toastContext.addFailToast(
          <span>Failed to {isEditExistingGoal ? "edit" : "create"} goal.</span>
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isGoalNameInvalid = () => {
    return goalName.length === 0;
  };

  return (
    <GVDSModal
      title={`${isEditExistingGoal ? "Edit" : "Create"} Goal`}
      size={GVDSModal.Size.large}
      show={show}
      onHide={close}
    >
      <GVDSModal.Body>
        <GVFormGroup>
          <Form.Label>Goal Name</Form.Label>
          <GVDSFormField
            name="goal-name"
            placeholder="Goal Name"
            value={goalName}
            onInput={(value) => setGoalName(value)}
            statusMetadata={
              isValidated && isGoalNameInvalid()
                ? FormFieldStatusMetadata.getError("Please enter goal name")
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <div className="d-flex justify-content-center">
          <img
            src={goalTargetModelIllustration}
            alt="Goal Target Model"
            className="goal-target-model-illustration"
          />
        </div>
        <div>
          <p>
            <strong>Goals</strong> describe your sustainability commitments,
            with linked target(s) that are measurable and monitored.
          </p>

          <div>
            <div className="d-flex flex-row">
              <div className="me-1">
                <GVDSIcon Icon={IconBulb} />
              </div>
              <div className="flex-grow-1 text-vertical-center">
                Quick tips on naming your goal:
              </div>
            </div>
            <ul>
              <li>
                <strong>Ambitious</strong> - Challenging and significant
              </li>
              <li>
                <strong>Inspiring</strong> - Motivates your team with a clear
                image of where you want to be
              </li>
              <li>
                <strong>Concise</strong> - Keep it short and memorable
              </li>
            </ul>
          </div>

          <div>
            <div className="d-flex flex-row">
              <div className="me-1">
                <GVDSIcon Icon={IconBulb} />
              </div>
              <div className="flex-grow-1 text-vertical-center">Examples:</div>
            </div>
            <ul>
              <li>
                Have the lowest environmental footprint of all hotel chains in
                our market segment.
              </li>
              <li>
                Exceed guest expectations by anticipating and fulfilling their
                wishes when it comes to sustainability.
              </li>
              <li>Sustain responsible operations.</li>
              <li>
                Maintain integrity, fairness and honesty in all our internal and
                external relationships.
              </li>
              <li>Zero food waste.</li>
            </ul>
          </div>
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={close}
          disabled={isLoading}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          className="save-goal"
          onClick={saveGoal}
          disabled={isLoading}
          text="Save"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const DeleteGoalModal = ({ show, close, goal, onSuccess }) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);

  const deleteGoal = async () => {
    setIsLoading(true);
    try {
      await GoalService.deleteGoal(
        selectedInventory.type,
        selectedInventory.id,
        goal.id
      );
      toastContext.addSuccessToast(
        <span>Your goal was deleted successfully.</span>
      );

      if (onSuccess) {
        onSuccess();
      }
      close();
    } catch (e) {
      toastContext.addFailToast(<span>Failed to delete goal.</span>);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GVDSModal
      title="Delete Goal"
      size={GVDSModal.Size.small}
      show={show}
      onHide={close}
    >
      <GVDSModal.Body>
        <div>
          You are about to delete the goal <strong>{goal?.name}</strong>.
        </div>
        <br />
        <div>
          This also removes all associations it has with any targets. Your
          targets will not be deleted. Are you sure?
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={close}
          disabled={isLoading}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          className="delete-goal"
          onClick={deleteGoal}
          disabled={isLoading}
          text="Yes, delete"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default ViewAllGoals;
