import React, { useCallback, useState } from "react";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";
import GVDSIconSlim from "../../gvds-components/Icons/GVDSIconSlim";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { Collapse } from "react-bootstrap";

const CertificationCollapsibleSection = ({
  title,
  content,
  isUsingInnerHtml = true,
}) => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(true);

  const contentDivRef = useCallback(
    (node) => {
      if (node !== null && isUsingInnerHtml) {
        node.innerHTML = content;
      }
    },
    [content]
  );

  return (
    <div className="collapsible-section__container">
      <div
        className="collapsible-section__title"
        onClick={() => setIsSectionExpanded(!isSectionExpanded)}
      >
        <div className="gvds-text--heading4">{title}</div>
        <GVDSTextButton
          text={
            <GVDSIconSlim
              className="color-black"
              Icon={isSectionExpanded ? IconChevronDown : IconChevronUp}
            />
          }
        />
      </div>
      <Collapse in={isSectionExpanded}>
        <div className="collapsible-section__content" ref={contentDivRef}>
          {!isUsingInnerHtml && content}
        </div>
      </Collapse>
    </div>
  );
};

export default CertificationCollapsibleSection;
