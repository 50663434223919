import { CellValue, RemoveActionCell } from "../../../Data/BulkDataInputCellViewer";
import _ from "lodash";

const columns = {
  0: {
    key: "index",
    label: "Row #",
    width: 2.5,
    classType: CellValue,
    errorKey: "index",
    readOnly: true
  },
  1: {
    key: "name",
    label: "Site name",
    width: 10,
    classType: CellValue,
    errorKey: "name"
  },
  2: {
    key: "action",
    label: "",
    width: 2,
    classType: RemoveActionCell,
    errorKey: "",
    readOnly: true
  }
};

const SITE_COLUMN_NAME = columns["1"].key;

class SiteValue {
  static new(row, removeRow) {
    const newSite = new SiteValue();

    Object.values(columns).forEach((c) => {
      newSite[c.key] = new c.classType();
    });

    newSite.action = RemoveActionCell.fromBulkSelectSiteRemoveRow(() => removeRow(row));
    newSite.index = new CellValue(row + 1, true, [], [], false);
    return newSite;
  }

  toGridRow() {
    return Object.values(columns).map((c) => this[c.key]);
  }

  static fromRowData(rowData) {
    const newSite = new SiteValue();
    Object.values(columns).forEach((c, col) => {
      newSite[c.key] = c.classType.fromCellData(rowData[col]);
    });
    return newSite;
  }

  static getNameByCol(col) {
    return columns[col]?.key;
  }

  removeAllErrors() {
    Object.values(columns).forEach((c) => {
      this[c.key].dataErrors = [];
    });
  }
}

export default class BulkSelectSiteService {

  getSiteColumnLabels() {
    return Object.values(columns).map((c) => ({ label: c.label, width: c.width }));
  }

  getNewRow(row, removeRow) {
    const newRow = SiteValue.new(Number(row), removeRow);
    return newRow.toGridRow();
  }

  updateRow(rowData, changes) {
    const siteRow = SiteValue.fromRowData(rowData);

    changes.forEach((c) => {
      const colName = SiteValue.getNameByCol(c.col);
      if (colName) {
        siteRow[colName].updateValue(c.value);
      }
    });
    return siteRow.toGridRow();
  }

  removeRow(rowNumber, setRowToDelete, grid) {
    let changedGrid = grid.map((row) => [...row]);
    changedGrid.splice(rowNumber, 1);
    changedGrid = changedGrid.map((g, row) => {
      const site = SiteValue.fromRowData(g);
      site.index.updateValue(row + 1);
      site.action = RemoveActionCell.fromBulkSelectSiteRemoveRow(() => setRowToDelete(row));
      return site.toGridRow();
    });
    return changedGrid;
  }

  getFilledRows(grid) {
    const result = grid.reduce((count, row) => {
      const site = SiteValue.fromRowData(row);
      if (!site[SITE_COLUMN_NAME].isEmpty()) {
        count += 1;
      }
      return count;
    }, 0);
    return result;
  }

  getSiteNames(grid) {
    return grid.filter((g) => {
      const site = SiteValue.fromRowData(g);
      return site[SITE_COLUMN_NAME].getValue() !== undefined;
    }).map((g) => {
      const site = SiteValue.fromRowData(g);
      return site[SITE_COLUMN_NAME].getValue();
    });
  }

  updateGridWithErrors = (grid, dataErrors) => {
    const changedGrid = grid.map((rowData) => {
      const site = SiteValue.fromRowData(rowData);
      site.removeAllErrors();
      return site.toGridRow();
    });

    for (const [row, err] of Object.entries(dataErrors)) {
      const reading = SiteValue.fromRowData(changedGrid[row]);
      Object.values(columns).forEach(({ key, errorKey }) => {
        reading[key].dataErrors = _.chain(err).get(errorKey, []).value();
      });
      changedGrid[row] = reading.toGridRow();
    }

    return changedGrid;
  };
}