import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import clone from "lodash/clone";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

import { getViewSurveyPath, SURVEYS } from "../../../config/ROUTES_NAME";
import { PERMISSIONS, SURVEY_STATUS } from "../../../config/constants";
import SurveyDetailForm from "../Components/SurveyDetailForm";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import SurveyQuestionForm from "../Components/SurveyQuestionForm";
import SurveyManagementService from "../../../services/SurveyManagementService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { SurveyModel } from "../Components/SurveyModel";
import ToastContext from "../../../context/ToastContext";
import { StringUtils, URLUtils } from "../../../services/UtilsService";
import SurveyResponses from "../Components/SurveyResponses";
import GVFormGroup from "../../common/GVFormGroup";
import PermissionsContext from "../../../context/PermissionsContext";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import BeforeUrlChangesPrompt from "../../common/BeforeUrlChangesPrompt";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import GVDSInfoCard from "../../../gvds-components/common/GVDSInfoCard";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCopy, IconTrash } from "@tabler/icons-react";
import UserProfileContext from "../../../context/UserProfileContext";
import GVDSFormSingleDatePicker from "../../../gvds-components/Forms/GVDSFormSingleDatePicker";

const checkIfDetailsTabHasErrors = (surveyDetailDTO, validationResult) =>
  surveyDetailDTO.deadline === null ||
  !StringUtils.isNotEmpty(surveyDetailDTO.invitational_email) ||
  !StringUtils.isNotEmpty(surveyDetailDTO.instructions) ||
  !StringUtils.isNotEmpty(surveyDetailDTO.description) ||
  !StringUtils.isNotEmpty(surveyDetailDTO.thank_you_message) ||
  validationResult.errors.some((error) =>
    error.toLowerCase().includes("deadline")
  );

const SurveyBuilder = () => {
  const history = useHistory();
  const location = useLocation();
  const { surveyId } = useParams();
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const permissionCtx = useContext(PermissionsContext);
  const userProfileContext = useContext(UserProfileContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("details");
  const [isNameValidated, setIsNameValidated] = useState(false);
  const [showSurveyValidationError, setShowSurveyValidationError] =
    useState(false);

  const [showLaunchModal, setShowLaunchModal] = useState(false);
  const closeLaunchModal = () => {
    if (!isValidatingLaunch && !isLaunching) {
      setShowLaunchModal(false);
    }
  };
  const [isValidatingLaunch, setIsValidatingLaunch] = useState(true);
  const [isSafeToLaunch, setIsSafeToLaunch] = useState(false);
  const [launchErrors, setLaunchErrors] = useState([]);
  const [isLaunching, setIsLaunching] = useState(false);

  const [latestBestPracticeQns, setLatestBestPracticeQns] = useState([]);
  const [differentBestPracticeQns, setDifferentBestPracticeQns] = useState([]);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState(null);
  const [
    isDuplicateTargetPortfolioValidated,
    setIsDuplicateTargetPortfolioValidated,
  ] = useState(false);

  const [showDeleteSurveyModal, setShowDeleteSurveyModal] = useState(false);
  const closeDeleteSurveyModal = () => {
    setShowDeleteSurveyModal(false);
  };

  const [newDeadline, setNewDeadline] = useState(new Date());
  const [showExtendDeadlineModal, setShowExtendDeadlineModal] = useState(false);
  const closeExtendDeadlineModal = () => {
    setShowExtendDeadlineModal(false);
  };

  const [hasChanges, setHasChanges] = useState(false);
  const [surveyModel, setSurveyModel] = useState(null);
  const onChange = () => {
    setSurveyModel(clone(surveyModel));
    setHasChanges(true);
  };

  const [showPromptUnsavedChangesModal, setShowPromptUnsavedChangesModal] =
    useState(false);
  const onCancelPromptUnsavedChangesModal = () => {
    setShowPromptUnsavedChangesModal(false);
  };

  const onQuestionMoved = async () => {
    try {
      await saveSurvey();
      toastContext.addSuccessToast(<span>Question moved successfully.</span>);
    } catch (error) {
      toastContext.addFailToast(
        <span>
          Failed to save changes. Please stay on the page and try saving
          manually.
        </span>
      );
    }
  };

  const [showDuplicateSurveyModal, setShowDuplicateSurveyModal] =
    useState(false);
  const [isDuplicateDialogLoading, setIsDuplicateDialogLoading] =
    useState(false);
  const closeDuplicateSurveyModal = () => {
    setShowDuplicateSurveyModal(false);
  };

  const isAllowedToManageSurvey =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.SURVEY_MANAGEMENT];

  const goToSurveyList = () => {
    history.push(SURVEYS);
  };

  const portfoliosUnderSelectedContract =
    userInventory.portfoliosUnderSelectedContract.get;

  const saveSurvey = () => {
    const selectedInventory = userInventory.selectedInventory.get;

    setIsSaving(true);
    if (surveyModel.id) {
      return SurveyManagementService.updateSurvey(
        selectedInventory.type,
        selectedInventory.id,
        surveyModel.id,
        surveyModel.toRequestPayload()
      )
        .then((surveyDetailDTO) => {
          setSurveyModel(SurveyModel.fromDTO(surveyDetailDTO));
          setHasChanges(false);
          return surveyDetailDTO;
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      return SurveyManagementService.createSurvey(
        selectedInventory.type,
        selectedInventory.id,
        surveyModel.toRequestPayload()
      )
        .then((surveyDetailDTO) => {
          setSurveyModel(SurveyModel.fromDTO(surveyDetailDTO));
          setHasChanges(false);
          URLUtils.replacePathWithoutRerendering(
            getViewSurveyPath(surveyDetailDTO.id),
            location
          );
          return surveyDetailDTO;
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const onSaveClicked = () => {
    setIsNameValidated(true);
    if (!surveyModel.isNameEmpty()) {
      saveSurvey()
        .then(() => {
          toastContext.addSuccessToast(<span>Survey successfully saved.</span>);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>
              Failed to save survey. Please stay on the page and try saving
              again.
            </span>
          );
        });
    } else {
      setActiveTabKey("details");
    }
  };

  const onSaveChangesBeforeDuplicating = async () => {
    setIsNameValidated(true);
    if (!surveyModel.isNameEmpty()) {
      saveSurvey()
        .then(() => {
          setShowPromptUnsavedChangesModal(false);
          promptDuplicateSurvey();
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>
              Failed to save survey. Please stay on the page and try duplicating
              again.
            </span>
          );
        });
    } else {
      setActiveTabKey("details");
    }
  };

  const validateLaunchSurvey = async () => {
    setIsNameValidated(true);
    if (!surveyModel.isNameEmpty()) {
      setIsValidatingLaunch(true);
      setShowLaunchModal(true);
      const surveyDetailDTO = await saveSurvey();
      const selectedInventory = userInventory.selectedInventory.get;

      const validationResult = await SurveyManagementService.validateSurvey(
        selectedInventory.type,
        selectedInventory.id,
        surveyDetailDTO.id
      );
      setShowSurveyValidationError(true);

      if (validationResult.errors.length === 0) {
        setIsSafeToLaunch(true);
      } else {
        setLaunchErrors(validationResult.errors);
        setIsSafeToLaunch(false);

        if (checkIfDetailsTabHasErrors(surveyDetailDTO, validationResult)) {
          if (activeTabKey !== "details") {
            setActiveTabKey("details");
          }
        } else if (
          validationResult.errors.some((error) =>
            error.toLowerCase().includes("question")
          )
        ) {
          if (activeTabKey !== "questions") {
            setActiveTabKey("questions");
          }
        }
      }

      setIsValidatingLaunch(false);
    }
  };

  const launchSurvey = async () => {
    const selectedInventory = userInventory.selectedInventory.get;

    if (surveyModel.id) {
      setIsLaunching(true);
      try {
        await SurveyManagementService.launchSurvey(
          selectedInventory.type,
          selectedInventory.id,
          surveyModel.id
        );
        setIsLaunching(false);
        goToSurveyList();
      } catch (e) {
        toastContext.addFailToast(<span>Failed to launch survey.</span>);
        setIsLaunching(false);
      }
    } else {
      toastContext.addFailToast(<span>Survey has not been saved.</span>);
    }
  };

  const getLaunchModalContent = () => {
    if (isValidatingLaunch || isLaunching) {
      return <LoadingSpinner />;
    } else if (isSafeToLaunch) {
      return (
        <div>
          <div>
            Changes cannot be made once a survey is launched to ensure data
            consistency.
          </div>
          <br />
          <div>
            Please double-check your survey one last time before launch.
          </div>
        </div>
      );
    } else if (!isSafeToLaunch) {
      return (
        <div>
          Some errors were found. Please resolve them before you launch the
          survey.
          <div className="color-red mt-2">
            <ul>
              {launchErrors.map((err) => (
                <li key={err}>{err}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
  };

  const markSurveyAsClosed = () => {
    const selectedInventory = userInventory.selectedInventory.get;

    SurveyManagementService.markSurveyAsClosed(
      selectedInventory.type,
      selectedInventory.id,
      surveyModel.id
    )
      .then(() => {
        toastContext.addSuccessToast(
          <span>Marked {surveyModel.name} as closed</span>
        );
        surveyModel.status = SURVEY_STATUS.CLOSED;
        setSurveyModel(clone(surveyModel));
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to mark survey as closed</span>);
      });
  };

  const sendTestEmail = async () => {
    setIsNameValidated(true);
    if (!surveyModel.isNameEmpty()) {
      const surveyDetailDTO = await saveSurvey();
      const surveyId = surveyDetailDTO.id; // retrieved from DTO as this could be new survey

      const selectedInventory = userInventory.selectedInventory.get;
      const user = userProfileContext.getUserProfile();

      SurveyManagementService.sendTestEmail(
        selectedInventory.type,
        selectedInventory.id,
        surveyId
      )
        .then(() => {
          toastContext.addSuccessToast(
            <span>Test email has been sent to your email {user.email}</span>
          );
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to send test email.</span>);
        });
    }
  };

  const promptExtendDeadline = () => {
    setNewDeadline(surveyModel.deadline);
    setShowExtendDeadlineModal(true);
  };

  const extendDeadline = () => {
    const selectedInventory = userInventory.selectedInventory.get;

    SurveyManagementService.extendDeadline(
      selectedInventory.type,
      selectedInventory.id,
      surveyId,
      newDeadline
    )
      .then((surveyDetailDTO) => {
        toastContext.addSuccessToast(
          <span>Deadline for {surveyModel.name} has been extended</span>
        );
        setSurveyModel(SurveyModel.fromDTO(surveyDetailDTO));
        closeExtendDeadlineModal();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to extend survey deadline</span>
        );
      });
  };

  const promptDeleteSurvey = () => {
    setShowDeleteSurveyModal(true);
  };

  const deleteSurvey = () => {
    const selectedInventory = userInventory.selectedInventory.get;

    setHasChanges(false);
    SurveyManagementService.deleteSurvey(
      selectedInventory.type,
      selectedInventory.id,
      surveyModel.id
    )
      .then(() => {
        toastContext.addSuccessToast(
          <span>Deleted survey {surveyModel.name}</span>
        );
        setShowDeleteSurveyModal(false);
        goToSurveyList();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to delete survey</span>);
        setHasChanges(true);
      });
  };

  const resetDuplicateSurveyState = () => {
    setDifferentBestPracticeQns([]);
    setSelectedPortfolioId(null);
    setIsDuplicateTargetPortfolioValidated(false);
  };

  const validateBeforePromptDuplicateSurvey = (e) => {
    e.preventDefault();
    if (hasChanges) {
      setShowPromptUnsavedChangesModal(true);
    } else {
      promptDuplicateSurvey();
    }
  };

  const promptDuplicateSurvey = () => {
    setIsDuplicateDialogLoading(true);
    resetDuplicateSurveyState();
    setShowDuplicateSurveyModal(true);
    const selectedInventory = userInventory.selectedInventory.get;

    SurveyManagementService.getBestPracticeQuestions(
      selectedInventory.type,
      selectedInventory.id
    )
      .then((bestPracticeQns) => {
        setLatestBestPracticeQns(bestPracticeQns);
        setDifferentBestPracticeQns(
          surveyModel.getUpdatedBestPracticeQns(bestPracticeQns)
        );
      })
      .finally(() => setIsDuplicateDialogLoading(false));
  };

  const duplicateSurvey = async () => {
    const portfolioTarget = portfoliosUnderSelectedContract.find(
      (portfolio) => portfolio.id === selectedPortfolioId
    );
    setIsDuplicateTargetPortfolioValidated(true);
    if (portfolioTarget) {
      setIsSaving(true);
      setIsDuplicateDialogLoading(true);
      const selectedInventory = userInventory.selectedInventory.get;

      SurveyManagementService.getAllSurvey(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((surveyList) => {
          const existingSurveyNames = surveyList.map((survey) => survey.name);
          const copySurveyModel = surveyModel.getDuplicateModel(
            latestBestPracticeQns,
            existingSurveyNames
          );
          SurveyManagementService.createSurvey(
            portfolioTarget.resource_type,
            portfolioTarget.id,
            copySurveyModel.toRequestPayload()
          )
            .then((surveyDetailDTO) => {
              toastContext.addSuccessToast(
                <span>Survey copied successfully.</span>
              );
              history.push(
                getRedirectURLWithCurrentParam(
                  getViewSurveyPath(surveyDetailDTO.id),
                  location
                )
              );
            })
            .catch(() => {
              toastContext.addFailToast(<span>Failed to copy survey</span>);
            })
            .finally(() => {
              setIsSaving(false);
              setShowDuplicateSurveyModal(false);
              setIsDuplicateDialogLoading(false);
            });
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to copy survey</span>);
        })
        .finally(() => {
          setIsSaving(false);
          setShowDuplicateSurveyModal(false);
          setIsDuplicateDialogLoading(false);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (surveyId) {
      const selectedInventory = userInventory.selectedInventory.get;

      SurveyManagementService.getSurvey(
        selectedInventory.type,
        selectedInventory.id,
        surveyId
      )
        .then((surveyDetailDTO) => {
          setSurveyModel(SurveyModel.fromDTO(surveyDetailDTO));
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            goToSurveyList();
          } else {
            setIsLoading(false);
            toastContext.addFailToast(<span>Failed to load survey</span>);
          }
        });
    } else {
      const surveyModel = new SurveyModel();
      surveyModel.createdFor =
        userInventory.selectedTreeNode.get.nodeValue.name;
      setSurveyModel(surveyModel);
      setIsLoading(false);
    }
  }, [surveyId]);

  const launchModalContent = getLaunchModalContent();

  if (isLoading || permissionCtx.isLoadingPermissions) {
    return (
      <div className="page-loading-container">
        <LoadingSpinner />
      </div>
    );
  }

  const isFormDisabled = surveyModel.isNotDraft();
  const formDisabledAlert =
    isFormDisabled && isAllowedToManageSurvey ? (
      <>
        <GVDSInfoCard title="Survey cannot be edited after launch to ensure data consistency.">
          <GVDSInfoCard.Footer>
            <GVDSButton
              onClick={promptExtendDeadline}
              variant={buttonVariant.primary}
              text="Extend survey deadline"
            />
          </GVDSInfoCard.Footer>
        </GVDSInfoCard>
        <GVDSModal
          title="Extend Survey Deadline"
          size={GVDSModal.Size.small}
          show={showExtendDeadlineModal}
          onHide={closeExtendDeadlineModal}
        >
          <GVDSModal.Body>
            <div className="mb-3">
              Extending the deadline of closed survey will reopen the survey.
              This will enable all sites to edit their existing survey
              responses.
            </div>
            <GVFormGroup>
              <Form.Label>Deadline</Form.Label>
              <GVDSFormSingleDatePicker
                selected={newDeadline}
                className="survey-deadline"
                onChange={(date) => {
                  setNewDeadline(date);
                }}
                dateFormat="dd MMM yyyy"
                minDate={new Date()}
              />
            </GVFormGroup>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={closeExtendDeadlineModal}
              text="Cancel"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              className="extend-survey-deadline"
              onClick={extendDeadline}
              text="Save"
            />
          </GVDSModal.Footer>
        </GVDSModal>
      </>
    ) : null;

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text={
            <>
              Return to Surveys List{" "}
              {!isFormDisabled && isAllowedToManageSurvey && (
                <span className="caption color-gray56 ms-1">
                  (Unless saved, changes will be lost)
                </span>
              )}
            </>
          }
          onClick={goToSurveyList}
          className="go-to-survey-list"
        />
        <PageHeader.Title>
          <h1>
            <div className="d-flex align-items-baseline survey-name-field">
              {surveyModel.isOriginalNameEmpty()
                ? "New Survey"
                : surveyModel.originalName}
              <div className="ms-2 caption">for {surveyModel.createdFor}</div>
            </div>
          </h1>
          <Spacer />
          {surveyModel.id && isAllowedToManageSurvey && (
            <Dropdown>
              <Dropdown.Toggle as={OtherActionDropdownToggle} />

              <Dropdown.Menu>
                <Dropdown.Item
                  key="copy"
                  href="#"
                  className="duplicate-survey"
                  onClick={validateBeforePromptDuplicateSurvey}
                >
                  <GVDSIcon Icon={IconCopy} /> Copy
                </Dropdown.Item>
                <Dropdown.Item
                  key="delete"
                  href="#"
                  className="danger delete-survey"
                  onClick={promptDeleteSurvey}
                >
                  <GVDSIcon Icon={IconTrash} /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {!surveyModel.isNotDraft() && isAllowedToManageSurvey && (
            <GVDSButton
              variant={buttonVariant.primary}
              className="ms-2 save-validate-launch-survey"
              onClick={validateLaunchSurvey}
              disabled={isSaving}
              text={!isSaving ? "Save & Launch Survey" : "Saving..."}
            />
          )}
        </PageHeader.Title>
        <PageHeader.Status>
          <div className="info-field">
            <StatusLabel
              color={
                surveyModel.status === SURVEY_STATUS.DRAFT
                  ? StatusLabel.Colors.gray
                  : surveyModel.status === SURVEY_STATUS.OPEN
                  ? StatusLabel.Colors.green
                  : StatusLabel.Colors.red
              }
            >
              {StringUtils.capitaliseWord(surveyModel.status)}
            </StatusLabel>
            {surveyModel.isOpen() && isAllowedToManageSurvey && (
              <GVDSButton
                variant={buttonVariant.secondary}
                className="ms-2 close-survey"
                onClick={markSurveyAsClosed}
                text="Mark as Closed"
              />
            )}
          </div>
        </PageHeader.Status>
      </PageHeader>
      <Tabs
        defaultActiveKey="details"
        activeKey={activeTabKey}
        onSelect={(key) => setActiveTabKey(key)}
      >
        <Tab eventKey="details" title="Details">
          {formDisabledAlert}
          <SurveyDetailForm
            surveyModel={surveyModel}
            onChange={onChange}
            isNameValidated={isNameValidated}
            isSaving={isSaving}
            showSurveyValidationError={showSurveyValidationError}
            sendTestEmail={sendTestEmail}
            isAllowedToManageSurvey={isAllowedToManageSurvey}
          />
        </Tab>
        <Tab eventKey="questions" title="Questions">
          {formDisabledAlert}
          <SurveyQuestionForm
            surveyModel={surveyModel}
            onChange={onChange}
            onQuestionMoved={onQuestionMoved}
            showSurveyValidationError={showSurveyValidationError}
            isAllowedToManageSurvey={isAllowedToManageSurvey}
          />
        </Tab>
        {surveyModel.isNotDraft() && (
          <Tab eventKey="responses" title="Responses">
            <SurveyResponses
              surveyModel={surveyModel}
              isAllowedToManageSurvey={isAllowedToManageSurvey}
            />
          </Tab>
        )}
      </Tabs>
      {!isFormDisabled && isAllowedToManageSurvey && (
        <div className="d-flex floating-action-container">
          <GVDSButton
            variant={buttonVariant.primary}
            className="ms-auto save-survey"
            onClick={onSaveClicked}
            disabled={isSaving}
            text={!isSaving ? "Save" : "Saving..."}
          />
        </div>
      )}

      <GVDSModal
        title="Delete Survey"
        size={GVDSModal.Size.small}
        show={showDeleteSurveyModal}
        onHide={closeDeleteSurveyModal}
      >
        <GVDSModal.Body>
          <div>
            {surveyModel.isNotDraft()
              ? "All questions and responses will be deleted along with the survey."
              : "All questions will be deleted along with the survey."}
          </div>
          <br />
          <div>This action cannot be undone. Are you sure?</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeDeleteSurveyModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            className="confirm-delete-survey"
            onClick={deleteSurvey}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <GVDSModal
        title="Launch Survey"
        size={GVDSModal.Size.small}
        show={showLaunchModal}
        onHide={closeLaunchModal}
      >
        <GVDSModal.Body>{launchModalContent}</GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            disabled={isValidatingLaunch || isLaunching}
            onClick={closeLaunchModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            className="launch-survey"
            onClick={launchSurvey}
            disabled={!isSafeToLaunch || isLaunching}
            text="Launch"
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <GVDSModal
        title="Copy Survey"
        size={GVDSModal.Size.small}
        show={showDuplicateSurveyModal}
        onHide={closeDuplicateSurveyModal}
      >
        <GVDSModal.Body>
          {isDuplicateDialogLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="mb-3">
                The copied survey will have all custom questions and best
                practice questions, as well as the original survey’s details.
                Please update the Survey Name and Deadline in the copied survey.
              </div>
              <GVFormGroup>
                <Form.Label>
                  Select the Portfolio/Sub-Portfolio to copy this survey for:
                </Form.Label>
                <GVDSFormSingleSelect
                  placeholder="Select a sub-portfolio"
                  className="select__portfolio"
                  isSearchable={true}
                  value={
                    selectedPortfolioId
                      ? getOptionByValueFromIdName(
                          selectedPortfolioId,
                          portfoliosUnderSelectedContract
                        )
                      : null
                  }
                  options={portfoliosUnderSelectedContract.map((portfolio) => {
                    return {
                      value: portfolio.id,
                      label: `${portfolio.name} (${portfolio.sites.length})`,
                    };
                  })}
                  onSelect={(selected) => {
                    setSelectedPortfolioId(selected.value);
                  }}
                  statusMetadata={
                    isDuplicateTargetPortfolioValidated && !selectedPortfolioId
                      ? FormFieldStatusMetadata.getError(
                          "Please select a sub-portfolio for copied survey."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                  menuShouldScrollIntoView={false}
                />
              </GVFormGroup>
              {differentBestPracticeQns.length > 0 && (
                <>
                  <div className="survey-duplicate-dialog__label">
                    Updated Best Practice Questions
                  </div>
                  <div className="gv-text-16">
                    Greenview has updated or removed the following Best Practice
                    Questions since the original survey's launch to reflect the
                    latest sustainability trends:
                    <ul>
                      <li>
                        <strong>Updated</strong> best practice questions are
                        copied over as the updated version. If you wish to use
                        the original version, recreate it as a custom question
                        in the copied survey.
                      </li>
                      <li>
                        <strong>Removed</strong> best practice questions are
                        copied over as a custom question.
                      </li>
                    </ul>
                  </div>
                  <div className="survey-bpq-updated-question__container">
                    {differentBestPracticeQns.map((q, index) => (
                      <div
                        key={index}
                        className="survey-bpq-updated-question__row"
                      >
                        <div className="question-number">{q.questionNum}.</div>
                        <div>
                          <StatusLabel color={StatusLabel.Colors.green}>
                            {q.questionStatus}
                          </StatusLabel>
                          <div className="question-text">{q.questionText}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {!isDuplicateDialogLoading && (
            <>
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={closeDuplicateSurveyModal}
                text="Cancel"
              />
              <GVDSButton
                variant={buttonVariant.primary}
                className="confirm-duplicate-survey"
                onClick={duplicateSurvey}
                text="Copy Survey"
              />
            </>
          )}
        </GVDSModal.Footer>
      </GVDSModal>

      <GVDSModal
        title="Save changes before duplicating"
        size={GVDSModal.Size.small}
        show={showPromptUnsavedChangesModal}
        onHide={onCancelPromptUnsavedChangesModal}
      >
        <GVDSModal.Body>
          <div>
            There are unsaved changes in your current survey. Please save the
            survey before duplicating!
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            className="cancel-leave"
            variant={buttonVariant.tertiary}
            onClick={onCancelPromptUnsavedChangesModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={onSaveChangesBeforeDuplicating}
            text="Yes, save the changes."
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <BeforeUrlChangesPrompt
        when={hasChanges}
        pageName="Survey Input Page"
        message="You have unsaved changes in the form. Your data will be cleared and won’t be stored when you leave this page. Are you sure?"
      />
    </div>
  );
};

export default SurveyBuilder;
