import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const OperationalMetricFilterView = ({
  options,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <OverlayTrigger
      placement="right"
      overlay={(props) => (
        <Popover {...props}>
          <Popover.Body>
            Choose how you'd like to view your usage data: as a total value, or
            as intensity adjusted by floor area or operational metrics (if
            applicable).
          </Popover.Body>
        </Popover>
      )}
    >
      <div>
        <GVDSFormSingleSelect
          placeholder="Select intensity"
          className="dashboard-filter__single-select__operational-metric"
          value={selectedOption}
          onSelect={(selectedOption) => setSelectedOption(selectedOption)}
          options={options}
          isDropdownFollowFilterWidth={false}
        />
      </div>
    </OverlayTrigger>
  );
};

export default OperationalMetricFilterView;
