import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import UserProfileContext from "../../../context/UserProfileContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import getGuidedTourStepsByPathAndResourceType from "./GuidedTourSteps";
import getFeaturesRequiredForPath from "../../common/Feature/FeaturesRequiredByPath";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";

export const isGuidedTourAvailable = (pathname, selectedInventory) => {
  return (
    getGuidedTourStepsByPathAndResourceType(pathname, selectedInventory?.type)
      .length > 0
  );
};

const GuidedTourMenu = ({ startGuidedTour }) => {
  let location = useLocation();
  const pathname = location.pathname;

  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const isAvailable = isGuidedTourAvailable(pathname, selectedInventory);
  const featureIsEnabled =
    getSubscriptionFeatureStatus(
      getFeaturesRequiredForPath(pathname),
      userInventory.isLoadingInventory.get,
      userInventory.selectedTreeNode,
      userProfile
    ) === SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  if (!isAvailable) {
    return null;
  }

  let guidedTourDesc;

  if (!featureIsEnabled) {
    guidedTourDesc = "Guided tours are not available for locked features.";
  } else {
    guidedTourDesc =
      "Want to understand how this works? Let us show you around.";
  }

  return (
    <>
      <div className="guided-tour-menu">
        <div className="guided-tour-menu-text">{guidedTourDesc}</div>
        {isAvailable && featureIsEnabled && (
          <GVDSTextButton
            className="start-guided-tour-btn"
            text={"Start Guided Tour"}
            onClick={startGuidedTour}
          />
        )}
      </div>
    </>
  );
};

export default GuidedTourMenu;
