import axios from "axios";
import { API_URL } from "../config/api-config";
import { FileAttachmentItemModel } from "../components/common/FileAttachments";
import { tobe_deleted_entry_prefix } from "../config/constants";

export class HelpArticleAdminListItemModel {
  constructor(articleListItem) {
    this.id = articleListItem.id;
    this.title = articleListItem.title;
    this.articleType = articleListItem.article_type;
    this.category = articleListItem.category;
    this.description = articleListItem.description;
    this.file = articleListItem.file
      ? FileAttachmentItemModel.fromFileDTO(articleListItem.file)
      : null;
    this.lastEditedOn = articleListItem.last_edited_on;
    this.lastEditedBy = articleListItem.last_edited_by;
    this.relevantArticles = articleListItem.relevant_articles;
    this.relevantPortalModules = articleListItem.relevant_portal_modules;
  }

  get article_type_name() {
    return this.articleType.name;
  }

  get file_name() {
    return this.file.name;
  }

  get category_name() {
    return this.category.name;
  }
}

export default class HelpArticleAdminService {
  static getHelpArticleOptions = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/help_article_options`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static createHelpArticle = async (
    title,
    article_type,
    category,
    description,
    relevant_article_ids,
    relevant_portal_module_ids,
    fileAttachmentItemModel
  ) => {
    try {
      const postBody = new FormData();
      const data = {
        title,
        article_type,
        category,
        description,
        relevant_article_ids,
        relevant_portal_module_ids,
      };

      if (fileAttachmentItemModel.isNew()) {
        postBody.append("file", fileAttachmentItemModel.file);
      }

      postBody.append("data", JSON.stringify(data));
      const response = await axios.post(
        `${API_URL}/api/v1/admin/help_articles`,
        postBody
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editHelpArticle = async (
    articleId,
    title,
    article_type,
    category,
    description,
    relevant_article_ids,
    relevant_portal_module_ids,
    newFileAttachmentItemModel = null,
    removedFileAttachmentItemModel = null
  ) => {
    try {
      const postBody = new FormData();
      const data = {
        title,
        article_type,
        category,
        description,
        relevant_article_ids,
        relevant_portal_module_ids,
        remove_files: [],
      };

      if (newFileAttachmentItemModel) {
        postBody.append("file", newFileAttachmentItemModel.file);
      }

      if (removedFileAttachmentItemModel) {
        data.remove_files.push(
          removedFileAttachmentItemModel.id.slice(
            tobe_deleted_entry_prefix.length
          )
        );
      }

      postBody.append("data", JSON.stringify(data));
      const response = await axios.put(
        `${API_URL}/api/v1/admin/help_articles/${articleId}`,
        postBody
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getAllHelpArticles = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/admin/help_articles`);
      return response.data.map((a) => new HelpArticleAdminListItemModel(a));
    } catch (error) {
      throw error;
    }
  };

  static deleteArticle = async (articleId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/admin/help_articles/${articleId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
