import axios from "axios";
import { API_URL } from "../../config/api-config";
import { HttpResponseService } from "../UtilsService";

class BestPracticesReportQuestion {
  constructor(questionId, questionText, categories, tags) {
    this.id = questionId;
    this.questionText = questionText;
    this.categories = categories;
    this.tags = tags;
  }

  get categoryNames() {
    return this.categories.map((category) => category.name);
  }

  get tagNames() {
    return this.tags.map((tag) => tag.name);
  }

  hasTag(tagNames) {
    return !!this.tags.find((t) => tagNames.indexOf(t.name) >= 0);
  }

  includeText(searchText) {
    return this.questionText.toLowerCase().includes(searchText.toLowerCase());
  }

  static fromJson(questionJson) {
    return new BestPracticesReportQuestion(
      questionJson["question_id"],
      questionJson["question_text"],
      questionJson["categories"],
      questionJson["tags"]
    );
  }
}

class BestPracticesReportConfig {
  constructor(questions) {
    this.questions = questions;
  }

  static fromJson(configJson) {
    return new BestPracticesReportConfig(
      configJson["questions"].map((q) =>
        BestPracticesReportQuestion.fromJson(q)
      )
    );
  }
}

export default class BestPracticesReportService {
  static getReportConfig = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/best_practices/config",
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );
      return BestPracticesReportConfig.fromJson(response.data);
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static downloadReport = async (resource_type, resource_id, question_ids) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/reports/best_practices/download`,
        { question_ids },
        {
          params: {
            resource_type,
            resource_id,
          },
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Best Practices Report.xlsx"
      );
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };
}
