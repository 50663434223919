import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../../HOC/withAuthentication";
import LoadingSpinner from "../../common/LoadingSpinner";
import { Container } from "react-bootstrap";
import { REFERENCE_DATA } from "../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import PerformanceGroupService from "../../../services/ReferenceDataServices/PerformanceGroupService";
import uniq from "lodash/uniq";
import ToastContext from "../../../context/ToastContext";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTable, {
  SORTING_TYPES,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";

const PerformanceGrouping = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [performanceGroups, setPerformanceGroups] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    performanceGroups,
    SystemToolboxSearchKeysByPageName.referenceData.performanceGrouping,
    {
      name: SORTING_TYPES.asc,
    }
  );

  useEffect(() => {
    setIsLoading(true);
    PerformanceGroupService.getAllPerformanceGroup()
      .then((response) => {
        setPerformanceGroups(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load performance groups.</span>
        );
      });
  }, []);

  const columns = [
    {
      header: "Performance Group Name",
      dataKey: "name",
      sortable: true,
    },
    {
      header: "Subtopic",
      dataKey: "subtopic_name",
      sortable: true,
    },
    {
      header: "Environmental Types Involved",
      dataKey: "definition",
      sortable: true,
      renderer: (q) =>
        q.environmental_types.map((env_type) => env_type.name).sort().join(", "),
    },
  ];

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return To Reference Data"
          onClick={goToReferenceData}
        />
        <PageHeader.Title>
          <h1>Performance Grouping</h1>
        </PageHeader.Title>
      </PageHeader>

      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search by Performance Grouping Name"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(performanceGroups.map((q) => q.subtopics).flat())}
          prefix="Subtopic"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, subtopics: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(
            performanceGroups.map((q) => q.get_environmental_types).flat()
          )}
          prefix="Environmental Types Involved"
          onChange={(filterKs) =>
            setFilterKeys({
              ...filterKeys,
              get_environmental_types: filterKs,
            })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {filteredSortedData.length === 0 && (
        <div className="table__no_content">No record found</div>
      )}
    </Container>
  );
};

export default withAuthentication(PerformanceGrouping);
