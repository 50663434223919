import React, { useContext, useEffect, useState } from "react";
import UserInventoryContext from "../../context/UserInventoryContext";
import { RESOURCES } from "../../config/constants";
import FileStorageService from "../../services/FileStorageService";
import ToastContext from "../../context/ToastContext";
import FileStorageEdit from "./FileStorageEdit";
import FileStorageAllFiles from "./FileStorageAllFiles";

const FILE_STORAGE_MAIN_STATE = {
  ALL_FILES: "ALL_FILES",
  EDIT: "EDIT"
};

export const FILE_STORAGE_MAIN_SELECT_MODE = {
  NONE: false,
  EDIT: "EDIT",
  DOWNLOAD: "DOWNLOAD",
  DELETE: "DELETE"
};

const FileStorageMain = () => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [fileStorageMainState, setFileStorageMainState] = useState(
    FILE_STORAGE_MAIN_STATE.ALL_FILES
  );

  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectMode, setSelectMode] = useState(
    FILE_STORAGE_MAIN_SELECT_MODE.NONE
  );

  const loadFileStorageObjects = () => {
    if (selectedInventory && selectedInventory.id) {
      if (selectedInventory.type === RESOURCES.SITE || selectedInventory.type === RESOURCES.PORTFOLIO) {
        setIsLoading(true);
        FileStorageService.getAllFileStorageObjects(
          selectedInventory.type,
          selectedInventory.id
        )
          .then((data) => {
            setFiles(data);
            setIsLoading(false);
          })
          .catch(() => {
            toastContext.addFailToast(
              <span>Failed to retrieve files from file storage</span>
            );
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    }
  };

  const goToFileStorageMain = () => {
    setFileStorageMainState(FILE_STORAGE_MAIN_STATE.ALL_FILES);
    onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.NONE);
  };

  const goToEdit = () => {
    setFileStorageMainState(FILE_STORAGE_MAIN_STATE.EDIT);
  };

  const onModeChange = (mode, preselectedFiles = []) => {
    setSelectedFiles(preselectedFiles);
    setSelectMode(mode);
  };

  const onEditSuccess = () => {
    onModeChange(FILE_STORAGE_MAIN_SELECT_MODE.NONE);
    loadFileStorageObjects();
  };

  useEffect(() => {
    loadFileStorageObjects();
  }, [selectedInventory]);

  if (fileStorageMainState === FILE_STORAGE_MAIN_STATE.ALL_FILES) {
    return (
      <FileStorageAllFiles
        allFileStorageObjects={files}
        isLoading={isLoading}
        loadFileStorageObjects={loadFileStorageObjects}
        selectMode={selectMode}
        onModeChange={onModeChange}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        onEdit={goToEdit}
      />
    );
  } else if (fileStorageMainState === FILE_STORAGE_MAIN_STATE.EDIT) {
    return (
      <FileStorageEdit
        filesToBeEdited={selectedFiles}
        goToFileStorageMain={goToFileStorageMain}
        onEditSuccess={onEditSuccess}
      />
    );
  }
};

export default FileStorageMain;
