import React, { useContext, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import UserInventoryContext, {
  SPECIAL_CONTRACTS,
} from "../../../context/UserInventoryContext";
import GVDSCustomIcons from "../../Icons/GVDSIconCustom";
import GVDSIcon from "../../Icons/GVDSIcon";
import { IconCheck, IconSearch } from "@tabler/icons-react";
import Dropdown from "react-bootstrap/Dropdown";
import { createPortal } from "react-dom";
import { GVDSColors } from "../../../styles/gvds-colors";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    margin: 8,
    borderColor: state.isFocused ? GVDSColors.tealSolid : GVDSColors.gray4,
    boxShadow: state.isFocused ? "0 0 3px 0 " + GVDSColors.tealSolid : "none",
    "&:hover": {
      boxShadow: state.isFocused ? "0 0 3px 0 " + GVDSColors.tealSolid : "none",
    },
  }),
  menu: () => ({}),
};

const ContractSelectorToggle = React.forwardRef(
  ({ children, onClick }, ref) => {
    return (
      <div ref={ref} className="contract-selector--toggle" onClick={onClick}>
        {children}
      </div>
    );
  }
);

const ContractOption = (props) => {
  return (
    <div>
      <components.Option
        {...props}
        className={props.isSelected ? " selected" : ""}
      >
        {props.label}{" "}
        {props.isSelected && (
          <>
            <GVDSIcon Icon={IconCheck} />
          </>
        )}
      </components.Option>
    </div>
  );
};

const ContractSelector = ({ parentModalRef }) => {
  const userInventory = useContext(UserInventoryContext);
  const contracts = userInventory.contracts.get;

  const someRef = useRef(null);
  const [element, setElement] = useState(null);
  useEffect(() => {
    // Force a rerender, so it can be passed to the child. Ref: https://stackoverflow.com/a/69701646
    setElement(parentModalRef.current.dialog);
  }, []);

  if (!contracts || contracts.length === 0) {
    return null;
  }

  const selectedContractId = userInventory.selectedContractId.get;
  const currentContract = contracts.find(
    (contract) => contract.id === selectedContractId
  );

  const isSelectable = contracts.length > 1;

  const onContractChange = (contractId) => {
    userInventory.selectedContractId.set(contractId);
  };

  if (isSelectable) {
    return (
      <Dropdown ref={someRef}>
        <Dropdown.Toggle
          as={ContractSelectorToggle}
          id="contract-selector-toggle"
        >
          Contract: {currentContract.name} <GVDSCustomIcons.Expand />
        </Dropdown.Toggle>

        {element &&
          createPortal(
            <Dropdown.Menu
              className="contract-selector--dropdown-menu"
              flip={false}
            >
              <Select
                menuIsOpen
                menuShouldScrollIntoView={false}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isClearable={false}
                tabSelectsValue={false}
                controlShouldRenderValue={false}
                components={{
                  Option: ContractOption,
                  DropdownIndicator: () => <GVDSIcon Icon={IconSearch} />,
                  IndicatorSeparator: null,
                }}
                placeholder="Search contract"
                value={{
                  label: currentContract.name,
                  value: currentContract.id,
                }}
                options={contracts
                  .sort((contract1, contract2) => {
                    const contract1name = contract1.name;
                    const contract2name = contract2.name;

                    if (SPECIAL_CONTRACTS.includes(contract1name)) {
                      return 1;
                    } else if (SPECIAL_CONTRACTS.includes(contract2name)) {
                      return -1;
                    } else {
                      return contract1.name.localeCompare(contract2.name);
                    }
                  })
                  .map((c) => ({ label: c.name, value: c.id }))}
                onChange={(o) => onContractChange(o.value)}
                classNamePrefix="contract-selector__select"
                styles={selectStyles}
                maxMenuHeight="14em"
              />
            </Dropdown.Menu>,
            element
          )}
      </Dropdown>
    );
  } else {
    return null;
  }
};

export default ContractSelector;
