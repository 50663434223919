import LoadingSpinner from "../../../common/LoadingSpinner";
import React, { useContext, useRef, useState } from "react";
import { ChangeOverTimeSiteRankingChartViewHeader } from "./ChangeOverTimeSiteRankingChartViewHeaders";
import {
  DashboardWidgetSharedUtils,
  siteRankingSortOptions,
} from "../DashboardWidgetSharedUtils";
import {
  changeOverTimeSiteRankingConfig,
  changeOverTimeSiteRankingViewTypeOptions,
} from "./ChangeOverTimeSiteRankingChartViewConfig";
import ChangeOverTimeSiteRankingChartViewContent from "./ChangeOverTimeSiteRankingChartViewContent";
import {
  downloadChartAsImageTimeoutInMs,
  expandedModalViewOptionEnum,
} from "../../Models/DashboardModels";
import { ChangeOverTimeSiteRankingChartUtils } from "./ChangeOverTimeSiteRankingChartUtils";
import ExpandedChangeOverTimeSiteRankingChartView from "./ExpandedChangeOverTimeSiteRankingChartView";
import { ParentSize } from "@visx/responsive";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import { DashboardTableExcelDownloader } from "../../DashboardTableExcelDownloader";
import { WidgetTypeEnum } from "../../Models/WidgetModels";

const ChangeOverTimeSiteRankingChartView = ({
  isLoading,
  title,
  period,
  hasDataGaps,
  subtopic,
  intensity,
  chartData,
  unit,
  intensityUnit,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [sortOrder, setSortOrder] = useState(siteRankingSortOptions[0]);
  const [selectedViewType, setSelectedViewType] = useState(
    changeOverTimeSiteRankingViewTypeOptions[0]
  );
  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const isDownloadingFromTableView = useRef(false);

  const [isShowExpandedView, setIsShowExpandedView] = useState(false);
  const [selectedExpandedViewOption, setSelectedExpandedViewOption] =
    useState(null);

  const chartHeight = ChangeOverTimeSiteRankingChartUtils.getChartHeight(
    changeOverTimeSiteRankingConfig.maxBarCount
  );

  const onSortOrderChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const onViewTypeChange = (newViewType) => {
    setSelectedViewType(newViewType);
  };

  const showExpandedChartView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
    setIsShowExpandedView(true);
  };

  const showExpandedTableView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
    setIsShowExpandedView(true);
  };

  let content;

  if (isLoading) {
    content = (
      <div className="dashboard--change-over-time-site-ranking__mini-view-box">
        <LoadingSpinner />
      </div>
    );
  } else {
    const hasData = ChangeOverTimeSiteRankingChartUtils.hasData(
      chartData,
      selectedViewType.value
    );

    const sortedChartData =
      ChangeOverTimeSiteRankingChartUtils.sortBarChartValues(
        chartData,
        sortOrder.value,
        selectedViewType.value
      );

    const truncatedSortedChartData =
      sortedChartData.length > changeOverTimeSiteRankingConfig.maxBarCount
        ? sortedChartData.slice(0, changeOverTimeSiteRankingConfig.maxBarCount)
        : sortedChartData;

    const fileName = DashboardWidgetSharedUtils.getDownloadFileName(
      userInventory.selectedTreeNode.get?.nodeValue?.name || "",
      WidgetTypeEnum.ChangeOverTimeSiteRankingChart,
      subtopic,
      period
    );

    const downloadChartAsImage = async (
      chartViewId,
      isFromExpandedView,
      excludeOnDownloadIds
    ) => {
      if (selectedExpandedViewOption === expandedModalViewOptionEnum.TABLE) {
        isDownloadingFromTableView.current = true;
        setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
      }
      setIsDownloadingChart(true);

      setTimeout(async () => {
        try {
          await DashboardWidgetSharedUtils.downloadChartAsImage(
            chartViewId,
            fileName,
            excludeOnDownloadIds,
            isFromExpandedView
          );
        } catch (e) {
          toastContext.addFailToast(
            <span>Failed to download chart as image.</span>
          );
        } finally {
          setIsDownloadingChart(false);
          if (isDownloadingFromTableView.current) {
            setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
            isDownloadingFromTableView.current = false;
          }
        }
      }, downloadChartAsImageTimeoutInMs);
    };

    const onDownloadTableAsExcel = () => {
      const excelSheetName = `${period}`;
      const dataRows = sortedChartData.map((datum) => datum.toTableRowData());
      const firstYear = sortedChartData[0].year1;
      const secondYear = sortedChartData[0].year2;
      const displayedUnit = intensityUnit ? `${unit}/${intensityUnit}` : unit;

      const headerRows = [
        [
          "Site Name",
          `${firstYear} (${displayedUnit})`,
          `${secondYear} (${displayedUnit})`,
          `YoY Change (${displayedUnit})`,
          "% Change",
        ],
      ];

      DashboardTableExcelDownloader.downloadExcel(
        headerRows,
        dataRows,
        fileName,
        excelSheetName
      );
    };

    content = (
      <div id={changeOverTimeSiteRankingConfig.chartViewId}>
        <div className="dashboard--change-over-time-site-ranking__mini-view-box">
          <ChangeOverTimeSiteRankingChartViewHeader
            title={title}
            period={period}
            hasDataGaps={hasDataGaps}
            subtopic={subtopic}
            intensity={intensity}
            selectedSiteCount={chartData.length}
            hasData={hasData}
            sortOrder={sortOrder}
            onSortOrderChange={onSortOrderChange}
            selectedViewType={selectedViewType}
            onViewTypeChange={onViewTypeChange}
            showExpandedTableView={showExpandedTableView}
            showExpandedChartView={showExpandedChartView}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(
                changeOverTimeSiteRankingConfig.chartViewId,
                false,
                [
                  changeOverTimeSiteRankingConfig.miniViewExcludeOnDownloadContainerId,
                ]
              )
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
          <div className="content-container">
            {hasData ? (
              <div className="chart-container">
                <ParentSize>
                  {(parent) =>
                    parent.width > 0 ? (
                      <ChangeOverTimeSiteRankingChartViewContent
                        width={parent.width}
                        height={chartHeight}
                        sortedChartData={truncatedSortedChartData}
                        selectedViewType={selectedViewType}
                        unit={unit}
                        intensityUnit={intensityUnit}
                      />
                    ) : null
                  }
                </ParentSize>
              </div>
            ) : (
              <div className="table__no_content">No data available</div>
            )}
          </div>
        </div>
        {hasData && isShowExpandedView && (
          <ExpandedChangeOverTimeSiteRankingChartView
            isShowExpandedChartView={isShowExpandedView}
            onCloseModal={() => setIsShowExpandedView(false)}
            sortedChartData={sortedChartData}
            title={title}
            period={period}
            subtopic={subtopic}
            hasDataGaps={hasDataGaps}
            selectedSiteCount={chartData.length}
            intensity={intensity}
            unit={unit}
            intensityUnit={intensityUnit}
            hasData={hasData}
            sortOrder={sortOrder}
            onSortOrderChange={onSortOrderChange}
            selectedViewType={selectedViewType}
            onViewTypeChange={onViewTypeChange}
            selectedExpandedViewOption={selectedExpandedViewOption}
            onExpandedViewOptionChange={(newExpandedViewOption) =>
              setSelectedExpandedViewOption(newExpandedViewOption)
            }
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(
                changeOverTimeSiteRankingConfig.expandedChartViewId,
                true,
                [
                  changeOverTimeSiteRankingConfig.expandedViewExcludeOnDownloadContainerId,
                ]
              )
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        )}
      </div>
    );
  }

  return content;
};

export default ChangeOverTimeSiteRankingChartView;
