import React, { useEffect, useState } from "react";
import { scaleLinear } from "@visx/scale";
import { withParentSize } from "@visx/responsive";
import { Axis, Orientation } from "@visx/axis";
import GridColumns from "@visx/grid/lib/grids/GridColumns";
import { PERFORMANCE_CHART_COLOR } from "./PerformanceReportChartService";
import { Text } from "@visx/text";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NumberService, UtilsService } from "../../../../services/UtilsService";
import sortBy from "lodash/sortBy";
import { DATA_STATUS } from "../../../../config/constants";
import capitalize from "lodash/capitalize";
import { Annotation, Connector, Label } from "@visx/annotation";
import Accordion from "react-bootstrap/Accordion";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { PERFORMANCE_REPORT_OPERATIONAL_METRICS } from "../PerformanceReportConfig";
import StatusLabel from "../../../../gvds-components/common/StatusLabel";
import GVDSAccordionHeader from "../../../../gvds-components/Accordion/GVDSAccordionHeader";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";

const BENCHMARK_PADDING = 60;
const BENCHMARK_HEIGHT = 33;
const BENCHMARK_TICK_HEIGHT = 5;
const BIG_NUMBER_OUT_OF_BOUND_OFFSET = 0.2 * BENCHMARK_PADDING;

const BENCHMARK_AXIS_LABELS = ["Worst", "Best"];

const METRO_AREA_ACTIVE_KEY = "metro_area_active_key";
const COUNTRY_ACTIVE_KEY = "country_active_key";
const CLIMATE_ZONE_ACTIVE_KEY = "climate_zone_active_key";
const PERFORMANCE_REPORT_COLUMN_GROUP_EMISSIONS = "emissions";
const METRO_AREA_NOT_AVAILABLE = "not_available";

const getYearlyValue = (report, year, dataCol) => {
  if (
    dataCol["data_key"] === PERFORMANCE_REPORT_COLUMN_GROUP_EMISSIONS &&
    report["yearly_reports"][year]["total_after_offsets"] !== null
  ) {
    return report["yearly_reports"][year]["total_after_offsets"];
  } else {
    return report["yearly_reports"][year][dataCol["data_key"]]["yearly"];
  }
};

const formatMissingDataTypes = (missingDataTypes) => {
  return missingDataTypes
    .map(
      (element) =>
        segmentTitlesMap[element.toLowerCase().replace(" ", "_")] ?? element
    )
    .join(", ");
};

const segmentTitlesMap = {
  asset_class: "Service Type / Asset Class",
  hotel_type: "Hotel Type",
  market_segment: "Market Segment",
  all_hotels: "All Hotels",
};

const getSegmentationName = (segment) => {
  const koppenGeiger = "köppen - geiger";
  const bailey = "bailey’s ecoregion";

  if (segment.location_type.toLowerCase() === koppenGeiger) {
    return (
      <span>
        {segment.location_name} (Köppen Geiger Climate Classification){" - "}
        {segment.segmentation_name}
      </span>
    );
  } else if (segment.location_type.toLowerCase() === bailey) {
    return (
      <span>
        {segment.location_name} (Bailey's Ecoregion){" - "}
        {segment.segmentation_name}
      </span>
    );
  } else {
    return (
      <span>
        {segment.location_name}
        {" - "}
        {segment.segmentation_name}
      </span>
    );
  }
};

const displaySingleBenchmarkChart = (segment, report, year, dataCol) => {
  return (
    <div className="segment-section">
      <Row>
        <Col>
          <div className="mb-1">
            {segment.benchmark === null &&
              segment.missing_data_types.length > 0 && (
                <div>
                  <StatusLabel color={StatusLabel.Colors.red} className="me-2">
                    Missing Site Details
                  </StatusLabel>
                  This view is unavailable as your site details are incomplete.
                  To view, please update this Site’s{" "}
                  {formatMissingDataTypes(segment.missing_data_types)} in Site
                  Details.
                </div>
              )}
            {segment.benchmark === null &&
              segment.missing_data_types.length === 0 && (
                <div>
                  <StatusLabel color={StatusLabel.Colors.gray} className="me-2">
                    Insufficient CHSB Data
                  </StatusLabel>
                  {segment.title === segmentTitlesMap.all_hotels
                    ? `Amongst ${segment.title}. `
                    : `Amongst hotels with similar ${segment.title}. `}
                  Please view other benchmark segments.
                </div>
              )}
            {segment.benchmark && [
              segment.title === segmentTitlesMap.all_hotels ? (
                <div>
                  Amongst All Hotels (against {segment.benchmark.year} data from
                  the CHSB {segment.benchmark.year + 2} Benchmark)
                </div>
              ) : (
                <div>
                  Amongst hotels with similar {segment.title} (against{" "}
                  {segment.benchmark.year} data from the CHSB{" "}
                  {segment.benchmark.year + 2} Benchmark)
                </div>
              ),
            ]}
          </div>
          {segment.segmentation_name && (
            <div className="gv-heading-5">{getSegmentationName(segment)}</div>
          )}
        </Col>
      </Row>
      {segment.benchmark && (
        <PerformanceReportSingleBenchmarkChart
          {...segment}
          yearlyValue={getYearlyValue(report, year, dataCol)}
        />
      )}
    </div>
  );
  // }
};

const getAvailableBenchmarkCount = (segments) => {
  let count = 0;

  Object.values(segments).forEach((segmentationType) => {
    if (
      segmentationType?.benchmark !== null &&
      segmentationType?.benchmark !== undefined
    ) {
      count++;
    }
  });
  return count;
};

const isBenchmarkGroupExpanded = (activeKeys, benchmarkGroupActiveKeyId) => {
  return !!activeKeys.includes(benchmarkGroupActiveKeyId);
};

const benchmarkCountTooltipContent = (
  <Popover>
    <Popover.Body>
      Number of available CHSB diagrams for this geographical location
    </Popover.Body>
  </Popover>
);

const benchmarkOperationalMetricsInvolveArea = [
  PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA,
  PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA,
];

const InsufficientCHSBDataView = () => {
  return (
    <div className="segment-section">
      <StatusLabel color={StatusLabel.Colors.gray} className="me-2">
        Insufficient CHSB Data
      </StatusLabel>
      Please view other benchmark segments.
    </div>
  );
};

const MissingSiteDetailsView = ({ missingData }) => {
  return (
    <div className="segment-section">
      <StatusLabel color={StatusLabel.Colors.red} className="me-2">
        Missing Site Details
      </StatusLabel>
      This view is unavailable as your site details are incomplete. To view,
      please update this Site's {missingData} in Site Details.
    </div>
  );
};

const metroAreaMissingDataType = "Metro Area";
const countryMissingDataType = "Country";
const climateZoneMissingDataType = "Climate Zone";

const isBenchmarkNotAvailable = (locationBenchmarkData) => {
  return (
    locationBenchmarkData.market_segment.benchmark === null &&
    locationBenchmarkData.market_segment.missing_data_types.length === 0 &&
    locationBenchmarkData.hotel_type.benchmark === null &&
    locationBenchmarkData.hotel_type.missing_data_types.length === 0 &&
    locationBenchmarkData.asset_class.benchmark === null &&
    locationBenchmarkData.asset_class.missing_data_types.length === 0 &&
    locationBenchmarkData.all_hotels.benchmark === null &&
    locationBenchmarkData.all_hotels.missing_data_types.length === 0
  );
};

const isLocationBenchmarkMissingLocation = (
  locationBenchmarkData,
  locationBenchmarkDataType
) => {
  return (
    locationBenchmarkData.market_segment.benchmark === null &&
    locationBenchmarkData.market_segment.missing_data_types.length > 0 &&
    locationBenchmarkData.market_segment.missing_data_types.includes(
      locationBenchmarkDataType
    ) &&
    locationBenchmarkData.hotel_type.benchmark === null &&
    locationBenchmarkData.hotel_type.missing_data_types.length > 0 &&
    locationBenchmarkData.hotel_type.missing_data_types.includes(
      locationBenchmarkDataType
    ) &&
    locationBenchmarkData.asset_class.benchmark === null &&
    locationBenchmarkData.asset_class.missing_data_types.length > 0 &&
    locationBenchmarkData.asset_class.missing_data_types.includes(
      locationBenchmarkDataType
    ) &&
    locationBenchmarkData.all_hotels.benchmark === null &&
    locationBenchmarkData.all_hotels.missing_data_types.length > 0 &&
    locationBenchmarkData.all_hotels.missing_data_types.includes(
      locationBenchmarkDataType
    )
  );
};

const PerformanceReportBenchmarkChart = ({
  benchmark,
  report,
  isDownloading,
  operationalMetric,
}) => {
  const [activeKeys, setActiveKeys] = useState([]);
  const { country, climate_zone, metro_area, year } = benchmark;
  const dataCol = sortBy(report["column_groups"], "seq")[0];

  const metroAreaAvailableBenchmarkCount =
    getAvailableBenchmarkCount(metro_area);
  const countryAvailableBenchmarkCount = getAvailableBenchmarkCount(country);
  const climateZoneAvailableBenchmarkCount =
    getAvailableBenchmarkCount(climate_zone);

  useEffect(() => {
    const defaultActiveKeys = [];
    if (metroAreaAvailableBenchmarkCount > 0) {
      defaultActiveKeys.push(METRO_AREA_ACTIVE_KEY);
    } else if (countryAvailableBenchmarkCount > 0) {
      defaultActiveKeys.push(COUNTRY_ACTIVE_KEY);
    } else if (climateZoneAvailableBenchmarkCount > 0) {
      defaultActiveKeys.push(CLIMATE_ZONE_ACTIVE_KEY);
    }
    setActiveKeys(defaultActiveKeys);
  }, []);

  useEffect(() => {
    if (isDownloading) {
      const newActiveKeys = [
        METRO_AREA_ACTIVE_KEY,
        COUNTRY_ACTIVE_KEY,
        CLIMATE_ZONE_ACTIVE_KEY,
      ];
      setActiveKeys(newActiveKeys);
    }
  }, [isDownloading]);

  for (const [key, value] of Object.entries(metro_area)) {
    value["title"] = segmentTitlesMap[key];
  }

  for (const [key, value] of Object.entries(country)) {
    value["title"] = segmentTitlesMap[key];
  }

  for (const [key, value] of Object.entries(climate_zone)) {
    value["title"] = segmentTitlesMap[key];
  }

  return (
    <>
      <div className="mb-2">
        <div className="performance-report__chart-table-title text-center">
          {report["title"]} {capitalize(dataCol["data_key"])} (
          {dataCol["unit_id"]}) - {year}
        </div>
        <div className="body-2 mt-4 mb-4">
          <div className="mb-4">
            The following diagrams compare your site’s usage or emissions
            against data in the Cornell Hotel Sustainability Benchmarking (CHSB)
            Index.
          </div>
          <div className="mb-2">
            The report outputs the <strong>most granular</strong> and{" "}
            <strong>most relevant segmentation</strong> based on your site's
            details and the latest benchmarking data available. The report may
            use different segments and CHSB data from different years.
          </div>
          {benchmarkOperationalMetricsInvolveArea.includes(
            operationalMetric
          ) && (
            <div>
              Floor area intensities in CHSB are based on conditioned space
              area.
            </div>
          )}
        </div>
      </div>
      <Accordion className="accordion-header" activeKey={activeKeys} alwaysOpen>
        <Accordion.Item eventKey={METRO_AREA_ACTIVE_KEY} className="mb-4">
          <GVDSAccordionHeader
            isExpanded={isBenchmarkGroupExpanded(
              activeKeys,
              METRO_AREA_ACTIVE_KEY
            )}
            title={
              <>Metro Area: {metro_area.asset_class?.location_name ?? ""}</>
            }
            count={
              <OverlayTrigger
                placement="top"
                overlay={benchmarkCountTooltipContent}
              >
                <span>{metroAreaAvailableBenchmarkCount}</span>
              </OverlayTrigger>
            }
            onClick={() =>
              setActiveKeys(
                UtilsService.toggleItem(activeKeys, METRO_AREA_ACTIVE_KEY)
              )
            }
          />
          <Accordion.Body>
            {activeKeys.includes(METRO_AREA_ACTIVE_KEY) ? (
              <>
                {isBenchmarkNotAvailable(metro_area) ? (
                  <InsufficientCHSBDataView />
                ) : isLocationBenchmarkMissingLocation(
                    metro_area,
                    metroAreaMissingDataType
                  ) ? (
                  <MissingSiteDetailsView
                    missingData={metroAreaMissingDataType}
                  />
                ) : (
                  <>
                    {displaySingleBenchmarkChart(
                      metro_area.market_segment,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      metro_area.hotel_type,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      metro_area.asset_class,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      metro_area.all_hotels,
                      report,
                      year,
                      dataCol
                    )}
                  </>
                )}
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={COUNTRY_ACTIVE_KEY} className="mb-4">
          <GVDSAccordionHeader
            isExpanded={isBenchmarkGroupExpanded(
              activeKeys,
              COUNTRY_ACTIVE_KEY
            )}
            title={<>Country: {country.asset_class?.location_name ?? ""}</>}
            count={
              <OverlayTrigger
                placement="top"
                overlay={benchmarkCountTooltipContent}
              >
                <span>{countryAvailableBenchmarkCount}</span>
              </OverlayTrigger>
            }
            onClick={() =>
              setActiveKeys(
                UtilsService.toggleItem(activeKeys, COUNTRY_ACTIVE_KEY)
              )
            }
          />
          <Accordion.Body>
            {activeKeys.includes(COUNTRY_ACTIVE_KEY) ? (
              <>
                {isBenchmarkNotAvailable(country) ? (
                  <InsufficientCHSBDataView />
                ) : isLocationBenchmarkMissingLocation(
                    country,
                    countryMissingDataType
                  ) ? (
                  <MissingSiteDetailsView
                    missingData={countryMissingDataType}
                  />
                ) : (
                  <>
                    {displaySingleBenchmarkChart(
                      country.market_segment,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      country.hotel_type,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      country.asset_class,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      country.all_hotels,
                      report,
                      year,
                      dataCol
                    )}
                  </>
                )}
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={CLIMATE_ZONE_ACTIVE_KEY}>
          <GVDSAccordionHeader
            isExpanded={isBenchmarkGroupExpanded(
              activeKeys,
              CLIMATE_ZONE_ACTIVE_KEY
            )}
            title={
              <div className="d-flex align-items-center">
                <span className="me-2">Climate Zone</span>
                <InfoTooltip
                  info={
                    <span>
                      Each benchmark diagram uses the most relevant climate zone
                      based on your site's climate zone and available CHSB data.
                      Hence, different diagrams may use different climate zones.
                    </span>
                  }
                />
              </div>
            }
            count={
              <OverlayTrigger
                placement="top"
                overlay={benchmarkCountTooltipContent}
              >
                <span>{climateZoneAvailableBenchmarkCount}</span>
              </OverlayTrigger>
            }
            onClick={() =>
              setActiveKeys(
                UtilsService.toggleItem(activeKeys, CLIMATE_ZONE_ACTIVE_KEY)
              )
            }
          />
          <Accordion.Body>
            {activeKeys.includes(CLIMATE_ZONE_ACTIVE_KEY) ? (
              <>
                {isBenchmarkNotAvailable(climate_zone) ? (
                  <InsufficientCHSBDataView />
                ) : isLocationBenchmarkMissingLocation(
                    climate_zone,
                    climateZoneMissingDataType
                  ) ? (
                  <MissingSiteDetailsView
                    missingData={climateZoneMissingDataType}
                  />
                ) : (
                  <>
                    {displaySingleBenchmarkChart(
                      climate_zone.market_segment,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      climate_zone.hotel_type,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      climate_zone.asset_class,
                      report,
                      year,
                      dataCol
                    )}
                    {displaySingleBenchmarkChart(
                      climate_zone.all_hotels,
                      report,
                      year,
                      dataCol
                    )}
                  </>
                )}
              </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export const PerformanceReportSingleBenchmarkChart = ({
  segmentation_name,
  benchmark,
  yearlyValue,
}) => {
  const getBenchmarkMessage = () => {
    if (!benchmark || yearlyValue.status !== DATA_STATUS.COMPLETE) return null;
    const {
      percentile_0,
      percentile_25,
      percentile_50,
      percentile_75,
      percentile_100,
    } = benchmark;
    const percentileScale = scaleLinear({
      domain: [percentile_0, percentile_100],
      range: [0, 100],
    });
    if (percentileScale(yearlyValue.value) < percentileScale(percentile_25)) {
      return (
        <>
          Based on the data we’ve gathered, your site’s performance is{" "}
          <strong>below average</strong> for {segmentation_name}.
        </>
      );
    }
    if (percentileScale(yearlyValue.value) < percentileScale(percentile_50)) {
      return (
        <>
          Based on the data we’ve gathered, your site’s performance is{" "}
          <strong>below average</strong> for {segmentation_name}.
        </>
      );
    }
    if (percentileScale(yearlyValue.value) < percentileScale(percentile_75)) {
      return (
        <>
          Based on the data we’ve gathered, your site’s performance is{" "}
          <strong>about average</strong> for {segmentation_name}.
        </>
      );
    }
    return (
      <>
        Based on the data we’ve gathered, your site’s performance is{" "}
        <strong>above average</strong> for {segmentation_name}.
      </>
    );
  };

  return (
    <>
      <Row className="performance-report-chart__single-benchmark-chart">
        <Col className="chart-section">
          <PerformanceReportScaleChart
            benchmark={benchmark}
            value={yearlyValue.value}
            dataStatus={yearlyValue.status}
          />
        </Col>
        <Col className="d-flex align-items-center legend-section">
          <div className="performance-report-chart__benchmark__details">
            <div>Mean = {NumberService.toPrecision(benchmark.mean)}</div>
            <div>
              Std Deviation ={" "}
              {NumberService.toPrecision(benchmark.standard_deviation)}
            </div>
            <div>Sample Size = {benchmark.count}</div>
          </div>
        </Col>
      </Row>
      <Row>
        {getBenchmarkMessage() && (
          <div className="gv-text-16">{getBenchmarkMessage()}</div>
        )}
        {yearlyValue.status !== DATA_STATUS.COMPLETE && (
          <div className="gvds-color--error text-vertical-center">
            <GVDSIcon className="me-1" Icon={IconAlertTriangleFilled} />
            <div className="gv-text-16">
              Site has data gaps. Benchmark may not be accurate.
            </div>
          </div>
        )}
      </Row>
    </>
  );
};

const PerformanceReportScaleChart = withParentSize(
  ({ benchmark, parentWidth, parentHeight, value, dataStatus }) => {
    const {
      percentile_0,
      percentile_25,
      percentile_50,
      percentile_75,
      percentile_100,
    } = benchmark;
    const scale = scaleLinear({
      domain: [percentile_0, percentile_100],
      range: [0, parentWidth - BENCHMARK_PADDING * 2],
    });

    const usableWidth = parentWidth - BENCHMARK_PADDING * 2;
    const usableWidthQuarter = usableWidth / 4;
    const annotationX25th = BENCHMARK_PADDING + usableWidthQuarter;
    const annotationX50th = BENCHMARK_PADDING + usableWidthQuarter * 2;
    const annotationX75th = BENCHMARK_PADDING + usableWidthQuarter * 3;
    // Subject = position of point in chart, Label = position of label
    const annotationLabelWidth = usableWidth / 8;
    const annotationLabelY =
      BENCHMARK_PADDING * 2.2 + BENCHMARK_HEIGHT + BENCHMARK_TICK_HEIGHT;
    const annotationSubjectX = (percentile) =>
      BENCHMARK_PADDING + scale(percentile);
    const annotationSubjectY = BENCHMARK_PADDING * 1.5 + BENCHMARK_HEIGHT;
    const annotationLabelXRelativeToSubject = (annotationLabelX, percentile) =>
      annotationLabelX - annotationSubjectX(percentile);
    const annotationLabelYRelativeToSubject =
      annotationLabelY - annotationSubjectY;

    const getValueX = () => {
      const valueX = scale(value);
      const minX = -BENCHMARK_PADDING / 2 + BIG_NUMBER_OUT_OF_BOUND_OFFSET;
      const maxX =
        parentWidth - BENCHMARK_PADDING * 1.5 - BIG_NUMBER_OUT_OF_BOUND_OFFSET;
      if (valueX < minX) {
        return minX;
      }
      if (valueX > maxX) {
        return maxX;
      }
      return valueX;
    };

    const getValueColor = () => {
      if (dataStatus !== DATA_STATUS.COMPLETE) {
        return PERFORMANCE_CHART_COLOR.RED;
      }
      return PERFORMANCE_CHART_COLOR.DEFAULT;
    };

    return (
      <svg width={parentWidth} height={250}>
        <g
          transform={`translate(${BENCHMARK_PADDING} ${
            BENCHMARK_PADDING * 1.5
          })`}
        >
          <rect
            x={0}
            width={scale(percentile_25)}
            height={BENCHMARK_HEIGHT}
            fill={PERFORMANCE_CHART_COLOR.GRAY_93}
          />
          <rect
            x={scale(percentile_25)}
            width={scale(percentile_75) - scale(percentile_25)}
            height={BENCHMARK_HEIGHT}
            fill={PERFORMANCE_CHART_COLOR.LIGHT_BLUE}
          />
          <rect
            x={scale(percentile_75)}
            width={scale(percentile_100) - scale(percentile_75)}
            height={BENCHMARK_HEIGHT}
            fill={PERFORMANCE_CHART_COLOR.GRAY_93}
          />

          <GridColumns
            scale={scale}
            height={BENCHMARK_HEIGHT + BENCHMARK_TICK_HEIGHT}
            stroke={PERFORMANCE_CHART_COLOR.GRAY_36}
            strokeWidth={2}
            tickValues={[percentile_0, percentile_100]}
          />
          <GridColumns
            scale={scale}
            height={BENCHMARK_HEIGHT}
            stroke={PERFORMANCE_CHART_COLOR.BLUE}
            strokeWidth={2}
            tickValues={[percentile_25, percentile_50, percentile_75]}
          />
          <Axis
            hideAxisLine
            hideTicks
            orientation={Orientation.bottom}
            stroke={PERFORMANCE_CHART_COLOR.GRAY_36}
            scale={scale}
            strokeWidth={2}
            tickValues={[percentile_0, percentile_100]}
            top={BENCHMARK_HEIGHT}
            tickFormat={(v, index) => ({
              value: NumberService.toPrecision(v),
              details: BENCHMARK_AXIS_LABELS[index],
            })}
            tickComponent={({ x, y, formattedValue }) => (
              <g>
                <Text
                  className="body-1-bold"
                  verticalAnchor="start"
                  textAnchor="middle"
                  x={x}
                  y={y}
                >
                  {formattedValue["value"]}
                </Text>
                <Text
                  verticalAnchor="start"
                  textAnchor="middle"
                  x={x}
                  y={y + 15}
                >
                  {formattedValue["details"]}
                </Text>
              </g>
            )}
          />

          {value && (
            <>
              <Text
                className="body-2-extra-bold"
                verticalAnchor="start"
                textAnchor="middle"
                fill={getValueColor()}
                x={getValueX()}
                y={-BENCHMARK_PADDING * 0.65}
              >
                {NumberService.toPrecision(value)}
              </Text>
              <line
                x1={getValueX()}
                x2={getValueX()}
                y1={-BENCHMARK_PADDING * 0.35}
                y2={BENCHMARK_HEIGHT}
                stroke={getValueColor()}
                strokeWidth={2}
              />
            </>
          )}
        </g>
        <Annotation x={annotationX25th} y={annotationLabelY}>
          <Connector
            stroke={PERFORMANCE_CHART_COLOR.BLUE}
            type="line"
            x={annotationSubjectX(percentile_25)}
            y={annotationSubjectY}
            dx={annotationLabelXRelativeToSubject(
              annotationX25th,
              percentile_25
            )}
            dy={annotationLabelYRelativeToSubject}
          />
          <Label
            backgroundFill={PERFORMANCE_CHART_COLOR.WHITE}
            backgroundProps={{ stroke: PERFORMANCE_CHART_COLOR.GRAY_56 }}
            showAnchorLine={false}
            fontColor={PERFORMANCE_CHART_COLOR.DEFAULT}
            horizontalAnchor="middle"
            title={NumberService.toPrecision(percentile_25)}
            titleFontSize={14}
            titleProps={{ textAnchor: "middle" }}
            subtitle="25th percentile"
            subtitleFontWeight={400}
            subtitleProps={{ textAnchor: "middle" }}
            verticalAnchor="start"
            width={annotationLabelWidth}
          />
        </Annotation>
        <Annotation x={annotationX50th} y={annotationLabelY}>
          <Connector
            stroke={PERFORMANCE_CHART_COLOR.BLUE}
            type="line"
            x={annotationSubjectX(percentile_50)}
            y={annotationSubjectY}
            dx={annotationLabelXRelativeToSubject(
              annotationX50th,
              percentile_50
            )}
            dy={annotationLabelYRelativeToSubject}
          />
          <Label
            backgroundFill={PERFORMANCE_CHART_COLOR.WHITE}
            backgroundProps={{ stroke: PERFORMANCE_CHART_COLOR.GRAY_56 }}
            showAnchorLine={false}
            fontColor={PERFORMANCE_CHART_COLOR.DEFAULT}
            horizontalAnchor="middle"
            title={NumberService.toPrecision(percentile_50)}
            titleFontSize={14}
            titleProps={{ textAnchor: "middle" }}
            subtitle="Median"
            subtitleFontWeight={400}
            subtitleProps={{ textAnchor: "middle" }}
            verticalAnchor="start"
            width={annotationLabelWidth}
          />
        </Annotation>
        <Annotation x={annotationX75th} y={annotationLabelY}>
          <Connector
            stroke={PERFORMANCE_CHART_COLOR.BLUE}
            type="line"
            x={annotationSubjectX(percentile_75)}
            y={annotationSubjectY}
            dx={annotationLabelXRelativeToSubject(
              annotationX75th,
              percentile_75
            )}
            dy={annotationLabelYRelativeToSubject}
          />
          <Label
            backgroundFill={PERFORMANCE_CHART_COLOR.WHITE}
            backgroundProps={{ stroke: PERFORMANCE_CHART_COLOR.GRAY_56 }}
            showAnchorLine={false}
            fontColor={PERFORMANCE_CHART_COLOR.DEFAULT}
            horizontalAnchor="middle"
            title={NumberService.toPrecision(percentile_75)}
            titleFontSize={14}
            titleProps={{ textAnchor: "middle" }}
            subtitle="75th percentile"
            subtitleFontWeight={400}
            subtitleProps={{ textAnchor: "middle" }}
            verticalAnchor="start"
            width={annotationLabelWidth}
          />
        </Annotation>
      </svg>
    );
  }
);

export default PerformanceReportBenchmarkChart;
