import React, { useCallback, useState } from "react";

const WorkspaceInfoContext = React.createContext();

export const WorkspaceInfoProvider = ({ children }) => {
  const [isWorkspaceInfoOpen, setIsWorkspaceInfoOpen] = useState(false);

  const openWorkspaceInfo = () => {
    setIsWorkspaceInfoOpen(true);
  };

  const closeWorkspaceInfo = () => {
    setIsWorkspaceInfoOpen(false);
  };

  const store = useCallback(
    {
      isOpen: isWorkspaceInfoOpen,
      open: openWorkspaceInfo,
      close: closeWorkspaceInfo,
    },
    [isWorkspaceInfoOpen]
  );

  return (
    <WorkspaceInfoContext.Provider value={store}>
      {children}
    </WorkspaceInfoContext.Provider>
  );
};

export default WorkspaceInfoContext;
