import React from "react";
import { useLocation } from "react-router-dom";

import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconHelpCircle } from "@tabler/icons-react";

export const GUIDED_TOUR_HELP_ARTICLE_TITLE = {
  ENV_SETUP_ENV_DATA: "Setting Up Environmental Data",
  ENV_READ_ENV_DATA_STATUS_AND_ISSUES:
    "Reading Environmental Data Status and Resolving Data Issues",
  ENV_INPUT_ENV_DATA: "Inputting Environmental Data",
  INIT_LOG_INIT: "Logging Initiatives",
  INIT_RUN_REPORT: "Running Initiatives Report",
  REPORT_PERF_UNDERSTAND_ENV:
    "Understanding Environmental Subtopic, Performance Grouping and Single Type",
  REPORT_PERF_UNDERSTAND_OPS: "Understanding Operational Types",
  REPORT_PERF_ISSUE_DATA_REQUEST: "Issuing Data Requests",
  GSTC_NAVIGATE: "Navigating through GSTC",
  BPQ_NAVIGATE: "Navigating through Best Practice Questions",
  BPQ_RUN_REPORT: "Running Best Practices Report",
};

const HELP_ARTICLE_TITLE_TO_ID = {
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.ENV_SETUP_ENV_DATA]:
    "cbe99b59-0512-4023-9988-b0dda91af538",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.ENV_READ_ENV_DATA_STATUS_AND_ISSUES]:
    "d4b99ad5-42d2-4f6d-a374-3e4aa132052f",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.ENV_INPUT_ENV_DATA]:
    "e4b8fe66-6534-465d-b85b-37a4e2c71c52",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.INIT_LOG_INIT]:
    "c4d419df-a277-437f-8a54-f0f0222ea36b",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.INIT_RUN_REPORT]:
    "8c0e4365-90e5-4fbc-84d6-2f59ae1113d5",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.REPORT_PERF_UNDERSTAND_ENV]:
    "c4d97eba-679e-4557-903a-ce09a46df2c6",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.REPORT_PERF_UNDERSTAND_OPS]:
    "7fa0d024-0d8f-495f-85ec-524364a73f3a",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.REPORT_PERF_ISSUE_DATA_REQUEST]:
    "cb90fb4a-dfb1-4ef5-abdb-73b8186891b2",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.GSTC_NAVIGATE]:
    "dee234b2-88db-4807-aafe-7e3310caf527",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.BPQ_NAVIGATE]:
    "4a87e095-62da-4482-afee-4ca7c4c1c3f6",
  [GUIDED_TOUR_HELP_ARTICLE_TITLE.BPQ_RUN_REPORT]:
    "e92eeb1e-2e5c-4e57-bf00-bfd042c4d477",
};

const getHelpArticleLinkByTitle = (title) => {
  return `https://www.greenviewportal.com/help/${HELP_ARTICLE_TITLE_TO_ID[title]}`;
};

export const GuidedTourHelpArticleInlineLink = ({ articleTitle }) => {
  const location = useLocation();

  // TODO replace with GVDSLink

  return (
    <a
      href={getRedirectURLWithCurrentParam(
        getHelpArticleLinkByTitle(articleTitle),
        location
      )}
      target="_blank"
    >
      {articleTitle}
    </a>
  );
};

export const GuidedTourHelpArticleDedicatedLink = ({ articleTitle }) => {
  const location = useLocation();

  const openArticle = () => {
    window.open(
      getRedirectURLWithCurrentParam(
        getHelpArticleLinkByTitle(articleTitle),
        location
      ),
      "_blank"
    );
  };

  return (
    <GVDSTextButton
      onClick={openArticle}
      text={articleTitle}
      icon={<GVDSIcon Icon={IconHelpCircle} />}
    />
  );
};
