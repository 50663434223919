import {
  CellValue,
  DropdownCellValue,
  NumberCellValue,
  RemoveActionCell,
} from "../Data/BulkDataInputCellViewer";
import moment from "moment";
import SiteBulkService from "../../services/SiteBulkService";
import find from "lodash/find";
import { new_entry_prefix } from "../../config/constants";
import { DateTimeUtils } from "../../services/UtilsService";
import _ from "lodash";

const columns = {
  0: {
    key: "action",
    label: "",
    width: 2,
    classType: RemoveActionCell,
    errorKey: "",
    readOnly: true,
  },
  1: {
    key: "index",
    label: "Row",
    width: 2.5,
    classType: CellValue,
    errorKey: "index",
    readOnly: true,
  },
  2: {
    key: "site_name",
    label: "Site Name*",
    width: 10,
    classType: CellValue,
    errorKey: "site_name",
  },
  3: {
    key: "record_month",
    label: "Month Year*",
    width: 10,
    classType: CellValue,
    errorKey: "record_month",
  },
  4: {
    key: "gfa_built",
    label: "Gross Floor Area*",
    width: 10,
    classType: NumberCellValue,
    errorKey: "gfa_built",
  },
  5: {
    key: "gfa_conditioned_space",
    label: "Conditioned Space",
    width: 10,
    classType: NumberCellValue,
    errorKey: "gfa_conditioned_space",
  },
  6: {
    key: "gfa_guestroom",
    label: "Guestrooms and Corridors",
    width: 10,
    classType: NumberCellValue,
    errorKey: "gfa_guestroom",
  },
  7: {
    key: "gfa_meeting",
    label: "Meeting / Function Space",
    width: 10,
    classType: NumberCellValue,
    errorKey: "gfa_meeting",
  },
  8: {
    key: "unit",
    label: "Unit*",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "unit",
  },
  9: {
    key: "comment",
    label: "Comment",
    width: 10,
    classType: CellValue,
    errorKey: "comment",
  },
};

class FloorAreaValue {
  static new(row, removeRow) {
    const newArea = new FloorAreaValue();

    Object.values(columns).forEach((c) => {
      newArea[c.key] = new c.classType();
    });

    newArea.action = RemoveActionCell.fromRemoveRow(() => removeRow(row));
    newArea.index = new CellValue(row + 1, true, [], [], false);
    return newArea;
  }

  static fromRowData(rowData) {
    const newArea = new FloorAreaValue();
    Object.values(columns).forEach((c, col) => {
      newArea[c.key] = c.classType.fromCellData(rowData[col]);
    });
    return newArea;
  }

  static getNameByCol(col) {
    return columns[col]?.key;
  }

  updateDataEditor(options) {
    this.unit.setDropdownOptions(options["area_units"]);
  }

  toGridRow() {
    return Object.values(columns).map((c) => this[c.key]);
  }

  isEmpty() {
    return Object.values(columns)
      .filter((c) => !c.readOnly)
      .reduce((acc, cur) => acc && this[cur.key].isEmpty(), true);
  }

  removeAllErrors() {
    Object.values(columns).forEach((c) => {
      this[c.key].dataErrors = [];
      this[c.key].possibleErrors = [];
    });
  }
}

export const getFloorAreaColumnLabels = () =>
  Object.values(columns).map((c) => ({ label: c.label, width: c.width }));

export default class BulkFloorAreasInputService {
  selectorOptions = {};

  async getSelectionOptions() {
    this.selectorOptions = await SiteBulkService.getConfig();
  }

  getNewRow(row, removeRow) {
    const newRow = FloorAreaValue.new(Number(row), removeRow);
    newRow.updateDataEditor(this.selectorOptions);
    return newRow.toGridRow();
  }

  updateRow(rowData, changes) {
    const areaRow = FloorAreaValue.fromRowData(rowData);

    changes.forEach((c) => {
      const colName = FloorAreaValue.getNameByCol(c.col);
      if (colName) {
        areaRow[colName].updateValue(c.value);
      }
    });
    areaRow.updateDataEditor(this.selectorOptions);
    return areaRow.toGridRow();
  }

  removeRow(rowNumber, setRowToDelete, grid) {
    let changedGrid = grid.map((row) => [...row]);
    changedGrid.splice(rowNumber, 1);
    changedGrid = changedGrid.map((g, row) => {
      const area = FloorAreaValue.fromRowData(g);
      area.index.updateValue(row + 1);
      area.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row));
      return area.toGridRow();
    });
    return changedGrid;
  }

  removeEmptyRows = (grid, setRowToDelete) => {
    return grid
      .map((rowData) => FloorAreaValue.fromRowData(rowData))
      .filter((r) => !r.isEmpty())
      .map((r, row) => {
        r.index.updateValue(row + 1);
        r.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row));
        return r.toGridRow();
      });
  };

  getArea(rowData) {
    const area = FloorAreaValue.fromRowData(rowData);
    const unit = find(
      this.selectorOptions["area_units"],
      (t) => t.name === area.unit.value
    );

    const record_month = moment(area.record_month.value, "MMM YYYY");

    return {
      row: area.index.value - 1,
      id: new_entry_prefix + area.index.value,
      site_name: area.site_name.value,
      record_month: record_month.isValid()
        ? DateTimeUtils.getUTCISOString(record_month)
        : "",
      gfa_built: area.gfa_built.getValue(),
      gfa_conditioned_space: area.gfa_conditioned_space.getValue(),
      gfa_guestroom: area.gfa_guestroom.getValue(),
      gfa_meeting: area.gfa_meeting.getValue(),
      unit: unit,
      comment: area.comment.value,
    };
  }

  updateGridWithErrors = (grid, dataErrors) => {
    const changedGrid = grid.map((rowData) => {
      const area = FloorAreaValue.fromRowData(rowData);
      area.removeAllErrors();
      return area.toGridRow();
    });

    for (const [row, err] of Object.entries(dataErrors)) {
      const reading = FloorAreaValue.fromRowData(changedGrid[row]);
      Object.values(columns).forEach(({ key, errorKey }) => {
        reading[key].dataErrors = _.chain(err).get(errorKey, []).value();
      });
      changedGrid[row] = reading.toGridRow();
    }

    return changedGrid;
  };
}
