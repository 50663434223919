import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import { TextCopies } from "../../../config/text-copies";
import JoinedBullet from "../../common/JoinedBullet";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import React, { useCallback, useContext, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import CertificationContext from "../CertificationContext";
import { useTranslation } from "react-i18next";

const AuditReadyInfoModal = ({ isShow, closeModal, verificationDetails }) => {
  const { t } = useTranslation();

  // https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const verificationDetailsRef = useCallback((node) => {
    if (node !== null) {
      node.innerHTML = verificationDetails;
    }
  }, []);

  return (
    <GVDSModal
      title="Ready for audit?"
      size={GVDSModal.Size.small}
      show={isShow}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        <div ref={verificationDetailsRef} />
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={closeModal}
          text={t("shared-modal.footer.okay")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const CertificationReadinessScore = () => {
  const certificationContext = useContext(CertificationContext);
  const [isShowAudiReadyInfoModal, setIsShowAuditReadyInfoModal] =
    useState(false);

  const currentCertification = certificationContext.certification;
  let completedRequirementCount = 0;
  let totalApplicableRequirementCount = 0;

  currentCertification.categories.forEach((category) => {
    category.criteria.forEach((criterion) => {
      completedRequirementCount += criterion.completedRequirementCount;
      totalApplicableRequirementCount +=
        criterion.totalApplicableRequirementCount;
    });
  });

  const completedPercentage =
    totalApplicableRequirementCount === 0
      ? 0
      : Math.round(
          (completedRequirementCount / totalApplicableRequirementCount) * 100
        );

  return (
    <>
      <div className="certification-homepage__readiness-score__container">
        <div className="certification-homepage__readiness-score__label">
          <div className="gvds-text--heading4">Readiness Score</div>
          <div className="ms-1 mt-1">
            <InfoTooltip
              info={
                TextCopies.CertificationAssessment.readinessScoreDescription
              }
            />
          </div>
          <JoinedBullet />
          <div>{`${completedPercentage}%`}</div>
          <JoinedBullet />
          <div>{`${completedRequirementCount}/${totalApplicableRequirementCount} requirements`}</div>
          <GVDSTextButton
            text="Ready for audit?"
            onClick={() => setIsShowAuditReadyInfoModal(true)}
            className="ms-auto"
          />
        </div>
        <ProgressBar now={completedPercentage} />
      </div>
      <AuditReadyInfoModal
        isShow={isShowAudiReadyInfoModal}
        closeModal={() => setIsShowAuditReadyInfoModal(false)}
        verificationDetails={currentCertification.verificationDetails}
      />
    </>
  );
};

export default CertificationReadinessScore;
