import { Trans, useTranslation } from "react-i18next";
import SupportContactLink from "../common/SupportContactLink";
import React from "react";

export const HelpAdditionalSupportBlurb = () => {
  const { t } = useTranslation();

  return (
    <Trans i18nKey="help-center.additional-support-blurb">
      Please check out the Help button at the top right for page-specific
      information. We have various Guided Tours around the Portal as well. For
      further assistance, please reach out to Portal Support at{" "}
      <SupportContactLink /> and we will get back to you within 12 hours.
    </Trans>
  );
};
