import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../HOC/withAuthentication";
import GuidanceArticles from "./../../services/GuidanceArticleService";
import GuidanceArticleService, {
  GUIDANCE_BOOKMARK_STATUS,
} from "./../../services/GuidanceArticleService";
import UserInventoryContext from "./../../context/UserInventoryContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DateTimeUtils, UtilsService } from "../../services/UtilsService";
import uniq from "lodash/uniq";
import LoadingSpinner from "../common/LoadingSpinner";
import { GuidanceArticleUtils } from "./GuidanceArticles/GuidanceArticleUtils";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ToastContext from "./../../context/ToastContext";
import { RESOURCES } from "../../config/constants";
import UNSDGService from "../../services/UNSDGService";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import BestPracticeCategoryService from "../../services/ReferenceDataServices/BestPracticeCategoryService";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import FilterSearchBox from "../../gvds-components/common/FilterSearchBox";
import GVDSTag from "../../gvds-components/common/GVDSTag";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import useGVDSTableCtrl from "../../gvds-components/Table/GVDSTableHook";
import { guidanceArticleSearchKeys } from "../../config/search-config";
import SavedFiltersService from "../../services/SavedFiltersService";
import { SORTING_TYPES } from "../../gvds-components/Table/GVDSTable";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconBookmark, IconBookmarkFilled } from "@tabler/icons-react";
import { ContentTypeDisplay } from "../common/ContentTypeDisplay";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

const BestPracticesGuidance = () => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const currentInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);

  const [guidanceArticles, setGuidanceArticles] = useState([]);

  const {
    filteredSortedData,
    totalDataLength,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
  } = useGVDSTableCtrl(
    guidanceArticles,
    guidanceArticleSearchKeys,
    {
      title: SORTING_TYPES.asc,
    },
    {},
    SavedFiltersService.SAVED_FILTER__GUIDANCE_LIBRARY
  );

  useEffect(() => {
    setIsLoading(true);
    if (currentInventory) {
      GuidanceArticles.getGuidanceArticlesForInventory(
        currentInventory.id,
        currentInventory.type
      )
        .then((response) => {
          setIsLoading(false);
          setGuidanceArticles(response);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast("Failed to load guides");
        });
    }
  }, [userInventory.selectedInventory.get]);

  const updateGuidanceArticle = () => {
    setGuidanceArticles([...guidanceArticles]);
  };

  const allCategory = uniq(
    guidanceArticles.flatMap((d) => d.categories).map((c) => c.name)
  ).sort(BestPracticeCategoryService.bestPracticeSortFn);

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("guidance-library.page-title")}</h1>
        </PageHeader.Title>
        {currentInventory.type !== RESOURCES.FACILITY && (
          <PageHeader.Description>
            {t("guidance-library.page-description")}
          </PageHeader.Description>
        )}
      </PageHeader>
      <div>
        {currentInventory.type === RESOURCES.FACILITY ? (
          <div className="table__no_content">
            {t("guidance-library.not-available-for-facility")}
          </div>
        ) : (
          <div>
            <div>
              {(currentInventory.type === RESOURCES.SITE ||
                currentInventory.type === RESOURCES.PORTFOLIO) &&
              guidanceArticles.length > 0 ? (
                <div>
                  <div className="best-practice-category-progress-container">
                    {allCategory.map((categoryName) => {
                      return (
                        <CategoryFilterDisplay
                          key={categoryName}
                          isSelected={
                            filterKeys.category_names &&
                            filterKeys.category_names.indexOf(categoryName) >= 0
                          }
                          categoryName={categoryName}
                          onClick={(categoryName) =>
                            setFilterKeys({
                              ...filterKeys,
                              category_names: UtilsService.toggleItem(
                                filterKeys.category_names || [],
                                categoryName
                              ),
                            })
                          }
                        />
                      );
                    })}
                  </div>

                  <GVDSTableCtrlContainer>
                    <FilterSearchBox
                      className="question-search-box"
                      placeholder="Search Guidance"
                      value={searchText}
                      onInput={setSearchText}
                    />

                    <GVDSTableCtrlMultiSelect
                      options={uniq(
                        guidanceArticles.map((q) => q.tag_names).flat()
                      ).sort()}
                      prefix="Tags"
                      onChange={(filterValues) =>
                        setFilterKeys({
                          ...filterKeys,
                          tag_names: filterValues,
                        })
                      }
                      defaultSelected={filterKeys.tag_names || []}
                    />

                    <GVDSTableCtrlMultiSelect
                      options={uniq(
                        guidanceArticles
                          .map((q) => q.un_sdg_names)
                          .flat()
                          .sort(UNSDGService.unsdgNameSortFn)
                      )}
                      prefix="UN SDGs"
                      onChange={(filterValues) =>
                        setFilterKeys({
                          ...filterKeys,
                          un_sdg_names: filterValues,
                        })
                      }
                      defaultSelected={filterKeys.un_sdg_names || []}
                    />

                    <GVDSTableCtrlMultiSelect
                      options={[
                        GUIDANCE_BOOKMARK_STATUS.IS_BOOKMARKED,
                        GUIDANCE_BOOKMARK_STATUS.IS_NOT_BOOKMARKED,
                      ]}
                      prefix="Bookmark"
                      onChange={(filterValues) =>
                        setFilterKeys({
                          ...filterKeys,
                          bookmarkStatus: filterValues,
                        })
                      }
                      defaultSelected={filterKeys.bookmarkStatus || []}
                    />
                    <GVDSTableCtrlMultiSelect
                      options={uniq(
                        guidanceArticles
                          .map((q) => q.contentTypeName)
                          .filter((contentTypeName) => contentTypeName !== null)
                      )}
                      prefix="Format"
                      onChange={(filterKs) =>
                        setFilterKeys({
                          ...filterKeys,
                          contentTypeName: filterKs,
                        })
                      }
                    />
                  </GVDSTableCtrlContainer>

                  {totalDataLength === 0 && (
                    <div className="gvds-empty-content">
                      No guide matching your filter.
                    </div>
                  )}
                  {filteredSortedData.map((article) => {
                    return (
                      <GuidanceArticleListItemDisplay
                        key={article.id}
                        article={article}
                        resource_id={currentInventory.id}
                        resource_type={currentInventory.type}
                        onGuidanceUpdate={() => updateGuidanceArticle(article)}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="table__no_content mt-2">
                  <p>No Guide available.</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const GuidanceArticleListItemDisplay = ({
  article,
  resource_id,
  resource_type,
  onGuidanceUpdate,
}) => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();
  const location = useLocation();

  const createBookmark = (article) => {
    GuidanceArticleService.createBookmark(
      resource_id,
      resource_type,
      article.id
    )
      .then((response) => {
        article.isBookmarked = true;
        toastContext.addSuccessToast(<span>Bookmark added.</span>);
        onGuidanceUpdate();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to bookmark article.</span>);
      });
  };

  const deleteBookmark = (article) => {
    GuidanceArticleService.deleteBookmark(
      resource_id,
      resource_type,
      article.id
    )
      .then((response) => {
        article.isBookmarked = false;
        toastContext.addSuccessToast(<span>Bookmark removed.</span>);
        onGuidanceUpdate();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to remove article bookmark.</span>
        );
      });
  };

  const redirectToSingleArticle = (article) => {
    history.push(
      getRedirectURLWithCurrentParam(
        GuidanceArticleUtils.getArticleUrl(article.id),
        location
      )
    );
  };

  return (
    <div
      className={
        "guidance-library__article-list-display" +
        (article.isBookmarked ? " bookmarked" : "")
      }
      onClick={() => redirectToSingleArticle(article)}
    >
      <Row>
        <Col sm={4}>
          <div className="guidance-library__article__label">
            {article.article_type_name} • Last Updated on:{" "}
            {DateTimeUtils.formatUTCDate(article.lastEditedOn)}
          </div>
          <div className="guidance-library__article-title">
            {article.title}
            <ContentTypeDisplay contentTypeName={article.contentType?.name} />
          </div>
        </Col>
        <Col sm={3}>
          <div className="guidance-library__article__label">Tags</div>
          <div>
            {article.tags.map((tag) => (
              <GVDSTag key={tag.id}>{tag.name}</GVDSTag>
            ))}
          </div>
        </Col>
        <Col sm={3}>
          <div className="guidance-library__article__label">UN SDGs</div>
          <div>
            {article.unSdgs.map((un_sdg, index) => (
              <OverlayTrigger
                key={index}
                placement="top"
                overlay={<Tooltip id={un_sdg.id}>{un_sdg.description}</Tooltip>}
              >
                <GVDSTag key={un_sdg.id}>{un_sdg.name}</GVDSTag>
              </OverlayTrigger>
            ))}
          </div>
        </Col>

        <Col sm={2}>
          <div className="guidance-library__article__bookmark-container">
            {article.isBookmarked ? (
              <GVDSButton
                onClick={(e) => {
                  e.stopPropagation();
                  deleteBookmark(article);
                }}
                variant={buttonVariant.tertiary}
                text="Saved"
                icon={<GVDSIcon Icon={IconBookmarkFilled} />}
              />
            ) : (
              <GVDSButton
                onClick={(e) => {
                  e.stopPropagation();
                  createBookmark(article);
                }}
                variant={buttonVariant.tertiary}
                icon={<GVDSIcon Icon={IconBookmark} />}
                text="Bookmark"
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const CategoryFilterDisplay = ({ isSelected, categoryName, onClick }) => {
  return (
    <div
      className={
        isSelected
          ? "guidance-library__category-filter-display active"
          : "guidance-library__category-filter-display"
      }
      onClick={() => onClick(categoryName)}
    >
      <div className="best-practice-category-card__header">
        <Form.Check
          label=""
          type="checkbox"
          id={`option-${categoryName}`}
          checked={isSelected}
        />
        <div className="best-practice-category-card__name">{categoryName}</div>
      </div>
    </div>
  );
};

export default withAuthentication(BestPracticesGuidance);
