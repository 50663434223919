export class DashboardBarChartSharedUtils {
  static minimumBarSizeInPx = 4;

  static groupBars = (barStacks) => {
    const barGroups = [];

    barStacks.map((barStack, index) => {
      barStack.bars.map((bar, barIndex) => {
        let barGroup = barGroups[barIndex];
        if (!barGroup) {
          barGroup = [];
          barGroups.push(barGroup);
        }
        const barData = bar.bar.data;
        if (
          Object.keys(barData).includes(barStack.key) &&
          barData[barStack.key] !== 0
        ) {
          barGroup.push(bar);
        }
      });
    });

    return barGroups.filter((bg) => bg.length > 0);
  };
}
