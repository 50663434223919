import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../services/UtilsService";
import React from "react";

export const SUBSCRIPTION_PAYMENT = {
  PAID: "paid",
  PARTIALLY_PAID: "partially paid",
  UNPAID: "unpaid",
  FREE: "free",
};

const colorMap = {
  [SUBSCRIPTION_PAYMENT.PAID]: StatusLabel.Colors.green,
  [SUBSCRIPTION_PAYMENT.PARTIALLY_PAID]: StatusLabel.Colors.red,
  [SUBSCRIPTION_PAYMENT.UNPAID]: StatusLabel.Colors.red,
  [SUBSCRIPTION_PAYMENT.FREE]: StatusLabel.Colors.yellow,
};

const SubscriptionPaymentBadge = ({ payment, className }) => {
  return (
    <StatusLabel color={colorMap[payment]} className={className}>
      {StringUtils.capitaliseWord(payment)}
    </StatusLabel>
  );
};

export default SubscriptionPaymentBadge;
