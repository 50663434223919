import axios from "axios";
import { API_URL } from "../config/api-config";

class CertificationRequirementCommentModel {
  constructor(id, comment, createdBy, createdOn) {
    this.id = id;
    this.comment = comment;
    this.createdBy = createdBy;
    this.createdOn = createdOn;
  }

  static fromDTO(dto) {
    return new CertificationRequirementCommentModel(
      dto["id"],
      dto["comment"],
      dto["created_by"],
      dto["created_on"]
    );
  }
}

export class CertificationRequirementCommentService {
  static async addComment(
    resource_type,
    resource_id,
    certificationId,
    requirementId,
    comment
  ) {
    try {
      const data = { comment };

      const response = await axios.post(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}/comments`,
        data,
        { params: { resource_type, resource_id } }
      );
      return CertificationRequirementCommentModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  }

  static async getRequirementComments(
    resource_type,
    resource_id,
    certificationId,
    requirementId
  ) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}/comments`,
        { params: { resource_type, resource_id } }
      );
      return response.data.map((c) =>
        CertificationRequirementCommentModel.fromDTO(c)
      );
    } catch (error) {
      throw error;
    }
  }

  static async updateComment(
    resource_type,
    resource_id,
    certificationId,
    requirementId,
    commentId,
    comment
  ) {
    try {
      const data = { comment };

      const response = await axios.put(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}/comments/${commentId}`,
        data,
        { params: { resource_type, resource_id } }
      );
      return CertificationRequirementCommentModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  }

  static async deleteComment(
    resource_type,
    resource_id,
    certificationId,
    requirementId,
    commentId
  ) {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}/comments/${commentId}`,
        { params: { resource_type, resource_id } }
      );
      return null;
    } catch (error) {
      throw error;
    }
  }
}
