import React from "react";
import { DateTimeUtils } from "../../../services/UtilsService";
import SignUpStatusBadge from "./SignUpStatusBadge";
import withAuthentication from "../../HOC/withAuthentication";
import { getAdminViewSignUpRequest } from "../../../config/ROUTES_NAME";
import LoadingSpinner from "../../common/LoadingSpinner";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import uniq from "lodash/uniq";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconExternalLink } from "@tabler/icons-react";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";

const ViewAllSignUpRequestHistory = ({
  history,
  isLoading,
  historicalSignUpRequests,
}) => {
  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    historicalSignUpRequests,
    SystemToolboxSearchKeysByPageName.siteSignUpRequests
  );

  const goToRequest = (requestId) => {
    history.push(getAdminViewSignUpRequest(requestId));
  };

  const columns = [
    {
      header: "Request Date",
      headerStyle: { width: "300px" },
      dataKey: "request_date",
      sortable: true,
      renderer: (r) => {
        return <span>{DateTimeUtils.formatUTCDate(r.request_date)}</span>;
      },
    },
    {
      header: "User Email",
      headerStyle: { width: "300px" },
      dataKey: "email_address",
      sortable: true,
    },
    {
      header: "Site Name",
      headerStyle: { width: "300px" },
      dataKey: "site_name",
      sortable: true,
    },
    {
      header: "Status",
      headerStyle: { width: "300px" },
      dataKey: "status",
      sortable: true,
      renderer: (r) => {
        return <SignUpStatusBadge status={r.status} />;
      },
    },
    {
      header: "Reviewed On",
      headerStyle: { width: "300px" },
      dataKey: "review_date",
      sortable: true,
      renderer: (r) => {
        return <span>{DateTimeUtils.formatUTCDate(r.review_date)}</span>;
      },
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "160px" },
      renderer: (r) => {
        return (
          <GVDSIconButton
            variant={iconButtonVariant.tertiary}
            onClick={() => {
              goToRequest(r.id);
            }}
            icon={<GVDSIcon Icon={IconExternalLink} />}
            tooltipText="View Details"
          />
        );
      },
    },
  ];

  return (
    <div>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search for user email or site name"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(historicalSignUpRequests.map((r) => r.status))}
          prefix="Status"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, status: filterKs })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        onRowClick={(r) => goToRequest(r.id)}
      />
      {isLoading && <LoadingSpinner />}
      {!isLoading &&
      (!historicalSignUpRequests || historicalSignUpRequests.length === 0) ? (
        <div className="table__no_content">
          There are no past site sign-up requests.
        </div>
      ) : (
        filteredSortedData.length === 0 && (
          <div className="table__no_content">
            No past site sign-up requests. Try changing your filter.
          </div>
        )
      )}
    </div>
  );
};

export default withAuthentication(ViewAllSignUpRequestHistory);
