import { GVDSColors } from "../../../../styles/gvds-colors";

export const performanceTimelinePeriodGroupingEnum = {
  YEAR: "year",
  QUARTER: "quarter",
  MONTH: "month",
};

export const performanceTimelinePeriodGroupingOptions = [
  {
    value: performanceTimelinePeriodGroupingEnum.YEAR,
    label: "Year",
  },
  {
    value: performanceTimelinePeriodGroupingEnum.QUARTER,
    label: "Quarter",
  },
  {
    value: performanceTimelinePeriodGroupingEnum.MONTH,
    label: "Month",
  },
];

export const performanceTimelineChartMarginInPx = {
  top: 10,
  left: 60,
  right: 10,
  bottom: 50,
};

export const performanceTimelineChartMinimapMarginInPx = {
  top: 0,
  left: 70,
  right: 240,
  bottom: 30,
};

export const selectedChartMinimapStyle = {
  fill: GVDSColors.tealSolid,
  fillOpacity: 0.2,
  stroke: GVDSColors.tealSolid,
  strokeWidth: 2,
};

export const defaultPeriodGroupingOption =
  performanceTimelinePeriodGroupingOptions.find(
    (o) => o.value === performanceTimelinePeriodGroupingEnum.MONTH
  ).value;

export const performanceTimelineChartConfig = {
  chartHeightInPx: 300,
  minimapHeightInPx: 90,
  barWidthInPx: 24,
  minimapBarWidthInPx: 9,
  minimapBottomAxisScaleMarginInPx: 5,
  legendContainerWidthInPx: 240,
  gridLineCount: 5,
  maxBarCount: 12,
  typeColumnIndex: 0,
  chartViewId: "performance-timeline-chart-view-id",
  expandedChartViewId: "expanded-performance-timeline-chart-view-id",
  brushHandlePathWidthInPx: 8,
  brushHandlePathHeightInPx: 15,
  minimapBrushRepositionToleranceInPx: 2,
  minimapBrushResizeToleranceInPx: 1,
  miniViewExcludeOnDownloadContainerId:
    "performance-timeline-mini-view-exclude-on-download-container-id",
  expandedViewExcludeOnDownloadContainerId:
    "performance-timeline-expanded-view-exclude-on-download-container-id",
};
