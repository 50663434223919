import React from "react";
import {useLocation} from "react-router-dom";

import CloseButton from "react-bootstrap/CloseButton";

import GuidedTourMenu from "./GuidedTour/GuidedTourMenu";
import {getRedirectURLWithCurrentParam} from "../common/QueryHandler";
import {HELP} from "../../config/ROUTES_NAME";
import FloatingAssistantRelevantHelpArticles from "./FloatingAssistantRelevantHelpArticles";
import GVDSButton, {buttonVariant,} from "../../gvds-components/Buttons/GVDSButton";
import {useTranslation} from "react-i18next";

// Not using style={...props.style} as overlay trigger uses position absolute
// which would cause floating menu scroll with parent, in this case <body>, and recalculated on every scroll
// and causes jumps depending on scroll speed
const FloatingAssistantMenu = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const location = useLocation();

  const { onClose, startGuidedTour, helpArticlesByPath } = props;

  return (
    <div ref={ref} className="floating-assistant-menu__container">
      <div className="floating-assistant-menu">
        <div className="floating-assistant-menu__header">
          <h2>{t("top-nav.help")}</h2>
          <CloseButton
            aria-label="Close floating assistant"
            onClick={onClose}
          />
        </div>
        <div className="floating-assistant-menu__content">
          <GuidedTourMenu startGuidedTour={startGuidedTour} />
          <FloatingAssistantRelevantHelpArticles
            helpArticlesByPath={helpArticlesByPath}
          />
        </div>
        <div className="floating-assistant-menu__footer">
          <div className="mb-3">{t("floating-assistant.description")}</div>
          <div className="d-flex">
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={() =>
                window.open(
                  getRedirectURLWithCurrentParam(HELP, location),
                  "_blank"
                )
              }
              text={t("floating-assistant.button-go-to-help-center")}
            />
            <GVDSButton
              className="ms-auto"
              variant={buttonVariant.tertiary}
              onClick={() =>
                window.open("mailto:support@greenviewportal.com", "_blank")
              }
              text={t("floating-assistant.button-contact-us")}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default FloatingAssistantMenu;
