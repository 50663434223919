import React, { useContext, useEffect, useRef, useState } from "react";
import range from "lodash/range";
import groupBy from "lodash/groupBy";

import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import BulkDataInput, { BulkDataInputControls } from "../Data/BulkDataInput";
import BulkSitesInputService, {
  getSiteColumnLabels,
} from "./BulkSitesInputService";
import LoadingSpinner from "../common/LoadingSpinner";
import SiteBulkService from "../../services/SiteBulkService";
import BulkSitesInputErrorService from "./BulkSitesInputErrorService";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";

const INITIAL_GRID_SIZE = 10;

const siteHeaderRenderer = ({ className, children }) => {
  return (
    <div className={className}>
      <table>
        <thead>
          <tr>
            <th
              className="cell read-only"
              style={{ width: "2rem" }}
              rowSpan={2}
            />
            <th
              className="cell read-only"
              style={{ width: "2.5rem" }}
              rowSpan={2}
            >
              Row
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Site Name*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Site Type*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Comment
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Street Address*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              City*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              State / Province
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Postal Code*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Country*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Approved Email Domains for this Site only (comma-separated)
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Status
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Date Opened
            </th>
            <th className="cell read-only" colSpan={8}>
              Related Entities
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Currency of Utility Invoice*
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Metro Area
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Climate Zone(Köppen–Geiger)
            </th>
            <th
              className="cell read-only"
              style={{ width: "10rem" }}
              rowSpan={2}
            >
              Climate Zone(Bailey's Ecoregion)
            </th>
            <th className="cell read-only" colSpan={6}>
              Hotel / Accommodation Only
            </th>
          </tr>
          <tr>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Brand Company
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Brand Company ID Code
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Brand Flag
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Asset Owner Company
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Asset Owner Company ID Code
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Management Company
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Management Company ID Code
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Other Property Identifier Code
            </th>

            <th className="cell read-only" style={{ width: "10rem" }}>
              Expedia Stars
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Market Segment
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Hotel Type
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Service Type / Asset Class
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Main Laundry Wash Location
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Room Count
            </th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const BulkSitesInput = ({ onComplete }) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const bulkInputService = useRef(new BulkSitesInputService());
  const bulkInputErrorService = useRef(new BulkSitesInputErrorService());

  const contracts = userInventory.contracts.get;
  const [grid, setGrid] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [numOfSitesInputted, setNumOfSitesInputted] = useState();
  const [failedSites, setFailedSites] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(-1);
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    bulkInputService.current
      .getSelectionOptions()
      .then(() => {
        resetGrid();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load bulk input selection options.</span>
        );
      });
  }, []);

  useEffect(() => {
    if (rowToDelete >= 0) {
      const newGrid = bulkInputService.current.removeRow(
        rowToDelete,
        setRowToDelete,
        grid
      );
      setGrid(newGrid);
      setRowToDelete(-1);
    }
  }, [rowToDelete]);

  const resetGrid = () => {
    const initialGrid = range(INITIAL_GRID_SIZE).map((row) =>
      bulkInputService.current.getNewRow(row, setRowToDelete)
    );
    bulkInputErrorService.current.resetErrors();
    setHasEdited(false);
    setGrid(initialGrid);
  };

  const handleChanges = (changes = [], additions = []) => {
    setHasEdited(true);

    const totalChanges = [...changes, ...additions];
    const changedGrid = grid.map((row) => [...row]);
    const changesByRow = groupBy(totalChanges, (c) => c.row);

    for (const [row, rowChanges] of Object.entries(changesByRow)) {
      const rowData = changedGrid[row]
        ? changedGrid[row]
        : bulkInputService.current.getNewRow(row, setRowToDelete);
      changedGrid[row] = bulkInputService.current.updateRow(
        rowData,
        rowChanges
      );
    }
    setGrid(changedGrid);
  };

  const addNewRow = () => {
    const newRow = Object.values(grid).length;
    setGrid([
      ...grid,
      bulkInputService.current.getNewRow(newRow, setRowToDelete),
    ]);
  };

  const submitSites = async () => {
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const sites = changedGrid.map((rowData) =>
      bulkInputService.current.getSite(rowData)
    );
    setIsLoading(true);
    try {
      const result = await SiteBulkService.createSites(sites);
      setIsLoading(false);
      setNumOfSitesInputted(result["created_sites"].length);
      setFailedSites(
        result["failed_sites"].length > 0 ? result["failed_sites"] : null
      );
      resetGrid();
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to create sites.</span>);
    }
  };

  const validateBulkData = async () => {
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const sites = changedGrid.map((rowData) =>
      bulkInputService.current.getSite(rowData)
    );

    if (sites.length === 0) return;

    setIsLoading(true);
    try {
      const errors = await SiteBulkService.validateSites(sites);
      const newGrid = bulkInputService.current.updateGridWithErrors(
        changedGrid,
        bulkInputErrorService.current.prepareDataErrors(
          errors.dataErrors.rowErrors
        )
      );
      bulkInputErrorService.current.updateErrors(errors);
      setHasEdited(false);
      setGrid(newGrid);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to validate sites input.</span>);
    }
  };

  const resetCreatedSitesInformation = () => {
    setNumOfSitesInputted(null);
    setFailedSites(null);
  };

  let content;
  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    const columns = getSiteColumnLabels();
    const totalWidth = columns.reduce((total, num) => total + num.width, 0);
    content = (
      <BulkDataInput
        className="bulk-input-sites"
        grid={grid}
        hasChanges={
          hasEdited ||
          bulkInputErrorService.current.hasDataErrors() ||
          !bulkInputErrorService.current.disableSubmit()
        }
        columns={columns}
        onChange={handleChanges}
        headerRenderer={siteHeaderRenderer}
        width={`${totalWidth}rem`}
      />
    );
  }

  return (
    <div>
      <div className="body-2 mb-2">
        Ensure that the relevant client's company and brand flags have been
        created before continuing with this step of the onboarding process.
      </div>
      {contracts && (
        <>
          {bulkInputErrorService.current.hasDataErrors() && (
            <GVDSBanner
              title={`${bulkInputErrorService.current.dataErrorRows.length} issues detected.`}
              variant={GVDSBanner.Variants.error}
            >
              Please fix the following rows:{" "}
              {bulkInputErrorService.current.dataErrorRows
                .map((row) => Number(row) + 1)
                .join(", ")}
              <br />
              You may need to scroll to the right to see all the columns.
            </GVDSBanner>
          )}
          {bulkInputErrorService.current.hasNoDataErrors() && (
            <GVDSBanner
              title="No issues found."
              variant={GVDSBanner.Variants.success}
            ></GVDSBanner>
          )}

          <div className="d-flex flex-row-reverse align-items-center">
            {hasEdited && <div>Changes made! Validate before submitting.</div>}
          </div>
          <div className="bulk-input-ctrl__container">
            <BulkDataInputControls
              addNewRow={addNewRow}
              rowCount={grid.length}
              onClearAll={resetGrid}
            />
            <div className="bulk-input-ctrl__action-ctrl bulk-input-sites-buttons">
              <GVDSButton
                variant={buttonVariant.secondary}
                className="validate-bulk-data"
                onClick={validateBulkData}
                text="Check for Validation"
              />
              <GVDSButton
                variant={buttonVariant.primary}
                className="submit-bulk-data"
                onClick={submitSites}
                disabled={
                  bulkInputErrorService.current.disableSubmit() ||
                  hasEdited ||
                  isLoading
                }
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text="Create Sites"
              />
            </div>
          </div>
          {content}
        </>
      )}

      <GVDSModal
        show={numOfSitesInputted > 0}
        onHide={() => resetCreatedSitesInformation()}
        title="Bulk Input Success"
        s={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          You have created {numOfSitesInputted} Sites.{" "}
          {failedSites !== null && (
            <div>
              {failedSites.length === 1
                ? "This is the site "
                : "These are the sites "}
              that failed to be created: {failedSites.join(", ")}.
            </div>
          )}
          Click on the “Continue” button to continue with the onboarding
          process.
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => {
              resetCreatedSitesInformation();
              onComplete();
            }}
            text="Continue"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </div>
  );
};

export default BulkSitesInput;
