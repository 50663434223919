import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import { DateTimeUtils, UtilsService } from "../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import React, { useContext, useState } from "react";
import ToastContext from "../../../context/ToastContext";
import BillingService from "../../../services/BillingService";
import LoadingSpinner from "../../common/LoadingSpinner";
import Form from "react-bootstrap/Form";
import GVDSFormTextArea from "../../../gvds-components/Forms/GVDSFormTextArea";
import { CONTACT_EMAIL_SALES } from "../../../config/constants";
import { GVDSFormErrorMessage } from "../../../gvds-components/Forms/GVDSFormShared";

const cancellationReasons = [
  "Business closure or change in circumstances",
  "Difficult to use or understand",
  "Features are missing or not meeting needs",
  "Dissatisfied with customer support",
  "Not using Greenview Portal enough",
  "Too expensive",
  "No longer need Greenview Portal",
  "Found a better alternative",
];

export const CancelSubscriptionModal = ({
  showModal,
  closeModal,
  afterEndPeriodDate,
  onProceedingWithCancellation,
}) => {
  return (
    <GVDSModal
      title="Are you sure you want to cancel?"
      size={GVDSModal.Size.small}
      show={showModal}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        <div className="billing-subscription__cancel-subscription__container">
          <div className="header-text">
            Once your subscription ends on{" "}
            <strong>{DateTimeUtils.formatLocalDate(afterEndPeriodDate)}</strong>
            , you will lose access to all features except for your Billing
            History and Surveys.
          </div>
        </div>
        <div>
          If you need a custom plan,{" "}
          <a
            className="contact-sales-email"
            href={`mailto:${CONTACT_EMAIL_SALES}`}
            target="_blank"
          >
            contact sales
          </a>
          .
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          className="do-not-cancel-button"
          onClick={closeModal}
          text="Don't cancel"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          className="continue-to-cancel-button"
          onClick={onProceedingWithCancellation}
          text="Continue to cancel"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const CancellationFeedbackModal = ({
  showModal,
  closeModal,
  selectedSubscriptionId,
  resourceType,
  resourceId,
  onCancelSubscriptionSuccess,
}) => {
  const toastContext = useContext(ToastContext);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const isCancellationReasonValid = selectedIndexes.length > 0;

  const cancelSubscription = () => {
    setIsValidated(true);

    if (selectedIndexes.length > 0) {
      setIsLoading(true);

      const selectedCancellationReasons = cancellationReasons.filter(
        (element, index) => selectedIndexes.includes(index)
      );

      BillingService.cancelSubscription(
        resourceType,
        resourceId,
        selectedSubscriptionId,
        selectedCancellationReasons,
        feedback
      )
        .then(() => {
          toastContext.addSuccessToast(<span>Subscription cancelled</span>);
          setSelectedIndexes([]);
          setFeedback("");
          onCancelSubscriptionSuccess();
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to cancel subscription</span>);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <GVDSModal
      title="We're sorry to see you go"
      size={GVDSModal.Size.small}
      show={showModal}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="gvds-text--formLabel mb-2">
              Please let us know why you’ve decided to cancel
            </div>
            {cancellationReasons.map((r, index) => (
              <Form.Check
                key={index}
                id={r}
                label={r}
                type="checkbox"
                checked={selectedIndexes.includes(index)}
                onChange={() =>
                  setSelectedIndexes(
                    UtilsService.toggleItem(selectedIndexes, index)
                  )
                }
              />
            ))}
            {isValidated && !isCancellationReasonValid && (
              <GVDSFormErrorMessage errorMsg="Please select a reason." />
            )}
            <div className="gvds-text--formLabel mt-4">Feedback</div>
            <div className="gvds-text--body mb-2">
              Your feedback will help us improve the Greenview Portal
              experience.
            </div>
            <GVDSFormTextArea
              rows={3}
              value={feedback}
              onInput={(value) => setFeedback(value)}
              placeholder="Enter feedback"
            />
          </>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          className="do-not-cancel-button"
          onClick={closeModal}
          text="Don't cancel"
          disabled={isLoading}
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          className="confirm-cancel-button"
          onClick={cancelSubscription}
          text="Confirm Cancellation"
          disabled={isLoading}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};
