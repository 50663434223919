import {
  INITIATIVE_STATUS,
  INITIATIVE_SUBTOPIC,
  INITIATIVE_TRACKING_CRITERION,
  INITIATIVE_TRACKING_FIELD,
  new_entry_prefix,
  RESOURCES,
} from "../../../config/constants";
import { cloneDeep, uniqueId } from "lodash";
import { DateTimeUtils, StringUtils } from "../../../services/UtilsService";
import InitiativeService from "../../../services/InitiativeService";
import { FileAttachmentItemModel } from "../../common/FileAttachments";
import { GenericUserManagedTagModel } from "../../common/CentralTags/UserManagedTagModel";

/* Tracking Criteria data model:
     key-value pair where:
     - key = criterion name (see INITIATIVE_TRACKING_CRITERION)
     - value = array of source meter

   source meter data model:
     key-value pair where:
     - key = field name (see INITIATIVE_TRACKING_FIELD)
     - value = value if it's an option for user to fill up, else null
**/
export class InitiativeModel {
  constructor(
    id = uniqueId(new_entry_prefix),
    name = "",
    periodFrom = null,
    periodTo = null,
    status = "",
    lead = "",
    story = "",
    selectedPortfolioTags = [],
    selectedSiteTags = [],
    selectedUNSDGs = [],
    subtopic = null,
    partnerOrganisation = "",
    initiativeLifecycleInYears = null,
    estimatedAnnualSavings = null,
    annualSavingsCurrencyId = null,
    trackingCriteriaRecord = {},
    comments = null,
    attachedFiles = [],
    lastActivity = null
  ) {
    this.id = id;
    this.name = name;
    this.periodFrom = periodFrom;
    this.periodTo = periodTo;
    this.status = status;
    this.lead = lead;
    this.story = story;
    this.selectedPortfolioTags = selectedPortfolioTags;
    this.selectedSiteTags = selectedSiteTags;
    this.selectedUNSDGs = selectedUNSDGs;
    this.subtopic = subtopic;
    this.partnerOrganisation = partnerOrganisation;
    this.initiativeLifecycleInYears = initiativeLifecycleInYears;
    this.estimatedAnnualSavings = estimatedAnnualSavings;
    this.annualSavingsCurrencyId = annualSavingsCurrencyId;
    this.trackingCriteriaRecord = trackingCriteriaRecord;
    this.comments = comments;
    this.attachedFiles = attachedFiles;
    this.lastActivity = lastActivity;
  }

  get searchString() {
    return `[${this.selectedPortfolioTags
      .concat(this.selectedSiteTags)
      .map((t) => t.name)
      .join(", ")}]`;
  }

  get trackingCriteria() {
    return Object.keys(this.trackingCriteriaRecord)
      .sort(InitiativeService.initiativeTrackingCriteriaSortFn)
      .map((criterion) =>
        criterion === INITIATIVE_TRACKING_CRITERION.ACCOUNTING_INFORMATION
          ? "Financial Impact"
          : criterion
      );
  }

  get allTags() {
    return this.selectedPortfolioTags
      .map((t) => new GenericUserManagedTagModel(t.id, t.name, RESOURCES.PORTFOLIO))
      .concat(
        this.selectedSiteTags.map((t) =>
          new GenericUserManagedTagModel(t.id, t.name, RESOURCES.SITE)
        )
      );
  }

  get allTagSelectionValues() {
    return this.allTags.map((t) => t.selectionValue);
  }

  isNameNotEmpty() {
    return this.name && this.name.length > 0;
  }

  isStatusNotEmpty() {
    return this.status;
  }

  isPeriodFromNotEmpty() {
    return this.periodFrom;
  }

  isEndBeforeStart() {
    return this.periodFrom && this.periodTo && this.periodTo < this.periodFrom;
  }

  isPeriodToEmptyOnCompleted() {
    return this.status === INITIATIVE_STATUS.COMPLETED && !this.periodTo;
  }

  isStoryNotEmpty() {
    return this.story && this.story.length > 0;
  }

  isUNSDGNotEmpty() {
    return this.selectedUNSDGs && this.selectedUNSDGs.length > 0;
  }

  isEfficiencyInitiative() {
    return this.subtopic === INITIATIVE_SUBTOPIC.EFFICIENCY_AND_ENVIRONMENT;
  }

  isInitiativeLifecycleNegative() {
    return (
      this.initiativeLifecycleInYears && this.initiativeLifecycleInYears < 0
    );
  }

  isTrackingCriteriaValid() {
    const meterHasInvalidField = (meter) => {
      return Object.keys(meter).find((field) => {
        const isRequiredField =
          field === INITIATIVE_TRACKING_FIELD.SOURCE ||
          field === INITIATIVE_TRACKING_FIELD.CURRENCY ||
          field === INITIATIVE_TRACKING_FIELD.UNIT;

        const isValueField =
          field === INITIATIVE_TRACKING_FIELD.QUANTITY ||
          field === INITIATIVE_TRACKING_FIELD.VALUE ||
          field === INITIATIVE_TRACKING_FIELD.PARTICIPANT ||
          field === INITIATIVE_TRACKING_FIELD.VOLUNTEER_HOURS ||
          field === INITIATIVE_TRACKING_FIELD.VOLUNTEER_HOURLY_RATE ||
          field === INITIATIVE_TRACKING_FIELD.ANNUAL_REDUCTION;

        if (isRequiredField && !meter[field]) {
          return true;
        } else if (
          isValueField &&
          !InitiativeModel.isValueValid(meter[field])
        ) {
          return true;
        }
        return false;
      });
    };

    return !Object.keys(this.trackingCriteriaRecord).find((criterion) => {
      const sourceMeters = this.trackingCriteriaRecord[criterion];
      return sourceMeters.find((meter) => {
        return meterHasInvalidField(meter);
      });
    });
  }

  isValid() {
    return (
      this.isNameNotEmpty() &&
      this.subtopic &&
      this.isStoryNotEmpty() &&
      this.isTrackingCriteriaValid() &&
      !this.isInitiativeLifecycleNegative() &&
      this.isStatusNotEmpty() &&
      this.isPeriodFromNotEmpty() &&
      !this.isEndBeforeStart() &&
      !this.isPeriodToEmptyOnCompleted()
    );
  }

  toRequestPayload() {
    // input type number will return "" on clearing input, which will be rejected by API field validation
    // hence the ternary operator to set to null on some float fields below
    return {
      name: this.name,
      status: this.status,
      lead: this.lead,
      initiative_subtopic: this.subtopic,
      story: this.story,
      portfolio_tags: this.selectedPortfolioTags,
      site_tags: this.selectedSiteTags,
      un_sdg: this.selectedUNSDGs,
      partner_organisation: this.partnerOrganisation,
      initiative_lifecycle_in_years: this.initiativeLifecycleInYears
        ? this.initiativeLifecycleInYears
        : null,
      annual_savings_currency_id: this.annualSavingsCurrencyId,
      estimated_annual_savings: this.estimatedAnnualSavings
        ? this.estimatedAnnualSavings
        : null,
      period_from: DateTimeUtils.getUTCISOString(this.periodFrom),
      period_to: this.periodTo
        ? DateTimeUtils.getUTCISOString(this.periodTo)
        : null,
      comments: this.comments,
      tracking_criteria_record: this.trackingCriteriaRecord,
    };
  }

  static fromDTO(initiativeDTO) {
    return new InitiativeModel(
      initiativeDTO.id,
      initiativeDTO.name,
      DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
        initiativeDTO.period_from
      ),
      initiativeDTO.period_to
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            initiativeDTO.period_to
          )
        : null,
      initiativeDTO.status,
      initiativeDTO.lead,
      initiativeDTO.story,
      initiativeDTO.portfolio_tags,
      initiativeDTO.site_tags,
      initiativeDTO.un_sdg,
      initiativeDTO.initiative_subtopic,
      initiativeDTO.partner_organisation,
      initiativeDTO.initiative_lifecycle_in_years,
      initiativeDTO.estimated_annual_savings,
      initiativeDTO.annual_savings_currency_id,
      initiativeDTO.tracking_criteria_record,
      initiativeDTO.comments,
      initiativeDTO.attached_files.map((file) =>
        FileAttachmentItemModel.fromFileDTO(file)
      ),
      initiativeDTO.last_activity
    );
  }

  copyInitiative(existingInitiativeNames) {
    const originalInitiativeModel = cloneDeep(this);
    return new InitiativeModel(
      null,
      StringUtils.getCopyNameFromCurrentNameAndExistingNames(
        this.name,
        existingInitiativeNames
      ),
      originalInitiativeModel.periodFrom,
      originalInitiativeModel.periodTo,
      originalInitiativeModel.status,
      originalInitiativeModel.lead,
      originalInitiativeModel.story,
      originalInitiativeModel.selectedPortfolioTags,
      originalInitiativeModel.selectedSiteTags,
      originalInitiativeModel.selectedUNSDGs,
      originalInitiativeModel.subtopic,
      originalInitiativeModel.partnerOrganisation,
      originalInitiativeModel.initiativeLifecycleInYears,
      originalInitiativeModel.estimatedAnnualSavings,
      originalInitiativeModel.annualSavingsCurrencyId,
      originalInitiativeModel.trackingCriteriaRecord,
      originalInitiativeModel.comments,
      [],
      originalInitiativeModel.lastActivity
    );
  }

  static createNewTrackingMeter(
    fields,
    sourceSelected = null,
    currencyIdSelected = null
  ) {
    const newTrackingSource = {};
    fields.forEach((field) => {
      newTrackingSource[field] = null;

      if (field === INITIATIVE_TRACKING_FIELD.SOURCE && sourceSelected) {
        newTrackingSource[field] = sourceSelected;
      }

      if (field === INITIATIVE_TRACKING_FIELD.CURRENCY && currencyIdSelected) {
        newTrackingSource[field] = currencyIdSelected;
      }
    });
    return newTrackingSource;
  }

  static copyTrackingCriteriaRecordFromSharedTrackingCriteria(
    newSubtopicTrackingCriteriaOptions,
    newSubtopicTrackingCriteriaRecord,
    prevSubtopicTrackingCriteriaRecord,
    defaultCurrencyId
  ) {
    Object.keys(newSubtopicTrackingCriteriaOptions).forEach((criterion) => {
      if (prevSubtopicTrackingCriteriaRecord[criterion]) {
        newSubtopicTrackingCriteriaRecord[criterion] =
          prevSubtopicTrackingCriteriaRecord[criterion];
      } else if (
        criterion === INITIATIVE_TRACKING_CRITERION.ACCOUNTING_INFORMATION &&
        !newSubtopicTrackingCriteriaRecord[
          INITIATIVE_TRACKING_CRITERION.ACCOUNTING_INFORMATION
        ]
      ) {
        newSubtopicTrackingCriteriaRecord[criterion] = [];
        newSubtopicTrackingCriteriaOptions[criterion][
          "options"
        ].sources.forEach((source) => {
          const newTrackingMeter = InitiativeModel.createNewTrackingMeter(
            newSubtopicTrackingCriteriaOptions[criterion]["options"].fields,
            source,
            defaultCurrencyId
          );
          newSubtopicTrackingCriteriaRecord[criterion].push(newTrackingMeter);
        });
      }
    });

    return newSubtopicTrackingCriteriaRecord;
  }

  static isValueValid = (value) => {
    if (value === null || value === "" || isNaN(value)) {
      // will be NaN on form type number being cleared
      return true;
    } else {
      return value >= 0;
    }
  };
}
