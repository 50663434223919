import { GVDSColors } from "../../../../styles/gvds-colors";
import { Group } from "@visx/group";
import { BarStack } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import React, { Fragment, useMemo, useRef, useState } from "react";
import {
  dashboardChartBackgroundColor,
  DashboardColorPalette,
} from "../DashboardColorPalette";
import { GridRows } from "@visx/grid";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";

import { NumberService, StringUtils } from "../../../../services/UtilsService";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { PerformanceTimelineChartUtils } from "./PerformanceTimelineChartUtils";
import { performanceTimelineChartConfig } from "./PerformanceTimelineViewConfig";
import {
  barChartMinimapRadius,
  barChartRadius,
  DashboardEnvClassificationDataTooltipModel,
} from "../../Models/DashboardModels";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";
import { DashboardBarChartSharedUtils } from "../DashboardBarChartSharedUtils";
import DashboardEnvClassificationDataTooltip from "../DashboardEnvClassificationDataTooltip";

const StackedBarTooltipContent = ({
  periodType,
  period,
  types,
  barData,
  unit,
  intensityUnit,
}) => {
  const displayedUnit = intensityUnit ? `${unit}/${intensityUnit}` : unit;
  const amountContents = types.map((type, index) => {
    const barValue = barData[type] === undefined ? 0 : barData[type];

    return new DashboardEnvClassificationDataTooltipModel(
      barValue,
      displayedUnit,
      type,
      DashboardWidgetSharedUtils.calculatePercentage(
        barValue,
        barData.periodTotalValue
      ),
      DashboardColorPalette[index]
    );
  });

  return (
    <div className="dashboard--chart__popover-tooltip-container">
      <div className="popover-tooltip-label">
        {StringUtils.getTitleCase(periodType)}
      </div>
      <div className="popover-tooltip-value">
        <strong>{period}</strong>
      </div>
      <div className="popover-tooltip-label">Amount</div>
      <DashboardEnvClassificationDataTooltip contents={amountContents} />
      <div className="popover-tooltip-label">Total</div>
      <div className="popover-tooltip-value">
        <strong>{NumberService.format(barData.periodTotalValue)}</strong>{" "}
        {displayedUnit}
      </div>
    </div>
  );
};

const VerticalSingleBar = ({
  xPosition,
  yPosition,
  barWidth,
  barHeight,
  barRadius = 0,
  barColor,
  isShowSeparator = false,
  barSeparatorWidth = 0,
  barSeparatorColor = GVDSColors.white,
}) => {
  const svgPathData = PerformanceTimelineChartUtils.getSVGPathData(
    xPosition,
    yPosition,
    barWidth,
    barHeight,
    barRadius
  );

  return (
    <>
      <svg width={barWidth + xPosition} height={barHeight + yPosition}>
        <path d={svgPathData} fill={barColor} stroke={barColor} />
      </svg>
      {isShowSeparator && (
        <rect
          x={xPosition}
          y={yPosition + barHeight}
          height={barSeparatorWidth}
          width={barWidth}
          fill={barSeparatorColor}
        />
      )}
    </>
  );
};

const VerticalStackedBar = ({ barGroup, tooltipContent, isCompact }) => {
  const ref = useRef(null);
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  return (
    <>
      <Group
        innerRef={ref}
        onMouseOver={() => setIsShowTooltip(true)}
        onMouseLeave={() => setIsShowTooltip(false)}
      >
        {barGroup.map((bar, index) => {
          let barHeight = bar.height;
          let yPosition = bar.y;
          const isLastBar = index === barGroup.length - 1;
          const isBarHeightUnderMinimumSize =
            barHeight < DashboardBarChartSharedUtils.minimumBarSizeInPx;

          if (isLastBar && isBarHeightUnderMinimumSize) {
            barHeight = DashboardBarChartSharedUtils.minimumBarSizeInPx;
            yPosition =
              yPosition +
              bar.height -
              DashboardBarChartSharedUtils.minimumBarSizeInPx;
          }

          return (
            <VerticalSingleBar
              key={index}
              xPosition={
                bar.x +
                bar.width / 2 -
                (isCompact
                  ? 0
                  : performanceTimelineChartConfig.barWidthInPx / 2)
              }
              yPosition={yPosition}
              barWidth={
                isCompact
                  ? performanceTimelineChartConfig.minimapBarWidthInPx
                  : performanceTimelineChartConfig.barWidthInPx
              }
              barHeight={barHeight}
              barRadius={
                index < barGroup.length - 1
                  ? 0
                  : isCompact
                  ? barChartMinimapRadius
                  : barChartRadius
              }
              barColor={bar.color}
            />
          );
        })}
      </Group>
      <Overlay show={isShowTooltip} placement="top" target={ref.current}>
        <Popover>
          <Popover.Body>
            <div>{tooltipContent}</div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

const VerticalStackedBarChart = ({
  width,
  height,
  margin,
  chartData,
  chartKeys,
  chartColors,
  yAxisScaleMaxValue,
  intensityUnit,
  unit,
  selectedPeriod,
  isCompact = false,
  children,
}) => {
  const xMax = Math.max(width - margin.left - margin.right);
  const yMax = Math.max(height - margin.top - margin.bottom);

  const types = chartKeys;
  const colors = chartColors;

  const additionalYAxisScale = 0.1 * yAxisScaleMaxValue; //to trigger the next grid line

  const xAxisScale = useMemo(
    () =>
      scaleBand({
        domain: chartData.map((d) => d.groupName),
        range: [0, xMax],
        round: true,
        padding: 0.2,
      }),
    [chartData, xMax]
  );

  const chartMinimapXAxisScale = useMemo(
    () =>
      scaleOrdinal({
        range: PerformanceTimelineChartUtils.getOrdinalScale(
          0,
          xMax,
          chartData.length
        ),
        domain: chartData.map((d) => d.period),
      }),
    [xMax, chartData]
  );

  const yAxisScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, yAxisScaleMaxValue + additionalYAxisScale],
        range: [yMax, 0],
        round: true,
        nice: true,
      }),
    [yAxisScaleMaxValue, additionalYAxisScale, yMax]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: types,
        range: colors,
      }),
    [types, colors]
  );

  const bottomAxisScale = useMemo(
    () =>
      scaleBand({
        domain: chartData.map((d) => d.groupName),
        range: [0, xMax],
        round: true,
        padding: 0.2,
      }),
    [chartData, xMax]
  );

  const bottomChartMinimapAxisScale = useMemo(
    () =>
      scaleOrdinal({
        domain: chartData.map((d) => d.groupName),
        range: PerformanceTimelineChartUtils.getOrdinalScale(
          performanceTimelineChartConfig.minimapBottomAxisScaleMarginInPx,
          xMax +
            performanceTimelineChartConfig.minimapBottomAxisScaleMarginInPx,
          chartData.length
        ),
      }),
    [chartData, xMax]
  );

  const yAxisLabel = intensityUnit
    ? `Intensity (${unit}/${intensityUnit})`
    : unit;

  return (
    <div>
      <svg width={width} height={height}>
        <rect
          width={width}
          height={height}
          fill={dashboardChartBackgroundColor}
        />
        {!isCompact && (
          <GridRows
            numTicks={performanceTimelineChartConfig.gridLineCount}
            top={margin.top}
            left={margin.left}
            scale={yAxisScale}
            width={xMax}
            stroke={GVDSColors.gray4}
          />
        )}
        <Group top={margin.top} left={margin.left}>
          <BarStack
            data={chartData}
            keys={types}
            height={yMax}
            x={(datum) => datum.groupName}
            xScale={isCompact ? chartMinimapXAxisScale : xAxisScale}
            yScale={yAxisScale}
            color={colorScale}
          >
            {(barStacks) => {
              const barGroups =
                DashboardBarChartSharedUtils.groupBars(barStacks);

              const bars = barGroups.map((barGroup, index) => {
                const barData = barGroup[0].bar.data;

                return (
                  <VerticalStackedBar
                    key={index}
                    barGroup={barGroup}
                    isCompact={isCompact}
                    tooltipContent={
                      <StackedBarTooltipContent
                        periodType={selectedPeriod}
                        period={barData.groupName}
                        barData={barData}
                        types={types}
                        unit={unit}
                        intensityUnit={intensityUnit}
                      />
                    }
                  />
                );
              });
              return (
                <>
                  {bars}
                  {children}
                </>
              );
            }}
          </BarStack>
          {isCompact ? (
            <AxisBottom
              hideAxisLine
              hideTicks
              top={yMax}
              scale={bottomChartMinimapAxisScale}
            />
          ) : (
            <>
              <AxisLeft
                hideAxisLine
                hideTicks
                scale={yAxisScale}
                numTicks={performanceTimelineChartConfig.gridLineCount}
                label={yAxisLabel}
                labelClassName="chart-axis-label"
                tickFormat={(value) => NumberService.humanize(value)}
              />
              <AxisBottom
                hideAxisLine
                hideTicks
                top={yMax}
                scale={bottomAxisScale}
                label={StringUtils.getTitleCase(selectedPeriod)}
                labelClassName="chart-axis-label"
                labelOffset={12}
              />
            </>
          )}
        </Group>
      </svg>
    </div>
  );
};

export default VerticalStackedBarChart;
