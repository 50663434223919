import axios from "axios";
import { API_URL } from "../../config/api-config";
import { DateTimeUtils, HttpResponseService } from "../UtilsService";

export default class StandardReportService {
  static getReportConfig = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/report_config/units",
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static getStandardReportRequestParam = (
    resource_id,
    resource_type,
    start,
    end,
    energyUnit,
    wasteUnit,
    waterUnit,
    emissionUnit,
    areaUnit,
    currencyId,
    refrigerantsUnit,
    use_location_based
  ) => ({
    resource_id,
    resource_type,
    start: DateTimeUtils.getUTCISOString(start),
    end: DateTimeUtils.getUTCISOString(end),
    energyUnit,
    wasteUnit,
    waterUnit,
    emissionUnit,
    areaUnit,
    currencyId: currencyId,
    refrigerantsUnit,
    use_location_based,
  });

  static getStandardReportStatus = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/standard/status",
        {
          params: params,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };
  static downloadStandardReport = async (params) => {
    try {
      const response = await axios.get(API_URL + "/api/v1/reports/standard", {
        params: params,
        responseType: "blob", // important
      });
      HttpResponseService.downloadFileFromResponse(
        response,
        "Standard Summary Report.xlsx"
      );
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static generateMonthlyDataBreakDown = async ({
    resource_id,
    resource_type,
    start,
    end,
    energyUnit,
    wasteUnit,
    waterUnit,
    emissionUnit,
    areaUnit,
    currencyId,
    refrigerantsUnit,
  }) => {
    const params = {
      resource_id,
      resource_type,
      start,
      end,
      energyUnit,
      wasteUnit,
      waterUnit,
      emissionUnit,
      areaUnit,
      currencyId,
      refrigerantsUnit,
    };
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/monthly_data_breakdown",
        {
          params: params,
          responseType: "blob", // important
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Monthly Data Breakdown Report.xlsx"
      );
    } catch (error) {
      throw error.response;
    }
  };

  static generateDataDump = async ({
    resource_id,
    resource_type,
    start,
    end,
    energyUnit,
    wasteUnit,
    waterUnit,
    emissionUnit,
    areaUnit,
    currencyId,
    refrigerantsUnit,
  }) => {
    const params = {
      resource_id,
      resource_type,
      start,
      end,
      energyUnit,
      wasteUnit,
      waterUnit,
      emissionUnit,
      areaUnit,
      currencyId,
      refrigerantsUnit,
    };
    try {
      const response = await axios.get(API_URL + "/api/v1/reports/data_dump", {
        params: params,
        responseType: "blob", // important
      });
      HttpResponseService.downloadFileFromResponse(
        response,
        "Data Dump Report.xlsx"
      );
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };
}
