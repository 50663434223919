import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const iconButtonVariant = {
  secondary: "gvds-icon-button__secondary",
  tertiary: "gvds-icon-button__tertiary",
  destructive: "gvds-icon-button__destructive",
};

const GVDSIconButton = React.forwardRef(
  (
    {
      variant,
      icon,
      tooltipText,
      tooltipDelay = 0,
      placement = "top",
      onClick,
      className,
      disabled = false,
    },
    ref
  ) => {
    const onClickLoseFocus = (e) => {
      e.currentTarget.blur();
      onClick(e);
    };

    return tooltipText ? (
      <OverlayTrigger
        delay={tooltipDelay}
        placement={placement}
        overlay={<Tooltip>{tooltipText}</Tooltip>}
      >
        <Button
          ref={ref}
          className={`gvds-button ${variant} ${className ?? ""}`}
          onClick={onClickLoseFocus}
          disabled={disabled}
        >
          {icon}
        </Button>
      </OverlayTrigger>
    ) : (
      <Button
        ref={ref}
        className={`gvds-button ${variant} ${className ?? ""}`}
        onClick={onClickLoseFocus}
        disabled={disabled}
      >
        {icon}
      </Button>
    );
  }
);

export default GVDSIconButton;
