import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { uniqueId } from "lodash";

import CertificationService from "../../../../services/ReferenceDataServices/CertificationService";
import { REFERENCE_DATA } from "../../../../config/ROUTES_NAME";
import LoadingSpinner from "../../../common/LoadingSpinner";
import InputCertificationsDialog from "./InputCertificationsDialog";
import FilterSearchBox from "../../../../gvds-components/common/FilterSearchBox";
import {
  new_entry_prefix,
  tobe_deleted_entry_prefix,
} from "../../../../config/constants";
import ToastContext from "../../../../context/ToastContext";
import DeleteCertificationPrompt from "./DeleteCertificationPrompt";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSTag from "../../../../gvds-components/common/GVDSTag";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../../config/search-config";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import Container from "react-bootstrap/Container";

class CertificationData {
  constructor(id = uniqueId(new_entry_prefix), name, status_options) {
    this.id = id;
    this.name = name;
    this.status_options = status_options;
  }

  isNewAndEmpty = () => {
    return this.id.startsWith(new_entry_prefix) && !this.name;
  };

  isEmpty = () => {
    return !this.name || this.name.length === 0;
  };
}

const isLevelInputted = (inputList) => {
  return (
    inputList.filter(
      (option) => !option.id.startsWith(tobe_deleted_entry_prefix)
    ).length > 0
  );
};

const Certifications = () => {
  const history = useHistory();
  const [certifications, setCertifications] = useState([]);
  const [show, setShowInputModal] = useState(false);

  const [isEditExisting, setIsEditExisting] = useState(false);
  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState([]);
  const [certificationName, setCertificationName] = useState("");
  const [optionToBeDeleted, setOptionToBeDeleted] = useState(null);
  const [showDeleteOptionPrompt, setShowDeleteOptionPrompt] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [certificationId, setCertificationId] = useState("");
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const [
    currentlyToBeDeletedRecordModels,
    setCurrentlyToBeDeletedCertification,
  ] = useState("");
  const [isValidated, setIsValidated] = useState(false);

  const [deleteCertification, setDeleteCertification] = useState(false);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    certifications,
    SystemToolboxSearchKeysByPageName.referenceData.certification,
    {
      name: SORTING_TYPES.asc,
    }
  );

  const loadRecord = () => {
    setIsLoading(true);
    CertificationService.GetAllCertifications()
      .then((certification) => {
        setIsLoading(false);
        setCertifications(certification);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load certifications.</span>);
      });
  };

  useEffect(() => {
    loadRecord();
  }, []);

  const columns = [
    {
      header: "Certification Name",
      dataKey: "name",
      sortable: true,
    },
    {
      header: "Levels",
      dataKey: "status_options",
      renderer: (q) =>
        q.status_options.map((status_option) => (
          <GVDSTag variant={GVDSTag.Variants.system} key={status_option.id}>
            {status_option.name}
          </GVDSTag>
        )),
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (q) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={(e) => {
                e.preventDefault();
                showEditRecord(q);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                promptDeleteRecord(q);
              }}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          </>
        );
      },
    },
  ];

  const openModal = () => {
    setCertificationName("");
    setInputList([]);
    setShowInputModal(true);
    setIsEditExisting(false);
    setIsValidated(false);
  };

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  const cancelDialog = () => {
    setShowInputModal(false);
  };

  const resetFields = () => {
    setShowInputModal(false);
  };

  const showEditRecord = (certification) => {
    const filterCertification = certifications.filter(
      (cert) => cert.id === certification.id
    );
    const status_options = filterCertification[0].status_options;
    const certificate = filterCertification[0].certificate;
    status_options.map((status_option) => {
      certificate.map((item) => {
        if (item.status.name === status_option.name) {
          status_option.edit_status = true;
        }
      });
    });
    setInputList(status_options);
    setShowInputModal(true);
    setIsEditExisting(true);
    setCertificationName(certification.name);
    setCertificationId(certification.id);
    setIsValidated(false);
  };

  // handle click event of the Add button
  const addOption = () => {
    setInputList([...inputList, new CertificationData()]);
  };

  const handleInputChangeName = (e) => {
    setCertificationName(e.target.value);
  };

  const cancelDeleteOptionPrompt = () => {
    setShowDeleteOptionPrompt(false);
    setOptionToBeDeleted(null);
  };

  const removeOption = (option) => {
    if (option.id.startsWith(new_entry_prefix)) {
      const list = [...inputList];
      list.splice(list.indexOf(option), 1);
      setInputList(list);
    } else {
      setOptionToBeDeleted(option);
      setShowDeleteOptionPrompt(true);
    }
  };

  const deleteExistingOption = () => {
    optionToBeDeleted.id = tobe_deleted_entry_prefix + optionToBeDeleted.id;
    setInputList([...inputList]);
    setOptionToBeDeleted(null);
    setShowDeleteOptionPrompt(false);
  };

  const handleInputChange = (e, id) => {
    const list = [...inputList];
    const selectedOption = list.find((option) => option.id === id);
    selectedOption.name = e.target.value;
    setInputList(list);
  };

  const isInputvalid = () => {
    return (
      certificationName !== "" &&
      isLevelInputted(inputList) &&
      !inputList.some((option) => option.name === "")
    );
  };

  const saveCertification = async (event) => {
    setIsValidated(true);

    if (isInputvalid()) {
      event.preventDefault();
      try {
        if (isEditExisting) {
          await CertificationService.updateCertification(
            certificationId,
            certificationName,
            inputList
          );
          toastContext.addSuccessToast(
            <span>Certification was successfully updated.</span>
          );
        } else {
          await CertificationService.createCertification(
            certificationName,
            inputList
          );
          toastContext.addSuccessToast(
            <span>Certification was successfully added.</span>
          );
        }
        resetFields();
        loadRecord();
      } catch (error) {
        isEditExisting
          ? toastContext.addFailToast(
              <span>Failed to update certification.</span>
            )
          : toastContext.addFailToast(
              <span>Failed to add certification.</span>
            );
      }
    }
  };

  const promptDeleteRecord = async (certification) => {
    setCurrentlyToBeDeletedCertification(certification.id);
    setIsLoading(true);
    try {
      const response = await CertificationService.getCertificationById(
        certification.id
      );
      setIsLoading(false);
      if (response) {
        setDeleteCertification(false);
      } else {
        setDeleteCertification(true);
      }
      setShowPromptDeleteModal(true);
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(
        <span>Failed to load certification detail.</span>
      );
    }
  };

  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  const deleteCurrentlySelectedCertification = () => {
    CertificationService.deleteDataRecords(
      currentlyToBeDeletedRecordModels
    ).then(() => {
      toastContext.addSuccessToast(
        <span>Certification was successfully deleted.</span>
      );
      loadRecord();
      handleClosePromptDeleteModal();
    });
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return To Reference Data"
          onClick={goToReferenceData}
        />
        <PageHeader.Title>
          <h1>Certifications</h1>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => openModal()}
            className="add-data"
            text="Create Certification"
            icon={<GVDSIcon Icon={IconCirclePlus} />}
          />
        </PageHeader.Title>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search by Certification"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {filteredSortedData.length === 0 && (
        <div className="table__no_content">No record found</div>
      )}

      <InputCertificationsDialog
        show={show}
        cancel={cancelDialog}
        isEditExisting={isEditExisting}
        handleInputChange={handleInputChange}
        removeOption={removeOption}
        addOption={addOption}
        handleInputChangeName={handleInputChangeName}
        inputList={inputList}
        certificationName={certificationName}
        saveCertification={saveCertification}
        isValidated={isValidated}
      />

      <DeleteCertificationPrompt
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        deleteCertification={deleteCertification}
        proceed={deleteCurrentlySelectedCertification}
      />
      <GVDSModal
        show={showDeleteOptionPrompt}
        onHide={cancelDeleteOptionPrompt}
        title="Confirm Delete Certification Level."
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          <p>
            No sites in the portal are currently associated with this
            Certification Level. Are you sure you want to delete{" "}
            <strong>{optionToBeDeleted?.name}</strong> ?
          </p>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancelDeleteOptionPrompt}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteExistingOption}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </Container>
  );
};

export default Certifications;
