import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import {
  IconCirclePlus,
  IconCopy,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react";
import LoadingSpinner from "../../../common/LoadingSpinner";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import FilterSearchBox from "../../../../gvds-components/common/FilterSearchBox";
import GVDSTableCtrlMultiSelect from "../../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import { uniq } from "lodash";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../../gvds-components/Table/GVDSTable";
import Container from "react-bootstrap/Container";
import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import HomepageAnnouncementAdminService, {
  HomepageAnnouncementStatusEnum,
} from "../../../../services/HomepageAnnouncementAdminService";
import HomepageAnnouncementInputModal from "./HomepageAnnouncementInputModal";
import LastActivityDisplay from "../../../common/LastActivityDisplay";
import StatusLabel from "../../../../gvds-components/common/StatusLabel";
import {
  getStatusLabelColor,
  getStatusOptionTransformer,
} from "./HomepageAnnouncementSharedUtils";
import { SystemToolboxSearchKeysByPageName } from "../../../../config/search-config";
import { StringUtils } from "../../../../services/UtilsService";
import DeleteHomepageAnnouncementPrompt from "./DeleteHomepageAnnouncementPrompt";
import CopyHomepageAnnouncementPrompt from "./CopyHomepageAnnouncementPrompt";

const getPopupStatusLabelColor = (status) => {
  if (status === HomepageAnnouncementStatusEnum.ACTIVE) {
    return StatusLabel.Colors.blue;
  } else {
    return StatusLabel.Colors.gray;
  }
};

const getTitleDescriptionDisplay = (
  title,
  description,
  isOneTimePopup,
  status
) => {
  return (
    <div className="system-toolbox--homepage-announcement__table__title-display">
      <div className="gvds-text--inputField">{title}</div>
      <div className="gvds-text--caption">{description}</div>
      {isOneTimePopup && (
        <StatusLabel color={getPopupStatusLabelColor(status)}>
          Show as popup
        </StatusLabel>
      )}
    </div>
  );
};

const EmptyHomepageAnnouncementListPlaceholder = () => {
  return (
    <div className="system-toolbox--homepage-announcement__empty-list__placeholder">
      <div className="gvds-text--heading3">There are no announcement</div>
      <div className="mt-3">
        <p>You can create a new announcement to share information with users</p>
      </div>
    </div>
  );
};

const HomepageAnnouncement = () => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [showInputModal, setShowInputModal] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showCopyPrompt, setShowCopyPrompt] = useState(false);

  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const [announcements, setAnnouncements] = useState([]);

  const loadAnnouncements = () => {
    setIsLoading(true);

    HomepageAnnouncementAdminService.getAnnouncements()
      .then((data) => {
        setAnnouncements(
          data.sort(HomepageAnnouncementAdminService.announcementSortFn)
        );
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to load landing page announcements.</span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCopy = () => {
    const newCopiedAnnouncement = currentAnnouncement.copyAnnouncement();
    setCurrentAnnouncement(newCopiedAnnouncement);
    setShowInputModal(true);
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    announcements,
    SystemToolboxSearchKeysByPageName.homepageAnnouncement
  );

  useEffect(() => {
    loadAnnouncements();
  }, []);

  const columns = [
    {
      header: "Title & Description",
      headerStyle: { width: "25%" },
      dataKey: "title",
      sortable: true,
      renderer: (announcement) => {
        return getTitleDescriptionDisplay(
          announcement.title,
          announcement.plainTextDescription,
          announcement.isOneTimePopup,
          announcement.status
        );
      },
    },
    {
      header: "CTA Buttons",
      headerStyle: { width: "15%" },
      dataKey: "firstCtaText",
      sortable: true,
      renderer: (announcement) => {
        return (
          <div className="d-flex flex-column gap-2">
            <div>{announcement.firstCtaText}</div>
            <div>{announcement.secondCtaText}</div>
          </div>
        );
      },
    },
    {
      header: "Start Period",
      headerStyle: { width: "10%" },
      dataKey: "startDate",
      sortable: true,
      renderer: (announcement) => {
        return announcement.startPeriodDisplay;
      },
    },
    {
      header: "End Period",
      headerStyle: { width: "10%" },
      dataKey: "endDate",
      sortable: true,
      renderer: (announcement) => {
        return announcement.endPeriodDisplay;
      },
    },
    {
      header: "Last Updated By",
      headerStyle: { width: "15%" },
      dataKey: "updatedOn",
      sortable: true,
      renderer: (announcement) => {
        return <LastActivityDisplay lastActivity={announcement.lastActivity} />;
      },
    },
    {
      header: "Status",
      headerStyle: { width: "10%" },
      dataKey: "status",
      sortable: true,
      renderer: (announcement) => {
        return (
          <StatusLabel color={getStatusLabelColor(announcement.status)}>
            {StringUtils.getTitleCase(announcement.status)}
          </StatusLabel>
        );
      },
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "15%" },
      renderer: (a) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
              onClick={() => {
                setCurrentAnnouncement(a);
                setShowInputModal(true);
              }}
            />
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              icon={<GVDSIcon Icon={IconCopy} />}
              tooltipText="Copy"
              onClick={() => {
                setCurrentAnnouncement(a);
                setShowCopyPrompt(true);
              }}
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
              onClick={() => {
                setCurrentAnnouncement(a);
                setShowDeletePrompt(true);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Container fluid>
        <PageHeader>
          <PageHeader.Title>
            <h1>Landing Page Announcement</h1>
            <Spacer />
            <GVDSButton
              variant={buttonVariant.primary}
              text="Create"
              onClick={() => {
                setShowInputModal(true);
                setCurrentAnnouncement(null);
              }}
              icon={<GVDSIcon Icon={IconCirclePlus} />}
            />
          </PageHeader.Title>
        </PageHeader>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <GVDSTableCtrlContainer>
              <FilterSearchBox
                className="announcement-search-box"
                placeholder="Search announcement"
                value={searchText}
                onInput={setSearchText}
              />
              <GVDSTableCtrlMultiSelect
                nonStringOptions={uniq(announcements.map((a) => a.status))}
                optionTransformer={getStatusOptionTransformer()}
                prefix="Status"
                value={filterKeys.status}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, status: filterKs })
                }
              />
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            <GVDSTable
              className="homepage-announcement-admin__table"
              columns={columns}
              dataToDisplay={currentPageData}
              startIndex={startIndex}
              sortKeys={sortKeys}
              setSortKeys={setSortKeys}
              noContent={
                announcements.length > 0 ? (
                  <div className="table__no_content">
                    <p>No announcements found. Try adjusting your filter.</p>
                  </div>
                ) : (
                  <EmptyHomepageAnnouncementListPlaceholder />
                )
              }
            />
          </>
        )}
      </Container>
      <HomepageAnnouncementInputModal
        show={showInputModal}
        setShow={setShowInputModal}
        currentAnnouncement={currentAnnouncement}
        onSuccess={loadAnnouncements}
        onCloseModal={() => setCurrentAnnouncement(null)}
      />
      <CopyHomepageAnnouncementPrompt
        show={showCopyPrompt}
        closeModal={() => setShowCopyPrompt(false)}
        onCopy={onCopy}
      />
      <DeleteHomepageAnnouncementPrompt
        show={showDeletePrompt}
        setShow={setShowDeletePrompt}
        currentAnnouncement={currentAnnouncement}
        onDeleteSuccess={loadAnnouncements}
      />
    </>
  );
};
export default HomepageAnnouncement;
