import React from "react";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";

const DeleteOperationalDataRecordPrompt = ({ show, cancel, proceed }) => {
  return (
    <GVDSModal
      title={`Delete Operational Data Record`}
      size={GVDSModal.Size.small}
      show={show}
      onHide={cancel}
    >
      <GVDSModal.Body>
        You are about to delete one or more operational data records. Are you
        sure?
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={cancel}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          onClick={proceed}
          text="Delete"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default DeleteOperationalDataRecordPrompt;
