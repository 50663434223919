import React, { useContext } from "react";
import WorkspaceInfoContext from "./WorkspaceInfoContext";
import { IconCheck, IconX } from "@tabler/icons-react";
import GVDSIconSlim from "../Icons/GVDSIconSlim";
import UserInventoryContext from "../../context/UserInventoryContext";
import { MAIN_PORTFOLIO, RESOURCES } from "../../config/constants";
import FeatureContext from "../../context/FeatureContext";
import FeatureService from "../../services/FeatureService";
import SupportContactLink from "../../components/common/SupportContactLink";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import GVDSIcon from "../Icons/GVDSIcon";
import GVDSModal from "../Modals/GVDSModal";
import GVDSButton, { buttonVariant } from "../Buttons/GVDSButton";

const WorkspaceSummary = () => {
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  if (userInventory.isLoadingInventory.get || !selectedInventory) {
    return null;
  } else {
    let content;

    if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      const selectedTreeNode = userInventory.selectedTreeNode.get;
      const nodeValue = selectedTreeNode.nodeValue;

      const inventoryName = nodeValue.name;

      const isMainPortfolio = inventoryName === MAIN_PORTFOLIO;

      const siteCount = selectedTreeNode.children.filter(
        (c) => c.nodeValue.type === RESOURCES.SITE
      ).length;
      const facilityCount = selectedTreeNode.children.filter(
        (c) => c.nodeValue.type === RESOURCES.FACILITY
      ).length;

      let siteFacilityDisplay;

      if (siteCount > 0 && facilityCount > 0) {
        siteFacilityDisplay = `${siteCount} site${
          siteCount > 1 ? "s" : ""
        } and ${facilityCount} facilit${facilityCount > 1 ? "ies" : "y"}`;
      } else if (siteCount > 0) {
        siteFacilityDisplay = `${siteCount} site${siteCount > 1 ? "s" : ""}`;
      } else {
        siteFacilityDisplay = `${facilityCount} facilit${
          facilityCount > 1 ? "ies" : "y"
        }`;
      }

      content = `${
        isMainPortfolio ? "The main portfolio" : "This sub-portfolio"
      } consists of ${siteFacilityDisplay}.`;
    } else if (selectedInventory.type === RESOURCES.SITE) {
      const selectedTreeNode = userInventory.selectedTreeNode.get;

      const parents = selectedTreeNode.parents;

      const isParentTreeRoot =
        parents.length === 1 && parents[0].nodeValue.id === null;
      if (isParentTreeRoot) {
        return null;
      }

      const subportfolioParents = parents
        .filter((p) => p.nodeValue.name !== MAIN_PORTFOLIO)
        .map((p) => p.nodeValue);

      if (subportfolioParents.length === 0) {
        return null;
      }

      content = `This site is part of the following sub-portfolio${
        subportfolioParents.length > 1 ? "s" : ""
      }: ${subportfolioParents.map((p) => p.name).join(", ")}.`;
    } else if (selectedInventory.type === RESOURCES.FACILITY) {
      const selectedTreeNode = userInventory.selectedTreeNode.get;

      const parentSite = selectedTreeNode.parents.find(
        (p) => p.nodeValue.type === RESOURCES.SITE
      )?.nodeValue;

      content = `This facility is a part of ${parentSite.name}.`;
    }

    return <div className="workspace-info--summary">{content}</div>;
  }
};

const WorkspaceFeatures = () => {
  const featureCtx = useContext(FeatureContext);
  const allFeatures = featureCtx.features;
  const isLoadingFeatures = featureCtx.isLoadingFeatures;

  const userInventory = useContext(UserInventoryContext);
  const inventoryFeatureNames =
    userInventory.selectedTreeNode.get.nodeValue.value.features;

  if (isLoadingFeatures) {
    return (
      <div>
        <div className="workspace-info--feature-header">
          Subscribed Features
        </div>
        <LoadingSpinner />
      </div>
    );
  }

  const allFeaturesNotLoadedProperly =
    allFeatures.length < inventoryFeatureNames.length;

  const featuresDisplay = allFeaturesNotLoadedProperly
    ? inventoryFeatureNames.map((f) => ({
        id: f,
        name: f,
        displayName: f,
        isEnabled: true,
      }))
    : allFeatures.map((f) => ({
        id: f.id,
        name: f.name,
        displayName: f.display_name,
        isEnabled: inventoryFeatureNames.includes(f.name),
      }));

  const isFullFeature =
    allFeaturesNotLoadedProperly || featuresDisplay.every((f) => f.isEnabled);

  let featureInfo;

  const subscribedFeatures = (
    <div className="workspace-info--feature-list">
      {featuresDisplay
        .sort((feature1, feature2) =>
          FeatureService.featureSortFn(feature1.name, feature2.name)
        )
        .map((f) => {
          return (
            <div key={f.id} className="feature-row-container">
              <div className="enabled-feature-icon">
                {f.isEnabled ? (
                  <GVDSIcon
                    Icon={IconCheck}
                    className="gvds-color--tealSolid"
                  />
                ) : (
                  <GVDSIconSlim Icon={IconX} className="gvds-color--error" />
                )}
              </div>
              {f.displayName}
            </div>
          );
        })}
    </div>
  );

  if (isFullFeature) {
    featureInfo = null;
  } else {
    featureInfo = (
      <div className="workspace-info--feature-info">
        Some features are locked. Looking for ways to further empower
        sustainability at your property? Contact{" "}
        <SupportContactLink
          subject="Get access to more features"
          openInNewTab={true}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="workspace-info--feature-header">Subscribed Features</div>
      {featureInfo}
      {subscribedFeatures}
    </div>
  );
};

const WorkspaceInfo = () => {
  const workspaceInfoContext = useContext(WorkspaceInfoContext);

  return (
    <GVDSModal
      title="Subscribed Features"
      size={GVDSModal.Size.medium}
      show={workspaceInfoContext.isOpen}
      onHide={workspaceInfoContext.close}
    >
      <GVDSModal.Body>
        <div>
          <WorkspaceSummary />
          <WorkspaceFeatures />
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={workspaceInfoContext.close}
          text="Okay"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default WorkspaceInfo;
