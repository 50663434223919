import axios from "axios";
import { API_URL } from "../../config/api-config";
import { HttpResponseService } from "../UtilsService";

export default class InitiativesReportService {
  static getReportConfig = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/reports/initiatives/config`,
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static getReportStatus = async (resource_type, resource_id, payload) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/reports/initiatives/status",
        payload,
        {
          params: { resource_type, resource_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static downloadReport = async (resource_type, resource_id, payload) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/reports/initiatives/download`,
        payload,
        {
          params: {
            resource_type,
            resource_id,
          },
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Initiatives Report.xlsx"
      );
    } catch (error) {
      throw error;
    }
  };
}
