import React, { Fragment, useState } from "react";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import { IconCheck, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { Collapse } from "react-bootstrap";

const planFeatureComparisonBlurbs = [
  // {title: "", description:"", isHotelOnly: false, orderedPlansIsFeatureEnabled: [], featureDescriptionByOrderedPlanIndex: {}},
  {
    title: "Environmental Data Management",
    description: "Track and report energy, water, waste, and refrigerant data",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [false, true, true],
    featureDescriptionByOrderedPlanIndex: {
      2: "AI-powered Invoice Data Capture Service available on request (additional fees)",
    },
  },
  {
    title: "Environmental Performance Reports",
    description:
      "Generate reports from customized managerial reports to data dumps, complete with automated Scope 1, 2, and 3 (waste and fuel and energy related) emissions calculations",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [false, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "Environmental Performance Benchmarking",
    description: "Benchmark environmental performance against peers",
    isHotelOnly: true,
    orderedPlansIsFeatureEnabled: [false, false, true],
    featureDescriptionByOrderedPlanIndex: {
      2: "Based on Cornell Hotel Sustainability Benchmarking (CHSB) Index",
    },
  },
  {
    title: "Hotel Operational Data Management & Reporting",
    description:
      "Track and report operational data like occupied rooms, no. of guests, etc.",
    isHotelOnly: true,
    orderedPlansIsFeatureEnabled: [false, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "Hotel Carbon Measurement Initiative (HCMI) Metric Report",
    description:
      "Automatic calculations of hotel's carbon emissions according to the HCMI methodology",
    isHotelOnly: true,
    orderedPlansIsFeatureEnabled: [false, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "Sustainability Initiatives Logging & Reporting",
    description:
      "Track and report actions for efficiency improvements, social impact, procurement, and more",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [true, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "People Data Management & Reporting",
    description: "Track and report diversity metrics",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [false, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "Goals & Targets",
    description:
      "Set sustainability targets with real-time progress monitoring, including shared targets for multiple properties",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [false, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "Best Practices Tracking & Reporting",
    description:
      "Learn and assess your property or portfolio's progress in implementing various sustainability best practices",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [true, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "Guidance Library",
    description:
      "Access 100+ curated guidance to identify and prioritize upcoming initiatives",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [false, false, true],
    featureDescriptionByOrderedPlanIndex: {
      2: "Available in videos and articles",
    },
  },
  {
    title: "GSTC: Guidance and Readiness Scoring",
    description:
      "DIY module for hotels interested in obtaining GSTC certification",
    isHotelOnly: true,
    orderedPlansIsFeatureEnabled: [false, false, true],
    featureDescriptionByOrderedPlanIndex: {
      2: "Advisory services available upon request (additional fees)",
    },
  },
  {
    title: "Risk Assessment Report",
    description:
      "Assess water and biodiversity risk for properties and portfolios",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [false, false, true],
    featureDescriptionByOrderedPlanIndex: {
      2: "Bespoke risk analysis dashboards for portfolios available on request (additional fees)",
    },
  },
  {
    title: "Sustainability Surveys",
    description:
      "Create and launch surveys where required for ESG reporting, managing company goals and targets, etc.",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [true, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "Task Management",
    description:
      "Collaborate with your team to advance your sustainability efforts",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [true, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
  {
    title: "File Storage",
    description:
      "Store all documents essential for third-party audits or showcasing progress",
    isHotelOnly: false,
    orderedPlansIsFeatureEnabled: [true, true, true],
    featureDescriptionByOrderedPlanIndex: {},
  },
];

const PlanFeaturesComparisonTable = ({ orderedPlanNames }) => {
  return (
    <div className="billing-packages-comparison__feature-comparison-table">
      <div></div>
      {orderedPlanNames.map((name) => (
        <div
          key={`name-${name}`}
          className="d-flex flex-column align-items-center gvds-text--heading4"
        >
          {name}
        </div>
      ))}
      {planFeatureComparisonBlurbs.map((featureRow, rowIndex) => {
        return (
          <Fragment key={`feature-${rowIndex}`}>
            <div>
              <div className="gvds-text--heading4">{featureRow.title}</div>
              <div className="gvds-text--caption">{featureRow.description}</div>
              {featureRow.isHotelOnly && (
                <div className="for-hotel-only__caption">
                  Available for hotel properties only
                </div>
              )}
            </div>
            {featureRow.orderedPlansIsFeatureEnabled.map(
              (isEnabled, orderedPlanIndex) => {
                return (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    key={`plan-${orderedPlanIndex}`}
                  >
                    {isEnabled && (
                      <div>
                        <GVDSIcon Icon={IconCheck} />
                      </div>
                    )}
                    {featureRow.featureDescriptionByOrderedPlanIndex &&
                      featureRow.featureDescriptionByOrderedPlanIndex[
                        orderedPlanIndex
                      ] && (
                        <div className="gvds-text--caption gvds-color--gray6 text-center">
                          {
                            featureRow.featureDescriptionByOrderedPlanIndex[
                              orderedPlanIndex
                            ]
                          }
                        </div>
                      )}
                  </div>
                );
              }
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

const BillingPlanComparisonTable = ({ orderedPlanNames }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="billing-packages-comparison__container">
      <div className="d-flex flex-column align-items-center pb-3">
        <h1>Compare features in different plans</h1>
        <GVDSTextButton
          text={
            <>
              Compare all features{" "}
              <GVDSIconSlim
                Icon={isExpanded ? IconChevronUp : IconChevronDown}
              />
            </>
          }
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />
      </div>
      <Collapse in={isExpanded}>
        <div>
          <PlanFeaturesComparisonTable orderedPlanNames={orderedPlanNames} />
        </div>
      </Collapse>
    </div>
  );
};

export default BillingPlanComparisonTable;
