import React, { useEffect, useMemo, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import GVDSTextButton from "../../../../gvds-components/Buttons/GVDSTextButton";
import { iconButtonVariant } from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconTrash } from "@tabler/icons-react";
import { tobe_deleted_entry_prefix } from "../../../../config/constants";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GVDSButton from "../../../../gvds-components/Buttons/GVDSButton";
import {
  createInitialSubrequirement,
  createInitialSubrequirementSection,
  NOT_SECTIONED,
} from "../CertificationSharedUtils";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import { StringUtils } from "../../../../services/UtilsService";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";

const AddSubRequirementButton = ({ onAdd, hasNoSubrequirement }) => {
  return (
    <GVDSTextButton
      className="system-toolbox--certification__add-subrequirement-button"
      variant={iconButtonVariant.tertiary}
      icon={<GVDSIcon Icon={IconCirclePlus} className="me-2" />}
      text={hasNoSubrequirement ? "Add sub-requirement" : "Add more"}
      onClick={onAdd}
    />
  );
};

const SubrequirementForm = ({
  subrequirement,
  onChange,
  deleteSubrequirement,
  isDeleteDisabled,
  isValidated,
}) => {
  const textAreaRef = useRef(null);

  return (
    <Row className="mb-2">
      <Col xs={11}>
        <GVDSFormTextArea
          ref={textAreaRef}
          name="subrequirement"
          placeholder="Input sub-requirement for users to check off."
          rows={1}
          value={subrequirement.content}
          onInput={(value) => {
            subrequirement.content = value;
            onChange();
          }}
          autoAdjustHeight={true}
          statusMetadata={
            isValidated && !StringUtils.isNotEmpty(subrequirement.content)
              ? FormFieldStatusMetadata.getError("This field cannot be empty.")
              : FormFieldStatusMetadata.getDefault()
          }
        />
      </Col>
      <Col xs={1}>
        <GVDSTextButton
          className={isDeleteDisabled ? "" : "color-red"}
          icon={<GVDSIcon Icon={IconTrash} className="me-2" />}
          onClick={deleteSubrequirement}
          disabled={isDeleteDisabled}
        />
      </Col>
    </Row>
  );
};

const SectionForm = ({
  section,
  onChange,
  deleteSection,
  addSubrequirement,
  confirmDeleteSubrequirement,
  isSectioned,
  isValidated,
}) => {
  const currentSubrequirements = section.subrequirements.filter(
    (subreq) => !subreq.id.startsWith(tobe_deleted_entry_prefix)
  );

  const isDeleteSubrequirementDisabled =
    isSectioned && currentSubrequirements.length === 1;

  return (
    <div className="system-toolbox--certification__requirement-subrequirement__section">
      {isSectioned && (
        <Row className="my-2">
          <Col xs={6}>
            <GVDSFormField
              name="sectionHeader"
              placeholder="Input section name. Ex: Year 1 requirements"
              value={section.sectionName}
              onInput={(value) => {
                section.sectionName = value;
                onChange();
              }}
              statusMetadata={
                isValidated && !StringUtils.isNotEmpty(section.sectionName)
                  ? FormFieldStatusMetadata.getError(
                      "This field cannot be empty."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </Col>
          <Col xs={6} className="text-end">
            <GVDSTextButton
              className="color-red"
              icon={<GVDSIcon Icon={IconTrash} className="me-2" />}
              text="Delete section"
              onClick={deleteSection}
            />
          </Col>
        </Row>
      )}

      {currentSubrequirements.length > 0 && (
        <div className="system-toolbox--certification__requirement-subrequirement__list-container">
          {currentSubrequirements.map((subrequirement) => (
            <SubrequirementForm
              key={subrequirement.id}
              subrequirement={subrequirement}
              onChange={onChange}
              deleteSubrequirement={() =>
                confirmDeleteSubrequirement(subrequirement.id)
              }
              isDeleteDisabled={isDeleteSubrequirementDisabled}
              isValidated={isValidated}
            />
          ))}
        </div>
      )}
      <AddSubRequirementButton
        onAdd={addSubrequirement}
        hasNoSubrequirement={currentSubrequirements.length === 0}
      />
    </div>
  );
};

const CertificationContentSubRequirementForm = ({
  subrequirementSections,
  setSubrequirementSections,
  isValidated,
  confirmDeleteSubrequirement,
}) => {
  const [isSectioned, setIsSectioned] = useState(false);

  const currentSections = useMemo(
    () =>
      subrequirementSections.filter(
        (section) => !section.id.startsWith(tobe_deleted_entry_prefix)
      ),
    [subrequirementSections]
  );

  useEffect(() => {
    if (subrequirementSections.length > 0) {
      setIsSectioned(
        currentSections.length > 0 &&
          currentSections[0].sectionName !== NOT_SECTIONED
      );
    }
  }, [subrequirementSections, currentSections]);

  const onChange = () => {
    setSubrequirementSections([...subrequirementSections]);
  };

  const addSubrequirement = (sectionIndex) => {
    const updatedSections = [...subrequirementSections];
    if (
      updatedSections.length === 0 ||
      updatedSections[sectionIndex].id.startsWith(tobe_deleted_entry_prefix)
    ) {
      updatedSections[sectionIndex] = createInitialSubrequirementSection();
    }
    updatedSections[sectionIndex].subrequirements.push(
      createInitialSubrequirement()
    );
    setSubrequirementSections(updatedSections);
  };

  const addSectionHeader = () => {
    const newSection = createInitialSubrequirementSection();
    newSection.sectionName = "";
    newSection.subrequirements = [createInitialSubrequirement()];
    setSubrequirementSections([...subrequirementSections, newSection]);
  };

  const deleteSection = (index) => {
    const updatedSections = subrequirementSections.filter(
      (_, i) => i !== index
    );
    setSubrequirementSections(updatedSections);
  };

  const toggleSectioned = () => {
    const newIsSectioned = !isSectioned;
    setIsSectioned(newIsSectioned);

    if (newIsSectioned) {
      setSubrequirementSections((prevSections) => {
        const isNoSections = prevSections.length === 0;
        const isFirstSectionHasNoSubrequirements =
          prevSections[0] && prevSections[0].subrequirements.length === 0;

        if (isNoSections || isFirstSectionHasNoSubrequirements) {
          return [
            {
              ...createInitialSubrequirementSection(),
              sectionName: "",
              subrequirements: [createInitialSubrequirement()],
            },
          ];
        } else {
          const existingSubrequirements = prevSections[0]
            ? prevSections[0].subrequirements.length > 0
              ? prevSections[0].subrequirements
              : [createInitialSubrequirement()]
            : [createInitialSubrequirement()];

          if (
            prevSections.length === 1 &&
            prevSections[0].sectionName === NOT_SECTIONED
          ) {
            return [
              {
                ...prevSections[0],
                sectionName: "",
              },
            ];
          } else {
            return [
              {
                ...createInitialSubrequirementSection(),
                sectionName: "",
                subrequirements: existingSubrequirements,
              },
            ];
          }
        }
      });
    } else {
      setSubrequirementSections([
        {
          ...createInitialSubrequirementSection(),
          subrequirements: [],
        },
      ]);
    }
  };

  const renderAddSubrequirementButton = () => {
    if (!isSectioned) {
      return <AddSubRequirementButton onAdd={() => addSubrequirement(0)} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="d-flex flex-row mb-2">
        <span className="gvds-text--inputField">
          Enable sections for sub-requirements{" "}
          <InfoTooltip
            info="Once sections are enabled, turning them off later will delete all sub-requirements and
             their related user responses. You'll need to start over. Changes aren’t saved until
             you click save button at the bottom of the modal."
          />
        </span>
        <Form.Check
          type="switch"
          className="ms-2"
          checked={isSectioned}
          onChange={toggleSectioned}
        />
      </div>
      <div className="system-toolbox--certification__requirement-subrequirement__container">
        <div>
          {currentSections.length > 0
            ? currentSections.map((section, index) => (
                <SectionForm
                  key={section.id}
                  section={section}
                  onChange={onChange}
                  deleteSection={() => deleteSection(index)}
                  addSubrequirement={() => addSubrequirement(index)}
                  confirmDeleteSubrequirement={(subreqId) =>
                    confirmDeleteSubrequirement(section.id, subreqId)
                  }
                  isSectioned={isSectioned}
                  isValidated={isValidated}
                />
              ))
            : renderAddSubrequirementButton()}
        </div>

        {isSectioned && (
          <div className="system-toolbox--certification__add-section-button-container">
            <GVDSButton
              className="system-toolbox--certification__add-section-button"
              variant={iconButtonVariant.secondary}
              text="Add sub-requirement section"
              onClick={addSectionHeader}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CertificationContentSubRequirementForm;
