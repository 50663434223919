import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../../HOC/withAuthentication";
import { Container } from "react-bootstrap";
import { REFERENCE_DATA } from "../../../config/ROUTES_NAME";
import BestPracticeCategoryService from "../../../services/ReferenceDataServices/BestPracticeCategoryService";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingSpinner from "../../common/LoadingSpinner";
import ToastContext from "../../../context/ToastContext";
import GVFormGroup from "../../common/GVFormGroup";
import { useHistory } from "react-router-dom";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSTable, {ACTIONS_DATAKEY, ACTIONS_TABLE_HEADER} from "../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";

const BestPracticeCategory = () => {
  let history = useHistory();
  const toastContext = useContext(ToastContext);
  const [allPracticeCategory, setAllPracticeCategory] = useState([]);
  const [deleteConfirmationShow, setDeleteConfirmationModalShow] =
    useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const [deleteCategoryName, setDeleteCategoryName] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [isValidated, setIsValidated] = useState(false);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allPracticeCategory, null);

  const getAllPracticeDataCategory = () => {
    setIsLoading(true);
    BestPracticeCategoryService.getAllBestPracticeCategory()
      .then((response) => {
        setIsLoading(false);
        setAllPracticeCategory(response);
        setDeleteConfirmationModalShow(false);
        setShowEdit(false);
        setShowAdd(false);
        setIsLoading(false);
        setNewCategoryName("");
        setIsValidated(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to get best practice categories.</span>
        );
      });
  };

  useEffect(() => {
    getAllPracticeDataCategory();
  }, []);

  const isStringEntryEmpty = (entry) => {
    return !entry || entry.length === 0;
  };

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  const deleteOptionalModalHandler = (categoryId, categoryName) => {
    setShowAdd(false);
    setDeleteConfirmationModalShow(true);
    setDeleteCategoryId(categoryId);
    setDeleteCategoryName(categoryName);
  };

  const editOptionHandler = (event, categoryId, categoryName) => {
    event.preventDefault();
    setShowEdit(true);
    setShowAdd(false);
    setCategoryName(categoryName);
    setDeleteCategoryId(categoryId);
  };

  const deleteOptionHandler = () => {
    setIsLoading(false);
    BestPracticeCategoryService.deletePracticeCategory(deleteCategoryId)
      .then(() => {
        toastContext.addSuccessToast(
          <span>{deleteCategoryName + " was deleted"}</span>
        );
        getAllPracticeDataCategory();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toastContext.addFailToast(<span>{err.response.data.message}</span>);
        } else {
          toastContext.addFailToast(<span>Failed to delete</span>);
        }
      });
  };

  const updateOptionHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsValidated(true);

    if (!isStringEntryEmpty(categoryName)) {
      BestPracticeCategoryService.updatePracticeCategory(
        deleteCategoryId,
        categoryName
      )
        .then((response) => {
          if (response === "data already exist") {
            toastContext.addFailToast(
              <span>
                A Best Practice Category already exists with this name. Please
                enter a new name.
              </span>
            );
          } else {
            getAllPracticeDataCategory();
            toastContext.addSuccessToast(
              <span>{categoryName + " was updated"}</span>
            );
          }
        })
        .catch((error) => {
          toastContext.addFailToast(
            <span>Failed to save best practice category</span>
          );
        });
      setIsLoading(false);
    }
  };

  const saveOptionHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsValidated(true);
    if (!isStringEntryEmpty(newCategoryName)) {
      BestPracticeCategoryService.savePracticeCategory(newCategoryName)
        .then((response) => {
          if (response === "data already exist") {
            toastContext.addFailToast(
              <span>
                A Best Practice Category already exists with this name. Please
                enter a new name.
              </span>
            );
          } else {
            toastContext.addSuccessToast(
              <span>{newCategoryName + " was added"}</span>
            );
            getAllPracticeDataCategory();
          }
        })
        .catch((error) => {
          toastContext.addFailToast(
            <span>Failed to save best practice category</span>
          );
        });
    }
  };

  const columns = [
    {
      header: "Option",
      dataKey: "name",
      sortable: true,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (category) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={(e) => editOptionHandler(e, category.id, category.name)}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() =>
                deleteOptionalModalHandler(category.id, category.name)
              }
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          </>
        );
      },
    },
  ];

  const editSectionBox = (
    <>
      {showEdit && (
        <div>
          <section className="section-box">
            <header>
              <h2>Edit Option</h2>
            </header>
            <article>
              <Form noValidate>
                <Row>
                  <Col sm={4}>
                    <Form.Label>Option Text</Form.Label>
                    <GVFormGroup controlId="OptionName">
                      <GVDSFormField
                        value={categoryName}
                        placeholder="Option Name"
                        onInput={(value) => setCategoryName(value)}
                        statusMetadata={
                          isValidated && isStringEntryEmpty(categoryName)
                            ? FormFieldStatusMetadata.getError(
                                "Enter option name"
                              )
                            : FormFieldStatusMetadata.getDefault()
                        }
                      />
                    </GVFormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="button-area">
                      <GVDSButton
                        variant={buttonVariant.tertiary}
                        onClick={() => setShowEdit(false)}
                        text="Cancel"
                      />
                      <GVDSButton
                        variant={buttonVariant.primary}
                        type="submit"
                        text="Update and close"
                        onClick={updateOptionHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </article>
          </section>
        </div>
      )}
    </>
  );

  const addSectionBox = (
    <>
      {showAdd && (
        <div>
          <section className="section-box">
            <header>
              <h2>Add Option</h2>
            </header>
            <article>
              <Form noValidate>
                <Row>
                  <Col sm={4}>
                    <Form.Label>Option Text</Form.Label>
                    <GVFormGroup controlId="OptionName">
                      <GVDSFormField
                        value={newCategoryName}
                        placeholder="Option Name"
                        onInput={(value) => setNewCategoryName(value)}
                        statusMetadata={
                          isValidated && isStringEntryEmpty(newCategoryName)
                            ? FormFieldStatusMetadata.getError(
                                "Enter option name"
                              )
                            : FormFieldStatusMetadata.getDefault()
                        }
                      />
                    </GVFormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="button-area">
                      <GVDSButton
                        variant={buttonVariant.tertiary}
                        className="cancel-option me-2"
                        onClick={() => setShowAdd(false)}
                        text="Cancel"
                      />
                      <GVDSButton
                        variant={buttonVariant.primary}
                        onClick={saveOptionHandler}
                        text="Save"
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </article>
          </section>
        </div>
      )}
    </>
  );

  const addOptionHandler = () => {
    setShowEdit(false);
    setShowAdd(true);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <div>
        <Container fluid>
          <PageHeader>
            <PageHeader.BackButton
              text="Return To Reference Data"
              onClick={goToReferenceData}
            />
            <PageHeader.Title>
              <h1>Best Practice Category</h1>
              <Spacer />
              <GVDSButton
                variant={buttonVariant.primary}
                className="add-data"
                onClick={addOptionHandler}
                text="Add option"
                icon={<GVDSIcon Icon={IconCirclePlus} />}
              />
            </PageHeader.Title>
          </PageHeader>
          {editSectionBox}
          {addSectionBox}
          <article>
            <GVDSTableCtrlContainer>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            <GVDSTable
              columns={columns}
              dataToDisplay={currentPageData}
              startIndex={startIndex}
              sortKeys={sortKeys}
              setSortKeys={setSortKeys}
            />
            {allPracticeCategory.length === 0 && (
              <div className="table__no_content mt-2">
                <p>No Best Practice Category available. </p>
              </div>
            )}
          </article>
          <GVDSModal
            show={deleteConfirmationShow}
            onHide={() => setDeleteConfirmationModalShow(false)}
            title="Option will disappear from the system."
            size={GVDSModal.Size.small}
          >
            <GVDSModal.Body>
              <p>
                Fields with this selection option will return to default. This
                action cannot be undone. Are you sure?
              </p>
            </GVDSModal.Body>
            <GVDSModal.Footer>
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={() => setDeleteConfirmationModalShow(false)}
                text="Cancel"
              />
              <GVDSButton
                variant={buttonVariant.destructive_primary}
                onClick={deleteOptionHandler}
                text="Yes, delete."
              />
            </GVDSModal.Footer>
          </GVDSModal>
        </Container>
      </div>
    );
  }
};

export default withAuthentication(BestPracticeCategory);
