import React, { useContext, useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { QRCodeCanvas } from "qrcode.react";

import UserService from "../../services/UserService";
import { withAmplify } from "../../context/AWSAmplifyContext";
import ToastContext from "../../context/ToastContext";
import GVFormGroup from "../common/GVFormGroup";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { useTranslation } from "react-i18next";

export const EnableMfaSetup = withAmplify(({ amplify, onSuccess }) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };

  const [setupToken, setSetupToken] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [otpInput, setOtpInput] = useState("");

  const verifyOtp = () => {
    UserService.verifyMfaFirstTime(otpInput)
      .then(() => {
        toastContext.addSuccessToast(
          <span>You have successfully enabled MFA.</span>
        );
        onSuccess();
        setShow(false);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>We were unable to process your changes. Please try again.</span>
        );
      });
  };

  useEffect(() => {
    amplify.getCurrentUser().then((userData) => {
      const email = userData["attributes"]["email"];
      UserService.getMfaSetupToken().then((setupToken) => {
        setSetupToken(setupToken);
        const generatedQrCode =
          "otpauth://totp/" +
          email +
          "?secret=" +
          setupToken +
          "&issuer=Greenview";
        setQrCode(generatedQrCode);
      });
    });
  }, []);

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShow(true)}
        className="text-nowrap"
      >
        {t("settings.security.button-enable-mfa")}
      </Button>
      <GVDSModal
        title="Enable Multi-Factor Authentication"
        size={GVDSModal.Size.small}
        show={show}
        onHide={closeModal}
      >
        <GVDSModal.Body>
          <div className="mb-3">
            You’ll need to install a two-factor authentication application on
            your smartphone such as{" "}
            <a
              href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en"
              target="_blank"
            >
              Google Authenticator
            </a>{" "}
            or{" "}
            <a
              href="https://www.microsoft.com/en-us/account/authenticator"
              target="_blank"
            >
              Windows Authenticator
            </a>
            .
          </div>
          <div className="mb-3">
            <Form.Label>Step 1: Configure the app.</Form.Label>
            <div>
              Open your authenticator app and scan the QR code below to add your
              Greenview account.
            </div>
            <div className="m-2 d-flex align-items-center justify-content-center">
              <QRCodeCanvas value={qrCode} />
            </div>
            <div>
              If the QR code is not working, manually enter this text code:{" "}
              {setupToken}
            </div>
          </div>
          <div>
            <GVFormGroup>
              <Form.Label>Step 2: Enter the 6-digit code.</Form.Label>
              <GVDSFormField
                type="number"
                placeholder="Enter OTP from app"
                value={otpInput}
                onInput={(value) => setOtpInput(value)}
              />
            </GVFormGroup>
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={verifyOtp}
            text="Enable MFA"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
});

export const DisableMfaSetup = ({ onSuccess }) => {
  const { t } = useTranslation();
  const toastContext = useContext(ToastContext);

  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };

  const [password, setPassword] = useState("");

  const disableMfa = () => {
    UserService.disableMfa(password)
      .then(() => {
        toastContext.addSuccessToast(
          <span>You have successfully disabled MFA.</span>
        );
        onSuccess();
        setShow(false);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>We were unable to process your changes. Please try again.</span>
        );
      });
  };

  return (
    <>
      <Button
        variant="link"
        className="link-danger text-nowrap"
        onClick={() => setShow(true)}
      >
        {t("settings.security.button-disable-mfa")}
      </Button>
      <GVDSModal
        title="Disable Multi-Factor Authentication"
        size={GVDSModal.Size.small}
        show={show}
        onHide={closeModal}
      >
        <GVDSModal.Body>
          <div className="mb-3">
            To disable MFA, enter your password to verify that it’s you.
          </div>
          <div>
            <GVFormGroup>
              <Form.Label>Password</Form.Label>
              <GVDSFormField
                type="password"
                placeholder="Enter your password"
                value={password}
                onInput={(value) => setPassword(value)}
              />
            </GVFormGroup>
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={disableMfa}
            text="Disable MFA"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};
