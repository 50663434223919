import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import uniq from "lodash/uniq";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import BrandFlagService from "../../../services/BrandFlagService";
import ToastContext from "../../../context/ToastContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import CompanyService from "../../../services/CompanyService";
import DeleteBrandFlagPrompt from "../Companies/DeleteBrandFlagPrompt";
import { COMPANY_TYPE } from "../../../config/constants";
import { REFERENCE_DATA } from "../../../config/ROUTES_NAME";
import GVFormGroup from "../../common/GVFormGroup";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";

const initialRecord = {
  brand_flag_name: "",
  brand_company_id: "",
};

const AllBrandFlags = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [show, setShowInputModal] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const [deleteBrandFlag, setDeleteBrandFlag] = useState(false);
  const [currentlyToBeDeletedRecordModels, setCurrentlyToBeDeletedBrandFlag] =
    useState("");

  const [brandFlags, setBrandFlags] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [newRecord, setNewRecord] = useState(initialRecord);

  const [editBrandData, setEditBrandData] = useState(false);
  const [brandFlagId, setBrandFlagId] = useState("");

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    brandFlags,
    SystemToolboxSearchKeysByPageName.referenceData.brandFlag,
    {
      name: SORTING_TYPES.asc,
    }
  );

  const loadRecord = () => {
    setIsLoading(true);
    Promise.all([
      BrandFlagService.getAllBrandFlags(),
      CompanyService.getAllCompanies(),
    ])
      .then(([brandFlags, companies]) => {
        const brandCompany = companies.filter((company) => {
          return company.company_types.find(
            (company_type) => company_type.name === COMPANY_TYPE.BRAND
          );
        });
        setCompanies(brandCompany);
        setBrandFlags(brandFlags);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load brand flags.</span>);
      });
  };

  useEffect(() => {
    loadRecord();
  }, []);

  const isInputValid = () => {
    return (
      newRecord.brand_flag_name !== "" && newRecord.brand_company_id !== ""
    );
  };

  const showEditRecord = (brand) => {
    setBrandFlagId(brand.id);
    setNewRecord({
      brand_flag_name: brand.name,
      brand_company_id: brand.brand_company_id,
    });
    setShowInputModal(true);
    setEditBrandData(true);
  };

  const columns = [
    {
      header: "Brand Flag Name",
      dataKey: "name",
      sortable: true,
      renderer: (q) => <div>{q.name}</div>,
    },
    {
      header: "Brand Company",
      dataKey: "brand_company",
      sortable: true,
      renderer: (q) => <div>{q.brand_company}</div>,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (q) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={(e) => {
                e.preventDefault();
                showEditRecord(q);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                promptDeleteRecord(q);
              }}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          </>
        );
      },
    },
  ];

  const openModal = () => {
    setIsValidated(false);
    setShowInputModal(true);
    setNewRecord({
      brand_flag_name: "",
      brand_company_id: "",
    });
    setEditBrandData(false);
  };

  const onTextChange = (value, eventName) => {
    setNewRecord({ ...newRecord, [eventName]: value });
  };

  const onDropdownChange = (value, eventName) => {
    setNewRecord({ ...newRecord, [eventName]: value });
  };

  const resetFields = () => {
    setShowInputModal(false);
  };

  const cancelDialog = () => {
    resetFields();
  };

  const saveData = async (event) => {
    setIsValidated(true);

    if (isInputValid()) {
      event.preventDefault();
      setIsLoading(true);

      try {
        if (editBrandData) {
          await BrandFlagService.updateBrandFlag(brandFlagId, newRecord);
          toastContext.addSuccessToast(
            <span>Brand flag was successfully edited.</span>
          );
        } else {
          await BrandFlagService.createNewBrandFlag(newRecord);

          toastContext.addSuccessToast(
            <span>Brand Flag was successfully added.</span>
          );
        }

        setIsLoading(false);
        loadRecord();
      } catch (e) {
        setIsLoading(false);
        editBrandData
          ? toastContext.addFailToast(<span>Failed to edit Brand Flag.</span>)
          : toastContext.addFailToast(
              <span>Failed to create Brand Flag.</span>
            );
      }
      resetFields();
    }
  };

  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  const promptDeleteRecord = async (brand) => {
    setCurrentlyToBeDeletedBrandFlag(brand.id);
    setIsLoading(true);
    try {
      const response = await BrandFlagService.getBrandFlagById(brand.id);
      setIsLoading(false);
      if (response) {
        setDeleteBrandFlag(false);
      } else {
        setDeleteBrandFlag(true);
      }
      setShowPromptDeleteModal(true);
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to remove brand flag.</span>);
    }
  };

  const deleteCurrentlySelectedBrandFlag = () => {
    BrandFlagService.deleteDataRecords(currentlyToBeDeletedRecordModels).then(
      () => {
        toastContext.addSuccessToast(
          <span>Brand Flag was successfully deleted.</span>
        );
        loadRecord();
        handleClosePromptDeleteModal();
      }
    );
  };

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <Container fluid>
        <PageHeader>
          <PageHeader.BackButton
            text="Return To Reference Data"
            onClick={goToReferenceData}
          />
          <PageHeader.Title>
            <h1>Brand Flags</h1>
            <Spacer />
            <GVDSButton
              variant={buttonVariant.primary}
              className="add-data"
              onClick={() => openModal()}
              text="Create Brand Flag"
              icon={<GVDSIcon Icon={IconCirclePlus} />}
            />
          </PageHeader.Title>
        </PageHeader>
        <GVDSTableCtrlContainer>
          <FilterSearchBox
            placeholder="Search by Brand Flag Name"
            value={searchText}
            onInput={setSearchText}
          />
          <GVDSTableCtrlMultiSelect
            options={uniq(brandFlags.map((q) => q.brand_name).flat())}
            prefix="Brand Company"
            onChange={(filterKs) =>
              setFilterKeys({ ...filterKeys, brand_name: filterKs })
            }
          />
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        </GVDSTableCtrlContainer>
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
        />
        {filteredSortedData.length === 0 && (
          <div className="table__no_content">No record found</div>
        )}
      </Container>

      <GVDSModal
        show={show}
        onHide={() => cancelDialog()}
        title={editBrandData ? "Edit Brand Flag" : "Create Brand Flag"}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          <GVFormGroup>
            <Form.Label>Brand Flag Name</Form.Label>
            <GVDSFormField
              className="company_name_input"
              placeholder="Enter brand flag name"
              value={newRecord.brand_flag_name}
              onInput={(value) => onTextChange(value, "brand_flag_name")}
              statusMetadata={
                isValidated && newRecord.brand_flag_name === ""
                  ? FormFieldStatusMetadata.getError(
                      "Please provide brand flag name."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Brand Company</Form.Label>
            <GVDSFormSingleSelect
              placeholder="Select Company"
              value={
                newRecord.brand_company_id
                  ? getOptionByValueFromIdName(
                      newRecord.brand_company_id,
                      companies
                    )
                  : null
              }
              onSelect={(selectedOption) =>
                onDropdownChange(selectedOption.value, "brand_company_id")
              }
              options={companies.map((company) => {
                return { value: company.id, label: company.name };
              })}
              statusMetadata={
                isValidated && newRecord.brand_company_id === ""
                  ? FormFieldStatusMetadata.getError("Please select a company.")
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
        </GVDSModal.Body>

        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => cancelDialog()}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            text="Save"
            onClick={saveData}
          />
        </GVDSModal.Footer>
      </GVDSModal>
      <DeleteBrandFlagPrompt
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        deleteBrandFlag={deleteBrandFlag}
        proceed={deleteCurrentlySelectedBrandFlag}
      />
    </div>
  );
};

export default AllBrandFlags;
