import React, { useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ViewAllDataRequests from "./DataRequest/ViewAllDataRequests";
import withAuthentication from "../../HOC/withAuthentication";
import Dropdown from "react-bootstrap/Dropdown";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import {
  OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT,
  OVERVIEW_DATA_OPERATIONAL_BULK_INPUT,
} from "../../../config/ROUTES_NAME";
import PermissionsContext from "../../../context/PermissionsContext";
import { PERMISSIONS } from "../../../config/constants";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSDropdownToggle from "../../../gvds-components/Buttons/GVDSDropdownToggle";
import { useTranslation } from "react-i18next";

const Overview = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const permissionCtx = useContext(PermissionsContext);

  const goToBulkEnvironmentalInput = () => {
    history.push(
      getRedirectURLWithCurrentParam(
        OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT,
        location
      )
    );
  };

  const goToBulkOperationalInput = () => {
    history.push(
      getRedirectURLWithCurrentParam(
        OVERVIEW_DATA_OPERATIONAL_BULK_INPUT,
        location
      )
    );
  };

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("data-management.overview.page-title")}</h1>
          <Spacer />
          {!permissionCtx.isLoadingPermissions &&
            (permissionCtx.permissions[
              PERMISSIONS.ENVIRONMENTAL_RECORD_CREATE
            ] ||
              permissionCtx.permissions[
                PERMISSIONS.OPERATIONAL_RECORD_CREATE
              ]) && (
              <Dropdown>
                <Dropdown.Toggle as={GVDSDropdownToggle}>
                  Input Bulk Records
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {!permissionCtx.isLoadingPermissions &&
                    permissionCtx.permissions[
                      PERMISSIONS.ENVIRONMENTAL_RECORD_CREATE
                    ] && (
                      <Dropdown.Item onClick={goToBulkEnvironmentalInput}>
                        Environmental Data
                      </Dropdown.Item>
                    )}
                  {!permissionCtx.isLoadingPermissions &&
                    permissionCtx.permissions[
                      PERMISSIONS.OPERATIONAL_RECORD_CREATE
                    ] && (
                      <Dropdown.Item onClick={goToBulkOperationalInput}>
                        Operational Data
                      </Dropdown.Item>
                    )}
                </Dropdown.Menu>
              </Dropdown>
            )}
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="request-log">
        <Tab eventKey="request-log" title="Request Log">
          <ViewAllDataRequests />
        </Tab>
      </Tabs>
    </div>
  );
};

export default withAuthentication(Overview);
