import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import clone from "lodash/clone";
import ProgressBar from "react-bootstrap/ProgressBar";

import { SURVEYS } from "../../../config/ROUTES_NAME";
import SurveyAssessmentService from "../../../services/SurveyAssessmentService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import SurveyAssessmentQuestionsDisplay from "../Components/SurveyAssessmentQuestionsDisplay";
import { SurveyAssessmentModel } from "../Components/SurveyAssessmentModel";
import { NumberService, StringUtils } from "../../../services/UtilsService";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import { PERMISSIONS } from "../../../config/constants";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import GVDSInfoCard from "../../../gvds-components/common/GVDSInfoCard";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { TextCopies } from "../../../config/text-copies";

const SiteViewSurvey = () => {
  const history = useHistory();
  const { surveyId } = useParams();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const permissionCtx = useContext(PermissionsContext);
  const stickyBannerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [showMarkAsCompletePromptModal, setShowMarkAsCompletePromptModal] =
    useState(false);

  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const closeInstructionModal = () => {
    setShowInstructionModal(false);
  };

  const [surveyAssessmentModel, setSurveyAssessmentModel] = useState(null);
  const [isStickyBannerOnTop, setIsStickyBannerOnTop] = useState(false);

  const isAllowedToAnswerSurvey =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.SURVEY_ANSWER];

  const updateSurveyAssessmentModel = () => {
    setSurveyAssessmentModel(clone(surveyAssessmentModel));
  };

  const showMarkAsCompletedBanner = () => {
    if (
      surveyAssessmentModel &&
      surveyAssessmentModel.assessmentComplete &&
      surveyAssessmentModel.isStatusOpen()
    ) {
      return (
        <GVDSBanner
          title=" This survey has been marked as completed at this site."
          variant={GVDSBanner.Variants.success}
        >
          {isAllowedToAnswerSurvey && (
            <div className="subtitle">
              Changes to the responses can still be made until the survey is
              closed and the responses will be automatically updated.
            </div>
          )}
        </GVDSBanner>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;

    setIsLoading(true);

    if (!userInventory.isLoadingInventory.get && selectedInventory) {
      SurveyAssessmentService.getSurvey(
        selectedInventory.type,
        selectedInventory.id,
        surveyId
      )
        .then((surveyAssessmentDTO) => {
          const model = SurveyAssessmentModel.fromDTO(surveyAssessmentDTO);
          setSurveyAssessmentModel(model);
          if (
            !model.assessmentComplete &&
            model.isStatusOpen() &&
            !model.isAllQuestionsAnswered() &&
            isAllowedToAnswerSurvey
          ) {
            setShowInstructionModal(true);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.response && e.response.status === 404) {
            // no such survey
          } else {
            toastContext.addFailToast(
              <span>Failed to load survey details.</span>
            );
          }
        });
    }
  }, [
    surveyId,
    userInventory.isLoadingInventory.get,
    userInventory.selectedInventory.get,
  ]);

  useEffect(() => {
    if (
      surveyAssessmentModel &&
      !surveyAssessmentModel.assessmentComplete &&
      surveyAssessmentModel.isStatusOpen() &&
      surveyAssessmentModel.isAllQuestionsAnswered() &&
      isAllowedToAnswerSurvey
    ) {
      setShowMarkAsCompletePromptModal(true);
    }
  }, [surveyAssessmentModel]);

  useEffect(() => {
    const onScroll = () => {
      if (stickyBannerRef.current !== null) {
        const rect = stickyBannerRef.current.getBoundingClientRect();
        if (rect.top > 0) {
          setIsStickyBannerOnTop(false);
        } else {
          setIsStickyBannerOnTop(true);
        }
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const instructionsRef = useCallback(
    (node) => {
      if (node !== null) {
        node.innerHTML = surveyAssessmentModel.instructions;
      }
    },
    [surveyAssessmentModel]
  );

  const goToSurveyList = () => {
    history.push(SURVEYS);
  };

  if (isLoading || permissionCtx.isLoadingPermissions) {
    return <LoadingSpinner />;
  } else if (!surveyAssessmentModel) {
    return (
      <div>
        <PageHeader>
          <PageHeader.BackButton
            text="Return to Surveys"
            onClick={goToSurveyList}
            className="go-to-survey-list"
          />
          <PageHeader.Title>
            <h1>Invalid survey link.</h1>
          </PageHeader.Title>
        </PageHeader>
        <div>
          You might have clicked on a bad link, or the survey you are looking
          for might have been removed or temporarily unavailable.
        </div>
      </div>
    );
  }

  const isFormDisabled = !surveyAssessmentModel.isStatusOpen();
  const formDisabledAlert = isFormDisabled ? (
    <GVDSInfoCard title="Responses cannot be edited in a survey that is already closed."></GVDSInfoCard>
  ) : null;

  const surveyProgress = () => {
    const noOfAnsweredQn = surveyAssessmentModel.getNoOfAnsweredQn();
    const progress = NumberService.format(
      (100 * noOfAnsweredQn) / surveyAssessmentModel.questions.length
    );
    return (
      <div>
        <div className="gvds-text--formLabel mb-1">Questions Answered</div>
        <div className="survey-progress">
          <ProgressBar now={progress} />
          <div className="d-flex">
            <div className="ms-1 gvds-text--caption">
              {noOfAnsweredQn}/{surveyAssessmentModel.questions.length}
            </div>
            <div className="ms-1 gvds-text--caption">({progress}%)</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <PageHeader>
          <PageHeader.BackButton
            text="Return to Surveys"
            onClick={goToSurveyList}
            className="go-to-survey-list"
          />
          <PageHeader.Title>
            <h1>{surveyAssessmentModel.name}</h1>
            <div className="ms-2 caption">
              for {userInventory.selectedTreeNode.get.nodeValue.name}
            </div>
          </PageHeader.Title>

          <PageHeader.Status>
            <div className="info-field">
              <StatusLabel
                color={
                  surveyAssessmentModel.isStatusOpen()
                    ? StatusLabel.Colors.green
                    : StatusLabel.Colors.red
                }
              >
                {StringUtils.capitaliseWord(surveyAssessmentModel.status)}
              </StatusLabel>
            </div>
          </PageHeader.Status>

          <PageHeader.Description>
            {surveyAssessmentModel.description}
          </PageHeader.Description>
        </PageHeader>

        <div
          className={
            isStickyBannerOnTop
              ? "survey-sticky-top-with-shadow"
              : "survey-sticky-top"
          }
          ref={stickyBannerRef}
        >
          <div className="survey-sticky-top-content">
            {showMarkAsCompletedBanner()}
            {formDisabledAlert}
            <div className="d-flex">
              <div>{surveyProgress()}</div>
              <div className="ms-auto">
                {!isFormDisabled && isAllowedToAnswerSurvey && (
                  <>
                    <MarkSurveyAsComplete
                      surveyAssessmentModel={surveyAssessmentModel}
                      onAssessmentCompleteUpdated={updateSurveyAssessmentModel}
                      showMarkAsCompletePromptModal={
                        showMarkAsCompletePromptModal
                      }
                      setShowMarkAsCompletePromptModal={
                        setShowMarkAsCompletePromptModal
                      }
                    />
                    <MarkSurveyAsIncomplete
                      surveyAssessmentModel={surveyAssessmentModel}
                      onAssessmentIncompleteUpdated={
                        updateSurveyAssessmentModel
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <SurveyAssessmentQuestionsDisplay
          surveyAssessmentModel={surveyAssessmentModel}
          onQuestionUpdated={() => updateSurveyAssessmentModel()}
          disabled={isFormDisabled || !isAllowedToAnswerSurvey}
        />
      </div>

      <GVDSModal
        title="Instructions"
        size={GVDSModal.Size.medium}
        show={showInstructionModal}
        onHide={closeInstructionModal}
      >
        <GVDSModal.Body>
          <div ref={instructionsRef} />
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={closeInstructionModal}
            text="Proceed"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

const MarkSurveyAsComplete = ({
  surveyAssessmentModel,
  onAssessmentCompleteUpdated,
  showMarkAsCompletePromptModal,
  setShowMarkAsCompletePromptModal,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);

  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const closeModal = () => {
    if (!isLoading) {
      setShowMarkAsCompletePromptModal(false);
      setShowThankYouModal(false);
    }
  };

  const thankYouRef = useCallback(
    (node) => {
      if (node !== null) {
        node.innerHTML = surveyAssessmentModel.thankYouMessage;
      }
    },
    [surveyAssessmentModel]
  );

  const markAsComplete = () => {
    const selectedInventory = userInventory.selectedInventory.get;

    setIsLoading(true);

    SurveyAssessmentService.markSurveyAsCompleted(
      selectedInventory.type,
      selectedInventory.id,
      surveyAssessmentModel.surveyId
    )
      .then(() => {
        setShowThankYouModal(true);
        setShowMarkAsCompletePromptModal(false);
        surveyAssessmentModel.assessmentComplete = true;
        onAssessmentCompleteUpdated();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to update survey assessment.</span>
        );
      });
  };

  let title;
  let modalContent;
  let modalFooter;

  if (isLoading) {
    title = "Marking survey as complete...";
    modalContent = <LoadingSpinner />;
    modalFooter = null;
  } else if (showMarkAsCompletePromptModal) {
    title = "Mark Survey as Completed";
    modalContent = TextCopies.SurveyAssessment.markAsComplete;
    modalFooter = (
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={closeModal}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={markAsComplete}
          text="Mark as Completed"
        />
      </GVDSModal.Footer>
    );
  } else if (showThankYouModal) {
    title = "Survey has been marked as complete";
    modalContent = <div ref={thankYouRef} />;
    modalFooter = (
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={closeModal}
          text="Close"
        />
      </GVDSModal.Footer>
    );
  }

  return (
    <>
      {!surveyAssessmentModel.assessmentComplete && (
        <div className="d-flex justify-content-end">
          <OverlayTrigger
            placement="left"
            overlay={
              <Popover>
                <Popover.Body>
                  {TextCopies.SurveyAssessment.markAsComplete}
                </Popover.Body>
              </Popover>
            }
          >
            <GVDSButton
              variant={buttonVariant.secondary}
              onClick={() => {
                setShowMarkAsCompletePromptModal(true);
              }}
              text="Mark as Completed"
            />
          </OverlayTrigger>
        </div>
      )}
      {/*  TODO modal is child of parent, so event will propagate up - make sure no click listener in parent */}
      <GVDSModal
        title={title}
        size={showThankYouModal ? GVDSModal.Size.medium : GVDSModal.Size.small}
        show={showMarkAsCompletePromptModal || showThankYouModal}
        onHide={closeModal}
        centered
      >
        <GVDSModal.Body>{modalContent}</GVDSModal.Body>
        {modalFooter}
      </GVDSModal>
    </>
  );
};

const MarkSurveyAsIncomplete = ({
  surveyAssessmentModel,
  onAssessmentIncompleteUpdated,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);

  const [showMarkAsIncompletePromptModal, setShowMarkAsIncompletePromptModal] =
    useState(false);
  const closeModal = () => {
    if (!isLoading) {
      setShowMarkAsIncompletePromptModal(false);
    }
  };

  const markAsIncomplete = () => {
    const selectedInventory = userInventory.selectedInventory.get;

    setIsLoading(true);

    SurveyAssessmentService.markSurveyAsIncomplete(
      selectedInventory.type,
      selectedInventory.id,
      surveyAssessmentModel.surveyId
    )
      .then(() => {
        setShowMarkAsIncompletePromptModal(false);
        surveyAssessmentModel.assessmentComplete = false;
        onAssessmentIncompleteUpdated();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to update survey assessment.</span>
        );
      });
  };

  let title;
  let modalContent;
  let modalFooter;

  if (isLoading) {
    title = "Marking survey as incomplete...";
    modalContent = <LoadingSpinner />;
    modalFooter = null;
  } else if (showMarkAsIncompletePromptModal) {
    title = "Mark Survey as Incomplete";
    modalContent = TextCopies.SurveyAssessment.markAsIncomplete;
    modalFooter = (
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={closeModal}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={markAsIncomplete}
          text="Mark as Incomplete"
        />
      </GVDSModal.Footer>
    );
  }

  return (
    <>
      {surveyAssessmentModel.assessmentComplete &&
        surveyAssessmentModel.isStatusOpen() && (
          <div className="d-flex justify-content-end">
            <OverlayTrigger
              placement="left"
              overlay={
                <Popover>
                  <Popover.Body>
                    {TextCopies.SurveyAssessment.markAsIncomplete}
                  </Popover.Body>
                </Popover>
              }
            >
              <GVDSButton
                variant={buttonVariant.secondary}
                onClick={() => {
                  setShowMarkAsIncompletePromptModal(true);
                }}
                text="Mark as Incomplete"
              />
            </OverlayTrigger>
          </div>
        )}
      {/*  TODO modal is child of parent, so event will propagate up - make sure no click listener in parent */}
      <GVDSModal
        title={title}
        size={GVDSModal.Size.small}
        show={showMarkAsIncompletePromptModal}
        onHide={closeModal}
        centered
      >
        <GVDSModal.Body>{modalContent}</GVDSModal.Body>
        {modalFooter}
      </GVDSModal>
    </>
  );
};

export default SiteViewSurvey;
