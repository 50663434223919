export const HOME = "/";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const SIGNUP_PACKAGES = "/signup-packages";
export const PAYMENT = "/payment";
export const PAYMENT_RESULT = "/post-payment";

// region Dashboards
export const DASHBOARDS = "/dashboards";
// endregion

// region Data
export const DATA = "/data";
export const OVERVIEW_DATA = "/data/overview";
export const ENVIRONMENTAL_DATA = "/data/environmental";
export const ENVIRONMENTAL_DATA_SETUP = "/data/environmental/setup";
export const ENVIRONMENTAL_DATA_BULK_INPUT = "/data/environmental/bulk-input";
export const ENVIRONMENTAL_DATA_METER = "/data/environmental/meter";
export const MARKET_PURCHASE_OFFSETS_TRACKER = "/data/environmental/tracker";
export const OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT =
  "/data/overview/environmental/bulk-input";
export const OVERVIEW_DATA_OPERATIONAL_BULK_INPUT =
  "/data/overview/operational/bulk-input";
export const OPERATIONAL_DATA = "/data/operational";
export const OPERATIONAL_DATA_SETUP = "/data/operational/setup";
export const OPERATIONAL_DATA_BULK_INPUT = "/data/operational/bulk-input";
export const OPERATIONAL_DATA_METER = "/data/operational/meter";

export const PEOPLE_DATA = "/data/people";
export const PEOPLE_DATA_METER = "/data/people/meter";
export const PEOPLE_DATA_SETUP = "/data/people/setup";

export const VIEW_ALL_INITIATIVES = "/data/initiatives";
// endregion

// region Report
export const REPORT = "/report";
export const REPORT_HCMI_METRIC = "/report/hcmi-metric";
export const REPORT_STANDARD_REPORT = "/report/standard-report";
export const REPORT_PERFORMANCE_REPORT = "/report/performance-report";
export const REPORT_INITIATIVES_REPORT = "/report/initiatives-report";
export const REPORT_BEST_PRACTICE_REPORT = "/report/best-practice-report";
export const REPORT_RISK_ASSESSMENT_REPORT = "/report/risk-assessment-report";
// endregion

// region Goals & Targets
export const GOALS_AND_TARGETS = "/goals-and-targets";
export const VIEW_TARGET_DETAILS = "/goals-and-targets/targets/:targetId";
export const getViewTargetDetailsPath = (targetId) =>
  `/goals-and-targets/targets/${targetId}`;
// endregion

// region GSTC
export const GSTC = "/gstc";
// endregion

// region Certifications
export const CERTIFICATIONS = "/certifications";
export const CERTIFICATION_HOMEPAGE = `${CERTIFICATIONS}/:certificationId`;
export const getViewCertificationHomepagePath = (certificationId) =>
  `${CERTIFICATIONS}/${certificationId}`;
export const CERTIFICATION_CONTENT_PAGE = `${CERTIFICATION_HOMEPAGE}/criteria/:criterionId`;
export const getViewCertificationContentPagePath = (
  certificationId,
  criterionId
) => `${CERTIFICATIONS}/${certificationId}/criteria/${criterionId}`;
export const CERTIFICATION_CONTENT_PAGE_REQUIREMENT = `${CERTIFICATION_CONTENT_PAGE}/requirements/:requirementId`;
export const getViewCertificationRequirementPath = (
  certificationId,
  criterionId,
  requirementId
) =>
  `${CERTIFICATIONS}/${certificationId}/criteria/${criterionId}/requirements/${requirementId}`;
// endregion

// region Best Practices
export const BEST_PRACTICES = "/best-practices";
export const BEST_PRACTICES_ASSESS = "/best-practices/assess";
export const BEST_PRACTICES_GUIDANCE = "/best-practices/guidance";
export const BEST_PRACTICES_GUIDANCE_ARTICLE =
  "/best-practices/guidance/:articleId";
export const getViewGuidanceArticlePath = (articleId) =>
  `/best-practices/guidance/${articleId}`;
export const POLICY_TRACKER = "/policy-tracker";
export const POLICY_TRACKER_DETAILS = "/policy-tracker/:policyId";
export const getPolicyTrackerDetailsPath = (policyId) =>
  `/policy-tracker/${policyId}`;
// endregion

// region Surveys
export const SURVEYS = "/surveys";
export const SURVEYS_CREATE = "/surveys/create";
export const VIEW_SURVEY = "/surveys/:surveyId";
export const getViewSurveyPath = (surveyId) => `/surveys/${surveyId}`;
// endregion

// region Help
export const HELP = "/help";
export const VIEW_HELP_ARTICLE = "/help/:articleId";
export const getViewHelpArticlePath = (articleId) => `/help/${articleId}`;
// endregion

// region User Profile
export const USER_PROFILE = "/user-profile";
export const TERMS_OF_USE = "/terms-of-use";
export const PRIVACY_POLICY = "/privacy-policy";
// endregion

// region Admin
export const ADMIN = "/admin";
export const ADMIN_TEAM = "/admin/team";
export const ADMIN_SITE_DETAILS = "/admin/site-details";
export const ADMIN_FACILITIES = "/admin/facilities";
export const ADMIN_FACILITY_DETAILS = "/admin/facilities/:facilityId";
export const getAdminFacilityDetailsPath = (facilityId) =>
  `/admin/facilities/${facilityId}`;
export const ADMIN_TAGS = "/admin/tags";
export const ADMIN_BILLING = "/admin/billing";
// endregion

// region System Toolbox
export const SYSTEM_TOOLBOX = "/system-toolbox";

export const VIEW_ALL_USERS = "/system-toolbox/users";

export const VIEW_ALL_CONTRACTS = "/system-toolbox/contracts";
export const CREATE_CONTRACT = "/system-toolbox/contracts/create";
export const VIEW_CONTRACT_DETAILS = "/system-toolbox/contracts/:contractId";
export const VIEW_CONTRACT_DETAILS__TEAM_TAB = "team";
export const CREATE_CONTRACT_SUBSCRIPTION =
  "/system-toolbox/contracts/:contractId/create-subscription";
export const VIEW_CONTRACT_SUBSCRIPTION =
  "/system-toolbox/contracts/:contractId/subscription/:subscriptionId";
export const DUPLICATE_CONTRACT_SUBSCRIPTION =
  "/system-toolbox/contracts/:contractId/subscription/:subscriptionId/duplicate";
export const getViewContractDetailsPath = (contractId) =>
  "/system-toolbox/contracts/" + contractId;
export const getAddSubscriptionToContractPath = (contractId) =>
  "/system-toolbox/contracts/" + contractId + "/create-subscription";
export const getViewContractSubscriptionPath = (contractId, subscriptionId) =>
  "/system-toolbox/contracts/" + contractId + `/subscription/${subscriptionId}`;
export const getDuplicateContractSubscriptionPath = (
  contractId,
  subscriptionId
) =>
  "/system-toolbox/contracts/" +
  contractId +
  `/subscription/${subscriptionId}/duplicate`;

export const getViewPortfolioDetailsPath = (portfolioId) =>
  "/system-toolbox/portfolios/" + portfolioId;

export const VIEW_ALL_SITES = "/system-toolbox/sites";
export const CREATE_SITE = "/system-toolbox/sites/create";
export const VIEW_SITE_DETAILS = "/system-toolbox/sites/:siteId";
export const VIEW_FACILITY_DETAILS =
  "/system-toolbox/sites/:siteId/facilities/:facilityId";
export const VIEW_SITE_DETAILS__TEAM_TAB = "team";
export const getViewSiteDetailsPath = (siteId) =>
  "/system-toolbox/sites/" + siteId;
export const getViewFacilityDetailsPath = (siteId, facilityId) =>
  `/system-toolbox/sites/${siteId}/facilities/${facilityId}`;

export const SYSTEM_TOOLBOX_BEST_PRACTICES_LIST =
  "/system-toolbox/best-practices";
export const SYSTEM_TOOLBOX_BEST_PRACTICES_CREATE =
  "/system-toolbox/best-practices/create";
export const SYSTEM_TOOLBOX_BEST_PRACTICES_EDIT =
  "/system-toolbox/best-practices/:bestPracticeId";
export const getAdminEditBestPracticePath = (bestPracticeId) =>
  `/system-toolbox/best-practices/${bestPracticeId}`;

export const SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_LIST =
  "/system-toolbox/guidance-articles";
export const SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_CREATE =
  "/system-toolbox/guidance-articles/create";
export const SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_EDIT =
  "/system-toolbox/guidance-articles/:guidanceArticleId";
export const getAdminEditGuidanceArticlePath = (guidanceArticleId) =>
  `/system-toolbox/guidance-articles/${guidanceArticleId}`;

export const SYSTEM_TOOLBOX_ONBOARDING_TOOL = "/system-toolbox/onboarding-tool";

export const SYSTEM_TOOLBOX_HELP_ARTICLES_LIST = "/system-toolbox/help";

export const SYSTEM_TOOLBOX_SMS = "/system-toolbox/sms";
export const SYSTEM_TOOLBOX_SMS_ACTION_CREATE =
  "/system-toolbox/sms/actions/create";
export const VIEW_SYSTEM_TOOLBOX_SMS_ACTION =
  "/system-toolbox/sms/actions/:actionId";
export const getViewSMSActionPath = (actionId) =>
  `/system-toolbox/sms/actions/${actionId}`;

export const SYSTEM_TOOLBOX_ANNOUNCEMENT = "/system-toolbox/announcement";

export const SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS =
  "/system-toolbox/signup-requests";
export const SYSTEM_TOOLBOX_VIEW_SIGNUP_REQUEST =
  "/system-toolbox/signup-requests/:signupId";
export const getAdminViewSignUpRequest = (requestId) =>
  `/system-toolbox/signup-requests/${requestId}`;
export const SYSTEM_TOOLBOX_VIEW_ENTERPRISE_SIGNUP_REQUEST =
  "/system-toolbox/signup-requests/enterprise/:signupId";
export const getAdminViewEnterpriseSignUpRequest = (requestId) =>
  `/system-toolbox/signup-requests/enterprise/${requestId}`;

export const SYSTEM_TOOLBOX_LAT_LONG_FINDER = "/system-toolbox/lat-long-finder";
export const SYSTEM_TOOLBOX_SUBSCRIPTIONS = "/system-toolbox/subscriptions";
export const SYSTEM_TOOLBOX_TROUBLESHOOT_DATA =
  "/system-toolbox/troubleshoot-data";

export const SYSTEM_TOOLBOX_CERTIFICATION_LIST =
  "/system-toolbox/certifications";
export const SYSTEM_TOOLBOX_CERTIFICATION_SETUP =
  "/system-toolbox/certifications/setup/create";
export const SYSTEM_TOOLBOX_CERTIFICATION_EDIT =
  "/system-toolbox/certifications/setup/:certificationId";
export const getAdminEditCertificationPath = (certificationId) =>
  `/system-toolbox/certifications/setup/${certificationId}`;
// endregion

// region Reference Data
export const REFERENCE_DATA = "/system-toolbox/reference-data";

export const REF_DATA_PORTAL_MODULE_LINKS =
  "/system-toolbox/reference-data/portal-module-links";

export const BEST_PRACTICES_CATEGORY =
  "/system-toolbox/reference-data/best-practice/categories";
export const BEST_PRACTICES_TAG =
  "/system-toolbox/reference-data/best-practice/tags";
export const SYSTEM_TOOLBOX_BRAND_FLAGS_LIST =
  "/system-toolbox/reference-data/brand-flags";
export const SYSTEM_TOOLBOX_COMPANIES_LIST =
  "/system-toolbox/reference-data/companies";

export const SYSTEM_TOOLBOX_SITE_FACILITY_OPTIONS =
  "/system-toolbox/reference-data/site-facility-options";

export const SYSTEM_TOOLBOX_AFFILIATIONS =
  "/system-toolbox/reference-data/affiliations";
export const SYSTEM_TOOLBOX_HOTEL_TYPES =
  "/system-toolbox/reference-data/hotel-types";
export const SYSTEM_TOOLBOX_LAUNDRY_LOCATIONS =
  "/system-toolbox/reference-data/laundry-locations";
export const SYSTEM_TOOLBOX_MARKET_SEGMENTS =
  "/system-toolbox/reference-data/market-segments";
export const SYSTEM_TOOLBOX_ASSET_CLASSES =
  "/system-toolbox/reference-data/asset-classes";
export const SYSTEM_TOOLBOX_COUNTRY_LIST =
  "/system-toolbox/reference-data/country-list";
export const SYSTEM_TOOLBOX_CURRENCY_LIST =
  "/system-toolbox/reference-data/currency-list";
export const SYSTEM_TOOLBOX_METROAREA_LIST =
  "/system-toolbox/reference-data/metroarea-list";
export const SYSTEM_TOOLBOX_PROVINCE_LIST =
  "/system-toolbox/reference-data/province-list";
export const SYSTEM_TOOLBOX_CLIMATE_ZONE_KOPPEN_GEIGER =
  "/system-toolbox/reference-data/climate-zone-koppen-geiger";
export const SYSTEM_TOOLBOX_CLIMATE_ZONE_BAILEYS_ECOREGION =
  "/system-toolbox/reference-data/climate-zone-baileys-ecoregion";

export const SYSTEM_TOOLBOX_CERTIFICATIONS =
  "/system-toolbox/reference-data/certifications";
export const SYSTEM_TOOLBOX_CONFIGURATION_FILES =
  "/system-toolbox/configuration-files";
export const SYSTEM_TOOLBOX_OPERATIONAL_TYPES =
  "/system-toolbox/reference-data/operation-types";
export const SYSTEM_TOOLBOX_ENVIRONMENTAL_TYPES =
  "/system-toolbox/reference-data/environmental-types";
export const SYSTEM_TOOLBOX_PERFORMANCE_GROUP_LIST =
  "/system-toolbox/reference-data/performance-groups";
export const SYSTEM_TOOLBOX_SUBTOPICS =
  "/system-toolbox/reference-data/subtopics";
export const SYSTEM_TOOLBOX_UNITS = "/system-toolbox/reference-data/units";
// endregion
