import React, { useContext, useEffect, useState } from "react";

import {
  PERMISSIONS,
  RESOURCES,
  TARGET_NOT_ASSOCIATED_TO_GOAL,
  TARGET_STATUS,
  TARGET_TOPIC,
  TARGET_TOPIC_DISPLAY_LABEL,
} from "../../config/constants";
import ViewAllTargets from "./ViewAllTargets";
import LoadingSpinner from "../common/LoadingSpinner";
import InputTargetModal from "./InputTargetModal";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import TargetService from "../../services/TargetService";
import ViewAllGoals from "./ViewAllGoals";
import goalTargetModelIllustration from "../../assets/images/goaltargetmodel.png";
import GoalService from "../../services/GoalService";
import PermissionsContext from "../../context/PermissionsContext";
import ViewPortfolioTargets from "./ViewPortfolioTargets";
import CollectiveTargetService from "../../services/CollectiveTargetService";
import Form from "react-bootstrap/Form";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import Spacer from "../../gvds-components/Layout/Spacer";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import { IconCirclePlus, IconHelp } from "@tabler/icons-react";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import { UtilsService } from "../../services/UtilsService";
import { useTranslation } from "react-i18next";

const ViewAllGoalsAndTargets = () => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [showGoalsTargetsInfo, setShowGoalsTargetsInfo] = useState(false);

  const [goals, setGoals] = useState([]);
  const [isLoadingGoals, setIsLoadingGoals] = useState(true);

  const [showInputModal, setShowInputModal] = useState(false);
  const [filterKeys, setFilterKeys] = useState({
    statuses: [TARGET_STATUS.OPEN],
  });
  const [targets, setTargets] = useState([]);
  const [isLoadingTargets, setIsLoadingTargets] = useState(true);
  const isAllowedToCreateTarget =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.GOALS_N_TARGETS_CREATE];

  const [showCollectiveTargetsOnly, setShowCollectiveTargetsOnly] =
    useState(false);

  useEffect(() => {
    loadGoals();
    loadTargets();
  }, [selectedInventory]);

  const loadGoals = async () => {
    if (selectedInventory?.id && selectedInventory?.type === RESOURCES.SITE) {
      setIsLoadingGoals(true);
      try {
        const goals = await GoalService.getAll(
          selectedInventory.type,
          selectedInventory.id
        );
        setGoals(goals);
      } catch (e) {
        toastContext.addFailToast(
          <span>Failed to load goals. Please try again.</span>
        );
      } finally {
        setIsLoadingGoals(false);
      }
    } else {
      setIsLoadingGoals(false);
    }
  };

  const loadTargets = async () => {
    if (selectedInventory?.id && selectedInventory?.type === RESOURCES.SITE) {
      setIsLoadingTargets(true);
      TargetService.getAll(selectedInventory.type, selectedInventory.id)
        .then((data) => {
          setTargets(data);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load targets. Please try again.</span>
          );
        })
        .finally(() => {
          setIsLoadingTargets(false);
        });
    } else if (
      selectedInventory?.id &&
      selectedInventory?.type === RESOURCES.PORTFOLIO
    ) {
      setIsLoadingTargets(true);
      CollectiveTargetService.getAllCollectiveTarget(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((data) => {
          setTargets(data);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load targets. Please try again.</span>
          );
        })
        .finally(() => {
          setIsLoadingTargets(false);
        });
    } else {
      setIsLoadingTargets(false);
    }
  };

  const hasNoGoalTargets =
    targets && !!targets.find((target) => target.goal === null);
  const goalNames = [];
  if (hasNoGoalTargets) {
    goalNames.push(TARGET_NOT_ASSOCIATED_TO_GOAL);
  }
  goalNames.push(...goals.map((goal) => goal.name));

  const getDisplayTargets = () => {
    return targets
      .filter((t) => {
        let matchFilter = true;

        if (filterKeys.statuses && filterKeys.statuses.length > 0) {
          matchFilter =
            matchFilter && filterKeys.statuses.indexOf(t.status) >= 0;
        }

        if (filterKeys.topics && filterKeys.topics.length > 0) {
          const topicFilters = filterKeys.topics.map((v) =>
            UtilsService.getKeyByValue(TARGET_TOPIC_DISPLAY_LABEL, v)
          );
          matchFilter = matchFilter && topicFilters.indexOf(t.topic) >= 0;
        }

        if (filterKeys.goals && filterKeys.goals.length > 0) {
          matchFilter =
            matchFilter &&
            ((t.goal &&
              t.goal.name &&
              filterKeys.goals.indexOf(t.goal.name) >= 0) ||
              (!t.goal &&
                filterKeys.goals.indexOf(TARGET_NOT_ASSOCIATED_TO_GOAL) >= 0));
        }

        if (showCollectiveTargetsOnly) {
          matchFilter = matchFilter && t.isFromCollectiveTarget;
        }

        return matchFilter;
      })
      .sort((a, _) => (a.status === TARGET_STATUS.CLOSED ? 1 : -1));
  };

  let content;
  if (isLoadingTargets || !selectedInventory) {
    content = <LoadingSpinner />;
  } else if (selectedInventory.type === RESOURCES.SITE) {
    const displayTargets = getDisplayTargets();

    content = (
      <>
        <ViewAllGoals
          goals={goals}
          isLoading={isLoadingGoals}
          onGoalChanged={loadGoals}
        />
        <div className="guided-tour-target__targets">
          <div className="d-flex flex-row">
            <h2>Targets</h2>

            {isAllowedToCreateTarget && (
              <>
                <Spacer />
                <GVDSButton
                  variant={buttonVariant.primary}
                  className="create-target-button d-flex flex-row align-items-center guided-tour-target__create-target"
                  onClick={() => setShowInputModal(true)}
                  icon={<GVDSIcon Icon={IconCirclePlus} />}
                  text="Create Target"
                />
              </>
            )}
          </div>
          {targets && targets.length > 0 && (
            <GVDSTableCtrlContainer>
              {goalNames.length > 0 && (
                <GVDSTableCtrlMultiSelect
                  width="400px"
                  options={goalNames}
                  prefix="Goals"
                  defaultSelected={filterKeys.goals}
                  onChange={(filterKs) =>
                    setFilterKeys({ ...filterKeys, goals: filterKs })
                  }
                />
              )}
              <GVDSTableCtrlMultiSelect
                options={Object.values(TARGET_STATUS)}
                prefix="Status"
                defaultSelected={filterKeys.statuses}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, statuses: filterKs })
                }
              />
              <GVDSTableCtrlMultiSelect
                options={Object.values(TARGET_TOPIC).map(
                  (t) => TARGET_TOPIC_DISPLAY_LABEL[t]
                )}
                prefix="Topic"
                defaultSelected={filterKeys.topics}
                onChange={(filterKs) =>
                  setFilterKeys({
                    ...filterKeys,
                    topics: filterKs,
                  })
                }
              />
              <div className="target-filter__collective-target-only__container">
                <span>Shared Targets only</span>
                <Form.Check
                  className="target-filter__collective-target-only__switch ms-2"
                  type="switch"
                  onChange={() =>
                    setShowCollectiveTargetsOnly(!showCollectiveTargetsOnly)
                  }
                  checked={showCollectiveTargetsOnly}
                />
              </div>
            </GVDSTableCtrlContainer>
          )}
        </div>
        <ViewAllTargets
          targets={displayTargets}
          isFiltered={targets.length !== displayTargets.length}
        />
      </>
    );
  } else if (selectedInventory.type === RESOURCES.PORTFOLIO) {
    content = (
      <ViewPortfolioTargets
        collectiveTargets={targets}
        onInputSuccess={loadTargets}
      />
    );
  } else if (selectedInventory.type === RESOURCES.FACILITY) {
    content = (
      <div className="table__no_content">
        The Goals & Targets feature is not available at the Facility level.
        Please navigate to the Site level to view them.
      </div>
    );
  }

  return (
    <>
      <div>
        <PageHeader>
          <PageHeader.Title>
            <h1>{t("goals-n-targets.page-title")}</h1>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => setShowGoalsTargetsInfo(true)}
              icon={<GVDSIcon Icon={IconHelp} />}
              tooltipText="About Goals & Targets"
            />
          </PageHeader.Title>
          <PageHeader.Description>
            {t("goals-n-targets.page-description")}
          </PageHeader.Description>
        </PageHeader>
        {content}
      </div>

      <AboutGoalsTargetsInfo
        show={showGoalsTargetsInfo}
        close={() => setShowGoalsTargetsInfo(false)}
      />
      <InputTargetModal
        show={showInputModal}
        setShow={setShowInputModal}
        onChange={loadTargets}
        goals={goals}
        clearOnSave={true}
      />
    </>
  );
};

const AboutGoalsTargetsInfo = ({ show, close }) => {
  return (
    <GVDSModal
      title="About Goals & Targets"
      size={GVDSModal.Size.medium}
      show={show}
      onHide={close}
    >
      <GVDSModal.Body>
        <div className="d-flex justify-content-center">
          <img
            src={goalTargetModelIllustration}
            alt="Goal Target Model"
            className="goal-target-model-illustration"
          />
        </div>
        <div>
          <p>
            <strong>Goals</strong> describe your sustainability commitments,
            with linked target(s) that are measurable and monitored.
          </p>

          <p>
            <strong>Targets</strong> are measurable outcomes that you can track
            to assess your progress towards a goal.
          </p>

          <p>
            Targets can be associated to a goal but it is possible for a target
            to exist without a goal.
          </p>

          <p>
            A <strong>Shared Target</strong> creates the same target for all
            sites under a portfolio but with a specific target value for each
            site.
          </p>
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={close}
          text="Close"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default ViewAllGoalsAndTargets;
