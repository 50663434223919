import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconPaperclip } from "@tabler/icons-react";

const AttachmentsTooltip = ({ attachmentNames }) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 200 }}
      overlay={
        <Popover>
          <Popover.Body>
            {attachmentNames.map((name, index) => (
              <div key={index}>{name}</div>
            ))}
          </Popover.Body>
        </Popover>
      }
    >
      <div className="d-inline">
        <GVDSIcon Icon={IconPaperclip} />
      </div>
    </OverlayTrigger>
  );
};

export default AttachmentsTooltip;
