import React from "react";

const GVDSButtonGroup = ({
  className,
  options,
  selectedOption,
  onSelect,
  disabled = false,
}) => {
  return (
    <div className={`gvds-button-group__container ${className ?? ""}`}>
      {options.map((option) => (
        <div
          key={option.value}
          className={`gvds-button-group
          ${
            disabled
              ? " disabled"
              : selectedOption === option.value
              ? " selected"
              : ""
          }`}
          onClick={() => {
            if (!disabled) {
              onSelect(option.value);
            }
          }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default GVDSButtonGroup;
