import { DashboardDataService } from "../../../services/Dashboard/DashboardDataService";

export default class DashboardDataLoaderManager {
  constructor() {
    this.inFlightAPICallByLoaderKey = new Set();
  }

  async loadYearlyData(
    resourceType,
    resourceId,
    year,
    subtopic,
    unitId,
    sourceDataHolder
  ) {
    const loaderKey = DashboardDataLoaderManager.getLoaderKey(
      resourceType,
      resourceId,
      year,
      subtopic,
      unitId
    );

    if (this.inFlightAPICallByLoaderKey.has(loaderKey)) {
      return;
    } else {
      this.inFlightAPICallByLoaderKey.add(loaderKey);
    }

    const response = await DashboardDataService.getDashboardData(
      resourceType,
      resourceId,
      year,
      subtopic,
      unitId
    );

    const data = response["data"];
    const cols = response["columns"];
    const meta = response["meta"];

    sourceDataHolder.updateDataForSubtopicForYear(
      year,
      subtopic,
      data,
      cols,
      meta
    );

    this.inFlightAPICallByLoaderKey.delete(loaderKey);
  }

  static getLoaderKey(resourceType, resourceId, year, subtopic, unitId) {
    return [resourceType, resourceId, year, subtopic, unitId].join(";");
  }
}
