import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { xor } from "lodash/array";

import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import { OverlayTrigger } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import ToastContext from "../../../../context/ToastContext";
import { TOTAL_PEOPLE_CATEGORY } from "../../../../services/PeopleService";
import GVDSInfoCard from "../../../../gvds-components/common/GVDSInfoCard";
import Button from "react-bootstrap/Button";

const findMatchingCategory = (categoriesRecord, categories) => {
  if (categoriesRecord) {
    return categoriesRecord.find((entry) => {
      return (
        xor(
          entry.categories.map((c) => c.id),
          categories.map((c) => c.id)
        ).length === 0
      );
    });
  } else {
    return null;
  }
};

const updateMatchingCategoryValue = (
  inputRecord,
  categories,
  newValue,
  onChangeCategoriesRecord
) => {
  const matchingCategory = findMatchingCategory(
    inputRecord.categories_record,
    categories
  );

  if (matchingCategory) {
    const value = parseFloat(newValue);
    matchingCategory.value = isNaN(value) ? "" : value < 0 ? "" : value;
    onChangeCategoriesRecord(inputRecord.categories_record);
  }
};

const PasteHandlerContext = React.createContext(null);

const PasteHandlerProvider = ({ children }) => {
  const [lastPaste, setLastPaste] = useState(null);
  const [rowIndicesToNewValues, setRowIndicesToNewValues] = useState();

  const store = useCallback(
    {
      handlePaste: (newRowIndicesToNewValues) => {
        setRowIndicesToNewValues(newRowIndicesToNewValues);
        setLastPaste(new Date());
      },
      lastPaste: lastPaste,
      rowIndicesToNewValues: rowIndicesToNewValues,
    },
    [lastPaste, rowIndicesToNewValues]
  );

  return (
    <PasteHandlerContext.Provider value={store}>
      {children}
    </PasteHandlerContext.Provider>
  );
};

export const withPasteHandler = (Component) => (props) =>
  (
    <PasteHandlerProvider>
      <Component {...props} />
    </PasteHandlerProvider>
  );

const InputPeopleRecordTable = withPasteHandler(
  ({
    previewMode = false,
    previewGroupings,
    inputRecord,
    onChangeCategoriesRecord,
    disabled = false,
  }) => {
    const toastContext = useContext(ToastContext);
    const pasteHandlerContext = useContext(PasteHandlerContext);

    const groupings = previewMode ? previewGroupings : inputRecord?.groupings;
    const rowData = useMemo(() => {
      if (!previewMode && isEmpty(inputRecord)) {
        return null;
      }

      let newRows = [];
      const rowSpanByColIndex = [1]; // last item does not need rowspan

      if (groupings.length === 0) {
        newRows.push([TOTAL_PEOPLE_CATEGORY]);
      } else {
        for (const grouping of groupings.slice().reverse()) {
          if (newRows.length === 0) {
            for (const category of grouping.categories) {
              newRows.push([category]);
            }
          } else {
            const updatedRows = [];
            for (const category of grouping.categories) {
              for (const row of newRows) {
                updatedRows.push([category, ...row]);
              }
            }
            newRows = updatedRows;
          }
          rowSpanByColIndex.unshift(
            grouping.categories.length * rowSpanByColIndex[0]
          );
        }

        rowSpanByColIndex.shift(); // row span is based on second grouping so first one should be removed
      }

      return { rows: newRows, rowSpanByColIndex: rowSpanByColIndex };
    }, [groupings]);

    const copyCategories = useCallback(async () => {
      const excelCategories = rowData.rows
        .map((row, rowIndex) => {
          return row
            .map((col, colIndex) => {
              // need to do module as only need 1 td with rowspan
              return rowIndex % rowData.rowSpanByColIndex[colIndex] === 0
                ? col.name
                : "";
            })
            .join("\t");
        })
        .join("\n");

      try {
        await navigator.clipboard.writeText(excelCategories);
        toastContext.addSuccessToast(<span>Categories have been copied.</span>);
      } catch (err) {
        toastContext.addFailToast(<span>Failed to copy categories.</span>);
      }
    }, [rowData]);

    const handlePaste = useCallback(
      (pasteText, rowIndex) => {
        const values = pasteText.split(/\r?\n/).map((v) => v.trim());
        const rowIndicesToNewValues = {};
        values.map((value, index) => {
          if (rowIndex + index < rowData.rows.length) {
            updateMatchingCategoryValue(
              inputRecord,
              rowData.rows[rowIndex + index],
              value,
              onChangeCategoriesRecord
            );
            rowIndicesToNewValues[rowIndex + index] = value;
          }
        });
        pasteHandlerContext.handlePaste(rowIndicesToNewValues);
        toastContext.addSuccessToast(<span>Values have been pasted.</span>);
      },
      [rowData]
    );

    return useMemo(() => {
      if (!previewMode && isEmpty(inputRecord)) {
        return null;
      } else {
        return (
          <>
            {!previewMode && (
              <GVDSInfoCard title="For spreadsheet:">
                <ol type="1">
                  <li>
                    <Button
                      variant="link"
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        copyCategories();
                      }}
                    >
                      Click here to copy the categories
                    </Button>
                    and paste them into your spreadsheet.
                  </li>
                  <li>
                    Click on the first cell to paste the value column from your
                    spreadsheet into the cells below.
                  </li>
                </ol>
              </GVDSInfoCard>
            )}
            <Table className="people-input-table">
              <tbody>
                {rowData.rows.map((row, rowIndex) => {
                  const matchingEntry = findMatchingCategory(
                    inputRecord?.categories_record,
                    row
                  );

                  return (
                    <tr key={`row-${rowIndex}`}>
                      {row.map((col, colIndex) => {
                        // need to do module as only need 1 td with rowspan
                        return rowIndex %
                          rowData.rowSpanByColIndex[colIndex] ===
                          0 ? (
                          <td
                            key={`col-${rowIndex}-${colIndex}`}
                            rowSpan={rowData.rowSpanByColIndex[colIndex]}
                          >
                            {previewMode || !col.definition ? (
                              col.name
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id={`definition-col-${rowIndex}-${colIndex}`}
                                  >
                                    {col.definition}
                                  </Tooltip>
                                }
                              >
                                <div>{col.name}</div>
                              </OverlayTrigger>
                            )}
                          </td>
                        ) : null;
                      })}
                      <td className="w-25">
                        {previewMode ? (
                          <Form.Control
                            size="sm"
                            className="people-input"
                            disabled={true}
                          />
                        ) : (
                          <InputField
                            initialValue={
                              matchingEntry ? matchingEntry.value : ""
                            }
                            handlePaste={handlePaste}
                            updateCategoryValue={(newValue) =>
                              updateMatchingCategoryValue(
                                inputRecord,
                                row,
                                newValue,
                                onChangeCategoriesRecord
                              )
                            }
                            disabled={disabled}
                            rowIndex={rowIndex}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        );
      }
    }, [previewMode, rowData.rows]);
  }
);

const InputField = ({
  initialValue,
  handlePaste,
  updateCategoryValue,
  disabled,
  rowIndex,
}) => {
  const pasteHandlerContext = useContext(PasteHandlerContext);

  const [localValue, setLocalValue] = useState(initialValue);

  useEffect(() => {
    updateCategoryValue(localValue);
  }, [localValue]);

  useEffect(() => {
    if (
      pasteHandlerContext.lastPaste &&
      pasteHandlerContext.rowIndicesToNewValues[rowIndex]
    ) {
      setLocalValue(pasteHandlerContext.rowIndicesToNewValues[rowIndex]);
    }
  }, [pasteHandlerContext.lastPaste]);

  return useMemo(
    () => (
      <Form.Control
        size="sm"
        className="people-input"
        value={localValue}
        onPaste={(e) => {
          e.preventDefault(); // prevent onChange
          handlePaste(e.clipboardData.getData("Text"), rowIndex);
        }}
        onChange={(e) => setLocalValue(e.target.value)}
        disabled={disabled}
      />
    ),
    [localValue]
  );
};

export default InputPeopleRecordTable;
