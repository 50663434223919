import React, { useState } from "react";

// Based on https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929
const DragAndDrop = ({ onDrop, children, disabled, ...props }) => {
  const [drag, setDrag] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  let content;
  if (disabled) {
    content = (
      <div {...props}>
        <div className="upload-box--disabled">{children}</div>
      </div>
    );
  } else {
    content = (
      <div
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        {...props}
      >
        <div className={`upload-box${drag ? "--drag" : ""}`}>{children}</div>
      </div>
    );
  }

  return content;
};
export default DragAndDrop;
