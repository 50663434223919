import React from "react";
import GVDSTextButton from "../Buttons/GVDSTextButton";
import { useTranslation } from "react-i18next";

const GVDSClearContentButton = ({
  className = null,
  disabled = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <GVDSTextButton
      className={`py-0${className ? " " + className : ""}`}
      text={t("shared.clear")}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default GVDSClearContentButton;
