import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

import {
  getDefaultMonthRangeEnd,
  getDefaultMonthRangeStart,
} from "../../common/MonthRangeSelector";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import PeopleService from "../../../services/PeopleService";
import LoadingSpinner from "../../common/LoadingSpinner";
import { DateTimeUtils, UtilsService } from "../../../services/UtilsService";
import InputPeopleRecordModal from "./InputPeopleRecord/InputPeopleRecordModal";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import DeletePeopleRecordPrompt from "./DeletePeopleRecordPrompt";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import PermissionsContext from "../../../context/PermissionsContext";
import intersection from "lodash/intersection";
import uniq from "lodash/uniq";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconExternalLink, IconTrash } from "@tabler/icons-react";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  MULTI_SELECT_DATAKEY,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSTableBulkActionBar from "../../../gvds-components/Table/Controls/GVDSTableBulkActionBar";

const ViewAllPeopleDataRecords = ({ peopleMeters = [], lastUpdate }) => {
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const meterTable = peopleMeters.reduce((a, c) => ({ ...a, [c.id]: c }), {});

  const [start, setStart] = useState(getDefaultMonthRangeStart());
  const [end, setEnd] = useState(getDefaultMonthRangeEnd());
  const [allDataRecords, setAllDataRecords] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [currentlyEditedRecord, setCurrentlyEditedRecord] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = () => {
    setShowEditModal(false);
    setCurrentlyEditedRecord(null);
  };

  const [currentlyToBeDeletedIds, setCurrentlyToBeDeletedIds] = useState([]);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };
  const [isDeleting, setIsDeleting] = useState(false);
  const [isMultipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const toggleRecordModelSelection = (recordId) => {
    setCurrentlyToBeDeletedIds(
      UtilsService.toggleItem(currentlyToBeDeletedIds, recordId)
    );
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allDataRecords, null);

  const loadDataRecords = () => {
    if (selectedInventory?.type === RESOURCES.SITE && selectedInventory?.id) {
      setIsLoading(true);
      PeopleService.getPeopleRecords(selectedInventory.id, start, end)
        .then((data) => {
          setIsLoading(false);
          setAllDataRecords(
            data.map((record) => {
              const meter = meterTable[record.meter_id];
              return {
                ...record,
                record_month: moment.utc(record.record_month).toDate(),
                subtopic_name: meter?.subtopic.name,
              };
            })
          );
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load people data records.</span>
          );
        });
    }
  };

  useEffect(() => {
    loadDataRecords();
  }, [selectedInventory, start, end, lastUpdate]);

  const onPageRecordsSelect = () => {
    const recordIds = currentPageData.map((r) => r.id);
    const intersectIds = intersection(currentlyToBeDeletedIds, recordIds);
    if (intersectIds.length === recordIds.length) {
      setCurrentlyToBeDeletedIds(
        currentlyToBeDeletedIds.filter((rId) => recordIds.indexOf(rId) < 0)
      );
    } else {
      setCurrentlyToBeDeletedIds(
        uniq([...currentlyToBeDeletedIds, ...recordIds])
      );
    }
  };

  const showEditRecord = (record) => {
    setCurrentlyEditedRecord(record);
    setShowEditModal(true);
  };

  const promptDeleteRecord = (recordIds) => {
    setCurrentlyToBeDeletedIds(recordIds);
    setShowPromptDeleteModal(true);
  };

  const deleteCurrentlySelectedRecords = () => {
    setIsDeleting(true);
    const selectedInventory = userInventory.selectedInventory.get;
    PeopleService.deletePeopleRecords(
      selectedInventory.id,
      currentlyToBeDeletedIds
    )
      .then(() => {
        const noOfDeletedModels = currentlyToBeDeletedIds.length;
        if (noOfDeletedModels === 1) {
          toastContext.addSuccessToast(
            <span>Data record deleted successfully</span>
          );
        } else {
          toastContext.addSuccessToast(
            <span>{noOfDeletedModels} data records deleted successfully</span>
          );
        }

        if (isMultipleDeleteMode) {
          setMultipleDeleteMode(false);
        }
        setCurrentlyToBeDeletedIds([]);
        loadDataRecords();
        handleClosePromptDeleteModal();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    const columns = [
      {
        header: "Subtopic",
        dataKey: "subtopic_name",
        sortable: true,
        headerStyle: { width: "200px" },
      },
      {
        header: "Groupings",
        dataKey: "groupings",
        renderer: (d) => <div>{d.groupings.map((g) => g.name).join(", ")}</div>,
        headerStyle: { width: "500px" },
      },
      {
        header: "Month Year",
        dataKey: "record_month",
        sortable: true,
        renderer: (d) => DateTimeUtils.formatUTCMonthYear(d["record_month"]),
        headerStyle: { width: "200px" },
      },
      {
        header: "Comment",
        dataKey: "comment",
        sortable: true,
        headerStyle: { width: "100px" },
        renderer: (d) =>
          d["comment"] ? <CommentTooltip comment={d["comment"]} /> : null,
      },
      {
        header: ACTIONS_TABLE_HEADER,
        dataKey: ACTIONS_DATAKEY,
        headerStyle: { width: "200px" },
        renderer: (r) => {
          return (
            <>
              <GVDSIconButton
                variant={iconButtonVariant.tertiary}
                onClick={(e) => {
                  e.preventDefault();
                  showEditRecord(r);
                }}
                icon={
                  <GVDSIcon
                    Icon={
                      !permissionCtx.isLoadingPermissions &&
                      permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_EDIT]
                        ? IconEdit
                        : IconExternalLink
                    }
                  />
                }
                tooltipText={
                  !permissionCtx.isLoadingPermissions &&
                  permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_EDIT]
                    ? "View/Edit"
                    : "View"
                }
              />

              {!permissionCtx.isLoadingPermissions &&
                permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_DELETE] && (
                  <GVDSIconButton
                    variant={iconButtonVariant.destructive}
                    icon={<GVDSIcon Icon={IconTrash} />}
                    onClick={() => {
                      promptDeleteRecord([r.id]);
                    }}
                    tooltipText="Delete"
                  />
                )}
            </>
          );
        },
      },
    ];
    if (isMultipleDeleteMode) {
      columns.unshift({
        header: "",
        dataKey: MULTI_SELECT_DATAKEY,
        renderer: (d) => {
          return (
            <div>
              <Form.Check
                checked={currentlyToBeDeletedIds.indexOf(d.id) >= 0}
                type="checkbox"
                key={d.id}
                id={d.id}
                label=""
                onChange={() => toggleRecordModelSelection(d.id)}
              />
            </div>
          );
        },
      });
    }
    const pageRecordIds = currentPageData.map((r) => r.id);
    const isPageSelected =
      intersection(pageRecordIds, currentlyToBeDeletedIds).length ===
      pageRecordIds.length;
    content = (
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        isPageSelected={isPageSelected}
        onPageSelect={onPageRecordsSelect}
        noContent={
          <div className="table__no_content">
            <p>
              No data records found within the filtered time period. Try
              adjusting your filter.
            </p>
          </div>
        }
        viewControl={
          <>
            <GVDSTableCtrlContainer>
              <GVDSTableCtrlMultiSelect
                options={peopleMeters.map((m) => m.subtopic.name)}
                prefix="Subtopic"
                value={filterKeys.subtopic_name}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, subtopic_name: filterKs })
                }
              />
              <div className="date-range-container">
                <GVDSFormStartEndMonthPicker
                  startMonth={start}
                  endMonth={end}
                  onChange={(s, e) => {
                    if (s !== start) {
                      setStart(s);
                    }
                    if (e !== end) {
                      setEnd(e);
                    }
                  }}
                />
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            {isMultipleDeleteMode && (
              <GVDSTableBulkActionBar
                selectedRowsCount={currentlyToBeDeletedIds.length}
                actionButtons={
                  <>
                    <GVDSButton
                      variant={buttonVariant.destructive_tertiary}
                      disabled={currentlyToBeDeletedIds.length === 0}
                      onClick={(e) => {
                        e.preventDefault();
                        promptDeleteRecord(currentlyToBeDeletedIds);
                      }}
                      text="Delete selected records"
                    />
                    <GVDSButton
                      variant={buttonVariant.tertiary}
                      onClick={(e) => {
                        e.preventDefault();
                        setMultipleDeleteMode(false);
                        setCurrentlyToBeDeletedIds([]);
                      }}
                      text="Cancel"
                    />
                  </>
                }
              />
            )}
          </>
        }
      />
    );
  }

  return (
    <div>
      <div className="d-flex mb-2">
        <Spacer />
        {!permissionCtx.isLoadingPermissions &&
          permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_DELETE] &&
          !isMultipleDeleteMode && (
            <Dropdown>
              <Dropdown.Toggle as={OtherActionDropdownToggle} />

              <Dropdown.Menu>
                <Dropdown.Item
                  key="delete-multiple"
                  href="#"
                  onClick={() => setMultipleDeleteMode(true)}
                  disabled={allDataRecords.length === 0}
                >
                  <span className="color-red">Delete multiple records</span>
                </Dropdown.Item>
                <Dropdown.Item
                  key="delete-all"
                  href="#"
                  onClick={() =>
                    promptDeleteRecord(filteredSortedData.map((d) => d.id))
                  }
                  disabled={filteredSortedData.length === 0}
                >
                  <span className="color-red">
                    Delete all ({filteredSortedData.length}) records
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
      </div>
      {content}
      <InputPeopleRecordModal
        showModal={showEditModal}
        onClose={closeEditModal}
        meterDetails={
          currentlyEditedRecord
            ? peopleMeters.find((m) => m.id === currentlyEditedRecord.meter_id)
            : null
        }
        currentRecord={currentlyEditedRecord}
        onDoneInput={loadDataRecords}
      />
      <DeletePeopleRecordPrompt
        noOfRecordsToBeDeleted={
          currentlyToBeDeletedIds.length === allDataRecords.length &&
          allDataRecords.length > 1
            ? `ALL (${currentlyToBeDeletedIds.length})`
            : currentlyToBeDeletedIds.length
        }
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        proceed={deleteCurrentlySelectedRecords}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default ViewAllPeopleDataRecords;
