import React from "react";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import { NumberService } from "../../../../services/UtilsService";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PopoverOnBigNumberTruncated from "../../../common/Tooltip/PopoverOnBigNumberTruncated";
import {
  getChangeColorClass,
  getChangeIcon,
} from "../DashboardWidgetSharedUtils";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { uniqueId } from "lodash";
import DashboardDataGapsIndicator from "../DashboardDataGapsIndicator";

const COLLECTION_SUMMARY_CARDS_TILE_COUNT = 6;

const CollectionSummaryOneCardView = ({
  title,
  description,
  value,
  unit,
  change,
  changeDescription,
  isNegativeChangeGood = true,
  isDataComplete = true,
}) => {
  const isIncreasing = change > 0;
  const isShowChangePercentage =
    change && Number.isFinite(change) && change !== 0;

  const changeColorClass = getChangeColorClass(
    isShowChangePercentage,
    isNegativeChangeGood,
    isIncreasing
  );
  const changeIcon = getChangeIcon(isShowChangePercentage, isIncreasing);

  const numberDisplayValue =
    value === undefined || value === null ? (
      <div className="collection-summary-one-card__number">-</div>
    ) : (
      <PopoverOnBigNumberTruncated
        key={uniqueId()}
        numberValue={value}
        contentClassName="collection-summary-one-card__number"
        maximumFractionDigits={2}
        minimumFractionDigits={2}
      />
    );

  const changeDisplayValue =
    change === undefined || !Number.isFinite(change)
      ? "- "
      : NumberService.format(Math.abs(change), 1, 1);

  const isEmptyCard = title === undefined;

  const content = !isEmptyCard ? (
    <>
      <div className="collection-summary-one-card__title">
        <div className="gvds-text--heading4">{title}</div>
        {!!description && <InfoTooltip info={description} />}
        {!isDataComplete && <DashboardDataGapsIndicator />}
      </div>

      <div className="collection-summary-one-card__value">
        {numberDisplayValue}
        <span className="collection-summary-one-card__value__unit">{unit}</span>
      </div>

      <OverlayTrigger
        placement="bottom"
        overlay={
          <Popover>
            <Popover.Body>{changeDescription}</Popover.Body>
          </Popover>
        }
      >
        <div
          className={`collection-summary-one-card__change ${changeColorClass}`}
        >
          {changeIcon}
          <div className="gvds-text--label">{changeDisplayValue}%</div>
        </div>
      </OverlayTrigger>
    </>
  ) : null;

  return (
    <div className="collection-summary-one-card__container">{content}</div>
  );
};

const CollectionSummaryCardsView = ({ data, isLoading = false }) => {
  let content;
  if (isLoading) {
    content = (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else {
    const displayData = Array(COLLECTION_SUMMARY_CARDS_TILE_COUNT)
      .fill(null)
      .map((_, index) => {
        if (index >= data.length || data[index] === undefined) {
          return {};
        } else {
          return data[index];
        }
      });

    content = (
      <div className="collection-summary-cards__grid">
        {displayData.map((summary, index) => (
          <CollectionSummaryOneCardView
            key={index}
            title={summary.title}
            description={summary.description}
            value={summary.value}
            unit={summary.unit}
            change={summary.change}
            changeDescription={summary.changeDescription}
            isNegativeChangeGood={summary.isNegativeChangeGood}
            isDataComplete={summary.isDataComplete}
          />
        ))}
      </div>
    );
  }

  return <div className="collection-summary-cards__container">{content}</div>;
};

export default CollectionSummaryCardsView;
