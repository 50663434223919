import React, { useContext } from "react";
import UserProfileContext from "../../../context/UserProfileContext";
import { UserUtils } from "../../../services/UtilsService";
import Dropdown from "react-bootstrap/Dropdown";
import {
  PRIVACY_POLICY,
  TERMS_OF_USE,
  USER_PROFILE,
  VIEW_ALL_CONTRACTS,
} from "../../../config/ROUTES_NAME";
import { getRedirectURLWithCurrentParam } from "../../../components/common/QueryHandler";
import { useHistory, useLocation } from "react-router-dom";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { withAmplify } from "../../../context/AWSAmplifyContext";
import Spacer from "../../Layout/Spacer";
import GVDSIcon from "../../Icons/GVDSIcon";
import { IconExternalLink, IconLogout, IconTool } from "@tabler/icons-react";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../Buttons/GVDSIconButton";
import NavWrapperContext from "../NavWrapperContext";
import { useTranslation } from "react-i18next";

const ProfileDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className="profile-dropdown-toggle"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const SystemToolboxTrigger = ({ onClick }) => {
  const navWrapperContext = useContext(NavWrapperContext);

  return (
    <div>
      <GVDSIconButton
        className={
          "system-toolbox-trigger" +
          (navWrapperContext.isSystemToolbox ? " active" : "")
        }
        variant={iconButtonVariant.tertiary}
        icon={<GVDSIcon Icon={IconTool} />}
        onClick={onClick}
      />
    </div>
  );
};

const SideNavBottom = ({ amplify, isMinimised }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const isPortalAdmin = !!user?.is_admin;

  const userAttribute = user
    ? UserUtils.getUserAttribute(user.email, user.full_name)
    : { initial: "", hash: 0 };

  const goToProfile = () => {
    history.push(USER_PROFILE);
  };

  const signOut = () => {
    toastContext.clearAllToast();
    userProfileContext.clearUserProfile();
    userInventory.clearUserInventory();
    amplify.signOut();
    sessionStorage.clear();
  };

  const redirectTo = (path) => {
    history.push(getRedirectURLWithCurrentParam(path, location));
  };

  const viewPrivacyPolicy = () => {
    window.open(
      getRedirectURLWithCurrentParam(PRIVACY_POLICY, location),
      "_blank"
    );
  };

  const viewTermsOfUse = () => {
    window.open(
      getRedirectURLWithCurrentParam(TERMS_OF_USE, location),
      "_blank"
    );
  };

  if (isMinimised) {
    return (
      <div className="gvds-side-nav-bottom">
        {isPortalAdmin && (
          <SystemToolboxTrigger
            onClick={() => redirectTo(VIEW_ALL_CONTRACTS)}
          />
        )}
        <Dropdown
          id="side-nav-user-dropdown"
          key="transition--side-nav-user-dropdown"
          className="profile--dropdown-toggle-container"
        >
          <Dropdown.Toggle as={ProfileDropdownToggle}>
            <div className="profile--container">
              <div
                className={`profile--icon ${UserUtils.getAvatarColor(
                  userAttribute.hash
                )}`}
              >
                <span>{userAttribute.initial}</span>
              </div>
            </div>
          </Dropdown.Toggle>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className="gvds-side-nav-bottom guided-tour-target__new-look__side-nav-account">
      <Dropdown
        id="side-nav-user-dropdown"
        key="transition--side-nav-user-dropdown"
        className="profile--dropdown-toggle-container"
      >
        <Dropdown.Toggle as={ProfileDropdownToggle}>
          <div className="profile--container">
            <div
              className={`profile--icon ${UserUtils.getAvatarColor(
                userAttribute.hash
              )}`}
            >
              <span>{userAttribute.initial}</span>
            </div>
            <div className="profile--name">
              {UserUtils.getFullNameOrEmail(user?.full_name, user?.email)}
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu align="start" className="profile-dropdown-menu">
          <Dropdown.Item onClick={goToProfile}>
            {t("settings.title")}
            <Spacer />
            <div className="icon-container">
              <GVDSIcon Icon={IconExternalLink} />
            </div>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={viewPrivacyPolicy}>
            <div>{t("privacy-policy.title")}</div>
          </Dropdown.Item>
          <Dropdown.Item onClick={viewTermsOfUse}>
            <div>{t("terms-of-use.title")}</div>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item className="logout-button" onClick={signOut}>
            {t("profile-menu.logout")}
            <Spacer />
            <div className="icon-container">
              <GVDSIcon Icon={IconLogout} />
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {isPortalAdmin && (
        <>
          <Spacer />
          <SystemToolboxTrigger
            onClick={() => redirectTo(VIEW_ALL_CONTRACTS)}
          />
        </>
      )}
    </div>
  );
};

export default withAmplify(SideNavBottom);
