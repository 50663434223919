import PageHeader from "../../gvds-components/Layout/PageHeader";
import Container from "react-bootstrap/Container";
import React, { useContext, useEffect, useRef, useState } from "react";
import withAuthentication from "../HOC/withAuthentication";
import { SORTING_TYPES } from "../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../gvds-components/Table/GVDSTableHook";
import { PolicyTrackerSearchKeys } from "../../config/search-config";
import clone from "lodash/clone";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import { getPolicyTrackerDetailsPath } from "../../config/ROUTES_NAME";
import { useHistory, useLocation } from "react-router-dom";
import { StringUtils } from "../../services/UtilsService";
import UserInventoryContext from "../../context/UserInventoryContext";
import PolicyTrackerService from "../../services/PolicyTrackerService";
import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import { RESOURCES } from "../../config/constants";
import PolicyTrackerSummary from "./components/PolicyTrackerSummary";
import PolicyTrackerTable from "./components/PolicyTrackerTable";
import PolicyTrackerFilter, {
  StickyPolicyTrackerFilter,
} from "./components/PolicyTrackerFilter";
import AnnouncementBarContext from "../../gvds-components/AnnouncementBar/AnnouncementBarContext";
import SavedFiltersService from "../../services/SavedFiltersService";
import { useTranslation } from "react-i18next";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconHelp } from "@tabler/icons-react";
import { AboutPolicyTrackerModal } from "./PolicyTrackerInfo";

const PolicyTracker = () => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const announcementBarContext = useContext(AnnouncementBarContext);
  const history = useHistory();
  const location = useLocation();
  const toastContext = useContext(ToastContext);

  const filterSectionRef = useRef(null);
  const [isFilterSectionSticky, setIsFilterSectionSticky] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAboutPolicyTrackerModal, setShowAboutPolicyTrackerModal] =
    useState(false);
  const closeAboutPolicyTrackerModal = () => {
    setShowAboutPolicyTrackerModal(false);
  };
  const minimumYPositionFilterStillInViewport = useRef();

  useEffect(() => {
    if (announcementBarContext.show) {
      minimumYPositionFilterStillInViewport.current = 90;
    } else {
      minimumYPositionFilterStillInViewport.current = 50;
    }
  }, [announcementBarContext.show]);

  useEffect(() => {
    const currentInventory = userInventory.selectedInventory.get;
    if (currentInventory) {
      setIsLoading(true);

      PolicyTrackerService.getPolicies(
        currentInventory.type,
        currentInventory.id
      )
        .then((response) => {
          setPolicies(response);
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to load policies.</span>);
        })
        .finally(() => {
          setIsLoading(false);
        });

      if (currentInventory.type === RESOURCES.SITE) {
        filterKeys["region"] = [];
        filterKeys["countryName"] = [];
        filterKeys["state"] = [];
        filterKeys["city"] = [];
        filterKeys["siteName"] = [];
        setFilterKeys(clone(filterKeys));
        setSearchText("");
      }
    }
  }, [userInventory.selectedInventory.get]);

  useEffect(() => {
    const handleScroll = () => {
      if (filterSectionRef.current) {
        const rect = filterSectionRef.current.getBoundingClientRect();
        if (rect.bottom < minimumYPositionFilterStillInViewport.current) {
          setIsFilterSectionSticky(true);
        } else {
          setIsFilterSectionSticky(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const goToPolicyTrackerDetailsPage = (policyId) => {
    history.push(
      getRedirectURLWithCurrentParam(
        getPolicyTrackerDetailsPath(policyId),
        location
      )
    );
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    policies,
    PolicyTrackerSearchKeys,
    {
      priorityLevelOrderIndex: SORTING_TYPES.asc,
      complianceObligationOrderIndex: SORTING_TYPES.asc,
      jurisdictionLevelOrderIndex: SORTING_TYPES.asc,
    },
    {},
    SavedFiltersService.SAVED_FILTER__POLICY_TRACKER,
  );

  const onPriorityLevelClick = (priorityLevel) => {
    const titleCasePriorityLevel = StringUtils.getTitleCase(priorityLevel);
    const selectedPriorityLevel = filterKeys["priorityLevel"] || [];

    if (selectedPriorityLevel.includes(titleCasePriorityLevel)) {
      filterKeys["priorityLevel"] = selectedPriorityLevel.filter(
        (pl) => pl !== titleCasePriorityLevel
      );
    } else {
      filterKeys["priorityLevel"] = [titleCasePriorityLevel];
    }

    setFilterKeys(clone(filterKeys));
  };

  const onTopicClick = (topicName) => {
    const selectedTopics = filterKeys["topics"] || [];

    if (selectedTopics.includes(topicName)) {
      filterKeys["topics"] = selectedTopics.filter(
        (topic) => topic !== topicName
      );
    } else {
      filterKeys["topics"] = [topicName];
    }

    setFilterKeys(clone(filterKeys));
  };

  const isPortfolio =
    userInventory.selectedInventory.get?.type === RESOURCES.PORTFOLIO;

  let content;

  content = (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("policy-tracker.page-title")}</h1>
          <GVDSIconButton
            variant={iconButtonVariant.tertiary}
            onClick={() => setShowAboutPolicyTrackerModal(true)}
            icon={<GVDSIcon Icon={IconHelp} />}
            tooltipText="About Policy Tracker"
            className="ms-2"
          />
        </PageHeader.Title>
        <PageHeader.Description>
          {isPortfolio
            ? t("policy-tracker.page-description-portfolio")
            : t("policy-tracker.page-description-site")}
        </PageHeader.Description>
      </PageHeader>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isFilterSectionSticky && (
            <StickyPolicyTrackerFilter
              isPortfolio={isPortfolio}
              policies={policies}
              filterKeys={filterKeys}
              setFilterKeys={setFilterKeys}
              isAnnouncementBarVisible={announcementBarContext.show}
            />
          )}
          <PolicyTrackerFilter
            isPortfolio={isPortfolio}
            policies={policies}
            filterKeys={filterKeys}
            setFilterKeys={setFilterKeys}
            ref={filterSectionRef}
          />
          <div className="gvds-text--heading3 mb-3">
            Your policies at a glance
          </div>
          <PolicyTrackerSummary
            filteredPolicies={filteredSortedData}
            onPriorityLevelClick={onPriorityLevelClick}
            onTopicClick={onTopicClick}
          />
          <PolicyTrackerTable
            isPortfolio={isPortfolio}
            policies={policies}
            currentPageData={currentPageData}
            startIndex={startIndex}
            endIndex={endIndex}
            sortKeys={sortKeys}
            setSortKeys={setSortKeys}
            filteredSortedData={filteredSortedData}
            searchText={searchText}
            setSearchText={setSearchText}
            totalDataLength={totalDataLength}
            onPaginationChange={onPaginationChange}
            gotoDetailsPage={goToPolicyTrackerDetailsPage}
          />
        </>
      )}
      <AboutPolicyTrackerModal
        show={showAboutPolicyTrackerModal}
        close={closeAboutPolicyTrackerModal}
      />
    </Container>
  );

  return content;
};

export default withAuthentication(PolicyTracker);
