import axios from "axios";
import { API_URL } from "../config/api-config";

export default class UserApprovalRequestService {
  static getPendingRequests = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/user_approval_requests`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static approveRequest = async (request_id) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/user_approval_requests/${request_id}/approve`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static rejectRequest = async (request_id) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/user_approval_requests/${request_id}/reject`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
