import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import {
  FormFieldState,
  FormFieldStatusMetadata,
} from "../../gvds-components/Forms/GVDSFormShared";
import {
  GenericOption,
  MultiValueContainer, MultiValueRemove,
} from "../../gvds-components/Forms/GVDSFormMultiSelect";

const components = {
  DropdownIndicator: null,
  Option: GenericOption,
  MultiValueContainer: MultiValueContainer,
  MultiValueRemove: MultiValueRemove,
};

const MultiTextInput = ({
  value,
  onChange,
  className,
  placeholder = "Type something and press enter",
  disabledPlaceholder = "Nothing yet",
  isDisabled = false,
  statusMetadata = FormFieldStatusMetadata.getDefault(),
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (newValueAsOptions, actionMeta) => {
    onChange(newValueAsOptions.map((o) => o.value));
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
      case " ":
        setInputValue("");
        value ? onChange([...value, inputValue]) : onChange([inputValue]);
        event.preventDefault();
    }
  };

  const valueAsOptions = value
    ? value.map((v) => {
        return { label: v, value: v };
      })
    : [];

  const gvdsClassNames = ["gvds-form__multi-select"].concat(
    statusMetadata.state === FormFieldState.warning
      ? ["gvds-form__warning"]
      : statusMetadata.state === FormFieldState.error
      ? ["gvds-form__error"]
      : []
  );

  const combinedClassNames = `${gvdsClassNames.join(" ")} ${className || ""}`;

  return (
    <CreatableSelect
      components={components}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className={combinedClassNames}
      classNamePrefix="gvds-multi-select"
      isDisabled={isDisabled}
      placeholder={isDisabled ? disabledPlaceholder : placeholder}
      inputValue={inputValue}
      value={valueAsOptions}
    />
  );
};

export default MultiTextInput;
