import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Table } from "react-bootstrap";

import LoadingSpinner from "../../common/LoadingSpinner";
import ToastContext from "../../../context/ToastContext";
import { REF_DATA_PORTAL_MODULE_LINKS } from "../../../config/ROUTES_NAME";
import PortalModuleLinkService from "../../../services/ReferenceDataServices/PortalModuleLinkService";

const PortalWideDataTable = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const headerName = "PORTAL - WIDE";

  const [activeKey, setActiveKey] = useState(headerName);

  const [portalModuleLinks, setPortalModuleLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    PortalModuleLinkService.getAllPortalModuleLinks()
      .then((response) => {
        setIsLoading(false);
        setPortalModuleLinks(response);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to get portal module links.</span>
        );
      });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Accordion
      key={headerName}
      defaultActiveKey={headerName}
      activeKey={activeKey === headerName ? activeKey : null}
      className="accordion-header"
    >
      <Accordion.Item eventKey={headerName}>
        <Card.Header
          onClick={() =>
            activeKey === headerName
              ? setActiveKey(null)
              : setActiveKey(headerName)
          }
        >
          <div className="body-2-bold">
            {headerName.toUpperCase()}{" "}
            <span className="collapse-toggle">
              {activeKey === headerName ? "(collapse)" : "(expand)"}
            </span>
          </div>
        </Card.Header>
        <Accordion.Body>
          <Table className="reference-data-table section-box">
            <tbody>
              <tr>
                <td className="td-level">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(REF_DATA_PORTAL_MODULE_LINKS);
                    }}
                  >
                    Portal Module Links
                  </a>
                </td>

                <td className="td-level reference-data-table-entry">
                  {portalModuleLinks.slice(0, 2).map((module) => (
                    <span key={module.id}>{module.name + ", "}</span>
                  ))}
                  {portalModuleLinks.length > 0 ? (
                    <span>..etc</span>
                  ) : (
                    <span>No Record Found</span>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default PortalWideDataTable;
