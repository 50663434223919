import React, { useContext, useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import PeopleDashboard from "./PeopleDashboard";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import { PEOPLE_DATA_SETUP } from "../../../config/ROUTES_NAME";
import withAuthentication from "../../HOC/withAuthentication";
import ViewAllPeopleDataRecords from "./ViewAllPeopleDataRecords";
import PeopleService from "../../../services/PeopleService";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import PermissionsContext from "../../../context/PermissionsContext";
import { useHistory, useLocation } from "react-router-dom";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconSettings } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const People = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const selectedInventory = userInventory.selectedInventory.get;
  const [peopleMeters, setPeopleMeters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(new Date());

  const reloadMeters = () => {
    setIsLoading(true);
    if (selectedInventory?.type === RESOURCES.SITE) {
      PeopleService.getPeopleMeters(selectedInventory.id)
        .then((peopleMetersResponse) => {
          setPeopleMeters(peopleMetersResponse);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to load people meters.</span>);
        });
    }
  };

  useEffect(() => {
    if (selectedInventory?.id) {
      reloadMeters();
    }
  }, [selectedInventory, lastUpdate]);

  const goToSetup = () => {
    history.push(getRedirectURLWithCurrentParam(PEOPLE_DATA_SETUP, location));
  };

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <Tabs defaultActiveKey="data-input">
        <Tab eventKey="data-input" title="Data Input">
          <PeopleDashboard
            peopleMeters={peopleMeters}
            onUpdate={() => setLastUpdate(new Date())}
          />
        </Tab>
        <Tab eventKey="history" title="All Records">
          <ViewAllPeopleDataRecords
            peopleMeters={peopleMeters}
            lastUpdate={lastUpdate}
          />
        </Tab>
      </Tabs>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("data-management.people.page-title")}</h1>
          <Spacer />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[PERMISSIONS.PEOPLE_METER_MANAGEMENT] && (
              <GVDSButton
                variant={buttonVariant.secondary}
                className="people-setup"
                onClick={() => goToSetup()}
                icon={<GVDSIcon Icon={IconSettings} />}
                text="Setup"
              />
            )}
        </PageHeader.Title>
      </PageHeader>
      {content}
    </div>
  );
};

export default withAuthentication(People);
