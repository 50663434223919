import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";

import withAuthentication from "../../HOC/withAuthentication";
import ViewAllInitiatives from "./ViewAllInitiatives";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";

import PermissionsContext from "../../../context/PermissionsContext";
import InitiativeDashboard from "./InitiativeDashboard";
import InputInitiative from "./InputInitiative";
import InitiativeService from "../../../services/InitiativeService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";
import RoutePersistedTabs from "../../common/Tabs/RoutePersistedTabs";
import { useTranslation } from "react-i18next";
import { getInitiativeSubtopicDescription } from "./InitiativeDisplayComponents";

const Initiatives = () => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const permissionCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);

  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [initiativeOptions, setInitiativeOptions] = useState(null);
  const [showInputInitiativeModal, setShowInputInitiativeModal] =
    useState(false);

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;

    if (selectedInventory && selectedInventory.type === RESOURCES.SITE) {
      InitiativeService.getAllInitiativeOption(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((response) => {
          setInitiativeOptions(response);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load initiative options.</span>
          );
        });
    } else {
      setInitiativeOptions(null);
    }
  }, [userInventory.selectedInventory.get]);

  const getInputNewInitiativeButton = () => {
    return (
      <GVDSButton
        variant={buttonVariant.primary}
        className="log-initiative guided-tour-target__initiative-input"
        onClick={() => setShowInputInitiativeModal(true)}
        icon={<GVDSIcon Icon={IconCirclePlus} />}
        text={t("data-management.initiatives.button-log-initiatives")}
      />
    );
  };

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("data-management.initiatives.page-title")}</h1>
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[PERMISSIONS.INITIATIVE_RECORD_CREATE] && (
              <>
                <Spacer />
                {getInputNewInitiativeButton()}
              </>
            )}
        </PageHeader.Title>
        <PageHeader.Description>
          {t("data-management.initiatives.page-description")}
          <ul>
            {Object.entries(getInitiativeSubtopicDescription(t)).map(
              ([subtopic, description]) => {
                return (
                  <li key={subtopic}>
                    <strong>{subtopic}</strong> - {description}
                  </li>
                );
              }
            )}
          </ul>
        </PageHeader.Description>
      </PageHeader>
      <RoutePersistedTabs defaultActiveKey="active-initiatives">
        <Tab
          eventKey="active-initiatives"
          title={t("data-management.initiatives.tab-active-initiatives")}
          tabClassName="tab-nav guided-tour-target__active-initiatives"
        >
          <InitiativeDashboard
            initiativeOptions={initiativeOptions}
            lastUpdate={lastUpdate}
            onUpdate={() => setLastUpdate(new Date())}
          />
        </Tab>
        <Tab
          eventKey="all-initiatives"
          title={t("data-management.initiatives.tab-all-initiatives")}
          tabClassName="tab-nav guided-tour-target__all-initiatives"
        >
          <ViewAllInitiatives
            initiativeOptions={initiativeOptions}
            lastUpdate={lastUpdate}
            onUpdate={() => setLastUpdate(new Date())}
          />
        </Tab>
      </RoutePersistedTabs>
      <InputInitiative
        initiativeOptions={initiativeOptions}
        onSuccess={() => setLastUpdate(new Date())}
        show={showInputInitiativeModal}
        setShow={setShowInputInitiativeModal}
      />
    </div>
  );
};
export default withAuthentication(Initiatives);
