import * as XLSX from "xlsx";
import ExcelExporterService from "../../services/ExcelExporterService";

export class DashboardTableExcelDownloader {
  static downloadExcel(
    headerRows,
    dataRows,
    fileNameWithoutExtension,
    sheetName = null,
    customColWidths = null
  ) {
    const worksheet = XLSX.utils.aoa_to_sheet([...headerRows, ...dataRows]);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      sheetName ?? fileNameWithoutExtension
    );

    worksheet["!cols"] = ExcelExporterService.setColumnWidths(
      worksheet,
      customColWidths
    );

    XLSX.writeFile(workbook, `${fileNameWithoutExtension}.xlsx`, {
      compression: true,
    });
  }
}
