import React, { Fragment } from "react";
import { getComparisonColumns } from "./PerformanceReportTableUtils";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import SafeDataService from "../../../../services/SafeDataService";

const EACOffsetTypeName = "Energy Attribute Certificate (EAC)";
const Scope3EnergyFuelPerformanceGroup =
  "Scope 3 Fuel and Energy Related Activities Emissions";

const costColumns = (
  <>
    <td className="performance-report-table__number-cell">N/A</td>
    <td className="performance-report-table__number-cell">N/A</td>
    <td className="performance-report-table__number-cell">N/A</td>
  </>
);

const MarketPurchaseOffsetsBreakdown = ({
  offsetTypeNames,
  performanceGroupName,
  firstYear,
  secondYear,
}) => {
  return offsetTypeNames.sort().map((t, index) => {
    if (
      performanceGroupName === Scope3EnergyFuelPerformanceGroup &&
      t === EACOffsetTypeName
    ) {
      const firstYearBundledEACOffsetTypeValue =
        firstYear.getAvailableBundledEACOffsetValueByType(t);
      const secondYearBundledEACOffsetTypeValue =
        secondYear.getAvailableBundledEACOffsetValueByType(t);
      const firstYearUnbundledEACOffsetTypeValue =
        firstYear.getAvailableUnbundledEACOffsetValueByType(t);
      const secondYearUnbundledEACOffsetTypeValue =
        secondYear.getAvailableUnbundledEACOffsetValueByType(t);

      const hasBundled =
        firstYearBundledEACOffsetTypeValue.value > 0 ||
        secondYearBundledEACOffsetTypeValue.value > 0;
      const hasUnbundled =
        firstYearUnbundledEACOffsetTypeValue.value > 0 ||
        secondYearUnbundledEACOffsetTypeValue.value > 0;

      let bundledDisplay = null;
      let unbundledDisplay = null;

      if (hasBundled) {
        bundledDisplay = (
          <>
            <tr className="performance-report-table__nested_details_row">
              <td colSpan={7}>Bundled {t}</td>
            </tr>
            <tr className="performance-report-table__nested_details_row breakdown">
              <td>Purchased</td>
              {getComparisonColumns(
                firstYearBundledEACOffsetTypeValue,
                secondYearBundledEACOffsetTypeValue,
                "",
                "",
                false
              )}
              {costColumns}
            </tr>
            <tr className="performance-report-table__nested_details_row breakdown">
              <td>To be deducted</td>
              {getComparisonColumns(
                firstYear.getUsedBundledEACOffsetValueByType(t),
                secondYear.getUsedBundledEACOffsetValueByType(t),
                "",
                "",
                false
              )}
              {costColumns}
            </tr>
            <tr className="performance-report-table__nested_details_row breakdown">
              <td>Surplus (if any)</td>
              {getComparisonColumns(
                firstYear.getSurplusBundledEACOffsetValueByType(t),
                secondYear.getSurplusBundledEACOffsetValueByType(t),
                "",
                "",
                false
              )}
              {costColumns}
            </tr>
          </>
        );
      }

      if (hasUnbundled) {
        unbundledDisplay = (
          <>
            <>
              <tr className="performance-report-table__nested_details_row">
                <td colSpan={7}>Unbundled {t}</td>
              </tr>
              <tr className="performance-report-table__nested_details_row breakdown">
                <td>Purchased</td>
                {getComparisonColumns(
                  firstYearUnbundledEACOffsetTypeValue,
                  secondYearUnbundledEACOffsetTypeValue,
                  "",
                  "",
                  false
                )}
                {costColumns}
              </tr>
              <tr className="performance-report-table__nested_details_row breakdown">
                <td>To be deducted</td>
                {getComparisonColumns(
                  firstYear.getUsedUnbundledEACOffsetValueByType(t),
                  secondYear.getUsedUnbundledEACOffsetValueByType(t),
                  "",
                  "",
                  false
                )}
                {costColumns}
              </tr>
              <tr className="performance-report-table__nested_details_row breakdown">
                <td>Surplus (if any)</td>
                {getComparisonColumns(
                  firstYear.getSurplusUnbundledEACOffsetValueByType(t),
                  secondYear.getSurplusUnbundledEACOffsetValueByType(t),
                  "",
                  "",
                  false
                )}
                {costColumns}
              </tr>
            </>
          </>
        );
      }

      return (
        <Fragment key={index}>
          {bundledDisplay}
          {unbundledDisplay}
        </Fragment>
      );
    } else {
      const availableFirstYear = firstYear.getAvailableOffsetTypeValue(t);
      const toBeDeductedFirstYear = firstYear.getUsedOffsetTypeValue(t);
      const surplusFirstYear = SafeDataService.minus(
        availableFirstYear,
        toBeDeductedFirstYear
      );

      const availableSecondYear = secondYear.getAvailableOffsetTypeValue(t);
      const toBeDeductedSecondYear = secondYear.getUsedOffsetTypeValue(t);
      const surplusSecondYear = SafeDataService.minus(
        availableSecondYear,
        toBeDeductedSecondYear
      );

      return (
        <Fragment key={index}>
          <tr className="performance-report-table__nested_details_row">
            <td colSpan={7}>{t}</td>
          </tr>
          <tr className="performance-report-table__nested_details_row breakdown">
            <td>Purchased</td>
            {getComparisonColumns(
              availableFirstYear,
              availableSecondYear,
              "",
              "",
              false
            )}
            {costColumns}
          </tr>
          <tr className="performance-report-table__nested_details_row breakdown">
            <td>To be deducted</td>
            {getComparisonColumns(
              toBeDeductedFirstYear,
              toBeDeductedSecondYear,
              "",
              "",
              false
            )}
            {costColumns}
          </tr>
          <tr className="performance-report-table__nested_details_row breakdown">
            <td>Surplus (if any)</td>
            {getComparisonColumns(
              surplusFirstYear,
              surplusSecondYear,
              "",
              "",
              false
            )}
            {costColumns}
          </tr>
        </Fragment>
      );
    }
  });
};

const MarketPurchaseOffsets = ({
  columnGroups,
  firstYear,
  secondYear,
  performanceGroupName,
}) => {
  if (!firstYear.totalMarketPurchaseDeduction || !secondYear.totalMarketPurchaseDeduction)
    return;

  const offsetTypeNames = uniq([
    ...firstYear.getOffsetTypeNames(),
    ...secondYear.getOffsetTypeNames(),
  ]);

  return (
    <>
      <tr className="performance-report-table__market_purchase_header_row">
        <td className="divide-border-right" colSpan="7">
          <span className="gvds-text--body__bold">Market Purchase</span>
          <span className="gvds-text--caption__italic ms-1">
            (Normalized for report period; shows surplus if any)
          </span>
        </td>
      </tr>
      <MarketPurchaseOffsetsBreakdown
        offsetTypeNames={offsetTypeNames}
        performanceGroupName={performanceGroupName}
        firstYear={firstYear}
        secondYear={secondYear}
      />
      <tr className="performance-report-table__subtotal_row">
        <td className="gvds-text--body__bold">
          Total Market Purchase Amount to be deducted
        </td>
        {getComparisonColumns(
          firstYear.totalMarketPurchaseDeduction,
          secondYear.totalMarketPurchaseDeduction,
          "",
          "",
          false
        )}
        <td className="performance-report-table__number-cell">N/A</td>
        <td className="performance-report-table__number-cell">N/A</td>
        <td className="performance-report-table__number-cell">N/A</td>
      </tr>
      <tr className="performance-report-table__total_row">
        <td>
          <span className="gvds-text--body__bold">Total</span>{" "}
          <span className="gvds-text--caption__italic">
            (with market purchase deduction)
          </span>
        </td>
        {sortBy(columnGroups, "seq").flatMap((c) =>
          c["market_based_enabled"] ? (
            <Fragment key={c["data_key"]}>
              {getComparisonColumns(
                firstYear.totalAfterOffsets,
                secondYear.totalAfterOffsets
              )}
            </Fragment>
          ) : (
            <Fragment key={c["data_key"]}>
              <td className="performance-report-table__number-cell">N/A</td>
              <td className="performance-report-table__number-cell">N/A</td>
              <td className="performance-report-table__number-cell">N/A</td>
            </Fragment>
          )
        )}
      </tr>
    </>
  );
};

export default MarketPurchaseOffsets;
