import React, { useContext, useEffect, useState } from "react";
import intersection from "lodash/intersection";
import uniq from "lodash/uniq";
import ToastContext from "../../../context/ToastContext";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";

import LoadingSpinner from "../../common/LoadingSpinner";
import SurveyManagementService from "../../../services/SurveyManagementService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { NumberService, UtilsService } from "../../../services/UtilsService";
import { getViewSurveyPath } from "../../../config/ROUTES_NAME";
import { generateSelectedQueryParam } from "../../common/QueryHandler";
import { RESOURCES } from "../../../config/constants";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { surveySiteResponseSearchKeys } from "../../../config/search-config";
import GVDSTable, {
  MULTI_SELECT_DATAKEY,
  SORTING_TYPES,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCheck, IconCircleCheck } from "@tabler/icons-react";

const isSiteStillInPortfolio = (siteId, portfolioTreeNode) => {
  if (portfolioTreeNode) {
    const portfolioSiteNodeValues = portfolioTreeNode.children
      .map((c) => c.nodeValue)
      .filter((c) => c.type === RESOURCES.SITE);
    return portfolioSiteNodeValues.find(
      (siteNodeValue) => siteNodeValue.id === siteId
    );
  } else {
    return false;
  }
};

const SurveyResponses = ({ surveyModel, isAllowedToManageSurvey }) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);

  const [surveyAssessments, setSurveyAssessments] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(surveyAssessments, surveySiteResponseSearchKeys, {
    site_name: SORTING_TYPES.asc,
  });

  useEffect(() => {
    if (surveyModel.isNotDraft()) {
      const selectedInventory = userInventory.selectedInventory.get;

      SurveyManagementService.getSurveyResponses(
        selectedInventory.type,
        selectedInventory.id,
        surveyModel.id
      ).then((assessments) => {
        setSurveyAssessments(assessments);
        setIsLoading(false);
      });
    }
  }, [surveyModel]);

  const getSiteSurveyAssessmentLink = (surveyId, siteId) => {
    return (
      getViewSurveyPath(surveyId) +
      "?" +
      generateSelectedQueryParam(
        RESOURCES.SITE,
        siteId,
        userInventory.selectedContractId.get
      )
    );
  };

  const downloadReport = async () => {
    const selectedInventory = userInventory.selectedInventory.get;

    try {
      await SurveyManagementService.getReport(
        selectedInventory.type,
        selectedInventory.id,
        surveyModel.id
      );
    } catch {
      toastContext.addFailToast(<span>Failed to download report</span>);
    }
  };

  let content;

  if (!surveyModel.isNotDraft()) {
    content = (
      <div>This page will only be available after this survey is launched.</div>
    );
  } else {
    if (isLoading) {
      content = <LoadingSpinner />;
    } else {
      const columns = [
        {
          header: "Site Name",
          dataKey: "site_name",
          headerStyle: { minWidth: "200px" },
          sortable: true,
          renderer: (s) => {
            return isSiteStillInPortfolio(
              s.site_id,
              userInventory.selectedTreeNode.get
            ) ? (
              <a
                href={getSiteSurveyAssessmentLink(s.survey_id, s.site_id)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {s.site_name}
              </a>
            ) : (
              <span>
                {s.site_name}{" "}
                <InfoTooltip
                  info={
                    <div>
                      This site is no longer in the portfolio.
                      <br />
                      <br />
                      To see its survey answer, download the survey responses
                      above.
                    </div>
                  }
                />
              </span>
            );
          },
        },
        {
          header: "Marked as Completed",
          dataKey: "assessment_complete",
          headerStyle: { width: "200px", textAlign: "center" },
          sortable: true,
          renderer: (s) => {
            return s.assessment_complete ? (
              <div className="d-flex align-items-center justify-content-center color-green">
                <GVDSIcon Icon={IconCheck} />
              </div>
            ) : null;
          },
        },
        {
          header: "Questions Answered",
          dataKey: "completed_questions",
          headerStyle: { maxWidth: "300px", width: "300px" },
          renderer: (s) => {
            const progress = NumberService.format(
              (100 * s.completed_questions) / s.total_questions
            );
            return (
              <div className="survey-progress">
                <ProgressBar now={progress} />
                <div className="d-flex">
                  <div className="ms-1 caption">
                    {s.completed_questions}/{s.total_questions}
                  </div>
                  <div className="ms-1 caption">({progress}%)</div>
                </div>
              </div>
            );
          },
        },
      ];

      content = (
        <>
          <div className="mb-5">
            <div className="mb-1">
              This excel sheet contains the aggregated results for each
              question, as well as the survey response by each individual site.
            </div>
            <GVDSButtonWithLoadingAction
              variant={buttonVariant.primary}
              onClickAsyncFunc={downloadReport}
              text="Download Excel"
            />
          </div>
          <div>
            <PageHeader>
              <PageHeader.Title>
                <h2>Sites Involved ({surveyAssessments.length})</h2>
                {isAllowedToManageSurvey && surveyModel.isOpen() && (
                  <div className="ms-auto">
                    <GVDSButton
                      variant={buttonVariant.primary}
                      onClick={() => setShowResendEmailModal(true)}
                      text="Resend Email"
                    />
                  </div>
                )}
              </PageHeader.Title>
            </PageHeader>

            <GVDSTableCtrlContainer>
              <FilterSearchBox
                placeholder="Search by Site Name"
                value={searchText}
                onInput={setSearchText}
              />
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            <GVDSTable
              columns={columns}
              dataToDisplay={currentPageData}
              startIndex={startIndex}
              sortKeys={sortKeys}
              setSortKeys={setSortKeys}
            />
            {filteredSortedData.length === 0 && (
              <div className="table__no_content">No record found</div>
            )}
          </div>
          <ResendEmailSiteSelectionList
            surveyModel={surveyModel}
            surveyAssessments={filteredSortedData}
            setShowResendEmailModal={setShowResendEmailModal}
            showResendEmailModal={showResendEmailModal}
          />
        </>
      );
    }
  }

  return (
    <div>
      <h2>Survey Responses</h2>
      {content}
    </div>
  );
};

export default SurveyResponses;

const ResendEmailSiteSelectionList = ({
  surveyModel,
  surveyAssessments,
  setShowResendEmailModal,
  showResendEmailModal,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const toggleSelection = (siteId) => {
    setSelectedSiteIds(UtilsService.toggleItem(selectedSiteIds, siteId));
  };
  const closeResendEmailModal = () => {
    setShowResendEmailModal(false);
    setSelectedSiteIds([]);
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(surveyAssessments, surveySiteResponseSearchKeys, {
    site_name: SORTING_TYPES.asc,
  });

  const resendEmail = () => {
    setIsResendingEmail(true);
    const selectedInventory = userInventory.selectedInventory.get;

    SurveyManagementService.resendEmail(
      selectedInventory.type,
      selectedInventory.id,
      surveyModel.id,
      selectedSiteIds
    )
      .then(() => {
        closeResendEmailModal();
        setIsResendingEmail(false);
        toastContext.addSuccessToast(
          <span>You have resent the email to the selected sites.</span>
        );
      })
      .catch(() => {
        setIsResendingEmail(false);
        toastContext.addFailToast(<span>Failed to resend email</span>);
      });
  };

  const onResendEmailTablePageSelect = () => {
    const pageSiteIdsForResend = currentPageData.map((s) => s.site_id);
    const intersectIds = intersection(selectedSiteIds, pageSiteIdsForResend);
    if (intersectIds.length === pageSiteIdsForResend.length) {
      setSelectedSiteIds(
        selectedSiteIds.filter((id) => pageSiteIdsForResend.indexOf(id) < 0)
      );
    } else {
      setSelectedSiteIds(uniq([...selectedSiteIds, ...pageSiteIdsForResend]));
    }
  };

  const selectionColumns = [
    {
      header: "",
      dataKey: MULTI_SELECT_DATAKEY,
      renderer: (s) => {
        return (
          <div>
            <Form.Check
              checked={selectedSiteIds.indexOf(s.site_id) >= 0}
              type="checkbox"
              key={s.site_id}
              id={s.site_id}
              label=""
              onChange={() => toggleSelection(s.site_id)}
            />
          </div>
        );
      },
    },
    {
      header: "Site Name",
      dataKey: "site_name",
      headerStyle: { minWidth: "200px" },
      sortable: true,
    },
    {
      header: "Marked as Completed",
      dataKey: "assessment_complete",
      headerStyle: { width: "200px", textAlign: "center" },
      sortable: true,
      renderer: (s) => {
        return s.assessment_complete ? (
          <div className="d-flex align-items-center justify-content-center color-green">
            <GVDSIcon Icon={IconCircleCheck} />
          </div>
        ) : null;
      },
    },
    {
      header: "Questions Answered",
      dataKey: "completed_questions",
      headerStyle: { maxWidth: "300px", width: "300px" },
      renderer: (s) => {
        const progress = NumberService.format(
          (100 * s.completed_questions) / s.total_questions
        );
        return (
          <div className="survey-progress">
            <ProgressBar now={progress} />
            <div className="d-flex">
              <div className="ms-1 caption">
                {s.completed_questions}/{s.total_questions}
              </div>
              <div className="ms-1 caption">({progress}%)</div>
            </div>
          </div>
        );
      },
    },
  ];

  const pageSiteIdsForResend = currentPageData.map((s) => s.site_id);
  const isPageSelected =
    intersection(pageSiteIdsForResend, selectedSiteIds).length ===
    pageSiteIdsForResend.length;

  return (
    <GVDSModal
      title="Resend Email"
      size={GVDSModal.Size.medium}
      show={showResendEmailModal}
      onHide={closeResendEmailModal}
    >
      <GVDSModal.Body>
        {isResendingEmail ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="mb-2">
              Select sites that you wish to resend email to.
            </div>
            <GVDSTableCtrlContainer>
              <div className="w-25 me-2">
                <FilterSearchBox
                  placeholder="Search by Site Name"
                  value={searchText}
                  onInput={setSearchText}
                />
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            <GVDSTable
              columns={selectionColumns}
              dataToDisplay={currentPageData}
              startIndex={startIndex}
              sortKeys={sortKeys}
              setSortKeys={setSortKeys}
              isPageSelected={isPageSelected}
              onPageSelect={onResendEmailTablePageSelect}
            />
            {filteredSortedData.length === 0 && (
              <div className="table__no_content">No record found.</div>
            )}
          </>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={closeResendEmailModal}
          disabled={isResendingEmail}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={resendEmail}
          disabled={isResendingEmail}
          text="Resend Email"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};
