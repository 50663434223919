import axios from "axios";
import { API_URL } from "../config/api-config";

export class ProvinceList {
  constructor(province) {
    this.id = province.id;
    this.name = province.name;
    this.country_name = province.country.name;
    this.country_id = province.country.id;
  }
  get country_name_list() {
    return this.country_name;
  }
}

export default class CountryService {
  static getAllCountry = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/ref_data/countries");
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  static getAllProvinces = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/ref_data/provinces");
      return response.data.map((p) => new ProvinceList(p));
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}
