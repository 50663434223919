import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SelectCurrency from "../../Currency/SelectCurrency";
import EnvironmentalService, {
  DataRecordErrorValidationEnum,
  PossibleErrorValueOutOfRangeComparisonEnum,
} from "../../../services/EnvironmentalService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { RESOURCES } from "../../../config/constants";
import ToastContext from "../../../context/ToastContext";
import { DateTimeUtils } from "../../../services/UtilsService";
import GVFormGroup from "../../common/GVFormGroup";
import EnvironmentalSubtopicService from "../../../services/EnvironmentalSubtopicService";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import {
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormStartEndDatePicker from "../../../gvds-components/Forms/GVDSFormStartEndDatePicker";
import { IconAlertTriangle } from "@tabler/icons-react";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import { useTranslation } from "react-i18next";

const initialMeterDetails = {
  subtopicId: "",
  typeId: "",
  units: [],
};

const blankRecord = {
  meter_id: "",
  start: null,
  end: null,
  usage: "",
  unit_id: "",
  cost: "",
  currency_id: "",
  comments: "",
};

const isInputFromMeterView = (meter, datasets) => {
  return meter && datasets.length === 1;
};

const createInputRecordFromCurrentRecord = (currentRecord, meterId) => ({
  meter_id: meterId,
  start: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
    currentRecord.periodFrom
  ),
  end: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
    currentRecord.periodTo
  ),
  usage: currentRecord.usage.toString(),
  unit_id: currentRecord.unit.id,
  cost: currentRecord.cost !== null ? currentRecord.cost.toString() : null,
  currency_id: currentRecord.currency.id,
  comments: currentRecord.comments,
});

const InputEnvironmentalDataRecordModal = ({
  show,
  onClose,
  datasets,
  meter,
  currentRecord,
  onDoneInput,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const defaultCurrency =
    userInventory.selectedTreeNode.get.nodeValue.value?.main_attribute
      ?.default_currency;
  const initialRecord = {
    ...blankRecord,
    currency_id: defaultCurrency ? defaultCurrency.id : "",
  };

  const [disableMeterSelect, setDisableMeterSelect] = useState(false);
  const [meterDetails, setMeterDetails] = useState(initialMeterDetails);
  const [inputRecord, setInputRecord] = useState(initialRecord);
  const [isEditExistingRecord, setIsEditExistingRecord] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [
    isDataErrorAndPossibleErrorChecked,
    setIsDataErrorAndPossibleErrorChecked,
  ] = useState(false);
  const [dataErrors, setDataErrors] = useState({});
  const [possibleErrors, setPossibleErrors] = useState({});

  const resetInputFields = () => {
    if (isInputFromMeterView(meter, datasets)) {
      setMeterDetails({
        subtopicId: datasets[0]["environmental_subtopic"]["id"],
        typeId: meter["environmental_type"]["id"],
        units: meter["environmental_type"]["units"],
      });
      setDisableMeterSelect(true);

      if (currentRecord) {
        // editing current record from meter view
        setIsEditExistingRecord(true);
        setInputRecord(
          createInputRecordFromCurrentRecord(currentRecord, meter.id)
        );
      } else {
        // creating new record from meter view
        setIsEditExistingRecord(false);
        setInputRecord({
          ...initialRecord,
          meter_id: meter.id,
          unit_id: meter["default_unit"]["id"],
          currency_id: meter["default_currency"]["id"],
        });
      }
    } else if (currentRecord) {
      setIsEditExistingRecord(true);
      setDisableMeterSelect(true);

      const meter = _.chain(datasets)
        .filter(
          (ds) =>
            ds["environmental_subtopic"]["id"] === currentRecord.subtopic.id
        )
        .flatMap((ds) => ds["meters"])
        .find((m) => m["id"] === currentRecord.meter.id)
        .value();

      setMeterDetails({
        subtopicId: currentRecord.subtopic.id,
        typeId: currentRecord.type.id,
        units: meter["environmental_type"]["units"],
      });

      setInputRecord(
        createInputRecordFromCurrentRecord(
          currentRecord,
          currentRecord.meter.id
        )
      );
    } else {
      setInputRecord(initialRecord);
      setMeterDetails(initialMeterDetails);
      setIsEditExistingRecord(false);
      setDisableMeterSelect(false);
    }
    setDataErrors([]);
    setPossibleErrors([]);
    setIsDataErrorAndPossibleErrorChecked(false);
    setIsValidated(false);
  };

  useEffect(() => {
    resetInputFields();
  }, [currentRecord, meter]);

  const onRecordChange = (fieldName, fieldValue) => {
    setInputRecord({ ...inputRecord, [fieldName]: fieldValue });
    setIsDataErrorAndPossibleErrorChecked(false);
  };

  const onMeterDetailsChange = (fieldName, fieldValue) => {
    if (fieldName === "subtopicId") {
      setMeterDetails({
        ...meterDetails,
        subtopicId: fieldValue,
        typeId: null,
      });
      setInputRecord({ ...inputRecord, meter_id: "", unit_id: "" });
    } else if (fieldName === "typeId") {
      setMeterDetails({ ...meterDetails, typeId: fieldValue });
      setInputRecord({ ...inputRecord, meter_id: "", unit_id: "" });
    } else {
      setMeterDetails({ ...meterDetails, [fieldName]: fieldValue });
    }
  };

  const onMeterChange = (meterId) => {
    const meter = _.chain(datasets)
      .filter(
        (ds) => ds["environmental_subtopic"]["id"] === meterDetails.subtopicId
      )
      .flatMap((ds) => ds["meters"])
      .find((m) => m["id"] === meterId)
      .value();

    setInputRecord({
      ...inputRecord,
      meter_id: meter.id,
      unit_id: meter["default_unit"]["id"],
      currency_id: meter["default_currency"]["id"],
    });
    onMeterDetailsChange("units", meter["environmental_type"]["units"]);
  };

  const getSubtopics = () => {
    return _.chain(datasets)
      .map((ds) => ds["environmental_subtopic"])
      .uniqBy("id")
      .value()
      .sort((subtopic1, subtopic2) => {
        EnvironmentalSubtopicService.subtopicsSortFn(
          subtopic1.name,
          subtopic2.name
        );
      });
  };

  const getTypes = () => {
    if (!meterDetails.subtopicId) {
      return [];
    }

    return _.chain(datasets)
      .filter(
        (ds) => ds["environmental_subtopic"]["id"] === meterDetails.subtopicId
      )
      .flatMap((ds) => ds["meters"])
      .map((m) => m["environmental_type"])
      .uniqBy("id")
      .value();
  };

  const getDatasetMeters = () => {
    if (!meterDetails.subtopicId || !meterDetails.typeId) {
      return [];
    }

    return _.chain(datasets)
      .filter(
        (ds) => ds["environmental_subtopic"]["id"] === meterDetails.subtopicId
      )
      .value();
  };

  const isSubtopicValid = () => {
    return meterDetails.subtopicId && meterDetails.subtopicId.length > 0;
  };

  const isTypeValid = () => {
    return meterDetails.typeId && meterDetails.typeId.length > 0;
  };

  const isMeterValid = () => {
    return inputRecord.meter_id && inputRecord.meter_id.length > 0;
  };

  const isStartDateValid = () => {
    return inputRecord.start !== null;
  };

  const isEndDateValid = () => {
    return inputRecord.end !== null;
  };

  const isEndDateEarlierThanStartDate = () => {
    return (
      inputRecord.start &&
      inputRecord.end &&
      inputRecord.end < inputRecord.start
    );
  };

  const isUsageValid = () => {
    return inputRecord.usage && inputRecord.usage.length > 0;
  };

  const isInputValid = () => {
    return (
      isSubtopicValid() &&
      isTypeValid() &&
      isMeterValid() &&
      isStartDateValid() &&
      isEndDateValid() &&
      !isEndDateEarlierThanStartDate() &&
      isUsageValid()
    );
  };

  const getSharedErrorMessage = (errorType) => {
    const EXISTING_RECORD_ERROR =
      DataRecordErrorValidationEnum.DATA_RECORD_ALREADY_EXIST_FOR_ENTERED_PERIOD;

    if (errorType === EXISTING_RECORD_ERROR) {
      return t(
        "data-management.environmental.usage.single-input.validation-error-message.existing-record-error"
      );
    } else {
      return errorType;
    }
  };

  const getDateErrorMessages = () => {
    if (!isValidated) {
      return null;
    }

    const periodFromErrors = dataErrors["period_from"];
    const periodToErrors = dataErrors["period_to"];
    const sharedErrors = _.intersection(periodFromErrors, periodToErrors);

    return (
      <Row className="mb-3" style={{ marginTop: "-1.5em" }}>
        <Col>
          {isEndDateEarlierThanStartDate() && (
            <GVDSFormErrorMessage
              errorMsg={t(
                "data-management.environmental.usage.single-input.validation-error-message.period-to-earlier-than-period-from-error"
              )}
            />
          )}
          {sharedErrors.map((error, index) => (
            <GVDSFormErrorMessage
              key={index}
              errorMsg={getSharedErrorMessage(error)}
            />
          ))}
        </Col>
      </Row>
    );
  };

  const saveDataRecord = async (event) => {
    event.preventDefault();
    setIsValidated(true);

    if (isInputValid()) {
      const selectedInventory = userInventory.selectedInventory.get;
      try {
        setIsSaving(true);
        const errors = await EnvironmentalService.validateSingleDataRecord(
          selectedInventory.type,
          selectedInventory.id,
          inputRecord.meter_id,
          inputRecord.start,
          inputRecord.end,
          inputRecord.usage,
          inputRecord.unit_id,
          inputRecord.cost,
          inputRecord.currency_id,
          inputRecord.comments,
          currentRecord ? currentRecord.id : null
        );

        if (
          !_.isEmpty(errors.dataErrors) ||
          !_.isEmpty(errors.possibleErrors)
        ) {
          setDataErrors(errors.dataErrors);
          setPossibleErrors(errors.possibleErrors);
          setIsDataErrorAndPossibleErrorChecked(true);
          setIsSaving(false);
        } else {
          await saveDataRecordWithoutValidation(event);
        }
      } catch (error) {
        toastContext.addFailToast(<span>Failed to validate data record.</span>);
        setIsSaving(false);
      }
    }
  };

  const saveDataRecordWithoutValidation = async (event) => {
    event.preventDefault();
    const selectedInventory = userInventory.selectedInventory.get;
    try {
      setIsSaving(true);
      if (isEditExistingRecord) {
        await EnvironmentalService.editDataRecord(
          selectedInventory.type,
          selectedInventory.id,
          currentRecord.id,
          inputRecord
        );
        toastContext.addSuccessToast(
          <span>Data record edited successfully</span>
        );
      } else {
        await EnvironmentalService.createDataRecord(
          selectedInventory.type,
          selectedInventory.id,
          userInventory.selectedInventory.get.id,
          inputRecord
        );
        toastContext.addSuccessToast(
          <span>Data record added successfully</span>
        );
      }
      setIsSaving(false);
      resetInputFields();
      if (onDoneInput) {
        onDoneInput();
      }
      onClose();
    } catch (error) {
      toastContext.addFailToast(<span>Failed to input data record.</span>);
      setIsSaving(false);
    }
  };

  const onCloseDialog = () => {
    if (!isSaving) {
      onClose();
    }
  };

  if (!datasets || datasets.length === 0) {
    return (
      <GVDSModal
        title={t(
          "data-management.environmental.usage.single-input.modal-title-input"
        )}
        size={GVDSModal.Size.small}
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <GVDSModal.Body>
          <div>
            {t(
              "data-management.environmental.usage.single-input.no-meter-line1"
            )}
          </div>
          <br />
          <div>
            {t(
              "data-management.environmental.usage.single-input.no-meter-line2"
            )}
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => {
              onClose();
            }}
            text={t("shared-modal.footer.close")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    );
  }

  const getSaveButton = () => {
    const onlyPossibleErrors =
      isDataErrorAndPossibleErrorChecked &&
      _.isEmpty(dataErrors) &&
      !_.isEmpty(possibleErrors);

    if (onlyPossibleErrors) {
      return (
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          onClickAsyncFunc={saveDataRecordWithoutValidation}
          text={t("shared-modal.footer.save-anyway")}
        />
      );
    } else {
      return (
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          onClickAsyncFunc={saveDataRecord}
          text={t("shared-modal.footer.save")}
        />
      );
    }
  };

  const getSubtopicOptionByValue = (optionValue) => {
    return getOptionByValueFromIdName(optionValue, getSubtopics());
  };

  const getTypeOptionByValue = (optionValue) => {
    return getOptionByValueFromIdName(optionValue, getTypes());
  };

  const getUnitOptionByValue = (optionValue) => {
    return getOptionByValueFromIdName(optionValue, meterDetails["units"]);
  };

  const getDatasetMeterOptionTuples = () => {
    let datasetMeterOptionTuples = [];
    getDatasetMeters().map((ds) =>
      ds["meters"]
        .filter((m) => m["environmental_type"]["id"] === meterDetails["typeId"])
        .forEach((meter) => {
          const optionLabel = `${meter.name ? meter.name : "Default"}${
            userInventory.selectedInventory.get &&
            userInventory.selectedInventory.get.type === RESOURCES.SITE
              ? " (" + ds["facility"]["name"] + ")"
              : ""
          }`;
          datasetMeterOptionTuples.push({
            value: meter.id,
            label: optionLabel,
          });
        })
    );

    return datasetMeterOptionTuples;
  };

  const getDatasetMeterOptionByValue = (optionValue) => {
    const options = getDatasetMeterOptionTuples();
    if (options) {
      const option = options.find((option) => option.value === optionValue);
      return option || null;
    } else {
      return null;
    }
  };

  return (
    <GVDSModal
      title={
        isEditExistingRecord
          ? t(
              "data-management.environmental.usage.single-input.modal-title-edit"
            )
          : t(
              "data-management.environmental.usage.single-input.modal-title-input"
            )
      }
      size={GVDSModal.Size.medium}
      show={show}
      onHide={() => {
        onCloseDialog();
      }}
    >
      <GVDSModal.Body>
        <Row>
          <Col sm={6}>
            <GVFormGroup controlId="selectSubtopic">
              <Form.Label>
                {t(
                  "data-management.environmental.usage.single-input.label-subtopic"
                )}
              </Form.Label>
              <GVDSFormSingleSelect
                className="select__subtopic"
                placeholder={t(
                  "data-management.environmental.shared-input-placeholder.subtopic"
                )}
                value={
                  meterDetails["subtopicId"]
                    ? getSubtopicOptionByValue(meterDetails["subtopicId"])
                    : null
                }
                options={getSubtopics().map((subtopic) => {
                  return { value: subtopic.id, label: subtopic.name };
                })}
                onSelect={(selectedOption) =>
                  onMeterDetailsChange("subtopicId", selectedOption.value)
                }
                disabled={disableMeterSelect || isSaving}
                statusMetadata={
                  isValidated && !isSubtopicValid()
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "data-management.environmental.usage.single-input.validation-error-message.empty-required-subtopic"
                        )
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <GVFormGroup controlId="selectType">
          <Form.Label>
            {t("data-management.environmental.shared.type")}
          </Form.Label>
          <GVDSFormSingleSelect
            className="select__type"
            placeholder={t(
              "data-management.environmental.shared-input-placeholder.type"
            )}
            value={
              meterDetails["typeId"]
                ? getTypeOptionByValue(meterDetails["typeId"])
                : null
            }
            options={getTypes().map((type) => {
              return { value: type.id, label: type.name };
            })}
            onSelect={(selectedOption) =>
              onMeterDetailsChange("typeId", selectedOption.value)
            }
            disabled={disableMeterSelect || isSaving}
            statusMetadata={
              isValidated && !isTypeValid()
                ? FormFieldStatusMetadata.getError(
                    t(
                      "data-management.environmental.usage.single-input.validation-error-message.empty-required-type"
                    )
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <Row>
          <Col sm={6}>
            <GVFormGroup controlId="selectMeter">
              <Form.Label>{t("shared.meter")}</Form.Label>
              <GVDSFormSingleSelect
                className="select__meter"
                placeholder={t(
                  "data-management.environmental.shared-input-placeholder.meter"
                )}
                value={
                  inputRecord["meter_id"]
                    ? getDatasetMeterOptionByValue(inputRecord["meter_id"])
                    : null
                }
                options={getDatasetMeterOptionTuples()}
                onSelect={(selectedOption) => {
                  onMeterChange(selectedOption.value);
                }}
                disabled={disableMeterSelect || isSaving}
                statusMetadata={
                  isValidated && !isMeterValid()
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "data-management.environmental.usage.single-input.validation-error-message.empty-required-meter"
                        )
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <GVFormGroup controlId="selectStartEndDate">
              <GVDSFormStartEndDatePicker
                startDateLabel={
                  <Form.Label>{t("shared-input-label.period-from")}</Form.Label>
                }
                endDateLabel={
                  <Form.Label>{t("shared-input-label.period-to")}</Form.Label>
                }
                className="env-record"
                startDate={inputRecord["start"]}
                endDate={inputRecord["end"]}
                onChange={(startDate, endDate) => {
                  if (startDate !== inputRecord["start"]) {
                    onRecordChange("start", startDate);
                  }
                  if (endDate !== inputRecord["end"]) {
                    onRecordChange("end", endDate);
                  }
                }}
                dateFormat="dd MMM yyyy"
                maxStartDate={new Date()}
                maxEndDate={new Date()}
                statusMetadata={
                  isValidated && (!isStartDateValid() || !isEndDateValid())
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "data-management.environmental.usage.single-input.validation-error-message.empty-required-period-range"
                        )
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        {getDateErrorMessages()}
        <Row>
          <Col sm={6}>
            <GVFormGroup controlId="usage">
              <Form.Label>
                {t(
                  "data-management.environmental.usage.single-input.label-usage"
                )}
              </Form.Label>
              <GVDSFormField
                type="number"
                placeholder={t(
                  "data-management.environmental.shared-input-placeholder.usage"
                )}
                className="usages_name_input"
                value={inputRecord.usage}
                onInput={(value) => onRecordChange("usage", value)}
                statusMetadata={
                  isValidated && !isUsageValid()
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "data-management.environmental.usage.single-input.validation-error-message.empty-required-usage"
                        )
                      )
                    : possibleErrors.usage
                    ? FormFieldStatusMetadata.getWarning()
                    : FormFieldStatusMetadata.getDefault()
                }
                disabled={isSaving}
              />
            </GVFormGroup>
          </Col>
          <Col sm={6}>
            <GVFormGroup controlId="selectUnit">
              <Form.Label>{t("shared-input-label.unit")}</Form.Label>
              <GVDSFormSingleSelect
                className="select__unit"
                placeholder={t("shared-input-placeholder.unit")}
                value={
                  inputRecord["unit_id"]
                    ? getUnitOptionByValue(inputRecord["unit_id"])
                    : null
                }
                disabled={true}
                options={meterDetails["units"].map((unit) => {
                  return { value: unit.id, label: unit.name };
                })}
              />
            </GVFormGroup>
          </Col>
        </Row>
        {possibleErrors.usage && (
          <div className="manual-warning-feedback d-flex flex-row mb-3">
            <div className="me-1">
              <GVDSIconSlim Icon={IconAlertTriangle} />
            </div>
            <div className="flex-grow-1 text-vertical-center">
              {possibleErrors.usage.compared_to_previous ===
              PossibleErrorValueOutOfRangeComparisonEnum.HIGH
                ? t(
                    `data-management.environmental.usage.single-input.possible-error-validation.usage-comparison-high`
                  )
                : t(
                    `data-management.environmental.usage.single-input.possible-error-validation.usage-comparison-low`
                  )}
            </div>
          </div>
        )}
        <Row>
          <Col sm={6}>
            <GVFormGroup controlId="cost">
              <Form.Label>
                {t(
                  "data-management.environmental.usage.single-input.label-cost"
                )}{" "}
                <span className="optional-form-label">
                  ({t("shared-input-label.optional")})
                </span>
              </Form.Label>
              <GVDSFormField
                type="number"
                placeholder={t(
                  "data-management.environmental.shared-input-placeholder.cost"
                )}
                className="cost_name_input"
                value={inputRecord.cost}
                onInput={(value) => onRecordChange("cost", value)}
                statusMetadata={
                  possibleErrors.cost
                    ? FormFieldStatusMetadata.getWarning()
                    : FormFieldStatusMetadata.getDefault()
                }
                disabled={isSaving}
              />
            </GVFormGroup>
          </Col>
          <Col sm={6}>
            <SelectCurrency
              selected={inputRecord["currency_id"]}
              onCurrencySelected={(value) =>
                onRecordChange("currency_id", value)
              }
              displayAsText={true}
            />
          </Col>
        </Row>
        {possibleErrors.cost && (
          <div className="manual-warning-feedback d-flex flex-row mb-3">
            <div className="me-1">
              <GVDSIconSlim Icon={IconAlertTriangle} />
            </div>
            <div className="flex-grow-1 text-vertical-center">
              {possibleErrors.cost.compared_to_previous ===
              PossibleErrorValueOutOfRangeComparisonEnum.HIGH
                ? t(
                    `data-management.environmental.usage.single-input.possible-error-validation.cost-comparison-high`
                  )
                : t(
                    `data-management.environmental.usage.single-input.possible-error-validation.cost-comparison-low`
                  )}
            </div>
          </div>
        )}
        <Row>
          <Col bg={10}>
            <GVFormGroup controlId="comments">
              <Form.Label>
                {t("shared-input-label.comments")}{" "}
                <span className="optional-form-label">
                  ({t("shared-input-label.optional")})
                </span>
              </Form.Label>
              <GVDSFormField
                placeholder={t("shared-input-placeholder.comment")}
                className="comments_name_input"
                value={inputRecord.comments}
                onInput={(value) => onRecordChange("comments", value)}
                disabled={isSaving}
              />
            </GVFormGroup>
          </Col>
        </Row>
      </GVDSModal.Body>

      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={() => {
            onCloseDialog();
          }}
          disabled={isSaving}
          text={t("shared-modal.footer.cancel")}
        />
        {getSaveButton()}
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default InputEnvironmentalDataRecordModal;
