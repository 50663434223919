import axios from "axios";
import { API_URL } from "../../../config/api-config";
import {
  CertificationListItemModel,
  CertificationModel,
} from "../../../components/SystemToolbox/Certification/CertificationModel";

export default class CertificationAdminService {
  static async createCertification(
    name,
    version,
    description,
    logoFile,
    verificationDetails
  ) {
    try {
      const payloadBody = new FormData();

      if (logoFile && logoFile.isNew()) {
        payloadBody.append("logo_file", logoFile.file);
      }

      const data = {
        name: name,
        version: version,
        description: description,
        verification_details: verificationDetails,
      };

      payloadBody.append("data", JSON.stringify(data));

      const response = await axios.post(
        `${API_URL}/api/v1/admin/certifications`,
        payloadBody
      );
      return response.data.id;
    } catch (error) {
      throw error;
    }
  }

  static async updateCertification(
    id,
    name,
    version,
    description,
    logoFiles,
    verificationDetails
  ) {
    try {
      const payloadBody = new FormData();

      if (logoFiles && logoFiles.length > 0) {
        const newLogoFile = logoFiles.find((file) => file.isNew());
        if (newLogoFile !== undefined) {
          payloadBody.append("new_logo_file", newLogoFile.file);
        } else {
          const deletedLogoFile = logoFiles.find((file) =>
            file.isToBeDeleted()
          );
          if (deletedLogoFile !== undefined) {
            payloadBody.append("deleted_logo_file_id", deletedLogoFile.id);
          }
        }
      }

      const data = {
        name: name,
        version: version,
        description: description,
        verification_details: verificationDetails,
      };

      payloadBody.append("data", JSON.stringify(data));

      const response = await axios.put(
        `${API_URL}/api/v1/admin/certifications/${id}`,
        payloadBody
      );

      return CertificationModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  }

  static async getCertifications() {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/certifications`
      );

      return response.data.map((cert) =>
        CertificationListItemModel.fromDTO(cert)
      );
    } catch (error) {
      throw error;
    }
  }

  static async publishCertification(certificationId) {
    try {
      await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/publish`
      );

      return;
    } catch (error) {
      throw error;
    }
  }

  static async unpublishCertification(certificationId) {
    try {
      await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/unpublish`
      );

      return;
    } catch (error) {
      throw error;
    }
  }

  static async getCertification(certificationId) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/certifications/${certificationId}`
      );

      return CertificationModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  }

  static async moveCertification(
    certificationToBeMoved,
    certificationToMoveTo,
    position
  ) {
    try {
      const data = {
        certification_to_move_to_id: certificationToMoveTo.id,
        position: position,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certificationToBeMoved.id}/move`,
        data
      );
      return response.data.map((cert) =>
        CertificationListItemModel.fromDTO(cert)
      );
    } catch (error) {
      throw error;
    }
  }
}
