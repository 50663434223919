import React, { useContext, useEffect, useState } from "react";
import SummarySiteRankingChartView from "./SummarySiteRankingChartView";
import DashboardDataContext from "../../Context/DashboardDataContext";
import DashboardCalculator from "../../Models/DashboardCalculator";
import { DATA_STATUS, RESOURCES } from "../../../../config/constants";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import { SummarySiteRankingChartDataModel } from "../../Models/DashboardModels";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";

const convertSummarySiteRankingDataToChartModel = (summarySiteRankingData) => {
  const data = summarySiteRankingData.value;
  const siteNames = Object.keys(data);
  const classificationValues = new Set();
  const totalValueByClassificationValue = {};

  const chartModels = [];

  siteNames.forEach((siteName) => {
    const siteSummary = data[siteName];

    const siteClassificationValues = Object.keys(siteSummary);
    const siteValuesByClassificationValue = {};

    siteClassificationValues.forEach((classificationValue) => {
      classificationValues.add(classificationValue);

      siteValuesByClassificationValue[classificationValue] =
        siteSummary[classificationValue];

      if (totalValueByClassificationValue[classificationValue] === undefined) {
        totalValueByClassificationValue[classificationValue] = 0;
      }
      totalValueByClassificationValue[classificationValue] +=
        siteSummary[classificationValue];
    });

    chartModels.push(
      new SummarySiteRankingChartDataModel(
        siteName,
        siteValuesByClassificationValue
      )
    );
  });

  const hasDataGaps = summarySiteRankingData.status !== DATA_STATUS.COMPLETE;

  const classificationValuesFromBiggestValue = Array.from(
    classificationValues
  ).sort((classificationValue1, classificationValue2) => {
      const total1 = totalValueByClassificationValue[classificationValue1];
      const total2 = totalValueByClassificationValue[classificationValue2];

      return DashboardWidgetSharedUtils.classificationValueByBiggestValueSortFn(
        classificationValue1,
        total1,
        classificationValue2,
        total2
      );
    });

  return {
    data: chartModels,
    keys: classificationValuesFromBiggestValue,
    hasDataGaps: hasDataGaps,
  };
};

const SummarySiteRankingChart = ({ element }) => {
  const userInventory = useContext(UserInventoryContext);
  const currentInventory = userInventory.selectedInventory.get;

  const dashboardDataContext = useContext(DashboardDataContext);
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const [summarySiteRankingData, setSummarySiteRankingData] = useState(null);

  const config = element["config"];

  const subtopic = config["subtopic"];
  const isDataLoaded = dashboardHolderFacade.isAllRequiredDataPresent(
    [subtopic],
    true
  );

  useEffect(() => {
    if (isDataLoaded && currentInventory.type === RESOURCES.PORTFOLIO) {
      setSummarySiteRankingData(
        DashboardCalculator.getSummarySiteRankingData(
          config,
          dashboardHolderFacade
        )
      );
    }
  }, [dashboardDataContext.lastDataUpdated]);

  if (!currentInventory || currentInventory.type !== RESOURCES.PORTFOLIO) {
    return null;
  }

  if (!isDataLoaded || summarySiteRankingData === null) {
    return (
      <SummarySiteRankingChartView title="Site Ranking" isLoading={true} />
    );
  }

  const {
    data: summarySiteRankingChartData,
    keys: summarySiteRankingChartKeys,
    hasDataGaps,
  } = convertSummarySiteRankingDataToChartModel(summarySiteRankingData);

  return (
    <SummarySiteRankingChartView
      isLoading={false}
      title="Site Ranking"
      subtopic={subtopic}
      intensity={DashboardCalculator.getIntensityDisplay(
        config,
        dashboardHolderFacade
      )}
      intensityUnit={DashboardCalculator.getDivisorUnit(
        config,
        dashboardHolderFacade
      )}
      hasDataGaps={hasDataGaps}
      period={dashboardHolderFacade.getContinuousTimeDisplay()}
      chartData={summarySiteRankingChartData}
      chartKeys={summarySiteRankingChartKeys}
      unit={DashboardCalculator.getDividendUnit(config, dashboardHolderFacade)}
    />
  );
};

export default SummarySiteRankingChart;
