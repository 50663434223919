import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../HOC/withAuthentication";

import Container from "react-bootstrap/Container";
import LoadingSpinner from "../common/LoadingSpinner";
import {
  CREATE_CONTRACT,
  getViewContractDetailsPath,
} from "../../config/ROUTES_NAME";
import ContractService from "../../services/ContractService";
import PermissionsContext from "../../context/PermissionsContext";
import { PERMISSIONS } from "../../config/constants";
import ContractStatusBadge from "./ContractStatusBadge";
import uniq from "lodash/uniq";
import { DateTimeUtils } from "../../services/UtilsService";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import UserAvatar from "../common/UserAvatar";
import SubscriptionSharedUtils from "../SystemToolbox/Subscription/SubscriptionSharedUtils";
import FeatureContext from "../../context/FeatureContext";
import CommentTooltip from "../common/Tooltip/CommentTooltip";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import FilterSearchBox from "../../gvds-components/common/FilterSearchBox";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import Spacer from "../../gvds-components/Layout/Spacer";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";
import useGVDSTableCtrl from "../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../config/search-config";
import GVDSTable, {
  SORTING_TYPES,
} from "../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../gvds-components/Table/Controls/GVDSPagination";

const ViewAllContracts = () => {
  const history = useHistory();
  const location = useLocation();
  const permissionsCtx = useContext(PermissionsContext);
  const featureCtx = useContext(FeatureContext);
  const allFeatures = featureCtx.features;

  const [allContracts, setAllContracts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    allContracts,
    SystemToolboxSearchKeysByPageName.contracts,
    {
      name: SORTING_TYPES.asc,
    }
  );

  useEffect(() => {
    const getAllContracts = async () => {
      return await ContractService.getAllContracts();
    };
    const allContractsFromServer = getAllContracts();

    allContractsFromServer.then((contractsResponse) => {
      setAllContracts(contractsResponse);
      setIsLoading(false);
    });
  }, []);

  let columns = [
    {
      header: "Contract Name",
      dataKey: "name",
      sortable: true,
      renderer: (contract) => (
        <Link
          to={getRedirectURLWithCurrentParam(
            getViewContractDetailsPath(contract.id),
            location
          )}
          className="view-contract"
          onClick={(e) => e.stopPropagation()}
        >
          {contract.name}
        </Link>
      ),
    },
    {
      header: "Status",
      dataKey: "status",
      sortable: true,
      renderer: (contract) => <ContractStatusBadge status={contract.status} />,
    },
    {
      header: "Contract Admin",
      dataKey: "users",
      sortable: true,
      renderer: (contract) =>
        contract.users.length === 0 ? (
          <span className="gvds-color--gray6">Unassigned</span>
        ) : (
          <UserAvatar
            fullName={contract.users[0]["full_name"]}
            userEmail={contract.users[0]["email"]}
          />
        ),
    },
    {
      header: "Subscribed Features",
      dataKey: "features",
      sortable: true,
      renderer: (contract) =>
        contract.features && contract.features.length > 0
          ? SubscriptionSharedUtils.getShorthandFeatureDisplay(
              allFeatures,
              contract.features,
              "right"
            )
          : "-",
    },
    {
      header: "No. of Active Sites",
      dataKey: "no_of_sites",
      sortable: true,
      renderer: (contract) => contract.no_of_sites ?? "-",
    },
    {
      header: "End Date",
      dataKey: "end_date",
      renderer: (contract) =>
        contract.end_date
          ? DateTimeUtils.formatLocalDate(contract.end_date)
          : "-",
    },
    {
      header: "Account Manager",
      dataKey: "account_manager",
      sortable: true,
      renderer: (contract) => (
        <div>
          {!contract.account_manager ? (
            <span className="gvds-color--gray6">Unassigned</span>
          ) : (
            <span>
              <UserAvatar
                fullName={contract.account_manager["full_name"]}
                userEmail={contract.account_manager["email"]}
              />
            </span>
          )}
        </div>
      ),
    },
    {
      header: "Comment",
      dataKey: "internal_comment",
      renderer: (contract) =>
        contract.internal_comment ? (
          <CommentTooltip comment={contract.internal_comment} />
        ) : (
          "-"
        ),
    },
  ];

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <>
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          onRowClick={(contract, event) => {
            const contractUrl = getRedirectURLWithCurrentParam(
              getViewContractDetailsPath(contract.id),
              location
            );

            history.push(contractUrl);
          }}
          noContent={
            allContracts.length === 0 ? (
              <div className="table__no_content">No contracts</div>
            ) : (
              filteredSortedData.length === 0 && (
                <div className="table__no_content">
                  No contracts. Try changing your filter.
                </div>
              )
            )
          }
          viewControl={
            <GVDSTableCtrlContainer>
              {allContracts.length > 0 ? (
                <>
                  <FilterSearchBox
                    className="contract-name-search-box"
                    placeholder="Search Contract"
                    value={searchText}
                    onInput={setSearchText}
                  />
                  <GVDSTableCtrlMultiSelect
                    options={uniq(allContracts.map((c) => c.status).flat())}
                    prefix="Status"
                    onChange={(filterKs) =>
                      setFilterKeys({ ...filterKeys, status: filterKs })
                    }
                  />
                  <GVDSPagination
                    startIndex={startIndex}
                    endIndex={endIndex}
                    total={totalDataLength}
                    onChange={onPaginationChange}
                  />
                </>
              ) : null}
            </GVDSTableCtrlContainer>
          }
        />
      </>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>All Contracts</h1>
          {!permissionsCtx.isLoadingPermissions &&
            permissionsCtx.permissions[PERMISSIONS.CONTRACT_CREATE] && (
              <>
                <Spacer />
                <GVDSButton
                  variant={buttonVariant.primary}
                  className="create_contract_button"
                  onClick={() =>
                    history.push(
                      getRedirectURLWithCurrentParam(CREATE_CONTRACT, location)
                    )
                  }
                  text="Create a new contract"
                  icon={<GVDSIcon Icon={IconCirclePlus} />}
                />
              </>
            )}
        </PageHeader.Title>
      </PageHeader>
      {content}
    </Container>
  );
};

export default withAuthentication(ViewAllContracts);
