import React, { Children, useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { PEOPLE_DATA } from "../../../config/ROUTES_NAME";
import ToastContext from "../../../context/ToastContext";
import withAuthentication from "../../HOC/withAuthentication";
import PeopleService from "../../../services/PeopleService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import PeopleMeterModal from "./PeopleMeterModal";
import LoadingSpinner from "../../common/LoadingSpinner";
import DeletePeopleMeterModal from "./DeletePeopleMeterModal";
import { RESOURCES } from "../../../config/constants";
import PeopleCategoryDisplay from "./PeopleCategoryDisplay";
import { useHistory } from "react-router-dom";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconEdit } from "@tabler/icons-react";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";

const PeopleSubtopicMetersDisplay = ({
  subtopic,
  subtopicMeter,
  addMeterDialog,
  editMeterDialog,
  deleteMeterDialog,
}) => {
  const controlColumns = [
    <td className="people-setup-table__tracking">
      <Form.Check
        type="switch"
        label=""
        id={subtopic.id}
        onChange={() =>
          !!subtopicMeter
            ? deleteMeterDialog(subtopicMeter)
            : addMeterDialog(subtopic)
        }
        checked={!!subtopicMeter}
        className="people-setup-table__switch"
      />
    </td>,
  ];
  const dataColumns = [
    <td className="people-setup-table__subtopic">{subtopic.name}</td>,
    <td className="people-setup-table__no_of_groupings">
      {subtopicMeter ? subtopicMeter.groupings.length : null}
    </td>,
    <td className="people-setup-table__definition">{subtopic.definition}</td>,
    <td className="people-setup-table__action">
      {subtopicMeter ? (
        <GVDSIconButton
          variant={iconButtonVariant.tertiary}
          icon={<GVDSIcon Icon={IconEdit} />}
          onClick={() => editMeterDialog(subtopicMeter)}
          tooltipText="Edit"
        />
      ) : null}
    </td>,
  ];

  return (
    <React.Fragment key={subtopic.id}>
      <tr className={` ${subtopicMeter ? "" : "color-gray56"}`}>
        {controlColumns}
        {dataColumns}
      </tr>
      {subtopicMeter && (
        <tr>
          {[...Array(Children.count(controlColumns))].map((el, i) => (
            <td key={i} />
          ))}
          <td colSpan={Children.count(dataColumns)}>
            <div>
              <GVDSTableDisplay>
                <thead>
                  <tr>
                    <th>Groupings</th>
                    <th>Categories</th>
                  </tr>
                </thead>
                <tbody>
                  {subtopicMeter.groupings.map((grouping, index) => {
                    return (
                      <tr key={`details-${subtopicMeter.id}-${index}`}>
                        <td className="col-4">{grouping.name}</td>
                        <td className="col-8">
                          <PeopleCategoryDisplay
                            categories={grouping.categories}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </GVDSTableDisplay>
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

const PeopleMeterSetup = () => {
  const history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);

  const [peopleSubtopics, setPeopleSubtopics] = useState([]);

  const [peopleMeters, setPeopleMeters] = useState([]);

  const [showMeterModal, setShowMeterModal] = useState(false);
  const [showDeleteMeterModal, setShowDeleteMeterModal] = useState(false);
  const [inputMeterDetails, setInputMeterDetails] = useState(null);

  useEffect(() => {
    if (selectedInventory?.type === RESOURCES.SITE) {
      Promise.all([
        PeopleService.getPeopleMeters(selectedInventory.id),
        PeopleService.getPeopleSubtopics(),
      ])
        .then((response) => {
          const peopleMetersResponse = response[0];
          const peopleSubtopicsResponse = response[1];

          setPeopleMeters(peopleMetersResponse);
          setPeopleSubtopics(peopleSubtopicsResponse);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to load people data.</span>);
        });
    }
  }, []);

  const goToPeopleData = () => {
    history.push(PEOPLE_DATA);
  };

  const addMeterDialog = (peopleSubtopic) => {
    setInputMeterDetails({
      subtopic_id: peopleSubtopic.id,
      grouping_ids: [],
    });
    setShowMeterModal(true);
  };

  const editMeterDialog = (subtopicMeter) => {
    setInputMeterDetails({
      meter_id: subtopicMeter.id,
      subtopic_id: subtopicMeter.subtopic.id,
      grouping_ids: subtopicMeter.groupings.map((g) => g.id),
    });
    setShowMeterModal(true);
  };

  const deleteMeterDialog = (subtopicMeter) => {
    setInputMeterDetails({
      meter_id: subtopicMeter.id,
      grouping_ids: [],
    });
    setShowDeleteMeterModal(true);
  };

  const reloadMeters = () => {
    setIsLoading(true);
    PeopleService.getPeopleMeters(selectedInventory.id)
      .then((peopleMetersResponse) => {
        setPeopleMeters(peopleMetersResponse);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load people meters.</span>);
      });
  };

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text="Return to People Data"
          onClick={goToPeopleData}
        />
        <PageHeader.Title>
          <h1>People Data Setup</h1>
        </PageHeader.Title>
      </PageHeader>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <GVDSTableDisplay className="people-setup-table">
          <thead>
            <tr>
              <th className="people-setup-table__tracking">Tracking</th>
              <th className="people-setup-table__subtopic">Subtopic</th>
              <th className="people-setup-table__no_of_groupings">
                No. of Groupings
              </th>
              <th className="people-setup-table__definition">Definition</th>
              <th className="people-setup-table__action">Action</th>
            </tr>
          </thead>

          <tbody>
            {peopleSubtopics.map((subtopic) => {
              const subtopicMeter = peopleMeters.find(
                (m) => m.subtopic.id === subtopic.id
              );
              return (
                <PeopleSubtopicMetersDisplay
                  key={subtopic.id}
                  subtopic={subtopic}
                  subtopicMeter={subtopicMeter}
                  addMeterDialog={addMeterDialog}
                  editMeterDialog={editMeterDialog}
                  deleteMeterDialog={deleteMeterDialog}
                />
              );
            })}
          </tbody>
        </GVDSTableDisplay>
      )}
      <PeopleMeterModal
        showModal={showMeterModal}
        closeModal={() => {
          setShowMeterModal(false);
        }}
        currentMeterDetails={inputMeterDetails}
        subtopicOptions={peopleSubtopics}
        onSuccess={() => {
          setShowMeterModal(false);
          reloadMeters();
        }}
      />
      <DeletePeopleMeterModal
        showModal={showDeleteMeterModal}
        onClose={() => {
          setShowDeleteMeterModal(false);
        }}
        meterId={inputMeterDetails?.meter_id}
        onSuccess={() => {
          setShowDeleteMeterModal(false);
          reloadMeters();
        }}
      />
    </div>
  );
};

export default withAuthentication(PeopleMeterSetup);
