import LoadingSpinner from "../../../common/LoadingSpinner";
import React, { useContext, useRef, useState } from "react";
import { PerformanceComparisonChartViewHeader } from "./PerformanceComparisonChartViewHeaders";
import PerformanceComparisonChartViewContent from "./PerformanceComparisonChartViewContent";
import ExpandedPerformanceComparisonChartView from "./ExpandedPerformanceComparisonChartView";
import {
  downloadChartAsImageTimeoutInMs,
  expandedModalViewOptionEnum,
} from "../../Models/DashboardModels";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import { performanceComparisonChartConfig } from "./PerformanceComparisonChartViewConfig";
import { DashboardTableExcelDownloader } from "../../DashboardTableExcelDownloader";
import { WidgetTypeEnum } from "../../Models/WidgetModels";

const PerformanceComparisonChartView = ({
  isLoading,
  title,
  titleInfoTooltip,
  period,
  hasDataGaps,
  subtopic,
  intensity,
  chartData,
  unit,
  intensityUnit,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isShowExpandedView, setIsShowExpandedView] = useState(false);
  const [selectedExpandedViewOption, setSelectedExpandedViewOption] =
    useState(null);
  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const isDownloadingFromTableView = useRef(false);

  const showExpandedChartView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
    setIsShowExpandedView(true);
  };

  const showExpandedTableView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
    setIsShowExpandedView(true);
  };

  let content;

  if (isLoading) {
    content = (
      <div className="dashboard--performance-comparison__mini-view-box">
        <LoadingSpinner />
      </div>
    );
  } else {
    const hasData = chartData !== null && chartData.hasData();
    const fileName = DashboardWidgetSharedUtils.getDownloadFileName(
      userInventory.selectedTreeNode.get?.nodeValue?.name || "",
      WidgetTypeEnum.PerformanceComparisonChart,
      subtopic,
      period
    );

    const downloadChartAsImage = async (
      chartViewId,
      isFromExpandedView,
      excludeOnDownloadIds
    ) => {
      if (selectedExpandedViewOption === expandedModalViewOptionEnum.TABLE) {
        isDownloadingFromTableView.current = true;
        setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
      }
      setIsDownloadingChart(true);

      setTimeout(async () => {
        try {
          await DashboardWidgetSharedUtils.downloadChartAsImage(
            chartViewId,
            fileName,
            excludeOnDownloadIds,
            isFromExpandedView
          );
        } catch (e) {
          toastContext.addFailToast(
            <span>Failed to download chart as image.</span>
          );
        } finally {
          setIsDownloadingChart(false);
          if (isDownloadingFromTableView.current) {
            setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
            isDownloadingFromTableView.current = false;
          }
        }
      }, downloadChartAsImageTimeoutInMs);
    };

    const onDownloadTableAsExcel = () => {
      const excelSheetName = `${period}`;
      const dataRows = chartData.toTableRowData();

      const headerRows = [
        [
          "",
          ...performanceComparisonChartConfig.monthTicks.map(
            (month) => `${month} (${unit})`
          ),
        ],
      ];

      DashboardTableExcelDownloader.downloadExcel(
        headerRows,
        dataRows,
        fileName,
        excelSheetName
      );
    };

    content = (
      <div id={performanceComparisonChartConfig.chartViewId}>
        <div className="dashboard--performance-comparison__mini-view-box">
          <PerformanceComparisonChartViewHeader
            title={title}
            titleInfoTooltip={titleInfoTooltip}
            period={period}
            hasDataGaps={hasDataGaps}
            subtopic={subtopic}
            intensity={intensity}
            hasData={hasData}
            showExpandedTableView={showExpandedTableView}
            showExpandedChartView={showExpandedChartView}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(
                performanceComparisonChartConfig.chartViewId,
                false,
                [
                  performanceComparisonChartConfig.miniViewExcludeOnDownloadContainerId,
                ]
              )
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
          <div className="content-container">
            {hasData ? (
              <PerformanceComparisonChartViewContent
                chartData={chartData}
                unit={unit}
                intensityUnit={intensityUnit}
              />
            ) : (
              <div className="table__no_content mt-4">No data available</div>
            )}
          </div>
        </div>
        {hasData && isShowExpandedView && (
          <ExpandedPerformanceComparisonChartView
            isShowExpandedChartView={isShowExpandedView}
            onCloseModal={() => setIsShowExpandedView(false)}
            title={title}
            period={period}
            subtopic={subtopic}
            hasDataGaps={hasDataGaps}
            intensity={intensity}
            unit={unit}
            intensityUnit={intensityUnit}
            chartData={chartData}
            selectedExpandedViewOption={selectedExpandedViewOption}
            onExpandedViewOptionChange={(newExpandedViewOption) =>
              setSelectedExpandedViewOption(newExpandedViewOption)
            }
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(
                performanceComparisonChartConfig.expandedChartViewId,
                true,
                [
                  performanceComparisonChartConfig.expandedViewExcludeOnDownloadContainerId,
                ]
              )
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        )}
      </div>
    );
  }

  return content;
};

export default PerformanceComparisonChartView;
