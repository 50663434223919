import React, { useEffect, useState } from "react";
import ReactDataSheet from "react-datasheet";
import BeforeUrlChangesPrompt from "../common/BeforeUrlChangesPrompt";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import { useTranslation } from "react-i18next";

const defaultHeaderRenderer = ({ className, columns, children }) => {
  return (
    <div className={className}>
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th
                className="cell read-only"
                key={col.label}
                style={{ width: `${col.width}rem` }}
              >
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export const BulkDataInputControls = ({
  addNewRow,
  onClearAll,
  rowCount = 0,
}) => {
  const { t } = useTranslation();

  const [showClearModal, setShowClearModal] = useState(false);
  const onModalClose = () => setShowClearModal(false);
  const onClearAllRows = () => {
    onClearAll();
    onModalClose();
  };
  return (
    <>
      <div className="bulk-input-ctrl__data-ctrl">
        <GVDSButton
          className="add-new-row"
          variant={buttonVariant.tertiary}
          onClick={addNewRow}
          text={t("data-management.shared.bulk-input.button-add-row")}
        />
        <GVDSButton
          className="clear-all-rows"
          variant={buttonVariant.destructive_tertiary}
          onClick={() => setShowClearModal(true)}
          text={t("data-management.shared.bulk-input.button-clear-all-rows")}
        />
        <div className="bulk-input-ctrl__row-count">
          {t("data-management.shared.bulk-input.label-row-count")}: {rowCount}
        </div>
      </div>
      <GVDSModal
        title={t(
          "data-management.shared.bulk-input.modal-title-clear-all-rows"
        )}
        size={GVDSModal.Size.small}
        show={showClearModal}
        onHide={onModalClose}
      >
        <GVDSModal.Body>
          <div>
            You’re about to clear all{" "}
            <strong>
              {rowCount} row{rowCount > 1 ? "s" : ""}
            </strong>{" "}
            in the bulk import form. Are you sure?
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={onModalClose}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={onClearAllRows}
            text={t(
              "data-management.shared.bulk-input.modal-footer-confirm-clear"
            )}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

const BulkDataInput = ({
  grid,
  columns = [],
  hasChanges = false,
  onChange,
  className = "",
  width = "100%",
  headerRenderer = defaultHeaderRenderer,
}) => {
  const beforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    if (hasChanges) {
      window.addEventListener("beforeunload", beforeUnload);
    }
    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, [hasChanges]);

  return (
    <div className={"bulk-data-input__datasheet " + className}>
      <div className="bulk-data-input__datasheet-table">
        <div style={{ width }}>
          <ReactDataSheet
            data={grid}
            sheetRenderer={(props) => headerRenderer({ columns, ...props })}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={onChange}
          />
        </div>
      </div>
      <BeforeUrlChangesPrompt
        when={hasChanges}
        pageName="Bulk Input Page"
        message="You have unsubmitted rows in the bulk input form. Your data will be cleared and won’t be stored when you leave this page. Are you sure?"
      />
    </div>
  );
};

export default BulkDataInput;
