import { createContext, useMemo, useState } from "react";

const CertificationOffcanvasContext = createContext(null);

export const CertificationOffcanvasProvider = ({ children }) => {
  const [show, setShow] = useState();
  const [activeTabKey, setActiveTabKey] = useState();

  const store = useMemo(
    () => ({
      show: show,
      setShow: setShow,
      activeTabKey: activeTabKey,
      setActiveTabKey: setActiveTabKey
    }),
    [show, activeTabKey]
  );

  return (
    <CertificationOffcanvasContext.Provider value={store}>
      {children}
    </CertificationOffcanvasContext.Provider>
  );
};


export default CertificationOffcanvasContext;