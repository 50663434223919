import { MenuTypeEnum, WidgetTypeEnum } from "./Models/WidgetModels";
import * as allIcons from "@tabler/icons-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContinuousTimeFilter from "./Filters/ContinuousTimeFilter";
import OperationalMetricFilter from "./Filters/OperationalMetricFilter";
import React from "react";
import UnitSelector from "./Filters/UnitSelector";
import Tab from "react-bootstrap/Tab";
import GVDSCustomIcons from "../../gvds-components/Icons/GVDSIconCustom";
import ClassificationSelector from "./Filters/ClassificationSelector";
import ComparisonTimeFilter from "./Filters/ComparisonTimeFilter";
import {
  ChangeOverTimeSiteRankingChartParser,
  ClassificationDonutChartParser,
  CollectionSummaryCardsParser,
  MainSummaryCardParser,
  PerformanceComparisonChartParser,
  PerformanceTimelineChartParser,
  SummarySiteRankingChartParser,
} from "./DashboardElementParsers";
import { DashboardTabs } from "./Widgets/DashboardTabs/DashboardTabs";

const customIconPrefix = "CustomIcon";

const widgetTypeEnumToParser = {
  [WidgetTypeEnum.MainSummaryCard]: MainSummaryCardParser,
  [WidgetTypeEnum.CollectionSummaryCards]: CollectionSummaryCardsParser,
  [WidgetTypeEnum.ClassificationDonutChart]: ClassificationDonutChartParser,
  [WidgetTypeEnum.SummarySiteRankingChart]: SummarySiteRankingChartParser,
  [WidgetTypeEnum.PerformanceComparisonChart]: PerformanceComparisonChartParser,
  [WidgetTypeEnum.PerformanceTimelineChart]: PerformanceTimelineChartParser,
  [WidgetTypeEnum.ChangeOverTimeSiteRankingChart]:
    ChangeOverTimeSiteRankingChartParser,
};

export default class DashboardSchemaParser {
  static parseView(schemaJson) {
    const widgets = [];

    if (schemaJson === null || schemaJson === undefined) {
      return { menu: null, widgets: null };
    }

    const menuSchema = schemaJson["menu"];
    const widgetsSchema = schemaJson["widgets"];

    widgetsSchema.forEach((element, index) => {
      widgets.push(DashboardSchemaParser.parseElement(element, `${index}.`));
    });

    return {
      menuSchema: menuSchema,
      widgets: widgets,
    };
  }

  static parseMenu(element) {
    const elementType = element["type"];
    switch (elementType) {
      case MenuTypeEnum.ContinuousTimeFilter:
        return <ContinuousTimeFilter key={elementType} />;
      case MenuTypeEnum.ComparisonTimeFilter:
        return <ComparisonTimeFilter key={elementType} />;
      case MenuTypeEnum.MetricSelector:
        return <OperationalMetricFilter key={elementType} />;
      case MenuTypeEnum.UnitSelector:
        return (
          <UnitSelector
            key={elementType}
            subtopic={element["config"]["subtopic"]}
          />
        );
      case MenuTypeEnum.ClassificationSelector:
        return (
          <ClassificationSelector
            key={elementType}
            subtopic={element["config"]["subtopic"]}
          />
        );
    }
  }

  static parseElement(element, orderPrefix = "", order = 0) {
    let key;

    switch (element["type"]) {
      case WidgetTypeEnum.SectionHeader:
        key = `SectionHeader-${orderPrefix}${order}`;

        const elementIcon = element["icon"];

        const IconToBeUsed = !!elementIcon
          ? elementIcon.startsWith(customIconPrefix)
            ? GVDSCustomIcons[elementIcon.slice(customIconPrefix.length)]
            : allIcons[elementIcon]
          : null;

        return (
          <div key={key} className="dashboard-section-header">
            <div className="gvds-text--heading3">{element["name"]}</div>
            {!!IconToBeUsed && <IconToBeUsed />}
          </div>
        );
      case WidgetTypeEnum.Row:
        key = `Row-${orderPrefix}${order}`;

        return (
          <Row key={key}>
            {element["children"].map((c, index) =>
              DashboardSchemaParser.parseElement(c, `${orderPrefix}.`, index)
            )}
          </Row>
        );
      case WidgetTypeEnum.Col:
        key = `Col-${orderPrefix}${order}`;
        return (
          <Col className="dashboard-widget--col" key={key}>
            {element["children"].map((c, index) =>
              DashboardSchemaParser.parseElement(c, `${orderPrefix}.`, index)
            )}
          </Col>
        );
      case WidgetTypeEnum.Tabs:
        key = `Tabs-${orderPrefix}${order}`;
        return <DashboardTabs key={key} element={element} />;
      case WidgetTypeEnum.Tab:
        key = `Tab-${element["name"]}-${orderPrefix}${order}`;
        return (
          <Tab key={key} title={element["name"]} eventKey={element["name"]}>
            {element["children"].map((c, index) =>
              DashboardSchemaParser.parseElement(c, key, index)
            )}
          </Tab>
        );
      default:
        if (element["type"] in widgetTypeEnumToParser) {
          return widgetTypeEnumToParser[element["type"]].parseElement(
            element,
            `${orderPrefix}.`
          );
        } else {
          return null;
        }
    }
  }

  static parseConfig(schemaJson, updateDataHolder) {
    const content = [];

    if (schemaJson === null || schemaJson === undefined) {
      return null;
    }

    for (const element of schemaJson) {
      DashboardSchemaParser.parseElementConfig(element, updateDataHolder);
    }

    return content;
  }

  static parseElementConfig(element, updateDataHolder) {
    switch (element["type"]) {
      case WidgetTypeEnum.Tabs:
      case WidgetTypeEnum.Tab:
      case WidgetTypeEnum.Row:
      case WidgetTypeEnum.Col:
        element["children"].forEach((c) =>
          DashboardSchemaParser.parseElementConfig(c, updateDataHolder)
        );
        break;
      default:
        if (element["type"] in widgetTypeEnumToParser) {
          widgetTypeEnumToParser[element["type"]].parseConfig(
            element,
            updateDataHolder
          );
        }
    }
  }
}
