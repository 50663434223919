import { DateTimeUtils, StringUtils } from "../../services/UtilsService";
import React from "react";
import { esgIcons } from "./PolicyModel";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import { TextCopies } from "../../config/text-copies";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";

const PolicyTrackerInfoContent = ({ data }) => {
  return (
    data &&
    data.map((d, index) => (
      <div key={index} className="policy-tracker__side-info__container">
        <span className="policy-tracker__side-info__label">
          <strong>{d.label}</strong>
        </span>
        <span className="policy-tracker__side-info__value">{d.value}</span>
      </div>
    ))
  );
};

const PolicyTrackerInfo = ({ className, title, children }) => {
  return (
    <div
      className={`policy-tracker-info-card__container${
        className ? " " + className : ""
      }`}
    >
      <div className="title">{title}</div>
      {children}
    </div>
  );
};

export const LocationInfo = ({
  region,
  countryName,
  state,
  city,
  jurisdictionLevel,
}) => {
  const locationData = [
    { label: "Region", value: region },
    { label: "Country", value: countryName },
    { label: "State", value: state },
    { label: "City", value: city },
    { label: "Jurisdiction Level", value: jurisdictionLevel },
  ];

  return (
    <PolicyTrackerInfo title="Location">
      <PolicyTrackerInfoContent data={locationData} />
    </PolicyTrackerInfo>
  );
};

export const RegulationParametersInfo = ({
  complianceObligation,
  legislativeStatus,
  effectiveDate,
  accurateAsOf,
  sectorFocus,
}) => {
  const regulationParametersData = [
    { label: "Compliance Obligation", value: complianceObligation },
    { label: "Legislative Status", value: legislativeStatus },
    {
      label: "Effective Date",
      value: effectiveDate ?? "",
    },
    {
      label: "Accurate as of",
      value: accurateAsOf ? DateTimeUtils.formatLocalDate(accurateAsOf) : "",
    },
    { label: "Sector Focus", value: sectorFocus },
  ];

  return (
    <PolicyTrackerInfo title="Regulation Parameters">
      <PolicyTrackerInfoContent data={regulationParametersData} />
    </PolicyTrackerInfo>
  );
};

export const ThemesInfo = ({
  esgStatuses,
  topics,
  keywords,
  transitionRisk,
}) => {
  const themesData = [
    {
      label: "E/S/G",
      value: esgStatuses
        ? esgStatuses.map((esg, index) => (
            <div key={index} className="esg-theme">
              <span className="me-1">{StringUtils.capitaliseWord(esg)}</span>
              <span className="esg-icon">{esgIcons[esg.toLowerCase()]}</span>
            </div>
          ))
        : null,
    },
    { label: "Topics", value: topics ? topics.join(", ") : "" },
    {
      label: "Keywords",
      value: keywords,
    },
    {
      label: "Transition Risk",
      value: transitionRisk,
    },
  ];

  return (
    <PolicyTrackerInfo title="Themes">
      <PolicyTrackerInfoContent data={themesData} />
    </PolicyTrackerInfo>
  );
};

export const RelatedInformationInfo = ({ references, relatedPolicies }) => {
  return (
    <PolicyTrackerInfo title="Related Information">
      <div className="policy-tracker__related-information__container">
        <div className="gvds-text--inputField mb-2">References</div>
        {references && references.length > 0 ? (
          <ul>
            {references.map((reference, index) => (
              <li key={index}>
                {reference.description && (
                  <span>{reference.description}: </span>
                )}
                <a href={reference.link} target="_blank">
                  {reference.link}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <div>NA</div>
        )}
      </div>
      <div className="gvds-text--inputField mt-3 mb-2">Related Policies</div>
      {relatedPolicies && relatedPolicies.length > 0 ? (
        <ul>
          {relatedPolicies.map((relatedPolicy, index) => (
            <li key={index}>{relatedPolicy.name}</li>
          ))}
        </ul>
      ) : (
        <div className="gvds-color--gray6">
          There are no related policies associated with this policy.
        </div>
      )}
    </PolicyTrackerInfo>
  );
};

export const PriorityLevelInfo = () => (
  <div className="policy-tracker__table__priority-level">
    <div className="mb-2">
      <strong>Priority:</strong>
    </div>
    <ul>
      <li key="high">
        <strong>High:</strong>{" "}
        {TextCopies.PolicyTracker.priorityLevelHighDescription}
      </li>
      <li key="medium">
        <strong>Medium:</strong>{" "}
        {TextCopies.PolicyTracker.priorityLevelMediumDescription}
      </li>
      <li key="low">
        <strong>Low:</strong>{" "}
        {TextCopies.PolicyTracker.priorityLevelLowDescription}
      </li>
      <li key="info-only">
        <strong>Info Only:</strong>{" "}
        {TextCopies.PolicyTracker.priorityLevelInfoOnlyDescription}
      </li>
    </ul>
  </div>
);

const AboutPolicyTrackerPriorityLevelItem = ({ level, description }) => (
  <div className="mb-2 policy-tracker__about-modal__content">
    <span className="gvds-text--heading4">{level} - </span>
    {description}
  </div>
);

const AboutPolicyTrackerInfoSection = ({ title, content }) => (
  <>
    <div className="border-bottom my-3" />
    <div className="gvds-text--heading4 mb-2">{title}</div>
    <div className="policy-tracker__about-modal__content">{content}</div>
  </>
);

export const AboutPolicyTrackerModal = ({ show, close }) => {
  return (
    <GVDSModal
      show={show}
      onHide={close}
      size={GVDSModal.Size.medium}
      title="About Policy Tracker"
    >
      <GVDSModal.Body>
        <div>
          Assess your portfolio or site's regulatory risks, opportunities and
          compliance needs with Greenview's curated list of
          sustainability-related policies.
        </div>
        <div className="border-bottom my-2" />
        <div className="gvds-text-button mb-2">Policies by Priority Level</div>
        <AboutPolicyTrackerPriorityLevelItem
          level="High"
          description={TextCopies.PolicyTracker.priorityLevelHighDescription}
        />
        <AboutPolicyTrackerPriorityLevelItem
          level="Medium"
          description={TextCopies.PolicyTracker.priorityLevelMediumDescription}
        />
        <AboutPolicyTrackerPriorityLevelItem
          level="Low"
          description={TextCopies.PolicyTracker.priorityLevelLowDescription}
        />
        <AboutPolicyTrackerPriorityLevelItem
          level="Info Only"
          description={
            TextCopies.PolicyTracker.priorityLevelInfoOnlyDescription
          }
        />
        <AboutPolicyTrackerInfoSection
          title="Compliance Obligation"
          content={TextCopies.PolicyTracker.complianceObligation}
        />
        <AboutPolicyTrackerInfoSection
          title="Legislative Status"
          content={TextCopies.PolicyTracker.legislativeStatus}
        />
        <AboutPolicyTrackerInfoSection
          title="Jurisdiction Level"
          content={TextCopies.PolicyTracker.jurisdictionLevel}
        />
        <AboutPolicyTrackerInfoSection
          title="Transition Risk"
          content={TextCopies.PolicyTracker.transitionRiskDescription}
        />
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={close}
          text="Okay"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};
