import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";

import ToastContext from "../../../context/ToastContext";
import withAuthentication from "../../HOC/withAuthentication";
import LoadingSpinner from "../../common/LoadingSpinner";
import { DateTimeUtils } from "../../../services/UtilsService";
import { getAdminViewSignUpRequest } from "../../../config/ROUTES_NAME";
import SignUpStatusBadge from "./SignUpStatusBadge";
import ViewSignUpRequestHistory from "./ViewAllSignUpRequestHistory";
import { SIGNUP_STATUS } from "../../../config/constants";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconExternalLink } from "@tabler/icons-react";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import Container from "react-bootstrap/Container";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import PortalAdminSignUpReviewService from "../../../services/PortalAdmin/PortalAdminSignUpReviewService";
import ViewAllEnterpriseSignUpRequests from "./ViewAllEnterpriseSignUpRequests";
import RoutePersistedTabs from "../../common/Tabs/RoutePersistedTabs";

const ViewAllSignUpRequests = () => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);

  const [allRequests, setAllRequests] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    PortalAdminSignUpReviewService.getAllSignUpRequests()
      .then((signUpRequests) => {
        setAllRequests(signUpRequests);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to load sign up requests.</span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Sign-up Requests</h1>
        </PageHeader.Title>
      </PageHeader>
      <RoutePersistedTabs defaultActiveKey="site-sign-ups">
        <Tab eventKey="site-sign-ups" title="Site Sign ups">
          <PendingSignUpRequests
            isLoading={isLoading}
            pendingRequests={allRequests.filter(
              (r) => r.status === SIGNUP_STATUS.PENDING
            )}
          />
        </Tab>
        <Tab eventKey="enterprise-inquiry" title="Enterprise Inquiry">
          <ViewAllEnterpriseSignUpRequests />
        </Tab>
        <Tab eventKey="history" title="History">
          <ViewSignUpRequestHistory
            isLoading={isLoading}
            historicalSignUpRequests={allRequests.filter(
              (r) => r.status !== SIGNUP_STATUS.PENDING
            )}
          />
        </Tab>
      </RoutePersistedTabs>
    </Container>
  );
};

const PendingSignUpRequests = ({ isLoading, pendingRequests }) => {
  const history = useHistory();
  const goToRequest = (requestId) => {
    history.push(getAdminViewSignUpRequest(requestId));
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(pendingRequests, null);

  const columns = [
    {
      header: "Request Date",
      headerStyle: { width: "300px" },
      dataKey: "request_date",
      sortable: true,
      renderer: (r) => {
        return <span>{DateTimeUtils.formatUTCDate(r.request_date)}</span>;
      },
    },
    {
      header: "User Email",
      headerStyle: { width: "300px" },
      dataKey: "email_address",
      sortable: true,
    },
    {
      header: "Site name",
      headerStyle: { width: "300px" },
      dataKey: "site_name",
      sortable: true,
    },
    {
      header: "For trial",
      headerStyle: { width: "300px" },
      dataKey: "for_trial",
      renderer: (r) => {
        return r.for_trial ? <div>Yes</div> : null;
      },
    },
    {
      header: "Status",
      headerStyle: { width: "300px" },
      dataKey: "status",
      sortable: true,
      renderer: (r) => {
        return <SignUpStatusBadge status={r.status} />;
      },
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "160px" },
      renderer: (r) => {
        return (
          <GVDSIconButton
            variant={iconButtonVariant.tertiary}
            onClick={() => {
              goToRequest(r.id);
            }}
            icon={<GVDSIcon Icon={IconExternalLink} />}
            tooltipText="View Details"
          />
        );
      },
    },
  ];

  return (
    <Container fluid>
      <GVDSTableCtrlContainer>
        {pendingRequests.length > 0 ? (
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        ) : null}
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        onRowClick={(r) => goToRequest(r.id)}
      />
      {isLoading && <LoadingSpinner />}
      {!isLoading && (!pendingRequests || pendingRequests.length === 0) && (
        <div className="table__no_content">
          There are currently no site sign-up requests that require action.
        </div>
      )}
    </Container>
  );
};

export default withAuthentication(ViewAllSignUpRequests);
