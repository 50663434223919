import React from "react";
import GVDSIcon from "../Icons/GVDSIcon";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";

const GVDSAccordionHeader = ({ isExpanded, title, count = null, onClick }) => {
  return (
    <div onClick={onClick} className="gvds-accordion-header">
      <div className="gvds-accordion-header__container">
        <GVDSIcon Icon={isExpanded ? IconChevronDown : IconChevronRight} />
        {title}
        {count !== null && (
          <span className="gvds-accordion-header__count">({count})</span>
        )}
      </div>
    </div>
  );
};

export default GVDSAccordionHeader;
