import React, { useContext, useRef, useState } from "react";
import { uniqueId } from "lodash";
import axios from "axios";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
  DEFAULT_MAX_FILE_SIZE_IN_MB,
  new_entry_prefix,
  tobe_deleted_entry_prefix,
} from "../../config/constants";
import { API_URL } from "../../config/api-config";
import DragAndDrop from "./DragAndDrop";
import ToastContext from "../../context/ToastContext";
import { FileUtils, HttpResponseService } from "../../services/UtilsService";
import InlineSpinner from "./InlineSpinner";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import {
  IconCircleCheck,
  IconCircleX,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";

export const MIMETypes = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PDF: "application/pdf",
  VIDEO: "video/mp4",
};

export class FileAttachmentItemModel {
  constructor(
    id = uniqueId(new_entry_prefix),
    name = null,
    link = null,
    file = null
  ) {
    this.id = id;
    this.name = name;
    this.link = link;
    this.file = file;
  }

  static fromFileSelector = (file) => {
    return new FileAttachmentItemModel(
      uniqueId(new_entry_prefix),
      file.name,
      null,
      file
    );
  };

  static fromFileDTO = (fileDTO) => {
    return new FileAttachmentItemModel(
      fileDTO.id,
      fileDTO.filename,
      API_URL + fileDTO.link,
      null
    );
  };

  isNew() {
    return this.id.startsWith(new_entry_prefix);
  }

  isToBeDeleted() {
    return this.id.startsWith(tobe_deleted_entry_prefix);
  }

  getSize() {
    return this.file ? this.file.size : 0;
  }

  removeNewPrefix() {
    if(this.isNew()) {
      this.id = this.id.replace(new_entry_prefix, "")
    }
  }
}

export const FileUploader = ({
  files,
  setFiles,
  description = "file here",
  maxFileSizeInMB = null,
  disabled = false,
  customErrorBanner = null,
  onSelectOversizedFiles = null,
  fileTypes = "*",
}) => {
  const hiddenFileInput = useRef(null);
  const [errorMessages, setErrorMessages] = useState([]);

  const addFiles = (fileList) => {
    const duplicateNameFiles = [];
    const zeroByteFiles = [];
    const overSizedFiles = [];

    const selectedFiles = Array.from(fileList)
      .filter((file) => {
        if (
          files.filter(
            (existingFile) =>
              existingFile.name === file.name && !existingFile.isToBeDeleted()
          ).length > 0
        ) {
          duplicateNameFiles.push(file);
          return false;
        } else {
          return true;
        }
      })
      .filter((file) => {
        if (file.size === 0) {
          zeroByteFiles.push(file);
          return false;
        } else {
          return true;
        }
      })
      .filter((file) => {
        if (
          maxFileSizeInMB &&
          FileUtils.isFileSizeLargerThan(file, maxFileSizeInMB)
        ) {
          overSizedFiles.push(file);
          return false;
        } else {
          return true;
        }
      })
      .map((file) => {
        return FileAttachmentItemModel.fromFileSelector(file);
      });

    if (duplicateNameFiles.length > 0) {
      const duplicateErrorMessage = (
        <>
          Files with the same name already exist:
          <br />
          <ul>
            {duplicateNameFiles.map((file, index) => (
              <li key={`duplicate-${index}`}>{file.name}</li>
            ))}
          </ul>
        </>
      );
      setErrorMessages([...errorMessages, duplicateErrorMessage]);
    }

    if (zeroByteFiles.length > 0) {
      const zeroByteErrorMessage = (
        <>
          Files are empty (Please unzip your files first if they are listed
          here):
          <br />
          <ul>
            {zeroByteFiles.map((file, index) => (
              <li key={`zero-${index}`}>{file.name}</li>
            ))}
          </ul>
        </>
      );
      setErrorMessages([...errorMessages, zeroByteErrorMessage]);
    }

    if (overSizedFiles.length > 0) {
      const overSizeErrorMessage = (
        <>
          Files exceeding file size limit
          {maxFileSizeInMB ? ` (${maxFileSizeInMB}MB)` : ""}:
          <br />
          <ul>
            {overSizedFiles.map((file, index) => (
              <li key={`zero-${index}`}>{file.name}</li>
            ))}
          </ul>
        </>
      );
      setErrorMessages([...errorMessages, overSizeErrorMessage]);
      if (onSelectOversizedFiles !== null) {
        onSelectOversizedFiles(overSizedFiles);
      }
    }

    setFiles([...files, ...selectedFiles]);
  };

  const onSelectFileClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const uploadBoxContent = (
    <>
      <div className="upload-box--content">
        <GVDSIcon Icon={IconUpload} />
        <div className="upload-box--text">
          Drag & drop {description}
          <br />
          or
        </div>
        <GVDSTextButton
          className="upload-box--text-button"
          text="Select file(s)"
          disabled={disabled}
        />
        {maxFileSizeInMB && (
          <div className="upload-box--caption">
            maximum file size: {maxFileSizeInMB}MB
          </div>
        )}
      </div>
      <input
        ref={hiddenFileInput}
        type="file"
        name="file"
        multiple
        accept={fileTypes}
        onChange={(event) => addFiles(event.target.files)}
        style={{ display: "none" }}
        disabled={disabled}
      />
    </>
  );

  return (
    <>
      <DragAndDrop
        onDrop={addFiles}
        onClick={onSelectFileClick}
        disabled={disabled}
      >
        {uploadBoxContent}
      </DragAndDrop>
      {customErrorBanner === null && errorMessages.length > 0 && (
        <GVDSBanner
          title="The following files have been excluded from upload. Please try again."
          variant={GVDSBanner.Variants.error}
        >
          <ul>
            {errorMessages.map((errorMessage, index) => (
              <li key={index}>{errorMessage}</li>
            ))}
          </ul>
        </GVDSBanner>
      )}
      {customErrorBanner !== null && customErrorBanner}
    </>
  );
};

export const MultipleFilesUploader = ({
  files,
  setFiles,
  maxFileSizeInMB = DEFAULT_MAX_FILE_SIZE_IN_MB,
  apiParams,
  useS3PresignedURL = false,
  disabled = false,
  disableSelectFile = false,
  onRemoveNewFileFromFiles = null,
  customErrorBanner = null,
  onSelectOversizedFiles = null,
  fileTypes = "*",
}) => {
  const [fileToBeDeleted, setFileToBeDeleted] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  const removeNewFileFromFiles = (newFileToRemove) => {
    files.splice(files.indexOf(newFileToRemove), 1);
    setFiles([...files]);
    if (onRemoveNewFileFromFiles) {
      onRemoveNewFileFromFiles();
    }
  };

  const promptFileDelete = (fileAttachmentItemModel) => {
    if (fileAttachmentItemModel.isNew()) {
      removeNewFileFromFiles(fileAttachmentItemModel);
    } else {
      setFileToBeDeleted(fileAttachmentItemModel);
      setShowDeleteModal(true);
    }
  };

  const onFileDelete = () => {
    if (fileToBeDeleted.isNew()) {
      // this code path should not happen, handling this case for defensive handling
      removeNewFileFromFiles(fileToBeDeleted);
    } else {
      fileToBeDeleted.id = tobe_deleted_entry_prefix + fileToBeDeleted.id;
      setFiles([...files]);
    }
    setShowDeleteModal(false);
  };

  const deleteFileModal = (
    <Modal show={showDeleteModal} onHide={cancelDelete} centered>
      <Modal.Header closeButton>
        <Modal.Title>Deleting {fileToBeDeleted?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This action cannot be undone once changes are saved. Are you sure?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={cancelDelete}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onFileDelete}>
          Yes, Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      <FileUploader
        files={files}
        setFiles={setFiles}
        maxFileSizeInMB={maxFileSizeInMB}
        disabled={disabled || disableSelectFile}
        customErrorBanner={customErrorBanner}
        onSelectOversizedFiles={onSelectOversizedFiles}
        fileTypes={fileTypes}
      />
      {files.map((file, index) => (
        <EditFileItemDisplay
          key={`file-${index}`}
          file={file}
          onDelete={promptFileDelete}
          apiParams={apiParams}
          useS3PresignedURL={useS3PresignedURL}
          disabled={disabled}
        />
      ))}
      {deleteFileModal}
    </>
  );
};

const EditFileItemDisplay = ({
  file: fileAttachmentItemModel,
  onDelete,
  apiParams,
  useS3PresignedURL = false,
  disabled = false,
}) => {
  let filenameDisplay;

  if (fileAttachmentItemModel.isNew()) {
    filenameDisplay = (
      <div className="uploaded-files-item-display">
        <GVDSIcon Icon={IconCircleCheck} />
        <span>{fileAttachmentItemModel.name}</span>
      </div>
    );
  } else if (fileAttachmentItemModel.isToBeDeleted()) {
    filenameDisplay = (
      <div className="uploaded-files-item-display">
        <GVDSIcon Icon={IconCircleX} />
        <span>{fileAttachmentItemModel.name}</span>
      </div>
    );
  } else {
    filenameDisplay = (
      <ViewFileItemDisplayWithAuth
        file={fileAttachmentItemModel}
        apiParams={apiParams}
        useS3PresignedURL={useS3PresignedURL}
      />
    );
  }

  return (
    <div className="uploaded-files-container">
      <div className="uploaded-files">
        {filenameDisplay}
        {!disabled && !fileAttachmentItemModel.isToBeDeleted() && (
          <GVDSIconButton
            className="delete-file-button"
            variant={iconButtonVariant.destructive}
            icon={<GVDSIcon Icon={IconTrash} />}
            onClick={(e) => {
              e.preventDefault();
              onDelete(fileAttachmentItemModel);
            }}
            tooltipText="Delete File"
          />
        )}
      </div>
    </div>
  );
};

export const ViewFileItemDisplay = ({ file: fileAttachmentItemModel }) => {
  return (
    <a href={fileAttachmentItemModel.link}>{fileAttachmentItemModel.name}</a>
  );
};

export const ViewFileItemDisplayWithAuth = ({
  file: fileAttachmentItemModel,
  apiParams = {},
  useS3PresignedURL = false,
}) => {
  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = async () => {
    setIsLoading(true);
    try {
      if (useS3PresignedURL) {
        await downloadFromPresignedS3Url(
          fileAttachmentItemModel.link,
          apiParams
        );
      } else {
        await HttpResponseService.getBlobResponse(
          fileAttachmentItemModel.link,
          apiParams
        );
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(
        <span>Failed to download attachment file.</span>
      );
    }
  };

  if (!fileAttachmentItemModel) {
    return null;
  }

  return (
    <GVDSTextButton
      onClick={downloadFile}
      className="ms-2 text-start"
      text={isLoading ? <InlineSpinner /> : fileAttachmentItemModel.name}
    />
  );
};

export const getPresignedS3Url = async (link) => {
  return axios.get(link).then((response) => {
    return response.data;
  });
};

export const downloadFromPresignedS3UrlAsBlobData = async (link) => {
  // Assumes the server will return HTTP 200 with the presigned S3 URL as the link
  // - Server cannot issue redirect as we cannot control the redirect headers, in which Authorization header will be
  //   forwarded
  const presignedS3URL = await getPresignedS3Url(link);

  return axios
    .get(presignedS3URL, {
      responseType: "blob", // important
      transformRequest: (data, headers) => {
        delete headers["Authorization"];
      },
    })
    .then((blobResponse) => {
      return blobResponse.data;
    });
};

const TIMESTAMP_UNIQUENESS_SEPARATOR = "-";

export const downloadFromPresignedS3Url = async (
  link,
  params = {},
  isFilenameStartsWithTimestamp = false
) => {
  // Assumes the server will return HTTP 200 with the presigned S3 URL as the link
  // - Server cannot issue redirect as we cannot control the redirect headers, in which Authorization header will be
  //   forwarded
  return axios.get(link, { params }).then((response) => {
    const presignedS3URL = response.data;
    return axios
      .get(presignedS3URL, {
        responseType: "blob", // important
        transformRequest: (data, headers) => {
          delete headers["Authorization"];
        },
      })
      .then((blobResponse) => {
        const s3Url = new URL(presignedS3URL);

        const path = s3Url.pathname;
        let filename = decodeURI(path.substring(path.lastIndexOf("/") + 1));
        if (isFilenameStartsWithTimestamp) {
          filename = filename.substring(
            filename.indexOf(TIMESTAMP_UNIQUENESS_SEPARATOR) + 1
          );
        }
        return HttpResponseService.downloadFileFromResponse(
          blobResponse,
          filename,
          isFilenameStartsWithTimestamp
        );
      });
  });
};
