import React from "react";

const GVDSTableCtrlContainer = ({ className, children }) => {
  let combinedClassName = "gvds-table-ctrl--container";

  if (className) {
    combinedClassName = combinedClassName + " " + className;
  }

  return <div className={combinedClassName}>{children}</div>;
};

export default GVDSTableCtrlContainer;
