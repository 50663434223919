import React, { useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { NumberService } from "../../services/UtilsService";

const CustomRangeSlider = ({
  className,
  value,
  onChange,
  disabled = false,
}) => {
  const min = 0;
  const max = 100;
  const outsideRange = value < min || value > max;

  const [show, setShow] = useState(false);
  const sliderRef = useRef(null);

  return (
    <div
      className={className + " d-flex align-items-center custom-range-slider-container"}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <input
        className="w-100"
        ref={sliderRef}
        type="range"
        min={min}
        max={max}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <Overlay target={sliderRef?.current} show={show} placement="top">
        {(props) => (
          <Tooltip className='mb-2' {...props}>
            {outsideRange
              ? "Value is not within slider range."
              : `${NumberService.toPrecision(value, 0)}%`}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

export default CustomRangeSlider;
