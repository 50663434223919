import React, { useContext, useState } from "react";

import InitiativeService from "../../../services/InitiativeService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import { RESOURCES } from "../../../config/constants";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";

const DeleteInitiativePrompt = ({
  show,
  onCloseModal,
  initiative,
  onSuccess,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);

  if (!initiative) {
    return null;
  }

  const closeModal = () => {
    if (!isLoading) {
      onCloseModal();
    }
  };

  const deleteInitiative = async () => {
    const selectedInventory = userInventory.selectedInventory.get;
    if (selectedInventory && selectedInventory.type === RESOURCES.SITE) {
      setIsLoading(true);
      InitiativeService.deleteInitiative(
        selectedInventory.type,
        selectedInventory.id,
        initiative.id
      )
        .then(() => {
          setIsLoading(false);
          onCloseModal();
          onSuccess();
          toastContext.addSuccessToast(
            <span>Initiative deleted successfully.</span>
          );
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to delete initiative.</span>);
        });
    }
  };

  return (
    <GVDSModal
      title="Delete Initiative"
      size={GVDSModal.Size.small}
      show={show}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            Once you delete your initiative, you will be unable to retrieve it
            again. Are you sure?
          </>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          disabled={isLoading}
          onClick={closeModal}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          disabled={isLoading}
          onClick={deleteInitiative}
          text="Delete"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default DeleteInitiativePrompt;
