import React, { useEffect, useState } from "react";
import EnvironmentalSubtopicService from "../../../services/EnvironmentalSubtopicService";
import withAuthentication from "../../HOC/withAuthentication";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Table } from "react-bootstrap";
import OperationalService from "../../../services/OperationalService";
import {
  SYSTEM_TOOLBOX_ENVIRONMENTAL_TYPES,
  SYSTEM_TOOLBOX_OPERATIONAL_TYPES,
  SYSTEM_TOOLBOX_PERFORMANCE_GROUP_LIST,
  SYSTEM_TOOLBOX_SUBTOPICS,
  SYSTEM_TOOLBOX_UNITS,
} from "../../../config/ROUTES_NAME";
import PerformanceGroupService from "../../../services/ReferenceDataServices/PerformanceGroupService";
import UnitsService from "../../../services/ReferenceDataServices/UnitsService";

const DataManagementTable = (props) => {
  const [environmentTypes, setEnvironmentTypes] = useState([]);
  const headerName = "Data Management";
  const [activeKey, setActiveKey] = useState(headerName);
  const [operationalTypes, setOperationalTypes] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [performanceGroups, setPerformanceGroups] = useState([]);
  const [unitConversionfactors, setUnitConversionFactors] = useState([]);

  useEffect(() => {
    EnvironmentalSubtopicService.getAllInputSubtopics().then((response) => {
      setSubtopics(response);
      setEnvironmentTypes(response[0].environmental_types);
    });
  }, []);

  useEffect(() => {
    OperationalService.getOperationMetricType().then((response) => {
      setOperationalTypes(response);
    });
  }, []);

  useEffect(() => {
    PerformanceGroupService.getAllPerformanceGroup().then((response) => {
      setPerformanceGroups(response);
    });
  }, []);

  useEffect(() => {
    UnitsService.getUnitConversionFactor().then((response) => {
      setUnitConversionFactors(response);
    });
  }, []);

  return (
    <>
      <section>
        <Accordion
          key={headerName}
          defaultActiveKey={activeKey}
          activeKey={activeKey === headerName ? activeKey : null}
          className="accordion-header"
        >
          <Accordion.Item eventKey={headerName}>
            <Card.Header
              onClick={() =>
                activeKey === headerName
                  ? setActiveKey(null)
                  : setActiveKey(headerName)
              }
            >
              <div className="body-2-bold">
                {headerName.toUpperCase()}{" "}
                <span className="collapse-toggle">
                  {activeKey === headerName ? "(collapse)" : "(expand)"}
                </span>
              </div>
            </Card.Header>
            <Accordion.Body>
              <Table className="reference-data-table section-box">
                <tbody>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_ENVIRONMENTAL_TYPES)
                        }
                      >
                        Environmental Types
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {environmentTypes.length > 0 &&
                        environmentTypes
                          .slice(0, 2)
                          .map((envType) => (
                            <span key={envType.id}>{envType.name + ", "}</span>
                          ))}
                      {environmentTypes.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_OPERATIONAL_TYPES)
                        }
                      >
                        Operational Types
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {operationalTypes.length > 0 &&
                        operationalTypes
                          .slice(0, 2)
                          .map((optType) => (
                            <span key={optType.id}>{optType.name + ", "}</span>
                          ))}
                      {operationalTypes.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(
                            SYSTEM_TOOLBOX_PERFORMANCE_GROUP_LIST
                          )
                        }
                      >
                        Performance Grouping
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {performanceGroups.length > 0 &&
                        performanceGroups
                          .slice(0, 2)
                          .map((envType) => (
                            <span key={envType.name}>
                              {envType.name + ", "}
                            </span>
                          ))}
                      {performanceGroups.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_SUBTOPICS)
                        }
                      >
                        Subtopics
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {subtopics.length > 0 &&
                        subtopics
                          .slice(0, 2)
                          .map((subtopic) => (
                            <span key={subtopic.id}>
                              {subtopic.name + ", "}
                            </span>
                          ))}
                      {subtopics.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() => props.history.push(SYSTEM_TOOLBOX_UNITS)}
                      >
                        Units
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {unitConversionfactors.length > 0 &&
                        unitConversionfactors
                          .slice(0, 2)
                          .map((unitConversionFactor) => (
                            <span key={unitConversionFactor.unit_name}>
                              {unitConversionFactor.unit_name + ", "}
                            </span>
                          ))}
                      {unitConversionfactors.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </>
  );
};

export default withAuthentication(DataManagementTable);
