import React from "react";
import Select from "react-select";
import { uniqueId } from "lodash";

const defaultSingleSelectStyles = {
  // disabled state cursor style cannot be overridden through CSS
  control: (css, state) => ({
    ...css,
    ...(state.isDisabled && {
      pointerEvents: "auto",
      cursor: "not-allowed",
    }),
  }),
};

const portalingSingleSelectStyles = {
  // disabled state cursor style cannot be overridden through CSS
  control: (css, state) => ({
    ...css,
    ...(state.isDisabled && {
      pointerEvents: "auto",
      cursor: "not-allowed",
    }),
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export default ({
  id = uniqueId(),
  className,
  name,
  options = [],
  onChange,
  components,
  width = "200px",
  noOptionsMessage = "No options",
  isDisabled,
  styles,
  menuIsOpen,
  closeMenuOnSelect,
  isLoading,
  isSearchable,
  isClearable,
  isOptionDisabled,
  menuPlacement,
  required,
  value,
  isInvalid = false,
  invalidMessage = "Field cannot be empty",
  placeholder = "Select Option",
  menuShouldScrollIntoView = true,
  portaling = null,
}) => {
  const onSelectChange = (selectedOption, actionContext) => {
    onChange(selectedOption, actionContext);
  };

  return (
    <>
      <Select
        id={id}
        name={name}
        value={value}
        options={options}
        noOptionsMessage={() => noOptionsMessage}
        components={components}
        width={width}
        onChange={onSelectChange}
        className={`react-select single-select ${isInvalid && "invalid"} ${
          className || ""
        }`}
        classNamePrefix="react-select"
        styles={
          portaling ? portalingSingleSelectStyles : defaultSingleSelectStyles
        }
        menuIsOpen={menuIsOpen}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isOptionDisabled={isOptionDisabled}
        menuPlacement={menuPlacement}
        required={required}
        placeholder={placeholder}
        menuPortalTarget={portaling ? document.body : null}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
      />
      {isInvalid && (
        <div className="single-select-invalid-feedback">{invalidMessage}</div>
      )}
    </>
  );
};

export const getOptionByValueFromIdName = (optionValue, idNameOptions) => {
  if (idNameOptions) {
    const option = idNameOptions.find((option) => option.id === optionValue);
    return option ? { value: option.id, label: option.name } : null;
  } else {
    return null;
  }
};
