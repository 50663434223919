import React, { useEffect } from "react";
import GVDSIconSlim from "../../Icons/GVDSIconSlim";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const GVDSPagination = ({
  startIndex,
  endIndex,
  pageSize = 20,
  total,
  onChange,
}) => {
  useEffect(() => {
    onChange(1, pageSize < total ? pageSize : total);
  }, [total]);

  const lastPage = () => {
    if (startIndex > 1) {
      onChange(startIndex - pageSize, startIndex - 1);
    }
  };

  const nextPage = () => {
    if (endIndex < total) {
      onChange(
        startIndex + pageSize,
        endIndex + pageSize < total ? endIndex + pageSize : total
      );
    }
  };

  return (
    <div className="gvds-pagination__layout-container">
      <div className="gvds-pagination__container">
        <div className="gvds-pagination-control-count">
          {startIndex}-{endIndex} of {total}
        </div>
        <div
          className={
            "gvds-pagination-control-button pagination-left" +
            (startIndex > 1 ? "" : " disabled")
          }
          onClick={lastPage}
        >
          <GVDSIconSlim Icon={IconChevronLeft} />
        </div>{" "}
        <div
          className={
            "gvds-pagination-control-button pagination-right" +
            (endIndex < total ? "" : " disabled")
          }
          onClick={nextPage}
        >
          <GVDSIconSlim Icon={IconChevronRight} />
        </div>
      </div>
    </div>
  );
};

export default GVDSPagination;
