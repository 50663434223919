import React, { useContext, useEffect, useState } from "react";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import {
  DateTimeUtils,
  NumberService,
  StringUtils,
} from "../../../services/UtilsService";
import {
  getSubscriptionPriceDisplay,
  SubscriptionPricingType,
} from "../../SystemToolbox/Subscription/SubscriptionSharedUtils";
import GVDSTable, {
  ACTIONS_DATAKEY,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconDownload, IconReceipt } from "@tabler/icons-react";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import UserInventoryContext from "../../../context/UserInventoryContext";
import BillingService from "../../../services/BillingService";
import ToastContext from "../../../context/ToastContext";
import SupportContactLink from "../../common/SupportContactLink";
import { RESOURCES } from "../../../config/constants";
import GVDSIconCustom from "../../../gvds-components/Icons/GVDSIconCustom";
import TooltipPersistentOnHover from "../../common/Tooltip/TooltipPersistentOnHover";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import { TOOLTIP_PLACEMENTS } from "../../../config/style-config";
import { Trans, useTranslation } from "react-i18next";

const ViewInvoices = () => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const loadInvoices = () => {
    setIsLoadingInvoices(true);

    BillingService.getInvoices(selectedInventory.type, selectedInventory.id)
      .then((invoices) => {
        setInvoices(invoices ?? []);
      })
      .catch(() => {
        setInvoices([]);
        toastContext.addFailToast(<span>Failed to load invoices</span>);
      })
      .finally(() => {
        setIsLoadingInvoices(false);
      });
  };

  useEffect(() => {
    loadInvoices();
  }, [userInventory.selectedInventory.get]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(invoices);

  const columns = [
    {
      header: t("admin.billing.history.table-header.date"),
      dataKey: "invoice_date",
      sortable: false,
      headerStyle: { width: "120px" },
      renderer: (i) => (
        <div className="invoice-date">
          {i.invoice_date ? DateTimeUtils.formatUTCDate(i.invoice_date) : ""}
        </div>
      ),
    },
    {
      header: t("admin.billing.history.table-header.invoice-no"),
      dataKey: "invoice_number",
      sortable: false,
      headerStyle: { width: "120px" },
      renderer: (i) => <div className="invoice-number">{i.invoice_number}</div>,
    },
    {
      header: t("admin.billing.history.table-header.billing-period"),
      dataKey: "billing_period",
      sortable: false,
      headerStyle: { width: "220px" },
      renderer: (i) => <div className="billing-period">{i.billing_period}</div>,
    },
    {
      header: t("admin.billing.history.table-header.total"),
      dataKey: "total",
      sortable: false,
      headerStyle: { width: "180px" },
      renderer: (i) => {
        const proratedPriceDisplay =
          i["prorated_price_amount"] !== null
            ? getSubscriptionPriceDisplay(
                i.currency,
                NumberService.format(i["prorated_price_amount"])
              )
            : "";
        return (
          <>
            <div className="total-invoice">
              {i.pricing_type === SubscriptionPricingType.PAID ? (
                <div className="d-flex flex-row align-items-center">
                  <div>
                    {getSubscriptionPriceDisplay(
                      i.currency,
                      NumberService.format(i["price_amount"])
                    )}
                  </div>{" "}
                  {i["prorated_price_amount"] !== null && (
                    <InfoTooltip
                      info={
                        <Trans i18nKey="admin.billing.history.prorated-message">
                          Invoice is prorated to {{ proratedPriceDisplay }}
                        </Trans>
                      }
                      placement={TOOLTIP_PLACEMENTS.TOP}
                    />
                  )}
                </div>
              ) : (
                StringUtils.capitaliseWord(SubscriptionPricingType.FREE)
              )}
            </div>
            {i.discount_code && (
              <div className="gvds-text--caption">
                {t("admin.billing.history.label-discount-code")}:{" "}
                {i.discount_code}
              </div>
            )}
            {i.is_tax_applicable && (
              <div className="gvds-text--caption">
                {t("admin.billing.history.label-tax-applied")}
              </div>
            )}
          </>
        );
      },
    },
    {
      header: t("shared-input-label.actions"),
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "120px" },
      renderer: (i) => {
        if (
          !i.invoice_date &&
          !i.invoice_number &&
          i.pricing_type === SubscriptionPricingType.PAID
        ) {
          let resourceName;

          if (selectedInventory.type === RESOURCES.PORTFOLIO) {
            const selectedContract = userInventory.contracts.get.find(
              (c) => c.id === userInventory.selectedContractId.get
            );
            resourceName = selectedContract?.name;
          } else {
            const selectedSite = userInventory.sites.get.find(
              (s) => s.id === selectedInventory.id
            );
            resourceName = selectedSite?.name;
          }

          return (
            <TooltipPersistentOnHover
              triggerContent={<GVDSIconCustom.Info />}
              triggerClassName="info-tooltip-icon ms-2"
              tooltipContent={
                <div>
                  <Trans i18nKey="admin.billing.history.legacy-subscription">
                    This is legacy subscription.
                    <br />
                    <SupportContactLink
                      subject={`Inquiry about subscription of ${resourceName} - ${i.billing_period}`}
                    >
                      Contact support
                    </SupportContactLink>{" "}
                    for more info about this subscription.
                  </Trans>
                </div>
              }
            />
          );
        } else {
          return (
            <>
              {i.invoice_pdf_url && (
                <GVDSIconButton
                  variant={iconButtonVariant.tertiary}
                  onClick={() =>
                    window.open(i.invoice_pdf_url, "noopener,noreferrer")
                  }
                  icon={<GVDSIcon Icon={IconDownload} />}
                  tooltipText={t(
                    "admin.billing.history.button-download-invoice"
                  )}
                  className="download-invoice-button"
                />
              )}
              {i.receipt_pdf_url && (
                <GVDSIconButton
                  variant={iconButtonVariant.tertiary}
                  onClick={() =>
                    window.open(i.receipt_pdf_url, "noopener,noreferrer")
                  }
                  icon={<GVDSIcon Icon={IconReceipt} />}
                  tooltipText={t(
                    "admin.billing.history.button-download-receipt"
                  )}
                  className="download-receipt-button"
                />
              )}
            </>
          );
        }
      },
    },
  ];

  let content;

  if (isLoadingInvoices) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <GVDSTable
        columns={columns}
        hideRowNumber={true}
        dataToDisplay={invoices}
        startIndex={startIndex}
        noContent={
          <div className="table__no_content">
            {t("admin.billing.history.no-invoices")}
          </div>
        }
        viewControl={
          <>
            <GVDSTableCtrlContainer>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
          </>
        }
      />
    );
  }

  return (
    <div>
      <div className="gvds-text--heading2 mb-3">
        {t("admin.billing.section-title-history")}
      </div>
      {content}
    </div>
  );
};

export default ViewInvoices;
