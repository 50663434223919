import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import React, { forwardRef, useState } from "react";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { Collapse } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import GVDSFormCheckboxWithIndeterminate, {
  indeterminateCheckboxValueEnum,
} from "../../../gvds-components/Forms/GVDSFormCheckboxWithIndeterminate";
import fuzzysort from "fuzzysort";
import {
  DashboardSearchKeys,
  fuzzysortOptionsWithKeys,
} from "../../../config/search-config";
import { UtilsService } from "../../../services/UtilsService";
import { DashboardFilterInputContentDisplay } from "./DashboardFilterSharedUtils";

const getSiteCheckboxState = (site, selectedSites) => {
  const selectedSite = selectedSites.find((s) => s.id === site.id);

  if (!selectedSite) {
    return indeterminateCheckboxValueEnum.false;
  }

  if (site.facilities.length <= 1) {
    return indeterminateCheckboxValueEnum.true;
  }

  const selectedFacilitiesCount = selectedSite.facilities.length;
  const totalFacilities = site.facilities.length;

  if (selectedFacilitiesCount === 0) {
    return indeterminateCheckboxValueEnum.false;
  } else if (selectedFacilitiesCount === totalFacilities) {
    return indeterminateCheckboxValueEnum.true;
  } else {
    return indeterminateCheckboxValueEnum.indeterminate;
  }
};

const isFacilityUnderSiteSelected = (
  selectedFacilityId,
  selectedSites,
  facilitySiteId
) => {
  const selectedSite = selectedSites.find((site) => site.id === facilitySiteId);
  if (!selectedSite) {
    return false;
  }

  return selectedSite.facilities.some(
    (facility) => facility.id === selectedFacilityId
  );
};

const InventoryFilterTrigger = forwardRef(({ selectedCount, onClick }, ref) => {
  return (
    <div
      className="dashboard-filter__dropdown-input__trigger"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <DashboardFilterInputContentDisplay
        selectedCount={selectedCount}
        label="Sites"
      />
    </div>
  );
});

const InventoryFilterView = ({ sites, selectedSites, setSelectedSites }) => {
  const [searchText, setSearchText] = useState("");
  const [expandedSites, setExpandedSites] = useState([]);

  const toggleSiteExpansion = (siteId) => {
    setExpandedSites(UtilsService.toggleItem(expandedSites, siteId));
  };

  const handleSiteSelect = (siteId) => {
    const currentSelectedSites = [...selectedSites];
    const isCurrentlySelected = currentSelectedSites.some(
      (s) => s.id === siteId
    );

    let newSelectedSites;
    if (isCurrentlySelected) {
      newSelectedSites = currentSelectedSites.filter((s) => s.id !== siteId);
    } else {
      const siteToAdd = sites.find((s) => s.id === siteId);
      newSelectedSites = [
        ...currentSelectedSites,
        {
          id: siteId,
          name: siteToAdd.name,
          facilities: siteToAdd.facilities.map((facility) => ({
            id: facility.id,
            name: facility.name,
          })),
        },
      ];
    }

    setSelectedSites(newSelectedSites);
  };

  const handleFacilitySelect = (siteId, facilityId) => {
    const currentSelectedSites = [...selectedSites];
    const site = sites.find((s) => s.id === siteId);
    const facility = site.facilities.find((f) => f.id === facilityId);
    const siteIndex = currentSelectedSites.findIndex((s) => s.id === siteId);

    let newSelectedSites;

    if (siteIndex === -1) {
      newSelectedSites = [
        ...currentSelectedSites,
        {
          id: siteId,
          name: site.name,
          facilities: [
            {
              id: facilityId,
              name: facility.name,
            },
          ],
        },
      ];
    } else {
      newSelectedSites = [...currentSelectedSites];
      const selectedSite = newSelectedSites[siteIndex];
      const facilityIndex = selectedSite.facilities.findIndex(
        (f) => f.id === facilityId
      );

      if (facilityIndex === -1) {
        selectedSite.facilities.push({
          id: facilityId,
          name: facility.name,
        });
      } else {
        selectedSite.facilities = selectedSite.facilities.filter(
          (f) => f.id !== facilityId
        );

        if (selectedSite.facilities.length === 0) {
          newSelectedSites = newSelectedSites.filter((s) => s.id !== siteId);
        }
      }
    }

    setSelectedSites(newSelectedSites);
  };

  const filteredSites =
    !searchText || searchText.length === 0
      ? sites
      : fuzzysort
          .go(
            searchText,
            sites,
            fuzzysortOptionsWithKeys(DashboardSearchKeys.inventoryFilter)
          )
          .map((result) => result.obj);

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={InventoryFilterTrigger}
        selectedCount={selectedSites.length}
      />

      <Dropdown.Menu className="dashboard-filter__inventory-filter__dropdown__menu">
        <div className="dashboard-filter__inventory-filter__search-box__container">
          <FilterSearchBox
            placeholder="Search site/facility"
            value={searchText}
            onInput={setSearchText}
          />
        </div>

        <div className="dashboard-filter__inventory-filter__action-button__container">
          <GVDSTextButton
            onClick={() =>
              setSelectedSites(
                sites.map((site) => ({
                  id: site.id,
                  name: site.name,
                  facilities: [...site.facilities],
                }))
              )
            }
          >
            Select all
          </GVDSTextButton>
          <GVDSTextButton onClick={() => setSelectedSites([])}>
            Clear All
          </GVDSTextButton>
        </div>

        <div className="dashboard-filter__inventory-filter__options__container">
          {filteredSites.map((site) => {
            const siteHasMultipleFacilities = site.facilities.length > 1;
            return (
              <div key={site.id}>
                <div className="dashboard-filter__inventory-filter__option__parent-option">
                  <div
                    className="dashboard-filter__inventory-filter__option-checkbox__container"
                    onClick={() => handleSiteSelect(site.id)}
                  >
                    <GVDSFormCheckboxWithIndeterminate
                      value={getSiteCheckboxState(site, selectedSites)}
                      label={site.name}
                      readOnly
                    />
                  </div>
                  {siteHasMultipleFacilities && (
                    <Button
                      variant="link"
                      className="option-collapse-toggle"
                      onClick={() => toggleSiteExpansion(site.id)}
                    >
                      <GVDSIcon
                        Icon={
                          expandedSites.includes(site.id)
                            ? IconChevronUp
                            : IconChevronDown
                        }
                      />
                    </Button>
                  )}
                </div>
                {siteHasMultipleFacilities && (
                  <Collapse in={expandedSites.includes(site.id)}>
                    <div className="dashboard-filter__inventory-filter__option__child-options">
                      {site.facilities.map((facility) => (
                        <div
                          key={facility.id}
                          className="dashboard-filter__inventory-filter__option-checkbox__container"
                          onClick={() =>
                            handleFacilitySelect(site.id, facility.id)
                          }
                        >
                          <Form.Check
                            type="checkbox"
                            label={facility.name}
                            checked={isFacilityUnderSiteSelected(
                              facility.id,
                              selectedSites,
                              site.id
                            )}
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                  </Collapse>
                )}
              </div>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default InventoryFilterView;
