import moment from "moment";
import { DateTimeUtils } from "../../services/UtilsService";
import React from "react";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import { min } from "lodash";

export const MeterStatus = {
  UP_TO_DATE: "UP TO DATE",
  ISSUES_DETECTED: "ISSUES DETECTED",
};

export default class MeterRecordsService {
  constructor() {
    this.meter = null;
    this.dataGaps = [];
    this.usageOutOfRanges = [];
    this.costOutOfRanges = [];
    this.outOfRanges = [];
  }

  updateMeter(meter, meterRecords) {}

  hasNoDataGaps(end) {
    return this.getDataGapDataIssues(end).length === 0;
  }

  hasNoOutOfRanges() {
    return true;
  }

  getMeterDetailStatus(end) {
    if (this.hasNoDataGaps(end) && this.hasNoOutOfRanges()) {
      return MeterStatus.UP_TO_DATE;
    } else {
      return MeterStatus.ISSUES_DETECTED;
    }
  }

  getMeterOverviewStatus(end) {
    if (this.hasNoDataGaps(end) && this.hasNoOutOfRanges()) {
      return MeterStatus.UP_TO_DATE;
    } else {
      return MeterStatus.ISSUES_DETECTED;
    }
  }

  static getStatusLabel(t, hasMissingDataRequest, status) {
    if (hasMissingDataRequest) {
      return (
        <StatusLabel color={StatusLabel.Colors.red}>
          {t("data-management.shared.label-data-requested")}
        </StatusLabel>
      );
    }
    switch (status) {
      case MeterStatus.ISSUES_DETECTED:
        return (
          <StatusLabel color={StatusLabel.Colors.yellow}>
            {t("data-management.shared.label-possible-issues")}
          </StatusLabel>
        );
      default:
        return;
    }
  }

  isDataUnupdatedNotDataGap(latestGapEnd, filterEnd) {
    const isGapUntilEndOfFilter =
      DateTimeUtils.getUTCISOString(latestGapEnd) ===
      DateTimeUtils.getUTCISOString(filterEnd);
    const isFilterInThePast = moment().startOf("day").isAfter(filterEnd);

    return isGapUntilEndOfFilter && !isFilterInThePast;
  }

  getUnupdatedDataIssues(end) {
    if (!this.dataGaps || this.dataGaps.length === 0) return null;

    const gaps = [...this.dataGaps];
    const latestGapStart = moment.utc(gaps[gaps.length - 1]["start"]);
    const latestGapEnd = moment.utc(gaps[gaps.length - 1]["end"]);

    let unupdatedMessages;
    if (
      !this.isWithinAllowedMissingRange(latestGapStart) &&
      this.isDataUnupdatedNotDataGap(latestGapEnd, end)
    ) {
      unupdatedMessages = `Data not updated since ${DateTimeUtils.formatUTCDate(
        latestGapStart
      )} 
        (${moment.duration(moment() - latestGapStart).humanize()} ago).`;
    }

    return unupdatedMessages;
  }

  getDataGapDataIssues(end) {
    if (!this.dataGaps || this.dataGaps.length === 0) return [];

    const gaps = [...this.dataGaps];
    const gapMessages = [];
    if (this.meter.enable_data_gaps_alert) {
      gapMessages.push(
        ...gaps
          .filter(
            (g) => !this.isWithinAllowedMissingRange(moment.utc(g["start"]))
          )
          .map(
            (g) =>
              `Data gap: No records found from ${DateTimeUtils.formatUTCDate(
                g["start"]
              )} - ${DateTimeUtils.formatUTCDate(
                min([
                  new Date(g["end"]),
                  this.getMaxMissingRangeEndDate().toDate(),
                ])
              )}`
          )
      );
    }

    return gapMessages;
  }

  getOutOfRangeDataIssues() {
    return [];
  }

  getDataIssues(end) {
    const dataIssues = [];
    dataIssues.push(...this.getDataGapDataIssues(end));
    dataIssues.push(...this.getOutOfRangeDataIssues());

    return dataIssues;
  }

  getMaxMissingRangeEndDate() {
    return moment().subtract(1, "months");
  }

  isWithinAllowedMissingRange(latestGapStart) {
    return latestGapStart.isAfter(this.getMaxMissingRangeEndDate());
  }

  getLastRecordDateDisplay(t, lastRecordDate) {
    const lastRecordDateDisplay = lastRecordDate
      ? `${t(
          "data-management.shared.label-last-record-date"
        )} ${DateTimeUtils.formatLocalDate(lastRecordDate)} (${moment
          .duration(moment() - moment(lastRecordDate))
          .humanize()} ago).`
      : t("data-management.shared.label-no-last-record-date");

    return lastRecordDateDisplay;
  }

  static getLastRecordDateTableDisplay(lastRecordDate) {
    return lastRecordDate ? (
      <div>{DateTimeUtils.formatLocalDate(lastRecordDate)}</div>
    ) : (
      "No last record date."
    );
  }
}
