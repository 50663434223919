import React from "react";
import { Route, Switch } from "react-router-dom";

import withAuthentication from "../HOC/withAuthentication";
import {
  REPORT_BEST_PRACTICE_REPORT,
  REPORT_HCMI_METRIC,
  REPORT_INITIATIVES_REPORT,
  REPORT_PERFORMANCE_REPORT,
  REPORT_RISK_ASSESSMENT_REPORT,
  REPORT_STANDARD_REPORT,
} from "../../config/ROUTES_NAME";
import HCMIMetric from "./HCMIMetric/HCMIMetric";
import StandardReport from "./StandardReport/StandardReport";
import PerformanceReport from "./PerformanceReport/PerformanceReport";
import InitiativesReport from "./InititativesReport/InitiativesReport";
import BestPracticesReport from "./BestPracticesReport/BestPracticesReport";
import RiskAssessmentReport from "./RiskAssessmentReport/RiskAssessmentReport";
import { PageWrapper } from "../../gvds-components/Nav/PageWrapper/PageWrapper";

const Report = () => {
  return (
    <Switch>
      <Route
        exact
        path={REPORT_HCMI_METRIC}
        render={() => (
          <PageWrapper configRouteName={REPORT_HCMI_METRIC}>
            <HCMIMetric />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={REPORT_STANDARD_REPORT}
        render={() => (
          <PageWrapper configRouteName={REPORT_STANDARD_REPORT}>
            <StandardReport />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={REPORT_PERFORMANCE_REPORT}
        render={() => (
          <PageWrapper configRouteName={REPORT_PERFORMANCE_REPORT}>
            <PerformanceReport />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={REPORT_INITIATIVES_REPORT}
        render={() => (
          <PageWrapper configRouteName={REPORT_INITIATIVES_REPORT}>
            <InitiativesReport />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={REPORT_BEST_PRACTICE_REPORT}
        render={() => (
          <PageWrapper configRouteName={REPORT_BEST_PRACTICE_REPORT}>
            <BestPracticesReport />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={REPORT_RISK_ASSESSMENT_REPORT}
        render={() => (
          <PageWrapper configRouteName={REPORT_RISK_ASSESSMENT_REPORT}>
            <RiskAssessmentReport />
          </PageWrapper>
        )}
      />
    </Switch>
  );
};

export default withAuthentication(Report);
