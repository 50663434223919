import axios from "axios";
import { API_URL } from "../config/api-config";
import SiteSubscriptionModel from "../components/SystemToolbox/Subscription/SiteSubscription/SiteSubscriptionModel";
import ContractSubscriptionModel from "../components/SystemToolbox/Subscription/ContractSubscription/ContractSubscriptionModel";
import BillingSubscriptionModel from "../components/SystemToolbox/Subscription/BillingSubscriptionModel";

export default class SubscriptionService {
  static async getSiteSubscriptions(siteId) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/sites/${siteId}/subscriptions`
      );
      return response.data.map((s) => {
        return SiteSubscriptionModel.fromDTO(s);
      });
    } catch (error) {
      throw error.response;
    }
  }

  static async addSiteSubscription(siteId, payload) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/sites/${siteId}/subscriptions`,
        payload
      );
      return SiteSubscriptionModel.fromDTO(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async updateSiteSubscription(siteId, subscriptionId, payload) {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/sites/${siteId}/subscriptions/${subscriptionId}`,
        payload
      );
      return SiteSubscriptionModel.fromDTO(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async deleteSiteSubscription(siteId, subscriptionId) {
    try {
      return await axios.delete(
        `${API_URL}/api/v1/sites/${siteId}/subscriptions/${subscriptionId}`
      );
    } catch (error) {
      throw error.response;
    }
  }

  static async getSubscriptionOptions(resource_type, resource_id) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/subscription_options`,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async validateBulkSelectSites(siteNames) {
    try {
      const response = await axios.post(
        API_URL + `/api/v1/contracts/subscriptions/validate`,
        siteNames
      );
      return response.data["data_errors"];
    } catch (error) {
      return {};
    }
  }

  static async getContractSubscriptions(contractId) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/contracts/${contractId}/subscriptions`
      );
      return response.data.map((s) => {
        return ContractSubscriptionModel.fromDTO(s);
      });
    } catch (error) {
      throw error;
    }
  }

  static async addContractSubscription(contractId, payload) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/contracts/${contractId}/subscriptions`,
        payload
      );
      return ContractSubscriptionModel.fromDTO(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async getContractSubscription(contractId, subscriptionId) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/contracts/${contractId}/subscriptions/${subscriptionId}`
      );
      return ContractSubscriptionModel.fromDTO(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async updateContractSubscription(contractId, subscriptionId, payload) {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/contracts/${contractId}/subscriptions/${subscriptionId}`,
        payload
      );
      return ContractSubscriptionModel.fromDTO(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async deleteContractSubscription(contractId, subscriptionId) {
    try {
      return await axios.delete(
        `${API_URL}/api/v1/contracts/${contractId}/subscriptions/${subscriptionId}`
      );
    } catch (error) {
      throw error.response;
    }
  }

  static async getAllSubscriptions() {
    try {
      const response = await axios.get(`${API_URL}/api/v1/admin/subscriptions`);
      return response.data.map((s) => {
        return BillingSubscriptionModel.fromDTO(s);
      });
    } catch (error) {
      throw error.response;
    }
  }
}
