import React, { useContext, useEffect, useRef, useState } from "react";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";

import { DateTimeUtils } from "../../services/UtilsService";
import ToastContext from "../../context/ToastContext";
import InlineSpinner from "../common/InlineSpinner";
import { downloadFromPresignedS3Url } from "../common/FileAttachments";
import UserInventoryContext from "../../context/UserInventoryContext";
import FileStorageService from "../../services/FileStorageService";
import LoadingSpinner from "../common/LoadingSpinner";
import PermissionsContext from "../../context/PermissionsContext";
import {
  FILE_ASSOCIATED_RESOURCE_PATH_NAME,
  PERMISSIONS,
  RESOURCES,
} from "../../config/constants";
import { matchPath, useLocation } from "react-router-dom";
import FileStorageStateContext from "../../context/FileStorageStateContext";
import OtherActionDropdownToggle from "../common/OtherActionDropdownToggle";
import Dropdown from "react-bootstrap/Dropdown";
import { UserManagedTagDisplay } from "../common/CentralTags/UserManagedTagDisplay";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSTag from "../../gvds-components/common/GVDSTag";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import { IconEdit, IconNotebook, IconTrash } from "@tabler/icons-react";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { Trans, useTranslation } from "react-i18next";

const MAX_MODULE_TAG_COUNT_CUTOFF = 3;
const FILE_STORAGE_DETAILS_SEPARATOR = (
  <span className="detail-separator">•</span>
);

const FileStorageItemDisplay = ({
  fileStorageObject,
  onEdit,
  onActionToFileStorageObject,
  showSelect,
  isSelected,
  onSelect,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const permissionCtx = useContext(PermissionsContext);
  const fileStorageStateContext = useContext(FileStorageStateContext);
  const location = useLocation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const selectedInventory = userInventory.selectedInventory.get;

  const triggerDeletePrompt = () => {
    setShowDeletePrompt(true);
  };

  const onDeletePromptClose = () => {
    if (!isDeleting) {
      setShowDeletePrompt(false);
    }
  };

  const deleteFileStorageObject = async () => {
    setIsDeleting(true);
    const associatedResourceObjectType =
      fileStorageObject.hasAssociatedResources
        ? await fileStorageStateContext.getAssociatedResources(
            selectedInventory.type,
            selectedInventory.id,
            fileStorageObject.id
          )
        : null;

    FileStorageService.deleteFileStorageObjects(
      selectedInventory.type,
      selectedInventory.id,
      [fileStorageObject.id]
    )
      .then(() => {
        toastContext.addSuccessToast(<span>File deleted successfully.</span>);
        setIsDeleting(false);
        setShowDeletePrompt(false);
        if (associatedResourceObjectType) {
          const associatedResourcePathName =
            FILE_ASSOCIATED_RESOURCE_PATH_NAME[associatedResourceObjectType];
          if (
            matchPath(location.pathname, {
              path: associatedResourcePathName,
              exact: true,
            })
          ) {
            fileStorageStateContext.reloadAssociatedResourceRelatedComponentFlag(
              associatedResourceObjectType
            );
          }
        }
        onActionToFileStorageObject();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to delete file.</span>);
        setIsDeleting(false);
      });
  };

  const fileStorageObjectName = fileStorageObject.name;

  return (
    <>
      <div className="file-storage-item-container">
        {showSelect && (
          <div className="file-storage-item-selector">
            <Form.Check
              checked={isSelected}
              type="checkbox"
              key={fileStorageObject.id}
              id={fileStorageObject.id}
              label=""
              onChange={onSelect}
            />
          </div>
        )}
        <div className="file-storage-item-display">
          <div className="file-storage-item-body">
            <div className="file-storage-item-content">
              <FileStorageObjectLinkDisplay
                fileStorageObject={fileStorageObject}
              />
              <FileStorageObjectModuleTagDisplay
                fileStorageObject={fileStorageObject}
              />
            </div>
            {!showSelect && (
              <Dropdown>
                <Dropdown.Toggle as={OtherActionDropdownToggle} />
                <Dropdown.Menu align="end">
                  {!permissionCtx.isLoadingPermissions &&
                    permissionCtx.permissions[
                      PERMISSIONS.FILE_STORAGE_EDIT
                    ] && (
                      <Dropdown.Item onClick={() => onEdit(fileStorageObject)}>
                        <GVDSIcon Icon={IconEdit} />
                        {t("shared.edit")}
                      </Dropdown.Item>
                    )}
                  {!permissionCtx.isLoadingPermissions &&
                    permissionCtx.permissions[
                      PERMISSIONS.FILE_STORAGE_DELETE
                    ] && (
                      <Dropdown.Item
                        className="danger"
                        onClick={triggerDeletePrompt}
                      >
                        <GVDSIcon Icon={IconTrash} />
                        {t("shared.delete")}
                      </Dropdown.Item>
                    )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div className="file-storage-item-footer">
            <div className="file-storage-item-upload-info">
              {selectedInventory.type === RESOURCES.PORTFOLIO && (
                <span className="file-storage-item-site-name">
                  {fileStorageObject.site.name} {FILE_STORAGE_DETAILS_SEPARATOR}
                </span>
              )}
              {t("file-storage.label-updated-by")}{" "}
              {fileStorageObject.uploadedBy
                ? fileStorageObject.uploadedBy.full_name
                : "-"}
              {fileStorageObject.uploadedOn
                ? ` (${DateTimeUtils.formatUTCDate(
                    fileStorageObject.uploadedOn
                  )})`
                : null}
            </div>
            {fileStorageObject.description && (
              <FileStorageObjectDescriptionDisplay
                fileStorageObject={fileStorageObject}
              />
            )}
          </div>
        </div>
      </div>
      <GVDSModal
        show={showDeletePrompt}
        onHide={onDeletePromptClose}
        size={GVDSModal.Size.small}
        title={
          <Trans i18nKey="file-storage.modal-title-delete-single-file">
            Delete {{ fileStorageObjectName }}.
          </Trans>
        }
      >
        {!isDeleting ? (
          <>
            <GVDSModal.Body>
              <div>{t("file-storage.delete-warning-single-file")}</div>
            </GVDSModal.Body>
            <GVDSModal.Footer>
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={onDeletePromptClose}
                text={t("shared-modal.footer.cancel")}
              />
              <GVDSButton
                variant={buttonVariant.destructive_primary}
                onClick={deleteFileStorageObject}
                text={t("shared-modal.footer.yes-delete")}
              />
            </GVDSModal.Footer>
          </>
        ) : (
          <GVDSModal.Body>
            <div className="mt-4">
              <LoadingSpinner />
            </div>
          </GVDSModal.Body>
        )}
      </GVDSModal>
    </>
  );
};

export const FileStorageObjectLinkDisplay = ({ fileStorageObject }) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = async () => {
    setIsLoading(true);
    try {
      const selectedInventory = userInventory.selectedInventory.get;
      const params = {
        resource_type: selectedInventory.type,
        resource_id: selectedInventory.id,
      };
      await downloadFromPresignedS3Url(fileStorageObject.link, params);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to download file</span>);
    }
  };

  if (!fileStorageObject) {
    return null;
  }

  return (
    <Button
      variant="link"
      size="sm"
      className="file-storage-item-name"
      onClick={downloadFile}
      disabled={isLoading}
    >
      {isLoading && <InlineSpinner />}
      {!isLoading && (
        <>
          {fileStorageObject.name}
          <FileStorageObjectAssociatedResourcesDisplay
            fileStorageObject={fileStorageObject}
          />
        </>
      )}
    </Button>
  );
};

export const FileStorageObjectAssociatedResourcesDisplay = ({
  fileStorageObject,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);

  const [isLoading, setIsLoading] = useState(true);
  const [associatedResourceModels, setAssociatedResourceModels] = useState([]);
  const [isErrorLoading, setIsErrorLoading] = useState(false);

  useEffect(() => {
    if (fileStorageObject.hasAssociatedResources) {
      const loadAssociatedResources = async () => {
        try {
          const selectedInventory = userInventory.selectedInventory.get;

          const associatedResourceModels =
            await FileStorageService.getFileStorageObjectAssociatedResources(
              selectedInventory.type,
              selectedInventory.id,
              fileStorageObject.id
            );
          setAssociatedResourceModels(associatedResourceModels);
          setIsLoading(false);
        } catch (e) {
          setIsErrorLoading(true);
          setIsLoading(false);
        }
      };

      loadAssociatedResources();
    }
  }, []);

  if (!fileStorageObject.hasAssociatedResources) {
    return null;
  } else {
    let content;

    if (isLoading) {
      content = <LoadingSpinner />;
    } else if (!isErrorLoading) {
      content = associatedResourceModels.map((m, index) => {
        const associatedResourceObjectName = m.associatedResourceObjectName;
        const associatedResourceObjectType = m.associatedResourceObjectType;

        return (
          <div key={index}>
            <Trans i18nKey="file-storage.linked-associated-resource">
              Linked to <em>{{ associatedResourceObjectName }}</em> in{" "}
              <em>{{ associatedResourceObjectType }}</em>
            </Trans>
          </div>
        );
      });
    } else {
      content = (
        <div>{t("file-storage.linked-associated-resource-error-loading")}</div>
      );
    }

    return (
      <div className="file-storage-link-icon">
        <OverlayTrigger
          placement="top"
          overlay={
            <Popover>
              <Popover.Body>{content}</Popover.Body>
            </Popover>
          }
        >
          <div>
            <GVDSIcon Icon={IconNotebook} />
          </div>
        </OverlayTrigger>
      </div>
    );
  }
};

const FileStorageObjectDescriptionDisplay = ({ fileStorageObject }) => {
  const descriptionElRef = useRef();

  const [isTruncated, setIsTruncated] = useState(false);

  const compareSize = () => {
    const isWidthScrollableWithoutTruncation =
      descriptionElRef.current.scrollWidth >
      descriptionElRef.current.clientWidth;
    setIsTruncated(isWidthScrollableWithoutTruncation);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  return (
    <OverlayTrigger
      placement="top-start"
      overlay={
        isTruncated ? (
          <Popover>
            <Popover.Body>{fileStorageObject.description}</Popover.Body>
          </Popover>
        ) : (
          <div style={{ display: "none" }} />
        )
      }
    >
      <div className="file-storage-item-description" ref={descriptionElRef}>
        {FILE_STORAGE_DETAILS_SEPARATOR} {fileStorageObject.description}
      </div>
    </OverlayTrigger>
  );
};

const FileStorageObjectModuleTagDisplay = ({ fileStorageObject }) => {
  const { t } = useTranslation();

  const moduleDisplays = fileStorageObject.associatedModules.map((m) => (
    <GVDSTag key={m.id} variant={GVDSTag.Variants.system}>
      {m.name}
    </GVDSTag>
  ));

  const tagDisplays = fileStorageObject.allTags.map((t) => (
    <UserManagedTagDisplay
      key={t.id}
      tagText={t.name}
      resourceType={t.resourceType}
    />
  ));

  const modulesAndTags = [...moduleDisplays, ...tagDisplays];

  if (modulesAndTags.length <= 3) {
    return <div>{modulesAndTags}</div>;
  } else {
    const firstFew = modulesAndTags.slice(0, MAX_MODULE_TAG_COUNT_CUTOFF);
    const others = modulesAndTags.slice(MAX_MODULE_TAG_COUNT_CUTOFF);

    const othersLength = others.length;

    return (
      <div>
        {firstFew}
        <OverlayTrigger
          overlay={
            <Popover>
              <Popover.Body>{others}</Popover.Body>
            </Popover>
          }
        >
          <div className="d-inline-block ms-2">
            <Trans i18nKey="file-storage.more-tags-label">
              + {{ othersLength }} more
            </Trans>
          </div>
        </OverlayTrigger>
      </div>
    );
  }
};

export default FileStorageItemDisplay;
