import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _, { uniq } from "lodash";

import withAuthentication from "../HOC/withAuthentication";
import HelpArticleService from "../../services/HelpArticleService";
import LoadingSpinner from "../common/LoadingSpinner";
import { HELP_ARTICLE_CATEGORY, LANGUAGE_CODES } from "../../config/constants";
import { getViewHelpArticlePath } from "../../config/ROUTES_NAME";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SupportContactLink from "../common/SupportContactLink";
import { availableLanguages } from "./HelpArticleLanguageData";
import fuzzysort from "fuzzysort";
import ToastContext from "../../context/ToastContext";
import PortalModuleLinkService from "../../services/ReferenceDataServices/PortalModuleLinkService";
import {
  fuzzysortOptionsWithKeys,
  helpArticleSearchKeys,
} from "../../config/search-config";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import FilterSearchBox from "../../gvds-components/common/FilterSearchBox";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import {
  IconChevronDown,
  IconHeadset,
  IconHelpCircleFilled,
  IconSearch,
} from "@tabler/icons-react";
import GVDSIconSlim from "../../gvds-components/Icons/GVDSIconSlim";
import GVDSLink from "../../gvds-components/Buttons/GVDSLink";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import { ContentTypeDisplay } from "../common/ContentTypeDisplay";
import { useTranslation } from "react-i18next";
import { HelpAdditionalSupportBlurb } from "./HelpSharedComponents";
import { LanguageContext } from "../../context/UserProfileContext";
import { StringUtils } from "../../services/UtilsService";

const HELP_ARTICLE_MINIMUM_VISIBLE_ARTICLES = 6;

const LanguageDivider = () => {
  return <div className="help-center__language-divider">·</div>;
};

const groupArticlesByCategory = (filteredArticles) => {
  const groupedArticles = filteredArticles.reduce((map, article) => {
    map[article.category.name] = map[article.category.name] || {
      articles: [],
    };
    map[article.category.name].articles.push(article);
    return map;
  }, {});

  return groupedArticles;
};

const ViewAllHelpArticles = () => {
  const { t } = useTranslation();

  const languageContext = useContext(LanguageContext);
  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [filterKeys, setFilterKeys] = useState({});
  const englishLanguagePrefix = availableLanguages[0];
  const [selectedLanguagePrefix, setSelectedLanguagePrefix] = useState(
    englishLanguagePrefix.prefix
  );
  const [allArticles, setAllArticles] = useState([]);
  const [
    filteredArticlesGroupedByCategory,
    setFilteredArticlesGroupedByCategory,
  ] = useState([]);
  const [relevantPortalModulesOptions, setRelevantPortalModulesOptions] =
    useState(null);

  const userProfileLangCode = languageContext.getLangCode();

  const loadAllArticles = async () => {
    setIsLoading(true);
    try {
      const allHelpArticles = await HelpArticleService.getAllHelpArticles();
      setAllArticles(allHelpArticles);

      const availableRelevantPortalModulesOptions = _.uniq(
        _.chain(allHelpArticles)
          .flatMap((d) => d.relevantPortalModules)
          .sort(PortalModuleLinkService.PortalModuleLinkSortFn)
          .map((module) => module.name)
          .value()
      );
      setRelevantPortalModulesOptions(availableRelevantPortalModulesOptions);
    } catch (error) {
      toastContext.addFailToast(<span>Failed to load Help Guides</span>);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAllArticles().then(() => {
      if (
        StringUtils.isNotEmpty(userProfileLangCode) &&
        userProfileLangCode !== LANGUAGE_CODES.english
      ) {
        setSelectedLanguagePrefix(userProfileLangCode.toUpperCase());
      }
    });
  }, []);

  useEffect(() => {
    const filteredArticlesByKeyword =
      searchText.length > 0
        ? fuzzysort
            .go(
              searchText,
              allArticles,
              fuzzysortOptionsWithKeys(helpArticleSearchKeys)
            )
            .map((result) => result.obj)
        : allArticles;

    const filtered = filteredArticlesByKeyword.filter((a) => {
      let matchFilter = true;

      if (
        filterKeys.article_type_name &&
        filterKeys.article_type_name.length > 0
      ) {
        matchFilter =
          matchFilter &&
          filterKeys.article_type_name.indexOf(a.article_type_name) >= 0;
      }

      if (
        filterKeys.relevant_portal_modules &&
        filterKeys.relevant_portal_modules.length > 0
      ) {
        matchFilter =
          matchFilter &&
          a.relevantPortalModules
            .map((module) => module.name)
            .some(
              (module) =>
                filterKeys.relevant_portal_modules.indexOf(module) >= 0
            );
      }

      if (selectedLanguagePrefix === "") {
        matchFilter = matchFilter && a.isEnglishArticle();
      } else {
        matchFilter =
          matchFilter && a.title.startsWith(`[${selectedLanguagePrefix}]`);
      }

      return matchFilter;
    });

    const groupedArticles = groupArticlesByCategory(filtered);
    setFilteredArticlesGroupedByCategory(groupedArticles);
  }, [isLoading, searchText, filterKeys, selectedLanguagePrefix, allArticles]);

  const sortedArticleCategories = Object.keys(
    filteredArticlesGroupedByCategory
  ).sort(HelpArticleService.HelpArticleCategorySortFn);

  let content;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <>
        <div className="d-flex flex-row mb-2">
          <div className="help-center-subtitle-icon">
            <GVDSIcon Icon={IconHeadset} className="color-black" />
          </div>
          <div>
            <HelpAdditionalSupportBlurb />
          </div>
        </div>
        <GVDSTableCtrlContainer>
          <FilterSearchBox
            className="article-search-box"
            placeholder="Search for a guide"
            value={searchText}
            onInput={setSearchText}
          />
          {relevantPortalModulesOptions && (
            <GVDSTableCtrlMultiSelect
              options={relevantPortalModulesOptions}
              prefix="Relevant Portal Modules"
              onChange={(filterKs) =>
                setFilterKeys({
                  ...filterKeys,
                  relevant_portal_modules: filterKs,
                })
              }
            />
          )}
          <GVDSTableCtrlMultiSelect
            options={uniq(allArticles.map((q) => q.article_type_name))}
            prefix="Format"
            onChange={(filterKs) =>
              setFilterKeys({ ...filterKeys, article_type_name: filterKs })
            }
          />
        </GVDSTableCtrlContainer>
        <div className="help-center__language-selector">
          <div className="me-2">{t("help-center.label-available-in")}:</div>
          {availableLanguages.map((language, index) => {
            return (
              <React.Fragment key={index}>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => setSelectedLanguagePrefix(language.prefix)}
                >
                  {language.name}
                </Button>
                {index !== availableLanguages.length - 1 && <LanguageDivider />}
              </React.Fragment>
            );
          })}
        </div>
        {selectedLanguagePrefix !== englishLanguagePrefix.prefix && (
          <div className="help-center__articles-in-other-languages-info">
            {t("help-center.instruction-check-english-for-more")}
          </div>
        )}
        <div className="help-center__v-spacing-before-content" />
        {_.isEmpty(filteredArticlesGroupedByCategory) && (
          <div className="help-article__no-content">
            <GVDSIcon Icon={IconSearch} className="gvds-empty-content--icon" />
            <div className="gvds-empty-content--title">
              {t("shared.no-results-found")}
            </div>
            <div>{t("help-center.list.no-content")}</div>
            <SupportContactLink />
          </div>
        )}
        <HelpArticleDisplayByCategories
          selectedLanguagePrefix={selectedLanguagePrefix}
          sortedArticleCategories={sortedArticleCategories}
          filteredArticlesGroupedByCategory={filteredArticlesGroupedByCategory}
          shouldExpandAllArticles={searchText.length > 0}
        />
      </>
    );
  }

  return (
    <div className="help-center__container">
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("help-center.page-title")}</h1>
        </PageHeader.Title>
      </PageHeader>
      {content}
    </div>
  );
};

const TranslatedCategory = ({ tSelectedLangOption, category }) => {
  switch (category) {
    case HELP_ARTICLE_CATEGORY.GETTING_STARTED_WITH_THE_GREENVIEW_PORTAL:
      return tSelectedLangOption("help-center.category.getting-started");
    case HELP_ARTICLE_CATEGORY.SETTING_UP_AND_MANAGING_YOUR_SITE:
      return tSelectedLangOption("help-center.category.setting-up-site");
    case HELP_ARTICLE_CATEGORY.INPUTTING_YOUR_DATA:
      return tSelectedLangOption("help-center.category.inputting-data");
    case HELP_ARTICLE_CATEGORY.RUNNING_AND_INTERPRETING_REPORTS:
      return tSelectedLangOption("help-center.category.running-reports");
    case HELP_ARTICLE_CATEGORY.IMPLEMENTING_BEST_PRACTICES:
      return tSelectedLangOption(
        "help-center.category.implementing-best-practices"
      );
    case HELP_ARTICLE_CATEGORY.MANAGING_YOUR_TASKS_AND_FILES:
      return tSelectedLangOption(
        "help-center.category.managing-tasks-and-files"
      );
    case HELP_ARTICLE_CATEGORY.SETTING_GOALS_AND_MONITORING_YOUR_PROGRESS:
      return tSelectedLangOption("help-center.category.setting-goals");
    case HELP_ARTICLE_CATEGORY.ALIGNING_WITH_CERTIFICATIONS:
      return tSelectedLangOption(
        "help-center.category.aligning-with-certifications"
      );
    case HELP_ARTICLE_CATEGORY.SURVEYS:
      return tSelectedLangOption("help-center.category.surveys");
    case HELP_ARTICLE_CATEGORY.HELPFUL_TIPS_PAST_ISSUES:
      return tSelectedLangOption("help-center.category.helpful-tips");
  }
};

const HelpArticleCategoryGroupDisplay = ({
  tSelectedLangOption,
  category,
  filteredArticlesForCategory,
  shouldExpandAllArticles,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);

  let filteredArticles = filteredArticlesForCategory;
  if (category === HELP_ARTICLE_CATEGORY.HELPFUL_TIPS_PAST_ISSUES) {
    filteredArticles = filteredArticles.sort(
      HelpArticleService.HelpfulTipsArticleDateSortFn
    );
  }

  const articlesToDisplay =
    isExpanded || shouldExpandAllArticles
      ? filteredArticles
      : filteredArticles.slice(0, HELP_ARTICLE_MINIMUM_VISIBLE_ARTICLES);

  const content = (
    <>
      {articlesToDisplay.map((article) => (
        <HelpArticleListItemDisplay
          key={article.id}
          history={history}
          category={category}
          article={article}
        />
      ))}
      {filteredArticlesForCategory.length >
        HELP_ARTICLE_MINIMUM_VISIBLE_ARTICLES &&
        !(isExpanded || shouldExpandAllArticles) && (
          <Button
            variant="link"
            size="sm"
            className={`${category
              .replaceAll(" ", "-")
              .toLowerCase()}-show-more-btn help-center-article-show-more-btn`}
            onClick={() => setIsExpanded(true)}
          >
            {t("help-center.label-show-more")}
            <GVDSIconSlim Icon={IconChevronDown} />
          </Button>
        )}
    </>
  );

  return (
    <div className="mb-4">
      <h3 className="help-center-category-name">
        <TranslatedCategory
          tSelectedLangOption={tSelectedLangOption}
          category={category}
        />
      </h3>
      {content}
    </div>
  );
};

const HelpArticleDisplayByCategories = ({
  selectedLanguagePrefix,
  sortedArticleCategories,
  filteredArticlesGroupedByCategory,
  shouldExpandArticles,
}) => {
  const { ready: readySelectedLangOption, t: tSelectedLangOption } =
    useTranslation("translation", {
      lng:
        selectedLanguagePrefix !== null && selectedLanguagePrefix !== ""
          ? selectedLanguagePrefix.toLowerCase()
          : "en",
      useSuspense: false,
    });

  if (!readySelectedLangOption) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return sortedArticleCategories.map((category, index) => {
    return (
      <HelpArticleCategoryGroupDisplay
        key={index}
        tSelectedLangOption={tSelectedLangOption}
        category={category}
        filteredArticlesForCategory={
          filteredArticlesGroupedByCategory[category].articles
        }
        shouldExpandAllArticles={shouldExpandArticles}
      />
    );
  });
};

const HelpArticleListItemDisplay = ({ category, article }) => {
  const location = useLocation();
  const history = useHistory();

  const getDisplayTitle = (articleTitle) => {
    return articleTitle.replace(/^\[.{2}\] - /i, "");
  };

  return (
    <div
      className={`${category
        .replaceAll(" ", "-")
        .toLowerCase()}-article help-center-article`}
    >
      <GVDSIcon
        Icon={IconHelpCircleFilled}
        className="help-center-article-icon"
      />
      <OverlayTrigger
        placement="top"
        overlay={
          <Popover>
            <Popover.Body>{article.description}</Popover.Body>
          </Popover>
        }
      >
        <div className="text-vertical-center">
          <GVDSLink
            className="help-center-article-link"
            variant={GVDSLink.variant.media_title}
            to={getRedirectURLWithCurrentParam(
              getViewHelpArticlePath(article.id),
              location
            )}
            target="_blank"
            rel="noreferrer"
          >
            {getDisplayTitle(article.title)}
          </GVDSLink>
          <ContentTypeDisplay contentTypeName={article.articleType.name} />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default withAuthentication(ViewAllHelpArticles);
