import React, { useContext, useEffect, useRef, useState } from "react";
import withAuthentication from "../../HOC/withAuthentication";
import PerformanceReportService from "../../../services/Report/PerformanceReportService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import PerformanceReportConfig from "./PerformanceReportConfig";
import ToastContext from "../../../context/ToastContext";
import PerformanceReportTable from "./PerformanceReportTable";
import {
  DATA_STATUS,
  REPORT_TYPE,
  RESOURCES,
  smoothScrollBehaviour,
} from "../../../config/constants";
import PerformanceReportChart from "./Charts/PerformanceReportChart";
import { type_hotel } from "../../Site/SiteFacilityInformationComponents";
import PerformanceReportParamsModel, {
  PerformanceReportStatus,
} from "./PerformanceReportConfigModel";
import _ from "lodash";
import CreateDataRequestModal from "../../Data/Overview/DataRequest/CreateDataRequestModal";
import {
  extractPageParamsObjectFromURL,
  getRedirectURLWithCurrentParam,
} from "../../common/QueryHandler";
import { OVERVIEW_DATA } from "../../../config/ROUTES_NAME";
import ReportLegend from "../ReportLegend";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import { useTranslation } from "react-i18next";

const initialReportParams = {
  subtopic_name: "",
  environmental_type_id: "",
  use_performance_group: true,
  use_location_based: null,
  performance_group_id: "",
  unit_id: "",
  currency_id: "",
  years: [],
  months: _.range(1, 13),
  operational_metric: "",
  operational_unit_id: "",
};

const PerformanceReport = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingReport, setLoadingReport] = useState(false);
  const [missingDataTypes, setMissingDataTypes] = useState([]);
  const [showDataRequest, setShowDataRequest] = useState(false);
  const [reportRanFromDataRequest, setReportRanFromDataRequest] =
    useState(false);

  const [reportConfig, setReportConfig] = useState({});
  const [reportParams, setReportParams] = useState(initialReportParams);

  const [performanceReport, setPerformanceReport] = useState(null);
  const [benchmarkAvailable, setBenchmarkAvailable] = useState(false);
  const [performanceBenchmark, setPerformanceBenchmark] = useState(null);
  const [inventoryId, setInventoryId] = useState("");

  const reportResultScrollTargetRef = useRef();

  useEffect(() => {
    setPerformanceReport(null);
    setMissingDataTypes([]);
    if (
      userInventory.selectedInventory.get &&
      userInventory.selectedInventory.get.id &&
      userInventory.selectedInventory.get.id !== inventoryId
    ) {
      setLoadingConfig(true);
      setInventoryId(userInventory.selectedInventory.get.id);
      PerformanceReportService.getReportConfig(
        userInventory.selectedInventory.get.type,
        userInventory.selectedInventory.get.id
      )
        .then((config) => {
          setLoadingConfig(false);
          setReportConfig(config);
          applyReportParamsFromSearchQuery(location, config);
          setBenchmarkAvailable(
            getIsBenchmarkAvailable(
              userInventory.selectedInventory.get.type,
              userInventory.selectedTreeNode.get.nodeValue
            )
          );
        })
        .catch((e) => {
          setLoadingConfig(false);
          toastContext.addFailToast(
            <span>Failed to load performance report configuration.</span>
          );
        });
    }
  }, [userInventory.selectedInventory.get]);

  useEffect(() => {
    setMissingDataTypes([]);
    setPerformanceReport(null);
    if (
      benchmarkAvailable &&
      PerformanceReportParamsModel.getBenchmarkStatus(
        reportParams,
        reportConfig
      ) === PerformanceReportStatus.AVAILABLE
    ) {
      getBenchmark(reportParams).then();
    } else {
      setPerformanceBenchmark(null);
    }
  }, [reportParams, reportConfig]);

  const applyReportParamsFromSearchQuery = (location, config) => {
    const reportParamsObj = extractPageParamsObjectFromURL(location);
    if (reportParamsObj && !reportRanFromDataRequest) {
      setReportParams(reportParamsObj);
      if (!reportParamsObj.postponeDownload) {
        downloadReport(reportParamsObj, config).then(() =>
          setReportRanFromDataRequest(true)
        );
      }
    } else {
      setReportParams({
        ...initialReportParams,
        currency_id: config["default_currency_id"],
      });
    }
  };

  const getIsBenchmarkAvailable = (resourceType, nodeValue) => {
    if (resourceType !== RESOURCES.SITE) {
      return false;
    }
    return nodeValue.value.type.name.toLowerCase() === type_hotel;
  };

  const getPerformanceWasteDiversionRateParams = (params) => ({
    resource_id: userInventory.selectedInventory.get.id,
    resource_type: userInventory.selectedInventory.get.type,
    years: params["years"],
    months: params["months"],
  });

  const getPerformanceRenewablesRateParams = (params) => ({
    resource_id: userInventory.selectedInventory.get.id,
    resource_type: userInventory.selectedInventory.get.type,
    performance_group_id: params["performance_group_id"],
    years: params["years"],
    months: params["months"],
  });

  const getPerformanceParams = (params) => ({
    resource_id: userInventory.selectedInventory.get.id,
    resource_type: userInventory.selectedInventory.get.type,
    subtopic_name: params["subtopic_name"],
    environmental_type_id: params["environmental_type_id"],
    performance_group_id: params["performance_group_id"],
    unit_id: params["unit_id"],
    currency_id: params["currency_id"],
    years: params["years"],
    months: params["months"],
    operational_metric: params["operational_metric"],
    operational_unit_id: params["operational_unit_id"],
    use_location_based: params["use_location_based"],
  });

  const getBenchmarkParams = () => ({
    resource_id: userInventory.selectedInventory.get.id,
    resource_type: userInventory.selectedInventory.get.type,
    subtopic_name: reportParams["subtopic_name"],
    operational_metric: reportParams["operational_metric"],
    performance_group_id: reportParams["performance_group_id"],
    unit_id: reportParams["unit_id"],
    operational_unit_id: reportParams["operational_unit_id"],
    year: reportParams["years"][reportParams["years"].length - 1],
  });

  const downloadReport = async (params, config) => {
    setLoadingReport(true);
    setMissingDataTypes([]);
    setPerformanceReport(null);
    if (PerformanceReportParamsModel.showWasteDiversionRate(params, config)) {
      await downloadWasteDiversionRateReport(params);
    } else if (
      PerformanceReportParamsModel.showRenewableRateGroups(params, config)
    ) {
      await downloadRenewablesRateReport(params);
    } else {
      await downloadPerformanceReport(params);
    }

    setLoadingReport(false);
  };

  const downloadPerformanceReport = async (params) => {
    try {
      const reportStatus = await PerformanceReportService.getStatus(
        getPerformanceParams(params)
      );
      if (reportStatus["status"] === DATA_STATUS.MISSING) {
        setMissingDataTypes(reportStatus.errors);
      }
      await PerformanceReportService.downloadReport(
        getPerformanceParams(params)
      );
    } catch (error) {
      if (error.status === 400) {
        if (error.data?.message) {
          toastContext.addFailToast(<span>{error.data.message}</span>);
        } else {
          toastContext.addFailToast(<span>Failed to download report.</span>);
        }
      } else if (error.status === 500) {
        toastContext.addFailToast(<span>Failed to generate report.</span>);
      }
    }
  };

  const downloadWasteDiversionRateReport = async (params) => {
    try {
      const reportStatus =
        await PerformanceReportService.getWasteDiversionRateStatus(
          getPerformanceWasteDiversionRateParams(params)
        );
      if (reportStatus["status"] === DATA_STATUS.MISSING) {
        setMissingDataTypes(reportStatus.errors);
      }
      await PerformanceReportService.downloadWasteDiversionRateReport(
        getPerformanceWasteDiversionRateParams(params)
      );
    } catch (error) {
      toastContext.addFailToast(<span>Failed to generate report.</span>);
    }
  };

  const downloadRenewablesRateReport = async (params) => {
    try {
      const reportStatus =
        await PerformanceReportService.getRenewablesRateStatus(
          getPerformanceRenewablesRateParams(params)
        );
      if (reportStatus["status"] === DATA_STATUS.MISSING) {
        setMissingDataTypes(reportStatus.errors);
      }
      await PerformanceReportService.downloadRenewablesRateReport(
        getPerformanceRenewablesRateParams(params)
      );
    } catch (error) {
      toastContext.addFailToast(<span>Failed to generate report.</span>);
    }
  };

  const runReport = async (params) => {
    setLoadingReport(true);
    setPerformanceReport(null);
    setMissingDataTypes([]);
    try {
      let report;
      if (
        PerformanceReportParamsModel.showWasteDiversionRate(
          reportParams,
          reportConfig
        )
      ) {
        report = await PerformanceReportService.getWasteDiversionRateReport(
          getPerformanceWasteDiversionRateParams(params)
        );
      } else if (
        PerformanceReportParamsModel.showRenewableRateGroups(
          reportParams,
          reportConfig
        )
      ) {
        report = await PerformanceReportService.getRenewablesRateReport(
          getPerformanceRenewablesRateParams(params)
        );
      } else {
        report = await PerformanceReportService.getReport(
          getPerformanceParams(params)
        );
      }

      setPerformanceReport(report);
      if (report.status === DATA_STATUS.MISSING) {
        setMissingDataTypes(report.errors);
      }

      if (reportResultScrollTargetRef.current) {
        reportResultScrollTargetRef.current.scrollIntoView(
          smoothScrollBehaviour
        );
      }
    } catch (error) {
      if (error?.status === 400) {
        if (error.data?.message) {
          toastContext.addFailToast(<span>{error.data.message}</span>);
        } else {
          toastContext.addFailToast(<span>Failed to run report.</span>);
        }
      } else {
        toastContext.addFailToast(<span>Failed to generate report.</span>);
      }
    }
    setLoadingReport(false);
  };

  const getBenchmark = async (params) => {
    setPerformanceBenchmark(null);
    try {
      const benchmark = await PerformanceReportService.getBenchmark(
        getBenchmarkParams(params)
      );
      setPerformanceBenchmark(benchmark);
    } catch (error) {
      if (error.status === 500) {
        toastContext.addFailToast(
          <span>Failed to get performance benchmark.</span>
        );
      }
    }
  };

  const onDataRequestCreate = () => {
    setShowDataRequest(false);
    toastContext.addSuccessToast(
      <div>
        <div>You have successfully created a data request.</div>
        <div>
          <GVDSTextButton
            onClick={() =>
              history.push(
                getRedirectURLWithCurrentParam(OVERVIEW_DATA, location)
              )
            }
            text={"Go to Data Management"}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("reports.performance.page-title")}</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          {t("reports.performance.page-description")}
        </PageHeader.Description>
      </PageHeader>
      {loadingConfig ? (
        <LoadingSpinner />
      ) : (
        <PerformanceReportConfig
          reportParams={reportParams}
          setReportParams={setReportParams}
          reportConfig={reportConfig}
          benchmarkAvailable={benchmarkAvailable}
          benchmark={performanceBenchmark}
          issueDataRequest={() => setShowDataRequest(true)}
          downloadReport={async () =>
            await downloadReport(reportParams, reportConfig)
          }
          runReport={async () => await runReport(reportParams)}
        />
      )}

      {!PerformanceReportParamsModel.disableDownloadReport(
        reportParams,
        reportConfig
      ) && <ReportLegend />}
      {loadingReport ? (
        <LoadingSpinner />
      ) : (
        <>
          {PerformanceReportParamsModel.getChartStatus(
            reportParams,
            reportConfig
          ) === PerformanceReportStatus.AVAILABLE && (
            <PerformanceReportChart
              reportParams={reportParams}
              reportConfig={reportConfig}
              report={performanceReport}
              benchmark={performanceBenchmark}
            />
          )}
          <PerformanceReportTable
            showLocationBased={
              PerformanceReportParamsModel.showMarketPurchaseOption(
                reportParams,
                reportConfig
              ) && reportParams["use_location_based"]
            }
            showMarketBased={
              PerformanceReportParamsModel.showMarketPurchaseOption(
                reportParams,
                reportConfig
              ) && !reportParams["use_location_based"]
            }
            reportMonths={reportParams["months"]}
            report={performanceReport}
            performanceGroupName={reportParams["performance_group_name"]}
          />
        </>
      )}
      <div ref={reportResultScrollTargetRef} />
      {showDataRequest && (
        <CreateDataRequestModal
          requestType={REPORT_TYPE.PERFORMANCE}
          missingDataTypes={missingDataTypes}
          requestParams={{
            ...reportParams,
            resource_id: userInventory.selectedInventory.get.id,
            resource_type: userInventory.selectedInventory.get.type,
          }}
          onModalClose={() => setShowDataRequest(false)}
          onCreate={onDataRequestCreate}
        />
      )}
    </div>
  );
};

export default withAuthentication(PerformanceReport);
