import React, { useContext } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CertificationComments from "./CertificationComments";
import CertificationEvidence from "./CertificationEvidence";
import CertificationOffcanvasContext from "./CertificationOffcanvasContext";
import CertificationContext from "../../CertificationContext";
import { useRouteMatch } from "react-router-dom";
import { CERTIFICATION_CONTENT_PAGE_REQUIREMENT } from "../../../../config/ROUTES_NAME";

const CertificationOffcanvas = () => {
  const { t } = useTranslation();

  const routeMatch = useRouteMatch(CERTIFICATION_CONTENT_PAGE_REQUIREMENT);
  const requirementId = routeMatch?.params["requirementId"];

  const certificationContext = useContext(CertificationContext);
  const certificationOffcanvasContext = useContext(
    CertificationOffcanvasContext
  );

  const onClose = () => {
    certificationOffcanvasContext.setShow(false);
  };

  const selectedRequirement =
    certificationContext.certification?.getSelectedRequirement(requirementId);

  return (
    <Offcanvas
      show={certificationOffcanvasContext.show}
      onHide={onClose}
      placement="end"
    >
      <Offcanvas.Body>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onClose}
          icon={<GVDSIcon Icon={IconX} />}
          text={t("shared-modal.footer.close")}
        />
        <Tabs
          activeKey={certificationOffcanvasContext.activeTabKey}
          onSelect={certificationOffcanvasContext.setActiveTabKey}
        >
          <Tab
            eventKey="evidence"
            title={`Evidence (${selectedRequirement?.evidence.length})`}
          >
            <CertificationEvidence />
          </Tab>
          <Tab
            eventKey="comments"
            title={`${t("shared-input-label.comments")} (${
              selectedRequirement?.comments.length
            })`}
          >
            <CertificationComments />
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CertificationOffcanvas;
