import { BillingCycleEnum } from "./PackagePlanModel";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PlanChangeDisplay from "../../Admin/Billing/PlanChangeDisplay";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import React, { useContext, useEffect, useState } from "react";
import {
  DateTimeUtils,
  NumberService,
  StringUtils,
} from "../../../services/UtilsService";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import BillingService from "../../../services/BillingService";
import { RESOURCES } from "../../../config/constants";
import PaymentInstructionModal from "../../Admin/Billing/PaymentInstructionModal";
import { billingWindowFeatures } from "../../../services/Public/BillingPaymentService";
import { SubscriptionPricingType } from "../../SystemToolbox/Subscription/SubscriptionSharedUtils";

const COMPONENT_STATE = {
  CONFIRM_CHANGE: "confirm change",
  PAYMENT_INSTRUCTION: "payment instruction",
};

const ConfirmChangePlanModal = ({
  show,
  onClose,
  currentPlan,
  newPlan,
  currentBillingCycle,
  currency = "USD",
  nextBillingDate,
  currentSubscription,
  onChangePlanSuccess,
}) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const resourceType = selectedInventory?.type;
  const resourceId = selectedInventory?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [componentState, setComponentState] = useState(
    COMPONENT_STATE.CONFIRM_CHANGE
  );

  const [currentSubscriptionRefundAmount, setCurrentSubscriptionRefundAmount] =
    useState(0);
  const [paymentLink, setPaymentLink] = useState(null);

  const selectedSubscriptionId = currentSubscription?.id;

  useEffect(() => {
    if (
      show &&
      selectedSubscriptionId &&
      resourceType === RESOURCES.SITE &&
      resourceId
    ) {
      BillingService.getSubscriptionRefundAmount(
        resourceType,
        resourceId,
        selectedSubscriptionId
      ).then((paidAmount) => {
        setCurrentSubscriptionRefundAmount(paidAmount);
      });
    }
  }, [show, selectedSubscriptionId]);

  if (
    !currentPlan ||
    !newPlan ||
    resourceType !== RESOURCES.SITE ||
    currentSubscription.pricingType === SubscriptionPricingType.FREE
  ) {
    return null;
  }

  const newBillingCycle =
    currentBillingCycle === BillingCycleEnum.monthly && newPlan.hasMonthly
      ? BillingCycleEnum.monthly
      : currentBillingCycle === BillingCycleEnum.yearly && newPlan.hasYearly
      ? BillingCycleEnum.yearly
      : newPlan.hasMonthly
      ? BillingCycleEnum.monthly
      : BillingCycleEnum.yearly;

  const currentYearlyPrice = currentPlan.getYearlyPriceForCurrency(currency);
  const currentPrice =
    currentBillingCycle === BillingCycleEnum.monthly
      ? currentPlan.getMonthlyPriceForCurrency(currency)
      : currentYearlyPrice;
  const newYearlyPrice = newPlan.getYearlyPriceForCurrency(currency);
  const newPrice =
    newBillingCycle === BillingCycleEnum.monthly
      ? newPlan.getMonthlyPriceForCurrency(currency)
      : newYearlyPrice;

  const onSubmitChange = () => {
    setIsLoading(true);
    BillingService.changeSubscriptionPlan(
      selectedInventory.type,
      selectedInventory.id,
      selectedSubscriptionId,
      newPlan.packagePlanId
    )
      .then((paymentLinkPayload) => {
        if (!paymentLinkPayload) {
          toastContext.addSuccessToast(<span>Subscription Plan updated</span>);
          onChangePlanSuccess();
        } else {
          setPaymentLink(paymentLinkPayload);
          window.open(paymentLinkPayload, "_blank", billingWindowFeatures);
          setComponentState(COMPONENT_STATE.PAYMENT_INSTRUCTION);
        }
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to change subscription plan</span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCancelChange = () => {
    closeModal();
  };

  const closeModal = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const newCycleDate = DateTimeUtils.formatLocalDate(nextBillingDate);
  const newCyclePaymentDate = DateTimeUtils.formatLocalDate(
    BillingService.getNewCyclePaymentDate(nextBillingDate)
  );

  const isDowngrade =
    currentYearlyPrice.priceInDecimal > newYearlyPrice.priceInDecimal;
  const isNewPlanSameBillingCycle = currentBillingCycle === newBillingCycle;

  const effectiveDateMessage = isDowngrade ? (
    <>
      Your new subscription will begin at the start of your next billing cycle
      on <strong>{newCycleDate}</strong>. You will be billed automatically on{" "}
      <strong>{newCyclePaymentDate}</strong>. Applicable taxes not inclusive.
    </>
  ) : (
    <>
      Your new subscription will begin upon successful payment of{" "}
      <strong>
        {currency} $
        {NumberService.format(
          newPrice.priceInDecimal - currentSubscriptionRefundAmount
        )}
      </strong>
      . This is the pro-rated difference between your current and new
      subscription. Applicable taxes not inclusive.
    </>
  );

  if (componentState === COMPONENT_STATE.PAYMENT_INSTRUCTION) {
    return (
      <PaymentInstructionModal onClose={onClose} paymentLink={paymentLink} />
    );
  }

  return (
    <GVDSModal
      size={GVDSModal.Size.medium}
      title="Confirm changes"
      show={show}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        {!isNewPlanSameBillingCycle && (
          <GVDSBanner
            variant={GVDSBanner.Variants.warning}
            title={`Your billing cycle will change to ${newBillingCycle}`}
          >
            <div>
              {StringUtils.capitaliseWord(currentBillingCycle ?? "")} billing is
              not available for {newPlan.name}.
            </div>
          </GVDSBanner>
        )}
        <div className="mt-3">
          <PlanChangeDisplay
            currentPlanName={currentPlan?.name}
            currentBillingCycle={currentBillingCycle}
            currentPrice={NumberService.format(
              currentPrice.priceInDecimal,
              0,
              0
            )}
            currency={currency}
            newPlanName={newPlan?.name}
            newBillingCycle={newBillingCycle}
            newPrice={NumberService.format(newPrice.priceInDecimal, 0, 0)}
          />
        </div>
        <div className="mt-4">{effectiveDateMessage}</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onCancelChange}
          text={`Cancel`}
          disabled={isLoading}
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={onSubmitChange}
          text={`Confirm Change`}
          disabled={isLoading}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default ConfirmChangePlanModal;
