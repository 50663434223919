import axios from "axios";
import { API_URL } from "../config/api-config";

const cannot_find_my_metro_area = "Can't find my metro area";

export class MetroAreaListModel {
  constructor(metroAreaList) {
    this.id = metroAreaList.id;
    this.name = metroAreaList.name;
    this.country_id = metroAreaList.country?.id;
    this.country_name = metroAreaList.country?.name;
  }

  get country_name_list() {
    return this.country_name;
  }
}

export default class MetroAreaService {
  static getAllMetroAreasForCountry = async (countryId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/metroareas/" + countryId + "/country"
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  static getAllMetroAreas = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/ref_data/metroareas");
      return response.data.map((a) => new MetroAreaListModel(a));
    } catch (error) {
      return [];
    }
  };

  static sortFn = (m1, m2) => {
    return (m1.name === cannot_find_my_metro_area ? -1 : m1.name.localeCompare(m2.name));
  };
}
