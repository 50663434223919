import React from "react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import PopoverOnBigNumberTruncated from "../../common/Tooltip/PopoverOnBigNumberTruncated";

export const BaseWidgetGenericSeparator = () => {
  return <div className="base-widget__generic-summary__separator"></div>
}

const BaseWidgetGenericSummaryItem = ({ label, icon, value, unit=null }) => {
  return (
    <div className="base-widget__generic-summary__container">
      <div className="base-widget__generic-summary__icon">
        <GVDSIcon Icon={icon} />
      </div>
      <div className="base-widget__generic-summary__text-content">
        <div className="base-widget__generic-summary__label">{label}</div>
        <div className="base-widget__generic-summary__value-container">
          <PopoverOnBigNumberTruncated
            numberValue={value}
            contentClassName="base-widget__generic-summary__value-number"
          />
          {unit && <div className="base-widget__generic-summary__unit">
            {unit}
          </div>}
        </div>
      </div>
    </div>
  );
};

export default BaseWidgetGenericSummaryItem;
