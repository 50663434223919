import GVDSTextButton from "../../../../gvds-components/Buttons/GVDSTextButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import {
  IconArrowsUpDown,
  IconCirclePlus,
  IconEdit,
} from "@tabler/icons-react";
import React, { useContext, useState } from "react";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import ToastContext from "../../../../context/ToastContext";
import CertificationRequirementAdminService from "../../../../services/PortalAdmin/Certification/CertificationRequirementAdminService";
import StatusLabel from "../../../../gvds-components/common/StatusLabel";
import {
  ConfirmDeleteRequirementModal,
  MoveCertificationRequirementDialog,
  RequirementFormModal,
} from "./CertificationContentRequirementModals";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";

const REQUIREMENT_CONTENT_EXTRA_PADDING_IN_PX = 12;

const CertificationContentRequirements = ({
  certificationId,
  selectedCategory,
  categories,
  selectedCriterion,
  reloadCategories,
  requirementNumberCssMinWidth,
}) => {
  const toastContext = useContext(ToastContext);

  const [requirements, setRequirements] = useState(
    (
      selectedCategory.criteria.find(
        (criterion) => criterion.id === selectedCriterion.id
      )?.requirements ?? []
    ).sort((a, b) => a.order - b.order)
  );
  const [requirementToBeEdited, setRequirementToBeEdited] = useState(null);
  const [showRequirementFormModal, setShowRequirementFormModal] =
    useState(false);
  const onRequirementFormModalClose = () => {
    setRequirementToBeEdited(null);
    setShowRequirementFormModal(false);
  };
  const promptAddRequirement = () => {
    setRequirementToBeEdited(null);
    setShowRequirementFormModal(true);
  };
  const promptEditRequirement = (requirement) => {
    setRequirementToBeEdited(requirement);
    setShowRequirementFormModal(true);
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteRequirementModal, setShowDeleteRequirementModal] =
    useState(false);
  const onDeleteRequirementModalClose = () => {
    setRequirementToBeEdited(null);
    setShowDeleteRequirementModal(false);
    reloadCategories();
  };
  const promptDeleteRequirement = () => {
    setShowRequirementFormModal(false);
    setShowDeleteRequirementModal(true);
  };

  const [requirementToBeMoved, setRequirementToBeMoved] = useState(null);
  const [showMoveRequirementModal, setShowMoveRequirementModal] =
    useState(false);
  const onMoveRequirementModalClose = () => {
    setRequirementToBeMoved(null);
    setShowMoveRequirementModal(false);
  };
  const promptMoveRequirement = (requirement) => {
    setRequirementToBeMoved(requirement);
    setShowMoveRequirementModal(true);
  };

  const deleteRequirement = async () => {
    setIsDeleting(true);

    try {
      await CertificationRequirementAdminService.deleteRequirement(
        certificationId,
        selectedCriterion.id,
        requirementToBeEdited.id
      );
      setRequirements(
        requirements.filter((req) => req.id !== requirementToBeEdited.id)
      );
      onDeleteRequirementModalClose();
      toastContext.addSuccessToast(
        <span>Requirement successfully deleted.</span>
      );
    } catch (error) {
      toastContext.addFailToast(<span>Failed to delete requirement.</span>);
    } finally {
      setIsDeleting(false);
    }
  };

  const getSubRequirementStatusLabel = (requirement) => {
    const subrequirementCount = requirement.subrequirementSections.reduce(
      (acc, section) => acc + section.subrequirements.length,
      0
    );

    return subrequirementCount > 0 ? (
      <StatusLabel
        className="mt-1"
        color={StatusLabel.Colors.gray}
      >{`${subrequirementCount} sub-requirement${
        subrequirementCount > 1 ? "s" : ""
      }`}</StatusLabel>
    ) : null;
  };

  return (
    <div className="system-toolbox--certification__requirements__content-container">
      <div className="system-toolbox--certification__requirements__content">
        {requirements.map((requirement) => (
          <div
            key={requirement.id}
            className="system-toolbox--certification__requirement"
          >
            <div className="d-flex flex-row">
              <div
                className="system-toolbox--certification__requirement-display__number"
                style={{ minWidth: `${requirementNumberCssMinWidth}px` }}
              >
                {requirement.number}
              </div>
              <div className="system-toolbox--certification__requirement-display__name">
                <div>{requirement.name}</div>
              </div>
              <Spacer />
              <div className="system-toolbox--certification__content-button-container thin-margin-top">
                <GVDSIconButton
                  variant={iconButtonVariant.tertiary}
                  icon={<GVDSIcon Icon={IconEdit} />}
                  tooltipText="Edit"
                  onClick={() => promptEditRequirement(requirement)}
                />
                <GVDSIconButton
                  variant={iconButtonVariant.tertiary}
                  icon={<GVDSIcon Icon={IconArrowsUpDown} />}
                  tooltipText="Move"
                  onClick={() => promptMoveRequirement(requirement)}
                />
              </div>
            </div>
            <div
              className="system-toolbox--certification__requirement-display__banner--subrequirement-label__container"
              style={{
                marginLeft: `${
                  requirementNumberCssMinWidth +
                  REQUIREMENT_CONTENT_EXTRA_PADDING_IN_PX
                }px`,
              }}
            >
              {requirement.instruction && (
                <GVDSBanner
                  variant={GVDSBanner.Variants.informational}
                  className="system-toolbox--certification__instruction-banner"
                >
                  <div className="gvds-text--caption">
                    {requirement.instruction}
                  </div>
                </GVDSBanner>
              )}
              {getSubRequirementStatusLabel(requirement)}
            </div>
          </div>
        ))}
        <GVDSTextButton
          className="system-toolbox--certification__add-requirement-button"
          variant={iconButtonVariant.tertiary}
          icon={<GVDSIcon Icon={IconCirclePlus} className="me-2" />}
          text="Add requirement"
          onClick={() => promptAddRequirement()}
        />
      </div>
      <RequirementFormModal
        certificationId={certificationId}
        category={selectedCategory}
        criteria={selectedCriterion}
        show={showRequirementFormModal}
        setShowInputRequirementForm={setShowRequirementFormModal}
        closeModal={onRequirementFormModalClose}
        requirement={requirementToBeEdited}
        onPressDeleteRequirement={promptDeleteRequirement}
        onRequirementSaved={reloadCategories}
      />
      <ConfirmDeleteRequirementModal
        show={showDeleteRequirementModal}
        closeModal={onDeleteRequirementModalClose}
        onDelete={deleteRequirement}
        isDeleting={isDeleting}
      />

      {requirementToBeMoved && (
        <MoveCertificationRequirementDialog
          show={showMoveRequirementModal}
          closeModal={onMoveRequirementModalClose}
          onRequirementMoved={reloadCategories}
          certificationId={certificationId}
          requirementToBeMoved={requirementToBeMoved}
          selectedCategory={selectedCategory}
          categories={categories}
          selectedCriterion={selectedCriterion}
        />
      )}
    </div>
  );
};

export default CertificationContentRequirements;
