export const MenuTypeEnum = {
  ContinuousTimeFilter: "Continuous Time Filter",
  ComparisonTimeFilter: "Comparison Time Filter",
  MetricSelector: "Metric Selector",
  SiteSelector: "Site Selector",
  ClassificationSelector: "Classification Selector",
  UnitSelector: "Unit Selector",
};

export const WidgetTypeEnum = {
  SectionHeader: "Section Header",
  Row: "Row",
  Col: "Col",
  Tabs: "Tabs",
  Tab: "Tab",

  MainSummaryCard: "Main Summary Card",
  CollectionSummaryCards: "Collection Summary Cards",
  ClassificationDonutChart: "Classification Donut Chart",
  SummarySiteRankingChart: "Summary Site Ranking Chart",
  PerformanceTimelineChart: "Performance Timeline Chart",
  ChangeOverTimeSiteRankingChart: "Change Over Time Site Ranking Chart",
  PerformanceComparisonChart: "PerformanceComparisonChart"
};
