import Dropdown from "react-bootstrap/Dropdown";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import React from "react";
import GVDSButtonGroup from "../../../gvds-components/Buttons/GVDSButtonGroup";
import { expandedModalViewOptions } from "../Models/DashboardModels";
import DropdownDownloadingSpinner from "../../common/DropdownDownloadingSpinner";

const DashboardExpandedHeaderControls = ({
  selectedViewOption,
  onSelectViewOption,
  onExportChart,
  onDownloadTableAsExcel,
  isDownloading = false,
}) => {
  return (
    <>
      <GVDSButtonGroup
        options={expandedModalViewOptions}
        selectedOption={selectedViewOption}
        onSelect={onSelectViewOption}
      />
      {isDownloading ? (
        <DropdownDownloadingSpinner className="mx-2" />
      ) : (
        <Dropdown>
          <Dropdown.Toggle as={OtherActionDropdownToggle} />
          <Dropdown.Menu>
            <Dropdown.Item
              key="download-chart"
              href="#"
              onClick={onExportChart}
            >
              Download chart as image
            </Dropdown.Item>
            <Dropdown.Item
              key="download-table"
              href="#"
              onClick={onDownloadTableAsExcel}
            >
              Download table as Excel
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default DashboardExpandedHeaderControls;
