import { PERFORMANCE_REPORT_TYPE } from "./PerformanceYearlyReportModel";
import { OPERATIONAL_METER_TYPE_FLOAT } from "../../../config/constants";
import { NumberService } from "../../../services/UtilsService";

export default class PerformanceReportFormatService {
  static renderFormattedValue = (reportType, reportTitle, value) => {
    if (reportType === PERFORMANCE_REPORT_TYPE.OPERATIONAL_ONLY) {
      if (
        reportTitle === OPERATIONAL_METER_TYPE_FLOAT.AVG_FTE ||
        reportTitle === OPERATIONAL_METER_TYPE_FLOAT.AVG_WORKERS
      ) {
        return NumberService.format(value, 2, 2);
      } else {
        return NumberService.format(value, 0, 0);
      }
    } else {
      return NumberService.format(value, 2, 2);
    }
  };

  static getStatusCssClass(status) {
    return status ? status.toLowerCase().replaceAll(" ", "_") : "empty";
  }
}
