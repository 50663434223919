export const classificationDonutChartConfig = {
  chartMarginInPixel: { top: 15, right: 10, bottom: 12, left: 10 },
  minLegendItemCount: 5,
  maxLegendItemCount: 10,
  chartViewId: "classification-donut-chart-view-id",
  expandedChartViewId: "expanded-classification-donut-chart-view-id",
  annotationTextHeightInPx: 15,
  annotationYOverlapTolerance: 15,
  annotationMaxAngleInRadForRightSideLoc: 0.9 * 2 * Math.PI,
  classificationValueColIndex: 0,
  miniViewExcludeOnDownloadContainerId: "classification-donut-mini-view-exclude-on-download-container-id",
  expandedViewExcludeOnDownloadContainerId: "classification-donut-expanded-view-exclude-on-download-container-id"
};
