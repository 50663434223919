import React, { useCallback, useState } from "react";

const WorkspaceNavContext = React.createContext();

export const WorkspaceNavProvider = ({ children }) => {
  const [isWorkspaceNavOpen, setIsWorkspaceNavOpen] = useState(false);

  const openWorkspaceNav = () => {
    setIsWorkspaceNavOpen(true);
  };

  const closeWorkspaceNav = () => {
    setIsWorkspaceNavOpen(false);
  };

  const store = useCallback(
    {
      isOpen: isWorkspaceNavOpen,
      open: openWorkspaceNav,
      close: closeWorkspaceNav,
    },
    [isWorkspaceNavOpen]
  );

  return (
    <WorkspaceNavContext.Provider value={store}>
      {children}
    </WorkspaceNavContext.Provider>
  );
};

export default WorkspaceNavContext;
