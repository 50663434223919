import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CreateDataRequestModal from "../../Data/Overview/DataRequest/CreateDataRequestModal";

import withAuthentication from "../../HOC/withAuthentication";
import {
  extractPageParamsObjectFromURL,
  getRedirectURLWithCurrentParam,
} from "../../common/QueryHandler";
import { OVERVIEW_DATA } from "../../../config/ROUTES_NAME";
import StandardReportService from "./../../../services/Report/StandardReportService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import SelectCurrency from "./../../Currency/SelectCurrency";
import {
  REPORT_TIME_FRAMES,
  REPORT_TYPE,
  SUBTOPIC_TYPE,
} from "../../../config/constants";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import ToastContext from "../../../context/ToastContext";
import ReportLegend, { EmissionReportingMethodBlurb } from "../ReportLegend";
import GVFormGroup from "../../common/GVFormGroup";
import { useHistory, useLocation } from "react-router-dom";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import { useTranslation } from "react-i18next";

const StandardReport = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const today = moment().toDate();

  const [isLoading, setIsLoading] = useState(true);

  const [timeFrameSelected, setTimeFrameSelected] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [currencyId, setCurrencyId] = useState("");

  const [energyUnits, setEnergyunits] = useState([]);
  const [wasteUnits, setWasteUnits] = useState([]);
  const [waterUnits, setWaterUnits] = useState([]);
  const [areaUnits, setAreaUnits] = useState([]);
  const [emissionUnits, setEmissionUnits] = useState([]);
  const [refrigerantsUnits, setRefrigerantsUnits] = useState([]);

  const [missingDataTypes, setMissingDataTypes] = useState([]);

  const [reportParams, setReportParams] = useState({
    energy_unit: "",
    waste_and_recycling_unit: "",
    water_unit: "",
    emission_unit: "",
    area_unit: "",
    refrigerants_unit: "",
    use_location_based: true,
  });

  const [showDataRequest, setShowDataRequest] = useState(false);

  useEffect(() => {
    const reportParamsObj = extractPageParamsObjectFromURL(location);
    if (reportParamsObj) {
      setTimeFrameSelected(REPORT_TIME_FRAMES.CUSTOM);
      setStartDate(moment(reportParamsObj["start"]).toDate());
      setEndDate(moment(reportParamsObj["end"]).toDate());
      if (!reportParamsObj["postponeDownload"]) {
        generateStandardReport(reportParamsObj).then();
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (selectedInventory) {
      setMissingDataTypes([]);
      setIsLoading(true);
      StandardReportService.getReportConfig(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((units) => {
          setEnergyunits(units["energy"]);
          setWasteUnits(units["waste"]);
          setWaterUnits(units["water"]);
          setAreaUnits(units["area"]);
          setEmissionUnits(units["emission"]);
          setRefrigerantsUnits(units["refrigerants"]);
          setReportParams({
            energy_unit: units["default_units"][SUBTOPIC_TYPE.ENERGY],
            waste_and_recycling_unit:
              units["default_units"][SUBTOPIC_TYPE.WASTE],
            refrigerants_unit:
              units["default_units"][SUBTOPIC_TYPE.REFRIGERANTS],
            water_unit: units["default_units"][SUBTOPIC_TYPE.WATER],
            emission_unit: units["default_units"][SUBTOPIC_TYPE.EMISSIONS],
            area_unit: units["default_units"]["area_unit"],
            use_location_based: true,
          });
          setCurrencyId(units["default_units"]["currency_code"]);
          setIsLoading(false);
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to get report config.</span>);
        });
    }
  }, [selectedInventory]);

  const changeStartDate = (date) => {
    if (!date) return;
    setStartDate(moment(date).startOf("month").toDate());
  };

  const changeEndDate = (date) => {
    if (!date) {
      setEndDate(date);
    } else {
      const endOfMonth = moment(date).endOf("month").toDate();
      setEndDate(moment(today).isBefore(endOfMonth) ? today : endOfMonth);
    }
  };

  const isEndDateEarlierThanStartDate = () => {
    return startDate && endDate && endDate < startDate;
  };

  const timeFrameChange = (selectedOption) => {
    const newTimeFrameSelected = selectedOption.value;
    setTimeFrameSelected(newTimeFrameSelected);

    let startDate = moment().startOf("month").toDate();
    let endDate = today;

    if (newTimeFrameSelected === REPORT_TIME_FRAMES.THIS_MONTH) {
      startDate = moment().startOf("month").toDate();
      endDate = today;
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.THIS_QUARTER) {
      startDate = moment().quarter(moment().quarter()).startOf("quarter")._d;
      endDate = today;
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.THIS_YEAR) {
      startDate = moment().startOf("year").toDate();
      endDate = today;
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.LAST_MONTH) {
      startDate = moment().subtract(1, "months").startOf("month").toDate();
      endDate = moment().subtract(1, "months").endOf("month").toDate();
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.LAST_QUARTER) {
      startDate = moment()
        .quarter(moment().quarter() - 1)
        .startOf("quarter")._d;
      endDate = moment()
        .quarter(moment().quarter() - 1)
        .endOf("quarter")._d;
    } else if (newTimeFrameSelected === REPORT_TIME_FRAMES.LAST_YEAR) {
      startDate = moment().startOf("year").subtract(1, "year").toDate();
      endDate = moment().endOf("year").subtract(1, "year").toDate();
    }

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const changeDropdownInputField = (selectedOption, actionCtx) => {
    const targetName = actionCtx.name;
    const newId = selectedOption.value;

    const newInputFields = { ...reportParams };

    newInputFields[targetName] = newId;

    setReportParams({ ...newInputFields });
  };

  const disableRunSummaryReport = () => {
    return (
      !timeFrameSelected ||
      !reportParams.energy_unit ||
      !reportParams.waste_and_recycling_unit ||
      !reportParams.water_unit ||
      !reportParams.emission_unit ||
      !reportParams.area_unit ||
      !currencyId ||
      isEndDateEarlierThanStartDate()
    );
  };

  const disableRunMonthlyDataBreakdownReport = () => {
    return (
      !timeFrameSelected ||
      !reportParams.energy_unit ||
      !reportParams.waste_and_recycling_unit ||
      !reportParams.water_unit ||
      !reportParams.emission_unit ||
      !reportParams.area_unit ||
      !currencyId ||
      !reportParams.refrigerants_unit ||
      isEndDateEarlierThanStartDate()
    );
  };

  const getReportParams = () => {
    return StandardReportService.getStandardReportRequestParam(
      selectedInventory.id,
      selectedInventory.type,
      startDate,
      endDate,
      reportParams.energy_unit,
      reportParams.waste_and_recycling_unit,
      reportParams.water_unit,
      reportParams.emission_unit,
      reportParams.area_unit,
      currencyId,
      reportParams.refrigerants_unit,
      reportParams.use_location_based
    );
  };

  const generateDataDumpReport = async (params) => {
    if (
      !params.resource_id ||
      !params.start ||
      !params.end ||
      !params.energyUnit ||
      !params.wasteUnit ||
      !params.waterUnit ||
      !params.emissionUnit ||
      !params.areaUnit ||
      !params.currencyId ||
      !params.refrigerantsUnit ||
      isEndDateEarlierThanStartDate()
    ) {
      return;
    }

    try {
      await StandardReportService.generateDataDump(params);
    } catch (error) {
      toastContext.addFailToast(<span>Failed to generate report.</span>);
    }
  };

  const generateMonthlyDataBreakDown = async (params) => {
    if (
      !params.resource_id ||
      !params.start ||
      !params.end ||
      !params.energyUnit ||
      !params.wasteUnit ||
      !params.waterUnit ||
      !params.emissionUnit ||
      !params.areaUnit ||
      !params.currencyId ||
      !params.refrigerantsUnit ||
      isEndDateEarlierThanStartDate()
    ) {
      return;
    }
    try {
      await StandardReportService.generateMonthlyDataBreakDown(params);
    } catch (error) {
      if (error.status === 400) {
        toastContext.addFailToast(error.data.message);
      } else {
        toastContext.addFailToast("Failed to generate report");
      }
    }
  };

  const generateStandardReport = async (params) => {
    if (
      !params.resource_id ||
      !params.start ||
      !params.end ||
      !params.energyUnit ||
      !params.wasteUnit ||
      !params.waterUnit ||
      !params.emissionUnit ||
      !params.areaUnit ||
      !params.currencyId ||
      isEndDateEarlierThanStartDate()
    ) {
      return;
    }
    setMissingDataTypes([]);

    try {
      const reportStatus = await StandardReportService.getStandardReportStatus(
        params
      );
      setMissingDataTypes(reportStatus.errors);
      await StandardReportService.downloadStandardReport(params);
    } catch (error) {
      toastContext.addFailToast(<span>Failed to generate report.</span>);
    }
  };

  const onDataRequestCreate = () => {
    setShowDataRequest(false);
    toastContext.addSuccessToast(
      <div>
        <div>You have successfully created a data request.</div>
        <div>
          <GVDSTextButton
            onClick={() =>
              history.push(
                getRedirectURLWithCurrentParam(OVERVIEW_DATA, location)
              )
            }
            text={"Go to Data Management"}
          />
        </div>
      </div>
    );
  };

  const notificationMessage = (
    <>
      {missingDataTypes.length > 0 && (
        <GVDSBanner
          title="This report was generated with data gaps. Download the report to view data gap details."
          variant={GVDSBanner.Variants.error}
        >
          If the data gaps identified below are missing data and should be
          entered, consider issuing a data request.
          <GVDSBanner.Footer>
            <GVDSBanner.FooterButton
              onClick={() => setShowDataRequest(true)}
              text="Issue a data request"
            />
          </GVDSBanner.Footer>
        </GVDSBanner>
      )}
      {!disableRunSummaryReport() && <ReportLegend />}
    </>
  );

  const disabledTimeFrame = timeFrameSelected !== REPORT_TIME_FRAMES.CUSTOM;

  let content;

  if (isLoading) {
    content = (
      <div className="h-50">
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <>
        <div className="report-config">
          <div className="report-config-options">
            <Row>
              <Col xl={3} lg={4}>
                <GVFormGroup controlId="selectTimeFrame">
                  <Form.Label>Time Frames</Form.Label>
                  <GVDSFormSingleSelect
                    placeholder="Select Time Frame"
                    value={
                      timeFrameSelected
                        ? { value: timeFrameSelected, label: timeFrameSelected }
                        : null
                    }
                    options={Object.values(REPORT_TIME_FRAMES).map(
                      (timeFrame) => {
                        return { value: timeFrame, label: timeFrame };
                      }
                    )}
                    onSelect={timeFrameChange}
                  />
                </GVFormGroup>
              </Col>
              <Col xl={6} lg={8}>
                <GVFormGroup controlId="selectMetric">
                  <GVDSFormStartEndMonthPicker
                    startMonthLabel={<Form.Label>Report Period</Form.Label>}
                    className="standard-report"
                    disabledStartMonth={disabledTimeFrame}
                    disabledEndMonth={disabledTimeFrame}
                    startMonth={startDate}
                    endMonth={endDate}
                    onChange={(paramStartMonth, paramEndMonth) => {
                      if (paramStartMonth !== startDate) {
                        changeStartDate(paramStartMonth);
                      }
                      if (paramEndMonth !== endDate) {
                        changeEndDate(paramEndMonth);
                      }
                    }}
                    maxStartMonth={today}
                    maxEndMonth={moment(today).endOf("month").toDate()}
                  />
                </GVFormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl={3} lg={4}>
                <GVFormGroup controlId="selectUnit">
                  <Form.Label>Energy Unit</Form.Label>
                  <GVDSFormSingleSelect
                    className="select__unit"
                    name="energy_unit"
                    placeholder="Select Energy Unit"
                    value={
                      reportParams.energy_unit
                        ? getOptionByValueFromIdName(
                            reportParams.energy_unit,
                            energyUnits
                          )
                        : null
                    }
                    options={energyUnits.map((energyUnit) => {
                      return { value: energyUnit.id, label: energyUnit.name };
                    })}
                    onSelect={changeDropdownInputField}
                  />
                </GVFormGroup>
              </Col>
              <Col xl={3} lg={4}>
                <GVFormGroup controlId="selectWasteUnit">
                  <Form.Label>Waste & Recycling Unit</Form.Label>
                  <GVDSFormSingleSelect
                    name="waste_and_recycling_unit"
                    placeholder="Select Waste & Recycling Unit"
                    value={
                      reportParams.waste_and_recycling_unit
                        ? getOptionByValueFromIdName(
                            reportParams.waste_and_recycling_unit,
                            wasteUnits
                          )
                        : null
                    }
                    options={wasteUnits.map((wasteUnit) => {
                      return { value: wasteUnit.id, label: wasteUnit.name };
                    })}
                    onSelect={changeDropdownInputField}
                  />
                </GVFormGroup>
              </Col>
              <Col xl={3} lg={4}>
                <GVFormGroup controlId="selectWaterUnit">
                  <Form.Label>Water Unit</Form.Label>
                  <GVDSFormSingleSelect
                    name="water_unit"
                    placeholder="Select Water Input"
                    value={
                      reportParams.water_unit
                        ? getOptionByValueFromIdName(
                            reportParams.water_unit,
                            waterUnits
                          )
                        : null
                    }
                    options={waterUnits.map((waterUnit) => {
                      return { value: waterUnit.id, label: waterUnit.name };
                    })}
                    onSelect={changeDropdownInputField}
                  />
                </GVFormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl={3} lg={4}>
                <GVFormGroup controlId="selectRefrigerantsUnits">
                  <Form.Label>Refrigerants Unit</Form.Label>
                  <GVDSFormSingleSelect
                    name="refrigerants_unit"
                    placeholder="Select Refrigerants Unit"
                    value={
                      reportParams.refrigerants_unit
                        ? getOptionByValueFromIdName(
                            reportParams.refrigerants_unit,
                            refrigerantsUnits
                          )
                        : null
                    }
                    options={refrigerantsUnits.map((refrigerantsUnit) => {
                      return {
                        value: refrigerantsUnit.id,
                        label: refrigerantsUnit.name,
                      };
                    })}
                    onSelect={changeDropdownInputField}
                  />
                </GVFormGroup>
              </Col>
              <Col xl={3} lg={4}>
                <GVFormGroup controlId="selectemissionUnits">
                  <Form.Label>Emissions Unit</Form.Label>
                  <GVDSFormSingleSelect
                    name="emission_unit"
                    placeholder="Select Emissions Unit"
                    value={
                      reportParams.emission_unit
                        ? getOptionByValueFromIdName(
                            reportParams.emission_unit,
                            emissionUnits
                          )
                        : null
                    }
                    options={emissionUnits.map((emissionUnit) => {
                      return {
                        value: emissionUnit.id,
                        label: emissionUnit.name,
                      };
                    })}
                    onSelect={changeDropdownInputField}
                  />
                </GVFormGroup>
              </Col>
              <Col xl={3} lg={4}>
                <GVFormGroup controlId="selectAreaunit">
                  <Form.Label>
                    Area Unit{" "}
                    <InfoTooltip info="For intensity metrics generated using gross floor area and conditioned space" />
                  </Form.Label>
                  <GVDSFormSingleSelect
                    name="area_unit"
                    placeholder="Select Area Unit"
                    value={
                      reportParams.area_unit
                        ? getOptionByValueFromIdName(
                            reportParams.area_unit,
                            areaUnits
                          )
                        : null
                    }
                    options={areaUnits.map((areaUnit) => {
                      return { value: areaUnit.id, label: areaUnit.name };
                    })}
                    onSelect={changeDropdownInputField}
                  />
                </GVFormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl={3} lg={4}>
                <GVFormGroup>
                  <SelectCurrency
                    selected={currencyId}
                    onCurrencySelected={setCurrencyId}
                  />
                </GVFormGroup>
              </Col>
            </Row>
          </div>
        </div>
        <div className="mt-3 mb-3 d-flex flex-row justify-content-start">
          <div className="report-download-area">
            <h2>Summary Report</h2>
            <p className="gv-text-14">
              Shows all your environmental intensity metrics at a glance
              <br />
              <br />
              Select location-based emissions [most common case] unless your
              site has market purchase or custom emission factors applied
            </p>
            <div className="report-download-extra-config">
              <Form.Label>
                <span className="me-1">Emissions Reporting Method</span>
                <InfoTooltip info={<EmissionReportingMethodBlurb />} />
              </Form.Label>
              <div className="d-flex">
                <Form.Check
                  id="standard-report-for-location-based"
                  className="me-1"
                  label="Location-based Emissions"
                  type="radio"
                  checked={reportParams["use_location_based"]}
                  onChange={() => {
                    setReportParams({
                      ...reportParams,
                      use_location_based: true,
                    });
                  }}
                />
              </div>
              <div className="d-flex">
                <Form.Check
                  id="standard-report-for-market-based"
                  className="me-1"
                  label="Market-based Emissions"
                  type="radio"
                  checked={!reportParams["use_location_based"]}
                  onChange={() => {
                    setReportParams({
                      ...reportParams,
                      use_location_based: false,
                    });
                  }}
                />
              </div>
            </div>
            <div className="mt-auto">
              <GVDSButtonWithLoadingAction
                disabled={disableRunSummaryReport()}
                onClickAsyncFunc={async () => {
                  await generateStandardReport(getReportParams());
                }}
                text="Download"
              />
            </div>
          </div>
          <div className="report-download-area">
            <h2>Monthly Breakdown Report</h2>
            <p className="gv-text-14">
              Shows monthly environmental, operational, people, and gross floor
              area data
            </p>
            <div className="mt-auto">
              <GVDSButtonWithLoadingAction
                disabled={disableRunMonthlyDataBreakdownReport()}
                onClickAsyncFunc={async () => {
                  await generateMonthlyDataBreakDown(getReportParams());
                }}
                text="Download"
              />
            </div>
          </div>
          <div className="report-download-area">
            <h2>Data Dump Report</h2>
            <p className="gv-text-14">
              Report optimised for data analysis, consisting of monthly
              environmental, operational, and gross floor area data in a
              row-based data format
            </p>

            <div className="mt-auto">
              <GVDSButtonWithLoadingAction
                disabled={disableRunMonthlyDataBreakdownReport()}
                onClickAsyncFunc={async () => {
                  await generateDataDumpReport(getReportParams());
                }}
                text="Download"
              />
            </div>
          </div>
        </div>
        {notificationMessage}
        {showDataRequest && (
          <CreateDataRequestModal
            requestType={REPORT_TYPE.STANDARD_REPORT}
            startDate={startDate}
            endDate={endDate}
            missingDataTypes={missingDataTypes}
            requestParams={getReportParams()}
            onModalClose={() => setShowDataRequest(false)}
            onCreate={onDataRequestCreate}
          />
        )}
      </>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("reports.standard.page-title")}</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          {t("reports.standard.page-description")}
        </PageHeader.Description>
      </PageHeader>
      {content}
    </div>
  );
};

export default withAuthentication(StandardReport);
