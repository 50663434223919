import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import LoadingSpinner from "../../common/LoadingSpinner";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSDropdownToggle from "../../../gvds-components/Buttons/GVDSDropdownToggle";
import CertificationReadinessScore from "./CertificationReadinessScore";
import CertificationCategoryFilters from "./CertificationCategoryFilters";
import CertificationCriterion from "./CertificationCriterion";
import CertificationCriteriaFilters from "./CertificationCriteriaFilters";
import AnnouncementBarContext from "../../../gvds-components/AnnouncementBar/AnnouncementBarContext";
import fuzzysort from "fuzzysort";
import {
  CertificationCriteriaSearchKeys,
  FUZZYSORT_SHARED_OPTIONS,
  fuzzysortOptionsWithKeys,
} from "../../../config/search-config";
import NoEvidenceUploadedModal from "../NoEvidenceUploadedModal";
import CertificationContext from "../CertificationContext";
import { CertificationUtil } from "../CertificationUtil";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import { CertificationAssessmentService } from "../../../services/CertificationService";
import { NoContentError } from "../../../errors/NoContentError";
import SavedFiltersService from "../../../services/SavedFiltersService";
import { usePrevious } from "../../common/ReactHook";
import CertificationAboutModal from "./CertificationAboutModal";

export const MAX_ALLOWED_CERTIFICATION_CATEGORY_NAME_CHAR_DISPLAY = 30;
export const CATEGORY_FILTER_EXTRA_PADDING_IN_PX = 12;

const getFilterPersistencePageKey = (certificationId) =>
  SavedFiltersService.SAVED_FILTER__CERTIFICATION + certificationId;

const CertificationHomepageFilter = ({
  selectedCategoryNames,
  onSelectedCategoryNamesChange,
  searchInput,
  onSearchInputChange,
  isHideCompletedCriteria,
  onHideCompletedCriteriaChange,
  filteredCriteriaCount,
  minimumYPositionFilterStillInViewport,
}) => {
  const announcementBarContext = useContext(AnnouncementBarContext);

  const [isFiltersSticky, setIsFiltersSticky] = useState(false);

  const filtersDivRef = useRef(null);

  const nonStickySearchBoxRef = useRef(null);
  const stickySearchBoxRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (filtersDivRef.current) {
        const rect = filtersDivRef.current.getBoundingClientRect();
        if (rect.top < minimumYPositionFilterStillInViewport.current) {
          setIsFiltersSticky(true);

          if (
            nonStickySearchBoxRef.current !== null &&
            document.activeElement === nonStickySearchBoxRef.current
          ) {
            if (stickySearchBoxRef.current !== null) {
              stickySearchBoxRef.current.focus();
            }
          }
        } else {
          setIsFiltersSticky(false);

          if (
            stickySearchBoxRef.current !== null &&
            document.activeElement === stickySearchBoxRef.current
          ) {
            if (nonStickySearchBoxRef.current !== null) {
              nonStickySearchBoxRef.current.focus();
            }
          }
        }
      }
    };
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return (
    <>
      {isFiltersSticky && (
        <div
          className={`certification-homepage__filters__container sticky${
            announcementBarContext.show ? " with-announcement-bar" : ""
          }`}
        >
          <CertificationCategoryFilters
            selectedCategoryNames={selectedCategoryNames}
            onSelectedCategoryNamesChange={onSelectedCategoryNamesChange}
          />
          <CertificationCriteriaFilters
            searchBoxRef={stickySearchBoxRef}
            searchInput={searchInput}
            onInput={onSearchInputChange}
            isHideCompleted={isHideCompletedCriteria}
            onHideCompletedChange={onHideCompletedCriteriaChange}
            filteredCriteriaCount={filteredCriteriaCount}
          />
        </div>
      )}
      <div ref={filtersDivRef}>
        <CertificationCategoryFilters
          selectedCategoryNames={selectedCategoryNames}
          onSelectedCategoryNamesChange={onSelectedCategoryNamesChange}
        />
        <CertificationCriteriaFilters
          searchBoxRef={nonStickySearchBoxRef}
          searchInput={searchInput}
          onInput={onSearchInputChange}
          isHideCompleted={isHideCompletedCriteria}
          onHideCompletedChange={onHideCompletedCriteriaChange}
          filteredCriteriaCount={filteredCriteriaCount}
        />
      </div>
    </>
  );
};

const CertificationHomepageCriteriaList = ({
  isFiltering,
  filteredCriteriaIds,
  calculateCssWidthRef,
}) => {
  const certificationContext = useContext(CertificationContext);

  const [criterionNumberCSSMinWidth, setCriterionNumberCSSMinWidth] =
    useState(0);
  const [categoryCSSMinWidth, setCategoryCSSMinWidth] = useState(0);

  const allCriteria = certificationContext.certification
    ? certificationContext.certification.categories
        .map((category) => category.criteria)
        .flat()
    : [];

  useEffect(() => {
    const currentCertification = certificationContext.certification;

    if (currentCertification) {
      const criterionNumbers = allCriteria.map((criterion) => criterion.number);
      setCriterionNumberCSSMinWidth(
        CertificationUtil.calculateMinCssWidth(
          criterionNumbers,
          calculateCssWidthRef
        )
      );
      const categoryNames = allCriteria.map((criterion) =>
        CertificationUtil.getCategoryNameDisplay(criterion.categoryName)
      );
      setCategoryCSSMinWidth(
        CertificationUtil.calculateMinCssWidth(
          categoryNames,
          calculateCssWidthRef
        ) + CATEGORY_FILTER_EXTRA_PADDING_IN_PX
      );
    }
  }, [certificationContext.certification]);

  if (isFiltering) {
    return <LoadingSpinner />;
  }

  const filteredCriteriaCount = filteredCriteriaIds
    ? filteredCriteriaIds.length
    : 0;

  return (
    <>
      {filteredCriteriaIds === null ? (
        <LoadingSpinner />
      ) : filteredCriteriaCount === 0 &&
        !certificationContext.certification.hasNoCriteria() ? (
        <div className="table__no_content">
          No criteria found. Please adjust your filter.
        </div>
      ) : null}
      {filteredCriteriaIds !== null &&
        allCriteria.map((criterion) => (
          <CertificationCriterion
            key={criterion.id}
            categoryName={criterion.categoryName}
            criterion={criterion}
            criterionNumberCSSMinWidth={criterionNumberCSSMinWidth}
            categoryCSSMinWidth={categoryCSSMinWidth}
            isClickable={true}
            isHidden={!filteredCriteriaIds.includes(criterion.id)}
          />
        ))}
    </>
  );
};

const CertificationHomepage = () => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const certificationContext = useContext(CertificationContext);

  const [selectedCategoryNames, setSelectedCategoryNames] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isHideCompletedCriteria, setIsHideCompletedCriteria] = useState(false);
  const [isFiltering, setIsFiltering] = useState(true);
  const [filteredCriteriaIds, setFilteredCriteriaIds] = useState(null);

  const prevCertificationId = usePrevious(
    certificationContext.certification?.id
  );
  const [hasInitBeenRun, setHasInitBeenRun] = useState(false);

  const resetFilters = () => {
    setSelectedCategoryNames([]);
    setSearchInput("");
    setIsHideCompletedCriteria(false);
  };

  useEffect(() => {
    setHasInitBeenRun(false);

    const isChangedCertification =
      certificationContext.certification &&
      prevCertificationId !== certificationContext.certification.id;

    if (certificationContext.certification) {
      if (!isChangedCertification) {
        setHasInitBeenRun(true);
      } else {
        const filterPersistencePageKey = getFilterPersistencePageKey(
          certificationContext.certification.id
        );
        const savedFilters = SavedFiltersService.getSavedFilters(
          filterPersistencePageKey
        );

        if (savedFilters) {
          setSearchInput(
            savedFilters[SavedFiltersService.SAVED_FILTER_OBJ_KEY__SEARCH_TEXT]
          );
          const filterKeys =
            savedFilters[SavedFiltersService.SAVED_FILTER_OBJ_KEY__FILTER_KEY];
          setSelectedCategoryNames(filterKeys.selectedCategoryNames);
          setIsHideCompletedCriteria(filterKeys.isHideCompletedCriteria);
        } else {
          resetFilters();
        }

        if (prevCertificationId !== undefined) {
          SavedFiltersService.clearSavedFilters(
            getFilterPersistencePageKey(prevCertificationId)
          );
        }

        setHasInitBeenRun(true);
      }
    }
  }, [certificationContext.certification]);

  useEffect(() => {
    setIsFiltering(true);

    if (hasInitBeenRun && certificationContext.certification) {
      const selectedCategories =
        selectedCategoryNames.length === 0
          ? certificationContext.certification.categories
          : certificationContext.certification.categories.filter((category) =>
              selectedCategoryNames.includes(category.name)
            );

      let selectedCriteria = selectedCategories
        .map((category) => category.criteria)
        .flat();

      if (isHideCompletedCriteria) {
        selectedCriteria = selectedCriteria.filter(
          (criterion) => !criterion.isAllRequirementsCompleted()
        );
      }

      if (searchInput !== null && searchInput.length > 0) {
        selectedCriteria = fuzzysort
          .go(
            searchInput,
            selectedCriteria,
            fuzzysortOptionsWithKeys(
              CertificationCriteriaSearchKeys,
              FUZZYSORT_SHARED_OPTIONS
            )
          )
          .map((result) => result.obj);
      }
      selectedCriteria.sort(CertificationUtil.sortCriteriaFn);
      setFilteredCriteriaIds(selectedCriteria.map((c) => c.id));
      setIsFiltering(false);

      const filterPersistencePageKey = getFilterPersistencePageKey(
        certificationContext.certification.id
      );
      SavedFiltersService.saveFilters(filterPersistencePageKey, {
        [SavedFiltersService.SAVED_FILTER_OBJ_KEY__SEARCH_TEXT]: searchInput,
        [SavedFiltersService.SAVED_FILTER_OBJ_KEY__FILTER_KEY]: {
          selectedCategoryNames,
          isHideCompletedCriteria,
        },
      });
    }
  }, [
    hasInitBeenRun,
    selectedCategoryNames,
    searchInput,
    isHideCompletedCriteria,
  ]);

  const [isShowNoEvidenceUploadedModal, setIsShowNoEvidenceUploadedModal] =
    useState(false);

  const minimumYPositionFilterStillInViewport = useRef();
  const containerRef = useCallback((node) => {
    if (node !== null) {
      minimumYPositionFilterStillInViewport.current =
        node.getBoundingClientRect().top;
    }
  }, []);

  const calculateCssWidthRef = useRef();

  const onSelectedCategoryNamesChange = (updatedSelectedCategoryNames) => {
    setSelectedCategoryNames(updatedSelectedCategoryNames);
  };

  const onSearchInputChange = (updatedSearchInput) => {
    setSearchInput(updatedSearchInput);
  };

  const onHideCompletedCriteriaChange = (isHide) => {
    setIsHideCompletedCriteria(isHide);
  };

  const downloadReadinessReport = async () => {
    const currentInventory = userInventory.selectedInventory.get;

    try {
      await CertificationAssessmentService.downloadReadinessReport(
        currentInventory.type,
        currentInventory.id,
        certificationContext.certificationId
      );
    } catch {
      toastContext.addFailToast(
        <span>Failed to download readiness report.</span>
      );
    }
  };

  const downloadCertificationAllEvidence = async () => {
    const currentInventory = userInventory.selectedInventory.get;

    try {
      await CertificationAssessmentService.downloadEvidence(
        currentInventory.type,
        currentInventory.id,
        certificationContext.certificationId
      );
      toastContext.addSuccessToast(
        <span>All files have been downloaded successfully.</span>
      );
    } catch (error) {
      if (error instanceof NoContentError) {
        setIsShowNoEvidenceUploadedModal(true);
      } else {
        toastContext.addFailToast(<span>Failed to download files.</span>);
      }
    }
  };

  let content;

  if (certificationContext.isLoading || !hasInitBeenRun) {
    content = <LoadingSpinner />;
  } else if (certificationContext.certification === null) {
    content = (
      <div className="table__no_content">
        Certification not found. Try accessing a different certification.
      </div>
    );
  } else {
    const filteredCriteriaCount = filteredCriteriaIds
      ? filteredCriteriaIds.length
      : 0;

    content = (
      <div ref={containerRef}>
        <PageHeader>
          <PageHeader.Title>
            <div className="certification-homepage__title__container">
              <h1>{certificationContext.certification.name}</h1>
              <div className="ms-2">
                <CertificationAboutModal />
              </div>
            </div>
            <Dropdown>
              <Dropdown.Toggle as={GVDSDropdownToggle}>
                Download
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                {
                  <Dropdown.Item onClick={downloadReadinessReport}>
                    Readiness report
                  </Dropdown.Item>
                }
                {
                  <Dropdown.Item onClick={downloadCertificationAllEvidence}>
                    All certification evidence
                  </Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
          </PageHeader.Title>
        </PageHeader>
        <CertificationReadinessScore />
        <CertificationHomepageFilter
          selectedCategoryNames={selectedCategoryNames}
          onSelectedCategoryNamesChange={onSelectedCategoryNamesChange}
          searchInput={searchInput}
          onSearchInputChange={onSearchInputChange}
          isHideCompletedCriteria={isHideCompletedCriteria}
          onHideCompletedCriteriaChange={onHideCompletedCriteriaChange}
          filteredCriteriaCount={filteredCriteriaCount}
          minimumYPositionFilterStillInViewport={
            minimumYPositionFilterStillInViewport
          }
        />
        <CertificationHomepageCriteriaList
          isFiltering={isFiltering}
          filteredCriteriaIds={filteredCriteriaIds}
          calculateCssWidthRef={calculateCssWidthRef}
        />
        <NoEvidenceUploadedModal
          isShow={isShowNoEvidenceUploadedModal}
          closeModal={() => setIsShowNoEvidenceUploadedModal(false)}
          displayedText="There are no evidence files uploaded for this certification"
        />
        <div
          ref={calculateCssWidthRef}
          style={{ width: "fit-content", visibility: "hidden" }}
        ></div>
      </div>
    );
  }

  return <Container fluid>{content}</Container>;
};

export default CertificationHomepage;
