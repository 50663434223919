import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import uniq from "lodash/uniq";
import withAuthentication from "../../HOC/withAuthentication";
import { DateTimeUtils } from "../../../services/UtilsService";
import PermissionsContext from "../../../context/PermissionsContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import {
  INITIATIVE_STATUS,
  PERMISSIONS,
  RESOURCES,
} from "../../../config/constants";
import InitiativeService from "../../../services/InitiativeService";
import LoadingSpinner from "../../common/LoadingSpinner";
import DeleteInitiativePrompt from "./DeleteInitiativePrompt";
import InputInitiative from "./InputInitiative";
import FileStorageStateContext from "../../../context/FileStorageStateContext";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import AttachmentsTooltip from "../../common/Tooltip/AttachmentsTooltip";
import LastActivityDisplay from "../../common/LastActivityDisplay";
import CopyInitiativePrompt from "./CopyInitiativePrompt";
import InitiativeListStyles from "./InitiativeListStyle";
import InitiativeNameColumnDisplay from "./InitiativeDisplayComponents";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { IconCopy, IconExternalLink, IconTrash } from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import GVDSAccordionHeader from "../../../gvds-components/Accordion/GVDSAccordionHeader";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import { useTranslation } from "react-i18next";

const InitiativeDashboard = ({ initiativeOptions, lastUpdate, onUpdate }) => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const fileStorageStateContext = useContext(FileStorageStateContext);

  const [isLoading, setIsLoading] = useState(true);
  const [activeKey, setActiveKey] = useState(null);
  const [subtopics, setSubtopics] = useState([]);

  const [initiativesInProgress, setInitiativesInProgress] = useState([]);
  const [existingInitiativeNames, setExistingInitiativeNames] = useState([]);
  const [showInputInitiativeModal, setShowInputInitiativeModal] =
    useState(false);
  const [showCopyPrompt, setShowCopyPrompt] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [currentInitiative, setCurrentInitiative] = useState(null);

  useEffect(() => {
    loadInitiativesInProgress();
  }, [fileStorageStateContext.initiativesComponentReloadFlag]);

  const loadInitiativesInProgress = () => {
    setIsLoading(true);
    const selectedInventory = userInventory.selectedInventory.get;
    if (selectedInventory && selectedInventory.type === RESOURCES.SITE) {
      Promise.all([
        InitiativeService.getActiveInitiatives(
          selectedInventory.type,
          selectedInventory.id
        ),
        InitiativeService.getAllInitiativeNames(
          selectedInventory.type,
          selectedInventory.id
        ),
      ])
        .then(([activeInitiatives, allInitiativeNames]) => {
          setInitiativesInProgress(activeInitiatives);
          setExistingInitiativeNames(allInitiativeNames);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load Active Initiatives.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    loadInitiativesInProgress();
  }, [userInventory.selectedInventory.get, lastUpdate]);

  useEffect(() => {
    const initiativeSubtopics = uniq(
      initiativesInProgress.map((init) => init["subtopic"])
    ).sort(InitiativeService.initiativeSubtopicSortFn);

    setSubtopics(initiativeSubtopics);

    if (
      (!activeKey && initiativeSubtopics.length > 0) ||
      initiativeSubtopics.indexOf(activeKey) < 0
    ) {
      setActiveKey(initiativeSubtopics[0]);
    }
  }, [initiativesInProgress]);

  const onCopy = () => {
    setCurrentInitiative(
      currentInitiative.copyInitiative(existingInitiativeNames)
    );
    setShowInputInitiativeModal(true);
  };

  if (isLoading) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {initiativesInProgress.length === 0 && (
        <div className="table__no_content">
          <p>{t("data-management.initiatives.dashboard.no-content")}</p>
        </div>
      )}
      {subtopics.map((subtopicName) => {
        const initiativesForSubtopic = initiativesInProgress
          .filter((init) => init["subtopic"] === subtopicName)
          .sort((a, b) => a.name.localeCompare(b.name));

        return (
          <Accordion
            key={subtopicName}
            defaultActiveKey={activeKey}
            activeKey={activeKey === subtopicName ? activeKey : null}
          >
            <Accordion.Item eventKey={subtopicName}>
              <GVDSAccordionHeader
                isExpanded={activeKey === subtopicName}
                title={subtopicName}
                count={initiativesForSubtopic.length}
                onClick={() =>
                  activeKey === subtopicName
                    ? setActiveKey(null)
                    : setActiveKey(subtopicName)
                }
              />
              <Accordion.Body>
                <GVDSTableDisplay>
                  <colgroup>
                    <col style={InitiativeListStyles.name} />
                    <col style={InitiativeListStyles.trackingCriteria} />
                    <col style={InitiativeListStyles.period} />
                    <col style={InitiativeListStyles.notes} />
                    <col style={InitiativeListStyles.lastUpdated} />
                    <col style={InitiativeListStyles.status} />
                    <col style={InitiativeListStyles.actions} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{t("data-management.initiatives.label-name")}</th>
                      <th>
                        {t(
                          "data-management.initiatives.label-tracking-criteria"
                        )}
                      </th>
                      <th>{t("shared-input-label.period")}</th>
                      <th>{t("data-management.initiatives.label-notes")}</th>
                      <th>{t("shared-table-header.last-updated-by")}</th>
                      <th>{t("shared-input-label.status")}</th>
                      <th className="gvds-table-cell__actions-header">
                        {t("shared-input-label.actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {initiativesForSubtopic.map((init) => {
                      const labelColor =
                        init.status === INITIATIVE_STATUS.PLANNED
                          ? StatusLabel.Colors.blue
                          : init.status === INITIATIVE_STATUS.IN_PROGRESS
                          ? StatusLabel.Colors.yellow
                          : init.status === INITIATIVE_STATUS.COMPLETED
                          ? StatusLabel.Colors.green
                          : init.status === INITIATIVE_STATUS.CANCELLED
                          ? StatusLabel.Colors.gray
                          : StatusLabel.Colors.gray;

                      const statusLabel = (
                        <StatusLabel color={labelColor}>
                          {init.status}
                        </StatusLabel>
                      );

                      return (
                        <tr
                          key={init.id}
                          className="is-clickable"
                          onClick={() => {
                            setCurrentInitiative(init);
                            setShowInputInitiativeModal(true);
                          }}
                        >
                          <td>
                            <InitiativeNameColumnDisplay initiative={init} />
                          </td>
                          <td>{init.trackingCriteria.join(", ")}</td>
                          <td>
                            <>
                              {DateTimeUtils.formatLocalDate(init.periodFrom)} -
                              <br />
                              {init.periodTo ? (
                                DateTimeUtils.formatLocalDate(init.periodTo)
                              ) : (
                                <span className="color-gray56 fst-italic">
                                  {t(
                                    "data-management.initiatives.label-no-end-date"
                                  )}
                                </span>
                              )}
                            </>
                          </td>
                          <td>
                            <>
                              {init.comments && (
                                <span className="mx-1">
                                  <CommentTooltip comment={init.comments} />
                                </span>
                              )}
                              {init.attachedFiles &&
                                init.attachedFiles.length > 0 && (
                                  <span className="mx-1">
                                    <AttachmentsTooltip
                                      attachmentNames={init.attachedFiles.map(
                                        (file) => file.name
                                      )}
                                    />
                                  </span>
                                )}
                            </>
                          </td>
                          <td>
                            {init.lastActivity && (
                              <LastActivityDisplay
                                lastActivity={init.lastActivity}
                              />
                            )}
                          </td>
                          <td>{statusLabel}</td>
                          <td>
                            <div className="gvds-table-cell__actions-container">
                              <GVDSIconButton
                                key="view-initiative"
                                className="initiative-action__view-details"
                                variant={iconButtonVariant.tertiary}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentInitiative(init);
                                  setShowInputInitiativeModal(true);
                                }}
                                icon={<GVDSIcon Icon={IconExternalLink} />}
                                tooltipText={t("shared.view-details")}
                              />
                              {!permissionCtx.isLoadingPermissions &&
                                permissionCtx.permissions[
                                  PERMISSIONS.INITIATIVE_RECORD_CREATE
                                ] && (
                                  <GVDSIconButton
                                    key="copy-initiative"
                                    className="initiative-action__copy"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setCurrentInitiative(init);
                                      setShowCopyPrompt(true);
                                    }}
                                    variant={iconButtonVariant.tertiary}
                                    icon={<GVDSIcon Icon={IconCopy} />}
                                    tooltipText={t("shared.copy")}
                                  />
                                )}
                              {!permissionCtx.isLoadingPermissions &&
                                permissionCtx.permissions[
                                  PERMISSIONS.INITIATIVE_RECORD_DELETE
                                ] && (
                                  <GVDSIconButton
                                    key="delete-initiative"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setCurrentInitiative(init);
                                      setShowDeletePrompt(true);
                                    }}
                                    variant={iconButtonVariant.destructive}
                                    icon={<GVDSIcon Icon={IconTrash} />}
                                    tooltipText={t("shared.delete")}
                                  />
                                )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </GVDSTableDisplay>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
      <InputInitiative
        currentInitiative={currentInitiative}
        initiativeOptions={initiativeOptions}
        onSuccess={onUpdate}
        show={showInputInitiativeModal}
        setShow={setShowInputInitiativeModal}
        onCloseModal={() => setCurrentInitiative(null)}
        onTriggerCopy={() => setShowCopyPrompt(true)}
      />
      <CopyInitiativePrompt
        show={showCopyPrompt}
        closeModal={() => setShowCopyPrompt(false)}
        onCopy={onCopy}
      />
      <DeleteInitiativePrompt
        show={showDeletePrompt}
        onCloseModal={() => {
          setShowDeletePrompt(false);
          setCurrentInitiative(null);
        }}
        initiative={currentInitiative}
        onSuccess={onUpdate}
      />
    </>
  );
};
export default withAuthentication(InitiativeDashboard);
