import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  BEST_PRACTICES_ASSESS,
  BEST_PRACTICES_GUIDANCE,
  BEST_PRACTICES_GUIDANCE_ARTICLE,
} from "../../config/ROUTES_NAME";
import withAuthentication from "../HOC/withAuthentication";
import BestPracticesAssess from "./BestPracticesAssess";
import BestPracticesGuidance from "./BestPracticesGuidance";
import ViewGuidanceArticle from "./GuidanceArticles/ViewGuidanceArticle";
import { PageWrapper } from "../../gvds-components/Nav/PageWrapper/PageWrapper";

const BestPractices = () => {
  return (
    <Switch>
      <Route
        exact
        path={BEST_PRACTICES_ASSESS}
        render={() => (
          <PageWrapper configRouteName={BEST_PRACTICES_ASSESS}>
            <BestPracticesAssess />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={BEST_PRACTICES_GUIDANCE}
        render={() => (
          <PageWrapper configRouteName={BEST_PRACTICES_GUIDANCE}>
            <BestPracticesGuidance />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={BEST_PRACTICES_GUIDANCE_ARTICLE}
        render={() => (
          <PageWrapper configRouteName={BEST_PRACTICES_GUIDANCE_ARTICLE}>
            <ViewGuidanceArticle />
          </PageWrapper>
        )}
      />
    </Switch>
  );
};

export default withAuthentication(BestPractices);
