import { SURVEY_QUESTION_TYPE, SURVEY_STATUS } from "../../../config/constants";
import { ANSWERING_SAVE_STATUS } from "../../common/AnswerSaveStatus";

export class SurveyAssessmentQuestionModel {
  constructor(
    questionId,
    questionText,
    questionType,
    description,
    options,
    answers,
    writtenAnswer,
    comment,
    childQuestions = [],
    bestPracticeQuestionId = null,
    saveStatus = ANSWERING_SAVE_STATUS.LOADED_FROM_API
  ) {
    this.questionId = questionId;
    this.questionText = questionText;
    this.questionType = questionType;
    this.description = description;
    this.options = options;
    this.answers = answers;
    this.writtenAnswer = writtenAnswer;
    this.comment = comment;
    this.childQuestions = childQuestions;
    this.bestPracticeQuestionId = bestPracticeQuestionId;
    this.saveStatus = saveStatus;
  }

  isAnswered() {
    if (
      this.questionType === SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE ||
      this.questionType === SURVEY_QUESTION_TYPE.CHECKBOXES ||
      this.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE ||
      this.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
    ) {
      return this.answers && this.answers.length > 0;
    } else if (this.questionType === SURVEY_QUESTION_TYPE.SHORT_ANSWER) {
      return this.writtenAnswer;
    } else if (this.questionType === SURVEY_QUESTION_TYPE.QUESTION_GROUP) {
      return this.childQuestions.filter((cq) => !cq.isAnswered()).length === 0;
    }
  }

  hasNoComment() {
    return !this.comment || this.comment.length === 0;
  }

  isSaved() {
    return (
      this.saveStatus === ANSWERING_SAVE_STATUS.LOADED_FROM_API ||
      this.saveStatus === ANSWERING_SAVE_STATUS.SAVED
    );
  }

  static fromDTO(questionDTO) {
    const model = new SurveyAssessmentQuestionModel(
      questionDTO.question_id,
      questionDTO.question_text,
      questionDTO.question_type,
      questionDTO.description,
      questionDTO.options,
      questionDTO.answers,
      questionDTO.written_answer,
      questionDTO.comment
    );

    if (model.questionType === SURVEY_QUESTION_TYPE.QUESTION_GROUP) {
      model.childQuestions = questionDTO.child_questions.map((cq) =>
        SurveyAssessmentQuestionModel.fromDTO(cq)
      );
    } else if (
      model.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE ||
      model.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
    ) {
      model.bestPracticeQuestionId = questionDTO.best_practice_question_id;
    }

    return model;
  }
}

export class SurveyAssessmentModel {
  constructor(
    id,
    surveyId,
    name,
    status,
    assessmentComplete,
    description,
    deadline,
    instructions,
    thankYouMessage
  ) {
    this.id = id;
    this.surveyId = surveyId;
    this.name = name;
    this.status = status;
    this.assessmentComplete = assessmentComplete;
    this.description = description;
    this.deadline = deadline;
    this.instructions = instructions;
    this.thankYouMessage = thankYouMessage;
    this.questions = [];
  }

  isStatusOpen() {
    return this.status === SURVEY_STATUS.OPEN;
  }

  getNoOfAnsweredQn() {
    return this.questions.filter((q) => q.isAnswered()).length;
  }

  isAllQuestionsAnswered() {
    return (
      this.getNoOfAnsweredQn() === this.questions.length &&
      this.questions.every((q) => q.isSaved())
    );
  }

  static fromDTO(surveyAssessmentDTO) {
    const surveyAssessmentModel = new SurveyAssessmentModel(
      surveyAssessmentDTO.id,
      surveyAssessmentDTO.survey_id,
      surveyAssessmentDTO.name,
      surveyAssessmentDTO.status,
      surveyAssessmentDTO.assessment_complete,
      surveyAssessmentDTO.description,
      surveyAssessmentDTO.deadline,
      surveyAssessmentDTO.instructions,
      surveyAssessmentDTO.thank_you_message
    );
    surveyAssessmentModel.questions = surveyAssessmentDTO.questions.map((q) =>
      SurveyAssessmentQuestionModel.fromDTO(q)
    );

    return surveyAssessmentModel;
  }
}
