export default class EnvironmentalDataRecordModel {
  constructor(facilityId, facilityName, readingJson) {
    this.id = readingJson["id"];
    this.subtopic = readingJson["environmental_subtopic"];
    this.type = readingJson["type"];
    this.facilityId = facilityId;
    this.facilityName = facilityName;
    this.meter = readingJson["meter"];
    this.periodFrom = readingJson["period_from"];
    this.periodTo = readingJson["period_to"];
    this.usage = readingJson["usage"];
    this.unit = readingJson["unit"];
    this.cost = readingJson["cost"];
    this.currency = readingJson["currency"];
    this.comments = readingJson["comments"];
  }

  get subtopic_name() {
    return this.subtopic?.name;
  }

  get meter_name() {
    return this.meter?.name;
  }

  get type_name() {
    return this.type?.name;
  }

  get unit_name() {
    return this.unit?.name;
  }

  get currency_name() {
    return this.currency?.name;
  }
}
