import { GVDSColors } from "../../../styles/gvds-colors";

export const DashboardColorPalette = [
  GVDSColors.data1,
  GVDSColors.data2,
  GVDSColors.data3,
  GVDSColors.data4,
  GVDSColors.data5,
  GVDSColors.data6,
  GVDSColors.data7,
  GVDSColors.data8,
  GVDSColors.data9,
  GVDSColors.data10,
];

export const dashboardChartBackgroundColor = GVDSColors.white