import React, { useState } from "react";
import {
  FormFieldStatusMetadata,
  getDateLabel,
  getMinEndDate,
  GVDSFormErrorMessage,
  isEndDateBeforeStartDate,
} from "./GVDSFormShared";
import GVDSFormSingleMonthPicker from "./GVDSFormSingleMonthPicker";
import moment from "moment";

const GVDSFormStartEndMonthPicker = ({
  className,
  startMonth,
  startMonthLabel,
  startPlaceholderText = "From",
  minStartMonth,
  maxStartMonth,
  endMonth,
  endMonthLabel,
  endPlaceholderText = "To",
  minEndMonth,
  maxEndMonth,
  onChange,
  dateFormat = "MMM yyyy",
  disabledStartMonth = false,
  disabledEndMonth = false,
  statusMetadata = FormFieldStatusMetadata.getDefault(),
  isPortalling = true,
}) => {
  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);

  const gvdsClassNames =
    "gvds-form__start-end-monthpicker__container " + (className ?? "");
  const statusMetadataWithoutMessage = new FormFieldStatusMetadata(
    statusMetadata.state
  );
  const shouldShowDateLabel = startMonthLabel || endMonthLabel;

  const onStartMonthChange = (inputMonth) => {
    if (isEndDateBeforeStartDate(inputMonth, endMonth)) {
      onChange(inputMonth, null);
    } else {
      onChange(inputMonth, endMonth);
    }
  };

  const onEndMonthChange = (inputtedMonth) => {
    let updatedEndMonth = null;
    if (inputtedMonth !== null) {
      const momentDate = moment(inputtedMonth);
      if (momentDate.isValid()) {
        updatedEndMonth = momentDate.endOf("month").toDate();
      }
    }
    onChange(startMonth, updatedEndMonth);
  };

  return (
    <>
      <div className={gvdsClassNames}>
        <div className="start-month__container">
          {shouldShowDateLabel && getDateLabel(startMonthLabel, endMonthLabel)}
          <GVDSFormSingleMonthPicker
            placeholder={startPlaceholderText}
            selected={startMonth}
            onChange={onStartMonthChange}
            dateFormat={dateFormat}
            disabled={disabledStartMonth}
            onCalendarOpen={() => setIsStartCalendarOpen(true)}
            onCalendarClose={() => setIsStartCalendarOpen(false)}
            isFocusStyle={isStartCalendarOpen}
            className="start-date"
            statusMetadata={statusMetadataWithoutMessage}
            minMonth={minStartMonth}
            maxMonth={maxStartMonth}
            isPortalling={isPortalling}
          />
        </div>
        <div className="end-month__container">
          {shouldShowDateLabel && getDateLabel(endMonthLabel, startMonthLabel)}
          <GVDSFormSingleMonthPicker
            placeholder={endPlaceholderText}
            selected={endMonth}
            onChange={onEndMonthChange}
            dateFormat={dateFormat}
            disabled={disabledEndMonth}
            onCalendarOpen={() => setIsEndCalendarOpen(true)}
            onCalendarClose={() => setIsEndCalendarOpen(false)}
            isFocusStyle={isEndCalendarOpen}
            className="end-date"
            statusMetadata={statusMetadataWithoutMessage}
            isUseIcon={false}
            minMonth={getMinEndDate(startMonth, minEndMonth)}
            maxMonth={maxEndMonth}
            openToDate={
              endMonth && !isEndDateBeforeStartDate(startMonth, endMonth)
                ? endMonth
                : startMonth
            }
            isPortalling={isPortalling}
          />
        </div>
      </div>
      {statusMetadata.isNotNormal() && statusMetadata.message && (
        <GVDSFormErrorMessage
          status={statusMetadata.state}
          errorMsg={statusMetadata.message}
        />
      )}
    </>
  );
};

export default GVDSFormStartEndMonthPicker;
