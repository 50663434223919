import axios from "axios";
import { API_URL } from "../config/api-config";

export default class GeocodingService {
  static isAddressComplete(
    streetAddress,
    zipCode,
    city,
    stateOrProvince,
    countryName
  ) {
    const isAddressValid = streetAddress && streetAddress.length > 0;

    const isCityValid = city && city.length > 0;

    const isZipCodeValid = zipCode && zipCode.length > 0;

    const isCountryValid = countryName && countryName.length > 0;

    return isAddressValid && isCityValid && isZipCodeValid && isCountryValid;
  }

  static async getLatLongFromAddress(
    site_name,
    street_address,
    zip_code,
    city,
    state_or_province,
    country_name
  ) {
    try {
      const payload = {
        site_name,
        street_address,
        zip_code,
        city,
        state_or_province,
        country_name,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/services/geocoding`,
        payload
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
