import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ToastContext from "../../../context/ToastContext";
import { MultipleFilesUploader } from "../../common/FileAttachments";
import HelpArticleAdminService from "../../../services/HelpArticleAdminService";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVFormGroup from "../../common/GVFormGroup";
import PortalModuleLinkService from "../../../services/ReferenceDataServices/PortalModuleLinkService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSFormTextArea from "../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormMultiSelect from "../../../gvds-components/Forms/GVDSFormMultiSelect";
import { GuideContentTypeUtils } from "../../../services/UtilsService";
import {
  ARTICLE_CONTENT_MAX_FILE_SIZE_IN_MB,
  ARTICLE_CONTENT_TYPE,
} from "../../../config/constants";

const HelpArticleForm = ({
  show,
  setShow,
  helpArticleOptions,
  currentArticle = null,
  onSuccess,
}) => {
  const toastContext = useContext(ToastContext);

  const handleClose = () => {
    setShow(false);
    resetForm();
  };

  const resetForm = () => {
    setIsValidated(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isEditExisting, setIsEditExisting] = useState(false);

  const [title, setTitle] = useState("");
  const [articleType, setArticleType] = useState(null);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(null);
  const [relevantArticles, setRelevantArticles] = useState([]);
  const [relevantPortalModules, setRelevantPortalModules] = useState([]);
  const [files, setFiles] = useState([]);

  const [availableTypes, setAvailableTypes] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableHelpArticles, setAvailableHelpArticles] = useState([]);
  const [availablePortalModules, setAvailablePortalModules] = useState([]);

  useEffect(() => {
    if (helpArticleOptions) {
      setAvailableTypes(helpArticleOptions.article_types);
      setAvailableCategories(helpArticleOptions.categories);
      setAvailableHelpArticles(helpArticleOptions.help_articles);
      setAvailablePortalModules(
        helpArticleOptions.portal_modules.sort(
          PortalModuleLinkService.PortalModuleLinkSortFn
        )
      );
    }
  }, [helpArticleOptions]);

  useEffect(() => {
    if (currentArticle) {
      setIsEditExisting(true);
      setTitle(currentArticle.title);
      setArticleType(currentArticle.articleType);
      setDescription(currentArticle.description);
      setCategory(currentArticle.category);
      setRelevantArticles(currentArticle.relevantArticles);
      setRelevantPortalModules(currentArticle.relevantPortalModules);
      currentArticle.file ? setFiles([currentArticle.file]) : setFiles([]);
    } else {
      setIsEditExisting(false);
      setTitle("");
      setArticleType(null);
      setDescription("");
      setCategory(null);
      setRelevantArticles([]);
      setRelevantPortalModules([]);
      setFiles([]);
    }
  }, [currentArticle]);

  useEffect(() => {
    if (helpArticleOptions) {
      if (currentArticle) {
        setAvailableHelpArticles(
          helpArticleOptions.help_articles.filter(
            (a) => a.id !== currentArticle.id
          )
        );
      } else {
        setAvailableHelpArticles(helpArticleOptions.help_articles);
      }
    }
  }, [currentArticle, helpArticleOptions]);

  const changeContentFile = (selectedFiles) => {
    setFiles(selectedFiles);
    if (selectedFiles.length > 0) {
      const filesNotDeleted = selectedFiles.filter(
        (file) => !file.isToBeDeleted()
      );
      if (filesNotDeleted.length === 1) {
        const file = filesNotDeleted[0];
        let contentFileType;
        if (GuideContentTypeUtils.isPdf(file)) {
          contentFileType = getContentType(ARTICLE_CONTENT_TYPE.PDF);
        } else if (GuideContentTypeUtils.isVideo(file)) {
          contentFileType = getContentType(ARTICLE_CONTENT_TYPE.VIDEO);
        } else {
          contentFileType = null;
        }
        setArticleType(contentFileType);
      } else {
        setArticleType(null);
      }
    } else {
      setArticleType(null);
    }
  };

  const isTitleValid = () => {
    return title && title.length > 0;
  };

  const isArticleTypeValid = () => {
    return articleType !== null;
  };

  const isCategoryValid = () => {
    return category !== null;
  };

  const isDescriptionValid = () => {
    return description && description.length > 0;
  };

  const isFileValid = () => {
    const filesNotDeleted = files.filter((file) => !file.isToBeDeleted());

    return (
      filesNotDeleted.length === 1 &&
      (GuideContentTypeUtils.isPdf(filesNotDeleted[0]) ||
        GuideContentTypeUtils.isVideo(filesNotDeleted[0]))
    );
  };

  const saveHelpArticle = () => {
    setIsValidated(true);

    if (
      isTitleValid() &&
      isArticleTypeValid() &&
      isCategoryValid() &&
      isDescriptionValid() &&
      isFileValid()
    ) {
      if (isEditExisting) {
        setIsLoading(true);

        const newFiles = files.filter((file) => file.isNew());
        const removedFiles = files.filter((file) => file.isToBeDeleted());

        HelpArticleAdminService.editHelpArticle(
          currentArticle.id,
          title,
          articleType,
          category,
          description,
          relevantArticles.map((a) => a.id),
          relevantPortalModules.map((m) => m.id),
          newFiles.length > 0 ? newFiles[0] : null,
          removedFiles.length > 0 ? removedFiles[0] : null
        )
          .then(() => {
            toastContext.addSuccessToast(
              <span>Guide successfully edited.</span>
            );
            setIsLoading(false);
            handleClose();
            if (onSuccess) {
              onSuccess();
            }
          })
          .catch(() => {
            toastContext.addFailToast(<span>Failed to edit guide.</span>);
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        HelpArticleAdminService.createHelpArticle(
          title,
          articleType,
          category,
          description,
          relevantArticles.map((a) => a.id),
          relevantPortalModules.map((m) => m.id),
          files[0]
        )
          .then(() => {
            toastContext.addSuccessToast(
              <span>Guide successfully created.</span>
            );
            setIsLoading(false);
            handleClose();
            if (onSuccess) {
              onSuccess();
            }
          })
          .catch(() => {
            toastContext.addFailToast(<span>Failed to create guide.</span>);
            setIsLoading(false);
          });
      }
    }
  };

  const getTitle = () => {
    if (isEditExisting) {
      return "Edit Guide";
    } else {
      return "Create Guide";
    }
  };

  const getContentType = (contentType) => {
    return availableTypes.find((obj) => obj.name === contentType);
  };

  let modalContent;

  if (isLoading) {
    modalContent = <LoadingSpinner />;
  } else {
    modalContent = (
      <>
        <Row>
          <Col sm={8}>
            <GVFormGroup controlId="title">
              <Form.Label>Title</Form.Label>
              <GVDSFormField
                name="title"
                value={title}
                onInput={(value) => setTitle(value)}
                statusMetadata={
                  isValidated && !isTitleValid()
                    ? FormFieldStatusMetadata.getError("Title is required")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
          <Col sm={4}>
            <GVFormGroup controlId="articleType">
              <Form.Label>Guide Type</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Guide Type"
                options={availableTypes.map((articleType) => {
                  return { value: articleType.id, label: articleType.name };
                })}
                value={
                  articleType
                    ? {
                        value: articleType.id,
                        label: articleType.name,
                      }
                    : null
                }
                disabled
                statusMetadata={
                  isValidated && !isArticleTypeValid()
                    ? FormFieldStatusMetadata.getError(
                        "Please choose file with accepted format/extension"
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <GVFormGroup>
          <Form.Label>Attach a File</Form.Label>
          <span className="mx-2 optional-form-label">
            (acceptable formats: PDF, mp4)
          </span>
          <MultipleFilesUploader
            files={files}
            setFiles={changeContentFile}
            maxFileSizeInMB={ARTICLE_CONTENT_MAX_FILE_SIZE_IN_MB}
            useS3PresignedURL={true}
          />
          {isValidated && !isFileValid() && (
            <div className="manual-invalid-feedback">
              Require 1 file of extension PDF or MP4.
            </div>
          )}
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Description</Form.Label>
          <div>
            Use keywords as much as possible to facilitate document search.
          </div>
          <GVDSFormTextArea
            rows={2}
            name="description"
            value={description}
            onInput={(value) => setDescription(value)}
            statusMetadata={
              isValidated && !isDescriptionValid()
                ? FormFieldStatusMetadata.getError("Description is required")
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Category</Form.Label>
          <GVDSFormSingleSelect
            placeholder="Select a Category"
            isSearchable={true}
            options={availableCategories.map((category) => {
              return { value: category.id, label: category.name };
            })}
            value={
              category
                ? {
                    value: category.id,
                    label: category.name,
                  }
                : null
            }
            onSelect={(selected) => {
              setCategory({ id: selected.value, name: selected.label });
            }}
            statusMetadata={
              isValidated && !isCategoryValid()
                ? FormFieldStatusMetadata.getError("Please select category")
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Relevant Guides</Form.Label>
          <span className="mx-2 optional-form-label">(Optional)</span>
          <GVDSFormMultiSelect
            placeholder="Search or Select a title"
            isSearchable={true}
            value={
              relevantArticles
                ? relevantArticles.map((article) => {
                    return { value: article.id, label: article.title };
                  })
                : []
            }
            onSelect={(selected) => {
              setRelevantArticles(
                selected.map((item) => {
                  return { id: item.value, title: item.label };
                })
              );
            }}
            options={availableHelpArticles.map((article) => {
              return { value: article.id, label: article.title };
            })}
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Relevant Portal Modules</Form.Label>
          <span className="mx-2 optional-form-label">(Optional)</span>
          <GVDSFormMultiSelect
            placeholder="Search or Select a portal module"
            isSearchable={true}
            value={
              relevantPortalModules
                ? relevantPortalModules.map((module) => {
                    return { value: module.id, label: module.name };
                  })
                : []
            }
            onSelect={(selected) => {
              setRelevantPortalModules(
                selected.map((item) => {
                  return { id: item.value, name: item.label };
                })
              );
            }}
            options={availablePortalModules.map((module) => {
              return { value: module.id, label: module.name };
            })}
          />
        </GVFormGroup>
      </>
    );
  }

  return (
    <GVDSModal
      title={getTitle()}
      size={GVDSModal.Size.medium}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>{modalContent}</GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          disabled={isLoading}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={saveHelpArticle}
          disabled={isLoading}
          text="Save"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default HelpArticleForm;
