import React, { useContext, useEffect, useState } from "react";
import { WorkspaceNavProvider } from "../gvds-components/Nav/TopNavComponents/WorkspaceNavContext";
import NavWrapper from "../gvds-components/Nav/NavWrapper";
import ToastsDisplay from "../components/common/ToastsDisplay";
import FileStorage from "../components/FileStorage/FileStorage";
import FloatingAssistant from "../components/FloatingAssistant/FloatingAssistant";
import withAuthentication from "../components/HOC/withAuthentication";
import UserProfileContext, {
  LanguageContext,
  UserProfileProvider,
} from "../context/UserProfileContext";
import UserInventoryContext, {
  UserInventoryProvider,
} from "../context/UserInventoryContext";
import LoadingSpinner from "../components/common/LoadingSpinner";
import FirstTimeSetupDialog from "../components/Login/FirstTimeSetupDialog";
import { Route, Switch } from "react-router-dom";
import {
  ADMIN,
  BEST_PRACTICES,
  CERTIFICATIONS,
  DASHBOARDS,
  DATA,
  GOALS_AND_TARGETS,
  HELP,
  HOME,
  POLICY_TRACKER,
  POLICY_TRACKER_DETAILS,
  REPORT,
  SURVEYS,
  SYSTEM_TOOLBOX,
  USER_PROFILE,
} from "../config/ROUTES_NAME";
import Home from "../components/Home/Home";
import Data from "../components/Data/Data";
import Report from "../components/Report/Report";
import GoalsAndTargets from "../components/GoalsAndTargets/GoalsAndTargets";
import BestPractices from "../components/BestPractices/BestPractices";
import Surveys from "../components/Surveys/Surveys";
import Admin from "../components/Admin/Admin";
import Help from "../components/Help/Help";
import SystemToolbox from "../components/SystemToolbox/SystemToolbox";
import UserProfile from "../components/UserProfile/UserProfile";
import TestPage from "../gvds-components/TestPage";
import { PermissionsProvider } from "../context/PermissionsContext";
import { FileStorageStateProvider } from "../context/FileStorageStateContext";
import { FloatingAssistantProvider } from "../components/FloatingAssistant/FloatingAssistantContext";
import { GuidedTourProvider } from "../components/FloatingAssistant/GuidedTour/GuidedTourContext";
import { FeatureProvider } from "../context/FeatureContext";
import AnnouncementBar from "../gvds-components/AnnouncementBar/AnnouncementBar";
import { AnnouncementBarProvider } from "../gvds-components/AnnouncementBar/AnnouncementBarContext";
import { NavWrapperProvider } from "../gvds-components/Nav/NavWrapperContext";
import PolicyTracker from "../components/PolicyTracker/PolicyTracker";
import PolicyTrackerDetails from "../components/PolicyTracker/PolicyTrackerDetails";
import { PageWrapper } from "../gvds-components/Nav/PageWrapper/PageWrapper";
import { useTranslation } from "react-i18next";
import Certifications from "../components/Certifications/Certifications";
import Dashboard from "../components/Dashboard/Dashboard";

const MainPortalContent = withAuthentication(() => {
  const { i18n, ready } = useTranslation("translation", { useSuspense: false });

  const userProfileContext = useContext(UserProfileContext);
  const languageContext = useContext(LanguageContext);
  const userInventory = useContext(UserInventoryContext);

  const [showFirstTimeSetup, setShowFirstTimeSetup] = useState(false);
  const [profile, setProfile] = useState(null);

  const triggerShowTermsOfUse = async () => {
    if (
      profile &&
      profile.accepted_terms_conditions &&
      profile.accepted_privacy_policy
    ) {
      setShowFirstTimeSetup(false);
    } else {
      setShowFirstTimeSetup(true);
    }
  };

  const setLanguage = (langCode) => {
    i18n.changeLanguage(langCode);
  };

  const loadUserProfile = () => {
    userProfileContext.loadUserProfile().then(() => {
      triggerShowTermsOfUse();
    });
  };

  useEffect(() => {
    if (!userProfileContext.isLoadingUserProfile) {
      setProfile(userProfileContext.getUserProfile());
    }
    triggerShowTermsOfUse();
  }, [userProfileContext.isLoadingUserProfile]);

  useEffect(() => {
    if (!!languageContext.getLangCode() && ready) {
      setLanguage(languageContext.getLangCode());
    }
  }, [ready, languageContext]);

  if (!profile) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else if (profile) {
    if (
      !profile.accepted_terms_conditions ||
      !profile.accepted_privacy_policy
    ) {
      return (
        <FirstTimeSetupDialog
          show={showFirstTimeSetup}
          user={profile}
          onSuccess={loadUserProfile}
        />
      );
    } else if (userInventory.isInventoryEmpty.get) {
      return (
        <div className="gvds-empty-content">
          You don't have any workspace. Please contact your site admin or
          contract admin.
        </div>
      );
    } else {
      return (
        <Switch>
          <Route exact path={HOME} render={() => <Home />} />
          <Route
            exact
            path={DASHBOARDS}
            render={() => (
              <PageWrapper configRouteName={DASHBOARDS}>
                <Dashboard />
              </PageWrapper>
            )}
          />
          <Route path={DATA} render={() => <Data />} />
          <Route path={REPORT} render={() => <Report />} />
          <Route path={GOALS_AND_TARGETS} render={() => <GoalsAndTargets />} />
          <Route path={CERTIFICATIONS} render={() => <Certifications />} />
          <Route path={BEST_PRACTICES} render={() => <BestPractices />} />
          <Route path={SURVEYS} render={() => <Surveys />} />
          <Route path={ADMIN} render={() => <Admin />} />
          <Route path={HELP} render={() => <Help />} />
          <Route path={SYSTEM_TOOLBOX} render={() => <SystemToolbox />} />
          <Route exact path={USER_PROFILE} render={() => <UserProfile />} />
          <Route
            exact
            path={POLICY_TRACKER}
            render={() => (
              <PageWrapper configRouteName={POLICY_TRACKER}>
                <PolicyTracker />
              </PageWrapper>
            )}
          />
          <Route
            exact
            path={POLICY_TRACKER_DETAILS}
            render={() => (
              <PageWrapper configRouteName={POLICY_TRACKER_DETAILS}>
                <PolicyTrackerDetails />
              </PageWrapper>
            )}
          />
          <Route exact path="/to-be-removed" render={() => <TestPage />} />
        </Switch>
      );
    }
  }
});

const AuthenticatedPage = () => {
  const webpage = (
    <WorkspaceNavProvider>
      <NavWrapper>
        <MainPortalContent />
      </NavWrapper>
    </WorkspaceNavProvider>
  );

  return (
    <>
      <NavWrapperProvider>
        <AnnouncementBarProvider>
          <AnnouncementBar />
          {webpage}
        </AnnouncementBarProvider>
      </NavWrapperProvider>

      <ToastsDisplay />
      <FileStorage />
      <FloatingAssistant />
    </>
  );
};

const AuthenticatedPageWithContexts = () => {
  return (
    <UserProfileProvider>
      <FeatureProvider>
        <UserInventoryProvider>
          <PermissionsProvider>
            <FileStorageStateProvider>
              <FloatingAssistantProvider>
                <GuidedTourProvider>
                  <AuthenticatedPage />
                </GuidedTourProvider>
              </FloatingAssistantProvider>
            </FileStorageStateProvider>
          </PermissionsProvider>
        </UserInventoryProvider>
      </FeatureProvider>
    </UserProfileProvider>
  );
};

export default AuthenticatedPageWithContexts;
