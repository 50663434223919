import React from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";

const DeletePeopleRecordPrompt = ({
  noOfRecordsToBeDeleted,
  show,
  cancel,
  proceed,
  isDeleting,
}) => {
  let dataRecordsNoDisplay;

  if (isNaN(noOfRecordsToBeDeleted) || noOfRecordsToBeDeleted !== 1) {
    dataRecordsNoDisplay = `${noOfRecordsToBeDeleted} data records`;
  } else {
    dataRecordsNoDisplay = `a data record`;
  }

  return (
    <GVDSModal
      title={`You are about to delete ${dataRecordsNoDisplay}`}
      size={GVDSModal.Size.small}
      show={show}
      onHide={cancel}
    >
      <GVDSModal.Body>
        {isDeleting ? (
          <LoadingSpinner />
        ) : (
          <>
            Once you delete your data records, you will be unable to retrieve it
            again.
          </>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={cancel}
          disabled={isDeleting}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          onClick={proceed}
          disabled={isDeleting}
          text="Delete"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default DeletePeopleRecordPrompt;
