import axios from "axios";
import { API_URL } from "../../config/api-config";
import { HttpResponseService } from "../UtilsService";

export default class PerformanceReportService {
  static getReportConfig = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/config",
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static getReport = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance",
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static getBenchmark = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/benchmark",
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static getStatus = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/status",
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static downloadReport = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/download",
        {
          params,
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Performance Report.xlsx"
      );
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static getWasteDiversionRateReport = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/waste_diversion_rate",
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static getWasteDiversionRateStatus = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/waste_diversion_rate/status",
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static downloadWasteDiversionRateReport = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/waste_diversion_rate/download",
        {
          params,
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Performance Report.xlsx"
      );
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static getRenewablesRateStatus = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/renewables_rate/status",
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  }

  static getRenewablesRateReport = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/renewables_rate",
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  }

  static downloadRenewablesRateReport = async (params) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/performance/renewables_rate/download",
        {
          params,
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Performance Report.xlsx"
      );
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  }
}
