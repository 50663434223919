import { DateTimeUtils, StringUtils } from "../../../services/UtilsService";
import UserAvatar from "../UserAvatar";
import React from "react";

const ActivityLogUserMetaDisplay = ({ lastActivity }) => {
  if (!lastActivity) {
    return null;
  } else {
    let displayName;
    if (StringUtils.isNotEmpty(lastActivity["action_by"]["full_name"])) {
      displayName = lastActivity["action_by"]["full_name"];
    } else {
      displayName = lastActivity["action_by"]["email"];
    }
    return (
      <>
        <UserAvatar
          fullName={lastActivity["action_by"]["full_name"]}
          userEmail={lastActivity["action_by"]["email"]}
        />
        {displayName} •{" "}
        <span className="ms-1 lastActivityDate">
          {DateTimeUtils.formatUTCDate(lastActivity["action_on"])}
        </span>
      </>
    );
  }
};

export default ActivityLogUserMetaDisplay;
