import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCircleFilled } from "@tabler/icons-react";
import React from "react";

const JoinedBullet = ({ className }) => {
  return (
    <GVDSIcon
      Icon={IconCircleFilled}
      className={`joined-bullet ${className ?? ""}`}
    />
  );
};

export default JoinedBullet;
