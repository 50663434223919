import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import {
  DEFAULT_OPERATION_METER_NAME,
  RESOURCES,
} from "../../../../config/constants";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconBellStar, IconCirclePlus, IconEdit } from "@tabler/icons-react";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import UserInventoryContext, {
  getFacilities,
} from "../../../../context/UserInventoryContext";
import { type_hotel } from "../../../Site/SiteFacilityInformationComponents";
import { getOperationMeterCount } from "../OperationalMeterSetup";
import GVDSTableExpandableRow from "../../../../gvds-components/Table/GVDSTableExpandableRow";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";

const isApplicableFacilityWithoutOperationTypePresent = (
  treeNode,
  operationDatasets,
  operationType
) => {
  const facilities = getFacilities(treeNode);

  const facilityWithHotelType = facilities.filter((facility) => {
    return type_hotel === facility.value.type.name.toLowerCase();
  });

  const datasetsWithOpsType = operationDatasets.filter((dataset) => {
    return dataset.meters.find(
      (meter) => meter.operation_type.id === operationType.id
    );
  });
  const facilityIdsWithOpsType = datasetsWithOpsType.map(
    (dataset) => dataset.facility.id
  );
  const facilitiesWithoutSuchOpsType = facilityWithHotelType.filter(
    (facility) => facilityIdsWithOpsType.indexOf(facility.id) < 0
  );

  return facilitiesWithoutSuchOpsType.length > 0;
};

const OperationalTypeMetersDisplay = ({
  operationType,
  operationDatasets,
  onOperationMeterToggle,
  addMeterDialogForSite,
  editMeterDialog,
  displayAlertPreferencesDialog,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const meterCount = getOperationMeterCount(operationDatasets, operationType);
  const isTrackingMeter = meterCount > 0;
  const hasApplicableFacilityWithoutMeter =
    isApplicableFacilityWithoutOperationTypePresent(
      userInventory.selectedTreeNode.get,
      operationDatasets,
      operationType
    );

  const controlColumns = [
    <td className="operation-type-table__tracking">
      <Form.Check
        type="switch"
        label=""
        id={operationType.id}
        onChange={() => onOperationMeterToggle(isTrackingMeter, operationType)}
        checked={isTrackingMeter}
        className="operational-meter-setup__switch"
      />
    </td>,
  ];

  const dataColumns = [
    <td className="operation-type-table__type">{operationType.name}</td>,
    <td className="operation-type-table__definition">
      {operationType.definition}
    </td>,
  ];

  return (
    <GVDSTableExpandableRow
      isExpanded={meterCount > 0}
      toggleIsExpanded={() => {}}
      disabled={true}
      controlColumns={controlColumns}
      dataColumns={dataColumns}
      expandedBody={
        <div>
          <GVDSTableDisplay>
            <thead>
              <tr>
                <th>Meter Id</th>
                {selectedInventory.type === RESOURCES.SITE && <th>Facility</th>}
                <th>Frequency</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {operationDatasets.map((dataset) =>
                dataset.meters
                  .filter(
                    (meter) => meter.operation_type.id === operationType.id
                  )
                  .map((meter) => (
                    <tr key={meter.id}>
                      <td>{DEFAULT_OPERATION_METER_NAME}</td>
                      {userInventory.selectedInventory.get.type ===
                        RESOURCES.SITE && <td>{dataset.facility.name}</td>}
                      <td className="text-capitalize">{meter.frequency}</td>
                      <td>{meter.description}</td>
                      <td>
                        <div className="meter-table__actions__container">
                          <GVDSIconButton
                            className="btn-edit-meter"
                            variant={iconButtonVariant.tertiary}
                            icon={<GVDSIcon Icon={IconEdit} />}
                            onClick={() =>
                              editMeterDialog(meter, dataset.facility.id)
                            }
                            tooltipText="Edit"
                          />
                          <GVDSIconButton
                            className="btn-show-alert-preferences-modal"
                            onClick={() =>
                              displayAlertPreferencesDialog(
                                meter,
                                dataset.facility.id
                              )
                            }
                            variant={iconButtonVariant.tertiary}
                            icon={<GVDSIcon Icon={IconBellStar} />}
                            tooltipText="Alert Preferences"
                          />
                        </div>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </GVDSTableDisplay>
          {selectedInventory.type === RESOURCES.SITE &&
            hasApplicableFacilityWithoutMeter && (
              <div className="action-row-in-expanded-row">
                <GVDSButton
                  variant={buttonVariant.tertiary}
                  icon={<GVDSIcon Icon={IconCirclePlus} />}
                  onClick={() => addMeterDialogForSite(operationType)}
                  text="Add meter"
                />
              </div>
            )}
        </div>
      }
    />
  );
};

export default OperationalTypeMetersDisplay;
