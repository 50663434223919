import { StringUtils } from "../../services/UtilsService";
import PolicyTrackerUtils from "./PolicyTrackerUtils";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import {
  IconBuildingBank,
  IconLeaf,
  IconUsersGroup,
} from "@tabler/icons-react";
import React from "react";

export const ESGStatus = {
  environmental: "environmental",
  social: "social",
  governance: "governance",
};

export const ESGOrderIndex = {
  [ESGStatus.environmental]: 0,
  [ESGStatus.social]: 1,
  [ESGStatus.governance]: 2,
};

export const esgIcons = {
  [ESGStatus.environmental]: <GVDSIcon Icon={IconLeaf} />,
  [ESGStatus.social]: <GVDSIcon Icon={IconUsersGroup} />,
  [ESGStatus.governance]: <GVDSIcon Icon={IconBuildingBank} />,
};

export const PriorityLevel = {
  high: "high",
  medium: "medium",
  low: "low",
  info_only: "info only",
};

export const PriorityLevelOrderIndex = {
  [PriorityLevel.high]: 0,
  [PriorityLevel.medium]: 1,
  [PriorityLevel.low]: 2,
  [PriorityLevel.info_only]: 3,
};

const LegislativeStatus = {
  active: "active",
  passed: "passed",
  pending: "pending",
  upcoming: "upcoming",
  did_not_pass: "did not pass",
};

export const LegislativeStatusOrderIndex = {
  [LegislativeStatus.active]: 0,
  [LegislativeStatus.passed]: 1,
  [LegislativeStatus.pending]: 2,
  [LegislativeStatus.upcoming]: 3,
  [LegislativeStatus.did_not_pass]: 4,
};

const ComplianceObligation = {
  mandatory: "mandatory",
  voluntary: "voluntary",
  guidance: "guidance",
};

export const ComplianceObligationOrderIndex = {
  [ComplianceObligation.mandatory]: 0,
  [ComplianceObligation.voluntary]: 1,
  [ComplianceObligation.guidance]: 2,
};

const JurisdictionLevel = {
  regional_eu: "regional - eu",
  country: "country",
  state: "state",
  city: "city",
};

export const JurisdictionLevelOrderIndex = {
  [JurisdictionLevel.regional_eu]: 0,
  [JurisdictionLevel.country]: 1,
  [JurisdictionLevel.state]: 2,
  [JurisdictionLevel.city]: 3,
};

export default class PolicyModel {
  constructor({
    id,
    name,
    description,
    topics,
    priorityLevel,
    complianceObligation,
    complianceRequirements,
    jurisdictionLevel,
    region,
    country,
    state,
    city,
    sites,
    legislativeStatus,
    esgStatuses,
    keywords,
    transitionRisk,
    deadline,
    thresholdEligibilityCriteria,
    recommendedAction,
    effectiveDate,
    accurateAsOf,
    sectorFocus,
    references,
    relatedPolicies,
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.topics = topics;
    this.priorityLevel = priorityLevel;
    this.complianceObligation = complianceObligation;
    this.complianceRequirements = complianceRequirements;
    this.jurisdictionLevel = jurisdictionLevel;
    this.region = region;
    this.country = country;
    this.state = state;
    this.city = city;
    this.sites = sites;
    this.legislativeStatus = legislativeStatus;
    this.esgStatuses = esgStatuses;
    this.keywords = keywords;
    this.transitionRisk = transitionRisk;
    this.deadline = deadline;
    this.thresholdEligibilityCriteria = thresholdEligibilityCriteria;
    this.recommendedAction = recommendedAction;
    this.countryName = country?.name;
    this.siteName = sites?.map((site) => site.name);
    this.priorityLevelOrderIndex =
      PolicyTrackerUtils.getPriorityLevelOrderIndex(priorityLevel);
    this.effectiveDate = effectiveDate;
    this.accurateAsOf = accurateAsOf;
    this.sectorFocus = sectorFocus;
    this.references = references;
    this.relatedPolicies = relatedPolicies;
    this.complianceObligationOrderIndex =
      PolicyTrackerUtils.getComplianceObligationOrderIndex(
        complianceObligation
      );
    this.jurisdictionLevelOrderIndex =
      PolicyTrackerUtils.getJurisdictionLevelOrderIndex(jurisdictionLevel);
    this.topicOrderIndex = PolicyTrackerUtils.getTopicOrderIndex(topics);
    this.esgOrderIndex = PolicyTrackerUtils.getESGsOrderIndex(esgStatuses);
  }

  static fromDTO(requestDTO) {
    return new PolicyModel({
      id: requestDTO["id"],
      name: requestDTO["name"],
      description: requestDTO["description"],
      topics: requestDTO["topics"]?.map((topic) =>
        StringUtils.getTitleCaseIgnoreUpperCase(topic)
      ),
      priorityLevel:
        requestDTO["priority_level"] === "Information Only"
          ? StringUtils.getTitleCase(PriorityLevel.info_only)
          : StringUtils.getTitleCase(requestDTO["priority_level"] ?? ""),
      complianceObligation: StringUtils.getTitleCase(
        requestDTO["compliance_obligation"] ?? ""
      ),
      complianceRequirements: requestDTO["compliance_requirements"],
      jurisdictionLevel: StringUtils.getTitleCaseIgnoreUpperCase(
        requestDTO["jurisdiction_level"] ?? ""
      ),
      region: StringUtils.getTitleCase(requestDTO["region"] ?? ""),
      country: requestDTO["country"],
      state: StringUtils.getTitleCase(requestDTO["state_or_province"] ?? ""),
      city: StringUtils.getTitleCase(requestDTO["city"] ?? ""),
      sites: requestDTO["sites"],
      legislativeStatus: StringUtils.getTitleCase(
        requestDTO["legislative_status"] ?? ""
      ),
      esgStatuses: requestDTO["esg_statuses"]?.map((esg) =>
        StringUtils.getTitleCase(esg)
      ),
      keywords: requestDTO["keywords"]?.map((keyword) =>
        StringUtils.getTitleCaseIgnoreUpperCase(keyword)
      ),
      transitionRisk: requestDTO["transition_risk"] ? "Yes" : "No",
      deadline: requestDTO["deadline"],
      thresholdEligibilityCriteria:
        requestDTO["threshold_eligibility_criteria"],
      recommendedAction: requestDTO["recommended_action"],
      effectiveDate: requestDTO["effective_date"],
      accurateAsOf: requestDTO["accurate_as_of"],
      sectorFocus: requestDTO["sector_focus"],
      references: requestDTO["references"],
      relatedPolicies: requestDTO["related_policies"],
    });
  }
}
