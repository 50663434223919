import React, { useContext } from "react";
import GVDSCustomIcons from "../../../gvds-components/Icons/GVDSIconCustom";
import { DateTimeUtils } from "../../../services/UtilsService";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import BillingService from "../../../services/BillingService";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSTextButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSTextButtonWithLoadingAction";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import UserProfileContext from "../../../context/UserProfileContext";
import { isUserBillingAdminForInventory } from "./BillingSharedUtils";
import { RESOURCES } from "../../../config/constants";
import { Trans, useTranslation } from "react-i18next";

const PaymentMethodDisplay = ({ paymentMethod }) => {
  const { t } = useTranslation();

  let content;

  if (!paymentMethod) {
    content = (
      <div className="gvds-text--caption mb-3">
        {t("admin.billing.payment.no-payment-method-saved")}
      </div>
    );
  } else {
    const expiryDate = DateTimeUtils.formatLocalMonthYear(
      paymentMethod.expiryDate
    );

    content = (
      <div className="d-flex flex-row align-items-center mb-2">
        {paymentMethod.brand === "visa" ? (
          <GVDSCustomIcons.Visa className="visa-icon" />
        ) : paymentMethod.brand === "mastercard" ? (
          <GVDSCustomIcons.MasterCard className="mastercard-icon" />
        ) : null}
        <span className="ms-2 me-2">.... .... .... {paymentMethod.last4}</span>
        <span>
          <Trans i18nKey="admin.billing.payment.payment-method-expiry">
            (Expires on {{ expiryDate }})
          </Trans>
        </span>
        <GVDSCustomIcons.PoweredByStripe className="powered-by-stripe-icon" />
      </div>
    );
  }

  return content;
};

const ViewPayment = ({
  isLoading,
  paymentMethod,
  billingAdmin,
  showUpdateBillingAdminModal,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedTreeNode = userInventory.selectedTreeNode.get;
  const inventoryDetails = selectedTreeNode?.nodeValue?.value;
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const isBillingAdmin = isUserBillingAdminForInventory(user, inventoryDetails);

  const selectedInventory = userInventory.selectedInventory.get;

  const handleChangePaymentMethod = async () => {
    let resource_type = null;
    let resource_id = null;
    if (selectedInventory.type === RESOURCES.SITE) {
      resource_type = selectedInventory.type;
      resource_id = selectedInventory.id;
    } else if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      resource_type = RESOURCES.CONTRACT;
      resource_id = userInventory.selectedContractId.get;
    }
    BillingService.getUpdatePaymentMethodUrl(resource_type, resource_id)
      .then((url) => {
        window.open(url, "_blank", "noopener,noreferrer");
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to get url to change payment method</span>
        );
      });
  };

  let content;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    const billingAdminDisplay = billingAdmin ? (
      <>
        {billingAdmin.fullName ?? ""}{" "}
        <span className="billing-email">{billingAdmin.email ?? ""}</span>
      </>
    ) : (
      <span className="gvds-text--caption">
        {t("admin.billing.payment.no-billing-admin-assigned")}
      </span>
    );

    content = (
      <section className="subscription-box__container">
        <article>
          <div className="billing-payment__details">
            <div className="flex-grow-1 d-flex flex-column">
              {isBillingAdmin && (
                <PaymentMethodDisplay paymentMethod={paymentMethod} />
              )}
              <div className="mb-2">
                {t("admin.team-management.shared.billing-admin")}:{" "}
                {billingAdminDisplay}
              </div>
            </div>
            <div className="d-flex flex-column align-items-end">
              {isBillingAdmin && (
                <GVDSTextButtonWithLoadingAction
                  className="mt-1"
                  text={t("admin.billing.payment.button-change-payment-method")}
                  onClickAsyncFunc={handleChangePaymentMethod}
                />
              )}
              <GVDSTextButton
                className="mt-1"
                text={t("admin.billing.payment.button-change-billing-admin")}
                onClick={showUpdateBillingAdminModal}
              />
            </div>
          </div>
        </article>
      </section>
    );
  }

  return (
    <div>
      <div className="gvds-text--heading2 mb-3">
        {t("admin.billing.section-title-payment")}
      </div>
      {content}
    </div>
  );
};

export default ViewPayment;
