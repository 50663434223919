import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";

import withAuthentication from "../../HOC/withAuthentication";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import HCMIReportService from "../../../services/Report/HCMIReportService";
import Table from "react-bootstrap/Table";
import CreateDataRequestModal from "../../Data/Overview/DataRequest/CreateDataRequestModal";
import {
  extractPageParamsObjectFromURL,
  getRedirectURLWithCurrentParam,
} from "../../common/QueryHandler";
import { OVERVIEW_DATA } from "../../../config/ROUTES_NAME";
import {
  DATA_STATUS,
  REPORT_TYPE,
  smoothScrollBehaviour,
} from "../../../config/constants";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SafeDataService from "../../../services/SafeDataService";
import { NumberService } from "../../../services/UtilsService";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import ToastContext from "../../../context/ToastContext";
import ReportLegend from "../ReportLegend";
import GVFormGroup from "../../common/GVFormGroup";
import { useHistory, useLocation } from "react-router-dom";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import { buttonVariant } from "../../../gvds-components/Buttons/GVDSButton";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import { useTranslation } from "react-i18next";

const HCMIMetric = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const maxStartDate = moment()
    .startOf("day")
    .subtract(1, "year")
    .startOf("month")
    .toDate();
  const [startDate, setStartDate] = useState(maxStartDate);
  const [endDate, setEndDate] = useState(
    moment()
      .startOf("day")
      .subtract(1, "year")
      .add(11, "months")
      .endOf("month")
      .toDate()
  );
  const [areaUnit, setAreaUnit] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [reportConfig, setReportConfig] = useState({});
  const [missingDataTypes, setMissingDataTypes] = useState([]);
  const [showDataRequest, setShowDataRequest] = useState(false);

  const selectedInventory = userInventory.selectedInventory.get;
  const [inventoryId, setInventoryId] = useState("");

  const [estimated, setEstimated] = useState(false);
  const [hcmiReport, setHcmiReport] = useState(null);

  const reportResultScrollTargetRef = useRef();

  useEffect(() => {
    const reportParamsObj = extractPageParamsObjectFromURL(location);
    if (reportParamsObj) {
      const start = moment(reportParamsObj["start"]).toDate();
      const end = moment(reportParamsObj["end"]).toDate();
      setStartDate(start);
      setEndDate(end);
      generateReport(reportParamsObj).then();
    }
  }, [location.search]);

  useEffect(() => {
    if (
      userInventory.selectedInventory.get &&
      userInventory.selectedInventory.get.id &&
      inventoryId !== userInventory.selectedInventory.get.id
    ) {
      setInventoryId(userInventory.selectedInventory.get.id);
      setEstimated(false);
      setMissingDataTypes([]);
      setHcmiReport(null);
      HCMIReportService.getReportConfig(
        userInventory.selectedInventory.get.type,
        userInventory.selectedInventory.get.id
      ).then((config) => {
        setReportConfig(config);
        setAreaUnit(config["default_area_unit"]);
      });
    }
  }, [userInventory.selectedInventory.get]);

  const changeStartDate = (date) => {
    if (!date) return;

    setStartDate(date);

    const endDateMoment = moment(date);
    endDateMoment.startOf("day").add(11, "months").endOf("month");
    setEndDate(endDateMoment.toDate());
  };

  const getReportRequest = (enableEstimation = false) => {
    return HCMIReportService.getHCMIReportRequestParams(
      selectedInventory.id,
      selectedInventory.type,
      startDate,
      endDate,
      areaUnit,
      enableEstimation
    );
  };

  const generateReport = async ({
    resource_id,
    resource_type,
    start,
    end,
    area_unit,
    estimate,
  }) => {
    setIsLoading(true);
    setEstimated(estimate);
    setMissingDataTypes([]);
    setHcmiReport(null);

    try {
      const hcmiReport = await HCMIReportService.getHCMIReport(
        resource_id,
        resource_type,
        start,
        end,
        area_unit,
        estimate
      );
      setHcmiReport({ ...hcmiReport, area_unit });
      if (hcmiReport.status === DATA_STATUS.MISSING) {
        setMissingDataTypes(hcmiReport.errors);
      }
      if (reportResultScrollTargetRef.current) {
        reportResultScrollTargetRef.current.scrollIntoView(
          smoothScrollBehaviour
        );
      }
    } catch (error) {
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to generate report.</span>);
    }

    setIsLoading(false);
  };

  const onDataRequestCreate = () => {
    setShowDataRequest(false);
    toastContext.addSuccessToast(
      <div>
        <div>You have successfully created a data request.</div>
        <div>
          <GVDSTextButton
            onClick={() =>
              history.push(
                getRedirectURLWithCurrentParam(OVERVIEW_DATA, location)
              )
            }
            text={"Go to Data Management"}
          />
        </div>
      </div>
    );
  };

  const downloadReport = async () => {
    await HCMIReportService.downloadHCMIReportURL(getReportRequest(estimated));
  };

  const disableRunReport = () => {
    return !areaUnit;
  };

  const notificationMessages = (
    <>
      {!isLoading && missingDataTypes.length > 0 && (
        <GVDSBanner
          title={
            estimated
              ? "There was insufficient complete data available to run this report with estimated data. Download the report to view data gap details."
              : "This report was generated with data gaps. Download the report to view data gap details."
          }
          variant={GVDSBanner.Variants.error}
          className="mt-2"
        >
          {!estimated && (
            <div>
              If the data gaps identified below are missing data and should be
              entered, consider issuing a data request or attempt to run this
              report by filling up the missing data with estimated data{" "}
              <span className="me-2">
                <InfoTooltip
                  info={
                    "The system will use the average of the previous three time periods (based on the Frequency of the meter) to fill in any data gaps at a meter, if data for those 3 periods is available. Example: Meter B is missing some data. If Meter B's frequency is “Monthly”, then the previous 3 months of values will be used to estimate each month of the gap in data."
                  }
                />
              </span>
              instead.
            </div>
          )}
          <GVDSBanner.Footer>
            <GVDSBanner.FooterButton
              onClick={() => setShowDataRequest(true)}
              text="Issue a data request"
            />
            {!estimated && (
              <GVDSBanner.FooterButton
                className="ms-2"
                onClick={() => generateReport(getReportRequest(true))}
                text="Run report with estimated data"
              />
            )}
          </GVDSBanner.Footer>
        </GVDSBanner>
      )}
      {!isLoading &&
        hcmiReport &&
        hcmiReport["status"] === DATA_STATUS.ESTIMATED && (
          <GVDSBanner
            title="Data is estimated"
            variant={GVDSBanner.Variants.warning}
            className="mt-2"
          >
            Estimated data was used for the generation of this report.
          </GVDSBanner>
        )}
      {!disableRunReport() && <ReportLegend />}
    </>
  );

  let report;

  if (isLoading) {
    report = <LoadingSpinner />;
  } else if (hcmiReport) {
    report = (
      <div className="mt-3">
        <div className="d-flex flex-row align-items-center">
          <h1>
            <span className="text-capitalize">{selectedInventory.type}</span>{" "}
            HCMI
          </h1>
          <Spacer />
          <GVDSButtonWithLoadingAction
            className="mb-2"
            onClickAsyncFunc={downloadReport}
            text="Download as Excel"
            variant={buttonVariant.primary}
          />
        </div>
        <Table bordered>
          <tbody>
            <tr>
              <td>Rooms Footprint per Occupied Room</td>
              <td
                className={
                  "hcmi-report-table__number-cell" +
                  (SafeDataService.highlightMissing(hcmiReport["room"])
                    ? " missing-data"
                    : "")
                }
              >
                {NumberService.format(
                  SafeDataService.getValue(hcmiReport["room"]),
                  4,
                  4
                )}
              </td>
              <td>kgCO2e</td>
            </tr>
            <tr>
              <td>
                Meeting Space Footprint per {hcmiReport["area_unit"]} per hour
              </td>
              <td
                className={
                  "hcmi-report-table__number-cell" +
                  (SafeDataService.highlightMissing(hcmiReport["meeting"])
                    ? " missing-data"
                    : "")
                }
              >
                {NumberService.format(
                  SafeDataService.getValue(hcmiReport["meeting"]),
                  4,
                  4
                )}
              </td>
              <td>kgCO2e</td>
            </tr>
          </tbody>
        </Table>
        <div className="gv-text-12 mt-2">
          1 This report uses market-based emissions which considers market
          purchases and custom emission factors. <br />
          2 For data on market purchases, run a monthly breakdown report. <br />
          3 The Portal will use system default emission factors unless
          overridden by a custom emission factor for a given environmental type
          and year.
        </div>
      </div>
    );
  }
  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("reports.hcmi.page-title")}</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          {t("reports.hcmi.page-description")}
        </PageHeader.Description>
      </PageHeader>
      <div className="report-config">
        <div className="report-config-options">
          <GVFormGroup>
            <Row>
              <Col xl={4} lg={6}>
                <GVDSFormStartEndMonthPicker
                  startMonthLabel={<Form.Label>Report Period</Form.Label>}
                  className="hcmi-report"
                  disabledEndMonth
                  startMonth={startDate}
                  endMonth={endDate}
                  onChange={(startMonth, endMonth) =>
                    changeStartDate(startMonth)
                  }
                  maxStartMonth={maxStartDate}
                />
              </Col>
            </Row>
          </GVFormGroup>
          <Row className="d-flex flex-row align-items-center">
            <Col xl={4} lg={6}>
              <GVFormGroup>
                <Form.Label>Floor Area Unit</Form.Label>
                <GVDSFormSingleSelect
                  name="area_unit"
                  className="select__floor-area-unit"
                  value={
                    areaUnit
                      ? getOptionByValueFromIdName(
                          areaUnit,
                          reportConfig["area_units"]
                        )
                      : null
                  }
                  options={
                    reportConfig["area_units"] &&
                    reportConfig["area_units"].map((areaUnit) => {
                      return { value: areaUnit.id, label: areaUnit.name };
                    })
                  }
                  onSelect={(selectedOption) =>
                    setAreaUnit(selectedOption.value)
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>
        </div>
        <div className="report-config-footer">
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            text="Run Report"
            onClickAsyncFunc={async () =>
              await generateReport(getReportRequest(false))
            }
            disabled={disableRunReport()}
          />
        </div>
      </div>
      {notificationMessages}
      {report}
      <div ref={reportResultScrollTargetRef} />
      {showDataRequest && (
        <CreateDataRequestModal
          requestType={REPORT_TYPE.HCMI}
          startDate={startDate}
          endDate={endDate}
          missingDataTypes={missingDataTypes}
          requestParams={getReportRequest(false)}
          onModalClose={() => setShowDataRequest(false)}
          onCreate={onDataRequestCreate}
        />
      )}
    </div>
  );
};

export default withAuthentication(HCMIMetric);
