import {Link, useLocation} from "react-router-dom";
import React, {Fragment, useContext, useEffect, useState} from "react";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import {PERMISSIONS, RESOURCES, USER_STATUS} from "../../../config/constants";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import {SystemToolboxSearchKeysByPageName} from "../../../config/search-config";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../gvds-components/Table/GVDSTable";
import UserService from "../../../services/UserService";
import uniq from "lodash/uniq";
import {UserDetails} from "../../User/ListUsersView";
import {UserStatusBadge} from "../../User/UserStatusBadge";
import GVDSButton, {buttonVariant,} from "../../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import CreatePortalAdminUser from "../../User/CreatePortalAdminUser";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSIconButton, {iconButtonVariant,} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import {IconCalendarRepeat, IconUserX} from "@tabler/icons-react";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import {getRedirectURLWithCurrentParam} from "../../common/QueryHandler";
import {getViewContractDetailsPath, getViewSiteDetailsPath,} from "../../../config/ROUTES_NAME";

// Note: Inactive could mean user is enabled but not part of any contract / sites
const USER_STATUSES_CANNOT_BE_OFFBOARDED = [
  USER_STATUS.INACTIVE,
  USER_STATUS.NONEXISTENT,
];

const formatResourceRole = (resourceRole, location) => {
  if (!resourceRole.resourceType && !resourceRole.resourceId) {
    return <span>{resourceRole.roleName}</span>;
  } else {
    return (
      <span>
        <Link
          to={
            resourceRole.resourceType === RESOURCES.CONTRACT
              ? getRedirectURLWithCurrentParam(
                  getViewContractDetailsPath(resourceRole.resourceId),
                  location
                )
              : resourceRole.resourceType === RESOURCES.SITE
              ? getRedirectURLWithCurrentParam(
                  getViewSiteDetailsPath(resourceRole.resourceId),
                  location
                )
              : null
          }
        >
          {resourceRole.inventoryName}
        </Link>{" "}
        ({resourceRole.roleName})
      </span>
    );
  }
};

const AllUsersList = ({ allUsers, loadUsers, isLoadingUsers }) => {
  const location = useLocation();
  const toastContext = useContext(ToastContext);
  const permissionsCtx = useContext(PermissionsContext);

  const hasOffboardActionPermission =
    !permissionsCtx.isLoadingPermissions &&
    permissionsCtx.permissions[PERMISSIONS.PORTAL_ADMIN];

  const [isLoadingOffboardUser, setIsLoadingOffboardUser] = useState(false);
  const [userToBeOffboarded, setUserToBeOffboarded] = useState(null);
  const [showOffboardModal, setShowOffboardModal] = useState(false);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allUsers, SystemToolboxSearchKeysByPageName.users, {
    email: SORTING_TYPES.asc,
  });

  const availableResourceRoleStrings = uniq(
    allUsers.map((user) => user.getUserResourceRoleStrings).flat()
  );

  const promptOffboardUser = (user) => {
    setUserToBeOffboarded(user);
    setShowOffboardModal(true);
  };

  const handleCloseOffboardModal = () => {
    if (!isLoadingOffboardUser) {
      setShowOffboardModal(false);
      setUserToBeOffboarded(null);
    }
  };

  const offboardUser = () => {
    setIsLoadingOffboardUser(true);
    UserService.offboardUser(userToBeOffboarded.id)
      .then(() => {
        toastContext.addSuccessToast(<span>User has been offboarded.</span>);
        loadUsers();
        handleCloseOffboardModal();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to offboard user.</span>);
      })
      .finally(() => setIsLoadingOffboardUser(false));
  };

  const columns = [
    {
      header: "User Details",
      headerStyle: { width: "400px" },
      dataKey: "email",
      sortable: true,
      renderer: (user) => {
        return (
          <UserDetails
            email={user.email}
            name={user.fullName}
            position={user.position}
          />
        );
      },
    },
    {
      header: "Role",
      headerStyle: { width: "600px", maxWidth: "600px" },
      dataKey: "resourceRoles",
      renderer: (u) => {
        if (!u.resourceRoles || u.resourceRoles.length === 0) {
          return (
            <span className="gvds-text--caption-empty-state">
              No associated contracts/sites
            </span>
          );
        }

        return (
          <div>
            {u.resourceRoles.map((resourceRole, index) => {
              return (
                <Fragment key={index}>
                  {index > 0 && ", "}
                  {formatResourceRole(resourceRole, location)}
                </Fragment>
              );
            })}
          </div>
        );
      },
    },
    {
      header: "Status",
      headerStyle: { width: "150px" },
      dataKey: "status",
      sortable: true,
      renderer: (u) => <UserStatusBadge status={u.status} />,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "200px" },
      renderer: (u) => {
        return (
          <>
            <ResendUserInviteButton user={u} />
            {hasOffboardActionPermission &&
            !USER_STATUSES_CANNOT_BE_OFFBOARDED.includes(u.status) ? (
              <GVDSIconButton
                variant={iconButtonVariant.destructive}
                onClick={() => promptOffboardUser(u)}
                tooltipText="Offboard User"
                icon={<GVDSIcon Icon={IconUserX} />}
              />
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader>
        <PageHeader.Title>
          <Spacer />
          <CreatePortalAdminUser
            onCreate={() => {
              loadUsers();
            }}
          />
        </PageHeader.Title>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search by Name or Email"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(allUsers.map((q) => q.get_user_status).flat())}
          prefix="Status"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, get_user_status: filterKs })
          }
        />
        <GVDSTableCtrlMultiSelect
          width="300px"
          options={availableResourceRoleStrings}
          prefix="Inventory"
          onChange={(filterKs) => {
            setFilterKeys({
              ...filterKeys,
              getUserResourceRoleStrings: filterKs,
            });
          }}
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      {isLoadingUsers ? (
        <div style={{ paddingTop: "100px" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <GVDSTable
            columns={columns}
            dataToDisplay={currentPageData}
            startIndex={startIndex}
            sortKeys={sortKeys}
            setSortKeys={setSortKeys}
            noContent={
              allUsers.length === 0 ? (
                <div className="table__no_content">No users</div>
              ) : (
                filteredSortedData.length === 0 && (
                  <div className="table__no_content">
                    No users. Try changing your filter.
                  </div>
                )
              )
            }
          />
        </>
      )}

      <GVDSModal
        show={showOffboardModal}
        onHide={handleCloseOffboardModal}
        title="Offboard User"
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          {isLoadingOffboardUser ? (
            <LoadingSpinner />
          ) : (
            <>
              You are about to offboard a user with email{" "}
              {userToBeOffboarded?.email}. This will disable login on the user
              email, remove the user from all contract and site teams, and
              remove the user from portal admin role. Are you sure?
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseOffboardModal}
            text="Cancel"
            disabled={isLoadingOffboardUser}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={offboardUser}
            text="Yes, offboard user"
            disabled={isLoadingOffboardUser}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

const ResendUserInviteButton = ({ user }) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [newTempPassword, setNewTempPassword] = useState(null);

  useEffect(() => {
    setNewTempPassword(null);
  }, [user]);

  const resendInviteEmail = () => {
    setIsLoading(true);
    UserService.resendUserInvite(user.id)
      .then((response) => {
        setIsLoading(false);
        setNewTempPassword(response["temporary_password"]);
        toastContext.addSuccessToast(
          <span>Invitational Email was successfully re-sent.</span>
        );
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to re-send invitational email.</span>
        );
      });
  };

  if (user.status !== USER_STATUS.AWAIT_LOGIN) {
    return null;
  }

  if (isLoading) {
    return (
      <div style={{ height: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!newTempPassword) {
    return (
      <GVDSIconButton
        variant={iconButtonVariant.tertiary}
        onClick={() => {
          resendInviteEmail();
        }}
        tooltipText="Resend Invitation Email"
        icon={<GVDSIcon Icon={IconCalendarRepeat} />}
      />
    );
  } else {
    return (
      <GVFormGroup>
        <Form.Label>Temporary Password</Form.Label>
        <Form.Control
          readOnly
          disabled
          size="sm"
          type="text"
          value={newTempPassword}
        />
      </GVFormGroup>
    );
  }
};

export default AllUsersList;
