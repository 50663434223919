const InitiativeListStyles = {
  name: { minWidth: "300px" },
  subtopic: { width: "150px", maxWidth: "150px", minWidth: "150px" },
  trackingCriteria: { width: "300px" },
  period: { width: "120px", maxWidth: "120px", minWidth: "120px" },
  notes: { width: "60px", maxWidth: "60px", minWidth: "60px" },
  lastUpdated: { width: "160px", minWidth: "100px", maxWidth: "160px", wordBreak: "break-all" },
  status: { width: "110px", maxWidth: "110px", minWidth: "110px" },
  actions: { width: "80px", maxWidth: "80px", minWidth: "80px" },
};

export default InitiativeListStyles;
