import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ParentSize } from "@visx/responsive";
import PolicyTrackerUtils, {
  PriorityLevelWithTooltip,
} from "../PolicyTrackerUtils";
import { PriorityLevel } from "../PolicyModel";
import { TextCopies } from "../../../config/text-copies";
import Divider, { DividerOrientation } from "../../common/Divider";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import React from "react";
import _, { sum } from "lodash";
import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { Text } from "@visx/text";
import { GVDSColors } from "../../../styles/gvds-colors";

const getTopicCountsSortedDesc = (policies) => {
  const allTopics = policies.map((policy) => policy.topics).flat();

  const topicCountsByTopic = {};
  allTopics.forEach((topic) =>
    topicCountsByTopic[topic]
      ? (topicCountsByTopic[topic] += 1)
      : (topicCountsByTopic[topic] = 1)
  );

  const topicCounts = Object.entries(topicCountsByTopic).map(([key, value]) => {
    return {
      name: key,
      count: value,
    };
  });

  return _.orderBy(topicCounts, "count", "desc");
};

const getPriorityLevelCounts = (policies) => {
  const priorityLevelCounts = {};
  policies.forEach((policy) => {
    if (policy.priorityLevel !== null && policy.priorityLevel !== "") {
      const priorityLevel = policy.priorityLevel.toLowerCase();
      priorityLevelCounts[priorityLevel]
        ? (priorityLevelCounts[priorityLevel] += 1)
        : (priorityLevelCounts[priorityLevel] = 1);
    }
  });

  return priorityLevelCounts;
};

const getTransitionRiskCount = (policies) => {
  return policies.filter((policy) => policy.transitionRisk === "Yes").length;
};

const SummaryDoughnutChart = ({ width, height, filteredPolicies }) => {
  const thickness = 25;
  const innerWidth = width;
  const innerHeight = height;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;

  const priorityLevelCounts = getPriorityLevelCounts(filteredPolicies);

  let chartData;
  if (Object.keys(priorityLevelCounts).length > 0) {
    chartData = [
      {
        label: PriorityLevel.high,
        value: priorityLevelCounts[PriorityLevel.high],
        color: PolicyTrackerUtils.getPriorityLevelDoughnutChartColor(
          PriorityLevel.high
        ),
      },
      {
        label: PriorityLevel.medium,
        value: priorityLevelCounts[PriorityLevel.medium],
        color: PolicyTrackerUtils.getPriorityLevelDoughnutChartColor(
          PriorityLevel.medium
        ),
      },
      {
        label: PriorityLevel.low,
        value: priorityLevelCounts[PriorityLevel.low],
        color: PolicyTrackerUtils.getPriorityLevelDoughnutChartColor(
          PriorityLevel.low
        ),
      },
      {
        label: PriorityLevel.info_only,
        value: priorityLevelCounts[PriorityLevel.info_only],
        color: PolicyTrackerUtils.getPriorityLevelDoughnutChartColor(
          PriorityLevel.info_only
        ),
      },
    ];
  } else {
    chartData = [{ label: "-", value: 1, color: GVDSColors.gray2 }];
  }
  const totalChartData = sum(Object.values(priorityLevelCounts));

  return (
    <svg width={width} height={height}>
      <Group top={centerY} left={centerX}>
        <Pie
          data={chartData}
          pieValue={(d) => d.value}
          outerRadius={radius}
          innerRadius={radius - thickness}
          pieSort={null}
        >
          {({ arcs, path, pie }) => (
            <g>
              {arcs.map((arc, i) => (
                <g key={`pie-arc-${i}`}>
                  <path
                    className={`arc${i}`}
                    d={path(arc)}
                    fill={arc.data.color}
                  />
                </g>
              ))}
            </g>
          )}
        </Pie>
        <Text textAnchor="middle" className="gvds-text--label" dy={-5}>
          Total
        </Text>
        <Text textAnchor="middle" className="gvds-text--heading3" dy={15}>
          {totalChartData}
        </Text>
      </Group>
    </svg>
  );
};

const PolicyTrackerSummary = ({
  filteredPolicies,
  onPriorityLevelClick,
  onTopicClick,
}) => {
  const uniquePoliciesByName = {};
  filteredPolicies.forEach((policy) => {
    uniquePoliciesByName[policy.name] = policy;
  });
  const uniquePolicies = Object.values(uniquePoliciesByName);

  const top3Topics = getTopicCountsSortedDesc(uniquePolicies).slice(0, 3);
  const priorityLevelCounts = getPriorityLevelCounts(uniquePolicies);
  const transitionRiskCount = getTransitionRiskCount(uniquePolicies);

  return (
    <Row className="policy-tracker__summary__container">
      <Col className="policy-tracker__summary__chart__container" lg={1}>
        <ParentSize>
          {(parent) => (
            <SummaryDoughnutChart
              width={parent.width}
              height={parent.height}
              filteredPolicies={uniquePolicies}
            />
          )}
        </ParentSize>
      </Col>
      <Col>
        <Row>
          <Col
            className="policy-tracker__summary__chart-details__container"
            lg={8}
          >
            <div>
              <div className="policy-tracker__summary__priority-level-title">
                Policies by Priority Level
              </div>
              <div className="policy-tracker__summary__priority-level">
                <PriorityLevelWithTooltip
                  priorityLevel={PriorityLevel.high}
                  priorityLevelCount={
                    priorityLevelCounts[PriorityLevel.high] ?? "-"
                  }
                  info={TextCopies.PolicyTracker.priorityLevelHighDescription}
                  onClick={() => onPriorityLevelClick(PriorityLevel.high)}
                />
                <PriorityLevelWithTooltip
                  priorityLevel={PriorityLevel.medium}
                  priorityLevelCount={
                    priorityLevelCounts[PriorityLevel.medium] ?? "-"
                  }
                  info={TextCopies.PolicyTracker.priorityLevelMediumDescription}
                  onClick={() => onPriorityLevelClick(PriorityLevel.medium)}
                />
                <PriorityLevelWithTooltip
                  priorityLevel={PriorityLevel.low}
                  priorityLevelCount={
                    priorityLevelCounts[PriorityLevel.low] ?? "-"
                  }
                  info={TextCopies.PolicyTracker.priorityLevelLowDescription}
                  onClick={() => onPriorityLevelClick(PriorityLevel.low)}
                />
                <PriorityLevelWithTooltip
                  priorityLevel={PriorityLevel.info_only}
                  priorityLevelCount={
                    priorityLevelCounts[PriorityLevel.info_only] ?? "-"
                  }
                  info={
                    TextCopies.PolicyTracker.priorityLevelInfoOnlyDescription
                  }
                  onClick={() => onPriorityLevelClick(PriorityLevel.info_only)}
                />
              </div>
              <Divider
                className="policy-tracker__summary__chart-details-divider"
                orientation={DividerOrientation.horizontal}
              />
              <div className="policy-tracker__summary__transition-risk">
                <div className="gvds-text--heading4 me-1">Transition Risk</div>
                <InfoTooltip
                  info={TextCopies.PolicyTracker.transitionRiskDescription}
                />
                <span className="transition-risk-count">
                  {transitionRiskCount}
                </span>
              </div>
            </div>
          </Col>
          <div className="policy-tracker__summary__divider-container col-lg-auto">
            <div className="divider"></div>
          </div>
          <Col
            className="policy-tracker__summary__most-common-topics__container"
            lg={3}
          >
            <Row>
              <Col
                className="policy-tracker__summary__most-common-topics__title"
                lg={12}
                sm={4}
              >
                Most Common Topics
              </Col>
              <Col
                className="policy-tracker__summary__most-common-topics__content"
                lg={12}
                sm={8}
              >
                {top3Topics.length > 0
                  ? top3Topics.map((topic) => (
                      <div
                        key={topic.name}
                        onClick={() => onTopicClick(topic.name)}
                      >
                        {topic.name} ({topic.count})
                      </div>
                    ))
                  : "-"}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PolicyTrackerSummary;
