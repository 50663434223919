import {
  RESOURCES,
  SOCIAL_PERFORMANCE_GROUP_ENUM,
  TARGET_DIRECTION,
  TARGET_TOPIC,
} from "../../config/constants";
import GVFormGroup from "../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import TargetConfigParamsModel from "./TargetConfigParamsModel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SelectTargetUnit } from "./TargetFormBuilder";
import React, { useContext } from "react";
import UserInventoryContext from "../../context/UserInventoryContext";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleYearPicker from "../../gvds-components/Forms/GVDSFormSingleYearPicker";

const InputTargetStepTargetValue = ({
  title,
  topic,
  config,
  params,
  setParams,
  businessErrors,
  targetInvalid,
  hasComparisonYear,
  setHasComparisonYear,
  isInvalid,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const currentYear = new Date().getFullYear();

  if (topic === TARGET_TOPIC.FREE_FORM) {
    return null;
  }
  const getDirections = () => {
    if (hasComparisonYear) {
      return [
        {
          value: TARGET_DIRECTION.INCREASE_OF,
          label: TARGET_DIRECTION.INCREASE_OF,
        },
        {
          value: TARGET_DIRECTION.REDUCTION_OF,
          label: TARGET_DIRECTION.REDUCTION_OF,
        },
      ];
    }

    return [
      {
        value: TARGET_DIRECTION.EQUAL_TO_OR_MORE_THAN,
        label: TARGET_DIRECTION.EQUAL_TO_OR_MORE_THAN,
      },
      {
        value: TARGET_DIRECTION.EQUAL_TO_OR_LESS_THAN,
        label: TARGET_DIRECTION.EQUAL_TO_OR_LESS_THAN,
      },
    ];
  };
  return (
    <GVFormGroup>
      <div className="gv-body-2--bold gvds-color--gray6">{title}</div>
      <div className="p-3">
        <Form.Label>
          Would you like to compare your performance against a previous year?
        </Form.Label>
        <Form.Check
          id="target-with-comparison-year"
          label="Yes"
          type="radio"
          checked={hasComparisonYear === undefined ? false : hasComparisonYear}
          onChange={() => {
            setHasComparisonYear(true);
            setParams({
              ...params,
              comparison_year: null,
              direction: "",
              unit_id: TargetConfigParamsModel.getDefaultUnit(
                params["performance_group_name"],
                config
              ),
            });
          }}
        />
        <Form.Check
          id="target-without-comparison-year"
          label="No"
          type="radio"
          checked={hasComparisonYear === undefined ? false : !hasComparisonYear}
          onChange={() => {
            setHasComparisonYear(false);
            setParams({
              ...params,
              comparison_year: null,
              direction: "",
              unit_id: TargetConfigParamsModel.getDefaultUnit(
                params["performance_group_name"],
                config
              ),
            });
          }}
        />
        {hasComparisonYear !== undefined ? (
          <div className="mt-2">
            <Row>
              <Col sm={4}>
                <GVFormGroup>
                  <Form.Label>Target Direction</Form.Label>
                  <GVDSFormSingleSelect
                    isSearchable={false}
                    className="target-direction-select"
                    value={
                      params.direction
                        ? {
                            value: params.direction,
                            label: params.direction,
                          }
                        : null
                    }
                    options={getDirections()}
                    onSelect={(selected) =>
                      setParams({ ...params, direction: selected.value })
                    }
                  />
                </GVFormGroup>
              </Col>
              <Col sm={4}>
                <GVFormGroup>
                  <Form.Label>Value</Form.Label>
                  {selectedInventory.type === RESOURCES.PORTFOLIO && (
                    <div className="caption" style={{ marginTop: "8px" }}>
                      configured in the next step
                    </div>
                  )}
                  {selectedInventory.type === RESOURCES.SITE && (
                    <>
                      <GVDSFormField
                        id="target-value-input"
                        value={params.target_value}
                        onInput={(value) =>
                          setParams({ ...params, target_value: value })
                        }
                        statusMetadata={
                          targetInvalid
                            ? FormFieldStatusMetadata.getError()
                            : businessErrors?.target_value
                            ? FormFieldStatusMetadata.getError(
                                "You may not reduce your amount by more than 100%"
                              )
                            : FormFieldStatusMetadata.getDefault()
                        }
                      />
                    </>
                  )}
                </GVFormGroup>
              </Col>
              <Col sm={4}>
                <GVFormGroup>
                  <Form.Label>
                    {[
                      SOCIAL_PERFORMANCE_GROUP_ENUM.CASH_CONTRIBUTION,
                      SOCIAL_PERFORMANCE_GROUP_ENUM.IN_KIND_DONATION_VALUE,
                    ].indexOf(params["performance_group_name"]) >= 0
                      ? "Currency"
                      : "Unit"}
                  </Form.Label>
                  <SelectTargetUnit
                    topic={topic}
                    config={config}
                    hasComparisonYear={hasComparisonYear}
                    params={params}
                    setParams={setParams}
                  />
                </GVFormGroup>
              </Col>
            </Row>
            {hasComparisonYear && (
              <Row>
                <Col sm={4}>
                  <GVFormGroup>
                    <Form.Label>In comparison to the year</Form.Label>
                    <GVDSFormSingleYearPicker
                      maxYear={
                        params.target_year
                          ? params.target_year - 1
                          : currentYear - 1
                      }
                      selected={params.comparison_year}
                      onChange={(year) =>
                        setParams({ ...params, comparison_year: year })
                      }
                    />
                  </GVFormGroup>
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <div className="create-target-form__locked-content mt-2 mb-3">
            This section will appear once you have selected an option above
          </div>
        )}
        {isInvalid && (
          <div className="manual-invalid-feedback">Please complete step 3.</div>
        )}
      </div>
    </GVFormGroup>
  );
};

export default InputTargetStepTargetValue;
