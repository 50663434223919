import axios from "axios";
import { API_URL } from "../config/api-config";

export class TroubleshootDataEnergyConversionService {
  static convertUsage = async (
    env_type_id,
    from_unit_id,
    to_unit_id,
    input_value
  ) => {
    try {
      const data = {
        env_type_id,
        from_unit_id,
        to_unit_id,
        input_value,
      };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/troubleshoot_data/energy_conversion`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export class TroubleshootDataUnitConversionService {
  static convert = async (from_unit_id, to_unit_id, input_value) => {
    try {
      const data = {
        from_unit_id,
        to_unit_id,
        input_value,
      };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/troubleshoot_data/unit_conversion`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static UnitConversionSortFn = (unit1, unit2) => {
    if (unit1.unit_type < unit2.unit_type) {
      return -1;
    }
    if (unit1.unit_type > unit2.unit_type) {
      return 1;
    }

    if (unit1.name < unit2.name) {
      return -1;
    }
    if (unit1.name > unit2.name) {
      return 1;
    }

    return 0;
  };
}
