import React, { useMemo } from "react";
import { uniqueId } from "lodash";

import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import InfoTooltip from "../common/Tooltip/InfoTooltip";
import { BEST_PRACTICE_ADOPTION_LEVEL_INFO } from "../SystemToolbox/BestPractices/BestPracticeModel";
import {
  BEST_PRACTICE_ADOPTION_LEVEL,
  QUESTION_ANSWER_STATUS,
} from "../../config/constants";
import StatusLabel from "../../gvds-components/common/StatusLabel";

const getProgress = (value, total) => {
  return 100 * (value / total);
};

export const getAdoptionLevelColor = (adoptionLevel) => {
  switch (adoptionLevel) {
    case BEST_PRACTICE_ADOPTION_LEVEL.YES:
      return "green";
    case BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS:
      return "yellow";
    case BEST_PRACTICE_ADOPTION_LEVEL.NO:
      return "red";
    case BEST_PRACTICE_ADOPTION_LEVEL.NA:
      return "gray";
    default:
      return "";
  }
};

const getAdoptionLevelProgressBar = (
  adoptionLevel,
  adoptionProgresses,
  totalQn
) => {
  const progress = getProgress(adoptionProgresses[adoptionLevel], totalQn);

  if (progress > 0) {
    return (
      <ProgressBar
        className={getAdoptionLevelColor(adoptionLevel)}
        key={adoptionLevel}
        now={progress}
      />
    );
  } else {
    // to ensure rounded edge at the end
    return null;
  }
};

const BestPracticeAdoptionChart = ({
  filteredQuestions,
  onAdoptionLevelClick,
}) => {
  const adoptionProgresses = useMemo(() => {
    return filteredQuestions.reduce(
      (progressByAdoption, question) => {
        const adoptionLevel = question.adoptionLevel;
        if (!progressByAdoption[adoptionLevel]) {
          progressByAdoption[adoptionLevel] = 1;
          return progressByAdoption;
        } else {
          progressByAdoption[adoptionLevel] += 1;
          return progressByAdoption;
        }
      },
      {
        [BEST_PRACTICE_ADOPTION_LEVEL.YES]: 0,
        [BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS]: 0,
        [BEST_PRACTICE_ADOPTION_LEVEL.NO]: 0,
        [BEST_PRACTICE_ADOPTION_LEVEL.NA]: 0,
        [BEST_PRACTICE_ADOPTION_LEVEL.NO_RESPONSE]: 0,
      }
    );
  }, [filteredQuestions]);

  const totalQn =
    adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.YES] +
    adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS] +
    adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.NO] +
    adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.NO_RESPONSE];

  return (
    <div className="best-practice-adoption-chart__container guided-tour-target__best-practice__adoption-snapshot">
      <div className="best-practice-adoption-chart__header">
        <div className="gvds-text--heading4">Questions:</div>
        <div className="best-practice-adoption-chart__total-question">
          {totalQn}
        </div>
        <InfoTooltip
          info={
            <div>
              <div>
                Questions marked N/A are not part of this best practice
                snapshot.
              </div>
              <br />
              <div>
                Questions marked N/A:{" "}
                {adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.NA]}
              </div>
            </div>
          }
        />
      </div>
      <div className="best-practice-adoption-chart__progress">
        <ProgressBar>
          {getAdoptionLevelProgressBar(
            BEST_PRACTICE_ADOPTION_LEVEL.YES,
            adoptionProgresses,
            totalQn
          )}
          {getAdoptionLevelProgressBar(
            BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS,
            adoptionProgresses,
            totalQn
          )}
          {getAdoptionLevelProgressBar(
            BEST_PRACTICE_ADOPTION_LEVEL.NO,
            adoptionProgresses,
            totalQn
          )}
        </ProgressBar>
      </div>
      <div className="best-practice-adoption-chart__breakdown">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={uniqueId("adoption-label")}>
              {
                BEST_PRACTICE_ADOPTION_LEVEL_INFO[
                  BEST_PRACTICE_ADOPTION_LEVEL.YES
                ]?.definition
              }
            </Tooltip>
          }
        >
          <StatusLabel
            color={StatusLabel.Colors.green}
            onClick={() => {
              onAdoptionLevelClick([BEST_PRACTICE_ADOPTION_LEVEL.YES], []);
            }}
          >
            <div className="adoption-level__number">
              {adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.YES]}
            </div>
            <div className="adoption-level__label">Yes</div>
          </StatusLabel>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={uniqueId("adoption-label")}>
              {
                BEST_PRACTICE_ADOPTION_LEVEL_INFO[
                  BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS
                ]?.definition
              }
            </Tooltip>
          }
        >
          <StatusLabel
            color={StatusLabel.Colors.yellow}
            onClick={() => {
              onAdoptionLevelClick(
                [BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS],
                []
              );
            }}
          >
            <div className="adoption-level__number">
              {adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS]}
            </div>
            <div className="adoption-level__label">In Progress</div>
          </StatusLabel>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={uniqueId("adoption-label")}>
              {
                BEST_PRACTICE_ADOPTION_LEVEL_INFO[
                  BEST_PRACTICE_ADOPTION_LEVEL.NO
                ]?.definition
              }
            </Tooltip>
          }
        >
          <StatusLabel
            color={StatusLabel.Colors.red}
            onClick={() => {
              onAdoptionLevelClick([BEST_PRACTICE_ADOPTION_LEVEL.NO], []);
            }}
          >
            <div className="adoption-level__number">
              {adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.NO]}
            </div>
            <div className="adoption-level__label">No</div>
          </StatusLabel>
        </OverlayTrigger>
        <StatusLabel
          color={StatusLabel.Colors.gray}
          onClick={() => {
            onAdoptionLevelClick([], [QUESTION_ANSWER_STATUS.NO]);
          }}
        >
          <div className="adoption-level__number">
            {adoptionProgresses[BEST_PRACTICE_ADOPTION_LEVEL.NO_RESPONSE]}
          </div>
          <div className="adoption-level__label">No Response</div>
        </StatusLabel>
      </div>
    </div>
  );
};

export default BestPracticeAdoptionChart;
