export const languagePrefixes = {
  Chinese: "ZH",
  Spanish: "ES",
  Vietnamese: "VI",
  Indonesian: "IN",
  Thai: "TH",
  German: "DE",
  French: "FR",
  Japanese: "JA",
};

export const availableLanguages = [
  { prefix: "", name: "English" },
  { prefix: languagePrefixes.Chinese, name: "中文" },
  { prefix: languagePrefixes.Spanish, name: "Español" },
  { prefix: languagePrefixes.Vietnamese, name: "Tiếng Việt" },
  { prefix: languagePrefixes.Indonesian, name: "Bahasa Indonesia" },
  { prefix: languagePrefixes.Thai, name: "ไทย" },
  { prefix: languagePrefixes.German, name: "Deutsch" },
  { prefix: languagePrefixes.French, name: "Français" },
  { prefix: languagePrefixes.Japanese, name: "日本語" },
];
