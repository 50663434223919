import axios from "axios";
import { API_URL } from "../../config/api-config";

export class PerformanceGroupList {
  constructor(performance) {
    this.name = performance.name;
    this.subtopic_name = performance.subtopic_name;
    this.environmental_types = performance.environmental_types;
  }

  get subtopics() {
    return this.subtopic_name;
  }

  get get_environmental_types() {
    return this.environmental_types.map((env) => env.name);
  }
}

export default class PerformanceGroupService {
  static getAllPerformanceGroup = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/performance_groups"
      );
      return response.data.map(
        (performanceGroup) => new PerformanceGroupList(performanceGroup)
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}
