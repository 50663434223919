import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import { ENVIRONMENTAL_DATA_SETUP } from "../../../config/ROUTES_NAME";
import EnvironmentalDashboard from "./EnvironmentalDashboard";
import withAuthentication from "../../HOC/withAuthentication";
import EnvironmentalService from "../../../services/EnvironmentalService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import { PERMISSIONS, UNIT_ID } from "../../../config/constants";
import MarketPurchaseOffsetService from "../../../services/MarketPurchaseService";
import InputEnvironmentalDataRecordModal from "./InputEnvironmentalDataRecordModal";
import InputMarketPurchaseRecordModal from "./MarketPurchaseOffsets/InputMarketPurchaseRecordModal";
import { useHistory, useLocation } from "react-router-dom";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { IconSettings } from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import ViewEnvironmentalActivityLogs from "../../common/ActivityLog/ViewEnvironmentalActivityLogs";
import ViewAllEnvironmentalRecords from "./ViewAllEnvironmentalRecords";
import RoutePersistedTabs from "../../common/Tabs/RoutePersistedTabs";
import { useTranslation } from "react-i18next";

const Environmental = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const inventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [datasets, setDatasets] = useState([]);
  const [trackers, setTrackers] = useState([]);

  const [showMeterInputModal, setShowMeterInputModal] = useState(false);
  const [selectedInputMeter, setSelectedInputMeter] = useState(null);
  const [meterInputDatasets, setMeterInputDatasets] = useState([]);

  const [showTrackerInputModal, setShowTrackerInputModal] = useState(false);
  const [selectedTracker, setSelectedTracker] = useState({});
  const [inputTrackers, setInputTrackers] = useState([]);

  const goToSetup = () => {
    history.push(
      getRedirectURLWithCurrentParam(ENVIRONMENTAL_DATA_SETUP, location)
    );
  };

  useEffect(() => {
    loadDatasets();
    loadTrackers();
  }, [inventory]);

  const loadDatasets = () => {
    if (inventory && inventory.id) {
      setIsLoading(true);
      setDatasets([]);

      EnvironmentalService.getAllDatasets(inventory.id, inventory.type)
        .then((ds) => {
          setDatasets(ds);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load environmental data.</span>
          );
        });
    }
  };

  const loadTrackers = () => {
    if (inventory && inventory.id) {
      MarketPurchaseOffsetService.getAllTrackers(inventory.type, inventory.id)
        .then((data) => setTrackers(data))
        .catch((e) => {
          toastContext.addFailToast(
            <span>Failed to load market purchase trackers.</span>
          );
        });
    }
  };

  const onMeterInput = (meter, datasets) => {
    setMeterInputDatasets(datasets);
    setSelectedInputMeter(meter);
    setShowMeterInputModal(true);
  };

  const onTrackerInput = (tracker, inputTrackers) => {
    setSelectedTracker(tracker);
    setInputTrackers(inputTrackers);
    setShowTrackerInputModal(true);
  };

  const onTrackerRecordUpdate = () => {
    loadTrackers();
    setLastUpdate(new Date());
  };

  if (isLoading) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("data-management.environmental.page-title")}</h1>
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.ENVIRONMENTAL_METER_MANAGEMENT
            ] && (
              <>
                <Spacer />
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip>
                      {t("data-management.environmental.setup-tooltip")}
                    </Tooltip>
                  }
                >
                  <GVDSButton
                    variant={buttonVariant.secondary}
                    className="guided-tour-target__environment-data-setup"
                    onClick={() => goToSetup()}
                    icon={<GVDSIcon Icon={IconSettings} />}
                    text={t("data-management.environmental.button-setup")}
                  />
                </OverlayTrigger>
              </>
            )}
        </PageHeader.Title>
      </PageHeader>

      <RoutePersistedTabs defaultActiveKey="data-input" mountOnEnter>
        <Tab eventKey="data-input" title={t("data-management.environmental.tab-data-input")}>
          <EnvironmentalDashboard
            lastUpdate={lastUpdate}
            datasets={datasets}
            onMeterInput={onMeterInput}
            trackers={trackers}
            loadTrackers={loadTrackers}
            onTrackerInput={onTrackerInput}
          />
        </Tab>
        <Tab
          eventKey="history"
          title={t("data-management.environmental.tab-all-records")}
          tabClassName="guided-tour-target__environment-history"
        >
          <ViewAllEnvironmentalRecords
            lastUpdate={lastUpdate}
            onRecordsUpdate={() => setLastUpdate(new Date())}
            datasets={datasets}
            onMeterInput={onMeterInput}
            trackers={trackers}
            onTrackerInput={onTrackerInput}
            onTrackerRecordUpdate={onTrackerRecordUpdate}
          />
        </Tab>
        <Tab eventKey="activity-log" title={t("data-management.environmental.tab-activity-log")}>
          <ViewEnvironmentalActivityLogs lastUpdate={lastUpdate} />
        </Tab>
      </RoutePersistedTabs>
      <InputEnvironmentalDataRecordModal
        show={showMeterInputModal}
        meter={selectedInputMeter}
        datasets={meterInputDatasets}
        onClose={() => setShowMeterInputModal(false)}
        onDoneInput={() => setLastUpdate(new Date())}
      />
      <InputMarketPurchaseRecordModal
        show={showTrackerInputModal}
        selectedTracker={selectedTracker}
        trackers={inputTrackers}
        unitId={
          inputTrackers.length > 0 ? inputTrackers[0]["unit_id"] : UNIT_ID.kwh
        }
        onSave={onTrackerRecordUpdate}
        onClose={() => setShowTrackerInputModal(false)}
      />
    </div>
  );
};

export default withAuthentication(Environmental);
