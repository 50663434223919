import ShortcutsWidget from "./Widgets/ShortcutsWidget";
import HelpfulTipsWidget from "./Widgets/HelpfulTipsWidget";
import React from "react";
import Row from "react-bootstrap/Row";
import BrandNewLookWidget from "./Widgets/BrandNewLookWidget";
import Col from "react-bootstrap/Col";
import HomepageAnnouncementWidget from "./Widgets/HomepageAnnouncementWidget";

const LandingPageOverview = ({ announcements }) => {
  return (
    <>
      <Row className="landing-page__row-widget-multi-col-container">
        <Col md={8}>
          <ShortcutsWidget />
        </Col>
        <Col md={4}>
          {announcements.length > 0 ? (
            <HomepageAnnouncementWidget announcements={announcements} />
          ) : (
            <BrandNewLookWidget />
          )}
        </Col>
      </Row>
      <Row className="landing-page__row-widget-container">
        <Col>
          <HelpfulTipsWidget />
        </Col>
      </Row>
    </>
  );
};

export default LandingPageOverview;
