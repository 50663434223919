import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import UserInventoryContext from "../../../context/UserInventoryContext";
import DashboardOptionContext from "./DashboardOptionContext";
import DashboardSchemaParser from "../DashboardSchemaParser";
import moment from "moment/moment";
import { range } from "lodash";
import SourceDataHolder from "../Models/SourceDataHolder";
import { DateTimeUtils } from "../../../services/UtilsService";
import { dashboardMenuSortFn } from "../Models/MenuModels";
import { MenuTypeEnum } from "../Models/WidgetModels";
import DashboardDataLoaderManager from "../Models/DashboardDataLoaderManager";
import { dashboardSubtopic } from "../Models/DashboardDataConstant";

const DashboardDataContext = createContext(null);

const divisorValueToSubtopic = {
  "gfa-sqm": "GFA",
  "gfa-sqft": "GFA",
  "conditionedspace-sqm": "Conditioned Space",
  "conditionedspace-sqft": "Conditioned Space",
  "ops-occupiedroom": "Occupied Room",
};

class DashboardDataHolder {
  constructor() {
    this.resourceType = null;
    this.resourceId = null;

    this.subtopics = new Set();

    this.sourceDataHolder = new SourceDataHolder();

    this.hasCompareToPreviousYear = false;

    this.dashboardDataLoaderManager = new DashboardDataLoaderManager();
  }

  setResource(resourceType, resourceId) {
    this.resourceType = resourceType;
    this.resourceId = resourceId;
  }

  isSameResource(resourceType, resourceId) {
    return this.resourceType === resourceType && this.resourceId === resourceId;
  }

  reset() {
    this.subtopics = new Set();
    this.sourceDataHolder = new SourceDataHolder();
    this.hasCompareToPreviousYear = false;
  }

  addToDataToLoad(subtopic) {
    this.subtopics.add(subtopic);
  }

  resetDataToLoad() {
    this.subtopics = new Set();
  }

  addToFilter(filters) {}

  setHasCompareToPreviousYear(hasCompareToPreviousYear) {
    this.hasCompareToPreviousYear = hasCompareToPreviousYear;
  }

  getYears(dashboardSelectedConfigHolder) {
    return dashboardSelectedConfigHolder.getYearsToQueryData(
      this.hasCompareToPreviousYear
    );
  }

  async loadData(resourceType, resourceId, dashboardSelectedConfigHolder) {
    if (this.resourceType === null || this.resourceId === null) {
      this.setResource(resourceType, resourceId);
    } else if (!this.isSameResource(resourceType, resourceId)) {
      this.setResource(resourceType, resourceId);
      this.reset();
    }

    const years = this.getYears(dashboardSelectedConfigHolder);

    const subtopics = [...this.subtopics];

    if (
      Object.keys(divisorValueToSubtopic).includes(
        dashboardSelectedConfigHolder.divisorValue
      )
    ) {
      subtopics.push(
        divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue]
      );
    }

    for (const subtopic of subtopics) {
      const subtopicUnit =
        dashboardSelectedConfigHolder.selectedUnitBySubtopic[subtopic];
      this.sourceDataHolder.updateSubtopicUnit(subtopic, subtopicUnit);

      for (const year of years) {
        if (
          this.sourceDataHolder.isDataForSubtopicYearPresent(
            subtopic,
            year,
            subtopicUnit
          )
        ) {
          continue;
        }

        await this.dashboardDataLoaderManager.loadYearlyData(
          resourceType,
          resourceId,
          year,
          subtopic,
          subtopicUnit,
          this.sourceDataHolder
        );
      }
    }
  }
}

class DashboardSelectedConfigHolder {
  constructor() {
    this.sharedMenuSchema = [];
    this.nonSharedMenuSchema = [];

    const defaultStartPeriod = moment()
      .subtract(12, "months")
      .startOf("month")
      .toDate();
    const defaultEndPeriod = moment()
      .subtract(1, "months")
      .endOf("month")
      .toDate();
    const defaultIncludedMonths = range(1, 12 + 1);

    this.continuousTime = {
      startMonth: defaultStartPeriod.getMonth() + 1,
      startYear: defaultStartPeriod.getFullYear(),
      endMonth: defaultEndPeriod.getMonth() + 1,
      endYear: defaultEndPeriod.getFullYear(),
    };
    this.continuousTimeIncludedMonthsByYear = {};
    this.selectAllContinuousTimeMonthYear();

    this.comparisonTime = {
      year1: defaultEndPeriod.getFullYear() - 1,
      year2: defaultEndPeriod.getFullYear(),
    };
    this.comparisonTimeIncludedMonths = defaultIncludedMonths;

    // should populate this from backend
    this.resetUnit();
    this.divisorValue = "total";

    this.classificationGroupSelected = null;
    this.classificationValuesSelected = null;

    this.inventoryFilter = null;
  }

  resetUnit(options) {
    if (options === undefined || options === null) {
      this.selectedUnitBySubtopic = {
        Energy: "kWh",
        Water: "L",
        Waste: "kg",
        Emissions: "kgCO2e",

        GFA: "sqm",
        "Conditioned Space": "sqm",
      };
    } else {
      const defaultUnitForSubtopic = options["defaultUnitForSubtopic"];
      this.selectedUnitBySubtopic = {
        Energy: defaultUnitForSubtopic[dashboardSubtopic.energy],
        Water: defaultUnitForSubtopic[dashboardSubtopic.water],
        Waste: defaultUnitForSubtopic[dashboardSubtopic.waste],
        Emissions: defaultUnitForSubtopic[dashboardSubtopic.emissions],

        GFA: defaultUnitForSubtopic[dashboardSubtopic.gfa],
        "Conditioned Space":
          defaultUnitForSubtopic[dashboardSubtopic.conditionedSpace],
      };
    }
  }

  get menuSchema() {
    const menuSchema = [];

    if (this.sharedMenuSchema !== null) {
      menuSchema.push(...this.sharedMenuSchema);
    }

    if (this.nonSharedMenuSchema !== null) {
      menuSchema.push(...this.nonSharedMenuSchema);
    }

    return menuSchema.sort(dashboardMenuSortFn);
  }

  setSharedMenuSchema(menuSchema) {
    this.sharedMenuSchema = menuSchema;

    if (
      !menuSchema.find((m) => m["type"] === MenuTypeEnum.ClassificationSelector)
    ) {
      this.resetClassification();
    }
  }

  setNonSharedMenuSchema(menuSchema) {
    this.nonSharedMenuSchema = menuSchema;
  }

  getYearsToQueryData(isNeedPrevYear) {
    const menuTypes = this.menuSchema.map((ms) => ms["type"]);
    if (menuTypes.includes(MenuTypeEnum.ContinuousTimeFilter)) {
      const startYear = isNeedPrevYear
        ? this.continuousTime.startYear - 1
        : this.continuousTime.startYear;

      return range(startYear, this.continuousTime.endYear + 1);
    } else if (menuTypes.includes(MenuTypeEnum.ComparisonTimeFilter)) {
      return [this.comparisonTime.year1, this.comparisonTime.year2];
    } else {
      return [];
    }
  }

  getComparisonYears() {
    return [this.comparisonTime.year1, this.comparisonTime.year2];
  }

  getAllContinuousTimeMonthYear() {
    const includedMonthsByYear = {};

    const startYear = this.continuousTime.startYear;
    const endYear = this.continuousTime.endYear;

    let year = startYear;
    let month = this.continuousTime.startMonth;

    while (year <= endYear) {
      if (!(year in includedMonthsByYear)) {
        includedMonthsByYear[year] = [];
      }

      includedMonthsByYear[year].push(month);

      if (year === endYear && month === this.continuousTime.endMonth) {
        break;
      }

      month++;

      if (month > 12) {
        month = 1;
        year++;
      }
    }

    return includedMonthsByYear;
  }

  selectAllContinuousTimeMonthYear() {
    this.continuousTimeIncludedMonthsByYear =
      this.getAllContinuousTimeMonthYear();
  }

  isContinuousTime() {
    const menuTypes = this.menuSchema.map((ms) => ms["type"]);
    return menuTypes.includes(MenuTypeEnum.ContinuousTimeFilter);
  }

  isComparisonTime() {
    const menuTypes = this.menuSchema.map((ms) => ms["type"]);
    return menuTypes.includes(MenuTypeEnum.ComparisonTimeFilter);
  }

  getIncludedMonthYearForCalculation(yearSubtraction = 0) {
    const menuTypes = this.menuSchema.map((ms) => ms["type"]);
    if (menuTypes.includes(MenuTypeEnum.ContinuousTimeFilter)) {
      return Object.keys(this.continuousTimeIncludedMonthsByYear).reduce(
        (monthsByYear, currYear) => {
          monthsByYear[currYear - yearSubtraction] =
            this.continuousTimeIncludedMonthsByYear[currYear];
          return monthsByYear;
        },
        {}
      );
    } else if (menuTypes.includes(MenuTypeEnum.ComparisonTimeFilter)) {
      const includedMonthsByYear = {};
      const years = [this.comparisonTime.year1, this.comparisonTime.year2];

      years.forEach((year) => {
        includedMonthsByYear[year] = this.comparisonTimeIncludedMonths;
      });
      return includedMonthsByYear;
    }

    return {};
  }

  getContinuousTimeDisplay = () => {
    const startDateDisplay = DateTimeUtils.formatLocalMonthYear(
      new Date(
        this.continuousTime.startYear,
        this.continuousTime.startMonth - 1
      )
    );
    const endDateDisplay = DateTimeUtils.formatLocalMonthYear(
      new Date(this.continuousTime.endYear, this.continuousTime.endMonth - 1)
    );
    return `${startDateDisplay} - ${endDateDisplay}`;
  };

  getComparisonTimeDisplay = () => {
    return `${this.comparisonTime.year1} vs ${this.comparisonTime.year2}`;
  };

  getUnitForSubtopic(subtopic) {
    return this.selectedUnitBySubtopic[subtopic];
  }

  setUnitForSubtopic(subtopic, unitId) {
    this.selectedUnitBySubtopic[subtopic] = unitId;
  }

  resetClassification() {
    this.classificationGroupSelected = null;
    this.classificationValuesSelected = null;
  }

  changeClassificationGroup(group, subtopic, sourceDataHolder) {
    const classificationValuesByGroup =
      sourceDataHolder.getSubtopicClassificationValuesByGroup(subtopic);

    if (classificationValuesByGroup != null) {
      this.classificationGroupSelected = group;
      this.classificationValuesSelected = classificationValuesByGroup[group];
    } else {
      this.resetClassification();
    }
  }

  changeClassificationValues(classificationValues) {
    this.classificationValuesSelected = classificationValues;
  }

  setInventoryFilter(inventoryFilter) {
    this.inventoryFilter = inventoryFilter;
  }

  getInventoryFilterForCalculation() {
    if (this.inventoryFilter === null) {
      return null;
    }

    const siteNameFacilityNameTuples = [];

    this.inventoryFilter.forEach((siteData) => {
      siteData.facilities.forEach((facilityData) => {
        siteNameFacilityNameTuples.push([siteData.name, facilityData.name]);
      });
    });

    return siteNameFacilityNameTuples;
  }

  changeDivisorValue(divisorValue) {
    this.divisorValue = divisorValue;

    switch (divisorValue) {
      case "gfa-sqm":
      case "conditionedspace-sqm":
        this.setUnitForSubtopic(divisorValueToSubtopic[divisorValue], "sqm");
        break;
      case "gfa-sqft":
      case "conditionedspace-sqft":
        this.setUnitForSubtopic(divisorValueToSubtopic[divisorValue], "sqft");
        break;
    }
  }
}

class DashboardHolderFacade {
  constructor() {
    this.dataHolder = new DashboardDataHolder();
    this.selectedConfigHolder = new DashboardSelectedConfigHolder();
  }

  // region DataHolder

  addToDataToLoad(subtopic) {
    this.dataHolder.addToDataToLoad(subtopic);
  }

  resetDataToLoad() {
    this.dataHolder.resetDataToLoad();
  }

  addToFilter(filters) {
    this.dataHolder.addToFilter(filters);
  }

  setHasCompareToPreviousYear(hasCompareToPreviousYear) {
    this.dataHolder.setHasCompareToPreviousYear(hasCompareToPreviousYear);
  }

  async loadData(resourceType, resourceId) {
    await this.dataHolder.loadData(
      resourceType,
      resourceId,
      this.selectedConfigHolder
    );
  }

  getSubtopicClassificationValuesByGroup(subtopic) {
    return this.dataHolder.sourceDataHolder.getSubtopicClassificationValuesByGroup(
      subtopic
    );
  }

  // endregion

  // region SourceDataHolder

  get sourceDataHolder() {
    return this.dataHolder.sourceDataHolder;
  }

  isAllRequiredDataPresent(subtopics, isAffectedByOpsMetric) {
    const subtopicsIncludingOpsMetric = [...subtopics];

    if (
      isAffectedByOpsMetric &&
      this.selectedConfigHolder.divisorValue in divisorValueToSubtopic
    ) {
      subtopicsIncludingOpsMetric.push(
        divisorValueToSubtopic[this.selectedConfigHolder.divisorValue]
      );
    }

    return this.sourceDataHolder.isAllRequiredDataPresent(
      subtopicsIncludingOpsMetric,
      this.dataHolder.getYears(this.selectedConfigHolder),
      this.selectedConfigHolder
    );
  }

  // endregion

  // region SelectedConfigHolder

  resetUnit(options) {
    this.selectedConfigHolder.resetUnit(options);
  }

  get menuSchema() {
    return this.selectedConfigHolder.menuSchema;
  }

  setSharedMenuSchema(menuSchema) {
    this.selectedConfigHolder.setSharedMenuSchema(menuSchema);
  }

  setNonSharedMenuSchema(menuSchema) {
    this.selectedConfigHolder.setNonSharedMenuSchema(menuSchema);
  }

  get classificationGroupSelected() {
    return this.selectedConfigHolder.classificationGroupSelected;
  }

  get classificationValuesSelected() {
    return this.selectedConfigHolder.classificationValuesSelected;
  }

  changeClassificationGroup(group, subtopic) {
    this.selectedConfigHolder.changeClassificationGroup(
      group,
      subtopic,
      this.dataHolder.sourceDataHolder
    );
  }

  changeClassificationValues(classificationValues) {
    this.selectedConfigHolder.changeClassificationValues(classificationValues);
  }

  isContinuousTime() {
    return this.selectedConfigHolder.isContinuousTime();
  }

  getContinuousTimeDisplay() {
    return this.selectedConfigHolder.getContinuousTimeDisplay();
  }

  getAllContinuousTimeMonthYear() {
    return this.selectedConfigHolder.getAllContinuousTimeMonthYear();
  }

  isComparisonTime() {
    return this.selectedConfigHolder.isComparisonTime();
  }

  getComparisonTimeDisplay = () => {
    return this.selectedConfigHolder.getComparisonTimeDisplay();
  };

  getDashboardTimeDisplay() {
    if (this.isContinuousTime()) {
      return this.getContinuousTimeDisplay();
    } else if (this.isComparisonTime()) {
      return this.getComparisonTimeDisplay();
    } else {
      return "";
    }
  }

  // endRegion
}

export const DashboardDataProvider = ({ children }) => {
  const userInventory = useContext(UserInventoryContext);
  const currentInventory = userInventory.selectedInventory.get;

  const dashboardOptionContext = useContext(DashboardOptionContext);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isDownloadingDashboardAsImage, setIsDownloadingDashboardAsImage] =
    useState(false);
  const [isInitialisingDashboardView, setIsInitialisingDashboardView] =
    useState(true);
  const [
    isInitialisingDashboardViewTimestamp,
    setIsInitialisingDashboardViewTimestamp,
  ] = useState(new Date());

  const dashboardHolderFacade = useRef(null);
  if (dashboardHolderFacade.current === null) {
    dashboardHolderFacade.current = new DashboardHolderFacade();
  }

  const [lastDataUpdated, setLastDataUpdated] = useState(new Date());

  const [lastConfigUpdated, setLastConfigUpdated] = useState(null);

  const updateDataHolder = (dataToLoad, filters, hasCompareToPreviousYear) => {
    dashboardHolderFacade.current.addToDataToLoad(dataToLoad);
    dashboardHolderFacade.current.addToFilter(filters);

    if (
      hasCompareToPreviousYear !== undefined &&
      hasCompareToPreviousYear !== null
    ) {
      dashboardHolderFacade.current.setHasCompareToPreviousYear(
        hasCompareToPreviousYear
      );
    }
  };

  const loadData = async (currentInventory) => {
    setIsLoadingData(true);
    await dashboardHolderFacade.current.loadData(
      currentInventory.type,
      currentInventory.id
    );
  };

  useEffect(() => {
    if (
      currentInventory &&
      dashboardOptionContext.selectedDashboard &&
      lastConfigUpdated !== null
    ) {
      dashboardHolderFacade.current.resetDataToLoad();

      // Improve: Shouldn't need to be parsed again
      DashboardSchemaParser.parseConfig(
        dashboardOptionContext.selectedDashboard.dashboardSchema["widgets"],
        updateDataHolder
      );

      loadData(currentInventory).then(() => {
        setLastDataUpdated(new Date());
        setIsLoadingData(false);
      });
    }
  }, [
    currentInventory,
    dashboardOptionContext.selectedDashboard,
    lastConfigUpdated,
  ]);

  useEffect(() => {
    if (currentInventory && dashboardOptionContext.lastDashboardChange) {
      onChangingDashboard();
    }
  }, [currentInventory, dashboardOptionContext.lastDashboardChange]);

  const onChangingDashboard = () => {
    dashboardHolderFacade.current.resetUnit(
      dashboardOptionContext.selectedDashboardOptions
    );
  };

  const onUpdateConfig = () => {
    setLastConfigUpdated(new Date());
  };

  const setIsInitialisingDashboardViewWithTimestamp = (isInit) => {
    setIsInitialisingDashboardView(isInit);
    setIsInitialisingDashboardViewTimestamp(new Date());
  };

  const store = useCallback(
    {
      lastDataUpdated: lastDataUpdated,
      updateDataHolder: updateDataHolder,
      isLoadingData: isLoadingData,
      isDownloadingDashboardAsImage: isDownloadingDashboardAsImage,
      setIsDownloadingDashboardAsImage: setIsDownloadingDashboardAsImage,
      isInitialisingDashboardView: isInitialisingDashboardView,
      isInitialisingDashboardViewTimestamp:
        isInitialisingDashboardViewTimestamp,
      setIsInitialisingDashboardView:
        setIsInitialisingDashboardViewWithTimestamp,
      dashboardHolderFacade: dashboardHolderFacade.current,
      selectedConfigHolder: dashboardHolderFacade.current.selectedConfigHolder, // TODO remove, replace with facade
      onUpdateConfig: onUpdateConfig,
    },
    [
      lastDataUpdated,
      lastConfigUpdated,
      isLoadingData,
      isDownloadingDashboardAsImage,
      isInitialisingDashboardViewTimestamp,
    ]
  );

  return (
    <DashboardDataContext.Provider value={store}>
      {children}
    </DashboardDataContext.Provider>
  );
};

export default DashboardDataContext;
