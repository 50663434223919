import React from "react";
import { Route, Switch } from "react-router-dom";

import withAuthentication from "../HOC/withAuthentication";
import ViewAllHelpArticles from "./ViewAllHelpArticles";
import { HELP, VIEW_HELP_ARTICLE } from "../../config/ROUTES_NAME";
import ViewHelpArticle from "./ViewHelpArticle";

const Help = () => {
  return (
    <Switch>
      <Route exact path={HELP} render={() => <ViewAllHelpArticles />} />
      <Route
        exact
        path={VIEW_HELP_ARTICLE}
        render={() => <ViewHelpArticle />}
      />
    </Switch>
  );
};

export default withAuthentication(Help);
