import { DATA_STATUS } from "../../../config/constants";
import SafeDataService, { NO_VALUE } from "../../../services/SafeDataService";
import { divisorValueToSubtopic } from "../Filters/OperationalMetricFilter";
import {
  classificationGroups,
  classificationValues,
  dashboardSubtopic,
  rowDataMeta,
  scope3InclusionEnum,
} from "./DashboardDataConstant";
import clone from "lodash/clone";

const noWidgetConfigClassificationValue = undefined;
const noUserDefinedConfigClassification = null;

const sumData = (data, valueIdx) => {
  return data.reduce((total, row) => {
    if (row[valueIdx] === NO_VALUE) {
      return total;
    }

    if (total === null) {
      total = 0;
    }

    return total + row[valueIdx];
  }, null);
};

export const divideDataWithDivisor = (data, divisor) => {
  if (divisor === null || divisor === undefined || divisor === 0) {
    return null;
  } else {
    return data === null || data === undefined ? null : data / divisor;
  }
};

const checkHasMissingData = (data, missingStatusIdx) => {
  return data.some((row) => row[missingStatusIdx] === true);
};

const filterDataForMonthYear = (data, includedMonthYear, yearIdx, monthIdx) => {
  return data.filter(
    (row) =>
      !!includedMonthYear[row[yearIdx]] &&
      includedMonthYear[row[yearIdx]].includes(row[monthIdx])
  );
};

const filterDataForSiteFacility = (
  data,
  selectedSiteNameFacilityNameTuples,
  siteNameIdx,
  facilityNameIdx,
  isUsingSiteDataOnly = false
) => {
  if (selectedSiteNameFacilityNameTuples === null) {
    return data;
  }

  if (!isUsingSiteDataOnly) {
    const inventoryFilterAsTupleString = selectedSiteNameFacilityNameTuples.map(
      (siteNameFacilityNameTuple) => siteNameFacilityNameTuple.join(":")
    );

    return data.filter((row) =>
      inventoryFilterAsTupleString.includes(
        `${row[siteNameIdx]}:${row[facilityNameIdx]}`
      )
    );
  } else {
    const siteNameFilter = selectedSiteNameFacilityNameTuples.map(
      (siteNameFacilityNameTuple) => siteNameFacilityNameTuple[0]
    );

    return data.filter((row) => siteNameFilter.includes(row[siteNameIdx]));
  }
};

const filterAreaDataForSiteFacility = (
  data,
  selectedSiteNameFacilityNameTuples,
  siteNameIdx,
  facilityNameIdx,
  isUsingSiteDataOnly = false
) => {
  if (selectedSiteNameFacilityNameTuples === null) {
    return data;
  }

  const tuplesBySiteName = selectedSiteNameFacilityNameTuples.reduce(
    (tuplesBySiteName, siteNameFacilityNameTuple) => {
      const siteName = siteNameFacilityNameTuple[0];

      if (tuplesBySiteName[siteName] === undefined) {
        tuplesBySiteName[siteName] = [];
      }

      tuplesBySiteName[siteName].push(siteNameFacilityNameTuple);

      return tuplesBySiteName;
    },
    {}
  );

  const siteNamesWithMultipleFacilities = [];
  const tuplesWithOneFacility = [];

  Object.keys(tuplesBySiteName).forEach((siteName) => {
    const tuplesForSiteName = tuplesBySiteName[siteName];
    if (tuplesForSiteName.length > 1) {
      siteNamesWithMultipleFacilities.push(siteName);
    } else {
      tuplesWithOneFacility.push(...tuplesForSiteName);
    }
  });

  const filteredData = [];

  filteredData.push(
    ...data.filter(
      (row) =>
        siteNamesWithMultipleFacilities.includes(row[siteNameIdx]) &&
        row[facilityNameIdx] === null
    )
  );

  // For 1 facility, use faculty area if there is data for faculty area, else use site area
  tuplesWithOneFacility.forEach((siteNameFacilityNameTuple) => {
    const tupleString = siteNameFacilityNameTuple.join(":");
    const siteName = siteNameFacilityNameTuple[0];
    const facilityAreas = data.filter(
      (row) => `${row[siteNameIdx]}:${row[facilityNameIdx]}` === tupleString
    );

    if (facilityAreas.length > 0 && !isUsingSiteDataOnly) {
      filteredData.push(...facilityAreas);
    } else {
      const siteAreas = data.filter(
        (row) => row[siteNameIdx] === siteName && row[facilityNameIdx] === null
      );
      filteredData.push(...siteAreas);
    }
  });

  return filteredData;
};

const isScope3RowFilter = (row, rowMetaIdx) => {
  return row[rowMetaIdx].includes(rowDataMeta.scope3);
};

const getScope3Inclusion = (
  classificationGroup,
  classificationValuesSelected
) => {
  let scope3Inclusion = scope3InclusionEnum.allScopes;

  if (classificationGroup === classificationGroups.scope3Category) {
    scope3Inclusion = scope3InclusionEnum.scope3Only;
  } else if (
    classificationGroup === classificationGroups.scope1Grouping ||
    classificationGroup === classificationGroups.scope2Grouping
  ) {
    scope3Inclusion = scope3InclusionEnum.noScope3;
  } else if (classificationGroup === classificationGroups.scope) {
    // There is potential bug here if group selected != group of widget
    if (classificationValuesSelected === null) {
      scope3Inclusion = scope3InclusionEnum.allScopes;
    } else {
      if (!classificationValuesSelected.includes(classificationValues.scope3)) {
        scope3Inclusion = scope3InclusionEnum.noScope3;
      } else if (classificationValuesSelected.length > 1) {
        scope3Inclusion = scope3InclusionEnum.allScopes;
      } else if (classificationValuesSelected.length === 1) {
        scope3Inclusion = scope3InclusionEnum.scope3Only;
      } else {
        scope3Inclusion = scope3InclusionEnum.none;
      }
    }
  }

  return scope3Inclusion;
};

const filterEmissionsDataByClassificationGroup = (
  data,
  rowMetaIdx,
  subtopic,
  classificationGroup,
  classificationValuesSelected
) => {
  if (subtopic !== dashboardSubtopic.emissions) {
    return data;
  } else {
    const scope3Inclusion = getScope3Inclusion(
      classificationGroup,
      classificationValuesSelected
    );

    return data.filter((row) => {
      if (scope3Inclusion === scope3InclusionEnum.scope3Only) {
        return isScope3RowFilter(row, rowMetaIdx);
      } else if (scope3Inclusion === scope3InclusionEnum.noScope3) {
        return !isScope3RowFilter(row, rowMetaIdx);
      } else if (scope3Inclusion === scope3InclusionEnum.none) {
        return false;
      } else {
        return true;
      }
    });
  }
};

const filterDataForClassification = (
  data,
  envTypeNamesNeeded,
  envTypeNameIdx,
  subtopic,
  widgetClassifications,
  classificationGroupSelected,
  classificationValuesSelected,
  rowMetaIdx
) => {
  if (subtopic !== dashboardSubtopic.emissions) {
    return data.filter((row) =>
      envTypeNamesNeeded.includes(row[envTypeNameIdx])
    );
  } else {
    let nonScope3Data = data.filter(
      (row) =>
        envTypeNamesNeeded.includes(row[envTypeNameIdx]) &&
        !isScope3RowFilter(row, rowMetaIdx)
    );
    let scope3Data = data.filter(
      (row) =>
        envTypeNamesNeeded.includes(row[envTypeNameIdx]) &&
        isScope3RowFilter(row, rowMetaIdx)
    );

    const scope3InclusionFromClassificationSelected = getScope3Inclusion(
      classificationGroupSelected,
      classificationValuesSelected
    );
    switch (scope3InclusionFromClassificationSelected) {
      case scope3InclusionEnum.noScope3:
        scope3Data = [];
        break;
      case scope3InclusionEnum.scope3Only:
        nonScope3Data = [];
        break;
      case scope3InclusionEnum.none:
        nonScope3Data = [];
        scope3Data = [];
        break;
    }

    if (widgetClassifications === noWidgetConfigClassificationValue) {
      return [...nonScope3Data, ...scope3Data];
    } else {
      const classificationValuesByGroup = widgetClassifications.reduce(
        (valuesByGroup, currentClassification) => {
          const grouping = currentClassification["grouping"];
          const value = currentClassification["value"];

          if (valuesByGroup[grouping] === undefined) {
            valuesByGroup[grouping] = [];
          }

          if (valuesByGroup[grouping].indexOf(value) === -1) {
            valuesByGroup[grouping].push(value);
          }

          return valuesByGroup;
        },
        {}
      );

      const groups = Object.keys(classificationValuesByGroup);

      const scope3Inclusions = new Set(
        groups.map((group) => {
          const classificationValues = classificationValuesByGroup[group];

          return getScope3Inclusion(group, classificationValues);
        })
      );

      if (scope3Inclusions.has(scope3InclusionEnum.allScopes)) {
        return [...nonScope3Data, ...scope3Data];
      } else if (scope3Inclusions.has(scope3InclusionEnum.noScope3)) {
        return nonScope3Data;
      } else if (scope3Inclusions.has(scope3InclusionEnum.scope3Only)) {
        return scope3Data;
      } else if (scope3Inclusions.has(scope3InclusionEnum.none)) {
        return [];
      } else {
        return [];
      }
    }
  }
};

const isEnvTypeInUserSelectedClassification = (
  classificationGroupSelected,
  classificationValuesSelected,
  envType,
  allClassificationsMeta
) => {
  if (classificationValuesSelected === null) {
    return true;
  }

  if (classificationGroupSelected === classificationGroups.environmentType) {
    return classificationValuesSelected.includes(envType);
  } else {
    const userSelectedClassificationTupleStrings =
      classificationValuesSelected.map(
        (value) => `${classificationGroupSelected};${value}`
      );
    const envTypeClassificationTuples = allClassificationsMeta[envType];

    if (envTypeClassificationTuples === undefined) {
      return false;
    }

    let isIncludedInSelection = true;

    if (classificationGroupSelected === classificationGroups.scope) {
      if (!classificationValuesSelected.includes(classificationValues.scope1)) {
        isIncludedInSelection = !envTypeClassificationTuples
          .map((tuple) => `${tuple[0]};${tuple[1]}`)
          .includes(
            `${classificationGroups.scope};${classificationValues.scope1}`
          );
      } else if (
        !classificationValuesSelected.includes(classificationValues.scope2)
      ) {
        isIncludedInSelection = !envTypeClassificationTuples
          .map((tuple) => `${tuple[0]};${tuple[1]}`)
          .includes(
            `${classificationGroups.scope};${classificationValues.scope2}`
          );
      }
    }

    return (
      isIncludedInSelection &&
      envTypeClassificationTuples
        .map((tuple) => `${tuple[0]};${tuple[1]}`)
        .some((tupleString) =>
          userSelectedClassificationTupleStrings.includes(tupleString)
        )
    );
  }
};

const getEnvTypeNameNeededForClassification = (
  configClassifications,
  allClassificationsMeta,
  classificationGroupSelected,
  classificationValuesSelected
) => {
  const envTypeNames = [];

  let effectiveConfigClassifications = configClassifications;

  if (configClassifications === noWidgetConfigClassificationValue) {
    if (
      classificationGroupSelected === noUserDefinedConfigClassification ||
      classificationValuesSelected === noUserDefinedConfigClassification
    ) {
      return null;
    }

    effectiveConfigClassifications = classificationValuesSelected.map(
      (value) => ({ grouping: classificationGroupSelected, value: value })
    );
  }

  effectiveConfigClassifications.forEach((configClassification) => {
    if (
      configClassification["grouping"] === classificationGroups.environmentType
    ) {
      const envType = configClassification["value"];

      const isInUserSelectedClassification =
        isEnvTypeInUserSelectedClassification(
          classificationGroupSelected,
          classificationValuesSelected,
          envType,
          allClassificationsMeta
        );

      if (isInUserSelectedClassification) {
        envTypeNames.push(envType);
      }
    } else {
      const configClassificationGroupValueTuple = `${configClassification["grouping"]};${configClassification["value"]}`;

      const classificationEnvTypeNames = Object.keys(
        allClassificationsMeta
      ).reduce((compositionEnvTypes, envType) => {
        const isEnvTypePartOfConfigClassifications = allClassificationsMeta[
          envType
        ]
          .map((tuple) => `${tuple[0]};${tuple[1]}`)
          .includes(configClassificationGroupValueTuple);
        const isInUserSelectedClassification =
          isEnvTypeInUserSelectedClassification(
            classificationGroupSelected,
            classificationValuesSelected,
            envType,
            allClassificationsMeta
          );

        if (
          isEnvTypePartOfConfigClassifications &&
          isInUserSelectedClassification
        ) {
          compositionEnvTypes.push(envType);
        }
        return compositionEnvTypes;
      }, []);

      envTypeNames.push(...classificationEnvTypeNames);
    }
  });

  return envTypeNames;
};

const getEnvTypeToClassificationValue = (
  selectedClassificationGroup,
  allClassificationsMeta,
  classificationGroupSelected,
  classificationValuesSelected
) => {
  if (
    selectedClassificationGroup === null ||
    selectedClassificationGroup === classificationGroups.environmentType
  ) {
    return Object.keys(allClassificationsMeta)
      .filter((envType) =>
        isEnvTypeInUserSelectedClassification(
          classificationGroupSelected,
          classificationValuesSelected,
          envType,
          allClassificationsMeta
        )
      )
      .reduce((envTypeToClassificationValue, envType) => {
        envTypeToClassificationValue[envType] = envType;
        return envTypeToClassificationValue;
      }, {});
  } else {
    return Object.keys(allClassificationsMeta).reduce(
      (envTypeToClassificationValue, envType) => {
        if (
          !isEnvTypeInUserSelectedClassification(
            classificationGroupSelected,
            classificationValuesSelected,
            envType,
            allClassificationsMeta
          )
        ) {
          return envTypeToClassificationValue;
        }

        const envTypeClassificationTupleMatchingGroup = allClassificationsMeta[
          envType
        ].find((tuple) => tuple[0] === selectedClassificationGroup);

        if (envTypeClassificationTupleMatchingGroup !== undefined) {
          envTypeToClassificationValue[envType] =
            envTypeClassificationTupleMatchingGroup[1];
        }

        return envTypeToClassificationValue;
      },
      {}
    );
  }
};

export default class DashboardCalculator {
  static getSummary(config, dashboardHolderFacade, yearSubtraction = 0) {
    const sourceDataHolder = dashboardHolderFacade.sourceDataHolder;
    const dashboardSelectedConfigHolder =
      dashboardHolderFacade.selectedConfigHolder;

    const subtopic = config["subtopic"];
    const subtopicDataHolder =
      sourceDataHolder.getDataHolderForSubtopic(subtopic);
    const columns = subtopicDataHolder.cols;
    const data = subtopicDataHolder.data;
    const meta = subtopicDataHolder.meta;

    const formula = config["formula"];

    const siteNameIdx = columns.indexOf("Site Name");
    const facilityNameIdx = columns.indexOf("Facility Name");
    const missingStatusIdx = columns.indexOf("Missing Data");
    const valueIdx = columns.indexOf("Usage");
    const monthIdx = columns.indexOf("Month");
    const yearIdx = columns.indexOf("Year");
    const envTypeNameIdx = columns.indexOf("Env Type Name");
    const rowMetaIdx = columns.indexOf("Meta");

    const includedMonthYear =
      dashboardSelectedConfigHolder.getIncludedMonthYearForCalculation(
        yearSubtraction
      );
    let filteredData = filterDataForMonthYear(
      data,
      includedMonthYear,
      yearIdx,
      monthIdx
    );
    filteredData = filterDataForSiteFacility(
      filteredData,
      dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
      siteNameIdx,
      facilityNameIdx
    );

    let hasMissingData = false;

    let divisor = 1;

    if (
      divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue] !==
      undefined
    ) {
      const divisorSubtopic =
        divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue];

      const divisorSubtopicDataHolder =
        sourceDataHolder.getDataHolderForSubtopic(divisorSubtopic);
      const divisorColumns = divisorSubtopicDataHolder.cols;
      const divisorData = divisorSubtopicDataHolder.data;

      if (divisorSubtopic === "Occupied Room") {
        const divisorValueIdx = divisorColumns.indexOf("Value");
        const divisorMonthIdx = divisorColumns.indexOf("Month");
        const divisorYearIdx = divisorColumns.indexOf("Year");
        const divisorMissingStatusIdx = divisorColumns.indexOf("Missing Data");

        let filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthYear,
          divisorYearIdx,
          divisorMonthIdx
        );
        filteredDivisorData = filterDataForSiteFacility(
          filteredDivisorData,
          dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
          siteNameIdx,
          facilityNameIdx
        );

        divisor = sumData(filteredDivisorData, divisorValueIdx);

        if (checkHasMissingData(filteredDivisorData, divisorMissingStatusIdx)) {
          hasMissingData = true;
        }
      } else {
        const divisorValueIdx = divisorColumns.indexOf("Value");
        const divisorMonthIdx = divisorColumns.indexOf("Month");
        const divisorYearIdx = divisorColumns.indexOf("Year");
        const divisorMissingStatusIdx = divisorColumns.indexOf("Missing Data");

        let filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthYear,
          divisorYearIdx,
          divisorMonthIdx
        );

        filteredDivisorData = filterAreaDataForSiteFacility(
          filteredDivisorData,
          dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
          siteNameIdx,
          facilityNameIdx
        );

        divisor = sumData(filteredDivisorData, divisorValueIdx);

        if (checkHasMissingData(filteredDivisorData, divisorMissingStatusIdx)) {
          hasMissingData = true;
        }
      }
    }

    const envTypeNamesNeeded = getEnvTypeNameNeededForClassification(
      config["classifications"],
      meta["classifications"],
      dashboardSelectedConfigHolder.classificationGroupSelected,
      dashboardSelectedConfigHolder.classificationValuesSelected
    );

    switch (formula) {
      case "sum":
        const sumCompositionData =
          envTypeNamesNeeded === null
            ? filteredData
            : filterDataForClassification(
                filteredData,
                envTypeNamesNeeded,
                envTypeNameIdx,
                subtopic,
                config["classifications"],
                dashboardSelectedConfigHolder.classificationGroupSelected,
                dashboardSelectedConfigHolder.classificationValuesSelected,
                rowMetaIdx
              );

        const value = sumData(sumCompositionData, valueIdx);
        if (checkHasMissingData(sumCompositionData, missingStatusIdx)) {
          hasMissingData = true;
        }

        return {
          value: divideDataWithDivisor(value, divisor),
          status: hasMissingData ? DATA_STATUS.MISSING : DATA_STATUS.COMPLETE,
        };
      case "composition":
        if (!meta["classifications"]) {
          return {
            value: null,
            status: DATA_STATUS.MISSING,
          };
        }

        const compositionData = filterDataForClassification(
          filteredData,
          envTypeNamesNeeded,
          envTypeNameIdx,
          subtopic,
          config["classifications"],
          dashboardSelectedConfigHolder.classificationGroupSelected,
          dashboardSelectedConfigHolder.classificationValuesSelected,
          rowMetaIdx
        );
        const compositionTotal = sumData(compositionData, valueIdx);
        const total = sumData(filteredData, valueIdx);

        if (checkHasMissingData(filteredData, missingStatusIdx)) {
          hasMissingData = true;
        }

        return {
          value:
            compositionTotal === null
              ? null
              : divideDataWithDivisor(100 * compositionTotal, total),
          status: hasMissingData ? DATA_STATUS.MISSING : DATA_STATUS.COMPLETE,
        };
    }
  }

  static getYearDiff(config, dashboardHolderFacade) {
    const currentYear = DashboardCalculator.getSummary(
      config,
      dashboardHolderFacade
    );
    const prevYear = DashboardCalculator.getSummary(
      config,
      dashboardHolderFacade,
      1
    );

    return SafeDataService.getVariance(currentYear, prevYear);
  }

  static getBreakdownByClassificationValue(config, dashboardHolderFacade) {
    const sourceDataHolder = dashboardHolderFacade.sourceDataHolder;
    const dashboardSelectedConfigHolder =
      dashboardHolderFacade.selectedConfigHolder;

    const subtopic = config["subtopic"];
    const subtopicDataHolder =
      sourceDataHolder.getDataHolderForSubtopic(subtopic);
    const columns = subtopicDataHolder.cols;
    const data = subtopicDataHolder.data;
    const meta = subtopicDataHolder.meta;

    const siteNameIdx = columns.indexOf("Site Name");
    const facilityNameIdx = columns.indexOf("Facility Name");
    const missingStatusIdx = columns.indexOf("Missing Data");
    const valueIdx = columns.indexOf("Usage");
    const monthIdx = columns.indexOf("Month");
    const yearIdx = columns.indexOf("Year");
    const envTypeNameIdx = columns.indexOf("Env Type Name");
    const rowMetaIdx = columns.indexOf("Meta");

    const includedMonthYear =
      dashboardSelectedConfigHolder.getIncludedMonthYearForCalculation();
    let filteredData = filterDataForMonthYear(
      data,
      includedMonthYear,
      yearIdx,
      monthIdx
    );
    filteredData = filterDataForSiteFacility(
      filteredData,
      dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
      siteNameIdx,
      facilityNameIdx
    );

    const selectedClassificationGroup =
      config["classificationGrouping"] !== undefined
        ? config["classificationGrouping"]
        : dashboardSelectedConfigHolder.classificationGroupSelected;
    const isClassificationValuesDefinedForChart =
      config["classificationValues"] !== undefined;
    const selectedClassificationValues = isClassificationValuesDefinedForChart
      ? config["classificationValues"]
      : dashboardSelectedConfigHolder.classificationValuesSelected;

    filteredData = filterEmissionsDataByClassificationGroup(
      filteredData,
      rowMetaIdx,
      subtopic,
      selectedClassificationGroup,
      selectedClassificationValues
    );

    const envTypeToRequiredClassificationValue =
      getEnvTypeToClassificationValue(
        selectedClassificationGroup,
        meta["classifications"],
        isClassificationValuesDefinedForChart
          ? selectedClassificationGroup
          : dashboardSelectedConfigHolder.classificationGroupSelected,
        selectedClassificationValues
      );

    let hasMissingData = checkHasMissingData(filteredData, missingStatusIdx);

    const totalByClassificationValue = filteredData.reduce(
      (totalByClassificationValue, row) => {
        const envType = row[envTypeNameIdx];
        let classificationValue = envTypeToRequiredClassificationValue[envType];

        if (
          subtopic === dashboardSubtopic.emissions &&
          selectedClassificationGroup === classificationGroups.scope
        ) {
          const classificationValuesSelected = selectedClassificationValues ?? [
            classificationValues.scope1,
            classificationValues.scope2,
            classificationValues.scope3,
          ];
          const isRowScope3 = isScope3RowFilter(row, rowMetaIdx);

          if (isRowScope3) {
            if (
              classificationValuesSelected.includes(classificationValues.scope3)
            ) {
              classificationValue = classificationValues.scope3;
            } else {
              classificationValue = undefined;
            }
          } else {
            const isScope1Excluded = !classificationValuesSelected.includes(
              classificationValues.scope1
            );
            const isScope2Excluded = !classificationValuesSelected.includes(
              classificationValues.scope2
            );

            if (
              (classificationValue === classificationValues.scope1 &&
                isScope1Excluded) ||
              (classificationValue === classificationValues.scope2 &&
                isScope2Excluded)
            ) {
              classificationValue = undefined;
            }
          }
        }

        const isEnvTypeNotInClassification = classificationValue === undefined;
        if (isEnvTypeNotInClassification) {
          return totalByClassificationValue;
        }

        if (row[valueIdx] !== NO_VALUE) {
          if (totalByClassificationValue[classificationValue] === undefined) {
            totalByClassificationValue[classificationValue] = 0;
          }

          totalByClassificationValue[classificationValue] += row[valueIdx];
        }

        return totalByClassificationValue;
      },
      {}
    );

    if (
      divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue] !==
      undefined
    ) {
      let divisor = 1;

      const divisorSubtopic =
        divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue];

      const divisorSubtopicDataHolder =
        sourceDataHolder.getDataHolderForSubtopic(divisorSubtopic);
      const divisorColumns = divisorSubtopicDataHolder.cols;
      const divisorData = divisorSubtopicDataHolder.data;

      if (divisorSubtopic === "Occupied Room") {
        const divisorValueIdx = divisorColumns.indexOf("Value");
        const divisorMonthIdx = divisorColumns.indexOf("Month");
        const divisorYearIdx = divisorColumns.indexOf("Year");
        const divisorMissingStatusIdx = divisorColumns.indexOf("Missing Data");

        let filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthYear,
          divisorYearIdx,
          divisorMonthIdx
        );
        filteredDivisorData = filterDataForSiteFacility(
          filteredDivisorData,
          dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
          siteNameIdx,
          facilityNameIdx
        );

        if (checkHasMissingData(filteredDivisorData, divisorMissingStatusIdx)) {
          hasMissingData = true;
        }

        divisor = sumData(filteredDivisorData, divisorValueIdx);
      } else {
        const divisorValueIdx = divisorColumns.indexOf("Value");
        const divisorMonthIdx = divisorColumns.indexOf("Month");
        const divisorYearIdx = divisorColumns.indexOf("Year");
        const divisorMissingStatusIdx = divisorColumns.indexOf("Missing Data");

        let filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthYear,
          divisorYearIdx,
          divisorMonthIdx
        );

        filteredDivisorData = filterAreaDataForSiteFacility(
          filteredDivisorData,
          dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
          siteNameIdx,
          facilityNameIdx
        );

        if (checkHasMissingData(filteredDivisorData, divisorMissingStatusIdx)) {
          hasMissingData = true;
        }

        divisor = sumData(filteredDivisorData, divisorValueIdx);
      }

      Object.keys(totalByClassificationValue).forEach((classificationValue) => {
        if (divisor !== null && divisor !== undefined && divisor !== 0) {
          totalByClassificationValue[classificationValue] /= divisor;
        } else {
          totalByClassificationValue[classificationValue] = null;
        }
      });
    }

    return {
      value: totalByClassificationValue,
      status: hasMissingData ? DATA_STATUS.MISSING : DATA_STATUS.COMPLETE,
    };
  }

  static getPerformanceComparisonData(config, dashboardHolderFacade) {
    const sourceDataHolder = dashboardHolderFacade.dataHolder.sourceDataHolder;
    const dashboardSelectedConfigHolder =
      dashboardHolderFacade.selectedConfigHolder;

    const comparisonYears = dashboardSelectedConfigHolder.getComparisonYears();

    const monthlyValuesByYear = comparisonYears.reduce(
      (dataByYear, currentYear) => {
        dataByYear[currentYear] = {};

        return dataByYear;
      },
      {}
    );

    const subtopic = config["subtopic"];
    const subtopicDataHolder =
      sourceDataHolder.getDataHolderForSubtopic(subtopic);
    const columns = subtopicDataHolder.cols;
    const data = subtopicDataHolder.data;
    const meta = subtopicDataHolder.meta;

    const siteNameIdx = columns.indexOf("Site Name");
    const facilityNameIdx = columns.indexOf("Facility Name");
    const missingStatusIdx = columns.indexOf("Missing Data");
    const valueIdx = columns.indexOf("Usage");
    const monthIdx = columns.indexOf("Month");
    const yearIdx = columns.indexOf("Year");
    const envTypeNameIdx = columns.indexOf("Env Type Name");
    const rowMetaIdx = columns.indexOf("Meta");

    const includedMonthsByYear =
      dashboardSelectedConfigHolder.getIncludedMonthYearForCalculation();
    let filteredData = filterDataForMonthYear(
      data,
      includedMonthsByYear,
      yearIdx,
      monthIdx
    );
    const selectedSiteNameFacilityNameTuples =
      dashboardSelectedConfigHolder.getInventoryFilterForCalculation();
    filteredData = filterDataForSiteFacility(
      filteredData,
      selectedSiteNameFacilityNameTuples,
      siteNameIdx,
      facilityNameIdx
    );

    const selectedClassificationGroup =
      dashboardSelectedConfigHolder.classificationGroupSelected;

    filteredData = filterEmissionsDataByClassificationGroup(
      filteredData,
      rowMetaIdx,
      subtopic,
      selectedClassificationGroup,
      dashboardSelectedConfigHolder.classificationValuesSelected
    );

    const envTypeToRequiredClassificationValue =
      getEnvTypeToClassificationValue(
        selectedClassificationGroup,
        meta["classifications"],
        dashboardSelectedConfigHolder.classificationGroupSelected,
        dashboardSelectedConfigHolder.classificationValuesSelected
      );

    let hasMissingData = checkHasMissingData(filteredData, missingStatusIdx);

    filteredData.forEach((row) => {
      const year = row[yearIdx];
      const month = row[monthIdx];

      const envType = row[envTypeNameIdx];
      const classificationValue = envTypeToRequiredClassificationValue[envType];

      const isEnvTypeNotInClassification = !classificationValue;
      if (isEnvTypeNotInClassification) {
        return;
      }

      if (row[valueIdx] !== NO_VALUE) {
        if (monthlyValuesByYear[year][month] === undefined) {
          monthlyValuesByYear[year][month] = 0;
        }

        monthlyValuesByYear[year][month] += row[valueIdx];
      }
    });

    if (
      divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue] !==
      undefined
    ) {
      let filteredDivisorData = [];

      let divisorMonthlyValueByYear = comparisonYears.reduce(
        (dataByYear, currentYear) => {
          dataByYear[currentYear] = {};

          return dataByYear;
        },
        {}
      );

      const divisorSubtopic =
        divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue];

      const divisorSubtopicDataHolder =
        sourceDataHolder.getDataHolderForSubtopic(divisorSubtopic);
      const divisorColumns = divisorSubtopicDataHolder.cols;
      const divisorData = divisorSubtopicDataHolder.data;

      const divisorValueIdx = divisorColumns.indexOf("Value");
      const divisorMonthIdx = divisorColumns.indexOf("Month");
      const divisorYearIdx = divisorColumns.indexOf("Year");
      const divisorMissingStatusIdx = divisorColumns.indexOf("Missing Data");
      const divisorSiteNameIdx = divisorColumns.indexOf("Site Name");
      const divisorFacilityNameIdx = divisorColumns.indexOf("Site Name");

      if (divisorSubtopic === "Occupied Room") {
        filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthsByYear,
          divisorYearIdx,
          divisorMonthIdx
        );
        filteredDivisorData = filterDataForSiteFacility(
          filteredDivisorData,
          selectedSiteNameFacilityNameTuples,
          divisorSiteNameIdx,
          divisorFacilityNameIdx,
          true
        );
      } else {
        filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthsByYear,
          divisorYearIdx,
          divisorMonthIdx
        );

        filteredDivisorData = filterAreaDataForSiteFacility(
          filteredDivisorData,
          selectedSiteNameFacilityNameTuples,
          siteNameIdx,
          facilityNameIdx,
          true
        );
      }

      filteredDivisorData.forEach((row) => {
        const year = row[divisorYearIdx];
        const month = row[divisorMonthIdx];

        if (divisorMonthlyValueByYear[year][month] === undefined) {
          divisorMonthlyValueByYear[year][month] = 0;
        }

        if (row[divisorValueIdx] !== NO_VALUE) {
          divisorMonthlyValueByYear[year][month] += row[divisorValueIdx];
        }

        if (row[divisorMissingStatusIdx] === true) {
          hasMissingData = true;
        }
      });

      comparisonYears.forEach((year) => {
        Object.keys(monthlyValuesByYear[year]).forEach((month) => {
          const divisor = divisorMonthlyValueByYear[year][month];

          monthlyValuesByYear[year][month] = divideDataWithDivisor(
            monthlyValuesByYear[year][month],
            divisor
          );
        });
      });
    }

    return {
      value: monthlyValuesByYear,
      status: hasMissingData ? DATA_STATUS.MISSING : DATA_STATUS.COMPLETE,
    };
  }

  static getSummarySiteRankingData(config, dashboardHolderFacade) {
    const sourceDataHolder = dashboardHolderFacade.dataHolder.sourceDataHolder;
    const dashboardSelectedConfigHolder =
      dashboardHolderFacade.selectedConfigHolder;

    const subtopic = config["subtopic"];
    const subtopicDataHolder =
      sourceDataHolder.getDataHolderForSubtopic(subtopic);
    const columns = subtopicDataHolder.cols;
    const data = subtopicDataHolder.data;
    const meta = subtopicDataHolder.meta;

    const siteNameIdx = columns.indexOf("Site Name");
    const facilityNameIdx = columns.indexOf("Facility Name");
    const missingStatusIdx = columns.indexOf("Missing Data");
    const valueIdx = columns.indexOf("Usage");
    const monthIdx = columns.indexOf("Month");
    const yearIdx = columns.indexOf("Year");
    const envTypeNameIdx = columns.indexOf("Env Type Name");
    const rowMetaIdx = columns.indexOf("Meta");

    const includedMonthsByYear =
      dashboardSelectedConfigHolder.getIncludedMonthYearForCalculation();
    let filteredData = filterDataForMonthYear(
      data,
      includedMonthsByYear,
      yearIdx,
      monthIdx
    );
    const selectedSiteNameFacilityNameTuples =
      dashboardSelectedConfigHolder.getInventoryFilterForCalculation();
    const siteNames = [
      ...new Set(selectedSiteNameFacilityNameTuples.map((tuple) => tuple[0])),
    ];
    filteredData = filterDataForSiteFacility(
      filteredData,
      selectedSiteNameFacilityNameTuples,
      siteNameIdx,
      facilityNameIdx
    );

    const selectedClassificationGroup =
      dashboardSelectedConfigHolder.classificationGroupSelected;

    const envTypeToRequiredClassificationValue =
      getEnvTypeToClassificationValue(
        selectedClassificationGroup,
        meta["classifications"],
        dashboardSelectedConfigHolder.classificationGroupSelected,
        dashboardSelectedConfigHolder.classificationValuesSelected
      );

    filteredData = filterEmissionsDataByClassificationGroup(
      filteredData,
      rowMetaIdx,
      subtopic,
      selectedClassificationGroup,
      dashboardSelectedConfigHolder.classificationValuesSelected
    );

    const totalByClassificationValueForSiteName = siteNames.reduce(
      (totalByClassificationValueForSiteName, siteName) => {
        totalByClassificationValueForSiteName[siteName] = {};
        return totalByClassificationValueForSiteName;
      },
      {}
    );
    let dataStatus = DATA_STATUS.COMPLETE;

    filteredData.forEach((row) => {
      const siteName = row[siteNameIdx];
      const envType = row[envTypeNameIdx];
      let classificationValue = envTypeToRequiredClassificationValue[envType];

      if (
        subtopic === dashboardSubtopic.emissions &&
        selectedClassificationGroup === classificationGroups.scope
      ) {
        if (isScope3RowFilter(row, rowMetaIdx)) {
          classificationValue = classificationValues.scope3;
        }
      }

      const isEnvTypeNotInClassification = classificationValue === undefined;
      if (isEnvTypeNotInClassification) {
        return;
      }

      if (row[valueIdx] !== NO_VALUE) {
        if (
          totalByClassificationValueForSiteName[siteName][
            classificationValue
          ] === undefined
        ) {
          totalByClassificationValueForSiteName[siteName][
            classificationValue
          ] = 0;
        }

        totalByClassificationValueForSiteName[siteName][classificationValue] +=
          row[valueIdx];
      }
      if (row[missingStatusIdx] === true) {
        dataStatus = DATA_STATUS.MISSING;
      }
    });

    if (
      divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue] !==
      undefined
    ) {
      let filteredDivisorData = [];

      let divisorValueBySiteName = siteNames.reduce(
        (divisorValueBySiteName, siteName) => {
          divisorValueBySiteName[siteName] = null;
          return divisorValueBySiteName;
        },
        {}
      );

      const divisorSubtopic =
        divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue];

      const divisorSubtopicDataHolder =
        sourceDataHolder.getDataHolderForSubtopic(divisorSubtopic);
      const divisorColumns = divisorSubtopicDataHolder.cols;
      const divisorData = divisorSubtopicDataHolder.data;

      const divisorValueIdx = divisorColumns.indexOf("Value");
      const divisorMonthIdx = divisorColumns.indexOf("Month");
      const divisorYearIdx = divisorColumns.indexOf("Year");
      const divisorMissingStatusIdx = divisorColumns.indexOf("Missing Data");
      const divisorSiteNameIdx = divisorColumns.indexOf("Site Name");
      const divisorFacilityNameIdx = divisorColumns.indexOf("Site Name");

      if (divisorSubtopic === "Occupied Room") {
        filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthsByYear,
          divisorYearIdx,
          divisorMonthIdx
        );
        filteredDivisorData = filterDataForSiteFacility(
          filteredDivisorData,
          selectedSiteNameFacilityNameTuples,
          divisorSiteNameIdx,
          divisorFacilityNameIdx,
          true
        );
      } else {
        filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthsByYear,
          divisorYearIdx,
          divisorMonthIdx
        );

        filteredDivisorData = filterAreaDataForSiteFacility(
          filteredDivisorData,
          selectedSiteNameFacilityNameTuples,
          siteNameIdx,
          facilityNameIdx,
          true
        );
      }

      filteredDivisorData.forEach((row) => {
        const siteName = row[divisorSiteNameIdx];

        if (divisorValueBySiteName[siteName] !== undefined) {
          if (row[divisorValueIdx] !== NO_VALUE) {
            if (divisorValueBySiteName[siteName] === null) {
              divisorValueBySiteName[siteName] = 0;
            }
            divisorValueBySiteName[siteName] += row[divisorValueIdx];
          }

          if (row[divisorMissingStatusIdx] === true) {
            dataStatus = DATA_STATUS.MISSING;
          }
        }
      });

      siteNames.forEach((siteName) => {
        const divisor = divisorValueBySiteName[siteName];
        const totalByClassificationValue =
          totalByClassificationValueForSiteName[siteName];

        Object.keys(totalByClassificationValue).map((classificationValue) => {
          totalByClassificationValue[classificationValue] =
            divideDataWithDivisor(
              totalByClassificationValue[classificationValue],
              divisor
            );
        });
      });
    }

    return {
      value: totalByClassificationValueForSiteName,
      status: dataStatus,
    };
  }

  static getChangeOverTimeSiteRankingData(config, dashboardHolderFacade) {
    const sourceDataHolder = dashboardHolderFacade.dataHolder.sourceDataHolder;
    const dashboardSelectedConfigHolder =
      dashboardHolderFacade.selectedConfigHolder;

    const subtopic = config["subtopic"];
    const subtopicDataHolder =
      sourceDataHolder.getDataHolderForSubtopic(subtopic);
    const columns = subtopicDataHolder.cols;
    const data = subtopicDataHolder.data;
    const meta = subtopicDataHolder.meta;

    const siteNameIdx = columns.indexOf("Site Name");
    const facilityNameIdx = columns.indexOf("Facility Name");
    const missingStatusIdx = columns.indexOf("Missing Data");
    const valueIdx = columns.indexOf("Usage");
    const monthIdx = columns.indexOf("Month");
    const yearIdx = columns.indexOf("Year");
    const envTypeNameIdx = columns.indexOf("Env Type Name");
    const rowMetaIdx = columns.indexOf("Meta");

    const includedMonthsByYear =
      dashboardSelectedConfigHolder.getIncludedMonthYearForCalculation();
    let filteredData = filterDataForMonthYear(
      data,
      includedMonthsByYear,
      yearIdx,
      monthIdx
    );
    const selectedSiteNameFacilityNameTuples =
      dashboardSelectedConfigHolder.getInventoryFilterForCalculation();
    filteredData = filterDataForSiteFacility(
      filteredData,
      selectedSiteNameFacilityNameTuples,
      siteNameIdx,
      facilityNameIdx
    );

    const selectedClassificationGroup =
      dashboardSelectedConfigHolder.classificationGroupSelected;

    filteredData = filterEmissionsDataByClassificationGroup(
      filteredData,
      rowMetaIdx,
      subtopic,
      selectedClassificationGroup,
      dashboardSelectedConfigHolder.classificationValuesSelected
    );

    const envTypeToRequiredClassificationValue =
      getEnvTypeToClassificationValue(
        selectedClassificationGroup,
        meta["classifications"],
        dashboardSelectedConfigHolder.classificationGroupSelected,
        dashboardSelectedConfigHolder.classificationValuesSelected
      );

    const years = Object.keys(includedMonthsByYear);

    const siteNames = [
      ...new Set(selectedSiteNameFacilityNameTuples.map((tuple) => tuple[0])),
    ];
    const yearInitialValue = siteNames.reduce((totalForSiteName, siteName) => {
      totalForSiteName[siteName] = null;

      return totalForSiteName;
    }, {});
    const totalForSiteNameByYear = years.reduce((objectByYear, year) => {
      objectByYear[year] = clone(yearInitialValue);

      return objectByYear;
    }, {});

    let dataStatus = DATA_STATUS.COMPLETE;

    filteredData.forEach((row) => {
      const siteName = row[siteNameIdx];
      const envType = row[envTypeNameIdx];
      const classificationValue = envTypeToRequiredClassificationValue[envType];

      const isEnvTypeNotInClassification = !classificationValue;
      if (isEnvTypeNotInClassification) {
        return;
      }

      const rowYear = row[yearIdx];

      if (row[valueIdx] !== NO_VALUE) {
        if (totalForSiteNameByYear[rowYear][siteName] === null) {
          totalForSiteNameByYear[rowYear][siteName] = 0;
        }

        totalForSiteNameByYear[rowYear][siteName] += row[valueIdx];
      }
      if (row[missingStatusIdx] === true) {
        dataStatus = DATA_STATUS.MISSING;
      }
    });

    if (
      divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue] !==
      undefined
    ) {
      let filteredDivisorData = [];

      const initValueBySiteName = siteNames.reduce(
        (divisorValueBySiteName, siteName) => {
          divisorValueBySiteName[siteName] = null;
          return divisorValueBySiteName;
        },
        {}
      );
      let divisorValueBySiteNameByYear = years.reduce(
        (initValueByYear, year) => {
          initValueByYear[year] = clone(initValueBySiteName);
          return initValueByYear;
        },
        {}
      );

      const divisorSubtopic =
        divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue];

      const divisorSubtopicDataHolder =
        sourceDataHolder.getDataHolderForSubtopic(divisorSubtopic);
      const divisorColumns = divisorSubtopicDataHolder.cols;
      const divisorData = divisorSubtopicDataHolder.data;

      const divisorValueIdx = divisorColumns.indexOf("Value");
      const divisorMonthIdx = divisorColumns.indexOf("Month");
      const divisorYearIdx = divisorColumns.indexOf("Year");
      const divisorMissingStatusIdx = divisorColumns.indexOf("Missing Data");
      const divisorSiteNameIdx = divisorColumns.indexOf("Site Name");
      const divisorFacilityNameIdx = divisorColumns.indexOf("Site Name");

      if (divisorSubtopic === "Occupied Room") {
        filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthsByYear,
          divisorYearIdx,
          divisorMonthIdx
        );
        filteredDivisorData = filterDataForSiteFacility(
          filteredDivisorData,
          selectedSiteNameFacilityNameTuples,
          divisorSiteNameIdx,
          divisorFacilityNameIdx,
          true
        );
      } else {
        filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthsByYear,
          divisorYearIdx,
          divisorMonthIdx
        );

        filteredDivisorData = filterAreaDataForSiteFacility(
          filteredDivisorData,
          selectedSiteNameFacilityNameTuples,
          siteNameIdx,
          facilityNameIdx,
          true
        );
      }

      filteredDivisorData.forEach((row) => {
        const siteName = row[divisorSiteNameIdx];
        const year = row[divisorYearIdx];

        if (
          divisorValueBySiteNameByYear[year] !== undefined &&
          divisorValueBySiteNameByYear[year][siteName] !== undefined
        ) {
          if (row[divisorValueIdx] !== NO_VALUE) {
            if (divisorValueBySiteNameByYear[year][siteName] === null) {
              divisorValueBySiteNameByYear[year][siteName] = 0;
            }
            divisorValueBySiteNameByYear[year][siteName] +=
              row[divisorValueIdx];
          }

          if (row[divisorMissingStatusIdx] === true) {
            dataStatus = DATA_STATUS.MISSING;
          }
        }
      });

      years.forEach((year) => {
        siteNames.forEach((siteName) => {
          const divisor = divisorValueBySiteNameByYear[year][siteName];

          totalForSiteNameByYear[year][siteName] = divideDataWithDivisor(
            totalForSiteNameByYear[year][siteName],
            divisor
          );
        });
      });
    }

    return {
      value: totalForSiteNameByYear,
      status: dataStatus,
    };
  }

  static getPerformanceTimelineData(config, dashboardHolderFacade) {
    const sourceDataHolder = dashboardHolderFacade.dataHolder.sourceDataHolder;
    const dashboardSelectedConfigHolder =
      dashboardHolderFacade.selectedConfigHolder;

    const subtopic = config["subtopic"];
    const subtopicDataHolder =
      sourceDataHolder.getDataHolderForSubtopic(subtopic);
    const columns = subtopicDataHolder.cols;
    const data = subtopicDataHolder.data;
    const meta = subtopicDataHolder.meta;

    const siteNameIdx = columns.indexOf("Site Name");
    const facilityNameIdx = columns.indexOf("Facility Name");
    const missingStatusIdx = columns.indexOf("Missing Data");
    const valueIdx = columns.indexOf("Usage");
    const monthIdx = columns.indexOf("Month");
    const yearIdx = columns.indexOf("Year");
    const envTypeNameIdx = columns.indexOf("Env Type Name");
    const rowMetaIdx = columns.indexOf("Meta");

    const includedMonthsByYear =
      dashboardSelectedConfigHolder.getIncludedMonthYearForCalculation();
    let filteredData = filterDataForSiteFacility(
      data,
      dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
      siteNameIdx,
      facilityNameIdx
    );

    const selectedClassificationGroup =
      dashboardSelectedConfigHolder.classificationGroupSelected;
    const selectedClassificationValues =
      dashboardSelectedConfigHolder.classificationValuesSelected;

    filteredData = filterEmissionsDataByClassificationGroup(
      filteredData,
      rowMetaIdx,
      subtopic,
      selectedClassificationGroup,
      dashboardSelectedConfigHolder.classificationValuesSelected
    );

    const envTypeToRequiredClassificationValue =
      getEnvTypeToClassificationValue(
        selectedClassificationGroup,
        meta["classifications"],
        dashboardSelectedConfigHolder.classificationGroupSelected,
        dashboardSelectedConfigHolder.classificationValuesSelected
      );

    const monthPeriods = [];
    Object.keys(includedMonthsByYear).forEach((year) => {
      includedMonthsByYear[year].forEach((month) => {
        monthPeriods.push(new Date(year, month - 1));
      });
    });
    monthPeriods.sort((date1, date2) => date1 - date2);

    const initValue = selectedClassificationValues.reduce(
      (monthValueByClassificationValue, classificationValue) => {
        monthValueByClassificationValue[classificationValue] = null;
        return monthValueByClassificationValue;
      },
      {}
    );

    const monthValueByClassificationValueByMonthPeriod = monthPeriods.reduce(
      (initValueByMonthPeriod, monthPeriod) => {
        initValueByMonthPeriod[monthPeriod] = clone(initValue);
        return initValueByMonthPeriod;
      },
      {}
    );

    let dataStatus = DATA_STATUS.COMPLETE;
    filteredData.forEach((row) => {
      const dateBucket = new Date(row[yearIdx], row[monthIdx] - 1);

      if (
        monthValueByClassificationValueByMonthPeriod[dateBucket] !== undefined
      ) {
        let classificationValue =
          envTypeToRequiredClassificationValue[row[envTypeNameIdx]];

        if (
          subtopic === dashboardSubtopic.emissions &&
          selectedClassificationGroup === classificationGroups.scope
        ) {
          if (isScope3RowFilter(row, rowMetaIdx)) {
            classificationValue = classificationValues.scope3;
          }
        }

        if (classificationValue !== undefined) {
          if (row[valueIdx] !== NO_VALUE) {
            if (
              monthValueByClassificationValueByMonthPeriod[dateBucket][
                classificationValue
              ] === null
            ) {
              monthValueByClassificationValueByMonthPeriod[dateBucket][
                classificationValue
              ] = 0;
            }
            monthValueByClassificationValueByMonthPeriod[dateBucket][
              classificationValue
            ] += row[valueIdx];
          }

          if (row[missingStatusIdx] === true) {
            dataStatus = DATA_STATUS.MISSING;
          }
        }
      }
    });

    let divisorValueByMonthPeriod = null;

    if (
      divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue] !==
      undefined
    ) {
      let filteredDivisorData = [];

      divisorValueByMonthPeriod = monthPeriods.reduce(
        (divisorValueByMonthPeriod, monthPeriod) => {
          divisorValueByMonthPeriod[monthPeriod] = null;
          return divisorValueByMonthPeriod;
        },
        {}
      );

      const divisorSubtopic =
        divisorValueToSubtopic[dashboardSelectedConfigHolder.divisorValue];

      const divisorSubtopicDataHolder =
        sourceDataHolder.getDataHolderForSubtopic(divisorSubtopic);
      const divisorColumns = divisorSubtopicDataHolder.cols;
      const divisorData = divisorSubtopicDataHolder.data;

      const divisorValueIdx = divisorColumns.indexOf("Value");
      const divisorMonthIdx = divisorColumns.indexOf("Month");
      const divisorYearIdx = divisorColumns.indexOf("Year");
      const divisorMissingStatusIdx = divisorColumns.indexOf("Missing Data");

      if (divisorSubtopic === "Occupied Room") {
        filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthsByYear,
          divisorYearIdx,
          divisorMonthIdx
        );
        filteredDivisorData = filterDataForSiteFacility(
          filteredDivisorData,
          dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
          siteNameIdx,
          facilityNameIdx
        );
      } else {
        filteredDivisorData = filterDataForMonthYear(
          divisorData,
          includedMonthsByYear,
          divisorYearIdx,
          divisorMonthIdx
        );

        filteredDivisorData = filterAreaDataForSiteFacility(
          filteredDivisorData,
          dashboardSelectedConfigHolder.getInventoryFilterForCalculation(),
          siteNameIdx,
          facilityNameIdx
        );
      }

      filteredDivisorData.forEach((row) => {
        const dateBucket = new Date(
          row[divisorYearIdx],
          row[divisorMonthIdx] - 1
        );

        if (divisorValueByMonthPeriod[dateBucket] !== undefined) {
          if (row[divisorValueIdx] !== NO_VALUE) {
            if (divisorValueByMonthPeriod[dateBucket] === null) {
              divisorValueByMonthPeriod[dateBucket] = 0;
            }
            divisorValueByMonthPeriod[dateBucket] += row[divisorValueIdx];
          }

          if (row[divisorMissingStatusIdx] === true) {
            dataStatus = DATA_STATUS.MISSING;
          }
        }
      });
    }

    return {
      value: monthValueByClassificationValueByMonthPeriod,
      divisorValue: divisorValueByMonthPeriod,
      status: dataStatus,
    };
  }

  static getFullUnit(elementConfig, dashboardHolderFacade) {
    const selectedConfigHolder = dashboardHolderFacade.selectedConfigHolder;

    const subtopic = elementConfig["subtopic"];
    if (elementConfig["formula"] === "composition") {
      return "%";
    }

    let divisorUnit = null;

    switch (selectedConfigHolder.divisorValue) {
      case "gfa-sqm":
      case "conditionedspace-sqm":
        divisorUnit = "sqm";
        break;
      case "gfa-sqft":
      case "conditionedspace-sqft":
        divisorUnit = "sqft";
        break;
      case "ops-occupiedroom":
        divisorUnit = "room";
        break;
    }

    const subtopicUnit = selectedConfigHolder.selectedUnitBySubtopic[subtopic];

    return divisorUnit === null
      ? subtopicUnit
      : `${subtopicUnit}/${divisorUnit}`;
  }

  static getDividendUnit(elementConfig, dashboardHolderFacade) {
    const selectedConfigHolder = dashboardHolderFacade.selectedConfigHolder;

    const subtopic = elementConfig["subtopic"];
    if (elementConfig["formula"] === "composition") {
      return "%";
    }

    return selectedConfigHolder.selectedUnitBySubtopic[subtopic];
  }

  static getDivisorUnit(elementConfig, dashboardHolderFacade) {
    const selectedConfigHolder = dashboardHolderFacade.selectedConfigHolder;

    let divisorUnit = null;

    switch (selectedConfigHolder.divisorValue) {
      case "gfa-sqm":
      case "conditionedspace-sqm":
        divisorUnit = "sqm";
        break;
      case "gfa-sqft":
      case "conditionedspace-sqft":
        divisorUnit = "sqft";
        break;
      case "ops-occupiedroom":
        divisorUnit = "room";
        break;
    }

    return divisorUnit;
  }

  static getIntensityDisplay(elementConfig, dashboardHolderFacade) {
    const selectedConfigHolder = dashboardHolderFacade.selectedConfigHolder;

    let intensityDisplay = null;

    switch (selectedConfigHolder.divisorValue) {
      case "gfa-sqm":
        intensityDisplay = "per GFA (sqm)";
        break;
      case "conditionedspace-sqm":
        intensityDisplay = "per Conditioned Space (sqm)";
        break;
      case "gfa-sqft":
        intensityDisplay = "per GFA (sqft)";
        break;
      case "conditionedspace-sqft":
        intensityDisplay = "per Conditioned Space (sqft)";
        break;
      case "ops-occupiedroom":
        intensityDisplay = "per Occupied Room";
        break;
    }

    return intensityDisplay;
  }
}
