import EnvironmentalDataRecordModel from "./EnvironmentalDataRecordModel";
import { DateTimeUtils } from "../../../services/UtilsService";
import MeterRecordsService from "../MeterRecordsService";

export class EnvironmentalMeterRecordsModel {
  constructor(meterRecords, facilityId, facilityName) {
    this.records = meterRecords["records"]?.map(
      (r) => new EnvironmentalDataRecordModel(facilityId, facilityName, r)
    );
    this.dataGaps = meterRecords["data_gaps"];
    this.usageOutOfRanges = meterRecords["usage_out_of_ranges"];
    this.costOutOfRanges = meterRecords["cost_out_of_ranges"];
    this.lastActivity = meterRecords["last_activity"];
    this.lastRecordDate = meterRecords["last_record_date"];
  }
}

export class EnvironmentalMeterDataHealthStatusModel {
  constructor(
    meterId,
    dataGaps,
    usageOutOfRanges,
    costOutOfRanges,
    lastActivity,
    lastRecordDate
  ) {
    this.meterId = meterId;
    this.dataGaps = dataGaps;
    this.usageOutOfRanges = usageOutOfRanges;
    this.costOutOfRanges = costOutOfRanges;
    this.lastActivity = lastActivity;
    this.lastRecordDate = lastRecordDate;
  }

  static fromDTO(dto) {
    return new EnvironmentalMeterDataHealthStatusModel(
      dto["meter_id"],
      dto["data_gaps"],
      dto["usage_out_of_ranges"],
      dto["cost_out_of_ranges"],
      dto["last_activity"],
      dto["last_record_date"]
    );
  }
}

export default class EnvironmentalMeterRecordsService extends MeterRecordsService {
  updateMeter(meter, meterRecords) {
    this.meter = meter;
    this.dataGaps = meterRecords.dataGaps || [];
    this.usageOutOfRanges = meterRecords.usageOutOfRanges || [];
    this.costOutOfRanges = meterRecords.costOutOfRanges || [];
    return this;
  }

  hasNoOutOfRanges() {
    return (
      (this.meter && !this.meter.enable_unusually_high_low_alert) ||
      (this.usageOutOfRanges.length === 0 && this.costOutOfRanges.length === 0)
    );
  }

  getOutOfRangeDataIssues() {
    if (this.meter && this.meter.enable_unusually_high_low_alert) {
      const usageIssues = this.usageOutOfRanges.map(
        (r) =>
          `Abnormally ${
            r["compared_to_previous"]
          } usage from ${DateTimeUtils.formatUTCDate(r["start"])} - 
        ${DateTimeUtils.formatUTCDate(r["end"])}`
      );
      const costIssues = this.costOutOfRanges.map(
        (r) =>
          `Abnormally ${
            r["compared_to_previous"]
          } cost from ${DateTimeUtils.formatUTCDate(r["start"])} - 
        ${DateTimeUtils.formatUTCDate(r["end"])}`
      );
      return [...usageIssues, ...costIssues];
    } else {
      return [];
    }
  }
}
