import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../../HOC/withAuthentication";
import {
  SYSTEM_TOOLBOX_CERTIFICATIONS,
  SYSTEM_TOOLBOX_CLIMATE_ZONE_BAILEYS_ECOREGION,
  SYSTEM_TOOLBOX_CLIMATE_ZONE_KOPPEN_GEIGER,
  SYSTEM_TOOLBOX_COUNTRY_LIST,
  SYSTEM_TOOLBOX_CURRENCY_LIST,
  SYSTEM_TOOLBOX_METROAREA_LIST,
  SYSTEM_TOOLBOX_PROVINCE_LIST,
  SYSTEM_TOOLBOX_SITE_FACILITY_OPTIONS,
} from "../../../config/ROUTES_NAME";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../common/LoadingSpinner";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import SiteFacilityTypeOptionsService from "../../../services/ReferenceDataServices/SiteFacilityTypeOptionsService";
import CountryService from "../../../services/CountryService";
import CurrencyService from "../../../services/CurrencyService";
import MetroAreaService from "../../../services/MetroAreaService";
import ClimateZoneService from "../../../services/ReferenceDataServices/ClimateZoneService";
import CertificationService from "../../../services/ReferenceDataServices/CertificationService";
import ToastContext from "../../../context/ToastContext";

const SiteFacilityTypeDataTable = (props) => {
  const toastContext = useContext(ToastContext);

  const [headerName, setHeaderName] = useState("SITE AND FACILITY DETAILS");
  const [activeKey, setActiveKey] = useState("SITE AND FACILITY DETAILS");
  const [siteFacilityOptions, setSiteFacilityOptions] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [metroAreas, setMetroArea] = useState([]);
  const [provincesList, setProvinces] = useState([]);
  const [koppenGeiger, setKoppenGeiger] = useState([]);
  const [baileysEcoregion, setBaileysEcoregion] = useState([]);
  const [certifications, setCertifications] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    SiteFacilityTypeOptionsService.getSiteFacilityTypeOptions()
      .then((response) => {
        setSiteFacilityOptions(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load site facility type options.</span>
        );
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    CountryService.getAllCountry()
      .then((response) => {
        setCountries(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load countries.</span>);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    CurrencyService.getAllCurrency()
      .then((response) => {
        setCurrencies(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load currencies.</span>);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    MetroAreaService.getAllMetroAreas()
      .then((response) => {
        setIsLoading(false);
        setMetroArea(response);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load metro areas.</span>);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    CountryService.getAllProvinces()
      .then((response) => {
        setIsLoading(false);
        setProvinces(response);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load provinces.</span>);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    ClimateZoneService.KoppenGeigerClimateZone()
      .then((response) => {
        setIsLoading(false);
        setKoppenGeiger(response);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load Koppen Geiger climate zone.</span>
        );
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    ClimateZoneService.BaileysEcoregionClimateZone()
      .then((response) => {
        setIsLoading(false);
        setBaileysEcoregion(response);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load Baileys Ecoregion climate zone.</span>
        );
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    CertificationService.GetAllCertifications()
      .then((response) => {
        setIsLoading(false);
        setCertifications(response);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load certifications.</span>);
      });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <section>
        <Accordion
          key={headerName}
          defaultActiveKey={activeKey}
          activeKey={activeKey === headerName ? activeKey : null}
          className="accordion-header"
        >
          <Accordion.Item eventKey={headerName}>
            <Card.Header
              onClick={() =>
                activeKey === headerName
                  ? setActiveKey(null)
                  : setActiveKey(headerName)
              }
            >
              <div className="body-2-bold">
                {headerName.toUpperCase()}{" "}
                <span className="collapse-toggle">
                  {activeKey === headerName ? "(collapse)" : "(expand)"}
                </span>
              </div>
            </Card.Header>
            <Accordion.Body>
              <Table className="reference-data-table section-box">
                <tbody>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_CERTIFICATIONS)
                        }
                      >
                        Certifications
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {certifications.length > 0 &&
                        certifications
                          .slice(0, 2)
                          .map((certification) => (
                            <span key={certification.id}>
                              {certification.name + ", "}
                            </span>
                          ))}
                      {certifications.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(
                            SYSTEM_TOOLBOX_CLIMATE_ZONE_BAILEYS_ECOREGION
                          )
                        }
                      >
                        Climate Zones (Bailey’s Ecoregion)
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {baileysEcoregion.length > 0 &&
                        baileysEcoregion
                          .slice(0, 2)
                          .map((be) => (
                            <span key={be.id}>{be.name + ", "}</span>
                          ))}
                      {baileysEcoregion.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(
                            SYSTEM_TOOLBOX_CLIMATE_ZONE_KOPPEN_GEIGER
                          )
                        }
                      >
                        Climate Zones (Köppen-Geiger)
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {koppenGeiger.length > 0 &&
                        koppenGeiger
                          .slice(0, 2)
                          .map((kg) => (
                            <span key={kg.id}>{kg.name + ", "}</span>
                          ))}
                      {koppenGeiger.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_COUNTRY_LIST)
                        }
                      >
                        Countries
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {countries.length > 0 &&
                        countries
                          .slice(0, 2)
                          .map((country) => (
                            <span key={country.id}>{country.name + ", "}</span>
                          ))}
                      {countries.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_CURRENCY_LIST)
                        }
                      >
                        Currencies
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {currencies.length > 0 &&
                        currencies
                          .slice(0, 2)
                          .map((currency) => (
                            <span key={currency.id}>
                              {currency.name + ", "}
                            </span>
                          ))}
                      {currencies.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_METROAREA_LIST)
                        }
                      >
                        Metro Area
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {metroAreas.length > 0 &&
                        metroAreas
                          .slice(0, 2)
                          .map((ma) => (
                            <span key={ma.id}>{ma.name + ", "}</span>
                          ))}
                      {metroAreas.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(
                            SYSTEM_TOOLBOX_SITE_FACILITY_OPTIONS
                          )
                        }
                      >
                        Site/Facility Types
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {siteFacilityOptions.length > 0 &&
                        siteFacilityOptions
                          .slice(0, 2)
                          .map((option) => (
                            <span key={option.id}>{option.name + ", "}</span>
                          ))}
                      {siteFacilityOptions.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_PROVINCE_LIST)
                        }
                      >
                        States/Provinces
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {provincesList.length > 0 &&
                        provincesList
                          .slice(0, 2)
                          .map((pl) => (
                            <span key={pl.id}>{pl.name + ", "}</span>
                          ))}
                      {provincesList.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </>
  );
};

export default withAuthentication(SiteFacilityTypeDataTable);
