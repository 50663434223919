import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  CERTIFICATION_CONTENT_PAGE,
  CERTIFICATION_HOMEPAGE,
} from "../../config/ROUTES_NAME";
import withAuthentication from "../HOC/withAuthentication";
import { PageWrapper } from "../../gvds-components/Nav/PageWrapper/PageWrapper";
import CertificationHomepage from "./Homepage/CertificationHomepage";
import CertificationContentPage from "./ContentPage/CertificationContentPage";
import { CertificationContextProvider } from "./CertificationContext";

const Certifications = () => {
  return (
    <Route
      path={CERTIFICATION_HOMEPAGE}
      render={() => (
        <CertificationContextProvider>
          <Switch>
            <Route
              exact
              path={CERTIFICATION_HOMEPAGE}
              render={() => (
                <PageWrapper configRouteName={CERTIFICATION_HOMEPAGE}>
                  <CertificationHomepage />
                </PageWrapper>
              )}
            />
            <Route
              path={CERTIFICATION_CONTENT_PAGE}
              render={() => (
                <PageWrapper configRouteName={CERTIFICATION_CONTENT_PAGE}>
                  <CertificationContentPage />
                </PageWrapper>
              )}
            />
          </Switch>
        </CertificationContextProvider>
      )}
    />
  );
};

export default Certifications;
