import { Editor } from "react-draft-wysiwyg";
import { GVDSFormErrorMessage } from "../../gvds-components/Forms/GVDSFormShared";
import React, { forwardRef, useEffect, useState } from "react";
import { EditorState, Modifier } from "draft-js";

const TextEditor = forwardRef(
  (
    {
      editorState,
      onEditorStateChange,
      placeholder,
      toolbar,
      statusMetadata,
      ...props
    },
    ref
  ) => {
    const [isAddingLink, setIsAddingLink] = useState(false);

    useEffect(() => {
      if (isAddingLink) {
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const anchorOffset = selection.getAnchorOffset();

        if (anchorOffset > 0) {
          const newSelection = selection.merge({
            anchorOffset: anchorOffset - 1,
            focusOffset: anchorOffset,
          });

          const newContentState = Modifier.removeRange(
            contentState,
            newSelection,
            "backward"
          );

          const newEditorState = EditorState.push(
            editorState,
            newContentState,
            "backspace-character"
          );

          onEditorStateChange(newEditorState);
        }

        setIsAddingLink(false);
      }
    }, [isAddingLink]);

    const handleLinkCallback = (linkData) => {
      /*
    Cannot implement backspace after adding link here directly.
    After return, the editor will update editor state again, replace update that happens here.
    That why we use useEffect to update after editor update.
     */
      setIsAddingLink(true);

      return linkData;
    };

    if (toolbar) {
      const toolbarLink = toolbar.link ?? {};
      toolbarLink["linkCallback"] = handleLinkCallback;
      toolbar["link"] = toolbarLink;
    }

    return (
      <>
        <Editor
          ref={ref}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName="rtf-wrapper"
          editorClassName="rtf-editor"
          placeholder={placeholder}
          toolbar={toolbar}
          {...props}
        />
        {statusMetadata.isNotNormal() && statusMetadata.message && (
          <GVDSFormErrorMessage
            status={statusMetadata.state}
            errorMsg={statusMetadata.message}
          />
        )}
      </>
    );
  }
);

export default TextEditor;
