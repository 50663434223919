import {
  DonutChartDataModel,
  OTHERS_LEGEND_LABEL,
} from "../../Models/DashboardModels";
import { classificationDonutChartConfig } from "./ClassificationDonutChartViewConfig";
import { DashboardColorPalette } from "../DashboardColorPalette";
import { NumberService } from "../../../../services/UtilsService";
import { smallValueRepresentation } from "../DashboardWidgetSharedUtils";

export class ClassificationDonutChartUtils {
  static getDisplayedData(isExpandLegends, data) {
    if (data.length === 0) {
      return [];
    }

    let displayedData;

    if (!isExpandLegends) {
      displayedData = data.slice(
        0,
        classificationDonutChartConfig.minLegendItemCount
      );
    } else if (
      data.length <= classificationDonutChartConfig.maxLegendItemCount
    ) {
      displayedData = data;
    } else {
      displayedData = data.slice(
        0,
        classificationDonutChartConfig.maxLegendItemCount - 1
      );
      const otherValue = ClassificationDonutChartUtils.getTotalValue(
        data.slice(classificationDonutChartConfig.maxLegendItemCount - 1)
      );
      displayedData.push(
        new DonutChartDataModel(
          OTHERS_LEGEND_LABEL,
          otherValue,
          DashboardColorPalette.slice(-1)
        )
      );
    }

    return displayedData;
  }

  static getTotalValue(data) {
    return data.reduce((total, data) => total + data.value, 0);
  }

  static getPieValue(pieValue, totalValue) {
    if (pieValue === 0) {
      return 0;
    }

    const minPieValue = smallValueRepresentation * totalValue;

    return Math.max(pieValue, minPieValue);
  }

  static getTypeDisplayValue(
    value,
    isHumanize = false,
    minimumFractionDigits = 0
  ) {
    if (value === null || value === undefined) {
      return null;
    } else if (value < smallValueRepresentation && value > 0) {
      return `<${smallValueRepresentation}`;
    } else if (isHumanize) {
      return NumberService.humanize(value);
    } else {
      return NumberService.format(value, 2, minimumFractionDigits);
    }
  }
}
