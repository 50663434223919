import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

import UserService from "../../services/UserService";
import ToastContext from "../../context/ToastContext";
import { TermsOfUseContent } from "../TermsAndConditions/TermsOfUse";
import GVFormGroup from "../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";

const COMPONENT_STATE = {
  TERMS_AND_CONDITIONS: "Terms and Conditions",
  SET_PROFILE: "Set Profile",
};

const isStringEntryEmpty = (entry) => {
  return !entry || entry.length === 0;
};

const FirstTimeSetupDialog = ({ show, user, onSuccess }) => {
  const toastContext = useContext(ToastContext);

  const [termsAndConditionsDetails, setTermsAndConditionsDetails] =
    useState(null);

  const [isValidated, setIsValidated] = useState(false);
  const [componentState, setComponentState] = useState(null);

  useEffect(() => {
    setComponentState(COMPONENT_STATE.TERMS_AND_CONDITIONS);
  }, [show]);

  useEffect(() => {
    setTermsAndConditionsDetails(user);
  }, [user]);

  const onChangeTermsOfUse = (event) => {
    setTermsAndConditionsDetails({
      ...termsAndConditionsDetails,
      accepted_terms_conditions: event.target.checked,
    });
  };
  const onChangePrivacyPolicy = (event) => {
    setTermsAndConditionsDetails({
      ...termsAndConditionsDetails,
      accepted_privacy_policy: event.target.checked,
    });
  };

  const setInputTextValue = (value, eventName) => {
    setTermsAndConditionsDetails({
      ...termsAndConditionsDetails,
      [eventName]: value,
    });
  };

  const acceptTermsAndCondition = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsValidated(true);
    if (
      termsAndConditionsDetails.accepted_terms_conditions &&
      termsAndConditionsDetails.accepted_privacy_policy
    ) {
      setIsValidated(false);
      if (
        isStringEntryEmpty(termsAndConditionsDetails.full_name) ||
        isStringEntryEmpty(termsAndConditionsDetails.position)
      ) {
        setComponentState(COMPONENT_STATE.SET_PROFILE);
      } else {
        saveData();
      }
    }
  };

  const updateProfileDetails = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsValidated(true);

    if (
      !isStringEntryEmpty(termsAndConditionsDetails.full_name) &&
      !isStringEntryEmpty(termsAndConditionsDetails.position)
    ) {
      saveData();
    }
  };

  const saveData = async () => {
    UserService.updateTermsAndConditions(user.id, termsAndConditionsDetails)
      .then(() => {
        closeWelcomeMessage();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to save first time setup. Please try again.</span>
        );
      });
  };

  const closeWelcomeMessage = () => {
    if (onSuccess) {
      onSuccess();
    }
    setComponentState(null);
  };

  return (
    <>
      <GVDSModal
        title="Terms of Use"
        size={GVDSModal.Size.medium}
        show={componentState === COMPONENT_STATE.TERMS_AND_CONDITIONS}
        className="terms-dialog"
        hideCloseButton={true}
      >
        <GVDSModal.Body>
          <div className="terms-of-use-container">{TermsOfUseContent()}</div>
          <div style={{ marginTop: "24px" }}>
            <Form.Check
              name="terms_of_use"
              type="checkbox"
              id="terms_of_use"
              label={
                <>
                  I understand and accept Greenview’s
                  <a href="/terms-of-use" target="_blank">
                    {" "}
                    Terms of Use
                  </a>
                  .
                </>
              }
              checked={
                termsAndConditionsDetails
                  ? termsAndConditionsDetails.accepted_terms_conditions
                  : false
              }
              onChange={(event) => onChangeTermsOfUse(event)}
            />
            {isValidated &&
              !termsAndConditionsDetails.accepted_terms_conditions && (
                <div className="manual-invalid-feedback checkbox-margin">
                  Please accept the Terms of Use in order to proceed.
                </div>
              )}
            <Form.Check
              name="privacy_policy"
              type="checkbox"
              id="privacy_policy"
              label={
                <>
                  I understand and consent to Personal Data to be used in
                  accordance with Greenview’s
                  <a href="/privacy-policy" target="_blank">
                    {" "}
                    Privacy Policy
                  </a>
                  .
                </>
              }
              checked={
                termsAndConditionsDetails
                  ? termsAndConditionsDetails.accepted_privacy_policy
                  : false
              }
              onChange={(event) => onChangePrivacyPolicy(event)}
            />
            {isValidated &&
              !termsAndConditionsDetails.accepted_privacy_policy && (
                <div className="manual-invalid-feedback checkbox-margin">
                  Please accept the Privacy Policy in order to proceed.
                </div>
              )}
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={acceptTermsAndCondition}
            disabled={
              !termsAndConditionsDetails?.accepted_terms_conditions ||
              !termsAndConditionsDetails?.accepted_privacy_policy
            }
            text={
              termsAndConditionsDetails &&
              !isStringEntryEmpty(termsAndConditionsDetails.full_name) &&
              !isStringEntryEmpty(termsAndConditionsDetails.position)
                ? "Finish"
                : "Continue"
            }
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <GVDSModal
        title="Setup your profile"
        size={GVDSModal.Size.small}
        show={componentState === COMPONENT_STATE.SET_PROFILE}
        hideCloseButton={true}
      >
        <GVDSModal.Body>
          <div className="mb-3">
            We need some of your details so other team members know it's you.
          </div>
          <GVFormGroup controlId="fullName">
            <Form.Label>Full Name</Form.Label>
            <GVDSFormField
              name="fullName"
              placeholder="Enter full name"
              value={termsAndConditionsDetails?.full_name}
              onInput={(value) => setInputTextValue(value, "full_name")}
              statusMetadata={
                isValidated &&
                isStringEntryEmpty(termsAndConditionsDetails?.full_name)
                  ? FormFieldStatusMetadata.getError("Name cannot be empty.")
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup controlId="position">
            <Form.Label>Position</Form.Label>
            <GVDSFormField
              name="position"
              placeholder="Please enter position"
              value={termsAndConditionsDetails?.position}
              onInput={(value) => setInputTextValue(value, "position")}
              statusMetadata={
                isValidated &&
                isStringEntryEmpty(termsAndConditionsDetails?.position)
                  ? FormFieldStatusMetadata.getError(
                      "Position cannot be empty."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            className="btn-terms_condition"
            variant={buttonVariant.primary}
            onClick={updateProfileDetails}
            text="Finish setup"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default FirstTimeSetupDialog;
