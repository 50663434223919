import React, { useContext, useEffect, useState } from "react";
import { uniq } from "lodash";

import HelpArticleForm from "./HelpArticleForm";
import HelpArticleAdminService from "../../../services/HelpArticleAdminService";
import { DateTimeUtils } from "../../../services/UtilsService";
import { ViewFileItemDisplayWithAuth } from "../../common/FileAttachments";
import withAuthentication from "../../HOC/withAuthentication";
import LoadingSpinner from "../../common/LoadingSpinner";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import DeleteHelpArticlePrompt from "./DeleteHelpArticlePrompt";
import ToastContext from "../../../context/ToastContext";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit } from "@tabler/icons-react";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import Spacer from "../../../gvds-components/Layout/Spacer";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Container from "react-bootstrap/Container";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import { ContentTypeLabelDisplay } from "../../common/ContentTypeDisplay";

const ViewAllHelpArticles = () => {
  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(true);

  const [helpArticleOptions, setHelpArticleOptions] = useState(null);

  const [allHelpArticles, setAllHelpArticles] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [currentlyEditedArticle, setCurrentlyEditedArticle] = useState(null);

  const loadHelpArticles = () => {
    setIsLoading(true);

    HelpArticleAdminService.getHelpArticleOptions().then((options) => {
      setHelpArticleOptions(options);
    });

    HelpArticleAdminService.getAllHelpArticles()
      .then((helpArticles) => {
        setAllHelpArticles(helpArticles);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load help guides.</span>);
      });
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    allHelpArticles,
    SystemToolboxSearchKeysByPageName.helpArticles
  );

  useEffect(() => {
    loadHelpArticles();
  }, []);

  const columns = [
    {
      header: "Title",
      headerStyle: { width: "300px" },
      dataKey: "title",
      sortable: true,
    },
    {
      header: "Format",
      headerStyle: { width: "150px" },
      dataKey: "article_type_name",
      sortable: true,
      renderer: (a) => {
        return (
          <ContentTypeLabelDisplay articleTypeName={a.article_type_name} />
        );
      },
    },
    {
      header: "File Name",
      headerStyle: { width: "300px" },
      dataKey: "file_name",
      renderer: (a) => {
        return (
          <ViewFileItemDisplayWithAuth file={a.file} useS3PresignedURL={true} />
        );
      },
    },
    {
      header: "Description",
      headerStyle: { width: "300px" },
      dataKey: "description",
    },
    {
      header: "Category",
      headerStyle: { width: "200px" },
      dataKey: "category_name",
      sortable: true,
    },
    {
      header: "Relevant Articles",
      headerStyle: { width: "300px" },
      dataKey: "relevantArticles",
      renderer: (a) => {
        return a.relevantArticles
          .map((ra) => {
            return `${ra.title} (${ra.article_type.name})`;
          })
          .join(", ");
      },
    },
    {
      header: "Last Updated",
      headerStyle: { width: "300px", maxWidth: "300px" },
      dataKey: "lastEditedOn",
      sortable: true,
      renderer: (a) => {
        const user = a.lastEditedBy;
        const userDisplay =
          user.full_name && user.full_name.length > 0
            ? user.full_name
            : user.email;
        return (
          <div
            style={{
              wordBreak: "break-all",
              overflowWrap: "break-word",
            }}
          >
            {userDisplay}{" "}
            <em>({DateTimeUtils.formatUTCDate(a.lastEditedOn)}</em>)
          </div>
        );
      },
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "160px" },
      renderer: (a) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => {
                setShowForm(true);
                setCurrentlyEditedArticle(a);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />

            <DeleteHelpArticlePrompt
              article={a}
              onDeleteSuccess={() => loadHelpArticles()}
            />
          </>
        );
      },
    },
  ];

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Help Guides</h1>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={() => {
              setShowForm(true);
              setCurrentlyEditedArticle(null);
            }}
            icon={<GVDSIcon Icon={IconCirclePlus} />}
            text="Create Guide"
          />
        </PageHeader.Title>
      </PageHeader>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <GVDSTableCtrlContainer>
            <FilterSearchBox
              className="article-search-box"
              placeholder="Search for a guide"
              value={searchText}
              onInput={setSearchText}
            />
            <GVDSTableCtrlMultiSelect
              options={uniq(allHelpArticles.map((q) => q.category_name))}
              prefix="Category"
              onChange={(filterKs) =>
                setFilterKeys({ ...filterKeys, category_name: filterKs })
              }
            />
            <GVDSTableCtrlMultiSelect
              options={uniq(allHelpArticles.map((q) => q.article_type_name))}
              prefix="Format"
              onChange={(filterKs) =>
                setFilterKeys({ ...filterKeys, article_type_name: filterKs })
              }
            />
            <GVDSPagination
              startIndex={startIndex}
              endIndex={endIndex}
              total={totalDataLength}
              onChange={onPaginationChange}
            />
          </GVDSTableCtrlContainer>
          <GVDSTable
            columns={columns}
            dataToDisplay={currentPageData}
            startIndex={startIndex}
            sortKeys={sortKeys}
            setSortKeys={setSortKeys}
          />
          {allHelpArticles.length === 0 ? (
            <div className="table__no_content">No help guides</div>
          ) : (
            filteredSortedData.length === 0 && (
              <div className="table__no_content">
                No help guides. Try changing your filter.
              </div>
            )
          )}
          <HelpArticleForm
            show={showForm}
            setShow={setShowForm}
            currentArticle={currentlyEditedArticle}
            helpArticleOptions={helpArticleOptions}
            onSuccess={loadHelpArticles}
          />
        </>
      )}
    </Container>
  );
};

export default withAuthentication(ViewAllHelpArticles);
