import axios from "axios";
import { API_URL } from "../../config/api-config";

export default class CertificationService {
  static GetAllCertifications = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/certifications"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static createCertification = async (name, list) => {
    const data = {
      name: name,
      status_options: list,
    };
    try {
      return await axios.post(
        API_URL + "/api/v1/ref_data/certifications",
        data
      );
    } catch (error) {
      throw error;
    }
  };

  static updateCertification = async (certId, name, list) => {
    const data = {
      name: name,
      status_options: list,
    };

    try {
      const response = await axios.patch(
        API_URL + `/api/v1/ref_data/certifications/${certId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getCertificationById = async (certId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/certifications/" + certId
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static deleteDataRecords = async (certId) => {
    try {
      const response = await axios.delete(
        API_URL + "/api/v1/ref_data/certifications/" + certId
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}
