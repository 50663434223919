import axios from "axios";
import { API_URL } from "../../config/api-config";
import { type_hotel } from "../../components/Site/SiteFacilityInformationComponents";

export default class SiteFacilityTypeOptionsService {
  static getSiteFacilityTypeOptions = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/site_facility_type_options"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static siteFacilityTypeSortFn = (type1, type2) => {
    if (type1.name.toLowerCase() === type_hotel) {
      return -1;
    } else if (type2.name.toLowerCase() === type_hotel) {
      return 1;
    } else {
      return type1.name.localeCompare(type2.name);
    }
  };
}
