import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import capitalize from "lodash/capitalize";
import reduce from "lodash/reduce";
import uniq from "lodash/uniq";

import {
  OPERATIONAL_DATA,
  OPERATIONAL_DATA_BULK_INPUT,
} from "../../../config/ROUTES_NAME";
import withAuthentication from "../../HOC/withAuthentication";
import {
  getDefaultMonthRangeEnd,
  getDefaultMonthRangeStart,
} from "../../common/MonthRangeSelector";
import OperationMeterRecordsService, {
  OperationalMeterRecordsModel,
} from "./OperationMeterRecordsService";
import OperationalService from "../../../services/OperationalService";
import LoadingSpinner from "../../common/LoadingSpinner";
import { DateTimeUtils, NumberService } from "../../../services/UtilsService";
import { MeterStatus } from "../MeterRecordsService";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import InputOperationalDataRecordModal from "./InputOperationalDataRecordModal";
import {
  OPERATIONAL_METER_TYPE_FLOAT,
  PERMISSIONS,
  RESOURCES,
} from "../../../config/constants";
import DataRequestService from "../../../services/DataRequestService";
import DeleteOperationalDataRecordPrompt from "./DeleteOperationalDataRecordPrompt";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "../../common/ReactHook";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSDropdownToggle from "../../../gvds-components/Buttons/GVDSDropdownToggle";
import InputAlertPreferencesModal from "../InputAlertPreferencesModal";
import GVFormGroup from "../../common/GVFormGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import MeterDismissAlertsBanner from "../MeterDismissAlertsBanner";
import MeterPossibleIssuesBanner from "../MeterPossibleIssuesBanner";
import MeterPossibleIssuesTurnedOffBanner from "../MeterPossibleIssuesTurnedOffBanner";
import { TextCopies } from "../../../config/text-copies";
import { useTranslation } from "react-i18next";

const ViewOperationalMeterDetails = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const toastContext = useContext(ToastContext);
  const [start, setStart] = useState(getDefaultMonthRangeStart());
  const [end, setEnd] = useState(getDefaultMonthRangeEnd());

  const [meter, setMeter] = useState({});
  const [dataset, setDataset] = useState();

  const [newDataRequestDetails, setNewDataRequestDetails] = useState({});
  const [dataRequests, setDataRequests] = useState([]);
  const [hasMissingDataRequest, setHasMissingDataRequest] = useState(false);

  const [meterRecords, setMeterRecords] = useState({});
  const [showInputModal, setShowInputModal] = useState(false);
  const [showAlertPreferencesModal, setShowAlertPreferencesModal] =
    useState(false);

  const handleCloseEditModal = () => {
    setCurrentlyEditedRecordModel(null);
    setShowInputModal(false);
  };

  const [dataIssues, setDataIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataRequestsLoading, setIsDataRequestsLoading] = useState(true);
  const [isLastRecordDateLoading, setIsLastRecordDateLoading] = useState(false);
  const [currentEditRecordModel, setCurrentlyEditedRecordModel] = useState();
  const meterService = useRef(new OperationMeterRecordsService());
  const [
    currentlyToBeDeletedRecordModels,
    setCurrentlyToBeDeletedRecordModels,
  ] = useState([]);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);

  const pastDataMeterRecords = useMemo(
    () => meterRecords.records || [],
    [meterRecords.records]
  );

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(pastDataMeterRecords, null);

  useEffect(() => {
    const userInventoryFacilityIds = userInventory.facilities.get.map(
      (facility) => facility.id
    );
    const selectedMeterFacilityId = location.state?.dataset?.facility?.id;

    if (
      location.state &&
      userInventoryFacilityIds.includes(selectedMeterFacilityId)
    ) {
      setMeter(location.state.meter);
      setDataset(location.state.dataset);
    } else {
      backToDashboard();
    }
  }, []);

  useEffect(() => {
    if (previousInventory && previousInventory !== selectedInventory) {
      backToDashboard();
    }
  }, [selectedInventory]);

  useEffect(() => {
    if (dataset && dataset["facility"]?.id) {
      loadDataRequests(dataset["facility"]["id"]).then(() =>
        setIsDataRequestsLoading(false)
      );
    }
  }, [dataset]);

  useEffect(() => {
    if (newDataRequestDetails.id) {
      setDataRequests([...dataRequests, newDataRequestDetails]);
    }
  }, [newDataRequestDetails.id]);

  useEffect(() => {
    if (meter.id) {
      setHasMissingDataRequest(
        reduce(
          dataRequests,
          (result, dr) =>
            result ||
            dr.hasMissingDataForMeter(
              meter.id,
              meter.start_date,
              meter.end_date
            ),
          false
        )
      );
    }
  }, [meter, dataRequests]);

  useEffect(() => {
    if (meter.id) {
      loadDataRecords();
    }
  }, [meter, start, end]);

  const loadDataRequests = async (facilityId) => {
    const dRequests = await DataRequestService.getOngoingDataRequests(
      facilityId,
      RESOURCES.FACILITY
    );
    for (let dId of uniq(dRequests.map((r) => r.id))) {
      const details = await DataRequestService.getDataRequest(
        dId,
        facilityId,
        RESOURCES.FACILITY
      );
      setNewDataRequestDetails(details);
    }
  };

  const loadDataRecords = () => {
    setIsLoading(true);
    OperationalService.getDataRecordsForMeter(
      selectedInventory.type,
      selectedInventory.id,
      meter.id,
      start,
      end
    )
      .then((data) => {
        const newMeterRecords = new OperationalMeterRecordsModel(
          data,
          dataset["facility"]["name"]
        );
        setMeterRecords(newMeterRecords);
        meterService.current.updateMeter(meter, newMeterRecords);
        setDataIssues(meterService.current.getDataIssues(end));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load operational data records for meter.</span>
        );
      });
  };

  const loadLastRecordDate = () => {
    setIsLastRecordDateLoading(true);
    OperationalService.getMeterLastRecordDate(
      meter["id"],
      selectedInventory.type,
      selectedInventory.id
    )
      .then((data) => {
        setMeter({
          ...meter,
          last_record_date:
            DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(data),
        });
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to load last record date for meter.</span>
        );
      })
      .finally(() => {
        setIsLastRecordDateLoading(false);
      });
  };

  const reloadAllData = () => {
    setIsDataRequestsLoading(true);
    setDataRequests([]);
    loadDataRecords();
    loadDataRequests(dataset["facility"]["id"]).then(() =>
      setIsDataRequestsLoading(false)
    );
    loadLastRecordDate();
  };

  const backToDashboard = () => {
    history.push(OPERATIONAL_DATA);
  };

  const goToBulkInput = () => {
    history.push(
      getRedirectURLWithCurrentParam(OPERATIONAL_DATA_BULK_INPUT, location)
    );
  };

  const showEditRecord = (recordModel) => {
    setCurrentlyEditedRecordModel(recordModel);
    setShowInputModal(true);
  };

  const promptDeleteRecord = (recordModels) => {
    setCurrentlyToBeDeletedRecordModels(recordModels);
    setShowPromptDeleteModal(true);
  };

  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  const deleteCurrentlySelectedRecordModel = () => {
    OperationalService.deleteDataRecords(
      selectedInventory.type,
      selectedInventory.id,
      currentlyToBeDeletedRecordModels.map((model) => model.id)
    ).then(() => {
      toastContext.addSuccessToast(
        <span>Data record deleted successfully</span>
      );
      setCurrentlyToBeDeletedRecordModels([]);
      reloadAllData();
      handleClosePromptDeleteModal();
    });
  };

  const renderFormattedValue = (meterType, value) => {
    if (
      meterType === OPERATIONAL_METER_TYPE_FLOAT.AVG_WORKERS ||
      meterType === OPERATIONAL_METER_TYPE_FLOAT.AVG_FTE
    ) {
      return NumberService.format(value, 2, 2);
    } else {
      return NumberService.format(value, 0, 0);
    }
  };

  if (!meter.id) {
    return <div>No meter found.</div>;
  }

  const meterDetails = (
    <div>
      <Form>
        <Row>
          <Col lg={4}>
            <GVFormGroup>
              <Form.Label>Facility</Form.Label>
              <div className="gvds-text--body">
                {dataset["facility"]["name"]}
              </div>
            </GVFormGroup>
          </Col>
          <Col lg={4}>
            <GVFormGroup>
              <Form.Label>Type</Form.Label>
              <div className="gvds-text--body">
                {meter["operation_type"]["name"]}
              </div>
            </GVFormGroup>
          </Col>
          <Col lg={4}>
            <GVFormGroup>
              <Form.Label>Frequency</Form.Label>
              <div className="gvds-text--body d-flex flex-row align-items-center">
                <span className="me-1">{capitalize(meter["frequency"])} </span>
                <InfoTooltip
                  info={
                    <div>
                      <div className="mb-1">
                        {
                          TextCopies.EnvironmentalOperational
                            .meterFrequencyDescription
                        }
                      </div>
                      <div>
                        {`(${TextCopies.EnvironmentalOperational.meterFrequencyExample}).`}
                      </div>
                    </div>
                  }
                  placement="top"
                />
              </div>
            </GVFormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );

  const getPastRecordTable = () => {
    const columns = [
      {
        header: "Period From",
        dataKey: "periodFrom",
        sortable: true,
        renderer: (d) => DateTimeUtils.formatUTCDate(d["periodFrom"]),
      },
      {
        header: "Period To",
        dataKey: "periodTo",
        sortable: true,
        renderer: (d) => DateTimeUtils.formatUTCDate(d["periodTo"]),
      },
      {
        header: "Value",
        dataKey: "value",
        sortable: true,
        renderer: (d) =>
          renderFormattedValue(meter["operation_type"]["name"], d["value"]),
      },
      {
        header: "Comment",
        dataKey: "comments",
        sortable: true,
        renderer: (d) =>
          d["comments"] ? <CommentTooltip comment={d["comments"]} /> : null,
      },
      {
        header: ACTIONS_TABLE_HEADER,
        dataKey: ACTIONS_DATAKEY,
        renderer: (d) => {
          return (
            <>
              {!permissionCtx.isLoadingPermissions &&
                permissionCtx.permissions[
                  PERMISSIONS.OPERATIONAL_RECORD_EDIT
                ] && (
                  <GVDSIconButton
                    variant={iconButtonVariant.tertiary}
                    icon={<GVDSIcon Icon={IconEdit} />}
                    onClick={(e) => {
                      e.preventDefault();
                      showEditRecord(d);
                    }}
                    tooltipText="Edit"
                  />
                )}

              {!permissionCtx.isLoadingPermissions &&
                permissionCtx.permissions[
                  PERMISSIONS.OPERATIONAL_RECORD_DELETE
                ] && (
                  <GVDSIconButton
                    variant={iconButtonVariant.destructive}
                    icon={<GVDSIcon Icon={IconTrash} />}
                    onClick={() => {
                      promptDeleteRecord([d]);
                    }}
                    tooltipText="Delete"
                  />
                )}
            </>
          );
        },
      },
    ];
    return (
      <GVDSTable
        className="past-records"
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        noContent={
          <div className="table__no_content">
            <p>No data records found. Try adjusting the date range.</p>
          </div>
        }
        viewControl={
          <GVDSTableCtrlContainer>
            <div className="date-range-container">
              <GVDSFormStartEndMonthPicker
                startMonth={start}
                endMonth={end}
                onChange={(s, e) => {
                  if (s !== start) {
                    setStart(s);
                  }
                  if (e !== end) {
                    setEnd(e);
                  }
                }}
              />
            </div>
            <GVDSPagination
              startIndex={startIndex}
              endIndex={endIndex}
              total={totalDataLength}
              onChange={onPaginationChange}
            />
          </GVDSTableCtrlContainer>
        }
      />
    );
  };
  const pastRecords = (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : !meter["enable_data_gaps_alert"] &&
        !meter["enable_unusually_high_low_alert"] ? (
        <MeterPossibleIssuesTurnedOffBanner
          showAlertPreferencesModal={() => setShowAlertPreferencesModal(true)}
        />
      ) : (
        <>
          <MeterDismissAlertsBanner
            dismissAlertsDate={meter["dismiss_alerts_date"]}
            showAlertPreferencesModal={() => setShowAlertPreferencesModal(true)}
          />
          {(meterService.current.getMeterDetailStatus(end) ===
            MeterStatus.ISSUES_DETECTED ||
            hasMissingDataRequest) && (
            <MeterPossibleIssuesBanner
              dataIssues={dataIssues}
              dataRequests={dataRequests}
              meter={meter}
              showAlertPreferencesModal={() =>
                setShowAlertPreferencesModal(true)
              }
            />
          )}
        </>
      )}

      {isLoading || !meterRecords.records ? (
        <LoadingSpinner />
      ) : (
        getPastRecordTable()
      )}
    </>
  );

  const onAlertPreferencesUpdate = (
    dismissAlertDate,
    enableDataGapsAlert,
    enableAbnormalUsageCost,
    effectivePeriodStartDate,
    effectivePeriodEndDate
  ) => {
    setShowAlertPreferencesModal(false);
    setMeter({
      ...meter,
      dismiss_alerts_date: DateTimeUtils.getUTCISOString(dismissAlertDate),
      enable_data_gaps_alert: enableDataGapsAlert,
      enable_unusually_high_low_alert: enableAbnormalUsageCost,
      start_date: DateTimeUtils.getUTCISOString(effectivePeriodStartDate),
      end_date: DateTimeUtils.getUTCISOString(effectivePeriodEndDate),
    });
  };

  const lastRecordDate = isLastRecordDateLoading ? (
    <LoadingSpinner />
  ) : (
    meterService.current.getLastRecordDateDisplay(t, meter.last_record_date)
  );

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text="Return to Data Input"
          onClick={backToDashboard}
        />
        <PageHeader.Title>
          <div>
            <h1>Default Meter</h1>
            {lastRecordDate && (
              <div className="gvds-text--body mt-1">{lastRecordDate}</div>
            )}
          </div>
          <Spacer />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.OPERATIONAL_METER_MANAGEMENT
            ] && (
              <GVDSButton
                variant={buttonVariant.secondary}
                className="btn-setup-alert-preferences"
                onClick={() => setShowAlertPreferencesModal(true)}
                text="Alert preferences"
              />
            )}
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.OPERATIONAL_RECORD_CREATE
            ] && (
              <Dropdown className="ms-auto">
                <Dropdown.Toggle as={GVDSDropdownToggle}>Input</Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item onClick={() => setShowInputModal(true)}>
                    <GVDSIcon Icon={IconCirclePlus} />
                    Single Record
                  </Dropdown.Item>
                  <Dropdown.Item onClick={goToBulkInput}>
                    <GVDSIcon Icon={IconCirclePlus} />
                    Bulk Records
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
        </PageHeader.Title>
      </PageHeader>
      {meterDetails}
      {pastRecords}
      <InputOperationalDataRecordModal
        show={showInputModal}
        onClose={handleCloseEditModal}
        datasets={[dataset]}
        meter={meter}
        currentRecord={currentEditRecordModel}
        onDoneInput={reloadAllData}
      />
      <DeleteOperationalDataRecordPrompt
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        proceed={deleteCurrentlySelectedRecordModel}
      />
      <InputAlertPreferencesModal
        show={showAlertPreferencesModal}
        setShow={setShowAlertPreferencesModal}
        facilityId={dataset["facility"]?.id}
        meterId={meter["id"]}
        oldDismissAlertDate={
          meter["dismiss_alerts_date"] &&
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            meter["dismiss_alerts_date"]
          )
        }
        oldEnableDataGapsAlert={meter["enable_data_gaps_alert"]}
        oldEnableAbnormalUsageCost={meter["enable_unusually_high_low_alert"]}
        oldEffectivePeriodStartDate={
          meter["start_date"] &&
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            meter["start_date"]
          )
        }
        oldEffectivePeriodEndDate={
          meter["end_date"] &&
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(meter["end_date"])
        }
        updateAlertPreferences={OperationalService.updateAlertPreferences}
        onAlertPreferencesUpdate={onAlertPreferencesUpdate}
      />
    </div>
  );
};

export default withAuthentication(ViewOperationalMeterDetails);
