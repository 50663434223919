import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import { RESOURCES } from "../../../../../config/constants";
import sortBy from "lodash/sortBy";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit } from "@tabler/icons-react";
import GVDSButton, {
  buttonVariant,
} from "../../../../../gvds-components/Buttons/GVDSButton";
import UserInventoryContext from "../../../../../context/UserInventoryContext";
import GVDSTableExpandableRow, {
  expandableIconCell,
} from "../../../../../gvds-components/Table/GVDSTableExpandableRow";
import GVDSTableDisplay from "../../../../../gvds-components/Table/GVDSTableDisplay";
import { useTranslation } from "react-i18next";

const MarketPurchaseTypeTrackerDisplay = ({
  offsetType,
  trackersByType,
  meterDetails,
  expandedTypes,
  toggleExpandedType,
  onTrackerToggle,
  onTrackerCreate,
  onTrackerEdit,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const trackers = trackersByType[offsetType.id];
  const isOffsetTypeTracked = !_.isEmpty(trackers);
  const isExpanded = expandedTypes.indexOf(offsetType.id) >= 0;

  const trackersCount = trackers ? trackers.length : 0;

  const controlColumns = [
    <td className="market-purchase-offset-table__tracking">
      <Form.Check
        type="switch"
        label=""
        id={offsetType.id}
        checked={isOffsetTypeTracked}
        onChange={() => onTrackerToggle(offsetType)}
        className="market-purchase-offset-tracker-setup__switch"
      />
    </td>,
    trackersCount > 0 ? expandableIconCell(isExpanded) : <td />,
  ];

  const dataColumns = [
    <td className="market-purchase-offset-table__type">
      <div>{offsetType.name}</div>
    </td>,
    <td className="market-purchase-offset-table__tracker_count">
      <div className="text-center">
        <strong>{trackersCount > 0 ? trackersCount : null}</strong>
      </div>
    </td>,
    <td className="market-purchase-offset-table__definition">
      <div>{offsetType.definition}</div>
    </td>,
  ];

  return (
    <GVDSTableExpandableRow
      isExpanded={isExpanded}
      toggleIsExpanded={() => toggleExpandedType(offsetType.id)}
      disabled={trackersCount === 0}
      controlColumns={controlColumns}
      dataColumns={dataColumns}
      expandedBody={
        <div>
          <GVDSTableDisplay>
            <thead>
              <tr>
                <th>
                  {t(
                    "data-management.environmental.market-purchase.tracker-id"
                  )}
                </th>
                {selectedInventory.type === RESOURCES.SITE && (
                  <th>{t("shared.facility")}</th>
                )}
                <th>
                  {t(
                    "data-management.environmental.market-purchase.table-header-usage-meter"
                  )}
                </th>
                <th>{t("shared-input-label.description")}</th>
                <th>{t("shared-input-label.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(trackers, "tracker_id").map((tracker) => (
                <tr key={tracker.id}>
                  <td>{tracker.tracker_id}</td>
                  {userInventory.selectedInventory.get.type ===
                    RESOURCES.SITE && <td>{tracker["facility"]["name"]}</td>}
                  <td>
                    <ul>
                      {tracker["environmental_meters"].map(
                        (m) =>
                          meterDetails[m.id] && (
                            <li key={m.id}>
                              {meterDetails[m.id].environmentalTypeName} -{" "}
                              {m.name}
                            </li>
                          )
                      )}
                    </ul>
                  </td>
                  <td>{tracker.description}</td>
                  <td>
                    <GVDSIconButton
                      className="market-purchase-offset-table__edit-button"
                      variant={iconButtonVariant.tertiary}
                      icon={<GVDSIcon Icon={IconEdit} />}
                      tooltipText={t("shared.edit")}
                      onClick={() => onTrackerEdit(tracker)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </GVDSTableDisplay>
          <div className="action-row-in-expanded-row">
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={() => onTrackerCreate(offsetType)}
              icon={<GVDSIcon Icon={IconCirclePlus} />}
              text={t("data-management.environmental.setup.button-add-tracker")}
            />
          </div>
        </div>
      }
    />
  );
};

export default MarketPurchaseTypeTrackerDisplay;
