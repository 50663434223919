import React, { useContext, useEffect, useRef, useState } from "react";
import GVDSButton, { buttonVariant } from "../../Buttons/GVDSButton";
import { IconCheck, IconCirclePlus, IconSettings } from "@tabler/icons-react";
import GVDSIcon from "../../Icons/GVDSIcon";
import UserInventoryContext from "../../../context/UserInventoryContext";
import {
  MAIN_PORTFOLIO,
  PERMISSIONS,
  RESOURCES,
  smoothScrollBehaviour,
} from "../../../config/constants";
import WorkspaceInventoryIcon from "../../common/WorkspaceInventoryIcon";
import Spacer from "../../Layout/Spacer";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../Buttons/GVDSIconButton";
import WorkspaceNavContext from "./WorkspaceNavContext";
import PermissionsContext from "../../../context/PermissionsContext";
import {
  DeleteSubPortfolio,
  InputSubPortfolio,
} from "../../../components/Contract/Portfolio/InputSubPortfolio";
import FilterSearchBox from "../../common/FilterSearchBox";
import fuzzysort from "fuzzysort";
import {
  fuzzysortOptionsWithKeys,
  workspaceNavPortfolioSelectorSearchKeys,
} from "../../../config/search-config";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { popupDelaySlowStart } from "../../../config/style-config";

const PortfolioDisplay = ({
  portfolio,
  isMainPortfolio = false,
  onFilterSiteBySubportfolio,
  hasManagePermission,
  onManagePortfolio,
}) => {
  const workspaceNavContext = useContext(WorkspaceNavContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const portfolioRef = useRef();

  useEffect(() => {
    if (isSelected && portfolioRef.current) {
      portfolioRef.current.scrollIntoView(smoothScrollBehaviour);
    }
  }, []);

  if (!selectedInventory) {
    return null;
  }

  const isSelected =
    selectedInventory.type === RESOURCES.PORTFOLIO &&
    selectedInventory.id === portfolio.id;

  const navigateToPortfolio = (id) => {
    userInventory.selectedInventory.set(RESOURCES.PORTFOLIO, id);
    if (isMainPortfolio) {
      onFilterSiteBySubportfolio(null);
    } else {
      onFilterSiteBySubportfolio(id);
    }
    workspaceNavContext.close();
  };

  const classNames = ["portfolio-item"];

  if (isSelected) {
    classNames.push("selected");
  }

  const combinedClassName = classNames.join(" ");

  const noOfSites = portfolio.sites.length;
  const noOfFacilities = portfolio.facilities.length;

  let childrenDisplay;

  if (noOfSites > 0) {
    childrenDisplay = `${noOfSites} site${noOfSites === 1 ? "" : "s"}`;
  } else {
    const facilityDisplay = `${noOfFacilities} facilit${
      noOfFacilities === 1 ? "y" : "ies"
    }`;
    childrenDisplay =
      childrenDisplay && childrenDisplay.length > 0
        ? childrenDisplay + ", " + facilityDisplay
        : facilityDisplay;
  }

  return (
    <OverlayTrigger
      delay={popupDelaySlowStart}
      placement="top"
      overlay={<Tooltip>{childrenDisplay}</Tooltip>}
    >
      <div
        ref={portfolioRef}
        className={combinedClassName}
        onClick={() => navigateToPortfolio(portfolio.id)}
      >
        <GVDSIcon Icon={WorkspaceInventoryIcon[RESOURCES.PORTFOLIO]} />
        <div className="name">{portfolio.name}</div>
        {isSelected && (
          <div className="selected-icon">
            <GVDSIcon Icon={IconCheck} />
          </div>
        )}
        {!isMainPortfolio && (
          <>
            <Spacer />
            {hasManagePermission && (
              <GVDSIconButton
                className="action-button"
                variant={iconButtonVariant.tertiary}
                icon={<GVDSIcon Icon={IconSettings} />}
                tooltipText="Manage sub-portfolio"
                onClick={(e) => {
                  e.stopPropagation();
                  onManagePortfolio();
                }}
              />
            )}
            <GVDSIconButton
              className="action-button"
              variant={iconButtonVariant.tertiary}
              icon={<GVDSIcon Icon={WorkspaceInventoryIcon[RESOURCES.SITE]} />}
              tooltipText="Show sites"
              onClick={(e) => {
                e.stopPropagation();
                onFilterSiteBySubportfolio(portfolio.id);
              }}
            />
          </>
        )}
      </div>
    </OverlayTrigger>
  );
};

const WorkspacePortfolioSelector = ({ onFilterSiteBySubportfolio }) => {
  const permissionCtx = useContext(PermissionsContext);

  const userInventory = useContext(UserInventoryContext);
  const selectedContractId = userInventory.selectedContractId.get;
  const portfoliosUnderSelectedContract =
    userInventory.portfoliosUnderSelectedContract.get;
  const subportfolios = portfoliosUnderSelectedContract.filter(
    (p) => p.name !== MAIN_PORTFOLIO
  );
  const mainPortfolio = portfoliosUnderSelectedContract.find(
    (p) => p.name === MAIN_PORTFOLIO
  );
  const portfolioById = portfoliosUnderSelectedContract.reduce(function (
    portfolioById,
    portfolioObj
  ) {
    portfolioById[portfolioObj.id] = portfolioObj;
    return portfolioById;
  },
  {});

  const [currentPortfolioDetails, setCurrentPortfolioDetails] = useState();

  const [showInputSubPortfolioModal, setShowInputSubPortfolioModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseInputSubPortfolio = () => {
    setShowInputSubPortfolioModal(false);
  };
  const handleShowInputSubPortfolio = (portfolioId) => {
    setCurrentPortfolioDetails(portfolioById[portfolioId]);
    setShowInputSubPortfolioModal(true);
  };
  const handleCancelDeleteModal = () => {
    setShowDeleteModal(false);
    setShowInputSubPortfolioModal(true);
  };

  const [searchText, setSearchText] = useState("");

  const onUpdateSubPortfolioInventory = () => {
    userInventory.loadUserInventory();
  };

  const canManageSubPortfolio =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.CONTRACT_PORTFOLIO_MANAGEMENT];

  const filteredSubportfolios =
    !searchText || searchText.length === 0
      ? subportfolios
      : fuzzysort
          .go(
            searchText,
            subportfolios,
            fuzzysortOptionsWithKeys(workspaceNavPortfolioSelectorSearchKeys)
          )
          .map((result) => result.obj);

  return (
    <div className="workspace-portfolio-selector">
      <FilterSearchBox
        className="search-box"
        placeholder="Search for a portfolio"
        value={searchText}
        onInput={setSearchText}
      />
      <PortfolioDisplay
        portfolio={mainPortfolio}
        isMainPortfolio={true}
        onFilterSiteBySubportfolio={onFilterSiteBySubportfolio}
      />
      <div className="subportfolios-header">Sub-portfolios</div>
      <div>
        {filteredSubportfolios.map((p) => (
          <PortfolioDisplay
            key={p.id}
            portfolio={p}
            onFilterSiteBySubportfolio={onFilterSiteBySubportfolio}
            hasManagePermission={canManageSubPortfolio}
            onManagePortfolio={() => handleShowInputSubPortfolio(p.id)}
          />
        ))}
      </div>
      {canManageSubPortfolio && (
        <div className="create-subportfolio-container">
          <GVDSButton
            variant={buttonVariant.tertiary}
            icon={<GVDSIcon Icon={IconCirclePlus} />}
            text="Create sub-portfolio"
            onClick={() => handleShowInputSubPortfolio(null)}
          />
        </div>
      )}
      {canManageSubPortfolio && (
        <InputSubPortfolio
          show={showInputSubPortfolioModal}
          onClose={handleCloseInputSubPortfolio}
          currentPortfolio={currentPortfolioDetails}
          contractId={selectedContractId}
          onSuccess={onUpdateSubPortfolioInventory}
          deleteTriggerComponent={
            <GVDSButton
              text="Delete Sub-Portfolio"
              variant={buttonVariant.destructive_tertiary}
              onClick={() => {
                setShowInputSubPortfolioModal(false);
                setShowDeleteModal(true);
              }}
            />
          }
        />
      )}
      {currentPortfolioDetails && (
        <DeleteSubPortfolio
          show={showDeleteModal}
          currentPortfolio={currentPortfolioDetails}
          onSuccess={onUpdateSubPortfolioInventory}
          onClose={handleCancelDeleteModal}
        />
      )}
    </div>
  );
};

export default WorkspacePortfolioSelector;
