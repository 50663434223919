import React, { Fragment, useRef, useState } from "react";
import { Group } from "@visx/group";
import { BarStackHorizontal } from "@visx/shape";
import { AxisBottom, AxisLeft, AxisTop } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { GVDSColors } from "../../../../styles/gvds-colors";
import GridColumns from "@visx/grid/lib/grids/GridColumns";
import {
  DashboardWidgetSharedUtils,
  YAxisTick,
} from "../DashboardWidgetSharedUtils";
import { barChartMaxCount } from "./SummarySiteRankingViewConfig";
import { NumberService } from "../../../../services/UtilsService";
import Popover from "react-bootstrap/Popover";
import { SummarySiteRankingChartUtils } from "./SummarySiteRankingChartUtils";
import { Text } from "@visx/text";
import Overlay from "react-bootstrap/Overlay";
import {
  dashboardChartBackgroundColor,
  DashboardColorPalette,
} from "../DashboardColorPalette";
import {
  barChartRadius,
  DashboardEnvClassificationDataTooltipModel,
  expandedViewSingleHorizontalBarChartHeightInPx,
  singleHorizontalBarChartHeightInPx,
} from "../../Models/DashboardModels";
import { DashboardBarChartSharedUtils } from "../DashboardBarChartSharedUtils";
import DashboardEnvClassificationDataTooltip from "../DashboardEnvClassificationDataTooltip";

const gridMargin = 13;
const gridLineCount = 5;
const barGapInPx = 11;
const expandedViewBarGapInPx = 9;

const StackedBarTooltipContent = ({
  siteName,
  types,
  barData,
  unit,
  intensityUnit,
}) => {
  const displayedUnit = intensityUnit ? `${unit}/${intensityUnit}` : unit;
  const amountContents = types.map((type, index) => {
    const barValue = barData[type] === undefined ? 0 : barData[type];

    return new DashboardEnvClassificationDataTooltipModel(
      barValue,
      displayedUnit,
      type,
      DashboardWidgetSharedUtils.calculatePercentage(
        barValue,
        barData.siteTotalValue
      ),
      DashboardColorPalette[index]
    );
  });
  return (
    <div className="dashboard--chart__popover-tooltip-container">
      <div className="popover-tooltip-label">Site</div>
      <div className="popover-tooltip-value">
        <strong>{siteName}</strong>
      </div>
      <div className="popover-tooltip-label">Amount</div>
      <DashboardEnvClassificationDataTooltip contents={amountContents} />
      <div className="popover-tooltip-label">Total</div>
      <div className="popover-tooltip-value">
        <strong>{NumberService.format(barData.siteTotalValue)}</strong>{" "}
        {displayedUnit}
      </div>
    </div>
  );
};

const SingleBar = ({
  xPosition,
  yPosition,
  barWidth,
  barHeight,
  barRadius = 0,
  barRadiusSpace = 0,
  barColor,
  isShowSeparator = false,
  barSeparatorWidth = 0,
  barSeparatorColor = GVDSColors.white,
}) => {
  const svgPathData = SummarySiteRankingChartUtils.getSVGPathData(
    xPosition,
    yPosition,
    barWidth,
    barHeight,
    barRadius,
    barRadiusSpace
  );

  return (
    <>
      <svg width={barWidth + xPosition + 1} height={barHeight + yPosition}>
        <path d={svgPathData} fill={barColor} />
      </svg>
      {isShowSeparator && (
        <rect
          x={xPosition}
          y={yPosition}
          width={barSeparatorWidth}
          height={barHeight}
          fill={barSeparatorColor}
        />
      )}
    </>
  );
};

const StackedBar = ({
  barGroup,
  tooltipContent,
  totalBarsValue,
  isExpandedView,
}) => {
  const ref = useRef(null);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const lastBar = barGroup[barGroup.length - 1];
  const lastBarWidth =
    lastBar.width < DashboardBarChartSharedUtils.minimumBarSizeInPx
      ? DashboardBarChartSharedUtils.minimumBarSizeInPx
      : lastBar.width;
  const barValueTextPosition = {
    x: lastBar.x + lastBarWidth + 4,
    y:
      lastBar.y +
      (isExpandedView ? expandedViewBarGapInPx : barGapInPx) +
      lastBar.height / 4,
  };

  return (
    <>
      <Group
        innerRef={ref}
        onMouseOver={() => setIsShowTooltip(true)}
        onMouseLeave={() => setIsShowTooltip(false)}
      >
        {barGroup.map((bar, index) => {
          let barWidth = bar.width;
          let xPosition = bar.x;
          const isBarWidthUnderMinimumSize =
            barWidth < DashboardBarChartSharedUtils.minimumBarSizeInPx;
          const isLastBar = index === barGroup.length - 1;

          if (isLastBar) {
            if (isBarWidthUnderMinimumSize) {
              barWidth = DashboardBarChartSharedUtils.minimumBarSizeInPx;
            }
          }

          return (
            <SingleBar
              key={index}
              xPosition={xPosition}
              yPosition={bar.y}
              barWidth={barWidth}
              barHeight={bar.height}
              barRadius={index < barGroup.length - 1 ? 0 : barChartRadius}
              barRadiusSpace={
                isBarWidthUnderMinimumSize
                  ? DashboardBarChartSharedUtils.minimumBarSizeInPx
                  : barChartRadius
              }
              barColor={bar.color}
            />
          );
        })}
      </Group>
      <Text
        x={barValueTextPosition.x}
        y={barValueTextPosition.y}
        className="dashboard--bar-chart-total-value"
      >
        {NumberService.humanize(totalBarsValue)}
      </Text>
      <Overlay show={isShowTooltip} placement="top" target={ref.current}>
        <Popover>
          <Popover.Body>
            <div>{tooltipContent}</div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

const HorizontalStackedBarChart = ({
  width,
  height,
  margin,
  chartData,
  chartKeys,
  chartColors,
  xAxisScaleMaxValue,
  intensityUnit,
  unit,
  availableBarSpaceCount = barChartMaxCount,
  isExpandedView,
}) => {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const leftoverBarCount = availableBarSpaceCount - chartData.length;
  const singleBarWithGapHeightInPx = isExpandedView
    ? expandedViewSingleHorizontalBarChartHeightInPx + expandedViewBarGapInPx
    : singleHorizontalBarChartHeightInPx + barGapInPx;
  const filledHeight = yMax - leftoverBarCount * singleBarWithGapHeightInPx;

  const types = chartKeys;
  const colors = chartColors;

  const additionalXAxisScale = 0.1 * xAxisScaleMaxValue; //to trigger the next grid line
  const xAxisScale = scaleLinear({
    domain: [0, xAxisScaleMaxValue + additionalXAxisScale],
    range: [0, xMax],
    round: true,
    nice: true,
  });
  const yAxisScale = scaleBand({
    domain: chartData.map((d) => d.siteName),
    range: [0, filledHeight],
    round: true,
    padding: 0.3,
  });
  const colorScale = scaleOrdinal({
    domain: types,
    range: colors,
  });

  const xAxisLabel = intensityUnit
    ? `Intensity (${unit}/${intensityUnit})`
    : unit;

  const gridColumnsTopPosition = isExpandedView
    ? margin.bottom
    : margin.top + gridMargin;
  const chartTopPosition = isExpandedView
    ? margin.bottom - gridMargin
    : margin.top;

  return (
    <div>
      {isExpandedView && <div className="x-axis-label mt-0">{xAxisLabel}</div>}
      <svg width={width} height={height}>
        <rect
          width={width}
          height={height}
          fill={dashboardChartBackgroundColor}
        />
        <GridColumns
          numTicks={gridLineCount}
          top={gridColumnsTopPosition}
          left={margin.left}
          scale={xAxisScale}
          height={yMax - 2 * gridMargin}
          stroke={GVDSColors.gray4}
        />
        <Group top={chartTopPosition} left={margin.left}>
          <BarStackHorizontal
            data={chartData}
            keys={types}
            height={yMax}
            y={(datum) => datum.siteName}
            xScale={xAxisScale}
            yScale={yAxisScale}
            color={colorScale}
          >
            {(barStacks) => {
              const barGroups =
                DashboardBarChartSharedUtils.groupBars(barStacks);

              return barGroups.map((barGroup, index) => {
                const barData = barGroup[0].bar.data;

                return (
                  <StackedBar
                    key={index}
                    barGroup={barGroup}
                    tooltipContent={
                      <StackedBarTooltipContent
                        siteName={barData.siteName}
                        barData={barData}
                        types={types}
                        unit={unit}
                        intensityUnit={intensityUnit}
                      />
                    }
                    totalBarsValue={barData.siteTotalValue}
                    isExpandedView={isExpandedView}
                  />
                );
              });
            }}
          </BarStackHorizontal>
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yAxisScale}
            tickComponent={(value) => (
              <YAxisTick
                width={margin.left}
                x={value.x}
                y={value.y}
                value={value.formattedValue}
              />
            )}
            tickValues={chartData.map((d) => d.siteName)}
          />
          {isExpandedView ? (
            <AxisTop
              hideAxisLine
              hideTicks
              numTicks={gridLineCount}
              top={margin.top}
              scale={xAxisScale}
              tickFormat={(value) => NumberService.humanize(value)}
            />
          ) : (
            <AxisBottom
              hideAxisLine
              hideTicks
              numTicks={gridLineCount}
              top={yMax}
              scale={xAxisScale}
              tickFormat={(value) => NumberService.humanize(value)}
            />
          )}
        </Group>
      </svg>
      {!isExpandedView && <div className="x-axis-label">{xAxisLabel}</div>}
    </div>
  );
};

export default HorizontalStackedBarChart;
