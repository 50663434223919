import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";

import LoadingSpinner from "../common/LoadingSpinner";
import CompanyService from "../../services/CompanyService";
import GVFormGroup from "../common/GVFormGroup";
import { getOptionByValueFromIdName } from "../common/Forms/SingleSelect";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";

const SelectCompany = ({ selected, onCompanySelected, isValidated }) => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    CompanyService.getAllCompanies()
      .then((result) => {
        setCompanies(result);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onSelect = (selectedOption) => {
    onCompanySelected(selectedOption.value);
  };

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <GVFormGroup controlId="selectCompany">
        <Form.Label>Company</Form.Label>
        <GVDSFormSingleSelect
          className="select__company"
          placeholder="Select Company"
          value={
            selected ? getOptionByValueFromIdName(selected, companies) : null
          }
          options={companies.map((c) => {
            return { value: c.id, label: c.name };
          })}
          onSelect={onSelect}
          statusMetadata={
            isValidated && !selected ? FormFieldStatusMetadata.getError("Please select a company.") : FormFieldStatusMetadata.getDefault()
          }
        />
      </GVFormGroup>
    );
  }

  return <div>{content}</div>;
};

export default SelectCompany;
