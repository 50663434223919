import React, { useContext, useEffect, useState } from "react";
import HotelTypesService from "../../../../services/ReferenceDataServices/HotelAndAccommodationAttributes/HotelTypeService";
import FilterSearchBox from "../../../../gvds-components/common/FilterSearchBox";
import { Container } from "react-bootstrap";
import { REFERENCE_DATA } from "../../../../config/ROUTES_NAME";
import withAuthentication from "../../../HOC/withAuthentication";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../../common/LoadingSpinner";
import ToastContext from "../../../../context/ToastContext";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../../config/search-config";
import GVDSTable, {SORTING_TYPES} from "../../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";

const HotelTypes = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [hotelTypes, setHotelTypes] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    hotelTypes,
    SystemToolboxSearchKeysByPageName.referenceData.hotelType,
    {
      name: SORTING_TYPES.asc,
    }
  );

  const loadRecord = () => {
    setIsLoading(true);
    HotelTypesService.GetHotelTypes()
      .then((response) => {
        setHotelTypes(response);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load hotel types.</span>);
      });
  };

  useEffect(() => {
    loadRecord();
  }, []);

  const columns = [
    {
      header: "Hotel Type",
      dataKey: "name",
      sortable: true,
      renderer: (q) => <div>{q.name}</div>,
    },
  ];

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <Container fluid>
        <PageHeader>
          <PageHeader.BackButton
            text="Return To Reference Data"
            onClick={goToReferenceData}
          />
          <PageHeader.Title>
            <h1>Hotel Type</h1>
          </PageHeader.Title>
        </PageHeader>
        <GVDSTableCtrlContainer>
          <FilterSearchBox
            placeholder="Search by Hotel type"
            value={searchText}
            onInput={setSearchText}
          />
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        </GVDSTableCtrlContainer>
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
        />
        {filteredSortedData.length === 0 && (
          <div className="table__no_content">No record found</div>
        )}
      </Container>
    </div>
  );
};

export default withAuthentication(HotelTypes);
