import React, { useContext, useEffect, useRef, useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons/faArrowCircleUp";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons/faArrowCircleDown";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";

import {
  SURVEY_QUESTION_TYPE,
  SURVEY_QUESTION_TYPE_LABEL,
} from "../../../config/constants";
import LoadingSpinner from "../../common/LoadingSpinner";
import SurveyManagementService from "../../../services/SurveyManagementService";
import SurveyBestPracticeQuestionAdd from "./SurveyBestPracticeQuestionLink";
import GVFormGroup from "../../common/GVFormGroup";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons/faArrowsUpDown";
import MoveSurveyQuestionDialog from "./MoveSurveyQuestionDialog";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormTextArea from "../../../gvds-components/Forms/GVDSFormTextArea";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";

const questionTypeOptions = [
  SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE,
  SURVEY_QUESTION_TYPE.CHECKBOXES,
  SURVEY_QUESTION_TYPE.SHORT_ANSWER,
  SURVEY_QUESTION_TYPE.QUESTION_GROUP,
];

const nestedQuestionTypeOptions = [
  SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE,
  SURVEY_QUESTION_TYPE.CHECKBOXES,
  SURVEY_QUESTION_TYPE.SHORT_ANSWER,
];

const QuestionDisplay = ({
  question,
  onChange,
  index,
  isNested,
  parentQuestion,
  nestedIndex,
  showSurveyValidationError,
  disabled,
}) => {
  return (
    <div
      className={
        isNested
          ? "survey-question-content nested-question"
          : "survey-question-content"
      }
    >
      <div className="survey-question-number">
        {index + 1}.{isNested ? `${nestedIndex + 1}.` : null}
      </div>
      <div className="survey-question-detail-and-options">
        <div className="survey-question-detail">
          <GVFormGroup className="flex-grow-1" style={{ marginRight: "24px" }}>
            <Form.Label>Question</Form.Label>
            <GVDSFormTextArea
              rows={1}
              name={`question-text-${index + 1}`}
              placeholder="Type your question here"
              value={question.questionText}
              onInput={(value) => {
                question.questionText = value;
                onChange();
              }}
              statusMetadata={
                showSurveyValidationError && question.isQuestionTextEmpty()
                  ? FormFieldStatusMetadata.getError("Need question text.")
                  : FormFieldStatusMetadata.getDefault()
              }
              disabled={disabled}
            />
          </GVFormGroup>
          <GVFormGroup className="w-25">
            <Form.Label>Question Type</Form.Label>
            <GVDSFormSingleSelect
              className="select__question-type"
              value={
                question.questionType
                  ? {
                      value: question.questionType,
                      label: SURVEY_QUESTION_TYPE_LABEL[question.questionType],
                    }
                  : null
              }
              options={(isNested
                ? nestedQuestionTypeOptions
                : questionTypeOptions
              ).map((questionType) => {
                return {
                  value: questionType,
                  label: SURVEY_QUESTION_TYPE_LABEL[questionType],
                };
              })}
              onSelect={(selectedOption) => {
                question.changeQuestionType(selectedOption.value);
                onChange();
              }}
              disabled={disabled}
            />
          </GVFormGroup>
        </div>
        <GVFormGroup className="flex-grow-1">
          <Form.Label>
            Description <span className="optional-form-label">(optional)</span>
          </Form.Label>
          <GVDSFormTextArea
            rows={1}
            placeholder="Add a longer description to your question"
            value={question.description}
            onInput={(value) => {
              question.description = value;
              onChange();
            }}
            disabled={disabled}
          />
        </GVFormGroup>
        {(question.questionType === SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE ||
          question.questionType === SURVEY_QUESTION_TYPE.CHECKBOXES) && (
          <GVFormGroup>
            <Form.Label>Options</Form.Label>
            {showSurveyValidationError &&
              question.isOptionsLessThanRequired() && (
                <GVDSFormErrorMessage status={FormFieldState.error} errorMsg="Need at least 2 options" />
              )}
            {question
              .getNotDeletedOptions()
              .map((option, optionIndex, filteredOptions) => {
                return (
                  <div
                    className="d-flex align-items-center mb-2"
                    key={`option-${option.id}`}
                  >
                    <Form.Check
                      disabled
                      label=""
                      type={
                        question.questionType ===
                        SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE
                          ? "radio"
                          : question.questionType ===
                            SURVEY_QUESTION_TYPE.CHECKBOXES
                          ? "checkbox"
                          : null
                      }
                    />
                    <GVDSFormField
                      placeholder={`Option ${optionIndex + 1}`}
                      name={`qn-${index + 1}-option-${optionIndex + 1}`}
                      value={option.name}
                      onInput={(value) => {
                        option.name = value;
                        onChange();
                      }}
                      className="w-50"
                      statusMetadata={
                        showSurveyValidationError && option.isOptionTextEmpty()
                          ? FormFieldStatusMetadata.getError()
                          : FormFieldStatusMetadata.getDefault()
                      }
                      disabled={disabled}
                    />
                    {!disabled && (
                      <>
                        <GVDSIconButton
                          variant={iconButtonVariant.tertiary}
                          disabled={
                            optionIndex === 0 && filteredOptions.length > 1
                          }
                          onClick={() => {
                            question.moveOptionUp(option);
                            onChange();
                          }}
                          icon={<FontAwesomeIcon icon={faArrowCircleUp} />}
                          tooltipText="Move question up"
                        />
                        <GVDSIconButton
                          variant={iconButtonVariant.tertiary}
                          disabled={optionIndex === filteredOptions.length - 1}
                          onClick={() => {
                            question.moveOptionDown(option);
                            onChange();
                          }}
                          icon={<FontAwesomeIcon icon={faArrowCircleDown} />}
                          tooltipText="Move question down"
                        />
                        <GVDSIconButton
                          variant={iconButtonVariant.destructive}
                          onClick={() => {
                            question.deleteOption(option);
                            onChange();
                          }}
                          icon={<FontAwesomeIcon icon={faTrash} />}
                          tooltipText="Delete"
                        />
                      </>
                    )}
                  </div>
                );
              })}
            {!disabled && (
              <div className="d-flex align-items-center">
                <GVDSButton
                  variant={buttonVariant.tertiary}
                  onClick={() => {
                    question.addOption();
                    onChange();
                  }}
                  icon={<GVDSIcon Icon={IconCirclePlus} />}
                  text="Add Option"
                />
                {question.isAllOptionsEmpty() &&
                  question.questionType ===
                    SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE && (
                    <>
                      <div className="color-gray56">or populate with</div>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => {
                          question.populateOptionsYesNo();
                          onChange();
                        }}
                      >
                        Y/N options
                      </Button>
                      <div className="color-gray56">or</div>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => {
                          question.populateOptionsPercent();
                          onChange();
                        }}
                      >
                        % options
                      </Button>
                    </>
                  )}
              </div>
            )}
          </GVFormGroup>
        )}
        {!disabled && isNested && (
          <div className="survey-question-nested-actions">
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              disabled={
                nestedIndex === 0 &&
                parentQuestion.getNotDeletedChildQns().length > 1
              }
              onClick={() => {
                parentQuestion.moveChildQnUp(question);
                onChange();
              }}
              icon={<FontAwesomeIcon icon={faArrowCircleUp} />}
              tooltipText="Move question up"
            />
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              disabled={
                nestedIndex ===
                parentQuestion.getNotDeletedChildQns().length - 1
              }
              onClick={() => {
                parentQuestion.moveChildQnDown(question);
                onChange();
              }}
              icon={<FontAwesomeIcon icon={faArrowCircleDown} />}
              tooltipText="Move question down"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                parentQuestion.deleteChildQn(question);
                onChange();
              }}
              icon={<FontAwesomeIcon icon={faTrash} />}
              tooltipText="Delete"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const SurveyBestPracticeQuestionDisplay = ({ question, index }) => {
  return (
    <div className={"survey-question-content"}>
      <div className="survey-question-number">{index + 1}</div>
      <div className="survey-question-detail-and-options">
        <div className="survey-question-detail">
          <GVFormGroup className="flex-grow-1" style={{ marginRight: "24px" }}>
            <Form.Label>Question</Form.Label>
            <GVDSFormTextArea rows={1} disabled value={question.questionText} />
          </GVFormGroup>
          <GVFormGroup className="w-25">
            <Form.Label>Question Type</Form.Label>
            <GVDSFormSingleSelect
              value={
                question.questionType
                  ? {
                      value: question.questionType,
                      label: SURVEY_QUESTION_TYPE_LABEL[question.questionType],
                    }
                  : null
              }
              disabled
            />
          </GVFormGroup>
        </div>
        <GVFormGroup className="flex-grow-1">
          <Form.Label>
            Description <span className="optional-form-label">(optional)</span>
          </Form.Label>
          <GVDSFormTextArea rows={1} value={question.description} disabled />
        </GVFormGroup>

        <GVFormGroup>
          <Form.Label>Options</Form.Label>
          {question.options.map((option, index) => {
            return (
              <div
                className="d-flex align-items-center mb-2"
                key={`option-${option.id}`}
              >
                <Form.Check
                  disabled
                  label=""
                  type={
                    question.questionType ===
                    SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE
                      ? "radio"
                      : question.questionType ===
                        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
                      ? "checkbox"
                      : null
                  }
                />
                <GVDSFormField
                  placeholder={`Option ${index + 1}`}
                  name={`option-${index + 1}`}
                  value={option.name}
                  className="w-50"
                  disabled
                />
              </div>
            );
          })}
        </GVFormGroup>
      </div>
    </div>
  );
};

const QuestionFooter = ({ question, onChange, onDelete, onMove, disabled }) => {
  if (disabled) {
    return null;
  }

  return (
    <div className="survey-question-footer">
      {question.questionType === SURVEY_QUESTION_TYPE.QUESTION_GROUP && (
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={() => {
            question.addChildQuestion();
            onChange();
          }}
          icon={<GVDSIcon Icon={IconCirclePlus} />}
          text="Add a question within this question group"
        />
      )}
      <Spacer />
      <GVDSButton
        variant={buttonVariant.tertiary}
        className="btn-move"
        onClick={onMove}
        icon={<FontAwesomeIcon icon={faArrowsUpDown} />}
        text="Move"
      />
      <GVDSButton
        variant={buttonVariant.destructive_tertiary}
        onClick={onDelete}
        icon={<FontAwesomeIcon icon={faTrash} />}
        text="Delete"
      />
    </div>
  );
};

const SurveyQuestionForm = ({
  surveyModel,
  onChange,
  onQuestionMoved,
  showSurveyValidationError,
  isAllowedToManageSurvey,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [availableBestPracticeQns, setAvailableBestPracticeQns] = useState([]);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [questionToBeMoved, setQuestionToBeMoved] = useState(null);
  const questionContainerRefs = useRef([]);

  const closeMoveDialog = () => {
    if (!isLoading) {
      setQuestionToBeMoved(null);
      setShowMoveDialog(false);
    }
  };
  const promptMoveQuestion = (question) => {
    setQuestionToBeMoved(question);
    setShowMoveDialog(true);
  };

  const scrollToMovedQuestion = () => {
    questionContainerRefs.current[questionToBeMoved.id].scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };

  const nonDeletedQuestions = surveyModel.nonDeletedQuestions;

  useEffect(() => {
    SurveyManagementService.getBestPracticeQuestions(
      selectedInventory.type,
      selectedInventory.id
    ).then((bestPracticeQns) => {
      setAvailableBestPracticeQns(bestPracticeQns);
      setIsLoading(false);
    });
  }, []);

  const deleteQuestion = (question) => {
    surveyModel.deleteQuestion(question);
    onChange();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isFormDisabled = surveyModel.isNotDraft();

  const questionList = nonDeletedQuestions.map((question, index) => {
    if (question.questionType === SURVEY_QUESTION_TYPE.QUESTION_GROUP) {
      return (
        <div
          key={index}
          ref={(el) => {
            questionContainerRefs.current[question.id] = el;
          }}
          className="survey-question-container"
        >
          <QuestionDisplay
            question={question}
            onChange={onChange}
            index={index}
            showSurveyValidationError={showSurveyValidationError}
            disabled={isFormDisabled || !isAllowedToManageSurvey}
          />
          {showSurveyValidationError &&
            question.isChildQuestionsLessThanRequired() && (
              <GVDSFormErrorMessage status={FormFieldState.error} errorMsg="Need at least 2 questions in a question group" />
            )}
          {question.getNotDeletedChildQns().map((childQn, childIndex) => {
            return (
              <QuestionDisplay
                key={childIndex}
                question={childQn}
                onChange={onChange}
                index={index}
                isNested={true}
                parentQuestion={question}
                nestedIndex={childIndex}
                showSurveyValidationError={showSurveyValidationError}
                disabled={isFormDisabled || !isAllowedToManageSurvey}
              />
            );
          })}
          <QuestionFooter
            question={question}
            onChange={onChange}
            onDelete={() => deleteQuestion(question)}
            onMove={() => promptMoveQuestion(question)}
            disabled={isFormDisabled || !isAllowedToManageSurvey}
            index={index}
          />
        </div>
      );
    } else if (
      question.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE ||
      question.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
    ) {
      return (
        <div
          key={index}
          ref={(el) => {
            questionContainerRefs.current[question.id] = el;
          }}
          className="survey-question-container"
        >
          <SurveyBestPracticeQuestionDisplay
            question={question}
            index={index}
          />
          <QuestionFooter
            question={question}
            onChange={onChange}
            onDelete={() => deleteQuestion(question)}
            onMove={() => promptMoveQuestion(question)}
            disabled={isFormDisabled || !isAllowedToManageSurvey}
            index={index}
          />
        </div>
      );
    } else {
      return (
        <div
          key={index}
          ref={(el) => (questionContainerRefs.current[question.id] = el)}
          className="survey-question-container"
        >
          <QuestionDisplay
            question={question}
            onChange={onChange}
            index={index}
            showSurveyValidationError={showSurveyValidationError}
            disabled={isFormDisabled || !isAllowedToManageSurvey}
          />
          <QuestionFooter
            question={question}
            onChange={onChange}
            onDelete={() => deleteQuestion(question)}
            onMove={() => promptMoveQuestion(question)}
            disabled={isFormDisabled || !isAllowedToManageSurvey}
            index={index}
          />
        </div>
      );
    }
  });

  return (
    <div>
      {questionList}
      {!isFormDisabled && isAllowedToManageSurvey && (
        <div className="d-flex align-items-center">
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => {
              surveyModel.addANewQuestion();
              onChange();
            }}
            icon={<GVDSIcon Icon={IconCirclePlus} />}
            text="Create a question"
          />
          <div className="color-gray56"> or</div>
          <SurveyBestPracticeQuestionAdd
            allQuestions={availableBestPracticeQns}
            alreadyAddedQuestionIds={surveyModel.getBestPracticeQuestionIds()}
            onAdding={(selectedBPQs) => {
              surveyModel.addBestPracticeQuestions(selectedBPQs);
              onChange();
            }}
          />
        </div>
      )}
      <MoveSurveyQuestionDialog
        show={showMoveDialog}
        onHide={closeMoveDialog}
        surveyModel={surveyModel}
        onChange={onChange}
        onQuestionMoved={onQuestionMoved}
        questionToBeMoved={questionToBeMoved}
        allQuestions={surveyModel.nonDeletedQuestions}
        scrollToMovedQuestion={scrollToMovedQuestion}
      />
    </div>
  );
};

export default SurveyQuestionForm;
