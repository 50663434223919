import axios from "axios";
import { API_URL } from "../config/api-config";
import { SUBTOPIC_TYPE_ORDER } from "../config/constants";

export default class EnvironmentalSubtopicService {
  static getAllInputSubtopics = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/environmental_subtopics"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static getAllSubtopics = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/ref_data/subtopics");
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static subtopicsSortFn = (subtopic1Name, subtopic2Name) => {
    const sortOrder1 = SUBTOPIC_TYPE_ORDER[subtopic1Name];
    const sortOrder2 = SUBTOPIC_TYPE_ORDER[subtopic2Name];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return -1;
    } else if (sortOrder2 !== undefined) {
      return 1;
    } else {
      return subtopic1Name.localeCompare(subtopic2Name);
    }
  };
}
