import React from "react";
import { BillingCycleEnum } from "../../common/Billing/PackagePlanModel";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import { IconChevronDown } from "@tabler/icons-react";
import { StringUtils } from "../../../services/UtilsService";
import { getSubscriptionPriceDisplay } from "../../SystemToolbox/Subscription/SubscriptionSharedUtils";

const getChargeCycleDisplay = (billingCycle) => {
  return billingCycle === BillingCycleEnum.monthly ? "month" : "year";
};

const getPriceDisplay = (price, currency, billingCycle) => {
  return `${getSubscriptionPriceDisplay(
    currency,
    price
  )} /${getChargeCycleDisplay(billingCycle)}`;
};

const PlanDetails = ({
  isDark = false,
  title,
  planName = null,
  billingCycle,
  priceDisplay,
}) => {
  return (
    <div
      className={
        "billing-plan-change-display__plan-box" + (isDark ? " dark" : "")
      }
    >
      <div className="gvds-text--heading4">{title}</div>
      <div className="billing-plan-change-display__plan-details">
        {!!planName && (
          <>
            <div className="gvds-text--inputField">Plan</div>
            <div>{planName}</div>
          </>
        )}
        <div className="gvds-text--inputField">Billing Cycle</div>
        <div>{StringUtils.capitaliseWord(billingCycle)}</div>
        <div className="gvds-text--inputField">Price</div>
        <div>{priceDisplay}</div>
      </div>
    </div>
  );
};

const PlanChangeDisplay = ({
  currentPlanName = null,
  currentBillingCycle,
  currentPrice,
  currency,
  newPlanName = null,
  newBillingCycle,
  newPrice,
}) => {
  return (
    <div>
      <PlanDetails
        isDark={true}
        title="Current Subscription"
        planName={currentPlanName}
        billingCycle={currentBillingCycle}
        priceDisplay={getPriceDisplay(
          currentPrice,
          currency,
          currentBillingCycle
        )}
      />
      <div className="d-flex align-items-center justify-content-center my-1">
        <GVDSIconSlim Icon={IconChevronDown} />
      </div>
      <PlanDetails
        title="New Subscription"
        planName={newPlanName}
        billingCycle={newBillingCycle}
        priceDisplay={getPriceDisplay(newPrice, currency, newBillingCycle)}
      />
    </div>
  );
};

export default PlanChangeDisplay;
