import React from "react";
import { UserManagedTagDisplay } from "../../common/CentralTags/UserManagedTagDisplay";
import { INITIATIVE_SUBTOPIC, RESOURCES } from "../../../config/constants";

export const getInitiativeSubtopicDescription = (t) => {
  return {
    [INITIATIVE_SUBTOPIC.EFFICIENCY_AND_ENVIRONMENT]: t(
      "data-management.initiatives.subtopic-description.environment"
    ),
    [INITIATIVE_SUBTOPIC.SOCIAL_IMPACT]: t(
      "data-management.initiatives.subtopic-description.social-impact"
    ),
    [INITIATIVE_SUBTOPIC.PROCUREMENT]: t(
      "data-management.initiatives.subtopic-description.procurement"
    ),
    [INITIATIVE_SUBTOPIC.OTHER]: t(
      "data-management.initiatives.subtopic-description.other"
    ),
  };
};

export const getInitiativeSubtopicDescriptionTrackingCriteria = (t) => {
  return {
    [INITIATIVE_SUBTOPIC.EFFICIENCY_AND_ENVIRONMENT]: t(
      "data-management.initiatives.subtopic-description-tracking-criteria.environment"
    ),
    [INITIATIVE_SUBTOPIC.SOCIAL_IMPACT]: t(
      "data-management.initiatives.subtopic-description-tracking-criteria.social-impact"
    ),
    [INITIATIVE_SUBTOPIC.PROCUREMENT]: t(
      "data-management.initiatives.subtopic-description-tracking-criteria.procurement"
    ),
    [INITIATIVE_SUBTOPIC.OTHER]: t(
      "data-management.initiatives.subtopic-description-tracking-criteria.other"
    ),
  };
}

const InitiativeNameColumnDisplay = ({ initiative }) => {
  return (
    <div className="initiative__name-column-display">
      <div className="initiative__name-column-display__name">
        {initiative.name}
      </div>
      <div className="initiative__name-column-display__story">
        {initiative.story}
      </div>
      <div className="mt-1">
        {initiative.selectedPortfolioTags.map((t) => (
          <UserManagedTagDisplay
            key={t.id}
            tagText={t.name}
            resourceType={RESOURCES.PORTFOLIO}
          />
        ))}
        {initiative.selectedSiteTags.map((t) => (
          <UserManagedTagDisplay
            key={t.id}
            tagText={t.name}
            resourceType={RESOURCES.SITE}
          />
        ))}
      </div>
    </div>
  );
};

export default InitiativeNameColumnDisplay;
