import React, { useContext, useState } from "react";
import DashboardOptionContext from "../Context/DashboardOptionContext";
import InlineSpinner from "../../common/InlineSpinner";
import DashboardSelector from "../DashboardSelector";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import { IconChevronDown, IconChevronUp, IconDots } from "@tabler/icons-react";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import Dropdown from "react-bootstrap/Dropdown";
import { Collapse } from "react-bootstrap";
import DashboardDataContext from "../Context/DashboardDataContext";
import DashboardSchemaParser from "../DashboardSchemaParser";
import InventoryFilter from "../Filters/InventoryFilter";
import {
  dashboardContentContainerId,
  downloadChartAsImageTimeoutInMs,
} from "../Models/DashboardModels";
import { DashboardWidgetSharedUtils } from "../Widgets/DashboardWidgetSharedUtils";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";

const DashboardActionToggle = React.forwardRef(({ onClick }, ref) => (
  <GVDSIconButton
    ref={ref}
    variant={iconButtonVariant.secondary}
    icon={<GVDSIcon Icon={IconDots} />}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

const DashboardMenu = () => {
  const dashboardOptionContext = useContext(DashboardOptionContext);
  const dashboardDataContext = useContext(DashboardDataContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const dashboards = dashboardOptionContext.dashboards;
  const selectedDashboard = dashboardOptionContext.selectedDashboard;
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const [isShowFilter, setIsShowFilter] = useState(true);

  if (dashboardOptionContext.isLoadingSelectedDashboard) {
    return (
      <div className="dashboard-menu__container">
        <div className="dashboard-menu__content-row">
          <div className="dashboard-menu__loading-spinner-container">
            <InlineSpinner />
          </div>
        </div>
      </div>
    );
  }

  const menu = dashboardDataContext.dashboardHolderFacade.menuSchema
    .map((element) => DashboardSchemaParser.parseMenu(element))
    .filter((m) => m !== null);

  const switchDashboard = (dashboardId) => {
    dashboardOptionContext.loadDashboard(dashboardId);
  };

  const downloadDashboardAsImage = async () => {
    dashboardDataContext.setIsDownloadingDashboardAsImage(true);

    setTimeout(async () => {
      try {
        const fileName = DashboardWidgetSharedUtils.getDownloadFileName(
          userInventory.selectedTreeNode.get?.nodeValue?.name || "",
          "dashboard",
          selectedDashboard.name,
          dashboardHolderFacade.getDashboardTimeDisplay()
        );
        await DashboardWidgetSharedUtils.downloadDashboardAsImage(
          dashboardContentContainerId,
          fileName,
          selectedDashboard.isUseTab()
        );
      } catch (e) {
        toastContext.addFailToast(
          <span>Failed to download dashboard as image.</span>
        );
      } finally {
        dashboardDataContext.setIsDownloadingDashboardAsImage(false);
      }
    }, downloadChartAsImageTimeoutInMs);
  };

  return (
    <div className="dashboard-menu__container">
      <div className="dashboard-menu__content-row">
        <div className="gvds-text--heading3 me-2">{selectedDashboard.name}</div>
        <DashboardSelector
          selectedDashboard={selectedDashboard}
          switchDashboard={switchDashboard}
          dashboards={dashboards}
        />
        <GVDSButton
          variant={buttonVariant.secondary}
          className="ms-auto"
          text={
            <>
              Filters
              <GVDSIconSlim
                className="ms-2"
                Icon={isShowFilter ? IconChevronUp : IconChevronDown}
              />
            </>
          }
          onClick={() => setIsShowFilter(!isShowFilter)}
        />

        <Dropdown className="ms-2">
          <Dropdown.Toggle as={DashboardActionToggle} />
          <Dropdown.Menu align="end">
            <Dropdown.Item
              key="download-dashboard"
              href="#"
              onClick={downloadDashboardAsImage}
            >
              Download dashboard as image
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Collapse in={isShowFilter}>
        <div className="dashboard-filter__content__container">
          {menu}
          <InventoryFilter />
        </div>
      </Collapse>
    </div>
  );
};

export default DashboardMenu;
