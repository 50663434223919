import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Table } from "react-bootstrap";
import withAuthentication from "../../HOC/withAuthentication";
import {
  SYSTEM_TOOLBOX_AFFILIATIONS,
  SYSTEM_TOOLBOX_ASSET_CLASSES,
  SYSTEM_TOOLBOX_HOTEL_TYPES,
  SYSTEM_TOOLBOX_LAUNDRY_LOCATIONS,
  SYSTEM_TOOLBOX_MARKET_SEGMENTS,
} from "../../../config/ROUTES_NAME";

import AffiliationsService from "../../../services/ReferenceDataServices/HotelAndAccommodationAttributes/AffiliationService";
import AssetClassService from "../../../services/ReferenceDataServices/HotelAndAccommodationAttributes/AssetClassService";
import HotelTypeService from "../../../services/ReferenceDataServices/HotelAndAccommodationAttributes/HotelTypeService";
import LaundryLocationService from "../../../services/ReferenceDataServices/HotelAndAccommodationAttributes/LaundryLocationService";
import MarketSegmentService from "../../../services/ReferenceDataServices/HotelAndAccommodationAttributes/MarketSegmentService";
import ToastContext from "../../../context/ToastContext";

const HotelAndAccommodationAttributesDataTables = (props) => {
  const toastContext = useContext(ToastContext);

  const [headerName, setHeaderName] = useState(
    "Hotel and Accommodation attributes"
  );
  const [activeKey, setActiveKey] = useState(
    "Hotel and Accommodation attributes"
  );
  const [affiliations, setAffiliations] = useState([]);
  const [hotelTypes, setHotelTypes] = useState([]);
  const [laundryLocations, setlaundryLocation] = useState([]);
  const [marketSegments, setMarketSegments] = useState([]);
  const [assetClasses, setAssetClasses] = useState([]);

  const [isLoading, setIsLoading] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    AffiliationsService.GetAllAffiliation()
      .then((response) => {
        setAffiliations(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load affiliations.</span>);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    HotelTypeService.GetHotelTypes()
      .then((response) => {
        setHotelTypes(response);
        setIsLoading(false);
      })
      .catch((e) => {
	      setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load hotel types.</span>);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    LaundryLocationService.GetLaundryLocations()
      .then((response) => {
        setlaundryLocation(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load laundry locations.</span>
        );
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    MarketSegmentService.GetAllMarketSegments()
      .then((response) => {
        setMarketSegments(response);
        setIsLoading(false);
      })
      .catch((e) => {
	      setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load market segments.</span>);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    AssetClassService.GetAllAssetClasses()
      .then((response) => {
        setAssetClasses(response);
        setIsLoading(false);
      })
      .catch((e) => {
	      setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load asset classes.</span>);
      });
  }, []);

  return (
    <>
      <section>
        <Accordion
          key={headerName}
          defaultActiveKey={activeKey}
          activeKey={activeKey === headerName ? activeKey : null}
          className="accordion-header"
        >
          <Accordion.Item eventKey={headerName}>
            <Card.Header
              onClick={() =>
                activeKey === headerName
                  ? setActiveKey(null)
                  : setActiveKey(headerName)
              }
            >
              <div className="body-2-bold">
                {headerName.toUpperCase()}{" "}
                <span className="collapse-toggle">
                  {activeKey === headerName ? "(collapse)" : "(expand)"}
                </span>
              </div>
            </Card.Header>
            <Accordion.Body>
              <Table className="reference-data-table section-box">
                <tbody>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_AFFILIATIONS)
                        }
                      >
                        Affiliations
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {affiliations.length > 0 &&
                        affiliations
                          .slice(0, 2)
                          .map((affiliation) => (
                            <span key={affiliation.id}>
                              {affiliation.name + ", "}
                            </span>
                          ))}
                      {affiliations.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_HOTEL_TYPES)
                        }
                      >
                        Hotel Types
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {hotelTypes.length > 0 &&
                        hotelTypes
                          .slice(0, 2)
                          .map((hotelType) => (
                            <span key={hotelType.id}>
                              {hotelType.name + ", "}
                            </span>
                          ))}
                      {hotelTypes.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_LAUNDRY_LOCATIONS)
                        }
                      >
                        Main Laundry Wash Locations
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {laundryLocations.length > 0 &&
                        laundryLocations
                          .slice(0, 2)
                          .map((laundryLocation) => (
                            <span key={laundryLocation.id}>
                              {laundryLocation.name + ", "}
                            </span>
                          ))}
                      {laundryLocations.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_MARKET_SEGMENTS)
                        }
                      >
                        Market Segments
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {marketSegments.length > 0 &&
                        marketSegments
                          .slice(0, 2)
                          .map((marketSegment) => (
                            <span key={marketSegment.id}>
                              {marketSegment.name + ", "}
                            </span>
                          ))}
                      {marketSegments.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={() =>
                          props.history.push(SYSTEM_TOOLBOX_ASSET_CLASSES)
                        }
                      >
                        Service Types/Asset Classes
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {" "}
                      {assetClasses.length > 0 &&
                        assetClasses
                          .slice(0, 2)
                          .map((assetClasse) => (
                            <span key={assetClasse.id}>
                              {assetClasse.name + ", "}
                            </span>
                          ))}
                      {assetClasses.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </>
  );
};

export default withAuthentication(HotelAndAccommodationAttributesDataTables);
