import { DateTimeUtils } from "../../services/UtilsService";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";
import GVDSInfoCard from "../../gvds-components/common/GVDSInfoCard";
import { Trans, useTranslation } from "react-i18next";

const MeterDismissAlertsBanner = ({
  dismissAlertsDate,
  showAlertPreferencesModal,
}) => {
  const { t } = useTranslation();

  if (!dismissAlertsDate) {
    return null;
  }

  const dateDisplay = DateTimeUtils.formatLocalDate(dismissAlertsDate);
  return (
    <>
      {dismissAlertsDate ? (
        <GVDSInfoCard
          className="meter-details__dismiss-alerts-banner"
          useIcon={true}
          title={
            <>
              <span className="me-1">
                <Trans i18nKey="data-management.shared.alert-preferences.dismiss-alert.suppressed-issues-msg">
                  Possible issues alerts before and including {{ dateDisplay }}{" "}
                  are dismissed.
                </Trans>
              </span>
              <GVDSTextButton
                text={t(
                  "data-management.shared.alert-preferences.dismiss-alert.update-cta"
                )}
                onClick={showAlertPreferencesModal}
              />
            </>
          }
        />
      ) : null}
    </>
  );
};

export default MeterDismissAlertsBanner;
