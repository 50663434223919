import React from "react";

const iconSlimSizeProps = {
  size: 16,
  stroke: 1.75,
};

const GVDSIconSlim = React.forwardRef(({ Icon, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={`gvds-icon-container ${className || ""}`}
      {...props}
    >
      <Icon {...iconSlimSizeProps} />
    </div>
  );
});

export default GVDSIconSlim;
