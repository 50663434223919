import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../../HOC/withAuthentication";
import LoadingSpinner from "../../common/LoadingSpinner";
import { Container } from "react-bootstrap";
import { REFERENCE_DATA } from "../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import EnvironmentalSubtopicService from "../../../services/EnvironmentalSubtopicService";
import uniq from "lodash/uniq";
import ToastContext from "../../../context/ToastContext";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";
import GVDSTable, {SORTING_TYPES} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";

export class EnvironmentTypeList {
  constructor(env_type) {
    this.id = env_type.id;
    this.name = env_type.name;
    this.subtopic_name = env_type.subtopic_name;
    this.subtopic_id = env_type.subtopic_id;
    this.units = env_type.units;
    this.definition = env_type.definition;
  }

  get get_subtopic_name() {
    return this.subtopic_name;
  }
}

const EnvironmentalTypes = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [environmentalTypes, setEnvironmentalTypes] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    environmentalTypes,
    SystemToolboxSearchKeysByPageName.referenceData.environmentalType,
    {
      name: SORTING_TYPES.asc,
    }
  );

  useEffect(() => {
    setIsLoading(true);
    EnvironmentalSubtopicService.getAllInputSubtopics()
      .then((response) => {
        let energy_types = response[0].environmental_types;

        energy_types.map((m) => {
          m.subtopic_id = response[0].id;
          m.subtopic_name = response[0].name;
        });

        let water_types = response[1].environmental_types;

        water_types.map((water_type) => {
          water_type.subtopic_id = response[1].id;
          water_type.subtopic_name = response[1].name;
        });

        let waste_types = response[2].environmental_types;

        waste_types.map((waste_type) => {
          waste_type.subtopic_id = response[2].id;
          waste_type.subtopic_name = response[2].name;
        });

        let refrigerant_types = response[3].environmental_types;

        refrigerant_types.map((refrigerant_type) => {
          refrigerant_type.subtopic_id = response[3].id;
          refrigerant_type.subtopic_name = response[3].name;
        });

        var envTypes = energy_types.concat(
          water_types,
          waste_types,
          refrigerant_types
        );

        const envModalData = envTypes.map(
          (env_type) => new EnvironmentTypeList(env_type)
        );
        setEnvironmentalTypes(envModalData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load environmental types.</span>
        );
      });
  }, []);

  const columns = [
    {
      header: "Environmental Type Name",
      dataKey: "name",
      sortable: true,
    },
    {
      header: "Subtopic",
      dataKey: "subtopic_name",
      sortable: true,
    },
    {
      header: "Description",
      dataKey: "definition",
      sortable: true,
    },
    {
      header: "Unit",
      dataKey: "units",
      sortable: true,
      renderer: (q) =>
        q.units.map((unit) => <span key={unit.id}>{unit.name + ", "}</span>),
    },
  ];

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return To Reference Data"
          onClick={goToReferenceData}
        />
        <PageHeader.Title>
          <h1>Environmental Types</h1>
        </PageHeader.Title>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search by Environmental Type Name"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={uniq(
            environmentalTypes.map((q) => q.get_subtopic_name).flat()
          )}
          prefix="Subtopic"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, get_subtopic_name: filterKs })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {filteredSortedData.length === 0 && (
        <div className="table__no_content">No record found</div>
      )}
    </Container>
  );
};

export default withAuthentication(EnvironmentalTypes);
