import React from "react";
import GVDSFormField from "../Forms/GVDSFormField";

const FilterSearchBox = React.forwardRef(
  ({ className, placeholder, value, onInput }, ref) => {
    return (
      <GVDSFormField
        ref={ref}
        className={"filter-search-box " + (!!className ? className : "")}
        placeholder={placeholder}
        onInput={(value) => onInput(value)}
        value={value}
      />
    );
  }
);

export default FilterSearchBox;
