import React from "react";
import moment from "moment";
import range from "lodash/range";

import { UtilsService } from "../../services/UtilsService";

const MonthNames = moment.monthsShort();

const MultiMonthCalendar = ({
  allowedMonths,
  selectedMonths,
  setSelectedMonths,
}) => {
  const selectMonth = (month) => {
    if (allowedMonths.indexOf(month) >= 0) {
      setSelectedMonths(
        UtilsService.toggleItem(selectedMonths, month).sort((a, b) =>
          a > b ? 1 : -1
        )
      );
    }
  };

  const getMonthCell = (mon) => {
    const selected = selectedMonths.indexOf(mon) >= 0;
    return (
      <div
        className={
          "multi-month-calendar__month_cell" +
          (selected ? " selected" : "") +
          (allowedMonths.indexOf(mon) < 0 ? " disabled" : "")
        }
        onClick={() => selectMonth(mon)}
      >
        {MonthNames[mon - 1]}
      </div>
    );
  };

  const getMonthRow = (firstMonthInRow) => {
    return (
      <div key={firstMonthInRow} className="d-flex">
        {getMonthCell(firstMonthInRow)}
        {getMonthCell(firstMonthInRow + 1)}
        {getMonthCell(firstMonthInRow + 2)}
      </div>
    );
  };

  return (
    <div className="performance-report__multi-month-calendar-container">
      <div className="multi-month-calendar__header d-flex align-items-center justify-content-around">
        <div className='gvds-text--heading3'>Month</div>
      </div>
      <div>{range(1, 13, 3).map((y) => getMonthRow(y))}</div>
    </div>
  );
};

export default MultiMonthCalendar;
