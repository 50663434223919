import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../../HOC/withAuthentication";
import LoadingSpinner from "../../common/LoadingSpinner";
import { Container } from "react-bootstrap";
import { REFERENCE_DATA } from "../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import EnvironmentalSubtopicService from "../../../services/EnvironmentalSubtopicService";
import ToastContext from "../../../context/ToastContext";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCheck } from "@tabler/icons-react";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSTable, {
  SORTING_TYPES,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import { GVDSColors } from "../../../styles/gvds-colors";

const Subtopics = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [subtopics, setSubtopics] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(subtopics, null, {
    name: SORTING_TYPES.asc,
  });

  useEffect(() => {
    setIsLoading(true);
    EnvironmentalSubtopicService.getAllSubtopics()
      .then((response) => {
        setSubtopics(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load subtopics.</span>);
      });
  }, []);

  const checkStatus = (output_only) => {
    if (output_only) {
      return (
        <div style={{ color: GVDSColors.success }}>
          <GVDSIcon Icon={IconCheck} />
        </div>
      );
    } else {
      return "";
    }
  };
  const columns = [
    {
      header: "Subtopic Name",
      dataKey: "name",
      sortable: true,
    },
    {
      header: "Output Only",
      dataKey: "output_only",
      sortable: true,
      renderer: (q) => <div>{checkStatus(q.output_only)}</div>,
    },
  ];

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return To Reference Data"
          onClick={goToReferenceData}
        />
        <PageHeader.Title>
          <h1>Subtopics</h1>
        </PageHeader.Title>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {filteredSortedData.length === 0 && (
        <div className="table__no_content">No record found</div>
      )}
    </Container>
  );
};

export default withAuthentication(Subtopics);
