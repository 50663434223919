import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import React from "react";

const DashboardExpandedModalView = ({
  id,
  header,
  body,
  footer,
  isShow,
  closeModal,
  hideCloseButton = false,
}) => {
  return (
    <GVDSModal
      id={id}
      className="dashboard--expanded-view__modal"
      title={header}
      size={GVDSModal.Size.xl}
      show={isShow}
      onHide={closeModal}
      hideCloseButton={hideCloseButton}
    >
      <GVDSModal.Body>{body}</GVDSModal.Body>
      {footer && (
        <GVDSModal.Footer>
          <div className="dashboard--expanded-view__footer-container">
            {footer}
          </div>
        </GVDSModal.Footer>
      )}
    </GVDSModal>
  );
};

export default DashboardExpandedModalView;
