import React, { useCallback, useState } from "react";

const FloatingAssistantContext = React.createContext();

export const FloatingAssistantProvider = ({ children }) => {
  const [isFloatingAssistantOpen, setIsFloatingAssistantOpen] = useState(false);

  const openFloatingAssistant = () => {
    setIsFloatingAssistantOpen(true);
  };

  const closeFloatingAssistant = () => {
    setIsFloatingAssistantOpen(false);
  };

  const toggleFloatingAssistant = () => {
    setIsFloatingAssistantOpen(!isFloatingAssistantOpen);
  };

  const store = useCallback(
    {
      isOpen: isFloatingAssistantOpen,
      open: openFloatingAssistant,
      close: closeFloatingAssistant,
      toggle: toggleFloatingAssistant,
    },
    [isFloatingAssistantOpen]
  );

  return (
    <FloatingAssistantContext.Provider value={store}>
      {children}
    </FloatingAssistantContext.Provider>
  );
};

export default FloatingAssistantContext;
