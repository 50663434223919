import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

import './i18n';

import AWSAmplifyService from "./services/AWSAmplifyService";
import AmplifyContext from "./context/AWSAmplifyContext";
import { setupAxios } from "./services/HttpService";
import { BrowserRouter as Router } from "react-router-dom";
import { FEATURE_FLAG_UNDER_MAINTENANCE } from "./config/feature-flag-config";
import { UnderMaintenancePlaceholder } from "./pages/UnderMaintenancePlaceholder";

const amplifyService = new AWSAmplifyService();

setupAxios();

const root = ReactDOM.createRoot(document.getElementById("root"));
if (FEATURE_FLAG_UNDER_MAINTENANCE) {
  root.render(<UnderMaintenancePlaceholder />);
} else {
  root.render(
    <Router>
      <AmplifyContext.Provider value={amplifyService}>
        <App />
      </AmplifyContext.Provider>
    </Router>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
