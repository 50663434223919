import {
  ComplianceObligationOrderIndex,
  esgIcons,
  ESGOrderIndex,
  JurisdictionLevelOrderIndex,
  LegislativeStatusOrderIndex,
  PriorityLevel,
  PriorityLevelOrderIndex,
} from "./PolicyModel";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  infoTooltipPopupDelayStart,
  TOOLTIP_PLACEMENTS,
} from "../../config/style-config";
import Popover from "react-bootstrap/Popover";
import { StringUtils } from "../../services/UtilsService";
import React from "react";
import { GVDSColors } from "../../styles/gvds-colors";

export default class PolicyTrackerUtils {
  static getPriorityLevelLabelColor(priorityLevel) {
    switch (priorityLevel?.toLowerCase()) {
      case PriorityLevel.high:
        return StatusLabel.Colors.red;
      case PriorityLevel.medium:
        return StatusLabel.Colors.yellow;
      case PriorityLevel.low:
        return StatusLabel.Colors.green;
      default:
        return StatusLabel.Colors.gray;
    }
  }

  static getESGIcons(esgStatuses) {
    if (!esgStatuses || esgStatuses.length === 0) {
      return null;
    }

    const icons = esgStatuses
      .sort(this.esgSortFn)
      .map((status, index) => (
        <span key={index}>{esgIcons[status.toLowerCase()]}</span>
      ));

    return <div className="esg-icons__container">{icons}</div>;
  }

  static getPriorityLevelDoughnutChartColor(priorityLevel) {
    switch (priorityLevel?.toLowerCase()) {
      case PriorityLevel.high:
        return GVDSColors.error;
      case PriorityLevel.medium:
        return GVDSColors.warning;
      case PriorityLevel.low:
        return GVDSColors.success;
      default:
        return GVDSColors.gray6;
    }
  }

  static priorityLevelSortFn = (level1, level2) => {
    const sortOrder1 = PriorityLevelOrderIndex[level1.toLowerCase()];
    const sortOrder2 = PriorityLevelOrderIndex[level2.toLowerCase()];

    return sortOrder1 - sortOrder2;
  };

  static legislativeStatusSortFn = (status1, status2) => {
    const sortOrder1 = LegislativeStatusOrderIndex[status1.toLowerCase()];
    const sortOrder2 = LegislativeStatusOrderIndex[status2.toLowerCase()];

    return sortOrder1 - sortOrder2;
  };

  static complianceObligationSortFn = (value1, value2) => {
    const sortOrder1 = ComplianceObligationOrderIndex[value1.toLowerCase()];
    const sortOrder2 = ComplianceObligationOrderIndex[value2.toLowerCase()];

    return sortOrder1 - sortOrder2;
  };

  static esgSortFn = (status1, status2) => {
    const sortOrder1 = ESGOrderIndex[status1.toLowerCase()];
    const sortOrder2 = ESGOrderIndex[status2.toLowerCase()];

    return sortOrder1 - sortOrder2;
  };

  static getPriorityLevelOrderIndex = (priorityLevel) => {
    return priorityLevel
      ? PriorityLevelOrderIndex[priorityLevel.toLowerCase()]
      : null;
  };

  static getComplianceObligationOrderIndex = (complianceObligation) => {
    return complianceObligation
      ? ComplianceObligationOrderIndex[complianceObligation.toLowerCase()]
      : null;
  };

  static getJurisdictionLevelOrderIndex = (jurisdictionLevel) => {
    return jurisdictionLevel
      ? JurisdictionLevelOrderIndex[jurisdictionLevel.toLowerCase()]
      : null;
  };

  static getTopicOrderIndex = (topics) => {
    return topics ? topics.join(", ") : null;
  };

  static getESGsOrderIndex = (esgStatuses) => {
    if (esgStatuses) {
      return `${esgStatuses.length}${esgStatuses
        .sort(PolicyTrackerUtils.esgSortFn)
        .map((esg) => ESGOrderIndex[esg.toLowerCase()])
        .join("")}`;
    } else {
      return null;
    }
  };
}

export const PriorityLevelWithTooltip = ({
  priorityLevel,
  priorityLevelCount,
  info,
  onClick,
}) => {
  return (
    <OverlayTrigger
      placement={TOOLTIP_PLACEMENTS.TOP}
      delay={infoTooltipPopupDelayStart}
      overlay={
        <Popover>
          <Popover.Body>{info}</Popover.Body>
        </Popover>
      }
    >
      <div className="priority-level-category" onClick={onClick}>
        <StatusLabel
          color={PolicyTrackerUtils.getPriorityLevelLabelColor(priorityLevel)}
        >
          {StringUtils.getTitleCase(priorityLevel)}
        </StatusLabel>
        <span className="priority-level-count">{priorityLevelCount}</span>
      </div>
    </OverlayTrigger>
  );
};
