import React, { useCallback, useContext, useEffect, useState } from "react";
import { CertificationService } from "../../services/CertificationService";
import ToastContext from "../../context/ToastContext";
import UserInventoryContext from "../../context/UserInventoryContext";
import { cloneDeep } from "lodash";
import { useParams } from "react-router-dom";
import { RESOURCES } from "../../config/constants";

const CertificationContext = React.createContext(null);

export const CertificationContextProvider = ({ children }) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);

  const { certificationId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [certification, setCertification] = useState(null);

  const refreshCertification = () => {
    setCertification(cloneDeep(certification));
  };

  useEffect(() => {
    const currentInventory = userInventory.selectedInventory.get;

    if (
      currentInventory &&
      currentInventory.type === RESOURCES.SITE &&
      certificationId
    ) {
      setIsLoading(true);

      CertificationService.getCertification(
        currentInventory.type,
        currentInventory.id,
        certificationId
      )
        .then((response) => {
          setCertification(response);
        })
        .catch((error) => {
          if (error.response.status !== 403 && error.response.status !== 404) {
            toastContext.addFailToast(
              <span>Failed to load certification.</span>
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [certificationId, userInventory.selectedInventory.get?.id]);

  const store = useCallback(
    {
      refreshCertification: refreshCertification,
      certificationId: certificationId,
      certification: certification,
      isLoading: isLoading,
    },
    [isLoading, certificationId, certification]
  );

  return (
    <CertificationContext.Provider value={store}>
      {children}
    </CertificationContext.Provider>
  );
};

export default CertificationContext;
