import React from "react";
import PopoverOnBigNumberTruncated from "../../../common/Tooltip/PopoverOnBigNumberTruncated";
import { NumberService } from "../../../../services/UtilsService";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  getChangeColorClass,
  getChangeIcon,
} from "../DashboardWidgetSharedUtils";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { uniqueId } from "lodash";
import DashboardDataGapsIndicator from "../DashboardDataGapsIndicator";

const MainSummaryCardView = ({
  title,
  description,
  value,
  unit,
  change,
  changeDescription,
  isNegativeChangeGood = true,
  isDataComplete = true,
  isLoading = false,
}) => {
  const isIncreasing = change > 0;
  const isShowChangePercentage =
    change && Number.isFinite(change) && change !== 0;

  const changeColorClass = getChangeColorClass(
    isShowChangePercentage,
    isNegativeChangeGood,
    isIncreasing
  );
  const changeIcon = getChangeIcon(isShowChangePercentage, isIncreasing);

  const numberDisplayValue =
    value === undefined || value === null ? (
      <div className="main-summary-card__number">-</div>
    ) : (
      <PopoverOnBigNumberTruncated
        key={uniqueId()}
        numberValue={value}
        contentClassName="main-summary-card__number"
        maximumFractionDigits={2}
        minimumFractionDigits={2}
      />
    );

  const changeDisplayValue =
    change === undefined || !Number.isFinite(change)
      ? "- "
      : NumberService.format(Math.abs(change), 1, 1);

  const isEmptyCard = title === undefined;

  let content;

  if (isLoading) {
    content = (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else if (isEmptyCard) {
    content = null;
  } else {
    content = (
      <>
        <div className="main-summary-card__title">
          <div className="gvds-text--heading4">{title}</div>
          {!!description && <InfoTooltip info={description} />}
          {!isDataComplete && <DashboardDataGapsIndicator />}
        </div>

        <div className="main-summary-card__value">
          {numberDisplayValue}
          <span className="main-summary-card__value__unit">{unit}</span>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body>{changeDescription}</Popover.Body>
              </Popover>
            }
          >
            <div className={`main-summary-card__change ${changeColorClass}`}>
              {changeIcon}
              <div className="gvds-text--label">{changeDisplayValue}%</div>
            </div>
          </OverlayTrigger>
        </div>
      </>
    );
  }

  return <div className="main-summary-card__container">{content}</div>;
};
export default MainSummaryCardView;
