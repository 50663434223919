import AWSConfig from "../config/aws-config";
import { Amplify, Auth } from "aws-amplify";
import { BehaviorSubject } from "rxjs";

const unauthenticated = false;

class AWSAmplifyService {
  constructor() {
    Amplify.configure({
      Auth: {
        mandatorySignIn: false,
        region: AWSConfig.REGION,
        userPoolId: AWSConfig.USER_POOL_ID,
        userPoolWebClientId: AWSConfig.APP_CLIENT_ID,
      },
    });

    this.currentUser = new BehaviorSubject(null);

    this.getCurrentUser()
      .then((user) => {
        this.currentUser.next(user);
      })
      .catch((unauthenticatedError) => {
        this.currentUser.next(unauthenticated);
      });
  }

  async signIn(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      this.currentUser.next(user);
      return user;
    } catch (error) {
      throw error;
    }
  }

  async updateNewPasswordAsRequired(newPassword) {
    try {
      const user = await Auth.completeNewPassword(
        this.currentUser.value,
        newPassword,
        {}
      );
      this.currentUser.next(user);
      return user;
    } catch (error) {
      throw error;
    }
  }

  async verifyOtp(user, otp) {
    return Auth.confirmSignIn(user, otp, "SOFTWARE_TOKEN_MFA")
      .then(() => {
        return this.getCurrentUser()
          .then((user) => {
            this.currentUser.next(user);
            return user;
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  }

  async getCurrentUser() {
    return await Auth.currentAuthenticatedUser();
  }

  async isAuthenticated() {
    try {
      await this.getCurrentUser();
      return true;
    } catch (unauthenticatedError) {
      return false;
    }
  }

  async signOut() {
    await Auth.signOut();
    this.currentUser.next(unauthenticated);
  }

  async forgotPassword(username) {
    try {
      const response = await Auth.forgotPassword(username);
      return true;
    } catch (error) {
      throw error;
    }
  }
}

export default AWSAmplifyService;
