import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import Row from "react-bootstrap/Row";

const SubscriptionCustomPaymentDueDays = ({
  model,
  onDataChange,
  disabled,
}) => {
  const [enableCustomPaymentDue, setEnableCustomPaymentDue] = useState(false);
  const [previousCustomPaymentDueDays, setPreviousCustomPaymentDueDays] =
    useState(null);

  useEffect(() => {
    if (model.customPaymentDueDays) {
      setEnableCustomPaymentDue(true);
    }
  }, [model]);

  return (
    <div className="subscription__custom-payment-due__container mb-2">
      <Row>
        <Col>
          <div className="d-flex flex-column mt-2">
            <Form.Check
              type="checkbox"
              label={
                <span className="gvds-text--inputField">
                  Custom Payment Due Date
                </span>
              }
              checked={enableCustomPaymentDue}
              name="use_custom_payment_due_days"
              id="use_custom_payment_due_days"
              onChange={() => {
                const isEnabled = !enableCustomPaymentDue;
                setEnableCustomPaymentDue(isEnabled);

                if (!isEnabled) {
                  setPreviousCustomPaymentDueDays(model.customPaymentDueDays);
                  model.customPaymentDueDays = null;
                } else {
                  model.customPaymentDueDays = previousCustomPaymentDueDays;
                }
                onDataChange();
              }}
              disabled={disabled}
            />
            <div style={{ marginLeft: "26px" }}>
              Use this option to set a custom payment due date. Custom payment
              due date will not have any grace period.
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex flex-row align-items-center">
            <GVDSFormField
              placeholder="Enter number of days"
              disabled={!enableCustomPaymentDue}
              type="number"
              value={model.customPaymentDueDays}
              onInput={(value) => {
                model.customPaymentDueDays = value;
                onDataChange();
              }}
              onWheel={(e) => {
                e.target.blur();
              }}
            />
            <div className="ms-2">Days</div>
          </div>
          <div className="gvds-text--caption gvds-color--gray6">
            After Start of Subscription
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SubscriptionCustomPaymentDueDays;
