import React from "react";
import { ACTIVITY_LOG_ACTION_DISPLAY } from "./ActivityLogSharedUtils";
import _ from "lodash";

const defaultBlankValue = "-";

const ActivityLogChangesDisplay = ({ action, changes, keyLabelPair }) => {
  const isEditActionActivityLog = action === ACTIVITY_LOG_ACTION_DISPLAY.EDIT;
  const isLegacyActivityLog = isEditActionActivityLog && _.isEmpty(changes);

  if (isLegacyActivityLog) {
    return (
      <div className="gvds-text--modal-warning-text">Details not available</div>
    );
  }

  if (!isEditActionActivityLog) {
    return defaultBlankValue;
  } else {
    const changeList = keyLabelPair.map(({ key, label }) => {
      const change = changes[key];
      if (!change) {
        return null;
      }
      return (
        <div className="activity-log-changes__container" key={key}>
          <div className="activity-log-changes__label">{label}</div>
          <span className="activity-log-changes-text__line-through">
            {change[0] === null ? defaultBlankValue : change[0]}
          </span>
          <span className="activity-log-changes__text">
            {" "}
            -> {change[1] === null ? defaultBlankValue : change[1]}
          </span>
        </div>
      );
    });

    return <>{changeList}</>;
  }
};

export default ActivityLogChangesDisplay;
