import axios from "axios";
import { API_URL } from "../config/api-config";

export class BestPracticeQuestionListItemModel {
  constructor(questionListItem) {
    this.id = questionListItem.id;
    this.question_text = questionListItem.question_text;
    this.categories = questionListItem.categories;
    this.tags = questionListItem.tags;
    this.status = questionListItem.status;
    this.questionType = questionListItem.question_type;
    this.searchString = `
    [ ${this.categories.map((category) => category.name).join(", ")} ] 
    [ ${this.tags.map((tag) => tag.name).join(", ")} ]`;
  }

  get categoryNames() {
    return this.categories.map((category) => category.name);
  }

  get tagNames() {
    return this.tags.map((tag) => tag.name);
  }
}

export default class BestPracticeAdminService {
  static getBestPracticeOptions = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/best_practices_options"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  static createBestPracticeQuestion = async (
    question_text,
    categories,
    tags,
    site_types,
    description,
    question_type,
    options
  ) => {
    try {
      const data = {
        question_text,
        categories,
        tags,
        site_types,
        description,
        question_type,
        options,
      };
      const response = await axios.post(
        API_URL + "/api/v1/admin/best_practices_questions",
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editBestPracticeQuestion = async (
    question_id,
    question_text,
    categories,
    tags,
    site_types,
    description,
    question_type,
    options
  ) => {
    try {
      const data = {
        question_text,
        categories,
        tags,
        site_types,
        description,
        question_type,
        options,
      };
      const response = await axios.put(
        `${API_URL}/api/v1/admin/best_practices_questions/${question_id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static changeBestPracticeQuestionStatus = async (question_id, status) => {
    try {
      const data = {
        status,
      };
      const response = await axios.put(
        `${API_URL}/api/v1/admin/best_practices_questions/${question_id}/status`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getAllBestPracticeQuestion = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/best_practices_questions`
      );
      return response.data.map((q) => new BestPracticeQuestionListItemModel(q));
    } catch (error) {
      throw error;
    }
  };

  static getGSTCBestPracticeQuestions = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/best_practices_questions?active_only=true`
      );
      return response.data.map((q) => new BestPracticeQuestionListItemModel(q));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static getBestPracticeQuestion = async (bestPracticeId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/best_practices_questions/${bestPracticeId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getBestPracticeQuestionOptions = async (bestPracticeId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/best_practices_questions/${bestPracticeId}/options`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteBestPracticeQuestion = async (bestPracticeId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/admin/best_practices_questions/${bestPracticeId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static moveBestPracticeQuestion = async (
    questionToBeMoved,
    questionToMoveTo,
    position
  ) => {
    try {
      const data = {
        question_to_move_to_id: questionToMoveTo.id,
        position: position,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/admin/best_practices_questions/${questionToBeMoved.id}/move`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
