import React from "react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconChevronDown } from "@tabler/icons-react";

const NO_SELECTED_DISPLAY = "-";

export const DashboardFilterInputContentDisplay = ({ selectedCount, label }) => {
  const selectedCountDisplay =
    selectedCount > 0 ? selectedCount : NO_SELECTED_DISPLAY;
  return (
    <div className="d-flex flex-row">
      <div className="dashboard-filter__filter-input__content">
        <div className="dashboard-filter__filter-input__selected-count">
          {selectedCountDisplay}
        </div>
        {label}
      </div>
      <GVDSIcon Icon={IconChevronDown} />
    </div>
  );
};


