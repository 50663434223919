import React from "react";
import { CONTACT_EMAIL_SUPPORT } from "../../config/constants";

const SupportContactLink = ({
  subject,
  linkText = "support@greenviewportal.com",
  openInNewTab = false,
  className,
  children,
}) => {
  let mailto = `mailto:${CONTACT_EMAIL_SUPPORT}`;
  if (subject) {
    mailto += `?subject=${subject}`;
  }
  const props = openInNewTab
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};

  if (className) {
    props.className = className;
  }

  return (
    <a href={mailto} {...props}>
      {children ?? linkText}
    </a>
  );
};

export default SupportContactLink;
