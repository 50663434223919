import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../../HOC/withAuthentication";
import {
  SYSTEM_TOOLBOX_BRAND_FLAGS_LIST,
  SYSTEM_TOOLBOX_COMPANIES_LIST,
} from "../../../config/ROUTES_NAME";
import { Table } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import CompanyService from "../../../services/CompanyService";
import BrandFlagService from "../../../services/BrandFlagService";
import ToastContext from "../../../context/ToastContext";

const CompaniesDataTable = (props) => {
  const toastContext = useContext(ToastContext);

  const [headerName, setHeaderName] = useState("companies");
  const [activeKey, setActiveKey] = useState("companies");
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [brandFlags, setBrandFlags] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      CompanyService.getAllCompanies(),
      BrandFlagService.getAllBrandFlags(),
    ])
      .then(([companies, brandFlags]) => {
        setCompanies(companies);
        setBrandFlags(brandFlags);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load companies.</span>);
      });
  }, []);

  return (
    <>
      <section>
        <Accordion
          key={headerName}
          defaultActiveKey={activeKey}
          activeKey={activeKey === headerName ? activeKey : null}
          className="accordion-header"
        >
          <Accordion.Item eventKey={headerName}>
            <Card.Header
              onClick={() =>
                activeKey === headerName
                  ? setActiveKey(null)
                  : setActiveKey(headerName)
              }
            >
              <div className="body-2-bold">
                {headerName.toUpperCase()}{" "}
                <span className="collapse-toggle">
                  {activeKey === headerName ? "(collapse)" : "(expand)"}
                </span>
              </div>
            </Card.Header>
            <Accordion.Body>
              <Table className="reference-data-table section-box">
                <tbody>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          props.history.push(SYSTEM_TOOLBOX_COMPANIES_LIST);
                        }}
                      >
                        Companies
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {companies.length > 0 &&
                        companies
                          .slice(0, 2)
                          .map((category) => (
                            <span key={category.id}>
                              {category.name + ", "}
                            </span>
                          ))}
                      {companies.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="td-level">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          props.history.push(SYSTEM_TOOLBOX_BRAND_FLAGS_LIST);
                        }}
                      >
                        Brand Flags
                      </a>
                    </td>
                    <td className="td-level reference-data-table-entry">
                      {brandFlags.slice(0, 2).map((brandFlag) => (
                        <span key={brandFlag.id}>{brandFlag.name + ", "}</span>
                      ))}
                      {brandFlags.length > 0 ? (
                        <span>..etc</span>
                      ) : (
                        <span>No Record Found</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </>
  );
};

export default withAuthentication(CompaniesDataTable);
