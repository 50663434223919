import React, { useContext, useEffect } from "react";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import DashboardDataContext from "../Context/DashboardDataContext";
import OperationalMetricFilterView from "./OperationalMetricFilterView";
import DashboardOptionContext from "../Context/DashboardOptionContext";
import { dashboardSubtopic } from "../Models/DashboardDataConstant";
import UserInventoryContext from "../../../context/UserInventoryContext";

const divisors = [
  {
    id: "total",
    name: "Total",
  },
  { id: "gfa-sqm", name: "Per GFA (sqm)" },
  { id: "gfa-sqft", name: "Per GFA (sqft)" },
  { id: "conditionedspace-sqm", name: "Per Conditioned Space (sqm)" },
  { id: "conditionedspace-sqft", name: "Per Conditioned Space (sqft)" },
  { id: "ops-occupiedroom", name: "Per Occupied Room" },
];

export const divisorValueToSubtopic = {
  "gfa-sqm": "GFA",
  "gfa-sqft": "GFA",
  "conditionedspace-sqm": "Conditioned Space",
  "conditionedspace-sqft": "Conditioned Space",
  "ops-occupiedroom": "Occupied Room",
};

const getRelevantUnitDivisors = (divisors, defaultUnitForSubtopic) => {
  let relevantDivisors = [...divisors];

  const gfaUnit = defaultUnitForSubtopic[dashboardSubtopic.gfa];
  if (gfaUnit === "sqm") {
    relevantDivisors.splice(
      relevantDivisors.findIndex((d) => d.id === "gfa-sqft"),
      1
    );
  } else if (gfaUnit === "sqft") {
    relevantDivisors.splice(
      relevantDivisors.findIndex((d) => d.id === "gfa-sqm"),
      1
    );
  }

  const conditionedSpaceUnit =
    defaultUnitForSubtopic[dashboardSubtopic.conditionedSpace];

  if (conditionedSpaceUnit === "sqm") {
    relevantDivisors.splice(
      relevantDivisors.findIndex((d) => d.id === "conditionedspace-sqft"),
      1
    );
  } else if (conditionedSpaceUnit === "sqft") {
    relevantDivisors.splice(
      relevantDivisors.findIndex((d) => d.id === "conditionedspace-sqm"),
      1
    );
  }

  return relevantDivisors;
};

const getConsistentDivisorForInventory = (
  currentDivisorId,
  defaultUnitForSubtopic
) => {
  if (currentDivisorId === "total" || currentDivisorId === "ops-occupiedroom") {
    return currentDivisorId;
  } else {
    if (currentDivisorId.startsWith("gfa-")) {
      const gfaUnit = defaultUnitForSubtopic[dashboardSubtopic.gfa];
      if (gfaUnit === "sqm") {
        return "gfa-sqm";
      } else if (gfaUnit === "sqft") {
        return "gfa-sqft";
      }
    } else if (currentDivisorId.startsWith("conditionedspace-")) {
      const conditionedSpaceUnit =
        defaultUnitForSubtopic[dashboardSubtopic.conditionedSpace];

      if (conditionedSpaceUnit === "sqm") {
        return "conditionedspace-sqm";
      } else if (conditionedSpaceUnit === "sqft") {
        return "conditionedspace-sqft";
      }
    }
  }
};

const OperationalMetricFilter = () => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const selectedConfigHolder = dashboardDataContext.selectedConfigHolder;

  const dashboardOptionContext = useContext(DashboardOptionContext);
  const options = dashboardOptionContext.selectedDashboardOptions;
  const defaultUnitForSubtopic = options !== null ? options["defaultUnitForSubtopic"] : {};

  const userInventory = useContext(UserInventoryContext);
  const currentInventory = userInventory.selectedInventory.get;

  useEffect(() => {
    if (currentInventory && options) {
      onChangeDivisor(
        getConsistentDivisorForInventory(
          selectedConfigHolder.divisorValue,
          defaultUnitForSubtopic
        )
      );

      dashboardDataContext.onUpdateConfig();
    }
  }, [currentInventory, options]);

  const onChangeDivisor = (newDivisorValue) => {
    selectedConfigHolder.changeDivisorValue(newDivisorValue);
    dashboardDataContext.onUpdateConfig();
  };

  return (
    <OperationalMetricFilterView
      options={
        divisors &&
        getRelevantUnitDivisors(divisors, defaultUnitForSubtopic).map(
          (divisor) => {
            return { value: divisor.id, label: divisor.name };
          }
        )
      }
      selectedOption={getOptionByValueFromIdName(
        selectedConfigHolder.divisorValue,
        divisors
      )}
      setSelectedOption={(selectedOption) =>
        onChangeDivisor(selectedOption.value)
      }
    />
  );
};

export default OperationalMetricFilter;
