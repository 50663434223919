import React, { useState } from "react";

import ViewAllOperationalMeters from "./ViewAllOperationalMeters";
import InputOperationalDataRecordModal from "./InputOperationalDataRecordModal";

const OperationalDashboard = ({ datasets, onUpdate, lastUpdate }) => {
  const [createModalDatasets, setCreateModalDatasets] = useState([]);
  const [createModalMeter, setCreateModalMeter] = useState(null);
  const [show, setShow] = useState(false);

  const onMeterInput = (meter, meterDataset) => {
    setCreateModalDatasets([meterDataset]);
    setCreateModalMeter(meter);
    setShow(true);
  };

  const onCreateModalClose = () => {
    setCreateModalDatasets(datasets);
    setCreateModalMeter(null);
    setShow(false);
  };

  return (
    <div>
      <ViewAllOperationalMeters
        datasets={datasets}
        onMeterInput={onMeterInput}
        lastUpdate={lastUpdate}
      />

      <InputOperationalDataRecordModal
        show={show}
        onClose={onCreateModalClose}
        datasets={createModalDatasets}
        meter={createModalMeter}
        onDoneInput={onUpdate}
      />
    </div>
  );
};

export default OperationalDashboard;
