import React from "react";

export const isAddressSearchMatch = (address, searchTerm) => {
  const {
    street_address,
    city,
    state_or_province,
    country,
    zip_code,
  } = address;

  const countryName = country?.name;

  const lowerCasedSearchTerm = searchTerm.toLowerCase();

  return (
    countryName?.toLowerCase().includes(lowerCasedSearchTerm) ||
    street_address?.toLowerCase().includes(lowerCasedSearchTerm) ||
    city?.toLowerCase().includes(lowerCasedSearchTerm) ||
    state_or_province?.toLowerCase().includes(lowerCasedSearchTerm) ||
    zip_code?.toLowerCase().includes(lowerCasedSearchTerm)
  );
};

const AddressDisplay = ({ address }) => {
  const {
    street_address,
    city,
    state_or_province,
    country,
    zip_code,
  } = address;

  let display = "";

  if (street_address) {
    display += street_address;
  }

  if (city) {
    display.length > 0 ? (display += ", " + city) : (display = city);
  }

  if (state_or_province) {
    display.length > 0
      ? (display += ", " + state_or_province)
      : (display = state_or_province);
  }

  if (zip_code) {
    display += " " + zip_code;
  }

  if (country) {
    display.length > 0
      ? (display += ", " + country.name)
      : (display = country.name);
  }

  return <>{display}</>;
};

export default AddressDisplay;
