import React, { Fragment, useContext, useEffect, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import SubscriptionService from "../../../../services/SubscriptionService";
import Col from "react-bootstrap/Col";
import GVFormGroup from "../../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import clone from "lodash/clone";
import { UtilsService } from "../../../../services/UtilsService";
import SubscriptionSharedUtils, {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  EXCLUDED_DEFAULT_FEATURES,
  getDefaultFullFeatures,
  SubscriptionFeatureSelection,
} from "../SubscriptionSharedUtils";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import { GVDSFormErrorMessage } from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import SubscriptionPeriods from "../shared/SubscriptionPeriods";
import BillingCycle from "../shared/BillingCycle";
import SubscriptionPaymentTerms from "../shared/SubscriptionPaymentTerms";

export const InputSiteSubscriptionContent = ({
  model,
  onDataChange,
  isValidated,
  allFeatures,
  orderedPackagePlans,
  featureList,
  minEndDate = null,
  isEditing = false,
}) => {
  const onPlanChange = (plan, isCustomPlan = false) => {
    model.planId = plan.package_plan_id;

    if (!isCustomPlan) {
      model.features = plan.feature_names.map((name) => {
        return allFeatures.find((feature) => feature.name === name);
      });
    } else {
      model.features = getDefaultFullFeatures(allFeatures);
    }

    model.pricingType = null;
    model.billingCycle = SubscriptionSharedUtils.getDefaultBillingCycle(plan);
    SubscriptionSharedUtils.updateSubscriptionTermsBasedOnPlanAndBillingCycle(
      model,
      model.billingCycle,
      plan
    );

    onDataChange();
  };

  return (
    <>
      <GVFormGroup
        controlId="comment"
        className="subscription__comment__container"
      >
        <Form.Label className="gvds-text--formLabel">
          Comments <span className="optional-form-label">(optional)</span>
        </Form.Label>
        <GVDSFormTextArea
          rows={3}
          value={model.comments}
          onInput={(value) => {
            model.comments = value;
            onDataChange();
          }}
        />
      </GVFormGroup>
      <hr />
      <Row>
        <Col lg={5}>
          <GVFormGroup controlId="plan">
            <Form.Label className="gvds-text--formLabel">Plan</Form.Label>
            <div className="subscription__select-plan">
              {orderedPackagePlans.map((plan) => {
                const isCustomPlan =
                  plan.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;

                return (
                  <Fragment key={plan.package_plan_id}>
                    <Form.Check
                      id={plan.package_plan_id}
                      label={
                        <strong>
                          {isCustomPlan
                            ? CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME
                            : plan.name}
                        </strong>
                      }
                      type="radio"
                      checked={model?.planId === plan.package_plan_id}
                      onChange={() => {
                        onPlanChange(plan, isCustomPlan);
                      }}
                      disabled={isEditing}
                    />
                    {model?.planId === plan.package_plan_id && (
                      <BillingCycle
                        isValidated={isValidated}
                        packagePlan={plan}
                        model={model}
                        onDataChange={onDataChange}
                        disabled={isEditing}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
            {isValidated && !model.isPlanValid() && (
              <GVDSFormErrorMessage errorMsg="This field cannot be empty." />
            )}
          </GVFormGroup>
        </Col>
        <Col lg={7}>
          <GVFormGroup
            controlId="subscribedFeatures"
            className="site-subscription__site_features"
          >
            <Form.Label className="gvds-text--formLabel">
              Subscribed Features
            </Form.Label>
            {featureList}
            {isValidated && !model.isFeaturesValid() && (
              <GVDSFormErrorMessage errorMsg="At least 1 feature should be selected." />
            )}
          </GVFormGroup>
        </Col>
      </Row>
      <hr />
      <SubscriptionPeriods
        className="site-subscription"
        model={model}
        onDataChange={onDataChange}
        isValidated={isValidated}
        minEndDate={minEndDate}
        disabled={isEditing}
      />
      {model.isPaidSubscription() && (
        <>
          <hr />
          <SubscriptionPaymentTerms
            model={model}
            isValidated={isValidated}
            onDataChange={onDataChange}
            isCustomPlan={
              orderedPackagePlans.find(
                (p) => p.package_plan_id === model.planId
              )?.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME
            }
            disabled={isEditing}
          />
        </>
      )}
    </>
  );
};

const InputSiteSubscriptionModal = ({
  showModal,
  onCloseModal,
  siteId,
  isSiteTaxApplicable,
  currentSubscription,
  allFeatures,
  orderedPackagePlans,
  onSuccess,
}) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [model, setModel] = useState(
    SubscriptionSharedUtils.getBlankSiteSubscriptionModel(allFeatures)
  );

  useEffect(() => {
    setIsValidated(false);

    let initialModel = currentSubscription;
    if (!currentSubscription) {
      initialModel =
        SubscriptionSharedUtils.getBlankSiteSubscriptionModel(allFeatures);
      initialModel.isTaxApplicable = isSiteTaxApplicable;
    } else {
      initialModel.inputDiscountId = currentSubscription.discountCode;
    }

    setModel(initialModel);
  }, [currentSubscription, allFeatures]);

  const closeModal = () => {
    if (!isLoading) {
      onCloseModal();
    }
  };

  const onDataChange = () => {
    setModel(clone(model));
  };

  const isEditing = !!model.id;

  const featureList = (
    <SubscriptionFeatureSelection
      allFeatures={allFeatures}
      subscriptionModel={model}
      onFeatureSelected={(feature) => {
        model.features = UtilsService.toggleItem(model.features, feature);
        onDataChange();
      }}
      disabled={
        isEditing ||
        SubscriptionSharedUtils.isSubscriptionPackagePlanNotCustom(
          orderedPackagePlans,
          model.planId
        )
      }
    />
  );

  const isAllInputValidated = () => {
    return (
      model.isStartDateValid() &&
      model.isEndDateValid() &&
      model.isReminderDateValid() &&
      model.isPriceValid() &&
      model.isFeaturesValid() &&
      model.isPlanValid() &&
      model.isBillingCycleValid()
    );
  };

  const saveSubscription = async () => {
    setIsValidated(true);

    if (isAllInputValidated()) {
      setIsLoading(true);
      try {
        if (isEditing) {
          await SubscriptionService.updateSiteSubscription(
            siteId,
            model.id,
            model.toRequestPayload()
          );
        } else {
          await SubscriptionService.addSiteSubscription(
            siteId,
            model.toRequestPayload()
          );
        }
        resetInputFields();
        onCloseModal();
        onSuccess();
        toastContext.addSuccessToast(
          <span>
            {isEditing
              ? "Subscription edited successfully"
              : "Subscription added successfully"}
          </span>
        );
      } catch (error) {
        toastContext.addFailToast(<span>Failed to save subscription.</span>);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetInputFields = () => {
    setIsValidated(false);
    setModel(SubscriptionSharedUtils.getBlankSiteSubscriptionModel([]));
  };

  return (
    <>
      <GVDSModal
        show={showModal}
        onHide={closeModal}
        title={
          (currentSubscription && currentSubscription.id ? "Edit" : "Add") +
          " Subscription"
        }
        size={GVDSModal.Size.medium}
      >
        <GVDSModal.Body>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <InputSiteSubscriptionContent
              model={model}
              onDataChange={onDataChange}
              isValidated={isValidated}
              featureList={featureList}
              allFeatures={allFeatures}
              orderedPackagePlans={orderedPackagePlans}
              isEditing={isEditing}
            />
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            disabled={isLoading}
            onClick={closeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            disabled={isLoading}
            onClick={saveSubscription}
            text="Save"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default InputSiteSubscriptionModal;
