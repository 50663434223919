import React, { useContext, useEffect, useState } from "react";

import { DateTimeUtils, UserUtils } from "../../../services/UtilsService";
import ContractService from "../../../services/ContractService";
import ToastContext from "../../../context/ToastContext";
import InlineSpinner from "../../common/InlineSpinner";
import UserInventoryContext from "../../../context/UserInventoryContext";

const PortfolioCreatorDisplay = ({ currentPortfolio }) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);

  const contractId = userInventory.selectedContractId.get;

  const [isLoading, setIsLoading] = useState(false);

  const [portfolioMetadata, setPortfolioMedata] = useState(null);

  const loadPortfolioMetadata = () => {
    setIsLoading(true);
    if (currentPortfolio.id) {
      ContractService.getPortfolioMetadata(contractId, currentPortfolio.id)
        .then((response) => {
          setPortfolioMedata(response);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load portfolio resources.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (currentPortfolio) {
      loadPortfolioMetadata();
    }
  }, [currentPortfolio]);

  let creationDisplayInfo = "No Information";

  if (isLoading) {
    creationDisplayInfo = (
      <div
        style={{ height: "24px", width: "fit-content", paddingLeft: "12px" }}
      >
        <InlineSpinner />
      </div>
    );
  } else if (portfolioMetadata) {
    let creatorInfo = "";
    let timestampInfo = "";

    if (portfolioMetadata.created_by) {
      creatorInfo = UserUtils.getFullNameOrEmail(
        portfolioMetadata.created_by.full_name,
        portfolioMetadata.created_by.email
      );
    } else {
      creatorInfo = "Greenview";
    }

    if (portfolioMetadata.created_on) {
      timestampInfo = DateTimeUtils.formatLocalDate(
        portfolioMetadata.created_on
      );
    }

    creationDisplayInfo = (
      <>
        Created by: {creatorInfo}
        <br />
        Created on: {timestampInfo}
      </>
    );
  }

  return <div>{creationDisplayInfo}</div>;
};

export default PortfolioCreatorDisplay;
