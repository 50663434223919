import React from "react";
import { InputSiteSubscriptionContent } from "../Subscription/SiteSubscription/InputSiteSubscriptionModal";
import clone from "lodash/clone";
import { UtilsService } from "../../../services/UtilsService";
import LoadingSpinner from "../../common/LoadingSpinner";
import SubscriptionSharedUtils, {
  SubscriptionFeatureSelection,
} from "../Subscription/SubscriptionSharedUtils";

const CreateSiteSubscriptionForm = ({
  subscription,
  onChange,
  isValidated,
  isLoadingSubscriptionOptions,
  allFeatures,
  orderedPackagePlans,
  minEndDate,
}) => {
  const onDataChange = () => {
    onChange(clone(subscription));
  };

  const toggleFeature = (feature) => {
    subscription.features = UtilsService.toggleItem(
      subscription.features,
      feature
    );
    onDataChange();
  };

  const featureList = isLoadingSubscriptionOptions ? (
    <LoadingSpinner />
  ) : (
    <SubscriptionFeatureSelection
      allFeatures={allFeatures}
      subscriptionModel={subscription}
      onFeatureSelected={(feature) => toggleFeature(feature)}
      disabled={SubscriptionSharedUtils.isSubscriptionPackagePlanNotCustom(
        orderedPackagePlans,
        subscription.planId
      )}
    />
  );

  return (
    <>
      <section className="section-box">
        <header>
          <h2 id="add-subscription">Add Subscription</h2>
        </header>
        <article>
          <InputSiteSubscriptionContent
            model={subscription}
            onDataChange={onDataChange}
            isValidated={isValidated}
            allFeatures={allFeatures}
            orderedPackagePlans={orderedPackagePlans}
            featureList={featureList}
            minEndDate={minEndDate}
          />
        </article>
      </section>
    </>
  );
};

export default CreateSiteSubscriptionForm;
