import React from "react";
import { NumberService } from "../../../services/UtilsService";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCircleFilled } from "@tabler/icons-react";

const DashboardSeparatorBullet = ({ color }) => {
  return (
    <GVDSIcon
      Icon={IconCircleFilled}
      className={`dashboard-separator-bullet`}
      style={{ "--bullet-color": color }}
    />
  );
};

const DashboardEnvClassificationDataTooltip = ({ contents }) => {
  return (
    <table className="dashboard--chart__popover-tooltip-table">
      {contents.map((content) => (
        <tr key={content.typeName}>
          <td align="right">
            <strong>{NumberService.format(content.value, 2, 2)}</strong>{" "}
            {content.unit}
          </td>
          <td align="right">({content.percentage})</td>
          <td>
            <DashboardSeparatorBullet color={content.color} />
            {content.typeName}
          </td>
        </tr>
      ))}
    </table>
  );
};

export default DashboardEnvClassificationDataTooltip;
