import GVDSInfoCard from "../../gvds-components/common/GVDSInfoCard";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";
import { useTranslation } from "react-i18next";

const MeterPossibleIssuesTurnedOffBanner = ({ showAlertPreferencesModal }) => {
  const { t } = useTranslation();

  return (
    <GVDSInfoCard
      className="meter-details__possible-issues-turned-off-banner"
      useIcon={true}
      title={
        <>
          <span className="me-1">
            {t(
              "data-management.shared.alert-preferences.possible-issues.turned-off-description"
            )}
          </span>
          <GVDSTextButton
            text={t("shared.update")}
            onClick={showAlertPreferencesModal}
          />
        </>
      }
    />
  );
};

export default MeterPossibleIssuesTurnedOffBanner;
