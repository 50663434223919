import React from "react";

import Modal from "react-bootstrap/Modal";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";

const CopyInitiativePrompt = ({ show, closeModal, onCopy }) => {
  return (
    <GVDSModal
      title="Copy Initiative"
      size={GVDSModal.Size.small}
      show={show}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        The copied initiative will have all details, except for Documents and
        Images. To proceed, click on the <strong>Copy</strong> button
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={closeModal}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          className="copy-initiative-modal__copy-button"
          onClick={() => {
            closeModal();
            onCopy();
          }}
          text="Copy"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default CopyInitiativePrompt;
