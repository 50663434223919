import React from "react";
import Form from "react-bootstrap/Form";

const GVFormGroup = React.forwardRef(
  ({ children, className, ...props }, ref) => {
    return (
      <Form.Group
        ref={ref}
        className={`form-group ${className ? className : ""}`}
        {...props}
      >
        {children}
      </Form.Group>
    );
  }
);
export default GVFormGroup;
