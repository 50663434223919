import DashboardExpandedModalView from "../../DashboardExpandedModalView";
import React from "react";
import { ExpandedChangeOverTimeSiteRankingChartViewHeader } from "./ChangeOverTimeSiteRankingChartViewHeaders";
import { expandedModalViewOptionEnum } from "../../Models/DashboardModels";
import ChangeOverTimeSiteRankingChartViewContent from "./ChangeOverTimeSiteRankingChartViewContent";
import { ChangeOverTimeSiteRankingChartUtils } from "./ChangeOverTimeSiteRankingChartUtils";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import { changeOverTimeSiteRankingConfig } from "./ChangeOverTimeSiteRankingChartViewConfig";
import { ParentSize } from "@visx/responsive";
import LoadingSpinnerInFullPage from "../../../common/LoadingSpinnerInFullPage";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";

const ExpandedChartView = ({
  sortedChartData,
  intensityUnit,
  unit,
  selectedViewType,
}) => {
  const chartHeight =
    ChangeOverTimeSiteRankingChartUtils.getExpandedViewChartHeight(
      Math.max(
        sortedChartData.length,
        changeOverTimeSiteRankingConfig.maxBarCount
      )
    );

  return (
    <div className="chart-container">
      <ParentSize>
        {(parent) => (
          <ChangeOverTimeSiteRankingChartViewContent
            width={parent.width}
            height={chartHeight}
            sortedChartData={sortedChartData}
            selectedViewType={selectedViewType}
            unit={unit}
            intensityUnit={intensityUnit}
            isExpandedView={true}
          />
        )}
      </ParentSize>
    </div>
  );
};

const ExpandedTableView = ({ sortedChartData, unit, intensityUnit }) => {
  if (sortedChartData.length > 0) {
    const tableRowData = sortedChartData.map((datum) => datum.toTableRowData());
    const firstYear = sortedChartData[0].year1;
    const secondYear = sortedChartData[0].year2;
    const displayedUnit = intensityUnit ? `${unit}/${intensityUnit}` : unit;

    return (
      <div className="dashboard--expanded-view__table-container">
        <GVDSTableDisplay>
          <thead>
            <tr>
              <th>Site Name</th>
              <th style={{ textAlign: "right" }}>
                {`${firstYear} (${displayedUnit})`}
              </th>
              <th style={{ textAlign: "right" }}>
                {`${secondYear} (${displayedUnit})`}
              </th>
              <th style={{ textAlign: "right" }}>
                {`YoY Change (${displayedUnit})`}
              </th>
              <th style={{ textAlign: "right" }}>% Change</th>
            </tr>
          </thead>
          <tbody>
            {tableRowData.map((rowData, index) => (
              <tr key={index}>
                {rowData.map((cellData, columnIndex) => (
                  <td
                    key={`row-${columnIndex}`}
                    align={
                      columnIndex ===
                      changeOverTimeSiteRankingConfig.siteNameColumnIndex
                        ? "left"
                        : "right"
                    }
                  >
                    {columnIndex ===
                    changeOverTimeSiteRankingConfig.siteNameColumnIndex
                      ? cellData
                      : DashboardWidgetSharedUtils.getChartValueDisplay(
                          cellData,
                          false,
                          2
                        )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </GVDSTableDisplay>
      </div>
    );
  }
};

const ExpandedChangeOverTimeSiteRankingChartViewBody = ({
  sortedChartData,
  unit,
  intensityUnit,
  selectedExpandedViewOption,
  selectedViewType,
}) => {
  return (
    <div className="dashboard--change-over-time-site-ranking__expanded-chart-view__content-container">
      {selectedExpandedViewOption === expandedModalViewOptionEnum.CHART ? (
        <ExpandedChartView
          sortedChartData={sortedChartData}
          selectedViewType={selectedViewType}
          unit={unit}
          intensityUnit={intensityUnit}
        />
      ) : (
        <ExpandedTableView
          sortedChartData={sortedChartData}
          unit={unit}
          intensityUnit={intensityUnit}
        />
      )}
    </div>
  );
};

const ExpandedChangeOverTimeSiteRankingChartView = ({
  isShowExpandedChartView,
  onCloseModal,
  sortedChartData,
  title,
  hasDataGaps,
  period,
  subtopic,
  intensity,
  selectedSiteCount,
  unit,
  intensityUnit,
  hasData,
  sortOrder,
  onSortOrderChange,
  selectedViewType,
  onViewTypeChange,
  selectedExpandedViewOption,
  onExpandedViewOptionChange,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  return (
    <>
      <DashboardExpandedModalView
        id={changeOverTimeSiteRankingConfig.expandedChartViewId}
        isShow={isShowExpandedChartView}
        closeModal={onCloseModal}
        hideCloseButton={isDownloadingChart}
        header={
          <ExpandedChangeOverTimeSiteRankingChartViewHeader
            title={title}
            hasDataGaps={hasDataGaps}
            period={period}
            subtopic={subtopic}
            intensity={intensity}
            hasData={hasData}
            selectedSiteCount={selectedSiteCount}
            sortOrder={sortOrder}
            onSortOrderChange={onSortOrderChange}
            selectedViewType={selectedViewType}
            onViewTypeChange={onViewTypeChange}
            selectedExpandedViewOption={selectedExpandedViewOption}
            onExpandedViewOptionChange={onExpandedViewOptionChange}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        }
        body={
          <ExpandedChangeOverTimeSiteRankingChartViewBody
            sortedChartData={sortedChartData}
            selectedExpandedViewOption={selectedExpandedViewOption}
            selectedViewType={selectedViewType}
            unit={unit}
            intensityUnit={intensityUnit}
          />
        }
      />
      {isShowExpandedChartView && isDownloadingChart && (
        <LoadingSpinnerInFullPage />
      )}
    </>
  );
};

export default ExpandedChangeOverTimeSiteRankingChartView;
