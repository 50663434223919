import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconAlertTriangleFilled } from "@tabler/icons-react";

const MissingValueWarningIcon = () => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="missing-value__warning_message">
          This number was generated with data gaps. Download a report for more
          details.
        </Tooltip>
      }
    >
      <span>
        <GVDSIcon
          Icon={IconAlertTriangleFilled}
          className="yellow-exclamation-triangle"
        />
      </span>
    </OverlayTrigger>
  );
};

export default MissingValueWarningIcon;
