import axios from "axios";
import { API_URL } from "../../config/api-config";
import { DateTimeUtils } from "../UtilsService";
import { WidgetTypeEnum } from "../../components/Dashboard/Models/WidgetModels";

class DashboardModel {
  constructor(id, name, dashboardSchema, createdOn) {
    this.id = id;
    this.name = name;
    this.dashboardSchema = dashboardSchema;
    this.createdOn = createdOn;
  }

  static fromDTO(dto) {
    return new DashboardModel(
      dto["id"],
      dto["name"],
      dto["dashboard_schema"],
      DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(dto["created_on"])
    );
  }

  isUseTab() {
    return (
      this.dashboardSchema.widgets.length === 1 &&
      this.dashboardSchema.widgets[0].type === WidgetTypeEnum.Tabs
    );
  }
}

export class DashboardService {
  static async getAllDashboards(resource_type, resource_id) {
    try {
      const params = {
        resource_type,
        resource_id,
      };
      const response = await axios.get(`${API_URL}/api/v1/dashboards`, {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getDashboard(resource_type, resource_id, dashboardId) {
    try {
      const params = {
        resource_type,
        resource_id,
      };
      const response = await axios.get(
        `${API_URL}/api/v1/dashboards/${dashboardId}`,
        {
          params,
        }
      );
      return DashboardModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  }

  static async getOptions(resource_type, resource_id) {
    try {
      const params = {
        resource_type,
        resource_id,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/dashboard-options`,
        {},
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
