import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import { CertificationCategoryAdminService } from "../../../../services/PortalAdmin/Certification/CertificationCategoryAdminService";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVFormGroup from "../../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import { StringUtils } from "../../../../services/UtilsService";
import { POSITION } from "../../../../config/constants";
import GVDSFormSingleSelect from "../../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import UnsavedChangePromptModal from "../../../common/UnsavedChangePromptModal";
import { useTranslation } from "react-i18next";

export const CategoryFormModal = ({
  show,
  setShowInputCategoryForm,
  closeModal,
  certificationId,
  category,
  onConfirmDeleteCategory,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [isValidated, setIsValidated] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const [showUnsavedChangePromptModal, setShowUnsavedChangePromptModal] =
    useState(false);

  useEffect(() => {
    if (category) {
      setCategoryName(category.name);
    } else {
      setCategoryName("");
    }
  }, [category]);

  const isInputsValid = () => {
    return categoryName;
  };

  const resetInputFields = () => {
    setCategoryName("");
    setIsValidated(false);
  };

  const hasChanges = () => {
    if (category !== null) {
      return category.name !== categoryName;
    } else {
      return categoryName.length > 0;
    }
  };

  const handleClose = () => {
    if (!isSaving) {
      if (hasChanges()) {
        setShowInputCategoryForm(false);
        setShowUnsavedChangePromptModal(true);
      } else {
        closeModal();
        resetInputFields();
      }
    }
  };

  const handleCancelCloseModal = () => {
    setShowUnsavedChangePromptModal(false);
    setShowInputCategoryForm(true);
  };

  const handleCloseModalResetForm = () => {
    setShowUnsavedChangePromptModal(false);
    closeModal();
    resetInputFields();
  };

  const saveCategory = async () => {
    setIsValidated(true);
    if (!isInputsValid()) {
      return;
    }

    setIsSaving(true);
    if (category) {
      try {
        const updatedCategory =
          await CertificationCategoryAdminService.updateCategory(
            certificationId,
            category.id,
            categoryName
          );
        toastContext.addSuccessToast(
          <span>Category successfully updated.</span>
        );
        resetInputFields();
        onSuccess(updatedCategory);
      } catch (error) {
        if (error.response.status === 400) {
          toastContext.addFailToast(
            <span>{error.response.data?.message}</span>
          );
        } else {
          toastContext.addFailToast(<span>Failed to update category.</span>);
        }
      } finally {
        setIsSaving(false);
      }
    } else {
      try {
        const newCategory =
          await CertificationCategoryAdminService.createCategory(
            certificationId,
            categoryName
          );
        toastContext.addSuccessToast(
          <span>Category successfully created.</span>
        );
        resetInputFields();
        onSuccess(newCategory);
      } catch (error) {
        if (error.response.status === 400) {
          toastContext.addFailToast(
            <span>{error.response.data?.message}</span>
          );
        } else {
          toastContext.addFailToast(
            <span>Failed to create new category.</span>
          );
        }
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      <GVDSModal
        title={`${category ? "Edit" : "Add"} category`}
        size={GVDSModal.Size.small}
        show={show}
        onHide={handleClose}
      >
        <GVDSModal.Body>
          <GVFormGroup controlId="name">
            <Form.Label>Category name</Form.Label>
            <GVDSFormTextArea
              name="name"
              value={categoryName}
              onInput={setCategoryName}
              placeholder="Enter category name"
              statusMetadata={
                isValidated && !categoryName
                  ? FormFieldStatusMetadata.getError(
                      "This field cannot be empty."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
            <div className="gvds-text--caption mt-1">
              Only the first 30 characters will be displayed, the rest truncated
              for display.
            </div>
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {category && (
            <>
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                onClick={onConfirmDeleteCategory}
                text={t("shared.delete")}
              />
              <Spacer />
            </>
          )}
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            onClickAsyncFunc={saveCategory}
            disabled={isSaving || !StringUtils.isNotEmpty(categoryName)}
            text={t("shared-modal.footer.save")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
      <UnsavedChangePromptModal
        show={showUnsavedChangePromptModal}
        onCancel={handleCancelCloseModal}
        onProceed={handleCloseModalResetForm}
        modalName="Input Category"
        message="You have unsaved changes in the form. Your data will be cleared and won’t be stored when you leave this page. Are you sure?"
      />
    </>
  );
};

export const ConfirmDeleteCategoryModal = ({
  show,
  isDeleting,
  closeModal,
  asyncOnDelete,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    if (!isDeleting) {
      closeModal();
    }
  };

  return (
    <GVDSModal
      show={show}
      onHide={handleClose}
      title="Delete category"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <div>Are you sure want to delete this Category?</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          text={t("shared-modal.footer.cancel")}
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.destructive_primary}
          onClickAsyncFunc={asyncOnDelete}
          text={t("shared.delete")}
          disabled={isDeleting}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const CannotDeleteCategoryModal = ({ show, closeModal }) => {
  const { t } = useTranslation();

  return (
    <GVDSModal
      show={show}
      onHide={closeModal}
      title="Unable to delete category"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <div>
          Please delete all nested Criteria or move them to another Category
          before deleting this Category.
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={closeModal}
          text={t("shared-modal.footer.okay")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const MoveCertificationCategoryModal = ({
  show,
  closeModal,
  onSuccess,
  categoryToBeMoved,
  allCategories,
  certificationId,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const [isValidated, setIsValidated] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const [position, setPosition] = useState(POSITION.BEFORE);
  const [categoryToMoveTo, setCategoryToMoveTo] = useState(null);

  const resetState = () => {
    setCategoryToMoveTo(null);
    setIsValidated(false);
    setPosition(POSITION.BEFORE);
  };

  const handleClose = () => {
    if (!isMoving) {
      closeModal();
    }
  };

  const moveCategory = async () => {
    setIsValidated(true);

    if (categoryToMoveTo) {
      setIsMoving(true);

      try {
        const newCategories =
          await CertificationCategoryAdminService.moveCategory(
            certificationId,
            categoryToBeMoved,
            categoryToMoveTo,
            position
          );
        resetState();
        onSuccess(newCategories);
        toastContext.addSuccessToast(<span>Category successfully moved.</span>);
      } catch {
        toastContext.addFailToast(<span>Failed to move category.</span>);
      } finally {
        setIsMoving(false);
      }
    }
  };

  return (
    <GVDSModal
      title="Move category to"
      size={GVDSModal.Size.small}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>
        <GVFormGroup>
          <Form.Label>Position</Form.Label>
          <Form.Check
            id="before"
            label="Before"
            type="radio"
            checked={position === POSITION.BEFORE}
            onChange={() => setPosition(POSITION.BEFORE)}
          />
          <Form.Check
            id="after"
            label="After"
            type="radio"
            checked={position === POSITION.AFTER}
            onChange={() => {
              setPosition(POSITION.AFTER);
            }}
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Category Name</Form.Label>
          <GVDSFormSingleSelect
            placeholder="Select category"
            className="category-to-move-to"
            isSearchable={true}
            value={
              categoryToMoveTo
                ? {
                    value: categoryToMoveTo,
                    label: categoryToMoveTo.name,
                  }
                : null
            }
            options={allCategories.map((q) => {
              return { value: q, label: q.name };
            })}
            isOptionDisabled={(option) => option.value === categoryToBeMoved}
            onSelect={(selected) => {
              setCategoryToMoveTo(selected.value);
            }}
            statusMetadata={
              isValidated && !categoryToMoveTo
                ? FormFieldStatusMetadata.getError("Please select a category")
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            onClickAsyncFunc={moveCategory}
            text="Move"
            disabled={isMoving}
          />
        </>
      </GVDSModal.Footer>
    </GVDSModal>
  );
};
