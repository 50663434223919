import { Link, useLocation } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import { getViewHelpArticlePath } from "../../config/ROUTES_NAME";
import React from "react";

const HelpfulTipsLinkDisplay = ({ helpArticleListItemModel }) => {
  let location = useLocation();

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover>
          <Popover.Body>{helpArticleListItemModel.description}</Popover.Body>
        </Popover>
      }
    >
      <Link
        to={getRedirectURLWithCurrentParam(
          getViewHelpArticlePath(helpArticleListItemModel.id),
          location
        )}
        target="_blank"
        className="widget--helpful-tips__item"
      >
        <div>{helpArticleListItemModel.title}</div>
      </Link>
    </OverlayTrigger>
  );
};

export default HelpfulTipsLinkDisplay;
