import React, { useContext, useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import UserService from "../../services/UserService";
import LoadingSpinner from "../common/LoadingSpinner";
import withAuthentication from "../HOC/withAuthentication";
import { DisableMfaSetup, EnableMfaSetup } from "./MfaSetup";
import ToastContext from "../../context/ToastContext";
import UserProfileContext from "../../context/UserProfileContext";
import GVFormGroup from "../common/GVFormGroup";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import Spacer from "../../gvds-components/Layout/Spacer";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import LanguageSetup from "./LanguageSetup";
import { Trans, useTranslation } from "react-i18next";
import SupportContactLink from "../common/SupportContactLink";

const UserProfile = () => {
  const { t } = useTranslation();

  const userProfileContext = useContext(UserProfileContext);
  const [profile, setProfile] = useState(null);

  const loadUserProfile = () => {
    userProfileContext.loadUserProfile();
  };

  useEffect(() => {
    if (!userProfileContext.isLoadingUserProfile) {
      setProfile(userProfileContext.getUserProfile());
    }
  }, [userProfileContext.isLoadingUserProfile]);

  if (!profile) {
    return <LoadingSpinner />;
  }

  const mfaStatus = () => {
    return profile.is_mfa_enabled ? (
      <StatusLabel color={StatusLabel.Colors.green} className="ms-2">
        {t("settings.security.status-enabled-mfa")}
      </StatusLabel>
    ) : (
      <StatusLabel color={StatusLabel.Colors.gray} className="ms-2">
        {t("settings.security.status-disabled-mfa")}
      </StatusLabel>
    );
  };

  return (
    <>
      <div>
        <PageHeader>
          <PageHeader.Title>
            <h1>{t("settings.title")}</h1>
          </PageHeader.Title>
        </PageHeader>
        <div className="col-lg-8 col-sm-12">
          <section className="section-box">
            <header>
              <h2>{t("settings.profile.title")}</h2>
            </header>
            <article className="compact">
              <div className="d-flex align-items-center">
                <GVFormGroup>
                  <Form.Label>
                    {t("settings.profile.label-full-name")}
                  </Form.Label>
                  <Form.Control
                    readOnly
                    plaintext
                    value={profile.full_name || ""}
                  />
                </GVFormGroup>
                <div className="ms-auto">
                  <EditUserFullName
                    userId={profile.id}
                    fullName={profile.full_name}
                    onSuccess={loadUserProfile}
                  />
                </div>
              </div>
            </article>
            <article className="compact">
              <div className="d-flex align-items-center">
                <GVFormGroup>
                  <Form.Label>
                    {t("settings.profile.label-position")}
                  </Form.Label>
                  <Form.Control
                    readOnly
                    plaintext
                    value={profile.position || ""}
                  />
                </GVFormGroup>
                <div className="ms-auto">
                  <EditUserPosition
                    userId={profile.id}
                    position={profile.position}
                    onSuccess={loadUserProfile}
                  />
                </div>
              </div>
            </article>
            <article className="compact">
              <GVFormGroup>
                <Form.Label>
                  {t("settings.profile.label-email-address")}
                </Form.Label>
                <Form.Control readOnly plaintext value={profile.email || ""} />
              </GVFormGroup>
            </article>
          </section>
          <section className="section-box">
            <header>
              <h2>{t("settings.security.title")}</h2>
            </header>
            <article className="compact">
              <div className="d-flex align-items-center">
                <Form.Label>{t("settings.security.label-password")}</Form.Label>
                <div className="ms-auto">
                  <ChangePassword userId={profile.id} />
                </div>
              </div>
            </article>
            <article className="compact">
              <div className="d-flex align-items-center">
                <div>
                  <Form.Label>
                    {t("settings.security.label-mfa")} {mfaStatus()}
                  </Form.Label>
                  <div className="gvds-text--caption gvds-color--gray6">
                    {t("settings.security.description-mfa")}
                  </div>
                </div>
                <Spacer />
                {!profile.is_mfa_enabled ? (
                  <EnableMfaSetup onSuccess={loadUserProfile} />
                ) : (
                  <DisableMfaSetup onSuccess={loadUserProfile} />
                )}
              </div>
            </article>
          </section>
          <section className="section-box">
            <header>
              <h2>{t("settings.language.title")}</h2>
              <div className="mt-1 gvds-text--caption gvds-color--gray6">
                <Trans i18nKey="settings.language.description">
                  Greenview Portal is in English by default. Please select your
                  preferred display language below. If the translation is not
                  available, English will be displayed. Please reach out to{" "}
                  <SupportContactLink
                    openInNewTab={true}
                    className="gvds-text--caption"
                  />{" "}
                  if you have any feedback.
                </Trans>
              </div>
              <article className="compact">
                <LanguageSetup />
              </article>
            </header>
          </section>
        </div>
      </div>
    </>
  );
};

const EditUserFullName = ({ userId, fullName, onSuccess }) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };
  const [fullNameInput, setFullNameInput] = useState("");

  useEffect(() => {
    setFullNameInput(fullName);
  }, [userId, fullName]);

  const updateFullName = () => {
    UserService.updateUserProfile(fullNameInput, null)
      .then(() => {
        toastContext.addSuccessToast(
          <span>Your full name has been updated.</span>
        );
        closeModal();
        onSuccess();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>We were unable to process your changes. Please try again.</span>
        );
      });
  };

  return (
    <>
      <Button variant="link" onClick={() => setShow(true)}>
        {t("shared.edit")}
      </Button>
      <GVDSModal
        title="Edit Full Name"
        size={GVDSModal.Size.small}
        show={show}
        onHide={closeModal}
      >
        <GVDSModal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              updateFullName();
            }}
          >
            <GVFormGroup>
              <Form.Label>Full Name</Form.Label>
              <GVDSFormField
                placeholder="Enter your full name"
                value={fullNameInput}
                onInput={(value) => setFullNameInput(value)}
              />
            </GVFormGroup>
          </Form>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={updateFullName}
            text="Save"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

const EditUserPosition = ({ userId, position, onSuccess }) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };
  const [positionInput, setPositionInput] = useState("");

  useEffect(() => {
    setPositionInput(position);
  }, [userId, position]);

  const updatePosition = () => {
    UserService.updateUserProfile(null, positionInput)
      .then(() => {
        toastContext.addSuccessToast(
          <span>Your position has been updated.</span>
        );
        closeModal();
        onSuccess();
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>We were unable to process your changes. Please try again.</span>
        );
      });
  };

  return (
    <>
      <Button variant="link" onClick={() => setShow(true)}>
        {t("shared.edit")}
      </Button>
      <Modal
        title="Edit Position"
        size={GVDSModal.Size.small}
        show={show}
        onHide={closeModal}
      >
        <GVDSModal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              updatePosition();
            }}
          >
            <GVFormGroup>
              <Form.Label>Position</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your position"
                value={positionInput}
                onChange={(e) => setPositionInput(e.target.value)}
              />
            </GVFormGroup>
          </Form>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={updatePosition}
            text="Save"
          />
        </GVDSModal.Footer>
      </Modal>
    </>
  );
};

const ChangePassword = () => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [show, setShow] = useState(false);
  const closeModal = () => {
    resetFields();
    setShow(false);
  };

  const [isValidated, setIsValidated] = useState(false);
  const [isCurrentPasswordWrong, setIsCurrentPasswordWrong] = useState(false);

  const [currentPasswordInput, setCurrentPasswordInput] = useState("");
  const [newPasswordInput, setNewPasswordInput] = useState("");
  const [confirmNewPasswordInput, setConfirmNewPasswordInput] = useState("");

  const resetFields = () => {
    setIsValidated(false);
    setCurrentPasswordInput("");
    setNewPasswordInput("");
    setConfirmNewPasswordInput("");
  };

  const isCurrentPasswordEmpty = () => {
    return currentPasswordInput.length === 0;
  };

  const isNewPasswordValid = () => {
    return newPasswordInput.length >= 8;
  };

  const isNewPasswordMatching = () => {
    return newPasswordInput === confirmNewPasswordInput;
  };

  const changePassword = () => {
    setIsValidated(true);

    if (
      !isCurrentPasswordEmpty() &&
      isNewPasswordValid() &&
      isNewPasswordMatching()
    ) {
      UserService.changePassword(currentPasswordInput, newPasswordInput)
        .then(() => {
          toastContext.addSuccessToast(
            <span>Your password has been updated.</span>
          );
          resetFields();
          closeModal();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setIsCurrentPasswordWrong(true);
            toastContext.addFailToast(<span>Wrong password.</span>);
          } else {
            toastContext.addFailToast(
              <span>
                We were unable to process your changes. Please try again.
              </span>
            );
          }
        });
    }
  };

  return (
    <>
      <Button variant="link" onClick={() => setShow(true)}>
        {t("settings.security.button-change-password")}
      </Button>
      <GVDSModal
        title="Change Password"
        size={GVDSModal.Size.small}
        show={show}
        onHide={closeModal}
      >
        <GVDSModal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              changePassword();
            }}
          >
            <GVFormGroup>
              <Form.Label>Current Password</Form.Label>
              <GVDSFormField
                type="password"
                value={currentPasswordInput}
                onInput={(value) => setCurrentPasswordInput(value)}
                statusMetadata={
                  isValidated && isCurrentPasswordEmpty()
                    ? FormFieldStatusMetadata.getError(
                        "Please enter your current password."
                      )
                    : isCurrentPasswordWrong
                    ? FormFieldStatusMetadata.getError("Wrong password")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>New Password</Form.Label>
              <GVDSFormField
                type="password"
                value={newPasswordInput}
                onInput={(value) => setNewPasswordInput(value)}
                statusMetadata={
                  isValidated && !isNewPasswordValid()
                    ? FormFieldStatusMetadata.getError(
                        "Your password should have at least 8 characters."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>Confirm New Password</Form.Label>
              <GVDSFormField
                type="password"
                value={confirmNewPasswordInput}
                onInput={(value) => setConfirmNewPasswordInput(value)}
                statusMetadata={
                  isValidated && !isNewPasswordMatching()
                    ? FormFieldStatusMetadata.getError(
                        "New password is not matching."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Form>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={changePassword}
            text="Change Password"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default withAuthentication(UserProfile);
