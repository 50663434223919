import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";

import { getViewSurveyPath, SURVEYS_CREATE } from "../../../config/ROUTES_NAME";
import SurveyManagementService from "../../../services/SurveyManagementService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { DateTimeUtils, NumberService } from "../../../services/UtilsService";
import {
  FEATURES,
  PERMISSIONS,
  SURVEY_STATUS,
} from "../../../config/constants";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import LoadingSpinner from "../../common/LoadingSpinner";
import { usePrevious } from "../../common/ReactHook";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import UserProfileContext from "../../../context/UserProfileContext";
import { surveyPortfolioSearchKeys } from "../../../config/search-config";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import SurveyStatusBadge from "../Components/SurveyStatusBadge";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTable from "../../../gvds-components/Table/GVDSTable";
import { useTranslation } from "react-i18next";

const PortfolioViewAllSurveys = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();
  const toastContext = useContext(ToastContext);

  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);

  const isAllowedToManageSurvey =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.SURVEY_MANAGEMENT];
  const hasFeatureToCreateSurvey =
    getSubscriptionFeatureStatus(
      [FEATURES.SURVEY__CREATE],
      userInventory.isLoadingInventory.get,
      userInventory.selectedTreeNode,
      userProfile
    ) === SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const goToCreateSurvey = () => {
    history.push(SURVEYS_CREATE);
  };

  const goToSurvey = (surveyId) => {
    history.push(getViewSurveyPath(surveyId));
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(surveys, surveyPortfolioSearchKeys);

  useEffect(() => {
    if (userInventory.isLoadingInventory.get) {
      setSurveys([]);
    } else if (
      selectedInventory &&
      (!previousInventory ||
        selectedInventory.type !== previousInventory.type ||
        selectedInventory.id !== previousInventory.id)
    ) {
      setIsLoading(true);
      SurveyManagementService.getAllSurvey(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((surveyList) => {
          setSurveys(surveyList);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to load surveys.</span>);
        });
    }
  }, [userInventory.isLoadingInventory.get, selectedInventory]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const columns = [
    {
      header: "Survey Name",
      dataKey: "name",
      headerStyle: { minWidth: "200px" },
      sortable: true,
    },
    {
      header: "Created for",
      dataKey: "created_for",
      sortable: true,
    },
    {
      header: "Deadline",
      dataKey: "deadline",
      headerStyle: { width: "200px" },
      sortable: true,
      renderer: (s) => (
        <span>
          {s.deadline ? DateTimeUtils.formatUTCDate(s.deadline) : "-"}{" "}
          {DateTimeUtils.getRemainingDaysBefore(s.deadline) <= 30 ? (
            <span className="caption color-gray56 fst-italic">
              ({DateTimeUtils.getSurveyDeadlineDueMessage(s.deadline, s.status)}
              )
            </span>
          ) : null}
        </span>
      ),
    },
    {
      header: "Sites Completed",
      dataKey: "completed_sites",
      headerStyle: { maxWidth: "300px", width: "300px" },
      renderer: (s) => {
        if (s.status === SURVEY_STATUS.DRAFT) {
          return "-";
        }
        const progress = NumberService.format(
          (100 * s.completed_sites) / s.total_sites,
          2,
          2
        );
        return (
          <div className="survey-progress">
            <ProgressBar now={progress} />
            <div className="d-flex">
              <div className="ms-1 caption">
                {s.completed_sites}/{s.total_sites}
              </div>
              <div className="ms-1 caption">({progress}%)</div>
            </div>
          </div>
        );
      },
    },
    {
      header: "Status",
      dataKey: "status",
      headerStyle: { width: "200px" },
      sortable: true,
      renderer: (s) => <SurveyStatusBadge status={s.status} />,
    },
  ];

  const getContent = () => {
    if (surveys.length === 0) {
      return (
        <div className="table__no_content mt-2">
          <p>No surveys yet.</p>
        </div>
      );
    } else if (filteredSortedData.length === 0) {
      return (
        <div className="table__no_content mt-2">
          <p>No surveys. Try changing your filter.</p>
        </div>
      );
    } else {
      return (
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          onRowClick={(s) => goToSurvey(s.id)}
        />
      );
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("surveys.page-title")}</h1>
          {isAllowedToManageSurvey && hasFeatureToCreateSurvey && (
            <>
              <Spacer />
              <GVDSButton
                variant={buttonVariant.primary}
                className="create-survey"
                onClick={goToCreateSurvey}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text="Create Survey"
              />
            </>
          )}
        </PageHeader.Title>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search for a Survey"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={[
            SURVEY_STATUS.DRAFT,
            SURVEY_STATUS.OPEN,
            SURVEY_STATUS.CLOSED,
          ]}
          prefix="Status"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, status: filterKs })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      {getContent()}
    </>
  );
};

export default PortfolioViewAllSurveys;
