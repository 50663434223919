import React from "react";
import {
  USER_APPROVAL_REQUEST_STATUS,
  USER_STATUS,
} from "../../config/constants";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../services/UtilsService";

const userStatusToColorMap = {
  [USER_STATUS.AWAIT_LOGIN]: StatusLabel.Colors.yellow,
  [USER_STATUS.PASSWORD_RESET]: StatusLabel.Colors.yellow,
  [USER_STATUS.AWAIT_LOGIN]: StatusLabel.Colors.yellow,
  [USER_STATUS.INACTIVE]: StatusLabel.Colors.yellow,
  [USER_STATUS.ACTIVE]: StatusLabel.Colors.green,
  [USER_STATUS.NONEXISTENT]: StatusLabel.Colors.red,
};

const userApprovalRequestStatusToColorMap = {
  [USER_APPROVAL_REQUEST_STATUS.PENDING]: StatusLabel.Colors.yellow,
  [USER_APPROVAL_REQUEST_STATUS.APPROVED]: StatusLabel.Colors.green,
  [USER_APPROVAL_REQUEST_STATUS.REJECTED]: StatusLabel.Colors.red,
};

export const UserStatusBadge = ({ status }) => {
  const colorVariant = userStatusToColorMap[status];
  const capitalisedStatus = StringUtils.capitaliseWord(status);

  return <StatusLabel color={colorVariant}>{capitalisedStatus}</StatusLabel>;
};

export const UserApprovalRequestStatusBadge = ({ status }) => {
  const colorVariant = userApprovalRequestStatusToColorMap[status];
  const capitalisedStatus = StringUtils.capitaliseWord(status);

  return <StatusLabel color={colorVariant}>{capitalisedStatus}</StatusLabel>;
};
