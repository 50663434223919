import { useContext, useEffect } from "react";
import DashboardDataContext from "../Context/DashboardDataContext";
import InlineSpinner from "../../common/InlineSpinner";
import { classificationGroups } from "../Models/DashboardDataConstant";
import { usePrevious } from "../../common/ReactHook";
import ClassificationFilterView from "./ClassificationFilterView";

const ClassificationSelector = ({ subtopic }) => {
  const dashboardDataContext = useContext(DashboardDataContext);

  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const prevSubtopic = usePrevious(subtopic);

  const classificationValuesByGroup =
    dashboardHolderFacade.getSubtopicClassificationValuesByGroup(subtopic);

  const onChangeClassificationGroup = (classificationGroupName) => {
    dashboardHolderFacade.changeClassificationGroup(
      classificationGroupName,
      subtopic
    );
    dashboardDataContext.onUpdateConfig();
  };

  const onChangeClassificationValues = (classificationValues) => {
    dashboardHolderFacade.changeClassificationValues(classificationValues);
    dashboardDataContext.onUpdateConfig();
  };

  const selectedClassificationGroup =
    dashboardHolderFacade.classificationGroupSelected;
  const selectedValues = dashboardHolderFacade.classificationValuesSelected;

  const isSubtopicClassificationLoaded = classificationValuesByGroup === null;
  const isDefaultClassificationGroupInitialised =
    selectedClassificationGroup === null;

  useEffect(() => {
    if (
      isDefaultClassificationGroupInitialised ||
      isSubtopicClassificationLoaded ||
      subtopic !== prevSubtopic
    ) {
      dashboardHolderFacade.changeClassificationGroup(
        classificationGroups.environmentType,
        subtopic
      );
      dashboardDataContext.onUpdateConfig();
    }
  }, [subtopic, classificationValuesByGroup]);

  if (
    isDefaultClassificationGroupInitialised ||
    isSubtopicClassificationLoaded
  ) {
    return (
      <div>
        <InlineSpinner />
      </div>
    );
  }

  const groupOptions = Object.keys(classificationValuesByGroup)
    .sort((group1, group2) => {
      if (group1 === classificationGroups.environmentType) {
        return -1;
      } else if (group2 === classificationGroups.environmentType) {
        return 1;
      } else {
        if (group1 === classificationGroups.scope) {
          return -1;
        } else if (group2 === classificationGroups.scope) {
          return 1;
        } else {
          return group1.localeCompare(group2);
        }
      }
    })
    .map((classificationGroupName) => {
      return { value: classificationGroupName, label: classificationGroupName };
    });
  const valueOptions = classificationValuesByGroup[selectedClassificationGroup];

  return (
    <>
      <ClassificationFilterView
        classificationGroupOptions={groupOptions}
        selectedGroupOption={{
          value: selectedClassificationGroup,
          label: selectedClassificationGroup,
        }}
        setSelectedGroup={(option) => onChangeClassificationGroup(option.value)}
        classificationValueOptions={valueOptions}
        selectedValues={selectedValues}
        setSelectedValues={(selectedOptions) => {
          onChangeClassificationValues(selectedOptions);
        }}
      />
    </>
  );
};

export default ClassificationSelector;
