import React from "react";
import { RESOURCES } from "../../config/constants";

const TagVariants = {
  system: "system",
  site: "site",
  portfolio: "portfolio",
};

export const VARIANT_BY_RESOURCE_TYPE = {
  [RESOURCES.SITE]: TagVariants.site,
  [RESOURCES.PORTFOLIO]: TagVariants.portfolio,
};

export const TAG_COLOR_BY_VARIANT = {
  [TagVariants.system]: "celeste",
  [TagVariants.site]: "lime",
  [TagVariants.portfolio]: "lavender",
};

const GVDSTag = React.forwardRef(
  ({ variant = TagVariants.system, children, ...props }, ref) => {
    const tagColor = TAG_COLOR_BY_VARIANT[variant];
    return (
      <div ref={ref} className={`gvds-tag tag--${tagColor}`} {...props}>
        {children}
      </div>
    );
  }
);

GVDSTag.Variants = TagVariants;

export default GVDSTag;
