import axios from "axios";
import { API_URL } from "../config/api-config";
import { FEATURE_ORDER } from "../config/constants";

export default class FeatureService {
  static getAvailableFeatures = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/features`);

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static featureSortFn = (feature1Name, feature2Name) => {
    const sortOrder1 = FEATURE_ORDER[feature1Name.toLowerCase()];
    const sortOrder2 = FEATURE_ORDER[feature2Name.toLowerCase()];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return -1;
    } else if (sortOrder2 !== undefined) {
      return 1;
    } else {
      return feature1Name.localeCompare(feature2Name);
    }
  };
}
