export const BillingCycleEnum = {
  monthly: "monthly",
  yearly: "yearly",
};

class PackagePlanPriceModel {
  constructor(billingCycle, currency, priceInDecimal, lookupKey) {
    this.billingCycle = billingCycle;
    this.currency = currency;
    this.priceInDecimal = priceInDecimal;
    this.lookupKey = lookupKey;
  }

  static fromDTO(dto) {
    return new PackagePlanPriceModel(
      dto["billing_cycle"],
      dto["currency"],
      dto["price_in_decimal"],
      dto["lookup_key"]
    );
  }
}

export class PackagePlanModel {
  constructor(packagePlanId, name, prices, hasTrial, hasMonthly, hasYearly, features) {
    this.packagePlanId = packagePlanId;
    this.name = name;
    this.prices = prices;
    this.monthlyPrices = prices.filter(
      (price) => price.billingCycle === BillingCycleEnum.monthly
    );
    this.yearlyPrices = prices.filter(
      (price) => price.billingCycle === BillingCycleEnum.yearly
    );
    this.hasTrial = hasTrial;
    this.hasMonthly = hasMonthly;
    this.hasYearly = hasYearly;
    this.features = features;
  }

  getMonthlyPriceForCurrency(currency) {
    return this.monthlyPrices.find(
      (p) => p.currency.toLowerCase() === currency.toLowerCase()
    );
  }

  getYearlyPriceForCurrency(currency) {
    return this.yearlyPrices.find(
      (p) => p.currency.toLowerCase() === currency.toLowerCase()
    );
  }

  static fromDTO(dto) {
    return new PackagePlanModel(
      dto["package_plan_id"],
      dto["name"],
      dto["prices"].map((p) => PackagePlanPriceModel.fromDTO(p)),
      dto["is_trial_available"],
      dto["is_monthly_available"],
      dto["is_yearly_available"],
      dto["feature_names"]
    );
  }
}
