import React from "react";
import ViewAllTask from "./ViewAllTask";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import { useTranslation } from "react-i18next";

const ViewArchivedTasks = ({ show, onClose }) => {
  const { t } = useTranslation();

  return (
    <GVDSModal
      className="view-archived-tasks"
      title={t("landing-page.widget-task-mgmt.label-archived-tasks")}
      size={GVDSModal.Size.medium}
      show={show}
      onHide={onClose}
    >
      <GVDSModal.Body>
        <ViewAllTask isArchivedTasks={true} />
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <div className="d-flex w-100">
          <GVDSButton
            className="ms-auto"
            variant={buttonVariant.tertiary}
            onClick={onClose}
            text={t("shared-modal.footer.close")}
          />
        </div>
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default ViewArchivedTasks;
