import React from "react";
import Row from "react-bootstrap/Row";
import GVFormGroup from "../../../common/GVFormGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import SubscriptionSharedUtils, {
  SubscriptionBillingCycle,
} from "../SubscriptionSharedUtils";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import SelectCurrency from "../../../Currency/SelectCurrency";
import SubscriptionCustomPaymentDueDays from "./SubscriptionCustomPaymentDueDays";

const SubscriptionPaymentTerms = ({
  model,
  isValidated,
  onDataChange,
  isCustomPlan,
  disabled,
}) => {
  const disablePriceInput =
    !isCustomPlan && model.billingCycle !== SubscriptionBillingCycle.CUSTOM;

  return (
    <>
      <Row>
        <Col lg={6}>
          <GVFormGroup controlId="inputPrice">
            <Form.Label className="gvds-text--body__bold">Price</Form.Label>
            <GVDSFormField
              type="number"
              placeholder="0.00"
              value={model.priceAmount}
              onInput={(value) => {
                model.priceAmount = value;
                onDataChange();
              }}
              disabled={disabled || disablePriceInput}
              statusMetadata={
                isValidated && !model.isPriceAmountValid()
                  ? FormFieldStatusMetadata.getError(
                      SubscriptionSharedUtils.getPriceAmountValidationMessage(
                        model.priceAmount
                      )
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
              onWheel={(e) => {
                e.target.blur();
              }}
            />
          </GVFormGroup>
        </Col>
        <Col lg={6}>
          <GVFormGroup controlId="InputCurrency">
            <Form.Label className="gvds-text--body__bold">Currency</Form.Label>
            <SelectCurrency
              selected={model.currencyId}
              onCurrencySelected={(currencyId) => {
                model.currencyId = currencyId;
                onDataChange();
              }}
              disabled={disabled || disablePriceInput}
              showLabel={false}
              isInvalid={isValidated && !model.isCurrencyValid()}
              invalidMessage="This field cannot be empty."
            />
          </GVFormGroup>
        </Col>
      </Row>
      <div className="subscription__tax-switch__container">
        <div className="tax-switch-label">Apply GST</div>
        <Form.Check
          className="tax-switch"
          type="switch"
          checked={model.isTaxApplicable}
          onChange={() => {
            model.isTaxApplicable = !model.isTaxApplicable;
            onDataChange();
          }}
          disabled={true}
        />
        <div className="tax-switch-caption">
          GST should be applied for entities based in Singapore
        </div>
      </div>
      <div className="mt-3">
        <SubscriptionCustomPaymentDueDays
          model={model}
          onDataChange={onDataChange}
          disabled={disabled}
        />
      </div>
      <div className="mt-3">
        <Row>
          <Col lg={6}>
            <GVFormGroup>
              <Form.Label>Discount ID</Form.Label>
              <GVDSFormField
                placeholder="Discount ID from Stripe"
                value={model.inputDiscountId}
                onInput={(value) => {
                  model.inputDiscountId = value;
                  onDataChange();
                }}
                disabled={!model.isNew()}
              />
            </GVFormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SubscriptionPaymentTerms;
