import axios from "axios";
import { API_URL } from "../../config/api-config";

export const billingWindowFeatures = "popup=1,width=500,height=800,left=100,top=100,noopener,noreferrer";

export default class BillingPaymentService {
  static getPaymentUrl = async (subscription_type, subscription_id, hash) => {
    try {
      const data = {
        subscription_type,
        subscription_id,
        hash
      };

      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${API_URL}/api/v1/billing/payment`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
