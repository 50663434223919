import { PERFORMANCE_REPORT_OPERATIONAL_SUBTOPIC } from "./PerformanceReportConfig";
import _ from "lodash";
import PerformanceBenchmarkReportModel from "./PerformanceBenchmarkReportModel";
import moment from "moment";
import { SUBTOPIC_TYPE } from "../../../config/constants";
import { DateTimeUtils } from "../../../services/UtilsService";

export const PerformanceReportStatus = {
  DEFAULT: "",
  AVAILABLE: "Available",
  UNAVAILABLE: "Unavailable",
  INSUFFICIENT_CHSB_DATA: "Insufficient CHSB Data",
};

class DateRange {
  constructor(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  static fromStartMonthToEndMonth(year, startMonth, endMonth) {
    const startDate = new Date(year, startMonth - monthIndexOffset, 1);
    const endDate = moment(new Date(year, endMonth - monthIndexOffset, 1))
      .endOf("month")
      .toDate();
    return new DateRange(startDate, endDate);
  }
}

const monthIndexOffset = 1;

export default class PerformanceReportParamsModel {
  static getPerformanceGroupMap(params, config) {
    if (!params["subtopic_name"] || !config["environmental_subtopics"])
      return {};
    return _.chain(config)
      .get("environmental_subtopics", [])
      .find((s) => s.name === params["subtopic_name"])
      .get("performance_groups", [])
      .reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
      .value();
  }

  static getUnitMap(params, config) {
    if (!params["subtopic_name"] || !config["environmental_subtopics"])
      return {};

    if (
      params["use_performance_group"] ||
      params["subtopic_name"] === SUBTOPIC_TYPE.EMISSIONS
    ) {
      return _.chain(config)
        .get("environmental_subtopics", [])
        .find((s) => s.name === params["subtopic_name"])
        .get("units", [])
        .sortBy("name")
        .reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
        .value();
    } else {
      return _.chain(config)
        .get("environmental_subtopics", [])
        .find((s) => s.name === params["subtopic_name"])
        .get("environmental_types", [])
        .find((e) => e.id === params["environmental_type_id"])
        .get("units", [])
        .sortBy("name")
        .reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
        .value();
    }
  }

  static getEnvironmentalTypeMap(params, config) {
    if (!params["subtopic_name"] || !config["environmental_subtopics"])
      return {};

    return _.chain(config)
      .get("environmental_subtopics", [])
      .find((s) => s.name === params["subtopic_name"])
      .get("environmental_types", [])
      .sortBy(["name"])
      .reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
      .value();
  }

  static getOperationMetrics(config) {
    let metrics = [];
    if (config["operational_metrics"]) {
      metrics = metrics.concat(config["operational_metrics"]);
    }
    if (config["area_types"]) {
      metrics = metrics.concat(config["area_types"].map((a) => a.name));
    }
    return metrics;
  }

  static getOperationUnitMap(params, config) {
    return _.chain(config)
      .get("area_types", [])
      .find((s) => s["name"] === params["operational_metric"])
      .get("units", [])
      .sortBy("name")
      .reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
      .value();
  }

  static getInputDisableStatus(params, config) {
    if (params["subtopic_name"] === PERFORMANCE_REPORT_OPERATIONAL_SUBTOPIC) {
      return {
        currency: true,
        operationalMetric: true,
        operationalMetricUnit: true,
      };
    }
    if (
      PerformanceReportParamsModel.showWasteDiversionRate(params, config) ||
      PerformanceReportParamsModel.showRenewableRateGroups(params, config)
    ) {
      return {
        unit: true,
        currency: true,
        operationalMetric: true,
        operationalMetricUnit: true,
      };
    }
    return {};
  }

  static getMonthRangeLabel(start, end) {
    const monthShort = moment.monthsShort();
    if (start === end) {
      return `${monthShort[start - 1]}`;
    } else {
      return `${monthShort[start - 1]}-${monthShort[end - 1]}`;
    }
  }

  static getMonthLabel(months) {
    if (months.length === 0) return "";
    let startMon = months[0];
    let endMon = months[0] - 1;
    let monthLabels = [];
    for (let m of months) {
      if (m === endMon + 1) {
        endMon = m;
      } else {
        monthLabels.push(
          PerformanceReportParamsModel.getMonthRangeLabel(startMon, endMon)
        );
        startMon = m;
        endMon = m;
      }
    }
    monthLabels.push(
      PerformanceReportParamsModel.getMonthRangeLabel(startMon, endMon)
    );
    return monthLabels.join(", ");
  }

  static generateDateRangesFromMonthsYears(months, years) {
    if (months.length === 0) return [];

    let startMon = months[0];
    let endMon = months[0] - 1;
    let dateRanges = [];

    months.forEach((m) => {
      if (m === endMon + 1) {
        endMon = m;
      } else {
        dateRanges.push(
          ...years.map((year) =>
            DateRange.fromStartMonthToEndMonth(year, startMon, endMon)
          )
        );
        startMon = m;
        endMon = m;
      }
    });

    dateRanges.push(
      ...years.map((year) =>
        DateRange.fromStartMonthToEndMonth(year, startMon, endMon)
      )
    );
    return dateRanges;
  }

  static getTableStatus(params, config) {
    if (
      !params["years"] ||
      params["years"].length === 0 ||
      PerformanceReportParamsModel.disableDownloadReport(params, config)
    )
      return PerformanceReportStatus.DEFAULT;
    if (params["years"].length > 2) return PerformanceReportStatus.UNAVAILABLE;
    return PerformanceReportStatus.AVAILABLE;
  }

  static getChartStatus(params, config) {
    if (PerformanceReportParamsModel.disableDownloadReport(params, config))
      return PerformanceReportStatus.DEFAULT;
    if (
      params["years"].length > 4 ||
      PerformanceReportParamsModel.showRenewableRateGroups(params, config)
    )
      return PerformanceReportStatus.UNAVAILABLE;
    return PerformanceReportStatus.AVAILABLE;
  }

  static getBenchmarkStatus(params, config) {
    if (PerformanceReportParamsModel.disableDownloadReport(params, config))
      return PerformanceReportStatus.DEFAULT;

    const benchmarkChecklist =
      PerformanceReportParamsModel.getBenchmarkChecklist(params, config);
    if (
      !benchmarkChecklist.subtopic ||
      !benchmarkChecklist.performance_group ||
      !benchmarkChecklist.metric ||
      !benchmarkChecklist.year
    )
      return PerformanceReportStatus.UNAVAILABLE;

    return PerformanceReportStatus.AVAILABLE;
  }

  static getBenchmarkChecklist(params, config) {
    if (!config) return {};
    return {
      subtopic:
        config["benchmark_subtopic"]?.indexOf(params["subtopic_name"]) >= 0,
      performance_group:
        config["benchmark_performance_groups"]?.indexOf(
          params["performance_group_name"]
        ) >= 0,
      metric:
        config["benchmark_operation_metrics"]?.indexOf(
          params["operational_metric"]
        ) >= 0,
      year:
        params["years"].length > 0 &&
        params["years"].indexOf(new Date().getFullYear()) < 0,
      location_based: params["use_location_based"] !== false,
    };
  }

  static getBenchmarkReportStatus(params, config, benchmarkReport) {
    const paramsStatus = PerformanceReportParamsModel.getBenchmarkStatus(
      params,
      config
    );
    if (paramsStatus !== PerformanceReportStatus.AVAILABLE) {
      return paramsStatus;
    }
    const performanceBenchmark = new PerformanceBenchmarkReportModel(
      benchmarkReport
    );
    if (performanceBenchmark.benchmark === null) {
      return PerformanceReportStatus.UNAVAILABLE;
    }
    if (!performanceBenchmark.isDisplayable()) {
      return PerformanceReportStatus.INSUFFICIENT_CHSB_DATA;
    }
    return PerformanceReportStatus.AVAILABLE;
  }

  static getDefaultUseLocationBasedForEnvironmentalType(
    environmentalTypeId,
    params,
    config
  ) {
    if (
      !PerformanceReportParamsModel.isSubtopicEnabledForMarketBasedEmission(
        params["subtopic_name"],
        config
      )
    ) {
      return null;
    }
    if (
      !PerformanceReportParamsModel.isEnvironmentalTypeEnabledForMarketBasedEmission(
        environmentalTypeId,
        config
      )
    ) {
      return true;
    }
    return params["use_location_based"] === null
      ? true
      : params["use_location_based"];
  }

  static getDefaultUseLocationBasedForPerformanceGroup(
    performanceGroupId,
    params,
    config
  ) {
    if (
      !PerformanceReportParamsModel.isSubtopicEnabledForMarketBasedEmission(
        params["subtopic_name"],
        config
      )
    ) {
      return null;
    }
    if (
      !PerformanceReportParamsModel.isPerformanceGroupEnabledForMarketBasedEmission(
        performanceGroupId,
        config
      )
    ) {
      return true;
    }
    return params["use_location_based"] === null
      ? true
      : params["use_location_based"];
  }

  static isAreaMetric(value, config) {
    if (!config["area_types"]) return false;
    return config["area_types"].map((a) => a.name).includes(value);
  }

  static isEnvironmentalTypeEnabledForMarketPurchase = (
    environmentalTypeId,
    config
  ) =>
    config["market_purchase_offset_enabled_config"]["environmental_types"].some(
      (e) => environmentalTypeId === e["id"]
    );

  static isEnvironmentalTypeSupportedForCustomEmission = (
    environmentalTypeId,
    config
  ) =>
    config["custom_ef_supported_config"]["environmental_types"].some(
      (e) => environmentalTypeId === e["id"]
    );

  static isEnvironmentalTypeEnabledForMarketBasedEmission = (
    environmentalTypeId,
    config
  ) =>
    PerformanceReportParamsModel.isEnvironmentalTypeEnabledForMarketPurchase(
      environmentalTypeId,
      config
    ) ||
    PerformanceReportParamsModel.isEnvironmentalTypeSupportedForCustomEmission(
      environmentalTypeId,
      config
    );

  static isPerformanceGroupEnabledForMarketPurchase = (
    performanceGroupId,
    config
  ) =>
    config["market_purchase_offset_enabled_config"]["performance_groups"].some(
      (p) => performanceGroupId === p["id"]
    );

  static isPerformanceGroupSupportedForCustomEmission = (
    performanceGroupId,
    config
  ) =>
    config["custom_ef_supported_config"]["performance_groups"].some(
      (p) => performanceGroupId === p["id"]
    );

  static isPerformanceGroupEnabledForMarketBasedEmission = (
    performanceGroupId,
    config
  ) =>
    PerformanceReportParamsModel.isPerformanceGroupEnabledForMarketPurchase(
      performanceGroupId,
      config
    ) ||
    PerformanceReportParamsModel.isPerformanceGroupSupportedForCustomEmission(
      performanceGroupId,
      config
    );

  static isSubtopicEnabledForMarketPurchase = (subtopicName, config) =>
    config["market_purchase_offset_enabled_config"] &&
    config["market_purchase_offset_enabled_config"]["subtopics"].some(
      (s) => subtopicName === s["name"]
    );

  static isSubtopicSupportedForCustomEmission = (subtopicName, config) =>
    config["custom_ef_supported_config"] &&
    config["custom_ef_supported_config"]["subtopics"].some(
      (s) => subtopicName === s["name"]
    );

  static isSubtopicEnabledForMarketBasedEmission = (subtopicName, config) =>
    PerformanceReportParamsModel.isSubtopicEnabledForMarketPurchase(
      subtopicName,
      config
    ) ||
    PerformanceReportParamsModel.isSubtopicSupportedForCustomEmission(
      subtopicName,
      config
    );

  static isSelectedMonthsEmpty(params) {
    return params["months"].length === 0;
  }

  static showWasteDiversionRate(params, config) {
    return (
      params["performance_group_name"] === config["waste_diversion_rate_name"]
    );
  }

  static showRenewableRateGroups(params, config) {
    return (
      config["renewables_rate_groups"] &&
      config["renewables_rate_groups"].some(
        (g) => params["performance_group_name"] === g
      )
    );
  }

  static showMarketPurchaseOption(params, config) {
    return (
      PerformanceReportParamsModel.isSubtopicEnabledForMarketBasedEmission(
        params["subtopic_name"],
        config
      ) &&
      (params["environmental_type_id"] || params["performance_group_id"])
    );
  }

  static isSubtopicEmissions(params) {
    return params["subtopic_name"] === SUBTOPIC_TYPE.EMISSIONS;
  }

  static disableMarketPurchaseOption(params, config) {
    return (
      PerformanceReportParamsModel.showMarketPurchaseOption(params, config) &&
      !PerformanceReportParamsModel.isEnvironmentalTypeEnabledForMarketBasedEmission(
        params["environmental_type_id"],
        config
      ) &&
      !PerformanceReportParamsModel.isPerformanceGroupEnabledForMarketBasedEmission(
        params["performance_group_id"],
        config
      )
    );
  }

  static disableMarketBasedEmissionsOption(params, config) {
    return (
      !PerformanceReportParamsModel.isSubtopicEmissions(params) ||
      PerformanceReportParamsModel.disableMarketPurchaseOption(
        params,
        config
      ) ||
      (!PerformanceReportParamsModel.isCustomEFAvailable(params, config) &&
        !PerformanceReportParamsModel.isMarketPurchaseOffsetRecordAvailable(
          params,
          config
        ))
    );
  }

  static isCustomEFAvailable(params, config) {
    let isCustomEFAvailableForSelectedYears = false;
    const years = params["years"];

    if (params["use_performance_group"]) {
      const inputPerformanceGroupId = params["performance_group_id"];
      for (const year of years) {
        const customEfAvailablePerformanceGroupIds =
          config["custom_ef_availability"][year]?.performance_group_ids;
        if (
          customEfAvailablePerformanceGroupIds !== undefined &&
          customEfAvailablePerformanceGroupIds !== null &&
          customEfAvailablePerformanceGroupIds.some(
            (id) => id === inputPerformanceGroupId
          )
        ) {
          isCustomEFAvailableForSelectedYears = true;
          break;
        }
      }
    } else {
      const inputEnvTypeId = params["environmental_type_id"];
      for (let year of years) {
        const customEfAvailableEnvTypeIds =
          config["custom_ef_availability"][year]?.environmental_type_ids;
        if (
          customEfAvailableEnvTypeIds !== undefined &&
          customEfAvailableEnvTypeIds !== null &&
          customEfAvailableEnvTypeIds.some((id) => inputEnvTypeId)
        ) {
          isCustomEFAvailableForSelectedYears = true;
          break;
        }
      }
    }

    return isCustomEFAvailableForSelectedYears;
  }

  static isMarketPurchaseOffsetRecordAvailable(params, config) {
    const years = params["years"];
    const months = params["months"];

    const selectedDateRanges =
      PerformanceReportParamsModel.generateDateRangesFromMonthsYears(
        months,
        years
      );
    const periodsWithMarketPurchase =
      config["market_purchase_offset_records_period"];

    return selectedDateRanges.some((dateRange) =>
      periodsWithMarketPurchase.some((period) =>
        DateTimeUtils.isDateRangeOverlap(
          new Date(period.period_from),
          new Date(period.period_to),
          dateRange.startDate,
          dateRange.endDate
        )
      )
    );
  }

  static disableDownloadReport(params, config) {
    if (_.isEmpty(config)) return true;

    if (
      PerformanceReportParamsModel.showWasteDiversionRate(params, config) ||
      PerformanceReportParamsModel.showRenewableRateGroups(params, config)
    ) {
      return params["years"].length === 0;
    }
    const operational_units = _.chain(config)
      .get("area_types", [])
      .find((s) => s["name"] === params["operational_metric"])
      .get("units", null)
      .value();

    if (params["subtopic_name"] === PERFORMANCE_REPORT_OPERATIONAL_SUBTOPIC) {
      return (
        !params["operational_metric"] ||
        params["years"].length === 0 ||
        params["months"].length === 0 ||
        (operational_units && !params["operational_unit_id"])
      );
    } else {
      const isReportOnTypeAbsent =
        !params["environmental_type_id"] && !params["performance_group_id"];
      return (
        !params["subtopic_name"] ||
        isReportOnTypeAbsent ||
        !params["unit_id"] ||
        !params["currency_id"] ||
        params["years"].length === 0 ||
        params["months"].length === 0 ||
        (operational_units && !params["operational_unit_id"])
      );
    }
  }

  static disableRunReport(params, config) {
    return !(
      PerformanceReportParamsModel.getTableStatus(params, config) ===
        PerformanceReportStatus.AVAILABLE ||
      PerformanceReportParamsModel.getChartStatus(params, config) ===
        PerformanceReportStatus.AVAILABLE
    );
  }
}
