import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import intersection from "lodash/intersection";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Popover } from "react-bootstrap";
import { faRecycle } from "@fortawesome/free-solid-svg-icons";

import UserInventoryContext from "../../../context/UserInventoryContext";
import SummaryService from "../../../services/SummaryService";
import {
  DATA_STATUS,
  FEATURES,
  RESOURCES,
  SUBTOPIC_TYPE,
} from "../../../config/constants";
import LoadingSpinner from "../../common/LoadingSpinner";
import { DateTimeUtils, NumberService } from "../../../services/UtilsService";
import {
  PERFORMANCE_REPORT_OPERATIONAL_METRICS,
  PERFORMANCE_REPORT_OPERATIONAL_PER_LABEL,
} from "../../Report/PerformanceReport/PerformanceReportConfig";
import {
  ADMIN_FACILITY_DETAILS,
  ADMIN_SITE_DETAILS,
  ENVIRONMENTAL_DATA,
  getAdminFacilityDetailsPath,
  OPERATIONAL_DATA,
  REPORT_STANDARD_REPORT,
} from "../../../config/ROUTES_NAME";
import {
  getRedirectURLWithCurrentParam,
  getURLWithPageParams,
} from "../../common/QueryHandler";
import ToastContext from "../../../context/ToastContext";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import UserProfileContext from "../../../context/UserProfileContext";
import { FeatureLockWidgetContent } from "./BaseWidgetEmptyContent";
import Spacer from "../../../gvds-components/Layout/Spacer";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import {
  IconAlertTriangleFilled,
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconBulb,
} from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { isRequestAborted } from "../../../services/HttpService";
import BaseWidgetPeriodControl from "./BaseWidgetPeriodControl";
import PopoverOnBigNumberTruncated from "../../common/Tooltip/PopoverOnBigNumberTruncated";
import { infoTooltipPopupDelayStart } from "../../../config/style-config";
import { useTranslation } from "react-i18next";

const MetricVarianceColor = {
  GREEN: StatusLabel.Colors.green,
  RED: StatusLabel.Colors.red,
  GRAY: StatusLabel.Colors.gray,
};

const defaultStartPeriod = moment()
  .subtract(12, "months")
  .startOf("month")
  .toDate();
const defaultEndPeriod = moment().subtract(1, "months").endOf("month").toDate();

const MetricVarianceView = ({ variance, isNegativeVarianceGood = true }) => {
  const { t } = useTranslation();

  const increasingColor = isNegativeVarianceGood
    ? MetricVarianceColor.RED
    : MetricVarianceColor.GREEN;
  const decreasingColor = isNegativeVarianceGood
    ? MetricVarianceColor.GREEN
    : MetricVarianceColor.RED;

  const isIncreasing = !(variance?.value < 0);

  const isShowStyle =
    variance && Number.isFinite(variance.value) && variance.value !== 0;

  const color = !isShowStyle
    ? MetricVarianceColor.GRAY
    : isIncreasing
    ? increasingColor
    : decreasingColor;

  const displayValue = !Number.isFinite(variance?.value)
    ? "-"
    : NumberService.format(Math.abs(variance.value * 100), 1, 1);

  const varianceIcon = !isShowStyle ? (
    <div className="icon-placeholder" />
  ) : isIncreasing ? (
    <GVDSIcon Icon={IconArrowNarrowUp} />
  ) : (
    <GVDSIcon Icon={IconArrowNarrowDown} />
  );

  return (
    <OverlayTrigger
      placement={"top"}
      delay={infoTooltipPopupDelayStart}
      overlay={
        <Popover>
          <Popover.Body>
            {t("landing-page.widget-env-metrics.tooltip-variance")}
          </Popover.Body>
        </Popover>
      }
    >
      <StatusLabel
        color={color}
        className="widget--environmental-metrics__box__metric-variance-status"
      >
        <span
          className={`widget--environmental-metrics__box__variance-icon ${color}`}
        >
          {varianceIcon}
        </span>
        <span className="widget--environmental-metrics__box__variance-value">
          {displayValue}%
        </span>
      </StatusLabel>
    </OverlayTrigger>
  );
};

const SummaryDataStatus = {
  MISSING_DATA_GENERAL: "Missing Data in General",
  NO_ENVIRONMENTAL: "No Environmental",
  NO_OPERATIONAL: "No Operational",
  NO_AREA: "No Area",
  COMPLETE_DATA: "Complete Data",
};

const getSummaryDataMissingMessage = (t) => {
  return {
    [SummaryDataStatus.MISSING_DATA_GENERAL]: t(
      "landing-page.widget-env-metrics.missing-data.general"
    ),
    [SummaryDataStatus.NO_AREA]: t(
      "landing-page.widget-env-metrics.missing-data.no-area"
    ),
    [SummaryDataStatus.NO_OPERATIONAL]: t(
      "landing-page.widget-env-metrics.missing-data.no-operational"
    ),
    [SummaryDataStatus.NO_ENVIRONMENTAL]: t(
      "landing-page.widget-env-metrics.missing-data.no-environmental"
    ),
  };
};

const getSummaryStatus = (summary, intensityMetric) => {
  if (!summary) {
    return SummaryDataStatus.COMPLETE_DATA;
  }

  if (
    summary["environmental_status"] === DATA_STATUS.NA ||
    summary["environmental_completeness"] === 0
  ) {
    return SummaryDataStatus.NO_ENVIRONMENTAL;
  }
  if (summary["intensity_completeness"] === 0) {
    if (
      [
        PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA,
        PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA,
      ].indexOf(intensityMetric) >= 0
    ) {
      return SummaryDataStatus.NO_AREA;
    } else if (
      [
        PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_GUEST_NIGHTS,
        PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_OCCUPIED_ROOMS,
      ].indexOf(intensityMetric) >= 0
    ) {
      return SummaryDataStatus.NO_OPERATIONAL;
    } else {
      return SummaryDataStatus.NO_ENVIRONMENTAL;
    }
  }
  if (
    summary["environmental_completeness"] < 100 ||
    summary["intensity_completeness"] < 100
  ) {
    return SummaryDataStatus.MISSING_DATA_GENERAL;
  }

  return SummaryDataStatus.COMPLETE_DATA;
};

const getRedirectPath = (summary, intensityMetric, resource_type) => {
  const summaryStatus = getSummaryStatus(summary, intensityMetric);
  if (summaryStatus === SummaryDataStatus.NO_ENVIRONMENTAL) {
    return ENVIRONMENTAL_DATA;
  } else if (summaryStatus === SummaryDataStatus.NO_OPERATIONAL) {
    return OPERATIONAL_DATA;
  } else if (summaryStatus === SummaryDataStatus.NO_AREA) {
    if (resource_type === RESOURCES.PORTFOLIO) {
      return ADMIN_SITE_DETAILS;
    } else if (resource_type === RESOURCES.SITE) {
      return ADMIN_SITE_DETAILS;
    } else {
      return ADMIN_FACILITY_DETAILS;
    }
  } else {
    return REPORT_STANDARD_REPORT;
  }
};

const MissingDataWarning = ({ summaryStatus }) => {
  const { t } = useTranslation();

  return (
    <>
      {summaryStatus !== SummaryDataStatus.COMPLETE_DATA && (
        <OverlayTrigger
          placement="top"
          delay={infoTooltipPopupDelayStart}
          overlay={
            <Popover>
              <Popover.Body>
                {getSummaryDataMissingMessage(t)[summaryStatus]}
              </Popover.Body>
            </Popover>
          }
        >
          <StatusLabel color={StatusLabel.Colors.yellow} className="ms-1">
            <GVDSIcon Icon={IconAlertTriangleFilled} />
            {t("data-management.shared.alert-preferences.data-gaps.title")}
          </StatusLabel>
        </OverlayTrigger>
      )}
    </>
  );
};

const getDisplayForTotal = (value, valueCalcFn = (v) => v) => {
  if (!Number.isFinite(value)) {
    return "-";
  }
  return valueCalcFn(value);
};

const EnvironmentalPerformanceGroupDisplay = ({
  label,
  isLoading,
  intensityMetric = null,
  setIntensityMetric = null,
  availableIntensityMetrics = null,
  value,
  unit,
  variance,
  isNegativeVarianceGood = true,
  summaryStatus,
}) => {
  let content;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <>
        <Spacer />
        {setIntensityMetric !== null && availableIntensityMetrics !== null && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="widget--environemntal-metrics__box__selector-container"
          >
            <GVDSFormSingleSelect
              isSearchable={false}
              value={intensityMetric}
              options={availableIntensityMetrics}
              onSelect={(newSelect) =>
                setIntensityMetric({
                  value: newSelect?.value || "",
                  label: newSelect?.label || "",
                })
              }
            />
          </div>
        )}
        <div className="widget--environmental-metrics__box__value-container">
          <PopoverOnBigNumberTruncated
            numberValue={value}
            contentClassName="widget--environmental-metrics__box__value-number"
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          />
          <div className="widget--environmental-metrics__box__unit">{unit}</div>
        </div>
        <div className="widget--environmental-metrics__box__status-container">
          <MetricVarianceView
            variance={variance}
            isNegativeVarianceGood={isNegativeVarianceGood}
          />
          <MissingDataWarning summaryStatus={summaryStatus} />
        </div>
      </>
    );
  }

  return (
    <div className="widget--environmental-metrics__box">
      <div className="widget--environmental-metrics__box__label">{label}</div>
      {content}
    </div>
  );
};

const EnvironmentalPerformanceGroupBox = ({
  subtopicName,
  performanceGroupName,
  intensityMetrics = [],
  allowedIntensityMetrics = [
    PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA,
    PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA,
  ],
  unitId,
  areaUnitId,
  start,
  end,
  resourceType,
  resourceId,
  useLocationBased = null,
  onRedirect,
  lastUpdateOn,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const availableIntensityMetrics = intersection(
    intensityMetrics,
    allowedIntensityMetrics
  ).map((m) => ({
    value: m,
    label: PERFORMANCE_REPORT_OPERATIONAL_PER_LABEL[m],
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [selectedIntensityMetric, setSelectedIntensityMetric] = useState(
    availableIntensityMetrics.length > 0 ? availableIntensityMetrics[0] : {}
  );
  const [performanceGroupSummary, setPerformanceGroupSummary] = useState({});

  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (
      !userInventory.isLoadingInventory.get &&
      selectedIntensityMetric.value
    ) {
      setIsLoading(true);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      SummaryService.getPerformanceGroupSummary(
        resourceType,
        resourceId,
        start,
        end,
        subtopicName,
        performanceGroupName,
        unitId,
        areaUnitId,
        useLocationBased,
        selectedIntensityMetric.value,
        abortControllerRef.current.signal
      )
        .then((data) => {
          setPerformanceGroupSummary(data);
        })
        .catch((e) => {
          if (isRequestAborted(e)) {
            return;
          }

          toastContext.addFailToast(
            <span>Failed to load performance group summary.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [selectedIntensityMetric.value, lastUpdateOn]);

  return (
    <div
      className="widget--environmental-metrics__performance_group__container"
      onClick={() =>
        onRedirect(
          getRedirectPath(
            performanceGroupSummary,
            selectedIntensityMetric.value,
            resourceType
          )
        )
      }
    >
      <EnvironmentalPerformanceGroupDisplay
        label={
          useLocationBased === null
            ? performanceGroupName
            : useLocationBased === false && (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover>
                        <Popover.Body>
                          {t(
                            "landing-page.widget-env-metrics.tooltip-market-based"
                          )}
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span>{performanceGroupName}&#42;</span>
                  </OverlayTrigger>
                </>
              )
        }
        isLoading={isLoading}
        intensityMetric={
          selectedIntensityMetric.value ? selectedIntensityMetric : null
        }
        setIntensityMetric={setSelectedIntensityMetric}
        availableIntensityMetrics={availableIntensityMetrics}
        value={getDisplayForTotal(performanceGroupSummary.total?.value)}
        unit={
          <>
            {unitId}
            {[
              PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA,
              PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA,
            ].indexOf(selectedIntensityMetric?.value) >= 0
              ? ` (per ${areaUnitId})`
              : ""}
          </>
        }
        variance={performanceGroupSummary.variance}
        summaryStatus={getSummaryStatus(
          performanceGroupSummary,
          selectedIntensityMetric?.value
        )}
      />
    </div>
  );
};

const WasteDiversionSummaryBox = ({
  performanceGroupName,
  start,
  end,
  resourceType,
  resourceId,
  onRedirect,
  lastUpdateOn,
}) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [wasteDiversionSummary, setWasteDiversionSummary] = useState({});

  const abortControllerRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    SummaryService.getWasteDiversionSummary(
      resourceType,
      resourceId,
      start,
      end,
      abortControllerRef.current.signal
    )
      .then((data) => {
        setWasteDiversionSummary(data);
      })
      .catch((e) => {
        if (isRequestAborted(e)) {
          return;
        }

        toastContext.addFailToast(
          <span>Failed to load waste diversion summary.</span>
        );
      })
      .finally(() => setIsLoading(false));

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [lastUpdateOn]);

  return (
    <div
      className="widget--environmental-metrics__performance_group__container"
      onClick={() =>
        onRedirect(
          getRedirectPath(
            wasteDiversionSummary,
            performanceGroupName,
            resourceType
          )
        )
      }
    >
      <EnvironmentalPerformanceGroupDisplay
        label={performanceGroupName}
        isLoading={isLoading}
        value={getDisplayForTotal(
          wasteDiversionSummary.total?.value,
          (v) => v * 100
        )}
        unit="%"
        variance={wasteDiversionSummary.variance}
        isNegativeVarianceGood={false}
        summaryStatus={getSummaryStatus(wasteDiversionSummary, null)}
      />
    </div>
  );
};

const ManageSiteDetailsBox = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);

  const goToSiteDetails = () => {
    if (
      userInventory.selectedInventory.get.type === RESOURCES.PORTFOLIO ||
      userInventory.selectedInventory.get.type === RESOURCES.SITE
    ) {
      history.push(
        getRedirectURLWithCurrentParam(ADMIN_SITE_DETAILS, location)
      );
    } else if (
      userInventory.selectedInventory.get.type === RESOURCES.FACILITY
    ) {
      history.push(
        getRedirectURLWithCurrentParam(
          getAdminFacilityDetailsPath(userInventory.selectedInventory.get?.id),
          location
        )
      );
    } else {
    }
  };

  return (
    <div className="widget--environmental-metrics__manage-site-details-box">
      <div className="widget--environmental-metrics__manage-site-details-box__icon-container">
        <GVDSIcon Icon={IconBulb} />
      </div>
      <div>
        {t("landing-page.widget-env-metrics.update-site-details.message")}
      </div>
      <Spacer />
      <div>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={goToSiteDetails}
          text={t("landing-page.widget-env-metrics.update-site-details.button")}
        />
      </div>
    </div>
  );
};

const EnvironmentalMetricsWidget = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const locked =
    subscriptionFeatureStatus !== SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const [start, setStart] = useState(defaultStartPeriod);
  const [end, setEnd] = useState(defaultEndPeriod);
  const [lastUpdateOn, setLastUpdateOn] = useState(new Date());

  const [metricsConfig, setMetricsConfig] = useState({
    area_types: [],
    operational_metrics: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const abortControllerRef = useRef(null);

  useEffect(() => {
    loadEnvironmentalConfig();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [
    userInventory.isLoadingInventory.get,
    userInventory.selectedInventory.get,
  ]);

  const loadEnvironmentalConfig = () => {
    const inventory = userInventory.selectedInventory.get;
    setIsLoading(true);

    if (
      !userInventory.isLoadingInventory.get &&
      userInventory.selectedInventory.get &&
      userInventory.selectedInventory.get.id
    ) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      SummaryService.getEnvironmentalMetricsConfig(
        inventory.type,
        inventory.id,
        abortControllerRef.current.signal
      )
        .then((data) => {
          setMetricsConfig(data);
        })
        .catch((e) => {
          if (isRequestAborted(e)) {
            return;
          }

          toastContext.addFailToast(
            <span>Failed to load environmental metrics configuration.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }
  };

  const onRedirect = (redirectPath) => {
    if (
      redirectPath === ENVIRONMENTAL_DATA ||
      redirectPath === OPERATIONAL_DATA ||
      redirectPath === ADMIN_SITE_DETAILS
    ) {
      history.push(getRedirectURLWithCurrentParam(redirectPath, location));
    } else if (redirectPath === ADMIN_FACILITY_DETAILS) {
      history.push(
        getRedirectURLWithCurrentParam(
          getAdminFacilityDetailsPath(userInventory.selectedInventory.get?.id),
          location
        )
      );
    } else {
      const reportParams = {
        resource_id: userInventory.selectedInventory.get?.id,
        resource_type: userInventory.selectedInventory.get?.type,
        start: DateTimeUtils.getUTCISOString(start),
        end: DateTimeUtils.getUTCISOString(end),
        energyUnit: metricsConfig["energy_unit_id"],
        wasteUnit: metricsConfig["waste_unit_id"],
        waterUnit: metricsConfig["water_unit_id"],
        emissionUnit: metricsConfig["emission_unit_id"],
        areaUnit: metricsConfig["area_unit_id"],
        currencyId: metricsConfig["currency_id"],
        postponeDownload: true,
      };
      history.push(
        getURLWithPageParams(location, REPORT_STANDARD_REPORT, reportParams)
      );
    }
  };

  let content;
  if (locked) {
    content = (
      <FeatureLockWidgetContent
        subscriptionFeatureStatus={subscriptionFeatureStatus}
      />
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <div className="widget--environmental-metrics__content">
        <EnvironmentalPerformanceGroupBox
          subtopicName={SUBTOPIC_TYPE.ENERGY}
          performanceGroupName={metricsConfig["energy_type_name"]}
          unitId={metricsConfig["energy_unit_id"]}
          areaUnitId={metricsConfig["area_unit_id"]}
          intensityMetrics={[
            ...metricsConfig["area_types"],
            ...metricsConfig["operational_metrics"],
          ]}
          allowedIntensityMetrics={[
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_OCCUPIED_ROOMS,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA,
          ]}
          start={start}
          end={end}
          resourceType={userInventory.selectedInventory.get?.type}
          resourceId={userInventory.selectedInventory.get?.id}
          onRedirect={onRedirect}
          lastUpdateOn={lastUpdateOn}
        />
        <EnvironmentalPerformanceGroupBox
          subtopicName={SUBTOPIC_TYPE.EMISSIONS}
          performanceGroupName={metricsConfig["emission_type_name"]}
          unitId={metricsConfig["emission_unit_id"]}
          areaUnitId={metricsConfig["area_unit_id"]}
          intensityMetrics={[
            ...metricsConfig["area_types"],
            ...metricsConfig["operational_metrics"],
          ]}
          allowedIntensityMetrics={[
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_OCCUPIED_ROOMS,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA,
          ]}
          start={start}
          end={end}
          resourceType={userInventory.selectedInventory.get?.type}
          resourceId={userInventory.selectedInventory.get?.id}
          useLocationBased={false}
          onRedirect={onRedirect}
          lastUpdateOn={lastUpdateOn}
        />
        <EnvironmentalPerformanceGroupBox
          subtopicName={SUBTOPIC_TYPE.WATER}
          performanceGroupName={metricsConfig["water_type_name"]}
          unitId={metricsConfig["water_unit_id"]}
          areaUnitId={metricsConfig["area_unit_id"]}
          intensityMetrics={[
            ...metricsConfig["operational_metrics"],
            ...metricsConfig["area_types"],
          ]}
          allowedIntensityMetrics={[
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_GUEST_NIGHTS,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_OCCUPIED_ROOMS,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA,
          ]}
          start={start}
          end={end}
          resourceType={userInventory.selectedInventory.get?.type}
          resourceId={userInventory.selectedInventory.get?.id}
          onRedirect={onRedirect}
          lastUpdateOn={lastUpdateOn}
        />
        <EnvironmentalPerformanceGroupBox
          subtopicName={SUBTOPIC_TYPE.WASTE}
          performanceGroupName={metricsConfig["waste_type_name"]}
          unitId={metricsConfig["waste_unit_id"]}
          areaUnitId={metricsConfig["area_unit_id"]}
          intensityMetrics={[
            ...metricsConfig["operational_metrics"],
            ...metricsConfig["area_types"],
          ]}
          allowedIntensityMetrics={[
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_GUEST_NIGHTS,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_OCCUPIED_ROOMS,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA,
            PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA,
          ]}
          start={start}
          end={end}
          resourceType={userInventory.selectedInventory.get?.type}
          resourceId={userInventory.selectedInventory.get?.id}
          onRedirect={onRedirect}
          lastUpdateOn={lastUpdateOn}
        />
        <WasteDiversionSummaryBox
          environmentalClassName="waste"
          icon={faRecycle}
          performanceGroupName={metricsConfig["waste_diversion_rate_name"]}
          start={start}
          end={end}
          resourceType={userInventory.selectedInventory.get?.type}
          resourceId={userInventory.selectedInventory.get?.id}
          onRedirect={onRedirect}
          lastUpdateOn={lastUpdateOn}
        />
        <ManageSiteDetailsBox />
      </div>
    );
  }

  return (
    <div className="widget--environmental-metrics__container">
      <div className="base-widget__heading">
        <div>{t("landing-page.widget-env-metrics.title")}</div>
        <BaseWidgetPeriodControl
          start={start}
          end={end}
          setStart={setStart}
          setEnd={setEnd}
          onSave={() => setLastUpdateOn(new Date())}
        />
      </div>
      <div>{content}</div>
    </div>
  );
};

export default EnvironmentalMetricsWidget;
