import moment from "moment";
import * as XLSX from "xlsx";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHTML from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { isEmpty, isEqual, range } from "lodash";

import {
  CHUNK_DELAY_IN_MS,
  MAIN_PORTFOLIO,
  MAX_CONCURRENT_DOWNLOADS,
  MB_TO_BYTES,
  OLDEST_YEAR_IN_PORTAL,
  RESOURCES,
  SURVEY_STATUS,
} from "../config/constants";
import { getRedirectURLWithCurrentParam } from "../components/common/QueryHandler";
import axios from "axios";
import {
  downloadFromPresignedS3Url,
  MIMETypes,
} from "../components/common/FileAttachments";

const MAX_DIGIT_CUT_IN_HUMANIZE = 12;

export class HttpResponseService {
  static async getBlobAsData(link) {
    const response = await axios.get(link, {
      responseType: "blob", // important
    });

    return response.data;
  }

  static async getBlobResponse(
    link,
    params,
    defaultFileName = "file_download"
  ) {
    // used to download file from API with headers
    const response = await axios.get(link, {
      params: params,
      responseType: "blob", // important
    });
    HttpResponseService.downloadFileFromResponse(response, defaultFileName);
  }

  static downloadFileFromResponse(response, defaultFileName) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const contentDisposition = response.headers["content-disposition"];
    let fileName = defaultFileName;
    if (contentDisposition) {
      fileName = this.extractFilenameFromContentDisposition(
        contentDisposition,
        defaultFileName
      );
    }
    HtmlService.downloadFileFromUrl(url, fileName);
  }

  static extractFilenameFromContentDisposition(
    contentDisposition,
    defaultFileName
  ) {
    // name match from https://stackoverflow.com/a/57589181
    // regex loop required for multiple filename (latin and UTF-8 present)
    // regex loop match taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
    let fileName = defaultFileName;

    const regex = /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/gi;

    let fileNameRegexMatchSearch;
    let bestMatchingFileNameRegexMatch;
    while (
      (fileNameRegexMatchSearch = regex.exec(contentDisposition)) != null
    ) {
      if (!bestMatchingFileNameRegexMatch) {
        bestMatchingFileNameRegexMatch = fileNameRegexMatchSearch;
      } else if (
        fileNameRegexMatchSearch &&
        fileNameRegexMatchSearch.length >= 2 &&
        !!fileNameRegexMatchSearch[1] // has UTF-8 as filename prefix
      ) {
        bestMatchingFileNameRegexMatch = fileNameRegexMatchSearch;
      }
    }

    if (
      bestMatchingFileNameRegexMatch &&
      bestMatchingFileNameRegexMatch.length >= 2
    ) {
      fileName = bestMatchingFileNameRegexMatch[2].replace(
        /^['"](.*)['"]$/,
        "$1"
      );

      const isFilenameStartsWithUTF8 = !!bestMatchingFileNameRegexMatch[1];

      if (isFilenameStartsWithUTF8) {
        fileName = decodeURIComponent(fileName);
      }
    }

    return fileName;
  }
}

export class HtmlService {
  static downloadFileFromUrl(url, fileName) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName?.trim());
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }
}

export class ExcelService {
  static isExcel(file) {
    return file?.type === MIMETypes.XLSX || file?.name?.endsWith(".xlsx");
  }

  static getRows(file, sheetName, startingRow, onRowsRead, onError) {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const fileContent = event.target.result;
        const workBook = XLSX.read(fileContent, { type: "binary" });

        let sheet = workBook.Sheets[sheetName];
        if (ObjectUtils.isEmpty(sheet)) {
          const firstSheetName = workBook.SheetNames[0];
          sheet = workBook.Sheets[firstSheetName];
        }

        const cellRange = XLSX.utils.decode_range(sheet["!ref"]);
        onRowsRead(
          range(startingRow - 1, cellRange.e.r + 1).map((rowNum) =>
            range(cellRange.s.c, cellRange.e.c + 1).map(
              (colNum) =>
                sheet[
                  XLSX.utils.encode_cell({
                    r: rowNum,
                    c: colNum,
                  })
                ]?.w || null
            )
          )
        );
      } catch (e) {
        onError();
      }
    };
    reader.readAsBinaryString(file);
  }
}

export class UtilsService {
  static toggleItem = (items, item) => {
    const newItems = [...items];
    const index = items.findIndex((element) => {
      return isEqual(element, item);
    });
    if (index >= 0) {
      newItems.splice(index, 1);
    } else {
      newItems.push(item);
    }
    return newItems;
  };

  static removeItems = (allItems, itemsToRemove) => {
    const newItems = [...allItems];

    itemsToRemove.forEach((item) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });

    return newItems;
  };

  static getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  static convertObjectNamesToObjectIds = (objectNames, objArray) => {
    return objectNames.reduce((acc, name) => {
      const obj = objArray.find((data) => data.name === name);
      if (obj) {
        acc.push(obj.id);
      }
      return acc;
    }, []);
  };

  static addIfUniqueToArray = (outputArray, element, uniqFn = (e) => e.id) => {
    const isIdNotInArray =
      outputArray.findIndex((obj) => uniqFn(obj) === element.id) === -1;

    if (isIdNotInArray) {
      outputArray.push(element);
    }
  };
}

export class SortUtils {
  static sortStringWithOthersLast = (string1, string2) => {
    if (
      string1.toLowerCase() === "other" ||
      string1.toLowerCase() === "others"
    ) {
      return 1;
    } else if (
      string2.toLowerCase() === "other" ||
      string2.toLowerCase() === "others"
    ) {
      return -1;
    } else {
      return string1.localeCompare(string2);
    }
  };
}

export class DateTimeUtils {
  static formatLocalDate(localDate) {
    return moment(localDate).format("DD MMM YYYY");
  }

  static formatLocalDateWithTime(localDate) {
    return moment(localDate).format("DD MMM YYYY HH:mm:ss");
  }

  static formatLocalMonthYear(localDate) {
    return moment(localDate).format("MMM YYYY");
  }

  static formatLocalPeriod(start, end) {
    return (
      DateTimeUtils.formatLocalDate(start) +
      " - " +
      DateTimeUtils.formatLocalDate(end)
    );
  }

  static formatUTCDate(utcDate) {
    return moment.utc(utcDate).format("DD MMM YYYY");
  }

  static formatUTCMonthYear(utcDate) {
    return moment.utc(utcDate).format("MMM YYYY");
  }

  static formatISODateWithFriendlyHourMinute(isoDate) {
    return moment(isoDate).format("DD MMM YYYY hh:mm a");
  }

  static formatUTCPeriod(start, end) {
    return (
      moment.utc(start).format("MMM YYYY") +
      " - " +
      moment.utc(end).format("MMM YYYY")
    );
  }

  /* To Serialize */
  static getUTCISOString(date) {
    const momentDate = moment(date);
    if (!date || !momentDate.isValid()) {
      return date;
    }

    const year = momentDate.year();
    const month = momentDate.month() + 1;
    const day = momentDate.date();

    return moment
      .utc(`${year}-${month}-${day}`, "YYYY-MM-DD")
      .toDate()
      .toISOString();
  }

  /* To Deserialize */
  static getDateFromUTCISOStringIgnoreTimezone(isoString) {
    if (isoString === null) {
      return null;
    }

    const momentDate = moment.utc(isoString);

    const year = momentDate.year();
    const month = momentDate.month() + 1;
    const day = momentDate.date();

    const hour = momentDate.hour();
    const minute = momentDate.minute();
    const second = momentDate.second();

    return moment(
      `${year}-${month}-${day} ${hour}:${minute}:${second}`,
      "YYYY-MM-DD H:m:s"
    ).toDate();
  }

  static formatEndOfMonth(month) {
    return DateTimeUtils.formatLocalDate(
      moment(new Date(moment().year(), month, 1)).endOf("month")
    );
  }

  static getUTCToday() {
    return DateTimeUtils.getUTCISOString(moment());
  }

  static getRemainingDaysBefore(deadline) {
    return moment(deadline)
      .add(1, "day")
      .startOf("day")
      .diff(moment().startOf("day"), "days");
  }

  static getDeadlineDueMessage(deadline) {
    const remainingDays = DateTimeUtils.getRemainingDaysBefore(deadline);
    return remainingDays > 0
      ? `due in ${remainingDays} ${remainingDays > 1 ? "days" : "day"}`
      : "expired";
  }

  static getSurveyDeadlineDueMessage(deadline, surveyStatus) {
    const dueMessage = DateTimeUtils.getDeadlineDueMessage(deadline);
    if (dueMessage === "expired" && surveyStatus === SURVEY_STATUS.OPEN) {
      return "due soon";
    } else {
      return dueMessage;
    }
  }

  static getTimestamp() {
    return moment().format("DDMMYYYYHHmm");
  }

  static getDurationBetween(start, end) {
    const duration = moment.duration(end.diff(start)).add(2, "days");
    const remainingYears = duration.years();
    const remainingMonths = duration.months();
    let durationText = "";
    if (remainingYears > 0) {
      durationText += `${remainingYears} ${
        remainingYears > 1 ? "years" : "year"
      } `;
    }
    if (remainingMonths > 0) {
      durationText += `${remainingMonths} ${
        remainingMonths > 1 ? "months" : "month"
      }`;
    }
    return durationText;
  }

  static getArrayOfYearsInPortal() {
    const currentYear = new Date().getUTCFullYear();
    return Array(currentYear - OLDEST_YEAR_IN_PORTAL + 1)
      .fill("")
      .map((v, idx) => currentYear - idx);
  }

  static getTodayWithTimeInZero() {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    return today;
  }

  static isDateEqual(date1, date2) {
    if (
      date1 !== null &&
      date1 !== undefined &&
      date2 !== null &&
      date2 !== undefined
    ) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    } else {
      return date1 === date2;
    }
  }

  static isDateRangeOverlap(startDate1, endDate1, startDate2, endDate2) {
    if (
      startDate1 === null ||
      startDate1 === undefined ||
      endDate1 === null ||
      endDate1 === undefined ||
      startDate2 === null ||
      startDate2 === undefined ||
      endDate2 === null ||
      endDate2 === undefined
    ) {
      return false;
    }

    const isDate1BeforeDate2 = endDate1 <= startDate2;
    const isDate1AfterDate2 = startDate1 >= endDate2;
    return !(isDate1BeforeDate2 || isDate1AfterDate2);
  }

  static getDateDifference(startDate, endDate) {
    return moment(endDate).diff(moment(startDate), "days") + 1;
  }
}

export class NumberService {
  static format(num, maximumFractionDigits = 2, minimumFractionDigits = 0) {
    if (!NumberService.isNumber(num)) {
      return num;
    }
    return num.toLocaleString(undefined, {
      maximumFractionDigits: maximumFractionDigits,
      minimumFractionDigits: minimumFractionDigits,
    });
  }

  static isNumber(num) {
    return typeof num === "number";
  }

  static parse(numStr) {
    try {
      return parseFloat(numStr.replace(/,/g, ""));
    } catch (e) {
      return numStr;
    }
  }

  static toPrecision(num, precision = 2) {
    if (!Number.isFinite(num)) {
      return num;
    }
    return parseFloat(num.toFixed(precision));
  }

  static humanize(num) {
    const absoluteNumber = Math.abs(num);
    const numberSign = num >= 0 ? 1 : -1;
    let result = "";

    const suffixByNoOfDigits = {
      3: "k",
      6: "M",
      9: "B",
      12: "T",
    };

    if (absoluteNumber < 1000) {
      result = `${NumberService.toPrecision(absoluteNumber) * numberSign}`;
    } else {
      const digitCount = Math.log10(absoluteNumber);
      const thousandsCount = Math.floor(digitCount / 3);
      const digitCutCount = Math.min(
        thousandsCount * 3,
        MAX_DIGIT_CUT_IN_HUMANIZE
      );

      const multiplierDivisor = Math.pow(10, digitCutCount);
      const numberInMultiplierAsBase = absoluteNumber / multiplierDivisor;
      const roundedNumber = NumberService.toPrecision(numberInMultiplierAsBase);

      const suffix = suffixByNoOfDigits[digitCutCount];

      if (digitCutCount === MAX_DIGIT_CUT_IN_HUMANIZE) {
        const [integerPart, decimalPart] = roundedNumber.toString().split(".");
        // Ref https://stackoverflow.com/a/62256954
        const formattedInteger = integerPart.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
        const decimalString =
          decimalPart === undefined || decimalPart === null
            ? ""
            : "." + decimalPart;
        const signString = numberSign === 1 ? "" : "-";

        result = `${signString}${formattedInteger}${decimalString}${suffix}`;
      } else {
        result = `${numberSign * roundedNumber}${suffix}`;
      }
    }

    return result;
  }
}

export class StringUtils {
  static isNotEmpty(value) {
    return !!value && value.length > 0;
  }

  static isEmail(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      StringUtils.isNotEmpty(value) && re.test(String(value).toLowerCase())
    );
  }

  static truncate(value, length) {
    return value.length === length ? value : value.slice(0, length);
  }

  static calculateAvailableCharacterLeft(
    inputText,
    maxLength,
    bufferBeforeDisplay
  ) {
    if (inputText.length > maxLength - bufferBeforeDisplay) {
      const characterLeft = maxLength - inputText.length;
      return characterLeft
        .toString()
        .concat(" character", characterLeft > 1 ? "s" : "", " left");
    } else {
      return null;
    }
  }

  static capitaliseWord(word) {
    if (!word) {
      return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  static getTitleCase(sentence) {
    return sentence
      .toLowerCase()
      .split(" ")
      .map((word) => this.capitaliseWord(word))
      .join(" ");
  }

  static getTitleCaseIgnoreUpperCase(sentence) {
    return sentence
      .split(" ")
      .map((word) => this.capitaliseWord(word))
      .join(" ");
  }

  static getCopyNameFromCurrentNameAndExistingNames(
    currentName,
    existingNames
  ) {
    const copyPrefix = "Copy of ";
    const copyAppendixRegex = /Copy of (.*) \(([\d\s]*)\)/i;
    const duplicateNumber = 1;

    let newCopyName = copyPrefix + currentName;

    const existingDuplicates = existingNames.filter((existingName) => {
      return existingName.startsWith(newCopyName);
    });

    if (existingDuplicates.length > 0) {
      const setCopyIterationAppendix = (name) => {
        const [matchedName, originalName, numberAppendix] =
          name.match(copyAppendixRegex) || [];
        if (matchedName) {
          newCopyName = `Copy of ${originalName} (${
            parseInt(numberAppendix) + 1
          })`;
        } else {
          newCopyName = `${name} (${duplicateNumber})`;
        }
      };

      const getNumberAppendix = (name) => {
        const [matchedName, originalName, numberAppendix] =
          name.match(copyAppendixRegex) || [];
        if (matchedName) {
          return parseInt(numberAppendix);
        } else {
          return null;
        }
      };

      const latestCopy = existingDuplicates
        .sort(function (a, b) {
          const numberAppendix = getNumberAppendix(a);
          const nextNumberAppendix = getNumberAppendix(b);
          if (numberAppendix && nextNumberAppendix) {
            return a - b;
          } else if (numberAppendix && !nextNumberAppendix) {
            return 1;
          } else if (!numberAppendix && nextNumberAppendix) {
            return -1;
          } else {
            return a.localeCompare(b);
          }
        })
        .slice(-1)[0];
      setCopyIterationAppendix(latestCopy);
    }

    return newCopyName;
  }
}

export class OrderedListNumberingService {
  static getLetterFromIndex(index) {
    const asciiIndexOfLowerA = 97;
    return String.fromCharCode(asciiIndexOfLowerA + index);
  }
}

export class PortfolioUtils {
  static portfolioSortCompare(a, b) {
    if (a.name === MAIN_PORTFOLIO) {
      return -1;
    } else if (b.name === MAIN_PORTFOLIO) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  }
}

export class RTFUtils {
  static convertEditorStateToHTML(editorState) {
    // TODO known issue of image align center not working
    // - default center align will not do anything
    // - if alignment center is set, div will have text-align: none instead of center
    // - possible fix: https://github.com/jpuri/react-draft-wysiwyg/issues/696#issuecomment-479638892
    // TODO known issue ol and li are not nested properly
    return draftToHTML(convertToRaw(editorState.getCurrentContent()));
  }

  static convertHTMLToEditorState(htmlString) {
    const blocksFromHtml = htmlToDraft(htmlString);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  }

  static isEmpty(editorState) {
    const contentState = editorState.getCurrentContent();

    const rawState = convertToRaw(contentState);
    if (!rawState || isEmpty(rawState)) {
      // filter undefined and {}
      return true;
    }

    return !(contentState.hasText() && contentState.getPlainText() !== "");
  }
}

export class URLUtils {
  static replacePathWithoutRerendering(newPath, location) {
    window.history.replaceState(
      null,
      "",
      getRedirectURLWithCurrentParam(newPath, location)
    );
  }

  static addHttpsPrefix(url) {
    if (url && !url.match(/^https?:\/\//i)) {
      return `https://${url}`;
    }
    return url;
  }

  static addMailToPrefix(email) {
    if (email && !email.startsWith("mailto:")) {
      return `mailto:${email}`;
    }
    return email;
  }

  static isValidUrl(url) {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }
}

export class FileUtils {
  static getFilesWhichSizeLargerThan(fileAttachmentItemModels, sizeInMB) {
    const sizeInBytes = sizeInMB * MB_TO_BYTES;

    return fileAttachmentItemModels.filter(
      (fileAttachmentItemModel) =>
        fileAttachmentItemModel.getSize() > sizeInBytes
    );
  }

  static isFileSizeLargerThan(file, sizeInMB) {
    const sizeInBytes = sizeInMB * MB_TO_BYTES;
    return file.size > sizeInBytes;
  }

  static chunkDownloadDelayInMs(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static async downloadFiles(resource_type, resource_id, fileLinks) {
    const downloadChunks = fileLinks.reduce((chunks, link, index) => {
      const chunkIndex = Math.floor(index / MAX_CONCURRENT_DOWNLOADS);
      if (!chunks[chunkIndex]) {
        chunks[chunkIndex] = [];
      }
      chunks[chunkIndex].push(link);
      return chunks;
    }, []);

    for (const chunk of downloadChunks) {
      await Promise.all(
        chunk.map((link) =>
          downloadFromPresignedS3Url(link, { resource_type, resource_id })
        )
      );
      await FileUtils.chunkDownloadDelayInMs(CHUNK_DELAY_IN_MS);
    }
  }
}

export class UserUtils {
  static getFirstTwoInitial(fullName) {
    if (fullName === null || fullName.length === 0) {
      return "";
    }
    const names = fullName.trim().split(/\s+/);
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[1].substring(0, 1).toUpperCase();
    } else if (names[0].length > 1) {
      initials += names[0][1].toUpperCase();
    }
    return initials;
  }

  static getFullNameOrEmail = (fullName, email) => {
    return fullName ? fullName : email;
  };

  static getUserAttribute(userEmail, userName) {
    const fullName = this.getFullNameOrEmail(userName, userEmail);
    const initial = this.getFirstTwoInitial(fullName);
    let hash = 0;
    let chr;
    if (userEmail.length > 0) {
      for (let index = 0; index < userEmail.length; index++) {
        chr = userEmail.charCodeAt(index);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
      }
    }

    return { initial: initial, hash: hash };
  }

  static getAvatarColor = (hash) => {
    return `accent${Math.abs(hash) % 6}`;
  };
}

export class DomUtils {
  // taken from React Joyride - https://github.com/gilbarbara/react-joyride/blob/main/src/modules/dom.js

  static getElement = (element) => {
    if (typeof element === "string") {
      return document.querySelector(element);
    }

    return element;
  };

  static isElementVisible = (element) => {
    if (!element) return false;

    let parentElement = element;

    while (parentElement) {
      if (parentElement === document.body) break;

      if (parentElement instanceof HTMLElement) {
        const { display, visibility } = getComputedStyle(parentElement);

        if (display === "none" || visibility === "hidden") {
          return false;
        }
      }

      parentElement = parentElement.parentNode;
    }
    return true;
  };

  static isElementRendered = (target) => {
    const element = DomUtils.getElement(target);
    const elementExists = !!element;
    return elementExists && DomUtils.isElementVisible(element);
  };
}

export class InventoryUtils {
  static isNotIndividualSiteSubscription = (userInventory) => {
    const selectedInventory = userInventory.selectedInventory.get;

    const isSite =
      userInventory.selectedInventory.get &&
      selectedInventory.type === RESOURCES.SITE;

    const isNotIndividualSiteSubscription =
      !isSite ||
      (userInventory.selectedTreeNode.get &&
        userInventory.selectedTreeNode.get.nodeValue.value &&
        userInventory.selectedTreeNode.get.nodeValue.value.is_part_of_contract);

    return isNotIndividualSiteSubscription;
  };
}

export class GuideContentTypeUtils {
  static isPdf(file) {
    return file?.name?.toLowerCase().endsWith(".pdf");
  }

  static isVideo(file) {
    return file?.name?.toLowerCase().endsWith(".mp4");
  }
}

export class MailToUtils {
  static sendEmailWithSubject(emailAddress, subject) {
    return `mailto:${emailAddress}?subject=${subject}`;
  }
}

export class TextareaUtils {
  static dynamicallyResizeTextarea(ref, target) {
    if (ref.current) {
      ref.current.style.height = "30px";
      ref.current.style.height = `${target.scrollHeight}px`;
    }
  }

  static setTextAreaHeight(ref, heightInPx) {
    if (ref.current) {
      ref.current.style.height = `${heightInPx}px`;
    }
  }
}

export class ObjectUtils {
  static isEmpty(object) {
    return object === null || object === undefined;
  }
}
