import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import React from "react";

const CopyHomepageAnnouncementPrompt = ({ show, closeModal, onCopy }) => {
  return (
    <GVDSModal
      title="Copy Announcement"
      size={GVDSModal.Size.small}
      show={show}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        The copied announcement will have all the same details as the original
        announcement. Remember to change the title, description, start and end
        date accordingly. To proceed, click on the <strong>Copy</strong> button
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={closeModal}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          className="copy-initiative-modal__copy-button"
          onClick={() => {
            closeModal();
            onCopy();
          }}
          text="Copy"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default CopyHomepageAnnouncementPrompt;
