import React, { useCallback, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import {
  BEST_PRACTICES_GUIDANCE_ARTICLE,
  DASHBOARDS,
  ENVIRONMENTAL_DATA_BULK_INPUT,
  ENVIRONMENTAL_DATA_METER,
  ENVIRONMENTAL_DATA_SETUP,
  HELP,
  MARKET_PURCHASE_OFFSETS_TRACKER,
  OPERATIONAL_DATA_BULK_INPUT,
  OPERATIONAL_DATA_METER,
  OPERATIONAL_DATA_SETUP,
  OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT,
  OVERVIEW_DATA_OPERATIONAL_BULK_INPUT,
  PEOPLE_DATA_METER,
  PEOPLE_DATA_SETUP,
  SURVEYS_CREATE,
  SYSTEM_TOOLBOX,
  USER_PROFILE,
  VIEW_SURVEY,
  VIEW_TARGET_DETAILS,
} from "../../config/ROUTES_NAME";

const NavWrapperContext = React.createContext();

const minimiseSideNavBreakpoint = 1000;

export const PageLayoutTemplateState = {
  default: "default",
  local: "local",
  fullspace: "fullspace",
};

const hideTopNavRoutes = [HELP, USER_PROFILE, SYSTEM_TOOLBOX, SURVEYS_CREATE];

const localPageNavRoutes = [
  HELP,
  USER_PROFILE,

  OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT,
  OVERVIEW_DATA_OPERATIONAL_BULK_INPUT,

  ENVIRONMENTAL_DATA_SETUP,
  ENVIRONMENTAL_DATA_METER,
  MARKET_PURCHASE_OFFSETS_TRACKER,
  ENVIRONMENTAL_DATA_BULK_INPUT,

  OPERATIONAL_DATA_SETUP,
  OPERATIONAL_DATA_METER,
  OPERATIONAL_DATA_BULK_INPUT,

  PEOPLE_DATA_SETUP,
  PEOPLE_DATA_METER,

  VIEW_TARGET_DETAILS,

  BEST_PRACTICES_GUIDANCE_ARTICLE,

  SURVEYS_CREATE,
  VIEW_SURVEY,
];

const fullspacePageNavRoutes = [DASHBOARDS];

export const NavWrapperProvider = ({ children }) => {
  const location = useLocation();

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMinimisedOnLargeScreen, setIsMinimisedOnLargeScreen] =
    useState(false);
  const [isMaximisedOnSmallScreen, setIsMaximisedOnSmallScreen] =
    useState(false);

  const onResize = () => {
    if (typeof window !== "undefined") {
      const width = window.innerWidth;

      if (width <= minimiseSideNavBreakpoint) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const isLargeScreen = !isSmallScreen;

  const pinNavWrapper = () => {
    if (isLargeScreen) {
      setIsMinimisedOnLargeScreen(false);
    } else {
      setIsMaximisedOnSmallScreen(true);
    }
  };

  const minimiseNavWrapper = () => {
    if (isLargeScreen) {
      setIsMinimisedOnLargeScreen(true);
    } else {
      setIsMaximisedOnSmallScreen(false);
    }
  };

  let isSideNavMinimised;
  let isSideNavControlHideNotPin;

  if (isLargeScreen) {
    isSideNavMinimised = isMinimisedOnLargeScreen;
    isSideNavControlHideNotPin = !isMinimisedOnLargeScreen;
  } else {
    isSideNavMinimised = !isMaximisedOnSmallScreen;
    isSideNavControlHideNotPin = isMaximisedOnSmallScreen;
  }

  const isSystemToolbox = matchPath(location.pathname, {
    path: SYSTEM_TOOLBOX,
    exact: false,
  });

  const hideTopNav = hideTopNavRoutes.find((r) => {
    return matchPath(location.pathname, {
      path: r,
      exact: false,
    });
  });

  const pageLayoutTemplate = localPageNavRoutes.find((r) => {
    return matchPath(location.pathname, {
      path: r,
      exact: false,
    });
  })
    ? PageLayoutTemplateState.local
    : fullspacePageNavRoutes.find((r) => {
        return matchPath(location.pathname, {
          path: r,
          exact: false,
        });
      })
    ? PageLayoutTemplateState.fullspace
    : PageLayoutTemplateState.default;

  const store = useCallback(
    {
      isSideNavMinimised: isSideNavMinimised,
      isSideNavControlHideNotPin: isSideNavControlHideNotPin,
      pinSideNav: pinNavWrapper,
      unpinSideNav: minimiseNavWrapper,
      isSystemToolbox: isSystemToolbox,
      hideTopNav: hideTopNav,
      pageLayoutTemplate: pageLayoutTemplate,
    },
    [
      isSmallScreen,
      isMinimisedOnLargeScreen,
      isMaximisedOnSmallScreen,
      isSystemToolbox,
      hideTopNav,
      pageLayoutTemplate,
    ]
  );

  return (
    <NavWrapperContext.Provider value={store}>
      {children}
    </NavWrapperContext.Provider>
  );
};

export default NavWrapperContext;
