import React from "react";
import { BEST_PRACTICE_ADOPTION_LEVEL } from "../../../config/constants";
import { GVDSColors } from "../../../styles/gvds-colors";

export const BEST_PRACTICE_ADOPTION_LEVEL_INFO = {
  [BEST_PRACTICE_ADOPTION_LEVEL.YES]: {
    icon: <span className="unicode-icon color-green">&#x2713;</span>,
    definition: "Site is adopting this best practice.",
  },
  [BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS]: {
    icon: <span className="unicode-icon color-dark-yellow">&#x25D0;</span>,
    definition: "Site has begun work to adopt this best practice.",
  },
  [BEST_PRACTICE_ADOPTION_LEVEL.NO]: {
    icon: <span className="unicode-icon color-red">&#x2717;</span>,
    definition: "Site is not adopting this best practice.",
  },
  [BEST_PRACTICE_ADOPTION_LEVEL.NA]: {
    icon: (
      <span
        className="unicode-icon color-gray56"
        style={{
          fontSize: "12px",
          fontWeight: "900",
          backgroundColor: GVDSColors.gray3,
        }}
      >
        N/A
      </span>
    ),
    definition: "Not applicable to site.",
  },
};
