import React from "react";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCheck } from "@tabler/icons-react";

// Reference https://ishadeed.com/article/stepper-component-html-css/

const HorizontalStepper = ({
  currentStepId,
  steps,
  isStepsClickable = false,
  onStepClick,
}) => {
  const currentStepIndex = steps.findIndex((s) => {
    return s.id === currentStepId;
  });

  const handleStepClick = (step) => {
    if (isStepsClickable && step.id !== currentStepId) {
      onStepClick(step.id);
    }
  };

  const content = steps.map((step, index) => {
    if (index < currentStepIndex) {
      return (
        <li
          key={index}
          className="horizontal-stepper__item"
          onClick={() => handleStepClick(step)}
        >
          <div
            className={
              "horizontal-stepper__completed-step" +
              (isStepsClickable ? " horizontal-stepper__item_with-pointer" : "")
            }
          >
              <GVDSIcon Icon={IconCheck} />
          </div>
          <div className="horizontal-stepper__description caption-bold">
            {step.description}
          </div>
        </li>
      );
    } else {
      return (
        <li
          key={index}
          className={
            step.id === currentStepId
              ? "horizontal-stepper__item"
              : "horizontal-stepper__disabled-item"
          }
          onClick={() => handleStepClick(step)}
        >
          <div
            className={
              (step.id === currentStepId
                ? "horizontal-stepper__current-step"
                : "horizontal-stepper__inactive-step") +
              (isStepsClickable ? " horizontal-stepper__item_with-pointer" : "")
            }
          >
            <div>{step.title}</div>
          </div>
          <div className="horizontal-stepper__description caption-bold mt-1">
            {step.description}
          </div>
        </li>
      );
    }
  });

  return <ol className="horizontal-stepper__container">{content}</ol>;
};

export default HorizontalStepper;
