import InlineSpinner from "./InlineSpinner";
import React from "react";

const DropdownDownloadingSpinner = ({className}) => {
  return (
    <div className={className}>
      <InlineSpinner width="36px" />
    </div>
  );
};

export default DropdownDownloadingSpinner;
