import React, { useEffect, useState } from "react";
import VerticalStackedBarChart from "./VerticalStackedBarChart";
import {
  performanceTimelineChartConfig,
  performanceTimelineChartMarginInPx,
  performanceTimelineChartMinimapMarginInPx,
} from "./PerformanceTimelineViewConfig";
import { DashboardColorPalette } from "../DashboardColorPalette";
import { PerformanceTimelineChartUtils } from "./PerformanceTimelineChartUtils";
import DashboardStackedBarLegend from "../DashboardStackedBarLegend";
import { maximumChartLegendItemCount } from "../../Models/DashboardModels";
import VerticalStackedBarChartMinimap from "./VerticalStackedBarChartMinimap";
import { GVDSColors } from "../../../../styles/gvds-colors";
import { ParentSize } from "@visx/responsive";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";

const PerformanceTimelineChartViewContent = ({
  chartData,
  chartKeys,
  intensityUnit,
  unit,
  selectedTimePeriod,
  minimapPosition,
  onMinimapPositionChange,
}) => {
  const [filteredData, setFilteredData] = useState([]);

  let displayedChartData;
  let displayedLegendItems;
  if (chartKeys.length > maximumChartLegendItemCount) {
    displayedLegendItems =
      DashboardWidgetSharedUtils.getMaximumDisplayedLegendItems(chartKeys);
    displayedChartData = PerformanceTimelineChartUtils.getCalculatedChartData(
      chartData,
      displayedLegendItems
    );
  } else {
    displayedChartData = chartData;
    displayedLegendItems = chartKeys;
  }

  const barChartData = displayedChartData.map((datum) =>
    datum.toBarChartData()
  );
  const chartColors = DashboardColorPalette.slice(
    0,
    displayedLegendItems.length
  );
  const yAxisScaleMaxValue =
    PerformanceTimelineChartUtils.getYAxisScaleMaxValue(displayedChartData);

  useEffect(() => {
    if (minimapPosition !== null) {
      setFilteredData(
        barChartData.slice(
          Math.round(Math.max(0, minimapPosition.startXDomainValue)),
          Math.round(minimapPosition.endXDomainValue) + 1
        )
      );
    }
  }, [selectedTimePeriod, chartData]);

  return (
    <ParentSize className="chart-minimap-container">
      {(parent) =>
        parent.width > 0 ? (
          <>
            <div className="chart-minimap-container">
              <VerticalStackedBarChart
                width={parent.width}
                height={performanceTimelineChartConfig.minimapHeightInPx}
                margin={performanceTimelineChartMinimapMarginInPx}
                chartData={barChartData}
                chartKeys={displayedLegendItems}
                chartColors={[GVDSColors.gray6]}
                yAxisScaleMaxValue={yAxisScaleMaxValue}
                intensityUnit={intensityUnit}
                unit={unit}
                selectedPeriod={selectedTimePeriod}
                isCompact={true}
              >
                <VerticalStackedBarChartMinimap
                  width={parent.width}
                  height={performanceTimelineChartConfig.minimapHeightInPx}
                  chartData={barChartData}
                  yAxisScaleMaxValue={yAxisScaleMaxValue}
                  setFilteredData={setFilteredData}
                  periodType={selectedTimePeriod}
                  minimapPosition={minimapPosition}
                  onMinimapPositionChange={onMinimapPositionChange}
                />
              </VerticalStackedBarChart>
            </div>
            <div className="chart-legend-container">
              <div className="chart-container">
                <VerticalStackedBarChart
                  width={
                    parent.width -
                    performanceTimelineChartConfig.legendContainerWidthInPx
                  }
                  height={performanceTimelineChartConfig.chartHeightInPx}
                  margin={performanceTimelineChartMarginInPx}
                  chartData={filteredData}
                  chartKeys={displayedLegendItems}
                  chartColors={chartColors}
                  yAxisScaleMaxValue={yAxisScaleMaxValue}
                  intensityUnit={intensityUnit}
                  unit={unit}
                  selectedPeriod={selectedTimePeriod}
                />
              </div>
              <div className="legend-container mt-0">
                <DashboardStackedBarLegend
                  legendItems={displayedLegendItems}
                  chartColors={chartColors}
                />
              </div>
            </div>
          </>
        ) : null
      }
    </ParentSize>
  );
};

export default PerformanceTimelineChartViewContent;
