import axios from "axios";
import { API_URL } from "../config/api-config";
import sortBy from "lodash/sortBy";
import { FileAttachmentItemModel } from "../components/common/FileAttachments";
import { FileUtils, HttpResponseService } from "./UtilsService";
import { NoContentError } from "../errors/NoContentError";
import { CertificationUtil } from "../components/Certifications/CertificationUtil";

class CertificationFileModel {
  constructor(id, filename, downloadLink) {
    this.id = id;
    this.filename = filename;
    this.downloadLink = downloadLink;
  }

  static fromDTO(dto) {
    return new CertificationFileModel(
      dto.file.id,
      dto.file.filename,
      `${API_URL}${dto.file.link}`
    );
  }
}

class CertificationRequirementEvidenceModel {
  constructor(id, file, createdBy, createdOn) {
    this.id = id;
    this.file = file;
    this.createdBy = createdBy;
    this.createdOn = createdOn;
  }

  static fromDTO(dto) {
    return new CertificationRequirementEvidenceModel(
      dto.id,
      CertificationFileModel.fromDTO(dto),
      dto.created_by,
      dto.created_on
    );
  }
}

class CertificationHomepageCriterionModel {
  constructor(
    id,
    number,
    name,
    order,
    description,
    instruction,
    requirements,
    completedRequirementCount,
    totalApplicableRequirementCount,
    categoryName
  ) {
    this.id = id;
    this.number = number;
    this.name = name;
    this.order = order;
    this.description = description;
    this.instruction = instruction;
    this.requirements = requirements;
    this.completedRequirementCount = completedRequirementCount;
    this.totalApplicableRequirementCount = totalApplicableRequirementCount;
    this.categoryName = categoryName;
  }

  static fromDTO(dto, categoryName) {
    return new CertificationHomepageCriterionModel(
      dto.id,
      dto.number,
      dto.name,
      dto.order,
      dto.description,
      dto.instruction,
      [],
      dto.completed_requirement_count,
      dto.total_applicable_requirement_count,
      categoryName
    );
  }

  isAllRequirementsCompleted() {
    return (
      this.completedRequirementCount === this.totalApplicableRequirementCount
    );
  }
}

class CertificationHomepageCategoryModel {
  constructor(id, name, order, criteria) {
    this.id = id;
    this.name = name;
    this.order = order;
    this.criteria = criteria;
  }

  static fromDTO(dto) {
    return new CertificationHomepageCategoryModel(
      dto.id,
      dto.name,
      dto.order,
      dto.criteria.map((criterion) =>
        CertificationHomepageCriterionModel.fromDTO(criterion, dto.name)
      )
    );
  }
}

class CertificationHomepageModel {
  constructor(
    id,
    name,
    version,
    description,
    verificationDetails,
    logoFileUrl,
    categories
  ) {
    this.id = id;
    this.name = name;
    this.version = version;
    this.description = description;
    this.verificationDetails = verificationDetails;
    this.logoFileUrl = logoFileUrl;
    this.categories = categories;
  }

  static fromDTO(dto) {
    const categories = dto.categories.map((category) =>
      CertificationHomepageCategoryModel.fromDTO(category)
    );

    let orderNumber = 1;
    categories.forEach((category) => {
      category.criteria.forEach(
        (criterion) => (criterion.order = orderNumber++)
      );
    });

    return new CertificationHomepageModel(
      dto.id,
      dto.name,
      dto.version,
      dto.description,
      dto.verification_details,
      dto.logo_file_url,
      categories
    );
  }

  isCriterionLoaded(criterionId) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        return criterion.requirements.length > 0;
      }
    }
  }

  updateCriterionWithRequirements(criterionId, requirements) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        criterion.requirements = requirements;
        return;
      }
    }
  }

  addEvidenceFilesToRequirement(criterionId, requirementId, evidenceFiles) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        const requirement = criterion.requirements.find(
          (r) => r.id === requirementId
        );

        if (requirement) {
          requirement.evidence.unshift(...evidenceFiles);
          return;
        }
      }
    }
  }

  removeEvidenceFileFromRequirement(criterionId, requirementId, evidenceId) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        const requirement = criterion.requirements.find(
          (r) => r.id === requirementId
        );

        if (requirement) {
          requirement.evidence = requirement.evidence.filter(
            (e) => e.id !== evidenceId
          );
          return;
        }
      }
    }
  }

  addCommentToRequirement(criterionId, requirementId, commentModel) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        const requirement = criterion.requirements.find(
          (r) => r.id === requirementId
        );

        if (requirement) {
          requirement.comments.push(commentModel);
          return;
        }
      }
    }
  }

  updateCommentOfRequirement(
    criterionId,
    requirementId,
    commentId,
    commentModel
  ) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        const requirement = criterion.requirements.find(
          (r) => r.id === requirementId
        );

        if (requirement) {
          const commentIndex = requirement.comments.findIndex(
            (c) => c.id === commentId
          );

          if (commentIndex >= 0) {
            requirement.comments[commentIndex] = commentModel;

            return;
          }
        }
      }
    }
  }

  removeCommentOfRequirement(criterionId, requirementId, commentId) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        const requirement = criterion.requirements.find(
          (r) => r.id === requirementId
        );

        if (requirement) {
          const commentIndex = requirement.comments.findIndex(
            (c) => c.id === commentId
          );

          if (commentIndex >= 0) {
            requirement.comments.splice(commentIndex, 1);

            return;
          }
        }
      }
    }
  }

  getSelectedCriterion(criterionId) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        return criterion;
      }
    }
    return null;
  }

  getSelectedRequirement(requirementId) {
    for (const category of this.categories) {
      for (const criterion of category.criteria) {
        const requirement = criterion.requirements.find(
          (requirement) => requirement.id === requirementId
        );
        if (requirement) {
          return requirement;
        }
      }
    }
    return null;
  }

  calculateTotalApplicableRequirementCount(
    criterionId,
    isApplicable,
    isCompleted
  ) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        if (isApplicable) {
          criterion.totalApplicableRequirementCount += 1;
          if (isCompleted) {
            criterion.completedRequirementCount += 1;
          }
        } else {
          criterion.totalApplicableRequirementCount -= 1;
          if (isCompleted) {
            criterion.completedRequirementCount -= 1;
          }
        }
        return;
      }
    }
  }

  calculateCompletedRequirementCount(criterionId, isCompleted) {
    for (const category of this.categories) {
      const criterion = category.criteria.find(
        (criterion) => criterion.id === criterionId
      );
      if (criterion) {
        if (isCompleted) {
          criterion.completedRequirementCount += 1;
        } else {
          criterion.completedRequirementCount -= 1;
        }
        return;
      }
    }
  }

  getTotalCriteriaCount() {
    return this.categories.reduce(
      (total, category) => total + category.criteria.length,
      0
    );
  }

  hasNoCriteria() {
    if (this.categories.length === 0) {
      return true;
    }

    return !this.categories.some((category) => category.criteria.length > 0);
  }

  getAllCategoryNames() {
    return this.categories.map((category) => category.name);
  }
}

class CertificationContentPageSampleEvidenceModel {
  constructor(id, name, downloadLink) {
    this.id = id;
    this.name = name;
    this.downloadLink = downloadLink;
  }

  static fromDTO(dto) {
    return new CertificationContentPageSampleEvidenceModel(
      dto.id,
      dto.name,
      dto.file ? `${API_URL}${dto.file.link}` : null
    );
  }
}

class CertificationContentPageSubrequirementModel {
  constructor(id, content, order, isCompleted) {
    this.id = id;
    this.content = content;
    this.order = order;
    this.isCompleted = isCompleted;
  }

  static fromDTO(dto) {
    return new CertificationContentPageSubrequirementModel(
      dto.id,
      dto.content,
      dto.order,
      dto.is_completed
    );
  }
}

class CertificationContentPageSubrequirementSectionModel {
  constructor(id, name, order, subrequirements) {
    this.id = id;
    this.name = name;
    this.order = order;
    this.subrequirements = subrequirements;
  }

  static fromDTO(dto) {
    return new CertificationContentPageSubrequirementSectionModel(
      dto.id,
      dto.name,
      dto.order,
      dto.subrequirements.map((sub) =>
        CertificationContentPageSubrequirementModel.fromDTO(sub)
      )
    );
  }
}

class CertificationContentPageRequirementModel {
  constructor(
    id,
    number,
    name,
    order,
    instruction,
    instructionGreenviewPortal,
    greenviewGuidance,
    additionalInformation,
    sampleEvidences,
    subrequirementSections,
    isApplicable,
    isCompleted
  ) {
    this.id = id;
    this.number = number;
    this.name = name;
    this.order = order;
    this.instruction = instruction;
    this.instructionGreenviewPortal = instructionGreenviewPortal;
    this.greenviewGuidance = greenviewGuidance;
    this.additionalInformation = additionalInformation;
    this.sampleEvidences = sampleEvidences;
    this.subrequirementSections = subrequirementSections;
    this.isApplicable = isApplicable;
    this.isCompleted = isCompleted;
    this.evidence = [];
    this.comments = [];
  }

  static fromDTO(dto) {
    return new CertificationContentPageRequirementModel(
      dto.id,
      dto.number,
      dto.name,
      dto.order,
      dto.instruction,
      dto.instruction_greenview_portal,
      dto.greenview_guidance,
      dto.additional_information,
      dto.sample_evidences.map((sampleEvidence) =>
        CertificationContentPageSampleEvidenceModel.fromDTO(sampleEvidence)
      ),
      dto.subrequirement_sections.map((section) =>
        CertificationContentPageSubrequirementSectionModel.fromDTO(section)
      ),
      dto.is_applicable,
      dto.is_completed
    );
  }
}

export class CertificationService {
  static async getCertifications() {
    try {
      const response = await axios.get(`${API_URL}/api/v1/certifications`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCertification(resource_type, resource_id, certificationId) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/certifications/${certificationId}`,
        { params: { resource_type, resource_id } }
      );
      return CertificationHomepageModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  }

  static async getCertificationContentPage(
    resource_type,
    resource_id,
    certificationId,
    criterionId
  ) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/certifications/${certificationId}/content_pages/${criterionId}`,
        { params: { resource_type, resource_id } }
      );
      return sortBy(
        response.data.map((requirement) =>
          CertificationContentPageRequirementModel.fromDTO(requirement)
        ),
        "order"
      );
    } catch (error) {
      throw error;
    }
  }
}

export class CertificationAssessmentService {
  static async saveRequirementAssessment(
    resource_type,
    resource_id,
    certificationId,
    requirementId,
    is_applicable,
    is_completed
  ) {
    try {
      const data = { is_applicable, is_completed };
      await axios.put(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}`,
        data,
        { params: { resource_type, resource_id } }
      );
    } catch (error) {
      throw error;
    }
  }

  static async saveSubrequirementAssessment(
    resource_type,
    resource_id,
    certificationId,
    requirementId,
    subrequirementId,
    is_completed
  ) {
    try {
      const data = { is_completed };
      await axios.put(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}/subrequirements/${subrequirementId}`,
        data,
        { params: { resource_type, resource_id } }
      );
    } catch (error) {
      throw error;
    }
  }

  static async uploadRequirementEvidenceFiles(
    resource_type,
    resource_id,
    certificationId,
    requirementId,
    evidenceFiles
  ) {
    const payloadBody = new FormData();

    if (evidenceFiles && evidenceFiles.length > 0) {
      evidenceFiles.forEach((file) => {
        payloadBody.append("file", file.file);
      });
    }

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}/evidences`,
        payloadBody,
        { params: { resource_type, resource_id } }
      );

      return response.data.map((data) =>
        CertificationRequirementEvidenceModel.fromDTO(data)
      );
    } catch (error) {
      throw error;
    }
  }

  static async getRequirementEvidenceFiles(
    resource_type,
    resource_id,
    certificationId,
    requirementId
  ) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}/evidences`,
        { params: { resource_type, resource_id } }
      );

      return response.data
        .map((data) => CertificationRequirementEvidenceModel.fromDTO(data))
        .sort(CertificationUtil.sortEvidenceFiles);
    } catch (error) {
      throw error;
    }
  }

  static async deleteRequirementEvidenceFile(
    resource_type,
    resource_id,
    certificationId,
    requirementId,
    evidenceId
  ) {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/certifications/${certificationId}/requirements/${requirementId}/evidences/${evidenceId}`,
        { params: { resource_id, resource_type } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async downloadReadinessReport(
    resource_type,
    resource_id,
    certificationId
  ) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/certifications/${certificationId}/readiness_report`,
        {
          params: { resource_type, resource_id },
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(response);
    } catch (error) {
      throw error;
    }
  }

  static async downloadEvidence(
    resource_type,
    resource_id,
    certificationId,
    criterionId = null
  ) {
    try {
      let response;
      if (criterionId) {
        response = await axios.get(
          `${API_URL}/api/v1/certifications/${certificationId}/criteria/${criterionId}/evidences`,
          { params: { resource_type, resource_id } }
        );
      } else {
        response = await axios.get(
          `${API_URL}/api/v1/certifications/${certificationId}/evidences`,
          { params: { resource_type, resource_id } }
        );
      }

      const evidenceFiles = response.data.map((file) =>
        FileAttachmentItemModel.fromFileDTO(file)
      );

      if (evidenceFiles.length === 0) {
        throw new NoContentError();
      } else {
        await FileUtils.downloadFiles(
          resource_type,
          resource_id,
          evidenceFiles.map((file) => file.link)
        );
      }
    } catch (error) {
      throw error;
    }
  }
}
