import React, { useContext } from "react";
import UserInventoryContext from "../../../context/UserInventoryContext";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import { billingWindowFeatures } from "../../../services/Public/BillingPaymentService";
import { Trans, useTranslation } from "react-i18next";

const PaymentInstructionModal = ({ onClose, paymentLink }) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);

  const onDonePayment = () => {
    onClose();
    userInventory.loadUserInventory();
  };

  return (
    <GVDSModal
      size={GVDSModal.Size.small}
      title={t("admin.billing.payment-instruction.modal-title")}
      show={true}
      hideCloseButton={true}
    >
      <GVDSModal.Body>
        <div className="mb-2">
          {t(
            "admin.billing.payment-instruction.payment-new-window-instruction"
          )}
        </div>

        <div>
          <Trans i18nKey="admin.billing.payment-instruction.payment-manual-link">
            If you don't see it, click{" "}
            <a
              href={paymentLink}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                window.open(paymentLink, "_blank", billingWindowFeatures);
              }}
            >
              here
            </a>{" "}
            to make the payment.
          </Trans>
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={onDonePayment}
          text={t("shared-modal.footer.close")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default PaymentInstructionModal;
