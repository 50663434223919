import axios from "axios";
import { API_URL } from "../config/api-config";
import { DateTimeUtils } from "./UtilsService";
import {
  TargetDetailsModel,
  TargetModel,
} from "../components/GoalsAndTargets/TargetDetailsModel";

export default class TargetService {
  static async createTarget(resource_type, resource_id, target) {
    try {
      const response = await axios.post(`${API_URL}/api/v1/targets`, target, {
        params: {
          resource_type,
          resource_id,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateTarget(resource_type, resource_id, targetId, target) {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/targets/${targetId}`,
        target,
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPreview(resource_type, resource_id, target) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/targets/preview`,
        target,
        {
          params: { resource_type, resource_id },
        }
      );

      return new TargetModel(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async getPreviewDetails(
    target,
    current_date,
    resource_type,
    resource_id
  ) {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/targets/preview/details",
        target,
        {
          params: {
            resource_id,
            resource_type,
            current_date: DateTimeUtils.getUTCISOString(current_date),
          },
        }
      );
      return new TargetDetailsModel(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async patchTarget(target_id, updates, resource_type, resource_id) {
    try {
      const response = await axios.patch(
        `${API_URL}/api/v1/targets/${target_id}`,
        updates,
        { params: { resource_type, resource_id } }
      );

      return new TargetModel(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async getAll(resource_type, resource_id) {
    try {
      const response = await axios.get(API_URL + "/api/v1/targets", {
        params: { resource_id, resource_type },
      });
      return response.data.map((d) => new TargetModel(d));
    } catch (error) {
      throw error;
    }
  }

  static async getTarget(target_id, resource_type, resource_id) {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/targets/" + target_id,
        {
          params: {
            resource_id,
            resource_type,
          },
        }
      );
      return new TargetModel(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async getDetails(target_id, current_date, resource_type, resource_id) {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/targets/" + target_id + "/details",
        {
          params: {
            resource_id,
            resource_type,
            current_date: DateTimeUtils.getUTCISOString(current_date),
          },
        }
      );
      return new TargetDetailsModel(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async getConfig(resource_type, resource_id) {
    try {
      const response = await axios.get(API_URL + "/api/v1/targets/config", {
        params: { resource_id, resource_type },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteTarget(resource_type, resource_id, targetId) {
    try {
      return await axios.delete(`${API_URL}/api/v1/targets/${targetId}`, {
        params: { resource_type, resource_id },
      });
    } catch (error) {
      throw error;
    }
  }
}
