import React from "react";

import { GUIDANCE_ARTICLE_STATUS } from "../../../config/constants";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../services/UtilsService";

const statusToColorMap = {
  [GUIDANCE_ARTICLE_STATUS.ACTIVE]: StatusLabel.Colors.green,
  [GUIDANCE_ARTICLE_STATUS.INACTIVE]: StatusLabel.Colors.gray,
};

const GuidanceArticleStatusBadge = ({ status }) => {
  let labelColor = statusToColorMap[status];

  return (
    <StatusLabel color={labelColor}>
      {StringUtils.capitaliseWord(status)}
    </StatusLabel>
  );
};

export default GuidanceArticleStatusBadge;
