import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FEATURES, TARGET_TOPIC } from "../../../config/constants";
import SummaryService from "../../../services/SummaryService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { GOALS_AND_TARGETS } from "../../../config/ROUTES_NAME";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import ToastContext from "../../../context/ToastContext";
import UserProfileContext from "../../../context/UserProfileContext";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import { FeatureLockWidgetContent } from "./BaseWidgetEmptyContent";
import { IconPlant, IconUsersGroup, IconWriting } from "@tabler/icons-react";
import BaseWidgetGenericSummaryItem, {
  BaseWidgetGenericSeparator,
} from "./BaseWidgetGenericSummaryItem";
import BaseWidgetHeadingTooltip from "./BaseWidgetHeadingTooltip";
import { useTranslation } from "react-i18next";

const TargetWidget = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.GOALS_N_TARGETS],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const locked =
    subscriptionFeatureStatus !== SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const [targetSummary, setTargetSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const inventory = userInventory.selectedInventory.get;
    if (!userInventory.isLoadingInventory.get && inventory && inventory.id) {
      setIsLoading(true);
      SummaryService.getTargetSummary(inventory.type, inventory.id)
        .then((data) => {
          setTargetSummary(data.topic_summaries);
        })
        .catch((e) => {
          toastContext.addFailToast("Failed to load target summary");
        })
        .finally(() => setIsLoading(false));
    }
  }, [
    userInventory.isLoadingInventory.get,
    userInventory.selectedInventory.get,
  ]);

  const redirectToGoalTargetPage = () => {
    history.push(getRedirectURLWithCurrentParam(GOALS_AND_TARGETS, location));
  };

  const getTargetValue = (topicName) => {
    let targetValue = 0;
    if (targetSummary.length > 0) {
      const topicObj = targetSummary.find(
        (summary) => summary.topic === topicName
      );
      targetValue = topicObj && topicObj.total ? topicObj.total : 0;
    }

    return targetValue;
  };

  let content;
  if (locked) {
    content = (
      <FeatureLockWidgetContent
        subscriptionFeatureStatus={subscriptionFeatureStatus}
      />
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <div className="base-widget__generic-summary__layout-container widget--target__content">
        <BaseWidgetGenericSummaryItem
          label={t(
            "landing-page.widget-targets.label-community-and-social-impact"
          )}
          icon={IconUsersGroup}
          value={getTargetValue(TARGET_TOPIC.SOCIAL)}
        />
        <BaseWidgetGenericSeparator />
        <BaseWidgetGenericSummaryItem
          label={t("landing-page.widget-targets.label-env-performance")}
          icon={IconPlant}
          value={getTargetValue(TARGET_TOPIC.ENVIRONMENTAL)}
        />
        <BaseWidgetGenericSeparator />
        <BaseWidgetGenericSummaryItem
          label={t("landing-page.widget-targets.label-free-form")}
          icon={IconWriting}
          value={getTargetValue(TARGET_TOPIC.FREE_FORM)}
        />
      </div>
    );
  }
  return (
    <div
      className="base-widget__container-clickable"
      onClick={redirectToGoalTargetPage}
    >
      <div className="base-widget__heading">
        <div className="base-widget__heading__title-text">
          {t("landing-page.widget-targets.title")}{" "}
          <BaseWidgetHeadingTooltip
            info={t("landing-page.widget-targets.tooltip")}
          />
        </div>
      </div>
      {content}
    </div>
  );
};

export default TargetWidget;
