import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDataSheet from "react-datasheet";
import LatLongFinderBulkInputService from "./LatLongFinderBulkInputService";
import groupBy from "lodash/groupBy";
import range from "lodash/range";
import clone from "lodash/clone";
import ToastContext from "../../../context/ToastContext";
import LatLongFinderService from "./LatLongFinderService";
import { BulkDataInputControls } from "../../Data/BulkDataInput";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import { buttonVariant } from "../../../gvds-components/Buttons/GVDSButton";
import Container from "react-bootstrap/Container";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";

const INITIAL_GRID_SIZE = 10;
const MAX_ALLOWED_GRID_SIZE = 3000;

const tableHeaderRenderer = ({ className, children }) => {
  return (
    <div className={className}>
      <table>
        <thead>
          <tr>
            <th className="cell read-only" style={{ width: "2rem" }} />
            <th className="cell read-only" style={{ width: "2.5rem" }}>
              Row
            </th>
            <th className="cell read-only" style={{ width: "15rem" }}>
              Site Name
            </th>
            <th className="cell read-only" style={{ width: "30rem" }}>
              Street Address
            </th>
            <th className="cell read-only" style={{ width: "12rem" }}>
              City
            </th>
            <th className="cell read-only" style={{ width: "15rem" }}>
              State / Province
            </th>
            <th className="cell read-only" style={{ width: "10rem" }}>
              Postal Code
            </th>
            <th className="cell read-only" style={{ width: "15rem" }}>
              Country
            </th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const LatLongFinder = () => {
  const getInitialRows = () => {
    return range(INITIAL_GRID_SIZE).map((row) =>
      latLongFinderBulkInputService.current.getNewRow(row, setRowToDelete)
    );
  };

  const toastContext = useContext(ToastContext);
  const latLongFinderBulkInputService = useRef(
    new LatLongFinderBulkInputService()
  );

  const [rowToDelete, setRowToDelete] = useState(-1);
  const [grid, setGrid] = useState(getInitialRows());
  const [hasEdited, setHasEdited] = useState(false);
  const [isRowOverLimit, setIsRowOverLimit] = useState(false);

  const columns = latLongFinderBulkInputService.current.getTableColumnLabels();

  useEffect(() => {
    if (rowToDelete >= 0) {
      const newGrid = latLongFinderBulkInputService.current.removeRow(
        rowToDelete,
        setRowToDelete,
        grid
      );
      setGrid(newGrid);
      setRowToDelete(-1);
      setIsRowOverLimit(false);
    }
  }, [rowToDelete]);

  const handleChanges = (changes = [], additions = []) => {
    setHasEdited(true);
    const totalChanges = [...changes, ...additions];
    const changedGrid = grid.map((row) => [...row]);
    const changesByRow = groupBy(totalChanges, (c) => c.row);

    for (const [row, rowChanges] of Object.entries(changesByRow)) {
      const rowData = changedGrid[row]
        ? changedGrid[row]
        : latLongFinderBulkInputService.current.getNewRow(row, setRowToDelete);
      changedGrid[row] = latLongFinderBulkInputService.current.updateRow(
        rowData,
        rowChanges
      );
    }

    if (changedGrid.length > MAX_ALLOWED_GRID_SIZE) {
      changedGrid.splice(
        MAX_ALLOWED_GRID_SIZE,
        changedGrid.length - MAX_ALLOWED_GRID_SIZE
      );
      setIsRowOverLimit(true);
    } else {
      setIsRowOverLimit(false);
    }
    setGrid(changedGrid);
  };

  const addRow = () => {
    const row = grid.length;
    if (row >= MAX_ALLOWED_GRID_SIZE) {
      setIsRowOverLimit(true);
    } else {
      grid.push(
        latLongFinderBulkInputService.current.getNewRow(row, setRowToDelete)
      );
      setGrid(clone(grid));
    }
  };

  const resetGrid = () => {
    setHasEdited(false);
    setGrid(getInitialRows());
    latLongFinderBulkInputService.current.resetErrors();
    setIsRowOverLimit(false);
  };

  const validateBulkData = async () => {
    setIsRowOverLimit(false);
    const changedGrid = latLongFinderBulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const sites = changedGrid.map((rowData) =>
      latLongFinderBulkInputService.current.getSite(rowData)
    );

    if (sites.length === 0) return;

    try {
      const errors = latLongFinderBulkInputService.current.validateSites(sites);
      const dataErrors =
        latLongFinderBulkInputService.current.prepareDataErrors(
          errors.dataErrors.rowErrors
        );
      const dataPossibleErrors =
        latLongFinderBulkInputService.current.prepareDataPossibleErrors(
          errors.dataPossibleErrors.rowPossibleErrors
        );
      const newGrid =
        latLongFinderBulkInputService.current.updateGridWithErrors(
          changedGrid,
          dataErrors,
          dataPossibleErrors
        );
      setHasEdited(false);
      setGrid(newGrid);
    } catch (e) {
      toastContext.addFailToast(<span>Failed to validate data</span>);
    }
  };

  const downloadLatLong = async () => {
    try {
      await LatLongFinderService.downloadLatLong(
        LatLongFinderService.toRequestPayload(grid)
      );
      resetGrid();
      toastContext.addSuccessToast(
        <span>
          <strong>Download Success</strong>
          <br />
          Please look for your latitude longitude report in your download folder
        </span>
      );
    } catch (e) {
      toastContext.addFailToast(<span>Failed to download report</span>);
    } finally {
    }
  };

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Latitude Longitude Finder</h1>
        </PageHeader.Title>
      </PageHeader>
      <div className="mb-2">
        The site name is taken into consideration when generating coordinates.
        For the most accurate coordinates, please input the actual site name
        rather than a placeholder.
      </div>
      <div className="mb-2">
        This tool only supports up to {MAX_ALLOWED_GRID_SIZE} entries in one
        download. If you have more, please split them into batches.
      </div>
      <div className="lat-long-finder__content-container">
        {latLongFinderBulkInputService.current.hasDataErrors() ? (
          <GVDSBanner
            title={`${latLongFinderBulkInputService.current.dataErrorRows.length} issues detected.`}
            variant={GVDSBanner.Variants.error}
            className="mt-2"
          >
            Please fix the following rows:{" "}
            {latLongFinderBulkInputService.current.dataErrorRows
              .map((row) => Number(row) + 1)
              .join(", ")}
          </GVDSBanner>
        ) : latLongFinderBulkInputService.current.hasPossibleErrors() ? (
          <GVDSBanner
            title={`${latLongFinderBulkInputService.current.possibleErrorRows.length} possible issues detected. Records with this issue can be submitted.`}
            variant={GVDSBanner.Variants.warning}
            className="mt-2"
          >
            Please check the following rows:{" "}
            {latLongFinderBulkInputService.current.possibleErrorRows
              .map((row) => Number(row) + 1)
              .join(", ")}
          </GVDSBanner>
        ) : (
          latLongFinderBulkInputService.current.hasNoErrors() && (
            <GVDSBanner
              title="No issues found."
              variant={GVDSBanner.Variants.success}
              className="mt-2"
            >
              Click "Download Lat-Long" to get your latitude longitude report.
            </GVDSBanner>
          )
        )}
        {isRowOverLimit && (
          <GVDSBanner
            variant={GVDSBanner.Variants.error}
            title="Too many entries"
          >
            <div>
              Your entries have exceeded the maximum limit (
              {MAX_ALLOWED_GRID_SIZE}). Please split your entries into multiple
              batches.
            </div>
          </GVDSBanner>
        )}
        <div className="d-flex flex-row-reverse align-items-center">
          {hasEdited && <div>Changes made! Validate before downloading.</div>}
        </div>
        <div className="action-bar">
          <BulkDataInputControls
            addNewRow={addRow}
            rowCount={latLongFinderBulkInputService.current.getFilledRows(grid)}
            onClearAll={resetGrid}
          />
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.secondary}
            className="ms-auto"
            text="Check for Validation"
            onClickAsyncFunc={validateBulkData}
          />
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            className="ms-2"
            onClickAsyncFunc={downloadLatLong}
            text="Download Lat-Long"
            disabled={
              hasEdited ||
              latLongFinderBulkInputService.current.disableDownload()
            }
          />
        </div>
        <div className="datasheet-table">
          <ReactDataSheet
            data={grid}
            sheetRenderer={(props) =>
              tableHeaderRenderer({ columns, ...props })
            }
            valueRenderer={(cell) => cell.value}
            onCellsChanged={handleChanges}
          />
        </div>
      </div>
    </Container>
  );
};

export default LatLongFinder;
