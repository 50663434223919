import React, { useContext, useEffect, useRef, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import { EditorState } from "draft-js";
import CertificationRequirementAdminService from "../../../../services/PortalAdmin/Certification/CertificationRequirementAdminService";
import { RTFUtils, StringUtils } from "../../../../services/UtilsService";
import { cloneDeep, isEqual } from "lodash";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVFormGroup from "../../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import GVDSFormFieldWithCharacterCount from "../../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import CertificationContentSubRequirementForm from "../Subrequirement/CertificationContentSubRequirementForm";
import { Editor } from "react-draft-wysiwyg";
import CertificationContentSampleEvidenceForm from "../Subrequirement/CertificationContentSampleEvidenceForm";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import {
  new_entry_prefix,
  POSITION,
  tobe_deleted_entry_prefix,
} from "../../../../config/constants";
import LoadingSpinner from "../../../common/LoadingSpinner";
import GVDSFormSingleSelect from "../../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import {
  createInitialSubrequirementSection,
  NOT_SECTIONED,
} from "../CertificationSharedUtils";
import UnsavedChangePromptModal from "../../../common/UnsavedChangePromptModal";
import Divider, { DividerOrientation } from "../../../common/Divider";
import { useTranslation } from "react-i18next";

const REQUIREMENT_VERBATIM_NUMBER_MAX_CHARACTER_LENGTH = 10;

export const RequirementFormModal = ({
  certificationId,
  category,
  criteria,
  show,
  setShowInputRequirementForm,
  closeModal,
  requirement,
  onPressDeleteRequirement,
  onRequirementSaved,
}) => {
  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [requirementNumber, setRequirementNumber] = useState("");
  const requirementNumberRef = useRef(null);
  const [requirementName, setRequirementName] = useState("");
  const requirementNameRef = useRef(null);
  const [instruction, setInstruction] = useState("");
  const [
    instructionGreenviewPortalEditorState,
    setInstructionGreenviewPortalEditorState,
  ] = useState(EditorState.createEmpty());
  const [greenviewGuidanceEditorState, setGreenviewGuidanceEditorState] =
    useState(EditorState.createEmpty());
  const [
    additionalInformationEditorState,
    setAdditionalInformationEditorState,
  ] = useState(EditorState.createEmpty());

  const [subrequirementSections, setSubrequirementSections] = useState([
    createInitialSubrequirementSection(),
  ]);
  const [showDeleteSubrequirementModal, setShowDeleteSubrequirementModal] =
    useState(false);
  const [toBeDeletedSectionId, setToBeDeletedSectionId] = useState(null);
  const [toBeDeletedSubrequirementId, setToBeDeletedSubrequirementId] =
    useState(null);
  const subrequirementRef = useRef(null);

  const [sampleEvidences, setSampleEvidences] = useState([]);
  const sampleEvidenceRef = useRef(null);

  const [showUnsavedChangePromptModal, setShowUnsavedChangePromptModal] =
    useState(false);

  useEffect(() => {
    if (requirement) {
      applyCurrentRequirementToInputData();
    } else {
      resetForm();
    }
  }, [requirement]);

  const applyCurrentRequirementToInputData = () => {
    setRequirementNumber(requirement.number);
    setRequirementName(requirement.name);
    setInstruction(requirement.instruction);
    setInstructionGreenviewPortalEditorState(
      RTFUtils.convertHTMLToEditorState(requirement.instructionGreenviewPortal)
    );
    setGreenviewGuidanceEditorState(
      RTFUtils.convertHTMLToEditorState(requirement.greenviewGuidance)
    );
    setAdditionalInformationEditorState(
      RTFUtils.convertHTMLToEditorState(requirement.additionalInformation)
    );

    if (
      requirement.subrequirementSections &&
      requirement.subrequirementSections.length > 0
    ) {
      setSubrequirementSections(
        requirement.subrequirementSections
          ? cloneDeep(requirement.subrequirementSections)
          : []
      );
    } else {
      setSubrequirementSections([createInitialSubrequirementSection()]);
    }

    setSampleEvidences(
      requirement.sampleEvidences ? cloneDeep(requirement.sampleEvidences) : []
    );
  };

  const resetForm = () => {
    setIsValidated(false);
    setRequirementNumber("");
    setRequirementName("");
    setInstruction("");
    setInstructionGreenviewPortalEditorState(EditorState.createEmpty());
    setGreenviewGuidanceEditorState(EditorState.createEmpty());
    setAdditionalInformationEditorState(EditorState.createEmpty());
    setSubrequirementSections([createInitialSubrequirementSection()]);
    setSampleEvidences([]);
  };

  const sectionHasChanges = () => {
    if (requirement.subrequirementSections.length === 0) {
      return !(
        subrequirementSections[0].sectionName === NOT_SECTIONED &&
        subrequirementSections[0].subrequirements.length === 0
      );
    } else {
      return !isEqual(
        requirement.subrequirementSections,
        subrequirementSections
      );
    }
  };

  const editorHasChanges = (value, editorState) => {
    if (!StringUtils.isNotEmpty(value) && RTFUtils.isEmpty(editorState)) {
      return false;
    } else {
      return value !== RTFUtils.convertEditorStateToHTML(editorState);
    }
  };

  const hasChanges = () => {
    if (requirement) {
      return (
        requirement.number !== requirementNumber ||
        requirement.name !== requirementName ||
        requirement.instruction !== instruction ||
        editorHasChanges(
          requirement.instructionGreenviewPortal,
          instructionGreenviewPortalEditorState
        ) ||
        editorHasChanges(
          requirement.greenviewGuidance,
          greenviewGuidanceEditorState
        ) ||
        editorHasChanges(
          requirement.additionalInformation,
          additionalInformationEditorState
        ) ||
        sectionHasChanges() ||
        !isEqual(requirement.sampleEvidences, sampleEvidences)
      );
    } else {
      return (
        requirementNumber.length > 0 ||
        requirementName.length > 0 ||
        instruction.length > 0 ||
        !RTFUtils.isEmpty(instructionGreenviewPortalEditorState) ||
        !RTFUtils.isEmpty(greenviewGuidanceEditorState) ||
        !RTFUtils.isEmpty(additionalInformationEditorState) ||
        !(
          subrequirementSections[0].sectionName === NOT_SECTIONED &&
          subrequirementSections[0].subrequirements.length === 0
        ) ||
        sampleEvidences.length > 0
      );
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      if (hasChanges()) {
        setShowInputRequirementForm(false);
        setShowUnsavedChangePromptModal(true);
      } else {
        closeModal();
        resetForm();
      }
    }
  };

  const handleCancelCloseModal = () => {
    setShowUnsavedChangePromptModal(false);
    setShowInputRequirementForm(true);
  };

  const handleCloseModalResetForm = () => {
    setShowUnsavedChangePromptModal(false);
    closeModal();
    resetForm();
  };

  const handleConfirmDeleteSubrequirement = (sectionId, subrequirementId) => {
    setToBeDeletedSectionId(sectionId);
    setToBeDeletedSubrequirementId(subrequirementId);
    setShowInputRequirementForm(false);
    setShowDeleteSubrequirementModal(true);
  };

  const deleteSubrequirement = () => {
    const updatedSections = [...subrequirementSections];

    const sectionIndex = updatedSections.findIndex(
      (section) => section.id === toBeDeletedSectionId
    );

    const subrequirementIndex = updatedSections[
      sectionIndex
    ].subrequirements.findIndex(
      (subrequirement) => subrequirement.id === toBeDeletedSubrequirementId
    );

    if (toBeDeletedSubrequirementId.startsWith(new_entry_prefix)) {
      updatedSections[sectionIndex].subrequirements.splice(
        subrequirementIndex,
        1
      );
    } else {
      updatedSections[sectionIndex].subrequirements[subrequirementIndex].id =
        tobe_deleted_entry_prefix + toBeDeletedSubrequirementId;
    }
    setSubrequirementSections(updatedSections);

    setShowDeleteSubrequirementModal(false);
    setShowInputRequirementForm(true);
  };

  const isInputsValid = () => {
    if (
      !StringUtils.isNotEmpty(requirementNumber) ||
      requirementNumber.length >
        REQUIREMENT_VERBATIM_NUMBER_MAX_CHARACTER_LENGTH
    ) {
      requirementNumberRef.current.scrollIntoView();
      return false;
    }

    if (!StringUtils.isNotEmpty(requirementName)) {
      requirementNameRef.current.scrollIntoView();
      return false;
    }

    const isSubrequirementInputNotValid = subrequirementSections.some(
      (section) => {
        return (
          !StringUtils.isNotEmpty(section.sectionName) ||
          section.subrequirements.some(
            (sr) => !StringUtils.isNotEmpty(sr.content)
          )
        );
      }
    );
    if (isSubrequirementInputNotValid) {
      subrequirementRef.current.scrollIntoView();
      return false;
    }

    const isSampleEvidenceNameValid = sampleEvidences.every((evidence) =>
      StringUtils.isNotEmpty(evidence.name)
    );
    if (!isSampleEvidenceNameValid) {
      sampleEvidenceRef.current.scrollIntoView();
      return false;
    }

    return true;
  };

  const saveRequirement = async () => {
    setIsValidated(true);

    if (isInputsValid()) {
      setIsLoading(true);

      const requirementData = {
        number: requirementNumber,
        name: requirementName,
        instruction: instruction,
        instruction_greenview_portal: getEditorContentBodyHTML(
          instructionGreenviewPortalEditorState
        ),
        greenview_guidance: getEditorContentBodyHTML(
          greenviewGuidanceEditorState
        ),
        additional_information: getEditorContentBodyHTML(
          additionalInformationEditorState
        ),
        subrequirement_sections: subrequirementSections,
        sample_evidences: sampleEvidences,
      };

      try {
        if (requirement) {
          await CertificationRequirementAdminService.updateRequirement(
            certificationId,
            criteria.id,
            requirement.id,
            requirementData
          );
          toastContext.addSuccessToast(
            <span>Requirement successfully updated.</span>
          );
        } else {
          await CertificationRequirementAdminService.createRequirement(
            certificationId,
            criteria.id,
            requirementData
          );
          toastContext.addSuccessToast(
            <span>Requirement successfully created.</span>
          );
          resetForm();
        }

        setShowInputRequirementForm(false);
        setIsValidated(false);
        onRequirementSaved();
      } catch (error) {
        toastContext.addFailToast(
          <span>
            Failed to {requirement ? "update" : "create"} requirement.
          </span>
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getEditorContentBodyHTML = (editorState) => {
    return RTFUtils.isEmpty(editorState)
      ? ""
      : RTFUtils.convertEditorStateToHTML(editorState);
  };

  return (
    <>
      <GVDSModal
        title={
          <div className="d-flex align-items-center">
            {requirement ? "Edit" : "Add"} requirement
            <span className="gvds-text--body ms-2">
              (Unless saved, changes will be lost)
            </span>
          </div>
        }
        size={GVDSModal.Size.medium}
        show={show}
        onHide={handleClose}
      >
        <GVDSModal.Body>
          <div className="system-toolbox--certification__requirement-form__information-field">
            <div className="system-toolbox--certification__requirement-form__information-field__label">
              Category:
            </div>
            <div>{category.name}</div>
          </div>
          <div className="system-toolbox--certification__requirement-form__information-field">
            <div className="system-toolbox--certification__requirement-form__information-field__label">
              Criteria:
            </div>
            <div>{`${criteria.number}. ${criteria.name}`}</div>
          </div>
          <div className="border-bottom mb-3" />
          <GVFormGroup controlId="number" className="w-50">
            <Form.Label>Requirement number</Form.Label>
            <div ref={requirementNumberRef}>
              <GVDSFormFieldWithCharacterCount
                name="name"
                value={requirementNumber}
                onInput={(value) => setRequirementNumber(value)}
                placeholder="Enter verbatim requirement number"
                maxLength={REQUIREMENT_VERBATIM_NUMBER_MAX_CHARACTER_LENGTH}
                statusMetadata={
                  isValidated && requirementNumber.length === 0
                    ? FormFieldStatusMetadata.getError(
                        "This field cannot be empty."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </div>
          </GVFormGroup>
          <GVFormGroup controlId="name">
            <Form.Label>Requirement name</Form.Label>
            <GVDSFormTextArea
              ref={requirementNameRef}
              name="name"
              value={requirementName}
              onInput={(value) => setRequirementName(value)}
              placeholder="Enter verbatim requirement name"
              statusMetadata={
                isValidated && requirementName.length === 0
                  ? FormFieldStatusMetadata.getError(
                      "This field cannot be empty."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup controlId="instruction">
            <Form.Label>Requirement instructions</Form.Label>
            <span className="optional-form-label ms-1">(Optional)</span>
            <GVDSFormField
              name="instruction"
              value={instruction}
              onInput={(value) => setInstruction(value)}
              placeholder="Enter any specific instructions for this requirement"
            />
          </GVFormGroup>
          <GVFormGroup controlId="subrequirements" ref={subrequirementRef}>
            <Form.Label>Sub-requirements</Form.Label>
            <span className="optional-form-label ms-1">(Optional)</span>
            <CertificationContentSubRequirementForm
              subrequirementSections={subrequirementSections}
              setSubrequirementSections={setSubrequirementSections}
              isValidated={isValidated}
              confirmDeleteSubrequirement={(sectionId, subrequirementId) =>
                handleConfirmDeleteSubrequirement(sectionId, subrequirementId)
              }
            />
          </GVFormGroup>
          <GVFormGroup controlId="portalUsageGuide">
            <Form.Label>Using Greenview Portal</Form.Label>
            <span className="optional-form-label ms-1">(Optional)</span>
            <Editor
              editorState={instructionGreenviewPortalEditorState}
              onEditorStateChange={(editorState) => {
                setInstructionGreenviewPortalEditorState(editorState);
              }}
              wrapperClassName="rtf-wrapper"
              editorClassName="rtf-editor"
              toolbar={{
                options: [
                  "blockType",
                  "inline",
                  "list",
                  "link",
                  "remove",
                  "history",
                ],
              }}
            />
          </GVFormGroup>
          <GVFormGroup controlId="requirementGuidance">
            <Form.Label>Greenview's guidance</Form.Label>
            <span className="optional-form-label ms-1">(Optional)</span>
            <Editor
              editorState={greenviewGuidanceEditorState}
              onEditorStateChange={(editorState) => {
                setGreenviewGuidanceEditorState(editorState);
              }}
              wrapperClassName="rtf-wrapper"
              editorClassName="rtf-editor"
              toolbar={{
                options: [
                  "blockType",
                  "inline",
                  "list",
                  "link",
                  "remove",
                  "history",
                ],
              }}
            />
          </GVFormGroup>
          <GVFormGroup controlId="sampleEvidences" ref={sampleEvidenceRef}>
            <Form.Label>Recommended evidence</Form.Label>
            <span className="optional-form-label ms-1">(Optional)</span>
            <CertificationContentSampleEvidenceForm
              sampleEvidences={sampleEvidences}
              setSampleEvidences={setSampleEvidences}
              isValidated={isValidated}
            />
          </GVFormGroup>
          <GVFormGroup controlId="additionalInformation">
            <Form.Label>Additional information</Form.Label>
            <span className="optional-form-label ms-1">(Optional)</span>
            <Editor
              editorState={additionalInformationEditorState}
              onEditorStateChange={(editorState) => {
                setAdditionalInformationEditorState(editorState);
              }}
              wrapperClassName="rtf-wrapper"
              editorClassName="rtf-editor"
              toolbar={{
                options: [
                  "blockType",
                  "inline",
                  "list",
                  "link",
                  "remove",
                  "history",
                ],
              }}
            />
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {requirement && (
            <>
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                onClick={onPressDeleteRequirement}
                text="Delete"
              />
              <Spacer />
            </>
          )}
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            text="Save"
            onClickAsyncFunc={saveRequirement}
          />
        </GVDSModal.Footer>
      </GVDSModal>
      <ConfirmDeleteSubrequirementModal
        closeModal={() => {
          setShowDeleteSubrequirementModal(false);
          setShowInputRequirementForm(true);
        }}
        show={showDeleteSubrequirementModal}
        onDelete={deleteSubrequirement}
      />
      <UnsavedChangePromptModal
        show={showUnsavedChangePromptModal}
        onCancel={handleCancelCloseModal}
        onProceed={handleCloseModalResetForm}
        modalName="Input Requirement"
        message="You have unsaved changes in the form. Your data will be cleared and won’t be stored when you leave this page. Are you sure?"
      />
    </>
  );
};

export const ConfirmDeleteRequirementModal = ({
  show,
  closeModal,
  onDelete,
  isDeleting,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    if (!isDeleting) {
      closeModal();
    }
  };

  return (
    <GVDSModal
      show={show}
      onHide={handleClose}
      title="Delete requirement"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <div>
          Are you sure want to delete this requirement? All sub-requirements,
          attachment, comments, and user responses will be deleted as well.
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          disabled={isDeleting}
          text={t("shared-modal.footer.cancel")}
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.destructive_primary}
          onClickAsyncFunc={onDelete}
          disabled={isDeleting}
          text={t("shared.delete")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const ConfirmDeleteSubrequirementModal = ({
  show,
  closeModal,
  onDelete,
}) => {
  return (
    <GVDSModal
      show={show}
      onHide={closeModal}
      title="Delete sub-requirement"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <div>
          Are you sure want to delete this sub-requirement? All user responses
          will be deleted as well.
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={closeModal}
          text="Cancel"
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.destructive_primary}
          onClickAsyncFunc={onDelete}
          text="Delete"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const MoveCertificationRequirementDialog = ({
  show,
  closeModal,
  onRequirementMoved,
  certificationId,
  requirementToBeMoved,
  selectedCategory,
  categories,
  selectedCriterion,
}) => {
  const toastContext = useContext(ToastContext);
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [position, setPosition] = useState(POSITION.BEFORE);
  const [requirementToMoveTo, setRequirementToMoveTo] = useState(null);
  const [categoryToMoveTo, setCategoryToMoveTo] = useState(selectedCategory);
  const [criteriaToMoveTo, setCriteriaToMoveTo] = useState(selectedCriterion);

  const isCriteriaToMoveToHasRequirements =
    criteriaToMoveTo?.requirements.length > 0;

  const handleClose = () => {
    if (!isLoading) {
      closeModal();
    }
  };

  const resetState = () => {
    setRequirementToMoveTo(null);
    setIsValidated(false);
    setPosition(POSITION.BEFORE);
  };

  const isInputValid = () => {
    return (
      categoryToMoveTo &&
      criteriaToMoveTo &&
      (requirementToMoveTo || !isCriteriaToMoveToHasRequirements)
    );
  };

  const moveRequirement = async () => {
    setIsValidated(true);

    if (!isInputValid()) {
      return;
    }

    setIsLoading(true);
    try {
      await CertificationRequirementAdminService.moveRequirement(
        certificationId,
        selectedCriterion.id,
        requirementToBeMoved,
        criteriaToMoveTo,
        requirementToMoveTo,
        position
      );
      resetState();
      closeModal();
      onRequirementMoved();
      toastContext.addSuccessToast(
        <span>Requirement successfully moved.</span>
      );
    } catch {
      toastContext.addFailToast(<span>Failed to move requirement.</span>);
    } finally {
      setIsLoading(false);
    }
  };

  const isCategoryOrCriteriaDifferentWithDefaultSelected =
    selectedCategory.id !== categoryToMoveTo.id ||
    selectedCriterion.id !== criteriaToMoveTo?.id;

  return (
    <GVDSModal
      title="Move requirement to"
      size={GVDSModal.Size.small}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {isCategoryOrCriteriaDifferentWithDefaultSelected && (
              <GVDSBanner
                variant={GVDSBanner.Variants.warning}
                className="system-toolbox--certification__move-requirement-banner"
              >
                <div className="gvds-text--caption">
                  All sub-requirements nested under this requirement will also
                  be moved
                </div>
              </GVDSBanner>
            )}
            <GVFormGroup>
              <Form.Label>Category Name</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Select category"
                isSearchable={true}
                value={
                  categoryToMoveTo
                    ? {
                        value: categoryToMoveTo,
                        label: categoryToMoveTo.name,
                      }
                    : null
                }
                options={
                  categories.length > 0
                    ? categories.map((category, index) => {
                        return { value: category, label: category.name };
                      })
                    : []
                }
                onSelect={(selected) => {
                  setCategoryToMoveTo(selected.value);
                  setCriteriaToMoveTo(null);
                }}
                statusMetadata={
                  isValidated && !selectedCategory
                    ? FormFieldStatusMetadata.getError(
                        "Please select a category"
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <GVFormGroup className="mb-0">
              <Form.Label>Criteria Name</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Select criteria"
                isSearchable={true}
                value={
                  criteriaToMoveTo
                    ? {
                        value: criteriaToMoveTo,
                        label: `${criteriaToMoveTo.number}. ${criteriaToMoveTo.name}`,
                      }
                    : null
                }
                options={
                  categoryToMoveTo.criteria.length > 0
                    ? categoryToMoveTo.criteria.map((criterion, index) => {
                        const label = `${criterion.number}. ${criterion.name}`;
                        return { value: criterion, label: label };
                      })
                    : []
                }
                onSelect={(selected) => {
                  setCriteriaToMoveTo(selected.value);
                }}
                statusMetadata={
                  isValidated && !criteriaToMoveTo
                    ? FormFieldStatusMetadata.getError(
                        "Please select a criteria"
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <Divider
              orientation={DividerOrientation.horizontal}
              className="system-toolbox--certification__move-requirement-divider"
            />
            <GVFormGroup>
              <Form.Label>Position</Form.Label>
              <Form.Check
                id="before"
                label="Before"
                type="radio"
                checked={
                  isCriteriaToMoveToHasRequirements &&
                  position === POSITION.BEFORE
                }
                onChange={() => setPosition(POSITION.BEFORE)}
                disabled={!isCriteriaToMoveToHasRequirements}
              />
              <Form.Check
                id="after"
                label="After"
                type="radio"
                checked={
                  isCriteriaToMoveToHasRequirements &&
                  position === POSITION.AFTER
                }
                onChange={() => {
                  setPosition(POSITION.AFTER);
                }}
                disabled={!isCriteriaToMoveToHasRequirements}
              />
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>Requirement Name</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Select requirement"
                className="requirement-to-move-to"
                isSearchable={true}
                value={
                  requirementToMoveTo
                    ? {
                        value: requirementToMoveTo,
                        label: `${requirementToMoveTo.number}. ${requirementToMoveTo.name}`,
                      }
                    : null
                }
                options={
                  criteriaToMoveTo && criteriaToMoveTo.requirements.length > 0
                    ? criteriaToMoveTo.requirements.map((requirement) => {
                        const label = `${requirement.number}. ${requirement.name}`;
                        return { value: requirement, label: label };
                      })
                    : []
                }
                isOptionDisabled={(option) =>
                  option.value.id === requirementToBeMoved.id
                }
                onSelect={(selected) => {
                  setRequirementToMoveTo(selected.value);
                }}
                disabled={!isCriteriaToMoveToHasRequirements}
                statusMetadata={
                  isValidated &&
                  !requirementToMoveTo &&
                  isCriteriaToMoveToHasRequirements
                    ? FormFieldStatusMetadata.getError(
                        "Please select a requirement"
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          text="Cancel"
          disabled={isLoading}
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          onClickAsyncFunc={moveRequirement}
          disabled={isLoading}
          text="Move"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};
