import React, { useContext } from "react";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import DashboardOptionContext from "../Context/DashboardOptionContext";
import DashboardDataContext from "../Context/DashboardDataContext";

const UnitSelector = ({ subtopic }) => {
  const dashboardOptionContext = useContext(DashboardOptionContext);
  const options = dashboardOptionContext.selectedDashboardOptions;
  const unitOptions =
    options !== null ? options["unitsBySubtopic"][subtopic] : [];

  const dashboardDataContext = useContext(DashboardDataContext);
  const selectedConfigHolder = dashboardDataContext.selectedConfigHolder;

  const onChangeUnit = (unitId) => {
    selectedConfigHolder.setUnitForSubtopic(subtopic, unitId);
    dashboardDataContext.onUpdateConfig();
  };

  return (
    <GVDSFormSingleSelect
      name="unit-selector"
      className="dashboard-filter__single-select__unit"
      value={getOptionByValueFromIdName(
        selectedConfigHolder.getUnitForSubtopic(subtopic),
        unitOptions
      )}
      options={
        unitOptions &&
        unitOptions
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((option) => {
            return { value: option.id, label: option.name };
          })
      }
      onSelect={(selectedOption) => onChangeUnit(selectedOption.value)}
      isDropdownFollowFilterWidth={false}
    />
  );
};

export default UnitSelector;
