import React, { useContext, useEffect, useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import withAuthentication from "../../HOC/withAuthentication";
import UserInventoryContext from "../../../context/UserInventoryContext";
import EnvironmentalService from "../../../services/EnvironmentalService";
import ToastContext from "../../../context/ToastContext";
import { ENVIRONMENTAL_DATA } from "../../../config/ROUTES_NAME";
import EnvironmentalMeterSetup from "./EnvironmentalMeterSetup";
import MarketPurchaseTrackerSetup from "./MarketPurchaseOffsets/MarketPurchaseTrackerSetup";
import { useHistory } from "react-router-dom";
import EnvironmentalCustomEmissionFactorSetup from "./CustomEmissionFactor/EnvironmentalCustomEmissionFactorSetup";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import { Trans, useTranslation } from "react-i18next";

const EnvironmentalDataSetup = () => {
  const { t } = useTranslation();

  let history = useHistory();
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isMeterDatasetsLoading, setIsMeterDatasetsLoading] = useState(true);
  const [isCustomEmissionFactorsLoading, setIsCustomEmissionFactorsLoading] =
    useState(true);
  const [meterDatasets, setMeterDatasets] = useState([]);
  const [customEmissionFactors, setCustomEmissionFactors] = useState([]);

  useEffect(() => {
    if (selectedInventory) {
      loadMeterDatasets();
      loadCustomEmissionFactors();
    } else {
      backToEnvironmental();
    }
  }, [selectedInventory]);

  const loadMeterDatasets = () => {
    if (selectedInventory.id) {
      setIsMeterDatasetsLoading(true);
      EnvironmentalService.getAllDatasets(
        selectedInventory.id,
        selectedInventory.type
      )
        .then((data) => {
          setMeterDatasets(data);
        })
        .catch((e) => {
          toastContext.addFailToast(<span>Failed to load meter data.</span>);
        })
        .finally(() => setIsMeterDatasetsLoading(false));
    }
  };

  const loadCustomEmissionFactors = () => {
    if (selectedInventory.id) {
      setIsCustomEmissionFactorsLoading(true);
      EnvironmentalService.getAllCustomEmissionFactors(
        selectedInventory.id,
        selectedInventory.type
      )
        .then((data) => {
          setCustomEmissionFactors(data);
        })
        .catch((e) => {
          toastContext.addFailToast(
            <span>Failed to load custom emission factors.</span>
          );
        })
        .finally(() => setIsCustomEmissionFactorsLoading(false));
    }
  };

  const backToEnvironmental = () => {
    history.push(ENVIRONMENTAL_DATA);
  };

  const backButtonPage = t(
    "data-management.environmental.setup.back-page-label"
  );

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text={
            <Trans i18nKey="shared.button-return-to-page">
              Return to {{ backButtonPage }}
            </Trans>
          }
          onClick={backToEnvironmental}
        />
        <PageHeader.Title>
          <h1>{t("data-management.environmental.setup.page-title")}</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="usage">
        <Tab
          eventKey="usage"
          title={t("data-management.environmental.usage.section-title")}
        >
          <div className="pb-3">
            {t("data-management.environmental.setup.usage-tab-description")}
          </div>
          <EnvironmentalMeterSetup
            metersDataSet={meterDatasets}
            reloadMeters={loadMeterDatasets}
          />
        </Tab>
        <Tab
          eventKey="market-purchase"
          title={t(
            "data-management.environmental.market-purchase.section-title"
          )}
        >
          <div className="pb-3">
            {t(
              "data-management.environmental.setup.market-purchase-tab-description"
            )}
          </div>
          <MarketPurchaseTrackerSetup
            isMeterDatasetsLoading={isMeterDatasetsLoading}
            meterDatasets={meterDatasets}
          />
        </Tab>
        <Tab
          eventKey="custom-ef"
          title={t("data-management.environmental.setup.tab-custom-ef")}
        >
          <div className="pb-3">
            {t("data-management.environmental.setup.custom-ef-tab-description")}
          </div>
          <EnvironmentalCustomEmissionFactorSetup
            metersDataSet={meterDatasets}
            isCustomEmissionFactorsLoading={isCustomEmissionFactorsLoading}
            customEmissionFactors={customEmissionFactors}
            reloadCustomEmissionFactors={loadCustomEmissionFactors}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default withAuthentication(EnvironmentalDataSetup);
