import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { POSITION } from "../../../config/constants";
import GVFormGroup from "../../common/GVFormGroup";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";

const MoveSurveyQuestionDialog = ({
  show,
  onHide,
  surveyModel,
  onChange,
  onQuestionMoved,
  questionToBeMoved,
  scrollToMovedQuestion,
  allQuestions,
}) => {
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [position, setPosition] = useState(POSITION.BEFORE);
  const [questionToMoveTo, setQuestionToMoveTo] = useState(null);

  const resetState = () => {
    setQuestionToMoveTo(null);
    setIsValidated(false);
    setPosition(POSITION.BEFORE);
  };

  const moveQuestion = async () => {
    setIsValidated(true);
    if (questionToMoveTo) {
      setIsLoading(true);
      surveyModel.moveQuestion(questionToBeMoved, questionToMoveTo, position);
      onChange();
      await onQuestionMoved();
      scrollToMovedQuestion();
      resetState();
      onHide();
      setIsLoading(false);
    }
  };

  return (
    <GVDSModal
      title="Move this question to"
      size={GVDSModal.Size.small}
      show={show}
      onHide={onHide}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <GVFormGroup>
              <Form.Label>Position</Form.Label>
              <Form.Check
                id="before"
                label="Before"
                type="radio"
                checked={position === POSITION.BEFORE}
                onChange={() => setPosition(POSITION.BEFORE)}
              />
              <Form.Check
                id="after"
                label="After"
                type="radio"
                checked={position === POSITION.AFTER}
                onChange={() => {
                  setPosition(POSITION.AFTER);
                }}
              />
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>Question</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Search for a question"
                className="question-to-move-to"
                isSearchable={true}
                value={
                  questionToMoveTo
                    ? {
                        value: questionToMoveTo,
                        label: questionToMoveTo.questionText,
                      }
                    : null
                }
                options={allQuestions.map((q, index) => {
                  const numberOrder = index + 1;
                  const questionLabel = `${numberOrder}. ${q.questionText}`;
                  return { value: q, label: questionLabel };
                })}
                isOptionDisabled={(option) =>
                  option.value === questionToBeMoved
                }
                onSelect={(selected) => {
                  setQuestionToMoveTo(selected.value);
                }}
                statusMetadata={
                  isValidated && !questionToMoveTo
                    ? FormFieldStatusMetadata.getError(
                        "Please select a question"
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        {!isLoading && (
          <>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={onHide}
              text="Cancel"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={moveQuestion}
              text="Move Question"
            />
          </>
        )}
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default MoveSurveyQuestionDialog;
