import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FEATURES, INITIATIVE_SUBTOPIC } from "../../../config/constants";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { VIEW_ALL_INITIATIVES } from "../../../config/ROUTES_NAME";
import SummaryService from "../../../services/SummaryService";
import find from "lodash/find";
import moment from "moment";
import ToastContext from "../../../context/ToastContext";
import UserProfileContext from "../../../context/UserProfileContext";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import { FeatureLockWidgetContent } from "./BaseWidgetEmptyContent";
import { BaseWidgetGenericSeparator } from "./BaseWidgetGenericSummaryItem";
import BaseWidgetPeriodControl from "./BaseWidgetPeriodControl";
import {
  getPathWithTabKey,
  getURLWithPageParams,
} from "../../common/QueryHandler";
import BaseWidgetHeadingTooltip from "./BaseWidgetHeadingTooltip";
import { useTranslation } from "react-i18next";

const defaultEndPeriod = new moment()
  .subtract(1, "month")
  .endOf("month")
  .toDate();
const defaultStartPeriod = moment(defaultEndPeriod).startOf("year").toDate();

const InitiativeSubtopicDisplay = ({
  subtopicName,
  total,
  plannedCount,
  ongoingCount,
  completedCount,
}) => {
  const { t } = useTranslation();

  return (
    <div className="widget--initiatives__subtopic-summary__container">
      <div className="widget--initiatives__subtopic-summary__subtopic">
        {subtopicName}
      </div>
      <div className="widget--initiatives__subtopic-summary__values-container">
        <div className="widget--initiatives__subtopic-summary__total-container">
          <span className="widget--initiatives__subtopic-summary__total-value">
            {total}
          </span>{" "}
          {t("landing-page.widget-initiatives.label-total")}
        </div>
        <div className="widget--initiatives__subtopic-summary__breakdown-container">
          <span className="widget--initiatives__subtopic-summary__breakdown-value">
            {plannedCount}
          </span>{" "}
          {t("landing-page.widget-initiatives.label-planned")}
        </div>
        <div className="widget--initiatives__subtopic-summary__breakdown-container">
          <span className="widget--initiatives__subtopic-summary__breakdown-value">
            {ongoingCount}
          </span>{" "}
          {t("landing-page.widget-initiatives.label-in-progress")}
        </div>
        <div className="widget--initiatives__subtopic-summary__breakdown-container">
          <span className="widget--initiatives__subtopic-summary__breakdown-value">
            {completedCount}
          </span>{" "}
          {t("landing-page.widget-initiatives.label-completed")}
        </div>
      </div>
    </div>
  );
};

const InitiativeWidget = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.DATA_MANAGEMENT__INITIATIVE],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const locked =
    subscriptionFeatureStatus !== SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const [start, setStart] = useState(defaultStartPeriod);
  const [end, setEnd] = useState(defaultEndPeriod);

  const [isLoading, setIsLoading] = useState(true);
  const [initiativeSummary, setInitiativeSummary] = useState({});

  const abortControllerRef = useRef(null);

  useEffect(() => {
    loadInitiativeSummary(start, end);

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [userInventory.selectedInventory.get]);

  const loadInitiativeSummary = (start, end) => {
    setIsLoading(true);
    const selectedInventory = userInventory.selectedInventory.get;
    if (selectedInventory) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      SummaryService.getInitiativeSummary(
        selectedInventory.type,
        selectedInventory.id,
        start,
        end
      )
        .then((data) => {
          setInitiativeSummary(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.status === 500) {
            toastContext.addFailToast(
              <span>Failed to load initiative summary.</span>
            );
          }
        });
    }
  };

  const redirectToInitiativePage = () => {
    const pathWithTabKey = getPathWithTabKey(
      getURLWithPageParams(location, VIEW_ALL_INITIATIVES, {
        start,
        end,
      }),
      "all-initiatives"
    );
    history.push(pathWithTabKey);
  };

  const onPeriodSelectorSaved = (newStart, newEnd) => {
    loadInitiativeSummary(newStart, newEnd);
  };

  let content;
  if (locked) {
    content = (
      <FeatureLockWidgetContent
        subscriptionFeatureStatus={subscriptionFeatureStatus}
      />
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    const subtopics = Object.values(INITIATIVE_SUBTOPIC);

    content = (
      <div
        className="widget--initiatives__content"
        onClick={redirectToInitiativePage}
      >
        {subtopics.map((subtopic, index) => {
          const subtopicSummary = find(
            initiativeSummary["subtopic_summaries"],
            (s) => s.subtopic === subtopic
          );
          return (
            <React.Fragment key={subtopic}>
              <InitiativeSubtopicDisplay
                key={subtopic}
                subtopicName={subtopic}
                total={subtopicSummary?.total || 0}
                plannedCount={subtopicSummary?.planned_count || 0}
                ongoingCount={subtopicSummary?.in_progress_count || 0}
                completedCount={subtopicSummary?.completed_count || 0}
              />
              {index !== subtopics.length - 1 && <BaseWidgetGenericSeparator />}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  return (
    <div className="base-widget__container">
      <div className="base-widget__heading">
        <div className="base-widget__heading__title-text">
          {t("landing-page.widget-initiatives.title")}{" "}
          <BaseWidgetHeadingTooltip
            info={t("landing-page.widget-initiatives.tooltip")}
          />
        </div>
        <BaseWidgetPeriodControl
          start={start}
          end={end}
          setStart={setStart}
          setEnd={setEnd}
          onSave={onPeriodSelectorSaved}
        />
      </div>
      {content}
    </div>
  );
};

export default InitiativeWidget;
