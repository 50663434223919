import React, { useContext, useEffect, useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import withAuthentication from "../../HOC/withAuthentication";
import {
  BEST_PRACTICES_CATEGORY,
  BEST_PRACTICES_TAG,
} from "../../../config/ROUTES_NAME";
import { Table } from "react-bootstrap";
import BestPracticeCategoryService from "../../../services/ReferenceDataServices/BestPracticeCategoryService";
import BestPracticeCategoryTag from "../../../services/ReferenceDataServices/BestPracticeCategoryTag";
import LoadingSpinner from "../../common/LoadingSpinner";
import ToastContext from "../../../context/ToastContext";
import { useHistory } from "react-router-dom";

const BestPracticeDataTable = () => {
  let history = useHistory();
  const toastContext = useContext(ToastContext);

  const [allBestpracticeCategory, setAllBestpracticeCategory] = useState([]);
  const [allBestpracticeTag, setAllBestpracticeTag] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const headerName = "BEST PRACTICES";
  const [activeKey, setActiveKey] = useState(headerName);

  useEffect(() => {
    setIsLoading(true);
    BestPracticeCategoryService.getAllBestPracticeCategory()
      .then((response) => {
        setIsLoading(false);
        setAllBestpracticeCategory(response);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to get best practice categories.</span>
        );
      });

    BestPracticeCategoryTag.getAllBestPracticeTag()
      .then((response) => {
        setIsLoading(false);
        setAllBestpracticeTag(response);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to get best practice tags.</span>
        );
      });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Accordion
      key={headerName}
      defaultActiveKey={activeKey}
      activeKey={activeKey === headerName ? activeKey : null}
      className="accordion-header"
    >
      <Accordion.Item eventKey={headerName}>
        <Card.Header
          onClick={() =>
            activeKey === headerName
              ? setActiveKey(null)
              : setActiveKey(headerName)
          }
        >
          <div className="body-2-bold">
            {headerName.toUpperCase()}{" "}
            <span className="collapse-toggle">
              {activeKey === headerName ? "(collapse)" : "(expand)"}
            </span>
          </div>
        </Card.Header>
        <Accordion.Body>
          <Table className="reference-data-table section-box">
            <tbody>
              <tr>
                <td className="td-level">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(BEST_PRACTICES_CATEGORY);
                    }}
                  >
                    Best Practice Category
                  </a>
                </td>

                <td className="td-level reference-data-table-entry">
                  {" "}
                  {allBestpracticeCategory.slice(0, 2).map((category) => (
                    <span key={category.id}>{category.name + ", "}</span>
                  ))}
                  {allBestpracticeCategory.length > 0 ? (
                    <span>..etc</span>
                  ) : (
                    <span>No Record Found</span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="td-level">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(BEST_PRACTICES_TAG);
                    }}
                  >
                    Best Practice Tags
                  </a>
                </td>
                <td className="td-level reference-data-table-entry">
                  {allBestpracticeTag.slice(0, 2).map((tag) => (
                    <span key={tag.id}>{tag.name + ", "}</span>
                  ))}
                  {allBestpracticeTag.length > 0 ? (
                    <span>..etc</span>
                  ) : (
                    <span>No Record Found</span>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default withAuthentication(BestPracticeDataTable);
