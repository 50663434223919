import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../services/UtilsService";
import React from "react";
import {SubscriptionStatusEnum} from "./SubscriptionSharedUtils";

const colorMap = {
  [SubscriptionStatusEnum.ACTIVE]: StatusLabel.Colors.green,
  [SubscriptionStatusEnum.CANCELLED]: StatusLabel.Colors.red,
  [SubscriptionStatusEnum.UPCOMING]: StatusLabel.Colors.blue,
  [SubscriptionStatusEnum.EXPIRED]: StatusLabel.Colors.gray,
  [SubscriptionStatusEnum.PENDING]: StatusLabel.Colors.yellow,
};

const SubscriptionStatusBadge = ({ status }) => {
  return (
    <StatusLabel color={colorMap[status]}>
      {StringUtils.capitaliseWord(status)}
    </StatusLabel>
  );
};

export default SubscriptionStatusBadge