import React, { useContext, useEffect, useState } from "react";
import LanguageOptionService from "../../services/ReferenceDataServices/LanguageOptionService";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import LoadingSpinner from "../common/LoadingSpinner";
import { useTranslation } from "react-i18next";
import UserService from "../../services/UserService";
import { LanguageContext } from "../../context/UserProfileContext";

const LanguageSetup = ({}) => {
  const { i18n } = useTranslation();

  const languageContext = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(true);
  const [languageOptions, setLanguageOptions] = useState([]);

  const [languageSelected, setLanguageSelected] = useState(
    languageContext.getLangCode()
  );

  useEffect(() => {
    setIsLoading(true);
    LanguageOptionService.getAllLanguages()
      .then((languageOptions) => {
        setLanguageOptions(languageOptions);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSelectingLanguage = (selection) => {
    const newLangCode = selection.value;
    setLanguageSelected(newLangCode);

    UserService.updateLanguage(newLangCode);
    languageContext.setLangCode(newLangCode);
    i18n.changeLanguage(newLangCode);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  const options = languageOptions
    .map((langOpt) => ({
      value: langOpt.langCode,
      label: langOpt.displayName,
    }))
    .sort((a, b) => LanguageOptionService.langCodeSortFn(a.value, b.value));

  const currentSelection = options.find((o) => o.value === languageSelected);

  return (
    <div style={{ width: "300px" }}>
      <GVDSFormSingleSelect
        value={currentSelection}
        options={options}
        onSelect={onSelectingLanguage}
      />
    </div>
  );
};

export default LanguageSetup;
