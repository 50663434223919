import React, { useCallback, useContext, useEffect, useState } from "react";
import FloatingAssistantContext from "../FloatingAssistantContext";

const GuidedTourContext = React.createContext();

export const GuidedTourProvider = ({ children }) => {
  const floatingAssistantContext = useContext(FloatingAssistantContext);
  const [isRunningGuidedTour, setIsRunningGuidedTour] = useState(false);

  const startGuidedTour = () => {
    floatingAssistantContext.close();
    setIsRunningGuidedTour(true);
  };

  const stopGuidedTour = () => {
    setIsRunningGuidedTour(false);
  };

  useEffect(() => {
    if (floatingAssistantContext.isOpen) {
      stopGuidedTour();
    }
  }, [floatingAssistantContext.isOpen]);

  const store = useCallback(
    {
      isRunning: isRunningGuidedTour,
      startGuidedTour: startGuidedTour,
      stopGuidedTour: stopGuidedTour,
    },
    [isRunningGuidedTour]
  );

  return (
    <GuidedTourContext.Provider value={store}>
      {children}
    </GuidedTourContext.Provider>
  );
};

export default GuidedTourContext;
