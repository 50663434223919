import axios from "axios";
import { API_URL } from "../config/api-config";
import OperationalDataRecordModel from "../components/Data/Operational/OperationalDataRecordModel";
import { DateTimeUtils, HttpResponseService } from "./UtilsService";
import get from "lodash/get";

export default class OperationalService {
  static getAllDatasets = async (resource_id, resource_type) => {
    try {
      const response = await axios.get(API_URL + "/api/v1/operation_datasets", {
        params: { resource_id, resource_type },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  // getting operational data set
  static getOperationDataType = async (facilityId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/facilities/" + facilityId + "/operation_dataset"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static getOperationMetricType = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/operation_meter_type"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static createDataRecord = async (resource_type, resource_id, newRecord) => {
    try {
      const response = await axios.post(
        API_URL +
          "/api/v1/facilities/" +
          newRecord.facility_id +
          "/operation_data_records",
        {
          ...newRecord,
          period_from: DateTimeUtils.getUTCISOString(newRecord["period_from"]),
          period_to: DateTimeUtils.getUTCISOString(newRecord["period_to"]),
        },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static validateSingleDataRecord = async (
    resource_type,
    resource_id,
    facility_id,
    meter_id,
    period_from,
    period_to,
    value,
    comments,
    record_id = null
  ) => {
    const reading = {
      period_from: DateTimeUtils.getUTCISOString(period_from),
      period_to: DateTimeUtils.getUTCISOString(period_to),
      meter_id,
      value,
      comments,
    };
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/facilities/${facility_id}/operation_data_records/validate`,
        reading,
        { params: { resource_type, resource_id, record_id } }
      );
      return {
        dataErrors: get(response.data, "data_errors", {}),
        possibleErrors: get(response.data, "possible_errors", {}),
      };
    } catch (error) {
      throw error;
    }
  };

  static createDataRecords = async (resource_type, resource_id, records) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/operation_data_records",
        records,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  static getAllOperationalDataRecords = async (
    resource_id,
    resource_type,
    start,
    end
  ) => {
    try {
      const params = {
        resource_id,
        resource_type,
        start: DateTimeUtils.getUTCISOString(start),
        end: DateTimeUtils.getUTCISOString(end),
      };
      const response = await axios.get(
        API_URL + "/api/v1/operation_data_records",
        { params }
      );
      let readings = [];
      for (const facilityRecords of response.data) {
        readings = readings.concat(
          facilityRecords["records"].map(
            (r) =>
              new OperationalDataRecordModel(
                facilityRecords["id"],
                facilityRecords["name"],
                r
              )
          )
        );
      }
      return readings;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static getDataRecordsForMeter = async (
    resource_type,
    resource_id,
    meterId,
    start,
    end
  ) => {
    try {
      const response = await axios.get(
        API_URL +
          "/api/v1/operation_meters/" +
          meterId +
          "/operation_data_records",
        {
          params: {
            resource_type,
            resource_id,
            start: DateTimeUtils.getUTCISOString(start),
            end: DateTimeUtils.getUTCISOString(end),
          },
        }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  static createOperationalMeter = async (
    resource_type,
    resource_id,
    facilityId,
    operationMeterTypeId,
    frequency,
    description
  ) => {
    const operationMeter = {
      operationMeterTypeId: operationMeterTypeId,
      frequency: frequency,
      description: description,
    };

    try {
      const response = await axios.post(
        API_URL + "/api/v1/facilities/" + facilityId + "/operation_dataset",
        operationMeter,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getMeterStatus = async (resource_type, resource_id, meterId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/operation_meters/" + meterId + "/status",
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static deleteMeter = async (resource_type, resource_id, meterId) => {
    try {
      const response = await axios.delete(
        API_URL + "/api/v1/operation_datasets/" + meterId + "/meters",
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editMeterEffectivePeriod = async (
    resource_type,
    resource_id,
    selectedMeterId,
    start_date,
    end_date
  ) => {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/operation_meters/${selectedMeterId}/effective_period`,
        {
          start_date: DateTimeUtils.getUTCISOString(start_date),
          end_date: DateTimeUtils.getUTCISOString(end_date),
        },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editMeterDetails = async (resource_type, resource_id, newRecord) => {
    const meterId = newRecord.meterID;
    const operationalData = {
      frequency: newRecord.frequency,
      description: newRecord.description,
    };
    try {
      const response = await axios.put(
        API_URL + "/api/v1/operation_datasets/" + meterId + "/meters",
        operationalData,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteDataRecords = async (resource_type, resource_id, recordIds) => {
    try {
      return await axios.delete(`${API_URL}/api/v1/operation_data_records`, {
        data: { record_ids: recordIds },
        params: { resource_type, resource_id },
      });
    } catch (error) {
      throw error;
    }
  };

  static editDataRecord = async (
    resource_type,
    resource_id,
    recordId,
    { meter_id, period_from, period_to, value, comments }
  ) => {
    const reading = {
      period_from: DateTimeUtils.getUTCISOString(period_from),
      period_to: DateTimeUtils.getUTCISOString(period_to),
      meter_id,
      value,
      comments,
    };
    try {
      const response = await axios.put(
        API_URL + "/api/v1/operation_data_records/" + recordId,
        reading,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static validateDataRecords = async (resource_type, resource_id, records) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/operation_data_records/validate",
        records,
        { params: { resource_type, resource_id } }
      );
      return {
        dataErrors: {
          rowErrors: get(response.data, "data_errors.row_errors", {}),
          errorRowNumbers: get(
            response.data,
            "data_errors.error_row_numbers",
            []
          ),
        },
        possibleErrors: {
          rowErrors: get(response.data, "possible_errors.row_errors", {}),
          errorRowNumbers: get(
            response.data,
            "possible_errors.error_row_numbers",
            []
          ),
        },
      };
    } catch (error) {
      return {};
    }
  };

  static downloadTemplate = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/operation_data_records/template`,
        {
          params: { resource_id, resource_type },
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "BulkOperationInputTemplate.xlsx"
      );
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static getBulkConfig = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/operation_data_records/config`,
        { params: { resource_id, resource_type } }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static updateMeterAlerts = async (
    resource_type,
    resource_id,
    selectedMeterId,
    enableDataGapsAlert,
    enableUnusuallyHighLowAlert
  ) => {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/operation_meters/${selectedMeterId}/meter_alerts`,
        {
          enable_data_gaps_alert: enableDataGapsAlert,
          enable_unusually_high_low_alert: enableUnusuallyHighLowAlert,
        },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateAlertPreferences = async (
    resource_type,
    resource_id,
    selectedMeterId,
    dismissAlertsDate,
    enableDataGapsAlert,
    enableUnusuallyHighLowAlert,
    effectivePeriodStartDate,
    effectivePeriodEndDate
  ) => {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/operation_meters/${selectedMeterId}/alert_preferences`,
        {
          dismiss_alerts_date: DateTimeUtils.getUTCISOString(dismissAlertsDate),
          enable_data_gaps_alert: enableDataGapsAlert,
          enable_unusually_high_low_alert: enableUnusuallyHighLowAlert,
          start_date: DateTimeUtils.getUTCISOString(effectivePeriodStartDate),
          end_date: DateTimeUtils.getUTCISOString(effectivePeriodEndDate),
        },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getMeterLastRecordDate = async (
    meter_id,
    resource_type,
    resource_id
  ) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/operation_meters/${meter_id}/last_record_date`,
        {
          params: { resource_id, resource_type },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
