import axios from "axios";
import { API_URL } from "../config/api-config";
import { FileAttachmentItemModel } from "../components/common/FileAttachments";
import { HELP_ARTICLE_CATEGORY, HELP_ARTICLE_CATEGORY_ORDER } from "../config/constants";

export class HelpArticleListItemModel {
  constructor(articleListItem) {
    this.id = articleListItem.id;
    this.title = articleListItem.title;
    this.articleType = articleListItem.article_type;
    this.category = articleListItem.category;
    this.description = articleListItem.description;
    this.relevantPortalModules = articleListItem.relevant_portal_modules;
  }

  get article_type_name() {
    return this.articleType.name;
  }

  get category_name() {
    return this.category.name;
  }

  isEnglishArticle() {
    return !this.title.startsWith("[")
  }

  isHelpfulTips() {
    return this.category.name.toLowerCase() === HELP_ARTICLE_CATEGORY.HELPFUL_TIPS_PAST_ISSUES.toLowerCase()
  }
}

export class HelpArticleModel {
  constructor(article) {
    this.id = article.id;
    this.title = article.title;
    this.articleType = article.article_type;
    this.category = article.category;
    this.description = article.description;
    this.file = article.file
      ? FileAttachmentItemModel.fromFileDTO(article.file)
      : null;
    this.lastEditedOn = article.last_edited_on;
    this.relevantArticles = article.relevant_articles;
    this.relevantPortalModules = article.relevant_portal_modules;
  }

  get article_type_name() {
    return this.articleType.name;
  }

  get file_name() {
    return this.file.name;
  }

  get category_name() {
    return this.category.name;
  }
}

export default class HelpArticleService {
  static getHelpArticle = async (helpArticleId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/help_articles/${helpArticleId}`
      );

      const article = response.data;

      return new HelpArticleModel(article);
    } catch (error) {
      throw error;
    }
  };

  static getAllHelpArticles = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/help_articles`);
      return response.data
        .map((a) => new HelpArticleListItemModel(a))
        .sort(HelpArticleService.HelpArticleSortFn);
    } catch (error) {
      throw error;
    }
  };

  static getHelpfulTips = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/summary/helpful_tips`
      );
      return response.data.map((a) => new HelpArticleListItemModel(a));
    } catch (error) {
      throw error;
    }
  };

  static HelpArticleSortFn = (article1, article2) => {
    const areBothArticlesEnglish = article1.isEnglishArticle() && article2.isEnglishArticle();
    const areBothArticlesSameLanguage =
      !article1.isEnglishArticle() &&
      !article2.isEnglishArticle() &&
      article1.title.substring(0, 4) === article2.title.substring(0, 4);

    if (areBothArticlesEnglish || areBothArticlesSameLanguage) {
      const sortOrder1 = HELP_ARTICLE_CATEGORY_ORDER.indexOf(
        article1.category_name
      );
      const sortOrder2 = HELP_ARTICLE_CATEGORY_ORDER.indexOf(
        article2.category_name
      );

      if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
        if (sortOrder1 !== sortOrder2) {
          return sortOrder1 - sortOrder2;
        } else {
          return article1.title.localeCompare(article2.title);
        }
      } else if (sortOrder1 !== undefined) {
        return -1;
      } else if (sortOrder2 !== undefined) {
        return 1;
      } else {
        const article1CategoryName = article1.category_name.toLowerCase();
        const article2CategoryName = article2.category_name.toLowerCase();

        if (article1CategoryName === article2CategoryName) {
          return article1.title.localeCompare(article2.title);
        } else if (article1CategoryName === "other") {
          return 1;
        } else if (article2CategoryName === "other") {
          return -1;
        } else {
          return article1CategoryName.localeCompare(article2CategoryName);
        }
      }
    } else {
      // different language
      if (!article1.isEnglishArticle() && !article2.isEnglishArticle()) {
        return article1.title.localeCompare(article2.title);
      } else if (!article1.isEnglishArticle()) {
        return 1;
      } else if (!article2.isEnglishArticle()) {
        return -1;
      } else {
        return article1.title.localeCompare(article2.title);
      }
    }
  };

  static HelpArticleCategorySortFn = (category1, category2) => {
    const sortOrder1 = HELP_ARTICLE_CATEGORY_ORDER.indexOf(category1);
    const sortOrder2 = HELP_ARTICLE_CATEGORY_ORDER.indexOf(category2);

    return sortOrder1 - sortOrder2;
  };

  static HelpfulTipsArticleDateSortFn = (article1, article2) => {
    const titleDateRegex =
      /\d{1,2} (january|february|march|april|may|june|july|august|september|october|november|december) \d{4}$/i;
    const date1 = article1.title.match(titleDateRegex);
    const date2 = article2.title.match(titleDateRegex);
    if (date1 && date2) {
      return new Date(date2) - new Date(date1);
    } else if (date1 && !date2) {
      return -1;
    } else if (!date1 && date2) {
      return 1;
    } else {
      article1.title.localeCompare(article2.title);
    }
  };
}
