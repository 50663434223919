import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

import SelectCurrency from "../../Currency/SelectCurrency";
import EnvironmentalService from "../../../services/EnvironmentalService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import GVFormGroup from "../../common/GVFormGroup";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSFormFieldWithCharacterCount from "../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MeterTips from "../MeterTips";
import { useTranslation } from "react-i18next";
import { FREQUENCY_OPTIONS } from "../../../config/constants";

const initialMeterDetails = {
  meterId: null,
  meterName: "",
  monitoringOnly: "",
  unitId: "",
  frequency: "monthly",
  description: "",
  facilityId: "",
  currencyId: "",
};

const MAX_LENGTH_METER_DESC = 50;

const EnvironmentalMeterDialog = ({
  show,
  close,
  onUpdateSuccessful,
  environmentalSubtopic,
  environmentalType,
  currentMeterDetails,
  facilities,
  unitByType,
  deleteMeterHandler,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);

  const [meterDetails, setMeterDetails] = useState(initialMeterDetails);
  const [isValidated, setIsValidated] = useState(false);
  const [meterErrorMessage, setMeterErrorMessage] = useState();

  const [isEditExistingRecord, setIsEditExistingRecord] = useState(false);
  const [disableFacilitySelect, setDisableFacilitySelect] = useState(false);

  const onCloseDialog = () => {
    resetForm();
    close();
  };

  const resetForm = () => {
    setMeterDetails(initialMeterDetails);
    setMeterErrorMessage(null);
    setIsValidated(false);
  };

  useEffect(() => {
    if (!show) return;
    if (facilities.length === 1) {
      setDisableFacilitySelect(true);
    } else {
      setDisableFacilitySelect(false);
    }

    if (currentMeterDetails) {
      setIsEditExistingRecord(true);
      setMeterDetails({ ...currentMeterDetails });
    } else {
      setIsEditExistingRecord(false);

      const currencyId =
        userInventory.selectedInventory.get &&
        userInventory.selectedTreeNode.get.nodeValue.value.main_attribute
          .default_currency !== null
          ? userInventory.selectedTreeNode.get.nodeValue.value.main_attribute
              .default_currency.id
          : "";

      setMeterDetails({
        ...initialMeterDetails,
        facilityId: facilities.length === 1 ? facilities[0].id : "",
        currencyId: currencyId,
      });
    }
  }, [currentMeterDetails, facilities, show]);

  useEffect(() => {
    const isCurrentUnitNotInOptions =
      meterDetails.unitId &&
      unitByType.map((unit) => unit.id).indexOf(meterDetails.unitId) < 0;

    if (isCurrentUnitNotInOptions) {
      onDropdownChange(initialMeterDetails.unitId, "unitId");
    }
  }, [unitByType]);

  const onTextChange = (value, eventName) => {
    setMeterDetails({ ...meterDetails, [eventName]: value });
  };

  const onDropdownChange = (value, eventName) => {
    setMeterDetails({ ...meterDetails, [eventName]: value });
  };

  const changeMonitoringOnly = (event) => {
    setMeterDetails({ ...meterDetails, monitoringOnly: event.target.checked });
  };

  const isStringEntryEmpty = (entry) => {
    return !entry || entry.length === 0;
  };

  const saveMeter = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsValidated(true);
    setMeterErrorMessage(null);

    if (
      !isStringEntryEmpty(meterDetails.meterName) &&
      meterDetails.unitId &&
      meterDetails.currencyId &&
      meterDetails.frequency &&
      meterDetails.facilityId &&
      (!meterDetails.description ||
        meterDetails.description.length <= MAX_LENGTH_METER_DESC)
    ) {
      if (selectedInventory) {
        try {
          if (isEditExistingRecord) {
            setIsLoading(true);
            EnvironmentalService.editMeter(
              selectedInventory.type,
              selectedInventory.id,
              currentMeterDetails.meterId,
              meterDetails
            )
              .then(() => {
                toastContext.addSuccessToast(
                  <span>Meter Updated successfully</span>
                );
                setIsLoading(false);
                onCloseDialog();
                onUpdateSuccessful();
              })
              .catch((err) => {
                setIsLoading(false);
                if (err.response.status === 400) {
                  setMeterErrorMessage(err.response.data.message);
                } else {
                  toastContext.addFailToast(
                    <span>Failed to update meter</span>
                  );
                }
              });
          } else {
            setIsLoading(true);
            EnvironmentalService.createMeter(
              selectedInventory.type,
              selectedInventory.id,
              environmentalSubtopic,
              environmentalType,
              meterDetails
            )
              .then(() => {
                toastContext.addSuccessToast(
                  <span>Meter added successfully</span>
                );
                setIsLoading(false);
                onCloseDialog();
                onUpdateSuccessful();
              })
              .catch((err) => {
                setIsLoading(false);
                if (err.response != null && err.response.status === 400) {
                  setMeterErrorMessage(err.response.data.message);
                } else {
                  toastContext.addFailToast(<span>Failed to add meter</span>);
                }
              });
          }
        } catch (error) {
          toastContext.addFailToast(
            <span>Something went wrong. Please refresh page.</span>
          );
        }
      }
    }
  };

  const getFacilityOptionByValue = (optionValue) => {
    return getOptionByValueFromIdName(optionValue, facilities);
  };

  const getUnitOptionByValue = (optionValue) => {
    return getOptionByValueFromIdName(optionValue, unitByType);
  };

  const getFrequencyOptionByValue = (optionValue) =>
    FREQUENCY_OPTIONS.find((option) => option.value === optionValue);

  return (
    <GVDSModal
      title={
        isEditExistingRecord
          ? t("data-management.environmental.setup.modal-title-edit-meter")
          : t("data-management.environmental.setup.modal-title-add-meter")
      }
      size={GVDSModal.Size.large}
      show={show}
      onHide={onCloseDialog}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Row>
            <Col md={8} lg={8}>
              <div>
                <GVFormGroup>
                  <Form.Label>
                    {t("data-management.environmental.shared.meter-id")}
                  </Form.Label>
                  <GVDSFormField
                    name="meterName"
                    value={meterDetails.meterName}
                    placeholder="Enter an identifiable name"
                    onInput={(value) => onTextChange(value, "meterName")}
                    statusMetadata={
                      isValidated && isStringEntryEmpty(meterDetails.meterName)
                        ? FormFieldStatusMetadata.getError(
                            "Please provide meter name."
                          )
                        : isValidated &&
                          !isStringEntryEmpty(meterDetails.meterName) &&
                          meterErrorMessage &&
                          meterErrorMessage.name
                        ? FormFieldStatusMetadata.getError(
                            meterErrorMessage.name
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                  />
                </GVFormGroup>

                <GVFormGroup controlId="meterForm.SelectCustom">
                  <Form.Label>{t("shared.facility")}</Form.Label>
                  <GVDSFormSingleSelect
                    placeholder="Select Facility"
                    className="select__facility"
                    value={
                      meterDetails.facilityId
                        ? getFacilityOptionByValue(meterDetails.facilityId)
                        : null
                    }
                    options={facilities.map((facility) => {
                      return { value: facility.id, label: facility.name };
                    })}
                    onSelect={(selectedOption) =>
                      onDropdownChange(selectedOption.value, "facilityId")
                    }
                    statusMetadata={
                      isValidated && !meterDetails.facilityId
                        ? FormFieldStatusMetadata.getError(
                            "Please select a facility."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    isDisabled={disableFacilitySelect}
                  />
                </GVFormGroup>

                <GVFormGroup controlId="monitoringOnly">
                  <Form.Label>
                    {t("data-management.environmental.shared.monitoring-only")}
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    checked={meterDetails.monitoringOnly || ""}
                    label={t(
                      "data-management.environmental.shared.monitoring-only-description"
                    )}
                    onChange={(event) => changeMonitoringOnly(event)}
                  />
                  {!!meterErrorMessage?.for_monitoring_only && (
                    <div className="manual-invalid-feedback mt-1">
                      {meterErrorMessage.for_monitoring_only}
                    </div>
                  )}
                </GVFormGroup>

                <GVFormGroup controlId="selectUnit">
                  <Form.Label>{t("shared-input-label.unit")}</Form.Label>
                  <GVDSFormSingleSelect
                    className="select__unit"
                    placeholder="Select unit"
                    value={
                      meterDetails.unitId
                        ? getUnitOptionByValue(meterDetails.unitId)
                        : null
                    }
                    options={unitByType.map((unit) => {
                      return { value: unit.id, label: unit.name };
                    })}
                    onSelect={(selectedOption) =>
                      onDropdownChange(selectedOption.value, "unitId")
                    }
                    statusMetadata={
                      isValidated && !meterDetails.unitId
                        ? FormFieldStatusMetadata.getError(
                            "Please select a unit."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                  />
                </GVFormGroup>
                <GVFormGroup controlId="frequency">
                  <Form.Label>
                    {t("data-management.environmental.shared.frequency")}
                  </Form.Label>
                  <div className="form-label-description">
                    {t("data-management.shared.meter-frequency-description")}
                    <InfoTooltip
                      info={`${t(
                        "data-management.shared.meter-frequency-example"
                      )}.`}
                    />
                  </div>
                  <GVDSFormSingleSelect
                    className="select__frequency"
                    placeholder="Select frequency"
                    value={
                      meterDetails.frequency
                        ? getFrequencyOptionByValue(meterDetails.frequency)
                        : null
                    }
                    options={FREQUENCY_OPTIONS}
                    onSelect={(selectedOption) =>
                      onDropdownChange(selectedOption.value, "frequency")
                    }
                    statusMetadata={
                      isValidated && !meterDetails.frequency
                        ? FormFieldStatusMetadata.getError(
                            "Please select a frequency."
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                  />
                </GVFormGroup>

                <GVFormGroup>
                  <SelectCurrency
                    selected={meterDetails.currencyId}
                    onCurrencySelected={(value) =>
                      onDropdownChange(value, "currencyId")
                    }
                  />
                </GVFormGroup>
                <GVFormGroup>
                  <Form.Label>{t("shared-input-label.description")}</Form.Label>
                  <span className="optional-form-label mx-1">
                    ({t("shared-input-label.optional")})
                  </span>
                  <GVDSFormFieldWithCharacterCount
                    placeholder="Short reference description for other users"
                    value={meterDetails.description}
                    onInput={(value) => onTextChange(value, "description")}
                    maxLength={MAX_LENGTH_METER_DESC}
                    statusMetadata={FormFieldStatusMetadata.getDefault()}
                  />
                </GVFormGroup>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <MeterTips />
            </Col>
          </Row>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        {isEditExistingRecord && (
          <>
            <GVDSButton
              variant={buttonVariant.destructive_tertiary}
              text={t("shared.delete")}
              onClick={() => deleteMeterHandler(meterDetails)}
              disabled={isLoading}
            />
            <Spacer />
          </>
        )}
        <GVDSButton
          variant={buttonVariant.tertiary}
          text={t("shared-modal.footer.cancel")}
          onClick={onCloseDialog}
          disabled={isLoading}
        />
        <GVDSButton
          variant={buttonVariant.primary}
          text={t("shared-modal.footer.save")}
          onClick={saveMeter}
          disabled={isLoading}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default EnvironmentalMeterDialog;
