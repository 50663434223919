import { LegendItem, LegendLabel } from "@visx/legend";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import React, { useEffect, useRef, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { TOOLTIP_PLACEMENTS } from "../../../../config/style-config";
import Popover from "react-bootstrap/Popover";
import { legendGlyphSizeInPx } from "../../Models/DashboardModels";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";
import { classificationDonutChartConfig } from "./ClassificationDonutChartViewConfig";

const LegendDescription = ({ label, value, totalValue }) => {
  const labelRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (labelRef.current) {
        setIsTruncated(
          labelRef.current.scrollHeight > labelRef.current.clientHeight
        );
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize, true);

    return () => {
      window.removeEventListener("resize", handleResize, true);
    };
  }, []);

  return (
    <div className="d-flex align-items-center">
      {isTruncated ? (
        <OverlayTrigger
          placement={TOOLTIP_PLACEMENTS.TOP}
          overlay={
            <Popover>
              <Popover.Body>{label}</Popover.Body>
            </Popover>
          }
        >
          <div ref={labelRef} className="legend-label">
            {label}
          </div>
        </OverlayTrigger>
      ) : (
        <div ref={labelRef} className="legend-label">
          {label}
        </div>
      )}
      <div className="legend-percentage">
        ({DashboardWidgetSharedUtils.calculatePercentage(value, totalValue)})
      </div>
    </div>
  );
};

const DonutLegend = ({
  legendItems,
  totalValue,
  totalItemCount,
  isExpandLegends,
  onToggleExpandLegends,
  shouldShowAllItems,
}) => {
  return (
    <div>
      {legendItems.map((item, i) => (
        <LegendItem
          key={`donut-legend-${i}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <svg
            width={legendGlyphSizeInPx}
            height={legendGlyphSizeInPx}
            style={{ margin: "2px 8px" }}
          >
            <circle
              fill={item.color}
              r={legendGlyphSizeInPx / 2}
              cx={legendGlyphSizeInPx / 2}
              cy={legendGlyphSizeInPx / 2}
            />
          </svg>
          <LegendLabel align="left" margin="0">
            <LegendDescription
              label={item.label}
              value={item.value}
              totalValue={totalValue}
            />
          </LegendLabel>
        </LegendItem>
      ))}
      {totalItemCount > classificationDonutChartConfig.minLegendItemCount &&
        !shouldShowAllItems && (
          <div className="ms-2">
            <GVDSButton
              className="w-100"
              variant={buttonVariant.secondary}
              text={isExpandLegends ? "Show less" : "Show more"}
              onClick={onToggleExpandLegends}
            />
          </div>
        )}
    </div>
  );
};

export default DonutLegend;
