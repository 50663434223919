import React, { useContext, useEffect, useRef, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import Form from "react-bootstrap/Form";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
  isEndDateBeforeStartDate,
} from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVFormGroup from "../../../common/GVFormGroup";
import Row from "react-bootstrap/Row";
import { Editor } from "react-draft-wysiwyg";
import GVDSFormStartEndDatePicker from "../../../../gvds-components/Forms/GVDSFormStartEndDatePicker";
import moment from "moment/moment";
import GVDSFormFieldWithCharacterCount from "../../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import HomepageAnnouncementAdminService, {
  getPlainTextFromHTML,
} from "../../../../services/HomepageAnnouncementAdminService";
import {
  RTFUtils,
  StringUtils,
  URLUtils,
} from "../../../../services/UtilsService";
import { EditorState } from "draft-js";
import Col from "react-bootstrap/Col";
import HomepageAnnouncementPreview from "./HomepageAnnouncementPreview";
import UnsavedChangePromptModal from "../../../common/UnsavedChangePromptModal";
import { get } from "lodash";
import GVDSTextButton from "../../../../gvds-components/Buttons/GVDSTextButton";
import { InputGroup } from "react-bootstrap";

const MAX_DESCRIPTION_CHARACTERS = 160;

const urlPrefix = "https://";

const customEndPeriodRange = {
  oneWeek: "1W",
  twoWeek: "2W",
  oneMonth: "1M",
};

const announcementInputErrorKeys = {
  maxActivePeriodLimit: "max_active_period_limit",
  oneTimePopupLimit: "one_time_popup_limit",
};

const announcementFormState = {
  create: "create",
  edit: "edit",
  copy: "copy",
};

const ctaLinkTypes = {
  url: "url",
  email: "email",
};

const ctaLinkPrefixes = {
  url: "https://",
  email: "mailto:",
};

const QuickfillButtons = ({ calculateEndPeriod, disabled = false }) => {
  return (
    <div className="ms-auto quickfill-container with-form-label-height">
      <span className="quickfill-label">Quickfill:</span>
      <GVDSTextButton
        className="quickfill-input"
        text={customEndPeriodRange.oneWeek}
        onClick={() => calculateEndPeriod(customEndPeriodRange.oneWeek)}
        disabled={disabled}
      />
      <GVDSTextButton
        className="quickfill-input"
        text={customEndPeriodRange.twoWeek}
        onClick={() => calculateEndPeriod(customEndPeriodRange.twoWeek)}
        disabled={disabled}
      />
      <GVDSTextButton
        className="quickfill-input"
        text={customEndPeriodRange.oneMonth}
        onClick={() => calculateEndPeriod(customEndPeriodRange.oneMonth)}
        disabled={disabled}
      />
    </div>
  );
};

const getCtaLinkPlaceholderByType = (type) => {
  return type === ctaLinkTypes.email
    ? "support@greenviewportal.com"
    : "greenview.sg";
};

const addPrefixToLink = (linkType, link) => {
  if (StringUtils.isNotEmpty(link)) {
    if (linkType === ctaLinkTypes.email) {
      return URLUtils.addMailToPrefix(link);
    } else {
      return URLUtils.addHttpsPrefix(link);
    }
  }
  return link;
};

const removePrefixFromLink = (link) => {
  if (link) {
    return link.replace(/^(https:\/\/|mailto:)/, "");
  }
  return link;
};

const getLinkTypeFromUrl = (url) => {
  if (url && url.startsWith(ctaLinkPrefixes.email)) {
    return ctaLinkTypes.email;
  }
  return ctaLinkTypes.url;
};

const HomepageAnnouncementInputModal = ({
  show,
  setShow,
  currentAnnouncement,
  onSuccess,
  onCloseModal,
}) => {
  const toastContext = useContext(ToastContext);

  const [title, setTitle] = useState("");
  const [descriptionEditorState, setDescriptionEditorState] = useState(
    EditorState.createEmpty()
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOneTimePopup, setIsOneTimePopup] = useState(false);
  const [firstCtaText, setFirstCtaText] = useState("");
  const [firstCtaLinkType, setFirstCtaLinkType] = useState(ctaLinkTypes.url);
  const [firstCtaLink, setFirstCtaLink] = useState("");
  const [secondCtaText, setSecondCtaText] = useState("");
  const [secondCtaLinkType, setSecondCtaLinkType] = useState(ctaLinkTypes.url);
  const [secondCtaLink, setSecondCtaLink] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState(announcementFormState.create);
  const [isValidated, setIsValidated] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [announcementInputErrors, setAnnouncementInputErrors] = useState({});
  const [showPromptUnsavedChangesModal, setShowPromptUnsavedChangesModal] =
    useState(false);

  const editorRef = useRef(null);

  useEffect(() => {
    if (currentAnnouncement) {
      applyCurrentAnnouncementToInputData();
      const isCopiedAnnouncement = currentAnnouncement.id === null;
      if (isCopiedAnnouncement) {
        setFormState(announcementFormState.copy);
        setHasChanges(true);
      } else {
        setFormState(announcementFormState.edit);
        setHasChanges(false);
      }
    } else {
      setFormState(announcementFormState.create);
      resetInputData();
    }
  }, [currentAnnouncement]);

  const applyCurrentAnnouncementToInputData = () => {
    if (currentAnnouncement) {
      setTitle(currentAnnouncement.title);
      setDescriptionEditorState(
        RTFUtils.convertHTMLToEditorState(currentAnnouncement.description)
      );
      setStartDate(moment(currentAnnouncement.startDate).toDate());
      setEndDate(moment(currentAnnouncement.endDate).toDate());
      setIsOneTimePopup(currentAnnouncement.isOneTimePopup);
      setFirstCtaText(currentAnnouncement.firstCtaText);
      setFirstCtaLink(removePrefixFromLink(currentAnnouncement.firstCtaUrl));
      setFirstCtaLinkType(getLinkTypeFromUrl(currentAnnouncement.firstCtaUrl));
      setSecondCtaText(currentAnnouncement.secondCtaText);
      setSecondCtaLink(removePrefixFromLink(currentAnnouncement.secondCtaUrl));
      setSecondCtaLinkType(
        getLinkTypeFromUrl(currentAnnouncement.secondCtaUrl)
      );
    }
  };

  const resetInputData = () => {
    setIsValidated(false);
    setHasChanges(false);
    setTitle("");
    setDescriptionEditorState(EditorState.createEmpty());
    setStartDate(null);
    setEndDate(null);
    setIsOneTimePopup(false);
    setFirstCtaText("");
    setFirstCtaLink("");
    setSecondCtaText("");
    setSecondCtaLink("");
  };

  const onCancelUnsavedPrompt = () => {
    setShowPromptUnsavedChangesModal(false);
    setShow(true);
  };

  const closeModalWithoutPrompt = () => {
    setShowPromptUnsavedChangesModal(false);
    setShow(false);
    setAnnouncementInputErrors({});
    resetInputData();
    onCloseModal();
  };

  const handleClose = () => {
    if (hasChanges) {
      setShow(false);
      setShowPromptUnsavedChangesModal(true);
    } else {
      closeModalWithoutPrompt();
    }
  };

  const calculateEndPeriod = (range) => {
    if (startDate) {
      switch (range) {
        case customEndPeriodRange.oneWeek:
          setEndDate(
            moment(startDate)
              .add(7 - 1, "day")
              .toDate()
          );
          break;
        case customEndPeriodRange.twoWeek:
          setEndDate(
            moment(startDate)
              .add(14 - 1, "day")
              .toDate()
          );
          break;
        case customEndPeriodRange.oneMonth:
          setEndDate(
            moment(startDate).add(1, "month").subtract(1, "day").toDate()
          );
          break;
        default:
          setEndDate(moment(startDate).add(1, "day").toDate());
      }
    }
  };

  const getDescriptionBodyHTML = () => {
    return RTFUtils.isEmpty(descriptionEditorState)
      ? ""
      : RTFUtils.convertEditorStateToHTML(descriptionEditorState);
  };

  const isTitleValid = () => {
    return title && title.length > 0;
  };

  const isDescriptionBodyValid = () => {
    const descriptionHtml = getDescriptionBodyHTML();
    const descriptionCharacterCount =
      getPlainTextFromHTML(descriptionHtml).length;

    const isDescriptionCharCountExceedLimit =
      descriptionCharacterCount > MAX_DESCRIPTION_CHARACTERS;
    const isDescriptionEmpty = RTFUtils.isEmpty(descriptionEditorState);

    return !isDescriptionEmpty && !isDescriptionCharCountExceedLimit;
  };

  const isAnnouncementActivePeriodValid = () => {
    return startDate !== null && endDate !== null;
  };

  const isActionLinkValidByActionText = (
    actionLinkType,
    actionLink,
    actionText
  ) => {
    if (StringUtils.isNotEmpty(actionText)) {
      if (StringUtils.isNotEmpty(actionLink)) {
        if (actionLinkType === ctaLinkTypes.email) {
          return StringUtils.isEmail(actionLink);
        } else {
          return (
            URLUtils.isValidUrl(actionLink) ||
            URLUtils.isValidUrl(URLUtils.addHttpsPrefix(actionLink))
          );
        }
      } else {
        return false;
      }
    }
    return true;
  };

  const isAnnouncementFormValid = () => {
    return (
      isTitleValid() &&
      isDescriptionBodyValid() &&
      isAnnouncementActivePeriodValid() &&
      isActionLinkValidByActionText(
        firstCtaLinkType,
        firstCtaLink,
        firstCtaText
      ) &&
      isActionLinkValidByActionText(
        secondCtaLinkType,
        secondCtaLink,
        secondCtaText
      )
    );
  };

  const saveAnnouncement = () => {
    setIsValidated(true);
    if (isAnnouncementFormValid()) {
      setIsLoading(true);
      const firstCtaFullLink = addPrefixToLink(firstCtaLinkType, firstCtaLink);
      const secondCtaFullLink = addPrefixToLink(
        secondCtaLinkType,
        secondCtaLink
      );

      if (formState === announcementFormState.edit) {
        HomepageAnnouncementAdminService.editAnnouncement(
          currentAnnouncement.id,
          title,
          getDescriptionBodyHTML(),
          startDate,
          endDate,
          isOneTimePopup,
          firstCtaText,
          firstCtaFullLink,
          secondCtaText,
          secondCtaFullLink
        )
          .then(() => {
            toastContext.addSuccessToast(
              <span>Announcement updated successfully.</span>
            );
            closeModalWithoutPrompt();
            if (onSuccess) {
              onSuccess();
            }
            resetInputData();
          })
          .catch((error) => {
            if (error.response.status === 400) {
              setAnnouncementInputErrors(error.response.data.message);
            }
            toastContext.addFailToast(
              <span>Failed to update announcement.</span>
            );
          })
          .finally(() => setIsLoading(false));
      } else {
        HomepageAnnouncementAdminService.createAnnouncement(
          title,
          getDescriptionBodyHTML(),
          startDate,
          endDate,
          isOneTimePopup,
          firstCtaText,
          firstCtaFullLink,
          secondCtaText,
          secondCtaFullLink
        )
          .then(() => {
            toastContext.addSuccessToast(
              <span>Announcement created successfully.</span>
            );
            closeModalWithoutPrompt();
            if (onSuccess) {
              onSuccess();
            }
            resetInputData();
          })
          .catch((error) => {
            if (error.response.status === 400) {
              setAnnouncementInputErrors(error.response.data.message);
            }
            toastContext.addFailToast(
              <span>Failed to create announcement.</span>
            );
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  const getStartEndDatePickerStatusMetadata = () => {
    if (isValidated && !isAnnouncementActivePeriodValid()) {
      return FormFieldStatusMetadata.getError(
        "Start date and end date cannot be empty"
      );
    } else if (isValidated && isEndDateBeforeStartDate(startDate, endDate)) {
      return FormFieldStatusMetadata.getError(
        "End date cannot be before start date"
      );
    } else {
      const maxActivePeriodLimitError = getMaxActivePeriodLimitError();
      if (maxActivePeriodLimitError) {
        const message = maxActivePeriodLimitError["message"];
        const unavailablePeriods =
            maxActivePeriodLimitError["unavailable_periods"];
        const errorDisplay = (
            <div>
              <div>{message}</div>
              <ul className="ps-4">
                {unavailablePeriods.map((period, index) => (
                    <li key={index}>{period}</li>
                ))}
              </ul>
            </div>
        );
        return FormFieldStatusMetadata.getError(errorDisplay);
      }
    }

    return FormFieldStatusMetadata.getDefault();
  };

  const getDescriptionRichTextEditorField = () => {
    const html = getDescriptionBodyHTML();
    const count = getPlainTextFromHTML(html).length;
    const characterCounterText = `${count} / ${MAX_DESCRIPTION_CHARACTERS}`;

    const isDescriptionEmpty = RTFUtils.isEmpty(descriptionEditorState);
    const isValidatedAndDescriptionEmpty = isValidated && isDescriptionEmpty;
    const isDescriptionOverLimit = count > MAX_DESCRIPTION_CHARACTERS;

    let formErrorMessage;
    if (isValidatedAndDescriptionEmpty) {
      formErrorMessage = "Description cannot be empty";
    } else if (isDescriptionOverLimit) {
      formErrorMessage = `${
        count - MAX_DESCRIPTION_CHARACTERS
      } characters over limit.`;
    }

    return (
      <GVFormGroup controlId="description">
        <Form.Label>Description</Form.Label>
        <div className="gvds-form__field-with-char-count__container">
          <div className="gvds-form__char-count">{characterCounterText}</div>
          <Editor
            ref={editorRef}
            editorState={descriptionEditorState}
            onEditorStateChange={(editorState) => {
              setDescriptionEditorState(editorState);
              setHasChanges(true);
            }}
            stripPastedStyles={true}
            placeholder="Enter content here"
            wrapperClassName="rtf-wrapper"
            editorClassName={`rtf-editor ${
              (isValidatedAndDescriptionEmpty || isDescriptionOverLimit) &&
              "error"
            }`}
            toolbar={{
              options: ["inline"],
              inline: {
                inDropdown: false,
                options: ["bold", "italic"],
              },
            }}
          />
          {(isValidatedAndDescriptionEmpty || isDescriptionOverLimit) && (
            <GVDSFormErrorMessage
              status={FormFieldState.error}
              errorMsg={formErrorMessage}
            />
          )}
        </div>
      </GVFormGroup>
    );
  };

  const getOneTimePopupInputLimitError = () => {
    return get(
      announcementInputErrors,
      announcementInputErrorKeys.oneTimePopupLimit
    );
  };

  const getMaxActivePeriodLimitError = () => {
    return get(
      announcementInputErrors,
      announcementInputErrorKeys.maxActivePeriodLimit
    );
  };

  const getUnsavedPromptTitle = () => {
    let promptStateTitle;
    switch (formState) {
      case announcementFormState.edit:
        promptStateTitle = "Edit";
        break;
      case announcementFormState.copy:
        promptStateTitle = "Copy";
        break;
      default:
        promptStateTitle = "Create";
    }
    return `${promptStateTitle} Announcement`;
  };

  let modalContent;

  if (isLoading) {
    modalContent = <LoadingSpinner />;
  } else {
    modalContent = (
      <>
        <Row>
          <Col md={7}>
            <GVFormGroup controlId="title">
              <Form.Label>Title</Form.Label>
              <GVDSFormFieldWithCharacterCount
                name="title"
                value={title}
                onInput={(value) => {
                  setTitle(value);
                  setHasChanges(true);
                }}
                placeholder="Enter announcement title"
                statusMetadata={
                  isValidated && !isTitleValid()
                    ? FormFieldStatusMetadata.getError("Title is required")
                    : FormFieldStatusMetadata.getDefault()
                }
                maxLength={50}
              />
            </GVFormGroup>
            {getDescriptionRichTextEditorField()}
            <GVDSFormStartEndDatePicker
              startDateLabel={<Form.Label>Start date</Form.Label>}
              endDateLabel={
                <Form.Label className="d-flex flex-row">
                  End date{" "}
                  <QuickfillButtons
                    calculateEndPeriod={calculateEndPeriod}
                    disabled={!startDate}
                  />
                </Form.Label>
              }
              startDate={startDate}
              endDate={endDate}
              onChange={(startDate, endDate) => {
                setStartDate(startDate);
                setEndDate(endDate);
                setHasChanges(true);
              }}
              dateFormat="dd MMM yyyy"
              minStartDate={moment().toDate()}
              statusMetadata={getStartEndDatePickerStatusMetadata()}
            />
            <GVFormGroup className="mt-3">
              <Form.Check
                type="checkbox"
                label="Also show as a one-time popup to users"
                name="one-time-popup"
                id="one-time-popup"
                checked={isOneTimePopup || false}
                onChange={() => {
                  setIsOneTimePopup(!isOneTimePopup);
                  setHasChanges(true);
                }}
              />
              {getOneTimePopupInputLimitError() && (
                <GVDSFormErrorMessage
                  status={FormFieldState.error}
                  errorMsg={getOneTimePopupInputLimitError()}
                />
              )}
              <div className="border-bottom"></div>
            </GVFormGroup>
            <GVFormGroup controlId="first-cta-text">
              <Form.Label>1st call to action text</Form.Label>
              <span className="optional-form-label ms-1">(optional)</span>
              <GVDSFormFieldWithCharacterCount
                name="first-cta-text"
                value={firstCtaText}
                onInput={(value) => {
                  setFirstCtaText(value);
                  setHasChanges(true);
                }}
                placeholder="Enter call to action text here"
                maxLength={20}
              />
            </GVFormGroup>
            <GVFormGroup controlId="first-cta-url">
              <Form.Label>1st call to action link</Form.Label>
              <div className="mb-2">
                <Form.Check
                  inline
                  type="radio"
                  label="URL"
                  name="firstCtaType"
                  id="firstCtaTypeUrl"
                  checked={firstCtaLinkType === ctaLinkTypes.url}
                  onChange={() => setFirstCtaLinkType(ctaLinkTypes.url)}
                  disabled={!StringUtils.isNotEmpty(firstCtaText)}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Email"
                  name="firstCtaType"
                  id="firstCtaTypeEmail"
                  checked={firstCtaLinkType === ctaLinkTypes.email}
                  onChange={() => setFirstCtaLinkType(ctaLinkTypes.email)}
                  disabled={!StringUtils.isNotEmpty(firstCtaText)}
                />
              </div>
              <InputGroup>
                <InputGroup.Text>
                  {ctaLinkPrefixes[firstCtaLinkType]}
                </InputGroup.Text>
                <GVDSFormField
                  name="first-cta-link"
                  type={firstCtaLinkType}
                  value={firstCtaLink}
                  onInput={(value) => {
                    setFirstCtaLink(value);
                    setHasChanges(true);
                  }}
                  placeholder={getCtaLinkPlaceholderByType(firstCtaLinkType)}
                  disabled={!StringUtils.isNotEmpty(firstCtaText)}
                  statusMetadata={
                    isValidated &&
                    !isActionLinkValidByActionText(
                      firstCtaLinkType,
                      firstCtaLink,
                      firstCtaText
                    )
                      ? FormFieldStatusMetadata.getError(
                          firstCtaLinkType === ctaLinkTypes.email
                            ? "Valid email is required"
                            : "Valid URL is required"
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </InputGroup>
              <div className="pt-3 border-bottom"></div>
            </GVFormGroup>
            <GVFormGroup controlId="second-cta-text">
              <Form.Label>2nd call to action text</Form.Label>
              <span className="optional-form-label ms-1">(optional)</span>
              <GVDSFormFieldWithCharacterCount
                name="second-cta-text"
                value={secondCtaText}
                onInput={(value) => {
                  setSecondCtaText(value);
                  setHasChanges(true);
                }}
                placeholder="Enter call to action text here"
                maxLength={20}
              />
            </GVFormGroup>
            <GVFormGroup controlId="second-cta-url">
              <Form.Label>2nd call to action link</Form.Label>
              <div className="mb-2">
                <Form.Check
                  inline
                  type="radio"
                  label="URL"
                  name="secondCtaType"
                  id="secondCtaTypeUrl"
                  checked={secondCtaLinkType === ctaLinkTypes.url}
                  onChange={() => setSecondCtaLinkType(ctaLinkTypes.url)}
                  disabled={!StringUtils.isNotEmpty(secondCtaText)}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Email"
                  name="secondCtaType"
                  id="secondCtaTypeEmail"
                  checked={secondCtaLinkType === ctaLinkTypes.email}
                  onChange={() => setSecondCtaLinkType(ctaLinkTypes.email)}
                  disabled={!StringUtils.isNotEmpty(secondCtaText)}
                />
              </div>
              <InputGroup>
                <InputGroup.Text>
                  {ctaLinkPrefixes[secondCtaLinkType]}
                </InputGroup.Text>
                <GVDSFormField
                  name="second-cta-link"
                  type={secondCtaLinkType}
                  value={secondCtaLink}
                  onInput={(value) => {
                    setSecondCtaLink(value);
                    setHasChanges(true);
                  }}
                  placeholder={getCtaLinkPlaceholderByType(secondCtaLinkType)}
                  disabled={!StringUtils.isNotEmpty(secondCtaText)}
                  statusMetadata={
                    isValidated &&
                    !isActionLinkValidByActionText(
                      secondCtaLinkType,
                      secondCtaLink,
                      secondCtaText
                    )
                      ? FormFieldStatusMetadata.getError(
                          secondCtaLinkType === ctaLinkTypes.email
                            ? "Valid email is required"
                            : "Valid URL is required"
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </InputGroup>
            </GVFormGroup>
          </Col>
          <Col md={5}>
            <Form.Label>Announcement Preview</Form.Label>
            <div className="system-toolbox--preview-homepage-announcement__container">
              <HomepageAnnouncementPreview
                title={title}
                descriptionBodyHtml={getDescriptionBodyHTML()}
                firstCtaText={firstCtaText}
                secondCtaText={secondCtaText}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <GVDSModal
        title={`${
          formState === announcementFormState.edit ? "Edit" : "Create"
        } landing page announcement`}
        size={GVDSModal.Size.large}
        show={show}
        onHide={handleClose}
      >
        <GVDSModal.Body>{modalContent}</GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveAnnouncement}
            disabled={!isAnnouncementFormValid() || isLoading}
            text="Save"
          />
        </GVDSModal.Footer>
      </GVDSModal>
      <UnsavedChangePromptModal
        show={showPromptUnsavedChangesModal}
        onCancel={onCancelUnsavedPrompt}
        onProceed={closeModalWithoutPrompt}
        modalName={getUnsavedPromptTitle()}
        message="You have unsaved changes in the form. Your data will be cleared and
            won’t be stored when you leave this page. Are you sure?"
      />
    </>
  );
};

export default HomepageAnnouncementInputModal;
