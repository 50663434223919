// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
import React from "react";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconDots } from "@tabler/icons-react";

const OtherActionDropdownToggle = React.forwardRef(
  (
    {
      children,
      onClick,
      iconClassName = "",
      icon = IconDots,
      tooltipText = null,
      disabled = false,
    },
    ref
  ) => (
    <GVDSIconButton
      ref={ref}
      tooltipText={tooltipText}
      variant={iconButtonVariant.tertiary}
      className="other-action-dropdown-toggle"
      icon={<GVDSIcon Icon={icon} className={iconClassName} />}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      disabled={disabled}
    />
  )
);

export default OtherActionDropdownToggle;
