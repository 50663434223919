import SubscriptionSharedUtils, {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  SubscriptionBillingCycle,
  SubscriptionPricingType,
} from "../SubscriptionSharedUtils";
import Form from "react-bootstrap/Form";
import { GVDSFormErrorMessage } from "../../../../gvds-components/Forms/GVDSFormShared";
import React from "react";

const BillingCycle = ({
  isValidated,
  packagePlan,
  model,
  onDataChange,
  disabled,
}) => {
  const isCustomPlan =
    packagePlan.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;

  return (
    <div className="subscription__billing-cycle__container">
      <div className="subscription__billing-cycle">
        {packagePlan.is_monthly_available && (
          <Form.Check
            id="billing-cycle-monthly"
            label={<strong>Billed Monthly</strong>}
            type="radio"
            checked={model.billingCycle === SubscriptionBillingCycle.MONTHLY}
            onChange={() => {
              SubscriptionSharedUtils.updateSubscriptionTermsBasedOnPlanAndBillingCycle(
                model,
                SubscriptionBillingCycle.MONTHLY,
                packagePlan
              );
              onDataChange();
            }}
            disabled={disabled}
          />
        )}
        {packagePlan.is_yearly_available && (
          <Form.Check
            id="billing-cycle-yearly"
            label={<strong>Billed Yearly</strong>}
            type="radio"
            checked={model.billingCycle === SubscriptionBillingCycle.YEARLY}
            onChange={() => {
              SubscriptionSharedUtils.updateSubscriptionTermsBasedOnPlanAndBillingCycle(
                model,
                SubscriptionBillingCycle.YEARLY,
                packagePlan
              );
              onDataChange();
            }}
            disabled={disabled}
          />
        )}
        {isCustomPlan && (
          <Form.Check
            id="billing-cycle-custom"
            label={<strong>Custom Duration (Auto-Renew Off)</strong>}
            type="radio"
            checked={model.billingCycle === SubscriptionBillingCycle.CUSTOM}
            onChange={() => {
              SubscriptionSharedUtils.updateSubscriptionTermsBasedOnPlanAndBillingCycle(
                model,
                SubscriptionBillingCycle.CUSTOM,
                packagePlan
              );
              onDataChange();
            }}
            disabled={disabled}
          />
        )}
        {packagePlan.is_trial_available && (
          <Form.Check
            id="billing-cycle-trial"
            label={<strong>Free Trial</strong>}
            type="radio"
            checked={model.pricingType === SubscriptionPricingType.FREE}
            onChange={() => {
              SubscriptionSharedUtils.updateSubscriptionTermsBasedOnPlanAndBillingCycle(
                model,
                null,
                packagePlan,
                true
              );
              onDataChange();
            }}
            disabled={disabled}
          />
        )}
      </div>
      {isValidated && !model.isBillingCycleValid() && (
        <GVDSFormErrorMessage errorMsg="This field cannot be empty." />
      )}
    </div>
  );
};

export default BillingCycle;
