import React, { useContext, useEffect, useState } from "react";
import { ClassificationDonutChartUtils } from "./ClassificationDonutChartUtils";
import ClassificationDonutChartView from "./ClassificationDonutChartView";
import DashboardCalculator from "../../Models/DashboardCalculator";
import DashboardDataContext from "../../Context/DashboardDataContext";
import { DashboardColorPalette } from "../DashboardColorPalette";
import { DATA_STATUS } from "../../../../config/constants";
import { DonutChartDataModel } from "../../Models/DashboardModels";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";

const convertBreakdownToDonutChartDataModel = (
  breakdownByClassificationValue
) => {
  const classificationValueSortedFromBiggestValue = Object.keys(
    breakdownByClassificationValue
  ).sort((classificationValue1, classificationValue2) => {
    const value1 = breakdownByClassificationValue[classificationValue1];
    const value2 = breakdownByClassificationValue[classificationValue2];

    return DashboardWidgetSharedUtils.classificationValueByBiggestValueSortFn(
      classificationValue1,
      value1,
      classificationValue2,
      value2
    );
  });

  return classificationValueSortedFromBiggestValue.map(
    (classificationValue, index) =>
      new DonutChartDataModel(
        classificationValue,
        breakdownByClassificationValue[classificationValue],
        DashboardColorPalette[index % DashboardColorPalette.length]
      )
  );
};

const ClassificationDonutChart = ({ element }) => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const [breakdownByClassificationValue, setBreakdownByClassificationValue] =
    useState(null);

  const config = element["config"];
  const subtopic = config["subtopic"];

  const isDataLoaded = dashboardHolderFacade.isAllRequiredDataPresent(
    [subtopic],
    true
  );

  useEffect(() => {
    if (isDataLoaded) {
      setBreakdownByClassificationValue(
        DashboardCalculator.getBreakdownByClassificationValue(
          config,
          dashboardHolderFacade
        )
      );
    }
  }, [dashboardDataContext.lastDataUpdated]);

  if (!isDataLoaded || breakdownByClassificationValue === null) {
    return (
      <ClassificationDonutChartView
        title={element["name"]}
        titleInfoTooltip={element["description"]}
        isLoading={true}
      />
    );
  }

  const unit = DashboardCalculator.getFullUnit(config, dashboardHolderFacade);
  const classificationDonutChartData = convertBreakdownToDonutChartDataModel(
    breakdownByClassificationValue.value
  );

  return (
    <ClassificationDonutChartView
      title={element["name"]}
      titleInfoTooltip={element["description"]}
      hasDataGaps={
        breakdownByClassificationValue.status !== DATA_STATUS.COMPLETE
      }
      period={dashboardHolderFacade.getContinuousTimeDisplay()}
      chartData={classificationDonutChartData}
      totalValue={ClassificationDonutChartUtils.getTotalValue(
        classificationDonutChartData
      )}
      footerLabel={config["footerLabel"]}
      unit={unit}
    />
  );
};

export default ClassificationDonutChart;
