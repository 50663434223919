import React, { Fragment, useContext, useState } from "react";
import Form from "react-bootstrap/Form";

import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import GVFormGroup from "../common/GVFormGroup";
import { getOptionByValueFromIdName } from "../common/Forms/SingleSelect";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSTableDisplay from "../../gvds-components/Table/GVDSTableDisplay";
import InfoTooltip from "../common/Tooltip/InfoTooltip";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import { UserRoleService } from "../../services/UserService";
import { useTranslation } from "react-i18next";

const UserDetailNotConfigured = () => {
  const { t } = useTranslation();

  return (
    <span className="gvds-text--caption__italic gvds-color--gray6">
      {t("admin.team-management.placeholder-user-details-not-configured")}
    </span>
  );
};

const isUserBillingAdmin = (user, billingAdmin) => {
  return user?.id === billingAdmin?.id;
};

export const ListUsersTeamView = ({
  users,
  availableRoles,
  onEdit,
  onDelete,
  onSuccessResponse,
  hasActionPermission = false,
  billingAdmin,
  canEditRole = false,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [userEdited, setUserEdited] = useState(null);
  const [newRoleId, setNewRoleId] = useState(null);
  const [asBillingAdmin, setAsBillingAdmin] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hasBillingAdmin = !!billingAdmin;

  const handleCloseEditModal = () => {
    if (!isLoading) {
      setShowEditModal(false);
      setUserEdited(null);
    }
  };
  const promptEditUserRole = (user) => {
    setUserEdited(user);
    setAsBillingAdmin(isUserBillingAdmin(user, billingAdmin));
    setNewRoleId(user?.display_roles[0].id);
    setShowEditModal(true);
  };
  const editUserRole = () => {
    setIsLoading(true);
    onEdit(userEdited.id, newRoleId, asBillingAdmin)
      .then((updatedContractDetails) => {
        toastContext.addSuccessToast(
          <span>User role successfully updated.</span>
        );
        onSuccessResponse(updatedContractDetails);
        handleCloseEditModal();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to update user role.</span>);
      })
      .finally(() => setIsLoading(false));
  };

  const [userToBeDeleted, setUserToBeDeleted] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => {
    if (!isLoading) {
      setShowDeleteModal(false);
      setUserToBeDeleted(null);
    }
  };
  const promptDeleteUserRole = (user) => {
    setUserToBeDeleted(user);
    setShowDeleteModal(true);
  };
  const deleteUserRole = () => {
    setIsLoading(true);
    onDelete(userToBeDeleted.id)
      .then((updatedResourceDetails) => {
        toastContext.addSuccessToast(<span>User has been removed.</span>);
        onSuccessResponse(updatedResourceDetails);
        handleCloseDeleteModal();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to remove user.</span>);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <GVDSTableDisplay className="user-list">
        <thead>
          <tr>
            <th style={{ width: "30%" }}>
              {t("admin.team-management.shared.table-header-email")}
            </th>
            <th style={{ width: "25%" }}>
              {t("admin.team-management.shared.table-header-name")}
            </th>
            <th style={{ width: "20%" }}>
              {t("admin.team-management.shared.table-header-position")}
            </th>
            <th>{t("admin.team-management.shared.table-header-role")}</th>
            {hasActionPermission && (
              <th style={{ width: "15%" }}>
                {t("shared-input-label.actions")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr key={user.id}>
                <td>
                  {user.email}
                  {isUserBillingAdmin(user, billingAdmin) && (
                    <StatusLabel
                      className="ms-2"
                      color={StatusLabel.Colors.blue}
                    >
                      {t("admin.team-management.shared.billing-admin")}
                    </StatusLabel>
                  )}
                </td>
                <td>
                  {user.full_name ? (
                    user.full_name
                  ) : (
                    <UserDetailNotConfigured />
                  )}
                </td>
                <td>
                  {user.position ? user.position : <UserDetailNotConfigured />}
                </td>
                <td>
                  {user.display_roles.length > 0 && (
                    <>{user.display_roles[0].name}</>
                  )}
                </td>
                {hasActionPermission && (
                  <td>
                    <div className="d-flex">
                      <GVDSIconButton
                        variant={iconButtonVariant.tertiary}
                        onClick={() => promptEditUserRole(user)}
                        icon={<GVDSIcon Icon={IconEdit} />}
                        tooltipText={t(
                          "admin.team-management.shared.edit-role"
                        )}
                        className="edit-role"
                      />
                      <GVDSIconButton
                        variant={iconButtonVariant.destructive}
                        onClick={() => promptDeleteUserRole(user)}
                        icon={<GVDSIcon Icon={IconTrash} />}
                        tooltipText={t(
                          "admin.team-management.shared.remove-user"
                        )}
                        className="remove-user"
                      />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </GVDSTableDisplay>
      <GVDSModal
        show={showEditModal}
        onHide={handleCloseEditModal}
        title={t("admin.team-management.shared.edit-role")}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <GVFormGroup controlId="userEmail">
                <Form.Label>
                  {t("admin.team-management.shared.table-header-email")}
                </Form.Label>
                <Form.Control
                  readOnly
                  plaintext
                  type="text"
                  value={userEdited ? userEdited.email : ""}
                />
              </GVFormGroup>
              <GVFormGroup controlId="userRole" className="mb-4">
                <Form.Label>
                  {t("admin.team-management.shared.table-header-role")}
                </Form.Label>
                <GVDSFormSingleSelect
                  className="select__edit-user-role"
                  value={
                    newRoleId
                      ? getOptionByValueFromIdName(newRoleId, availableRoles)
                      : null
                  }
                  onSelect={(selectedOption) =>
                    setNewRoleId(selectedOption.value)
                  }
                  options={availableRoles
                    ?.sort(UserRoleService.roleSortFn)
                    .map((role) => {
                      return { value: role.id, label: role.name };
                    })}
                  disabled={!canEditRole}
                />
              </GVFormGroup>
              <GVFormGroup controlId="billingAdmin">
                <div className="billing-admin__container">
                  <Form.Label className="me-1">
                    {t("admin.team-management.shared.billing-admin")}
                  </Form.Label>
                  <InfoTooltip
                    info={t(
                      "admin.team-management.tooltip-only-one-billing-admin"
                    )}
                    placement="bottom"
                  />
                  <Form.Check
                    className="billing-admin-switch"
                    type="switch"
                    checked={asBillingAdmin}
                    disabled={isUserBillingAdmin(userEdited, billingAdmin)}
                    onChange={() => setAsBillingAdmin(!asBillingAdmin)}
                  />
                </div>
                {isUserBillingAdmin(userEdited, billingAdmin) ? (
                  <GVDSBanner
                    title={t(
                      "admin.team-management.banner-title-transfer-billing-admin"
                    )}
                    variant={GVDSBanner.Variants.warning}
                  />
                ) : hasBillingAdmin && asBillingAdmin ? (
                  <GVDSBanner
                    title={t(
                      "admin.team-management.banner-title-warning-billing-admin-transfer"
                    )}
                    variant={GVDSBanner.Variants.warning}
                  />
                ) : null}
              </GVFormGroup>
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseEditModal}
            text={t("shared-modal.footer.cancel")}
            disabled={isLoading}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={editUserRole}
            text={t("shared-modal.footer.save")}
            disabled={isLoading}
          />
        </GVDSModal.Footer>
      </GVDSModal>
      <GVDSModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        title={t("admin.team-management.shared.remove-user")}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            t("admin.team-management.remove-user-warning")
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseDeleteModal}
            text={t("shared-modal.footer.cancel")}
            disabled={isLoading}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteUserRole}
            text={t("admin.team-management.shared.button-yes-remove")}
            disabled={isLoading}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export const ListPendingInviteTeamView = ({
  pendingUserInvites,
  onDelete,
  onSuccessResponse,
  hasActionPermission = false,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [inviteToBeDeleted, setInviteToBeDeleted] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setInviteToBeDeleted(null);
  };
  const promptDeleteInvite = (invite) => {
    setInviteToBeDeleted(invite);
    setShowDeleteModal(true);
  };
  const deleteInvite = () => {
    onDelete(inviteToBeDeleted.id)
      .then((updatedResourceDetails) => {
        toastContext.addSuccessToast(<span>Invite has been deleted.</span>);
        onSuccessResponse(updatedResourceDetails);
        handleCloseDeleteModal();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to remove invite.</span>);
      });
  };

  return (
    <>
      <GVDSTableDisplay className="pending-user-list">
        <thead>
          <tr>
            <th style={{ width: "30%" }}>
              {t("admin.team-management.shared.table-header-email")}
            </th>
            <th style={{ width: "25%" }}>
              {t("admin.team-management.shared.table-header-name")}
            </th>
            <th style={{ width: "20%" }}>
              {t("admin.team-management.shared.table-header-position")}
            </th>
            <th>{t("admin.team-management.shared.table-header-role")}</th>
            {hasActionPermission && (
              <th style={{ width: "15%" }}>
                {t("shared-input-label.actions")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {pendingUserInvites.map((invite) => {
            let user = invite.user;
            if (!invite.user) {
              user = { email: invite.email };
            }
            user.role = invite.role;

            return (
              <tr key={invite.id}>
                <td>{user.email}</td>
                <td>
                  {user.full_name ? (
                    user.full_name
                  ) : (
                    <UserDetailNotConfigured />
                  )}
                </td>
                <td>
                  {user.position ? user.position : <UserDetailNotConfigured />}
                </td>
                <td>{user.role.name}</td>
                {hasActionPermission && (
                  <td>
                    <GVDSIconButton
                      variant={iconButtonVariant.destructive}
                      onClick={() => promptDeleteInvite(invite)}
                      icon={<GVDSIcon Icon={IconTrash} />}
                      tooltipText={t("admin.team-management.delete-invite")}
                      className="delete-invite"
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </GVDSTableDisplay>
      <GVDSModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        title={t("admin.team-management.delete-invite")}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          {t("admin.team-management.delete-invite-warning")}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseDeleteModal}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteInvite}
            text={t("admin.team-management.shared.button-yes-remove")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export const UserDetails = ({ email, name, position }) => {
  return (
    <div className="d-flex flex-column">
      <div>{email}</div>
      <div className="gvds-text--caption gvds-color--gray6">
        {name ? name : "-"} | {position ? position : "-"}
      </div>
    </div>
  );
};
