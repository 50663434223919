import { RESOURCES } from "./constants";
import {
  ADMIN_FACILITIES,
  ADMIN_SITE_DETAILS,
  ADMIN_TAGS,
  ADMIN_TEAM,
  BEST_PRACTICES_ASSESS, CERTIFICATION_CONTENT_PAGE,
  CERTIFICATION_HOMEPAGE,
  CERTIFICATIONS,
  ENVIRONMENTAL_DATA,
  ENVIRONMENTAL_DATA_METER,
  ENVIRONMENTAL_DATA_SETUP,
  GOALS_AND_TARGETS,
  GSTC,
  MARKET_PURCHASE_OFFSETS_TRACKER,
  OPERATIONAL_DATA,
  OPERATIONAL_DATA_METER,
  OPERATIONAL_DATA_SETUP,
  PEOPLE_DATA,
  PEOPLE_DATA_METER,
  PEOPLE_DATA_SETUP,
  POLICY_TRACKER,
  REPORT_BEST_PRACTICE_REPORT,
  REPORT_HCMI_METRIC,
  REPORT_INITIATIVES_REPORT,
  REPORT_RISK_ASSESSMENT_REPORT,
  REPORT_STANDARD_REPORT,
  SURVEYS,
  SURVEYS_CREATE,
  VIEW_ALL_INITIATIVES,
  VIEW_SURVEY,
  VIEW_TARGET_DETAILS
} from "./ROUTES_NAME";
import { StringUtils } from "../services/UtilsService";
import { isHotel } from "../context/UserInventoryContext";

class Applicability {
  constructor(
    applicableInventories = [
      RESOURCES.PORTFOLIO,
      RESOURCES.SITE,
      RESOURCES.FACILITY,
    ],
    isHotelOnly = false
  ) {
    this.applicableInventories = applicableInventories;
    this.isHotelOnly = isHotelOnly;
  }

  isApplicable(selectedTreeNode) {
    const nodeValue = selectedTreeNode.nodeValue;
    const inventoryType = nodeValue.type;

    if (!this.applicableInventories.includes(inventoryType)) {
      return false;
    } else if (this.isHotelOnly) {
      return isHotel(selectedTreeNode);
    } else {
      return true;
    }
  }

  getInventoryOnlyMessage() {
    const capitalisedInventories = this.applicableInventories.map((i) =>
      StringUtils.capitaliseWord(i)
    );

    const count = capitalisedInventories.length;
    let message;

    if (count === 1) {
      message = capitalisedInventories[0];
    } else if (count === 2) {
      message = capitalisedInventories.join(" and ");
    } else {
      const allExceptLast = capitalisedInventories.slice(0, -1);
      const lastItem = capitalisedInventories.slice(-1)[0];
      message = allExceptLast.join(", ") + " and " + lastItem;
    }

    return message;
  }

  getApplicableMessage() {
    let message = this.getInventoryOnlyMessage();

    if (this.isHotelOnly) {
      message += " of type: Hotel/Accommodation";
    }

    return message;
  }

  getSwitchWorkspaceMessage() {
    return this.getInventoryOnlyMessage();
  }
}

const siteFacilityOnly = [RESOURCES.SITE, RESOURCES.FACILITY];
const siteOnly = [RESOURCES.SITE];
const portfolioSiteOnly = [RESOURCES.PORTFOLIO, RESOURCES.SITE];

const applicabilityForAll = new Applicability();

const applicabilitySiteFacilityOnly = new Applicability(siteFacilityOnly);
const applicabilitySiteOnly = new Applicability(siteOnly);
const applicabilitySiteOnlyHotelOnly = new Applicability(siteOnly, true);
const applicabilityPortfolioSiteOnly = new Applicability(portfolioSiteOnly);
const applicabilityPortfolioOnly = new Applicability([RESOURCES.PORTFOLIO]);
const applicableHotelOnly = new Applicability(
  [RESOURCES.PORTFOLIO, RESOURCES.SITE, RESOURCES.FACILITY],
  true
);

const routeNameToApplicableType = {
  [ENVIRONMENTAL_DATA]: applicabilitySiteFacilityOnly,
  [ENVIRONMENTAL_DATA_SETUP]: applicabilitySiteFacilityOnly,
  [ENVIRONMENTAL_DATA_METER]: applicabilitySiteFacilityOnly,
  [MARKET_PURCHASE_OFFSETS_TRACKER]: applicabilitySiteFacilityOnly,

  [OPERATIONAL_DATA]: applicabilitySiteFacilityOnly,
  [OPERATIONAL_DATA_SETUP]: applicabilitySiteFacilityOnly,
  [OPERATIONAL_DATA_METER]: applicabilitySiteFacilityOnly,

  [PEOPLE_DATA]: applicabilitySiteOnly,
  [PEOPLE_DATA_SETUP]: applicabilitySiteOnly,
  [PEOPLE_DATA_METER]: applicabilitySiteOnly,

  [VIEW_ALL_INITIATIVES]: applicabilitySiteOnly,

  [REPORT_HCMI_METRIC]: applicableHotelOnly,
  [REPORT_STANDARD_REPORT]: applicabilityPortfolioSiteOnly,
  [REPORT_INITIATIVES_REPORT]: applicabilityPortfolioSiteOnly,
  [REPORT_BEST_PRACTICE_REPORT]: applicabilityPortfolioSiteOnly,
  [REPORT_RISK_ASSESSMENT_REPORT]: applicabilityPortfolioSiteOnly,

  [GOALS_AND_TARGETS]: applicabilityPortfolioSiteOnly,
  [VIEW_TARGET_DETAILS]: applicabilityPortfolioSiteOnly,

  [GSTC]: applicabilitySiteOnlyHotelOnly,

  [CERTIFICATIONS]: applicabilitySiteOnly,
  [CERTIFICATION_HOMEPAGE]: applicabilitySiteOnly,
  [CERTIFICATION_CONTENT_PAGE]: applicabilitySiteOnly,

  [BEST_PRACTICES_ASSESS]: applicabilityPortfolioSiteOnly,
  [POLICY_TRACKER]: applicabilityPortfolioSiteOnly,

  [SURVEYS]: applicabilityPortfolioSiteOnly,
  [SURVEYS_CREATE]: applicabilityPortfolioOnly,
  [VIEW_SURVEY]: applicabilityPortfolioSiteOnly,

  [ADMIN_SITE_DETAILS]: applicabilitySiteOnly,
  [ADMIN_TEAM]: applicabilityPortfolioSiteOnly,
  [ADMIN_FACILITIES]: applicabilitySiteOnly,
  [ADMIN_TAGS]: applicabilityPortfolioSiteOnly,
};

export const getApplicability = (routeName) => {
  const applicability = routeNameToApplicableType[routeName];

  return applicability ?? applicabilityForAll;
};
