import React, { useEffect, useState } from "react";
import uniq from "lodash/uniq";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import { UtilsService } from "../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSTag from "../../../gvds-components/common/GVDSTag";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconUnlink } from "@tabler/icons-react";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  MULTI_SELECT_DATAKEY,
} from "../../../gvds-components/Table/GVDSTable";
import BestPracticeStatusBadge from "../BestPractices/BestPracticeStatusBadge";

const BestPracticeQuestionLink = ({
  allQuestions,
  linkedQuestions,
  onLinked,
}) => {
  const [showLinkBestPracticeModal, setShowLinkBestPracticeModal] =
    useState(false);
  const closeLinkBestPracticeModal = () => {
    setShowLinkBestPracticeModal(false);
  };

  const showQuestionSelector = () => {
    setShowLinkBestPracticeModal(true);
  };

  const saveLinkedQuestions = () => {
    closeLinkBestPracticeModal();
    onLinked(selectedQuestions);
  };

  const removeLinkedQuestion = (link_removed_qn) => {
    const newSelectedQuestions = selectedQuestions.filter(
      (q) => q.id !== link_removed_qn.id
    );
    setSelectedQuestions(newSelectedQuestions);
    onLinked(newSelectedQuestions);
  };

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const toggleSelection = (q) => {
    setSelectedQuestions(UtilsService.toggleItem(selectedQuestions, q));
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    allQuestions,
    SystemToolboxSearchKeysByPageName.guidanceArticleBestPracticeQuestion
  );

  useEffect(() => {
    const linkedQnIds = linkedQuestions.map((q) => q.id);
    setSelectedQuestions(
      allQuestions.filter((q) => linkedQnIds.indexOf(q.id) >= 0)
    );
  }, [allQuestions, linkedQuestions]);

  const selectionModalColumns = [
    {
      header: "",
      dataKey: MULTI_SELECT_DATAKEY,
      renderer: (q) => {
        return (
          <div>
            <Form.Check
              checked={selectedQuestions.indexOf(q) >= 0}
              type="checkbox"
              key={q.id}
              id={q.id}
              label=""
              onChange={() => toggleSelection(q)}
            />
          </div>
        );
      },
    },
    {
      header: "Question",
      dataKey: "question_text",
    },
    {
      header: "Categories",
      dataKey: "categoryNames",
    },
    {
      header: "Tags",
      dataKey: "tagNames",
      renderer: (q) =>
        q.tagNames.map((tag) => (
          <GVDSTag variant={GVDSTag.Variants.system} key={tag}>
            {tag}
          </GVDSTag>
        )),
    },
    {
      header: "Status",
      dataKey: "status",
      renderer: (q) => <BestPracticeStatusBadge status={q.status} />,
    },
  ];

  return (
    <>
      <PageHeader>
        <PageHeader.Title>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.tertiary}
            className="link-best-practice"
            onClick={showQuestionSelector}
            text={
              (linkedQuestions.length === 0 ? "Link" : "Edit") +
              " Best Practice Questions"
            }
          />
        </PageHeader.Title>
      </PageHeader>
      <LinkedBestPracticeQuestion
        linkedQuestions={linkedQuestions}
        removeLinkedQuestion={removeLinkedQuestion}
      />
      <GVDSModal
        title="Link Best Practice Questions"
        show={showLinkBestPracticeModal}
        onHide={closeLinkBestPracticeModal}
        size={GVDSModal.Size.large}
      >
        <GVDSModal.Body>
          <GVDSTableCtrlContainer>
            <FilterSearchBox
              placeholder="Search Best Practice Question"
              value={searchText}
              onInput={setSearchText}
            />
            <GVDSTableCtrlMultiSelect
              options={uniq(allQuestions.map((q) => q.categoryNames).flat())}
              prefix="Categories"
              onChange={(filterKs) =>
                setFilterKeys({ ...filterKeys, categoryNames: filterKs })
              }
            />
            <GVDSTableCtrlMultiSelect
              options={uniq(allQuestions.map((q) => q.tagNames).flat())}
              prefix="Tags"
              onChange={(filterKs) =>
                setFilterKeys({ ...filterKeys, tagNames: filterKs })
              }
            />
            <GVDSPagination
              startIndex={startIndex}
              endIndex={endIndex}
              total={totalDataLength}
              onChange={onPaginationChange}
            />
          </GVDSTableCtrlContainer>
          <GVDSTable
            columns={selectionModalColumns}
            dataToDisplay={currentPageData}
            startIndex={startIndex}
            sortKeys={sortKeys}
            setSortKeys={setSortKeys}
          />
          {filteredSortedData.length === 0 && (
            <div className="table__no_content">No record found</div>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeLinkBestPracticeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveLinkedQuestions}
            text="Link"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};
export default BestPracticeQuestionLink;

const LinkedBestPracticeQuestion = ({
  linkedQuestions,
  removeLinkedQuestion,
}) => {
  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(linkedQuestions, null);

  const displayColumns = [
    { header: "Question", dataKey: "question_text" },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (q) => {
        return (
          <Row>
            <Col>
              <GVDSIconButton
                variant={iconButtonVariant.destructive}
                onClick={() => {
                  removeLinkedQuestion(q);
                }}
                icon={<GVDSIcon Icon={IconUnlink} />}
                tooltipText="Unlink"
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <GVDSTableCtrlContainer>
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={displayColumns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {linkedQuestions.length === 0 && (
        <div className="table__no_content">No best practices linked yet.</div>
      )}
    </>
  );
};
