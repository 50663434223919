import {
  CONTRACT_QUERY_KEY,
  SELECTED_QUERY_KEY,
  TYPE_DELIMITER,
} from "../../context/UserInventoryContext";
import { URLUtils } from "../../services/UtilsService";

export const REPORT_PARAMS_QUERY_KEY = "reportParams";
export const MODAL_QUERY_KEY = "modal";
export const PAGE_PARAMS_QUERY_KEY = "pageParams";
export const getRedirectURLWithCurrentParam = (path, location) => {
  return path + "?" + getForwardedQuery(location);
};

export const getQueryHandler = (location) => {
  return new URLSearchParams(location.search);
};

export const getForwardedQuery = (location) => {
  const queryKeysToBeForwarded = [
    SELECTED_QUERY_KEY,
    CONTRACT_QUERY_KEY,
    MODAL_QUERY_KEY,
  ];

  const queryHandler = getQueryHandler(location);

  for (const key of queryHandler.keys()) {
    if (!queryKeysToBeForwarded.includes(key)) {
      queryHandler.delete(key);
    }
  }

  return queryHandler.toString();
};

export const updatePathWithSelectedContractId = (
  history,
  location,
  selectedContractId,
  isRemoveSelectedQuery
) => {
  const queryHandler = getQueryHandler(location);
  queryHandler.set(CONTRACT_QUERY_KEY, `${selectedContractId}`);

  if (isRemoveSelectedQuery) {
    queryHandler.delete(SELECTED_QUERY_KEY);
  }
  location.search = queryHandler.toString();

  return URLUtils.replacePathWithoutRerendering(location.pathname, location);
};

export const getPathWithUpdatedInventory = (
  location,
  resource_type,
  resource_id,
  contract_id
) => {
  const queryHandler = getQueryHandler(location);
  queryHandler.set(
    SELECTED_QUERY_KEY,
    `${resource_type}${TYPE_DELIMITER}${resource_id}`
  );

  if (contract_id) {
    queryHandler.set(CONTRACT_QUERY_KEY, `${contract_id}`);
  }

  return `${location.pathname}?${queryHandler.toString()}`;
};

export const generateSelectedQueryParam = (
  resourceType,
  resourceId,
  contractId
) => {
  const params = {
    [SELECTED_QUERY_KEY]: `${resourceType}${TYPE_DELIMITER}${resourceId}`,
    [CONTRACT_QUERY_KEY]: `${contractId}`,
  };
  return new URLSearchParams(params).toString();
};

export const removeParamFromQuery = (location, paramKey) => {
  const queryHandler = getQueryHandler(location);

  if (queryHandler.get(paramKey) !== null) {
    queryHandler.delete(paramKey);
  }
  location.search = queryHandler.toString();
  URLUtils.replacePathWithoutRerendering(location.pathname, location);
};

export const getPathWithTabKey = (pathWithQueryParam, tabKey) => {
  const hashParam = !!tabKey ? `#${tabKey}` : "";
  return pathWithQueryParam + hashParam;
};

export const generateReportParamsSearchURL = (reportParamsObj) => {
  const encodedParams = encodeURIComponent(
    '"' + JSON.stringify(reportParamsObj) + '"'
  );

  return `${REPORT_PARAMS_QUERY_KEY}=${encodedParams}`;
};

export const updateQueryWithModalParam = (location, key, value) => {
  const queryHandler = getQueryHandler(location);
  queryHandler.set(MODAL_QUERY_KEY, `${key}${TYPE_DELIMITER}${value}`);

  return queryHandler;
};

export const consumeModalParamFromQuery = (location) => {
  const queryHandler = getQueryHandler(location);
  const modalQuery = queryHandler.get(MODAL_QUERY_KEY);

  queryHandler.delete(MODAL_QUERY_KEY);
  location.search = queryHandler.toString();

  URLUtils.replacePathWithoutRerendering(location.pathname, location);

  return modalQuery;
};

const generatePageParamsSearchURL = (pageParamsObj) => {
  const encodedParams = encodeURIComponent(
    '"' + JSON.stringify(pageParamsObj) + '"'
  );

  return `${PAGE_PARAMS_QUERY_KEY}=${encodedParams}`;
};

export const getURLWithPageParams = (location, path, pageParamsObj) => {
  let redirectUrl = getRedirectURLWithCurrentParam(path, location);

  if (!!pageParamsObj) {
    redirectUrl += `&${generatePageParamsSearchURL(pageParamsObj)}`;
  }

  return redirectUrl;
};

export const extractPageParamsObjectFromURL = (location) => {
  const queryHandler = getQueryHandler(location);

  const pageParamsQueryValue = queryHandler.get(PAGE_PARAMS_QUERY_KEY);
  if (!pageParamsQueryValue) {
    return null;
  }
  const pageParamsJSONStringWithoutSurroundingQuotes =
    pageParamsQueryValue.substring(1, pageParamsQueryValue.length - 1);
  return JSON.parse(pageParamsJSONStringWithoutSurroundingQuotes);
};
