import axios from "axios";
import { API_URL } from "../../config/api-config";

export class DashboardDataService {
  static async getDashboardData(resource_type, resource_id, year, subtopic, unit_id) {
    try {
      const params = {
        resource_type,
        resource_id,
      };
      const payload = {
        year,
        subtopic,
        unit_id
      };

      const response = await axios.post(
        `${API_URL}/api/v1/dashboard-data`,
        payload,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
