import GVDSBanner from "../../gvds-components/common/GVDSBanner";
import React from "react";
import moment from "moment";
import { DateTimeUtils } from "../../services/UtilsService";
import { useTranslation } from "react-i18next";

const MeterPossibleIssuesBanner = ({
  dataIssues,
  dataRequests,
  meter,
  showAlertPreferencesModal,
}) => {
  const { t } = useTranslation();

  const possibleIssues = [];
  possibleIssues.push(...dataIssues);

  const flattenDataRequests = dataRequests
    .sort((a, b) => (moment(a.deadline).isBefore(b.deadline) ? -1 : 1))
    .map((dr) =>
      dr
        .getMissingDataGapsForMeter(meter.id, meter.start_date, meter.end_date)
        .map((gap) => {
          return `Data Requested between 
                  ${DateTimeUtils.formatUTCDate(gap["start"])} - 
                  ${DateTimeUtils.formatUTCDate(gap["end"])} (Deadline: 
                  ${DateTimeUtils.formatUTCDate(dr.deadline)})`;
        })
    )
    .flat();
  possibleIssues.push(...flattenDataRequests);

  return (
    <GVDSBanner
      className="meter-details__possible-issues-banner"
      title={t(
        "data-management.shared.alert-preferences.possible-issues.title"
      )}
      variant={GVDSBanner.Variants.warning}
    >
      {possibleIssues.length === 1 ? (
        <div className="mb-1">{possibleIssues[0]}</div>
      ) : (
        <ul>
          {possibleIssues.map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
      )}
      <div className="cta-alert-preference" onClick={showAlertPreferencesModal}>
        {t(
          "data-management.shared.alert-preferences.possible-issues.alert-banner-link"
        )}
      </div>
    </GVDSBanner>
  );
};

export default MeterPossibleIssuesBanner;
