import axios from "axios";
import { API_URL } from "../config/api-config";
import PolicyModel from "../components/PolicyTracker/PolicyModel";
import { HttpResponseService } from "./UtilsService";

export default class PolicyTrackerService {
  static getPolicies = async (resourceType, resourceId) => {
    try {
      const params = {
        resource_id: resourceId,
        resource_type: resourceType,
      };

      const response = await axios.get(`${API_URL}/api/v1/policy-tracker`, {
        params,
      });
      return response.data.map((policy) => PolicyModel.fromDTO(policy));
    } catch (error) {
      throw error;
    }
  };

  static getPolicy = async (resourceType, resourceId, policyId) => {
    try {
      const params = {
        resource_id: resourceId,
        resource_type: resourceType,
      };

      const response = await axios.get(
        `${API_URL}/api/v1/policy-tracker/${policyId}`,
        {
          params,
        }
      );

      return PolicyModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static downloadPolicy = async (resourceId, resourceType, policyId) => {
    const params = {
      resource_id: resourceId,
      resource_type: resourceType,
    };

    try {
      const response = await axios.get(
        `${API_URL}/api/v1/policy-tracker/download/${policyId}`,
        {
          params: params,
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response
      );
    } catch (error) {
      throw error;
    }
  };
}
