import axios from "axios";
import { API_URL } from "../../config/api-config";

export const UnitConversionTypeEnum = {
  ENERGY: "energy",
  LIQUID_VOLUME: "liquid volume",
  DRY_VOLUME: "dry volume",
  VOLUME: "volume",
  WEIGHT: "weight",
  AREA: "area",
  EMISSION: "emission",
  FLOWRATE: "flowrate",
  UNIT: "unit",
  UNITLESS: "unitless",
  OTHER: "other",
};

export class UnitConversionList {
  constructor(unit) {
    this.unit_name = unit.unit_name;
    this.name = unit.name;
    this.unit_type = unit.unit_type;
    this.conversion_factor_to_base_unit = unit.conversion_factor_to_base_unit;
  }
  get get_unit_type() {
    return this.unit_type;
  }
}

export default class UnitsService {
  static getUnitConversionFactor = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/unit_conversions"
      );
      return response.data.map((a) => new UnitConversionList(a));
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}
