import axios from "axios";
import { API_URL } from "../config/api-config";
import { DateTimeUtils } from "./UtilsService";

export default class SummaryService {
  static async getSocialImpactSummary(
    resource_type,
    resource_id,
    start,
    end,
    abortControllerSignal
  ) {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/summary/social_impact",
        {
          params: {
            resource_id,
            resource_type,
            start: DateTimeUtils.getUTCISOString(start),
            end: DateTimeUtils.getUTCISOString(end),
          },
          signal: abortControllerSignal,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getInitiativeSummary(resource_type, resource_id, start, end) {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/summary/initiatives",
        {
          params: {
            resource_id,
            resource_type,
            start: DateTimeUtils.getUTCISOString(start),
            end: DateTimeUtils.getUTCISOString(end),
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getEnvironmentalMetricsConfig(
    resource_type,
    resource_id,
    abortControllerSignal
  ) {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/summary/environmental/config",
        {
          params: {
            resource_id,
            resource_type,
          },
          signal: abortControllerSignal,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPerformanceGroupSummary(
    resource_type,
    resource_id,
    start,
    end,
    subtopic_name,
    performance_group_name,
    unit_id,
    area_unit_id,
    use_location_based,
    intensity_metric,
    abortControllerSignal
  ) {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/summary/environmental/performance_group",
        {
          params: {
            resource_type,
            resource_id,
            start: DateTimeUtils.getUTCISOString(start),
            end: DateTimeUtils.getUTCISOString(end),
            subtopic_name,
            performance_group_name,
            unit_id,
            area_unit_id,
            use_location_based,
            intensity_metric,
          },
          signal: abortControllerSignal,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getWasteDiversionSummary(
    resource_type,
    resource_id,
    start,
    end,
    abortControllerSignal
  ) {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/summary/environmental/waste_diversion_rate",
        {
          params: {
            resource_type,
            resource_id,
            start: DateTimeUtils.getUTCISOString(start),
            end: DateTimeUtils.getUTCISOString(end),
          },
          signal: abortControllerSignal,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getTargetSummary(resource_type, resource_id, start, end) {
    try {
      const response = await axios.get(API_URL + "/api/v1/summary/targets", {
        params: {
          resource_id,
          resource_type,
          start: DateTimeUtils.getUTCISOString(start),
          end: DateTimeUtils.getUTCISOString(end),
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
