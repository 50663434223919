import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import GVFormGroup from "../../../common/GVFormGroup";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const InputCertificationsDialog = (props) => {
  const {
    show,
    cancel,
    isEditExisting,
    handleInputChange,
    removeOption,
    addOption,
    handleInputChangeName,
    inputList,
    certificationName,
    saveCertification,
    isValidated,
  } = props;

  const certificationOptionsWithoutDeletedOnes = inputList.filter(
    (option) => !option.id.startsWith("del-")
  );

  return (
    <>
      <GVDSModal
        show={show}
        onHide={cancel}
        title={isEditExisting ? "Edit Certification" : "Create Certification"}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          <GVFormGroup>
            <Form.Label>Certification Name</Form.Label>
            <GVDSFormField
              className="company_name_input"
              placeholder="Enter Certification Name"
              value={certificationName}
              onInput={(value, e) => handleInputChangeName(e)}
              statusMetadata={
                isValidated && certificationName === ""
                  ? FormFieldStatusMetadata.getError(
                      "Please provide certification name."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <Form.Label>Levels</Form.Label>
          {certificationOptionsWithoutDeletedOnes.map((option, index) => (
            <div key={index}>
              <Row>
                <Col>
                  <GVFormGroup style={{ width: "240px" }}>
                    <GVDSFormField
                      className="company_name_input"
                      value={option.name}
                      disabled={option.edit_status || ""}
                      onInput={(value, e) => handleInputChange(e, option.id)}
                      statusMetadata={
                        isValidated &&
                        (option.name === undefined || option.name === "")
                          ? FormFieldStatusMetadata.getError(
                              "Please provide certification level."
                            )
                          : FormFieldStatusMetadata.getDefault()
                      }
                    />
                  </GVFormGroup>
                </Col>
                <Col>
                  {option.edit_status === true ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          This cannot be deleted as it is currently in use. To
                          delete this, remove all associations with it in the
                          portal.
                        </Tooltip>
                      }
                    >
                      <span>
                        <GVDSIconButton
                          variant={iconButtonVariant.destructive}
                          icon={
                            <GVDSIcon
                              Icon={IconTrash}
                              className="color-gray85"
                            />
                          }
                        />
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <GVDSIconButton
                      variant={iconButtonVariant.destructive}
                      onClick={(e) => {
                        removeOption(option);
                      }}
                      icon={<GVDSIcon Icon={IconTrash} />}
                      tooltipText="Delete"
                    />
                  )}
                </Col>
              </Row>
            </div>
          ))}
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => addOption()}
            icon={<GVDSIcon Icon={IconPlus} />}
            text="Add Level"
          />
          {isValidated &&
            certificationOptionsWithoutDeletedOnes.length === 0 && (
              <div className="manual-invalid-feedback">
                Please provide certification level.
              </div>
            )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            text="Cancel"
            onClick={cancel}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            text="Save"
            onClick={saveCertification}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default InputCertificationsDialog;
