import React from "react";
import ContinuousTimeMonthInclusionControlView from "./ContinuousTimeMonthInclusionControlView";
import ContinuousTimePeriodControlView from "./ContinuousTimePeriodControlView";

const ContinuousTimeFilterView = ({
  startPeriod,
  endPeriod,
  activeQuickfillValue,
  includedMonthsByYear,
  onSavePeriod,
  onSaveMonthInclusion,
}) => {
  return (
    <div className="dashboard-filter__continuous-time-period-control__display">
      <ContinuousTimePeriodControlView
        startPeriod={startPeriod}
        endPeriod={endPeriod}
        activeQuickfillValue={activeQuickfillValue}
        onSave={onSavePeriod}
      />
      <ContinuousTimeMonthInclusionControlView
        startPeriod={startPeriod}
        endPeriod={endPeriod}
        includedMonthsByYear={includedMonthsByYear}
        onSave={onSaveMonthInclusion}
      />
    </div>
  );
};

export default ContinuousTimeFilterView;
