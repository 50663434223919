import axios from "axios";
import { Auth } from "aws-amplify";

export const setupAxios = () => {
  axios.interceptors.request.use(
    async (config) => {
      const currentSession = await Auth.currentSession();
      config.headers.Authorization = `Bearer ${currentSession
        .getAccessToken()
        .getJwtToken()}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const isRequestAborted = (error) => {
  return axios.isCancel(error);
}