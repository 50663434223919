import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { Annotation, Connector, Label } from "@visx/annotation";
import { GVDSColors } from "../../../../styles/gvds-colors";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { TOOLTIP_PLACEMENTS } from "../../../../config/style-config";
import Popover from "react-bootstrap/Popover";
import { LegendItem, LegendLabel } from "@visx/legend";
import {
  DashboardEnvClassificationDataTooltipModel,
  legendGlyphSizeInPx,
} from "../../Models/DashboardModels";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";
import { classificationDonutChartConfig } from "./ClassificationDonutChartViewConfig";
import { ClassificationDonutChartUtils } from "./ClassificationDonutChartUtils";
import DashboardEnvClassificationDataTooltip from "../DashboardEnvClassificationDataTooltip";

const ChartPopoverDescription = ({ label, value, unit, totalValue }) => {
  return (
    <div className="dashboard--classification-donut-chart__chart-popover">
      <div className="chart-popover-label">{label}</div>
      <div className="chart-popover-value ms-1">
        <strong>
          {ClassificationDonutChartUtils.getTypeDisplayValue(value)}
        </strong>{" "}
        {unit} (
        {DashboardWidgetSharedUtils.calculatePercentage(value, totalValue)})
      </div>
    </div>
  );
};

const ChartPopoverContent = ({ legendItems, unit, totalValue }) => {
  return (
    <div>
      {legendItems.map((item, index) => (
        <LegendItem
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <svg
            width={legendGlyphSizeInPx}
            height={legendGlyphSizeInPx}
            style={{ margin: "2px 8px" }}
          >
            <circle
              fill={item.color}
              r={legendGlyphSizeInPx / 2}
              cx={legendGlyphSizeInPx / 2}
              cy={legendGlyphSizeInPx / 2}
            />
          </svg>
          <LegendLabel align="left" margin="0">
            <ChartPopoverDescription
              label={item.label}
              value={item.value}
              unit={unit}
              totalValue={totalValue}
            />
          </LegendLabel>
        </LegendItem>
      ))}
    </div>
  );
};

const getAnnotationLocationByIndex = (arcs, path, labelOffset, thickness) => {
  /*
		There is chance more than 2 annotations could overlap in 1 location.
		So need to compare current annotation with all next annotations to make sure no more annotation overlap with current annotation.
		Once a non overlap annotation found, could stop the loop.
		There is chance that shifted annotation could overlap with next annotation, so need to repeat for each annotation.

		The heuristic of the pie chart it starts with biggest value to smallest value - meaning
		the tail end are always full of small values, hence more overlaps. Hence, some special handling:
		- for pie that's beyond certain "angle" (0 = 12 o'clock, clockwise), move the label to the right as likely the right is empty
		- The label is handled in desc sort. The position of these small sections are on the top of the chart,
			so it's better to push down the annotation from top to bottom
	 */
  const locations = arcs.map((arc) => {
    const [centroidX, centroidY] = path.centroid(arc);

    return {
      x: centroidX,
      y: centroidY,
      dx: (centroidX < 0 ? labelOffset * -1 : labelOffset) - centroidX,
      dy: (centroidY < 0 ? -1 : 1) * (thickness * 0.7),
    };
  });

  // put some tail values annotation to right side
  const tailArcs = arcs.filter(
    (arc) =>
      arc.startAngle >=
      classificationDonutChartConfig.annotationMaxAngleInRadForRightSideLoc
  );
  const halfIndex = Math.floor(tailArcs.length / 2);
  const lastFewTailArcsSortedFromLast = tailArcs
    .slice(halfIndex, tailArcs.length)
    .reverse();
  const locationsMaxIndex = locations.length - 1;

  lastFewTailArcsSortedFromLast.map((arc, i) => {
    const [centroidX, centroidY] = path.centroid(arc);

    locations[locationsMaxIndex - i] = {
      x: centroidX,
      y: centroidY,
      dx: labelOffset - centroidX,
      dy: (centroidY < 0 ? -1 : 1) * (thickness * 0.7),
    };
  });

  // re-calculate clashing labels
  const fixClashingLabels = (locationDataSortedTopDown) => {
    for (
      let locationDataArrayIndex = 0;
      locationDataArrayIndex < locationDataSortedTopDown.length - 1;
      locationDataArrayIndex++
    ) {
      let nextLocationDataArrayIndex = locationDataArrayIndex + 1;
      let isOverlap = true;

      while (isOverlap) {
        const index = locationDataSortedTopDown[locationDataArrayIndex].index;
        const nextIndex =
          locationDataSortedTopDown[nextLocationDataArrayIndex].index;

        const yCurrent = locations[index].y + locations[index].dy;
        const yNext = locations[nextIndex].y + locations[nextIndex].dy;
        const ySpaceBetween = Math.abs(yNext - yCurrent);

        const xCurrent = locations[index].x + locations[index].dx;
        const xNext = locations[nextIndex].x + locations[nextIndex].dx;
        const isOnSameXSide = Math.sign(xCurrent) === Math.sign(xNext);

        if (!isOnSameXSide) {
          break;
        }

        if (
          ySpaceBetween <
          classificationDonutChartConfig.annotationYOverlapTolerance
        ) {
          locations[nextIndex].dy =
            locations[nextIndex].dy +
            classificationDonutChartConfig.annotationTextHeightInPx;
          nextLocationDataArrayIndex++;

          if (nextLocationDataArrayIndex === locationDataSortedTopDown.length) {
            break;
          }
        } else {
          isOverlap = false;
        }
      }
    }
  };

  const locationsWithIndex = locations.map((loc, index) => ({
    loc,
    index,
    labelX: loc.x + loc.dx,
    labelY: loc.y + loc.dy,
  }));
  const leftLabelLocationsSortedTopDown = locationsWithIndex
    .filter((locData) => locData.labelX < 0)
    .sort((loc1, loc2) => loc1.labelY - loc2.labelY);
  const rightLabelLocationsSortedTopDown = locationsWithIndex
    .filter((locData) => locData.labelX >= 0)
    .sort((loc1, loc2) => loc1.labelY - loc2.labelY);

  fixClashingLabels(leftLabelLocationsSortedTopDown);
  fixClashingLabels(rightLabelLocationsSortedTopDown);

  return locations.reduce((locationByIndex, loc, i) => {
    locationByIndex[i] = loc;

    return locationByIndex;
  }, {});
};

const DonutChartContent = ({ arcs, path, labelOffset, thickness }) => {
  const arcsWithData = arcs.filter((arc) => arc.data.value > 0);
  const annotationLocationByIndex = getAnnotationLocationByIndex(
    arcsWithData,
    path,
    labelOffset,
    thickness
  );

  const pies = arcsWithData.map((arc, i) => {
    return (
      <g key={`pie-arc-${i}`}>
        <path className={`arc${i}`} d={path(arc)} fill={arc.data.color} />
      </g>
    );
  });
  const pieLabels = arcsWithData.map((arc, i) => {
    const annotationLoc = annotationLocationByIndex[i];

    return (
      <g key={`pie-arc-label-${i}`}>
        {arc.data.value > 0 && (
          <Annotation
            x={annotationLoc.x}
            y={annotationLoc.y}
            dx={annotationLoc.dx}
            dy={annotationLoc.dy}
          >
            <Label
              showAnchorLine={false}
              showBackground={false}
              title={ClassificationDonutChartUtils.getTypeDisplayValue(
                arc.data.value,
                true
              )}
              fontColor={GVDSColors.gray9}
              titleFontSize={12}
              titleFontWeight={500}
            />
            <Connector stroke={GVDSColors.gray10} />
          </Annotation>
        )}
      </g>
    );
  });

  return (
    <>
      {pies}
      {pieLabels}
    </>
  );
};

const DonutChart = ({
  width,
  height,
  thickness,
  margin,
  data,
  unit,
  totalValue,
}) => {
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const labelOffset = width * 0.3;

  const popoverContents = data.map((datum) => {
    return new DashboardEnvClassificationDataTooltipModel(
      datum.value,
      unit,
      datum.label,
      DashboardWidgetSharedUtils.calculatePercentage(datum.value, totalValue),
      datum.color
    );
  });

  return (
    <svg width={width} height={height}>
      <Group top={centerY + margin.top} left={centerX + margin.left - 5}>
        <Pie
          data={data}
          pieValue={(d) =>
            ClassificationDonutChartUtils.getPieValue(d.value, totalValue)
          }
          outerRadius={radius}
          innerRadius={radius - thickness}
          pieSort={null}
        >
          {({ arcs, path, pie }) => (
            <OverlayTrigger
              placement={
                data.length <= classificationDonutChartConfig.minLegendItemCount
                  ? TOOLTIP_PLACEMENTS.TOP
                  : TOOLTIP_PLACEMENTS.AUTO
              }
              overlay={
                <Popover>
                  <Popover.Body>
                    <DashboardEnvClassificationDataTooltip
                      contents={popoverContents}
                    />
                  </Popover.Body>
                </Popover>
              }
            >
              <g>
                <DonutChartContent
                  arcs={arcs}
                  path={path}
                  labelOffset={labelOffset}
                  thickness={thickness}
                />
              </g>
            </OverlayTrigger>
          )}
        </Pie>
      </Group>
    </svg>
  );
};

export default DonutChart;
