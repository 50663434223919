import React from "react";
import { Route, Switch } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";

import {
  LOGIN,
  PAYMENT, PAYMENT_RESULT,
  PRIVACY_POLICY,
  SIGNUP,
  SIGNUP_PACKAGES,
  TERMS_OF_USE,
} from "./config/ROUTES_NAME";
import Login from "./components/Login/Login";
import TermsOfUse from "./components/TermsAndConditions/TermsOfUse";
import PrivacyPolicy from "./components/TermsAndConditions/PrivacyPolicy";
import SignUp from "./components/Login/SignUp";
import AuthenticatedPage from "./pages/AuthenticatedPage";
import BillingSignUp from "./components/Public/SignUp/BillingSignUp";
import { ToastProvider } from "./context/ToastContext";
import BillingPayment from "./components/Public/Payment/BillingPayment";
import BillingPostPayment from "./components/Public/Payment/BillingPostPayment";

const App = () => {
  return (
    <>
      <ToastProvider>
        <div id="page-container" className="screen-container">
          <Switch>
            <Route path={LOGIN} render={() => <Login />} />
            <Route path={SIGNUP} render={() => <SignUp />} />
            <Route path={SIGNUP_PACKAGES} render={() => <BillingSignUp />} />
            <Route path={PAYMENT} render={() => <BillingPayment />} />
            <Route path={PAYMENT_RESULT} render={() => <BillingPostPayment />} />
            <Route path={PRIVACY_POLICY} render={() => <PrivacyPolicy />} />
            <Route exact path={TERMS_OF_USE} render={() => <TermsOfUse />} />
            <Route>
              <AuthenticatedPage />
            </Route>
          </Switch>
        </div>
        <div id="portalling-target" />
      </ToastProvider>
    </>
  );
};

export default App;
