import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../context/ToastContext";
import { UserUtils } from "../../services/UtilsService";
import UserService from "../../services/UserService";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSButtonWithLoadingAction from "../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import Spacer from "../../gvds-components/Layout/Spacer";

export const ConfirmRemoveAccountManager = ({
  isLoading,
  showModal,
  hideModal,
  asyncRemoveAccountManager,
}) => {
  return (
    <GVDSModal
      show={showModal}
      onHide={hideModal}
      title="Remove Account Manager"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <div>Are you sure you want to remove the Account Manager?</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={hideModal}
          disabled={isLoading}
          text="Cancel"
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.destructive_primary}
          onClickAsyncFunc={asyncRemoveAccountManager}
          disabled={isLoading}
          text="Remove"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const SelectAccountManagerModal = ({
  isValidated,
  showModal,
  hideModal,
  assignAccountManager,
  currentAccountManager,
  promptConfirmRemoveAccountManager,
}) => {
  const toastContext = useContext(ToastContext);
  const [inputAccountManager, setInputAccountManager] = useState(
    currentAccountManager
      ? {
          value: currentAccountManager.id,
          label: UserUtils.getFullNameOrEmail(
            currentAccountManager.full_name,
            currentAccountManager.email
          ),
        }
      : null
  );
  const [portalAdmins, setPortalAdmins] = useState([]);

  useEffect(() => {
    UserService.getPortalAdmins()
      .then((portalAdmins) => {
        setPortalAdmins(portalAdmins);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load portal admins.</span>);
      });
  }, []);

  return (
    <GVDSModal
      show={showModal}
      onHide={hideModal}
      title="Account Manager"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <GVDSFormSingleSelect
          placeholder="Account Manager"
          isSearchable={true}
          value={inputAccountManager}
          options={portalAdmins.map((portalAdmin) => {
            return {
              value: portalAdmin.id,
              label: UserUtils.getFullNameOrEmail(
                portalAdmin.full_name,
                portalAdmin.email
              ),
            };
          })}
          onSelect={(selected) => setInputAccountManager(selected)}
          statusMetadata={
            isValidated && !inputAccountManager
              ? FormFieldStatusMetadata.getError(
                  "Please select an account manager"
                )
              : FormFieldStatusMetadata.getDefault()
          }
          menuShouldScrollIntoView={false}
        />
      </GVDSModal.Body>
      <GVDSModal.Footer>
        {currentAccountManager && (
          <>
            <GVDSButton
              variant={buttonVariant.destructive_tertiary}
              text="Remove Account Manager"
              onClick={promptConfirmRemoveAccountManager}
            />
            <Spacer />
          </>
        )}
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          onClickAsyncFunc={async () =>
            await assignAccountManager(inputAccountManager)
          }
          text="Save"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default SelectAccountManagerModal;
