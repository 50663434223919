import React from "react";

const iconDefaultSizeProps = {
  size: 20,
  stroke: 1.75,
};

const GVDSIcon = React.forwardRef(({ Icon, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={`gvds-icon-container ${className || ""}`}
      {...props}
    >
      <Icon {...iconDefaultSizeProps} />
    </div>
  );
});

export default GVDSIcon;
