import uniqBy from "lodash/uniqBy";
import flatMap from "lodash/flatMap";
import {
  DateTimeUtils,
  NumberService,
} from "../../../../services/UtilsService";
import { REPORT_TYPE } from "../../../../config/constants";
import moment from "moment";
import StatusLabel from "../../../../gvds-components/common/StatusLabel";

export const DataRequestStatusEnum = {
  COMPLETED: "COMPLETED",
  INCOMPLETE: "INCOMPLETE",
  EXPIRED: "EXPIRED",
  NOT_APPLICABLE: "NOT APPLICABLE",
  NO_METER_SETUP: "NO METER SETUP",
};

const DataRequestStatusSortOrder = {
  [DataRequestStatusEnum.INCOMPLETE]: 1,
  [DataRequestStatusEnum.NO_METER_SETUP]: 2,
  [DataRequestStatusEnum.COMPLETED]: 3,
  [DataRequestStatusEnum.EXPIRED]: 4,
  [DataRequestStatusEnum.NOT_APPLICABLE]: 5,
};

export const DataRequestTypeFullNames = {
  [REPORT_TYPE.HCMI]: "HCMI Metric Report",
  [REPORT_TYPE.PERFORMANCE]: "Performance Report",
  [REPORT_TYPE.STANDARD_REPORT]: "Summary Report",
};

export default class DataRequestModel {
  constructor({
    id,
    resourceId,
    requestedBy,
    requestedOn,
    type,
    periods,
    deadline,
    status,
    description,
    runReport,
    allowDelete,
    progress,
    missingTypes,
    missingDataDetails,
    requestParams,
    completed_sites_facilities,
  }) {
    this.id = id;
    this.resourceId = resourceId;
    this.requestedBy = requestedBy;
    this.requestedOn = requestedOn;
    this.type = type;
    this.typeInFullName = DataRequestTypeFullNames[type];
    this.periods = periods;
    this.deadline = deadline;
    this.status = status;
    this.statusOrder = DataRequestStatusSortOrder[status];
    this.description = description;
    this.runReport = runReport;
    this.allowDelete = allowDelete;
    this.progress = progress;
    this.missingTypes = missingTypes;
    this.missingDataDetails = missingDataDetails;
    this.requestParams = requestParams;
    this.completed_sites_facilities = completed_sites_facilities;
  }

  static fromJson(requestJson) {
    return new DataRequestModel({
      id: requestJson["id"],
      resourceId: requestJson["resource_id"],
      requestedBy:
        requestJson["requested_by_name"] ||
        requestJson["requested_by_email"] ||
        "-",
      requestedOn: requestJson["requested_on"],
      type: requestJson["type"],
      periods: requestJson["periods"],
      deadline: requestJson["deadline"],
      status: requestJson["status"],
      description: requestJson["description"],
      runReport: requestJson["is_same_resource"],
      allowDelete: requestJson["is_same_resource"],
      progress: NumberService.isNumber(requestJson["progress"])
        ? Math.floor(requestJson["progress"])
        : null,
      missingTypes: requestJson["missing_types"],
      missingDataDetails: requestJson["missing_data_details"]
        ? requestJson["missing_data_details"]
        : [],
      requestParams: requestJson["request_params"],
      completed_sites_facilities: requestJson["completed_sites_facilities"],
    });
  }

  getStatusLabelColor() {
    switch (this.status) {
      case DataRequestStatusEnum.COMPLETED:
        return StatusLabel.Colors.green;
      case DataRequestStatusEnum.INCOMPLETE:
        return StatusLabel.Colors.yellow;
      case DataRequestStatusEnum.EXPIRED:
        return StatusLabel.Colors.red;
      case DataRequestStatusEnum.NOT_APPLICABLE:
      case DataRequestStatusEnum.NO_METER_SETUP:
        return StatusLabel.Colors.gray;
      default:
        return StatusLabel.Colors.blue;
    }
  }

  getDeadlineDueMessage() {
    return `(${DateTimeUtils.getDeadlineDueMessage(this.deadline)})`;
  }

  isExpired() {
    return this.status === DataRequestStatusEnum.EXPIRED;
  }

  isCompleted() {
    return this.status === DataRequestStatusEnum.COMPLETED;
  }

  isProgressLoading() {
    return !this.isExpired() && !NumberService.isNumber(this.progress);
  }

  hasMissingDataForMeter(meterId, meterStart, meterEnd) {
    return (
      this.getMissingDataGapsForMeter(meterId, meterStart, meterEnd).length > 0
    );
  }

  getMissingDataGapsForMeter(meterId, meterStart, meterEnd) {
    let dataGaps = uniqBy(
      flatMap(
        this.missingDataDetails
          .filter((m) => m["meter_id"] === meterId)
          .map((m) => m["data_gaps"])
      ),
      (g) => g["start"] + g["end"]
    );
    if (!meterStart && !meterEnd) {
      return dataGaps;
    }
    if (meterStart) {
      const meterStartDate = moment(
        DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(meterStart)
      );
      dataGaps = dataGaps
        .filter(
          (g) =>
            !meterStartDate.isAfter(
              DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(g["end"])
            )
        )
        .map((g) => ({
          ...g,
          start: meterStartDate.isBefore(
            DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(g["start"])
          )
            ? g["start"]
            : meterStart,
        }));
    }
    if (meterEnd) {
      const meterEndDate = moment(
        DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(meterEnd)
      );

      dataGaps = dataGaps
        .filter(
          (g) =>
            !meterEndDate.isBefore(
              DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(g["start"])
            )
        )
        .map((g) => ({
          ...g,
          end: meterEndDate.isAfter(
            DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(g["end"])
          )
            ? g["end"]
            : meterEnd,
        }));
    }

    return dataGaps;
  }
}
