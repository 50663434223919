import React, { useState } from "react";
import Button from "react-bootstrap/Button";

const MAX_COUNT_CUTOFF = 10;

const PeopleCategoryDisplay = ({ categories }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (categories.length <= MAX_COUNT_CUTOFF) {
    return <>{categories.map((c) => c.name).join(", ")}</>;
  } else {
    if (isExpanded) {
      return (
        <>
          {categories.map((c) => c.name).join(", ")}
          <Button
            size="sm"
            variant="link"
            onClick={() => setIsExpanded(false)}
            className="fst-italic"
          >
            (collapse)
          </Button>
        </>
      );
    } else {
      return (
        <>
          {categories
            .slice(0, MAX_COUNT_CUTOFF)
            .map((c) => c.name)
            .join(", ")}
          , ...{" "}
          <Button
            size="sm"
            variant="link"
            onClick={() => setIsExpanded(true)}
            className="fst-italic"
          >
            (expand)
          </Button>
        </>
      );
    }
  }
};

export default PeopleCategoryDisplay;
