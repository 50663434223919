import React, { useEffect, useRef } from "react";

const SurveyMessagePreviewDisplay = ({ content }) => {
  const divRef = useRef();

  useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = content;
    }
  }, [content]);

  return <div ref={divRef} />;
};

export default SurveyMessagePreviewDisplay;
