import React, { useContext, useEffect, useState } from "react";
import SignUpFormView from "./SignUpFormView";
import { SignupModel } from "./SignUpModel";
import clone from "lodash/clone";
import SignUpService from "../../../services/SignUpService";
import BillingPackages, {
  yearlyMonthlyPriceDiff,
} from "../../common/Billing/BillingPlans";
import LoadingSpinner from "../../common/LoadingSpinner";
import greenviewPortalLogo from "../../../assets/images/new-greenview-logo.svg";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVFormGroup from "../../common/GVFormGroup";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import Form from "react-bootstrap/Form";
import {
  BillingCycleEnum,
  PackagePlanModel,
} from "../../common/Billing/PackagePlanModel";
import SupportContactLink from "../../common/SupportContactLink";
import {
  IconArrowNarrowLeft,
  IconCircleCheckFilled,
} from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import Spacer from "../../../gvds-components/Layout/Spacer";
import { HOME, LOGIN } from "../../../config/ROUTES_NAME";
import { Link, useHistory } from "react-router-dom";
import ToastContext from "../../../context/ToastContext";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import ToastsDisplay from "../../common/ToastsDisplay";
import BillingEnterprisePlanInquiry from "./BillingEnterprisePlanInquiry";
import {
  CONTACT_EMAIL_SALES,
  CONTACT_EMAIL_SALES_SUBJECT,
} from "../../../config/constants";
import BillingPlanComparisonTable from "../../common/Billing/BillingPlanComparisonTable";
import { CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME } from "../../SystemToolbox/Subscription/SubscriptionSharedUtils";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import { MailToUtils } from "../../../services/UtilsService";

const BillingSignUpStates = {
  PLANS_SELECTION: "Plans Selection",
  SIGNUP_FORM: "Sign Up Form",
  ENTERPRISE_PLAN_SIGNUP_FORM: "Enterprise Plan Sign Up Form",
  SIGNUP_DONE: "Sign Up Done",
};

const BillingSignUp = () => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [componentState, setComponentState] = useState(
    BillingSignUpStates.PLANS_SELECTION
  );
  const [showSubscriptionBillModal, setShowSubscriptionBillModal] =
    useState(false);
  const [
    showSubscriptionBillSwitchToTrialModal,
    setShowSubscriptionBillSwitchToTrialModal,
  ] = useState(false);
  const [signUpOptions, setSignUpOptions] = useState(null);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isSelectedTrial, setIsSelectedTrial] = useState(false);
  const [selectedBillingCycle, setSelectedBillingCycle] = useState(null);

  const currency = "usd";
  const isEnterprisePlan = selectedPlan === null;

  const onChoosingPlan = (plan, isTrial) => {
    setSelectedPlan(plan);
    setIsSelectedTrial(isTrial);

    if (!isTrial && !plan.hasMonthly) {
      setSelectedBillingCycle(BillingCycleEnum.yearly);
    }

    setComponentState(BillingSignUpStates.SIGNUP_FORM);
  };

  const onChoosingEnterprise = () => {
    SignUpService.logVisitingEnterprisePlanSignUpApplication();
    setSelectedPlan(null);
    setComponentState(BillingSignUpStates.ENTERPRISE_PLAN_SIGNUP_FORM);
  };

  const goBackToPlanSelection = () => {
    setSelectedPlan(null);
    setIsSelectedTrial(null);
    setComponentState(BillingSignUpStates.PLANS_SELECTION);
  };

  const [signUpModel, setSignUpModel] = useState(new SignupModel());
  const onSignUpModelChange = () => {
    setSignUpModel(clone(signUpModel));
  };

  const [isValidated, setIsValidated] = useState(false);
  const onSubmittingSignUpForm = () => {
    setIsValidated(true);
    if (signUpModel.isValid()) {
      if (isSelectedTrial) {
        submitSignUpRequest(isSelectedTrial);
      } else {
        setShowSubscriptionBillModal(true);
      }
    }
  };

  const onConfirmingSubscriptionSignUp = () => {
    submitSignUpRequest(isSelectedTrial);
  };

  const onCancellingSubscriptionSignUp = () => {
    setShowSubscriptionBillModal(false);

    if (selectedPlan.hasTrial) {
      setShowSubscriptionBillSwitchToTrialModal(true);
    }
  };

  const onConfirmingSwitchToTrial = () => {
    setIsSelectedTrial(true);

    submitSignUpRequest(true);

    setShowSubscriptionBillSwitchToTrialModal(false);
  };
  const onCancellingSwitchToTrial = () => {
    setShowSubscriptionBillSwitchToTrialModal(false);
  };

  const submitSignUpRequest = async (isForTrial) => {
    setIsLoading(true);
    try {
      await SignUpService.submitSignUpApplicationWithPackagePlan(
        signUpModel.userFullName,
        signUpModel.emailAddress,
        signUpModel.companyName,
        signUpModel.siteName,
        signUpModel.siteType,
        signUpModel.streetAddress,
        signUpModel.zipCode,
        signUpModel.city,
        signUpModel.stateOrProvince,
        signUpModel.country,
        selectedPlan.packagePlanId,
        isForTrial ? null : selectedBillingCycle,
        isForTrial
      );
      setComponentState(BillingSignUpStates.SIGNUP_DONE);
    } catch (e) {
      toastContext.addFailToast(
        <div>
          <div className="gvds-text--body__bold">
            Failed to submit application.
          </div>
          <div>
            Please contact portal admin at <SupportContactLink />
          </div>
        </div>
      );
    } finally {
      setIsLoading(false);
    }
  };

  let content;

  useEffect(() => {
    SignUpService.getSignUpConfig()
      .then((data) => {
        setSignUpOptions(data);
        setIsLoading(false);
      })
      .catch(() => {});
  }, []);

  if (signUpOptions === null) {
    return <LoadingSpinner />;
  }

  if (componentState === BillingSignUpStates.PLANS_SELECTION) {
    const orderedPackagePlans =
      signUpOptions["pricing_packages"]["ordered_package_plans"];
    const pricingPlanModels = orderedPackagePlans.map((planDto) =>
      PackagePlanModel.fromDTO(planDto)
    );
    content = (
      <>
        <BillingPackages
          pricingPlanModels={pricingPlanModels}
          onChoosingPlan={onChoosingPlan}
          onChoosingEnterprise={onChoosingEnterprise}
          currency={currency}
          isSignUp={true}
        />
        <div className="mb-5"></div>
        <BillingPlanComparisonTable
          orderedPlanNames={orderedPackagePlans
            .map((p) => p.name)
            .filter((name) => name !== CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME)}
        />
      </>
    );
  } else if (componentState === BillingSignUpStates.SIGNUP_FORM) {
    const yearlyPrice = selectedPlan.getYearlyPriceForCurrency(currency);
    const monthlyPrice = selectedPlan.getMonthlyPriceForCurrency(currency);
    content = (
      <>
        <div className="signup__form">
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={goBackToPlanSelection}
            text="Back to plans"
            icon={<GVDSIconSlim Icon={IconArrowNarrowLeft} />}
            className="page-header--back-button mb-2"
          />
          <div className="gvds-text--heading2">
            {isSelectedTrial
              ? "Start your free trial of Greenview Portal"
              : "Sign up for Greenview Portal"}
          </div>
          <div className="contact-us__display">
            Managing multiple hotels or properties?{" "}
            <GVDSTextButton
              text="Contact us"
              onClick={() =>
                setComponentState(
                  BillingSignUpStates.ENTERPRISE_PLAN_SIGNUP_FORM
                )
              }
            />{" "}
            instead
          </div>
          <SignUpFormView
            signUpOptions={signUpOptions}
            signUpModel={signUpModel}
            onModelChange={onSignUpModelChange}
            isValidated={isValidated}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            text={`Sign up ${isSelectedTrial ? "for trial" : ""}`}
            onClick={onSubmittingSignUpForm}
            className="w-100"
          />
        </div>
        <GVDSModal
          size={GVDSModal.Size.small}
          title="Confirm your subscription details"
          show={showSubscriptionBillModal}
          onHide={() => setShowSubscriptionBillModal(false)}
        >
          <GVDSModal.Body>
            <GVFormGroup>
              <Form.Label>Plan Selected</Form.Label>
              <div>{selectedPlan.name}</div>
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>
                Billing Cycle{" "}
                {selectedPlan.hasMonthly && (
                  <InfoTooltip info="Select whether you prefer to be billed annually or monthly for your subscription." />
                )}
              </Form.Label>
              <Form.Check
                id="billing-cycle--yearly"
                label={
                  <>
                    Yearly ({currency.toUpperCase()}{" "}
                    <strong>${yearlyPrice?.priceInDecimal}</strong> per year){" "}
                    {selectedPlan.hasMonthly && (
                      <StatusLabel color={StatusLabel.Colors.green}>
                        Save{" "}
                        {yearlyMonthlyPriceDiff(
                          yearlyPrice.priceInDecimal,
                          monthlyPrice.priceInDecimal
                        )}
                        %
                      </StatusLabel>
                    )}
                  </>
                }
                type="radio"
                checked={selectedBillingCycle === BillingCycleEnum.yearly}
                onChange={() => {
                  setSelectedBillingCycle(BillingCycleEnum.yearly);
                }}
              />
              {selectedPlan.hasMonthly && (
                <Form.Check
                  id="billing-cycle--monthly"
                  label={
                    <>
                      Monthly ({currency.toUpperCase()}{" "}
                      <strong>${monthlyPrice?.priceInDecimal}</strong> per
                      month)
                    </>
                  }
                  type="radio"
                  checked={selectedBillingCycle === BillingCycleEnum.monthly}
                  onChange={() => {
                    setSelectedBillingCycle(BillingCycleEnum.monthly);
                  }}
                />
              )}
            </GVFormGroup>

            <div>Your subscription will begin upon successful payment.</div>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={onCancellingSubscriptionSignUp}
              disabled={isLoading}
              text="Cancel"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={onConfirmingSubscriptionSignUp}
              disabled={isLoading}
              text="Confirm"
            />
          </GVDSModal.Footer>
        </GVDSModal>

        <GVDSModal
          size={GVDSModal.Size.small}
          title="Not sure yet?"
          show={showSubscriptionBillSwitchToTrialModal}
          onHide={() => setShowSubscriptionBillSwitchToTrialModal(false)}
        >
          <GVDSModal.Body>
            If you're not ready to commit, why not try a free trial of the
            'Greenview Portal' instead?
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={onCancellingSwitchToTrial}
              disabled={isLoading}
              text="Not Now"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={onConfirmingSwitchToTrial}
              disabled={isLoading}
              text="Sign Up for Trial"
            />
          </GVDSModal.Footer>
        </GVDSModal>
      </>
    );
  } else if (
    componentState === BillingSignUpStates.ENTERPRISE_PLAN_SIGNUP_FORM
  ) {
    content = (
      <div className="signup__form">
        <BillingEnterprisePlanInquiry
          signUpOptions={signUpOptions}
          goBackToPlanSelection={goBackToPlanSelection}
          onSuccess={() => {
            setComponentState(BillingSignUpStates.SIGNUP_DONE);
          }}
        />
      </div>
    );
  } else if (componentState === BillingSignUpStates.SIGNUP_DONE) {
    content = (
      <div className="billing__signup-done__container">
        <div>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={goBackToPlanSelection}
            text="Back to plans"
            icon={<GVDSIconSlim Icon={IconArrowNarrowLeft} />}
            className="page-header--back-button mb-4"
          />
          <div className="gvds-text--heading2 d-flex align-items-center">
            <GVDSIcon
              className="billing__signup-done__icon"
              Icon={IconCircleCheckFilled}
            />
            {isEnterprisePlan
              ? "Your inquiry has been received!"
              : "Your sign up details have been received!"}
          </div>
          <br />
          <div>
            Our team will reach out to you via email shortly on how to start
            using Greenview Portal.
          </div>
          <br />
          <div>
            More questions? Let us help.{" "}
            <a
              className="contact-sales-email"
              href={MailToUtils.sendEmailWithSubject(
                CONTACT_EMAIL_SALES,
                CONTACT_EMAIL_SALES_SUBJECT
              )}
              target="_blank"
            >
              Contact sales
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        componentState === BillingSignUpStates.PLANS_SELECTION
          ? "billing-sign-up__plans-selection__container"
          : ""
      }
    >
      <div className="gvds-top-nav">
        <Link to={HOME} target="_blank">
          <img src={greenviewPortalLogo} alt="Greenview Portal" />
        </Link>
        <Spacer />
        <GVDSButton
          variant={buttonVariant.tertiary}
          text="Contact Sales"
          onClick={() => {
            window.open(
              MailToUtils.sendEmailWithSubject(
                CONTACT_EMAIL_SALES,
                CONTACT_EMAIL_SALES_SUBJECT
              ),
              "_blank"
            );
          }}
        />
        <GVDSButton
          variant={buttonVariant.secondary}
          text="Login"
          onClick={() => {
            history.push(LOGIN);
          }}
        />
      </div>
      <div className="signup__container">{content}</div>
      <ToastsDisplay />
    </div>
  );
};

export default BillingSignUp;
