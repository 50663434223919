import React, { useContext, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import SubscriptionService from "../../../../services/SubscriptionService";
import GVDSButton, { buttonVariant } from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";

const DeleteSiteSubscriptionPrompt = ({ showPrompt, onClosePrompt, siteId, subscriptionId, onSuccess }) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    if (!isLoading) {
      onClosePrompt();
    }
  };

  const deleteSubscription = async () => {
    setIsLoading(true);
    SubscriptionService.deleteSiteSubscription(
      siteId,
      subscriptionId
    )
      .then(() => {
        onClosePrompt();
        onSuccess();
        toastContext.addSuccessToast(<span>Subscription deleted successfully</span>);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to delete subscription.</span>);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <GVDSModal
        show={showPrompt}
        onHide={closeModal}
        title="Delete Subscription"
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          {isLoading ? <LoadingSpinner /> : (
            <>
              You are about to delete a subscription. If there are no other subscriptions, all users (except system
              admin) will lose access to this site. Are you sure?
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            disabled={isLoading}
            onClick={closeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            disabled={isLoading}
            onClick={deleteSubscription}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default DeleteSiteSubscriptionPrompt;