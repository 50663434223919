import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";

import withAuthentication from "../HOC/withAuthentication";
import { RESOURCES } from "../../config/constants";
import { SURVEYS, SURVEYS_CREATE, VIEW_SURVEY } from "../../config/ROUTES_NAME";
import UserInventoryContext from "../../context/UserInventoryContext";
import PortfolioViewAllSurveys from "./PortfolioView/PortfolioViewAllSurveys";
import LoadingSpinner from "../common/LoadingSpinner";
import SurveyBuilder from "./PortfolioView/SurveyBuilder";
import SiteViewAllSurveys from "./SiteView/SiteViewAllSurveys";
import SiteViewSurvey from "./SiteView/SiteViewSurvey";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import { PageWrapper } from "../../gvds-components/Nav/PageWrapper/PageWrapper";
import {useTranslation} from "react-i18next";

const Surveys = () => {
  return (
    <Switch>
      <Route
        exact
        path={SURVEYS}
        render={() => (
          <PageWrapper configRouteName={SURVEYS}>
            <ViewAllSurveys />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={SURVEYS_CREATE}
        render={() => (
          <PageWrapper configRouteName={SURVEYS_CREATE}>
            <SurveyBuilder />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={VIEW_SURVEY}
        render={() => (
          <PageWrapper configRouteName={VIEW_SURVEY}>
            <ViewSurvey />
          </PageWrapper>
        )}
      />
    </Switch>
  );
};

const ViewAllSurveys = () => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);

  let display = <LoadingSpinner />;

  const selectedInventory = userInventory.selectedInventory.get;

  if (!userInventory.isLoadingInventory.get && selectedInventory) {
    if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      display = <PortfolioViewAllSurveys />;
    } else if (selectedInventory.type === RESOURCES.SITE) {
      display = <SiteViewAllSurveys />;
    } else if (selectedInventory.type === RESOURCES.FACILITY) {
      display = (
        <>
          <PageHeader>
            <PageHeader.Title>
              <h1>{t("surveys.page-title")}</h1>
            </PageHeader.Title>
          </PageHeader>
          <div className="table__no_content">
            Surveys are unavailable at the facility-level.
          </div>
        </>
      );
    }
  }

  return <div>{display}</div>;
};

const ViewSurvey = () => {
  const userInventory = useContext(UserInventoryContext);

  let display = <LoadingSpinner />;

  const selectedInventory = userInventory.selectedInventory.get;

  if (!userInventory.isLoadingInventory.get && selectedInventory) {
    if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      display = <SurveyBuilder />;
    } else if (selectedInventory.type === RESOURCES.SITE) {
      display = <SiteViewSurvey />;
    } else if (selectedInventory.type === RESOURCES.FACILITY) {
      display = (
        <div className="table__no_content">
          Surveys are unavailable at the facility-level.
        </div>
      );
    }
  }

  return <div>{display}</div>;
};

export default withAuthentication(Surveys);
