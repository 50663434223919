import axios from "axios";
import moment from "moment";
import { API_URL } from "../config/api-config";
import { DateTimeUtils } from "./UtilsService";
import {
  BEST_PRACTICES,
  CERTIFICATIONS,
  ENVIRONMENTAL_DATA,
  GSTC,
  OPERATIONAL_DATA,
  OVERVIEW_DATA,
  PEOPLE_DATA,
  VIEW_ALL_INITIATIVES,
} from "../config/ROUTES_NAME";
import { FILE_MODULES, RESOURCES } from "../config/constants";
import { GenericUserManagedTagModel } from "../components/common/CentralTags/UserManagedTagModel";

const FILE_PER_PAGE = 300;

export const FILE_MODULE_BY_PATH = {
  [OVERVIEW_DATA]: [
    FILE_MODULES.ENVIRONMENTAL_DATA__ENERGY,
    FILE_MODULES.ENVIRONMENTAL_DATA__WATER,
    FILE_MODULES.ENVIRONMENTAL_DATA__WASTE_RECYCLING,
    FILE_MODULES.ENVIRONMENTAL_DATA__REFRIGERANTS,
    FILE_MODULES.ENVIRONMENTAL_DATA__MARKET_PURCHASE,
    FILE_MODULES.ENVIRONMENTAL_DATA__OTHER,
    FILE_MODULES.OPERATIONAL_DATA,
    FILE_MODULES.PEOPLE_DATA,
    FILE_MODULES.INITIATIVES__EFFICIENCY_ENVIRONMENT,
    FILE_MODULES.INITIATIVES__PROCUREMENT,
    FILE_MODULES.INITIATIVES__SOCIAL_IMPACT,
    FILE_MODULES.INITIATIVES__OTHER,
  ],
  [ENVIRONMENTAL_DATA]: [
    FILE_MODULES.ENVIRONMENTAL_DATA__ENERGY,
    FILE_MODULES.ENVIRONMENTAL_DATA__WATER,
    FILE_MODULES.ENVIRONMENTAL_DATA__WASTE_RECYCLING,
    FILE_MODULES.ENVIRONMENTAL_DATA__REFRIGERANTS,
    FILE_MODULES.ENVIRONMENTAL_DATA__MARKET_PURCHASE,
    FILE_MODULES.ENVIRONMENTAL_DATA__OTHER,
  ],
  [OPERATIONAL_DATA]: [FILE_MODULES.OPERATIONAL_DATA],
  [PEOPLE_DATA]: [FILE_MODULES.PEOPLE_DATA],
  [VIEW_ALL_INITIATIVES]: [
    FILE_MODULES.INITIATIVES__EFFICIENCY_ENVIRONMENT,
    FILE_MODULES.INITIATIVES__PROCUREMENT,
    FILE_MODULES.INITIATIVES__SOCIAL_IMPACT,
    FILE_MODULES.INITIATIVES__OTHER,
  ],
  [BEST_PRACTICES]: [FILE_MODULES.BEST_PRACTICES],
  [GSTC]: [FILE_MODULES.GSTC],
  [CERTIFICATIONS]: [FILE_MODULES.CERTIFICATIONS],
};

export class FileStorageObjectModel {
  constructor(
    id,
    name,
    link,
    description,
    uploadedBy,
    uploadedOn,
    associatedModules,
    portfolioTags,
    siteTags,
    hasAssociatedResources,
    site
  ) {
    this.id = id;
    this.name = name;
    this.link = link;
    this.description = description;
    this.uploadedBy = uploadedBy;
    this.uploadedOn = uploadedOn;
    this.associatedModules = associatedModules;
    this.portfolioTags = portfolioTags;
    this.siteTags = siteTags;
    this.hasAssociatedResources = hasAssociatedResources;
    this.site = site;
  }

  get searchString() {
    return `[${this.portfolioTags
      .concat(this.siteTags)
      .map((t) => t.name)
      .join(", ")}]`;
  }

  get allTags() {
    return this.portfolioTags
      .map(
        (t) => new GenericUserManagedTagModel(t.id, t.name, RESOURCES.PORTFOLIO)
      )
      .concat(
        this.siteTags.map(
          (t) => new GenericUserManagedTagModel(t.id, t.name, RESOURCES.SITE)
        )
      );
  }

  get allTagSelectionValues() {
    return this.allTags.map((t) => t.selectionValue);
  }

  hasModule(moduleNames) {
    return !!this.associatedModules.find(
      (m) => moduleNames.indexOf(m.name) >= 0
    );
  }

  hasTag(userManagedTagSelectionValues) {
    return !!this.allTagSelectionValues.some(
      (tSelectionValue) =>
        userManagedTagSelectionValues.indexOf(tSelectionValue) >= 0
    );
  }

  inSite(siteNames) {
    return siteNames.indexOf(this.site.name) >= 0;
  }

  within(start, end) {
    if (!this.uploadedOn) {
      return true;
    }

    let afterStart = true,
      beforeEnd = true;
    if (start) {
      afterStart = !moment(start)
        .startOf("day")
        .isAfter(
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(this.uploadedOn)
        );
    }
    if (end) {
      beforeEnd = !moment(end)
        .startOf("day")
        .isBefore(
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(this.uploadedOn)
        );
    }

    return afterStart && beforeEnd;
  }

  static fromDTO(fileStorageObjectDTO) {
    return new FileStorageObjectModel(
      fileStorageObjectDTO.id,
      fileStorageObjectDTO.name,
      API_URL + fileStorageObjectDTO.link,
      fileStorageObjectDTO.description,
      fileStorageObjectDTO.uploaded_by,
      fileStorageObjectDTO.uploaded_on,
      fileStorageObjectDTO.associated_modules,
      fileStorageObjectDTO.portfolio_tags,
      fileStorageObjectDTO.site_tags,
      fileStorageObjectDTO.has_associated_resources,
      fileStorageObjectDTO.site
    );
  }
}

class FileStorageAssociatedResourceModel {
  constructor(associatedResourceObjectType, associatedResourceObjectName) {
    this.associatedResourceObjectType = associatedResourceObjectType;
    this.associatedResourceObjectName = associatedResourceObjectName;
  }

  static fromDTO(fileStorageAssociatedResourceDTO) {
    return new FileStorageAssociatedResourceModel(
      fileStorageAssociatedResourceDTO.associated_resource_object_type,
      fileStorageAssociatedResourceDTO.associated_resource_object_name
    );
  }
}

export default class FileStorageService {
  static getAllFileStorageOptions = async (resource_type, resource_id) => {
    try {
      const query = { resource_type, resource_id };

      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/file_storage_options`,
        { params: query }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static validateFileNames = async (resource_type, resource_id, fileNames) => {
    try {
      const params = { resource_type, resource_id };

      const response = await axios.post(
        `${API_URL}/api/v1/file_storage/validate_names`,
        { names: fileNames },
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static uploadFiles = async (
    resource_type,
    resource_id,
    files,
    associatedModules,
    portfolioTags,
    siteTags
  ) => {
    const query = { resource_type, resource_id };
    const postBody = new FormData();

    const payload = {
      associated_modules: associatedModules,
      portfolio_tags: portfolioTags,
      site_tags: siteTags,
    };

    postBody.append("data", JSON.stringify(payload));

    files.forEach((fileAttachmentItemModel) => {
      if (fileAttachmentItemModel.isNew()) {
        postBody.append("file", fileAttachmentItemModel.file);
      }
    });

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/file_storage`,
        postBody,
        {
          params: query,
        }
      );

      return response.data.map((o) => FileStorageObjectModel.fromDTO(o));
    } catch (error) {
      throw error;
    }
  };

  static getAllFileStorageObjects = async (resource_type, resource_id) => {
    let page = 1;

    const params = {
      resource_type,
      resource_id,
      page,
      size: FILE_PER_PAGE,
    };

    let hasNextPage = true;
    const fileStorageDtos = [];

    try {
      while (hasNextPage) {
        const response = await axios.get(`${API_URL}/api/v1/file_storage`, {
          params,
        });
        hasNextPage = response.data.has_next_page;
        fileStorageDtos.push(
          ...response.data.results.map((o) => FileStorageObjectModel.fromDTO(o))
        );
        params["page"] = ++page;
      }

      return fileStorageDtos;
    } catch (error) {
      throw error;
    }
  };

  static updateFileStorageObjectDetails = async (
    resource_type,
    resource_id,
    editedFileStorageObjects
  ) => {
    const editedFilesPayload = editedFileStorageObjects.map((f) => {
      return {
        id: f.id,
        name: f.newName,
        description: f.newDescription,
        associated_modules: f.newAssociatedModules.map((m) => ({
          id: m.id,
          name: m.name,
        })),
        portfolio_tags: f.newPortfolioTags.map((t) => ({
          id: t.id,
          name: t.name,
        })),
        site_tags: f.newSiteTags.map((t) => ({ id: t.id, name: t.name })),
        site_id: f.site.id,
      };
    });
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/file_storage`,
        { files: editedFilesPayload },
        {
          params: {
            resource_id,
            resource_type,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteFileStorageObjects = async (
    resource_type,
    resource_id,
    fileStorageObjectIds
  ) => {
    try {
      const response = await axios.delete(`${API_URL}/api/v1/file_storage`, {
        data: {
          ids: fileStorageObjectIds,
        },
        params: {
          resource_id,
          resource_type,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getFileNamesValidationPayloadForUpload = (
    fileAttachmentItemModels,
    site_id
  ) => {
    return fileAttachmentItemModels.map((fileAttachmentItemModel) => {
      if (fileAttachmentItemModel.isNew()) {
        return {
          old_name: "",
          new_name: fileAttachmentItemModel.name,
          site_id: site_id,
        };
      }
    });
  };

  static getFileNamesValidationPayloadForEdit = (fileStorageObjects) => {
    return fileStorageObjects.map((fileStorageObject) => {
      fileStorageObject.newName = fileStorageObject.newName.trim();
      return {
        old_name: fileStorageObject.name,
        new_name: fileStorageObject.newName,
        site_id: fileStorageObject.site.id,
      };
    });
  };

  static getFileNamesValidationPayload = (
    fileAttachmentItemModels,
    site_id
  ) => {
    return fileAttachmentItemModels
      .filter(
        (fileAttachmentItemModel) =>
          fileAttachmentItemModel.isNew() ||
          fileAttachmentItemModel.isToBeDeleted()
      )
      .map((fileAttachmentItemModel) => {
        if (fileAttachmentItemModel.isNew()) {
          return {
            old_name: "",
            new_name: fileAttachmentItemModel.name,
            site_id: site_id,
          };
        } else if (fileAttachmentItemModel.isToBeDeleted()) {
          return {
            old_name: fileAttachmentItemModel.name,
            new_name: "",
            site_id: site_id,
          };
        }
      });
  };

  static getFileStorageObjectAssociatedResources = async (
    resource_type,
    resource_id,
    object_id
  ) => {
    const params = { resource_type, resource_id };

    try {
      const response = await axios.get(
        `${API_URL}/api/v1/file_storage/${object_id}/associated_resources`,
        {
          params,
        }
      );

      return response.data.map((o) =>
        FileStorageAssociatedResourceModel.fromDTO(o)
      );
    } catch (error) {
      throw error;
    }
  };
}
