import React, { useContext, useEffect, useState } from "react";

import UserManagedTagService from "../../../services/UserManagedTagService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { StringUtils } from "../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";

const TagDeletePrompt = ({ show, tag, onClose, onDelete }) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);
  const [tagResources, setTagResources] = useState(null);

  const closeModal = () => {
    if (!isLoading) {
      setTagResources(null);
      onClose();
    }
  };

  const loadTagResources = async () => {
    setIsLoading(true);
    UserManagedTagService.getTagResources(
      selectedInventory.type,
      selectedInventory.id,
      tag.id
    )
      .then((tagResources) => {
        setTagResources(tagResources);
        setIsLoading(false);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load tag resource.</span>);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedInventory && tag) {
      setIsLoading(true);
      loadTagResources();
    }
  }, [tag]);

  const deleteTag = () => {
    if (selectedInventory) {
      setIsLoading(true);
      UserManagedTagService.deleteTag(
        selectedInventory.type,
        selectedInventory.id,
        tag.id
      )
        .then(() => {
          toastContext.addSuccessToast(
            <span>{tag.name} deleted successfully</span>
          );
          onDelete();
          onClose();
          setIsLoading(false);
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to delete tag.</span>);
          setIsLoading(false);
        });
    }
  };

  const tagInInitiativeCountDisplay = tagResources
    ? tagResources.initiatives.length
    : "0";
  const tagInFileStorageCountDisplay = tagResources
    ? tagResources.file_storage_objects.length
    : "0";

  let content;

  if (isLoading || !tagResources) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <>
        <div>
          Deleting this tag will remove it from all items where it has been used
          (currently used for{" "}
          <strong className="tag-initiative-usage-count-display">
            {tagInInitiativeCountDisplay}
          </strong>{" "}
          items in Initiatives and{" "}
          <strong className="tag-file-storage-usage-count-display">
            {tagInFileStorageCountDisplay}
          </strong>{" "}
          items in File Storage).
        </div>
        <br />
        <div>This action cannot be undone. Are you sure?</div>
      </>
    );
  }

  if (tag) {
    const modalTitle = `Delete ${StringUtils.getTitleCase(
      tag.resourceType
    )} Tag`;

    return (
      <GVDSModal
        title={modalTitle}
        size={GVDSModal.Size.small}
        show={show}
        onHide={closeModal}
      >
        <GVDSModal.Body>{content}</GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeModal}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteTag}
            disabled={isLoading}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    );
  }
};

export default TagDeletePrompt;
