// Based on the performance tips on https://github.com/farzher/fuzzysort#how-to-go-fast--performance-tips
export function fuzzysortOptionsWithKeys(
  keys,
  options = FUZZYSORT_SHARED_OPTIONS
) {
  return { ...options, keys: keys };
}

export const FUZZYSORT_SHARED_OPTIONS = {
  limit: 200,
  threshold: 0.3,
};

export const workspaceNavContractSelectorSearchKeys = ["name"];
export const workspaceNavPortfolioSelectorSearchKeys = ["name"];
export const workspaceNavSiteSelectorSearchKeys = ["name"];

export const initiativeSearchKeys = ["name", "story", "searchString"];

export const helpArticleSearchKeys = ["title", "description"];
export const bestPracticeAssessQuestionSearchKeys = [
  "questionText",
  "searchString",
];
export const bestPracticeReportQuestionSearchKeys = ["questionText"];
export const guidanceArticleSearchKeys = ["title", "searchString"];
export const GSTCActionCriteriaSearchKeys = ["name", "criteria_number"];
export const GSTCGuidanceActionSearchKeys = [
  "criteriaNumber",
  "name",
  "criteriaName",
  "criteriaDescription",
];
export const surveyPortfolioSearchKeys = ["name"];
export const surveySiteSearchKeys = ["name"];
export const surveyBestPracticeQuestionSearchKeys = ["questionText"];
export const surveySiteResponseSearchKeys = ["site_name"];
export const fileStorageSearchKeys = ["name", "description", "searchString"];
export const userManagedTagSearchKeys = ["name", "description"];
export const contractSiteListSearchKeys = [
  "name",
  "country.name",
  "street_address",
  "city",
  "state_or_province",
  "zip_code",
];

export const SystemToolboxSearchKeysByPageName = {
  contracts: ["name"],
  sites: ["name"],
  users: ["email", "fullName"],
  siteSignUpRequests: ["email_address", "site_name"],
  helpArticles: ["title", "description"],
  GSTCCriteria: ["criteriaNumber", "name", "description"],
  GSTCChapter: ["name"],
  GSTCActionItem: ["name", "searchString"],
  bestPractice: ["question_text", "searchString"],
  guidanceArticleBestPracticeQuestion: ["question_text"],
  guidanceArticle: ["title", "searchString"],
  referenceData: {
    portalModuleLink: ["name"],
    companies: ["name"],
    brandFlag: ["name"],
    affiliations: ["name"],
    hotelType: ["name"],
    laundryLocation: ["name"],
    marketSegment: ["name"],
    assetClass: ["name"],
    environmentalType: ["name"],
    operationalType: ["name"],
    performanceGrouping: ["name"],
    unitConversion: ["name", "unit_name"],
    certification: ["name"],
    baileyEcoregion: ["name"],
    koppenGeiger: ["name"],
    countries: ["name"],
    currencies: ["name"],
    metroArea: ["name"],
    siteFacilityType: ["name"],
    stateAndProvince: ["name"],
  },
  subscriptions: [
    "name",
    "siteNames",
    "type",
    "paymentStatus",
    "priceAmountSearch",
    "startPeriodSearch",
    "endPeriodSearch",
  ],
  homepageAnnouncement: ["title", "plainTextDescription"],
};

export const PolicyTrackerSearchKeys = ["name"];

export const CertificationCriteriaSearchKeys = [
  "name",
  "number",
  "description",
  "categoryName",
];

export const DashboardSearchKeys = {
  inventoryFilter: [
    "name",
    (obj) => obj.facilities?.map((facility) => facility.name).join(","),
  ],
};
