import DashboardDataContext from "../../Context/DashboardDataContext";
import React, { useContext, useEffect, useState } from "react";
import DashboardCalculator from "../../Models/DashboardCalculator";
import { DATA_STATUS } from "../../../../config/constants";
import SafeDataService from "../../../../services/SafeDataService";
import MainSummaryCardView from "./MainSummaryCardView";
import { useTranslation } from "react-i18next";

const MainSummaryCard = ({ element }) => {
  const { t } = useTranslation();

  const dashboardDataContext = useContext(DashboardDataContext);
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const [summaryData, setSummaryData] = useState([]);

  const config = element["config"];
  const subtopics = [config["subtopic"]];

  const isDataLoaded = dashboardHolderFacade.isAllRequiredDataPresent(
    subtopics,
    true
  );

  useEffect(() => {
    if (!dashboardDataContext.isLoadingData && isDataLoaded) {
      const config = element["config"];

      const summarySafeData = DashboardCalculator.getSummary(
        config,
        dashboardHolderFacade
      );

      const unit = DashboardCalculator.getFullUnit(
        config,
        dashboardHolderFacade
      );
      const change = SafeDataService.getValue(
        DashboardCalculator.getYearDiff(config, dashboardHolderFacade)
      );
      const isNegativeChangeGood = config["isNegativeChangeGood"];

      setSummaryData({
        title: element["name"],
        value: summarySafeData.value,
        unit: unit,
        change: change,
        changeDescription: t("dashboards.prev-period-comparison-description"),
        isNegativeChangeGood: isNegativeChangeGood,
        description: element["description"],
        isDataComplete: summarySafeData.status === DATA_STATUS.COMPLETE,
      });
    }
  }, [dashboardDataContext.lastDataUpdated]);

  if (dashboardDataContext.isLoadingData || !isDataLoaded) {
    return (
      <MainSummaryCardView
        title={element["name"]}
        description={element["description"]}
        isLoading={true}
      />
    );
  }

  return (
    <MainSummaryCardView
      title={summaryData.title}
      description={summaryData.description}
      value={summaryData.value}
      unit={summaryData.unit}
      change={summaryData.change}
      changeDescription={summaryData.changeDescription}
      isNegativeChangeGood={summaryData.isNegativeChangeGood}
      isDataComplete={summaryData.isDataComplete}
    />
  );
};

export default MainSummaryCard;
