import {
  TARGET_TOPIC,
  TARGET_TOPIC_DISPLAY_LABEL,
} from "../../config/constants";
import GVFormGroup from "../common/GVFormGroup";
import React from "react";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconBulb } from "@tabler/icons-react";

const InputTargetStepSelectTopic = (props) => {
  return (
    <GVFormGroup>
      <div className="gv-body-2--bold gvds-color--gray6">{props.title}</div>
      <div className="create-target-form__items-container p-3">
        <div
          className={
            "create-target-form__box" +
            (props.topic === TARGET_TOPIC.SOCIAL ? " active" : "")
          }
          onClick={() => props.setTopic(TARGET_TOPIC.SOCIAL)}
        >
          <div className="target-topic-name">
            {TARGET_TOPIC_DISPLAY_LABEL[TARGET_TOPIC.SOCIAL]}
          </div>
          <div className="target-topic-description">
            Set targets for data such as volunteer hours, cash contribution and
            in-kind donation value that are measured in the portal.
          </div>
        </div>
        <div
          className={
            "create-target-form__box" +
            (props.topic === TARGET_TOPIC.ENVIRONMENTAL ? " active" : "")
          }
          onClick={() => props.setTopic(TARGET_TOPIC.ENVIRONMENTAL)}
        >
          <div className="target-topic-name">
            {TARGET_TOPIC_DISPLAY_LABEL[TARGET_TOPIC.ENVIRONMENTAL]}
          </div>
          <div className="target-topic-description">
            Set targets for environmental data such as energy, water, waste and
            GHG emissions that are measured in the portal.
          </div>
        </div>
        {props.children}
      </div>
      {props.topic === TARGET_TOPIC.FREE_FORM && (
        <div className="gvds-text--body mt-4">
          <div className="text-vertical-center">
            <GVDSIcon Icon={IconBulb} className="me-1" /> Quick tips on naming
            your target...
          </div>
          <ul>
            <li>
              <span className="body-1-bold">Measurable</span> - Consider metrics
              or definable traits that can help you evaluate progress
            </li>
            <li>
              <span className="body-1-bold">Time-based</span> - Give a deadline
              or timeframe to plan around target completion
            </li>
            <li>
              <span className="body-1-bold">Specific</span> - Make it focused
              and narrow for more effective planning
            </li>
          </ul>
          <ul className="mt-2">
            <li>E.g. Achieve GSTC certification by 2022.</li>
            <li>
              E.g. Achieve 100% elimination of single-use plastics by 2023.
            </li>
            <li>
              E.g. Hire 6 underprivileged youths into introductory roles as part
              of our Youth Employment Program in 2021.
            </li>
          </ul>
        </div>
      )}
      {props.isInvalid && (
        <div className="manual-invalid-feedback" style={{ marginLeft: "16px" }}>
          Please complete step 1.
        </div>
      )}
    </GVFormGroup>
  );
};

export default InputTargetStepSelectTopic;
