import moment from "moment";
import { DATA_STATUS } from "../../../../config/constants";
import PerformanceReportFormatService from "../PerformanceReportFormatService";
import SafeDataService, {
  NA_VALUE,
  NO_VALUE,
} from "../../../../services/SafeDataService";
import { buildChartTheme, lightTheme } from "@visx/xychart";
import find from "lodash/find";
import capitalize from "lodash/capitalize";
import { DateTimeUtils } from "../../../../services/UtilsService";
import {GVDSColors} from "../../../../styles/gvds-colors";

export const PERFORMANCE_CHART_TYPE = {
  LINE: "Line",
  BENCHMARK: "Benchmark",
};

export const PERFORMANCE_CHART_COLOR = {
  BLUE: GVDSColors.info,
  LIGHT_BLUE: GVDSColors.infoLight,
  GREEN: GVDSColors.success,
  YELLOW: GVDSColors.warning,
  RED: GVDSColors.error,
  GRAY_36: GVDSColors.gray9,
  GRAY_56: GVDSColors.gray6,
  GRAY_93: GVDSColors.gray3,
  WHITE: GVDSColors.white,
  DEFAULT: GVDSColors.gray10,
};

export const customTheme = buildChartTheme({
  ...lightTheme,
  backgroundColor: PERFORMANCE_CHART_COLOR.WHITE,
  colors: [
    PERFORMANCE_CHART_COLOR.BLUE,
    PERFORMANCE_CHART_COLOR.GREEN,
    PERFORMANCE_CHART_COLOR.GRAY_56,
    PERFORMANCE_CHART_COLOR.YELLOW,
  ],
});

export class PerformanceReportChartDatumService {
  static getLabel(defaultLabel, value) {
    const label = defaultLabel;
    if (SafeDataService.getValue(value) === NA_VALUE) {
      return "- (no available data)";
    } else if (SafeDataService.getValue(value) === NO_VALUE) {
      return label + " (no available data)";
    } else if (value.status === DATA_STATUS.MISSING) {
      return label + " (missing data)";
    }
    return label;
  }
  static getColor(value) {
    if (
      SafeDataService.getValue(value) !== NO_VALUE &&
      SafeDataService.getValue(value) !== NA_VALUE &&
      value.status === DATA_STATUS.MISSING
    ) {
      return PERFORMANCE_CHART_COLOR.RED;
    }
    return PERFORMANCE_CHART_COLOR.DEFAULT;
  }

  static getX(mon) {
    return moment(`2020-${mon}-01`).toDate();
  }

  static getY(reportMonths, mon, value) {
    if (
      reportMonths.indexOf(Number(mon)) < 0 ||
      SafeDataService.getValue(value) === NO_VALUE ||
      SafeDataService.getValue(value) === NA_VALUE
    ) {
      return undefined;
    }
    return value.value;
  }
}

export default class PerformanceReportChartService {
  static getReportYears(report) {
    return Object.keys(report["yearly_reports"])
      .filter((y) => `${y}`.length === 4)
      .sort();
  }

  static getYearlyData(
    chartType,
    report,
    reportYears,
    reportMonths,
    showAsPercentage
  ) {
    return reportYears.map((r) => {
      const yearly = report["yearly_reports"][r];
      return {
        dataKey: r,
        data: Object.entries(yearly[chartType]["monthly"]).map(([mon, val]) => {
          const value = showAsPercentage
            ? SafeDataService.multiply(val, 100)
            : val;
          return {
            x: PerformanceReportChartDatumService.getX(mon),
            y: PerformanceReportChartDatumService.getY(
              reportMonths,
              mon,
              value
            ),
            color: PerformanceReportChartDatumService.getColor(val),
            label: PerformanceReportChartDatumService.getLabel(
              PerformanceReportFormatService.renderFormattedValue(
                report["report_type"],
                report["title"],
                SafeDataService.getValue(value)
              ),
              value
            ),
          };
        }),
      };
    });
  }

  static getMissingData(
    chartType,
    report,
    reportYears,
    reportMonths,
    showAsPercentage
  ) {
    let missingData = [];
    for (let year of reportYears) {
      const yearly = report["yearly_reports"][year];
      const data = Object.entries(yearly[chartType]["monthly"])
        .filter(([mon, val]) => {
          return (
            reportMonths.indexOf(Number(mon)) >= 0 &&
            val.status === DATA_STATUS.MISSING &&
            SafeDataService.getValue(val) !== NO_VALUE &&
            SafeDataService.getValue(val) !== NA_VALUE
          );
        })
        .map(([mon, val]) => ({
          x: PerformanceReportChartDatumService.getX(mon),
          y: showAsPercentage ? val.value * 100 : val.value,
        }));
      if (data.length > 0) {
        missingData.push({
          dataKey: year + DATA_STATUS.MISSING,
          data,
        });
      }
    }
    return missingData;
  }

  static getTitleDetails(chartType, columnGroups) {
    const column = find(columnGroups, (c) => c["data_key"] === chartType);
    return column ? `– ${capitalize(chartType)} (${column["unit_id"]})` : "";
  }

  static getDownloadImageName(
    resourceName,
    reportTitle,
    chartType,
    reportYears
  ) {
    const nameDetails = [
      resourceName,
      reportTitle,
      capitalize(chartType),
      reportYears.join(","),
      DateTimeUtils.getTimestamp(),
    ];
    return nameDetails.join("_");
  }
}
