import React, { useContext, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";

import GVFormGroup from "../common/GVFormGroup";
import FileStorageStateContext, {
  FILE_STORAGE_PAGES,
} from "../../context/FileStorageStateContext";
import { MultipleFilesUploader } from "../common/FileAttachments";
import { FileUtils } from "../../services/UtilsService";
import { DEFAULT_MAX_FILE_SIZE_IN_MB, RESOURCES } from "../../config/constants";
import FileStorageService, {
  FILE_MODULE_BY_PATH,
} from "../../services/FileStorageService";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import FileStorageEdit from "./FileStorageEdit";
import UserManagedTagPicker from "../common/CentralTags/UserManagedTagPicker";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormMultiSelect from "../../gvds-components/Forms/GVDSFormMultiSelect";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import { Trans, useTranslation } from "react-i18next";
import SupportContactLink from "../common/SupportContactLink";

const FILE_STORAGE_UPLOAD_STATE = {
  INPUT: "INPUT",
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED",
  EDITING: "EDITING",
};

const FILE_STORAGE_MAX_SIZE_IN_MB = DEFAULT_MAX_FILE_SIZE_IN_MB;

const FileStorageUpload = () => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const fileStorageStateContext = useContext(FileStorageStateContext);

  const [fileStorageUploadState, setFileStorageUploadState] = useState(
    FILE_STORAGE_UPLOAD_STATE.INPUT
  );

  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [associatedModuleOptions, setAssociatedModuleOptions] = useState([]);
  const [portfolioTagOptions, setPortfolioTagOptions] = useState([]);
  const [siteTagOptionsBySiteIds, setSiteTagOptionsBySiteIds] = useState([]);

  const [files, setFiles] = useState([]);
  const [associatedModules, setAssociatedModules] = useState([]);
  const [portfolioTags, setPortfolioTags] = useState([]);
  const [siteTags, setSiteTags] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [fileNameConflictMessage, setFileNameConflictMessage] = useState("");
  const [conflictedFileNames, setConflictedFileNames] = useState([]);

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;

    FileStorageService.getAllFileStorageOptions(
      selectedInventory.type,
      selectedInventory.id
    )
      .then((data) => {
        setAssociatedModuleOptions(data.associated_modules);
        setPortfolioTagOptions(data.portfolio_tags);
        setSiteTagOptionsBySiteIds(data.site_tags_by_site_ids);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to load file storage options</span>
        );
      })
      .finally(() => {
        setIsLoadingOptions(false);
      });
  }, []);

  useEffect(() => {
    if (conflictedFileNames.length > 0) {
      setErrorMessage(
        <>
          {fileNameConflictMessage}
          {conflictedFileNames?.join("\n")}
        </>
      );
    } else {
      setErrorMessage(null);
    }
  }, [conflictedFileNames]);

  useEffect(() => {
    onUploadAttributeChange();
  }, [files, associatedModules, portfolioTags, siteTags]);

  const goToFileStorageMain = () => {
    fileStorageStateContext.changePage(FILE_STORAGE_PAGES.MAIN);
  };

  const editUploadedFiles = () => {
    setFileStorageUploadState(FILE_STORAGE_UPLOAD_STATE.EDITING);
  };

  const isFileSizeValid = () => {
    return (
      FileUtils.getFilesWhichSizeLargerThan(files, FILE_STORAGE_MAX_SIZE_IN_MB)
        .length === 0
    );
  };

  const onUploadAttributeChange = () => {
    const hasChanges =
      associatedModules.length > 0 ||
      portfolioTags.length > 0 ||
      siteTags.length > 0 ||
      files.length > 0;
    fileStorageStateContext.setHasChanges(hasChanges);
  };

  const backToAllFiles = () => {
    fileStorageStateContext.setHasChanges(false);
    goToFileStorageMain();
  };

  const uploadFiles = async () => {
    if (isFileSizeValid()) {
      const selectedInventory = userInventory.selectedInventory.get;

      setFileStorageUploadState(FILE_STORAGE_UPLOAD_STATE.UPLOADING);
      fileStorageStateContext.setIsLoading(true);

      try {
        const fileNames =
          FileStorageService.getFileNamesValidationPayloadForUpload(
            files,
            selectedInventory.id
          );

        await FileStorageService.validateFileNames(
          selectedInventory.type,
          selectedInventory.id,
          fileNames
        );
      } catch (e) {
        fileStorageStateContext.setIsLoading(false);
        setFileNameConflictMessage(e.response.data.message.message);
        setConflictedFileNames(e.response.data.message.conflicts);
        setFileStorageUploadState(FILE_STORAGE_UPLOAD_STATE.INPUT);
        return;
      }

      try {
        const uploadedFileStorageObjects = await FileStorageService.uploadFiles(
          selectedInventory.type,
          selectedInventory.id,
          files,
          associatedModules,
          portfolioTags,
          siteTags
        );

        setUploadedFiles(uploadedFileStorageObjects);

        fileStorageStateContext.setIsLoading(false);
        fileStorageStateContext.setHasChanges(false);
        setFileStorageUploadState(FILE_STORAGE_UPLOAD_STATE.UPLOADED);
      } catch (e) {
        setErrorMessage(
          <>
            <Trans i18nKey="file-storage.upload.upload-error-message">
              Unknown error occurred. Please contact{" "}
              <SupportContactLink subject="File Upload Error" />
              with the file you intended to upload or try again. Note that
              uploading a folder is unsupported.
            </Trans>
          </>
        );
        fileStorageStateContext.setIsLoading(false);
        setFileStorageUploadState(FILE_STORAGE_UPLOAD_STATE.INPUT);
      }
    }
  };

  const onRemoveNewFileFromFiles = () => {
    setConflictedFileNames(
      conflictedFileNames.filter((cf) => {
        if (files.findIndex((f) => f.name === cf) > -1) {
          return cf;
        }
      })
    );
  };

  if (fileStorageUploadState === FILE_STORAGE_UPLOAD_STATE.INPUT) {
    const selectedInventory = userInventory.selectedInventory.get;

    return (
      <FileStorageUploadInput
        files={files}
        setFiles={setFiles}
        associatedModules={associatedModules}
        setAssociatedModules={setAssociatedModules}
        portfolioTags={portfolioTags}
        setPortfolioTags={setPortfolioTags}
        siteTags={siteTags}
        setSiteTags={setSiteTags}
        uploadFiles={uploadFiles}
        isLoadingOptions={isLoadingOptions}
        associatedModuleOptions={associatedModuleOptions}
        portfolioTagOptions={portfolioTagOptions}
        siteTagOptions={siteTagOptionsBySiteIds[selectedInventory.id] ?? []}
        errorMessage={errorMessage}
        backToAllFiles={backToAllFiles}
        onRemoveNewFileFromFiles={onRemoveNewFileFromFiles}
      />
    );
  } else if (fileStorageUploadState === FILE_STORAGE_UPLOAD_STATE.UPLOADING) {
    return <FileStorageUploading />;
  } else if (fileStorageUploadState === FILE_STORAGE_UPLOAD_STATE.UPLOADED) {
    return (
      <FileStorageUploadDone
        fileStorageObjects={uploadedFiles}
        goToFileStorageMain={goToFileStorageMain}
        editUploadedFiles={editUploadedFiles}
      />
    );
  } else if (fileStorageUploadState === FILE_STORAGE_UPLOAD_STATE.EDITING) {
    return (
      <FileStorageEdit
        filesToBeEdited={uploadedFiles}
        goToFileStorageMain={goToFileStorageMain}
      />
    );
  } else {
    return null;
  }
};

const FileStorageUploadInput = ({
  files,
  setFiles,
  uploadFiles,
  associatedModules,
  setAssociatedModules,
  portfolioTags,
  setPortfolioTags,
  siteTags,
  setSiteTags,
  isLoadingOptions,
  associatedModuleOptions,
  portfolioTagOptions,
  siteTagOptions,
  errorMessage,
  backToAllFiles,
  onRemoveNewFileFromFiles,
}) => {
  const { t } = useTranslation();

  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  useEffect(() => {
    if (
      !isLoadingOptions &&
      associatedModuleOptions &&
      associatedModuleOptions.length > 0
    ) {
      const currentPath = Object.keys(FILE_MODULE_BY_PATH).find((path) =>
        matchPath(location.pathname, {
          path: path,
          exact: false,
        })
      );

      const matchingAssociatedModules = FILE_MODULE_BY_PATH[currentPath]
        ? FILE_MODULE_BY_PATH[currentPath]
            .map((m) => {
              const matchingModule = associatedModuleOptions.find(
                (am) => am.name === m
              );

              return matchingModule
                ? {
                    id: matchingModule.id,
                    name: matchingModule.name,
                  }
                : null;
            })
            .filter((m) => !!m)
        : [];

      setAssociatedModules(currentPath ? matchingAssociatedModules : []);
    }
  }, [location.pathname, isLoadingOptions, associatedModuleOptions]);

  const isSite =
    userInventory.selectedInventory.get &&
    selectedInventory.type === RESOURCES.SITE;

  const showPortfolioTag =
    !isSite ||
    (userInventory.selectedTreeNode.get &&
      userInventory.selectedTreeNode.get.nodeValue.value &&
      userInventory.selectedTreeNode.get.nodeValue.value.is_part_of_contract);

  return (
    <>
      <PageHeader.BackButton
        text={t("file-storage.upload.back-page-label")}
        onClick={backToAllFiles}
      />
      <div className="file-storage-header">
        <h1>{t("file-storage.upload.page-title")}</h1>
      </div>
      <div className="file-storage-body">
        {isLoadingOptions && <LoadingSpinner />}
        {!isLoadingOptions && (
          <>
            {errorMessage && (
              <GVDSBanner
                title={t("file-storage.upload.error-while-uploading")}
                variant={GVDSBanner.Variants.error}
              >
                <div style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</div>
              </GVDSBanner>
            )}
            <GVFormGroup>
              <MultipleFilesUploader
                files={files}
                setFiles={setFiles}
                maxFileSizeInMB={FILE_STORAGE_MAX_SIZE_IN_MB}
                onRemoveNewFileFromFiles={onRemoveNewFileFromFiles}
              />
            </GVFormGroup>
            {showPortfolioTag && (
              <GVFormGroup controlId="fileStoragePortfolioTags">
                <Form.Label>
                  {t("shared-input-label.portfolio-tags")}
                </Form.Label>
                <span className="optional-form-label ms-1">
                  ({t("shared-input-label.optional")})
                </span>
                <div className="form-label-description">
                  {t("user-managed-tag.about-tags.portfolio-tag-description")}
                </div>
                <UserManagedTagPicker
                  resourceType={RESOURCES.PORTFOLIO}
                  selectedTags={portfolioTags}
                  allTags={portfolioTagOptions}
                  onChange={(selectedPortfolioTagsInput) => {
                    setPortfolioTags(
                      selectedPortfolioTagsInput.map((tag) => {
                        return { id: tag.value, name: tag.label };
                      })
                    );
                  }}
                  className="portfolio-tag"
                />
              </GVFormGroup>
            )}
            <GVFormGroup controlId="fileStorageSiteTags">
              <Form.Label>{t("shared-input-label.portfolio-tags")}</Form.Label>
              <span className="optional-form-label ms-1">
                ({t("shared-input-label.optional")})
              </span>
              <div className="form-label-description">
                {t("user-managed-tag.about-tags.site-tag-description")}
              </div>
              <UserManagedTagPicker
                resourceType={RESOURCES.SITE}
                selectedTags={siteTags}
                allTags={siteTagOptions}
                onChange={(selectedSiteTagsInput) => {
                  setSiteTags(
                    selectedSiteTagsInput.map((tag) => {
                      return { id: tag.value, name: tag.label };
                    })
                  );
                }}
                className="site-tag"
              />
            </GVFormGroup>
            <GVFormGroup controlId="associatedModules">
              <Form.Label>
                {t("file-storage.shared.label-associated-modules")}
              </Form.Label>
              <span className="optional-form-label ms-1">
                ({t("shared-input-label.optional")})
              </span>
              <div className="form-label-description">
                {t("file-storage.upload.label-description-associated-modules")}
              </div>
              <GVDSFormMultiSelect
                name="associated-modules"
                closeMenuOnSelect={false}
                isClearable={false}
                value={associatedModules.map((m) => {
                  return { label: m.name, value: m.id };
                })}
                onSelect={(values) => {
                  setAssociatedModules(
                    values.map((v) => {
                      return { id: v.value, name: v.label };
                    })
                  );
                }}
                options={associatedModuleOptions.map((o) => ({
                  label: o.name,
                  value: o.id,
                }))}
              />
            </GVFormGroup>
            <div className="d-flex mt-2">
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                className="ms-auto me-1"
                onClick={backToAllFiles}
                text={t("shared-modal.footer.cancel")}
              />
              <GVDSButton
                variant={buttonVariant.primary}
                onClick={uploadFiles}
                disabled={
                  files.length === 0 ||
                  FileUtils.getFilesWhichSizeLargerThan(
                    files,
                    FILE_STORAGE_MAX_SIZE_IN_MB
                  ).length > 0
                }
                text={t("shared-modal.footer.upload")}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const FileStorageUploading = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="file-storage-header no-space-above">
        <h1>{t("file-storage.upload.uploading-page-title")}</h1>
      </div>
      <div className="file-storage-body">
        <div className="mb-4">{t("file-storage.upload.uploading-message")}</div>
        <LoadingSpinner />
        <div className="mt-2 color-red text-center">
          {t("file-storage.upload.uploading-warning")}
        </div>
      </div>
    </>
  );
};

const FileStorageUploadDone = ({
  fileStorageObjects,
  goToFileStorageMain,
  editUploadedFiles,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader.BackButton
        text={t("file-storage.upload.back-page-label")}
        onClick={goToFileStorageMain}
      />
      <div className="file-storage-header">
        <h1>{t("file-storage.upload.upload-done-page-title")}</h1>
      </div>
      <div className="file-storage-body">
        <div>
          {t("file-storage.upload.upload-done-message")}
          <ul>
            {fileStorageObjects.map((f) => {
              return <li key={f.id}>{f.name}</li>;
            })}
          </ul>
        </div>
        <div className="d-flex mt-2">
          <GVDSButton
            variant={buttonVariant.tertiary}
            className="ms-auto me-2"
            onClick={editUploadedFiles}
            text={t("file-storage.upload.button-edit-file-information")}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={goToFileStorageMain}
            text={t("file-storage.upload.back-page-label")}
          />
        </div>
      </div>
    </>
  );
};

export default FileStorageUpload;
