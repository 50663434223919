import { tobe_deleted_entry_prefix } from "../../../config/constants";
import axios from "axios";
import { API_URL } from "../../../config/api-config";
import { CertificationRequirementModel } from "../../../components/SystemToolbox/Certification/CertificationModel";

export default class CertificationRequirementAdminService {
  static getRequirements = async (certification_id, criteria_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/certifications/${certification_id}/criteria/${criteria_id}/requirements`
      );
      return response.data.map((requirement) =>
        CertificationRequirementModel.fromDTO(requirement)
      );
    } catch (error) {
      throw error;
    }
  };

  static prepareInputRequirementAPIFormData(requirementData) {
    const formData = new FormData();
    const dataToSend = {
      number: requirementData["number"],
      name: requirementData["name"],
      instruction: requirementData["instruction"],
      instruction_greenview_portal:
        requirementData["instruction_greenview_portal"],
      greenview_guidance: requirementData["greenview_guidance"],
      additional_information: requirementData["additional_information"],
      subrequirement_sections: requirementData["subrequirement_sections"]
        .filter((section) => !section.id.startsWith(tobe_deleted_entry_prefix))
        .map((section, sectionIndex) => ({
          id: section.id,
          section_name: section.sectionName,
          order: sectionIndex + 1,
          subrequirements: section.subrequirements
            .filter(
              (subreq) => !subreq.id.startsWith(tobe_deleted_entry_prefix)
            )
            .map((subreq, subreqIndex) => ({
              ...subreq,
              order: subreqIndex + 1,
            })),
        })),
      sample_evidences: [],
      sample_evidence_ids_with_file_to_be_removed: [],
    };

    requirementData.sample_evidences.forEach((evidence, index) => {
      dataToSend.sample_evidences.push({
        id: evidence.id,
        name: evidence.name,
        file_ids: evidence.files.map((file) => file.id),
        file_key:
          evidence.files.length > 0 ? `evidence_file_${evidence.id}` : null,
      });

      if (evidence.files.length > 0) {
        evidence.files.forEach((file) => {
          if (file.isNew()) {
            formData.append(`evidence_file_${evidence.id}`, file.file);
          } else if (file.isToBeDeleted()) {
            dataToSend.sample_evidence_ids_with_file_to_be_removed.push(
              evidence.id
            );
          }
        });
      }
    });

    formData.append("data", JSON.stringify(dataToSend));
    return formData;
  }

  static createRequirement = async (
    certification_id,
    criteria_id,
    requirementData
  ) => {
    try {
      const formData = this.prepareInputRequirementAPIFormData(requirementData);
      const response = await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certification_id}/criteria/${criteria_id}/requirements`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateRequirement = async (
    certification_id,
    criteria_id,
    requirement_id,
    requirementData
  ) => {
    try {
      const formData = this.prepareInputRequirementAPIFormData(requirementData);
      const response = await axios.put(
        `${API_URL}/api/v1/admin/certifications/${certification_id}/criteria/${criteria_id}/requirement/${requirement_id}`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteRequirement = async (
    certification_id,
    criteria_id,
    requirement_id
  ) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/admin/certifications/${certification_id}/criteria/${criteria_id}/requirement/${requirement_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static moveRequirement = async (
    certification_id,
    criteria_id,
    requirementToBeMoved,
    criteriaToMoveTo,
    requirementToMoveTo,
    position
  ) => {
    try {
      const data = {
        criteria_to_move_to_id: criteriaToMoveTo.id,
        requirement_to_move_to_id: requirementToMoveTo
          ? requirementToMoveTo.id
          : null,
        position,
      };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certification_id}/criteria/${criteria_id}/requirements/${requirementToBeMoved.id}/move`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
