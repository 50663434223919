import React from "react";
import { CONTRACT_STATUS } from "../../config/constants";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../services/UtilsService";

const statusToColorMap = {
  [CONTRACT_STATUS.DRAFT]: StatusLabel.Colors.gray,
  [CONTRACT_STATUS.ACTIVE]: StatusLabel.Colors.green,
  [CONTRACT_STATUS.INACTIVE]: StatusLabel.Colors.red,
};

const ContractStatusBadge = ({ status }) => {
  const colorVariant = statusToColorMap[status];
  const capitalisedStatus = StringUtils.capitaliseWord(status);

  return <StatusLabel color={colorVariant}>{capitalisedStatus}</StatusLabel>;
};

export default ContractStatusBadge;
