
const GVDSTimezoneDisplay = ({ className }) => {
  return (
    <div className={"gvds-text--caption " + className ?? ""}>
      Time Zone: UTC
    </div>
  );
};

export default GVDSTimezoneDisplay;
