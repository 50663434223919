import React, { useContext, useRef, useState } from "react";
import DonutChart from "./DonutChart";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import DonutLegend from "./DonutLegend";
import { NumberService } from "../../../../services/UtilsService";
import { TOOLTIP_PLACEMENTS } from "../../../../config/style-config";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { ClassificationDonutChartUtils } from "./ClassificationDonutChartUtils";
import { ParentSize } from "@visx/responsive";
import ExpandedClassificationDonutChartView from "./ExpandedClassificationDonutChartView";
import DashboardMinifiedHeaderControls from "../DashboardMinifiedHeaderControls";
import {
  downloadChartAsImageTimeoutInMs,
  expandedModalViewOptionEnum,
} from "../../Models/DashboardModels";
import DashboardDataGapsIndicator from "../DashboardDataGapsIndicator";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import DashboardDataContext from "../../Context/DashboardDataContext";
import { DashboardTableExcelDownloader } from "../../DashboardTableExcelDownloader";
import { classificationDonutChartConfig } from "./ClassificationDonutChartViewConfig";
import { WidgetTypeEnum } from "../../Models/WidgetModels";
import JoinedBullet from "../../../common/JoinedBullet";

const ClassificationDonutChartViewHeader = ({
  title,
  titleInfoTooltip,
  hasDataGaps,
  period,
  unit,
  showExpandedChartView,
  showExpandedTableView,
  hasData,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  const dashboardDataContext = useContext(DashboardDataContext);

  return (
    <div className="header-container">
      <div>
        <div className="title-container">
          <div className="title">{title}</div>
          {!!titleInfoTooltip && (
            <InfoTooltip
              info={titleInfoTooltip}
              placement={TOOLTIP_PLACEMENTS.TOP}
            />
          )}
          {hasDataGaps && <DashboardDataGapsIndicator />}
        </div>
        <div className="subtitle-container">
          <div className="subtitle">{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div className="subtitle">{unit}</div>
        </div>
      </div>
      {!dashboardDataContext.isDownloadingDashboardAsImage && (
        <div
          id={
            classificationDonutChartConfig.miniViewExcludeOnDownloadContainerId
          }
          className="dashboard-chart-header__action-buttons"
        >
          <DashboardMinifiedHeaderControls
            showExpandedTableView={showExpandedTableView}
            showExpandedChartView={showExpandedChartView}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
            disabled={!hasData}
            isDownloading={isDownloadingChart}
          />
        </div>
      )}
    </div>
  );
};

export const ClassificationDonutChartViewContent = ({
  chartData,
  unit,
  totalValue,
  chartThickness,
  shouldShowAllItems = false,
}) => {
  const [isExpandLegends, setIsExpandLegends] = useState(shouldShowAllItems);

  let displayedData = ClassificationDonutChartUtils.getDisplayedData(
    isExpandLegends,
    chartData
  );

  return (
    <>
      <div className="chart">
        <ParentSize>
          {(parent) => (
            <DonutChart
              width={parent.width}
              height={parent.height}
              thickness={chartThickness}
              margin={classificationDonutChartConfig.chartMarginInPixel}
              data={displayedData}
              unit={unit}
              totalValue={totalValue}
            />
          )}
        </ParentSize>
      </div>
      <div className="legend">
        <DonutLegend
          legendItems={displayedData}
          totalValue={totalValue}
          totalItemCount={chartData.length}
          isExpandLegends={isExpandLegends}
          onToggleExpandLegends={() => setIsExpandLegends(!isExpandLegends)}
          shouldShowAllItems={shouldShowAllItems}
        />
      </div>
    </>
  );
};

const ClassificationDonutChartViewFooter = ({
  footerLabel,
  unit,
  totalValue,
}) => {
  return (
    <div className="footer-container">
      <div className="footer-label">{footerLabel}:</div>
      <div className="gvds-text--caption">
        <strong>{NumberService.format(totalValue)}</strong> {unit}
      </div>
    </div>
  );
};

const ClassificationDonutChartView = ({
  isLoading,
  title,
  titleInfoTooltip,
  hasDataGaps = false,
  period,
  chartData,
  totalValue,
  footerLabel,
  unit,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isShowExpandedChartView, setIsShowExpandedChartView] = useState(false);
  const [selectedExpandedViewOption, setSelectedExpandedViewOption] =
    useState(null);
  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const isDownloadingFromTableView = useRef(false);

  const hasData =
    !!chartData &&
    chartData.length > 0 &&
    chartData.reduce((total, datum) => total + datum.value, 0) > 0;

  const showExpandedChartView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
    setIsShowExpandedChartView(true);
  };

  const showExpandedTableView = () => {
    setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
    setIsShowExpandedChartView(true);
  };

  let content;
  if (isLoading) {
    content = (
      <div className="dashboard--classification-donut-chart__mini-view-box">
        <LoadingSpinner />
      </div>
    );
  } else {
    const fileName = DashboardWidgetSharedUtils.getDownloadFileName(
      userInventory.selectedTreeNode.get?.nodeValue?.name || "",
      WidgetTypeEnum.ClassificationDonutChart,
      title,
      period
    );

    const downloadChartAsImage = async (
      chartViewId,
      isFromExpandedView,
      excludeOnDownloadIds
    ) => {
      if (selectedExpandedViewOption === expandedModalViewOptionEnum.TABLE) {
        isDownloadingFromTableView.current = true;
        setSelectedExpandedViewOption(expandedModalViewOptionEnum.CHART);
      }
      setIsDownloadingChart(true);

      setTimeout(async () => {
        try {
          await DashboardWidgetSharedUtils.downloadChartAsImage(
            chartViewId,
            fileName,
            excludeOnDownloadIds,
            isFromExpandedView
          );
        } catch (e) {
          toastContext.addFailToast(
            <span>Failed to download chart as image.</span>
          );
        } finally {
          setIsDownloadingChart(false);
          if (isDownloadingFromTableView.current) {
            setSelectedExpandedViewOption(expandedModalViewOptionEnum.TABLE);
            isDownloadingFromTableView.current = false;
          }
        }
      }, downloadChartAsImageTimeoutInMs);
    };

    const onDownloadTableAsExcel = () => {
      const excelSheetName = "Table";
      const headerRows = [
        ["Period", period],
        [],
        ["Type", "Percentage", `Value (${unit})`],
      ];

      const dataRows = chartData.map((donutChartData) => {
        return donutChartData.toTableRowData(totalValue);
      });

      DashboardTableExcelDownloader.downloadExcel(
        headerRows,
        dataRows,
        fileName,
        excelSheetName
      );
    };

    const donutChartId = `${classificationDonutChartConfig.chartViewId}-${title}`;

    content = (
      <>
        <div
          id={donutChartId}
          className="dashboard--classification-donut-chart__mini-view-box"
        >
          <ClassificationDonutChartViewHeader
            title={title}
            titleInfoTooltip={titleInfoTooltip}
            period={period}
            unit={unit}
            hasDataGaps={hasDataGaps}
            showExpandedChartView={showExpandedChartView}
            showExpandedTableView={showExpandedTableView}
            hasData={hasData}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(donutChartId, false, [
                classificationDonutChartConfig.miniViewExcludeOnDownloadContainerId,
              ])
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
          {hasData ? (
            <>
              <div className="content-container">
                <ClassificationDonutChartViewContent
                  chartData={chartData}
                  unit={unit}
                  totalValue={totalValue}
                  chartThickness={25}
                />
              </div>
              <ClassificationDonutChartViewFooter
                footerLabel={footerLabel}
                unit={unit}
                totalValue={totalValue}
              />
            </>
          ) : (
            <div className="content-container">
              <div className="table__no_content h-100">No data available</div>
            </div>
          )}
        </div>
        {hasData && (
          <ExpandedClassificationDonutChartView
            isShowExpandedClassificationChartView={isShowExpandedChartView}
            onCloseModal={() => setIsShowExpandedChartView(false)}
            title={title}
            period={period}
            hasDataGaps={hasDataGaps}
            selectedExpandedViewOption={selectedExpandedViewOption}
            onSelectViewOption={(option) =>
              setSelectedExpandedViewOption(option)
            }
            chartData={chartData}
            unit={unit}
            totalValue={totalValue}
            footerLabel={footerLabel}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={() =>
              downloadChartAsImage(
                classificationDonutChartConfig.expandedChartViewId,
                true,
                [
                  classificationDonutChartConfig.expandedViewExcludeOnDownloadContainerId,
                ]
              )
            }
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        )}
      </>
    );
  }

  return content;
};

export default ClassificationDonutChartView;
