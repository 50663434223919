import React, { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import withAuthentication from "../HOC/withAuthentication";
import UserInventoryContext from "../../context/UserInventoryContext";
import {
  ENVIRONMENTAL_DATA,
  ENVIRONMENTAL_DATA_BULK_INPUT,
  ENVIRONMENTAL_DATA_METER,
  ENVIRONMENTAL_DATA_SETUP,
  MARKET_PURCHASE_OFFSETS_TRACKER,
  OPERATIONAL_DATA,
  OPERATIONAL_DATA_BULK_INPUT,
  OPERATIONAL_DATA_METER,
  OPERATIONAL_DATA_SETUP,
  OVERVIEW_DATA,
  OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT,
  OVERVIEW_DATA_OPERATIONAL_BULK_INPUT,
  PEOPLE_DATA,
  PEOPLE_DATA_METER,
  PEOPLE_DATA_SETUP,
  VIEW_ALL_INITIATIVES,
} from "../../config/ROUTES_NAME";
import Environmental from "./Environmental/Environmental";
import Overview from "./Overview/Overview";
import BulkEnvironmentalDataInput from "./Environmental/BulkEnvironmentalDataInput";
import Operational from "./Operational/Operational";
import OperationalMeterSetup from "./Operational/OperationalMeterSetup";
import ViewEnvironmentalMeterDetails from "./Environmental/ViewEnvironmentalMeterDetails";
import Initiatives from "./initiatives/Initiatives";
import BulkOperationalDataInput from "./Operational/BulkOperationalDataInput";
import { RESOURCES } from "../../config/constants";
import ViewOperationalMeterDetails from "./Operational/ViewOperationalMeterDetails";
import People from "./People/People";
import PeopleMeterSetup from "./People/PeopleMeterSetup";
import ViewPeopleMeterDetails from "./People/ViewPeopleMeterDetails";
import EnvironmentalDataSetup from "./Environmental/EnvironmentalDataSetup";
import ViewMarketPurchaseTrackerDetails from "./Environmental/MarketPurchaseOffsets/ViewMarketPurchaseTrackerDetails";
import { PageWrapper } from "../../gvds-components/Nav/PageWrapper/PageWrapper";

const Data = () => {
  const userInventory = useContext(UserInventoryContext);

  const selectedInventory = userInventory.selectedInventory.get;
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    if (!userInventory.isLoadingInventory.get && selectedInventory) {
      if (selectedInventory.type === RESOURCES.SITE) {
        setNavItems([
          { path: "data/overview", label: "Overview" },
          { path: "data/environmental", label: "Environmental" },
          { path: "data/operational", label: "Operational" },
          { path: "data/people", label: "People" },
          { path: "data/initiatives", label: "Initiatives" },
        ]);
      } else if (selectedInventory.type === RESOURCES.FACILITY) {
        setNavItems([
          { path: "data/overview", label: "Overview" },
          { path: "data/environmental", label: "Environmental" },
          { path: "data/operational", label: "Operational" },
        ]);
      } else {
        setNavItems([{ path: "data/overview", label: "Overview" }]);
      }
    }
  }, [selectedInventory]);

  return (
    <>
      <Switch>
        <Route
          exact
          path={OVERVIEW_DATA}
          render={() => (
            <PageWrapper configRouteName={OVERVIEW_DATA}>
              <Overview />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT}
          render={() => (
            <PageWrapper
              configRouteName={OVERVIEW_DATA_ENVIRONMENTAL_BULK_INPUT}
            >
              <BulkEnvironmentalDataInput />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={OVERVIEW_DATA_OPERATIONAL_BULK_INPUT}
          render={() => (
            <PageWrapper configRouteName={OVERVIEW_DATA_OPERATIONAL_BULK_INPUT}>
              <BulkOperationalDataInput />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={ENVIRONMENTAL_DATA_SETUP}
          render={() => (
            <PageWrapper configRouteName={ENVIRONMENTAL_DATA_SETUP}>
              <EnvironmentalDataSetup />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={ENVIRONMENTAL_DATA_METER}
          render={() => (
            <PageWrapper configRouteName={ENVIRONMENTAL_DATA_METER}>
              <ViewEnvironmentalMeterDetails />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={MARKET_PURCHASE_OFFSETS_TRACKER}
          render={() => (
            <PageWrapper configRouteName={MARKET_PURCHASE_OFFSETS_TRACKER}>
              <ViewMarketPurchaseTrackerDetails />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={ENVIRONMENTAL_DATA_BULK_INPUT}
          render={() => (
            <PageWrapper configRouteName={ENVIRONMENTAL_DATA_BULK_INPUT}>
              <BulkEnvironmentalDataInput />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={ENVIRONMENTAL_DATA}
          render={() => (
            <PageWrapper configRouteName={ENVIRONMENTAL_DATA}>
              <Environmental />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={OPERATIONAL_DATA_SETUP}
          render={() => (
            <PageWrapper configRouteName={OPERATIONAL_DATA_SETUP}>
              <OperationalMeterSetup />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={OPERATIONAL_DATA_METER}
          render={() => (
            <PageWrapper configRouteName={OPERATIONAL_DATA_METER}>
              <ViewOperationalMeterDetails />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={OPERATIONAL_DATA}
          render={() => (
            <PageWrapper configRouteName={OPERATIONAL_DATA}>
              <Operational />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={OPERATIONAL_DATA_BULK_INPUT}
          render={() => (
            <PageWrapper configRouteName={OPERATIONAL_DATA_BULK_INPUT}>
              <BulkOperationalDataInput />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={PEOPLE_DATA}
          render={() => (
            <PageWrapper configRouteName={PEOPLE_DATA}>
              <People />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={PEOPLE_DATA_SETUP}
          render={() => (
            <PageWrapper configRouteName={PEOPLE_DATA_SETUP}>
              <PeopleMeterSetup />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={PEOPLE_DATA_METER}
          render={() => (
            <PageWrapper configRouteName={PEOPLE_DATA_METER}>
              <ViewPeopleMeterDetails />
            </PageWrapper>
          )}
        />
        <Route
          exact
          path={VIEW_ALL_INITIATIVES}
          render={() => (
            <PageWrapper configRouteName={VIEW_ALL_INITIATIVES}>
              <Initiatives />
            </PageWrapper>
          )}
        />
      </Switch>
    </>
  );
};

export default withAuthentication(Data);
