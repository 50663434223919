import axios from "axios";
import { API_URL } from "../config/api-config";
import {
  INITIATIVE_ACCOUNTING_INFORMATION_SOURCE_ORDER,
  INITIATIVE_FIELD_ORDER,
  INITIATIVE_SUBTOPIC_ORDER,
  INITIATIVE_TRACKING_CRITERION_ORDER,
  tobe_deleted_entry_prefix,
} from "../config/constants";
import { DateTimeUtils } from "./UtilsService";
import { InitiativeModel } from "../components/Data/initiatives/InitiativeModel";

export default class InitiativeService {
  static getAllInitiativeOption = async (resource_type, resource_id) => {
    try {
      const query = { resource_type, resource_id };

      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/initiative_options`,
        { params: query }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getAllInitiatives = async (resourceType, resourceId, start, end) => {
    try {
      const params = { resource_type: resourceType, resource_id: resourceId };

      if (start) params["start"] = DateTimeUtils.getUTCISOString(start);
      if (end) params["end"] = DateTimeUtils.getUTCISOString(end);

      const response = await axios.get(`${API_URL}/api/v1/initiatives`, {
        params,
      });

      return response.data.map((initiative) =>
        InitiativeModel.fromDTO(initiative)
      );
    } catch (error) {
      throw error;
    }
  };

  static getActiveInitiatives = async (resourceType, resourceId) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/initiatives/active`, {
        params: { resource_type: resourceType, resource_id: resourceId },
      });

      return response.data.map((initiative) =>
        InitiativeModel.fromDTO(initiative)
      );
    } catch (error) {
      throw error;
    }
  };

  static createInitiative = async (
    resource_type,
    resource_id,
    initiativePayload,
    files
  ) => {
    const query = { resource_type, resource_id };
    const postBody = new FormData();

    postBody.append("data", JSON.stringify(initiativePayload));

    files.forEach((fileAttachmentItemModel) => {
      if (fileAttachmentItemModel.isNew()) {
        postBody.append("file", fileAttachmentItemModel.file);
      }
    });
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/initiatives`,
        postBody,
        {
          params: query,
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editInitiative = async (
    resource_type,
    resource_id,
    initiativeId,
    initiativePayload,
    files
  ) => {
    try {
      const query = { resource_type, resource_id };

      const postBody = new FormData();

      initiativePayload.remove_files = [];

      files.forEach((fileAttachmentItemModel) => {
        if (fileAttachmentItemModel.isNew()) {
          postBody.append("file", fileAttachmentItemModel.file);
        } else if (fileAttachmentItemModel.isToBeDeleted()) {
          initiativePayload.remove_files.push(
            fileAttachmentItemModel.id.slice(tobe_deleted_entry_prefix.length)
          );
        }
      });

      postBody.append("data", JSON.stringify(initiativePayload));

      await axios.put(
        `${API_URL}/api/v1/initiatives/${initiativeId}`,
        postBody,
        {
          params: query,
        }
      );

      return null;
    } catch (error) {
      throw error;
    }
  };

  static deleteInitiative = async (
    resource_type,
    resource_id,
    initiativeId
  ) => {
    try {
      const query = { resource_type, resource_id };

      await axios.delete(`${API_URL}/api/v1/initiatives/${initiativeId}`, {
        params: query,
      });
    } catch (error) {
      throw error;
    }
  };

  static getAllInitiativeNames = async (resource_type, resource_id) => {
    try {
      const query = { resource_type, resource_id };
      const response = await axios.get(`${API_URL}/api/v1/initiative_names`, {
        params: query,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static initiativeSubtopicSortFn = (subtopicName1, subtopicName2) => {
    const sortOrder1 = INITIATIVE_SUBTOPIC_ORDER[subtopicName1];
    const sortOrder2 = INITIATIVE_SUBTOPIC_ORDER[subtopicName2];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return -1;
    } else if (sortOrder2 !== undefined) {
      return 1;
    } else {
      if (subtopicName1.toLowerCase() === "other") {
        return 1;
      } else if (subtopicName2.toLowerCase() === "other") {
        return -1;
      } else {
        return subtopicName1.localeCompare(subtopicName2);
      }
    }
  };

  static initiativeTrackingCriteriaSortFn = (
    criterionName1,
    criterionName2
  ) => {
    // any other criterion should be positioned as is, aside from accounting information and other which come last
    const sortOrder1 = INITIATIVE_TRACKING_CRITERION_ORDER[criterionName1];
    const sortOrder2 = INITIATIVE_TRACKING_CRITERION_ORDER[criterionName2];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return 1;
    } else if (sortOrder2 !== undefined) {
      return -1;
    } else {
      if (criterionName1.toLowerCase() === "other") {
        return 1;
      } else if (criterionName2.toLowerCase() === "other") {
        return -1;
      } else {
        return 0;
      }
    }
  };

  static initiativeAccountingInfoSourceSortFn = (sourceName1, sourceName2) => {
    const sortOrder1 =
      INITIATIVE_ACCOUNTING_INFORMATION_SOURCE_ORDER[sourceName1];
    const sortOrder2 =
      INITIATIVE_ACCOUNTING_INFORMATION_SOURCE_ORDER[sourceName2];

    return sortOrder1 - sortOrder2;
  };

  static initiativeTrackingCriteriaFieldColumnSortFn = (
    fieldColumn1,
    fieldColumn2
  ) => {
    return (
      INITIATIVE_FIELD_ORDER.indexOf(fieldColumn1.toLowerCase()) -
      INITIATIVE_FIELD_ORDER.indexOf(fieldColumn2.toLowerCase())
    );
  };
}
