import React, { useContext, useEffect, useState } from "react";
import uniq from "lodash/uniq";
import intersection from "lodash/intersection";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

import {
  OPERATIONAL_METER_TYPE_FLOAT,
  PERMISSIONS,
  RESOURCES,
} from "../../../config/constants";
import {
  DateTimeUtils,
  NumberService,
  UtilsService,
} from "../../../services/UtilsService";
import OperationalService from "../../../services/OperationalService";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import withAuthentication from "../../HOC/withAuthentication";
import LoadingSpinner from "../../common/LoadingSpinner";
import {
  getDefaultMonthRangeEnd,
  getDefaultMonthRangeStart,
} from "../../common/MonthRangeSelector";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import InputOperationalDataRecordModal from "./InputOperationalDataRecordModal";
import DeleteOperationalDataRecordPrompt from "./DeleteOperationalDataRecordPrompt";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import { resetFilterKeys } from "../../../gvds-components/Table/TableUtils";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  MULTI_SELECT_DATAKEY,
} from "../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSTableBulkActionBar from "../../../gvds-components/Table/Controls/GVDSTableBulkActionBar";

const ViewAllOperationalDataRecords = ({ lastUpdate, datasets }) => {
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [start, setStart] = useState(getDefaultMonthRangeStart());
  const [end, setEnd] = useState(getDefaultMonthRangeEnd());

  const [allDataRecords, setAllDataRecords] = useState([]);

  const [isMultipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const [currentEditRecordModel, setCurrentlyEditedRecordModel] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const toggleRecordModelSelection = (recordId) => {
    setCurrentlyToBeDeletedRecordIds(
      UtilsService.toggleItem(currentlyToBeDeletedRecordIds, recordId)
    );
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allDataRecords, null);

  const [currentlyToBeDeletedRecordIds, setCurrentlyToBeDeletedRecordIds] =
    useState([]);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);

  useEffect(() => {
    loadDataRecords();
  }, [userInventory.selectedInventory.get, start, end, lastUpdate]);

  useEffect(() => {
    const meterTypes = uniq(allDataRecords.map((r) => r.meterType));
    const facilityNames = uniq(allDataRecords.map((r) => r.facilityName));

    const newFilterKeys = resetFilterKeys(filterKeys, {
      meterType: meterTypes,
      facilityName: facilityNames,
    });

    setFilterKeys(newFilterKeys);
  }, [allDataRecords]);

  const loadDataRecords = () => {
    const currentInventory = userInventory.selectedInventory.get;
    if (currentInventory) {
      setIsLoading(true);
      OperationalService.getAllOperationalDataRecords(
        currentInventory.id,
        currentInventory.type,
        start,
        end
      )
        .then((resp) => {
          setAllDataRecords(resp);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load operational data records.</span>
          );
        });
    }
  };

  const onPageRecordsSelect = () => {
    const recordIds = currentPageData.map((r) => r.id);
    const intersectIds = intersection(currentlyToBeDeletedRecordIds, recordIds);
    if (intersectIds.length === recordIds.length) {
      setCurrentlyToBeDeletedRecordIds(
        currentlyToBeDeletedRecordIds.filter(
          (rId) => recordIds.indexOf(rId) < 0
        )
      );
    } else {
      setCurrentlyToBeDeletedRecordIds(
        uniq([...currentlyToBeDeletedRecordIds, ...recordIds])
      );
    }
  };

  const showEditRecord = (recordModel) => {
    setCurrentlyEditedRecordModel(recordModel);
    setShowEditModal(true);
  };

  const promptDeleteRecord = (recordIds) => {
    setCurrentlyToBeDeletedRecordIds(recordIds);
    setShowPromptDeleteModal(true);
  };

  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  const deleteCurrentlySelectedRecordModel = () => {
    const currentInventory = userInventory.selectedInventory.get;
    OperationalService.deleteDataRecords(
      currentInventory.type,
      currentInventory.id,
      currentlyToBeDeletedRecordIds
    )
      .then(() => {
        const noOfDeletedModels = currentlyToBeDeletedRecordIds.length;
        if (noOfDeletedModels === 1) {
          toastContext.addSuccessToast(
            <span>Data record deleted successfully</span>
          );
        } else {
          toastContext.addSuccessToast(
            <span>{noOfDeletedModels} data records deleted successfully</span>
          );
        }
        setCurrentlyToBeDeletedRecordIds([]);
        loadDataRecords();
        handleClosePromptDeleteModal();
      })
      .catch(() => {
        toastContext.addFailToast("Failed to delete record(s)");
      });
  };

  const renderFormattedValue = (meterType, value) => {
    if (
      meterType === OPERATIONAL_METER_TYPE_FLOAT.AVG_WORKERS ||
      meterType === OPERATIONAL_METER_TYPE_FLOAT.AVG_FTE
    ) {
      return NumberService.format(value, 2, 2);
    } else {
      return NumberService.format(value, 0, 0);
    }
  };

  let content;
  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    let columns = [
      { header: "Type", dataKey: "meterType", sortable: true },
      {
        header: "Period From",
        dataKey: "periodFrom",
        sortable: true,
        renderer: (d) => DateTimeUtils.formatUTCDate(d["periodFrom"]),
      },
      {
        header: "Period To",
        dataKey: "periodTo",
        sortable: true,
        renderer: (d) => DateTimeUtils.formatUTCDate(d["periodTo"]),
      },
      {
        header: "Value",
        dataKey: "value",
        sortable: true,
        renderer: (d) => renderFormattedValue(d["meterType"], d["value"]),
      },
      {
        header: "Comment",
        dataKey: "comments",
        sortable: true,
        renderer: (d) =>
          d["comments"] ? <CommentTooltip comment={d["comments"]} /> : null,
      },
      {
        header: ACTIONS_TABLE_HEADER,
        dataKey: ACTIONS_DATAKEY,
        renderer: (d) => {
          return (
            <>
              {!permissionCtx.isLoadingPermissions &&
                permissionCtx.permissions[
                  PERMISSIONS.OPERATIONAL_RECORD_EDIT
                ] && (
                  <GVDSIconButton
                    variant={iconButtonVariant.tertiary}
                    icon={<GVDSIcon Icon={IconEdit} />}
                    onClick={(e) => {
                      e.preventDefault();
                      showEditRecord(d);
                    }}
                    tooltipText="Edit"
                  />
                )}

              {!permissionCtx.isLoadingPermissions &&
                permissionCtx.permissions[
                  PERMISSIONS.OPERATIONAL_RECORD_DELETE
                ] && (
                  <GVDSIconButton
                    variant={iconButtonVariant.destructive}
                    icon={<GVDSIcon Icon={IconTrash} />}
                    onClick={() => {
                      promptDeleteRecord([d.id]);
                    }}
                    tooltipText="Delete"
                  />
                )}
            </>
          );
        },
      },
    ];
    if (isMultipleDeleteMode) {
      columns.unshift({
        header: "",
        dataKey: MULTI_SELECT_DATAKEY,
        renderer: (d) => {
          return (
            <div>
              <Form.Check
                checked={currentlyToBeDeletedRecordIds.indexOf(d.id) >= 0}
                type="checkbox"
                key={d.id}
                id={d.id}
                label=""
                onChange={() => toggleRecordModelSelection(d.id)}
              />
            </div>
          );
        },
      });
    }
    if (userInventory.selectedInventory.get.type === RESOURCES.SITE) {
      columns.splice(1, 0, {
        header: "Facility",
        dataKey: "facilityName",
        sortable: true,
      });
    }
    const pageRecordIds = currentPageData.map((r) => r.id);
    const isPageSelected =
      intersection(pageRecordIds, currentlyToBeDeletedRecordIds).length ===
      pageRecordIds.length;
    content = (
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        isPageSelected={isPageSelected}
        onPageSelect={onPageRecordsSelect}
        noContent={
          <div className="table__no_content">
            <p>
              No data records found within the filtered time period. Try
              adjusting your filter.
            </p>
          </div>
        }
        viewControl={
          <>
            <GVDSTableCtrlContainer>
              <GVDSTableCtrlMultiSelect
                options={uniq(allDataRecords.map((r) => r.meterType))}
                prefix="Type"
                value={filterKeys.meterType}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, meterType: filterKs })
                }
              />
              {userInventory.selectedInventory.get &&
                userInventory.selectedInventory.get.type === RESOURCES.SITE && (
                  <GVDSTableCtrlMultiSelect
                    options={uniq(allDataRecords.map((r) => r.facilityName))}
                    prefix="Facility"
                    value={filterKeys.facilityName}
                    onChange={(filterKs) =>
                      setFilterKeys({ ...filterKeys, facilityName: filterKs })
                    }
                  />
                )}
              <div className="date-range-container">
                <GVDSFormStartEndMonthPicker
                  startMonth={start}
                  endMonth={end}
                  onChange={(s, e) => {
                    if (s !== start) {
                      setStart(s);
                    }
                    if (e !== end) {
                      setEnd(e);
                    }
                  }}
                />
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            {isMultipleDeleteMode && (
              <GVDSTableBulkActionBar
                selectedRowsCount={currentlyToBeDeletedRecordIds.length}
                actionButtons={
                  <>
                    <GVDSButton
                      variant={buttonVariant.destructive_primary}
                      disabled={currentlyToBeDeletedRecordIds.length === 0}
                      onClick={(e) => {
                        e.preventDefault();
                        promptDeleteRecord(currentlyToBeDeletedRecordIds);
                      }}
                      text="Delete selected"
                    />
                    <GVDSButton
                      variant={buttonVariant.tertiary}
                      onClick={(e) => {
                        e.preventDefault();
                        setMultipleDeleteMode(false);
                        setCurrentlyToBeDeletedRecordIds([]);
                      }}
                      text="Cancel"
                    />
                  </>
                }
              />
            )}
          </>
        }
      />
    );
  }

  return (
    <div>
      <div className="d-flex mb-2">
        <Spacer />
        {!permissionCtx.isLoadingPermissions &&
          permissionCtx.permissions[PERMISSIONS.OPERATIONAL_RECORD_DELETE] && (
            <div className="ms-auto">
              {!isMultipleDeleteMode && (
                <Dropdown>
                  <Dropdown.Toggle as={OtherActionDropdownToggle} />
                  <Dropdown.Menu>
                    <Dropdown.Item
                      key="delete-multiple"
                      href="#"
                      onClick={() => setMultipleDeleteMode(true)}
                      disabled={allDataRecords.length === 0}
                    >
                      <span className="color-red">Delete multiple records</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      key="delete-all"
                      href="#"
                      onClick={() =>
                        promptDeleteRecord(filteredSortedData.map((d) => d.id))
                      }
                      disabled={filteredSortedData.length === 0}
                    >
                      <span className="color-red">
                        Delete all ({filteredSortedData.length}) records
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
      </div>
      {content}
      <InputOperationalDataRecordModal
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        currentRecord={currentEditRecordModel}
        onDoneInput={loadDataRecords}
        datasets={datasets}
      />

      <DeleteOperationalDataRecordPrompt
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        proceed={deleteCurrentlySelectedRecordModel}
      />
    </div>
  );
};

export default withAuthentication(ViewAllOperationalDataRecords);
