import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DataRequestService from "../../../services/DataRequestService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { FEATURES, RESOURCES } from "../../../config/constants";
import WidgetService from "./WidgetService";
import CustomProgressBar from "../../common/CustomProgressBar";
import moment from "moment";
import { OVERVIEW_DATA } from "../../../config/ROUTES_NAME";
import keyBy from "lodash/keyBy";
import ToastContext from "../../../context/ToastContext";
import UserProfileContext from "../../../context/UserProfileContext";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import BaseWidgetEmptyContent, {
  FeatureLockWidgetContent,
} from "./BaseWidgetEmptyContent";
import BaseWidgetHeadingTooltip from "./BaseWidgetHeadingTooltip";
import InlineSpinner from "../../common/InlineSpinner";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { isRequestAborted } from "../../../services/HttpService";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();

  const history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const locked =
    subscriptionFeatureStatus !== SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const [inventoryId, setInventoryId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [allDataRequests, setAllDataRequests] = useState({});
  const [newDataRequestDetails, setNewDataRequestDetails] = useState({});
  const [progressMessage, setProgressMessage] = useState("");
  const [tooltip, setTooltip] = useState("");

  const abortControllerRef = useRef(null);

  useEffect(() => {
    const inventory = userInventory.selectedInventory.get;

    if (
      !userInventory.isLoadingInventory.get &&
      userInventory.selectedInventory.get &&
      userInventory.selectedInventory.get.id
    ) {
      setIsLoading(true);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      setInventoryId(inventory.id);
      setAllDataRequests({});

      DataRequestService.getOngoingDataRequests(
        inventory.id,
        inventory.type,
        abortControllerRef.current.signal
      )
        .then((data) => {
          setAllDataRequests(keyBy(data, "id"));
          data.forEach((d) => {
            DataRequestService.getDataRequest(
              d.id,
              inventory.id,
              inventory.type,
              abortControllerRef.current.signal
            )
              .then((details) => {
                setNewDataRequestDetails(details);
              })
              .catch((e) => {
                if (isRequestAborted(e)) {
                  return;
                }
                toastContext.addFailToast(
                  <span>Failed to load data request.</span>
                );
              });
          });
        })
        .catch((e) => {
          if (isRequestAborted(e)) {
            return;
          }
          toastContext.addFailToast(<span>Failed to load data requests.</span>);
        })
        .finally(() => setIsLoading(false));
    }

    if (userInventory.selectedInventory.get.type === RESOURCES.PORTFOLIO) {
      setProgressMessage(
        t("landing-page.widget-data-request.portfolio-progress-msg")
      );
      setTooltip(t("landing-page.widget-data-request.portfolio-tooltip"));
    } else {
      setProgressMessage(
        t("landing-page.widget-data-request.site-progress-msg")
      );
      setTooltip(t("landing-page.widget-data-request.site-tooltip"));
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [
    userInventory.isLoadingInventory.get,
    userInventory.selectedInventory.get,
  ]);

  useEffect(() => {
    if (newDataRequestDetails.resourceId === inventoryId) {
      setAllDataRequests({
        ...allDataRequests,
        [newDataRequestDetails.id]: newDataRequestDetails,
      });
    }
  }, [newDataRequestDetails.id, newDataRequestDetails.resourceId]);

  const getVisibleDataRequests = () => {
    return Object.values(allDataRequests)
      .filter((d) => !d.isExpired())
      .filter((d) => d.progress < 100)
      .sort((a, b) => (moment(a.deadline).isBefore(b.deadline) ? -1 : 1));
  };

  const redirectToDataRequestPage = () => {
    history.push(OVERVIEW_DATA);
  };

  let content;
  if (locked) {
    content = (
      <FeatureLockWidgetContent
        subscriptionFeatureStatus={subscriptionFeatureStatus}
      />
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  } else if (getVisibleDataRequests().length === 0) {
    content = (
      <BaseWidgetEmptyContent
        title={t("landing-page.widget-data-request.no-content")}
      />
    );
  } else {
    content = (
      <div className="landing-page-widget__list__container">
        {getVisibleDataRequests().map((dr) => {
          return (
            <div
              key={dr.id}
              className="landing-page-widget__list-item__container"
            >
              <div className="landing-page-widget__list-item__header">
                <div className="landing-page-widget__list-item__header-text">
                  {dr.typeInFullName}
                </div>
                <div className="landing-page-widget__list-item__header-date">
                  {WidgetService.getDeadlineDueMessage(dr.deadline)}
                </div>
              </div>
              <div className="landing-page-widget__list-item__subheader">
                ({dr.periods.join(", ")})
              </div>
              <div className="landing-page-widget__list-item__progress">
                {dr.isProgressLoading() ? (
                  <div style={{ width: "fit-content" }}>
                    <InlineSpinner />
                  </div>
                ) : userInventory.selectedInventory.get.type ===
                  RESOURCES.PORTFOLIO ? (
                  <CustomProgressBar
                    progress={dr.progress}
                    description={progressMessage}
                  />
                ) : (
                  <StatusLabel color={dr.getStatusLabelColor()}>
                    {dr.status}
                  </StatusLabel>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className="base-widget__container-clickable"
      onClick={redirectToDataRequestPage}
    >
      <div className="base-widget__heading">
        <div className="base-widget__heading__title-text">
          {t("landing-page.widget-data-request.title")}{" "}
          <BaseWidgetHeadingTooltip info={tooltip} />
        </div>
      </div>
      {content}
    </div>
  );
};
