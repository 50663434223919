import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  BulkDataInputDateSelector,
  BulkDataInputDropdownSelector,
} from "./BulkDataInputSelector";
import moment from "moment";
import { DateTimeUtils, NumberService } from "../../services/UtilsService";
import Button from "react-bootstrap/Button";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import {
  IconCalendarEvent,
  IconCaretDown,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import Spacer from "../../gvds-components/Layout/Spacer";

export class RemoveActionCell {
  constructor(component) {
    this.component = component;
    this.forceComponent = true;
  }

  static fromRemoveRow(
    removeRow,
    className = "bulk-data-input-datasheet-action-cell"
  ) {
    return new RemoveActionCell(
      (
        <div className={className} onClick={() => removeRow()}>
          <GVDSIcon Icon={IconTrash} />
        </div>
      )
    );
  }

  static fromCellData(cellData) {
    return new RemoveActionCell(cellData["component"]);
  }

  static fromBulkSelectSiteRemoveRow(removeRow) {
    return new RemoveActionCell(
      (
        <div className="subscription__bulk-select-sites-datasheet-action-cell">
          <Button
            size="sm"
            variant="link"
            className="link-danger"
            onClick={() => removeRow()}
          >
            <GVDSIcon Icon={IconX} />
          </Button>
        </div>
      )
    );
  }
}

export class CellValue {
  constructor(
    value,
    readOnly = false,
    dataErrors = [],
    possibleErrors = [],
    customClassName = null,
    showErrorMessages = true
  ) {
    this.value = value;
    this.readOnly = readOnly;
    this.dataErrors = dataErrors;
    this.possibleErrors = possibleErrors;
    this.showErrorMessages = showErrorMessages;
    this.valueViewer = (props) =>
      BulkDataInputCellViewer(customClassName, props);
  }

  updateValue(newValue) {
    this.value = newValue;
  }

  getValue() {
    return this.value ? this.value : undefined;
  }

  isEmpty() {
    return !this.value;
  }

  static fromCellData(cellData) {
    return new CellValue(
      cellData["value"],
      cellData["readOnly"],
      cellData["dataErrors"],
      cellData["possibleErrors"],
      cellData["showErrorMessages"]
    );
  }
}

export class DropdownCellValue extends CellValue {
  constructor(
    value,
    dataEditor,
    icon,
    readOnly = false,
    dataErrors = [],
    possibleErrors = []
  ) {
    super(value, readOnly, dataErrors, possibleErrors);
    this.dataEditor = dataEditor;
    this.icon = icon;
  }

  setDropdownOptions(options = []) {
    this.dataEditor =
      options.length > 0
        ? (props) => <BulkDataInputDropdownSelector data={options} {...props} />
        : null;
    this.icon = this.dataEditor ? (
      <GVDSIcon className="bulk-input-cell__icon" Icon={IconCaretDown} />
    ) : null;
  }

  static fromCellData(cellData) {
    return new DropdownCellValue(
      cellData["value"],
      cellData["dataEditor"],
      cellData["icon"],
      cellData["readOnly"],
      cellData["dataErrors"],
      cellData["possibleErrors"],
      cellData["showErrorMessages"]
    );
  }
}

export class DateCellValue extends CellValue {
  constructor(value, readOnly = false, dataErrors, possibleErrors) {
    super(
      value,
      readOnly,
      dataErrors,
      possibleErrors,
      "bulk-date-input__container"
    );
    this.dataEditor = (props) => <BulkDataInputDateSelector {...props} />;
    this.icon = (
      <GVDSIcon className="bulk-input-cell__icon" Icon={IconCalendarEvent} />
    );
  }

  updateValue(newValue) {
    const newDate = moment(newValue);
    if (newDate.isValid()) {
      this.value = DateTimeUtils.formatLocalDate(newDate);
    } else {
      this.value = newValue;
    }
  }

  getValue() {
    if (!this.value) return null;
    const date = moment(this.value);
    return date.isValid() ? DateTimeUtils.getUTCISOString(date) : this.value;
  }

  static fromCellData(cellData) {
    return new DateCellValue(
      cellData["value"],
      cellData["readOnly"],
      cellData["dataErrors"],
      cellData["possibleErrors"],
      cellData["showErrorMessages"]
    );
  }
}

export class NumberCellValue extends CellValue {
  getValue() {
    return this.value ? NumberService.parse(this.value) : undefined;
  }

  static fromCellData(cellData) {
    return new NumberCellValue(
      cellData["value"],
      cellData["readOnly"],
      cellData["dataErrors"],
      cellData["possibleErrors"],
      cellData["showErrorMessages"]
    );
  }
}

const BulkDataInputCellViewer = (customClassName, props) => {
  const { cell, value } = props;
  const { dataErrors, possibleErrors, showErrorMessages, icon } = cell;
  const hasDataError = () => dataErrors.length > 0;
  const hasPossibleError = () => possibleErrors.length > 0;

  const getClassName = () => {
    const classNames = ["text-break", "d-flex"];
    if (customClassName) {
      classNames.push(customClassName);
    }
    if (hasDataError()) {
      classNames.push("data-input-cell-data-error");
    } else if (hasPossibleError()) {
      classNames.push("data-input-cell-possible-error");
    }
    return classNames.join(" ");
  };

  const content = (
    <div className={getClassName()}>
      {value}
      <Spacer />
      {icon}
    </div>
  );

  if ((hasDataError() || hasPossibleError()) && showErrorMessages) {
    const errorsContent = [...dataErrors, ...possibleErrors].map(
      (err, index) => <div key={index}>{err}</div>
    );

    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{errorsContent}</Tooltip>}
      >
        {content}
      </OverlayTrigger>
    );
  }

  return content;
};
