import React from "react";
import { uniqueId } from "lodash";
import Select from "react-select";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "./GVDSFormShared";
import { GVDSComponentColors } from "../../styles/gvds-colors";

const DROPDOWN_MAX_WIDTH = "400px";

const getPortallingSingleSelectStyles = (isDropdownFollowFilterWidth) => ({
  control: (provided, state) => ({
    ...provided,
    ...(state.isDisabled && {
      pointerEvents: "auto",
      cursor: "not-allowed",
      backgroundColor: GVDSComponentColors.formDisabledBackgroundColor,
      color: GVDSComponentColors.formDisabledTextColor,
    }),
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (base) => ({
    ...base,
    ...(isDropdownFollowFilterWidth
      ? { width: "100%" }
      : {
          width: "max-content",
          minWidth: "100%",
          maxWidth: DROPDOWN_MAX_WIDTH,
        }),
  }),
  singleValue: (provided, state) => ({
    ...provided,
    ...(state.isDisabled && {
      color: GVDSComponentColors.formDisabledTextColor,
    }),
  }),
  multiValue: (provided, state) => ({
    ...provided,
    ...(state.isDisabled && {
      color: GVDSComponentColors.formDisabledTextColor,
    }),
  }),
});

const GVDSFormSingleSelect = ({
  id = uniqueId(),
  name,
  options = [],
  value,
  onSelect,
  statusMetadata = FormFieldStatusMetadata.getDefault(),
  placeholder = "Select Option",
  noOptionsMessage = "No options",
  disabled,
  isLoading,
  isSearchable,
  isClearable,
  isOptionDisabled,
  isDropdownFollowFilterWidth = true,
  menuIsOpen,
  closeMenuOnSelect,
  required,
  className,
  components,
  width = "200px",
  menuPlacement,
  menuShouldScrollIntoView = true,
  menuPosition = "fixed",
  ...props
}) => {
  const gvdsClassNames = ["gvds-form__single-select"].concat(
    statusMetadata.state === FormFieldState.warning
      ? ["gvds-form__warning"]
      : statusMetadata.state === FormFieldState.error
      ? ["gvds-form__error"]
      : []
  );

  const combinedClassNames = `${gvdsClassNames.join(" ")} ${className || ""}`;

  const onSelectChange = (selectedOption, actionContext) => {
    onSelect(selectedOption, actionContext);
  };

  return (
    <>
      <Select
        id={id}
        name={name}
        options={options}
        value={value}
        onChange={onSelectChange}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        isDisabled={disabled}
        isLoading={isLoading}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isOptionDisabled={isOptionDisabled}
        menuIsOpen={menuIsOpen}
        closeMenuOnSelect={closeMenuOnSelect}
        required={required}
        classNamePrefix="gvds-single-select"
        className={combinedClassNames}
        components={components}
        width={width}
        menuPlacement={menuPlacement}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        styles={getPortallingSingleSelectStyles(isDropdownFollowFilterWidth)}
        menuPosition={menuPosition}
        blurInputOnSelect={true}
        {...props}
      />
      {statusMetadata.isNotNormal() && statusMetadata.message && (
        <GVDSFormErrorMessage
          status={statusMetadata.state}
          errorMsg={statusMetadata.message}
        />
      )}
    </>
  );
};

export default GVDSFormSingleSelect;
