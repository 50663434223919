import React, { useContext, useState } from "react";
import { SignUpSiteDetailsFormFiew } from "./SignUpFormView";
import { EnterprisePlanSignUpModel } from "./SignUpModel";
import clone from "lodash/clone";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GVDSFormTextArea from "../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import SignUpService from "../../../services/SignUpService";
import SupportContactLink from "../../common/SupportContactLink";
import ToastContext from "../../../context/ToastContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";

const BillingEnterprisePlanInquiry = ({
  signUpOptions,
  goBackToPlanSelection,
  onSuccess,
}) => {
  const toastContext = useContext(ToastContext);

  const [signUpModel, setSignUpModel] = useState(
    new EnterprisePlanSignUpModel()
  );
  const onModelChange = () => {
    setSignUpModel(clone(signUpModel));
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const onSubmittingEnterpriseInquiryForm = () => {
    setIsValidated(true);

    if (signUpModel.isValid()) {
      setIsLoading(true);
      SignUpService.submitEnterprisePlanSignUpApplication(
        signUpModel.userFullName,
        signUpModel.emailAddress,
        signUpModel.companyName,
        signUpModel.noOfProperties,
        signUpModel.businessNeeds,
        signUpModel.siteName,
        signUpModel.siteType,
        signUpModel.streetAddress,
        signUpModel.zipCode,
        signUpModel.city,
        signUpModel.stateOrProvince,
        signUpModel.country
      )
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          toastContext.addFailToast(
            <div>
              <div className="gvds-text--body__bold">
                Failed to submit application.
              </div>
              <div>
                Please contact portal admin at <SupportContactLink />
              </div>
            </div>
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <GVDSButton
        variant={buttonVariant.tertiary}
        onClick={goBackToPlanSelection}
        text="Back to plans"
        icon={<GVDSIconSlim Icon={IconArrowNarrowLeft} />}
        className="page-header--back-button mb-2"
      />
      <section>
        <div className="gvds-text--heading2 mb-2">
          Request for your tailored price quote
        </div>
        <div className="contact-us__display">
          Managing a single hotel or property and interested in our packaged plans instead? <GVDSTextButton text="Choose from here" onClick={goBackToPlanSelection } />
        </div>
        <GVFormGroup>
          <Form.Label>Business Email</Form.Label>
          <GVDSFormField
            name="emailAddress"
            className="signup-form__email-input"
            value={signUpModel.emailAddress}
            onInput={(value) => {
              signUpModel.emailAddress = value;
              onModelChange();
            }}
            statusMetadata={
              isValidated && !signUpModel.isEmailAddressValid()
                ? FormFieldStatusMetadata.getError(
                    "Please enter a valid email."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>Your Name</Form.Label>
              <GVDSFormField
                name="userFullName"
                value={signUpModel.userFullName}
                onInput={(value) => {
                  signUpModel.userFullName = value;
                  onModelChange();
                }}
                statusMetadata={
                  isValidated && !signUpModel.isNameValid()
                    ? FormFieldStatusMetadata.getError(
                        "Please enter your name."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <Form.Label>Your Company</Form.Label>
              <GVDSFormField
                name="company"
                value={signUpModel.companyName}
                onInput={(value) => {
                  signUpModel.companyName = value;
                  onModelChange();
                }}
                statusMetadata={
                  isValidated && !signUpModel.isCompanyValid()
                    ? FormFieldStatusMetadata.getError(
                        "Please enter your company."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <GVFormGroup>
          <Form.Label>Number of properties</Form.Label>
          <GVDSFormField
            name="noOfProperties"
            type="number"
            value={signUpModel.noOfProperties}
            onInput={(value) => {
              signUpModel.noOfProperties = value;
              onModelChange();
            }}
            statusMetadata={
              isValidated && !signUpModel.isNoOfPropertiesValid()
                ? FormFieldStatusMetadata.getError(
                    "Please input number of properties."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Tell us more about your needs</Form.Label>
          <GVDSFormTextArea
            name="businessNeeds"
            value={signUpModel.businessNeeds}
            onInput={(value) => {
              signUpModel.businessNeeds = value;
              onModelChange();
            }}
            statusMetadata={
              isValidated && !signUpModel.isBusinessNeedsValid()
                ? FormFieldStatusMetadata.getError(
                    "Please tell us more about your needs."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
            placeholder="Share about your properties, requirements, goals, or any specific features you're interested in."
          />
        </GVFormGroup>
      </section>

      <section>
        <h3>Property Information</h3>
        <div className="mb-3">
          Please provide details about one of your properties. This helps us
          verify your inquiry accurately.
        </div>
        <SignUpSiteDetailsFormFiew
          signUpOptions={signUpOptions}
          signUpModel={signUpModel}
          onModelChange={onModelChange}
          isValidated={isValidated}
        />
      </section>
      <GVDSButton
        variant={buttonVariant.primary}
        text="Send Inquiry"
        onClick={onSubmittingEnterpriseInquiryForm}
        className="w-100 mb-4"
      />
    </div>
  );
};

export default BillingEnterprisePlanInquiry;
