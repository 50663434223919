import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  BEST_QUESTION_CATEGORIES,
  FEATURES,
  RESOURCES,
} from "../../../config/constants";
import UserInventoryContext from "../../../context/UserInventoryContext";
import BestPracticeAssessmentService from "../../../services/BestPracticeAssessmentService";
import LoadingSpinner from "../../common/LoadingSpinner";
import BestPracticeCategoryService from "../../../services/ReferenceDataServices/BestPracticeCategoryService";
import { BEST_PRACTICES_ASSESS } from "../../../config/ROUTES_NAME";
import ToastContext from "../../../context/ToastContext";
import UserProfileContext from "../../../context/UserProfileContext";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import BaseWidgetEmptyContent, {
  FeatureLockWidgetContent,
} from "./BaseWidgetEmptyContent";
import BaseWidgetHeadingTooltip from "./BaseWidgetHeadingTooltip";
import {
  IconActivity,
  IconBatteryCharging,
  IconBook,
  IconDroplet,
  IconRecycle,
  IconSeeding,
  IconTransferIn,
  IconUsersGroup,
} from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import PopoverOnBigNumberTruncated from "../../common/Tooltip/PopoverOnBigNumberTruncated";
import { useTranslation } from "react-i18next";

const BestPracticeIcons = {
  [BEST_QUESTION_CATEGORIES.WATER]: IconDroplet,
  [BEST_QUESTION_CATEGORIES.ENERGY]: IconBatteryCharging,
  [BEST_QUESTION_CATEGORIES.WASTE]: IconRecycle,
  [BEST_QUESTION_CATEGORIES.HEALTH_N_WELLNESS]: IconActivity,
  [BEST_QUESTION_CATEGORIES.SOURCING]: IconTransferIn,
  [BEST_QUESTION_CATEGORIES.CLIMATE_ACTION]: IconSeeding,
  [BEST_QUESTION_CATEGORIES.SOCIAL_IMPACT]: IconUsersGroup,
  [BEST_QUESTION_CATEGORIES.OTHER]: IconBook,
};

const getIcon = (categoryName) => {
  const icon = BestPracticeIcons[categoryName];
  return icon ? icon : IconBook;
};

const BestPracticeCategoryDisplay = ({ label, icon, value, unit = null }) => {
  return (
    <div className="widget--best-practice__category-display__container">
      <div className="widget--best-practice__category-display__icon">
        <GVDSIcon Icon={icon} />
      </div>
      <div className="widget--best-practice__category-display__value-container">
        <PopoverOnBigNumberTruncated
          numberValue={value}
          contentClassName="widget--best-practice__category-display__value-number"
        />
      </div>
      <div className="widget--best-practice__category-display__label">
        {label}
      </div>
    </div>
  );
};

const BestPracticeWidget = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.BEST_PRACTICES__ASSESSMENT],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const locked =
    subscriptionFeatureStatus !== SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;
  const [isLoading, setIsLoading] = useState(true);
  const [bestPracticeGroups, setBestPracticeGroups] = useState({});

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;
    if (
      selectedInventory &&
      selectedInventory.id &&
      selectedInventory.type === RESOURCES.SITE
    ) {
      setIsLoading(true);
      BestPracticeAssessmentService.getBestPracticeQuestions(
        RESOURCES.SITE,
        selectedInventory.id
      )
        .then((bpqModels) => {
          const groupedQuestions = bpqModels.reduce(
            (bpqByCategory, bpqModel) => {
              bpqModel.categoryNames.forEach((categoryName) => {
                if (!bpqByCategory[categoryName]) {
                  bpqByCategory[categoryName] = [];
                }
                bpqByCategory[categoryName].push(bpqModel);
              });
              return bpqByCategory;
            },
            {}
          );
          setBestPracticeGroups(groupedQuestions);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.status !== 403) {
            toastContext.addFailToast(
              <span>Failed to load best practice questions.</span>
            );
          }
        });
    }
  }, [userInventory.selectedInventory.get]);

  const redirectToBestPracticePage = () => {
    history.push(BEST_PRACTICES_ASSESS);
  };

  let content;
  if (locked) {
    content = (
      <FeatureLockWidgetContent
        subscriptionFeatureStatus={subscriptionFeatureStatus}
      />
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  } else if (Object.values(bestPracticeGroups).length === 0) {
    content = (
      <BaseWidgetEmptyContent
        title={t("landing-page.widget-best-practices.no-content")}
      />
    );
  } else {
    content = Object.keys(bestPracticeGroups)
      .sort(BestPracticeCategoryService.bestPracticeSortFn)
      .map((categoryName) => (
        <BestPracticeCategoryDisplay
          key={categoryName}
          label={categoryName}
          value={`${
            bestPracticeGroups[categoryName].filter((b) => b.isAnswered).length
          }/${bestPracticeGroups[categoryName].length}`}
          icon={getIcon(categoryName)}
        />
      ));
  }

  return (
    <div
      className="base-widget__container-clickable widget--best-practice__container"
      onClick={redirectToBestPracticePage}
    >
      <div className="base-widget__heading">
        <div className="base-widget__heading__title-text">
          {t("landing-page.widget-best-practices.title")}{" "}
          <BaseWidgetHeadingTooltip
            info={t("landing-page.widget-best-practices.tooltip")}
          />
        </div>
      </div>
      <div
        className={
          "widget--best-practice__content" +
          (isLoading || locked ? " no-content" : "")
        }
      >
        {content}
      </div>
    </div>
  );
};

export default BestPracticeWidget;
