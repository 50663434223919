import { DateTimeUtils } from "../../services/UtilsService";
import moment from "moment";
import { TARGET_PERIOD_TYPE, TARGET_TOPIC } from "../../config/constants";
import SafeDataService from "../../services/SafeDataService";

export const TARGET_VALIDATION_ERROR_CODE = {
  REDUCE_BY_MORE_THAN_100_PERCENT: "REDUCE_BY_MORE_THAN_100_PERCENT",
};

export class TargetModel {
  constructor(details) {
    this.id = details["id"];
    this.resourceId = details["resource_id"];
    this.goal = details["goal"];
    this.name = details["name"];
    this.comment = details["comment"];
    this.periodType = details["period_type"];
    this.topic = details["topic"];
    this.status = details["status"];
    this.baselineYear = details["baseline_year"];
    this.targetYear = details["target_year"];
    this.comparisonYear = details["comparison_year"];
    this.unitId = details["unit_id"];
    this.showProgress = details["show_progress"];
    this.usePercentageChange = details["use_percentage_change"];
    this.intensityMetricPerLabel = details["intensity_metric_per_label"];
    this.targetParamDetails = details["target_param_details"];
    this.params = details["params"];
    this.isFromCollectiveTarget = details["is_from_collective_target"];
    this.collectiveTargetInfo = details["collective_target_info"];

    this.timeRemaining = DateTimeUtils.getDurationBetween(
      this.currentDateForTime,
      moment(new Date(this.targetYear, 11, 31))
    );
    this.timePassed = DateTimeUtils.getDurationBetween(
      moment(new Date(this.baselineYear, 0, 1)),
      this.currentDateForTime
    );
    this.currentValuePeriodLabel = `${DateTimeUtils.formatLocalMonthYear(
      this.currentStartDate
    )} - ${DateTimeUtils.formatLocalMonthYear(this.currentDateForCalculation)}`;
    this.targetValuePeriodLabel =
      this.periodType === TARGET_PERIOD_TYPE.TOTAL
        ? `Jan ${this.baselineYear} - Dec ${this.targetYear}`
        : `Jan ${this.targetYear} - Dec ${this.targetYear}`;
    this.showCalculatedValue = this.topic !== TARGET_TOPIC.FREE_FORM;
  }

  get currentDateForTime() {
    const targetStartDate = moment(new Date(this.baselineYear, 0, 1));
    const targetEndDate = moment(new Date(this.targetYear, 11, 31));
    const today = moment();
    if (this.baselineYear && moment(targetStartDate).isAfter(today)) {
      return targetStartDate;
    } else if (moment(targetEndDate).isBefore(today)) {
      return targetEndDate;
    } else {
      return today;
    }
  }

  get currentDateForCalculation() {
    const targetStartDate = new Date(this.baselineYear, 0, 1);
    const targetEndDate = new Date(this.targetYear, 11, 31);
    const endOfLastMonth = moment().subtract(1, "month").endOf("month");
    if (this.baselineYear && moment(targetStartDate).isAfter(endOfLastMonth)) {
      return new Date(this.baselineYear, 0, 31);
    } else if (moment(targetEndDate).isBefore(endOfLastMonth)) {
      return targetEndDate;
    } else {
      return endOfLastMonth.toDate();
    }
  }

  get currentStartDate() {
    return this.periodType === TARGET_PERIOD_TYPE.TOTAL
      ? new Date(this.baselineYear, 0, 1)
      : moment(this.currentDateForCalculation)
          .subtract(11, "months")
          .startOf("month")
          .toDate();
  }
}

export class TargetDetailsModel {
  constructor(details) {
    this.summary = details["summary"];
    this.comparisonValue = details["comparison_value"];
    this.currentValue = details["current_value"];
    this.currentValueStartDate = DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
      details["current_value_start_date"]
    );
    this.currentValueEndDate = DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
      details["current_value_end_date"]
    );
    this.targetValue = details["target_value"];
    this.usePercentageChange = details["use_percentage_change"];
    this.isTargetAchieved = details["is_target_achieved"];
    this.precision = details["precision"];
    this.reportParams = this.getReportParams(
      details["topic"],
      details["report_params"]
    );
    this.achievedStatus = SafeDataService.mergeStatuses([
      this.currentValue?.status,
      this.targetValue?.status,
    ]);
  }

  getReportParams(topic, params) {
    switch (topic) {
      case TARGET_TOPIC.SOCIAL:
        return {
          initiative: {
            ...params.initiative,
            start: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
              params.initiative.start
            ),
            end: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
              params.initiative.end
            ),
            postponeDownload: true,
          },
        };
      case TARGET_TOPIC.ENVIRONMENTAL:
        return {
          performance: {
            ...params.performance,
            postponeDownload: true,
          },
          standard: {
            ...params.standard,
            start: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
              params.standard.start
            ),
            end: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
              params.standard.end
            ),
            postponeDownload: true,
          },
        };
      default:
        return {};
    }
  }

  get progress() {
    if (!this.currentValue || !this.targetValue) return null;

    return SafeDataService.multiply(
      SafeDataService.divide(this.currentValue, this.targetValue),
      100
    );
  }

  get comparableDiff() {
    if (!this.currentValue || !this.comparisonValue) {
      return null;
    }
    if (this.usePercentageChange) {
      return SafeDataService.getVariance(
        this.currentValue,
        this.comparisonValue
      );
    } else {
      return SafeDataService.minus(this.currentValue, this.comparisonValue);
    }
  }

  get targetDiff() {
    if (!this.targetValue || !this.currentValue) {
      return null;
    }
    const diff = SafeDataService.minus(this.currentValue, this.targetValue);
    if (this.usePercentageChange && this.comparisonValue) {
      return SafeDataService.multiply(
        SafeDataService.divide(diff, this.targetValue),
        100
      );
    } else {
      return diff;
    }
  }
}

export class CollectiveTargetModel extends TargetModel {
  constructor(details) {
    super(details);

    this.targetValues = details["target_values"];
  }
}
