import axios from "axios";
import { API_URL } from "../config/api-config";

export class HomepageAnnouncementModel {
  constructor(
    id = null,
    title = "",
    description = "",
    startDate = null,
    endDate = null,
    isOnetimePopup = false,
    firstCtaText = "",
    firstCtaUrl = "",
    secondCtaText = "",
    secondCtaUrl = ""
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.isOneTimePopup = isOnetimePopup;
    this.firstCtaText = firstCtaText;
    this.firstCtaUrl = firstCtaUrl;
    this.secondCtaText = secondCtaText;
    this.secondCtaUrl = secondCtaUrl;
  }

  static fromDTO(homepageAnnouncementDTO) {
    return new HomepageAnnouncementModel(
      homepageAnnouncementDTO["id"],
      homepageAnnouncementDTO["title"],
      homepageAnnouncementDTO["description"],
      homepageAnnouncementDTO["start_date"],
      homepageAnnouncementDTO["end_date"],
      homepageAnnouncementDTO["one_time_popup"],
      homepageAnnouncementDTO["first_cta_text"],
      homepageAnnouncementDTO["first_cta_url"],
      homepageAnnouncementDTO["second_cta_text"],
      homepageAnnouncementDTO["second_cta_url"]
    );
  }
}

export default class HomepageAnnouncementService {
  static getActiveAnnouncements = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/active_homepage_announcements`
      );
      return response.data.map((homepageAnnouncementDto) =>
        HomepageAnnouncementModel.fromDTO(homepageAnnouncementDto)
      );
    } catch (error) {
      throw error;
    }
  };
}
