import Container from "react-bootstrap/Container";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import {
  IconCircleFilled,
  IconDownload,
  IconEdit,
  IconGitBranch,
  IconMail,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useState } from "react";
import PermissionsContext from "../../../context/PermissionsContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import {
  getPathWithTabKey,
  getRedirectURLWithCurrentParam,
  updateQueryWithModalParam,
} from "../../common/QueryHandler";
import {
  getViewContractDetailsPath,
  getViewContractSubscriptionPath,
  getViewSiteDetailsPath,
} from "../../../config/ROUTES_NAME";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import uniq from "lodash/uniq";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../gvds-components/Table/GVDSTable";
import { useHistory, useLocation } from "react-router-dom";
import UserAvatar from "../../common/UserAvatar";
import {
  DateTimeUtils,
  NumberService,
  StringUtils,
} from "../../../services/UtilsService";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import SubscriptionTypeBadge from "./SubscriptionTypeBadge";
import SubscriptionPaymentBadge from "./SubscriptionPaymentBadge";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import SubscriptionStatusBadge from "./SubscriptionStatusBadge";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";
import SubscriptionService from "../../../services/SubscriptionService";
import TooltipPersistentOnHover from "../../common/Tooltip/TooltipPersistentOnHover";
import ToastContext from "../../../context/ToastContext";
import { SITE_SUBSCRIPTION_MODAL_QUERY_KEY } from "./SiteSubscription/ViewAllSiteSubscriptions";
import SubscriptionSharedUtils, {
  SubscriptionPricingType,
  SubscriptionStatusEnum,
} from "./SubscriptionSharedUtils";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import ExcelExporterService from "../../../services/ExcelExporterService";

const subscriptionValueExtractorByDataKey = {
  price: (sub) => {
    return sub.priceAmount > 0
      ? sub.currencyId + " " + NumberService.format(sub.priceAmount)
      : StringUtils.capitaliseWord(SubscriptionPricingType.FREE);
  },
  startDate: (sub) => DateTimeUtils.formatLocalDate(sub.startPeriod),
  endDate: (sub) => DateTimeUtils.formatLocalDate(sub.endPeriod),
  accountManager: (sub) => {
    return sub.accountManager
      ? sub.accountManager.full_name
        ? sub.accountManager.full_name
        : sub.accountManager.email
      : "";
  },
  siteCount: (sub) => {
    let siteCount = "";
    if (sub.siteSubscriptions) {
      siteCount = sub.siteSubscriptions.length;
      const deviantSitesCount =
        SubscriptionSharedUtils.getSiteSubscriptionDifferentFromContractCount(
          sub.siteSubscriptions
        );
      if (deviantSitesCount > 0) {
        siteCount += " . " + deviantSitesCount;
      }
    }
    return siteCount;
  },
};

const goToSubscriptionPage = (subscription, location, history) => {
  if (subscription.type === RESOURCES.CONTRACT) {
    const redirectPath = getViewContractSubscriptionPath(
      subscription.resourceId,
      subscription.id
    );
    history.push(getRedirectURLWithCurrentParam(redirectPath, location));
  } else {
    const query = updateQueryWithModalParam(
      location,
      SITE_SUBSCRIPTION_MODAL_QUERY_KEY,
      subscription.id
    );
    location.search = query.toString();
    const redirectPath = getViewSiteDetailsPath(subscription.resourceId);
    history.push(
      getPathWithTabKey(
        getRedirectURLWithCurrentParam(redirectPath, location),
        "subscriptions"
      )
    );
  }
};

const ViewAllSubscriptions = ({}) => {
  const history = useHistory();
  const location = useLocation();
  const toastContext = useContext(ToastContext);
  const permissionsCtx = useContext(PermissionsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    SubscriptionService.getAllSubscriptions()
      .then((response) => {
        setSubscriptions(response);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load subscriptions.</span>);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    subscriptions,
    SystemToolboxSearchKeysByPageName.subscriptions,
    {
      name: SORTING_TYPES.asc,
    },
  );

  const columns = [
    {
      header: "Name",
      dataKey: "name",
      sortable: true,
      headerStyle: { width: "350px" },
      renderer: (s) => <div className="contract-site-name">{s.name}</div>,
    },
    {
      header: "Type",
      dataKey: "type",
      sortable: true,
      headerStyle: { width: "100px" },
      renderer: (s) => (
        <>
          <SubscriptionTypeBadge type={s.type} />
          {s.paymentType && (
            <StatusLabel
              color={StatusLabel.Colors.gray}
              className="subscription__payment_type__display"
            >
              {StringUtils.capitaliseWord(s.paymentType)}
            </StatusLabel>
          )}
        </>
      ),
    },
    {
      header: "Plan",
      dataKey: "plan",
      sortable: true,
      headerStyle: { width: "150px" },
      renderer: (s) => <div className="subscription-plan">{s.plan}</div>,
    },
    {
      header: "Status",
      dataKey: "status",
      sortable: true,
      headerStyle: { width: "100px" },
      renderer: (s) => <SubscriptionStatusBadge status={s.status} />,
    },
    {
      header: "Payment",
      dataKey: "paymentStatus",
      sortable: true,
      headerStyle: { width: "100px" },
      renderer: (s) => <SubscriptionPaymentBadge payment={s.paymentStatus} />,
    },
    {
      header: "Price",
      dataKey: "priceAmount",
      sortable: true,
      headerStyle: { width: "150px" },
      renderer: (s) => (
        <div className="subscription-price">
          {s.pricingType === SubscriptionPricingType.PAID
            ? `${s.currencyId} ${NumberService.format(s.priceAmount)}`
            : StringUtils.capitaliseWord(SubscriptionPricingType.FREE)}
        </div>
      ),
    },
    {
      header: "Start Date",
      dataKey: "startPeriod",
      sortable: true,
      headerStyle: { width: "150px" },
      renderer: (s) => (
        <div className="subscription-start-date">
          {s.startPeriod ? DateTimeUtils.formatLocalDate(s.startPeriod) : "-"}
          {s.status === SubscriptionStatusEnum.PENDING && (
            <div className="gvds-text--caption">*</div>
          )}
        </div>
      ),
    },
    {
      header: "End Date",
      dataKey: "endPeriod",
      sortable: true,
      headerStyle: { width: "150px" },
      renderer: (s) => (
        <div className="subscription-end-date">
          {s.endPeriod ? DateTimeUtils.formatLocalDate(s.endPeriod) : "-"}
          {s.status === SubscriptionStatusEnum.PENDING && (
            <div className="gvds-text--caption">*</div>
          )}
        </div>
      ),
    },
    {
      header: "Account Manager",
      dataKey: "accountManager",
      headerStyle: { width: "130px" },
      renderer: (s) => (
        <div>
          {!s.accountManager ? (
            <span className="gvds-color--gray6">
              Unassigned
            </span>
          ) : (
            <span>
              <UserAvatar
                fullName={s.accountManager["full_name"]}
                userEmail={s.accountManager["email"]}
              />
            </span>
          )}
        </div>
      ),
    },
    {
      header: "Site Count",
      dataKey: "siteCount",
      headerStyle: { width: "130px" },
      renderer: (s) => (
        <div className="subscription-site-count">
          {s.siteSubscriptions ? (
            <>
              <span>{s.siteSubscriptions.length}</span>
              {SubscriptionSharedUtils.getSiteSubscriptionDifferentFromContractCount(
                s.siteSubscriptions
              ) > 0 ? (
                <>
                  <GVDSIcon
                    Icon={IconCircleFilled}
                    className="subscription__joined-bullet"
                  />
                  <TooltipPersistentOnHover
                    triggerClassName="subscription-site-count__deviant-site"
                    triggerContent={
                      <>
                        <span>
                          {SubscriptionSharedUtils.getSiteSubscriptionDifferentFromContractCount(
                            s.siteSubscriptions
                          )}
                        </span>
                        <GVDSIcon Icon={IconGitBranch} />
                      </>
                    }
                    tooltipContent="No. of sites which start period, end period, or subscribed features is not following this contract subscription's terms."
                  />
                </>
              ) : null}
            </>
          ) : (
            <span className="subscription-site-count__not-available">NA</span>
          )}
        </div>
      ),
    },
    {
      header: "Discount ID",
      dataKey: "discountCode",
      headerStyle: { width: "150px" },
      renderer: (s) =>
        s.discountCode ? (
          <div className="subscription-discount-code">{s.discountCode}</div>
        ) : (
          <div className="subscription-no-discount-code">No discount</div>
        ),
    },
    {
      header: "Comments",
      dataKey: "comments",
      headerStyle: {
        width: "100px",
      },
      renderer: (s) => (
        <div className="subscription-comment">
          {s.comments ? <CommentTooltip comment={s.comments} /> : "-"}
        </div>
      ),
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "120px" },
      renderer: (s) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => goToSubscriptionPage(s, location, history)}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
              className="edit-subscription-button"
            />
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => {
                window.open(
                  `mailto:${s.billingAdmin.email}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              icon={<GVDSIcon Icon={IconMail} />}
              tooltipText="Email Billing Admin"
              className="email-billing-admin-button"
            />
          </>
        );
      },
    },
  ];

  const exportListAsExcel = () => {
    const dataToExport = subscriptions.map((sub) => {
      const newSub = {
        ...sub,
        type:
          sub.paymentType !== null
            ? `${sub.type} - ${sub.paymentType}`
            : sub.type,
        priceAmount:
          sub.pricingType === SubscriptionPricingType.PAID
            ? `${sub.currencyId} ${NumberService.format(sub.priceAmount)}`
            : SubscriptionPricingType.FREE,
      };

      return newSub;
    });

    const flatJson = ExcelExporterService.generateFlatJsonByTableColumns(
      dataToExport,
      columns,
      subscriptionValueExtractorByDataKey
    );
    ExcelExporterService.exportToExcel(
      flatJson,
      `All Subscriptions_${DateTimeUtils.getTimestamp()}`,
      [25, 20, 20, 10, 12, 10, 12, 12, 12, 25, 10, 12, 20]
    );
  };

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <div>
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          onRowClick={(subscription) => {
            const pathWithTab = getPathWithTabKey(
              getRedirectURLWithCurrentParam(
                subscription.type === RESOURCES.CONTRACT
                  ? getViewContractDetailsPath(subscription.resourceId)
                  : getViewSiteDetailsPath(subscription.resourceId),
                location
              ),
              "subscriptions"
            );
            history.push(pathWithTab);
          }}
          noContent={
            subscriptions.length === 0 ? (
              <div className="table__no_content">No subscriptions</div>
            ) : (
              filteredSortedData.length === 0 && (
                <div className="table__no_content">
                  No subscriptions. Try changing your filter.
                </div>
              )
            )
          }
          viewControl={
            <>
              <GVDSTableCtrlContainer>
                <FilterSearchBox
                  className="subscription-search-box"
                  placeholder="Search"
                  value={searchText}
                  onInput={setSearchText}
                />
                <GVDSTableCtrlMultiSelect
                  options={uniq(
                    subscriptions.map((s) => s.typeFilter).flat()
                  ).sort()}
                  prefix="Type"
                  onChange={(filterKs) =>
                    setFilterKeys({
                      ...filterKeys,
                      typeFilter: filterKs,
                    })
                  }
                />
                <GVDSTableCtrlMultiSelect
                  options={uniq(subscriptions.map((s) => s.status).flat())}
                  prefix="Status"
                  onChange={(filterKs) =>
                    setFilterKeys({
                      ...filterKeys,
                      status: filterKs,
                    })
                  }
                />
                <GVDSTableCtrlMultiSelect
                  options={uniq(
                    subscriptions.map((s) => s.paymentStatus).flat()
                  )}
                  prefix="Payment"
                  onChange={(filterKs) =>
                    setFilterKeys({
                      ...filterKeys,
                      paymentStatus: filterKs,
                    })
                  }
                />
                <GVDSTableCtrlMultiSelect
                  options={uniq(subscriptions.map((s) => s.plan).flat())}
                  prefix="Plan"
                  onChange={(filterKs) =>
                    setFilterKeys({
                      ...filterKeys,
                      plan: filterKs,
                    })
                  }
                />
                <GVDSPagination
                  startIndex={startIndex}
                  endIndex={endIndex}
                  total={totalDataLength}
                  onChange={onPaginationChange}
                />
              </GVDSTableCtrlContainer>
            </>
          }
        />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Subscriptions</h1>
          {!permissionsCtx.isLoadingPermissions && (
            <>
              <Spacer />
              {permissionsCtx.permissions[PERMISSIONS.PORTAL_ADMIN] && (
                <GVDSButton
                  variant={buttonVariant.primary}
                  className="export-subscriptions-button"
                  onClick={exportListAsExcel}
                  text="Export to Excel"
                  icon={<GVDSIcon Icon={IconDownload} />}
                />
              )}
            </>
          )}
        </PageHeader.Title>
        <PageHeader.Description>
          Searching with Site name will show Contracts that include this Site as
          well.
          <br />
          Any dates with * means that it isn't confirmed until user makes
          payment.
        </PageHeader.Description>
      </PageHeader>
      {content}
    </Container>
  );
};

export default ViewAllSubscriptions;
