import axios from "axios";
import { API_URL } from "../../config/api-config";

export default class BestPracticeTagTag {
  static getAllBestPracticeTag = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/best_practices/tags"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deletePracticeTag = async (tagId) => {
    try {
      const response = await axios.delete(
        API_URL + "/api/v1/ref_data/best_practices/tag/" + tagId
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updatePracticeTag = async (tagId, tagName) => {
    try {
      const data = {
        bestPracticeTagName: tagName,
      };
      const response = await axios.patch(
        API_URL + `/api/v1/ref_data/best_practices/tag/${tagId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static savePracticeTag = async (tagName) => {
    try {
      const data = {
        bestPracticeTagName: tagName,
      };
      const response = await axios.post(
        API_URL + `/api/v1/ref_data/best_practices/tags`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
