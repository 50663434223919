import React from "react";
import { Trans, useTranslation } from "react-i18next";

const GVDSTableBulkActionBar = ({ selectedRowsCount, actionButtons }) => {
  const { t } = useTranslation();

  return (
    <div className="gvds-table-ctrl--bulk-action-bar__container">
      <div className="selected-rows-count">
        <Trans i18nKey="data-management.shared.label-rows-selected">
            <strong>{{selectedRowsCount}}</strong> rows selected
        </Trans>
      </div>
      {actionButtons}
    </div>
  );
};

export default GVDSTableBulkActionBar;
