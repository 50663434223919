export const DividerOrientation = {
  horizontal: "horizontal",
  vertical: "vertical",
};

const Divider = ({ className, orientation }) => {
  let dividerClassName;

  switch (orientation) {
    case DividerOrientation.horizontal:
      dividerClassName = "divider-horizontal";
      break;
    case DividerOrientation.vertical:
      dividerClassName = "divider-vertical";
      break;
    default:
      dividerClassName = "";
  }

  return (
    <div className={`divider__container${className? " "+className: ""}`}>
      <div className={dividerClassName}></div>
    </div>
  );
};

export default Divider;
