import React from "react";
import Form from "react-bootstrap/Form";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit } from "@tabler/icons-react";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSTableExpandableRow, {
  expandableIconCell,
} from "../../../../gvds-components/Table/GVDSTableExpandableRow";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import { useTranslation } from "react-i18next";

const EnvironmentalTypeCustomEmissionFactorsDisplay = ({
  environmentType,
  expandedTypes,
  toggleExpandedType,
  customEmissionFactorByEnvironmentalType,
  onEnvironmentalTypeToggle,
  addCustomEmissionFactorDialog,
  editCustomEmissionFactorDialog,
}) => {
  const { t } = useTranslation();

  const isExpanded = expandedTypes.indexOf(environmentType.id) >= 0;

  const customEmissionFactorsCount =
    customEmissionFactorByEnvironmentalType.length;
  const isCustomEmissionFactorPresent = customEmissionFactorsCount > 0;

  const controlColumns = [
    <td className="custom-emission-factor-table__tracking">
      <Form.Check
        type="switch"
        id={environmentType.id}
        label=""
        key={environmentType.id}
        value={environmentType.id}
        className="environmental-meter-setup__switch"
        onChange={() =>
          onEnvironmentalTypeToggle(
            isCustomEmissionFactorPresent,
            environmentType
          )
        }
        checked={isCustomEmissionFactorPresent}
      />
    </td>,
    customEmissionFactorsCount > 0 ? expandableIconCell(isExpanded) : <td />,
  ];

  const dataColumns = [
    <td className="custom-emission-factor-table__type">
      <div>{environmentType.name}</div>
    </td>,
    <td className="custom-emission-factor-table__ef_count">
      {customEmissionFactorsCount > 0 ? (
        <strong>{customEmissionFactorsCount}</strong>
      ) : (
        <em>
          {t("data-management.environmental.custom-ef.setup.use-default")}
        </em>
      )}
    </td>,
  ];

  return (
    <GVDSTableExpandableRow
      isExpanded={isExpanded}
      toggleIsExpanded={() => toggleExpandedType(environmentType.id)}
      disabled={customEmissionFactorsCount === 0}
      controlColumns={controlColumns}
      dataColumns={dataColumns}
      expandedBody={
        <div>
          <GVDSTableDisplay>
            <thead>
              <tr>
                <th>{t("shared-input-label.year")}</th>
                <th className="custom-emission-factor-values-table__ef_value">
                  {t("data-management.environmental.custom-ef.emission-factor")}
                </th>
                <th>{t("shared-input-label.description")}</th>
                <th>{t("shared-input-label.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {customEmissionFactorByEnvironmentalType.map(
                (customEmissionFactor) => (
                  <tr key={customEmissionFactor.id}>
                    <td>{customEmissionFactor.year}</td>
                    <td>{customEmissionFactor.factor}</td>
                    <td>{customEmissionFactor.source_description}</td>
                    <td>
                      <GVDSIconButton
                        onClick={() =>
                          editCustomEmissionFactorDialog(customEmissionFactor)
                        }
                        variant={iconButtonVariant.tertiary}
                        icon={<GVDSIcon Icon={IconEdit} />}
                        tooltipText={t("shared.edit")}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </GVDSTableDisplay>
          <div className="action-row-in-expanded-row">
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={() => addCustomEmissionFactorDialog(environmentType)}
              icon={<GVDSIcon Icon={IconCirclePlus} />}
              text={t(
                "data-management.environmental.custom-ef.setup.modal-title-add"
              )}
            />
          </div>
        </div>
      }
    />
  );
};

export default EnvironmentalTypeCustomEmissionFactorsDisplay;
