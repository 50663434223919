import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import { TextCopies } from "../../../config/text-copies";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import PolicyTrackerUtils from "../PolicyTrackerUtils";
import { StringUtils } from "../../../services/UtilsService";
import GVDSTable from "../../../gvds-components/Table/GVDSTable";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import React from "react";
import { PriorityLevelInfo } from "../PolicyTrackerInfo";

const PolicyTrackerTable = ({
  isPortfolio,
  policies,
  currentPageData,
  startIndex,
  endIndex,
  sortKeys,
  setSortKeys,
  filteredSortedData,
  searchText,
  setSearchText,
  totalDataLength,
  onPaginationChange,
  gotoDetailsPage,
}) => {
  const columns = [
    {
      header: (
        <div className="text-icon-aligned-horizontally">
          Priority
          <span className="mx-1">
            <InfoTooltip info={<PriorityLevelInfo />} placement="right" />
          </span>
        </div>
      ),
      dataKey: "priorityLevelOrderIndex",
      sortable: true,
      headerStyle: { width: "80px" },
      renderer: (p) =>
        p.priorityLevel ? (
          <StatusLabel
            color={PolicyTrackerUtils.getPriorityLevelLabelColor(
              p.priorityLevel
            )}
          >
            {StringUtils.getTitleCase(p.priorityLevel)}
          </StatusLabel>
        ) : null,
    },
    {
      header: "Policy Name",
      dataKey: "name",
      sortable: true,
      headerStyle: { width: "300px" },
      renderer: (p) => <div>{p.name}</div>,
    },
    {
      header: (
        <div className="text-icon-aligned-horizontally">
          E/S/G
          <span className="mx-1">
            <InfoTooltip
              info={TextCopies.PolicyTracker.esgDescription}
              placement="right"
            />
          </span>
        </div>
      ),
      dataKey: "esgOrderIndex",
      sortable: true,
      headerStyle: { width: "80px" },
      renderer: (p) => {
        return PolicyTrackerUtils.getESGIcons(p.esgStatuses);
      },
    },
    {
      header: "Topics",
      dataKey: "topicOrderIndex",
      sortable: true,
      headerStyle: { width: "150px" },
      renderer: (p) => <div>{p.topics ? p.topics.join(", ") : ""}</div>,
    },
    {
      header: (
        <div className="text-icon-aligned-horizontally">
          Compliance Obligation
          <span className="mx-1">
            <InfoTooltip
              info={
                <div className="policy-tracker__table__compliance-obligation-tooltips">
                  {TextCopies.PolicyTracker.complianceObligation}
                </div>
              }
              placement="right"
            />
          </span>
        </div>
      ),
      dataKey: "complianceObligationOrderIndex",
      sortable: true,
      headerStyle: { width: "100px" },
      renderer: (p) => <div>{p.complianceObligation}</div>,
    },
    {
      header: (
        <div className="text-icon-aligned-horizontally">
          Jurisdiction Level
          <span className="mx-1">
            <InfoTooltip
              info={<div>{TextCopies.PolicyTracker.jurisdictionLevel}</div>}
              placement="left"
            />
          </span>
        </div>
      ),
      dataKey: "jurisdictionLevelOrderIndex",
      sortable: true,
      headerStyle: { width: "100px" },
      renderer: (p) => <div>{p.jurisdictionLevel}</div>,
    },
  ];

  if (isPortfolio) {
    columns.push({
      header: "Country",
      dataKey: "countryName",
      sortable: true,
      headerStyle: { width: "120px" },
      renderer: (p) => <div>{p.country.name}</div>,
    });
  }

  return (
    <div>
      <GVDSTable
        hideRowNumber={true}
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        onRowClick={(policy) => gotoDetailsPage(policy.id)}
        noContent={
          policies.length === 0 ? (
            <div className="table__no_content">No policies</div>
          ) : (
            filteredSortedData.length === 0 && (
              <div className="table__no_content">
                No policy found. Try adjusting your filter.
              </div>
            )
          )
        }
        viewControl={
          <>
            <GVDSTableCtrlContainer>
              <FilterSearchBox
                className="policy-search-box"
                placeholder="Search for a policy"
                value={searchText}
                onInput={setSearchText}
              />
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
          </>
        }
      />
    </div>
  );
};

export default PolicyTrackerTable;
