import React, { useContext } from "react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconSparkles } from "@tabler/icons-react";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GuidedTourContext from "../../FloatingAssistant/GuidedTour/GuidedTourContext";
import { useTranslation } from "react-i18next";

const BrandNewLookWidget = () => {
  const { t } = useTranslation();

  const guidedTourContext = useContext(GuidedTourContext);

  return (
    <div className="base-widget__container widget--new-look__container">
      <div className="base-widget__heading-icon">
        <GVDSIcon Icon={IconSparkles} />
      </div>
      <div className="base-widget__heading">
        {t("landing-page.empty-announcement-placeholder.title")}
      </div>
      <div className="d-flex flex-column">
        <div className="flex-grow-1">
          {t("landing-page.empty-announcement-placeholder.description")}
        </div>
        <div>
          <GVDSButton
            variant={buttonVariant.primary}
            text={t("landing-page.empty-announcement-placeholder.cta")}
            onClick={guidedTourContext.startGuidedTour}
          />
        </div>
      </div>
    </div>
  );
};

export default BrandNewLookWidget;
