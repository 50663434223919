import React, { useContext, useEffect, useState } from "react";

import FeatureService from "../services/FeatureService";
import UserProfileContext from "./UserProfileContext";
import { isPrereqReady } from "../components/HOC/withPrereqReady";

const FeatureContext = React.createContext(null);

export const FeatureProvider = (props) => {
  const userProfileContext = useContext(UserProfileContext);

  const [features, setFeatures] = useState({});
  const [isLoadingFeatures, setIsLoadingFeatures] = useState(true);

  const store = {
    isLoadingFeatures: isLoadingFeatures,
    features: features,
  };

  const loadFeatures = async () => {
    try {
      setIsLoadingFeatures(true);
      const features = await FeatureService.getAvailableFeatures();
      setFeatures(features);
    } catch {
      setFeatures([]);
    } finally {
      setIsLoadingFeatures(false);
    }
  };

  useEffect(() => {
    if (isPrereqReady(userProfileContext)) {
      loadFeatures();
    } else {
      setIsLoadingFeatures(true);
    }
  }, [
    userProfileContext
  ]);

  return (
    <FeatureContext.Provider value={store}>
      {props.children}
    </FeatureContext.Provider>
  );
};

export default FeatureContext;
