import React, { useContext, useEffect, useRef, useState } from "react";
import { MAIN_PORTFOLIO, RESOURCES } from "../../../config/constants";
import GVDSIcon from "../../Icons/GVDSIcon";
import GVDSCustomIcons from "../../Icons/GVDSIconCustom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import WorkspacePortfolioSelector from "./WorkspacePortfolioSelector";
import WorkspaceSiteSelector from "./WorkspaceSiteSelector";
import WorkspaceInventoryIcon from "../../common/WorkspaceInventoryIcon";
import UserInventoryContext, {
  INACTIVE_SITES,
  isMultipleWorkspaces,
  SPECIAL_CONTRACTS,
} from "../../../context/UserInventoryContext";
import WorkspaceNavContext from "./WorkspaceNavContext";
import Modal from "react-bootstrap/Modal";
import NavWrapperContext from "../NavWrapperContext";
import ContractSelector from "./WorkspaceNavContractSelector";

const WorkspaceSelectorModal = () => {
  const workspaceNavContext = useContext(WorkspaceNavContext);
  const navWrapperContext = useContext(NavWrapperContext);

  const userInventory = useContext(UserInventoryContext);
  const contracts = userInventory.contracts.get;
  const hasContract =
    contracts && contracts.find((c) => !SPECIAL_CONTRACTS.includes(c.name));

  const [portfolioIdForSiteFilter, setPortfolioIdForSiteFilter] =
    useState(null);
  const [tabActiveKey, setTabActiveKey] = useState("sites");

  const modalRef = useRef();

  useEffect(() => {
    const defaultOpenedTab = "sites";
    setTabActiveKey(defaultOpenedTab);
  }, [workspaceNavContext.isOpen]);

  if (userInventory.isLoadingInventory.get || !userInventory.selectedTreeNode.get) {
    return null;
  }

  let content;

  const portfoliosUnderSelectedContract =
    userInventory.portfoliosUnderSelectedContract.get;

  if (
    portfoliosUnderSelectedContract &&
    portfoliosUnderSelectedContract.length > 0
  ) {
    const mainPortfolio = portfoliosUnderSelectedContract.find(
      (p) => p.name === MAIN_PORTFOLIO
    );

    const noOfPortfolios = portfoliosUnderSelectedContract.length;
    const noOfAllSites = mainPortfolio.sites.length;

    let siteCountDisplay = noOfAllSites;
    let sites = mainPortfolio.sites;
    let subportfolioForSiteFilter = null;

    if (portfolioIdForSiteFilter !== null) {
      subportfolioForSiteFilter = portfoliosUnderSelectedContract.find(
        (p) => p.id === portfolioIdForSiteFilter
      );

      if (subportfolioForSiteFilter) {
        sites = subportfolioForSiteFilter.sites;
        const noOfSites = sites.length;
        siteCountDisplay = `${noOfSites}`;
      } else {
        setPortfolioIdForSiteFilter(null);
      }
    }

    const onFilterSiteBySubportfolio = (portfolioId) => {
      setPortfolioIdForSiteFilter(portfolioId);
      setTabActiveKey("sites");
    };

    content = (
      <>
        <Tabs activeKey={tabActiveKey} onSelect={(key) => setTabActiveKey(key)}>
          <Tab eventKey="portfolio" title={`Portfolio (${noOfPortfolios})`}>
            <WorkspacePortfolioSelector
              onFilterSiteBySubportfolio={onFilterSiteBySubportfolio}
            />
          </Tab>
          <Tab eventKey="sites" title={`Sites (${siteCountDisplay})`}>
            <WorkspaceSiteSelector
              selectedSubportfolio={subportfolioForSiteFilter}
              clearSelectedSubportfolio={() =>
                setPortfolioIdForSiteFilter(null)
              }
              sites={sites}
            />
          </Tab>
        </Tabs>
      </>
    );
  } else {
    const sites =
      userInventory.selectedContractId.get === INACTIVE_SITES
        ? userInventory.inactiveSites.get
        : userInventory.sites.get;

    const noOfAllSites = sites?.length;
    content = (
      <>
        <Tabs activeKey="sites">
          <Tab eventKey="sites" title={`Sites (${noOfAllSites})`}>
            <WorkspaceSiteSelector sites={sites} />
          </Tab>
        </Tabs>
      </>
    );
  }

  return (
    <Modal
      ref={modalRef}
      show={workspaceNavContext.isOpen}
      onHide={workspaceNavContext.close}
      className="workspace-nav--selector-modal-component"
      dialogClassName="workspace-nav--selector-modal-dialog"
      contentClassName={
        "workspace-nav--selector-modal-content" +
        (navWrapperContext.isSideNavMinimised ? " minimised-side-nav" : "")
      }
      backdropClassName="workspace-nav--selector-modal-backdrop"
    >
      <Modal.Body>
        {hasContract && <ContractSelector parentModalRef={modalRef} />}
        {content}
      </Modal.Body>
    </Modal>
  );
};

const CurrentWorkspaceDisplay = ({ hasOtherItems }) => {
  const userInventory = useContext(UserInventoryContext);
  const selectedTreeNode = userInventory.selectedTreeNode.get;

  if (!selectedTreeNode) {
    return null;
  }

  const nodeValue = selectedTreeNode.nodeValue;

  const inventoryType = nodeValue.type;
  const inventoryName = nodeValue.name;

  let inventoryNameDisplay = inventoryName;
  let icon = WorkspaceInventoryIcon[RESOURCES.SITE];

  if (inventoryType === RESOURCES.PORTFOLIO) {
    icon = WorkspaceInventoryIcon[RESOURCES.PORTFOLIO];
  } else if (inventoryType === RESOURCES.SITE) {
  } else if (inventoryType === RESOURCES.FACILITY) {
    const siteParent = selectedTreeNode.parents.find(
      (n) => n.nodeValue.type === RESOURCES.SITE
    );
    const siteName = siteParent.nodeValue.name;
    inventoryNameDisplay = `${siteName}: ${inventoryName}`;
  }

  return (
    <div className="workspace-nav--current-workspace-display">
      <GVDSIcon Icon={icon} />
      <div className="name-display">{inventoryNameDisplay}</div>
      {hasOtherItems && <GVDSCustomIcons.Expand />}
    </div>
  );
};

const WorkspaceNav = () => {
  const workspaceNavContext = useContext(WorkspaceNavContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedTreeNode = userInventory.selectedTreeNode.get;

  const hasOtherItems =
    !!selectedTreeNode &&
    (isMultipleWorkspaces(selectedTreeNode) ||
      userInventory.contracts.get.length > 1);

  const onTriggerOpenModal = () => {
    if (hasOtherItems) {
      workspaceNavContext.open();
    }
  };

  return (
    <>
      <div
        className={
          hasOtherItems
            ? "gvds-top-nav--workspace-interactable guided-tour-target__new-look__workspace-selector"
            : "gvds-top-nav--workspace-static"
        }
        onClick={onTriggerOpenModal}
      >
        <CurrentWorkspaceDisplay hasOtherItems={hasOtherItems} />
      </div>
      <WorkspaceSelectorModal />
    </>
  );
};

export default WorkspaceNav;
