import {
  performanceTimelineChartConfig,
  performanceTimelineChartMinimapMarginInPx,
  selectedChartMinimapStyle,
} from "./PerformanceTimelineViewConfig";
import { GVDSColors } from "../../../../styles/gvds-colors";
import { Brush } from "@visx/brush";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { PerformanceTimelineChartUtils } from "./PerformanceTimelineChartUtils";
import * as d3 from "d3";
import ToastContext from "../../../../context/ToastContext";
import DashboardDataContext from "../../Context/DashboardDataContext";

const BrushHandle = ({ x, height, isBrushActive }) => {
  const pathWidth = performanceTimelineChartConfig.brushHandlePathWidthInPx;
  const pathHeight = performanceTimelineChartConfig.brushHandlePathHeightInPx;
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill={GVDSColors.tealSolid}
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5"
        stroke={GVDSColors.tealSolid}
        strokeWidth="1"
        style={{ cursor: "ew-resize" }}
      />
      <path
        d="M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke={GVDSColors.white}
        strokeWidth="1"
        style={{ cursor: "ew-resize" }}
      />
    </Group>
  );
};

const VerticalStackedBarChartMinimap = ({
  width,
  height,
  chartData,
  yAxisScaleMaxValue,
  setFilteredData,
  periodType,
  minimapPosition,
  onMinimapPositionChange,
}) => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const toastContext = useContext(ToastContext);
  const chartMinimapRef = useRef(null);
  const firstRenderRef = useRef(true);
  const selectedStartXDomainValue = useRef(null);

  const xChartMinimapMax = Math.max(
    width -
      performanceTimelineChartMinimapMarginInPx.left -
      performanceTimelineChartMinimapMarginInPx.right,
    0
  );
  const yChartMinimapMax = Math.max(
    height -
      performanceTimelineChartMinimapMarginInPx.top -
      performanceTimelineChartMinimapMarginInPx.bottom,
    0
  );

  useEffect(() => {
    if (!firstRenderRef.current) {
      setMinimapPosition(0, Math.min(maxChartMinimapWidth, xChartMinimapMax));
      onMinimapPositionChange(
        0,
        performanceTimelineChartConfig.maxBarCount - 1
      );
    } else {
      firstRenderRef.current = false;
    }
  }, [periodType, dashboardHolderFacade.selectedConfigHolder.continuousTime]);

  useEffect(() => {
    if (minimapPosition !== null) {
      setMinimapPosition(
        Math.max(
          0,
          chartMinimapXAxisScale(minimapPosition.startXDomainValue) +
            performanceTimelineChartConfig.minimapBrushRepositionToleranceInPx
        ),
        Math.min(
          xChartMinimapMax,
          chartMinimapXAxisScale(minimapPosition.endXDomainValue) -
            performanceTimelineChartConfig.minimapBrushRepositionToleranceInPx
        )
      );
    } else {
      setMinimapPosition(0, Math.min(maxChartMinimapWidth, xChartMinimapMax));
      onMinimapPositionChange(
        0,
        performanceTimelineChartConfig.maxBarCount - 1
      );
    }
  }, [minimapPosition]);

  const setMinimapPosition = (startXDomainValue, endXDomainValue) => {
    const updater = (prevMinimap) => {
      const newExtent = chartMinimapRef.current.getExtent(
        { x: startXDomainValue, y: 0 },
        { x: endXDomainValue, y: yChartMinimapMax }
      );

      const newState = {
        ...prevMinimap,
        start: { x: newExtent.x0, y: newExtent.y0 },
        end: { x: newExtent.x1, y: newExtent.y1 },
        extent: newExtent,
      };

      return newState;
    };

    chartMinimapRef.current.updateBrush(updater);
  };

  const chartMinimapXAxisScale = useMemo(
    () =>
      scaleLinear({
        range: PerformanceTimelineChartUtils.getOrdinalScale(
          0,
          xChartMinimapMax,
          chartData.length
        ),
        domain: d3.ticks(0, chartData.length - 1, chartData.length - 1),
      }),
    [xChartMinimapMax, chartData]
  );

  const chartMinimapYAxisScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, yAxisScaleMaxValue],
        range: [yChartMinimapMax, 0],
        round: true,
        nice: true,
      }),
    [yChartMinimapMax, yAxisScaleMaxValue]
  );

  const maxChartMinimapWidth = chartMinimapXAxisScale(
    performanceTimelineChartConfig.maxBarCount - 1
  );

  const initialChartMinimapPosition = {
    start: { x: 0 },
    end: { x: maxChartMinimapWidth },
  };

  const onBrushChange = (domain) => {
    let startIndex;
    let endIndex;

    if (!domain) {
      startIndex = Math.round(selectedStartXDomainValue.current);
      endIndex = Math.round(selectedStartXDomainValue.current);
    } else {
      startIndex = Math.round(Math.max(domain.x0, 0));
      endIndex = Math.round(Math.max(domain.x1, 0));

      const selectedBars = endIndex - startIndex + 1;
      if (selectedBars > performanceTimelineChartConfig.maxBarCount) {
        endIndex = startIndex + performanceTimelineChartConfig.maxBarCount - 1;
      }
    }

    setFilteredData(chartData.slice(startIndex, endIndex + 1));
  };

  const onBrushEnd = (domain) => {
    let brushDomain = {};

    if (!domain) {
      brushDomain.x0 = selectedStartXDomainValue.current;
      brushDomain.x1 = selectedStartXDomainValue.current;
    } else {
      brushDomain.x0 = domain.x0;
      brushDomain.x1 = domain.x1;
    }

    let minimapStartXDomainValue;
    let minimapEndXDomainValue;

    const brushWidth =
      chartMinimapXAxisScale(brushDomain.x1 - brushDomain.x0) -
      performanceTimelineChartConfig.brushHandlePathWidthInPx / 2;

    const isBrushWidthOverMaxWidth =
      Math.min(brushWidth, xChartMinimapMax) - maxChartMinimapWidth >=
      performanceTimelineChartConfig.minimapBrushResizeToleranceInPx;
    if (isBrushWidthOverMaxWidth) {
      minimapStartXDomainValue = Math.max(brushDomain.x0, 0);
      minimapEndXDomainValue =
        minimapStartXDomainValue +
        performanceTimelineChartConfig.maxBarCount -
        1;

      toastContext.addFailToast(
        <span>
          Maximum {performanceTimelineChartConfig.maxBarCount} {periodType}s
          allowed.
        </span>
      );
    } else {
      minimapStartXDomainValue = brushDomain.x0;
      minimapEndXDomainValue = brushDomain.x1;
    }

    onMinimapPositionChange(minimapStartXDomainValue, minimapEndXDomainValue);
  };

  const onBrushStart = (domain) => {
    selectedStartXDomainValue.current = domain.x;
  };

  return (
    <Brush
      xScale={chartMinimapXAxisScale}
      yScale={chartMinimapYAxisScale}
      width={xChartMinimapMax}
      height={yChartMinimapMax}
      margin={performanceTimelineChartMinimapMarginInPx}
      handleSize={performanceTimelineChartConfig.brushHandlePathWidthInPx}
      innerRef={chartMinimapRef}
      resizeTriggerAreas={["left", "right"]}
      brushDirection="horizontal"
      initialBrushPosition={initialChartMinimapPosition}
      onChange={onBrushChange}
      onBrushEnd={onBrushEnd}
      onBrushStart={onBrushStart}
      selectedBoxStyle={selectedChartMinimapStyle}
      useWindowMoveEvents
      renderBrushHandle={(props) => <BrushHandle {...props} />}
    />
  );
};

export default VerticalStackedBarChartMinimap;
