import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import TargetService from "../../services/TargetService";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import { NumberService } from "../../services/UtilsService";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import { getViewTargetDetailsPath } from "../../config/ROUTES_NAME";
import {
  DATA_STATUS,
  RESOURCES,
  TARGET_PERIOD_TYPE,
  TARGET_STATUS,
} from "../../config/constants";
import SafeDataService from "../../services/SafeDataService";
import MissingValueWarningIcon from "../common/MissingValueWarningIcon";
import Button from "react-bootstrap/cjs/Button";
import PopoverOnTruncated from "../common/Tooltip/PopoverOnTruncated";
import WorkspaceInventoryIcon from "../../gvds-components/common/WorkspaceInventoryIcon";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import GVDSCustomIcons from "../../gvds-components/Icons/GVDSIconCustom";
import { IconSearch, IconTargetArrow } from "@tabler/icons-react";

const TargetCard = ({ target }) => {
  const history = useHistory();
  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [details, setDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (showDetails && isEmpty(details) && selectedInventory?.id) {
      setIsLoading(true);
      TargetService.getDetails(
        target.id,
        target.currentDateForCalculation,
        selectedInventory.type,
        selectedInventory.id
      )
        .then((data) => {
          setDetails(data);
        })
        .catch((e) => {
          toastContext.addFailToast(
            <span>Failed to load target details.</span>
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [showDetails, selectedInventory]);

  const redirectToTargetDetails = () => {
    history.push(
      getRedirectURLWithCurrentParam(
        getViewTargetDetailsPath(target.id),
        location
      )
    );
  };

  return (
    <div
      className={"target-box" + (showDetails ? " target-box--open" : "")}
      onClick={redirectToTargetDetails}
    >
      <div className="target-box--content-container">
        <div className="goal-name">
          {target.goal ? target.goal.name : "- Not associated to a goal"}
        </div>
        <div className="target-box--summary">
          <PopoverOnTruncated
            content={target.name}
            contentClassName="target-box--summary__target-name"
          />
          {(target.isFromCollectiveTarget ||
            target.status === TARGET_STATUS.CLOSED) && (
            <div className="status-container">
              {target.isFromCollectiveTarget && (
                <StatusLabel color={StatusLabel.Colors.gray}>
                  <GVDSIcon
                    Icon={WorkspaceInventoryIcon[RESOURCES.PORTFOLIO]}
                  />
                  Shared Target
                </StatusLabel>
              )}
              {target.status === TARGET_STATUS.CLOSED && (
                <StatusLabel color={StatusLabel.Colors.gray}>
                  Closed
                </StatusLabel>
              )}
            </div>
          )}
          {!target.isFromCollectiveTarget && target.showCalculatedValue && (
            <div className="preview-control">
              <Button
                variant="link"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDetails(!showDetails);
                }}
              >
                {showDetails ? "Close Preview" : "Preview"}
              </Button>
            </div>
          )}
        </div>
        {showDetails && (
          <div className="target-box--preview-container">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {details.isTargetAchieved && (
                  <div className="achieve-status--container">
                    <StatusLabel color={StatusLabel.Colors.green}>
                      Achieved
                    </StatusLabel>
                    {/*{details.achievedStatus === DATA_STATUS.MISSING && (*/}
                    {/*  <MissingValueWarningIcon />*/}
                    {/*)}*/}
                  </div>
                )}
                <div className="target-preview--target-value">
                  <div className="target-preview--subheader">
                    Target{" "}
                    {details.targetValue?.status === DATA_STATUS.MISSING && (
                      <MissingValueWarningIcon />
                    )}
                  </div>
                  <div className="target-preview--value-container">
                    <div className="target-preview--value-text">
                      {details.targetValue &&
                        NumberService.format(
                          SafeDataService.getValue(details.targetValue),
                          details.precision,
                          details.precision
                        )}
                    </div>
                    <div className="target-preview--unit-text">
                      {target.unitId}{" "}
                      {!!target.intensityMetricPerLabel &&
                        target.intensityMetricPerLabel}
                    </div>
                  </div>
                </div>
                <div className="target-preview--current-value">
                  <div className="target-preview--subheader">
                    {target.periodType === TARGET_PERIOD_TYPE.TOTAL
                      ? "To Date"
                      : "Current (T-12)"}{" "}
                    {details.currentValue?.status === DATA_STATUS.MISSING && (
                      <MissingValueWarningIcon />
                    )}
                  </div>
                  <div className="target-preview--value-container">
                    <div className="target-preview--value-text">
                      {details.currentValue &&
                        NumberService.format(
                          SafeDataService.getValue(details.currentValue),
                          details.precision,
                          details.precision
                        )}
                    </div>
                    <div className="target-preview--unit-text">
                      {target.unitId}{" "}
                      {!!target.intensityMetricPerLabel &&
                        target.intensityMetricPerLabel}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "target-preview--difference-value" +
                    (details.isTargetAchieved ? " achieved" : "")
                  }
                >
                  <div className="target-preview--difference--value-text">
                    {details.targetDiff &&
                      NumberService.format(
                        SafeDataService.getValue(details.targetDiff),
                        details.precision,
                        details.precision
                      )}
                  </div>{" "}
                  <div className="target-preview--difference--unit-text">
                    {target.usePercentageChange ? "%" : target.unitId} from
                    Target
                  </div>
                  {/*{details.targetDiff.status === DATA_STATUS.MISSING && (*/}
                  {/*  <MissingValueWarningIcon />*/}
                  {/*)}*/}
                </div>
                {details.targetDiff.status === DATA_STATUS.MISSING && (
                  <div className="data-status-message--container">
                    <div className="data-status-message--icon-container">
                      <GVDSCustomIcons.Info />
                    </div>
                    <div className="data-status-message--text">
                      Preview contains data gaps. Download Report for more
                      details.
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const getNoContentPage = (isFiltered) => {
  return (
    <div className="view-all-targets__no-content">
      {isFiltered ? (
        <>
          <GVDSIcon Icon={IconSearch} className="gvds-empty-content--icon" />
          <div className="gvds-empty-content--title">No results found</div>
          <div>Clear your filters and try again.</div>
        </>
      ) : (
        <>
          <GVDSIcon
            Icon={IconTargetArrow}
            className="gvds-empty-content--icon"
          />
          <div className="gvds-empty-content--title">
            Start building your targets
          </div>
          <div>
            Click on the "Create Target" button at the top-right of this section
            and start tracking your target progress.
          </div>
        </>
      )}
    </div>
  );
};

const ViewAllTargets = ({ targets, isFiltered }) => {
  if (targets.length === 0) {
    return getNoContentPage(isFiltered);
  } else {
    return (
      <div className="view-all-targets-container">
        {targets.map((t) => (
          <TargetCard key={t.id} target={t} />
        ))}
      </div>
    );
  }
};

export default ViewAllTargets;
