import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import InlineSpinner from "../../components/common/InlineSpinner";

const GVDSTextButtonWithLoadingAction = React.forwardRef(
  (
    { text = "", disabled, icon = null, onClickAsyncFunc, className, ...props },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (event) => {
      setIsLoading(true);

      try {
        await onClickAsyncFunc(event);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Button
        ref={ref}
        variant="link"
        className={`gvds-text-button ${className ?? ""}`}
        disabled={disabled || isLoading}
        onClick={handleClick}
        {...props}
      >
        {isLoading ? (
          <div style={{ height: "24px" }}>
            <InlineSpinner />
          </div>
        ) : (
          <>
            {icon}
            {text}
          </>
        )}
      </Button>
    );
  }
);

export default GVDSTextButtonWithLoadingAction;
