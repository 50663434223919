import axios from "axios";
import { API_URL } from "../config/api-config";
import { TASK_MANAGEMENT_STATUS } from "../config/constants";
import { DateTimeUtils, RTFUtils } from "./UtilsService";
import { TASK_MANAGEMENT_STATUS_ORDER } from "../components/TaskManagement/SharedTaskManagementUtil";

export default class TaskManagementService {
  static getAllTask = async (resource_type, resource_id, is_archived) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/task_management/tasks`,
        {
          params: { resource_type, resource_id, is_archived },
        }
      );
      return response.data.map((t) => {
        t.status_order = TASK_MANAGEMENT_STATUS_ORDER[t.status];
        return t;
      });
    } catch (error) {
      throw error.response;
    }
  };

  static taskStatusSortFn = (status1, status2) => {
    const sortOrder1 = TASK_MANAGEMENT_STATUS[status1];
    const sortOrder2 = TASK_MANAGEMENT_STATUS[status2];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return -1;
    } else if (sortOrder2 !== undefined) {
      return 1;
    } else {
      return status1.localeCompare(status2);
    }
  };

  static getAvailableAssignees = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/task_management/assignees`,
        {
          params: { resource_type, resource_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static createTask = async (resource_type, resource_id, data) => {
    const payload = TaskManagementService.getPayload(data);

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/task_management/tasks`,
        payload,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateTask = async (resource_type, resource_id, task_id, data) => {
    const payload = TaskManagementService.getPayload(data);

    try {
      const response = await axios.put(
        `${API_URL}/api/v1/task_management/tasks/${task_id}`,
        payload,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getPayload = (data) => {
    return {
      name: data.name,
      description:
        data.description && !RTFUtils.isEmpty(data.description)
          ? RTFUtils.convertEditorStateToHTML(data.description)
          : "",
      deadline: DateTimeUtils.getUTCISOString(data.deadline),
      status: data.status,
      assignees: data.assignees.map((a) => {
        return a.id;
      }),
    };
  };

  static deleteTask = async (resource_type, resource_id, task_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/task_management/tasks/${task_id}`,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static setArchiveStatus = async (
    resource_type,
    resource_id,
    task_id,
    archive_action
  ) => {
    try {
      const response = await axios.patch(
        `${API_URL}/api/v1/task_management/tasks/${task_id}`,
        { archive_action: archive_action },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getTask = async (resource_type, resource_id, task_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/task_management/tasks/${task_id}`,
        {
          params: { resource_type, resource_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}
