import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import withAuthentication from "../HOC/withAuthentication";
import Form from "react-bootstrap/Form";
import SiteService from "../../services/SiteService";
import {
  getViewSiteDetailsPath,
  VIEW_ALL_SITES,
} from "../../config/ROUTES_NAME";
import CreateSiteForm from "./CreateSiteForm";
import ToastContext from "../../context/ToastContext";
import { useHistory, useLocation } from "react-router-dom";
import LoadingSpinner from "../common/LoadingSpinner";
import {
  getPathWithTabKey,
  getRedirectURLWithCurrentParam,
} from "../common/QueryHandler";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import { isUsersValid } from "../User/AddUsersWithRole";

const CreateSite = () => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();
  const location = useLocation();

  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [site, setSite] = useState({});
  const [siteErrors, setSiteErrors] = useState({});

  const isSiteValid = () => {
    return (
      site.name &&
      site.type &&
      site.type.id &&
      site.location?.country?.id &&
      site.location.country.id.length > 0 &&
      site.location?.city &&
      site.location.city.length > 0 &&
      site.location?.zip_code &&
      site.location.zip_code.length > 0 &&
      site.main_attribute?.default_currency?.id &&
      site.main_attribute.default_currency.id.length > 0 &&
      isUsersValid(site.usersWithRoles)
    );
  };

  const createSite = async (event) => {
    event.preventDefault();

    setIsValidated(true);

    if (isSiteValid()) {
      try {
        const siteDTO = await SiteService.createNewSite(site);

        toastContext.addSuccessToast(
          <span>
            Site created.
            <br />
            Add subscription to provide access.
          </span>
        );

        const pathWithTabKey = getPathWithTabKey(
          getRedirectURLWithCurrentParam(
            getViewSiteDetailsPath(siteDTO.id),
            location
          ),
          "subscriptions"
        );
        history.push(pathWithTabKey);
      } catch (error) {
        if (error.status === 400) {
          setSiteErrors(error.data.message);
        } else {
          toastContext.addFailToast(<span>Failed to create site.</span>);
        }
        setIsLoading(false);
      }
    }
  };

  const backToSiteList = () => {
    history.push(VIEW_ALL_SITES);
  };

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return to Site List"
          onClick={backToSiteList}
        />
        <PageHeader.Title>
          <h1>Create site</h1>
        </PageHeader.Title>
      </PageHeader>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Form>
          <CreateSiteForm
            site={site}
            onChange={setSite}
            isValidated={isValidated}
            errors={siteErrors}
          />
          <div className="d-flex justify-content-end">
            <GVDSButton
              variant={buttonVariant.tertiary}
              className="me-2"
              onClick={backToSiteList}
              text="Cancel"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              text="Create Site"
              onClick={createSite}
            />
          </div>
        </Form>
      )}
    </Container>
  );
};

export default withAuthentication(CreateSite);
