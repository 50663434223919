import React, { useContext, useEffect, useState } from "react";
import { PERMISSIONS, RESOURCES } from "../../../../config/constants";
import PermissionsContext from "../../../../context/PermissionsContext";
import { useHistory } from "react-router-dom";
import {
  getAddSubscriptionToContractPath,
  getDuplicateContractSubscriptionPath,
  getViewContractSubscriptionPath,
} from "../../../../config/ROUTES_NAME";
import SubscriptionSharedUtils from "../SubscriptionSharedUtils";
import SubscriptionService from "../../../../services/SubscriptionService";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { DateTimeUtils, StringUtils } from "../../../../services/UtilsService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteContractSubscriptionPrompt from "./DeleteContractSubscriptionPrompt";
import TooltipPersistentOnHover from "../../../common/Tooltip/TooltipPersistentOnHover";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import {
  IconCircleFilled,
  IconCirclePlus,
  IconCopy,
  IconEdit,
  IconExternalLink,
  IconGitBranch,
  IconTrash,
} from "@tabler/icons-react";
import StatusLabel from "../../../../gvds-components/common/StatusLabel";
import UserAvatar from "../../../common/UserAvatar";
import SubscriptionStatusBadge from "../SubscriptionStatusBadge";

const getSubscriptionDisplay = (
  subscription,
  allFeatures,
  actionButtons,
  accountManager,
  billingAdmin,
  packagePlans
) => {
  const selectedPlan = packagePlans?.find(
    (pp) => pp.package_plan_id === subscription.planId
  );

  return (
    <section key={subscription.id} className="subscription-box__container mt-3">
      <header className="d-flex flex-row align-items-center">
        {selectedPlan && (
          <div className="subscription-plan">
            {StringUtils.capitaliseWord(selectedPlan.name)}
          </div>
        )}
        {SubscriptionSharedUtils.getShorthandFeatureDisplay(
          allFeatures,
          subscription.features,
          "right",
          false
        )}
        <div className="ms-2">
          <SubscriptionStatusBadge status={subscription.status} />
        </div>
        {subscription.paymentType && (
          <div className="ms-1">
            <StatusLabel color={StatusLabel.Colors.gray}>
              {StringUtils.capitaliseWord(subscription.paymentType)}
            </StatusLabel>
          </div>
        )}
        <div className="subscription__site-count-container">
          {"Site Count: "}
          {subscription.siteSubscriptions.length}
          {SubscriptionSharedUtils.getSiteSubscriptionDifferentFromContractCount(
            subscription.siteSubscriptions
          ) > 0 && (
            <>
              <GVDSIcon
                Icon={IconCircleFilled}
                className="subscription__joined-bullet"
              />
              <TooltipPersistentOnHover
                triggerClassName="subscription__site-count__deviant-site"
                triggerContent={
                  <>
                    {SubscriptionSharedUtils.getSiteSubscriptionDifferentFromContractCount(
                      subscription.siteSubscriptions
                    )}
                    <GVDSIcon Icon={IconGitBranch} className="ms-2" />
                  </>
                }
                tooltipContent="No. of sites which start period, end period, or subscribed features is not following this contract subscription's terms."
              />
            </>
          )}
        </div>
        {actionButtons ?? null}
      </header>
      <article>
        <Row>
          <Col lg={6}>
            <table className="subscription-content-display">
              <colgroup>
                <col className="label-column" />
                <col className="value-column" />
              </colgroup>
              <tbody>
                <tr>
                  <td>Start Date</td>
                  <td>
                    {DateTimeUtils.formatLocalDate(subscription.startPeriod)}
                  </td>
                </tr>
                <tr>
                  <td>Reminder Date</td>
                  <td>
                    {DateTimeUtils.formatLocalDate(subscription.reminderDate)}
                  </td>
                </tr>
                <tr>
                  <td>End Date</td>
                  <td>
                    {DateTimeUtils.formatLocalDate(subscription.endPeriod)}
                  </td>
                </tr>
                <tr>
                  <td>Last Updated</td>
                  <td>
                    <div className="subscription__joined-bullet-container">
                      <UserAvatar
                        fullName={subscription.updatedBy}
                        userEmail=""
                      />
                      <span className="ms-1">{subscription.updatedBy}</span>
                      <GVDSIcon
                        Icon={IconCircleFilled}
                        className="subscription__joined-bullet"
                      />
                      {DateTimeUtils.formatLocalDate(subscription.updatedOn)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Billing Admin</td>
                  <td>
                    {SubscriptionSharedUtils.getBillingAdminDisplay(
                      billingAdmin
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={6}>
            <table className="subscription-content-display">
              <colgroup>
                <col className="label-column" />
                <col className="value-column" />
              </colgroup>
              <tbody>
                <tr>
                  <td>Price</td>
                  <td>
                    {SubscriptionSharedUtils.getPriceDisplay(
                      subscription,
                      subscription.getProratedPriceAmount()
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Billing Cycle</td>
                  <td>
                    {StringUtils.capitaliseWord(
                      subscription.billingCycle ?? ""
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Auto-Renew</td>
                  <td>{subscription.autoRenew ? "On" : "Off"}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>
                    {SubscriptionSharedUtils.getDiscountDisplay(
                      subscription.discountCode
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Account Manager</td>
                  <td>
                    {SubscriptionSharedUtils.getAccountManagerDisplay(
                      accountManager
                    )}
                  </td>
                </tr>
                <tr className="comment-section">
                  <td>Comment</td>
                  <td>
                    {SubscriptionSharedUtils.getCommentDisplay(
                      subscription.comments
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </article>
    </section>
  );
};

const ViewAllContractSubscriptions = ({
  contractId,
  accountManager,
  billingAdmin,
}) => {
  const permissionCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);
  const history = useHistory();

  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [pastSubscriptions, setPastSubscriptions] = useState([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(true);
  const [allFeatures, setAllFeatures] = useState([]);
  const [packagePlans, setPackagePlans] = useState([]);

  const [showDeleteSubscriptionPrompt, setShowDeleteSubscriptionPrompt] =
    useState(false);
  const [deletedSubscriptionId, setDeletedSubscriptionId] = useState();

  const isAllowedToCreateSubscription =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.PORTAL_ADMIN];

  const goToCreateSubscription = () => {
    history.push(getAddSubscriptionToContractPath(contractId));
  };

  const goToEditSubscription = (subscriptionId) => {
    history.push(getViewContractSubscriptionPath(contractId, subscriptionId));
  };

  const goToDuplicateSubscription = (subscriptionId) => {
    history.push(
      getDuplicateContractSubscriptionPath(contractId, subscriptionId)
    );
  };

  useEffect(() => {
    loadSubscriptionOptions();
    loadAllSubscriptions();
  }, []);

  const loadSubscriptionOptions = async () => {
    try {
      const options = await SubscriptionService.getSubscriptionOptions(
        RESOURCES.CONTRACT,
        contractId
      );
      if (options) {
        setAllFeatures(options["features"]);
        setPackagePlans(options["ordered_package_plans"]);
      }
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to load subscription options.</span>
      );
    }
  };

  const loadAllSubscriptions = async () => {
    setIsLoadingSubscriptions(true);
    try {
      const subscriptions = await SubscriptionService.getContractSubscriptions(
        contractId
      );
      setActiveSubscriptions(
        SubscriptionSharedUtils.getActiveSubscriptions(subscriptions)
      );
      setPastSubscriptions(
        SubscriptionSharedUtils.getPastSubscriptions(subscriptions)
      );
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to load subscriptions. Please try again.</span>
      );
    } finally {
      setIsLoadingSubscriptions(false);
    }
  };

  const getActionButtons = (subscription) => {
    return (
      <Dropdown className="ms-auto">
        <Dropdown.Toggle variant="link" className="primary">
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu className="subscription__action-dropdown" align="end">
          {subscription.stripeInvoiceLinks &&
          subscription.stripeInvoiceLinks.length > 0 ? (
            subscription.stripeInvoiceLinks.length === 1 ? (
              <Dropdown.Item
                onClick={() => {
                  window.open(
                    subscription.stripeInvoiceLinks[0],
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <GVDSIcon Icon={IconExternalLink} />
                View Invoice in Stripe
              </Dropdown.Item>
            ) : (
              subscription.stripeInvoiceLinks.map((link, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    window.open(link, "_blank", "noopener,noreferrer");
                  }}
                >
                  <GVDSIcon Icon={IconExternalLink} />
                  View Invoice {index + 1} in Stripe
                </Dropdown.Item>
              ))
            )
          ) : (
            <Dropdown.Item disabled={true} onClick={() => {}}>
              <GVDSIcon Icon={IconExternalLink} />
              View Invoice in Stripe
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => goToEditSubscription(subscription.id)}>
            <GVDSIcon Icon={IconEdit} />
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => goToDuplicateSubscription(subscription.id)}
          >
            <GVDSIcon Icon={IconCopy} />
            Copy
          </Dropdown.Item>
          <Dropdown.Item
            className="color-red"
            onClick={() => {
              setDeletedSubscriptionId(subscription.id);
              setShowDeleteSubscriptionPrompt(true);
            }}
          >
            <GVDSIcon Icon={IconTrash} />
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  let content;
  content = (
    <>
      <div className="mb-5">
        <div className="d-flex flex-row">
          <h2>
            Ongoing Subscriptions
            {activeSubscriptions.length > 0 && (
              <span> ({activeSubscriptions.length})</span>
            )}
          </h2>
          <div className="ms-auto">
            {isAllowedToCreateSubscription && (
              <GVDSButton
                variant={buttonVariant.primary}
                className="create-subscription-button"
                onClick={goToCreateSubscription}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text="Add Subscription"
              />
            )}
          </div>
        </div>
        {isLoadingSubscriptions ? (
          <LoadingSpinner />
        ) : activeSubscriptions.length === 0 ? (
          SubscriptionSharedUtils.getEmptyOngoingSubscription()
        ) : (
          activeSubscriptions.map((subscription) =>
            getSubscriptionDisplay(
              subscription,
              allFeatures,
              getActionButtons(subscription),
              accountManager,
              billingAdmin,
              packagePlans
            )
          )
        )}
      </div>
      <div>
        <h2>
          Past Subscriptions
          {pastSubscriptions.length > 0 && (
            <span> ({pastSubscriptions.length})</span>
          )}
        </h2>
        {isLoadingSubscriptions ? (
          <LoadingSpinner />
        ) : pastSubscriptions.length === 0 ? (
          SubscriptionSharedUtils.getEmptyPastSubscription()
        ) : (
          pastSubscriptions.map((subscription) =>
            getSubscriptionDisplay(
              subscription,
              allFeatures,
              getActionButtons(subscription),
              accountManager,
              billingAdmin,
              packagePlans
            )
          )
        )}
      </div>
      <DeleteContractSubscriptionPrompt
        showPrompt={showDeleteSubscriptionPrompt}
        onClosePrompt={() => setShowDeleteSubscriptionPrompt(false)}
        contractId={contractId}
        subscriptionId={deletedSubscriptionId}
        onSuccess={loadAllSubscriptions}
      />
    </>
  );

  return content;
};

export default ViewAllContractSubscriptions;
