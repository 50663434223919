import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Editor } from "react-draft-wysiwyg";
import { RTFUtils } from "../../../services/UtilsService";
import SurveyMessagePreviewDisplay from "./SurveyMessagePreviewDisplay";
import GVFormGroup from "../../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormTextArea from "../../../gvds-components/Forms/GVDSFormTextArea";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleDatePicker from "../../../gvds-components/Forms/GVDSFormSingleDatePicker";
import GVDSTag from "../../../gvds-components/common/GVDSTag";

const SurveyDetailForm = ({
  surveyModel,
  onChange,
  isNameValidated,
  isSaving,
  showSurveyValidationError,
  sendTestEmail,
  isAllowedToManageSurvey,
}) => {
  const [showInstructionsPreview, setShowInstructionsPreview] = useState(false);
  const closeInstructionsPreview = () => {
    setShowInstructionsPreview(false);
  };

  const [showThankYouMessagePreview, setShowThankYouMessagePreview] =
    useState(false);
  const closeThankYouMessagePreview = () => {
    setShowThankYouMessagePreview(false);
  };

  const isFormDisabled = surveyModel.isNotDraft();

  return (
    <div className="pt-3">
      <Row>
        <Col lg={9}>
          <GVFormGroup>
            <Form.Label>Survey Name</Form.Label>
            <GVDSFormField
              name="survey-name"
              placeholder="Enter survey name"
              value={surveyModel.name}
              onInput={(value) => {
                surveyModel.name = value;
                onChange();
              }}
              statusMetadata={
                (isNameValidated || showSurveyValidationError) &&
                surveyModel.isNameEmpty()
                  ? FormFieldStatusMetadata.getError("Please add a name.")
                  : FormFieldStatusMetadata.getDefault()
              }
              disabled={isFormDisabled || !isAllowedToManageSurvey}
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Description</Form.Label>
            <GVDSFormTextArea
              rows={4}
              name="survey-description"
              placeholder="Give a description to mention the purpose of the survey."
              value={surveyModel.description}
              onInput={(value) => {
                surveyModel.description = value;
                onChange();
              }}
              statusMetadata={
                showSurveyValidationError && surveyModel.isDescriptionEmpty()
                  ? FormFieldStatusMetadata.getError(
                      "Please add a description."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
              disabled={isFormDisabled || !isAllowedToManageSurvey}
            />
          </GVFormGroup>
          <GVFormGroup>
            <Form.Label>Deadline</Form.Label>
            <GVDSFormSingleDatePicker
              disabled={isFormDisabled || !isAllowedToManageSurvey}
              selected={surveyModel.deadline}
              className="survey-deadline"
              onChange={(date) => {
                surveyModel.deadline = date;
                onChange();
              }}
              dateFormat="dd MMM yyyy"
              minDate={new Date()}
              statusMetadata={
                showSurveyValidationError && !surveyModel.deadline
                  ? FormFieldStatusMetadata.getError(
                      "Please select a deadline."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
        </Col>
      </Row>
      <GVFormGroup>
        <div className="d-flex">
          <div>
            <Form.Label>Invitational Email</Form.Label>
            <div className="form-label-description">
              When this survey is launched, the invitational email will be sent
              to site admins and site contributors within this portfolio, as
              well as the portfolio’s admins.
            </div>
          </div>
          {isAllowedToManageSurvey && (
            <>
              <Spacer />
              <GVDSButton
                variant={buttonVariant.secondary}
                disabled={isSaving}
                onClick={sendTestEmail}
                text={!isSaving ? "Send Test Email to Me" : "Sending..."}
              />
            </>
          )}
        </div>
        <Editor
          editorState={surveyModel.invitationalEmail}
          onEditorStateChange={(editorState) => {
            surveyModel.invitationalEmail = editorState;
            onChange();
          }}
          readOnly={isFormDisabled || !isAllowedToManageSurvey}
          wrapperClassName="rtf-wrapper"
          editorClassName={
            isFormDisabled || !isAllowedToManageSurvey
              ? "rtf-editor disabled"
              : "rtf-editor"
          }
          toolbar={{
            options: [
              "blockType",
              "inline",
              "list",
              "link",
              "image",
              "remove",
              "history",
            ],
          }}
        />
        {showSurveyValidationError &&
          surveyModel.isInvitationalEmailEmpty() && (
            <GVDSFormErrorMessage
              status={FormFieldState.error}
              errorMsg="Please add invitation message for the survey."
            />
          )}
      </GVFormGroup>
      <GVFormGroup>
        <div className="d-flex">
          <div>
            <Form.Label>Instructions</Form.Label>
            <div className="form-label-description">
              Users will see this when they first arrive at a survey.
            </div>
          </div>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.secondary}
            onClick={() => setShowInstructionsPreview(true)}
            text="Preview"
          />
        </div>
        <Editor
          editorState={surveyModel.instructions}
          onEditorStateChange={(editorState) => {
            surveyModel.instructions = editorState;
            onChange();
          }}
          readOnly={isFormDisabled || !isAllowedToManageSurvey}
          wrapperClassName="rtf-wrapper"
          editorClassName={
            isFormDisabled || !isAllowedToManageSurvey
              ? "rtf-editor disabled"
              : "rtf-editor"
          }
          toolbar={{
            options: [
              "blockType",
              "inline",
              "list",
              "link",
              "image",
              "remove",
              "history",
            ],
          }}
        />
        {showSurveyValidationError && surveyModel.isInstructionsEmpty() && (
          <GVDSFormErrorMessage
            status={FormFieldState.error}
            errorMsg="Please add instructions for the survey."
          />
        )}
      </GVFormGroup>
      <GVFormGroup>
        <div className="d-flex">
          <div>
            <Form.Label>Thank You Message</Form.Label>
            <div className="form-label-description">
              Users will see this after they submit their survey responses.
            </div>
          </div>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.secondary}
            onClick={() => setShowThankYouMessagePreview(true)}
            text="Preview"
          />
        </div>
        <Editor
          editorState={surveyModel.thankYouMessage}
          onEditorStateChange={(editorState) => {
            surveyModel.thankYouMessage = editorState;
            onChange();
          }}
          readOnly={isFormDisabled || !isAllowedToManageSurvey}
          wrapperClassName="rtf-wrapper"
          editorClassName={
            isFormDisabled || !isAllowedToManageSurvey
              ? "rtf-editor disabled"
              : "rtf-editor"
          }
          toolbar={{
            options: [
              "blockType",
              "inline",
              "list",
              "link",
              "image",
              "remove",
              "history",
            ],
          }}
        />
        {showSurveyValidationError && surveyModel.isThankYouMessageEmpty() && (
          <GVDSFormErrorMessage
            status={FormFieldState.error}
            errorMsg="Please add a thank you note to the survey participant."
          />
        )}
      </GVFormGroup>

      <GVDSModal
        title={
          <GVDSTag variant={GVDSTag.Variants.system}>
            Preview Instructions
          </GVDSTag>
        }
        size={GVDSModal.Size.medium}
        show={showInstructionsPreview}
        onHide={closeInstructionsPreview}
      >
        <GVDSModal.Body>
          <SurveyMessagePreviewDisplay
            content={RTFUtils.convertEditorStateToHTML(
              surveyModel.instructions
            )}
          />
        </GVDSModal.Body>
      </GVDSModal>

      <GVDSModal
        title={
          <GVDSTag variant={GVDSTag.Variants.system}>
            Preview Thank You Message
          </GVDSTag>
        }
        show={showThankYouMessagePreview}
        onHide={closeThankYouMessagePreview}
      >
        <GVDSModal.Body>
          <SurveyMessagePreviewDisplay
            content={RTFUtils.convertEditorStateToHTML(
              surveyModel.thankYouMessage
            )}
          />
        </GVDSModal.Body>
      </GVDSModal>
    </div>
  );
};

export default SurveyDetailForm;
