import React, { useContext, useEffect, useState } from "react";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import MarketPurchaseOffsetsService from "../../../../services/MarketPurchaseService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import {
  DateTimeUtils,
  NumberService,
  UtilsService,
} from "../../../../services/UtilsService";
import uniq from "lodash/uniq";
import { PERMISSIONS, RESOURCES, UNIT_ID } from "../../../../config/constants";
import PermissionsContext from "../../../../context/PermissionsContext";
import InputMarketPurchaseRecordModal from "./InputMarketPurchaseRecordModal";
import DeleteMarketPurchaseRecordPrompt from "./DeleteMarketPurchaseRecordPrompt";
import Form from "react-bootstrap/Form";
import intersection from "lodash/intersection";
import CommentTooltip from "../../../common/Tooltip/CommentTooltip";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSFormStartEndMonthPicker from "../../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSTable, {
  ACTIONS_DATAKEY,
  MULTI_SELECT_DATAKEY,
} from "../../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableBulkActionBar from "../../../../gvds-components/Table/Controls/GVDSTableBulkActionBar";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const ViewAllMarketPurchaseOffsetRecords = ({
  lastUpdate,
  onRecordsUpdate,
  trackers,
  onTrackerRecordUpdate,
  startPeriod,
  setStartPeriod,
  endPeriod,
  setEndPeriod,
  isMultipleDeleteMode,
  setMultipleDeleteMode,
  setTotalDataLengthCanBeDeleted,
  triggerDeleteAllTimestamp,
  triggerDownloadExcelTimestamp,
  setIsDownloadExcelLoading,
}) => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);
  const [allRecords, setAllRecords] = useState([]);

  const [currentlyEditedRecordModel, setCurrentlyEditedRecordModel] =
    useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => {
    setCurrentlyEditedRecordModel(null);
    setShowEditModal(false);
  };

  const [currentlyToBeDeletedRecordIds, setCurrentlyToBeDeletedRecordIds] =
    useState([]);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const handleClosePromptDeleteModal = () => {
    setCurrentlyToBeDeletedRecordIds([]);
    setShowPromptDeleteModal(false);
  };
  const toggleRecordModelSelection = (recordId) => {
    setCurrentlyToBeDeletedRecordIds(
      UtilsService.toggleItem(currentlyToBeDeletedRecordIds, recordId)
    );
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allRecords, null);

  useEffect(() => {
    setTotalDataLengthCanBeDeleted(totalDataLength);
  }, [totalDataLength]);

  useEffect(() => {
    if (triggerDeleteAllTimestamp !== null) {
      promptDeleteRecord(filteredSortedData.map((d) => d.id));
    }
  }, [triggerDeleteAllTimestamp]);

  useEffect(() => {
    if (triggerDownloadExcelTimestamp !== null) {
      onDownloadMarketPurchaseOffsetRecordExcel();
    }
  }, [triggerDownloadExcelTimestamp]);

  const getFilterIdsFromFilterKeys = () => {
    const facilityIds = !_.isEmpty(filterKeys.facilityName)
      ? UtilsService.convertObjectNamesToObjectIds(
          filterKeys.facilityName,
          facilityOptions
        )
      : [];
    const trackerTypeIds = !_.isEmpty(filterKeys.trackerType)
      ? UtilsService.convertObjectNamesToObjectIds(
          filterKeys.trackerType,
          trackerTypeOptions
        )
      : [];
    const trackerIds = !_.isEmpty(filterKeys.trackerName)
      ? UtilsService.convertObjectNamesToObjectIds(
          filterKeys.trackerName,
          trackerOptions
        )
      : [];

    return { facilityIds, trackerTypeIds, trackerIds };
  };

  const onDownloadMarketPurchaseOffsetRecordExcel = () => {
    const currentInventory = userInventory.selectedInventory.get;
    const { facilityIds, trackerTypeIds, trackerIds } =
      getFilterIdsFromFilterKeys();
    setIsDownloadExcelLoading(true);
    MarketPurchaseOffsetsService.downloadMarketPurchaseOffsetRecordExcel(
      currentInventory.type,
      currentInventory.id,
      startPeriod,
      endPeriod,
      facilityIds,
      trackerTypeIds,
      trackerIds
    )
      .catch(() => {
        toastContext.addFailToast(<span>Failed to download excel.</span>);
      })
      .finally(() => setIsDownloadExcelLoading(false));
  };

  const loadDataRecords = () => {
    if (selectedInventory.id) {
      setIsLoading(true);
      MarketPurchaseOffsetsService.getAllRecords(
        selectedInventory.type,
        selectedInventory.id,
        startPeriod,
        endPeriod
      )
        .then((data) => {
          setIsLoading(false);
          setAllRecords(data);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load market purchase records.</span>
          );
        });
    }
  };

  useEffect(() => {
    loadDataRecords();
  }, [selectedInventory, startPeriod, endPeriod, lastUpdate]);

  let trackerTypeOptions = [];
  let trackerOptions = [];
  let facilityOptions = [];

  allRecords.forEach((record) => {
    const trackerTypeObj = {
      id: record.trackerTypeId,
      name: record.trackerType,
    };
    UtilsService.addIfUniqueToArray(trackerTypeOptions, trackerTypeObj);

    const trackerObj = {
      id: record.trackerId,
      name: record.trackerName,
    };
    UtilsService.addIfUniqueToArray(trackerOptions, trackerObj);

    const facilityObj = {
      id: record.facilityId,
      name: record.facilityName,
    };
    UtilsService.addIfUniqueToArray(facilityOptions, facilityObj);
  });

  const onPageRecordsSelect = () => {
    const recordIds = currentPageData.map((r) => r.id);
    const intersectIds = intersection(currentlyToBeDeletedRecordIds, recordIds);
    if (intersectIds.length === recordIds.length) {
      setCurrentlyToBeDeletedRecordIds(
        currentlyToBeDeletedRecordIds.filter(
          (rId) => recordIds.indexOf(rId) < 0
        )
      );
    } else {
      setCurrentlyToBeDeletedRecordIds(
        uniq([...currentlyToBeDeletedRecordIds, ...recordIds])
      );
    }
  };

  const showEditRecord = (recordModel) => {
    setCurrentlyEditedRecordModel(recordModel);
    setShowEditModal(true);
  };

  const promptDeleteRecord = (recordIds) => {
    setCurrentlyToBeDeletedRecordIds(recordIds);
    setShowPromptDeleteModal(true);
  };

  const deleteCurrentlySelectedRecordModel = () => {
    const selectedInventory = userInventory.selectedInventory.get;
    MarketPurchaseOffsetsService.deleteDataRecords(
      selectedInventory.type,
      selectedInventory.id,
      currentlyToBeDeletedRecordIds
    ).then(() => {
      const noOfDeletedModels = currentlyToBeDeletedRecordIds.length;
      if (noOfDeletedModels === 1) {
        toastContext.addSuccessToast(
          <span>Data record deleted successfully</span>
        );
      } else {
        toastContext.addSuccessToast(
          <span>{noOfDeletedModels} data records deleted successfully</span>
        );
      }

      if (isMultipleDeleteMode) {
        setMultipleDeleteMode(false);
      }
      setCurrentlyToBeDeletedRecordIds([]);
      loadDataRecords();
      onRecordsUpdate();
      handleClosePromptDeleteModal();
    });
  };

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    let columns = [
      {
        header: t("data-management.environmental.shared.type"),
        dataKey: "trackerType",
        sortable: true,
      },
      {
        header: t(
          "data-management.environmental.market-purchase.tracker-list.table-header.tracker"
        ),
        dataKey: "trackerName",
        sortable: true,
      },
      {
        header: t("shared-input-label.period-from"),
        dataKey: "periodFrom",
        sortable: true,
        renderer: (d) => DateTimeUtils.formatUTCDate(d.periodFrom),
      },
      {
        header: t("shared-input-label.period-to"),
        dataKey: "periodTo",
        sortable: true,
        renderer: (d) => DateTimeUtils.formatUTCDate(d.periodTo),
      },
      {
        header: `${t(
          "data-management.environmental.market-purchase.single-input.option-bundled"
        )} / ${t(
          "data-management.environmental.market-purchase.single-input.option-unbundled"
        )}`,
        dataKey: "isBundled",
        renderer: (d) => (d.isBundled ? "Bundled" : "Unbundled"),
      },
      {
        header: t(
          "data-management.environmental.market-purchase.records.table-header-amount"
        ),
        dataKey: "amount",
        sortable: true,
        renderer: (d) => NumberService.format(d["amount"], 2, 2),
      },
      { header: t("shared-input-label.unit"), dataKey: "unitName" },
      {
        header: t("shared-input-label.comments"),
        dataKey: "comments",
        sortable: true,
        renderer: (d) =>
          d["comments"] ? <CommentTooltip comment={d["comments"]} /> : null,
      },
      {
        header: t("shared-input-label.actions"),
        dataKey: ACTIONS_DATAKEY,
        renderer: (d) => {
          return (
            <>
              {!permissionCtx.isLoadingPermissions &&
                permissionCtx.permissions[
                  PERMISSIONS.MARKET_PURCHASE_OFFSETS_RECORD_EDIT
                ] && (
                  <GVDSIconButton
                    className="edit-eac-record-button"
                    variant={iconButtonVariant.tertiary}
                    icon={<GVDSIcon Icon={IconEdit} />}
                    tooltipText={t("shared.edit")}
                    onClick={(e) => {
                      e.preventDefault();
                      showEditRecord(d);
                    }}
                  />
                )}
              {!permissionCtx.isLoadingPermissions &&
                permissionCtx.permissions[
                  PERMISSIONS.MARKET_PURCHASE_OFFSETS_RECORD_DELETE
                ] && (
                  <GVDSIconButton
                    className="delete-eac-record-button"
                    variant={iconButtonVariant.destructive}
                    icon={<GVDSIcon Icon={IconTrash} />}
                    tooltipText={t("shared.delete")}
                    onClick={() => {
                      promptDeleteRecord([d.id]);
                    }}
                  />
                )}
            </>
          );
        },
      },
    ];
    if (isMultipleDeleteMode) {
      columns.unshift({
        header: "",
        dataKey: MULTI_SELECT_DATAKEY,
        renderer: (d) => {
          return (
            <div>
              <Form.Check
                checked={currentlyToBeDeletedRecordIds.indexOf(d.id) >= 0}
                type="checkbox"
                key={d.id}
                id={d.id}
                label=""
                onChange={() => toggleRecordModelSelection(d.id)}
              />
            </div>
          );
        },
      });
    }
    if (userInventory.selectedInventory.get.type === RESOURCES.SITE) {
      columns.splice(0, 0, {
        header: t("shared.facility"),
        dataKey: "facilityName",
        sortable: true,
      });
    }
    const pageRecordIds = currentPageData.map((r) => r.id);
    const isPageSelected =
      intersection(pageRecordIds, currentlyToBeDeletedRecordIds).length ===
      pageRecordIds.length;
    content = (
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        isPageSelected={isPageSelected}
        onPageSelect={onPageRecordsSelect}
        noContent={
          <div className="table__no_content">
            <p>{t("data-management.environmental.all-records.no-record")}</p>
          </div>
        }
        viewControl={
          <>
            <GVDSTableCtrlContainer>
              <GVDSTableCtrlMultiSelect
                options={uniq(allRecords.map((r) => r.trackerType))}
                prefix="Type"
                value={filterKeys.trackerType}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, trackerType: filterKs })
                }
              />
              {selectedInventory?.type === RESOURCES.SITE && (
                <GVDSTableCtrlMultiSelect
                  options={uniq(allRecords.map((r) => r.facilityName)).sort()}
                  prefix="Facility"
                  value={filterKeys.facilityName}
                  onChange={(filterKs) =>
                    setFilterKeys({
                      ...filterKeys,
                      facilityName: filterKs,
                    })
                  }
                />
              )}
              <GVDSTableCtrlMultiSelect
                options={uniq(allRecords.map((r) => r.trackerName))}
                prefix="Tracker"
                value={filterKeys.trackerName}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, trackerName: filterKs })
                }
              />
              <div className="date-range-container">
                <GVDSFormStartEndMonthPicker
                  startMonth={startPeriod}
                  endMonth={endPeriod}
                  onChange={(s, e) => {
                    if (s !== startPeriod) {
                      setStartPeriod(s);
                    }
                    if (e !== endPeriod) {
                      setEndPeriod(e);
                    }
                  }}
                />
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            {isMultipleDeleteMode && (
              <GVDSTableBulkActionBar
                selectedRowsCount={currentlyToBeDeletedRecordIds.length}
                actionButtons={
                  <>
                    <GVDSButton
                      variant={buttonVariant.destructive_tertiary}
                      disabled={currentlyToBeDeletedRecordIds.length === 0}
                      onClick={(e) => {
                        e.preventDefault();
                        promptDeleteRecord(currentlyToBeDeletedRecordIds);
                      }}
                      text={t("shared-modal.footer.delete-selected")}
                    />
                    <GVDSButton
                      variant={buttonVariant.tertiary}
                      onClick={(e) => {
                        e.preventDefault();
                        setMultipleDeleteMode(false);
                        setCurrentlyToBeDeletedRecordIds([]);
                      }}
                      text={t("shared-modal.footer.cancel")}
                    />
                  </>
                }
              />
            )}
          </>
        }
      />
    );
  }

  return (
    <div>
      {content}
      <InputMarketPurchaseRecordModal
        show={showEditModal}
        currentRecord={currentlyEditedRecordModel}
        selectedTracker={{ id: currentlyEditedRecordModel?.trackerId }}
        trackers={trackers}
        unitId={trackers.length > 0 ? trackers[0]["unit_id"] : UNIT_ID.kwh}
        onSave={onTrackerRecordUpdate}
        onClose={handleCloseEditModal}
      />
      <DeleteMarketPurchaseRecordPrompt
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        proceed={deleteCurrentlySelectedRecordModel}
      />
    </div>
  );
};

export default ViewAllMarketPurchaseOffsetRecords;
