import React, { useContext, useEffect, useRef, useState } from "react";
import range from "lodash/range";
import BulkSelectSiteService from "./BulkSelectSiteService";
import ReactDataSheet from "react-datasheet";
import groupBy from "lodash/groupBy";
import BulkSitesInputErrorService from "../../../Site/BulkSitesInputErrorService";
import SubscriptionService from "../../../../services/SubscriptionService";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import clone from "lodash/clone";
import { getRedirectURLWithCurrentParam } from "../../../common/QueryHandler";
import {
  CREATE_SITE,
  SYSTEM_TOOLBOX_ONBOARDING_TOOL,
} from "../../../../config/ROUTES_NAME";
import { Link, useLocation } from "react-router-dom";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";

const INITIAL_GRID_SIZE = 3;

const tableHeaderRenderer = ({ className, children }) => {
  return (
    <div className={className}>
      <table>
        <thead>
          <tr>
            <th className="cell read-only" style={{ width: "80px" }}>
              Row #
            </th>
            <th className="cell read-only">Site name</th>
            <th className="cell read-only" style={{ width: "80px" }}>
              Remove
            </th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const BulkSelectSitesModal = ({
  showModal,
  onCloseModal,
  selectedSiteNames,
  allSites,
  reloadAllSites,
  onSelectSites,
}) => {
  const location = useLocation();
  const toastContext = useContext(ToastContext);

  const bulkSelectSiteService = useRef(new BulkSelectSiteService());
  const bulkInputErrorService = useRef(new BulkSitesInputErrorService());

  const [isLoading, setIsLoading] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(-1);
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    resetGrid();
  }, []);

  useEffect(() => {
    if (rowToDelete >= 0) {
      const newGrid = bulkSelectSiteService.current.removeRow(
        rowToDelete,
        setRowToDelete,
        grid
      );
      setGrid(newGrid);
      setRowToDelete(-1);
    }
  }, [rowToDelete]);

  const closeModal = () => {
    if (!isLoading) {
      onCloseModal();
    }
  };

  const addRow = () => {
    const row = grid.length;
    grid.push(bulkSelectSiteService.current.getNewRow(row, setRowToDelete));
    setGrid(clone(grid));
  };

  const resetGrid = () => {
    const initialGrid = range(INITIAL_GRID_SIZE).map((row) =>
      bulkSelectSiteService.current.getNewRow(row, setRowToDelete)
    );
    bulkInputErrorService.current.resetErrors();
    setGrid(initialGrid);
  };

  const handleChanges = (changes = [], additions = []) => {
    const totalChanges = [...changes, ...additions];
    const changedGrid = grid.map((row) => [...row]);
    const changesByRow = groupBy(totalChanges, (c) => c.row);

    for (const [row, rowChanges] of Object.entries(changesByRow)) {
      const rowData = changedGrid[row]
        ? changedGrid[row]
        : bulkSelectSiteService.current.getNewRow(row, setRowToDelete);
      changedGrid[row] = bulkSelectSiteService.current.updateRow(
        rowData,
        rowChanges
      );
    }
    setGrid(changedGrid);
  };

  const checkForValidation = async () => {
    const siteNames = bulkSelectSiteService.current.getSiteNames(grid);

    if (siteNames.length > 0) {
      const siteNamesWithIndex = siteNames.map((name, index) => {
        return { index: index, name: name };
      });
      const duplicateSiteNameEntry = siteNamesWithIndex.filter(
        (site, index) => siteNames.indexOf(site.name) !== index
      );
      const duplicateSiteInInputPage = siteNamesWithIndex.filter((site) =>
        selectedSiteNames.includes(site.name)
      );

      const duplicateSiteInputErrors = duplicateSiteInInputPage.reduce(
        (obj, site) => {
          obj[site.index] = {
            name: ["This site is already within this contract subscription."],
          };
          return obj;
        },
        {}
      );
      const duplicateSiteNameEntryErrors = duplicateSiteNameEntry.reduce(
        (obj, site) => {
          obj[site.index] = {
            name: ["Multiple entry of the same site name detected."],
          };
          return obj;
        },
        {}
      );

      const noExistingSiteErrors =
        await SubscriptionService.validateBulkSelectSites(siteNames);

      const rowErrors = {
        ...duplicateSiteInputErrors,
        ...duplicateSiteNameEntryErrors,
        ...noExistingSiteErrors,
      };

      const duplicateSiteInputRowNumbers = duplicateSiteInInputPage.map(
        (site) => site.index
      );
      const duplicateSiteNameEntryRowNumbers = duplicateSiteNameEntry.map(
        (site) => site.index
      );
      const noExistingSiteRowNumbers = noExistingSiteErrors
        ? Object.keys(noExistingSiteErrors)
        : [];
      const errorRowNumbers = [
        ...new Set([
          ...duplicateSiteInputRowNumbers,
          ...duplicateSiteNameEntryRowNumbers,
          ...noExistingSiteRowNumbers,
        ]),
      ];

      const errors = {
        dataErrors: {
          rowErrors: rowErrors,
          errorRowNumbers: errorRowNumbers,
        },
      };
      const newGrid = bulkSelectSiteService.current.updateGridWithErrors(
        grid,
        bulkInputErrorService.current.prepareDataErrors(
          errors.dataErrors.rowErrors
        )
      );

      try {
        await reloadAllSites();
      } catch (error) {
        toastContext.addFailToast(
          "Failed to reload site list. Please try again."
        );
      }

      bulkInputErrorService.current.updateErrors(errors);
      setGrid(newGrid);
    } else {
      bulkInputErrorService.current.resetErrors();
      setGrid(clone(grid));
    }
  };

  const selectSites = async () => {
    const siteNames = bulkSelectSiteService.current.getSiteNames(grid);
    setIsLoading(true);

    try {
      const sites = allSites.filter((site) => siteNames.includes(site.name));
      onSelectSites(sites);
      resetGrid();
      onCloseModal();
    } catch (e) {
      toastContext.addFailToast(<span>Failed to select sites.</span>);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = bulkSelectSiteService.current.getSiteColumnLabels();

  return (
    <GVDSModal
      show={showModal}
      onHide={closeModal}
      title="Bulk select sites"
      size={GVDSModal.Size.large}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="contract-subscription__bulk-select-sites__container">
            <div className="contract-subscription__bulk-select-sites__instruction-container">
              <ol className="contract-subscription__bulk-select-sites__instruction-steps">
                <li>
                  Create new site(s) using the{" "}
                  <Link
                    to={getRedirectURLWithCurrentParam(
                      SYSTEM_TOOLBOX_ONBOARDING_TOOL,
                      location
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    onboarding tool
                  </Link>{" "}
                  (multiple) or{" "}
                  <Link
                    to={getRedirectURLWithCurrentParam(CREATE_SITE, location)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    create site form
                  </Link>{" "}
                  (single).
                </li>
                <li>
                  Copy and paste site names into the column below. Ensure that
                  they are an exact match to their site names in the Portal
                  database.
                </li>
                <li>
                  Click "Check for Validation" to verify the site names and
                  address any existing issues, if necessary.
                </li>
                <li>
                  If no issues are found during the validation process, proceed
                  by clicking "Select Sites".
                </li>
              </ol>
              <div>
                Tip: You can conveniently copy and paste the site names from
                existing Excel sheets, such as the onboarding template.
              </div>
            </div>
            {bulkInputErrorService.current.hasDataErrors() && (
              <GVDSBanner
                title={`${bulkInputErrorService.current.dataErrorRows.length} issues detected.`}
                variant={GVDSBanner.Variants.error}
                className="mt-2"
              >
                Please fix the following rows:{" "}
                {bulkInputErrorService.current.dataErrorRows
                  .map((row) => Number(row) + 1)
                  .join(", ")}
              </GVDSBanner>
            )}
            {bulkInputErrorService.current.hasNoDataErrors() && (
              <GVDSBanner
                title="No issues found."
                variant={GVDSBanner.Variants.success}
                className="mt-2"
              >
                Click "Select Sites" to add these sites into your subscription.
              </GVDSBanner>
            )}
            <div className="contract-subscription__bulk-select-sites__table">
              <div className="bulk-input-ctrl__container">
                <div className="bulk-input-ctrl__data-ctrl">
                  <GVDSButton
                    variant={buttonVariant.tertiary}
                    className="link-primary"
                    onClick={addRow}
                    text="Add row"
                  />
                  <GVDSButton
                    variant={buttonVariant.destructive_tertiary}
                    onClick={resetGrid}
                    text="Clear all"
                  />
                  <div className="bulk-input-ctrl__row-count">
                    Row count:{" "}
                    {bulkSelectSiteService.current.getFilledRows(grid)}
                  </div>
                </div>
                <div className="bulk-input-ctrl__action-ctrl">
                  <GVDSButtonWithLoadingAction
                    variant={buttonVariant.secondary}
                    onClickAsyncFunc={checkForValidation}
                    text="Check for Validation"
                  />
                  <GVDSButtonWithLoadingAction
                    variant={buttonVariant.primary}
                    onClickAsyncFunc={selectSites}
                    disabled={bulkInputErrorService.current.disableSubmit()}
                    text="Select Sites"
                  />
                </div>
              </div>
              <div className="contract-subscription__bulk-select-sites__datasheet-table">
                <ReactDataSheet
                  data={grid}
                  sheetRenderer={(props) =>
                    tableHeaderRenderer({ columns, ...props })
                  }
                  valueRenderer={(cell) => cell.value}
                  onCellsChanged={handleChanges}
                />
              </div>
            </div>
          </div>
        )}
      </GVDSModal.Body>
    </GVDSModal>
  );
};

export default BulkSelectSitesModal;
