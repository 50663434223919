import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import OperationalService from "../../../services/OperationalService";
import ToastContext from "../../../context/ToastContext";
import { DEFAULT_OPERATION_METER_NAME } from "../../../config/constants";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { DateTimeUtils } from "../../../services/UtilsService";
import GVFormGroup from "../../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormStartEndDatePicker from "../../../gvds-components/Forms/GVDSFormStartEndDatePicker";
import { IconAlertTriangle } from "@tabler/icons-react";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";

const initialRecord = {
  facility_id: "",
  meter_id: "",
  period_from: null,
  period_to: null,
  value: "",
  comments: "",
};

const isInputFromMeterView = (meter, datasets) => {
  return meter && datasets.length === 1;
};

const InputOperationalDataRecordModal = ({
  show,
  onClose,
  datasets = [],
  meter,
  currentRecord,
  onDoneInput,
}) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);

  const [disableMeterSelect, setDisableMeterSelect] = useState(false);
  const [newRecord, setNewRecord] = useState(initialRecord);
  const [isEditExistingRecord, setIsEditExistingRecord] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [
    isDataErrorAndPossibleErrorChecked,
    setIsDataErrorAndPossibleErrorChecked,
  ] = useState(false);
  const [dataErrors, setDataErrors] = useState({});
  const [possibleErrors, setPossibleErrors] = useState({});

  const getFacilities = () => {
    return _.chain(datasets)
      .map((d) => d.facility)
      .uniqBy((f) => f.id)
      .value();
  };

  const facilities = getFacilities();
  const isOnlyHasOneFacility = facilities.length === 1;

  const resetInputFields = () => {
    if (isInputFromMeterView(meter, datasets)) {
      setDisableMeterSelect(true);

      if (currentRecord) {
        setIsEditExistingRecord(true);
        setNewRecord({
          facility_id: datasets[0].facility.id,
          meter_id: meter.id,
          period_from: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            currentRecord.periodFrom
          ),
          period_to: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            currentRecord.periodTo
          ),
          value: currentRecord.value.toString(),
          comments: currentRecord.comments,
        });
      } else {
        setIsEditExistingRecord(false);
        setNewRecord({
          ...initialRecord,
          meter_id: meter.id,
          facility_id: datasets[0].facility.id,
        });
      }
    } else if (currentRecord) {
      setIsEditExistingRecord(true);
      setDisableMeterSelect(true);

      setNewRecord({
        facility_id: currentRecord.facilityId,
        meter_id: currentRecord.meterId,
        period_from: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          currentRecord.periodFrom
        ),
        period_to: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          currentRecord.periodTo
        ),
        value: currentRecord.value.toString(),
        comments: currentRecord.comments,
      });
    } else {
      setDisableMeterSelect(false);
      setIsEditExistingRecord(false);
      if (isOnlyHasOneFacility) {
        setNewRecord({
          ...initialRecord,
          facility_id: facilities[0].id,
        });
      } else {
        setNewRecord(initialRecord);
      }
    }
    setDataErrors([]);
    setPossibleErrors([]);
    setIsDataErrorAndPossibleErrorChecked(false);
    setIsValidated(false);
  };

  useEffect(() => {
    resetInputFields();
  }, [currentRecord, meter]);

  const getMeters = () => {
    if (!newRecord.facility_id) {
      return [];
    }

    return _.chain(datasets)
      .filter((d) => d.facility.id === newRecord.facility_id)
      .flatMap((d) => d.meters)
      .value();
  };

  const onRecordChange = (fieldName, fieldValue) => {
    setNewRecord({ ...newRecord, [fieldName]: fieldValue });
  };

  const isFacilityValid = () => {
    return newRecord.facility_id && newRecord.facility_id.length > 0;
  };

  const isTypeValid = () => {
    // currently operation only have 1 meter for each type, hence checking the meter is checking the type
    return newRecord.meter_id && newRecord.meter_id.length > 0;
  };

  const isStartDateValid = () => {
    return newRecord.period_from !== null;
  };

  const isEndDateValid = () => {
    return newRecord.period_to !== null;
  };

  const isEndDateEarlierThanStartDate = () => {
    return (
      newRecord.period_from &&
      newRecord.period_to &&
      newRecord.period_to < newRecord.period_from
    );
  };

  const isValueValid = () => {
    return newRecord.value && newRecord.value.length > 0;
  };

  const isInputValid = () => {
    return (
      isFacilityValid() &&
      isTypeValid() &&
      isStartDateValid() &&
      isEndDateValid() &&
      !isEndDateEarlierThanStartDate() &&
      isValueValid()
    );
  };

  const getDateErrorMessages = () => {
    if (!isValidated) {
      return null;
    }

    const periodFromErrors = dataErrors["period_from"];
    const periodToErrors = dataErrors["period_to"];
    const sharedErrors = _.intersection(periodFromErrors, periodToErrors);

    return (
      <Row className="mb-3" style={{ marginTop: "-1.5em" }}>
        <Col>
          {isEndDateEarlierThanStartDate() && (
            <div className="manual-invalid-feedback">
              “Period to” date cannot be earlier than “Period from” date.
            </div>
          )}
          {sharedErrors.map((e) => (
            <div className="manual-invalid-feedback">{e}</div>
          ))}
        </Col>
      </Row>
    );
  };

  const saveDataRecord = async (event) => {
    event.preventDefault();
    setIsValidated(true);

    if (isInputValid()) {
      const selectedInventory = userInventory.selectedInventory.get;
      try {
        setIsSaving(true);
        const errors = await OperationalService.validateSingleDataRecord(
          selectedInventory.type,
          selectedInventory.id,
          newRecord.facility_id,
          newRecord.meter_id,
          newRecord.period_from,
          newRecord.period_to,
          newRecord.value,
          newRecord.comments,
          currentRecord ? currentRecord.id : null
        );

        if (
          !_.isEmpty(errors.dataErrors) ||
          !_.isEmpty(errors.possibleErrors)
        ) {
          setDataErrors(errors.dataErrors);
          setPossibleErrors(errors.possibleErrors);
          setIsDataErrorAndPossibleErrorChecked(true);
          setIsSaving(false);
        } else {
          await saveDataRecordWithoutValidation(event);
        }
      } catch (error) {
        toastContext.addFailToast(<span>Failed to input data record.</span>);
        setIsSaving(false);
      }
    }
  };

  const saveDataRecordWithoutValidation = async (event) => {
    event.preventDefault();
    const selectedInventory = userInventory.selectedInventory.get;
    try {
      setIsValidated(true);
      setIsSaving(true);
      if (isEditExistingRecord) {
        await OperationalService.editDataRecord(
          selectedInventory.type,
          selectedInventory.id,
          currentRecord.id,
          newRecord
        );
        toastContext.addSuccessToast(
          <span>Data record edited successfully</span>
        );
      } else {
        await OperationalService.createDataRecord(
          selectedInventory.type,
          selectedInventory.id,
          newRecord
        );
        toastContext.addSuccessToast(
          <span>Data record added successfully</span>
        );
      }
      setIsSaving(false);
      resetInputFields();
      if (onDoneInput) {
        onDoneInput();
      }
      onClose();
    } catch (error) {
      toastContext.addFailToast(<span>Failed to input data record.</span>);
      setIsSaving(false);
    }
  };

  const onCloseDialog = () => {
    if (!isSaving) {
      onClose();
    }
  };

  if (!datasets || datasets.length === 0) {
    return (
      <GVDSModal
        title="Input Operational Data Record"
        size={GVDSModal.Size.small}
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <GVDSModal.Body>
          <div>There are currently no meters to track data.</div>
          <br />
          <div>Create a meter by going to Setup at the top-right corner.</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => {
              onClose();
            }}
            text="Close"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    );
  }

  const getSaveButton = () => {
    const onlyPossibleErrors =
      isDataErrorAndPossibleErrorChecked &&
      _.isEmpty(dataErrors) &&
      !_.isEmpty(possibleErrors);

    if (onlyPossibleErrors) {
      return (
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          onClickAsyncFunc={saveDataRecordWithoutValidation}
          text="Save anyway"
        />
      );
    } else {
      return (
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.primary}
          onClickAsyncFunc={saveDataRecord}
          text="Save"
        />
      );
    }
  };

  const getFacilityOptionByValue = (optionValue) => {
    const facilities = getFacilities();
    if (facilities) {
      const option = facilities.find((option) => option.id === optionValue);
      return option ? { value: option.id, label: option.name } : null;
    } else {
      return null;
    }
  };

  const getMeterOptionByValue = (optionValue) => {
    const meters = getMeters();
    if (meters) {
      const option = meters.find((option) => option.id === optionValue);
      return option
        ? { value: option.id, label: option["operation_type"].name }
        : null;
    } else {
      return null;
    }
  };

  return (
    <GVDSModal
      title={`${
        isEditExistingRecord ? "Edit" : "Input"
      } Operational Data Record`}
      size={GVDSModal.Size.medium}
      show={show}
      onHide={() => {
        onCloseDialog();
      }}
    >
      <GVDSModal.Body>
        <Row>
          <Col sm={6}>
            <GVFormGroup controlId="selectFacility">
              <Form.Label>Facility</Form.Label>
              <GVDSFormSingleSelect
                className="select__facility"
                placeholder="Select Facility"
                value={
                  newRecord["facility_id"]
                    ? getFacilityOptionByValue(newRecord["facility_id"])
                    : null
                }
                options={
                  currentRecord
                    ? [
                        {
                          value: currentRecord.facilityId,
                          label: currentRecord.facilityName,
                        },
                      ]
                    : getFacilities().map((facility) => {
                        return { value: facility.id, label: facility.name };
                      })
                }
                onSelect={(selectedOption) =>
                  onRecordChange("facility_id", selectedOption.value)
                }
                disabled={
                  isOnlyHasOneFacility || disableMeterSelect || isSaving
                }
                statusMetadata={
                  isValidated && !isFacilityValid()
                    ? FormFieldStatusMetadata.getError(
                        "Please select a facility."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <GVFormGroup controlId="selectMeter">
              <Form.Label>Type</Form.Label>
              <GVDSFormSingleSelect
                className="select__meter"
                placeholder="Select Type"
                value={
                  newRecord["meter_id"]
                    ? getMeterOptionByValue(newRecord["meter_id"])
                    : null
                }
                options={
                  currentRecord
                    ? currentRecord.meterType
                      ? [
                          {
                            value: currentRecord.meterType,
                            label: currentRecord.meterType,
                          },
                        ]
                      : [
                          {
                            value: meter["operation_type"].name,
                            label: meter["operation_type"].name,
                          },
                        ]
                    : getMeters().map((meter) => {
                        return {
                          value: meter.id,
                          label: meter["operation_type"].name,
                        };
                      })
                }
                onSelect={(selectedOption) =>
                  onRecordChange("meter_id", selectedOption.value)
                }
                disabled={disableMeterSelect || isSaving}
                statusMetadata={
                  isValidated && !isTypeValid()
                    ? FormFieldStatusMetadata.getError("Please select a type.")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <GVFormGroup>
              <Form.Label>Meter</Form.Label>
              <GVDSFormField
                value={
                  newRecord["meter_id"]
                    ? DEFAULT_OPERATION_METER_NAME
                    : DEFAULT_OPERATION_METER_NAME
                }
                disabled
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <GVFormGroup controlId="selectStartEndDate">
              <GVDSFormStartEndDatePicker
                startDateLabel={<Form.Label>Period From</Form.Label>}
                endDateLabel={<Form.Label>Period To</Form.Label>}
                className="ops-record"
                startDate={newRecord["period_from"]}
                endDate={newRecord["period_to"]}
                onChange={(startDate, endDate) => {
                  if (startDate !== newRecord["period_from"]) {
                    onRecordChange("period_from", startDate);
                  }
                  if (endDate !== newRecord["period_to"]) {
                    onRecordChange("period_to", endDate);
                  }
                }}
                dateFormat="dd MMM yyyy"
                maxStartDate={new Date()}
                maxEndDate={new Date()}
                statusMetadata={
                  isValidated && (!isStartDateValid() || !isEndDateValid())
                    ? FormFieldStatusMetadata.getError("Please fill the dates")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        {getDateErrorMessages()}
        <Row>
          <Col sm={6}>
            <GVFormGroup controlId="value">
              <Form.Label>Value</Form.Label>
              <GVDSFormField
                type="number"
                placeholder="Enter value"
                className="values_name_input"
                value={newRecord.value}
                onInput={(value) => onRecordChange("value", value)}
                statusMetadata={
                  isValidated && !isValueValid()
                    ? FormFieldStatusMetadata.getError("Please enter a value.")
                    : FormFieldStatusMetadata.getDefault()
                }
                disabled={isSaving}
              />
            </GVFormGroup>
          </Col>
        </Row>
        {possibleErrors.value && (
          <div className="manual-warning-feedback d-flex flex-row mb-3">
            <div className="me-1">
              <GVDSIconSlim Icon={IconAlertTriangle} />
            </div>
            <div className="flex-grow-1 text-vertical-center">
              The value is unusually {possibleErrors.value.compared_to_previous}{" "}
              compared to the previous value and may be incorrect.
            </div>
          </div>
        )}
        <Row>
          <Col bg={10}>
            <Form.Label>Comments</Form.Label>
            <GVFormGroup controlId="comments">
              <GVDSFormField
                placeholder="Enter comments"
                className="comments_name_input"
                value={newRecord.comments}
                onInput={(value) => onRecordChange("comments", value)}
                disabled={isSaving}
              />
            </GVFormGroup>
          </Col>
        </Row>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onCloseDialog}
          disabled={isSaving}
          text="Close"
        />
        {getSaveButton()}
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default InputOperationalDataRecordModal;
