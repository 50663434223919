import React from "react";
import GVDSIcon from "../gvds-components/Icons/GVDSIcon";
import {
  IconBuildingBank,
  IconLeaf,
  IconUsersGroup,
} from "@tabler/icons-react";

export const TextCopies = {
  UNSDGs: {
    about:
      "The UN Sustainable Development Goals (SDGs) are a set of 17 goals seeking to address global challenges and achieve a more sustainable future by 2030.",
  },
  SurveyAssessment: {
    markAsComplete: (
      <div>
        This action marks your survey as complete to the survey creator.
        <br />
        <br />
        You can still mark this survey as incomplete or make changes to
        responses until the survey is closed.
      </div>
    ),
    markAsIncomplete: (
      <div>
        This action marks your survey as incomplete to the survey creator.
        <br />
        <br />
        You will have to mark your survey as complete again after updating your
        responses.
      </div>
    ),
  },
  EnvironmentalOperational: {
    meterFrequencyDescription:
      "The typical timespan of a data entry for this meter. Frequency may be used to extrapolate for missing data records in the HCMI Metric report only.",
    meterFrequencyExample:
      "Example: Meter B is missing some data. If Meter B’s frequency is “Monthly”, then the previous 3 months of values will be used to estimate each month of the missing data",
  },
  PolicyTracker: {
    esgDescription: (
      <>
        <div className="text-icon-aligned-horizontally">
          <span className="me-1">Environmental</span>
          <GVDSIcon Icon={IconLeaf} />{" "}
        </div>
        <div className="text-icon-aligned-horizontally">
          <span className="me-1">Social</span>
          <GVDSIcon Icon={IconUsersGroup} />{" "}
        </div>
        <div className="text-icon-aligned-horizontally">
          <span className="me-1">Governance</span>
          <GVDSIcon Icon={IconBuildingBank} />
        </div>
      </>
    ),
    complianceRequirements: (
      <>
        <p>
          Indicates when the site/company must take action to comply, and the
          potential impact of non-compliance.
        </p>
        <div>
          Some policies may not have a deadline because they are either still in
          the legislative process, or they require ongoing compliance.
        </div>
      </>
    ),
    thresholdEligibilityCriteria:
      'Indicates if the policy only applies to sites/companies of a certain size or type. "No" means that the policy has no specified threshold and is generally applicable to all.',
    priorityLevelHighDescription:
      "The policy is highly relevant to hotels/hospitality sector, likely mandatory, and requires direct compliance action. It may offer substantial rebates or incentives.",
    priorityLevelMediumDescription:
      "The policy might be mandatory or voluntary. Compliance for hotels/hospitality sector is not strictly required currently, but may be in future. It may offer moderate rebates or incentives.",
    priorityLevelLowDescription:
      "The policy is broad in scope or not directly targeting hotels/hospitality sector. It is usually voluntary or for guidance.",
    priorityLevelInfoOnlyDescription:
      "Policy is informational with no action required from hotels/hospitality sector, but can provide ESG trend context and indicate direction of future impactful policies.",
    transitionRiskDescription:
      "The policy is related to decarbonization or climate change mitigation/adaptation. This is useful in identifying and managing regulatory risks",
    complianceObligation:
      "Indicates how the policy applies to the site/company. Compliance options: Mandatory (required to comply), Voluntary (may choose to participate) or Guidance (recommendations or information for context).",
    legislativeStatus:
      "Indicates the current stage of this policy in the legislative process, e.g. whether it has officially become law or is still under review.",
    jurisdictionLevel:
      "Indicates the geographic or administrative scope at which the policy applies.",
    disclaimers: [
      "No Guarantee of Accuracy: The research, interpretation, and advisory services provided are based on the information available at the time of analysis, and there is no guarantee of the accuracy, completeness, or realibity of the information.",
      "Not a substitute for Legal Advice: The concepts of this policy tracker provided are not intended to be a substitute for legal advice. Consultation should be sought with qualified legal counsel for specific legal advice on regulatory matters.",
      "No Liability: Greenview shall not be liable for any direct, indirect, incidental, or consequential damages arising from the use of the policy tracker.",
      "Independent Analysis: The information provided is an independent analysis, and Greenview is not affiliated with any regulatory agency or government.",
      "No Endorsement: Greenview does not endorse any specific regulatory policy or course of action and provides the analysis solely for information purposes.",
      "This data set is intended for the sole use of the recipient. Further dissemination of this file or its contents requires specific permission.",
    ],
  },
  CertificationAssessment: {
    readinessScoreDescription: (
      <>
        <p>
          Measures how prepared your property is for certification based on
          completed requirements.
        </p>
        <span>
          Note: this score excludes tasks or options under a requirement and
          requirements marked as "not applicable".
        </span>
      </>
    ),
  },
};
