import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import UserInventoryContext, {
  getSiteId,
} from "../../../context/UserInventoryContext";
import { RESOURCES } from "../../../config/constants";
import LoadingSpinner from "../../common/LoadingSpinner";
import {
  ADMIN_SITE_DETAILS,
  ADMIN_TEAM,
  BEST_PRACTICES_ASSESS,
  ENVIRONMENTAL_DATA,
  GOALS_AND_TARGETS,
  HELP,
  REPORT_BEST_PRACTICE_REPORT,
  REPORT_INITIATIVES_REPORT,
  REPORT_PERFORMANCE_REPORT,
  REPORT_STANDARD_REPORT,
  SURVEYS,
  VIEW_ALL_INITIATIVES,
} from "../../../config/ROUTES_NAME";
import {
  getPathWithTabKey,
  getPathWithUpdatedInventory,
  getRedirectURLWithCurrentParam,
} from "../../common/QueryHandler";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import {
  IconBook,
  IconRocket,
  IconClipboardCheck,
  IconFileAnalytics,
  IconHelpCircle,
  IconSettings,
  IconTable,
  IconTargetArrow,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const generateAdminFacilityDetailPath = (
  location,
  userInventory,
  facilityId
) => {
  if (userInventory.selectedInventory.get.type === RESOURCES.FACILITY) {
    const newLocation = {
      pathname: `/admin/facilities/${facilityId}`,
      search: location.search,
    };
    return getPathWithUpdatedInventory(
      newLocation,
      RESOURCES.SITE,
      getSiteId(userInventory.selectedTreeNode.get)
    );
  }
  return "/";
};

const LANDING_PAGE_SHORTCUTS = {
  MANAGE_TEAM: "Manage Contract Team",
  RUN_PERFORMANCE_REPORT: "Run Performance Report",
  RUN_STANDARD_REPORT: "Run Standard Report",
  SET_SHARED_TARGETS: "Set Shared Targets",
  RUN_INITIATIVES_REPORT: "Run Initiatives Report",
  RUN_BEST_PRACTICES_REPORT: "Run Best Practices Report",
  LAUNCH_A_SURVEY: "Launch a Survey",
  VIEW_HELP_CENTER: "View Help Center",
  MANAGE_SITE_DETAILS_AND_TEAM: "Manage Site Details & Team",
  INPUT_ENVIRONMENTAL_DATA: "Input Environmental Data",
  SET_GOALS_AND_TARGETS: "Set Goals & Targets",
  LOG_INITIATIVES: "Log Initiatives",
  VIEW_BEST_PRACTICES: "View Best Practices",
  MANAGE_FACILITY_DETAILS: "Manage Facility Details",
};

const LANDING_PAGE_SHORTCUTS_BY_TYPE = {
  [RESOURCES.PORTFOLIO]: [
    LANDING_PAGE_SHORTCUTS.MANAGE_TEAM,
    LANDING_PAGE_SHORTCUTS.RUN_PERFORMANCE_REPORT,
    LANDING_PAGE_SHORTCUTS.RUN_STANDARD_REPORT,
    LANDING_PAGE_SHORTCUTS.SET_SHARED_TARGETS,
    LANDING_PAGE_SHORTCUTS.RUN_INITIATIVES_REPORT,
    LANDING_PAGE_SHORTCUTS.RUN_BEST_PRACTICES_REPORT,
    LANDING_PAGE_SHORTCUTS.LAUNCH_A_SURVEY,
    LANDING_PAGE_SHORTCUTS.VIEW_HELP_CENTER,
  ],
  [RESOURCES.SITE]: [
    LANDING_PAGE_SHORTCUTS.MANAGE_SITE_DETAILS_AND_TEAM,
    LANDING_PAGE_SHORTCUTS.INPUT_ENVIRONMENTAL_DATA,
    LANDING_PAGE_SHORTCUTS.RUN_PERFORMANCE_REPORT,
    LANDING_PAGE_SHORTCUTS.SET_GOALS_AND_TARGETS,
    LANDING_PAGE_SHORTCUTS.LOG_INITIATIVES,
    LANDING_PAGE_SHORTCUTS.RUN_INITIATIVES_REPORT,
    LANDING_PAGE_SHORTCUTS.VIEW_BEST_PRACTICES,
    LANDING_PAGE_SHORTCUTS.VIEW_HELP_CENTER,
  ],
  [RESOURCES.FACILITY]: [
    LANDING_PAGE_SHORTCUTS.MANAGE_FACILITY_DETAILS,
    LANDING_PAGE_SHORTCUTS.INPUT_ENVIRONMENTAL_DATA,
    LANDING_PAGE_SHORTCUTS.RUN_PERFORMANCE_REPORT,
    LANDING_PAGE_SHORTCUTS.VIEW_HELP_CENTER,
  ],
};

const getShortcutProps = (t) => {
  return {
    [LANDING_PAGE_SHORTCUTS.MANAGE_TEAM]: {
      path: ADMIN_TEAM,
      icon: IconSettings,
      label: t("landing-page.shortcuts.label-manage-team"),
    },
    [LANDING_PAGE_SHORTCUTS.RUN_PERFORMANCE_REPORT]: {
      path: REPORT_PERFORMANCE_REPORT,
      icon: IconFileAnalytics,
      label: t("landing-page.shortcuts.label-performance-report"),
    },
    [LANDING_PAGE_SHORTCUTS.RUN_STANDARD_REPORT]: {
      path: REPORT_STANDARD_REPORT,
      icon: IconFileAnalytics,
      label: t("landing-page.shortcuts.label-standard-report"),
    },
    [LANDING_PAGE_SHORTCUTS.SET_SHARED_TARGETS]: {
      path: GOALS_AND_TARGETS,
      icon: IconTargetArrow,
      label: t("landing-page.shortcuts.label-set-shared-targets"),
    },
    [LANDING_PAGE_SHORTCUTS.RUN_INITIATIVES_REPORT]: {
      path: REPORT_INITIATIVES_REPORT,
      icon: IconFileAnalytics,
      label: t("landing-page.shortcuts.label-initiatives-report"),
    },
    [LANDING_PAGE_SHORTCUTS.RUN_BEST_PRACTICES_REPORT]: {
      path: REPORT_BEST_PRACTICE_REPORT,
      icon: IconFileAnalytics,
      label: t("landing-page.shortcuts.label-best-practices-report"),
    },
    [LANDING_PAGE_SHORTCUTS.LAUNCH_A_SURVEY]: {
      path: SURVEYS,
      icon: IconClipboardCheck,
      label: t("landing-page.shortcuts.label-launch-survey"),
    },
    [LANDING_PAGE_SHORTCUTS.VIEW_HELP_CENTER]: {
      path: HELP,
      icon: IconHelpCircle,
      openInNewTab: true,
      label: t("landing-page.shortcuts.label-view-help-center"),
    },
    [LANDING_PAGE_SHORTCUTS.MANAGE_SITE_DETAILS_AND_TEAM]: {
      path: ADMIN_SITE_DETAILS,
      icon: IconSettings,
      label: t("landing-page.shortcuts.label-manage-site-details-and-team"),
    },
    [LANDING_PAGE_SHORTCUTS.INPUT_ENVIRONMENTAL_DATA]: {
      path: ENVIRONMENTAL_DATA,
      icon: IconTable,
      label: t("landing-page.shortcuts.label-input-env-data"),
    },
    [LANDING_PAGE_SHORTCUTS.SET_GOALS_AND_TARGETS]: {
      path: GOALS_AND_TARGETS,
      icon: IconTargetArrow,
      label: t("landing-page.shortcuts.label-set-goals-and-targets"),
    },
    [LANDING_PAGE_SHORTCUTS.LOG_INITIATIVES]: {
      path: VIEW_ALL_INITIATIVES,
      icon: IconTable,
      label: t("landing-page.shortcuts.label-input-initiatives"),
    },
    [LANDING_PAGE_SHORTCUTS.VIEW_BEST_PRACTICES]: {
      path: BEST_PRACTICES_ASSESS,
      icon: IconBook,
      label: t("landing-page.shortcuts.label-view-best-practices"),
    },
    [LANDING_PAGE_SHORTCUTS.MANAGE_FACILITY_DETAILS]: {
      path: (location, userInventory) =>
        generateAdminFacilityDetailPath(
          location,
          userInventory,
          userInventory.selectedInventory.get?.id
        ),
      icon: IconSettings,
      label: t("landing-page.shortcuts.label-manage-facility"),
    },
  };
};

const getIcon = (shortcutProps) => {
  return shortcutProps && shortcutProps.icon ? (
    <GVDSIcon className="widget-shortcut__icon" Icon={shortcutProps.icon} />
  ) : (
    <GVDSIcon className="widget-shortcut__icon" Icon={IconSettings} />
  );
};

const ShortcutsWidget = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const userInventory = useContext(UserInventoryContext);
  const [isLoading, setIsLoading] = useState(true);
  const [shortcuts, setShortcuts] = useState([]);

  const getShortcutPath = (shortcutProps) => {
    const path = shortcutProps.path;
    const tab = shortcutProps.tab;

    const pathWithParam =
      typeof path === "function"
        ? path(location, userInventory)
        : getRedirectURLWithCurrentParam(path, location);
    return !!tab ? getPathWithTabKey(pathWithParam, tab) : pathWithParam;
  };

  useEffect(() => {
    if (
      !userInventory.isLoadingInventory.get &&
      userInventory.selectedInventory.get
    ) {
      setIsLoading(true);
      const selectedInventory = userInventory.selectedInventory.get;
      setShortcuts(LANDING_PAGE_SHORTCUTS_BY_TYPE[selectedInventory.type]);
      setIsLoading(false);
    }
  }, [
    userInventory.isLoadingInventory.get,
    userInventory.selectedInventory.get,
  ]);

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <div className="widget--shortcut__content">
        {shortcuts.map((shortcut, index) => {
          const shortcutProps = getShortcutProps(t)[shortcut];
          const anchorProps = !!shortcutProps?.openInNewTab
            ? { target: "_blank", rel: "noopener noreferrer" }
            : {};

          return (
            <Link
              key={index}
              to={getShortcutPath(shortcutProps)}
              {...anchorProps}
            >
              <div className="widget-shortcut__item">
                {getIcon(shortcutProps)}
                <div>{shortcutProps.label}</div>
              </div>
            </Link>
          );
        })}
      </div>
    );
  }

  return (
    <div className="base-widget__container">
      <div className="base-widget__heading-icon">
        <GVDSIcon Icon={IconRocket} />
      </div>
      <div className="base-widget__heading">
        {t("landing-page.shortcuts.title")}
      </div>
      {content}
    </div>
  );
};

export default ShortcutsWidget;
