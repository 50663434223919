import React, { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import RiskAssessmentReportService from "../../../services/Report/RiskAssessmentReportService";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import Button from "react-bootstrap/Button";
import { DateTimeUtils } from "../../../services/UtilsService";
import UserAvatar from "../../common/UserAvatar";
import { ASYNC_SYSTEM_TASK_STATUS } from "../../../config/constants";
import SupportContactLink from "../../common/SupportContactLink";
import { HourMinuteCountdownTimeDisplay } from "../../common/DynamicTimeDisplay";
import InlineSpinner from "../../common/InlineSpinner";
import { downloadFromPresignedS3Url } from "../../common/FileAttachments";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSTable from "../../../gvds-components/Table/GVDSTable";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import {
  IconCircleCheck,
  IconClock,
  IconExclamationCircle,
  IconInbox,
  IconLoader,
} from "@tabler/icons-react";

const generatedReportColumns = [
  {
    header: "Status",
    dataKey: "status",
    headerStyle: { width: "200px" },
    renderer: (r) => {
      return <ReportStatusDisplay details={r} />;
    },
  },
  {
    header: "Report Name",
    dataKey: "reportName",
    headerStyle: { width: "900px" },
    renderer: (r) => {
      return <ReportNameDisplay details={r} />;
    },
  },
  {
    header: "Selected Risk Categories",
    dataKey: "selectedRiskCategoriesDisplay",
    headerStyle: { width: "400px" },
  },
  {
    header: "Generated By",
    dataKey: "requestedOn",
    headerStyle: { width: "200px" },
    renderer: (r) => {
      return (
        <div>
          <UserAvatar
            fullName={r.requestedByName}
            userEmail={r.requestedByEmail}
          />{" "}
          {DateTimeUtils.formatUTCDate(r.requestedOn)}
        </div>
      );
    },
  },
];

const ReportStatusDisplay = ({ details }) => {
  const status = details.status;
  let display;

  if (
    status === ASYNC_SYSTEM_TASK_STATUS.QUEUED ||
    status === ASYNC_SYSTEM_TASK_STATUS.IN_PROGRESS
  ) {
    display = (
      <>
        <GVDSIcon className="me-2 fa-spin" Icon={IconLoader} />
        <div>Generating...</div>
      </>
    );
  } else if (status === ASYNC_SYSTEM_TASK_STATUS.FAIL) {
    display = (
      <>
        <GVDSIcon className="me-2 color-yellow" Icon={IconExclamationCircle} />
        <div className="color-dark-yellow">Error</div>
      </>
    );
  } else if (status === ASYNC_SYSTEM_TASK_STATUS.DONE) {
    display = (
      <>
        <GVDSIcon className="me-2 gvds-color--success" Icon={IconCircleCheck} />
        <div className="color-green">
          Expires in{" "}
          <HourMinuteCountdownTimeDisplay targetTime={details.expireOn} />
        </div>
      </>
    );
  } else if (status === ASYNC_SYSTEM_TASK_STATUS.EXPIRED) {
    display = (
      <>
        <GVDSIcon className="me-2 gvds-color--gray6" Icon={IconClock} />
        <div>Expired</div>
      </>
    );
  }

  return <div className="text-vertical-center">{display}</div>;
};

const ReportLinkDisplay = ({ reportName, reportLink }) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = async () => {
    setIsLoading(true);
    try {
      const selectedInventory = userInventory.selectedInventory.get;
      const params = {
        resource_type: selectedInventory.type,
        resource_id: selectedInventory.id,
      };
      await downloadFromPresignedS3Url(reportLink, params, true);
    } catch (e) {
      toastContext.addFailToast(<span>Failed to download report</span>);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant="link"
      size="sm"
      className="file-storage-item-name"
      onClick={downloadFile}
      disabled={isLoading}
    >
      {isLoading && <InlineSpinner />}
      {!isLoading && <>{reportName}</>}
    </Button>
  );
};

const ReportNameDisplay = ({ details }) => {
  let display;

  if (
    details.status === ASYNC_SYSTEM_TASK_STATUS.QUEUED ||
    details.status === ASYNC_SYSTEM_TASK_STATUS.IN_PROGRESS
  ) {
    display = <div>—</div>;
  } else if (details.status === ASYNC_SYSTEM_TASK_STATUS.FAIL) {
    display = (
      <div className="color-dark-yellow">
        Please generate the report again, or contact{" "}
        <SupportContactLink
          openInNewTab={true}
          className="color-dark-yellow text-decoration-underline"
        />
        .
      </div>
    );
  } else if (details.status === ASYNC_SYSTEM_TASK_STATUS.DONE) {
    display = (
      <div>
        <ReportLinkDisplay
          reportName={details.reportName}
          reportLink={details.reportLink}
        />
      </div>
    );
  } else if (details.status === ASYNC_SYSTEM_TASK_STATUS.EXPIRED) {
    display = <div>{details.reportName}</div>;
  }

  return <div className="d-flex flex-row align-items-center">{display}</div>;
};

const hasQueuedReport = (reports) => {
  return reports.some(
    (r) =>
      r.status === ASYNC_SYSTEM_TASK_STATUS.QUEUED ||
      r.status === ASYNC_SYSTEM_TASK_STATUS.IN_PROGRESS
  );
};

const GeneratedReports = ({ lastUpdate, onLoadReports }) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(true);
  const [generatedReports, setGeneratedReports] = useState([]);
  const [reportLastRefresh, setReportLastRefresh] = useState(new Date());

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(generatedReports, null);

  const loadReports = () => {
    if (selectedInventory?.id) {
      setIsLoading(true);
      RiskAssessmentReportService.getGeneratedReports(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((data) => {
          setGeneratedReports(data);
          onLoadReports(hasQueuedReport(data));
          setReportLastRefresh(new Date());
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load generated reports.</span>
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    loadReports();
  }, [selectedInventory, lastUpdate]);

  let content;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else if (generatedReports.length === 0) {
    content = (
      <div className="empty-generated-report-placeholder">
        <div className="gvds-empty-content--icon">
          <GVDSIcon Icon={IconInbox}/>
        </div>
        <div className="gvds-empty-content--title">No generated reports</div>
        <div>
          To generate a risk assessment report, select the risk categories
          you’re interested in above and click “Generate Report”.
        </div>
      </div>
    );
  } else {
    content = (
      <>
        <div className="gv-text-14">
          <p>
            The report may take some time to generate due to their size. When
            the report is ready, the user who generated it will receive an email
            notification. Generated reports will be available for download from
            the Portal for 24 hours, please come back to download it before it
            expires.
          </p>
        </div>
        <div>
          <div className="d-flex align-items-center">
            <div className="ms-auto me-2">
              Last Updated:{" "}
              {DateTimeUtils.formatLocalDateWithTime(reportLastRefresh)}
            </div>
            <GVDSButton
              variant={buttonVariant.secondary}
              onClick={loadReports}
              text="Refresh Status"
            />
          </div>
          <div>
            <GVDSTableCtrlContainer>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            <GVDSTable
              columns={generatedReportColumns}
              dataToDisplay={currentPageData}
              startIndex={startIndex}
              sortKeys={sortKeys}
              setSortKeys={setSortKeys}
              hideRowNumber={true}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="gvds-text--heading3 mb-2">Generated Reports</div>
      {content}
    </div>
  );
};

export default GeneratedReports;
