import axios from "axios";
import { API_URL } from "../config/api-config";
import { DateTimeUtils } from "./UtilsService";
import {
  DataRequestStatusEnum,
  DataRequestTypeFullNames,
} from "../components/Data/Overview/DataRequest/DataRequestModel";
import StatusLabel from "../gvds-components/common/StatusLabel";

export class ContractDetailsModel {
  constructor(
    id,
    name,
    status,
    company,
    internalComment,
    approvedDomains,
    accountManager,
    billingAdmin,
    stripeCustomerUrl,
    users,
    pendingUsers,
    sites,
    portfolios
  ) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.company = company;
    this.internalComment = internalComment;
    this.approvedDomains = approvedDomains;
    this.accountManager = accountManager;
    this.billingAdmin = billingAdmin;
    this.stripeCustomerUrl = stripeCustomerUrl;
    this.users = users;
    this.pendingUsers = pendingUsers;
    this.sites = sites;
    this.portfolios = portfolios;
  }

  static fromDTO(dto) {
    return new ContractDetailsModel(
      dto.id,
      dto.name,
      dto.status,
      dto.company,
      dto.internal_comment,
      dto.approved_domains,
      dto.account_manager,
      dto.billing_admin,
      dto["stripe_customer_url"],
      dto.users,
      dto.pending_users,
      dto.sites,
      dto.portfolios
    );
  }
}

export class PortfolioResourceDataRequestModel {
  constructor({ id, type, deadline, status }) {
    this.id = id;
    this.type = type;
    this.typeInFullName = DataRequestTypeFullNames[type];
    this.deadline = deadline;
    this.status = status;
  }

  static fromJson(requestJson) {
    return new PortfolioResourceDataRequestModel({
      id: requestJson["id"],
      type: requestJson["type"],
      deadline: requestJson["deadline"],
      status: requestJson["status"],
    });
  }

  getStatusLabelColor() {
    if (!this.status) {
      return StatusLabel.Colors.gray;
    }

    if (this.status === DataRequestStatusEnum.EXPIRED) {
      return StatusLabel.Colors.red;
    } else {
      return StatusLabel.Colors.green;
    }
  }
}

export default class ContractService {
  static createNewDraftContract = async (
    name,
    company_id,
    internal_comment,
    approved_domains,
    users_with_roles
  ) => {
    try {
      const data = {
        name,
        company_id,
        internal_comment,
        approved_domains,
        users_with_roles,
      };

      const response = await axios.post(API_URL + "/api/v1/contracts", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateContract = async (
    contract_id,
    name,
    company_id,
    internal_comment
  ) => {
    try {
      const data = {
        name,
        company_id,
        internal_comment,
      };

      const response = await axios.put(
        `${API_URL}/api/v1/contracts/${contract_id}`,
        data
      );
      return ContractDetailsModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static addUserToContract = async (
    contractId,
    email,
    roleId,
    as_billing_admin
  ) => {
    try {
      const data = {
        email,
        roleId,
        as_billing_admin,
      };

      const response = await axios.post(
        API_URL + "/api/v1/contracts/" + contractId + "/users",
        data
      );
      return ContractDetailsModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static editUserRole = async (
    contract_id,
    user_id,
    role_id,
    as_billing_admin
  ) => {
    try {
      const data = {
        role_id,
        as_billing_admin,
      };

      const response = await axios.put(
        `${API_URL}/api/v1/contracts/${contract_id}/users/${user_id}`,
        data
      );
      return ContractDetailsModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static removeUserFromContract = async (contract_id, user_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/contracts/${contract_id}/users/${user_id}`
      );
      return ContractDetailsModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static deleteInviteFromContract = async (contract_id, invite_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/contracts/${contract_id}/invites/${invite_id}`
      );
      return ContractDetailsModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static updateContractApprovedDomains = async (
    contract_id,
    approved_domains
  ) => {
    try {
      const data = {
        approved_domains,
      };

      const response = await axios.put(
        `${API_URL}/api/v1/contracts/${contract_id}/approved_domains`,
        data
      );
      return ContractDetailsModel.fromDTO(response.data);
    } catch (error) {
      throw error.response;
    }
  };

  static getAllContracts = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/contracts");
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getContractDetails = async (contractId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/contracts/" + contractId
      );
      return ContractDetailsModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static getUsers = async (contractId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/contracts/${contractId}/users`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getPortfolioConfig = async (contractId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/contracts/${contractId}/portfolio-config`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static addPortfolioToContract = async (contractId, portfolio) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/contracts/" + contractId + "/portfolios",
        portfolio
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static editSubPortfolio = async (
    contractId,
    portfolioId,
    portfolioUpdate
  ) => {
    try {
      const response = await axios.patch(
        `${API_URL}/api/v1/contracts/${contractId}/portfolios/${portfolioId}`,
        portfolioUpdate
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static getPortfolioDetails = async (contractId, portfolioId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/contracts/${contractId}/portfolios/${portfolioId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getPortfolioResources = async (contractId, portfolioId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/contracts/${contractId}/portfolios/${portfolioId}/resources`,
        { params: { current_date: DateTimeUtils.getUTCToday() } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getPortfolioMetadata = async (contractId, portfolioId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/contracts/${contractId}/portfolios/${portfolioId}/metadata`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteSubPortfolio = async (contractId, portfolioId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/contracts/${contractId}/portfolios/${portfolioId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getContractOptions = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/contract_options`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateAccountManager = async (contract_id, user_id) => {
    try {
      const data = { user_id };

      const response = await axios.put(
        `${API_URL}/api/v1/contracts/${contract_id}/account_manager`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static removeAccountManager = async (contract_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/contracts/${contract_id}/account_manager`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
