import { uniqueId } from "lodash";
import { new_entry_prefix } from "../../../config/constants";

export const NOT_SECTIONED = "NOT_SECTIONED";

export const createInitialSubrequirement = () => ({
  id: uniqueId(new_entry_prefix),
  content: "",
});

export const createInitialSubrequirementSection = () => ({
  id: uniqueId(new_entry_prefix),
  sectionName: NOT_SECTIONED,
  subrequirements: [],
});
