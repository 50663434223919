import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import Joyride, { STATUS } from "react-joyride";

import getGuidedTourStepsByPathAndResourceType, {
  filterVisibleSteps,
} from "./GuidedTourSteps";
import UserInventoryContext from "../../../context/UserInventoryContext";
import CloseButton from "react-bootstrap/CloseButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import { GuidedTourHelpArticleDedicatedLink } from "./GuidedTourHelpArticleLink";

const GuidedTourTooltip = ({
  index,
  size,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  skipProps,
}) => {
  let headerBody;

  if (!!step.title) {
    headerBody = (
      <>
        <div className="guided-tour-tooltip__header">
          <div className="guided-tour-tooltip__title">{step.title}</div>
          <div className="guided-tour-tooltip__close">
            <CloseButton {...skipProps} />
          </div>
        </div>
        <div className="guided-tour-tooltip__body">
          <div className="guided-tour-tooltip__content">{step.content}</div>
        </div>
      </>
    );
  } else {
    headerBody = (
      <>
        <div className="guided-tour-tooltip__body">
          <div className="guided-tour-tooltip__content">{step.content}</div>
          <div className="guided-tour-tooltip__close">
            <CloseButton {...skipProps} />
          </div>
        </div>
      </>
    );
  }

  let links;

  if (
    !!step.helpArticleTitleDedicatedLinks &&
    step.helpArticleTitleDedicatedLinks.length > 0
  ) {
    links = (
      <div className="guided-tour-tooltip__dedicated-links">
        {step.helpArticleTitleDedicatedLinks.map((title, index) => (
          <GuidedTourHelpArticleDedicatedLink
            key={index}
            articleTitle={title}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="guided-tour-tooltip__container" {...tooltipProps}>
      {headerBody}
      {links}
      <div className="guided-tour-tooltip__footer">
        <div className="guided-tour-tooltip__step-display">
          Step {index + 1} of {size}
        </div>
        <div className="flex-spacer" />
        {index > 0 && (
          <GVDSButton
            variant={buttonVariant.tertiary}
            text="Back"
            {...backProps}
          />
        )}
        <GVDSButton
          variant={buttonVariant.primary}
          text={index === size - 1 ? "Finish Tour" : "Next"}
          {...primaryProps}
        />
      </div>
    </div>
  );
};

const GuidedTourRunner = ({ isRunning, stopRunning }) => {
  let location = useLocation();
  const pathname = location.pathname;

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const steps = filterVisibleSteps(
    getGuidedTourStepsByPathAndResourceType(pathname, selectedInventory?.type)
  );

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      stopRunning();
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={isRunning}
      scrollToFirstStep
      steps={steps}
      tooltipComponent={GuidedTourTooltip}
      hideCloseButton
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

export default GuidedTourRunner;
