import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { RESOURCES } from "../../../../config/constants";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import {
  IconBellStar,
  IconCheck,
  IconCirclePlus,
  IconEdit,
} from "@tabler/icons-react";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import GVDSTableExpandableRow, {
  expandableIconCell,
} from "../../../../gvds-components/Table/GVDSTableExpandableRow";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import { useTranslation } from "react-i18next";

const getNoOfMeters = (metersDatasets, subtopicName, environment_type_id) => {
  const subtopicDatasets = metersDatasets.filter(
    (meterDataset) => meterDataset.environmental_subtopic.name === subtopicName
  );

  if (!subtopicDatasets) {
    return 0;
  }

  const noOfMeterOfTypesFromAllFacilities = subtopicDatasets.map(
    (dataset) =>
      dataset.meters.filter(
        (env_type) => env_type.environmental_type.id === environment_type_id
      ).length
  );

  return noOfMeterOfTypesFromAllFacilities.reduce((a, b) => a + b, 0);
};

const EnvironmentalTypeMetersDisplay = ({
  subtopicName,
  metersDataSet,
  subtopicOptions,
  isEnvTypeTracked,
  environmentType,
  expandedTypes,
  toggleExpandedType,
  onEnvironmentalTypeToggle,
  addMeterDialog,
  editMeterDialog,
  displayAlertPreferencesDialog,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const isExpanded = expandedTypes.indexOf(environmentType.id) >= 0;

  const meterCount = getNoOfMeters(
    metersDataSet,
    subtopicName,
    environmentType.id
  );

  const controlColumns = [
    <td className="environmental-type-table__tracking">
      <Form.Check
        type="switch"
        id={environmentType.id}
        label=""
        key={environmentType.id}
        value={environmentType.id}
        className="environmental-meter-setup__switch"
        onChange={() =>
          onEnvironmentalTypeToggle(
            isEnvTypeTracked,
            environmentType.id,
            subtopicOptions.id
          )
        }
        checked={isEnvTypeTracked}
      />
    </td>,
    meterCount > 0 ? expandableIconCell(isExpanded) : <td />,
  ];

  const dataColumns = [
    <td className="environmental-type-table__type">
      <div>{environmentType.name}</div>
    </td>,
    <td className="environmental-type-table__meter_count">
      <div className="text-center">
        <strong>{meterCount > 0 ? meterCount : null}</strong>
      </div>
    </td>,
    <td className="environmental-type-table__definition">
      <div>{environmentType.definition}</div>
    </td>,
  ];

  return (
    <GVDSTableExpandableRow
      isExpanded={isExpanded}
      toggleIsExpanded={() => toggleExpandedType(environmentType.id)}
      disabled={meterCount === 0}
      controlColumns={controlColumns}
      dataColumns={dataColumns}
      expandedBody={
        <div>
          <GVDSTableDisplay>
            <thead>
              <tr>
                <th>{t("data-management.environmental.shared.meter-id")}</th>
                {selectedInventory.type === RESOURCES.SITE && (
                  <th>{t("shared.facility")}</th>
                )}
                <th>
                  {t("data-management.environmental.shared.monitoring-only")}
                </th>
                <th>{t("shared-input-label.unit")}</th>
                <th>{t("data-management.environmental.shared.frequency")}</th>
                <th>{t("shared-input-label.currency")}</th>
                <th>{t("shared-input-label.description")}</th>
                <th>{t("shared-input-label.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {metersDataSet
                .filter(
                  (meterdataset) =>
                    meterdataset.environmental_subtopic.name === subtopicName
                )
                .map((meterdataset) =>
                  meterdataset.meters
                    .filter(
                      (meter) =>
                        meter.environmental_type.id === environmentType.id
                    )
                    .map((meter) => (
                      <tr key={meter.id}>
                        <td>{meter.name}</td>

                        {userInventory.selectedInventory.get.type ===
                          RESOURCES.SITE && (
                          <td>{meterdataset.facility.name}</td>
                        )}

                        <td>
                          {meter.for_monitoring_only ? (
                            <div className="text-vertical-center justify-content-center gvds-color--success">
                              <GVDSIcon Icon={IconCheck} />
                            </div>
                          ) : null}
                        </td>
                        <td>{meter.default_unit.id}</td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {meter.frequency}
                        </td>
                        <td>{meter.default_currency.id}</td>
                        <td>{meter.description}</td>
                        <td>
                          <div className="meter-table__actions__container">
                            <GVDSIconButton
                              className="btn-edit-meter"
                              onClick={() =>
                                editMeterDialog(
                                  environmentType.id,
                                  subtopicOptions.id,
                                  meter,
                                  meterdataset.facility.id
                                )
                              }
                              variant={iconButtonVariant.tertiary}
                              icon={<GVDSIcon Icon={IconEdit} />}
                              tooltipText={t("shared.edit")}
                            />
                            <GVDSIconButton
                              className="btn-show-alert-preferences-modal"
                              onClick={() =>
                                displayAlertPreferencesDialog(
                                  meter,
                                  meterdataset.facility.id
                                )
                              }
                              variant={iconButtonVariant.tertiary}
                              icon={<GVDSIcon Icon={IconBellStar} />}
                              tooltipText={t(
                                "data-management.shared.alert-preferences.title"
                              )}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                )}
            </tbody>
          </GVDSTableDisplay>
          <div className="action-row-in-expanded-row">
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={() =>
                addMeterDialog(environmentType.id, subtopicOptions.id)
              }
              icon={<GVDSIcon Icon={IconCirclePlus} />}
              text={t("data-management.environmental.setup.button-add-meter")}
            />
          </div>
        </div>
      }
    />
  );
};

export default EnvironmentalTypeMetersDisplay;
