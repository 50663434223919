import React from "react";

import { SURVEY_STATUS } from "../../../config/constants";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../services/UtilsService";

const statusToColorMap = {
  [SURVEY_STATUS.OPEN]: StatusLabel.Colors.green,
  [SURVEY_STATUS.DRAFT]: StatusLabel.Colors.gray,
  [SURVEY_STATUS.CLOSED]: StatusLabel.Colors.red,
};

const SurveyStatusBadge = ({ status }) => {
  let labelColor = statusToColorMap[status];

  return (
    <StatusLabel color={labelColor}>
      {StringUtils.capitaliseWord(status)}
    </StatusLabel>
  );
};

export default SurveyStatusBadge;
