import React from "react";
import GVDSCustomIcons from "../Icons/GVDSIconCustom";

const InfoCardFooterContainer = ({ children }) => {
  return <div className="info-card-content-footer--container">{children}</div>;
};

const GVDSInfoCard = ({
  useIcon = true,
  title,
  variant,
  className,
  children,
  ...props
}) => {
  return (
    <div className={`gvds-info-card ${className ?? ""}`} {...props}>
      <div className="info-card-heading ">
        {useIcon && (
          <div className="info-card-heading--icon">
            <GVDSCustomIcons.InfoFilled />
          </div>
        )}
        <div className="info-card-heading--title">{title}</div>
      </div>
      <div className={`info-card-content ${!useIcon && `no-icon`}`}>
        {children}
      </div>
    </div>
  );
};

GVDSInfoCard.Footer = InfoCardFooterContainer;

export default GVDSInfoCard;
