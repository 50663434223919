import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import MultiTextInput from "../common/MultiTextInput";
import GVFormGroup from "../common/GVFormGroup";
import SupportContactLink from "../common/SupportContactLink";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSTag from "../../gvds-components/common/GVDSTag";
import { Trans, useTranslation } from "react-i18next";

const ApprovedDomains = ({ approvedDomains, isAllowedToEdit, onEditDone }) => {
  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = () => {
    resetFields();
    setShowEditModal(false);
  };

  const [approvedDomainsInput, setApprovedDomainsInput] = useState([]);

  const resetFields = () => {
    setApprovedDomainsInput(approvedDomains);
  };

  const submitChanges = () => {
    setShowEditModal(false);
    onEditDone(approvedDomainsInput ? approvedDomainsInput : []);
  };

  useEffect(() => {
    resetFields();
  }, [approvedDomains]);

  return (
    <div>
      <div>
        {t("admin.team-management.approved-domains.label-approved-domains")}:{" "}
        {approvedDomains && approvedDomains.length > 0 ? (
          approvedDomains.map((domain) => `@${domain}`).join(", ")
        ) : (
          <span className="no-approved-domain__caption">
            {t("admin.team-management.approved-domains.no-approved-domains")}
          </span>
        )}
        {isAllowedToEdit && (
          <Button
            size="sm"
            variant="link"
            onClick={() => setShowEditModal(true)}
          >
            {t("admin.team-management.approved-domains.edit-approved-domains")}
          </Button>
        )}
      </div>
      <div>
        <Trans i18nKey="admin.team-management.approved-domains.manage-approved-domains-message">
          Please contact <SupportContactLink /> to add users with non-approved
          domains or to manage the approved domains list.
        </Trans>
      </div>

      <GVDSModal
        show={showEditModal}
        onHide={closeEditModal}
        title={t(
          "admin.team-management.approved-domains.edit-approved-domains"
        )}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          <div className="mb-3">
            <Trans i18nKey="admin.team-management.approved-domains.edit-approved-domains-message">
              Press Enter or Space for each domain (will be shown like{" "}
              <GVDSTag variant={GVDSTag.Variants.system}>this</GVDSTag>
              ).
            </Trans>
          </div>
          <GVFormGroup>
            <MultiTextInput
              value={approvedDomainsInput}
              onChange={setApprovedDomainsInput}
              placeholder="Add domains by pressing enter"
            />
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeEditModal}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={submitChanges}
            text={t("shared-modal.footer.save")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </div>
  );
};

export default ApprovedDomains;
