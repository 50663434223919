import React, { useContext, useEffect, useState } from "react";
import DashboardDataContext from "../../Context/DashboardDataContext";
import DashboardCalculator from "../../Models/DashboardCalculator";
import PerformanceComparisonChartView from "./PerformanceComparisonChartView";
import { DATA_STATUS } from "../../../../config/constants";
import {
  PerformanceComparisonChartDataModel,
  PerformanceComparisonChartMonthlyValueModel,
} from "../../Models/DashboardModels";
import range from "lodash/range";

const convertPerformanceComparisonDataToChartModel = (
  performanceComparisonData
) => {
  const monthlyValuesByYear = performanceComparisonData.value;

  const years = Object.keys(monthlyValuesByYear);

  if (years.length !== 2) {
    return { data: null, hasDataGaps: true };
  }

  const months = range(1, 13);

  const chartModel = new PerformanceComparisonChartDataModel(
    years[0],
    months.map((month, index) => {
      return new PerformanceComparisonChartMonthlyValueModel(
        index,
        monthlyValuesByYear[years[0]][month]
      );
    }),
    years[1],
    months.map((month, index) => {
      return new PerformanceComparisonChartMonthlyValueModel(
        index,
        monthlyValuesByYear[years[1]][month]
      );
    })
  );

  const hasDataGaps = performanceComparisonData.status !== DATA_STATUS.COMPLETE;

  return {
    data: chartModel,
    hasDataGaps: hasDataGaps,
  };
};

const PerformanceComparisonChart = ({ element }) => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const [performanceComparisonData, setPerformanceComparisonData] =
    useState(null);

  const config = element["config"];

  const subtopic = config["subtopic"];
  const isDataLoaded = dashboardHolderFacade.isAllRequiredDataPresent(
    [subtopic],
    true
  );

  useEffect(() => {
    if (isDataLoaded && dashboardHolderFacade.isComparisonTime()) {
      const performanceComparisonData =
        DashboardCalculator.getPerformanceComparisonData(
          config,
          dashboardHolderFacade
        );

      setPerformanceComparisonData(performanceComparisonData);
    }
  }, [dashboardDataContext.lastDataUpdated]);

  if (!isDataLoaded || performanceComparisonData === null) {
    return (
      <PerformanceComparisonChartView
        isLoading={true}
        title="Performance comparison"
        titleInfoTooltip={element["description"]}
      />
    );
  }

  const { data: performanceComparisonChartData, hasDataGaps } =
    convertPerformanceComparisonDataToChartModel(performanceComparisonData);

  return (
    <PerformanceComparisonChartView
      isLoading={false}
      title="Performance comparison"
      titleInfoTooltip={element["description"]}
      period={dashboardHolderFacade.getComparisonTimeDisplay()}
      subtopic={subtopic}
      intensity={DashboardCalculator.getIntensityDisplay(
        config,
        dashboardHolderFacade
      )}
      hasDataGaps={hasDataGaps}
      chartData={performanceComparisonChartData}
      unit={DashboardCalculator.getDividendUnit(config, dashboardHolderFacade)}
      intensityUnit={DashboardCalculator.getDivisorUnit(
        config,
        dashboardHolderFacade
      )}
    />
  );
};

export default PerformanceComparisonChart;
