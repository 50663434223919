import React, { useContext, useState } from "react";
import withAuthentication from "../HOC/withAuthentication";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LoadingSpinner from "../common/LoadingSpinner";
import SelectCompany from "../Company/SelectCompany";
import ContractService from "../../services/ContractService";
import AddUsersWithRoles, { isUsersValid } from "../User/AddUsersWithRole";
import { RESOURCES } from "../../config/constants";
import { getViewContractDetailsPath } from "../../config/ROUTES_NAME";
import ToastContext from "../../context/ToastContext";
import MultiTextInput from "../common/MultiTextInput";
import GVFormGroup from "../common/GVFormGroup";
import { useHistory, useLocation } from "react-router-dom";
import {
  getPathWithTabKey,
  getRedirectURLWithCurrentParam,
} from "../common/QueryHandler";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSFormTextArea from "../../gvds-components/Forms/GVDSFormTextArea";
import GVDSTag from "../../gvds-components/common/GVDSTag";

const CreateContract = () => {
  let history = useHistory();
  let location = useLocation();
  const toastContext = useContext(ToastContext);

  const [isValidated, setIsValidated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [contractName, setContractName] = useState("");

  const [companyId, setCompanyId] = useState("");
  const [internalComment, setInternalComment] = useState(null);

  const [approvedDomainsInput, setApprovedDomainsInput] = useState([]);
  const [usersWithRoles, setUsersWithRoles] = useState([]);

  const saveContractDraft = async (event) => {
    event.preventDefault();
    setIsValidated(true);

    if (contractName && companyId && isUsersValid(usersWithRoles)) {
      setIsLoading(true);

      try {
        const contractDTO = await ContractService.createNewDraftContract(
          contractName,
          companyId,
          internalComment,
          approvedDomainsInput,
          usersWithRoles
        );

        toastContext.addSuccessToast(
          <span>
            Contract created.
            <br />
            Add subscription to provide access.
          </span>
        );

        const pathWithTabKey = getPathWithTabKey(
          getRedirectURLWithCurrentParam(
            getViewContractDetailsPath(contractDTO.id),
            location
          ),
          "subscriptions"
        );
        history.push(pathWithTabKey);
      } catch (error) {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to create a contract.</span>);
      }
    }
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <h1>Creating contract</h1>
      <div>
        <Row>
          <Col sm={5}>
            <GVFormGroup controlId="contractName">
              <Form.Label>Contract Name</Form.Label>
              <GVDSFormField
                placeholder="Enter contract name"
                value={contractName}
                onInput={(value) => setContractName(value)}
                statusMetadata={
                  isValidated && !contractName
                    ? FormFieldStatusMetadata.getError(
                        "Please provide Contract Name."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <SelectCompany
              selected={companyId}
              onCompanySelected={setCompanyId}
              isValidated={isValidated}
            />
          </Col>
        </Row>
        <div className="mb-4">
          <div className="info-field">
            Internal Comment{" "}
            <span className="optional-form-label">(Optional)</span>
          </div>
          <GVDSFormTextArea
            name="internal-comment"
            value={internalComment}
            onInput={(value) => setInternalComment(value)}
            rows={3}
          />
        </div>
        <GVFormGroup className="mb-4">
          <div className="info-field">
            Approved Domains{" "}
            <span className="optional-form-label">(Optional)</span>
          </div>
          <div>
            Press Enter or Space for each domain (will be shown like{" "}
            <GVDSTag variant={GVDSTag.Variants.system}>this</GVDSTag>
            ).
          </div>
          <MultiTextInput
            className="approved-domains-input"
            value={approvedDomainsInput}
            onChange={setApprovedDomainsInput}
            placeholder="Add domains by pressing enter"
          />
        </GVFormGroup>
        <div className="mb-4">
          <AddUsersWithRoles
            resource={RESOURCES.CONTRACT}
            usersWithRoles={usersWithRoles}
            setUsersWithRoles={setUsersWithRoles}
            isValidated={isValidated}
          />
        </div>
        <div className="d-flex justify-content-end">
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveContractDraft}
            text="Create Contract"
          />
        </div>
      </div>
    </Container>
  );
};

export default withAuthentication(CreateContract);
