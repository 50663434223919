import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { DashboardService } from "../../../services/Dashboard/DashboardService";

const DashboardOptionContext = createContext(null);

export const DashboardOptionProvider = ({ children }) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const currentInventory = userInventory.selectedInventory.get;

  const [isLoadingDashboardList, setIsLoadingDashboardList] = useState(true);
  const [isLoadingSelectedDashboard, setIsLoadingSelectedDashboard] =
    useState(true);

  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [selectedDashboardOptions, setSelectedDashboardOptions] =
    useState(null);
  const [lastDashboardChange, setLastDashboardChange] = useState(new Date());

  useEffect(() => {
    if (currentInventory) {
      setIsLoadingDashboardList(true);

      DashboardService.getAllDashboards(
        currentInventory.type,
        currentInventory.id
      )
        .then((dashboardsResponse) => {
          setDashboards(dashboardsResponse);

          if (selectedDashboard === null) {
            loadDashboard(dashboardsResponse[0].id);
          }
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to load dashboards.</span>);
        })
        .finally(() => {
          setIsLoadingDashboardList(false);
        });

      DashboardService.getOptions(
        currentInventory.type,
        currentInventory.id
      ).then((menuOptions) => {
        setSelectedDashboardOptions(menuOptions);
      });
    }
  }, [currentInventory?.id]);

  const loadDashboard = async (dashboardId) => {
    setIsLoadingSelectedDashboard(true);

    try {
      const dashboardResponse = await DashboardService.getDashboard(
        currentInventory.type,
        currentInventory.id,
        dashboardId
      );
      setSelectedDashboard(dashboardResponse);
      setLastDashboardChange(new Date());
    } catch (e) {
      toastContext.addFailToast(<span>Failed to load dashboard.</span>);
    } finally {
      setIsLoadingSelectedDashboard(false);
    }
  };

  const store = useCallback(
    {
      dashboards: dashboards,
      selectedDashboard: selectedDashboard,
      selectedDashboardOptions: selectedDashboardOptions,
      isLoadingDashboardList: isLoadingDashboardList,
      isLoadingSelectedDashboard: isLoadingSelectedDashboard,
      loadDashboard: loadDashboard,
      lastDashboardChange: lastDashboardChange,
    },
    [
      isLoadingDashboardList,
      dashboards,
      isLoadingSelectedDashboard,
      selectedDashboard,
      selectedDashboardOptions,
      lastDashboardChange,
    ]
  );

  return (
    <DashboardOptionContext.Provider value={store}>
      {children}
    </DashboardOptionContext.Provider>
  );
};

export default DashboardOptionContext;
