import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import GVFormGroup from "../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { RESOURCES, TASK_MANAGEMENT_STATUS } from "../../config/constants";
import { Editor } from "react-draft-wysiwyg";
import {
  DateTimeUtils,
  RTFUtils,
  UserUtils,
} from "../../services/UtilsService";
import TaskManagementService from "../../services/TaskManagementService";
import ToastContext from "../../context/ToastContext";
import UserInventoryContext from "../../context/UserInventoryContext";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../common/LoadingSpinner";
import { faArchive } from "@fortawesome/free-solid-svg-icons/faArchive";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSFormSingleDatePicker from "../../gvds-components/Forms/GVDSFormSingleDatePicker";
import GVDSFormMultiSelect from "../../gvds-components/Forms/GVDSFormMultiSelect";
import { Trans, useTranslation } from "react-i18next";
import { TASK_MANAGEMENT_ARCHIVE_ACTION } from "./SharedTaskManagementUtil";

const initialValues = {
  name: "",
  description: "",
  deadline: "",
  status: TASK_MANAGEMENT_STATUS.NOT_STARTED,
  assignees: [],
};

const SELECT_ALL_ASSIGNEES_ID = "all";

const InputTaskModal = ({
  show,
  onClose,
  availableAssignees,
  onDoneInput,
  currentTask,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isValidated, setIsValidated] = useState(false);
  const [isEditExistingTask, setIsEditExistingTask] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [inputTask, setInputTask] = useState(initialValues);

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    if (currentTask) {
      setIsEditExistingTask(true);
      setInputTask({
        name: currentTask.name,
        description: RTFUtils.convertHTMLToEditorState(currentTask.description),
        deadline: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          currentTask.deadline
        ),
        status: currentTask.status,
        assignees: currentTask.assignees.map((a) => {
          return {
            id: a.id,
            name: UserUtils.getFullNameOrEmail(a.full_name, a.email),
          };
        }),
        created_by: currentTask.created_by,
        created_on: currentTask.created_on,
        created_in: currentTask.created_in,
      });
    } else {
      setIsEditExistingTask(false);
      setInputTask(initialValues);
    }
  }, [currentTask]);

  const isTaskNameValid = () => {
    return inputTask.name && inputTask.name.length > 0;
  };

  const isDeadlineValid = () => {
    if (!isEditExistingTask) {
      return !!(
        inputTask.deadline &&
        inputTask.deadline >= new Date().setHours(0, 0, 0, 0)
      );
    } else {
      return inputTask.deadline;
    }
  };

  const isAssigneesValid = () => {
    return inputTask.assignees && inputTask.assignees.length > 0;
  };

  const isInputValid = () => {
    return isTaskNameValid() && isDeadlineValid() && isAssigneesValid();
  };

  const onTaskValueChange = (fieldName, fieldValue) => {
    setInputTask({ ...inputTask, [fieldName]: fieldValue });
  };

  const resetInputFields = () => {
    setInputTask(initialValues);
    setIsEditExistingTask(false);
    setIsValidated(false);
  };

  const closeModal = () => {
    if (!isProcessing) {
      setIsValidated(false);
      onClose();
    }
  };

  const onDeletePromptClose = () => {
    if (!isProcessing) {
      setShowDeletePrompt(false);
    }
  };

  const saveTask = async (event) => {
    event.preventDefault();
    setIsValidated(true);

    if (isInputValid()) {
      const selectedInventory = userInventory.selectedInventory.get;
      try {
        setIsProcessing(true);
        if (isEditExistingTask) {
          await TaskManagementService.updateTask(
            selectedInventory.type,
            selectedInventory.id,
            currentTask.id,
            inputTask
          );
          toastContext.addSuccessToast(<span>Task updated successfully</span>);
        } else {
          await TaskManagementService.createTask(
            selectedInventory.type,
            selectedInventory.id,
            inputTask
          );
          toastContext.addSuccessToast(<span>Task created successfully</span>);
        }
        setIsProcessing(false);
        resetInputFields();
        onDoneInput();
        onClose();
      } catch (error) {
        toastContext.addFailToast(<span>Failed to save task.</span>);
        setIsProcessing(false);
      }
    }
  };

  const deleteTask = async (event) => {
    event.preventDefault();
    const selectedInventory = userInventory.selectedInventory.get;

    try {
      setIsProcessing(true);
      await TaskManagementService.deleteTask(
        selectedInventory.type,
        selectedInventory.id,
        currentTask.id
      );
      toastContext.addSuccessToast(
        <span>
          <em>"{currentTask.name}"</em> deleted successfully
        </span>
      );
      setIsProcessing(false);
      setShowDeletePrompt(false);
      resetInputFields();
      onDoneInput();
    } catch (error) {
      toastContext.addFailToast(<span>Failed to delete task.</span>);
      setIsProcessing(false);
    }
  };

  const archiveTask = async (event) => {
    event.preventDefault();
    const selectedInventory = userInventory.selectedInventory.get;

    try {
      setIsProcessing(true);
      await TaskManagementService.setArchiveStatus(
        selectedInventory.type,
        selectedInventory.id,
        currentTask.id,
        TASK_MANAGEMENT_ARCHIVE_ACTION.ARCHIVED
      );
      toastContext.addSuccessToast(
        <span>
          <em>"{currentTask.name}"</em> archived successfully
        </span>
      );
      setIsProcessing(false);
      resetInputFields();
      onDoneInput();
      onClose();
    } catch (error) {
      toastContext.addFailToast(<span>Failed to archive task.</span>);
      setIsProcessing(false);
    }
  };

  const getDeletePrompt = () => {
    const taskName = currentTask?.name;
    return (
      <GVDSModal
        title={t("landing-page.widget-task-mgmt.label-delete-task")}
        size={GVDSModal.Size.small}
        show={showDeletePrompt}
        onHide={onDeletePromptClose}
      >
        {!isProcessing ? (
          <>
            <GVDSModal.Body>
              <div>
                <Trans i18nKey="landing-page.widget-task-mgmt.delete-warning">
                  You are about to permanently delete{" "}
                  <strong>
                    <em>{{ taskName }}</em>
                  </strong>
                  . This action cannot be undone. Are you sure?
                </Trans>
              </div>
            </GVDSModal.Body>
            <GVDSModal.Footer>
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={onDeletePromptClose}
                text={t("shared-modal.footer.cancel")}
              />
              <GVDSButton
                variant={buttonVariant.destructive_primary}
                onClick={deleteTask}
                text={t("shared-modal.footer.yes-delete")}
              />
            </GVDSModal.Footer>
          </>
        ) : (
          <GVDSModal.Body>
            <div className="mt-4">
              <LoadingSpinner />
            </div>
          </GVDSModal.Body>
        )}
      </GVDSModal>
    );
  };

  const getSelectableAssignees = () => {
    const selectableAssignees = availableAssignees.map((a) => ({
      label: UserUtils.getFullNameOrEmail(a.full_name, a.email),
      value: a.id,
    }));
    if (inputTask.assignees.length < availableAssignees.length) {
      selectableAssignees.push({
        label: "Everyone (All users in this team)",
        value: SELECT_ALL_ASSIGNEES_ID,
      });
    }
    return selectableAssignees;
  };

  const updateSelectedAssignees = (selectedAssigneesInDropdown) => {
    let selectedAssignees;

    if (
      selectedAssigneesInDropdown.find(
        (value) => value.value === SELECT_ALL_ASSIGNEES_ID
      )
    ) {
      selectedAssignees = availableAssignees.map((v) => {
        return { id: v.id, name: v.email };
      });
    } else {
      selectedAssignees = selectedAssigneesInDropdown.map((v) => {
        return { id: v.value, name: v.label };
      });
    }
    onTaskValueChange("assignees", selectedAssignees);
  };

  const getDeadlineStatusMetadata = () => {
    if (isValidated && !isDeadlineValid()) {
      if (!inputTask.deadline) {
        return FormFieldStatusMetadata.getError("Task needs a deadline.");
      } else {
        return FormFieldStatusMetadata.getError(
          "Task deadline must be in the future."
        );
      }
    } else {
      return FormFieldStatusMetadata.getDefault();
    }
  };

  return (
    <>
      <GVDSModal
        className="input-task"
        title={
          isEditExistingTask
            ? t("landing-page.widget-task-mgmt.modal-title-update")
            : t("landing-page.widget-task-mgmt.modal-title-create")
        }
        size={GVDSModal.Size.small}
        show={show}
        onHide={() => closeModal()}
      >
        <GVDSModal.Body>
          <Row>
            <Col sm={12}>
              <GVFormGroup controlId="taskName">
                <Form.Label>
                  {t("landing-page.widget-task-mgmt.label-task-name")}
                  <span className="optional-form-label"> (max. 500 chars)</span>
                </Form.Label>
                <GVDSFormField
                  maxLength={500}
                  className="task_name_input"
                  value={inputTask.name}
                  onInput={(value) => onTaskValueChange("name", value)}
                  statusMetadata={
                    isValidated && !isTaskNameValid()
                      ? FormFieldStatusMetadata.getError(
                          "Give your task a name."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <GVFormGroup controlId="status">
                <Form.Label>
                  {t("landing-page.widget-task-mgmt.label-status")}
                </Form.Label>
                <GVDSFormSingleSelect
                  className="select__task-status"
                  placeholder="Select status"
                  value={
                    inputTask.status
                      ? { value: inputTask.status, label: inputTask.status }
                      : null
                  }
                  onSelect={(selectedOption) =>
                    onTaskValueChange("status", selectedOption.value)
                  }
                  options={Object.values(TASK_MANAGEMENT_STATUS).map((s) => {
                    return { value: s, label: s };
                  })}
                />
              </GVFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <GVFormGroup controlId="deadline">
                <Form.Label>
                  {t("landing-page.widget-task-mgmt.label-deadline")}
                </Form.Label>
                <GVDSFormSingleDatePicker
                  className="deadline-input"
                  selected={inputTask.deadline}
                  onChange={(date) => onTaskValueChange("deadline", date)}
                  dateFormat="dd MMM yyyy"
                  minDate={isEditExistingTask ? false : new Date()}
                  statusMetadata={getDeadlineStatusMetadata()}
                />
              </GVFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <GVFormGroup controlId="assignees">
                <Form.Label>
                  {t("landing-page.widget-task-mgmt.label-assign-to")}
                </Form.Label>
                <div className="caption mb-2">
                  {t(
                    "landing-page.widget-task-mgmt.label-assign-to-description"
                  )}
                </div>
                <GVDSFormMultiSelect
                  name="assignees"
                  closeMenuOnSelect={false}
                  isClearable={false}
                  value={inputTask.assignees.map((a) => {
                    return { label: a.name, value: a.id };
                  })}
                  onSelect={(values) => {
                    updateSelectedAssignees(values);
                  }}
                  options={getSelectableAssignees()}
                  className="select-assignee"
                  statusMetadata={
                    isValidated && !isAssigneesValid()
                      ? FormFieldStatusMetadata.getError(
                          "Task has to be assigned."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <GVFormGroup controlId="description">
                <Form.Label>
                  {t("shared-input-label.description")}
                  <span className="optional-form-label">
                    {" "}
                    ({t("shared-input-label.optional")})
                  </span>
                </Form.Label>
                <Editor
                  editorState={inputTask.description}
                  onEditorStateChange={(content) =>
                    onTaskValueChange("description", content)
                  }
                  wrapperClassName="rtf-wrapper"
                  editorClassName="rtf-editor"
                  toolbar={{
                    options: [
                      "blockType",
                      "inline",
                      "list",
                      "link",
                      "remove",
                      "history",
                    ],
                  }}
                />
              </GVFormGroup>
            </Col>
          </Row>
          {isEditExistingTask && (
            <Row>
              <Col sm={12}>
                <GVFormGroup controlId="description">
                  <div className="caption">
                    {t("shared-input-label.created-by")}:{" "}
                    {inputTask.created_by
                      ? UserUtils.getFullNameOrEmail(
                          inputTask.created_by.full_name,
                          inputTask.created_by.email
                        )
                      : ""}
                  </div>
                  <div className="caption">
                    {t("shared-input-label.created-on")}:{" "}
                    {inputTask.created_on
                      ? DateTimeUtils.formatLocalDate(inputTask.created_on)
                      : ""}
                  </div>
                  {userInventory.selectedInventory.get.type ===
                    RESOURCES.PORTFOLIO && (
                    <div className="caption">
                      {t(
                        "landing-page.widget-task-mgmt.label-created-in-resource"
                      )}
                      : {inputTask.created_in}
                    </div>
                  )}
                </GVFormGroup>
              </Col>
            </Row>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <div className="d-flex w-100">
            {isEditExistingTask && (
              <>
                <GVDSButton
                  variant={buttonVariant.tertiary}
                  onClick={archiveTask}
                  disabled={isProcessing}
                  icon={<FontAwesomeIcon icon={faArchive} />}
                  text={t("landing-page.widget-task-mgmt.label-archive")}
                />
                <GVDSButton
                  variant={buttonVariant.destructive_tertiary}
                  onClick={() => {
                    setShowDeletePrompt(true);
                    onClose();
                  }}
                  disabled={isProcessing}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  text={t("shared.delete")}
                />
              </>
            )}
            <GVDSButton
              className="ms-auto"
              variant={buttonVariant.tertiary}
              onClick={() => onClose()}
              disabled={isProcessing}
              text={t("shared-modal.footer.cancel")}
            />
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={saveTask}
              disabled={isProcessing}
              text={t("shared-modal.footer.save")}
            />
          </div>
        </GVDSModal.Footer>
      </GVDSModal>
      {getDeletePrompt()}
    </>
  );
};

export default InputTaskModal;
