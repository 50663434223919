import { useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../context/ToastContext";
import UserApprovalRequestService from "../../../services/UserApprovalRequestService";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { UserDetails } from "../../User/ListUsersView";
import { RESOURCES } from "../../../config/constants";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import {
  getViewContractDetailsPath,
  getViewSiteDetailsPath,
} from "../../../config/ROUTES_NAME";
import { DateTimeUtils } from "../../../services/UtilsService";
import { UserApprovalRequestStatusBadge } from "../../User/UserStatusBadge";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCheck, IconX } from "@tabler/icons-react";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import LoadingSpinner from "../../common/LoadingSpinner";

const PendingUsersList = ({ onApproveInvite }) => {
  const location = useLocation();
  const toastContext = useContext(ToastContext);

  const [pendingRequests, setPendingRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadPendingUsers = () => {
    setIsLoading(true);
    UserApprovalRequestService.getPendingRequests()
      .then((pendingRequests) => {
        setPendingRequests(pendingRequests);
      })
      .finally(() => setIsLoading(false));
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(pendingRequests, null);

  useEffect(() => {
    loadPendingUsers();
  }, []);

  const approveRequest = (requestId) => {
    setIsLoading(true);
    UserApprovalRequestService.approveRequest(requestId)
      .then(() => {
        toastContext.addSuccessToast(<span>Request approved.</span>);
        loadPendingUsers();
        onApproveInvite();
      })
      .catch((error) => {
        toastContext.addFailToast(
          <span>Failed to approve request.{error.response.data}</span>
        );
      })
      .finally(() => setIsLoading(false));
  };

  const rejectRequest = (requestId) => {
    setIsLoading(true);
    UserApprovalRequestService.rejectRequest(requestId)
      .then(() => {
        toastContext.addSuccessToast(<span>Request rejected.</span>);
        loadPendingUsers();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to reject request.</span>);
      })
      .finally(() => setIsLoading(false));
  };

  const userDetailsDisplay = (user, req_email) => {
    return (
      <UserDetails
        email={user ? user.email : req_email}
        name={user && user.full_name ? user.full_name : null}
        position={user && user.position ? user.position : null}
      />
    );
  };

  const entityDisplay = (resourceType, resourceId, resourceName) => {
    const link =
      resourceType === RESOURCES.CONTRACT
        ? getRedirectURLWithCurrentParam(
            getViewContractDetailsPath(resourceId),
            location
          )
        : getRedirectURLWithCurrentParam(
            getViewSiteDetailsPath(resourceId),
            location
          );

    return (
      <div className="d-flex flex-column">
        <div>
          <a href={link}>{resourceName}</a>
        </div>
        <div className="caption color-gray56 text-capitalize">
          {resourceType}
        </div>
      </div>
    );
  };

  const columns = [
    {
      header: "Invite Date",
      dataKey: "invite_date",
      sortable: true,
      renderer: (req) => DateTimeUtils.formatUTCDate(req.invite_date),
    },
    {
      header: "User Details",
      dataKey: "email",
      sortable: true,
      renderer: (req) => userDetailsDisplay(req.user, req.email),
    },
    {
      header: "Account Status",
      dataKey: "status",
      sortable: true,
      renderer: (req) => <UserApprovalRequestStatusBadge status={req.status} />,
    },
    {
      header: "Entity",
      dataKey: "resource_id",
      sortable: true,
      renderer: (req) =>
        entityDisplay(req.resource_type, req.resource_id, req.resource_name),
    },
    {
      header: "Role",
      dataKey: "role",
      sortable: true,
      renderer: (req) => req.role.name,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "200px" },
      renderer: (req) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => {
                approveRequest(req.id);
              }}
              disabled={isLoading}
              icon={<GVDSIcon Icon={IconCheck} />}
              tooltipText="Approve"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                rejectRequest(req.id);
              }}
              disabled={isLoading}
              icon={<GVDSIcon Icon={IconX} />}
              tooltipText="Reject"
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <GVDSTableCtrlContainer>
        {pendingRequests.length > 0 ? (
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        ) : null}
      </GVDSTableCtrlContainer>

      {isLoading ? (
        <div style={{ paddingTop: "100px" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          noContent={
            <div className="table__no_content">No pending requests.</div>
          }
        />
      )}
    </>
  );
};

export default PendingUsersList;
