import { convertFromRaw, EditorState } from "draft-js";
import { isEqual, uniqueId } from "lodash";

import {
  BEST_PRACTICE_QUESTION_TYPE,
  new_entry_prefix,
  POSITION,
  SURVEY_QUESTION_TYPE,
  SURVEY_STATUS,
  tobe_deleted_entry_prefix,
} from "../../../config/constants";
import InvitationalEmailInitialContent from "./InvitationalEmailInitialContent.json";
import InstructionInitialContent from "./InstructionInitialContent.json";
import ThankYouInitialContent from "./ThankYouInitialContent.json";
import {
  DateTimeUtils,
  RTFUtils,
  StringUtils,
} from "../../../services/UtilsService";

const MIN_OPTION_NO = 2;
const MIN_CHILD_QN_NO = 2;

const SurveyBestPracticeQuestionStatusEnum = {
  UPDATED: "UPDATED",
  REMOVED: "REMOVED",
};

class SurveyQuestionOptionModel {
  constructor(id = uniqueId(new_entry_prefix), name = "") {
    this.id = id;
    this.name = name;
  }

  isNew = () => {
    return this.id.startsWith(new_entry_prefix);
  };

  isOptionTextEmpty = () => {
    return this.name.length === 0;
  };

  isNewAndEmpty = () => {
    return this.isNew() && this.isOptionTextEmpty();
  };

  isToBeDeleted = () => {
    return this.id.startsWith(tobe_deleted_entry_prefix);
  };

  toRequestPayload = () => {
    return { id: this.id, name: this.name };
  };

  static fromDTO = (optionDTO) => {
    return new SurveyQuestionOptionModel(optionDTO.id, optionDTO.option_text);
  };
}

class SurveyQuestionModel {
  constructor(id = uniqueId(new_entry_prefix)) {
    this.id = id;
    this.questionText = "";
    this.description = "";
    this.questionType = SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE;
    this.options = [
      new SurveyQuestionOptionModel(),
      new SurveyQuestionOptionModel(),
    ];
    this.childQuestions = [];
    this.bestPracticeQuestionId = null;
  }

  isNew = () => {
    return this.id.startsWith(new_entry_prefix);
  };

  isToBeDeleted = () => {
    return this.id.startsWith(tobe_deleted_entry_prefix);
  };

  isBestPracticeQuestion = () => {
    return (
      this.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE ||
      this.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
    );
  };

  isQuestionTextEmpty = () => {
    return this.questionText.length === 0;
  };

  changeQuestionType = (newQuestionType) => {
    if (newQuestionType === SURVEY_QUESTION_TYPE.QUESTION_GROUP) {
      this.getNotDeletedOptions().map((o) => this.deleteOption(o));
      const existingChildQns = this.getNotDeletedChildQns();
      if (existingChildQns.length < MIN_CHILD_QN_NO) {
        for (let i = 0; i < MIN_CHILD_QN_NO - existingChildQns.length; i++) {
          this.addChildQuestion();
        }
      }
    } else if (newQuestionType === SURVEY_QUESTION_TYPE.SHORT_ANSWER) {
      this.getNotDeletedChildQns().map((q) => this.deleteChildQn(q));
      this.getNotDeletedOptions().map((o) => this.deleteOption(o));
    } else if (
      newQuestionType === SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE ||
      newQuestionType === SURVEY_QUESTION_TYPE.CHECKBOXES
    ) {
      this.getNotDeletedChildQns().map((q) => this.deleteChildQn(q));
      const existingOptions = this.getNotDeletedOptions();
      if (existingOptions.length < MIN_OPTION_NO) {
        for (let i = 0; i < MIN_OPTION_NO - existingOptions.length; i++) {
          this.addOption();
        }
      }
    }

    this.questionType = newQuestionType;
  };

  addOption = () => {
    this.options.push(new SurveyQuestionOptionModel());
  };

  getNotDeletedOptions = () => {
    return this.options.filter((o) => !o.isToBeDeleted());
  };

  isAllOptionsEmpty = () => {
    return !this.getNotDeletedOptions().find(
      (option) => !option.isNewAndEmpty()
    );
  };

  populateOptionsYesNo = () => {
    const yes = new SurveyQuestionOptionModel();
    yes.name = "Yes";
    const no = new SurveyQuestionOptionModel();
    no.name = "No";
    const noButPlanned = new SurveyQuestionOptionModel();
    noButPlanned.name = "No, but plan to within the next 12 months";
    const na = new SurveyQuestionOptionModel();
    na.name = "N/A";
    this.getNotDeletedOptions().map((o) => this.deleteOption(o));
    this.options = this.options.concat([yes, no, noButPlanned, na]); // concat as there might be deleted options
  };

  populateOptionsPercent = () => {
    const percent90 = new SurveyQuestionOptionModel();
    percent90.name = "90% +";
    const percent75to89 = new SurveyQuestionOptionModel();
    percent75to89.name = "75-89%";
    const percent50to74 = new SurveyQuestionOptionModel();
    percent50to74.name = "50-74%";
    const percent1to49 = new SurveyQuestionOptionModel();
    percent1to49.name = "1-49%";
    const percent0 = new SurveyQuestionOptionModel();
    percent0.name = "0%";

    this.getNotDeletedOptions().map((o) => this.deleteOption(o));
    this.options = this.options.concat([
      percent90,
      percent75to89,
      percent50to74,
      percent1to49,
      percent0,
    ]);
  };

  moveOptionUp = (option) => {
    const optionIndex = this.options.indexOf(option);
    this.options[optionIndex] = this.options[optionIndex - 1];
    this.options[optionIndex - 1] = option;
  };

  moveOptionDown = (option) => {
    const optionIndex = this.options.indexOf(option);
    this.options[optionIndex] = this.options[optionIndex + 1];
    this.options[optionIndex + 1] = option;
  };

  deleteOption = (option) => {
    if (option.isNew()) {
      this.options.splice(this.options.indexOf(option), 1);
    } else {
      option.id = tobe_deleted_entry_prefix + option.id;
    }
  };

  isOptionsLessThanRequired = () => {
    return this.options.length < MIN_OPTION_NO;
  };

  addChildQuestion = () => {
    this.childQuestions.push(new SurveyQuestionModel());
  };

  getNotDeletedChildQns = () => {
    return this.childQuestions.filter((childQn) => !childQn.isToBeDeleted());
  };

  moveChildQnUp = (childQn) => {
    const childQnIndex = this.childQuestions.indexOf(childQn);
    this.childQuestions[childQnIndex] = this.childQuestions[childQnIndex - 1];
    this.childQuestions[childQnIndex - 1] = childQn;
  };

  moveChildQnDown = (childQn) => {
    const childQnIndex = this.childQuestions.indexOf(childQn);
    this.childQuestions[childQnIndex] = this.childQuestions[childQnIndex + 1];
    this.childQuestions[childQnIndex + 1] = childQn;
  };

  deleteChildQn = (childQn) => {
    if (childQn.isNew()) {
      this.childQuestions.splice(this.childQuestions.indexOf(childQn), 1);
    } else {
      childQn.id = tobe_deleted_entry_prefix + childQn.id;
    }
  };

  isChildQuestionsLessThanRequired = () => {
    return this.childQuestions.length < MIN_CHILD_QN_NO;
  };

  getOptionNames = () => {
    return this.options.map((option) => option.name);
  };

  getDuplicate = () => {
    const surveyQuestionModel = new SurveyQuestionModel();
    surveyQuestionModel.questionText = this.questionText;
    surveyQuestionModel.description = this.description;
    surveyQuestionModel.questionType = this.questionType;
    surveyQuestionModel.options = this.options.map((option) => {
      const surveyOption = new SurveyQuestionOptionModel();
      surveyOption.name = option.name;
      return surveyOption;
    });
    surveyQuestionModel.childQuestions = this.childQuestions.map((question) =>
      question.getDuplicate()
    );
    surveyQuestionModel.bestPracticeQuestionId = this.bestPracticeQuestionId;

    return surveyQuestionModel;
  };

  toRequestPayload = () => {
    const payload = {
      id: this.id,
      question_text: this.questionText,
      description: this.description,
      question_type: this.questionType,
    };

    if (
      this.questionType === SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE ||
      this.questionType === SURVEY_QUESTION_TYPE.CHECKBOXES
    ) {
      payload["options"] = this.options
        .filter((o) => !o.isNewAndEmpty())
        .map((option) => option.toRequestPayload());
    } else if (
      this.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE ||
      this.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
    ) {
      payload["best_practice_question_id"] = this.bestPracticeQuestionId;
      payload["options"] = this.options.map((option) =>
        option.toRequestPayload()
      );
    } else if (this.questionType === SURVEY_QUESTION_TYPE.QUESTION_GROUP) {
      payload["child_questions"] = this.childQuestions.map((child) =>
        child.toRequestPayload()
      );
    }

    return payload;
  };

  static fromDTO(surveyQuestionDTO) {
    const questionModel = new SurveyQuestionModel(surveyQuestionDTO.id);
    questionModel.questionText = surveyQuestionDTO.question_text;
    questionModel.description = surveyQuestionDTO.description;
    questionModel.questionType = surveyQuestionDTO.question_type;

    if (
      questionModel.questionType === SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE ||
      questionModel.questionType === SURVEY_QUESTION_TYPE.CHECKBOXES
    ) {
      questionModel.options = surveyQuestionDTO.options.map((o) =>
        SurveyQuestionOptionModel.fromDTO(o)
      );

      const existingOptionsLength = questionModel.options.length;
      if (existingOptionsLength < MIN_OPTION_NO) {
        for (let i = 0; i < MIN_OPTION_NO - existingOptionsLength; i++) {
          questionModel.addOption();
        }
      }
    } else if (
      questionModel.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE ||
      questionModel.questionType ===
        SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
    ) {
      questionModel.bestPracticeQuestionId =
        surveyQuestionDTO.best_practice_question_id;
      questionModel.options = surveyQuestionDTO.options.map((o) =>
        SurveyQuestionOptionModel.fromDTO(o)
      );
    } else if (
      questionModel.questionType === SURVEY_QUESTION_TYPE.QUESTION_GROUP
    ) {
      questionModel.childQuestions = surveyQuestionDTO.child_questions.map(
        (cq) => SurveyQuestionModel.fromDTO(cq)
      );
    }

    return questionModel;
  }
}

export class SurveyModel {
  constructor(
    id = null,
    name = "",
    createdFor = "",
    status = SURVEY_STATUS.DRAFT,
    description = "",
    deadline = null,
    invitationalEmail = EditorState.createWithContent(
      convertFromRaw(InvitationalEmailInitialContent)
    ),
    instructions = EditorState.createWithContent(
      convertFromRaw(InstructionInitialContent)
    ),
    thankYouMessage = EditorState.createWithContent(
      convertFromRaw(ThankYouInitialContent)
    ),
    questions = [new SurveyQuestionModel()]
  ) {
    this.id = id;
    this.name = name;
    this.originalName = name;
    this.createdFor = createdFor;
    this.status = status;
    this.description = description;
    this.deadline = deadline;
    this.invitationalEmail = invitationalEmail;
    this.instructions = instructions;
    this.thankYouMessage = thankYouMessage;
    this.questions = questions;
  }

  get nonDeletedQuestions() {
    return this.questions.filter((q) => !q.isToBeDeleted());
  }

  isNotDraft() {
    return this.status !== SURVEY_STATUS.DRAFT;
  }

  isOpen() {
    return this.status === SURVEY_STATUS.OPEN;
  }

  isOriginalNameEmpty() {
    return this.originalName.length === 0;
  }

  isNameEmpty() {
    return this.name.length === 0;
  }

  isDescriptionEmpty() {
    return this.description.length === 0;
  }

  isInvitationalEmailEmpty() {
    return RTFUtils.isEmpty(this.invitationalEmail);
  }

  isInstructionsEmpty() {
    return RTFUtils.isEmpty(this.instructions);
  }

  isThankYouMessageEmpty() {
    return RTFUtils.isEmpty(this.thankYouMessage);
  }

  addANewQuestion() {
    this.questions.push(new SurveyQuestionModel());
  }

  deleteQuestion(question) {
    if (question.isNew()) {
      this.questions.splice(this.questions.indexOf(question), 1);
    } else {
      question.id = tobe_deleted_entry_prefix + question.id;
    }
  }

  moveQuestion(questionToBeMoved, questionToMoveTo, position) {
    const questionToBeMovedIndex = this.questions.indexOf(questionToBeMoved);
    const questionToMoveToIndex = this.questions.indexOf(questionToMoveTo);
    let moveToIndex;
    const isQuestionToBeMovedCurrentlyAfterTargetPosition =
      questionToBeMovedIndex > questionToMoveToIndex;
    const isQuestionToBeMovedCurrentlyBeforeTargetPosition =
      questionToBeMovedIndex < questionToMoveToIndex;

    if (questionToBeMovedIndex !== questionToMoveToIndex) {
      if (position === POSITION.BEFORE) {
        if (isQuestionToBeMovedCurrentlyAfterTargetPosition) {
          moveToIndex = questionToMoveToIndex;
        } else {
          moveToIndex = questionToMoveToIndex - 1;
        }
      } else {
        if (isQuestionToBeMovedCurrentlyBeforeTargetPosition) {
          moveToIndex = questionToMoveToIndex;
        } else {
          moveToIndex = questionToMoveToIndex + 1;
        }
      }
      this.questions.splice(
        moveToIndex,
        0,
        this.questions.splice(questionToBeMovedIndex, 1)[0]
      );
    }
  }

  addBestPracticeQuestions(bestPracticeQuestions) {
    bestPracticeQuestions.forEach((q) => {
      const model = new SurveyQuestionModel();
      model.questionType =
        q.questionType === BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE
          ? SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE
          : SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES;
      model.questionText = q.questionText;
      model.description = q.description;
      model.bestPracticeQuestionId = q.id;
      model.options = q.options.map((o) => {
        const surveyOption = new SurveyQuestionOptionModel();
        surveyOption.name = o.name;
        return surveyOption;
      });
      this.questions.push(model);
    });
  }

  getBestPracticeQuestions() {
    return this.questions.filter(
      (q) => q.isBestPracticeQuestion() && !q.isToBeDeleted()
    );
  }

  getBestPracticeQuestionIds() {
    return this.getBestPracticeQuestions().map((q) => q.bestPracticeQuestionId);
  }

  toRequestPayload() {
    return {
      name: this.name,
      description: this.description,
      deadline: this.deadline
        ? DateTimeUtils.getUTCISOString(this.deadline)
        : null,
      invitational_email: this.isInvitationalEmailEmpty()
        ? ""
        : RTFUtils.convertEditorStateToHTML(this.invitationalEmail),
      instructions: this.isInstructionsEmpty()
        ? ""
        : RTFUtils.convertEditorStateToHTML(this.instructions),
      thank_you_message: this.isThankYouMessageEmpty()
        ? ""
        : RTFUtils.convertEditorStateToHTML(this.thankYouMessage),
      questions: this.questions.map((question) => question.toRequestPayload()),
    };
  }

  getDuplicateQuestions() {
    return this.questions.map((question) => {
      return question.getDuplicate();
    });
  }

  getDuplicateModel(latestBestPracticeQns, existingSurveyNames) {
    const copySurveyModel = new SurveyModel(
      null,
      StringUtils.getCopyNameFromCurrentNameAndExistingNames(
        this.name,
        existingSurveyNames
      ),
      this.createdFor,
      SURVEY_STATUS.DRAFT,
      this.description,
      null,
      this.invitationalEmail,
      this.instructions,
      this.thankYouMessage,
      this.getDuplicateQuestions()
    );
    copySurveyModel.updateBestPracticeQns(latestBestPracticeQns);

    return copySurveyModel;
  }

  getUpdatedBestPracticeQns(latestBestPracticeQns) {
    const isBestPracticeQnsIsSame = (
      surveyBestPracticeQn,
      latestBestPracticeQn
    ) =>
      latestBestPracticeQn.id === surveyBestPracticeQn.bestPracticeQuestionId &&
      latestBestPracticeQn.questionText === surveyBestPracticeQn.questionText &&
      isEqual(
        latestBestPracticeQn.options.map((q) => q.name),
        surveyBestPracticeQn.getOptionNames()
      ) &&
      latestBestPracticeQn.description === surveyBestPracticeQn.description;

    const surveyBestPracticeQns = this.getBestPracticeQuestions();

    if (surveyBestPracticeQns.length > 0) {
      const latestBestPracticeQnsMap = latestBestPracticeQns.reduce(
        (accum, question) => {
          accum[question.id] = question;
          return accum;
        },
        {}
      );
      return this.questions
        .map((q, index) => ({ questionNum: index + 1, ...q }))
        .filter((q) => q.isBestPracticeQuestion())
        .reduce((accum, surveyBestPracticeQn) => {
          const latestBestPracticeQn =
            latestBestPracticeQnsMap[
              surveyBestPracticeQn.bestPracticeQuestionId
            ];

          if (latestBestPracticeQn) {
            if (
              !isBestPracticeQnsIsSame(
                surveyBestPracticeQn,
                latestBestPracticeQn
              )
            ) {
              accum.push({
                questionNum: surveyBestPracticeQn.questionNum,
                questionStatus: SurveyBestPracticeQuestionStatusEnum.UPDATED,
                questionText: surveyBestPracticeQn.questionText,
              });
            }
          } else {
            accum.push({
              questionNum: surveyBestPracticeQn.questionNum,
              questionStatus: SurveyBestPracticeQuestionStatusEnum.REMOVED,
              questionText: surveyBestPracticeQn.questionText,
            });
          }
          return accum;
        }, []);
    }
    return [];
  }

  updateBestPracticeQns(latestBestPracticeQns) {
    this.questions.forEach((q) => {
      if (q.isBestPracticeQuestion()) {
        const latestBestPracticeQn = latestBestPracticeQns.find(
          (bestPracticeQuestion) =>
            bestPracticeQuestion.id === q.bestPracticeQuestionId
        );
        if (latestBestPracticeQn) {
          q.questionType =
            latestBestPracticeQn.questionType ===
            BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE
              ? SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE
              : SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES;
          q.questionText = latestBestPracticeQn.questionText;
          q.description = latestBestPracticeQn.description;
          q.bestPracticeQuestionId = latestBestPracticeQn.id;
          q.options = latestBestPracticeQn.options.map((o) => {
            const surveyOption = new SurveyQuestionOptionModel();
            surveyOption.name = o.name;
            return surveyOption;
          });
        } else {
          q.bestPracticeQuestionId = null;
          q.questionType =
            q.questionType ===
            SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE
              ? SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE
              : SURVEY_QUESTION_TYPE.CHECKBOXES;
        }
      }
    });
  }

  static fromDTO(surveyDetailDTO) {
    const surveyModel = new SurveyModel(
      surveyDetailDTO.id,
      surveyDetailDTO.name,
      surveyDetailDTO.created_for,
      surveyDetailDTO.status,
      surveyDetailDTO.description,
      surveyDetailDTO.deadline
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            surveyDetailDTO.deadline
          )
        : null,
      RTFUtils.convertHTMLToEditorState(surveyDetailDTO.invitational_email),
      RTFUtils.convertHTMLToEditorState(surveyDetailDTO.instructions),
      RTFUtils.convertHTMLToEditorState(surveyDetailDTO.thank_you_message)
    );

    surveyModel.questions = surveyDetailDTO.questions.map((q) =>
      SurveyQuestionModel.fromDTO(q)
    );
    return surveyModel;
  }
}
