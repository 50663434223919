import React from "react";
import GVDSFormMultiSelect from "../../../gvds-components/Forms/GVDSFormMultiSelect";
import { VARIANT_BY_RESOURCE_TYPE } from "../../../gvds-components/common/GVDSTag";

const UserManagedTagPicker = ({
  resourceType,
  selectedTags,
  onChange,
  allTags,
  disabled = false,
  className,
  ...props
}) => {
  return (
    <GVDSFormMultiSelect
      disabled={disabled}
      value={selectedTags.map((tag) => {
        return {
          label: tag.name,
          value: tag.id,
          variant: VARIANT_BY_RESOURCE_TYPE[resourceType],
        };
      })}
      onSelect={onChange}
      options={allTags
        .sort((tag1, tag2) => {
          return tag1.name.toLowerCase().localeCompare(tag2.name.toLowerCase());
        })
        .map((tag) => ({
          label: tag.name,
          value: tag.id,
          resourceType: resourceType,
          variant: VARIANT_BY_RESOURCE_TYPE[resourceType],
        }))}
      noOptionsMessage="No options available"
      className={`user-managed-tag-multi-select ${className ?? ""}`}
      {...props}
    />
  );
};

/*
 To be used with `GenericUserManagedTagModel`
 */
export const GenericUserManagedTagPicker = ({
  selectedTags,
  onChange,
  allGenericUserManagedTagModels,
  disabled = false,
}) => {
  return (
    <GVDSFormMultiSelect
      disabled={disabled}
      value={selectedTags.map((tag) => {
        return {
          label: tag.name,
          value: tag.id,
          resourceType: tag.resourceType,
          variant: VARIANT_BY_RESOURCE_TYPE[tag.resourceType],
        };
      })}
      onSelect={(selected) => {
        onChange(
          selected.map((o) => ({
            id: o.value,
            name: o.label,
            resourceType: o.resourceType,
            variant: o.color,
          }))
        );
      }}
      options={allGenericUserManagedTagModels
        .sort((tag1, tag2) => {
          return tag1.name.toLowerCase().localeCompare(tag2.name.toLowerCase());
        })
        .map((tag) => ({
          label: tag.name,
          value: tag.id,
          resourceType: tag.resourceType,
          variant: VARIANT_BY_RESOURCE_TYPE[tag.resourceType],
        }))}
      noOptionsMessage="No options available"
      className="user-managed-tag-multi-select"
    />
  );
};

export default UserManagedTagPicker;
