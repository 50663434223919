import React from "react";
import { SITE_STATUS } from "../../config/constants";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../services/UtilsService";

const statusToColorMap = {
  [SITE_STATUS.ACTIVE]: StatusLabel.Colors.green,
  [SITE_STATUS.INACTIVE]: StatusLabel.Colors.red,
};

const SiteStatusBadge = ({ status }) => {
  return (
    <StatusLabel color={statusToColorMap[status]}>
      {StringUtils.capitaliseWord(status)}
    </StatusLabel>
  );
};

export default SiteStatusBadge;
