import axios from "axios";
import { API_URL } from "../config/api-config";
import { tobe_deleted_entry_prefix } from "../config/constants";
import { FileAttachmentItemModel } from "../components/common/FileAttachments";
import { BestPracticeQuestionListItemModel } from "./BestPracticeAdminService";

export class GuidanceArticleListItemModel {
  constructor(articleListItem) {
    this.id = articleListItem.id;
    this.title = articleListItem.title;
    this.categories = articleListItem.categories;
    this.tags = articleListItem.tags;
    this.status = articleListItem.status;
    this.unSdgs = articleListItem.un_sdgs;
    this.siteTypes = articleListItem.site_types;
    this.lastEditedOn = articleListItem.last_edited_on;
    this.contentType = articleListItem.content_type;
    this.searchString = `[${this.tags
      .map((sdg) => sdg.name)
      .join(", ")}] [${this.unSdgs.map((sdg) => sdg.name).join(", ")}]`;
  }

  get category_names() {
    return this.categories.map((category) => category.name);
  }

  get tag_names() {
    return this.tags.map((tag) => tag.name);
  }

  get un_sdg_names() {
    return this.unSdgs.map((sdg) => sdg.name);
  }

  get site_type_names() {
    return this.siteTypes.map((siteType) => siteType.name);
  }

  get contentTypeName() {
    return this.contentType !== null ? this.contentType.name : null;
  }
}

export default class GuidanceArticleAdminService {
  static getGuidanceArticleOptions = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/guidance_article_options`
      );
      const articleOptions = response.data;
      articleOptions.best_practices_questions =
        articleOptions.best_practices_questions.map(
          (q) => new BestPracticeQuestionListItemModel(q)
        );
      return articleOptions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static createGuidanceArticle = async (
    title,
    categories,
    tags,
    site_types,
    un_sdgs,
    article_type,
    contentFileAttachmentItemModel,
    downloadable_resources,
    linked_question_ids,
    relevant_article_ids,
    content_type
  ) => {
    try {
      const postBody = new FormData();
      const data = {
        title,
        categories,
        tags,
        site_types,
        un_sdgs,
        article_type,
        linked_question_ids,
        relevant_article_ids,
        content_type,
      };

      if (contentFileAttachmentItemModel.isNew()) {
        postBody.append(
          "guidance_article_content_file",
          contentFileAttachmentItemModel.file
        );
      }

      downloadable_resources.forEach((fileAttachmentItemModel) => {
        if (fileAttachmentItemModel.isNew()) {
          postBody.append(
            "downloadable_resource",
            fileAttachmentItemModel.file
          );
        }
      });

      postBody.append("data", JSON.stringify(data));
      const response = await axios.post(
        `${API_URL}/api/v1/admin/guidance_articles`,
        postBody
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editGuidanceArticle = async (
    guidanceArticleId,
    title,
    categories,
    tags,
    site_types,
    un_sdgs,
    article_type,
    newContentFileAttachmentItemModel,
    downloadable_resources,
    linked_question_ids,
    relevant_article_ids,
    content_type
  ) => {
    try {
      const postBody = new FormData();
      const data = {
        title,
        categories,
        tags,
        site_types,
        un_sdgs,
        article_type,
        removed_downloadable_resources_files: [],
        linked_question_ids,
        relevant_article_ids,
        content_type,
      };

      if (newContentFileAttachmentItemModel) {
        postBody.append(
          "guidance_article_content_file",
          newContentFileAttachmentItemModel.file
        );
      }

      downloadable_resources.forEach((fileAttachmentItemModel) => {
        if (fileAttachmentItemModel.isNew()) {
          postBody.append(
            "downloadable_resource",
            fileAttachmentItemModel.file
          );
        } else if (fileAttachmentItemModel.isToBeDeleted()) {
          data.removed_downloadable_resources_files.push(
            fileAttachmentItemModel.id.slice(tobe_deleted_entry_prefix.length)
          );
        }
      });

      postBody.append("data", JSON.stringify(data));
      const response = await axios.put(
        `${API_URL}/api/v1/admin/guidance_articles/${guidanceArticleId}`,
        postBody
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getGuidanceArticle = async (guidanceArticleId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/guidance_articles/${guidanceArticleId}`
      );

      const article = response.data;

      if (article.content_file) {
        article.content_file = FileAttachmentItemModel.fromFileDTO(
          article.content_file
        );
      }

      article.attached_files = article.attached_files.map((file) =>
        FileAttachmentItemModel.fromFileDTO(file)
      );

      article.linked_questions = article.linked_questions.map(
        (q) => new BestPracticeQuestionListItemModel(q)
      );

      return article;
    } catch (error) {
      throw error;
    }
  };

  static deleteGuidanceArticle = async (guidanceArticleId) => {
    try {
      await axios.delete(
        `${API_URL}/api/v1/admin/guidance_articles/${guidanceArticleId}`
      );
    } catch (error) {
      throw error;
    }
  };

  static getAllGuidanceArticles = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/guidance_articles`
      );
      return response.data.map((a) => new GuidanceArticleListItemModel(a));
    } catch (error) {
      throw error;
    }
  };

  static changeArticleStatus = async (article_id, status) => {
    try {
      const data = {
        status,
      };
      const response = await axios.put(
        `${API_URL}/api/v1/admin/guidance_articles/${article_id}/status`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
