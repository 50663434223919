import moment from "moment";

export const getDefaultMonthRangeStart = () =>
  moment().startOf("day").subtract(12, "months").startOf("month").toDate();
export const getDefaultMonthRangeEnd = () =>
  moment().startOf("day").endOf("month").toDate();

export const getDefaultDateRangeStart = () =>
  moment().startOf("day").subtract(12, "months").toDate();
export const getDefaultDateRangeEnd = () => moment().endOf("day").toDate();
