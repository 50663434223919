import _ from "lodash";
import { HIDE_TARGET_EMISSION_TEXT, SOCIAL_PERFORMANCE_GROUP_ENUM } from "../../config/constants";

export default class TargetConfigParamsModel {
  static getOperationUnitMap(params, config) {
    return _.chain(config)
      .get("area_types", [])
      .find((s) => s["name"] === params["intensity_metric"])
      .get("units", [])
      .sortBy("name")
      .reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
      .value();
  }

  static getDefaultUnit(performanceGroupName, config) {
    const useCurrencyUnit =
      [
        SOCIAL_PERFORMANCE_GROUP_ENUM.CASH_CONTRIBUTION,
        SOCIAL_PERFORMANCE_GROUP_ENUM.IN_KIND_DONATION_VALUE
      ].indexOf(performanceGroupName) >= 0;
    return useCurrencyUnit ? config["default_currency_id"] : "";
  }

  static getUseLocationBasedForPerformanceGroup(
    performanceGroupName,
    params,
    config
  ) {
    if(!TargetConfigParamsModel.isSubtopicEnabledForMarketBasedEmissions(config, params)){
      return HIDE_TARGET_EMISSION_TEXT
    } else {
      return !TargetConfigParamsModel.isPerformanceGroupEnabledForMarketBasedEmissions(performanceGroupName, config)
    }
  }

  static isSubtopicEnabledForMarketBasedEmissions(config, params) {
    return ((config["market_purchase_offset_enabled_config"]["subtopics"].some((s) => params["subtopic_name"] === s["name"])) ||
      (config["custom_ef_supported_config"]["subtopics"].some((s) => params["subtopic_name"] === s["name"])));
  }

  static isPerformanceGroupEnabledForMarketBasedEmissions(performanceGroupName, config) {
    return ((config["market_purchase_offset_enabled_config"]["performance_groups"].some((p) => performanceGroupName === p["name"])) ||
      (config["custom_ef_supported_config"]["performance_groups"].some((p) => performanceGroupName === p["name"])));
  }

  static isUsingPercentageAsUnit(performanceGroupName, config) {
    const renewableRateGroups = config["renewables_rate_groups"] || [];
    return (
      performanceGroupName === config["waste_diversion_rate_name"] ||
      renewableRateGroups.includes(performanceGroupName)
    );
  }
}
