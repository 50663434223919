import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import GVDSIconCustom from "../../../gvds-components/Icons/GVDSIconCustom";

const BaseWidgetHeadingTooltip = ({ info }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover>
          <Popover.Body>{info}</Popover.Body>
        </Popover>
      }
    >
      <GVDSIconCustom.Info className="base-widget__info-icon" />
    </OverlayTrigger>
  );
};

export default BaseWidgetHeadingTooltip;
