import React, { useContext } from "react";
import AnnouncementBarContext from "./AnnouncementBarContext";
import { IconX } from "@tabler/icons-react";
import GVDSIcon from "../Icons/GVDSIcon";

const AnnouncementBar = () => {
  const announcementBarContext = useContext(AnnouncementBarContext);

  if (!announcementBarContext.show) {
    return null;
  }

  return (
    <div className="gvds-announcement-bar">
      <div className="gvds-announcement-bar__content">
        <div className="gvds-announcement-bar__message">
          {announcementBarContext.message}
        </div>
        {announcementBarContext.cta && (
          <div
            className="gvds-announcement-bar__cta"
            onClick={announcementBarContext.ctaAction}
          >
            {announcementBarContext.cta}
          </div>
        )}
      </div>
      {announcementBarContext.isDismissible && (
        <div
          className="gvds-announcement-bar__close"
          onClick={announcementBarContext.dismiss}
        >
          <GVDSIcon Icon={IconX} />
        </div>
      )}
    </div>
  );
};

export default AnnouncementBar;
