import React from "react";
import Button from "react-bootstrap/Button";

const GVDSTextButton = React.forwardRef(
  (
    {
      text = "",
      disabled,
      icon = null,
      onClick,
      className,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        variant="link"
        className={`gvds-text-button ${className ?? ""}`}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {children ? (
          children
        ) : (
          <>
            {icon}
            {text}
          </>
        )}
      </Button>
    );
  }
);

export default GVDSTextButton;
