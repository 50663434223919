import React, { useContext, useEffect, useRef, useState } from "react";
import { clone, uniqueId } from "lodash";
import moment from "moment";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { components } from "react-select";

import InfoTooltip from "../common/Tooltip/InfoTooltip";
import {
  new_entry_prefix,
  tobe_deleted_entry_prefix,
} from "../../config/constants";
import CommentTooltip from "../common/Tooltip/CommentTooltip";
import { DateTimeUtils, NumberService } from "../../services/UtilsService";
import GVFormGroup from "../common/GVFormGroup";
import { getOptionByValueFromIdName } from "../common/Forms/SingleSelect";
import { LoadingSpinnerInline } from "../common/LoadingSpinner";
import GeocodingService from "../../services/GeolocationService";
import ToastContext from "../../context/ToastContext";
import UnsavedChangePromptModal from "../common/UnsavedChangePromptModal";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleDatePicker from "../../gvds-components/Forms/GVDSFormSingleDatePicker";
import GVDSFormSingleMonthPicker from "../../gvds-components/Forms/GVDSFormSingleMonthPicker";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import {
  IconCirclePlus,
  IconEdit,
  IconStarFilled,
  IconStarHalfFilled,
} from "@tabler/icons-react";
import GVDSTableDisplay from "../../gvds-components/Table/GVDSTableDisplay";
import { useTranslation } from "react-i18next";

const WarningOnModal = () => {
  const { t } = useTranslation();

  return (
    <div className="gvds-text--modal-warning-text">
      {t("admin.site-details.modal-warning-changes-not-saved")}
    </div>
  );
};

export const deep_field_separator = "__";
export const type_hotel = "hotel / accommodation"; // TODO replace with TYPE_HOTEL_ALL_LOWER
export const empty_id_name = { id: "", name: "" };
export const expedia_star_option = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
export const DropdownOptions = ({
  label,
  placeholder = `Search for ${label}`,
  inEditMode,
  targetName,
  value,
  onChange,
  options,
  isInvalid = false,
  invalidMessage = "Field cannot be empty",
  optionSortFn = (o1, o2) => o1.name.localeCompare(o2.name),
}) => {
  return (
    <GVFormGroup>
      <Form.Label>{label}</Form.Label>
      {inEditMode ? (
        <GVDSFormSingleSelect
          className={targetName}
          placeholder={placeholder}
          isSearchable={true}
          options={options.sort(optionSortFn).map((option) => {
            return { value: option.id, label: option.name };
          })}
          value={
            value && value.id
              ? {
                  value: value.id,
                  label: value.name,
                }
              : null
          }
          onSelect={(selected) => {
            onChange(targetName, selected.value, selected.label);
          }}
          statusMetadata={
            isInvalid
              ? FormFieldStatusMetadata.getError(invalidMessage)
              : FormFieldStatusMetadata.getDefault()
          }
        />
      ) : (
        <Form.Control
          readOnly
          plaintext
          type="text"
          name={targetName}
          value={value && value.name ? value.name : ""}
        />
      )}
    </GVFormGroup>
  );
};

export const StarDisplay = ({ value }) => {
  if (!value) {
    return null;
  }

  let display = [];

  for (let i = 0; i < Math.floor(value); i++) {
    display.push(
      <GVDSIcon key={i} Icon={IconStarFilled} className="star-display" />
    );
  }

  if (!Number.isInteger(value)) {
    display.push(
      <GVDSIcon key={0.5} Icon={IconStarHalfFilled} className="star-display" />
    );
  }

  return <>{display}</>;
};

export const StarSelected = (props) => {
  return (
    <components.SingleValue {...props}>
      <StarDisplay value={props.data.value} />
    </components.SingleValue>
  );
};

export const StarOption = (props) => {
  return (
    <components.Option {...props}>
      <StarDisplay value={props.value} />
    </components.Option>
  );
};

export const StarPicker = ({
  label,
  inEditMode,
  targetName,
  value,
  onChange,
  options,
}) => {
  return (
    <div>
      <Form.Label>{label}</Form.Label>
      {inEditMode ? (
        <GVDSFormSingleSelect
          className="start-picker"
          components={{ Option: StarOption, SingleValue: StarSelected }}
          value={{ value: value, label: value }}
          options={options.map((option) => {
            return { value: option, label: option };
          })}
          onSelect={(newSelect) => onChange(targetName, newSelect.value)}
        />
      ) : (
        <div className="form-select-styling">
          {value ? (
            <StarDisplay value={value} />
          ) : (
            <Form.Control readOnly plaintext type="text" value="" />
          )}
        </div>
      )}
    </div>
  );
};

export class Area {
  constructor(
    id = uniqueId(new_entry_prefix),
    record_month = null,
    gfa_built = null,
    gfa_conditioned_space = null,
    gfa_guestroom = null,
    gfa_meeting = null,
    unit = empty_id_name,
    comment = null
  ) {
    this.id = id;
    this.record_month = record_month;
    this.gfa_built = gfa_built;
    this.gfa_conditioned_space = gfa_conditioned_space;
    this.gfa_guestroom = gfa_guestroom;
    this.gfa_meeting = gfa_meeting;
    this.unit = unit;
    this.comment = comment;
  }

  isNew() {
    return this.id.startsWith(new_entry_prefix);
  }

  isToBeDeleted() {
    return this.id.startsWith(tobe_deleted_entry_prefix);
  }

  getUsedSpace() {
    return this.gfa_guestroom + this.gfa_meeting;
  }

  getPublicArea() {
    if (this.gfa_conditioned_space !== null) {
      return (
        this.gfa_conditioned_space -
        (this.gfa_guestroom || 0) -
        (this.gfa_meeting || 0)
      );
    } else {
      return "-";
    }
  }

  hasNoData() {
    return (
      this.record_month === null &&
      this.gfa_built === null &&
      this.gfa_conditioned_space === null &&
      this.gfa_guestroom === null &&
      this.gfa_meeting === null
    );
  }

  hasPeriod() {
    return this.record_month !== null;
  }

  isConditionedSpaceBiggerThanBuiltArea() {
    return this.gfa_conditioned_space > this.gfa_built;
  }

  isUsedSpaceBiggerThanConditionedSpace() {
    return this.getUsedSpace() > this.gfa_conditioned_space;
  }

  isGrossFloorAreaNegative() {
    return this.gfa_built < 0;
  }

  isConditionedSpaceNegative() {
    return this.gfa_conditioned_space < 0;
  }

  isGuestroomSpaceNegative() {
    return this.gfa_guestroom < 0;
  }

  isMeetingSpaceNegative() {
    return this.gfa_meeting < 0;
  }

  isValid() {
    return (
      this.hasPeriod() &&
      !this.isConditionedSpaceBiggerThanBuiltArea() &&
      !this.isUsedSpaceBiggerThanConditionedSpace() &&
      !this.isGrossFloorAreaNegative() &&
      !this.isConditionedSpaceNegative() &&
      !this.isGuestroomSpaceNegative() &&
      !this.isMeetingSpaceNegative()
    );
  }

  getConditionedSpaceErrorMessage(t) {
    if (this.isConditionedSpaceBiggerThanBuiltArea()) {
      return t(
        "admin.site-details.floor-area.validation-error-message.conditioned-space-larger-than-built"
      );
    } else if (this.isConditionedSpaceNegative()) {
      return t(
        "admin.site-details.floor-area.validation-error-message.conditioned-space-negative"
      );
    } else {
      return "";
    }
  }

  getGuestroomSpaceErrorMessage(t) {
    if (this.isUsedSpaceBiggerThanConditionedSpace()) {
      return t(
        "admin.site-details.floor-area.validation-error-message.used-area-bigger-than-conditioned"
      );
    } else if (this.isGuestroomSpaceNegative()) {
      return t(
        "admin.site-details.floor-area.validation-error-message.guest-rooms-corridors-negative"
      );
    } else {
      return "";
    }
  }

  getMeetingSpaceErrorMessage(t) {
    if (this.isUsedSpaceBiggerThanConditionedSpace()) {
      return t(
        "admin.site-details.floor-area.validation-error-message.used-area-bigger-than-conditioned"
      );
    } else if (this.isMeetingSpaceNegative()) {
      return t(
        "admin.site-details.floor-area.validation-error-message.meeting-function-space-negative"
      );
    } else {
      return "";
    }
  }
}

const GrossFloorAreaTooltip = () => {
  const { t } = useTranslation();

  return (
    <InfoTooltip
      info={t("admin.site-details.floor-area.tooltip-gross-floor-area")}
    />
  );
};

const GfaConditionedSpaceTooltip = () => {
  const { t } = useTranslation();

  return (
    <InfoTooltip
      info={t("admin.site-details.floor-area.tooltip-conditioned-space")}
    />
  );
};

const PublicBackHouseAreaTooltip = () => {
  const { t } = useTranslation();

  return (
    <InfoTooltip
      info={t("admin.site-details.floor-area.tooltip-public-back-house-area")}
    />
  );
};

const formatFloorAreaForTableView = (value) => {
  if (value === null) {
    return "";
  }

  return NumberService.format(value, 6);
};

const checkIfInputRecordMonthExists = (areas, inputRecordMonth) => {
  const inputAreaDate = new Date(inputRecordMonth);
  const inputAreaMonth = inputAreaDate.getMonth() + 1;
  const inputAreaYear = inputAreaDate.getFullYear();

  return areas.some((area) => {
    const existingAreaDate = new Date(area.record_month);
    const existingAreaMonth = existingAreaDate.getMonth() + 1;
    const existingAreaYear = existingAreaDate.getFullYear();
    return (
      existingAreaMonth === inputAreaMonth && existingAreaYear === inputAreaYear
    );
  });
};

export const FloorArea = ({
  isHotel,
  inEditMode,
  areaUnits,
  areas,
  onAreaChange,
}) => {
  const { t } = useTranslation();

  const [showInputModal, setShowInputModal] = useState(false);
  const [inputAreaModel, setInputAreaModel] = useState(new Area());
  const [isEditingArea, setIsEditingArea] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [areaIdToBeDeleted, setAreaIdToBeDeleted] = useState(null);

  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedChangePromptModal, setShowUnsavedChangePromptModal] =
    useState(false);

  const handleCloseModal = () => {
    setShowInputModal(false);
    if (hasChanges) {
      setShowUnsavedChangePromptModal(true);
    } else {
      resetForm();
    }
  };

  const handleCloseDeleteModal = () => {
    setAreaIdToBeDeleted(null);
    setShowDeleteModal(false);
  };

  const onCancelUnsavedPrompt = () => {
    setShowUnsavedChangePromptModal(false);
    setShowInputModal(true);
  };

  const closeModalWithoutPrompt = () => {
    setIsValidated(false);
    setShowUnsavedChangePromptModal(false);
    setShowInputModal(false);
    resetForm();
  };

  const resetForm = () => {
    setInputAreaModel(new Area());
    setHasChanges(false);
  };

  const promptNewEntry = () => {
    setIsEditingArea(false);
    resetForm();
    setShowInputModal(true);
  };

  const promptEditArea = (area) => {
    setIsEditingArea(true);
    setInputAreaModel(clone(area));
    setShowInputModal(true);
  };

  const saveArea = () => {
    setIsValidated(true);

    if (!inputAreaModel.isValid()) {
      return;
    }

    const existingAreaNotEdited = areas.filter(
      (area) => area.id !== inputAreaModel.id
    );

    if (
      !checkIfInputRecordMonthExists(
        existingAreaNotEdited,
        inputAreaModel.record_month
      )
    ) {
      existingAreaNotEdited.push(inputAreaModel);
      onAreaChange(existingAreaNotEdited);
      closeModalWithoutPrompt();
    }
  };

  const onChange = (area) => {
    setHasChanges(true);
    setInputAreaModel(clone(area));
  };

  const changeAreaRecordMonth = (area, newRecordMonth) => {
    area.record_month = newRecordMonth;
    onChange(area);
  };

  const changeAreaValue = (event, area) => {
    const areaTypeUpdated = event.target.name;

    const inputValue = event.target.value;

    if (inputValue === "") {
      area[areaTypeUpdated] = null;
    } else {
      area[areaTypeUpdated] = parseFloat(inputValue);
    }

    onChange(area);
  };

  const changeAreaUnit = (selectedOption, area) => {
    const newAreaUnitId = selectedOption.value;
    const newAreaName = selectedOption.label;

    area.unit = { id: newAreaUnitId, name: newAreaName };

    onChange(area);
  };

  const changeComment = (event, area) => {
    area.comment = event.target.value;
    onChange(area);
  };

  const deleteSelectedArea = () => {
    if (areaIdToBeDeleted.startsWith(new_entry_prefix)) {
      areas.splice(areas.map((a) => a.id).indexOf(areaIdToBeDeleted), 1);
    } else {
      const areaToBeDeleted = areas.find((a) => a.id === areaIdToBeDeleted);
      areaToBeDeleted.id = tobe_deleted_entry_prefix + areaToBeDeleted.id;
    }
    onAreaChange([...areas]);
    handleCloseDeleteModal();
  };

  const notDeletedAreas = areas
    .filter((a) => !a.isToBeDeleted())
    .sort((a, b) => (moment(a.record_month).isBefore(b.record_month) ? -1 : 1));

  const isEmptyAreas = notDeletedAreas.length === 0;

  const getRecordMonthStatusMetadata = () => {
    if (!isValidated) {
      return FormFieldStatusMetadata.getDefault();
    }

    if (!inputAreaModel.hasPeriod()) {
      return FormFieldStatusMetadata.getError(
        t(
          "admin.site-details.floor-area.validation-error-message.empty-required-month-year"
        )
      );
    }

    let existingAreaNotEdited = [...areas];

    if (isEditingArea) {
      existingAreaNotEdited = existingAreaNotEdited.filter(
        (area) => area.id !== inputAreaModel.id
      );
    }

    if (
      checkIfInputRecordMonthExists(
        existingAreaNotEdited,
        inputAreaModel.record_month
      )
    ) {
      return FormFieldStatusMetadata.getError(
        t(
          "admin.site-details.floor-area.validation-error-message.floor-area-same-period"
        )
      );
    }

    return FormFieldStatusMetadata.getDefault();
  };

  return (
    <>
      {isEmptyAreas ? (
        <div className="gvds-empty-content">
          <div>{t("admin.site-details.floor-area.no-content")}</div>
          {inEditMode && (
            <div className="mt-2">
              <GVDSButton
                variant={buttonVariant.secondary}
                className="add-floor-area-details-button"
                onClick={promptNewEntry}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text={t("admin.site-details.floor-area.button-add-area")}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <GVDSTableDisplay>
            <thead>
              <tr>
                <th>{t("admin.site-details.floor-area.labels.month-year")}</th>
                <th>
                  {t("admin.site-details.floor-area.labels.gross-floor-area")}{" "}
                  <GrossFloorAreaTooltip />
                </th>
                {isHotel && (
                  <>
                    <th>
                      {t(
                        "admin.site-details.floor-area.labels.conditioned-space"
                      )}{" "}
                      <GfaConditionedSpaceTooltip />
                    </th>
                    <th>
                      {t(
                        "admin.site-details.floor-area.labels.guest-rooms-corridors"
                      )}
                    </th>
                    <th>
                      {t(
                        "admin.site-details.floor-area.labels.meeting-function-space"
                      )}
                    </th>
                    <th>
                      {t(
                        "admin.site-details.floor-area.labels.public-back-house"
                      )}{" "}
                      <PublicBackHouseAreaTooltip />
                    </th>
                  </>
                )}
                <th style={{ width: "50px" }}>
                  {t("shared-input-label.unit")}
                </th>
                <th style={{ width: "50px" }}>
                  {t("shared-input-label.comments")}
                </th>
                {inEditMode && <th>{t("shared-input-label.actions")}</th>}
              </tr>
            </thead>
            <tbody>
              {notDeletedAreas.map((area) => {
                return (
                  <tr key={area.id}>
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="record_month"
                        value={moment(area.record_month).format("MMM YYYY")}
                      />
                    </td>
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        name="gfa_built"
                        value={formatFloorAreaForTableView(area.gfa_built)}
                      />
                    </td>
                    {isHotel && (
                      <>
                        <td>
                          <Form.Control
                            readOnly
                            plaintext
                            name="gfa_conditioned_space"
                            value={formatFloorAreaForTableView(
                              area.gfa_conditioned_space
                            )}
                            isInvalid={area.isConditionedSpaceBiggerThanBuiltArea()}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            plaintext
                            name="gfa_guestroom"
                            value={formatFloorAreaForTableView(
                              area.gfa_guestroom
                            )}
                            isInvalid={area.isUsedSpaceBiggerThanConditionedSpace()}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            plaintext
                            name="gfa_meeting"
                            value={formatFloorAreaForTableView(
                              area.gfa_meeting
                            )}
                            isInvalid={area.isUsedSpaceBiggerThanConditionedSpace()}
                          />
                        </td>
                        <td>
                          <Form.Control
                            readOnly
                            plaintext
                            name="public_area"
                            value={formatFloorAreaForTableView(
                              area.getPublicArea()
                            )}
                          />
                        </td>
                      </>
                    )}
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="unit"
                        value={area.unit.id}
                      />
                    </td>
                    <td>
                      <div className="comment-tooltip-container-on-form-row">
                        {area.comment && (
                          <CommentTooltip comment={area.comment} />
                        )}
                      </div>
                    </td>
                    {inEditMode && (
                      <td>
                        <div className="container-for-item-in-form-table">
                          <GVDSIconButton
                            variant={iconButtonVariant.tertiary}
                            onClick={(e) => {
                              e.preventDefault();
                              promptEditArea(area);
                            }}
                            icon={<GVDSIcon Icon={IconEdit} />}
                            tooltipText={t("shared.edit")}
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </GVDSTableDisplay>
          {inEditMode && (
            <div className="mt-1">
              <GVDSButton
                variant={buttonVariant.secondary}
                className="add-floor-area-details-button"
                onClick={promptNewEntry}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text={t("admin.site-details.floor-area.button-add-area")}
              />
            </div>
          )}
        </>
      )}
      <GVDSModal
        show={showInputModal}
        onHide={handleCloseModal}
        title={
          isEditingArea
            ? t("admin.site-details.floor-area.modal-title-edit-area")
            : t("admin.site-details.floor-area.modal-title-add-area")
        }
        size={GVDSModal.Size.medium}
      >
        <GVDSModal.Body>
          <Row>
            <GVFormGroup className="col-lg-4 col-sm-12">
              <Form.Label>
                {t("admin.site-details.floor-area.labels.month-year")}
              </Form.Label>
              <GVDSFormSingleMonthPicker
                className="area-record-month-picker"
                selected={inputAreaModel.record_month}
                onChange={(date) => changeAreaRecordMonth(inputAreaModel, date)}
                maxDate={new Date()}
                statusMetadata={getRecordMonthStatusMetadata()}
              />
            </GVFormGroup>
          </Row>
          <Row className="form-row-spacer">
            <GVFormGroup className="col-lg-4 col-sm-12">
              <Form.Label>{t("shared-input-label.unit")}</Form.Label>
              <GVDSFormSingleSelect
                name="unit"
                placeholder="Select Unit"
                value={
                  inputAreaModel.unit.id
                    ? {
                        value: inputAreaModel.unit.id,
                        label: inputAreaModel.unit.id,
                      }
                    : null
                }
                onSelect={(selectedOption) =>
                  changeAreaUnit(selectedOption, inputAreaModel)
                }
                options={areaUnits?.map((unit) => {
                  return { value: unit.id, label: unit.id };
                })}
              />
            </GVFormGroup>
          </Row>
          <Row className="form-row-spacer">
            <GVFormGroup className="col-lg-4 col-sm-12">
              <Form.Label>
                {t("admin.site-details.floor-area.labels.gross-floor-area")}{" "}
                <GrossFloorAreaTooltip />
              </Form.Label>
              <GVDSFormField
                type="number"
                name="gfa_built"
                value={inputAreaModel.gfa_built}
                onInput={(value, event) =>
                  changeAreaValue(event, inputAreaModel)
                }
                statusMetadata={
                  inputAreaModel.isGrossFloorAreaNegative()
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "admin.site-details.floor-area.validation-error-message.gross-floor-area-negative"
                        )
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Row>
          {isHotel && (
            <>
              <Row className="form-row-spacer">
                <GVFormGroup className="col-lg-4 col-sm-12">
                  <Form.Label>
                    {t(
                      "admin.site-details.floor-area.labels.conditioned-space"
                    )}{" "}
                    <GfaConditionedSpaceTooltip />
                  </Form.Label>
                  <GVDSFormField
                    type="number"
                    name="gfa_conditioned_space"
                    value={inputAreaModel.gfa_conditioned_space}
                    onInput={(value, event) =>
                      changeAreaValue(event, inputAreaModel)
                    }
                    statusMetadata={
                      inputAreaModel.isConditionedSpaceBiggerThanBuiltArea() ||
                      inputAreaModel.isConditionedSpaceNegative()
                        ? FormFieldStatusMetadata.getError(
                            inputAreaModel.getConditionedSpaceErrorMessage(t)
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                  />
                </GVFormGroup>
              </Row>
              <Row className="form-row-spacer">
                <GVFormGroup className="col-lg-4 col-sm-12 align-self-baseline">
                  <Form.Label>
                    {t(
                      "admin.site-details.floor-area.labels.guest-rooms-corridors"
                    )}
                  </Form.Label>
                  <GVDSFormField
                    type="number"
                    name="gfa_guestroom"
                    value={inputAreaModel.gfa_guestroom}
                    onInput={(value, event) =>
                      changeAreaValue(event, inputAreaModel)
                    }
                    statusMetadata={
                      inputAreaModel.isUsedSpaceBiggerThanConditionedSpace() ||
                      inputAreaModel.isGuestroomSpaceNegative()
                        ? FormFieldStatusMetadata.getError(
                            inputAreaModel.getGuestroomSpaceErrorMessage(t)
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                  />
                </GVFormGroup>
                <GVFormGroup className="col-lg-4 col-sm-12 align-self-baseline">
                  <Form.Label>
                    {t(
                      "admin.site-details.floor-area.labels.meeting-function-space"
                    )}
                  </Form.Label>
                  <GVDSFormField
                    type="number"
                    name="gfa_meeting"
                    value={inputAreaModel.gfa_meeting}
                    onInput={(value, event) =>
                      changeAreaValue(event, inputAreaModel)
                    }
                    statusMetadata={
                      inputAreaModel.isUsedSpaceBiggerThanConditionedSpace() ||
                      inputAreaModel.isMeetingSpaceNegative()
                        ? FormFieldStatusMetadata.getError(
                            inputAreaModel.getMeetingSpaceErrorMessage(t)
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                  />
                </GVFormGroup>
                <GVFormGroup className="col-lg-4 col-sm-12 align-self-baseline">
                  <Form.Label>
                    {t(
                      "admin.site-details.floor-area.labels.public-back-house"
                    )}{" "}
                    <PublicBackHouseAreaTooltip />
                  </Form.Label>
                  <Form.Control
                    readOnly
                    plaintext
                    name="public_area"
                    value={NumberService.format(
                      inputAreaModel.getPublicArea(),
                      6
                    )}
                  />
                </GVFormGroup>
              </Row>
            </>
          )}
          <Row className="form-row-spacer">
            <GVFormGroup className="col-sm-12">
              <Form.Label>
                {t("shared-input-label.comments")}{" "}
                <span className="optional-form-label">
                  ({t("shared-input-label.optional")})
                </span>
              </Form.Label>
              <GVDSFormField
                name="comment"
                placeholder="Add a comment about this floor area"
                value={inputAreaModel.comment}
                onInput={(value, event) => changeComment(event, inputAreaModel)}
              />
            </GVFormGroup>
          </Row>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {isEditingArea && (
            <div className="me-auto">
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                onClick={() => {
                  setAreaIdToBeDeleted(inputAreaModel.id);
                  setShowInputModal(false);
                  setShowDeleteModal(true);
                }}
                text={t("shared.delete")}
              />
            </div>
          )}
          <WarningOnModal />
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseModal}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveArea}
            text={isEditingArea ? t("shared.edit") : t("shared.add")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
      <GVDSModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        title={t("admin.site-details.floor-area.modal-title-delete-area")}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          {t("admin.site-details.floor-area.delete-warning")}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <WarningOnModal />
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseDeleteModal}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteSelectedArea}
            text={t("shared-modal.footer.yes-delete")}
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <UnsavedChangePromptModal
        show={showUnsavedChangePromptModal}
        onCancel={onCancelUnsavedPrompt}
        onProceed={closeModalWithoutPrompt}
        modalName={
          isEditingArea
            ? t("admin.site-details.floor-area.modal-title-edit-area")
            : t("admin.site-details.floor-area.modal-title-add-area")
        }
        message={t("shared.unsaved-change-prompt-message")}
      />
    </>
  );
};

export const brandFlagNeedBrandCompanyMesssage =
  "Add a Brand Company to Related Entities first";

export class EntityRelationship {
  constructor(
    id = uniqueId(new_entry_prefix),
    relation_type = empty_id_name,
    company = empty_id_name,
    internal_company_code = null
  ) {
    this.id = id;
    this.relation_type = relation_type;
    this.company = company;
    this.internal_company_code = internal_company_code;
  }

  isNew() {
    return this.id.startsWith(new_entry_prefix);
  }

  isToBeDeleted() {
    return this.id.startsWith(tobe_deleted_entry_prefix);
  }

  has_no_data() {
    return (
      this.relation_type === empty_id_name &&
      this.company === empty_id_name &&
      this.internal_company_code === null
    );
  }
}

export const RelatedEntities = ({
  inEditMode,
  relatedCompanyOptions,
  relatedEntities,
  onRelatedEntitiesChange,
}) => {
  const { t } = useTranslation();

  const [showInputModal, setShowInputModal] = useState(false);
  const handleCloseModal = () => {
    setShowInputModal(false);
    if (hasChanges) {
      setShowUnsavedChangePromptModal(true);
    } else {
      resetForm();
    }
  };

  const [isValidated, setIsValidated] = useState(false);

  const [inputRelatedEntityModel, setInputRelatedEntityModel] = useState(
    new EntityRelationship()
  );
  const [isEditingEntityRelationship, setIsEditingEntityRelationship] =
    useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => {
    setEntityRelationshipIdToBeDeleted(null);
    setShowDeleteModal(false);
  };
  const [entityRelationshipIdToBeDeleted, setEntityRelationshipIdToBeDeleted] =
    useState(null);

  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedChangePromptModal, setShowUnsavedChangePromptModal] =
    useState(false);

  const promptNewEntry = () => {
    setIsEditingEntityRelationship(false);
    setInputRelatedEntityModel(new EntityRelationship());
    setShowInputModal(true);
  };

  const promptEditEntityRelationship = (entityRelationship) => {
    setIsEditingEntityRelationship(true);
    setInputRelatedEntityModel(clone(entityRelationship));
    setShowInputModal(true);
  };

  const onCancelUnsavedPrompt = () => {
    setShowUnsavedChangePromptModal(false);
    setShowInputModal(true);
  };

  const closeModalWithoutPrompt = () => {
    setShowUnsavedChangePromptModal(false);
    setShowInputModal(false);
    resetForm();
  };

  const saveEntityRelationship = () => {
    setIsValidated(true);
    if (!isRelationTypeInvalid() && !isCompanyInvalid()) {
      if (!isEditingEntityRelationship) {
        onRelatedEntitiesChange([...relatedEntities, inputRelatedEntityModel]);
      } else {
        const indexOfRelatedEntityEdited = relatedEntities
          .map((relatedEntity) => relatedEntity.id)
          .indexOf(inputRelatedEntityModel.id);
        relatedEntities[indexOfRelatedEntityEdited] = inputRelatedEntityModel;
        onRelatedEntitiesChange([...relatedEntities]);
      }
      setIsValidated(false);
      closeModalWithoutPrompt();
    }
  };

  const resetForm = () => {
    setInputRelatedEntityModel(new EntityRelationship());
    setHasChanges(false);
  };

  const onChange = (relatedEntity) => {
    setHasChanges(true);
    setInputRelatedEntityModel(clone(relatedEntity));
  };

  const changeInternalCompanyCode = (event, relatedEntity) => {
    const valueUpdated = event.target.name;
    relatedEntity[valueUpdated] = event.target.value;
    onChange(relatedEntity);
  };

  const changeRelationType = (selectedOption, relatedEntity) => {
    const newRelationTypeId = selectedOption.value;
    const newRelationTypeName = selectedOption.label;

    relatedEntity.relation_type = {
      id: newRelationTypeId,
      name: newRelationTypeName,
    };

    onChange(relatedEntity);
  };

  const changeRelatedCompany = (selectedOption, relatedEntity) => {
    const newCompanyId = selectedOption.value;
    const newCompanyName = selectedOption.label;

    relatedEntity.company = { id: newCompanyId, name: newCompanyName };

    onChange(relatedEntity);
  };

  const deleteSelectedEntityRelationship = () => {
    if (entityRelationshipIdToBeDeleted.startsWith(new_entry_prefix)) {
      relatedEntities.splice(
        relatedEntities
          .map((r) => r.id)
          .indexOf(entityRelationshipIdToBeDeleted),
        1
      );
    } else {
      const entityRelationshipToBeDeleted = relatedEntities.find(
        (r) => r.id === entityRelationshipIdToBeDeleted
      );
      entityRelationshipToBeDeleted.id =
        tobe_deleted_entry_prefix + entityRelationshipToBeDeleted.id;
    }
    onRelatedEntitiesChange([...relatedEntities]);
    handleCloseDeleteModal();
  };

  const isRelationTypeInvalid = () => {
    return !inputRelatedEntityModel.relation_type.id;
  };

  const isCompanyInvalid = () => {
    return !inputRelatedEntityModel.company.id;
  };

  const getRelatedCompanyOptionTuples = () => {
    let relatedCompanyOptionTuples = [];
    if (inputRelatedEntityModel.relation_type.id) {
      relatedCompanyOptions
        .find(
          (relation_type) =>
            relation_type.id === inputRelatedEntityModel.relation_type.id
        )
        .companies.map((company) => {
          relatedCompanyOptionTuples.push({
            value: company.id,
            label: company.name,
          });
        });
    }

    return relatedCompanyOptionTuples;
  };

  const getRelatedCompanyOptionByValue = (optionValue) => {
    const options = getRelatedCompanyOptionTuples();
    if (options) {
      const option = options.find((option) => option.value === optionValue);
      return option || null;
    } else {
      return null;
    }
  };

  const notDeletedRelatedEntities = !!relatedEntities
    ? relatedEntities.filter((r) => !r.isToBeDeleted())
    : [];
  const isEmptyRelatedEntities = notDeletedRelatedEntities.length === 0;

  return (
    <>
      {isEmptyRelatedEntities ? (
        <div className="gvds-empty-content">
          <div>{t("admin.site-details.related-entities.no-content")}</div>
          {inEditMode && (
            <div className="mt-2">
              <GVDSButton
                className="add-related-entity-button"
                variant={buttonVariant.secondary}
                onClick={promptNewEntry}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text={t(
                  "admin.site-details.related-entities.button-add-related-entity"
                )}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <GVDSTableDisplay>
            <thead>
              <tr>
                <th>
                  {t("admin.site-details.related-entities.labels.relation")}
                </th>
                <th>
                  {t("admin.site-details.related-entities.labels.company")}
                </th>
                <th>
                  {t("admin.site-details.related-entities.labels.id-code")}
                  <InfoTooltip
                    info={t(
                      "admin.site-details.related-entities.tooltip-id-code"
                    )}
                  />
                </th>
                {inEditMode && <th>{t("shared-input-label.actions")}</th>}
              </tr>
            </thead>
            <tbody>
              {notDeletedRelatedEntities.map((entity) => {
                return (
                  <tr key={entity.id}>
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="relation_type"
                        value={entity.relation_type.name}
                      />
                    </td>
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="status"
                        value={entity.company.name}
                      />
                    </td>
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="internal_company_code"
                        value={entity.internal_company_code || ""}
                      />
                    </td>
                    {inEditMode && (
                      <td>
                        <div className="container-for-item-in-form-table">
                          <GVDSIconButton
                            variant={iconButtonVariant.tertiary}
                            onClick={(e) => {
                              e.preventDefault();
                              promptEditEntityRelationship(entity);
                            }}
                            icon={<GVDSIcon Icon={IconEdit} />}
                            tooltipText={t("shared.edit")}
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </GVDSTableDisplay>
          {inEditMode && (
            <div className="mt-1">
              <GVDSButton
                className="add-related-entity-button"
                variant={buttonVariant.secondary}
                onClick={promptNewEntry}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text={t(
                  "admin.site-details.related-entities.button-add-related-entity"
                )}
              />
            </div>
          )}
        </>
      )}
      <GVDSModal
        show={showInputModal}
        onHide={handleCloseModal}
        title={
          isEditingEntityRelationship
            ? t("admin.site-details.related-entities.modal-title-edit-entity")
            : t("admin.site-details.related-entities.modal-title-add-entity")
        }
        size={GVDSModal.Size.medium}
      >
        <GVDSModal.Body>
          <Row>
            <GVFormGroup className="col-lg-6 col-sm-12">
              <Form.Label>
                {t("admin.site-details.related-entities.labels.relation")}
              </Form.Label>
              <GVDSFormSingleSelect
                name="relation_type"
                placeholder="Select Relation Type"
                value={
                  inputRelatedEntityModel.relation_type.id
                    ? getOptionByValueFromIdName(
                        inputRelatedEntityModel.relation_type.id,
                        relatedCompanyOptions
                      )
                    : null
                }
                onSelect={(selectedOption) =>
                  changeRelationType(selectedOption, inputRelatedEntityModel)
                }
                options={relatedCompanyOptions?.map((relation_type) => {
                  return { value: relation_type.id, label: relation_type.name };
                })}
                statusMetadata={
                  isValidated && isRelationTypeInvalid()
                    ? FormFieldStatusMetadata.getError("Field cannot be empty")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Row>
          <Row className="form-row-spacer">
            <GVFormGroup className="col-lg-6 col-sm-12">
              <Form.Label>
                {t("admin.site-details.related-entities.labels.company")}
              </Form.Label>
              <GVDSFormSingleSelect
                name="company"
                placeholder="Select company"
                value={
                  inputRelatedEntityModel.company.id
                    ? getRelatedCompanyOptionByValue(
                        inputRelatedEntityModel.company.id
                      )
                    : null
                }
                onSelect={(selectedOption) =>
                  changeRelatedCompany(selectedOption, inputRelatedEntityModel)
                }
                options={getRelatedCompanyOptionTuples()}
                statusMetadata={
                  isValidated && isCompanyInvalid()
                    ? FormFieldStatusMetadata.getError("Field cannot be empty")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <GVFormGroup className="col-lg-6 col-sm-12">
              <Form.Label>
                {t("admin.site-details.related-entities.labels.id-code")}
                <InfoTooltip
                  info={t(
                    "admin.site-details.related-entities.labels.tooltip-id-code"
                  )}
                />
              </Form.Label>
              <GVDSFormField
                name="internal_company_code"
                value={inputRelatedEntityModel.internal_company_code}
                onInput={(value, event) =>
                  changeInternalCompanyCode(event, inputRelatedEntityModel)
                }
              />
            </GVFormGroup>
          </Row>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {isEditingEntityRelationship && (
            <div className="me-auto">
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                onClick={() => {
                  setEntityRelationshipIdToBeDeleted(
                    inputRelatedEntityModel.id
                  );
                  setShowInputModal(false);
                  setShowDeleteModal(true);
                }}
                text={t("shared.delete")}
              />
            </div>
          )}
          <WarningOnModal />
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseModal}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveEntityRelationship}
            text={
              isEditingEntityRelationship ? t("shared.edit") : t("shared.add")
            }
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <GVDSModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        title={t(
          "admin.site-details.related-entities.modal-title-delete-entity"
        )}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          {t("admin.site-details.related-entities.delete-warning")}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <WarningOnModal />
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseDeleteModal}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteSelectedEntityRelationship}
            text={t("shared-modal.footer.yes-delete")}
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <UnsavedChangePromptModal
        show={showUnsavedChangePromptModal}
        onCancel={onCancelUnsavedPrompt}
        onProceed={closeModalWithoutPrompt}
        modalName={
          isEditingEntityRelationship
            ? t("admin.site-details.related-entities.modal-title-edit-entity")
            : t("admin.site-details.related-entities.modal-title-add-entity")
        }
        message={t("shared.unsaved-change-prompt-message")}
      />
    </>
  );
};

export class Certificate {
  constructor(
    id = uniqueId(new_entry_prefix),
    certification = empty_id_name,
    status = empty_id_name,
    date_completed = null,
    comment = null
  ) {
    this.id = id;
    this.certification = certification;
    this.status = status;
    this.date_completed = date_completed
      ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(date_completed)
      : null;
    this.comment = comment;
  }

  isToBeDeleted() {
    return this.id.startsWith(tobe_deleted_entry_prefix);
  }

  has_no_data() {
    return (
      this.certification === empty_id_name &&
      this.status === empty_id_name &&
      this.comment === null &&
      this.date_completed === null
    );
  }

  hasCertification() {
    return this.certification.id;
  }

  hasStatus() {
    return this.status.id;
  }

  hasPeriod() {
    return this.date_completed !== null;
  }

  isValid() {
    return this.hasCertification() && this.hasStatus() && this.hasPeriod();
  }
}

export const Certification = ({
  inEditMode,
  certificateOptions,
  certificates,
  onCertificateChange,
}) => {
  const { t } = useTranslation();

  const [showInputModal, setShowInputModal] = useState(false);
  const handleCloseModal = () => {
    setShowInputModal(false);
    if (hasChanges) {
      setShowUnsavedChangePromptModal(true);
    } else {
      resetForm();
    }
  };
  const [inputCertificateModel, setInputCertificateModel] = useState(
    new Certificate()
  );
  const [isEditingCertificate, setIsEditingCertificate] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => {
    setCertificateIdToBeDeleted(null);
    setShowDeleteModal(false);
  };
  const [certificateIdToBeDeleted, setCertificateIdToBeDeleted] =
    useState(null);

  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedChangePromptModal, setShowUnsavedChangePromptModal] =
    useState(false);

  const promptNewEntry = () => {
    setIsEditingCertificate(false);
    setInputCertificateModel(new Certificate());
    setShowInputModal(true);
  };

  const promptEditCertificate = (certificate) => {
    setIsEditingCertificate(true);
    setInputCertificateModel(clone(certificate));
    setShowInputModal(true);
  };

  const saveCertificate = () => {
    setIsValidated(true);

    if (inputCertificateModel.isValid()) {
      if (!isEditingCertificate) {
        onCertificateChange([...certificates, inputCertificateModel]);
      } else {
        const indexOfCertificateEdited = certificates
          .map((certificate) => certificate.id)
          .indexOf(inputCertificateModel.id);
        certificates[indexOfCertificateEdited] = inputCertificateModel;
        onCertificateChange([...certificates]);
      }
      setIsValidated(false);
      closeModalWithoutPrompt();
    }
  };

  const resetForm = () => {
    setIsValidated(false);
    setInputCertificateModel(new Certificate());
    setHasChanges(false);
  };

  const onChange = (certificate) => {
    setHasChanges(true);
    setInputCertificateModel(clone(certificate));
  };

  const onCancelUnsavedPrompt = () => {
    setShowUnsavedChangePromptModal(false);
    setShowInputModal(true);
  };

  const closeModalWithoutPrompt = () => {
    setShowUnsavedChangePromptModal(false);
    setShowInputModal(false);
    resetForm();
  };

  const changeCertificateDateCompleted = (certificate, newDateCompleted) => {
    certificate.date_completed = newDateCompleted;
    onChange(certificate);
  };

  const changeCertificateComment = (event, certificate) => {
    const valueUpdated = event.target.name;
    certificate[valueUpdated] = event.target.value;
    onChange(certificate);
  };

  const changeCertificateCertification = (selectedOption, certificate) => {
    const newCertificationId = selectedOption.value;
    const newCertificationName = selectedOption.label;

    certificate.certification = {
      id: newCertificationId,
      name: newCertificationName,
    };

    onChange(certificate);
  };

  const changeCertificateStatus = (selectedOption, certificate) => {
    const newStatusId = selectedOption.value;
    const newStatusName = selectedOption.label;

    certificate.status = { id: newStatusId, name: newStatusName };

    onChange(certificate);
  };

  const deleteSelectedCertificate = () => {
    if (certificateIdToBeDeleted.startsWith(new_entry_prefix)) {
      certificates.splice(
        certificates.map((r) => r.id).indexOf(certificateIdToBeDeleted),
        1
      );
    } else {
      const entityRelationshipToBeDeleted = certificates.find(
        (r) => r.id === certificateIdToBeDeleted
      );
      entityRelationshipToBeDeleted.id =
        tobe_deleted_entry_prefix + entityRelationshipToBeDeleted.id;
    }
    onCertificateChange([...certificates]);
    handleCloseDeleteModal();
  };

  const getCertificateStatusOptionTuples = () => {
    let certificateStatusOptionTuples = [];
    if (inputCertificateModel.certification.id) {
      certificateOptions
        ?.find((option) => option.id === inputCertificateModel.certification.id)
        .status_options.map((status_option) => {
          certificateStatusOptionTuples.push({
            value: status_option.id,
            label: status_option.name,
          });
        });
    }

    return certificateStatusOptionTuples;
  };

  const getCertificateStatusOptionByValue = (optionValue) => {
    const options = getCertificateStatusOptionTuples();
    if (options) {
      const option = options.find((option) => option.value === optionValue);
      return option || null;
    } else {
      return null;
    }
  };

  const notDeletedCertificates = certificates.filter((c) => !c.isToBeDeleted());
  const isEmptyCertificates = notDeletedCertificates.length === 0;

  return (
    <>
      {isEmptyCertificates ? (
        <div className="gvds-empty-content">
          <div>{t("admin.site-details.certification.no-content")}</div>
          {inEditMode && (
            <div className="mt-2">
              <GVDSButton
                variant={buttonVariant.secondary}
                onClick={promptNewEntry}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text={t(
                  "admin.site-details.certification.button-add-certificate"
                )}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <GVDSTableDisplay>
            <thead>
              <tr>
                <th>
                  {t("admin.site-details.certification.labels.certification")}
                </th>
                <th>{t("shared-input-label.status")}</th>
                <th style={{ width: "150px" }}>
                  {t("admin.site-details.certification.labels.date-completed")}
                </th>
                <th style={{ width: "50px" }}>
                  {t("shared-input-label.comments")}
                </th>
                {inEditMode && <th>{t("shared-input-label.actions")}</th>}
              </tr>
            </thead>
            <tbody>
              {notDeletedCertificates.map((certificate) => {
                return (
                  <tr key={certificate.id}>
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="certification"
                        value={certificate.certification.name}
                      />
                    </td>
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="status"
                        value={certificate.status.name}
                      />
                    </td>
                    <td>
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="date_completed"
                        value={moment(certificate.date_completed).format(
                          "DD MMM YYYY"
                        )}
                      />
                    </td>
                    <td>
                      <div className="comment-tooltip-container-on-form-row">
                        {certificate.comment && (
                          <CommentTooltip comment={certificate.comment} />
                        )}
                      </div>
                    </td>
                    {inEditMode && (
                      <td>
                        <div className="container-for-item-in-form-table">
                          <GVDSIconButton
                            variant={iconButtonVariant.tertiary}
                            onClick={(e) => {
                              e.preventDefault();
                              promptEditCertificate(certificate);
                            }}
                            icon={<GVDSIcon Icon={IconEdit} />}
                            tooltipText={t("shared.edit")}
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </GVDSTableDisplay>
          {inEditMode && (
            <div className="mt-1">
              <GVDSButton
                variant={buttonVariant.secondary}
                onClick={promptNewEntry}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text={t(
                  "admin.site-details.certification.button-add-certificate"
                )}
              />
            </div>
          )}
        </>
      )}

      <GVDSModal
        show={showInputModal}
        onHide={handleCloseModal}
        title={isEditingCertificate ? t("admin.site-details.certification.modal-title-edit-certificate") : t("admin.site-details.certification.modal-title-add-certificate")}
        size={GVDSModal.Size.medium}
      >
        <GVDSModal.Body>
          <Row>
            <GVFormGroup className="col-xxl-4 col-xl-6 col-sm-12">
              <Form.Label>
                {t("admin.site-details.certification.labels.certification")}
              </Form.Label>
              <GVDSFormSingleSelect
                name="certification"
                placeholder="Select Certification"
                value={
                  inputCertificateModel.certification.id
                    ? getOptionByValueFromIdName(
                        inputCertificateModel.certification.id,
                        certificateOptions
                      )
                    : null
                }
                onSelect={(selectedOption) =>
                  changeCertificateCertification(
                    selectedOption,
                    inputCertificateModel
                  )
                }
                options={certificateOptions?.map((option) => {
                  return { value: option.id, label: option.name };
                })}
                statusMetadata={
                  isValidated && !inputCertificateModel.hasCertification()
                    ? FormFieldStatusMetadata.getError("Field cannot be empty")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <GVFormGroup className="col-xxl-4 col-xl-6 col-sm-12">
              <Form.Label>{t("shared-input-label.status")}</Form.Label>
              <GVDSFormSingleSelect
                name="status"
                placeholder="Select status"
                value={
                  inputCertificateModel.status.id
                    ? getCertificateStatusOptionByValue(
                        inputCertificateModel.status.id
                      )
                    : null
                }
                onSelect={(selectedOption) =>
                  changeCertificateStatus(selectedOption, inputCertificateModel)
                }
                options={getCertificateStatusOptionTuples()}
                statusMetadata={
                  isValidated && !inputCertificateModel.hasStatus()
                    ? FormFieldStatusMetadata.getError("Field cannot be empty")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Row>
          <Row className="form-row-spacer">
            <GVFormGroup className="col-xxl-4 col-xl-6 col-sm-12">
              <Form.Label>
                {t("admin.site-details.certification.labels.date-completed")}
              </Form.Label>
              <GVDSFormSingleDatePicker
                selected={inputCertificateModel.date_completed}
                onChange={(date) =>
                  changeCertificateDateCompleted(inputCertificateModel, date)
                }
                dateFormat="dd MMM yyyy"
                maxDate={new Date()}
                className={
                  !isValidated || inputCertificateModel.hasPeriod()
                    ? ""
                    : "invalid-datepicker"
                }
                statusMetadata={
                  isValidated && !inputCertificateModel.hasPeriod()
                    ? FormFieldStatusMetadata.getError("Please select a date")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Row>
          <Row className="form-row-spacer">
            <GVFormGroup className="col-lg-12 col-sm-12">
              <Form.Label>{t("shared-input-label.comments")}</Form.Label>
              <GVDSFormField
                name="comment"
                value={inputCertificateModel.comment}
                onInput={(value, event) =>
                  changeCertificateComment(event, inputCertificateModel)
                }
              />
            </GVFormGroup>
          </Row>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {isEditingCertificate && (
            <div className="me-auto">
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                onClick={() => {
                  setCertificateIdToBeDeleted(inputCertificateModel.id);
                  setShowInputModal(false);
                  setShowDeleteModal(true);
                }}
                text={t("shared.delete")}
              />
            </div>
          )}
          <WarningOnModal />
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseModal}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveCertificate}
            text={isEditingCertificate ? t("shared.edit") : t("shared.add")}
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <GVDSModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        title={t("admin.site-details.certification.modal-title-delete-certificate")}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>{t("admin.site-details.certification.delete-warning")}</GVDSModal.Body>
        <GVDSModal.Footer>
          <WarningOnModal />
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleCloseDeleteModal}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteSelectedCertificate}
            text={t("shared-modal.footer.yes-delete")}
          />
        </GVDSModal.Footer>
      </GVDSModal>

      <UnsavedChangePromptModal
        show={showUnsavedChangePromptModal}
        onCancel={onCancelUnsavedPrompt}
        onProceed={closeModalWithoutPrompt}
        modalName={isEditingCertificate ? t("admin.site-details.certification.modal-title-edit-certificate") : t("admin.site-details.certification.modal-title-add-certificate")}
        message={t("shared.unsaved-change-prompt-message")}
      />
    </>
  );
};

export const GeographicCoordinates = ({
  siteName,
  originalLat,
  originalLong,
  isInputLocationSameAsOriginal,
  streetAddress,
  zipCode,
  city,
  stateOrProvince,
  countryName,
  onFailGetLatLong,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);

  const [displayLat, setDisplayLat] = useState(null);
  const [displayLong, setDisplayLong] = useState(null);

  const getLatLongTimer = useRef(null);
  const getLatLongPromise = useRef(null);

  const debounceGetLatLong = (debounceWait = 1000) => {
    clearTimeout(getLatLongTimer.current);
    setIsLoading(true);

    getLatLongTimer.current = setTimeout(async () => {
      if (getLatLongPromise.current) {
        await getLatLongPromise.current;
      }

      const promise = GeocodingService.getLatLongFromAddress(
        siteName,
        streetAddress,
        zipCode,
        city,
        stateOrProvince,
        countryName
      );
      getLatLongPromise.current = promise;

      promise
        .then((latLongDto) => {
          setDisplayLat(latLongDto.lat);
          setDisplayLong(latLongDto.long);
        })
        .catch((e) => {
          if (e.response && e.response.status === 404) {
            onFailGetLatLong();
          } else {
            toastContext.addFailToast(
              <span>
                Failed to get geographic coordinates. Please try again.
              </span>
            );
          }
          setDisplayLat(null);
          setDisplayLong(null);
        })
        .finally(() => {
          getLatLongPromise.current = null;
          setIsLoading(false);
        });
    }, debounceWait);
  };

  useEffect(() => {
    if (isInputLocationSameAsOriginal && originalLat && originalLong) {
      setDisplayLat(originalLat);
      setDisplayLong(originalLong);
    } else {
      if (
        GeocodingService.isAddressComplete(
          streetAddress,
          zipCode,
          city,
          stateOrProvince,
          countryName
        )
      ) {
        debounceGetLatLong();
      } else {
        setDisplayLat(null);
        setDisplayLong(null);
      }
    }
  }, [
    siteName,
    isInputLocationSameAsOriginal,
    originalLat,
    originalLong,
    streetAddress,
    zipCode,
    city,
    stateOrProvince,
    countryName,
  ]);

  if (isLoading) {
    return (
      <div>
        <LoadingSpinnerInline />
      </div>
    );
  } else if (displayLat === null && displayLong === null) {
    return <div>-</div>;
  } else {
    return (
      <div>
        {NumberService.format(displayLat, 6, 6)},{" "}
        {NumberService.format(displayLong, 6, 6)} (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${displayLat},${displayLong}`}
          target="_blank"
        >
          {t("admin.site-details.link-geo-coordinate-view-gmaps")}
        </a>
        )
      </div>
    );
  }
};
