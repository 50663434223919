import React, { forwardRef, useEffect, useState } from "react";
import Select, { components } from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import intersection from "lodash/intersection";

const longSelectThreshold = 10;
const shortSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 200,
    margin: 8,
    display: "none",
    width: state.selectProps.width,
  }),
  menu: (provided, state) => ({
    boxShadow: "inset 0 0 0 rgba(0, 0, 0, 0)",
    width: state.selectProps.width,
  }),
};

const longSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 200,
    margin: 8,
    width: state.selectProps.width,
  }),
  menu: () => ({
    boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
  }),
};

export const CustomCheckboxOption = (props) => {
  const displayAs = props.data.display;
  return (
    <div>
      <components.Option
        {...props}
        className="mini-multi-select__option text-capitalize"
      >
        <Form.Check
          className={props.isSelected ? "gv-select-selected" : ""}
          checked={props.isSelected}
          readOnly
          type="checkbox"
          label={displayAs}
        />
      </components.Option>
    </div>
  );
};

const CheckboxOption = (props) => (
  <div>
    <components.Option
      {...props}
      className="mini-multi-select__option text-capitalize"
    >
      <Form.Check
        className={props.isSelected ? "gv-select-selected" : ""}
        checked={props.isSelected}
        readOnly
        type="checkbox"
        label={props.value}
      />
    </components.Option>
  </div>
);

const MiniSelectTrigger = forwardRef(({ children, onClick }, ref) => (
  <div
    className="mini-multi-select__trigger"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}{" "}
    <span>
      <FontAwesomeIcon icon={faCaretDown} />
    </span>
  </div>
));

const Menu = (props) => {
  const optionsHeaderText = props.selectProps.GVCustomOptionsHeaderText;
  return (
    <components.Menu {...props}>
      <div>
        {optionsHeaderText && (
          <div className="mini-multi-select__menu">{optionsHeaderText}</div>
        )}
        <div>{props.children}</div>
      </div>
    </components.Menu>
  );
};

export class OptionTransformer {
  constructor(labelFn, valueFn, displayFn) {
    this.labelFn = labelFn;
    this.valueFn = valueFn;
    this.displayFn = displayFn;
  }

  transform(o) {
    return {
      label: this.labelFn(o),
      value: this.valueFn(o),
      display: this.displayFn(o),
    };
  }
}

const defaultStringOptionTransformer = new OptionTransformer(
  (o) => o,
  (o) => o,
  (o) => o
);

export default ({
  options = [],
  nonStringOptions = null,
  optionTransformer = defaultStringOptionTransformer,
  prefix,
  defaultSelected = [],
  onChange,
  width = "200px",
  noOptionsMessage = "No options",
  optionsHeaderText = null,
}) => {
  let isUsingNonStringValues = false;
  let dropdownOptions = options;

  if (nonStringOptions !== null) {
    isUsingNonStringValues = true;
    dropdownOptions = nonStringOptions;
  }

  const refreshOptions = options
    .map((o) => optionTransformer.transform(o).value)
    .join(",");
  const refreshSelectedKey = defaultSelected
    .map((o) => optionTransformer.transform(o).value)
    .join(",");
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const currentlySelectedValues = selected.map((o) => o.value);

    let newSelected = [];

    if (dropdownOptions.length > 0) {
      if (defaultSelected.length > 0) {
        newSelected = intersection(dropdownOptions, defaultSelected);
      } else if (currentlySelectedValues.length > 0) {
        newSelected = dropdownOptions.filter((o) =>
          currentlySelectedValues.includes(optionTransformer.transform(o).value)
        );
      }
    }

    setSelected(newSelected.map((o) => optionTransformer.transform(o)));
  }, [refreshOptions, refreshSelectedKey]);

  const onSelectChange = (selectedOpts) => {
    setSelected(selectedOpts);
    onChange(selectedOpts.map((s) => s.value));
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={MiniSelectTrigger}>
        <span>{prefix}: </span>
        {selected.length > 0 ? (
          <span className="mini-multi-select__number_of_selected">
            {selected.length}
          </span>
        ) : (
          <span>All</span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu flip={false}>
        <Select
          className="mini-multi-select"
          isMulti
          menuIsOpen
          menuShouldScrollIntoView={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          tabSelectsValue={false}
          controlShouldRenderValue={false}
          components={{
            Menu: Menu,
            Option: isUsingNonStringValues
              ? CustomCheckboxOption
              : CheckboxOption,
            DropdownIndicator: () => (
              <FontAwesomeIcon className="me-1" icon={faSearch} />
            ),
            IndicatorSeparator: null,
          }}
          GVCustomOptionsHeaderText={optionsHeaderText}
          placeholder="Search"
          value={selected}
          options={dropdownOptions.map((o) => optionTransformer.transform(o))}
          noOptionsMessage={() => noOptionsMessage}
          width={width}
          styles={
            dropdownOptions.length > longSelectThreshold
              ? longSelectStyles
              : shortSelectStyles
          }
          onChange={onSelectChange}
          maxMenuHeight="40rem"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};
