import React from "react";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import BestPracticeDataTable from "./BestPractice/BestPracticeDataTable";
import CompaniesDataTable from "./Companies/CompaniesDataTable";
import SiteFacilityTypeDataTable from "./SiteFacilityTypeOptions/SiteFacilityTypeDataTable";
import HotelAndAccommodationAttributesDataTables from "../ReferenceData/HotelAndAccommodationAttributes/HotelAndAccommodationAttributesDataTables";
import DataManagementTable from "./DataManagement/DataManagementTable";
import PortalWideDataTable from "./PortalWide/PortalWideDataTable";
import Container from "react-bootstrap/Container";
import PageHeader from "../../gvds-components/Layout/PageHeader";

const ReferenceDataDashboard = () => {
  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Reference Data</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="data-tables">
        <Tab eventKey="data-tables" title="Data Tables">
          <PortalWideDataTable />
          <BestPracticeDataTable />
          <CompaniesDataTable />
          <HotelAndAccommodationAttributesDataTables />
          <DataManagementTable />
          <SiteFacilityTypeDataTable />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ReferenceDataDashboard;
