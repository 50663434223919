import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";

import LoadingSpinner from "../common/LoadingSpinner";
import ToastContext from "../../context/ToastContext";
import UserService from "../../services/UserService";
import { StringUtils } from "../../services/UtilsService";
import GVFormGroup from "../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus } from "@tabler/icons-react";

const CreatePortalAdminUser = ({ onCreate }) => {
  const toastContext = useContext(ToastContext);

  const [isSaving, setIsSaving] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setIsValidated(false);
    setEmail("");
  };

  const [email, setEmail] = useState("");

  const isEmailValid = () => {
    return StringUtils.isEmail(email);
  };

  const createPortalAdmin = async () => {
    setIsValidated(true);

    if (isEmailValid()) {
      setIsSaving(true);
      try {
        await UserService.createPortalAdminUser(email);
        toastContext.addSuccessToast(
          <span>{email} was successfully created as a portal admin.</span>
        );
        handleClose();
        setIsSaving(false);
        onCreate();
      } catch (error) {
        setIsSaving(false);
        toastContext.addFailToast(
          <span>Failed to create portal admin user.</span>
        );
      }
    }
  };

  return (
    <>
      <GVDSButton
        variant={buttonVariant.primary}
        onClick={() => setShow(true)}
        text="Create Portal Admin User"
        icon={<GVDSIcon Icon={IconCirclePlus} />}
      />

      <GVDSModal
        title="Create Portal Admin User"
        show={show}
        onHide={handleClose}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          {isSaving && <LoadingSpinner />}
          {!isSaving && (
            <>
              <div className="mb-3">
                A portal admin is granted all access and management controls
                within the portal.
              </div>
              <GVFormGroup>
                <Form.Label>Email Address</Form.Label>
                <GVDSFormField
                  type="email"
                  className="user-name-input"
                  placeholder="Enter portal admin email"
                  value={email}
                  onInput={(value) => setEmail(value)}
                  statusMetadata={
                    isValidated && !isEmailValid()
                      ? FormFieldStatusMetadata.getError(
                          "Please provide a valid email address."
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            disabled={isSaving}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={createPortalAdmin}
            disabled={isSaving}
            text="Create"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default CreatePortalAdminUser;
