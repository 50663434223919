import axios from "axios";
import { API_URL } from "../config/api-config";
import { CollectiveTargetModel } from "../components/GoalsAndTargets/TargetDetailsModel";

export default class CollectiveTargetService {
  static async getPreview(resource_type, resource_id, collective_target) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/collective_targets/preview`,
        collective_target,
        {
          params: { resource_type, resource_id },
        }
      );

      return new CollectiveTargetModel(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async createCollectiveTarget(
    resource_type,
    resource_id,
    collective_target
  ) {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/collective_targets`,
        collective_target,
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateCollectiveTarget(
    resource_type,
    resource_id,
    collectiveTargetId,
    collectiveTarget
  ) {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/collective_targets/${collectiveTargetId}`,
        collectiveTarget,
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async patchCollectiveTarget(
    collective_target_id,
    updates,
    resource_type,
    resource_id
  ) {
    try {
      const response = await axios.patch(
        `${API_URL}/api/v1/collective_targets/${collective_target_id}`,
        updates,
        { params: { resource_type, resource_id } }
      );

      return new CollectiveTargetModel(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async getAllCollectiveTarget(resource_type, resource_id) {
    try {
      const response = await axios.get(API_URL + "/api/v1/collective_targets", {
        params: { resource_id, resource_type },
      });
      return response.data.map((d) => new CollectiveTargetModel(d));
    } catch (error) {
      return [];
    }
  }

  static async getCollectiveTarget(
    collective_target_id,
    resource_type,
    resource_id
  ) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/collective_targets/${collective_target_id}`,
        {
          params: {
            resource_id,
            resource_type,
          },
        }
      );
      return new CollectiveTargetModel(response.data);
    } catch (error) {
      throw error.response;
    }
  }

  static async deleteCollectiveTarget(
    resource_type,
    resource_id,
    collective_target_id
  ) {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/collective_targets/${collective_target_id}`,
        {
          params: {
            resource_id,
            resource_type,
          },
        }
      );
      return null;
    } catch (error) {
      throw error;
    }
  }
}
