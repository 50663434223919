import SubscriptionSharedUtils, {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  customEndPeriodRange,
  SubscriptionBillingCycle,
  SubscriptionPaymentType,
  SubscriptionPricingType,
} from "../SubscriptionSharedUtils";
import {
  DateTimeUtils,
  NumberService,
} from "../../../../services/UtilsService";
import SiteSubscriptionModel, {
  SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE,
} from "../SiteSubscription/SiteSubscriptionModel";
import moment from "moment";
import { SUBSCRIPTION_PAYMENT } from "../SubscriptionPaymentBadge";

export const DAYS_BEFORE_OVERDUE_TO_SHOW_BANNER = 1;

export default class ContractSubscriptionModel {
  constructor({
    id,
    contract,
    startPeriod,
    endPeriod,
    reminderDate,
    pricingType,
    currencyId,
    features,
    comments,
    status,
    billingCycle,
    discountCode,
    paymentStatus,
    paymentType,
    planId,
    nextPaymentDate,
    paymentDueDate,
    customPaymentDueDays,
    isTaxApplicable,
    paymentLink,
    autoRenew,
    stripeInvoiceLinks,
    updatedBy,
    updatedOn,
    siteSubscriptions = [],
    isFirstSubscription,
    successorSubscriptionId,
    isUnbilledRenewal,
    extraInvoiceSitePriceBySiteId,
  }) {
    this.id = id;
    this.contract = contract;
    this.startPeriod = startPeriod;
    this.endPeriod = endPeriod;
    this.reminderDate = reminderDate;
    this.pricingType = pricingType;
    this.currencyId = currencyId;
    this.features = features;
    this.comments = comments;
    this.status = status;
    this.billingCycle = billingCycle;
    this.discountCode = discountCode;
    this.paymentStatus = paymentStatus;
    this.paymentType = paymentType;
    this.planId = planId;
    this.autoRenew = autoRenew;
    this.nextPaymentDate = nextPaymentDate;
    this.paymentDueDate = paymentDueDate;
    this.customPaymentDueDays = customPaymentDueDays;
    this.isTaxApplicable = !!isTaxApplicable;
    this.paymentLink = paymentLink;
    this.stripeInvoiceLinks = stripeInvoiceLinks;
    this.updatedBy = updatedBy;
    this.updatedOn = updatedOn;
    this.siteSubscriptions = siteSubscriptions;
    this.isFirstSubscription = isFirstSubscription;
    this.successorSubscriptionId = successorSubscriptionId;
    this.isUnbilledRenewal = isUnbilledRenewal;
    this.extraInvoiceSitePriceBySiteId = extraInvoiceSitePriceBySiteId;
  }

  getPaymentDueMessage() {
    return this.paymentDueDate
      ? `${this.currencyId ?? ""} ${NumberService.format(
          this.getProratedPriceAmount()
        )} on ${DateTimeUtils.formatUTCDate(
          SubscriptionSharedUtils.getPaymentDueDate(
            this.hasCustomPaymentDueDay,
            this.paymentDueDate,
            this.startPeriod
          )
        )}`
      : null;
  }

  get hasCustomPaymentDueDay() {
    return !!this.customPaymentDueDays;
  }

  getNextPaymentMessage() {
    return this.nextPaymentDate
      ? `${this.currencyId ?? ""} ${NumberService.format(
          this.getPriceAmount()
        )} on ${DateTimeUtils.formatUTCDate(this.nextPaymentDate)}`
      : null;
  }

  static fromDTO(requestDTO) {
    const lastUpdater = requestDTO["updated_by"] ?? requestDTO["created_by"];
    const lastUpdatedBy = !!lastUpdater
      ? lastUpdater["full_name"] || lastUpdater["email"]
      : "-";
    const lastUpdatedOn = requestDTO["updated_on"] ?? requestDTO["created_on"];

    const contractSubscriptionModel = new ContractSubscriptionModel({
      id: requestDTO["id"],
      contract: requestDTO["contract"],
      startPeriod: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
        requestDTO["start_period"]
      ),
      endPeriod: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
        requestDTO["end_period"]
      ),
      reminderDate: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
        requestDTO["reminder_date"]
      ),
      pricingType: requestDTO["pricing_type"],
      currencyId: requestDTO["currency"] ? requestDTO["currency"]["id"] : null,
      features: requestDTO["features"],
      comments: requestDTO["comments"],
      status: requestDTO["status"],
      billingCycle: requestDTO["billing_cycle"],
      discountCode: requestDTO["discount_code"],
      paymentStatus: requestDTO["payment_status"],
      paymentType: requestDTO["payment_type"],
      planId: requestDTO["plan_id"],
      autoRenew: requestDTO["auto_renew"] ?? false,
      customPaymentDueDays: requestDTO["custom_payment_due_days"],
      isTaxApplicable: requestDTO["is_tax_applicable"],
      paymentLink: requestDTO["payment_link"],
      nextPaymentDate: requestDTO["next_payment_date"],
      paymentDueDate: requestDTO["payment_due_date"],
      stripeInvoiceLinks: requestDTO["stripe_invoice_links"],
      updatedBy: lastUpdatedBy,
      updatedOn:
        DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(lastUpdatedOn),
      isFirstSubscription: requestDTO["is_first_subscription"],
      successorSubscriptionId: requestDTO["successorSubscriptionId"],
      isUnbilledRenewal: requestDTO["is_unbilled_renewal"],
      extraInvoiceSitePriceBySiteId:
        requestDTO["extra_invoice_site_price_by_site_id"],
    });

    contractSubscriptionModel.siteSubscriptions = requestDTO[
      "site_subscriptions"
    ].map((subscription) =>
      SiteSubscriptionModel.fromDTO(subscription, contractSubscriptionModel)
    );

    return contractSubscriptionModel;
  }

  isStartDateValid() {
    return (
      this.startPeriod &&
      (this.endPeriod ? this.startPeriod <= this.endPeriod : true)
    );
  }

  isEndDateValid() {
    return (
      this.endPeriod &&
      (this.startPeriod ? this.endPeriod >= this.startPeriod : true)
    );
  }

  isReminderDateValid() {
    return (
      this.reminderDate &&
      (this.endPeriod ? this.reminderDate <= this.endPeriod : true) &&
      (this.startPeriod ? this.reminderDate >= this.startPeriod : true)
    );
  }

  isPriceValid() {
    return this.isPricingTypeValid() && this.isCurrencyValid();
  }

  isPricingTypeValid() {
    return this.pricingType;
  }

  isCurrencyValid() {
    return !this.isPaidSubscription() || this.currencyId;
  }

  isFeaturesValid() {
    return this.features.length > 0;
  }

  isPlanValid() {
    return this.planId;
  }

  isPaymentTypeValid() {
    return !this.isPaidSubscription() || this.paymentType;
  }

  isBillingCycleValid() {
    return !this.isPaidSubscription() || this.billingCycle;
  }

  isAllPriceAmountValid() {
    return (
      !this.isPaidSubscription() ||
      this.siteSubscriptions.every(
        (subscription) =>
          subscription.priceAmount !== undefined &&
          subscription.priceAmount !== null &&
          subscription.priceAmount !== "" &&
          subscription.priceAmount >= 0
      )
    );
  }

  isAllSitePeriodValid() {
    return this.siteSubscriptions.every(
      (subscription) =>
        this.isSiteSubscriptionStartPeriodValid(subscription) &&
        this.isSiteSubscriptionEndPeriodValid(subscription)
    );
  }

  getProratedPriceAmount() {
    const invoicePriceBySiteId = this.extraInvoiceSitePriceBySiteId;

    return this.siteSubscriptions.reduce((total, subscription) => {
      if (
        invoicePriceBySiteId &&
        subscription.site.id in invoicePriceBySiteId
      ) {
        return total + invoicePriceBySiteId[subscription.site.id];
      } else {
        return total + subscription.priceAmount;
      }
    }, 0);
  }

  getPriceAmount() {
    return this.siteSubscriptions.reduce((total, subscription) => {
      return total + subscription.priceAmount;
    }, 0);
  }

  isSiteSubscriptionStartPeriodValid(siteSubscription) {
    return (
      siteSubscription.startPeriod ===
        SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE ||
      (siteSubscription.startPeriod >= this.startPeriod &&
        siteSubscription.startPeriod <= this.endPeriod &&
        siteSubscription.isStartDateValid())
    );
  }

  isSiteSubscriptionEndPeriodValid(siteSubscription) {
    return (
      siteSubscription.endPeriod ===
        SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE ||
      (siteSubscription.endPeriod >= this.startPeriod &&
        siteSubscription.endPeriod <= this.endPeriod &&
        siteSubscription.isEndDateValid())
    );
  }

  isPaidSubscription() {
    return this.pricingType === SubscriptionPricingType.PAID;
  }

  isRecurringBillingCycle() {
    return (
      this.billingCycle &&
      [
        SubscriptionBillingCycle.MONTHLY,
        SubscriptionBillingCycle.YEARLY,
      ].includes(this.billingCycle)
    );
  }

  isUnpaidSubscriptionOnDue() {
    const paymentDueDate = this.hasCustomPaymentDueDay
      ? this.paymentDueDate
      : this.startPeriod;
    return (
      this.paymentStatus === SUBSCRIPTION_PAYMENT.UNPAID &&
      DateTimeUtils.getRemainingDaysBefore(paymentDueDate) <=
        DAYS_BEFORE_OVERDUE_TO_SHOW_BANNER
    );
  }

  getDuplicateModel(allSites, orderedPackagePlans) {
    const newStartPeriod = moment(this.endPeriod).add(1, "day").toDate();

    const billingCycle = this.billingCycle;
    const pricingType = this.pricingType;

    const subscriptionPlan = orderedPackagePlans.find(
      (plan) => plan.package_plan_id === this.planId
    );

    const isCustomPlan =
      subscriptionPlan.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;

    const price = subscriptionPlan.prices.find(
      (price) => price.billing_cycle === billingCycle
    );
    let siteSubPriceAmount = null;
    let currencyId = null;
    if (pricingType === SubscriptionPricingType.FREE) {
      currencyId = null;
    } else if (!isCustomPlan && price) {
      siteSubPriceAmount = price.price_in_decimal;
      currencyId = price.currency.toUpperCase();
    } else {
      currencyId = this.currencyId;
    }

    const newContractSubscription = new ContractSubscriptionModel({
      id: null,
      contract: this.contract,
      startPeriod: newStartPeriod,
      endPeriod: SubscriptionSharedUtils.calculateEndPeriod(
        customEndPeriodRange.oneYear,
        newStartPeriod
      ),
      reminderDate: null,
      pricingType: pricingType,
      currencyId: currencyId,
      features: this.features,
      comments: this.comments,
      billingCycle: billingCycle,
      planId: this.planId,
      autoRenew: null,
      customPaymentDueDays: this.customPaymentDueDays,
      isTaxApplicable: this.isTaxApplicable,
      paymentType: this.paymentType,
      siteSubscriptions: [],
    });

    let autoRenew;

    if (
      [
        SubscriptionBillingCycle.MONTHLY,
        SubscriptionBillingCycle.YEARLY,
      ].includes(billingCycle)
    ) {
      autoRenew = true;

      const endPeriod = SubscriptionSharedUtils.getEndPeriodBasedOnBillingCycle(
        newContractSubscription,
        billingCycle
      );
      if (endPeriod !== null) {
        newContractSubscription.endPeriod = endPeriod;
      }
    } else {
      autoRenew = false;
    }

    newContractSubscription.autoRenew = autoRenew;

    newContractSubscription.siteSubscriptions = this.siteSubscriptions.map(
      (ss) =>
        ss.underContractGetDuplicateModelForContractSubscription(
          currencyId,
          siteSubPriceAmount,
          autoRenew,
          this.customPaymentDueDays,
          this.paymentType === SubscriptionPaymentType.DECENTRALIZED,
          allSites
        )
    );

    return newContractSubscription;
  }

  toRequestPayload(invoiceDataPayload, shouldIssueInvoice) {
    return {
      start_period: DateTimeUtils.getUTCISOString(this.startPeriod),
      end_period: DateTimeUtils.getUTCISOString(this.endPeriod),
      reminder_date: DateTimeUtils.getUTCISOString(this.reminderDate),
      pricing_type: this.pricingType,
      currency_id:
        this.pricingType === SubscriptionPricingType.PAID
          ? this.currencyId
          : null,
      feature_ids: this.features.map((f) => f.id),
      comments: this.comments,
      billing_cycle: this.isPaidSubscription() ? this.billingCycle : null,
      plan_id: this.planId,
      auto_renew: this.isPaidSubscription() ? this.autoRenew : false,
      payment_type: this.isPaidSubscription() ? this.paymentType : null,
      custom_payment_due_days: this.customPaymentDueDays,
      is_tax_applicable: this.isTaxApplicable,
      site_subscriptions: this.siteSubscriptions.map((subscription) => {
        subscription.pricingType = this.pricingType;
        subscription.currencyId = this.currencyId;
        subscription.billingCycle = this.billingCycle;
        return subscription.toRequestPayload();
      }),
      invoice_data: invoiceDataPayload,
      should_issue_invoice: shouldIssueInvoice,
    };
  }

  get isDecentralized() {
    return this.paymentType === SubscriptionPaymentType.DECENTRALIZED;
  }
}
