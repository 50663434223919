import React from "react";

import Form from "react-bootstrap/Form";
import { ADVANCED_OR_AND_FILTER } from "../../../config/constants";
import { TextTooltipPersistentOnHover } from "../Tooltip/TooltipPersistentOnHover";
import TextButton from "../Forms/TextButton";

export const AdvancedOrAndSelectorFilter = ({
  selectedFilter,
  setSelectedFilter,
}) => {
  const filterSelector = (
    <>
      <div>
        <Form.Label className="mb-2">Selection Criteria</Form.Label>
        <div
          className="advanced-or-and-filter__option-container clickable"
          onClick={() => {
            setSelectedFilter(ADVANCED_OR_AND_FILTER.OR);
          }}
        >
          <div>
            <Form.Check
              label=""
              type="radio"
              checked={selectedFilter === ADVANCED_OR_AND_FILTER.OR}
              onChange={() => {}}
            />
          </div>
          <div>
            <div className="gv-text-14--bold">Contains any (Default)</div>
            <div className="gv-text-14 color-gray56">
              retrieves results that meet any specified criteria
            </div>
          </div>
        </div>
        <div
          className="advanced-or-and-filter__option-container clickable"
          onClick={() => {
            setSelectedFilter(ADVANCED_OR_AND_FILTER.AND);
          }}
        >
          <div>
            <Form.Check
              label=""
              type="radio"
              checked={selectedFilter === ADVANCED_OR_AND_FILTER.AND}
              onChange={() => {
                setSelectedFilter(ADVANCED_OR_AND_FILTER.AND);
              }}
            />
          </div>
          <div>
            <div className="gv-text-14--bold">Contains all</div>
            <div className="gv-text-14 color-gray56">
              retrieves only results that meet all specified criteria
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (selectedFilter === ADVANCED_OR_AND_FILTER.OR) {
    return (
      <TextTooltipPersistentOnHover
        triggerContent="Advanced"
        tooltipContent={filterSelector}
        placement="left"
      />
    );
  } else {
    return (
      <span>
        contains all (
        <TextButton
          text="Reset"
          onClick={() => setSelectedFilter(ADVANCED_OR_AND_FILTER.OR)}
        />
        )
      </span>
    );
  }
};
