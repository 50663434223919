import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import UserProfileContext from "../../../context/UserProfileContext";
import { FEATURES, SURVEY_STATUS } from "../../../config/constants";
import SurveyManagementService from "../../../services/SurveyManagementService";
import WidgetService from "./WidgetService";
import CustomProgressBar from "../../common/CustomProgressBar";
import { getViewSurveyPath, SURVEYS } from "../../../config/ROUTES_NAME";
import SurveyAssessmentService from "../../../services/SurveyAssessmentService";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import BaseWidgetEmptyContent, {
  FeatureLockWidgetContent,
} from "./BaseWidgetEmptyContent";
import BaseWidgetHeadingTooltip from "./BaseWidgetHeadingTooltip";
import LoadingSpinner from "../../common/LoadingSpinner";
import { isRequestAborted } from "../../../services/HttpService";
import { useTranslation } from "react-i18next";

export const PortfolioSurveyWidget = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.SURVEY__ANSWER],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const locked =
    subscriptionFeatureStatus !== SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const [isLoading, setIsLoading] = useState(true);
  const [openSurveys, setOpenSurveys] = useState([]);

  const abortControllerRef = useRef(null);

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;
    if (
      !userInventory.isLoadingInventory.get &&
      selectedInventory &&
      selectedInventory.id
    ) {
      setIsLoading(true);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      SurveyManagementService.getAllSurvey(
        selectedInventory.type,
        selectedInventory.id,
        abortControllerRef.current.signal
      )
        .then((surveyList) => {
          setOpenSurveys(
            surveyList
              .filter(
                (s) => s.status === SURVEY_STATUS.OPEN && s["total_sites"] > 0
              )
              .sort((a, b) =>
                moment(a.deadline).isBefore(b.deadline) ? -1 : 1
              )
          );
        })
        .catch((e) => {
          if (isRequestAborted(e)) {
            return;
          }

          const response = e.response;
          if (response.status !== 403) {
            toastContext.addFailToast(<span>Failed to load surveys.</span>);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [
    userInventory.isLoadingInventory.get,
    userInventory.selectedInventory.get,
  ]);

  const getSurveyProgress = (survey) => {
    return (survey["completed_sites"] / survey["total_sites"]) * 100;
  };

  const redirectToSurveyPage = (event, surveyId) => {
    event.stopPropagation();
    if (surveyId) {
      history.push(getViewSurveyPath(surveyId));
    } else {
      history.push(SURVEYS);
    }
  };

  let content;
  if (locked) {
    content = (
      <FeatureLockWidgetContent
        subscriptionFeatureStatus={subscriptionFeatureStatus}
      />
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  } else if (openSurveys.length === 0) {
    content = (
      <BaseWidgetEmptyContent
        title={t("landing-page.widget-survey.portfolio-no-content")}
      />
    );
  } else {
    content = (
      <div className="landing-page-widget__list__container">
        {openSurveys.map((sur) => {
          return (
            <div
              key={sur.id}
              className="landing-page-widget__list-item__container"
              onClick={(e) => redirectToSurveyPage(e, sur.id)}
            >
              <div className="landing-page-widget__list-item__header">
                <div className="landing-page-widget__list-item__header-text">
                  {sur.name}
                </div>
                <div className="landing-page-widget__list-item__header-date">
                  {WidgetService.getDeadlineDueMessage(sur.deadline)}
                </div>
              </div>
              <div className="landing-page-widget__list-item__progress">
                <CustomProgressBar
                  progress={getSurveyProgress(sur)}
                  description={"sites completed"}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className="base-widget__container-clickable"
      onClick={redirectToSurveyPage}
    >
      <div className="base-widget__heading">
        <div className="base-widget__heading__title-text">
          {t("landing-page.widget-survey.title")}{" "}
          <BaseWidgetHeadingTooltip
            info={t("landing-page.widget-survey.portfolio-tooltip")}
          />
        </div>
      </div>
      {content}
    </div>
  );
};

export const SiteSurveyWidget = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
    [FEATURES.SURVEY__ANSWER],
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    userProfile
  );
  const locked =
    subscriptionFeatureStatus !== SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

  const [isLoading, setIsLoading] = useState(true);
  const [openSurveys, setOpenSurveys] = useState([]);

  const abortControllerRef = useRef(null);

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;
    if (
      !userInventory.isLoadingInventory.get &&
      selectedInventory &&
      selectedInventory.id
    ) {
      setIsLoading(true);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      SurveyAssessmentService.getAllSurvey(
        selectedInventory.type,
        selectedInventory.id,
        abortControllerRef.current.signal
      )
        .then((surveyList) => {
          setOpenSurveys(
            surveyList
              .filter(
                (s) => s.status === SURVEY_STATUS.OPEN && !s.assessment_complete
              )
              .sort((a, b) =>
                moment(a.deadline).isBefore(b.deadline) ? -1 : 1
              )
          );
        })
        .catch((e) => {
          if (isRequestAborted(e)) {
            return;
          }

          const response = e.response;
          if (response.status !== 403) {
            toastContext.addFailToast(<span>Failed to load surveys.</span>);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [
    userInventory.isLoadingInventory.get,
    userInventory.selectedInventory.get,
  ]);

  const getSurveyProgress = (survey) => {
    return (survey["completed_questions"] / survey["total_questions"]) * 100;
  };

  const redirectToSurveyPage = (event, surveyId) => {
    event.stopPropagation();
    if (surveyId) {
      history.push(getViewSurveyPath(surveyId));
    } else {
      history.push(SURVEYS);
    }
  };

  let content;
  if (locked) {
    content = (
      <FeatureLockWidgetContent
        subscriptionFeatureStatus={subscriptionFeatureStatus}
      />
    );
  } else if (isLoading) {
    content = <LoadingSpinner />;
  } else if (openSurveys.length === 0) {
    content = (
      <BaseWidgetEmptyContent
        title={t("landing-page.widget-survey.site-no-content")}
      />
    );
  } else {
    content = (
      <div className="landing-page-widget__list__container">
        {openSurveys.map((sur) => {
          return (
            <div
              key={sur.id}
              className="landing-page-widget__list-item__container"
              onClick={(e) => redirectToSurveyPage(e, sur.survey_id)}
            >
              <div className="landing-page-widget__list-item__header">
                <div className="landing-page-widget__list-item__header-text">
                  {sur.name}
                </div>
                <div className="landing-page-widget__list-item__header-date">
                  {WidgetService.getDeadlineDueMessage(sur.deadline)}
                </div>
              </div>
              <div className="landing-page-widget__list-item__progress">
                <CustomProgressBar
                  progress={getSurveyProgress(sur)}
                  description={"answered"}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className="base-widget__container-clickable"
      onClick={redirectToSurveyPage}
    >
      <div className="base-widget__heading">
        <div className="base-widget__heading__title-text">
          {t("landing-page.widget-survey.title")}{" "}
          <BaseWidgetHeadingTooltip
            info={t("landing-page.widget-survey.site-tooltip")}
          />
        </div>
      </div>
      {content}
    </div>
  );
};
