import React, { useContext, useEffect, useRef, useState } from "react";
import { cloneDeep, xorBy } from "lodash";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { FileStorageObjectLinkDisplay } from "./FileStorageItemDisplay";
import GVFormGroup from "../common/GVFormGroup";
import Row from "react-bootstrap/Row";
import FileStorageService from "../../services/FileStorageService";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import FileStorageStateContext from "../../context/FileStorageStateContext";
import AboutTagsTooltip from "../common/CentralTags/AboutTags";
import UserManagedTagPicker from "../common/CentralTags/UserManagedTagPicker";
import { RESOURCES } from "../../config/constants";
import { InventoryUtils } from "../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormMultiSelect from "../../gvds-components/Forms/GVDSFormMultiSelect";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import GVDSIconCustom from "../../gvds-components/Icons/GVDSIconCustom";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSFormTextArea from "../../gvds-components/Forms/GVDSFormTextArea";
import { useTranslation } from "react-i18next";

const isFileAttributeChange = (editedFiles) => {
  return editedFiles.some(
    (file) =>
      file.name !== file.newName ||
      file.description !== file.newDescription ||
      xorBy(file.associatedModules, file.newAssociatedModules, "id").length >
        0 ||
      xorBy(file.tags, file.newTags, "id").length > 0
  );
};

const FileStorageEdit = ({
  filesToBeEdited,
  goToFileStorageMain,
  onEditSuccess,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const fileStorageStateContext = useContext(FileStorageStateContext);

  const pageHeaderRef = useRef();

  const [isLoading, setIsLoading] = useState(true);

  const [editedFiles, setEditedFiles] = useState([]);
  const [associatedModuleOptions, setAssociatedModuleOptions] = useState([]);
  const [portfolioTagOptions, setPortfolioTagOptions] = useState([]);
  const [siteTagOptionsBySiteIds, setSiteTagOptionsBySiteIds] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [conflictingFileNames, setConflictingFileNames] = useState([]);

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;

    FileStorageService.getAllFileStorageOptions(
      selectedInventory.type,
      selectedInventory.id
    )
      .then((data) => {
        setAssociatedModuleOptions(data.associated_modules);
        setPortfolioTagOptions(data.portfolio_tags);
        setSiteTagOptionsBySiteIds(data.site_tags_by_site_ids);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load file storage options</span>
        );
      });
  }, []);

  useEffect(() => {
    setEditedFiles(
      cloneDeep(filesToBeEdited).map((f) => {
        f.newName = f.name;
        f.newDescription = f.description;
        f.newAssociatedModules = f.associatedModules;
        f.newPortfolioTags = f.portfolioTags;
        f.newSiteTags = f.siteTags;

        return f;
      })
    );
  }, [filesToBeEdited]);

  const saveEdits = async () => {
    if (
      editedFiles.some((fileStorageObject) =>
        getFilenameInvalidErrorMsg(t, fileStorageObject)
      )
    ) {
      return;
    }

    const selectedInventory = userInventory.selectedInventory.get;

    const fileNames =
      FileStorageService.getFileNamesValidationPayloadForEdit(editedFiles);

    setIsLoading(true);
    fileStorageStateContext.setIsLoading(true);

    try {
      await FileStorageService.validateFileNames(
        selectedInventory.type,
        selectedInventory.id,
        fileNames
      );
    } catch (e) {
      fileStorageStateContext.setIsLoading(false);
      setIsLoading(false);

      if (e.response.data.message.conflicts) {
        setConflictingFileNames(e.response.data.message.conflicts);
      }

      setErrorMessage(
        <>
          {e.response.data.message.message}
          {e.response.data.message.conflicts?.join("\n")}
        </>
      );

      if (pageHeaderRef.current) {
        pageHeaderRef.current.scrollIntoView();
      }
      return;
    }

    try {
      await FileStorageService.updateFileStorageObjectDetails(
        selectedInventory.type,
        selectedInventory.id,
        editedFiles
      );

      toastContext.addSuccessToast(<span>Files edited successfully.</span>);

      if (onEditSuccess) {
        onEditSuccess();
      }

      fileStorageStateContext.setIsLoading(false);
      setIsLoading(false);
      backToAllFiles();
    } catch (e) {
      fileStorageStateContext.setIsLoading(false);
      setIsLoading(false);
      toastContext.addFailToast(<span>Failed to edit files.</span>);
    }
  };

  const onChange = () => {
    setEditedFiles([...editedFiles]);
    fileStorageStateContext.setHasChanges(isFileAttributeChange(editedFiles));
  };

  const backToAllFiles = () => {
    fileStorageStateContext.setHasChanges(false);
    goToFileStorageMain();
  };

  const showPortfolioTag =
    InventoryUtils.isNotIndividualSiteSubscription(userInventory);

  return (
    <>
      <PageHeader.BackButton
        text={t("file-storage.edit.back-page-label")}
        onClick={backToAllFiles}
      />
      <div className="file-storage-header" ref={pageHeaderRef}>
        <h1>{t("file-storage.edit.page-title")}</h1>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {errorMessage && (
            <GVDSBanner
              title={t("file-storage.edit.error-while-saving")}
              variant={GVDSBanner.Variants.error}
            >
              <div style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</div>
            </GVDSBanner>
          )}
          {editedFiles.map((f, index) => (
            <FileStorageDetailsForm
              key={f.id}
              index={index + 1}
              file={f}
              associatedModuleOptions={associatedModuleOptions}
              portfolioTagOptions={portfolioTagOptions}
              showPortfolioTag={showPortfolioTag}
              siteTagOptions={siteTagOptionsBySiteIds[f.site.id] ?? []}
              onChange={onChange}
              conflictingFileNames={conflictingFileNames}
            />
          ))}
          <div className="d-flex mt-2">
            <GVDSButton
              variant={buttonVariant.tertiary}
              className="ms-auto me-1"
              onClick={backToAllFiles}
              text={t("file-storage.edit.back-page-label")}
            />
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={saveEdits}
              text={t("shared-modal.footer.save")}
            />
          </div>
        </>
      )}
    </>
  );
};

const isSameFileExtension = (file) => {
  const newNameOldNameNoExtension =
    !file.name?.includes(".") && !file.newName?.includes(".");
  if (newNameOldNameNoExtension) {
    return true;
  }

  const oldExtension = file.name?.split(".").pop();
  const newExtension = file.newName?.split(".").pop();
  return oldExtension === newExtension;
};

const getFilenameInvalidErrorMsg = (t, file) => {
  if (!file.newName || file.newName.trim().length === 0) {
    return t(
      "file-storage.edit.validation-error-message.empty-required-file-name"
    );
  } else if (
    file.name?.includes(".") &&
    (!file.newName?.includes(".") ||
      file.newName?.split(".").pop().length === 0)
  ) {
    return t(
      "file-storage.edit.validation-error-message.file-name-require-extension"
    );
  } else {
    return null;
  }
};

const FileStorageDetailsForm = ({
  index,
  file,
  associatedModuleOptions,
  portfolioTagOptions,
  showPortfolioTag,
  siteTagOptions,
  onChange,
  conflictingFileNames,
}) => {
  const { t } = useTranslation();

  const filenameValidityErrorMessage = getFilenameInvalidErrorMsg(t, file);
  const isFilenameInvalid = !!filenameValidityErrorMessage;

  return (
    <div className="file-storage-edit-display">
      <div className="file-storage-edit-body">
        <div className="file-storage-edit-index">{index}</div>
        <div className="file-storage-edit-content">
          <div className="mb-3">
            <FileStorageObjectLinkDisplay fileStorageObject={file} />
          </div>
          <GVFormGroup controlId="fileName">
            <Form.Label>{t("file-storage.edit.label-file-name")}</Form.Label>
            <GVDSFormField
              type="text"
              name="fileName"
              value={file.newName}
              onInput={(value) => {
                file.newName = value;
                onChange();
              }}
              statusMetadata={
                isFilenameInvalid
                  ? FormFieldStatusMetadata.getError(
                      filenameValidityErrorMessage
                    )
                  : conflictingFileNames.indexOf(file.newName?.trim()) >= 0
                  ? FormFieldStatusMetadata.getError(
                      t(
                        "file-storage.edit.validation-error-message.file-name-already-exists"
                      )
                    )
                  : !isSameFileExtension(file)
                  ? FormFieldStatusMetadata.getWarning(
                      t(
                        "file-storage.edit.validation-error-message.file-extension-changed"
                      )
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <Row>
            {showPortfolioTag && (
              <Col>
                <GVFormGroup controlId="fileStoragePortfolioTags">
                  <div className="form-label-row">
                    <Form.Label>
                      {t("shared-input-label.portfolio-tags")}{" "}
                      <span className="optional-form-label">
                        ({t("shared-input-label.optional")})
                      </span>
                    </Form.Label>
                    <AboutTagsTooltip
                      asText={false}
                      triggerContent={<GVDSIconCustom.Info />}
                      tagResourceType={RESOURCES.PORTFOLIO}
                    />
                  </div>
                  <UserManagedTagPicker
                    resourceType={RESOURCES.PORTFOLIO}
                    selectedTags={file.newPortfolioTags}
                    allTags={portfolioTagOptions}
                    onChange={(selectedPortfolioTagsInput) => {
                      file.newPortfolioTags = selectedPortfolioTagsInput.map(
                        (tag) => {
                          return { id: tag.value, name: tag.label };
                        }
                      );
                      onChange();
                    }}
                    className="portfolio-tag"
                  />
                </GVFormGroup>
              </Col>
            )}
            <Col>
              <GVFormGroup controlId="fileStorageSiteTags">
                <div className="form-label-row">
                  <Form.Label>
                    {t("shared-input-label.site-tags")}{" "}
                    <span className="optional-form-label">
                      ({t("shared-input-label.optional")})
                    </span>
                  </Form.Label>
                  <AboutTagsTooltip
                    asText={false}
                    triggerContent={<GVDSIconCustom.Info />}
                    tagResourceType={RESOURCES.SITE}
                  />
                </div>
                <UserManagedTagPicker
                  resourceType={RESOURCES.SITE}
                  selectedTags={file.newSiteTags}
                  allTags={siteTagOptions}
                  onChange={(selectedSiteTagsInput) => {
                    file.newSiteTags = selectedSiteTagsInput.map((tag) => {
                      return { id: tag.value, name: tag.label };
                    });
                    onChange();
                  }}
                  className="site-tag"
                />
              </GVFormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <GVFormGroup controlId="associatedModules">
                <Form.Label>
                  {t("file-storage.shared.label-associated-modules")}{" "}
                  <span className="optional-form-label">
                    ({t("shared-input-label.optional")})
                  </span>
                </Form.Label>
                <GVDSFormMultiSelect
                  name="associated-modules"
                  closeMenuOnSelect={false}
                  isClearable={false}
                  value={file.newAssociatedModules.map((m) => {
                    return { label: m.name, value: m.id };
                  })}
                  onSelect={(values) => {
                    file.newAssociatedModules = values.map((v) => {
                      return { id: v.value, name: v.label };
                    });
                    onChange();
                  }}
                  options={associatedModuleOptions.map((o) => ({
                    label: o.name,
                    value: o.id,
                  }))}
                  className="associated-module-select"
                />
              </GVFormGroup>
            </Col>
            <Col>
              <GVFormGroup controlId="description">
                <Form.Label>
                  {t("shared-input-label.description")}{" "}
                  <span className="optional-form-label">
                    ({t("shared-input-label.optional")})
                  </span>
                </Form.Label>
                <GVDSFormTextArea
                  type="text"
                  name="description"
                  rows={1}
                  placeholder="Versioning, reference no., other info"
                  value={file.newDescription}
                  onInput={(value) => {
                    file.newDescription = value;
                    onChange();
                  }}
                />
              </GVFormGroup>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FileStorageEdit;
