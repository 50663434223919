import axios from "axios";
import { API_URL } from "../config/api-config";

export class SiteListItemModel {
  constructor(
    id,
    name,
    status,
    users,
    features,
    associatedContracts,
    endDate,
    comment,
    accountManager
  ) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.users = users;
    this.features = features;
    this.associatedContracts = associatedContracts;
    this.endDate = endDate;
    this.comment = comment;
    this.accountManager = accountManager;
  }

  get associatedContractNames() {
    return this.associatedContracts.map((contract) => contract.name);
  }

  static fromDTO(siteListItemDTO) {
    return new SiteListItemModel(
      siteListItemDTO["id"],
      siteListItemDTO["name"],
      siteListItemDTO["status"],
      siteListItemDTO["users"],
      siteListItemDTO["features"],
      siteListItemDTO["associated_contracts"],
      siteListItemDTO["end_date"],
      siteListItemDTO["comment"],
      siteListItemDTO["account_manager"]
    );
  }
}

export default class SiteService {
  static createNewSite = async (site) => {
    try {
      const response = await axios.post(API_URL + "/api/v1/sites", site);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getAllSites = async () => {
    try {
      const response = await axios.get(API_URL + "/api/v1/sites");
      return response.data
        .map((s) => SiteListItemModel.fromDTO(s))
        .sort((site1, site2) => site1.name.localeCompare(site2.name));
    } catch (error) {
      throw error;
    }
  };

  static getSite = async (siteId) => {
    try {
      const response = await axios.get(API_URL + "/api/v1/sites/" + siteId);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editSite = async (siteId, editedFields) => {
    try {
      const response = await axios.patch(
        `${API_URL}/api/v1/sites/${siteId}`,
        editedFields
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static addUserToSite = async (siteId, email, roleId, as_billing_admin) => {
    try {
      const data = {
        email,
        roleId,
        as_billing_admin,
      };

      const response = await axios.post(
        API_URL + "/api/v1/sites/" + siteId + "/users",
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editUserRole = async (site_id, user_id, role_id, as_billing_admin) => {
    try {
      const data = {
        role_id,
        as_billing_admin,
      };

      const response = await axios.put(
        `${API_URL}/api/v1/sites/${site_id}/users/${user_id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static removeUserFromSite = async (site_id, user_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/sites/${site_id}/users/${user_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteInviteFromSite = async (site_id, invite_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/sites/${site_id}/invites/${invite_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateSiteApprovedDomains = async (site_id, approved_domains) => {
    try {
      const data = {
        approved_domains,
      };

      const response = await axios.put(
        `${API_URL}/api/v1/sites/${site_id}/approved_domains`,
        data
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static getUsers = async (siteId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/sites/" + siteId + "/users"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static getSiteFacilityOptions = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/site_facility_options`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  static getCreateSiteOptions = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/create_site_options`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  static getSiteFacilityTypeOptions = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/site_facility_type_options`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  static updateAccountManager = async (site_id, user_id) => {
    try {
      const data = { user_id };

      const response = await axios.put(
        `${API_URL}/api/v1/sites/${site_id}/account_manager`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static removeAccountManager = async (site_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/sites/${site_id}/account_manager`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
