import axios from "axios";
import { API_URL } from "../config/api-config";
import { DateTimeUtils, HttpResponseService } from "./UtilsService";
import { MarketPurchaseOffsetRecordModel } from "../components/Data/Environmental/MarketPurchaseOffsets/MarketPurchaseOffsetModel";
import _ from "lodash";

export default class MarketPurchaseOffsetsService {
  static getAllTypes = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/market_purchase_offset_types"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static createTracker = async (
    resource_type,
    resource_id,
    tracker_details
  ) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/market_purchase_offset_trackers",
        tracker_details,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateTracker = async (
    resource_type,
    resource_id,
    tracker_details
  ) => {
    try {
      const response = await axios.put(
        API_URL +
          "/api/v1/market_purchase_offset_trackers/" +
          tracker_details.id,
        tracker_details,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getAllTrackers = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/market_purchase_offset_trackers",
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static deleteTracker = async (id_for_tracker, resource_type, resource_id) => {
    try {
      const response = await axios.delete(
        API_URL + "/api/v1/market_purchase_offset_trackers/" + id_for_tracker,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static createRecord = async (resource_type, resource_id, new_record) => {
    try {
      const response = await axios.post(
        API_URL + "/api/v1/market_purchase_offset_records",
        {
          ...new_record,
          period_from: DateTimeUtils.getUTCISOString(new_record["period_from"]),
          period_to: DateTimeUtils.getUTCISOString(new_record["period_to"]),
        },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static editRecord = async (
    resource_type,
    resource_id,
    record_id,
    edited_record
  ) => {
    try {
      const response = await axios.put(
        API_URL + "/api/v1/market_purchase_offset_records/" + record_id,
        {
          ...edited_record,
          period_from: DateTimeUtils.getUTCISOString(
            edited_record["period_from"]
          ),
          period_to: DateTimeUtils.getUTCISOString(edited_record["period_to"]),
        },
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteDataRecords = async (resource_type, resource_id, recordIds) => {
    try {
      return await axios.delete(
        `${API_URL}/api/v1/market_purchase_offset_records`,
        {
          data: { record_ids: recordIds },
          params: { resource_type, resource_id },
        }
      );
    } catch (error) {
      throw error;
    }
  };

  static getAllRecords = async (resource_type, resource_id, start, end) => {
    try {
      const params = { resource_type, resource_id };
      if (start) params["start"] = DateTimeUtils.getUTCISOString(start);
      if (end) params["end"] = DateTimeUtils.getUTCISOString(end);
      const response = await axios.get(
        API_URL + "/api/v1/market_purchase_offset_records",
        { params }
      );
      return response.data.map((d) =>
        MarketPurchaseOffsetRecordModel.fromJson(d)
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static getTrackerRecords = async (
    resource_type,
    resource_id,
    id_for_tracker,
    start,
    end
  ) => {
    try {
      const params = {
        resource_type,
        resource_id,
        start: DateTimeUtils.getUTCISOString(start),
        end: DateTimeUtils.getUTCISOString(end),
      };
      const response = await axios.get(
        API_URL +
          "/api/v1/market_purchase_offset_trackers/" +
          id_for_tracker +
          "/records",
        { params }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static getTrackerUsage = async (
    resource_type,
    resource_id,
    id_for_tracker,
    start,
    end
  ) => {
    try {
      const params = {
        resource_type,
        resource_id,
        start: DateTimeUtils.getUTCISOString(start),
        end: DateTimeUtils.getUTCISOString(end),
      };
      const response = await axios.get(
        `${API_URL}/api/v1/market_purchase_offset_trackers/${id_for_tracker}/usages`,
        { params }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static downloadMarketPurchaseOffsetRecordExcel = async (
    resourceType,
    resourceId,
    start,
    end,
    facilityIds,
    typeIds,
    trackerIds
  ) => {
    const params = {
      resource_id: resourceId,
      resource_type: resourceType,
    };
    const data = {};
    if (start) data["start"] = DateTimeUtils.getUTCISOString(start);
    if (end) data["end"] = DateTimeUtils.getUTCISOString(end);
    if (!_.isEmpty(facilityIds)) data["facility_ids"] = facilityIds;
    if (!_.isEmpty(typeIds)) data["tracker_type_ids"] = typeIds;
    if (!_.isEmpty(trackerIds)) data["tracker_ids"] = trackerIds;

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/market_purchase_offset_records/download`,
        data,
        {
          params: params,
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Market Purchase Offset Record Excel.xlsx"
      );
    } catch (error) {
      throw error;
    }
  };
}
