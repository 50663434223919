import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  DateTimeUtils,
  NumberService,
  StringUtils,
} from "../../../../services/UtilsService";
import SubscriptionSharedUtils, {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  SubscriptionPaymentType,
  SubscriptionPricingType,
  TAX_COUNTRY_NAME_SINGAPORE,
} from "../SubscriptionSharedUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipPersistentOnHover from "../../../common/Tooltip/TooltipPersistentOnHover";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import {
  IconArrowBackUp,
  IconCircleFilled,
  IconCirclePlus,
  IconGitBranch,
  IconPencilDown,
  IconUnlink,
  IconX,
} from "@tabler/icons-react";
import GVDSFormSingleDatePicker from "../../../../gvds-components/Forms/GVDSFormSingleDatePicker";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import FilterContractSubscriptionColumnPopUp, {
  filterOptionEnum,
} from "./FilterContractSubscriptionColumnPopUp";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import Table from "react-bootstrap/Table";
import { OverlayTrigger, Popover } from "react-bootstrap";
import fuzzysort from "fuzzysort";
import { fuzzysortOptionsWithKeys } from "../../../../config/search-config";
import _ from "lodash";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import FilterSearchBox from "../../../../gvds-components/common/FilterSearchBox";
import { CONTRACT_SUBSCRIPTION_INPUT_MODES } from "./InputContractSubscription";
import SelectSubscriptionFeatureModal from "./SelectSubscriptionFeatureModal";
import SelectIndividualSitesModal from "./SelectIndividualSitesModal";
import BulkSelectSitesModal from "./BulkSelectSitesModal";
import SubscriptionService from "../../../../services/SubscriptionService";
import { RESOURCES } from "../../../../config/constants";
import { useParams } from "react-router-dom";
import ToastContext from "../../../../context/ToastContext";
import SiteSubscriptionModel, {
  SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE,
} from "../SiteSubscription/SiteSubscriptionModel";
import SubscriptionPaymentBadge, {
  SUBSCRIPTION_PAYMENT,
} from "../SubscriptionPaymentBadge";
import GVDSCustomIcons from "../../../../gvds-components/Icons/GVDSIconCustom";
import Form from "react-bootstrap/Form";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import { TOOLTIP_PLACEMENTS } from "../../../../config/style-config";

const SiteSubscriptionFeaturesDisplayCell = ({
  subscription,
  allFeatures,
  showSiteSubscriptionFeatures,
  revertToContractFeatures,
}) => {
  const [show, setShow] = useState(false);

  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  return (
    <OverlayTrigger
      show={show}
      overlay={
        <Popover
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <Popover.Body>
            {SubscriptionSharedUtils.getFeaturesList(
              allFeatures,
              subscription.features,
              () => showSiteSubscriptionFeatures(subscription)
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <td
        className="features-column clickable"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onClick={() => showSiteSubscriptionFeatures(subscription)}
      >
        <div className="d-flex flex-row align-items-center">
          <div className="show-subscription-feature__container">
            {allFeatures && allFeatures.length === subscription.features.length
              ? "All features "
              : "Partial features "}
            <GVDSCustomIcons.Info className="subscription-features-icon" />
          </div>
          <RevertToContractTermButton
            onRevert={(e) => {
              e.stopPropagation();
              revertToContractFeatures(subscription);
            }}
          />
        </div>
      </td>
    </OverlayTrigger>
  );
};

const getRemoveButton = (subscription, removeSubscription) => {
  const removeButton = (
    <GVDSIconButton
      variant={
        subscription.canDeleteFromContractSubscription
          ? iconButtonVariant.destructive
          : iconButtonVariant.tertiary
      }
      disabled={!subscription.canDeleteFromContractSubscription}
      onClick={() => removeSubscription(subscription)}
      icon={<GVDSIcon Icon={IconX} />}
    />
  );

  return subscription.canDeleteFromContractSubscription ? (
    removeButton
  ) : (
    <TooltipPersistentOnHover
      triggerContent={removeButton}
      tooltipContent="Sites cannot be removed during mid-subscription. Change the end period of the site subscription instead to achieve a similar outcome."
    />
  );
};

const getNoSiteSelectedView = () => {
  return (
    <div className="selected-sites__no-content">
      <FontAwesomeIcon
        icon={faTimesCircle}
        className="selected-sites__no-content-icon"
      />
      <div className="title">No sites added</div>
      <div className="sub-title">
        To start, click on "Select individual site(s)" or "Bulk select sites" to
        begin.
      </div>
    </div>
  );
};

const SiteSearchBox = ({ value, setValue }) => {
  return (
    <FilterSearchBox
      placeholder="Search a site in this list"
      onInput={(value) => setValue(value)}
      value={value}
    />
  );
};

const SiteDeviantIcon = () => {
  return (
    <TooltipPersistentOnHover
      triggerContent={
        <GVDSIcon Icon={IconGitBranch} className="ms-2 deviant-icon" />
      }
      tooltipContent="This site's start period, end period, or subscribed features is not following this contract subscription's terms."
    />
  );
};

const FollowsContractTermCell = ({ onUnlink, className }) => {
  return (
    <OverlayTrigger
      overlay={
        <Popover>
          <Popover.Body>
            Click the icon to break link and define terms specific to this site.
          </Popover.Body>
        </Popover>
      }
    >
      <td className={"follows-contract-term-cell " + className}>
        <div className="follows-contract-term">
          <div className="follows-contract-term-label">
            follows contract term
          </div>

          <GVDSIconButton
            variant={iconButtonVariant.tertiary}
            className="follows-contract-term-button link-primary"
            onClick={onUnlink}
            icon={<GVDSIcon Icon={IconUnlink} />}
          />
        </div>
      </td>
    </OverlayTrigger>
  );
};

const RevertToContractTermButton = ({ onRevert }) => {
  return (
    <TooltipPersistentOnHover
      triggerContent={
        <GVDSIconButton
          variant={iconButtonVariant.tertiary}
          className="unlink-contract-term-button link-primary"
          onClick={onRevert}
          icon={<GVDSIcon Icon={IconArrowBackUp} />}
        />
      }
      triggerClassName="ms-auto"
      tooltipContent="Click to revert to follow contract terms."
    />
  );
};

const InputContractSubscriptionSiteSelection = ({
  model,
  onDataChange,
  inputMode,
  isValidated,
  allFeatures,
  allSites,
  setAllSites,
  orderedPackagePlans,
}) => {
  const toastContext = useContext(ToastContext);
  const { contractId, subscriptionId } = useParams();

  const [siteSearchText, setSiteSearchText] = useState("");

  const [filteredSiteSubscriptions, setFilteredSiteSubscriptions] = useState(
    []
  );
  const [additionalFilters, setAdditionalFilters] = useState(null);

  const [globalPriceAmountValue, setGlobalPriceAmountValue] = useState("");

  const [
    showSelectSubscriptionFeatureModal,
    setShowSelectSubscriptionFeatureModal,
  ] = useState(false);
  const [
    siteSubscriptionCustomFeaturesInput,
    setSiteSubscriptionCustomFeaturesInput,
  ] = useState([]);
  const [selectedSiteSubscription, setSelectedSiteSubscription] = useState();

  const [showSelectIndividualSite, setShowSelectIndividualSite] =
    useState(false);
  const [showBulkSelectSite, setShowBulkSelectSite] = useState(false);

  const plan = orderedPackagePlans.find(
    (p) => p.package_plan_id === model.planId
  );

  const isCustomPlan = plan?.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;

  let sitePrice = null;

  if (!isCustomPlan) {
    const price = plan?.prices.find(
      (price) => price.billing_cycle === model.billingCycle
    );
    sitePrice = price?.price_in_decimal;
  }

  useEffect(() => {
    const filteredSubscriptionsByKeyword =
      siteSearchText.length > 0
        ? fuzzysort
            .go(
              siteSearchText,
              model.siteSubscriptions,
              fuzzysortOptionsWithKeys(["site.name"])
            )
            .map((result) => result.obj)
        : model.siteSubscriptions;

    const filtered = filteredSubscriptionsByKeyword.filter((s) => {
      let matchFilter = true;

      if (additionalFilters) {
        const paymentStatusFilter =
          model.pricingType === SubscriptionPricingType.FREE
            ? true
            : additionalFilters.paymentStatus.value ===
              filterOptionEnum.PAID_STATUS
            ? s.paymentStatus === SUBSCRIPTION_PAYMENT.PAID
            : additionalFilters.paymentStatus.value ===
              filterOptionEnum.UNPAID_STATUS
            ? s.paymentStatus === SUBSCRIPTION_PAYMENT.UNPAID
            : true;

        const startPeriodFilter =
          additionalFilters.startPeriod.value ===
          filterOptionEnum.FOLLOWING_TERM
            ? s.startPeriod === null
            : additionalFilters.startPeriod.value ===
              filterOptionEnum.NOT_FOLLOWING_TERM
            ? s.startPeriod !== null
            : true;

        const endPeriodFilter =
          additionalFilters.endPeriod.value === filterOptionEnum.FOLLOWING_TERM
            ? s.endPeriod === null
            : additionalFilters.endPeriod.value ===
              filterOptionEnum.NOT_FOLLOWING_TERM
            ? s.endPeriod !== null
            : true;

        const featureFilter =
          additionalFilters.features.value === filterOptionEnum.FOLLOWING_TERM
            ? s.features === null
            : additionalFilters.features.value ===
              filterOptionEnum.NOT_FOLLOWING_TERM
            ? s.features !== null
            : true;

        matchFilter =
          matchFilter &&
          paymentStatusFilter &&
          startPeriodFilter &&
          endPeriodFilter &&
          featureFilter;
      }

      return matchFilter;
    });

    setFilteredSiteSubscriptions(
      filtered.sort((a, b) => {
        return a.site.name.localeCompare(b.site.name);
      })
    );
  }, [siteSearchText, additionalFilters, model]);

  const removeSubscription = (subscription) => {
    const deletedIndex = model.siteSubscriptions.findIndex(
      (s) => s.site.id === subscription.site.id
    );
    model.siteSubscriptions.splice(deletedIndex, 1);
    onDataChange();
  };

  const TotalPriceDisplay = () => {
    const invoicePriceBySiteId = model.extraInvoiceSitePriceBySiteId;
    const priceAmounts = model.siteSubscriptions.map((subscription) =>
      invoicePriceBySiteId &&
      subscription.site.id in invoicePriceBySiteId &&
      invoicePriceBySiteId[subscription.site.id]
        ? Number(invoicePriceBySiteId[subscription.site.id])
        : subscription.priceAmount
        ? Number(subscription.priceAmount)
        : subscription.priceAmount
    );
    if (
      priceAmounts.length === 0 ||
      priceAmounts.some((amount) => amount === undefined || amount === "")
    ) {
      return (
        <span className="color-gray56">
          Total calculated when all site prices are filled
        </span>
      );
    } else {
      return (
        <span>
          {model.currencyId} {NumberService.format(_.sum(priceAmounts))}
        </span>
      );
    }
  };

  const getSelectedSitesTable = () => {
    const content = filteredSiteSubscriptions.map((siteSubscription, index) => {
      const isNewSiteOnEditingContractSubscription =
        inputMode === CONTRACT_SUBSCRIPTION_INPUT_MODES.EDIT &&
        siteSubscription.isNew();
      const proratedPriceAmount =
        model.extraInvoiceSitePriceBySiteId &&
        siteSubscription.site.id in model.extraInvoiceSitePriceBySiteId &&
        model.extraInvoiceSitePriceBySiteId[siteSubscription.site.id] <
          siteSubscription.priceAmount
          ? model.extraInvoiceSitePriceBySiteId[siteSubscription.site.id]
          : null;

      return (
        <tr key={siteSubscription.site.name}>
          <td
            className={
              "row-column" + highlightDifferentSubscription(siteSubscription)
            }
          >
            <div>
              {index + 1}
              {siteSubscription.underContractIsSubscriptionDifferentFromContract() && (
                <SiteDeviantIcon />
              )}
            </div>
          </td>
          <td className="site-name-column">
            {siteSubscription.site.name}
            {isNewSiteOnEditingContractSubscription && (
              <span className="green-label ms-2">NEW</span>
            )}
          </td>
          <td className="payment-status-column">
            {siteSubscription.paymentStatus && (
              <SubscriptionPaymentBadge
                payment={siteSubscription.paymentStatus}
              />
            )}
          </td>
          {siteSubscription.startPeriod !==
          SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE ? (
            <td className="start-period-column">
              <div className="unlink-contract-term">
                <GVDSFormSingleDatePicker
                  className="subscription-start-period-input"
                  selected={siteSubscription.startPeriod}
                  onChange={(date) => {
                    siteSubscription.startPeriod = date;
                    onDataChange();
                  }}
                  dateFormat="dd MMM yyyy"
                  statusMetadata={
                    isValidated &&
                    !model.isSiteSubscriptionStartPeriodValid(siteSubscription)
                      ? FormFieldStatusMetadata.getError()
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
                <RevertToContractTermButton
                  onRevert={() => revertStartPeriod(siteSubscription)}
                />
              </div>
              {isValidated &&
                !model.isSiteSubscriptionStartPeriodValid(siteSubscription) && (
                  <GVDSFormErrorMessage
                    status={FormFieldState.error}
                    errorMsg={SubscriptionSharedUtils.getStartPeriodValidationMessage(
                      siteSubscription.startPeriod,
                      siteSubscription.endPeriod,
                      model.startPeriod,
                      model.endPeriod
                    )}
                  />
                )}
            </td>
          ) : (
            <FollowsContractTermCell
              className="start-period-column"
              onUnlink={() => unlinkStartPeriod(siteSubscription)}
            />
          )}

          {siteSubscription.endPeriod !==
          SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE ? (
            <td className="end-period-column">
              <div className="unlink-contract-term">
                <GVDSFormSingleDatePicker
                  className="subscription-end-period-input"
                  selected={siteSubscription.endPeriod}
                  onChange={(date) => {
                    siteSubscription.endPeriod = date;
                    onDataChange();
                  }}
                  dateFormat="dd MMM yyyy"
                  statusMetadata={
                    isValidated &&
                    !model.isSiteSubscriptionEndPeriodValid(siteSubscription)
                      ? FormFieldStatusMetadata.getError()
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
                <RevertToContractTermButton
                  onRevert={() => {
                    revertEndPeriod(siteSubscription);
                  }}
                />
              </div>
              {isValidated &&
                !model.isSiteSubscriptionEndPeriodValid(siteSubscription) && (
                  <GVDSFormErrorMessage
                    status={FormFieldState.error}
                    errorMsg={SubscriptionSharedUtils.getEndPeriodValidationMessage(
                      siteSubscription.startPeriod,
                      siteSubscription.endPeriod,
                      model.startPeriod,
                      model.endPeriod
                    )}
                  />
                )}
            </td>
          ) : (
            <FollowsContractTermCell
              className="end-period-column"
              onUnlink={() => unlinkEndPeriod(siteSubscription)}
            />
          )}

          {siteSubscription.features !==
          SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE ? (
            <SiteSubscriptionFeaturesDisplayCell
              subscription={siteSubscription}
              allFeatures={allFeatures}
              showSiteSubscriptionFeatures={showSiteSubscriptionFeatures}
              revertToContractFeatures={revertToContractFeatures}
            />
          ) : (
            <FollowsContractTermCell
              className="features-column"
              onUnlink={() => unlinkFeatures(siteSubscription)}
            />
          )}
          {model.pricingType === SubscriptionPricingType.PAID && (
            <td className="price-amount-column">
              <div className="price-amount__container">
                <div>
                  <GVDSFormField
                    type="number"
                    onInput={(value) => {
                      siteSubscription.priceAmount = value;
                      onDataChange();
                    }}
                    value={siteSubscription.priceAmount}
                    statusMetadata={
                      isValidated &&
                      !siteSubscription.underContractIsPriceAmountValid()
                        ? FormFieldStatusMetadata.getError(
                            SubscriptionSharedUtils.getPriceAmountValidationMessage(
                              siteSubscription.priceAmount
                            )
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    disabled={!siteSubscription.isNew() || !isCustomPlan}
                    onWheel={(e) => {
                      e.target.blur();
                    }}
                  />
                </div>
                {proratedPriceAmount && (
                  <InfoTooltip
                    info={`Prorated price amount: ${proratedPriceAmount}`}
                    placement={TOOLTIP_PLACEMENTS.TOP}
                  />
                )}
              </div>
            </td>
          )}
          {model.pricingType === SubscriptionPricingType.PAID &&
            model.paymentType === SubscriptionPaymentType.DECENTRALIZED && (
              <td>
                <Form.Check
                  className="tax-switch"
                  type="switch"
                  checked={siteSubscription.isTaxApplicable}
                  disabled={true}
                />
              </td>
            )}
          <td>
            <GVDSFormTextArea
              rows={1}
              value={siteSubscription.comments}
              onInput={(value) => {
                siteSubscription.comments = value;
                onDataChange();
              }}
            />
          </td>
          <td className="action-buttons-column">
            {getRemoveButton(siteSubscription, removeSubscription)}
          </td>
        </tr>
      );
    });

    return (
      <>
        <GVDSTableCtrlContainer>
          <SiteSearchBox value={siteSearchText} setValue={setSiteSearchText} />
          <FilterContractSubscriptionColumnPopUp
            onApplyFilters={setAdditionalFilters}
            pricingType={model.pricingType}
          />
          {model.pricingType &&
            model.pricingType === SubscriptionPricingType.PAID && (
              <>
                <div className="action-divider"></div>
                <div className="d-flex align-items-center">
                  <div className="me-2">Quick-fill site value:</div>
                  <div>
                    <GVDSFormField
                      className={"input-global-value"}
                      type="number"
                      placeholder="value"
                      onInput={(value) => setGlobalPriceAmountValue(value)}
                      value={globalPriceAmountValue}
                      statusMetadata={
                        !isGlobalPriceAmountValid()
                          ? FormFieldStatusMetadata.getError(
                              "This field cannot be less than zero."
                            )
                          : FormFieldStatusMetadata.getDefault()
                      }
                    />
                  </div>
                  <GVDSIconButton
                    tooltipText="Apply to all rows"
                    icon={<GVDSIcon Icon={IconPencilDown} />}
                    className="btn-populate-price-amount"
                    variant={iconButtonVariant.tertiary}
                    onClick={populatePriceAmountsWithGlobalValue}
                  />
                </div>
              </>
            )}
          <div className="ms-auto">
            Showing {filteredSiteSubscriptions.length} of{" "}
            {model.siteSubscriptions.length} sites
          </div>
        </GVDSTableCtrlContainer>
        <Table hover className="site-subscription-table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Site Name</th>
              <th>Payment Status</th>
              <th>Start Period</th>
              <th>End Period</th>
              <th>Features</th>
              {model.pricingType === SubscriptionPricingType.PAID && (
                <th>Value ({model.currencyId})</th>
              )}
              {model.pricingType === SubscriptionPricingType.PAID &&
                model.paymentType === SubscriptionPaymentType.DECENTRALIZED && (
                  <th>GST</th>
                )}
              <th>Comment</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>
      </>
    );
  };

  const highlightDifferentSubscription = (siteSubscription) => {
    return siteSubscription.underContractIsSubscriptionDifferentFromContract()
      ? " different-subscription-cell"
      : "";
  };

  const isGlobalPriceAmountValid = () => {
    return globalPriceAmountValue === "" || globalPriceAmountValue >= 0;
  };

  const populatePriceAmountsWithGlobalValue = () => {
    if (isGlobalPriceAmountValid()) {
      model.siteSubscriptions.forEach((subscription) => {
        subscription.priceAmount = globalPriceAmountValue;
      });
      onDataChange();
    }
  };

  const revertStartPeriod = (subscription) => {
    subscription.startPeriod = SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE;
    onDataChange();
  };

  const unlinkStartPeriod = (subscription) => {
    subscription.startPeriod = "";
    onDataChange();
  };

  const revertEndPeriod = (subscription) => {
    subscription.endPeriod = SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE;
    onDataChange();
  };

  const unlinkEndPeriod = (subscription) => {
    subscription.endPeriod = "";
    onDataChange();
  };

  const revertToContractFeatures = (subscription) => {
    subscription.features = SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE;
    onDataChange();
  };

  const unlinkFeatures = (subscription) => {
    setSiteSubscriptionCustomFeaturesInput(model.features);
    setSelectedSiteSubscription(subscription);
    setShowSelectSubscriptionFeatureModal(true);
  };

  const showSiteSubscriptionFeatures = (subscription) => {
    setSiteSubscriptionCustomFeaturesInput(subscription.features);
    setSelectedSiteSubscription(subscription);
    setShowSelectSubscriptionFeatureModal(true);
  };

  const updateSiteSubscriptionFeatures = (selectedFeatures) => {
    selectedSiteSubscription.features = selectedFeatures;
    setSiteSubscriptionCustomFeaturesInput([]);
    setShowSelectSubscriptionFeatureModal(false);
    onDataChange();
  };

  const onCloseSiteSubscriptionFeatureModal = () => {
    setSiteSubscriptionCustomFeaturesInput([]);
    setShowSelectSubscriptionFeatureModal(false);
  };

  const reloadAllSites = async () => {
    try {
      const options = await SubscriptionService.getSubscriptionOptions(
        RESOURCES.CONTRACT,
        contractId
      );
      if (options) {
        setAllSites(options["sites"]);
      }
    } catch (e) {
      toastContext.addFailToast(<span>Failed to reload site list.</span>);
    }
  };

  const removeSubscriptionFromUnselectedSitesIfPossible = (selectedSites) => {
    model.siteSubscriptions = model.siteSubscriptions.filter((subscription) => {
      if (subscriptionId) {
        return (
          !subscription.canDeleteFromContractSubscription ||
          selectedSites.findIndex((site) => site.id === subscription.site.id) >
            -1
        );
      } else {
        return (
          selectedSites.findIndex((site) => site.id === subscription.site.id) >
          -1
        );
      }
    });
  };

  const addSubscriptionForSelectedSites = (selectedSites) => {
    const today = new Date().setHours(0, 0, 0, 0);

    selectedSites.forEach((site) => {
      if (
        model.siteSubscriptions.findIndex((s) => s.site.id === site.id) === -1
      ) {
        model.siteSubscriptions.push(
          new SiteSubscriptionModel({
            id: null,
            site: site,
            startPeriod:
              inputMode === CONTRACT_SUBSCRIPTION_INPUT_MODES.EDIT &&
              model.startPeriod < today
                ? today
                : null,
            endPeriod: null,
            features: null,
            paymentStatus:
              model.pricingType === SubscriptionPricingType.FREE
                ? SUBSCRIPTION_PAYMENT.FREE
                : SUBSCRIPTION_PAYMENT.UNPAID,
            isTaxApplicable:
              allSites.find((s) => s.id === site.id).country_name ===
              TAX_COUNTRY_NAME_SINGAPORE,
            priceAmount: sitePrice,
          })
        );
      }
    });
  };

  return (
    <>
      <div className="input-contract-subscription__site-selection">
        <div className="contract-term-summary__container">
          <div className="gv-heading-2">Contract terms summary</div>
          <Row className="contract-term-summary__details mt-3">
            <Col lg={6}>
              <table className="subscription-content-display">
                <colgroup>
                  <col className="label-column" />
                  <col className="value-column" />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Period</td>
                    <td>
                      {DateTimeUtils.formatLocalDate(model.startPeriod)} -{" "}
                      {DateTimeUtils.formatLocalDate(model.endPeriod)}
                    </td>
                  </tr>
                  <tr>
                    <td>Reminder Date</td>
                    <td>{DateTimeUtils.formatLocalDate(model.reminderDate)}</td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>
                      <div>
                        {model.pricingType === SubscriptionPricingType.PAID ? (
                          <TotalPriceDisplay />
                        ) : (
                          StringUtils.getTitleCase(model.pricingType ?? "")
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Subscribed Features</td>
                    <td>
                      {SubscriptionSharedUtils.getShorthandFeatureDisplay(
                        allFeatures,
                        model.features,
                        "right"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col lg={6}>
              <table className="subscription-content-display">
                <colgroup>
                  <col className="label-column" />
                  <col className="value-column" />
                </colgroup>
                <tbody>
                  <tr>
                    <td>No. of sites</td>
                    <td>
                      <div className="subscription-site-count">
                        {model.siteSubscriptions.length}
                        {SubscriptionSharedUtils.getSiteSubscriptionDifferentFromContractCount(
                          model.siteSubscriptions
                        ) > 0 && (
                          <>
                            <GVDSIcon
                              Icon={IconCircleFilled}
                              className="subscription__joined-bullet"
                            />
                            <TooltipPersistentOnHover
                              triggerClassName="subscription-site-count__deviant-site"
                              triggerContent={
                                <>
                                  <span>
                                    {SubscriptionSharedUtils.getSiteSubscriptionDifferentFromContractCount(
                                      model.siteSubscriptions
                                    )}
                                  </span>
                                  <GVDSIcon
                                    Icon={IconGitBranch}
                                    className="ms-2"
                                  />
                                </>
                              }
                              tooltipContent="No. of sites which start period, end period, or subscribed features is not following this contract subscription's terms."
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr className="comment-section">
                    <td>Comments</td>
                    <td>
                      {SubscriptionSharedUtils.getCommentDisplay(
                        model.comments
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
        <div className="site-selection__container">
          <div className="d-flex flex-row">
            <div className="gv-heading-2">
              Sites ({model.siteSubscriptions.length})
            </div>
            <GVDSButton
              variant={buttonVariant.primary}
              className="select-individual-site-button ms-auto me-2"
              onClick={() => {
                setShowSelectIndividualSite(true);
              }}
              icon={<GVDSIcon Icon={IconCirclePlus} />}
              text="Select individual site(s)"
            />
            <GVDSButton
              variant={buttonVariant.primary}
              className="bulk-select-site-button"
              onClick={() => setShowBulkSelectSite(true)}
              icon={<GVDSIcon Icon={IconCirclePlus} />}
              text="Bulk select sites"
            />
          </div>
          {model.siteSubscriptions.length === 0
            ? getNoSiteSelectedView()
            : getSelectedSitesTable()}
        </div>
      </div>
      <SelectSubscriptionFeatureModal
        showModal={showSelectSubscriptionFeatureModal}
        onCloseModal={onCloseSiteSubscriptionFeatureModal}
        allFeatures={allFeatures}
        contractFeatures={model.features}
        subscriptionFeatures={siteSubscriptionCustomFeaturesInput}
        onSave={updateSiteSubscriptionFeatures}
      />
      <SelectIndividualSitesModal
        allSites={allSites}
        reloadAllSites={reloadAllSites}
        siteSubscriptions={model.siteSubscriptions}
        showModal={showSelectIndividualSite}
        onCloseModal={() => setShowSelectIndividualSite(false)}
        onSuccess={(selectedSites) => {
          removeSubscriptionFromUnselectedSitesIfPossible(selectedSites);
          addSubscriptionForSelectedSites(selectedSites);
          onDataChange();
        }}
      />
      <BulkSelectSitesModal
        showModal={showBulkSelectSite}
        onCloseModal={() => setShowBulkSelectSite(false)}
        selectedSiteNames={model.siteSubscriptions.map(
          (subscription) => subscription.site.name
        )}
        allSites={allSites}
        reloadAllSites={reloadAllSites}
        onSelectSites={(selectedSites) => {
          addSubscriptionForSelectedSites(selectedSites);
          onDataChange();
        }}
      />
    </>
  );
};

export default InputContractSubscriptionSiteSelection;
