import React, { useContext, useEffect, useState } from "react";
import DashboardOptionContext from "../../Context/DashboardOptionContext";
import DashboardDataContext from "../../Context/DashboardDataContext";
import Tabs from "react-bootstrap/Tabs";
import DashboardSchemaParser from "../../DashboardSchemaParser";

export const LAST_TAB_STORAGE_KEY = "gv-dashboard-tabs-last-key";

const getLastTab = (tabNames) => {
  const lastTabKey = sessionStorage.getItem(LAST_TAB_STORAGE_KEY);

  if (lastTabKey === null || !tabNames.includes(lastTabKey)) {
    return null;
  } else {
    return lastTabKey;
  }
};

const saveLastTab = (tabKey) => {
  sessionStorage.setItem(LAST_TAB_STORAGE_KEY, tabKey);
};

export const DashboardTabs = ({ element }) => {
  const dashboardOptionContext = useContext(DashboardOptionContext);
  const selectedDashboard = dashboardOptionContext.selectedDashboard;

  const dashboardDataContext = useContext(DashboardDataContext);

  const [activeKey, setActiveKey] = useState(
    getLastTab(element["children"].map((tab) => tab["name"]))
  );

  const onTabInit = (tabName) => {
    const tab = element["children"].find((tab) => tab["name"] === tabName);

    if (tab !== undefined) {
      dashboardDataContext.dashboardHolderFacade.setNonSharedMenuSchema(
        tab["menu"]
      );
      dashboardDataContext.onUpdateConfig();
    }
  };

  useEffect(() => {
    if (
      !!selectedDashboard &&
      !dashboardDataContext.isInitialisingDashboardView
    ) {
      let tabName;

      if (activeKey === null) {
        tabName = element["children"][0]["name"];
        setActiveKey(tabName);
        saveLastTab(tabName);
      } else {
        tabName = activeKey;
      }

      onTabInit(tabName);
    }
  }, [
    selectedDashboard,
    dashboardDataContext.isInitialisingDashboardViewTimestamp,
  ]);

  const onChangeTab = (tabName) => {
    setActiveKey(tabName);
    saveLastTab(tabName);

    onTabInit(tabName);
  };

  return (
    <Tabs
      mountOnEnter
      activeKey={activeKey}
      onSelect={(eventKey) => onChangeTab(eventKey)}
    >
      {element["children"].map((c) => DashboardSchemaParser.parseElement(c))}
    </Tabs>
  );
};
