import { TabsProvider } from "./TabsContext";
import RoutePersistedTabs from "./RoutePersistedTabs";

const RoutePersistedTabsWithContext = (props) => {
  return (
    <TabsProvider>
      <RoutePersistedTabs {...props}>
        {props.children}
      </RoutePersistedTabs>
    </TabsProvider>
  )
}

export default RoutePersistedTabsWithContext