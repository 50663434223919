import React, { useContext, useEffect, useState } from "react";
import PerformanceTimelineChartView from "./PerformanceTimelineChartView";
import DashboardDataContext from "../../Context/DashboardDataContext";
import DashboardCalculator from "../../Models/DashboardCalculator";
import { PerformanceTimelineChartInputDataModel } from "../../Models/DashboardModels";
import { DATA_STATUS } from "../../../../config/constants";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";

const convertPerformanceTimelineDataToChartModel = (
  performanceTimelineData,
  allMonthsByYear
) => {
  const monthValueByClassificationValueByMonthPeriod =
    performanceTimelineData.value;
  const divisorValueByMonthPeriod =
    performanceTimelineData.divisorValue ?? null;
  const hasDataGaps = performanceTimelineData.status === DATA_STATUS.MISSING;

  const classificationValues = new Set();
  const monthPeriods = [];
  Object.keys(allMonthsByYear).forEach((year) => {
    allMonthsByYear[year].forEach((month) => {
      monthPeriods.push(new Date(year, month - 1, 1));
    });
  });
  const totalValueByClassificationValue = {};

  const chartInputModels = monthPeriods.map((monthPeriod) => {
    let monthValueByClassificationValue =
      monthValueByClassificationValueByMonthPeriod[monthPeriod];

    if (monthValueByClassificationValue === undefined) {
      monthValueByClassificationValue = {};
    } else {
      Object.keys(monthValueByClassificationValue).forEach(
        (classificationValue) => {
          classificationValues.add(classificationValue);

          if (
            monthValueByClassificationValue[classificationValue] !== null ||
            monthValueByClassificationValue[classificationValue] !== undefined
          ) {
            if (
              totalValueByClassificationValue[classificationValue] === undefined
            ) {
              totalValueByClassificationValue[classificationValue] = 0;
            }
            totalValueByClassificationValue[classificationValue] +=
              monthValueByClassificationValue[classificationValue];
          }
        }
      );
    }

    return new PerformanceTimelineChartInputDataModel(
      monthPeriod,
      monthValueByClassificationValue,
      divisorValueByMonthPeriod !== null
        ? divisorValueByMonthPeriod[monthPeriod]
        : null
    );
  });

  const classificationValuesFromBiggestValue = Array.from(
    classificationValues
  ).sort((classificationValue1, classificationValue2) => {
    const total1 = totalValueByClassificationValue[classificationValue1];
    const total2 = totalValueByClassificationValue[classificationValue2];

    return DashboardWidgetSharedUtils.classificationValueByBiggestValueSortFn(
      classificationValue1,
      total1,
      classificationValue2,
      total2
    );
  });

  return {
    data: chartInputModels,
    keys: classificationValuesFromBiggestValue,
    hasDataGaps,
  };
};

const PerformanceTimelineChart = ({ element }) => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const [performanceTimelineData, setPerformanceTimelineData] = useState(null);

  const config = element["config"];
  const subtopic = config["subtopic"];

  const isDataLoaded = dashboardHolderFacade.isAllRequiredDataPresent(
    [subtopic],
    true
  );

  useEffect(() => {
    if (isDataLoaded) {
      const performanceTimelineData =
        DashboardCalculator.getPerformanceTimelineData(
          config,
          dashboardHolderFacade
        );

      setPerformanceTimelineData(performanceTimelineData);
    }
  }, [dashboardDataContext.lastDataUpdated]);

  if (!isDataLoaded || performanceTimelineData === null) {
    return (
      <PerformanceTimelineChartView
        title="Performance history"
        titleInfoTooltip={element["description"]}
        period={dashboardHolderFacade.getContinuousTimeDisplay()}
        isLoading={true}
      />
    );
  }

  const {
    data: performanceTimelineChartData,
    keys: performanceTimelineChartKeys,
    hasDataGaps,
  } = convertPerformanceTimelineDataToChartModel(
    performanceTimelineData,
    dashboardHolderFacade.getAllContinuousTimeMonthYear()
  );

  return (
    <PerformanceTimelineChartView
      isLoading={false}
      title="Performance history"
      titleInfoTooltip={element["description"]}
      period={dashboardHolderFacade.getContinuousTimeDisplay()}
      hasDataGaps={hasDataGaps}
      chartData={performanceTimelineChartData}
      chartKeys={performanceTimelineChartKeys}
      subtopic={subtopic}
      intensity={DashboardCalculator.getIntensityDisplay(
        config,
        dashboardHolderFacade
      )}
      intensityUnit={DashboardCalculator.getDivisorUnit(
        config,
        dashboardHolderFacade
      )}
      unit={DashboardCalculator.getDividendUnit(config, dashboardHolderFacade)}
    />
  );
};

export default PerformanceTimelineChart;
