import React, { useEffect, useState } from "react";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { getRedirectURLWithCurrentParam } from "./QueryHandler";
import GVDSButton, { buttonVariant } from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";

const BeforeUrlChangesPrompt = ({ when, pageName, message }) => {
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState();
  const [confirmNavigation, setConfirmNavigation] = useState(false);

  useEffect(() => {
    if (confirmNavigation && nextLocation?.pathname) {
      history.push(
        getRedirectURLWithCurrentParam(nextLocation.pathname, nextLocation)
      );
    }
  }, [confirmNavigation]);

  const handleBlockedRoute = (loc) => {
    const isLocationChanged =
      location.pathname !== loc.pathname || location.search !== loc.search;
    if (!confirmNavigation && isLocationChanged) {
      setShowModal(true);
      setNextLocation(loc);
      return false;
    }
    return true;
  };

  const onModalClose = () => {
    setShowModal(false);
    setNextLocation(null);
  };

  const onLeave = () => {
    setShowModal(false);
    setConfirmNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedRoute} />
      <GVDSModal
        show={showModal}
        onHide={onModalClose}
        title={"Leave " + pageName}
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          <div>{message}</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            className="cancel-leave"
            variant={buttonVariant.tertiary}
            onClick={onModalClose}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={onLeave}
            text="Yes, leave page."
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default BeforeUrlChangesPrompt;
