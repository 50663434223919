import React from "react";

const ColorVariants = {
  green: "green",
  yellow: "yellow",
  red: "red",
  gray: "gray",
  blue: "blue",
  lavender: "lavender",
  lime: "lime",
};

const StatusLabel = React.forwardRef(
  ({ color = ColorVariants.gray, className = "", children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={`status-label label-${color} ${className}`}
        {...props}
      >
        {children}
      </div>
    );
  }
);

StatusLabel.Colors = ColorVariants;

export default StatusLabel;
