import { Axis, GlyphSeries, Grid, LineSeries, XYChart } from "@visx/xychart";
import React, { useEffect, useRef, useState } from "react";
import { DashboardColorPalette } from "../DashboardColorPalette";
import { ParentSize } from "@visx/responsive";
import { GlyphCircle } from "@visx/glyph";
import { GVDSColors } from "../../../../styles/gvds-colors";
import {
  performanceComparisonChartAccessors,
  performanceComparisonChartConfig,
} from "./PerformanceComparisonChartViewConfig";
import { LegendItem, LegendLabel } from "@visx/legend";
import { legendGlyphSizeInPx } from "../../Models/DashboardModels";
import { PerformanceComparisonChartUtils } from "./PerformanceComparisonChartUtils";
import { Line } from "@visx/shape";
import { NumberService, ObjectUtils } from "../../../../services/UtilsService";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { TOOLTIP_PLACEMENTS } from "../../../../config/style-config";

const LineChartGlyph = ({ year, monthlyValues, color }) => {
  return (
    <GlyphSeries
      key={"year1"}
      dataKey={year}
      data={monthlyValues}
      colorAccessor={() => color}
      renderGlyph={(d) => (
        <GlyphCircle
          left={d.x}
          top={d.y}
          fill={GVDSColors.white}
          stroke={color}
          strokeWidth={2}
        />
      )}
      {...performanceComparisonChartAccessors}
    />
  );
};

const LineChartTooltipContent = ({
  month,
  unit,
  intensityUnit,
  year1,
  year1Value,
  year2,
  year2Value,
  changeValue,
  changePercentage,
}) => {
  const displayedUnit = intensityUnit ? `${unit}/${intensityUnit}` : unit;

  return (
    <div className="dashboard--chart__popover-tooltip-container">
      <div className="popover-tooltip-label">Month</div>
      <div className="popover-tooltip-value">
        <strong>{month}</strong>
      </div>
      <div className="popover-tooltip-label">YoY change</div>
      <div className="popover-tooltip-value">
        {ObjectUtils.isEmpty(changeValue) ||
        ObjectUtils.isEmpty(changePercentage) ? (
          <strong>(no available data)</strong>
        ) : (
          <>
            <strong>{NumberService.format(changeValue, 1, 1)}</strong>{" "}
            {displayedUnit}{" "}
            {`(${NumberService.format(changePercentage, 1, 1)}%)`}
          </>
        )}
      </div>
      <div className="popover-tooltip-label">{year1}</div>
      <div className="popover-tooltip-value">
        {ObjectUtils.isEmpty(year1Value) ? (
          <strong>(no available data)</strong>
        ) : (
          <>
            <strong>{NumberService.format(year1Value, 1, 1)}</strong>{" "}
            {displayedUnit}
          </>
        )}
      </div>
      <div className="popover-tooltip-label">{year2}</div>
      <div className="popover-tooltip-value">
        {ObjectUtils.isEmpty(year2Value) ? (
          <strong>(no available data)</strong>
        ) : (
          <>
            <strong>{NumberService.format(year2Value, 1, 1)}</strong>{" "}
            {displayedUnit}
          </>
        )}
      </div>
    </div>
  );
};

const LineChart = ({
  height,
  width,
  year1,
  year1MonthlyValues,
  year2,
  year2MonthlyValues,
  changeMonthlyValues,
  maxValue,
  minValue,
  unit,
  intensityUnit,
}) => {
  const [isShowLine, setIsShowLine] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tooltipRef = useRef(null);

  const xScale = {
    type: "band",
    domain: performanceComparisonChartConfig.monthTicks.map(
      (month, index) => index
    ),
  };
  const yScale = { type: "linear" };

  const selectedMonthCount = performanceComparisonChartConfig.monthTicks.length;
  const selectedMonth =
    performanceComparisonChartConfig.monthTicks[selectedIndex];
  const selectedYear1Value = year1MonthlyValues[selectedIndex]?.value ?? null;
  const selectedYear2Value = year2MonthlyValues[selectedIndex]?.value ?? null;
  const changeValue = ObjectUtils.isEmpty(changeMonthlyValues)
    ? null
    : changeMonthlyValues[selectedIndex];

  const filledWidth =
    width -
    performanceComparisonChartConfig.lineChartLeftMarginInPx -
    performanceComparisonChartConfig.lineChartRightMarginInPx;
  const filledHeight =
    height - performanceComparisonChartConfig.bottomAxisHeightInPx;

  const tooltipsPosition = PerformanceComparisonChartUtils.getTooltipsPosition(
    filledWidth,
    selectedMonthCount
  );

  const yAxisTicks = PerformanceComparisonChartUtils.getYAxisTickValues(
    minValue,
    maxValue,
    filledHeight
  );
  const yAxisLabel = intensityUnit
    ? `Intensity (${unit}/${intensityUnit})`
    : unit;

  useEffect(() => {
    setIsShowTooltip(isShowLine);
  }, [isShowLine]);

  const handleShowLine = (event) => {
    setIsShowLine(true);
    setSelectedIndex(event.index);
  };

  const handleHideLine = (event) => {
    const isEnteringTooltip =
      event.relatedTarget &&
      event.relatedTarget.className &&
      event.relatedTarget.className.toString().includes("popover");

    if (!isEnteringTooltip) {
      setIsShowLine(false);
    }
  };

  return (
    <>
      <XYChart
        height={height}
        width={width}
        xScale={xScale}
        yScale={yScale}
        onPointerMove={(event) => handleShowLine(event)}
        onPointerOut={(event) => handleHideLine(event)}
      >
        <Axis
          hideAxisLine
          hideTicks
          orientation="bottom"
          label="Month"
          labelClassName="chart-axis-label"
          tickFormat={(value) =>
            performanceComparisonChartConfig.monthTicks[value]
          }
        />
        <Axis
          hideAxisLine
          hideTicks
          orientation="left"
          label={yAxisLabel}
          labelClassName="chart-axis-label"
          labelOffset={performanceComparisonChartConfig.leftAxisLabelOffsetInPx}
          tickValues={yAxisTicks}
          tickFormat={(value) => NumberService.humanize(value)}
        />
        <Grid columns={false} tickValues={yAxisTicks} />
        {isShowLine && (
          <Line
            from={{ x: tooltipsPosition[selectedIndex], y: 0 }}
            to={{
              x: tooltipsPosition[selectedIndex],
              y: height - performanceComparisonChartConfig.bottomAxisHeightInPx,
            }}
            stroke={GVDSColors.gray10}
            strokeWidth={2}
            pointerEvents="none"
            innerRef={tooltipRef}
          />
        )}
        <LineSeries
          key={"year1"}
          dataKey={year1}
          data={year1MonthlyValues}
          stroke={DashboardColorPalette[0]}
          {...performanceComparisonChartAccessors}
        />
        <LineSeries
          key={"year2"}
          dataKey={year2}
          data={year2MonthlyValues}
          stroke={DashboardColorPalette[1]}
          {...performanceComparisonChartAccessors}
        />
        <LineChartGlyph
          year={year1}
          monthlyValues={year1MonthlyValues}
          color={DashboardColorPalette[0]}
        />
        <LineChartGlyph
          year={year2}
          monthlyValues={year2MonthlyValues}
          color={DashboardColorPalette[1]}
        />
      </XYChart>
      <Overlay
        key={`key-tooltip-${selectedIndex}`}
        show={isShowTooltip}
        placement={TOOLTIP_PLACEMENTS.RIGHT}
        target={tooltipRef.current}
      >
        <Popover onMouseLeave={() => setIsShowLine(false)}>
          <Popover.Body>
            <div>
              <LineChartTooltipContent
                month={selectedMonth}
                unit={unit}
                intensityUnit={intensityUnit}
                year1={year1}
                year2={year2}
                year1Value={selectedYear1Value}
                year2Value={selectedYear2Value}
                changeValue={changeValue?.value}
                changePercentage={changeValue?.percentage}
              />
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

const Legend = ({ legendItems, chartColors }) => {
  return (
    <div>
      {legendItems.map((item, i) => (
        <LegendItem
          key={`legend-${i}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <svg
            width={legendGlyphSizeInPx}
            height={legendGlyphSizeInPx}
            style={{ margin: "2px 8px" }}
          >
            <circle
              fill={chartColors[i]}
              r={legendGlyphSizeInPx / 2}
              cx={legendGlyphSizeInPx / 2}
              cy={legendGlyphSizeInPx / 2}
            />
          </svg>
          <LegendLabel align="left" margin="0">
            {item}
          </LegendLabel>
        </LegendItem>
      ))}
    </div>
  );
};

const PerformanceComparisonChartViewContent = ({
  chartData,
  unit,
  intensityUnit,
}) => {
  const { minValue, maxValue } = chartData.getValueRange();

  return (
    <>
      <div className="chart-container">
        <ParentSize>
          {(parent) => (
            <LineChart
              height={parent.height}
              width={parent.width}
              year1={chartData.year1}
              year1MonthlyValues={chartData.year1MonthlyValues}
              year2={chartData.year2}
              year2MonthlyValues={chartData.year2MonthlyValues}
              changeMonthlyValues={chartData.changeMonthlyValues}
              maxValue={maxValue}
              minValue={minValue}
              unit={unit}
              intensityUnit={intensityUnit}
            />
          )}
        </ParentSize>
      </div>
      <div className="legend-container">
        <Legend
          legendItems={[chartData.year1, chartData.year2]}
          chartColors={[DashboardColorPalette[0], DashboardColorPalette[1]]}
        />
      </div>
    </>
  );
};

export default PerformanceComparisonChartViewContent;
