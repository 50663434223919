import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import { PERMISSIONS, RESOURCES } from "../../config/constants";
import withAuthentication from "../HOC/withAuthentication";
import PermissionsContext from "../../context/PermissionsContext";
import UserService from "../../services/UserService";
import ContractService from "../../services/ContractService";
import {
  getViewSiteDetailsPath,
  VIEW_ALL_CONTRACTS,
  VIEW_CONTRACT_DETAILS__TEAM_TAB,
} from "../../config/ROUTES_NAME";
import LoadingSpinner from "../common/LoadingSpinner";
import {
  ListPendingInviteTeamView,
  ListUsersTeamView,
} from "../User/ListUsersView";
import ContractStatusBadge from "./ContractStatusBadge";
import { AddUserWithRole } from "../User/AddUsersWithRole";
import ToastContext from "../../context/ToastContext";
import ApprovedDomains from "../User/ApprovedDomains";
import AddressDisplay from "../common/AddressDisplay";
import UserInventoryContext from "../../context/UserInventoryContext";
import RoutePersistedTabs from "../common/Tabs/RoutePersistedTabs";
import { getOptionByValueFromIdName } from "../common/Forms/SingleSelect";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import ViewAllContractSubscriptions from "../SystemToolbox/Subscription/ContractSubscription/ViewAllContractSubscriptions";
import { SubscriptionStatusEnum } from "../SystemToolbox/Subscription/SubscriptionSharedUtils";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSFormTextArea from "../../gvds-components/Forms/GVDSFormTextArea";
import FilterSearchBox from "../../gvds-components/common/FilterSearchBox";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import GVDSTag from "../../gvds-components/common/GVDSTag";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import useGVDSTableCtrl from "../../gvds-components/Table/GVDSTableHook";
import { contractSiteListSearchKeys } from "../../config/search-config";
import GVDSTable, {
  SORTING_TYPES,
} from "../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../gvds-components/Table/Controls/GVDSPagination";
import { UserUtils } from "../../services/UtilsService";
import GVFormGroup from "../common/GVFormGroup";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";
import SelectAccountManagerModal, {
  ConfirmRemoveAccountManager,
} from "../common/SelectAccountManagerModal";
import Spacer from "../../gvds-components/Layout/Spacer";
import SubscriptionStatusBadge from "../SystemToolbox/Subscription/SubscriptionStatusBadge";

const ViewContractDetails = () => {
  const history = useHistory();

  const permissionsCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  let { contractId } = useParams();

  const [contractDetails, setContractDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [inEditMode, setInEditMode] = useState(false);

  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([]);

  const [inputContractName, setInputContractName] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [internalComment, setInternalComment] = useState(null);

  const [isAddingUser, setIsAddingUser] = useState(false);

  const [showSelectAccountManagerModal, setShowSelectAccountManagerModal] =
    useState(false);
  const [isSelectAccountManagerValidated, setIsSelectAccountManagerValidated] =
    useState(false);
  const [showConfirmRemoveAccountManager, setShowConfirmRemoveAccountManager] =
    useState(false);
  const [isRemovingAccountManager, setIsRemovingAccountManager] =
    useState(false);

  const loadContractDetails = (contractId) => {
    setIsLoading(true);
    ContractService.getContractDetails(contractId)
      .then((details) => {
        setContractDetails(details);
        setIsLoading(false);
      })
      .catch(() => {
        setContractDetails(null);
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load contract</span>);
      });
  };

  useEffect(() => {
    Promise.all([
      UserService.getAllRolesForResource(RESOURCES.CONTRACT),
      ContractService.getContractOptions(),
    ]).then(([availableRoles, contractOptions]) => {
      setAvailableRoles(availableRoles);
      setAvailableCompanies(contractOptions.companies);
      setIsLoadingOptions(false);
    });
  }, []);

  useEffect(() => {
    loadContractDetails(contractId);
  }, [contractId]);

  useEffect(() => {
    resetFields();
  }, [contractDetails]);

  const addUserWithRoleToContract = async (email, roleId, asBillingAdmin) => {
    setIsAddingUser(true);
    ContractService.addUserToContract(contractId, email, roleId, asBillingAdmin)
      .then((updatedContractDetails) => {
        toastContext.addSuccessToast(<span>Invites successfully sent.</span>);
        updateContractDetails(updatedContractDetails);
        setIsAddingUser(false);
      })
      .catch((error) => {
        toastContext.addFailToast(
          <span>
            {error.response &&
            error.response.data &&
            (typeof error.response.data === "string" ||
              error.response.data instanceof String)
              ? error.response.data
              : "Failed to add " + email + " to contract team"}
          </span>
        );
        setIsAddingUser(false);
      });
  };

  const editUserRole = (userId, roleId, asBillingAdmin) => {
    return ContractService.editUserRole(
      contractId,
      userId,
      roleId,
      asBillingAdmin
    );
  };

  const removeUser = (userId) => {
    return ContractService.removeUserFromContract(contractId, userId);
  };

  const deleteInvite = (inviteId) => {
    return ContractService.deleteInviteFromContract(contractId, inviteId);
  };

  const updateContractDetails = (newContractDetails) => {
    setContractDetails(newContractDetails);
    userInventory.loadUserInventory();
  };

  const saveContractDetailsChange = () => {
    setIsLoading(true);
    ContractService.updateContract(
      contractId,
      inputContractName,
      selectedCompanyId,
      internalComment
    )
      .then((updatedContractDetails) => {
        setContractDetails(updatedContractDetails);
        toastContext.addSuccessToast(<span>Updated contract details</span>);
        userInventory.loadUserInventory();
        setInEditMode(false);
        setIsLoading(false);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to update contract details.</span>
        );
        setIsLoading(false);
      });
  };

  const updateContractApprovedDomains = (newApprovedDomains) => {
    setIsLoading(true);
    ContractService.updateContractApprovedDomains(
      contractId,
      newApprovedDomains
    )
      .then((updatedContractDetails) => {
        setContractDetails(updatedContractDetails);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.status === 400) {
          toastContext.addFailToast(<span>{error.data.message}</span>);
        } else {
          toastContext.addFailToast(
            <span>Failed to update contract approved domains.</span>
          );
        }
        setIsLoading(false);
      });
  };

  const resetFields = () => {
    if (contractDetails) {
      setInputContractName(contractDetails.name);
      setSelectedCompanyId(contractDetails.company?.id);
      setInternalComment(contractDetails.internalComment);
    }
  };

  const backToContractList = () => {
    history.push(VIEW_ALL_CONTRACTS);
  };

  const assignAccountManager = async (inputAccountManager) => {
    setIsSelectAccountManagerValidated(true);
    if (!inputAccountManager) return;

    try {
      await ContractService.updateAccountManager(
        contractId,
        inputAccountManager.value
      );
      setShowSelectAccountManagerModal(false);
      loadContractDetails(contractId);
      toastContext.addSuccessToast(
        <span>Account Manager has been updated.</span>
      );
    } catch (error) {
      toastContext.addFailToast(<span>Failed to update account manager.</span>);
    }
  };

  const asyncRemoveAccountManager = async (contractId) => {
    setIsRemovingAccountManager(true);

    try {
      await ContractService.removeAccountManager(contractId);
      setShowConfirmRemoveAccountManager(false);
      contractDetails.accountManager = null;
      setContractDetails(contractDetails);
      toastContext.addSuccessToast(
        <span>Account Manager has been removed.</span>
      );
    } catch (error) {
      toastContext.addFailToast(<span>Failed to remove account manager.</span>);
    } finally {
      setIsRemovingAccountManager(false);
    }
  };

  let content;
  if (isLoading || isLoadingOptions) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else if (!contractDetails) {
    content = <div>No such contract.</div>;
  } else {
    content = (
      <>
        <PageHeader>
          <PageHeader.BackButton
            text="Return to Contract list"
            onClick={backToContractList}
          />
          <PageHeader.Title>
            <h1>{contractDetails.name}</h1>
            <Spacer />
            {!permissionsCtx.isLoadingPermissions &&
              permissionsCtx.permissions[PERMISSIONS.CONTRACT_VIEW] && (
                <GVDSButton
                  variant={buttonVariant.primary}
                  onClick={() => {
                    window.open(
                      contractDetails.stripeCustomerUrl,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                  text="View Stripe Customer"
                />
              )}
          </PageHeader.Title>
          <PageHeader.Status>
            <div className="info-field">
              <ContractStatusBadge status={contractDetails.status} />
            </div>
          </PageHeader.Status>
          <div className="account-manager__container">
            {contractDetails.accountManager ? (
              <>
                <span className="account-manager__display">
                  Account Manager:{" "}
                  {UserUtils.getFullNameOrEmail(
                    contractDetails.accountManager.full_name,
                    contractDetails.accountManager.email
                  )}
                </span>
              </>
            ) : (
              <>
                <span className="account-manager__display__not-assigned">
                  No Account Manager
                </span>
              </>
            )}
            <GVDSTextButton
              text={
                contractDetails.accountManager
                  ? "Update Account Manager"
                  : "Set Account Manager"
              }
              onClick={() => setShowSelectAccountManagerModal(true)}
            />
          </div>
        </PageHeader>
        <RoutePersistedTabs defaultActiveKey="contract-details">
          <Tab
            eventKey="contract-details"
            title="Contract Details"
            tabClassName="tab-nav"
          >
            {permissionsCtx.permissions[PERMISSIONS.CONTRACT_EDIT] && (
              <div className="d-flex">
                <div className="ms-auto">
                  {!inEditMode && (
                    <GVDSButton
                      variant={buttonVariant.secondary}
                      onClick={() => setInEditMode(true)}
                      text="Edit Contract Details"
                    />
                  )}
                  {inEditMode && (
                    <>
                      <GVDSButton
                        variant={buttonVariant.tertiary}
                        onClick={() => {
                          resetFields();
                          setInEditMode(false);
                        }}
                        text="Cancel"
                      />
                      <GVDSButton
                        variant={buttonVariant.primary}
                        onClick={() => saveContractDetailsChange()}
                        text="Save Changes"
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            <GVFormGroup>
              <Form.Label>Contract Name</Form.Label>
              {inEditMode ? (
                <GVDSFormField
                  value={inputContractName}
                  onInput={(value) => setInputContractName(value)}
                />
              ) : (
                <Form.Control
                  type="text"
                  value={inputContractName || ""}
                  disabled
                  readOnly
                  plaintext
                />
              )}
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>Company</Form.Label>
              {inEditMode ? (
                <GVDSFormSingleSelect
                  className="w-25"
                  placeHolder="Select Company"
                  name="company"
                  value={
                    selectedCompanyId
                      ? getOptionByValueFromIdName(
                          selectedCompanyId,
                          availableCompanies
                        )
                      : null
                  }
                  onSelect={(selectedOption) =>
                    setSelectedCompanyId(selectedOption.value)
                  }
                  options={availableCompanies.map((company) => {
                    return { value: company.id, label: company.name };
                  })}
                />
              ) : (
                <Form.Control
                  readOnly
                  plaintext
                  type="text"
                  name="company"
                  value={
                    selectedCompanyId
                      ? availableCompanies.find(
                          (c) => c.id === selectedCompanyId
                        )?.name
                      : ""
                  }
                />
              )}
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>Internal Comment</Form.Label>
              {inEditMode ? (
                <GVDSFormTextArea
                  value={internalComment}
                  onInput={(value) => setInternalComment(value)}
                  rows={3}
                />
              ) : (
                <Form.Control
                  value={internalComment || ""}
                  as="textarea"
                  rows={3}
                  disabled
                  readOnly
                  plaintext
                />
              )}
            </GVFormGroup>
          </Tab>
          <Tab
            eventKey={VIEW_CONTRACT_DETAILS__TEAM_TAB}
            title="Team"
            tabClassName="tab-nav"
          >
            <section className="section-box">
              <h2>Team ({contractDetails.users.length})</h2>
              <ListUsersTeamView
                users={contractDetails.users}
                availableRoles={availableRoles}
                onEdit={editUserRole}
                onDelete={removeUser}
                onSuccessResponse={updateContractDetails}
                hasActionPermission={
                  !permissionsCtx.isLoadingPermissions &&
                  permissionsCtx.permissions[
                    PERMISSIONS.CONTRACT_USER_MANAGEMENT
                  ]
                }
                billingAdmin={contractDetails.billingAdmin}
                canEditRole={
                  !permissionsCtx.isLoadingPermissions &&
                  permissionsCtx.permissions[
                    PERMISSIONS.CONTRACT_USER_MANAGEMENT
                  ]
                }
              />
            </section>
            <section className="section-box">
              <h2>Pending Invite ({contractDetails.pendingUsers.length})</h2>
              <ListPendingInviteTeamView
                pendingUserInvites={contractDetails.pendingUsers}
                onDelete={deleteInvite}
                onSuccessResponse={updateContractDetails}
                hasActionPermission={
                  !permissionsCtx.isLoadingPermissions &&
                  permissionsCtx.permissions[
                    PERMISSIONS.CONTRACT_USER_MANAGEMENT
                  ]
                }
              />
            </section>
            {!permissionsCtx.isLoadingPermissions &&
              permissionsCtx.permissions[
                PERMISSIONS.CONTRACT_USER_MANAGEMENT
              ] && (
                <>
                  <AddUserWithRole
                    availableRoles={availableRoles}
                    approvedDomains={contractDetails.approvedDomains}
                    onAddingUser={addUserWithRoleToContract}
                    isAddingUser={isAddingUser}
                    hasBillingAdmin={!!contractDetails.billingAdmin}
                  />
                  <ApprovedDomains
                    approvedDomains={contractDetails.approvedDomains}
                    isAllowedToEdit={
                      permissionsCtx.permissions[
                        PERMISSIONS.CONTRACT_APPROVED_DOMAINS
                      ]
                    }
                    onEditDone={updateContractApprovedDomains}
                  />
                </>
              )}
          </Tab>
          {contractDetails.status === "active" && (
            <Tab eventKey="sites" title="Sites" tabClassName="tab-nav">
              <ListSitesView sites={contractDetails.sites} />
            </Tab>
          )}
          <Tab
            eventKey="subscriptions"
            title="Subscriptions"
            tabClassName="tab-nav"
          >
            <ViewAllContractSubscriptions
              contractId={contractDetails.id}
              accountManager={contractDetails.accountManager}
              billingAdmin={contractDetails.billingAdmin}
            />
          </Tab>
        </RoutePersistedTabs>
        <SelectAccountManagerModal
          isValidated={isSelectAccountManagerValidated}
          showModal={showSelectAccountManagerModal}
          hideModal={() => setShowSelectAccountManagerModal(false)}
          assignAccountManager={assignAccountManager}
          currentAccountManager={contractDetails.accountManager}
          promptConfirmRemoveAccountManager={() => {
            setShowSelectAccountManagerModal(false);
            setShowConfirmRemoveAccountManager(true);
          }}
        />
        <ConfirmRemoveAccountManager
          isLoading={isRemovingAccountManager}
          showModal={showConfirmRemoveAccountManager}
          hideModal={() => setShowConfirmRemoveAccountManager(false)}
          asyncRemoveAccountManager={async () =>
            await asyncRemoveAccountManager(contractId)
          }
        />
      </>
    );
  }

  return <Container fluid>{content}</Container>;
};

const ListSitesView = ({ sites }) => {
  const history = useHistory();
  const location = useLocation();
  const [filteredSites, setFilteredSites] = useState([]);
  const [sitesWithActiveSubscription, setSitesWithActiveSubscription] =
    useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    sitesWithActiveSubscription,
    contractSiteListSearchKeys,
    {
      name: SORTING_TYPES.asc,
    }
  );

  useEffect(() => {
    setSitesWithActiveSubscription(
      sites.filter((s) => s.status !== SubscriptionStatusEnum.EXPIRED)
    );
  }, [sites]);

  const columns = [
    {
      header: "Site Name",
      headerStyle: { width: "200px" },
      dataKey: "id",
      renderer: (s) => (
        <Link
          to={getRedirectURLWithCurrentParam(
            getViewSiteDetailsPath(s.id),
            location
          )}
          onClick={(e) => e.stopPropagation()}
          className="view-site"
        >
          {s.status === SubscriptionStatusEnum.UPCOMING && (
            <SubscriptionStatusBadge status={s.status} />
          )}
          {s.name}
        </Link>
      ),
    },
    {
      header: "Address",
      headerStyle: { width: "300px" },
      dataKey: "address",
      renderer: (s) => (
        <div>
          <AddressDisplay address={s.location} />
        </div>
      ),
    },
    {
      header: "Features",
      dataKey: "features",
      renderer: (s) => (
        <div>
          {s.features.map((feature) => (
            <GVDSTag variant={GVDSTag.Variants.system} key={feature.id}>
              {feature.display_name}
            </GVDSTag>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        onRowClick={(site, event) => {
          const siteUrl = getRedirectURLWithCurrentParam(
            getViewSiteDetailsPath(site.id),
            location
          );

          history.push(siteUrl);
        }}
        noContent={
          filteredSortedData.length === 0 && (
            <div className="table__no_content">No site found</div>
          )
        }
        viewControl={
          <GVDSTableCtrlContainer>
            <FilterSearchBox
              className="site-search-box"
              placeholder="Search site by name or address"
              value={searchText}
              onInput={setSearchText}
            />
            <GVDSPagination
              startIndex={startIndex}
              endIndex={endIndex}
              total={totalDataLength}
              onChange={onPaginationChange}
            />
          </GVDSTableCtrlContainer>
        }
      />
    </div>
  );
};

export default withAuthentication(ViewContractDetails);
