import React, { useEffect, useRef, useState } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { NumberService } from "../../../services/UtilsService";

const PopoverOnBigNumberTruncated = ({
  numberValue,
  contentClassName,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0,
}) => {
  const displayValueContainerElRef = useRef();
  const fullValueElRef = useRef();

  const [isTruncated, setIsTruncated] = useState(false);

  const compareSize = () => {
    if (fullValueElRef.current && displayValueContainerElRef.current) {
      const isWidthScrollableWithoutTruncation =
        fullValueElRef.current.scrollWidth >
        displayValueContainerElRef.current.clientWidth;

      setIsTruncated(isWidthScrollableWithoutTruncation);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  useEffect(() => {
    compareSize();
  }, [fullValueElRef.current?.scrollWidth]);

  const fullValueDisplay = NumberService.format(
    numberValue || 0,
    maximumFractionDigits,
    minimumFractionDigits
  );

  const valueDisplay = isTruncated
    ? NumberService.humanize(numberValue)
    : fullValueDisplay;

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        isTruncated ? (
          <Popover>
            <Popover.Body>{fullValueDisplay}</Popover.Body>
          </Popover>
        ) : (
          <div style={{ display: "none" }} />
        )
      }
    >
      <div className={contentClassName} ref={displayValueContainerElRef}>
        {valueDisplay}
        <div
          style={{
            position: "absolute",
            visibility: "hidden",
          }}
          ref={fullValueElRef}
        >
          {fullValueDisplay}
        </div>
      </div>
    </OverlayTrigger>
  );
};

export default PopoverOnBigNumberTruncated;
