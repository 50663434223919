import axios from "axios";
import { API_URL } from "../config/api-config";
import { RESOURCES } from "../config/constants";
import { DateTimeUtils } from "./UtilsService";

export const PEOPLE_ROW_INPUT_LIMIT = 800;

export const TOTAL_PEOPLE_CATEGORY = {
  id: "total",
  name: "Total",
  definition: "Total",
};

export const PEOPLE_SUBTOPIC = {
  TURNOVER: "Turnover",
};

export default class PeopleService {
  static getPeopleSubtopics = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/people_subtopics`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static createPeopleMeter = async (site_id, subtopic_id, grouping_ids) => {
    try {
      const data = {
        subtopic_id,
        grouping_ids,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/people_meters`,
        data,
        { params: { resource_type: RESOURCES.SITE, resource_id: site_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updatePeopleMeter = async (
    site_id,
    meter_id,
    subtopic_id,
    grouping_ids
  ) => {
    try {
      const data = {
        subtopic_id,
        grouping_ids,
      };
      const response = await axios.put(
        `${API_URL}/api/v1/people_meters/${meter_id}`,
        data,
        { params: { resource_type: RESOURCES.SITE, resource_id: site_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getPeopleMeters = async (site_id) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/people_meters`, {
        params: { resource_type: RESOURCES.SITE, resource_id: site_id },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getMeterStatus = async (meter_id, site_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/people_meters/${meter_id}/status`,
        {
          params: { resource_type: RESOURCES.SITE, resource_id: site_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static deleteMeter = async (meter_id, site_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/people_meters/${meter_id}`,
        {
          params: { resource_type: RESOURCES.SITE, resource_id: site_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static createPeopleRecord = async (
    site_id,
    meter_id,
    record_month,
    groupings,
    categories_record,
    comment
  ) => {
    try {
      const data = {
        record_month: DateTimeUtils.getUTCISOString(record_month),
        groupings,
        categories_record,
        comment,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/people_meters/${meter_id}/data_records`,
        data,
        { params: { resource_type: RESOURCES.SITE, resource_id: site_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updatePeopleRecord = async (
    site_id,
    record_id,
    record_month,
    groupings,
    categories_record,
    comment
  ) => {
    try {
      const data = {
        record_month: DateTimeUtils.getUTCISOString(record_month),
        groupings,
        categories_record,
        comment,
      };
      const response = await axios.put(
        `${API_URL}/api/v1/people_data_records/${record_id}`,
        data,
        { params: { resource_type: RESOURCES.SITE, resource_id: site_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getPeopleRecords = async (site_id, start, end, meter_id = null) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/people_data_records`,
        {
          params: {
            resource_type: RESOURCES.SITE,
            resource_id: site_id,
            start: DateTimeUtils.getUTCISOString(start),
            end: DateTimeUtils.getUTCISOString(end),
            meter_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static deletePeopleRecords = async (site_id, record_ids) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/people_data_records`,
        {
          data: { record_ids },
          params: { resource_type: RESOURCES.SITE, resource_id: site_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getCalculatedValues = async (
    site_id,
    meter_id,
    record_id,
    record_month,
    groupings,
    categories_record,
    comment,
    cancelTokenSource
  ) => {
    try {
      const data = {
        meter_id,
        record_month: DateTimeUtils.getUTCISOString(record_month),
        groupings,
        categories_record,
        comment,
      };

      if (record_id) {
        data.record_id = record_id;
      }

      const response = await axios.post(
        `${API_URL}/api/v1/people_record_calculated_values`,
        data,
        {
          params: { resource_type: RESOURCES.SITE, resource_id: site_id },
          cancelToken: cancelTokenSource.token,
        }
      );
      return response.data;
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw error;
      }
    }
  };

  static numberOfRowsFromGroupings = (groupings) => {
    return groupings
      .map((g) => g.categories.length)
      .reduce(
        (accumulatedValue, currentValue) => accumulatedValue * currentValue,
        1
      );
  };
}
