import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

import SiteService from "../../services/SiteService";
import UserService from "../../services/UserService";

import withAuthentication from "../HOC/withAuthentication";
import LoadingSpinner from "../common/LoadingSpinner";
import { PERMISSIONS, RESOURCES } from "../../config/constants";
import {
  ListPendingInviteTeamView,
  ListUsersTeamView,
} from "../User/ListUsersView";
import { AddUserWithRole } from "../User/AddUsersWithRole";
import ViewAllFacilities from "./Facility/ViewAllFacilities";
import CreateFacility from "./Facility/CreateFacility";
import ViewSiteInformation from "./ViewSiteInformation";
import ApprovedDomains from "../User/ApprovedDomains";
import ToastContext from "../../context/ToastContext";
import PermissionsContext from "../../context/PermissionsContext";
import UserInventoryContext from "../../context/UserInventoryContext";
import {
  VIEW_ALL_SITES,
  VIEW_SITE_DETAILS__TEAM_TAB,
} from "../../config/ROUTES_NAME";
import { TabContext } from "../common/Tabs/TabContext";
import RoutePersistedTabsWithContext from "../common/Tabs/RoutePersistedTabsWithContext";
import ViewAllSiteSubscriptions from "../SystemToolbox/Subscription/SiteSubscription/ViewAllSiteSubscriptions";
import WorkspaceInventoryIcon from "../../gvds-components/common/WorkspaceInventoryIcon";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";
import { UserUtils } from "../../services/UtilsService";
import SelectAccountManagerModal, {
  ConfirmRemoveAccountManager,
} from "../common/SelectAccountManagerModal";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import Spacer from "../../gvds-components/Layout/Spacer";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";

const ViewSiteDetails = () => {
  const toastContext = useContext(ToastContext);
  const permissionsCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const history = useHistory();

  const { siteId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [siteDetails, setSiteDetails] = useState({});
  const [availableRoles, setAvailableRoles] = useState([]);
  const [isAddingUser, setIsAddingUser] = useState(false);

  const [showSelectAccountManagerModal, setShowSelectAccountManagerModal] =
    useState(false);
  const [isSelectAccountManagerValidated, setIsSelectAccountManagerValidated] =
    useState(false);
  const [showConfirmRemoveAccountManager, setShowConfirmRemoveAccountManager] =
    useState(false);
  const [isRemovingAccountManager, setIsRemovingAccountManager] =
    useState(false);

  const loadSiteDetails = (siteId) => {
    setIsLoading(true);
    SiteService.getSite(siteId)
      .then((data) => {
        setSiteDetails(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to load site details.</span>);
      });
  };

  useEffect(() => {
    loadSiteDetails(siteId);
    UserService.getAllRolesForResource(RESOURCES.SITE).then(
      (availableRoles) => {
        setAvailableRoles(availableRoles);
      }
    );
  }, [siteId]);

  const backToSiteList = () => {
    history.push(VIEW_ALL_SITES);
  };

  const addUserWithRoleToSite = async (email, roleId, asBillingAdmin) => {
    setIsAddingUser(true);
    SiteService.addUserToSite(siteId, email, roleId, asBillingAdmin)
      .then((updatedSiteDetails) => {
        toastContext.addSuccessToast(<span>Invites successfully sent.</span>);
        updateSiteDetails(updatedSiteDetails);
        setIsAddingUser(false);
      })
      .catch((error) => {
        toastContext.addFailToast(
          <span>
            {error.response &&
            error.response.data &&
            (typeof error.response.data === "string" ||
              error.response.data instanceof String)
              ? error.response.data
              : "Failed to add " + email + " to site team"}
          </span>
        );
        setIsAddingUser(false);
      });
  };

  const editUserRole = (userId, roleId, asBillingAdmin) => {
    return SiteService.editUserRole(siteId, userId, roleId, asBillingAdmin);
  };

  const removeUser = (userId) => {
    return SiteService.removeUserFromSite(siteId, userId);
  };

  const deleteInvite = (inviteId) => {
    return SiteService.deleteInviteFromSite(siteId, inviteId);
  };

  const updateSiteDetails = (newSiteDetails) => {
    setSiteDetails(newSiteDetails);
  };

  const updateSiteApprovedDomains = (newApprovedDomains) => {
    setIsLoading(true);
    SiteService.updateSiteApprovedDomains(siteId, newApprovedDomains)
      .then((updatedSiteDetails) => {
        setSiteDetails(updatedSiteDetails);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.status === 400) {
          toastContext.addFailToast(<span>{error.data.message}</span>);
        } else {
          toastContext.addFailToast(
            <span>Failed to update site approved domains.</span>
          );
        }
        setIsLoading(false);
      });
  };

  const assignAccountManager = async (inputAccountManager) => {
    setIsSelectAccountManagerValidated(true);
    if (!inputAccountManager) return;

    try {
      await SiteService.updateAccountManager(siteId, inputAccountManager.value);
      setShowSelectAccountManagerModal(false);
      loadSiteDetails(siteId);
      toastContext.addSuccessToast(
        <span>Account Manager has been updated.</span>
      );
    } catch (error) {
      toastContext.addFailToast(<span>Failed to update account manager.</span>);
    }
  };

  const asyncRemoveAccountManager = async (siteId) => {
    setIsRemovingAccountManager(true);

    try {
      await SiteService.removeAccountManager(siteId);
      setShowConfirmRemoveAccountManager(false);
      siteDetails.account_manager = null;
      setSiteDetails(siteDetails);
      toastContext.addSuccessToast(
        <span>Account Manager has been removed.</span>
      );
    } catch (error) {
      toastContext.addFailToast(<span>Failed to remove account manager.</span>);
    } finally {
      setIsRemovingAccountManager(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!siteDetails) {
    return <div>No such site.</div>;
  }

  return (
    <>
      <Container fluid>
        <PageHeader>
          <PageHeader.BackButton
            text="Return to Site list"
            onClick={backToSiteList}
          />
          <PageHeader.Title>
            <h1>
              <div
                className="me-2 d-inline-flex"
                style={{ transform: "translate(0, 2px)" }}
              >
                <GVDSIcon Icon={WorkspaceInventoryIcon[RESOURCES.SITE]} />
              </div>
              {siteDetails.name}
            </h1>
            <Spacer />
            {!permissionsCtx.isLoadingPermissions &&
              permissionsCtx.permissions[PERMISSIONS.SITE_VIEW] && (
                <GVDSButton
                  variant={buttonVariant.primary}
                  onClick={() => {
                    window.open(
                      siteDetails.stripe_customer_url,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                  text="View Stripe Customer"
                />
              )}
          </PageHeader.Title>
        </PageHeader>
        <div className="account-manager__container">
          {siteDetails.account_manager ? (
            <>
              <span className="account-manager__display">
                Account Manager:{" "}
                {UserUtils.getFullNameOrEmail(
                  siteDetails.account_manager.full_name,
                  siteDetails.account_manager.email
                )}
              </span>
            </>
          ) : (
            <>
              <span className="account-manager__display__not-assigned">
                No Account Manager
              </span>
            </>
          )}
          <GVDSTextButton
            text={
              siteDetails.account_manager
                ? "Update Account Manager"
                : "Set Account Manager"
            }
            onClick={() => setShowSelectAccountManagerModal(true)}
          />
        </div>
        <RoutePersistedTabsWithContext defaultActiveKey="site-details">
          <Tab
            eventKey="site-details"
            title="Site Details"
            tabClassName="tab-nav"
          >
            <TabContext.Provider value="site-details">
              <ViewSiteInformation
                siteDetails={siteDetails}
                onChange={() => loadSiteDetails(siteId)}
              />
            </TabContext.Provider>
          </Tab>
          <Tab
            eventKey={VIEW_SITE_DETAILS__TEAM_TAB}
            title="Team"
            tabClassName="tab-nav"
          >
            <section className="section-box">
              <h2>Team ({siteDetails.users.length})</h2>
              <ListUsersTeamView
                users={siteDetails.users}
                availableRoles={availableRoles}
                onEdit={editUserRole}
                onDelete={removeUser}
                onSuccessResponse={updateSiteDetails}
                hasActionPermission={
                  !permissionsCtx.isLoadingPermissions &&
                  permissionsCtx.permissions[PERMISSIONS.SITE_USER_MANAGEMENT]
                }
                billingAdmin={siteDetails.billing_admin}
                canEditRole={
                  !permissionsCtx.isLoadingPermissions &&
                  permissionsCtx.permissions[PERMISSIONS.SITE_USER_MANAGEMENT]
                }
              />
            </section>
            <section className="section-box">
              <h2>Pending Invite ({siteDetails.pending_users.length})</h2>
              <ListPendingInviteTeamView
                pendingUserInvites={siteDetails.pending_users}
                onDelete={deleteInvite}
                onSuccessResponse={updateSiteDetails}
                hasActionPermission={
                  !permissionsCtx.isLoadingPermissions &&
                  permissionsCtx.permissions[PERMISSIONS.SITE_USER_MANAGEMENT]
                }
              />
            </section>
            {!permissionsCtx.isLoadingPermissions &&
              permissionsCtx.permissions[PERMISSIONS.SITE_USER_MANAGEMENT] && (
                <>
                  <AddUserWithRole
                    availableRoles={availableRoles}
                    approvedDomains={siteDetails.approved_domains}
                    onAddingUser={addUserWithRoleToSite}
                    isAddingUser={isAddingUser}
                    hasBillingAdmin={!!siteDetails.billing_admin}
                  />
                  <ApprovedDomains
                    approvedDomains={siteDetails.approved_domains}
                    isAllowedToEdit={
                      permissionsCtx.permissions[
                        PERMISSIONS.SITE_APPROVED_DOMAINS
                      ]
                    }
                    onEditDone={updateSiteApprovedDomains}
                  />
                </>
              )}
          </Tab>
          <Tab eventKey="facilities" title="Facilities" tabClassName="tab-nav">
            {!permissionsCtx.isLoadingPermissions &&
              permissionsCtx.permissions[
                PERMISSIONS.SITE_FACILITY_MANAGEMENT
              ] && (
                <div
                  style={{
                    flex: 1,
                    alignItems: "flex-end",
                    textAlign: "right",
                    padding: "10px 0",
                  }}
                >
                  <CreateFacility
                    onCreate={() => {
                      userInventory.loadUserInventory();
                      loadSiteDetails(siteId);
                    }}
                  />
                </div>
              )}
            <ViewAllFacilities
              siteId={siteId}
              facilities={siteDetails.all_facilities}
            />
          </Tab>
          <Tab
            eventKey="subscriptions"
            title="Subscriptions"
            tabClassName="tab-nav"
          >
            <ViewAllSiteSubscriptions
              siteId={siteId}
              siteDetails={siteDetails}
              accountManager={siteDetails.account_manager}
              billingAdmin={siteDetails.billing_admin}
            />
          </Tab>
        </RoutePersistedTabsWithContext>
      </Container>
      <SelectAccountManagerModal
        isValidated={isSelectAccountManagerValidated}
        showModal={showSelectAccountManagerModal}
        hideModal={() => setShowSelectAccountManagerModal(false)}
        assignAccountManager={assignAccountManager}
        currentAccountManager={siteDetails.account_manager}
        promptConfirmRemoveAccountManager={() => {
          setShowSelectAccountManagerModal(false);
          setShowConfirmRemoveAccountManager(true);
        }}
      />
      <ConfirmRemoveAccountManager
        isLoading={isRemovingAccountManager}
        showModal={showConfirmRemoveAccountManager}
        hideModal={() => setShowConfirmRemoveAccountManager(false)}
        asyncRemoveAccountManager={async () =>
          await asyncRemoveAccountManager(siteId)
        }
      />
    </>
  );
};

export default withAuthentication(ViewSiteDetails);
