import React, { useContext, useEffect, useState } from "react";
import { clone, cloneDeep } from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Container from "react-bootstrap/Container";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import InlineSpinner from "../../../common/InlineSpinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ContractSubscriptionModel from "./ContractSubscriptionModel";
import ContractService from "../../../../services/ContractService";
import SubscriptionService from "../../../../services/SubscriptionService";
import { RESOURCES } from "../../../../config/constants";
import { getViewContractDetailsPath } from "../../../../config/ROUTES_NAME";
import {
  getPathWithTabKey,
  getRedirectURLWithCurrentParam,
} from "../../../common/QueryHandler";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import InputContractSubscriptionContractTerms from "./InputContractSubscriptionContractTerms";
import InputContractSubscriptionSiteSelection from "./InputContractSubscriptionSiteSelection";
import ContractInvoicePrompt from "./ContractInvoicePrompt";
import { BillingSiteSubscriptionContractInvoiceModel } from "../../../../services/BillingService";
import SubscriptionSharedUtils, {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  EXCLUDED_DEFAULT_FEATURES,
  getDefaultFullFeatures,
} from "../SubscriptionSharedUtils";

const CONTRACT_SUBSCRIPTION_STEPS = {
  CONTRACT_TERMS: "Contract Terms",
  SITE_SELECTION: "Site Selection",
};

const contractSubscriptionStepTitle = {
  [CONTRACT_SUBSCRIPTION_STEPS.CONTRACT_TERMS]: "1. Contract Terms",
  [CONTRACT_SUBSCRIPTION_STEPS.SITE_SELECTION]: "2. Site Selection & Terms",
};

const DEFAULT_CUSTOM_PAYMENT_DUE_DAYS = 30;

export const CONTRACT_SUBSCRIPTION_INPUT_MODES = {
  NEW: "New",
  EDIT: "Edit",
  DUPLICATE: "Duplicate",
};

const getBlankSubscriptionModel = (allFeatures, planId = null) => {
  return new ContractSubscriptionModel({
    id: null,
    contract: null,
    startPeriod: null,
    endPeriod: null,
    reminderDate: null,
    pricingType: null,
    currencyId: null,
    features: getDefaultFullFeatures(allFeatures),
    comments: "",
    autoRenew: false,
    planId: planId,
    isTaxApplicable: false,
    siteSubscriptions: [],
    customPaymentDueDays: DEFAULT_CUSTOM_PAYMENT_DUE_DAYS,
  });
};

const InputContractSubscription = ({ isDuplicate }) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);

  const history = useHistory();
  const location = useLocation();
  const { contractId, subscriptionId } = useParams();

  const inputMode = isDuplicate
    ? CONTRACT_SUBSCRIPTION_INPUT_MODES.DUPLICATE
    : subscriptionId
    ? CONTRACT_SUBSCRIPTION_INPUT_MODES.EDIT
    : CONTRACT_SUBSCRIPTION_INPUT_MODES.NEW;

  const [isLoadingContractName, setIsLoadingContractName] = useState(true);
  const [isLoadingSubscriptionOptions, setIsLoadingSubscriptionOptions] =
    useState(true);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);

  const [isValidated, setIsValidated] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [contractName, setContractName] = useState("");
  const [currentStep, setCurrentStep] = useState(
    CONTRACT_SUBSCRIPTION_STEPS.CONTRACT_TERMS
  );
  const [model, setModel] = useState(getBlankSubscriptionModel([]));

  const [allFeatures, setAllFeatures] = useState([]);
  const [orderedPackagePlans, setOrderedPackagePlans] = useState([]);
  const [allSites, setAllSites] = useState([]);

  const [originalSelectedFeatures, setOriginalSelectedFeatures] = useState([]);

  const [showContractInvoicingPrompt, setShowContractInvoicingPrompt] =
    useState(false);
  const [siteSubscriptionInvoiceModels, setSiteSubscriptionInvoiceModels] =
    useState([]);

  useEffect(() => {
    loadSubscriptionOptions();
  }, []);

  useEffect(() => {
    setIsLoadingContractName(true);
    ContractService.getContractDetails(contractId)
      .then((details) => {
        setContractName(details.name);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load contract name</span>);
      })
      .finally(() => {
        setIsLoadingContractName(false);
      });
  }, [contractId]);

  useEffect(() => {
    if (orderedPackagePlans.length > 0 && subscriptionId) {
      setIsLoadingSubscription(true);
      SubscriptionService.getContractSubscription(contractId, subscriptionId)
        .then((subscription) => {
          if (isDuplicate) {
            setModel(
              subscription.getDuplicateModel(allSites, orderedPackagePlans)
            );
          } else {
            setModel(subscription);
          }
          setOriginalSelectedFeatures(subscription.features);
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to load subscription</span>);
        })
        .finally(() => {
          setIsLoadingSubscription(false);
        });
    }
  }, [subscriptionId, orderedPackagePlans]);

  const loadSubscriptionOptions = async () => {
    setIsLoadingSubscriptionOptions(true);
    try {
      const options = await SubscriptionService.getSubscriptionOptions(
        RESOURCES.CONTRACT,
        contractId
      );
      if (options) {
        const allFeaturesOptions = options["features"];
        setAllFeatures(allFeaturesOptions);
        const orderedPackagePlanOptions = options["ordered_package_plans"];
        setOrderedPackagePlans(orderedPackagePlanOptions);
        setAllSites(options["sites"]);
        if (inputMode === CONTRACT_SUBSCRIPTION_INPUT_MODES.NEW) {
          const customPlan = orderedPackagePlanOptions.find(
            (p) => p.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME
          );
          const newModel = getBlankSubscriptionModel(
            [...allFeaturesOptions],
            customPlan.package_plan_id
          );

          SubscriptionSharedUtils.updateSubscriptionTermsBasedOnPlanAndBillingCycle(
            newModel,
            newModel.billingCycle,
            customPlan
          );

          setModel(newModel);
          setIsLoadingSubscription(false);
        }
      }
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to load subscription options.</span>
      );
    } finally {
      setIsLoadingSubscriptionOptions(false);
    }
  };

  const goToContractSubscriptionPage = () => {
    const pathWithTabKey = getPathWithTabKey(
      getRedirectURLWithCurrentParam(
        getViewContractDetailsPath(contractId),
        location
      ),
      "subscriptions"
    );
    history.push(pathWithTabKey);
  };

  const onDataChange = () => {
    setModel(cloneDeep(model));
  };

  const resetInputFields = () => {
    setIsValidated(false);
    setModel(getBlankSubscriptionModel([...allFeatures]));
  };

  const submitSubscription = async () => {
    if (
      inputMode !== CONTRACT_SUBSCRIPTION_INPUT_MODES.EDIT ||
      !model.isPaidSubscription()
    ) {
      await saveSubscription();
    } else {
      setIsValidated(true);

      if (isAllInputValidated()) {
        const newSiteSubscriptions = model.siteSubscriptions.filter((siteSub) =>
          siteSub.isNew()
        );
        if (newSiteSubscriptions.length > 0) {
          const siteSubscriptionInvoiceModels = newSiteSubscriptions.map(
            (sub) =>
              BillingSiteSubscriptionContractInvoiceModel.fromSiteSubscription(
                sub,
                model.startPeriod,
                model.endPeriod
              )
          );
          setSiteSubscriptionInvoiceModels(siteSubscriptionInvoiceModels);
          setShowContractInvoicingPrompt(true);
        } else {
          await saveSubscription();
        }
      }
    }
  };

  const saveSubscription = async (withInvoiceData = false) => {
    setIsValidated(true);

    if (isAllInputValidated()) {
      setIsSaving(true);
      try {
        if (model.id) {
          await SubscriptionService.updateContractSubscription(
            contractId,
            model.id,
            model.toRequestPayload(
              siteSubscriptionInvoiceModels.map((invoiceModel) =>
                invoiceModel.toRequestPayload()
              ),
              withInvoiceData
            )
          );
        } else {
          await SubscriptionService.addContractSubscription(
            contractId,
            model.toRequestPayload()
          );
        }
        resetInputFields();
        toastContext.addSuccessToast(
          <span>
            {model.id
              ? "Subscription edited successfully"
              : "Subscription added successfully"}
          </span>
        );
        userInventory.loadUserInventory();
        goToContractSubscriptionPage();
      } catch (error) {
        toastContext.addFailToast(<span>Failed to save subscription.</span>);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const isContractTermsStepValidated = () => {
    return (
      model.isPriceValid() &&
      model.isPaymentTypeValid() &&
      model.isBillingCycleValid() &&
      model.isStartDateValid() &&
      model.isEndDateValid() &&
      model.isReminderDateValid() &&
      model.isPlanValid() &&
      model.isFeaturesValid()
    );
  };

  const isSiteSelectionStepValidated = () => {
    return model.isAllSitePeriodValid() && model.isAllPriceAmountValid();
  };

  const isAllInputValidated = () => {
    return isContractTermsStepValidated() && isSiteSelectionStepValidated();
  };

  const isContractTermsStepCompleted = () => {
    if (isContractTermsStepValidated()) {
      return true;
    } else {
      setIsValidated(true);
      return false;
    }
  };

  const goToStep = (nextStep) => {
    if (currentStep === CONTRACT_SUBSCRIPTION_STEPS.CONTRACT_TERMS) {
      if (isContractTermsStepCompleted()) {
        setCurrentStep(CONTRACT_SUBSCRIPTION_STEPS.SITE_SELECTION);
      }
    } else {
      setCurrentStep(CONTRACT_SUBSCRIPTION_STEPS.CONTRACT_TERMS);
    }
  };

  const isEditing = inputMode === CONTRACT_SUBSCRIPTION_INPUT_MODES.EDIT;

  return (
    <Container fluid className="input-contract-subscription__main-container">
      <PageHeader>
        <PageHeader.Title>
          <h1>
            {isEditing ? "Edit" : "Add"} Subscription{" "}
            <span className="gv-text-16">
              {isLoadingContractName ? (
                <InlineSpinner />
              ) : (
                `for ${contractName}`
              )}
            </span>
          </h1>
        </PageHeader.Title>
      </PageHeader>
      {isLoadingSubscriptionOptions || isLoadingSubscription ? (
        <LoadingSpinner />
      ) : (
        <Tabs activeKey={currentStep} onSelect={(key) => goToStep(key)}>
          <Tab
            eventKey={CONTRACT_SUBSCRIPTION_STEPS.CONTRACT_TERMS}
            title={
              contractSubscriptionStepTitle[
                CONTRACT_SUBSCRIPTION_STEPS.CONTRACT_TERMS
              ]
            }
          >
            <InputContractSubscriptionContractTerms
              model={model}
              onDataChange={onDataChange}
              allFeatures={allFeatures}
              orderedPackagePlans={orderedPackagePlans}
              originalSelectedFeatures={originalSelectedFeatures}
              isValidated={isValidated}
              disabled={isEditing}
            />
          </Tab>
          <Tab
            eventKey={CONTRACT_SUBSCRIPTION_STEPS.SITE_SELECTION}
            title={
              contractSubscriptionStepTitle[
                CONTRACT_SUBSCRIPTION_STEPS.SITE_SELECTION
              ]
            }
          >
            <InputContractSubscriptionSiteSelection
              model={model}
              onDataChange={onDataChange}
              inputMode={inputMode}
              isValidated={isValidated}
              allFeatures={allFeatures}
              allSites={allSites}
              setAllSites={setAllSites}
              orderedPackagePlans={orderedPackagePlans}
            />
          </Tab>
        </Tabs>
      )}
      <div className="floating-action-container with-border mt-auto">
        <div className="d-flex justify-content-end">
          <GVDSButton
            className="me-3"
            variant={buttonVariant.tertiary}
            disabled={isSaving}
            onClick={goToContractSubscriptionPage}
            text="Cancel"
          />
          {currentStep === CONTRACT_SUBSCRIPTION_STEPS.CONTRACT_TERMS ? (
            <GVDSButton
              className="me-3"
              variant={buttonVariant.primary}
              onClick={() =>
                goToStep(CONTRACT_SUBSCRIPTION_STEPS.SITE_SELECTION)
              }
              text="Next"
            />
          ) : (
            <GVDSButton
              className="me-3"
              variant={buttonVariant.primary}
              disabled={isSaving}
              onClick={submitSubscription}
              text={isSaving ? "Saving..." : "Save"}
            />
          )}
        </div>
      </div>
      <ContractInvoicePrompt
        show={showContractInvoicingPrompt}
        closeModal={() => setShowContractInvoicingPrompt(false)}
        currencyId={model.currencyId}
        siteSubscriptionInvoiceModels={siteSubscriptionInvoiceModels}
        onInvoiceModelsChanged={() =>
          setSiteSubscriptionInvoiceModels(clone(siteSubscriptionInvoiceModels))
        }
        saveWithoutCreatingInvoice={() => saveSubscription(false)}
        saveWithCreatingInvoice={() => saveSubscription(true)}
        isLoading={isSaving}
      />
    </Container>
  );
};

export default InputContractSubscription;
