import PageHeader from "../../../gvds-components/Layout/PageHeader";
import React, { useContext, useEffect, useState } from "react";
import {
  getAdminEditCertificationPath,
  SYSTEM_TOOLBOX_CERTIFICATION_LIST,
} from "../../../config/ROUTES_NAME";
import { useHistory, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import RoutePersistedTabs from "../../common/Tabs/RoutePersistedTabs";
import withAuthentication from "../../HOC/withAuthentication";
import CertificationContentManager from "./CertificationContentManager";
import ToastContext from "../../../context/ToastContext";
import CertificationAdminService from "../../../services/PortalAdmin/Certification/CertificationAdminService";
import { CertificationForm } from "./CertificationModals";

const CertificationSetup = () => {
  const toastContext = useContext(ToastContext);
  const history = useHistory();
  const goToCertificationList = () => {
    history.push(SYSTEM_TOOLBOX_CERTIFICATION_LIST);
  };

  const goToEditCertification = (certificationId) => {
    history.push(getAdminEditCertificationPath(certificationId));
  };

  const { certificationId } = useParams();

  const [certification, setCertification] = useState(null);
  const [formTitle, setFormTitle] = useState("");

  useEffect(() => {
    if (certificationId) {
      CertificationAdminService.getCertification(certificationId)
        .then((certification) => {
          setCertification(certification);
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to load certification.</span>);
        });
      setFormTitle("Edit Certification");
    } else {
      setFormTitle("Setup Certification");
    }
  }, [certificationId]);

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text={<>Back to Certifications </>}
          onClick={goToCertificationList}
        />
        <PageHeader.Title>
          <div className="system-toolbox--certification__setup-title">
            <h1>{formTitle}</h1>
            {certification && (
              <div className="system-toolbox--certification__name-label">
                ({certification.name})
              </div>
            )}
          </div>
        </PageHeader.Title>
      </PageHeader>
      <RoutePersistedTabs defaultActiveKey="certification-details">
        <Tab eventKey="certification-details" title="Certification details">
          <CertificationForm
            certification={certification}
            onSuccess={(certificationId) =>
              goToEditCertification(certificationId)
            }
          />
        </Tab>
        <Tab eventKey="content" title="Content" disabled={!certification}>
          <CertificationContentManager certification={certification} />
        </Tab>
      </RoutePersistedTabs>
    </div>
  );
};

export default withAuthentication(CertificationSetup);
