import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import React from "react";
import { useTranslation } from "react-i18next";

const NoEvidenceUploadedModal = ({ isShow, closeModal, displayedText }) => {
  const { t } = useTranslation();

  return (
    <GVDSModal
      title="Nothing to download"
      size={GVDSModal.Size.small}
      show={isShow}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        <div>{displayedText}</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={closeModal}
          text={t("shared-modal.footer.okay")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default NoEvidenceUploadedModal;
