import React, { useContext } from "react";
import UserInventoryContext from "../../context/UserInventoryContext";
import { RESOURCES } from "../../config/constants";
import ViewCollectiveTargetDetails from "./ViewCollectiveTargetDetails";
import ViewTargetDetails from "./ViewTargetDetails";

const ViewTargetDetailsBuilder = () => {
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  if (!selectedInventory) {
    return null;
  }

  if (selectedInventory.type === RESOURCES.PORTFOLIO) {
    return <ViewCollectiveTargetDetails />;
  } else if (selectedInventory.type === RESOURCES.SITE) {
    return <ViewTargetDetails />;
  } else {
    return null;
  }
};

export default ViewTargetDetailsBuilder;
