import React, { useContext, useEffect, useState } from "react";
import { PERMISSIONS, RESOURCES } from "../../../../config/constants";
import ToastContext from "../../../../context/ToastContext";
import SubscriptionService from "../../../../services/SubscriptionService";
import PermissionsContext from "../../../../context/PermissionsContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { DateTimeUtils, StringUtils } from "../../../../services/UtilsService";
import InputSiteSubscriptionModal from "./InputSiteSubscriptionModal";
import DeleteSiteSubscriptionPrompt from "./DeleteSiteSubscriptionPrompt";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SubscriptionSharedUtils, {
  TAX_COUNTRY_NAME_SINGAPORE,
} from "../SubscriptionSharedUtils";
import { getViewContractDetailsPath } from "../../../../config/ROUTES_NAME";
import { Link, useLocation } from "react-router-dom";
import {
  getPathWithTabKey,
  getQueryHandler,
  getRedirectURLWithCurrentParam,
  MODAL_QUERY_KEY,
  removeParamFromQuery,
} from "../../../common/QueryHandler";
import UserInventoryContext, {
  TYPE_DELIMITER,
} from "../../../../context/UserInventoryContext";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import {
  IconCircleFilled,
  IconCirclePlus,
  IconCopy,
  IconEdit,
  IconExternalLink,
  IconTrash,
} from "@tabler/icons-react";
import UserAvatar from "../../../common/UserAvatar";
import SubscriptionStatusBadge from "../SubscriptionStatusBadge";

export const SITE_SUBSCRIPTION_MODAL_QUERY_KEY = "ss";

const SiteSubscriptionDisplay = ({
  subscription,
  allFeatures,
  orderedPackagePlans,
  actionButtons,
  accountManager,
  billingAdmin,
}) => {
  const location = useLocation();

  const subscriptionPlan = orderedPackagePlans.find(
    (plan) => plan.package_plan_id === subscription.planId
  );

  return (
    <section key={subscription.id} className="subscription-box__container mt-3">
      <header className="d-flex flex-row align-items-center">
        <div className="subscription-plan">
          {StringUtils.capitaliseWord(subscriptionPlan?.name ?? "")}
        </div>
        {SubscriptionSharedUtils.getShorthandFeatureDisplay(
          allFeatures,
          subscription.features,
          "right",
          false
        )}
        <div className="ms-2">
          <SubscriptionStatusBadge status={subscription.status} />
        </div>
        {subscription.underContractIsFromContractSubscription() && (
          <div className="contract-subscription-indicator">
            <div>
              Comes from{" "}
              <Link
                to={getPathWithTabKey(
                  getRedirectURLWithCurrentParam(
                    getViewContractDetailsPath(subscription.contract.id),
                    location
                  ),
                  "subscriptions"
                )}
                target="_blank"
              >
                {subscription.contract.name}
              </Link>
              's contract subscription
            </div>
          </div>
        )}
        {actionButtons ?? null}
      </header>
      <article>
        <Row>
          <Col lg={6}>
            <table className="subscription-content-display">
              <colgroup>
                <col className="label-column" />
                <col className="value-column" />
              </colgroup>
              <tbody>
                <tr>
                  <td>Start Date</td>
                  <td>
                    {DateTimeUtils.formatLocalDate(subscription.startPeriod)}
                  </td>
                </tr>
                <tr>
                  <td>Reminder Date</td>
                  <td>
                    {subscription.reminderDate
                      ? DateTimeUtils.formatLocalDate(subscription.reminderDate)
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>End Date</td>
                  <td>
                    {DateTimeUtils.formatLocalDate(subscription.endPeriod)}
                  </td>
                </tr>
                <tr>
                  <td>Last Updated</td>
                  <td>
                    <div className="subscription__joined-bullet-container">
                      <UserAvatar
                        fullName={subscription.updatedBy}
                        userEmail=""
                      />
                      <span className="ms-1">{subscription.updatedBy}</span>
                      <GVDSIcon
                        Icon={IconCircleFilled}
                        className="subscription__joined-bullet"
                      />
                      {DateTimeUtils.formatLocalDate(subscription.updatedOn)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Billing Admin</td>
                  <td>
                    {SubscriptionSharedUtils.getBillingAdminDisplay(
                      billingAdmin
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={6}>
            <table className="subscription-content-display">
              <colgroup>
                <col className="label-column" />
                <col className="value-column" />
              </colgroup>
              <tbody>
                <tr>
                  <td>Price</td>
                  <td>
                    {SubscriptionSharedUtils.getPriceDisplay(
                      subscription,
                      subscription.proratedPriceAmount ??
                        subscription.priceAmount
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Billing Cycle</td>
                  <td>
                    {StringUtils.capitaliseWord(
                      subscription.billingCycle ?? ""
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Auto-Renew</td>
                  <td>{subscription.autoRenew ? "On" : "Off"}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>
                    {SubscriptionSharedUtils.getDiscountDisplay(
                      subscription.discountCode
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Account Manager</td>
                  <td>
                    {SubscriptionSharedUtils.getAccountManagerDisplay(
                      accountManager
                    )}
                  </td>
                </tr>
                <tr className="comment-section">
                  <td>Comment</td>
                  <td>
                    {SubscriptionSharedUtils.getCommentDisplay(
                      subscription.comments
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </article>
    </section>
  );
};

const ViewAllSiteSubscriptions = ({
  siteId,
  siteDetails,
  accountManager,
  billingAdmin,
}) => {
  const location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);

  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(true);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [pastSubscriptions, setPastSubscriptions] = useState([]);
  const [showInputSubscriptionModal, setShowInputSubscriptionModal] =
    useState(false);
  const [showDeleteSubscriptionPrompt, setShowDeleteSubscriptionPrompt] =
    useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);

  const [isLoadingSubscriptionOptions, setIsLoadingSubscriptionOptions] =
    useState(true);
  const [allFeatures, setAllFeatures] = useState([]);
  const [orderedPackagePlans, setOrderedPackagePlans] = useState([]);

  const isAllowedToCreateSubscription =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.PORTAL_ADMIN];

  useEffect(() => {
    loadSubscriptionOptions();
    loadAllSubscriptions(false);
  }, []);

  const checkIfDirectAccessSpecificSubscription = (subscriptions) => {
    const modalQuery = getQueryHandler(location).get(MODAL_QUERY_KEY);
    if (modalQuery !== null) {
      const [key, id] = modalQuery.split(TYPE_DELIMITER);
      if (key === SITE_SUBSCRIPTION_MODAL_QUERY_KEY) {
        const subscription = subscriptions.find((s) => s.id === id);
        if (subscription) {
          setCurrentSubscription(subscription);
          setShowInputSubscriptionModal(true);
        } else {
          toastContext.addFailToast(
            <span>Selected subscription is not found.</span>
          );
          removeParamFromQuery(location, MODAL_QUERY_KEY);
        }
      }
    }
  };

  const loadSubscriptionOptions = async () => {
    setIsLoadingSubscriptionOptions(true);
    try {
      const options = await SubscriptionService.getSubscriptionOptions(
        RESOURCES.SITE,
        siteId
      );
      if (options) {
        setAllFeatures(options["features"]);
        setOrderedPackagePlans(options["ordered_package_plans"]);
      }
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to load subscription options.</span>
      );
    } finally {
      setIsLoadingSubscriptionOptions(false);
    }
  };

  const loadAllSubscriptions = async (reloadInventory = true) => {
    setIsLoadingSubscriptions(true);
    try {
      const subscriptions = await SubscriptionService.getSiteSubscriptions(
        siteId
      );
      setActiveSubscriptions(
        SubscriptionSharedUtils.getActiveSubscriptions(subscriptions)
      );
      setPastSubscriptions(
        SubscriptionSharedUtils.getPastSubscriptions(subscriptions)
      );
      checkIfDirectAccessSpecificSubscription(subscriptions);
      if (reloadInventory) {
        userInventory.loadUserInventory();
      }
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to load subscriptions. Please try again.</span>
      );
    } finally {
      setIsLoadingSubscriptions(false);
    }
  };

  const duplicateSubscription = async (subscription) => {
    const duplicatedSubscription = subscription.getDuplicateModel(
      siteDetails.location.country.name === TAX_COUNTRY_NAME_SINGAPORE,
      orderedPackagePlans
    );
    setCurrentSubscription(duplicatedSubscription);
    setShowInputSubscriptionModal(true);
  };

  const getActionButtons = (subscription) => {
    return !subscription.underContractIsFromContractSubscription() ? (
      <Dropdown className="ms-auto">
        <Dropdown.Toggle variant="link" className="primary">
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu className="subscription__action-dropdown" align="end">
          <Dropdown.Item
            disabled={!subscription.stripeInvoiceLink}
            onClick={() => {
              window.open(
                subscription.stripeInvoiceLink,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            <GVDSIcon Icon={IconExternalLink} />
            View Invoice in Stripe
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCurrentSubscription(subscription);
              setShowInputSubscriptionModal(true);
            }}
          >
            <GVDSIcon Icon={IconEdit} />
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={() => duplicateSubscription(subscription)}>
            <GVDSIcon Icon={IconCopy} />
            Copy
          </Dropdown.Item>
          <Dropdown.Item
            className="color-red"
            onClick={() => {
              setCurrentSubscription(subscription);
              setShowDeleteSubscriptionPrompt(true);
            }}
          >
            <GVDSIcon Icon={IconTrash} />
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ) : !!subscription.stripeInvoiceLink ? (
      <Dropdown className="ms-auto">
        <Dropdown.Toggle variant="link" className="primary">
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu className="subscription__action-dropdown" align="end">
          <Dropdown.Item
            onClick={() => {
              window.open(
                subscription.stripeInvoiceLink,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            <GVDSIcon Icon={IconExternalLink} />
            View in Stripe
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ) : null;
  };

  let content;
  content = (
    <>
      <div className="mb-5">
        <div className="d-flex flex-row">
          <h2>
            Ongoing Subscriptions
            {activeSubscriptions.length > 0 && (
              <span> ({activeSubscriptions.length})</span>
            )}
          </h2>
          <div className="ms-auto d-flex flex-row">
            {isAllowedToCreateSubscription && (
              <GVDSButton
                variant={buttonVariant.primary}
                className="create-subscription-button"
                onClick={() => {
                  setCurrentSubscription(null);
                  setShowInputSubscriptionModal(true);
                }}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text="Add Subscription"
              />
            )}
          </div>
        </div>
        {isLoadingSubscriptions ? (
          <LoadingSpinner />
        ) : activeSubscriptions.length === 0 ? (
          SubscriptionSharedUtils.getEmptyOngoingSubscription()
        ) : (
          activeSubscriptions.map((subscription) => (
            <SiteSubscriptionDisplay
              key={subscription.id}
              subscription={subscription}
              allFeatures={allFeatures}
              orderedPackagePlans={orderedPackagePlans}
              actionButtons={getActionButtons(subscription)}
              accountManager={accountManager}
              billingAdmin={billingAdmin}
            />
          ))
        )}
      </div>
      <div>
        <h2>
          Past Subscriptions
          {pastSubscriptions.length > 0 && (
            <span> ({pastSubscriptions.length})</span>
          )}
        </h2>
        {isLoadingSubscriptions ? (
          <LoadingSpinner />
        ) : pastSubscriptions.length === 0 ? (
          SubscriptionSharedUtils.getEmptyPastSubscription()
        ) : (
          pastSubscriptions.map((subscription) => (
            <SiteSubscriptionDisplay
              key={subscription.id}
              subscription={subscription}
              allFeatures={allFeatures}
              orderedPackagePlans={orderedPackagePlans}
              actionButtons={getActionButtons(subscription)}
              accountManager={accountManager}
              billingAdmin={billingAdmin}
            />
          ))
        )}
      </div>
      <InputSiteSubscriptionModal
        showModal={showInputSubscriptionModal}
        onCloseModal={() => {
          removeParamFromQuery(location, MODAL_QUERY_KEY);
          setShowInputSubscriptionModal(false);
        }}
        siteId={siteId}
        isSiteTaxApplicable={
          siteDetails.location.country.name === TAX_COUNTRY_NAME_SINGAPORE
        }
        currentSubscription={currentSubscription}
        allFeatures={allFeatures}
        orderedPackagePlans={orderedPackagePlans}
        onSuccess={loadAllSubscriptions}
      />
      <DeleteSiteSubscriptionPrompt
        showPrompt={showDeleteSubscriptionPrompt}
        onClosePrompt={() => setShowDeleteSubscriptionPrompt(false)}
        siteId={siteId}
        subscriptionId={currentSubscription?.id}
        onSuccess={loadAllSubscriptions}
      />
    </>
  );

  return content;
};

export default ViewAllSiteSubscriptions;
