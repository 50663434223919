import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconArrowsDiagonal, IconTable } from "@tabler/icons-react";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import DropdownDownloadingSpinner from "../../common/DropdownDownloadingSpinner";

const DashboardMinifiedHeaderControls = ({
  showExpandedTableView,
  showExpandedChartView,
  downloadChartAsImage,
  onDownloadTableAsExcel,
  disabled = false,
  isDownloading = false,
}) => {
  return (
    <>
      <GVDSIconButton
        tooltipText="View as table"
        className="ms-0"
        variant={iconButtonVariant.tertiary}
        icon={<GVDSIcon Icon={IconTable} />}
        onClick={showExpandedTableView}
        disabled={disabled}
      />
      <GVDSIconButton
        tooltipText="Expanded view"
        className="ms-0"
        variant={iconButtonVariant.tertiary}
        icon={<GVDSIcon Icon={IconArrowsDiagonal} />}
        onClick={showExpandedChartView}
        disabled={disabled}
      />
      {isDownloading ? (
        <DropdownDownloadingSpinner className="me-2" />
      ) : (
        <Dropdown className="dashboard-minified-header-control__dropdown">
          <Dropdown.Toggle
            as={OtherActionDropdownToggle}
            disabled={disabled}
            tooltipText="More actions"
          />
          <Dropdown.Menu>
            <Dropdown.Item
              key="download-chart"
              href="#"
              onClick={downloadChartAsImage}
            >
              Download chart as image
            </Dropdown.Item>
            <Dropdown.Item
              key="download-table"
              href="#"
              onClick={onDownloadTableAsExcel}
            >
              Download table as Excel
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default DashboardMinifiedHeaderControls;
