import React from "react";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "./GVDSFormShared";
import Form from "react-bootstrap/Form";

const GVDSFormField = React.forwardRef(
  (
    {
      type = "text",
      value,
      onInput,
      name,
      disabled = false,
      className = "",
      placeholder = null,
      statusMetadata = FormFieldStatusMetadata.getDefault(),
      ...props
    },
    ref
  ) => {
    const gvdsClassNames = ["gvds-form__field"].concat(
      statusMetadata.state === FormFieldState.warning
        ? ["gvds-form__warning"]
        : statusMetadata.state === FormFieldState.error
        ? ["gvds-form__error"]
        : []
    );
    const combinedClassNames = `${gvdsClassNames.join(" ")} ${className || ""}`;

    return (
      <>
        <Form.Control
          ref={ref}
          type={type}
          name={name}
          value={value ?? ""}
          className={combinedClassNames}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => onInput(e.target.value, e)}
          {...props}
        />
        {statusMetadata.isNotNormal() && statusMetadata.message && (
          <GVDSFormErrorMessage
            status={statusMetadata.state}
            errorMsg={statusMetadata.message}
          />
        )}
      </>
    );
  }
);

export default GVDSFormField;
