import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import withAuthentication from "../../HOC/withAuthentication";
import { PEOPLE_DATA } from "../../../config/ROUTES_NAME";
import {
  getDefaultMonthRangeEnd,
  getDefaultMonthRangeStart,
} from "../../common/MonthRangeSelector";
import LoadingSpinner from "../../common/LoadingSpinner";
import { DateTimeUtils } from "../../../services/UtilsService";
import PeopleService from "../../../services/PeopleService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import InputPeopleRecordModal from "./InputPeopleRecord/InputPeopleRecordModal";
import ActivityLogService from "../../../services/ActivityLogService";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import PeopleGroupingsTable from "./PeopleGroupingsTable";
import DeletePeopleRecordPrompt from "./DeletePeopleRecordPrompt";
import PermissionsContext from "../../../context/PermissionsContext";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "../../common/ReactHook";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconExternalLink, IconTrash } from "@tabler/icons-react";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSTable, {ACTIONS_DATAKEY, ACTIONS_TABLE_HEADER} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";

const ViewPeopleMeterDetails = () => {
  let history = useHistory();
  let location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const [start, setStart] = useState(getDefaultMonthRangeStart());
  const [end, setEnd] = useState(getDefaultMonthRangeEnd());
  const [isLoading, setIsLoading] = useState(false);
  const [showInputModal, setShowInputModal] = useState(false);

  const [meter, setMeter] = useState({});
  const [meterRecords, setMeterRecords] = useState([]);
  const [activities, setActivities] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(meterRecords, null);

  const onModalClose = () => {
    setShowInputModal(false);
    setCurrentlyEditedRecord(null);
  };

  const onRecordUpdated = () => {
    loadDataRecords();
    loadActivityLogs();
  };

  const [currentlyEditedRecord, setCurrentlyEditedRecord] = useState(null);

  const [currentlyToBeDeletedRecords, setCurrentlyToBeDeletedRecords] =
    useState([]);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };
  const [isDeleting, setIsDeleting] = useState(false);

  const loadDataRecords = () => {
    if (selectedInventory?.type === RESOURCES.SITE && meter.id) {
      PeopleService.getPeopleRecords(
        selectedInventory?.id,
        start,
        end,
        meter.id
      )
        .then((data) => {
          setIsLoading(false);
          setMeterRecords(
            data.map((record) => {
              return {
                ...record,
                record_month: moment.utc(record.record_month).toDate(),
              };
            })
          );
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to load people data.</span>);
        });
    }
  };

  const loadActivityLogs = () => {
    ActivityLogService.getLogs(meter.id, RESOURCES.PEOPLE_METER).then(
      setActivities
    );
  };

  useEffect(() => {
    if (location.state) {
      setMeter(location.state.meter);
    } else {
      backToDashboard();
    }
  }, []);

  useEffect(() => {
    if (previousInventory && previousInventory !== selectedInventory) {
      backToDashboard();
    }
  }, [selectedInventory]);

  useEffect(() => {
    if (meter.id) {
      loadDataRecords();
    }
  }, [meter, start, end]);

  useEffect(() => {
    if (meter.id) {
      loadActivityLogs();
    }
  }, [meter]);

  const backToDashboard = () => {
    history.push(PEOPLE_DATA);
  };

  const showEditRecord = (record) => {
    setCurrentlyEditedRecord(record);
    setShowInputModal(true);
  };

  const promptDeleteRecord = (records) => {
    setCurrentlyToBeDeletedRecords(records);
    setShowPromptDeleteModal(true);
  };

  const deleteCurrentlySelectedRecords = () => {
    setIsDeleting(true);
    PeopleService.deletePeopleRecords(
      selectedInventory.id,
      currentlyToBeDeletedRecords.map((record) => record.id)
    )
      .then(() => {
        toastContext.addSuccessToast(
          <span>Data record deleted successfully</span>
        );
        setCurrentlyToBeDeletedRecords([]);
        onRecordUpdated();
        handleClosePromptDeleteModal();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const columns = [
    {
      header: "Month Year",
      dataKey: "record_month",
      sortable: true,
      headerStyle: { width: "300px" },
      renderer: (d) => DateTimeUtils.formatUTCMonthYear(d["record_month"]),
    },
    {
      header: "Comment",
      dataKey: "comment",
      sortable: true,
      headerStyle: { width: "100px" },
      renderer: (d) =>
        d["comment"] ? <CommentTooltip comment={d["comment"]} /> : null,
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      headerStyle: { width: "300px" },
      renderer: (r) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              icon={
                <GVDSIcon
                  Icon={
                    !permissionCtx.isLoadingPermissions &&
                    permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_EDIT]
                      ? IconEdit
                      : IconExternalLink
                  }
                />
              }
              onClick={(e) => {
                e.preventDefault();
                showEditRecord(r);
              }}
              tooltipText={
                !permissionCtx.isLoadingPermissions &&
                permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_EDIT]
                  ? "View/Edit"
                  : "View"
              }
            />

            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_DELETE] && (
                <GVDSIconButton
                  variant={iconButtonVariant.destructive}
                  icon={<GVDSIcon Icon={IconTrash} />}
                  onClick={() => {
                    promptDeleteRecord([r]);
                  }}
                  tooltipText="Delete"
                />
              )}
          </>
        );
      },
    },
  ];

  const meterDetailsTab = (
    <>
      <div className="d-flex flex-row align-items-center">
        <h2 className="meter-details">Groupings Tracked</h2>
        <div className="ms-auto">
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_CREATE] && (
              <GVDSButton
                variant={buttonVariant.primary}
                className="add-data"
                onClick={() => setShowInputModal(true)}
                text="Input Single Record"
              />
            )}
        </div>
      </div>
      <div className="p-3 mb-2">
        <PeopleGroupingsTable groupings={meter.groupings} />
      </div>

      <h2 className="past-records mt-4">Past Data Records</h2>
      {isLoading || !meterRecords ? (
        <LoadingSpinner />
      ) : (
        <GVDSTable
          className="past-records"
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          noContent={
            <div className="table__no_content">
              <p>
                No data records found within the filtered time period. Try
                adjusting your filter.
              </p>
            </div>
          }
          viewControl={
            <GVDSTableCtrlContainer>
              <GVDSFormStartEndMonthPicker
                startMonth={start}
                endMonth={end}
                onChange={(s, e) => {
                  if (s !== start) {
                    setStart(s);
                  }
                  if (e !== end) {
                    setEnd(e);
                  }
                }}
              />
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
          }
        />
      )}
      {meter.id && (
        <InputPeopleRecordModal
          showModal={showInputModal}
          onClose={onModalClose}
          onDoneInput={onRecordUpdated}
          meterDetails={meter}
          currentRecord={currentlyEditedRecord}
        />
      )}
      <DeletePeopleRecordPrompt
        noOfRecordsToBeDeleted={1}
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        proceed={deleteCurrentlySelectedRecords}
        isDeleting={isDeleting}
      />
    </>
  );

  const activityLogTab = (
    <GVDSTableDisplay className="activity-logs">
      <thead>
        <tr>
          <th>No.</th>
          <th>Action</th>
          <th>Action By</th>
          <th>Action On</th>
        </tr>
      </thead>

      <tbody>
        {activities.map((a, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{a.action}</td>
            <td>{a["action_by"]["email"]}</td>
            <td>{DateTimeUtils.formatUTCDate(a["action_on"])} </td>
          </tr>
        ))}
      </tbody>
    </GVDSTableDisplay>
  );

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text="Return to Data Input"
          onClick={backToDashboard}
        />
        <PageHeader.Title>
          <h1>{meter.subtopic?.name}</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="subtopic-overview">
        <Tab eventKey="subtopic-overview" title="Subtopic Overview">
          {meterDetailsTab}
        </Tab>
        <Tab eventKey="activity-log" title="Activity Log">
          {activityLogTab}
        </Tab>
      </Tabs>
    </div>
  );
};

export default withAuthentication(ViewPeopleMeterDetails);
