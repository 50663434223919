import React, { useRef, useState } from "react";
import uniq from "lodash/uniq";
import Form from "react-bootstrap/Form";

import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import { UtilsService } from "../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSTag from "../../../gvds-components/common/GVDSTag";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { surveyBestPracticeQuestionSearchKeys } from "../../../config/search-config";
import GVDSTable, {
  MULTI_SELECT_DATAKEY,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";

const SurveyBestPracticeQuestionLink = ({
  allQuestions,
  alreadyAddedQuestionIds,
  onAdding,
}) => {
  const parentModalToMultiselectRef = useRef();
  const [showBestPracticeModal, setShowBestPracticeModal] = useState(false);
  const closeBestPracticeModal = () => {
    setShowBestPracticeModal(false);
  };

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const toggleSelection = (q) => {
    setSelectedQuestions(UtilsService.toggleItem(selectedQuestions, q));
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allQuestions, surveyBestPracticeQuestionSearchKeys);

  const addQuestions = () => {
    onAdding(selectedQuestions);
    closeBestPracticeModal();
    setSelectedQuestions([]);
  };

  const selectionModalColumns = [
    {
      header: "",
      dataKey: MULTI_SELECT_DATAKEY,
      renderer: (q) => {
        return (
          <div>
            <Form.Check
              checked={
                selectedQuestions.indexOf(q) >= 0 ||
                alreadyAddedQuestionIds.indexOf(q.id) >= 0
              }
              disabled={alreadyAddedQuestionIds.indexOf(q.id) >= 0}
              type="checkbox"
              key={q.id}
              id={q.id}
              label=""
              onChange={() => toggleSelection(q)}
            />
          </div>
        );
      },
    },
    {
      header: "Question",
      dataKey: "questionText",
    },
    {
      header: "Categories",
      dataKey: "categoryNames",
      renderer: (q) =>
        q.categoryNames.map((category) => (
          <GVDSTag variant={GVDSTag.Variants.system} key={category}>
            {category}
          </GVDSTag>
        )),
    },
    {
      header: "Tags",
      dataKey: "tagNames",
      renderer: (q) =>
        q.tagNames.map((tag) => (
          <GVDSTag variant={GVDSTag.Variants.system} key={tag}>
            {tag}
          </GVDSTag>
        )),
    },
  ];

  return (
    <>
      <GVDSButton
        variant={buttonVariant.tertiary}
        onClick={() => {
          setShowBestPracticeModal(true);
        }}
        text="Add Best Practice Questions"
      />
      <GVDSModal
        ref={parentModalToMultiselectRef}
        title="Add Best Practice Questions"
        size={GVDSModal.Size.large}
        show={showBestPracticeModal}
        onHide={closeBestPracticeModal}
      >
        <GVDSModal.Body>
          <GVDSTableCtrlContainer>
            <FilterSearchBox
              placeholder="Search Best Practice Question"
              value={searchText}
              onInput={setSearchText}
            />
            <GVDSTableCtrlMultiSelect
              parentModalRef={parentModalToMultiselectRef}
              options={uniq(allQuestions.map((q) => q.categoryNames).flat())}
              prefix="Categories"
              onChange={(filterKs) =>
                setFilterKeys({ ...filterKeys, categoryNames: filterKs })
              }
            />
            <GVDSTableCtrlMultiSelect
              parentModalRef={parentModalToMultiselectRef}
              options={uniq(allQuestions.map((q) => q.tagNames).flat())}
              prefix="Tags"
              onChange={(filterKs) =>
                setFilterKeys({ ...filterKeys, tagNames: filterKs })
              }
            />
            <GVDSPagination
              startIndex={startIndex}
              endIndex={endIndex}
              total={totalDataLength}
              onChange={onPaginationChange}
            />
          </GVDSTableCtrlContainer>
          <GVDSTable
            columns={selectionModalColumns}
            dataToDisplay={currentPageData}
            startIndex={startIndex}
            sortKeys={sortKeys}
            setSortKeys={setSortKeys}
          />
          {filteredSortedData.length === 0 && (
            <div className="table__no_content">No record found</div>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeBestPracticeModal}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={addQuestions}
            text="Add to Survey"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};
export default SurveyBestPracticeQuestionLink;
