import { matchPath } from "react-router-dom";

import {
  BEST_PRACTICES_ASSESS,
  ENVIRONMENTAL_DATA,
  GOALS_AND_TARGETS,
  GSTC,
  HOME,
  REPORT_PERFORMANCE_REPORT,
  VIEW_ALL_INITIATIVES,
} from "../../../config/ROUTES_NAME";
import { RESOURCES } from "../../../config/constants";
import { DomUtils } from "../../../services/UtilsService";
import {
  GUIDED_TOUR_HELP_ARTICLE_TITLE,
  GuidedTourHelpArticleInlineLink,
} from "./GuidedTourHelpArticleLink";
import {
  IconChevronDown,
  IconChevronRight,
  IconLayoutSidebarLeftCollapse,
  IconPin,
} from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import React from "react";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";

const GuidedTourSteps = [
  {
    path: HOME,
    applicableResources: [
      RESOURCES.PORTFOLIO,
      RESOURCES.SITE,
      RESOURCES.FACILITY,
    ],
    steps: [
      {
        target: ".guided-tour-target__new-look__side-nav-modules",
        title: "New Side Navigation",
        spotlightPadding: 0,
        content: (
          <>
            Discover the Portal modules through our new side navigation panel –
            everything is within reach.
            <br />
            <br />
            Expand (
            <GVDSIconSlim Icon={IconChevronRight} className="inline-icon" />) to
            navigate to other pages within the module, and collapse (
            <GVDSIconSlim Icon={IconChevronDown} className="inline-icon" />) to
            hide it.
            <br />
            <br />
            Need more space? Simply collapse (
            <GVDSIcon
              Icon={IconLayoutSidebarLeftCollapse}
              className="inline-icon"
            />
            ) the panel, or pin (
            <GVDSIcon Icon={IconPin} className="inline-icon" />) it back with a
            click.
          </>
        ),
        placement: "right-start",
      },
      {
        target: ".guided-tour-target__new-look__side-nav-account",
        title: "Your Account, Your Control",
        spotlightPadding: 0,
        content: (
          <>
            Conveniently manage your account settings at the bottom of the side
            navigation panel.
          </>
        ),
        placement: "right-end",
      },
      {
        target: ".guided-tour-target__new-look__workspace-selector",
        title: "Switch between Sites",
        content: <>Effortlessly switch between multiple sites or facilities.</>,
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__new-look__file-storage",
        title: "Easy File Storage Access",
        content: <>Uploading and managing your files is just a click away.</>,
        placement: "bottom-end",
      },
      {
        target: ".guided-tour-target__new-look__help",
        title: "Tailored Help and Support",
        content: (
          <>
            Need a hand? The Help button gives you recommended page-specific
            Help Guides, guided tours, and our Help Center.
            <br />
            <br />
            You're now all set to explore and make the most of our enhanced
            platform. Happy sustainability tracking!
          </>
        ),
        placement: "bottom-end",
      },
    ],
  },
  {
    path: ENVIRONMENTAL_DATA,
    applicableResources: [RESOURCES.SITE, RESOURCES.FACILITY],
    steps: [
      {
        target: ".guided-tour-target__environment-data-setup",
        content: (
          <>
            Click here to <strong>setup</strong> and begin tracking your
            environmental data. You can return here anytime to manage your
            meters and trackers:
            <br />
            <br />
            <strong>Usage Meters</strong> track your site's utility usage.
            <br />
            <strong>Market Purchase Trackers</strong> offset your site's utility
            usage from applicable usage meters.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={GUIDED_TOUR_HELP_ARTICLE_TITLE.ENV_SETUP_ENV_DATA}
            />{" "}
            to learn more.
          </>
        ),
        placement: "left-start",
      },
      {
        target: ".guided-tour-target__environment-data-meters",
        content: (
          <>
            Your site's existing meters are shown here. Click the row to view a
            specific meter.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={
                GUIDED_TOUR_HELP_ARTICLE_TITLE.ENV_READ_ENV_DATA_STATUS_AND_ISSUES
              }
            />{" "}
            to learn more.
          </>
        ),
        placement: "bottom-start",
      },
      {
        target: ".guided-tour-target__environment-input",
        content: (
          <>
            Click here to <strong>input record</strong>.
            <br />
            <br />
            You can input a <strong>single record</strong>, or if you want to
            input <strong>multiple records</strong>, you can download an Excel
            template and input records into multiple meters of different
            environmental types.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={GUIDED_TOUR_HELP_ARTICLE_TITLE.ENV_INPUT_ENV_DATA}
            />{" "}
            to learn more.
          </>
        ),
        placement: "left-start",
      },
      {
        target: ".guided-tour-target__environment-input-in-all-records",
        content: (
          <>
            Click here to <strong>input record</strong>.
            <br />
            <br />
            You can input a <strong>single record</strong>, or if you want to
            input <strong>multiple records</strong>, you can download an Excel
            template and input records into multiple meters of different
            environmental types.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={GUIDED_TOUR_HELP_ARTICLE_TITLE.ENV_INPUT_ENV_DATA}
            />{" "}
            to learn more.
          </>
        ),
        placement: "left-start",
      },
      {
        target: ".guided-tour-target__environment-history",
        content: (
          <>
            Click here to view a <strong>history</strong> of all environmental
            data records.
          </>
        ),
        placement: "bottom",
      },
    ],
  },
  {
    path: VIEW_ALL_INITIATIVES,
    applicableResources: [RESOURCES.SITE],
    steps: [
      {
        target: ".guided-tour-target__initiative-input",
        content: (
          <>
            Start tracking activities or efforts done at the site with the
            following subtopics: <strong>Efficiency & Environment</strong>,{" "}
            <strong>Social Impact</strong>, <strong>Procurement</strong> and{" "}
            <strong>Other</strong>.
            <br />
            <br />
            Click here to log an initiative.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={GUIDED_TOUR_HELP_ARTICLE_TITLE.INIT_LOG_INIT}
            />{" "}
            to learn more.
          </>
        ),
        placement: "left-start",
      },
      {
        target: ".guided-tour-target__active-initiatives",
        content: (
          <>
            The "<strong>Active Initiatives</strong>" tab shows you all
            initiatives with the status "Planned" or "In Progress".
          </>
        ),
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__all-initiatives",
        content: (
          <>
            The "<strong>All Initiatives</strong>" tab shows all your
            initiatives with any status.
            <br />
            <br />
            Tip: Generate an Initiatives Report to aggregate initiatives across
            a specified period. View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={GUIDED_TOUR_HELP_ARTICLE_TITLE.INIT_RUN_REPORT}
            />{" "}
            to learn more.
          </>
        ),
        placement: "bottom",
      },
    ],
  },
  {
    path: REPORT_PERFORMANCE_REPORT,
    applicableResources: [
      RESOURCES.PORTFOLIO,
      RESOURCES.SITE,
      RESOURCES.FACILITY,
    ],
    steps: [
      {
        target: ".guided-tour-target__performance-report__period-selector",
        content: (
          <>
            Select one or more year(s). You can also select specific months.
            This is typically used in reports for the current calendar year.
          </>
        ),
        placement: "right",
      },
      {
        target: ".guided-tour-target__performance-report__subtopic-selector",
        content: (
          <>
            Select an <strong>environmental</strong> or{" "}
            <strong>operational Subtopic</strong>.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={
                GUIDED_TOUR_HELP_ARTICLE_TITLE.REPORT_PERF_UNDERSTAND_ENV
              }
            />{" "}
            and{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={
                GUIDED_TOUR_HELP_ARTICLE_TITLE.REPORT_PERF_UNDERSTAND_OPS
              }
            />{" "}
            to learn more.
          </>
        ),
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__performance-report__data-type-selector",
        content: (
          <>
            Select a <strong>Performance Grouping</strong> or{" "}
            <strong>Single Type</strong>. Select the unit.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={
                GUIDED_TOUR_HELP_ARTICLE_TITLE.REPORT_PERF_UNDERSTAND_ENV
              }
            />{" "}
            and{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={
                GUIDED_TOUR_HELP_ARTICLE_TITLE.REPORT_PERF_UNDERSTAND_OPS
              }
            />{" "}
            to learn more.
          </>
        ),
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__performance-report__metric-selector",
        content: (
          <>
            Select an <strong>Operational Metric</strong> to run intensity
            reports based on floor area or hotel operations (only available for
            sites with Site Type: Hotel/Accommodation).
          </>
        ),
        placement: "top",
      },
      {
        target: ".guided-tour-target__performance-report__report-availability",
        content: (
          <>
            This <strong>preview section</strong> shows you the available data
            formats for your report based on your selection.
          </>
        ),
        placement: "left",
      },
      {
        target:
          ".guided-tour-target__performance-report__benchmark-availability",
        content: (
          <>
            The{" "}
            <strong>Cornell Hotel Sustainability Benchmarking (CHSB)</strong>{" "}
            Index enables hotels to benchmark their environmental performance
            against peers.
            <br />
            <br />
            To compare with other hotels in the same metro area, country, or
            climate zone, follow the instructions in this box.
            <br />
            <br />
            This is only available for sites with Site Type:
            Hotel/Accommodation.
          </>
        ),
        placement: "left",
      },
      {
        target: ".guided-tour-target__performance-report__generate-report",
        content: (
          <>
            When all mandatory fields are filled in, you can{" "}
            <strong>download an Excel report</strong>, or{" "}
            <strong>run the report</strong> within this page.
          </>
        ),
        placement: "top",
      },
      {
        target: ".guided-tour-target__performance-report__data-request",
        content: (
          <>
            To address data gaps in a report, click here to{" "}
            <strong>issue a data request</strong> to the relevant site(s). Sites
            with complete data will not receive this request.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={
                GUIDED_TOUR_HELP_ARTICLE_TITLE.REPORT_PERF_ISSUE_DATA_REQUEST
              }
            />{" "}
            to learn more.
          </>
        ),
        placement: "top",
      },
    ],
  },
  {
    path: GOALS_AND_TARGETS,
    applicableResources: [RESOURCES.SITE],
    steps: [
      {
        target: ".guided-tour-target__goals",
        content: (
          <>
            <strong>Goals</strong> convey your long-term sustainability vision
            and commitments, while <strong>Targets</strong> provide a clear
            course of action towards achieving a goal. You can use goals to
            group multiple targets.
          </>
        ),
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__create-goal",
        content: (
          <>
            Click here to <strong>create a goal</strong>.
          </>
        ),
        placement: "left",
      },
      {
        target: ".guided-tour-target__targets",
        content: (
          <>
            <strong>Targets</strong> break down goals into smaller and
            achievable steps with measurable outcomes. You can track and assess
            your target progress automatically based on data inputted under Data
            Management.
            <br />
            <br />
            You can create targets of the following topics:{" "}
            <strong>Community & Social Impact</strong>,{" "}
            <strong>Environmental Performance</strong> and{" "}
            <strong>Free-form</strong>.
          </>
        ),
        placement: "top",
      },
      {
        target: ".guided-tour-target__create-target",
        content: (
          <>
            Click here to <strong>create a target</strong>.
          </>
        ),
        placement: "left",
      },
    ],
  },
  {
    path: GSTC,
    applicableResources: [RESOURCES.SITE],
    steps: [
      {
        target: ".guided-tour-target__gstc-list",
        content: (
          <>
            <strong>Greenview's GSTC Guidance</strong> unpacks the GSTC Criteria
            into action-oriented step-by-step chapters to assist properties
            towards attaining GSTC certification.
            <br />
            <br />
            Click on the <strong>chapters</strong> to learn more and align your
            site with the GSTC Criteria.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={GUIDED_TOUR_HELP_ARTICLE_TITLE.GSTC_NAVIGATE}
            />{" "}
            to learn more.
          </>
        ),
        placement: "right",
      },
      {
        target: ".guided-tour-target__gstc-search",
        content: (
          <>
            <strong>Search</strong> for a specific Action Item or GSTC
            criterion.
          </>
        ),
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__gstc-action-item",
        content: (
          <>
            For each <strong>Action Item</strong>, the guidance will be
            displayed here.
            <br />
            <br />
            To update your site's <strong>Satisfactory Outcome</strong> for an
            Action Item, select an appropriate answer: "Yes", "No", or "N/A".
            <br />
            <br />
            When Satisfactory Outcomes are updated, they will contribute to the
            site's Readiness Score.
          </>
        ),
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__gstc-readiness-score",
        content: (
          <>
            The <strong>Readiness Score</strong> calculates the number of
            Satisfactory Outcomes your site has completed. A higher score
            indicates a higher readiness to getting certified.
          </>
        ),
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__gstc-report",
        content: (
          <>
            To see a breakdown of the Readiness Score and a checklist of this
            site’s Satisfactory Outcomes, click here to download a{" "}
            <strong>GSTC Readiness Report</strong>.
          </>
        ),
        placement: "bottom",
      },
    ],
  },
  {
    path: BEST_PRACTICES_ASSESS,
    applicableResources: [RESOURCES.PORTFOLIO, RESOURCES.SITE],
    steps: [
      {
        target: ".guided-tour-target__best-practice__categories",
        content: (
          <>
            <strong>Best Practices</strong> enable you to understand and assess
            level of achievement in sustainability practices.
            <br />
            <br />
            Use <strong>categories</strong> to filter Best Practice Questions of
            interest to you.
            <br />
            <br />
            Tip: View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={GUIDED_TOUR_HELP_ARTICLE_TITLE.BPQ_NAVIGATE}
            />{" "}
            to learn more.
          </>
        ),
        placement: "bottom",
      },
      {
        target: ".guided-tour-target__best-practice__adoption-snapshot",
        content: (
          <>
            This <strong>Best Practices Snapshot</strong> shows the adoption
            level summary of best practices at your site.
            <br />
            <br />
            Tip: Run Best Practices Report to view a summary of your site/
            portfolio's response to the Best Practices. View{" "}
            <GuidedTourHelpArticleInlineLink
              articleTitle={GUIDED_TOUR_HELP_ARTICLE_TITLE.BPQ_RUN_REPORT}
            />{" "}
            to learn more.
          </>
        ),
        placement: "top",
      },
      {
        target: ".guided-tour-target__best-practice__question",
        content: (
          <>
            Answer <strong>Best Practice Questions</strong> to start learning
            more about sustainability practices. Check out the{" "}
            <strong>Relevant Guides</strong> for additional resources.
          </>
        ),
        placement: "top",
      },
      {
        target: ".guided-tour-target__best-practice__question-adoption-level",
        content: (
          <>
            View the <strong>Adoption Level</strong> after answering a Best
            Practice Question.
          </>
        ),
        placement: "left",
      },
    ],
  },
];

const guidedTourStepsByResourceTypeAndPath = GuidedTourSteps.reduce(
  (optimisedSteps, currentStepsConfig) => {
    currentStepsConfig.steps.forEach((s) => (s.disableBeacon = true));

    currentStepsConfig.applicableResources.forEach((r) => {
      optimisedSteps[r][currentStepsConfig.path] = currentStepsConfig;
    });

    return optimisedSteps;
  },
  {
    [RESOURCES.PORTFOLIO]: {},
    [RESOURCES.SITE]: {},
    [RESOURCES.FACILITY]: {},
  }
);

const getGuidedTourStepsByPathAndResourceType = (pathname, resourceType) => {
  if (!resourceType) {
    return [];
  }

  const guidedTourStepsByPath =
    guidedTourStepsByResourceTypeAndPath[resourceType];

  const matchingPath = Object.keys(guidedTourStepsByPath).find((mappedPath) => {
    return matchPath(pathname, { path: mappedPath, exact: true });
  });

  return matchingPath ? guidedTourStepsByPath[matchingPath].steps : [];
};

// this is not built into get function because the content might still be loading hence no elements are visible
export const filterVisibleSteps = (steps) => {
  return steps.filter((step) => {
    return DomUtils.isElementRendered(step.target);
  });
};

export default getGuidedTourStepsByPathAndResourceType;
