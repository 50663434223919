import JoinedBullet from "../../../common/JoinedBullet";
import GVDSButtonGroup from "../../../../gvds-components/Buttons/GVDSButtonGroup";
import DashboardMinifiedHeaderControls from "../DashboardMinifiedHeaderControls";
import React, { useContext } from "react";
import {
  performanceTimelineChartConfig,
  performanceTimelinePeriodGroupingOptions,
} from "./PerformanceTimelineViewConfig";
import DashboardExpandedHeaderControls from "../DashboardExpandedHeaderControls";
import DashboardDataGapsIndicator from "../DashboardDataGapsIndicator";
import DashboardDataContext from "../../Context/DashboardDataContext";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import { TOOLTIP_PLACEMENTS } from "../../../../config/style-config";

export const PerformanceTimelineChartViewHeader = ({
  title,
  titleInfoTooltip,
  hasDataGaps,
  period,
  subtopic,
  intensity,
  showExpandedChartView,
  showExpandedTableView,
  hasData,
  selectedTimePeriodOption,
  onSelectTimePeriodOption,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  const dashboardDataContext = useContext(DashboardDataContext);

  return (
    <div className="header-container">
      <div>
        <div className="title-container">
          <div className="title">{title}</div>
          {!!titleInfoTooltip && (
            <InfoTooltip
              info={titleInfoTooltip}
              placement={TOOLTIP_PLACEMENTS.TOP}
            />
          )}
          {hasDataGaps && <DashboardDataGapsIndicator />}
        </div>
        <div className="subtitle-container">
          <div className="subtitle">{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div className="subtitle">
            {subtopic} {intensity}
          </div>
        </div>
      </div>
      {!dashboardDataContext.isDownloadingDashboardAsImage && (
        <div
          id={
            performanceTimelineChartConfig.miniViewExcludeOnDownloadContainerId
          }
          className="dashboard-chart-header__action-buttons"
        >
          <GVDSButtonGroup
            className="dashboard--performance-timeline__period-grouping-button-group"
            options={performanceTimelinePeriodGroupingOptions}
            selectedOption={selectedTimePeriodOption}
            onSelect={onSelectTimePeriodOption}
            disabled={!hasData}
          />
          <DashboardMinifiedHeaderControls
            showExpandedTableView={showExpandedTableView}
            showExpandedChartView={showExpandedChartView}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
            disabled={!hasData}
            isDownloading={isDownloadingChart}
          />
        </div>
      )}
    </div>
  );
};

export const ExpandedPerformanceTimelineChartViewHeader = ({
  title,
  period,
  subtopic,
  intensity,
  hasDataGaps,
  selectedTimePeriodOption,
  onSelectTimePeriodOption,
  selectedExpandedViewOption,
  onSelectExpandedViewOption,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  return (
    <div className="dashboard--performance-timeline__expanded-chart-view__header-container">
      <div>
        <div className="expanded-chart-view__title-container">
          <div className="expanded-chart-view__title">{title}</div>
          {hasDataGaps && <DashboardDataGapsIndicator />}
        </div>
        <div className="expanded-chart-view__subtitle">
          <div>{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div>
            {subtopic} {intensity}
          </div>
        </div>
      </div>
      <div
        id={
          performanceTimelineChartConfig.expandedViewExcludeOnDownloadContainerId
        }
        className="dashboard-chart-header__action-buttons"
      >
        <GVDSButtonGroup
          className="dashboard--performance-timeline__period-grouping-button-group"
          options={performanceTimelinePeriodGroupingOptions}
          selectedOption={selectedTimePeriodOption}
          onSelect={onSelectTimePeriodOption}
        />
        <DashboardExpandedHeaderControls
          selectedViewOption={selectedExpandedViewOption}
          onSelectViewOption={onSelectExpandedViewOption}
          onExportChart={downloadChartAsImage}
          onDownloadTableAsExcel={onDownloadTableAsExcel}
          isDownloading={isDownloadingChart}
        />
      </div>
    </div>
  );
};
