import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  PERMISSIONS,
  RESOURCES,
  TARGET_STATUS,
  TARGET_TOPIC,
  TARGET_TOPIC_DISPLAY_LABEL,
} from "../../config/constants";
import PermissionsContext from "../../context/PermissionsContext";
import InputCollectiveTargetModal from "./InputCollectiveTargetModal";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import { getViewTargetDetailsPath } from "../../config/ROUTES_NAME";
import PopoverOnTruncated from "../common/Tooltip/PopoverOnTruncated";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import WorkspaceInventoryIcon from "../../gvds-components/common/WorkspaceInventoryIcon";
import Spacer from "../../gvds-components/Layout/Spacer";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import {
  IconCirclePlus,
  IconSearch,
  IconTargetArrow,
} from "@tabler/icons-react";
import {UtilsService} from "../../services/UtilsService";

const CollectiveTargetCard = ({ collectiveTarget }) => {
  const history = useHistory();
  const location = useLocation();

  const redirectToCollectiveTargetDetails = () => {
    history.push(
      getRedirectURLWithCurrentParam(
        getViewTargetDetailsPath(collectiveTarget.id),
        location
      )
    );
  };

  return (
    <div className="target-box" onClick={redirectToCollectiveTargetDetails}>
      <div className="target-box--content-container">
        <div className="target-box--summary">
          <PopoverOnTruncated
            content={collectiveTarget.name}
            contentClassName="target-box--summary__target-name"
          />
          <div className="status-container">
            <StatusLabel color={StatusLabel.Colors.gray}>
              <GVDSIcon Icon={WorkspaceInventoryIcon[RESOURCES.PORTFOLIO]} />
              Shared Target
            </StatusLabel>
            {collectiveTarget.status === TARGET_STATUS.CLOSED && (
              <StatusLabel color={StatusLabel.Colors.gray}>Closed</StatusLabel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ViewPortfolioTargets = ({ collectiveTargets, onInputSuccess }) => {
  const permissionCtx = useContext(PermissionsContext);

  const [showInputModal, setShowInputModal] = useState(false);
  const [filterKeys, setFilterKeys] = useState({
    statuses: [TARGET_STATUS.OPEN],
  });

  const isAllowedToCreateTarget =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.GOALS_N_TARGETS_CREATE];

  const getDisplayCollectiveTargets = () => {
    return collectiveTargets
      .filter((t) => {
        let matchFilter = true;

        if (filterKeys.statuses && filterKeys.statuses.length > 0) {
          matchFilter =
            matchFilter && filterKeys.statuses.indexOf(t.status) >= 0;
        }

        if (filterKeys.topics && filterKeys.topics.length > 0) {
          const topicFilters = filterKeys.topics.map((v) =>
            UtilsService.getKeyByValue(TARGET_TOPIC_DISPLAY_LABEL, v)
          );
          matchFilter = matchFilter && topicFilters.indexOf(t.topic) >= 0;
        }

        return matchFilter;
      })
      .sort((a, _) => (a.status === TARGET_STATUS.CLOSED ? 1 : -1));
  };

  const getNoContentPage = (isFiltered) => {
    return (
      <div className="portfolio-targets__no-content">
        {isFiltered ? (
          <>
            <GVDSIcon Icon={IconSearch} className="gvds-empty-content--icon" />
            <h3>No results found</h3>
            <div>Clear your filters and try again.</div>
          </>
        ) : (
          <>
            <GVDSIcon
              Icon={IconTargetArrow}
              className="gvds-empty-content--icon"
            />
            <h3>Start building your targets</h3>
            <div>
              Click on the "Create Shared Target" button at the top-right of
              this page to start keeping track of your portfolio's targets.
            </div>
          </>
        )}
      </div>
    );
  };

  const displayedCollectiveTargets = getDisplayCollectiveTargets();

  return (
    <>
      <div className="portfolio-targets__container">
        <div className="d-flex flex-row mb-2">
          <h2>Targets</h2>

          {isAllowedToCreateTarget && (
            <>
              <Spacer />
              <GVDSButton
                variant={buttonVariant.primary}
                className="create-collective-target-button"
                onClick={() => setShowInputModal(true)}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                text="Create Shared Target"
              />
            </>
          )}
        </div>
        {collectiveTargets && collectiveTargets.length > 0 && (
          <GVDSTableCtrlContainer>
            <GVDSTableCtrlMultiSelect
              options={Object.values(TARGET_STATUS)}
              prefix="Status"
              defaultSelected={filterKeys.statuses}
              onChange={(filterKs) =>
                setFilterKeys({ ...filterKeys, statuses: filterKs })
              }
            />
            <GVDSTableCtrlMultiSelect
              options={[TARGET_TOPIC.ENVIRONMENTAL, TARGET_TOPIC.SOCIAL].map(
                (t) => TARGET_TOPIC_DISPLAY_LABEL[t]
              )}
              prefix="Topic"
              defaultSelected={filterKeys.topics}
              onChange={(filterKs) =>
                setFilterKeys({
                  ...filterKeys,
                  topics: filterKs,
                })
              }
            />
          </GVDSTableCtrlContainer>
        )}
        {displayedCollectiveTargets.length === 0 ? (
          getNoContentPage(
            displayedCollectiveTargets.length !== collectiveTargets.length
          )
        ) : (
          <div className="view-all-targets-container">
            {displayedCollectiveTargets.map((ct) => (
              <CollectiveTargetCard key={ct.id} collectiveTarget={ct} />
            ))}
          </div>
        )}
      </div>
      <InputCollectiveTargetModal
        show={showInputModal}
        setShow={setShowInputModal}
        onInputSuccess={onInputSuccess}
      />
    </>
  );
};

export default ViewPortfolioTargets;
