import { CellValue, RemoveActionCell } from "../../Data/BulkDataInputCellViewer";
import _ from "lodash";
import flatMap from "lodash/flatMap";

export const columns = {
  0: {
    key: "action",
    label: "",
    width: 2,
    classType: RemoveActionCell,
    errorKey: "",
    readOnly: true
  },
  1: {
    key: "index",
    label: "Row",
    width: 2.5,
    classType: CellValue,
    errorKey: "index",
    readOnly: true
  },
  2: {
    key: "site_name",
    label: "Site Name*",
    width: 10,
    classType: CellValue,
    errorKey: "site_name"
  },
  3: {
    key: "street_address",
    label: "Street Address*",
    width: 10,
    classType: CellValue,
    errorKey: "street_address"
  },
  4: {
    key: "city",
    label: "City*",
    width: 10,
    classType: CellValue,
    errorKey: "city"
  },
  5: {
    key: "state_or_province",
    label: "State / Province",
    width: 10,
    classType: CellValue,
    errorKey: "state_or_province"
  },
  6: {
    key: "zip_code",
    label: "Postal Code*",
    width: 10,
    classType: CellValue,
    errorKey: "zip_code"
  },
  7: {
    key: "country",
    label: "Country*",
    width: 10,
    classType: CellValue,
    errorKey: "country"
  }
};

export const SITE_NAME_KEY = columns["2"].key;

export class SiteValue {
  static new(row, removeRow) {
    const newSite = new SiteValue();

    Object.values(columns).forEach((c) => {
      newSite[c.key] = new c.classType();
    });

    newSite.action = RemoveActionCell.fromRemoveRow(() => removeRow(row), "action-cell");
    newSite.index = new CellValue(row + 1, true, [], [], false);
    return newSite;
  }

  static fromRowData(rowData) {
    const newSite = new SiteValue();
    Object.values(columns).forEach((c, col) => {
      newSite[c.key] = c.classType.fromCellData(rowData[col]);
    });
    return newSite;
  }

  static getNameByCol(col) {
    return columns[col]?.key;
  }

  toGridRow() {
    return Object.values(columns).map((c) => this[c.key]);
  }

  isEmpty() {
    return Object.values(columns)
      .filter((c) => !c.readOnly)
      .reduce((acc, cur) => acc && this[cur.key].isEmpty(), true);
  }

  removeAllErrors() {
    Object.values(columns).forEach((c) => {
      this[c.key].dataErrors = [];
      this[c.key].possibleErrors = [];
    });
  }
}

export default class LatLongFinderBulkInputService {
  constructor() {
    this.dataErrorRows = null;
    this.possibleErrorRows = null;
  }

  getTableColumnLabels() {
    return Object.values(columns).map((c) => ({ label: c.label, width: c.width }));
  }

  getNewRow(row, removeRow) {
    const newRow = SiteValue.new(Number(row), removeRow);
    return newRow.toGridRow();
  }

  updateRow(rowData, changes) {
    const siteRow = SiteValue.fromRowData(rowData);

    changes.forEach((c) => {
      const colName = SiteValue.getNameByCol(c.col);
      if (colName) {
        siteRow[colName].updateValue(c.value);
      }
    });
    return siteRow.toGridRow();
  }

  removeRow(rowNumber, setRowToDelete, grid) {
    let changedGrid = grid.map((row) => [...row]);
    changedGrid.splice(rowNumber, 1);
    changedGrid = changedGrid.map((g, row) => {
      const site = SiteValue.fromRowData(g);
      site.index.updateValue(row + 1);
      site.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row), "action-cell");
      return site.toGridRow();
    });
    return changedGrid;
  }

  getFilledRows(grid) {
    return grid.reduce((count, row) => {
      const site = SiteValue.fromRowData(row);
      for (let columnIndex = 2; columnIndex < Object.keys(columns).length; columnIndex++) {
        if (!site[columns[columnIndex].key].isEmpty()) {
          count += 1;
          break;
        }
      }
      return count;
    }, 0);
  }

  removeEmptyRows(grid, setRowToDelete) {
    return grid
      .map((rowData) => SiteValue.fromRowData(rowData))
      .filter((r) => !r.isEmpty())
      .map((r, row) => {
        r.index.updateValue(row + 1);
        r.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row), "action-cell");
        return r.toGridRow();
      });
  };

  getSite(rowData) {
    const site = SiteValue.fromRowData(rowData);

    return {
      row: site.index.value - 1,
      name: site.site_name?.value,
      street_address: site.street_address?.value,
      city: site.city?.value,
      state_or_province: site.state_or_province?.value,
      zip_code: site.zip_code?.value,
      country: site.country?.value
    };
  }

  validateSites(sites) {
    const rowErrors = {};
    const errorRowNumbers = [];
    const rowPossibleErrors = {};
    const possibleErrorRowNumbers = [];
    const possibleErrorWarning = "An empty field will result in a less precise coordinate";

    sites.forEach((site, index) => {
      rowErrors[index] = {};
      rowPossibleErrors[index] = {};

      if (site.name === undefined || site.name === "") {
        rowErrors[index]["site_name"] = ["This field cannot be empty."];
        errorRowNumbers.push(index);
      }
      if (site.street_address === undefined || site.street_address === "") {
        rowPossibleErrors[index]["street_address"] = [possibleErrorWarning];
      }
      if (site.city === undefined || site.city === "") {
        rowPossibleErrors[index]["city"] = [possibleErrorWarning];
      }
      if (site.state_or_province === undefined || site.state_or_province === "") {
        rowPossibleErrors[index]["state_or_province"] = [possibleErrorWarning];
      }
      if (site.zip_code === undefined || site.zip_code === "") {
        rowPossibleErrors[index]["zip_code"] = [possibleErrorWarning];
      }
      if (site.country === undefined || site.country === "") {
        rowPossibleErrors[index]["country"] = [possibleErrorWarning];
      }
      if (Object.keys(rowPossibleErrors[index]).length > 0) {
        possibleErrorRowNumbers.push(index);
      }

      if (Object.keys(rowErrors[index]).length === 0) {
        delete rowErrors[index];
      }
      if (Object.keys(rowPossibleErrors[index]).length === 0) {
        delete rowPossibleErrors[index];
      }
    });

    return {
      dataErrors: {
        rowErrors: rowErrors,
        errorRowNumbers: errorRowNumbers
      },
      dataPossibleErrors: {
        rowPossibleErrors: rowPossibleErrors,
        possibleErrorRowNumbers: possibleErrorRowNumbers
      }
    };
  }

  updateGridWithErrors = (grid, dataErrors, possibleErrors) => {
    const changedGrid = grid.map((rowData) => {
      const site = SiteValue.fromRowData(rowData);
      site.removeAllErrors();
      return site.toGridRow();
    });

    if (Object.keys(dataErrors).length > 0) {
      for (const [row, err] of Object.entries(dataErrors)) {
        const reading = SiteValue.fromRowData(changedGrid[row]);
        Object.values(columns).forEach(({ key, errorKey }) => {
          reading[key].dataErrors = _.chain(err).get(errorKey, []).value();
        });
        changedGrid[row] = reading.toGridRow();
      }
    } else if (Object.keys(possibleErrors).length > 0) {
      for (const [row, err] of Object.entries(possibleErrors)) {
        const reading = SiteValue.fromRowData(changedGrid[row]);
        Object.values(columns).forEach(({ key, errorKey }) => {
          reading[key].possibleErrors = _.chain(err).get(errorKey, []).value();
        });
        changedGrid[row] = reading.toGridRow();
      }
    }

    return changedGrid;
  };

  prepareDataErrors = (rowErrors) => {
    let errors = {};
    this.dataErrorRows = [];

    for (const [row, err] of Object.entries(rowErrors)) {
      errors[row] = { ...err };
      errors[row]["index"] = flatMap(Object.values(errors[row]));
      this.dataErrorRows.push(row);
    }

    return errors;
  };

  prepareDataPossibleErrors = (rowPossibleErrors) => {
    let possibleErrors = {};
    this.possibleErrorRows = [];

    for (const [row, possibleError] of Object.entries(rowPossibleErrors)) {
      possibleErrors[row] = { ...possibleError };
      possibleErrors[row]["index"] = flatMap(Object.values(possibleErrors[row]));
      this.possibleErrorRows.push(row);
    }

    return possibleErrors;
  };

  hasDataErrors() {
    return this.dataErrorRows && this.dataErrorRows.length > 0;
  }

  hasNoDataErrors() {
    return this.dataErrorRows && this.dataErrorRows.length === 0;
  }

  hasPossibleErrors() {
    return this.possibleErrorRows && this.possibleErrorRows.length > 0;
  }

  hasNoPossibleErrors() {
    return this.possibleErrorRows && this.possibleErrorRows.length === 0;
  }

  hasNoErrors() {
    return this.hasNoDataErrors() && this.hasNoPossibleErrors();
  }

  disableDownload() {
    return !this.hasNoDataErrors();
  }

  resetErrors() {
    this.dataErrorRows = null;
    this.possibleErrorRows = null;
  }
}