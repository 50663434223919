export class MarketPurchaseOffsetRecordModel {
  constructor({
    id,
    periodFrom,
    periodTo,
    amount,
    unitName,
    comments,
    trackerId,
    trackerName,
    trackerTypeId,
    trackerType,
    facilityId,
    facilityName,
    isBundled,
  }) {
    this.id = id;
    this.periodFrom = periodFrom;
    this.periodTo = periodTo;
    this.amount = amount;
    this.unitName = unitName;
    this.comments = comments;
    this.trackerId = trackerId;
    this.trackerName = trackerName;
    this.trackerTypeId = trackerTypeId;
    this.trackerType = trackerType;
    this.facilityId = facilityId;
    this.facilityName = facilityName;
    this.isBundled = isBundled;
  }

  static fromJson(recordJson) {
    return new MarketPurchaseOffsetRecordModel({
      id: recordJson["id"],
      periodFrom: recordJson["period_from"],
      periodTo: recordJson["period_to"],
      amount: recordJson["amount"],
      unitName: recordJson["unit"]["name"],
      comments: recordJson["comments"],
      trackerId: recordJson["tracker"]["id"],
      trackerName: recordJson["tracker"]["tracker_id"],
      trackerTypeId: recordJson["tracker"]["offset_type"]["id"],
      trackerType: recordJson["tracker"]["offset_type"]["name"],
      facilityId: recordJson["tracker"]["facility"]["id"],
      facilityName: recordJson["tracker"]["facility"]["name"],
      isBundled: recordJson["is_bundled"],
    });
  }
}
