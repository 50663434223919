import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import { IconArrowNarrowDown, IconArrowNarrowUp } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { SORTING_TYPES } from "../../../gvds-components/Table/GVDSTable";
import domtoimage from "dom-to-image";
import { GVDSColors } from "../../../styles/gvds-colors";
import {
  DateTimeUtils,
  HtmlService,
  NumberService,
} from "../../../services/UtilsService";
import { WidgetTypeEnum } from "../Models/WidgetModels";
import { scopeClassificationValues } from "../Models/DashboardDataConstant";
import { divideDataWithDivisor } from "../Models/DashboardCalculator";
import {
  maximumChartLegendItemCount,
  OTHERS_LEGEND_LABEL,
} from "../Models/DashboardModels";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { TOOLTIP_PLACEMENTS } from "../../../config/style-config";
import Popover from "react-bootstrap/Popover";

const tabContentClassName = "tab-content";
const imagePaddingInPx = 20;
const yAxisTickHeight = 24;

export const smallValueRepresentation = 0.01;
export const smallValuePercentageRepresentation = 1;

export const DownloadableFileName = {
  dashboard: "Dashboard",
  classificationDonut: "Classification Donut",
  summarySiteRanking: "Summary Site Ranking",
  performanceComparison: "Performance Comparison",
  changeOverTimeSiteRanking: "Change Overtime Site Ranking",
  performanceHistory: "Performance History",
};

export const DashboardDownloadFileName = {
  dashboard: DownloadableFileName.dashboard,
  [WidgetTypeEnum.ClassificationDonutChart]:
    DownloadableFileName.classificationDonut,
  [WidgetTypeEnum.SummarySiteRankingChart]:
    DownloadableFileName.summarySiteRanking,
  [WidgetTypeEnum.PerformanceComparisonChart]:
    DownloadableFileName.performanceComparison,
  [WidgetTypeEnum.ChangeOverTimeSiteRankingChart]:
    DownloadableFileName.changeOverTimeSiteRanking,
  [WidgetTypeEnum.PerformanceTimelineChart]:
    DownloadableFileName.performanceHistory,
};

export const ChangeVarianceColorClass = {
  GREEN: "gvds-color--success",
  RED: "gvds-color--error",
  GRAY: "gvds-color--gray6",
};

export const getChangeColorClass = (
  isShowChangePercentage,
  isNegativeChangeGood,
  isIncreasing
) => {
  if (!isShowChangePercentage) {
    return ChangeVarianceColorClass.GRAY;
  } else {
    if (isNegativeChangeGood) {
      return isIncreasing
        ? ChangeVarianceColorClass.RED
        : ChangeVarianceColorClass.GREEN;
    } else {
      return isIncreasing
        ? ChangeVarianceColorClass.GREEN
        : ChangeVarianceColorClass.RED;
    }
  }
};

export const getChangeIcon = (isShowChangePercentage, isIncreasing) => {
  if (!isShowChangePercentage) {
    return null;
  }

  return isIncreasing ? (
    <GVDSIconSlim Icon={IconArrowNarrowUp} />
  ) : (
    <GVDSIconSlim Icon={IconArrowNarrowDown} />
  );
};

export const siteRankingSortOptions = [
  {
    value: SORTING_TYPES.desc,
    label: "From high to low",
  },
  {
    value: SORTING_TYPES.asc,
    label: "From low to high",
  },
];

export const YAxisTick = ({ width, x, y, value }) => {
  const ref = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsTruncated(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }, []);

  const xPosition = width * -1;

  return (
    <foreignObject
      x={xPosition}
      y={y - 12}
      width={width + x}
      height={yAxisTickHeight}
    >
      <div className="dashboard__y-axis-tick">
        {isTruncated ? (
          <OverlayTrigger
            placement={TOOLTIP_PLACEMENTS.TOP}
            overlay={
              <Popover>
                <Popover.Body>{value}</Popover.Body>
              </Popover>
            }
          >
            <div ref={ref} className="y-axis-tick-value">
              {value}
            </div>
          </OverlayTrigger>
        ) : (
          <div ref={ref} className="y-axis-tick-value">
            {value}
          </div>
        )}
      </div>
    </foreignObject>
  );
};

export class DashboardWidgetSharedUtils {
  static getChartPercentDisplay(percentage, isHumanize = false) {
    if (percentage === null || percentage === undefined) {
      return null;
    } else if (
      smallValuePercentageRepresentation * -1 < percentage &&
      percentage < 0
    ) {
      return `>${smallValuePercentageRepresentation * -1}%`;
    } else if (
      0 < percentage &&
      percentage < smallValuePercentageRepresentation
    ) {
      return `<${smallValuePercentageRepresentation}%`;
    } else if (isHumanize) {
      return `${NumberService.humanize(percentage)}%`;
    } else {
      return `${Math.round(percentage)}%`;
    }
  }

  static getChartValueDisplay(
    value,
    isHumanize = false,
    minimumFractionDigits = 0
  ) {
    if (value === null || value === undefined) {
      return null;
    } else if (smallValueRepresentation * -1 < value && value < 0) {
      return `>${smallValueRepresentation * -1}`;
    } else if (0 < value && value < smallValueRepresentation) {
      return `<${smallValueRepresentation}`;
    } else if (isHumanize) {
      return NumberService.humanize(value);
    } else {
      return NumberService.format(value, 2, minimumFractionDigits);
    }
  }

  static calculatePercentage(value, totalValue) {
    const percentage = divideDataWithDivisor(100 * value, totalValue);
    return DashboardWidgetSharedUtils.getChartPercentDisplay(percentage);
  }

  static getDownloadFileName(resourceName, chartType, reportTitle, period) {
    const nameDetails = [
      resourceName,
      DashboardDownloadFileName[chartType],
      reportTitle,
      period,
      DateTimeUtils.getTimestamp(),
    ];
    return nameDetails.join("_");
  }

  static getExpandedViewContentWrapper(node) {
    const modalContent = node.firstChild;

    const wrapper = document.createElement("div");
    wrapper.style.display = "inline-block";
    wrapper.style.position = "absolute";
    wrapper.style.top = "-9999px";
    wrapper.appendChild(modalContent.cloneNode(true));
    document.body.appendChild(wrapper);

    return wrapper;
  }

  static getDomToImageOptions(node, isFromExpandedView, excludeOnDownloadIds) {
    if (isFromExpandedView) {
      return {
        bgcolor: GVDSColors.white,
        width: node.scrollWidth + imagePaddingInPx * 2,
        height: node.scrollHeight + imagePaddingInPx * 2,
        style: { padding: `${imagePaddingInPx}px` },
        filter: (node) => !excludeOnDownloadIds.includes(node.id),
      };
    } else {
      return {
        bgcolor: GVDSColors.white,
        filter: (node) => !excludeOnDownloadIds.includes(node.id),
      };
    }
  }

  static async downloadChartAsImage(
    chartViewId,
    filename,
    excludeOnDownloadIds,
    isFromExpandedView = false
  ) {
    let element = document.getElementById(chartViewId);
    let expandedViewElementWrapper;

    if (isFromExpandedView) {
      expandedViewElementWrapper =
        DashboardWidgetSharedUtils.getExpandedViewContentWrapper(element);
      element = expandedViewElementWrapper.firstChild;
    }

    const dataUrl = await domtoimage.toPng(
      element,
      DashboardWidgetSharedUtils.getDomToImageOptions(
        element,
        isFromExpandedView,
        excludeOnDownloadIds
      )
    );
    HtmlService.downloadFileFromUrl(dataUrl, filename);

    if (isFromExpandedView) {
      document.body.removeChild(expandedViewElementWrapper);
    }
  }

  static getActiveTabContent(node) {
    return Array.from(node.children).find(
      (child) => child.className === tabContentClassName
    );
  }

  static async downloadDashboardAsImage(
    dashboardViewId,
    filename,
    isUseTab = false
  ) {
    const element = isUseTab
      ? DashboardWidgetSharedUtils.getActiveTabContent(
          document.getElementById(dashboardViewId)
        )
      : document.getElementById(dashboardViewId);

    const dataUrl = await domtoimage.toPng(element, {
      bgcolor: GVDSColors.white,
      width: element.scrollWidth + imagePaddingInPx * 2,
      height: element.scrollHeight + imagePaddingInPx * 2,
      style: {
        padding: `${imagePaddingInPx}px`,
      },
    });
    HtmlService.downloadFileFromUrl(dataUrl, filename);
  }

  static classificationValueByBiggestValueSortFn(
    classificationValue1,
    value1,
    classificationValue2,
    value2
  ) {
    if (
      scopeClassificationValues.includes(classificationValue1) &&
      scopeClassificationValues.includes(classificationValue2)
    ) {
      return classificationValue1.localeCompare(classificationValue2);
    }

    const hasValue1 = value1 !== undefined && value1 !== null;
    const hasValue2 = value2 !== undefined && value2 !== null;

    if (hasValue1 && hasValue2) {
      return value2 - value1;
    } else if (hasValue1) {
      return -1;
    } else if (hasValue2) {
      return 1;
    } else {
      return classificationValue1.localeCompare(classificationValue2);
    }
  }

  static getMaximumDisplayedLegendItems(keys) {
    return [
      ...keys.slice(0, maximumChartLegendItemCount - 1),
      OTHERS_LEGEND_LABEL,
    ];
  }
}
