import React, { useEffect, useState } from "react";
import moment from "moment";
import GVDSFormSingleDatePicker from "../../gvds-components/Forms/GVDSFormSingleDatePicker";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";

const TAB_KEY = 9;
const ENTER_KEY = 13;

export const BulkDataInputDropdownSelector = ({
  data,
  value,
  onCommit,
  onRevert,
}) => {
  const [e, setE] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(
      data.map((d) => ({
        label: d.name,
        value: d.name,
      }))
    );
  }, []);

  const handleChange = (opt) => {
    if (!opt) {
      return onRevert();
    }
    onCommit(opt.value, e);
  };

  const handleKeyDown = (e) => {
    if (e.which === ENTER_KEY || e.which === TAB_KEY) {
      e.persist();
      setE(e);
    } else {
      setE(null);
    }
  };

  return (
    <GVDSFormSingleSelect
      menuIsOpen
      closeMenuOnSelect
      value={value ? { label: value, value: value } : null}
      onSelect={handleChange}
      onInputKeyDown={handleKeyDown}
      options={options}
    />
  );
};

export const BulkDataInputDateSelector = ({ value, onCommit, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateInput, setDateInput] = useState("");

  useEffect(() => {
    const momentDate = moment(value);
    if (momentDate.isValid()) {
      setSelectedDate(momentDate.toDate());
    }
  }, [value]);

  const onDateSelect = (date) => {
    if (date) {
      onChange(date.toISOString());
      setSelectedDate(date);
    }
  };

  const onDataInputKeyChange = (e) => {
    if (e.which === ENTER_KEY || e.which === TAB_KEY) {
      onCommit(dateInput);
    }
  };

  const onDateInputChange = (e) => {
    if (e.target.value) {
      setDateInput(e.target.value);
      onChange(e.target.value);
    }
  };

  return (
    <GVDSFormSingleDatePicker
      autoFocus
      dateFormat="dd MMM yyyy"
      openToDate={selectedDate}
      selected={selectedDate}
      maxDate={moment().toDate()}
      onChange={onDateSelect}
      onChangeRaw={onDateInputChange}
      onKeyDown={onDataInputKeyChange}
      startOpen={true}
      isPortalling={false}
    />
  );
};
