import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { buttonVariant } from "./GVDSButton";
import { GVDSColors } from "../../styles/gvds-colors";
import { ScaleLoader } from "react-spinners";

export const ButtonLoadingSpinner = ({
  color = GVDSColors.white,
  width = 4,
  height = 17,
}) => {
  return (
    <div className={"gvds-button__loading-spinner"}>
      <ScaleLoader color={color} loading={true} width={width} height={height} />
    </div>
  );
};

const loadingColorByVariant = {
  [buttonVariant.primary]: GVDSColors.white,
  [buttonVariant.secondary]: GVDSColors.gray10,
  [buttonVariant.destructive_primary]: GVDSColors.white
};

const GVDSButtonWithLoadingAction = ({
                                       variant = buttonVariant.primary,
                                       text = "",
                                       disabled,
                                       icon,
                                       onClickAsyncFunc,
                                       className
                                     }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (event) => {
    setIsLoading(true);

    try {
      await onClickAsyncFunc(event);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      className={`gvds-button ${isLoading? "gvds-button-is-loading": ""} ${variant} ${icon ? "with-leading-icon" : ""} ${className ?? ""}`}
      disabled={disabled || isLoading}
      onClick={handleClick}
    >
      {isLoading ? (
        <div>
          <ButtonLoadingSpinner color={loadingColorByVariant[variant]} />
        </div>
      ) : (
        <>
          {icon && <span className="gvds-button__icon">{icon}</span>}
          {text}
        </>
      )}
    < /Button>
  );
};

export default GVDSButtonWithLoadingAction;
