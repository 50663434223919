import React, { useContext, useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { PERMISSIONS, RESOURCES } from "../config/constants";
import UserService from "../services/UserService";
import UserInventoryContext from "./UserInventoryContext";
import axios from "axios";
import UserProfileContext from "./UserProfileContext";
import { isPrereqReady } from "../components/HOC/withPrereqReady";
import {usePrevious} from "../components/common/ReactHook";

const PermissionsContext = React.createContext(null);

const CREATE_PATH_PARAM = "create";

// TODO evaluate if this NotNull is still necessary - null is used to indicate loading / reset
const usePreviousNotNull = (value) => {
  const ref = useRef();
  useEffect(() => {
    if (value !== null) {
      ref.current = value;
    }
  }, [value]);
  return ref.current;
};

export const PermissionsProvider = (props) => {
  let location = useLocation();
  const userProfileContext = useContext(UserProfileContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const pathname = location.pathname;
  const selectedContractId = userInventory.selectedContractId.get;
  const previousContract = usePreviousNotNull(selectedContractId);
  const prevPathname = usePreviousNotNull(pathname);
  const prevSelectedInventory = usePrevious(selectedInventory);
  const loadingPermissionsTimer = useRef(null);
  const loadingPermissionsPromise = useRef(null);

  const [hasPermissionBeenInitialised, setHasPermissionBeenInitialised] =
    useState(false);
  const [permissions, setPermissions] = useState({});
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);

  const store = {
    isLoadingPermissions: isLoadingPermissions,
    permissions: permissions,
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    const newInventoryNotNull = selectedInventory !== null;

    if (isPrereqReady(userProfileContext) && pathname && newInventoryNotNull) {
      const hasInventoryChanged =
        selectedInventory?.type !== prevSelectedInventory?.type ||
        selectedInventory?.id !== prevSelectedInventory?.id;

      const isContractChanged = previousContract !== selectedContractId;

      const isInventoryChangedBetweenPortfoliosWithinSameContract =
        previousContract === selectedContractId &&
        selectedInventory?.type === RESOURCES.PORTFOLIO &&
        prevSelectedInventory?.type === RESOURCES.PORTFOLIO;

      const shouldReloadPermissionForInventory = isContractChanged
        ? true
        : isInventoryChangedBetweenPortfoliosWithinSameContract
        ? false
        : hasInventoryChanged;

      if (!hasPermissionBeenInitialised || shouldReloadPermissionForInventory) {
        setIsLoadingPermissions(true);
        const loadPermissions = async () => {
          if (
            !userProfileContext.isLoadingUserProfile &&
            userProfileContext.getUserProfile() === null
          ) {
            setPermissions({});
            setIsLoadingPermissions(false);
            return;
          }

          let permissionsToCheck = Object.values(PERMISSIONS); // TODO update the backend instead to return user permissions
          let params = {};

          if (
            "contractId" in params &&
            params["contractId"] === CREATE_PATH_PARAM
          ) {
            delete params["contractId"];
          }
          if ("siteId" in params && params["siteId"] === CREATE_PATH_PARAM) {
            delete params["siteId"];
          }

          if (
            selectedInventory &&
            !("contractId" in params) &&
            !("portfolioId" in params) &&
            !("siteId" in params) &&
            !("facilityId" in params)
          ) {
            if (selectedInventory.type === RESOURCES.PORTFOLIO) {
              params["portfolioId"] = selectedInventory.id;
            } else if (selectedInventory.type === RESOURCES.SITE) {
              params["siteId"] = selectedInventory.id;
            } else if (selectedInventory.type === RESOURCES.FACILITY) {
              params["facilityId"] = selectedInventory.id;
            }
          }

          UserService.checkPermissions(
            permissionsToCheck,
            params,
            cancelTokenSource
          )
            .then((permissionsMap) => {
              setPermissions(permissionsMap);
              setHasPermissionBeenInitialised(true);
            })
            .finally(() => setIsLoadingPermissions(false));
        };

        clearTimeout(loadingPermissionsTimer.current);

        loadingPermissionsTimer.current = setTimeout(async () => {
          const promise = loadPermissions();
          loadingPermissionsPromise.current = promise;

          promise
            .then(() => {
              loadingPermissionsPromise.current = null;
            })
            .catch(() => {
              loadingPermissionsPromise.current = null;
            });
        }, 100);
      }
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [pathname, selectedInventory, userProfileContext, selectedContractId]);

  return (
    <PermissionsContext.Provider value={store}>
      {props.children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsContext;
