import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import TargetService from "../../services/TargetService";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import moment from "moment";
import { NumberService } from "../../services/UtilsService";
import SafeDataService from "../../services/SafeDataService";
import { DATA_STATUS, HIDE_TARGET_EMISSION_TEXT } from "../../config/constants";
import MissingValueWarningIcon from "../common/MissingValueWarningIcon";
import {
  TARGET_VALIDATION_ERROR_CODE,
  TargetDetailsModel,
} from "./TargetDetailsModel";
import isEmpty from "lodash/isEmpty";
import InfoTooltip from "../common/Tooltip/InfoTooltip";
import _ from "lodash";

const TargetPreview = ({ newTarget, targetErrors, setTargetErrors }) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const currentDate = new moment().subtract(1, "month").endOf("month").toDate();
  const [target, setTarget] = useState({});
  const [targetDetails, setTargetDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const loadTargetDetails = (targetForPreview, showCalculatedValue) => {
    if (selectedInventory?.id && showCalculatedValue) {
      setTargetDetails({});
      setIsLoadingDetails(true);
      TargetService.getPreviewDetails(
        targetForPreview,
        currentDate,
        selectedInventory.type,
        selectedInventory.id
      )
        .then((data) => {
          setIsLoadingDetails(false);
          setTargetDetails(data);
        })
        .catch((error) => {
          setIsLoadingDetails(false);
          if (error.status === 400) {
            setTargetDetails(new TargetDetailsModel(error.data.data));
            setTargetErrors({ target_invalid: error.data.error });
          } else {
            toastContext.addFailToast(
              <span>
                Failed to load target preview details. Please try again.
              </span>
            );
          }
        });
    }
  };

  useEffect(() => {
    if (selectedInventory?.id) {
      setIsLoading(true);
      TargetService.getPreview(
        selectedInventory.type,
        selectedInventory.id,
        newTarget
      )
        .then((data) => {
          setIsLoading(false);
          setTarget(data);
          setTargetErrors({});
          loadTargetDetails(newTarget, data.showCalculatedValue);
        })
        .catch((error) => {
          setIsLoading(false);
          setTarget({});
          if (error.status === 400) {
            setTargetErrors(JSON.parse(error.data.message));
          } else {
            toastContext.addFailToast(
              <span>Failed to load target preview. Please try again.</span>
            );
          }
        });
    }
  }, [JSON.stringify(newTarget), selectedInventory]);

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else if (!isEmpty(targetErrors["business_errors"])) {
    const msg =
      targetErrors["business_errors"]["target_value"] ===
      TARGET_VALIDATION_ERROR_CODE.REDUCE_BY_MORE_THAN_100_PERCENT
        ? "The value of your target cannot be more than 100 if you selected a “reduce” for your target direction and “%” for your unit."
        : "";
    content = <div className="target-preview__name color-red">{msg}</div>;
  } else if (targetErrors["data_incomplete"]) {
    content = (
      <div className="target-preview-container__no-preview">
        This section will be populated once all fields have been filled in.
      </div>
    );
  } else {
    content = (
      <>
        <div className="target-preview__name">{target.name}</div>
        <div className="target-preview-table-container">
          <Table className="target-preview-table">
            <thead>
              <tr>
                <th>Calculation</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Time remaining{" "}
                  <InfoTooltip info="The amount of time between next month or the target’s start date (whichever comes later) and the target’s end date." />
                </td>
                <td>{target.timeRemaining}</td>
              </tr>
              {target.comparisonYear && (
                <tr>
                  <td>Comparable Value ({target.comparisonYear})</td>
                  <td className="d-flex justify-content-end align-items-center">
                    {targetDetails.comparisonValue?.status ===
                      DATA_STATUS.MISSING && <MissingValueWarningIcon />}
                    {targetDetails.comparisonValue && (
                      <span className="ms-2">
                        {NumberService.format(
                          SafeDataService.getValue(
                            targetDetails.comparisonValue
                          ),
                          targetDetails.precision,
                          targetDetails.precision
                        )}
                      </span>
                    )}
                    <span className="ms-1">{target.unitId}</span>
                  </td>
                </tr>
              )}
              {target.showCalculatedValue && (
                <tr>
                  <td>Current Value ({target.currentValuePeriodLabel})</td>
                  <td className="d-flex justify-content-end align-items-center">
                    {isLoadingDetails && <LoadingSpinner />}
                    {targetDetails.currentValue?.status ===
                      DATA_STATUS.MISSING && <MissingValueWarningIcon />}
                    {targetDetails.currentValue && (
                      <span className="ms-2">
                        {NumberService.format(
                          SafeDataService.getValue(targetDetails.currentValue),
                          targetDetails.precision,
                          targetDetails.precision
                        )}
                      </span>
                    )}
                    <span className="ms-1">{target.unitId}</span>
                  </td>
                </tr>
              )}
              {target.showCalculatedValue && (
                <tr>
                  <td>Target ({target.targetValuePeriodLabel})</td>
                  <td className="d-flex justify-content-end align-items-center">
                    {targetDetails.targetValue?.status ===
                      DATA_STATUS.MISSING && <MissingValueWarningIcon />}
                    {targetDetails.targetValue && (
                      <span className="ms-2">
                        {NumberService.format(
                          SafeDataService.getValue(targetDetails.targetValue),
                          targetDetails.precision,
                          targetDetails.precision
                        )}
                      </span>
                    )}
                    <span className="ms-1">{target.unitId}</span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  const marketPurchaseOffsetNotes = (
    <div className="caption mt-3">
      {!newTarget || !newTarget.params || _.isEmpty(newTarget.params) ? null
        : (newTarget.params.use_location_based === undefined || newTarget.params.use_location_based === HIDE_TARGET_EMISSION_TEXT) ? null
          : newTarget.params.use_location_based === true ?
            (<>
              Location-based emissions target values are used for the selected
              performance group, calculated using system default emission factors
              (i.e. national grid-average emission factor).
            </>)
            : (<>
                Market-based emissions target values are used for the selected
                performance group, calculated with market purchases and custom
                emission factors considered.
              </>)
      }
    </div>
  );

  return (
    <div className="target-preview-container">
      <div className="target-preview__header-title">Target Preview</div>
      {content}
      {marketPurchaseOffsetNotes}
    </div>
  );
};

export default TargetPreview;
