import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Container } from "react-bootstrap";

import { REFERENCE_DATA } from "../../../config/ROUTES_NAME";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import LoadingSpinner from "../../common/LoadingSpinner";
import PortalModuleLinkService from "../../../services/ReferenceDataServices/PortalModuleLinkService";
import ToastContext from "../../../context/ToastContext";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTable, {SORTING_TYPES} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../config/search-config";

const PortalModuleLinks = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);

  const [portalModuleLinks, setPortalModuleLinks] = useState([]);
  const [filteredPortalModuleLinks, setFilteredPortalModuleLinks] = useState(
    []
  );

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    portalModuleLinks,
    SystemToolboxSearchKeysByPageName.referenceData.portalModuleLink,
    {
      name: SORTING_TYPES.asc,
    }
  );

  const loadRecord = () => {
    setIsLoading(true);
    PortalModuleLinkService.getAllPortalModuleLinks()
      .then((response) => {
        setIsLoading(false);
        setPortalModuleLinks(response);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load portal module links.</span>
        );
      });
  };

  useEffect(() => {
    loadRecord();
  }, []);

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  const columns = [
    {
      header: "Portal Modules",
      dataKey: "name",
      sortable: true,
      renderer: (pml) => <div>{pml.name}</div>,
    },
  ];

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return To Reference Data"
          onClick={goToReferenceData}
        />
        <PageHeader.Title>
          <h1>Portal Module Links</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          This is a list of portal modules that can be referenced when there’s a
          ‘Relevant Portal Modules’ field.
        </PageHeader.Description>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search by Portal Modules"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {portalModuleLinks.length === 0 && (
        <div className="table__no_content">No portal module link available</div>
      )}
    </Container>
  );
};

export default PortalModuleLinks;
