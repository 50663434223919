import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import GVFormGroup from "../../../common/GVFormGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MarketPurchaseOffsetsService from "../../../../services/MarketPurchaseService";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import LoadingSpinner from "../../../common/LoadingSpinner";
import isEmpty from "lodash/isEmpty";
import { DATA_STATUS, RESOURCES } from "../../../../config/constants";
import {
  DateTimeUtils,
  NumberService,
} from "../../../../services/UtilsService";
import Table from "react-bootstrap/Table";
import CustomRangeSlider from "../../../common/CustomRangeSlider";
import MissingValueWarningIcon from "../../../common/MissingValueWarningIcon";
import moment from "moment";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleSelect from "../../../../gvds-components/Forms/GVDSFormSingleSelect";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import GVDSFormStartEndDatePicker from "../../../../gvds-components/Forms/GVDSFormStartEndDatePicker";
import { useTranslation } from "react-i18next";

const isAmountValid = (amount) => {
  return amount !== "" && amount >= 0;
};

const getAmountErrorMessage = (amount) => {
  if (amount === "") {
    return "Please enter a value.";
  } else if (amount < 0) {
    return "Negative value is not allowed.";
  } else {
    return null;
  }
};

const isAllInputsValid = (input, amount) => {
  return (
    input["id_for_tracker"] &&
    input["period_from"] &&
    input["period_to"] &&
    input["is_bundled"] !== null &&
    input["is_bundled"] !== undefined &&
    isAmountValid(amount)
  );
};

const InputMarketPurchaseRecordModal = ({
  show,
  onSave,
  onClose,
  selectedTracker,
  trackers,
  unitId,
  currentRecord,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [newRecord, setNewRecord] = useState({});
  const [meterUsages, setMeterUsages] = useState({});
  const [isInitializingEditValue, setIsInitializingEditValue] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    if (currentRecord) {
      setNewRecord({
        id_for_tracker: selectedTracker.id,
        period_from: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          currentRecord.periodFrom
        ),
        period_to: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          currentRecord.periodTo
        ),
        comments: currentRecord.comments,
        is_bundled: currentRecord.isBundled,
      });
      setAmount(currentRecord.amount);
      setIsInitializingEditValue(true);
    } else {
      setNewRecord({ ...newRecord, id_for_tracker: selectedTracker?.id });
    }
  }, [currentRecord, selectedTracker?.id]);

  useEffect(() => {
    if (isReadyToShowUsage()) {
      setIsLoading(true);
      const usageEndDate = new moment().isBefore(newRecord["period_to"])
        ? new moment().toDate()
        : newRecord["period_to"];

      MarketPurchaseOffsetsService.getTrackerUsage(
        userInventory.selectedInventory.get.type,
        userInventory.selectedInventory.get.id,
        newRecord["id_for_tracker"],
        newRecord["period_from"],
        usageEndDate
      )
        .then((data) => {
          setIsLoading(false);
          setMeterUsages(data);
          if (!isInitializingEditValue) {
            setAmount(NumberService.toPrecision(data["total"]["value"]));
            setSliderValue(data["total"]["value"] === 0 ? 0 : 100);
          } else {
            setIsInitializingEditValue(false);
            setSliderValue(
              isEmpty(data) || data["total"]["value"] === 0
                ? 0
                : (amount / data["total"]["value"]) * 100
            );
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [
    newRecord["id_for_tracker"],
    newRecord["period_from"],
    newRecord["period_to"],
  ]);

  const isReadyToShowUsage = () =>
    newRecord["id_for_tracker"] &&
    newRecord["period_from"] &&
    newRecord["period_to"];

  const isSliderDisabled = () =>
    isEmpty(meterUsages) || meterUsages["total"]["value"] === 0;

  const onSliderChange = (val) => {
    setAmount(
      NumberService.toPrecision((val * meterUsages["total"]["value"]) / 100)
    );
    setSliderValue(val);
  };

  const onAmountChange = (val) => {
    setAmount(val);
    setSliderValue(
      isSliderDisabled() ? 0 : (val / meterUsages["total"]["value"]) * 100
    );
  };

  const saveRecord = async (e) => {
    e.preventDefault();
    setIsValidated(true);
    if (!isAllInputsValid(newRecord, amount)) {
      return;
    }

    setIsSaving(true);
    try {
      if (currentRecord) {
        await MarketPurchaseOffsetsService.editRecord(
          userInventory.selectedInventory.get.type,
          userInventory.selectedInventory.get.id,
          currentRecord.id,
          { ...newRecord, amount, unit_id: unitId }
        );
        toastContext.addSuccessToast(<span>Record edited successfully.</span>);
      } else {
        await MarketPurchaseOffsetsService.createRecord(
          userInventory.selectedInventory.get.type,
          userInventory.selectedInventory.get.id,
          { ...newRecord, amount, unit_id: unitId }
        );
        toastContext.addSuccessToast(<span>Record added successfully.</span>);
      }
      setIsSaving(false);
      onSave();
      onModalClose();
    } catch (error) {
      toastContext.addFailToast(<span>Failed to save record.</span>);
      setIsSaving(false);
    }
  };

  const onModalClose = () => {
    if (!isSaving) {
      setNewRecord({ id_for_tracker: selectedTracker?.id });
      setMeterUsages({});
      setAmount(0);
      setSliderValue(0);
      setIsValidated(false);
      onClose();
    }
  };

  if (!trackers || trackers.length === 0) {
    return (
      <GVDSModal
        title={`${t(
          "data-management.environmental.market-purchase.single-input.modal-title-input"
        )}`}
        size={GVDSModal.Size.small}
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <GVDSModal.Body>
          <div>
            {t(
              "data-management.environmental.market-purchase.single-input.no-tracker-line1"
            )}
          </div>
          <br />
          <div>
            {t(
              "data-management.environmental.market-purchase.single-input.no-tracker-line2"
            )}
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => {
              onClose();
            }}
            text={t("shared-modal.footer.close")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    );
  }

  const getTrackerOptionLabelFormatting = (option) => {
    return option
      ? {
          value: option.id,
          label: `${option.tracker_id} ${
            userInventory.selectedInventory.get &&
            userInventory.selectedInventory.get.type === RESOURCES.SITE
              ? "(" + option["facility"]["name"] + ")"
              : ""
          }`,
        }
      : null;
  };

  const getTrackerOptionByValue = (optionValue) => {
    if (trackers) {
      const option = trackers.find((option) => option.id === optionValue);
      return getTrackerOptionLabelFormatting(option);
    } else {
      return null;
    }
  };

  return (
    <GVDSModal
      title={
        currentRecord
          ? t(
              "data-management.environmental.market-purchase.single-input.modal-title-edit"
            )
          : t(
              "data-management.environmental.market-purchase.single-input.modal-title-input"
            )
      }
      show={show}
      onHide={onModalClose}
    >
      <GVDSModal.Body>
        <GVFormGroup controlId="selectTracker">
          <Form.Label>
            {t("data-management.environmental.market-purchase.tracker-id")}
          </Form.Label>
          <GVDSFormSingleSelect
            className="select__tracker"
            placeholder="Select Tracker"
            value={
              newRecord["id_for_tracker"]
                ? getTrackerOptionByValue(newRecord["id_for_tracker"])
                : null
            }
            options={trackers.map((tracker) => {
              return getTrackerOptionLabelFormatting(tracker);
            })}
            onSelect={(selectedOption) =>
              setNewRecord({
                ...newRecord,
                id_for_tracker: selectedOption.value,
              })
            }
            statusMetadata={
              isValidated && !newRecord["id_for_tracker"]
                ? FormFieldStatusMetadata.getError("Please select a tracker.")
                : FormFieldStatusMetadata.getDefault()
            }
            disabled={!!selectedTracker?.id}
          />
        </GVFormGroup>
        <Row>
          <Col sm={12}>
            <GVFormGroup controlId="selectStartEndDate">
              <GVDSFormStartEndDatePicker
                startDateLabel={
                  <Form.Label>{t("shared-input-label.period-from")}</Form.Label>
                }
                endDateLabel={
                  <Form.Label>{t("shared-input-label.period-to")}</Form.Label>
                }
                className="eac-record"
                startDate={newRecord["period_from"]}
                endDate={newRecord["period_to"]}
                onChange={(startDate, endDate) => {
                  if (startDate !== newRecord["period_from"]) {
                    setNewRecord({ ...newRecord, period_from: startDate });
                  }
                  if (endDate !== newRecord["period_to"]) {
                    setNewRecord({ ...newRecord, period_to: endDate });
                  }
                }}
                dateFormat="dd MMM yyyy"
                statusMetadata={
                  isValidated &&
                  !(newRecord["period_from"] && newRecord["period_to"])
                    ? FormFieldStatusMetadata.getError("Please enter a date.")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <GVFormGroup className="mt-2 mb-4">
          <Form.Label>
            {t(
              "data-management.environmental.market-purchase.single-input.label-bundle-unbundle"
            )}
          </Form.Label>
          <Form.Check
            id="bundled-eac-record"
            label={t(
              "data-management.environmental.market-purchase.single-input.option-bundled"
            )}
            type="radio"
            checked={newRecord.is_bundled === true}
            onChange={() => {
              setNewRecord({ ...newRecord, is_bundled: true });
            }}
          />
          <Form.Check
            id="unbundled-eac-record"
            label={t(
              "data-management.environmental.market-purchase.single-input.option-unbundled"
            )}
            type="radio"
            checked={newRecord.is_bundled === false}
            onChange={() => {
              setNewRecord({ ...newRecord, is_bundled: false });
            }}
          />
          {isValidated &&
            (newRecord["is_bundled"] === null ||
              newRecord["is_bundled"] === undefined) && (
              <GVDSFormErrorMessage errorMsg="Please select bundled/unbundled." />
            )}
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>
            {t(
              "data-management.environmental.market-purchase.single-input.label-applicable-meter"
            )}
          </Form.Label>
          <div className="form-label-description">
            {t(
              "data-management.environmental.market-purchase.single-input.label-description-applicable-meter"
            )}
          </div>
          {!isReadyToShowUsage() && (
            <div className="market-purchase-offset-record-form__no-content">
              {t(
                "data-management.environmental.market-purchase.single-input.empty-state-meter-usage"
              )}
            </div>
          )}
          {isLoading && <LoadingSpinner />}
          {!isEmpty(meterUsages) && (
            <div className="market-purchase-tracker-usages-table-container">
              <Table className="market-purchase-tracker-usages-table">
                <thead>
                  <tr>
                    <th>
                      {DateTimeUtils.formatLocalPeriod(
                        meterUsages["start"],
                        meterUsages["end"]
                      )}
                    </th>
                    <th>VALUE</th>
                  </tr>
                </thead>
                <tbody>
                  {meterUsages["meter_usages"].map((usage, index) => (
                    <tr key={index}>
                      <td className="gv-body-1">
                        <span>
                          {usage["meter"]["environmental_type"]["name"]}
                        </span>
                        <span> - </span>
                        <span>{usage["meter"]["name"]}</span>
                      </td>
                      <td>
                        {usage["usage"]["status"] === DATA_STATUS.MISSING && (
                          <MissingValueWarningIcon />
                        )}
                        <span className="ms-2">
                          {NumberService.format(usage.usage.value)} {unitId}
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>Total Usage</td>
                    <td>
                      {meterUsages["total"]["status"] ===
                        DATA_STATUS.MISSING && <MissingValueWarningIcon />}
                      <span className="ms-2">
                        {NumberService.format(meterUsages["total"]["value"])}{" "}
                        {unitId}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </GVFormGroup>
        <GVFormGroup controlId="setAmount">
          <Form.Label>
            {t(
              "data-management.environmental.market-purchase.single-input.label-amount"
            )}
          </Form.Label>
          <div className="form-label-description">
            {t(
              "data-management.environmental.market-purchase.single-input.label-description-amount"
            )}
          </div>
          <div className="d-flex">
            <div className="d-flex w-50">
              <div className="flex-grow-1">
                <GVDSFormField
                  type="number"
                  placeholder="Enter amount"
                  className="amount_input"
                  value={amount}
                  onInput={(value) => onAmountChange(value)}
                  statusMetadata={
                    isValidated && !isAmountValid(amount)
                      ? FormFieldStatusMetadata.getError()
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </div>
              <span className="p-2 mt-1 body-2">{unitId}</span>
            </div>
            <div className="w-50 d-flex justify-content-center">
              <CustomRangeSlider
                className="w-75"
                disabled={
                  isEmpty(meterUsages) || meterUsages["total"]["value"] === 0
                }
                value={sliderValue}
                onChange={(e) => onSliderChange(e.target.value)}
              />
            </div>
          </div>
          {isValidated && !isAmountValid(amount) && (
            <GVDSFormErrorMessage
              status={FormFieldState.error}
              errorMsg={getAmountErrorMessage(amount)}
            />
          )}
        </GVFormGroup>
        <GVFormGroup controlId="comments">
          <Form.Label>
            {t("shared-input-label.comments")}{" "}
            <span className="optional-form-label">
              ({t("shared-input-label.optional")})
            </span>
          </Form.Label>
          <GVDSFormField
            type="text"
            placeholder="Enter comments"
            className="comments_input"
            value={newRecord.comments}
            onInput={(value) => setNewRecord({ ...newRecord, comments: value })}
          />
        </GVFormGroup>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onModalClose}
          disabled={isSaving}
          text={t("shared-modal.footer.close")}
        />
        <GVDSButton
          variant={buttonVariant.primary}
          text={t("shared-modal.footer.save")}
          onClick={saveRecord}
          disabled={isSaving}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default InputMarketPurchaseRecordModal;
