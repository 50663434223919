import React, { useContext, useState } from "react";
import LoadingSpinner from "../../common/LoadingSpinner";
import SubscriptionSharedUtils, {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  getSubscriptionPriceDisplay,
  SubscriptionPaymentType,
  SubscriptionPricingType,
  SubscriptionStatusEnum,
} from "../../SystemToolbox/Subscription/SubscriptionSharedUtils";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import { CONTACT_EMAIL_SALES, RESOURCES } from "../../../config/constants";
import SubscriptionPaymentBadge, {
  SUBSCRIPTION_PAYMENT,
} from "../../SystemToolbox/Subscription/SubscriptionPaymentBadge";
import {
  DateTimeUtils,
  NumberService,
  StringUtils,
} from "../../../services/UtilsService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import moment from "moment/moment";
import UserInventoryContext from "../../../context/UserInventoryContext";
import UserProfileContext from "../../../context/UserProfileContext";
import { isUserBillingAdminForInventory } from "./BillingSharedUtils";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconMail } from "@tabler/icons-react";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import PaymentInstructionModal from "./PaymentInstructionModal";
import { billingWindowFeatures } from "../../../services/Public/BillingPaymentService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import ExcelExporterService from "../../../services/ExcelExporterService";
import { Trans, useTranslation } from "react-i18next";

const getSiteSubscriptionPaymentStatus = (
  isDecentralised,
  siteSubscription,
  contractSubscriptionPaymentStatus
) => {
  return isDecentralised
    ? siteSubscription.paymentStatus
    : contractSubscriptionPaymentStatus;
};

const getSiteSubscriptionPaymentDue = (siteSubscription) => {
  const invoicePriceBySiteId = siteSubscription.extraInvoiceSitePriceBySiteId;
  const price =
    invoicePriceBySiteId && siteSubscription.site.id in invoicePriceBySiteId
      ? invoicePriceBySiteId[siteSubscription.site.id]
      : siteSubscription.priceAmount;

  return siteSubscription.paymentStatus === SUBSCRIPTION_PAYMENT.FREE ||
    siteSubscription.paymentStatus === SUBSCRIPTION_PAYMENT.PAID
    ? ""
    : getSubscriptionPriceDisplay(
        siteSubscription.currencyId,
        NumberService.format(price)
      );
};

const exportBillingAdminListAsExcel = (contractSubscription) => {
  const dataToExport = {};

  contractSubscription.siteSubscriptions.forEach((siteSub) => {
    const siteName = siteSub.site.name;

    let siteInfo = dataToExport[siteName];
    if (siteInfo) {
      siteInfo["Payment Status"] =
        siteInfo["Payment Status"] +
        ", " +
        getSiteSubscriptionPaymentStatus(
          contractSubscription.isDecentralized,
          siteSub,
          contractSubscription.paymentStatus
        );
      siteInfo["Payment Due"] =
        siteInfo["Payment Due"] + ", " + getSiteSubscriptionPaymentDue(siteSub);
    } else {
      siteInfo = {
        "Site Name": siteName,
        "Payment Status": getSiteSubscriptionPaymentStatus(
          contractSubscription.isDecentralized,
          siteSub,
          contractSubscription.paymentStatus
        ),
        "Payment Due": getSiteSubscriptionPaymentDue(siteSub),
        "Billing Admin": siteSub.billingAdminEmail,
      };
    }
    dataToExport[siteName] = siteInfo;
  });

  ExcelExporterService.exportToExcel(
    Object.values(dataToExport),
    `Site Payment Status_${DateTimeUtils.getTimestamp()}`,
    [30, 20, 20, 25],
    "Site Payment Status"
  );
};

const BillingAdminSubscriptionStatusBadge = ({ subscription }) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const isFromContract =
    selectedInventory.type === RESOURCES.SITE &&
    subscription.siteContractSubscriptionDetails;

  const isFirstSubscriptionUnpaid =
    subscription.paymentStatus === SUBSCRIPTION_PAYMENT.UNPAID &&
    subscription.isFirstSubscription;
  const isUnpaidUpcoming =
    subscription.isUnbilledRenewal ||
    (subscription.status === SubscriptionStatusEnum.UPCOMING &&
      subscription.paymentStatus === SUBSCRIPTION_PAYMENT.UNPAID);

  const contractName = isFromContract
    ? subscription.siteContractSubscriptionDetails.contract.name
    : "";

  return (
    <>
      {subscription.paymentType &&
        subscription.paymentType === SubscriptionPaymentType.DECENTRALIZED && (
          <StatusLabel color={StatusLabel.Colors.gray} className="ms-2">
            {t("admin.billing.subscriptions.decentralised-sites-paying")}
          </StatusLabel>
        )}
      {isFromContract && (
        <StatusLabel color={StatusLabel.Colors.gray} className="ms-2">
          <Trans i18nKey="admin.billing.subscriptions.from-contract-name">
            From {{ contractName }}
          </Trans>
        </StatusLabel>
      )}
      {isFirstSubscriptionUnpaid || isUnpaidUpcoming ? (
        <StatusLabel color={StatusLabel.Colors.yellow} className="ms-2">
          Pending
        </StatusLabel>
      ) : subscription.status === SubscriptionStatusEnum.UPCOMING &&
        subscription.paymentStatus === SUBSCRIPTION_PAYMENT.PAID ? (
        <StatusLabel color={StatusLabel.Colors.blue} className="ms-2">
          Upcoming
        </StatusLabel>
      ) : (
        <SubscriptionPaymentBadge
          payment={subscription.paymentStatus}
          className="ms-2"
        />
      )}
    </>
  );
};

const ViewSitesModal = ({
  subscription,
  showSitePaymentStatus,
  setShowSitePaymentStatus,
}) => {
  const { t } = useTranslation();

  const invoicePriceBySiteId = subscription.extraInvoiceSitePriceBySiteId;

  const isNotYetPaid = ![
    SUBSCRIPTION_PAYMENT.PAID,
    SUBSCRIPTION_PAYMENT.FREE,
  ].includes(subscription.paymentStatus);
  const paymentDueDate = isNotYetPaid
    ? DateTimeUtils.formatLocalDate(
        subscription.hasCustomPaymentDueDay
          ? subscription.paymentDueDate
          : subscription.startPeriod
      )
    : "";

  return (
    <GVDSModal
      show={showSitePaymentStatus}
      onHide={() => setShowSitePaymentStatus(false)}
      title={t("admin.billing.subscriptions.site-payment-status")}
    >
      <GVDSModal.Body>
        <div className="d-flex align-items-center mb-1">
          {isNotYetPaid && (
            <div>
              <Trans i18nKey="admin.billing.subscriptions.payment-due-on">
                Payment due on {{ paymentDueDate }}
              </Trans>
            </div>
          )}
          <GVDSButton
            className="ms-auto"
            variant={buttonVariant.primary}
            text={t("shared.download")}
            onClick={() => exportBillingAdminListAsExcel(subscription)}
          />
        </div>
        <GVDSTableDisplay>
          <thead>
            <tr>
              <th>{t("admin.billing.subscriptions.label-site-name")}</th>
              {subscription.isDecentralized && (
                <th>{t("admin.billing.subscriptions.label-payment-status")}</th>
              )}
              <th>{t("admin.billing.subscriptions.label-payment-due")}</th>
              <th>{t("admin.billing.subscriptions.label-email-action")}</th>
            </tr>
          </thead>
          <tbody>
            {subscription.siteSubscriptions.map((s) => {
              const price =
                invoicePriceBySiteId && s.site.id in invoicePriceBySiteId
                  ? invoicePriceBySiteId[s.site.id]
                  : s.priceAmount;

              return (
                <tr key={s.id}>
                  <td>{s.site.name}</td>
                  {subscription.isDecentralized && (
                    <td>
                      <SubscriptionPaymentBadge payment={s.paymentStatus} />
                    </td>
                  )}
                  <td>
                    {s.paymentStatus === SUBSCRIPTION_PAYMENT.FREE ? (
                      <div className="gvds-color--gray6">-</div>
                    ) : s.paymentStatus === SUBSCRIPTION_PAYMENT.PAID ? (
                      <div className="gvds-color--gray6">
                        {t(
                          "admin.billing.subscriptions.placeholder-no-payment-due"
                        )}
                      </div>
                    ) : (
                      <div>
                        {getSubscriptionPriceDisplay(
                          s.currencyId,
                          NumberService.format(price)
                        )}
                      </div>
                    )}
                  </td>
                  <td>
                    {s.billingAdminEmail ? (
                      <GVDSIconButton
                        variant={iconButtonVariant.tertiary}
                        icon={<GVDSIcon Icon={IconMail} />}
                        tooltipText={t(
                          "admin.billing.subscriptions.button-email-billing-admin"
                        )}
                        placement="top"
                        onClick={() => {
                          window.open(
                            `mailto:${s.billingAdminEmail}`,
                            "_blank"
                          );
                        }}
                      />
                    ) : (
                      <div className="gvds-color--gray6">
                        {t(
                          "admin.billing.subscriptions.placeholder-no-billing-admin"
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </GVDSTableDisplay>
      </GVDSModal.Body>
    </GVDSModal>
  );
};

const BillingSubscriptionActionButtons = ({
  subscription,
  onTriggerChangePlan,
  onTriggerChangeBillingCycle,
  onTriggerCancelSubscription,
  plan,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [showSitePaymentStatus, setShowSitePaymentStatus] = useState(false);
  const [showPaymentInstruction, setShowPaymentInstruction] = useState(false);

  if (!plan) {
    return null;
  }

  let content;

  const isAtPortfolio = selectedInventory.type === RESOURCES.PORTFOLIO;
  const isAtSite = selectedInventory.type === RESOURCES.SITE;
  const isCustomPlan = plan?.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;
  const isCancelled = subscription.status === SubscriptionStatusEnum.CANCELLED;

  const makePaymentButton = (
    <GVDSTextButton
      text={t("admin.billing.subscriptions.button-make-payment")}
      onClick={() => {
        setShowPaymentInstruction(true);
        window.open(subscription.paymentLink, "_blank", billingWindowFeatures);
      }}
    />
  );

  const contactSalesButton = (
    <a
      className="contact-sales-email"
      href={`mailto:${CONTACT_EMAIL_SALES}`}
      target="_blank"
    >
      {t("admin.billing.subscriptions.button-contact-sales")}
    </a>
  );

  const cancelSubscriptionButton = (
    <GVDSTextButton
      text={t("admin.billing.subscriptions.button-cancel-subscription")}
      onClick={() => {
        onTriggerCancelSubscription();
      }}
    />
  );

  if (isAtPortfolio) {
    let subscriptionActions = null;

    if (isCancelled) {
      subscriptionActions = <>{contactSalesButton}</>;
    } else if (
      subscription.pricingType === SubscriptionPricingType.PAID &&
      subscription.paymentStatus === SUBSCRIPTION_PAYMENT.PAID
    ) {
      subscriptionActions = <>{cancelSubscriptionButton}</>;
    } else if (
      !subscription.isDecentralized &&
      subscription.pricingType === SubscriptionPricingType.PAID &&
      (subscription.status === SubscriptionStatusEnum.PENDING ||
        subscription.paymentStatus === SUBSCRIPTION_PAYMENT.PARTIALLY_PAID)
    ) {
      subscriptionActions = (
        <>
          {makePaymentButton}
          {subscription.status === SubscriptionStatusEnum.PENDING &&
            cancelSubscriptionButton}
        </>
      );
    }

    content = (
      <>
        <GVDSTextButton
          text={t(
            "admin.billing.subscriptions.button-view-site-payment-status"
          )}
          onClick={() => {
            setShowSitePaymentStatus(true);
          }}
        />
        {subscriptionActions}
        {subscription.siteSubscriptions && (
          <ViewSitesModal
            subscription={subscription}
            showSitePaymentStatus={showSitePaymentStatus}
            setShowSitePaymentStatus={setShowSitePaymentStatus}
          />
        )}
      </>
    );
  } else if (isAtSite) {
    const isSingleSiteSubscription =
      !subscription.siteContractSubscriptionDetails;

    if (isSingleSiteSubscription) {
      if (isCancelled) {
        if (isCustomPlan) {
          content = contactSalesButton;
        } else {
          content = (
            <GVDSTextButton
              text={t("admin.billing.subscriptions.button-choose-a-plan")}
              onClick={onTriggerChangePlan}
            />
          );
        }
      } else if (subscription.status === SubscriptionStatusEnum.PENDING) {
        content = (
          <>
            {makePaymentButton}
            {cancelSubscriptionButton}
          </>
        );
      } else {
        const isPlanHasMultipleBillingCycle = plan.hasMonthly && plan.hasYearly;

        content = (
          <>
            <GVDSTextButton
              text={
                subscription.pricingType === SubscriptionPricingType.FREE
                  ? t(
                      "admin.billing.subscriptions.button-get-paid-subscription"
                    )
                  : t("admin.billing.subscriptions.button-change-plan")
              }
              onClick={onTriggerChangePlan}
            />
            {subscription.pricingType === SUBSCRIPTION_PAYMENT.PAID && (
              <>
                {cancelSubscriptionButton}
                {isPlanHasMultipleBillingCycle && (
                  <GVDSTextButton
                    text={t(
                      "admin.billing.subscriptions.button-change-billing-cycle"
                    )}
                    onClick={onTriggerChangeBillingCycle}
                  />
                )}
              </>
            )}
          </>
        );
      }
    } else {
      const isDecentralised =
        subscription.siteContractSubscriptionDetails.paymentType ===
        SubscriptionPaymentType.DECENTRALIZED;

      if (isDecentralised) {
        if (subscription.status === SubscriptionStatusEnum.PENDING) {
          content = makePaymentButton;
        }
      } else {
        // from centralised contract - view only, no action
        content = null;
      }
    }
  } else {
    return null;
  }

  return (
    <div className="billing-subscription__action-buttons">
      {content}
      {showPaymentInstruction && (
        <PaymentInstructionModal
          onClose={() => {
            setShowPaymentInstruction(false);
          }}
          paymentLink={subscription.paymentLink}
        />
      )}
    </div>
  );
};

const BillingCycleDisplay = ({
  startPeriod,
  endPeriod,
  billingCycle,
  isBillingAdmin,
}) => {
  const { t } = useTranslation();

  const startDate = DateTimeUtils.formatLocalDate(startPeriod);
  const endDate = DateTimeUtils.formatLocalDate(endPeriod);
  const billingCycleDisplay = (
    <>
      {billingCycle &&
        isBillingAdmin &&
        `(${StringUtils.capitaliseWord(billingCycle)})`}
    </>
  );

  return (
    <div>
      <Trans i18nKey="admin.billing.subscriptions.billing-cycle-display">
        Subscription Period {{ startDate }} - {{ endDate }}{" "}
        {{ billingCycleDisplay }}
      </Trans>
    </div>
  );
};

const BillingSubscriptionDisplay = ({
  subscription,
  packagePlans,
  allFeatures,
  isBillingAdmin,
  isSuccessorBilled,
  onTriggerChangePlan,
  onTriggerChangeBillingCycle,
  onTriggerCancelSubscription,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const isAtSite = selectedInventory.type === RESOURCES.SITE;

  const plan = packagePlans.find(
    (pp) => pp.packagePlanId === subscription.planId
  );

  const isFirstSubscriptionUnpaid =
    subscription.paymentStatus === SUBSCRIPTION_PAYMENT.UNPAID &&
    subscription.isFirstSubscription;

  const enableActions = !subscription.isUnbilledRenewal && !isSuccessorBilled;
  const isSingleSiteSubscription =
    !subscription.siteContractSubscriptionDetails;

  const getSubscriptionPaymentStatusBanner = () => {
    if (
      isSingleSiteSubscription &&
      isFirstSubscriptionUnpaid &&
      !subscription.hasCustomPaymentDueDay
    ) {
      return (
        <GVDSBanner
          title={t(
            "admin.billing.subscriptions.subscription-status.banner-title-single-site-first-sub"
          )}
          variant={GVDSBanner.Variants.warning}
          className="mt-3"
        />
      );
    } else if (subscription.isUnpaidSubscriptionOnDue()) {
      const isSiteWithCentralizedPaymentType =
        isAtSite &&
        subscription.underContractIsFromContractSubscription() &&
        subscription.siteContractSubscriptionDetails?.paymentType ===
          SubscriptionPaymentType.CENTRALIZED;

      if (isSiteWithCentralizedPaymentType) {
        const paymentDueDate = DateTimeUtils.formatLocalDate(
          SubscriptionSharedUtils.getPaymentDueDate(
            subscription.hasCustomPaymentDueDay,
            subscription.paymentDueDate,
            subscription.startPeriod
          )
        );
        return (
          <GVDSBanner
            title={
              <Trans i18nKey="admin.billing.subscriptions.subscription-status.banner-title-centralised-site-payment-due">
                Your payment was due on {{ paymentDueDate }}, to be made by your
                corporate team. No action required from your end.
              </Trans>
            }
            variant={GVDSBanner.Variants.warning}
            className="mt-3"
          >
            {t(
              "admin.billing.subscriptions.subscription-status.banner-message-payment-due"
            )}
          </GVDSBanner>
        );
      } else {
        const paymentDueDate = DateTimeUtils.formatLocalDate(
          SubscriptionSharedUtils.getPaymentDueDate(
            subscription.hasCustomPaymentDueDay,
            subscription.paymentDueDate,
            subscription.startPeriod
          )
        );

        return (
          <GVDSBanner
            title={
              <Trans i18nKey="admin.billing.subscriptions.subscription-status.banner-title-payment-due">
                Your payment was due on {{ paymentDueDate }}, please make
                payment to begin or continue access.
              </Trans>
            }
            variant={GVDSBanner.Variants.warning}
            className="mt-3"
          >
            {t(
              "admin.billing.subscriptions.subscription-status.banner-message-payment-due"
            )}
          </GVDSBanner>
        );
      }
    }
  };

  const endPeriodDisplay = `${DateTimeUtils.formatLocalDate(
    moment(subscription.endPeriod)
  )}`;
  return (
    <section className="subscription-box__container">
      <article>
        <Row>
          <Col lg={8}>
            <div className="billing-subscription__name-display">
              <span className="gvds-text--heading4">{plan?.name}</span>
              {SubscriptionSharedUtils.getShorthandFeatureDisplay(
                allFeatures,
                subscription.features,
                "right",
                false
              )}
              <BillingAdminSubscriptionStatusBadge
                subscription={subscription}
                className="ms-2"
              />
            </div>
            {subscription.paymentStatus === SUBSCRIPTION_PAYMENT.UNPAID ? (
              <div className="payment-due-message">
                {t(
                  "admin.billing.subscriptions.subscription-status.label-payment-due"
                )}
                : {subscription.getPaymentDueMessage()}
              </div>
            ) : subscription.nextPaymentDate ? (
              <div className="next-payment-message">
                {t(
                  "admin.billing.subscriptions.subscription-status.label-next-payment"
                )}
                : {subscription.getNextPaymentMessage()}
              </div>
            ) : null}
            {subscription.status !== SubscriptionStatusEnum.CANCELLED &&
              !isFirstSubscriptionUnpaid && (
                <BillingCycleDisplay
                  isBillingAdmin={isBillingAdmin}
                  startPeriod={subscription.startPeriod}
                  endPeriod={subscription.endPeriod}
                  billingCycle={subscription.billingCycle}
                />
              )}
          </Col>
          <Col lg={4}>
            {isBillingAdmin && enableActions && (
              <BillingSubscriptionActionButtons
                subscription={subscription}
                onTriggerChangePlan={onTriggerChangePlan}
                onTriggerChangeBillingCycle={onTriggerChangeBillingCycle}
                onTriggerCancelSubscription={onTriggerCancelSubscription}
                plan={plan}
              />
            )}
          </Col>
        </Row>
        {subscription.status === SubscriptionStatusEnum.CANCELLED && (
          <GVDSBanner
            title={
              <Trans i18nKey="admin.billing.subscriptions.subscription-status.banner-title-cancelled">
                Your subscription has been cancelled and will expire on{" "}
                {{ endPeriodDisplay }}
              </Trans>
            }
            variant={GVDSBanner.Variants.warning}
            className="mt-3"
          >
            {t(
              "admin.billing.subscriptions.subscription-status.banner-message-payment-due"
            )}
          </GVDSBanner>
        )}
        {getSubscriptionPaymentStatusBanner()}
      </article>
    </section>
  );
};

const ViewSubscriptions = ({
  isLoading,
  subscriptions,
  packagePlans,
  allFeatures,
  onTriggerChangePlan,
  onTriggerChangeBillingCycle,
  onTriggerCancelSubscription,
  selectSubscription,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const selectedTreeNode = userInventory.selectedTreeNode.get;
  const inventoryDetails = selectedTreeNode?.nodeValue?.value;
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const isBillingAdmin = isUserBillingAdminForInventory(user, inventoryDetails);

  let content;

  if (isLoading || packagePlans.length === 0) {
    content = <LoadingSpinner />;
  } else if (subscriptions.length > 0) {
    content = (
      <div>
        {subscriptions.map((s) => {
          let isSuccessorBilled = false;

          if (s.successorSubscriptionId) {
            const successor = subscriptions.find(
              (sub) => sub.id === s.successorSubscriptionId
            );
            isSuccessorBilled = successor && !successor.isUnbilledRenewal;
          }

          return (
            <BillingSubscriptionDisplay
              key={s.id}
              subscription={s}
              packagePlans={packagePlans}
              allFeatures={allFeatures}
              isBillingAdmin={isBillingAdmin}
              isSuccessorBilled={isSuccessorBilled}
              onTriggerChangePlan={() => {
                selectSubscription(s);
                onTriggerChangePlan();
              }}
              onTriggerChangeBillingCycle={() => {
                selectSubscription(s);
                onTriggerChangeBillingCycle();
              }}
              onTriggerCancelSubscription={() => {
                selectSubscription(s);
                onTriggerCancelSubscription();
              }}
            />
          );
        })}
      </div>
    );
  } else {
    let resourceType;
    if (selectedInventory.type === RESOURCES.SITE) {
      resourceType = t("shared.site");
    } else if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      resourceType = t("shared.contract");
    }

    content = (
      <div className="gvds-empty-content mb-2">
        <div>
          <Trans i18nKey="admin.billing.subscriptions.no-active-subscriptions">
            There are no active subscriptions associated with this{" "}
            {{ resourceType }}.
          </Trans>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="gvds-text--heading2 mb-3">
        {t("admin.billing.section-title-subscriptions")}
      </div>
      {content}
    </div>
  );
};

export default ViewSubscriptions;
