import React from "react";

import { SIGNUP_STATUS, SURVEY_STATUS } from "../../../config/constants";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../services/UtilsService";

const statusToColorMap = {
  [SIGNUP_STATUS.PENDING]: StatusLabel.Colors.red,
  [SIGNUP_STATUS.APPROVED]: StatusLabel.Colors.green,
  [SIGNUP_STATUS.REJECTED]: StatusLabel.Colors.gray,
};

const SignUpStatusBadge = ({ status }) => {
  let labelColor = statusToColorMap[status];

  return (
    <StatusLabel color={labelColor}>
      {StringUtils.capitaliseWord(status)}
    </StatusLabel>
  );
};

export default SignUpStatusBadge;
