import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import UserInventoryContext, {
  getSiteId,
} from "../../../../context/UserInventoryContext";
import { UtilsService } from "../../../../services/UtilsService";
import { SUBTOPIC_TYPE } from "../../../../config/constants";
import EnvironmentalSubtopicService from "../../../../services/EnvironmentalSubtopicService";
import EnvironmentalService from "../../../../services/EnvironmentalService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import Accordion from "react-bootstrap/Accordion";

import EnvironmentalCustomEmissionFactorDialog from "./EnvironmentalCustomEmissionFactorDialog";
import { orderBy } from "lodash";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSAccordionHeader from "../../../../gvds-components/Accordion/GVDSAccordionHeader";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import EnvironmentalTypeCustomEmissionFactorsDisplay from "./EnvironmentalTypeCustomEmissionFactorsDisplay";
import { useTranslation } from "react-i18next";

const availableEnvironmentalCustomEmissionFactorSubtopic = [
  SUBTOPIC_TYPE.ENERGY,
];

const customEmissionFactorSortFn = (customEf1, customEf2) => {
  const customEfYear1 = parseInt(customEf1.year);
  const customEfYear2 = parseInt(customEf2.year);

  return customEfYear2 - customEfYear1;
};

const EnvironmentalCustomEmissionFactorSetup = ({
  metersDataSet,
  customEmissionFactors,
  isCustomEmissionFactorsLoading,
  reloadCustomEmissionFactors,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [siteId, setSiteId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allSubtopicOptions, setAllSubtopicOptions] = useState([]);
  const [activeKey, setActiveKey] = useState(
    allSubtopicOptions.length > 0 ? allSubtopicOptions[0].name : null
  );
  const [expandedTypes, setExpandedTypes] = useState([]);

  const [
    currentCustomEmissionFactorDetails,
    setCurrentCustomEmissionFactorDetails,
  ] = useState(null);

  const [
    showInputCustomEmissionFactorModal,
    setShowInputCustomEmissionFactorModal,
  ] = useState(false);

  const [
    showDeleteCustomEmissionFactorDialog,
    setShowDeleteCustomEmissionFactorDialog,
  ] = useState(false);

  const toggleExpandedType = (id) => {
    setExpandedTypes(UtilsService.toggleItem(expandedTypes, id));
  };

  const handleCloseInputCustomEmissionFactor = () => {
    setCurrentCustomEmissionFactorDetails(null);
    setShowInputCustomEmissionFactorModal(false);
  };

  const handleCloseDeleteCustomEmissionFactor = () => {
    setCurrentCustomEmissionFactorDetails(null);
    setShowDeleteCustomEmissionFactorDialog(false);
  };

  useEffect(() => {
    if (selectedInventory) {
      setIsLoading(true);
      setSiteId(getSiteId(userInventory.selectedTreeNode.get));
      EnvironmentalSubtopicService.getAllInputSubtopics()
        .then((data) => {
          const availableInputSubtopics = data.filter((subtopic) =>
            availableEnvironmentalCustomEmissionFactorSubtopic.includes(
              subtopic.name
            )
          );
          setAllSubtopicOptions(availableInputSubtopics);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load environmental subtopics.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [userInventory.selectedInventory.get]);

  const onEnvironmentalTypeToggle = (
    isCustomEmissionFactorByEnvironmentalTypeAvailable,
    envType
  ) => {
    if (isCustomEmissionFactorByEnvironmentalTypeAvailable) {
      toastContext.addFailToast(
        <span>
          To stop customizing emission factors, delete each custom emission
          factor for this type.
        </span>
      );
    } else {
      addCustomEmissionFactorDialog(envType);
    }
  };

  const addCustomEmissionFactorDialog = (envType) => {
    setCurrentCustomEmissionFactorDetails({
      year: "",
      factor: "",
      source_description: "",
      environmental_type: envType,
    });
    setShowInputCustomEmissionFactorModal(true);
  };

  const editCustomEmissionFactorDialog = (customEmissionFactor) => {
    setCurrentCustomEmissionFactorDetails(customEmissionFactor);
    setShowInputCustomEmissionFactorModal(true);
  };

  const getCustomEmissionFactorByEnvironmentalType = (environment_type_id) => {
    return customEmissionFactors
      .filter(
        (customEmissionFactor) =>
          customEmissionFactor.environmental_type.id === environment_type_id
      )
      .sort(customEmissionFactorSortFn);
  };

  const isEnvironmentalTypeHasMeters = (
    subtopicName,
    metersDataSet,
    environment_type_id
  ) => {
    const trackedMeterCountPerEnvType = metersDataSet
      .filter(
        (meterDataset) =>
          meterDataset.environmental_subtopic.name === subtopicName
      )
      .map(
        (meterDataset) =>
          meterDataset.meters.filter(
            (env_type) => env_type.environmental_type.id === environment_type_id
          ).length
      );
    return trackedMeterCountPerEnvType.reduce((a, b) => a + b, 0) > 0;
  };

  const deleteCustomEmissionFactorHandler = (customEmissionFactor) => {
    if (customEmissionFactor) {
      setShowDeleteCustomEmissionFactorDialog(true);
      setShowInputCustomEmissionFactorModal(false);
    }
  };

  const deleteCustomEmissionFactor = () => {
    EnvironmentalService.deleteCustomEmissionFactor(
      currentCustomEmissionFactorDetails.id,
      selectedInventory.type,
      selectedInventory.id
    )
      .then(() => {
        toastContext.addSuccessToast(
          <span>Custom emission factor removed successfully</span>
        );
        reloadCustomEmissionFactors();
        setShowDeleteCustomEmissionFactorDialog(false);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to remove custom emission factor.</span>
        );
      });
  };

  if (isLoading || isCustomEmissionFactorsLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        {allSubtopicOptions.map((environmentalSubtopic) => {
          const subtopicName = environmentalSubtopic.name;
          const subtopicOptions = allSubtopicOptions.find(
            (d) => d.name === subtopicName
          );

          const envTypesWithMeters = orderBy(
            subtopicOptions.environmental_types,
            [
              (envType) => {
                return getCustomEmissionFactorByEnvironmentalType(envType.id);
              },
              "name",
            ],
            ["desc", "asc"]
          ).filter((environmentType) =>
            isEnvironmentalTypeHasMeters(
              subtopicName,
              metersDataSet,
              environmentType.id
            )
          );

          return (
            <Accordion
              key={environmentalSubtopic.name}
              defaultActiveKey={activeKey}
              activeKey={
                activeKey === environmentalSubtopic.name ? activeKey : null
              }
            >
              <Accordion.Item eventKey={environmentalSubtopic.name}>
                <GVDSAccordionHeader
                  isExpanded={activeKey === environmentalSubtopic.name}
                  title={environmentalSubtopic.name}
                  onClick={() =>
                    activeKey === environmentalSubtopic.name
                      ? setActiveKey(null)
                      : setActiveKey(environmentalSubtopic.name)
                  }
                />
                <Accordion.Body>
                  <GVDSTableDisplay>
                    <thead>
                      <tr>
                        <th
                          className="custom-emission-factor-table__tracking"
                          colSpan={2}
                        >
                          {t(
                            "data-management.environmental.custom-ef.setup.table-header.custom-factor"
                          )}
                        </th>
                        <th className="custom-emission-factor-table__type">
                          {t("data-management.environmental.shared.type")}
                        </th>
                        <th className="custom-emission-factor-table__ef_count">
                          {t(
                            "data-management.environmental.custom-ef.setup.table-header.custom-ef-count"
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {envTypesWithMeters.map((environmentType) => {
                        const customEmissionFactorByEnvironmentalType =
                          getCustomEmissionFactorByEnvironmentalType(
                            environmentType.id
                          );
                        return (
                          <EnvironmentalTypeCustomEmissionFactorsDisplay
                            key={environmentType.id}
                            environmentType={environmentType}
                            expandedTypes={expandedTypes}
                            toggleExpandedType={toggleExpandedType}
                            customEmissionFactorByEnvironmentalType={
                              customEmissionFactorByEnvironmentalType
                            }
                            onEnvironmentalTypeToggle={
                              onEnvironmentalTypeToggle
                            }
                            addCustomEmissionFactorDialog={
                              addCustomEmissionFactorDialog
                            }
                            editCustomEmissionFactorDialog={
                              editCustomEmissionFactorDialog
                            }
                          />
                        );
                      })}
                    </tbody>
                  </GVDSTableDisplay>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}

        <EnvironmentalCustomEmissionFactorDialog
          show={showInputCustomEmissionFactorModal}
          close={handleCloseInputCustomEmissionFactor}
          onUpdateSuccessful={() => {
            reloadCustomEmissionFactors();
          }}
          currentCustomEmissionFactorDetails={
            currentCustomEmissionFactorDetails
          }
          siteId={siteId}
          deleteCustomEmissionFactorHandler={deleteCustomEmissionFactorHandler}
        />

        <GVDSModal
          title={t(
            "data-management.environmental.custom-ef.setup.modal-title-delete"
          )}
          size={GVDSModal.Size.small}
          show={showDeleteCustomEmissionFactorDialog}
          onHide={() => setShowDeleteCustomEmissionFactorDialog(false)}
        >
          <GVDSModal.Body>
            <div>
              {t(
                "data-management.environmental.custom-ef.setup.delete.warning"
              )}
            </div>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={() => handleCloseDeleteCustomEmissionFactor()}
              text={t("shared-modal.footer.cancel")}
            />
            <GVDSButton
              variant={buttonVariant.destructive_primary}
              onClick={() => deleteCustomEmissionFactor()}
              text={t("shared.delete")}
            />
          </GVDSModal.Footer>
        </GVDSModal>
      </>
    );
  }
};

export default EnvironmentalCustomEmissionFactorSetup;
