import { useContext } from "react";
import DashboardDataContext from "../Context/DashboardDataContext";
import ComparisonTimeFilterView from "./ComparisonTimeFilterView";

const ComparisonTimeFilter = () => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const selectedConfigHolder = dashboardDataContext.selectedConfigHolder;

  const year1 = selectedConfigHolder.comparisonTime.year1;
  const year2 = selectedConfigHolder.comparisonTime.year2;
  const includedMonths = selectedConfigHolder.comparisonTimeIncludedMonths;

  const onSaveYears = (newYear1, newYear2) => {
    selectedConfigHolder.comparisonTime = {
      year1: newYear1,
      year2: newYear2,
    };

    dashboardDataContext.onUpdateConfig();
  };

  const onSaveMonths = (selectedMonths) => {
    selectedConfigHolder.comparisonTimeIncludedMonths = selectedMonths;

    dashboardDataContext.onUpdateConfig();
  };

  return (
    <ComparisonTimeFilterView
      selectedMonths={includedMonths}
      year1={year1}
      year2={year2}
      onSaveYears={onSaveYears}
      onSaveMonthsSelection={onSaveMonths}
    />
  );
};

export default ComparisonTimeFilter;
