import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import OnboardingSetupTemplate from "./OnboardingSetupTemplate";
import BulkSitesInput from "../../Site/BulkSitesInput";
import BulkFloorAreasInput from "../../Site/BulkFloorAreasInput";
import BulkSiteUsersInput from "../../Site/BulkSiteUsersInput";
import { CREATE_CONTRACT, VIEW_ALL_SITES } from "../../../config/ROUTES_NAME";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Container from "react-bootstrap/Container";

const CreateSubscriptionTab = () => {
  const location = useLocation();

  return (
    <div className="onboarding-tool__text-blurb-container">
      <div>
        Ensure that the relevant sites have been created before continuing with
        this step of the onboarding process.
      </div>
      <div>
        <div>
          There are 2 types of subscriptions:{" "}
          <strong>Contract subscription</strong> and{" "}
          <strong>Site subscription</strong>.
          <ul>
            <li>
              Use <strong>Contract subscription</strong> for multiple sites
              signed up under the same company or business contract. This is the
              common case on using onboarding tool.
            </li>
            <li>
              Use <strong>Site subscription</strong> for site which sign up as
              individual hotel (rather than part of a group sign up)
            </li>
          </ul>
        </div>
        <div>
          To create a <strong>Contract subscription</strong>:
          <ol>
            <li>
              Click{" "}
              <Link
                to={getRedirectURLWithCurrentParam(CREATE_CONTRACT, location)}
                target="_blank"
              >
                here
              </Link>{" "}
              to create a contract in the Contracts page.
            </li>
            <li>
              Once the contract is successfully created, proceed to create a
              subscription for the contract under the "Subscriptions" tab.
            </li>
          </ol>
        </div>
        <div>
          To create a <strong>Site subscription</strong>:
          <ol>
            <li>
              Go to{" "}
              <Link
                to={getRedirectURLWithCurrentParam(VIEW_ALL_SITES, location)}
                target="_blank"
              >
                Sites
              </Link>{" "}
              and search for the site you want to add subscription to.
            </li>
            <li>
              In the Site Details page, proceed to create a subscription for the
              contract under the "Subscriptions" tab.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

const OnboardingTool = () => {
  const [activeKey, setActiveKey] = useState("setup-template");
  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Onboarding Tool</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs
        activeKey={activeKey}
        onSelect={(eventKey) => setActiveKey(eventKey)}
      >
        <Tab eventKey="setup-template" title="1. Setup & template">
          <OnboardingSetupTemplate />
        </Tab>
        <Tab eventKey="create-site" title="2. Create site(s)">
          <BulkSitesInput
            onComplete={() => setActiveKey("create-subscription")}
          />
        </Tab>
        <Tab eventKey="create-subscription" title="3. Create subscription">
          <CreateSubscriptionTab />
        </Tab>
        <Tab eventKey="input-floor-area" title="4. Input floor area">
          <BulkFloorAreasInput
            onComplete={() => setActiveKey("setup-meters")}
          />
        </Tab>
        <Tab eventKey="create-users" title="5. Create user(s)">
          <BulkSiteUsersInput
            onComplete={() => setActiveKey("create-subscription")}
          />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default OnboardingTool;
