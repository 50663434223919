import axios from "axios";
import { API_URL } from "../../config/api-config";
import { BEST_QUESTION_CATEGORY_ORDER } from "../../config/constants";

export default class BestPracticeCategoryService {
  static getAllBestPracticeCategory = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/best_practices/categories"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deletePracticeCategory = async (categoryId) => {
    try {
      const response = await axios.delete(
        API_URL + "/api/v1/ref_data/best_practices/category/" + categoryId
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updatePracticeCategory = async (categoryId, categoryName) => {
    try {
      const data = {
        bestPracticeCategoryName: categoryName,
      };
      const response = await axios.patch(
        API_URL + `/api/v1/ref_data/best_practices/category/${categoryId}`,
        data
      );
      return response.data;
    } catch (error) {}
  };

  static savePracticeCategory = async (categoryName) => {
    try {
      const data = {
        bestPracticeCategoryName: categoryName,
      };
      const response = await axios.post(
        API_URL + `/api/v1/ref_data/best_practices/categories`,
        data
      );
      return response.data;
    } catch (error) {}
  };

  static bestPracticeSortFn = (category1Name, category2Name) => {
    const sortOrder1 =
      BEST_QUESTION_CATEGORY_ORDER[category1Name.toLowerCase()];
    const sortOrder2 =
      BEST_QUESTION_CATEGORY_ORDER[category2Name.toLowerCase()];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return -1;
    } else if (sortOrder2 !== undefined) {
      return 1;
    } else {
      if (category1Name.toLowerCase() === "other") {
        return 1;
      } else if (category2Name.toLowerCase() === "other") {
        return -1;
      } else {
        return category1Name.localeCompare(category2Name);
      }
    }
  };
}
