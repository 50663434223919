import React from "react";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import { useTranslation } from "react-i18next";

const DeleteMarketPurchaseRecordPrompt = ({ show, cancel, proceed }) => {
  const { t } = useTranslation();

  return (
    <GVDSModal
      title={t(
        "data-management.environmental.market-purchase.single-input.modal-title-delete"
      )}
      size={GVDSModal.Size.small}
      show={show}
      onHide={cancel}
    >
      <GVDSModal.Body>
        {t("data-management.environmental.usage.delete-warning")}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={cancel}
          text={t("shared-modal.footer.cancel")}
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          onClick={proceed}
          text={t("shared.delete")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default DeleteMarketPurchaseRecordPrompt;
