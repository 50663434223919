import React, { useContext, useEffect, useState } from "react";
import uniq from "lodash/uniq";
import intersection from "lodash/intersection";

import EnvironmentalService from "../../../services/EnvironmentalService";
import LoadingSpinner from "../../common/LoadingSpinner";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import {
  DateTimeUtils,
  NumberService,
  UtilsService,
} from "../../../services/UtilsService";
import DeleteEnvironmentalDataRecordPrompt from "./DeleteEnvironmentalDataRecordPrompt";
import InputEnvironmentalDataRecordModal from "./InputEnvironmentalDataRecordModal";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import { resetFilterKeys } from "../../../gvds-components/Table/TableUtils";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  GVDSTableRowSelectCheckbox,
  MULTI_SELECT_DATAKEY,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSTableBulkActionBar from "../../../gvds-components/Table/Controls/GVDSTableBulkActionBar";
import { formatCurrencyNameDisplay } from "../../Currency/SelectCurrency";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const ViewAllEnvironmentalDataRecords = ({
  lastUpdate,
  onRecordsUpdate,
  datasets,
  startPeriod,
  setStartPeriod,
  endPeriod,
  setEndPeriod,
  isMultipleDeleteMode,
  setMultipleDeleteMode,
  setTotalDataLengthCanBeDeleted,
  triggerDeleteAllTimestamp,
  triggerDownloadExcelTimestamp,
  setIsDownloadExcelLoading,
}) => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [allDataRecords, setAllDataRecords] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [currentlyEditedRecordModel, setCurrentlyEditedRecordModel] =
    useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => {
    setCurrentlyEditedRecordModel(null);
    setShowEditModal(false);
  };

  const [currentlyToBeDeletedRecordIds, setCurrentlyToBeDeletedRecordIds] =
    useState([]);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const handleClosePromptDeleteModal = () => {
    setCurrentlyToBeDeletedRecordIds([]);
    setShowPromptDeleteModal(false);
  };
  const toggleRecordModelSelection = (recordId) => {
    setCurrentlyToBeDeletedRecordIds(
      UtilsService.toggleItem(currentlyToBeDeletedRecordIds, recordId)
    );
  };

  const {
    filteredSortedData,
    currentPageData,

    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,

    filterKeys,
    setFilterKeys,

    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allDataRecords);

  useEffect(() => {
    setTotalDataLengthCanBeDeleted(totalDataLength);
  }, [totalDataLength]);

  useEffect(() => {
    if (triggerDeleteAllTimestamp !== null) {
      promptDeleteRecord(filteredSortedData.map((d) => d.id));
    }
  }, [triggerDeleteAllTimestamp]);

  useEffect(() => {
    if (triggerDownloadExcelTimestamp !== null) {
      onDownloadEnvironmentalDataRecordExcel();
    }
  }, [triggerDownloadExcelTimestamp]);

  const getFilterIdsFromFilterKeys = () => {
    const facilityIds = !_.isEmpty(filterKeys.facilityName)
      ? UtilsService.convertObjectNamesToObjectIds(
          filterKeys.facilityName,
          facilities
        )
      : [];
    const subtopicIds = !_.isEmpty(filterKeys.subtopic_name)
      ? UtilsService.convertObjectNamesToObjectIds(
          filterKeys.subtopic_name,
          subtopics
        )
      : [];
    const typeIds = !_.isEmpty(filterKeys.type_name)
      ? UtilsService.convertObjectNamesToObjectIds(filterKeys.type_name, types)
      : [];
    const meterIds = !_.isEmpty(filterKeys.meter_name)
      ? UtilsService.convertObjectNamesToObjectIds(
          filterKeys.meter_name,
          meters
        )
      : [];

    return { facilityIds, subtopicIds, typeIds, meterIds };
  };

  const onDownloadEnvironmentalDataRecordExcel = () => {
    const currentInventory = userInventory.selectedInventory.get;
    const { facilityIds, subtopicIds, typeIds, meterIds } =
      getFilterIdsFromFilterKeys();
    setIsDownloadExcelLoading(true);
    EnvironmentalService.downloadEnvironmentalDataRecordExcel(
      currentInventory.type,
      currentInventory.id,
      startPeriod,
      endPeriod,
      facilityIds,
      subtopicIds,
      typeIds,
      meterIds
    )
      .catch(() => {
        toastContext.addFailToast(<span>Failed to download excel.</span>);
      })
      .finally(() => setIsDownloadExcelLoading(false));
  };

  const loadDataRecords = () => {
    const currentInventory = userInventory.selectedInventory.get;
    if (currentInventory) {
      setIsLoading(true);
      EnvironmentalService.getAllDataRecords(
        currentInventory.id,
        currentInventory.type,
        startPeriod,
        endPeriod
      )
        .then((resp) => {
          setAllDataRecords(resp);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(
            <span>Failed to load environmental data records.</span>
          );
        });
    }
  };

  useEffect(() => {
    loadDataRecords();
  }, [userInventory.selectedInventory.get, startPeriod, endPeriod, lastUpdate]);

  let subtopics = [];
  let types = [];
  let meters = [];
  let facilities = [];

  allDataRecords.forEach((record) => {
    UtilsService.addIfUniqueToArray(subtopics, record.subtopic);
    UtilsService.addIfUniqueToArray(types, record.type);
    UtilsService.addIfUniqueToArray(meters, record.meter);
    const facilityObj = {
      id: record.facilityId,
      name: record.facilityName,
    };
    UtilsService.addIfUniqueToArray(facilities, facilityObj);
  });

  useEffect(() => {
    const newFilterKeys = resetFilterKeys(filterKeys, {
      subtopic_name: subtopics.map((subtopic) => subtopic.name),
      type_name: types.map((type) => type.name),
      facilityName: facilities.map((facility) => facility.name),
      meter_name: meters.map((meter) => meter.name),
    });

    setFilterKeys(newFilterKeys);
  }, [allDataRecords]);

  const onPageRecordsSelect = () => {
    const recordIds = currentPageData.map((r) => r.id);
    const intersectIds = intersection(currentlyToBeDeletedRecordIds, recordIds);
    if (intersectIds.length === recordIds.length) {
      setCurrentlyToBeDeletedRecordIds(
        currentlyToBeDeletedRecordIds.filter(
          (rId) => recordIds.indexOf(rId) < 0
        )
      );
    } else {
      setCurrentlyToBeDeletedRecordIds(
        uniq([...currentlyToBeDeletedRecordIds, ...recordIds])
      );
    }
  };

  const onDoneRecordInput = () => {
    loadDataRecords();
    onRecordsUpdate();
  };

  const showEditRecord = (recordModel) => {
    setCurrentlyEditedRecordModel(recordModel);
    setShowEditModal(true);
  };

  const promptDeleteRecord = (recordIds) => {
    setCurrentlyToBeDeletedRecordIds(recordIds);
    setShowPromptDeleteModal(true);
  };

  const deleteCurrentlySelectedRecordModel = () => {
    const selectedInventory = userInventory.selectedInventory.get;
    EnvironmentalService.deleteDataRecords(
      selectedInventory.type,
      selectedInventory.id,
      currentlyToBeDeletedRecordIds
    ).then(() => {
      const noOfDeletedModels = currentlyToBeDeletedRecordIds.length;
      if (noOfDeletedModels === 1) {
        toastContext.addSuccessToast(
          <span>Data record deleted successfully</span>
        );
      } else {
        toastContext.addSuccessToast(
          <span>{noOfDeletedModels} data records deleted successfully</span>
        );
      }

      if (isMultipleDeleteMode) {
        setMultipleDeleteMode(false);
      }
      setCurrentlyToBeDeletedRecordIds([]);
      loadDataRecords();
      onRecordsUpdate();
      handleClosePromptDeleteModal();
    });
  };

  let columns = [
    {
      header: t(
        "data-management.environmental.usage.single-input.label-subtopic"
      ),
      dataKey: "subtopic_name",
      sortable: true,
    },
    { header: t("data-management.environmental.shared.type"), dataKey: "type_name", sortable: true },
    { header: t("shared.meter"), dataKey: "meter_name", sortable: true },
    {
      header: t("shared-input-label.period-from"),
      dataKey: "periodFrom",
      sortable: true,
      renderer: (d) => DateTimeUtils.formatUTCDate(d["periodFrom"]),
    },
    {
      header: t("shared-input-label.period-to"),
      dataKey: "periodTo",
      sortable: true,
      renderer: (d) => DateTimeUtils.formatUTCDate(d["periodTo"]),
    },
    {
      header: t("data-management.environmental.usage.single-input.label-usage"),
      dataKey: "usage",
      sortable: true,
      renderer: (d) => NumberService.format(d["usage"], 2, 2),
    },
    { header: t("shared-input-label.unit"), dataKey: "unit_name" },
    {
      header: t("data-management.environmental.usage.single-input.label-cost"),
      dataKey: "cost",
      sortable: true,
      renderer: (d) => NumberService.format(d["cost"], 2, 2),
    },
    {
      header: t("shared-input-label.currency"),
      dataKey: "currency_name",
      renderer: (d) => formatCurrencyNameDisplay(d["currency"]),
    },
    {
      header: t("shared-input-label.comments"),
      dataKey: "comments",
      sortable: true,
      renderer: (d) =>
        d["comments"] ? <CommentTooltip comment={d["comments"]} /> : null,
    },
    {
      header: t("shared-input-label.actions"),
      dataKey: ACTIONS_DATAKEY,
      renderer: (d) => {
        return (
          <>
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.ENVIRONMENTAL_RECORD_EDIT
              ] && (
                <GVDSIconButton
                  variant={iconButtonVariant.tertiary}
                  icon={<GVDSIcon Icon={IconEdit} />}
                  tooltipText={t("shared.edit")}
                  onClick={(e) => {
                    e.preventDefault();
                    showEditRecord(d);
                  }}
                />
              )}
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.ENVIRONMENTAL_RECORD_DELETE
              ] && (
                <GVDSIconButton
                  variant={iconButtonVariant.destructive}
                  icon={<GVDSIcon Icon={IconTrash} />}
                  tooltipText={t("shared.delete")}
                  onClick={() => {
                    promptDeleteRecord([d.id]);
                  }}
                />
              )}
          </>
        );
      },
    },
  ];
  if (isMultipleDeleteMode) {
    columns.unshift({
      header: "",
      dataKey: MULTI_SELECT_DATAKEY,
      renderer: (d) => {
        return (
          <div>
            <GVDSTableRowSelectCheckbox
              key={d.id}
              checked={currentlyToBeDeletedRecordIds.indexOf(d.id) >= 0}
              onChange={() => toggleRecordModelSelection(d.id)}
            />
          </div>
        );
      },
    });
  }
  if (userInventory.selectedInventory.get.type === RESOURCES.SITE) {
    columns.splice(0, 0, {
      header: t("shared.facility"),
      dataKey: "facilityName",
      sortable: true,
    });
  }

  let content;
  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    const pageRecordIds = currentPageData.map((r) => r.id);
    const isPageSelected =
      intersection(pageRecordIds, currentlyToBeDeletedRecordIds).length ===
      pageRecordIds.length;

    content = (
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
        isPageSelected={isPageSelected}
        onPageSelect={onPageRecordsSelect}
        selectionKey={"id"}
        selectedValues={currentlyToBeDeletedRecordIds}
        noContent={
          <div className="table__no_content">
            <p>
              {t("data-management.environmental.all-records.no-record")}
            </p>
          </div>
        }
        viewControl={
          <>
            <GVDSTableCtrlContainer>
              <GVDSTableCtrlMultiSelect
                options={subtopics.map((subtopic) => subtopic.name)}
                prefix="Subtopic"
                value={filterKeys.subtopic_name}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, subtopic_name: filterKs })
                }
              />
              <GVDSTableCtrlMultiSelect
                options={types.map((type) => type.name)}
                prefix="Type"
                value={filterKeys.type_name}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, type_name: filterKs })
                }
              />
              {userInventory.selectedInventory.get &&
                userInventory.selectedInventory.get.type === RESOURCES.SITE && (
                  <GVDSTableCtrlMultiSelect
                    options={facilities.map((facility) => facility.name)}
                    prefix="Facility"
                    value={filterKeys.facilityName}
                    onChange={(filterKs) =>
                      setFilterKeys({ ...filterKeys, facilityName: filterKs })
                    }
                  />
                )}
              <GVDSTableCtrlMultiSelect
                options={meters.map((type) => type.name)}
                prefix="Meter"
                value={filterKeys.meter_name}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, meter_name: filterKs })
                }
              />
              <div className="date-range-container">
                <GVDSFormStartEndMonthPicker
                  startMonth={startPeriod}
                  endMonth={endPeriod}
                  onChange={(s, e) => {
                    if (s !== startPeriod) {
                      setStartPeriod(s);
                    }
                    if (e !== endPeriod) {
                      setEndPeriod(e);
                    }
                  }}
                />
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
            {isMultipleDeleteMode && (
              <GVDSTableBulkActionBar
                selectedRowsCount={currentlyToBeDeletedRecordIds.length}
                actionButtons={
                  <>
                    <GVDSButton
                      variant={buttonVariant.destructive_primary}
                      disabled={currentlyToBeDeletedRecordIds.length === 0}
                      onClick={(e) => {
                        e.preventDefault();
                        promptDeleteRecord(currentlyToBeDeletedRecordIds);
                      }}
                      text={t("shared-modal.footer.delete-selected")}
                    />
                    <GVDSButton
                      variant={buttonVariant.tertiary}
                      onClick={(e) => {
                        e.preventDefault();
                        setMultipleDeleteMode(false);
                        setCurrentlyToBeDeletedRecordIds([]);
                      }}
                      text={t("shared-modal.footer.cancel")}
                    />
                  </>
                }
              />
            )}
          </>
        }
      />
    );
  }

  return (
    <div>
      {content}
      <InputEnvironmentalDataRecordModal
        show={showEditModal}
        onClose={handleCloseEditModal}
        currentRecord={currentlyEditedRecordModel}
        datasets={datasets}
        onDoneInput={onDoneRecordInput}
      />
      <DeleteEnvironmentalDataRecordPrompt
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        proceed={deleteCurrentlySelectedRecordModel}
      />
    </div>
  );
};

export default ViewAllEnvironmentalDataRecords;
