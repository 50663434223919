import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

export const withRouter = (Component) => {
  return (props) => {
    const location = useLocation();
    const params = useParams();
    const history = useHistory();

    return (
      <Component
        {...props}
        location={location}
        params={params}
        history={history}
      />
    );
  };
};
