// To be made the same as styles/gvds-base--layout.scss
export const breakpoints = {
  largeDesktop: 1440,
  desktop: 1280,
  laptop: 720,
};

export const popupDelaySlowStart = { show: 300, hide: 0 };
export const infoTooltipPopupDelayStart = { show: 200, hide: 400 };

export const TOOLTIP_PLACEMENTS = {
  AUTO: "auto",
  TOP: "top",
  BOTTOM: "bottom",
  RIGHT: "right",
  LEFT: "left",
};