import { Trans, useTranslation } from "react-i18next";
import React, { forwardRef, useCallback, useContext, useState } from "react";
import CertificationContext from "../CertificationContext";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconHelp } from "@tabler/icons-react";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";

const tabKeyEnum = {
  about: "About",
  structure: "Structure",
};

const StructureDescriptions = () => (
  <Trans i18nKey="certifications.home-page.about-modal.structure-descriptions">
    <p>
      <strong>Requirements</strong> are the specific actions that needs to be
      completed by the property to achieve the certification.
    </p>
    <p>
      Some requirements may have <strong>Requirement Tasks</strong>, which may
      function as multiple-choice options or provide granular details about the
      expectation of specific requirements.
    </p>
    <p>
      <strong>Criteria</strong> groups relevant requirements with a similar
      topic, and <strong>Category</strong> groups relevant criteria with a
      similar topic.
    </p>
  </Trans>
);

const AboutTabContent = forwardRef(({ logoFileUrl }, ref) => {
  return (
    <div className="d-flex flex-row">
      <div ref={ref}></div>
      {logoFileUrl && (
        <>
          <span className="ms-3" />
          <img
            className="certification-homepage__about-logo"
            src={logoFileUrl}
            alt=""
          />
        </>
      )}
    </div>
  );
});

const StructureTabContent = () => {
  return (
    <div className="certification-homepage__structure-container">
      <div className="structure-description">
        <StructureDescriptions />
      </div>
      <div className="structure-image">
        <div className="parent-frame">
          <div className="child-frame">
            <div className="child-frame__content-container">
              <div className="child-frame__content me-2">Requirement Task</div>
              <div className="child-frame__content">Requirement Task</div>
            </div>
            <div className="structure-image-label">Requirement</div>
          </div>
          <div className="structure-image-label">Criteria</div>
        </div>
        <div className="structure-image-label">Category</div>
      </div>
    </div>
  );
};

const CertificationAboutModal = () => {
  const { t } = useTranslation();

  const certificationContext = useContext(CertificationContext);
  const description = certificationContext.certification.description;
  const logoFileUrl = certificationContext.certification.logoFileUrl;

  const [isShowAboutModal, setIsShowAboutModal] = useState(false);
  const [activeKey, setActiveKey] = useState(tabKeyEnum.about);

  const descriptionRef = useCallback((node) => {
    if (node !== null) {
      node.innerHTML = description;
    }
  }, []);

  const closeModal = () => {
    setIsShowAboutModal(false);
  };

  return (
    <>
      <InfoTooltip
        info={`About ${certificationContext.certification.name}`}
        icon={<GVDSIcon Icon={IconHelp} />}
        placement="top"
        onClick={() => setIsShowAboutModal(true)}
      />

      <GVDSModal
        title="Certifications"
        size={GVDSModal.Size.medium}
        show={isShowAboutModal}
        onHide={closeModal}
      >
        <GVDSModal.Body>
          <Tabs
            mountOnEnter
            activeKey={activeKey}
            onSelect={(eventKey) => setActiveKey(eventKey)}
          >
            <Tab title={tabKeyEnum.about} eventKey={tabKeyEnum.about}>
              <AboutTabContent ref={descriptionRef} logoFileUrl={logoFileUrl} />
            </Tab>
            <Tab title={tabKeyEnum.structure} eventKey={tabKeyEnum.structure}>
              <StructureTabContent />
            </Tab>
          </Tabs>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={closeModal}
            text={t("shared-modal.footer.close")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default CertificationAboutModal;
