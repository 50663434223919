import React, { useState } from "react";
import {
  FormFieldStatusMetadata,
  getDateLabel,
  getMinEndDate,
  GVDSFormErrorMessage,
  isEndDateBeforeStartDate,
} from "./GVDSFormShared";
import GVDSFormSingleYearPicker from "./GVDSFormSingleYearPicker";

const GVDSFormStartEndYearPicker = ({
  className,
  startYear,
  startYearLabel,
  startPlaceholderText = "From",
  minStartYear,
  maxStartYear,
  endYear,
  endYearLabel,
  endPlaceholderText = "To",
  minEndYear,
  maxEndYear,
  onChange,
  dateFormat = "yyyy",
  disabledStartYear = false,
  disabledEndYear = false,
  statusMetadata = FormFieldStatusMetadata.getDefault(),
  isPortalling = true,
}) => {
  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);

  const gvdsClassNames =
    "gvds-form__start-end-yearpicker__container " + (className ?? "");
  const statusMetadataWithoutMessage = new FormFieldStatusMetadata(
    statusMetadata.state
  );
  const shouldShowDateLabel = startYearLabel || endYearLabel;

  const onStartYearChange = (inputYear) => {
    if (isEndDateBeforeStartDate(inputYear, endYear)) {
      onChange(inputYear, null);
    } else {
      onChange(inputYear, endYear);
    }
  };

  return (
    <>
      <div className={gvdsClassNames}>
        <div className="start-year__container">
          {shouldShowDateLabel && getDateLabel(startYearLabel, endYearLabel)}
          <GVDSFormSingleYearPicker
            placeholder={startPlaceholderText}
            selected={startYear}
            onChange={onStartYearChange}
            dateFormat={dateFormat}
            disabled={disabledStartYear}
            onCalendarOpen={() => setIsStartCalendarOpen(true)}
            onCalendarClose={() => setIsStartCalendarOpen(false)}
            isFocusStyle={isStartCalendarOpen}
            className="start-date"
            statusMetadata={statusMetadataWithoutMessage}
            minYear={minStartYear}
            maxYear={maxStartYear}
            isPortalling={isPortalling}
          />
        </div>
        <div className="end-year__container">
          {shouldShowDateLabel && getDateLabel(endYearLabel, startYearLabel)}
          <GVDSFormSingleYearPicker
            placeholder={endPlaceholderText}
            selected={endYear}
            onChange={(endYear) => onChange(startYear, endYear)}
            dateFormat={dateFormat}
            disabled={disabledEndYear}
            onCalendarOpen={() => setIsEndCalendarOpen(true)}
            onCalendarClose={() => setIsEndCalendarOpen(false)}
            isFocusStyle={isEndCalendarOpen}
            className="end-date"
            statusMetadata={statusMetadataWithoutMessage}
            isUseIcon={false}
            minYear={getMinEndDate(startYear, minEndYear)}
            maxYear={maxEndYear}
            openToYear={
              endYear && !isEndDateBeforeStartDate(startYear, endYear)
                ? endYear
                : startYear
            }
            isPortalling={isPortalling}
          />
        </div>
      </div>
      {statusMetadata.isNotNormal() && statusMetadata.message && (
        <GVDSFormErrorMessage
          status={statusMetadata.state}
          errorMsg={statusMetadata.message}
        />
      )}
    </>
  );
};

export default GVDSFormStartEndYearPicker;
