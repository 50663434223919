import React from "react";

import withAuthentication from "../../HOC/withAuthentication";
import { getViewFacilityDetailsPath } from "../../../config/ROUTES_NAME";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";

const ViewAllFacilities = ({ siteId, facilities }) => {
  let history = useHistory();
  const location = useLocation();

  return (
    <GVDSTableDisplay className="facility-table">
      <thead>
        <tr>
          <th>Facility Name</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {facilities.map((facility) => {
          return (
            <tr
              key={facility.id}
              className="is-clickable"
              onClick={() => {
                const facilityUrl = getRedirectURLWithCurrentParam(
                  getViewFacilityDetailsPath(siteId, facility.id),
                  location
                );

                history.push(facilityUrl);
              }}
            >
              <td className="facility-name">
                <Link
                  to={getRedirectURLWithCurrentParam(
                    getViewFacilityDetailsPath(siteId, facility.id),
                    location
                  )}
                >
                  {facility.name}
                </Link>
              </td>
              <td>{facility.type.name}</td>
            </tr>
          );
        })}
      </tbody>
    </GVDSTableDisplay>
  );
};

export default withAuthentication(ViewAllFacilities);
