import { NumberService, ObjectUtils } from "../../../services/UtilsService";
import { performanceComparisonChartConfig } from "../Widgets/PerformanceComparisonChart/PerformanceComparisonChartViewConfig";
import { DashboardWidgetSharedUtils } from "../Widgets/DashboardWidgetSharedUtils";
import { divideDataWithDivisor } from "./DashboardCalculator";
import { ClassificationDonutChartUtils } from "../Widgets/ClassificationDonutChart/ClassificationDonutChartUtils";

export const DATA_GAPS_TOOLTIP =
  "This number was generated with data gaps. Download a report for more details.";

export const expandedModalViewOptionEnum = {
  CHART: "chart",
  TABLE: "table",
};
export const expandedModalViewOptions = [
  {
    value: expandedModalViewOptionEnum.CHART,
    label: "Chart",
  },
  {
    value: expandedModalViewOptionEnum.TABLE,
    label: "Table",
  },
];

export const maximumChartLegendItemCount = 10;
export const barChartRadius = 8;
export const barChartMinimapRadius = 4;
export const legendGlyphSizeInPx = 8;
export const OTHERS_LEGEND_LABEL = "Others";
export const singleHorizontalBarChartHeightInPx = 24;
export const singleBarChartGapInPx = 10;
export const expandedViewSingleHorizontalBarChartHeightInPx = 20;

export const downloadChartAsImageTimeoutInMs = 500;
export const dashboardContentContainerId = "dashboard-content-container-id";

export class DonutChartDataModel {
  constructor(label, value, color) {
    this.label = label;
    this.value = value;
    this.color = color;
  }

  toTableRowData(totalValue) {
    return [
      this.label,
      DashboardWidgetSharedUtils.calculatePercentage(this.value, totalValue),
      ClassificationDonutChartUtils.getTypeDisplayValue(this.value, false, 2),
    ];
  }
}

export class SummarySiteRankingChartDataModel {
  constructor(siteName, values) {
    this.siteName = siteName;
    this.values = values;
    this.siteTotalValue = Object.values(values).reduce((total, value) => {
      if (total === null) {
        return value;
      }

      return total + value;
    }, null);
  }

  toTableRowData(chartKeys) {
    return [
      this.siteName,
      ...chartKeys.map((key) => this.values[key]),
      this.siteTotalValue,
    ];
  }

  toBarChartData() {
    const barChartData = {
      siteName: this.siteName,
      siteTotalValue: this.siteTotalValue,
    };
    Object.entries(this.values).forEach(
      ([key, value]) => (barChartData[key] = value)
    );

    return barChartData;
  }
}

export class PerformanceTimelineChartInputDataModel {
  constructor(period, valueByType, divisorValue) {
    this.period = period;
    this.valueByType = valueByType;
    this.divisorValue = divisorValue;
  }
}

export class PerformanceTimelineChartDataModel {
  constructor(period, groupName, valueByType) {
    this.period = period;
    this.groupName = groupName;
    this.valueByType = valueByType;
    this.periodTotalValue = Object.values(valueByType).reduce(
      (total, value) => total + value,
      0
    );
  }

  toBarChartData() {
    const barChartData = {
      period: this.period,
      groupName: this.groupName,
      periodTotalValue: this.periodTotalValue,
    };
    Object.entries(this.valueByType).forEach(
      ([key, value]) => (barChartData[key] = value)
    );

    return barChartData;
  }
}

export class PerformanceTimelineMinimapPositionModel {
  constructor(startXDomainValue, endXDomainValue) {
    this.startXDomainValue = startXDomainValue;
    this.endXDomainValue = endXDomainValue;
  }
}

export class ChangeOverTimeSiteRankingChartDataModel {
  constructor(siteName, year1, year1Value, year2, year2Value) {
    this.siteName = siteName;
    this.year1 = year1;
    this.year1Value = year1Value;
    this.year2 = year2;
    this.year2Value = year2Value;

    if (year1Value !== null && year2Value !== null) {
      const changeValue = year2Value - year1Value;
      this.changeValue = {
        value: changeValue,
        percentage:
          changeValue === 0
            ? 0
            : divideDataWithDivisor(100 * changeValue, year1Value),
      };
    } else {
      this.changeValue = { value: null, percentage: null };
    }
  }

  toTableRowData() {
    return [
      this.siteName,
      this.year1Value,
      this.year2Value,
      this.changeValue.value,
      this.changeValue.percentage,
    ];
  }
}

export class PerformanceComparisonChartMonthlyValueModel {
  constructor(month, value) {
    this.month = month;
    this.value = value;
  }
}

export class PerformanceComparisonChartDataModel {
  constructor(year1, year1MonthlyValues, year2, year2MonthlyValues) {
    this.year1 = year1;
    this.year1MonthlyValues = year1MonthlyValues ?? [];
    this.year2 = year2;
    this.year2MonthlyValues = year2MonthlyValues ?? [];

    if (
      this.year1MonthlyValues.length === 0 ||
      this.year2MonthlyValues.length === 0
    ) {
      this.changeMonthlyValues = null;
    } else {
      this.changeMonthlyValues = year2MonthlyValues.map(
        (monthlyValue, index) => {
          const value1 = year1MonthlyValues[index]?.value;
          const value2 = monthlyValue?.value;

          if (value1 === undefined || value2 === undefined) {
            return { value: null, percentage: null };
          } else {
            const difference = value2 - value1;
            return {
              value: difference,
              percentage: NumberService.toPrecision(
                difference === 0
                  ? 0
                  : divideDataWithDivisor(100 * difference, value1),
                1
              ),
            };
          }
        }
      );
    }
  }

  hasData() {
    if (
      this.year1MonthlyValues.length === 0 &&
      this.year2MonthlyValues.length === 0
    ) {
      return false;
    }

    return (
      this.year1MonthlyValues.some(
        (monthlyValue) => !ObjectUtils.isEmpty(monthlyValue.value)
      ) ||
      this.year2MonthlyValues.some(
        (monthlyValue) => !ObjectUtils.isEmpty(monthlyValue.value)
      )
    );
  }

  toTableRowData() {
    const tableRowData = [];

    const year1RowData = [this.year1];
    if (this.year1MonthlyValues.length === 0) {
      year1RowData.push(
        ...Array(performanceComparisonChartConfig.monthTicks.length).fill(null)
      );
    } else {
      year1RowData.push(
        ...this.year1MonthlyValues.map(
          (monthlyValue) => monthlyValue?.value ?? null
        )
      );
    }
    tableRowData.push(year1RowData);

    const year2RowData = [this.year2];
    if (this.year2MonthlyValues.length === 0) {
      year2RowData.push(
        ...Array(performanceComparisonChartConfig.monthTicks.length).fill(null)
      );
    } else {
      year2RowData.push(
        ...this.year2MonthlyValues.map(
          (monthlyValue) => monthlyValue?.value ?? null
        )
      );
    }
    tableRowData.push(year2RowData);

    const yoyChangeRowData = ["YoY Change"];
    const changePercentageRowData = ["Change %"];
    if (ObjectUtils.isEmpty(this.changeMonthlyValues)) {
      yoyChangeRowData.push(
        ...Array(performanceComparisonChartConfig.monthTicks.length).fill(null)
      );
      changePercentageRowData.push(
        ...Array(performanceComparisonChartConfig.monthTicks.length).fill(null)
      );
    } else {
      this.changeMonthlyValues.forEach((changeValue) => {
        yoyChangeRowData.push(changeValue.value);
        changePercentageRowData.push(changeValue.percentage);
      });
    }
    tableRowData.push(yoyChangeRowData);
    tableRowData.push(changePercentageRowData);

    return tableRowData;
  }

  getValueRange() {
    let maxValue = 0;
    let minValue = 0;

    this.year1MonthlyValues.forEach((v) => {
      if (!ObjectUtils.isEmpty(v)) {
        if (maxValue < v.value) {
          maxValue = v.value;
        }
        if (minValue > v.value) {
          minValue = v.value;
        }
      }
    });

    this.year2MonthlyValues.forEach((v) => {
      if (!ObjectUtils.isEmpty(v)) {
        if (maxValue < v.value) {
          maxValue = v.value;
        }
        if (minValue > v.value) {
          minValue = v.value;
        }
      }
    });

    return { minValue: minValue, maxValue: maxValue };
  }
}

export class DashboardEnvClassificationDataTooltipModel {
  constructor(value, unit, typeName, percentage, color) {
    this.value = value;
    this.unit = unit;
    this.typeName = typeName;
    this.percentage = percentage;
    this.color = color;
  }
}
