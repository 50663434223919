import {
  getRedirectURLWithCurrentParam,
  updateQueryWithModalParam,
} from "../../components/common/QueryHandler";
import { ADMIN_BILLING } from "../../config/ROUTES_NAME";
import { billingWindowFeatures } from "../../services/Public/BillingPaymentService";
import { CONTACT_EMAIL_SALES } from "../../config/constants";
import { MailToUtils } from "../../services/UtilsService";

const CONTACT_SALES_FOR_PAID_ACCESS_SUBJECT =
  "Greenview Portal Enterprise Solution Subscription Inquiry";

const getMessageCtaModel = (
  message,
  cta = null,
  ctaAction = null,
  dismissible = false
) => {
  return {
    message,
    cta,
    ctaAction,
    dismissible,
  };
};

const getGoToPricingPlanTrigger = (history, location) => {
  return () => {
    const query = updateQueryWithModalParam(location, "open", "pricing-plan");

    location.search = query.toString();
    history.push(getRedirectURLWithCurrentParam(ADMIN_BILLING, location));
  };
};

const getGoToPaymentDetailsTrigger = (history, location) => {
  return () => {
    history.push(getRedirectURLWithCurrentParam(ADMIN_BILLING, location));
  };
};

const getGoToPaymentLinkTrigger = (
  paymentLink,
  setShowPaymentInstruction,
  setPaymentLink
) => {
  return () => {
    window.open(paymentLink, "_blank", billingWindowFeatures);
    setShowPaymentInstruction(true);
    setPaymentLink(paymentLink);
  };
};

const currentlyInTrial = (subscription, isBillingAdmin, history, location) => {
  if (!isBillingAdmin) {
    return getMessageCtaModel(
      `You have ${subscription.days_left_for_access} days left in your trial.`,
      null,
      null
    );
  } else {
    const isCustomPlan = subscription.is_custom_plan;

    return getMessageCtaModel(
      `You have ${subscription.days_left_for_access} days left in your trial.`,
      isCustomPlan ? "Contact Sales" : "Choose a plan",
      isCustomPlan
        ? () => {
            window.open(
              MailToUtils.sendEmailWithSubject(
                CONTACT_EMAIL_SALES,
                CONTACT_SALES_FOR_PAID_ACCESS_SUBJECT
              ),
              "_blank"
            );
          }
        : getGoToPricingPlanTrigger(history, location)
    );
  }
};

const currentlyInTrialWithUnpaidSub = (subscription, history, location) => {
  return getMessageCtaModel(
    `You have ${subscription.days_left_for_access} days left in your trial - update payment to start your subscription.`,
    "View Details",
    getGoToPaymentDetailsTrigger(history, location)
  );
};

const pastTrial = (subscription, isBillingAdmin, history, location) => {
  if (!isBillingAdmin) {
    return getMessageCtaModel(`Your trial has expired.`, null, null);
  } else {
    const isCustomPlan = subscription.is_custom_plan;

    return getMessageCtaModel(
      `Your trial has expired.`,
      isCustomPlan ? "Contact Sales" : "Choose a plan",
      isCustomPlan
        ? () => {
            window.open(
              MailToUtils.sendEmailWithSubject(
                CONTACT_EMAIL_SALES,
                CONTACT_SALES_FOR_PAID_ACCESS_SUBJECT
              ),
              "_blank"
            );
          }
        : getGoToPricingPlanTrigger(history, location)
    );
  }
};

const noActiveSubscription = (
  isCustomPlan,
  isBillingAdmin,
  history,
  location
) => {
  if (isBillingAdmin) {
    if (isCustomPlan) {
      return getMessageCtaModel(
        "You do not have an active subscription. Access to features is limited.",
        "Contact Sales",
        () => {
          window.open(
            MailToUtils.sendEmailWithSubject(
              CONTACT_EMAIL_SALES,
              CONTACT_SALES_FOR_PAID_ACCESS_SUBJECT
            ),
            "_blank"
          );
        }
      );
    } else {
      return getMessageCtaModel(
        "You do not have an active subscription. Access to features is limited.",
        "Purchase a subscription",
        getGoToPricingPlanTrigger(history, location)
      );
    }
  } else {
    return getMessageCtaModel(
      "You do not have an active subscription. Access to features is limited.",
      null,
      null
    );
  }
};

const paymentPending = (
  subscription,
  setShowPaymentInstruction,
  setPaymentLink
) => {
  return getMessageCtaModel(
    "Payment pending, complete payment to continue enjoying Greenview Portal",
    "Pay now",
    getGoToPaymentLinkTrigger(
      subscription.payment_link,
      setShowPaymentInstruction,
      setPaymentLink
    )
  );
};

const paymentPendingDecentralisedContract = (history, location) => {
  return getMessageCtaModel(
    "Payment pending, ensure sites complete payment to continue enjoying Greenview Portal",
    "View details",
    getGoToPaymentDetailsTrigger(history, location),
    true
  );
};

const subscriptionMessageCtas = {
  currentlyInTrial: currentlyInTrial,
  currentlyInTrialWithUnpaidSub: currentlyInTrialWithUnpaidSub,
  pastTrial: pastTrial,
  paymentPending: paymentPending,
  paymentPendingDecentralisedContract: paymentPendingDecentralisedContract,
  noActiveSubscription: noActiveSubscription,
};

export default subscriptionMessageCtas;
