import React, { useContext, useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import withAuthentication from "../../../HOC/withAuthentication";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EmissionService from "../../../../services/EmissionService";
import ToastContext from "../../../../context/ToastContext";
import { MultipleFilesUploader } from "../../../common/FileAttachments";
import LoadingSpinner from "../../../common/LoadingSpinner";
import GVFormGroup from "../../../common/GVFormGroup";
import { DateTimeUtils } from "../../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../../gvds-components/Table/GVDSTable";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";
import GVDSFormSingleYearPicker from "../../../../gvds-components/Forms/GVDSFormSingleYearPicker";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";

const UploadEmissionFactorModal = ({
  show,
  onClose,
  currentRecord,
  onUpload,
}) => {
  const toastContext = useContext(ToastContext);
  const disableYearPicker = !!currentRecord?.year;
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [uploadYear, setUploadYear] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [unprocessedEmissionRows, setUnprocessedEmissionRows] = useState([]);

  useEffect(() => {
    setUploadYear(currentRecord?.year);
  }, [currentRecord]);

  const onModalClose = () => {
    setUnprocessedEmissionRows([]);
    setUploadFiles([]);
    onClose();
  };

  const saveEmission = async () => {
    setIsLoadingUpload(true);

    try {
      const unprocessedEmissionRows = await EmissionService.saveWasteEmission(
        uploadYear,
        uploadFiles[0]
      );

      if (unprocessedEmissionRows.length === 0) {
        toastContext.addSuccessToast(
          <span>
            Waste emission factors for {uploadYear} was uploaded successfully.
          </span>
        );
        onModalClose();
      } else {
        setUnprocessedEmissionRows(unprocessedEmissionRows);
        toastContext.addFailToast(
          <span>
            Uploading Waste Emission Factors Data for {uploadYear} failed
            partially. Please check the error list for unprocessed rows.
          </span>
        );
      }
      onUpload();
      setUploadFiles([]);
    } catch (error) {
      toastContext.addFailToast(
        <span>Failed to upload waste emission factors data.</span>
      );
    }
    setIsLoadingUpload(false);
  };
  let modalContent;
  if (isLoadingUpload) {
    modalContent = (
      <div className="p-3">
        <LoadingSpinner />
      </div>
    );
  } else {
    modalContent = (
      <>
        <GVDSModal.Body>
          <div className="body-2 fst-italic pt-3 pb-3">
            Note: This action will replace an existing file for the selected
            year.
          </div>
          {unprocessedEmissionRows.length > 0 && (
            <GVDSBanner
              title={"Some rows were not processed"}
              variant={GVDSBanner.Variants.error}
            >
              <ul>
                {unprocessedEmissionRows.map((row, index) => (
                  <li key={index}>{row}</li>
                ))}
              </ul>
            </GVDSBanner>
          )}
          <Row>
            <GVFormGroup as={Col} md="4">
              <Form.Label>Year</Form.Label>
              <GVDSFormSingleYearPicker
                disabled={disableYearPicker}
                placeholder="Select a year"
                selected={uploadYear}
                onChange={(year) => setUploadYear(year)}
              />
            </GVFormGroup>
          </Row>
          <MultipleFilesUploader
            files={uploadFiles}
            setFiles={setUploadFiles}
          />
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={onModalClose}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveEmission}
            disabled={!uploadYear || uploadFiles.length !== 1}
            text="Upload"
          />
        </GVDSModal.Footer>
      </>
    );
  }

  return (
    <GVDSModal
      show={show}
      onHide={() => {
        onModalClose();
      }}
      title={
        currentRecord.filename
          ? "Edit Waste Emission Factors"
          : "Upload Waste Emission Factors"
      }
      size={GVDSModal.Size.small}
    >
      {modalContent}
    </GVDSModal>
  );
};

const ViewAllWasteEmissionFactors = () => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentlyEditRecord, setCurrentlyEditRecord] = useState({});
  const [currentlyDeletedYear, setCurrentlyDeletedYear] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(files, null, {
    filename: SORTING_TYPES.asc,
  });

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = () => {
    setIsLoading(true);
    EmissionService.getAllWasteEmissionFiles()
      .then((res) => {
        setFiles(res);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load waste emission files.</span>
        );
      });
  };

  const promptDeleteRecord = (year) => {
    setCurrentlyDeletedYear(year);
    setShowPromptDeleteModal(true);
  };

  const downloadEmission = async (year) => {
    try {
      await EmissionService.downloadWasteEmission(year);
    } catch (error) {
      toastContext.addFailToast(
        <span>Failed to download Waste Emission Factor.</span>
      );
    }
  };

  const deleteCurrentlySelectedFile = async () => {
    try {
      await EmissionService.deleteWasteEmissionFile(currentlyDeletedYear);
      toastContext.addSuccessToast(
        <span>Waste Emission Factor was successfully deleted.</span>
      );
      loadFiles();
      setShowPromptDeleteModal(false);
    } catch (_) {
      toastContext.addFailToast(
        <span>Failed to delete Waste Emission Factor.</span>
      );
    }
  };

  const onUploadModalClose = () => {
    setShowUploadModal(false);
    setCurrentlyEditRecord({});
  };

  const columns = [
    {
      header: "Year",
      dataKey: "year",
      sortable: true,
    },
    {
      header: "File Name",
      dataKey: "filename",
      renderer: (b) => (
        <div>
          <Button
            variant="link"
            size="sm"
            onClick={() => downloadEmission(b.year)}
          >
            {b.filename}
          </Button>
        </div>
      ),
    },
    {
      header: "Uploaded By",
      dataKey: "updated_by",
      renderer: (b) =>
        b["updated_on"] && (
          <div className="users">
            {b["updated_by"]}
            <span className="caption fst-italic">
              &nbsp;({DateTimeUtils.formatUTCDate(b["updated_on"])})
            </span>
          </div>
        ),
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (b) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => {
                setShowUploadModal(true);
                setCurrentlyEditRecord(b);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                promptDeleteRecord(b.year);
              }}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          </>
        );
      },
    },
  ];
  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <>
        <GVDSTableCtrlContainer>
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        </GVDSTableCtrlContainer>
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
        />
        {files.length === 0 && (
          <div className="table__no_content">No emission factors available</div>
        )}
      </>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            className="prompt-upload-emission"
            onClick={() => {
              setShowUploadModal(true);
            }}
            text="Upload File"
            icon={<GVDSIcon Icon={IconCirclePlus} />}
          />
        </PageHeader.Title>
      </PageHeader>
      <UploadEmissionFactorModal
        show={showUploadModal}
        onClose={onUploadModalClose}
        currentRecord={currentlyEditRecord}
        onUpload={loadFiles}
      />

      {content}
      <GVDSModal
        show={showPromptDeleteModal}
        onHide={() => setShowPromptDeleteModal(false)}
        title="Delete Waste Emission Factor"
        size={GVDSModal.Size.small}
      >
        <GVDSModal.Body>
          This action cannot be undone. Are you sure?
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => setShowPromptDeleteModal(false)}
            text="Cancel"
          />

          <GVDSButtonWithLoadingAction
            variant={buttonVariant.destructive_primary}
            onClickAsyncFunc={deleteCurrentlySelectedFile}
            text="Yes, Delete."
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </Container>
  );
};
export default withAuthentication(ViewAllWasteEmissionFactors);
