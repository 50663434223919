import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UserService, { UserRoleService } from "../../services/UserService";
import { StringUtils } from "../../services/UtilsService";
import GVFormGroup from "../common/GVFormGroup";
import { getOptionByValueFromIdName } from "../common/Forms/SingleSelect";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import {
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";
import SupportContactLink from "../common/SupportContactLink";
import GVDSTableDisplay from "../../gvds-components/Table/GVDSTableDisplay";
import InfoTooltip from "../common/Tooltip/InfoTooltip";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconTrash } from "@tabler/icons-react";
import { RESOURCES } from "../../config/constants";
import { Trans, useTranslation } from "react-i18next";

const isUserAndBillingAdminAvailable = (usersWithRoles) => {
  return (
    usersWithRoles &&
    usersWithRoles.length > 0 &&
    usersWithRoles.some((user) => user.as_billing_admin)
  );
};

const getDuplicatedUserEmails = (usersWithRoles) => {
  const userEmails = [];
  const duplicatedUserEmails = [];
  for (const user of usersWithRoles) {
    if (!userEmails.includes(user.email)) {
      userEmails.push(user.email);
    } else {
      duplicatedUserEmails.push(user.email);
    }
  }
  return duplicatedUserEmails;
};

export const isUsersValid = (usersWithRoles) => {
  return (
    isUserAndBillingAdminAvailable(usersWithRoles) &&
    getDuplicatedUserEmails(usersWithRoles).length === 0
  );
};

const getUsersInvalidErrorMessage = (usersWithRoles) => {
  const errorMessage = [];

  if (!isUserAndBillingAdminAvailable(usersWithRoles)) {
    errorMessage.push("Need at least 1 user as billing admin.");
  }

  const duplicatedUserEmail = getDuplicatedUserEmails(usersWithRoles);
  duplicatedUserEmail.forEach((email) =>
    errorMessage.push(`Duplicate user found for ${email}`)
  );

  return errorMessage.map((error) => <div key={error}>{error}</div>);
};

const AddUsersWithRoles = ({
  resource,
  usersWithRoles,
  setUsersWithRoles,
  ignoreDomainCheck = true,
  firstUserEmail = null,
  isValidated = false,
}) => {
  const [roles, setRoles] = useState([]);
  const [hasRunInit, setHasRunInit] = useState(false);

  useEffect(() => {
    UserService.getAllRolesForResource(resource).then((availableRoles) => {
      setRoles(availableRoles);
    });
  }, [resource]);

  const addUserWithRole = (userEmail, roleId, asBillingAdmin) => {
    if (usersWithRoles.length === 0) {
      asBillingAdmin = true;
    }

    const currentUsersWithRoles = [...usersWithRoles];
    if (asBillingAdmin) {
      currentUsersWithRoles.forEach((u) => (u.as_billing_admin = false));
    }

    setUsersWithRoles([
      ...usersWithRoles,
      { email: userEmail, roleId: roleId, as_billing_admin: asBillingAdmin },
    ]);
  };

  const removeUser = (user) => {
    const index = usersWithRoles.findIndex((userWithRole) => {
      return user.email === userWithRole.email;
    });
    if (index >= 0) {
      usersWithRoles.splice(index, 1);
      setUsersWithRoles([...usersWithRoles]);
    }
  };

  useEffect(() => {
    if (
      !hasRunInit &&
      roles.length > 0 &&
      !!firstUserEmail &&
      usersWithRoles.length === 0
    ) {
      const resourceAdminRole = roles.find((r) =>
        r.name.toLowerCase().includes("admin")
      );
      const role = resourceAdminRole ?? roles[0];
      addUserWithRole(firstUserEmail, role.id, true);
      setHasRunInit(true);
    }
  }, [hasRunInit, roles, usersWithRoles, firstUserEmail]);

  return (
    <div>
      <section className="section-box">
        <h3>
          Team{" "}
          <span className="optional-form-label">
            ({resource === RESOURCES.CONTRACT ? "Optional - " : ""}
            {ignoreDomainCheck && (
              <>The users below will be auto-approved without domain check</>
            )}
            )
          </span>
        </h3>
        <ListUserEmailsWithRoles
          users={usersWithRoles}
          availableRoles={roles}
          removeUser={removeUser}
        />
      </section>
      <AddUserWithRole
        availableRoles={roles}
        onAddingUser={addUserWithRole}
        ignoreDomainCheck={ignoreDomainCheck}
      />
      {isValidated && !isUsersValid(usersWithRoles) && (
        <GVDSFormErrorMessage
          errorMsg={getUsersInvalidErrorMessage(usersWithRoles)}
        />
      )}
    </div>
  );
};

export const AddUserWithRole = ({
  availableRoles,
  approvedDomains,
  onAddingUser,
  ignoreDomainCheck = false,
  isAddingUser = false,
  hasBillingAdmin = false,
}) => {
  const { t } = useTranslation();

  const [isDomainValidated, setIsDomainValidated] = useState(false);
  const [userEmailInput, setUserEmailInput] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [inputErrors, setInputErrors] = useState({ email: false, role: false });

  const [asBillingAdmin, setAsBillingAdmin] = useState(false);

  const onSelect = (selectedOption) => {
    setSelectedRole(selectedOption.value);
  };

  const addUser = async () => {
    const isEmailValid = StringUtils.isEmail(userEmailInput);
    if (!isEmailValid || !selectedRole) {
      setInputErrors({
        email: !isEmailValid,
        role: !selectedRole,
      });
    } else {
      await onAddingUser(userEmailInput, selectedRole, asBillingAdmin);
      clearInputs();
    }
  };

  const clearInputs = () => {
    setUserEmailInput("");
    setInputErrors({ email: false, role: false });
    setAsBillingAdmin(false);
  };

  const isEmailFromUnapprovedDomain = () => {
    if (ignoreDomainCheck) {
      return false;
    }

    return (
      isDomainValidated &&
      StringUtils.isEmail(userEmailInput) &&
      (!approvedDomains ||
        !approvedDomains.some((domain) =>
          userEmailInput.toLowerCase().endsWith(domain.toLowerCase())
        ))
    );
  };

  const sortedRoles = availableRoles.sort(UserRoleService.roleSortFn);

  return (
    <>
      {isEmailFromUnapprovedDomain() && (
        <GVDSBanner
          title={t(
            "admin.team-management.add-user.banner-title-nonapproved-domain"
          )}
          variant={GVDSBanner.Variants.warning}
        >
          <Trans i18nKey="admin.team-management.add-user.banner-message-nonapproved-domain">
            Please check the following rows: This requires a manual approval by
            the portal admin before the user can be added to help prevent
            malicious or unauthorised access. To add more domains under the list
            of approved domains, contact{" "}
            <SupportContactLink openInNewTab={true} />.
          </Trans>
        </GVDSBanner>
      )}
      <div className="add-user-with-role__container">
        <Row className="d-flex flex-grow-1">
          <Col lg="4" sm="12">
            <GVFormGroup controlId="userEmail">
              <Form.Label>
                {t("admin.team-management.add-user.label-invite-user")}
              </Form.Label>
              <GVDSFormField
                type="email"
                className="user-name-input"
                placeholder={t(
                  "admin.team-management.add-user.placeholder-email"
                )}
                value={userEmailInput}
                onInput={(value) => setUserEmailInput(value)}
                onBlur={() => setIsDomainValidated(true)}
                statusMetadata={
                  inputErrors.email
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "admin.team-management.add-user.validation-error-message.empty-required-email"
                        )
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
          <Col lg="3" sm="8">
            <GVFormGroup controlId="selectRole">
              <Form.Label>
                {t("admin.team-management.shared.table-header-role")}
              </Form.Label>
              <GVDSFormSingleSelect
                className="select__user-role"
                placeholder="Select a role"
                value={
                  selectedRole
                    ? getOptionByValueFromIdName(selectedRole, availableRoles)
                    : null
                }
                onSelect={onSelect}
                options={sortedRoles.map((role) => {
                  return { value: role.id, label: role.name };
                })}
                statusMetadata={
                  inputErrors.role
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "admin.team-management.add-user.validation-error-message.empty-required-role"
                        )
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
          <Col lg="5" sm="4">
            <GVFormGroup controlId="billingAdmin">
              <Form.Label></Form.Label>
              <div className="billing-admin__container invite-new-user">
                <Form.Label className="me-1">
                  {t("admin.team-management.shared.billing-admin")}
                </Form.Label>
                <InfoTooltip
                  info={t(
                    "admin.team-management.add-user.tooltip-billing-admin"
                  )}
                  placement="bottom"
                />
                <Form.Check
                  className="billing-admin-switch"
                  type="switch"
                  checked={asBillingAdmin}
                  onChange={() => setAsBillingAdmin(!asBillingAdmin)}
                />
              </div>
            </GVFormGroup>
          </Col>
        </Row>
        <div className="add-user-with-role__action-button">
          <div>
            <Form.Label className="add-user-with-role__action-button__spacer">
              Action
            </Form.Label>
            <GVDSButton
              id="add-user"
              variant={buttonVariant.secondary}
              onClick={addUser}
              disabled={isAddingUser}
              text={t("admin.team-management.add-user.button-send-invite")}
            />
          </div>
        </div>
      </div>
      {hasBillingAdmin && asBillingAdmin && (
        <GVDSBanner
          title={t(
            "admin.team-management.banner-title-warning-billing-admin-transfer"
          )}
          variant={GVDSBanner.Variants.warning}
        />
      )}
    </>
  );
};

const ListUserEmailsWithRoles = ({ users, availableRoles, removeUser }) => {
  return (
    <GVDSTableDisplay className="user-table">
      <thead>
        <tr>
          <th>User Email</th>
          <th>Role</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr key={index}>
            <td>
              {user.email}
              {user.as_billing_admin && (
                <StatusLabel className="ms-2" color={StatusLabel.Colors.blue}>
                  Billing Admin
                </StatusLabel>
              )}
            </td>
            <td>
              {availableRoles.find((role) => role.id === user.roleId).name}
            </td>
            <td>
              <GVDSIconButton
                variant={iconButtonVariant.destructive}
                onClick={() => removeUser(user)}
                icon={<GVDSIcon Icon={IconTrash} />}
                tooltipText="Remove User"
                className="remove-user"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </GVDSTableDisplay>
  );
};

export default AddUsersWithRoles;
