import React, { useContext, useEffect, useRef, useState } from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FEATURES, SURVEY_QUESTION_TYPE } from "../../../config/constants";
import { UtilsService } from "../../../services/UtilsService";
import SurveyAssessmentService from "../../../services/SurveyAssessmentService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVFormGroup from "../../common/GVFormGroup";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import PopoverOnTruncated from "../../common/Tooltip/PopoverOnTruncated";
import UserProfileContext from "../../../context/UserProfileContext";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormTextArea from "../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import {
  ANSWERING_SAVE_STATUS,
  getSaveStatus,
} from "../../common/AnswerSaveStatus";

const QuestionMultipleAnswersDisplay = ({
  surveyId,
  question,
  questionNumber,
  debounceSavingAnswer,
  onQuestionUpdated,
  disabled,
}) => {
  const userInventory = useContext(UserInventoryContext);

  const saveAnswers = async (selectedAnswers) => {
    const selectedInventory = userInventory.selectedInventory.get;

    return SurveyAssessmentService.updateAnswer(
      selectedInventory.id,
      surveyId,
      question.questionId,
      selectedAnswers.map((answer) => answer.id)
    );
  };

  const revertAnswers = (answersBeforeSave) => {
    question.answers = answersBeforeSave;
    onQuestionUpdated();
  };

  const onOptionSelected = (option) => {
    const currentAnswers = question.answers;
    let selectedAnswers;
    if (question.questionType === SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE) {
      if (
        currentAnswers &&
        currentAnswers.length > 0 &&
        currentAnswers[0].id === option.id
      ) {
        // unselect currently selected option
        selectedAnswers = [];
      } else {
        selectedAnswers = [option];
      }
    } else if (question.questionType === SURVEY_QUESTION_TYPE.CHECKBOXES) {
      // toggle answers
      selectedAnswers = question.answers
        ? UtilsService.toggleItem(question.answers, option)
        : [option];
    }

    question.answers = selectedAnswers;
    onQuestionUpdated();

    debounceSavingAnswer(
      currentAnswers,
      selectedAnswers,
      saveAnswers,
      revertAnswers
    );
  };

  return (
    <div
      className={
        question.isAnswered()
          ? "survey-question-display answered"
          : "survey-question-display"
      }
    >
      <div className="survey-question-text me-3">{questionNumber}.</div>
      <div className="flex-grow-1">
        <div className="survey-question-text mb-1">{question.questionText}</div>
        {question.description && (
          <div className="mb-1">{question.description}</div>
        )}
        <div className="mb-1">
          {question.options.map((option) => {
            return (
              <div
                className="d-flex align-items-center mb-2"
                key={`option-${option.id}`}
              >
                <Form.Check
                  label={option.option_text}
                  type={
                    question.questionType ===
                    SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE
                      ? "radio"
                      : "checkbox"
                  }
                  id={`option-${option.id}`}
                  onChange={() => {
                    onOptionSelected(option);
                  }}
                  checked={
                    (question.answers &&
                      question.answers.filter(
                        (answer) => answer.id === option.id
                      ).length > 0) ||
                    false
                  }
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>
        {getSaveStatus(question.saveStatus)}
        <QuestionCommentDisplay
          surveyId={surveyId}
          question={question}
          questionNumber={questionNumber}
          onQuestionUpdated={onQuestionUpdated}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const QuestionShortAnswerDisplay = ({
  surveyId,
  question,
  questionNumber,
  debounceSavingAnswer,
  onQuestionUpdated,
  disabled,
}) => {
  const userInventory = useContext(UserInventoryContext);

  const [answerInputText, setAnswerInputText] = useState("");

  useEffect(() => {
    if (!answerInputText) {
      setAnswerInputText(question.writtenAnswer);
    }
  }, [question]);

  const saveAnswer = async (updatedAnswer) => {
    const selectedInventory = userInventory.selectedInventory.get;

    return SurveyAssessmentService.updateWrittenAnswer(
      selectedInventory.id,
      surveyId,
      question.questionId,
      updatedAnswer
    );
  };

  const revertAnswer = (answerBeforeSave) => {
    question.writtenAnswer = answerBeforeSave;
    onQuestionUpdated();
    setAnswerInputText(answerBeforeSave);
  };

  const onAnswering = (e) => {
    const currentAnswer = question.writtenAnswer;
    const newAnswer = e.target.value;

    setAnswerInputText(newAnswer);

    question.writtenAnswer = newAnswer;
    onQuestionUpdated();

    debounceSavingAnswer(
      currentAnswer,
      newAnswer,
      saveAnswer,
      revertAnswer,
      500
    );
  };

  return (
    <div
      className={
        question.isAnswered()
          ? "survey-question-display answered"
          : "survey-question-display"
      }
    >
      <div className="survey-question-text me-3">{questionNumber}.</div>
      <div className="flex-grow-1">
        <div className="survey-question-text mb-1">{question.questionText}</div>
        {question.description && (
          <div className="mb-1">{question.description}</div>
        )}
        <Row className="mt-3">
          <Col lg={9}>
            <Form.Label>Response</Form.Label>
            <GVDSFormField
              name="written-answer"
              placeholder="Enter your response here"
              value={answerInputText}
              onInput={(value, e) => onAnswering(e)}
              disabled={disabled}
            />
            {getSaveStatus(question.saveStatus)}
          </Col>
        </Row>
        <QuestionCommentDisplay
          surveyId={surveyId}
          question={question}
          questionNumber={questionNumber}
          onQuestionUpdated={onQuestionUpdated}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const QuestionGroupDisplay = ({
  surveyId,
  question,
  questionNumber,
  onQuestionUpdated,
  disabled,
}) => {
  return (
    <div
      className={
        question.isAnswered()
          ? "survey-question-display answered"
          : "survey-question-display"
      }
    >
      <div className="flex-grow-1">
        <div className="d-flex flex-row flex-grow-1">
          <div className="survey-question-text me-3">{questionNumber}.</div>
          <div className="flex-grow-1">
            <div className="survey-question-text mb-1">
              {question.questionText}
            </div>
            {question.description && (
              <div className="mb-1">{question.description}</div>
            )}
          </div>
          <QuestionGroupCommentDisplay
            surveyId={surveyId}
            question={question}
            questionNumber={questionNumber}
            onQuestionUpdated={onQuestionUpdated}
            disabled={disabled}
          />
        </div>
        <div className="flex-grow-1 mb-1">
          {question.childQuestions.map((cq, childIndex) => {
            return (
              <QuestionAssessmentDisplay
                key={childIndex}
                surveyId={surveyId}
                question={cq}
                questionNumber={`${questionNumber}.${childIndex + 1}`}
                onQuestionUpdated={onQuestionUpdated}
                disabled={disabled}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const QuestionBestPracticeDisplay = ({
  surveyId,
  question,
  questionNumber,
  debounceSavingAnswer,
  onQuestionUpdated,
  disabled,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();

  const [isLoading, setIsLoading] = useState(true);

  const [bestPracticePreviousAnswers, setBestPracticePreviousAnswers] =
    useState([]);

  useEffect(() => {
    const hasFeatureBestPractice =
      getSubscriptionFeatureStatus(
        [FEATURES.BEST_PRACTICES__ASSESSMENT],
        userInventory.isLoadingInventory.get,
        userInventory.selectedTreeNode,
        userProfile
      ) === SUBSCRIPTION_FEATURE_STATUS.HAS_ACCESS;

    if (!question.isAnswered() && !disabled && hasFeatureBestPractice) {
      const selectedInventory = userInventory.selectedInventory.get;

      SurveyAssessmentService.getLinkedBestPracticeAnswer(
        selectedInventory.id,
        surveyId,
        question.questionId
      )
        .then((answers) => {
          setBestPracticePreviousAnswers(answers);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const saveAnswers = async (selectedAnswers) => {
    const selectedInventory = userInventory.selectedInventory.get;

    return SurveyAssessmentService.updateAnswer(
      selectedInventory.id,
      surveyId,
      question.questionId,
      selectedAnswers.map((answer) => answer.id)
    );
  };

  const revertAnswers = (answersBeforeSave) => {
    question.answers = answersBeforeSave;
    onQuestionUpdated();
  };

  const onOptionSelected = (option) => {
    const currentAnswers = question.answers;
    let selectedAnswers;
    if (
      question.questionType ===
      SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE
    ) {
      if (
        currentAnswers &&
        currentAnswers.length > 0 &&
        currentAnswers[0].id === option.id
      ) {
        // unselect currently selected option
        selectedAnswers = [];
      } else {
        selectedAnswers = [option];
      }
    } else if (
      question.questionType ===
      SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
    ) {
      // toggle answers
      selectedAnswers = question.answers
        ? UtilsService.toggleItem(question.answers, option)
        : [option];
    }

    question.answers = selectedAnswers;
    onQuestionUpdated();

    debounceSavingAnswer(
      currentAnswers,
      selectedAnswers,
      saveAnswers,
      revertAnswers
    );
  };

  const prepopulateAnswer = () => {
    const previousAnswerTexts = bestPracticePreviousAnswers.map(
      (a) => a.option_text
    );
    const prepopulatedAnswers = question.options.filter(
      (o) => previousAnswerTexts.indexOf(o.option_text) >= 0
    );

    const currentAnswers = question.answers;

    question.answers = prepopulatedAnswers;
    question.isPendingSave = true;
    onQuestionUpdated();

    debounceSavingAnswer(
      currentAnswers,
      prepopulatedAnswers,
      saveAnswers,
      revertAnswers,
      0,
      <span>
        We were unable to prepopulate your response to question {questionNumber}
        . Please try again.
      </span>
    );
  };

  if (isLoading) {
    return (
      <div className="survey-question-display">
        <LoadingSpinner />
      </div>
    );
  }

  const getBestPracticePreviousAnswerDisplay = () => {
    if (question.isAnswered() || disabled) {
      return null;
    } else if (
      !bestPracticePreviousAnswers ||
      bestPracticePreviousAnswers.length === 0
    ) {
      return null;
    } else {
      // if any answer is not available as option, do not display as likely the bpq has been updated
      return (
        <div className="survey-question-best-practice-prepopulate-container">
          <div className="mb-1">
            This question was previously answered with the following response:
          </div>
          <div className="mb-3">
            <strong>
              {bestPracticePreviousAnswers.map((a) => a.option_text).join(", ")}
            </strong>
          </div>
          <div className="d-flex align-items-center">
            <GVDSButton
              variant={buttonVariant.secondary}
              onClick={prepopulateAnswer}
              text="Populate existing response"
            />
            <div className="ms-2">
              or update responses by clicking on the options above.
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={
        question.isAnswered()
          ? "survey-question-display answered"
          : "survey-question-display"
      }
    >
      <div className="survey-question-text me-3">{questionNumber}.</div>
      <div className="flex-grow-1">
        <div className="survey-question-text mb-1">
          <StatusLabel color={StatusLabel.Colors.blue}>
            Best Practice
          </StatusLabel>{" "}
          {question.questionText}
        </div>
        {question.description && (
          <div className="mb-1">{question.description}</div>
        )}
        <div className="mb-1">
          {question.options.map((option) => {
            return (
              <div
                className="d-flex align-items-center mb-2"
                key={`option-${option.id}`}
              >
                <Form.Check
                  label={option.option_text}
                  type={
                    question.questionType ===
                    SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE
                      ? "radio"
                      : "checkbox"
                  }
                  id={`option-${option.id}`}
                  onClick={() => {
                    onOptionSelected(option);
                  }}
                  checked={
                    (question.answers &&
                      question.answers.filter(
                        (answer) => answer.id === option.id
                      ).length > 0) ||
                    false
                  }
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>
        {getSaveStatus(question.saveStatus)}
        <div>{getBestPracticePreviousAnswerDisplay()}</div>
        <QuestionCommentDisplay
          surveyId={surveyId}
          question={question}
          questionNumber={questionNumber}
          onQuestionUpdated={onQuestionUpdated}
          disabled={disabled}
        />
      </div>
      <div className="d-flex flex-column align-items-end">
        <InfoTooltip info="Your response to this question will update the response to this Best Practice Question for your Site." />
      </div>
    </div>
  );
};

const QuestionResponsiveAnsweringDisplay = ({
  surveyId,
  question,
  questionNumber,
  onQuestionUpdated,
  disabled,
}) => {
  const toastContext = useContext(ToastContext);

  const saveTimer = useRef(null);
  const savePromise = useRef(null);
  const [answerBeforeSaving, setAnswerBeforeSaving] = useState(null);

  // Reference:
  // - https://mreigen.medium.com/reactjs-auto-save-feature-for-any-input-field-a0d2ca0c0a7
  // - https://stackoverflow.com/a/53090848
  const debounceSavingAnswer = (
    oldAnswer,
    newAnswer,
    saveAnswer,
    revertAnswer,
    debounceWait = 1000,
    errorMessage = (
      <span>
        We were unable to save your response to question {questionNumber}.
        Please try again.
      </span>
    )
  ) => {
    clearTimeout(saveTimer.current);
    question.saveStatus = ANSWERING_SAVE_STATUS.NOT_SAVED;
    onQuestionUpdated();

    saveTimer.current = setTimeout(async () => {
      question.saveStatus = ANSWERING_SAVE_STATUS.SAVING;
      onQuestionUpdated();

      if (savePromise.current) {
        await savePromise.current;
      }

      if (!answerBeforeSaving) {
        setAnswerBeforeSaving(oldAnswer);
      }

      const promise = saveAnswer(newAnswer);
      savePromise.current = promise;

      promise
        .then(() => {
          savePromise.current = null;
          setAnswerBeforeSaving(null);
          question.saveStatus = ANSWERING_SAVE_STATUS.SAVED;
          onQuestionUpdated();
        })
        .catch(() => {
          savePromise.current = null;
          question.saveStatus = ANSWERING_SAVE_STATUS.FAILED;
          onQuestionUpdated();

          revertAnswer(oldAnswer);

          toastContext.addFailToast(errorMessage);
        });
    }, debounceWait);
  };

  if (
    question.questionType === SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE ||
    question.questionType === SURVEY_QUESTION_TYPE.CHECKBOXES
  ) {
    return (
      <QuestionMultipleAnswersDisplay
        surveyId={surveyId}
        question={question}
        questionNumber={questionNumber}
        debounceSavingAnswer={debounceSavingAnswer}
        onQuestionUpdated={onQuestionUpdated}
        disabled={disabled}
      />
    );
  } else if (question.questionType === SURVEY_QUESTION_TYPE.SHORT_ANSWER) {
    return (
      <QuestionShortAnswerDisplay
        surveyId={surveyId}
        question={question}
        questionNumber={questionNumber}
        debounceSavingAnswer={debounceSavingAnswer}
        onQuestionUpdated={onQuestionUpdated}
        disabled={disabled}
      />
    );
  } else if (
    question.questionType ===
      SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE ||
    question.questionType ===
      SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES
  ) {
    return (
      <QuestionBestPracticeDisplay
        surveyId={surveyId}
        question={question}
        questionNumber={questionNumber}
        debounceSavingAnswer={debounceSavingAnswer}
        onQuestionUpdated={onQuestionUpdated}
        disabled={disabled}
      />
    );
  }
};

const QuestionAssessmentDisplay = ({
  surveyId,
  question,
  questionNumber,
  onQuestionUpdated,
  disabled,
}) => {
  if (question.questionType === SURVEY_QUESTION_TYPE.QUESTION_GROUP) {
    return (
      <QuestionGroupDisplay
        surveyId={surveyId}
        question={question}
        questionNumber={questionNumber}
        onQuestionUpdated={onQuestionUpdated}
        disabled={disabled}
      />
    );
  } else {
    return (
      <QuestionResponsiveAnsweringDisplay
        surveyId={surveyId}
        question={question}
        questionNumber={questionNumber}
        onQuestionUpdated={onQuestionUpdated}
        disabled={disabled}
      />
    );
  }
};

const QuestionCommentDisplay = ({
  surveyId,
  question,
  questionNumber,
  onQuestionUpdated,
  disabled,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [isUpdatingComment, setIsUpdatingComment] = useState(false);
  const [comment, setComment] = useState(
    question.comment ? question.comment : ""
  );

  const updateComment = () => {
    setIsUpdatingComment(true);

    const selectedInventory = userInventory.selectedInventory.get;

    SurveyAssessmentService.updateComment(
      selectedInventory.id,
      surveyId,
      question.questionId,
      comment
    )
      .then(() => {
        toastContext.addSuccessToast(
          `Updated comment for question ${questionNumber}`
        );
        question.comment = comment;
        onQuestionUpdated();
        setIsUpdatingComment(false);
        setShowCommentModal(false);
      })
      .catch(() => toastContext.addFailToast("Failed to update comment"));
  };

  return (
    <>
      <div className="survey-question-comment__container">
        {question.hasNoComment() ? (
          !disabled && (
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={() => setShowCommentModal(true)}
              text="Add comment"
            />
          )
        ) : (
          <>
            <div className="me-1">
              <strong>Comment:</strong>
            </div>
            <PopoverOnTruncated
              content={question.comment}
              contentClassName="survey-question-comment"
            />
            {!disabled && (
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={() => setShowCommentModal(true)}
                text="Edit"
              />
            )}
          </>
        )}
      </div>
      {/* TODO modal is child of parent, so event will propagate up - make sure no click listener in parent */}
      <GVDSModal
        title={`${question.hasNoComment() ? "Add" : "Edit"} comment`}
        size={GVDSModal.Size.small}
        show={showCommentModal}
        onHide={() => setShowCommentModal(false)}
      >
        <GVDSModal.Body>
          <div className="mb-4">
            Write a comment to give additional context to your answers if
            needed.
          </div>
          <GVFormGroup controlId="comment">
            <Form.Label>Comment</Form.Label>
            <GVDSFormTextArea
              value={comment}
              onInput={(value) => {
                setComment(value);
              }}
              rows={3}
              disabled={isUpdatingComment}
            />
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => setShowCommentModal(false)}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={updateComment}
            disabled={
              isUpdatingComment ||
              (question.comment ? comment === question.comment : comment === "")
            }
            text="Save"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

const QuestionGroupCommentDisplay = ({
  surveyId,
  question,
  questionNumber,
  onQuestionUpdated,
  disabled,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [isUpdatingComment, setIsUpdatingComment] = useState(false);
  const [comment, setComment] = useState(
    question.comment ? question.comment : ""
  );

  const updateComment = () => {
    setIsUpdatingComment(true);

    const selectedInventory = userInventory.selectedInventory.get;

    SurveyAssessmentService.updateComment(
      selectedInventory.id,
      surveyId,
      question.questionId,
      comment
    )
      .then(() => {
        toastContext.addSuccessToast(
          `Updated comment for question ${questionNumber}`
        );
        question.comment = comment;
        onQuestionUpdated();
        setIsUpdatingComment(false);
        setShowCommentModal(false);
      })
      .catch(() => toastContext.addFailToast("Failed to update comment"));
  };

  return (
    <>
      <div className="d-flex flex-column align-items-end">
        <div className="mt-auto">
          {question.hasNoComment() ? (
            !disabled && (
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={() => setShowCommentModal(true)}
                text="Add comment"
              />
            )
          ) : (
            <div className="d-flex flex-row align-items-center">
              <PopoverOnTruncated
                content={question.comment}
                contentClassName="survey-question-comment"
              />
              {!disabled && (
                <GVDSButton
                  variant={buttonVariant.tertiary}
                  onClick={() => setShowCommentModal(true)}
                  text="Edit"
                />
              )}
            </div>
          )}
        </div>
      </div>
      {/* TODO modal is child of parent, so event will propagate up - make sure no click listener in parent */}
      <GVDSModal
        title={`${question.hasNoComment() ? "Add" : "Edit"} comment`}
        size={GVDSModal.Size.small}
        show={showCommentModal}
        onHide={() => setShowCommentModal(false)}
      >
        <GVDSModal.Body>
          <div className="mb-4">
            Write a comment to give additional context to your answers if
            needed.
          </div>
          <GVFormGroup controlId="comment">
            <Form.Label>Comment</Form.Label>
            <GVDSFormTextArea
              rows={3}
              value={comment}
              onInput={(value) => {
                setComment(value);
              }}
              disabled={isUpdatingComment}
            />
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => setShowCommentModal(false)}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={updateComment}
            disabled={
              isUpdatingComment ||
              (question.comment ? comment === question.comment : comment === "")
            }
            text="Save"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

const SurveyAssessmentQuestionsDisplay = ({
  surveyAssessmentModel,
  onQuestionUpdated,
  disabled,
}) => {
  let questions = surveyAssessmentModel.questions;

  return (
    <div>
      {questions.map((q, index) => (
        <QuestionAssessmentDisplay
          key={index}
          surveyId={surveyAssessmentModel.surveyId}
          question={q}
          questionNumber={index + 1}
          onQuestionUpdated={onQuestionUpdated}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default SurveyAssessmentQuestionsDisplay;
