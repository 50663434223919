import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import PermissionsContext from "../../context/PermissionsContext";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import { PERMISSIONS, RESOURCES, TARGET_STATUS } from "../../config/constants";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import { GOALS_AND_TARGETS } from "../../config/ROUTES_NAME";
import LoadingSpinner from "../common/LoadingSpinner";
import UNSDGService from "../../services/UNSDGService";
import CollectiveTargetService from "../../services/CollectiveTargetService";
import InputCollectiveTargetModal from "./InputCollectiveTargetModal";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import WorkspaceInventoryIcon from "../../gvds-components/common/WorkspaceInventoryIcon";
import { StringUtils } from "../../services/UtilsService";
import { IconSettings } from "@tabler/icons-react";

const DeleteCollectiveTargetModal = ({ show, onClose, targetId }) => {
  const history = useHistory();
  const location = useLocation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isDeleting, setIsDeleting] = useState(false);

  const handleOnClose = () => {
    if (!isDeleting) {
      onClose();
    }
  };

  const deleteCollectiveTarget = () => {
    if (selectedInventory?.id) {
      setIsDeleting(true);
      CollectiveTargetService.deleteCollectiveTarget(
        selectedInventory.type,
        selectedInventory.id,
        targetId
      )
        .then(() => {
          toastContext.addSuccessToast("Shared target deleted successfully.");
          history.push(
            getRedirectURLWithCurrentParam(GOALS_AND_TARGETS, location)
          );
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to delete shared target. Please try again.</span>
          );
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
  };

  return (
    <GVDSModal
      title="Delete Shared Target"
      size={GVDSModal.Size.small}
      show={show}
      onHide={handleOnClose}
    >
      <GVDSModal.Body>
        {isDeleting ? (
          <LoadingSpinner />
        ) : (
          <div className="body-2">
            This will also remove this target at each site within the portfolio.
            This is a permanent action and cannot be undone, are you sure?
          </div>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleOnClose}
          disabled={isDeleting}
          text="Cancel"
        />
        <GVDSButton
          className="delete-collective-target-button"
          variant={buttonVariant.destructive_primary}
          onClick={deleteCollectiveTarget}
          disabled={isDeleting}
          text="Yes, Delete"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const MarkAsClosedModal = ({ show, onCloseModal, closeTarget }) => {
  const onTargetClose = () => {
    closeTarget();
    onCloseModal();
  };

  return (
    <GVDSModal
      title="Close Shared Target"
      size={GVDSModal.Size.small}
      show={show}
      onHide={onCloseModal}
    >
      <GVDSModal.Body>
        <div className="body-2">
          Closed Shared Targets are hidden by default on the main site-level
          Goals & Targets page.
        </div>
        <br />
        <div className="body-2">You can re-open a Shared Target anytime.</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onCloseModal}
          text="Cancel"
        />
        <GVDSButton
          className="close-collective-target-button"
          variant={buttonVariant.primary}
          onClick={onTargetClose}
          text="Mark as Closed"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const CollectiveTargetStatus = ({ status }) => {
  return (
    <StatusLabel
      color={
        status === TARGET_STATUS.OPEN
          ? StatusLabel.Colors.green
          : status === TARGET_STATUS.CLOSED
          ? StatusLabel.Colors.gray
          : ""
      }
    >
      {StringUtils.capitaliseWord(status)}
    </StatusLabel>
  );
};

const ViewCollectiveTargetDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const { targetId } = useParams();

  const permissionCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [collectiveTarget, setCollectiveTarget] = useState({});
  const [showMarkAsClosed, setShowMarkAsClosed] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const onTriggerDelete = () => {
    setShowEdit(false);
    setShowDelete(true);
  };

  const [isLoading, setIsLoading] = useState(true);
  const isAllowedToEditCollectiveTarget =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.GOALS_N_TARGETS_EDIT];

  useEffect(() => {
    if (
      collectiveTarget.resourceId &&
      selectedInventory.id &&
      selectedInventory.id !== collectiveTarget.resourceId
    ) {
      backToAllGoalsAndTargets(false);
    } else {
      loadTarget();
    }
  }, [targetId, selectedInventory]);

  const loadTarget = () => {
    if (targetId && selectedInventory?.id) {
      setIsLoading(true);
      CollectiveTargetService.getCollectiveTarget(
        targetId,
        selectedInventory.type,
        selectedInventory.id
      )
        .then((data) => {
          if (
            data.resourceId &&
            selectedInventory.id &&
            selectedInventory.id !== data.resourceId
          ) {
            backToAllGoalsAndTargets(false);
          } else {
            setCollectiveTarget(data);
          }
        })
        .catch((e) => {
          if (e.status === 404) {
            backToAllGoalsAndTargets(false);
          } else {
            setIsLoading(false);
            toastContext.addFailToast(
              "Failed to load shared target. Please try again."
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const backToAllGoalsAndTargets = (saveInBrowserHistory = true) => {
    if (saveInBrowserHistory) {
      history.push(getRedirectURLWithCurrentParam(GOALS_AND_TARGETS, location));
    } else {
      history.replace(
        getRedirectURLWithCurrentParam(GOALS_AND_TARGETS, location)
      );
    }
  };

  const updateTargetStatus = (newStatus) => {
    CollectiveTargetService.patchCollectiveTarget(
      targetId,
      { status: newStatus },
      selectedInventory.type,
      selectedInventory.id
    )
      .then((data) => {
        toastContext.addSuccessToast(
          "Shared target status has been updated successfully."
        );
        setCollectiveTarget(data);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to update shared target status. Please try again.</span>
        );
      });
  };

  let content;
  let title;
  let status;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    title = (
      <div className="flex-grow-1">
        <StatusLabel color={StatusLabel.Colors.gray}>
          <GVDSIcon Icon={WorkspaceInventoryIcon[RESOURCES.PORTFOLIO]} />
          Shared Target
        </StatusLabel>
        <div className="d-flex">
          <h1>{collectiveTarget.name}</h1>

          {collectiveTarget?.id && isAllowedToEditCollectiveTarget && (
            <GVDSButton
              className="show-edit-collective-target-button ms-auto"
              variant={buttonVariant.secondary}
              disabled={isLoading}
              onClick={() => setShowEdit(true)}
              icon={<GVDSIcon Icon={IconSettings} />}
              text="Manage"
            />
          )}
        </div>
      </div>
    );
    status = (
      <div className="info-field">
        <CollectiveTargetStatus status={collectiveTarget.status} />
        {collectiveTarget.status === TARGET_STATUS.OPEN &&
          isAllowedToEditCollectiveTarget && (
            <GVDSButton
              variant={buttonVariant.secondary}
              className="collective-target-mark-as-closed-button ms-2"
              onClick={() => setShowMarkAsClosed(true)}
              text="Mark as Closed"
            />
          )}
        {collectiveTarget.status === TARGET_STATUS.CLOSED &&
          isAllowedToEditCollectiveTarget && (
            <GVDSButton
              variant={buttonVariant.secondary}
              className="collective-target-reopen-button ms-2"
              onClick={() => updateTargetStatus(TARGET_STATUS.OPEN)}
              text="Re-open target"
            />
          )}
      </div>
    );
    content = (
      <>
        {collectiveTarget.targetParamDetails?.un_sdgs && (
          <div className="collective-target-details__un-sdgs-container">
            {collectiveTarget.targetParamDetails.un_sdgs
              .sort((sdg1, sdg2) =>
                UNSDGService.unsdgNameSortFn(sdg1.name, sdg2.name)
              )
              .map((u) => (
                <div
                  key={u.id}
                  className="collective-target-details__un-sdgs-container__pill"
                >
                  {u.name}
                </div>
              ))}
          </div>
        )}
        <hr />
        <MarkAsClosedModal
          show={showMarkAsClosed}
          onCloseModal={() => setShowMarkAsClosed(false)}
          closeTarget={() => updateTargetStatus(TARGET_STATUS.CLOSED)}
        />
        <InputCollectiveTargetModal
          show={showEdit}
          setShow={setShowEdit}
          onInputSuccess={loadTarget}
          existingCollectiveTarget={collectiveTarget}
          onTriggerDelete={onTriggerDelete}
          onReloadCollectiveTarget={loadTarget}
        />
        <DeleteCollectiveTargetModal
          targetId={targetId}
          show={showDelete}
          onClose={() => setShowDelete(false)}
        />
      </>
    );
  }

  return (
    <div className="collective-target-details__container">
      <PageHeader>
        <PageHeader.BackButton
          text="Return to all targets"
          onClick={backToAllGoalsAndTargets}
        />
        <PageHeader.Title>{title}</PageHeader.Title>
        <PageHeader.Status>{status}</PageHeader.Status>
      </PageHeader>
      {content}
    </div>
  );
};

export default ViewCollectiveTargetDetails;
