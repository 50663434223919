import React, { useContext, useEffect, useState } from "react";
import uniq from "lodash/uniq";

import {
  INITIATIVE_STATUS,
  PERMISSIONS,
  RESOURCES,
} from "../../../config/constants";
import PermissionsContext from "../../../context/PermissionsContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import FileStorageStateContext from "../../../context/FileStorageStateContext";
import withAuthentication from "../../HOC/withAuthentication";
import { DateTimeUtils } from "../../../services/UtilsService";
import InitiativeService from "../../../services/InitiativeService";
import LoadingSpinner from "../../common/LoadingSpinner";
import {
  getDefaultMonthRangeEnd,
  getDefaultMonthRangeStart,
} from "../../common/MonthRangeSelector";
import DeleteInitiativePrompt from "./DeleteInitiativePrompt";
import InputInitiative from "./InputInitiative";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import AttachmentsTooltip from "../../common/Tooltip/AttachmentsTooltip";
import LastActivityDisplay from "../../common/LastActivityDisplay";
import CopyInitiativePrompt from "./CopyInitiativePrompt";
import InitiativeListStyles from "./InitiativeListStyle";
import InitiativeNameColumnDisplay from "./InitiativeDisplayComponents";
import { initiativeSearchKeys } from "../../../config/search-config";
import { uniqBy } from "lodash";
import { GenericUserManagedTagModel } from "../../common/CentralTags/UserManagedTagModel";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import { resetFilterKeys } from "../../../gvds-components/Table/TableUtils";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
} from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import { IconCopy, IconTrash } from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import { extractPageParamsObjectFromURL } from "../../common/QueryHandler";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next";

const ViewAllInitiatives = ({ initiativeOptions, lastUpdate, onUpdate }) => {
  const {t} = useTranslation();

  const location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const fileStorageStateContext = useContext(FileStorageStateContext);

  const [isLoading, setIsLoading] = useState(true);

  const [allInitiatives, setAllInitiatives] = useState([]);
  const [existingInitiativeNames, setExistingInitiativeNames] = useState([]);
  const [start, setStart] = useState(getDefaultMonthRangeStart());
  const [end, setEnd] = useState(getDefaultMonthRangeEnd());

  const [showInputInitiativeModal, setShowInputInitiativeModal] =
    useState(false);
  const [showCopyPrompt, setShowCopyPrompt] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [currentInitiative, setCurrentInitiative] = useState(null);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allInitiatives, initiativeSearchKeys);

  useEffect(() => {
    loadInitiatives();
  }, [fileStorageStateContext.initiativesComponentReloadFlag]);

  const loadInitiatives = () => {
    setIsLoading(true);
    const selectedInventory = userInventory.selectedInventory.get;
    if (selectedInventory && selectedInventory.type === RESOURCES.SITE) {
      Promise.all([
        InitiativeService.getAllInitiatives(
          selectedInventory.type,
          selectedInventory.id,
          start,
          end
        ),
        InitiativeService.getAllInitiativeNames(
          selectedInventory.type,
          selectedInventory.id
        ),
      ])
        .then(([initiativesInTimeRange, allInitiativeNames]) => {
          setAllInitiatives(initiativesInTimeRange);
          setExistingInitiativeNames(allInitiativeNames);
        })
        .catch((e) => {
          toastContext.addFailToast(<span>Failed to load Initiatives.</span>);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    loadInitiatives();
  }, [userInventory.selectedInventory.get, start, end, lastUpdate]);

  useEffect(() => {
    const subtopicOptions = uniq(allInitiatives.map((r) => r.subtopic)).sort(
      InitiativeService.initiativeSubtopicSortFn
    );
    const trackingCriteriaOptions = uniq(
      allInitiatives.map((q) => q.trackingCriteria).flat()
    );
    const tagOptions = uniq(
      allInitiatives
        .map((r) => r.allTags)
        .flat()
        .map((t) => t.selectionValue)
    );
    const statusOptions = uniq(allInitiatives.map((r) => r["status"]));

    const newFilterKeys = resetFilterKeys(filterKeys, {
      subtopic: subtopicOptions,
      trackingCriteria: trackingCriteriaOptions,
      status: statusOptions,
      allTagSelectionValues: tagOptions,
    });

    setFilterKeys(newFilterKeys);
  }, [allInitiatives]);

  useEffect(() => {
    const pageParamsObj = extractPageParamsObjectFromURL(location);
    if (pageParamsObj) {
      setStart(moment(pageParamsObj["start"]).toDate());
      setEnd(moment(pageParamsObj["end"]).toDate());
    }
  }, [location.search]);

  const onCopy = () => {
    setCurrentInitiative(
      currentInitiative.copyInitiative(existingInitiativeNames)
    );
    setShowInputInitiativeModal(true);
  };

  let columns = [
    {
      header: t("data-management.initiatives.label-name"),
      dataKey: "name",
      headerStyle: InitiativeListStyles.name,
      sortable: true,
      renderer: (i) => {
        return <InitiativeNameColumnDisplay initiative={i} />;
      },
    },
    {
      header: t(
          "data-management.initiatives.label-subtopic"
      ),
      dataKey: "subtopic",
      sortable: true,
      headerStyle: InitiativeListStyles.subtopic,
    },
    {
      header: t(
          "data-management.initiatives.label-tracking-criteria"
      ),
      dataKey: "trackingCriteria",
      headerStyle: InitiativeListStyles.trackingCriteria,
      renderer: (d) => {
        return <>{d.trackingCriteria.join(", ")}</>;
      },
    },
    {
      header: t("shared-input-label.period"),
      dataKey: "periodFrom",
      headerStyle: InitiativeListStyles.period,
      sortable: true,
      renderer: (d) => {
        return (
          <>
            {DateTimeUtils.formatLocalDate(d.periodFrom)} -<br />
            {d.periodTo ? (
              DateTimeUtils.formatLocalDate(d.periodTo)
            ) : (
              <span className="color-gray56 fst-italic">{t("data-management.initiatives.label-no-end-date")}</span>
            )}
          </>
        );
      },
    },
    {
      header: t("data-management.initiatives.label-notes"),
      headerStyle: InitiativeListStyles.notes,
      dataKey: "comments",
      renderer: (d) => {
        return (
          <>
            {d.comments && (
              <span className="mx-1">
                <CommentTooltip comment={d.comments} />
              </span>
            )}
            {d.attachedFiles && d.attachedFiles.length > 0 && (
              <span className="mx-1">
                <AttachmentsTooltip
                  attachmentNames={d.attachedFiles.map((file) => file.name)}
                />
              </span>
            )}
          </>
        );
      },
    },
    {
      header: t("shared-table-header.last-updated-by"),
      dataKey: "lastUpdated",
      headerStyle: InitiativeListStyles.lastUpdated,
      renderer: (d) => {
        return (
          <div style={{ wordBreak: "break-all" }}>
            {d.lastActivity && (
              <LastActivityDisplay lastActivity={d.lastActivity} />
            )}
          </div>
        );
      },
    },
    {
      header: t("shared-input-label.status"),
      dataKey: "status",
      headerStyle: InitiativeListStyles.status,
      sortable: true,
      renderer: (init) => {
        const labelColor =
          init.status === INITIATIVE_STATUS.PLANNED
            ? StatusLabel.Colors.blue
            : init.status === INITIATIVE_STATUS.IN_PROGRESS
            ? StatusLabel.Colors.yellow
            : init.status === INITIATIVE_STATUS.COMPLETED
            ? StatusLabel.Colors.green
            : init.status === INITIATIVE_STATUS.CANCELLED
            ? StatusLabel.Colors.gray
            : StatusLabel.Colors.gray;

        return <StatusLabel color={labelColor}>{init.status}</StatusLabel>;
      },
    },
    {
      header: t("shared-input-label.actions"),
      dataKey: ACTIONS_DATAKEY,
      headerStyle: InitiativeListStyles.actions,
      renderer: (d) => {
        return (
          <>
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.INITIATIVE_RECORD_CREATE
              ] && (
                <GVDSIconButton
                  key="copy-initiative"
                  className="initiative-action__copy"
                  onClick={() => {
                    setCurrentInitiative(d);
                    setShowCopyPrompt(true);
                  }}
                  variant={iconButtonVariant.tertiary}
                  icon={<GVDSIcon Icon={IconCopy} />}
                  tooltipText="Copy"
                />
              )}
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.INITIATIVE_RECORD_DELETE
              ] && (
                <GVDSIconButton
                  key="delete-initiative"
                  onClick={() => {
                    setCurrentInitiative(d);
                    setShowDeletePrompt(true);
                  }}
                  variant={iconButtonVariant.destructive}
                  icon={<GVDSIcon Icon={IconTrash} />}
                  tooltipText="Delete"
                />
              )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      {isLoading ? (
        <div style={{ paddingTop: "100px" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          onRowClick={(i) => {
            setCurrentInitiative(i);
            setShowInputInitiativeModal(true);
          }}
          className="initiatives"
          noContent={
            allInitiatives.length === 0 ? (
              <div className="table__no_content">
                {t("data-management.initiatives.all-initiatives.no-content")}</div>
            ) : (
              filteredSortedData.length === 0 && (
                <div className="table__no_content">
                  {t("data-management.initiatives.all-initiatives.filtered-no-content")}
                </div>
              )
            )
          }
          viewControl={
            <GVDSTableCtrlContainer>
              <FilterSearchBox
                className="initiative-search-box"
                placeholder="Search name, story or tag"
                value={searchText}
                onInput={setSearchText}
              />
              <GVDSTableCtrlMultiSelect
                options={uniq(allInitiatives.map((r) => r.subtopic)).sort(
                  InitiativeService.initiativeSubtopicSortFn
                )}
                prefix="Subtopic"
                value={filterKeys.subtopic}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, subtopic: filterKs })
                }
              />
              <GVDSTableCtrlMultiSelect
                nonStringOptions={uniqBy(
                  allInitiatives.map((r) => r.allTags).flat(),
                  (t) => t.selectionValue
                )}
                optionTransformer={GenericUserManagedTagModel.getOptionTransformer()}
                prefix="Tags"
                value={filterKeys.allTagSelectionValues}
                onChange={(filterKs) =>
                  setFilterKeys({
                    ...filterKeys,
                    allTagSelectionValues: filterKs,
                  })
                }
              />
              <GVDSTableCtrlMultiSelect
                options={uniq(
                  allInitiatives.map((q) => q.trackingCriteria).flat()
                )}
                prefix="Tracking Criteria"
                value={filterKeys.trackingCriteria}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, trackingCriteria: filterKs })
                }
              />
              <div className="date-range-container">
                <GVDSFormStartEndMonthPicker
                  startMonth={start}
                  endMonth={end}
                  onChange={(s, e) => {
                    if (s !== start) {
                      setStart(s);
                    }
                    if (e !== end) {
                      setEnd(e);
                    }
                  }}
                />
              </div>
              <GVDSTableCtrlMultiSelect
                options={uniq(allInitiatives.map((r) => r["status"]))}
                prefix="Status"
                value={filterKeys.status}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, status: filterKs })
                }
              />
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
          }
        />
      )}
      <InputInitiative
        currentInitiative={currentInitiative}
        initiativeOptions={initiativeOptions}
        onSuccess={onUpdate}
        show={showInputInitiativeModal}
        setShow={setShowInputInitiativeModal}
        onCloseModal={() => setCurrentInitiative(null)}
        onTriggerCopy={() => setShowCopyPrompt(true)}
      />
      <CopyInitiativePrompt
        show={showCopyPrompt}
        closeModal={() => setShowCopyPrompt(false)}
        onCopy={onCopy}
      />
      <DeleteInitiativePrompt
        show={showDeletePrompt}
        onCloseModal={() => {
          setShowDeletePrompt(false);
          setCurrentInitiative(null);
        }}
        initiative={currentInitiative}
        onSuccess={onUpdate}
      />
    </div>
  );
};

export default withAuthentication(ViewAllInitiatives);
