import withAuthentication from "../../HOC/withAuthentication";
import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVFormGroup from "../../common/GVFormGroup";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import EnvironmentalSubtopicService from "../../../services/EnvironmentalSubtopicService";
import ToastContext from "../../../context/ToastContext";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { StringUtils } from "../../../services/UtilsService";
import GVDSButton from "../../../gvds-components/Buttons/GVDSButton";
import {
  TroubleshootDataEnergyConversionService,
  TroubleshootDataUnitConversionService,
} from "../../../services/TroubleshootDataService";
import { SUBTOPIC_TYPE } from "../../../config/constants";
import LoadingSpinner from "../../common/LoadingSpinner";

const EnergyConversion = ({
  unitConversionFactors,
  isLoadingConversionFactors,
}) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [energyEnvTypes, setEnergyEnvTypes] = useState([]);

  const [selectedEnvTypeId, setSelectedEnvTypeId] = useState(null);
  const [selectedInputUnit, setSelectedInputUnit] = useState(null);
  const [selectedOutputUnit, setSelectedOutputUnit] = useState(null);
  const [inputUsage, setInputUsage] = useState(null);

  const [outputConvertedUsage, setOutputConvertedUsage] = useState(null);
  const [outputConversionFactor, setOutputConversionFactor] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    EnvironmentalSubtopicService.getAllInputSubtopics()
      .then((allSubtopic) => {
        setEnergyEnvTypes(
          allSubtopic.find((subtopic) => subtopic.name === SUBTOPIC_TYPE.ENERGY)
            .environmental_types
        );
      })
      .catch((e) => {
        toastContext.addFailToast(
          <span>Failed to load environmental subtopics.</span>
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const isConversionInputValid = () => {
    return (
      StringUtils.isNotEmpty(selectedEnvTypeId) &&
      StringUtils.isNotEmpty(selectedInputUnit) &&
      StringUtils.isNotEmpty(selectedOutputUnit) &&
      StringUtils.isNotEmpty(inputUsage)
    );
  };

  const submitUsageConversion = () => {
    setIsValidated(true);
    if (isConversionInputValid()) {
      setIsLoading(true);
      TroubleshootDataEnergyConversionService.convertUsage(
        selectedEnvTypeId,
        selectedInputUnit,
        selectedOutputUnit,
        inputUsage
      )
        .then((conversion) => {
          setOutputConvertedUsage(conversion.converted_value);
          setOutputConversionFactor(conversion.conversion_factor);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getUnitFormFieldStatusMetadataBySelectedUnit = (selectedUnit) => {
    if (isValidated) {
      if (!selectedEnvTypeId) {
        return FormFieldStatusMetadata.getError(
          "Please select a environmental type."
        );
      } else if (!selectedUnit) {
        return FormFieldStatusMetadata.getError("Please select a unit");
      }
    }
    return FormFieldStatusMetadata.getDefault();
  };

  const energyUnitsFromSelectedEnvType = selectedEnvTypeId
    ? energyEnvTypes.find((envType) => envType.id === selectedEnvTypeId).units
    : [];

  const energyUnitsWithUnitType = unitConversionFactors
    .filter((unitConversionFactor) =>
      energyUnitsFromSelectedEnvType.some(
        (energyUnitFromSelectedType) =>
          energyUnitFromSelectedType.id === unitConversionFactor.name
      )
    )
    .sort(TroubleshootDataUnitConversionService.UnitConversionSortFn);

  const unitOptions = energyUnitsWithUnitType.map((unit) => {
    return { value: unit.name, label: unit.unit_name };
  });

  let content;

  if (isLoadingConversionFactors) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <div className="troubleshoot-data-tool__container">
        <h3>Energy Conversion</h3>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>Environmental Type</Form.Label>
              <GVDSFormSingleSelect
                className="select__type"
                placeholder="Select Environmental Type"
                value={
                  selectedEnvTypeId
                    ? getOptionByValueFromIdName(
                        selectedEnvTypeId,
                        energyEnvTypes
                      )
                    : null
                }
                options={energyEnvTypes.map((envType) => {
                  return { value: envType.id, label: envType.name };
                })}
                onSelect={(option) => setSelectedEnvTypeId(option.value)}
                statusMetadata={
                  isValidated && !StringUtils.isNotEmpty(selectedEnvTypeId)
                    ? FormFieldStatusMetadata.getError(
                        "Please select environmental type"
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup controlId="usage">
              <Form.Label>Usage</Form.Label>
              <GVDSFormField
                type="number"
                placeholder="Enter usage"
                value={inputUsage}
                onInput={(value) => setInputUsage(value)}
                statusMetadata={
                  isValidated && !StringUtils.isNotEmpty(inputUsage)
                    ? FormFieldStatusMetadata.getError(
                        "Please enter a usage value."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <GVFormGroup>
              <Form.Label>Input Unit</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Select Input Unit"
                value={
                  selectedInputUnit
                    ? getOptionByValueFromIdName(
                        selectedInputUnit,
                        energyUnitsFromSelectedEnvType
                      )
                    : null
                }
                options={unitOptions}
                onSelect={(option) => setSelectedInputUnit(option.value)}
                disabled={!selectedEnvTypeId}
                statusMetadata={getUnitFormFieldStatusMetadataBySelectedUnit(
                  selectedInputUnit
                )}
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup>
              <Form.Label>Converted Unit</Form.Label>
              <GVDSFormSingleSelect
                placeholder="Select Converted Unit"
                value={
                  selectedOutputUnit
                    ? getOptionByValueFromIdName(
                        selectedOutputUnit,
                        energyUnitsFromSelectedEnvType
                      )
                    : null
                }
                options={unitOptions}
                onSelect={(option) => setSelectedOutputUnit(option.value)}
                disabled={!selectedEnvTypeId}
                statusMetadata={getUnitFormFieldStatusMetadataBySelectedUnit(
                  selectedOutputUnit
                )}
              />
            </GVFormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <GVFormGroup controlId="conversionFactor">
              <Form.Label>Output Conversion Factor</Form.Label>
              <GVDSFormField
                placeholder="Conversion Factor"
                type="number"
                value={outputConversionFactor}
                disabled
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup controlId="convertedUsage">
              <Form.Label>Converted Usage</Form.Label>
              <GVDSFormField
                placeholder="Converted Usage"
                type="number"
                value={outputConvertedUsage}
                disabled
              />
            </GVFormGroup>
          </Col>
        </Row>
        <GVDSButton
          onClick={submitUsageConversion}
          disabled={isLoading || !isConversionInputValid}
          text={isLoading ? "Converting" : "Convert Usage"}
        />
      </div>
    );
  }
  return content;
};

export default withAuthentication(EnergyConversion);
