import React, { useContext, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import HomepageAnnouncementAdminService from "../../../../services/HomepageAnnouncementAdminService";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import LoadingSpinner from "../../../common/LoadingSpinner";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";

const DeleteHomepageAnnouncementPrompt = ({
  show,
  setShow,
  currentAnnouncement,
  onDeleteSuccess,
}) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const cancelDeletePrompt = () => {
    if (!isLoading) {
      setShow(false);
    }
  };

  const deleteAnnouncement = () => {
    HomepageAnnouncementAdminService.deleteAnnouncement(currentAnnouncement.id)
      .then(() => {
        toastContext.addSuccessToast(
          <span>Announcement deleted successfully.</span>
        );
        if (onDeleteSuccess) {
          onDeleteSuccess();
        }
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to delete announcement.</span>);
      })
      .finally(() => {
        setIsLoading(false);
        setShow(false);
      });
  };

  return (
    <>
      <GVDSModal
        title="Delete announcement"
        size={GVDSModal.Size.small}
        show={show}
        onHide={cancelDeletePrompt}
      >
        <GVDSModal.Body>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              Once you delete your announcement, you will be unable to retrieve
              it again. Are you sure?
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancelDeletePrompt}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteAnnouncement}
            disabled={isLoading}
            text="Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default DeleteHomepageAnnouncementPrompt;
