import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconMessage } from "@tabler/icons-react";
import { TOOLTIP_PLACEMENTS } from "../../../config/style-config";
import { TooltipUtils } from "./TooltipUtils";

const CommentTooltip = ({ comment }) => {
  const popoverPlacement = TOOLTIP_PLACEMENTS.TOP;
  const popoverStyle = TooltipUtils.getTooltipContentStyle(popoverPlacement);

  return (
    <OverlayTrigger
      placement={popoverPlacement}
      delay={{ show: 100, hide: 200 }}
      overlay={
        <Popover style={popoverStyle}>
          <Popover.Body>{comment}</Popover.Body>
        </Popover>
      }
    >
      <div className="d-inline">
        <GVDSIcon Icon={IconMessage} />
      </div>
    </OverlayTrigger>
  );
};

export default CommentTooltip;
