import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import CertificationCriterionAdminService from "../../../../services/PortalAdmin/Certification/CertificationCriterionAdminService";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVFormGroup from "../../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import GVDSFormFieldWithCharacterCount from "../../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import { StringUtils } from "../../../../services/UtilsService";
import { POSITION } from "../../../../config/constants";
import GVDSFormSingleSelect from "../../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import Divider, { DividerOrientation } from "../../../common/Divider";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";
import UnsavedChangePromptModal from "../../../common/UnsavedChangePromptModal";
import { useTranslation } from "react-i18next";

const CRITERIA_VERBATIM_NUMBER_MAX_CHARACTER_LENGTH = 10;

export const CriteriaFormModal = ({
  show,
  setShowInputCriteriaForm,
  closeModal,
  onSuccess,
  criterion,
  category,
  certificationId,
  onPressDeleteCriteria,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [isValidated, setIsValidated] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [criterionNumber, setCriterionNumber] = useState("");
  const [criterionName, setCriterionName] = useState("");
  const [criterionDescription, setCriterionDescription] = useState("");
  const [criterionInstruction, setCriterionInstruction] = useState("");

  const [showUnsavedChangePromptModal, setShowUnsavedChangePromptModal] =
    useState(false);

  useEffect(() => {
    if (criterion) {
      setCriterionNumber(criterion.number);
      setCriterionName(criterion.name);
      setCriterionDescription(criterion.description);
      setCriterionInstruction(criterion.instruction);
    } else {
      resetInputFields();
    }
  }, [criterion]);

  const resetInputFields = () => {
    setCriterionNumber("");
    setCriterionName("");
    setCriterionDescription("");
    setCriterionInstruction("");
    setIsValidated(false);
  };

  const isInputsValid = () => {
    return criterionNumber && criterionName;
  };

  const hasChanges = () => {
    if (criterion !== null) {
      return (
        criterion.number !== criterionNumber ||
        criterion.name !== criterionName ||
        criterion.description !== criterionDescription ||
        criterion.instruction !== criterionInstruction
      );
    } else {
      return (
        criterionNumber.length > 0 ||
        criterionName.length > 0 ||
        criterionDescription.length > 0 ||
        criterionInstruction.length > 0
      );
    }
  };

  const handleClose = () => {
    if (!isSaving) {
      if (hasChanges()) {
        setShowInputCriteriaForm(false);
        setShowUnsavedChangePromptModal(true);
      } else {
        closeModal();
        resetInputFields();
      }
    }
  };

  const handleCancelCloseModal = () => {
    setShowUnsavedChangePromptModal(false);
    setShowInputCriteriaForm(true);
  };

  const handleCloseModalResetForm = () => {
    setShowUnsavedChangePromptModal(false);
    closeModal();
    resetInputFields();
  };

  const saveCriterion = async () => {
    setIsValidated(true);
    if (!isInputsValid()) {
      return;
    }

    setIsSaving(true);

    if (criterion) {
      try {
        const updatedCriterion =
          await CertificationCriterionAdminService.updateCriterion(
            certificationId,
            category.id,
            criterion.id,
            criterionNumber,
            criterionName,
            criterionDescription,
            criterionInstruction
          );
        toastContext.addSuccessToast(
          <span>Criteria successfully updated.</span>
        );
        resetInputFields();
        onSuccess(updatedCriterion);
      } catch (error) {
        if (error.response.status === 400) {
          toastContext.addFailToast(
            <span>{error.response.data?.message}</span>
          );
        } else {
          toastContext.addFailToast(<span>Failed to update criteria.</span>);
        }
      } finally {
        setIsSaving(false);
      }
    } else {
      try {
        const newCriterion =
          await CertificationCriterionAdminService.createCriterion(
            certificationId,
            category.id,
            criterionNumber,
            criterionName,
            criterionDescription,
            criterionInstruction
          );
        toastContext.addSuccessToast(
          <span>Criteria successfully created.</span>
        );
        resetInputFields();
        onSuccess(newCriterion);
      } catch (error) {
        if (error.response.status === 400) {
          toastContext.addFailToast(
            <span>{error.response.data?.message}</span>
          );
        } else {
          toastContext.addFailToast(
            <span>Failed to create new criteria.</span>
          );
        }
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      <GVDSModal
        title={`${criterion ? "Edit" : "Add"} criteria`}
        size={GVDSModal.Size.medium}
        show={show}
        onHide={handleClose}
      >
        <GVDSModal.Body>
          <div className="system-toolbox--certification__criteria-form__category-field">
            <Form.Label>Category:</Form.Label>
            <div className="ms-5">{category?.name}</div>
          </div>
          <GVFormGroup controlId="number">
            <Form.Label>Criteria number</Form.Label>
            <GVDSFormFieldWithCharacterCount
              name="name"
              value={criterionNumber}
              onInput={setCriterionNumber}
              placeholder="Enter verbatim criteria number"
              maxLength={CRITERIA_VERBATIM_NUMBER_MAX_CHARACTER_LENGTH}
              statusMetadata={
                isValidated && !criterionNumber
                  ? FormFieldStatusMetadata.getError(
                      "This field cannot be empty."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup controlId="name">
            <Form.Label>Criteria name</Form.Label>
            <GVDSFormTextArea
              name="name"
              value={criterionName}
              onInput={setCriterionName}
              placeholder="Enter verbatim criteria name"
              statusMetadata={
                isValidated && !criterionName
                  ? FormFieldStatusMetadata.getError(
                      "This field cannot be empty."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <GVFormGroup controlId="description">
            <Form.Label>Criteria description</Form.Label>
            <span className="optional-form-label ms-1">(Optional)</span>
            <GVDSFormTextArea
              name="description"
              value={criterionDescription}
              onInput={setCriterionDescription}
              placeholder="Enter verbatim description only if applicable"
            />
          </GVFormGroup>
          <GVFormGroup controlId="instruction">
            <Form.Label>Criteria instructions</Form.Label>
            <span className="optional-form-label ms-1">(Optional)</span>
            <GVDSFormField
              name="instruction"
              value={criterionInstruction}
              onInput={setCriterionInstruction}
              placeholder="Enter any specific instructions for this criteria"
            />
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {criterion && (
            <>
              <GVDSButton
                variant={buttonVariant.destructive_tertiary}
                onClick={onPressDeleteCriteria}
                text={t("shared.delete")}
              />
              <Spacer />
            </>
          )}
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            onClickAsyncFunc={saveCriterion}
            disabled={
              isSaving ||
              !(
                StringUtils.isNotEmpty(criterionNumber) &&
                StringUtils.isNotEmpty(criterionName)
              )
            }
            text={t("shared-modal.footer.save")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
      <UnsavedChangePromptModal
        show={showUnsavedChangePromptModal}
        onCancel={handleCancelCloseModal}
        onProceed={handleCloseModalResetForm}
        modalName="Input Criteria"
        message="You have unsaved changes in the form. Your data will be cleared and won’t be stored when you leave this page. Are you sure?"
      />
    </>
  );
};

export const ConfirmDeleteCriteriaModal = ({
  show,
  isDeleting,
  closeModal,
  asyncOnDelete,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    if (!isDeleting) {
      closeModal();
    }
  };

  return (
    <GVDSModal
      show={show}
      onHide={handleClose}
      title="Delete criteria"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <div>Are you sure want to delete this Criteria?</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          text={t("shared-modal.footer.cancel")}
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.destructive_primary}
          onClickAsyncFunc={asyncOnDelete}
          text={t("shared.delete")}
          disabled={isDeleting}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const CannotDeleteCriteriaModal = ({ show, closeModal }) => {
  const { t } = useTranslation();

  return (
    <GVDSModal
      show={show}
      onHide={closeModal}
      title="Unable to delete criteria"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        <div>
          Please delete all nested Requirements or move them to another Criteria
          before deleting this Criteria.
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={closeModal}
          text={t("shared-modal.footer.okay")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const MoveCertificationCriteriaModal = ({
  show,
  closeModal,
  onSuccess,
  criteriaToBeMoved,
  allCategories,
  certificationId,
  selectedCategory,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const [isValidated, setIsValidated] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const [position, setPosition] = useState(POSITION.BEFORE);
  const [categoryToMoveTo, setCategoryToMoveTo] = useState(null);
  const [criteriaToMoveTo, setCriteriaToMoveTo] = useState(null);

  const [moveToCategoryCriteria, setMoveToCategoryCriteria] = useState([]);

  const isMoveToCategoryHasEmptyCriteria = moveToCategoryCriteria.length === 0;

  useEffect(() => {
    setCategoryToMoveTo(selectedCategory);
    setMoveToCategoryCriteria(selectedCategory.criteria);
  }, [selectedCategory]);

  const resetState = () => {
    setCategoryToMoveTo(selectedCategory);
    setMoveToCategoryCriteria(selectedCategory.criteria);
    setCriteriaToMoveTo(null);
    setIsValidated(false);
    setPosition(POSITION.BEFORE);
  };

  const handleClose = () => {
    if (!isMoving) {
      closeModal();
    }
  };

  const moveCriteria = async () => {
    setIsValidated(true);

    if (criteriaToMoveTo || isMoveToCategoryHasEmptyCriteria) {
      setIsMoving(true);

      try {
        const newCriteria =
          await CertificationCriterionAdminService.moveCriterion(
            certificationId,
            selectedCategory.id,
            categoryToMoveTo.id,
            criteriaToBeMoved,
            criteriaToMoveTo,
            position
          );
        resetState();
        onSuccess(newCriteria);
        toastContext.addSuccessToast(<span>Criteria successfully moved.</span>);
      } catch {
        toastContext.addFailToast(<span>Failed to move criteria.</span>);
      } finally {
        setIsMoving(false);
      }
    }
  };

  const isMovingToAnotherCategory =
    categoryToMoveTo?.id !== selectedCategory.id;

  return (
    <GVDSModal
      title="Move criteria to"
      size={GVDSModal.Size.small}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>
        {isMovingToAnotherCategory && (
          <GVDSBanner
            variant={GVDSBanner.Variants.warning}
            className="system-toolbox--certification__move-criteria-banner"
          >
            <div className="gvds-text--caption">
              All requirements nested under this criteria will also be moved
            </div>
          </GVDSBanner>
        )}
        <GVFormGroup>
          <Form.Label>Category Name</Form.Label>
          <GVDSFormSingleSelect
            placeholder="Select category"
            className="category-to-move-to"
            isSearchable={true}
            value={
              categoryToMoveTo
                ? {
                    value: categoryToMoveTo,
                    label: categoryToMoveTo?.name,
                  }
                : {
                    value: selectedCategory,
                    label: selectedCategory.name,
                  }
            }
            options={allCategories.map((c) => {
              return { value: c, label: c.name };
            })}
            onSelect={(selected) => {
              setCategoryToMoveTo(selected.value);
              setCriteriaToMoveTo(null);
              setMoveToCategoryCriteria(selected.value.criteria);
            }}
          />
        </GVFormGroup>
        <Divider
          orientation={DividerOrientation.horizontal}
          className="system-toolbox--certification__move-criteria-divider"
        />
        <GVFormGroup>
          <Form.Label>Position</Form.Label>
          <Form.Check
            id="before"
            label="Before"
            type="radio"
            checked={
              !isMoveToCategoryHasEmptyCriteria && position === POSITION.BEFORE
            }
            onChange={() => setPosition(POSITION.BEFORE)}
            disabled={isMoveToCategoryHasEmptyCriteria}
          />
          <Form.Check
            id="after"
            label="After"
            type="radio"
            checked={
              !isMoveToCategoryHasEmptyCriteria && position === POSITION.AFTER
            }
            onChange={() => {
              setPosition(POSITION.AFTER);
            }}
            disabled={isMoveToCategoryHasEmptyCriteria}
          />
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>Criteria Name</Form.Label>
          <GVDSFormSingleSelect
            placeholder="Select criteria"
            className="category-to-move-to"
            isSearchable={true}
            value={
              criteriaToMoveTo
                ? {
                    value: criteriaToMoveTo,
                    label: `${criteriaToMoveTo.number} ${criteriaToMoveTo.name}`,
                  }
                : null
            }
            options={moveToCategoryCriteria.map((q) => {
              const label = `${q.number} ${q.name}`;
              return { value: q, label: label };
            })}
            isOptionDisabled={(option) =>
              option.value.id === criteriaToBeMoved.id
            }
            onSelect={(selected) => {
              setCriteriaToMoveTo(selected.value);
            }}
            disabled={isMoveToCategoryHasEmptyCriteria}
            statusMetadata={
              isValidated &&
              !isMoveToCategoryHasEmptyCriteria &&
              !criteriaToMoveTo
                ? FormFieldStatusMetadata.getError("Please select a criteria")
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButtonWithLoadingAction
            variant={buttonVariant.primary}
            onClickAsyncFunc={moveCriteria}
            text="Move"
            disabled={isMoving}
          />
        </>
      </GVDSModal.Footer>
    </GVDSModal>
  );
};
