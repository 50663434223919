import React, { useContext, useEffect, useRef, useState } from "react";
import { TabContext } from "./TabContext";
import TabsContext from "./TabsContext";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";

const BeforeTabChangesPrompt = ({ when, tabName, message, onProceed }) => {
  const tabsContext = useContext(TabsContext);
  const tabEventKey = useContext(TabContext);
  const proceedFnRef = useRef();
  const [showPromptModal, setShowPromptModal] = useState(false);

  const promptFn = (proceedToChangeTabFn) => {
    setShowPromptModal(true);
    proceedFnRef.current = proceedToChangeTabFn;
  };

  useEffect(() => {
    if (when) {
      tabsContext.registerListener(tabEventKey, promptFn);
      return () => tabsContext.unregisterListener(tabEventKey);
    }
  }, [when]);

  const onCancel = () => {
    setShowPromptModal(false);
  };

  const onLeaveTab = () => {
    setShowPromptModal(false);
    onProceed();
    proceedFnRef.current();
  };

  return (
    <GVDSModal
      show={showPromptModal}
      onHide={onCancel}
      size={GVDSModal.Size.small}
      title={`Leave ${tabName}`}
    >
      <GVDSModal.Body>
        <div>{message}</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          className="cancel-leave"
          variant={buttonVariant.tertiary}
          onClick={onCancel}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          onClick={onLeaveTab}
          text="Yes, leave tab."
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default BeforeTabChangesPrompt;
