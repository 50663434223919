import React, { Fragment, useContext, useEffect, useState } from "react";
import ToastContext from "../../../../context/ToastContext";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import MarketPurchaseOffsetService from "../../../../services/MarketPurchaseService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import sortBy from "lodash/sortBy";
import Form from "react-bootstrap/Form";
import _, { isEmpty } from "lodash";
import { UtilsService } from "../../../../services/UtilsService";
import GVFormGroup from "../../../common/GVFormGroup";
import { RESOURCES } from "../../../../config/constants";
import { getOptionByValueFromIdName } from "../../../common/Forms/SingleSelect";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../../../gvds-components/Forms/GVDSFormSingleSelect";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import MarketPurchaseTypeTrackerDisplay from "./TrackerSetup/MarketPurchaseTypeTrackerDisplay";
import { Trans, useTranslation } from "react-i18next";

const MarketPurchaseTrackerModal = ({
  show,
  onClose,
  onUpdate,
  meters,
  trackerDetails,
  setTrackerDetails,
  onDeleteTracker,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getFacilities = () => {
    return _.chain(meters)
      .map((m) => ({ id: m.facilityId, name: m.facilityName }))
      .uniqBy("id")
      .sortBy("name")
      .value();
  };

  const facilities = getFacilities();
  const isOnlyHasOneFacility = facilities.length === 1;

  useEffect(() => {
    if (!isEmpty(errors)) {
      setErrors({});
    }
  }, [trackerDetails]);

  const toggleMeter = (meterId) => {
    setTrackerDetails({
      ...trackerDetails,
      meter_ids: UtilsService.toggleItem(trackerDetails.meter_ids, meterId),
    });
  };

  const saveTracker = async (event) => {
    event.preventDefault();
    setErrors({});
    setIsLoading(true);
    const httpRequest = trackerDetails.id
      ? MarketPurchaseOffsetService.updateTracker(
          selectedInventory.type,
          selectedInventory.id,
          trackerDetails
        )
      : MarketPurchaseOffsetService.createTracker(
          selectedInventory.type,
          selectedInventory.id,
          trackerDetails
        );
    httpRequest
      .then(() => {
        onUpdate(trackerDetails["offset_type_id"]);
        setTrackerDetails({});
        toastContext.addSuccessToast(
          <span>
            Tracker {trackerDetails.id ? "updated" : "added"} successfully
          </span>
        );
        setIsLoading(false);
        onClose();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setErrors(error.response.data.message);
        } else {
          toastContext.addFailToast(<span>Failed to add tracker.</span>);
        }
        setIsLoading(false);
      });
  };

  const onHide = () => {
    setTrackerDetails({});
    setErrors({});
    onClose();
  };

  const onFacilityChange = (facilityId) => {
    setTrackerDetails({
      ...trackerDetails,
      facility_id: facilityId,
      meter_ids: meters
        .filter((m) => m.facilityId === facilityId)
        .map((m) => m.id),
    });
  };

  const getFacilityOptionByValue = (optionValue) => {
    return getOptionByValueFromIdName(optionValue, getFacilities());
  };

  return (
    <GVDSModal
      title={
        trackerDetails.id
          ? t(
              "data-management.environmental.market-purchase.setup.modal-title-edit"
            )
          : t(
              "data-management.environmental.market-purchase.setup.modal-title-create"
            )
      }
      size={GVDSModal.Size.medium}
      show={show}
      onHide={onHide}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <GVFormGroup>
              <Form.Label>
                {t("data-management.environmental.market-purchase.tracker-id")}
              </Form.Label>
              <GVDSFormField
                name="tracker"
                placeholder="Enter an identifiable name"
                value={trackerDetails.tracker_id}
                onInput={(value) =>
                  setTrackerDetails({
                    ...trackerDetails,
                    tracker_id: value,
                  })
                }
                statusMetadata={
                  !!errors["tracker_id"]
                    ? FormFieldStatusMetadata.getError(errors["tracker_id"])
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <GVFormGroup controlId="selectFacility">
              <Form.Label>{t("shared.facility")}</Form.Label>
              <GVDSFormSingleSelect
                className="select__facility"
                placeholder="Select Facility"
                value={
                  trackerDetails.facility_id
                    ? getFacilityOptionByValue(trackerDetails.facility_id)
                    : null
                }
                options={facilities.map((facility) => {
                  return { value: facility.id, label: facility.name };
                })}
                onSelect={(selectedOption) =>
                  onFacilityChange(selectedOption.value)
                }
                statusMetadata={
                  !!errors["facility_id"]
                    ? FormFieldStatusMetadata.getError(errors["facility_id"])
                    : FormFieldStatusMetadata.getDefault()
                }
                disabled={
                  isOnlyHasOneFacility ||
                  selectedInventory?.type === RESOURCES.FACILITY
                }
              />
            </GVFormGroup>
            <GVFormGroup>
              <Form.Label>
                {t(
                  "data-management.environmental.market-purchase.single-input.label-applicable-meter"
                )}
              </Form.Label>
              <div className="form-field-description mb-3">
                <Trans
                  i18nKey={
                    "data-management.environmental.market-purchase.setup.create-tracker.info-applicable"
                  }
                >
                  EAC only applies to <em>Purchased Electricity</em> energy
                  types. Select the usage meters that you would like to offset
                  with this EAC. For Monitoring Only meters are excluded.
                </Trans>
              </div>
              {meters
                .filter((m) => m.facilityId === trackerDetails.facility_id)
                .map((meter, index) => (
                  <div className="d-flex" key={index}>
                    <Form.Check
                      checked={trackerDetails.meter_ids?.some(
                        (meterId) => meterId === meter.id
                      )}
                      type="checkbox"
                      key={meter.id}
                      id={meter.id}
                      label=""
                      onChange={() => toggleMeter(meter.id)}
                      isInvalid={!!errors["meter_ids"]}
                    />
                    <span className="body-2">
                      {meter.environmentalTypeName} - {meter.name}
                    </span>
                  </div>
                ))}
              {meters.filter((m) => m.facilityId === trackerDetails.facility_id)
                .length === 0 && (
                <div
                  className="table__no_content mb-3"
                  style={{ height: "100px" }}
                >
                  {t(
                    "data-management.environmental.market-purchase.setup.tracker-section-info"
                  )}
                </div>
              )}
              {trackerDetails["facility_id"] && !!errors["meter_ids"] && (
                <GVDSFormErrorMessage
                  status={FormFieldState.error}
                  errorMsg={errors["meter_ids"]}
                />
              )}
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>{t("shared-input-label.description")}</Form.Label>
              <span className="optional-form-label mx-1">
                ({t("shared-input-label.optional")})
              </span>
              <GVDSFormField
                placeholder="Short reference description for other users"
                value={trackerDetails.description}
                onInput={(value) =>
                  setTrackerDetails({
                    ...trackerDetails,
                    description: value,
                  })
                }
              />
            </GVFormGroup>

            <Form.Label>
              {t(
                "data-management.environmental.market-purchase.setup.label-additional-notes"
              )}
            </Form.Label>
            <ul className="body-1">
              <li>
                {t(
                  "data-management.environmental.market-purchase.setup.additional-notes-1"
                )}
              </li>
            </ul>
          </>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        {trackerDetails.id && (
          <>
            <GVDSButton
              variant={buttonVariant.destructive_tertiary}
              onClick={onDeleteTracker}
              text={t("shared.delete")}
              disabled={isLoading}
            />
            <Spacer />
          </>
        )}
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onHide}
          text={t("shared-modal.footer.cancel")}
          disabled={isLoading}
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={saveTracker}
          text={t("shared-modal.footer.save")}
          disabled={isLoading}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const MarketPurchaseTrackerSetup = ({
  isMeterDatasetsLoading,
  meterDatasets,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const [isLoading, setIsLoading] = useState(false);
  const [offsetTypes, setOffsetTypes] = useState([]);
  const [trackersByType, setTrackersByType] = useState({});
  const [metersByType, setMetersByType] = useState({});

  const [showTrackerModal, setShowTrackerModal] = useState(false);
  const [trackerDetails, setTrackerDetails] = useState({});

  const [showUnableToCreateModal, setShowUnableToCreateModal] = useState(false);
  const [expandedTypes, setExpandedTypes] = useState([]);
  const [showPromptDeleteTracker, setShowPromptDeleteTracker] = useState(false);
  const [canDeleteTracker, setCanDeleteTracker] = useState(false);
  const [deleteTrackerTitleMessage, setDeleteTrackerTitleMessage] =
    useState(null);
  const [deleteTrackerBodyMessage, setDeleteTrackerBodyMessage] =
    useState(null);
  const [isCheckingTrackerHasRecords, setIsCheckingTrackerHasRecords] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleExpandedType = (id) => {
    setExpandedTypes(UtilsService.toggleItem(expandedTypes, id));
  };

  useEffect(() => {
    setIsLoading(true);
    MarketPurchaseOffsetService.getAllTypes()
      .then((data) => {
        setOffsetTypes(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load market purchase configuration.</span>
        );
      });
  }, []);

  useEffect(() => {
    if (selectedInventory?.id) {
      loadTrackers();
    }
  }, [selectedInventory]);

  useEffect(() => {
    setMetersByType(
      offsetTypes.reduce(
        (a, c) => ({ ...a, [c.id]: getMetersForOffsetType(c) }),
        {}
      )
    );
  }, [meterDatasets]);

  const loadTrackers = () => {
    MarketPurchaseOffsetService.getAllTrackers(
      selectedInventory.type,
      selectedInventory.id
    )
      .then((data) => {
        setTrackersByType(_.groupBy(data, "offset_type.id"));
      })
      .catch((e) => {
        toastContext.addFailToast(
          <span>Failed to load market purchase trackers.</span>
        );
      });
  };

  const onTrackerToggle = (offsetType) => {
    if (_.isEmpty(trackersByType[offsetType.id])) {
      if (_.isEmpty(metersByType[offsetType.id])) {
        setShowUnableToCreateModal(true);
      } else {
        onTrackerCreate(offsetType);
      }
    } else {
      toastContext.addFailToast(
        <span>To stop tracking a type, delete each tracker under it.</span>
      );
    }
  };

  const getFacilities = (selectedTreeNode) => {
    if (!selectedTreeNode) {
      return [];
    } else if (selectedTreeNode.nodeValue.type === RESOURCES.FACILITY) {
      return [selectedTreeNode.nodeValue];
    } else if (selectedTreeNode.nodeValue.type === RESOURCES.SITE) {
      return selectedTreeNode.children.map((c) => c.nodeValue);
    } else {
      return [];
    }
  };

  const facilities = getFacilities(userInventory.selectedTreeNode.get);
  const isOnlyHasOneFacility = facilities.length === 1;

  const onTrackerCreate = (offsetType) => {
    let facilityId = null;
    if (selectedInventory.type === RESOURCES.FACILITY) {
      facilityId = selectedInventory.id;
    } else if (isOnlyHasOneFacility) {
      facilityId = facilities[0].id;
    } else {
      facilityId = null;
    }

    setTrackerDetails({
      offset_type_id: offsetType.id,
      unit_id: offsetType.units[0].id,
      meter_ids: metersByType[offsetType.id]
        .filter((m) => m.facilityId === facilityId)
        .map((m) => m.id),
      facility_id: facilityId,
    });
    setShowTrackerModal(true);
  };

  const onTrackerEdit = (tracker) => {
    setTrackerDetails({
      ...tracker,
      facility_id: tracker["facility"]["id"],
      offset_type_id: tracker["offset_type"].id,
      meter_ids: tracker["environmental_meters"].map((m) => m.id),
    });
    setShowTrackerModal(true);
  };

  const onTrackerFinishUpdate = (offsetTypeId) => {
    if (
      !trackersByType[offsetTypeId] ||
      trackersByType[offsetTypeId].length === 0
    ) {
      toggleExpandedType(offsetTypeId);
    }
    loadTrackers();
  };

  const getMetersForOffsetType = (offsetType) => {
    return _.chain(meterDatasets)
      .flatMap((ds) =>
        ds.meters.map((m) => ({
          id: m.id,
          name: m.name,
          facilityId: ds.facility?.id,
          facilityName: ds.facility?.name,
          environmentalTypeName: m["environmental_type"]["name"],
          forMonitoringOnly: m["for_monitoring_only"],
        }))
      )
      .filter(
        (m) =>
          m.environmentalTypeName === offsetType["environmental_type_name"] &&
          !m.forMonitoringOnly
      )
      .value();
  };

  const onTrackerDelete = async () => {
    setShowTrackerModal(false);
    setShowPromptDeleteTracker(true);
    try {
      const trackerHasRecords = await isTrackerHasRecords(trackerDetails.id);
      setCanDeleteTracker(!trackerHasRecords);
      if (trackerHasRecords) {
        setDeleteTrackerTitleMessage(
          <h4>
            {t(
              "data-management.environmental.market-purchase.setup.delete-tracker.modal-title-unable"
            )}
          </h4>
        );
        setDeleteTrackerBodyMessage(
          <>
            <div>
              {t(
                "data-management.environmental.market-purchase.setup.delete-tracker.unable-has-data1"
              )}
            </div>
            <div className="mt-3">
              {t(
                "data-management.environmental.market-purchase.setup.delete-tracker.unable-has-data2"
              )}
            </div>
          </>
        );
      } else {
        setDeleteTrackerTitleMessage(
          <h4>
            {t(
              "data-management.environmental.market-purchase.setup.delete-tracker.modal-title"
            )}
          </h4>
        );
        setDeleteTrackerBodyMessage(
          <div>
            {t(
              "data-management.environmental.market-purchase.setup.delete-tracker.warning-permanent"
            )}
          </div>
        );
      }
    } catch (error) {
      setShowPromptDeleteTracker(false);
      toastContext.addFailToast(<span>Failed to delete tracker.</span>);
    }
  };

  const isTrackerHasRecords = async (trackerId) => {
    setIsCheckingTrackerHasRecords(true);
    try {
      const records = await MarketPurchaseOffsetService.getTrackerRecords(
        selectedInventory.type,
        selectedInventory.id,
        trackerId
      );
      return records.length > 0;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsCheckingTrackerHasRecords(false);
    }
  };

  const deleteTracker = () => {
    setIsDeleting(true);
    MarketPurchaseOffsetService.deleteTracker(
      trackerDetails.id,
      selectedInventory.type,
      selectedInventory.id
    )
      .then(() => {
        setIsDeleting(false);
        toastContext.addSuccessToast(<span>Tracker removed successfully</span>);
        loadTrackers();
        setShowPromptDeleteTracker(false);
      })
      .catch(() => {
        setIsDeleting(false);
        toastContext.addFailToast(<span>Failed to remove tracker.</span>);
      });
  };

  if (isLoading || isMeterDatasetsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <GVDSTableDisplay>
        <thead>
          <tr>
            <th className="market-purchase-offset-table__tracking">
              {t("data-management.environmental.setup.table-header.tracking")}
            </th>
            <th className="gvds-table-header__expandable" />
            <th className="market-purchase-offset-table__type">
              {t("data-management.environmental.shared.type")}
            </th>
            <th className="market-purchase-offset-table__tracker_count">
              {t(
                "data-management.environmental.setup.table-header.tracker-count"
              )}
            </th>
            <th className="market-purchase-offset-table__definition">
              {t("shared.definition")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortBy(offsetTypes, ["name"]).map((offsetType) => {
            const meterDetails = _.keyBy(
              getMetersForOffsetType(offsetType),
              "id"
            );
            return (
              <MarketPurchaseTypeTrackerDisplay
                key={offsetType.id}
                offsetType={offsetType}
                trackersByType={trackersByType}
                meterDetails={meterDetails}
                expandedTypes={expandedTypes}
                toggleExpandedType={toggleExpandedType}
                onTrackerToggle={onTrackerToggle}
                onTrackerCreate={onTrackerCreate}
                onTrackerEdit={onTrackerEdit}
              />
            );
          })}
        </tbody>
      </GVDSTableDisplay>
      <MarketPurchaseTrackerModal
        show={showTrackerModal}
        onClose={() => setShowTrackerModal(false)}
        meters={metersByType[trackerDetails.offset_type_id] || []}
        onUpdate={onTrackerFinishUpdate}
        trackerDetails={trackerDetails}
        setTrackerDetails={setTrackerDetails}
        onDeleteTracker={onTrackerDelete}
      />
      <GVDSModal
        title={isCheckingTrackerHasRecords ? "" : deleteTrackerTitleMessage}
        size={GVDSModal.Size.small}
        show={showPromptDeleteTracker}
        onHide={() => setShowPromptDeleteTracker(false)}
      >
        <GVDSModal.Body>
          {isCheckingTrackerHasRecords ? (
            <LoadingSpinner />
          ) : (
            deleteTrackerBodyMessage
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          {isCheckingTrackerHasRecords ? null : !canDeleteTracker ? (
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={() => setShowPromptDeleteTracker(false)}
              text={t("shared-modal.footer.okay")}
            />
          ) : (
            <>
              <GVDSButton
                variant={buttonVariant.tertiary}
                onClick={() => setShowPromptDeleteTracker(false)}
                disabled={isDeleting}
                text={t("shared-modal.footer.cancel")}
              />
              <GVDSButton
                variant={buttonVariant.destructive_primary}
                onClick={deleteTracker}
                text={t("shared.delete")}
              />
            </>
          )}
        </GVDSModal.Footer>
      </GVDSModal>
      <GVDSModal
        title={t(
          "data-management.environmental.market-purchase.setup.create-tracker.modal-title-unable"
        )}
        size={GVDSModal.Size.small}
        show={showUnableToCreateModal}
        onHide={() => setShowUnableToCreateModal(false)}
      >
        <GVDSModal.Body>
          {t(
            "data-management.environmental.market-purchase.setup.create-tracker.warning-purchased-electricity"
          )}
        </GVDSModal.Body>
      </GVDSModal>
    </>
  );
};

export default MarketPurchaseTrackerSetup;
