import React, { useContext, useMemo } from "react";

import Offcanvas from "react-bootstrap/Offcanvas";

import FileStorageStateContext, {
  FILE_STORAGE_PAGES,
} from "../../context/FileStorageStateContext";
import FileStorageMain from "./FileStorageMain";
import FileStorageUpload from "./FileStorageUpload";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../common/Feature/FeatureCheckPageWrapper";
import { FEATURES } from "../../config/constants";
import FeatureLockMessage from "../common/Feature/FeatureLockMessage";
import UserInventoryContext from "../../context/UserInventoryContext";
import UserProfileContext from "../../context/UserProfileContext";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import NoSubscriptionMessage from "../common/Feature/NoSubscriptionMesage";
import { useTranslation } from "react-i18next";

const FileStorage = () => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();
  const fileStorageStateContext = useContext(FileStorageStateContext);

  const content = useMemo(() => {
    const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
      [FEATURES.FILE_STORAGE],
      userInventory.isLoadingInventory.get,
      userInventory.selectedTreeNode,
      userProfile
    );

    if (
      subscriptionFeatureStatus === SUBSCRIPTION_FEATURE_STATUS.NO_SUBSCRIPTION
    ) {
      return (
        <>
          <div className="file-storage-header">
            <h1>{t("top-nav.file-storage")}</h1>
          </div>
          <NoSubscriptionMessage
            onClickListener={fileStorageStateContext.close}
          />
        </>
      );
    } else if (
      subscriptionFeatureStatus ===
      SUBSCRIPTION_FEATURE_STATUS.HAS_SUBSCRIPTION_NO_ACCESS
    ) {
      return (
        <>
          <div className="file-storage-header">
            <h1>{t("top-nav.file-storage")}</h1>
          </div>
          <FeatureLockMessage />
        </>
      );
    } else {
      if (fileStorageStateContext.currentPage === FILE_STORAGE_PAGES.MAIN) {
        return <FileStorageMain />;
      } else if (
        fileStorageStateContext.currentPage === FILE_STORAGE_PAGES.UPLOAD
      ) {
        return <FileStorageUpload />;
      }
    }
  }, [
    userInventory.isLoadingInventory.get,
    userInventory.selectedTreeNode,
    fileStorageStateContext.currentPage,
  ]);

  return (
    <>
      <Offcanvas
        show={fileStorageStateContext.isOpen}
        onHide={fileStorageStateContext.close}
        placement="end"
      >
        <Offcanvas.Body>{content}</Offcanvas.Body>
      </Offcanvas>

      <GVDSModal
        show={fileStorageStateContext.showCloseFileStoragePrompt}
        onHide={fileStorageStateContext.cancelCloseFileStorage}
        size={GVDSModal.Size.small}
        title={t("file-storage.modal-title-prompt-close")}
      >
        <GVDSModal.Body>
          <div>{t("file-storage.unsaved-change-prompt-message")}</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={fileStorageStateContext.cancelCloseFileStorage}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={fileStorageStateContext.confirmCloseFileStorage}
            text={t("shared-modal.footer.yes-close")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default FileStorage;
