import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import GVFormGroup from "../../../common/GVFormGroup";
import GVDSFormStartEndDatePicker from "../../../../gvds-components/Forms/GVDSFormStartEndDatePicker";
import Form from "react-bootstrap/Form";
import GVDSTimezoneDisplay from "../../../../gvds-components/common/GVDSTimezoneDisplay";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GVDSFormSingleDatePicker from "../../../../gvds-components/Forms/GVDSFormSingleDatePicker";
import React from "react";
import SubscriptionSharedUtils, {
  customEndPeriodRange,
} from "../SubscriptionSharedUtils";
import Button from "react-bootstrap/Button";

const QuickfillButtons = ({ calculateEndPeriod }) => {
  return (
    <div className="ms-auto quickfill-container">
      <span className="quickfill-label">Quickfill:</span>
      <Button
        variant="link"
        className="quickfill-input"
        size="sm"
        onClick={() => calculateEndPeriod(customEndPeriodRange.fourteenDays)}
      >
        {customEndPeriodRange.fourteenDays}
      </Button>
      <Button
        variant="link"
        className="quickfill-input"
        size="sm"
        onClick={() => calculateEndPeriod(customEndPeriodRange.oneMonth)}
      >
        {customEndPeriodRange.oneMonth}
      </Button>
      <Button
        variant="link"
        className="quickfill-input"
        size="sm"
        onClick={() => calculateEndPeriod(customEndPeriodRange.oneYear)}
      >
        {customEndPeriodRange.oneYear}
      </Button>
    </div>
  );
};

const SubscriptionPeriods = ({
  className,
  model,
  onDataChange,
  isValidated,
  minEndDate = null,
  disabled = false,
}) => {
  const calculateEndPeriod = (range) => {
    if (model.startPeriod) {
      model.endPeriod = SubscriptionSharedUtils.calculateEndPeriod(
        range,
        model.startPeriod
      );
      onSubscriptionPeriodsChange(model.startPeriod, model.endPeriod);
      onDataChange();
    }
  };

  const getPeriodErrorMessage = () => {
    return !model.isStartDateValid()
      ? FormFieldStatusMetadata.getError(
          SubscriptionSharedUtils.getStartPeriodValidationMessage(
            model.startPeriod,
            model.endPeriod
          )
        )
      : !model.isEndDateValid()
      ? FormFieldStatusMetadata.getError(
          SubscriptionSharedUtils.getEndPeriodValidationMessage(
            model.startPeriod,
            model.endPeriod
          )
        )
      : FormFieldStatusMetadata.getDefault();
  };

  const onSubscriptionPeriodsChange = (startDate, endDate) => {
    model.endPeriod = endDate;
    if (model.startPeriod !== startDate) {
      model.startPeriod = startDate;
      if (model.isRecurringBillingCycle()) {
        const endPeriod =
          SubscriptionSharedUtils.getEndPeriodBasedOnBillingCycle(
            model,
            model.billingCycle
          );
        if (endPeriod !== null) {
          model.endPeriod = endPeriod;
        }
      }
    }
    const defaultReminderDate = SubscriptionSharedUtils.getDefaultReminderDate(
      model,
      model.billingCycle,
      model.pricingType
    );
    if (defaultReminderDate !== null) {
      model.reminderDate = defaultReminderDate;
    }
  };

  return (
    <>
      <GVFormGroup controlId="selectStartEndPeriod">
        <GVDSFormStartEndDatePicker
          startDateLabel={
            <Form.Label className="gvds-text--formLabel">Start Date</Form.Label>
          }
          endDateLabel={
            <div className="d-flex flex-row">
              <Form.Label className="gvds-text--formLabel">End Date</Form.Label>
              {!model.isRecurringBillingCycle() && (
                <QuickfillButtons calculateEndPeriod={calculateEndPeriod} />
              )}
            </div>
          }
          className={className}
          startDate={model.startPeriod}
          endDate={model.endPeriod}
          disabledStartDate={disabled}
          disabledEndDate={model.isRecurringBillingCycle() || disabled}
          onChange={(startDate, endDate) => {
            onSubscriptionPeriodsChange(startDate, endDate);
            onDataChange();
          }}
          dateFormat="dd MMM yyyy"
          statusMetadata={
            isValidated
              ? getPeriodErrorMessage()
              : FormFieldStatusMetadata.getDefault()
          }
          minEndDate={minEndDate}
        />
        <GVDSTimezoneDisplay className="subscription__timezone-display" />
      </GVFormGroup>
      <Row>
        <Col lg={6}>
          <GVFormGroup controlId="selectReminderDate">
            <Form.Label className="gvds-text--formLabel">
              Reminder Date
            </Form.Label>
            <GVDSFormSingleDatePicker
              className="reminder-date-input"
              selected={model.reminderDate}
              placeholder="Select Reminder Date"
              onChange={(date) => {
                model.reminderDate = date;
                onDataChange();
              }}
              dateFormat="dd MMM yyyy"
              statusMetadata={
                isValidated && !model.isReminderDateValid()
                  ? FormFieldStatusMetadata.getError(
                      SubscriptionSharedUtils.getReminderDateValidationMessage(
                        model.reminderDate,
                        model.startPeriod,
                        model.endPeriod
                      )
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
            <GVDSTimezoneDisplay className="subscription__timezone-display" />
          </GVFormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SubscriptionPeriods;
