import { classificationGroups } from "./DashboardDataConstant";

class SubtopicDataHolder {
  constructor() {
    this.yearsIndex = [];
    this.unitId = "";

    this.data = [];
    this.cols = [];
    this.meta = {};
  }

  isDataForYearPresent(year) {
    return this.yearsIndex.includes(year);
  }

  updateUnit(unitId) {
    if (this.unitId !== unitId) {
      this.unitId = unitId;
      this.yearsIndex = [];
      this.data = [];
    }
  }

  updateDataForYear(year, data, cols, meta) {
    this.yearsIndex.push(year);
    this.cols = cols;
    this.data.push(...data);
    this.meta = { ...this.meta, ...meta };
  }
}

export default class SourceDataHolder {
  constructor() {
    this.dataHolderBySubtopic = {};
  }

  isDataForSubtopicYearPresent(subtopic, year, unitId) {
    if (this.dataHolderBySubtopic[subtopic] === undefined) {
      return false;
    }

    return (
      this.dataHolderBySubtopic[subtopic].isDataForYearPresent(year) &&
      this.dataHolderBySubtopic[subtopic].unitId === unitId
    );
  }

  isAllRequiredDataPresent(subtopics, years, selectedConfigHolder) {
    if (years.length === 0) {
      return false;
    }

    for (const subtopic of subtopics) {
      const unitForSubtopic = selectedConfigHolder.getUnitForSubtopic(subtopic);
      for (const year of years) {
        if (
          !this.isDataForSubtopicYearPresent(subtopic, year, unitForSubtopic)
        ) {
          return false;
        }
      }
    }

    return true;
  }

  getDataHolderForSubtopic(subtopic) {
    if (this.dataHolderBySubtopic[subtopic] === undefined) {
      return {};
    }

    return this.dataHolderBySubtopic[subtopic];
  }

  updateDataForSubtopicForYear(year, subtopic, data, cols, meta) {
    if (this.dataHolderBySubtopic[subtopic] === undefined) {
      this.dataHolderBySubtopic[subtopic] = new SubtopicDataHolder();
    }

    this.dataHolderBySubtopic[subtopic].updateDataForYear(
      year,
      data,
      cols,
      meta
    );
  }

  updateSubtopicUnit(subtopic, unitId) {
    if (this.dataHolderBySubtopic[subtopic] === undefined) {
      this.dataHolderBySubtopic[subtopic] = new SubtopicDataHolder();
    }

    this.dataHolderBySubtopic[subtopic].updateUnit(unitId);
  }

  getSubtopicClassificationValuesByGroup(subtopic) {
    if (this.dataHolderBySubtopic[subtopic] === undefined) {
      return null;
    }

    const subtopicDataHolder = this.dataHolderBySubtopic[subtopic];
    const subtopicMeta = subtopicDataHolder.meta;
    const classificationTuplesByEnvType = subtopicMeta["classifications"];
    const subtopicClassificationGroups =
      subtopicMeta["subtopicClassificationGroups"];

    if (
      classificationTuplesByEnvType === null ||
      classificationTuplesByEnvType === undefined
    ) {
      return null;
    }

    const envTypes = Object.keys(classificationTuplesByEnvType);

    const classificationValuesByGroup = envTypes.reduce(
      (valuesByGroup, envType) => {
        const envTypeClassificationTuples =
          classificationTuplesByEnvType[envType];

        envTypeClassificationTuples.forEach((tuple) => {
          const group = tuple[0];
          const value = tuple[1];

          const isClassificationGroupPartOfSubtopic =
            subtopicClassificationGroups.indexOf(group) !== -1;
          if (isClassificationGroupPartOfSubtopic) {
            if (valuesByGroup[group] === undefined) {
              valuesByGroup[group] = new Set();
            }

            valuesByGroup[group].add(value);
          }
        });

        return valuesByGroup;
      },
      {}
    );

    Object.keys(classificationValuesByGroup).forEach((group) => {
      classificationValuesByGroup[group] = Array.from(
        classificationValuesByGroup[group]
      );
    });

    classificationValuesByGroup[classificationGroups.environmentType] =
      envTypes;

    return classificationValuesByGroup;
  }
}
