import React from "react";
import { components } from "react-select";
import UNSDGService from "../../services/UNSDGService";
import GVDSFormMultiSelect from "../../gvds-components/Forms/GVDSFormMultiSelect";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import GVDSTag, { TagVariants } from "../../gvds-components/common/GVDSTag";

export const UNSDGOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="gvds-text--heading4">{props.label}</div>
      <div className="gvds-text--caption gvds-color--gray6">{props.data.description}</div>
    </components.Option>
  );
};

const UNSDGPicker = ({
  selectedUNSDGs,
  onChange,
  allUNSDGs,
  isInvalid,
  placeholder = "Select applicable UN SDGs...",
  invalidMessage = "Please select applicable UN SDGs",
  disabled = false,
}) => {
  return (
    <GVDSFormMultiSelect
      components={{ Option: UNSDGOption }}
      placeholder={placeholder}
      disabled={disabled}
      hideSelectedOptions={true}
      value={selectedUNSDGs.map((sdg) => {
        return {
          label: sdg.name,
          value: sdg.id,
          variant: GVDSTag.Variants.system,
        };
      })}
      onSelect={onChange}
      options={allUNSDGs
        .sort((sdg1, sdg2) =>
          UNSDGService.unsdgNameSortFn(sdg1.name, sdg2.name)
        )
        .map((sdg) => ({
          label: sdg.name,
          value: sdg.id,
          description: sdg.description,
        }))}
      className="unsdg-select"
      statusMetadata={
        isInvalid
          ? FormFieldStatusMetadata.getError(invalidMessage)
          : FormFieldStatusMetadata.getDefault()
      }
    />
  );
};

export default UNSDGPicker;
