import React from "react";
import { ReactComponent as InfoIconFile } from "../../assets/icons/gvds-icon-custom--info.svg";
import { ReactComponent as InfoFilledIconFile } from "../../assets/icons/gvds-icon-custom--info-filled.svg";
import { ReactComponent as ExpandIconFile } from "../../assets/icons/gvds-icon-custom--expand.svg";
import { ReactComponent as MultiBuildingIconFile } from "../../assets/icons/gvds-icon-custom--multiBuilding.svg";
import { ReactComponent as VisaIconFile } from "../../assets/icons/gvds-icon-custom--visa.svg";
import { ReactComponent as MasterCardIconFile } from "../../assets/icons/gvds-icon-custom--mastercard.svg";
import { ReactComponent as PoweredByStripeIconFile } from "../../assets/icons/gvds-icon-custom--powered-by-stripe.svg";
import { ReactComponent as WasteIconFile } from "../../assets/icons/gvds-icon-custom--waste.svg";

const iconFiles = {
  Info: InfoIconFile,
  InfoFilled: InfoFilledIconFile,
  Expand: ExpandIconFile,
  MultiBuilding: MultiBuildingIconFile,
  Visa: VisaIconFile,
  MasterCard: MasterCardIconFile,
  PoweredByStripe: PoweredByStripeIconFile,
  Waste: WasteIconFile,
};

const createIconComponent = (IconFile) =>
  React.forwardRef(({ className = "", ...props }, ref) => (
    <div
      ref={ref}
      className={`gvds-icon-container gvds-icon-container--custom ${className}`}
      {...props}
    >
      <IconFile />
    </div>
  ));

const GVDSCustomIcons = Object.fromEntries(
  Object.entries(iconFiles).map(([key, IconFile]) => [
    key,
    createIconComponent(IconFile),
  ])
);

export default GVDSCustomIcons;
