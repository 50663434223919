import {
  DateTimeUtils,
  NumberService,
} from "../../../../services/UtilsService";
import SubscriptionSharedUtils, {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  customEndPeriodRange,
  getSubscriptionPriceDisplay,
  SubscriptionBillingCycle,
  SubscriptionPricingType,
  SubscriptionStatusEnum,
  TAX_COUNTRY_NAME_SINGAPORE,
} from "../SubscriptionSharedUtils";
import moment from "moment/moment";
import { SUBSCRIPTION_PAYMENT } from "../SubscriptionPaymentBadge";
import { DAYS_BEFORE_OVERDUE_TO_SHOW_BANNER } from "../ContractSubscription/ContractSubscriptionModel";

const NEW_SITE_SUBSCRIPTION_ID = null;
export const SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE = null;

export class SiteContractSubscriptionDetailsModel {
  constructor({ id, contract, paymentType }) {
    this.id = id;
    this.contract = contract;
    this.paymentType = paymentType;
  }

  static fromDTO(requestDTO) {
    if (requestDTO) {
      return new SiteContractSubscriptionDetailsModel({
        id: requestDTO["id"],
        contract: requestDTO["contract"],
        paymentType: requestDTO["payment_type"],
      });
    } else {
      return null;
    }
  }
}

export default class SiteSubscriptionModel {
  constructor({
    id = NEW_SITE_SUBSCRIPTION_ID,
    site,
    startPeriod,
    endPeriod,
    reminderDate,
    pricingType,
    currencyId,
    priceAmount,
    proratedPriceAmount,
    features,
    comments,
    status,
    billingCycle,
    discountCode,
    paymentStatus,
    planId,
    autoRenew,
    nextPaymentDate,
    paymentDueDate,
    customPaymentDueDays,
    isTaxApplicable,
    inputDiscountId,
    stripeInvoiceLink,
    updatedBy,
    updatedOn,
    siteContractSubscriptionDetails,
    isFirstSubscription,
    successorSubscriptionId,
    isUnbilledRenewal,
    billingAdminEmail = null,
    paymentLink = null,
    contractSubscriptionModel = null,
  }) {
    this.id = id;
    this.site = site;
    this.startPeriod = startPeriod;
    this.endPeriod = endPeriod;
    this.reminderDate = reminderDate;
    this.pricingType = pricingType;
    this.currencyId = currencyId;
    this.priceAmount = priceAmount;
    this.proratedPriceAmount = proratedPriceAmount;
    this.features = features;
    this.comments = comments;
    this.status = status;
    this.billingCycle = billingCycle;
    this.discountCode = discountCode;
    this.paymentStatus = paymentStatus;
    this.planId = planId;
    this.autoRenew = autoRenew;
    this.nextPaymentDate = nextPaymentDate;
    this.paymentDueDate = paymentDueDate;
    this.customPaymentDueDays = customPaymentDueDays;
    this.isTaxApplicable = !!isTaxApplicable;
    this.inputDiscountId = inputDiscountId;
    this.paymentLink = paymentLink;
    this.stripeInvoiceLink = stripeInvoiceLink;
    this.updatedBy = updatedBy;
    this.updatedOn = updatedOn;
    this.siteContractSubscriptionDetails = siteContractSubscriptionDetails;
    this.contractSubscriptionModel = contractSubscriptionModel;
    this.isFirstSubscription = isFirstSubscription;
    this.successorSubscriptionId = successorSubscriptionId;
    this.isUnbilledRenewal = isUnbilledRenewal;
    this.billingAdminEmail = billingAdminEmail;
  }

  get canDeleteFromContractSubscription() {
    return (
      this.id === NEW_SITE_SUBSCRIPTION_ID ||
      this.contractSubscriptionModel?.status === SubscriptionStatusEnum.UPCOMING
    );
  }

  get contract() {
    return this.siteContractSubscriptionDetails.contract;
  }

  get hasCustomPaymentDueDay() {
    return !!this.customPaymentDueDays;
  }

  getPaymentDueMessage() {
    return this.paymentDueDate
      ? `${getSubscriptionPriceDisplay(
          this.currencyId ?? "",
          NumberService.format(this.proratedPriceAmount ?? this.priceAmount)
        )} on ${DateTimeUtils.formatUTCDate(
          SubscriptionSharedUtils.getPaymentDueDate(
            this.hasCustomPaymentDueDay,
            this.paymentDueDate,
            this.startPeriod
          )
        )}`
      : null;
  }

  getNextPaymentMessage() {
    return this.nextPaymentDate
      ? `${this.currencyId ?? ""} ${NumberService.format(
          this.priceAmount
        )} on ${DateTimeUtils.formatUTCDate(this.nextPaymentDate)}`
      : null;
  }

  static fromDTO(requestDTO, contractSubscriptionModel = null) {
    const lastUpdater = requestDTO["updated_by"] ?? requestDTO["created_by"];
    const lastUpdatedBy = !!lastUpdater
      ? lastUpdater["full_name"] || lastUpdater["email"]
      : "-";
    const lastUpdatedOn = requestDTO["updated_on"] ?? requestDTO["created_on"];

    return new SiteSubscriptionModel({
      id: requestDTO["id"],
      site: requestDTO["site"],
      startPeriod: requestDTO["start_period"]
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            requestDTO["start_period"]
          )
        : null,
      endPeriod: requestDTO["end_period"]
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            requestDTO["end_period"]
          )
        : null,
      reminderDate: requestDTO["reminder_date"]
        ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            requestDTO["reminder_date"]
          )
        : null,
      pricingType: requestDTO["pricing_type"],
      currencyId: requestDTO["currency"] ? requestDTO["currency"]["id"] : null,
      priceAmount: requestDTO["price_amount"],
      proratedPriceAmount: requestDTO["prorated_price_amount"],
      features: requestDTO["features"],
      comments: requestDTO["comments"],
      status: requestDTO["status"],
      billingCycle: requestDTO["billing_cycle"],
      discountCode: requestDTO["discount_code"],
      paymentStatus: requestDTO["payment_status"],
      planId: requestDTO["plan_id"],
      autoRenew: requestDTO["auto_renew"] ?? false,
      paymentDueDate: requestDTO["payment_due_date"],
      nextPaymentDate: requestDTO["next_payment_date"],
      customPaymentDueDays: requestDTO["custom_payment_due_days"],
      isTaxApplicable: requestDTO["is_tax_applicable"],
      paymentLink: requestDTO["payment_link"],
      stripeInvoiceLink: requestDTO["stripe_invoice_link"],
      updatedBy: lastUpdatedBy,
      updatedOn:
        DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(lastUpdatedOn),
      siteContractSubscriptionDetails:
        SiteContractSubscriptionDetailsModel.fromDTO(
          requestDTO["contract_subscription"]
        ),
      contractSubscriptionModel: contractSubscriptionModel,
      isFirstSubscription: requestDTO["is_first_subscription"],
      successorSubscriptionId: requestDTO["successor_subscription_id"],
      isUnbilledRenewal: requestDTO["is_unbilled_renewal"],
      billingAdminEmail: requestDTO["billing_admin_email"],
    });
  }

  isNew() {
    return this.id === NEW_SITE_SUBSCRIPTION_ID;
  }

  isStartDateValid() {
    return (
      this.startPeriod &&
      (this.endPeriod ? this.startPeriod <= this.endPeriod : true)
    );
  }

  isEndDateValid() {
    return (
      this.endPeriod &&
      (this.startPeriod ? this.endPeriod >= this.startPeriod : true)
    );
  }

  isReminderDateValid() {
    return (
      this.reminderDate &&
      (this.endPeriod ? this.reminderDate <= this.endPeriod : true) &&
      (this.startPeriod ? this.reminderDate >= this.startPeriod : true)
    );
  }

  isPriceValid() {
    return this.isCurrencyValid() && this.isPriceAmountValid();
  }

  isPricingTypeValid() {
    return this.pricingType;
  }

  isCurrencyValid() {
    return !this.isPaidSubscription() || this.currencyId;
  }

  isPriceAmountValid() {
    return (
      !this.isPaidSubscription() ||
      (this.priceAmount !== null && this.priceAmount >= 0)
    );
  }

  isFeaturesValid() {
    return this.features.length > 0;
  }

  isPaidSubscription() {
    return this.pricingType === SubscriptionPricingType.PAID;
  }

  isPlanValid() {
    return this.planId;
  }

  isBillingCycleValid() {
    return !this.isPaidSubscription() || this.billingCycle;
  }

  isRecurringBillingCycle() {
    return (
      this.billingCycle &&
      [
        SubscriptionBillingCycle.MONTHLY,
        SubscriptionBillingCycle.YEARLY,
      ].includes(this.billingCycle)
    );
  }

  isUnpaidSubscriptionOnDue() {
    const paymentDueDate = this.hasCustomPaymentDueDay
      ? this.paymentDueDate
      : this.startPeriod;
    return (
      this.paymentStatus === SUBSCRIPTION_PAYMENT.UNPAID &&
      DateTimeUtils.getRemainingDaysBefore(paymentDueDate) <=
        DAYS_BEFORE_OVERDUE_TO_SHOW_BANNER
    );
  }

  underContractIsFeaturesBreakFromContract() {
    return this.features !== SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE;
  }

  underContractIsFromContractSubscription() {
    return !!this.siteContractSubscriptionDetails;
  }

  underContractIsPriceAmountValid() {
    return !(
      this.priceAmount === undefined ||
      this.priceAmount === null ||
      this.priceAmount === "" ||
      this.priceAmount < 0
    );
  }

  underContractIsSubscriptionDifferentFromContract() {
    return (
      this.startPeriod !== SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE ||
      this.endPeriod !== SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE ||
      this.features !== SITE_CONTRACT_SUBSCRIPTION_FOLLOW_CONTRACT_VALUE
    );
  }

  getDuplicateModel(isTaxApplicable, orderedPackagePlans) {
    const billingCycle = this.billingCycle;
    const pricingType = this.pricingType;

    const subscriptionPlan = orderedPackagePlans.find(
      (plan) => plan.package_plan_id === this.planId
    );

    const isCustomPlan =
      subscriptionPlan.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;

    const price = subscriptionPlan.prices.find(
      (price) => price.billing_cycle === billingCycle
    );
    let priceAmount;
    let currencyId;
    if (pricingType === SubscriptionPricingType.FREE) {
      priceAmount = null;
      currencyId = null;
    } else if (!isCustomPlan && price) {
      priceAmount = price.price_in_decimal;
      currencyId = price.currency.toUpperCase();
    } else {
      priceAmount = 0;
      currencyId = null;
    }

    const newStartPeriod = moment(this.endPeriod).add(1, "day").toDate();

    const duplicatedModel = new SiteSubscriptionModel({
      id: null,
      site: this.site,
      startPeriod: newStartPeriod,
      endPeriod: SubscriptionSharedUtils.calculateEndPeriod(
        customEndPeriodRange.oneYear,
        newStartPeriod
      ),
      reminderDate: null,
      pricingType: pricingType,
      currencyId: currencyId,
      priceAmount: priceAmount,
      features: this.features,
      comments: this.comments,
      billingCycle: billingCycle,
      planId: this.planId,
      autoRenew: null,
      customPaymentDueDays: this.customPaymentDueDays,
      isTaxApplicable: isTaxApplicable,
    });

    if (
      [
        SubscriptionBillingCycle.MONTHLY,
        SubscriptionBillingCycle.YEARLY,
      ].includes(billingCycle)
    ) {
      duplicatedModel.autoRenew = true;

      const endPeriod = SubscriptionSharedUtils.getEndPeriodBasedOnBillingCycle(
        duplicatedModel,
        billingCycle
      );
      if (endPeriod !== null) {
        duplicatedModel.endPeriod = endPeriod;
      }
    } else {
      duplicatedModel.autoRenew = false;
    }

    return duplicatedModel;
  }

  underContractGetDuplicateModelForContractSubscription(
    currencyId,
    siteSubPriceAmount,
    autoRenew,
    customPaymentDueDays,
    isDecentralised,
    allSites
  ) {
    let isTaxApplicable = null;

    if (isDecentralised) {
      const siteId = this.site.id;
      const siteData = allSites.find((site) => site.id === siteId);
      isTaxApplicable = siteData.country_name === TAX_COUNTRY_NAME_SINGAPORE;
    }

    return new SiteSubscriptionModel({
      id: null,
      site: this.site,
      startPeriod: null,
      endPeriod: null,
      reminderDate: null,
      pricingType: this.pricingType,
      currencyId: currencyId,
      priceAmount: siteSubPriceAmount ?? this.priceAmount,
      features: this.features,
      comments: this.comments,
      autoRenew: autoRenew,
      customPaymentDueDays: customPaymentDueDays,
      isTaxApplicable: isTaxApplicable,
      paymentStatus:
        this.pricingType === SubscriptionPricingType.FREE
          ? SUBSCRIPTION_PAYMENT.FREE
          : SUBSCRIPTION_PAYMENT.UNPAID,
    });
  }

  toRequestPayload() {
    return {
      site_id: this.site?.id,
      start_period: DateTimeUtils.getUTCISOString(this.startPeriod),
      end_period: DateTimeUtils.getUTCISOString(this.endPeriod),
      reminder_date: DateTimeUtils.getUTCISOString(this.reminderDate),
      pricing_type: this.pricingType,
      currency_id: this.isPaidSubscription() ? this.currencyId : null,
      price_amount: this.isPaidSubscription() ? this.priceAmount : null,
      feature_ids: this.features ? this.features.map((f) => f.id) : null,
      comments: this.comments,
      billing_cycle: this.isPaidSubscription() ? this.billingCycle : null,
      plan_id: this.planId,
      auto_renew: this.isPaidSubscription() ? this.autoRenew : false,
      custom_payment_due_days: this.customPaymentDueDays,
      is_tax_applicable: this.isTaxApplicable,
      input_discount_id: this.inputDiscountId,
    };
  }
}
