import React, { useContext, useEffect, useState } from "react";
import UserInventoryContext, {
  TYPE_DELIMITER,
} from "../../context/UserInventoryContext";
import TaskManagementService from "../../services/TaskManagementService";
import ToastContext from "../../context/ToastContext";
import FilterSearchBox from "../../gvds-components/common/FilterSearchBox";
import uniq from "lodash/uniq";
import LoadingSpinner from "../common/LoadingSpinner";
import Form from "react-bootstrap/Form";
import {
  DateTimeUtils,
  StringUtils,
  UserUtils,
} from "../../services/UtilsService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import UserProfileContext from "../../context/UserProfileContext";
import InputTaskModal from "./InputTaskModal";
import PermissionsContext from "../../context/PermissionsContext";
import { useLocation } from "react-router-dom";
import {
  getQueryHandler,
  MODAL_QUERY_KEY,
  removeParamFromQuery,
} from "../common/QueryHandler";
import { Popover } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import OtherActionDropdownToggle from "../common/OtherActionDropdownToggle";
import ViewArchivedTasks from "./ViewArchivedTasks";
import _ from "lodash";
import UserAvatar from "../common/UserAvatar";
import TableHeaderColumnWithSortButton from "../common/TableHeaderColumnWithSortButton";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import Spacer from "../../gvds-components/Layout/Spacer";
import GVDSPagination from "../../gvds-components/Table/Controls/GVDSPagination";
import {
  IconArchiveFilled,
  IconArchiveOff,
  IconCirclePlus,
  IconFileText,
  IconHourglassLow,
  IconListCheck,
  IconSearch,
} from "@tabler/icons-react";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import GVDSTableDisplay from "../../gvds-components/Table/GVDSTableDisplay";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import { useTranslation } from "react-i18next";
import {
  getTranslatedTaskStatus,
  TASK_MANAGEMENT_ARCHIVE_ACTION,
  TASK_MANAGEMENT_STATUS_COLOR,
} from "./SharedTaskManagementUtil";

const TASK_MODAL_QUERY_KEY = "tm";

const getCreator = (task) => {
  return (
    <>
      {task.created_by && (
        <span className="task-creator">
          <UserAvatar
            fullName={task.created_by.full_name}
            userEmail={task.created_by.email}
          />
        </span>
      )}
    </>
  );
};

const TaskDescriptionIcon = () => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover>
          <Popover.Body>
            {t("landing-page.widget-task-mgmt.label-description-written")}
          </Popover.Body>
        </Popover>
      }
    >
      <GVDSIcon Icon={IconFileText} />
    </OverlayTrigger>
  );
};

const getFirst3Assignees = (assignees) => {
  return assignees.slice(0, 3).map((a, index) => {
    return (
      <span key={index} className="task-assignee_avatar">
        <UserAvatar fullName={a.full_name} userEmail={a.email} />
      </span>
    );
  });
};

const getRemainingAssignees = (assignees) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover>
          <Popover.Body>
            {assignees.slice(3, assignees.length).map((a) => (
              <div>{UserUtils.getFullNameOrEmail(a.full_name, a.email)}</div>
            ))}
          </Popover.Body>
        </Popover>
      }
    >
      <span>+{assignees.length - 3}</span>
    </OverlayTrigger>
  );
};

const ViewAllTask = ({ isArchivedTasks, setTaskCount }) => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const toastContext = useContext(ToastContext);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [allTasks, setAllTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [sortedTasks, setSortedTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [filterKeys, setFilterKeys] = useState({});
  const [showMyTasksOnly, setShowMyTasksOnly] = useState(false);
  const [sortKeys, setSortKeys] = useState({});
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);

  const [showInputTaskModal, setShowInputTaskModal] = useState(false);
  const [availableAssignees, setAvailableAssignees] = useState([]);

  const [showArchivedTaskModal, setShowArchivedTaskModal] = useState(false);
  const [isUnarchiving, setIsUnarchiving] = useState(false);

  const [showNoTaskFoundPrompt, setShowNoTaskFoundPrompt] = useState(false);
  const [noTaskFoundPromptTitle, setNoTaskFoundPromptTitle] = useState(null);
  const [noTaskFoundPromptDescription, setNoTaskFoundPromptDescription] =
    useState(null);

  const sortKeyPriorities = { status_order: null, deadline: null };

  useEffect(() => {
    loadTasks();
  }, [userInventory.isLoadingInventory.get, selectedInventory]);

  useEffect(() => {
    const currentUserId = userProfileContext.getUserProfile().id;
    if (!isLoading) {
      const filtered = allTasks.filter((t) => {
        let matchFilter = true;

        if (searchText.length > 0) {
          matchFilter =
            matchFilter &&
            t.name.toLowerCase().includes(searchText.toLowerCase());
        }
        if (filterKeys.status && filterKeys.status.length > 0) {
          matchFilter = matchFilter && filterKeys.status.indexOf(t.status) >= 0;
        }
        if (showMyTasksOnly) {
          matchFilter =
            matchFilter &&
            t.assignees.some((a) => {
              return a.id === currentUserId;
            });
        }
        return matchFilter;
      });

      setFilteredTasks(filtered);

      const sortKeysByPriority = _.chain(sortKeys)
        .keys()
        .sortBy([(key) => sortKeyPriorities[key]])
        .value();

      let sortedData = _.orderBy(
        filtered,
        sortKeysByPriority,
        sortKeysByPriority.map((k) => sortKeys[k])
      );
      if (isArchivedTasks) {
        sortedData = sortedData.slice(startIndex - 1, endIndex);
      }
      setSortedTasks(sortedData);

      if (!isArchivedTasks) {
        const taskCounts = [
          allTasks.length + " task" + (allTasks.length > 1 ? "s" : ""),
        ];
        if (allTasks.length !== filtered.length) {
          taskCounts.push(filtered.length + " filtered");
        }
        setTaskCount(taskCounts.join(", "));
      }
    }
  }, [
    isLoading,
    startIndex,
    endIndex,
    allTasks,
    searchText,
    showMyTasksOnly,
    filterKeys,
    sortKeys,
  ]);

  const onPaginationChange = (start, end) => {
    setStartIndex(start);
    setEndIndex(end);
  };

  const showNoTaskFound = async (taskId) => {
    const task = await TaskManagementService.getTask(
      selectedInventory.type,
      selectedInventory.id,
      taskId
    );
    if (task) {
      setNoTaskFoundPromptTitle(
        t("landing-page.widget-task-mgmt.no-task-archived-title")
      );
      setNoTaskFoundPromptDescription(
        t("landing-page.widget-task-mgmt.no-task-archived-description")
      );
    } else {
      setNoTaskFoundPromptTitle(
        t("landing-page.widget-task-mgmt.no-task-missing-title")
      );
      setNoTaskFoundPromptDescription(
        t("landing-page.widget-task-mgmt.no-task-missing-description")
      );
    }
    setShowNoTaskFoundPrompt(true);
  };

  const openModalByQueryKey = (tasks) => {
    const modalQuery = getQueryHandler(location).get(MODAL_QUERY_KEY);
    if (modalQuery !== null) {
      const [taskKey, taskId] = modalQuery.split(TYPE_DELIMITER);
      if (taskKey === TASK_MODAL_QUERY_KEY) {
        const task = tasks.find((t) => t.id === taskId);
        if (task) {
          showEditModal(task);
        } else {
          showNoTaskFound(taskId);
        }
      }
    }
  };

  const onCloseModal = () => {
    removeParamFromQuery(location, MODAL_QUERY_KEY);
    setShowInputTaskModal(false);
  };

  const onCloseNoTaskFoundPrompt = () => {
    removeParamFromQuery(location, MODAL_QUERY_KEY);
    setShowNoTaskFoundPrompt(false);
  };

  const loadTasks = () => {
    if (selectedInventory) {
      setIsLoading(true);
      const tasksPromise = TaskManagementService.getAllTask(
        selectedInventory.type,
        selectedInventory.id,
        isArchivedTasks
      );
      const assigneesPromise = TaskManagementService.getAvailableAssignees(
        selectedInventory.type,
        selectedInventory.id
      );
      Promise.all([tasksPromise, assigneesPromise])
        .then((results) => {
          setAllTasks(results[0]);
          setAvailableAssignees(results[1]);
          setIsLoading(false);
          openModalByQueryKey(results[0]);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to load tasks.</span>);
        });
    } else {
      setAllTasks([]);
      setAvailableAssignees([]);
    }
  };

  const showEditModal = (task) => {
    setSelectedTask(task);
    setShowInputTaskModal(true);
  };

  const showCreateModal = () => {
    setSelectedTask(null);
    setShowInputTaskModal(true);
  };

  const getActionButtons = () => {
    return (
      <>
        <Dropdown className="archived-tasks-dropdown">
          <Dropdown.Toggle as={OtherActionDropdownToggle} />
          <Dropdown.Menu>
            <Dropdown.Item
              key="archived-task"
              href="#"
              onClick={() => setShowArchivedTaskModal(true)}
            >
              <GVDSIcon Icon={IconArchiveFilled} className="me-2" />
              {t("landing-page.widget-task-mgmt.label-archived-tasks")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <GVDSButton
          className="btn-create-task"
          variant={buttonVariant.primary}
          onClick={() => showCreateModal()}
          icon={<GVDSIcon Icon={IconCirclePlus} />}
          text={t("landing-page.widget-task-mgmt.button-create-task")}
        />
      </>
    );
  };

  const sortedStatuses = uniq(allTasks.map((t) => t.status)).sort(
    TaskManagementService.taskStatusSortFn
  );

  const statusValueByTranslatedStatus = sortedStatuses.reduce((acc, status) => {
    acc[getTranslatedTaskStatus(t, status)] = status;
    return acc;
  }, {});

  const getHeader = () => {
    return (
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          className="task-search-box"
          placeholder={t("landing-page.widget-task-mgmt.searchbox-placeholder")}
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={sortedStatuses.map((status) =>
            getTranslatedTaskStatus(t, status)
          )}
          prefix={t("landing-page.widget-task-mgmt.label-status")}
          onChange={(filterKs) => {
            const originalStatusValues = filterKs.map(
              (key) => statusValueByTranslatedStatus[key]
            );
            setFilterKeys({ ...filterKeys, status: originalStatusValues });
          }}
        />
        <div className="only-my-task-switch">
          <span>{t("landing-page.widget-task-mgmt.label-only-my-task")}</span>
          <Form.Check
            type="switch"
            className="ms-2"
            onChange={() => setShowMyTasksOnly(!showMyTasksOnly)}
            checked={showMyTasksOnly}
          />
        </div>
        <Spacer />
        {!permissionCtx.isLoadingPermissions &&
          !isArchivedTasks &&
          getActionButtons()}
        {isArchivedTasks && (
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={filteredTasks.length}
            onChange={onPaginationChange}
          />
        )}
      </GVDSTableCtrlContainer>
    );
  };

  const unarchiveTask = async (task) => {
    const selectedInventory = userInventory.selectedInventory.get;

    try {
      setIsUnarchiving(true);
      await TaskManagementService.setArchiveStatus(
        selectedInventory.type,
        selectedInventory.id,
        task.id,
        TASK_MANAGEMENT_ARCHIVE_ACTION.UNARCHIVED
      );
      toastContext.addSuccessToast(
        <span>
          <em>Unarchived "{task.name}"</em> successfully
        </span>
      );
      setIsUnarchiving(false);
      loadTasks();
    } catch (error) {
      toastContext.addFailToast(<span>Failed to unarchive task.</span>);
      setIsUnarchiving(false);
    }
  };

  const getNoTaskFoundPrompt = () => {
    return (
      <GVDSModal
        title={noTaskFoundPromptTitle}
        size={GVDSModal.Size.small}
        show={showNoTaskFoundPrompt}
        onHide={onCloseNoTaskFoundPrompt}
      >
        <>
          <GVDSModal.Body>
            <div>{noTaskFoundPromptDescription}</div>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.tertiary}
              onClick={onCloseNoTaskFoundPrompt}
              text={t("shared-modal.footer.close")}
            />
          </GVDSModal.Footer>
        </>
      </GVDSModal>
    );
  };

  const getContent = () => {
    if (allTasks.length === 0) {
      return (
        <div className="task-management__no-content">
          <div className="gvds-empty-content--icon">
            <GVDSIcon Icon={IconListCheck} />
          </div>
          <div className="gvds-empty-content--title">
            {t("landing-page.widget-task-mgmt.label-no-tasks")}
          </div>
          <div>{t("landing-page.widget-task-mgmt.label-no-tasks-message")}</div>
        </div>
      );
    } else {
      if (filteredTasks.length === 0) {
        return (
          <div className="task-management__no-content">
            <div className="gvds-empty-content--icon">
              <GVDSIcon Icon={IconSearch} />
            </div>
            <div className="gvds-empty-content--title">
              {t("landing-page.widget-task-mgmt.label-no-task-found")}
            </div>
            <div>
              {t("landing-page.widget-task-mgmt.label-no-task-found-message")}
            </div>
          </div>
        );
      } else {
        const onRowClick = (e, task) => {
          e.preventDefault();
          if (!isArchivedTasks) {
            showEditModal(task);
          }
        };

        return (
          <div className="task-management__table-container">
            <GVDSTableDisplay className="task-management__table">
              <thead>
                <tr>
                  <th className="gvds-table-cell__row-number-header">
                    {t("shared-table-header.numbering")}
                  </th>
                  <th className="task-column">
                    {t("landing-page.widget-task-mgmt.table-header.task")}
                  </th>
                  <TableHeaderColumnWithSortButton
                    className="status-column"
                    headerTitle={t(
                      "landing-page.widget-task-mgmt.label-status"
                    )}
                    dataKey="status_order"
                    sortKeys={sortKeys}
                    setSortKeys={setSortKeys}
                  />
                  <TableHeaderColumnWithSortButton
                    className="deadline-column"
                    headerTitle={t(
                      "landing-page.widget-task-mgmt.label-deadline"
                    )}
                    dataKey="deadline"
                    sortKeys={sortKeys}
                    setSortKeys={setSortKeys}
                  />
                  <th className="assignees-column">
                    {t(
                      "landing-page.widget-task-mgmt.table-header.assigned-to"
                    )}
                  </th>
                  <th className="creator-column">
                    {t("landing-page.widget-task-mgmt.table-header.creator")}
                  </th>
                  {isArchivedTasks && (
                    <th className="action-column">
                      {t("shared-input-label.actions")}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {sortedTasks.map((task, index) => {
                  return (
                    <tr
                      key={task.id}
                      className={isArchivedTasks ? "" : "is-clickable"}
                    >
                      <td>
                        <div className="gvds-table-cell__row-number">
                          {index + 1}
                        </div>
                      </td>
                      <td
                        className="task-column__content"
                        onClick={(e) => onRowClick(e, task)}
                      >
                        <div className="d-flex flex-row">
                          <span className="task-name__content me-1">
                            {task.name}
                          </span>
                          {task.description.length > 0 && (
                            <TaskDescriptionIcon />
                          )}
                        </div>
                      </td>
                      <td onClick={(e) => onRowClick(e, task)}>
                        <StatusLabel
                          color={TASK_MANAGEMENT_STATUS_COLOR[task.status]}
                        >
                          {getTranslatedTaskStatus(t, task.status)}
                        </StatusLabel>
                      </td>
                      <td onClick={(e) => onRowClick(e, task)}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Popover>
                              <Popover.Body>
                                {StringUtils.capitaliseWord(
                                  DateTimeUtils.getDeadlineDueMessage(
                                    task.deadline
                                  )
                                )}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <div>
                            {DateTimeUtils.getRemainingDaysBefore(
                              task.deadline
                            ) < 1 && (
                              <div className="gvds-color--error d-flex align-items-center">
                                <GVDSIcon Icon={IconHourglassLow} />
                                {DateTimeUtils.formatUTCDate(task.deadline)}
                              </div>
                            )}
                            {DateTimeUtils.getRemainingDaysBefore(
                              task.deadline
                            ) > 0 && DateTimeUtils.formatUTCDate(task.deadline)}
                          </div>
                        </OverlayTrigger>
                      </td>
                      <td onClick={(e) => onRowClick(e, task)}>
                        {getFirst3Assignees(task.assignees)}
                        {task.assignees.length - 3 > 0 &&
                          getRemainingAssignees(task.assignees)}
                      </td>
                      <td onClick={(e) => onRowClick(e, task)}>
                        {getCreator(task)}
                      </td>
                      {isArchivedTasks && (
                        <td>
                          <GVDSIconButton
                            variant={iconButtonVariant.tertiary}
                            onClick={() => {
                              unarchiveTask(task);
                            }}
                            icon={<GVDSIcon Icon={IconArchiveOff} />}
                            tooltipText={t(
                              "landing-page.widget-task-mgmt.label-unarchive"
                            )}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </GVDSTableDisplay>
          </div>
        );
      }
    }
  };

  const onArchivedTaskModalClose = () => {
    if (!isUnarchiving) {
      setShowArchivedTaskModal(false);
      loadTasks();
    }
  };

  return (
    <div className="task-management__container">
      {getHeader()}
      {isLoading ? <LoadingSpinner /> : getContent()}
      <InputTaskModal
        show={showInputTaskModal}
        onClose={() => onCloseModal()}
        availableAssignees={availableAssignees}
        onDoneInput={() => loadTasks()}
        currentTask={selectedTask}
      />
      <ViewArchivedTasks
        show={showArchivedTaskModal}
        onClose={onArchivedTaskModalClose}
      />
      {getNoTaskFoundPrompt()}
    </div>
  );
};

export default ViewAllTask;
