import LoadingSpinner from "./LoadingSpinner";

const LoadingSpinnerInFullPage = () => {
  return (
    <div className="full-page-loading-spinner__container">
      <LoadingSpinner />
    </div>
  );
};

export default LoadingSpinnerInFullPage;
