import { DateTimeUtils } from "../../../../services/UtilsService";
import React, { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../../common/LoadingSpinner";
import Container from "react-bootstrap/Container";
import ToastContext from "../../../../context/ToastContext";
import RiskAssessmentReportService from "../../../../services/Report/RiskAssessmentReportService";
import withAuthentication from "../../../HOC/withAuthentication";
import {
  downloadFromPresignedS3Url,
  MultipleFilesUploader,
} from "../../../common/FileAttachments";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../../gvds-components/Table/GVDSTable";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";
import GVDSTextButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSTextButtonWithLoadingAction";

const emptyRecord = [
  {
    filename: "-",
    filePath: "-",
    created_by: "-",
    created_on: "-",
  },
];

const EMPTY_RECORD_FILENAME = "-";

const validateFileExtension = (uploadFiles) => {
  const allowedFileExtension = "xlsx";

  if (uploadFiles.length === 1) {
    const splittedFileName = uploadFiles[0].name.split(".");
    const fileExtension = splittedFileName[splittedFileName.length - 1];
    return fileExtension === allowedFileExtension;
  } else {
    return false;
  }
};

const UploadPolicyMastersheetModal = ({
  show,
  onClose,
  currentRecord,
  onUpload,
  unprocessedPolicyRows,
  setUnprocessedPolicyRows,
}) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);

  const onModalClose = () => {
    setUploadFiles([]);
    onClose();
  };

  const savePolicyMastersheet = () => {
    setIsLoading(true);
    const httpRequest = currentRecord.id
      ? RiskAssessmentReportService.updatePolicyMastersheet(
          currentRecord.id,
          uploadFiles[0]
        )
      : RiskAssessmentReportService.savePolicyMastersheet(uploadFiles[0]);

    httpRequest
      .then((response) => {
        if (response.length > 0) {
          setUnprocessedPolicyRows(response);
          toastContext.addFailToast(
            <span>
              Uploading Policy Mastersheet Data failed partially. Please check
              the error list for unprocessed rows.
            </span>
          );
          setUploadFiles([]);
        } else {
          toastContext.addSuccessToast(<span>File updated successfully.</span>);
          onModalClose();
        }
        setIsLoading(false);
        onUpload();
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(<span>Failed to upload File.</span>);
      });
  };
  let content;
  if (isLoading) {
    content = (
      <div className="p-3">
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <>
        <GVDSModal.Body>
          <div className="body-2 fst-italic pt-3 pb-3">
            Note: This action will replace the existing file for Policy
            Mastersheet.
          </div>
          {unprocessedPolicyRows.length > 0 && (
            <GVDSBanner
              title={"Some rows were not processed"}
              variant={GVDSBanner.Variants.error}
            >
              <ul>
                {unprocessedPolicyRows.map((row, index) => (
                  <li key={index}>{row}</li>
                ))}
              </ul>
            </GVDSBanner>
          )}
          <MultipleFilesUploader
            files={uploadFiles}
            setFiles={setUploadFiles}
          />
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={onModalClose}
            text="Cancel"
          />

          <GVDSButton
            variant={buttonVariant.primary}
            disabled={
              uploadFiles.length !== 1 || !validateFileExtension(uploadFiles)
            }
            onClick={savePolicyMastersheet}
            text="Upload"
          />
        </GVDSModal.Footer>
      </>
    );
  }

  return (
    <GVDSModal
      show={show}
      onHide={onModalClose}
      title="Upload Policy Mastersheet File"
      size={GVDSModal.Size.small}
    >
      {content}
    </GVDSModal>
  );
};

const DeletePolicyMastersheetModal = ({
  show,
  onClose,
  deleteSelectedRecord,
}) => {
  return (
    <GVDSModal
      show={show}
      onHide={onClose}
      title="Delete Policy Mastersheet File"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        This action cannot be undone. Are you sure?
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onClose}
          text="Cancel"
        />

        <GVDSButton
          variant={buttonVariant.destructive_primary}
          onClick={deleteSelectedRecord}
          text="Yes, Delete."
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const ViewAllPolicyMastersheet = () => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);

  const [isLoading, setIsLoading] = useState(true);
  const [allPolicyMastersheetRecords, setAllPolicyMastersheetRecords] =
    useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [unprocessedPolicyRows, setUnprocessedPolicyRows] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(allPolicyMastersheetRecords, null, {
    filename: SORTING_TYPES.asc,
  });

  useEffect(() => {
    loadRecords();
  }, []);

  const loadRecords = () => {
    setIsLoading(true);
    RiskAssessmentReportService.getPolicyMastersheetFiles()
      .then((records) => {
        if (records.length > 0) {
          setAllPolicyMastersheetRecords(records);
        } else {
          setAllPolicyMastersheetRecords(emptyRecord);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load Policy Mastersheet File records</span>
        );
      });
  };

  const onUploadModalOpen = (record) => {
    setShowUploadModal(true);
    setSelectedRecord(record);
  };

  const onUploadModalClose = () => {
    setShowUploadModal(false);
    setSelectedRecord({});
    setUnprocessedPolicyRows([]);
  };

  const onDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const downloadFile = async (filepath) => {
    try {
      const selectedInventory = userInventory.selectedInventory.get;
      const params = {
        resource_type: selectedInventory.type,
        resource_id: selectedInventory.id,
      };
      await downloadFromPresignedS3Url(filepath, params);
    } catch (e) {
      toastContext.addFailToast(<span>Failed to download file</span>);
    }
  };

  const deleteSelectedRecord = () => {
    RiskAssessmentReportService.deletePolicyMastersheet(selectedRecord.id)
      .then(() => {
        toastContext.addSuccessToast(
          <span>Policy Mastersheet file was successfully deleted.</span>
        );
        loadRecords();
        onDeleteModalClose();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to delete file.</span>);
      });
  };

  const columns = [
    {
      header: "File Name",
      dataKey: "filename",
      renderer: (record) =>
        record.filename === EMPTY_RECORD_FILENAME ? (
          record.filename
        ) : (
          <GVDSTextButtonWithLoadingAction
            onClickAsyncFunc={async () => await downloadFile(record.filepath)}
            text={record.filename}
          />
        ),
    },
    {
      header: "Uploaded By",
      dataKey: "updated_by",
      renderer: (record) => (
        <div>
          {record.created_by}
          {record.last_status_update_on !== "-" && (
            <span className="caption fst-italic">
              &nbsp;(
              {DateTimeUtils.formatUTCDate(record.last_status_update_on)})
            </span>
          )}
        </div>
      ),
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (record) => {
        return (
          <div>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => onUploadModalOpen(record)}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                setSelectedRecord(record);
                setShowDeleteModal(true);
              }}
              disabled={!record.id}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          </div>
        );
      },
    },
  ];

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <>
        <GVDSTableCtrlContainer>
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        </GVDSTableCtrlContainer>
        <GVDSTable
          className="policy-mastersheet-table"
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
        />
        {allPolicyMastersheetRecords.length === 0 && (
          <div className="table__no_content">No policy available</div>
        )}
        <UploadPolicyMastersheetModal
          show={showUploadModal}
          onClose={onUploadModalClose}
          currentRecord={selectedRecord}
          onUpload={loadRecords}
          unprocessedPolicyRows={unprocessedPolicyRows}
          setUnprocessedPolicyRows={setUnprocessedPolicyRows}
        />
        <DeletePolicyMastersheetModal
          show={showDeleteModal}
          onClose={onDeleteModalClose}
          deleteSelectedRecord={deleteSelectedRecord}
        />
      </>
    );
  }

  return <Container fluid>{content}</Container>;
};

export default withAuthentication(ViewAllPolicyMastersheet);
