import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import UserInventoryContext from "../../../context/UserInventoryContext";
import { usePrevious } from "../../common/ReactHook";
import { getViewSurveyPath } from "../../../config/ROUTES_NAME";
import LoadingSpinner from "../../common/LoadingSpinner";
import { DateTimeUtils, NumberService } from "../../../services/UtilsService";
import { SURVEY_STATUS } from "../../../config/constants";
import ProgressBar from "react-bootstrap/ProgressBar";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import SurveyAssessmentService from "../../../services/SurveyAssessmentService";
import ToastContext from "../../../context/ToastContext";
import { surveySiteSearchKeys } from "../../../config/search-config";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSTableCtrlMultiSelect from "../../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import SurveyStatusBadge from "../Components/SurveyStatusBadge";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import GVDSTable from "../../../gvds-components/Table/GVDSTable";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import { IconCheck } from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { useTranslation } from "react-i18next";

const SiteViewAllSurveys = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);

  const goToSurvey = (surveyId) => {
    history.push(getViewSurveyPath(surveyId));
  };

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(surveys, surveySiteSearchKeys);

  useEffect(() => {
    if (userInventory.isLoadingInventory.get) {
      setIsLoading(true);
      setSurveys([]);
    } else if (
      selectedInventory &&
      (!previousInventory ||
        selectedInventory.type !== previousInventory.type ||
        selectedInventory.id !== previousInventory.id)
    ) {
      setIsLoading(true);
      SurveyAssessmentService.getAllSurvey(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((surveyList) => {
          setSurveys(surveyList);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toastContext.addFailToast(<span>Failed to load surveys.</span>);
        });
    }
  }, [userInventory.isLoadingInventory.get, selectedInventory]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const columns = [
    {
      header: "Survey Name",
      dataKey: "name",
      headerStyle: { minWidth: "200px", maxWidth: "200px" },
      sortable: true,
    },
    {
      header: "Deadline",
      dataKey: "deadline",
      headerStyle: { width: "200px" },
      sortable: true,
      renderer: (s) => (
        <span>
          {s.deadline ? DateTimeUtils.formatUTCDate(s.deadline) : "-"}{" "}
          {DateTimeUtils.getRemainingDaysBefore(s.deadline) <= 30 ? (
            <span className="caption color-gray56 fst-italic">
              ({DateTimeUtils.getSurveyDeadlineDueMessage(s.deadline, s.status)}
              )
            </span>
          ) : null}
        </span>
      ),
    },
    {
      header: "Completed",
      dataKey: "assessment_complete",
      headerStyle: { width: "100px", textAlign: "center" },
      renderer: (s) => {
        return s.assessment_complete ? (
          <div className="text-vertical-center justify-content-center gvds-color--success">
            <GVDSIcon Icon={IconCheck} />
          </div>
        ) : null;
      },
    },
    {
      header: "Questions Answered",
      dataKey: "completed_questions",
      headerStyle: { maxWidth: "300px", width: "300px" },
      renderer: (s) => {
        const progress = NumberService.format(
          (100 * s.completed_questions) / s.total_questions,
          2,
          2
        );
        return (
          <div className="survey-progress">
            <ProgressBar now={progress} />
            <div className="d-flex">
              <div className="ms-1 caption">
                {s.completed_questions}/{s.total_questions}
              </div>
              <div className="ms-1 caption">({progress}%)</div>
            </div>
          </div>
        );
      },
    },
    {
      header: "Status",
      dataKey: "status",
      headerStyle: { width: "100px" },
      sortable: true,
      renderer: (s) => <SurveyStatusBadge status={s.status} />,
    },
  ];

  const getContent = () => {
    if (surveys.length === 0) {
      return (
        <div className="table__no_content mt-2">
          <p>No surveys yet.</p>
        </div>
      );
    } else if (filteredSortedData.length === 0) {
      return (
        <div className="table__no_content mt-2">
          <p>No surveys. Try changing your filter.</p>
        </div>
      );
    } else {
      return (
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          onRowClick={(s) => goToSurvey(s.survey_id)}
        />
      );
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("surveys.page-title")}</h1>
        </PageHeader.Title>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search for a Survey"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSTableCtrlMultiSelect
          options={[
            SURVEY_STATUS.DRAFT,
            SURVEY_STATUS.OPEN,
            SURVEY_STATUS.CLOSED,
          ]}
          prefix="Status"
          onChange={(filterKs) =>
            setFilterKeys({ ...filterKeys, status: filterKs })
          }
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      {getContent()}
    </>
  );
};
export default SiteViewAllSurveys;
