import PageHeader from "../../gvds-components/Layout/PageHeader";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { POLICY_TRACKER } from "../../config/ROUTES_NAME";
import StatusLabel from "../../gvds-components/common/StatusLabel";
import PolicyTrackerUtils from "./PolicyTrackerUtils";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";
import GVDSIconSlim from "../../gvds-components/Icons/GVDSIconSlim";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import { Collapse } from "react-bootstrap";
import Divider, { DividerOrientation } from "../common/Divider";
import InfoTooltip from "../common/Tooltip/InfoTooltip";
import { TextCopies } from "../../config/text-copies";
import { StringUtils } from "../../services/UtilsService";
import GVDSInfoCard from "../../gvds-components/common/GVDSInfoCard";
import {
  LocationInfo,
  RegulationParametersInfo,
  RelatedInformationInfo,
  ThemesInfo,
} from "./PolicyTrackerInfo";
import PolicyTrackerService from "../../services/PolicyTrackerService";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import GVDSButtonWithLoadingAction from "../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import Container from "react-bootstrap/Container";

const DescriptionSection = ({ description }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(true);

  return (
    <>
      <div className="policy-tracker__section-trigger">
        <div className="gvds-text--heading3">Description</div>
        <GVDSTextButton
          text={
            <GVDSIconSlim
              className="color-black"
              Icon={isDescriptionExpanded ? IconChevronDown : IconChevronRight}
            />
          }
          onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
        />
      </div>
      <Collapse in={isDescriptionExpanded}>
        <div className="policy-tracker__section-with-line-break">
          {description}
        </div>
      </Collapse>
      <Divider className="my-3" orientation={DividerOrientation.horizontal} />
    </>
  );
};

const ComplianceSection = ({
  deadline,
  complianceRequirementsDescription,
  thresholdEligibilityCriteriaDescription,
}) => {
  const [isComplianceExpanded, setIsComplianceExpanded] = useState(true);

  return (
    <>
      <div className="policy-tracker__section-trigger">
        <div className="gvds-text--heading3">Compliance</div>
        <GVDSTextButton
          text={
            <GVDSIconSlim
              className="color-black"
              Icon={isComplianceExpanded ? IconChevronDown : IconChevronRight}
            />
          }
          onClick={() => setIsComplianceExpanded(!isComplianceExpanded)}
        />
      </div>
      <Collapse in={isComplianceExpanded}>
        <div>
          <div className="policy-tracker__compliance-requirements__container">
            <div className="policy-tracker__compliance-requirements__trigger">
              <span className="policy-tracker__compliance__subtitle">
                Compliance Requirements
              </span>
              <span className="mx-1">
                <InfoTooltip
                  info={TextCopies.PolicyTracker.complianceRequirements}
                />
              </span>
              {deadline && (
                <StatusLabel className="ms-1" color={StatusLabel.Colors.gray}>
                  Deadline: {deadline}
                </StatusLabel>
              )}
            </div>
            <div className="policy-tracker__section-with-line-break">
              {complianceRequirementsDescription}
            </div>
          </div>
          <div className="policy-tracker__threshold-eligibility-criteria__container">
            <div className="policy-tracker__threshold-eligibility-criteria__trigger">
              Threshold/Eligibility Criteria
              <span className="mx-1">
                <InfoTooltip
                  info={TextCopies.PolicyTracker.thresholdEligibilityCriteria}
                />
              </span>
            </div>
            <div className="policy-tracker__section-with-line-break">
              {thresholdEligibilityCriteriaDescription}
            </div>
          </div>
        </div>
      </Collapse>
      <Divider className="my-3" orientation={DividerOrientation.horizontal} />
    </>
  );
};

const RecommendedActionSection = ({ recommendedActionDescription }) => {
  const [isRecommendedActionExpanded, setIsRecommendedActionExpanded] =
    useState(true);

  return (
    <GVDSInfoCard
      title={
        <div className="policy-tracker__section-trigger mb-0">
          <div className="gvds-text--heading3">Recommended Action</div>
          <GVDSTextButton
            text={
              <GVDSIconSlim
                className="color-black"
                Icon={
                  isRecommendedActionExpanded
                    ? IconChevronDown
                    : IconChevronRight
                }
              />
            }
            onClick={() =>
              setIsRecommendedActionExpanded(!isRecommendedActionExpanded)
            }
          />
        </div>
      }
      useIcon={false}
    >
      <Collapse in={isRecommendedActionExpanded}>
        <div className="policy-tracker__section-with-line-break mt-3">
          {recommendedActionDescription}
        </div>
      </Collapse>
    </GVDSInfoCard>
  );
};

const DisclaimerSection = () => {
  return (
    <div className="gvds-text--label mt-4">
      <div>Disclaimer:</div>
      <ol>
        {TextCopies.PolicyTracker.disclaimers.map((text, index) => (
          <li key={index}>{text}</li>
        ))}
      </ol>
    </div>
  );
};

const PolicyTrackerDetails = () => {
  const history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const { policyId } = useParams();
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const currentInventory = userInventory.selectedInventory.get;
    if (currentInventory) {
      setIsLoading(true);
      PolicyTrackerService.getPolicy(
        currentInventory.type,
        currentInventory.id,
        policyId
      )
        .then((response) => {
          setSelectedPolicy(response);
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to load policy.</span>);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [policyId, userInventory.selectedInventory.get]);

  const goToPolicyTrackerListPage = () => {
    history.push(POLICY_TRACKER);
  };

  const downloadReport = async () => {
    const currentInventory = userInventory.selectedInventory.get;

    await PolicyTrackerService.downloadPolicy(
      currentInventory.id,
      currentInventory.type,
      policyId
    );
  };

  if (isLoading || !selectedPolicy) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <Container fluid>
        <PageHeader>
          <PageHeader.BackButton
            text="Return to Policy Tracker"
            onClick={goToPolicyTrackerListPage}
          />
          <PageHeader.Title>
            <div className="policy-tracker-details__title__container">
              <h1>{selectedPolicy.name}</h1>
              <div className="mt-1">
                Priority level:
                {selectedPolicy.priorityLevel && (
                  <StatusLabel
                    className="ms-1"
                    color={PolicyTrackerUtils.getPriorityLevelLabelColor(
                      selectedPolicy.priorityLevel
                    )}
                  >
                    {StringUtils.getTitleCase(selectedPolicy.priorityLevel)}
                  </StatusLabel>
                )}
              </div>
            </div>
            <div className="download-policy-tracker-button__container">
              <GVDSButtonWithLoadingAction
                className="ms-auto"
                text="Download"
                onClickAsyncFunc={downloadReport}
              />
            </div>
          </PageHeader.Title>
          <PageHeader.Status></PageHeader.Status>
        </PageHeader>
        <div className="d-flex flex-row">
          <div className="policy-tracker-details__main-content__container">
            <DescriptionSection description={selectedPolicy.description} />
            <ComplianceSection
              deadline={selectedPolicy.deadline}
              complianceRequirementsDescription={
                selectedPolicy.complianceRequirements
              }
              thresholdEligibilityCriteriaDescription={
                selectedPolicy.thresholdEligibilityCriteria
              }
            />
            <RecommendedActionSection
              recommendedActionDescription={selectedPolicy.recommendedAction}
            />
            <DisclaimerSection />
          </div>
          <div className="policy-tracker-details__side-content__container">
            <LocationInfo
              region={selectedPolicy.region}
              countryName={selectedPolicy.country?.name}
              state={selectedPolicy.state}
              city={selectedPolicy.city}
              jurisdictionLevel={selectedPolicy.jurisdictionLevel}
            />
            <RegulationParametersInfo
              complianceObligation={selectedPolicy.complianceObligation}
              legislativeStatus={selectedPolicy.legislativeStatus}
              effectiveDate={selectedPolicy.effectiveDate}
              accurateAsOf={selectedPolicy.accurateAsOf}
              sectorFocus={selectedPolicy.sectorFocus}
            />
            <ThemesInfo
              esgStatuses={selectedPolicy.esgStatuses}
              topics={selectedPolicy.topics}
              keywords={selectedPolicy.keywords.join(", ")}
              transitionRisk={selectedPolicy.transitionRisk}
            />
            <RelatedInformationInfo
              references={selectedPolicy.references}
              relatedPolicies={selectedPolicy.relatedPolicies}
            />
          </div>
        </div>
      </Container>
    );
  }
};

export default PolicyTrackerDetails;
