import axios from "axios";
import { API_URL } from "../../../config/api-config";

class CertificationCriterionAdminService {
  static async getCriteria(certificationId, categoryId) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories/${categoryId}/criteria`
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createCriterion(
    certificationId,
    categoryId,
    number,
    name,
    description,
    instruction
  ) {
    try {
      const data = { number, name, description, instruction };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories/${categoryId}/criteria`,
        data
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateCriterion(
    certificationId,
    categoryId,
    criterionId,
    number,
    name,
    description,
    instruction
  ) {
    try {
      const data = { number, name, description, instruction };

      const response = await axios.put(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories/${categoryId}/criteria/${criterionId}`,
        data
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteCriterion(certificationId, categoryId, criterionId) {
    try {
      await axios.delete(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories/${categoryId}/criteria/${criterionId}`
      );
    } catch (error) {
      throw error;
    }
  }

  static async moveCriterion(
    certificationId,
    sourceCategoryId,
    destinationCategoryId,
    criterionToBeMoved,
    criterionToMoveTo,
    position
  ) {
    try {
      const data = {
        destination_category_id: destinationCategoryId,
        criterion_to_move_to_id: criterionToMoveTo
          ? criterionToMoveTo.id
          : null,
        position: position,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories/${sourceCategoryId}/criteria/${criterionToBeMoved.id}/move`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default CertificationCriterionAdminService;
