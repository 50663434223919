import * as XLSX from "xlsx";

const DEFAULT_COLUMN_WIDTH = 30;

export default class ExcelExporterService {
  static exportToExcel(
    flatJSONList,
    fileNameWithoutExtension,
    customColWidths = null,
    sheetName = null
  ) {
    const worksheet = XLSX.utils.json_to_sheet(flatJSONList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      sheetName ?? fileNameWithoutExtension
    );

    worksheet["!cols"] = this.setColumnWidths(worksheet, customColWidths);

    XLSX.writeFile(workbook, `${fileNameWithoutExtension}.xlsx`, {
      compression: true,
    });
  }

  static setColumnWidths(worksheet, customColWidths = null) {
    const range = worksheet["!ref"].split(":");
    const startCell = range[0];
    const endCell = range[1];

    const startColumnIndex = XLSX.utils.decode_cell(startCell).c;
    const endColumnIndex = XLSX.utils.decode_cell(endCell).c;

    const numberOfColumns = endColumnIndex - startColumnIndex + 1;

    const columnWidths = [];
    if (!!customColWidths) {
      for (let i = 0; i < numberOfColumns; i++) {
        columnWidths[i] = { wch: customColWidths[i] };
      }
    } else {
      for (let i = 0; i < numberOfColumns; i++) {
        columnWidths[i] = { wch: DEFAULT_COLUMN_WIDTH };
      }
    }
    return columnWidths;
  }

  static generateFlatJsonByTableColumns(
    data,
    tableColumns,
    valueExtractorByDataKey = {}
  ) {
    const tableColumnDataKeys = tableColumns.map((column) => column.dataKey);
    const tableColumnDataKeyToHeader = tableColumns.reduce(
      (dataKeyToHeaderMap, column) => {
        dataKeyToHeaderMap[column.dataKey] = column.header;
        return dataKeyToHeaderMap;
      },
      {}
    );

    return data.map((item) => {
      return tableColumnDataKeys.reduce((finalObject, dataKey) => {
        if (item.hasOwnProperty(dataKey)) {
          finalObject[tableColumnDataKeyToHeader[dataKey]] =
            dataKey in valueExtractorByDataKey
              ? valueExtractorByDataKey[dataKey](item)
              : item[dataKey];
        } else if (dataKey in valueExtractorByDataKey) {
          finalObject[tableColumnDataKeyToHeader[dataKey]] =
            valueExtractorByDataKey[dataKey](item);
        }
        return finalObject;
      }, {});
    });
  }
}
