import React from "react";
import { withAmplify } from "../../context/AWSAmplifyContext";

import LoadingSpinner from "../common/LoadingSpinner";
import { withRouter } from "./withRouter";

const loadingUser = null;
const unauthenticated = false;

const withAuthentication = (Component) => {
  class AuthenticationRedirector extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoadingCurrentUser: true,
      };
    }

    componentDidMount() {
      this.listener = this.props.amplify.currentUser.subscribe((user) => {
        if (user === loadingUser) {
          // wait as user data is not loaded yet
        } else if (user === unauthenticated) {
          const pathBeforeLogin =
            this.props.location.pathname + this.props.location.search;
          this.props.history.push({
            pathname: "/login",
            state: {
              redirectToAfterLogin: pathBeforeLogin,
            },
          });
        } else {
          this.setState({
            isLoadingCurrentUser: false,
          });
        }
      });
    }

    componentWillUnmount() {
      this.listener.unsubscribe();
    }

    render() {
      if (this.state.isLoadingCurrentUser) {
        return <div><LoadingSpinner /></div>;
      }

      return <Component {...this.props} />;
    }
  }

  return withRouter(withAmplify(AuthenticationRedirector));
};

export default withAuthentication;
