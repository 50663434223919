import React, { useContext, useEffect, useRef, useState } from "react";
import PermissionsContext from "../../../context/PermissionsContext";
import {
  DEFAULT_MAX_FILE_SIZE_IN_MB,
  INITIATIVE_STATUS,
  PERMISSIONS,
  RESOURCES,
} from "../../../config/constants";
import { InitiativeModel } from "./InitiativeModel";
import Form from "react-bootstrap/Form";
import GVFormGroup from "../../common/GVFormGroup";
import UNSDGPicker from "../../common/UNSDGPicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import { MultipleFilesUploader } from "../../common/FileAttachments";
import InitiativeService from "../../../services/InitiativeService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import TrackingCriteriaForm from "./TrackingCriteriaForm";
import LoadingSpinner from "../../common/LoadingSpinner";
import SelectCurrency from "../../Currency/SelectCurrency";
import { cloneDeep } from "lodash";
import { FileUtils, InventoryUtils } from "../../../services/UtilsService";
import FileStorageService from "../../../services/FileStorageService";
import UnsavedChangePromptModal from "../../common/UnsavedChangePromptModal";
import UserManagedTagPicker from "../../common/CentralTags/UserManagedTagPicker";
import { TextCopies } from "../../../config/text-copies";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormTextArea from "../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormStartEndDatePicker from "../../../gvds-components/Forms/GVDSFormStartEndDatePicker";
import GVDSFormFieldWithCharacterCount from "../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import GVDSTextAreaWithCharacterCount from "../../../gvds-components/Forms/GVDSTextAreaWithCharacterCount";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCopy } from "@tabler/icons-react";
import GVDSInfoCard from "../../../gvds-components/common/GVDSInfoCard";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import {
  getInitiativeSubtopicDescription,
  getInitiativeSubtopicDescriptionTrackingCriteria,
} from "./InitiativeDisplayComponents";
import { Trans, useTranslation } from "react-i18next";

const INPUT_INITIATIVE_STATE = {
  CREATE: "CREATE",
  EDIT: "EDIT",
};

const InputInitiative = ({
  currentInitiative,
  initiativeOptions,
  onSuccess,
  show,
  setShow,
  onCloseModal = () => {},
  onTriggerCopy = () => {},
}) => {
  const { t } = useTranslation();

  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedChangePromptModal, setShowUnsavedChangePromptModal] =
    useState(false);

  const handleCloseModal = () => {
    if (!isLoading) {
      setShow(false);
      if (hasChanges) {
        setShowUnsavedChangePromptModal(true);
      } else {
        onCloseModal();
        resetForm();
      }
    }
  };

  const handleCloseModalResetForm = () => {
    if (!isLoading) {
      onCloseModal();
      setShow(false);
      setShowUnsavedChangePromptModal(false);
      resetForm();
    }
  };

  const handleCancelCloseModal = () => {
    setShowUnsavedChangePromptModal(false);
    setShow(true);
  };

  const [isValidated, setIsValidated] = useState(false);

  const setInitiativeModelToCurrentInitiative = () => {
    setInitiativeModel(cloneDeep(currentInitiative));
    setFiles(currentInitiative.attachedFiles);
  };

  const resetInitiativeModel = () => {
    const newInitiativeModel = new InitiativeModel();

    if (defaultCurrencyId) {
      newInitiativeModel.annualSavingsCurrencyId = defaultCurrencyId;
    }

    setInitiativeModel(newInitiativeModel);
    setFiles([]);
  };

  const resetForm = () => {
    setIsValidated(false);
    trackingCriteriaRecordCacheBySubtopic.current = {};
    setFileErrorMessage(null);
    setHasChanges(false);

    if (currentInitiative) {
      setInitiativeModelToCurrentInitiative();
    } else {
      resetInitiativeModel();
    }
  };

  const isUserAllowedToEdit = () => {
    return (
      !permissionCtx.isLoadingPermissions &&
      permissionCtx.permissions[PERMISSIONS.INITIATIVE_RECORD_EDIT]
    );
  };

  const subtopicSelectorRef = useRef();
  const basicInfoRef = useRef();
  const trackingCriteriaInputRef = useRef();
  const additionalInfoRef = useRef();
  const documentsAndImagesRef = useRef();

  const [initiativeSubtopicOptions, setInitiativeSubtopicOptions] =
    useState(null);
  const [initiativeModel, setInitiativeModel] = useState(new InitiativeModel());
  const onChange = () => {
    setHasChanges(true);
    setInitiativeModel(cloneDeep(initiativeModel));
  };
  const [files, setFiles] = useState([]);
  const [inputInitiativeState, setInputInitiativeState] = useState(
    INPUT_INITIATIVE_STATE.CREATE
  );

  const [allPortfolioTags, setAllPortfolioTags] = useState([]);
  const [allSiteTags, setAllSiteTags] = useState([]);

  const [allUNSDGs, setUNSDGs] = useState([]);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [availableEnergyTypes, setAvailableEnergyTypes] = useState([]);
  const [defaultCurrencyId, setDefaultCurrencyId] = useState("");

  const [fileErrorMessage, setFileErrorMessage] = useState(null);
  const [fileNameConflictMessage, setFileNameConflictMessage] = useState("");
  const [conflictedFileNames, setConflictedFileNames] = useState([]);

  const trackingCriteriaRecordCacheBySubtopic = useRef({});

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;

    if (
      selectedInventory &&
      selectedInventory.type === RESOURCES.SITE &&
      initiativeOptions
    ) {
      setInitiativeSubtopicOptions(initiativeOptions.subtopics);
      setAllPortfolioTags(initiativeOptions.portfolio_tags);
      setAllSiteTags(initiativeOptions.site_tags);
      setUNSDGs(initiativeOptions.un_sdg);
      setAvailableCurrencies(initiativeOptions.currencies);
      setAvailableEnergyTypes(initiativeOptions.energy_types);

      if (
        userInventory.selectedTreeNode.get.nodeValue.value.main_attribute
          .default_currency === null
      ) {
        setDefaultCurrencyId("");
      } else {
        const defaultCurrency =
          userInventory.selectedTreeNode.get.nodeValue.value.main_attribute
            .default_currency;
        setDefaultCurrencyId(defaultCurrency.id);
        if (!currentInitiative) {
          initiativeModel.annualSavingsCurrencyId = defaultCurrency.id;
          setInitiativeModel(cloneDeep(initiativeModel));
        }
      }
    } else {
      setUNSDGs([]);
    }
  }, [userInventory.selectedInventory.get, initiativeOptions]);

  useEffect(() => {
    if (!isLoading) {
      if (currentInitiative) {
        setInitiativeModelToCurrentInitiative();
        setInputInitiativeState(
          !currentInitiative.id
            ? INPUT_INITIATIVE_STATE.CREATE
            : INPUT_INITIATIVE_STATE.EDIT
        );
      } else {
        setInputInitiativeState(INPUT_INITIATIVE_STATE.CREATE);
      }
    }
  }, [currentInitiative]);

  useEffect(() => {
    if (conflictedFileNames.length > 0) {
      setFileErrorMessage(
        <>
          {fileNameConflictMessage}
          {conflictedFileNames?.join("\n")}
        </>
      );
    } else {
      setFileErrorMessage(null);
    }
  }, [conflictedFileNames]);

  const changeSubtopic = (newSubtopicSelected) => {
    if (
      isUserAllowedToEdit() &&
      initiativeModel.subtopic !== newSubtopicSelected
    ) {
      const existingTrackingCriteriaRecord = cloneDeep(
        initiativeModel.trackingCriteriaRecord
      );

      trackingCriteriaRecordCacheBySubtopic.current[initiativeModel.subtopic] =
        existingTrackingCriteriaRecord;

      initiativeModel.subtopic = newSubtopicSelected;

      const newSubtopicOptions = initiativeSubtopicOptions[newSubtopicSelected];

      let prepopulatedTrackingCriteriaRecord = {};

      if (trackingCriteriaRecordCacheBySubtopic.current[newSubtopicSelected]) {
        prepopulatedTrackingCriteriaRecord =
          trackingCriteriaRecordCacheBySubtopic.current[newSubtopicSelected];
      }

      initiativeModel.trackingCriteriaRecord =
        InitiativeModel.copyTrackingCriteriaRecordFromSharedTrackingCriteria(
          newSubtopicOptions,
          prepopulatedTrackingCriteriaRecord,
          existingTrackingCriteriaRecord,
          defaultCurrencyId
        );

      onChange();
    }
  };

  const changeTrackingCriteriaRecordDetails = (newTrackingCriteriaRecord) => {
    initiativeModel.trackingCriteriaRecord = newTrackingCriteriaRecord;
    onChange();
  };

  const onSelectingPortfolioTags = (selectedPortfolioTagsInput) => {
    initiativeModel.selectedPortfolioTags = selectedPortfolioTagsInput.map(
      (tag) => {
        return { id: tag.value, name: tag.label };
      }
    );
    onChange();
  };

  const onSelectingSiteTags = (selectedSiteTagsInput) => {
    initiativeModel.selectedSiteTags = selectedSiteTagsInput.map((tag) => {
      return { id: tag.value, name: tag.label };
    });
    onChange();
  };

  const onSelectingUNSDGs = (selectedUNSDGs) => {
    initiativeModel.selectedUNSDGs = selectedUNSDGs.map((sdg) => {
      return { id: sdg.value, name: sdg.label };
    });
    onChange();
  };

  const onRemoveNewFileFromFiles = () => {
    setConflictedFileNames(
      conflictedFileNames.filter((cf) => {
        if (files.findIndex((f) => f.name === cf) > -1) {
          return cf;
        }
      })
    );
  };

  const getTitle = () => {
    if (inputInitiativeState === INPUT_INITIATIVE_STATE.EDIT) {
      return isUserAllowedToEdit()
        ? t(
            "data-management.initiatives.input.modal-title-view-or-edit-initiative"
          )
        : t("data-management.initiatives.input.modal-title-view-initiative");
    } else {
      return t("data-management.initiatives.input.modal-title-log-initiative");
    }
  };

  const isShowCopyButton =
    isUserAllowedToEdit() &&
    inputInitiativeState === INPUT_INITIATIVE_STATE.EDIT;

  const saveInitiative = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsValidated(true);

    if (
      initiativeModel.isValid() &&
      FileUtils.getFilesWhichSizeLargerThan(files, DEFAULT_MAX_FILE_SIZE_IN_MB)
        .length === 0
    ) {
      setIsLoading(true);
      const selectedInventory = userInventory.selectedInventory.get;

      try {
        const fileNames = FileStorageService.getFileNamesValidationPayload(
          files,
          selectedInventory.id
        );

        await FileStorageService.validateFileNames(
          selectedInventory.type,
          selectedInventory.id,
          fileNames
        );
        setFileErrorMessage(null);
      } catch (e) {
        setFileNameConflictMessage(e.response.data.message.message);
        setConflictedFileNames(e.response.data.message.conflicts);
        setIsLoading(false);

        if (documentsAndImagesRef.current) {
          documentsAndImagesRef.current.scrollIntoView();
        }

        return;
      }

      if (inputInitiativeState === INPUT_INITIATIVE_STATE.CREATE) {
        InitiativeService.createInitiative(
          selectedInventory.type,
          selectedInventory.id,
          initiativeModel.toRequestPayload(),
          files
        )
          .then(() => {
            toastContext.addSuccessToast(
              <span>Initiative has been added.</span>
            );
            onSuccess();
            handleCloseModalResetForm();
            setIsLoading(false);
          })
          .catch(() => {
            toastContext.addFailToast(
              <span>Failed to create initiative.</span>
            );
            setIsLoading(false);
          });
      } else {
        InitiativeService.editInitiative(
          selectedInventory.type,
          selectedInventory.id,
          initiativeModel.id,
          initiativeModel.toRequestPayload(),
          files
        )
          .then(() => {
            toastContext.addSuccessToast(
              <span>Initiative has been updated.</span>
            );
            onSuccess();
            handleCloseModalResetForm();
            setIsLoading(false);
          })
          .catch(() => {
            toastContext.addFailToast(
              <span>Failed to update initiative.</span>
            );
            setIsLoading(false);
          });
      }
    } else {
      if (!initiativeModel.subtopic && subtopicSelectorRef.current) {
        subtopicSelectorRef.current.scrollIntoView();
      } else if (
        (!initiativeModel.isNameNotEmpty() ||
          !initiativeModel.isStoryNotEmpty() ||
          !initiativeModel.isStatusNotEmpty() ||
          !initiativeModel.isPeriodFromNotEmpty() ||
          initiativeModel.isEndBeforeStart() ||
          initiativeModel.isPeriodToEmptyOnCompleted()) &&
        basicInfoRef.current
      ) {
        basicInfoRef.current.scrollIntoView();
      } else if (
        !initiativeModel.isTrackingCriteriaValid() &&
        trackingCriteriaInputRef.current
      ) {
        trackingCriteriaInputRef.current.scrollIntoView();
      } else if (
        initiativeModel.isInitiativeLifecycleNegative() &&
        additionalInfoRef.current
      ) {
        additionalInfoRef.current.scrollIntoView();
      }
    }
  };

  const showPortfolioTag =
    InventoryUtils.isNotIndividualSiteSubscription(userInventory);

  const isFormDisabled = !isUserAllowedToEdit();

  let content;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    const selectedInventory = userInventory.selectedInventory.get;
    const initiativeSubtopicDescription = getInitiativeSubtopicDescription(t);
    const initiativeSubtopicDescriptionTrackingCriteria =
      getInitiativeSubtopicDescriptionTrackingCriteria(t);

    content = (
      <>
        {isFormDisabled && (
          <GVDSInfoCard
            title={t(
              "data-management.initiatives.input.view-access-only-message"
            )}
          />
        )}
        <h3 ref={subtopicSelectorRef}>
          {t("data-management.initiatives.input.label-select-subtopic")}
        </h3>
        {isValidated && !initiativeModel.subtopic && (
          <div className="manual-invalid-feedback">
            {t(
              "data-management.initiatives.input.validation-error-message.empty-required-subtopic"
            )}
          </div>
        )}
        <div className="initiative-input__subtopics-container">
          {Object.keys(initiativeSubtopicDescription).map((subtopic) => {
            return (
              <div
                key={subtopic}
                className={
                  "initiative-input-form__box" +
                  (initiativeModel.subtopic === subtopic ? " active" : "") +
                  (isFormDisabled ? " disabled" : "")
                }
                onClick={() => changeSubtopic(subtopic)}
              >
                <div className="subtopic-name">{subtopic}</div>
                <div className="subtopic-description">
                  {initiativeSubtopicDescription[subtopic]}
                  <br />
                  <br />
                  {initiativeSubtopicDescriptionTrackingCriteria[subtopic]}
                </div>
              </div>
            );
          })}
        </div>
        <hr className="thick-line" />
        <h3 ref={basicInfoRef}>
          {t("data-management.initiatives.input.label-basic-information")}
        </h3>
        <GVFormGroup controlId="initiativeName">
          <Form.Label className="me-1">
            {t("data-management.initiatives.input.label-name")}
          </Form.Label>
          <GVDSFormFieldWithCharacterCount
            value={initiativeModel.name}
            disabled={isFormDisabled}
            onInput={(value) => {
              initiativeModel.name = value;
              onChange();
            }}
            maxLength={200}
            statusMetadata={
              isValidated && !initiativeModel.isNameNotEmpty()
                ? FormFieldStatusMetadata.getError(
                    t(
                      "data-management.initiatives.input.validation-error-message.empty-required-name"
                    )
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <GVFormGroup controlId="initiativeLead">
          <Form.Label className="me-1">
            {t("data-management.initiatives.input.label-initiative-lead")}
          </Form.Label>
          <span className="optional-form-label">
            ({t("shared-input-label.optional")})
          </span>
          <GVDSFormFieldWithCharacterCount
            value={initiativeModel.lead}
            disabled={isFormDisabled}
            onInput={(value) => {
              initiativeModel.lead = value;
              onChange();
            }}
            maxLength={200}
            statusMetadata={FormFieldStatusMetadata.getDefault()}
          />
        </GVFormGroup>
        <Row>
          <Col sm={8}>
            <GVFormGroup controlId="selectStartEndDate">
              <GVDSFormStartEndDatePicker
                startDateLabel={
                  <>
                    <Form.Label className="me-1">
                      {t("shared-input-label.period-from")}
                    </Form.Label>
                    <InfoTooltip
                      info={
                        <div>
                          <Trans i18nKey="data-management.initiatives.input.label-description-period-from">
                            The date that this initiative started / the date
                            that it will start.
                            <br />
                            <br />
                            For searching and reporting purposes, this
                            initiative will be included if any day between its
                            Period From and Period To falls within the timeframe
                            chosen.
                          </Trans>
                        </div>
                      }
                    />
                  </>
                }
                endDateLabel={
                  <>
                    <Form.Label className="me-1">
                      {t("shared-input-label.period-to")}
                    </Form.Label>
                    {initiativeModel.status !== INITIATIVE_STATUS.COMPLETED && (
                      <span className="optional-form-label me-1">
                        ({t("shared-input-label.optional")})
                      </span>
                    )}
                    <InfoTooltip
                      info={
                        <div>
                          <Trans i18nKey="data-management.initiatives.input.label-description-period-to">
                            The date that this initiative ended / the date that
                            it will end. You may leave this field blank if you
                            don’t know when this initiative will end.
                            <br />
                            <br />
                            Note: If this field is blank, for searching and
                            reporting purposes, this record will be included
                            only if its Period From falls within the timeframe
                            chosen.
                          </Trans>
                        </div>
                      }
                    />
                  </>
                }
                className="initiative-record"
                disabledStartDate={isFormDisabled}
                disabledEndDate={isFormDisabled}
                startDate={initiativeModel.periodFrom}
                endDate={initiativeModel.periodTo}
                onChange={(startDate, endDate) => {
                  initiativeModel.periodFrom = startDate;
                  initiativeModel.periodTo = endDate;
                  onChange();
                }}
                dateFormat="dd MMM yyyy"
                statusMetadata={
                  isValidated && !initiativeModel.isPeriodFromNotEmpty()
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "data-management.initiatives.input.validation-error-message.empty-required-period-from"
                        )
                      )
                    : initiativeModel.isPeriodToEmptyOnCompleted()
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "data-management.initiatives.input.validation-error-message.empty-required-period-to"
                        )
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
          <Col>
            <GVFormGroup controlId="initiativeStatus">
              <Form.Label>{t("shared-input-label.status")}</Form.Label>
              <GVDSFormSingleSelect
                className="select__initiative-status"
                placeholder="Select Status"
                value={
                  initiativeModel.status
                    ? {
                        value: initiativeModel.status,
                        label: initiativeModel.status,
                      }
                    : null
                }
                options={Object.values(INITIATIVE_STATUS).map((status) => {
                  return { value: status, label: status };
                })}
                onSelect={(selectedOption) => {
                  initiativeModel.status = selectedOption.value;
                  onChange();
                }}
                disabled={isFormDisabled}
                statusMetadata={
                  isValidated && !initiativeModel.isStatusNotEmpty()
                    ? FormFieldStatusMetadata.getError(
                        t(
                          "data-management.initiatives.input.validation-error-message.empty-required-status"
                        )
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
          </Col>
        </Row>
        <GVFormGroup controlId="initiativeStory">
          <Form.Label>
            {t("data-management.initiatives.input.label-story")}
          </Form.Label>
          <div className="form-label-description">
            {t("data-management.initiatives.input.label-description-story")}
          </div>
          <GVDSFormTextArea
            rows={2}
            disabled={isFormDisabled}
            value={initiativeModel.story}
            onInput={(value) => {
              initiativeModel.story = value;
              onChange();
            }}
            statusMetadata={
              isValidated && !initiativeModel.isStoryNotEmpty()
                ? FormFieldStatusMetadata.getError(
                    t(
                      "data-management.initiatives.input.validation-error-message.empty-required-story"
                    )
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        {showPortfolioTag && (
          <GVFormGroup controlId="portfolioTags">
            <Form.Label>{t("shared-input-label.portfolio-tags")}</Form.Label>
            <span className="optional-form-label ms-1">
              ({t("shared-input-label.optional")})
            </span>
            <div className="form-label-description">
              {t("user-managed-tag.about-tags.portfolio-tag-description")}
            </div>
            <UserManagedTagPicker
              disabled={isFormDisabled}
              resourceType={RESOURCES.PORTFOLIO}
              selectedTags={initiativeModel.selectedPortfolioTags}
              allTags={allPortfolioTags}
              onChange={onSelectingPortfolioTags}
              hideSelectedOptions={true}
              className="portfolio-tag"
            />
          </GVFormGroup>
        )}
        <GVFormGroup controlId="siteTags">
          <Form.Label>{t("shared-input-label.site-tags")}</Form.Label>
          <span className="optional-form-label ms-1">
            ({t("shared-input-label.optional")})
          </span>
          <div className="form-label-description">
            {t("user-managed-tag.about-tags.site-tag-description")}
          </div>
          <UserManagedTagPicker
            disabled={isFormDisabled}
            resourceType={RESOURCES.SITE}
            selectedTags={initiativeModel.selectedSiteTags}
            allTags={allSiteTags}
            onChange={onSelectingSiteTags}
            hideSelectedOptions={true}
            className="site-tag"
          />
        </GVFormGroup>
        <GVFormGroup controlId="UNSDG">
          <Form.Label>{t("shared-input-label.un-sdg")}</Form.Label>
          <span className="optional-form-label mx-1">
            ({t("shared-input-label.optional")})
          </span>
          <InfoTooltip info={<span>{TextCopies.UNSDGs.about}</span>} />
          <UNSDGPicker
            disabled={isFormDisabled}
            selectedUNSDGs={initiativeModel.selectedUNSDGs}
            allUNSDGs={allUNSDGs}
            onChange={onSelectingUNSDGs}
          />
        </GVFormGroup>
        <hr className="thick-line" />
        <h3 ref={trackingCriteriaInputRef}>
          {t(
            "data-management.initiatives.input.label-select-tracking-criteria"
          )}
        </h3>
        <div className="mb-1">
          <Trans
            i18nKey={
              "data-management.initiatives.input.label-description-tracking-criteria"
            }
          >
            Choose which data you wish to track for your initiative. Click the
            buttons below to add a new tracking criterion.
            <br />
            If the available tracking criteria do not seem relevant to your
            initiative, try changing the subtopic.
          </Trans>
        </div>
        <TrackingCriteriaForm
          trackingCriteriaRecord={initiativeModel.trackingCriteriaRecord}
          subtopicTrackingCriteriaOptions={
            initiativeSubtopicOptions && initiativeModel.subtopic
              ? initiativeSubtopicOptions[initiativeModel.subtopic]
              : null
          }
          currencies={availableCurrencies}
          defaultCurrencyId={defaultCurrencyId}
          energyTypes={availableEnergyTypes}
          onTrackingCriteriaRecordUpdate={changeTrackingCriteriaRecordDetails}
          disabled={isFormDisabled}
          isValidated={isValidated}
        />

        <hr className="thick-line" />
        <h3 ref={additionalInfoRef}>
          {t("data-management.initiatives.input.label-additional-info")}
        </h3>
        {initiativeModel.isEfficiencyInitiative() && (
          <>
            <Row>
              <Col lg={6} sm={12}>
                <GVFormGroup>
                  <Form.Label>
                    {t("data-management.initiatives.input.label-lifecycle")}
                  </Form.Label>
                  <span className="optional-form-label mx-1">
                    ({t("shared-input-label.optional")})
                  </span>
                  <InfoTooltip
                    info={t(
                      "data-management.initiatives.input.label-description-lifecycle"
                    )}
                  />
                  <GVDSFormField
                    type="number"
                    name="initiative-lifecycle"
                    placeholder=""
                    value={initiativeModel.initiativeLifecycleInYears}
                    onInput={(value) => {
                      initiativeModel.initiativeLifecycleInYears = value;
                      onChange();
                    }}
                    statusMetadata={
                      isValidated &&
                      initiativeModel.isInitiativeLifecycleNegative()
                        ? FormFieldStatusMetadata.getError(
                            t(
                              "data-management.initiatives.input.validation-error-message.negative-lifecycle"
                            )
                          )
                        : FormFieldStatusMetadata.getDefault()
                    }
                    disabled={isFormDisabled}
                  />
                </GVFormGroup>
              </Col>
            </Row>
            <GVFormGroup>
              <Form.Label>
                {t("data-management.initiatives.input.label-annual-savings")}
              </Form.Label>
              <span className="optional-form-label mx-1">
                ({t("shared-input-label.optional")})
              </span>
              <Row>
                <div style={{ paddingLeft: "15px", width: "130px" }}>
                  <SelectCurrency
                    selected={initiativeModel.annualSavingsCurrencyId}
                    onCurrencySelected={(newCurrency) => {
                      initiativeModel.annualSavingsCurrencyId = newCurrency;
                      onChange();
                    }}
                    showLabel={false}
                    showAsCurrencyId={true}
                    targetName="annual-savings-currency"
                    displayAsText={isFormDisabled}
                  />
                </div>
                <Col lg={6} sm={12}>
                  <GVDSFormField
                    type="number"
                    name="estimated-annual-savings"
                    placeholder=""
                    value={initiativeModel.estimatedAnnualSavings}
                    onInput={(value) => {
                      initiativeModel.estimatedAnnualSavings = value;
                      onChange();
                    }}
                    disabled={isFormDisabled}
                  />
                </Col>
              </Row>
            </GVFormGroup>
          </>
        )}
        <GVFormGroup controlId="initiativePartnerOrganisation">
          <Form.Label>
            {t("data-management.initiatives.input.label-partner-org")}
          </Form.Label>
          <span className="optional-form-label ms-1">
            ({t("shared-input-label.optional")})
          </span>
          <GVDSFormFieldWithCharacterCount
            value={initiativeModel.partnerOrganisation}
            disabled={isFormDisabled}
            onInput={(value) => {
              initiativeModel.partnerOrganisation = value;
              onChange();
            }}
            maxLength={200}
            statusMetadata={FormFieldStatusMetadata.getDefault()}
          />
        </GVFormGroup>
        <GVFormGroup controlId="initiativeComments">
          <Form.Label>{t("shared-input-label.comments")}</Form.Label>
          <span className="optional-form-label ms-1">
            ({t("shared-input-label.optional")})
          </span>
          <div className="form-label-description">
            {t("data-management.initiatives.input.label-description-comments")}
          </div>
          <GVDSTextAreaWithCharacterCount
            value={initiativeModel.comments}
            disabled={isFormDisabled}
            onInput={(value) => {
              initiativeModel.comments = value;
              onChange();
            }}
            placeholder="Enter Comments"
            rows={3}
            maxLength={500}
            statusMetadata={FormFieldStatusMetadata.getDefault()}
          />
        </GVFormGroup>
        <hr className="thick-line" />
        <h3 ref={documentsAndImagesRef}>
          {t(
            "data-management.initiatives.input.label-provide-documents-and-images"
          )}
        </h3>
        <div className="mb-3">
          {t(
            "data-management.initiatives.input.label-description-provide-documents-and-images"
          )}
        </div>
        <GVFormGroup>
          <Form.Label>
            {t("data-management.initiatives.input.label-documents-and-images")}
            <span className="optional-form-label ms-1">
              ({t("shared-input-label.optional")};{" "}
              {t(
                "data-management.initiatives.input.label-documents-and-images-max-size"
              )}
              )
            </span>
          </Form.Label>
          {fileErrorMessage && (
            <GVDSBanner
              title={t(
                "data-management.initiatives.input.error-cannot-save-files"
              )}
              variant={GVDSBanner.Variants.error}
            >
              <div style={{ whiteSpace: "pre-wrap" }}>{fileErrorMessage}</div>
            </GVDSBanner>
          )}
          <MultipleFilesUploader
            files={files}
            setFiles={setFiles}
            useS3PresignedURL={true}
            apiParams={{
              resource_type: selectedInventory?.type,
              resource_id: selectedInventory?.id,
            }}
            disabled={isFormDisabled}
            onRemoveNewFileFromFiles={onRemoveNewFileFromFiles}
          />
        </GVFormGroup>
      </>
    );
  }

  return (
    <>
      <GVDSModal
        title={getTitle()}
        dialogClassName="input-initiative-modal"
        size={GVDSModal.Size.large}
        show={show}
        onHide={handleCloseModal}
      >
        <GVDSModal.Body>{content}</GVDSModal.Body>
        <GVDSModal.Footer>
          {isShowCopyButton && (
            <div className="me-auto">
              <GVDSButton
                variant={buttonVariant.tertiary}
                className="copy-initiative"
                onClick={() => {
                  setShow(false);
                  onTriggerCopy();
                }}
                icon={<GVDSIcon Icon={IconCopy} />}
                text={t("shared.copy")}
                disabled={isLoading}
              />
            </div>
          )}
          <GVDSButton
            className="close-initiative"
            variant={buttonVariant.tertiary}
            disabled={isLoading}
            onClick={handleCloseModal}
            text={
              isUserAllowedToEdit()
                ? t("shared-modal.footer.cancel")
                : t("shared-modal.footer.close")
            }
          />
          {isUserAllowedToEdit() && (
            <GVDSButton
              variant={buttonVariant.primary}
              className="save-initiative"
              disabled={isLoading}
              onClick={saveInitiative}
              text={t("shared-modal.footer.save")}
            />
          )}
        </GVDSModal.Footer>
      </GVDSModal>

      <UnsavedChangePromptModal
        show={showUnsavedChangePromptModal}
        onCancel={handleCancelCloseModal}
        onProceed={handleCloseModalResetForm}
        modalName={t(
          "data-management.initiatives.input.warning-modal-title-input-initiative"
        )}
        message={t("shared.unsaved-change-prompt-message")}
      />
    </>
  );
};
export default InputInitiative;
