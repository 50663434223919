import axios from "axios";
import { API_URL } from "../../../config/api-config";
import { CertificationContentCategoryModel } from "../../../components/SystemToolbox/Certification/CertificationModel";

export class CertificationCategoryAdminService {
  static async getCategories(certificationId) {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories`
      );

      return response.data.map((category) =>
        CertificationContentCategoryModel.fromDTO(category)
      );
    } catch (error) {
      throw error;
    }
  }

  static async createCategory(certificationId, name) {
    try {
      const data = { name };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories`,
        data
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateCategory(certificationId, categoryId, name) {
    try {
      const data = { name };

      const response = await axios.put(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories/${categoryId}`,
        data
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteCategory(certificationId, categoryId) {
    try {
      await axios.delete(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories/${categoryId}`
      );
    } catch (error) {
      throw error;
    }
  }

  static async moveCategory(
    certificationId,
    categoryToBeMoved,
    categoryToMoveTo,
    position
  ) {
    try {
      const data = {
        category_to_move_to_id: categoryToMoveTo.id,
        position: position,
      };
      const response = await axios.post(
        `${API_URL}/api/v1/admin/certifications/${certificationId}/categories/${categoryToBeMoved.id}/move`,
        data
      );
      return response.data.map(category => CertificationContentCategoryModel.fromDTO(category));
    } catch (error) {
      throw error;
    }
  }
}
