import React, { useEffect, useState } from "react";
import { uniqueId } from "lodash";
import {
  new_entry_prefix,
  tobe_deleted_entry_prefix,
} from "../../../../config/constants";
import Form from "react-bootstrap/Form";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import GVDSTextButton from "../../../../gvds-components/Buttons/GVDSTextButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconTrash } from "@tabler/icons-react";
import { MultipleFilesUploader } from "../../../common/FileAttachments";
import { iconButtonVariant } from "../../../../gvds-components/Buttons/GVDSIconButton";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "../../../../gvds-components/Forms/GVDSFormShared";
import { StringUtils } from "../../../../services/UtilsService";

const SampleEvidenceForm = ({
  isValidated,
  evidence,
  onChange,
  deleteSampleEvidence,
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    setShowErrorMessage(false);
  }, [evidence]);

  const handleFilesChange = (newFiles) => {
    setShowErrorMessage(false);
    if (newFiles.length > 0) {
      const activeFiles = newFiles.filter((file) => !file.isToBeDeleted());
      if (activeFiles.length > 1) {
        setShowErrorMessage(true);
      } else {
        evidence.files = newFiles;
        onChange();
      }
    } else {
      evidence.files = newFiles;
      onChange();
    }
  };

  const activeEvidenceFileCount = evidence.files.filter(
    (file) => !file.isToBeDeleted()
  ).length;

  return (
    <div className="system-toolbox--certification__requirement-evidence__content">
      <div className="d-flex mb-2">
        <div className="flex-grow-1">
          <GVDSFormField
            name="evidence"
            value={evidence.name}
            placeholder="Evidence name"
            onInput={(value) => {
              evidence.name = value;
              onChange();
            }}
            statusMetadata={
              isValidated && !StringUtils.isNotEmpty(evidence.name)
                ? FormFieldStatusMetadata.getError(
                    "This field cannot be empty."
                  )
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </div>
        <GVDSTextButton
          className="delete-evidence-btn"
          icon={<GVDSIcon Icon={IconTrash} className="me-2" />}
          text="Delete sample"
          onClick={deleteSampleEvidence}
        />
      </div>
      <MultipleFilesUploader
        files={evidence.files}
        setFiles={handleFilesChange}
        useS3PresignedURL={true}
        disableSelectFile={activeEvidenceFileCount > 0}
      />
      {showErrorMessage && (
        <GVDSFormErrorMessage
          status={FormFieldState.error}
          errorMsg="Only 1 file per evidence is allowed."
        />
      )}
    </div>
  );
};

const CertificationContentSampleEvidenceForm = ({
  sampleEvidences,
  setSampleEvidences,
  isValidated,
}) => {
  const addSampleEvidence = () => {
    const newSampleEvidence = {
      id: uniqueId(new_entry_prefix),
      name: "",
      files: [],
    };
    setSampleEvidences([...sampleEvidences, newSampleEvidence]);
  };

  const deleteSampleEvidence = (evidenceId) => {
    const updatedEvidences = [...sampleEvidences];

    const evidenceIndex = updatedEvidences.findIndex(
      (evidence) => evidence.id === evidenceId
    );

    if (evidenceId.startsWith(new_entry_prefix)) {
      updatedEvidences.splice(evidenceIndex, 1);
    } else {
      updatedEvidences[evidenceIndex].id =
        tobe_deleted_entry_prefix + evidenceId;
    }
    setSampleEvidences(updatedEvidences);
  };

  const onChange = () => {
    setSampleEvidences([...sampleEvidences]);
  };

  const currentEvidences = sampleEvidences.filter(
    (evidence) => !evidence.id.startsWith(tobe_deleted_entry_prefix)
  );

  return (
    <div className="system-toolbox--certification__requirement-evidence__container">
      {currentEvidences.length > 0 && (
        <div>
          <div className="system-toolbox--certification__requirement-evidence__label">
            <Form.Label>
              Sample evidence{" "}
              <InfoTooltip
                info="Uploading files is optional. Users can download files uploaded here.
              The file name will be displayed as text."
              />
            </Form.Label>
          </div>

          {currentEvidences.map((evidence) => (
            <SampleEvidenceForm
              key={evidence.id}
              evidence={evidence}
              onChange={onChange}
              deleteSampleEvidence={() => deleteSampleEvidence(evidence.id)}
              isValidated={isValidated}
            />
          ))}
        </div>
      )}

      <GVDSTextButton
        className="system-toolbox--certification__add-content-button"
        variant={iconButtonVariant.tertiary}
        icon={<GVDSIcon Icon={IconCirclePlus} className="me-2" />}
        text="Add sample evidence"
        onClick={addSampleEvidence}
      />
    </div>
  );
};

export default CertificationContentSampleEvidenceForm;
