import React from "react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconLockSquareRounded } from "@tabler/icons-react";
import {
  getRedirectURLWithCurrentParam,
  updateQueryWithModalParam,
} from "../QueryHandler";
import { ADMIN_BILLING } from "../../../config/ROUTES_NAME";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import { useHistory, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import SupportContactLink from "../SupportContactLink";

const FeatureLockMessage = () => {
  const history = useHistory();
  const location = useLocation();

  const goToPricingPlanTrigger = () => {
    const query = updateQueryWithModalParam(location, "open", "pricing-plan");

    location.search = query.toString();
    history.push(getRedirectURLWithCurrentParam(ADMIN_BILLING, location));
  };

  return (
    <div className="feature-lock">
      <GVDSIcon
        Icon={IconLockSquareRounded}
        className="gvds-empty-content--icon"
      />
      <div className="feature-lock-message">
        <Trans i18nKey="shared.feature-lock-message-to-pricing-plan">
          This feature is locked. If you wish to use this feature or know more,
          view our pricing plans{" "}
          <GVDSTextButton onClick={goToPricingPlanTrigger}>here</GVDSTextButton>
          .<br />
          Contact <SupportContactLink /> for more information.
        </Trans>
      </div>
    </div>
  );
};

export default FeatureLockMessage;
