import React from "react";
import Modal from "react-bootstrap/Modal";

const GVDSModalSize = {
  small: "small",
  medium: "medium",
  large: "large",
  xl: "xl"
};

const GVDSModalSizeToClassName = {
  [GVDSModalSize.small]: "gvds-modal-size--small",
  [GVDSModalSize.medium]: "gvds-modal-size--medium",
  [GVDSModalSize.large]: "gvds-modal-size--large",
  [GVDSModalSize.xl]: "gvds-modal-size--xl",
};

const GVDSModalHeader = React.forwardRef(({ title, hideCloseButton }, ref) => {
  return (
    <Modal.Header ref={ref} closeButton={!hideCloseButton}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
  );
});

const GVDSModalBody = React.forwardRef(({ children }, ref) => {
  return <Modal.Body ref={ref}>{children}</Modal.Body>;
});

const GVDSModalFooter = React.forwardRef(({ children }, ref) => {
  return <Modal.Footer ref={ref}>{children}</Modal.Footer>;
});

const GVDSModal = React.forwardRef(
  (
    {
      title,
      size = GVDSModalSize.medium,
      show,
      onHide,
      className,
      dialogClassName,
      contentClassName,
      hideCloseButton = false,
      children,
      ...props
    },
    ref
  ) => {
    const combinedClassNames = `gvds-modal ${className || ""}`;
    const combinedDialogClassNames = `${GVDSModalSizeToClassName[size]} ${
      dialogClassName || ""
    }`;

    return (
      <Modal
        ref={ref}
        show={show}
        onHide={onHide}
        scrollable
        centered
        className={combinedClassNames}
        dialogClassName={combinedDialogClassNames}
        contentClassName={contentClassName}
        {...props}
      >
        <GVDSModalHeader title={title} hideCloseButton={hideCloseButton} />
        {children}
      </Modal>
    );
  }
);

GVDSModal.Size = GVDSModalSize;
GVDSModal.Body = GVDSModalBody;
GVDSModal.Footer = GVDSModalFooter;

export default GVDSModal;
