import React, { useContext, useEffect, useRef, useState } from "react";
import UserAvatar from "../../../common/UserAvatar";
import Dropdown from "react-bootstrap/Dropdown";
import OtherActionDropdownToggle from "../../../common/OtherActionDropdownToggle";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import { TextareaUtils } from "../../../../services/UtilsService";
import UserProfileContext from "../../../../context/UserProfileContext";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import ToastContext from "../../../../context/ToastContext";
import { CertificationRequirementCommentService } from "../../../../services/CertificationCommentService";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import CertificationContext from "../../CertificationContext";
import { useParams, useRouteMatch } from "react-router-dom";
import { CERTIFICATION_CONTENT_PAGE_REQUIREMENT } from "../../../../config/ROUTES_NAME";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { PERMISSIONS } from "../../../../config/constants";
import PermissionsContext from "../../../../context/PermissionsContext";

const COMMENT_DETAILS_SEPARATOR = <span className="detail-separator">•</span>;

const CertificationCommentTimestampDisplay = ({ timestamp }) => {
  const timestampObj = moment.utc(timestamp);
  timestampObj.local();

  const timestampDisplayFull =
    timestampObj.format("MMM DD, YYYY") + " at " + timestampObj.format("LT");

  const isWithin1Day = timestampObj.isAfter(moment().subtract(1, "days"));

  if (isWithin1Day) {
    return (
      <OverlayTrigger overlay={<Tooltip>{timestampDisplayFull}</Tooltip>}>
        <div className="d-inline">{timestampObj.fromNow()}</div>
      </OverlayTrigger>
    );
  } else {
    return <div className="d-inline">{timestampDisplayFull}</div>;
  }
};

const DeleteCertificationCommentPrompt = ({
  show,
  close,
  asyncDeleteComment,
}) => {
  const { t } = useTranslation();

  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    if (!isLoading) {
      close();
    }
  };

  const deleteComment = async () => {
    setIsLoading(true);

    try {
      await asyncDeleteComment();
      toastContext.addSuccessToast(<span>Comment deleted</span>);
      close();
    } catch {
      toastContext.addFailToast(<span>Failed to delete comment</span>);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GVDSModal
      title={`Delete this comment?`}
      size={GVDSModal.Size.small}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>
        {isLoading ? <LoadingSpinner /> : <>This action cannot be undone.</>}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          text={t("shared-modal.footer.cancel")}
          disabled={isLoading}
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          onClick={deleteComment}
          text={t("shared.delete")}
          disabled={isLoading}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const CertificationCommentDisplayInput = ({ commentModel }) => {
  const { t } = useTranslation();

  const { certificationId, criterionId } = useParams();
  const routeMatch = useRouteMatch(CERTIFICATION_CONTENT_PAGE_REQUIREMENT);
  const requirementId = routeMatch?.params["requirementId"];

  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();
  const userInventory = useContext(UserInventoryContext);
  const permissionsCtx = useContext(PermissionsContext);

  const certificationContext = useContext(CertificationContext);

  const displayTextareaRef = useRef(null);

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isSavingEdit, setIsSavingEdit] = useState(false);
  const [inputComment, setInputComment] = useState("");
  const textareaRef = useRef(null);

  const triggerEdit = () => {
    setInputComment(commentModel.comment);
    setIsInEditMode(true);
  };

  const cancelEdit = () => {
    setIsInEditMode(false);
  };

  const saveEdit = async () => {
    setIsSavingEdit(true);

    try {
      const currentInventory = userInventory.selectedInventory.get;

      const commentId = commentModel.id;
      const updatedCommentModel =
        await CertificationRequirementCommentService.updateComment(
          currentInventory.type,
          currentInventory.id,
          certificationContext.certificationId,
          requirementId,
          commentId,
          inputComment
        );

      certificationContext.certification.updateCommentOfRequirement(
        criterionId,
        requirementId,
        commentId,
        updatedCommentModel
      );
      certificationContext.refreshCertification();

      toastContext.addSuccessToast(<span>Comment updated</span>);
      setIsInEditMode(false);
    } catch {
      toastContext.addFailToast(<span>Failed to update comment</span>);
    } finally {
      setIsSavingEdit(false);
    }
  };

  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);

  const triggerDelete = () => {
    setShowPromptDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  const asyncDeleteComment = async () => {
    const currentInventory = userInventory.selectedInventory.get;

    const commentId = commentModel.id;
    await CertificationRequirementCommentService.deleteComment(
      currentInventory.type,
      currentInventory.id,
      certificationContext.certificationId,
      requirementId,
      commentId
    );

    certificationContext.certification.removeCommentOfRequirement(
      criterionId,
      requirementId,
      commentId
    );
    certificationContext.refreshCertification();
  };

  useEffect(() => {
    if (isInEditMode && textareaRef.current) {
      TextareaUtils.dynamicallyResizeTextarea(textareaRef, textareaRef.current);
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.value.length;
    } else if (!isInEditMode && displayTextareaRef.current) {
      TextareaUtils.dynamicallyResizeTextarea(
        displayTextareaRef,
        displayTextareaRef.current
      );
    }
  }, [isInEditMode]);

  const createdBy = commentModel.createdBy;
  const email = createdBy["email"];
  const fullName = createdBy["full_name"];
  const isCreatorOfComment = userProfile.email === email;
  const isAllowedToDeleteOthers =
    !permissionsCtx.isLoadingPermissions &&
    permissionsCtx.permissions[
      PERMISSIONS.CERTIFICATION_ASSESSMENT_DELETE_OTHERS
    ];

  let content;

  if (!isInEditMode) {
    content = (
      <div className="certification-comments__display-comment__content">
        <div className="certification-comments__display-comment__header">
          <div className="flex-grow-1 d-flex flex-row align-items-center">
            <div className="gvds-text--formLabel">{fullName}</div>
            <div className="ms-1 gvds-text--label gvds-color--gray6">
              {COMMENT_DETAILS_SEPARATOR}{" "}
              <CertificationCommentTimestampDisplay
                timestamp={commentModel.createdOn}
              />
            </div>
          </div>
          <div>
            {
              <Dropdown
                className={
                  isCreatorOfComment || isAllowedToDeleteOthers
                    ? ""
                    : "invisible"
                }
              >
                <Dropdown.Toggle as={OtherActionDropdownToggle} />

                <Dropdown.Menu>
                  {isCreatorOfComment && (
                    <Dropdown.Item key="edit" href="#" onClick={triggerEdit}>
                      <GVDSIcon Icon={IconEdit} /> {t("shared.edit")}
                    </Dropdown.Item>
                  )}
                  {(isCreatorOfComment || isAllowedToDeleteOthers) && (
                    <Dropdown.Item
                      key="delete"
                      href="#"
                      className="danger"
                      onClick={triggerDelete}
                    >
                      <GVDSIcon Icon={IconTrash} /> {t("shared.delete")}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            }
          </div>
        </div>
        <div>
          <GVDSFormTextArea
            ref={displayTextareaRef}
            value={commentModel.comment}
            readOnly={true}
          />
        </div>
        <DeleteCertificationCommentPrompt
          show={showPromptDeleteModal}
          close={closeDeleteModal}
          asyncDeleteComment={asyncDeleteComment}
        />
      </div>
    );
  } else {
    content = (
      <div className="certification-comments__edit-comment-container">
        <div className="certification-comments__edit-comment-textarea-container">
          <GVDSFormTextArea
            ref={textareaRef}
            value={inputComment}
            placeholder={t(
              "certifications.content-page.comments.input-placeholder"
            )}
            onInput={(value, e) => {
              setInputComment(value);

              TextareaUtils.dynamicallyResizeTextarea(textareaRef, e.target);
            }}
            rows={1}
            disabled={isSavingEdit}
          />
        </div>
        <div className="certification-comments__edit-comment-footer">
          <Spacer />
          <GVDSButton
            text={t("shared-modal.footer.cancel")}
            variant={buttonVariant.tertiary}
            onClick={cancelEdit}
            disabled={isSavingEdit}
          />
          <GVDSButton
            text={t("shared-modal.footer.save")}
            variant={buttonVariant.primary}
            onClick={saveEdit}
            disabled={isSavingEdit}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="certification-comments__display-comment__container">
      <UserAvatar userEmail={email} fullName={fullName} />
      {content}
    </div>
  );
};

export default CertificationCommentDisplayInput;
