import React, { useContext } from "react";
import greenviewPortalLogoIconOnly from "../../../assets/images/new-greenview-logo-icon-only.svg";
import Spacer from "../../Layout/Spacer";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../Buttons/GVDSIconButton";
import GVDSIcon from "../../Icons/GVDSIcon";
import { IconLayoutSidebarLeftCollapse, IconPin } from "@tabler/icons-react";
import NavWrapperContext from "../NavWrapperContext";
import { getRedirectURLWithCurrentParam } from "../../../components/common/QueryHandler";
import { Link, useLocation } from "react-router-dom";
import { HOME } from "../../../config/ROUTES_NAME";
import { useTranslation } from "react-i18next";

const SideNavTop = ({ isMinimised, onHideMenu }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navWrapperContext = useContext(NavWrapperContext);

  if (isMinimised) {
    return (
      <div className="gvds-side-nav-top">
        <div className="logo">
          <img src={greenviewPortalLogoIconOnly} alt="Greenview Portal" />
        </div>
      </div>
    );
  }

  return (
    <div className="gvds-side-nav-top">
      <Link to={getRedirectURLWithCurrentParam(HOME, location)}>
        <div className="logo">
          <img src={greenviewPortalLogoIconOnly} alt="Greenview Portal" />
          <div>Greenview Portal</div>
        </div>
      </Link>
      <Spacer />
      {navWrapperContext.isSideNavControlHideNotPin ? (
        <GVDSIconButton
          key="transition--unpin-side-nav"
          className="side-nav-control"
          variant={iconButtonVariant.tertiary}
          icon={<GVDSIcon Icon={IconLayoutSidebarLeftCollapse} />}
          onClick={() => {
            navWrapperContext.unpinSideNav();
            onHideMenu();
          }}
          tooltipText={t("side-nav.tooltip-hide-menu")}
          tooltipDelay={500}
          placement="right"
        />
      ) : (
        <GVDSIconButton
          key="transition--pin-side-nav"
          className="side-nav-control"
          variant={iconButtonVariant.tertiary}
          icon={<GVDSIcon Icon={IconPin} />}
          onClick={navWrapperContext.pinSideNav}
          tooltipText={t("side-nav.tooltip-pin-menu")}
          tooltipDelay={500}
          placement="right"
        />
      )}
    </div>
  );
};

export default SideNavTop;
