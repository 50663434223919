import React, { useContext } from "react";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import NavWrapperContext, {
  PageLayoutTemplateState,
} from "./NavWrapperContext";
import AnnouncementBarContext from "../AnnouncementBar/AnnouncementBarContext";

const NavWrapper = ({ children }) => {
  const navWrapperContext = useContext(NavWrapperContext);
  const announcementBarContext = useContext(AnnouncementBarContext);

  const shouldMinimisedSideNav =
    !navWrapperContext.isSystemToolbox && navWrapperContext.isSideNavMinimised;

  const containerClassNames = ["gvds-page-container__beside-side-nav"];
  if (shouldMinimisedSideNav) {
    containerClassNames.push("is-side-nav-minimised");
  }
  const combinedContainerClassNames = containerClassNames.join(" ");
  const hideTopNav = navWrapperContext.hideTopNav;

  let content = null;

  if (
    navWrapperContext.pageLayoutTemplate === PageLayoutTemplateState.default
  ) {
    content = (
      <div
        className={"gvds-content-container" + (hideTopNav ? " no-top-nav" : "")}
      >
        {children}
      </div>
    );
  } else if (
    navWrapperContext.pageLayoutTemplate === PageLayoutTemplateState.local
  ) {
    content = (
      <div
        className={
          "gvds-content-container local-page" +
          (hideTopNav ? " no-top-nav" : "")
        }
      >
        <div className="local-page--content">{children}</div>
      </div>
    );
  } else if (
    navWrapperContext.pageLayoutTemplate === PageLayoutTemplateState.fullspace
  ) {
    content = (
      <div
        className={
          "gvds-content-container fullspace-page" +
          (hideTopNav ? " no-top-nav" : "")
        }
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={`gvds-page-container gvds-page-container__side-nav-dominant ${
        announcementBarContext.show ? "with-announcement-bar" : ""
      }`}
    >
      <SideNav isMinimised={shouldMinimisedSideNav} />
      <div className={combinedContainerClassNames}>
        {!hideTopNav && <TopNav />}
        {content}
      </div>
    </div>
  );
};

export default NavWrapper;
