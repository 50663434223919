import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";

import CurrencyService from "../../services/CurrencyService";
import GVDSFormSingleSelect from "../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import { useTranslation } from "react-i18next";

export const formatCurrencyNameDisplay = (currency) => {
  return `${currency.name} (${currency.id})`;
};

const SelectCurrency = ({
  selected,
  onCurrencySelected,
  disabled = false,
  showAsCurrencyId = false,
  showLabel = true,
  targetName = "currency",
  isInvalid = false,
  invalidMessage = "Please select currency",
  displayAsText = false,
  placeholder = "Select currency",
}) => {
  const { t } = useTranslation();

  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    CurrencyService.getAllCurrency().then((response) => {
      setCurrencies(response);
    });
  }, []);

  const selectedCurrency = currencies.find(
    (currency) => currency.id === selected
  );

  if (displayAsText) {
    return (
      <>
        {showLabel && <Form.Label>{t("shared-input-label.currency")}</Form.Label>}
        <Form.Control
          readOnly
          plaintext
          type="text"
          value={
            !selectedCurrency
              ? ""
              : showAsCurrencyId
              ? selectedCurrency.id
              : formatCurrencyNameDisplay(selectedCurrency)
          }
        />
      </>
    );
  }

  return (
    <>
      {showLabel && <Form.Label>{t("shared-input-label.currency")}</Form.Label>}
      <GVDSFormSingleSelect
        placeholder={showAsCurrencyId ? "Currency" : placeholder}
        isSearchable={true}
        value={
          selectedCurrency
            ? {
                value: selectedCurrency.id,
                label: showAsCurrencyId
                  ? selectedCurrency.id
                  : formatCurrencyNameDisplay(selectedCurrency),
              }
            : null
        }
        options={currencies
          .sort((c1, c2) =>
            showAsCurrencyId
              ? c1.id.localeCompare(c2.id)
              : c1.name.localeCompare(c2.name)
          )
          .map((currency) => {
            return {
              value: currency.id,
              label: showAsCurrencyId
                ? currency.id
                : formatCurrencyNameDisplay(currency),
            };
          })}
        onSelect={(selected) => {
          onCurrencySelected(selected.value);
        }}
        className={targetName}
        statusMetadata={
          isInvalid
            ? FormFieldStatusMetadata.getError(invalidMessage)
            : FormFieldStatusMetadata.getDefault()
        }
        disabled={disabled}
      />
    </>
  );
};

export const SelectCurrencyCompact = ({
  currencies,
  selectedCurrencyId,
  onCurrencySelected,
  disabled = false,
  targetName = "currency",
  isInvalid = false,
  invalidMessage = "Required",
}) => {
  if (disabled) {
    return (
      <Form.Control readOnly plaintext type="text" value={selectedCurrencyId} />
    );
  }

  return (
    <>
      <GVDSFormSingleSelect
        placeholder={`Currency`}
        isSearchable={true}
        value={
          selectedCurrencyId
            ? {
                value: selectedCurrencyId,
                label: selectedCurrencyId,
              }
            : null
        }
        options={currencies
          .sort((c1, c2) => c1.id.localeCompare(c2.id))
          .map((currency) => {
            return { value: currency.id, label: currency.id };
          })}
        onSelect={(selected) => {
          onCurrencySelected(selected.value);
        }}
        className={targetName}
        statusMetadata={
          isInvalid
            ? FormFieldStatusMetadata.getError(invalidMessage)
            : FormFieldStatusMetadata.getDefault()
        }
      />
    </>
  );
};

export default SelectCurrency;
