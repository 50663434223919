import React, { useContext, useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import withAuthentication from "../../../HOC/withAuthentication";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EmissionService from "../../../../services/EmissionService";
import ToastContext from "../../../../context/ToastContext";
import { MultipleFilesUploader } from "../../../common/FileAttachments";
import LoadingSpinner from "../../../common/LoadingSpinner";
import GVFormGroup from "../../../common/GVFormGroup";
import { DateTimeUtils } from "../../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSTable, {
  ACTIONS_DATAKEY,
  ACTIONS_TABLE_HEADER,
  SORTING_TYPES,
} from "../../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";
import GVDSFormSingleYearPicker from "../../../../gvds-components/Forms/GVDSFormSingleYearPicker";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSTextButton from "../../../../gvds-components/Buttons/GVDSTextButton";

const UploadEmissionFactorModal = ({
  show,
  onClose,
  currentRecord,
  onUpload,
}) => {
  const toastContext = useContext(ToastContext);
  const disableYearPicker = !!currentRecord?.year;
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [uploadYear, setUploadYear] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [unprocessedEmissionRows, setUnprocessedEmissionRows] = useState([]);

  useEffect(() => {
    setUploadYear(currentRecord?.year);
  }, [currentRecord]);

  const onModalClose = () => {
    setUnprocessedEmissionRows([]);
    setUploadFiles([]);
    onClose();
  };

  const saveEmission = async () => {
    setIsLoadingUpload(true);

    try {
      const unprocessedEmissionRows =
        await EmissionService.saveWellToTankEmission(
          uploadYear,
          uploadFiles[0]
        );

      if (unprocessedEmissionRows.length === 0) {
        toastContext.addSuccessToast(
          <span>
            Well to tank emission factors for {uploadYear} was uploaded
            successfully.
          </span>
        );
        onModalClose();
      } else {
        setUnprocessedEmissionRows(unprocessedEmissionRows);
        toastContext.addFailToast(
          <span>
            Uploading Well to Tank Emission Factors Data for {uploadYear} failed
            partially. Please check the error list for unprocessed rows.
          </span>
        );
      }

      onUpload();
      setUploadFiles([]);
    } catch (error) {
      toastContext.addFailToast(
        <span>Failed to upload well to tank emission factors data.</span>
      );
    } finally {
      setIsLoadingUpload(false);
    }
  };

  let modalContent;

  if (isLoadingUpload) {
    modalContent = (
      <div className="p-3">
        <LoadingSpinner />
      </div>
    );
  } else {
    modalContent = (
      <>
        <GVDSModal.Body>
          <div className="gvds-text--caption__italic pt-3 pb-3">
            Note: This action will replace an existing file for the selected
            year.
          </div>
          {unprocessedEmissionRows.length > 0 && (
            <GVDSBanner
              title={"Some rows were not processed"}
              variant={GVDSBanner.Variants.error}
            >
              <ul>
                {unprocessedEmissionRows.map((row, index) => (
                  <li key={index}>{row}</li>
                ))}
              </ul>
            </GVDSBanner>
          )}
          <Row>
            <GVFormGroup as={Col} md="4">
              <Form.Label>Year</Form.Label>
              <GVDSFormSingleYearPicker
                disabled={disableYearPicker}
                placeholder="Select a year"
                selected={uploadYear}
                onChange={(year) => setUploadYear(year)}
              />
            </GVFormGroup>
          </Row>
          <MultipleFilesUploader
            files={uploadFiles}
            setFiles={setUploadFiles}
          />
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={onModalClose}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveEmission}
            disabled={!uploadYear || uploadFiles.length !== 1}
            text="Upload"
          />
        </GVDSModal.Footer>
      </>
    );
  }

  return (
    <GVDSModal
      show={show}
      onHide={() => {
        onModalClose();
      }}
      title={
        currentRecord.filename
          ? "Edit Well to Tank Emission Factors"
          : "Upload Well to Tank Emission Factors"
      }
      size={GVDSModal.Size.small}
    >
      {modalContent}
    </GVDSModal>
  );
};

const DeleteEmissionFactorFileModal = ({
  showPromptDeleteModal,
  setShowPromptDeleteModal,
  deleteCurrentlySelectedFile,
}) => {
  return (
    <GVDSModal
      show={showPromptDeleteModal}
      onHide={() => setShowPromptDeleteModal(false)}
      title="Delete Well to Tank Emission Factor"
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>
        This action cannot be undone. Are you sure?
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={() => setShowPromptDeleteModal(false)}
          text="Cancel"
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.destructive_primary}
          onClickAsyncFunc={deleteCurrentlySelectedFile}
          text="Yes, Delete."
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const ViewAllWellToTankEmissionFactors = () => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [emissionFactorFiles, setEmissionFactorFiles] = useState([]);
  const [currentEditRecord, setCurrentEditRecord] = useState({});
  const [currentToBeDeletedYear, setCurrentToBeDeletedYear] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(emissionFactorFiles, null, {
    filename: SORTING_TYPES.asc,
  });

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = () => {
    setIsLoading(true);
    EmissionService.getAllWellToTankEmissionFiles()
      .then((res) => {
        setEmissionFactorFiles(res);
      })
      .catch((e) => {
        toastContext.addFailToast(
          <span>Failed to load well to tank emission files.</span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const promptDeleteRecord = (year) => {
    setCurrentToBeDeletedYear(year);
    setShowPromptDeleteModal(true);
  };

  const downloadEmission = async (year) => {
    try {
      await EmissionService.downloadWellToTankEmission(year);
    } catch (error) {
      toastContext.addFailToast(
        <span>Failed to download Well to Tank Emission Factor.</span>
      );
    }
  };

  const deleteSelectedFile = async () => {
    try {
      await EmissionService.deleteWellToTankEmissionFile(
        currentToBeDeletedYear
      );
      toastContext.addSuccessToast(
        <span>Well to Tank Emission Factor was successfully deleted.</span>
      );

      loadFiles();
      setShowPromptDeleteModal(false);
    } catch (error) {
      toastContext.addFailToast(
        <span>Failed to delete Well to Tank Emission Factor.</span>
      );
    }
  };

  const onUploadModalClose = () => {
    setShowUploadModal(false);
    setCurrentEditRecord({});
  };

  const columns = [
    {
      header: "Year",
      dataKey: "year",
      sortable: true,
    },
    {
      header: "File Name",
      dataKey: "filename",
      renderer: (f) => (
        <div>
          <GVDSTextButton
            text={f.filename}
            onClick={() => downloadEmission(f.year)}
          />
        </div>
      ),
    },
    {
      header: "Uploaded By",
      dataKey: "updated_by",
      renderer: (f) =>
        f["updated_on"] && (
          <div className="users">
            {f["updated_by"]}
            <span className="gvds-text--caption__italic">
              &nbsp;({DateTimeUtils.formatUTCDate(f["updated_on"])})
            </span>
          </div>
        ),
    },
    {
      header: ACTIONS_TABLE_HEADER,
      dataKey: ACTIONS_DATAKEY,
      renderer: (f) => {
        return (
          <>
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={() => {
                setShowUploadModal(true);
                setCurrentEditRecord(f);
              }}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
            />
            <GVDSIconButton
              variant={iconButtonVariant.destructive}
              onClick={() => {
                promptDeleteRecord(f.year);
              }}
              icon={<GVDSIcon Icon={IconTrash} />}
              tooltipText="Delete"
            />
          </>
        );
      },
    },
  ];

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <>
        <GVDSTableCtrlContainer>
          <GVDSPagination
            startIndex={startIndex}
            endIndex={endIndex}
            total={totalDataLength}
            onChange={onPaginationChange}
          />
        </GVDSTableCtrlContainer>
        <GVDSTable
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
        />
        {emissionFactorFiles.length === 0 && (
          <div className="table__no_content">No emission factors available</div>
        )}
      </>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <Spacer />
          <GVDSButton
            variant={buttonVariant.primary}
            className="prompt-upload-emission"
            onClick={() => {
              setShowUploadModal(true);
            }}
            text="Upload File"
            icon={<GVDSIcon Icon={IconCirclePlus} />}
          />
        </PageHeader.Title>
      </PageHeader>
      {content}
      <UploadEmissionFactorModal
        show={showUploadModal}
        onClose={onUploadModalClose}
        currentRecord={currentEditRecord}
        onUpload={loadFiles}
      />
      <DeleteEmissionFactorFileModal
        showPromptDeleteModal={showPromptDeleteModal}
        setShowPromptDeleteModal={setShowPromptDeleteModal}
        deleteCurrentlySelectedFile={deleteSelectedFile}
      />
    </Container>
  );
};
export default withAuthentication(ViewAllWellToTankEmissionFactors);
