import React from "react";
import { FormFieldState, FormFieldStatusMetadata } from "./GVDSFormShared";
import { StringUtils } from "../../services/UtilsService";
import GVDSFormField from "./GVDSFormField";

const GVDSFormFieldWithCharacterCount = ({
  type = "text",
  value,
  onInput,
  name,
  maxLength,
  disabled = false,
  className = "",
  placeholder = null,
  statusMetadata = FormFieldStatusMetadata.getDefault(),
  ...props
}) => {
  const counterText = `${value ? value.length : 0}/${maxLength}`;
  const isValueOverLimit =
    StringUtils.isNotEmpty(value) && value.length > maxLength;

  let charactersStatusMetaData;

  if (isValueOverLimit) {
    let errorMessage;
    const valueOverLimitErrorMsg = `${
      value.length - maxLength
    } characters over limit.`;

    if (statusMetadata.state === FormFieldState.error) {
      errorMessage = `${valueOverLimitErrorMsg} ${statusMetadata.message}`;
    } else {
      errorMessage = valueOverLimitErrorMsg;
    }

    charactersStatusMetaData = new FormFieldStatusMetadata(
      FormFieldState.error,
      errorMessage
    );
  } else {
    charactersStatusMetaData = statusMetadata;
  }

  return (
    <div className="gvds-form__field-with-char-count__container">
      <div className="gvds-form__char-count">{counterText}</div>
      <GVDSFormField
        type={type}
        name={name}
        value={value}
        onInput={onInput}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        statusMetadata={charactersStatusMetaData}
        {...props}
      />
    </div>
  );
};

export default GVDSFormFieldWithCharacterCount;
