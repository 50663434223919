import EnergyConversion from "./EnergyConversion";
import { Container } from "react-bootstrap";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import UnitConversion from "./UnitConversion";
import React, { useContext, useEffect, useState } from "react";
import UnitsService from "../../../services/ReferenceDataServices/UnitsService";
import ToastContext from "../../../context/ToastContext";

const TroubleshootData = () => {
  const toastContext = useContext(ToastContext);
  const [isLoadingConversionFactors, setIsLoadingConversionFactors] =
    useState(true);
  const [unitConversionFactors, setUnitConversionFactors] = useState([]);

  useEffect(() => {
    UnitsService.getUnitConversionFactor()
      .then((response) => {
        setUnitConversionFactors(response);
      })
      .catch((e) => {
        toastContext.addFailToast(<span>Failed to load units.</span>);
      })
      .finally(() => setIsLoadingConversionFactors(false));
  }, []);

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Troubleshoot Conversion</h1>
        </PageHeader.Title>
      </PageHeader>
      <div>
        <EnergyConversion
          unitConversionFactors={unitConversionFactors}
          isLoadingConversionFactors={isLoadingConversionFactors}
        />
      </div>
      <div>
        <UnitConversion
          unitConversionFactors={unitConversionFactors}
          isLoadingConversionFactors={isLoadingConversionFactors}
        />
      </div>
    </Container>
  );
};

export default TroubleshootData;
