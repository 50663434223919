import React from "react";
import Button from "react-bootstrap/Button";

const monthsArray = [
  { name: "Jan", value: 1 },
  { name: "Feb", value: 2 },
  { name: "Mar", value: 3 },
  { name: "Apr", value: 4 },
  { name: "May", value: 5 },
  { name: "Jun", value: 6 },
  { name: "Jul", value: 7 },
  { name: "Aug", value: 8 },
  { name: "Sep", value: 9 },
  { name: "Oct", value: 10 },
  { name: "Nov", value: 11 },
  { name: "Dec", value: 12 },
];

const MonthSelectionButton = ({
  monthNumber,
  monthName,
  isSelected,
  isEnabled,
  onClick,
}) => {
  return (
    <Button
      className={`month-button ${isSelected ? "selected" : ""} ${
        !isEnabled ? "disabled" : ""
      }`}
      onClick={() => isEnabled && onClick(monthNumber)}
      disabled={!isEnabled}
    >
      {monthName}
    </Button>
  );
};

const DashboardFilterMonthGridSelection = ({
  selectedMonthNumbers = [],
  enabledMonthNumbers = [],
  onMonthSelect,
}) => {
  return (
    <div className="dashboard-filter__months-grid">
      {monthsArray.map((month) => {
        return (
          <MonthSelectionButton
            key={month.value}
            monthNumber={month.value}
            monthName={month.name}
            isSelected={selectedMonthNumbers?.includes(month.value)}
            isEnabled={enabledMonthNumbers?.includes(month.value)}
            onClick={onMonthSelect}
          />
        );
      })}
    </div>
  );
};

export default DashboardFilterMonthGridSelection;
