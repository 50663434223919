import React, { useEffect, useState } from "react";

import { UtilsService } from "../../services/UtilsService";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const NumOfYearsPerPage = 12;
const NumOfYearsPerRow = 3;

const YearCalendar = ({ minYear, maxYear, years, onYearSelect }) => {
  const currentYear = new Date().getFullYear();
  const [lastYearInCal, setLastYearInCal] = useState(currentYear);

  useEffect(() => {
    if (maxYear) {
      setLastYearInCal(maxYear);
    } else if (minYear) {
      setLastYearInCal(minYear + NumOfYearsPerPage - 1);
    } else {
      setLastYearInCal(currentYear + NumOfYearsPerPage - 1);
    }
  }, [minYear, maxYear]);

  const getYearCell = (year) => {
    const selected = years.indexOf(year) >= 0;
    return (
      <div
        className={
          "multi-year-calendar__year_cell" + (selected ? " selected" : "")
        }
        onClick={() => onYearSelect(year)}
      >
        {year}
      </div>
    );
  };

  const getYearRow = (firstYearInRow) => {
    return (
      <div key={firstYearInRow} className="d-flex">
        {getYearCell(firstYearInRow)}
        {getYearCell(firstYearInRow + 1)}
        {getYearCell(firstYearInRow + 2)}
      </div>
    );
  };

  const getFirstYearInColumn = () => {
    let firstYearInColumn = [];
    for (let i = 1; i < NumOfYearsPerPage; i += NumOfYearsPerRow) {
      firstYearInColumn.push(lastYearInCal - NumOfYearsPerPage + i);
    }
    return firstYearInColumn;
  };

  return (
    <div className="performance-report__multi-year-calendar-container">
      <div className="multi-year-calendar__header d-flex align-items-center justify-content-between">
        <div
          className={
            "pagination-control-button previous" +
            (!minYear || lastYearInCal - NumOfYearsPerPage > minYear
              ? ""
              : " disabled")
          }
          onClick={() =>
            setLastYearInCal(
              lastYearInCal -
                (lastYearInCal - NumOfYearsPerPage + 1 === minYear
                  ? 0
                  : NumOfYearsPerPage)
            )
          }
        >
          <GVDSIcon Icon={IconChevronLeft} />
        </div>
        <div className="title">
          {lastYearInCal - NumOfYearsPerPage + 1}-{lastYearInCal}
        </div>
        <div
          className={
            "pagination-control-button next" +
            (!maxYear || lastYearInCal < maxYear ? "" : " disabled")
          }
          onClick={() =>
            setLastYearInCal(
              lastYearInCal +
                (lastYearInCal === maxYear ? 0 : NumOfYearsPerPage)
            )
          }
        >
          <GVDSIcon Icon={IconChevronRight} />
        </div>
      </div>
      <div className="multi-year-calendar__year_cell__container">{getFirstYearInColumn().map((y) => getYearRow(y))}</div>
    </div>
  );
};

const MultiYearCalendar = ({
  minYear,
  maxYear,
  selectedYears,
  setSelectedYears,
}) => {
  const selectYear = (year) => {
    setSelectedYears(
      UtilsService.toggleItem(selectedYears, year).sort((a, b) =>
        a > b ? 1 : -1
      )
    );
  };

  return (
    <YearCalendar
      minYear={minYear}
      maxYear={maxYear}
      years={selectedYears}
      onYearSelect={selectYear}
    />
  );
};

export default MultiYearCalendar;
