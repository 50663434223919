import React, { useContext, useState } from "react";
import { Tabs } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import TabsContext from "./TabsContext";

const RoutePersistedTabs = ({
  defaultActiveKey,
  className,
  children,
  ...props
}) => {
  const tabsContext = useContext(TabsContext);
  const history = useHistory();
  const location = useLocation();

  const tabInPathHash = location.hash.substring(1);
  const [activeKey, setActiveKey] = useState(
    !!tabInPathHash ? tabInPathHash : defaultActiveKey
  );

  const changeTab = (event) => {
    setActiveKey(event);
    history.replace(
      {
        search: location.search,
        hash: event,
      },
      location.state
    );
  };

  const setClickedEventKey = (eventKey) => {
    if (eventKey !== activeKey) {
      const beforeChangeTabFunction =
        tabsContext?.getFunctionByEventKey(activeKey) ?? undefined;
      if (beforeChangeTabFunction !== undefined) {
        beforeChangeTabFunction(() => changeTab(eventKey));
      } else {
        changeTab(eventKey);
      }
    }
  };

  return (
    <Tabs
      activeKey={activeKey}
      className={className}
      onSelect={(clickedEventKey) => setClickedEventKey(clickedEventKey)}
      {...props}
    >
      {children}
    </Tabs>
  );
};

export default RoutePersistedTabs;
