import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { UtilsService } from "../../../services/UtilsService";
import AddressDisplay, {
  isAddressSearchMatch,
} from "../../common/AddressDisplay";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import Spacer from "../../../gvds-components/Layout/Spacer";

const SelectSitesAndFacilities = ({
  allSites,
  sitesInPortfolio,
  selectedSites,
  onSitesSelect,
  facilitiesInPortfolio,
  selectedFacilities,
  onFacilitiesSelect,
}) => {
  const [searchText, setSearchText] = useState("");
  const [expandedSites, setExpandedSites] = useState([]);

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [filteredSites, setFilteredSites] = useState([]);
  const [displayedSites, setDisplayedSites] = useState([]);

  const toggleSiteExpansion = (siteId) => {
    setExpandedSites(UtilsService.toggleItem(expandedSites, siteId));
  };

  const toggleSiteSelection = (siteId) => {
    onSitesSelect(UtilsService.toggleItem(selectedSites, siteId));
    const facilityIdsUnderSite = allSites
      .find((s) => s.id === siteId)
      ?.all_facilities.map((f) => f.id);

    if (facilityIdsUnderSite) {
      onFacilitiesSelect(
        UtilsService.removeItems(selectedFacilities, facilityIdsUnderSite)
      );
    }
  };
  const toggleFacilitySelection = (facilityId) => {
    onFacilitiesSelect(UtilsService.toggleItem(selectedFacilities, facilityId));
  };

  useEffect(() => {
    const filtered = allSites
      .sort((s1, s2) => s1.name.localeCompare(s2.name))
      .filter((s) => {
        let matchFilter = true;

        if (searchText.length > 0) {
          matchFilter =
            matchFilter &&
            (s.name.toLowerCase().includes(searchText.toLowerCase()) ||
              isAddressSearchMatch(s.location, searchText) ||
              s.all_facilities.find(
                (f) =>
                  f.name.toLowerCase().includes(searchText.toLowerCase()) ||
                  isAddressSearchMatch(f.location, searchText)
              ));
        }

        return matchFilter;
      });

    setFilteredSites(filtered);
    setDisplayedSites(filtered.slice(startIndex - 1, endIndex));
  }, [allSites, searchText, startIndex, endIndex]);

  const onPaginationChange = (start, end) => {
    setStartIndex(start);
    setEndIndex(end);
  };

  return (
    <div>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search Site/Facility by Name or Address"
          value={searchText}
          onInput={setSearchText}
        />
        <Spacer />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={filteredSites.length}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "20px", textAlign: "center" }}>Select</th>
            <th>Name</th>
            <th style={{ width: "140px", textAlign: "right" }}>
              Facility Count
            </th>
            <th style={{ width: "40%" }}>Site Address</th>
          </tr>
        </thead>
        <tbody>
          {displayedSites.map((site) => {
            const isSiteSearched = searchText.length > 0;
            const isAnyFacilityUnderSiteSelected = site.all_facilities.find(
              (f) => selectedFacilities.indexOf(f.id) >= 0
            );
            const isAnyFacilityUnderSiteSearched =
              searchText.length > 0 &&
              site.all_facilities.find(
                (f) =>
                  f.name.toLowerCase().includes(searchText.toLowerCase()) ||
                  isAddressSearchMatch(f.location, searchText)
              );
            const isSiteExpanded =
              isSiteSearched ||
              isAnyFacilityUnderSiteSelected ||
              isAnyFacilityUnderSiteSearched ||
              expandedSites.indexOf(site.id) >= 0
                ? site.id
                : null;
            return (
              <Accordion key={site.id} as="tr" activeKey={isSiteExpanded}>
                <td style={{ textAlign: "center" }}>
                  <Form.Check
                    checked={selectedSites.indexOf(site.id) >= 0}
                    className={`site-option ${getCheckboxColorClass(
                      site.id,
                      selectedSites,
                      sitesInPortfolio
                    )}`}
                    type="checkbox"
                    key={site.id}
                    id={site.id}
                    label=""
                    onChange={() => toggleSiteSelection(site.id)}
                  />
                </td>
                <td>
                  <div className="mt-1">{site.name}</div>
                  <Accordion.Item eventKey={site.id}>
                    <Accordion.Body>
                      <div className="mt-2">
                        {site.all_facilities.map((facility) => (
                          <Form.Check
                            checked={
                              selectedSites.indexOf(site.id) >= 0 ||
                              selectedFacilities.indexOf(facility.id) >= 0
                            }
                            disabled={selectedSites.indexOf(site.id) >= 0}
                            className={`facility-option ${getCheckboxColorClass(
                              facility.id,
                              selectedFacilities,
                              facilitiesInPortfolio
                            )}`}
                            type="checkbox"
                            key={facility.id}
                            id={facility.id}
                            label={facility.name}
                            onChange={() =>
                              toggleFacilitySelection(facility.id)
                            }
                          />
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </td>
                <td style={{ textAlign: "right" }}>
                  <div className="d-flex align-content-center justify-content-end mt-1">
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => toggleSiteExpansion(site.id)}
                    >
                      {searchText.length === 0 && (
                        <span className="collapse-toggle expand-facilities">
                          {isSiteExpanded ? "(collapse)" : "(expand)"}
                        </span>
                      )}
                    </Button>
                    <span className="ms-1">{site.all_facilities.length}</span>
                  </div>
                </td>
                <td>
                  <div className="mt-1">
                    <AddressDisplay address={site.location} />
                  </div>
                </td>
              </Accordion>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const getCheckboxColorClass = (id, selection, originallyInPortfolio) => {
  if (
    selection.indexOf(id) >= 0 &&
    originallyInPortfolio &&
    originallyInPortfolio.indexOf(id) >= 0
  ) {
    // was already in portfolio
    return "gv-select-black";
  } else if (
    selection.indexOf(id) < 0 &&
    originallyInPortfolio &&
    originallyInPortfolio.indexOf(id) >= 0
  ) {
    // removed from portfolio
    return "gv-select-danger";
  } else if (selection.indexOf(id) >= 0) {
    // new selection
    return "gv-select-selected";
  } else {
    // normal
    return "gv-select-black";
  }
};

export default SelectSitesAndFacilities;
