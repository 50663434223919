import React, { useContext, useRef, useState } from "react";
import UserAvatar from "../../../common/UserAvatar";
import UserProfileContext from "../../../../context/UserProfileContext";
import { useTranslation } from "react-i18next";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import GVDSButton from "../../../../gvds-components/Buttons/GVDSButton";
import { TextareaUtils } from "../../../../services/UtilsService";
import { CertificationRequirementCommentService } from "../../../../services/CertificationCommentService";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import { useParams, useRouteMatch } from "react-router-dom";
import CertificationContext from "../../CertificationContext";
import { CERTIFICATION_CONTENT_PAGE_REQUIREMENT } from "../../../../config/ROUTES_NAME";
import ToastContext from "../../../../context/ToastContext";
import PermissionsContext from "../../../../context/PermissionsContext";
import { PERMISSIONS } from "../../../../config/constants";

const CertificationCommentAdd = () => {
  const { t } = useTranslation();

  const { certificationId, criterionId } = useParams();
  const routeMatch = useRouteMatch(CERTIFICATION_CONTENT_PAGE_REQUIREMENT);
  const requirementId = routeMatch?.params["requirementId"];

  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();
  const permissionsCtx = useContext(PermissionsContext);

  const certificationContext = useContext(CertificationContext);

  const [isLoading, setIsLoading] = useState(false);

  const [inputComment, setInputComment] = useState("");
  const textareaRef = useRef(null);

  const inputCharCount = inputComment.length;

  const resetCommentField = () => {
    setInputComment("");
    TextareaUtils.setTextAreaHeight(textareaRef, 23);
  };

  const addComment = async () => {
    const currentInventory = userInventory.selectedInventory.get;

    setIsLoading(true);

    try {
      const commentModel =
        await CertificationRequirementCommentService.addComment(
          currentInventory.type,
          currentInventory.id,
          certificationContext.certificationId,
          requirementId,
          inputComment
        );

      certificationContext.certification.addCommentToRequirement(
        criterionId,
        requirementId,
        commentModel
      );
      certificationContext.refreshCertification();

      resetCommentField();
      toastContext.addSuccessToast(<span>Comment added</span>);
    } catch {
      toastContext.addFailToast(<span>Failed to add comment</span>);
    } finally {
      setIsLoading(false);
    }
  };

  const isAllowedToInput =
    !permissionsCtx.isLoadingPermissions &&
    permissionsCtx.permissions[PERMISSIONS.CERTIFICATION_ASSESSMENT_INPUT];

  return (
    <div className="certification-comments__add-comment-container">
      <UserAvatar fullName={user.full_name} userEmail={user.email} />
      <div className="certification-comments__add-comment-input-container">
        <div className="certification-comments__add-comment-textarea-container">
          <GVDSFormTextArea
            ref={textareaRef}
            value={inputComment}
            placeholder={t(
              "certifications.content-page.comments.input-placeholder"
            )}
            onInput={(value, e) => {
              setInputComment(value);

              TextareaUtils.dynamicallyResizeTextarea(textareaRef, e.target);
            }}
            rows={1}
            disabled={!isAllowedToInput || isLoading}
          />
        </div>
        <GVDSButton
          text={t("shared-modal.footer.save")}
          disabled={!isAllowedToInput || inputCharCount === 0 || isLoading}
          onClick={addComment}
        />
      </div>
    </div>
  );
};

export default CertificationCommentAdd;
