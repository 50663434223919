import Container from "react-bootstrap/Container";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import React from "react";
import withAuthentication from "../../HOC/withAuthentication";
import EmailAnnouncement from "./EmailAnnouncement/EmailAnnouncement";
import HomepageAnnouncement from "./HomepageAnnouncement/HomepageAnnouncement";

const Announcement = () => {
  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Announcement</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="email-announcement">
        <Tab eventKey="email-announcement" title="Email">
          <EmailAnnouncement />
        </Tab>
        <Tab eventKey="homepage-announcement" title="Landing Page">
          <HomepageAnnouncement />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default withAuthentication(Announcement);
