import MeterRecordsService from "../MeterRecordsService";
import { DateTimeUtils } from "../../../services/UtilsService";

export class OperationalDataRecordModel {
  constructor(facilityName, readingJson) {
    this.id = readingJson["id"];
    this.facilityName = facilityName;
    this.periodFrom = readingJson["period_from"];
    this.periodTo = readingJson["period_to"];
    this.value = readingJson["value"];
    this.comments = readingJson["comments"];
  }
}

export class OperationalMeterRecordsModel {
  constructor(meterRecords, facilityName) {
    this.records = meterRecords["records"].map(
      (r) => new OperationalDataRecordModel(facilityName, r)
    );
    this.dataGaps = meterRecords["data_gaps"];
    this.outOfRanges = meterRecords["out_of_ranges"];
    this.lastActivity = meterRecords["last_activity"];
  }
}

export default class OperationMeterRecordsService extends MeterRecordsService {
  updateMeter(meter, meterRecords) {
    this.meter = meter;
    this.dataGaps = meterRecords.dataGaps || [];
    this.outOfRanges = meterRecords.outOfRanges || [];
    return this;
  }

  hasNoOutOfRanges() {
    return (
      (this.meter && !this.meter.enable_unusually_high_low_alert) ||
      this.outOfRanges.length === 0
    );
  }

  getOutOfRangeDataIssues() {
    if (this.meter && this.meter.enable_unusually_high_low_alert) {
      return this.outOfRanges.map(
        (r) =>
          `Abnormally ${r["compared_to_previous"]} value from  
        ${DateTimeUtils.formatUTCDate(
          r["start"]
        )} - ${DateTimeUtils.formatUTCDate(r["end"])}`
      );
    } else {
      return [];
    }
  }
}
