import React from "react";
import { withAmplify } from "../../context/AWSAmplifyContext";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import LoadingSpinner from "../common/LoadingSpinner";

import greenviewPortalLogo from "../../assets/images/new-greenview-logo.svg";
import UserService from "../../services/UserService";
import { USER_STATUS } from "../../config/constants";
import { SIGNUP_PACKAGES } from "../../config/ROUTES_NAME";
import GVFormGroup from "../common/GVFormGroup";
import { FEATURE_FLAG_SHOW_GLTR_ON_SIGNUP } from "../../config/feature-flag-config";
import { withRouter } from "../HOC/withRouter";
import SupportContactLink from "../common/SupportContactLink";
import { getQueryHandler, removeParamFromQuery } from "../common/QueryHandler";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../gvds-components/Forms/GVDSFormShared";
import Spacer from "../../gvds-components/Layout/Spacer";
import GVDSBanner from "../../gvds-components/common/GVDSBanner";
import { IconX } from "@tabler/icons-react";
import GVDSIconSlim from "../../gvds-components/Icons/GVDSIconSlim";
import GVDSTextButton from "../../gvds-components/Buttons/GVDSTextButton";

const operationIsIdle = null;
const JUMP_TO_RESET_PASSWORD_STATE_QUERY_KEY = "reset";

const COMPONENT_STATE = {
  RESEND_INVITE: "RESENT_INVITE",
  EXPIRED_PASSWORD: "PasswordResetRequiredException",
  RESET_PASSWORD: "RESET_PASSWORD",
  LOGIN: "login",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  PASSWORD_CHANGED: "PASSWORD_CHANGED",
};

const COGNITO_ERROR_MSG = {
  PASSWORD_ATTEMPTS_EXCEEDED: "Password attempts exceeded",
  OTP_ATTEMPTS_EXCEEDED: "Attempt limit exceeded, please try after some time.",
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.redirectAfterLogin = this.redirectAfterLogin.bind(this);
    this.handleSignInFlow = this.handleSignInFlow.bind(this);
    this.login = this.login.bind(this);
    this.goToResetPasswordScreen = this.goToResetPasswordScreen.bind(this);
    this.updateNewPasswordAsRequired =
      this.updateNewPasswordAsRequired.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
    this.isNewPasswordValid = this.isNewPasswordValid.bind(this);
    this.isNewPasswordMatching = this.isNewPasswordMatching.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleConfirmNewPasswordInput =
      this.handleConfirmNewPasswordInput.bind(this);
    this.handleInputText = this.handleInputText.bind(this);
    this.isStringEntryNotEmpty = this.isStringEntryNotEmpty.bind(this);

    this.state = {
      loginState: COMPONENT_STATE.LOGIN,
      isLoading: true,
      isOperationSuccessful: operationIsIdle,
      redirectToAfterLogin: "/",
      currentUserSigningIn: null,
      isValidated: false,
      newPasswordInput: "",
      confirmNewPasswordInput: "",
      email: "",
      password: "",
      otp_code: "",
      mfaOtpInput: "",
      InvalidPasswordException: false,
      ExpiredCodeException: false,
      canOTPBeSentAgain: true,
      isInvalidResendOtpMessage: "",
      verification_email: "",
      isLockResetPasswordEmail: false,
      isLoginBlocked: false,
    };
  }

  async componentDidMount() {
    const redirectToAfterLogin = this.props.location.state
      ? this.props.location.state.redirectToAfterLogin
      : "/";
    const isAuthenticated = await this.props.amplify.isAuthenticated();

    if (isAuthenticated) {
      this.props.history.push(redirectToAfterLogin);
    } else {
      let queryHandler = getQueryHandler(this.props.location);
      const isJumpToResetPassword = queryHandler.has(
        JUMP_TO_RESET_PASSWORD_STATE_QUERY_KEY
      );
      if (isJumpToResetPassword) {
        removeParamFromQuery(
          this.props.location,
          JUMP_TO_RESET_PASSWORD_STATE_QUERY_KEY
        );
      }

      this.setState({
        isLoading: false,
        redirectToAfterLogin: redirectToAfterLogin,
        loginState: isJumpToResetPassword
          ? COMPONENT_STATE.RESET_PASSWORD
          : COMPONENT_STATE.LOGIN,
        isValidated: false,
        isOperationSuccessful: operationIsIdle,
      });
    }
  }

  redirectAfterLogin = () => {
    this.props.history.push(this.state.redirectToAfterLogin);
  };

  handleSignInFlow = (signedInUser) => {
    if (signedInUser.challengeName === COMPONENT_STATE.NEW_PASSWORD_REQUIRED) {
      this.setState({
        loginState: COMPONENT_STATE.NEW_PASSWORD_REQUIRED,
        isLoading: false,
        isValidated: false,
        isOperationSuccessful: operationIsIdle,
        password: ""
      });
    } else if (
      signedInUser.challengeName === COMPONENT_STATE.SOFTWARE_TOKEN_MFA
    ) {
      this.setState({
        loginState: COMPONENT_STATE.SOFTWARE_TOKEN_MFA,
        isLoading: false,
        isValidated: false,
        isOperationSuccessful: operationIsIdle,
      });
    } else {
      UserService.postAuthCheck()
        .then(() => {
          this.redirectAfterLogin();
        })
        .catch((error) => {
          if (
            error?.response?.status === 400 &&
            error?.response?.data?.message?.code ===
              "PasswordResetRequiredException"
          ) {
            this.setState({
              loginState: COMPONENT_STATE.EXPIRED_PASSWORD,
              isLoading: false,
              isValidated: false,
              isOperationSuccessful: operationIsIdle,
            });
          } else {
            this.setState({
              isLoading: false,
              loginState: COMPONENT_STATE.LOGIN,
              isValidated: false,
              isOperationSuccessful: false,
            });
          }
        });
    }
  };

  login = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const username = this.state.email;
    const password = this.state.password;

    try {
      const signedInUser = await this.props.amplify.signIn(username, password);
      this.setState({ currentUserSigningIn: signedInUser });

      if (!signedInUser) {
        this.setState({
          isLoading: false,
          isOperationSuccessful: false,
          email: username,
        });
      } else {
        this.setState({ isOperationSuccessful: operationIsIdle });
        this.handleSignInFlow(signedInUser);
      }
    } catch (error) {
      if (error.code === COMPONENT_STATE.EXPIRED_PASSWORD) {
        this.setState({
          loginState: COMPONENT_STATE.EXPIRED_PASSWORD,
          verification_email: username,
          isLoading: false,
          isValidated: false,
          isOperationSuccessful: operationIsIdle,
          isLockResetPasswordEmail: true,
        });
        await this.props.amplify.forgotPassword(username);
      } else if (
        error.message === COGNITO_ERROR_MSG.PASSWORD_ATTEMPTS_EXCEEDED
      ) {
        this.setState({
          isOperationSuccessful: false,
          email: username,
          isLoading: false,
          isLoginBlocked: true,
        });
      } else {
        this.setState({
          isOperationSuccessful: false,
          email: username,
          isLoading: false,
        });
      }
    }
  };

  updateNewPasswordAsRequired = async (e) => {
    e.preventDefault();

    const newPassword = this.state.password;

    try {
      const signedInUser = await this.props.amplify.updateNewPasswordAsRequired(
        newPassword
      );
      const userEmail = signedInUser.challengeParam.userAttributes.email;
      await UserService.updateLastPasswordReset(userEmail);

      this.setState({ isOperationSuccessful: operationIsIdle });

      this.handleSignInFlow(signedInUser);
    } catch (error) {
      this.setState({ isOperationSuccessful: false });
    }
  };

  handleInputText = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isStringEntryNotEmpty = (value) => {
    return value.length !== 0;
  };

  isNewPasswordValid = () => {
    return this.state.newPasswordInput.length >= 8;
  };

  handleChangePassword = (value) => {
    this.setState({ newPasswordInput: value });
  };

  handleConfirmNewPasswordInput = (value) => {
    this.setState({ confirmNewPasswordInput: value });
  };

  isNewPasswordMatching = () => {
    return this.state.newPasswordInput === this.state.confirmNewPasswordInput;
  };

  goToForgotPasswordScreen = async () => {
    this.setState({
      loginState: COMPONENT_STATE.FORGOT_PASSWORD,
      isValidated: false,
      isOperationSuccessful: operationIsIdle,
    });
  };

  backToLogin = async () => {
    this.setState({
      loginState: COMPONENT_STATE.LOGIN,
      isValidated: false,
      isOperationSuccessful: operationIsIdle,
      verification_email: "",
    });
  };

  signUp = async () => {
    this.props.history.push(SIGNUP_PACKAGES);
  };

  goToResetPasswordScreen = () => {
    this.setState({
      loginState: COMPONENT_STATE.RESET_PASSWORD,
      isValidated: false,
      isOperationSuccessful: operationIsIdle,
      isLockResetPasswordEmail: true,
    });
  };

  sendVerificationCodeForResetPassword = async (e) => {
    e.preventDefault();
    const userEmail = this.state.verification_email;
    this.setState({ isLoading: true, isInvalidResendOtpMessage: "" });

    try {
      const userStatus = await UserService.getUserStatusByEmail(userEmail);
      if (userStatus === USER_STATUS.AWAIT_LOGIN) {
        await UserService.resendUserInviteByEmail(userEmail);
        this.setState({
          isLoading: false,
          loginState: COMPONENT_STATE.RESEND_INVITE,
          isValidated: false,
          isOperationSuccessful: operationIsIdle,
        });
      } else {
        try {
          await this.props.amplify.forgotPassword(userEmail);
          this.setState({
            isLoading: false,
            loginState: COMPONENT_STATE.RESET_PASSWORD,
            email: userEmail,
            isValidated: false,
            isOperationSuccessful: operationIsIdle,
            isLockResetPasswordEmail: true,
          });
        } catch (error) {
          if (error.message === COGNITO_ERROR_MSG.OTP_ATTEMPTS_EXCEEDED) {
            this.setState({
              isLoading: false,
              verification_email: userEmail,
              isInvalidResendOtpMessage:
                "Requesting for an OTP code is temporarily unavailable due to too many request attempts. Please ensure that you have checked your junk mail or try again later.",
            });
          } else {
            this.setState({
              isLoading: false,
              isOperationSuccessful: false,
              verification_email: userEmail,
            });
          }
        }
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        isOperationSuccessful: false,
        verification_email: userEmail,
      });
    }
  };

  resendOTP = async (e) => {
    e.preventDefault();
    const username = this.state.email;

    if (this.state.email) {
      this.setState({ isLoading: true, isInvalidResendOtpMessage: "" });
      try {
        await this.props.amplify.forgotPassword(username);
        this.setState({ isLoading: false, canOTPBeSentAgain: false });
      } catch (error) {
        if (error.message === COGNITO_ERROR_MSG.OTP_ATTEMPTS_EXCEEDED) {
          this.setState({
            isLoading: false,
            isInvalidResendOtpMessage:
              "Requesting for an OTP code is temporarily unavailable due to too many request attempts. Please ensure that you have checked your junk mail or try again later.",
          });
        } else {
          this.setState({
            isLoading: false,
            isInvalidResendOtpMessage:
              "Failed to resend OTP. Please try again.",
          });
        }
      }
    } else {
      this.setState({ isInvalidResendOtpMessage: "Please provide email" });
    }
  };

  resetPassword = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const otp_code = e.target.otp_code.value;
    const newPassword = this.state.newPasswordInput;

    this.setState({ isValidated: true });
    if (
      this.isNewPasswordValid() &&
      this.isNewPasswordMatching() &&
      this.isStringEntryNotEmpty(email) &&
      this.isStringEntryNotEmpty(otp_code)
    ) {
      try {
        this.setState({ isLoading: true });
        await UserService.setNewUserPassword(email, otp_code, newPassword);
        this.setState({
          isLoading: false,
          loginState: COMPONENT_STATE.PASSWORD_CHANGED,
          isValidated: false,
          isOperationSuccessful: operationIsIdle,

          isInvalidResendOtpMessage: "",
          otp_code: "",
          newPasswordInput: "",
          confirmNewPasswordInput: "",
          ExpiredCodeException: false,
          ErrorMessage: "",
          isLockResetPasswordEmail: false,
          isLoginBlocked: false,
        });
      } catch (error) {
        const cognitoError = error.response.data.message;

        if (cognitoError.code === "InvalidPasswordException") {
          this.setState({ InvalidPasswordException: true });
          //password must have length greater than or equal to 6
        } else if (cognitoError.code === "ExpiredCodeException") {
          // Invalid code provided, please request a code again.
          this.setState({
            ExpiredCodeException: true,
            ErrorMessage:
              "Invalid Email Address or OTP Code provided, please check that your Email Address and OTP Code are accurate.",
          });
        } else if (
          cognitoError.code === "CodeMismatchException" ||
          error.response.status === 404
        ) {
          this.setState({
            ExpiredCodeException: true,
            ErrorMessage:
              "Invalid Email Address or OTP Code provided, please check that your Email Address and OTP Code are accurate.",
          });
        }

        this.setState({ isLoading: false, isValidated: false });
      }
    }
    if (!this.isStringEntryNotEmpty(otp_code)) {
      this.setState({
        ExpiredCodeException: true,
        ErrorMessage: "Please enter your 6-digit OTP code",
      });
    }
  };

  verifyOtp = async (e) => {
    e.preventDefault();

    const otp = this.state.mfaOtpInput;
    this.setState({ isLoading: true });

    try {
      const signedInUser = await this.props.amplify.verifyOtp(
        this.state.currentUserSigningIn,
        otp
      );

      if (signedInUser) {
        this.setState({
          isOperationSuccessful: operationIsIdle,
        });
        this.handleSignInFlow(signedInUser);
      } else {
        this.setState({ isOperationSuccessful: false });
      }
    } catch (error) {
      this.setState({ isOperationSuccessful: false });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    let content = null;

    if (this.state.isLoading) {
      content = <LoadingSpinner />;
    } else if (this.state.loginState === COMPONENT_STATE.LOGIN) {
      const isFailedToLogin =
        this.state.isOperationSuccessful !== null &&
        this.state.isOperationSuccessful === false;
      content = (
        <>
          <Row>
            <Col>
              <div className="d-flex justify-content-center">
                <img
                  src={greenviewPortalLogo}
                  alt="Greenview Portal"
                  width="200"
                />
              </div>
            </Col>
          </Row>
          {this.state.isLoginBlocked && (
            <GVDSBanner
              title="Login blocked temporarily"
              variant={GVDSBanner.Variants.error}
            >
              For security reasons, your account is temporarily blocked as there
              were too many failed login attempts. Please try again later.
            </GVDSBanner>
          )}
          <Form className="border-bottom mt-4" onSubmit={this.login}>
            <GVFormGroup controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <GVDSFormField
                type="email"
                className="login-username__input"
                placeholder="Enter email"
                name="email"
                value={this.state.email}
                onInput={(value, e) => this.handleInputText(e)}
                statusMetadata={
                  isFailedToLogin && !this.state.isLoginBlocked
                    ? FormFieldStatusMetadata.getError()
                    : FormFieldStatusMetadata.getDefault()
                }
                autoFocus
              />
            </GVFormGroup>

            <GVFormGroup controlId="formBasicPassword">
              <div className="d-flex flex-row align-items-baseline">
                <Form.Label>Password</Form.Label>
                <Spacer />
                <GVDSTextButton
                  className="login__get-new-password-btn"
                  onClick={this.goToForgotPasswordScreen}
                  text="Get a new password"
                />
              </div>

              <GVDSFormField
                type="password"
                className="login-password__input "
                placeholder="Password"
                statusMetadata={
                  isFailedToLogin && !this.state.isLoginBlocked
                    ? FormFieldStatusMetadata.getError(
                        "The email or password you have entered is incorrect. Please try again."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
                value={this.state.password}
                onInput={(value) => this.setState({ password: value })}
              />
            </GVFormGroup>
            <GVDSButton
              variant={buttonVariant.primary}
              className="auth-submit__button login-submit__button mb-4"
              isSubmitButton={true}
              text="Login"
            />
          </Form>

          <div className="d-flex flex-row align-items-center justify-content-center">
            <GVDSButton
              variant={buttonVariant.tertiary}
              className="login__sign-up-btn"
              onClick={this.signUp}
              text={
                FEATURE_FLAG_SHOW_GLTR_ON_SIGNUP
                  ? "Sign up"
                  : "Sign up for a 14-day trial — it’s free"
              }
            />
          </div>
        </>
      );
    } else if (
      this.state.loginState === COMPONENT_STATE.NEW_PASSWORD_REQUIRED
    ) {
      content = (
        <>
          <Row>
            <Col>
              <div className="auth_header">Change password</div>
            </Col>
          </Row>
          <Form onSubmit={this.updateNewPasswordAsRequired}>
            <GVFormGroup controlId="formBasicPassword">
              <Form.Label>Please enter a new password</Form.Label>
              <div className="form-label-description">
                Use at least 8 characters. The longer the password, the better.
              </div>
              <GVDSFormField
                type="password"
                autoComplete="new-password"
                className="login-password__input"
                placeholder="Password"
                value={this.state.password}
                onInput={(value) => this.setState({ password: value })}
              />
            </GVFormGroup>
            <GVDSButton
              variant={buttonVariant.primary}
              className="login-submit__button auth-submit__button"
              isSubmitButton={true}
              text="Change My Password"
            />
          </Form>
        </>
      );
    } else if (this.state.loginState === COMPONENT_STATE.SOFTWARE_TOKEN_MFA) {
      content = (
        <>
          <Row>
            <Col>
              <div className="auth_header">Verify that it’s you</div>
            </Col>
          </Row>
          <Form onSubmit={this.verifyOtp}>
            <GVFormGroup>
              <Form.Label>
                Enter the 6-digit code displayed on your authenticator
                application from your phone.
              </Form.Label>
              <GVDSFormField
                type="number"
                placeholder="Enter OTP"
                value={this.state.mfaOtpInput}
                onInput={(value) => {
                  this.setState({ mfaOtpInput: value });
                }}
                statusMetadata={
                  this.state.isOperationSuccessful === false
                    ? FormFieldStatusMetadata.getError(
                        "The code you’ve entered is incorrect. Please try again."
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <GVDSButton
              variant={buttonVariant.primary}
              className="auth-submit__button"
              isSubmitButton={true}
              text="Verify"
            />
          </Form>
        </>
      );
    } else if (this.state.loginState === COMPONENT_STATE.FORGOT_PASSWORD) {
      content = (
        <>
          <Row>
            <Col>
              <div className="auth_header">Get New Password</div>
            </Col>
          </Row>
          {this.state.isInvalidResendOtpMessage && (
            <GVDSBanner
              title="Error detected"
              variant={GVDSBanner.Variants.error}
            >
              {this.state.isInvalidResendOtpMessage}
            </GVDSBanner>
          )}
          <div className="forgot_pass_message">
            Enter the email address associated with your account below and we’ll
            send you an OTP code to reset your password.
          </div>

          <Form onSubmit={this.sendVerificationCodeForResetPassword}>
            <GVFormGroup controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <GVDSFormField
                name="verification_email"
                type="email"
                className="otp-username__input"
                placeholder="Enter email"
                value={this.state.verification_email}
                onInput={(value, e) => this.handleInputText(e)}
                statusMetadata={
                  this.state.isOperationSuccessful !== null &&
                  this.state.isOperationSuccessful === false
                    ? FormFieldStatusMetadata.getError("Wrong email address")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>
            <div>
              <GVDSButton
                variant={buttonVariant.primary}
                className="login-submit__button auth-submit__button"
                isSubmitButton={true}
                text="Send OTP code"
              />
            </div>
            <div>
              <GVDSButton
                className="login__back-to-login-btn"
                variant={buttonVariant.tertiary}
                onClick={this.backToLogin}
                text="Return to Login Page"
              />
            </div>
          </Form>
        </>
      );
    } else if (this.state.loginState === COMPONENT_STATE.EXPIRED_PASSWORD) {
      content = (
        <>
          <Row>
            <Col>
              <div className="auth_header">Your password has expired</div>
            </Col>
          </Row>

          <div className="forgot_pass_message">
            To ensure your account security, you are required to update the
            password to your Greenview Portal account every 6 months.
            <br />
            <br />
            Click “Update Password” to reset your password.
          </div>

          <GVDSButton
            variant={buttonVariant.primary}
            className="login-submit__button auth-submit__button"
            onClick={this.goToResetPasswordScreen}
            text="Update Password"
          />
        </>
      );
    } else if (this.state.loginState === COMPONENT_STATE.RESET_PASSWORD) {
      const authTitle = "Reset Password";
      const authMessage = (
        <div className="forgot_pass_message">
          We have emailed you the OTP code if you are in the system. Please enter the OTP code below.
        </div>
      );
      content = (
        <>
          <Row>
            <Col>
              <div className="auth_header">{authTitle}</div>
            </Col>
          </Row>
          <>
            {authMessage}
            {this.state.canOTPBeSentAgain ? (
              <p>
                Didn't receive your OTP code? Check if you're using the correct email address or {" "}
                <GVDSTextButton
                  onClick={this.resendOTP}
                  text="resend OTP code."
                />
              </p>
            ) : (
              <div className="resent_otp_message">
                We have resent the OTP code. Please check your inbox again.
              </div>
            )}

            {this.state.isInvalidResendOtpMessage && (
              <GVDSBanner
                title="Invalid OTP"
                variant={GVDSBanner.Variants.error}
              >
                {this.state.isInvalidResendOtpMessage}
              </GVDSBanner>
            )}
          </>
          <Form onSubmit={this.resetPassword}>
            <GVFormGroup controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <GVDSFormField
                name="email"
                type="email"
                disabled={this.state.isLockResetPasswordEmail}
                className="login-username__input"
                placeholder="Enter email"
                value={this.state.email}
                onInput={(value, e) => this.handleInputText(e)}
                statusMetadata={
                  this.state.isValidated &&
                  !this.isStringEntryNotEmpty(this.state.email)
                    ? FormFieldStatusMetadata.getError("Please provide email")
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>

            <GVFormGroup controlId="verificationCode">
              <Form.Label>OTP Code</Form.Label>
              <GVDSFormField
                name="otp_code"
                type="number"
                placeholder="Enter your 6-digit OTP code"
                value={this.state.otp_code}
                onInput={(value, e) => this.handleInputText(e)}
                statusMetadata={
                  this.state.ExpiredCodeException !== null &&
                  this.state.ExpiredCodeException === true
                    ? FormFieldStatusMetadata.getError(this.state.ErrorMessage)
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>

            <GVFormGroup controlId="formBasicPassword">
              <Form.Label>New Password</Form.Label>
              <div className="form-label-description">
                Use at least 8 characters. The longer the password, the better.
              </div>
              <GVDSFormField
                type="password"
                autoComplete="new-password"
                className="new-password__input"
                value={this.state.newPasswordInput}
                onInput={(value) => this.handleChangePassword(value)}
                statusMetadata={
                  this.state.isValidated && !this.isNewPasswordValid()
                    ? FormFieldStatusMetadata.getError(
                        <>
                          <GVDSIconSlim Icon={IconX} />
                          Your password should have at least 8 characters
                        </>
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>

            <GVFormGroup controlId="formConfirmBasicPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <GVDSFormField
                type="password"
                autoComplete="new-password"
                className="confirm-password__input"
                value={this.state.confirmNewPasswordInput}
                onInput={(value) => this.handleConfirmNewPasswordInput(value)}
                statusMetadata={
                  this.state.isValidated && !this.isNewPasswordMatching()
                    ? FormFieldStatusMetadata.getError(
                        <>
                          <GVDSIconSlim Icon={IconX} />
                          New password is not matching
                        </>
                      )
                    : FormFieldStatusMetadata.getDefault()
                }
              />
            </GVFormGroup>

            <GVDSButton
              variant={buttonVariant.primary}
              className="change_password-submit__button auth-submit__button"
              isSubmitButton={true}
              text="Update Password"
            />
          </Form>
        </>
      );
    } else if (this.state.loginState === COMPONENT_STATE.PASSWORD_CHANGED) {
      content = (
        <>
          <Row>
            <Col>
              <div className="auth_header">Password updated</div>
            </Col>
          </Row>
          <div className="forgot_pass_message text-center">
            Your password has been updated successfully.
          </div>

          <GVDSButton
            variant={buttonVariant.secondary}
            className="login__back-to-login-btn"
            onClick={this.backToLogin}
            text="Return to Login Page"
          />
        </>
      );
    } else if (this.state.loginState === COMPONENT_STATE.RESEND_INVITE) {
      content = (
        <>
          <Row>
            <Col>
              <div className="auth_header">Invite has been re-sent</div>
            </Col>
          </Row>

          <div>
            <p>It looks like you haven’t logged in before.</p>
            <p>
              We’ve just re-sent your email invitation along with a new
              temporary password. Just log in with the temporary password and
              you’ll be prompted to set up your own new password in no time.
            </p>
            <p>
              Please don't hesitate to reach out to Portal Support at{" "}
              <SupportContactLink /> for assistance.
            </p>
          </div>

          <GVDSButton
            variant={buttonVariant.secondary}
            className="login__back-to-login-btn"
            onClick={this.backToLogin}
            text="Return to Login Page"
          />
        </>
      );
    }

    return (
      <Container fluid className="h-100">
        <Row className="h-100 justify-content-center align-items-center">
          <Col xl={4} lg={6} md={8} s={12}>
            <Container fluid className="login-section">
              <Row>
                <Col>{content}</Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withAmplify(withRouter(Login));
