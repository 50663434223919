import React, { useEffect, useRef, useState } from "react";
import GVDSIconSlim from "../../gvds-components/Icons/GVDSIconSlim";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import Modal from "react-bootstrap/Modal";

const DASHBOARD_SELECTOR_MODAL_TOP_GAP_IN_PX = 6;

const DashboardSelectorModal = ({
  selectedDashboard,
  switchDashboard,
  dashboards,
  isOpen,
  onClose,
  triggerRef,
}) => {
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.bottom,
        left: rect.left,
      });
    }
  }, [isOpen, triggerRef]);

  const handleOptionClick = (dashboardId) => {
    switchDashboard(dashboardId);
    onClose();
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      className="dashboard-selector__modal"
      dialogClassName="dashboard-selector__modal__dialog"
      style={{
        top: `${modalPosition.top + DASHBOARD_SELECTOR_MODAL_TOP_GAP_IN_PX}px`,
        left: `${modalPosition.left}px`,
      }}
      animation={false}
      container={document.body}
      backdropClassName="dashboard-selector__modal__backdrop"
    >
      <Modal.Body className="p-0">
        {dashboards.map((dashboard) => (
          <div
            className={`dashboard-selector__modal__option ${
              selectedDashboard.id === dashboard.id ? "selected" : ""
            }`}
            key={dashboard.id}
            onClick={() => handleOptionClick(dashboard.id)}
          >
            <div>{dashboard.name}</div>
            {selectedDashboard.id === dashboard.id && (
              <GVDSIconSlim
                Icon={IconCheck}
                className="gvds-color--success ms-auto"
              />
            )}
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

const DashboardSelector = ({
  selectedDashboard,
  switchDashboard,
  dashboards,
}) => {
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const triggerRef = useRef(null);

  const onTriggerModal = () => {
    setShowSelectionModal(!showSelectionModal);
  };

  const onCloseModal = () => {
    setShowSelectionModal(false);
  };

  return (
    <>
      <div
        ref={triggerRef}
        className="dashboard-selector__trigger"
        onClick={onTriggerModal}
      >
        Switch dashboard <GVDSIconSlim Icon={IconChevronDown} />
      </div>
      <DashboardSelectorModal
        selectedDashboard={selectedDashboard}
        switchDashboard={switchDashboard}
        dashboards={dashboards}
        isOpen={showSelectionModal}
        onClose={onCloseModal}
        triggerRef={triggerRef}
      />
    </>
  );
};

export default DashboardSelector;
