import React from "react";
import GVDSTag, {
  VARIANT_BY_RESOURCE_TYPE,
} from "../../../gvds-components/common/GVDSTag";

const UserManagedTag = ({ tag }) => {
  return (
    <UserManagedTagDisplay tagText={tag.name} resourceType={tag.resourceType} />
  );
};

export const UserManagedTagDisplay = ({ tagText, resourceType }) => {
  return (
    <GVDSTag variant={VARIANT_BY_RESOURCE_TYPE[resourceType]}>
      {tagText}
    </GVDSTag>
  );
};

export default UserManagedTag;
