import React, { useContext, useEffect, useState } from "react";
import ToastContext from "../../../context/ToastContext";
import SiteService from "../../../services/SiteService";
import FacilityService from "../../../services/FacilityService";
import { get } from "lodash";
import LoadingSpinner from "../../common/LoadingSpinner";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GVFormGroup from "../../common/GVFormGroup";
import { getOptionByValueFromIdName } from "../../common/Forms/SingleSelect";
import withAuthentication from "../../HOC/withAuthentication";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";

const CreateFacility = ({ siteId, onCreate }) => {
  const toastContext = useContext(ToastContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [facilityTypeOptions, setFacilityTypeOptions] = useState([]);

  useEffect(() => {
    SiteService.getSiteFacilityTypeOptions().then((data) => {
      setFacilityTypeOptions(data);
    });
  }, []);

  const [facilityName, setFacilityName] = useState("");
  const [facilityType, setFacilityType] = useState({ id: "", name: "" });

  const [validated, setValidated] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const saveFacility = async (event) => {
    event.preventDefault();
    setValidated(true);
    if (!isFacilityNameValid() || !isFacilityTypeValid()) {
      return;
    }
    setIsSaving(true);
    setErrorMessage({});
    try {
      await FacilityService.createNewFacility(
        siteId,
        facilityName,
        facilityType
      );
      setIsSaving(false);
      setShow(false);
      toastContext.addSuccessToast(<span>Facility successfully created.</span>);
      onCreate();
    } catch (error) {
      setIsSaving(false);
      if (error.response != null && error.response.status === 400) {
        if (error.response.data.message.name != null) {
          setErrorMessage({ name: error.response.data.message.name.name });
        } else {
          toastContext.addFailToast(<span>Failed to create a facility.</span>);
        }
      } else {
        toastContext.addFailToast(<span>Failed to create a facility.</span>);
      }
    }
  };

  const isFacilityNameWithError = () => {
    return !!get(errorMessage, "name");
  };

  const isFacilityNameValid = () => {
    return facilityName.length > 0;
  };

  const isFacilityTypeValid = () => {
    return facilityType.id.length > 0;
  };

  if (isSaving) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <GVDSButton
        variant={buttonVariant.secondary}
        className="create_facility_button"
        onClick={handleShow}
        text="Create a new facility"
      />

      <GVDSModal
        title="Create Facility"
        size={GVDSModal.Size.medium}
        show={show}
        onHide={handleClose}
      >
        <GVDSModal.Body>
          <Row>
            <Col sm={5}>
              <GVFormGroup controlId="facilityName">
                <Form.Label>Facility Name</Form.Label>
                <GVDSFormField
                  placeholder="Enter facility name"
                  className="facility_name_input"
                  value={facilityName}
                  onInput={(value) => setFacilityName(value)}
                  statusMetadata={
                    validated && !isFacilityNameValid()
                      ? FormFieldStatusMetadata.getError(
                          "Please provide Facility Name."
                        )
                      : validated && isFacilityNameWithError()
                      ? FormFieldStatusMetadata.getError(
                          get(errorMessage, "name")
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              </GVFormGroup>
            </Col>
          </Row>
          <GVFormGroup controlId="facilityType">
            <Form.Label>Facility Type</Form.Label>
            <GVDSFormSingleSelect
              placeholder="Select facility type"
              className="select__facility-type"
              value={
                facilityType.id
                  ? getOptionByValueFromIdName(
                      facilityType.id,
                      facilityTypeOptions
                    )
                  : null
              }
              options={facilityTypeOptions.map((option) => {
                return { value: option.id, label: option.name };
              })}
              onSelect={(selectedOption) => {
                setFacilityType({
                  id: selectedOption.value,
                  name: selectedOption.label,
                });
              }}
              statusMetadata={
                validated && !isFacilityTypeValid()
                  ? FormFieldStatusMetadata.getError(
                      "Please select a facility type."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            text="Create Facility"
            onClick={saveFacility}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default withAuthentication(CreateFacility);
