import StatusLabel from "../../gvds-components/common/StatusLabel";
import { TASK_MANAGEMENT_STATUS } from "../../config/constants";

export const TASK_MANAGEMENT_STATUS_ORDER = {
  [TASK_MANAGEMENT_STATUS.NOT_STARTED]: 1,
  [TASK_MANAGEMENT_STATUS.ACKNOWLEDGED]: 2,
  [TASK_MANAGEMENT_STATUS.IN_PROGRESS]: 3,
  [TASK_MANAGEMENT_STATUS.COMPLETED]: 4,
  [TASK_MANAGEMENT_STATUS.CANCELLED]: 5,
};

export const TASK_MANAGEMENT_STATUS_COLOR = {
  [TASK_MANAGEMENT_STATUS.NOT_STARTED]: StatusLabel.Colors.red,
  [TASK_MANAGEMENT_STATUS.ACKNOWLEDGED]: StatusLabel.Colors.blue,
  [TASK_MANAGEMENT_STATUS.IN_PROGRESS]: StatusLabel.Colors.yellow,
  [TASK_MANAGEMENT_STATUS.COMPLETED]: StatusLabel.Colors.green,
  [TASK_MANAGEMENT_STATUS.CANCELLED]: StatusLabel.Colors.gray,
};

export const getTranslatedTaskStatus = (t, status) => {
  switch (status) {
    case TASK_MANAGEMENT_STATUS.NOT_STARTED:
      return t("landing-page.widget-task-mgmt.statuses.not-started");
    case TASK_MANAGEMENT_STATUS.ACKNOWLEDGED:
      return t("landing-page.widget-task-mgmt.statuses.acknowledged");
    case TASK_MANAGEMENT_STATUS.IN_PROGRESS:
      return t("landing-page.widget-task-mgmt.statuses.in-progress");
    case TASK_MANAGEMENT_STATUS.COMPLETED:
      return t("landing-page.widget-task-mgmt.statuses.completed");
    case TASK_MANAGEMENT_STATUS.CANCELLED:
      return t("landing-page.widget-task-mgmt.statuses.cancelled");
  }
};

export const TASK_MANAGEMENT_ARCHIVE_ACTION = {
  ARCHIVED: "Archived",
  UNARCHIVED: "Unarchived",
};
