import React from "react";
import GVDSButton, { buttonVariant } from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";

const DeleteBrandFlagPrompt = ({ show, cancel, deleteBrandFlag, proceed }) => {
  const messageBody = (
    <>
      {!deleteBrandFlag && (
        <div>
          <p>
            This Brand Flag cannot be deleted because it is currently associated
            with Sites.
          </p>
          <p>
            In order to delete this Brand Flag, please remove all associations
            with this Brand Flag.
          </p>
        </div>
      )}
      {deleteBrandFlag && (
        <div>
          <p>
            Fields with this selection option will return to default. This
            action cannot be undone. Are you sure?
          </p>
        </div>
      )}
    </>
  );

  return (
    <GVDSModal
      show={show}
      onHide={cancel}
      title={deleteBrandFlag
        ? "Option will disappear from the system."
        : "Unable to Delete Brand Flag"}
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body>{messageBody}</GVDSModal.Body>
      <GVDSModal.Footer>
        {deleteBrandFlag && (
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancel}
            text="Cancel"
          />
        )}
        {deleteBrandFlag ? (
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={proceed}
            text="Yes, Delete."
          />
        ) : (
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={cancel}
            text="Okay"
          />
        )}
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default DeleteBrandFlagPrompt;
