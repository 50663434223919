import React, { useContext } from "react";
import UserProfileContext from "../../../context/UserProfileContext";
import UserInventoryContext, {
  hasHotelInWorkspace,
  isMultipleWorkspaces,
} from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import FeatureLockMessage from "../../../components/common/Feature/FeatureLockMessage";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../../components/common/Feature/FeatureCheckPageWrapper";
import { getApplicability } from "../../../config/page-config";
import getFeaturesRequiredForPath from "../../../components/common/Feature/FeaturesRequiredByPath";
import GVDSButton, { buttonVariant } from "../../Buttons/GVDSButton";
import WorkspaceNavContext from "../TopNavComponents/WorkspaceNavContext";
import NoSubscriptionMessage from "../../../components/common/Feature/NoSubscriptionMesage";
import NavWrapperContext, {
  PageLayoutTemplateState,
} from "../NavWrapperContext";

const NotApplicableInventory = ({ applicability }) => {
  const workspaceNavContext = useContext(WorkspaceNavContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedTreeNode = userInventory.selectedTreeNode.get;
  const treeNodes = userInventory.treeNodes.get;

  if (!selectedTreeNode) {
    return null;
  }

  const hasOtherItems = isMultipleWorkspaces(selectedTreeNode);
  const hasHotel = hasHotelInWorkspace(treeNodes);

  const showWorkspaceSwitcher =
    hasOtherItems && (applicability.isHotelOnly ? hasHotel : true);

  return (
    <div className="page-wrapper--unavailable-container">
      <div className="message">
        This module is currently only available for{" "}
        {applicability.getApplicableMessage()}.
        {showWorkspaceSwitcher && (
          <div className="mt-4">
            <GVDSButton
              variant={buttonVariant.primary}
              text={`Switch to ${applicability.getSwitchWorkspaceMessage()}`}
              onClick={workspaceNavContext.open}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const PageWrapper = ({ configRouteName, children }) => {
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;

  const navWrapperContext = useContext(NavWrapperContext);

  let content;

  if (userInventory.isLoadingInventory.get || !selectedInventory) {
    content = (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else {
    const applicability = getApplicability(configRouteName);
    const anyFeaturesRequired = getFeaturesRequiredForPath(configRouteName);
    const selectedTreeNode = userInventory.selectedTreeNode.get;

    if (!applicability.isApplicable(selectedTreeNode)) {
      content = <NotApplicableInventory applicability={applicability} />;
    } else {
      const subscriptionFeatureStatus = getSubscriptionFeatureStatus(
        anyFeaturesRequired,
        userInventory.isLoadingInventory.get,
        userInventory.selectedTreeNode,
        userProfile
      );

      const hasNoSubscription =
        subscriptionFeatureStatus ===
        SUBSCRIPTION_FEATURE_STATUS.NO_SUBSCRIPTION;
      const hasSubscriptionNoAccess =
        subscriptionFeatureStatus ===
        SUBSCRIPTION_FEATURE_STATUS.HAS_SUBSCRIPTION_NO_ACCESS;

      if (hasNoSubscription) {
        content = <NoSubscriptionMessage />;
      } else if (hasSubscriptionNoAccess) {
        content = <FeatureLockMessage />;
      } else {
        content = children;
      }

      if (
        navWrapperContext.pageLayoutTemplate ===
          PageLayoutTemplateState.fullspace &&
        (hasNoSubscription || hasSubscriptionNoAccess)
      ) {
        content = <div className="no-content-container">{content}</div>;
      }
    }
  }

  return content;
};
