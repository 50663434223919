import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../../../HOC/withAuthentication";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { Container } from "react-bootstrap";
import FilterSearchBox from "../../../../gvds-components/common/FilterSearchBox";
import { REFERENCE_DATA } from "../../../../config/ROUTES_NAME";
import { useHistory } from "react-router-dom";
import ClimateZoneService from "../../../../services/ReferenceDataServices/ClimateZoneService";
import ToastContext from "../../../../context/ToastContext";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import GVDSTableCtrlContainer from "../../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSPagination from "../../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTable, {SORTING_TYPES} from "../../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../../gvds-components/Table/GVDSTableHook";
import { SystemToolboxSearchKeysByPageName } from "../../../../config/search-config";

const KoppenGeiger = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);

  const [koppenGeigerList, setKoppenGeiger] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const {
    filteredSortedData,
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(
    koppenGeigerList,
    SystemToolboxSearchKeysByPageName.referenceData.koppenGeiger,
    {
      name: SORTING_TYPES.asc,
    }
  );

  const loadRecord = () => {
    setIsLoading(true);
    ClimateZoneService.KoppenGeigerClimateZone()
      .then((response) => {
        setIsLoading(false);
        setKoppenGeiger(response);
      })
      .catch((e) => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load Koppen Geiger climate zone.</span>
        );
      });
  };

  useEffect(() => {
    loadRecord();
  }, []);

  const columns = [
    {
      header: "Climate Zone Name",
      dataKey: "name",
      sortable: true,
    },
    {
      header: "Climate Zone Type",
      dataKey: "type",
      sortable: true,
    },
  ];

  const goToReferenceData = () => {
    history.push(REFERENCE_DATA);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return To Reference Data"
          onClick={goToReferenceData}
        />
        <PageHeader.Title>
          <h1>Climate Zones (Köppen-Geiger)</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          Temperate climate zones within the Köppen-Geiger system is selected
          over Bailey’s Ecoregion climate zones for Performance Benchmarking
          when a site has filled both Climate Zone systems in their site
          details.
        </PageHeader.Description>
      </PageHeader>
      <GVDSTableCtrlContainer>
        <FilterSearchBox
          placeholder="Search by climate zone"
          value={searchText}
          onInput={setSearchText}
        />
        <GVDSPagination
          startIndex={startIndex}
          endIndex={endIndex}
          total={totalDataLength}
          onChange={onPaginationChange}
        />
      </GVDSTableCtrlContainer>
      <GVDSTable
        columns={columns}
        dataToDisplay={currentPageData}
        startIndex={startIndex}
        sortKeys={sortKeys}
        setSortKeys={setSortKeys}
      />
      {filteredSortedData.length === 0 && (
        <div className="table__no_content">No record found</div>
      )}
    </Container>
  );
};

export default withAuthentication(KoppenGeiger);
