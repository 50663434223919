import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import { IconTrash, IconUpload } from "@tabler/icons-react";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import CertificationEvidenceUploadModal from "./CertificationEvidenceUploadModal";
import UserAvatar from "../../../common/UserAvatar";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import Dropdown from "react-bootstrap/Dropdown";
import OtherActionDropdownToggle from "../../../common/OtherActionDropdownToggle";
import UserProfileContext from "../../../../context/UserProfileContext";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import moment from "moment/moment";
import GVDSTextButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSTextButtonWithLoadingAction";
import { CertificationAssessmentService } from "../../../../services/CertificationService";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import CertificationContext from "../../CertificationContext";
import { downloadFromPresignedS3Url } from "../../../common/FileAttachments";
import PermissionsContext from "../../../../context/PermissionsContext";
import { PERMISSIONS } from "../../../../config/constants";
import GVDSButtonWithLoadingAction from "../../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import { useParams, useRouteMatch } from "react-router-dom";
import { CERTIFICATION_CONTENT_PAGE_REQUIREMENT } from "../../../../config/ROUTES_NAME";

const CertificationEvidenceListItem = ({ evidence }) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();
  const permissionsCtx = useContext(PermissionsContext);

  const [showPromptDeleteModal, setShowDeletePromptModal] = useState(false);

  const triggerDelete = () => {
    setShowDeletePromptModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeletePromptModal(false);
  };

  const downloadFile = async () => {
    try {
      const currentInventory = userInventory.selectedInventory.get;
      const params = {
        resource_type: currentInventory.type,
        resource_id: currentInventory.id,
      };
      await downloadFromPresignedS3Url(evidence.file.downloadLink, params);
    } catch (e) {
      toastContext.addFailToast(<span>Failed to download file</span>);
    }
  };

  const createdBy = evidence.createdBy;
  const email = createdBy["email"];
  const fullName = createdBy["full_name"];
  const isCreatorOfEvidence = userProfile.email === email;
  const isAllowedToDeleteOthers =
    !permissionsCtx.isLoadingPermissions &&
    permissionsCtx.permissions[
      PERMISSIONS.CERTIFICATION_ASSESSMENT_DELETE_OTHERS
    ];

  const timestampObj = moment.utc(evidence.createdOn);
  timestampObj.local();

  const timestampDisplay = timestampObj.format("DD MMM YYYY");

  return (
    <div
      key={evidence.id}
      className="certification-evidence__evidence-list-item__container"
    >
      <div className="evidence-file-name">
        <GVDSTextButtonWithLoadingAction
          className="text-start"
          text={evidence.file.filename}
          onClickAsyncFunc={downloadFile}
        />
      </div>
      <UserAvatar userEmail={email} fullName={fullName} />
      <div className="text-nowrap ms-1">{timestampDisplay}</div>
      <Dropdown
        className={
          isCreatorOfEvidence || isAllowedToDeleteOthers ? "" : "invisible"
        }
      >
        <Dropdown.Toggle as={OtherActionDropdownToggle} />

        <Dropdown.Menu>
          {(isCreatorOfEvidence || isAllowedToDeleteOthers) && (
            <Dropdown.Item
              key="delete"
              href="#"
              className="danger"
              onClick={triggerDelete}
            >
              <GVDSIcon Icon={IconTrash} /> {t("shared.delete")}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <DeleteCertificationEvidencePrompt
        show={showPromptDeleteModal}
        close={closeDeleteModal}
        evidence={evidence}
      />
    </div>
  );
};

const DeleteCertificationEvidencePrompt = ({ show, close, evidence }) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const certificationContext = useContext(CertificationContext);

  const { certificationId, criterionId } = useParams();
  const routeMatch = useRouteMatch(CERTIFICATION_CONTENT_PAGE_REQUIREMENT);
  const requirementId = routeMatch?.params["requirementId"];
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    if (!isDeleting) {
      close();
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const currentInventory = userInventory.selectedInventory.get;

      await CertificationAssessmentService.deleteRequirementEvidenceFile(
        currentInventory.type,
        currentInventory.id,
        certificationContext.certificationId,
        requirementId,
        evidence.id
      );
      certificationContext.certification.removeEvidenceFileFromRequirement(
        criterionId,
        requirementId,
        evidence.id
      );
      handleClose();
      certificationContext.refreshCertification();
      toastContext.addSuccessToast(<span>File deleted</span>);
    } catch {
      toastContext.addFailToast(<span>Failed to delete file</span>);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <GVDSModal
      title={`Delete evidence?`}
      size={GVDSModal.Size.small}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>
        <strong>{evidence.file.filename}</strong> will be permanently removed
        from this requirement.
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          text={t("shared-modal.footer.cancel")}
          disabled={isDeleting}
        />
        <GVDSButtonWithLoadingAction
          variant={buttonVariant.destructive_primary}
          onClickAsyncFunc={handleDelete}
          text={t("shared.delete")}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const CertificationEvidence = () => {
  const { t } = useTranslation();

  const routeMatch = useRouteMatch(CERTIFICATION_CONTENT_PAGE_REQUIREMENT);
  const requirementId = routeMatch?.params["requirementId"];

  const certificationContext = useContext(CertificationContext);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const selectedRequirement =
    certificationContext.certification?.getSelectedRequirement(requirementId);
  const evidenceFiles = selectedRequirement?.evidence;

  const closeUploadModal = () => {
    setShowUploadModal(false);
  };

  const uploadEvidenceButton = (
    <div className="d-flex mb-1">
      <Spacer />
      <GVDSButton
        variant={buttonVariant.primary}
        icon={<GVDSIcon Icon={IconUpload} />}
        text={t("certifications.content-page.evidences.button-upload-evidence")}
        onClick={() => setShowUploadModal(true)}
      />
    </div>
  );

  let content;

  if (evidenceFiles.length === 0) {
    content = (
      <div className="table__no_content h-100">
        {t("certifications.content-page.evidences.no-content")}
      </div>
    );
  } else {
    content = (
      <>
        {evidenceFiles.map((e) => (
          <CertificationEvidenceListItem key={e.id} evidence={e} />
        ))}
      </>
    );
  }

  return (
    <>
      <div className="certification-evidence__page-container">
        {uploadEvidenceButton}
        {content}
      </div>
      <CertificationEvidenceUploadModal
        show={showUploadModal}
        close={closeUploadModal}
      />
    </>
  );
};

export default CertificationEvidence;
