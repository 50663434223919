import axios from "axios";
import { API_URL } from "../config/api-config";
import { HttpResponseService } from "./UtilsService";

export default class EmissionService {
  static saveEnergyEmission = async (year, fileModel) => {
    const formData = new FormData();
    formData.append("file", fileModel.file);
    formData.append("filename", fileModel.file.name);

    try {
      const response = await axios.put(
        `${API_URL}/api/v1/configuration_files/energy_emission_files/${year}`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static saveWasteEmission = async (year, fileModel) => {
    const formData = new FormData();
    formData.append("file", fileModel.file);
    formData.append("filename", fileModel.file.name);

    try {
      const response = await axios.put(
        `${API_URL}/api/v1/configuration_files/waste_emission_files/${year}`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static saveGridLossEmission = async (year, fileModel) => {
    const formData = new FormData();
    formData.append("file", fileModel.file);
    formData.append("filename", fileModel.file.name);

    try {
      const response = await axios.put(
        `${API_URL}/api/v1/configuration_files/grid_loss_emission_files/${year}`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static saveWellToTankEmission = async (year, fileModel) => {
    const formData = new FormData();
    formData.append("file", fileModel.file);
    formData.append("filename", fileModel.file.name);

    try {
      const response = await axios.put(
        `${API_URL}/api/v1/configuration_files/well_to_tank_emission_files/${year}`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static downloadEnergyEmission = async (year) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/configuration_files/energy_emission_files/${year}`,
        {
          responseType: "blob", // important
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        `Energy Emission Factor for ${year}.xlsx`
      );
    } catch (error) {
      throw error.response;
    }
  };

  static downloadWasteEmission = async (year) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/configuration_files/waste_emission_files/${year}`,
        {
          responseType: "blob", // important
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        `Waste Emission Factor for ${year}.xlsx`
      );
    } catch (error) {
      throw error.response;
    }
  };

  static downloadGridLossEmission = async (year) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/configuration_files/grid_loss_emission_files/${year}`,
        {
          responseType: "blob", // important
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        `Grid Loss Emission Factor for ${year}.xlsx`
      );
    } catch (error) {
      throw error.response;
    }
  };

  static downloadWellToTankEmission = async (year) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/configuration_files/well_to_tank_emission_files/${year}`,
        {
          responseType: "blob", // important
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        `Well to Tank Emission Factor for ${year}.xlsx`
      );
    } catch (error) {
      throw error.response;
    }
  };

  static getAllEnergyEmissionFiles = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/configuration_files/energy_emission_files`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static getAllWasteEmissionFiles = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/configuration_files/waste_emission_files`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static getAllGridLossEmissionFiles = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/configuration_files/grid_loss_emission_files`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static getAllWellToTankEmissionFiles = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/configuration_files/well_to_tank_emission_files`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };

  static deleteEnergyEmissionFile = async (year) => {
    try {
      await axios.delete(
        `${API_URL}/api/v1/configuration_files/energy_emission_files/${year}`
      );
    } catch (error) {
      throw error.response;
    }
  };

  static deleteWasteEmissionFile = async (year) => {
    try {
      await axios.delete(
        `${API_URL}/api/v1/configuration_files/waste_emission_files/${year}`
      );
    } catch (error) {
      throw error.response;
    }
  };

  static deleteGridLossEmissionFile = async (year) => {
    try {
      await axios.delete(
        `${API_URL}/api/v1/configuration_files/grid_loss_emission_files/${year}`
      );
    } catch (error) {
      throw error.response;
    }
  };

  static deleteWellToTankEmissionFile = async (year) => {
    try {
      await axios.delete(
        `${API_URL}/api/v1/configuration_files/well_to_tank_emission_files/${year}`
      );
    } catch (error) {
      throw error.response;
    }
  };
}
