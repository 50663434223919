import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ViewAllEnergyEmissionFactors from "./Emission/ViewAllEnergyEmissionFactors";
import ViewAllBenchmarkData from "./Benchmark/ViewAllBenchmarkData";
import ViewAllWasteEmissionFactors from "./Emission/ViewAllWasteEmissionFactors";
import ViewAllPolicyMastersheetData from "./PolicyMastersheet/ViewAllPolicyMastersheetData";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Container from "react-bootstrap/Container";
import ViewAllGridLossEmissionFactors from "./Emission/ViewAllGridLossEmissionFactors";
import ViewAllWellToTankEmissionFactors from "./Emission/ViewAllWellToTankEmissionFactors";

const ConfigurationFiles = () => {
  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.Title>
          <h1>Configuration Files</h1>
        </PageHeader.Title>
      </PageHeader>
      <Tabs defaultActiveKey="chsb-benchmark">
        <Tab eventKey="chsb-benchmark" title="CHSB Benchmark">
          <ViewAllBenchmarkData />
        </Tab>
        <Tab eventKey="energy-ef" title="Energy Emission Factors">
          <ViewAllEnergyEmissionFactors />
        </Tab>
        <Tab eventKey="waste-ef" title="Waste Emission Factors">
          <ViewAllWasteEmissionFactors />
        </Tab>
        <Tab eventKey="grid-loss" title="Grid Loss Emission Factors">
          <ViewAllGridLossEmissionFactors />
        </Tab>
        <Tab eventKey="well-to-tank" title="Well To Tank Emission Factors">
          <ViewAllWellToTankEmissionFactors />
        </Tab>
        <Tab eventKey="policy-mastersheet" title="Policy Mastersheet">
          <ViewAllPolicyMastersheetData />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ConfigurationFiles;
