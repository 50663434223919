import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import uniq from "lodash/uniq";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import flatMap from "lodash/flatMap";

import {
  ENVIRONMENTAL_DATA_BULK_INPUT,
  ENVIRONMENTAL_DATA_METER,
} from "../../../config/ROUTES_NAME";
import EnvironmentalService from "../../../services/EnvironmentalService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import MeterRecordsService from "../MeterRecordsService";
import DataRequestService from "../../../services/DataRequestService";
import { LoadingSpinnerInline } from "../../common/LoadingSpinner";
import PermissionsContext from "../../../context/PermissionsContext";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import LastActivityDisplay from "../../common/LastActivityDisplay";
import EnvironmentalSubtopicService from "../../../services/EnvironmentalSubtopicService";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSDropdownToggle from "../../../gvds-components/Buttons/GVDSDropdownToggle";
import { IconCirclePlus, IconExternalLink } from "@tabler/icons-react";
import Accordion from "react-bootstrap/Accordion";
import GVDSAccordionHeader from "../../../gvds-components/Accordion/GVDSAccordionHeader";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import { DataIssueAlertExplanationBlurb } from "../DataIssueAlert";
import { useTranslation } from "react-i18next";
import EnvironmentalMeterRecordsService from "./EnvironmentalMeterRecordsService";
import ToastContext from "../../../context/ToastContext";

const ViewAllEnvironmentalMetersRow = ({
  meter,
  dataHealthMeterStatus,
  dataHealthEnd,
  dataset,
  allDataRequestsLoaded,
  dataRequests,
  onMeterInput,
  lastUpdate,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const hasMissingDataRequest = reduce(
    dataRequests,
    (result, dr) =>
      result ||
      dr.hasMissingDataForMeter(meter.id, meter.start_date, meter.end_date),
    false
  );
  const [status, setStatus] = useState("");

  const showMeterDetails = (meter, dataset) => {
    history.push({
      pathname: ENVIRONMENTAL_DATA_METER,
      state: {
        meter,
        dataset,
        dataRequests,
      },
    });
  };

  const isDataHealthLoading =
    dataHealthMeterStatus === null || dataHealthMeterStatus === undefined;

  useEffect(() => {
    if (!isDataHealthLoading) {
      const meterStatus = new EnvironmentalMeterRecordsService()
        .updateMeter(meter, dataHealthMeterStatus)
        .getMeterOverviewStatus(dataHealthEnd);
      setStatus(meterStatus);
    }
  }, [dataHealthMeterStatus]);

  return (
    <tr
      key={meter.id}
      className="is-clickable"
      onClick={() => showMeterDetails(meter, dataset)}
    >
      <td className="environmental-meter-table__type">
        <div>{meter["environmental_type"]["name"]}</div>
      </td>
      <td className="environmental-meter-table__meter">
        {meter["name"]}
        {userInventory.selectedInventory.get.type === RESOURCES.SITE && (
          <div className="table-data__subtext">
            {dataset["facility"]["name"]}
          </div>
        )}
      </td>
      <td>
        {isDataHealthLoading ? (
          <LoadingSpinnerInline />
        ) : (
          dataHealthMeterStatus.lastRecordDate &&
          MeterRecordsService.getLastRecordDateTableDisplay(
            dataHealthMeterStatus.lastRecordDate
          )
        )}
      </td>
      <td>
        {isDataHealthLoading ? (
          <LoadingSpinnerInline />
        ) : (
          dataHealthMeterStatus.lastActivity && (
            <LastActivityDisplay
              lastActivity={dataHealthMeterStatus.lastActivity}
            />
          )
        )}
      </td>
      <td className="environmental-meter-table__status">
        {allDataRequestsLoaded || hasMissingDataRequest ? (
          MeterRecordsService.getStatusLabel(t, hasMissingDataRequest, status)
        ) : (
          <LoadingSpinnerInline />
        )}
      </td>
      <td className="environmental-meter-table__actions">
        <div className="gvds-table-cell__actions-container">
          <GVDSIconButton
            variant={iconButtonVariant.tertiary}
            icon={<GVDSIcon Icon={IconExternalLink} />}
            tooltipText={t("shared.view-details")}
            onClick={(e) => {
              e.stopPropagation();
              showMeterDetails(meter, dataset);
            }}
          />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.ENVIRONMENTAL_RECORD_CREATE
            ] && (
              <GVDSIconButton
                variant={iconButtonVariant.tertiary}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                tooltipText={t("shared.input")}
                onClick={(e) => {
                  e.stopPropagation();
                  onMeterInput(meter, [dataset]);
                }}
              />
            )}
        </div>
      </td>
    </tr>
  );
};

const ViewAllEnvironmentalMeters = ({
  datasets = [],
  onMeterInput,
  lastUpdate,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const permissionCtx = useContext(PermissionsContext);
  const toastContext = useContext(ToastContext);

  const [newDataRequestDetails, setNewDataRequestDetails] = useState({});
  const [dataRequests, setDataRequests] = useState([]);
  const [isAllDataRequestsLoaded, setIsAllDataRequestsLoaded] = useState(false);

  const dataHealthStart = moment()
    .startOf("day")
    .subtract(12, "months")
    .toDate();
  const dataHealthEnd = moment().startOf("day").toDate();
  const [dataHealthMeterStatusById, setDataHealthMeterStatusById] = useState(
    {}
  );

  useEffect(() => {
    const inventory = userInventory.selectedInventory.get;
    if (inventory && inventory.id) {
      loadData(inventory.id, inventory.type).then();
    }
  }, [datasets]);

  useEffect(() => {
    if (newDataRequestDetails.id) {
      setDataRequests([...dataRequests, newDataRequestDetails]);
    }
  }, [newDataRequestDetails.id]);

  useEffect(() => {
    const selectedInventory = userInventory.selectedInventory.get;
    EnvironmentalService.getDataHealthMetersStatus(
      selectedInventory.type,
      selectedInventory.id,
      dataHealthStart,
      dataHealthEnd
    )
      .then((meterDataHealthStatusModels) => {
        const meterStatusById = meterDataHealthStatusModels.reduce(
          (acc, environmentalMeterDataHealthStatusModel) => {
            acc[environmentalMeterDataHealthStatusModel.meterId] =
              environmentalMeterDataHealthStatusModel;

            return acc;
          },
          {}
        );
        setDataHealthMeterStatusById(meterStatusById);
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to load meter status</span>);
      });
  }, [lastUpdate]);

  const loadData = async (resourceId, resourceType) => {
    const dr = flatMap(
      await Promise.all(
        userInventory.facilities.get.map(
          async (f) =>
            await DataRequestService.getOngoingDataRequests(
              f.id,
              RESOURCES.FACILITY
            )
        )
      )
    );
    for (let dId of uniq(dr.map((r) => r.id))) {
      const details = await DataRequestService.getDataRequest(
        dId,
        resourceId,
        resourceType
      );
      setNewDataRequestDetails(details);
    }
    setIsAllDataRequestsLoaded(true);
  };

  const subtopics = uniq(
    datasets
      .filter((d) => d["meters"] && d["meters"].length > 0)
      .map((d) => d["environmental_subtopic"]["name"])
  ).sort(EnvironmentalSubtopicService.subtopicsSortFn);
  const [activeKey, setActiveKey] = useState(
    subtopics.length > 0 ? subtopics[0] : null
  );

  const goToBulkInput = () => {
    history.push(
      getRedirectURLWithCurrentParam(ENVIRONMENTAL_DATA_BULK_INPUT, location)
    );
  };

  const meterCountPerSubtopic = (datasets) => {
    let meterCount = 0;
    datasets.forEach((item) => {
      if (item.hasOwnProperty("meters") && Array.isArray(item.meters)) {
        meterCount += item.meters.length;
      }
    });

    return meterCount;
  };

  return (
    <>
      <div className="d-flex mb-2">
        <h2 className="d-flex text-capitalize">
          {t("data-management.environmental.usage.section-title")}
          <span className="body-1 ms-1">
            <InfoTooltip
              info={t(
                "data-management.environmental.usage.section-description"
              )}
            />
          </span>
        </h2>
        <div className="ms-auto d-flex align-items-center">
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.ENVIRONMENTAL_RECORD_CREATE
            ] && (
              <Dropdown className="guided-tour-target__environment-input">
                <Dropdown.Toggle as={GVDSDropdownToggle}>
                  {t("data-management.environmental.usage.input-button.label")}
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {
                    <Dropdown.Item onClick={() => onMeterInput(null, datasets)}>
                      <GVDSIcon Icon={IconCirclePlus} />{" "}
                      {t(
                        "data-management.environmental.usage.input-button.single"
                      )}
                    </Dropdown.Item>
                  }
                  {
                    <Dropdown.Item onClick={() => goToBulkInput()}>
                      <GVDSIcon Icon={IconCirclePlus} />{" "}
                      {t(
                        "data-management.environmental.usage.input-button.bulk"
                      )}
                    </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
            )}
        </div>
      </div>
      <div className="guided-tour-target__environment-data-meters">
        {subtopics.length === 0 && (
          <div className="table__no_content">
            {t("data-management.environmental.usage.meter-list.no-content")}
          </div>
        )}
        {subtopics.map((subtopicName) => (
          <Accordion
            key={subtopicName}
            defaultActiveKey={activeKey}
            activeKey={activeKey === subtopicName ? activeKey : null}
          >
            <Accordion.Item eventKey={subtopicName}>
              <GVDSAccordionHeader
                isExpanded={activeKey === subtopicName}
                title={subtopicName}
                count={meterCountPerSubtopic(
                  datasets.filter(
                    (d) => d["environmental_subtopic"]["name"] === subtopicName
                  )
                )}
                onClick={() =>
                  activeKey === subtopicName
                    ? setActiveKey(null)
                    : setActiveKey(subtopicName)
                }
              />
              <Accordion.Body>
                <GVDSTableDisplay className="environmental-meter-table">
                  <thead>
                    <tr>
                      <th className="environmental-meter-table__type">
                        {t("data-management.environmental.shared.type")}
                      </th>
                      <th className="environmental-meter-table__meter">
                        {t("shared.meter")}
                      </th>
                      <th className="environmental-meter-table__last_record_date">
                        {t(
                          "data-management.environmental.usage.meter-list.table-header.last-record-date"
                        )}
                      </th>
                      <th className="environmental-meter-table__last_updated">
                        {t("shared-table-header.last-updated-by")}
                      </th>
                      <th className="environmental-meter-table__status">
                        <div className="d-flex align-items-center">
                          <span className="me-1">
                            {t(
                              "data-management.environmental.usage.meter-list.table-header.alerts"
                            )}
                          </span>
                          <InfoTooltip
                            info={<DataIssueAlertExplanationBlurb />}
                          />
                        </div>
                      </th>
                      <th className="environmental-meter-table__actions gvds-table-cell__actions-header">
                        {t("shared-input-label.actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {datasets
                      .filter(
                        (d) =>
                          d["environmental_subtopic"]["name"] === subtopicName
                      )
                      .map((d) =>
                        sortBy(d["meters"], [
                          "environmental_type.name",
                          "name",
                        ]).map((mt) => (
                          <ViewAllEnvironmentalMetersRow
                            key={mt.id}
                            meter={mt}
                            dataHealthMeterStatus={
                              dataHealthMeterStatusById[mt.id]
                            }
                            dataHealthEnd={dataHealthEnd}
                            dataset={d}
                            allDataRequestsLoaded={isAllDataRequestsLoaded}
                            dataRequests={dataRequests}
                            onMeterInput={onMeterInput}
                            lastUpdate={lastUpdate}
                          />
                        ))
                      )}
                  </tbody>
                </GVDSTableDisplay>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default ViewAllEnvironmentalMeters;
