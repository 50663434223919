import axios from "axios";
import { API_URL } from "../../config/api-config";
import { HttpResponseService } from "../UtilsService";

class RiskAssessmentReportTaskModel {
  constructor(
    id,
    status,
    expireOn,
    reportName,
    reportLink,
    selectedRiskCategories,
    requestedByName,
    requestedByEmail,
    requestedOn
  ) {
    this.id = id;
    this.status = status;
    this.expireOn = expireOn;
    this.reportName = reportName;
    this.reportLink = reportLink;
    this.selectedRiskCategories = selectedRiskCategories;
    this.requestedByName = requestedByName;
    this.requestedByEmail = requestedByEmail;
    this.requestedOn = requestedOn;
  }

  get selectedRiskCategoriesDisplay() {
    return this.selectedRiskCategories.join(", ");
  }

  static fromDTO(riskAssessmentReportTaskDTO) {
    return new RiskAssessmentReportTaskModel(
      riskAssessmentReportTaskDTO.id,
      riskAssessmentReportTaskDTO.status,
      riskAssessmentReportTaskDTO.expire_on,
      riskAssessmentReportTaskDTO.report_name,
      API_URL + riskAssessmentReportTaskDTO.report_link,
      riskAssessmentReportTaskDTO.selected_risk_categories,
      riskAssessmentReportTaskDTO.requested_by_name,
      riskAssessmentReportTaskDTO.requested_by_email,
      riskAssessmentReportTaskDTO.requested_on
    );
  }
}

export default class RiskAssessmentReportService {
  static getReportConfig = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/risk_assessment/config",
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static downloadReport = async (
    resource_type,
    resource_id,
    risk_parameter_names
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/reports/risk_assessment/download`,
        { risk_parameter_names },
        {
          params: {
            resource_type,
            resource_id,
          },
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Risk Assessment Report.xlsx"
      );
    } catch (error) {
      throw error;
    }
  };

  static triggerReportGeneration = async (
    resource_type,
    resource_id,
    risk_parameter_names
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/reports/risk_assessment/requests`,
        { risk_parameter_names },
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getGeneratedReports = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/reports/risk_assessment/requests`,
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );
      return response.data.map((reportTaskDTO) =>
        RiskAssessmentReportTaskModel.fromDTO(reportTaskDTO)
      );
    } catch (error) {
      throw error;
    }
  };

  static getPolicyMastersheetFiles = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/configuration_files/policy_mastersheet_files"
      );
      return response.data.map((d) => ({
        ...d,
        filepath: API_URL + d.filepath
      }));
    } catch (error) {
      throw error;
    }
  };

  static savePolicyMastersheet = async (fileModel) => {
    try {
      const postBody = new FormData();
      postBody.append("file", fileModel.file);
      postBody.append("filename", fileModel.file.name);

      const response = await axios.post(
        `${API_URL}/api/v1/configuration_files/policy_mastersheet_files`,
        postBody
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updatePolicyMastersheet = async (refDocId, fileModel) => {
    try {
      const postBody = new FormData();
      postBody.append("file", fileModel.file);
      postBody.append("filename", fileModel.file.name);

      const response = await axios.put(
        `${API_URL}/api/v1/configuration_files/policy_mastersheet_files/${refDocId}`,
        postBody
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deletePolicyMastersheet = async (id) => {
    try {
      const response = await axios.delete(
        API_URL + "/api/v1/configuration_files/policy_mastersheet_files/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}
