import React, { useContext, useEffect, useState } from "react";
import withAuthentication from "../HOC/withAuthentication";
import UserInventoryContext from "../../context/UserInventoryContext";
import headerImg from "../../assets/images/landing-page-illustration.png";
import RoutePersistedTabs from "../common/Tabs/RoutePersistedTabs";
import Tab from "react-bootstrap/Tab";
import LoadingSpinner from "../common/LoadingSpinner";
import LandingPageOverview from "./LandingPageOverview";
import LandingPageTodo from "./LandingPageTodo";
import LandingPagePerformance from "./LandingPagePerformance";
import Spacer from "../../gvds-components/Layout/Spacer";
import HomepageAnnouncementService from "../../services/HomepageAnnouncementService";
import ToastContext from "../../context/ToastContext";
import LandingPagePopUp from "./LandingPagePopUp";
import { useTranslation } from "react-i18next";

const Home = () => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [popUpAnnouncement, setPopUpAnnouncement] = useState(null);
  const [activeAnnouncements, setActiveAnnouncements] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    loadActiveAnnouncements();
  }, []);

  const loadActiveAnnouncements = () => {
    HomepageAnnouncementService.getActiveAnnouncements()
      .then((homepageAnnouncementsModels) => {
        const firstPopupAnnouncement = homepageAnnouncementsModels.find(
          (announcement) => announcement.isOneTimePopup
        );

        setPopUpAnnouncement(firstPopupAnnouncement || null);
        setActiveAnnouncements(homepageAnnouncementsModels);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to load landing page announcements.</span>
        );
      });
  };

  let content;

  if (
    userInventory.isLoadingInventory.get ||
    !userInventory.selectedInventory.get
  ) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <>
        <div className="top-section__container">
          <div className="top-section__content">
            <div className="landing-page-header__message-container">
              <div>
                <div className="landing-page-header__message-welcome">
                  {t("landing-page.label-welcome-to")}
                </div>
                <div className="landing-page-header__message-portal">
                  {t("landing-page.label-greenview-portal")}
                </div>
              </div>
            </div>
            <Spacer />
            <div className="landing-page-header__illustration-container">
              <img
                src={headerImg}
                className="landing-page-header-illustration"
                alt="My Greenview"
              />
            </div>
          </div>
        </div>
        <div className="content-section__container">
          <div className="content-section__content">
            <RoutePersistedTabs
              defaultActiveKey="overview"
              className="landing-page__tabs"
              mountOnEnter
            >
              <Tab eventKey="overview" title={t("landing-page.tab.overview")}>
                <LandingPageOverview announcements={activeAnnouncements} />
              </Tab>
              <Tab eventKey="todo" title={t("landing-page.tab.todo")}>
                <LandingPageTodo />
              </Tab>
              <Tab
                eventKey="performance"
                title={t("landing-page.tab.performance")}
              >
                <LandingPagePerformance />
              </Tab>
            </RoutePersistedTabs>
          </div>
        </div>
        <LandingPagePopUp popUpAnnouncement={popUpAnnouncement} />
      </>
    );
  }

  return <div className="landing-page__container">{content}</div>;
};

export default withAuthentication(Home);
