import React from "react";
import ViewAllEnvironmentalMeters from "./ViewAllEnvironmentalMeters";
import ViewAllMarketPurchaseTrackers from "./MarketPurchaseOffsets/ViewAllMarketPurchaseTrackers";

const EnvironmentalDashboard = ({
  lastUpdate,
  datasets,
  onMeterInput,
  onTrackerInput,
  trackers,
  loadTrackers,
}) => {
  return (
    <>
      <ViewAllEnvironmentalMeters
        datasets={datasets}
        onMeterInput={onMeterInput}
        lastUpdate={lastUpdate}
      />

      <div className="mt-2 mb-3" style={{ height: "1px" }} />
      <ViewAllMarketPurchaseTrackers
        trackers={trackers}
        onTrackerInput={onTrackerInput}
        loadTrackers={loadTrackers}
      />
    </>
  );
};

export default EnvironmentalDashboard;
