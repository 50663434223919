import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateTimeUtils } from "../../../services/UtilsService";
import {
  getPresignedS3Url,
  ViewFileItemDisplay,
} from "../../common/FileAttachments";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSTag from "../../../gvds-components/common/GVDSTag";
import { ARTICLE_CONTENT_TYPE } from "../../../config/constants";
import GVDSLink from "../../../gvds-components/Buttons/GVDSLink";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import { getViewGuidanceArticlePath } from "../../../config/ROUTES_NAME";
import { useLocation } from "react-router-dom";
import { ContentTypeDisplay } from "../../common/ContentTypeDisplay";

const GuidanceArticleDisplay = ({
  title,
  showTitle = true,
  htmlContent,
  contentFileInput,
  lastEditedOn,
  articleType,
  categories,
  tags,
  siteTypes,
  UNSDGs,
  files,
  contentType,
  relevantArticles,
}) => {
  const location = useLocation();
  const [pdfPresignedUrl, setPdfPresignedUrl] = useState(null);
  const [videoPresignedUrl, setVideoPresignedUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const divRef = useRef();
  const isUploadedContentExist = contentFileInput && contentFileInput.link;

  useEffect(() => {
    if (htmlContent) {
      if (divRef.current) {
        divRef.current.innerHTML = htmlContent;
      }
    }
  }, [divRef.current, htmlContent]);

  const videoPlayerRef = useCallback(
    (node) => {
      if (node !== null) {
        let videoSource;
        if (isUploadedContentExist && videoPresignedUrl !== null) {
          videoSource = videoPresignedUrl;
        } else if (!isUploadedContentExist && !isLoading) {
          videoSource = URL.createObjectURL(contentFileInput.file);
        }
        // Reference https://gist.github.com/edin-m/889fa79a0fa124b1a8c3
        // Reference https://wplearninglab.com/amazon-s3-video-embed-code/
        node.src = videoSource;
        node.load();
        node.oncontextmenu = (e) => {
          e.preventDefault();
        };
      }
    },
    [videoPresignedUrl]
  );

  useEffect(() => {
    if (isUploadedContentExist) {
      setIsLoading(true);
      if (contentType?.name === ARTICLE_CONTENT_TYPE.PDF) {
        getPresignedS3Url(contentFileInput.link)
          .then((presignedS3URL) => {
            setPdfPresignedUrl(presignedS3URL);
          })
          .finally(() => setIsLoading(false));
      } else if (contentType?.name === ARTICLE_CONTENT_TYPE.VIDEO) {
        getPresignedS3Url(contentFileInput.link)
          .then((presignedS3URL) => {
            setVideoPresignedUrl(presignedS3URL);
          })
          .finally(() => setIsLoading(false));
      }
    } else {
      setPdfPresignedUrl(null);
      setVideoPresignedUrl(null);
    }
  }, [contentFileInput]);

  let content;
  if (isLoading) {
    content = (
      <div className="page-loading-container">
        <LoadingSpinner />
      </div>
    );
  } else {
    if (contentFileInput) {
      if (contentType?.name === ARTICLE_CONTENT_TYPE.PDF) {
        let contentFile;
        if (isUploadedContentExist) {
          contentFile = encodeURIComponent(pdfPresignedUrl);
        } else {
          contentFile = URL.createObjectURL(contentFileInput.file);
        }
        content = (
          <iframe
            title="PDF"
            className="guidance-article-pdf-container"
            src={`${process.env.PUBLIC_URL}/dist/pdfjs-2.5.207-dist/web/gv-viewer.html?file=${contentFile}#zoom=page-width`}
          />
        );
      } else if (contentType?.name === ARTICLE_CONTENT_TYPE.VIDEO) {
        content = (
          <div className="guidance-article-video-container">
            <video
              ref={videoPlayerRef}
              controls="controls"
              controlsList="nodownload"
            />
          </div>
        );
      }
    } else if (htmlContent) {
      content = <div className="guidance-article" ref={divRef} />;
    } else {
      content = (
        <div className="table__no_content me-3">
          <p>No uploaded file content yet.</p>
        </div>
      );
    }
  }

  return (
    <div>
      {showTitle && <h1>{title}</h1>}
      <div className="d-flex">
        {content}
        <div className="guidance-article-side-info">
          <div className="gvds-text--heading4">Guide Information</div>
          <div className="mb-2">
            <ul>
              <li>
                Last edited on: {DateTimeUtils.formatUTCDate(lastEditedOn)}
              </li>
              <li>
                <span className="text-capitalize">{articleType?.name}</span>{" "}
                Guide
              </li>
            </ul>
          </div>
          {relevantArticles.length > 0 && (
            <>
              <div className="gvds-text--heading4 mt-2">Relevant Guides</div>
              <ul>
                {relevantArticles.map((ra) => {
                  return (
                    <li>
                      <div className="text-vertical-center">
                        <GVDSLink
                          variant={GVDSLink.variant.media_title}
                          to={getRedirectURLWithCurrentParam(
                            getViewGuidanceArticlePath(ra.id),
                            location
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {ra.title}
                        </GVDSLink>
                        <ContentTypeDisplay
                          contentTypeName={ra.content_type?.name}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          {files && files.length > 0 && (
            <>
              <div className="gvds-text--heading4">Downloadable Resources</div>
              <div className="mb-2">
                <ul>
                  {files.map((file) => {
                    return (
                      <li key={file.id}>
                        <ViewFileItemDisplay file={file} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
          <div className="gvds-text--heading4 mb-1">Categories</div>
          <div className="mb-2">
            {categories.map((category, index) => (
              <GVDSTag key={index}>{category.name}</GVDSTag>
            ))}
          </div>
          {tags && tags.length > 0 && (
            <>
              <div className="gvds-text--heading4 mb-1">Tags</div>
              <div className="mb-2">
                {tags.map((tag, index) => (
                  <GVDSTag key={index}>{tag.name}</GVDSTag>
                ))}
              </div>
            </>
          )}
          <div className="gvds-text--heading4 mb-1">UN SDGs</div>
          <div className="mb-2">
            {UNSDGs.map((sdg, index) => (
              <GVDSTag key={index}>{sdg.name}</GVDSTag>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidanceArticleDisplay;
