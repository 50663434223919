import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";

const ONE_MIN_IN_MILLISEC = 60 * 1000;

export const HourMinuteCountdownTimeDisplay = ({ targetTime }) => {
  const interval = useRef(null);
  const [now, setNow] = useState(moment.utc());

  useEffect(() => {
    interval.current = setInterval(() => {
      setNow(moment.utc());
    }, ONE_MIN_IN_MILLISEC);
    return () => clearInterval(interval.current);
  }, [targetTime]);

  const duration = moment.duration(moment.utc(targetTime).diff(now));
  const remainingHours = Math.floor(duration.asHours());
  const remainingMinutes = duration.minutes();

  return (
    <>
      {remainingHours}h {remainingMinutes}m
    </>
  );
};
