import StatusLabel from "../../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../../services/UtilsService";
import { OptionTransformer } from "../../../common/MiniMultiSelect";
import React from "react";
import { HomepageAnnouncementStatusEnum } from "../../../../services/HomepageAnnouncementAdminService";

export const getStatusLabelColor = (status) => {
  switch (status) {
    case HomepageAnnouncementStatusEnum.UPCOMING:
      return StatusLabel.Colors.yellow;
    case HomepageAnnouncementStatusEnum.ACTIVE:
      return StatusLabel.Colors.green;
    case HomepageAnnouncementStatusEnum.EXPIRED:
      return StatusLabel.Colors.gray;
    default:
      return StatusLabel.Colors.gray;
  }
};

export const getStatusOptionTransformer = () => {
  return new OptionTransformer(
    (status) => status,
    (status) => status,
    (status) => (
      <StatusLabel color={getStatusLabelColor(status)} key={status}>
        {StringUtils.getTitleCase(status)}
      </StatusLabel>
    )
  );
};
