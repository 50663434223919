import MainSummaryCard from "./Widgets/MainSummaryCard/MainSummaryCard";
import CollectionSummaryCards from "./Widgets/CollectionSummaryCards/CollectionSummaryCards";
import ClassificationDonutChart from "./Widgets/ClassificationDonutChart/ClassificationDonutChart";
import React from "react";
import PerformanceComparisonChart from "./Widgets/PerformanceComparisonChart/PerformanceComparisonChart";
import SummarySiteRankingChart from "./Widgets/SummarySiteRankingChart/SummarySiteRankingChart";
import PerformanceTimelineChart from "./Widgets/PerformanceTimelineChart/PerformanceTimelineChart";
import ChangeOverTimeSiteRankingChart from "./Widgets/ChangeOverTimeSiteRankingChart/ChangeOverTimeSiteRankingChart";

export class MainSummaryCardParser {
  static parseElement(element, orderPrefix) {
    const key = `MainSummaryCard-${orderPrefix}`;

    return <MainSummaryCard key={key} element={element} />;
  }

  static parseConfig(element, updateDataHolder) {
    const config = element["config"];
    const filters = element["filters"];
    updateDataHolder(config["subtopic"], filters, true);
  }
}

export class CollectionSummaryCardsParser {
  static parseElement(element, orderPrefix) {
    const key = `CollectionSummaryCards-${orderPrefix}`;

    return <CollectionSummaryCards key={key} element={element} />;
  }

  static parseConfig(element, updateDataHolder) {
    const children = element["children"];

    children.forEach((child) => {
      const config = child["config"];
      const filters = child["filters"];
      updateDataHolder(config["subtopic"], filters, true);
    });

    // Ref Hans notes:
    //  - ignore filter for now
  }
}

export class ClassificationDonutChartParser {
  static parseElement(element, orderPrefix) {
    const key = `ClassificationDonutChart-${orderPrefix}`;

    return <ClassificationDonutChart key={key} element={element} />;
  }

  static parseConfig(element, updateDataHolder) {}
}

export class SummarySiteRankingChartParser {
  static parseElement(element, orderPrefix) {
    const key = `SummarySiteRankingChart-${orderPrefix}`;

    return <SummarySiteRankingChart key={key} element={element} />;
  }

  static parseConfig(element, updateDataHolder) {}
}

export class PerformanceComparisonChartParser {
  static parseElement(element, orderPrefix) {
    const key = `PerformanceComparisonChart-${orderPrefix}`;

    return <PerformanceComparisonChart key={key} element={element} />;
  }

  static parseConfig(element, updateDataHolder) {}
}

export class PerformanceTimelineChartParser {
  static parseElement(element, orderPrefix) {
    const key = `PerformanceTimelineChart-${orderPrefix}`;

    return <PerformanceTimelineChart key={key} element={element} />;
  }

  static parseConfig(element, updateDataHolder) {}
}

export class ChangeOverTimeSiteRankingChartParser {
  static parseElement(element, orderPrefix) {
    const key = `ChangeOverTimeSiteRankingChart-${orderPrefix}`;

    return <ChangeOverTimeSiteRankingChart key={key} element={element} />;
  }

  static parseConfig(element, updateDataHolder) {}
}