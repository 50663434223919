import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  BEST_PRACTICES_CATEGORY,
  BEST_PRACTICES_TAG,
  CREATE_CONTRACT,
  CREATE_CONTRACT_SUBSCRIPTION,
  CREATE_SITE,
  DUPLICATE_CONTRACT_SUBSCRIPTION,
  REF_DATA_PORTAL_MODULE_LINKS,
  REFERENCE_DATA,
  SYSTEM_TOOLBOX_AFFILIATIONS,
  SYSTEM_TOOLBOX_ANNOUNCEMENT,
  SYSTEM_TOOLBOX_ASSET_CLASSES,
  SYSTEM_TOOLBOX_BEST_PRACTICES_CREATE,
  SYSTEM_TOOLBOX_BEST_PRACTICES_EDIT,
  SYSTEM_TOOLBOX_BEST_PRACTICES_LIST,
  SYSTEM_TOOLBOX_BRAND_FLAGS_LIST,
  SYSTEM_TOOLBOX_CERTIFICATION_EDIT,
  SYSTEM_TOOLBOX_CERTIFICATION_LIST,
  SYSTEM_TOOLBOX_CERTIFICATION_SETUP,
  SYSTEM_TOOLBOX_CERTIFICATIONS,
  SYSTEM_TOOLBOX_CLIMATE_ZONE_BAILEYS_ECOREGION,
  SYSTEM_TOOLBOX_CLIMATE_ZONE_KOPPEN_GEIGER,
  SYSTEM_TOOLBOX_COMPANIES_LIST,
  SYSTEM_TOOLBOX_CONFIGURATION_FILES,
  SYSTEM_TOOLBOX_COUNTRY_LIST,
  SYSTEM_TOOLBOX_CURRENCY_LIST,
  SYSTEM_TOOLBOX_ENVIRONMENTAL_TYPES,
  SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_CREATE,
  SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_EDIT,
  SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_LIST,
  SYSTEM_TOOLBOX_HELP_ARTICLES_LIST,
  SYSTEM_TOOLBOX_HOTEL_TYPES,
  SYSTEM_TOOLBOX_LAT_LONG_FINDER,
  SYSTEM_TOOLBOX_LAUNDRY_LOCATIONS,
  SYSTEM_TOOLBOX_MARKET_SEGMENTS,
  SYSTEM_TOOLBOX_METROAREA_LIST,
  SYSTEM_TOOLBOX_ONBOARDING_TOOL,
  SYSTEM_TOOLBOX_OPERATIONAL_TYPES,
  SYSTEM_TOOLBOX_PERFORMANCE_GROUP_LIST,
  SYSTEM_TOOLBOX_PROVINCE_LIST,
  SYSTEM_TOOLBOX_SITE_FACILITY_OPTIONS,
  SYSTEM_TOOLBOX_SUBSCRIPTIONS,
  SYSTEM_TOOLBOX_SUBTOPICS,
  SYSTEM_TOOLBOX_TROUBLESHOOT_DATA,
  SYSTEM_TOOLBOX_UNITS,
  SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS,
  SYSTEM_TOOLBOX_VIEW_ENTERPRISE_SIGNUP_REQUEST,
  SYSTEM_TOOLBOX_VIEW_SIGNUP_REQUEST,
  VIEW_ALL_CONTRACTS,
  VIEW_ALL_SITES,
  VIEW_ALL_USERS,
  VIEW_CONTRACT_DETAILS,
  VIEW_CONTRACT_SUBSCRIPTION,
  VIEW_FACILITY_DETAILS,
  VIEW_SITE_DETAILS,
} from "../../config/ROUTES_NAME";
import withAuthentication from "../HOC/withAuthentication";
import ViewAllContracts from "../Contract/ViewAllContracts";
import CreateContract from "../Contract/CreateContract";
import UserManagement from "./Users/UserManagement";
import ViewContractDetails from "../Contract/ViewContractDetails";
import ViewAllSites from "../Site/ViewAllSites";
import ViewSiteDetails from "../Site/ViewSiteDetails";
import ViewFacilityDetails from "../Site/Facility/ViewFacilityDetails";
import ReferenceDataDashboard from "./../ReferenceData/ReferenceDataDashboard";
import BestPracticeCategory from "./../ReferenceData/BestPractice/BestPracticeCategory";
import BestPracticeTag from "./../ReferenceData/BestPractice/BestPracticeTag";
import ViewAllBestPractices from "./BestPractices/ViewAllBestPractices";
import BestPracticeForm from "./BestPractices/BestPracticeForm";
import ViewAllGuidanceArticles from "./GuidanceArticles/ViewAllGuidanceArticles";
import GuidanceArticleForm from "./GuidanceArticles/GuidanceArticleForm";
import OnboardingTool from "./OnboardingTool/OnboardingTool";
import AllBrandFlags from "./../ReferenceData/Companies/AllBrandFlags";
import AllCompanies from "./../ReferenceData/Companies/AllCompanies";
import SiteFacilityTypeOptions from "../ReferenceData/SiteFacilityTypeOptions/SiteFacilityTypeOptions";
import Affiliations from "../ReferenceData/HotelAndAccommodationAttributes/Affiliations/Affiliations";
import HotelTypes from "../ReferenceData/HotelAndAccommodationAttributes/HotelTypes/HotelTypes";
import LaundryLocations from "../ReferenceData/HotelAndAccommodationAttributes/LaundryLocations/LaundryLocations";
import MarketSegment from "../ReferenceData/HotelAndAccommodationAttributes/MarketSegments/MarketSegments";
import AssetClasses from "../ReferenceData/HotelAndAccommodationAttributes/AssetClasses/AssetClasses";
import CountryList from "../ReferenceData/SiteFacilityTypeOptions/Countries/Countries";
import CurrencyList from "../ReferenceData/SiteFacilityTypeOptions/Currencies/Currencies";
import MetroAreaList from "../ReferenceData/SiteFacilityTypeOptions/MetroArea/MetroArea";
import ProvinceList from "../ReferenceData/SiteFacilityTypeOptions/StateAndProvince/StateAndProvince";
import BaileyEcoregion from "../ReferenceData/SiteFacilityTypeOptions/ClimateZones/BaileyEcoregion";
import KoppenGeiger from "../ReferenceData/SiteFacilityTypeOptions/ClimateZones/KoppenGeiger";
import Certifications from "../ReferenceData/SiteFacilityTypeOptions/Certifications/Certifications";
import ConfigurationFiles from "./ConfigurationFiles/ConfigurationFiles";
import OperationalTypes from "../ReferenceData/DataManagement/OperationalTypes";
import EnvironmentalTypes from "../ReferenceData/DataManagement/EnvironmentalTypes";
import PerformanceGrouping from "../ReferenceData/DataManagement/PerformanceGrouping";
import Subtopics from "../ReferenceData/DataManagement/Subtopics";
import UnitsConversion from "../ReferenceData/DataManagement/UnitsConversion";
import ViewAllHelpArticles from "./HelpArticles/ViewAllHelpArticles";
import PortalModuleLinks from "../ReferenceData/PortalWide/PortalModuleLinks";
import ViewAllSignUpRequests from "./SiteSignUpRequest/ViewAllSignUpRequests";
import ViewSignUpRequest from "./SiteSignUpRequest/ViewSignUpRequest";
import InputContractSubscription from "./Subscription/ContractSubscription/InputContractSubscription";
import CreateSite from "../Site/CreateSite";
import LatLongFinder from "./LatLongFinder/LatLongFinder";
import ViewAllSubscriptions from "./Subscription/ViewAllSubscriptions";
import ViewEnterpriseSignupRequest from "./SiteSignUpRequest/ViewEnterpriseSignupRequest";
import TroubleshootData from "./TroubleshootData/TroubleshootData";
import Announcement from "./Announcement/Announcement";
import ViewAllCertification from "./Certification/ViewAllCerfication";
import CertificationSetup from "./Certification/CertificationSetup";

const SystemToolbox = () => {
  return (
    <Switch>
      <Route
        exact
        path={VIEW_ALL_CONTRACTS}
        render={() => <ViewAllContracts />}
      />
      <Route exact path={CREATE_CONTRACT} render={() => <CreateContract />} />
      <Route exact path={VIEW_ALL_USERS} render={() => <UserManagement />} />
      <Route
        exact
        path={VIEW_CONTRACT_DETAILS}
        render={() => <ViewContractDetails />}
      />
      <Route
        exact
        path={CREATE_CONTRACT_SUBSCRIPTION}
        render={() => <InputContractSubscription />}
      />
      <Route
        exact
        path={VIEW_CONTRACT_SUBSCRIPTION}
        render={() => <InputContractSubscription />}
      />
      <Route
        exact
        path={DUPLICATE_CONTRACT_SUBSCRIPTION}
        render={() => <InputContractSubscription isDuplicate={true} />}
      />
      <Route exact path={VIEW_ALL_SITES} render={() => <ViewAllSites />} />
      <Route exact path={CREATE_SITE} render={() => <CreateSite />} />
      <Route
        exact
        path={VIEW_SITE_DETAILS}
        render={() => <ViewSiteDetails />}
      />
      <Route
        exact
        path={VIEW_FACILITY_DETAILS}
        render={() => <ViewFacilityDetails />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_CONFIGURATION_FILES}
        render={() => <ConfigurationFiles />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_BEST_PRACTICES_LIST}
        render={() => <ViewAllBestPractices />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_BEST_PRACTICES_CREATE}
        render={() => <BestPracticeForm />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_BEST_PRACTICES_EDIT}
        render={() => <BestPracticeForm />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_LIST}
        render={() => <ViewAllGuidanceArticles />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_CREATE}
        render={() => <GuidanceArticleForm />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_GUIDANCE_ARTICLES_EDIT}
        render={() => <GuidanceArticleForm />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_ONBOARDING_TOOL}
        render={() => <OnboardingTool />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_HELP_ARTICLES_LIST}
        render={() => <ViewAllHelpArticles />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_ANNOUNCEMENT}
        render={() => <Announcement />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_VIEW_ALL_SIGNUP_REQUESTS}
        render={() => <ViewAllSignUpRequests />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_VIEW_SIGNUP_REQUEST}
        render={() => <ViewSignUpRequest />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_VIEW_ENTERPRISE_SIGNUP_REQUEST}
        render={() => <ViewEnterpriseSignupRequest />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_TROUBLESHOOT_DATA}
        render={() => <TroubleshootData />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_CERTIFICATION_LIST}
        render={() => <ViewAllCertification />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_CERTIFICATION_SETUP}
        render={() => <CertificationSetup />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_CERTIFICATION_EDIT}
        render={() => <CertificationSetup />}
      />
      {/* Reference Data */}
      <Route
        exact
        path={REFERENCE_DATA}
        render={() => <ReferenceDataDashboard />}
      />
      <Route
        exact
        path={REF_DATA_PORTAL_MODULE_LINKS}
        render={() => <PortalModuleLinks />}
      />
      <Route
        exact
        path={BEST_PRACTICES_CATEGORY}
        render={() => <BestPracticeCategory />}
      />
      <Route
        exact
        path={BEST_PRACTICES_TAG}
        render={() => <BestPracticeTag />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_BRAND_FLAGS_LIST}
        render={() => <AllBrandFlags />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_COMPANIES_LIST}
        render={() => <AllCompanies />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_SITE_FACILITY_OPTIONS}
        render={() => <SiteFacilityTypeOptions />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_AFFILIATIONS}
        render={() => <Affiliations />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_HOTEL_TYPES}
        render={() => <HotelTypes />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_ASSET_CLASSES}
        render={() => <AssetClasses />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_MARKET_SEGMENTS}
        render={() => <MarketSegment />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_LAUNDRY_LOCATIONS}
        render={() => <LaundryLocations />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_COUNTRY_LIST}
        render={() => <CountryList />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_CURRENCY_LIST}
        render={() => <CurrencyList />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_METROAREA_LIST}
        render={() => <MetroAreaList />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_PROVINCE_LIST}
        render={() => <ProvinceList />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_CLIMATE_ZONE_BAILEYS_ECOREGION}
        render={() => <BaileyEcoregion />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_CLIMATE_ZONE_KOPPEN_GEIGER}
        render={() => <KoppenGeiger />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_CERTIFICATIONS}
        render={() => <Certifications />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_OPERATIONAL_TYPES}
        render={() => <OperationalTypes />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_ENVIRONMENTAL_TYPES}
        render={() => <EnvironmentalTypes />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_PERFORMANCE_GROUP_LIST}
        render={() => <PerformanceGrouping />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_SUBTOPICS}
        render={() => <Subtopics />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_UNITS}
        render={() => <UnitsConversion />}
      />

      <Route
        exact
        path={SYSTEM_TOOLBOX_LAT_LONG_FINDER}
        render={() => <LatLongFinder />}
      />
      <Route
        exact
        path={SYSTEM_TOOLBOX_SUBSCRIPTIONS}
        render={() => <ViewAllSubscriptions />}
      />
    </Switch>
  );
};

export default withAuthentication(SystemToolbox);
