import React from "react";
import { ClassificationDonutChartViewContent } from "./ClassificationDonutChartView";
import { NumberService } from "../../../../services/UtilsService";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import { ClassificationDonutChartUtils } from "./ClassificationDonutChartUtils";
import DashboardExpandedModalView from "../../DashboardExpandedModalView";
import DashboardExpandedHeaderControls from "../DashboardExpandedHeaderControls";
import { expandedModalViewOptionEnum } from "../../Models/DashboardModels";
import { classificationDonutChartConfig } from "./ClassificationDonutChartViewConfig";
import DashboardDataGapsIndicator from "../DashboardDataGapsIndicator";
import JoinedBullet from "../../../common/JoinedBullet";
import LoadingSpinnerInFullPage from "../../../common/LoadingSpinnerInFullPage";

const ExpandedDonutChartViewHeader = ({
  title,
  period,
  unit,
  hasDataGaps,
  selectedViewOption,
  onSelectViewOption,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  return (
    <div className="dashboard--classification-donut-chart__expanded-chart-view__header-container">
      <div>
        <div className="expanded-chart-view__title">
          {title}
          {hasDataGaps && <DashboardDataGapsIndicator />}
        </div>
        <div className="expanded-chart-view__subtitle-container">
          <div className="subtitle">{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div className="subtitle">{unit}</div>
        </div>
      </div>
      <div
        id={
          classificationDonutChartConfig.expandedViewExcludeOnDownloadContainerId
        }
        className="dashboard-chart-header__action-buttons"
      >
        <DashboardExpandedHeaderControls
          selectedViewOption={selectedViewOption}
          onSelectViewOption={onSelectViewOption}
          onExportChart={downloadChartAsImage}
          onDownloadTableAsExcel={onDownloadTableAsExcel}
          isDownloading={isDownloadingChart}
        />
      </div>
    </div>
  );
};

const ExpandedDonutChartView = ({ chartData, unit, totalValue }) => {
  return (
    <div className="expanded-chart-view__chart-legend-container">
      <ClassificationDonutChartViewContent
        chartData={chartData}
        unit={unit}
        totalValue={totalValue}
        chartThickness={35}
        shouldShowAllItems={true}
      />
    </div>
  );
};

const ExpandedDonutChartTableView = ({ displayedData, unit, totalValue }) => {
  return (
    <div className="dashboard--expanded-view__table-container">
      <GVDSTableDisplay>
        <thead>
          <tr>
            <th>Type</th>
            <th style={{ textAlign: "right" }}>Percentage</th>
            <th style={{ textAlign: "right" }}>Value ({unit})</th>
          </tr>
        </thead>
        <tbody>
          {displayedData.map((chartData, index) => (
            <tr key={index}>
              {chartData
                .toTableRowData(totalValue)
                .map((cellData, columnIndex) => (
                  <td
                    key={`col-${columnIndex}`}
                    align={
                      columnIndex ===
                      classificationDonutChartConfig.classificationValueColIndex
                        ? "left"
                        : "right"
                    }
                  >
                    {cellData}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </GVDSTableDisplay>
    </div>
  );
};

const ExpandedDonutChartViewBody = ({
  chartData,
  unit,
  selectedViewOption,
  totalValue,
}) => {
  return (
    <div className="dashboard--classification-donut-chart__expanded-chart-view__content-container">
      {selectedViewOption === expandedModalViewOptionEnum.CHART ? (
        <ExpandedDonutChartView
          chartData={chartData}
          unit={unit}
          totalValue={totalValue}
        />
      ) : (
        <ExpandedDonutChartTableView
          displayedData={ClassificationDonutChartUtils.getDisplayedData(
            true,
            chartData
          )}
          unit={unit}
          totalValue={totalValue}
        />
      )}
    </div>
  );
};

const ExpandedDonutChartViewFooter = ({ footerLabel, unit, totalValue }) => {
  return (
    <div className="dashboard--classification-donut-chart__expanded-chart-view__footer-container">
      <div className="footer-label">{footerLabel}:</div>
      <div className="footer-value">
        <strong>{NumberService.format(totalValue)}</strong> {unit}
      </div>
    </div>
  );
};

const ExpandedClassificationDonutChartView = ({
  isShowExpandedClassificationChartView,
  onCloseModal,
  title,
  period,
  hasDataGaps,
  selectedExpandedViewOption,
  onSelectViewOption,
  chartData,
  unit,
  totalValue,
  footerLabel,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  return (
    <>
      <DashboardExpandedModalView
        id={classificationDonutChartConfig.expandedChartViewId}
        isShow={isShowExpandedClassificationChartView}
        closeModal={onCloseModal}
        hideCloseButton={isDownloadingChart}
        header={
          <ExpandedDonutChartViewHeader
            title={title}
            period={period}
            unit={unit}
            hasDataGaps={hasDataGaps}
            selectedViewOption={selectedExpandedViewOption}
            onSelectViewOption={onSelectViewOption}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        }
        body={
          <ExpandedDonutChartViewBody
            chartData={chartData}
            unit={unit}
            totalValue={totalValue}
            selectedViewOption={selectedExpandedViewOption}
          />
        }
        footer={
          selectedExpandedViewOption === expandedModalViewOptionEnum.CHART ? (
            <ExpandedDonutChartViewFooter
              footerLabel={footerLabel}
              unit={unit}
              totalValue={totalValue}
            />
          ) : null
        }
      />
      {isShowExpandedClassificationChartView && isDownloadingChart && (
        <LoadingSpinnerInFullPage />
      )}
    </>
  );
};

export default ExpandedClassificationDonutChartView;
