import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import GVDSIconSlim from "../Icons/GVDSIconSlim";
import GVDSButton, { buttonVariant } from "../Buttons/GVDSButton";

const PageHeaderTitle = ({ children }) => {
  return <div className="page-header--title">{children}</div>;
};

const PageHeaderStatus = ({ children }) => {
  return <div className="page-header--status">{children}</div>;
};

const PageHeaderBackButton = ({
  text = "Return to previous page",
  onClick,
  className = "",
}) => {
  return (
    <div className="page-header--back-button-container">
      <GVDSButton
        variant={buttonVariant.tertiary}
        className={"page-header--back-button " + className}
        onClick={onClick}
        icon={<GVDSIconSlim Icon={IconArrowNarrowLeft} />}
        text={text}
      />
    </div>
  );
};

const PageHeaderDescription = ({ children }) => {
  return (
    <Row>
      <Col className="page-header--description">
        {children}
      </Col>
    </Row>
  );
};

const PageHeader = ({ children }) => {
  return (
    <Container fluid className="page-header">
      {children}
    </Container>
  );
};

PageHeader.Title = PageHeaderTitle;
PageHeader.Status = PageHeaderStatus;
PageHeader.Description = PageHeaderDescription;
PageHeader.BackButton = PageHeaderBackButton;

export default PageHeader;
