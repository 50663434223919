import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconCheck } from "@tabler/icons-react";
import React from "react";

export const ANSWERING_SAVE_STATUS = {
  LOADED_FROM_API: "Loaded",
  NOT_SAVED: "Not saved",
  SAVING: "Saving...",
  SAVED: "Saved",
  FAILED: "Failed to save",
};

export const getSaveStatus = (saveStatus) => {
  if (saveStatus === ANSWERING_SAVE_STATUS.NOT_SAVED) {
    return (
      <div className="answer-save-status gvds-color--gray6">
        Not saved yet
      </div>
    );
  } else if (saveStatus === ANSWERING_SAVE_STATUS.SAVING) {
    return (
      <div className="answer-save-status gvds-color--gray6">
        Saving...
      </div>
    );
  } else if (saveStatus === ANSWERING_SAVE_STATUS.SAVED) {
    return (
      <div className="answer-save-status gvds-color--success">
        <GVDSIcon Icon={IconCheck} /> Saved
      </div>
    );
  } else if (saveStatus === ANSWERING_SAVE_STATUS.FAILED) {
    return (
      <div className="answer-save-status gvds-color--error">
        Failed to save
      </div>
    );
  } else {
    return null;
  }
};
