import axios from "axios";
import { API_URL } from "../../../config/api-config";

export default class AffiliationsService {
  static GetAllAffiliation = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/affiliations"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  static createRecord = async (name) => {
    try {
      const data = {
        name,
      };
      const response = await axios.post(
        API_URL + "/api/v1/ref_data/affiliations",
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateRecord = async (Id, name) => {
    try {
      const data = {
        name,
      };
      const response = await axios.patch(
        API_URL + `/api/v1/ref_data/affiliations/${Id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static GetAffiliationById = async (typeId) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/affiliations/" + typeId
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static deleteDataRecords = async (typeId) => {
    try {
      const response = await axios.delete(
        API_URL + `/api/v1/ref_data/affiliations/${typeId}`
      );
      return response;
    } catch (error) {}
  };
}
