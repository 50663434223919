import React, { useContext, useEffect, useState } from "react";
import UserInventoryContext from "../../../context/UserInventoryContext";
import ToastContext from "../../../context/ToastContext";
import { StringUtils } from "../../../services/UtilsService";
import UserManagedTagService from "../../../services/UserManagedTagService";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { UserManagedTagDisplay } from "../../common/CentralTags/UserManagedTagDisplay";
import { RESOURCES } from "../../../config/constants";
import _ from "lodash";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormFieldWithCharacterCount from "../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import { IconChevronRight } from "@tabler/icons-react";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";

const maxTagInputCharacterAllowed = 32;
const maxDescriptionCharacterAllowed = 50;

const exampleTags = ["Utility Bills", "Volunteer Event", "Sustainable Seafood"];

const LearnTagSectionBlurb = ({ selectedInventoryType }) => {
  const isPortfolio = selectedInventoryType === RESOURCES.PORTFOLIO;
  const portfolioNamingTagBlurb = (
    <>
      <p>
        Tags help sites within your portfolio organize their items, and
        aggregate collective impact at the portfolio level
      </p>
      <p>
        For example, tags for common initiatives and document categories can be
        created:{" "}
        {exampleTags.map((example, index) => (
          <UserManagedTagDisplay
            tagText={example}
            resourceType={selectedInventoryType}
            key={index}
          />
        ))}
      </p>
      <p>
        Sites within the portfolio can now use tags to label their items as they
        log initiatives and documents to track their contribution. This makes
        tracking and reporting on the impact of the entire program easy!
      </p>
    </>
  );

  const siteNamingTagBlurb = (
    <>
      <p>
        Tags help sites organize their items, and aggregate collective impact.
      </p>
      <p>
        For example, tags for common initiatives and document categories can be
        created:{" "}
        {exampleTags.map((example, index) => (
          <UserManagedTagDisplay
            tagText={example}
            resourceType={selectedInventoryType}
            key={index}
          />
        ))}
      </p>
      <p>
        Sites can now use tags to label their items as they log initiatives and
        documents to track their contribution. This makes tracking and reporting
        on the impact of the entire program easy!
      </p>
    </>
  );

  return (
    <div className="mt-3">
      {isPortfolio ? portfolioNamingTagBlurb : siteNamingTagBlurb}
    </div>
  );
};

const TagInputModal = ({
  show,
  isPortfolio,
  currentTag,
  onSuccess,
  onClose,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [tagNameInput, setTagNameInput] = useState("");
  const [tagNameInputError, setTagNameInputError] = useState(null);
  const [tagDescriptionInput, setTagDescriptionInput] = useState("");
  const [showTagConvention, setShowTagConvention] = useState(false);

  const [
    isDataErrorAndPossibleErrorChecked,
    setIsDataErrorAndPossibleErrorChecked,
  ] = useState(false);
  const [dataErrors, setDataErrors] = useState({});
  const [possibleErrors, setPossibleErrors] = useState({});

  const onResetForm = () => {
    setIsValidated(false);
    setTagNameInput("");
    setTagDescriptionInput("");
    setShowTagConvention(false);

    setDataErrors([]);
    setPossibleErrors([]);
    setIsDataErrorAndPossibleErrorChecked(false);
  };

  const onCloseModal = () => {
    if (!isLoading) {
      onResetForm();
      onClose();
    }
  };

  useEffect(() => {
    if (currentTag) {
      setTagNameInput(currentTag.name);
      setTagDescriptionInput(currentTag.description);
    } else {
      setTagNameInput("");
      setTagDescriptionInput("");
    }
  }, [currentTag]);

  const saveTag = async () => {
    setIsValidated(true);
    if (selectedInventory && StringUtils.isNotEmpty(tagNameInput)) {
      setIsLoading(true);
      const isCreateNotUpdate = !currentTag;

      try {
        const validationErrors = await UserManagedTagService.validateTag(
          selectedInventory.type,
          selectedInventory.id,
          isCreateNotUpdate ? "" : currentTag.name,
          tagNameInput
        );

        if (
          !_.isEmpty(validationErrors.dataErrors) ||
          !_.isEmpty(validationErrors.possibleErrors)
        ) {
          setDataErrors(validationErrors.dataErrors);
          setPossibleErrors(validationErrors.possibleErrors);
          setIsDataErrorAndPossibleErrorChecked(true);
          setIsLoading(false);
        } else {
          await saveTagWithoutValidation();
        }
      } catch (error) {
        toastContext.addFailToast(
          <span>Failed to validate tag, please try again.</span>
        );
        setIsLoading(false);
      }
    }
  };

  const saveTagWithoutValidation = async () => {
    const isCreateNotUpdate = !currentTag;

    try {
      if (isCreateNotUpdate) {
        await UserManagedTagService.createTag(
          selectedInventory.type,
          selectedInventory.id,
          tagNameInput,
          tagDescriptionInput
        );
        toastContext.addSuccessToast(
          <span>{tagNameInput} created successfully</span>
        );
      } else {
        await UserManagedTagService.updateTag(
          selectedInventory.type,
          selectedInventory.id,
          currentTag.id,
          tagNameInput,
          tagDescriptionInput
        );

        toastContext.addSuccessToast(
          <span>{currentTag.name} updated successfully</span>
        );
      }

      onSuccess();

      onCloseModal();
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;

      toastContext.addFailToast(
        <span>
          Failed to {isCreateNotUpdate ? "create" : "update"} tag.
          {!!errorMessage ? " " + errorMessage : null}
        </span>
      );
      setIsLoading(false);
    }
  };

  const modalTitle = `${currentTag ? "Edit" : "Create"} ${
    isPortfolio ? "Portfolio" : "Site"
  } Tag`;

  const learnTagSectionButton = (
    <Button
      variant="link"
      className="p-0 gv-text-14 text-vertical-center"
      onClick={() => setShowTagConvention(true)}
    >
      Learn more about naming tags{" "}
      <span>
        <GVDSIconSlim Icon={IconChevronRight} />
      </span>
    </Button>
  );

  const getSaveButton = () => {
    const isOnlyPossibleErrors =
      isDataErrorAndPossibleErrorChecked &&
      _.isEmpty(dataErrors) &&
      !_.isEmpty(possibleErrors);

    if (isLoading) {
      return (
        <GVDSButton variant={buttonVariant.primary} disabled text="Saving..." />
      );
    } else if (isOnlyPossibleErrors) {
      return (
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={saveTagWithoutValidation}
          text="Save anyway"
        />
      );
    } else {
      return (
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={saveTag}
          text="Save"
        />
      );
    }
  };

  return (
    <GVDSModal
      title={modalTitle}
      size={GVDSModal.Size.medium}
      show={show}
      onHide={onCloseModal}
    >
      <GVDSModal.Body>
        <div>
          <GVFormGroup className="mb-1">
            <Form.Label>Tag Name</Form.Label>
            <div className="form-label-description">
              Tags that are short and standardized make everyone’s work
              smoother.
            </div>
            <GVDSFormFieldWithCharacterCount
              name="tagName"
              placeholder="Tag Name"
              value={tagNameInput}
              maxLength={maxTagInputCharacterAllowed}
              onInput={(value) => {
                setTagNameInput(value);
                setIsDataErrorAndPossibleErrorChecked(false);
              }}
              statusMetadata={
                isValidated && !StringUtils.isNotEmpty(tagNameInput)
                  ? FormFieldStatusMetadata.getError("Please provide tag name.")
                  : dataErrors && dataErrors.length > 0
                  ? FormFieldStatusMetadata.getError(
                      "This tag name already exists."
                    )
                  : possibleErrors && possibleErrors.length > 0
                  ? FormFieldStatusMetadata.getWarning(
                      `This tag name already exists in ${
                        isPortfolio
                          ? "sites under this portfolio"
                          : "portfolio of this site"
                      }. To proceed, click Save anyway.`
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>
          <div className="mb-3">
            {!showTagConvention ? (
              learnTagSectionButton
            ) : (
              <LearnTagSectionBlurb
                selectedInventoryType={selectedInventory.type}
              />
            )}
          </div>

          <GVFormGroup className="mb-1">
            <Form.Label>Description</Form.Label>
            <span className="optional-form-label mx-1">(Optional)</span>
            <div className="form-label-description">
              Provide additional context for the tag.
            </div>
            <GVDSFormFieldWithCharacterCount
              name="tagDescription"
              placeholder="Description about the tag"
              value={tagDescriptionInput}
              maxLength={maxDescriptionCharacterAllowed}
              onInput={(value) => {
                setTagDescriptionInput(value);
                setIsDataErrorAndPossibleErrorChecked(false);
              }}
              statusMetadata={FormFieldStatusMetadata.getDefault()}
            />
          </GVFormGroup>
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onCloseModal}
          disabled={isLoading}
          text="Cancel"
        />
        {getSaveButton()}
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default TagInputModal;
