import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import DataRequestService from "../../../../services/DataRequestService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import ToastContext from "../../../../context/ToastContext";
import GVFormGroup from "../../../common/GVFormGroup";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleDatePicker from "../../../../gvds-components/Forms/GVDSFormSingleDatePicker";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";
import GVDSTextAreaWithCharacterCount from "../../../../gvds-components/Forms/GVDSTextAreaWithCharacterCount";
import SupportContactLink from "../../../common/SupportContactLink";

const MAX_LENGTH_DATA_REQUEST_DESC = 300;

const COMPONENT_STATE = {
  INPUT: "Input",
  SENDING_EMAIL: "Sending Email",
  DONE: "Done",
  FAILED_TO_SEND_EMAIL: "Failed to send email",
};

const CreateDataRequestModal = ({
  requestType,
  requestParams,
  missingDataTypes,
  startDate,
  endDate,
  onModalClose,
  onCreate,
}) => {
  const toastContext = useContext(ToastContext);

  const [componentState, setComponentState] = useState(COMPONENT_STATE.INPUT);

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [deadline, setDeadline] = useState(null);
  const [description, setDescription] = useState("");
  const [siteNames, setSiteNames] = useState([]);
  const [preview, setPreview] = useState({});

  useEffect(() => {
    DataRequestService.getPreview(requestType, requestParams).then((dr) =>
      setPreview(dr)
    );
  }, []);

  const handleClose = () => {
    if (!isLoading) {
      onModalClose();
    }
  };

  const isDataValid = () => {
    return deadline !== null;
  };

  const createDataRequest = async (e) => {
    e.preventDefault();
    setIsValidated(true);
    if (isDataValid()) {
      setIsLoading(true);
      try {
        const dataRequest = await DataRequestService.createDataRequest(
          requestType,
          startDate,
          endDate,
          deadline,
          description,
          requestParams
        );
        sendEmailForDataRequest(dataRequest.id).then();
      } finally {
        setIsValidated(false);
        setIsLoading(false);
      }
    }
  };

  const sendEmailForDataRequest = async (dataRequestId) => {
    setComponentState(COMPONENT_STATE.SENDING_EMAIL);
    try {
      const emailRes = await DataRequestService.sendEmailForDataRequest(
        dataRequestId
      );
      setSiteNames(emailRes["site_names"]);
      setComponentState(COMPONENT_STATE.DONE);
    } catch (e) {
      setComponentState(COMPONENT_STATE.FAILED_TO_SEND_EMAIL);
      toastContext.addFailToast(
        <span>Failed to send email for data request.</span>
      );
    }
  };

  if (componentState === COMPONENT_STATE.SENDING_EMAIL) {
    return (
      <GVDSModal
        title="Sending out your data request"
        size={GVDSModal.Size.small}
        className="data-request-email-modal"
        show={true}
      >
        <GVDSModal.Body>
          <div>
            Sites are currently being inspected for missing data. Emails will
            only be sent out to sites with missing data to notify them of your
            data request. This may take slightly longer depending on the number
            of sites receiving this email.
          </div>
          <div className="p-4">
            <LoadingSpinner />
          </div>
          <div className="gv-body-1 color-red text-center">
            Please do not refresh or leave this page until all emails are sent.
          </div>
        </GVDSModal.Body>
      </GVDSModal>
    );
  } else if (componentState === COMPONENT_STATE.DONE) {
    if (siteNames?.length > 0) {
      return (
        <GVDSModal
          title="Emails successfully sent"
          size={GVDSModal.Size.small}
          className="data-request-site-names-modal"
          show={true}
        >
          <GVDSModal.Body>
            <div className="mb-1">
              The following sites with missing data have received your data
              request:
            </div>
            <ul>
              {siteNames.map((n, index) => (
                <li key={index}>{n}</li>
              ))}
            </ul>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={onCreate}
              text="Close"
            />
          </GVDSModal.Footer>
        </GVDSModal>
      );
    } else {
      return (
        <GVDSModal
          title="Data request complete"
          size={GVDSModal.Size.small}
          className="data-request-site-names-modal"
          show={true}
        >
          <GVDSModal.Body>
            <div className="mb-1">
              All of your sites and facilities have complete data for the time
              period requested. No emails were sent. For details, go to Data
              Management > Overview page to see the data request.
            </div>
          </GVDSModal.Body>
          <GVDSModal.Footer>
            <GVDSButton
              variant={buttonVariant.primary}
              onClick={onCreate}
              text="Close"
            />
          </GVDSModal.Footer>
        </GVDSModal>
      );
    }
  } else if (componentState === COMPONENT_STATE.FAILED_TO_SEND_EMAIL) {
    return (
      <GVDSModal
        title="Emails failed to be sent"
        size={GVDSModal.Size.small}
        show={true}
        onHide={handleClose}
      >
        <GVDSModal.Body>
          <div className="mb-1">
            Your data request has been successfully created, but the system
            failed to send the emails to the sites.
            <br />
            <br />
            Reach out to <SupportContactLink /> for help.
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={handleClose}
            text="Close"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    );
  } else {
    let content;

    if (isLoading) {
      content = <LoadingSpinner />;
    } else {
      content = (
        <>
          <GVFormGroup>
            <Form.Label>Deadline</Form.Label>
            <GVDSFormSingleDatePicker
              required
              className="data-request-deadline-input"
              selected={deadline}
              dateFormat="dd MMM yyyy"
              minDate={new Date()}
              onChange={(date) => setDeadline(date)}
              statusMetadata={
                isValidated && deadline === null
                  ? FormFieldStatusMetadata.getError(
                      "Please provide a deadline."
                    )
                  : FormFieldStatusMetadata.getDefault()
              }
            />
          </GVFormGroup>

          <GVFormGroup controlId="dataRequestDescription">
            <Form.Label>Description</Form.Label>
            <GVDSTextAreaWithCharacterCount
              className="data-request-description-input"
              placeholder="Information for the users who will input data to fulfil this request. e.g., “This data will be used in our year-end sustainability report”"
              value={description}
              onInput={(value) => setDescription(value)}
              maxLength={MAX_LENGTH_DATA_REQUEST_DESC}
              rows={3}
            />
          </GVFormGroup>

          <GVFormGroup>
            <Form.Label>Period</Form.Label>
            {preview.periods &&
              preview.periods.map((p, index) => <div key={index}>{p}</div>)}
          </GVFormGroup>

          <GVFormGroup>
            <Form.Label>Requested Data</Form.Label>
            {(!missingDataTypes || missingDataTypes.length === 0) && (
              <div>
                Once created, you can view the requested data in the Data
                Management > Overview page.
              </div>
            )}
            {missingDataTypes.map((type, index) => (
              <div key={index}>- {type}</div>
            ))}
          </GVFormGroup>
          <div className="pb-5 px-2">
            Emails will only be sent out to sites with missing data to notify
            them of your data request.
          </div>
        </>
      );
    }

    return (
      <GVDSModal
        title="Create a Request"
        size={GVDSModal.Size.medium}
        className="data-request-modal"
        show={true}
        onHide={handleClose}
      >
        <GVDSModal.Body>{content}</GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={handleClose}
            text="Close"
            disabled={isLoading}
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={createDataRequest}
            text="Send Request"
            disabled={isLoading}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    );
  }
};

export default CreateDataRequestModal;
