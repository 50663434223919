import React, { useContext, useEffect, useState } from "react";
import { get } from "lodash";
import Form from "react-bootstrap/Form";

import ContractService, {
  PortfolioResourceDataRequestModel,
} from "../../../services/ContractService";
import SelectSitesAndFacilities from "./SelectSitesAndFacilities";
import ToastContext from "../../../context/ToastContext";
import GVFormGroup from "../../common/GVFormGroup";
import UserInventoryContext from "../../../context/UserInventoryContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import PortfolioCreatorDisplay from "./PortfolioCreatorDisplay";
import { DateTimeUtils, StringUtils } from "../../../services/UtilsService";
import { CollectiveTargetStatus } from "../../GoalsAndTargets/ViewCollectiveTargetDetails";
import {
  SURVEY_STATUS_ORDER,
  TARGET_STATUS_ORDER,
} from "../../../config/constants";
import moment from "moment/moment";
import { DataRequestStatusEnum } from "../../Data/Overview/DataRequest/DataRequestModel";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormFieldWithCharacterCount from "../../../gvds-components/Forms/GVDSFormFieldWithCharacterCount";
import Spacer from "../../../gvds-components/Layout/Spacer";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import SurveyStatusBadge from "../../Surveys/Components/SurveyStatusBadge";

const MAX_LENGTH_PORTFOLIO_NAME = 35;
const MAX_LENGTH_PORTFOLIO_DESC = 300;

const collectiveTargetStatusSortFn = (collectiveTarget1, collectiveTarget2) => {
  const sortOrder1 = TARGET_STATUS_ORDER[collectiveTarget1.status];
  const sortOrder2 = TARGET_STATUS_ORDER[collectiveTarget2.status];

  if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
    return sortOrder1 - sortOrder2;
  } else if (sortOrder1 !== undefined) {
    return -1;
  } else if (sortOrder2 !== undefined) {
    return 1;
  } else {
    return 0;
  }
};

const surveyStatusSortFn = (survey1, survey2) => {
  const sortOrder1 = SURVEY_STATUS_ORDER[survey1.status];
  const sortOrder2 = SURVEY_STATUS_ORDER[survey2.status];

  if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
    return sortOrder1 - sortOrder2;
  } else if (sortOrder1 !== undefined) {
    return -1;
  } else if (sortOrder2 !== undefined) {
    return 1;
  } else {
    return 0;
  }
};

const dataRequestStatusSortFn = (dr1, dr2) => {
  if (dr1.status === dr2.status) {
    return moment.utc(dr1.deadline) - moment.utc(dr2.deadline);
  } else if (dr1.status === DataRequestStatusEnum.EXPIRED) {
    return 1;
  } else {
    return -1;
  }
};

export const InputSubPortfolio = ({
  currentPortfolio,
  onSuccess,
  contractId,
  show,
  onClose,
  deleteTriggerComponent,
}) => {
  const toastContext = useContext(ToastContext);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [selectedFacilityIds, setSelectedFacilityIds] = useState([]);
  const [siteIdsInPortfolio, setSiteIdsInPortfolio] = useState([]);
  const [facilityIdsInPortfolio, setFacilityIdsInPortfolio] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const [contractSites, setContractSites] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    ContractService.getPortfolioConfig(contractId)
      .then((portfolioConfig) => {
        setContractSites(portfolioConfig.sites);
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to load portfolio options.</span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [contractId]);

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const isNameInvalid = () => {
    return !name || name.length === 0;
  };

  const isNameError = () => {
    return !!get(inputErrors, "name");
  };

  const isNoSiteOrFacilitySelected = () => {
    return selectedSiteIds.length === 0 && selectedFacilityIds.length === 0;
  };

  const saveSubPortfolio = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsValidated(true);

    if (isNameInvalid() || isNoSiteOrFacilitySelected()) {
      return;
    }

    setIsLoading(true);
    if (currentPortfolio) {
      ContractService.editSubPortfolio(contractId, currentPortfolio.id, {
        name: name,
        description: description,
        siteIds: selectedSiteIds,
        facilityIds: selectedFacilityIds,
      })
        .then(() => {
          toastContext.addSuccessToast(
            <span>Sub-portfolio updated successfully.</span>
          );
          clearInput();
          onSuccess();
        })
        .catch((error) => {
          if (error.status === 400) {
            setInputErrors(error.data.message);
          } else {
            toastContext.addFailToast(
              <span>Failed to update sub-portfolio.</span>
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      ContractService.addPortfolioToContract(contractId, {
        name: name,
        description: description,
        siteIds: selectedSiteIds,
        facilityIds: selectedFacilityIds,
      })
        .then(() => {
          toastContext.addSuccessToast(
            <span>Sub-portfolio created successfully.</span>
          );
          clearInput();
          onSuccess();
        })
        .catch((error) => {
          if (error.status === 400) {
            setInputErrors(error.data.message);
          } else {
            toastContext.addFailToast(
              <span>Failed to create sub-portfolio.</span>
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const setInputToCurrentPortfolio = () => {
    setName(currentPortfolio.name || "");
    setDescription(currentPortfolio.description || "");
    const siteIdsInPortfolio = currentPortfolio.sites.map((s) => s.id);
    setSiteIdsInPortfolio(siteIdsInPortfolio);
    setSelectedSiteIds(siteIdsInPortfolio);
    const facilityIdsInPortfolio = currentPortfolio.facilities.map((f) => f.id);
    setFacilityIdsInPortfolio(facilityIdsInPortfolio);
    setSelectedFacilityIds(facilityIdsInPortfolio);
    setInputErrors({});
  };

  const clearInput = () => {
    setIsValidated(false);
    setName("");
    setDescription("");
    setSiteIdsInPortfolio([]);
    setSelectedSiteIds([]);
    setFacilityIdsInPortfolio([]);
    setSelectedFacilityIds([]);
    setInputErrors({});
  };

  useEffect(() => {
    if (currentPortfolio) {
      setInputToCurrentPortfolio();
    } else {
      clearInput();
    }
  }, [currentPortfolio]);

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    const showCharacterLeftForDesc =
      StringUtils.calculateAvailableCharacterLeft(
        description,
        MAX_LENGTH_PORTFOLIO_DESC,
        50
      );
    content = (
      <>
        <GVFormGroup controlId="portfolio-name">
          <Form.Label>Portfolio Name</Form.Label>
          <GVDSFormFieldWithCharacterCount
            placeholder="Enter Portfolio Name"
            value={name}
            onInput={(value) => {
              setName(value);
            }}
            className="portfolio-name col-4"
            maxLength={MAX_LENGTH_PORTFOLIO_NAME}
            statusMetadata={
              isValidated && isNameInvalid()
                ? FormFieldStatusMetadata.getError(
                    "Please provide sub-portfolio name."
                  )
                : isNameError()
                ? FormFieldStatusMetadata.getError(get(inputErrors, "name"))
                : FormFieldStatusMetadata.getDefault()
            }
          />
        </GVFormGroup>
        <GVFormGroup controlId="portfolio-description">
          <Form.Label>
            Portfolio Description{" "}
            <span className="optional-form-label">(optional)</span>
          </Form.Label>
          <GVDSFormField
            placeholder="Enter Portfolio Description"
            value={description}
            onInput={(value) => setDescription(value)}
            className="portfolio-description"
            maxLength={MAX_LENGTH_PORTFOLIO_DESC}
          />
          {showCharacterLeftForDesc && (
            <div className="caption mt-1">{showCharacterLeftForDesc}</div>
          )}
        </GVFormGroup>
        <Form.Label>Select Sites and Facilities </Form.Label>
        <div className="mb-2">
          When a Site is selected, all Facilities under it are selected by
          default. Click on "<em>(expand)</em>" to show all Facilities within a
          Site.
        </div>
        <SelectSitesAndFacilities
          allSites={contractSites}
          sitesInPortfolio={siteIdsInPortfolio}
          selectedSites={selectedSiteIds}
          onSitesSelect={setSelectedSiteIds}
          facilitiesInPortfolio={facilityIdsInPortfolio}
          selectedFacilities={selectedFacilityIds}
          onFacilitiesSelect={setSelectedFacilityIds}
        />
        {currentPortfolio && (
          <PortfolioCreatorDisplay currentPortfolio={currentPortfolio} />
        )}
      </>
    );
  }

  return (
    <GVDSModal
      title={currentPortfolio ? "Manage Sub-Portfolio" : "Create Sub-Portfolio"}
      size={GVDSModal.Size.large}
      show={show}
      onHide={handleClose}
    >
      <GVDSModal.Body>{content}</GVDSModal.Body>
      <GVDSModal.Footer>
        {!isLoading && currentPortfolio && (
          <>
            {deleteTriggerComponent}
            <Spacer />
          </>
        )}

        {isValidated && isNoSiteOrFacilitySelected() && (
          <span className="text-danger me-2">
            Please select at least one Site or Facility
          </span>
        )}
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={handleClose}
          disabled={isLoading}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          text={currentPortfolio ? "Save" : "Create New Sub-Portfolio"}
          disabled={isLoading}
          onClick={(event) => saveSubPortfolio(event)}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export const DeleteSubPortfolio = ({
  show,
  currentPortfolio,
  onClose,
  onSuccess,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const contractId = userInventory.selectedContractId.get;

  const [isLoading, setIsLoading] = useState(false);

  const [subportfolioCollectiveTargets, setSubportfolioCollectiveTargets] =
    useState([]);
  const [subPortfolioSurveys, setSubPortfolioSurveys] = useState([]);
  const [subPortfolioDataRequests, setSubPortfolioDataRequests] = useState([]);

  const [confirmActionTextValue, setConfirmActionTextValue] = useState("");

  let trimmedPortfolioName = currentPortfolio
    ? currentPortfolio.name.trim()
    : "";

  const deleteSubportfolio = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    ContractService.deleteSubPortfolio(contractId, currentPortfolio.id)
      .then(() => {
        toastContext.addSuccessToast(
          <span>Sub-Portfolio deleted successfully</span>
        );
        onSuccess();
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to delete sub-portfolio.</span>);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const hasSurveysOrDataRequests = () => {
    return (
      (subportfolioCollectiveTargets &&
        subportfolioCollectiveTargets.length > 0) ||
      (subPortfolioSurveys && subPortfolioSurveys.length > 0) ||
      (subPortfolioDataRequests && subPortfolioDataRequests.length > 0)
    );
  };

  const isConfirmationValid = () => {
    if (!hasSurveysOrDataRequests()) {
      return true;
    }

    return trimmedPortfolioName === confirmActionTextValue;
  };

  const loadPortfolioResources = () => {
    setIsLoading(true);
    if (currentPortfolio.id) {
      ContractService.getPortfolioResources(contractId, currentPortfolio.id)
        .then((response) => {
          setSubportfolioCollectiveTargets(response.collective_targets);
          setSubPortfolioSurveys(response.surveys);
          setSubPortfolioDataRequests(
            response.data_requests.map((dr) =>
              PortfolioResourceDataRequestModel.fromJson(dr)
            )
          );
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load portfolio resources.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (currentPortfolio) {
      loadPortfolioResources();
    }
  }, [currentPortfolio]);

  let modalContent;

  if (isLoading) {
    modalContent = <LoadingSpinner />;
  } else if (hasSurveysOrDataRequests()) {
    modalContent = (
      <div>
        <div>
          The existing shared targets, surveys or data requests (listed below)
          were created under this sub-portfolio and will also be deleted.
        </div>
        <SubportfolioResourcesDisplay
          collectiveTargets={subportfolioCollectiveTargets}
          surveys={subPortfolioSurveys}
          dataRequests={subPortfolioDataRequests}
        />
        <div>
          Please note that:
          <ul>
            <li>
              Site responses will be deleted along with its survey. Download all
              survey reports for future use before proceeding with this
              deletion.
            </li>
            <li>
              Open surveys and incomplete data requests will disappear for Sites
              responding to them.
            </li>
            <li>
              Sites and Facilities within this sub-portfolio will not be
              deleted.
            </li>
            <li>Guide bookmarks will be deleted.</li>
          </ul>
        </div>
        <div>
          This action cannot be undone. Are you sure? Type the name of your
          sub-portfolio <strong>"{trimmedPortfolioName}"</strong> to confirm.
          <div className="mt-2">
            <Form.Control
              type="text"
              name="confirmAction"
              value={confirmActionTextValue}
              onChange={(e) => setConfirmActionTextValue(e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  } else {
    modalContent = (
      <div>
        <div className="mb-2">
          You’re about to delete your sub-portfolio,{" "}
          <strong>{trimmedPortfolioName}</strong>.
        </div>
        <div>
          Please note that:
          <ul>
            <li>
              The Sites and Facilities within this sub-portfolio will not be
              deleted.
            </li>
            <li>Guide bookmarks will be deleted.</li>
          </ul>
        </div>
        <div>This action cannot be undone. Are you sure?</div>
      </div>
    );
  }

  return (
    <GVDSModal
      title="Delete Sub-Portfolio"
      size={GVDSModal.Size.medium}
      show={show}
      onHide={onClose}
    >
      <GVDSModal.Body>{modalContent}</GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          text="Cancel"
          disabled={isLoading}
          onClick={onClose}
        />
        <GVDSButton
          variant={buttonVariant.destructive_primary}
          text="Yes, Delete"
          disabled={isLoading || !isConfirmationValid()}
          onClick={deleteSubportfolio}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const SubportfolioResourcesDisplay = ({
  collectiveTargets,
  surveys,
  dataRequests,
}) => {
  return (
    <div className="subportfolio-survey-data-request__container">
      {collectiveTargets && collectiveTargets.length > 0 && (
        <div>
          <div className="body-1-bold">Shared Targets</div>
          <ul>
            {collectiveTargets
              .sort(collectiveTargetStatusSortFn)
              .map((target) => (
                <li key={target.id}>
                  <div className="my-1">
                    <CollectiveTargetStatus status={target.status} />{" "}
                    {target.name}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      )}
      {surveys && surveys.length > 0 && (
        <div>
          <div className="body-1-bold">Surveys</div>
          <ul>
            {surveys.sort(surveyStatusSortFn).map((survey) => (
              <li key={survey.id}>
                <div className="my-1">
                  <SurveyStatusBadge status={survey.status} /> {survey.name}{" "}
                  (Deadline:{" "}
                  {survey.deadline
                    ? DateTimeUtils.formatUTCDate(survey.deadline)
                    : "-"}
                  )
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {dataRequests && dataRequests.length > 0 && (
        <div>
          <div className="body-1-bold">Data Requests</div>
          <ul>
            {dataRequests.sort(dataRequestStatusSortFn).map((dataRequest) => (
              <li key={dataRequest.id}>
                <div className="my-1">
                  <StatusLabel color={dataRequest.getStatusLabelColor()}>
                    {dataRequest.status}
                  </StatusLabel>{" "}
                  {dataRequest.typeInFullName} (Deadline:{" "}
                  {dataRequest.deadline
                    ? DateTimeUtils.formatUTCDate(dataRequest.deadline)
                    : "-"}
                  )
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
