import JoinedBullet from "../../../common/JoinedBullet";
import DashboardMinifiedHeaderControls from "../DashboardMinifiedHeaderControls";
import React, { useContext } from "react";
import DashboardDataGapsIndicator from "../DashboardDataGapsIndicator";
import DashboardExpandedHeaderControls from "../DashboardExpandedHeaderControls";
import DashboardDataContext from "../../Context/DashboardDataContext";
import InfoTooltip from "../../../common/Tooltip/InfoTooltip";
import { TOOLTIP_PLACEMENTS } from "../../../../config/style-config";
import { performanceComparisonChartConfig } from "./PerformanceComparisonChartViewConfig";

export const PerformanceComparisonChartViewHeader = ({
  title,
  titleInfoTooltip,
  period,
  hasDataGaps,
  subtopic,
  intensity,
  hasData,
  showExpandedTableView,
  showExpandedChartView,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  const dashboardDataContext = useContext(DashboardDataContext);

  return (
    <div className="header-container">
      <div>
        <div className="title-container">
          <div className="title">{title}</div>
          {!!titleInfoTooltip && (
            <InfoTooltip
              info={titleInfoTooltip}
              placement={TOOLTIP_PLACEMENTS.TOP}
            />
          )}
          {hasDataGaps && <DashboardDataGapsIndicator />}
        </div>
        <div className="subtitle-container">
          <div className="subtitle">{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div className="subtitle">
            {subtopic} {intensity}
          </div>
        </div>
      </div>
      {!dashboardDataContext.isDownloadingDashboardAsImage && (
        <div
          id={
            performanceComparisonChartConfig.miniViewExcludeOnDownloadContainerId
          }
          className="dashboard-chart-header__action-buttons"
        >
          <DashboardMinifiedHeaderControls
            showExpandedTableView={showExpandedTableView}
            showExpandedChartView={showExpandedChartView}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
            disabled={!hasData}
            isDownloading={isDownloadingChart}
          />
        </div>
      )}
    </div>
  );
};

export const ExpandedPerformanceComparisonChartViewHeader = ({
  title,
  period,
  hasDataGaps,
  subtopic,
  intensity,
  selectedExpandedViewOption,
  onExpandedViewOptionChange,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  return (
    <div className="dashboard--performance-comparison__expanded-chart-view__header-container">
      <div>
        <div className="expanded-chart-view__title-container">
          <div className="expanded-chart-view__title">{title}</div>
          {hasDataGaps && <DashboardDataGapsIndicator />}
        </div>
        <div className="expanded-chart-view__subtitle">
          <div>{period}</div>
          <JoinedBullet className="dashboard-joined-bullet" />
          <div>
            {subtopic} {intensity}
          </div>
        </div>
      </div>
      <div
        id={
          performanceComparisonChartConfig.expandedViewExcludeOnDownloadContainerId
        }
        className="dashboard-chart-header__action-buttons"
      >
        <DashboardExpandedHeaderControls
          selectedViewOption={selectedExpandedViewOption}
          onSelectViewOption={onExpandedViewOptionChange}
          onExportChart={downloadChartAsImage}
          onDownloadTableAsExcel={onDownloadTableAsExcel}
          isDownloading={isDownloadingChart}
        />
      </div>
    </div>
  );
};
