import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import {
  IconChevronLeft,
  IconChevronRight,
  IconSpeakerphone,
} from "@tabler/icons-react";
import { buttonVariant } from "../../../gvds-components/Buttons/GVDSButton";
import React, { useCallback, useEffect, useRef, useState } from "react";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";

const announcementAutoScrollDelayInMilliseconds = 5 * 1000;

const HomepageCTALinkButton = React.forwardRef(
  (
    {
      variant = buttonVariant.primary,
      children,
      href,
      target = "_blank",
      disabled,
      icon,
      className,
      ...props
    },
    ref
  ) => {
    const linkProps =
      href && href !== "#"
        ? {
            href,
            target,
            rel: "noopener noreferrer",
          }
        : {
            href: "#",
            role: "button",
          };

    return (
      <a
        ref={ref}
        {...linkProps}
        className={`gvds-button text-decoration-none ${variant} 
        ${disabled ? "disabled" : ""}
        ${icon ? "with-leading-icon" : ""} ${className ?? ""}`}
        tabIndex={disabled ? -1 : 0}
        aria-disabled={disabled}
        {...props}
      >
        {icon && <span className="gvds-button__icon">{icon}</span>}
        {children}
      </a>
    );
  }
);

export const HomepageAnnouncementDisplay = ({
  title,
  descriptionBodyHtml,
  firstCtaText,
  firstCtaUrl,
  secondCtaText,
  secondCtaUrl,
}) => {
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.innerHTML = descriptionBodyHtml;
    }
  }, [descriptionBodyHtml]);

  return (
    <div className="widget--homepage-announcement__display__container">
      <div className="widget--homepage-announcement__display__heading">
        <div className="widget--homepage-announcement__display__heading-icon">
          <GVDSIcon Icon={IconSpeakerphone} />
        </div>
        <div className="widget--homepage-announcement__display__heading-title">
          {title}
        </div>
      </div>
      <div
        className="widget--homepage-announcement__display__content"
        ref={descriptionRef}
      />
      <div className="widget--homepage-announcement__display__cta-buttons">
        {firstCtaText && (
          <HomepageCTALinkButton
            variant={buttonVariant.primary}
            href={firstCtaUrl}
          >
            {firstCtaText}
          </HomepageCTALinkButton>
        )}
        {secondCtaText && (
          <HomepageCTALinkButton
            variant={buttonVariant.primary}
            href={secondCtaUrl}
          >
            {secondCtaText}
          </HomepageCTALinkButton>
        )}
      </div>
    </div>
  );
};

const NavigationDots = ({ total, current, onDotClick }) => {
  return (
    <div className="widget--homepage-announcement__nav-dots">
      {[...Array(total)].map((_, index) => (
        <button
          key={index}
          className={`widget--homepage-announcement__nav-dots__item ${
            index === current ? "active" : ""
          }`}
          onClick={() => onDotClick(index)}
        />
      ))}
    </div>
  );
};

const HomepageAnnouncementWidget = ({ announcements }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const isMultipleAnnouncements = announcements.length > 1;

  const intervalId = useRef();

  const startAutoScroll = useCallback(() => {
    clearInterval(intervalId.current);
    if (isMultipleAnnouncements) {
      intervalId.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements.length);
      }, announcementAutoScrollDelayInMilliseconds);
    }
  }, [isMultipleAnnouncements, announcements.length]);

  useEffect(() => {
    startAutoScroll();
    return () => clearInterval(intervalId.current);
  }, [startAutoScroll]);

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + announcements.length) % announcements.length
    );
    startAutoScroll();
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % announcements.length);
    startAutoScroll();
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    startAutoScroll();
  };

  return (
    <div className="widget--homepage-announcement__container">
      <HomepageAnnouncementDisplay
        title={announcements[currentIndex].title}
        descriptionBodyHtml={announcements[currentIndex].description}
        firstCtaText={announcements[currentIndex].firstCtaText}
        firstCtaUrl={announcements[currentIndex].firstCtaUrl}
        secondCtaText={announcements[currentIndex].secondCtaText}
        secondCtaUrl={announcements[currentIndex].secondCtaUrl}
      />

      {isMultipleAnnouncements && (
        <div className="widget--homepage-announcement__nav">
          <div className="widget--homepage-announcement__nav__container">
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={handlePrevious}
              icon={<GVDSIconSlim Icon={IconChevronLeft} />}
            />
            <NavigationDots
              total={announcements.length}
              current={currentIndex}
              onDotClick={handleDotClick}
            />
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              onClick={handleNext}
              icon={<GVDSIconSlim Icon={IconChevronRight} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default HomepageAnnouncementWidget;
