import React from "react";
import Table from "react-bootstrap/Table";

const GVDSTableDisplay = ({ className, children }) => {
  return (
    <div className="gvds-table-container">
      <div className="gvds-table-viewport">
        <Table className={className}>{children}</Table>
      </div>
    </div>
  );
};

export default GVDSTableDisplay;
