import React from "react";
import {
  GOALS_AND_TARGETS,
  VIEW_TARGET_DETAILS,
} from "../../config/ROUTES_NAME";
import { Route, Switch } from "react-router-dom";
import withAuthentication from "../HOC/withAuthentication";
import ViewAllGoalsAndTargets from "./ViewAllGoalsAndTargets";
import ViewTargetDetailsBuilder from "./ViewTargetDetailsBuilder";
import { PageWrapper } from "../../gvds-components/Nav/PageWrapper/PageWrapper";

const GoalsAndTargets = () => {
  return (
    <Switch>
      <Route
        exact
        path={GOALS_AND_TARGETS}
        render={() => (
          <PageWrapper configRouteName={GOALS_AND_TARGETS}>
            <ViewAllGoalsAndTargets />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path={VIEW_TARGET_DETAILS}
        render={() => (
          <PageWrapper configRouteName={VIEW_TARGET_DETAILS}>
            <ViewTargetDetailsBuilder />
          </PageWrapper>
        )}
      />
    </Switch>
  );
};

export default withAuthentication(GoalsAndTargets);
