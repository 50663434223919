import axios from "axios";
import { API_URL } from "../config/api-config";
import get from "lodash/get";
import { StringUtils } from "./UtilsService";

export class UserManagedTagDetailModel {
  constructor(
    id,
    name,
    description,
    createdUnder,
    resourceName,
    resourceType,
    resourceId,
    createdBy,
    createdOn,
    updatedBy,
    updatedOn
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.createdUnder = createdUnder;
    this.resourceName = resourceName;
    this.resourceType = resourceType;
    this.resourceId = resourceId;
    this.createdBy = createdBy;
    this.createdOn = createdOn;
    this.updatedBy = updatedBy;
    this.updatedOn = updatedOn;
  }

  static fromDTO(UserManagedTagDTO) {
    return new UserManagedTagDetailModel(
      UserManagedTagDTO["id"],
      UserManagedTagDTO["name"],
      UserManagedTagDTO["description"],
      UserManagedTagDTO["created_under"],
      UserManagedTagDTO["resource_name"],
      UserManagedTagDTO["resource_type"],
      UserManagedTagDTO["resource_id"],
      UserManagedTagDTO["created_by"],
      UserManagedTagDTO["created_on"],
      UserManagedTagDTO["updated_by"],
      UserManagedTagDTO["updated_on"]
    );
  }

  get getCreatedUnderWithResourceTypePrefix() {
    return `(${StringUtils.getTitleCase(this.resourceType)}) ${
      this.createdUnder
    }`;
  }
}

export default class UserManagedTagService {
  static getTags = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/user_managed_tags`, {
        params: {resource_type, resource_id},
      });
      return response.data.map((UserManagedTagDTO) =>
        UserManagedTagDetailModel.fromDTO(UserManagedTagDTO)
      );
    } catch (error) {
      throw error.response;
    }
  };

  static validateTag = async (
    resource_type,
    resource_id,
    old_name,
    new_name
  ) => {
    const payload = {old_name, new_name};

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/user_managed_tags/validate`,
        payload,
        {params: {resource_type, resource_id}}
      );
      return {
        dataErrors: get(response.data, "data_errors", {}),
        possibleErrors: get(response.data, "possible_errors", {}),
      };
    } catch (error) {
      throw error;
    }
  };

  static createTag = async (resource_type, resource_id, name, description) => {
    const payload = { name, description };

    try {
      const response = await axios.post(
        `${API_URL}/api/v1/user_managed_tags`,
        payload,
        { params: { resource_type, resource_id } }
      );
      return UserManagedTagDetailModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static updateTag = async (
    resource_type,
    resource_id,
    tag_id,
    name,
    description
  ) => {
    const payload = { name, description };

    try {
      const response = await axios.put(
        `${API_URL}/api/v1/user_managed_tags/${tag_id}`,
        payload,
        { params: { resource_type, resource_id } }
      );
      return UserManagedTagDetailModel.fromDTO(response.data);
    } catch (error) {
      throw error;
    }
  };

  static deleteTag = async (resource_type, resource_id, tag_id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/v1/user_managed_tags/${tag_id}`,
        { params: { resource_type, resource_id } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getTagResources = async (resource_type, resource_id, tag_id) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/user_managed_tags/${tag_id}/resources`,
        {
          params: { resource_type, resource_id },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  };
}
