import React, { useEffect, useState } from "react";
import LoadingSpinner from "./LoadingSpinner";

const Delayed = ({ children, waitBeforeShow = 50 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? (
    children
  ) : (
    <div className="mt-2">
      <LoadingSpinner />
    </div>
  );
};

export default Delayed;
