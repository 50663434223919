import React, { useContext, useState } from "react";

import ToastContext from "../../../context/ToastContext";
import HelpArticleAdminService from "../../../services/HelpArticleAdminService";
import LoadingSpinner from "../../common/LoadingSpinner";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconTrash } from "@tabler/icons-react";

const DeleteHelpArticlePrompt = ({ article, onDeleteSuccess }) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const cancelDeletePrompt = () => {
    if (!isLoading) {
      setShowDeletePrompt(false);
    }
  };

  const deleteArticle = () => {
    HelpArticleAdminService.deleteArticle(article.id)
      .then(() => {
        toastContext.addSuccessToast(<span>Guide successfully deleted.</span>);
        setIsLoading(false);
        if (onDeleteSuccess) {
          onDeleteSuccess();
        }
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to delete guide.</span>);
        setIsLoading(false);
      });
  };

  return (
    <>
      <GVDSIconButton
        variant={iconButtonVariant.destructive}
        onClick={() => {
          setShowDeletePrompt(true);
        }}
        icon={<GVDSIcon Icon={IconTrash} />}
        tooltipText="Delete"
      />
      {/*  TODO modal is child of parent, so event will propagate up - make sure no click listener in parent */}
      <GVDSModal
        title="Delete Guide"
        size={GVDSModal.Size.small}
        show={showDeletePrompt}
        onHide={cancelDeletePrompt}
      >
        <GVDSModal.Body>
          {isLoading && <LoadingSpinner />}
          {!isLoading && (
            <>
              This will also remove all links with any other articles. Are you
              sure?
            </>
          )}
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancelDeletePrompt}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={deleteArticle}
            disabled={isLoading}
            text="Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default DeleteHelpArticlePrompt;
