export default class SavedFiltersService {
  static SAVED_FILTER__GUIDANCE_LIBRARY = "savedFilter--guidance-library";
  static SAVED_FILTER__POLICY_TRACKER = "savedFilter--policy-tracker"
  static SAVED_FILTER__CERTIFICATION = "savedFilter--certification"

  static SAVED_FILTER_OBJ_KEY__SEARCH_TEXT = "search-text";
  static SAVED_FILTER_OBJ_KEY__FILTER_KEY = "filter-key";

  static saveFilters = (pageKey, filterValue) => {
    sessionStorage.setItem(pageKey, JSON.stringify(filterValue));
  };

  static getSavedFilters = (pageKey) => {
    return JSON.parse(sessionStorage.getItem(pageKey));
  };

  static clearSavedFilters = (pageKey) => {
    sessionStorage.removeItem(pageKey);
  }
}