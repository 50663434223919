import React, { useState } from "react";
import PerformanceYearlyReportModel, {
  PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY,
  PERFORMANCE_REPORT_TYPE,
} from "./PerformanceYearlyReportModel";
import moment from "moment";
import intersection from "lodash/intersection";
import { RESOURCES } from "../../../config/constants";
import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import { UtilsService } from "../../../services/UtilsService";
import sortBy from "lodash/sortBy";
import capitalize from "lodash/capitalize";
import SafeDataService from "../../../services/SafeDataService";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import { getExpandableIcon } from "../../../gvds-components/Table/GVDSTableExpandableRow";
import MarketPurchaseOffsets from "./Table/MarketPurchaseOffsets";
import { getComparisonColumns } from "./Table/PerformanceReportTableUtils";

const PerformanceReportFootnoteLocation = {
  YEARLY_SUBTOTAL: "yearly subtotal",
};

const PerformanceReportTable = ({
  showLocationBased,
  showMarketBased,
  reportMonths,
  report,
  performanceGroupName,
}) => {
  if (
    !report ||
    !report["yearly_reports"] ||
    Object.keys(report["yearly_reports"]).length > 3
  )
    return <div className="performance-report-table__initial-placeholder" />;
  const multiplyFactor = report["show_as_percentage"] ? 100 : 1;
  const reportYears = Object.keys(report["yearly_reports"]);
  const firstYear = new PerformanceYearlyReportModel(
    reportYears[0],
    report["yearly_reports"][reportYears[0]]
  );
  const secondYear = new PerformanceYearlyReportModel(
    reportYears[1],
    report["yearly_reports"][reportYears[1]]
  );

  if (report["report_type"] === PERFORMANCE_REPORT_TYPE.OPERATIONAL_ONLY) {
    return (
      <PerformanceReportOperationalTable
        firstYear={firstYear}
        secondYear={secondYear}
        report={report}
        reportMonths={reportMonths}
      />
    );
  }

  return (
    <PerformanceReportEnvironmentalTable
      firstYear={firstYear}
      secondYear={secondYear}
      report={report}
      reportMonths={reportMonths}
      multiplyFactor={multiplyFactor}
      showLocationBased={showLocationBased}
      showMarketBased={showMarketBased}
      performanceGroupName={performanceGroupName}
    />
  );
};

const PerformanceReportOperationalTable = ({
  firstYear,
  secondYear,
  report,
  reportMonths,
}) => {
  const getQuarterRows = () => {
    let rows = [];
    for (let i = 1; i <= 4; i++) {
      const quarterMonth = moment().startOf("year").quarter(i).month() + 1;
      rows.push(
        <tr
          key={i}
          className={
            "performance-report-table__nested_details_row" +
            (intersection(reportMonths, [
              quarterMonth,
              quarterMonth + 1,
              quarterMonth + 2,
            ]).length === 0
              ? " performance-report-table--not-included"
              : "")
          }
        >
          <td>
            Q{i} {report["quarters_label"][i]}
          </td>
          {getComparisonColumns(
            firstYear.getQuarterlyValue(
              quarterMonth,
              PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY
            ),
            secondYear.getQuarterlyValue(
              quarterMonth,
              PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY
            ),
            report["report_type"],
            report["title"]
          )}
        </tr>
      );
    }
    return rows;
  };
  return (
    <div className="performance-report-table__container">
      <h2>{report["title"]} Performance</h2>
      <GVDSTableDisplay>
        <thead>
          <tr>
            <th>Period</th>
            <th className="performance-report-table__number-cell">
              {firstYear.year}
            </th>
            <th className="performance-report-table__number-cell">
              {secondYear.year}
            </th>
            <th className="performance-report-table__number-cell">
              YOY Change (%)
            </th>
          </tr>
        </thead>
        <tbody>
          {moment.months().map((m, index) => (
            <tr
              className={
                "performance-report-table__month_row" +
                (reportMonths.indexOf(index + 1) < 0
                  ? " performance-report-table--not-included"
                  : "")
              }
              key={m}
            >
              <td>{m}</td>
              {getComparisonColumns(
                firstYear.getMonthlyValue(
                  index + 1,
                  PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY
                ),
                secondYear.getMonthlyValue(
                  index + 1,
                  PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY
                ),
                report["report_type"],
                report["title"]
              )}
            </tr>
          ))}
          <tr className="performance-report-table__subtotal_row">
            <td className="gvds-text--body__bold">
              TOTAL {report["year_label"]}
            </td>
            {getComparisonColumns(
              firstYear.getYearlyValue(PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY),
              secondYear.getYearlyValue(
                PERFORMANCE_REPORT_OPERATIONAL_DATA_KEY
              ),
              report["report_type"],
              report["title"]
            )}
          </tr>
          {getQuarterRows()}
        </tbody>
      </GVDSTableDisplay>
    </div>
  );
};

const PerformanceReportEnvironmentalTable = ({
  firstYear,
  secondYear,
  report,
  reportMonths,
  multiplyFactor,
  showLocationBased,
  showMarketBased,
  performanceGroupName,
}) => {
  const [expandedMonths, setExpandedMonths] = useState([]);

  const toggleExpandMonth = (month) => {
    setExpandedMonths(UtilsService.toggleItem(expandedMonths, month));
  };

  const getMeterBreakdownForMonth = (month) => {
    const meters = uniqBy(
      [...firstYear.getMeterDetails(), ...secondYear.getMeterDetails()],
      "id"
    );

    const hideRow = expandedMonths.indexOf(month) < 0;
    return sortBy(meters, ["facilityName", "name"]).map((m) => (
      <tr
        className={
          "performance-report-table__breakdown_row" +
          (hideRow ? " hide" : "") +
          (reportMonths.indexOf(month) < 0
            ? " performance-report-table--not-included"
            : "")
        }
        key={m.id + month}
      >
        <td>
          {m.name}{" "}
          {report["resource_type"] === RESOURCES.SITE ? (
            <div className="caption fst-italic">{m.facilityName}</div>
          ) : (
            ""
          )}
        </td>
        {sortBy(report["column_groups"], "seq").flatMap((c) =>
          getComparisonColumns(
            SafeDataService.multiply(
              firstYear.getMeterMonthlyValue(
                m.id,
                month,
                c["data_key"],
                report["report_type"]
              ),
              multiplyFactor
            ),
            SafeDataService.multiply(
              secondYear.getMeterMonthlyValue(
                m.id,
                month,
                c["data_key"],
                report["report_type"]
              ),
              multiplyFactor
            )
          )
        )}
      </tr>
    ));
  };

  const getTypeBreakdownForMonth = (month) => {
    const typeNames = uniq([
      ...firstYear.getTypeNames(),
      ...secondYear.getTypeNames(),
    ]);
    const hideRow = expandedMonths.indexOf(month) < 0;
    return typeNames.sort().map((t) => (
      <tr
        className={
          "performance-report-table__breakdown_row" +
          (hideRow ? " hide" : "") +
          (reportMonths.indexOf(month) < 0
            ? " performance-report-table--not-included"
            : "")
        }
        key={t + month}
      >
        <td>{t}</td>
        {sortBy(report["column_groups"], "seq").flatMap((c) =>
          getComparisonColumns(
            SafeDataService.multiply(
              firstYear.getTypeMonthlyValue(
                t,
                month,
                c["data_key"],
                report["report_type"]
              ),
              multiplyFactor
            ),
            SafeDataService.multiply(
              secondYear.getTypeMonthlyValue(
                t,
                month,
                c["data_key"],
                report["report_type"]
              ),
              multiplyFactor
            )
          )
        )}
      </tr>
    ));
  };

  const showPortfolioMeterBreakdown = () =>
    report["resource_type"] === RESOURCES.PORTFOLIO &&
    report["show_meter_breakdown"];

  const getMonthCell = (month, monthName) => {
    if (showPortfolioMeterBreakdown()) {
      return <td>{monthName}</td>;
    }
    const expandIcon = (
      <GVDSIconSlim
        Icon={getExpandableIcon(expandedMonths.indexOf(month) >= 0)}
      />
    );

    return (
      <td className="performance-report-table__month_row__month_cell">
        {expandIcon} {monthName}
      </td>
    );
  };

  const getMonthRows = (monthName, month) => {
    let breakdownRows = [];
    if (!showPortfolioMeterBreakdown()) {
      breakdownRows = report["show_meter_breakdown"]
        ? getMeterBreakdownForMonth(month)
        : getTypeBreakdownForMonth(month);
    }

    const rows = [
      <tr
        className={
          "performance-report-table__month_row is-clickable" +
          (reportMonths.indexOf(month) < 0
            ? " performance-report-table--not-included"
            : "")
        }
        key={month}
        onClick={() => toggleExpandMonth(month)}
      >
        {getMonthCell(month, monthName)}
        {sortBy(report["column_groups"], "seq").flatMap((c) =>
          getComparisonColumns(
            SafeDataService.multiply(
              firstYear.getMonthlyValue(
                month,
                c["data_key"],
                report["report_type"]
              ),
              multiplyFactor
            ),
            SafeDataService.multiply(
              secondYear.getMonthlyValue(
                month,
                c["data_key"],
                report["report_type"]
              ),
              multiplyFactor
            )
          )
        )}
      </tr>,
      ...breakdownRows,
    ];
    return <>{rows}</>;
  };

  const getQuarterRows = () => {
    let rows = [];
    for (let i = 1; i <= 4; i++) {
      const quarterMonth = moment().startOf("year").quarter(i).month() + 1;
      rows.push(
        <tr
          className={
            "performance-report-table__nested_details_row" +
            (intersection(reportMonths, [
              quarterMonth,
              quarterMonth + 1,
              quarterMonth + 2,
            ]).length === 0
              ? " performance-report-table--not-included"
              : "")
          }
          key={i}
        >
          <td>
            Q{i} {report["quarters_label"][i]}
          </td>
          {sortBy(report["column_groups"], "seq").flatMap((c) =>
            getComparisonColumns(
              SafeDataService.multiply(
                firstYear.getQuarterlyValue(
                  quarterMonth,
                  c["data_key"],
                  report["report_type"]
                ),
                multiplyFactor
              ),
              SafeDataService.multiply(
                secondYear.getQuarterlyValue(
                  quarterMonth,
                  c["data_key"],
                  report["report_type"]
                ),
                multiplyFactor
              )
            )
          )}
        </tr>
      );
    }
    return rows;
  };

  const getSuperscript = (superscriptLocation) => {
    const footnote = report["footnotes"].find(
      (f) => f.location === superscriptLocation
    );
    return footnote ? footnote.superscript : "";
  };

  return (
    <div className="performance-report-table__container">
      <div className="performance-report__chart-table-title mb-1">
        {report["title"]}
      </div>
      <GVDSTableDisplay className="performance-report-table__table">
        <thead>
          <tr>
            <th>Period</th>
            {sortBy(report["column_groups"], "seq").map((c, index) => (
              <React.Fragment key={index}>
                <th className="performance-report-table__number-cell">
                  {capitalize(c["data_key"])} ({c["unit_id"]}) -{" "}
                  {firstYear.year}
                </th>
                <th className="performance-report-table__number-cell">
                  {capitalize(c["data_key"])} ({c["unit_id"]}) -{" "}
                  {secondYear.year}
                </th>
                <th className="performance-report-table__number-cell">
                  {capitalize(c["data_key"])} YOY Change (%)
                </th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {moment.months().map((m, index) => getMonthRows(m, index + 1))}
          <tr className="performance-report-table__subtotal_row">
            <td className="gvds-text--body__bold">
              TOTAL
              {getSuperscript(
                PerformanceReportFootnoteLocation.YEARLY_SUBTOTAL
              )}{" "}
              {report["year_label"]}
            </td>
            {sortBy(report["column_groups"], "seq").flatMap((c) =>
              getComparisonColumns(
                SafeDataService.multiply(
                  firstYear.getYearlyValue(
                    c["data_key"],
                    report["report_type"]
                  ),
                  multiplyFactor
                ),
                SafeDataService.multiply(
                  secondYear.getYearlyValue(
                    c["data_key"],
                    report["report_type"]
                  ),
                  multiplyFactor
                )
              )
            )}
          </tr>
          {getQuarterRows()}
          {showMarketBased && (
            <MarketPurchaseOffsets
              columnGroups={report["column_groups"]}
              firstYear={firstYear}
              secondYear={secondYear}
              performanceGroupName={performanceGroupName}
            />
          )}
        </tbody>
      </GVDSTableDisplay>
      {report["footnotes"] &&
        report["footnotes"].length > 0 &&
        report["footnotes"].map((f, index) => (
          <div className="gvds-text--caption mt-2" key={index}>
            {f.superscript} {f.message}
          </div>
        ))}
    </div>
  );
};

export default PerformanceReportTable;
