import {
  OTHERS_LEGEND_LABEL,
  SummarySiteRankingChartDataModel,
} from "../../Models/DashboardModels";
import { SORTING_TYPES } from "../../../../gvds-components/Table/GVDSTable";

export class SummarySiteRankingChartUtils {
  static getCalculatedChartData = (chartData, displayedKeys) => {
    const singleValueKeys = displayedKeys.filter(
      (key) => key !== OTHERS_LEGEND_LABEL
    );

    return chartData.map((datum) => {
      const updatedValues = {};
      let othersValue = null;

      Object.entries(datum.values).forEach(([key, value]) => {
        if (singleValueKeys.includes(key)) {
          updatedValues[key] = value;
        } else {
          if (othersValue === null) {
            othersValue = 0;
          }
          othersValue += value;
        }
      });

      if (othersValue !== null) {
        updatedValues[OTHERS_LEGEND_LABEL] = othersValue;
      }

      return new SummarySiteRankingChartDataModel(
        datum.siteName,
        updatedValues
      );
    });
  };

  static sortBarChartValues = (chartData, sortOrder) => {
    return chartData.sort((firstData, secondData) => {
      const firstDataValue = firstData.siteTotalValue;
      const secondDataValue = secondData.siteTotalValue;
      const diff = firstData.siteTotalValue - secondData.siteTotalValue;

      if (firstDataValue !== null && secondDataValue !== null) {
        return sortOrder === SORTING_TYPES.desc ? -1 * diff : diff;
      } else if (firstDataValue === null && secondDataValue === null) {
        return firstData.siteName.localeCompare(secondData.siteName);
      } else if (firstDataValue === null) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  static getXAxisScaleMaxValue = (chartData) => {
    let maxValue = 0;

    chartData.forEach((datum) => {
      if (datum.siteTotalValue > maxValue) {
        maxValue = datum.siteTotalValue;
      }
    });

    return maxValue;
  };

  static getSVGPathData = (
    xPosition,
    yPosition,
    barWidth,
    barHeight,
    radius,
    radiusSpace
  ) => {
    if (radius > 0) {
      return `
    M ${xPosition} ${yPosition}
    H ${barWidth + xPosition - radiusSpace}
    A ${radius} ${radius} 0 0 1 ${barWidth + xPosition} ${radius + yPosition}
    V ${barHeight + yPosition - radius}
    A ${radius} ${radius} 0 0 1 ${barWidth + xPosition - radiusSpace} ${
        barHeight + yPosition
      }
    H ${xPosition}
    Z
  `;
    } else {
      return `
    M ${xPosition} ${yPosition}
    H ${barWidth + xPosition}
    V ${barHeight + yPosition}
    H ${xPosition}
    Z
  `;
    }
  };
}
