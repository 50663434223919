import axios from "axios";
import { API_URL } from "../config/api-config";

export default class BenchmarkService {
  static getAllBenchmarkData = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/benchmark/benchmark_files"
      );
      return response.data.map((d) => ({
        ...d,
        filepath: API_URL + d.filepath,
      }));
    } catch (error) {
      throw error;
    }
  };

  static getBenchmarkReference = async (reference_id) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/benchmark/benchmark_files/" + reference_id
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static updateBenchmarkData = async (id, fileModel) => {
    try {
      const formData = new FormData();
      formData.append("file", fileModel.file);
      formData.append("filename", fileModel.file.name);

      const response = await axios.put(
        API_URL + "/api/v1/benchmark/benchmark_files/" + id,
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteBenchmarkReference = async (id) => {
    try {
      const response = await axios.delete(
        API_URL + "/api/v1/benchmark/benchmark_files/" + id
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  static saveBenchmarkData = async (year, fileModel) => {
    try {
      const postBody = new FormData();
      postBody.append("file", fileModel.file);
      postBody.append("filename", fileModel.file.name);
      postBody.append("year", year);

      const response = await axios.post(
        `${API_URL}/api/v1/benchmark/benchmark_files`,
        postBody
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
