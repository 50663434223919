import React from "react";
import GVDSIcon from "../Icons/GVDSIcon";
import {
  IconAlertCircleFilled,
  IconAlertTriangleFilled,
  IconChevronRight,
  IconCircleCheckFilled,
} from "@tabler/icons-react";
import Button from "react-bootstrap/Button";
import GVDSIconSlim from "../Icons/GVDSIconSlim";

const AlertVariants = {
  success: "success",
  warning: "warning",
  error: "error",
  informational: "informational",
};

const ICON_BY_VARIANT = {
  [AlertVariants.success]: IconCircleCheckFilled,
  [AlertVariants.warning]: IconAlertTriangleFilled,
  [AlertVariants.error]: IconAlertCircleFilled,
  [AlertVariants.informational]: IconAlertCircleFilled,
};

const BannerFooterContainer = ({ children }) => {
  return <div className="banner-content-footer--container">{children}</div>;
};

const BannerFooterButton = React.forwardRef(
  (
    {
      text = "",
      disabled,
      icon = <GVDSIconSlim Icon={IconChevronRight} />,
      onClick,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        variant="link"
        className={`gvds-banner-footer-button ${className ?? ""}`}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        <>
          {text}
          {icon}
        </>
      </Button>
    );
  }
);

const GVDSBanner = ({
  icon = null,
  title = null,
  variant,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={`gvds-banner gvds-banner-${variant} ${className ?? ""}`}
      {...props}
    >
      <div className="banner-icon__container">
        <GVDSIcon Icon={icon || ICON_BY_VARIANT[variant]} />
      </div>

      <div className="banner-content">
        {title ? <div className="banner-heading">{title}</div> : null}
        {children}
      </div>
    </div>
  );
};

GVDSBanner.Variants = AlertVariants;
GVDSBanner.Footer = BannerFooterContainer;
GVDSBanner.FooterButton = BannerFooterButton;

export default GVDSBanner;
