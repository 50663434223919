import PageHeader from "../../../gvds-components/Layout/PageHeader";
import CertificationCriterion from "../Homepage/CertificationCriterion";
import Container from "react-bootstrap/Container";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Route,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  CERTIFICATION_CONTENT_PAGE_REQUIREMENT,
  getViewCertificationHomepagePath,
  getViewCertificationRequirementPath,
} from "../../../config/ROUTES_NAME";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { CertificationService } from "../../../services/CertificationService";
import ToastContext from "../../../context/ToastContext";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JoinedBullet from "../../common/JoinedBullet";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCheck } from "@tabler/icons-react";
import CertificationContext from "../CertificationContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import { CertificationOffcanvasProvider } from "./CertificationOffcanvas/CertificationOffcanvasContext";
import CertificationOffcanvas from "./CertificationOffcanvas/CertificationOffcanvas";
import CertificationRequirementDisplay from "./CertificationRequirementDisplay";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";

const CertificationRequirementSelector = ({
  certificationId,
  criterionId,
  requirement,
  selectedRequirementId,
  popoverContent,
}) => {
  const history = useHistory();

  const [isShowOverlay, setIsShowOverlay] = useState(false);
  const popoverRef = useRef(null);

  /* Use overlay rather than overlay trigger because:
   - the trigger is using display: contents which cannot be used as target of popover
   - react bootstrap overlay trigger doesn't support custom target
   */
  return (
    <>
      <Overlay
        show={isShowOverlay}
        placement="right"
        target={popoverRef.current}
      >
        <Popover>
          <Popover.Body>{popoverContent}</Popover.Body>
        </Popover>
      </Overlay>

      <div
        className={`certification-content-page__content__requirement${
          requirement.id === selectedRequirementId ? " selected" : ""
        }${!requirement.isApplicable ? " not-applicable" : ""}`}
        onClick={() =>
          history.push(
            getViewCertificationRequirementPath(
              certificationId,
              criterionId,
              requirement.id
            )
          )
        }
        onMouseEnter={() => setIsShowOverlay(true)}
        onMouseLeave={() => setIsShowOverlay(false)}
      >
        <div className="requirement-number">{requirement.number}</div>
        <div className="requirement-name">{requirement.name}</div>
        <div className="requirement-check__container" ref={popoverRef}>
          {requirement.isApplicable && requirement.isCompleted ? (
            <GVDSIcon Icon={IconCheck} />
          ) : null}
        </div>
      </div>
    </>
  );
};

const CertificationContentPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { certificationId, criterionId } = useParams();

  const routeMatch = useRouteMatch(CERTIFICATION_CONTENT_PAGE_REQUIREMENT);
  const requirementId = routeMatch?.params["requirementId"];

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const certificationContext = useContext(CertificationContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const currentInventory = userInventory.selectedInventory.get;

    if (
      currentInventory &&
      criterionId &&
      !!certificationContext.certification
    ) {
      if (!certificationContext.certification.isCriterionLoaded(criterionId)) {
        setIsLoading(true);

        CertificationService.getCertificationContentPage(
          currentInventory.type,
          currentInventory.id,
          certificationId,
          criterionId
        )
          .then((response) => {
            certificationContext.certification.updateCriterionWithRequirements(
              criterionId,
              response
            );

            certificationContext.refreshCertification();
            selectFirstRequirement();
          })
          .catch((error) => {
            if (error.response.status !== 404) {
              toastContext.addFailToast(
                <span>Failed to load content page.</span>
              );
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        selectFirstRequirement();
        setIsLoading(false);
      }
    }
  }, [certificationContext.isLoading, criterionId]);

  const selectFirstRequirement = () => {
    const requirements =
      certificationContext.certification?.getSelectedCriterion(
        criterionId
      ).requirements;
    if (requirements.length > 0) {
      history.push(
        getViewCertificationRequirementPath(
          certificationId,
          criterionId,
          requirements[0].id
        )
      );
    }
  };

  const gotoCertificationHomepage = () => {
    history.push(
      getRedirectURLWithCurrentParam(
        getViewCertificationHomepagePath(certificationContext.certificationId),
        location
      )
    );
  };

  let content;
  const selectedCriterion =
    certificationContext.certification?.getSelectedCriterion(criterionId);

  if (isLoading) {
    content = (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else if (!selectedCriterion) {
    content = (
      <div className="table__no_content">
        Criteria not found. Try accessing a different criteria.
      </div>
    );
  } else {
    content = (
      <>
        <PageHeader>
          <PageHeader.BackButton
            text={`Back to all ${certificationContext.certification.name} criteria`}
            onClick={gotoCertificationHomepage}
          />
        </PageHeader>
        <CertificationCriterion
          categoryName={selectedCriterion.categoryName}
          criterion={selectedCriterion}
        />
        {selectedCriterion.instruction && (
          <div className="certification-content-page__instruction-banner">
            <GVDSBanner variant={GVDSBanner.Variants.informational}>
              <div className="gvds-text--caption">
                {selectedCriterion.instruction}
              </div>
            </GVDSBanner>
          </div>
        )}
        <div className="certification-content-page__content__container">
          <Row>
            <Col lg={3} md={12}>
              <div className="certification-content-page__content__requirement__container">
                <div className="certification-content-page__content__requirement__title">
                  <div className="gvds-text--heading4">Requirements</div>
                  <JoinedBullet />
                  <div className="gvds-text--label">{`${selectedCriterion.totalApplicableRequirementCount} applicable`}</div>
                </div>
                <div className="certification-content-page__content__requirement__grid-container">
                  {selectedCriterion.requirements.map((requirement, index) => (
                    <CertificationRequirementSelector
                      key={index}
                      certificationId={certificationId}
                      criterionId={criterionId}
                      requirement={requirement}
                      selectedRequirementId={requirementId}
                      popoverContent={`${requirement.number} ${requirement.name}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col lg={9} md={12}>
              <Route
                exact
                path={CERTIFICATION_CONTENT_PAGE_REQUIREMENT}
                render={() => <CertificationRequirementDisplay />}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }

  return (
    <Container fluid>
      {content}
      <CertificationOffcanvas />
    </Container>
  );
};

const CertificationContentPageWithContext = () => {
  return (
    <CertificationOffcanvasProvider>
      <CertificationContentPage />
    </CertificationOffcanvasProvider>
  );
};

export default CertificationContentPageWithContext;
