import React, { useContext, useState } from "react";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import SelectCurrency from "../../Currency/SelectCurrency";
import MultiYearCalendar from "../../common/MultiYearCalendar";
import PerformanceReportParamsModel, {
  PerformanceReportStatus,
} from "./PerformanceReportConfigModel";
import MultiMonthCalendar from "../../common/MultiMonthCalendar";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import { components } from "react-select";
import GVFormGroup from "../../common/GVFormGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import FeatureLockMessage from "../../common/Feature/FeatureLockMessage";
import {
  getSubscriptionFeatureStatus,
  SUBSCRIPTION_FEATURE_STATUS,
} from "../../common/Feature/FeatureCheckPageWrapper";
import { FEATURES } from "../../../config/constants";
import UserInventoryContext from "../../../context/UserInventoryContext";
import UserProfileContext from "../../../context/UserProfileContext";
import EnvironmentalSubtopicService from "../../../services/EnvironmentalSubtopicService";
import { EmissionReportingMethodBlurb } from "../ReportLegend";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSButtonWithLoadingAction from "../../../gvds-components/Buttons/GVDSButtonWithLoadingAction";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import Container from "react-bootstrap/Container";
import { IconCheck, IconScale, IconX } from "@tabler/icons-react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import NoSubscriptionMessage from "../../common/Feature/NoSubscriptionMesage";
import Spacer from "../../../gvds-components/Layout/Spacer";
import PerformanceReportFormatService from "./PerformanceReportFormatService";

export const PERFORMANCE_REPORT_OPERATIONAL_SUBTOPIC = "Operational";
export const PERFORMANCE_REPORT_OPERATIONAL_METRICS = {
  TOTAL_OCCUPIED_ROOMS: "Total Occupied Rooms",
  TOTAL_AVAILABLE_ROOMS: "Total Available Rooms",
  AVERAGE_FTES: "Average Monthly FTEs",
  AVERAGE_WORKERS_ON_MAIN_SHIFT: "Average Workers on Main Shift",
  TOTAL_NO_OF_GUEST: "Total # of Guests",
  TOTAL_NO_OF_OVERNIGHT_GUESTS: "Total # of Overnight Guests",
  TOTAL_FOOD_COVERS: "Total Food Covers",
  TOTAL_GUEST_NIGHTS: "Total Guest Nights",
  TOTAL_MEETING_ATTENDEES: "Total Meeting Attendees",
  GROSS_BUILT_FLOOR_AREA: "Gross Built Floor Area",
  GROSS_CONDITIONED_FLOOR_AREA: "Gross Conditioned Floor Area",
};
export const PERFORMANCE_REPORT_OPERATIONAL_PER_LABEL = {
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_OCCUPIED_ROOMS]:
    "Per Occupied Room",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_AVAILABLE_ROOMS]:
    "Per Available Room",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.AVERAGE_FTES]: "Per Average FTE",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.AVERAGE_WORKERS_ON_MAIN_SHIFT]:
    "Per Average Worker on Main Shift",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_NO_OF_GUEST]: "Per Guest",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_NO_OF_OVERNIGHT_GUESTS]:
    "Per Overnight Guest",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_FOOD_COVERS]: "Per Food Cover",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_GUEST_NIGHTS]:
    "Per Guest Night",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.TOTAL_MEETING_ATTENDEES]:
    "Per Meeting Attendee",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_BUILT_FLOOR_AREA]:
    "Per sqm/sqft (GFA)",
  [PERFORMANCE_REPORT_OPERATIONAL_METRICS.GROSS_CONDITIONED_FLOOR_AREA]:
    "Per sqm/sqft (Conditioned Space)",
};

const PERFORMANCE_REPORT_VIEW_STATUS = {
  [PerformanceReportStatus.AVAILABLE]: <GVDSIcon Icon={IconCheck} />,
  [PerformanceReportStatus.UNAVAILABLE]: <GVDSIcon Icon={IconX} />,
  [PerformanceReportStatus.INSUFFICIENT_CHSB_DATA]: <GVDSIcon Icon={IconX} />,
};

const getReportParamsWithLocationBasedEmissionsByMarketPurchaseAndCustomEF = (
  newReportParams,
  reportConfig
) => {
  const isMarketBasedEmissionsOptionDisabled =
    PerformanceReportParamsModel.disableMarketBasedEmissionsOption(
      newReportParams,
      reportConfig
    );

  if (
    newReportParams["use_location_based"] === false &&
    isMarketBasedEmissionsOptionDisabled
  ) {
    newReportParams["use_location_based"] = true;
  } else if (
    newReportParams["use_location_based"] === true &&
    !isMarketBasedEmissionsOptionDisabled &&
    (PerformanceReportParamsModel.isCustomEFAvailable(
      newReportParams,
      reportConfig
    ) ||
      PerformanceReportParamsModel.isMarketPurchaseOffsetRecordAvailable(
        newReportParams,
        reportConfig
      ))
  ) {
    newReportParams["use_location_based"] = false;
  }

  return newReportParams;
};

const PerformanceReportSelectOption = (props) => {
  return (
    <components.Option {...props}>
      <span className="align-baseline">
        {props.data.label}
        {props.data.showBenchmark && (
          <GVDSIcon
            className="d-inline ms-1 gvds-color--success"
            Icon={IconScale}
          />
        )}
      </span>
    </components.Option>
  );
};

const PerformanceReportSelected = (props) => {
  return (
    <components.SingleValue {...props}>
      <span className="align-baseline">
        {props.data.label}
        {props.data.showBenchmark && (
          <GVDSIcon
            className="d-inline ms-1 gvds-color--success"
            Icon={IconScale}
          />
        )}
      </span>
    </components.SingleValue>
  );
};

const PerformanceReportOperationalSubtopicConfig = ({
  reportConfig,
  reportParams,
  updateParams,
}) => {
  const getOperationalMetrics = () => {
    return reportConfig["operational_metrics"] || [];
  };

  return (
    <>
      <Row>
        <Col>
          <GVFormGroup>
            <Form.Label>Report On</Form.Label>
            <Form.Check
              id="performance-report-on-group"
              label="Performance Grouping"
              type="radio"
              disabled
            />
            <Form.Check
              id="performance-report-on-type"
              label="Single Type"
              type="radio"
              readOnly
              checked={true}
            />
          </GVFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <GVFormGroup className="performance-report-config__nested-dropdowns__container">
            <GVDSFormSingleSelect
              name="operational_metrics"
              placeholder="Select operational metric"
              value={
                reportParams["operational_metric"]
                  ? {
                      value: reportParams["operational_metric"],
                      label: reportParams["operational_metric"],
                    }
                  : null
              }
              options={getOperationalMetrics().map((metric) => {
                return { value: metric, label: metric };
              })}
              onSelect={(selectedOption) =>
                updateParams({
                  ...reportParams,
                  operational_metric: selectedOption.value,
                })
              }
            />
          </GVFormGroup>
        </Col>
      </Row>
    </>
  );
};

const EnvironmentalUnitDropdown = ({
  reportParams,
  reportConfig,
  unitMap,
  disableStatus,
  updateParams,
}) => {
  return (
    <GVDSFormSingleSelect
      className="units"
      placeholder={
        disableStatus.unit &&
        (PerformanceReportParamsModel.showWasteDiversionRate(
          reportParams,
          reportConfig
        ) ||
          PerformanceReportParamsModel.showRenewableRateGroups(
            reportParams,
            reportConfig
          ))
          ? "%"
          : "Select unit"
      }
      value={
        reportParams["unit_id"] && !disableStatus.unit
          ? {
              value: reportParams["unit_id"],
              label: unitMap[reportParams["unit_id"]],
            }
          : null
      }
      options={Object.entries(unitMap).map(([id, name]) => {
        return {
          value: id,
          label: name,
        };
      })}
      onSelect={(newSelect) =>
        updateParams({
          ...reportParams,
          unit_id: newSelect.value,
        })
      }
      disabled={disableStatus.unit}
    />
  );
};

const PerformanceGroupDropdowns = ({
  reportParams,
  reportConfig,
  performanceGroupMap,
  disableStatus,
  unitMap,
  updateParams,
}) => {
  return (
    <div className="performance-report-config__nested-dropdowns__container">
      <GVDSFormSingleSelect
        className="performance-groups"
        placeholder="Select performance group"
        components={{
          Option: PerformanceReportSelectOption,
          SingleValue: PerformanceReportSelected,
        }}
        value={
          reportParams["performance_group_id"]
            ? {
                value: reportParams["performance_group_id"],
                label:
                  performanceGroupMap[reportParams["performance_group_id"]],
                showBenchmark:
                  reportConfig["benchmark_performance_groups"].indexOf(
                    performanceGroupMap[reportParams["performance_group_id"]]
                  ) >= 0,
              }
            : null
        }
        options={Object.entries(performanceGroupMap).map(([id, name]) => {
          return {
            value: id,
            label: name,
            showBenchmark:
              reportConfig["benchmark_performance_groups"].indexOf(name) >= 0,
          };
        })}
        onSelect={(newSelect) => {
          const updatedReportParams = {
            ...reportParams,
            performance_group_id: newSelect.value,
            performance_group_name: performanceGroupMap[newSelect.value],
            use_location_based:
              PerformanceReportParamsModel.getDefaultUseLocationBasedForPerformanceGroup(
                newSelect.value,
                reportParams,
                reportConfig
              ),
          };
          const reportParamsWithLocationBasedEmissions =
            getReportParamsWithLocationBasedEmissionsByMarketPurchaseAndCustomEF(
              updatedReportParams,
              reportConfig
            );

          updateParams(reportParamsWithLocationBasedEmissions);
        }}
      />
      <EnvironmentalUnitDropdown
        reportParams={reportParams}
        reportConfig={reportConfig}
        unitMap={unitMap}
        disableStatus={disableStatus}
        updateParams={updateParams}
      />
    </div>
  );
};

const SingleTypeDropdowns = ({
  reportParams,
  reportConfig,
  disableStatus,
  environmentalTypeMap,
  unitMap,
  updateParams,
}) => {
  return (
    <div className="performance-report-config__nested-dropdowns__container">
      <GVDSFormSingleSelect
        className="environmental-types"
        placeholder="Select environmental type"
        value={
          reportParams["environmental_type_id"]
            ? {
                value: reportParams["environmental_type_id"],
                label:
                  environmentalTypeMap[reportParams["environmental_type_id"]],
              }
            : null
        }
        options={Object.entries(environmentalTypeMap).map(([id, name]) => {
          return {
            value: id,
            label: name,
          };
        })}
        onSelect={(newSelect) => {
          const updatedReportParams = {
            ...reportParams,
            environmental_type_id: newSelect.value,
            use_location_based:
              PerformanceReportParamsModel.getDefaultUseLocationBasedForEnvironmentalType(
                newSelect.value,
                reportParams,
                reportConfig
              ),
          };

          const reportParamsWithLocationBasedEmissions =
            getReportParamsWithLocationBasedEmissionsByMarketPurchaseAndCustomEF(
              updatedReportParams,
              reportConfig
            );

          updateParams(reportParamsWithLocationBasedEmissions);
        }}
      />
      <EnvironmentalUnitDropdown
        reportParams={reportParams}
        reportConfig={reportConfig}
        unitMap={unitMap}
        disableStatus={disableStatus}
        updateParams={updateParams}
      />
    </div>
  );
};

const PerformanceReportEnvironmentalSubtopicConfig = ({
  reportConfig,
  reportParams,
  updateParams,
}) => {
  const disableStatus = PerformanceReportParamsModel.getInputDisableStatus(
    reportParams,
    reportConfig
  );
  const performanceGroupMap =
    PerformanceReportParamsModel.getPerformanceGroupMap(
      reportParams,
      reportConfig
    );
  const unitMap = PerformanceReportParamsModel.getUnitMap(
    reportParams,
    reportConfig
  );
  const environmentalTypeMap =
    PerformanceReportParamsModel.getEnvironmentalTypeMap(
      reportParams,
      reportConfig
    );

  return (
    <>
      <Row>
        <Col>
          <GVFormGroup>
            <Form.Label>Report On</Form.Label>
            <Form.Check
              id="performance-report-on-group"
              label="Performance Grouping"
              type="radio"
              checked={reportParams["use_performance_group"]}
              onChange={() => {
                updateParams({
                  ...reportParams,
                  use_performance_group: true,
                  environmental_type_id: "",
                  environmental_type_name: "",
                });
              }}
            />
            {reportParams["use_performance_group"] && (
              <PerformanceGroupDropdowns
                reportParams={reportParams}
                reportConfig={reportConfig}
                performanceGroupMap={performanceGroupMap}
                disableStatus={disableStatus}
                unitMap={unitMap}
                updateParams={updateParams}
              />
            )}
            <Form.Check
              id="performance-report-on-type"
              label="Single Type"
              type="radio"
              checked={!reportParams["use_performance_group"]}
              onChange={() => {
                updateParams({
                  ...reportParams,
                  use_performance_group: false,
                  performance_group_id: "",
                  performance_group_name: "",
                });
              }}
            />
            {!reportParams["use_performance_group"] && (
              <SingleTypeDropdowns
                reportParams={reportParams}
                reportConfig={reportConfig}
                environmentalTypeMap={environmentalTypeMap}
                disableStatus={disableStatus}
                unitMap={unitMap}
                updateParams={updateParams}
              />
            )}
          </GVFormGroup>
        </Col>
      </Row>

      {PerformanceReportParamsModel.showMarketPurchaseOption(
        reportParams,
        reportConfig
      ) && (
        <Row>
          <Col>
            <GVFormGroup>
              <div>
                <Form.Label>
                  Emissions Reporting Method
                  <InfoTooltip info={<EmissionReportingMethodBlurb />} />
                </Form.Label>
              </div>

              <div className="gvds-text--body mb-2">
                Select location-based emissions [most common case] unless your
                site has market purchase or custom emission factors applied.
              </div>

              <div>
                <Form.Check
                  id="performance-report-for-location-based"
                  className="me-1"
                  label="Location-based Emissions"
                  type="radio"
                  checked={reportParams["use_location_based"]}
                  onChange={() => {
                    updateParams({
                      ...reportParams,
                      use_location_based: true,
                    });
                  }}
                />
              </div>
              <div>
                <Form.Check
                  id="performance-report-for-market-based"
                  className="me-1"
                  label={
                    PerformanceReportParamsModel.disableMarketBasedEmissionsOption(
                      reportParams,
                      reportConfig
                    ) ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="market-purchase-offset-disabled">
                            Market-based emissions is not applicable for your
                            selected performance group/single type.
                          </Tooltip>
                        }
                      >
                        <span>Market-based Emissions</span>
                      </OverlayTrigger>
                    ) : (
                      <span>Market-based Emissions</span>
                    )
                  }
                  type="radio"
                  checked={!reportParams["use_location_based"]}
                  disabled={PerformanceReportParamsModel.disableMarketBasedEmissionsOption(
                    reportParams,
                    reportConfig
                  )}
                  onChange={() => {
                    updateParams({
                      ...reportParams,
                      use_location_based: false,
                    });
                  }}
                />
              </div>
            </GVFormGroup>
          </Col>
        </Row>
      )}
    </>
  );
};

const PerformanceReportSubtopicConfig = ({
  reportConfig,
  reportParams,
  updateParams,
}) => {
  const getSubtopics = () => {
    let subtopics = [];
    if (reportConfig["environmental_subtopics"]) {
      subtopics = subtopics.concat(
        reportConfig["environmental_subtopics"]
          .map((s) => s.name)
          .sort(EnvironmentalSubtopicService.subtopicsSortFn)
      );
    }

    if (
      reportConfig["operational_metrics"] &&
      reportConfig["operational_metrics"].length > 0
    ) {
      subtopics.push(PERFORMANCE_REPORT_OPERATIONAL_SUBTOPIC);
    }
    return subtopics;
  };

  return (
    <div className="report-config-options">
      <Row>
        <Col>
          <GVFormGroup className="guided-tour-target__performance-report__subtopic-selector">
            <Form.Label>Subtopic</Form.Label>
            <GVDSFormSingleSelect
              className="select__subtopic"
              placeholder="Select subtopic"
              components={{
                Option: PerformanceReportSelectOption,
                SingleValue: PerformanceReportSelected,
              }}
              value={
                reportParams["subtopic_name"]
                  ? {
                      value: reportParams["subtopic_name"],
                      label: reportParams["subtopic_name"],
                      showBenchmark:
                        reportConfig["benchmark_subtopic"].indexOf(
                          reportParams["subtopic_name"]
                        ) >= 0,
                    }
                  : null
              }
              options={getSubtopics().map((option) => {
                return {
                  value: option,
                  label: option,
                  showBenchmark:
                    reportConfig["benchmark_subtopic"].indexOf(option) >= 0,
                };
              })}
              onSelect={(newSelect) =>
                updateParams({
                  ...reportParams,
                  subtopic_name: newSelect.value,
                  unit_id:
                    reportConfig["default_units_by_subtopic"][newSelect.value],
                  environmental_type_id: "",
                  performance_group_id: "",
                  performance_group_name: "",
                  operational_metric: "",
                  use_location_based: null,
                })
              }
            />
          </GVFormGroup>
        </Col>
      </Row>
      <div className="guided-tour-target__performance-report__data-type-selector">
        {reportParams["subtopic_name"] ===
        PERFORMANCE_REPORT_OPERATIONAL_SUBTOPIC ? (
          <PerformanceReportOperationalSubtopicConfig
            reportConfig={reportConfig}
            reportParams={reportParams}
            updateParams={updateParams}
          />
        ) : (
          <PerformanceReportEnvironmentalSubtopicConfig
            reportConfig={reportConfig}
            reportParams={reportParams}
            updateParams={updateParams}
          />
        )}
      </div>
    </div>
  );
};

const PerformanceReportCurrencyOperationConfig = ({
  reportParams,
  reportConfig,
  updateParams,
}) => {
  const disableStatus = PerformanceReportParamsModel.getInputDisableStatus(
    reportParams,
    reportConfig
  );
  const operationMetrics =
    PerformanceReportParamsModel.getOperationMetrics(reportConfig);
  const operationUnitMap = PerformanceReportParamsModel.getOperationUnitMap(
    reportParams,
    reportConfig
  );
  return (
    <div className="report-config-options">
      <Row>
        <Col>
          <GVFormGroup>
            <SelectCurrency
              selected={
                !disableStatus.currency ? reportParams["currency_id"] : null
              }
              disabled={disableStatus.currency}
              onCurrencySelected={(id) =>
                updateParams({
                  ...reportParams,
                  currency_id: id,
                })
              }
              placeholder={disableStatus.currency ? "-" : "Select currency"}
            />
          </GVFormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <GVFormGroup className="guided-tour-target__performance-report__metric-selector">
            <Form.Label>
              Operational Metric{" "}
              <span className="optional-form-label">(Optional)</span>
            </Form.Label>
            <Row>
              <Col>
                <GVDSFormSingleSelect
                  placeholder={
                    disableStatus.operationalMetric
                      ? "-"
                      : "Select operational metric"
                  }
                  className="select__operational-metric"
                  isClearable={true}
                  components={{
                    Option: PerformanceReportSelectOption,
                    SingleValue: PerformanceReportSelected,
                  }}
                  value={
                    reportParams["operational_metric"] &&
                    reportParams["subtopic_name"] !==
                      PERFORMANCE_REPORT_OPERATIONAL_SUBTOPIC &&
                    !disableStatus.operationalMetric
                      ? {
                          value: reportParams["operational_metric"],
                          label:
                            PERFORMANCE_REPORT_OPERATIONAL_PER_LABEL[
                              reportParams["operational_metric"]
                            ],
                          showBenchmark:
                            reportConfig["benchmark_operation_metrics"].indexOf(
                              reportParams["operational_metric"]
                            ) >= 0,
                        }
                      : null
                  }
                  options={_.sortBy(
                    operationMetrics.map((m) => {
                      return {
                        value: m,
                        label: PERFORMANCE_REPORT_OPERATIONAL_PER_LABEL[m],
                        showBenchmark:
                          reportConfig["benchmark_operation_metrics"].indexOf(
                            m
                          ) >= 0,
                      };
                    }),
                    "label"
                  )}
                  onSelect={(newSelect) =>
                    updateParams({
                      ...reportParams,
                      operational_metric: newSelect?.value || "",
                      operational_unit_id:
                        PerformanceReportParamsModel.isAreaMetric(
                          newSelect?.value,
                          reportConfig
                        )
                          ? reportConfig["default_area_unit_id"]
                          : null,
                    })
                  }
                  disabled={disableStatus.operationalMetric}
                />
              </Col>
              {!_.isEmpty(operationUnitMap) && (
                <Col sm={4}>
                  <GVDSFormSingleSelect
                    placeholder="Select unit"
                    value={
                      reportParams["operational_unit_id"] &&
                      !disableStatus.operationalMetricUnit
                        ? {
                            value: reportParams["operational_unit_id"],
                            label:
                              operationUnitMap[
                                reportParams["operational_unit_id"]
                              ],
                          }
                        : null
                    }
                    options={Object.entries(operationUnitMap).map(
                      ([id, name]) => {
                        return {
                          value: id,
                          label: name,
                        };
                      }
                    )}
                    onSelect={(newSelect) =>
                      updateParams({
                        ...reportParams,
                        operational_unit_id: newSelect.value,
                      })
                    }
                    disabled={disableStatus.operationalMetricUnit}
                  />
                </Col>
              )}
            </Row>
          </GVFormGroup>
        </Col>
      </Row>
    </div>
  );
};

const PerformanceReportTimeConfig = ({
  reportParams,
  reportConfig,
  updateParams,
}) => {
  const [showYear, setShowYear] = useState(true);
  const allowedMonths = _.range(1, 13);

  const updateParamsWithLocationBasedEmissionsDefault = (
    updatedReportParams
  ) => {
    const reportParamsWithLocationBasedEmissions =
      getReportParamsWithLocationBasedEmissionsByMarketPurchaseAndCustomEF(
        updatedReportParams,
        reportConfig
      );

    updateParams(reportParamsWithLocationBasedEmissions);
  };

  const updateYears = (years) => {
    updateParamsWithLocationBasedEmissionsDefault({
      ...reportParams,
      years: years,
    });
  };

  const updateMonths = (months) => {
    updateParamsWithLocationBasedEmissionsDefault({
      ...reportParams,
      months: months,
    });
  };

  return (
    <div className="report-config-options guided-tour-target__performance-report__period-selector">
      <div>
        <Row>
          <Col>
            <Form.Label>
              Report Period{" "}
              <InfoTooltip info="Most common case: only select the Years you want. For more advanced customisation, you may also select the Month(s). Example: If you select the years 2018 and 2019 and the months February and September, then you will only see comparisons and totals for those two months across 2018 and 2019." />
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup className="year-month-toggle-button-group">
              <GVDSButton
                variant={buttonVariant.tertiary}
                className={
                  showYear
                    ? "gvds-button-group--selected"
                    : "gvds-button-group--not-selected"
                }
                onClick={() => setShowYear(true)}
                text="Year"
              />
              <GVDSButton
                variant={buttonVariant.tertiary}
                className={
                  showYear
                    ? "gvds-button-group--not-selected"
                    : "gvds-button-group--selected"
                }
                onClick={() => setShowYear(false)}
                text="Month"
              />
            </ButtonGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            {showYear ? (
              <MultiYearCalendar
                maxYear={new Date().getFullYear()}
                selectedYears={reportParams.years}
                setSelectedYears={updateYears}
              />
            ) : (
              <MultiMonthCalendar
                allowedMonths={allowedMonths}
                selectedMonths={reportParams.months}
                setSelectedMonths={updateMonths}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="year-month-selected-details">
              <div>Year: {reportParams.years.join(", ")}</div>
              <div>
                Month:{" "}
                {PerformanceReportParamsModel.getMonthLabel(
                  reportParams.months
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const BenchmarkChecklistItem = ({ title, isCheck, showIcon = true }) => {
  return (
    <div className="performance-report-config__benchmark-checklist-item">
      <span className="align-baseline">
        {`${title} `}
        <GVDSIcon
          className={`d-inline ms-1 gvds-color--success${
            showIcon ? "" : " visibility-hidden"
          }`}
          Icon={IconScale}
        />
      </span>
      <Spacer />
      <span className="performance-report-config__benchmark-checkmark-icon">
        <GVDSIcon
          className={`gvds-color--success${
            isCheck ? "" : " visibility-hidden"
          }`}
          Icon={IconCheck}
        />
      </span>
    </div>
  );
};

const PerformanceReportPreview = ({
  reportParams,
  reportConfig,
  benchmarkAvailable,
  benchmark,
}) => {
  const userInventory = useContext(UserInventoryContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.getUserProfile();
  const subscriptionFeatureStatusForPerformanceBenchmark =
    getSubscriptionFeatureStatus(
      [FEATURES.REPORT__PERFORMANCE_BENCHMARK],
      userInventory.isLoadingInventory.get,
      userInventory.selectedTreeNode,
      userProfile
    );
  const tableStatus = PerformanceReportParamsModel.getTableStatus(
    reportParams,
    reportConfig
  );
  const chartStatus = PerformanceReportParamsModel.getChartStatus(
    reportParams,
    reportConfig
  );
  const benchmarkStatus = !benchmarkAvailable
    ? PerformanceReportStatus.UNAVAILABLE
    : PerformanceReportParamsModel.getBenchmarkReportStatus(
        reportParams,
        reportConfig,
        benchmark
      );
  const benchmarkChecklist = PerformanceReportParamsModel.getBenchmarkChecklist(
    reportParams,
    reportConfig
  );

  const getBenchmarkView = (
    subscriptionFeatureStatusForPerformanceBenchmark
  ) => {
    let content;
    let caption = null;

    if (
      subscriptionFeatureStatusForPerformanceBenchmark ===
      SUBSCRIPTION_FEATURE_STATUS.NO_SUBSCRIPTION
    ) {
      content = (
        <div className="me-3">
          <NoSubscriptionMessage />
        </div>
      );
    } else if (
      subscriptionFeatureStatusForPerformanceBenchmark ===
      SUBSCRIPTION_FEATURE_STATUS.HAS_SUBSCRIPTION_NO_ACCESS
    ) {
      content = (
        <div className="me-3">
          <FeatureLockMessage />
        </div>
      );
    } else if (!benchmarkAvailable) {
      content = (
        <div className="performance-report-config__benchmark-view__caption">
          Only available for Hotel/Accommodation Sites.
        </div>
      );
    } else {
      caption = (
        <div className="performance-report-config__benchmark-view__caption">
          {benchmarkStatus === PerformanceReportStatus.INSUFFICIENT_CHSB_DATA
            ? "Insufficient CHSB data"
            : "Unavailable unless the criteria below are met"}
        </div>
      );
      content = (
        <>
          <ul className="performance-report-config__benchmark-checklist-item__container">
            <li>
              <BenchmarkChecklistItem
                title="Select Subtopic with"
                isCheck={benchmarkChecklist.subtopic}
              />
            </li>
            <li>
              <BenchmarkChecklistItem
                title="Select Performance Grouping with"
                isCheck={benchmarkChecklist.performance_group}
              />
            </li>
            <li>
              <BenchmarkChecklistItem
                title="Select Operational Metric with"
                isCheck={benchmarkChecklist.metric}
              />
            </li>
            <li>
              <BenchmarkChecklistItem
                title="Current year is not selected"
                isCheck={benchmarkChecklist.year}
                showIcon={false}
              />
            </li>
          </ul>
        </>
      );
    }

    return (
      <div className="performance-report-config__benchmark-view">
        <div className="form-label-row guided-tour-target__performance-report__benchmark-availability align-items-start">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <div className="performance-report-config__benchmark-view__title">
                CHSB Benchmark
              </div>
              <InfoTooltip info="The Cornell Hotel Sustainability Benchmarking Index (CHSB) is an industry-led global data collection and benchmarking initiative, with data on energy, water and carbon emissions from hotels around the world. Participants in the CHSB index include major hotel brands, operators and owners, representing all regions of the world." />
            </div>
            {caption}
          </div>
          <Spacer />
          <div
            className={`performance-report-config__status-icon ${PerformanceReportFormatService.getStatusCssClass(
              benchmarkStatus
            )}`}
          >
            <div>{PERFORMANCE_REPORT_VIEW_STATUS[benchmarkStatus]}</div>
          </div>
        </div>
        {content}
      </div>
    );
  };

  return (
    <div className="report-config-options guided-tour-target__performance-report__report-availability">
      <Container fluid className="px-1">
        <div className="performance-report-config__preview-content">
          <div className="performance-report-config__table-view">
            <div className="performance-report-config__table-view__title">
              Table
              <Spacer />
              <div
                className={`performance-report-config__status-icon ${PerformanceReportFormatService.getStatusCssClass(
                  tableStatus
                )}`}
              >
                <div>{PERFORMANCE_REPORT_VIEW_STATUS[tableStatus]}</div>
              </div>
            </div>
            {tableStatus === PerformanceReportStatus.UNAVAILABLE && (
              <div className="performance-report-config__table-view__caption">
                Table view unavailable when more than 2 years are selected. You
                can still download the report to view it in Excel.
              </div>
            )}
          </div>
          <div className="performance-report-config__chart-view">
            <div className="performance-report-config__chart-view__title">
              Chart
              <Spacer />
              <div
                className={`performance-report-config__status-icon ${PerformanceReportFormatService.getStatusCssClass(
                  chartStatus
                )}`}
              >
                <div>{PERFORMANCE_REPORT_VIEW_STATUS[chartStatus]}</div>
              </div>
            </div>
            {PerformanceReportParamsModel.showRenewableRateGroups(
              reportParams,
              reportConfig
            ) && (
              <div className="performance-report-config__chart-view__caption">
                No monthly breakdown values for your selected performance
                grouping.
              </div>
            )}
            {reportParams["years"].length > 4 && (
              <div className="performance-report-config__chart-view__caption">
                Select fewer than 5 years to view the chart.
              </div>
            )}
          </div>
          {getBenchmarkView(subscriptionFeatureStatusForPerformanceBenchmark)}
        </div>
      </Container>
    </div>
  );
};

const PerformanceReportConfig = ({
  reportParams,
  setReportParams,
  reportConfig,
  downloadReport,
  runReport,
  benchmarkAvailable,
  benchmark,
  issueDataRequest,
}) => {
  const isDownloadReportButtonDisabled =
    PerformanceReportParamsModel.disableDownloadReport(
      reportParams,
      reportConfig
    );
  const isRunReportButtonDisabled =
    PerformanceReportParamsModel.disableRunReport(reportParams, reportConfig);

  return (
    <div className="report-config performance-report-config">
      <div className="performance-report-config__input-with-preview-container">
        <div>
          <div className="performance-report-config__title">
            Report settings
          </div>
          <div className="performance-report-config__config-container">
            <div>
              <PerformanceReportTimeConfig
                reportParams={reportParams}
                reportConfig={reportConfig}
                updateParams={setReportParams}
              />
            </div>
            <div className="performance-report-config__config-options-container">
              <PerformanceReportSubtopicConfig
                reportParams={reportParams}
                reportConfig={reportConfig}
                updateParams={setReportParams}
              />
              <PerformanceReportCurrencyOperationConfig
                reportParams={reportParams}
                reportConfig={reportConfig}
                updateParams={setReportParams}
              />
            </div>
          </div>
        </div>
        <div className="performance-report-config__preview__container">
          <div className="performance-report-config__title">Report view</div>
          <PerformanceReportPreview
            reportParams={reportParams}
            reportConfig={reportConfig}
            benchmarkAvailable={benchmarkAvailable}
            benchmark={benchmark}
          />
        </div>
      </div>
      <div className="performance-report-config__footer">
        {PerformanceReportParamsModel.isSelectedMonthsEmpty(reportParams) && (
          <div className="report-config-footer__error-msg">
            No months selected
          </div>
        )}
        <GVDSButton
          className="guided-tour-target__performance-report__data-request"
          variant={buttonVariant.tertiary}
          disabled={isDownloadReportButtonDisabled}
          onClick={() => issueDataRequest()}
          text="Issue data request"
        />
        <Spacer />
        <div className="guided-tour-target__performance-report__generate-report">
          <GVDSButtonWithLoadingAction
            className="ms-2"
            variant={buttonVariant.secondary}
            text="Download Excel"
            disabled={isDownloadReportButtonDisabled}
            onClickAsyncFunc={downloadReport}
          />
          <GVDSButtonWithLoadingAction
            className="ms-2"
            text="Run Report"
            disabled={isRunReportButtonDisabled}
            onClickAsyncFunc={runReport}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceReportConfig;
