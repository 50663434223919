import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "./GVDSFormShared";

const GVDSFormSingleYearPicker = React.forwardRef(
  (
    {
      placeholder = "Select a year",
      className = "",
      selected,
      onChange,
      dateFormat = "yyyy",
      disabled = false,
      onCalendarOpen,
      onCalendarClose,
      isFocusStyle = false,
      isUseIcon = true,
      statusMetadata = FormFieldStatusMetadata.getDefault(),
      maxYear,
      minYear,
      openToYear,
      isPortalling = true,
      ...props
    },
    ref
  ) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const selectedDate = selected ? new Date(selected, 0, 1) : null;
    const maxDate = maxYear ? new Date(maxYear, 0, 1) : null;
    const minDate = minYear ? new Date(minYear, 0, 1) : null;
    const openToDate = openToYear ? new Date(openToYear, 0, 1) : null;

    const gvdsClassNames = ["gvds-form__single-yearpicker-input"];
    switch (statusMetadata.state) {
      case FormFieldState.warning:
        gvdsClassNames.push("gvds-form__warning-border");
        if (isUseIcon) {
          gvdsClassNames.push("gvds-form__warning-icon");
        }
        break;
      case FormFieldState.error:
        gvdsClassNames.push("gvds-form__error-border");
        if (isUseIcon) {
          gvdsClassNames.push("gvds-form__error-icon");
        }
        break;
      default:
        break;
    }
    if ((isCalendarOpen || isFocusStyle) && !disabled) {
      gvdsClassNames.push("on-focus");
    }

    const combinedClassNames = `${gvdsClassNames.join(" ")} ${className || ""}`;

    const handleCalendarOpen = () => {
      setIsCalendarOpen(true);
      if (onCalendarOpen) {
        onCalendarOpen();
      }
    };

    const handleCalendarClose = () => {
      setIsCalendarOpen(false);
      if (onCalendarClose) {
        onCalendarClose();
      }
    };

    return (
      <>
        <DatePicker
          ref={ref}
          placeholderText={placeholder}
          selected={selectedDate}
          onChange={(date) => onChange(date?.getFullYear())}
          dateFormat={dateFormat}
          disabled={disabled}
          className={combinedClassNames}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          maxDate={maxDate}
          minDate={minDate}
          openToDate={openToDate}
          showYearPicker
          portalId={isPortalling ? "portalling-target" : null}
          {...props}
        />
        {statusMetadata.isNotNormal() && statusMetadata.message && (
          <GVDSFormErrorMessage
            status={statusMetadata.state}
            errorMsg={statusMetadata.message}
          />
        )}
      </>
    );
  }
);

export default GVDSFormSingleYearPicker;
