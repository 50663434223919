import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import uniq from "lodash/uniq";
import { uniqueId } from "lodash";

import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import withAuthentication from "../HOC/withAuthentication";
import UserInventoryContext from "../../context/UserInventoryContext";
import BestPracticeAssessmentService from "../../services/BestPracticeAssessmentService";
import {
  BEST_PRACTICE_ADOPTION_LEVEL,
  BEST_PRACTICE_QUESTION_TYPE,
  PERMISSIONS,
  QUESTION_ANSWER_STATUS,
  RESOURCES,
} from "../../config/constants";
import LoadingSpinner from "../common/LoadingSpinner";
import ToastContext from "../../context/ToastContext";
import { UtilsService } from "../../services/UtilsService";
import BestPracticeCategoryService from "../../services/ReferenceDataServices/BestPracticeCategoryService";
import { GuidanceArticleUtils } from "./GuidanceArticles/GuidanceArticleUtils";
import { usePrevious } from "../common/ReactHook";
import GuidanceArticleService from "../../services/GuidanceArticleService";
import { getRedirectURLWithCurrentParam } from "../common/QueryHandler";
import GVFormGroup from "../common/GVFormGroup";
import PermissionsContext from "../../context/PermissionsContext";
import { REPORT_BEST_PRACTICE_REPORT } from "../../config/ROUTES_NAME";
import PopoverOnTruncated from "../common/Tooltip/PopoverOnTruncated";
import BestPracticeAdoptionChart, {
  getAdoptionLevelColor,
} from "./BestPracticeAdoptionChart";
import { BEST_PRACTICE_ADOPTION_LEVEL_INFO } from "../SystemToolbox/BestPractices/BestPracticeModel";
import { bestPracticeAssessQuestionSearchKeys } from "../../config/search-config";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSFormTextArea from "../../gvds-components/Forms/GVDSFormTextArea";
import PageHeader from "../../gvds-components/Layout/PageHeader";
import FilterSearchBox from "../../gvds-components/common/FilterSearchBox";
import GVDSTag from "../../gvds-components/common/GVDSTag";
import GVDSTableCtrlContainer from "../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTableCtrlMultiSelect from "../../gvds-components/Table/Controls/GVDSTableCtrlMultiSelect";
import useGVDSTableCtrl from "../../gvds-components/Table/GVDSTableHook";
import Spacer from "../../gvds-components/Layout/Spacer";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../gvds-components/Icons/GVDSIcon";
import { IconEdit } from "@tabler/icons-react";
import GVDSLink from "../../gvds-components/Buttons/GVDSLink";
import {
  ANSWERING_SAVE_STATUS,
  getSaveStatus,
} from "../common/AnswerSaveStatus";
import { Trans, useTranslation } from "react-i18next";

const BestPracticesAssess = ({ guidanceArticleId }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const saveTimer = useRef(null);
  const savePromise = useRef(null);
  const [answerBeforeSaving, setAnswerBeforeSaving] = useState(null);

  const isLinkedFromGuidanceArticle = !!guidanceArticleId;

  const {
    filteredSortedData,
    totalDataLength,
    filterKeys,
    setFilterKeys,
    searchText,
    setSearchText,
  } = useGVDSTableCtrl(questions, bestPracticeAssessQuestionSearchKeys);

  useEffect(() => {
    setIsLoading(true);
    if (
      selectedInventory &&
      (!previousInventory || selectedInventory.id !== previousInventory.id)
    ) {
      if (
        selectedInventory.type === RESOURCES.SITE ||
        selectedInventory.type === RESOURCES.PORTFOLIO
      ) {
        if (isLinkedFromGuidanceArticle) {
          GuidanceArticleService.getLinkedQuestionsForArticle(
            selectedInventory.id,
            selectedInventory.type,
            guidanceArticleId
          )
            .then((questions) => {
              setQuestions(questions);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
              toastContext.addFailToast(
                <span>
                  Failed to load relevant best practice questions for the
                  article.
                </span>
              );
            });
        } else {
          BestPracticeAssessmentService.getBestPracticeQuestions(
            selectedInventory.type,
            selectedInventory.id
          )
            .then((questions) => {
              setQuestions(questions);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
              toastContext.addFailToast(
                <span>Failed to load best practice questions.</span>
              );
            });
        }
      } else {
        // temporary feature block for facility
        setIsLoading(false);
      }
    }
  }, [selectedInventory]);

  const updateQuestion = () => {
    setQuestions([...questions]);
  };

  const debounceSavingAnswer = (
    oldAnswer,
    newAnswer,
    question,
    siteId,
    debounceWait = 1000,
    errorMessage = (
      <span>
        We were unable to save your response to this Best Practice. Please try
        again.
      </span>
    )
  ) => {
    clearTimeout(saveTimer.current);
    question.saveStatus = ANSWERING_SAVE_STATUS.NOT_SAVED;
    updateQuestion();

    saveTimer.current = setTimeout(async () => {
      question.saveStatus = ANSWERING_SAVE_STATUS.SAVING;
      updateQuestion();

      if (savePromise.current) {
        await savePromise.current;
      }

      if (!answerBeforeSaving) {
        setAnswerBeforeSaving(oldAnswer);
      }

      const promise = saveAnswer(newAnswer, question, siteId);
      savePromise.current = promise;

      promise
        .then(() => {
          savePromise.current = null;
          setAnswerBeforeSaving(null);
          question.saveStatus = ANSWERING_SAVE_STATUS.SAVED;
          updateQuestion();
        })
        .catch(() => {
          savePromise.current = null;
          question.saveStatus = ANSWERING_SAVE_STATUS.FAILED;
          revertAnswer(oldAnswer, question);
          updateQuestion();

          toastContext.addFailToast(errorMessage);
        });
    }, debounceWait);
  };

  const saveAnswer = async (selectedAnswers, question, siteId) => {
    return BestPracticeAssessmentService.updateAnswer(
      siteId,
      question.questionId,
      selectedAnswers.map((answer) => answer.id)
    );
  };

  const revertAnswer = (answersBeforeSave, question) => {
    question.answers = answersBeforeSave;
  };

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else if (selectedInventory.type === RESOURCES.FACILITY) {
    // temporary feature block for facility
    return (
      <div>
        The Best Practices feature is not available at the Facility level.
        Please navigate to the Portfolio or Site level to view it.
      </div>
    );
  }

  const categoryProgresses = questions.reduce(
    (progressByCategory, question) => {
      question.categories.forEach((category) => {
        const categoryName = category.name;
        if (!progressByCategory[categoryName]) {
          progressByCategory[categoryName] = {
            answered: question.isAnswered ? 1 : 0,
            total: 1,
          };
        } else {
          progressByCategory[categoryName].answered += question.isAnswered
            ? 1
            : 0;
          progressByCategory[categoryName].total += 1;
        }
      });
      return progressByCategory;
    },
    {}
  );

  return (
    <div>
      <PageHeader>
        <PageHeader.Title>
          <h1>{t("best-practices.page-title")}</h1>
        </PageHeader.Title>
        <PageHeader.Description>
          {selectedInventory.type === RESOURCES.PORTFOLIO ? (
            isLinkedFromGuidanceArticle ? (
              <Trans i18nKey="best-practices.page-description-portfolio-in-linked-guidance-article">
                Greenview Portal’s best practice questions have been collected
                by experts to guide companies towards
                sustainability and conservation. To understand best practices
                for sites in your portfolio, run a{" "}
                <Link
                  to={getRedirectURLWithCurrentParam(
                    REPORT_BEST_PRACTICE_REPORT,
                    location
                  )}
                >
                  best practices report
                </Link>
                .<br />
                Best practice questions can only be answered at the site level.
              </Trans>
            ) : (
              <Trans i18nKey="best-practices.page-description-portfolio">
                The full list of Greenview Portal’s best practice questions
                collected by experts to guide companies towards environmental
                sustainability and conservation. To understand best practices
                for sites in your portfolio, run a{" "}
                <Link
                  to={getRedirectURLWithCurrentParam(
                    REPORT_BEST_PRACTICE_REPORT,
                    location
                  )}
                >
                  best practices report
                </Link>
                .<br />
                Best practice questions can only be answered at the site level.
              </Trans>
            )
          ) : (
            <>{t("best-practices.page-description-site")}</>
          )}
        </PageHeader.Description>
      </PageHeader>
      {!isLinkedFromGuidanceArticle && (
        <div className="guided-tour-target__best-practice__categories">
          <h3>Category</h3>
          <div className="best-practice-category-progress-container">
            {selectedInventory.type === RESOURCES.PORTFOLIO &&
              Object.keys(categoryProgresses)
                .sort(BestPracticeCategoryService.bestPracticeSortFn)
                .map((categoryName) => {
                  return (
                    <CategoryNoProgressDisplay
                      key={categoryName}
                      isSelected={
                        filterKeys.categoryNames &&
                        filterKeys.categoryNames.indexOf(categoryName) >= 0
                      }
                      categoryName={categoryName}
                      onClick={(categoryName) =>
                        setFilterKeys({
                          ...filterKeys,
                          categoryNames: UtilsService.toggleItem(
                            filterKeys.categoryNames || [],
                            categoryName
                          ),
                        })
                      }
                    />
                  );
                })}
            {selectedInventory.type === RESOURCES.SITE &&
              Object.keys(categoryProgresses)
                .sort(BestPracticeCategoryService.bestPracticeSortFn)
                .map((categoryName) => {
                  const categoryProgress = categoryProgresses[categoryName];
                  return (
                    <CategoryProgressDisplay
                      key={categoryName}
                      isSelected={
                        filterKeys.categoryNames &&
                        filterKeys.categoryNames.indexOf(categoryName) >= 0
                      }
                      categoryName={categoryName}
                      answered={categoryProgress.answered}
                      total={categoryProgress.total}
                      onClick={(categoryName) =>
                        setFilterKeys({
                          ...filterKeys,
                          categoryNames: UtilsService.toggleItem(
                            filterKeys.categoryNames || [],
                            categoryName
                          ),
                        })
                      }
                    />
                  );
                })}
          </div>
        </div>
      )}

      {questions.length > 0 ? (
        <GVDSTableCtrlContainer>
          <FilterSearchBox
            className="question-search-box"
            placeholder="Search Best Practice Question"
            value={searchText}
            onInput={setSearchText}
          />
          <GVDSTableCtrlMultiSelect
            options={uniq(
              questions
                .map((q) => q.tags)
                .flat()
                .map((tag) => tag.name)
                .sort()
            )}
            prefix="Tags"
            onChange={(filterKs) =>
              setFilterKeys({ ...filterKeys, tagNames: filterKs })
            }
          />
          {selectedInventory.type === RESOURCES.SITE && (
            <>
              <GVDSTableCtrlMultiSelect
                options={[
                  QUESTION_ANSWER_STATUS.YES,
                  QUESTION_ANSWER_STATUS.NO,
                ]}
                prefix="Answered"
                defaultSelected={
                  filterKeys.isAnswered
                    ? filterKeys.isAnswered.map((isAnswered) =>
                        isAnswered
                          ? QUESTION_ANSWER_STATUS.YES
                          : QUESTION_ANSWER_STATUS.NO
                      )
                    : []
                }
                onChange={(filterKs) => {
                  const convertedFilter = filterKs.map(
                    (str) => str === QUESTION_ANSWER_STATUS.YES
                  );
                  setFilterKeys({
                    ...filterKeys,
                    isAnswered: convertedFilter,
                  });
                }}
              />
              <GVDSTableCtrlMultiSelect
                options={[
                  BEST_PRACTICE_ADOPTION_LEVEL.YES,
                  BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS,
                  BEST_PRACTICE_ADOPTION_LEVEL.NO,
                  BEST_PRACTICE_ADOPTION_LEVEL.NA,
                ]}
                prefix="Adoption Level"
                defaultSelected={filterKeys.adoptionLevel}
                onChange={(filterKs) =>
                  setFilterKeys({ ...filterKeys, adoptionLevel: filterKs })
                }
              />
            </>
          )}
        </GVDSTableCtrlContainer>
      ) : (
        <div className="table__no_content mt-2">
          {isLinkedFromGuidanceArticle
            ? "No Relevant Best Practice."
            : "No Best Practice available."}
        </div>
      )}

      {totalDataLength === 0 && (
        <div className="table__no_content mt-2">
          No best practice questions found within the specified scope. Try
          adjusting your filter or use another keyword.
        </div>
      )}
      {!isLinkedFromGuidanceArticle &&
        selectedInventory.type === RESOURCES.SITE &&
        filteredSortedData.length > 0 && (
          <BestPracticeAdoptionChart
            filteredQuestions={filteredSortedData}
            onAdoptionLevelClick={(adoptionLevel, isAnswered) => {
              const isAnsweredConvertedFilter = isAnswered.map(
                (str) => str === QUESTION_ANSWER_STATUS.YES
              );

              setFilterKeys({
                ...filterKeys,
                adoptionLevel: adoptionLevel,
                isAnswered: isAnsweredConvertedFilter,
              });
            }}
          />
        )}

      <div>
        {filteredSortedData.map((question, displayIndex) => {
          const index = questions.indexOf(question);
          if (selectedInventory.type === RESOURCES.SITE) {
            return (
              <SiteBestPracticeQuestionDisplay
                key={question.questionId}
                siteId={selectedInventory.id}
                question={question}
                index={index}
                onQuestionUpdated={() => updateQuestion(question)}
                guidanceArticleId={guidanceArticleId}
                debounceSavingAnswer={debounceSavingAnswer}
                isGuidedTourTarget={displayIndex === 0}
              />
            );
          } else if (selectedInventory.type === RESOURCES.PORTFOLIO) {
            return (
              <PortfolioBestPracticeQuestionDisplay
                key={question.questionId}
                question={question}
                index={index}
                guidanceArticleId={guidanceArticleId}
                isGuidedTourTarget={displayIndex === 0}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export const SiteBestPracticeQuestionDisplay = ({
  siteId,
  question,
  index,
  onQuestionUpdated,
  guidanceArticleId,
  debounceSavingAnswer,
  isGuidedTourTarget = false,
}) => {
  const location = useLocation();
  const permissionCtx = useContext(PermissionsContext);
  const rowNumber = index + 1;

  const isDisableAnswer =
    !permissionCtx.isLoadingPermissions &&
    !permissionCtx.permissions[PERMISSIONS.BEST_PRACTICE_ANSWER];

  const onOptionSelected = (option) => {
    const currentAnswers = question.answers;
    let selectedAnswers;
    if (question.questionType === BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE) {
      if (
        currentAnswers &&
        currentAnswers.length > 0 &&
        currentAnswers[0].id === option.id
      ) {
        // unselect currently selected option
        selectedAnswers = [];
      } else {
        selectedAnswers = [option];
      }
    } else if (
      question.questionType === BEST_PRACTICE_QUESTION_TYPE.CHECKBOXES
    ) {
      // toggle answers
      selectedAnswers = question.answers
        ? UtilsService.toggleItem(question.answers, option)
        : [option];
    }
    question.answers = selectedAnswers;
    onQuestionUpdated();
    debounceSavingAnswer(currentAnswers, selectedAnswers, question, siteId);
  };

  const updateComment = async (comment) => {
    return BestPracticeAssessmentService.updateComment(
      siteId,
      question.questionId,
      comment
    );
  };

  const getLinkToSingleArticle = (article) => {
    return getRedirectURLWithCurrentParam(
      GuidanceArticleUtils.getArticleUrl(article.id),
      location
    );
  };

  return (
    <div
      className={`best-practice-question-display ${
        question.isAnswered ? "answered" : ""
      }`}
    >
      <BestPracticeQuestionDetailDisplay
        rowNumber={rowNumber}
        question={question}
        onOptionSelected={onOptionSelected}
        isDisableAnswer={isDisableAnswer}
        isSupportComment={true}
        updateComment={updateComment}
        isGuidedTourTarget={isGuidedTourTarget}
      />
      <div className="d-flex flex-column align-items-end">
        <BestPracticeQuestionAdoptionLevelDisplay
          question={question}
          isGuidedTourTarget={isGuidedTourTarget}
        />
        {question.linkedGuidanceArticles &&
          question.linkedGuidanceArticles.length > 0 && (
            <GuidanceArticleLink
              guidanceArticleId={guidanceArticleId}
              linkedGuidanceArticles={question.linkedGuidanceArticles}
              getLinkToSingleArticle={getLinkToSingleArticle}
            />
          )}
      </div>
    </div>
  );
};

export const PortfolioBestPracticeQuestionDisplay = ({
  question,
  index,
  guidanceArticleId,
  isGuidedTourTarget = false,
}) => {
  const location = useLocation();
  const rowNumber = index + 1;

  const getLinkToSingleArticle = (article) => {
    return getRedirectURLWithCurrentParam(
      GuidanceArticleUtils.getArticleUrl(article.id),
      location
    );
  };

  return (
    <div
      className={`best-practice-question-display ${
        isGuidedTourTarget ? "guided-tour-target__best-practice__question" : ""
      }`}
    >
      <BestPracticeQuestionDetailDisplay
        rowNumber={rowNumber}
        question={question}
        isDisableAnswer={true}
        isSupportComment={false}
      />
      {question.linkedGuidanceArticles &&
        question.linkedGuidanceArticles.length > 0 && (
          <div className="d-flex flex-column align-items-end">
            <GuidanceArticleLink
              guidanceArticleId={guidanceArticleId}
              linkedGuidanceArticles={question.linkedGuidanceArticles}
              getLinkToSingleArticle={getLinkToSingleArticle}
            />
          </div>
        )}
    </div>
  );
};

const BestPracticeQuestionDetailDisplay = ({
  rowNumber,
  question,
  onOptionSelected,
  isDisableAnswer = true,
  isSupportComment = false,
  updateComment,
  isGuidedTourTarget,
}) => {
  return (
    <>
      <div className="best-practice-question-text me-3">{rowNumber}.</div>
      <div className="flex-grow-1">
        <div
          className={`best-practice-question-text mb-1 ${
            isGuidedTourTarget
              ? "guided-tour-target__best-practice__question"
              : ""
          }`}
        >
          {question.questionText}
        </div>
        {question.description && (
          <div className="mb-1">{question.description}</div>
        )}
        <div className="mb-1">
          {question.options.map((option) => {
            return (
              <div
                className="d-flex align-items-center mb-2"
                key={`option-${option.id}`}
              >
                <Form.Check
                  label={option.option_text}
                  type={
                    question.questionType ===
                    BEST_PRACTICE_QUESTION_TYPE.MULTIPLE_CHOICE
                      ? "radio"
                      : "checkbox"
                  }
                  id={`option-${option.id}`}
                  onClick={() => {
                    if (onOptionSelected) {
                      onOptionSelected(option);
                    }
                  }}
                  readOnly
                  checked={
                    (question.answers &&
                      question.answers.filter(
                        (answer) => answer.id === option.id
                      ).length > 0) ||
                    false
                  }
                  disabled={isDisableAnswer}
                />
              </div>
            );
          })}
        </div>
        {isSupportComment && (
          <BestPracticeQuestionCommentDisplay
            rowNumber={rowNumber}
            question={question}
            isDisableAnswer={isDisableAnswer}
            updateComment={updateComment}
          />
        )}
        <div className="d-flex">
          {question.tags.map((tag) => (
            <GVDSTag key={tag.id}>{tag.name}</GVDSTag>
          ))}
        </div>
        {getSaveStatus(question.saveStatus)}
      </div>
    </>
  );
};

const BestPracticeQuestionCommentDisplay = ({
  rowNumber,
  question,
  isDisableAnswer,
  updateComment,
}) => {
  const toastContext = useContext(ToastContext);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [isUpdatingComment, setIsUpdatingComment] = useState(false);
  const [comment, setComment] = useState(
    question.comment ? question.comment : ""
  );

  const hasNoComment = !question.comment;

  const onCommentSaved = () => {
    setIsUpdatingComment(true);
    if (!updateComment) {
      toastContext.addFailToast("Failed to update comment");
      return;
    }

    updateComment(comment)
      .then(() => {
        toastContext.addSuccessToast(
          `Updated comment for question ${rowNumber}`
        );
        question.comment = comment;
        setIsUpdatingComment(false);
        setShowCommentModal(false);
      })
      .catch(() => toastContext.addFailToast("Failed to update comment"));
  };

  return (
    <>
      <div className="best-practice-question-comment__container">
        {hasNoComment ? (
          !isDisableAnswer && (
            <GVDSButton
              variant={buttonVariant.secondary}
              onClick={() => setShowCommentModal(true)}
              text="Add comment"
            />
          )
        ) : (
          <>
            <div className="me-1">
              <strong>Comment:</strong>
            </div>
            <PopoverOnTruncated
              content={question.comment}
              contentClassName="best-practice-question-comment"
            />
            {!isDisableAnswer && (
              <GVDSIconButton
                variant={iconButtonVariant.tertiary}
                onClick={() => {
                  setShowCommentModal(true);
                }}
                icon={<GVDSIcon Icon={IconEdit} />}
                tooltipText="Edit"
                className="edit-comment-button"
              />
            )}
          </>
        )}
      </div>
      <GVDSModal
        title={`${hasNoComment ? "Add" : "Edit"} comment`}
        size={GVDSModal.Size.small}
        show={showCommentModal}
        onHide={() => setShowCommentModal(false)}
      >
        <GVDSModal.Body>
          <GVFormGroup controlId="comment">
            <Form.Label>Comment</Form.Label>
            <div className="form-label-description">
              Write a comment to give additional context to your answers if
              needed.
            </div>
            <GVDSFormTextArea
              value={comment}
              onInput={(value) => {
                setComment(value);
              }}
              disabled={isUpdatingComment}
            />
          </GVFormGroup>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={() => setShowCommentModal(false)}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={onCommentSaved}
            disabled={
              isUpdatingComment ||
              (question.comment ? comment === question.comment : comment === "")
            }
            text="Save"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

const BestPracticeQuestionAdoptionLevelDisplay = ({
  question,
  isGuidedTourTarget = false,
}) => {
  let content;
  const adoptionLevel = question.adoptionLevel;

  switch (adoptionLevel) {
    case BEST_PRACTICE_ADOPTION_LEVEL.YES:
    case BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS:
    case BEST_PRACTICE_ADOPTION_LEVEL.NO:
    case BEST_PRACTICE_ADOPTION_LEVEL.NA:
      content = (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={uniqueId("adoption-label")}>
              {BEST_PRACTICE_ADOPTION_LEVEL_INFO[adoptionLevel]?.definition}
            </Tooltip>
          }
        >
          <div
            className={`best-practice-question__adoption-lvl ${getAdoptionLevelColor(
              adoptionLevel
            )}`}
          >
            <div>{BEST_PRACTICE_ADOPTION_LEVEL_INFO[adoptionLevel]?.icon}</div>
          </div>
        </OverlayTrigger>
      );
      break;
    case BEST_PRACTICE_ADOPTION_LEVEL.NO_RESPONSE:
      content = (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={uniqueId("adoption-label")}>
              Answer the question to view your site’s adoption level for this
              best practice.
            </Tooltip>
          }
        >
          <div className="best-practice-question__adoption-lvl empty" />
        </OverlayTrigger>
      );
      break;
    default:
      content = (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={uniqueId("adoption-label")}>
              The adoption level for this best practice is yet to be determined.
            </Tooltip>
          }
        >
          <div className="info-field gray-label">UNAVAILABLE</div>
        </OverlayTrigger>
      );
  }

  return (
    <div
      className={`${
        isGuidedTourTarget
          ? "guided-tour-target__best-practice__question-adoption-level"
          : ""
      }`}
    >
      {content}
    </div>
  );
};

const GuidanceArticleLink = ({
  guidanceArticleId,
  linkedGuidanceArticles,
  getLinkToSingleArticle,
}) => {
  return (
    <div className="best-practice-question-relevant-articles-container">
      <div className="gvds-text--body__bold">Relevant Guides</div>
      {linkedGuidanceArticles.map((article) => (
        <div key={article.id} className="mt-1 text-right">
          <GVDSLink
            to={getLinkToSingleArticle(article)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {article.id !== guidanceArticleId ? (
              <>{article.title}</>
            ) : (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id={uniqueId("guidance-article")}>
                    You are currently viewing this article
                  </Tooltip>
                }
              >
                <span>{article.title}</span>
              </OverlayTrigger>
            )}
          </GVDSLink>
        </div>
      ))}
    </div>
  );
};

const CategoryProgressDisplay = ({
  isSelected,
  categoryName,
  answered,
  total,
  onClick,
}) => {
  const progress = 100 * (answered / total);

  return (
    <div
      className={
        isSelected
          ? "best-practice-category-progress active"
          : "best-practice-category-progress"
      }
      onClick={() => onClick(categoryName)}
    >
      <div className="best-practice-category-card__header with-progress">
        <Form.Check
          label=""
          type="checkbox"
          id={`option-${categoryName}`}
          checked={isSelected}
        />
        <div className="best-practice-category-card__name">{categoryName}</div>
      </div>
      <Spacer />
      <div>
        <ProgressBar now={progress} />
        <div className="mt-1 ms-1 caption">
          {answered}/{total}
        </div>
      </div>
    </div>
  );
};

const CategoryNoProgressDisplay = ({ isSelected, categoryName, onClick }) => {
  return (
    <div
      className={
        isSelected
          ? "best-practice-portfolio-category active"
          : "best-practice-portfolio-category"
      }
      onClick={() => onClick(categoryName)}
    >
      <div className="best-practice-category-card__header">
        <Form.Check
          label=""
          type="checkbox"
          id={`option-${categoryName}`}
          checked={isSelected}
        />
        <div className="best-practice-category-card__name">{categoryName}</div>
      </div>
    </div>
  );
};

export default withAuthentication(BestPracticesAssess);
