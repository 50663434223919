import React, { useContext, useState } from "react";
import DashboardDataContext from "../Context/DashboardDataContext";
import ContinuousTimeFilterView from "./ContinuousTimeFilterView";
import moment from "moment";

const ContinuousTimeFilter = () => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const selectedConfigHolder = dashboardDataContext.selectedConfigHolder;
  const [activeQuickfillValue, setActiveQuickfillValue] = useState(null);

  const start = moment({
    year: selectedConfigHolder.continuousTime.startYear,
    month: selectedConfigHolder.continuousTime.startMonth - 1,
  })
    .startOf("month")
    .toDate();
  const end = moment({
    year: selectedConfigHolder.continuousTime.endYear,
    month: selectedConfigHolder.continuousTime.endMonth - 1,
  })
    .endOf("month")
    .toDate();

  const onSavePeriod = (newStart, newEnd, quickfillValue) => {
    setActiveQuickfillValue(quickfillValue);

    selectedConfigHolder.continuousTime = {
      startYear: newStart.getFullYear(),
      startMonth: newStart.getMonth() + 1,
      endYear: newEnd.getFullYear(),
      endMonth: newEnd.getMonth() + 1,
    };

    selectedConfigHolder.selectAllContinuousTimeMonthYear();
    dashboardDataContext.onUpdateConfig();
  };

  const onSaveMonthInclusion = (selectedMonthsByYear) => {
    selectedConfigHolder.continuousTimeIncludedMonthsByYear =
      selectedMonthsByYear;
    dashboardDataContext.onUpdateConfig();
  };

  return (
    <ContinuousTimeFilterView
      startPeriod={start}
      endPeriod={end}
      activeQuickfillValue={activeQuickfillValue}
      includedMonthsByYear={
        selectedConfigHolder.continuousTimeIncludedMonthsByYear
      }
      onSavePeriod={onSavePeriod}
      onSaveMonthInclusion={onSaveMonthInclusion}
    />
  );
};

export default ContinuousTimeFilter;
