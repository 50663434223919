import React, { useContext, useEffect, useRef, useState } from "react";
import Spacer from "../../../../gvds-components/Layout/Spacer";
import GVDSTextButton from "../../../../gvds-components/Buttons/GVDSTextButton";
import GVDSIconSlim from "../../../../gvds-components/Icons/GVDSIconSlim";
import {
  IconArrowsUpDown,
  IconChevronDown,
  IconChevronRight,
  IconCirclePlus,
  IconEdit,
} from "@tabler/icons-react";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import ToastContext from "../../../../context/ToastContext";
import CertificationContentRequirements from "../Requirement/CertificationContentRequirements";
import CertificationCriterionAdminService from "../../../../services/PortalAdmin/Certification/CertificationCriterionAdminService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import {
  CannotDeleteCriteriaModal,
  ConfirmDeleteCriteriaModal,
  CriteriaFormModal,
  MoveCertificationCriteriaModal,
} from "./CertificationContentCriteriaModals";
import { CertificationUtil } from "../../../Certifications/CertificationUtil";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";
import { useLocation } from "react-router-dom";

const CRITERIA_HAS_REQUIREMENTS_ERROR_MESSAGE = "Criteria has requirements";
const CRITERION_REQUIREMENT_NUMBER_EXTRA_PADDING_IN_PX = 16;
const CRITERION_REQUIREMENT_INSTRUCTION_BANNER_EXTRA_PADDING_IN_PX = 46;

const CertificationContentCriterion = ({
  certificationId,
  selectedCategory,
  allCategories,
  criterion,
  reloadCategories,
  promptEditCriteria,
  promptMoveCriteria,
  isExpanded = false,
}) => {
  const location = useLocation();
  const [isCriterionExpanded, setIsCriterionExpanded] = useState(isExpanded);

  const ref = useRef(null);
  const [
    criterionRequirementNumberCssMinWidth,
    setCriterionRequirementNumberCssMinWidth,
  ] = useState(0);

  useEffect(() => {
    const criterionRequirementNumbers = [
      criterion.number,
      ...criterion.requirements.map((r) => r.number),
    ];
    setCriterionRequirementNumberCssMinWidth(
      CertificationUtil.calculateMinCssWidth(criterionRequirementNumbers, ref) +
        CRITERION_REQUIREMENT_NUMBER_EXTRA_PADDING_IN_PX
    );
    ref.current.innerHTML = null;
  }, [location]);

  return (
    <>
      <div
        ref={ref}
        style={{ width: "fit-content", visibility: "hidden" }}
      ></div>
      <div
        className="system-toolbox--certification__criteria"
        onClick={(event) => setIsCriterionExpanded(!isCriterionExpanded)}
      >
        <div className="d-flex flex-row">
          <GVDSTextButton
            className="system-toolbox--certification__criteria__collapsible-toggle-button"
            text={
              <GVDSIconSlim
                className="color-black"
                Icon={isCriterionExpanded ? IconChevronDown : IconChevronRight}
              />
            }
          />
          <div
            className="system-toolbox--certification__criteria-display__number"
            style={{ minWidth: `${criterionRequirementNumberCssMinWidth}px` }}
          >
            {criterion.number}
          </div>
          <div className="system-toolbox--certification__criteria-display__name">
            <div>{criterion.name}</div>
          </div>
          <Spacer />
          <div className="system-toolbox--certification__content-button-container thin-margin-top">
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              icon={<GVDSIcon Icon={IconEdit} />}
              tooltipText="Edit"
              onClick={(event) => {
                event.stopPropagation();
                promptEditCriteria(criterion);
              }}
            />
            <GVDSIconButton
              variant={iconButtonVariant.tertiary}
              icon={<GVDSIcon Icon={IconArrowsUpDown} />}
              tooltipText="Move"
              onClick={(event) => {
                event.stopPropagation();
                promptMoveCriteria(criterion);
              }}
            />
          </div>
        </div>
        {criterion.instruction && (
          <div
            className="system-toolbox--certification__instruction-banner__container"
            style={{
              marginLeft: `${
                criterionRequirementNumberCssMinWidth +
                CRITERION_REQUIREMENT_INSTRUCTION_BANNER_EXTRA_PADDING_IN_PX
              }px`,
            }}
          >
            <GVDSBanner
              variant={GVDSBanner.Variants.informational}
              className="system-toolbox--certification__instruction-banner"
            >
              <div className="gvds-text--caption">{criterion.instruction}</div>
            </GVDSBanner>
          </div>
        )}
      </div>
      {isCriterionExpanded && (
        <CertificationContentRequirements
          certificationId={certificationId}
          selectedCategory={selectedCategory}
          categories={allCategories}
          selectedCriterion={criterion}
          reloadCategories={reloadCategories}
          requirementNumberCssMinWidth={criterionRequirementNumberCssMinWidth}
        />
      )}
    </>
  );
};

const CertificationContentCriteria = ({
  certificationId,
  selectedCategory,
  allCategories,
  reloadCategories,
}) => {
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [criteria, setCriteria] = useState([]);

  useEffect(() => {
    if (selectedCategory) {
      setIsLoading(true);
      CertificationCriterionAdminService.getCriteria(
        certificationId,
        selectedCategory.id
      )
        .then((response) => {
          setCriteria(response.sort(CertificationUtil.sortCriteriaFn));
        })
        .catch(() => {
          toastContext.addFailToast(<span>Failed to load criteria.</span>);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedCategory]);

  const [showCriteriaFormModal, setShowCriteriaFormModal] = useState(false);
  const onCriteriaFormModalClose = () => {
    setCriterionToBeEdited(null);
    setShowCriteriaFormModal(false);
  };
  const promptAddCriteria = () => {
    setCriterionToBeEdited(null);
    setShowCriteriaFormModal(true);
  };

  const refreshCriteria = (updatedCriterion) => {
    const criterion = criteria.find(
      (criterion) => criterion.id === updatedCriterion.id
    );

    if (criterion === undefined) {
      criteria.push(updatedCriterion);
    } else {
      criterion.number = updatedCriterion.number;
      criterion.name = updatedCriterion.name;
      criterion.description = updatedCriterion.description;
      criterion.instruction = updatedCriterion.instruction;
    }
    setCriteria(criteria);
  };

  const [criterionToBeEdited, setCriterionToBeEdited] = useState(null);
  const promptEditCriteria = (criteria) => {
    setCriterionToBeEdited(criteria);
    setShowCriteriaFormModal(true);
  };

  const [criteriaToBeMoved, setCriteriaToBeMoved] = useState(null);
  const [showMoveCriteriaModal, setShowMoveCriteriaModal] = useState(false);
  const onMoveCriteriaModalClose = () => {
    setCriteriaToBeMoved(null);
    setShowMoveCriteriaModal(false);
  };
  const promptMoveCriteria = (criteria) => {
    setCriteriaToBeMoved(criteria);
    setShowMoveCriteriaModal(true);
  };

  const onSuccessMoveCriteria = (newCriteria) => {
    setCriteria(newCriteria);
    setShowMoveCriteriaModal(false);
    reloadCategories();
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteCriteriaModal, setShowDeleteCriteriaModal] = useState(false);
  const [showCannotDeleteCriteriaModal, setShowCannotDeleteCriteriaModal] =
    useState(false);
  const onSuccessDeleteCriteria = () => {
    const newCriteria = criteria.filter(
      (criterion) => criterion.id !== criterionToBeEdited.id
    );
    setCriteria(newCriteria);
    reloadCategories();
    onDeleteCriteriaModalClose();
  };

  const onDeleteCriteriaModalClose = () => {
    setCriterionToBeEdited(null);
    setShowDeleteCriteriaModal(false);
  };

  const promptDeleteCriteria = () => {
    setShowCriteriaFormModal(false);
    setShowDeleteCriteriaModal(true);
  };

  const deleteCriteria = async () => {
    setIsDeleting(true);

    try {
      await CertificationCriterionAdminService.deleteCriterion(
        certificationId,
        selectedCategory.id,
        criterionToBeEdited.id
      );

      toastContext.addSuccessToast(<span>Criteria successfully deleted.</span>);
      setIsDeleting(false);
      onSuccessDeleteCriteria();
    } catch (error) {
      setIsDeleting(false);

      if (
        error.response?.data?.message ===
        CRITERIA_HAS_REQUIREMENTS_ERROR_MESSAGE
      ) {
        setShowDeleteCriteriaModal(false);
        setShowCannotDeleteCriteriaModal(true);
      } else {
        toastContext.addFailToast(<span>Failed to delete criteria.</span>);
      }
    }
  };

  return (
    <>
      <div className="gvds-text--heading3 mb-3">Criteria & Requirements</div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="system-toolbox--certification__criteria-container">
          {criteria.length > 0 &&
            criteria.map((criterion, index) => (
              <CertificationContentCriterion
                key={index}
                certificationId={certificationId}
                selectedCategory={selectedCategory}
                allCategories={allCategories}
                criterion={criterion}
                reloadCategories={reloadCategories}
                promptEditCriteria={promptEditCriteria}
                promptMoveCriteria={promptMoveCriteria}
                isExpanded={index === 0}
              />
            ))}

          <GVDSTextButton
            className="system-toolbox--certification__add-content-button"
            variant={iconButtonVariant.tertiary}
            icon={<GVDSIcon Icon={IconCirclePlus} className="me-2" />}
            text="Add criteria"
            onClick={promptAddCriteria}
            disabled={!selectedCategory}
          />
        </div>
      )}

      <CriteriaFormModal
        show={showCriteriaFormModal}
        setShowInputCriteriaForm={setShowCriteriaFormModal}
        onSuccess={(updatedCriterion) => {
          refreshCriteria(updatedCriterion);
          setCriterionToBeEdited(null);
          setShowCriteriaFormModal(false);
          reloadCategories();
        }}
        criterion={criterionToBeEdited}
        category={selectedCategory}
        certificationId={certificationId}
        closeModal={onCriteriaFormModalClose}
        onPressDeleteCriteria={promptDeleteCriteria}
      />
      <ConfirmDeleteCriteriaModal
        show={showDeleteCriteriaModal}
        closeModal={onDeleteCriteriaModalClose}
        isDeleting={isDeleting}
        asyncOnDelete={deleteCriteria}
      />
      <CannotDeleteCriteriaModal
        show={showCannotDeleteCriteriaModal}
        closeModal={() => setShowCannotDeleteCriteriaModal(false)}
      />
      {criteriaToBeMoved && (
        <MoveCertificationCriteriaModal
          show={showMoveCriteriaModal}
          closeModal={onMoveCriteriaModalClose}
          onSuccess={onSuccessMoveCriteria}
          criteriaToBeMoved={criteriaToBeMoved}
          allCategories={allCategories}
          certificationId={certificationId}
          selectedCategory={selectedCategory}
        />
      )}
    </>
  );
};

export default CertificationContentCriteria;
