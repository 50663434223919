import React from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { getRedirectURLWithCurrentParam } from "../../../components/common/QueryHandler";

class Nav {
  constructor(name, route) {
    this.name = name;
    this.route = route;
  }
}

const navItems = [
  new Nav("Contracts", "/system-toolbox/contracts"),
  new Nav("Sites", "/system-toolbox/sites"),
  new Nav("Subscriptions", "/system-toolbox/subscriptions"),
  new Nav("Users", "/system-toolbox/users"),
  new Nav("Sign-Up Requests", "/system-toolbox/signup-requests"),
  new Nav("Onboarding Tool", "/system-toolbox/onboarding-tool"),
  new Nav("Help Guides", "/system-toolbox/help"),
  new Nav("Guidance Library", "/system-toolbox/guidance-articles"),
  new Nav("Certifications", "/system-toolbox/certifications"),
  new Nav("Best Practices List", "/system-toolbox/best-practices"),
  new Nav("Lat Long Finder", "/system-toolbox/lat-long-finder"),
  new Nav("Configuration Files", "/system-toolbox/configuration-files"),
  new Nav("Reference Data", "/system-toolbox/reference-data"),
  new Nav("Announcement", "/system-toolbox/announcement"),
  new Nav("Troubleshoot Conversion", "/system-toolbox/troubleshoot-data"),
];

const NavItem = ({ item }) => {
  const history = useHistory();
  const location = useLocation();

  const redirectTo = (path) => {
    history.push(getRedirectURLWithCurrentParam(path, location));
  };

  const isSelected = matchPath(location.pathname, {
    path: item.route,
    exact: false,
  });

  return (
    <div
      className={"gvds-module-nav__l1-row"}
      onClick={() => {
        redirectTo(item.route);
      }}
    >
      <div
        className={
          "gvds-module-nav__selectable" + (isSelected ? " selected" : "")
        }
      >
        <div>{item.name}</div>
      </div>
    </div>
  );
};

const SystemToolboxNav = () => {
  return (
    <div className="gvds-system-toolbox-nav">
      {navItems.map((i) => (
        <NavItem key={i.name} item={i} />
      ))}
    </div>
  );
};

export default SystemToolboxNav;
