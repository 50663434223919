import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import React, { forwardRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { DashboardFilterInputContentDisplay } from "./DashboardFilterSharedUtils";
import { UtilsService } from "../../../services/UtilsService";
import FilterSearchBox from "../../../gvds-components/common/FilterSearchBox";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import fuzzysort from "fuzzysort";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const ClassificationGroupFilterView = ({
  classificationGroupOptions,
  selectedGroupOption,
  setSelectedGroup,
}) => {
  return (
    <OverlayTrigger
      placement="right"
      flip={true}
      overlay={(props) => (
        <Popover {...props}>
          <Popover.Body>
            Choose how you'd like to view your data: breakdown by environment
            types, or by different classification groupings.
          </Popover.Body>
        </Popover>
      )}
    >
      <div>
        <GVDSFormSingleSelect
          placeholder="Select classification"
          className="dashboard-filter__classification-group-filter__single-select"
          value={selectedGroupOption}
          onSelect={(option) => setSelectedGroup(option)}
          options={classificationGroupOptions}
          isDropdownFollowFilterWidth={false}
        />
      </div>
    </OverlayTrigger>
  );
};

const ClassificationValueFilterTrigger = forwardRef(
  ({ selectedCount, onClick }, ref) => {
    return (
      <div
        className="dashboard-filter__classification-value-filter__trigger"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <DashboardFilterInputContentDisplay
          selectedCount={selectedCount}
          label="Types"
        />
      </div>
    );
  }
);

const ClassificationValueFilterView = ({
  classificationValueOptions,
  selectedValues,
  setSelectedValues,
}) => {
  const [searchText, setSearchText] = useState("");

  const handleSelect = (value) => {
    const updatedValues = UtilsService.toggleItem(selectedValues, value);
    setSelectedValues(updatedValues);
  };

  const isValueSelected = (value) => {
    return selectedValues.includes(value);
  };

  const filteredValues =
    !searchText || searchText.length === 0
      ? classificationValueOptions
      : fuzzysort
          .go(searchText, classificationValueOptions)
          .map((result) => result.target);

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={ClassificationValueFilterTrigger}
        selectedCount={selectedValues.length}
      />

      <Dropdown.Menu className="dashboard-filter__classification-value-filter__dropdown-menu">
        <div className="dashboard-filter__inventory-filter__search-box__container">
          <FilterSearchBox
            placeholder="Search type"
            value={searchText}
            onInput={setSearchText}
          />
        </div>

        <div className="dashboard-filter__inventory-filter__action-button__container">
          <GVDSTextButton
            onClick={() => setSelectedValues(classificationValueOptions)}
          >
            Select all
          </GVDSTextButton>
          <GVDSTextButton onClick={() => setSelectedValues([])}>
            Clear All
          </GVDSTextButton>
        </div>

        <div className="dashboard-filter__classification-value-filter__all-options-container">
          {filteredValues
            .sort((a, b) => a.localeCompare(b))
            .map((value) => (
              <div
                key={value}
                className="dashboard-filter__classification-value-filter__option-checkbox-container"
                onClick={() => handleSelect(value)}
              >
                <Form.Check
                  type="checkbox"
                  label={value}
                  checked={isValueSelected(value)}
                  readOnly
                />
              </div>
            ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ClassificationFilterView = ({
  classificationGroupOptions,
  selectedGroupOption,
  setSelectedGroup,
  classificationValueOptions,
  selectedValues,
  setSelectedValues,
}) => {
  return (
    <div className="dashboard-filter__classification-filter__container">
      <ClassificationGroupFilterView
        classificationGroupOptions={classificationGroupOptions}
        selectedGroupOption={selectedGroupOption}
        setSelectedGroup={setSelectedGroup}
      />
      <div className="dashboard-filter__classification-filter__sub-filter-separator" />
      <ClassificationValueFilterView
        classificationValueOptions={classificationValueOptions}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
      />
    </div>
  );
};

export default ClassificationFilterView;
