import React, { useContext, useEffect, useRef, useState } from "react";
import UserInventoryContext from "../../../context/UserInventoryContext";
import WorkspaceInventoryIcon from "../../common/WorkspaceInventoryIcon";
import { RESOURCES, smoothScrollBehaviour } from "../../../config/constants";
import GVDSIcon from "../../Icons/GVDSIcon";
import GVDSIconSlim from "../../Icons/GVDSIconSlim";
import {
  IconCheck,
  IconChevronDown,
  IconChevronRight,
} from "@tabler/icons-react";
import Spacer from "../../Layout/Spacer";
import WorkspaceNavContext from "./WorkspaceNavContext";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../Buttons/GVDSIconButton";
import FilterSearchBox from "../../common/FilterSearchBox";
import fuzzysort from "fuzzysort";
import {
  fuzzysortOptionsWithKeys,
  workspaceNavSiteSelectorSearchKeys,
} from "../../../config/search-config";
import GVDSButton, { buttonVariant } from "../../Buttons/GVDSButton";

const SiteDisplay = ({ site }) => {
  const workspaceNavContext = useContext(WorkspaceNavContext);
  const userInventory = useContext(UserInventoryContext);

  const siteRef = useRef();

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isSelected && siteRef.current) {
      siteRef.current.scrollIntoView(smoothScrollBehaviour);
    }
  }, []);

  const selectedInventory = userInventory.selectedInventory.get;
  if (!selectedInventory) {
    return null;
  }

  const isSelected =
    selectedInventory.type === RESOURCES.SITE &&
    selectedInventory.id === site.id;

  const navigateToInventory = (type, id) => {
    userInventory.selectedInventory.set(type, id);
    workspaceNavContext.close();
  };

  const isMultipleFacilities = site.all_facilities.length > 1;
  const isChildFacilitySelected =
    selectedInventory.type === RESOURCES.FACILITY &&
    !!site.all_facilities.find((f) => f.id === selectedInventory.id);

  const classNames = ["site-item"];

  if (isSelected) {
    classNames.push("selected");
  }

  const combinedClassName = classNames.join(" ");

  const showAsExpanded =
    isMultipleFacilities && (isExpanded || isChildFacilitySelected);

  return (
    <>
      <div
        ref={siteRef}
        className={combinedClassName}
        onClick={() => navigateToInventory(RESOURCES.SITE, site.id)}
      >
        <GVDSIcon Icon={WorkspaceInventoryIcon[RESOURCES.SITE]} />
        <div className="name"> {site.name}</div>
        {isSelected && (
          <div className="selected-icon">
            <GVDSIcon Icon={IconCheck} />
          </div>
        )}
        {isMultipleFacilities && (
          <>
            <Spacer />
            {showAsExpanded ? (
              <GVDSIconButton
                className="action-button"
                disabled={isChildFacilitySelected}
                variant={iconButtonVariant.tertiary}
                icon={<GVDSIconSlim Icon={IconChevronDown} />}
                tooltipText={isChildFacilitySelected ? null : "Hide facilities"}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(false);
                }}
              />
            ) : (
              <GVDSIconButton
                className="action-button"
                variant={iconButtonVariant.tertiary}
                icon={<GVDSIconSlim Icon={IconChevronRight} />}
                tooltipText="Show facilities"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(true);
                }}
              />
            )}
          </>
        )}
      </div>
      {showAsExpanded && (
        <div className="facility-list-container">
          {site.all_facilities.map((f) => {
            const isFacilitySelected =
              selectedInventory.type === RESOURCES.FACILITY &&
              selectedInventory.id === f.id;

            const className = isFacilitySelected
              ? "facility-item selected"
              : "facility-item";

            return (
              <div
                key={f.id}
                className={className}
                onClick={() => navigateToInventory(RESOURCES.FACILITY, f.id)}
              >
                {f.name}
                {isFacilitySelected && (
                  <div className="selected-icon">
                    <GVDSIcon Icon={IconCheck} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const WorkspaceSiteSelector = ({
  selectedSubportfolio = null,
  clearSelectedSubportfolio,
  sites,
}) => {
  const [searchText, setSearchText] = useState("");

  let subportfolioFilterDisplay = null;

  if (selectedSubportfolio !== null) {
    subportfolioFilterDisplay = (
      <div className="subportfolio-filter-display">
        Showing {selectedSubportfolio.sites.length} sites under{" "}
        <div className="subportfolio-filter-display__name">
          <GVDSIcon Icon={WorkspaceInventoryIcon[RESOURCES.PORTFOLIO]} />{" "}
          {selectedSubportfolio.name}
        </div>
        <GVDSButton
          variant={buttonVariant.tertiary}
          text="Show all sites"
          className="ms-auto"
          onClick={clearSelectedSubportfolio}
        />
      </div>
    );
  }

  const filteredSites =
    !searchText || searchText.length === 0
      ? sites
      : fuzzysort
          .go(
            searchText,
            sites,
            fuzzysortOptionsWithKeys(workspaceNavSiteSelectorSearchKeys)
          )
          .map((result) => result.obj);

  return (
    <div className="workspace-site-selector">
      <FilterSearchBox
        className="search-box"
        placeholder="Search for a site or facility"
        value={searchText}
        onInput={setSearchText}
      />
      {subportfolioFilterDisplay}
      {filteredSites
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((s) => (
          <SiteDisplay key={s.id} site={s} />
        ))}
    </div>
  );
};

export default WorkspaceSiteSelector;
