import React from "react";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import LoadingSpinner from "../../../common/LoadingSpinner";

const ContractInvoicePrompt = ({
  show,
  closeModal,
  currencyId,
  siteSubscriptionInvoiceModels,
  onInvoiceModelsChanged,
  saveWithoutCreatingInvoice,
  saveWithCreatingInvoice,
  isLoading,
}) => {
  const onHide = () => {
    if (!isLoading) {
      closeModal();
    }
  }

  return (
    <GVDSModal
      show={show}
      onHide={onHide}
      size={GVDSModal.Size.medium}
      title="Invoice newly added sites"
    >
      <GVDSModal.Body>
        <div>
          You have added new sites during the current billing cycle of this
          contract subscription. You likely want to pro-rate the amount to
          invoice them.
          <br />
          <br />
          Enter pro-rated amount to invoice per site in the table below. The
          table has been pre-filled with pro-rated value from subscription start
          date of the sites.
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="mt-3">
            <GVDSTableDisplay>
              <thead>
                <tr>
                  <th>Site Name</th>
                  <th>Full Subscription Price ({currencyId})</th>
                  <th>Invoice Amount</th>
                </tr>
              </thead>
              <tbody>
                {siteSubscriptionInvoiceModels.map((model, index) => {
                  return (
                    <tr key={`invoice-${index}`}>
                      <td>{model.siteName}</td>
                      <td>{model.fullPrice}</td>
                      <td>
                        <GVDSFormField
                          value={model.invoicedPrice}
                          onInput={(value) => {
                            model.invoicedPrice = value;
                            onInvoiceModelsChanged();
                          }}
                          type="number"
                          placeholder={model.getProratedAmount()}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </GVDSTableDisplay>
          </div>
        )}
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          text="Save without issuing invoice"
          onClick={saveWithoutCreatingInvoice}
          disabled={isLoading}
        />
        <GVDSButton
          variant={buttonVariant.primary}
          text="Save"
          onClick={saveWithCreatingInvoice}
          disabled={isLoading}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default ContractInvoicePrompt;
