import StatusLabel from "../../../gvds-components/common/StatusLabel";
import React from "react";
import { useTranslation } from "react-i18next";

export const ACTIVITY_LOG_ACTION = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
};

export const ACTIVITY_LOG_ACTION_DISPLAY = {
  INPUT: "Input",
  DELETE: "Delete",
  EDIT: "Edit",
};

export const ACTIVITY_LOG_ACTION_LABEL = {
  [ACTIVITY_LOG_ACTION.CREATE]: ACTIVITY_LOG_ACTION_DISPLAY.INPUT,
  [ACTIVITY_LOG_ACTION.DELETE]: ACTIVITY_LOG_ACTION_DISPLAY.DELETE,
  [ACTIVITY_LOG_ACTION.UPDATE]: ACTIVITY_LOG_ACTION_DISPLAY.EDIT,
};

export const ACTIVITY_LOG_DATE_FIELD_KEYS = ["period_from", "period_to"];

export const getActionLogLabelColor = (action) => {
  switch (action) {
    case ACTIVITY_LOG_ACTION_DISPLAY.INPUT:
      return StatusLabel.Colors.green;
    case ACTIVITY_LOG_ACTION_DISPLAY.EDIT:
      return StatusLabel.Colors.blue;
    case ACTIVITY_LOG_ACTION_DISPLAY.DELETE:
      return StatusLabel.Colors.red;
    default:
      return StatusLabel.Colors.gray;
  }
};

export const getTranslatedActionLogLabel = (t, action) => {
  switch (action) {
    case ACTIVITY_LOG_ACTION_DISPLAY.INPUT:
      return t("data-management.shared.activity-log.action-type.input");
    case ACTIVITY_LOG_ACTION_DISPLAY.EDIT:
      return t("data-management.shared.activity-log.action-type.edit");
    case ACTIVITY_LOG_ACTION_DISPLAY.DELETE:
      return t("data-management.shared.activity-log.action-type.delete");
    default:
      return "";
  }
};

export const LegacyActivityLogDetailContent = () => {
  const { t } = useTranslation();

  return (
    <div className="gvds-empty-content">
      {t("data-management.shared.activity-log.legacy-activity-log-details")}
    </div>
  );
};

export const filterActivityLogsByPeriod = ({
  allActivityLogs,
  activityLogDatePeriodStart,
  activityLogDatePeriodEnd,
  filterStartDate,
  filterEndDate,
}) => {
  return allActivityLogs.filter((item) => {
    const actionDate = item.lastActivity.action_on;
    const isActionDateValid =
      (!activityLogDatePeriodStart ||
        actionDate >= activityLogDatePeriodStart) &&
      (!activityLogDatePeriodEnd || actionDate <= activityLogDatePeriodEnd);

    if (!isActionDateValid) {
      return false;
    }

    if (filterStartDate || filterEndDate) {
      const recordPeriodFrom = item.periodFrom;
      const recordPeriodTo = item.periodTo;

      if (filterStartDate && !filterEndDate) {
        return recordPeriodTo > filterStartDate;
      }

      if (!filterStartDate && filterEndDate) {
        return recordPeriodFrom < filterEndDate;
      }

      return (
        recordPeriodFrom <= filterEndDate && recordPeriodTo >= filterStartDate
      );
    }

    return true;
  });
};
