import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import uniq from "lodash/uniq";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import flatMap from "lodash/flatMap";

import UserInventoryContext from "../../../context/UserInventoryContext";
import {
  DEFAULT_OPERATION_METER_NAME,
  PERMISSIONS,
  RESOURCES,
} from "../../../config/constants";
import { OPERATIONAL_DATA_METER } from "../../../config/ROUTES_NAME";
import OperationalService from "../../../services/OperationalService";
import OperationalMeterRecordsService, {
  OperationalMeterRecordsModel,
} from "./OperationMeterRecordsService";
import MeterRecordsService from "../MeterRecordsService";
import DataRequestService from "../../../services/DataRequestService";
import { LoadingSpinnerInline } from "../../common/LoadingSpinner";
import PermissionsContext from "../../../context/PermissionsContext";
import LastActivityDisplay from "../../common/LastActivityDisplay";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconExternalLink } from "@tabler/icons-react";
import GVDSTableDisplay from "../../../gvds-components/Table/GVDSTableDisplay";
import { DataIssueAlertExplanationBlurb } from "../DataIssueAlert";
import { ACTIONS_TABLE_HEADER } from "../../../gvds-components/Table/GVDSTable";
import { useTranslation } from "react-i18next";

const ViewAllOperationalMetersRow = ({
  meter,
  dataset,
  allDataRequestsLoaded,
  dataRequests,
  onMeterInput,
  lastUpdate,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const hasMissingDataRequest = reduce(
    dataRequests,
    (result, dr) =>
      result ||
      dr.hasMissingDataForMeter(meter.id, meter.start_date, meter.end_date),
    false
  );
  const [status, setStatus] = useState("");
  const [meterRecords, setMeterRecords] = useState({});
  const showMeterDetails = (m, d) => {
    history.push({
      pathname: OPERATIONAL_DATA_METER,
      state: {
        meter: m,
        dataset: d,
        dataRequests,
      },
    });
  };
  useEffect(() => {
    const start = moment().startOf("day").subtract(12, "months").toDate();
    const end = moment().startOf("day").toDate();
    const selectedInventory = userInventory.selectedInventory.get;

    OperationalService.getDataRecordsForMeter(
      selectedInventory.type,
      selectedInventory.id,
      meter.id,
      start,
      end
    ).then((data) => {
      const mRecords = new OperationalMeterRecordsModel(data);
      setMeterRecords(mRecords);
      const meterStatus = new OperationalMeterRecordsService()
        .updateMeter(meter, mRecords)
        .getMeterOverviewStatus(end);
      setStatus(meterStatus);
    });
  }, [lastUpdate]);

  return (
    <tr
      key={meter.id}
      className="is-clickable"
      onClick={() => showMeterDetails(meter, dataset)}
    >
      <td>{meter["operation_type"]["name"]}</td>
      <td className="operational-meter-table__meter-name">
        {DEFAULT_OPERATION_METER_NAME}{" "}
        {userInventory.selectedInventory.get.type === RESOURCES.SITE && (
          <div className="table-data__subtext">
            {dataset["facility"]["name"]}
          </div>
        )}
      </td>
      <td>
        {meterRecords.lastActivity && (
          <LastActivityDisplay lastActivity={meterRecords.lastActivity} />
        )}
      </td>
      <td className="environmental-meter-table__status">
        {allDataRequestsLoaded || hasMissingDataRequest ? (
          MeterRecordsService.getStatusLabel(t, hasMissingDataRequest, status)
        ) : (
          <LoadingSpinnerInline />
        )}
      </td>
      <td>
        <div className="gvds-table-cell__actions-container">
          <GVDSIconButton
            variant={iconButtonVariant.tertiary}
            icon={<GVDSIcon Icon={IconExternalLink} />}
            tooltipText="View Details"
            onClick={(e) => {
              e.stopPropagation();
              showMeterDetails(meter, dataset);
            }}
          />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.OPERATIONAL_RECORD_CREATE
            ] && (
              <GVDSIconButton
                variant={iconButtonVariant.tertiary}
                icon={<GVDSIcon Icon={IconCirclePlus} />}
                onClick={(e) => {
                  e.stopPropagation();
                  onMeterInput(meter, dataset);
                }}
                tooltipText="Input"
              />
            )}
        </div>
      </td>
    </tr>
  );
};

const ViewAllOperationalMeters = ({ datasets, onMeterInput, lastUpdate }) => {
  const userInventory = useContext(UserInventoryContext);
  const [newDataRequestDetails, setNewDataRequestDetails] = useState({});
  const [dataRequests, setDataRequests] = useState([]);
  const [allDataRequestsLoaded, setAllDataRequestsLoaded] = useState(false);

  useEffect(() => {
    const inventory = userInventory.selectedInventory.get;
    if (inventory && inventory.id) {
      loadData(inventory.id, inventory.type).then();
    }
  }, [datasets]);

  useEffect(() => {
    if (newDataRequestDetails.id) {
      setDataRequests([...dataRequests, newDataRequestDetails]);
    }
  }, [newDataRequestDetails.id]);

  const loadData = async (resourceId, resourceType) => {
    const dr = flatMap(
      await Promise.all(
        userInventory.facilities.get.map(
          async (f) =>
            await DataRequestService.getOngoingDataRequests(
              f.id,
              RESOURCES.FACILITY
            )
        )
      )
    );
    for (let dId of uniq(dr.map((r) => r.id))) {
      const details = await DataRequestService.getDataRequest(
        dId,
        resourceId,
        resourceType
      );
      setNewDataRequestDetails(details);
    }
    setAllDataRequestsLoaded(true);
  };

  return (
    <div>
      <GVDSTableDisplay className="operational-meter-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Meter</th>
            <th>Last Updated By</th>
            <th>
              <div className="form-label-row">
                <span className="me-1">Alerts</span>
                <InfoTooltip info={<DataIssueAlertExplanationBlurb />} />
              </div>
            </th>
            <th className="gvds-table-cell__actions-header">
              {ACTIONS_TABLE_HEADER}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortBy(datasets, ["facility.name"]).map((d) =>
            sortBy(d["meters"], ["operation_type.name"]).map((mt, index) => (
              <ViewAllOperationalMetersRow
                key={index}
                datasetskey={mt.id}
                meter={mt}
                dataset={d}
                allDataRequestsLoaded={allDataRequestsLoaded}
                dataRequests={dataRequests}
                onMeterInput={onMeterInput}
                lastUpdate={lastUpdate}
              />
            ))
          )}
        </tbody>
      </GVDSTableDisplay>
      {(datasets.length === 0 ||
        datasets.every((d) => d.meters.length === 0)) && (
        <div className="table__no_content">
          No operational meter yet. Setup your operational meters to start
          tracking your operational data.
        </div>
      )}
    </div>
  );
};

export default ViewAllOperationalMeters;
