import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "./GVDSFormShared";

const GVDSFormSingleMonthPicker = React.forwardRef(
  (
    {
      placeholder = "Select a month",
      className = "",
      selected,
      onChange,
      dateFormat = "MMM yyyy",
      disabled = false,
      onCalendarOpen,
      onCalendarClose,
      isFocusStyle = false,
      isUseIcon = true,
      statusMetadata = FormFieldStatusMetadata.getDefault(),
      maxMonth,
      minMonth,
      isPortalling = true,
      ...props
    },
    ref
  ) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const gvdsClassNames = ["gvds-form__single-monthpicker-input"];
    switch (statusMetadata.state) {
      case FormFieldState.warning:
        gvdsClassNames.push("gvds-form__warning-border");
        if (isUseIcon) {
          gvdsClassNames.push("gvds-form__warning-icon");
        }
        break;
      case FormFieldState.error:
        gvdsClassNames.push("gvds-form__error-border");
        if (isUseIcon) {
          gvdsClassNames.push("gvds-form__error-icon");
        }
        break;
      default:
        break;
    }
    if ((isCalendarOpen || isFocusStyle) && !disabled) {
      gvdsClassNames.push("on-focus");
    }

    const combinedClassNames = `${gvdsClassNames.join(" ")} ${className || ""}`;

    const handleCalendarOpen = () => {
      setIsCalendarOpen(true);
      if (onCalendarOpen) {
        onCalendarOpen();
      }
    };

    const handleCalendarClose = () => {
      setIsCalendarOpen(false);
      if (onCalendarClose) {
        onCalendarClose();
      }
    };

    return (
      <>
        <DatePicker
          ref={ref}
          placeholderText={placeholder}
          selected={selected}
          onChange={(date) => onChange(date)}
          dateFormat={dateFormat}
          disabled={disabled}
          className={combinedClassNames}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          maxDate={maxMonth}
          minDate={minMonth}
          showMonthYearPicker
          portalId={isPortalling ? "portalling-target" : null}
          {...props}
        />
        {statusMetadata.isNotNormal() && statusMetadata.message && (
          <GVDSFormErrorMessage
            status={statusMetadata.state}
            errorMsg={statusMetadata.message}
          />
        )}
      </>
    );
  }
);

export default GVDSFormSingleMonthPicker;
