import {
  changeOverTimeSiteRankingBarDirectionEnum,
  changeOverTimeSiteRankingMargin,
  ChangeOverTimeSiteRankingViewTypeEnum,
} from "./ChangeOverTimeSiteRankingChartViewConfig";
import { SORTING_TYPES } from "../../../../gvds-components/Table/GVDSTable";
import {
  expandedViewSingleHorizontalBarChartHeightInPx,
  singleBarChartGapInPx,
  singleHorizontalBarChartHeightInPx,
} from "../../Models/DashboardModels";
import { scaleLinear } from "@visx/scale";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";

const sortingValueDiffTolerance = 0.00001;

export class ChangeOverTimeSiteRankingChartUtils {
  static hasData(chartData, viewType) {
    if (chartData === null || chartData.length === 0) {
      return false;
    }

    return chartData.some((data) => data.changeValue[viewType] !== null);
  }

  static sortBarChartValues(chartData, sortOrder, viewType) {
    return chartData.sort((firstData, secondData) => {
      const firstDataValue = firstData.changeValue[viewType];
      const secondDataValue = secondData.changeValue[viewType];
      const diff = firstDataValue - secondDataValue;

      if (
        firstDataValue !== null &&
        secondDataValue !== null &&
        Math.abs(diff) > sortingValueDiffTolerance
      ) {
        return sortOrder === SORTING_TYPES.desc ? -1 * diff : diff;
      } else if (firstDataValue === null && secondDataValue === null) {
        return firstData.siteName.localeCompare(secondData.siteName);
      } else if (firstDataValue === null) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  static getXAxisScale(minXAxisScaleValue, maxXAxisScaleValue, xMax) {
    /*
    In some cases, edge ticks (first and last) are less than edge values (minimum and maximum).
    Need to manually expand the edge ticks to cover smallest and largest values.

    When bar near edge tick, label could be displayed across edge tick.
    Need to manually add 1 more tick to prevent this.
     */
    const xAxisScale = scaleLinear({
      domain: [minXAxisScaleValue, maxXAxisScaleValue],
      range: [0, xMax],
      round: true,
    });

    const xAxisTicks = xAxisScale.ticks();
    if (xAxisTicks.length > 0) {
      const firstTick = xAxisTicks[0];
      const secondTick = xAxisTicks.length > 1 ? xAxisTicks[1] : xAxisTicks[0];
      const tickStep = secondTick - firstTick;
      const lastTick = xAxisTicks[xAxisTicks.length - 1];

      let newMinXAxisScaleValue = firstTick;
      let newMaxXAxisScaleValue = lastTick;

      while (minXAxisScaleValue < newMinXAxisScaleValue) {
        newMinXAxisScaleValue -= tickStep;
      }

      while (maxXAxisScaleValue > newMaxXAxisScaleValue) {
        newMaxXAxisScaleValue += tickStep;
      }

      const isAllValuesPositive = newMinXAxisScaleValue === 0;
      const isAllValuesNegative = newMaxXAxisScaleValue === 0;

      if (!isAllValuesPositive) {
        const minTickRange =
          Math.abs(newMinXAxisScaleValue - minXAxisScaleValue) / tickStep;
        if (minTickRange <= 0.5) {
          newMinXAxisScaleValue -= tickStep;
        }
      }

      if (!isAllValuesNegative) {
        const maxTickRange =
          Math.abs(newMaxXAxisScaleValue - maxXAxisScaleValue) / tickStep;

        if (maxTickRange <= 0.5) {
          newMaxXAxisScaleValue += tickStep;
        }
      }

      if (
        newMinXAxisScaleValue !== minXAxisScaleValue ||
        newMaxXAxisScaleValue !== maxXAxisScaleValue
      ) {
        xAxisScale.domain([newMinXAxisScaleValue, newMaxXAxisScaleValue]);
      }
    }

    return xAxisScale;
  }

  static getXAxisScaleRangeValue = (chartData, viewType) => {
    let maxValue = 0;
    let minValue = 0;

    chartData.forEach((datum) => {
      const comparedValue = datum.changeValue[viewType];
      if (comparedValue > maxValue) {
        maxValue = comparedValue;
      } else if (comparedValue < minValue) {
        minValue = comparedValue;
      }
    });

    return { min: minValue, max: maxValue };
  };

  static getSVGPathData = (
    xPosition,
    yPosition,
    barWidth,
    barHeight,
    radius,
    radiusSpace,
    direction
  ) => {
    if (direction === changeOverTimeSiteRankingBarDirectionEnum.RIGHT) {
      return `
    M ${xPosition} ${yPosition}
    H ${xPosition + barWidth - radiusSpace}
    A ${radius} ${radius} 0 0 1 ${xPosition + barWidth} ${yPosition + radius}
    V ${yPosition + barHeight - radius}
    A ${radius} ${radius} 0 0 1 ${xPosition + barWidth - radiusSpace} ${
        yPosition + barHeight
      }
    H ${xPosition}
    Z
  `;
    } else {
      return `
    M ${xPosition + barWidth} ${yPosition}
    H ${xPosition + radiusSpace}
    A ${radius} ${radius} 0 0 0 ${xPosition} ${yPosition + radius}
    V ${yPosition + barHeight - radius}
    A ${radius} ${radius} 0 0 0 ${xPosition + radiusSpace} ${
        yPosition + barHeight
      }
    H ${xPosition + barWidth}
    Z
  `;
    }
  };

  static getTextXPosition(
    value,
    xPosition,
    barWidth,
    viewType,
    labelWidthCalculationRef
  ) {
    if (value >= 0) {
      return xPosition + barWidth + 4;
    } else {
      if (labelWidthCalculationRef.current) {
        let formattedValue =
          DashboardWidgetSharedUtils.getChartValueDisplay(value, true);
        if (viewType === ChangeOverTimeSiteRankingViewTypeEnum.PERCENTAGE) {
          formattedValue += "%";
        }

        labelWidthCalculationRef.current.innerHTML = formattedValue;
        const textWidth = Math.ceil(
          labelWidthCalculationRef.current.clientWidth
        );

        return xPosition - textWidth;
      } else {
        return 0;
      }
    }
  }

  static getChartHeight(barCount) {
    return (
      (singleHorizontalBarChartHeightInPx + singleBarChartGapInPx) * barCount +
      changeOverTimeSiteRankingMargin.top +
      changeOverTimeSiteRankingMargin.bottom
    );
  }

  static getExpandedViewChartHeight(barCount) {
    return (
      (expandedViewSingleHorizontalBarChartHeightInPx + singleBarChartGapInPx) *
        barCount +
      changeOverTimeSiteRankingMargin.top +
      changeOverTimeSiteRankingMargin.bottom
    );
  }
}
