import { TOOLTIP_PLACEMENTS } from "../../../config/style-config";
import Popover from "react-bootstrap/Popover";
import { DATA_GAPS_TOOLTIP } from "../Models/DashboardModels";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React from "react";

const DashboardDataGapsIndicator = () => {
  return (
    <OverlayTrigger
      placement={TOOLTIP_PLACEMENTS.TOP}
      overlay={
        <Popover>
          <Popover.Body>{DATA_GAPS_TOOLTIP}</Popover.Body>
        </Popover>
      }
    >
      <GVDSIcon Icon={IconAlertTriangleFilled} className="data-gaps-icon" />
    </OverlayTrigger>
  );
};

export default DashboardDataGapsIndicator;
