import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import React, { useContext, useEffect, useState } from "react";
import InfoTooltip from "../common/Tooltip/InfoTooltip";
import GVFormGroup from "../common/GVFormGroup";
import GVDSFormSingleDatePicker from "../../gvds-components/Forms/GVDSFormSingleDatePicker";
import Form from "react-bootstrap/Form";
import moment from "moment/moment";
import GVDSFormStartEndDatePicker from "../../gvds-components/Forms/GVDSFormStartEndDatePicker";
import UserInventoryContext from "../../context/UserInventoryContext";
import ToastContext from "../../context/ToastContext";
import LoadingSpinner from "../common/LoadingSpinner";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GVDSInfoCard from "../../gvds-components/common/GVDSInfoCard";
import GVDSClearContentButton from "../../gvds-components/common/GVDSClearContentButton";
import UnsavedChangePromptModal from "../common/UnsavedChangePromptModal";
import { DateTimeUtils } from "../../services/UtilsService";
import { useTranslation } from "react-i18next";

const TODAY = new Date();

const MeterDetailsTips = () => {
  const { t } = useTranslation();

  return (
    <GVDSInfoCard
      title={t("data-management.shared.alert-preferences.tips.title")}
      useIcon={false}
    >
      <div className="meter-tips-container">
        <ul>
          <li>
            <div className="item-title">
              {t("data-management.shared.alert-preferences.tips.missing-data")}
            </div>
            <div className="gvds-text--caption">
              {t(
                "data-management.shared.alert-preferences.tips.missing-data-tips"
              )}
            </div>
          </li>
          <li>
            <div className="item-title">
              {t(
                "data-management.shared.alert-preferences.tips.seasonal-meter"
              )}
            </div>
            <div className="gvds-text--caption">
              {t(
                "data-management.shared.alert-preferences.tips.seasonal-meter-tips"
              )}
            </div>
          </li>
          <li>
            <div className="item-title">
              {t("data-management.shared.alert-preferences.tips.new-meter")}
            </div>
            <div className="gvds-text--caption">
              {t(
                "data-management.shared.alert-preferences.tips.new-meter-tips"
              )}
            </div>
          </li>
          <li>
            <div className="item-title">
              {t(
                "data-management.shared.alert-preferences.tips.discontinued-meter"
              )}
            </div>
            <div className="gvds-text--caption">
              {t(
                "data-management.shared.alert-preferences.tips.discontinued-meter-tips"
              )}
            </div>
          </li>
        </ul>
      </div>
    </GVDSInfoCard>
  );
};

const InputAlertPreferencesModal = ({
  show,
  setShow,
  facilityId,
  meterId,
  oldDismissAlertDate,
  oldEnableDataGapsAlert,
  oldEnableAbnormalUsageCost,
  oldEffectivePeriodStartDate,
  oldEffectivePeriodEndDate,
  updateAlertPreferences,
  onAlertPreferencesUpdate,
}) => {
  const { t } = useTranslation();

  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const [dismissAlertDate, setDismissAlertDate] = useState(oldDismissAlertDate);
  const [enableDataGapsAlert, setEnableDataGapsAlert] = useState(
    oldEnableDataGapsAlert
  );
  const [enableAbnormalUsageCost, setEnableAbnormalUsageCost] = useState(
    oldEnableAbnormalUsageCost
  );
  const [effectivePeriodStartDate, setEffectivePeriodStartDate] = useState(
    oldEffectivePeriodStartDate
  );
  const [effectivePeriodEndDate, setEffectivePeriodEndDate] = useState(
    oldEffectivePeriodEndDate
  );
  const [showUnsavedChangePromptModal, setShowUnsavedChangePromptModal] =
    useState(false);

  const hasChanges =
    !DateTimeUtils.isDateEqual(oldDismissAlertDate, dismissAlertDate) ||
    oldEnableDataGapsAlert !== enableDataGapsAlert ||
    oldEnableAbnormalUsageCost !== enableAbnormalUsageCost ||
    !DateTimeUtils.isDateEqual(
      oldEffectivePeriodStartDate,
      effectivePeriodStartDate
    ) ||
    !DateTimeUtils.isDateEqual(
      oldEffectivePeriodEndDate,
      effectivePeriodEndDate
    );

  useEffect(() => {
    setDismissAlertDate(oldDismissAlertDate);
    setEnableDataGapsAlert(oldEnableDataGapsAlert);
    setEnableAbnormalUsageCost(oldEnableAbnormalUsageCost);
    setEffectivePeriodStartDate(oldEffectivePeriodStartDate);
    setEffectivePeriodEndDate(oldEffectivePeriodEndDate);
  }, [meterId]);

  const saveAlertPreferences = () => {
    const selectedInventory = userInventory.selectedInventory.get;

    setIsLoading(true);
    updateAlertPreferences(
      selectedInventory.type,
      selectedInventory.id,
      meterId,
      dismissAlertDate,
      enableDataGapsAlert,
      enableAbnormalUsageCost,
      effectivePeriodStartDate,
      effectivePeriodEndDate,
      facilityId
    )
      .then(() => {
        toastContext.addSuccessToast(
          <span>Alert preferences updated successfully.</span>
        );
        onAlertPreferencesUpdate(
          dismissAlertDate,
          enableDataGapsAlert,
          enableAbnormalUsageCost,
          effectivePeriodStartDate,
          effectivePeriodEndDate
        );
      })
      .catch(() => {
        toastContext.addFailToast(
          <span>Failed to update alert preferences.</span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetModal = () => {
    setDismissAlertDate(oldDismissAlertDate);
    setEnableDataGapsAlert(oldEnableDataGapsAlert);
    setEnableAbnormalUsageCost(oldEnableAbnormalUsageCost);
    setEffectivePeriodStartDate(oldEffectivePeriodStartDate);
    setEffectivePeriodEndDate(oldEffectivePeriodEndDate);
  };

  const onCloseModal = () => {
    if (!isLoading) {
      if (hasChanges) {
        setShow(false);
        setShowUnsavedChangePromptModal(true);
      } else {
        handleCloseModal();
      }
    }
  };

  const onCancelPromptUnsavedChangesModal = () => {
    setShowUnsavedChangePromptModal(false);
    setShow(true);
  };

  const handleCloseModal = () => {
    resetModal();
    setShowUnsavedChangePromptModal(false);
    setShow(false);
  };

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <Row>
        <Col lg={8}>
          <div>
            <div className="mb-3">
              <h3>
                {t(
                  "data-management.shared.alert-preferences.possible-issues.title"
                )}
              </h3>
              <div className="gvds-text--body mb-3">
                {t(
                  "data-management.shared.alert-preferences.possible-issues.description"
                )}
              </div>
              <div>
                <div className="d-flex flex-row mb-3">
                  <GVFormGroup controlId="dataGapsSwitch">
                    <Form.Check
                      className="data-gaps-switch me-2"
                      type="switch"
                      checked={enableDataGapsAlert}
                      onChange={() =>
                        setEnableDataGapsAlert(!enableDataGapsAlert)
                      }
                    />
                  </GVFormGroup>
                  <div>
                    {/*TODO*/}
                    <div className="gvds-text--inputField">
                      {t(
                        "data-management.shared.alert-preferences.data-gaps.title"
                      )}
                    </div>
                    <div className="gvds-text--caption">
                      {t(
                        "data-management.shared.alert-preferences.data-gaps.description"
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <GVFormGroup controlId="unusuallyHighLowSwitch">
                    <Form.Check
                      className="unusually-high-low-switch me-2"
                      type="switch"
                      checked={enableAbnormalUsageCost}
                      onChange={() =>
                        setEnableAbnormalUsageCost(!enableAbnormalUsageCost)
                      }
                    />
                  </GVFormGroup>
                  <div>
                    <div className="gvds-text--inputField">
                      {t(
                        "data-management.shared.alert-preferences.abnormal-values.title"
                      )}
                    </div>
                    <div className="gvds-text--caption">
                      {t(
                        "data-management.shared.alert-preferences.abnormal-values.description"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div>
              <h3>
                {t("data-management.shared.alert-preferences.advanced.title")}
              </h3>
              <div className="gvds-text--body mb-3">
                {t(
                  "data-management.shared.alert-preferences.advanced.description"
                )}
              </div>
              <GVFormGroup>
                <h4>
                  {t(
                    "data-management.shared.alert-preferences.dismiss-alert.title"
                  )}
                  <InfoTooltip
                    info={
                      <div>
                        {t(
                          "data-management.shared.alert-preferences.dismiss-alert.example"
                        )}
                      </div>
                    }
                    placement="top"
                  />
                </h4>
                <div className="gvds-text--body mb-3">
                  {t(
                    "data-management.shared.alert-preferences.dismiss-alert.description"
                  )}
                </div>
                <div className="dismiss-alert-date__container">
                  <div className="d-flex flex-row">
                    <div className="gvds-text--formLabel me-auto">
                      {t("shared-input-label.date")}
                    </div>
                    <GVDSClearContentButton
                      onClick={() => setDismissAlertDate(null)}
                    />
                  </div>
                  <GVDSFormSingleDatePicker
                    className="dismiss-alert-date"
                    selected={dismissAlertDate}
                    onChange={(date) => setDismissAlertDate(date)}
                    dateFormat="dd MMM yyyy"
                    maxDate={TODAY}
                  />
                </div>
              </GVFormGroup>
              <GVFormGroup>
                <h4>
                  {t(
                    "data-management.shared.alert-preferences.effective-period.title"
                  )}{" "}
                  <InfoTooltip
                    info={
                      <>
                        <div className="mb-1">
                          {t(
                            "data-management.shared.alert-preferences.effective-period.detail1"
                          )}
                        </div>
                        <div>
                          {t(
                            "data-management.shared.alert-preferences.effective-period.detail2"
                          )}
                        </div>
                        <div>
                          {t(
                            "data-management.shared.alert-preferences.effective-period.detail3"
                          )}
                        </div>
                      </>
                    }
                    placement="top"
                  />
                </h4>
                <div className="gvds-text--body mb-3">
                  {t(
                    "data-management.shared.alert-preferences.effective-period.description"
                  )}
                </div>
                <GVDSFormStartEndDatePicker
                  startDateLabel={
                    <div className="d-flex flex-row">
                      <div className="gvds-text--formLabel me-auto">
                        {t("shared-input-label.start-date")}
                      </div>
                      <GVDSClearContentButton
                        onClick={() => {
                          setEffectivePeriodStartDate(null);
                        }}
                      />
                    </div>
                  }
                  startDate={effectivePeriodStartDate}
                  endDateLabel={
                    <div className="d-flex flex-row">
                      <div className="gvds-text--formLabel ms-2 me-auto">
                        {t("shared-input-label.end-date")}
                      </div>
                      <GVDSClearContentButton
                        onClick={() => {
                          setEffectivePeriodEndDate(null);
                        }}
                      />
                    </div>
                  }
                  endDate={effectivePeriodEndDate}
                  onChange={(start, end) => {
                    if (start !== effectivePeriodStartDate) {
                      setEffectivePeriodStartDate(start);
                    }
                    if (end !== effectivePeriodEndDate) {
                      setEffectivePeriodEndDate(end);
                    }
                  }}
                  maxStartDate={
                    effectivePeriodEndDate &&
                    moment(effectivePeriodEndDate).subtract(1, "days").toDate()
                  }
                  minEndDate={
                    effectivePeriodStartDate &&
                    moment(effectivePeriodStartDate).add(1, "days").toDate()
                  }
                  dateFormat="dd MMM yyyy"
                />
              </GVFormGroup>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <MeterDetailsTips />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <GVDSModal
        title={
          <div>
            {t("data-management.shared.alert-preferences.title")}{" "}
            <InfoTooltip
              info={
                <div>
                  {t("data-management.shared.alert-preferences.description")}
                </div>
              }
            />
          </div>
        }
        size={GVDSModal.Size.medium}
        show={show}
        onHide={onCloseModal}
      >
        <GVDSModal.Body>{content}</GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={onCloseModal}
            text={t("shared-modal.footer.cancel")}
            disabled={isLoading}
          />
          <GVDSButton
            className="save-alert-preferences"
            variant={buttonVariant.primary}
            onClick={saveAlertPreferences}
            text={t("shared-modal.footer.save")}
            disabled={isLoading}
          />
        </GVDSModal.Footer>
      </GVDSModal>
      <UnsavedChangePromptModal
        show={showUnsavedChangePromptModal}
        onCancel={onCancelPromptUnsavedChangesModal}
        onProceed={handleCloseModal}
        modalName={t("data-management.shared.alert-preferences.title")}
        message={t("shared.unsaved-change-prompt-message")}
      />
    </>
  );
};

export default InputAlertPreferencesModal;
