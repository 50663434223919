import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AmplifyContext from "./AWSAmplifyContext";

import UserService from "../services/UserService";
import {
  getPathWithUpdatedInventory,
  getQueryHandler,
  updatePathWithSelectedContractId,
} from "../components/common/QueryHandler";
import { RESOURCES, TYPE_HOTEL_ALL_LOWER } from "../config/constants";
import { PortfolioUtils } from "../services/UtilsService";
import ToastContext from "./ToastContext";
import { isPrereqReady } from "../components/HOC/withPrereqReady";
import UserProfileContext from "./UserProfileContext";
import { type_hotel } from "../components/Site/SiteFacilityInformationComponents";
import { UserInventoryService } from "../services/UserInventoryService";

export const SELECTED_QUERY_KEY = "selected";
export const CONTRACT_QUERY_KEY = "contract";
export const TYPE_DELIMITER = "~";

export const ACTIVE_SITES = "[Sites with Subscription]";
export const INACTIVE_SITES = "[Inactive Sites]";
export const INACTIVE_SITES_STORAGE_KEY = "inactive_sites";

export const SPECIAL_CONTRACTS = [ACTIVE_SITES, INACTIVE_SITES];

export const getSiteId = (selectedTreeNode) => {
  if (!selectedTreeNode) {
    return null;
  } else {
    if (selectedTreeNode.nodeValue.type === RESOURCES.FACILITY) {
      return selectedTreeNode.parents[0].nodeValue.id;
    }
    if (selectedTreeNode.nodeValue.type === RESOURCES.SITE) {
      return selectedTreeNode.nodeValue.id;
    }
    return null;
  }
};

export const isMultipleWorkspaces = (selectedTreeNode) => {
  const parents = selectedTreeNode.parents;
  const hasParents = parents.length > 0 && parents[0].nodeValue.id !== null;
  const children = selectedTreeNode.children;
  const hasChildren =
    children.length > 0 &&
    ((children.length === 1 &&
      children[0].nodeValue.type !== RESOURCES.FACILITY) ||
      children.length > 1);
  const siblings = selectedTreeNode.getSiblings();
  const hasSiblings = siblings.length > 1;
  const hasOtherItems = hasParents || hasChildren || hasSiblings;

  return hasOtherItems;
};

export const isHotel = (selectedTreeNode) => {
  const nodeValue = selectedTreeNode.nodeValue;
  const inventoryType = nodeValue.type;

  if (inventoryType === RESOURCES.PORTFOLIO) {
    const sites = nodeValue.value.sites;
    const facilities = nodeValue.value.facilities;
    const isAnySiteHotel = Object.values(sites).some(
      (site) => site.type.name.toLowerCase() === type_hotel
    );
    const isAnyFacilitiesHotel = Object.values(facilities).some(
      (facility) => facility.type.name.toLowerCase() === type_hotel
    );
    return isAnySiteHotel || isAnyFacilitiesHotel;
  } else if (
    inventoryType === RESOURCES.SITE ||
    inventoryType === RESOURCES.FACILITY
  ) {
    const inventoryType = selectedTreeNode.nodeValue.value.type.name;
    return inventoryType.toLowerCase() === TYPE_HOTEL_ALL_LOWER;
  } else {
    return false;
  }
};

export const hasHotelInWorkspace = (treeNodes) => {
  return !!treeNodes.find((node) => isHotel(node));
};

export const getFacilities = (selectedTreeNode) => {
  if (!selectedTreeNode) {
    return [];
  }
  if (selectedTreeNode.nodeValue.type === RESOURCES.FACILITY) {
    return [selectedTreeNode.nodeValue];
  }
  if (selectedTreeNode.nodeValue.type === RESOURCES.SITE) {
    return selectedTreeNode.children.map((c) => c.nodeValue);
  }
  return [];
};

const UserInventoryContext = React.createContext(null);

export const UserInventoryProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const toastContext = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(true);

  const [contracts, setContracts] = useState(null);
  const [sites, setSites] = useState(null);
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [selectedContractFullDetails, setSelectedContractFullDetails] =
    useState(null);

  const [tree, setTree] = useState(null);
  const [treeNodes, setTreeNodes] = useState([]);
  const [selectedTreeNode, setSelectedTreeNode] = useState(null);
  const [inactiveSites, setInactiveSites] = useState(null);

  const amplify = useContext(AmplifyContext);
  const userProfileContext = useContext(UserProfileContext);

  const loadUserInventory = () => {
    setIsLoading(true);
    amplify.getCurrentUser().then((amplifyUserData) => {
      if (amplifyUserData) {
        UserInventoryService.getUserInventory()
          .then((inventory) => {
            let activeContracts = inventory.contracts
              ? inventory.contracts.sort((a, b) => a.name.localeCompare(b.name))
              : [];

            if (inventory.sites.length > 0) {
              activeContracts.push({
                id: ACTIVE_SITES,
                name: ACTIVE_SITES,
              });
            }

            const selectedInactiveSiteIds = JSON.parse(
              sessionStorage.getItem(INACTIVE_SITES_STORAGE_KEY)
            );
            if (selectedInactiveSiteIds && selectedInactiveSiteIds.length > 0) {
              activeContracts.push({
                id: INACTIVE_SITES,
                name: INACTIVE_SITES,
              });
            }

            setContracts(activeContracts);

            if (
              (!selectedContractId ||
                !activeContracts.find(
                  (contract) => contract.id === selectedContractId
                )) &&
              activeContracts &&
              activeContracts.length > 0
            ) {
              let queryHandler = getQueryHandler(location);
              const contractIdInQuery = queryHandler.get(CONTRACT_QUERY_KEY);

              if (
                contractIdInQuery &&
                activeContracts.find(
                  (contract) => contract.id === contractIdInQuery
                )
              ) {
                changeContractId(contractIdInQuery);
              } else {
                changeContractId(activeContracts[0].id);
              }
            }

            setSites(inventory.sites);

            if (
              (!activeContracts || activeContracts.length === 0) &&
              (!inventory.sites || inventory.sites.length === 0)
            ) {
              // user has no inventory, no loading needed for navigation construction
              setIsLoading(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
            toastContext.addFailToast(
              <span>Failed to load user inventory</span>
            );
          });
      } else {
        resetUserInventory();
      }
    });
  };

  const resetUserInventory = () => {
    setContracts(null);
    setSelectedContractId(null);

    setSites(null);

    setTree(null);
    setTreeNodes([]);
    setSelectedTreeNode(null);
  };

  const changeContractId = (contractId) => {
    setIsLoading(true);
    const isRemoveSelectedQuery =
      selectedContractId &&
      contractId !== selectedContractId &&
      !SPECIAL_CONTRACTS.includes(contractId);
    updatePathWithSelectedContractId(
      history,
      location,
      contractId,
      isRemoveSelectedQuery
    );
    setSelectedContractId(contractId);
  };

  useEffect(() => {
    if (isPrereqReady(userProfileContext)) {
      loadUserInventory();
    } else {
      resetUserInventory();
    }
  }, [userProfileContext]);

  const store = useCallback(
    {
      loadUserInventory: loadUserInventory,
      clearUserInventory: resetUserInventory,
      contracts: { get: contracts, set: setContracts },
      sites: { get: sites, set: setSites },
      facilities: { get: getFacilities(selectedTreeNode) },
      selectedContractId: {
        get: selectedContractId,
        set: changeContractId,
      },
      selectedTreeNode: { get: selectedTreeNode },
      treeNodes: { get: treeNodes },
      selectedInventory: {
        get: selectedTreeNode ? selectedTreeNode.toInventory() : null,
        set: (type, id) => {
          history.push(
            getPathWithUpdatedInventory(location, type, id),
            location.state
          );
        },
      },
      portfoliosUnderSelectedContract: {
        get: selectedContractFullDetails?.portfolios,
      },
      sitesUnderSelectedContract: {
        get: selectedContractFullDetails?.portfolios?.find(
          (p) => p.name === "Main Portfolio"
        )?.sites,
      },
      isLoadingInventory: { get: isLoading },
      isInventoryEmpty: {
        get:
          !isLoading &&
          (!contracts || contracts.length === 0) &&
          treeNodes.filter((node) => node.nodeValue.id !== null).length === 0,
      },
      inactiveSites: { get: inactiveSites, set: setInactiveSites },
    },
    [
      location.pathname,
      isLoading,
      contracts,
      sites,
      selectedContractId,
      selectedTreeNode,
      tree,
    ]
  );

  const constructTreeFromContract = (contractDetails) => {
    setIsLoading(true);
    const newTreeRoot = new TreeNode(null, null, null, null, null);
    const newTreeNodes = [newTreeRoot];

    contractDetails.portfolios
      .sort(PortfolioUtils.portfolioSortCompare)
      .forEach((portfolio) => {
        const portfolioNode = new TreeNode(
          newTreeRoot,
          portfolio.id,
          portfolio.name,
          portfolio["resource_type"],
          portfolio
        );
        newTreeNodes.push(portfolioNode);
        portfolio.sites.forEach((site) => {
          let siteNode = newTreeNodes.find(
            (node) =>
              node.nodeValue.type === RESOURCES.SITE &&
              node.nodeValue.id === site.id
          );

          if (siteNode) {
            siteNode.addParent(portfolioNode);
          } else {
            siteNode = new TreeNode(
              portfolioNode,
              site.id,
              site.name,
              site["resource_type"],
              site
            );
            newTreeNodes.push(siteNode);

            site.all_facilities.forEach((facility) => {
              const facilityNode = new TreeNode(
                siteNode,
                facility.id,
                facility.name,
                facility["resource_type"],
                facility
              );
              newTreeNodes.push(facilityNode);
            });
          }
        });
        portfolio.facilities.forEach((facility) => {
          let facilityNode = newTreeNodes.find(
            (node) =>
              node.nodeValue.type === RESOURCES.FACILITY &&
              node.nodeValue.id === facility.id
          );

          if (facilityNode) {
            facilityNode.addParent(portfolioNode);
          } else {
            const facilityNode = new TreeNode(
              portfolioNode,
              facility.id,
              facility.name,
              facility["resource_type"],
              facility
            );
            newTreeNodes.push(facilityNode);
          }
        });
      });

    setTree(newTreeRoot);
    setTreeNodes(newTreeNodes);

    setIsLoading(false);
  };

  const constructTreeFromSites = (sites) => {
    setIsLoading(true);
    const newTreeRoot = new TreeNode(null, null, null, null, null);
    const newTreeNodes = [newTreeRoot];

    sites.forEach((site) => {
      let siteNode = new TreeNode(
        newTreeRoot,
        site.id,
        site.name,
        site["resource_type"],
        site
      );
      newTreeNodes.push(siteNode);

      site.all_facilities.forEach((facility) => {
        const facilityNode = new TreeNode(
          siteNode,
          facility.id,
          facility.name,
          facility["resource_type"],
          facility
        );
        newTreeNodes.push(facilityNode);
      });
    });

    setTree(newTreeRoot);
    setTreeNodes(newTreeNodes);

    setIsLoading(false);
  };

  useEffect(() => {
    if (contracts && contracts.length > 0) {
      if (
        selectedContractId &&
        contracts.find((contract) => contract.id === selectedContractId)
      ) {
        setIsLoading(true);
        setTree(null);
        setTreeNodes([]);
        setSelectedTreeNode(null);

        if (selectedContractId === ACTIVE_SITES) {
          constructTreeFromSites(sites);
          setSelectedContractFullDetails(null);
        } else if (selectedContractId === INACTIVE_SITES) {
          const selectedInactiveSiteIds = JSON.parse(
            sessionStorage.getItem(INACTIVE_SITES_STORAGE_KEY)
          );
          if (selectedInactiveSiteIds && selectedInactiveSiteIds.length > 0) {
            UserInventoryService.getInactiveSites(selectedInactiveSiteIds)
              .then((sites) => {
                constructTreeFromSites(sites);
                setInactiveSites(sites);
                setSelectedContractFullDetails(null);
              })
              .catch(() => {
                setIsLoading(false);
                toastContext.addFailToast(
                  <span>Failed to load site details. Please try again.</span>
                );
              });
          }
        } else {
          UserInventoryService.getContractInventoryDetails(selectedContractId)
            .then((contractDetails) => {
              setSelectedContractFullDetails(contractDetails);
              constructTreeFromContract(contractDetails);
            })
            .catch(() => {
              setIsLoading(false);
              toastContext.addFailToast(
                <span>Failed to load contract details. Please try again.</span>
              );
            });
        }
      } else {
        // no contract has been selected yet, wait
      }
    }
  }, [selectedContractId, contracts]);

  useEffect(() => {
    if ((!contracts || contracts.length === 0) && sites && sites.length > 0) {
      setSelectedTreeNode(null);
      constructTreeFromSites(sites);
    }
  }, [sites]);

  useEffect(() => {
    if (tree && tree.children.length > 0 && treeNodes && treeNodes.length > 0) {
      let queryHandler = getQueryHandler(location);
      const selectedQuery = queryHandler.get(SELECTED_QUERY_KEY);
      let selectedType = null;
      let selectedId = null;

      if (selectedQuery) {
        [selectedType, selectedId] = selectedQuery.split(TYPE_DELIMITER);
      }

      if (selectedType && selectedId) {
        const currentNode = treeNodes.find(
          (node) =>
            node.nodeValue.type === selectedType &&
            node.nodeValue.id === selectedId
        );

        if (currentNode) {
          if (
            !selectedTreeNode ||
            currentNode.nodeValue.type !== selectedTreeNode.nodeValue.type ||
            currentNode.nodeValue.id !== selectedTreeNode.nodeValue.id
          ) {
            setSelectedTreeNode(currentNode);
          }
        } else {
          // unable to find node with specified query param, go to tree's first child
          const firstChildValue = tree.children[0].nodeValue;

          history.replace(
            getPathWithUpdatedInventory(
              location,
              firstChildValue.type,
              firstChildValue.id,
              selectedContractId
            ),
            location.state
          );
        }
      } else if (selectedTreeNode) {
        // no query param but a node is selected, so update query param
        history.replace(
          getPathWithUpdatedInventory(
            location,
            selectedTreeNode.nodeValue.type,
            selectedTreeNode.nodeValue.id,
            selectedContractId
          ),
          location.state
        );
      } else if (tree && tree.children && tree.children.length > 0) {
        // nothing is selected, so select first child in tree
        setSelectedTreeNode(tree.children[0]);
      }
    } else {
      setSelectedTreeNode(null);
    }
  }, [history, location, tree, selectedTreeNode, treeNodes]);

  return (
    <UserInventoryContext.Provider value={store}>
      {children}
    </UserInventoryContext.Provider>
  );
};

export default UserInventoryContext;

export class Inventory {
  constructor(type, id) {
    this.type = type;
    this.id = id;
  }
}

class NodeValue {
  id;
  name;
  type;
  value;

  constructor(id, name, type, value) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.value = value;
  }
}

class TreeNode {
  parents;
  children;
  nodeValue;

  constructor(parent, id, name, type, value) {
    this.parents = [parent];
    this.nodeValue = new NodeValue(id, name, type, value);
    this.children = [];
    if (parent) {
      parent.addChild(this);
    }
  }

  addParent(parent) {
    this.parents.push(parent);
    parent.addChild(this);
  }

  addChild(child) {
    this.children.push(child);
  }

  getSiblings() {
    return this.parents[0] ? this.parents[0].children : null;
  }

  toInventory() {
    return new Inventory(this.nodeValue.type, this.nodeValue.id);
  }
}
