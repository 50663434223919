import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import withAuthentication from "../../HOC/withAuthentication";
import GuidanceArticleService from "../../../services/GuidanceArticleService";
import LoadingSpinner from "../../common/LoadingSpinner";
import GuidanceArticleDisplay from "./GuidanceArticleDisplay";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import UserInventoryContext from "../../../context/UserInventoryContext";
import BestPracticesAssess from "./../../BestPractices/BestPracticesAssess";
import { RESOURCES } from "../../../config/constants";
import ToastContext from "../../../context/ToastContext";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import { BEST_PRACTICES_GUIDANCE } from "../../../config/ROUTES_NAME";

const ViewGuidanceArticle = () => {
  const history = useHistory();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const selectedInventory = userInventory.selectedInventory.get;
  const { articleId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [articleDetails, setArticleDetails] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (selectedInventory) {
      GuidanceArticleService.getGuidanceArticle(
        selectedInventory.type,
        selectedInventory.id,
        articleId
      )
        .then((response) => {
          setArticleDetails(response);
          setIsLoading(false);
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to load Guide. Please try again.</span>
          );
        });
    }
  }, [articleId, selectedInventory]);

  const goToGuidanceLibrary = () => {
    history.push(BEST_PRACTICES_GUIDANCE);
  };

  if (isLoading) {
    return (
      <div className="page-loading-container">
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <div>
        <PageHeader>
          <PageHeader.BackButton
            text="Return to Guidance Library"
            onClick={goToGuidanceLibrary}
          />
          <PageHeader.Title>
            <h1>{articleDetails.title}</h1>
          </PageHeader.Title>
        </PageHeader>
        <Tabs defaultActiveKey="article">
          <Tab eventKey="article" title="Guide">
            <GuidanceArticleDisplay
              showTitle={false}
              htmlContent={articleDetails.body}
              contentFileInput={articleDetails.content_file}
              lastEditedOn={articleDetails.last_edited_on}
              articleType={articleDetails.article_type}
              categories={articleDetails.categories}
              tags={articleDetails.tags}
              siteTypes={articleDetails.site_types}
              UNSDGs={articleDetails.un_sdgs}
              files={articleDetails.attached_files}
              contentType={articleDetails.content_type}
              relevantArticles={articleDetails.relevant_articles}
            />
          </Tab>
          <Tab eventKey="best-practice" title="Linked Best Practices">
            {(selectedInventory.type === RESOURCES.SITE ||
              selectedInventory.type === RESOURCES.PORTFOLIO) && (
              <BestPracticesAssess guidanceArticleId={articleId} />
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
};

export default withAuthentication(ViewGuidanceArticle);
