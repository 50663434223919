import axios from "axios";
import { API_URL } from "../../config/api-config";
import { DateTimeUtils, HttpResponseService } from "../UtilsService";

export default class HCMIReportService {
  static getReportConfig = async (resource_type, resource_id) => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/reports/hcmi/config",
        {
          params: {
            resource_type,
            resource_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static getHCMIReportRequestParams = (
    resource_id,
    resource_type,
    start,
    end,
    area_unit,
    estimate = false
  ) => ({
    resource_id,
    resource_type,
    start: DateTimeUtils.getUTCISOString(start),
    end: DateTimeUtils.getUTCISOString(end),
    area_unit,
    estimate,
  });

  static getHCMIReport = async (
    resource_id,
    resource_type,
    start,
    end,
    area_unit,
    estimate
  ) => {
    try {
      const response = await axios.get(API_URL + "/api/v1/reports/hcmi", {
        params: { resource_id, resource_type, start, end, area_unit, estimate },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };

  static downloadHCMIReportURL = async ({
    resource_id,
    resource_type,
    start,
    end,
    area_unit,
    estimate,
  }) => {
    const params = {
      resource_id,
      resource_type,
      start,
      end,
      area_unit,
      estimate,
    };

    try {
      const response = await axios.get(
        `${API_URL}/api/v1/reports/hcmi/download`,
        {
          params: params,
          responseType: "blob", // important
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "HCMI Metric Report.xlsx"
      );
    } catch (error) {
      console.error(error);
      throw error.response;
    }
  };
}
