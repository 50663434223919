import Container from "react-bootstrap/Container";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import React, { useContext, useEffect, useState } from "react";
import {
  ACTIVE_SUBSCRIPTION_STATUSES,
  SubscriptionPricingType,
  SubscriptionStatusEnum,
} from "../../SystemToolbox/Subscription/SubscriptionSharedUtils";
import BillingService from "../../../services/BillingService";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext, {
  TYPE_DELIMITER,
} from "../../../context/UserInventoryContext";
import { CONTACT_EMAIL_SALES, RESOURCES } from "../../../config/constants";
import SubscriptionService from "../../../services/SubscriptionService";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import {
  consumeModalParamFromQuery,
  getRedirectURLWithCurrentParam,
} from "../../common/QueryHandler";
import { ADMIN_TEAM } from "../../../config/ROUTES_NAME";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import ChangeBillingCycleModal from "./ChangeBillingCycleModal";
import ViewPricingPlan from "./ViewPricingPlan";
import {
  CancellationFeedbackModal,
  CancelSubscriptionModal,
} from "./CancelSubscription";
import ConfirmChangePlanModal from "../../common/Billing/ConfirmChangePlanModal";
import ViewSubscriptions from "./ViewSubscriptions";
import ViewPayment from "./ViewPayment";
import UserProfileContext from "../../../context/UserProfileContext";
import { isUserBillingAdminForInventory } from "./BillingSharedUtils";
import ViewInvoices from "./ViewInvoices";
import StartNewSubscriptionModal from "./StartNewSubscriptionModal";
import { SUBSCRIPTION_PAYMENT } from "../../SystemToolbox/Subscription/SubscriptionPaymentBadge";
import { useTranslation } from "react-i18next";

const CURRENT_SITE_SUBSCRIPTION_STATUS_ORDER = {
  [SubscriptionStatusEnum.ACTIVE]: 0,
  [SubscriptionStatusEnum.UPCOMING]: 1,
  [SubscriptionStatusEnum.PENDING]: 2,
  [SubscriptionStatusEnum.CANCELLED]: 3,
};

const UpdateBillingAdminModal = ({ showModal, closeModal }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  return (
    <GVDSModal
      title={t("admin.billing.update-billing-admin.modal-title")}
      size={GVDSModal.Size.small}
      show={showModal}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        <div className="mb-2">
          {t("admin.billing.update-billing-admin.instruction")}
        </div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.primary}
          className="go-to-team-management-button"
          onClick={() =>
            history.push(getRedirectURLWithCurrentParam(ADMIN_TEAM, location))
          }
          text={t(
            "admin.billing.update-billing-admin.button-go-to-team-management"
          )}
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

const ViewBilling = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedTreeNode = userInventory.selectedTreeNode.get;
  const inventoryDetails = selectedTreeNode?.nodeValue?.value;
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.getUserProfile();

  const isBillingAdmin = isUserBillingAdminForInventory(user, inventoryDetails);

  const [subscriptions, setSubscriptions] = useState([]);

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingAdmin, setBillingAdmin] = useState(null);
  const [packagePlans, setPackagePlans] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);

  const [isLoadingConfiguration, setIsLoadingConfiguration] = useState(true);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(true);

  const [showUpdateBillingAdminModal, setShowUpdateBillingAdminModal] =
    useState(false);

  const [newPlan, setNewPlan] = useState(null);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [showConfirmChangePlanModal, setShowConfirmChangePlanModal] =
    useState(false);
  const [showChangeBillingCycleModal, setShowChangeBillingCycleModal] =
    useState(false);
  const [showStartNewSubscriptionModal, setShowStartNewSubscriptionModal] =
    useState(false);
  const [
    startNewSubscriptionAfterSubscriptionId,
    setStartNewSubscriptionAfterSubscriptionId,
  ] = useState(null);

  const openModalByQueryKey = () => {
    const modalQuery = consumeModalParamFromQuery(location);
    if (modalQuery !== null) {
      const [open, modalName] = modalQuery.split(TYPE_DELIMITER);
      if (modalName === "pricing-plan") {
        setShowPricingPlanModal(true);
      }
    }
  };

  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [showCancellationFeedbackModal, setShowCancellationFeedbackModal] =
    useState(false);

  const selectedInventory = userInventory.selectedInventory.get;

  useEffect(() => {
    loadBillingConfiguration();
    loadSubscriptions();
  }, [userInventory.selectedInventory.get]);

  const loadBillingConfiguration = () => {
    setIsLoadingConfiguration(true);

    let resourceType = null;
    let resourceId = null;
    if (selectedInventory.type === RESOURCES.SITE) {
      resourceType = selectedInventory.type;
      resourceId = selectedInventory.id;
    } else if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      resourceType = RESOURCES.CONTRACT;
      resourceId = userInventory.selectedContractId.get;
    }

    BillingService.getBillingConfiguration(resourceType, resourceId)
      .then((config) => {
        setPaymentMethod(config.paymentMethod);
        setBillingAdmin(config.billingAdmin);
        setPackagePlans(config.packagePlans);
        setAllFeatures(config.allFeatures);
      })
      .catch(() => {
        setPaymentMethod(null);
        setBillingAdmin(null);
        setPackagePlans(null);
        setAllFeatures(null);
        toastContext.addFailToast(
          <span>Failed to load Billing Configuration</span>
        );
      })
      .finally(() => {
        setIsLoadingConfiguration(false);
      });
  };

  const loadSubscriptions = () => {
    setIsLoadingSubscriptions(true);

    if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      SubscriptionService.getContractSubscriptions(
        userInventory.selectedContractId.get
      )
        .then((subs) => {
          const activeAndIncomingSubscriptions = subs.filter((s) =>
            ACTIVE_SUBSCRIPTION_STATUSES.includes(s.status)
          );
          setSubscriptions(activeAndIncomingSubscriptions);
        })
        .catch(() => setSubscriptions([]))
        .finally(() => setIsLoadingSubscriptions(false));
    } else if (selectedInventory.type === RESOURCES.SITE) {
      SubscriptionService.getSiteSubscriptions(selectedInventory.id)
        .then((subs) => {
          const activeAndIncomingSubscriptions = subs.filter((s) =>
            ACTIVE_SUBSCRIPTION_STATUSES.includes(s.status)
          );
          setSubscriptions(activeAndIncomingSubscriptions);
        })
        .catch(() => setSubscriptions([]))
        .finally(() => setIsLoadingSubscriptions(false));
    } else {
      setSubscriptions([]);
      setIsLoadingSubscriptions(false);
    }
  };

  const onCancelSubscriptionSuccess = () => {
    setShowCancellationFeedbackModal(false);
    loadSubscriptions();
  };

  const onChoosingNewPlan = (plan, pricingPlanSubscription) => {
    setSelectedSubscription(pricingPlanSubscription);
    setNewPlan(plan);

    const isSubscriptionFromContract =
      pricingPlanSubscription &&
      pricingPlanSubscription.siteContractSubscriptionDetails;
    const isFirstSubscriptionUnpaid =
      !!pricingPlanSubscription &&
      pricingPlanSubscription.paymentStatus === SUBSCRIPTION_PAYMENT.UNPAID &&
      pricingPlanSubscription.isFirstSubscription;

    if (
      !isSubscriptionFromContract &&
      pricingPlanSubscription &&
      pricingPlanSubscription.pricingType !== SubscriptionPricingType.FREE &&
      pricingPlanSubscription.status !== SubscriptionStatusEnum.CANCELLED &&
      !isFirstSubscriptionUnpaid
    ) {
      setShowConfirmChangePlanModal(true);
    } else {
      if (isSubscriptionFromContract) {
        setStartNewSubscriptionAfterSubscriptionId(null);
      } else if (
        pricingPlanSubscription &&
        (pricingPlanSubscription.status === SubscriptionStatusEnum.CANCELLED ||
          pricingPlanSubscription.pricingType === SubscriptionPricingType.FREE)
      ) {
        setStartNewSubscriptionAfterSubscriptionId(pricingPlanSubscription.id);
      } else {
        setStartNewSubscriptionAfterSubscriptionId(null);
      }

      setShowStartNewSubscriptionModal(true);
    }
    setShowPricingPlanModal(false);
  };

  useEffect(() => {
    openModalByQueryKey();
  }, [location]);

  const currentSubscription = selectedSubscription;

  const currentPlanId = currentSubscription?.planId;
  const currentPlan = packagePlans.find(
    (plan) => plan.packagePlanId === currentPlanId
  );
  const currentBillingCycle = currentSubscription?.billingCycle;
  const nextBillingDate = moment(currentSubscription?.endPeriod).add(1, "day");

  let pricingPlanSubscription = currentSubscription;
  if (
    selectedInventory.type === RESOURCES.SITE &&
    !pricingPlanSubscription &&
    subscriptions.length > 0
  ) {
    const sortedCurrentSingleSiteSubCandidates = subscriptions
      .filter((s) => !s.siteContractSubscriptionDetails)
      .sort((s1, s2) => {
        const sortOrder1 = CURRENT_SITE_SUBSCRIPTION_STATUS_ORDER[s1.status];
        const sortOrder2 = CURRENT_SITE_SUBSCRIPTION_STATUS_ORDER[s2.status];

        if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
          return sortOrder1 - sortOrder2;
        } else if (sortOrder1 !== undefined) {
          return 1;
        } else if (sortOrder2 !== undefined) {
          return -1;
        } else {
        }
      });
    const currentSingleSiteSub =
      sortedCurrentSingleSiteSubCandidates.length > 0
        ? sortedCurrentSingleSiteSubCandidates[0]
        : null;
    const currentContractSiteSub = subscriptions.find(
      (s) =>
        s.siteContractSubscriptionDetails &&
        [
          SubscriptionStatusEnum.ACTIVE,
          SubscriptionStatusEnum.UPCOMING,
        ].includes(s.status)
    );
    pricingPlanSubscription = currentSingleSiteSub
      ? currentSingleSiteSub
      : currentContractSiteSub;
  }

  return (
    <>
      <Container fluid>
        <PageHeader>
          <PageHeader.Title>
            <h1>{t("admin.billing.page-title")}</h1>
            <Spacer />
            <GVDSButton
              className="contact-sales-button"
              variant={buttonVariant.secondary}
              text={t("admin.billing.button-contact-sales")}
              onClick={() => {
                window.open(`mailto:${CONTACT_EMAIL_SALES}`, "_blank");
              }}
            />
            <GVDSButton
              className="view-pricing-plans-button"
              variant={buttonVariant.primary}
              text={t("admin.billing.view-pricing-plan")}
              onClick={() => {
                setShowPricingPlanModal(true);
              }}
            />
          </PageHeader.Title>
        </PageHeader>
        <ViewSubscriptions
          isLoading={isLoadingSubscriptions}
          subscriptions={subscriptions}
          packagePlans={packagePlans}
          allFeatures={allFeatures}
          onTriggerChangePlan={() => {
            setShowPricingPlanModal(true);
          }}
          onTriggerChangeBillingCycle={() => {
            setShowChangeBillingCycleModal(true);
          }}
          onTriggerCancelSubscription={() =>
            setShowCancelSubscriptionModal(true)
          }
          selectSubscription={(s) => setSelectedSubscription(s)}
        />
        <ViewPayment
          isLoading={isLoadingConfiguration}
          paymentMethod={paymentMethod}
          billingAdmin={billingAdmin}
          showUpdateBillingAdminModal={() =>
            setShowUpdateBillingAdminModal(true)
          }
        />
        {isBillingAdmin && <ViewInvoices />}
      </Container>
      <UpdateBillingAdminModal
        showModal={showUpdateBillingAdminModal}
        closeModal={() => setShowUpdateBillingAdminModal(false)}
      />
      <ViewPricingPlan
        show={showPricingPlanModal}
        onHide={() => setShowPricingPlanModal(false)}
        orderedPackagePlans={packagePlans}
        billingAdmin={billingAdmin}
        currentSubscription={pricingPlanSubscription}
        currentPlanId={pricingPlanSubscription?.planId}
        onChoosingNewPlan={(plan) =>
          onChoosingNewPlan(plan, pricingPlanSubscription)
        }
      />
      <ConfirmChangePlanModal
        show={showConfirmChangePlanModal}
        onClose={() => {
          setShowConfirmChangePlanModal(false);
          setNewPlan(null);
        }}
        currentBillingCycle={currentBillingCycle}
        nextBillingDate={nextBillingDate}
        currentPlan={currentPlan}
        newPlan={newPlan}
        currentSubscription={currentSubscription}
        onChangePlanSuccess={() => {
          setShowConfirmChangePlanModal(false);
          setNewPlan(null);
          loadSubscriptions();
        }}
      />
      <StartNewSubscriptionModal
        show={showStartNewSubscriptionModal}
        onClose={() => {
          setShowStartNewSubscriptionModal(false);
          setNewPlan(null);
        }}
        newPlan={newPlan}
        startNewSubscriptionAfterSubscriptionId={
          startNewSubscriptionAfterSubscriptionId
        }
        onNewPlanSuccess={() => {
          setShowStartNewSubscriptionModal(false);
          setNewPlan(null);
          loadSubscriptions();
        }}
      />
      <ChangeBillingCycleModal
        show={showChangeBillingCycleModal}
        onClose={() => {
          setShowChangeBillingCycleModal(false);
        }}
        currentPlan={currentPlan}
        currentBillingCycle={currentBillingCycle}
        nextBillingDate={nextBillingDate}
        selectedSubscriptionId={currentSubscription?.id}
        onChangeBillingCycleSuccess={() => {
          setShowChangeBillingCycleModal(false);
          loadSubscriptions();
        }}
      />
      <CancelSubscriptionModal
        showModal={showCancelSubscriptionModal}
        closeModal={() => setShowCancelSubscriptionModal(false)}
        afterEndPeriodDate={selectedSubscription?.endPeriod}
        onProceedingWithCancellation={() => {
          setShowCancelSubscriptionModal(false);
          setShowCancellationFeedbackModal(true);
        }}
      />
      <CancellationFeedbackModal
        showModal={showCancellationFeedbackModal}
        closeModal={() => setShowCancellationFeedbackModal(false)}
        resourceType={selectedInventory.type}
        resourceId={selectedInventory.id}
        selectedSubscriptionId={selectedSubscription?.id}
        onCancelSubscriptionSuccess={onCancelSubscriptionSuccess}
      />
    </>
  );
};

export default ViewBilling;
