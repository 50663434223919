import React, { useEffect, useState } from "react";
import FeatureService from "../../../../services/FeatureService";
import Form from "react-bootstrap/Form";
import { UtilsService } from "../../../../services/UtilsService";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";

const SelectSubscriptionFeatureModal = ({
  showModal,
  onCloseModal,
  onSave,
  allFeatures,
  contractFeatures,
  subscriptionFeatures,
}) => {
  const [selectedFeatures, setSelectedFeatures] =
    useState(subscriptionFeatures);

  useEffect(() => {
    setSelectedFeatures(subscriptionFeatures);
  }, [subscriptionFeatures]);

  const toggleFeature = (feature) => {
    setSelectedFeatures(UtilsService.toggleItem(selectedFeatures, feature));
  };

  return (
    <GVDSModal
      show={showModal}
      onHide={onCloseModal}
      size={GVDSModal.Size.small}
    >
      <GVDSModal.Body className="select-subscription-feature__container">
        <div className="gvds-text--body__bold mb-1">Features</div>
        <div className="gvds-text--caption mb-2">
          Features that are not enabled in this contract's subscription will be
          disabled here.
        </div>
        {allFeatures
          .sort((feature1, feature2) =>
            FeatureService.featureSortFn(feature1.name, feature2.name)
          )
          .map((feature) => {
            return (
              <Form.Check
                checked={
                  selectedFeatures.findIndex((f) => f.id === feature.id) >= 0
                }
                disabled={
                  contractFeatures.findIndex((f) => f.id === feature.id) < 0
                }
                type="checkbox"
                name={feature.name}
                key={feature.id}
                id={`site-${feature.id}`}
                label={feature.display_name}
                onChange={() => toggleFeature(feature)}
              />
            );
          })}
      </GVDSModal.Body>
      <GVDSModal.Footer className="d-flex flex-row">
        <GVDSButton
          variant={buttonVariant.tertiary}
          className="ms-auto"
          onClick={onCloseModal}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={() => onSave(selectedFeatures)}
          text="Save"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default SelectSubscriptionFeatureModal;
