import React, { useContext, useEffect, useState } from "react";
import { cloneDeep, get, isEqual, uniqueId } from "lodash";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingSpinner from "../../common/LoadingSpinner";

import SiteService from "../../../services/SiteService";
import FacilityService from "../../../services/FacilityService";
import {
  Area,
  brandFlagNeedBrandCompanyMesssage,
  deep_field_separator,
  DropdownOptions,
  empty_id_name,
  EntityRelationship,
  expedia_star_option,
  FloorArea,
  RelatedEntities,
  StarPicker,
  type_hotel,
} from "../../Site/SiteFacilityInformationComponents";
import {
  new_entry_prefix,
  PERMISSIONS,
  RESOURCES,
  tobe_deleted_entry_prefix,
} from "../../../config/constants";
import ToastContext from "../../../context/ToastContext";
import PermissionsContext from "../../../context/PermissionsContext";
import { useHistory, useParams } from "react-router-dom";
import SelectCurrency from "../../Currency/SelectCurrency";
import GVFormGroup from "../../common/GVFormGroup";
import { DateTimeUtils } from "../../../services/UtilsService";
import BeforeUrlChangesPrompt from "../../common/BeforeUrlChangesPrompt";
import { ADMIN_FACILITIES } from "../../../config/ROUTES_NAME";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { usePrevious } from "../../common/ReactHook";
import { InventoryIcon } from "../../common/Inventory/InventoryDisplay";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import OtherActionDropdownToggle from "../../common/OtherActionDropdownToggle";
import MetroAreaService from "../../../services/MetroAreaService";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";
import GVDSFormSingleDatePicker from "../../../gvds-components/Forms/GVDSFormSingleDatePicker";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSFormMultiSelect from "../../../gvds-components/Forms/GVDSFormMultiSelect";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconTrash } from "@tabler/icons-react";

const ViewFacilityDetails = () => {
  const history = useHistory();
  const toastContext = useContext(ToastContext);
  const permissionsCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);
  const { facilityId } = useParams();
  const [facilityDetails, setFacilityDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [inEditMode, setEditMode] = useState(false);
  const [inputFields, setInputFields] = useState({
    name: { original: "", inputValue: "" },
    type: { original: empty_id_name, inputValue: empty_id_name },

    use_site_area: { original: null, inputValue: null },
    areas: { original: [], inputValue: [] },

    use_site_location: { original: null, inputValue: null },
    location__street_address: { original: "", inputValue: "" },
    location__city: { original: "", inputValue: "" },
    location__state_or_province: { original: "", inputValue: "" },
    location__zip_code: { original: "", inputValue: "" },
    location__country: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },

    use_site_related_entities: { original: null, inputValue: null },
    related_entities: { original: [], inputValue: [] },
    brand: { original: empty_id_name, inputValue: empty_id_name },
    other_identifier_code: { original: "", inputValue: "" },

    use_site_about_attribute: { original: null, inputValue: null },
    about_attribute__status: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    about_attribute__date_opened: {
      original: "",
      inputValue: "",
    },

    use_site_main_attribute: { original: null, inputValue: null },
    main_attribute__default_currency: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    main_attribute__metro_area: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    main_attribute__koppen_geiger_climate_zone: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    main_attribute__baileys_ecoregion_climate_zone: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },

    use_site_hotel_attribute: { original: null, inputValue: null },
    hotel_attribute__affiliations: {
      original: [],
      inputValue: [],
    },
    hotel_attribute__expedia_star: {
      original: null,
      inputValue: null,
    },
    hotel_attribute__market_segment: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    hotel_attribute__hotel_type: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    hotel_attribute__asset_class: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    hotel_attribute__laundry_location: {
      original: empty_id_name,
      inputValue: empty_id_name,
    },
    hotel_attribute__room_count: {
      original: null,
      inputValue: null,
    },
  });
  const [siteFacilityOptions, setSiteFacilityOptions] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const cancelDeletePrompt = () => {
    setShowDeletePrompt(false);
  };

  useEffect(() => {
    if (
      selectedInventory &&
      previousInventory &&
      selectedInventory.id !== previousInventory.id
    ) {
      goToFacilities();
    } else if (selectedInventory && !previousInventory) {
      setIsLoading(true);
      Promise.all([
        FacilityService.getFacility(facilityId),
        SiteService.getSiteFacilityOptions(),
      ])
        .then(([facilityData, siteFacilityOptions]) => {
          setFacilityDetails(facilityData);
          setOriginalValues(facilityData);
          resetInputFields();
          setSiteFacilityOptions(siteFacilityOptions);
        })
        .catch((e) => {
          toastContext.addFailToast(
            <span>Failed to load facility details. Please try again.</span>
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [selectedInventory]);

  const toggleEditMode = () => {
    setEditMode(!inEditMode);
  };

  const onInputFieldsChange = (newInputFields) => {
    setInputFields({ ...newInputFields });
    setHasChanges(true);
  };

  const saveChanges = () => {
    if (!isInputFieldsValid()) {
      return;
    }

    setIsLoading(true);
    setErrorMessage({});
    const editedFields = Object.keys(inputFields)
      .filter((key) => {
        const original = inputFields[key].original;
        const inputValue = inputFields[key].inputValue;

        return !isEqual(original, inputValue);
      })
      .reduce((obj, key) => {
        if (
          inputFields[key].inputValue.id !== undefined &&
          inputFields[key].inputValue.id === ""
        ) {
          inputFields[key].inputValue = { id: null, name: null };
        }

        if (key.includes(deep_field_separator)) {
          const [topField, nestedField] = key.split(deep_field_separator);
          if (!obj[topField]) {
            obj[topField] = {};
          }
          if (topField === "about_attribute" && nestedField === "date_opened") {
            obj[topField][nestedField] = DateTimeUtils.getUTCISOString(
              inputFields[key].inputValue
            );
          } else {
            obj[topField][nestedField] = inputFields[key].inputValue;
          }
        } else if (key === "areas") {
          const originalAreas = inputFields[key].original;
          const inputAreas = inputFields[key].inputValue;

          obj[key] = inputAreas
            .filter((inputArea) => {
              const originalArea = originalAreas.find(
                (area) => area.id === inputArea.id
              );

              if (!originalArea) {
                // if new area is empty, do not include
                return !(
                  inputArea.id.startsWith(new_entry_prefix) &&
                  inputArea.hasNoData()
                );
              }

              return !isEqual(inputArea, originalArea);
            })
            .map((area) => {
              area.record_month = DateTimeUtils.getUTCISOString(
                area.record_month
              );
              return area;
            });
        } else if (key.startsWith("use_site_")) {
          const newInputValue = inputFields[key].inputValue;
          obj[key] = newInputValue;
          const notUsingSiteValue = !newInputValue;

          if (notUsingSiteValue) {
            if (key === "use_site_location") {
              [
                "location__city",
                "location__country",
                "location__state_or_province",
                "location__street_address",
                "location__zip_code",
              ].map((affectedKey) => {
                const [topField, nestedField] =
                  affectedKey.split(deep_field_separator);
                if (!obj[topField]) {
                  obj[topField] = {};
                }
                obj[topField][nestedField] =
                  inputFields[affectedKey].inputValue;
              });
            } else if (key === "use_site_area") {
              const affectedKey = "areas";

              const inputAreas = inputFields[affectedKey].inputValue;

              obj[affectedKey] = inputAreas.map((area) => {
                area.record_month = DateTimeUtils.getUTCISOString(
                  area.record_month
                );
                return area;
              });
            } else if (key === "use_site_related_entities") {
              ["related_entities", "brand", "other_identifier_code"].map(
                (affectedKey) => {
                  if (
                    !isEqual(inputFields[affectedKey].inputValue, empty_id_name)
                  ) {
                    obj[affectedKey] = inputFields[affectedKey].inputValue;
                  }
                }
              );
            } else if (key === "use_site_about_attribute") {
              ["about_attribute__status", "about_attribute__date_opened"].map(
                (affectedKey) => {
                  const [topField, nestedField] =
                    affectedKey.split(deep_field_separator);
                  if (!obj[topField]) {
                    obj[topField] = {};
                  }

                  if (
                    !isEqual(inputFields[affectedKey].inputValue, empty_id_name)
                  ) {
                    if (
                      topField === "about_attribute" &&
                      nestedField === "date_opened"
                    ) {
                      obj[topField][nestedField] =
                        DateTimeUtils.getUTCISOString(
                          inputFields[affectedKey].inputValue
                        );
                    } else {
                      obj[topField][nestedField] =
                        inputFields[affectedKey].inputValue;
                    }
                  }
                }
              );
            } else if (key === "use_site_main_attribute") {
              [
                "main_attribute__default_currency",
                "main_attribute__metro_area",
                "main_attribute__koppen_geiger_climate_zone",
                "main_attribute__baileys_ecoregion_climate_zone",
              ].map((affectedKey) => {
                const [topField, nestedField] =
                  affectedKey.split(deep_field_separator);
                if (!obj[topField]) {
                  obj[topField] = {};
                }
                if (
                  !isEqual(inputFields[affectedKey].inputValue, empty_id_name)
                ) {
                  obj[topField][nestedField] =
                    inputFields[affectedKey].inputValue;
                }
              });
            }
            if (
              facilityDetails.type.name.toLowerCase() === type_hotel &&
              key === "use_site_hotel_attribute"
            ) {
              [
                "hotel_attribute__affiliations",
                "hotel_attribute__expedia_star",
                "hotel_attribute__market_segment",
                "hotel_attribute__hotel_type",
                "hotel_attribute__asset_class",
                "hotel_attribute__laundry_location",
                "hotel_attribute__room_count",
              ].map((affectedKey) => {
                const [topField, nestedField] =
                  affectedKey.split(deep_field_separator);
                if (!obj[topField]) {
                  obj[topField] = {};
                }
                if (
                  !isEqual(inputFields[affectedKey].inputValue, empty_id_name)
                ) {
                  obj[topField][nestedField] =
                    inputFields[affectedKey].inputValue;
                }
              });
            }
          }
        } else {
          obj[key] = inputFields[key].inputValue;
        }

        return obj;
      }, {});

    FacilityService.editFacility(
      RESOURCES.SITE,
      selectedInventory.id,
      facilityDetails.id,
      editedFields
    )
      .then(() => {
        setEditMode(false);
        toastContext.addSuccessToast(
          <span>Facility Details have been updated</span>
        );
        userInventory.loadUserInventory();
      })
      .catch((error) => {
        if (error.response != null && error.response.status === 400) {
          if (error.response.data.message.name != null) {
            setErrorMessage({ name: error.response.data.message.name.name });
          } else {
            toastContext.addFailToast(
              <span>Failed to update Facility Details</span>
            );
          }
        } else {
          toastContext.addFailToast(
            <span>Failed to update Facility Details</span>
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancelChanges = () => {
    resetInputFields();
    setEditMode(false);
  };

  const setOriginalValues = (facilityDetails) => {
    const originalFields = inputFields;

    originalFields.name.original = facilityDetails.name;
    originalFields.type.original = facilityDetails.type;

    originalFields.use_site_location.original =
      facilityDetails.use_site_location;
    originalFields.location__city.original = facilityDetails.location.city;
    originalFields.location__country.original =
      facilityDetails.location.country;
    originalFields.location__state_or_province.original =
      facilityDetails.location.state_or_province;
    originalFields.location__street_address.original =
      facilityDetails.location.street_address;
    originalFields.location__zip_code.original =
      facilityDetails.location.zip_code;

    originalFields.use_site_area.original = facilityDetails.use_site_area;
    originalFields.areas.original = facilityDetails.areas.map(
      (area) =>
        new Area(
          facilityDetails.use_site_area ? uniqueId(new_entry_prefix) : area.id,
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            area.record_month
          ),
          area.gfa_built,
          area.gfa_conditioned_space,
          area.gfa_guestroom,
          area.gfa_meeting,
          area.unit,
          area.comment
        )
    );

    originalFields.use_site_related_entities.original =
      facilityDetails.use_site_related_entities;
    originalFields.related_entities.original =
      facilityDetails.related_entities.map((related_entity) => {
        return new EntityRelationship(
          facilityDetails.use_site_related_entities
            ? uniqueId(new_entry_prefix)
            : related_entity.id,
          related_entity.relation_type,
          related_entity.company,
          related_entity.internal_company_code
        );
      });
    originalFields.brand.original = facilityDetails.brand
      ? facilityDetails.brand
      : empty_id_name;
    originalFields.other_identifier_code.original =
      facilityDetails.other_identifier_code;

    originalFields.use_site_about_attribute.original =
      facilityDetails.use_site_about_attribute;
    originalFields.about_attribute__status.original = facilityDetails
      .about_attribute.status
      ? facilityDetails.about_attribute.status
      : empty_id_name;
    originalFields.about_attribute__date_opened.original = facilityDetails
      .about_attribute.date_opened
      ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          facilityDetails.about_attribute.date_opened
        )
      : null;

    originalFields.use_site_main_attribute.original =
      facilityDetails.use_site_main_attribute;
    originalFields.main_attribute__default_currency.original = facilityDetails
      .main_attribute.default_currency
      ? facilityDetails.main_attribute.default_currency
      : empty_id_name;
    originalFields.main_attribute__metro_area.original = facilityDetails
      .main_attribute.metro_area
      ? facilityDetails.main_attribute.metro_area
      : empty_id_name;
    originalFields.main_attribute__koppen_geiger_climate_zone.original =
      facilityDetails.main_attribute.koppen_geiger_climate_zone
        ? facilityDetails.main_attribute.koppen_geiger_climate_zone
        : empty_id_name;
    originalFields.main_attribute__baileys_ecoregion_climate_zone.original =
      facilityDetails.main_attribute.baileys_ecoregion_climate_zone
        ? facilityDetails.main_attribute.baileys_ecoregion_climate_zone
        : empty_id_name;

    if (facilityDetails.type.name.toLowerCase() === type_hotel) {
      originalFields.use_site_hotel_attribute.original =
        facilityDetails.use_site_hotel_attribute;
      originalFields.hotel_attribute__affiliations.original = facilityDetails
        .hotel_attribute.affiliations
        ? facilityDetails.hotel_attribute.affiliations
        : [];
      originalFields.hotel_attribute__expedia_star.original = facilityDetails
        .hotel_attribute.expedia_star
        ? facilityDetails.hotel_attribute.expedia_star
        : null;
      originalFields.hotel_attribute__market_segment.original = facilityDetails
        .hotel_attribute.market_segment
        ? facilityDetails.hotel_attribute.market_segment
        : empty_id_name;
      originalFields.hotel_attribute__hotel_type.original = facilityDetails
        .hotel_attribute.hotel_type
        ? facilityDetails.hotel_attribute.hotel_type
        : empty_id_name;
      originalFields.hotel_attribute__asset_class.original = facilityDetails
        .hotel_attribute.asset_class
        ? facilityDetails.hotel_attribute.asset_class
        : empty_id_name;
      originalFields.hotel_attribute__laundry_location.original =
        facilityDetails.hotel_attribute.laundry_location
          ? facilityDetails.hotel_attribute.laundry_location
          : empty_id_name;
      originalFields.hotel_attribute__room_count.original = facilityDetails
        .hotel_attribute.room_count
        ? facilityDetails.hotel_attribute.room_count
        : null;
    }

    setInputFields(originalFields);
  };

  const goToFacilities = () => {
    history.push(ADMIN_FACILITIES);
  };

  const resetInputFields = () => {
    const resetFields = inputFields;

    Object.keys(resetFields).forEach((key) => {
      resetFields[key].inputValue = cloneDeep(resetFields[key].original);
    });

    setInputFields(resetFields);
    setHasChanges(false);
  };

  const changeCheckboxInputField = (event) => {
    const targetName = event.target.name;

    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = !inputFields[targetName].inputValue;

    onInputFieldsChange(newInputFields);
  };

  const changeTextInputField = (event) => {
    const targetName = event.target.name;

    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = event.target.value;

    onInputFieldsChange(newInputFields);
  };

  const changeDropdownInputField = (targetName, newId, newName) => {
    const newInputFields = inputFields;

    newInputFields[targetName].inputValue = { id: newId, name: newName };

    if (targetName === "location__country") {
      newInputFields["main_attribute__metro_area"].inputValue = empty_id_name;

      if (inputFields.location__state_or_province.inputValue) {
        const provinceDetail = siteFacilityOptions.provinces
          ? siteFacilityOptions.provinces.find(
              (p) =>
                p.name === inputFields.location__state_or_province.inputValue
            )
          : null;
        if (provinceDetail && provinceDetail.country.id !== newId) {
          newInputFields["location__state_or_province"].inputValue = "";
        } else if (
          !provinceDetail &&
          siteFacilityOptions.provinces &&
          siteFacilityOptions.provinces.find((p) => p.country.id === newId)
        ) {
          // no matching province dropdown and new country has province dropdown
          newInputFields["location__state_or_province"].inputValue = "";
        }
      }
    }

    onInputFieldsChange(newInputFields);
  };

  const changeDate = (targetName, newDate) => {
    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = newDate;

    onInputFieldsChange(newInputFields);
  };

  const changeMultiSelect = (targetName, selected) => {
    const newInputFields = inputFields;

    newInputFields[targetName].inputValue = selected.map((item) => {
      return { id: item.value, name: item.label };
    });

    onInputFieldsChange(newInputFields);
  };

  const changeStateOrProvinceDropdown = (targetName, newId, newName) => {
    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = newName;

    onInputFieldsChange(newInputFields);
  };

  const changeStarPicker = (targetName, value) => {
    const newInputFields = inputFields;
    newInputFields[targetName].inputValue = value;

    onInputFieldsChange(newInputFields);
  };

  const changeArea = (areas) => {
    const newInputFields = inputFields;
    newInputFields.areas.inputValue = areas;

    onInputFieldsChange(newInputFields);
  };

  const getBrandCompaniesIds = (relatedEntities) => {
    return relatedEntities
      .filter(
        (entity) =>
          entity.relation_type.name.toLowerCase() === "brand" &&
          entity.company.id.length > 0 &&
          !entity.id.startsWith(tobe_deleted_entry_prefix)
      )
      .map((entity) => entity.company.id);
  };

  const getAvailableBrandOptions = (relatedEntities) => {
    const brandCompaniesIds = getBrandCompaniesIds(relatedEntities);

    return brandCompaniesIds && siteFacilityOptions.brands
      ? Object.entries(siteFacilityOptions.brands)
          .map(([brandCompanyId, brandsOfCompany]) => {
            return brandCompaniesIds.indexOf(brandCompanyId) >= 0
              ? brandsOfCompany
              : [];
          })
          .reduce((a, b) => a.concat(b), [])
      : [];
  };

  const changeRelatedEntities = (relatedEntities) => {
    const newInputFields = inputFields;
    newInputFields.related_entities.inputValue = relatedEntities;

    const brandOptions = getAvailableBrandOptions(
      inputFields.related_entities.inputValue
    );

    const isCurrentlySelectedBrandInAvailableBrandList = brandOptions.find(
      (brandOption) => brandOption.id === inputFields.brand.inputValue.id
    );

    const isBrandOptionAvailable = brandOptions.length > 0;
    const isBrandValueNA = inputFields.brand.inputValue.id === "";

    if (
      !isBrandOptionAvailable ||
      (!isBrandValueNA && !isCurrentlySelectedBrandInAvailableBrandList)
    ) {
      newInputFields.brand.inputValue = empty_id_name;
    }

    onInputFieldsChange(newInputFields);
  };

  const isFacilityNameValid = () => {
    return (
      inputFields.name.inputValue && inputFields.name.inputValue.length > 0
    );
  };

  const isFacilityNameWithErrors = () => {
    return !!get(errorMessage, "name");
  };

  const isFacilityTypeValid = () => {
    return inputFields.type.inputValue && inputFields.type.inputValue.id;
  };

  const isAddressValid = () => {
    return (
      inputFields.use_site_location.inputValue ||
      (inputFields.location__street_address.inputValue &&
        inputFields.location__street_address.inputValue.length > 0)
    );
  };

  const isCityValid = () => {
    return (
      inputFields.use_site_location.inputValue ||
      (inputFields.location__city.inputValue &&
        inputFields.location__city.inputValue.length > 0)
    );
  };

  const isZipCodeValid = () => {
    return (
      inputFields.use_site_location.inputValue ||
      (inputFields.location__zip_code.inputValue &&
        inputFields.location__zip_code.inputValue.length > 0)
    );
  };

  const getProvincesOfCountry = () => {
    return siteFacilityOptions.provinces &&
      inputFields.location__country.inputValue
      ? siteFacilityOptions.provinces.filter(
          (p) => p.country.id === inputFields.location__country.inputValue.id
        )
      : [];
  };

  const isStateOrProvinceValid = () => {
    const provincesOfCountry = getProvincesOfCountry();

    if (provincesOfCountry.length === 0) {
      return true;
    } else {
      return !!inputFields.location__state_or_province.inputValue;
    }
  };

  const isCountryValid = () => {
    return (
      inputFields.use_site_location.inputValue ||
      (inputFields.location__country.inputValue &&
        inputFields.location__country.inputValue.id &&
        inputFields.location__country.inputValue.id.length > 0)
    );
  };

  const isCurrencyValid = () => {
    return (
      inputFields.use_site_main_attribute.inputValue ||
      (inputFields.main_attribute__default_currency.inputValue &&
        inputFields.main_attribute__default_currency.inputValue.id &&
        inputFields.main_attribute__default_currency.inputValue.id.length > 0)
    );
  };

  const isInputFieldsValid = () => {
    return (
      isFacilityNameValid() &&
      isFacilityTypeValid() &&
      isAddressValid() &&
      isCityValid() &&
      isZipCodeValid() &&
      isStateOrProvinceValid() &&
      isCountryValid() &&
      isCurrencyValid()
    );
  };

  const stateOrProvinceInput = () => {
    const provincesOfCountry = getProvincesOfCountry();

    if (inEditMode) {
      if (provincesOfCountry.length > 0) {
        return (
          <DropdownOptions
            label="State / Province"
            inEditMode={true}
            targetName="location__state_or_province"
            value={{
              id: inputFields.location__state_or_province.inputValue,
              name: inputFields.location__state_or_province.inputValue,
            }}
            onChange={changeStateOrProvinceDropdown}
            options={provincesOfCountry.map((p) => {
              return { id: p.name, name: p.name };
            })}
            isInvalid={!isStateOrProvinceValid()}
            invalidMessage={"State or Province is required."}
          />
        );
      } else {
        return (
          <>
            <Form.Label>State / Province</Form.Label>
            <GVDSFormField
              name="location__state_or_province"
              value={inputFields.location__state_or_province.inputValue}
              onInput={(value, e) => changeTextInputField(e)}
            />
          </>
        );
      }
    } else {
      return (
        <>
          <Form.Label>State / Province</Form.Label>
          <Form.Control
            readOnly
            plaintext
            name="location__state_or_province"
            value={inputFields.location__state_or_province.inputValue || ""}
          />
        </>
      );
    }
  };

  const brandOptions = getAvailableBrandOptions(
    inputFields.related_entities.inputValue
  );

  const getBrandCompaniesOptionTuples = () => {
    let brandCompaniesOptionTuples = [];
    if (
      getBrandCompaniesIds(inputFields.related_entities.inputValue).length > 0
    ) {
      brandCompaniesOptionTuples.push({ value: "", label: "Not Applicable" });
      if (brandOptions) {
        brandOptions.map((option) => {
          brandCompaniesOptionTuples.push({
            value: option.id,
            label: option.name,
          });
        });
      }
    }
    return brandCompaniesOptionTuples;
  };

  const getBrandValue = (inputValue) => {
    const isNotApplicableSelected =
      inputValue.name === "" && inputValue.id === "";
    if (isNotApplicableSelected) {
      if (
        getBrandCompaniesIds(inputFields.related_entities.inputValue).length > 0
      ) {
        return { value: "", label: "Not Applicable" };
      } else {
        return null;
      }
    } else {
      return { value: inputValue.id, label: inputValue.name };
    }
  };

  const getBrandTitle = (inputValue) => {
    const brandValue = getBrandValue(inputValue);
    if (brandValue) {
      return brandValue.label;
    } else {
      return "";
    }
  };

  const deleteFacility = () => {
    FacilityService.deleteFacility(
      RESOURCES.SITE,
      selectedInventory.id,
      facilityId
    )
      .then(() => {
        toastContext.addSuccessToast(<span>Facility delete successfully</span>);
        userInventory.loadUserInventory();
        goToFacilities();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toastContext.addFailToast(<span>{err.response.data.message}</span>);
        } else {
          toastContext.addFailToast(<span>Failed to delete facility</span>);
        }
      });
  };

  if (isLoading || !facilityDetails) {
    return (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <PageHeader>
        <PageHeader.BackButton
          text="Return to facilities list"
          onClick={goToFacilities}
        />
        <PageHeader.Title>
          <h1>
            <div className="me-2 pt-1 d-inline-flex align-items-center">
              {/* TODO */}
              {InventoryIcon("facility")}
            </div>
            {`${facilityDetails.name}'s Facility Details`}
          </h1>
          <Spacer />
          {!permissionsCtx.isLoadingPermissions &&
            permissionsCtx.permissions[PERMISSIONS.SITE_FACILITY_MANAGEMENT] &&
            !inEditMode && (
              <GVDSButton
                className="edit-facility-button"
                variant={buttonVariant.primary}
                onClick={toggleEditMode}
                text="Edit Facility Details"
              />
            )}
          {!permissionsCtx.isLoadingPermissions &&
            permissionsCtx.permissions[
              PERMISSIONS.SITE_FACILITY_MANAGEMENT
            ] && (
              <Dropdown>
                <Dropdown.Toggle as={OtherActionDropdownToggle} />

                <Dropdown.Menu>
                  <Dropdown.Item
                    key="delete"
                    href="#"
                    className="danger"
                    onClick={() => setShowDeletePrompt(true)}
                  >
                    <GVDSIcon Icon={IconTrash} /> Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
        </PageHeader.Title>
      </PageHeader>
      <Form>
        <section className="section-box">
          <header>
            <h2>General</h2>
          </header>
          <article>
            <GVFormGroup>
              <Form.Label>Facility Name</Form.Label>
              {inEditMode ? (
                <GVDSFormField
                  name="name"
                  value={inputFields.name.inputValue}
                  onInput={(value, e) => changeTextInputField(e)}
                  statusMetadata={
                    !isFacilityNameValid()
                      ? FormFieldStatusMetadata.getError(
                          "Please provide facility name."
                        )
                      : isFacilityNameWithErrors()
                      ? FormFieldStatusMetadata.getError(
                          get(errorMessage, "name")
                        )
                      : FormFieldStatusMetadata.getDefault()
                  }
                />
              ) : (
                <Form.Control
                  readOnly
                  plaintext
                  type="text"
                  data-lpignore="true"
                  name="name"
                  value={inputFields.name.inputValue}
                />
              )}
            </GVFormGroup>
            <GVFormGroup>
              <DropdownOptions
                label="Type"
                inEditMode={inEditMode}
                targetName="type"
                value={inputFields.type.inputValue}
                onChange={changeDropdownInputField}
                options={siteFacilityOptions.types}
                isInvalid={!isFacilityTypeValid()}
                invalidMessage={"Please select a facility type."}
              />
            </GVFormGroup>
          </article>
        </section>
        <section className="section-box">
          <header>
            <h2>Location</h2>
          </header>
          <article>
            <Form.Check
              disabled={!inEditMode}
              className="checkbox-large"
              type="checkbox"
              label="Use site's location"
              name="use_site_location"
              id="use_site_location"
              checked={inputFields.use_site_location.inputValue || false}
              onChange={changeCheckboxInputField}
            />
            {!inputFields.use_site_location.inputValue && (
              <>
                <Row>
                  <Col>
                    <GVFormGroup>
                      <Form.Label>Street Address</Form.Label>
                      {inEditMode ? (
                        <GVDSFormField
                          name="location__street_address"
                          value={
                            inputFields.location__street_address.inputValue
                          }
                          onInput={(value, e) => changeTextInputField(e)}
                        />
                      ) : (
                        <Form.Control
                          readOnly
                          plaintext
                          name="location__street_address"
                          value={
                            inputFields.location__street_address.inputValue ||
                            ""
                          }
                        />
                      )}
                    </GVFormGroup>
                  </Col>
                  <Col lg={4}>
                    <GVFormGroup>
                      <Form.Label>Postal Code</Form.Label>
                      {inEditMode ? (
                        <GVDSFormField
                          name="location__zip_code"
                          value={inputFields.location__zip_code.inputValue}
                          onInput={(value, e) => changeTextInputField(e)}
                          statusMetadata={
                            !isZipCodeValid()
                              ? FormFieldStatusMetadata.getError(
                                  "Postal code is required."
                                )
                              : FormFieldStatusMetadata.getDefault()
                          }
                        />
                      ) : (
                        <Form.Control
                          readOnly
                          plaintext
                          name="location__zip_code"
                          value={
                            inputFields.location__zip_code.inputValue || ""
                          }
                        />
                      )}
                    </GVFormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <GVFormGroup>
                      <Form.Label>City</Form.Label>
                      {inEditMode ? (
                        <GVDSFormField
                          name="location__city"
                          value={inputFields.location__city.inputValue}
                          onInput={(value, e) => changeTextInputField(e)}
                          statusMetadata={
                            !isCityValid()
                              ? FormFieldStatusMetadata.getError(
                                  "City is required."
                                )
                              : FormFieldStatusMetadata.getDefault()
                          }
                        />
                      ) : (
                        <Form.Control
                          readOnly
                          plaintext
                          name="location__city"
                          value={inputFields.location__city.inputValue || ""}
                        />
                      )}
                    </GVFormGroup>
                  </Col>
                  <Col lg={4}>{stateOrProvinceInput()}</Col>
                  <Col lg={4}>
                    <DropdownOptions
                      label="Country"
                      inEditMode={inEditMode}
                      targetName="location__country"
                      value={inputFields.location__country.inputValue}
                      onChange={changeDropdownInputField}
                      options={siteFacilityOptions.countries}
                      isInvalid={!isCountryValid()}
                      invalidMessage="Please select a country"
                    />
                  </Col>
                </Row>
              </>
            )}
          </article>
        </section>
        <section className="section-box">
          <header>
            <h2>Floor Area Details</h2>
            <div>
              (Only used if you are generating report at facility level. Report
              at site level will use site's area.)
            </div>
            <div>
              Floor area details are used for report calculation in some
              reports. Please ensure that floor area details are present for the
              Month Year during or after the report period when generating a
              report or responding to a data request.
            </div>
          </header>
          <article>
            <Form.Check
              disabled={!inEditMode}
              className="checkbox-large"
              type="checkbox"
              label="Use site's area"
              name="use_site_area"
              id="use_site_area"
              checked={inputFields.use_site_area.inputValue || false}
              onChange={changeCheckboxInputField}
            />
            {!inputFields.use_site_area.inputValue && (
              <FloorArea
                isHotel={
                  inputFields.type.inputValue.name.toLowerCase() === type_hotel
                }
                inEditMode={inEditMode}
                areaUnits={siteFacilityOptions.area_units}
                areas={inputFields.areas.inputValue}
                onAreaChange={changeArea}
              />
            )}
          </article>
        </section>
        <section className="section-box">
          <header>
            <h2>Related Entities</h2>
            <div>
              Add your brand, management or owner company here. Setting this up
              properly will allows users at the brand / management level to
              search for this site when necessary.
            </div>
          </header>
          <article>
            <Form.Check
              disabled={!inEditMode}
              className="checkbox-large"
              type="checkbox"
              label="Use site's related entities"
              name="use_site_related_entities"
              id="use_site_related_entities"
              checked={
                inputFields.use_site_related_entities.inputValue || false
              }
              onChange={changeCheckboxInputField}
            />
            {!inputFields.use_site_related_entities.inputValue && (
              <>
                <Row className="mt-3">
                  <Col xxl={4} xl={6}>
                    <GVFormGroup>
                      <Form.Label>Brand Flag</Form.Label>
                      {inEditMode ? (
                        <GVDSFormSingleSelect
                          name="brand"
                          value={
                            inputFields.brand.inputValue
                              ? getBrandValue(inputFields.brand.inputValue)
                              : null
                          }
                          onSelect={(selectedOption, actionCtx) => {
                            const targetName = actionCtx.name;
                            const newId = selectedOption.value;
                            const newName = selectedOption.label;
                            changeDropdownInputField(
                              targetName,
                              newId,
                              newName
                            );
                          }}
                          disabled={
                            getBrandCompaniesIds(
                              inputFields.related_entities.inputValue
                            ).length === 0
                          }
                          placeholder={
                            getBrandCompaniesIds(
                              inputFields.related_entities.inputValue
                            ).length === 0
                              ? brandFlagNeedBrandCompanyMesssage
                              : "Select a Brand Company"
                          }
                          options={getBrandCompaniesOptionTuples()}
                        />
                      ) : (
                        <Form.Control
                          readOnly
                          plaintext
                          type="text"
                          name="brand"
                          value={
                            inputFields.brand.inputValue
                              ? getBrandTitle(inputFields.brand.inputValue)
                              : ""
                          }
                        />
                      )}
                    </GVFormGroup>
                  </Col>
                  <Col xxl={4} xl={6}>
                    <GVFormGroup>
                      <Form.Label>Other Identifier Code</Form.Label>
                      {inEditMode ? (
                        <GVDSFormField
                          name="other_identifier_code"
                          value={inputFields.other_identifier_code.inputValue}
                          onInput={(value, e) => changeTextInputField(e)}
                        />
                      ) : (
                        <Form.Control
                          readOnly
                          plaintext
                          name="other_identifier_code"
                          value={
                            inputFields.other_identifier_code.inputValue || ""
                          }
                        />
                      )}
                    </GVFormGroup>
                  </Col>
                </Row>
                <RelatedEntities
                  inEditMode={inEditMode}
                  relatedEntities={inputFields.related_entities.inputValue}
                  relatedCompanyOptions={
                    siteFacilityOptions.related_company_options
                  }
                  onRelatedEntitiesChange={changeRelatedEntities}
                />
              </>
            )}
          </article>
        </section>
        <section className="section-box">
          <header>
            <h2>Attributes</h2>
            <div className="mb-3">
              The Attributes of your Site will help users to make sense of your
              data. We recommend that all relevant fields are completed to
              ensure that your data is as accurate and useful as possible to the
              readers of your report.
            </div>
          </header>
          <article>
            <div className="gvds-site-facility-content-box">
              <h3>About</h3>
              <Form.Check
                disabled={!inEditMode}
                className="checkbox-large"
                type="checkbox"
                label="Use site's about details"
                name="use_site_about_attribute"
                id="use_site_about_attribute"
                checked={inputFields.use_site_about_attribute.inputValue || false}
                onChange={changeCheckboxInputField}
              />
              {!inputFields.use_site_about_attribute.inputValue && (
                <>
                  <DropdownOptions
                    label="Status"
                    inEditMode={inEditMode}
                    targetName="about_attribute__status"
                    value={inputFields.about_attribute__status.inputValue}
                    onChange={changeDropdownInputField}
                    options={siteFacilityOptions.statuses}
                  />
                  <GVFormGroup>
                    <Form.Label>Date Opened</Form.Label>
                    {inEditMode ? (
                      <div className="form-datepicker">
                        <GVDSFormSingleDatePicker
                          selected={
                            inputFields.about_attribute__date_opened.inputValue
                          }
                          onChange={(date) =>
                            changeDate("about_attribute__date_opened", date)
                          }
                          dateFormat="dd MMM yyyy"
                          className="block-datepicker"
                          maxDate={new Date()}
                        />
                      </div>
                    ) : (
                      <Form.Control
                        readOnly
                        plaintext
                        type="text"
                        name="about_attribute__date_opened"
                        value={
                          inputFields.about_attribute__date_opened.inputValue
                            ? moment(
                                inputFields.about_attribute__date_opened
                                  .inputValue
                              ).format("DD MMM YYYY")
                            : ""
                        }
                      />
                    )}
                  </GVFormGroup>
                </>
              )}
            </div>
          </article>
          <article>
            <div className="gvds-site-facility-content-box">
              <h3>Main</h3>
              <Form.Check
                disabled={!inEditMode}
                className="checkbox-large"
                type="checkbox"
                label="Use site's main attribute"
                name="use_site_main_attribute"
                id="use_site_main_attribute"
                checked={inputFields.use_site_main_attribute.inputValue || false}
                onChange={changeCheckboxInputField}
              />
              {!inputFields.use_site_main_attribute.inputValue && (
                <>
                  <GVFormGroup>
                    <Form.Label>Currency of Utility Invoice</Form.Label>
                    <SelectCurrency
                      selected={
                        inputFields.main_attribute__default_currency.inputValue
                          ?.id
                      }
                      onCurrencySelected={(currencyId) => {
                        changeDropdownInputField(
                          "main_attribute__default_currency",
                          currencyId,
                          siteFacilityOptions.currencies.find(
                            (c) => c.id === currencyId
                          )?.name
                        );
                      }}
                      displayAsText={!inEditMode}
                      isInvalid={!isCurrencyValid()}
                      invalidMessage="Please select a currency"
                      targetName={"main_attribute__default_currency"}
                      showLabel={false}
                    />
                  </GVFormGroup>
                  <DropdownOptions
                    label="Metro Area"
                    inEditMode={inEditMode}
                    targetName="main_attribute__metro_area"
                    value={inputFields.main_attribute__metro_area.inputValue}
                    onChange={changeDropdownInputField}
                    options={
                      inputFields.location__country.inputValue
                        ? siteFacilityOptions.metro_areas?.filter(
                            (metro_area) => {
                              return (
                                metro_area.country === null ||
                                metro_area.country.id ===
                                  inputFields.location__country.inputValue.id
                              );
                            }
                          )
                        : []
                    }
                    optionSortFn={MetroAreaService.sortFn}
                  />
                  <DropdownOptions
                    label="Climate Zone (Köppen–Geiger)"
                    inEditMode={inEditMode}
                    targetName="main_attribute__koppen_geiger_climate_zone"
                    value={
                      inputFields.main_attribute__koppen_geiger_climate_zone
                        .inputValue
                    }
                    onChange={changeDropdownInputField}
                    options={siteFacilityOptions.koppen_geiger_climate_zones}
                  />
                  <DropdownOptions
                    label="Climate Zone (Bailey's Ecoregion)"
                    inEditMode={inEditMode}
                    targetName="main_attribute__baileys_ecoregion_climate_zone"
                    value={
                      inputFields.main_attribute__baileys_ecoregion_climate_zone
                        .inputValue
                    }
                    onChange={changeDropdownInputField}
                    options={siteFacilityOptions.baileys_ecoregion_climate_zones}
                  />
                </>
              )}
            </div>
          </article>
          {inputFields.type.inputValue.name.toLowerCase() === type_hotel && (
            <article>
              <div className="gvds-site-facility-content-box">
                <h3>Hotel</h3>
                <Form.Check
                  disabled={!inEditMode}
                  className="checkbox-large"
                  type="checkbox"
                  label="Use site's hotel attribute"
                  name="use_site_hotel_attribute"
                  id="use_site_hotel_attribute"
                  checked={
                    inputFields.use_site_hotel_attribute.inputValue || false
                  }
                  onChange={changeCheckboxInputField}
                />
                {!inputFields.use_site_hotel_attribute.inputValue && (
                  <>
                    <GVFormGroup>
                      <Form.Label>Affiliation</Form.Label>
                      <GVDSFormMultiSelect
                        name="hotel_attribute__affiliations"
                        disabled={!inEditMode}
                        value={
                          inputFields.hotel_attribute__affiliations.inputValue
                            ? inputFields.hotel_attribute__affiliations.inputValue.map(
                                (affiliation) => {
                                  return {
                                    label: affiliation.name,
                                    value: affiliation.id,
                                  };
                                }
                              )
                            : []
                        }
                        onSelect={(values) =>
                          changeMultiSelect(
                            "hotel_attribute__affiliations",
                            values
                          )
                        }
                        options={
                          siteFacilityOptions.affiliations
                            ? siteFacilityOptions.affiliations.map(
                                (affiliation) => {
                                  return {
                                    label: affiliation.name,
                                    value: affiliation.id,
                                  };
                                }
                              )
                            : []
                        }
                        className="affiliation-select"
                      />
                    </GVFormGroup>
                    <GVFormGroup>
                      <StarPicker
                        label="Expedia Stars"
                        inEditMode={inEditMode}
                        targetName="hotel_attribute__expedia_star"
                        value={
                          inputFields.hotel_attribute__expedia_star.inputValue
                        }
                        onChange={changeStarPicker}
                        options={expedia_star_option}
                      />
                    </GVFormGroup>
                    <DropdownOptions
                      label="Market Segment"
                      inEditMode={inEditMode}
                      targetName="hotel_attribute__market_segment"
                      value={
                        inputFields.hotel_attribute__market_segment.inputValue
                      }
                      onChange={changeDropdownInputField}
                      options={siteFacilityOptions.market_segments}
                    />
                    <DropdownOptions
                      label="Hotel Type"
                      inEditMode={inEditMode}
                      targetName="hotel_attribute__hotel_type"
                      value={inputFields.hotel_attribute__hotel_type.inputValue}
                      onChange={changeDropdownInputField}
                      options={siteFacilityOptions.hotel_types}
                    />
                    <DropdownOptions
                      label="Service Type / Asset Class"
                      inEditMode={inEditMode}
                      targetName="hotel_attribute__asset_class"
                      value={inputFields.hotel_attribute__asset_class.inputValue}
                      onChange={changeDropdownInputField}
                      options={siteFacilityOptions.asset_classes}
                    />
                    <DropdownOptions
                      label="Main Laundry Wash Location"
                      inEditMode={inEditMode}
                      targetName="hotel_attribute__laundry_location"
                      value={
                        inputFields.hotel_attribute__laundry_location.inputValue
                      }
                      onChange={changeDropdownInputField}
                      options={siteFacilityOptions.laundry_locations}
                    />
                    <GVFormGroup>
                      <Form.Label>Room Count</Form.Label>
                      {inEditMode ? (
                        <GVDSFormField
                          name="hotel_attribute__room_count"
                          type="number"
                          value={
                            inputFields.hotel_attribute__room_count.inputValue
                          }
                          onInput={(value, e) => changeTextInputField(e)}
                        />
                      ) : (
                        <Form.Control
                          readOnly
                          plaintext
                          name="hotel_attribute__room_count"
                          type="number"
                          value={
                            inputFields.hotel_attribute__room_count.inputValue ||
                            ""
                          }
                        />
                      )}
                    </GVFormGroup>
                  </>
                )}
              </div>
            </article>
          )}
        </section>
      </Form>
      {inEditMode && (
        <div className="floating-action-container">
          <div className="d-flex justify-content-end">
            <GVDSButton
              className="me-3"
              variant={buttonVariant.tertiary}
              disabled={!inEditMode}
              onClick={cancelChanges}
              text="Cancel"
            />
            <GVDSButton
              className="me-3"
              variant={buttonVariant.primary}
              disabled={!inEditMode}
              onClick={saveChanges}
              text="Save Changes"
            />
          </div>
        </div>
      )}
      <BeforeUrlChangesPrompt
        when={hasChanges}
        pageName="Facility Details Input Page"
        message="You have unsaved changes in the form. Your data will be cleared and won’t be stored when you leave this page. Are you sure?"
      />
      <GVDSModal
        title="Delete Facility"
        size={GVDSModal.Size.small}
        show={showDeletePrompt}
        onHide={cancelDeletePrompt}
      >
        <GVDSModal.Body>
          <div>
            Deleting a facility will delete all environmental and operational
            data at that facility. This action cannot be undone. Are you sure?
          </div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={cancelDeletePrompt}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={() => deleteFacility()}
            disabled={isLoading}
            text="Yes, Delete"
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </Container>
  );
};

export default ViewFacilityDetails;
