import React, { useContext, useState } from "react";
import ModuleNav from "./SideNavComponents/ModuleNav";
import SideNavBottom from "./SideNavComponents/SideNavBottom";
import Button from "react-bootstrap/Button";
import SystemToolboxNav from "./SideNavComponents/SystemToolboxNav";
import { useHistory, useLocation } from "react-router-dom";
import { HOME } from "../../config/ROUTES_NAME";
import { getRedirectURLWithCurrentParam } from "../../components/common/QueryHandler";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import GVDSIconSlim from "../Icons/GVDSIconSlim";
import NavWrapperContext from "./NavWrapperContext";
import SideNavTop from "./SideNavComponents/SideNavTop";
import { PERMISSIONS } from "../../config/constants";
import PermissionsContext from "../../context/PermissionsContext";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const ON_HIDE_MENU_NO_HOVER_BUFFER_IN_MILISECONDS = 300;

const SideNav = ({ isMinimised }) => {
  const permissionsCtx = useContext(PermissionsContext);
  const navWrapperContext = useContext(NavWrapperContext);

  const [isOnHover, setIsOnHover] = useState(false);
  const [onHideMenuLastUpdated, setOnHideMenuLastUpdated] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const redirectTo = (path) => {
    history.push(getRedirectURLWithCurrentParam(path, location));
  };

  const classNames = ["gvds-side-nav"];

  const isInHoverBufferAfterHideMenu =
    onHideMenuLastUpdated &&
    new Date().getTime() - onHideMenuLastUpdated.getTime() <=
      ON_HIDE_MENU_NO_HOVER_BUFFER_IN_MILISECONDS;
  const shouldMinimisedSideNav =
    (isMinimised && !isOnHover) || isInHoverBufferAfterHideMenu;

  if (shouldMinimisedSideNav) {
    classNames.push("minimised");
  }
  const combinedClassName = classNames.join(" ");

  let content;
  if (navWrapperContext.isSystemToolbox) {
    if (permissionsCtx.isLoadingPermissions) {
      content = (
        <>
          <Button
            variant="link"
            className="gvds-side-nav--return-from-system-toolbox"
            onClick={() => {
              redirectTo(HOME);
            }}
          >
            <div className="back-icon">
              <GVDSIconSlim Icon={IconArrowNarrowLeft} />
            </div>
            Exit System Toolbox
          </Button>
          <LoadingSpinner />
        </>
      );
    } else if (permissionsCtx.permissions[PERMISSIONS.PORTAL_ADMIN]) {
      content = (
        <>
          <Button
            variant="link"
            className="gvds-side-nav--return-from-system-toolbox"
            onClick={() => {
              redirectTo(HOME);
            }}
          >
            <div className="back-icon">
              <GVDSIconSlim Icon={IconArrowNarrowLeft} />
            </div>
            Exit System Toolbox
          </Button>
          <SystemToolboxNav />
        </>
      );
    } else {
      redirectTo(HOME);
    }
  } else {
    content = (
      <>
        <div className="gvds-side-nav--top-and-module-container guided-tour-target__new-look__side-nav-modules">
          <SideNavTop
            isMinimised={shouldMinimisedSideNav}
            onHideMenu={() => {
              setIsOnHover(false);
              setOnHideMenuLastUpdated(new Date());
            }}
          />
          <ModuleNav isSideNavMinimised={shouldMinimisedSideNav} />
        </div>
        <SideNavBottom isMinimised={shouldMinimisedSideNav} />
      </>
    );
  }

  return (
    <div
      className={combinedClassName}
      onMouseEnter={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
    >
      <div className="gvds-side-nav__content_container">{content}</div>
    </div>
  );
};

export default SideNav;
