import GVDSFormSingleYearPicker from "../../../gvds-components/Forms/GVDSFormSingleYearPicker";
import React, { forwardRef, useState } from "react";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconChevronDown } from "@tabler/icons-react";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import Spacer from "../../../gvds-components/Layout/Spacer";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import range from "lodash/range";
import DashboardFilterMonthGridSelection from "./DashboardFilterMonthGridSelection";
import { UtilsService } from "../../../services/UtilsService";

const allMonthNumbers = range(1, 13);

const MonthSelectionTrigger = forwardRef(
  ({ onClick, isFilterApplied }, ref) => (
    <div
      className="dashboard-filter__comparison-time-filter__trigger"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="dashboard-filter__text-display-with-indicator">
        {isFilterApplied && (
          <span className="dashboard-filter__applied-filter-indicator" />
        )}
        Months
      </div>
      <GVDSIcon Icon={IconChevronDown} />
    </div>
  )
);

const ComparisonTimeFilterView = ({
  selectedMonths,
  year1,
  year2,
  onSaveYears,
  onSaveMonthsSelection,
}) => {
  const [show, setShow] = useState(false);
  const handleToggle = (isShow) => {
    setShow(isShow);
    resetToPreviousSavedValue();
  };

  const [inputSelectedMonths, setInputSelectedMonths] =
    useState(selectedMonths);

  const resetToPreviousSavedValue = () => {
    setInputSelectedMonths(selectedMonths);
  };

  const onMonthSelection = (monthNumber) => {
    setInputSelectedMonths(
      UtilsService.toggleItem(inputSelectedMonths, monthNumber)
    );
  };

  const handleSelectAll = () => {
    setInputSelectedMonths(allMonthNumbers);
  };

  const handleClearAll = () => {
    setInputSelectedMonths([]);
  };

  const handleClose = () => {
    setShow(false);
    resetToPreviousSavedValue();
  };

  const handleSave = () => {
    onSaveMonthsSelection(inputSelectedMonths);
    setShow(false);
  };

  const onYear1Change = (newYear1) => {
    onSaveYears(newYear1, year2);
  };

  const onYear2Change = (newYear2) => {
    onSaveYears(year1, newYear2);
  };

  const isSelectAllDisabled = inputSelectedMonths.length === 12;
  const isClearAllDisabled = inputSelectedMonths.length === 0;
  const hasExcludedMonths = selectedMonths.length < allMonthNumbers.length;

  const hasChanges = () => {
    return (
      JSON.stringify([...selectedMonths].sort()) !==
      JSON.stringify([...inputSelectedMonths].sort())
    );
  };

  return (
    <div className="dashboard-filter__comparison-time-filter__container">
      <GVDSFormSingleYearPicker
        className="dashboard-filter__comparison-time-filter__year-picker--left"
        selected={year1}
        onChange={onYear1Change}
      />
      <GVDSFormSingleYearPicker
        className="dashboard-filter__comparison-time-filter__year-picker--right"
        selected={year2}
        onChange={onYear2Change}
      />
      <Dropdown align="start" drop="down" show={show} onToggle={handleToggle}>
        <Dropdown.Toggle
          as={MonthSelectionTrigger}
          isFilterApplied={hasExcludedMonths}
        />
        <Dropdown.Menu
          className="dashboard-filter__comparison-time-filter__dropdown-menu"
          flip={false}
        >
          <div>
            <div className="dashboard-filter__comparison-time-filter__dropdown-heading">
              <div className="gvds-text--heading4">Months</div>
              <InfoTooltip info="Select or deselect months to be included for the chosen year." />
            </div>
            <div>
              <div className="dashboard-filter__comparison-time-filter__month-grid__action-button">
                <GVDSTextButton
                  onClick={handleSelectAll}
                  disabled={isSelectAllDisabled}
                >
                  Select all
                </GVDSTextButton>
                <GVDSTextButton
                  onClick={handleClearAll}
                  disabled={isClearAllDisabled}
                >
                  Clear all
                </GVDSTextButton>
              </div>
              <DashboardFilterMonthGridSelection
                enabledMonthNumbers={allMonthNumbers}
                selectedMonthNumbers={inputSelectedMonths}
                onMonthSelect={onMonthSelection}
              />
              <div className="dashboard-filter__comparison-time-filter__dropdown-footer">
                {hasChanges() && (
                  <div className="gvds-text--modal-warning-text">
                    Changes won't be applied until you press Save
                  </div>
                )}
              </div>
              <div className="dashboard-filter__comparison-time-filter__dropdown-footer">
                <Spacer />
                <GVDSButton
                  onClick={handleClose}
                  variant={buttonVariant.tertiary}
                  text="Cancel"
                />
                <GVDSButton
                  onClick={handleSave}
                  variant={buttonVariant.primary}
                  text="Save"
                />
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ComparisonTimeFilterView;
