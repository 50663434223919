import axios from "axios";
import { API_URL } from "../config/api-config";
import { RESOURCES } from "../config/constants";

class FacilityInventoryModel {
  constructor(
    id,
    name,
    type,
    location,
    main_attribute,
    features,
    subscriptions
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.location = location;
    this.main_attribute = main_attribute;
    this.features = features;
    this.subscriptions = subscriptions;
    this.resource_type = RESOURCES.FACILITY;
  }

  static fromDTOAndSiteData(
    facilityInventoryDTO,
    siteFeatures,
    siteSubscriptions
  ) {
    return new FacilityInventoryModel(
      facilityInventoryDTO["id"],
      facilityInventoryDTO["name"],
      facilityInventoryDTO["type"],
      facilityInventoryDTO["location"],
      facilityInventoryDTO["main_attribute"],
      siteFeatures,
      siteSubscriptions
    );
  }
}

class SiteInventoryModel {
  constructor(
    id,
    name,
    resource_type,
    type,
    location,
    main_attribute,
    main_facility_id,
    all_facilities,
    features,
    is_part_of_contract,
    subscriptions,
    billing_admin_id
  ) {
    this.id = id;
    this.name = name;
    this.resource_type = resource_type;
    this.type = type;
    this.location = location;
    this.main_attribute = main_attribute;
    this.all_facilities = all_facilities.map((f) =>
      FacilityInventoryModel.fromDTOAndSiteData(f, features, subscriptions)
    );
    this.main_facility = this.all_facilities.find(
      (f) => f.id === main_facility_id
    );
    this.features = features;
    this.is_part_of_contract = is_part_of_contract;
    this.subscriptions = subscriptions;
    this.billing_admin_id = billing_admin_id;
    this.resource_type = RESOURCES.SITE;
  }

  static fromDTO(siteInventoryDTO) {
    return new SiteInventoryModel(
      siteInventoryDTO["id"],
      siteInventoryDTO["name"],
      siteInventoryDTO["resource_type"],
      siteInventoryDTO["type"],
      siteInventoryDTO["location"],
      siteInventoryDTO["main_attribute"],
      siteInventoryDTO["main_facility_id"],
      siteInventoryDTO["all_facilities"],
      siteInventoryDTO["features"],
      siteInventoryDTO["is_part_of_contract"],
      siteInventoryDTO["subscriptions"],
      siteInventoryDTO["billing_admin_id"]
    );
  }
}

class PortfolioInventoryModel {
  constructor(
    id,
    name,
    facilities,
    sites,
    description,
    features,
    subscriptions,
    billing_admin_id
  ) {
    this.id = id;
    this.name = name;
    this.facilities = facilities;
    this.sites = sites;
    this.description = description;
    this.features = features;
    this.subscriptions = subscriptions;
    this.billing_admin_id = billing_admin_id;
    this.resource_type = RESOURCES.PORTFOLIO;
  }

  static fromDTOAndContractData(
    portfolioInventoryDTO,
    features,
    subscriptions,
    billing_admin_id,
    siteDTOById,
    facilityDTOById
  ) {
    const sites = portfolioInventoryDTO["site_ids"]
      .filter((sId) => sId in siteDTOById)
      .map((sId) => siteDTOById[sId]);
    const facilities = portfolioInventoryDTO["facility_ids"]
      .filter((fId) => fId in facilityDTOById)
      .map((fId) => facilityDTOById[fId]);

    return new PortfolioInventoryModel(
      portfolioInventoryDTO["id"],
      portfolioInventoryDTO["name"],
      facilities,
      sites,
      portfolioInventoryDTO["description"],
      features,
      subscriptions,
      billing_admin_id
    );
  }
}

export class UserInventoryService {
  static getUserInventory = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/inventory/user`);

      const inventory = response.data;

      inventory.sites = inventory.sites.map((s) =>
        SiteInventoryModel.fromDTO(s)
      );

      return inventory;
    } catch (error) {
      throw error;
    }
  };

  static getContractInventoryDetails = async (contractId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/inventory/contract/${contractId}`
      );
      const contractInventory = response.data;

      contractInventory.sites = contractInventory.sites.map((s) =>
        SiteInventoryModel.fromDTO(s)
      );

      const siteDTOById = contractInventory.sites.reduce(
        (siteDTOByIdAccumulator, currentSiteDTO) => {
          siteDTOByIdAccumulator[currentSiteDTO.id] = currentSiteDTO;
          return siteDTOByIdAccumulator;
        },
        {}
      );

      const facilityDTOById = {};

      contractInventory.sites.forEach((s) =>
        s.all_facilities.forEach((f) => (facilityDTOById[f.id] = f))
      );

      contractInventory.portfolios = contractInventory.portfolios.map((p) =>
        PortfolioInventoryModel.fromDTOAndContractData(
          p,
          contractInventory.features,
          contractInventory.subscriptions,
          contractInventory.billing_admin_id,
          siteDTOById,
          facilityDTOById
        )
      );

      return contractInventory;
    } catch (error) {
      throw error;
    }
  };

  static getInactiveSites = async (siteIds) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/admin/inventory/inactive_sites`,
        { site_ids: siteIds }
      );
      return response.data.map((s) => SiteInventoryModel.fromDTO(s));
    } catch (error) {
      throw error;
    }
  };
}
