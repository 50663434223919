export const float_precision = 2;
export const MB_TO_BYTES = 1048576;
export const new_entry_prefix = "new-";
export const tobe_deleted_entry_prefix = "del-";

export const OLDEST_YEAR_IN_PORTAL = 2007 - 1; // to account for records spanning from end of 2006
export const DEFAULT_MAX_FILE_SIZE_IN_MB = 40;

export const DISALLOWED_CHARACTERS_IN_EXCEL_REGEX =
  /[^a-zA-Z0-9-& ()!#$%+,-./:;<=>?@\[^`{|}~'\]*"]/;

export const smoothScrollBehaviour = { behavior: "smooth", block: "center" };

export const DEFAULT_OPERATION_METER_NAME = "Default Meter";

export const MAIN_PORTFOLIO = "Main Portfolio";

export const TYPE_HOTEL_ALL_LOWER = "hotel / accommodation";

export const RESOURCES = {
  PORTAL: "portal",
  CONTRACT: "contract",
  SITE: "site",
  FACILITY: "facility",
  PORTFOLIO: "portfolio",
  ENVIRONMENTAL_METER: "environmental_meter",
  MARKET_PURCHASE_OFFSETS_TRACKER: "market_purchase_offsets_tracker",
  OPERATIONAL_METER: "operational_meter",
  PEOPLE_METER: "people_meter",
};

export const PERMISSIONS = {
  CONTRACT_CREATE: "contract:create",
  CONTRACT_EDIT: "contract:edit",
  CONTRACT_VIEW: "contract:view",
  CONTRACT_VIEW_ALL: "contract:view-all",
  CONTRACT_USER_MANAGEMENT: "contract:user-management",
  CONTRACT_APPROVED_DOMAINS: "contract:approved-domains",
  CONTRACT_PORTFOLIO_MANAGEMENT: "contract:portfolio-management",

  SITE_CREATE: "site:create",
  SITE_EDIT: "site:edit",
  SITE_VIEW: "site:view",
  SITE_VIEW_ALL: "site:view-all",
  SITE_USER_MANAGEMENT: "site:user-management",
  SITE_APPROVED_DOMAINS: "site:approved-domains",
  SITE_FACILITY_MANAGEMENT: "site:facility-management",

  ENVIRONMENTAL_METER_MANAGEMENT: "env_meter:management",
  ENVIRONMENTAL_RECORD_CREATE: "env_record:create",
  ENVIRONMENTAL_RECORD_VIEW: "env_record:view",
  ENVIRONMENTAL_RECORD_EDIT: "env_record:edit",
  ENVIRONMENTAL_RECORD_DELETE: "env_record:delete",

  MARKET_PURCHASE_OFFSETS_TRACKER_MANAGEMENT:
    "market_purchase_offsets_meter:management",
  MARKET_PURCHASE_OFFSETS_RECORD_CREATE:
    "market_purchase_offsets_record:create",
  MARKET_PURCHASE_OFFSETS_RECORD_VIEW: "market_purchase_offsets_record:view",
  MARKET_PURCHASE_OFFSETS_RECORD_EDIT: "market_purchase_offsets_record:edit",
  MARKET_PURCHASE_OFFSETS_RECORD_DELETE:
    "market_purchase_offsets_record:delete",

  OPERATIONAL_METER_MANAGEMENT: "ops_meter:management",
  OPERATIONAL_RECORD_CREATE: "ops_record:create",
  OPERATIONAL_RECORD_VIEW: "ops_record:view",
  OPERATIONAL_RECORD_EDIT: "ops_record:edit",
  OPERATIONAL_RECORD_DELETE: "ops_record:delete",

  PEOPLE_METER_MANAGEMENT: "people_meter:management",
  PEOPLE_RECORD_CREATE: "people_record:create",
  PEOPLE_RECORD_VIEW: "people_record:view",
  PEOPLE_RECORD_EDIT: "people_record:edit",
  PEOPLE_RECORD_DELETE: "people_record:delete",

  INITIATIVE_MANAGEMENT: "initiative:management",
  INITIATIVE_RECORD_CREATE: "initiative_record:create",
  INITIATIVE_RECORD_VIEW: "initiative_record:view",
  INITIATIVE_RECORD_EDIT: "initiative_record:edit",
  INITIATIVE_RECORD_DELETE: "initiative_record:delete",

  SURVEY_MANAGEMENT: "survey:management",
  SURVEY_ANSWER: "survey:answer",
  SURVEY_VIEW: "survey:view",

  BEST_PRACTICE_ANSWER: "best_practice:answer",
  BEST_PRACTICE_VIEW: "best_practice:view",

  GOALS_N_TARGETS_CREATE: "goals_n_targets:create",
  GOALS_N_TARGETS_VIEW: "goals_n_targets:view",
  GOALS_N_TARGETS_EDIT: "goals_n_targets:edit",
  GOALS_N_TARGETS_DELETE: "goals_n_targets:delete",

  USER_VIEW_ALL: "user:view-all",
  COMPANY_VIEW_ALL: "company:view-all",
  BEST_PRACTICE_QUESTION_ADMIN: "best_practice_question:admin",
  GUIDANCE_ARTICLE_ADMIN: "guidance_article:admin",

  EMISSION_UPLOAD: "emission:upload",
  EMISSION_DOWNLOAD: "emission:download",
  REF_DATA_CREATE: "ref_data:create",
  REF_DATA_EDIT: "ref_data:edit",
  REF_DATA_VIEW: "ref_data:view",
  REF_DATA_DELETE: "ref_data:delete",
  BENCHMARK_UPLOAD: "benchmark:upload",
  POLICY_MASTERSHEET: "policy_mastersheet:management",

  ONBOARDING_ALL: "onboarding:all",
  HELP_ARTICLE_ADMIN: "help_article:admin",

  SMS_CHAPTER_CREATE: "sms_chapter:create",
  SMS_ACTION_CREATE: "sms_action:create",
  SMS_ACTION_EDIT: "sms_action:edit",

  ANNOUNCEMENT_ADMIN: "announcement:admin",
  SIGNUP_ADMIN: "sign_up:admin",

  FILE_STORAGE_CREATE: "file_storage:create",
  FILE_STORAGE_VIEW: "file_storage:view",
  FILE_STORAGE_EDIT: "file_storage:edit",
  FILE_STORAGE_DELETE: "file_storage:delete",

  TASK_MANAGEMENT_CREATE: "task_management:create",
  TASK_MANAGEMENT_VIEW: "task_management:view",
  TASK_MANAGEMENT_EDIT: "task_management:edit",
  TASK_MANAGEMENT_DELETE: "task_management:delete",

  USER_MANAGED_TAG_CREATE: "user_managed_tag:create",
  USER_MANAGED_TAG_VIEW: "user_managed_tag:view",
  USER_MANAGED_TAG_EDIT: "user_managed_tag:edit",
  USER_MANAGED_TAG_DELETE: "user_managed_tag:delete",

  CERTIFICATION_ASSESSMENT_VIEW: "certification_assessment:view",
  CERTIFICATION_ASSESSMENT_INPUT: "certification_assessment:input",
  CERTIFICATION_ASSESSMENT_DELETE: "certification_assessment:delete",
  CERTIFICATION_ASSESSMENT_DELETE_OTHERS:
    "certification_assessment:delete-others",

  PORTAL_ADMIN: "portal:admin",
};

export const FEATURES = {
  DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL:
    "data_management:environmental_operational",
  DATA_MANAGEMENT__PEOPLE: "data_management:people",
  DATA_MANAGEMENT__INITIATIVE: "data_management:initiative",

  SURVEY__CREATE: "survey:create",
  SURVEY__ANSWER: "survey:answer",

  BEST_PRACTICES__ASSESSMENT: "best_practices:assessment",
  BEST_PRACTICES__GUIDANCE: "best_practices:guidance",

  REPORT__HCMI: "report:hcmi",
  REPORT__STANDARD: "report:standard",
  REPORT__PERFORMANCE: "report:performance",
  REPORT__PERFORMANCE_BENCHMARK: "report:performance_benchmark",
  REPORT__INITIATIVES: "report:initiatives",
  REPORT__BEST_PRACTICES: "report:best_practices",
  REPORT__RISK_ASSESSMENT: "report:risk_assessment",

  GSTC: "gstc:guidance",
  GOALS_N_TARGETS: "goals_n_targets",
  FILE_STORAGE: "file_storage",
  TASK_MANAGEMENT: "task_management",
  POLICY_TRACKER: "policy_tracker",
  CERTIFICATIONS: "certifications",
  DASHBOARDS: "dashboards",
};

const FEATURE_ORDER_LIST = [
  FEATURES.DASHBOARDS,

  FEATURES.DATA_MANAGEMENT__ENVIRONMENTAL_OPERATIONAL,
  FEATURES.DATA_MANAGEMENT__PEOPLE,
  FEATURES.DATA_MANAGEMENT__INITIATIVE,

  FEATURES.REPORT__BEST_PRACTICES,
  FEATURES.REPORT__HCMI,
  FEATURES.REPORT__INITIATIVES,
  FEATURES.REPORT__PERFORMANCE,
  FEATURES.REPORT__PERFORMANCE_BENCHMARK,
  FEATURES.REPORT__RISK_ASSESSMENT,
  FEATURES.REPORT__STANDARD,

  FEATURES.GOALS_N_TARGETS,
  FEATURES.GSTC,
  FEATURES.CERTIFICATIONS,
  FEATURES.BEST_PRACTICES__ASSESSMENT,
  FEATURES.BEST_PRACTICES__GUIDANCE,
  FEATURES.POLICY_TRACKER,
  FEATURES.SURVEY__CREATE,
  FEATURES.SURVEY__ANSWER,
  FEATURES.FILE_STORAGE,
  FEATURES.TASK_MANAGEMENT,
];

export const FEATURE_ORDER = FEATURE_ORDER_LIST.reduce(
  (featureToSortOrder, featureInList, featureIndex) => {
    featureToSortOrder[featureInList] = featureIndex;

    return featureToSortOrder;
  },
  {}
);

export const TOAST_TYPE = {
  SUCCESS: "success",
  FAIL: "fail",
};

export const CONTRACT_STATUS = {
  DRAFT: "draft",
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const SITE_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

// region Users
export const DISPLAY_USER_ROLES = {
  ADMIN: "Admin",
  CONTRIBUTOR: "Contributor",
  VIEWER: "Viewer",
};

export const USER_ROLES = {
  PORTAL_ADMIN: "Portal Admin",
  CONTRACT_ADMIN: "Contract Admin",
  CONTRACT_VIEWER: "Contract Viewer",
  SITE_ADMIN: "Site Admin",
  SITE_CONTRIBUTOR: "Site Contributor",
  SITE_CONTRIBUTOR_LIMITED: "Site Contributor - Limited",
  SITE_VIEWER: "Site Viewer",
};

const USER_ROLE_ORDER_LIST = [
  USER_ROLES.PORTAL_ADMIN,
  USER_ROLES.CONTRACT_ADMIN,
  USER_ROLES.CONTRACT_VIEWER,
  USER_ROLES.SITE_ADMIN,
  USER_ROLES.SITE_CONTRIBUTOR,
  USER_ROLES.SITE_CONTRIBUTOR_LIMITED,
  USER_ROLES.SITE_VIEWER,
];

export const USER_ROLE_ORDER = USER_ROLE_ORDER_LIST.reduce(
  (userRoleToSortOrder, userRoleInList, userRoleIndex) => {
    userRoleToSortOrder[userRoleInList] = userRoleIndex;

    return userRoleToSortOrder;
  },
  {}
);

export const USER_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  AWAIT_LOGIN: "Await Login",
  PASSWORD_RESET: "Password Reset",
  NONEXISTENT: "Nonexistent",
};

export const USER_APPROVAL_REQUEST_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
};
// endregion

export const REPORT_TIME_FRAMES = {
  THIS_MONTH: "This month",
  THIS_QUARTER: "This quarter",
  THIS_YEAR: "This year",
  LAST_MONTH: "Last month",
  LAST_QUARTER: "Last quarter",
  LAST_YEAR: "Last year",
  CUSTOM: "Custom",
};

export const REPORT_TYPE = {
  HCMI: "HCMI",
  STANDARD_REPORT: "Standard",
  PERFORMANCE: "Performance",
  INITIATIVES: "Initiatives",
  BEST_PRACTICE: "Best Practice",
  RISK_ASSESSMENT: "Risk Assessment",
};

// region Initiatives
export const INITIATIVE_SUBTOPIC = {
  EFFICIENCY_AND_ENVIRONMENT: "Efficiency and Environment",
  SOCIAL_IMPACT: "Social Impact",
  PROCUREMENT: "Procurement",
  OTHER: "Other",
};

export const INITIATIVE_SUBTOPIC_ORDER = {
  [INITIATIVE_SUBTOPIC.EFFICIENCY_AND_ENVIRONMENT]: 1,
  [INITIATIVE_SUBTOPIC.SOCIAL_IMPACT]: 2,
  [INITIATIVE_SUBTOPIC.PROCUREMENT]: 3,
  [INITIATIVE_SUBTOPIC.OTHER]: 4,
};

export const INITIATIVE_STATUS = {
  PLANNED: "Planned",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
};

export const INITIATIVE_TRACKING_CRITERION = {
  ACCOUNTING_INFORMATION: "Accounting Information", // Label as Financial Impact
  INKIND_DONATION: "In-Kind Donation",
  CASH_CONTRIBUTION: "Cash Contribution",
  VOLUNTEER_EVENT: "Volunteer Event",
  EFFICIENCY_SAVINGS: "Efficiency Savings",
  OTHER: "Other",
};

export const INITIATIVE_TRACKING_CRITERION_ORDER = {
  // any other criterion should be positioned as is, aside from accounting information and other
  [INITIATIVE_TRACKING_CRITERION.OTHER]: 1,
  [INITIATIVE_TRACKING_CRITERION.ACCOUNTING_INFORMATION]: 2,
};

export const INITIATIVE_TRACKING_FIELD = {
  SOURCE: "source", // identifier
  DESCRIPTOR: "descriptor", // identifier
  CURRENCY: "currency",
  UNIT: "unit",
  QUANTITY: "quantity", // value
  VALUE: "value", // value
  PARTICIPANT: "participant", // value
  VOLUNTEER_HOURS: "volunteer hours", // value
  VOLUNTEER_HOURLY_RATE: "volunteer hourly rate", // value
  ENV_TYPE: "env type", //identifier
  ANNUAL_REDUCTION: "annual reduction", // value
  COMMENT: "comment", // Label as Tracking Notes, value
};

export const INITIATIVE_FIELD_ORDER = [
  INITIATIVE_TRACKING_FIELD.SOURCE,
  INITIATIVE_TRACKING_FIELD.DESCRIPTOR,
  INITIATIVE_TRACKING_FIELD.ENV_TYPE,
  INITIATIVE_TRACKING_FIELD.QUANTITY,
  INITIATIVE_TRACKING_FIELD.ANNUAL_REDUCTION,
  INITIATIVE_TRACKING_FIELD.UNIT,
  INITIATIVE_TRACKING_FIELD.VALUE,
  INITIATIVE_TRACKING_FIELD.PARTICIPANT,
  INITIATIVE_TRACKING_FIELD.VOLUNTEER_HOURS,
  INITIATIVE_TRACKING_FIELD.VOLUNTEER_HOURLY_RATE,
  INITIATIVE_TRACKING_FIELD.CURRENCY,
  INITIATIVE_TRACKING_FIELD.COMMENT,
];

export const INITIATIVE_EXCEPTION_SOURCE = {
  ENERGY: "Energy",
};

export const INITIATIVE_ACCOUNTING_INFORMATION_SOURCE_ORDER = {
  "Gross Investment": 0,
  Rebates: 1,
  Incentives: 2,
  "Tax-Deductible Amount": 3,
};
// endregion

// region Best Practice
export const BEST_QUESTION_CATEGORIES = {
  WATER: "Water",
  ENERGY: "Energy",
  WASTE: "Waste",
  HEALTH_N_WELLNESS: "Health & Wellness",
  SOURCING: "Sourcing",
  CLIMATE_ACTION: "Climate Action",
  SOCIAL_IMPACT: "Social Impact",
  OTHER: "Other",
};

export const BEST_QUESTION_CATEGORY_ORDER = {
  [BEST_QUESTION_CATEGORIES.ENERGY]: 0,
  [BEST_QUESTION_CATEGORIES.WASTE]: 1,
  [BEST_QUESTION_CATEGORIES.WATER]: 2,
  [BEST_QUESTION_CATEGORIES.CLIMATE_ACTION]: 3,
  [BEST_QUESTION_CATEGORIES.HEALTH_N_WELLNESS]: 4,
  [BEST_QUESTION_CATEGORIES.SOURCING]: 5,
  [BEST_QUESTION_CATEGORIES.SOCIAL_IMPACT]: 6,
};

export const BEST_PRACTICE_QUESTION_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const BEST_PRACTICE_QUESTION_TYPE = {
  MULTIPLE_CHOICE: "multiple_choice",
  CHECKBOXES: "checkboxes",
};

export const BEST_PRACTICE_ADOPTION_LEVEL = {
  YES: "Yes",
  IN_PROGRESS: "In Progress",
  NO: "No",
  NA: "N/A",
  NO_RESPONSE: "No Response",
};

export const BEST_PRACTICE_ADOPTION_LEVEL_PRECEDENCE = {
  [BEST_PRACTICE_ADOPTION_LEVEL.NA]: 0,
  [BEST_PRACTICE_ADOPTION_LEVEL.NO]: 1,
  [BEST_PRACTICE_ADOPTION_LEVEL.IN_PROGRESS]: 2,
  [BEST_PRACTICE_ADOPTION_LEVEL.YES]: 3,
};

export const QUESTION_ANSWER_STATUS = {
  YES: "Yes",
  NO: "No",
};
// endregion

export const GUIDANCE_ARTICLE_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

// region Survey
export const SURVEY_STATUS = {
  DRAFT: "draft",
  OPEN: "open",
  CLOSED: "closed",
};

export const SURVEY_STATUS_ORDER = {
  [SURVEY_STATUS.OPEN]: 0,
  [SURVEY_STATUS.CLOSED]: 1,
  [SURVEY_STATUS.DRAFT]: 2,
};

export const SURVEY_QUESTION_TYPE = {
  MULTIPLE_CHOICE: "multiple_choice",
  CHECKBOXES: "checkboxes",
  SHORT_ANSWER: "short_answer",
  QUESTION_GROUP: "question_group",
  BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE:
    "best_practice_question_multiple_choice",
  BEST_PRACTICE_QUESTION_CHECKBOXES: "best_practice_question_checkboxes",
};

export const SURVEY_QUESTION_TYPE_LABEL = {
  [SURVEY_QUESTION_TYPE.MULTIPLE_CHOICE]: "Multiple Choice",
  [SURVEY_QUESTION_TYPE.CHECKBOXES]: "Checkboxes",
  [SURVEY_QUESTION_TYPE.SHORT_ANSWER]: "Short Answer",
  [SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_MULTIPLE_CHOICE]:
    "Best Practice Question",
  [SURVEY_QUESTION_TYPE.BEST_PRACTICE_QUESTION_CHECKBOXES]:
    "Best Practice Question",
  [SURVEY_QUESTION_TYPE.QUESTION_GROUP]: "Question Group",
};
// endregion

export const FREQUENCY_OPTIONS = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "quarterly",
    label: "Quarterly",
  },
  {
    value: "annually",
    label: "Annually",
  },
];

export const SUBTOPIC_TYPE = {
  ENERGY: "Energy",
  EMISSIONS: "Emissions",
  WATER: "Water",
  WASTE: "Waste & Recycling",
  REFRIGERANTS: "Refrigerants",
};

export const SUBTOPIC_TYPE_ORDER = {
  [SUBTOPIC_TYPE.ENERGY]: 0,
  [SUBTOPIC_TYPE.WATER]: 1,
  [SUBTOPIC_TYPE.WASTE]: 2,
  [SUBTOPIC_TYPE.REFRIGERANTS]: 3,
  [SUBTOPIC_TYPE.EMISSIONS]: 4,
};

export const DATA_STATUS = {
  COMPLETE: "Complete",
  MISSING: "Missing",
  ESTIMATED: "Estimated",
  NA: "N/A",
  NS: "Not Setup",
  UNUSED: "Unused",
  EXCLUDED: "Excluded",
  CONSTANT: "Constant",
};

export const COMPANY_TYPE = {
  BRAND: "Brand",
  MANAGEMENT: "Management",
  ASSET_OWNER: "Asset Owner",
};

export const OPERATIONAL_METER_TYPE_FLOAT = {
  AVG_WORKERS: "Average Workers on Main Shift",
  AVG_FTE: "Average Monthly FTEs",
};

export const HELP_ARTICLE_CATEGORY = {
  GETTING_STARTED_WITH_THE_GREENVIEW_PORTAL:
    "Getting started with the Greenview Portal",
  SETTING_UP_AND_MANAGING_YOUR_SITE: "Setting up and managing your site",
  INPUTTING_YOUR_DATA: "Inputting your data",
  RUNNING_AND_INTERPRETING_REPORTS: "Running and interpreting reports",
  IMPLEMENTING_BEST_PRACTICES: "Implementing best practices",
  MANAGING_YOUR_TASKS_AND_FILES: "Managing your tasks and files",
  SETTING_GOALS_AND_MONITORING_YOUR_PROGRESS:
    "Setting goals and monitoring your progress",
  ALIGNING_WITH_CERTIFICATIONS: "Aligning with certifications",
  SURVEYS: "Surveys",
  HELPFUL_TIPS_PAST_ISSUES: "Helpful Tips - Past Issues",
};

export const HELP_ARTICLE_CATEGORY_ORDER = [
  HELP_ARTICLE_CATEGORY.GETTING_STARTED_WITH_THE_GREENVIEW_PORTAL,
  HELP_ARTICLE_CATEGORY.SETTING_UP_AND_MANAGING_YOUR_SITE,
  HELP_ARTICLE_CATEGORY.INPUTTING_YOUR_DATA,
  HELP_ARTICLE_CATEGORY.RUNNING_AND_INTERPRETING_REPORTS,
  HELP_ARTICLE_CATEGORY.SETTING_GOALS_AND_MONITORING_YOUR_PROGRESS,
  HELP_ARTICLE_CATEGORY.IMPLEMENTING_BEST_PRACTICES,
  HELP_ARTICLE_CATEGORY.SURVEYS,
  HELP_ARTICLE_CATEGORY.MANAGING_YOUR_TASKS_AND_FILES,
  HELP_ARTICLE_CATEGORY.ALIGNING_WITH_CERTIFICATIONS,
  HELP_ARTICLE_CATEGORY.HELPFUL_TIPS_PAST_ISSUES,
];

export const InfoChapterType = {
  INTRODUCTORY: "Introductory",
  CONCLUDING: "Concluding",
};

export const POSITION = {
  BEFORE: "before",
  AFTER: "after",
};

// region Target
export const TARGET_STATUS = {
  OPEN: "open",
  CLOSED: "closed",
};

export const TARGET_STATUS_ORDER = {
  [TARGET_STATUS.OPEN]: 0,
  [TARGET_STATUS.CLOSED]: 1,
};

export const TARGET_TOPIC = {
  SOCIAL: "social",
  ENVIRONMENTAL: "environmental",
  FREE_FORM: "free-form",
};

export const TARGET_TOPIC_DISPLAY_LABEL = {
  [TARGET_TOPIC.SOCIAL]: "Community & Social Impact",
  [TARGET_TOPIC.ENVIRONMENTAL]: "Environmental Performance",
  [TARGET_TOPIC.FREE_FORM]: "Free-form",
};

export const TARGET_DIRECTION = {
  REDUCTION_OF: "reduction of",
  INCREASE_OF: "increase of",
  EQUAL_TO_OR_MORE_THAN: "equal to or more than",
  EQUAL_TO_OR_LESS_THAN: "equal to or less than",
};

export const TARGET_PERIOD_TYPE = {
  ANNUAL: "annual",
  TOTAL: "total",
};

export const SOCIAL_PERFORMANCE_GROUP_ENUM = {
  CASH_CONTRIBUTION: "Cash Contribution",
  TOTAL_VOLUNTEER_HOURS: "Total Volunteer Hours",
  IN_KIND_DONATION_VALUE: "In-Kind Donation Value",
};

export const TARGET_SPECIAL_UNITS = {
  PERCENTAGE: "%",
  HOURS: "hours",
};
// endregion

export const SIGNUP_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const FILE_MODULES = {
  ENVIRONMENTAL_DATA__ENERGY: "Environmental Data - Energy",
  ENVIRONMENTAL_DATA__WASTE_RECYCLING: "Environmental Data - Waste & Recycling",
  ENVIRONMENTAL_DATA__WATER: "Environmental Data - Water",
  ENVIRONMENTAL_DATA__REFRIGERANTS: "Environmental Data - Refrigerants",
  ENVIRONMENTAL_DATA__MARKET_PURCHASE: "Environmental Data - Market Purchase",
  ENVIRONMENTAL_DATA__OTHER: "Environmental Data - Other",
  OPERATIONAL_DATA: "Operational Data",
  PEOPLE_DATA: "People Data",
  INITIATIVES__SOCIAL_IMPACT: "Initiatives - Social Impact",
  INITIATIVES__EFFICIENCY_ENVIRONMENT: "Initiatives - Efficiency & Environment",
  INITIATIVES__PROCUREMENT: "Initiatives - Procurement",
  INITIATIVES__OTHER: "Initiatives - Other",
  GSTC: "GSTC",
  BEST_PRACTICES: "Best Practices",
  OTHER: "Other",
  CERTIFICATIONS: "Certifications",
};

export const FILE_ASSOCIATED_RESOURCE = {
  INITIATIVES: "INITIATIVES",
  GSTC: "GSTC",
};

export const FILE_ASSOCIATED_RESOURCE_PATH_NAME = {
  INITIATIVES: "/data/initiatives",
  GSTC: "/gstc",
};

export const UNIT_ID = {
  kwh: "kWh",
};

export const TASK_MANAGEMENT_STATUS = {
  NOT_STARTED: "Not Started",
  ACKNOWLEDGED: "Acknowledged",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
};

export const GSTC_SATISFACTORY_OUTCOME_STATUS = {
  YES: "Yes",
  NO: "No",
  NA: "N/A",
};

export const PORTAL_MODULE_LINKS_URL = {
  MY_GREENVIEW: "/",
  DATA_MGMT__OVERVIEW: "/data/overview",
  DATA_MGMT__ENVIRONMENTAL: "/data/environmental",
  DATA_MGMT__OPERATIONAL: "/data/operational",
  DATA_MGMT__PEOPLE: "/data/people",
  DATA_MGMT__INITIATIVES: "/data/initiatives",
  REPORTS__PERFORMANCE: "/report/performance-report",
  REPORTS__STANDARD: "/report/standard-report",
  REPORTS__HCMI_METRIC: "/report/hcmi-metric",
  REPORTS__INITIATIVES: "/report/initiatives-report",
  REPORTS__BEST_PRACTICES: "/report/best-practice-report",
  REPORTS__RISK_ASSESSMENT: "/report/risk-assessment-report",
  GOALS_N_TARGETS: "/goals-and-targets",
  GSTC: "/gstc",
  BEST_PRACTICES__ASSESSMENT: "/best-practices/assess",
  BEST_PRACTICES__GUIDANCE_LIBRARY: "/best-practices/guidance",
  SURVEYS: "/surveys",
  ADMIN__TEAM: "/admin/team",
  ADMIN__SITE_DETAILS: "/admin/site-details",
};

const PORTAL_MODULE_LINKS_URL_LIST = [
  PORTAL_MODULE_LINKS_URL.MY_GREENVIEW,
  PORTAL_MODULE_LINKS_URL.DATA_MGMT__OVERVIEW,
  PORTAL_MODULE_LINKS_URL.DATA_MGMT__ENVIRONMENTAL,
  PORTAL_MODULE_LINKS_URL.DATA_MGMT__OPERATIONAL,
  PORTAL_MODULE_LINKS_URL.DATA_MGMT__PEOPLE,
  PORTAL_MODULE_LINKS_URL.DATA_MGMT__INITIATIVES,
  PORTAL_MODULE_LINKS_URL.REPORTS__BEST_PRACTICES,
  PORTAL_MODULE_LINKS_URL.REPORTS__HCMI_METRIC,
  PORTAL_MODULE_LINKS_URL.REPORTS__INITIATIVES,
  PORTAL_MODULE_LINKS_URL.REPORTS__PERFORMANCE,
  PORTAL_MODULE_LINKS_URL.REPORTS__RISK_ASSESSMENT,
  PORTAL_MODULE_LINKS_URL.REPORTS__STANDARD,
  PORTAL_MODULE_LINKS_URL.GOALS_N_TARGETS,
  PORTAL_MODULE_LINKS_URL.GSTC,
  PORTAL_MODULE_LINKS_URL.BEST_PRACTICES__ASSESSMENT,
  PORTAL_MODULE_LINKS_URL.BEST_PRACTICES__GUIDANCE_LIBRARY,
  PORTAL_MODULE_LINKS_URL.SURVEYS,
  PORTAL_MODULE_LINKS_URL.ADMIN__TEAM,
  PORTAL_MODULE_LINKS_URL.ADMIN__SITE_DETAILS,
];

export const PORTAL_MODULE_LINKS_URL_ORDER =
  PORTAL_MODULE_LINKS_URL_LIST.reduce(
    (portalModuleLinksToSortOrder, portalModuleLink, portalModuleLinkIndex) => {
      portalModuleLinksToSortOrder[portalModuleLink] = portalModuleLinkIndex;

      return portalModuleLinksToSortOrder;
    },
    {}
  );

export const TARGET_NOT_ASSOCIATED_TO_GOAL = "Targets not associated to a goal";
export const HIDE_TARGET_EMISSION_TEXT = null;

export const ASYNC_SYSTEM_TASK_STATUS = {
  QUEUED: "Queued",
  IN_PROGRESS: "In Progress",
  DONE: "Done",
  EXPIRED: "Expired",
  FAIL: "Fail",
};

export const ADVANCED_OR_AND_FILTER = {
  OR: "Or",
  AND: "And",
};

export const ARTICLE_CONTENT_TYPE = {
  PDF: "PDF",
  VIDEO: "Video",
};

export const ARTICLE_CONTENT_MAX_FILE_SIZE_IN_MB = 600;

// region External
export const CONTACT_EMAIL_SUPPORT = "support@greenviewportal.com";
export const CONTACT_EMAIL_SALES = "sales@greenview.sg";
export const CONTACT_EMAIL_SALES_SUBJECT = "Greenview Portal Sign-up Inquiry";

export const ONBOARDING_TEMPLATE_FROM_DROPBOX_LINK =
  "https://www.dropbox.com/scl/fo/11slb4qn0rbkya6wubo6g/h?rlkey=9bqt2iwh5744rccuthumeqdvi&dl=0";

// reference: https://www.alchemysoftware.com/livedocs/ezscript/Topics/Catalyst/Language.htm
export const LANGUAGE_CODES = {
  english: "en",
  chinese_simplified: "zh",
  japanese: "ja",
  spanish: "es",
  thai: "th",
  german: "de"
};

const LANG_CODE_ORDER_LIST = [
  LANGUAGE_CODES.english,
  LANGUAGE_CODES.german,
  LANGUAGE_CODES.japanese,
  LANGUAGE_CODES.chinese_simplified,
  LANGUAGE_CODES.spanish,
  LANGUAGE_CODES.thai,
];

export const LANG_CODE_ORDER = LANG_CODE_ORDER_LIST.reduce(
  (langCodeToSortOrder, langCodeInList, langCodeIndex) => {
    langCodeToSortOrder[langCodeInList] = langCodeIndex;

    return langCodeToSortOrder;
  },
  {}
);

// endregion

// region Rich Text Editor

export const EDITOR_HANDLE_INPUT_STATE = {
  handled: "handled",
  non_handled: "non-handled",
};

// endregion

// region Pop Up
export const localStorageHomepageAnnouncementPopUpKey =
  "gv-homepageAnnouncementPopUps";
export const localStorageNewDesignPopUpKey = "gv-newDesignPopUp";
export const POP_UP_ANNOUNCEMENT_RESPONSE_OPTIONS = {
  FIRST_CTA_URL: "firstCtaUrl",
  SECOND_CTA_URL: "secondCtaUrl",
  DISMISS: "dismiss",
  SUPPRESSED: "suppressed",
};

// endregion Pop Up

export const subscriptionPaymentCompletionStatus = {
  fullyPaid: "yes",
  partiallyPaid: "partial",
  unpaid: "no",
};

export const MAX_CONCURRENT_DOWNLOADS = 5;
export const CHUNK_DELAY_IN_MS = 300;
