export const ChangeOverTimeSiteRankingViewTypeEnum = {
  VALUE: "value",
  PERCENTAGE: "percentage",
};

export const changeOverTimeSiteRankingViewTypeOptions = [
  {
    value: ChangeOverTimeSiteRankingViewTypeEnum.PERCENTAGE,
    label: "%",
  },
  {
    value: ChangeOverTimeSiteRankingViewTypeEnum.VALUE,
    label: "Value",
  },
];

export const changeOverTimeSiteRankingBarDirectionEnum = {
  LEFT: "left",
  RIGHT: "right",
};

export const changeOverTimeSiteRankingMargin = {
  top: 10,
  left: 200,
  right: 35,
  bottom: 50,
};

export const changeOverTimeSiteRankingConfig = {
  axisLeftMargin: -20,
  maxBarCount: 5,
  gridMargin: 7,
  siteNameColumnIndex: 0,
  chartViewId: "change-over-time-site-ranking-chart-view-id",
  expandedChartViewId: "expanded-change-over-time-site-ranking-chart-view-id",
  tableViewTypePrefix: "By",
  miniViewExcludeOnDownloadContainerId:
    "change-over-time-site-ranking-mini-view-exclude-on-download-container-id",
  expandedViewExcludeOnDownloadContainerId:
    "change-over-time-site-ranking-expanded-view-exclude-on-download-container-id",
};
