import axios from "axios";
import { API_URL } from "../config/api-config";
import moment from "moment";
import { DateTimeUtils, HttpResponseService } from "./UtilsService";
import {
  ACTIVITY_LOG_ACTION,
  ACTIVITY_LOG_ACTION_LABEL,
  ACTIVITY_LOG_DATE_FIELD_KEYS,
  getActionLogLabelColor,
} from "../components/common/ActivityLog/ActivityLogSharedUtils";
import { OptionTransformer } from "../components/common/MiniMultiSelect";
import React from "react";
import StatusLabel from "../gvds-components/common/StatusLabel";
import _ from "lodash";

export class ActivityLogModel {
  constructor(activityLog) {
    this.id = activityLog.id;
    this.action = ACTIVITY_LOG_ACTION_LABEL[activityLog.action];
    this.lastActivity = {
      action_by: activityLog.action_by,
      action_on: moment.utc(activityLog.action_on),
    };
    this.changed_object_data = activityLog.changed_object_data;

    const hasChanges =
      activityLog.action === ACTIVITY_LOG_ACTION.UPDATE &&
      !_.isEmpty(activityLog.changed_object_data.previous_value);
    this.changes = hasChanges
      ? Object.fromEntries(
          Object.entries(activityLog.changed_object_data.previous_value).map(
            ([key, value]) => [
              key,
              ACTIVITY_LOG_DATE_FIELD_KEYS.indexOf(key) >= 0
                ? [
                    DateTimeUtils.formatLocalDate(value),
                    DateTimeUtils.formatLocalDate(
                      activityLog.changed_object_data.latest_value[key]
                    ),
                  ]
                : [value, activityLog.changed_object_data.latest_value[key]],
            ]
          )
        )
      : null;
  }

  static getOptionTransformer() {
    return new OptionTransformer(
      (o) => o.action,
      (o) => o.action,
      (o) => (
        <StatusLabel color={getActionLogLabelColor(o.action)} key={o.action}>
          {o.action}
        </StatusLabel>
      )
    );
  }
}

export default class ActivityLogService {
  static async getLogs(resource_id, resource_type) {
    try {
      const params = {
        resource_id,
        resource_type,
        start: DateTimeUtils.getUTCISOString(
          moment().subtract(12, "months").startOf("month")
        ),
        end: DateTimeUtils.getUTCISOString(moment().add(1, "day")),
      };
      const response = await axios.get(`${API_URL}/api/v1/activity_logs`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}

//region Environmental Data Record Activity Log

export const environmentalDataRecordChangeKeyLabelPair = [
  { key: "period_from", label: "Period From" },
  { key: "period_to", label: "Period To" },
  { key: "usage", label: "Usage" },
  { key: "cost", label: "Cost" },
  { key: "comments", label: "Comments" },
];

export class EnvironmentalDataRecordActivityLogModel extends ActivityLogModel {
  constructor(activityLog) {
    super(activityLog);

    const latest_value = activityLog.changed_object_data.latest_value;
    this.periodFrom = latest_value.period_from
      ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          latest_value.period_from
        )
      : null;
    this.periodTo = latest_value.period_to
      ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          latest_value.period_to
        )
      : null;
    this.subtopicName = latest_value.environmental_subtopic_name;
    this.typeName = latest_value.environmental_type_name;
    this.meterName = latest_value.meter_name;
    this.usage = latest_value.usage;
    this.cost = latest_value.cost;
    this.unitName = latest_value.unit_name;
    this.currencyName = latest_value.currency_name;
    this.facilityName = latest_value.facility_name;
    this.comments = latest_value.comments;
  }

  get isLegacyActivityLog() {
    const isNoChangeField = !this.changes;
    const isAnyActivityLogMandatoryFieldsMissing =
      !this.periodFrom || !this.periodTo || this.usage === null;
    return isNoChangeField && isAnyActivityLogMandatoryFieldsMissing;
  }
}

export class EnvironmentalDataRecordActivityLogService {
  static async getEnvironmentalDataRecordActivityLogs(
    resource_id,
    resource_type
  ) {
    try {
      const params = {
        resource_id,
        resource_type,
      };
      const response = await axios.get(
        `${API_URL}/api/v1/environmental_data_records/logs`,
        {
          params,
        }
      );
      return response.data.map(
        (log) => new EnvironmentalDataRecordActivityLogModel(log)
      );
    } catch (error) {
      throw error;
    }
  }
}

export class EnvironmentalDataRecordActivityLogDownloadService {
  static downloadExcel = async (
    resource_type,
    resource_id,
    activity_log_period_start,
    activity_log_period_end,
    record_period_start,
    record_period_end
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/environmental_data_records/logs/download`,
        {
          activity_log_period_start,
          activity_log_period_end,
          record_period_start,
          record_period_end,
        },
        {
          params: {
            resource_type,
            resource_id,
          },
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Environmental Data Record Activity Log Excel.xlsx"
      );
    } catch (error) {
      throw error;
    }
  };
}

// endregion

//region Market Purchase Offset Record Activity Log
export const marketPurchaseOffsetRecordChangeKeyLabelPair = [
  { key: "period_from", label: "Period From" },
  { key: "period_to", label: "Period To" },
  { key: "is_bundled", label: "Bundled/Unbundled" },
  { key: "amount", label: "Amount Purchased" },
  { key: "comments", label: "Comments" },
];

export class MarketPurchaseOffsetRecordActivityLogModel extends ActivityLogModel {
  constructor(activityLog) {
    super(activityLog);
    const latest_value = activityLog.changed_object_data.latest_value;
    this.periodFrom = latest_value.period_from
      ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          latest_value.period_from
        )
      : null;
    this.periodTo = latest_value.period_to
      ? DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
          latest_value.period_to
        )
      : null;
    this.isBundled = latest_value.is_bundled ? "Bundled" : "Unbundled";
    this.typeName = latest_value.tracker_offset_type_name;
    this.trackerName = latest_value.tracker_name;
    this.amount = latest_value.amount;
    this.unitName = latest_value.unit_name;
    this.facilityName = latest_value.facility_name;
    this.comments = latest_value.comments;

    const is_bundled_changes =
      this.changes && this.changes.is_bundled
        ? this.changes.is_bundled.map((value) =>
            value === true ? "Bundled" : "Unbundled"
          )
        : null;

    if (is_bundled_changes) {
      this.changes = { ...this.changes, is_bundled: is_bundled_changes };
    }
  }

  get isLegacyActivityLog() {
    const isNoChangeField = !this.changes;
    const isAnyActivityLogMandatoryFieldsMissing =
      !this.periodFrom || !this.periodTo || this.amount === null;
    return isNoChangeField && isAnyActivityLogMandatoryFieldsMissing;
  }
}

export class MarketPurchaseOffsetRecordActivityLogService {
  static async getMarketPurchaseOffsetRecordActivityLogs(
    resource_id,
    resource_type
  ) {
    try {
      const params = {
        resource_id,
        resource_type,
      };
      const response = await axios.get(
        `${API_URL}/api/v1/market_purchase_offset_records/logs`,
        {
          params,
        }
      );
      return response.data.map(
        (activityLog) =>
          new MarketPurchaseOffsetRecordActivityLogModel(activityLog)
      );
    } catch (error) {
      throw error;
    }
  }
}

export class MarketPurchaseOffsetRecordActivityLogDownloadService {
  static downloadExcel = async (
    resource_type,
    resource_id,
    activity_log_period_start,
    activity_log_period_end,
    record_period_start,
    record_period_end
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/market_purchase_offset_records/logs/download`,
        {
          activity_log_period_start,
          activity_log_period_end,
          record_period_start,
          record_period_end,
        },
        {
          params: {
            resource_type,
            resource_id,
          },
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Market Purchase Offset Record Activity Log Excel.xlsx"
      );
    } catch (error) {
      throw error;
    }
  };
}

// endregion
