import React from "react";
import SupportContactLink from "../../common/SupportContactLink";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconLock, IconSeeding } from "@tabler/icons-react";
import { SUBSCRIPTION_FEATURE_STATUS } from "../../common/Feature/FeatureCheckPageWrapper";
import {
  NoSubscriptionDescription,
  NoSubscriptionTitle,
} from "../../common/Feature/NoSubscriptionMesage";
import { Trans, useTranslation } from "react-i18next";

const BaseWidgetEmptyContent = ({
  icon = IconSeeding,
  title,
  subtitle = null,
}) => {
  const { t } = useTranslation();

  if (subtitle === null) {
    subtitle = t("landing-page.base-widget.default-subtitle");
  }

  return (
    <div className="base-widget-empty">
      <GVDSIcon Icon={icon} className="gvds-empty-content--icon" />
      <div className="gvds-empty-content--title">{title}</div>
      <div>{subtitle}</div>
    </div>
  );
};

export default BaseWidgetEmptyContent;

export const FeatureLockWidgetContent = ({ subscriptionFeatureStatus }) => {
  const { t } = useTranslation();

  let icon, title, subtitle;

  if (
    subscriptionFeatureStatus === SUBSCRIPTION_FEATURE_STATUS.NO_SUBSCRIPTION
  ) {
    icon = IconLock;
    title = <NoSubscriptionTitle />;
    subtitle = (
      <div className="feature-lock-message">
        <NoSubscriptionDescription />
      </div>
    );
  } else {
    icon = IconSeeding;
    title = t("landing-page.base-widget.feature-lock-title");
    subtitle = (
      <Trans i18nKey="landing-page.base-widget.feature-lock-subtitle">
        Contact <SupportContactLink subject="Get access to more features" /> to
        know more about this feature.
      </Trans>
    );
  }

  return (
    <BaseWidgetEmptyContent icon={icon} title={title} subtitle={subtitle} />
  );
};
