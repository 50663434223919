import React from "react";
import GVDSButton, {
  buttonVariant,
} from "../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../gvds-components/Modals/GVDSModal";
import { Trans, useTranslation } from "react-i18next";

const UnsavedChangePromptModal = ({
  show,
  onCancel,
  onProceed,
  modalName: pageOrModuleName,
  message,
}) => {
  const { t } = useTranslation();

  const onModalClose = () => {
    onCancel();
  };

  return (
    <>
      <GVDSModal
        size={GVDSModal.Size.small}
        title={
          <Trans i18nKey="shared.unsaved-change-modal-title-leave">
            Leave {{ pageOrModuleName }}
          </Trans>
        }
        show={show}
        onHide={onModalClose}
      >
        <GVDSModal.Body>
          <div>{message}</div>
        </GVDSModal.Body>
        <GVDSModal.Footer>
          <GVDSButton
            className="cancel-leave"
            variant={buttonVariant.tertiary}
            onClick={onModalClose}
            text={t("shared-modal.footer.cancel")}
          />
          <GVDSButton
            variant={buttonVariant.destructive_primary}
            onClick={() => onProceed()}
            text={t("shared.unsaved-change-modal-footer-yes-leave")}
          />
        </GVDSModal.Footer>
      </GVDSModal>
    </>
  );
};

export default UnsavedChangePromptModal;
