import React from "react";
import FeatureService from "../../../services/FeatureService";
import moment from "moment";
import TooltipPersistentOnHover from "../../common/Tooltip/TooltipPersistentOnHover";
import SiteSubscriptionModel from "./SiteSubscription/SiteSubscriptionModel";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCheck, IconReceipt, IconX } from "@tabler/icons-react";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import MultiLineTextDisplay from "../../common/MultiLineTextDisplay";
import UserAvatar from "../../common/UserAvatar";
import { NumberService } from "../../../services/UtilsService";
import SubscriptionPaymentBadge from "./SubscriptionPaymentBadge";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import GVDSCustomIcons from "../../../gvds-components/Icons/GVDSIconCustom";
import { FEATURES } from "../../../config/constants";

export const SubscriptionStatusEnum = {
  PENDING: "pending",
  UPCOMING: "upcoming",
  ACTIVE: "active",
  EXPIRED: "expired",
  CANCELLED: "cancelled",
};

export const ACTIVE_SUBSCRIPTION_STATUSES = [
  SubscriptionStatusEnum.PENDING,
  SubscriptionStatusEnum.ACTIVE,
  SubscriptionStatusEnum.UPCOMING,
  SubscriptionStatusEnum.CANCELLED,
];

export const SubscriptionPricingType = {
  FREE: "free",
  PAID: "paid",
};

export const customEndPeriodRange = {
  fourteenDays: "14D",
  oneMonth: "1M",
  oneYear: "1Y",
};

export const CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME = "Enterprise Solution";
export const TAX_COUNTRY_NAME_SINGAPORE = "Singapore";

export const SubscriptionPaymentType = {
  CENTRALIZED: "centralized",
  DECENTRALIZED: "decentralized",
};

export const SubscriptionBillingCycle = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
  CUSTOM: "custom",
};

const DEFAULT_REMINDER_DATE_DAYS_FROM_END_PERIOD_FOR_FREE_TRIAL = 7;
const DEFAULT_REMINDER_DATE_DAYS_FROM_END_PERIOD_FOR_MONTHLY = 15;
const DEFAULT_REMINDER_DATE_MONTHS_FROM_END_PERIOD_FOR_YEARLY = 2;

export default class SubscriptionSharedUtils {
  static getEmptyPastSubscription = () => {
    return (
      <div className="view-all-subscription__no-content">
        <GVDSIcon Icon={IconReceipt} className="gvds-empty-content--icon" />
        <div className="gvds-empty-content--title">No past subscriptions</div>
      </div>
    );
  };

  static getEmptyOngoingSubscription = () => {
    return (
      <div className="view-all-subscription__no-content">
        <GVDSIcon Icon={IconReceipt} className="gvds-empty-content--icon" />
        <div className="gvds-empty-content--title">No active subscriptions</div>
      </div>
    );
  };

  static getFeaturesDisplayModel = (allFeatures, subscribedFeatures) => {
    return allFeatures.map((f) => ({
      id: f.id,
      name: f.name,
      displayName: f.display_name,
      isEnabled: subscribedFeatures.findIndex((sf) => sf.name === f.name) >= 0,
    }));
  };

  static getFeaturesList = (allFeatures, subscribedFeatures, onClick) => {
    const featuresDisplayModel =
      SubscriptionSharedUtils.getFeaturesDisplayModel(
        allFeatures,
        subscribedFeatures
      );

    return (
      <div
        onClick={() => {
          if (onClick !== undefined) {
            onClick();
          }
        }}
        className={
          "subscription--shorthand-feature-list" +
          (onClick !== undefined ? " clickable" : "")
        }
      >
        <h4>Subscribed Features</h4>
        {featuresDisplayModel
          .sort((feature1, feature2) =>
            FeatureService.featureSortFn(feature1.name, feature2.name)
          )
          .map((f) => {
            return (
              <div key={f.id} className="feature-row-container">
                <div className="enabled-feature-icon">
                  {f.isEnabled ? (
                    <GVDSIcon
                      Icon={IconCheck}
                      className="gvds-color--tealSolid"
                    />
                  ) : (
                    <GVDSIconSlim Icon={IconX} className="gvds-color--error" />
                  )}
                </div>
                {f.displayName}
              </div>
            );
          })}
      </div>
    );
  };

  static getShorthandFeatureDisplay = (
    allFeatures,
    subscribedFeatures,
    placement = "bottom",
    showText = true
  ) => {
    return (
      <div className="d-flex flex-row align-items-center">
        {showText
          ? allFeatures && allFeatures.length === subscribedFeatures.length
            ? "All features"
            : "Partial features"
          : ""}
        <TooltipPersistentOnHover
          triggerContent={
            <GVDSCustomIcons.Info className="subscription-features-icon" />
          }
          triggerClassName={`show-subscription-feature__container ms-1`}
          tooltipContent={SubscriptionSharedUtils.getFeaturesList(
            allFeatures,
            subscribedFeatures
          )}
          placement={placement}
        />
      </div>
    );
  };

  static getActiveSubscriptions = (subscriptions) => {
    return subscriptions
      .filter((s) => ACTIVE_SUBSCRIPTION_STATUSES.includes(s.status))
      .sort((a, b) => (moment(a.startPeriod).isBefore(b.startPeriod) ? 1 : -1));
  };

  static getPastSubscriptions = (subscriptions) => {
    return subscriptions
      .filter((s) => s.status === SubscriptionStatusEnum.EXPIRED)
      .sort((a, b) => (moment(a.startPeriod).isBefore(b.startPeriod) ? 1 : -1));
  };

  static calculateEndPeriod = (range, startPeriod) => {
    let endPeriod;

    switch (range) {
      case customEndPeriodRange.fourteenDays:
        endPeriod = moment(startPeriod).add(13, "day").toDate();
        break;
      case customEndPeriodRange.oneMonth:
        endPeriod = moment(startPeriod)
          .add(1, "month")
          .subtract(1, "day")
          .toDate();
        break;
      case customEndPeriodRange.oneYear:
        endPeriod = moment(startPeriod)
          .add(1, "year")
          .subtract(1, "day")
          .toDate();
        break;
      default:
        endPeriod = startPeriod;
    }

    return endPeriod;
  };

  static getStartPeriodValidationMessage = (
    startPeriod,
    endPeriod,
    contractStartPeriod = null,
    contractEndPeriod = null
  ) => {
    if (!startPeriod) {
      return "This field cannot be empty.";
    } else if (endPeriod && startPeriod > endPeriod) {
      return "This field cannot be after end period.";
    } else if (
      contractStartPeriod &&
      contractEndPeriod &&
      (startPeriod < contractStartPeriod || startPeriod > contractEndPeriod)
    ) {
      return "This field cannot be outside of contract period";
    } else {
      return null;
    }
  };

  static getEndPeriodValidationMessage = (
    startPeriod,
    endPeriod,
    contractStartPeriod = null,
    contractEndPeriod = null
  ) => {
    if (!endPeriod) {
      return "This field cannot be empty.";
    } else if (startPeriod && endPeriod < startPeriod) {
      return "This field cannot be before start period.";
    } else if (
      contractStartPeriod &&
      contractEndPeriod &&
      (endPeriod < contractStartPeriod || endPeriod > contractEndPeriod)
    ) {
      return "This field cannot be outside of contract period";
    } else {
      return null;
    }
  };

  static getReminderDateValidationMessage = (
    reminderDate,
    startPeriod,
    endPeriod
  ) => {
    if (!reminderDate) {
      return "This field cannot be empty.";
    } else if (
      (endPeriod && reminderDate > endPeriod) ||
      (startPeriod && reminderDate < startPeriod)
    ) {
      return "This field cannot be before start period or after end period.";
    } else {
      return null;
    }
  };

  static getPriceAmountValidationMessage = (priceAmount) => {
    if (!priceAmount || priceAmount === "") {
      return "This field cannot be empty.";
    } else if (priceAmount < 0) {
      return "This field cannot be less than zero.";
    } else {
      return null;
    }
  };

  static getBlankSiteSubscriptionModel = (allFeatures) => {
    return new SiteSubscriptionModel({
      id: null,
      site: null,
      startPeriod: null,
      endPeriod: null,
      reminderDate: null,
      pricingType: null,
      currencyId: null,
      priceAmount: "",
      features: allFeatures,
      comments: "",
      billingCycle: null,
      planId: null,
      autoRenew: false,
      isTaxApplicable: false,
      inputDiscountId: null,
    });
  };

  static getPriceDisplay = (subscription, priceAmount) => {
    return (
      <div className="subscription__joined-bullet-container">
        {subscription.isPaidSubscription() && (
          <span className="me-1">
            {subscription.currencyId} {NumberService.format(priceAmount)}
          </span>
        )}
        <SubscriptionPaymentBadge payment={subscription.paymentStatus} />
      </div>
    );
  };

  static getBillingAdminDisplay = (billingAdmin) => {
    return billingAdmin ? (
      <>
        <div>{billingAdmin.full_name ?? "-"}</div>
        <a
          className="billing-admin-email"
          href={`mailto:${billingAdmin.email}`}
          target="_blank"
        >
          {billingAdmin.email}
        </a>
      </>
    ) : (
      <div>-</div>
    );
  };

  static getDiscountDisplay = (discountCode) => {
    return discountCode && discountCode !== "" ? (
      <div>{discountCode}</div>
    ) : (
      <div className="subscription__no-data-available">No discount</div>
    );
  };

  static getAccountManagerDisplay = (accountManager) => {
    return accountManager ? (
      <>
        <UserAvatar
          fullName={accountManager.full_name}
          userEmail={accountManager.email}
        />{" "}
        {accountManager.full_name ?? "-"}
      </>
    ) : (
      <div className="subscription__no-data-available">Not assigned</div>
    );
  };

  static getCommentDisplay = (comments) => {
    return comments && comments !== "" ? (
      <MultiLineTextDisplay>{comments}</MultiLineTextDisplay>
    ) : (
      <div className="subscription__no-data-available">No comment</div>
    );
  };

  static getEndPeriodBasedOnBillingCycle = (model, billingCycle) => {
    if (model.startPeriod === null) {
      return null;
    }

    switch (billingCycle) {
      case SubscriptionBillingCycle.MONTHLY:
        return moment(model.startPeriod)
          .add(1, "month")
          .add(-1, "day")
          .toDate();
      case SubscriptionBillingCycle.YEARLY:
        return moment(model.startPeriod).add(1, "year").add(-1, "day").toDate();
      default:
        return null;
    }
  };

  static getDefaultReminderDate = (model, billingCycle, pricingType) => {
    if (model.endPeriod === null) {
      return null;
    }

    if (
      billingCycle === SubscriptionBillingCycle.MONTHLY ||
      pricingType === SubscriptionPricingType.FREE
    ) {
      let endPeriodReminderOffsetInDays = 0;

      if (pricingType === SubscriptionPricingType.FREE) {
        endPeriodReminderOffsetInDays =
          DEFAULT_REMINDER_DATE_DAYS_FROM_END_PERIOD_FOR_FREE_TRIAL;
      } else if (billingCycle === SubscriptionBillingCycle.MONTHLY) {
        endPeriodReminderOffsetInDays =
          DEFAULT_REMINDER_DATE_DAYS_FROM_END_PERIOD_FOR_MONTHLY;
      }

      return moment(model.endPeriod)
        .subtract(endPeriodReminderOffsetInDays - 1, "day")
        .toDate();
    } else if (
      billingCycle === SubscriptionBillingCycle.YEARLY ||
      billingCycle === SubscriptionBillingCycle.CUSTOM
    ) {
      return moment(model.endPeriod)
        .subtract(
          DEFAULT_REMINDER_DATE_MONTHS_FROM_END_PERIOD_FOR_YEARLY,
          "month"
        )
        .toDate();
    } else {
      return null;
    }
  };

  static getSiteSubscriptionDifferentFromContractCount(siteSubscriptions) {
    const values = siteSubscriptions?.map((subscription) => {
      return subscription.startPeriod !== null ||
        subscription.endPeriod !== null ||
        subscription.features !== null
        ? 1
        : 0;
    });
    return _.sum(values);
  }

  static isSubscriptionPackagePlanNotCustom = (
    orderedPackagePlans,
    selectedPlanId
  ) => {
    return !orderedPackagePlans.find(
      (plan) =>
        plan.package_plan_id === selectedPlanId &&
        plan.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME
    );
  };

  static updateSubscriptionTermsBasedOnPlanAndBillingCycle = (
    model,
    billingCycle,
    packagePlan,
    isTrial = null
  ) => {
    const isCustomPlan =
      packagePlan?.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;
    const isContractSubscription = model.siteSubscriptions !== undefined;

    model.billingCycle = billingCycle;

    if (
      [
        SubscriptionBillingCycle.MONTHLY,
        SubscriptionBillingCycle.YEARLY,
      ].includes(billingCycle)
    ) {
      model.autoRenew = true;

      const endPeriod = SubscriptionSharedUtils.getEndPeriodBasedOnBillingCycle(
        model,
        billingCycle
      );
      if (endPeriod !== null) {
        model.endPeriod = endPeriod;
      }
    } else {
      model.autoRenew = false;
    }

    const price = packagePlan.prices.find(
      (price) => price.billing_cycle === billingCycle
    );
    const currencyId = price?.currency?.toUpperCase() ?? null;
    if (isContractSubscription) {
      let sitePrice;
      if (!isCustomPlan && price) {
        sitePrice = price.price_in_decimal;
      } else {
        sitePrice = 0;
      }
      model.siteSubscriptions
        .filter((siteSub) => siteSub.isNew())
        .forEach((subscription) => {
          subscription.priceAmount = sitePrice;
        });
    } else {
      if (!isCustomPlan && price) {
        model.priceAmount = price.price_in_decimal;
      } else {
        model.priceAmount = 0;
      }
    }
    model.currencyId = currencyId;

    model.pricingType = isTrial
      ? SubscriptionPricingType.FREE
      : SubscriptionPricingType.PAID;

    if (isTrial) {
      model.endPeriod = null;
      model.reminderDate = null;
    }

    const defaultReminderDate = SubscriptionSharedUtils.getDefaultReminderDate(
      model,
      model.billingCycle,
      model.pricingType
    );
    if (defaultReminderDate !== null) {
      model.reminderDate = defaultReminderDate;
    }
  };

  static getDefaultBillingCycle = (plan) => {
    const activeBillingCycles = [
      plan.is_monthly_available ? SubscriptionBillingCycle.MONTHLY : null,
      plan.is_yearly_available ? SubscriptionBillingCycle.YEARLY : null,
      plan.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME
        ? SubscriptionBillingCycle.CUSTOM
        : null,
    ].filter((bc) => bc !== null);
    if (activeBillingCycles.length === 1) {
      return activeBillingCycles[0];
    } else {
      return null;
    }
  };

  static getPaymentDueDate(
    hasCustomPaymentDueDay,
    paymentDueDate,
    startPeriod
  ) {
    return hasCustomPaymentDueDay ? paymentDueDate : startPeriod;
  }
}

export const SubscriptionFeatureSelection = ({
  allFeatures,
  subscriptionModel,
  onFeatureSelected,
  disabled,
}) => {
  return allFeatures
    .sort((feature1, feature2) =>
      FeatureService.featureSortFn(feature1.name, feature2.name)
    )
    .map((feature) => {
      return (
        <Form.Check
          checked={
            subscriptionModel.features &&
            subscriptionModel.features.findIndex((f) => f.id === feature.id) >=
              0
          }
          type="checkbox"
          name={feature.name}
          key={feature.id}
          id={`${feature.id}-${_.uniqueId()}`}
          label={feature.display_name}
          onChange={() => {
            onFeatureSelected(feature);
          }}
          disabled={disabled}
        />
      );
    });
};

export const getSubscriptionPriceDisplay = (currency, price) => {
  return `${currency} $${price}`;
};

const EXCLUDED_DEFAULT_FEATURES = [FEATURES.POLICY_TRACKER];

export const getDefaultFullFeatures = (allFeatures) => {
  return allFeatures.filter(
    (feature) => !EXCLUDED_DEFAULT_FEATURES.includes(feature.name)
  );
};
