import React, { useCallback, useState } from "react";
import { FILE_ASSOCIATED_RESOURCE } from "../config/constants";
import FileStorageService from "../services/FileStorageService";
import { FileUtils } from "../services/UtilsService";

export const FILE_STORAGE_PAGES = {
  MAIN: "MAIN",
  UPLOAD: "UPLOAD",
};

const FileStorageStateContext = React.createContext(false);

export const FileStorageStateProvider = ({ children }) => {
  const [isFileStorageOpen, setIsFileStorageOpen] = useState(false);
  const [fileStoragePage, setFileStoragePage] = useState(
    FILE_STORAGE_PAGES.MAIN
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showCloseFileStoragePrompt, setShowCloseFileStoragePrompt] =
    useState(false);
  const [
    gstcAttachmentComponentReloadFlag,
    setGstcAttachmentComponentReloadFlag,
  ] = useState(false);
  const [initiativesComponentReloadFlag, setInitiativesComponentReloadFlag] =
    useState(false);

  const openFileStorage = () => {
    setIsFileStorageOpen(true);
  };

  const closeFileStorage = () => {
    if (!isLoading) {
      if (hasChanges) {
        setShowCloseFileStoragePrompt(true);
      } else {
        hideFileStorageDrawer();
      }
    }
  };

  const hideFileStorageDrawer = () => {
    setIsFileStorageOpen(false);

    if (fileStoragePage === FILE_STORAGE_PAGES.UPLOAD) {
      setFileStoragePage(FILE_STORAGE_PAGES.MAIN);
    }
  };

  const cancelCloseFileStorage = () => {
    setShowCloseFileStoragePrompt(false);
  };

  const confirmCloseFileStorage = () => {
    if (!isLoading) {
      setShowCloseFileStoragePrompt(false);
      setHasChanges(false);
      hideFileStorageDrawer();
    }
  };

  const changePage = (newPage) => {
    setFileStoragePage(newPage);
  };

  const reloadAssociatedResourceRelatedComponentFlag = (moduleName) => {
    if (moduleName === FILE_ASSOCIATED_RESOURCE.INITIATIVES) {
      setInitiativesComponentReloadFlag(!initiativesComponentReloadFlag);
    } else if (moduleName === FILE_ASSOCIATED_RESOURCE.GSTC) {
      setGstcAttachmentComponentReloadFlag(!gstcAttachmentComponentReloadFlag);
    }
  };

  const getAssociatedResources = async (
    resourceType,
    resourceId,
    fileStorageObjectId
  ) => {
    const associatedResourceModels =
      await FileStorageService.getFileStorageObjectAssociatedResources(
        resourceType,
        resourceId,
        fileStorageObjectId
      );
    return associatedResourceModels[0].associatedResourceObjectType
      .toUpperCase()
      .replaceAll(" ", "_");
  };

  const store = useCallback(
    {
      isOpen: isFileStorageOpen,
      open: openFileStorage,
      close: closeFileStorage,
      currentPage: fileStoragePage,
      changePage: changePage,
      downloadFiles: FileUtils.downloadFiles,
      setIsLoading: setIsLoading,
      showCloseFileStoragePrompt: showCloseFileStoragePrompt,
      cancelCloseFileStorage: cancelCloseFileStorage,
      confirmCloseFileStorage: confirmCloseFileStorage,
      setHasChanges: setHasChanges,
      reloadAssociatedResourceRelatedComponentFlag:
        reloadAssociatedResourceRelatedComponentFlag,
      gstcAttachmentComponentReloadFlag: gstcAttachmentComponentReloadFlag,
      initiativesComponentReloadFlag: initiativesComponentReloadFlag,
      getAssociatedResources: getAssociatedResources,
    },
    [
      isFileStorageOpen,
      fileStoragePage,
      isLoading,
      hasChanges,
      showCloseFileStoragePrompt,
      gstcAttachmentComponentReloadFlag,
      initiativesComponentReloadFlag,
    ]
  );

  return (
    <FileStorageStateContext.Provider value={store}>
      {children}
    </FileStorageStateContext.Provider>
  );
};

export default FileStorageStateContext;
