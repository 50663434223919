import axios from "axios";
import { API_URL } from "../config/api-config";
import { RESOURCES } from "../config/constants";

export default class SurveyAssessmentService {
  static getAllSurvey = async (resource_type, resource_id, abortControllerSignal) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/surveys/assessment?${new URLSearchParams(
          params
        ).toString()}`,
        {
          signal: abortControllerSignal
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getSurvey = async (resource_type, resource_id, surveyId) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/surveys/assessment/${surveyId}?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static markSurveyAsCompleted = async (
    resource_type,
    resource_id,
    surveyId
  ) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.post(
        `${API_URL}/api/v1/surveys/assessment/${surveyId}/complete?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static markSurveyAsIncomplete = async (
    resource_type,
    resource_id,
    surveyId
  ) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.post(
        `${API_URL}/api/v1/surveys/assessment/${surveyId}/incomplete?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateAnswer = async (site_id, survey_id, question_id, answer_ids) => {
    try {
      const params = { resource_type: RESOURCES.SITE, resource_id: site_id };
      const data = { answer_ids };
      const response = await axios.put(
        `${API_URL}/api/v1/surveys/assessment/${survey_id}/question/${question_id}/answer?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateWrittenAnswer = async (
    site_id,
    survey_id,
    question_id,
    written_answer
  ) => {
    try {
      const params = { resource_type: RESOURCES.SITE, resource_id: site_id };
      const data = { written_answer };
      const response = await axios.put(
        `${API_URL}/api/v1/surveys/assessment/${survey_id}/question/${question_id}/answer?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateComment = async (site_id, survey_id, question_id, comment) => {
    try {
      const params = { resource_type: RESOURCES.SITE, resource_id: site_id };
      const data = { comment };
      const response = await axios.put(
        `${API_URL}/api/v1/surveys/assessment/${survey_id}/question/${question_id}/comment?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getLinkedBestPracticeAnswer = async (
    site_id,
    survey_id,
    question_id
  ) => {
    try {
      const params = { resource_type: RESOURCES.SITE, resource_id: site_id };
      const response = await axios.get(
        `${API_URL}/api/v1/surveys/assessment/${survey_id}/question/${question_id}/linked_best_practice_answer?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
