import React, { forwardRef, useEffect, useState } from "react";
import Select, { components } from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import GVDSIconSlim from "../../Icons/GVDSIconSlim";
import GVDSIcon from "../../Icons/GVDSIcon";
import { createPortal } from "react-dom";
import { GVDSColors } from "../../../styles/gvds-colors";
import GVDSTextButton from "../../Buttons/GVDSTextButton";
import { isEmpty, xor } from "lodash";

const shortSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided, state) => ({
    width: state.selectProps.width,
  }),
};

export const longSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 200,
    margin: 8,
    width: state.selectProps.width,
    borderColor: state.isFocused ? GVDSColors.tealSolid : GVDSColors.gray4,
    boxShadow: state.isFocused ? "0 0 3px 0 " + GVDSColors.tealSolid : "none",
    "&:hover": {
      boxShadow: state.isFocused ? "0 0 3px 0 " + GVDSColors.tealSolid : "none",
    },
  }),
  menu: () => ({}),
};

export const CustomCheckboxOption = (props) => {
  const displayAs = props.data.display;
  return (
    <div>
      <components.Option
        {...props}
        className="gvds-table-ctrl--multi-select__option"
      >
        <Form.Check
          checked={props.isSelected}
          readOnly
          type="checkbox"
          label={displayAs}
        />
      </components.Option>
    </div>
  );
};

const CheckboxOption = (props) => (
  <div>
    <components.Option
      {...props}
      className="gvds-table-ctrl--multi-select__option"
    >
      <Form.Check
        checked={props.isSelected}
        readOnly
        type="checkbox"
        label={props.value}
      />
    </components.Option>
  </div>
);

export const TableCtrlMultiSelectTrigger = forwardRef(
  ({ isFilterActive, children, onClick }, ref) => (
    <div
      className={`gvds-table-ctrl--multi-select__trigger ${
        isFilterActive ? "is-filter-active" : ""
      }`}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children} <GVDSIconSlim Icon={IconChevronDown} />
    </div>
  )
);

const Menu = (props) => {
  const optionsHeaderText = props.selectProps.GVDSOptionsHeaderText;
  const showLongListCtrl = props.selectProps.GVDSMenuShowLongListCtrl;
  return (
    <components.Menu {...props}>
      <div>
        {optionsHeaderText && (
          <div className="gvds-table-ctrl--multi-select__menu-header">
            {optionsHeaderText}
          </div>
        )}
        {showLongListCtrl && (
          <div className="gvds-table-ctrl--multi-select__menu-selection-ctrl">
            <GVDSTextButton
              text="Select all"
              onClick={props.selectProps.GVDSMenuSelectAll}
            />
            <GVDSTextButton
              className="ms-2"
              text="Clear all"
              onClick={props.selectProps.GVDSMenuClearAll}
            />
          </div>
        )}
        <div>{props.children}</div>
      </div>
    </components.Menu>
  );
};

export class OptionTransformer {
  constructor(labelFn, valueFn, displayFn) {
    this.labelFn = labelFn;
    this.valueFn = valueFn;
    this.displayFn = displayFn;
  }

  transform(o) {
    return {
      label: this.labelFn(o),
      value: this.valueFn(o),
      display: this.displayFn(o),
    };
  }
}

const defaultStringOptionTransformer = new OptionTransformer(
  (o) => o,
  (o) => o,
  (o) => o
);

const GVDSTableCtrlMultiSelect = ({
  parentModalRef = null, // need ref to <Modal> if within modal
  options = [],
  nonStringOptions = null,
  optionTransformer = defaultStringOptionTransformer,
  prefix,
  defaultSelected = [], // TODO remove after replacing all as controlled
  value: controlledSelectedValues,
  onChange,
  width = "350px",
  noOptionsMessage = "None available",
  optionsHeaderText = null,
  longSelectThreshold = 3,
}) => {
  const [inputValue, setInputValue] = useState("");

  let isUsingNonStringValues = false;
  let dropdownOptions = options;

  if (nonStringOptions !== null) {
    isUsingNonStringValues = true;
    dropdownOptions = nonStringOptions;
  }

  const transformedOptions = dropdownOptions.map((o) =>
    optionTransformer.transform(o)
  );

  const isControlled = !!controlledSelectedValues; // TODO remove after replacing all as controlled

  const refreshOptions = transformedOptions.map((o) => o.value).join(",");
  const refreshDefaultSelectedKey = defaultSelected
    .map((o) => optionTransformer.transform(o).value)
    .join(","); // TODO remove after replacing all as controlled
  const [uncontrolledSelected, setUncontrolledSelected] = useState([]); // TODO remove after replacing all as controlled

  const updateSelectionToValidOptions = () => {
    if (!transformedOptions || transformedOptions.length === 0) {
      setUncontrolledSelected([]);
      onChange([]);
    } else {
      let selectedValuesStillInOptions = [];
      const optionValues = transformedOptions.map((o) => o.value);

      if (isControlled) {
        selectedValuesStillInOptions = optionValues.filter((optionValue) =>
          controlledSelectedValues.includes(optionValue)
        );
      } else {
        if (defaultSelected.length > 0) {
          selectedValuesStillInOptions = optionValues.filter((optionValue) =>
            defaultSelected.includes(optionValue)
          );
        } else if (uncontrolledSelected.length > 0) {
          const uncontrolledSelectedValues = uncontrolledSelected.map(
            (v) => v.value
          );
          selectedValuesStillInOptions = optionValues.filter((optionValue) =>
            uncontrolledSelectedValues.includes(optionValue)
          );
        }
      }

      const newSelectedOptions = transformedOptions.filter((o) =>
        selectedValuesStillInOptions.includes(o.value)
      );
      setUncontrolledSelected(newSelectedOptions);

      const isNewOptionsSameAsControlledValues = isEmpty(
        xor(
          newSelectedOptions.map((o) => o.value),
          controlledSelectedValues
        )
      );

      if (!isNewOptionsSameAsControlledValues) {
        onChange(newSelectedOptions.map((o) => o.value));
      }
    }
  };

  useEffect(() => {
    updateSelectionToValidOptions();
  }, [refreshOptions, refreshDefaultSelectedKey]);

  const isInModal = parentModalRef !== null;
  const [parentModalElement, setParentModalElement] = useState(null);

  useEffect(() => {
    if (isInModal) {
      // Force a rerender, so it can be passed to the child. Ref: https://stackoverflow.com/a/69701646
      setParentModalElement(parentModalRef.current.dialog);
    }
  }, []);

  const onSelectChange = (selectedOpts) => {
    setUncontrolledSelected(selectedOpts);
    onChange(selectedOpts.map((s) => s.value));
  };

  const selectAll = () => {
    setUncontrolledSelected(transformedOptions);
    onChange(transformedOptions.map((s) => s.value));
    setInputValue("");
  };

  const clearAll = () => {
    setUncontrolledSelected([]);
    onChange([]);
    setInputValue("");
  };

  const selected = isControlled
    ? transformedOptions.filter((o) =>
        controlledSelectedValues?.includes(o.value)
      )
    : uncontrolledSelected;

  const onInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  const dropdownMenu = (
    <Dropdown.Menu
      flip={false}
      className="gvds-table-ctrl--multi-select__dropdown-menu"
    >
      <Select
        className="gvds-table-ctrl--multi-select__selector"
        isMulti
        menuIsOpen
        menuShouldScrollIntoView={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        tabSelectsValue={false}
        controlShouldRenderValue={false}
        components={{
          Menu: Menu,
          Option: isUsingNonStringValues
            ? CustomCheckboxOption
            : CheckboxOption,
          DropdownIndicator: () => <GVDSIcon Icon={IconSearch} />,
          IndicatorSeparator: null,
        }}
        placeholder="Search"
        value={selected ?? []}
        options={transformedOptions}
        onChange={onSelectChange}
        noOptionsMessage={() => noOptionsMessage}
        width={width}
        styles={
          dropdownOptions.length > longSelectThreshold
            ? longSelectStyles
            : shortSelectStyles
        }
        maxMenuHeight="21em"
        GVDSOptionsHeaderText={optionsHeaderText}
        GVDSMenuShowLongListCtrl={dropdownOptions.length > longSelectThreshold}
        GVDSMenuSelectAll={selectAll}
        GVDSMenuClearAll={clearAll}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onMenuClose={() => setInputValue("")}
      />
    </Dropdown.Menu>
  );

  return (
    <Dropdown className="gvds-table-ctrl--multi-select">
      <Dropdown.Toggle
        as={TableCtrlMultiSelectTrigger}
        isFilterActive={selected.length > 0}
      >
        <div>{prefix} </div>
      </Dropdown.Toggle>

      {isInModal
        ? parentModalElement
          ? createPortal(dropdownMenu, parentModalElement)
          : null
        : dropdownMenu}
    </Dropdown>
  );
};

export default GVDSTableCtrlMultiSelect;
