import UserAvatar from "../common/UserAvatar";
import { DateTimeUtils } from "../../services/UtilsService";
import React from "react";

const LastUpdatedOnDisplay = ({updatedBy, updatedOn}) => {
  if (!updatedOn) {
    return null;
  } else {
    return (
      <>
        <UserAvatar
          fullName={updatedBy["full_name"]}
          userEmail={updatedBy["email"]}
        />
        <span className="ms-1 last-updated-date">
          {DateTimeUtils.formatLocalDate(updatedOn)}
        </span>
      </>
    );
  }
};

export default LastUpdatedOnDisplay;
