import React from "react";
import { DateTimeUtils } from "../../services/UtilsService";
import UserAvatar from "./UserAvatar";

const LastActivityDisplay = ({ lastActivity }) => {
  if (!lastActivity) {
    return null;
  } else {
    return (
      <>
        <UserAvatar
          fullName={lastActivity["action_by"]["full_name"]}
          userEmail={lastActivity["action_by"]["email"]}
        />
        <span className="ms-1 lastActivityDate">
          {DateTimeUtils.formatUTCDate(lastActivity["action_on"])}
        </span>
      </>
    );
  }
};

export default LastActivityDisplay;
