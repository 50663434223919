import React, { useEffect } from "react";
import GVFormGroup from "../../common/GVFormGroup";
import GVDSFormField from "../../../gvds-components/Forms/GVDSFormField";
import GVDSFormSingleSelect from "../../../gvds-components/Forms/GVDSFormSingleSelect";
import { FormFieldStatusMetadata } from "../../../gvds-components/Forms/GVDSFormShared";

export const SourceSetupSelector = ({
  controlId,
  trackingMeter,
  field,
  sourceOptions,
  onChangeTrackingMeter,
  disabled,
  isValidated,
}) => {
  const onChangeTrackingSource = (selectedOption) => {
    trackingMeter[field] = {
      id: selectedOption.value,
      name: selectedOption.label,
    };
    if (trackingMeter.unit) {
      trackingMeter.unit = null;
    }
    onChangeTrackingMeter();
  };

  return (
    <GVFormGroup controlId={controlId}>
      {disabled ? (
        <GVDSFormField
          name="source"
          type="text"
          value={trackingMeter[field] ? trackingMeter[field].name : ""}
          disabled={disabled}
        />
      ) : (
        <GVDSFormSingleSelect
          className="select__tracking-source"
          name="source"
          placeholder="Select Source"
          value={
            trackingMeter[field]
              ? {
                  value: trackingMeter[field].id,
                  label: trackingMeter[field].name,
                }
              : null
          }
          options={sourceOptions.map((source) => {
            return { value: source.id, label: source.name };
          })}
          onSelect={onChangeTrackingSource}
          statusMetadata={
            isValidated && !(trackingMeter[field] && trackingMeter[field].id)
              ? FormFieldStatusMetadata.getError("Please select a Source.")
              : FormFieldStatusMetadata.getDefault()
          }
          disabled={disabled}
        />
      )}
    </GVFormGroup>
  );
};

export const UnitSetupSelector = ({
  controlId,
  trackingMeter,
  field,
  unitOptions,
  onChangeTrackingMeter,
  disabled,
  isValidated,
}) => {
  useEffect(() => {
    if (unitOptions.length === 1) {
      // set unit as the only one
      trackingMeter[field] = {
        id: unitOptions[0].id,
        name: unitOptions[0].name,
      };
      onChangeTrackingMeter();
    }
  }, [unitOptions]);

  const onChangeUnit = (selectedOption) => {
    trackingMeter[field] = {
      id: selectedOption.value,
      name: selectedOption.label,
    };
    onChangeTrackingMeter();
  };

  let display;

  if (unitOptions.length === 1) {
    display = <div className="caption fst-italic">{unitOptions[0].name}</div>;
  } else {
    display = (
      <GVFormGroup controlId={controlId}>
        <GVDSFormSingleSelect
          className="select__unit"
          name="unit"
          placeholder="Select Unit"
          value={
            trackingMeter[field]
              ? {
                  value: trackingMeter[field].id,
                  label: trackingMeter[field].name,
                }
              : null
          }
          options={unitOptions.map((unit) => {
            return { value: unit.id, label: unit.name };
          })}
          onSelect={onChangeUnit}
          statusMetadata={
            isValidated && !trackingMeter[field]
              ? FormFieldStatusMetadata.getError("Required")
              : FormFieldStatusMetadata.getDefault()
          }
          disabled={disabled}
        />
      </GVFormGroup>
    );
  }

  return display;
};
