import DashboardExpandedModalView from "../../DashboardExpandedModalView";
import React from "react";
import { ExpandedPerformanceTimelineChartViewHeader } from "./PerformanceTimelineChartViewHeaders";
import { expandedModalViewOptionEnum } from "../../Models/DashboardModels";
import PerformanceTimelineChartViewContent from "./PerformanceTimelineChartViewContent";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import { NumberService } from "../../../../services/UtilsService";
import { PerformanceTimelineChartUtils } from "./PerformanceTimelineChartUtils";
import { performanceTimelineChartConfig } from "./PerformanceTimelineViewConfig";
import LoadingSpinnerInFullPage from "../../../common/LoadingSpinnerInFullPage";

const ExpandedTableView = ({ chartData, chartKeys, unit }) => {
  if (chartData.length > 0) {
    const periods = chartData.map((datum) => datum.groupName);
    const tableRowData = PerformanceTimelineChartUtils.getTableRowData(
      chartData,
      chartKeys,
      periods
    );

    return (
      <div className="dashboard--expanded-view__table-container">
        <GVDSTableDisplay>
          <thead>
            <tr>
              <th>Type</th>
              {periods.map((period) => (
                <th key={period} style={{ textAlign: "right" }}>
                  {period} ({unit})
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRowData.map((rowData, index) => (
              <tr key={index}>
                {rowData.map((cellData, columnIndex) => (
                  <td
                    key={`row-${columnIndex}`}
                    align={
                      columnIndex ===
                      performanceTimelineChartConfig.typeColumnIndex
                        ? "left"
                        : "right"
                    }
                  >
                    {columnIndex ===
                    performanceTimelineChartConfig.typeColumnIndex
                      ? cellData
                      : NumberService.format(cellData, 2, 2)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </GVDSTableDisplay>
      </div>
    );
  }
};

const ExpandedPerformanceTimelineChartViewBody = ({
  chartData,
  chartKeys,
  unit,
  intensityUnit,
  selectedViewOption,
  selectedTimePeriod,
  minimapPosition,
  onMinimapPositionChange,
}) => {
  return (
    <div className="dashboard--performance-timeline__expanded-chart-view__content-container">
      {selectedViewOption === expandedModalViewOptionEnum.CHART ? (
        <PerformanceTimelineChartViewContent
          chartData={chartData}
          chartKeys={chartKeys}
          intensityUnit={intensityUnit}
          unit={unit}
          selectedTimePeriod={selectedTimePeriod}
          minimapPosition={minimapPosition}
          onMinimapPositionChange={onMinimapPositionChange}
        />
      ) : (
        <ExpandedTableView
          chartData={chartData}
          chartKeys={chartKeys}
          unit={unit}
        />
      )}
    </div>
  );
};

const ExpandedPerformanceTimelineChartView = ({
  isShowExpandedChartView,
  onCloseModal,
  title,
  period,
  subtopic,
  intensity,
  intensityUnit,
  chartData,
  chartKeys,
  unit,
  hasDataGaps,
  selectedExpandedViewOption,
  onSelectExpandedViewOption,
  selectedTimePeriodOption,
  onSelectTimePeriodOption,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
  minimapPosition,
  onMinimapPositionChange,
}) => {
  return (
    <>
      <DashboardExpandedModalView
        id={performanceTimelineChartConfig.expandedChartViewId}
        isShow={isShowExpandedChartView}
        closeModal={onCloseModal}
        hideCloseButton={isDownloadingChart}
        header={
          <ExpandedPerformanceTimelineChartViewHeader
            title={title}
            period={period}
            subtopic={subtopic}
            intensity={intensity}
            hasDataGaps={hasDataGaps}
            selectedExpandedViewOption={selectedExpandedViewOption}
            onSelectExpandedViewOption={onSelectExpandedViewOption}
            selectedTimePeriodOption={selectedTimePeriodOption}
            onSelectTimePeriodOption={onSelectTimePeriodOption}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        }
        body={
          <ExpandedPerformanceTimelineChartViewBody
            chartData={chartData}
            chartKeys={chartKeys}
            unit={unit}
            intensityUnit={intensityUnit}
            selectedTimePeriod={selectedTimePeriodOption}
            selectedViewOption={selectedExpandedViewOption}
            minimapPosition={minimapPosition}
            onMinimapPositionChange={onMinimapPositionChange}
          />
        }
      />
      {isShowExpandedChartView && isDownloadingChart && (
        <LoadingSpinnerInFullPage />
      )}
    </>
  );
};

export default ExpandedPerformanceTimelineChartView;
