import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import capitalize from "lodash/capitalize";
import { DateTimeUtils, NumberService } from "../../../services/UtilsService";
import LoadingSpinner from "../../common/LoadingSpinner";
import EnvironmentalService from "../../../services/EnvironmentalService";
import EnvironmentalMeterRecordsService, {
  EnvironmentalMeterRecordsModel,
} from "./EnvironmentalMeterRecordsService";
import {
  ENVIRONMENTAL_DATA,
  ENVIRONMENTAL_DATA_BULK_INPUT,
} from "../../../config/ROUTES_NAME";
import withAuthentication from "../../HOC/withAuthentication";
import InputEnvironmentalDataRecordModal from "./InputEnvironmentalDataRecordModal";
import reduce from "lodash/reduce";
import { PERMISSIONS, RESOURCES } from "../../../config/constants";
import {
  getDefaultMonthRangeEnd,
  getDefaultMonthRangeStart,
} from "../../common/MonthRangeSelector";
import DeleteEnvironmentalDataRecordPrompt from "./DeleteEnvironmentalDataRecordPrompt";
import ToastContext from "../../../context/ToastContext";
import { MeterStatus } from "../MeterRecordsService";
import UserInventoryContext from "../../../context/UserInventoryContext";
import DataRequestService from "../../../services/DataRequestService";
import uniq from "lodash/uniq";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import PermissionsContext from "../../../context/PermissionsContext";
import CommentTooltip from "../../common/Tooltip/CommentTooltip";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "../../common/ReactHook";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../../gvds-components/Buttons/GVDSIconButton";
import GVDSFormStartEndMonthPicker from "../../../gvds-components/Forms/GVDSFormStartEndMonthPicker";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import Spacer from "../../../gvds-components/Layout/Spacer";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCirclePlus, IconEdit, IconTrash } from "@tabler/icons-react";
import GVDSPagination from "../../../gvds-components/Table/Controls/GVDSPagination";
import GVDSTableCtrlContainer from "../../../gvds-components/Table/Controls/GVDSTableCtrlContainer";
import GVDSTable, {
  ACTIONS_DATAKEY,
} from "../../../gvds-components/Table/GVDSTable";
import useGVDSTableCtrl from "../../../gvds-components/Table/GVDSTableHook";
import Dropdown from "react-bootstrap/Dropdown";
import GVDSDropdownToggle from "../../../gvds-components/Buttons/GVDSDropdownToggle";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import InputAlertPreferencesModal from "../InputAlertPreferencesModal";
import MeterDismissAlertsBanner from "../MeterDismissAlertsBanner";
import MeterPossibleIssuesBanner from "../MeterPossibleIssuesBanner";
import MeterPossibleIssuesTurnedOffBanner from "../MeterPossibleIssuesTurnedOffBanner";
import { Trans, useTranslation } from "react-i18next";
import InlineSpinner from "../../common/InlineSpinner";

const ViewEnvironmentalMeterDetails = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();

  const permissionCtx = useContext(PermissionsContext);
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const previousInventory = usePrevious(selectedInventory);

  const [start, setStart] = useState(getDefaultMonthRangeStart());
  const [end, setEnd] = useState(getDefaultMonthRangeEnd());

  const [meter, setMeter] = useState({});
  const [dataset, setDataset] = useState();

  const [newDataRequestDetails, setNewDataRequestDetails] = useState({});
  const [dataRequests, setDataRequests] = useState([]);
  const [hasMissingDataRequest, setHasMissingDataRequest] = useState(false);

  const [meterRecords, setMeterRecords] = useState({});
  const [dataIssues, setDataIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataRequestsLoading, setIsDataRequestsLoading] = useState(true);
  const meterRecordsService = useRef(new EnvironmentalMeterRecordsService());

  const [currentlyEditedRecordModel, setCurrentlyEditedRecordModel] =
    useState(null);
  const [showInputModal, setShowInputModal] = useState(false);
  const [showAlertPreferencesModal, setShowAlertPreferencesModal] =
    useState(false);

  const handleCloseEditModal = () => {
    setCurrentlyEditedRecordModel(null);
    setShowInputModal(false);
  };

  const [
    currentlyToBeDeletedRecordModels,
    setCurrentlyToBeDeletedRecordModels,
  ] = useState([]);
  const [showPromptDeleteModal, setShowPromptDeleteModal] = useState(false);
  const handleClosePromptDeleteModal = () => {
    setShowPromptDeleteModal(false);
  };

  const pastDataMeterRecords = useMemo(
    () => meterRecords.records || [],
    [meterRecords.records]
  );

  const {
    currentPageData,
    startIndex,
    endIndex,
    totalDataLength,
    onPaginationChange,
    sortKeys,
    setSortKeys,
  } = useGVDSTableCtrl(pastDataMeterRecords, null);

  useEffect(() => {
    const userInventoryFacilityIds = userInventory.facilities.get.map(
      (facility) => facility.id
    );
    const selectedMeterFacilityId = location.state?.dataset?.facility?.id;

    if (
      location.state &&
      userInventoryFacilityIds.includes(selectedMeterFacilityId)
    ) {
      setMeter(location.state.meter);
      setDataset(location.state.dataset);
    } else {
      backToDashboard();
    }
  }, []);

  useEffect(() => {
    if (previousInventory && previousInventory !== selectedInventory) {
      backToDashboard();
    }
  }, [selectedInventory]);

  useEffect(() => {
    if (dataset && dataset["facility"]?.id) {
      loadDataRequests(dataset["facility"]["id"]).then(() =>
        setIsDataRequestsLoading(false)
      );
    }
  }, [dataset]);

  useEffect(() => {
    if (newDataRequestDetails.id) {
      setDataRequests([...dataRequests, newDataRequestDetails]);
    }
  }, [newDataRequestDetails.id]);

  useEffect(() => {
    if (meter.id) {
      setHasMissingDataRequest(
        reduce(
          dataRequests,
          (result, dr) =>
            result ||
            dr.hasMissingDataForMeter(
              meter.id,
              meter.start_date,
              meter.end_date
            ),
          false
        )
      );
    }
  }, [meter, dataRequests]);

  const loadDataRequests = async (facilityId) => {
    const dRequests = await DataRequestService.getOngoingDataRequests(
      facilityId,
      RESOURCES.FACILITY
    );
    for (let dId of uniq(dRequests.map((r) => r.id))) {
      const details = await DataRequestService.getDataRequest(
        dId,
        facilityId,
        RESOURCES.FACILITY
      );
      setNewDataRequestDetails(details);
    }
  };

  const loadDataRecords = () => {
    setIsLoading(true);
    EnvironmentalService.getDataRecordsForMeter(
      selectedInventory.type,
      selectedInventory.id,
      meter.id,
      start,
      end
    )
      .then((data) => {
        const newMeterRecords = new EnvironmentalMeterRecordsModel(
          data,
          dataset["facility"]["id"],
          dataset["facility"]["name"]
        );

        setMeterRecords(newMeterRecords);
        meterRecordsService.current.updateMeter(meter, newMeterRecords);
        setDataIssues(meterRecordsService.current.getDataIssues(end));
        setMeter({
          ...meter,
          last_record_date: DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            newMeterRecords.lastRecordDate
          ),
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toastContext.addFailToast(
          <span>Failed to load data records for meter.</span>
        );
      });
  };

  const reloadAllData = () => {
    setIsDataRequestsLoading(true);
    setDataRequests([]);
    loadDataRecords();
    loadDataRequests(dataset["facility"]["id"]).then(() =>
      setIsDataRequestsLoading(false)
    );
  };

  useEffect(() => {
    if (meter.id) {
      loadDataRecords();
    }
  }, [meter.id, start, end]);

  const backToDashboard = () => {
    history.push(ENVIRONMENTAL_DATA);
  };

  const goToBulkInput = () => {
    history.push(ENVIRONMENTAL_DATA_BULK_INPUT);
  };

  const promptDeleteRecord = (recordModels) => {
    setCurrentlyToBeDeletedRecordModels(recordModels);
    setShowPromptDeleteModal(true);
  };

  const deleteCurrentlySelectedRecordModel = () => {
    EnvironmentalService.deleteDataRecords(
      selectedInventory.type,
      selectedInventory.id,
      currentlyToBeDeletedRecordModels.map((model) => model.id)
    ).then(() => {
      toastContext.addSuccessToast(
        <span>Data record successfully deleted</span>
      );
      setCurrentlyToBeDeletedRecordModels([]);
      reloadAllData();
      handleClosePromptDeleteModal();
    });
  };

  const showEditRecord = (recordModel) => {
    setCurrentlyEditedRecordModel(recordModel);
    setShowInputModal(true);
  };

  if (!meter.id) {
    return (
      <div>{t("data-management.shared.meter-details.meter-not-found")}</div>
    );
  }

  const meterDetails = (
    <Form>
      <Row>
        <Col lg={4}>
          <GVFormGroup>
            <Form.Label>{t("shared.facility")}</Form.Label>
            <div className="gvds-text--body">{dataset["facility"]["name"]}</div>
          </GVFormGroup>
        </Col>
        <Col lg={4}>
          <GVFormGroup>
            <Form.Label>
              {t("data-management.environmental.shared.type")} (
              {t(
                "data-management.environmental.usage.single-input.label-subtopic"
              )}
              )
            </Form.Label>
            <div className="gvds-text--body">
              {meter["environmental_type"]["name"]} (
              {dataset["environmental_subtopic"]["name"]})
            </div>
          </GVFormGroup>
        </Col>
        <Col lg={4}>
          <GVFormGroup>
            <Form.Label>{t("shared-input-label.unit")}</Form.Label>
            <div className="gvds-text--body">
              {meter["default_unit"]["name"]}
            </div>
          </GVFormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <GVFormGroup>
            <Form.Label>
              {t("data-management.shared.meter-details.label-included-in-calc")}
            </Form.Label>
            <div className="gvds-text--body">
              {meter["for_monitoring_only"]
                ? t(
                    "data-management.shared.meter-details.included-in-calc-false"
                  )
                : t(
                    "data-management.shared.meter-details.included-in-calc-true"
                  )}
            </div>
          </GVFormGroup>
        </Col>
        <Col lg={4}>
          <GVFormGroup>
            <Form.Label>
              {t("data-management.environmental.shared.frequency")}
            </Form.Label>
            <div className="gvds-text--body d-flex flex-row align-items-center">
              <span className="me-1">{capitalize(meter["frequency"])} </span>
              <InfoTooltip
                info={
                  <>
                    <div className="mb-1">
                      {t("data-management.shared.meter-frequency-description")}
                    </div>
                    <div>
                      {t("data-management.shared.meter-frequency-example")}
                    </div>
                  </>
                }
                placement="top"
              />
            </div>
          </GVFormGroup>
        </Col>
      </Row>
    </Form>
  );

  const columns = [
    {
      header: t("shared-input-label.period-from"),
      dataKey: "periodFrom",
      sortable: true,
      renderer: (d) => DateTimeUtils.formatUTCDate(d["periodFrom"]),
    },
    {
      header: t("shared-input-label.period-to"),
      dataKey: "periodTo",
      sortable: true,
      renderer: (d) => DateTimeUtils.formatUTCDate(d["periodTo"]),
    },
    {
      header: t("data-management.environmental.usage.single-input.label-usage"),
      dataKey: "usage",
      sortable: true,
      renderer: (d) => NumberService.format(d["usage"], 2, 2),
    },
    { header: t("shared-input-label.unit"), dataKey: "unit_name" },
    {
      header: t("data-management.environmental.usage.single-input.label-cost"),
      dataKey: "cost",
      sortable: true,
      renderer: (d) => NumberService.format(d["cost"], 2, 2),
    },
    { header: t("shared-input-label.currency"), dataKey: "currency_name" },
    {
      header: t("shared-input-label.comments"),
      dataKey: "comments",
      sortable: true,
      renderer: (d) =>
        d["comments"] ? <CommentTooltip comment={d["comments"]} /> : null,
    },
    {
      header: t("shared-input-label.actions"),
      dataKey: ACTIONS_DATAKEY,
      renderer: (d) => {
        return (
          <>
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.ENVIRONMENTAL_RECORD_EDIT
              ] && (
                <GVDSIconButton
                  variant={iconButtonVariant.tertiary}
                  icon={<GVDSIcon Icon={IconEdit} />}
                  onClick={() => {
                    showEditRecord(d);
                  }}
                />
              )}
            {!permissionCtx.isLoadingPermissions &&
              permissionCtx.permissions[
                PERMISSIONS.ENVIRONMENTAL_RECORD_DELETE
              ] && (
                <GVDSIconButton
                  variant={iconButtonVariant.destructive}
                  icon={<GVDSIcon Icon={IconTrash} />}
                  onClick={() => {
                    promptDeleteRecord([d]);
                  }}
                />
              )}
          </>
        );
      },
    },
  ];

  const pastRecords = (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : !meter["enable_data_gaps_alert"] &&
        !meter["enable_unusually_high_low_alert"] ? (
        <MeterPossibleIssuesTurnedOffBanner
          showAlertPreferencesModal={() => setShowAlertPreferencesModal(true)}
        />
      ) : (
        <>
          <MeterDismissAlertsBanner
            dismissAlertsDate={meter["dismiss_alerts_date"]}
            showAlertPreferencesModal={() => setShowAlertPreferencesModal(true)}
          />
          {(meterRecordsService.current.getMeterDetailStatus(end) ===
            MeterStatus.ISSUES_DETECTED ||
            hasMissingDataRequest) && (
            <MeterPossibleIssuesBanner
              dataIssues={dataIssues}
              dataRequests={dataRequests}
              meter={meter}
              showAlertPreferencesModal={() =>
                setShowAlertPreferencesModal(true)
              }
            />
          )}
        </>
      )}

      {isLoading || !meterRecords.records ? (
        <LoadingSpinner />
      ) : (
        <GVDSTable
          className="past-records"
          columns={columns}
          dataToDisplay={currentPageData}
          startIndex={startIndex}
          sortKeys={sortKeys}
          setSortKeys={setSortKeys}
          noContent={
            <div className="table__no_content">
              <p>
                {t(
                  "data-management.shared.meter-details.record-table.no-content"
                )}
              </p>
            </div>
          }
          viewControl={
            <GVDSTableCtrlContainer>
              <div className="date-range-container">
                <GVDSFormStartEndMonthPicker
                  startMonth={start}
                  endMonth={end}
                  onChange={(s, e) => {
                    if (s !== start) {
                      setStart(s);
                    }
                    if (e !== end) {
                      setEnd(e);
                    }
                  }}
                />
              </div>
              <GVDSPagination
                startIndex={startIndex}
                endIndex={endIndex}
                total={totalDataLength}
                onChange={onPaginationChange}
              />
            </GVDSTableCtrlContainer>
          }
        />
      )}
    </>
  );

  const lastRecordDate = isLoading ? (
    <InlineSpinner />
  ) : (
    meterRecordsService.current.getLastRecordDateDisplay(
      t,
      meter.last_record_date
    )
  );

  const onAlertPreferencesUpdate = (
    dismissAlertDate,
    enableDataGapsAlert,
    enableAbnormalUsageCost,
    effectivePeriodStartDate,
    effectivePeriodEndDate
  ) => {
    setShowAlertPreferencesModal(false);
    setMeter({
      ...meter,
      dismiss_alerts_date: DateTimeUtils.getUTCISOString(dismissAlertDate),
      enable_data_gaps_alert: enableDataGapsAlert,
      enable_unusually_high_low_alert: enableAbnormalUsageCost,
      start_date: DateTimeUtils.getUTCISOString(effectivePeriodStartDate),
      end_date: DateTimeUtils.getUTCISOString(effectivePeriodEndDate),
    });
  };

  const backButtonPage = t("data-management.environmental.tab-data-input");

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text={
            <Trans i18nKey="shared.button-return-to-page">
              Return to {{ backButtonPage }}
            </Trans>
          }
          onClick={backToDashboard}
        />
        <PageHeader.Title>
          <div>
            <h1>{meter.name}</h1>
            {lastRecordDate && (
              <div className="gvds-text--body mt-1">{lastRecordDate}</div>
            )}
          </div>
          <Spacer />
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.ENVIRONMENTAL_METER_MANAGEMENT
            ] && (
              <GVDSButton
                variant={buttonVariant.secondary}
                className="btn-setup-alert-preferences"
                onClick={() => setShowAlertPreferencesModal(true)}
                text={t("data-management.shared.alert-preferences.title")}
              />
            )}
          {!permissionCtx.isLoadingPermissions &&
            permissionCtx.permissions[
              PERMISSIONS.ENVIRONMENTAL_RECORD_CREATE
            ] && (
              <Dropdown className="guided-tour-target__environment-input">
                <Dropdown.Toggle as={GVDSDropdownToggle}>
                  {t("data-management.environmental.usage.input-button.label")}
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {
                    <Dropdown.Item onClick={() => setShowInputModal(true)}>
                      <GVDSIcon Icon={IconCirclePlus} />{" "}
                      {t(
                        "data-management.environmental.usage.input-button.single"
                      )}
                    </Dropdown.Item>
                  }
                  {
                    <Dropdown.Item onClick={() => goToBulkInput()}>
                      <GVDSIcon Icon={IconCirclePlus} />{" "}
                      {t(
                        "data-management.environmental.usage.input-button.bulk"
                      )}
                    </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
            )}
        </PageHeader.Title>
      </PageHeader>
      {meterDetails}
      {pastRecords}
      <InputEnvironmentalDataRecordModal
        show={showInputModal}
        onClose={handleCloseEditModal}
        currentRecord={currentlyEditedRecordModel}
        datasets={[dataset]}
        meter={meter}
        onDoneInput={reloadAllData}
      />
      <DeleteEnvironmentalDataRecordPrompt
        noOfRecordsToBeDeleted={1}
        show={showPromptDeleteModal}
        cancel={handleClosePromptDeleteModal}
        proceed={deleteCurrentlySelectedRecordModel}
      />
      <InputAlertPreferencesModal
        show={showAlertPreferencesModal}
        setShow={setShowAlertPreferencesModal}
        facilityId={dataset["facility"]?.id}
        meterId={meter.id}
        oldDismissAlertDate={
          meter["dismiss_alerts_date"] &&
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            meter["dismiss_alerts_date"]
          )
        }
        oldEnableDataGapsAlert={meter["enable_data_gaps_alert"]}
        oldEnableAbnormalUsageCost={meter["enable_unusually_high_low_alert"]}
        oldEffectivePeriodStartDate={
          meter["start_date"] &&
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            meter["start_date"]
          )
        }
        oldEffectivePeriodEndDate={
          meter["end_date"] &&
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(meter["end_date"])
        }
        updateAlertPreferences={EnvironmentalService.updateAlertPreferences}
        onAlertPreferencesUpdate={onAlertPreferencesUpdate}
      />
    </div>
  );
};

export default withAuthentication(ViewEnvironmentalMeterDetails);
