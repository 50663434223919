import React, { Fragment, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GVFormGroup from "../../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import GVDSFormTextArea from "../../../../gvds-components/Forms/GVDSFormTextArea";
import SubscriptionSharedUtils, {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  EXCLUDED_DEFAULT_FEATURES,
  getDefaultFullFeatures,
  SubscriptionFeatureSelection,
  SubscriptionPaymentType,
  SubscriptionPricingType,
} from "../SubscriptionSharedUtils";
import { UtilsService } from "../../../../services/UtilsService";
import { GVDSFormErrorMessage } from "../../../../gvds-components/Forms/GVDSFormShared";
import BillingCycle from "../shared/BillingCycle";
import SubscriptionPeriods from "../shared/SubscriptionPeriods";
import SelectCurrency from "../../../Currency/SelectCurrency";
import GVDSBanner from "../../../../gvds-components/common/GVDSBanner";
import SubscriptionCustomPaymentDueDays from "../shared/SubscriptionCustomPaymentDueDays";
import { SUBSCRIPTION_PAYMENT } from "../SubscriptionPaymentBadge";

const CurrencyPaymentType = ({
  model,
  onDataChange,
  isValidated,
  isCustomPlan,
  disabled,
}) => {
  return (
    <>
      <GVFormGroup controlId="inputPaymentType" className="mt-3">
        <Form.Label className="gvds-text--formLabel">
          Centralized / Decentralized Payment
        </Form.Label>
        <div className="subscription__payment-type">
          <Form.Check
            id="centralized"
            label="Centralized. Contract pays for all sites."
            type="radio"
            checked={model?.paymentType === SubscriptionPaymentType.CENTRALIZED}
            onChange={() => {
              model.paymentType = SubscriptionPaymentType.CENTRALIZED;
              onDataChange();
            }}
            disabled={disabled}
          />
          <Form.Check
            id="decentralized"
            label="Decentralized. Each site pays for itself."
            type="radio"
            checked={
              model?.paymentType === SubscriptionPaymentType.DECENTRALIZED
            }
            onChange={() => {
              model.paymentType = SubscriptionPaymentType.DECENTRALIZED;
              onDataChange();
            }}
            disabled={disabled}
          />
        </div>
        {isValidated && !model.isPaymentTypeValid() && (
          <GVDSFormErrorMessage errorMsg="This field cannot be empty." />
        )}
      </GVFormGroup>
      <Row>
        <Col lg={3}>
          <GVFormGroup controlId="inputCurrency">
            <Form.Label className="gvds-text--formLabel">Currency</Form.Label>
            <SelectCurrency
              selected={model.currencyId}
              onCurrencySelected={(currencyId) => {
                model.currencyId = currencyId;
                onDataChange();
              }}
              showLabel={false}
              isInvalid={isValidated && !model.isCurrencyValid()}
              invalidMessage="This field cannot be empty."
              disabled={disabled || !isCustomPlan}
            />
          </GVFormGroup>
        </Col>
      </Row>
      {model.paymentType === SubscriptionPaymentType.CENTRALIZED ? (
        <div className="subscription__tax-switch__container">
          <div className="tax-switch-label">Apply GST</div>
          <Form.Check
            className="tax-switch"
            type="switch"
            checked={model.isTaxApplicable}
            onChange={() => {
              model.isTaxApplicable = !model.isTaxApplicable;
              onDataChange();
            }}
            disabled={disabled}
          />
          <div className="tax-switch-caption">
            GST should be applied for entities based in Singapore
          </div>
        </div>
      ) : (
        <div className="subscription__tax-switch__container">
          <div className="tax-switch-label">Apply GST</div>
          <div className="gvds-text--caption ms-2">
            (For Decentralized, GST will be applied per site for sites in
            Singapore)
          </div>
        </div>
      )}
      <SubscriptionCustomPaymentDueDays
        model={model}
        onDataChange={onDataChange}
        disabled={disabled}
      />
    </>
  );
};

const InputContractSubscriptionContractTerms = ({
  model,
  onDataChange,
  allFeatures,
  orderedPackagePlans,
  originalSelectedFeatures,
  isValidated,
  disabled,
}) => {
  const [showUncheckedFeatureBanner, setShowUncheckedFeatureBanner] =
    useState(false);

  const plan = orderedPackagePlans.find(
    (p) => p.package_plan_id === model.planId
  );
  const isCustomPlan = plan?.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;

  const onPlanChange = (plan, isCustomPlan = false) => {
    model.planId = plan.package_plan_id;
    if (!isCustomPlan) {
      model.features = plan.feature_names.map((name) => {
        return allFeatures.find((feature) => feature.name === name);
      });
    } else {
      model.features = getDefaultFullFeatures(allFeatures);
    }
    model.pricingType = null;
    model.billingCycle = SubscriptionSharedUtils.getDefaultBillingCycle(plan);
    SubscriptionSharedUtils.updateSubscriptionTermsBasedOnPlanAndBillingCycle(
      model,
      model.billingCycle,
      plan
    );

    onDataChange();
  };

  const onBillingCycleChange = () => {
    const plan = orderedPackagePlans.find(
      (p) => p.package_plan_id === model.planId
    );
    const isCustomPlan = plan?.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;

    let sitePrice = null;

    if (!isCustomPlan) {
      const price = plan?.prices.find(
        (price) => price.billing_cycle === model.billingCycle
      );
      sitePrice = price?.price_in_decimal;
    }

    model.siteSubscriptions
      .filter((siteSub) => siteSub.isNew())
      .forEach((subscription) => {
        subscription.priceAmount = sitePrice;
      });

    const defaultReminderDate = SubscriptionSharedUtils.getDefaultReminderDate(
      model,
      model.billingCycle,
      model.pricingType
    );
    if (defaultReminderDate !== null) {
      model.reminderDate = defaultReminderDate;
    }

    if (model.pricingType === SubscriptionPricingType.FREE) {
      model.siteSubscriptions.forEach((ss) => {
        ss.paymentStatus = SUBSCRIPTION_PAYMENT.FREE;
      });
    } else {
      model.siteSubscriptions.forEach((ss) => {
        ss.paymentStatus = SUBSCRIPTION_PAYMENT.UNPAID;
      });
    }

    onDataChange();
  };

  const featureList = (
    <SubscriptionFeatureSelection
      allFeatures={allFeatures}
      subscriptionModel={model}
      onFeatureSelected={(feature) => toggleContractFeature(feature)}
      disabled={
        disabled ||
        SubscriptionSharedUtils.isSubscriptionPackagePlanNotCustom(
          orderedPackagePlans,
          model.planId
        )
      }
    />
  );

  const toggleContractFeature = (feature) => {
    model.features = UtilsService.toggleItem(model.features, feature);
    const uncheckedFeatures = originalSelectedFeatures.filter(
      (f) => model.features.findIndex((mf) => mf.id === f.id) < 0
    );
    if (uncheckedFeatures.length > 0) {
      setShowUncheckedFeatureBanner(true);
      updateSiteSubscriptionFeatures(uncheckedFeatures);
    } else {
      setShowUncheckedFeatureBanner(false);
    }
    onDataChange();
  };

  const updateSiteSubscriptionFeatures = (uncheckedFeatures) => {
    model.siteSubscriptions.forEach((ss) => {
      if (ss.underContractIsFeaturesBreakFromContract()) {
        uncheckedFeatures.forEach((uf) => {
          const index = ss.features.findIndex((ssf) => ssf.id === uf.id);
          if (index >= 0) {
            ss.features.splice(index, 1);
          }
        });
      }
    });
  };

  return (
    <>
      <Row>
        <Col lg={8}>
          <GVFormGroup controlId="inputComments">
            <Form.Label className="gvds-text--formLabel">
              Comments <span className="optional-form-label">(optional)</span>
            </Form.Label>
            <GVDSFormTextArea
              rows={3}
              value={model.comments}
              onInput={(value) => {
                model.comments = value;
                onDataChange();
              }}
            />
          </GVFormGroup>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={5}>
          <GVFormGroup controlId="plan">
            <Form.Label className="gvds-text--formLabel">Plan</Form.Label>
            <div className="subscription__select-plan">
              {orderedPackagePlans.map((plan) => {
                const isCustomPlan =
                  plan.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME;

                return (
                  <Fragment key={plan.package_plan_id}>
                    <Form.Check
                      id={plan.package_plan_id}
                      label={
                        <strong>
                          {isCustomPlan
                            ? CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME
                            : plan.name}
                        </strong>
                      }
                      type="radio"
                      checked={model?.planId === plan.package_plan_id}
                      onChange={() => {
                        onPlanChange(plan, isCustomPlan);
                      }}
                      disabled={disabled}
                    />
                    {model?.planId === plan.package_plan_id && (
                      <BillingCycle
                        isValidated={isValidated}
                        packagePlan={plan}
                        model={model}
                        onDataChange={() => {
                          onBillingCycleChange();
                        }}
                        disabled={disabled}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
            {isValidated && !model.isPlanValid() && (
              <GVDSFormErrorMessage errorMsg="This field cannot be empty." />
            )}
          </GVFormGroup>
        </Col>
        <Col lg={7}>
          <GVFormGroup
            controlId="subscribedFeatures"
            className="contract-subscription__contract-features"
          >
            <Form.Label className="gvds-text--formLabel">
              Subscribed Features
            </Form.Label>
            {showUncheckedFeatureBanner && (
              <GVDSBanner
                title="Unchecking a previously enabled feature will result in the feature being inaccessible for existing site subscriptions"
                variant={GVDSBanner.Variants.warning}
                className="mt-2"
              >
                Site subscriptions can only include features enabled in the
                contract subscription. Once you save, all site subscriptions
                will be updated accordingly.
              </GVDSBanner>
            )}
            {featureList}
            {isValidated && !model.isFeaturesValid() && (
              <GVDSFormErrorMessage errorMsg="At least 1 feature should be selected." />
            )}
          </GVFormGroup>
        </Col>
      </Row>
      <hr />
      <SubscriptionPeriods
        className="contract-subscription"
        model={model}
        onDataChange={onDataChange}
        isValidated={isValidated}
        disabled={disabled}
      />
      {model.isPaidSubscription() && (
        <>
          <hr />
          <CurrencyPaymentType
            model={model}
            isValidated={isValidated}
            onDataChange={onDataChange}
            isCustomPlan={isCustomPlan}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
};

export default InputContractSubscriptionContractTerms;
