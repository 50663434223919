import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { breakpoints } from "../../../config/style-config";
import GVDSIconButton, {
  iconButtonVariant,
} from "../../Buttons/GVDSIconButton";

const TopNavItem = ({ icon, text, onClick, className }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const onResize = () => {
    if (typeof window !== "undefined") {
      const width = window.innerWidth;

      if (width <= breakpoints.laptop) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  if (!isSmallScreen) {
    return (
      <Button
        className={`gvds-top-nav--item ${className ?? ""}`}
        onClick={onClick}
      >
        {icon}
        {!!text && <div className="gvds-top-nav--item--text">{text}</div>}
      </Button>
    );
  } else {
    return (
      <GVDSIconButton
        variant={iconButtonVariant.tertiary}
        icon={icon}
        tooltipText={text}
        placement="bottom"
        onClick={onClick}
        className={className ?? ""}
      />
    );
  }
};

export default TopNavItem;
