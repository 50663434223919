import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { cloneDeep } from "lodash";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import InputPeopleRecordTable from "./InputPeopleRecordTable";
import UserInventoryContext from "../../../../context/UserInventoryContext";
import ToastContext from "../../../../context/ToastContext";
import PeopleService, {
  PEOPLE_SUBTOPIC,
  TOTAL_PEOPLE_CATEGORY,
} from "../../../../services/PeopleService";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { DateTimeUtils } from "../../../../services/UtilsService";
import PermissionsContext from "../../../../context/PermissionsContext";
import { PERMISSIONS } from "../../../../config/constants";
import GVFormGroup from "../../../common/GVFormGroup";
import Delayed from "../../../common/Delayed";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../../gvds-components/Modals/GVDSModal";
import GVDSFormField from "../../../../gvds-components/Forms/GVDSFormField";
import GVDSFormSingleMonthPicker from "../../../../gvds-components/Forms/GVDSFormSingleMonthPicker";
import { FormFieldStatusMetadata } from "../../../../gvds-components/Forms/GVDSFormShared";

const EXISTING_PEOPLE_RECORD_ERROR_VALIDATION =
  "A data record already exists in the system for the time period that you have entered.";
const LAST_MONTH_DATE = new Date();
LAST_MONTH_DATE.setDate(0);

const inputRecordDetailsReducer = (inputRecordDetails, action) => {
  switch (action.type) {
    case "updateRecordMonth":
      return { ...inputRecordDetails, record_month: action.payload };
    case "updateComment":
      return { ...inputRecordDetails, comment: action.payload };
    case "updateCategoriesRecord":
      return { ...inputRecordDetails, categories_record: action.payload };
    case "reset":
      return action.payload;
    default:
      throw new Error();
  }
};

const InputPeopleRecordModal = ({
  showModal,
  onClose,
  meterDetails,
  currentRecord,
  onDoneInput,
}) => {
  const permissionCtx = useContext(PermissionsContext);
  const isUserAllowedToEdit =
    !permissionCtx.isLoadingPermissions &&
    permissionCtx.permissions[PERMISSIONS.PEOPLE_RECORD_EDIT];
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);

  const selectedInventory = userInventory.selectedInventory.get;
  const [inputRecordDetails, dispatchInputRecordDetails] = useReducer(
    inputRecordDetailsReducer,
    {}
  );

  const [showTable, setShowTable] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isEditExistingRecord, setIsEditExistingRecord] = useState(false);
  const [isRecordMonthAlreadyExist, setIsRecordMonthAlreadyExist] =
    useState(false);

  const closeModal = () => {
    setIsValidated(false);
    setShowTable(false); // for performance
    onClose();
  };

  const recordMonthRef = useRef();

  useEffect(() => {
    if (showModal) {
      setShowTable(true);
    }
  }, [showModal]);

  const resetInputFields = () => {
    if (meterDetails) {
      const resetDetails = {};
      resetDetails.meter_id = meterDetails.id;
      resetDetails.groupings = meterDetails.groupings;

      let rows = [];

      if (meterDetails.groupings.length === 0) {
        rows.push([TOTAL_PEOPLE_CATEGORY]);
      } else {
        for (const grouping of meterDetails.groupings.slice().reverse()) {
          if (rows.length === 0) {
            for (const category of grouping.categories) {
              rows.push([category]);
            }
          } else {
            const updatedRows = [];
            for (const category of grouping.categories) {
              for (const row of rows) {
                updatedRows.push([category, ...row]);
              }
            }
            rows = updatedRows;
          }
        }
      }

      resetDetails.categories_record = rows.map((row) => {
        return { categories: row, value: "" };
      });

      dispatchInputRecordDetails({ type: "reset", payload: resetDetails });
    }
  };

  useEffect(() => {
    if (meterDetails) {
      if (currentRecord) {
        setIsEditExistingRecord(true);
        const record = cloneDeep(currentRecord);
        record.record_month =
          DateTimeUtils.getDateFromUTCISOStringIgnoreTimezone(
            currentRecord.record_month
          );
        dispatchInputRecordDetails({ type: "reset", payload: record });
      } else {
        setIsEditExistingRecord(false);
        resetInputFields();
      }
    }
  }, [meterDetails, currentRecord]);

  const changeInputRecordMonth = (date) => {
    dispatchInputRecordDetails({ type: "updateRecordMonth", payload: date });
  };

  const changeInputRecordCategoriesRecord = useCallback(
    (newCategoriesRecord) => {
      dispatchInputRecordDetails({
        type: "updateCategoriesRecord",
        payload: newCategoriesRecord,
      });
    },
    []
  );

  const changeInputComment = (comment) => {
    dispatchInputRecordDetails({ type: "updateComment", payload: comment });
  };

  const isRecordMonthValid = () => {
    return inputRecordDetails.record_month;
  };

  const isInputRecordValid = () => {
    return isRecordMonthValid();
  };

  const isSubtopicForYTD = () => {
    return meterDetails.subtopic.name === PEOPLE_SUBTOPIC.TURNOVER;
  };

  const saveRecord = async () => {
    setIsValidated(true);

    if (isInputRecordValid()) {
      try {
        setIsLoading(true);
        if (!isEditExistingRecord) {
          await PeopleService.createPeopleRecord(
            selectedInventory.id,
            inputRecordDetails.meter_id,
            inputRecordDetails.record_month,
            inputRecordDetails.groupings,
            inputRecordDetails.categories_record,
            inputRecordDetails.comment
          );
        } else {
          await PeopleService.updatePeopleRecord(
            selectedInventory.id,
            currentRecord.id,
            inputRecordDetails.record_month,
            inputRecordDetails.groupings,
            inputRecordDetails.categories_record,
            inputRecordDetails.comment
          );
        }

        toastContext.addSuccessToast(
          <span>
            Data record {isEditExistingRecord ? "edited" : "added"}{" "}
            successfully.
          </span>
        );
        setIsLoading(false);
        resetInputFields();
        if (onDoneInput) {
          onDoneInput();
        }

        closeModal();
      } catch (error) {
        setIsLoading(false);
        if (error?.response?.status === 400) {
          if (
            error.response.data.message ===
            EXISTING_PEOPLE_RECORD_ERROR_VALIDATION
          ) {
            setIsRecordMonthAlreadyExist(true);
          } else {
            toastContext.addFailToast(
              <span>{error.response.data.message}</span>
            );
          }
        } else {
          toastContext.addFailToast(<span>Failed to add data record.</span>);
        }
      }
    } else {
      if (!isRecordMonthValid() && recordMonthRef.current) {
        recordMonthRef.current.scrollIntoView();
      }
    }
  };

  if (!meterDetails) {
    return null;
  }

  const getErrorMessage = () => {
    return !isRecordMonthValid()
      ? FormFieldStatusMetadata.getError("Please enter record month.")
      : isRecordMonthAlreadyExist
      ? FormFieldStatusMetadata.getError(
          "A data record already exists in the system for the time period that you have entered."
        )
      : FormFieldStatusMetadata.getDefault();
  };

  return (
    <GVDSModal
      title={`Input ${meterDetails.subtopic.name} Data Record`}
      size={GVDSModal.Size.medium}
      show={showModal}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <GVFormGroup controlId="subtopic">
              <Form.Label>Subtopic</Form.Label>
              <GVDSFormField
                value={meterDetails.subtopic.name}
                disabled={true}
              />
            </GVFormGroup>

            <GVFormGroup ref={recordMonthRef}>
              <Form.Label>Month Year</Form.Label>
              {isSubtopicForYTD() ? (
                <>
                  <div className="mb-2">
                    {meterDetails.subtopic.name} records are always inputted for
                    the beginning of the selected year till the selected month
                    year.
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div className="flex-grow-0 flex-shrink-0">
                      Jan{" "}
                      {isRecordMonthValid()
                        ? inputRecordDetails.record_month.getFullYear()
                        : "####"}
                    </div>
                    <div className="mx-4">-</div>
                    <GVDSFormSingleMonthPicker
                      className={"people-record-month"}
                      selected={inputRecordDetails.record_month}
                      onChange={(date) => changeInputRecordMonth(date)}
                      maxMonth={LAST_MONTH_DATE}
                      disabled={!isUserAllowedToEdit}
                      statusMetadata={
                        isValidated
                          ? getErrorMessage()
                          : FormFieldStatusMetadata.getDefault()
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <GVDSFormSingleMonthPicker
                    className={"people-record-month"}
                    selected={inputRecordDetails.record_month}
                    onChange={(date) => changeInputRecordMonth(date)}
                    maxDate={LAST_MONTH_DATE}
                    disabled={!isUserAllowedToEdit}
                    statusMetadata={
                      isValidated
                        ? getErrorMessage()
                        : FormFieldStatusMetadata.getDefault()
                    }
                  />
                </>
              )}
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>Data Record Input</Form.Label>
              <div>
                All empty cells within this table will be treated as "0".
                <br />
                This table was configured in the setup page. If updates need to
                be made, navigate to the ‘People Data Setup’ page to do so.
              </div>
              {showTable && (
                <Delayed>
                  <InputPeopleRecordTable
                    inputRecord={inputRecordDetails}
                    onChangeCategoriesRecord={changeInputRecordCategoriesRecord}
                    isValidated={isValidated}
                    disabled={!isUserAllowedToEdit}
                  />
                </Delayed>
              )}
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>Record Result</Form.Label>
              <div>Below are the aggregated numbers and relevant records.</div>
              {isInputRecordValid() ? (
                <RecordPreview
                  inputRecordDetails={inputRecordDetails}
                  showYTDperiod={isSubtopicForYTD()}
                  subtopicName={meterDetails.subtopic.name}
                />
              ) : (
                <div className="table__no_content">
                  Preview will only show when “Month Year” field is filled in.
                </div>
              )}
            </GVFormGroup>

            <GVFormGroup>
              <Form.Label>
                Comment <span className="optional-form-label">(optional)</span>
              </Form.Label>
              <GVDSFormField
                value={inputRecordDetails.comment}
                onInput={(value) => changeInputComment(value)}
                disabled={!isUserAllowedToEdit}
              />
            </GVFormGroup>
          </>
        )}
      </GVDSModal.Body>
      {isUserAllowedToEdit && (
        <GVDSModal.Footer>
          <GVDSButton
            variant={buttonVariant.tertiary}
            onClick={closeModal}
            disabled={isLoading}
            text="Cancel"
          />
          <GVDSButton
            variant={buttonVariant.primary}
            onClick={saveRecord}
            disabled={isLoading}
            text="Save & Close"
          />
        </GVDSModal.Footer>
      )}
    </GVDSModal>
  );
};

const RecordPreview = ({
  inputRecordDetails,
  showYTDperiod = false,
  subtopicName,
}) => {
  const userInventory = useContext(UserInventoryContext);

  const selectedInventory = userInventory.selectedInventory.get;

  const loadingCalculationTimer = useRef(null);
  const loadingCalculationPromise = useRef(null);
  const [isLoadingCalculatedValues, setIsLoadingCalculatedValues] =
    useState(true);
  const [calculatedValues, setCalculatedValues] = useState(null);

  let groupingsTotal = [...inputRecordDetails.groupings];
  groupingsTotal = groupingsTotal.map((grouping) => {
    let totalGrouping = { ...grouping };
    totalGrouping.categories = totalGrouping.categories.map((category) => {
      const totalCategory = { ...category };
      totalCategory.totalValue = inputRecordDetails.categories_record
        .filter((entry) => entry.categories.find((c) => c.id === category.id))
        .map((entry) => parseFloat(entry.value))
        .map((value) => (isNaN(value) ? 0 : value))
        .reduce((a, b) => a + b, 0);
      return totalCategory;
    });

    return totalGrouping;
  });

  const total = inputRecordDetails.categories_record
    .map((entry) => parseFloat(entry.value))
    .map((value) => (isNaN(value) ? 0 : value))
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    setIsLoadingCalculatedValues(true);

    const calculateValues = async () => {
      PeopleService.getCalculatedValues(
        selectedInventory.id,
        inputRecordDetails.meter_id,
        inputRecordDetails.id ? inputRecordDetails.id : null,
        inputRecordDetails.record_month,
        inputRecordDetails.groupings,
        inputRecordDetails.categories_record,
        inputRecordDetails.comment,
        cancelTokenSource
      ).then((calculatedValues) => {
        setCalculatedValues(calculatedValues);
        setIsLoadingCalculatedValues(false);
      });
    };

    clearTimeout(loadingCalculationTimer.current);

    loadingCalculationTimer.current = setTimeout(async () => {
      const promise = calculateValues();
      loadingCalculationPromise.current = promise;

      promise
        .then(() => {
          loadingCalculationPromise.current = null;
        })
        .catch(() => {
          loadingCalculationPromise.current = null;
        });
    }, 500);

    return () => {
      cancelTokenSource.cancel();
    };
  }, [inputRecordDetails]);

  const getRecordPeriodDisplay = () => {
    return showYTDperiod ? (
      <>
        Record for{" "}
        {DateTimeUtils.formatLocalMonthYear(
          moment(inputRecordDetails.record_month).startOf("year")
        )}{" "}
        to {DateTimeUtils.formatLocalMonthYear(inputRecordDetails.record_month)}
      </>
    ) : (
      <>
        For{" "}
        {DateTimeUtils.formatLocalMonthYear(inputRecordDetails.record_month)}
      </>
    );
  };

  return (
    <div className="p-3">
      <Table className="people-record-preview-table">
        <tbody>
          <tr>
            <td>{getRecordPeriodDisplay()}</td>
            <th>VALUE</th>
          </tr>
          <tr className="people-grouping-row-separator" />
          {groupingsTotal.map((grouping, index) => {
            return (
              <React.Fragment key={`grouping-${index}`}>
                <tr key={grouping.id} className="people-grouping-row">
                  <th>{grouping.name}</th>
                </tr>
                {grouping.categories.map((category) => {
                  return (
                    <tr key={category.id} className="people-category-row">
                      <td>{category.name}</td>
                      <td>{category.totalValue}</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
          <tr className="people-grouping-row-separator" />
          <tr className="people-grouping-row">
            <th>Total</th>
            <td>{total}</td>
          </tr>
        </tbody>
      </Table>
      <div className="mt-4">
        <Table className="people-record-calculation-table">
          <tbody>
            <tr>
              <th>{`${inputRecordDetails.record_month.getFullYear()} ${subtopicName} Summary`}</th>
              <th>VALUE</th>
            </tr>
            <tr className="people-grouping-row-separator" />
            <tr className="people-grouping-row-separator" />
            {isLoadingCalculatedValues ? (
              <tr>
                <td colSpan="2">
                  <div>Loading calculated values...</div>
                </td>
              </tr>
            ) : calculatedValues ? (
              calculatedValues.map((calculatedValue, index) => {
                if (!calculatedValue.label && !calculatedValue.value) {
                  return (
                    <tr key={`calculated-${index}`}>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  );
                }
                return (
                  <tr key={`calculated-${index}`}>
                    <td>{calculatedValue.label}</td>
                    <td>{calculatedValue.value}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="2">No calculated values available</td>
              </tr>
            )}
            <tr className="people-grouping-row-separator" />
            <tr className="people-grouping-row-separator" />
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default InputPeopleRecordModal;
