import React from "react";

import { OptionTransformer } from "../MiniMultiSelect";
import { UserManagedTagDisplay } from "./UserManagedTagDisplay";
import { ManageTagsLink } from "./AboutTags";

const TAG_RESOURCE_SEPARATOR = "+";

export class GenericUserManagedTagModel {
  constructor(id, name, resourceType) {
    this.id = id;
    this.name = name;
    this.resourceType = resourceType;
  }

  get selectionValue() {
    return `${this.resourceType}${TAG_RESOURCE_SEPARATOR}${this.id}`;
  }

  static getOptionTransformer() {
    return new OptionTransformer(
      (o) => o.name,
      (o) => o.selectionValue,
      (o) => (
        <UserManagedTagDisplay tagText={o.name} resourceType={o.resourceType} />
      )
    );
  }

  static getNoTagsCreatedYetMessage = (t) => {
    return (
      <div>
        {t("user-managed-tag.no-tags-have-been-created")}
        <br />
        <ManageTagsLink />
      </div>
    );
  };
}
