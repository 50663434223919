import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import range from "lodash/range";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Spacer from "../Layout/Spacer";
import {
  FormFieldState,
  FormFieldStatusMetadata,
  GVDSFormErrorMessage,
} from "./GVDSFormShared";
import {
  IconCaretDown,
  IconCaretUp,
  IconCheck,
  IconDots,
} from "@tabler/icons-react";
import GVDSIcon from "../Icons/GVDSIcon";

const months = [
  { key: "Jan", value: "January" },
  { key: "Feb", value: "February" },
  { key: "Mar", value: "March" },
  { key: "Apr", value: "April" },
  { key: "May", value: "May" },
  { key: "Jun", value: "June" },
  { key: "Jul", value: "July" },
  { key: "Aug", value: "August" },
  { key: "Sep", value: "September" },
  { key: "Oct", value: "October" },
  { key: "Nov", value: "November" },
  { key: "Dec", value: "December" },
];

const DatePickerDropdownToggle = React.forwardRef(
  ({ children, onClick, icon = IconDots }, ref) => (
    <Button
      variant="link"
      className="gvds-datepicker__header-dropdown"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children} <GVDSIcon Icon={icon} />
    </Button>
  )
);

const DatePickerDropdown = ({ selectedIndex, onChange, options }) => {
  const [isExpand, setIsExpand] = useState(false);

  const itemRef = useRef();
  const scrollDropdownIntoSelectedItem = () => {
    itemRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollDropdownIntoSelectedItem();
  }, [isExpand]);

  return (
    <Dropdown onToggle={() => setIsExpand(!isExpand)}>
      <Dropdown.Toggle
        as={DatePickerDropdownToggle}
        icon={isExpand ? IconCaretUp : IconCaretDown}
      >
        {options[selectedIndex]?.key}
      </Dropdown.Toggle>
      <Dropdown.Menu className="datepicker__dropdown-menu">
        {options.map((option, index) => (
          <Dropdown.Item
            key={option.key}
            className={`datepicker__dropdown-item${
              index === selectedIndex ? " selected" : ""
            }`}
            onClick={() => onChange(index)}
            ref={index === selectedIndex ? itemRef : null}
          >
            {option.value}
            <Spacer />
            {index === selectedIndex && (
              <GVDSIcon Icon={IconCheck} className="selected-item-check" />
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const datePickerHeader = (startYear, endYear, events) => {
  const years = range(startYear, parseInt(endYear) + 1, 1).map((year) => {
    return { key: year, value: year };
  });
  const selectedDate = new Date(events.date);

  return (
    <div className="gvds-datepicker__header-container ">
      <DatePickerDropdown
        selectedIndex={selectedDate.getMonth()}
        onChange={events.changeMonth}
        options={months}
      />
      <DatePickerDropdown
        selectedIndex={selectedDate.getFullYear() - startYear}
        onChange={(index) => events.changeYear(index + startYear)}
        options={years}
      />
    </div>
  );
};

const GVDSFormSingleDatePicker = React.forwardRef(
  (
    {
      className = "",
      placeholder = "Select a date",
      selected,
      onChange,
      startYear = new Date().getFullYear() - 25,
      endYear = startYear + 50,
      dateFormat = "dd/MM/yyyy",
      disabled = false,
      onCalendarOpen,
      onCalendarClose,
      isFocusStyle = false,
      isUseIcon = true,
      statusMetadata = FormFieldStatusMetadata.getDefault(),
      maxDate,
      minDate,
      isPortalling = true,
      ...props
    },
    ref
  ) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const gvdsClassNames = ["gvds-form__single-datepicker-input"];
    switch (statusMetadata.state) {
      case FormFieldState.warning:
        gvdsClassNames.push("gvds-form__warning-border");
        if (isUseIcon) {
          gvdsClassNames.push("gvds-form__warning-icon");
        }
        break;
      case FormFieldState.error:
        gvdsClassNames.push("gvds-form__error-border");
        if (isUseIcon) {
          gvdsClassNames.push("gvds-form__error-icon");
        }
        break;
      default:
        break;
    }
    if ((isCalendarOpen || isFocusStyle) && !disabled) {
      gvdsClassNames.push("on-focus");
    }

    const combinedClassNames = `${gvdsClassNames.join(" ")} ${className || ""}`;

    const handleCalendarOpen = () => {
      setIsCalendarOpen(true);
      if (onCalendarOpen) {
        onCalendarOpen();
      }
    };

    const handleCalendarClose = () => {
      setIsCalendarOpen(false);
      if (onCalendarClose) {
        onCalendarClose();
      }
    };

    return (
      <>
        <DatePicker
          ref={ref}
          renderCustomHeader={(events) =>
            datePickerHeader(startYear, endYear, events)
          }
          selected={selected}
          onChange={(date) => onChange(date)}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          disabled={disabled}
          className={combinedClassNames}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          maxDate={maxDate}
          minDate={minDate}
          portalId={isPortalling ? "portalling-target" : null}
          {...props}
        />
        {statusMetadata.isNotNormal() && statusMetadata.message && (
          <GVDSFormErrorMessage
            status={statusMetadata.state}
            errorMsg={statusMetadata.message}
          />
        )}
      </>
    );
  }
);

export default GVDSFormSingleDatePicker;
