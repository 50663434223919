import axios from "axios";
import { API_URL } from "../config/api-config";
import { SURVEY_STATUS } from "../config/constants";
import { DateTimeUtils, HttpResponseService } from "./UtilsService";

export class SurveyBestPracticeQnModel {
  constructor(question) {
    this.id = question.id;
    this.questionText = question.question_text;
    this.description = question.description;
    this.categories = question.categories;
    this.tags = question.tags;
    this.questionType = question.question_type;
    this.options = question.options.map((o) => {
      return { id: o.id, name: o.option_text };
    });
  }

  get categoryNames() {
    return this.categories.map((category) => category.name);
  }

  get tagNames() {
    return this.tags.map((tag) => tag.name);
  }
}

export default class SurveyManagementService {
  static createSurvey = async (resource_type, resource_id, surveyPayload) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.post(
        `${API_URL}/api/v1/surveys/management?${new URLSearchParams(
          params
        ).toString()}`,
        surveyPayload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static updateSurvey = async (
    resource_type,
    resource_id,
    surveyId,
    surveyPayload
  ) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.put(
        `${API_URL}/api/v1/surveys/management/${surveyId}?${new URLSearchParams(
          params
        ).toString()}`,
        surveyPayload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getAllSurvey = async (resource_type, resource_id, abortControllerSignal) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/surveys/management?${new URLSearchParams(
          params
        ).toString()}`,
        {
          signal: abortControllerSignal
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getSurvey = async (resource_type, resource_id, surveyId) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/surveys/management/${surveyId}?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getBestPracticeQuestions = async (resource_type, resource_id) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/surveys/best_practice_questions?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data.map((q) => new SurveyBestPracticeQnModel(q));
    } catch (error) {
      throw error;
    }
  };

  static validateSurvey = async (resource_type, resource_id, surveyId) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.post(
        `${API_URL}/api/v1/surveys/management/${surveyId}/validate?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static launchSurvey = async (resource_type, resource_id, surveyId) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.post(
        `${API_URL}/api/v1/surveys/management/${surveyId}/launch?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static markSurveyAsClosed = async (resource_type, resource_id, surveyId) => {
    try {
      const params = { resource_type, resource_id };
      const data = { status: SURVEY_STATUS.CLOSED };
      const response = await axios.put(
        `${API_URL}/api/v1/surveys/management/${surveyId}/status?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static extendDeadline = async (
    resource_type,
    resource_id,
    surveyId,
    newDeadline
  ) => {
    try {
      const params = { resource_type, resource_id };
      const data = { deadline: DateTimeUtils.getUTCISOString(newDeadline) };
      const response = await axios.put(
        `${API_URL}/api/v1/surveys/management/${surveyId}/deadline?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static deleteSurvey = async (resource_type, resource_id, surveyId) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.delete(
        `${API_URL}/api/v1/surveys/management/${surveyId}?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static sendTestEmail = async (resource_type, resource_id, survey_id) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.post(
        `${API_URL}/api/v1/surveys/management/${survey_id}/test_email?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static resendEmail = async (
    resource_type,
    resource_id,
    survey_id,
    site_ids
  ) => {
    try {
      const params = { resource_type, resource_id };
      const data = { site_ids };
      const response = await axios.post(
        `${API_URL}/api/v1/surveys/management/${survey_id}/resend_email?${new URLSearchParams(
          params
        ).toString()}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getSurveyResponses = async (resource_type, resource_id, survey_id) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/surveys/management/${survey_id}/responses?${new URLSearchParams(
          params
        ).toString()}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getReport = async (resource_type, resource_id, surveyId) => {
    try {
      const params = { resource_type, resource_id };
      const response = await axios.get(
        `${API_URL}/api/v1/surveys/management/${surveyId}/report?${new URLSearchParams(
          params
        ).toString()}`,
        {
          responseType: "blob",
        }
      );
      HttpResponseService.downloadFileFromResponse(
        response,
        "Survey Report.xlsx"
      );
    } catch (error) {
      throw error;
    }
  };
}
