import React from "react";

import { ScaleLoader } from "react-spinners";
import { GVDSColors } from "../../styles/gvds-colors";

const LoadingSpinner = ({
  color = GVDSColors.tealSolid,
  width = 8,
  height = 35,
  leftAligned = false,
}) => {
  return (
    <div
      className={`center-content loading-spinner ${
        leftAligned ? "left-aligned" : ""
      }`}
    >
      <ScaleLoader color={color} loading={true} width={width} height={height} />
    </div>
  );
};

export const LoadingSpinnerInline = () => {
  return <LoadingSpinner width={4} height={15} leftAligned={true} />;
};

export default LoadingSpinner;
