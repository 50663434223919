import GVDSInfoCard from "../../gvds-components/common/GVDSInfoCard";
import React from "react";
import { useTranslation } from "react-i18next";

const MeterTips = () => {
  const { t } = useTranslation();

  return (
    <GVDSInfoCard
      useIcon={false}
      title={t("data-management.shared.meter-tips.title")}
    >
      <div className="meter-tips-container">
        <ul>
          <li>
            <div className="item-title">
              {t("data-management.shared.meter-tips.tip1")}
            </div>
            <div className="gvds-text--caption">
              {t("data-management.shared.meter-tips.tip1-description")}
            </div>
          </li>
          <li>
            <div className="item-title">
              {t("data-management.shared.meter-tips.tip2")}
            </div>
            <div className="gvds-text--caption">
              {t("data-management.shared.meter-tips.tip2-description")}
            </div>
          </li>
        </ul>
      </div>
    </GVDSInfoCard>
  );
};

export default MeterTips;
