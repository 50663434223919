import React, { useEffect, useState } from "react";
import clone from "lodash/clone";

import { OverlayTrigger, Popover } from "react-bootstrap";
import GVDSButton, {
  buttonVariant,
} from "../../../../gvds-components/Buttons/GVDSButton";
import GVDSFormSingleSelect from "../../../../gvds-components/Forms/GVDSFormSingleSelect";
import GVDSIcon from "../../../../gvds-components/Icons/GVDSIcon";
import { IconFilter } from "@tabler/icons-react";
import PageHeader from "../../../../gvds-components/Layout/PageHeader";
import { SubscriptionPricingType } from "../SubscriptionSharedUtils";

export const filterOptionEnum = {
  ALL_SITES: "ALL_SITES",
  NOT_FOLLOWING_TERM: "NOT_FOLLOWING_TERM",
  FOLLOWING_TERM: "FOLLOWING_TERM",
  PAID_STATUS: "PAID_STATUS",
  UNPAID_STATUS: "UNPAID_STATUS",
};

const filterOptions = [
  { value: filterOptionEnum.ALL_SITES, label: "All sites" },
  {
    value: filterOptionEnum.NOT_FOLLOWING_TERM,
    label: "Only sites not following contract term",
  },
  {
    value: filterOptionEnum.FOLLOWING_TERM,
    label: "Only sites following contract term",
  },
];

const paymentStatusFilterOptions = [
  { value: filterOptionEnum.ALL_SITES, label: "All sites" },
  {
    value: filterOptionEnum.PAID_STATUS,
    label: "Only sites with paid status",
  },
  {
    value: filterOptionEnum.UNPAID_STATUS,
    label: "Only sites with unpaid status",
  },
];

const FilterContractSubscriptionColumnPopUp = ({
  onApplyFilters,
  pricingType,
}) => {
  const [show, setShow] = useState(false);

  const [filters, setFilters] = useState({
    paymentStatus: paymentStatusFilterOptions[0],
    startPeriod: filterOptions[0],
    endPeriod: filterOptions[0],
    features: filterOptions[0],
  });

  useEffect(() => {
    filters.paymentStatus = paymentStatusFilterOptions[0];
    setFilters(clone(filters));
    applyFilters();
  }, [pricingType]);

  const applyFilters = () => {
    onApplyFilters(filters);
  };

  const FilterValue = ({ keyValue, options = filterOptions }) => {
    return (
      <GVDSFormSingleSelect
        value={filters[keyValue]}
        onSelect={(selected) => {
          filters[keyValue] = selected;
          setFilters(clone(filters));
          applyFilters();
        }}
        options={options}
        menuPosition="absolute"
      />
    );
  };

  const clearFilters = () => {
    filters.paymentStatus = paymentStatusFilterOptions[0];
    filters.startPeriod = filterOptions[0];
    filters.endPeriod = filterOptions[0];
    filters.features = filterOptions[0];
    onApplyFilters(null);
  };

  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  const filterDisplay = (
    <>
      <PageHeader>
        <PageHeader.Title>
          <h1>Filter Columns</h1>
        </PageHeader.Title>
      </PageHeader>
      <table className="contract-subscription__filter-column-table">
        <colgroup>
          <col className="label-column" />
          <col className="value-column" />
        </colgroup>
        <tbody>
          {pricingType === SubscriptionPricingType.PAID && (
            <tr>
              <td>Payment Status</td>
              <td>
                <FilterValue
                  keyValue="paymentStatus"
                  options={paymentStatusFilterOptions}
                />
              </td>
            </tr>
          )}
          <tr>
            <td>Start Period</td>
            <td>
              <FilterValue keyValue="startPeriod" />
            </td>
          </tr>
          <tr>
            <td>End Period</td>
            <td>
              <FilterValue keyValue="endPeriod" />
            </td>
          </tr>
          <tr>
            <td>Features</td>
            <td>
              <FilterValue keyValue="features" />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex justify-content-end mt-2">
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={clearFilters}
          text="Clear filters"
        />
      </div>
    </>
  );

  let hasFilters =
    (pricingType !== SubscriptionPricingType.FREE &&
      filters.paymentStatus !== paymentStatusFilterOptions[0]) ||
    filters.startPeriod !== filterOptions[0] ||
    filters.endPeriod !== filterOptions[0] ||
    filters.features !== filterOptions[0];

  return (
    <OverlayTrigger
      show={show}
      placement="top"
      overlay={
        <Popover
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <Popover.Body>{filterDisplay}</Popover.Body>
        </Popover>
      }
    >
      <span>
        <GVDSButton
          variant={
            hasFilters ? buttonVariant.secondary : buttonVariant.tertiary
          }
          className="filter-button"
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          icon={<GVDSIcon Icon={IconFilter} />}
          text="Filter"
        />
      </span>
    </OverlayTrigger>
  );
};

export default FilterContractSubscriptionColumnPopUp;
