export default class PerformanceBenchmarkReportModel {
  constructor(benchmark) {
    this.benchmark = benchmark;
  }

  isDisplayable() {
    return !!(
      this.benchmark?.country?.asset_class?.benchmark ||
      this.benchmark?.country?.asset_class?.missing_data_types?.length > 0 ||
      this.benchmark?.country?.market_segment?.benchmark ||
      this.benchmark?.country?.market_segment?.missing_data_types?.length > 0 ||
      this.benchmark?.country?.hotel_type?.benchmark ||
      this.benchmark?.country?.hotel_type?.missing_data_types?.length > 0 ||
      this.benchmark?.metro_area?.asset_class?.benchmark ||
      this.benchmark?.metro_area?.asset_class?.missing_data_types?.length > 0 ||
      this.benchmark?.metro_area?.market_segment?.benchmark ||
      this.benchmark?.metro_area?.market_segment?.missing_data_types?.length > 0 ||
      this.benchmark?.metro_area?.hotel_type?.benchmark ||
      this.benchmark?.metro_area?.hotel_type?.missing_data_types?.length > 0 ||
      this.benchmark?.climate_zone?.asset_class?.benchmark ||
      this.benchmark?.climate_zone?.asset_class?.missing_data_types?.length > 0 ||
      this.benchmark?.climate_zone?.market_segment?.benchmark ||
      this.benchmark?.climate_zone?.market_segment?.missing_data_types?.length > 0 ||
      this.benchmark?.climate_zone?.hotel_type?.benchmark ||
      this.benchmark?.climate_zone?.hotel_type?.missing_data_types?.length > 0
    );
  }
}
