import { StringUtils } from "../../../services/UtilsService";
import { DISALLOWED_CHARACTERS_IN_EXCEL_REGEX } from "../../../config/constants";

export class SignupModel {
  constructor(
    userFullName = "",
    emailAddress = "",
    companyName = "",
    siteName = "",
    siteType = null,
    streetAddress = "",
    zipCode = "",
    city = "",
    stateOrProvince = "",
    country = null
  ) {
    this.userFullName = userFullName;
    this.emailAddress = emailAddress;
    this.companyName = companyName;
    this.siteName = siteName;
    this.siteType = siteType;
    this.streetAddress = streetAddress;
    this.zipCode = zipCode;
    this.city = city;
    this.stateOrProvince = stateOrProvince;
    this.country = country;
  }

  isNameValid() {
    return StringUtils.isNotEmpty(this.userFullName);
  }

  isEmailAddressValid() {
    return StringUtils.isEmail(this.emailAddress);
  }

  isCompanyValid() {
    return StringUtils.isNotEmpty(this.companyName);
  }

  isSiteNameNotEmpty() {
    return StringUtils.isNotEmpty(this.siteName);
  }

  isSiteNameNoBlacklistedCharacter() {
    return !this.siteName.match(DISALLOWED_CHARACTERS_IN_EXCEL_REGEX);
  }

  isSiteNameValid() {
    return this.isSiteNameNotEmpty() && this.isSiteNameNoBlacklistedCharacter();
  }

  isSiteTypeValid() {
    return this.siteType;
  }

  isStreetAddressValid() {
    return StringUtils.isNotEmpty(this.streetAddress);
  }

  isZipCodeValid() {
    return StringUtils.isNotEmpty(this.zipCode);
  }

  isCityValid() {
    return StringUtils.isNotEmpty(this.city);
  }

  isStateOrProvinceValid() {
    return StringUtils.isNotEmpty(this.stateOrProvince);
  }

  isCountryValid() {
    return this.country;
  }

  isValid() {
    return (
      this.isNameValid() &&
      this.isEmailAddressValid() &&
      this.isCompanyValid() &&
      this.isSiteNameValid() &&
      this.isSiteTypeValid() &&
      this.isStreetAddressValid() &&
      this.isZipCodeValid() &&
      this.isCityValid() &&
      this.isStateOrProvinceValid() &&
      this.isCountryValid()
    );
  }
}

export class EnterprisePlanSignUpModel extends SignupModel {
  constructor(
    userFullName = "",
    emailAddress = "",
    companyName = "",
    noOfProperties = null,
    businessNeeds = "",
    siteName = "",
    siteType = null,
    streetAddress = "",
    zipCode = "",
    city = "",
    stateOrProvince = "",
    country = null
  ) {
    super(
      userFullName,
      emailAddress,
      companyName,
      siteName,
      siteType,
      streetAddress,
      zipCode,
      city,
      stateOrProvince,
      country
    );
    this.noOfProperties = noOfProperties;
    this.businessNeeds = businessNeeds;
  }

  isNameValid() {
    return StringUtils.isNotEmpty(this.userFullName);
  }

  isEmailAddressValid() {
    return StringUtils.isEmail(this.emailAddress);
  }

  isCompanyValid() {
    return StringUtils.isNotEmpty(this.companyName);
  }

  isNoOfPropertiesValid() {
    return (
      StringUtils.isNotEmpty(this.noOfProperties) &&
      !isNaN(this.noOfProperties) &&
      parseInt(this.noOfProperties, 10) > 0
    );
  }

  isBusinessNeedsValid() {
    return StringUtils.isNotEmpty(this.businessNeeds);
  }

  isSiteNameNotEmpty() {
    return true;
  }

  isSiteNameValid() {
    return StringUtils.isNotEmpty(this.siteName)
      ? this.isSiteNameNoBlacklistedCharacter()
      : true;
  }

  isSiteTypeValid() {
    return true;
  }

  isStreetAddressValid() {
    return true;
  }

  isZipCodeValid() {
    return true;
  }

  isCityValid() {
    return true;
  }

  isStateOrProvinceValid() {
    return true;
  }

  isCountryValid() {
    return true;
  }

  isValid() {
    return (
      this.isNameValid() &&
      this.isEmailAddressValid() &&
      this.isCompanyValid() &&
      this.isNoOfPropertiesValid() &&
      this.isBusinessNeedsValid() &&
      this.isSiteNameValid()
    );
  }
}
