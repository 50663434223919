import { StringUtils } from "./UtilsService";
import axios from "axios";
import { API_URL } from "../config/api-config";
import { DISALLOWED_CHARACTERS_IN_EXCEL_REGEX } from "../config/constants";

export const SIGNUP_TRIAL_PERIOD_IN_DAYS = 7;

export class SignUpFormModel {
  constructor(
    userFullName = "",
    emailAddress = "",
    companyName = "",
    siteName = "",
    siteType = null,
    streetAddress = "",
    zipCode = "",
    city = "",
    stateOrProvince = "",
    country = null
  ) {
    this.userFullName = userFullName;
    this.emailAddress = emailAddress;
    this.companyName = companyName;
    this.siteName = siteName;
    this.siteType = siteType;
    this.streetAddress = streetAddress;
    this.zipCode = zipCode;
    this.city = city;
    this.stateOrProvince = stateOrProvince;
    this.country = country;
  }

  isNameValid() {
    return StringUtils.isNotEmpty(this.userFullName);
  }

  isEmailAddressValid() {
    return StringUtils.isEmail(this.emailAddress);
  }

  isCompanyValid() {
    return StringUtils.isNotEmpty(this.companyName);
  }

  isSiteNameNotEmpty() {
    return StringUtils.isNotEmpty(this.siteName);
  }

  isSiteNameNoBlacklistedCharacter() {
    return !this.siteName.match(DISALLOWED_CHARACTERS_IN_EXCEL_REGEX);
  }

  isSiteNameValid() {
    return this.isSiteNameNotEmpty() && this.isSiteNameNoBlacklistedCharacter();
  }

  isSiteTypeValid() {
    return this.siteType;
  }

  isStreetAddressValid() {
    return StringUtils.isNotEmpty(this.streetAddress);
  }

  isZipCodeValid() {
    return StringUtils.isNotEmpty(this.zipCode);
  }

  isCityValid() {
    return StringUtils.isNotEmpty(this.city);
  }

  isStateOrProvinceValid() {
    return StringUtils.isNotEmpty(this.stateOrProvince);
  }

  isCountryValid() {
    return this.country;
  }

  isValid() {
    return (
      this.isNameValid() &&
      this.isEmailAddressValid() &&
      this.isCompanyValid() &&
      this.isSiteNameValid() &&
      this.isSiteTypeValid() &&
      this.isStreetAddressValid() &&
      this.isZipCodeValid() &&
      this.isCityValid() &&
      this.isStateOrProvinceValid() &&
      this.isCountryValid()
    );
  }
}

export default class SignUpService {
  static getSignUpConfig = async () => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${API_URL}/api/v1/signup/config`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static submitSignupApplication = async (
    user_full_name,
    email_address,
    company_name,
    site_name,
    site_type,
    street_address,
    zip_code,
    city,
    state_or_province,
    country
  ) => {
    try {
      const payload = {
        user_full_name,
        email_address,
        company_name,
        site_name,
        site_type,
        street_address,
        zip_code,
        state_or_province,
        city,
        country,
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${API_URL}/api/v1/signup`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static submitSignUpApplicationWithPackagePlan = async (
    user_full_name,
    email_address,
    company_name,
    site_name,
    site_type,
    street_address,
    zip_code,
    city,
    state_or_province,
    country,
    package_plan_id,
    billing_cycle,
    for_trial
  ) => {
    try {
      const payload = {
        user_full_name,
        email_address,
        company_name,
        site_name,
        site_type,
        street_address,
        zip_code,
        state_or_province,
        city,
        country,
        package_plan_id,
        billing_cycle,
        for_trial,
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${API_URL}/api/v2/signup/package-plan`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static logVisitingEnterprisePlanSignUpApplication = async () => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${API_URL}/api/v2/signup/enterprise-plan/visit`
      );
      return response.data;
    } catch (error) {}
  };

  static submitEnterprisePlanSignUpApplication = async (
    user_full_name,
    email_address,
    company_name,
    no_of_properties,
    business_needs,
    site_name,
    site_type,
    street_address,
    zip_code,
    city,
    state_or_province,
    country,
  ) => {
    try {
      const payload = {
        user_full_name,
        email_address,
        company_name,
        no_of_properties,
        business_needs,
        site_name,
        site_type,
        street_address,
        zip_code,
        state_or_province,
        city,
        country,
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${API_URL}/api/v2/signup/enterprise-plan`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
