import React, { useContext, useEffect, useRef, useState } from "react";
import UserInventoryContext from "../../../context/UserInventoryContext";
import EnvironmentalService from "../../../services/EnvironmentalService";
import LoadingSpinner from "../../common/LoadingSpinner";
import BulkEnvironmentalDataInputService from "./BulkEnvironmentalDataInputService";
import groupBy from "lodash/groupBy";
import range from "lodash/range";
import flatMap from "lodash/flatMap";
import { getRedirectURLWithCurrentParam } from "../../common/QueryHandler";
import { ENVIRONMENTAL_DATA, OVERVIEW_DATA } from "../../../config/ROUTES_NAME";
import BulkDataInput, { BulkDataInputControls } from "../BulkDataInput";
import BulkEnvironmentalDataInputErrorService from "./BulkEnvironmentalDataInputErrorService";
import withAuthentication from "../../HOC/withAuthentication";
import { RESOURCES } from "../../../config/constants";
import ToastContext from "../../../context/ToastContext";
import BulkExcelUploadModal from "../../common/BulkExcelUploadModal";
import { useHistory, useLocation } from "react-router-dom";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import PageHeader from "../../../gvds-components/Layout/PageHeader";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconDownload, IconUpload } from "@tabler/icons-react";
import GVDSBanner from "../../../gvds-components/common/GVDSBanner";
import { Trans, useTranslation } from "react-i18next";

const INITIAL_GRID_SIZE = 10;
const DATA_STARTING_COL = 2;

const BulkEnvironmentalDataInput = () => {
  const { t } = useTranslation();

  let history = useHistory();
  let location = useLocation();
  const userInventory = useContext(UserInventoryContext);
  const toastContext = useContext(ToastContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const bulkInputService = useRef(new BulkEnvironmentalDataInputService(t));
  const bulkInputErrorService = useRef(
    new BulkEnvironmentalDataInputErrorService()
  );

  const [grid, setGrid] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bulkConfig, setBulkConfig] = useState({});
  const [rowToDelete, setRowToDelete] = useState(-1);
  const [hasEdited, setHasEdited] = useState(false);
  const [numOfRecordsInputted, setNumOfRecordsInputted] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentInventoryId, setCurrentInventoryId] = useState("");

  useEffect(() => {
    if (selectedInventory?.id && selectedInventory.id !== currentInventoryId) {
      setCurrentInventoryId(selectedInventory.id);
      bulkInputErrorService.current.resetErrors();
      setHasEdited(false);
      setIsLoading(true);
      bulkInputService.current.updateResource(
        selectedInventory.type,
        userInventory.selectedTreeNode.get
      );
      EnvironmentalService.getBulkConfig(
        selectedInventory.type,
        selectedInventory.id
      )
        .then((config) => {
          bulkInputService.current.setSelectionOptions(config);
          setBulkConfig(config);
          resetGrid();
        })
        .catch(() => {
          toastContext.addFailToast(
            <span>Failed to get config. Please try again.</span>
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedInventory]);

  useEffect(() => {
    if (rowToDelete >= 0) {
      const newGrid = bulkInputService.current.removeRow(
        rowToDelete,
        setRowToDelete,
        grid
      );
      setGrid(newGrid);
      setRowToDelete(-1);
    }
  }, [rowToDelete]);

  const resetGrid = () => {
    const initialGrid = range(INITIAL_GRID_SIZE).map((row) =>
      bulkInputService.current.getNewRow(row, setRowToDelete)
    );
    bulkInputErrorService.current.resetErrors();
    setHasEdited(false);
    setGrid(initialGrid);
  };

  const addNewRow = () => {
    const newRow = Object.values(grid).length;
    setGrid([
      ...grid,
      bulkInputService.current.getNewRow(newRow, setRowToDelete),
    ]);
  };

  const submitBulkData = async () => {
    setIsLoading(true);
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    const newDataReadings = changedGrid.map((rowData) =>
      bulkInputService.current.getDataReading(rowData)
    );
    try {
      await EnvironmentalService.createDataRecords(
        selectedInventory.type,
        selectedInventory.id,
        newDataReadings
      );
      setNumOfRecordsInputted(grid.length);
      resetGrid();
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to submit. Please try again.</span>
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validateBulkData = async () => {
    const changedGrid = bulkInputService.current.removeEmptyRows(
      grid,
      setRowToDelete
    );
    if (changedGrid.length === 0) return;

    const newDataReadings = changedGrid.map((rowData) =>
      bulkInputService.current.getDataReading(rowData)
    );
    if (newDataReadings.length === 0) return;

    setIsLoading(true);
    try {
      const errors = await EnvironmentalService.validateDataRecords(
        selectedInventory.type,
        selectedInventory.id,
        newDataReadings
      );
      const newGrid = bulkInputService.current.updateGridWithErrors(
        changedGrid,
        bulkInputErrorService.current.prepareDataErrors(
          errors.dataErrors.rowErrors
        ),
        bulkInputErrorService.current.preparePossibleErrors(
          errors.possibleErrors.rowErrors
        )
      );
      bulkInputErrorService.current.updateErrors(errors);
      setHasEdited(false);
      setGrid(newGrid);
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to validate. Please try again.</span>
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onTemplateDownload = async () => {
    setIsDownloading(true);
    try {
      await EnvironmentalService.downloadTemplate(
        selectedInventory.type,
        selectedInventory.id
      );
    } catch (e) {
      toastContext.addFailToast(
        <span>Failed to download template. Please try again.</span>
      );
    } finally {
      setIsDownloading(false);
    }
  };

  const onRowsRead = async (rows) => {
    await handleChanges(
      [],
      [],
      flatMap(
        rows.map((row, ri) =>
          row.map((col, ci) => ({
            row: ri,
            col: ci + DATA_STARTING_COL,
            value: col,
          }))
        )
      )
    );
  };

  const handleChanges = async (gridToChange, changes = [], additions = []) => {
    setHasEdited(true);

    const totalChanges = [...changes, ...additions];
    const changesByRow = groupBy(totalChanges, (c) => c.row);

    for (const [row, rowChanges] of Object.entries(changesByRow)) {
      const rowData = gridToChange[row]
        ? gridToChange[row]
        : bulkInputService.current.getNewRow(row, setRowToDelete);
      gridToChange[row] = bulkInputService.current.updateRow(
        rowData,
        rowChanges
      );
    }
    setGrid(gridToChange);
  };

  const onInputSuccess = () => {
    if (selectedInventory?.type === RESOURCES.PORTFOLIO) {
      resetGrid();
    } else {
      history.push(
        getRedirectURLWithCurrentParam(ENVIRONMENTAL_DATA, location)
      );
    }
    setNumOfRecordsInputted(null);
  };

  const backToDataPage = () => {
    if (selectedInventory.type === RESOURCES.PORTFOLIO) {
      history.push(OVERVIEW_DATA);
    } else {
      history.push(ENVIRONMENTAL_DATA);
    }
  };

  let content;

  if (isLoading) {
    content = (
      <div style={{ paddingTop: "100px" }}>
        <LoadingSpinner />
      </div>
    );
  } else {
    content = (
      <BulkDataInput
        grid={grid}
        hasChanges={
          hasEdited ||
          bulkInputErrorService.current.hasDataErrors() ||
          bulkInputErrorService.current.hasPossibleErrors() ||
          !bulkInputErrorService.current.disableSubmit()
        }
        columns={bulkInputService.current.columns}
        onChange={(changes, additions) =>
          handleChanges(
            grid.map((row) => [...row]),
            changes,
            additions
          )
        }
      />
    );
  }

  const dataErrorCount = bulkInputErrorService.current.hasDataErrors()
    ? bulkInputErrorService.current.dataErrorRows.length
    : 0;
  const possibleErrorCount = bulkInputErrorService.current.hasPossibleErrors()
    ? bulkInputErrorService.current.possibleErrorRows.length
    : 0;

  const backButtonPage =
    selectedInventory && selectedInventory.type === RESOURCES.PORTFOLIO
      ? t("data-management.overview.shared.back-page-label")
      : t("data-management.environmental.setup.back-page-label");

  return (
    <div>
      <PageHeader>
        <PageHeader.BackButton
          text={
            <Trans i18nKey="shared.button-return-to-page">
              Return to {{ backButtonPage }}
            </Trans>
          }
          onClick={backToDataPage}
        />
        <PageHeader.Title>
          <h1>
            {t("data-management.environmental.usage.bulk-input.page-title")}
          </h1>
        </PageHeader.Title>
      </PageHeader>
      {bulkInputErrorService.current.hasDataErrors() && (
        <GVDSBanner
          title={
            <Trans
              i18nKey={
                "data-management.environmental.usage.bulk-input.banner-data-error-title"
              }
            >
              {{
                dataErrorCount,
              }}{" "}
              issues detected.
            </Trans>
          }
          variant={GVDSBanner.Variants.error}
        >
          {t(
            "data-management.environmental.usage.bulk-input.banner-data-error-content"
          )}
          :{" "}
          {bulkInputErrorService.current.dataErrorRows
            .map((row) => Number(row) + 1)
            .join(", ")}
        </GVDSBanner>
      )}

      {bulkInputErrorService.current.hasPossibleErrors() && (
        <GVDSBanner
          title={
            <Trans
              i18nKey={
                "data-management.environmental.usage.bulk-input.banner-possible-error-title"
              }
            >
              {{ possibleErrorCount }} possible issues detected. Records with
              this issue can be submitted.
            </Trans>
          }
          variant={GVDSBanner.Variants.warning}
        >
          {t(
            "data-management.environmental.usage.bulk-input.banner-possible-error-content"
          )}
          :{" "}
          {bulkInputErrorService.current.possibleErrorRows
            .map((row) => Number(row) + 1)
            .join(", ")}
        </GVDSBanner>
      )}

      {bulkInputErrorService.current.hasNoErrors() && (
        <GVDSBanner
          title={t(
            "data-management.environmental.usage.bulk-input.banner-no-issue-title"
          )}
          variant={GVDSBanner.Variants.success}
        >
          {t(
            "data-management.environmental.usage.bulk-input.banner-no-issue-content"
          )}
        </GVDSBanner>
      )}
      <div className="d-flex flex-row-reverse align-items-center">
        {hasEdited && (
          <div>
            {t("data-management.environmental.usage.bulk-input.changes-made")}
          </div>
        )}
      </div>

      <div className="bulk-input-ctrl__container">
        <BulkDataInputControls
          addNewRow={addNewRow}
          rowCount={grid.length}
          onClearAll={resetGrid}
        />
        <div className="bulk-input-ctrl__action-ctrl">
          <GVDSButton
            className="download-environmental-template"
            variant={buttonVariant.tertiary}
            disabled={isDownloading}
            onClick={onTemplateDownload}
            icon={<GVDSIcon Icon={IconDownload} />}
            text={t(
              "data-management.environmental.usage.bulk-input.button-download-template"
            )}
          />
          <GVDSButton
            className="upload-environmental-sheet"
            variant={buttonVariant.tertiary}
            disabled={isLoading}
            onClick={() => setShowUploadModal(true)}
            icon={<GVDSIcon Icon={IconUpload} />}
            text={t(
              "data-management.environmental.usage.bulk-input.button-upload-sheet"
            )}
          />
          <GVDSButton
            variant={buttonVariant.secondary}
            className="validate-bulk-data"
            onClick={validateBulkData}
            text={t(
              "data-management.environmental.usage.bulk-input.button-validate"
            )}
          />
          <GVDSButton
            className="submit-bulk-data"
            onClick={submitBulkData}
            disabled={
              isLoading ||
              hasEdited ||
              bulkInputErrorService.current.disableSubmit()
            }
            text={t("shared.submit")}
          />
        </div>
      </div>
      {content}

      <GVDSModal
        title="Bulk Input Success"
        size={GVDSModal.Size.small}
        show={numOfRecordsInputted > 0}
        onHide={onInputSuccess}
      >
        <GVDSModal.Body>
          You have recorded {numOfRecordsInputted} entries into the portal.
        </GVDSModal.Body>
      </GVDSModal>

      <BulkExcelUploadModal
        sheetName={bulkConfig["upload_sheet_name"]}
        startingRow={bulkConfig["upload_start_row"]}
        show={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onRowsRead={onRowsRead}
      />
    </div>
  );
};

export default withAuthentication(BulkEnvironmentalDataInput);
