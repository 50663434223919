import SiteBulkService from "../../services/SiteBulkService";
import {
  CellValue,
  DropdownCellValue,
  RemoveActionCell,
} from "../Data/BulkDataInputCellViewer";
import find from "lodash/find";
import _ from "lodash";

const columns = {
  0: {
    key: "action",
    label: "",
    width: 2,
    classType: RemoveActionCell,
    errorKey: "",
    readOnly: true,
  },
  1: {
    key: "index",
    label: "Row",
    width: 2.5,
    classType: CellValue,
    errorKey: "index",
    readOnly: true,
  },
  2: {
    key: "site_name",
    label: "Site Name*",
    width: 10,
    classType: CellValue,
    errorKey: "site_name",
  },
  3: {
    key: "full_name",
    label: "Full Name",
    width: 10,
    classType: CellValue,
    errorKey: "full_name",
  },
  4: {
    key: "email",
    label: "Email*",
    width: 10,
    classType: CellValue,
    errorKey: "email",
  },
  5: {
    key: "position",
    label: "Position",
    width: 10,
    classType: CellValue,
    errorKey: "position",
  },
  6: {
    key: "role",
    label: "User Role*",
    width: 10,
    classType: DropdownCellValue,
    errorKey: "role_id",
  },
};

class SiteUserValue {
  static new(row, removeRow) {
    const newArea = new SiteUserValue();

    Object.values(columns).forEach((c) => {
      newArea[c.key] = new c.classType();
    });

    newArea.action = RemoveActionCell.fromRemoveRow(() => removeRow(row));
    newArea.index = new CellValue(row + 1, true, [], [], false);
    return newArea;
  }

  static fromRowData(rowData) {
    const newArea = new SiteUserValue();
    Object.values(columns).forEach((c, col) => {
      newArea[c.key] = c.classType.fromCellData(rowData[col]);
    });
    return newArea;
  }

  static getNameByCol(col) {
    return columns[col]?.key;
  }

  updateDataEditor(options) {
    this.role.setDropdownOptions(options["roles"]);
  }

  toGridRow() {
    return Object.values(columns).map((c) => this[c.key]);
  }

  isEmpty() {
    return Object.values(columns)
      .filter((c) => !c.readOnly)
      .reduce((acc, cur) => acc && this[cur.key].isEmpty(), true);
  }

  removeAllErrors() {
    Object.values(columns).forEach((c) => {
      this[c.key].dataErrors = [];
      this[c.key].possibleErrors = [];
    });
  }
}

export const getSiteUsersColumnLabels = () =>
  Object.values(columns).map((c) => ({ label: c.label, width: c.width }));

export default class BulkSiteUsersInput {
  selectorOptions = {};

  async getSelectionOptions() {
    this.selectorOptions = await SiteBulkService.getConfig();
  }

  getNewRow(row, removeRow) {
    const newRow = SiteUserValue.new(Number(row), removeRow);
    newRow.updateDataEditor(this.selectorOptions);
    return newRow.toGridRow();
  }

  updateRow(rowData, changes) {
    const userRow = SiteUserValue.fromRowData(rowData);

    changes.forEach((c) => {
      const colName = SiteUserValue.getNameByCol(c.col);
      if (colName) {
        userRow[colName].updateValue(c.value);
      }
    });
    userRow.updateDataEditor(this.selectorOptions);
    return userRow.toGridRow();
  }

  removeRow(rowNumber, setRowToDelete, grid) {
    let changedGrid = grid.map((row) => [...row]);
    changedGrid.splice(rowNumber, 1);
    changedGrid = changedGrid.map((g, row) => {
      const user = SiteUserValue.fromRowData(g);
      user.index.updateValue(row + 1);
      user.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row));
      return user.toGridRow();
    });
    return changedGrid;
  }

  removeEmptyRows = (grid, setRowToDelete) => {
    return grid
      .map((rowData) => SiteUserValue.fromRowData(rowData))
      .filter((r) => !r.isEmpty())
      .map((r, row) => {
        r.index.updateValue(row + 1);
        r.action = RemoveActionCell.fromRemoveRow(() => setRowToDelete(row));
        return r.toGridRow();
      });
  };

  getUser(rowData) {
    const user = SiteUserValue.fromRowData(rowData);
    const role = find(
      this.selectorOptions["roles"],
      (t) => t.name === user.role.value
    );

    return {
      row: user.index.value - 1,
      site_name: user.site_name.getValue(),
      full_name: user.full_name.value,
      email: user.email.getValue(),
      position: user.position.value,
      role_id: role?.id,
    };
  }

  updateGridWithErrors = (grid, dataErrors) => {
    const changedGrid = grid.map((rowData) => {
      const user = SiteUserValue.fromRowData(rowData);
      user.removeAllErrors();
      return user.toGridRow();
    });

    for (const [row, err] of Object.entries(dataErrors)) {
      const reading = SiteUserValue.fromRowData(changedGrid[row]);
      Object.values(columns).forEach(({ key, errorKey }) => {
        reading[key].dataErrors = _.chain(err).get(errorKey, []).value();
      });
      changedGrid[row] = reading.toGridRow();
    }

    return changedGrid;
  };
}
