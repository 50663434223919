import axios from "axios";
import { API_URL } from "../../../config/api-config";

export default class LaundryLocationService {
  static GetLaundryLocations = async () => {
    try {
      const response = await axios.get(
        API_URL + "/api/v1/ref_data/laundry_locations"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
