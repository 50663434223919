import DashboardExpandedModalView from "../../DashboardExpandedModalView";
import React from "react";
import { SummarySiteRankingChartViewContent } from "./SummarySiteRankingChartView";
import GVDSTableDisplay from "../../../../gvds-components/Table/GVDSTableDisplay";
import { NumberService } from "../../../../services/UtilsService";
import { SummarySiteRankingChartUtils } from "./SummarySiteRankingChartUtils";
import {
  expandedModalViewOptionEnum,
  maximumChartLegendItemCount,
} from "../../Models/DashboardModels";
import { ExpandedSummarySiteRankingChartViewHeader } from "./SummarySiteRankingChartViewHeaders";
import { expandedSummarySiteRankingChartViewId } from "./SummarySiteRankingViewConfig";
import { DashboardWidgetSharedUtils } from "../DashboardWidgetSharedUtils";
import LoadingSpinnerInFullPage from "../../../common/LoadingSpinnerInFullPage";

const siteNameColumnIndex = 0;

const SummarySiteRankingTableView = ({
  sortedChartData,
  chartKeys,
  intensityUnit,
  unit,
}) => {
  let types;
  let displayedChartData;

  if (chartKeys.length > maximumChartLegendItemCount) {
    types =
      DashboardWidgetSharedUtils.getMaximumDisplayedLegendItems(chartKeys);
    displayedChartData = SummarySiteRankingChartUtils.getCalculatedChartData(
      sortedChartData,
      types
    );
  } else {
    types = chartKeys;
    displayedChartData = sortedChartData;
  }

  if (displayedChartData.length > 0) {
    const displayedUnit = intensityUnit
      ? `(${unit}/${intensityUnit})`
      : `(${unit})`;

    return (
      <div className="dashboard--expanded-view__table-container">
        <GVDSTableDisplay>
          <thead>
            <tr>
              <th>Site Name</th>
              {types.map((type) => (
                <th key={type} style={{ textAlign: "right" }}>
                  {type} {displayedUnit}
                </th>
              ))}
              <th style={{ textAlign: "right" }}>Total {displayedUnit}</th>
            </tr>
          </thead>
          <tbody>
            {displayedChartData.map((chartData, index) => (
              <tr key={index}>
                {chartData
                  .toTableRowData(types)
                  .map((cellData, columnIndex) => (
                    <td
                      key={`row-${columnIndex}`}
                      align={
                        columnIndex === siteNameColumnIndex ? "left" : "right"
                      }
                    >
                      {columnIndex === siteNameColumnIndex
                        ? cellData
                        : NumberService.format(cellData, 2, 2)}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </GVDSTableDisplay>
      </div>
    );
  }
};

const ExpandedSummarySiteRankingChartViewBody = ({
  sortedChartData,
  chartKeys,
  unit,
  intensityUnit,
  selectedViewOption,
}) => {
  return (
    <div className="dashboard--summary-site-ranking__expanded-chart-view__content-container">
      {selectedViewOption === expandedModalViewOptionEnum.CHART ? (
        <SummarySiteRankingChartViewContent
          sortedChartData={sortedChartData}
          chartKeys={chartKeys}
          intensityUnit={intensityUnit}
          unit={unit}
          availableBarSpaceCount={sortedChartData.length}
          isExpandedView={true}
        />
      ) : (
        <SummarySiteRankingTableView
          sortedChartData={sortedChartData}
          chartKeys={chartKeys}
          intensityUnit={intensityUnit}
          unit={unit}
        />
      )}
    </div>
  );
};

const ExpandedSummarySiteRankingChartView = ({
  isShowExpandedChartView,
  onCloseModal,
  title,
  period,
  chartData,
  chartKeys,
  subtopic,
  intensity,
  intensityUnit,
  unit,
  hasDataGaps,
  sortOrder,
  onSortOrderChange,
  selectedExpandedViewOption,
  onSelectExpandedViewOption,
  isDownloadingChart,
  downloadChartAsImage,
  onDownloadTableAsExcel,
}) => {
  const sortedChartData = SummarySiteRankingChartUtils.sortBarChartValues(
    chartData,
    sortOrder.value
  );

  return (
    <>
      <DashboardExpandedModalView
        id={expandedSummarySiteRankingChartViewId}
        isShow={isShowExpandedChartView}
        closeModal={onCloseModal}
        hideCloseButton={isDownloadingChart}
        isLoading={isDownloadingChart}
        header={
          <ExpandedSummarySiteRankingChartViewHeader
            title={title}
            period={period}
            selectedSiteCount={chartData.length}
            subtopic={subtopic}
            intensity={intensity}
            hasDataGaps={hasDataGaps}
            selectedViewOption={selectedExpandedViewOption}
            onSelectViewOption={onSelectExpandedViewOption}
            sortOrder={sortOrder}
            onSortOrderChange={onSortOrderChange}
            isDownloadingChart={isDownloadingChart}
            downloadChartAsImage={downloadChartAsImage}
            onDownloadTableAsExcel={onDownloadTableAsExcel}
          />
        }
        body={
          <ExpandedSummarySiteRankingChartViewBody
            sortedChartData={sortedChartData}
            chartKeys={chartKeys}
            unit={unit}
            intensityUnit={intensityUnit}
            selectedViewOption={selectedExpandedViewOption}
          />
        }
      />
      {isShowExpandedChartView && isDownloadingChart && (
        <LoadingSpinnerInFullPage />
      )}
    </>
  );
};

export default ExpandedSummarySiteRankingChartView;
