import React from "react";
import { FormFieldState, FormFieldStatusMetadata } from "./GVDSFormShared";
import { StringUtils } from "../../services/UtilsService";
import GVDSFormTextArea from "./GVDSFormTextArea";

const GVDSTextAreaWithCharacterCount = ({
  value,
  onInput,
  name,
  disabled = false,
  plaintext = false,
  readOnly = false,
  className = "",
  rows = 3,
  maxLength,
  placeholder = null,
  statusMetadata = FormFieldStatusMetadata.getDefault(),
  ...props
}) => {
  const counterText = `${value ? value.length : 0}/${maxLength}`;
  const isValueOverLimit =
    StringUtils.isNotEmpty(value) && value.length > maxLength;

  let charactersStatusMetaData;

  if (isValueOverLimit) {
    let errorMessage;
    const valueOverLimitErrorMsg = `${
      value.length - maxLength
    } characters over limit.`;

    if (statusMetadata.state === FormFieldState.error) {
      errorMessage = `${valueOverLimitErrorMsg} ${statusMetadata.message}`;
    } else {
      errorMessage = valueOverLimitErrorMsg;
    }

    charactersStatusMetaData = new FormFieldStatusMetadata(
      FormFieldState.error,
      errorMessage
    );
  } else {
    charactersStatusMetaData = statusMetadata;
  }
  return (
    <div className="gvds-form__field-with-char-count__container">
      <div className="gvds-form__char-count">{counterText}</div>
      <GVDSFormTextArea
        value={value}
        onInput={onInput}
        disabled={disabled}
        plaintext={plaintext}
        readOnly={readOnly}
        className={className}
        rows={rows}
        placeholder={placeholder}
        statusMetadata={charactersStatusMetaData}
        {...props}
      />
    </div>
  );
};

export default GVDSTextAreaWithCharacterCount;
