import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NumberService } from "../../services/UtilsService";

const CustomProgressBar = ({ progress = 0, description }) => {
  const getColor = () => {
    if (progress > 75) {
      return "green";
    } else if (progress > 25) {
      return "yellow";
    } else {
      return "red";
    }
  };
  return (
    <div className="data-request-progress">
      <ProgressBar className={getColor()} now={progress} />
      <div className="ms-2 gv-text-12">
        {NumberService.format(progress)}% {description}
      </div>
    </div>
  );
};

export default CustomProgressBar;
