import React, { useState } from "react";

import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import { MailToUtils, NumberService } from "../../../services/UtilsService";
import GVDSIcon from "../../../gvds-components/Icons/GVDSIcon";
import { IconCheck, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import GVDSCustomIcons from "../../../gvds-components/Icons/GVDSIconCustom";
import GVDSInfoCard from "../../../gvds-components/common/GVDSInfoCard";
import GVDSTextButton from "../../../gvds-components/Buttons/GVDSTextButton";
import {
  CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME,
  getSubscriptionPriceDisplay,
} from "../../SystemToolbox/Subscription/SubscriptionSharedUtils";
import Spacer from "../../../gvds-components/Layout/Spacer";
import headerImg from "../../../assets/images/landing-page-illustration.png";
import GVDSIconSlim from "../../../gvds-components/Icons/GVDSIconSlim";
import { Collapse } from "react-bootstrap";
import {
  CONTACT_EMAIL_SALES,
  CONTACT_EMAIL_SALES_SUBJECT,
} from "../../../config/constants";

const planBlurbs = [
  {
    description:
      "Kickstart your sustainability journey by implementing operational best practices and initiatives related to efficiency, environment, community development, sourcing, and more.",
    featureIncludedTitle: "What's included?",
    featureIncludedList: [
      <div>
        <strong>Log sustainability initiatives</strong> and aggregate impact
        metrics for various topics including UN SDGs
      </div>,
      <div>
        Learn over <strong>200 sustainability best practices</strong> and track
        your implementation efforts
      </div>,
      <div>
        Store all documents and files in a <strong>unified repository</strong>{" "}
        for easy accessibility, organization, and management
      </div>,
      <div>
        Streamline sustainability management with{" "}
        <strong>Task management</strong>, facilitating collaboration, deadlines,
        and progress tracking
      </div>,
    ],
  },
  {
    description:
      "Centralize your ESG data management and reporting, automate Scope 1, 2, and 3 (waste) emissions calculations, set measurable goals and more.",
    featureIncludedTitle: "All Initiatives Tracking features, plus",
    featureIncludedList: [
      <div>
        Track various ESG data including{" "}
        <strong>Scope 1, 2, and 3 (waste) emissions data</strong> with automatic
        calculations
      </div>,
      <div>
        Generate <strong>performance reports</strong> for routine monitoring and
        internal and external reporting needs
      </div>,
      <div>
        Set <strong>goals & targets</strong> to communicate progress and
        achievements in real-time
      </div>,
      <div>
        Automatically calculate hotel carbon footprint with{" "}
        <strong>HCMI</strong> (hotels only)
      </div>,
    ],
  },
  {
    description:
      "Maximize your sustainability impact with our all-in-one ESG solution designed to fast-track progress, elevate performance, and boost staff engagement and productivity.",
    featureIncludedTitle: "All Performance Reporting features, plus",
    featureIncludedList: [
      <div>
        Benchmark environmental performance against peers using{" "}
        <strong>CHSB</strong> (industry's largest dataset, for hotels only)
      </div>,
      <div>
        Tap into <strong>100+ articles and videos</strong> on sustainability
        topics by experts, for property implementation
      </div>,
      <div>
        Achieve{" "}
        <strong>
          {/* TODO update this once the certifications are available */}
          {/*Basics verification, GSTC, Green Key FEE or Green Key Global*/}
          {/*certification*/}
          GSTC certification
        </strong>{" "}
        with step-by-step guidance and resources
      </div>,
      <div>
        Understand <strong>risks and opportunities</strong> covering water,
        biodiversity
      </div>,
    ],
  },
];

export const yearlyMonthlyPriceDiff = (yearlyPrice, monthlyPrice) => {
  const monthlyPriceAnnualTotal = 12 * monthlyPrice;

  const diffAsPercentage =
    (100 * (monthlyPriceAnnualTotal - yearlyPrice)) / monthlyPriceAnnualTotal;

  return NumberService.format(diffAsPercentage, 0, 0);
};

const PlanDisplay = ({
  pricingPlanModel,
  onChoosingPlan,
  currency,
  planIndex,
  isSignUp,
  isBillingAdmin,
  isCurrentPlan,
  isInTrial,
  isAtSite,
  disableSelection = false,
}) => {
  if (!pricingPlanModel) {
    return null;
  }

  const monthlyPrice = pricingPlanModel.getMonthlyPriceForCurrency(currency);
  const yearlyPrice = pricingPlanModel.getYearlyPriceForCurrency(currency);

  const planBlurb = planBlurbs[planIndex];

  const showChoosePlanButton =
    isSignUp ||
    (isAtSite && !disableSelection && (isInTrial || !isCurrentPlan));

  return (
    <div className="billing-packages__plan-display__container-with-status">
      <div className={`plan-${planIndex}-current-status`}>
        {isCurrentPlan && (
          <div className="current-plan-status">Your current plan</div>
        )}
      </div>
      <div className="billing-packages__plan-display__container">
        <div
          className={`plan-${planIndex}-title ${
            isCurrentPlan ? "is-current-plan" : ""
          }`}
        >
          <div className="gvds-text--heading3">{pricingPlanModel.name}</div>
        </div>
        <div className={`plan-${planIndex}-description`}>
          {planBlurb.description}
        </div>
        <div className={`plan-${planIndex}-price`}>
          {pricingPlanModel.hasYearly && (
            <div className="mb-2">
              <span className="gvds-text--heading3">
                {getSubscriptionPriceDisplay(
                  currency.toUpperCase(),
                  NumberService.format(yearlyPrice.priceInDecimal, 0, 0)
                )}
              </span>{" "}
              /year{" "}
              {pricingPlanModel.hasMonthly && (
                <StatusLabel color={StatusLabel.Colors.green}>
                  Save{" "}
                  {yearlyMonthlyPriceDiff(
                    yearlyPrice.priceInDecimal,
                    monthlyPrice.priceInDecimal
                  )}
                  %
                </StatusLabel>
              )}
            </div>
          )}
          {pricingPlanModel.hasMonthly ? (
            <div>
              or{" "}
              <strong>
                {getSubscriptionPriceDisplay(
                  currency.toUpperCase(),
                  NumberService.format(monthlyPrice.priceInDecimal, 0, 0)
                )}
              </strong>{" "}
              /month
            </div>
          ) : (
            <div>Monthly billing not available</div>
          )}
        </div>
        <div className={`plan-${planIndex}-action`}>
          {(isSignUp || isBillingAdmin) && (
            <>
              {showChoosePlanButton ? (
                <GVDSButton
                  variant={buttonVariant.primary}
                  text={isSignUp ? "Get Started" : "Select Plan"}
                  onClick={() => onChoosingPlan(pricingPlanModel, false)}
                />
              ) : (
                <div className="select-plan-button-placeholder"></div>
              )}
              {isSignUp && pricingPlanModel.hasTrial && (
                <GVDSButton
                  variant={buttonVariant.tertiary}
                  text="Try for free"
                  onClick={() => onChoosingPlan(pricingPlanModel, true)}
                />
              )}
            </>
          )}
        </div>
        <div className={`plan-${planIndex}-feature-included`}>
          <div className="plan__feature-included-heading">
            {planBlurb.featureIncludedTitle}
          </div>
          <ul>
            {planBlurb.featureIncludedList.map((f, fIndex) => (
              <li key={fIndex}>
                <GVDSIcon Icon={IconCheck} className="me-2" />
                {f}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const BillingPackages = ({
  pricingPlanModels,
  onChoosingPlan,
  onChoosingEnterprise,
  currency,
  isSignUp,
  isBillingAdmin,
  billingAdminEmail,
  currentPlanId,
  isInTrial,
  isAtSite,
  disableSelection,
}) => {
  const [
    isEnterpriseSolutionFeatureExpanded,
    setIsEnterpriseSolutionFeatureExpanded,
  ] = useState(false);

  const isLoggedInViewNotBillingAdmin = !isSignUp && !isBillingAdmin;

  const isOnCustomPlan =
    currentPlanId &&
    pricingPlanModels.find(
      (m) => m.name === CUSTOM_PORTAL_SUBSCRIPTION_PLAN_NAME
    )?.packagePlanId === currentPlanId;

  return (
    <div className="billing-packages__container">
      <div className="billing-packages__header">
        <div className="billing-packages__title">
          <h1>
            Empower your sustainability journey with Greenview Portal's ESG
            plans
          </h1>
          <div>Unlimited users, infinite impact</div>
        </div>
        <Spacer />
        <div className="billing-packages__illustration-container">
          <img
            src={headerImg}
            className="billing-packages-illustration"
            alt="My Greenview"
          />
        </div>
      </div>
      {isLoggedInViewNotBillingAdmin && (
        <GVDSInfoCard title="Please contact your company's Billing Admin to purchase or manage subscriptions.">
          <div>
            <GVDSTextButton
              text="Contact Billing Admin"
              onClick={() =>
                window.open(`mailto:${billingAdminEmail}`, "_blank")
              }
            />
          </div>
        </GVDSInfoCard>
      )}
      <div className="billing-packages__plans-selection">
        <div className="billing-packages__plan-display__container plan-enterprise">
          <div className="plan-enterprise-description">
            <GVDSCustomIcons.MultiBuilding className="gvds-color--tealSolid mb-3" />
            {isOnCustomPlan && (
              <div className="mb-2">
                <StatusLabel color={StatusLabel.Colors.gray}>
                  Your current plan
                </StatusLabel>
              </div>
            )}
            <div className="gvds-text--heading3 mb-2">Enterprise Solution</div>
            <div className="mb-3">
              Managing multiple hotels or properties with unique needs? Reach
              out to our Sales team for tailored solutions.
            </div>
            <div className="mb-3">Contact Sales for pricing</div>
          </div>
          <div className="plan-enterprise-action">
            <div className="d-flex flex-column">
              {!isLoggedInViewNotBillingAdmin && (
                <GVDSButton
                  variant={buttonVariant.primary}
                  text="Contact Us"
                  onClick={onChoosingEnterprise}
                />
              )}
              <GVDSTextButton
                text={
                  <>
                    Learn more{" "}
                    <GVDSIconSlim
                      Icon={
                        isEnterpriseSolutionFeatureExpanded
                          ? IconChevronUp
                          : IconChevronDown
                      }
                    />
                  </>
                }
                onClick={() => {
                  setIsEnterpriseSolutionFeatureExpanded(
                    !isEnterpriseSolutionFeatureExpanded
                  );
                }}
                className="learn-more-button"
              />
              <Collapse in={isEnterpriseSolutionFeatureExpanded}>
                <div
                  className={
                    isSignUp
                      ? "enterprise-solution-features__sign-up"
                      : "enterprise-solution-features"
                  }
                >
                  <div className="mb-3">
                    Greenview supports companies with{" "}
                    <strong>
                      sustainability program development and implementation
                    </strong>
                    , including <strong>portfolio-wide integration</strong> with
                    Greenview Portal for progress monitoring. Engage with our
                    seasoned team to initiate your customized enterprise
                    solution today.
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        <PlanDisplay
          pricingPlanModel={pricingPlanModels[0]}
          onChoosingPlan={onChoosingPlan}
          currency={currency}
          planIndex={0}
          isSignUp={isSignUp}
          isBillingAdmin={isBillingAdmin}
          isCurrentPlan={pricingPlanModels[0]?.packagePlanId === currentPlanId}
          isInTrial={isInTrial}
          isAtSite={isAtSite}
          disableSelection={disableSelection}
        />
        <PlanDisplay
          pricingPlanModel={pricingPlanModels[1]}
          onChoosingPlan={onChoosingPlan}
          currency={currency}
          planIndex={1}
          isSignUp={isSignUp}
          isBillingAdmin={isBillingAdmin}
          isCurrentPlan={pricingPlanModels[1]?.packagePlanId === currentPlanId}
          isInTrial={isInTrial}
          isAtSite={isAtSite}
          disableSelection={disableSelection}
        />
        <PlanDisplay
          pricingPlanModel={pricingPlanModels[2]}
          onChoosingPlan={onChoosingPlan}
          currency={currency}
          planIndex={2}
          isSignUp={isSignUp}
          isBillingAdmin={isBillingAdmin}
          isCurrentPlan={pricingPlanModels[2]?.packagePlanId === currentPlanId}
          isInTrial={isInTrial}
          isAtSite={isAtSite}
          disableSelection={disableSelection}
        />
      </div>

      <div className="billing-packages__footer">
        <div className="gvds-text--heading3 mb-2">Add-ons</div>
        <div className="mb-1">
          <span className="gvds-text--heading4">ESG Policy Tracker</span> - Stay
          updated of regulatory policies affecting your property or portfolio
          (Only for Enterprise Solution)
        </div>
        <div className="mb-2">
          <span className="gvds-text--heading4">
            AI driven invoice data capture
          </span>{" "}
          - Fuss-free data upload to Greenview Portal (Only for Greenview Suite
          and Enterprise Solution)
        </div>
        <div className="mb-2">
          Contact{" "}
          <a
            href={MailToUtils.sendEmailWithSubject(
              CONTACT_EMAIL_SALES,
              CONTACT_EMAIL_SALES_SUBJECT
            )}
            target="_blank"
          >
            {CONTACT_EMAIL_SALES}
          </a>{" "}
          for a quote
        </div>
        <div className="gvds-text--body gvds-color--gray6">
          Applicable taxes for Singapore-based companies not included in all
          listed prices.
        </div>
      </div>
    </div>
  );
};

export default BillingPackages;
