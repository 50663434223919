import GVDSModal from "../../../gvds-components/Modals/GVDSModal";
import GVDSButton, {
  buttonVariant,
} from "../../../gvds-components/Buttons/GVDSButton";
import React, { useContext, useState } from "react";
import ToastContext from "../../../context/ToastContext";
import UserInventoryContext from "../../../context/UserInventoryContext";
import { RESOURCES } from "../../../config/constants";
import PaymentInstructionModal from "../../Admin/Billing/PaymentInstructionModal";
import GVFormGroup from "../../common/GVFormGroup";
import Form from "react-bootstrap/Form";
import InfoTooltip from "../../common/Tooltip/InfoTooltip";
import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { yearlyMonthlyPriceDiff } from "../../common/Billing/BillingPlans";
import { BillingCycleEnum } from "../../common/Billing/PackagePlanModel";
import BillingService from "../../../services/BillingService";
import { billingWindowFeatures } from "../../../services/Public/BillingPaymentService";

const COMPONENT_STATE = {
  CONFIRM_NEW: "confirm new",
  PAYMENT_INSTRUCTION: "payment instruction",
};

const StartNewSubscriptionModal = ({
  show,
  onClose,
  newPlan,
  startNewSubscriptionAfterSubscriptionId,
  onNewPlanSuccess,
}) => {
  const toastContext = useContext(ToastContext);
  const userInventory = useContext(UserInventoryContext);
  const selectedInventory = userInventory.selectedInventory.get;
  const resourceType = selectedInventory?.type;
  const resourceId = selectedInventory?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [componentState, setComponentState] = useState(
    COMPONENT_STATE.CONFIRM_NEW
  );

  const [billingCycle, setBillingCycle] = useState(BillingCycleEnum.yearly);
  const [paymentLink, setPaymentLink] = useState(null);

  if (!newPlan || resourceType !== RESOURCES.SITE) {
    return null;
  }

  const currency = "USD";

  const yearlyPrice = newPlan.getYearlyPriceForCurrency(currency);
  const monthlyPrice = newPlan.getMonthlyPriceForCurrency(currency);

  const onSubmitChange = () => {
    setIsLoading(true);
    BillingService.requestNewSubscription(
      resourceType,
      resourceId,
      newPlan.packagePlanId,
      billingCycle,
      startNewSubscriptionAfterSubscriptionId
    )
      .then((paymentLinkPayload) => {
        if (!paymentLinkPayload) {
          toastContext.addSuccessToast(<span>Subscription added.</span>);
          onNewPlanSuccess();
        } else {
          setPaymentLink(paymentLinkPayload);
          window.open(paymentLinkPayload, "_blank", billingWindowFeatures);
          setComponentState(COMPONENT_STATE.PAYMENT_INSTRUCTION);
        }
      })
      .catch(() => {
        toastContext.addFailToast(<span>Failed to get new subscription</span>);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onCancelChange = () => {
    closeModal();
  };

  const closeModal = () => {
    if (!isLoading) {
      onClose();
    }
  };

  if (componentState === COMPONENT_STATE.PAYMENT_INSTRUCTION) {
    return (
      <PaymentInstructionModal onClose={onClose} paymentLink={paymentLink} />
    );
  }

  return (
    <GVDSModal
      size={GVDSModal.Size.small}
      title="Confirm your subscription details"
      show={show}
      onHide={closeModal}
    >
      <GVDSModal.Body>
        <GVFormGroup>
          <Form.Label>Plan Selected</Form.Label>
          <div>{newPlan.name}</div>
        </GVFormGroup>
        <GVFormGroup>
          <Form.Label>
            Billing Cycle{" "}
            {newPlan.hasMonthly && (
              <InfoTooltip info="Select whether you prefer to be billed annually or monthly for your subscription." />
            )}
          </Form.Label>
          <Form.Check
            id="billing-cycle--yearly"
            label={
              <>
                Yearly ({currency.toUpperCase()}{" "}
                <strong>${yearlyPrice?.priceInDecimal}</strong> per year){" "}
                {newPlan.hasMonthly && (
                  <StatusLabel color={StatusLabel.Colors.green}>
                    Save{" "}
                    {yearlyMonthlyPriceDiff(
                      yearlyPrice.priceInDecimal,
                      monthlyPrice.priceInDecimal
                    )}
                    %
                  </StatusLabel>
                )}
              </>
            }
            type="radio"
            checked={billingCycle === BillingCycleEnum.yearly}
            onChange={() => {
              setBillingCycle(BillingCycleEnum.yearly);
            }}
            disabled={isLoading}
          />
          {newPlan.hasMonthly && (
            <Form.Check
              id="billing-cycle--monthly"
              label={
                <>
                  Monthly ({currency.toUpperCase()}{" "}
                  <strong>${monthlyPrice?.priceInDecimal}</strong> per month)
                </>
              }
              type="radio"
              checked={billingCycle === BillingCycleEnum.monthly}
              onChange={() => {
                setBillingCycle(BillingCycleEnum.monthly);
              }}
              disabled={isLoading}
            />
          )}
        </GVFormGroup>

        <div>Your subscription will begin upon successful payment.</div>
      </GVDSModal.Body>
      <GVDSModal.Footer>
        <GVDSButton
          variant={buttonVariant.tertiary}
          onClick={onCancelChange}
          disabled={isLoading}
          text="Cancel"
        />
        <GVDSButton
          variant={buttonVariant.primary}
          onClick={onSubmitChange}
          disabled={isLoading}
          text="Confirm"
        />
      </GVDSModal.Footer>
    </GVDSModal>
  );
};

export default StartNewSubscriptionModal;
