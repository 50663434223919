import axios from "axios";
import { API_URL } from "../../config/api-config";
import { LANG_CODE_ORDER } from "../../config/constants";

class LanguageOptionModel {
  constructor(langCode, displayName) {
    this.langCode = langCode;
    this.displayName = displayName;
  }

  static fromDTO(dto) {
    return new LanguageOptionModel(dto["lang_code"], dto["display_name"]);
  }
}

export default class LanguageOptionService {
  static getAllLanguages = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/ref_data/language_options`
      );
      return response.data.map((langDto) =>
        LanguageOptionModel.fromDTO(langDto)
      );
    } catch (error) {
      throw error;
    }
  };

  static langCodeSortFn = (langCode1, langCode2) => {
    const sortOrder1 = LANG_CODE_ORDER[langCode1];
    const sortOrder2 = LANG_CODE_ORDER[langCode2];

    if (sortOrder1 !== undefined && sortOrder2 !== undefined) {
      return sortOrder1 - sortOrder2;
    } else if (sortOrder1 !== undefined) {
      return -1;
    } else if (sortOrder2 !== undefined) {
      return 1;
    } else {
      return langCode1.localeCompare(langCode2);
    }
  };
}
