import React, { useContext } from "react";
import UserInventoryContext from "../../context/UserInventoryContext";
import { RESOURCES } from "../../config/constants";
import Row from "react-bootstrap/Row";
import DataRequestBox from "./Widgets/DataRequestWidget";
import TaskManagementWidget from "./Widgets/TaskManagementWidget";
import Col from "react-bootstrap/Col";
import { PortfolioSurveyWidget, SiteSurveyWidget } from "./Widgets/SurveyWidget";

const LandingPageTodo = () => {
  const userInventory = useContext(UserInventoryContext);
  if (
    !userInventory.isLoadingInventory.get &&
    userInventory.selectedInventory.get
  ) {
    let currentInventoryType = userInventory.selectedInventory.get.type;
    if (currentInventoryType === RESOURCES.FACILITY) {
      return (
        <>
          <Row className="landing-page__row-widget-container">
            <DataRequestBox />
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row className="landing-page__row-widget-container">
            <Col>
              <TaskManagementWidget />
            </Col>
          </Row>
          <Row className="landing-page__row-widget-multi-col-container">
            <Col>
              <DataRequestBox />
            </Col>
            <Col>
              {currentInventoryType === RESOURCES.PORTFOLIO ? (
                <PortfolioSurveyWidget />
              ) : (
                <SiteSurveyWidget />
              )}
            </Col>
          </Row>
        </>
      );
    }
  }
  return null;
};

export default LandingPageTodo;
