import React from "react";
import greenviewPortalLogo from "../../../assets/images/new-greenview-logo.svg";
import { HOME } from "../../../config/ROUTES_NAME";
import { Link } from "react-router-dom";

const BillingPostPayment = () => {
  const content = (
    <>
      <h2>Payment Successful!</h2>
      <div>
        Thank you for your payment. Retrieve your receipt in your email or
        access it conveniently in the portal.{" "}
        <Link to={HOME}>Click here to access Greenview Portal.</Link>
      </div>
    </>
  );

  return (
    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
      <div className="mb-4">
        <img src={greenviewPortalLogo} alt="Greenview Portal" />
      </div>
      {content}
    </div>
  );
};

export default BillingPostPayment;
