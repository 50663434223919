import StatusLabel from "../../../gvds-components/common/StatusLabel";
import { StringUtils } from "../../../services/UtilsService";
import React from "react";

const SUBSCRIPTION_TYPE = {
  CONTRACT: "contract",
  SITE: "site",
};

const colorMap = {
  [SUBSCRIPTION_TYPE.CONTRACT]: StatusLabel.Colors.lavender,
  [SUBSCRIPTION_TYPE.SITE]: StatusLabel.Colors.lime,
};

const SubscriptionTypeBadge = ({ type }) => {
  return (
    <StatusLabel color={colorMap[type]}>
      {StringUtils.capitaliseWord(type)}
    </StatusLabel>
  );
};

export default SubscriptionTypeBadge