import DashboardDataContext from "../../Context/DashboardDataContext";
import React, { useContext, useEffect, useState } from "react";
import DashboardCalculator from "../../Models/DashboardCalculator";
import CollectionSummaryCardsView from "./CollectionSummaryCardsView";
import { DATA_STATUS } from "../../../../config/constants";
import SafeDataService from "../../../../services/SafeDataService";
import { useTranslation } from "react-i18next";

const CollectionSummaryCards = ({ element }) => {
  const { t } = useTranslation();

  const dashboardDataContext = useContext(DashboardDataContext);
  const dashboardHolderFacade = dashboardDataContext.dashboardHolderFacade;

  const [summaryData, setSummaryData] = useState([]);

  const subtopics = [
    ...new Set(element["children"].map((c) => c["config"]["subtopic"])),
  ];

  const isDataLoaded = dashboardHolderFacade.isAllRequiredDataPresent(
    subtopics,
    true
  );

  useEffect(() => {
    if (!dashboardDataContext.isLoadingData && isDataLoaded) {
      const children = element["children"];

      setSummaryData(
        children.map((c) => {
          const config = c["config"];

          const summarySafeData = DashboardCalculator.getSummary(
            config,
            dashboardHolderFacade
          );

          const unit = DashboardCalculator.getFullUnit(
            config,
            dashboardHolderFacade
          );
          const change = SafeDataService.getValue(
            DashboardCalculator.getYearDiff(config, dashboardHolderFacade)
          );
          const isNegativeChangeGood = config["isNegativeChangeGood"];

          return {
            title: c["name"],
            value: summarySafeData.value,
            unit: unit,
            change: change,
            changeDescription: t(
              "dashboards.prev-period-comparison-description"
            ),
            isNegativeChangeGood: isNegativeChangeGood,
            description: c["description"],
            isDataComplete: summarySafeData.status === DATA_STATUS.COMPLETE,
          };
        })
      );
    }
  }, [dashboardDataContext.lastDataUpdated]);

  if (dashboardDataContext.isLoadingData || !isDataLoaded) {
    return <CollectionSummaryCardsView isLoading={true} />;
  }

  return <CollectionSummaryCardsView data={summaryData} />;
};

export default CollectionSummaryCards;
